import { Component } from 'react';
import { FormatControlProps } from '../column-hint-format-manager';
import { ColumnHintLayoutSection } from '../../../../../components/column-hint/layouts-section';
import { ColumnHintFontsSection } from '../../../../../components/column-hint/fonts-section';
import { ColumnHintColoursSection } from '../../../../../components/column-hint/colours-section';
import { ColumnHintOtherSection } from '../../../../../components/column-hint/other-section';
import { ColumnHintDateSection } from '../../../../../components/column-hint/date-section';
import { ColumnHintHyperlinksSection } from '../../../../../components/column-hint/hyperlinks-section';

export class ColumnHintDateFormat extends Component<FormatControlProps> {
    public render() {
        return (
            <div>
                <ColumnHintDateSection
                    columnHint={this.props.columnHint}
                    onChangeHint={this.props.onChangeHint}
                />
                <ColumnHintLayoutSection
                    columnHint={this.props.columnHint}
                    onChangeHint={this.props.onChangeHint}
                />
                <ColumnHintFontsSection
                    columnHint={this.props.columnHint}
                    onChangeHint={this.props.onChangeHint}
                />
                <ColumnHintColoursSection
                    columnHint={this.props.columnHint}
                    onChangeHint={this.props.onChangeHint}
                />
                <ColumnHintHyperlinksSection
                    columnHint={this.props.columnHint}
                    onChangeHint={this.props.onChangeHint}
                />
                <ColumnHintOtherSection
                    columnHint={this.props.columnHint}
                    onChangeHint={this.props.onChangeHint}
                />
            </div>
        );
    }
}
