import { useCallback } from 'react';
import useFetch from '../useFetch';
import useSnackbar from '../useSnackbar';
import endPointsMapper from '../../../../../configs/endPointsMapper';
import { updateCurrentPage } from "../../actions"
import useLoader from '../useLoader';
import translator from '../../../../../services/translator';
import { PAGES } from "../../reducers";
import useUserMaintenance from '../../context';

const { translate: t } = translator;

const useCreateNewUser = () => {

  const { openSnackbar } = useSnackbar();

  const { reduxDispatch } = useUserMaintenance();

  const onSuccess = useCallback(() => {
    openSnackbar({ type: 'success', message: t('tkChangesSavedSuccessfully') });
    reduxDispatch(updateCurrentPage(PAGES.LANDING_PAGE));
  }, []);

  const onError = useCallback(() => {
    openSnackbar({ type: 'error', message: t('tkAnErrorPleaseTryAgain') });
  }, []);

  const {
    doFetch,
    loading
  } = useFetch(endPointsMapper.CREATE_NEW_USER, onSuccess, onError);

  useLoader(loading);

  const createNewUser = useCallback(async (data) => {
    await doFetch({ userSaveDetails: [data] })
  }, [])

  return {
    createNewUser
  }

}

export default useCreateNewUser;