import { FormatControlProps } from '../../plugins/column-hint/view/components/column-hint-format-manager';
import { ColumnHintFormatSection } from '../../plugins/column-hint/view/components/formatScreens/column-hint-format-section';
import { Row, Col } from '@gs-ux-uitoolkit-react/layout';
import { columnFormattingPluginStylePrefix } from '../../style/plugins/column-hint-plugin-stylesheet';
import { ColumnHintControlColor } from '../../plugins/column-hint/view/components/formatControls/column-hint-control-color';
export const ColumnHintColoursSection = (props: FormatControlProps) => {
    return (
        <ColumnHintFormatSection title="Colors">
            <Row>
                <Col sm={12}>
                    <div
                        className={`${columnFormattingPluginStylePrefix}_colour-container ${columnFormattingPluginStylePrefix}_flex`}
                    >
                        <ColumnHintControlColor
                            columnHint={props.columnHint}
                            onChangeHint={props.onChangeHint}
                            title="Background"
                            colorPath="bg"
                        />

                        <ColumnHintControlColor
                            columnHint={props.columnHint}
                            onChangeHint={props.onChangeHint}
                            title="Foreground"
                            colorPath="fg"
                        />
                        <ColumnHintControlColor
                            columnHint={props.columnHint}
                            onChangeHint={props.onChangeHint}
                            title="Column Groups Background"
                            colorPath="bgHeaderGroups"
                        />
                    </div>
                </Col>
            </Row>
        </ColumnHintFormatSection>
    );
};
