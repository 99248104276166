import React, {useState, useEffect} from 'react';
import {PropTypes} from 'prop-types';
import LeftPanel from '../../core/LeftPanel';
import Input from '../../core/Input';
import Button from '../../core/Button';
import {LOGIN_NEXT_STEP_CODES, FORGOT_PASSWORD_STEP_CODES} from '../../../constants/pageConstants';
import {Conditional} from '../../core/Conditional';
import translator from '../../../services/translator';
import './index.scss';
import {getLoadOfEnterOTP, getLoadOfEnterUID} from '../../../utils/loginAnalytics';
import {SessionStorage} from '../../../utils/storage';
import ValidationItem from '../ValidationItem';

const {translate: t} = translator;

const ForgotPassword = (props) => {
  const {OTP, UID} = FORGOT_PASSWORD_STEP_CODES;
  const [isUpdateSaved, setUpdateSaved] = useState(false);
  const [uid, setUid] = useState(props.userId || SessionStorage.getItem(UID));
  const [otp, setOtp] = useState('');
  const [step, setStep] = useState((LOGIN_NEXT_STEP_CODES.FORGOT_PASSWORD_OTP === props.nextStep) ? OTP : UID);
  const {submitCredentialsError} = props;
  const errorState = submitCredentialsError && isUpdateSaved;
  const leftPanelProps = {
    title: t('tkLeftPanelHeader'),
    subTitle: t('tkLeftPanelSubHeader')
  };

  useEffect(() => {
    if(step === OTP){
      props.eventAnalytics(getLoadOfEnterOTP());
    }else{
      props.eventAnalytics(getLoadOfEnterUID());
    }
  }, step);

  const onSubmit = () => {
    if (step === UID) {
      SessionStorage.setItem(UID, uid);
      props.submitUID({uid});
    } else {
      props.submitOTP({otp: otp.trim()});
    }
    setStep((step === UID) ? OTP : step);
    setUpdateSaved(true);
  };

  const onInputChange = (value = '', event) => {
    const {name} = event.target;
    if (name === 'uid') {
      setUid(value.trim());
    } else {
      setOtp(value.trim());
    }
    setUpdateSaved(false);
  };

  const isNextDisabled = () => {
    return !(uid && uid.length >= 3);
  };

  const isSubmitDisabled = () => {
    return !(otp && otp.length >= 1);
  };

  const resendOTP = () => {
    window.location.reload();
  };

  return (
    <div className="forget-password">
        <div className="forget-password__left-panel">
          <div className="panel-title">
            <LeftPanel {...leftPanelProps} />
          </div>
        </div>
        {
          <div className="forget-password__right-panel">
            <div className="fields">
              <div className="fields__header">
                {t('tkForgotPasswordHeader')}
              </div>
              <div className="fields__subheader">
                {(step === UID) ? t('tkForgotPasswordSubHeaderUID') : t('tkForgotPasswordSubHeaderOTP')}
              </div>
              <form onSubmit={onSubmit}>
                <div className="fields__inputs">
                  <div className="input-container">
                    <Conditional condition={step === UID}>
                      <Input
                        hasVerification={false}
                        type="text"
                        name="uid"
                        placeholder={t('tkUIDPlaceholder')}
                        value={uid}
                        changeHandler={onInputChange}
                        customClass={`login-input ${errorState && 'error'}`} />
                      <Input
                        hasVerification={false}
                        type="text"
                        name="otp"
                        placeholder={t('tkOTPPlaceholder')}
                        value={otp}
                        changeHandler={onInputChange}
                        customClass={`login-input ${errorState && 'error'}`} />
                    </Conditional>
                  </div>
                  {
                    errorState &&
                    <div className="fields__error-text">
                      <ValidationItem
                        key={submitCredentialsError}
                        customClass="validation-list"
                        className="check-mark"
                        iconStatus='invalid'
                        lineText={submitCredentialsError}
                      />
                    </div>
                  }
                </div>
                <div className="login-button">
                  <Conditional condition={step === UID}>
                    <Button
                      type="submit"
                      customClass="button-primary-transparent"
                      label={t('tkNext')}
                      isDisabled={isNextDisabled()}
                      clickHandler={onSubmit} />
                    <Button
                      type="submit"
                      customClass="button-primary-transparent"
                      label={t('tkSubmit')}
                      isDisabled={isSubmitDisabled()}
                      clickHandler={onSubmit} />
                  </Conditional>
                </div>
                <div className="footer">
                  <Conditional condition={step === UID}>
                    <span className="footer__header">
                      {t('tkUIDFooterNote')}
                    </span>
                    <span className="footer__header">
                      {t('tkOTPFooterNote')}
                      <div className="footer__resendCode">
                        {t('tkResendCodePrefix')}
                        <span onClick={resendOTP} className="footer__subheader">
                          {t('tkResendYourCode')}
                        </span>
                      </div>
                    </span>
                  </Conditional>
                </div>
              </form>
            </div>
          </div>
        }
      </div>
  );
};

ForgotPassword.propTypes = {
  submitCredentialsError: PropTypes.string,
  userId: PropTypes.string,
  nextStep: PropTypes.string,
  submitUID: PropTypes.func,
  submitOTP: PropTypes.func,
  eventAnalytics: PropTypes.func,
  history: PropTypes.object
};

export default ForgotPassword;
