import React from 'react';
import useUserMaintenance from '../../../context';
import OrgDetailsGrid from './OrgDetailsGrid';
import translator from '../../../../../../services/translator';

const { translate: t } = translator;

const Funds = () => {
  const {
    state: { disablingEdit: isOrgEditable },
  } = useUserMaintenance();
  return (
    <div className='userdetailsfunds orgdetailsfunds gs-scrollbar'>
      <div className='orgdetailsfunds__header'>{t('tkFundsEntitlements')}</div>
      <div className='orgdetailsfunds__subheader'>{isOrgEditable ? t('tkOrgFundsEditSubtitle') : t('tkOrgFundsSubtitle')}</div>
      <OrgDetailsGrid />
    </div>
  );
};

export default Funds;
