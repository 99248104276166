import { PropsWithChildren, FC, memo, Children, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    StepsProps,
    stepsStyleSheet,
    stepsDefaultProps,
    getStepsRootClasses,
    getStepsListClasses,
    // @ts-expect-error - StepsOrientation needed for API extractor dynamic import() issue
    StepsOrientation,
    getToEdgeToEdgeContainerClasses,
} from '@gs-ux-uitoolkit-common/steps';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { StepsContext } from './steps-context';
import { componentAnalytics } from './analytics-tracking';

/**
 * Steps are used to visualize a set of related milestones or a sequence of processes.
 * @visibleName Steps
 */
export const Steps: FC<PropsWithChildren<StepsProps>> = memo(props => {
    const {
        currentStep,
        fillIndicators = stepsDefaultProps.fillIndicators,
        orientation = stepsDefaultProps.orientation,
        size = stepsDefaultProps.size,
        className,
        classes: overrideClasses,
        children,
        inline = stepsDefaultProps.inline,
        expandable = stepsDefaultProps.expandable,
        hideLabels = stepsDefaultProps.hideLabels,
        onStepClick,
        ...attributes
    } = props;

    const totalNumberOfSteps = Children.count(children);

    const theme = useTheme();
    const cssClasses = useStyleSheet(stepsStyleSheet, {
        orientation,
        hideLabels,
        totalNumberOfSteps,
        size,
        theme,
    });

    const StepsList = (
        <>
            <ul
                data-cy="gs-uitk-steps__list"
                className={getStepsListClasses({
                    cssClasses,
                    overrideClasses,
                })}
            >
                {Children.map(children, (child, index) => (
                    <StepsContext.Provider value={{ ...props, index }}>
                        {child}
                    </StepsContext.Provider>
                ))}
            </ul>
        </>
    );

    useEffect(() => {
        // track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'steps' });
    }, []); // Only run once

    return (
        <span
            data-gs-uitk-component="steps"
            data-orientation={orientation}
            data-size={size}
            className={getStepsRootClasses({
                cssClasses,
                overrideClasses,
                className,
            })}
            {...attributes}
        >
            {hideLabels ? (
                <div
                    data-cy="gs-uitk-steps__to-edge-container"
                    className={getToEdgeToEdgeContainerClasses({ cssClasses })}
                >
                    {StepsList}
                </div>
            ) : (
                StepsList
            )}
        </span>
    );
});
Steps.displayName = 'Steps';

Steps.propTypes = {
    classes: PropTypes.object,
    currentStep: PropTypes.number,
    fillIndicators: PropTypes.bool,
    inline: PropTypes.bool,
    expandable: PropTypes.bool,
    hideLabels: PropTypes.bool,
    onStepClick: PropTypes.func,
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    size: PropTypes.oneOf(['sm', 'md']),
};
