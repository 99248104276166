import { AutofitPivotActionKeys } from './action-constants';

export type AutofitPivotActionTypes = ToggleEnablePluginAction;

export interface ToggleEnablePluginAction {
    type: AutofitPivotActionKeys.TOGGLE_ENABLE_PLUGIN;
}

export const ToggleEnablePlugin = (): ToggleEnablePluginAction => ({
    type: AutofitPivotActionKeys.TOGGLE_ENABLE_PLUGIN,
});
