import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import useFetch from '../useFetch';
import useSnackbar from '../useSnackbar';
import endPointsMapper from '../../../../../configs/endPointsMapper';
import translator from '../../../../../services/translator';
import { addPageContext } from '../../../../../actions/page';
import { ORGANIZATIONS } from '../../constants';
import { fetchUserSearchDetails, updateCurrentPage } from '../../actions';
import { PAGES } from '../../reducers';
import '../../components/FirmOrganizationMappingModal/index.scss';

const { translate: t } = translator;

const SnackbarHyperLink = ({ reduxDispatch, id, name, firmName, firmCode }) => {
  const snackbar = useSnackbar();
  const handleClick = () => {
    reduxDispatch(addPageContext({ pageKey: ORGANIZATIONS }));
    reduxDispatch(updateCurrentPage(PAGES.DETAILS_PAGE));
    reduxDispatch(fetchUserSearchDetails({ id, isOrganization: true }));
    snackbar && snackbar.closeSnackbar && snackbar.closeSnackbar('mapFirmToOrganizationSuccess');
  };

  return (
    <>
      <span>{t('tkMapFirmToOrganizationSuccess', firmName, firmCode)}</span>
      <span onClick={handleClick} className='snackbarHyperlink'>
        {name}.
      </span>
    </>
  );
};

SnackbarHyperLink.propTypes = {
  reduxDispatch: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  firmName: PropTypes.string,
  firmCode: PropTypes.string,
};

function useFirmToOrganizationMapping(reduxDispatch) {
  const { openSnackbar } = useSnackbar();

  const onSuccess = useCallback(
    (
      _,
      {
        orgFirmMappingRequest: {
          organizationRequest: { name, id },
          firmRequest: { firmName, firmCode },
        },
      }
    ) => {
      openSnackbar({
        type: 'success',
        isHtmlMsg: true,
        id: `organizationId-${id}`,
        message: <SnackbarHyperLink reduxDispatch={reduxDispatch} id={id} name={name} firmName={firmName} firmCode={firmCode} />,
      });
    },
    []
  );

  const onError = useCallback(
    (
      _,
      {
        orgFirmMappingRequest: {
          organizationRequest: { name },
          firmRequest: { firmName, firmCode },
        },
      }
    ) => {
      openSnackbar({
        type: 'error',
        message: t('tkMapFirmToOrganizationError', firmName, firmCode, name),
      });
    },
    []
  );

  const endpoint = endPointsMapper.FETCH_FIRM_ORGANIZATION_MAPPING;

  const { doFetch: onMapFirmToOrganization, loading, success } = useFetch(endpoint, onSuccess, onError);

  return { onMapFirmToOrganization, loading, success };
}

export default useFirmToOrganizationMapping;
