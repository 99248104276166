import  translator  from '../services/translator';
import {STORE} from '../constants/pageConstants';
import {SessionStorage} from './storage';
const {translateTradeKeys: t} = translator;

export const translateTradeMessage = (code, message, param) => {
  switch(code) {
    case 'TRADEBLOTTER_9683': {
      const {nextNavStrikeTime} = param;
      return t(code, message, nextNavStrikeTime, nextNavStrikeTime);
    }
    case 'TRADEBLOTTER_2999': {
      const {tradeType} = param;
      return tradeType && t(code, message, tradeType) ||  t(code, message);
    }

    default:
      return t(code, message) ;
  }
};

export const clearActivityErrorSession = () => {
  SessionStorage.removeItem(STORE.TRADE_APPROVALS_ERROR_MESSAGES_STORE);
};
