import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import Button from '../../core/Button';
import {getAppRoute, getQueryParamValue, getZendeskURL} from '../../../utils/commonUtils';
import Constants from '../../../constants/appConstants';

const getMappedUrl = (pathMapping) => {
  const gcspIdentifier = getQueryParamValue(Constants.TARGET);
  if (gcspIdentifier) {
    const mosaicPathMapping = pathMapping.find((path) => path.key === gcspIdentifier);
    if (mosaicPathMapping) {
      const {value = "", isHelpRoute = false} = mosaicPathMapping;
      // If it's a help route then the Domain name would be zendesk, not the mosaic domain
      return isHelpRoute ? getZendeskURL() : value;
    }
  }
  return getAppRoute(Constants.PORTFOLIO_ROUTE);
};

export const InterstitialModal = ({labels, pathMapping}) => {
  const clickHandler = () => {
    window.location.href = getMappedUrl(pathMapping);
  };

  return (
    <div className="interstitialModal__overlay">
      <div className="modal-outline">
        <div className="interstitialModal__container">
          <div className="interstitialModal__header-container">
            <span className="interstitialModal__header">{labels.tkInterstitialHeader}</span>
          </div>
          <div className="interstitialModal__line" />
          <div className="interstitialModal__body">
            <p>{labels.tkInterstitialLine1}</p>
            <p>{labels.tkInterstitialLine2}</p>
          </div>
          <div className="interstitialModal__btn-container">
            <Button
              label={labels.tkInterstitialBtnLabel}
              clickHandler={clickHandler}
            />
          </div>
        </div>
        <div className="interstitialModal__rectangle" />
      </div>
    </div>
  );
};

InterstitialModal.propTypes = {
  pathMapping: PropTypes.array,
  labels: PropTypes.object
};
