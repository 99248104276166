/**
 * Utility method to call `Object.prototype.hasOwnProperty.call(obj, 'someProp')`
 * on an object without the long chaining of methods in the code (which
 * contributes to file size).
 *
 * See https://eslint.org/docs/latest/rules/no-prototype-builtins for details.
 */
export function hasOwnProperty(obj: object, propName: string): boolean {
    return Object.prototype.hasOwnProperty.call(obj, propName);
}
