import {
    CellClassParams,
    CellStyleFunc,
    ColDef,
    ColGroupDef,
    GridOptions,
    ICellRendererComp,
    ICellRendererFunc,
    IRowNode,
    RowClassParams,
    ValueFormatterParams,
    ValueGetterParams,
} from '@ag-grid-community/core';
import {
    DataType,
    DraDatasource,
    DraSetupReturn,
    DraViewConfig,
    ExpressionQuery,
    PluginAction,
    PluginBase,
    PluginScreen,
    PluginWidget,
    Registry,
    SimpleEventDispatcher,
    QuickFilter,
} from '@gs-ux-uitoolkit-common/datacore';
import * as Redux from 'redux';
import { CustomSort } from '../plugins/custom-sort/custom-sort-plugin';
import { CustomDRAOptions } from '../plugins/dra/dra-plugin';
import { ExportCallbacks, ExportOptions } from '../plugins/exports/export';
import { SavedView, SavedViewOptions } from '../plugins/saved-view/saved-view';
import { DataGridState, PluginStateChangedPayload } from '../redux/datagrid-state';
import { Theme as UitkTheme } from '@gs-ux-uitoolkit-react/theme';
import { ReactReduxContextValue } from 'react-redux';
import { Context } from 'react';

/**
 * Interface of the GridWrapper. Sits on top of a Grid.
 */
export interface GridWrapper {
    /**
     * Event being called when a column has been resized
     */
    columnResized: SimpleEventDispatcher<GridWrapperColumnResizedEventArgs>;
    /**
     * Event being called when the grid is scrolled
     */
    gridScrolled: SimpleEventDispatcher<object>;
    /**
     * Event being called when a data is updated
     */
    dataUpdated: SimpleEventDispatcher<DataUpdatedEventArgs>;
    /**
     * Event being called when a column is pinned
     */
    columnPinned: SimpleEventDispatcher<ColumnPinnedEventArgs>;
    /**
     * Event being called when a row group is opened or closed
     */
    rowGroupCollapseChanged: SimpleEventDispatcher<object>;
    /**
     * Event being called when a row group is opened
     */
    rowGroupExpanded: SimpleEventDispatcher<object>;
    /**
     * Event being called when row group is changed
     */
    rowGroupChanged: SimpleEventDispatcher<object>;
    /**
     * Event being called when typing into a cell editor
     */
    editorKeyDown: SimpleEventDispatcher<EditorKeyDownEventArgs>;
    /**
     * Event being called when row selection changes
     */
    rowSelectionChanged: SimpleEventDispatcher<object>;
    /**
     * Event being called when the data is rendered for the first time
     */
    firstDataRendered: SimpleEventDispatcher<object>;
    /**
     * Event called when the savedView has been saved
     */
    savedViewSaved: SimpleEventDispatcher<SavedView>;
    savedViewCreated: SimpleEventDispatcher<SavedView>;
    savedViewDeleted: SimpleEventDispatcher<string>;

    quickFilterSaved: SimpleEventDispatcher<QuickFilter>;
    quickFilterCreated: SimpleEventDispatcher<QuickFilter>;
    quickFilterDeleted: SimpleEventDispatcher<QuickFilter>;
    pluginStateChanged: SimpleEventDispatcher<PluginStateChangedPayload>;

    themeChanged: SimpleEventDispatcher<UitkTheme>;
    /**
     * HTML Ref Object for the wrapper to enable DOM Manipulation
     */
    wrapperRef: HTMLElement | null;
    /**
     * Returns the Modals registry of the gridWrapper instance
     */
    getScreensRegistry: () => Registry<PluginScreen>;
    /**
     * Returns the Widgets registry of the gridWrapper instance
     */
    getWidgetsRegistry: () => Registry<PluginWidget>;
    /**
     * Returns the Actions registry of the gridWrapper instance
     */
    getActionsRegistry: () => Registry<PluginAction>;
    /**
     * Get list of modules registerd in the grid
     */
    getRegisteredModulesNames: () => string[];
    getFormattedValueFromColumn: GetFormattedValueFromColumn;
    /**
     * Get the ColumnFilterModel associated to the underlying grid implementation
     */
    getColumnFilterModel: () => {
        [key: string]: any;
    };
    /**
     * Returns the associated redux store
     */
    getReduxStore(): Redux.Store<DataGridState>;
    /**
     * Returns the redux store context
     */
    getReduxStoreContext(): Context<ReactReduxContextValue> | undefined;
    /**
     * Returns the base state of the system at startup without application customization
     */
    getBaseState(): DataGridState | null;
    /**
     * Returns the an array of the distinct value for the column.
     * It includes a original data in the DisplayTuple so we can sort the data accordingly in the UIs
     * @param columnId id of the column
     */
    getDistinctColumnValueList(columnId: string): Promise<DisplayValueTuple[]>;
    /**
     * Returns the display value of a cell for the grid.
     * i.e. could be "$ 1.000.000,00" for the value 1000000.00
     * @param rowNode rowNode of the record
     * @param columnId Id of the column
     */
    getDisplayValue(rowNode: IRowNode, columnId: string): string;
    /**
     * Evaluate the value getter in case the valueGetter is an expression
     * @param valueGetter
     * @param params
     */
    evaluateValueGetter(
        valueGetter:
            | string
            | ((params: ValueGetterParams) => any)
            | ((params: ValueFormatterParams) => any),
        params: ValueGetterParams | ValueFormatterParams,
        useExpressionEvaluator?: boolean
    ): any;
    /**
     * Returns the value of a cell for the grid.
     * @param rowNode rowNode of the record
     * @param columnId Id of the column
     */
    getRawValue(rowNode: IRowNode, columnId: string): any;
    /**
     * Returns true if column exists and false if not.
     * @param columnId Id of the column
     */
    doesColumnExist(columnId: string): boolean;
    /**
     * Set a custom sort on a column
     * Remark: we are leaking the ag-Grid RowNode implementation on that method
     * @param customSort a CustomSort
     * @param comparator The function that will be called when sorting
     * @
     */
    addCustomSort(
        customSort: CustomSort,
        comparator: (
            valueA: any,
            valueB: any,
            nodeA?: IRowNode,
            nodeB?: IRowNode,
            isInverted?: boolean
        ) => number
    ): void;
    /**
     * Removes a custom sort from a Column
     * @param columnId Id of the Column
     */
    removeCustomSort(columnId: string): void;
    /**
     * Remove the column from the filter model
     * @param columnId the Column Id
     */
    removeColumnFilter(columnId: string): void;
    /**
     * Empty the column filter model
     */
    removeAllColumnFilters(): void;
    /**
     * Refresh the grid when filtering has changed
     */
    refreshFiltering(): void;
    /**
     * Register a callback that will tell if it needs to call filterpass
     */
    onIsFilterPresent(isFilterPresent: () => boolean): void;
    /**
     * Register a callback that will be called for each record and filter it or not
     * @param filterPass the callback
     */
    onFilterPass(filterPass: (rowNode: IRowNode) => boolean): void;
    /**
     * add a Plugin to the grid wrapper. It will initialize it and start it
     * @param plugin The plugin
     */
    addPlugin(plugin: PluginBase<GridWrapper, DataGridState>): void;
    /**
     * Get the plugins that are loaded
     */
    getPlugins(): PluginBase<GridWrapper, DataGridState>[];
    /**
     * Adds a Quick Filter search to the grid
     * @param search The text search
     */
    addQuickFilter(search: string): void;
    /**
     * Returns true when the grid is connected to a DRA data source
     */
    isUsingDra(): boolean;
    /**
     * Returns true if one or more valueList are truncated in the cells of a column
     * @param colId The column Id
     */
    hasColumnTruncatedValues(colId: string): boolean;
    /**
     * Apply a mask to the column
     * @param colId The column Id
     */
    maskColumn(colId: string): void;
    /**
     * Remove the column mask
     * @param colId The column Id
     */
    unmaskColumn(colId: string): void;
    /**
     * Redraw the grid
     */
    refreshGrid(): void;
    /**
     * Remove an item from the cellClassRules
     * @param columnId Column to remove cellClassRule, null if row rule
     * @param propertyName Class to be removed from cellClassRules
     */
    removeCellClassRule(columnId: string | null, propertyName: string): void;
    /**
     * Add an item to the cellClassRules
     * @param columnId Column to add to cell class rules, null if entire row
     * @param propertyName Name of the class to use
     * @param func Function to check whether the class should be added
     */

    setTheme(theme: UitkTheme): void;

    getTheme(): UitkTheme;
    addCellClassRule(
        columnId: string | null,
        propertyName: string,
        func: (params: CellClassParams) => boolean
    ): void;
    /**
     * Set the ColumnStyle on the ColDef
     * @param columnId Column to add to cell class rules, null if entire row
     * @param func Function to check whether the class should be added
     */
    setColumnStyle(columnId: string, func: CellStyleFunc): void;
    /**
     * Remove the ColumnStyle on the ColDef
     * @param columnId Column to add to cell class rules, null if entire row
     */
    removeColumnStyle(columnId: string): void;
    /**
     * Add an item to rowClassRules
     * @param propertyName Name of the class to use
     * @param func Function to check whether the class should be applied
     */
    addRowClassRule(propertyName: string, rule: (params: RowClassParams) => boolean): void;
    /**
     * Remove an item from rowClassRules
     * @param propertyName Name of the class to remove
     */
    removeRowClassRule(propertyName: string): void;
    /**
     * Get the id of the grid
     */
    getId(): string;
    /**
     * Get the pivoted column id given column and pivotKeys
     * @param columnId Primary column id
     * @param pivotKeys Array of pivot keys on column
     */
    getPivotedColumnId(columnId: string | null, pivotKeys?: string[]): string;
    /**
     * Autofit the column
     */
    autofitColumn(columnId: string): void;
    /**
     * Set the value of the editor of the corresponding cell
     * logs an error if the cell is not currently editing
     */
    setEditorValue(rowIndex: number, columnId: string, value: string): void;
    /**
     * Set the name that appears in the header
     * @param columnId The column id
     * @param name The name to add to the header
     */
    setHeaderName(columnId: string, name: string): void;
    /**
     * Set the className on a columnGroup header
     * @param groupId The group id
     * @param className The className
     */
    setHeaderGroupClass(groupId: string, className: string): void;
    /**
     * Set the className on a column header
     * @param columnId The column id
     * @param className The className
     */
    setHeaderClass(columnId: string, className: string): void;
    /**
     * Set a tooltip for the header
     * @param columnId The column id
     * @param tooltip The tooltip to add to the header
     */
    setHeaderTooltip(columnId: string, tooltip: string): void;
    /**
     * Set a tooltip for the column
     * @param columnId The column id
     * @param tooltip The tooltip to add to the header
     */
    setTooltip(columnId: string, tooltip: string): void;
    /**
     * Set the width of a column
     * @param columnId The column id
     * @param width The width to set the column to
     */
    setColumnWidth(columnId: string, width: number): void;
    /**
     * Set if a column is editable
     * @param columnId The column id
     * @param editable
     */
    setColumnEditable(columnId: string, editable: boolean): void;
    /**
     * Set a formatter for the column
     * @param columnId The column id
     * @param formatter The formatter that will be called when rendering the cell
     */
    /**
     * Sets the minimum width that a column can take
     * @param columnId The column id
     * @param width The minimum width in px
     */
    setColumnMinWidth(columnId: string, width: number): void;
    /**
     * Sets the maximum width that a column can take
     * @param columnId The column id
     * @param width The maximum width in px
     */
    setColumnMaxWidth(columnId: string, width: number): void;
    setColumnVisibilty(columnId: string, visible: boolean): void;
    setFormatter(columnId: string, formatter: (value: any) => string): void;
    removeFormatter(columnId: string): void;
    /**
     * Set the id of the grid
     */
    setId(id: string): void;

    /**
     * Allow the column data type definitions to be updated dynamically
     * @param columnDataTypeDefinition
     */
    setColumnDataTypeDefinition(columnDataTypeDefinition: { [index: string]: DataType }): void;
    /**
     * Get the Selected Nodex
     * We leak ag-grid implementation here as well. Not ideal
     */
    getSelectedNodes(): IRowNode[];
    /**
     * Run function for every node in the grid
     * @param func the function to run
     */
    forEachNode(func: (rowNode: IRowNode) => void): void;
    /**
     * Run function for every node in the grid after sort and filter
     * @param func the function to run
     */
    forEachNodeAfterFilterAndSort(func: (rowNode: IRowNode) => void): void;
    /**
     * Create a savedView
     */
    getSavedView(options: SavedViewOptions, name: string): SavedView;
    /**
     * Set savedView
     */
    setSavedView(savedView: SavedView): void;
    /**
     * Exports the grid data
     * @param options Export options
     * @param callbacks: Callbacks to process data before it is exported
     */
    export(options: ExportOptions, callbacks?: ExportCallbacks): Promise<void>;
    /**
     * Get the options for the grid
     */
    getGridOptions(): GridOptions;
    /**
     * Destroy the gridWrapper
     */
    destroy(): void;
    /**
     * Set the cell renderer for a column
     * @param columnId
     * @param cellRenderer
     */
    setCellRenderer(
        columnId: string,
        cellRenderer: string | (new () => ICellRendererComp) | ICellRendererFunc | undefined
    ): void;
    /**
     * Add a cell value getter to a column
     * @param columnId
     * @param valueGetter
     */
    setColumnValueGetter(
        columnId: string,
        valueGetter: string | ((params: ValueGetterParams) => any) | undefined,
        initialValueGetter?: string | ((params: ValueGetterParams) => any) | undefined
    ): void;
    /**
     * reset the column value getter to the initial getter if one has been stored.
     * @param columnId
     */
    resetColumnValueGetterToInitialGetter(columnId: string): void;
    /**
     * Returns the cell value getter of a column if any has been set
     * @param columnId
     * @param valueGetter
     */
    getColumnValueGetter(
        columnId: string
    ): string | ((params: ValueGetterParams) => any) | undefined;
    /**
     * Remove the cell renderer for a column
     * @param columnId
     */
    removeCellRenderer(columnId: string): void;
    /**
     * returns the custom dra options
     */
    getCustomDRAOptions(): CustomDRAOptions;
    /**
     * get dra datasource
     */
    getDRADatasource(): DraDatasource | null;
    /**
     * set the callback for open modal screen
     */
    setOpenModalScreenCallback: (callback: (screenId: string) => void) => void;
    /**
     * open modal screen
     */
    openModalScreen: (screenId: string) => void;
    /**
     * Add column resize listener
     */
    addColumnResizeListener: (callback: () => void) => void;
    /**
     * Remove column resize listener
     */
    removeColumnResizeListener: (callback: () => void) => void;
    /**
     * Set column header heights
     */
    setColumnHeaderHeights: (value: number) => void;
    /**
     * Append to an array of header classes. This will convert the existing non array header class value into an array
     */
    appendHeaderClass(className: string, columnId?: string): void;
    /**
     * Remove appended header class from the class name array
     */
    removeAppendedHeaderClass(className: string, columnId?: string): void;
    /**
     * Listen to grid column state changes
     */
    listenToGridColumnStateChanges: (callback: () => void) => void;
    /**
     * get overlay container
     */
    getOverlayContainer: () => HTMLElement | null;
    /** Listen to grid viewport change listener
     */
    addGridViewportChangedListener: (callback: () => void) => void;
    /**
     * remove grid viewport change listener
     */
    removeGridViewportChangedListener: (callback: () => void) => void;
    /**
     *  Initialises the state of the floating filter by checking which columns have floating filters enabled and storing their ids
     */
    initialiseFloatingFilters: () => void;
    /**
     * toggles the floating filter enabled state
     */
    toggleFloatingFilters: () => void;
    /**
     *  used to inform grid api that plugin has been added
     */
    onPluginAdded: (
        onPluginAddedCallback: (plugin: PluginBase<GridWrapper, DataGridState>) => void
    ) => void;
}

/**
 * Represent the definition of a grid column
 */
export interface GridColumn {
    /**
     * The Id of the column. Unique
     */
    columnId: string;
    /**
     * The label of the column i.e. the label that will be displayed in the header
     */
    columnLabel: string;
    /**
     * The index of the column in the grid. If not visible -1
     */
    visibleIndex: number;
    /**
     * The visibility of the column in the Grid
     */
    visible: boolean;
    /**
     * If the column is hidden from the end user
     */
    hidden: boolean;
    /**
     * The type of data in the column
     */
    dataType: DataType;
    /**
     * If the column is sortable
     */
    isSortable: boolean;
    /**
     * If the column is editable
     */
    isEditable: boolean;
    /**
     * If the column is filterable
     */
    isFilterable: boolean;
    /**
     * If the column is a pivot column/row group column
     */
    isRowGroup: boolean;
    /**
     * Pivot keys for identifying pivoted columns that are generated
     */
    pivotKeys?: string[];
    /**
     * Primary col id for pivoted columns
     */
    primaryColumnId?: string;
}

export type GetFormattedValueFromColumn = (
    columnId: string,
    value: string | number | Date
) => string;

/**
 * Represent a tuple of displayvalue and rawvalue
 */
export interface DisplayValueTuple {
    /**
     * the value as it would be rendered in the grid
     */
    displayValue: string;
    /**
     * the value as it's being provided by the datasource
     */
    rawValue: any;
}

/**
 * The configuration of the system
 */
export interface GridWrapperConfiguration {
    /**
     * Id of the grid
     */
    id: string;
    /**
     * Datasource Configuration
     */
    datasourceConfiguration?: DatasourceConfiguration;
    /**
     * UI Configuration
     */
    uiConfiguration?: UIConfiguration;
    swallowFormattingError?: boolean;
    swallowFormattingErrorCellValue?: string;
}

/**
 * The different datasources that are supported
 */
export enum DatasourceType {
    InMemory = 'InMemory',
    GraphQL = 'GraphQL',
    DRA = 'DRA',
}

/**
 * The configuration for the datasource
 */
export interface DatasourceConfiguration {
    /**
     * the type of datasource
     */
    datasourceType: DatasourceType;
    /**
     * column definitions using ag-Grid schema
     */
    columns?: (ColDef | ColGroupDef)[];
    /**
     * The url endpoint if using an Ajax endpoint or DRA
     */
    url?: string;
    /**
     * The authUrl when using a DRA datasource
     */
    authUrl?: string;
    /**
     * When closing the DRAView
     */
    onCloseView?: (eventBus: any) => void;
    /**
     * When connecting the DRASource
     */
    onConnect?: (eventBus: any) => void;
    /**
     * When disconnecting the DRASource
     */
    onDisconnect?: (eventBus: any) => void;
    /**
     * Initial expansion depth of the DRA datasource
     */
    defaultExpansionDepth?: number;
    /**
     * Protocols to use for the DRASource
     */
    protocols?: string[];
    /**
     * Used to provide the DraSetup when using DRA datasource
     */
    code?: (
        dataSourceResource: any,
        datasourceConfig: DatasourceConfiguration
    ) => Promise<DraSetupReturn>;
    /**
     * Fixed filter to use for DRA
     */
    fixedDRAFilter?: ExpressionQuery;
    /**
     * Callback that contains an expression to modify before sending to DRA
     */
    draFilterTransformer?: (expression: ExpressionQuery) => ExpressionQuery;
    /**
     * Do not use. Private
     */
    datasource?: typeof DraDatasource;
    /**
     * Do not use. Private
     */
    viewConfig?: typeof DraViewConfig;
    /**
     * Do not use. Private
     */
    requestMiddleware?: (request: XMLHttpRequest) => Promise<void>;
    /**
     * Do not use. Private
     */
    getDraUser?: () => Promise<any>;
}

export interface UIConfiguration {
    /**
     * The debounce time for the searchable plugin
     */
    debounceTime: number;
}

/**
 * Argument of the event Column Resized
 */
export interface GridWrapperColumnResizedEventArgs {
    columnId: string;
}

/**
 * Argument of the event Column Pinned
 */
export interface ColumnPinnedEventArgs {
    columnId: string;
}

/**
 * Argument of the event DataUpdated
 */
export interface DataUpdatedEventArgs {
    oldValue: any;
    newValue: any;
    columnId: string;
    // will need to add wither the rownode or a primary key as I'm keen to not have ag-grid specific object leaking outside to the plugins
}
export interface EditorKeyDownEventArgs {
    keyboardEvent: KeyboardEvent;
    currentEditorValue: string;
    rowIndex: number | null;
    columnId: string;
}

export interface PivotState {
    isPivotMode: boolean;
    pivotColumns: string[] | null;
}

export interface ColumnGroupState {
    groupId: string;
    open: boolean;
}
export const AG_BODY_VIEWPORT_CLASS = 'ag-body-viewport';
export const AG_HEADER_VIEWPORT_CLASS = 'ag-header-viewport';
export const DEFAULT_SWALLOW_FORMATTING_ERROR_CELL_VALUE = '#Error';
// This value is the value from AutoGroupColService.GROUP_AUTO_COLUMN_ID but it's not being exposed. Might need to ask ag-Grid to export it
export const agGridAutoColumn = 'ag-Grid-AutoColumn';
export const defaultDebounceTime = 400;
