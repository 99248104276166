import endPointsMapper from './endPointsMapper';

export default {
  DESKTOP_SERVICE: {
    LOCAL_SERVICE_URL: 'http://localhost:3000',
    DEV_SERVICE_URL: '',
    PROD_SERVICE_URL: '',
    TEST_SERVICE_URL: '',
  },
  TABLET_SERVICE: {
    LOCAL_SERVICE_URL: 'http://localhost:3000',
    DEV_SERVICE_URL: '',
    PROD_SERVICE_URL: '',
    TEST_SERVICE_URL: '',
  },
  'load': [endPointsMapper.FETCH_LABELS]
};
