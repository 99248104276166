import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import {amountFormatter} from '../../../utils/amountFormatter';
import {getUpdatedGlossaryText} from '../../../utils/commonUtils';
import './index.scss';

const PortfolioTotalHeader = ({labels, totals, baseCurrency}) => {
  const currencyType = ` ${(baseCurrency || 'USD')}`;
  const title = labels.tkPortfolioTotalTitleText + getUpdatedGlossaryText({glossaryTxt: labels.tkTitleText});
  return (
    <div className="portfolioTotal">
      <div className="portfolioTotal__head">
        <div className="portfolioTotal__title">
          <h1>{labels.tkYourPortfolio || '--'}</h1>
          <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(title || '--')}} />
        </div>
        <div className="portfolioTotal__data">
          <div className="portfolioTotal__data-mtd-accrual">
            <h2>{((totals.mtdAccrualBaseCcy === undefined)) ? '--' : (amountFormatter(totals.mtdAccrualBaseCcy, 2) + currencyType)}</h2>
            <p>{labels.tkMTDAccrualBaseCCY || '--'}</p>
          </div>
          <div className="portfolioTotal__data-projected-closing">
            <h2>{((totals.projectedClosingBalanceBaseCcy === undefined)) ? '--' : (amountFormatter(totals.projectedClosingBalanceBaseCcy, 2) + currencyType)}</h2>
            <p>{labels.tkProjectedClosingBalanceBaseCcy || '--'}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

PortfolioTotalHeader.propTypes = {
  labels: PropTypes.object.isRequired,
  totals: PropTypes.object.isRequired,
  baseCurrency: PropTypes.string
};

export default PortfolioTotalHeader;
