import { CssClassDefinitionsObject, CssProperties } from '../types';
import { interpolationRe } from './interpolation-regex';
import { isNestedPropertiesObj } from './util';

/**
 * Given a CssClassDefinitionsObject which may have nested selectors which
 * rely on other CSS classes in the object, sorts the class names in order.
 *
 * For example, when given:
 *
 *     {
 *         class1: {
 *             color: 'red',
 *
 *             '& #{class2}': {
 *                 color: 'green'
 *             }
 *         }
 *         class2: {
 *             color: 'yellow'
 *         }
 *         class3: {
 *             color: 'pink',
 *
 *             '#{class1} &': {
 *                 color: 'purple'
 *             }
 *         }
 *     }
 *
 * Then the resulting class order would be:
 *
 *     ['class2', 'class1', 'class3']
 *
 * because 'class1' depends on 'class2', and 'class3' depends on 'class1'.
 *
 * We need to create 'class2' with Emotion first and retrieve its computed CSS
 * class name so that we can create 'class1', and then we need 'class1's
 * computed class name so we can create 'class3'.
 *
 * @param classDefinitionsObject The object which contains all of the CSS class
 *   definitions for the StyleSheet
 */
export function getClassNamesInDependencyOrder<ClassNames extends string>(
    classDefinitionsObject: CssClassDefinitionsObject<ClassNames>
): ClassNames[] {
    const classNames = Object.keys(classDefinitionsObject) as ClassNames[];
    const classNamesSet = new Set<string>(classNames);
    const graph = new DirectedGraph(classNames);

    // Go through each class definition, and find its dependencies on other
    // classes
    classNames.forEach(className => {
        const nestedSelectors = getNestedSelectors(classDefinitionsObject[className]);

        nestedSelectors.forEach(nestedSelector => {
            const classDependencies = parseClassDependencies(nestedSelector);

            classDependencies.forEach(depClassName => {
                if (depClassName === className) {
                    throw new Error(
                        `The dependency on the class name '${depClassName}' refers ` +
                            `to itself in the selector: '${nestedSelector}'. Use the '&' ` +
                            `character to refer to the parent class/selector`
                    );
                } else if (!classNamesSet.has(depClassName)) {
                    throw new Error(
                        `The dependency on the class name '${depClassName}' does ` +
                            `not refer to another class in the StyleSheet. The full ` +
                            `selector is: '${nestedSelector}'`
                    );
                } else {
                    graph.addEdge(className, depClassName as ClassNames);
                }
            });
        });
    });

    return topologicalSort(graph);
}

/**
 * Mini adjacency list graph implementation just to come up with the topological
 * sort of the CSS class names in dependency order
 */
class DirectedGraph<ClassNames extends string> {
    public readonly vertices: Vertices<ClassNames>;

    constructor(classNames: ClassNames[]) {
        // Initialize all vertices to empty set for their adjacency lists
        this.vertices = {} as Vertices<ClassNames>;
        classNames.forEach(className => {
            this.vertices[className] = new Set();
        });
    }

    public addEdge(from: ClassNames, to: ClassNames) {
        this.vertices[from].add(to);
    }

    public getVertices(): ClassNames[] {
        return Object.keys(this.vertices) as ClassNames[];
    }

    // Returns the vertices adjacent to the given `vertex`
    public adj(vertex: ClassNames) {
        return [...this.vertices[vertex]];
    }
}

type Vertices<ClassNames extends string> = { [vertex in ClassNames]: Set<ClassNames> };

/**
 * Sorts the nodes of the dependency order. Throws an error if the references
 * are circular.
 *
 * @throws Error if a cycle in the references is detected
 */
function topologicalSort<ClassNames extends string>(
    graph: DirectedGraph<ClassNames>
): ClassNames[] {
    const seen = {} as { [vertex in ClassNames]?: true };

    // For detecting a cycle
    const onStack = {} as { [vertex in ClassNames]?: boolean };

    // edgeTo[w] = v means v->w in the graph
    // This is used to report the cycle if one is found
    const edgeTo = {} as { [vertex in ClassNames]?: ClassNames };

    // Will be the class names in dependency order
    const result: ClassNames[] = [];

    graph.getVertices().forEach(vertex => {
        if (!seen[vertex]) dfs(vertex);
    });
    return result;

    // depth-first search
    function dfs(vertex: ClassNames) {
        seen[vertex] = true;
        onStack[vertex] = true;

        graph.adj(vertex).forEach(w => {
            if (!seen[w]) {
                edgeTo[w] = vertex;
                dfs(w);
            } else if (onStack[w]) {
                // A circular reference exists
                const cycle: ClassNames[] = [];
                for (let x = vertex; x !== w; x = edgeTo[x]!) {
                    cycle.push(x);
                }
                cycle.push(w);
                cycle.push(vertex);
                throw new Error(
                    `A cycle was detected in nested selectors: #{${cycle.join(
                        '} -> #{'
                    )}}. The CSS class references used in nested selectors cannot reference each other circularly`
                );
            }
        });

        onStack[vertex] = false;
        result.push(vertex);
    }
}

/**
 * Parses dependencies on any other classes in the given CSS selector. For
 * instance:
 *
 *     parseClassDependencies('&:hover #{class1} .something #{class2}');
 *
 * this will return:
 *
 *     ['class1', 'class2']
 */
export function parseClassDependencies(selector: string): string[] {
    const result: string[] = [];

    let match: RegExpExecArray | null;
    while ((match = interpolationRe.exec(selector))) {
        result.push(match[1]);
    }
    return result;
}

/**
 * Given a CssProperties object, grabs the selector strings from every nested
 * selector at all levels deep.
 */
function getNestedSelectors(cssProperties: CssProperties): string[] {
    const result: string[] = [];

    Object.keys(cssProperties).forEach(prop => {
        const value = cssProperties[prop];

        if (isNestedPropertiesObj(value)) {
            const selector = prop; // for clarity

            result.push(selector);
            result.push(...getNestedSelectors(value));
        }
    });
    return result;
}
