import React from 'react';
import PropTypes from 'prop-types';
import CustomScrollbar from 'react-scrollbars-custom';
import './index.scss';

const withRenderer = (cssClass) => {
  const Sub = (props) => {
    const {elementRef, ...restProps} = props;
    return (
      <div
        {...restProps}
        className={cssClass}
        ref={elementRef}
      />
    );
  };
  Sub.propTypes = {
    elementRef: PropTypes.element
  };
  return Sub;
};

export const withScroll = (CustomComponent, config, scrollProps = {}) => (props) => {
  const defaultOptions = {
    cssTrackX: 'custom-scroll__track custom-scroll__track--X',
    cssTrackY: 'custom-scroll__track custom-scroll__track--Y',
    cssContent: 'custom-scroll__content',
    cssWrapper: 'custom-scroll__wrapper',
    cssThumbX: 'custom-scroll__thumb custom-scroll__thumb--X',
    cssThumbY: 'custom-scroll__thumb custom-scroll__thumb--Y',
    overflowX: 'hidden',
    overflowY: 'scroll',
    style: {
      width: '100%',
      'maxHeight': '250px'
    }
  };

  const options = {...defaultOptions, ...config};
  const {
    cssTrackX, cssTrackY, cssThumbX, cssThumbY, cssContent, cssWrapper, overflowX,
    overflowY, style, ...restProps
  } = options;
  return (
    <CustomScrollbar
      {...restProps}
      {...scrollProps}
      trackXRenderer={overflowX === 'hidden' ? () => null : withRenderer(cssTrackX)}
      trackYRenderer={overflowY === 'hidden' ? () => null : withRenderer(cssTrackY)}
      thumbXRenderer={withRenderer(cssThumbX)}
      thumbYRenderer={withRenderer(cssThumbY)}
      wrapperRenderer={withRenderer(cssWrapper)}
      contentRenderer={withRenderer(cssContent)}
      style={style}>
      <CustomComponent {...props} />
    </CustomScrollbar>
  );
};

const Scrollbar = ({config, scrollProps = {}, children}) => {
  const childrenArray = React.Children.toArray(children);

  // This component will have only one direct child.
  const firstChild  = childrenArray.length > 0 && childrenArray[0];
  const ChildComponent = firstChild.type;
  const {props} = firstChild;

  return withScroll(ChildComponent, config, scrollProps)(props)

};

Scrollbar.propTypes = {
  config: PropTypes.object,
  scrollProps: PropTypes.object,
  children: PropTypes.element.isRequired
};

export default Scrollbar;
