import { colors } from '@gs-ux-uitoolkit-common/design-system';
import { Theme } from '@gs-ux-uitoolkit-common/theme';
import { mix, rgba } from 'polished';
import { ButtonTheme } from './button-theme-types';

export function getButtonTheme(theme: Theme): ButtonTheme {
    const primaryBoldColors = theme.getColorInteractionShades('primary', 'bold');
    const primaryMinimalColors = theme.getColorInteractionShades('primary', 'minimal');

    const secondaryBoldColors = theme.getColorInteractionShades('secondary', 'bold');
    secondaryBoldColors.text =
        theme.colorMode == 'light' ? theme.text.primary : theme.text.secondary;
    const secondaryMinimalColors = theme.getColorInteractionShades('secondary', 'minimal');
    secondaryMinimalColors.text =
        theme.colorMode == 'light' ? theme.text.primary : theme.text.secondary;
    secondaryMinimalColors.border = theme.border.moderate;
    secondaryMinimalColors.active = rgba(
        theme.increaseContrast(theme.colorScheme.secondary, 2).toString(),
        theme.colorMode === 'light' ? 0.4 : 0.2
    );
    secondaryMinimalColors.hover = rgba(
        theme.increaseContrast(theme.colorScheme.secondary, 2).toString(),
        theme.colorMode === 'light' ? 0.2 : 0.1
    );

    const destructiveBoldColors = theme.getColorInteractionShades('error', 'bold');
    const destructiveMinimalColors = theme.getColorInteractionShades('error', 'minimal');

    const infoBoldColors = theme.getColorInteractionShades('info', 'bold');
    const infoMinimalColors = theme.getColorInteractionShades('info', 'minimal');

    return {
        action: {
            primary: {
                emphasis: {
                    bold: {
                        backgroundColor: primaryBoldColors.background,
                        backgroundHoverColor: primaryBoldColors.hover,
                        backgroundActiveColor: primaryBoldColors.active,
                        textColor: primaryBoldColors.text,
                    },
                    minimal: {
                        backgroundColor: primaryMinimalColors.background,
                        backgroundHoverColor: primaryMinimalColors.hover,
                        backgroundActiveColor: primaryMinimalColors.active,
                        textColor: primaryMinimalColors.text,
                    },
                    subtle: {
                        backgroundColor: primaryMinimalColors.background,
                        backgroundHoverColor: primaryMinimalColors.hover,
                        backgroundActiveColor: primaryMinimalColors.active,
                        borderColor: primaryMinimalColors.text,
                        textColor: primaryMinimalColors.text,
                    },
                },
            },
            secondary: {
                emphasis: {
                    bold: {
                        backgroundColor: secondaryBoldColors.background,
                        backgroundHoverColor: secondaryBoldColors.hover,
                        backgroundActiveColor: secondaryBoldColors.active,
                        textColor: secondaryBoldColors.text,
                    },
                    minimal: {
                        backgroundColor: secondaryMinimalColors.background,
                        backgroundHoverColor: secondaryMinimalColors.hover,
                        backgroundActiveColor: secondaryMinimalColors.active,
                        textColor: secondaryMinimalColors.text,
                    },
                    subtle: {
                        backgroundColor: secondaryMinimalColors.background,
                        backgroundHoverColor: secondaryMinimalColors.hover,
                        backgroundActiveColor: secondaryMinimalColors.active,
                        borderColor: secondaryMinimalColors.border,
                        textColor: secondaryMinimalColors.text,
                    },
                },
            },
            destructive: {
                emphasis: {
                    bold: {
                        backgroundColor: destructiveBoldColors.background,
                        backgroundHoverColor: destructiveBoldColors.hover,
                        backgroundActiveColor: destructiveBoldColors.active,
                        textColor: destructiveBoldColors.text,
                    },
                    minimal: {
                        backgroundColor: destructiveMinimalColors.background,
                        backgroundHoverColor: destructiveMinimalColors.hover,
                        backgroundActiveColor: destructiveMinimalColors.active,
                        textColor: destructiveMinimalColors.text,
                    },
                    subtle: {
                        backgroundColor: destructiveMinimalColors.background,
                        backgroundHoverColor: destructiveMinimalColors.hover,
                        backgroundActiveColor: destructiveMinimalColors.active,
                        borderColor: destructiveMinimalColors.text,
                        textColor: destructiveMinimalColors.text,
                    },
                },
            },
            info: {
                emphasis: {
                    bold: {
                        backgroundColor: infoBoldColors.background,
                        backgroundHoverColor: infoBoldColors.hover,
                        backgroundActiveColor: infoBoldColors.active,
                        textColor: infoBoldColors.text,
                    },
                    minimal: {
                        backgroundColor: infoMinimalColors.background,
                        backgroundHoverColor: infoMinimalColors.hover,
                        backgroundActiveColor: infoMinimalColors.active,
                        textColor: infoMinimalColors.text,
                    },
                    subtle: {
                        backgroundColor: infoMinimalColors.background,
                        backgroundHoverColor: infoMinimalColors.hover,
                        backgroundActiveColor: infoMinimalColors.active,
                        borderColor: infoMinimalColors.text,
                        textColor: infoMinimalColors.text,
                    },
                },
            },
            contrast: {
                emphasis: {
                    bold: {
                        backgroundColor: colors.white,
                        backgroundHoverColor: mix(0.1, colors.gray100, colors.white),
                        backgroundActiveColor: mix(0.2, colors.gray100, colors.white),
                        textColor: colors.gray110,
                    },
                    minimal: {
                        backgroundColor: 'transparent',
                        backgroundHoverColor: rgba(colors.white, 0.1),
                        backgroundActiveColor: rgba(colors.white, 0.2),
                        textColor: colors.white,
                    },
                    subtle: {
                        backgroundColor: 'transparent',
                        backgroundHoverColor: rgba(colors.white, 0.1),
                        backgroundActiveColor: rgba(colors.white, 0.2),
                        borderColor: colors.white,
                        textColor: colors.white,
                    },
                },
            },
        },
        disabled: {
            primary: {
                emphasis: {
                    bold: {
                        backgroundColor: rgba(primaryBoldColors.background.toString(), 0.3),
                        textColor: rgba(primaryBoldColors.text.toString(), 0.8),
                        borderColor: 'transparent',
                    },
                    minimal: {
                        backgroundColor: 'transparent',
                        textColor: rgba(primaryMinimalColors.text.toString(), 0.4),
                        borderColor: 'transparent',
                    },
                    subtle: {
                        backgroundColor: 'transparent',
                        borderColor: rgba(primaryMinimalColors.text.toString(), 0.4),
                        textColor: rgba(primaryMinimalColors.text.toString(), 0.4),
                    },
                },
            },
            secondary: {
                emphasis: {
                    bold: {
                        backgroundColor: rgba(secondaryBoldColors.background.toString(), 0.4),
                        textColor: rgba(secondaryBoldColors.text.toString(), 0.5),
                        borderColor: 'transparent',
                    },
                    minimal: {
                        backgroundColor: 'transparent',
                        textColor: rgba(secondaryMinimalColors.text.toString(), 0.4),
                        borderColor: 'transparent',
                    },
                    subtle: {
                        backgroundColor: 'transparent',
                        borderColor: rgba(secondaryMinimalColors.text.toString(), 0.4),
                        textColor: rgba(secondaryMinimalColors.text.toString(), 0.5),
                    },
                },
            },
            destructive: {
                emphasis: {
                    bold: {
                        backgroundColor: rgba(destructiveBoldColors.background.toString(), 0.3),
                        textColor: rgba(destructiveBoldColors.text.toString(), 0.8),
                        borderColor: 'transparent',
                    },
                    minimal: {
                        backgroundColor: 'transparent',
                        textColor: rgba(destructiveMinimalColors.text.toString(), 0.4),
                        borderColor: 'transparent',
                    },
                    subtle: {
                        backgroundColor: 'transparent',
                        borderColor: rgba(destructiveMinimalColors.text.toString(), 0.4),
                        textColor: rgba(destructiveMinimalColors.text.toString(), 0.4),
                    },
                },
            },
            info: {
                emphasis: {
                    bold: {
                        backgroundColor: rgba(infoBoldColors.background.toString(), 0.3),
                        textColor: rgba(infoBoldColors.text.toString(), 0.8),
                        borderColor: 'transparent',
                    },
                    minimal: {
                        backgroundColor: 'transparent',
                        textColor: rgba(infoMinimalColors.text.toString(), 0.4),
                        borderColor: 'transparent',
                    },
                    subtle: {
                        backgroundColor: 'transparent',
                        borderColor: rgba(infoMinimalColors.text.toString(), 0.4),
                        textColor: rgba(infoMinimalColors.text.toString(), 0.4),
                    },
                },
            },
            contrast: {
                emphasis: {
                    bold: {
                        backgroundColor: rgba(colors.white, 0.4),
                        textColor: rgba(colors.blue100, 0.4),
                        borderColor: 'transparent',
                    },
                    minimal: {
                        backgroundColor: 'transparent',
                        textColor: rgba(colors.white, 0.4),
                        borderColor: 'transparent',
                    },
                    subtle: {
                        backgroundColor: 'transparent',
                        borderColor: rgba(colors.white, 0.4),
                        textColor: rgba(colors.white, 0.4),
                    },
                },
            },
        },
        focus: {
            primary: {
                backgroundColor: primaryBoldColors.background,
                textColor: primaryBoldColors.text,
                borderColor: theme.colorScheme.primary,
            },
            secondary: {
                backgroundColor: secondaryBoldColors.background,
                textColor: secondaryBoldColors.text,
                borderColor: theme.colorScheme.primary,
            },
            destructive: {
                backgroundColor: destructiveBoldColors.background,
                textColor: destructiveBoldColors.text,
                borderColor: theme.colorScheme.primary,
            },
            info: {
                backgroundColor: infoBoldColors.background,
                textColor: infoBoldColors.text,
                borderColor: theme.colorScheme.primary,
            },
            contrast: {
                backgroundColor: colors.white,
                textColor: colors.blue100,
                borderColor: colors.white,
            },
        },
    };
}
