import React, {useEffect, useRef} from 'react';
import DOMPurify from 'dompurify';
import PropTypes from "prop-types";
import isArray from 'lodash/isArray';
import HighChartComponent from './HighCharts';
import {useChartContext} from '../../context';
import {ChartActionTypes} from '../../actions';
import {Conditional} from '../../../../components/core/Conditional';
import translator from '../../../../services/translator';
import history from '../../../../utils/history';
import {getAppRoute} from '../../../../utils/commonUtils';
import AppConstants from '../../../../constants/appConstants';
import {FUND_TRACKER} from "../../../../constants/pageConstants";
import {verifyThresholds} from "../../utils";
import {useAsync} from '../../reducers/Hooks'

const {translate: t} = translator;

const HighStock = ({
   primaryShareClassId,
   onChartDateRangeChange,
   onChartZoomRangeChange
}) => {
  const {state, dispatch: chartDispatch} = useChartContext();
  const {
    chartOptions, isFundOrBenchmarkDataMissing, isFundOrBenchmarkSeriesDataMissing,
    isMetricDataMissing, metrics: selectedMetrics, thresholdList = [], selection,
    benchmarks: selectedFundsOrBenchMarks = [], selectedZoomOption, selectedChartDateRange,
    allShareClassData
  } = state;
  const chart = useRef(null);
  const chartComponent = useRef(null);
  const isBenchMarksCompareMode = (selection === FUND_TRACKER.BENCHMARKS);
  const {run} = useAsync(chartDispatch);
  const isMetricsCompareMode = (selection === FUND_TRACKER.METRICS);

  useEffect(() => {
    chart.current = chartComponent.current.chart;
    chartDispatch({
      type: ChartActionTypes.registerChart,
      data: {
        chart: chart.current
      }
    });
  }, []);

  const redirectToFundFinder = (e) => {
    if (e.target && e.target.className === 'link__primary') {
      history.push(getAppRoute(AppConstants.FUND_FINDER_ROUTE));
    }
  };

  // ****************************************************************************************
  //                     Update the threshold config in the chart options
  // ****************************************************************************************
  const getUpdatedChartOptions = () => {
    if (isArray(chartOptions.yAxis)) {
      // Verify if all the thresholds are same, and also get the lower and upper thresholds for the Primary Fund Selected
      const {
        isAllThresholdHasSameLimits,
        baseLowerThreshold,
        baseUpperThreshold
      } = verifyThresholds(
        primaryShareClassId,
        selectedFundsOrBenchMarks,
        selectedMetrics,
        thresholdList,
        isBenchMarksCompareMode
      );
      const plotLines = [];
      if (isAllThresholdHasSameLimits && !isMetricsCompareMode) {
        if (baseLowerThreshold) {
          plotLines.push({
            value: baseLowerThreshold,
            color: '#e86427',
            dashStyle: 'shortdash',
            width: 3,
            label: {
              useHTML: true,
              text: `<div style="color: white; background: #e86427; padding: 0 10px 0 10px">Lower Bound: ${baseLowerThreshold}</div>`,
              x: -2
            }
          });
        }
        if (baseUpperThreshold) {
          plotLines.push({
            value: baseUpperThreshold,
            color: '#077d55',
            dashStyle: 'shortdash',
            width: 3,
            label: {
              useHTML: true,
              text: `<div style="color: white; background: #077d55; padding: 0 10px 0 10px">Upper Bound: ${baseUpperThreshold}</div>`,
              x: -2
            }
          });
        }
      }
      chartOptions.yAxis = chartOptions.yAxis.map(item => {
        if (item.opposite) {
          return {...item, plotLines}
        }
        return item;
      });
    }

    // Preserve the user selected zoom option
    if (selectedZoomOption !== undefined &&
      chartOptions.rangeSelector.selected !== selectedZoomOption) {
      chartOptions.rangeSelector.selected = selectedZoomOption;
    }

    // If user has the custom chart date selected
    if (selectedChartDateRange) {
      const {fromDate, toDate} = selectedChartDateRange;
      chartOptions.xAxis = {
        type: 'datetime',
        min: fromDate,
        max: toDate
      };
    }

    return chartOptions;
  };

  return (
    <>
      <HighChartComponent
        chartComponent={chartComponent}
        chartOptions={getUpdatedChartOptions()}
        chartDispatch={chartDispatch}
        onChartDateRangeChange={onChartDateRangeChange}
        onChartZoomRangeChange={onChartZoomRangeChange}
        selectedZoomOption={selectedZoomOption}
        isFundOrBenchmarkSeriesDataMissing={isFundOrBenchmarkSeriesDataMissing}
        isMetricDataMissing={isMetricDataMissing}
        allShareClassData={allShareClassData}
        run={run}
      />
      <Conditional condition={!primaryShareClassId}>
        <div
          onClick={redirectToFundFinder}
          className="fund-selection-missing"
          dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(t('tkSelectFund'))}}
        />
      </Conditional>
      <Conditional condition={isFundOrBenchmarkDataMissing}>
        <div className="fund-selection-missing">{t('tkFundBenchmarkUnavailableToTrack')}</div>
      </Conditional>
    </>
  );
};

HighStock.propTypes = {
  primaryShareClassId: PropTypes.string,
  onChartDateRangeChange: PropTypes.func,
  onChartZoomRangeChange: PropTypes.func
};

export default HighStock;
