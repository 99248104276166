export interface ColumnHint {
    /**
     * column to apply ColumnHint to
     */
    columnId: string;
    /**
     * Hints for the column
     */
    hints: HintList;
    /**
     * Pivot keys for generated pivoted columns
     */
    pivotKeys?: string[];
}

export interface HintList {
    alignment?: Alignment;
    bg?: string;
    bgHeaderGroups?: string;
    columnWidth?: number;
    cssClasses?: string; // This was just a string in the original Dash Grid api, would make more sense to be an array of strings
    editable?: boolean;
    enabled?: boolean;
    fg?: string;
    fontSize?: string;
    fontWeight?: string | number;
    format?: ColumnHintFormat;
    formatFunction?: [string, string, string, [string]];
    groupingDelimiter?: string;
    headerTooltip?: string;
    name?: string;
    styleColumn?: string;
    tooltip?: string;
    verticalGridLines?: boolean;
    [key: string]:
        | boolean
        | string
        | number
        | HintList
        | ColumnHintFormat
        | undefined
        | [string, string, string, [string]];
    columnHeaderWrapping?: boolean;
}

export type Alignment = 'left' | 'right' | 'center';

export interface ValueMap {
    value: string;
    replacement: string;
}

export enum DefaultColumnHintTypes {
    Number = 'numberDefaults',
    Date = 'dateDefaults',
    DateTime = 'dateTimeDefaults',
    String = 'stringDefaults',
    Default = 'defaults',
}

export interface ColumnHintDefaults extends HintList {
    numberDefaults?: HintList;
    stringDefaults?: HintList;
    dateDefaults?: HintList;
    dateTimeDefaults?: HintList;
}
export interface ColumnHintFormat {
    multiplier?: number;
    d3Specifier?: string;
    parenthesesIfNegative?: boolean;
    redIfNegative?: boolean;
    namedSpecifier?: NumberNamedPattern | DateNamedPattern;
    currencySymbol?: string;
    prefix?: string;
    postfix?: string;
    preProcessor?: 'text' | 'seconds';
    preProcessorD3Specifier?: string;
    invalidDateText?: string;
    type?: ColumnHintType;
    valueMapping?: ValueMap[];
    specialNumbersFormat?: SpecialNumbersFormat;
    hyperlinkFormat?: HyperlinkFormat;
    dateFormat?: DateFormat;
}

export enum DateFormat {
    UTC = 'UTC',
    Local = 'Local',
}

export interface HyperlinkFormat {
    // row group will enable hyperlinks on row groups
    rowGroup?: boolean;
    target?: string;
    replace?: string;
    url: string;
}

export interface SpecialNumbersFormat {
    infinity?: CellFormat;
    nan?: CellFormat;
    null?: CellFormat;
    empty?: CellFormat;
    undefined?: CellFormat;
    zero?: CellFormat;
}
export interface CellFormat {
    text?: string;
    cssClass?: string;
}
export enum ColumnHintType {
    Date = 'date',
    Numeric = 'numeric',
    String = 'string',
}
export enum DateNamedPattern {
    // DateTime Named Patterns
    Date = 'date',
    DateTime = 'dateTime',
    Time = 'time',
    DayMonthYear = 'dayMonthYear',
}

export enum NumberNamedPattern {
    // Numbers Named Patterns
    Currency = 'currency',
    ZeroPercentage = 'zeroPercentage',
    OnePercentage = 'onePercentage',
    TwoPercentage = 'twoPercentage',
    ThreePercentage = 'threePercentage',
    FourPercentage = 'fourPercentage',
    LargeNumber = 'largeNumber',
    ZeroDP = 'zeroDP',
    OneDP = 'oneDP',
    TwoDP = 'twoDP',
    ThreeDP = 'threeDP',
    FourDP = 'fourDP',
    FiveDP = 'fiveDP',
}
