import { fontFamily, fontWeight } from './typography';
import {
    withBasisFallback,
    withGoldmanSansFallback,
    withGoldmanSansVariableFallback,
    withMonospaceFallback,
    withSabonFallback,
} from './typography-utils';

export const typographyFonts: TypographyFontMap = {
    display01: 'goldmanSansLight',
    display02: 'goldmanSansLight',
    display03: 'goldmanSansLight',
    heading01: 'goldmanSansMedium',
    heading02: 'goldmanSansMedium',
    heading03: 'goldmanSansMedium',
    heading04: 'goldmanSansMedium',
    heading05: 'goldmanSansMedium',
    heading06: 'goldmanSansMedium',
    heading07: 'goldmanSansMedium',

    display01Serif: 'sabon',
    display02Serif: 'sabon',
    display03Serif: 'sabon',
    heading01Serif: 'sabon',
    heading02Serif: 'sabon',
    heading03Serif: 'sabon',
    heading04Serif: 'sabon',

    display01Basis: 'basis',
    display02Basis: 'basis',
    display03Basis: 'basis',
    heading01Basis: 'basis',
    heading02Basis: 'basis',
    heading03Basis: 'basis',
    heading04Basis: 'basis',

    body01: 'goldmanSans',
    body02: 'goldmanSans',
    body03: 'goldmanSans',
    body04: 'goldmanSans',
    body05: 'goldmanSans',
    ui01: 'goldmanSans',
    ui02: 'goldmanSansMedium',
    caption01: 'goldmanSans',
    code01: 'robotoMono',
    code02: 'robotoMono',
};

export const typographyFontFamilyVariants: {
    [name in TypographyFontFamily]: TypographyFontStyles;
} = {
    goldmanSansLight: {
        regular: {
            fontFamily: withGoldmanSansFallback(fontFamily.goldmanSans),
            fontWeight: fontWeight.light,
        },
        bold: {
            fontFamily: withGoldmanSansFallback(fontFamily.goldmanSans),
            fontWeight: fontWeight.regular,
        },
        italic: {
            // the GoldmanSans Variable italic font type is used since there is no font file for 'Goldman Sans Light Italic'
            fontFamily: withGoldmanSansVariableFallback(fontFamily.goldmanSansVariableItalic),
            fontWeight: fontWeight.light,
        },
        boldItalic: {
            fontFamily: withGoldmanSansFallback(fontFamily.goldmanSansVariableItalic),
            fontWeight: fontWeight.regular,
        },
    },
    goldmanSans: {
        regular: {
            fontFamily: withGoldmanSansFallback(fontFamily.goldmanSans),
            fontWeight: fontWeight.regular,
        },
        bold: {
            fontFamily: withGoldmanSansFallback(fontFamily.goldmanSans),
            fontWeight: fontWeight.medium,
        },
        italic: {
            fontFamily: withGoldmanSansFallback(fontFamily.goldmanSans),
            fontWeight: fontWeight.regular,
        },
        boldItalic: {
            fontFamily: withGoldmanSansFallback(fontFamily.goldmanSans),
            fontWeight: fontWeight.medium,
        },
    },
    goldmanSansMedium: {
        regular: {
            fontFamily: withGoldmanSansFallback(fontFamily.goldmanSans),
            fontWeight: fontWeight.medium,
        },
        bold: {
            fontFamily: withGoldmanSansFallback(fontFamily.goldmanSans),
            fontWeight: fontWeight.bold,
        },
        italic: {
            fontFamily: withGoldmanSansFallback(fontFamily.goldmanSansVariableItalic),
            fontWeight: fontWeight.medium,
        },
        boldItalic: {
            fontFamily: withGoldmanSansFallback(fontFamily.goldmanSansVariableItalic),
            fontWeight: fontWeight.bold,
        },
    },
    robotoMono: {
        regular: {
            fontFamily: withMonospaceFallback(fontFamily.mono),
            fontWeight: fontWeight.regular,
        },
        bold: {
            fontFamily: withMonospaceFallback(fontFamily.robotoMonoMedium),
            fontWeight: fontWeight.medium,
        },
        italic: {
            fontFamily: withMonospaceFallback(fontFamily.robotoMonoItalic),
            fontWeight: fontWeight.regular,
        },
        boldItalic: {
            fontFamily: withMonospaceFallback(fontFamily.robotoMonoMediumItalic),
            fontWeight: fontWeight.medium,
        },
    },
    sabon: {
        regular: {
            fontFamily: withSabonFallback(fontFamily.sabonRoman),
            fontWeight: fontWeight.regular,
        },
        bold: {
            fontFamily: withSabonFallback(fontFamily.sabonBold),
            fontWeight: fontWeight.bold,
        },
        italic: {
            fontFamily: withSabonFallback(fontFamily.sabonItalic),
            fontWeight: fontWeight.regular,
        },
        boldItalic: {
            fontFamily: withSabonFallback(fontFamily.sabonBoldItalic),
            fontWeight: fontWeight.bold,
        },
    },
    basis: {
        regular: {
            fontFamily: withBasisFallback(fontFamily.basis),
            fontWeight: fontWeight.regular,
        },
        bold: {
            fontFamily: withBasisFallback(fontFamily.basisBold),
            fontWeight: fontWeight.bold,
        },
        italic: {
            fontFamily: withBasisFallback(fontFamily.basisItalic),
            fontWeight: fontWeight.regular,
        },
        boldItalic: {
            fontFamily: withBasisFallback(fontFamily.basisBoldItalic),
            fontWeight: fontWeight.bold,
        },
    },
};

export const typographyVariants: { [name in Typography]: TypographyVariant } = {
    display01: {
        ...typographyFontFamilyVariants[typographyFonts['display01']].regular,
        fontSize: '78px',
        lineHeight: '104px',
        letterSpacing: '0.2px',
    },
    display02: {
        ...typographyFontFamilyVariants[typographyFonts['display02']].regular,
        fontSize: '60px',
        lineHeight: '80px',
        letterSpacing: '0.2px',
    },
    display03: {
        ...typographyFontFamilyVariants[typographyFonts['display03']].regular,
        fontSize: '48px',
        lineHeight: '64px',
        letterSpacing: '0.2px',
    },
    heading01: {
        ...typographyFontFamilyVariants[typographyFonts['heading01']].regular,
        fontSize: '40px',
        lineHeight: '52px',
        letterSpacing: '0.2px',
    },
    heading02: {
        ...typographyFontFamilyVariants[typographyFonts['heading02']].regular,
        fontSize: '32px',
        lineHeight: '40px',
        letterSpacing: '0.2px',
    },
    heading03: {
        ...typographyFontFamilyVariants[typographyFonts['heading03']].regular,
        fontSize: '24px',
        lineHeight: '32px',
        letterSpacing: '0.2px',
    },
    heading04: {
        ...typographyFontFamilyVariants[typographyFonts['heading04']].regular,
        fontSize: '20px',
        lineHeight: '28px',
        letterSpacing: '0.2px',
    },
    heading05: {
        ...typographyFontFamilyVariants[typographyFonts['heading05']].regular,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0',
    },
    heading06: {
        ...typographyFontFamilyVariants[typographyFonts['heading06']].regular,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0',
    },
    heading07: {
        ...typographyFontFamilyVariants[typographyFonts['heading07']].regular,
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0',
    },

    display01Serif: {
        ...typographyFontFamilyVariants[typographyFonts['display01Serif']].regular,
        fontSize: '78px',
        lineHeight: '104px',
        letterSpacing: '0.2px',
    },
    display02Serif: {
        ...typographyFontFamilyVariants[typographyFonts['display02Serif']].regular,
        fontSize: '60px',
        lineHeight: '80px',
        letterSpacing: '0.2px',
    },
    display03Serif: {
        ...typographyFontFamilyVariants[typographyFonts['display03Serif']].regular,
        fontSize: '48px',
        lineHeight: '64px',
        letterSpacing: '0.2px',
    },
    heading01Serif: {
        ...typographyFontFamilyVariants[typographyFonts['heading01Serif']].regular,
        fontSize: '40px',
        lineHeight: '52px',
        letterSpacing: '0.2px',
    },
    heading02Serif: {
        ...typographyFontFamilyVariants[typographyFonts['heading02Serif']].regular,
        fontSize: '32px',
        lineHeight: '40px',
        letterSpacing: '0.2px',
    },
    heading03Serif: {
        ...typographyFontFamilyVariants[typographyFonts['heading03Serif']].regular,
        fontSize: '24px',
        lineHeight: '32px',
        letterSpacing: '0.2px',
    },
    heading04Serif: {
        ...typographyFontFamilyVariants[typographyFonts['heading04Serif']].regular,
        fontSize: '20px',
        lineHeight: '28px',
        letterSpacing: '0.2px',
    },

    display01Basis: {
        ...typographyFontFamilyVariants[typographyFonts['display01Basis']].regular,
        fontSize: '78px',
        lineHeight: '104px',
        letterSpacing: '0px',
    },
    display02Basis: {
        ...typographyFontFamilyVariants[typographyFonts['display02Basis']].regular,
        fontSize: '60px',
        lineHeight: '80px',
        letterSpacing: '0px',
    },
    display03Basis: {
        ...typographyFontFamilyVariants[typographyFonts['display03Basis']].regular,
        fontSize: '48px',
        lineHeight: '64px',
        letterSpacing: '0px',
    },
    heading01Basis: {
        ...typographyFontFamilyVariants[typographyFonts['heading01Basis']].regular,
        fontSize: '40px',
        lineHeight: '52px',
        letterSpacing: '0px',
    },
    heading02Basis: {
        ...typographyFontFamilyVariants[typographyFonts['heading02Basis']].regular,
        fontSize: '32px',
        lineHeight: '40px',
        letterSpacing: '0px',
    },
    heading03Basis: {
        ...typographyFontFamilyVariants[typographyFonts['heading03Basis']].regular,
        fontSize: '24px',
        lineHeight: '32px',
        letterSpacing: '0px',
    },
    heading04Basis: {
        ...typographyFontFamilyVariants[typographyFonts['heading04Basis']].regular,
        fontSize: '20px',
        lineHeight: '28px',
        letterSpacing: '0px',
    },

    body01: {
        ...typographyFontFamilyVariants[typographyFonts['body01']].regular,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0',
    },
    body02: {
        ...typographyFontFamilyVariants[typographyFonts['body02']].regular,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0',
    },
    body03: {
        ...typographyFontFamilyVariants[typographyFonts['body03']].regular,
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0',
    },
    body04: {
        ...typographyFontFamilyVariants[typographyFonts['body04']].regular,
        fontSize: '11px',
        lineHeight: '16px',
        letterSpacing: '0',
    },
    body05: {
        ...typographyFontFamilyVariants[typographyFonts['body05']].regular,
        fontSize: '10px',
        lineHeight: '12px',
        letterSpacing: '0',
    },
    ui01: {
        ...typographyFontFamilyVariants[typographyFonts['ui01']].regular,
        fontSize: '24px',
        lineHeight: '32px',
        letterSpacing: '0',
    },
    ui02: {
        ...typographyFontFamilyVariants[typographyFonts['ui02']].regular,
        fontSize: '11px',
        lineHeight: '16px',
        letterSpacing: '0',
    },
    caption01: {
        ...typographyFontFamilyVariants[typographyFonts['caption01']].regular,
        fontSize: '11px',
        lineHeight: '16px',
        letterSpacing: '0',
    },
    code01: {
        ...typographyFontFamilyVariants[typographyFonts['code01']].regular,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0',
    },
    code02: {
        ...typographyFontFamilyVariants[typographyFonts['code02']].regular,
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0',
    },
};

export interface TypographyVariant {
    fontFamily: string;
    fontWeight: number;
    fontSize: string;
    lineHeight: string;
    letterSpacing: string;
}

export type TypographyFontStyles = { [name in FontStyle]: TypographyFontVariant };

export type TypographyFontMap = { [name in Typography]: TypographyFontFamily };

export type TypographyFontFamily =
    | 'goldmanSansLight'
    | 'goldmanSans'
    | 'goldmanSansMedium'
    | 'robotoMono'
    | 'sabon'
    | 'basis';

export interface TypographyFontVariant {
    fontFamily: string;
    fontWeight: number;
}

export type FontStyle = 'regular' | 'bold' | 'italic' | 'boldItalic';
export const fontStyles: FontStyle[] = ['regular', 'bold', 'italic', 'boldItalic'];

export type TextTypography =
    | 'body01'
    | 'body02'
    | 'body03'
    | 'body04'
    | 'body05'
    | 'ui01'
    | 'ui02'
    | 'caption01'
    | 'code01'
    | 'code02';

export const textTypographies: TextTypography[] = [
    'body01',
    'body02',
    'body03',
    'body04',
    'body05',
    'ui01',
    'ui02',
    'caption01',
    'code01',
    'code02',
];

export type HeadingTypography =
    | 'display01'
    | 'display02'
    | 'display03'
    | 'heading01'
    | 'heading02'
    | 'heading03'
    | 'heading04'
    | 'heading05'
    | 'heading06'
    | 'heading07'
    | 'display01Serif'
    | 'display02Serif'
    | 'display03Serif'
    | 'heading01Serif'
    | 'heading02Serif'
    | 'heading03Serif'
    | 'heading04Serif'
    | 'display01Basis'
    | 'display02Basis'
    | 'display03Basis'
    | 'heading01Basis'
    | 'heading02Basis'
    | 'heading03Basis'
    | 'heading04Basis';

export const headingTypographies: HeadingTypography[] = [
    'display01',
    'display02',
    'display03',
    'heading01',
    'heading02',
    'heading03',
    'heading04',
    'heading05',
    'heading06',
    'heading07',
    'display01Serif',
    'display02Serif',
    'display03Serif',
    'heading01Serif',
    'heading02Serif',
    'heading03Serif',
    'heading04Serif',
    'display01Basis',
    'display02Basis',
    'display03Basis',
    'heading01Basis',
    'heading02Basis',
    'heading03Basis',
    'heading04Basis',
];

export type Typography = TextTypography | HeadingTypography;

/**
 * List of possible text color variants in the design system.
 */
export type TypographyColor =
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'link'
    | 'destructive'
    | 'reversed';
