
import {fundFinderAnalytics, SORT} from '../../../constants/pageConstants';
import { eventAnalytics as eventAnalyticsAction } from '../../../actions/sagas/index';


// All the helper function
const getColumnList = (headers) => {
  const visibleColumns = [];
  headers.forEach(header => {
    if (!header){
      return;
    }
    const { hide = false, headerName = ''} = header;
    if(!hide){
      visibleColumns.push(headerName);
    }
  });
  return visibleColumns.join('|');
};

const getAdjustedColumnsList = (headers) => {
  return getColumnList(headers.filter((header) => header && header.headerName && header.headerName.endsWith('AdjYield')));
};

const containsAdjustedFields = (headers) => {
  return headers && headers.length && headers.some((header) =>
    header && header.headerName && ( header.headerName.endsWith('AdjYield') || header.headerName.startsWith('Adjusted Yield') )
  );
};


export const getViewChangeAnalyticsData = (columnsString, viewName) => ({
  event: {
    eventName: fundFinderAnalytics.EVENT_VIEW_CHANGE,
    eventStage: 'view_changed',
    valueChosen: columnsString,
    viewChosen:  viewName
  }
});

export const getManagerChangeAnalyticsData = (data) => {
  const managerNames = data.map((item) => item.label );
  return ({
    event: {
      eventName: fundFinderAnalytics.EVENT_MANAGERS_CHANGE,
      valueChosen: managerNames.join('|')
    }
  });
};

export const getFundCurrenciesChangeAnalyticsData = (data) => {
  const managerNames = data.map((item) => item.label );
  return ({
    event: {
      eventName: fundFinderAnalytics.EVENT_CURRENCIES_CHANGE,
      valueChosen: managerNames.join('|')
    }
  });
};

export const getFundTypesChangeAnalyticsData = (data) => {
  const managerNames = data.map((item) => item.label );
  return ({
    event: {
      eventName: fundFinderAnalytics.EVENT_FUND_TYPES_CHANGE,
      valueChosen: managerNames.join('|')
    }
  });
};


export const getColumnSortingAnalyticsData = (order, columnName) => ({
  event: {
    eventName: fundFinderAnalytics.EVENT_COLUMN_SORTING,
    eventStage:  order === SORT.ASC ? 'ascending' : 'descending',
    valueChosen: columnName
  }
});

export const getColumnSearchAnalyticsData = (headerName) => ({
  event: {
    eventName: fundFinderAnalytics.EVENT_COLUMN_SEARCH,
    valueChosen: headerName
  }
});

export const getColumnSearchNoResultAnalyticsData = (val) => ({
  event: {
    eventName: fundFinderAnalytics.EVENT_SEARCH_COLUMN_0_RESULTS,
    valueChosen: val
  }
});

export const getGridEndLoadOnSearchAnalyticsData = (filterTerm, totalRecords, activeColumns, selectedView, selectedManagers) => {
  return ({
    event : {
      eventName: fundFinderAnalytics.EVENT_GRID_LOAD,
      eventType: 'search',
      filterTerm,
      numberOfRecords: totalRecords,
      viewChosen: selectedView,
      valueChosen: activeColumns,
      managers: selectedManagers,
      timeDiff: 0
    }
  });
};

export const getGridEndLoadAnalyticsData = (event, totalRecords, activeColumns, selectedView, selectedManagers, time, snackbarList) => {
  const snackbar = (event === 'load') ? {snackbarId : snackbarList.map((obj) => (obj.msgCopy || obj.message)).join(" | ")} : undefined;

  const data = {
    event : {
      eventName: fundFinderAnalytics.EVENT_GRID_LOAD,
      valueChosen: getColumnList(activeColumns),
      eventType: event,
      numberOfRecords: totalRecords,
      timeDiff: Date.now() - time,
      viewChosen: selectedView,
      managers: selectedManagers,
      ...(
        containsAdjustedFields(activeColumns)
        && {
          eventDesc: 'RebateFlowInteraction',
          eventDetail: 'FundFinderGridLoadedWithAdjustedYield',
          eventAction: 'GridLoad',
          componentInfo: {
            componentName: 'Fund Finder with Rebate'
          },
          satelliteTrackEventName: 'rebatevents'
        }
      )
    }
  };

  return (snackbar ? {...data, snackbar} : data);
};

export const getSaveViewAnalyticsData = (columns, view) => ({
  event: {
    eventName: fundFinderAnalytics.EVENT_VIEW_SAVE,
    eventStage: 'save_and_apply',
    valueChosen: getColumnList(columns),
    viewChosen: view.name,
    ...(
      containsAdjustedFields(columns)
      && {
        eventDesc: 'RebateFlowInteraction',
        eventDetail: getAdjustedColumnsList(columns),
        eventAction: 'Adjusted Yield Checkbox Saved Clicks',
        componentInfo: {
          componentName: 'Fund Finder - Save Settings'
        },
        satelliteTrackEventName: 'rebatevents'
      }
    )
  }
});

export const getExportViewAnalyticsData = (columns, viewName, fileType) => ({
  event: {
    eventName: fundFinderAnalytics.EVENT_EXPORT_VIEW,
    fileType,
    valueChosen: getColumnList(columns),
    viewChosen: viewName
  }
});

export const getColumnRearrangeAnalyticsData = (columnName) => ({
  event: {
    eventName: fundFinderAnalytics.EVENT_COLUMN_REARRANGE,
    valueChosen: columnName
  }
});

export const getColumnRemoveAnalyticsData = (columns, columnName, viewName) => ({
  event: {
    eventName: fundFinderAnalytics.EVENT_COLUMN_REMOVE,
    valueChosen: getColumnList(columns),
    viewChosen: viewName,
    columnRemoved: columnName
  }
});

export const dispatchAnalytics = (dispatch, data) => {
  dispatch(eventAnalyticsAction(data));
};
