import moment from 'moment';
import momentTimezone from 'moment-timezone';
import Constants, {RANGE_BUTTONS} from '../constants/appConstants';
import translator from '../services/translator';

const {translate: t} = translator;

const options = {
  month: 'short'
};

const getValuePrefixedByZero = (val) => {
  return val && val.length === 1 && Number(val) < 10 ? `0${val}` :  val;
};

const getFormattedDateByDelimiter = (date) => {
  const possibleDelimiters = ['/', '-', ' '];
  let formattedDate = date;
  possibleDelimiters.forEach(delimiter => {
    if (date.includes(delimiter)) {
      formattedDate = date
        .split(delimiter)
        .map(item => getValuePrefixedByZero(item))
        .join(delimiter);
    }
  });
  return formattedDate;
};

// forcing the `en-us` locale until locales are provided by services
export const formatStandardUSDate = javaUtcDate => {
  const jsDate = new Date(javaUtcDate);

  const year = jsDate.getFullYear().toString();
  const day = jsDate.getDate() < 10 ? `0${jsDate.getDate()}` :  jsDate.getDate().toString();
  const month = jsDate.toLocaleDateString(Constants.US_LOCALE, options).toUpperCase();

  return `${day}.${month}.${year}`;
};

export const isValidDate = (date, format) => {
  if (format) {
    return moment(getFormattedDateByDelimiter(date), format, true).isValid();
  }
  try {
    // Date.parse uses the MM/DD/YYYY as default formatter
    return !Number.isNaN(Date.parse(date));
  } catch (e) {
    return false;
  }
};

export const getClientTimeZoneFromBrowser = () => {
  return momentTimezone.tz.guess();
};

export const getTimeZoneFromDate = (date) => {
  return moment(date).tz(getClientTimeZoneFromBrowser()).tz();
};

/**
 * @param date - Date passed from cexcel
 * @param format - Format required by Trade Blotter API
 * @returns {string}
 */
export const formatDateWithTimeZone = (date, format) => {
  return momentTimezone(date, getClientTimeZoneFromBrowser()).format(format);
};

/**
 * @param date - Date passed from excel
 * @param oldFormat - will be used only for CSV / Fixed files
 * @param newFormat - Format required by Trade Blotter API
 * @returns {string}
 */
export const formatDate = (date, oldFormat, newFormat) => {
  return moment(date, oldFormat).format(newFormat);
};

export const convertDateToUserTZWithOn = (date, userTimeZone, on) => {
  if(date && userTimeZone) {
    return `${moment.tz(date, userTimeZone).format('HH:mm z')} ${on} ${moment.tz(date, userTimeZone).format('DD.MMM.YYYY')}`;
  }
  return date;
};

export const formatDateToUserTimezone = (date, userTimeZone, dateFormatter) => {
  return momentTimezone.tz(date, userTimeZone).format(dateFormatter);
};

export const toDate = (dateStr, splitBy) => {
  const [day, month, year] = dateStr.split(splitBy);
  return new Date(year, month - 1, day);
};

export const formatDateForInvstPlcy = (date, formatStr = 'DD-MMMM-YYYY') => {
  return moment(date).format(formatStr);
};

export const getFormattedDate = (date, formatter) => (
  formatter && formatDateForInvstPlcy(date, formatter) || date
);

const today = moment().toDate();
const getPreviousBusinessDay = () => {
  const workday = moment();
  const day = workday.day();
  let diff = 1;  // returns yesterday
  if (day === 0 || day === 1){  // is Sunday or Monday
    diff = day + 2;  // returns Friday
  }
  return workday.subtract(diff, 'days');
};
export const getPreviousBusinessDate = () => getPreviousBusinessDay().toDate();
export const getYesterdaysDate = () => moment().subtract(1, 'days').toDate();
/** given start and end date, helper fn ensures the returned end date is not earlier than start date */
export const getValidEndDateForRange = (startDate, endDate) => (endDate - startDate) >= 0 ? endDate : startDate;

const priorBusinessDay = getPreviousBusinessDate();
const yesterday = getYesterdaysDate();
const oneWeek = moment().subtract(7, 'days').toDate();
const mtd = moment().startOf('month').toDate();
const startDatePriorMonth = moment().subtract(1, 'months').startOf('month').toDate();
const endDatePriorMonth = moment().subtract(1, 'months').endOf('month').toDate();
const startDateCurrentQuarter = moment().startOf('quarter').toDate();
const startDatePriorYear = moment().subtract(12, 'months').startOf('year').toDate();
const endDatePriorYear = moment().subtract(12, 'months').endOf('year').toDate();

const quarterAdjustment= (moment().month() % 3) + 1;
const lastQuarterMomentEndDate = moment().subtract({ months: quarterAdjustment }).endOf('month');
const lastQuarterStartDate = lastQuarterMomentEndDate.clone().subtract({ months: 2 }).startOf('month').toDate();
const lastQuarterEndDate = lastQuarterMomentEndDate.toDate();

export const getRange = (preset, formatStr) => {
  const ranges = {
    [RANGE_BUTTONS.TODAY]: {fromDate: getFormattedDate(today, formatStr), toDate: getFormattedDate(today, formatStr)},
    [RANGE_BUTTONS.PRIOR_BUSINESS_DAY]: {fromDate: getFormattedDate(priorBusinessDay, formatStr), toDate: getFormattedDate(priorBusinessDay, formatStr)},
    [RANGE_BUTTONS.PRIOR_SEVEN_DAYS]: {fromDate: getFormattedDate(oneWeek, formatStr), toDate: getFormattedDate(yesterday, formatStr)},
    [RANGE_BUTTONS.CURRENT_MONTH]: { fromDate: getFormattedDate(mtd, formatStr), toDate: getFormattedDate(getValidEndDateForRange(mtd, yesterday), formatStr)},
    [RANGE_BUTTONS.PRIOR_MONTH]: {fromDate: getFormattedDate(startDatePriorMonth, formatStr), toDate: getFormattedDate(endDatePriorMonth, formatStr)},
    [RANGE_BUTTONS.CURRENT_QUARTER]: {fromDate: getFormattedDate(startDateCurrentQuarter, formatStr), toDate: getFormattedDate(getValidEndDateForRange(startDateCurrentQuarter, yesterday), formatStr)},
    [RANGE_BUTTONS.PRIOR_QUARTER]: {fromDate: getFormattedDate(lastQuarterStartDate, formatStr), toDate: getFormattedDate(lastQuarterEndDate, formatStr)},
    [RANGE_BUTTONS.PRIOR_YEAR]: {fromDate: getFormattedDate(startDatePriorYear, formatStr), toDate: getFormattedDate(endDatePriorYear, formatStr)}
  };
  return ranges[preset] || {};
};

export const getButtons = () => [
  {
    label: t('tkCurrentDay'),
    value: RANGE_BUTTONS.TODAY,
    date: {start: today, end: today},
    isActive: false,
    hidden: false,
    disabled: false,
    id: 'dp-btn-1'
  },
  {
    label: t('tkPriorDay'),
    value: RANGE_BUTTONS.PRIOR_BUSINESS_DAY,
    date: {start: priorBusinessDay, end: priorBusinessDay},
    isActive: false,
    hidden: false,
    disabled: false,
    id: 'dp-btn-2'
  },
  {
    label: t('tkPrior7Days'),
    value: RANGE_BUTTONS.PRIOR_SEVEN_DAYS,
    date: {start: oneWeek, end: yesterday},
    isActive: true,
    hidden: false,
    disabled: false,
    id: 'dp-btn-3'
  },
  {
    label: t('tkCurrentMonth'),
    value: RANGE_BUTTONS.CURRENT_MONTH,
    date: {start: mtd, end: getValidEndDateForRange(mtd, yesterday)},
    isActive: false,
    hidden: false,
    disabled: false,
    id: 'dp-btn-4'
  },
  {
    label: t('tkPriorMonth'),
    value: RANGE_BUTTONS.PRIOR_MONTH,
    date: {start: startDatePriorMonth, end: endDatePriorMonth},
    isActive: false,
    hidden: false,
    disabled: false,
    id: 'dp-btn-5'
  },
  {
    label: t('tkCurrentQuarter'),
    value: RANGE_BUTTONS.CURRENT_QUARTER,
    date: {start: startDateCurrentQuarter, end: getValidEndDateForRange(startDateCurrentQuarter, yesterday)},
    isActive: false,
    hidden: false,
    disabled: false,
    id: 'dp-btn-6'
  },
  {
    label: t('tkPriorQuarter'),
    value: RANGE_BUTTONS.PRIOR_QUARTER,
    date: {start: lastQuarterStartDate, end: lastQuarterEndDate},
    isActive: false,
    hidden: false,
    disabled: false,
    id: 'dp-btn-7'
  },
  {
    label: t('tkPriorYear'),
    value: RANGE_BUTTONS.PRIOR_YEAR,
    date: {start: startDatePriorYear, end: endDatePriorYear},
    isActive: false,
    hidden: false,
    disabled: false,
    id: 'dp-btn-7'
  },
];

export const getUTCDateFromMillis = (dateMillis) => {
  return moment.utc(dateMillis).format('YYYY-MM-DD');
};

export const getJSDateFromString = (dateString, formatStr = 'YYYY-MM-DD') => {
  return dateString ? moment(dateString, formatStr).toDate() : dateString;
};
