import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import {
    Theme,
    ThemeTypographyVariant,
    createComponentClassDefinitions,
} from '@gs-ux-uitoolkit-common/theme';
import { ListGroupSize } from './list-group-props';
import { DeepReadonly } from 'ts-essentials';
import './list-group-theme-overrides';

export interface ListGroupStyleSheetProps {
    theme: Theme;
    size: ListGroupSize;
    horizontal: boolean;
}

export interface ListGroupCssClasses {
    root: string;
}

export type ListGroupStyledClasses = CssClassDefinitionsObject<keyof ListGroupCssClasses>;

export interface ListGroupStyleOverridesParams {
    props: DeepReadonly<ListGroupStyleSheetProps>;
    createDefaultStyledClasses: () => ListGroupStyledClasses;
}

export const listGroupStyleSheet = new StyleSheet(
    'list-group',
    (props: ListGroupStyleSheetProps) => {
        return createComponentClassDefinitions<ListGroupStyleSheetProps, ListGroupStyledClasses>(
            props,
            createDefaultStyledClasses,
            props.theme.styleOverrides?.listGroup
        );
    }
);

function createDefaultStyledClasses({
    theme,
    size,
    horizontal,
}: ListGroupStyleSheetProps): ListGroupStyledClasses {
    let itemTypography: ThemeTypographyVariant = 'body02';
    if (size === 'sm') {
        itemTypography = 'body03';
    } else if (size === 'lg') {
        itemTypography = 'body01';
    }

    const horizontalStyles = horizontal
        ? {
              '> [data-gs-uitk-component="list-group-item"]': {
                  borderTop: `1px solid ${theme.border.minimal}`,
                  borderLeft: `1px solid ${theme.border.minimal}`,
              },
              '> [data-gs-uitk-component="list-group-item"]:last-child': {
                  borderRight: `1px solid ${theme.border.minimal}`,
              },
          }
        : {};

    return {
        root: {
            ...theme.typography[itemTypography],
            display: 'flex',
            flexDirection: horizontal ? 'row' : 'column',
            paddingLeft: '0px',
            marginBottom: '0px',
            listStyle: 'none',
            ...horizontalStyles,
            overflow: 'auto',
        },
    };
}
