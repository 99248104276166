import { Component, FormEvent } from 'react';
import { classNamePrefix } from '../../../../style/plugins/keyboard-shortcuts-stylesheet';
import { Form, FormGroup } from '@gs-ux-uitoolkit-react/form';
import { Label } from '@gs-ux-uitoolkit-react/label';
import { InputNumber } from '@gs-ux-uitoolkit-react/input';
import { KeyboardShortcut, ShortcutKey, NumericShortcutOperation } from '../../keyboard-shortcut';
import { Select, SelectChangeEvent, SelectOption } from '@gs-ux-uitoolkit-react/select';
import { PluginPreviewComponent } from '../../../../components/plugin-preview/plugin-preview-layout';

export interface KeyboardShortcutsScreenProps {
    data: KeyboardShortcut;
    shortcutKeyTaken: ShortcutKey[];
    updateEditedConfigItem: (newData: Partial<KeyboardShortcut>) => void;
}
export class KeyboardShortcutsScreen extends Component<KeyboardShortcutsScreenProps> {
    public render() {
        // create key option data
        const excludedKeys = this.props.shortcutKeyTaken.filter(x => x !== this.props.data.key);
        const shortcutKeyOptionData = this.enumToSelectOptions(ShortcutKey, excludedKeys);

        //create operation option data
        const operationOptionData = this.enumToSelectOptions(NumericShortcutOperation);
        const summaryDetails = this.getSummaryDetails();
        return (
            <div className={`${classNamePrefix}_container`}>
                <div className={`${classNamePrefix}_body`}>
                    <Label className={`${classNamePrefix}_title`}>CREATE NUMERIC SHORTCUT</Label>
                    <Form preventSubmit={true}>
                        <FormGroup>
                            <Label>Shortcut Key</Label>
                            <Select
                                options={shortcutKeyOptionData}
                                defaultValue={this.props.data.key}
                                onChange={(event: SelectChangeEvent) => this.onChangeKey(event)}
                                className={`${classNamePrefix}_select`}
                                clearable={false}
                                data-cy={`${classNamePrefix}_select_shortcut-key`}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Operation</Label>
                            <Select
                                options={operationOptionData}
                                defaultValue={this.props.data.operation}
                                onChange={(event: SelectChangeEvent) =>
                                    this.onChangeOperation(event)
                                }
                                className={`${classNamePrefix}_select`}
                                clearable={false}
                                data-cy={`${classNamePrefix}_select_shortcut-operation`}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Value</Label>
                            <InputNumber
                                placeholder="Enter number"
                                value={this.props.data.value}
                                onChange={(event: FormEvent<HTMLInputElement>) =>
                                    this.onChangeValue(event)
                                }
                            />
                        </FormGroup>
                    </Form>
                </div>
                <PluginPreviewComponent
                    data-cy={`${classNamePrefix}_preview-component`}
                    previewTitle="Summary"
                    previewBody={summaryDetails}
                ></PluginPreviewComponent>
            </div>
        );
    }

    private enumToSelectOptions<T extends { [s: string]: string } | ArrayLike<unknown>, U>(
        enumType: T,
        notToInlude: U[] = []
    ): SelectOption[] {
        return Object.entries(enumType)
            .filter(x => !notToInlude.includes(x[1]))
            .map(entry => ({ value: entry['0'], label: entry['1'] }));
    }
    private onChangeKey(event: SelectChangeEvent) {
        this.props.updateEditedConfigItem({ key: event.selectedValue as ShortcutKey });
    }
    private onChangeOperation(event: SelectChangeEvent) {
        this.props.updateEditedConfigItem({
            operation: event.selectedValue as NumericShortcutOperation,
        });
    }
    private onChangeValue(event: FormEvent<HTMLInputElement>) {
        this.props.updateEditedConfigItem({
            value: parseInt(event.currentTarget.value, 10),
        });
    }
    private getSummaryDetails() {
        const exampleCellValue = 24;
        return (
            <div className={`${classNamePrefix}_preview-component-body`}>{`'${
                this.props.data.key
            }' ${this.props.data.operation} [CellValue] ${
                this.props.data.operation === NumericShortcutOperation.Replace ? 'with' : 'by'
            } ${this.props.data.value}

        Example:
        ${exampleCellValue} becomes ${this.getExampleValue(exampleCellValue)}
        `}</div>
        );
    }
    private getExampleValue(exampleCellValue: number): number {
        switch (this.props.data.operation) {
            case NumericShortcutOperation.Add:
                return exampleCellValue + this.props.data.value;
            case NumericShortcutOperation.Divide:
                return exampleCellValue / this.props.data.value;
            case NumericShortcutOperation.Multiply:
                return exampleCellValue * this.props.data.value;
            case NumericShortcutOperation.Replace:
                return this.props.data.value;
            case NumericShortcutOperation.Subtract:
                return exampleCellValue - this.props.data.value;
        }
    }
}
