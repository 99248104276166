import { logger } from '@gs-ux-uitoolkit-common/datacore';
import * as Redux from 'redux';

/**
 * Middleware that will log an error when some of the action raises an error
 * @param store The redux store
 */
export const crashReporter: Redux.Middleware = store => next => action => {
    try {
        return next(action);
    } catch (err: any) {
        logger.error(
            `Caught an exception in the store executing ${action.type}`,
            err,
            action,
            store.getState()
        );
        throw err;
    }
};
