import { IconName } from './full-set';

export const v16ToV17IconMapping: {
    [key in string]: IconName;
} = {
    'add-circle': 'add-circle-outline',
    bookmark: 'bookmark-border',
    'chat-bubble': 'chat-bubble-outline',
    error: 'error-outline',
    favorite: 'favorite-border',
    help: 'help-outline',
    mic: 'mic-none',
    'remove-circle': 'remove-circle-outline',
    star: 'star-outline',
    warning: 'warning-amber',
    work: 'work-outline',
};
