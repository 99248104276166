import {
    globalHelper,
    logger,
    ReduxStorageEngine,
    UIToolkitDeepPartial,
    ReduxStorage,
} from '@gs-ux-uitoolkit-common/datacore';
import { ReactReduxContextValue } from 'react-redux';
import * as Redux from 'redux';
// import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import { GridWrapper } from '../grid-wrappers/grid-wrapper';
import { AllActionTypes } from './actions/all-action-types';
import { SystemConfigurationStoreReady } from './actions/system-configuration-action';
import { DataGridState } from './datagrid-state';
import { crashReporter } from './middlewares/crash-reporter';
import { gridMiddleware } from './middlewares/grid-middleware';
import { loggerMiddleware } from './middlewares/logger';
import { rootReducer } from './reducers/root-reducer';
import { Context, createContext } from 'react';

export const blotterSystemState: UIToolkitDeepPartial<DataGridState> = {};
/**
 * The store related to a grid
 */
export class DataGridStore {
    private reduxStore: Redux.Store<DataGridState>;
    private baseState: DataGridState | null = null;
    private GridContext: Context<ReactReduxContextValue>;
    constructor(gridWrapper: GridWrapper, defaultGridState: UIToolkitDeepPartial<DataGridState>) {
        const mergedState = globalHelper.mergeStates(blotterSystemState, defaultGridState);
        const reduxStorageEngine = new ReduxStorageEngine(mergedState);
        // For now we never trigger REDUX_STORAGE_SAVE so we pass an dummy array for the whitelist
        const reduxStorageMiddleware = ReduxStorage.createMiddleware(
            reduxStorageEngine,
            [],
            ['NONE']
        );
        const reducerWithStorage = ReduxStorage.reducer<DataGridState>(
            rootReducer,
            (baseState: DataGridState, newState: DataGridState) => {
                // we store the initial state as we want the API to give back the state
                // that has changed intead of the whole state
                this.baseState = globalHelper.cloneObject(baseState);
                return globalHelper.mergeStates(baseState, newState);
            }
        );

        const middlewareSet = [
            reduxStorageMiddleware,
            loggerMiddleware,
            crashReporter,
            gridMiddleware(gridWrapper),
        ];
        // if (process.env.NODE_ENV === 'development') {
        //     middlewareSet.push(reduxImmutableStateInvariant());
        // }
        const middleware = Redux.applyMiddleware(...middlewareSet);

        this.reduxStore = Redux.legacy_createStore<DataGridState, AllActionTypes, object, object>(
            reducerWithStorage,
            middleware
        );

        this.GridContext = createContext({
            store: this.reduxStore,
        }) as unknown as Context<ReactReduxContextValue>;

        const loadStorage = ReduxStorage.createLoader(reduxStorageEngine);

        loadStorage(this.reduxStore)
            .then(newState => {
                this.getReduxStore().dispatch(SystemConfigurationStoreReady());
                return newState;
            })
            .then(newState => logger.debug('Loaded state:', newState));
    }

    public getReduxStore(): Redux.Store<DataGridState> {
        return this.reduxStore;
    }

    public getBaseState(): DataGridState | null {
        return this.baseState;
    }
    public getStoreContext(): Context<ReactReduxContextValue> | undefined {
        return this.GridContext;
    }
}
