import { FunctionComponent, PropsWithChildren, memo } from 'react';
import { CommonReactPopoverProps } from './popover';
import { ReactComponentProps } from '@gs-ux-uitoolkit-react/component';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { popoverStyleSheet, getPopoverHeaderClasses } from '@gs-ux-uitoolkit-common/popover';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';

export interface PopoverHeaderProps extends ReactComponentProps {
    classes?: CommonReactPopoverProps['classes'];
    size?: CommonReactPopoverProps['size'];
}

/**
 * Provides appropriate spacing and styling around the header section of the Popover's content.
 */
export const PopoverHeader: FunctionComponent<PropsWithChildren<PopoverHeaderProps>> = memo(
    props => {
        const { classes: overrideClasses, size, className, ...children } = props;
        const theme = useTheme();
        const cssClasses = useStyleSheet(popoverStyleSheet, { size, theme });
        return (
            <div
                data-gs-uitk-component="popover-header"
                data-cy="gs-uitk-popover__header"
                {...children}
                className={getPopoverHeaderClasses({ cssClasses, overrideClasses, className })}
            />
        );
    }
);
PopoverHeader.displayName = 'PopoverHeader';
