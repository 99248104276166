import {getDomainMapper} from "../../../utils/commonUtils";
const domainMapper = getDomainMapper();

export default  {
  PING_CONFIG: {
    'path': '/gs-sso/userapi?initialCall=true',
    'method': 'GET'
  },
  INITIAL_PING_CALL: {
    'path': '',
    'method': 'GET',
    'parameters': {
      'appContext.pingConfig.response_type': 'response_type',
      'appContext.pingConfig.client_id': 'client_id',
      'appContext.pingConfig.state': 'state',
      'appContext.pingConfig.redirect_uri': 'redirect_uri',
      'appContext.pingConfig.nonce': 'nonce',
      'appContext.pingConfig.response_mode': 'response_mode',
      'appContext.pingConfig.access_token_manager_id': 'access_token_manager_id',
      'appContext.pingConfig.pfidpadapterid': 'pfidpadapterid',
      'appContext.pingConfig.minAuthLevel': 'minAuthLevel',
      'appContext.pingConfig.reset': 'reset',
      'appContext.pingConfig.sessionId': 'sessionId'
    }
  },
  FETCH_LABELS: {
    'path': `/content/${domainMapper}-login/nodes/contentkeys/login-en.json`,
    'method': 'GET'
  },
  FETCH_LOGIN_CONFIGURATIONS: {
    'path': `/content/${domainMapper}-login/nodes/login-configurations.json`,
    'method': 'GET'
  },
  PWD_AND_PIN_VALIDATION_ITEMS: {
    'path': `/content/${domainMapper}-login/nodes/pwd-and-pin-validation-config.json`,
    'method': 'GET'
  },
  FETCH_DEEPLINK_MAPPINGS: {
    'path': `/content/${domainMapper}-login/nodes/deeplink-mappings.json`,
    'method': 'GET'
  },
  FETCH_HELP_CONTENT_CONFIGURATIONS: {
    'path': `/content/${domainMapper}-login/nodes/help-content-configurations.json`,
    'method': 'GET'
  },
  SUBMIT_CREDENTIALS:{
    'path': '',
    'method': 'POST'
  },
  CREATE_NEW_PASSWORD:{
    'path': '',
    'method': 'POST'
  },
  CREATE_NEW_PIN:{
    'path': '',
    'method': 'POST'
  },
  SUBMIT_FPW_UID: {
    'path': '',
    'method': 'POST'
  },
  SUBMIT_FPW_OTP: {
    'path': '',
    'method': 'POST'
  },
  SET_COOKIE: {
    'path': '/gs-sso/userapi',
    'method': 'POST'
  },
  SAVE_TERMS_AND_CONDITIONS_DATA: {
    'path': '/api/v1/users/termsandconditions',
    'method': 'POST'
  },
  ACCOUNT_LOGOUT: {
    'path': '/content/logout',
    'method': 'GET'
  },
  COOKIE_LOGOUT: {
    'path': '',
    'method': 'GET'
  },
  MFA_INIT: {
    'path': '/mfa/init',
    'method': 'POST'
  }
}
