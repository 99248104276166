/* eslint-disable react-hooks/exhaustive-deps */
// TODO: https://jira.site.gs.com/browse/UX-14730
// 61:9  warning  React Hook useMemo has a missing dependency: 'getSharedAttributes'. Either include it or remove the dependency array

import {
    DOMAttributes,
    CSSProperties,
    FunctionComponent,
    useContext,
    useMemo,
    useEffect,
    ReactHTML,
} from 'react';
import PropTypes from 'prop-types';
import {
    RowProps as CommonRowProps,
    rowStyleSheet,
    LayoutContext as CommonLayoutContext,
    getRowClassNames,
    layoutDensities,
} from '@gs-ux-uitoolkit-common/layout';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { LayoutContext } from './layout-context';
import { breakpointsPropType, gridPropType } from './container';
import { componentAnalytics } from './analytics-tracking';

export interface RowProps extends DOMAttributes<HTMLElement>, CommonRowProps<CSSProperties> {
    /**
     * Specifies the container HTML tag. Default is `div`.
     */
    tag?: string;
}

export const Row: FunctionComponent<RowProps> = (props: RowProps) => {
    const {
        className,
        breakpoints,
        grid,
        density,
        noGutters,
        colsAtXs,
        colsAtSm,
        colsAtMd,
        colsAtLg,
        colsAtXl,
        colsAtXXl,
        colsAtXXXl,
        tag: Tag = 'div',
        ...otherProps
    } = props;

    const parentLayoutContext = useContext(LayoutContext);

    function getSharedAttributes() {
        const context = parentLayoutContext;
        const gridProp = grid !== undefined ? grid : context.grid;
        return {
            breakpoints: breakpoints !== undefined ? breakpoints : context.breakpoints,
            grid: gridProp,
            density: density || context.density,
            noGutters: noGutters !== undefined ? noGutters : context.noGutters,
        };
    }

    const layoutContext: CommonLayoutContext = useMemo(
        () => ({
            ...getSharedAttributes(),
            colsAtXs,
            colsAtSm,
            colsAtMd,
            colsAtLg,
            colsAtXl,
            colsAtXXl,
            colsAtXXXl,
        }),
        [
            parentLayoutContext,
            breakpoints,
            grid,
            density,
            noGutters,
            colsAtXs,
            colsAtSm,
            colsAtMd,
            colsAtLg,
            colsAtXl,
            colsAtXXl,
            colsAtXXXl,
        ]
    );

    const theme = useTheme();
    const cssClasses: any = useStyleSheet(rowStyleSheet, {
        theme,
        ...getSharedAttributes(),
    });
    const rootClasses = getRowClassNames({ cssClasses, className });

    const TypedTag = Tag as keyof ReactHTML;

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({
            componentName: 'row',
            officialComponentName: 'other',
        });
    }, []); // Only run once

    return (
        <LayoutContext.Provider value={layoutContext}>
            <TypedTag {...otherProps} className={rootClasses} data-gs-uitk-component="row" />
        </LayoutContext.Provider>
    );
};

const colsPropType = (props: any, propName: string, componentName: string) => {
    // Tried `PropTypes.oneOf([1, 2, 3, 4, 5, 6, 'auto'])` but TS did not like it.
    const prop = props[propName];
    if (prop === undefined || prop === 'auto' || (!isNaN(+prop) && +prop >= 1 && +prop <= 6)) {
        return null;
    }
    return new Error(
        `Invalid prop "${propName}" supplied to "${componentName}". Validation failed.`
    );
};

Row.propTypes = {
    breakpoints: breakpointsPropType,
    grid: gridPropType,
    density: PropTypes.oneOf(layoutDensities),
    noGutters: PropTypes.bool,
    colsAtXs: colsPropType,
    colsAtSm: colsPropType,
    colsAtMd: colsPropType,
    colsAtLg: colsPropType,
    colsAtXl: colsPropType,
    colsAtXXl: colsPropType,
    colsAtXXXl: colsPropType,
    tag: PropTypes.string,
};
