import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import BuildInfo from '../../components/app/BuildInfo';
import {labelSelector} from '../../selectors/app';

const mapStateToProps = (state) => ({
  labels: labelSelector(state)
});

BuildInfo.propTypes = {
  labels: PropTypes.object
};

export default connect(mapStateToProps)(BuildInfo);
