import { StyleSheet } from './style-sheet';
import { CssClasses } from './types';

export type SrOnlyCssClasses = CssClasses<typeof srOnlyStyleSheet>;

/**
 * Stylesheet to apply classes for elements that should only be
 * visible to screen readers.
 */
export const srOnlyStyleSheet = new StyleSheet('sr-only', () => {
    return {
        root: {
            position: 'absolute',
            width: '1px',
            height: '1px',
            padding: '0',
            margin: '-1px',
            overflow: 'hidden',
            clip: 'rect(0, 0, 0, 0)',
            whiteSpace: 'nowrap',
            border: '0',
        },
    };
});
