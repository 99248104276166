import actionTypes from '../../../actions/app/actionTypes';

export function tradeBlotterDataReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.UPDATE_TRADE_OPTIONS:
    case actionTypes.UPDATE_CACHED_TRADE_OPTIONS:
    case actionTypes.ADD_SHELL_ACCOUNT_CONFIGS:
      return {...state, ...action.data};
    default:
      return state;
  }
}

export function tradeBlotterContextReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.UPDATE_SELECTED_ROWS:
    case actionTypes.CHANGE_ACTIVE_TRADE_ROW:
    case actionTypes.UPDATE_ACTIVE_TRADE:
    case actionTypes.UPDATE_DRAFT_TRADES:
    case actionTypes.DRAFT_TRADES_LOADING:
    case actionTypes.UPDATE_VALID_TRADES:
    case actionTypes.UPDATE_COMPLETE_TRADES:
    case actionTypes.BLOTTER_DIRTY_STATUS:
    case actionTypes.SAVING_TRADES_IN_PROGRESS:
    case actionTypes.SAVE_QUEUED_STATUS:
    case actionTypes.UPDATE_RETRIEVE_QUEUED_STATUS:
    case actionTypes.VERIFY_TRADES_IN_PROGRESS:
    case actionTypes.SHOW_NEW_ACCOUNT_MODAL:
    case actionTypes.CLOSE_NEW_ACCOUNT_MODAL:
    case actionTypes.SHELL_ACCOUNT_OPTIONS_LOADING:
    case actionTypes.CACHED_TRADE_OPTIONS_LOADING:
    case actionTypes.TRADE_INPUTS_LOADING:
    case actionTypes.PLACING_TRADES_IN_PROGRESS:
    case actionTypes.SHOW_TRADE_BLOTTER_SNACKBAR:
    case actionTypes.SHOW_NEW_ACCOUNT_MODAL_SNACKBAR:
    case actionTypes.SHOW_TRADE_SAVE_FAIL_SNACKBAR:
    case actionTypes.TRADE_OPTIONS_LOADING:
    case actionTypes.UPDATE_SHELL_ACCOUNT_STATE:
      return {...state, ...action.data};
    case actionTypes.UPDATE_TRADE_INLINE_WARNING:
      return {...state, ...state.activeTrade, ...action.data};
    default:
      return state;
  }
}

