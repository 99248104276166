import React from 'react';
import PropTypes from "prop-types";
import "./index.scss"
import {ButtonGroup, ButtonSelect} from "@gs-ux-uitoolkit-react/button";
import {connect} from "react-redux";
import {eventAnalytics} from "../../../../../../actions/sagas";
import {getRebateToggleAnalyticsData} from "../../../../../../utils/portfolioAnalytics";

const AdjustedYieldToggler = ({onChangeYieldState, sendAnalytics, fundIdentifier}) => {

  const handleAdjustedButtonClick = () => {
    onChangeYieldState('Adjusted');
    sendAnalytics(getRebateToggleAnalyticsData('Adjusted Yield Toggle', fundIdentifier));
  };

  const handleUnadjustedButtonClick = () => {
    onChangeYieldState('Unadjusted');
    sendAnalytics(getRebateToggleAnalyticsData('Unadjusted Yield Toggle', fundIdentifier));
  };

  return (
    <div className='adjusted-yield-toggler'>
      <ButtonGroup>
        <ButtonSelect
          classes={{target: "button-target", input: "button-input", root: "button-root", label: "button-label"}}
          onClick={handleAdjustedButtonClick}
          size="sm"
          type="single"
          name="yield-toggle"
          defaultSelected
        >
          Adjusted
        </ButtonSelect>
        <div className='divider'> </div>
        <ButtonSelect
          classes={{target: "button-target", input: "button-input", root: "button-root", label: "button-label"}}
          onClick={handleUnadjustedButtonClick}
          size="sm"
          type="single"
          name="yield-toggle"
        >
          Unadjusted
        </ButtonSelect>
      </ButtonGroup>
    </div>
  )
};

AdjustedYieldToggler.propTypes = {
  onChangeYieldState: PropTypes.func,
  sendAnalytics: PropTypes.func,
  fundIdentifier: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
  sendAnalytics: (data) => dispatch(eventAnalytics(data))
});

export default connect(
  null,
  mapDispatchToProps
)(AdjustedYieldToggler);
