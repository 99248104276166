import { connect } from 'react-redux';
import ForgotPassword from '../../components/app/ForgotPassword';
import { usernameSelector } from '../../selectors/app';
import {nextStepSelector, submitCredentialsErrorSelector} from '../../selectors/pages/login';
import { fpwSubmitOTP, fpwSubmitUID } from '../../actions/page/login';
import {eventAnalytics} from "../../actions/sagas";

const mapStateToProps = (state) => ({
  submitCredentialsError : submitCredentialsErrorSelector(state),
  nextStep: nextStepSelector(state),
  userId: usernameSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  submitUID: (data) => dispatch(fpwSubmitUID(data)),
  submitOTP: (data) => dispatch(fpwSubmitOTP(data)),
  eventAnalytics: (data) => dispatch(eventAnalytics(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
