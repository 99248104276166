import isPlainObject from 'lodash/isPlainObject';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import commonParams from '../configs/commonParams';
import GLSError from '../error';
import errorTypes from '../error/errorType';

let currentState;

const getCurrentPropValue = (key) => get(currentState, key);

const isParamValueValid = paramValue =>
  (paramValue !== undefined && paramValue !== null && paramValue !== '' && paramValue !== 'undefined' && paramValue !== 'null');

const getCurrentPropObject = (key, mapping = {}, localState) => {
  const {mapper = {}} = mapping;
  const mapperKeys = Object.keys(mapper);
  if (mapperKeys.length === 0) {
    return {};
  }
  const data = localState ? get(localState, key) : get(currentState, key);

  const obj = {};
  for (let index = 0; index < mapperKeys.length; index++) {
    const currentPropValue = get(data, mapperKeys[index]);
    if (isParamValueValid(currentPropValue)) {
      obj[mapper[mapperKeys[index]]] = currentPropValue;
    }
  }
  return obj;
};

const getCurrentPropArray = (key, mapping = {}, localState) => {
  const {mapper = {}, condition = () => true} = mapping;
  const mapperKeys = Object.keys(mapper);
  if (mapperKeys.length === 0) {
    return [];
  }
  const data = localState ? get(localState, key) : get(currentState, key);
  const arr = [];
  for (let index = 0; index < data.length; index++) {
    const value = data[index];
    const obj = {};
    for (let i = 0; i < mapperKeys.length; i++) {
      let currentPropValue = '';
      const {type = 'string'} = mapper[mapperKeys[i]];
      if (type === 'object' && isPlainObject(get(value, mapperKeys[i]))) {
        const {paramKey} = mapper[mapperKeys[i]];
        currentPropValue = getCurrentPropObject(mapperKeys[i], mapper[mapperKeys[i]], value);
        obj[paramKey] = currentPropValue;
      } else if (type === 'array' && isArray(get(value, mapperKeys[i]))) {
        const {paramKey} = mapper[mapperKeys[i]];
        currentPropValue = getCurrentPropArray(mapperKeys[i], mapper[mapperKeys[i]], value);
        obj[paramKey] = currentPropValue;
      } else {
        currentPropValue = get(value, mapperKeys[i]);
        if (isParamValueValid(currentPropValue)) {
          obj[mapper[mapperKeys[i]]] = currentPropValue;
        }
      }
    }
    if(condition(obj)) {
      arr.push(obj);
    }
  }
  return arr;
};

const createParamsObject = (parameters, payLoad, skipCommonParams) => {
  const paramsObj = {};

  // add common params
  if (!skipCommonParams) {
    const keys = Object.keys(commonParams);
    for (let index = 0; index < keys.length; index++) {
      const currentPropValue = getCurrentPropValue(keys[index]);
      if (isParamValueValid(currentPropValue)) {
        paramsObj[commonParams[keys[index]]] = currentPropValue;
      }
    }
  }

  if (parameters && isObject(parameters)) {
    const keys = Object.keys(parameters);
    for (let index = 0; index < keys.length; index++) {
      let currentPropValue = '';
      const {type = 'string'} = parameters[keys[index]];
      if (type === 'array' && isArray(get(currentState, keys[index]))) {
        const {paramKey} = parameters[keys[index]];
        currentPropValue = getCurrentPropArray(keys[index], parameters[keys[index]]);
        paramsObj[paramKey] = currentPropValue;
      } else if (type === 'object' && isPlainObject(get(currentState, keys[index]))) {
        const {paramKey} = parameters[keys[index]];
        currentPropValue = getCurrentPropObject(keys[index], parameters[keys[index]]);
        paramsObj[paramKey] = currentPropValue;
      } else {
        currentPropValue = getCurrentPropValue(keys[index]);
        if (isParamValueValid(currentPropValue)) {
          paramsObj[parameters[keys[index]]] = currentPropValue;
        }
      }
    }
  } else {
    throw new GLSError(errorTypes.PARAMETER_ERROR);
  }

  if (payLoad && isArray(payLoad)) {
    for (let index = 0; index < payLoad.length; index++) {
      const param = payLoad[index].split('=');
      const [payLoadKey, payLoadValue] = param;
      if (isParamValueValid(payLoadValue)) {
        paramsObj[payLoadKey] = payLoadValue;
      }
    }
  }

  if(payLoad && isPlainObject(payLoad)){
    const keys = Object.keys(payLoad);
    for (let index = 0; index < keys.length; index++) {
      if (isParamValueValid(payLoad[keys[index]])) {
        paramsObj[keys[index]] = payLoad[keys[index]];
      }
    }
  }

  return paramsObj;
};

const getParameterObj = (parameters = {}, state, payLoad, skipCommonParams) => {
  currentState = state;
  return createParamsObject(parameters, payLoad, skipCommonParams);
};


export default getParameterObj;


