import {
    ReactNode,
    useCallback,
    useContext,
    PropsWithChildren,
    FC,
    memo,
    KeyboardEvent,
} from 'react';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import {
    stepStyleSheet,
    stepDefaultProps,
    getStepEffectiveStatus,
    getStepIcon,
    getStepRootClasses,
    getStepButtonClasses,
    getStepIndicatorClasses,
    getStepLabelClasses,
    getStepContentClasses,
    getStepIconClasses,
    StepProps as StepCommonProps,
} from '@gs-ux-uitoolkit-common/steps';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { Collapse } from '@gs-ux-uitoolkit-react/collapse';
import { StepsContext } from './steps-context';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';

export interface StepProps extends Omit<StepCommonProps, 'label'> {
    /**
     * The label for the step.
     */
    label?: string | ReactNode;
}

/**
 * Step is each individual item that makes up the 'Steps' component
 * @visibleName Step
 */
export const Step: FC<PropsWithChildren<StepProps>> = memo(props => {
    const {
        children,
        className,
        classes: overrideClasses,
        expanded: expandedProp = false,
        icon,
        label,
        status: statusProp,
        disabled = stepDefaultProps.disabled,
        ...attributes
    } = props;

    const theme = useTheme();

    // Configuration from the parent Steps instance
    const {
        currentStep,
        expandable = false,
        fillIndicators: fillIndicator,
        hideLabels: hideLabel,
        index,
        inline = false,
        onStepClick,
        orientation,
        size,
    } = useContext(StepsContext);

    const status = getStepEffectiveStatus({ statusProp, currentStep, index });
    const finalIcon = getStepIcon(icon, status);
    const isCurrentStep = currentStep === index; // this step is active
    const expanded = expandedProp || index === currentStep;
    const clickable = !!onStepClick && !disabled;

    const cssClasses = useStyleSheet(stepStyleSheet, {
        theme,
        status,
        currentStep,
        index,
        inline,
        expandable,
        expanded,
        orientation,
        fillIndicator,
        hideLabel,
        disabled,
        size,
        clickable,
    });

    const onStepClickCb = useCallback(() => {
        if (clickable && onStepClick) {
            onStepClick({ stepIndex: index });
        }
    }, [index, onStepClick, clickable]);

    const onStepPressEnter = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Enter' && onStepClick) {
                onStepClick({ stepIndex: index });
            }
        },
        [index, onStepClick]
    );

    return (
        <li
            {...attributes}
            className={getStepRootClasses({ cssClasses, overrideClasses, className })}
            key={index}
            data-status={status}
            data-gs-uitk-component="step"
        >
            <div
                className={getStepButtonClasses({
                    cssClasses,
                    overrideClasses,
                })}
                {...(onStepClick && { onClick: onStepClickCb })}
                onKeyPress={e => onStepPressEnter(e)}
                tabIndex={clickable ? 0 : -1}
                role={clickable ? 'button' : 'presentation'}
                aria-disabled={!clickable}
                data-cy="gs-uitk-step__button"
            >
                <span
                    className={getStepIndicatorClasses({ cssClasses, overrideClasses })}
                    data-cy="gs-uitk-step__indicator"
                >
                    {' '}
                    {finalIcon && (
                        <Icon
                            {...finalIcon}
                            className={getStepIconClasses({ cssClasses, overrideClasses })}
                            data-cy="gs-uitk-step__icon"
                            aria-hidden="true"
                        />
                    )}
                </span>
                <span
                    className={getStepLabelClasses({ cssClasses, overrideClasses })}
                    data-cy="gs-uitk-step__label"
                >
                    {label}
                </span>
            </div>
            {orientation === 'vertical' && expandable ? (
                <Collapse
                    className={getStepContentClasses({ cssClasses, overrideClasses })}
                    expanded={expanded || isCurrentStep}
                    data-cy="gs-uitk-step__content"
                    role="presentation"
                >
                    {children}
                </Collapse>
            ) : (
                <div
                    className={getStepContentClasses({ cssClasses, overrideClasses })}
                    data-cy="gs-uitk-step__content"
                >
                    {children}
                </div>
            )}
        </li>
    );
});
Step.displayName = 'Step';
