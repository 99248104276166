import { FeatureFlag, featureFlagService } from '@gs-ux-uitoolkit-common/datacore';
import { pluginRegistry } from '../libraries/plugin-registry';
import { AutofitPivotPlugin } from './autofit-pivot/autofit-pivot-plugin';
import { ColumnHintPlugin } from './column-hint/column-hint-plugin';
import { CrossModelPlugin } from './cross-model/cross-model-plugin';
import { CustomSortPlugin } from './custom-sort/custom-sort-plugin';
import { KeyboardShortcutPlugin } from './keyboard-shortcut/keyboard-shortcut-plugin';
import { ExportsPlugin } from './exports/exports-plugin';
import { RowCustomisationPlugin } from './row-customisation/row-customisation-plugin';
import { SavedViewPlugin } from './saved-view/saved-view-plugin';
import { MaskedColumnPlugin } from './masked-column/masked-column-plugin';
import { Plugins } from './plugin-enum';
import { QuickFilterPlugin } from './quick-filter/quick-filter-plugin';
import { SearchablePlugin } from './searchable/searchable-plugin';
import { SizeAndDensityPlugin } from './size-density/size-density-plugin';
import { NotificationPlugin } from './notification/notification-plugin';
import { ZebraStripesPlugin } from './zebra-stripes/zebra-stripes-plugin';
import { GridConfigurationPlugin } from './grid-configuration/grid-configuration-plugin';

pluginRegistry.register(Plugins.AutofitPivotPlugin, AutofitPivotPlugin);
pluginRegistry.register(Plugins.ColumnHintPlugin, ColumnHintPlugin);
pluginRegistry.register(Plugins.CrossModelPlugin, CrossModelPlugin);
pluginRegistry.register(Plugins.CustomSortPlugin, CustomSortPlugin);
pluginRegistry.register(Plugins.KeyboardShortcutPlugin, KeyboardShortcutPlugin);
pluginRegistry.register(Plugins.RowCustomisationPlugin, RowCustomisationPlugin);
if (featureFlagService.isFeatureFlagSet(FeatureFlag.SavedViewPlugin)) {
    pluginRegistry.register(Plugins.SavedViewPlugin, SavedViewPlugin);
}
pluginRegistry.register(Plugins.ExportPlugin, ExportsPlugin);
pluginRegistry.register(Plugins.MaskedColumnPlugin, MaskedColumnPlugin);
pluginRegistry.register(Plugins.QuickFilterPlugin, QuickFilterPlugin);
pluginRegistry.register(Plugins.SearchablePlugin, SearchablePlugin);
pluginRegistry.register(Plugins.SizeAndDensityPlugin, SizeAndDensityPlugin);
pluginRegistry.register(Plugins.NotificationPlugin, NotificationPlugin);
pluginRegistry.register(Plugins.ZebraStripesPlugin, ZebraStripesPlugin);
pluginRegistry.register(Plugins.GridConfigurationPlugin, GridConfigurationPlugin);
