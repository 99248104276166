import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import CustomView from '../../../components/app/CustomView';
import {
  allViewsSelector, isOpenCustomViewModal, selectedModelView,
  groupedColumnsSelector, columnsMetadata, modelActiveMode, isModalLoading, duplicateViewName, getErrorObject,
  checkIsPageInErrorMode
} from '../../../selectors/pages/portfolio';
import {closeCustomViewModal, saveCustomView, deleteCustomView, editCustomView} from '../../../actions/page/portfolio';
import { eventAnalytics } from '../../../actions/sagas';
import { addPageContext } from '../../../actions/page';
import { labelSelector, getLabel } from '../../../selectors/app/index';
import {getSaveViewAnalyticsData} from '../analytics';
import Modal from '../../../components/core/Modal';

export const PortfolioCustomViewModel =(props) => {


  const handleClose =() => {
    props.closeModal({'modelActiveMode': '', 'isModalLoading': false, 'duplicateViewName': undefined, isPageInErrorMode: false});
  };

  const modalProps = {
    animation: true,
    suppressScrollOnActive: true,
    customModalStyle: {overflow: 'hidden'},
    open: props.open,
    handleClose
  };
  return (
    <Modal {...modalProps}>
      <CustomView {...props} />
    </Modal>
  );
};

const mapStateToProps = state => (
  {
    columnsMetadata: columnsMetadata(state),
    groupedColumns: groupedColumnsSelector(state),
    allViews: allViewsSelector(state),
    open: isOpenCustomViewModal(state),
    currentView: selectedModelView(state),
    labels: labelSelector(state),
    label: key => getLabel(state, key),
    modelActiveMode: modelActiveMode(state),
    isLoading: isModalLoading(state),
    duplicateViewName: duplicateViewName(state),
    errorObject: getErrorObject(state),
    isPageInErrorMode: checkIsPageInErrorMode(state)
  }
);

export const mapDispatchToProps = dispatch => (
  {
    closeModal: data => dispatch(closeCustomViewModal(data)),
    doAnalytics: data => dispatch(eventAnalytics(data)),
    saveCustomView: data => dispatch(saveCustomView(data)),
    editCustomView: data => dispatch(editCustomView(data)),
    deleteCustomView: data => dispatch(deleteCustomView(data)),
    addPageContext: (obj) => dispatch(addPageContext(obj)),
    dispatchViewSaveAnalytics: (viewColumns) => {
      const data = getSaveViewAnalyticsData(viewColumns);
      dispatch(eventAnalytics(data));
    }
  }
);

PortfolioCustomViewModel.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  errorObject: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioCustomViewModel);
