import { arrayHelper } from '@gs-ux-uitoolkit-common/datacore';
import { GridColumn } from '../../grid-wrappers/grid-wrapper';
import { RowCustomisation } from './row-customisation';

export class RowCustomisationHelper {
    public getPivotedApplyToColumnId(
        rowCustomisation: RowCustomisation,
        columns: GridColumn[]
    ): string | null {
        if (rowCustomisation.pivotKeys && rowCustomisation.pivotKeys.length) {
            const pivotedColumn = columns.find(col => {
                return (
                    arrayHelper.areIdentical(
                        col.pivotKeys || [],
                        rowCustomisation.pivotKeys || []
                    ) && col.primaryColumnId === rowCustomisation.columnId
                );
            });
            return (pivotedColumn && pivotedColumn.columnId) || rowCustomisation.columnId;
        }
        return rowCustomisation.columnId;
    }
}

export const rowCustomisationHelper = new RowCustomisationHelper();
