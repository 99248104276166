import { PropsWithChildren, useEffect, useState, FC } from 'react';
import ReactDOM from 'react-dom';

export interface SsrCompatiblePortalProps {
    /**
     * An optional HTML element in which the portal should be rendered. If a valid
     * element is not passed the parent will default to document.body
     */
    parentElement?: Element;
}

/**
 * Provides and SSR safe way to render elements outside the hierarchy of the parent
 * component's tree. This component renders the children ONLY after the element is mounted.
 * In UI Toolkit it's recommended to use this instead of createPortal to ensure
 * compatibility with NextJS & Gatsby.
 *
 * This is currently used by BottomSheet, Menu & Modal
 */
export const SsrCompatiblePortal: FC<PropsWithChildren<SsrCompatiblePortalProps>> = ({
    parentElement,
    children,
}) => {
    const [mounted, setMounted] = useState(false);

    // Portals cannot be created on the server side as we won't have a reference
    // to document.body. Use an empty effect to set a 'mounted' state that will
    // be executed on the client side that will be used to create the portal
    useEffect(() => {
        setMounted(true);
    }, []);

    // Ensure this is running on the client side (mounted === true) and 'document'
    // is available as a global variable before creating a react portal
    return mounted
        ? ReactDOM.createPortal(children, parentElement ? parentElement : document.body)
        : null;
};
