import {
    DOMAttributes,
    CSSProperties,
    FunctionComponent,
    useContext,
    ReactHTML,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
    ColProps as CommonColProps,
    colStyleSheet,
    ColSizeObject,
    getColClassNames,
    layoutDensities,
} from '@gs-ux-uitoolkit-common/layout';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { LayoutContext } from './layout-context';
import { componentAnalytics } from './analytics-tracking';

export interface ColProps extends DOMAttributes<HTMLElement>, CommonColProps<CSSProperties> {
    /**
     * Specifies the container HTML tag. Default is `div`.
     */
    tag?: string;
}

export const Col: FunctionComponent<ColProps> = (props: ColProps) => {
    const {
        className,
        density,
        noGutters,
        xs,
        sm,
        md,
        lg,
        xl,
        xxl,
        xxxl,
        tag: Tag = 'div',
        ...otherProps
    } = props;

    const context = useContext(LayoutContext);
    const { colsAtXs, colsAtSm, colsAtMd, colsAtLg, colsAtXl, colsAtXXl, colsAtXXXl } = context;
    const theme = useTheme();
    const cssClasses = useStyleSheet(colStyleSheet, {
        theme,
        breakpoints: context.breakpoints,
        grid: context.grid,
        density: density || context.density,
        noGutters: noGutters !== undefined ? noGutters : context.noGutters,
        xs,
        sm,
        md,
        lg,
        xl,
        xxl,
        xxxl,
        colsAtXs,
        colsAtSm,
        colsAtMd,
        colsAtLg,
        colsAtXl,
        colsAtXXl,
        colsAtXXXl,
    });
    const rootClasses = getColClassNames({ cssClasses, className });

    const TypedTag = Tag as keyof ReactHTML;

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({
            componentName: 'col',
            officialComponentName: 'other',
        });
    }, []); // Only run once

    return <TypedTag {...otherProps} className={rootClasses} data-gs-uitk-component="col" />;
};

function isValidNumber(num: any, min: number, max: number) {
    return !isNaN(+num) && +num >= min && +num <= max;
}

function isValidSizeObject(obj: ColSizeObject) {
    if (typeof obj !== 'object') {
        return false;
    }
    const { span, offset, order } = obj;
    if (span !== undefined && span !== 'auto' && !isValidNumber(span, 1, 12)) {
        return false;
    }
    if (offset !== undefined && !isValidNumber(offset, 0, 12)) {
        return false;
    }
    if (
        order !== undefined &&
        order !== 'first' &&
        order !== 'last' &&
        !isValidNumber(order, -1, 13)
    ) {
        return false;
    }
    return true;
}

export const sizePropType = (props: any, propName: string, componentName: string) => {
    const prop = props[propName];
    if (
        prop === undefined ||
        prop === 'auto' ||
        prop === true ||
        prop === false ||
        isValidNumber(prop, 1, 12) ||
        isValidSizeObject(prop)
    ) {
        return null;
    }
    return new Error(
        `Invalid prop "${propName}" supplied to "${componentName}". Validation failed.`
    );
};

Col.propTypes = {
    density: PropTypes.oneOf(layoutDensities),
    noGutters: PropTypes.bool,
    xs: sizePropType,
    sm: sizePropType,
    md: sizePropType,
    lg: sizePropType,
    xl: sizePropType,
    xxl: sizePropType,
    xxxl: sizePropType,
    tag: PropTypes.string,
};
