/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ThreeSquaresLoader } from '../../Loaders';
import Constants from '../../../../constants/appConstants';
import './index.scss';
import translator  from '../../../../services/translator';
import {Conditional} from "../../Conditional";

const {translate: t} = translator;

class Select extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};

    if(nextProps.options !== prevState.options) {
      const options = Number.isInteger(prevState.hoveredIndex) && prevState.hoveredIndex > -1 ?
        nextProps.options.map((option, i) => (i === prevState.hoveredIndex ? {...option, isHovered: true} : option)) :
        nextProps.options;
      newState = {...newState, options};
    }

    if(nextProps.selectedOption !== prevState.selectedOption || !nextProps.selectedOption) {
      newState = {...newState, selectedLabel: nextProps.selectedOption ? nextProps.selectedOption[nextProps.labelKey] : ''};
    }

    return Object.keys(newState).length ? newState : null;
  }

  constructor(props) {
    super(props);
    this.state = {
      hoveredIndex: -1,
      open: false,
      options: props.options,
      selectedLabel: props.selectedOption && props.labelKey ? props.selectedOption[props.labelKey] : '',
    };

    this.dropdown = React.createRef();
    this.menu = React.createRef();

    this.handleClose = this.handleClose.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidMount(){
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount(){
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const isOutside = (this.dropdown.current && this.menu.current && !(this.dropdown.current.contains(event.target) ||
      this.menu.current.contains(event.target)) );
    if ( this.state.open && isOutside ) {
      this.ignoreBlur = false;
      this.insideList = false;
      this.handleClose();
    } else if(!isOutside){
      this.ignoreBlur = true;
    }
  }

  handleClose() {
    if(!this.insideList){
      this.setState({open: false, hoveredIndex: -1});
    }
  }

  handleOpen() {
    this.insideList = true;
    this.setState({open: true});
  }

  handleSelect(selectedOption, e, interactionMode = Constants.ANALYTICS_MOUSE_INTERACTION, isCloseDropdown = true) {
    if (!isCloseDropdown) {
      e.preventDefault();
    } else {
      const {labelKey} = this.props;
      this.insideList = false;
      this.setState({
        selectedLabel: selectedOption[labelKey],
        open: false,
        hoveredIndex: -1
      });

      if (this.props.clickHandler) {
        this.props.clickHandler(selectedOption, interactionMode);
      }
    }
  }

  handleToggle() {
    if(!this.state.open) {
      this.insideList = true;
      this.handleOpen();
    } else {
      this.insideList = false;
      this.handleClose();
    }
  }

  handleBlur() {
    setTimeout(() => {
      if(!this.ignoreBlur) {
        this.insideList = false;
        this.handleClose();
      }
    }, 10);
  }

  handleKeyDown(e) {
    const {keyCode} = e;
    const {hoveredIndex, options } = this.state;
    switch(keyCode) {
      case 32:
        e.preventDefault();
        this.handleSelect(options[hoveredIndex], null, Constants.ANALYTICS_KEYBOARD_INTERACTION);
        break;
      case 9:
        this.handleToggle();
        break;
      case 13:
        this.handleSelect(options[hoveredIndex], null, Constants.ANALYTICS_KEYBOARD_INTERACTION);
        break;
      case 38:
        e.preventDefault();
        if(hoveredIndex > 0 && options.length > 1) {
          const newIndex = hoveredIndex - 1;
          this.setState({hoveredIndex: newIndex});
        }
        break;
      case 40:
        e.preventDefault();
        if(hoveredIndex < options.length - 1) {
          const newIndex = hoveredIndex + 1;
          this.setState({hoveredIndex: newIndex});
        }
        break;
      default:
        break;
    }
  }

  render() {
    const { hoveredIndex, selectedLabel, open, options} = this.state;
    const { labelStyle, optionRenderer, labelKey, menuStyle, isDisabled, isLoading, hasWarning, hasError, testId } = this.props;

    return (
      <div data-testid={testId} className="trade-dropdown" ref={this.dropdown}>
        <div
          className={`trade-dropdown__label ${hasWarning ? 'warning' : ''} ${hasError ? 'error' : ''} ${isDisabled ? 'trade-dropdown__label--disabled' : ''} `}
          tabIndex={isDisabled ? null : '0'}
          onMouseDown={this.handleToggle}
          onFocus={this.handleOpen}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          style={labelStyle}>
          <label data-testid={`${testId}-label`} className={cn('trade-dropdown__labelText', {'trade-dropdown__labelText--disabled': isDisabled})} data-tooltip={true} data-label={selectedLabel}>{selectedLabel}</label>
          { isDisabled ?
            <span className="trade-dropdown__icon trade-dropdown__icon--disabled">&nbsp;</span> :
            <span className={`trade-dropdown__icon ${open ? 'trade-dropdown__icon--open' : 'trade-dropdown__icon--closed'}`}>&nbsp;</span>
          }
        </div>
        <div className='scrollbar'>
          <ul
              ref={this.menu}
              onBlur={this.handleBlur}
              className={open ? 'trade-dropdown__menu scrollbar' : 'trade-dropdown__menu--closed'}
              style={menuStyle}
          >
            {
              <Conditional condition={isLoading}>
                <div className="loader-container">
                  <ThreeSquaresLoader />
                </div>
                {
                  options && options.length && optionRenderer ?
                    <>
                      {
                        options.map((option, i) => {
                          return (
                            <React.Fragment key={option.value || i}>
                              {optionRenderer({
                                hoverOption: this.handleKeyDown,
                                option,
                                selectOption: this.handleSelect,
                                selectedLabel,
                                labelKey,
                                isHovered: (i === hoveredIndex)
                              })}
                            </React.Fragment>
                          );
                        })
                      }
                    </> :
                    <div className="trade-dropdown__row">{t('tkNoResults')}</div>
                }
              </Conditional>
            }
          </ul>
        </div>
      </div>
    );
  }
}

Select.propTypes = {
  hasWarning: PropTypes.bool,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  clickHandler: PropTypes.func,
  labelStyle: PropTypes.object,
  selectedOption: PropTypes.object,
  menuStyle: PropTypes.object,
  options: PropTypes.array,
  optionRenderer: PropTypes.func,
  labelKey: PropTypes.string,
  testId: PropTypes.string
};

Select.defaultProps = {
  isDisabled: false,
  isLoading: false
};

export default Select;
