import { Component } from 'react';
import { FormGroup } from '@gs-ux-uitoolkit-react/form';
import { ColumnSelector } from '../../../../components/column-selector/column-selector';
import { GridColumn } from '../../../../grid-wrappers/grid-wrapper';
import { CustomSort } from '../../custom-sort-plugin';
import { Switch } from '@gs-ux-uitoolkit-react/switch';
import { DataType } from '@gs-ux-uitoolkit-common/datacore';
import { Label } from '@gs-ux-uitoolkit-react/label';

export interface CustomSortCreateProps {
    data: CustomSort;
    columns: GridColumn[];
    gridResetColumnValueList: (columnId: string) => void;
    gridGetColumnValueList: (columnId: string) => void;
    updateEditedConfigItem: (newData: Partial<CustomSort>) => void;
}

export interface CustomSortCreateState {
    nameIsNull: string;
    createButtonDisplayName: string;
}

export class CustomSortCreateComponent extends Component<CustomSortCreateProps> {
    public render() {
        const selectedColumn = this.props.columns.find(
            col => col.columnId === this.props.data.columnId
        );

        return (
            <>
                <Label>SELECT A COLUMN</Label>
                <div className={'gs-uitk-column-selector-main__container'}>
                    <FormGroup>
                        <ColumnSelector
                            autoSize={true}
                            size="sm"
                            columns={this.props.columns}
                            onChange={this.onChangeColumn}
                            selectedColumnId={this.props.data.columnId}
                        />
                    </FormGroup>
                    {selectedColumn ? (
                        selectedColumn.dataType === DataType.Number ? (
                            <FormGroup data-cy="customSort-absoluteSort-container">
                                <Switch
                                    defaultToggledOn={this.props.data.absoluteSort}
                                    onChange={this.onChangeAbsolute}
                                    name="toggle"
                                >
                                    Absolute Sort
                                </Switch>
                            </FormGroup>
                        ) : null
                    ) : null}
                </div>
            </>
        );
    }
    private onChangeColumn = (columnId: string) => {
        // we clear column values for the previously selected columnId
        this.props.gridResetColumnValueList(this.props.data.columnId);
        this.props.updateEditedConfigItem({ columnId, dataList: [] });
        this.props.gridGetColumnValueList(columnId);
    };

    private onChangeAbsolute = () => {
        // not using the event from the onchange as somehow the Switch always send `on`.....
        this.props.updateEditedConfigItem({
            absoluteSort: !this.props.data.absoluteSort,
            absoluteSortActive: !this.props.data.absoluteSort,
        });
    };
}
