export default function dropdownReducer(state, action) {

  const {direction, type, items, length, lastSelectedItem} = action;

  const getCurrentIndex = (() => {
    const index = (direction === 'UP') ? (state.currentIndex  > 0 ? state.currentIndex - 1 : length)
      : (state.currentIndex  < length ? state.currentIndex + 1 : 0);
    const element = document.getElementById(`${state.id}-item-${index}`);
    element && element.focus();
    return index;
  });

  switch (type){
    case 'UPDATE_LENGTH': return {...state, length};
    case 'UPDATE_ITEMS': return {...state, items};
    case 'UPDATE_APPLIED_OPTIONS_STATE':
      return {...state, lastAppliedOptionsState: state.items.map((item) => ({...item}))};
    case 'UPDATE_LAST_ITEM' :
      return { ...state, lastSelectedItem: Object.assign({}, lastSelectedItem)};
    case 'EXPAND': return {...state, expanded: true};
    case 'COLLAPSE': return {...state, expanded: false};
    case 'GOTO':
      return {...state, currentIndex: getCurrentIndex()};
    default:
      return state;
  }
}
