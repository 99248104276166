import { Expression, QuickFilter, ExpressionQuery } from '@gs-ux-uitoolkit-common/datacore';
import { Component, Fragment } from 'react';
import { GridColumn, GetFormattedValueFromColumn } from '../../../grid-wrappers/grid-wrapper';
import { DataGridState, ColumnValueListState } from '../../../redux/datagrid-state';
import { connect } from 'react-redux';
import { ExperiencedExpression } from '../../../components/expression/component/experienced-expression';
import { experiencedExpressionStyleSheet } from '../../../style/component/experienced-expression-stylesheet';
import { ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';

export interface QuickFilterAdvancedExpressionProps extends QuickFilterAdvancedExpressionOwnProps {
    columnValueList: ColumnValueListState[];
    quickFilterList: QuickFilter[];
}

export interface QuickFilterAdvancedExpressionOwnProps {
    expression: Expression;
    columns: GridColumn[];
    currentQuickFilter?: QuickFilter;
    showRowGroups?: boolean;
    gridGetColumnValueList: (columnId: string) => void;
    gridResetColumnValueList: (columnId: string | null) => void;
    onChangeExpression: (expression: Expression) => void;
    getFormattedValueFromColumn: GetFormattedValueFromColumn;
}

export class QuickFilterAdvancedExpressionComponent extends Component<QuickFilterAdvancedExpressionProps> {
    public componentWillUnmount() {
        experiencedExpressionStyleSheet.unmount(this);
    }
    public render() {
        return (
            <ThemeConsumer>
                {theme => {
                    const cssClasses = experiencedExpressionStyleSheet.mount(this, { theme });
                    return (
                        <Fragment>
                            <div className={cssClasses.root}>
                                <ExperiencedExpression
                                    onChangeExpressionQuery={this.onExpressionChange}
                                    expressionQuery={this.props.expression.query}
                                    columns={this.props.columns}
                                    columnValueList={this.props.columnValueList}
                                    gridGetColumnValueList={this.props.gridGetColumnValueList}
                                    gridResetColumnValueList={this.props.gridResetColumnValueList}
                                    quickFilterList={this.props.quickFilterList} // Not currently used in experienced mode
                                    currentQuickFilter={this.props.currentQuickFilter} // Not currently used in experienced mode
                                    getFormattedValueFromColumn={
                                        this.props.getFormattedValueFromColumn
                                    }
                                    showRowGroups={this.props.showRowGroups}
                                />
                            </div>
                        </Fragment>
                    );
                }}
            </ThemeConsumer>
        );
    }
    private onExpressionChange = (query: ExpressionQuery) => {
        this.props.onChangeExpression({ query, mode: this.props.expression.mode });
    };
}
const mapStateToProps = (state: DataGridState, ownProps: QuickFilterAdvancedExpressionOwnProps) => {
    return {
        columns: ownProps.columns,
        columnValueList: state.grid.columnValueList,
        expression: ownProps.expression,
        gridGetColumnValueList: ownProps.gridGetColumnValueList,
        gridResetColumnValueList: ownProps.gridResetColumnValueList,
        onChangeExpression: ownProps.onChangeExpression,
        quickFilterList: state.quickFilter.configItemList,
    };
};

export const QuickFilterAdvancedExpression = connect(mapStateToProps)(
    QuickFilterAdvancedExpressionComponent
);
