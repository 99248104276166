import { GridConfigurationActionKeys } from '../actions/action-constants';
import { GridConfigurationState } from '../datagrid-state';
import { GridConfigurationActionTypes } from '../actions/grid-configuration-action';
import { Plugins } from '../../plugins/plugin-enum';

/**
 * Initial state of the Export State
 */
export const defaultGridConfigurationState: GridConfigurationState = {
    currentPluginName: Plugins.ColumnHintPlugin,
    size: 'sm',
    density: 'standard',
};

/**
 * Reducer for the ExportState
 * @param state State of the ExportState
 * @param action Action received
 */
export const gridConfigurationReducer = (
    state: GridConfigurationState = defaultGridConfigurationState,
    action: GridConfigurationActionTypes
): GridConfigurationState => {
    switch (action.type) {
        case GridConfigurationActionKeys.OPEN_GRID_CONFIGURATION:
            return { ...state, currentPluginName: action.pluginName };
        case GridConfigurationActionKeys.SET_CURRENT_PLUGIN_NAME:
            return { ...state, currentPluginName: action.pluginName };
        case GridConfigurationActionKeys.SET_SIZE:
            return { ...state, size: action.size };
        case GridConfigurationActionKeys.SET_DENSITY:
            return { ...state, density: action.density };
        default:
            return state;
    }
};
