export function tradeActivityPageDataReducer(state = {}, action) {
  const { type } = action;
  switch (type) {
    default:
      return state;
  }
}

export function tradeActivityPageContextReducer(state = {}, action) {
  const { type } = action;
  switch (type) {
    default:
      return state;
  }
}
