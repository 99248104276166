import { Logger } from './logger';

declare global {
    // For globalThis
    let GS_UX_UITOOLKIT_VERSIONS_CHECK_DISABLE: boolean | 'hide-warnings';

    // For window
    interface Window {
        GS_UX_UITOOLKIT_VERSIONS_CHECK_DISABLE?: boolean | 'hide-warnings';
    }
    // For Global
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace NodeJS {
        interface Global {
            GS_UX_UITOOLKIT_VERSIONS_CHECK_DISABLE?: boolean | 'hide-warnings';
        }
    }
}

/**
 * This is used to track the different toolkit component used in an application
 * For ex:
 * {
 *     "firstRegisteredVersion": "12.1.1",
 *     "components": {
 *          "@gs-ux-uitoolkit-react/design-system" : "12.1.1",
 *          "@gs-ux-uitoolkit-react/header" : "12.1.1"
 *     }
 * }
 */
interface ToolkitVersionsTracker {
    /**
     * First tracked component registered. The name of the component is stored for error log.
     */
    firstRegisteredComponentName: string | undefined;
    /**
     * The version of the first tracked component registered. Its version will be used as a baseline for the other component
     */
    firstRegisteredComponentVersion: string | undefined;
    /**
     * Individual component versions tracking
     */
    components: {
        [componentName: string]: string;
    };
}

const GS_UX_UITOOLKIT_VERSIONS = 'GS_UX_UITOOLKIT_VERSIONS';

// List of all those deprecated packages whose versions are not updated on every major release
const VERSION_CHECK_SKIPPED_PACKAGES: string[] = [
    '@gs-ux-uitoolkit-react/d3-chart',
    '@gs-ux-uitoolkit-common/d3-chart',
    '@gs-ux-uitoolkit-angular/d3-chart',
    '@gs-ux-uitoolkit-react/graph',
    '@gs-ux-uitoolkit-common/graph',
    '@gs-ux-uitoolkit-angular/graph',
];
/**
 * Track the versions of the toolkit component in the application. If any version is different it will throw an exception unless UITOOLKIT_VERSIONS_CHECK_DISABLE is set to true
 * @param componentName The name of the component i.e. @gs-ux-uitoolkit-react/design-system
 * @param componentVersion The version of the component i.e. "12.1.1"
 */
export function trackComponentVersion(componentName: string, componentVersion: string) {
    const versions = getComponentVersionTracker();

    //we store the component versions purely for tracking and debugging purposes
    versions.components[componentName] = componentVersion;

    // if firstRegisteredVersion is not set it means that this component is the first one to be registered and therefor its version will be used as baseline
    if (!versions.firstRegisteredComponentVersion) {
        versions.firstRegisteredComponentVersion = componentVersion;
        versions.firstRegisteredComponentName = componentName;
    } else {
        // if there was already a firstRegisteredVersion set then we just compare with the version of the component and log an error when different
        // if GS_UX_UITOOLKIT_VERSIONS_CHECK_DISABLE value is 'hide-warnings' then we not display any message
        if (
            versions.firstRegisteredComponentVersion !== componentVersion &&
            getGlobalThis().GS_UX_UITOOLKIT_VERSIONS_CHECK_DISABLE !== 'hide-warnings'
        ) {
            if (
                getGlobalThis().GS_UX_UITOOLKIT_VERSIONS_CHECK_DISABLE === true ||
                VERSION_CHECK_SKIPPED_PACKAGES.indexOf(componentName) > -1
            ) {
                // Log as info() to to avoid CI failures checking for warnings
                Logger.info(
                    `GS_UX_UITOOLKIT_VERSIONS_CHECK_DISABLE global has been set 'true'. Mixed versions of UI Toolkit is allowed.\nUI Toolkit package versions registered: `,
                    versions
                );
            } else {
                Logger.error(
                    'UI Toolkit versions registered: ',
                    versions,
                    '\n',
                    `${componentName}: ${componentVersion} is different from the first registered toolkit component version: ${versions.firstRegisteredComponentName}: ${versions.firstRegisteredComponentVersion}\n`,
                    '\n',
                    `For more information, refer to error 'mixed-ui-toolkit-versions' on https://ui.web.gs.com/docs/react/error-descriptions`
                );
            }
        }
    }
}

function getComponentVersionTracker(): ToolkitVersionsTracker {
    const globalThis = getGlobalThis();

    // if it's the first time it's being called we create the instance
    if (!(globalThis as any)[GS_UX_UITOOLKIT_VERSIONS]) {
        const newVersionTracker: ToolkitVersionsTracker = {
            firstRegisteredComponentName: undefined,
            firstRegisteredComponentVersion: undefined,
            components: {},
        };
        (globalThis as any)[GS_UX_UITOOLKIT_VERSIONS] = newVersionTracker;
    }
    return (globalThis as any)[GS_UX_UITOOLKIT_VERSIONS];
}

/**
 * Naive implementation until Node 12 is used on all projects and we can have globalThis
 */
function getGlobalThis() {
    if (typeof globalThis !== 'undefined') return globalThis;
    // @ts-expect-error (Can't seem to get TypeScript to recognize 'global')
    if (typeof global !== 'undefined') return global;
    if (typeof window !== 'undefined') return window;
    throw new Error('Unable to locate global `this`');
}
