import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { ContainerProps, defaultContainerProps } from './container-props';
import { ColProps, ColSize, ColSizeOrder } from './col-props';
import { RowProps } from './row-props';
import { breakpoints as defaultBreakpoints } from './breakpoints';
import { grid as defaultGrids } from './grid';
import { DeepReadonly } from 'ts-essentials';
import './col-theme-overrides';

export interface ColStyleSheetProps {
    theme: Theme;
    breakpoints?: RowProps['breakpoints'];
    grid?: RowProps['grid'];
    density?: ColProps['density'];
    noGutters?: ContainerProps['noGutters'];
    xs?: ColProps['xs'];
    sm?: ColProps['sm'];
    md?: ColProps['md'];
    lg?: ColProps['lg'];
    xl?: ColProps['xl'];
    xxl?: ColProps['xxl'];
    xxxl?: ColProps['xxxl'];
    colsAtXs?: RowProps['colsAtXs'];
    colsAtSm?: RowProps['colsAtSm'];
    colsAtMd?: RowProps['colsAtMd'];
    colsAtLg?: RowProps['colsAtLg'];
    colsAtXl?: RowProps['colsAtXl'];
    colsAtXXl?: RowProps['colsAtXXl'];
    colsAtXXXl?: RowProps['colsAtXXXl'];
}

export interface ColCssClasses {
    root: string;
}

export type ColStyledClasses = CssClassDefinitionsObject<keyof ColCssClasses>;

export interface ColStyleOverridesParams {
    props: DeepReadonly<ColStyleSheetProps>;
    createDefaultStyledClasses: () => ColStyledClasses;
}

export const colStyleSheet = new StyleSheet('col', (props: ColStyleSheetProps) => {
    return createComponentClassDefinitions<ColStyleSheetProps, ColStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.col
    );
});

const widths = [
    '8.33333%',
    '16.66667%',
    '25%',
    '33.33333%',
    '41.66667%',
    '50%',
    '58.33333%',
    '66.66667%',
    '75%',
    '83.33333%',
    '91.66667%',
    '100%',
];

const rowColWidths = [
    '100%',
    '50%',
    '33.33333%',
    '25%',
    '20%',
    '16.66667%',
    '14.28571%',
    '12.5%',
    '11.11111%',
    '10%',
    '9.0909%',
    '8.33333%',
];

function getOrder(order?: ColSizeOrder) {
    if (order === 'first') {
        return -1;
    } else if (order === 'last') {
        return 13;
    } else if (order !== undefined) {
        return order;
    }
    return 12;
}

function getColSpanStyle(maxWidth: string) {
    return {
        flex: `0 0 ${maxWidth}`,
        maxWidth,
    };
}

function getColAuto() {
    return {
        flex: '0 0 auto',
        width: 'auto',
        maxWidth: '100%',
    };
}

function getColDefault() {
    return {
        flexBasis: 0,
        flexGrow: 1,
        maxWidth: '100%',
    };
}

function getStyleForBreakpoint(size?: ColSize, rowCols?: RowProps['colsAtXs']) {
    if (size !== undefined) {
        if (size === false) {
            // Do not add any layout styling.
            return {};
        }
        if (size === 'auto') {
            return getColAuto();
        }
        const sizeNum = +size;
        if (!isNaN(sizeNum) && sizeNum >= 1 && sizeNum <= 12) {
            return getColSpanStyle(widths[sizeNum - 1]);
        }
        if (typeof size === 'object') {
            const { span, offset, order } = size;
            let styleSpan, styleOffset, styleOrder;
            if (span !== undefined) {
                if (span === 'auto') {
                    styleSpan = getColAuto();
                } else {
                    const spanNum: number = +span;
                    if (!isNaN(spanNum) && spanNum >= 1 && spanNum <= 12) {
                        styleSpan = getColSpanStyle(widths[spanNum - 1]);
                    }
                }
            }
            if (offset !== undefined && offset >= 0 && offset <= 12) {
                styleOffset = {
                    marginLeft: offset === 0 ? '0' : widths[offset - 1],
                };
            }
            if (order !== undefined) {
                styleOrder = {
                    order: getOrder(order),
                };
            }
            if (styleSpan || styleOffset || styleOrder) {
                return {
                    ...(styleSpan || getColDefault()),
                    ...styleOffset,
                    ...styleOrder,
                };
            }
        }
    }
    if (rowCols && rowCols !== 'auto') {
        return getColSpanStyle(rowColWidths[rowCols - 1]);
    }
    return getColDefault();
}

function getBestSize(...sizes: (ColSize | undefined)[]) {
    for (let i = 0; i < sizes.length; i++) {
        if (sizes[i] !== undefined) {
            return sizes[i];
        }
    }
    return undefined;
}

function getSizesAt({ xs, sm, md, lg, xl, xxl, xxxl }: Partial<ColStyleSheetProps>) {
    return {
        xs,
        sm: getBestSize(sm, xs),
        md: getBestSize(md, sm, xs),
        lg: getBestSize(lg, md, sm, xs),
        xl: getBestSize(xl, lg, md, sm, xs),
        xxl: getBestSize(xxl, xl, lg, md, sm, xs),
        xxxl: getBestSize(xxxl, xxl, xl, lg, md, sm, xs),
    };
}

function getColsAt({
    colsAtXs,
    colsAtSm,
    colsAtMd,
    colsAtLg,
    colsAtXl,
    colsAtXXl,
    colsAtXXXl,
}: Partial<ColStyleSheetProps>) {
    return {
        colsAtXs,
        colsAtSm: colsAtSm || colsAtXs,
        colsAtMd: colsAtMd || colsAtSm || colsAtXs,
        colsAtLg: colsAtLg || colsAtMd || colsAtSm || colsAtXs,
        colsAtXl: colsAtXl || colsAtLg || colsAtMd || colsAtSm || colsAtXs,
        colsAtXXl: colsAtXXl || colsAtXl || colsAtLg || colsAtMd || colsAtSm || colsAtXs,
        colsAtXXXl:
            colsAtXXXl || colsAtXXl || colsAtXl || colsAtLg || colsAtMd || colsAtSm || colsAtXs,
    };
}

function createDefaultStyledClasses({
    breakpoints = defaultBreakpoints,
    density = defaultContainerProps.density,
    noGutters = defaultContainerProps.noGutters,
    grid: customGrid,
    ...sizeProps
}: ColStyleSheetProps): ColStyledClasses {
    const { colsAtXs, colsAtSm, colsAtMd, colsAtLg, colsAtXl, colsAtXXl, colsAtXXXl } =
        getColsAt(sizeProps);
    const { xs, sm, md, lg, xl, xxl, xxxl } = getSizesAt(sizeProps);

    const grid = customGrid || defaultGrids[density];

    const rootStyles = {
        display: 'block',
        position: 'relative',
        width: '100%',
        boxSizing: 'border-box',

        '& > *': {
            boxSizing: 'border-box',
        },
    } as const;

    return {
        root: {
            [`@media (min-width:${breakpoints.xs.minWidth})`]:
                xs !== false
                    ? {
                          ...rootStyles,
                          paddingLeft: noGutters ? 0 : `${grid.xs.gutter * 0.5}px`,
                          paddingRight: noGutters ? 0 : `${grid.xs.gutter * 0.5}px`,
                          ...getStyleForBreakpoint(xs, colsAtXs),
                      }
                    : {
                          display: 'none',
                      },
            [`@media (min-width:${breakpoints.sm.minWidth}px)`]:
                sm !== false
                    ? {
                          ...rootStyles,
                          paddingLeft: noGutters ? 0 : `${grid.sm.gutter * 0.5}px`,
                          paddingRight: noGutters ? 0 : `${grid.sm.gutter * 0.5}px`,
                          ...getStyleForBreakpoint(sm, colsAtSm),
                      }
                    : {
                          display: 'none',
                      },
            [`@media (min-width:${breakpoints.md.minWidth}px)`]:
                md !== false
                    ? {
                          ...rootStyles,
                          paddingLeft: noGutters ? 0 : `${grid.md.gutter * 0.5}px`,
                          paddingRight: noGutters ? 0 : `${grid.md.gutter * 0.5}px`,
                          ...getStyleForBreakpoint(md, colsAtMd),
                      }
                    : {
                          display: 'none',
                      },
            [`@media (min-width:${breakpoints.lg.minWidth}px)`]:
                lg !== false
                    ? {
                          ...rootStyles,
                          paddingLeft: noGutters ? 0 : `${grid.lg.gutter * 0.5}px`,
                          paddingRight: noGutters ? 0 : `${grid.lg.gutter * 0.5}px`,
                          ...getStyleForBreakpoint(lg, colsAtLg),
                      }
                    : {
                          display: 'none',
                      },
            [`@media (min-width:${breakpoints.xl.minWidth}px)`]:
                xl !== false
                    ? {
                          ...rootStyles,
                          paddingLeft: noGutters ? 0 : `${grid.xl.gutter * 0.5}px`,
                          paddingRight: noGutters ? 0 : `${grid.xl.gutter * 0.5}px`,
                          ...getStyleForBreakpoint(xl, colsAtXl),
                      }
                    : {
                          display: 'none',
                      },
            [`@media (min-width:${breakpoints.xxl.minWidth}px)`]:
                xxl !== false
                    ? {
                          ...rootStyles,
                          paddingLeft: noGutters ? 0 : `${grid.xxl.gutter * 0.5}px`,
                          paddingRight: noGutters ? 0 : `${grid.xxl.gutter * 0.5}px`,
                          ...getStyleForBreakpoint(xxl, colsAtXXl),
                      }
                    : {
                          display: 'none',
                      },
            [`@media (min-width:${breakpoints.xxxl.minWidth}px)`]:
                xxxl !== false
                    ? {
                          ...rootStyles,
                          paddingLeft: noGutters ? 0 : `${grid.xxxl.gutter * 0.5}px`,
                          paddingRight: noGutters ? 0 : `${grid.xxxl.gutter * 0.5}px`,
                          ...getStyleForBreakpoint(xxxl, colsAtXXXl),
                      }
                    : {
                          display: 'none',
                      },
        },
    };
}
