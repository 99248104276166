import { Properties } from 'csstype';
import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './flex-theme-overrides';

export type FlexStyleSheetProps = {
    theme: Theme;
    styles: Properties;
};

export interface FlexCssClasses {
    root: string;
}

export type FlexStyledClasses = CssClassDefinitionsObject<keyof FlexCssClasses>;

export interface FlexStyleOverridesParams {
    props: DeepReadonly<FlexStyleSheetProps>;
    createDefaultStyledClasses: () => FlexStyledClasses;
}

export const flexStyleSheet = new StyleSheet('flex', (props: FlexStyleSheetProps) => {
    return createComponentClassDefinitions<FlexStyleSheetProps, FlexStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.flex
    );
});

function createDefaultStyledClasses({ styles }: FlexStyleSheetProps): FlexStyledClasses {
    return {
        root: {
            ...styles,
        },
    };
}
