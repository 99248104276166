import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import FundFactView from '../../components/app/FundFact';
import {
  getFundLiterature, getActiveFundFact, updatedFundFactConfig, getRegistrationState,
  getErrorObject, checkIsPageInErrorMode, showFundTrackerButtonSelector, getRegistration,
  getInstruction, getStatement, getUnsavedRebateChanges, getCloseFundFactCard
} from '../../selectors/containers/fundFactView';
import { addPageContext } from '../../actions/page';
import { eventAnalytics } from '../../actions/sagas';
import { labelSelector, getLabel, fundIdentifierPreference } from '../../selectors/app/index';
import { fetchLiterature, fetchAccountRegistration, fetchSettlementInstruction,
  fetchStatement, downloadStatementPDF, fetchDebitCreditInfo,
  clearAccountDetailData, } from '../../actions/containers/fundFact';
import {addAppContext} from '../../actions/app';
import {fundFactCardTrackCTAEventData} from "../../modules/FundTracker/utils/analytics";
import {REBATE_NAVIGATION_WITH_UNSAVED_CHANGES} from "../../components/app/FundFact/FundRebate/constants";

export const FundFactViewModel = (props) => {
  return(
    props.activeFundFact ? <FundFactView {...props} /> : null
  );
};

export const mapStateToProps = state => (
  {
    resources : getFundLiterature(state),
    instruction: getInstruction(state),
    registration: getRegistration(state),
    registrationState: getRegistrationState(state),
    statementList: getStatement(state),
    activeFundFact: getActiveFundFact(state),
    labels: labelSelector(state),
    label: key => getLabel(state, key),
    config: updatedFundFactConfig(state),
    errorObject: getErrorObject(state),
    isPageInErrorMode: checkIsPageInErrorMode(state),
    showFundTrackerButton: showFundTrackerButtonSelector(state),
    fundIdentifierPreference: fundIdentifierPreference(state),
    unsavedRebateChanges: getUnsavedRebateChanges(state),
    closeFundFactCard: getCloseFundFactCard(state)
  }
);

export const mapDispatchToProps = dispatch => (
  {
    updateActiveFundFact: data => dispatch(addPageContext({activeFundFact: data})),
    sendAnalytics: (data) => dispatch(eventAnalytics(data)),
    fetchLiterature: id => dispatch(fetchLiterature(id)),
    fetchAccountRegistration: id => dispatch(fetchAccountRegistration(id)),
    fetchSettlementInstruction: () => dispatch(fetchSettlementInstruction()),
    fetchStatement: id => dispatch(fetchStatement(id)),
    downloadStatementPDF: data => dispatch(downloadStatementPDF(data)),
    clearLiterature: () => dispatch(addPageContext({'fundLiterature': []})),
    resetError: () => dispatch(addPageContext({isPageInErrorMode: false})),
    clickTrackCTA: (fundTrackerInitShareClassData) => {
      dispatch(eventAnalytics(fundFactCardTrackCTAEventData()));
      dispatch(addAppContext(fundTrackerInitShareClassData));
    },
    fetchDebitCreditInfo: id => dispatch(fetchDebitCreditInfo(id)),
    clearAccountDetailData: () => dispatch(clearAccountDetailData()),
    updateRebateStatusToNavigationWithUnsavedChanges: () => dispatch(addPageContext({fundRebateStatus: {type: REBATE_NAVIGATION_WITH_UNSAVED_CHANGES}})),
    unsetCloseFundFactCard: () => dispatch(addPageContext({closeFundFactCard: false}))
  }
);

FundFactViewModel.propTypes = {
  updateActiveFundFact: PropTypes.func,
  activeFundFact: PropTypes.object,
  fetchLiterature: PropTypes.func,
  registration: PropTypes.array,
  registrationState: PropTypes.number,
  fetchAccountRegistration: PropTypes.func,
  instruction: PropTypes.array,
  fetchSettlementInstruction: PropTypes.func,
  statementList: PropTypes.array,
  fetchStatement: PropTypes.func,
  downloadStatementPDF: PropTypes.func,
  resetError: PropTypes.func,
  clearLiterature: PropTypes.func,
  clickTrackCTA: PropTypes.func,
  showFundTrackerButton: PropTypes.bool,
  fundIdentifierPreference: PropTypes.string,
  fetchDebitCreditInfo: PropTypes.func,
  clearAccountDetailData: PropTypes.func,
  unsavedRebateChanges: PropTypes.bool,
  updateRebateStatusToNavigationWithUnsavedChanges: PropTypes.func,
  closeFundFactCard: PropTypes.bool,
  unsetCloseFundFactCard: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(FundFactViewModel);
