import { ComponentProps, CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { FormSize, FormStatus } from './form-types';

export interface FormFeedbackProps<StyleType = CommonStyleType> extends ComponentProps<StyleType> {
    /**
     * Sets font size and other styling appropriate for the size.
     */
    size?: FormSize;

    /**
     * By default, FormFeedback displays in an error color. If set to "success",
     * then the color will be a success color. If set to "none", the component
     * will not display at all because its sole purpose is to display status; to
     * display information that does not depend upon the current status, use a
     * FormText component.
     */
    status?: FormStatus;

    /**
     * Whether or not the text appears with disabled styling
     */
    disabled?: boolean;
}

export const defaultFormFeedbackProps: FormFeedbackProps = {
    size: 'md',
    disabled: false,
};
