import { useContext, CSSProperties, ReactNode, FC, memo } from 'react';
import PropTypes from 'prop-types';
import { cardStyleSheet, getCardSubtitleClasses } from '@gs-ux-uitoolkit-common/card';

import { ComponentProps } from '@gs-ux-uitoolkit-react/component';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { CardContext } from './card-context';

export interface CardSubtitleProps extends ComponentProps<CSSProperties> {
    children?: ReactNode;
}

/**
 * CardSubtitle styles the subtitle of a Card appropriately.
 */
export const CardSubtitle: FC<CardSubtitleProps> = memo(props => {
    const { className, children, ...attributes } = props;

    const theme = useTheme();
    const { size } = useContext(CardContext);
    const cssClasses = useStyleSheet(cardStyleSheet, { theme, size });

    return (
        <div
            data-gs-uitk-component="card-subtitle"
            {...attributes}
            className={getCardSubtitleClasses({ cssClasses, className })}
        >
            {children}
        </div>
    );
});
CardSubtitle.displayName = 'CardSubtitle';

CardSubtitle.propTypes = {
    className: PropTypes.string,
};
