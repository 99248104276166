import { ComponentProps, CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { ColorName, Size, Shape } from '@gs-ux-uitoolkit-common/design-system';
import { LoadingIconCssClasses } from './loading-icon-style-sheet';

export interface LoadingIconProps<StyleType = CommonStyleType> extends ComponentProps<StyleType> {
    /**
     * Style classes to override.
     */
    classes?: Partial<LoadingIconCssClasses>;

    /**
     * Loading label to display - true to display default or a custom label string.
     */
    label?: string | boolean;

    /**
     * Color of the loading icon.
     */
    color?: LoadingIconColorName;

    /**
     * Size of the loading icon.
     */
    size?: LoadingIconSize;

    /**
     * Shape of the loading icon.
     */
    shape?: LoadingIconShape;
}

export type LoadingIconColorName = Extract<ColorName, 'blue' | 'gray'>;
export type LoadingIconSize = Extract<Size, 'sm' | 'md' | 'lg'>;
export type LoadingIconShape = Extract<Shape, 'square' | 'circle'>;

export const loadingIconColorNames: LoadingIconColorName[] = ['blue', 'gray'];
export const loadingIconSizes: LoadingIconSize[] = ['sm', 'md', 'lg'];
export const loadingIconShapes: LoadingIconShape[] = ['square', 'circle'];

export type LoadingIconDefaultProps = Required<
    Pick<LoadingIconProps, 'label' | 'color' | 'size' | 'shape'>
>;

export const loadingIconDefaultProps: LoadingIconDefaultProps = {
    label: false,
    color: 'blue',
    size: 'md',
    shape: 'square',
};
export const loadingIconDefaultLabelText = 'Loading...';
