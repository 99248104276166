import { all, call, takeEvery, put, select, spawn } from 'redux-saga/effects';
import isObject from 'lodash/isObject';
import actionTypes from '../../../../actions';
import ServiceManager from '../../../../services';
import loginEndPointsMapper from '../../../../configs/endPointsMapper/login';
import endPointsMapper from '../../../../configs/endPointsMapper';
import GLSError from '../../../../error';
import errorTypes from '../../../../error/errorType';
import Constants from '../../../../constants/appConstants';
import pageConfig from '../../../../configs/pageConfig/login';
import {isPageLoading} from '../../../../actions/page';
import {pageIdSelector} from '../../../../selectors/pages';
import {
  loginActionWatchers, fetchDeepLinkMappings, fetchLoginAppLabels, fetchPINGConfig, initialLogin,
  fetchLoginAEMConfiguration, fetchHelpContents, createPasswordPageLoad, loginPageLoad, createPinPageLoad
} from '../../pages/login';
import { addAppContext, updateAppLoading} from '../../../../actions/app';
import { labelSelector } from '../../../../selectors/app';
import  { userSelector, isTradingAllowedSelector } from '../../../../selectors/user';
import {isInterstitialPage} from '../../../../utils/commonUtils';
import {clearActivityErrorSession} from '../../../../utils/tradeApprovals';
import  { pingConfigSelector } from '../../../../selectors/pages/login';
import {eventAnalytics} from '../../../../analytics';

export default function* loginRootSaga() {
  const {NODE_ENV} = process.env;
  yield put(addAppContext({NODE_ENV})); // Add env on app load
  yield spawn(onAppInit); // Start first thread for app
}

export function* onAppInit() {
  try {
    yield put(updateAppLoading(true));
    if (!isInterstitialPage()) {
      yield spawn(addActionWatchers);
    }
    yield call(fetchAppBootstrapData);
    if (!isInterstitialPage()) {
      yield call(fetchPingBootstrapData);
    }
    yield put(updateAppLoading(false));
  } catch (error) {
    yield put(updateAppLoading(false));
  }
}

export function* addActionWatchers() {
  yield spawn(appActionWatcher);
  yield spawn(loginActionWatchers);
}

export function* fetchAppBootstrapData(){
  yield spawn(fetchLoginAEMConfiguration);
  yield call(fetchLoginAppLabels);
  yield call(fetchDeepLinkMappings);
  yield call(fetchHelpContents);
}

export function* fetchPingBootstrapData() {
  yield call(fetchPINGConfig);
  yield call(initialLogin);
}

export function* appActionWatcher() {
  yield takeEvery(actionTypes.BACK, back);
  yield takeEvery(actionTypes.EVENT_ANALYTICS, doEventAnalytics);
  yield takeEvery(actionTypes.UPDATE_PAGE_ID, onPageLoad);
  yield takeEvery(actionTypes.LOGOUT, onLogout);
}

export function* back() {
  yield select(pageIdSelector);
}

export function* doEventAnalytics({data}) {
  const pageId = yield select(pageIdSelector);
  const userData = yield select(userSelector);
  const labels = yield select(labelSelector);
  const canTrade = yield select(isTradingAllowedSelector);
  if (isObject(data) && Object.keys(data).length) {
    eventAnalytics && eventAnalytics({pageId, data, userData, labels, tradeAccess: canTrade.toString()});
  }
}

export function* onPageLoad({data: {pageId = '', refreshPage = false}}) {
  window.scrollTo(0, 0);

  // If the user pressed on browser Back/Forward button
  if (refreshPage) {
    switch (pageId) {
      case pageConfig.Login.pageId:
      case pageConfig.ForgotPassword.pageId: {
        window.location.reload();
        break;
      }
      case pageConfig.CreatePin.pageId:
      case pageConfig.CreatePassword.pageId: {
        window.location.href = Constants.HOME_ROUTE;
        break;
      }
      default:
        console.error(`Load service not defined in redux-saga for ${pageId}`);
    }
  } else {
    switch (pageId) {
      case pageConfig.Login.pageId: {
        yield call(loginPageLoad);
        break;
      }
      case pageConfig.CreatePassword.pageId: {
        yield call(createPasswordPageLoad);
        break;
      }
      case pageConfig.CreatePin.pageId: {
        yield call(createPinPageLoad);
        break;
      }
      default:
        console.error(`Load service not defined in redux-saga for ${pageId}`);
    }
  }
}

export function* onLogout({data}) {
  const {reloadWindow = false} = data;
  const state = yield select();
  try {
    yield put(isPageLoading(true));
    const pingConfig = yield select(pingConfigSelector);
    const logoutArgs = { state, endPointConfig: loginEndPointsMapper.ACCOUNT_LOGOUT };
    const logoutApiArgs = { state, endPointConfig: endPointsMapper.LOGOUT };
    loginEndPointsMapper.COOKIE_LOGOUT.path = pingConfig.authURL + pingConfig.logoutParams;
    const logoutCookieArgs = {
      state, endPointConfig: loginEndPointsMapper.COOKIE_LOGOUT,
      serviceType: Constants.PING
    };
    yield all([
      call(ServiceManager, logoutArgs),
      call(ServiceManager, logoutApiArgs),
      call(ServiceManager, logoutCookieArgs)
    ]);
  } catch (error) {
    throw new GLSError(errorTypes.UNKNOWN_SERVICE_ERROR, error);
  } finally {
    // If we need to reload the window
    clearActivityErrorSession();
    if (reloadWindow) {
      window.location.reload();
    }
    yield put(isPageLoading(false));
  }
}
