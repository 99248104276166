import React from 'react';
import {createRoot} from 'react-dom/client';
import {ConnectedRouter} from 'connected-react-router';
import {Provider} from 'react-redux';
import LoginApp from '../../containers/LoginApp';
import history from '../../utils/history';
import {getLoginStore} from '../../store/storeModules/login';
import '../../utils/altTag';
import '../../stylesheets/main.scss';

const store = getLoginStore(history);
const rootContainer = document.getElementById('root');
const root = createRoot(rootContainer);

root.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <LoginApp />
    </ConnectedRouter>
  </Provider>
);
