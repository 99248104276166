// NOTE: Using a Set does not seem to work here. Possible that Chrome removes interned strings
// after they're not in use anymore, such as when a method exits? Using a key/value object instead
// fixes the issue
const displayedWarnings: { [key: string]: boolean } = {};

/**
 * Outputs a console warning once to the user. Subsequent calls to display the same text are
 * silent.
 *
 * This can be used to display warnings about, for instance, legacy APIs that should no longer
 * be used.
 */
export function logWarningOnce(warningMessage: string): void {
    if (!displayedWarnings[warningMessage]) {
        console.warn(warningMessage);
        displayedWarnings[warningMessage] = true;
    }
}
