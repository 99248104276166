import actionTypes from "./actionTypes";

export const updateInvestmentPolicyDataLoading = data => ({
  type: actionTypes.INVESTMENT_POLICY_DATA_LOADING,
  data: {isInvestmentPolicyDataLoading: data}
});


export const changeInvestmentPolicyTab = data => ({
  type: actionTypes.INVESTMENT_POLICY_TAB_CHANGE,
  data
});
