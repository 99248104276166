import moment from 'moment/moment';
import { isArray } from 'lodash';
import {
  fetchShareClassesAndBenchmarkData,
  fetchChartData,
  fetchBenchmarkChartData,
  saveFundTrackerThresholdForUser,
  getFundTrackerThresholdsForUser,
  getShareClassPerformanceData,
  fetchExportData
} from './ServiceManager';
import {
  getBenchmarkFieldValue,
  getHighChartSeries,
  getUpdateSeriesWithNameAndLegend,
  isChartSeriesDataEmpty,
  processServiceResponse,
  getAsOfDate,
  orderShareclassPerformance
} from '../utils';
import {ChartActionTypes} from '../actions';

export const fetchUserEntitledShareClassesAndBenchmarks = (run, chartDispatch, userGuid) => {
  run(
    fetchShareClassesAndBenchmarkData({userGuid})
      .then(shareClassAndBenchmarkData => {
        chartDispatch({
          type: ChartActionTypes.setShareClassAndBenchmarkData,
          data: {
            shareClassAndBenchmarkData
          }
        });
      })
  )
};

export const fetchShareClassPerformanceData = (
  asOfDate, shareClasses, run, chartDispatch
) => {
  const cusips = [];
  const isins = [];
  const orderedShareclassIds = [];
  shareClasses.forEach((shareClass) => {
    if (shareClass.cusip) {
      cusips.push(shareClass.cusip);
    } else if(shareClass.isin) {
      isins.push(shareClass.isin)
    }
    orderedShareclassIds.push(shareClass.id);
  });
  chartDispatch({
    type: ChartActionTypes.isFetchShareClsPerfDataInProgress,
    data: {isFetchShareClsPerfDataInProgress: true}
  });
  return run(
    getShareClassPerformanceData({
      asOfDate,
      cusips,
      isins
    })
      .then(({results}) => {
        const orederedResult = orderShareclassPerformance(results, orderedShareclassIds, "id")
        chartDispatch({
          type: ChartActionTypes.setShareClassPerformanceData,
          data: {
            results: orederedResult,
            asOfDate
          }
        });
        chartDispatch({
          type: ChartActionTypes.isFetchShareClsPerfDataInProgress,
          data: {isFetchShareClsPerfDataInProgress: false}
        });
      })
  );
};

export const fetchInitialFundSeries = (run, chartDispatch, primaryShareClassId, field, selectedFundsOrBenchmarks) => {
  const {fieldLabel, fieldValue} = field;
  const selectedFunds = selectedFundsOrBenchmarks.filter(item => item.type === "fund").map(({value}) => value);
  const shareclassIds = [primaryShareClassId, ...selectedFunds]
  run(
    fetchChartData({
      startDate: '2016-01-01',
      endDate: moment(new Date()).format("YYYY-MM-DD"), // '2022-01-01',
      shareclassIds,
      field: fieldValue
    })
      .then(data => {
        const chartSeriesData = [];
        const shareClassesData = [];
        const orderedData = shareclassIds.map(shareclassId => {
          if (data && isArray(data)) {
            return data.find(d => d.id === shareclassId);
          }
          return undefined;
        }).filter(obj => obj);
        orderedData.forEach((sd = {}) => {
          // Add the series to chart options
          const series = getHighChartSeries(sd, `${sd.id}-${fieldValue}`);
          chartSeriesData.push(series);
          const shareclass = {...sd.shareclass, value: sd.id};
          shareClassesData.push(shareclass);
        });

        if (chartSeriesData.length > 0) {
          const asOfDate = getAsOfDate(chartSeriesData[0].data);
          // Add the chart data to the context
          chartDispatch({
            type: ChartActionTypes.initialLoad,
            data: {
              series: chartSeriesData,
              id: primaryShareClassId,
              shareClassDetails: orderedData,
              fieldLabel,
              asOfDate
            }
          });

          if (shareclassIds.length > 1) {
            fetchShareClassPerformanceData(
              asOfDate,
              shareClassesData,
              run,
              chartDispatch
            );
          }
        }

        if (chartSeriesData.length === 0) {
          chartDispatch({
            type: ChartActionTypes.isFundOrBenchmarkDataMissing,
            data: {
              isFundOrBenchmarkDataMissing: chartSeriesData.length === 0
            }
          });
        } else {
          const isFundOrBenchmarkSeriesDataMissing = isChartSeriesDataEmpty(chartSeriesData[0]);
          chartDispatch({
            type: ChartActionTypes.isFundOrBenchmarkSeriesDataMissing,
            data: {
              isFundOrBenchmarkSeriesDataMissing
            }
          });
        }
      })
  );
};

const fetchSeries = async (
  shareClasses, fieldValue, run, chartDispatch, updatedChartOptions,
  {chartInstance, allShareClassData, primaryShareClassId}, addShareClassData = true,
  yAxisLabel, selectedMetrics, allSelectedShareclasses
) => {
  const shareclassIds = shareClasses.map(item => item.value);
  const completeData = await fetchChartData({
    startDate: '2016-01-01',
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    shareclassIds,
    field: fieldValue
  })
  const {asOfDate, shareclasses} = processServiceResponse(
    allShareClassData,
    updatedChartOptions,
    yAxisLabel,
    completeData,
    fieldValue,
    addShareClassData,
    chartDispatch,
    chartInstance,
    primaryShareClassId,
    selectedMetrics,
    allSelectedShareclasses
  );
  if (asOfDate) {
    fetchShareClassPerformanceData(
      asOfDate,
      shareclasses,
      run,
      chartDispatch
    );
  }
  return completeData;
}

export const fetchAdditionalFundsSeriesData = (
  shareClasses, fieldValue, run, chartDispatch, updatedChartOptions,
  {chartInstance, allShareClassData, primaryShareClassId, benchmarks}, addShareClassData = true,
  yAxisLabel, selectedMetrics, allSelectedShareclasses
) => {
  return run(
    fetchSeries(
      shareClasses, fieldValue, run, chartDispatch, updatedChartOptions,
      {chartInstance, allShareClassData, primaryShareClassId, benchmarks}, addShareClassData,
      yAxisLabel, selectedMetrics, allSelectedShareclasses
    )
  );
};

export const fetchAdditionalBenchmarkSeriesData = (
  benchmarks, fieldValue, run, chartDispatch, updatedChartOptions,
  {chartInstance, allShareClassData, primaryShareClassId}, addShareClassData = true,
  yAxisLabel, selectedMetrics
) => {
  const benchmarkIds = benchmarks.map(({value}) => value);
  return run(
    fetchBenchmarkChartData({
      startDate: '2016-01-01',
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      benchmarkIds,
      field: fieldValue
    })
      .then(completeData => {
        processServiceResponse(
          allShareClassData,
          updatedChartOptions,
          yAxisLabel,
          completeData,
          fieldValue,
          addShareClassData,
          chartDispatch,
          chartInstance,
          primaryShareClassId,
          selectedMetrics
        );
      })
  );
};

export const addMetricToSeries = (
  metrics,
  run,
  chartDispatch,
  updatedChartOptions,
  {primaryShareClassId, allShareClassData},
  selectedMetrics,
  addAxisOpposite = true
) => {
  const {value, label: yAxisLabel} = metrics;
  const yAxisId = `${primaryShareClassId}-${value}`;
  run(
    fetchChartData({
      startDate: '2016-01-01',
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      shareclassIds: [primaryShareClassId],
      field: value
    })
      .then(completeData => { // Need to take care for errors.
        completeData.forEach(data => {
          const clonedChartOptions = {...updatedChartOptions};
          const isSecondaryYAxis = clonedChartOptions.yAxis.length >= 1;
          // Add the series
          const chartSeriesData = getHighChartSeries(data, yAxisId, yAxisLabel, isSecondaryYAxis);
          clonedChartOptions.series.push(chartSeriesData);
          // Add the Y axis
          clonedChartOptions.yAxis.push({
            title: {
              text: yAxisLabel
            },
            opposite: addAxisOpposite,
            showLastLabel: true
          });
          // Update the name and legend of the series accordingly
          const updateSeries = getUpdateSeriesWithNameAndLegend(
            clonedChartOptions.series,
            allShareClassData,
            primaryShareClassId,
            selectedMetrics
          );
          chartDispatch({
            type: ChartActionTypes.updateChartOptions,
            data: {
              chartOptions: {
                ...clonedChartOptions,
                series: updateSeries
              }
            }
          });
          const isMetricDataMissing = isChartSeriesDataEmpty(chartSeriesData);
          chartDispatch({
            type: ChartActionTypes.isMetricDataMissing,
            data: {
              isMetricDataMissing
            }
          });
        });
      })
  );
};

export const fetchFundsOrBenchmarksData = (
  run,
  fieldForQuery,
  benchmarkMetricsOptions,
  selectedFundsOrBenchMarks,
  chartDispatch,
  chartOptions,
  chartState,
  addShareClassData,
  yAxisLabel,
  selectedMetrics,
  allSelectedShareclasses
) => {
  const {fieldValue} = fieldForQuery;
  const addedFunds = selectedFundsOrBenchMarks.filter(({type}) => type === 'fund');
  const addedBenchmarks = selectedFundsOrBenchMarks.filter(({type}) => type === 'benchmark');
  if (addedFunds.length > 0) {
    fetchAdditionalFundsSeriesData(
      addedFunds,
      fieldValue,
      run,
      chartDispatch,
      chartOptions,
      chartState,
      addShareClassData,
      yAxisLabel,
      selectedMetrics,
      allSelectedShareclasses
    );
  }
  if (addedBenchmarks.length > 0) {
    fetchAdditionalBenchmarkSeriesData(
      addedBenchmarks,
      getBenchmarkFieldValue(benchmarkMetricsOptions, fieldValue),
      run,
      chartDispatch,
      chartOptions,
      chartState,
      addShareClassData,
      yAxisLabel,
      selectedMetrics
    )
  }
};

export const getFundTrackerThresholds = (run, chartDispatch, userGuid) => {
  run(
    getFundTrackerThresholdsForUser(userGuid)
      .then(thresholdList => {
        chartDispatch({
          type: ChartActionTypes.setThresholdList,
          data: {
            thresholdList
          }
        });
      })
  )
};

export const saveFundTrackerThreshold = (run, chartDispatch, thresholdPayload) => {
  run(
    saveFundTrackerThresholdForUser(thresholdPayload)
      .then(() => {
        chartDispatch({
          type: ChartActionTypes.isThresholdSaved,
          data: {
            isThresholdSaved: true
          }
        });

        // Reload the thresholds list
        getFundTrackerThresholds(
          run,
          chartDispatch,
          thresholdPayload.userGuid
        )
      })
  )
};

export const fetchFundTrackerExportData = (run, chartDispatch, startDate, endDate, shareclassIds, fieldValue) => {
  run(
    fetchExportData({
      startDate,
      endDate,
      shareclassIds,
      field: fieldValue
    })
      .then(excelExportData => {
        chartDispatch({
          type: ChartActionTypes.setExcelExportData,
          data: {
            excelExportData
          }
        });
      })
  )
};


