import { GridWrapper } from '../../grid-wrappers/grid-wrapper';
import { DataGridState, SavedViewState } from '../../redux/datagrid-state';
import { Categories, Plugins } from '../plugin-enum';
import { getSavedViewPluginModal } from './view/saved-view-plugin-modal';
import { getSavedViewPluginWidget } from './view/saved-view-plugin-widget';
import { ColumnRefreshPluginBase } from '../column-refresh/column-refresh-plugin-base';
import { PluginIcon } from '@gs-ux-uitoolkit-common/datacore';
import { ModuleIdentfier } from '../module-identfier';
import { OpenGridConfiguration } from '../../redux/actions/grid-configuration-action';
import { DataGridToolbarItem } from '../../toolbar-state';
import { SetSavedView } from '../../redux/actions/saved-view-action';

const mainIcon: PluginIcon = { name: 'grid-table-columns', type: 'filled' };
const componentId = 'SavedViewModal';

/**
 * The SavedView plugin. Allows the developers and end-users to create and save views of their datagrid
 */
export class SavedViewPlugin extends ColumnRefreshPluginBase<
    GridWrapper,
    DataGridState,
    SavedViewState
> {
    private onGridStateCallback: undefined | (() => void) = undefined;
    protected static requiredModules: ModuleIdentfier[] = [];

    constructor(wrapper: GridWrapper) {
        super(
            Plugins.SavedViewPlugin,
            Categories.Data,
            mainIcon,
            wrapper,
            state => state.savedView
        );
        this.screens = [
            {
                componentId,
                icon: mainIcon,
                label: 'Saved Views',
                screen: getSavedViewPluginModal(wrapper.getReduxStoreContext()),
                store: wrapper.getReduxStore(),
                context: wrapper.getReduxStoreContext(),
            },
        ];
        this.actions = [
            {
                action: OpenGridConfiguration(Plugins.SavedViewPlugin),
                componentId: 'SavedViewsShortcut' as DataGridToolbarItem,
                icon: mainIcon,
                label: 'Saved Views',
                store: wrapper.getReduxStore(),
                context: wrapper.getReduxStoreContext(),
            },
        ];
        this.widgets = [
            {
                componentId: 'SavedViewsWidget' as DataGridToolbarItem,
                hideIcon: true,
                icon: mainIcon,
                label: '',
                maxWidth: 178,
                minWidth: 178,
                name: 'Saved Views Widget',
                store: wrapper.getReduxStore(),
                context: wrapper.getReduxStoreContext(),
                widget: getSavedViewPluginWidget(
                    this.setGridStateChange,
                    wrapper.getReduxStoreContext()
                ),
                width: 178,
            },
        ];

        this.listenToGridEvents(this.wrapper);
    }

    private setGridStateChange = (onGridStateCallback: undefined | (() => void)) => {
        this.onGridStateCallback = onGridStateCallback;
    };

    private listenToGridEvents(wrapper: GridWrapper) {
        wrapper.listenToGridColumnStateChanges(this.onGridColumnStateChanged);
        wrapper.columnResized.subscribe(this.onGridColumnStateChanged);
    }
    private onGridColumnStateChanged = () => {
        if (this.onGridStateCallback) {
            this.onGridStateCallback();
        }
    };
    protected internalStop(): void {
        this.wrapper.columnResized.unsubscribe(this.onGridColumnStateChanged);
    }
    protected stateChangedOrStart(): void {
        if (this.shouldForceRefresh()) {
            const currentSavedView = this.getPluginState()?.currentSavedView;
            this.setRefreshComplete();
            if (currentSavedView) {
                this.wrapper.getReduxStore().dispatch(SetSavedView(currentSavedView));
            }
        }
    }
}
