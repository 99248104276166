import {useCallback, useLayoutEffect, useRef} from 'react';
import {useChartContext} from '../context';

export const useSafeDispatch = (dispatch) => {
  const mounted = useRef(false);

  useLayoutEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return useCallback((...args) => (
    mounted.current ? dispatch(...args) : undefined
  ), [dispatch]);
};

export const useAsync = (dispatch) => {
  const {dispatch: defaultDispatch} = useChartContext();
  if (!dispatch) {
    dispatch = defaultDispatch;
  }

  const run = useCallback(
    promise => {
      dispatch({type: 'pending'});
      promise.then(data => {
        dispatch({type: 'resolved', data});
      }).catch(error => {
        dispatch({type: 'error', error});
      });
    }, [dispatch]
  );

  return {
    run
  };
};
