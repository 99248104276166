import { Typography } from '@gs-ux-uitoolkit-common/design-system';
import { rgba } from 'polished';
import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { ModalSize } from './modal-props';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './modal-header-theme-overrides';

export interface ModalHeaderStyleSheetProps {
    theme: Theme;
    size: ModalSize;
}

export interface ModalHeaderCssClasses {
    root: string;
    titleContainer: string;
    subtitle: string;
    title: string;
    dismissButton: string;
}

export type ModalHeaderStyledClasses = CssClassDefinitionsObject<keyof ModalHeaderCssClasses>;

export interface ModalHeaderStyleOverridesParams {
    props: DeepReadonly<ModalHeaderStyleSheetProps>;
    createDefaultStyledClasses: () => ModalHeaderStyledClasses;
}

export const modalHeaderStyleSheet = new StyleSheet(
    'modalHeader',
    (props: ModalHeaderStyleSheetProps) => {
        return createComponentClassDefinitions<
            ModalHeaderStyleSheetProps,
            ModalHeaderStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.modalHeader);
    }
);

function createDefaultStyledClasses({
    theme,
    size,
}: ModalHeaderStyleSheetProps): ModalHeaderStyledClasses {
    let headerPadding = '16px 16px 8px';
    let titleVariant: Typography = 'heading05';
    let closeFontSize = '20px';
    let closeMarginTop = '-1px';
    let closeMarginRight = '-10px';

    if (size === 'sm') {
        headerPadding = '12px 12px 8px';
        titleVariant = 'heading06';
        closeFontSize = '16px';
        closeMarginTop = '-3px';
        closeMarginRight = '-6px';
    } else if (size === 'lg') {
        headerPadding = '20px 16px 8px';
        titleVariant = 'heading04';
        closeMarginTop = '3px';
    }
    return {
        root: {
            padding: headerPadding,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
        },
        subtitle: {
            ...theme.typography.caption01,
            colors: theme.text.secondary,
        },
        titleContainer: {},
        title: {
            ...theme.typography[titleVariant],
            margin: '0px',
        },
        // TODO: Replace with close-button
        dismissButton: {
            backgroundColor: 'transparent',
            border: 0,
            color: theme.text.tertiary,
            cursor: 'pointer',
            marginRight: closeMarginRight,
            marginTop: closeMarginTop,
            '&:focus': {
                outline: 'none',
            },
            '&:focus-visible': {
                outline: `1px solid ${theme.text.link}`,
            },
            span: {
                fontSize: closeFontSize,
                '&:hover': {
                    backgroundColor: rgba(`${theme.surface.bold}`, 0.1),
                },
            },
        },
    };
}
