import { PropsWithChildren, Component, MouseEvent } from 'react';
import { Alert } from '@gs-ux-uitoolkit-react/alert';
import { Button } from '@gs-ux-uitoolkit-react/button';
import { Collapse } from '@gs-ux-uitoolkit-react/collapse';
import { StyleSheet, CssClasses } from '@gs-ux-uitoolkit-react/style';
import { componentAnalytics } from './analytics-tracking';

export interface ErrorBoundaryProps {
    message?: string;
}
export interface ErrorBoundaryState {
    showCollapse: boolean;
    error: Error | null;
}

const overrideStyleSheet = new StyleSheet('error-override', {
    root: { position: 'absolute', right: '8px', top: '8px' },
    button: { color: 'white', '&:focus::before': { borderColor: 'white' } },
});

/**
 * Follow react pattern so we show an alert when a component throws an exception when rendering
 */
export class ErrorBoundary extends Component<
    PropsWithChildren<ErrorBoundaryProps>,
    ErrorBoundaryState
> {
    private overrideClasses: CssClasses<string>;

    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { error: null, showCollapse: false };
        this.overrideClasses = overrideStyleSheet.mount(this, null);
    }
    componentDidMount() {
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'other' });
    }

    componentWillUnmount() {
        overrideStyleSheet.unmount(this);
    }

    componentDidCatch(error: Error): void {
        this.setState({ error });
    }

    render() {
        if (this.state.error) {
            return (
                <div data-gs-uitk-component="error-boundary">
                    <Alert status="error">
                        {this.props.message
                            ? this.props.message
                            : 'An error occurred in this component'}
                        <Button
                            actionType="secondary"
                            emphasis="minimal"
                            classes={{
                                root: this.overrideClasses.root,
                                button: this.overrideClasses.button,
                            }}
                            onClick={this.toggleCollapse}
                            data-cy="gs-uitk-error-boundary-toggle"
                        >
                            {this.state.showCollapse ? 'Hide' : 'Show'} Error
                        </Button>
                        <Collapse
                            style={{ marginTop: '16px' }}
                            expanded={this.state.showCollapse}
                            data-cy="gs-uitk-error-boundary-collapse"
                        >
                            {this.state.error.message}
                            {this.state.error.stack ? this.state.error.stack : 'No stack trace'}
                        </Collapse>
                    </Alert>
                </div>
            );
        }
        return this.props.children;
    }
    toggleCollapse = (event: MouseEvent) => {
        event.stopPropagation();
        this.setState({
            showCollapse: !this.state.showCollapse,
        });
    };
}
