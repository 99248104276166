import { isBrowser } from '@gs-ux-uitoolkit-common/shared';
import { EnumHelper } from './helpers/enum-helper';

/**
 * This enum is used to map to existing window.UITOOLKIT_LOG_LEVEL configuration and as a consequence should remain lowercase
 */
enum LogLevels {
    error,
    warn,
    info,
    debug,
}

declare global {
    interface Window {
        UITOOLKIT_LOG_LEVEL: string | undefined;
    }
}

/**
 * Logger of the system
 * If window.UITOOLKIT_LOG_LEVEL is not declared, Log level by default is warn.
 */
export class Logger {
    public info(message: string, ...args: any[]) {
        if (this.getEnvLogLevel() >= LogLevels.info) {
            // tslint:disable-next-line:no-console
            console.log(message, ...args);
        }
    }
    public debug(message: string, ...args: any[]) {
        if (this.getEnvLogLevel() >= LogLevels.debug) {
            // tslint:disable-next-line:no-console
            console.log(message, ...args); // using .log() rather than .debug() to avoid having to also set level "Verbose" in Chrome.
        }
    }
    public warn(message: string, ...args: any[]) {
        if (this.getEnvLogLevel() >= LogLevels.warn) {
            // tslint:disable-next-line:no-console
            console.warn(message, ...args);
        }
    }
    public error(message: string, ...args: any[]) {
        if (this.getEnvLogLevel() >= LogLevels.error) {
            // tslint:disable-next-line:no-console
            console.error(message, ...args);
        }
    }
    private getEnvLogLevel(): LogLevels {
        if (!isBrowser || !window.UITOOLKIT_LOG_LEVEL) {
            return LogLevels.warn;
        }
        return EnumHelper.stringToEnum<LogLevels>(
            window.UITOOLKIT_LOG_LEVEL.toLowerCase(),
            LogLevels
        );
    }
}

export const logger = new Logger();
