import { Component } from 'react';
import { GridColumn } from '../../../../grid-wrappers/grid-wrapper';
import { CustomSort } from '../../custom-sort-plugin';
import { ColumnValueListState } from '../../../../redux/datagrid-state';
import { Label } from '@gs-ux-uitoolkit-react/label';
import { CustomSortColumnValuesCriteria } from './custom-sort-column-values-criteria';
import { LoadingIcon } from '@gs-ux-uitoolkit-react/loading';

/**
 * The props for CustomSortValueSelector
 */
export interface CustomSortValueSelectorProps {
    data: CustomSort;
    /**
     * Available column valueList for the selected column
     */
    columns: GridColumn[];
    columnValueList: ColumnValueListState | null;
    gridResetColumnValueList: (columnId: string) => void;
    gridGetColumnValueList: (columnId: string) => void;
    updateEditedConfigItem: (newData: Partial<CustomSort>) => void;
}

/**
 * The dropdown to select the data for the custom sort with a preview
 */
export class CustomSortValueSelectorComponent extends Component<CustomSortValueSelectorProps> {
    componentDidMount() {
        if (!this.props.data.absoluteSort) {
            this.props.gridGetColumnValueList(this.props.data.columnId);
        }
    }

    public render() {
        return this.props.columnValueList ? (
            <div className={'gs-uitk-columnValueSelection'}>
                <Label>ADD VALUES TO SORT</Label>
                {
                    <CustomSortColumnValuesCriteria
                        selectedColumnValues={this.props.data.dataList || []}
                        columnValueList={this.props.columnValueList || null}
                        onAddColumnValues={this.onAddColumnValues.bind(this)}
                        onRemoveColumnValues={this.onRemoveColumnValues.bind(this)}
                    ></CustomSortColumnValuesCriteria>
                }
            </div>
        ) : (
            <div className={`gs-uitk-custom-sort-loading-icon-container `}>
                <LoadingIcon className="gs-uitk-loading-icon" />
            </div>
        );
    }

    onAddColumnValues(newValues: string[]) {
        const updatedValues = [...this.props.data.dataList, ...newValues];
        this.props.updateEditedConfigItem({ dataList: updatedValues });
    }

    onRemoveColumnValues(newValues: string[]) {
        const updatedValues = this.props.data.dataList.filter(function (value) {
            return !newValues.includes(value);
        });
        this.props.updateEditedConfigItem({ dataList: updatedValues });
    }
}
