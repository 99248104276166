import { BadgeColor } from './badge-color';
import { BadgeEmphasis } from './badge-emphasis';
import { BadgeStatus } from './badge-status';

export interface BadgeDeprecationConvertedProps {
    color?: BadgeColor;
    status?: BadgeStatus;
    emphasis?: BadgeEmphasis;
}

const primaryAndInfo: BadgeDeprecationConvertedProps = {
    color: undefined,
    status: 'information',
    emphasis: 'bold',
};
const primaryAndInfoAndBlueLight: BadgeDeprecationConvertedProps = {
    color: 'blue',
    status: undefined,
    emphasis: 'subtle',
};
const successAndGreenLight: BadgeDeprecationConvertedProps = {
    color: 'green',
    status: undefined,
    emphasis: 'subtle',
};
const warningAndYellowLight: BadgeDeprecationConvertedProps = {
    color: 'yellow',
    status: undefined,
    emphasis: 'subtle',
};
const dangerAndRedLight: BadgeDeprecationConvertedProps = {
    color: 'red',
    status: undefined,
    emphasis: 'subtle',
};
const deprecatedColorMap: { [name: string]: BadgeDeprecationConvertedProps } = {
    // colors which don't have an equivalent name, mapped to a different color
    violet: { color: 'aqua', status: undefined, emphasis: 'bold' },
    'light-blue': { color: 'ultramarine', status: undefined, emphasis: 'bold' },
    gold: { color: 'gray', status: undefined, emphasis: 'bold' },

    // "light" colors which now need emphasis='subtle'
    'purple-light': { color: 'purple', status: undefined, emphasis: 'subtle' },
    'violet-light': { color: 'aqua', status: undefined, emphasis: 'subtle' },
    'blue-light': primaryAndInfoAndBlueLight,
    'light-blue-light': { color: 'ultramarine', status: undefined, emphasis: 'subtle' },
    'teal-light': { color: 'teal', status: undefined, emphasis: 'subtle' },
    'green-light': successAndGreenLight,
    'red-light': dangerAndRedLight,
    'orange-light': { color: 'orange', status: undefined, emphasis: 'subtle' },
    'gold-light': { color: 'gray', status: undefined, emphasis: 'subtle' },
    'yellow-light': warningAndYellowLight,

    // colors that are now represented by the "status" prop
    primary: primaryAndInfo,
    info: primaryAndInfo,
    success: { color: undefined, status: 'success', emphasis: 'bold' },
    warning: { color: undefined, status: 'warning', emphasis: 'bold' },
    danger: { color: undefined, status: 'error', emphasis: 'bold' },
    'primary-light': primaryAndInfoAndBlueLight,
    'info-light': primaryAndInfoAndBlueLight,
    'success-light': successAndGreenLight,
    'warning-light': warningAndYellowLight,
    'danger-light': dangerAndRedLight,
};

export function convertPropsForBackwardsCompat(
    badgeProps: BadgeDeprecationConvertedProps
): BadgeDeprecationConvertedProps {
    if (badgeProps.color == null) {
        return badgeProps;
    }

    const convertedProps: BadgeDeprecationConvertedProps = deprecatedColorMap[badgeProps.color];
    if (convertedProps) {
        const propValuesStringified = Object.entries(convertedProps).reduce((acc, [key, value]) => {
            return value ? `${acc}${key}="${value}" ` : acc;
        }, '');
        console.warn(
            `Badge: detected deprecated color "${badgeProps.color}" - colors rendered may not be expected. Please update to use ` +
                `the new props and values of the current API.  Converting to new props with values:\n  ${propValuesStringified}`
        );
        return convertedProps;
    }

    return badgeProps;
}
