import React from 'react';
import PropTypes from 'prop-types';
import AccountList from '../AccountList';
import ScopeList from '../ScopeList';
import translator from '../../../../../services/translator';
import './index.scss';

const {translate: t} = translator;

const PolicyScope = (props) => {
  const {
    scopes,
    isEnabled,
    createdBy,
    lastUpdatedBy,
    selectedAccounts,
    testId
  } = props;
  return (
    <div className='invt-policy-scope' data-testid={`${testId}-scope`}>
      <div className='invt-policy-scope__description'>
        <div className="invt-policy-scope__text" data-testid={`${testId}-created-by`}>{createdBy}</div>
        <div className="invt-policy-scope__text" data-testid={`${testId}-last-updated-by`}>{lastUpdatedBy}</div>
      </div>
      <div className='invt-policy-scope__content'>
        <div className='invt-policy-scope__section' >
          <div className="invt-policy-scope__title">{t('tkPolicyScope')}</div>
          <ScopeList placeholder={t('tkNoScopeDefined')} disabled={!isEnabled}  items={scopes}  />
        </div>
        <div className="invt-policy-scope__section">
          <div className="invt-policy-scope__title">{t('tkSelectedAccounts')}</div>
          <AccountList placeholder={t('tkNoAccountsSelected')} disabled={!isEnabled} items={selectedAccounts}  />
        </div>
      </div>
    </div>
  );};

PolicyScope.propTypes={
  isEnabled: PropTypes.bool.isRequired,
  scopes: PropTypes.array.isRequired,
  createdBy: PropTypes.string.isRequired,
  lastUpdatedBy: PropTypes.string.isRequired,
  selectedAccounts: PropTypes.array.isRequired,
  testId: PropTypes.string
};

export default PolicyScope;
