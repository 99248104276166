import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { ButtonSize } from '@gs-ux-uitoolkit-common/button';
import { getStatusStyles } from './input-style-sheet';
import { InputStatus } from './types';
import { getFormTheme } from './form-theme';
import { DeepReadonly } from 'ts-essentials';
import './input-group-button-theme-overrides';

export interface InputGroupButtonStyleSheetProps {
    theme: Theme;
    size: ButtonSize;
    status: InputStatus;
}

export interface InputGroupButtonCssClasses {
    root: string;
}

export type InputGroupButtonStyledClasses = CssClassDefinitionsObject<
    keyof InputGroupButtonCssClasses
>;

export interface InputGroupButtonStyleOverridesParams {
    props: DeepReadonly<InputGroupButtonStyleSheetProps>;
    createDefaultStyledClasses: () => InputGroupButtonStyledClasses;
}

export const inputGroupButtonStyleSheet = new StyleSheet(
    'input-group-button',
    (props: InputGroupButtonStyleSheetProps) => {
        return createComponentClassDefinitions<
            InputGroupButtonStyleSheetProps,
            InputGroupButtonStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.inputGroupButton);
    }
);

export const globalInputGroupButtonClass = 'gs-input-group-button';

function getButtonStyles({ theme }: { theme: Theme }) {
    const formTheme = getFormTheme(theme);

    return {
        lineHeight: 'normal',
        margin: '-1px',
        borderColor: formTheme.input.borderColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 0,
        transition: 'border-color 0.15s ease-in-out',

        '&:hover': {
            borderColor: formTheme.input.borderHoverColor,
            zIndex: 1,
        },
        '&:focus': {
            borderColor: theme.colorScheme.primary,
            zIndex: 2,
        },

        '&::before': {
            // This is the main thing: Button's own focus rect is disabled,
            // replaced by borders set as part of InputGroup.
            content: 'none',
        },
    };
}

function getTheme({ theme, status }: InputGroupButtonStyleSheetProps) {
    const buttonTheme = {
        ...getButtonStyles({ theme }),
        ...(status && getStatusStyles({ theme, status })),
    };
    return {
        margin: '0 -1px 0 0',
        '& button': {
            ...buttonTheme,
        },
    };
}

function createDefaultStyledClasses(
    props: InputGroupButtonStyleSheetProps
): InputGroupButtonStyledClasses {
    return {
        root: {
            ...getTheme(props),
        },
    };
}
