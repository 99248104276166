import { Component } from 'react';
import { ColumnHintFormatSection } from './column-hint-format-section';
import { Row, Col } from '@gs-ux-uitoolkit-react/layout';
import { ColumnHintControlNamedFormat } from '../formatControls/column-hint-control-named-format';
import { ColumnHintControlNegative } from '../formatControls/column-hint-control-negative';
import { ColumnHintControlPrefixSuffix } from '../formatControls/column-hint-control-prefix-suffix';
import { ColumnHintControlMultiplier } from '../formatControls/column-hint-control-multiplier';
import { FormatControlProps } from '../column-hint-format-manager';
import { columnFormattingPluginStylePrefix } from '../../../../../style/plugins/column-hint-plugin-stylesheet';
import { ColumnHintLayoutSection } from '../../../../../components/column-hint/layouts-section';
import { ColumnHintFontsSection } from '../../../../../components/column-hint/fonts-section';
import { ColumnHintColoursSection } from '../../../../../components/column-hint/colours-section';
import { ColumnHintOtherSection } from '../../../../../components/column-hint/other-section';
import { ColumnHintHyperlinksSection } from '../../../../../components/column-hint/hyperlinks-section';

export class ColumnHintNumberFormat extends Component<FormatControlProps> {
    public render() {
        return (
            <div>
                <ColumnHintFormatSection title="Numeric">
                    <Row>
                        <Col sm={12}>
                            <ColumnHintControlNamedFormat
                                columnHint={this.props.columnHint}
                                onChangeHint={this.props.onChangeHint}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <ColumnHintControlNegative
                                columnHint={this.props.columnHint}
                                onChangeHint={this.props.onChangeHint}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <div
                                className={`${columnFormattingPluginStylePrefix}_numeric-prefix-container`}
                            >
                                <div
                                    className={`${columnFormattingPluginStylePrefix}_numeric-prefix-sub-container`}
                                >
                                    <ColumnHintControlPrefixSuffix
                                        columnHint={this.props.columnHint}
                                        onChangeHint={this.props.onChangeHint}
                                    />
                                </div>
                                <ColumnHintControlMultiplier
                                    columnHint={this.props.columnHint}
                                    onChangeHint={this.props.onChangeHint}
                                />
                            </div>
                        </Col>
                    </Row>
                </ColumnHintFormatSection>
                <ColumnHintLayoutSection
                    columnHint={this.props.columnHint}
                    onChangeHint={this.props.onChangeHint}
                />
                <ColumnHintFontsSection
                    columnHint={this.props.columnHint}
                    onChangeHint={this.props.onChangeHint}
                />
                <ColumnHintColoursSection
                    columnHint={this.props.columnHint}
                    onChangeHint={this.props.onChangeHint}
                />
                <ColumnHintHyperlinksSection
                    columnHint={this.props.columnHint}
                    onChangeHint={this.props.onChangeHint}
                />
                <ColumnHintOtherSection
                    columnHint={this.props.columnHint}
                    onChangeHint={this.props.onChangeHint}
                />
            </div>
        );
    }
}
