/*
 * These colors were generated from @mq/aurora-scripts version 20191213.20191213.1975781
 * @mq/aurora-scripts/dist/colors
 */
export interface Color {
    name: ColorName; // example: 'blue'
    swatch: ColorSwatch; // example: '010'
    value: ColorValue; // example: 'blue010'
    hex: string; // example: '#f0f4fa'
}

/**
 * List of possible color names in the design system.
 */
export type ColorName =
    | 'gray'
    | 'red'
    | 'orange'
    | 'yellow'
    | 'lime'
    | 'green'
    | 'teal'
    | 'turquoise'
    | 'aqua'
    | 'blue'
    | 'ultramarine'
    | 'purple'
    | 'pink';

/**
 * Array of possible color names to allow for easier iteration in demos, etc.
 */
export const colorNames: ColorName[] = [
    'gray',
    'red',
    'orange',
    'yellow',
    'lime',
    'green',
    'teal',
    'turquoise',
    'aqua',
    'blue',
    'ultramarine',
    'purple',
    'pink',
];

/**
 * List of possible color swatches in the design system.
 */
export type ColorSwatch =
    | '010'
    | '020'
    | '030'
    | '040'
    | '050'
    | '060'
    | '070'
    | '080'
    | '090'
    | '100';

/**
 * Array of possible color names to allow for easier iteration in demos, etc.
 */
export const colorSwatches: ColorSwatch[] = [
    '010',
    '020',
    '030',
    '040',
    '050',
    '060',
    '070',
    '080',
    '090',
    '100',
];

/**
 * List of possible color values in the design system.
 */
export type ColorValue =
    | `${ColorName}${ColorSwatch}`
    | 'gray110' // A very dark shade of gray for text elements
    | 'black'
    | 'white'
    | 'transparent';

/**
 * List of possible color hex values in the design system.
 */
export type ColorHex =
    | '#f2f5f7'
    | '#dce3e8'
    | '#c1ccd6'
    | '#9fb1bd'
    | '#7a909e'
    | '#5b7282'
    | '#3e5463'
    | '#2a3f4d'
    | '#1c2b36'
    | '#0e171f'
    | '#090f14'
    | '#ebf3f7'
    | '#c9e7f5'
    | '#8bd3f7'
    | '#48b8f0'
    | '#1195d6'
    | '#0073ba'
    | '#08548a'
    | '#0e3d66'
    | '#0c2b45'
    | '#0b1724'
    | '#f0f4fa'
    | '#d4e4fa'
    | '#adccf7'
    | '#75b1ff'
    | '#3d8df5'
    | '#186ade'
    | '#0d4ea6'
    | '#103a75'
    | '#11294d'
    | '#0d1826'
    | '#ebf7ed'
    | '#c7ebd1'
    | '#88dba8'
    | '#43c478'
    | '#16a163'
    | '#077d55'
    | '#075e45'
    | '#094536'
    | '#092e25'
    | '#081a15'
    | '#ebf7da'
    | '#d5f0b1'
    | '#aad971'
    | '#78bf39'
    | '#52a31d'
    | '#3c7d0e'
    | '#2e5c0e'
    | '#254211'
    | '#1c2e10'
    | '#121a0d'
    | '#fcf2eb'
    | '#fcddc7'
    | '#fcbc97'
    | '#fc9162'
    | '#e86427'
    | '#bf4815'
    | '#8f3415'
    | '#632b17'
    | '#402117'
    | '#1f1410'
    | '#fcf0f8'
    | '#f7daed'
    | '#f7b7e2'
    | '#fa87d4'
    | '#ed4cb7'
    | '#cc1d92'
    | '#961574'
    | '#6b155a'
    | '#47153f'
    | '#241020'
    | '#f7f2fc'
    | '#eadcfc'
    | '#dabefa'
    | '#c89afc'
    | '#ac71f0'
    | '#8f49de'
    | '#6b30ab'
    | '#4c277d'
    | '#331f4d'
    | '#1c1229'
    | '#fcf0f0'
    | '#fadcd9'
    | '#fabbb4'
    | '#fc9086'
    | '#fa5343'
    | '#d91f11'
    | '#a1160a'
    | '#75160c'
    | '#4f150f'
    | '#24120c'
    | '#ebf5f4'
    | '#beebe7'
    | '#86d9d4'
    | '#4ebfb9'
    | '#279c9c'
    | '#167b7d'
    | '#155c5e'
    | '#124241'
    | '#102e2d'
    | '#0c1a19'
    | '#ebf5f4'
    | '#c7e8ed'
    | '#81d8e6'
    | '#45bcd1'
    | '#159ab3'
    | '#067a91'
    | '#09596b'
    | '#0c424d'
    | '#102d33'
    | '#0f181a'
    | '#f2f3fa'
    | '#dee0fa'
    | '#c2c7fc'
    | '#a0a7fa'
    | '#7681fc'
    | '#545fe8'
    | '#3a45b0'
    | '#2f337d'
    | '#23254d'
    | '#141429'
    | '#faf6cf'
    | '#f7e379'
    | '#f5c518'
    | '#d9a514'
    | '#b3870e'
    | '#946613'
    | '#70491c'
    | '#54341f'
    | '#38251b'
    | '#1c1613'
    | '#000000'
    | '#ffffff'
    | 'transparent';

export const colors: { [key in ColorValue]: ColorHex } = {
    gray010: '#f2f5f7',
    gray020: '#dce3e8',
    gray030: '#c1ccd6',
    gray040: '#9fb1bd',
    gray050: '#7a909e',
    gray060: '#5b7282',
    gray070: '#3e5463',
    gray080: '#2a3f4d',
    gray090: '#1c2b36',
    gray100: '#0e171f',
    gray110: '#090f14',
    aqua010: '#ebf3f7',
    aqua020: '#c9e7f5',
    aqua030: '#8bd3f7',
    aqua040: '#48b8f0',
    aqua050: '#1195d6',
    aqua060: '#0073ba',
    aqua070: '#08548a',
    aqua080: '#0e3d66',
    aqua090: '#0c2b45',
    aqua100: '#0b1724',
    blue010: '#f0f4fa',
    blue020: '#d4e4fa',
    blue030: '#adccf7',
    blue040: '#75b1ff',
    blue050: '#3d8df5',
    blue060: '#186ade',
    blue070: '#0d4ea6',
    blue080: '#103a75',
    blue090: '#11294d',
    blue100: '#0d1826',
    green010: '#ebf7ed',
    green020: '#c7ebd1',
    green030: '#88dba8',
    green040: '#43c478',
    green050: '#16a163',
    green060: '#077d55',
    green070: '#075e45',
    green080: '#094536',
    green090: '#092e25',
    green100: '#081a15',
    lime010: '#ebf7da',
    lime020: '#d5f0b1',
    lime030: '#aad971',
    lime040: '#78bf39',
    lime050: '#52a31d',
    lime060: '#3c7d0e',
    lime070: '#2e5c0e',
    lime080: '#254211',
    lime090: '#1c2e10',
    lime100: '#121a0d',
    orange010: '#fcf2eb',
    orange020: '#fcddc7',
    orange030: '#fcbc97',
    orange040: '#fc9162',
    orange050: '#e86427',
    orange060: '#bf4815',
    orange070: '#8f3415',
    orange080: '#632b17',
    orange090: '#402117',
    orange100: '#1f1410',
    pink010: '#fcf0f8',
    pink020: '#f7daed',
    pink030: '#f7b7e2',
    pink040: '#fa87d4',
    pink050: '#ed4cb7',
    pink060: '#cc1d92',
    pink070: '#961574',
    pink080: '#6b155a',
    pink090: '#47153f',
    pink100: '#241020',
    purple010: '#f7f2fc',
    purple020: '#eadcfc',
    purple030: '#dabefa',
    purple040: '#c89afc',
    purple050: '#ac71f0',
    purple060: '#8f49de',
    purple070: '#6b30ab',
    purple080: '#4c277d',
    purple090: '#331f4d',
    purple100: '#1c1229',
    red010: '#fcf0f0',
    red020: '#fadcd9',
    red030: '#fabbb4',
    red040: '#fc9086',
    red050: '#fa5343',
    red060: '#d91f11',
    red070: '#a1160a',
    red080: '#75160c',
    red090: '#4f150f',
    red100: '#24120c',
    teal010: '#ebf5f4',
    teal020: '#beebe7',
    teal030: '#86d9d4',
    teal040: '#4ebfb9',
    teal050: '#279c9c',
    teal060: '#167b7d',
    teal070: '#155c5e',
    teal080: '#124241',
    teal090: '#102e2d',
    teal100: '#0c1a19',
    turquoise010: '#ebf5f4',
    turquoise020: '#c7e8ed',
    turquoise030: '#81d8e6',
    turquoise040: '#45bcd1',
    turquoise050: '#159ab3',
    turquoise060: '#067a91',
    turquoise070: '#09596b',
    turquoise080: '#0c424d',
    turquoise090: '#102d33',
    turquoise100: '#0f181a',
    ultramarine010: '#f2f3fa',
    ultramarine020: '#dee0fa',
    ultramarine030: '#c2c7fc',
    ultramarine040: '#a0a7fa',
    ultramarine050: '#7681fc',
    ultramarine060: '#545fe8',
    ultramarine070: '#3a45b0',
    ultramarine080: '#2f337d',
    ultramarine090: '#23254d',
    ultramarine100: '#141429',
    yellow010: '#faf6cf',
    yellow020: '#f7e379',
    yellow030: '#f5c518',
    yellow040: '#d9a514',
    yellow050: '#b3870e',
    yellow060: '#946613',
    yellow070: '#70491c',
    yellow080: '#54341f',
    yellow090: '#38251b',
    yellow100: '#1c1613',
    black: '#000000',
    white: '#ffffff',
    transparent: 'transparent',
};
