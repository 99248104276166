import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Modal from '../../core/Modal';
import Button from '../../core/Button';
import RadioGroup from '../../../components/app/RadioGroup';
import translator  from '../../../services/translator';

import './index.scss';
import {Conditional} from '../../core/Conditional';
import Checkbox from '../../core/CheckBox';
const {translate: t} = translator;

const customModalStyle={position: 'fixed', top: '50%', left: '50%', height: 'auto', transform: 'translate(-50%, -50%)'};

const FileDownloadModal = ({isModalOpen, handleClose, handleDownload, handleFileTypeChange, options, isMoreThan20Columns, selectedFileType = 'pdf', reportName}) => {
  const downloadModalRef = useRef();

  const [fitToPage, setFitToPage] = useState(false);

  const onClose = (status) => {
    setFitToPage(false);
    downloadModalRef.current.closeModal(() => handleClose(status));
  };

  const handleFileDownload = (status) => {
    handleDownload(fitToPage);
    setFitToPage(false);
    downloadModalRef.current.closeModal(() => handleClose(status));
  };

  const onChange = (event, isChecked, option) => {
     handleFileTypeChange(option.id);
  };

  const onFitToPageChange = (event, isChecked) => {
    setFitToPage(isChecked);
  };

  const showWarning = isMoreThan20Columns && fitToPage;
  return(
    <Modal
      ref={downloadModalRef}
      customModalStyle={customModalStyle}
      suppressScrollOnActive={true}
      customClass="modal--center approval-modal file-download-modal__index"
      backdropCustomClass="file-download-modal__backdrop-index"
      open={isModalOpen}
      handleClose={handleClose}
      animation={false}
    >
      <div className="file-download-modal">
        {reportName && <div className="file-download-modal__reportName">{t(reportName)}</div>}
        <div className="file-download-modal__heading">{t('tkDownload')}</div>
        <div className="file-download-modal__inputContainer">
          <div className="file-download-modal__section">
            <div  className="file-download-modal__section--title">{t('tkSelectYourFormat')}</div>
            <div className="margin-neg-8px">
              <RadioGroup
                testId="file-download-radio-group"
                onChange={onChange}
                options={options}
                />
            </div>
            <Conditional condition={selectedFileType === 'pdf'}>
              <div className="checkbox-container">
                <Checkbox id='checkboxFitToWidth' isChecked={fitToPage} changeHandler={onFitToPageChange} label={t('tkFitToPageWidth')} value={true}/>
                <Conditional condition={showWarning}>
                  <div className='file-download-modal__warning'>{t('tkPDFDownloadMaxColumnWarning')}</div>
                </Conditional>
              </div>
            </Conditional>
          </div>
        </div>
        <div className="file-download-modal__buttonRow">
          <Button label={t('tkCancel')}   customClass='button-secondary-small' clickHandler={(event) => onClose(event)} />
          <Button label={t('tkDownload')} customClass='button-primary-small' clickHandler={(event) => handleFileDownload(event)} />
        </div>
      </div>
    </Modal>
  );
};

FileDownloadModal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDownload: PropTypes.func,
  handleFileTypeChange: PropTypes.func,
  options: PropTypes.array,
  selectedFileType: PropTypes.string,
  isMoreThan20Columns: PropTypes.bool,
  reportName: PropTypes.string
};

export default FileDownloadModal;
