export default function dropdownReducer(state, action) {

  const {type, startDate, endDate, selectedDate, buttonsList, selectedButtonId, value, maxDate} = action;
  switch (type){
    case 'UPDATE_SELECTED_DATE': return {...state, selectedDate};
    case 'UPDATE_DATE_RANGE': return {...state, startDate, endDate, selectedButtonId, value};
    case 'UPDATE_BUTTONS_LIST' : return { ...state, buttonsList};
    case 'UPDATE_BUTTONS_LIST_AND_RANGE' : return { ...state, buttonsList, startDate, endDate, selectedButtonId, value};
    case 'UPDATE_START_AND_END_DATES': return { ...state, startDate, endDate};
    case 'EXPAND': return {...state, expanded: true};
    case 'COLLAPSE': return {...state, expanded: false};
    case 'UPDATE_MAX_DATE': return {...state, maxDate};
    default:
      return state;
  }
}
