import { FC, PropsWithChildren, createContext, useContext } from 'react';
import { Emotion } from '@emotion/css/create-instance';
import { getEmotionInstance } from '@gs-ux-uitoolkit-common/style';

/**
 * When run on a browser, the UITK style implementation uses a singleton Emotion
 * instance stored on the global object. This utilizes a single shared cache for
 * all UITK components of potentially different UITK versions.
 *
 * However on Next.js's App Router, we need an Emotion instance per-request in
 * order to collect the styles for that particular request. And the only way to
 * provide this to the rest of the app for a given request is through React
 * Context. This is the context for that.
 *
 * When this Context has a value, it will be used by the styling implementation.
 * When it is `undefined`, the global instance should be used.
 */
export const EmotionInstanceContext = createContext<Emotion | undefined>(undefined);

export type EmotionInstanceProviderProps = PropsWithChildren<{
    emotion: Emotion;
}>;

export const EmotionInstanceProvider: FC<EmotionInstanceProviderProps> = ({
    emotion,
    children,
}) => {
    return (
        <EmotionInstanceContext.Provider value={emotion}>
            {children}
        </EmotionInstanceContext.Provider>
    );
};

/**
 * Returns the context-provided Emotion instance (if provided in the React
 * tree), or otherwise returns the global Emotion instance.
 */
export function useEmotionInstance(): Emotion {
    // Provide the global Emotion instance if no context instance has been
    // provided
    return useContext(EmotionInstanceContext) || getEmotionInstance();
}
