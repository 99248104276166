import {
    useContext,
    useEffect,
    useState,
    CSSProperties,
    HTMLAttributes,
    ReactNode,
    FC,
    MouseEvent,
} from 'react';
import {
    AccordionPanelProps as CommonAccordionPanelProps,
    accordionPanelDefaultProps,
    accordionPanelStyleSheet,
    getAccordionPanelClassNames,
    getAccordionPanelBodyClassNames,
    getAccordionPanelHeaderClassNames,
    getAccordionPanelSummaryClassNames,
    getAccordionPanelToggleClassNames,
    AccordionPanelToggleEvent,
} from '@gs-ux-uitoolkit-common/accordion';
import { Collapse } from '@gs-ux-uitoolkit-react/collapse';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { AccordionContext } from './accordion-context';

// @ts-expect-error: needed because api-extractor can not parse dynamic imports in .d.ts files
import { AccordionSize, AccordionEmphasis } from '@gs-ux-uitoolkit-common/accordion';

let counter = 0;
/**
 * Generate sequential IDs for accordion panels
 */
function getId(): number {
    return ++counter;
}

export interface AccordionPanelProps
    extends CommonAccordionPanelProps<CSSProperties>,
        HTMLAttributes<HTMLElement> {
    /**
     * Custom attributes for the button used to toggle expand.
     *
     * This can be used to set analytics attributes.
     */
    expandButtonAttrs?: { [attrName: string]: string };

    /**
     * Content to display in the header of the accordion panel.
     */
    header?: ReactNode;

    /**
     * Content to display in place of the panel when it is collapsed.
     */
    summary?: ReactNode;

    /**
     * Content to display when the panel is expanded.
     */
    children?: ReactNode;
}

export interface AccordionPanelState {
    expanded: boolean;
    id: string | null;
}

/**
 * AccordionPanel is a panel nested inside the 'Accordion' container component.
 */
export const AccordionPanel: FC<AccordionPanelProps> = props => {
    const {
        children,
        className,
        classes: overrideClasses,
        disabled = accordionPanelDefaultProps.disabled,
        expandButtonAttrs,
        hideExpandIcon = accordionPanelDefaultProps.hideExpandIcon,
        summary,
        header,
        toggleOnClick = accordionPanelDefaultProps.toggleOnClick,
        expanded: propsExpanded,
        defaultExpanded = accordionPanelDefaultProps.defaultExpanded,
        onExpand,
        onCollapse,
        onToggle,
        panelId,
        'aria-label': ariaLabel,
        ...attributes
    } = props;

    const [state, setState] = useState<AccordionPanelState>({
        expanded: !!(props.expanded || props.defaultExpanded),
        id: props.id || null,
    });

    const isUncontrolled = () => {
        return props.expanded == null;
    };

    const toggle = (event: MouseEvent) => {
        if (event) {
            event.preventDefault();
        }
        if (disabled || !toggleOnClick) {
            return;
        }
        const expanded = !state.expanded;
        if (isUncontrolled()) {
            setState({ ...state, expanded });
        }
        const { panelId } = props;
        panelToggledCallback({ expanded, panelId });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (!state.id) {
            // If an ID was not provided by the props generate a random string
            // that can be assigned to the panel content and the header. This is required
            // to ensure semantic connectivity between as well as a11y
            const randomId = `accordion-panel-${getId()}`;
            setState({ ...state, id: randomId });
        }
    });

    const context = useContext(AccordionContext);

    const theme = useTheme();

    const panelToggledCallback = (event: AccordionPanelToggleEvent) => {
        if (typeof props.onToggle === 'function') {
            props.onToggle(event);
        }
        if (event.expanded) {
            if (typeof props.onExpand === 'function') {
                props.onExpand(event);
            }
        } else {
            if (typeof props.onCollapse === 'function') {
                props.onCollapse(event);
            }
        }
    };

    const expanded = propsExpanded != null ? !!propsExpanded : state.expanded;

    const cssClasses = useStyleSheet(accordionPanelStyleSheet, {
        theme,
        disabled,
        expanded,
        emphasis: context.emphasis,
        size: context.size,
        expandHidden: hideExpandIcon,
    });

    return (
        <div
            data-gs-uitk-component="accordion-panel"
            data-id={state.id}
            {...attributes}
            className={getAccordionPanelClassNames({
                cssClasses,
                overrideClasses,
                className,
            })}
        >
            <div
                data-cy="gs-uitk-accordion-panel-header"
                className={getAccordionPanelHeaderClassNames({
                    cssClasses,
                    overrideClasses,
                })}
            >
                <button
                    {...expandButtonAttrs}
                    {...(state.id && {
                        id: `${state.id}-header`,
                        'aria-controls': `${state.id}-content`,
                    })}
                    type="button"
                    data-cy="gs-uitk-accordion-panel-toggle"
                    onClick={toggle}
                    aria-expanded={expanded}
                    aria-label={ariaLabel}
                    aria-disabled={disabled}
                    disabled={disabled}
                    className={getAccordionPanelToggleClassNames({
                        cssClasses,
                        overrideClasses,
                    })}
                >
                    {header}
                </button>

                {summary && !expanded && (
                    <div
                        data-cy="gs-uitk-accordion-panel-summary"
                        className={getAccordionPanelSummaryClassNames({
                            cssClasses,
                            overrideClasses,
                        })}
                    >
                        {summary}
                    </div>
                )}
            </div>
            <Collapse
                {...(state.id && {
                    id: `${state.id}-content`,
                    'aria-labelledby': `${state.id}-header`,
                })}
                data-cy="gs-uitk-accordion-panel-body"
                expanded={expanded}
                role="presentation"
                className={getAccordionPanelBodyClassNames({
                    cssClasses,
                    overrideClasses,
                })}
            >
                {children}
            </Collapse>
        </div>
    );
};
