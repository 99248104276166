import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './tippy-theme-overrides';

export interface TippyCssClasses {
    root: string;
}

export interface TippyStyleSheetProps {
    theme: Theme;
}

export type TippyStyledClasses = CssClassDefinitionsObject<keyof TippyCssClasses>;

export interface TippyStyleOverridesParams {
    props: DeepReadonly<TippyStyleSheetProps>;
    createDefaultStyledClasses: () => TippyStyledClasses;
}

export const tippyStyleSheet = new StyleSheet('tippy', (props: TippyStyleSheetProps) => {
    return createComponentClassDefinitions<TippyStyleSheetProps, TippyStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.tippy
    );
});

function createDefaultStyledClasses({ theme }: TippyStyleSheetProps): TippyStyledClasses {
    const tippyColor = theme.surface.contrast;
    return {
        root: {
            position: 'relative',
            backgroundColor: tippyColor,
            color: theme.text.reversed,
            borderRadius: '4px',
            fontSize: '14px',
            fontFamily: theme.typography.body01.fontFamily,
            lineHeight: '1.4',
            outline: 0,
            transitionProperty: 'transform, visibility, opacity',

            '&[data-animation="fade"][data-state="hidden"]': {
                opacity: 0,
            },
            '&[data-inertia][data-state="visible"]': {
                transitionTimingFunction: 'cubic-bezier(0.54, 1.5, 0.38, 1.11)',
            },
            '&[data-placement^="top"] > .tippy-arrow': {
                bottom: 0,
                '&:before': {
                    bottom: '-7px',
                    left: '0px',
                    borderWidth: '8px 8px 0px',
                    borderTopColor: 'inherit',
                    transformOrigin: 'center top',
                },
            },
            '&[data-placement^="bottom"] > .tippy-arrow': {
                top: '0px',
                '&:before': {
                    top: '-7px',
                    left: '0px',
                    borderWidth: '0px 8px 8px',
                    borderBottomColor: 'inherit',
                    transformOrigin: 'center bottom',
                },
            },
            '&[data-placement^="left"] > .tippy-arrow': {
                right: '0px',
                '&:before': {
                    right: '-7px',
                    borderWidth: '8px 0px 8px 8px',
                    borderLeftColor: 'inherit',
                    transformOrigin: 'center left',
                },
            },
            '&[data-placement^="right"] > .tippy-arrow': {
                left: '0px',
                '&:before': {
                    left: '-7px',
                    borderWidth: '8px 8px 8px 0px',
                    borderRightColor: 'inherit',
                    transformOrigin: 'center right',
                },
            },
            '.tippy-arrow': {
                width: '16px',
                height: '16px',
                color: tippyColor,
                '&:before': {
                    content: '""',
                    position: 'absolute',
                    borderColor: 'transparent',
                    borderStyle: 'solid',
                    transform: 'scale(0.75)',
                },
            },
            '.tippy-content': {
                position: 'relative',
                zIndex: 1,
            },
        },
    };
}
