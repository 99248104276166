import { PropsWithChildren, FC, useContext, CSSProperties } from 'react';
import { FormContext } from '@gs-ux-uitoolkit-react/form';
import {
    InputGroupContentProps as InputGroupContentCommonProps,
    inputGroupContentStyleSheet,
    globalInputGroupContentClass,
} from '@gs-ux-uitoolkit-common/input';
import { cx, useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';

export interface InputGroupContentProps extends InputGroupContentCommonProps<CSSProperties> {}

/**
 * InputGroupContent allows content such as text or inputs to be integrated
 * cohesively into a containing InputGroup component.
 */
export const InputGroupContent: FC<PropsWithChildren<InputGroupContentProps>> = props => {
    const { className, ...otherProps } = props;

    const formContext = useContext(FormContext);

    const theme = useTheme();
    const cssClasses: any = useStyleSheet(inputGroupContentStyleSheet, {
        theme,
        size: formContext.size || 'md',
    });
    const containerClasses = cx(cssClasses.root, className, globalInputGroupContentClass);

    return (
        <div
            {...otherProps}
            data-gs-uitk-component="input-group-content"
            className={containerClasses}
        >
            {props.children}
        </div>
    );
};
