import { arrayHelper } from '@gs-ux-uitoolkit-common/datacore';
import { Alert } from '@gs-ux-uitoolkit-react/alert';
import { Label } from '@gs-ux-uitoolkit-react/label';
import {
    Select,
    SelectChangeEvent,
    SelectOption,
    SelectProps,
    SelectSize,
} from '@gs-ux-uitoolkit-react/select';
import { Component } from 'react';
import { GridColumn } from '../../grid-wrappers/grid-wrapper';

export interface ColumnSelectorProps {
    columns: GridColumn[];
    onChange?: (columnId: string) => void;
    selectedColumnId: string;
    selectedColumnPivotKeys?: string[];
    hideLabel?: boolean;
    autoSize?: boolean;
    disabled?: boolean;
    labelText?: string;
    showRowGroups?: boolean;
    className?: string;
    clearable?: boolean;
    size?: SelectSize;
}

export const selectColumnHeader = 'Enter Column Name';
export const selectColumnPlaceholder = 'Select Column';

export class ColumnSelector extends Component<ColumnSelectorProps> {
    public render() {
        const columnsToDisplay: SelectOption[] = this.props.columns
            .filter(x => (this.props.showRowGroups && x.isRowGroup) || !x.isRowGroup)
            .map(c => {
                return {
                    label: c.columnLabel,
                    value: c.columnId,
                };
            });

        const selectedColumn = this.props.columns.find(
            x =>
                x.columnId === this.props.selectedColumnId ||
                (x.primaryColumnId === this.props.selectedColumnId &&
                    arrayHelper.areIdentical(
                        x.pivotKeys || [],
                        this.props.selectedColumnPivotKeys || []
                    ))
        );
        const selectConfig: SelectProps = {
            size: this.props.size,
            disabled: this.props.disabled,
            onChange: (selected: SelectChangeEvent) => this.onChangeColumn(selected),
            options: columnsToDisplay,
            placeholder: selectColumnPlaceholder,
            clearable: this.props.clearable,
            selectedValue: selectedColumn ? selectedColumn.columnId : null,
        };

        return (
            <div
                data-cy="gs-uitk-column-selector-container"
                className={`gs-uitk-column-selector-container ${this.props.className || ''}`}
                style={{ width: this.props.autoSize ? undefined : '250px', maxWidth: '100%' }}
            >
                {!this.props.hideLabel && (
                    <Label>{this.props.labelText || selectColumnHeader}</Label>
                )}
                {columnsToDisplay.length > 0 ? (
                    <Select {...selectConfig} data-cy="gs-uitk-datagrid-column-selector" />
                ) : (
                    <Alert status="information">No column available</Alert>
                )}
            </div>
        );
    }

    private onChangeColumn = (selected: SelectChangeEvent) => {
        if (this.props.onChange) {
            this.props.onChange(selected.selectedValue || '');
        }
    };
}
