import actionTypes from './actionTypes';

export const fetchLiterature = id => (
  {
    type: actionTypes.FETCH_LITERATURE,
    id
  }
);

export const fetchFundFactConfigInit = () => (
  {
    type: actionTypes.FETCH_FUND_FACT_CARD_CONFIGURATIONS
  }
);

export const fetchAccountRegistration = id => (
  {
    type: actionTypes.FETCH_ACCOUNT_REGISTRATION,
    id
  }
);

export const fetchSettlementInstruction = () => (
  {
    type: actionTypes.FETCH_SETTLEMENT_INSTRUCTION
  }
);

export const fetchStatement = id => (
  {
    type: actionTypes.FETCH_ACCOUNT_STATEMENT,
    id
  }
);

export const downloadStatementPDF = data => (
  {
    type: actionTypes.DOWNLOAD_STATEMENT_PDF,
    statementId: data.statementId,
    fileName: data.fileName
  }
);

export const fetchDebitCreditInfo = id => (
  {
    type: actionTypes.FETCH_DEBIT_CREDIT_INFO,
    id
  }
);

export const updateDebitCredit = data => (
  {
    type: actionTypes.UPDATE_DEBIT_CREDIT,
    data
  }
);

export const clearDebitCreditEditStatus = () => ({
  type: actionTypes.CLEAR_DEBIT_CREDIT_EDIT_STATUS
});

export const clearAccountDetailData = () => ({
  type: actionTypes.CLEAR_ACCOUNT_DETAIL_DATA
});

export const clearDownloadStatementFailureMessage = () => ({
  type: actionTypes.CLEAR_DOWNLOAD_STATEMENT_FAILURE_MESSAGE
});

export const saveFundRebate = data => (
  {
    type: actionTypes.SAVE_FUND_REBATE,
    data
  }
);
