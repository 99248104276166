import { QuickFilter } from '@gs-ux-uitoolkit-common/datacore';
import { Alert } from '@gs-ux-uitoolkit-react/alert';
import { Input } from '@gs-ux-uitoolkit-react/input';
import { FormGroup, Form } from '@gs-ux-uitoolkit-react/form';
import { Label } from '@gs-ux-uitoolkit-react/label';
import { Component, Fragment, FormEvent } from 'react';

export interface QuickFilterNameProps {
    nameIsNull: string;
    nameIsDuplicate: string;
    expressionNotValid: string;
    configItemList: QuickFilter[];
    data: QuickFilter;
    onNameChange: (newData: Partial<QuickFilter>) => void;
}
export class QuickFilterName extends Component<QuickFilterNameProps> {
    public render() {
        return (
            <Fragment>
                {this.props.nameIsNull && <Alert status="error">{this.props.nameIsNull}</Alert>}
                {this.props.nameIsDuplicate && (
                    <Alert status="error">{this.props.nameIsDuplicate}</Alert>
                )}
                {this.props.expressionNotValid && (
                    <Alert status="error">{this.props.expressionNotValid}</Alert>
                )}
                <Form preventSubmit={true}>
                    <FormGroup className={'gs-uitk-quickfilter-expression-name'}>
                        <Label>Name This Filter</Label>
                        <Input
                            onChange={this.onNameChange}
                            value={this.props.data.name}
                            placeholder="Filter Name"
                            size="sm"
                        />
                    </FormGroup>
                </Form>
            </Fragment>
        );
    }
    private onNameChange = (event: FormEvent<HTMLInputElement>) => {
        this.props.onNameChange({
            name: event.currentTarget.value,
        });
    };
}
