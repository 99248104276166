/**
 * Converts a hex color value to rgb/rgba
 */
export const hexToRgb = (hex: string, alpha?: string) => {
    if (hex.length !== 4 && hex.length !== 7) {
        return '';
    }
    const sectionLength = hex.length === 4 ? 1 : 2;
    let index = 1;

    const rString = hex.slice(index, index + sectionLength);
    const r = parseInt(rString + (sectionLength === 1 ? rString : ''), 16);
    index += sectionLength;

    const gString = hex.slice(index, index + sectionLength);
    const g = parseInt(gString + (sectionLength === 1 ? gString : ''), 16);
    index += sectionLength;

    const bString = hex.slice(index, index + sectionLength);
    const b = parseInt(bString + (sectionLength === 1 ? bString : ''), 16);

    if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
};
