import React, { useState } from 'react';
import { Button } from '@gs-ux-uitoolkit-react/button';
import PropTypes from 'prop-types';
import useUserMaintenance from '../../../context';
import ChangeManagementModal from '../../ChangeManagementDetailsModal/Modal';
import translator from '../../../../../../services/translator';
import { USERS } from '../../../constants';

const { translate: t } = translator;

const LastUpdatedAtRenderer = ({ fieldData }) => {

  const {
    storeValues: { pageKey },
  } = useUserMaintenance();

  const [displayChangeManagementModal, setDisplayChangeManagementModal] = useState(false);

  const openChangeManagementModal = () => {
    setDisplayChangeManagementModal(true);
  };

  const closeChangeManagementModal = () => {
    setDisplayChangeManagementModal(false);
  };

  return (
    <>
      <div className='userdetails__value--container'>
        <div>{fieldData}</div>
        {
          pageKey === USERS && (
            <Button actionType='primary' onClick={openChangeManagementModal} emphasis='minimal' >
              {t("tkView")}
            </Button>
          )
        }
      </div>
      {displayChangeManagementModal && <ChangeManagementModal closeModal={closeChangeManagementModal} />}
    </>
  );
}

LastUpdatedAtRenderer.propTypes = {
  fieldData: PropTypes.string
};

export default LastUpdatedAtRenderer;