import { Properties } from 'csstype';

/**
 * This method provides the styles required to handle overflow for blocks of text. This contains
 * common styles that are shared within the project. Usage of this can be seen in Text, Heading,
 * Popover, Tooltip, etc.
 */
export function getOverflowStyles(overflow: 'none' | 'wrap' | 'truncate'): Properties {
    switch (overflow) {
        case 'wrap':
            return {
                overflowWrap: 'break-word',
                // IE11 compatibility
                wordBreak: 'break-word',
                // Enables the break-word text wrapping within flex (must be 1px for IE11)
                // Ref: https://stackoverflow.com/questions/36150458/flex-item-overflows-container-due-to-long-word-even-after-using-word-wrap/36150638
                minWidth: '1px',
            };
        case 'truncate':
            return {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            };
        default:
            return {};
    }
}
