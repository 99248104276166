import { PluginBase, PluginIcon } from '@gs-ux-uitoolkit-common/datacore';
import { ModuleIdentfier } from '../module-identfier';
import {} from 'gs-uitk-lodash';
import { GridWrapper } from '../../grid-wrappers/grid-wrapper';
import { DataGridState, GridConfigurationState } from '../../redux/datagrid-state';
import { Categories, Plugins } from '../plugin-enum';
import { OpenGridConfiguration } from '../../redux/actions/grid-configuration-action';
import { DataGridToolbarItem } from '../../toolbar-state';

const mainIcon: PluginIcon = {
    name: 'construction',
    type: 'filled',
};

/**
 * The Grid Configuration plugin.
 */
export class GridConfigurationPlugin extends PluginBase<
    GridWrapper,
    DataGridState,
    GridConfigurationState
> {
    protected static requiredModules: ModuleIdentfier[] = [];
    constructor(wrapper: GridWrapper) {
        super(
            Plugins.GridConfigurationPlugin,
            Categories.Data,
            mainIcon,
            wrapper,
            state => state.gridConfiguration
        );
        this.actions = [
            {
                action: OpenGridConfiguration(Plugins.ColumnHintPlugin),
                componentId: 'GridConfigurationShortcut' as DataGridToolbarItem,
                icon: mainIcon,
                label: 'Grid Configuration',
                store: wrapper.getReduxStore(),
                context: wrapper.getReduxStoreContext(),
            },
        ];
    }

    protected stateChangedOrStart(): void {}

    protected internalStop(): void {}
}
