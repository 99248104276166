import { ErrorBoundary } from '@gs-ux-uitoolkit-react/error-boundary';
import {} from 'gs-uitk-lodash';
import { FC, StrictMode } from 'react';

import { DataGridInternal } from './data-grid-internal';
import { DataGridProps } from './props';

/**
 * The React wrapper that houses ag-Grid
 *
 * @externalLinks [
 *   { "label": "View ag-Grid Documentation", "url": "https://www.ag-grid.com/javascript-data-grid/" }
 * ]
 */
export const DataGrid: FC<DataGridProps> = (props: DataGridProps) => (
    <ErrorBoundary>
        <StrictMode>
            <DataGridInternal {...props} />
        </StrictMode>
    </ErrorBoundary>
);
