import { FC, CSSProperties, HTMLAttributes, memo, ReactNode, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    ListGroupProps as ListGroupCommonProps,
    getListGroupClasses,
    listGroupDefaultProps,
    listGroupStyleSheet,
    listGroupSizes,
} from '@gs-ux-uitoolkit-common/list-group';
import { ListGroupContext } from './list-group-context';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { componentAnalytics } from './analytics-tracking';
import { DragAndDropContext, Droppable } from '@gs-ux-uitoolkit-react/drag-and-drop';

export type ListGroupProps = ListGroupCommonProps<CSSProperties> &
    HTMLAttributes<HTMLElement> & {
        /**
         * Content to display inside of the ListGroup. This should be a set of `<ListGroupItem>` tags.
         */
        children?: ReactNode;
    };

/**
 * A List Group presents one or more items that make up a list.
 */
export const ListGroup: FC<ListGroupProps> = memo(props => {
    const {
        className,
        horizontal = listGroupDefaultProps.horizontal,
        size = listGroupDefaultProps.size,
        dragAndDrop = listGroupDefaultProps.dragAndDrop,
        disableInternalDragAndDropContext = listGroupDefaultProps.dragAndDrop,
        onStateUpdate = listGroupDefaultProps.onStateUpdate,
        droppableId,
        children,
        classes: overrideClasses,
        ...attributes
    } = props;

    const theme = useTheme();

    const cssClasses = useStyleSheet(listGroupStyleSheet, { theme, size, horizontal });
    const listGroupClasses = getListGroupClasses({
        cssClasses,
        className,
        overrideClasses,
    });
    const dragDirection: 'vertical' | 'horizontal' = horizontal ? 'horizontal' : 'vertical';
    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'list-group' });
    }, []); // Only run once

    const ConditionalContext = disableInternalDragAndDropContext ? 'div' : DragAndDropContext;

    return (
        <ListGroupContext.Provider
            value={{
                size: size,
                dragAndDrop: dragAndDrop,
            }}
        >
            {dragAndDrop ? (
                <ConditionalContext
                    onStateUpdate={onStateUpdate}
                    spacing={0}
                    direction={dragDirection}
                >
                    <Droppable
                        droppableId={droppableId}
                        elementTypeOverride="ul"
                        data-gs-uitk-component="list-group"
                        data-use-external-context={
                            disableInternalDragAndDropContext ? 'true' : 'false'
                        }
                        data-size={size}
                        data-horizontal={horizontal}
                        {...attributes}
                        classes={{ list: listGroupClasses }}
                    >
                        {children}
                    </Droppable>
                </ConditionalContext>
            ) : (
                <ul
                    data-gs-uitk-component="list-group"
                    data-size={size}
                    data-horizontal={horizontal}
                    {...attributes}
                    className={listGroupClasses}
                >
                    {children}
                </ul>
            )}
        </ListGroupContext.Provider>
    );
});
ListGroup.displayName = 'ListGroup';

ListGroup.propTypes = {
    className: PropTypes.string,
    horizontal: PropTypes.bool,
    size: PropTypes.oneOf(listGroupSizes),
};
