export default {
  FETCH_TRADE_BLOTTER_ACCOUNT_SEARCH: {
    'path': '/api/v1/tradeblotter/account/search',
    'method': 'POST',
    'parameters': {
      'appContext.activeTrade.firm.firmId': 'firmId',
      'appContext.activeTrade.firm.branchId': 'branchId',
      'appContext.activeTrade.shareClass.id': 'shareClassId',
      'appContext.activeTrade.hasOpenAccounts': 'hasOpenAccounts',
      'appContext.activeTrade.account': {
        'type': 'object',
        'paramKey': 'account',
        'mapper': {
          'taAccount': 'taAccount',
          'name': 'name',
          'bin': 'bin'
        }
      }
    }
  },
  FETCH_TRADE_BLOTTER_ACCOUNT_INPUTS: {
    'path': '/api/v1/tradeblotter/account/inputs',
    'method': 'POST',
    'parameters': {
      'appContext.activeTrade.firm.firmId': 'firmId',
      'appContext.activeTrade.firm.branchId': 'branchId',
      'appContext.activeTrade.shareClass.id': 'shareClassId',
      'appContext.activeTrade.account': {
        'type': 'object',
        'paramKey': 'account',
        'mapper': {
          'taAccount': 'taAccount',
          'name': 'name',
          'bin': 'bin'
        }
      },
      'appContext.activeTrade.isNewAccount': 'isNewAccount'
    }
  },
  FETCH_TRADE_BLOTTER_CART_SAVE: {
    'path': '/api/v1/tradeblotter/cart/save',
    'method': 'POST',
    'parameters': {
      'appData.tradeBlotterVersion': 'tradeBlotterVersion',
      'appContext.draftTrades': {
        'type': 'array',
        'paramKey': 'draftTrades',
        'mapper': {
          'firm.firmId': 'firmId',
          'firm.branchId': 'branchId',
          'shareClass.id': 'shareClassId',
          'account': {
            'type': 'object',
            'paramKey': 'account',
            'mapper': {
              'taAccount': 'taAccount',
              'name': 'name',
              'bin': 'bin',
              'id': 'id',
              'accountHash': 'accountHash'
            }
          },
          'isNewAccount': 'isNewAccount',
          'tradeDate': 'tradeDate',
          'tradeType': 'tradeType',
          'intendedSourceSystem' : 'intendedSourceSystem',
          'amountType': 'amountType',
          'tradeAmount': 'tradeAmount',
          'paymentInstruction': {
            'type': 'object',
            'paramKey': 'paymentInstruction',
            'mapper': {
              'paymentInstructionId': 'paymentInstructionId',
              'isStdInstruction': 'isStdInstruction'
            }
          },
          'homeAwayType': 'homeAwayType',
          'settlementCycle': 'settlementCycle',
          'displayPosition': 'displayPosition',
          'hasOpenAccounts': 'hasOpenAccounts',
          'options.shareClassCurrency': 'shareClassCurrency',
          'isNewBin': 'isNewBin'
        },
        'condition': ({firmId = undefined, branchId = undefined, shareClassId = undefined, account = {}}) => {
          const {taAccount = undefined, bin = undefined} = account;
          return ((firmId && branchId) || shareClassId || (taAccount || bin));
        }
      }
    }
  },
  TRADES_IMPORT_SAVE_BULK: {
    'path': '/api/v1/trades',
    'method': 'POST',
  },

  FETCH_TRADE_BLOTTER_CART_VERIFY: {
    'path': '/api/v1/tradeblotter/cart/verify',
    'method': 'POST',
    'parameters': {
      'appData.tradeBlotterVersion': 'tradeBlotterVersion',
      'appContext.draftTrades': {
        'type': 'array',
        'paramKey': 'draftTrades',
        'mapper': {
          'firm.firmId': 'firmId',
          'firm.branchId': 'branchId',
          'shareClass.id': 'shareClassId',
          'account': {
            'type': 'object',
            'paramKey': 'account',
            'mapper': {
              'taAccount': 'taAccount',
              'name': 'name',
              'bin': 'bin',
              'id': 'id'
              }
            },
          'isNewAccount': 'isNewAccount',
          'isNewBin': 'isNewBin',
          'tradeDate': 'tradeDate',
          'tradeType': 'tradeType',
          'intendedSourceSystem' : 'intendedSourceSystem',
          'amountType': 'amountType',
          'tradeAmount': 'tradeAmount',
          'paymentInstruction': {
            'type': 'object',
            'paramKey': 'paymentInstruction',
            'mapper': {
              'paymentInstructionId': 'paymentInstructionId',
              'isStdInstruction': 'isStdInstruction'
              }
            },
          'homeAwayType': 'homeAwayType',
          'settlementCycle': 'settlementCycle',
          'displayPosition': 'displayPosition',
          'options.shareClassCurrency': 'shareClassCurrency',
          'tradeCutoff': 'tradeCutoff',
          'warnings': {
            'type': 'array',
            'paramKey': 'acceptedWarnings',
            'mapper': {
              'message': 'message',
              'code': 'code'
            }
          }
        }
      }
    }
  },
  FETCH_TRADE_BLOTTER_CART_PLACE: {
    'path': '/api/v1/tradeblotter/cart/place',
    'method': 'POST',
    'parameters': {
      'appData.tradeBlotterVersion': 'tradeBlotterVersion',
      'appContext.draftTrades': {
        'type': 'array',
        'paramKey': 'draftTrades',
        'mapper': {
          'firm.firmId': 'firmId',
          'firm.branchId': 'branchId',
          'shareClass.id': 'shareClassId',
          'account': {
            'type': 'object',
            'paramKey': 'account',
            'mapper': {
              'taAccount': 'taAccount',
              'name': 'name',
              'bin': 'bin',
              'id': 'id'
            }
          },
          'isNewAccount': 'isNewAccount',
          'tradeDate': 'tradeDate',
          'tradeType': 'tradeType',
          'intendedSourceSystem' : 'intendedSourceSystem',
          'amountType': 'amountType',
          'tradeAmount': 'tradeAmount',
          'paymentInstruction': {
            'type': 'object',
            'paramKey': 'paymentInstruction',
            'mapper': {
              'paymentInstructionId': 'paymentInstructionId',
              'isStdInstruction': 'isStdInstruction'
            }
          },
          'homeAwayType': 'homeAwayType',
          'settlementCycle': 'settlementCycle',
          'displayPosition': 'displayPosition',
          'options.shareClassCurrency': 'shareClassCurrency',
          'isNewBin': 'isNewBin',
          'tradeCutoff': 'tradeCutoff'
        }
      }
    }
  },
  FETCH_TRADE_BLOTTER_CART_RETRIEVE: {
    'path': '/api/v1/tradeblotter/cart/retrieve',
    'method': 'POST'
  },
  FETCH_TRADE_BLOTTER_CART_VERSION: {
    'path': '/api/v1/tradeblotter/cart/retrieveTradeBlotterVersion',
    'method': 'GET'
  },
  FETCH_TRADE_BLOTTER_CART_RETRIEVE_PLAIN: {
    'path': '/api/v1/tradeblotter/cart/retrieve/plain',
    'method': 'POST'
  },
  FETCH_TRADE_BLOTTER_SHELLACCOUNT_SEARCH: {
    'path': '/api/v1/tradeblotter/shellaccount/search',
    'method': 'POST',
    'parameters': {
      'appContext.shellAccountState.firm.firmId': 'firmId',
      'appContext.shellAccountState.firm.branchId': 'branchId',
      'appContext.shellAccountState.account': {
        'type': 'object',
        'paramKey': 'account',
        'mapper': {
          'accountHash': 'accountHash',
          'bin': 'bin',
          'name': 'name'
        }
      },
      'appContext.shellAccountState.shareClass.id': 'shareClassId',
      'appContext.shellAccountState.isNewBin': 'isNewBin'
    }
  }
};

