import { FunctionComponent, useContext, CSSProperties, HTMLAttributes, ReactNode } from 'react';
import PropTypes from 'prop-types';
import {
    getHeaderClass,
    MenuHeaderProps as MenuHeaderCommonProps,
    menuHeaderStyleSheet,
} from '@gs-ux-uitoolkit-common/menu';
import { MenuContext } from './menu-context';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';

export interface MenuHeaderProps
    extends MenuHeaderCommonProps<CSSProperties>,
        HTMLAttributes<HTMLElement> {
    children?: ReactNode;
}

export const MenuHeader: FunctionComponent<MenuHeaderProps> = (props: MenuHeaderProps) => {
    const { size, className, classes: overrideClasses, children, ...otherProps } = props;
    const theme = useTheme();

    // Get the size from the context. This will be used if the prop was not passed.
    // The useContext hook will have to be called at the root of the function to prevent hooks
    // from being called conditionally within the useStyleSheet hook
    const context = useContext(MenuContext);

    const cssClasses = useStyleSheet(menuHeaderStyleSheet, {
        theme,
        size: size || context.size,
    });
    return (
        <p
            data-gs-uitk-component="menu-header"
            tabIndex={-1}
            className={getHeaderClass({
                cssClasses,
                className,
                overrideClasses,
            })}
            {...otherProps}
        >
            {children}
        </p>
    );
};

MenuHeader.propTypes = {
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
};
