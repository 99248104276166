import actionTypes from './actionTypes';

export const back = () => (
  {
    type: actionTypes.BACK
  }
);

export const logout = () => (
  {
    type: actionTypes.LOGOUT
  }
);

export const eventAnalytics = data => (
  {
    type: actionTypes.EVENT_ANALYTICS,
    data
  }
);

export const fetchTradeOptions = () => (
  {
    type: actionTypes.FETCH_TRADE_OPTIONS
  }
);

export const fetchDraftTrades = (data) => (
  {
    type: actionTypes.FETCH_DRAFT_TRADES,
    data
  }
);

export const saveDraftTrades = (data) => (
  {
    type: actionTypes.SAVE_DRAFT_TRADES,
    data
  }
);

export const verifyTrades = () => (
  {
    type: actionTypes.VERIFY_TRADES
  }
);

export const placeTrades = () => (
  {
    type: actionTypes.PLACE_TRADES
  }
);

export const addToTradeBlotter = data => (
  {
    type: actionTypes.ADD_TO_TRADE_BLOTTER,
    data
  }
);

export const removeFromTradeBlotter = data => (
  {
    type: actionTypes.REMOVE_FROM_TRADE_BLOTTER,
    data
  }
);

export const fetchAccountInputs = () => (
  {
    type: actionTypes.FETCH_ACCOUNT_INPUTS
  }
);

export const tradeEventAnalytics = (data) => (
  {
    type: actionTypes.TRADE_EVENT_ANALYTICS,
    data
  }
);

export const fetchCachedTradeOptions = () => (
  {
    type: actionTypes.FETCH_CACHED_TRADE_OPTIONS,
  }
);

export const messageCenterEventAnalytics = data => (
  {
    type: actionTypes.MESSAGE_CENTER_EVENT_ANALYTICS,
    data
  }
);

export const fetchShellAccountOptions = () => (
  {
    type: actionTypes.FETCH_SHELL_ACCOUNT_OPTIONS
  }
);

export const fetchUpdatedTradeBlotterVersion = () => (
  {
    type: actionTypes.FETCH_TRADE_BLOTTER_CART_VERSION
  }
);
