import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { getInputStyleSheet, InputThemeStyleSheetProps } from './input-style-sheet';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './textarea-theme-overrides';

export interface TextareaThemeStyleSheetProps extends InputThemeStyleSheetProps {
    theme: Theme;
    cols?: boolean;
}

export interface TextAreaCssClasses {
    root: string;
}

export type TextareaStyledClasses = CssClassDefinitionsObject<keyof TextAreaCssClasses>;

export interface TextareaStyleOverridesParams {
    props: DeepReadonly<TextareaThemeStyleSheetProps>;
    createDefaultStyledClasses: () => TextareaStyledClasses;
}

export const textareaStyleSheet = new StyleSheet(
    'textarea',
    (props: TextareaThemeStyleSheetProps) => {
        return createComponentClassDefinitions<TextareaThemeStyleSheetProps, TextareaStyledClasses>(
            props,
            createDefaultStyledClasses,
            props.theme.styleOverrides?.textarea
        );
    }
);

export const globalTextareaClass = 'gs-textarea';

function createDefaultStyledClasses(props: TextareaThemeStyleSheetProps): TextareaStyledClasses {
    return {
        root: {
            ...getInputStyleSheet(props),
            height: 'auto',
            width: props.cols ? 'fit-content' : undefined,
        },
    };
}
