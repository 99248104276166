import { Component, Fragment, ChangeEvent } from 'react';
import { Button } from '@gs-ux-uitoolkit-react/button';
import { FormGroup } from '@gs-ux-uitoolkit-react/form';
import { Input } from '@gs-ux-uitoolkit-react/input';
import { Label } from '@gs-ux-uitoolkit-react/label';
import { Checkbox, CheckboxChangeEvent } from '@gs-ux-uitoolkit-react/checkbox';
import { columnFormattingPluginStylePrefix } from '../../../../../style/plugins/column-hint-plugin-stylesheet';
import { HintList } from '@gs-ux-uitoolkit-common/datacore';
import { SelectOption, Select, SelectChangeEvent } from '@gs-ux-uitoolkit-react/select';
import { FormatControlProps } from '../column-hint-format-manager';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';

export class ColumnHintControlHyperlink extends Component<FormatControlProps> {
    public render() {
        const targetOptions: SelectOption[] = [
            { label: 'New Window', value: '_blank' },
            { label: 'Current Window', value: '_self' },
        ];

        const radioWindowChecked =
            this.props.columnHint.format === undefined ||
            (this.props.columnHint.format &&
                this.props.columnHint.format.hyperlinkFormat === undefined) ||
            (this.props.columnHint.format &&
                this.props.columnHint.format.hyperlinkFormat &&
                (this.props.columnHint.format.hyperlinkFormat.target === '_blank' ||
                    this.props.columnHint.format.hyperlinkFormat.target === '_self' ||
                    this.props.columnHint.format.hyperlinkFormat.target === undefined));

        return (
            <Fragment>
                <div className={`${columnFormattingPluginStylePrefix}_hyperlink-container`}>
                    <FormGroup>
                        <Label>Target</Label>
                        <div className={`${columnFormattingPluginStylePrefix}_flex-column`}>
                            <div
                                className={`${columnFormattingPluginStylePrefix}_flex ${columnFormattingPluginStylePrefix}_target-container`}
                            >
                                <Select
                                    options={targetOptions}
                                    onChange={this.onChangeTargetWindow}
                                    clearable={false}
                                    disabled={!radioWindowChecked}
                                    selectedValue={
                                        (radioWindowChecked &&
                                            this.props.columnHint.format?.hyperlinkFormat
                                                ?.target) ||
                                        ''
                                    }
                                />
                                <div
                                    className={`${columnFormattingPluginStylePrefix}_flex ${columnFormattingPluginStylePrefix}_iframe-container`}
                                >
                                    <Checkbox
                                        name={'iframe'}
                                        value={'iframe'}
                                        onChange={this.onChangeTargetCheckbox}
                                        checked={!radioWindowChecked}
                                    >
                                        iframe ID
                                    </Checkbox>

                                    <Input
                                        data-cy="gs-uitk-datagrid-column-iframe-input"
                                        className={'column-hint-control-column-width-pixel-input'}
                                        value={
                                            (!radioWindowChecked &&
                                                this.props.columnHint.format?.hyperlinkFormat
                                                    ?.target) ||
                                            ''
                                        }
                                        onChange={this.onChangeFrameId}
                                        disabled={radioWindowChecked}
                                    />
                                </div>
                            </div>
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Label>Url</Label>
                        <Input
                            data-cy="gs-uitk-datagrid-column-hint-url"
                            value={this.props.columnHint.format?.hyperlinkFormat?.url || ''}
                            placeholder="e.g. http://www.gs.com/search$value"
                            onChange={this.onChangeUrl}
                        />
                    </FormGroup>
                    <div
                        className={`${columnFormattingPluginStylePrefix}_flex ${columnFormattingPluginStylePrefix}_pivot-container`}
                    >
                        <FormGroup>
                            <Label>Use in Pivot</Label>

                            <Checkbox
                                name="pivotCheckbox"
                                data-cy="gs-uitk-datagrid-column-hint-pivot-checkbox"
                                checked={
                                    this.props.columnHint.format?.hyperlinkFormat?.rowGroup || false
                                }
                                onChange={this.onChangeUseInPivot}
                            >
                                Apply hyperlink to pivot column
                            </Checkbox>
                        </FormGroup>

                        <FormGroup>
                            <Label>Pass value to url</Label>
                            <Input
                                data-cy="gs-uitk-datagrid-column-hint-url-replace"
                                value={this.props.columnHint.format?.hyperlinkFormat?.replace || ''}
                                placeholder="e.g. value"
                                onChange={this.onChangeReplace}
                            />
                            <Icon type="filled" name="info" />
                            <span>
                                Here you can type a value that will get replaced with the contents
                                of the cell in the hyperlink
                            </span>
                        </FormGroup>
                    </div>

                    <Button
                        style={{ marginBottom: '12px' }}
                        actionType="secondary"
                        disabled={!this.props.columnHint.format?.hyperlinkFormat}
                        onClick={this.removeHyperlinkFormat}
                    >
                        Remove hyperlink format
                    </Button>
                </div>
            </Fragment>
        );
    }

    private onChangeTargetCheckbox = (e: CheckboxChangeEvent) => {
        const checked = e.checkedState === true;
        const clonedHint: HintList = {
            ...this.props.columnHint,
            format: {
                ...this.props.columnHint.format,
                hyperlinkFormat: {
                    ...this.props.columnHint.format?.hyperlinkFormat,
                    target: checked ? '' : '_blank',
                    url: this.props.columnHint.format?.hyperlinkFormat?.url || '',
                },
            },
        };
        this.props.onChangeHint(clonedHint);
    };

    private onChangeTargetWindow = (e: SelectChangeEvent) => {
        const value = e.selectedValue;

        const clonedHint: HintList = {
            ...this.props.columnHint,
            format: {
                ...this.props.columnHint.format,
                hyperlinkFormat: {
                    ...this.props.columnHint.format?.hyperlinkFormat,
                    target: value || '_blank',
                    url: this.props.columnHint.format?.hyperlinkFormat?.url || '',
                },
            },
        };
        this.props.onChangeHint(clonedHint);
    };

    private onChangeFrameId = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        const clonedHint: HintList = {
            ...this.props.columnHint,
            format: {
                ...this.props.columnHint.format,
                hyperlinkFormat: {
                    ...this.props.columnHint.format?.hyperlinkFormat,
                    target: value,
                    url: this.props.columnHint.format?.hyperlinkFormat?.url || '',
                },
            },
        };
        this.props.onChangeHint(clonedHint);
    };

    private onChangeUseInPivot = (e: CheckboxChangeEvent) => {
        const value = e.checkedState === true;

        const clonedHint: HintList = {
            ...this.props.columnHint,
            format: {
                ...this.props.columnHint.format,
                hyperlinkFormat: {
                    ...this.props.columnHint.format?.hyperlinkFormat,
                    rowGroup: value,
                    url: this.props.columnHint.format?.hyperlinkFormat?.url || '',
                },
            },
        };
        this.props.onChangeHint(clonedHint);
    };

    private onChangeUrl = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        const clonedHint: HintList = {
            ...this.props.columnHint,
            format: {
                ...this.props.columnHint.format,
                hyperlinkFormat: {
                    ...this.props.columnHint.format?.hyperlinkFormat,
                    url: value,
                },
            },
        };
        this.props.onChangeHint(clonedHint);
    };

    private onChangeReplace = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        const clonedHint: HintList = {
            ...this.props.columnHint,
            format: {
                ...this.props.columnHint.format,
                hyperlinkFormat: {
                    ...this.props.columnHint.format?.hyperlinkFormat,
                    replace: value,
                    url: this.props.columnHint.format?.hyperlinkFormat?.url || '',
                },
            },
        };
        this.props.onChangeHint(clonedHint);
    };

    private removeHyperlinkFormat = () => {
        const clonedHint: HintList = {
            ...this.props.columnHint,
            format: {
                ...this.props.columnHint.format,
            },
        };
        if (clonedHint.format) {
            delete clonedHint.format.hyperlinkFormat;
        }
        this.props.onChangeHint(clonedHint);
    };
}
