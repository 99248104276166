import omit from 'lodash/omit';
import moment from 'moment';
import {createSelector} from 'reselect';
import {labelSelector} from '../../app';
import Constants, {TRADEBLOTTER_FUND_FEES} from '../../../constants/appConstants';
import {injectKeys} from '../../../utils/dynamici18nInjection';
import {amountFormatter, currencyFormatter} from '../../../utils/amountFormatter';
import {isRowComplete, getTMinusNDate, getTPlusNDate} from '../../../utils/tradeBlotter';
import {allowFeaturesSelector, isInternalUserSelector, isTradingAllowedSelector} from '../../user';
import {config, tradeRowValidationRules, configKeys} from './config';
import translator from '../../../services/translator';
import {getAppRoute} from '../../../utils/commonUtils';

const defaultShellAccountState = {account: {}, shareClass: {}, isNewBin: false};
export const shellAccountConfigsSelector = state => state.appData.shellAccountConfigs || {};
export const shellAccountOptionsSelector = state => state.appData.shellAccountOptions || {};
export const isHomeAwayApplicableSelector = state => state.user.permissions && state.user.permissions.homeAway ? state.user.permissions.homeAway : false;
export const currencySymbolSelector = state => state.appData.currencySymbol || {};
export const activeTradeSelectorBase = state => state.appContext.activeTrade || {};
export const draftTradesSelectorBase = state => state.appContext.draftTrades || [];
export const isRetrieveQueuedSelector = state => state.appContext.isRetrieveQueued || false;
export const isDraftTradesLoadingSelector = state => state.appContext.isDraftTradesLoading || state.appContext.isRetrieveQueued || false;
export const tradeOptionsSelector = state => state.appData.tradeOptions || {};
export const isTradeOptionsLoadingSelector = state => state.appContext.isTradeOptionsLoading || false;
export const isBlotterDirtySelector = state => state.appContext.isBlotterDirty || false;
export const isSaveQueuedSelector = state => state.appContext.isSaveQueued || false;
export const isSavingTradesSelector = state => state.appContext.isSavingTrades || false;
export const isVerifyingTradesSelector = state => state.appContext.isVerifyingTrades || false;
export const validTradesSelector = state => state.appContext.validTrades || {};
export const tradeBlotterSessionIdSelector = state => state.appData.tradeBlotterSessionId || '';
export const completeTradesSelector = state => state.appContext.completeTrades || {};
export const errorTradesCountSelector = state => state.appData.errorTradesCount || 0;
export const warningTradesCountSelector = state => state.appData.warningTradesCount || 0;
export const currentTradeStepSelector = state => state.appData.currentTradeStep || 0;
export const blotterAggregatesSelector = state => state.appData.blotterAggregates || [];
export const showNewAccountModalSelector = state => state.appContext.showNewAccountModal || false;
export const showRedemptionFeeModalSelector = state => state.appContext.showRedemptionFeeModal || false;
export const isShellAccountOptionsLoadingSelector = state => state.appContext.isShellAccountOptionsLoading || false;
export const cachedTradeOptionsSelector = state => state.appData.cachedTradeOptions || {};
export const isCachedTradeOptionsLoadingSelector = state => state.appContext.isCachedTradeOptionsLoading || false;
export const isTradeInputsLoadingSelector = state => state.appContext.isTradeInputsLoading || false;
export const isPlacingTradesSelector = state => state.appContext.isPlacingTrades || false;
export const tradeBlotterSnackbarSelector = state => state.appContext.tradeBlotterSnackbarMessage || {};
export const tradeRequireApprovalsSelector = state => state.appContext.tradeRequireApprovals;
export const tradeRequireNoApprovalsSelector = state => state.appContext.tradeRequireNoApprovals;
export const newAccountModalSnackbarSelector = state => state.appContext.newAccountModalSnackbarMessage || {};
export const isTradingDisabledSelector = state => state.appData.isTradingDisabled || false;
export const shellAccountStateSelector = state => state.appContext.shellAccountState || defaultShellAccountState;
const {translate: t} = translator;

export const tradeRowValidationRulesSelector = createSelector(isInternalUserSelector, (isInternalUser) => isInternalUser ? [tradeRowValidationRules[0]] : tradeRowValidationRules);

const getTradeDates = (trade, options) => {
  const {
    redemptionNoticePeriod, purchaseNoticePeriod, purchaseTradeCutoff,
    redemptionTradeCutoff, tTradeDate, productHolidays, userTimeZone, fundTimeZone
  } = options;
  const {tradeType, tradeDate} = trade;

  let time;
  let noticePeriod;
  if(tradeType) {
    time = tradeType === Constants.TRADE_TYPE_PURCHASE_DATA_KEY ? purchaseTradeCutoff : redemptionTradeCutoff;
    noticePeriod = tradeType === Constants.TRADE_TYPE_PURCHASE_DATA_KEY ? purchaseNoticePeriod : redemptionNoticePeriod;
  } else {
    const purchaseTMinusN = purchaseNoticePeriod && purchaseNoticePeriod.split('-')[1] || 0;
    const redemptionTMinusN = redemptionNoticePeriod && redemptionNoticePeriod.split('-')[1] || 0;
    time = purchaseTMinusN <= redemptionTMinusN ? purchaseTradeCutoff : redemptionTradeCutoff;
    noticePeriod = purchaseTMinusN <= redemptionTMinusN ? purchaseNoticePeriod : redemptionNoticePeriod;
  }

  const tMinusN = noticePeriod && noticePeriod.split('-')[1] || 0;
  const startDate = getTPlusNDate(tTradeDate, tMinusN, productHolidays);
  const endDate = getTPlusNDate(startDate, Constants.TRADE_CALENDER_ENABLED_FOR_NUMBER_OF_DAYS_IN_FUTURE, productHolidays);
  const isAfter = moment(startDate).isSameOrAfter(tradeDate, 'day');
  const isAfterEndDate = moment(tradeDate).isAfter(endDate, 'day');
  const newTradeDate = isAfterEndDate ? startDate : tradeDate;
  const cutoff = getTMinusNDate(newTradeDate, tMinusN, productHolidays);
  const cutoffDate = moment(cutoff).format('DD.MMM.YYYY');
  const cutoffTime = moment(time, ['HH.mm']).format('hh:mmA');
  const tradeCutOffAsPerFundTimeZone = moment.tz(`${cutoffDate} ${cutoffTime}`, 'DD.MMM.YYYY hh:mm:A', fundTimeZone);
  const tradeCutOffDateTimeAsPerUserTimeZone = userTimeZone !== fundTimeZone ? tradeCutOffAsPerFundTimeZone.clone().tz(userTimeZone) : tradeCutOffAsPerFundTimeZone;
  const tradeCutoffDate = tradeCutOffDateTimeAsPerUserTimeZone.format('DD.MMM.YYYY');
  const tradeCutoffTime = tradeCutOffDateTimeAsPerUserTimeZone.format('hh:mm A');
  const timeZone = moment().tz(userTimeZone);
  const userTimeZoneInZFormat =  timeZone && timeZone.format('z');
  const tradeCutoff = `${tradeCutoffTime || ''} ${userTimeZoneInZFormat} ${tradeCutoffDate || ''}`;
  return {startDate, endDate, cutoffDate, cutoffTime, tradeDate: isAfter ? startDate : newTradeDate, tradeCutoff };
};


export const activeTradeSelector = createSelector(activeTradeSelectorBase, (activeTrade) => {
  const {tradeAmount, tradeType, amountType, options, tradeDate: selectedTradeDate} = activeTrade;
  if(!options) return activeTrade;
  // Handle the condition when Trade Type 'redeem' and Amount Type  'all'  show the Available Balance as a Trade Amount.
  const {startDate, tradeCutoff, tradeDate} = getTradeDates(activeTrade, options);
  const isSameOrAfter = moment(startDate).isSameOrAfter(tradeDate, 'day');
  const isSame = moment(selectedTradeDate).isSame(tradeDate, 'day');
  const date = isSameOrAfter ? startDate : tradeDate;
  const amount = !tradeAmount && tradeType === Constants.TRADE_TYPE_REDEEM_DATA_KEY &&  amountType === Constants.AMOUNT_TYPE_ALL_DATA_KEY && options.availableBalance || tradeAmount;


  return {...activeTrade, tradeAmount: amount, tradeDate: date, tradeType, isAfter : !isSame, tradeCutoff};
});

export const draftTradesSelector = createSelector(draftTradesSelectorBase, (draftTrades) => {
  return draftTrades.map((trade) => {
    const {tradeAmount, tradeType, amountType, options, tradeDate} = trade;
    if(!options) return trade;

    // Handle the condition when Trade Type 'redeem' and Amount Type  'all'  show the Available Balance as a Trade Amount.

    const {startDate, tradeCutoff} = getTradeDates(trade, options);
    const isSameOrAfter = moment(startDate).isSameOrAfter(tradeDate, 'day');
    // const isAfter = moment(startDate).isAfter(tradeDate, 'day');
    const date = isSameOrAfter ? startDate : tradeDate;
    const amount = !tradeAmount && tradeType === Constants.TRADE_TYPE_REDEEM_DATA_KEY &&  amountType === Constants.AMOUNT_TYPE_ALL_DATA_KEY && options.availableBalance || tradeAmount;
    return {...trade, tradeAmount: amount, tradeDate: date, tradeType, tradeCutoff};
  });
});

export const activeTradeIndexSelector = createSelector(
  activeTradeSelector,
  draftTradesSelector,
  (activeTrade = {}, draftTrade = []) => {
    if(!draftTrade.length) return -1;
    return draftTrade.findIndex(data => data.displayPosition === activeTrade.displayPosition);
  }
);

export const snackBarMsgAndCTALabelsSelector = createSelector(
  draftTradesSelector, labelSelector, tradeRequireApprovalsSelector, tradeRequireNoApprovalsSelector,
  (draftTrades, labels, approvalRequiredCount, noApprovalRequiredCount) => {
    const tradeCopy = {
      reviewedMsg: '',
      placedMsg: '',
      placedCTA: ''
    };

    const draftTradesCount = draftTrades.length;
    if (!(draftTradesCount && (approvalRequiredCount || noApprovalRequiredCount))) {
      return tradeCopy;
    }

    const isNewAccountTradePresent = draftTrades.some(trade => trade.isNewAccount);
    const approvalRequiredSuffix = (approvalRequiredCount > 1) ? labels.tkTradesHave : labels.tkTradeHas;
    const noApprovalRequiredSuffix = (noApprovalRequiredCount > 1) ? labels.tkTradesHave : labels.tkTradeHas;
    const isSingleTrade = (draftTradesCount === 1);

    if (draftTradesCount === approvalRequiredCount) {
      tradeCopy.reviewedMsg = injectKeys(isSingleTrade ? labels.tkReviewTradesAlertAll : labels.tkReviewTradesAlertsAll, `${draftTradesCount}`);
      tradeCopy.placedMsg = injectKeys(labels[isNewAccountTradePresent ? 'tkNewAccountPlacedAll' : 'tkTradesPlacedAll'], `${approvalRequiredCount} ${approvalRequiredSuffix}`);
      tradeCopy.placedCTA = labels.tkSubmitForApproval;
    } else if (draftTradesCount === noApprovalRequiredCount) {
      tradeCopy.reviewedMsg = injectKeys(isSingleTrade ? labels.tkReviewTradesAlertNone : labels.tkReviewTradesAlertsNone, `${draftTradesCount}`);
      tradeCopy.placedMsg = injectKeys(labels[isNewAccountTradePresent ? 'tkNewAccountPlacedNone' : 'tkTradesPlacedNone'], `${noApprovalRequiredCount} ${noApprovalRequiredSuffix}`);
      tradeCopy.placedCTA = isSingleTrade ? labels.tkPlaceTrade : labels.tkPlaceTrades;
    } else {
      tradeCopy.reviewedMsg = injectKeys(isSingleTrade ? labels.tkReviewTradesAlertSome : labels.tkReviewTradesAlertsSome, `${draftTradesCount}`);
      tradeCopy.placedMsg = injectKeys(labels[isNewAccountTradePresent ? 'tkNewAccountPlacedSome' : 'tkTradesPlacedSome'],
        `${noApprovalRequiredCount} ${noApprovalRequiredSuffix}`,
        `${approvalRequiredCount} ${approvalRequiredSuffix}`);
      tradeCopy.placedCTA = labels.tkSubmitTrades;
    }

    Object.keys(tradeCopy).forEach(key => {
      tradeCopy[key] = tradeCopy[key].replace(/ +/g, ' '); // Remove extra whitespace
    });

    return tradeCopy;
  }
);

export const getConfigKeys = createSelector(isHomeAwayApplicableSelector, isInternalUserSelector, (displayHomeAway, isInternalUser) => {
  const keys = [...configKeys];
   if(isInternalUser) {
     keys[0] = Constants.ACCOUNT;
     keys[1] = Constants.FIRM;
    }

  if(displayHomeAway === false) {
    const index = keys.indexOf(Constants.HOME_AWAY_TYPE);
    keys.splice(index, 1);
    return keys;
  }
   return keys;
});

export const enterTradeConfigSelector = createSelector(
  isHomeAwayApplicableSelector,
  isInternalUserSelector,
  (displayHomeAway, isInternalUser) => {
    let tradeConfig = {...config};
    if(isInternalUser) {
      tradeConfig =  omit(config, [Constants.SHARE_CLASS]);
      const shareClassConfig =  config[Constants.SHARE_CLASS];
        delete shareClassConfig.validationRules.EU_REDEMPTION_GATE_WARNING;
        tradeConfig = {shareClass: shareClassConfig, ...tradeConfig};
    }
    if (displayHomeAway === false) {
      tradeConfig[Constants.ACCOUNT].fieldWidth = '11.07%';
      tradeConfig[Constants.SHARE_CLASS].fieldWidth = '15.2%';
      return omit(tradeConfig, Constants.HOME_AWAY_TYPE);
    }
    return config;
  }
);

export const reviewTradesConfigSelector = createSelector(enterTradeConfigSelector, (config) => config);

export const getTradeOptions = createSelector(tradeOptionsSelector, (tradeOptions) => tradeOptions);

const getAmountType = (amountType, shareClassCurrency) => {
  let label;

  if(amountType === Constants.CURRENCY_DATA_KEY){
    label = shareClassCurrency || t('tkCurrency');
  } else if( amountType ===  Constants.SHARES_DATA_KEY) {
    label = t('tkShares');
  } else if(amountType === Constants.AMOUNT_TYPE_ALL_DATA_KEY){
    label = t('tkAll');
  }
  return amountType ? {label, value: amountType} : {};
};

const getAmountTypes = (amountType, shareClassCurrency, tradeType) => {

  const amountTypes = [];
  const arrAmountType = amountType && tradeType && amountType[tradeType] || [];
  arrAmountType.forEach(val => {
    if (tradeType === Constants.TRADE_TYPE_PURCHASE_DATA_KEY) {
      if (val !== Constants.AMOUNT_TYPE_ALL_DATA_KEY) {
        amountTypes.push( getAmountType(val, shareClassCurrency));
      }
    } else {
      amountTypes.push(getAmountType(val, shareClassCurrency));
    }
  });
  return amountTypes;
};

const getTradeTypes = (tradeType) => {
  return tradeType.map(val => ({
    label:  val === Constants.TRADE_TYPE_PURCHASE_DATA_KEY ? t('tkPurchase') : t('tkRedeem'), // labels[tradeTypeConfig[0][val]],
    value: val
  }));
};


const getHomeAway = (homeAway) => {
  return homeAway.map(val => ({
    label: val === Constants.HOME ? t('tkHome') : t('tkAway'),
    value: val
  }));
};


const getCurrencyPrefix = (amountType, shareClassCurrency, currencySymbols) => {
  return (amountType === Constants.CURRENCY_DATA_KEY || amountType === Constants.AMOUNT_TYPE_ALL_DATA_KEY) ? (currencySymbols[shareClassCurrency] || '') : '';
} ;

const getPaymentInstructionsData = (tradeType, paymentInstructionId, paymentInstructions) => {
  if(tradeType && paymentInstructions && paymentInstructions[tradeType]) {
    return {
      paymentInstructionId: paymentInstructionId && paymentInstructions[tradeType].filter(paymentIns => (paymentIns.paymentInstructionId === paymentInstructionId))[0] || '',
      paymentInstructions: paymentInstructions[tradeType] || []
    };
  }
  return {
    paymentInstructionId: '',
    paymentInstructions: [],
  };

};

const transformOptionsDependentValues = (trade, currencySymbols) => {
  const {isNewAccount, options, paymentInstructionId, tradeAmount } = trade;

  if(!options) return {};

  const {settlementCycle, amountType, tradeDate, tradeType, productHolidays, shareClassCurrency,
    homeAway, mtdAccrual, navStrikeTime, nav, showNav, showNextNav, nextNavStrikeTime,
    availableShare, availableBalance, paymentInstructions} = options;

  const amountTypes = getAmountTypes(amountType, shareClassCurrency, trade.tradeType);
  const tradeTypes = getTradeTypes(tradeType);

  const  {startDate, endDate, tradeCutoff} = getTradeDates(trade, options);
  const  homeAwayOpts = getHomeAway(homeAway);
  const currencySymbol = shareClassCurrency ? currencySymbols[shareClassCurrency] : '';

  const tradeAmountCurrencySymbolPrefix = getCurrencyPrefix(trade.amountType, shareClassCurrency, currencySymbols);
  const amountTypeObj = getAmountType(trade.amountType, shareClassCurrency);

  const mtdAccrualVal = mtdAccrual && (trade.amountType === Constants.AMOUNT_TYPE_ALL_DATA_KEY && `${currencySymbol}${currencyFormatter(mtdAccrual)}`);
  const availableBalanceVal =  availableBalance  && `${currencySymbol}${currencyFormatter(availableBalance)}` || t('tkNullDataPlaceholder');
  const sharePrecision = trade.shareClass.taId === 2 ? 4 : 3;
  const availableShareVal = availableShare && `${amountFormatter(availableShare, sharePrecision, false)}` || t('tkNullDataPlaceholder');
  const paymentInstructionsData = getPaymentInstructionsData(trade.tradeType, paymentInstructionId, paymentInstructions);

  const settlementCycleType = trade.tradeDate && options && tradeDate && new Date(trade.tradeDate) > new Date(tradeDate) ? Constants.SETTLEMENT_CYCLE_FUTURE : Constants.SETTLEMENT_CYCLE_CURRENT;

  const settlementCycles = settlementCycle && settlementCycle[settlementCycleType].map(val => ({
    label: val,
    value: val
  })) || [];

  return {
    tradeTypes,
    amountTypes,
    tradeCutoff,
    currencySymbol,
    settlementCycles,
    tradeAmount,
    startDate,
    homeAway: homeAwayOpts,
    mtdAccrual: mtdAccrualVal,
    holidays: productHolidays,
    amountType: amountTypeObj,
    isTradeDateDisabled: !tradeDate,
    tradeAmountCurrencySymbolPrefix,
    isTradeTypeDisabled: !tradeType,
    availableShare: availableShareVal,
    availableBalance: availableBalanceVal,
    isSettlementCycleDisabled: !settlementCycle,
    nav: showNav && (nav || t('tkNullDataPlaceholder')),
    navStrikeTime: showNav && nav && navStrikeTime,
    endDate: isNewAccount ? startDate : endDate,
    nextNavStrikeTime: showNextNav &&  (nextNavStrikeTime || t('tkNullDataPlaceholder')),
    isAmountTypeDisabled: (!(amountType && trade.tradeType && amountType[trade.tradeType])),
    ...paymentInstructionsData
  };

};

const dpPrecision  = {
  shares: '3',
  currency: '2'
};

const transformTradeDependentValues = (trade) => {

  const {firm, account, shareClass, tradeDate, settlementCycle, tradeType, amountType,
    displayPosition, isNewAccount, hasOpenAccounts, homeAwayType,  errors=[], warnings=[], inlineErrors=[],
    inlineWarnings=[], inlineInformation=[], intendedSourceSystem} = trade;

  const tradeTypeObj = tradeType ? {label: tradeType === Constants.TRADE_TYPE_PURCHASE_DATA_KEY ? t('tkPurchase') : t('tkRedeem'), value: tradeType} : {};
  const noAmountTypeValue = !(amountType && Object.keys(amountType).length);
  const sharePrecision = amountType === Constants.SHARES_DATA_KEY && shareClass && shareClass.taId === 2 ? 4 : dpPrecision.shares;
  const tradeAmountDecimalPrecision = amountType === Constants.SHARES_DATA_KEY ? Number(sharePrecision) : Number(dpPrecision.currency);
  const isTradeAmountDisabled = noAmountTypeValue || amountType === Constants.AMOUNT_TYPE_ALL_DATA_KEY;
  const isShareClassDisabled = isNewAccount || (hasOpenAccounts === false);
  const isPaymentInstructionIdDisabled = !(tradeType === Constants.TRADE_TYPE_REDEEM_DATA_KEY);


  const homeAwayTypeObj = homeAwayType ? {
    label: homeAwayType  === Constants.HOME ? t('tkHome') : t('tkAway'),
    value: homeAwayType
  } : {};

  return {
    firm,
    errors,
    account,
    warnings,
    tradeDate,
    shareClass,
    inlineErrors,
    inlineWarnings,
    inlineInformation,
    displayPosition,
    intendedSourceSystem,
    isShareClassDisabled,
    isTradeAmountDisabled,
    tradeAmountDecimalPrecision,
    isPaymentInstructionIdDisabled,
    tradeType: tradeTypeObj,
    homeAwayType : homeAwayTypeObj,
    isAccountDisabled: isNewAccount,
    isFirmBranchDisabled: isNewAccount,
    isHomeAwayTypeDisabled: noAmountTypeValue,
    settlementCycle: settlementCycle ? {label: settlementCycle, value: settlementCycle} : {},
  };
};

const getTransformedTrade = ({trade, currencySymbols, tradeOptions = {}, canTrade, activeTrade}) => {

  const tradeDependentValues = transformTradeDependentValues(trade);
  const optionsDependentValue = transformOptionsDependentValues(trade, currencySymbols, activeTrade);

  const isShellAccountAllowed = tradeOptions && tradeOptions.isShellAccountAllowed && canTrade;
  return Object.keys(tradeOptions).length ? { ...tradeDependentValues, ...optionsDependentValue, ...tradeOptions, isShellAccountAllowed } :
    { ...tradeDependentValues, ...optionsDependentValue, isShellAccountAllowed };
};

/** *
 * End of new code
 * */

const getTransformedInternalUserTrade = ({trade,  currencySymbols, labels, tradeOptions, canTrade, activeTrade, isTradeOptionsLoading = false}) => {

  const transformedTrade = getTransformedTrade({trade, labels, currencySymbols, tradeOptions, canTrade, activeTrade});

  let tradeOptionsMissingShareClasses;
  let tradeOptionsMissingFirms;
  const {firm, shareClass, account} = trade;

  if(tradeOptions && Object.keys(tradeOptions).length) {

    const {shareClasses, firms} = tradeOptions;
    const isActiveTrade = trade.displayPosition === activeTrade.displayPosition;

    if(isActiveTrade && !isTradeOptionsLoading) {
      tradeOptionsMissingShareClasses = (!shareClasses || (shareClasses && !shareClasses.length));
      tradeOptionsMissingFirms = (!firms || (firms && !firms.length));
    } else {
      tradeOptionsMissingShareClasses = !shareClass || (shareClass && !Object.keys(shareClass).length);
      tradeOptionsMissingFirms = !firm || (firm && !Object.keys(firm).length);
    }
  }
  const internalUserTradeOptions = {
    accountName: account && account.name ? account.name : (account && shareClass && !isTradeOptionsLoading ? t('tkNullDataPlaceholder') : ''),
    isFirmBranchDisabled: !account || (tradeOptionsMissingShareClasses && tradeOptionsMissingFirms) || false,
    isShareClassDisabled: !account || (tradeOptionsMissingShareClasses && tradeOptionsMissingFirms) || false

  };
  return {...transformedTrade, ...internalUserTradeOptions};
};

export const transformedDraftTradesSelector = createSelector(
  draftTradesSelector, currencySymbolSelector, isInternalUserSelector, labelSelector, activeTradeSelector,
  (draftTrades, currencySymbols, isInternalUser, labels, activeTrade) => {
    if (!draftTrades.length || !Object.keys(currencySymbols).length || !Object.keys(labels).length) {
      return [];
    }

    const data = isInternalUser ?
      draftTrades.map(draftTrade => getTransformedInternalUserTrade({
        trade: draftTrade,
        currencySymbols,
        labels,
        activeTrade
      })) :
      draftTrades.map(draftTrade => getTransformedTrade({
        trade: draftTrade,
        currencySymbols,
        labels,
        activeTrade
      }));
    return data;
  }
);

export const transformedActiveTradeSelector = createSelector(
  activeTradeSelector, tradeOptionsSelector, currencySymbolSelector, isInternalUserSelector, labelSelector, isTradingAllowedSelector, isTradeOptionsLoadingSelector,
  (activeTrade, tradeOptions, currencySymbols, isInternalUser, labels, canTrade, isTradeOptionsLoading) => {
    if (!Object.keys(activeTrade).length || !Object.keys(currencySymbols).length || !Object.keys(labels).length) {
      return {};
    }

    const tradeArguments = {
      trade: activeTrade,
      currencySymbols,
      labels,
      tradeOptions,
      canTrade,
      activeTrade,
      isTradeOptionsLoading
    };
    return isInternalUser ? getTransformedInternalUserTrade(tradeArguments) : getTransformedTrade(tradeArguments);
  }
);

export const inlineErrorExistSelector = createSelector(
  draftTradesSelector, activeTradeSelector, (draftTrades, activeTrade) => {
    if (draftTrades.length === 0) {
      return false;
    }
    return draftTrades.some(draftTrade => {
      if (draftTrade[Constants.DISPLAY_POSITION] === activeTrade[Constants.DISPLAY_POSITION]) {
        return (activeTrade[Constants.INLINE_ERRORS] && activeTrade[Constants.INLINE_ERRORS].length > 0);
      } else {
        return (draftTrade[Constants.INLINE_ERRORS] && draftTrade[Constants.INLINE_ERRORS].length > 0);
      }
    });
  }
);

export const areAllTradesCompleteSelector = createSelector(
  activeTradeSelector,
  draftTradesSelector,
  completeTradesSelector,
  enterTradeConfigSelector,
  (activeTrade, draftTrades, completeTrades, enterTradeConfig) => {
    if (isRowComplete(activeTrade, enterTradeConfig)) {
      const numberOfCompleteTrades = completeTrades[activeTrade[Constants.DISPLAY_POSITION]] ? Object.keys(completeTrades).length : Object.keys(completeTrades).length + 1;
      return numberOfCompleteTrades === draftTrades.length;
    } else {
      return false;
    }
  }
);

export const verifyTradesDisabledSelector = createSelector(
  isVerifyingTradesSelector,
  inlineErrorExistSelector,
  areAllTradesCompleteSelector,
  (isTradeBlotterVerifying, inlineErrorExists, areAllTradesComplete) => {
    return isTradeBlotterVerifying || inlineErrorExists || !areAllTradesComplete;
  }
);

export const showAcknowledgeAndProceedSelector = createSelector(
  isBlotterDirtySelector,
  areAllTradesCompleteSelector,
  inlineErrorExistSelector,
  errorTradesCountSelector,
  warningTradesCountSelector,
  (isBlotterDirty, areAllTradesComplete, inlineErrorExists, errorTradesCount, warningTradesCount) => {
    return !isBlotterDirty && !inlineErrorExists && areAllTradesComplete && errorTradesCount === 0 && warningTradesCount > 0;
  }
);

export const verifyTradesLabelSelector = createSelector(
  showAcknowledgeAndProceedSelector,
  draftTradesSelector,
  labelSelector,
  (showAcknowledgeAndProceed, draftTrades, labels) => {
    return showAcknowledgeAndProceed ? labels.tkAcknowledgeAndProceed : draftTrades.length > 1 ? labels.tkVerifyTrades : labels.tkVerifyTrade;
  }
);

export const reviewTradesSubtextSelector = createSelector(
  draftTradesSelector,
  labelSelector,
  (draftTrades, labels) => {
    if (draftTrades.length > 0) {
      if (draftTrades.every(trade => trade.amountType === Constants.CURRENCY_DATA_KEY)) {
        return draftTrades.length > 1 ? labels.tkCurrencyOnlyMultiple : labels.tkCurrencyOnly;
      }

      const anyShares = draftTrades.some(trade => trade.amountType === Constants.SHARES_DATA_KEY);
      const anyAll = draftTrades.some(trade => trade.amountType === Constants.AMOUNT_TYPE_ALL_DATA_KEY);

      if (anyShares && !anyAll) {
        const label = draftTrades.length > 1 ? labels.tkAnySharesNoAllMultiple : labels.tkAnySharesNoAll;
        return `${label}\n${labels.tkAnySharesNoAllDisclaimer}`;
      }
      else if (anyAll) {
        return labels.tkAnyAll;
      } else {
        return '';
      }
    }
  }
);

export const redemptionFeeTradesSelector = createSelector(
  draftTradesSelector,
  currencySymbolSelector,
  (draftTrades, currencySymbol) => {
    const trades = [];
    if (draftTrades && draftTrades.length > 0) {
      draftTrades.forEach(trade => {
        const {shareClass = {}, account, tradeType, options, displayPosition} = trade;
        const {shareClassCurrency, tradeAmount, feeAmount: activeFeeAmount, fundFees = []} = options || {};
        const {name, feePercent: activeFeePercent, isFeeActive} = shareClass;
        const discretionaryFee = fundFees.find(({feeTypeShortCode}) => (
          feeTypeShortCode === TRADEBLOTTER_FUND_FEES.DISCRETIONARY_FEE
        )) || {};
        const {feePercent: discretionaryFeePercent, feeAmount: discretionaryFeeAmount} = discretionaryFee;
        const feeAmount = isFeeActive ? activeFeeAmount : discretionaryFeeAmount;
        const feePercent = isFeeActive ? activeFeePercent : discretionaryFeePercent;
        if (tradeType === Constants.TRADE_TYPE_REDEEM_DATA_KEY && feePercent) {
          const redemptionFeeObj = {
            accountName: account.name,
            shareClassName: name,
            tradeAmount: `${currencySymbol[shareClassCurrency]}${amountFormatter(tradeAmount)}`,
            feeAmount: `${currencySymbol[shareClassCurrency]}${amountFormatter(feeAmount)}`,
            feePercent: `${feePercent}%`,
            displayPosition
          };
          trades.push(redemptionFeeObj);
        }
      });
    }
    return trades;
  }
);

export const isVerifyingOrPlacingSelector = createSelector(
  isVerifyingTradesSelector,
  isPlacingTradesSelector,
  (isTradeBlotterVerifying, isTradeBlotterPlacing) => {
    return isTradeBlotterVerifying || isTradeBlotterPlacing;
  }
);

export const tradeBlotterBannerDataSelector = createSelector(
  draftTradesSelector, labelSelector, isTradingDisabledSelector,
  (draftTrades, labels, isTradingDisabled) => {
    const draftTradesLen = draftTrades.length;
    let message = labels.tkTradeBlotterEmpty;
    let actionLabel = labels.tkStartTrading;
    if (isTradingDisabled) {
      message = labels.SKINNY_RETRIEVE_FAILED;
      actionLabel = '';
    } else if (draftTradesLen > 0) {
      if (draftTradesLen === 1) {
        message = labels.tkTradeBlotterSingular;
        actionLabel = labels.tkViewTrade;
      } else {
        message = injectKeys(labels.tkTradeBlotterPlural, draftTradesLen);
        actionLabel = labels.tkViewTrades;
      }
    }
    return {message, actionLabel};
  }
);

export const viewTradeStatusSelector = createSelector(
  allowFeaturesSelector, (allowFeatures) => {
    return getAppRoute(allowFeatures.includes(Constants.CTSEntitlement) ? Constants.TRADE_CURRENT_TRADE_STATUS : Constants.REPORT_CTS_ROUTE);
  }
);
