import { useContext, useEffect, FC } from 'react';
import PropTypes from 'prop-types';
import { ButtonProps, Button } from '@gs-ux-uitoolkit-react/button';
import { FormContext } from '@gs-ux-uitoolkit-react/form';
import {
    inputGroupButtonStyleSheet,
    globalInputGroupButtonClass,
} from '@gs-ux-uitoolkit-common/input';
import { cx, useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { componentAnalytics } from './analytics-tracking';

export interface InputGroupButtonProps extends Omit<ButtonProps, 'size'> {}

/**
 * InputGroupButton allows a [Button](./button) to be integrated cohesively into a containing
 * InputGroup component.
 */
export const InputGroupButton: FC<InputGroupButtonProps> = props => {
    const { className, classes, disabled, status = 'none', ...otherProps } = props;

    const formContext = useContext(FormContext);

    const theme = useTheme();

    const internalSize = formContext.size || 'md';

    const cssClasses = useStyleSheet(inputGroupButtonStyleSheet, {
        theme,
        size: internalSize,
        status,
    });

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({
            componentName: 'input-group-button',
            officialComponentName: 'other',
        });
    }, []); // Only run once

    return (
        <Button
            className={cx(cssClasses.root, className, globalInputGroupButtonClass)}
            classes={classes}
            disabled={disabled !== undefined ? disabled : formContext.disabled}
            size={internalSize}
            status={status}
            {...otherProps}
            data-gs-uitk-component="input-group-button"
        />
    );
};

InputGroupButton.propTypes = {
    ...Button.propTypes,
    // Duping actionType is necessary because otherwise TypeScript complains:
    // "Type 'string' is not assignable to type '"primary" | "secondary" | "destructive" | "info" | "contrast" | null | undefined'"
    actionType: PropTypes.oneOf(['primary', 'secondary', 'destructive', 'info', 'contrast']),
};
