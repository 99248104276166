import { ComponentClass, createElement } from 'react';
import { Provider } from 'react-redux';
import { createRoot, Root } from 'react-dom/client';
import { GridWrapper } from '../../grid-wrappers/grid-wrapper';

let root!: Root;
export const setOverlayContainer = (
    overlayRef: HTMLElement | null,
    body: ComponentClass,
    wrapper: GridWrapper
) => {
    const content = createElement(body, {});
    // eslint-disable-next-line react/no-children-prop
    const containerWrapper = createElement(Provider, {
        children: content,
        store: wrapper.getReduxStore(),
    });
    if (overlayRef) {
        root = createRoot(overlayRef);
        root.render(containerWrapper);
    }
};
export const clearOverlayContainer = () => {
    if (root) root.unmount();
};
