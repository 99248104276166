import React from 'react';
import './index.scss';

export const ThreeSquaresLoader = () => {
  return (
    <div className="loader-wrapper three-squares">
      <span>&nbsp;</span>
      <span>&nbsp;</span>
      <span>&nbsp;</span>
    </div>
  );
};
