import * as Redux from 'redux';
import {
    DataStackPlugin,
    PluginAction,
    PluginScreen,
    PluginWidget,
    PluginIcon,
} from '../interfaces/plugins';
import { logger } from '../libraries/logger';
import { Registry } from '../libraries/registry';
import { SimpleEventDispatcher } from '@gs-ux-uitoolkit-common/dra';
import { Context } from 'react';
import { isEqual } from 'gs-uitk-lodash';

export type PluginInternalState = 'uninitialized' | 'initialized' | 'started' | 'stopped';

export interface PluginStateChange {
    eventType: 'start' | 'change';
    pluginState: any;
}

/**
 * The base class of the Plugins which will manage the lifecycle of the plugins and all the necessary registrations
 */

export interface ComponentWrapper {
    getScreensRegistry: () => Registry<PluginScreen>;
    getWidgetsRegistry: () => Registry<PluginWidget>;
    getActionsRegistry: () => Registry<PluginAction>;
    getRegisteredModulesNames: () => string[];
    getReduxStore(): Redux.Store<any>;
    /**
     * Returns the redux store context
     */
    getReduxStoreContext(): Context<any> | undefined;
}

export abstract class PluginBase<U extends ComponentWrapper, V, T = any>
    implements DataStackPlugin
{
    protected screens: PluginScreen[] = [];
    protected widgets: PluginWidget[] = [];
    protected actions: PluginAction[] = [];
    protected static requiredModules: string[] = [];
    private pluginState: T | null = null;
    private previousPluginState: T | null = null;
    private storeSubscription: Redux.Unsubscribe | null = null;
    private stateChangedEventDispatcher: SimpleEventDispatcher<PluginStateChange>;
    // TODO: expose state to grid wrapper
    private internalState: PluginInternalState = 'uninitialized';

    constructor(
        protected pluginName: string,
        protected category: string,
        protected icon: PluginIcon,
        protected wrapper: U,
        protected stateNeeded: (state: V) => T
    ) {
        this.stateChangedEventDispatcher = new SimpleEventDispatcher<PluginStateChange>();
    }

    public initialize() {
        logger.debug('Initializing plugin:' + this.pluginName);

        // register modals
        this.screens.forEach(screen =>
            this.wrapper.getScreensRegistry().register(screen.componentId, screen)
        );

        // register widgets
        this.widgets.forEach(widget =>
            this.wrapper.getWidgetsRegistry().register(widget.componentId, widget)
        );

        // register actions
        this.actions.forEach(action => {
            this.wrapper.getActionsRegistry().register(action.componentId, action);
        });

        this.internalState = 'initialized';
        logger.debug('Initialized plugin:' + this.pluginName);
    }

    public start() {
        if (this.internalState !== 'initialized') {
            throw new Error('Plugin ' + this.pluginName + ' was not initialized before starting');
        }

        logger.debug('Starting plugin:' + this.pluginName);

        const names = this.wrapper.getRegisteredModulesNames();
        this.validateModules(names);

        let pluginState: T;

        this.storeSubscription = this.wrapper.getReduxStore().subscribe(() => {
            const state = this.wrapper.getReduxStore().getState();
            pluginState = this.stateNeeded(state);
            if (!isEqual(pluginState, this.pluginState)) {
                this.previousPluginState = this.pluginState;
                this.pluginState = pluginState;
                this.stateChangedOrStart();
                this.dispatchStateChange({ eventType: 'change', pluginState });
            }
        });
        const state = this.wrapper.getReduxStore().getState();
        pluginState = this.stateNeeded(state);
        this.pluginState = pluginState;

        this.stateChangedOrStart();
        this.internalState = 'started';
        logger.debug('Started plugin:' + this.pluginName);
    }

    public stop() {
        if (this.internalState !== 'started') {
            return;
        }

        logger.debug('Stopping plugin:' + this.pluginName);
        if (this.storeSubscription) {
            this.storeSubscription();
        }
        this.internalStop();
        this.internalState = 'stopped';
        logger.debug('Stopped plugin:' + this.pluginName);
    }

    public getName(): string {
        return this.pluginName;
    }

    public getIcon(): PluginIcon {
        return this.icon;
    }
    public getScreens(): PluginScreen[] {
        return [...this.screens];
    }

    public getStateChangedEventDispatcher(): SimpleEventDispatcher<PluginStateChange> {
        return this.stateChangedEventDispatcher;
    }

    /**
     * This method gets called on startup and when the relevent part of the state changes as determined by stateNeeded
     */
    protected abstract stateChangedOrStart(): void;
    /**
     * This method gets called on Stopping the plugin
     */
    protected abstract internalStop(): void;

    protected getPluginState(): T | null {
        return this.pluginState;
    }

    protected getPreviousPluginState(): T | null {
        return this.previousPluginState;
    }

    protected validateModules(names: string[]) {
        if (
            !PluginBase.requiredModules.reduce(
                (previous, current) => previous && names.includes(current),
                true
            )
        ) {
            const errorText = `Plugin ${
                this.pluginName
            } needs these modules [${PluginBase.requiredModules.join(', ')}] to be passed to grid!`;
            console.error(errorText);
            throw new Error(errorText);
        }
    }

    private dispatchStateChange({ eventType, pluginState }: PluginStateChange): void {
        this.stateChangedEventDispatcher.dispatchAsync({
            pluginState,
            eventType,
        });
    }
}
