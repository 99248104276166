import { Store } from 'redux';
import { load as actionLoad } from './actions';
import { Loader, StorageEngine } from './types';

export function createLoader<TState>(engine: StorageEngine): Loader<TState> {
    return (store: Store<TState>) => {
        const dispatchLoad = (state: TState) => store.dispatch(actionLoad(state));
        return engine.load().then((newState: TState) => {
            dispatchLoad(newState);
            return newState;
        });
    };
}
