import { invert } from 'gs-uitk-lodash';
import PropTypes from 'prop-types';
import { Component, RefObject, createRef, KeyboardEvent } from 'react';
import Draggable from 'react-draggable';
import { Popover } from '@gs-ux-uitoolkit-react/popover';
import {
    colors as toolkitColors,
    ColorName,
    ColorValue,
} from '@gs-ux-uitoolkit-react/design-system';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { Theme, ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';
import {
    ColorPickerColor,
    ColorRamp,
    ColorPickerSelectorMode,
    ColorPickerSelectorSize,
    ColorPickerState,
    ColorPickerProps,
    colorPickerDefaultProps,
    colorPickerStyleSheet,
    ColorPickerCssClasses,
    getColorPickerRootClasses,
    getColorPickerSelectorChipClasses,
    getColorPickerSelectorChipInnerClasses,
    getColorPickerSelectorFormClasses,
    getColorPickerSelectorFormInnerClasses,
    getColorPickerSelectorIconClasses,
    getColorPickerPopupClasses,
    getColorPickerPopupSubheaderClasses,
    getColorPickerPopupContentsClasses,
    getColorPickerRampsContainerClasses,
    getColorPickerFooterClasses,
    getColorPickerFooterItemClasses,
    getColorPickerFooterLabelClasses,
    getColorPickerRampTileClasses,
    getColorPickerActiveMarkerClasses,
    getColorPickerCloseButtonClasses,
    getColorPickerScrollScreenClasses,
} from '@gs-ux-uitoolkit-common/color-picker';
import { componentAnalytics } from './analytics-tracking';
import { createStyleSheet } from '@gs-ux-uitoolkit-react/style';

const white: ColorPickerColor = {
    hex: '#ffffff',
    label: 'white',
};

/**
 * Keys used in the generate ramps method
 */
const rampKeys: ColorName[] = [
    'gray',
    'red',
    'orange',
    'yellow',
    'lime',
    'green',
    'teal',
    'turquoise',
    'aqua',
    'blue',
    'ultramarine',
    'purple',
    'pink',
];

//custom style needed for popover, we don't want the default background color on toolkit Popover
const customPopoverStyleSheet = createStyleSheet('customStyles', {
    content: {
        backgroundColor: 'transparent',
        marginTop: '-20px',
    },
});
/**
 * Custom color picker component built specifically to
 * handle exported toolkit palettes from common sass
 */
export class ColorPicker extends Component<ColorPickerProps, ColorPickerState> {
    public static propTypes: { [key in keyof ColorPickerProps]: any } = {
        className: PropTypes.string,
        mode: PropTypes.oneOf([
            ColorPickerSelectorMode.Icon,
            ColorPickerSelectorMode.Chip,
            ColorPickerSelectorMode.FormChip,
        ]),
        onSelect: PropTypes.func,
        selectedColor: PropTypes.string,
        size: PropTypes.oneOf([
            ColorPickerSelectorSize.Lg,
            ColorPickerSelectorSize.Md,
            ColorPickerSelectorSize.Sm,
        ]),
    };
    private baseClassName = 'gs-uitk-color-picker';
    private ramps: ColorRamp[];
    private containerRef: RefObject<HTMLDivElement>;
    private firstContentItemRef: RefObject<HTMLDivElement>;
    private selectorRef: RefObject<HTMLDivElement> = createRef();

    public constructor(props: ColorPickerProps) {
        super(props);

        this.ramps = [];

        this.containerRef = createRef();
        this.firstContentItemRef = createRef();

        this.state = {
            hoveredColor: null,
            popoverOpen: false,
            scrolledToTop: true,
            selectedColor: this.processSelectedColor(this.props.selectedColor),
        };
    }

    public componentDidMount() {
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'other' });
    }

    public componentDidUpdate(prevProps: ColorPickerProps) {
        const selectedColor = this.props.selectedColor;
        if (prevProps.selectedColor !== selectedColor) {
            this.setState({
                selectedColor: this.processSelectedColor(selectedColor),
            });
        }
    }

    componentWillUnmount() {
        colorPickerStyleSheet.unmount(this);
    }

    public render() {
        const { selectedColor, popoverOpen, hoveredColor, scrolledToTop } = this.state;
        const { className, size, classes: overrideClasses } = this.props;
        const { baseClassName } = this;
        const customPopoverClasses = customPopoverStyleSheet.mount(this, null);

        return (
            <ThemeConsumer>
                {(theme: Theme) => {
                    const cssClasses = colorPickerStyleSheet.mount(this, {
                        theme,
                        size: size || colorPickerDefaultProps.size,
                        scrollScreenActive: !scrolledToTop,
                    });
                    this.ramps = this.generateRamps(theme);
                    return (
                        <div
                            className={getColorPickerRootClasses({ className, overrideClasses })}
                            data-cy={baseClassName} // backward-compatibility, use data-gs-uitk-component instead
                            data-gs-uitk-component="color-picker"
                        >
                            {this.renderSelector(cssClasses, overrideClasses)}
                            <Popover
                                visible={popoverOpen}
                                className={customPopoverClasses.content}
                                showTip={false}
                                target={this.selectorRef}
                                placement="bottom"
                                onClickOutside={this.toggle}
                                data-cy={`${baseClassName}-popup-container`}
                            >
                                <Draggable nodeRef={this.containerRef}>
                                    <div
                                        className={getColorPickerPopupClasses({
                                            cssClasses,
                                            overrideClasses,
                                        })}
                                        ref={this.containerRef}
                                        onScroll={this.onScroll}
                                    >
                                        <div
                                            className={getColorPickerPopupContentsClasses({
                                                cssClasses,
                                                overrideClasses,
                                            })}
                                            data-cy={`${baseClassName}-popup__contents`}
                                        >
                                            <div
                                                className={getColorPickerPopupSubheaderClasses({
                                                    cssClasses,
                                                    overrideClasses,
                                                })}
                                                data-cy={`${baseClassName}-popup__subheader`}
                                                ref={this.firstContentItemRef}
                                            >
                                                Primary Palette
                                            </div>
                                            <div
                                                className={getColorPickerRampsContainerClasses({
                                                    cssClasses,
                                                    overrideClasses,
                                                })}
                                                onMouseLeave={this.resetHoverColor}
                                            >
                                                {this.renderPrimaries(cssClasses, overrideClasses)}
                                            </div>
                                            <div
                                                className={getColorPickerPopupSubheaderClasses({
                                                    cssClasses,
                                                    overrideClasses,
                                                })}
                                                data-cy={`${baseClassName}-popup__subheader`}
                                            >
                                                Full Palette
                                            </div>
                                            <div
                                                className={getColorPickerRampsContainerClasses({
                                                    cssClasses,
                                                    overrideClasses,
                                                })}
                                                onMouseLeave={this.resetHoverColor}
                                            >
                                                {this.ramps.map(r => (
                                                    <div key={r.id}>
                                                        {r.colors.map(c =>
                                                            this.renderTile(
                                                                c,
                                                                cssClasses,
                                                                overrideClasses
                                                            )
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div
                                            className={getColorPickerFooterClasses({
                                                cssClasses,
                                                overrideClasses,
                                            })}
                                        >
                                            <div
                                                className={getColorPickerFooterItemClasses({
                                                    cssClasses,
                                                    overrideClasses,
                                                })}
                                                data-cy={`${baseClassName}__name-footer-item`}
                                            >
                                                <span
                                                    className={getColorPickerFooterLabelClasses({
                                                        cssClasses,
                                                        overrideClasses,
                                                    })}
                                                    data-cy={`${baseClassName}__name-footer-label`}
                                                >
                                                    Name:
                                                </span>
                                                {this.generateLabelDisplayName(
                                                    (hoveredColor && hoveredColor.label) ||
                                                        selectedColor.label
                                                )}
                                            </div>
                                            <div
                                                className={getColorPickerFooterItemClasses({
                                                    cssClasses,
                                                    overrideClasses,
                                                })}
                                                data-cy={`${baseClassName}__hex-footer-item`}
                                            >
                                                <span
                                                    className={getColorPickerFooterLabelClasses({
                                                        cssClasses,
                                                        overrideClasses,
                                                    })}
                                                    data-cy={`${baseClassName}__hex-footer-label`}
                                                >
                                                    Hex:
                                                </span>
                                                {(hoveredColor && hoveredColor.hex) ||
                                                    selectedColor.hex}
                                            </div>
                                        </div>
                                        <div
                                            className={getColorPickerScrollScreenClasses({
                                                cssClasses,
                                                overrideClasses,
                                            })}
                                        />
                                        <div
                                            className={getColorPickerCloseButtonClasses({
                                                cssClasses,
                                                overrideClasses,
                                            })}
                                            data-cy={`${baseClassName}__close-button`}
                                            onClick={this.toggle}
                                            onKeyDown={this.toggleOrCloseWithKey}
                                            role="button"
                                            tabIndex={0}
                                        >
                                            &#215;
                                        </div>
                                    </div>
                                </Draggable>
                            </Popover>
                        </div>
                    );
                }}
            </ThemeConsumer>
        );
    }

    /**
     * Process a passed in color or set to default white
     */
    private processSelectedColor(selectedColor: string | undefined): ColorPickerColor {
        let foundLabel;
        if (selectedColor) {
            this.verifyColorFormat(selectedColor);
            foundLabel = invert(toolkitColors)[selectedColor.toLowerCase()];
        }

        return {
            hex: selectedColor || white.hex,
            label: selectedColor ? foundLabel || 'Custom' : white.label,
        };
    }

    /**
     * Make sure passed color conforms to hex format
     */
    private verifyColorFormat(color: string) {
        const hexColorRegex = /^#[\dA-Fa-f]{6}$/;
        if (!hexColorRegex.test(color)) {
            throw new Error(
                `Initial color prop value '${color}' passed into ColorPicker should be a hex value of format: "#000000"`
            );
        }
    }

    /**
     * Generate an array of ramps from the toolkit colors
     */
    private generateRamps(theme: Theme): ColorRamp[] {
        const ramps: ColorRamp[] = [];
        rampKeys.forEach(c => {
            const ramp: ColorRamp = {
                colors: [],
                id: `${c}-ramp-tile`,
            };
            for (let i = 100; i > 0; i -= 10) {
                const key = `${c}${String(i).padStart(3, '0')}` as ColorValue;
                const result = toolkitColors[key];
                if (result) {
                    ramp.colors.push({
                        hex: result.toUpperCase(),
                        label: key,
                    });
                }
            }
            if (ramp.colors.length) {
                if (this.isDarkMode(theme)) {
                    ramp.colors = ramp.colors.reverse();
                }
                ramps.push(ramp);
            }
        });
        return ramps;
    }

    /**
     * Open/close the popover
     */
    private toggle = () => {
        this.setState({ popoverOpen: !this.state.popoverOpen });
    };

    /**
     * Toggle the popover for enter key, close for escape key
     */
    private toggleOrCloseWithKey = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.code == 'Enter') {
            this.toggle();
        } else if (e.code == 'Escape') {
            this.setState({ popoverOpen: false });
        }
    };

    /**
     * Clear out the currently hovered color
     */
    private resetHoverColor = () => {
        this.setState({ hoveredColor: null });
    };

    /**
     * Sets the color currently being hovered over
     */
    private setHoverColor(color: ColorPickerColor) {
        this.setState({ hoveredColor: color });
    }

    /**
     * Process the color key name into a friendly display name
     *
     * Example: 'blue-030' => 'Blue 030'
     */
    private generateLabelDisplayName(label: string): string {
        return label
            .replace(/([A-Za-z]+)(\d{3})/g, '$1 $2') // ex: 'gray020' -> 'gray 020'
            .split(' ')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1)) // caplitalize each word
            .join(' ');
    }

    /**
     * Local tile select event - also triggers passed in callback
     */
    private onSelect(color: ColorPickerColor) {
        this.setState({
            selectedColor: color,
        });
        if (this.props.onSelect) {
            this.props.onSelect(color.hex);
        }
    }

    /**
     * Select color for enter key, close popover for escape key
     */
    private selectOrCloseWithKey(color: ColorPickerColor, e: KeyboardEvent<HTMLDivElement>) {
        if (e.code == 'Enter') {
            this.onSelect(color);
        } else if (e.code == 'Escape') {
            this.setState({ popoverOpen: false });
        }
    }

    /**
     * Creates the popup selector
     */
    private renderSelector(
        cssClasses: ColorPickerCssClasses,
        overrideClasses?: ColorPickerProps['classes']
    ): JSX.Element {
        const style = { background: this.state.selectedColor.hex };
        let selectorClassName = '';
        let contents;

        switch (this.props.mode || ColorPickerSelectorMode.Chip) {
            case ColorPickerSelectorMode.Chip:
                selectorClassName = getColorPickerSelectorChipClasses({
                    cssClasses,
                    overrideClasses,
                });
                contents = (
                    <div
                        style={style}
                        className={getColorPickerSelectorChipInnerClasses({
                            cssClasses,
                            overrideClasses,
                        })}
                        data-cy={`${this.baseClassName}__selector-inner`}
                    />
                );
                break;

            case ColorPickerSelectorMode.FormChip:
                selectorClassName = getColorPickerSelectorFormClasses({
                    cssClasses,
                    overrideClasses,
                });
                contents = (
                    <div
                        style={style}
                        className={getColorPickerSelectorFormInnerClasses({
                            cssClasses,
                            overrideClasses,
                        })}
                        data-cy={`${this.baseClassName}__selector-inner`}
                    />
                );
                break;

            case ColorPickerSelectorMode.Icon:
                selectorClassName = getColorPickerSelectorIconClasses({
                    cssClasses,
                    overrideClasses,
                });
                contents = <Icon name="brush" type="filled" />;
                break;
        }

        return (
            <div
                className={selectorClassName}
                ref={this.selectorRef}
                onClick={this.toggle}
                onKeyDown={this.toggleOrCloseWithKey}
                data-cy={`${this.baseClassName}__selector`}
                role="button"
                tabIndex={0}
            >
                {contents}
            </div>
        );
    }

    /**
     * Parse ramps for primaries and create tiles
     * Convention: take 060 as primary
     */
    private renderPrimaries(
        cssClasses: ColorPickerCssClasses,
        overrideClasses?: ColorPickerProps['classes']
    ): JSX.Element[] {
        return this.ramps
            .map(ramp => ramp.colors[4]) // 100 090 080 070 060 050 040 030 020 010
            .filter(color => !!color)
            .map(color => {
                const tileColor = color.label === 'gray-060' ? white : color;
                return (
                    <div key={`${tileColor.hex}-primary`}>
                        {this.renderTile(tileColor, cssClasses, overrideClasses)}
                    </div>
                );
            });
    }

    private isDarkMode = (theme: Theme) => {
        return theme.colorMode === 'dark';
    };

    /**
     * Create a color tile
     */
    private renderTile(
        color: ColorPickerColor,
        cssClasses: ColorPickerCssClasses,
        overrideClasses?: ColorPickerProps['classes']
    ): JSX.Element {
        const isActive = color.hex === this.state.selectedColor.hex;
        return (
            <div
                className={getColorPickerRampTileClasses({ cssClasses, overrideClasses })}
                key={color.hex}
                style={{
                    background: color.hex,
                    border: `1px solid ${color.hex === white.hex ? '#000000' : color.hex}`,
                }}
                onClick={() => this.onSelect(color)}
                onKeyDown={event => this.selectOrCloseWithKey(color, event)}
                onMouseEnter={() => this.setHoverColor(color)}
                data-cy={`${this.baseClassName}__ramp-tile`}
                data-color={`${color.hex}`}
                role="button"
                tabIndex={-1}
            >
                {isActive && (
                    <div
                        className={getColorPickerActiveMarkerClasses({
                            cssClasses,
                            overrideClasses,
                        })}
                        data-cy={`${this.baseClassName}__active-marker`}
                    />
                )}
            </div>
        );
    }

    /**
     * Handle scroll event to add transparent white box
     * behind close button when scrolling starts
     */
    private onScroll = () => {
        const container = this.containerRef.current;
        const containerItem = this.firstContentItemRef.current;
        if (container && containerItem) {
            const containerOffset = container.getBoundingClientRect().top;
            const containerItemOffset = containerItem.getBoundingClientRect().top;
            const scrollDifference = containerOffset - containerItemOffset;
            this.setState({
                scrolledToTop: scrollDifference < -5,
            });
        }
    };
}
