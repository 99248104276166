import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {EXTERNAL_LINK_SKIP_ATTRIBUTE} from '../../../../constants/pageConstants';
import Button from '../../../../components/core/Button';
import {controlPanleMapDispatchToProps} from '../../../Grid/mapDispatchToProps';
import {allowPDFDownloadFeatureSelector} from '../../../../selectors/global';
import {pageIdSelector} from '../../../../selectors/pages';
import {getFundTrackerTabsConfig} from '../../../../selectors/pages/fundTracker';
import {useChartContext} from '../../context';
import './index.scss';

const ActionBar = (props) => {
  const {
    stepperConfig, currentPageId, allowPDFDownload,
    dispatchDownloadExcel, dispatchOpenFileDownloadModal
  } = props;
  const [currentTab, setCurrentTab] = useState(currentPageId);
  const chartContext = useChartContext();
  const {
    state: {
      primaryShareClassId,
      isFundOrBenchmarkDataMissing
    }
  } = chartContext;
  const isShowActionBarButtons = primaryShareClassId && !isFundOrBenchmarkDataMissing;

  const handleScroll = () => {
    const scrollPosition = window.pageYOffset;
    sessionStorage.setItem('fundTrackerScrollPosition', `${scrollPosition}`);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      sessionStorage.removeItem('fundTrackerScrollPosition');
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const scrollPosition = sessionStorage.getItem('fundTrackerScrollPosition');
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
    }
    setCurrentTab(currentPageId);
  }, [currentPageId]);

  const onTabClick = (event, tab) => {
    if(currentPageId === tab.id) return;
    setCurrentTab(tab.id);
  };

  const handleDownloadExcel = (props) => {
    dispatchDownloadExcel(props);
  };

  const handleOpenFileDownloadModal = (props) => {
    dispatchOpenFileDownloadModal(props);
  };

  const handleFileDownload = () => {
    if (allowPDFDownload) {
      handleOpenFileDownloadModal();
    } else {
      handleDownloadExcel();
    }
  };

  return (
    !isEmpty(stepperConfig) &&
      <div className="actionbar">
        <div className="actionbar__stepper">
          {
            stepperConfig.map((tab) => {
              const isTabMatched = (currentPageId === tab.id) || (currentTab === tab.id);
              return (
                <NavLink
                  exact={tab.exact}
                  key={tab.routingPathId}
                  to={{
                    pathname: tab.path,
                    state: {pageId: currentPageId || tab.id}
                  }}
                  onClick={(e) => onTabClick(e, tab)}
                  isActive={() => isTabMatched}
                  className={cn('tab', {'tab--disabled': isTabMatched})}
                  activeClassName="tab--active"
                  {...{[EXTERNAL_LINK_SKIP_ATTRIBUTE]: true}}
                >
                  {tab.label}
                </NavLink>
              );
            })
          }
        </div>
        {
          isShowActionBarButtons &&
            <div className="actionbar__buttons">
              <Button
                iconClass="downloadTracker"
                clickHandler={handleFileDownload}
              />
            </div>
        }
      </div>
  );
};

ActionBar.propTypes = {
  currentPageId: PropTypes.string,
  stepperConfig: PropTypes.array,
  allowPDFDownload: PropTypes.bool,
  dispatchDownloadExcel: PropTypes.func,
  dispatchOpenFileDownloadModal: PropTypes.func
};

export const mapStateToProps = (state) => ({
  allowPDFDownload: allowPDFDownloadFeatureSelector(state),
  currentPageId: pageIdSelector(state),
  stepperConfig: getFundTrackerTabsConfig(state)
});

export default connect(mapStateToProps, controlPanleMapDispatchToProps)(ActionBar);
