import {
    PropsWithChildren,
    AriaAttributes,
    MouseEvent,
    useEffect,
    useMemo,
    FunctionComponent,
} from 'react';
import { boxStyleSheet, getBoxClassNames } from '@gs-ux-uitoolkit-common/layout';
import { fontFamily } from '@gs-ux-uitoolkit-common/design-system';
import { ReactComponentProps } from '@gs-ux-uitoolkit-react/component';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { Properties } from 'csstype';
import { componentAnalytics } from './analytics-tracking';

export type BoxProps = ReactComponentProps &
    AriaAttributes &
    PropsWithChildren<{
        onClick?: (e: MouseEvent) => void;
        as?: keyof JSX.IntrinsicElements;
    }> &
    Pick<
        Properties,
        | 'background'
        | 'boxSizing'
        | 'color'
        | 'display'
        | 'margin'
        | 'minWidth'
        | 'padding'
        | 'fontFamily'
    > & {
        /**
         * Alias for the `margin` CSS property
         */
        m?: string;

        /**
         * Sets the `margin-top` value
         */
        mt?: string;

        /**
         * Sets the `margin-bottom` value
         */
        mb?: string;

        /**
         * Sets the `margin-right` value
         */
        mr?: string;

        /**
         * Sets the `margin-left` value
         */
        ml?: string;

        /**
         * Alias for the `padding` CSS property
         */
        p?: string;

        /**
         * Sets the `padding-top` value
         */
        pt?: string;

        /**
         * Sets the `padding-bottom` value
         */
        pb?: string;

        /**
         * Sets the `padding-right` value
         */
        pr?: string;

        /**
         * Sets the `padding-left` value
         */
        pl?: string;
    };

export const boxDefaultProps: BoxProps = {
    boxSizing: 'border-box',
    margin: 0,
    minWidth: 0,
    fontFamily: fontFamily.goldmanSans,
};

export const Box: FunctionComponent<BoxProps> = ({
    background,
    boxSizing = boxDefaultProps.boxSizing,
    color,
    display,
    margin = boxDefaultProps.margin,
    m,
    mt,
    mb,
    mr,
    ml,
    minWidth = boxDefaultProps.minWidth,
    padding,
    p,
    pt,
    pb,
    pr,
    pl,
    children,
    className,
    fontFamily = boxDefaultProps.fontFamily,
    as,
    ...props
}: BoxProps) => {
    const styles = useMemo(
        () => ({
            background,
            boxSizing,
            color,
            display,
            margin: m || margin,
            marginTop: mt,
            marginBottom: mb,
            marginRight: mr,
            marginLeft: ml,
            minWidth,
            padding: p || padding,
            paddingTop: pt,
            paddingBottom: pb,
            paddingRight: pr,
            paddingLeft: pl,
            fontFamily,
        }),
        [
            background,
            boxSizing,
            color,
            display,
            m,
            margin,
            mt,
            mb,
            mr,
            ml,
            minWidth,
            p,
            padding,
            pt,
            pb,
            pr,
            pl,
            fontFamily,
        ]
    );

    const theme = useTheme();
    const cssClasses = useStyleSheet(boxStyleSheet, { styles, theme });
    const Element: keyof JSX.IntrinsicElements = as || 'div';

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({
            componentName: 'box',
            officialComponentName: 'box',
        });
    }, []); // Only run once

    return (
        <Element
            data-gs-uitk-component="box"
            className={getBoxClassNames({ cssClasses, className })}
            {...props}
        >
            {children}
        </Element>
    );
};
