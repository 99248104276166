import actionTypes from '../../../../actions/page/preferences/investmentPolicy/actionTypes';

export function investmentPolicyDataReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.INVESTMENT_POLICY_DATA_LOADING:
      return {...state, ...action.data};
    case actionTypes.INVESTMENT_POLICY_TAB_CHANGE:
      return {...state, selectedPolicyId: action.data};
    default:
      return state;
  }
}

