import React, { useRef, useMemo, useCallback, useState, useEffect } from 'react';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { Button } from '@gs-ux-uitoolkit-react/button';
import PropTypes from 'prop-types';
import useFetch from '../../hooks/useFetch';
import useSnackBar from '../../hooks/useSnackbar';
import useUserMaintenance from '../../context/index';
import AutoComplete from '../../../../../components/core/AutoComplete';
import ParentOrgWarningModal from '../OrganizationDetails/ParentOrgWarningModal';
import endPointsMapper from '../../../../../configs/endPointsMapper';
import { getSortedStringData } from '../../../../../utils/sorting';
import { CHANGE, REMOVE } from '../../constants';
import translator from '../../../../../services/translator';

const { translate: t } = translator;

const AutoCompleteOrgSearch = props => {
  const {
    state: { organizationDetails: { id: editingOrganizationId = '' } = {} },
  } = useUserMaintenance();
  const { value = {}, config, handleChange, errorState: { errorMessage } = {}, canDeleteField, fieldIndex } = props;

  const { organizationName, organizationId, relationshipType } = value;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [defaultSearchTerm, setDefaultSearchTerm] = useState(organizationName);
  const autoCompleteRef = useRef();
  const { openSnackbar } = useSnackBar();
  const onAutoCompleteError = useCallback(() => {
    openSnackbar({ type: 'error', messageId: 'organizationAutoCompleteErrorMsg', message: t('tkOrganizationAutoCompleteNewErrorMsg') });
  }, []);
  const lastSelectedOrganization = useRef(value);

  const { loading, doFetch, data } = useFetch(endPointsMapper.FETCH_USER_SEARCH, undefined, onAutoCompleteError);

  const { organizations: { values = [] } = {} } = data || {};

  const onInputChange = data => {
    data.length >= 3 && doFetch({ searchString: data, searchGroups: ['organization'], searchMaxResults: 7 });
  };

  useEffect(() => {
    setDefaultSearchTerm(organizationName);
    autoCompleteRef.current.setSearchTerm(organizationName);
  }, [organizationName]);

  const updatedOptions = useMemo(() => {
    if (!data) return [];
    const result = [];
    if (values.length) {
      const allMatches = values
        // Remove filter when services implement error messages
        .filter(result => result.id !== editingOrganizationId)
        .map(item => ({ ...item, label: item.name, labels: [{ name: item.name, isScGreyText: false }] }));
      result.push({ title: null, values: getSortedStringData(allMatches, 'label', true) });
    }
    return result;
  }, [data]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onOptionSelection = newValue => {
    if (newValue.id === selectedOption.id) return;
    setSelectedOption(newValue);
    setDefaultSearchTerm(newValue.label);
    if (!organizationId) {
      handleChange({
        value: {
          organizationId: newValue.id,
          organizationName: newValue.name,
          relationshipType: config.dataType,
          targetOrganizationType: 'TARGET',
        },
        fieldIndex,
        config,
        action: 'change',
        relationshipType,
      });
    } else {
      setIsModalOpen(true);
      setModalType('change');
    }
  };

  const onDeleteClick = () => {
    setIsModalOpen(true);
    setModalType('remove');
  };

  const onModalSubmit = modalSelectedOption => {
    if (modalType === CHANGE && modalSelectedOption) {
      setDefaultSearchTerm(selectedOption.name);
      handleChange({
        value: {
          organizationId: selectedOption.id,
          organizationName: selectedOption.name,
          relationshipType: config.dataType,
          targetOrganizationType: 'TARGET',
        },
        fieldIndex,
        config,
        action: 'change',
        relationshipType,
      });
      lastSelectedOrganization.current = selectedOption;
    } else if (modalType === REMOVE && modalSelectedOption) {
      handleChange({
        value,
        action: 'delete',
        config,
        fieldIndex,
        relationshipType,
      });
    } else {
      setSelectedOption(lastSelectedOrganization.current);
      setDefaultSearchTerm(value.organizationName);
      autoCompleteRef.current.setSearchTerm(value.organizationName);
    }
    setIsModalOpen(false);
  };

  const onOutSideClick = searchTerm => {
    if (searchTerm !== organizationName) {
      autoCompleteRef.current.setSearchTerm(organizationName);
    }
  };

  return (
    <div className='userdetails__autoCompleteOrgSearch'>
      <div className='userdetails__autoCompleteOrgSearch--field'>
        <AutoComplete
          ref={autoCompleteRef}
          placeholderText={t('tkOrganization')}
          searchIconPosition={organizationId ? null : 'right'}
          onInputChange={onInputChange}
          debounceTime={150}
          minChar={3}
          iconSize='md'
          isLoading={loading}
          options={updatedOptions}
          onOptionSelection={onOptionSelection}
          defaultSearchTerm={defaultSearchTerm}
          showClearIcon
          onClear={onDeleteClick}
          onOutSideClick={onOutSideClick}
        />
        {canDeleteField && (
          <Button size='sm' className='userdetails__removeField' onClick={onDeleteClick} actionType='primary' emphasis='minimal'>
            <Icon name='remove-circle' type='filled' style={{ color: 'rgb(217, 31, 17)' }} size='sm' />
          </Button>
        )}
      </div>
      {errorMessage && (
        <span className='error-message' data-testid='text-errorMessage'>
          {errorMessage}
        </span>
      )}
      {isModalOpen && <ParentOrgWarningModal modalType={modalType} closeModal={closeModal} isModalOpen={isModalOpen} onModalSubmit={onModalSubmit} />}
    </div>
  );
};

AutoCompleteOrgSearch.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.object,
  config: PropTypes.object,
  errorState: PropTypes.object,
  canDeleteField: PropTypes.bool,
  fieldIndex: PropTypes.number,
};

export default AutoCompleteOrgSearch;
