export default {
  ADD_APP_PREFERENCE: 'ADD_APP_PREFERENCE',
  EDIT_APP_PREFERENCE: 'EDIT_APP_PREFERENCE',
  SAVE_APP_PREFERENCES: 'SAVE_APP_PREFERENCES',
  ADD_SAVED_PREFERENCES: 'ADD_SAVED_PREFERENCES',
  CLEAR_APP_PREFERENCE: 'CLEAR_APP_PREFERENCE',
  TOGGLE_COLLAPSE : 'TOGGLE_COLLAPSE',
  TOGGLE_COLLAPSE_REPORT : 'TOGGLE_COLLAPSE_REPORT',
  TOGGLE_ZBP: 'TOGGLE_ZBP',
  FIRM_PREFERENCES_CHANGED: 'FIRM_PREFERENCES_CHANGED',
  FIRM_LOADED: 'FIRM_LOADED',
  TOGGLE_OPEN_ACCOUNT_ONLY: 'TOGGLE_OPEN_ACCOUNT_ONLY',
  TOGGLE_INST_SHARE_CLASSES_ONLY: 'TOGGLE_INST_SHARE_CLASSES_ONLY',
  MANAGERS_PREFERENCE_CHANGE: 'MANAGERS_PREFERENCE_CHANGE',
  ADD_PREFERENCES_ERROR: 'ADD_PREFERENCES_ERROR',
  FUND_CURRENCIES_PREFERENCE_CHANGE: 'FUND_CURRENCIES_PREFERENCE_CHANGE',
  ESG_FUND_PREFERENCE_CHANGE: 'ESG_FUND_PREFERENCE_CHANGE',
  FUND_TYPES_PREFERENCE_CHANGE: 'FUND_TYPES_PREFERENCE_CHANGE',
  TRADE_TEMPLATE_PREFERENCE_CHANGE: 'TRADE_TEMPLATE_PREFERENCE_CHANGE',
  FUND_TRACKER_PREFERENCE_CHANGE: 'FUND_TRACKER_PREFERENCE_CHANGE'
};
