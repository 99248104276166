import {
    StyleSheet,
    CssProperties,
    CssClassDefinitionsObject,
    getEmotionInstance,
} from '@gs-ux-uitoolkit-common/style';
import { IconSize, defaultIconProps, IconRequiredProps } from './props';
import { getIconCodePoint } from './util';
import { resolveIconFont } from './icon-to-fonts';
import { FontLoadingStore, getIconFontLoadingClass } from './font-loading-store';
import {
    iconFontFamilies,
    iconFontSizes,
    iconFontWeight,
    injectIconFonts,
} from './icon-font-faces';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './icon-theme-overrides';

export interface IconStyleSheetProps {
    theme: Theme;
    fontFamily: string;
    size: IconSize;
    type: 'filled' | 'outlined';
    spin: boolean;
    pulse: boolean;
    loading: boolean;
}

export interface IconCssClasses {
    root: string;
    content: string;
}

export type IconStyledClasses = CssClassDefinitionsObject<keyof IconCssClasses>;

export interface IconStyleOverridesParams {
    props: DeepReadonly<IconStyleSheetProps>;
    createDefaultStyledClasses: () => IconStyledClasses;
}

export const iconStyleSheet = new StyleSheet('icon', (props: IconStyleSheetProps) => {
    return createComponentClassDefinitions<IconStyleSheetProps, IconStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.icon
    );
});

function createDefaultStyledClasses({
    fontFamily,
    size,
    type,
    spin,
    pulse,
    loading,
}: IconStyleSheetProps): IconStyledClasses {
    return {
        root: {
            ...getBaseIconStyles({ fontFamily, size, type }),
            ...(loading && getLoadingStyles(size)),
        },
        content: {
            // 'inline-block' required for css 'transform' to work https://stackoverflow.com/questions/4919963/css3-transform-not-working
            display: 'inline-block',
            ...(spin && getSpinStyles()),
            ...(pulse && getPulseStyles()),
        },
    };
}

function getBaseIconStyles(props: {
    fontFamily: string;
    size?: IconSize;
    type: 'filled' | 'outlined';
}): CssProperties {
    injectIconFonts();

    const { fontFamily, size = defaultIconProps.size, type = defaultIconProps.type } = props;

    // Call `getFont()` to add the global icon loading class to the body element, which prevents the raw icon ligature text from showing.
    // The global icon loading class will be removed once the icon font is loaded and the real icon images are displayed.
    // This prevents the icon ligature text from "flashing" while the icon font loads (https://gitlab.aws.site.gs.com/fp/mwp-ui/gs-ux-uitoolkit/-/merge_requests/1300)
    FontLoadingStore.getFont(fontFamily);
    const fontVariationSettings = `'FILL' ${
        type === 'filled' ? 1 : 0
    }, 'wght' 300, 'GRAD' 0, 'opsz' 24`;
    const baseIconStyles = {
        fontStyle: 'normal',
        lineHeight: '1',
        letterSpacing: 'normal',
        textTransform: 'none',
        display: 'inline-block',
        whiteSpace: 'nowrap',
        wordWrap: 'normal',
        direction: 'ltr',
        fontFeatureSettings: '"liga"',
        WebkitFontFeatureSettings: '"liga"',
        MozFontFeatureSettings: '"liga"',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        fontFamily: fontFamily,
        fontWeight: iconFontWeight,
        fontSize: iconFontSizes[size],
        verticalAlign: 'bottom', // vertically centers the icon with the text
        fontVariationSettings: fontVariationSettings,
    } as const;
    const fontLoadingStyles = {
        visibility: 'hidden',
        width: iconFontSizes[size],
        height: iconFontSizes[size],
    } as const;

    return {
        ...baseIconStyles,
        [`.${getIconFontLoadingClass({ fontFamily })} &`]: {
            ...fontLoadingStyles,
        },
    };
}

/**
 * Returns a style object for the icon specified
 * To be used in pseudo elements (::before/::after)
 */
export const getIconStyles = (
    props: IconRequiredProps & {
        size?: IconSize;
    }
): CssProperties => {
    const { size, ...icon } = props;
    const fontFamily = resolveFontFamily(icon);

    return {
        content: `"${getIconCodePoint(icon)}"`,
        ...getBaseIconStyles({ fontFamily, size, type: icon.type }),
    };
};

/**
 * Resolves the font family for the provided icon props.
 *
 * The font family passed to the style sheet as an optimization (passing 'name' would not allow the stylesheet to memoize).
 */
export function resolveFontFamily(props: IconRequiredProps): string {
    const resolvedFont = resolveIconFont(props);
    return resolvedFont ? iconFontFamilies[resolvedFont] : '';
}

/**
 * Returns a style object that applies a spin animation.
 */
export function getSpinStyles(): CssProperties {
    const spin = getEmotionInstance().keyframes`
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    `;
    return {
        animation: `${spin} 2s infinite linear`,
        display: 'inline-block', // required for css 'transform' to work https://stackoverflow.com/questions/4919963/css3-transform-not-working
    };
}

/**
 * Returns a style object that applies a pulse animation.
 */
export function getPulseStyles(): CssProperties {
    const pulse = getEmotionInstance().keyframes`
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    `;
    return {
        animation: `${pulse} 1s infinite steps(8)`,
        display: 'inline-block', // required for css 'transform' to work https://stackoverflow.com/questions/4919963/css3-transform-not-working
    };
}

/**
 * Returns a style object used for when the icon is loading.
 */
export function getLoadingStyles(size: IconSize): CssProperties {
    return {
        width: iconFontSizes[size],
        height: iconFontSizes[size],
        visibility: 'hidden',
    };
}
