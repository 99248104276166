import React, {useEffect, useState} from 'react';
import DOMPurify from 'dompurify';
import cn from 'classnames';
import PropTypes from 'prop-types';
import translator from '../../../services/translator';
import {Conditional} from '../../core/Conditional';
import Link from '../../core/Link';
import {EXTERNAL_LINK_SKIP_ATTRIBUTE} from '../../../constants/pageConstants';
import {getAppRoute} from '../../../utils/commonUtils';
import Constants from '../../../constants/appConstants';
import {FAQ_TOPIC_CLICK_ANALYTICS_EVENT, READ_MORE_LINK_EVENT} from './analytics';
import './index.scss';

DOMPurify.setConfig({ADD_ATTR: ['target']});
const {translate: t} = translator;

const Collapsible = ({id, title, children, className, onExpandFaqQuestionAnswer}) => {
  const [open, setOpen] = useState(false);
  const togglePanel = () => {
    // Dispatch analytics call
    onExpandFaqQuestionAnswer(title);

    // Expand/collapse the answer section
    setOpen(!open);
  };

  return (
    <div className={className}>
      <div className="wrapper">
        <Link
          customClass={cn({'expand': open}, {'collapse': !open})}
          handleClick={togglePanel}
          extraProps={{'analytics-attribute': READ_MORE_LINK_EVENT, id}}
        />
        <Link
          handleClick={togglePanel}
          extraProps={{'analytics-attribute': READ_MORE_LINK_EVENT}}
          customClass={cn('header', {'header--selected': open})}
          label={title}
        />
      </div>
      <Conditional condition={open}>
        <div className='content'>
          {children}
        </div>
      </Conditional>
    </div>
  );
};

Collapsible.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.object,
  className: PropTypes.string,
  onExpandFaqQuestionAnswer: PropTypes.func
};

const FaqDetails = ({faqContents, children, onClickFaqTopic}) => {
  const [isScrollDone, setIsScrollDone] = useState(false);
  const fieldRefs = [];

  const scrollToItem = (element, offset) => {
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition - offset;
    window.scroll(0, offsetPosition);
  };

  const isChildItem = (itemId) => {
    return faqContents.some(({section, items}) => {
      const sectionId = section.replace(/\s/g, "");
      return items.some((item, index) => {
        const currentItemId = `${sectionId}${index + 1}`;
        return (itemId === currentItemId);
      })
    });
  };

  useEffect(() => {
    if (!isScrollDone) {
      // Scroll to topic (if hash is available in the URL)
      const hashFromUrl = window.location.href.split('#')[1];
      const topicItem = hashFromUrl && document.getElementById(hashFromUrl);
      if (topicItem) {
        // Scroll to the specific item
        scrollToItem(topicItem, 135);
        // If it's a child item (question & answer), expand it
        if (isChildItem(hashFromUrl)) {
          setTimeout(() => {
            topicItem.click();
          }, 1);
        }
        setIsScrollDone(true);
      }
    }
  });

  const onFaqNavItemSelect = (item) => {
    // Scroll to selected topic
    const {section} = item.currentTarget.dataset;
    const [selectedItem] = fieldRefs.filter(item => (item.props.children.includes(section)));
    scrollToItem(selectedItem.ref.current, 100);

    // Dispatch analytics call
    onClickFaqTopic(section);
  };

  const recursiveInjectProps = (children) => {
    let childPropsChildren;

    return React.Children.map(children, (child) => {
      if (child && child.props && child.props.children) {
        childPropsChildren = recursiveInjectProps(child.props.children);
      } else {
        childPropsChildren = null;
      }
      if (child.type === 'div' && child.props && child.props.className === 'faq__item-header') {
        const ref = React.createRef();
        fieldRefs.push({ref, props: child.props});
        return React.cloneElement(child, {ref}, childPropsChildren);
      } else if (child.type) {
        return React.cloneElement(child, {}, childPropsChildren);
      }
      return child;
    });
  };

  return (
    <React.Fragment>
      <div className="faq__nav-section">
        {
          faqContents.map(({section}, index) => (
            <Conditional condition={section !== 'Glossary'}>
              <Link
                label={`${index + 1}. ${section}`}
                handleClick={onFaqNavItemSelect}
                extraProps={{'data-section': section, 'analytics-attribute': FAQ_TOPIC_CLICK_ANALYTICS_EVENT}}
              />
            </Conditional>
          ))
        }
      </div>
      <div className='faq__items-wrapper'>
        {
          recursiveInjectProps(children)
        }
      </div>
    </React.Fragment>
  );
};

FaqDetails.propTypes = {
  faqContents: PropTypes.object,
  children: PropTypes.object,
  onClickFaqTopic: PropTypes.func
};

const FAQ = ({faqContents = [], onPageLoad, onClickFaqTopic, onExpandFaqQuestionAnswer, faqPageLoadEventData}) => {
  useEffect(() => {
    // Dispatch analytics call
    onPageLoad(faqPageLoadEventData);
  }, []);

  const onScrollToTopClick = () => {
    window.scrollTo({
      top: 0,
      left: 100,
      behavior: 'smooth'
    });
  };

  return (
    <div className="faq main-container__content">
      <div className="faq__top-section">
        <span className="heading">{t('tkFrequentlyAskedQuestions')}</span>
        <div className="faq__info">
          <div className="subheading">
            {t('tkBrowseFAQsByTopics')}
            <Link
              label={`${t('tkGlossary')}.`}
              href={getAppRoute(Constants.GLOSSARY_ROUTE)}
              extraProps={{[EXTERNAL_LINK_SKIP_ATTRIBUTE]: true}}
            />
          </div>
        </div>
      </div>
      <div className="faq__details">
        <FaqDetails
          faqContents={faqContents}
          onClickFaqTopic={onClickFaqTopic}
        >
          {
            faqContents.map(({id, section, items}, index) => {
              const additionalProps = id ? {id} : {};
              const sectionId = section.replace(/\s/g, "");
              return (
                <Conditional condition={section !== 'Glossary'}>
                  <div className='faq__item-wrapper'>
                    <div
                      {...additionalProps}
                      className="faq__item-header">
                      {`${index + 1}. ${section}`}
                    </div>
                    {
                      items.map(({question, answer}, index) => {
                        const itemId = `${sectionId}${index + 1}`;
                        return (
                          <Collapsible
                            id={itemId}
                            title={question}
                            onExpandFaqQuestionAnswer={onExpandFaqQuestionAnswer}
                            className='faq__question-answer'>
                            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(answer || '--')}}/>
                          </Collapsible>
                        )
                      })
                    }
                    <div className='faq__item-back'>
                      <Link
                        handleClick={onScrollToTopClick}
                      />
                      <div className="title" onClick={onScrollToTopClick}>
                        {t('tkBackToTop')}
                      </div>
                    </div>
                  </div>
                </Conditional>
              )
            })
          }
        </FaqDetails>
      </div>
    </div>
  );
};

FAQ.propTypes = {
  faqContents: PropTypes.array,
  onPageLoad: PropTypes.func,
  faqPageLoadEventData: PropTypes.object,
  onClickFaqTopic: PropTypes.func,
  onExpandFaqQuestionAnswer: PropTypes.func
};

export default FAQ;
