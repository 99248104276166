import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const FilterButton = ({ onClick, visibilityState }) => {
  return (
    <button
      className={`transparent-button icon-search-large ${visibilityState ? 'active' : ''}`}
      onClick={onClick}
      aria-label='filter button'
    />
  );
};
FilterButton.propTypes = {
  onClick: PropTypes.func,
  visibilityState: PropTypes.bool,
};

export default FilterButton;
