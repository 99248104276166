export class Mixin {
    static mix(...mixins: any[]) {
        for (const mixin of mixins) {
            this.copyProperties(Mix, mixin);
            this.copyProperties(Mix.prototype, mixin.prototype);
        }

        return Mix;
    }

    static extendAndMix(superclass: any, ...mixins: any[]) {
        for (const mixin of mixins) {
            this.copyProperties(superclass, mixin);
            this.copyProperties(superclass.prototype, mixin.prototype);
        }

        return superclass;
    }

    static copyProperties(target: any, ...sources: any[]) {
        for (const source of sources) {
            if (source) {
                for (const key of Reflect.ownKeys(source)) {
                    if (!(['constructor', 'prototype', 'name'] as any).includes(key as any)) {
                        const desc = Object.getOwnPropertyDescriptor(source, key);
                        Object.defineProperty(target, key, desc as any);
                    }
                }
            }
        }
    }
}
export class Mix {}
