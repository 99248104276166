import { StyleSheet, CssClasses } from '@gs-ux-uitoolkit-common/style';

export const visuallyHiddenStyles = new StyleSheet('visually-hidden', () => {
    return {
        root: {
            '[data-gs-uitk-component="popover-target"]': {
                display: 'inline',
            },
            '[data-cy="gs-uitk-popover__content"]': {
                padding: '8px',
            },
        },
        hidden: {
            position: 'absolute',
            width: '1px',
            height: '1px',
            padding: '0',
            margin: '-1px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            border: '0',
            clip: 'rect(0,0,0,0)!important',
        },
    };
});

export type VisuallyHiddenCssClasses = CssClasses<typeof visuallyHiddenStyles>;
