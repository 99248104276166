import { Orientation } from '@gs-ux-uitoolkit-common/design-system';
import { ButtonSize, ButtonCommonProps } from '../button/button-props';
import { ButtonGroupCssClasses } from './button-group-style-sheet';

export interface ButtonGroupCommonProps {
    /**
     * Orientation of the ButtonGroup.
     */
    orientation?: ButtonGroupOrientation;

    /**
     * Sets the `size` of the buttons nested in the ButtonGroup.
     */
    size?: ButtonSize;

    /**
     * Sets the `name` of the buttons nested in the ButtonGroup.
     */
    name?: ButtonCommonProps['name'];

    /**
     * Additional classes to add on the root element.
     */
    className?: string;

    /**
     * Style classes to override.
     */
    classes?: ButtonGroupOverrideClasses;
}

export const defaultButtonGroupProps: DefaultButtonGroupProps = {
    orientation: 'horizontal',
};

export type DefaultButtonGroupProps = Required<Pick<ButtonGroupCommonProps, 'orientation'>>;

export type ButtonGroupOverrideClasses = Partial<Pick<ButtonGroupCssClasses, 'root'>>;

export type ButtonGroupOrientation = Extract<Orientation, 'horizontal' | 'vertical'>;

export const buttonGroupOrientations: ButtonGroupOrientation[] = ['horizontal', 'vertical'];
