import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Constants from '../../constants/appConstants';
import WithPageError from '../../containers/WithPageError';
import {
  getReportsTabConfig, getReportsNavLinks, isUserEntitledToMyReportsSelector
} from '../../selectors/pages/reports';
import InjectRoutes from '../../routes';
import Stepper from '../../components/core/Stepper';
import PageHeader from '../../components/app/PageHeader';
import translator from '../../services/translator';
import {getAppRoute} from '../../utils/commonUtils';
import {pageIdSelector} from '../../selectors/pages';
import {Conditional} from '../../components/core/Conditional';
import {allReportsGridDataSelector, isReportPageLoadingSelector} from '../../selectors/pages/reports/allReports';
import {selectedSavedReportDataSelector} from '../../selectors/app/reports';
import {addAppContext} from '../../actions/app';
import {ThreeSquaresLoader} from '../../components/core/Loaders';
import {myReportsCountSelector} from '../../selectors/pages/reports/myReports';
import {labelSelector} from '../../selectors/app';
import './index.scss';

const {translate: t} = translator;

const getPageHeaderDetails = (selectedSavedReportData, pageHeaderData, pageId) => {
  if (['AllReports', 'MyReports'].includes(pageId)) {
    return {
      reportTitle: t('tkNavMenuReports'),
      reportTitleDesc: t('tkReportsSubHeader'),
      hideBackToReportsLink: true
    };
  }
  if (selectedSavedReportData && pageId === 'SavedReport') {
    return {
      reportTitle: selectedSavedReportData.reportName,
      reportTitleDesc: selectedSavedReportData.reportDescription,
      hideBackToReportsLink: false
    };
  } else {
    const {reportTitleKey = '', reportTitleDescKey = ''} = pageHeaderData.find(({legacyReportId, newReportId}) => {
      return [legacyReportId, newReportId].includes(pageId);
    }) || {};
    return {
      reportTitle: t(reportTitleKey),
      reportTitleDesc: t(reportTitleDescKey),
      hideBackToReportsLink: false
    };
  }
};

const Reports = ({
  stepperConfig, reportsNavLinks, subRoutes, pageId, history, selectedSavedReportData,
  isReportPageLoading, pageHeaderData = [], dispatchSavedReport, isUserEntitledToMyReports,
  myReportsCount
}) => {
  const {
    reportTitle, reportTitleDesc, hideBackToReportsLink
  } = getPageHeaderDetails(selectedSavedReportData, pageHeaderData, pageId);
  const backToReportLink = pageId==='SavedReport' ? Constants.REPORT_MY_REPORTS_TAB_ROUTE : Constants.ALL_REPORTS_TAB_ROUTE;
  const onClickBackToReportsLink = () => {
    dispatchSavedReport({selectedSavedReportData: null});
    history.push(getAppRoute(backToReportLink));
  };
  const isReportTitlePresent = !!reportTitle;
  const backToAllReportsLabel = `< ${t('tkBackToAllReports')}`;
  const showTabs = isUserEntitledToMyReports &&
    (hideBackToReportsLink && !isReportPageLoading && !isEmpty(stepperConfig));

  return (
    <div className="js-main-content main-container__content reports">
      {
        isReportPageLoading &&
          <ThreeSquaresLoader />
      }
      {
        !isReportPageLoading &&
          <div className="reports__header">
            <Conditional condition={!hideBackToReportsLink && isReportTitlePresent}>
              <span
                onClick={onClickBackToReportsLink}
                className="reports__back">
                {backToAllReportsLabel}
              </span>
            </Conditional>
            <Conditional condition={isReportTitlePresent}>
              <PageHeader
                title={reportTitle}
                description={reportTitleDesc}
              />
            </Conditional>
          </div>
      }
      <Conditional condition={showTabs}>
        <div className="reports__tabs">
          <Stepper
            stepperConfig={stepperConfig}
            currentPageId={pageId}
            itemsCount={myReportsCount}
          />
        </div>
      </Conditional>
      <div className="reports__container">
        <WithPageError>
          <InjectRoutes
            routes={subRoutes}
            navLinks={reportsNavLinks}
            redirectRoutes={[getAppRoute(Constants.REPORTS_ROUTE)]}
          />
        </WithPageError>
      </div>
    </div>
  );
};

Reports.propTypes = {
  pageId: PropTypes.string,
  pageHeaderData: PropTypes.string,
  reportsNavLinks: PropTypes.array,
  subRoutes: PropTypes.array,
  isReportPageLoading: PropTypes.bool,
  stepperConfig: PropTypes.array,
  selectedSavedReportData: PropTypes.object,
  dispatchSavedReport: PropTypes.object,
  history: PropTypes.object,
  isUserEntitledToMyReports: PropTypes.bool,
  myReportsCount: PropTypes.string
};

const mapStateToProps = (state) => ({
  pageId: pageIdSelector(state),
  pageHeaderData: allReportsGridDataSelector(state),
  reportsNavLinks: getReportsNavLinks(state),
  isReportPageLoading: isReportPageLoadingSelector(state),
  stepperConfig: getReportsTabConfig(state),
  isUserEntitledToMyReports: isUserEntitledToMyReportsSelector(state),
  selectedSavedReportData: selectedSavedReportDataSelector(state),
  myReportsCount: myReportsCountSelector(state),
  labels: labelSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSavedReport: data => dispatch(addAppContext(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reports));
