import { FC } from 'react';
import { Menu, MenuSize } from '@gs-ux-uitoolkit-react/menu';
import { DropdownMenuProps } from './dropdown-props';
import { DropdownContext } from './dropdown-context';

/**
 * DropdownMenu is a sub-component of the Dropdown, shows a menu when triggered.
 *
 * It functions just like the Menu, but `visible` is set on the Dropdown instead of directly on the DropdownMenu.
 */
export const DropdownMenu: FC<DropdownMenuProps> = props => {
    return (
        <DropdownContext.Consumer>
            {({ size, menuVisible, menuTargetEl, menuOnBlur }) => (
                <>
                    {menuTargetEl && (
                        <Menu
                            {...props}
                            size={getSize({
                                propsSize: props.size,
                                contextSize: size === 'xl' ? 'lg' : size,
                            })}
                            data-size={getSize({
                                propsSize: props.size,
                                contextSize: size === 'xl' ? 'lg' : size,
                            })}
                            visible={menuVisible}
                            target={menuTargetEl}
                            onBlur={getOnBlur({
                                propsCallback: props.onBlur,
                                contextCallback: menuOnBlur,
                            })}
                        >
                            {props.children}
                        </Menu>
                    )}
                </>
            )}
        </DropdownContext.Consumer>
    );
};

function getSize(args: { propsSize?: MenuSize; contextSize?: MenuSize }): MenuSize | undefined {
    const { propsSize, contextSize } = args;
    // use context size when the direct props dont have a size
    if (contextSize != null && propsSize == null) {
        return contextSize;
    }
    return propsSize;
}

function getOnBlur(args: {
    propsCallback?: DropdownMenuProps['onBlur'];
    contextCallback?: DropdownMenuProps['onBlur'];
}): DropdownMenuProps['onBlur'] {
    const { propsCallback, contextCallback } = args;

    return event => {
        contextCallback && contextCallback(event);
        propsCallback && propsCallback(event);
    };
}
