import React, {useState} from 'react';
import PropTypes from "prop-types";
import moment from 'moment/moment';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import translator from '../../../../../services/translator';
import {useChartContext} from '../../../context';
import {fundIdentifierPreference} from "../../../../../selectors/preferences";
import {
  getFundDisplay,
  removeFundsOrBenchmarkSeriesToChart, diffArrays,
  setMetricBenchmarksToState, updateChartSeries,
  cleanupGridData
} from '../../../utils';
import {useAsync} from "../../../reducers/Hooks";
import {benchmarkMetricsOptionsSelector} from "../../../../../selectors/pages/fundTracker";
import Link from "../../../../../components/core/Link";
import {Conditional} from "../../../../../components/core/Conditional";
import './index.scss';
import {ChartActionTypes} from '../../../actions';

const {translate: t} = translator;

const SubHeader = (props) => {
  const {state: chartState, dispatch: chartDispatch} = useChartContext();
  const {
    shareclass = {},
    allShareClassData = [],
    benchmarks,
    fieldForQuery,
    primaryShareClassId,
    selection,
    asOfDate: finalDate,
    metrics: selectedMetrics,
    shareClassPerformanceData
  } = chartState;
  const [chartLoading, setChartLoading] = useState(false);
  const isShareClassInfoLoading = (!shareclass || !(shareclass.shareclass));
  const {
    fundIdentifier, benchmarkMetricsOptions, handleBackClick,
    isShowBackLink = true, saveUserPreferredFunds
  } = props;
  const {run} = useAsync(chartDispatch);
  const [primaryMetricItem] = selectedMetrics;

  // ***************************************************************************************
  //            To remove Metrics and Funds/Benchmark series from chart
  // ***************************************************************************************
  const removeMetricsAndBenchmarksFromSeries = (selectedFundOrBenchmarks, metricsItemToRemove) => {
    const fundOrBenchmarksToRemove = diffArrays(
      benchmarks,
      selectedFundOrBenchmarks,
      (v1, v2) => v1.value === v2.value
    );
    return {
      fundOrBenchmarksToRemove,
      updatedChartOptions: updateChartSeries(
        metricsItemToRemove,
        fundOrBenchmarksToRemove,
        chartState,
        primaryMetricItem.value,
        benchmarkMetricsOptions
      )
    }
  };

  // ***************************************************************************************
  //            To process added Funds/Benchmark data added from modal
  // ***************************************************************************************
  const processBenchmarkItems = (shareClassIdToRemove) => {
    setChartLoading(true);
    const selectedFundOrBenchmarks = benchmarks.filter(benchmark => benchmark.value !== shareClassIdToRemove);
    // Get the metric item to be removed from series
    const metricsItemToRemove = undefined;
    const selectedFundOrBenchmarksData = selectedFundOrBenchmarks;
    /* Remove the series from Chart for the benchmarks that were removed from Modal.
       We also need to remove if there were any compare metrics item that were set earlier */
    const {
      fundOrBenchmarksToRemove,
      updatedChartOptions
    } = removeMetricsAndBenchmarksFromSeries(selectedFundOrBenchmarksData, metricsItemToRemove);

    // Set the updated Share Class ids
    const selectedCompareShareClassId = selectedFundOrBenchmarksData.map(({value}) => value);
    const lastShareclassIds = [primaryShareClassId, ...selectedCompareShareClassId];
    saveUserPreferredFunds(lastShareclassIds, 'lastShareclassIds');

    // Get the newly added benchmarks to the chart series
    const completeData = allShareClassData.filter(data => data.id !== shareClassIdToRemove )
    removeFundsOrBenchmarkSeriesToChart(
      completeData,
      run,
      fieldForQuery,
      benchmarkMetricsOptions,
      selectedFundOrBenchmarksData,
      chartDispatch,
      updatedChartOptions,
      chartState,
      true,
      "",
      [primaryMetricItem]
    );

    const results = diffArrays(shareClassPerformanceData, fundOrBenchmarksToRemove, (v1, v2) => {
      return v1.id === v2.value;
    });
    chartDispatch({
      type: ChartActionTypes.setShareClassPerformanceData,
      data: {results, finalDate}
    });

    // Add/remove data from bottom grid
    cleanupGridData(
      selectedFundOrBenchmarksData,
      fundOrBenchmarksToRemove,
      allShareClassData,
      primaryShareClassId,
      chartDispatch
    );

    // Set the selected benchmark items to context
    const updatedData = {
      benchmarks: selectedFundOrBenchmarksData,
      selection: (!!selectedFundOrBenchmarksData.length && 'benchmarks')
    };
    setMetricBenchmarksToState(chartDispatch, updatedData);
    setTimeout(() => setChartLoading(false), 1000);
  };

  const onActionClick = (shareClassIdToRemove) => {
    if(chartLoading) {
      console.log('chart loading =', chartLoading);
      return;
    }
    processBenchmarkItems(shareClassIdToRemove);
  };

  const fundDisplay = getFundDisplay(shareclass, fundIdentifier);
  const fundDisplayOnCompare = allShareClassData.map(item => getFundDisplay(item, fundIdentifier));

  const {
    nav: {
      value = '--',
      navChange = '--',
      asOfDate = '--'
    } = {},
    shareclass: {
      fundDetails: {
        navType
      } = {},
      longName,
      shareClassDetails: {
        isInstitutional
      } = {}
    } = {}
  } = shareclass;

  const onCompareMode = allShareClassData.length > 1 && selection !== 'metrics';
  const subHeaderName = !onCompareMode ? (fundDisplay && `${longName} (${fundDisplay})` || longName) : t('tkCompareFunds');
  let navChangeValue = navChange;
  if(navChange>=0) navChangeValue = `+${navChange}`;
  return (
    <Conditional condition={!isShareClassInfoLoading}>
      <>
        <Conditional condition={isShowBackLink}>
          <div className="back">
            <Link
              customClass="back"
              handleClick={handleBackClick}
              label={`< ${t('tkBack')}`}
            />
          </div>
        </Conditional>
        <div className="sub-header">
          <div className="sub-header__name">{subHeaderName}</div>
          <Conditional condition={!onCompareMode}>
            <div className="sub-header__classdetails">
              <div className="section">
                <span>{t('tkClass')}</span>
                <span className="classtype">{isInstitutional ? t('tkInstitutional') : t('tkNonInstitutional')}</span>
              </div>
              <div className="section">
                <span>{t('tkNavColon')}</span>
                <span className="value">{value}</span>
              </div>
              <div className="section">
                <span className="classtype">{(navType !== 'CNAV' && navType !== 'LVNAV' && navType !== 'PDCNAV') ? `(${navChangeValue})` : navChangeValue}</span>
              </div>
              <div>
                <span className="classtype datetime">
                  {asOfDate !== '--' ? moment(asOfDate).format('DD MMMM') : asOfDate}
                </span>
              </div>
            </div>
            <div className="sub-header__shareclasses">
              {
                allShareClassData.map((config, index) => (
                  <div className="shareclass">
                    <span className="shareclass__index">{index + 1}) </span>
                    <Conditional condition={index === 0}>
                      <span> {fundDisplayOnCompare[index] && `${config.shareclass.longName} (${fundDisplayOnCompare[index]})` || config.shareclass.longName}</span>
                      <span className="shareclass__removable">
                        {fundDisplayOnCompare[index] && `${config.shareclass.longName} (${fundDisplayOnCompare[index]})` || config.shareclass.longName}
                        <span onClick={() => onActionClick(config.shareclass.id)}>
                          <span className={chartLoading ? "shareclass__remove-icon-disable" : "shareclass__remove-icon"}/>
                        </span>
                      </span>
                    </Conditional>
                  </div>
                ))
              }
            </div>
          </Conditional>
        </div>
      </>
    </Conditional>
  );
};

const mapStateToProps = (state) => ({
  fundIdentifier: fundIdentifierPreference(state),
  benchmarkMetricsOptions: benchmarkMetricsOptionsSelector(state)
});

SubHeader.propTypes = {
  fundIdentifier: PropTypes.string,
  handleBackClick: PropTypes.func,
  benchmarkMetricsOptions: PropTypes.object,
  isShowBackLink: PropTypes.bool,
  saveUserPreferredFunds: PropTypes.func
};

export default withRouter(connect(mapStateToProps, null)(SubHeader));
