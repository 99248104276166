import {
    colorNames,
    colors,
    ColorValue,
    Elevation,
    elevationVariants,
    TypographyVariant,
    typographyVariants,
    getFontFaces,
} from '@gs-ux-uitoolkit-common/design-system';
import { rgba } from 'polished';
import { Theme, ThemeElevation, ThemeTypographyVariant } from '../theme';
import { changeContrast, darken, lighten } from '../utils/color';
import { getColorShades, getSurfaceShades } from '../utils/interaction';

const color: Theme['color'] = colorNames.reduce(
    (obj, colorName) => {
        obj[colorName] = {
            bold: colors[`${colorName}060` as ColorValue],
            subtle: colors[`${colorName}020` as ColorValue],
        };
        return obj;
    },
    {} as Theme['color']
);

// Overrides for a few color hues to adjust the visual value
color.yellow.bold = colors.yellow030;
color.yellow.subtle = colors.yellow010;

const status: Theme['status'] = {
    none: color.gray,
    information: color.blue,
    success: color.green,
    warning: color.yellow,
    warningAlt: {
        bold: colors.orange050,
        subtle: colors.orange020,
    },
    error: color.red,
    loading: color.blue,
};

const text: Theme['text'] = {
    primary: colors.gray090,
    secondary: colors.gray070,
    tertiary: colors.gray040,
    link: colors.blue060,
    destructive: colors.red060,
    reversed: colors.white,
};

const surface: Theme['surface'] = {
    primary: colors.white,
    secondary: colors.gray010,
    tertiary: colors.gray020,
    moderate: colors.gray030,
    bold: colors.gray040,
    strong: colors.gray060,
    contrast: colors.gray110,
    none: rgba(colors.white, 0.0),
};

const colorScheme: Theme['colorScheme'] = {
    primary: colors.blue060,
    secondary: colors.gray020,
    info: colors.blue100,
    header: colors.blue090,
    overlay: rgba(colors.gray090, 0.8),
};

const border: Theme['border'] = {
    minimal: colors.gray020,
    subtle: colors.gray030,
    moderate: colors.gray040,
    strong: colors.gray050,
    bold: colors.gray060,
    contrast: colors.gray090,
    reversed: colors.white,
    input: colors.gray050,
};

const state: Theme['state'] = {
    disabledOpacity: 0.3,
};

const elevation = (Object.keys(elevationVariants) as (keyof typeof elevationVariants)[])
    .map(elevation => ({
        [elevation]: {
            shadow: elevationVariants[elevation].boxShadow,
            background: surface.primary,
        },
    }))
    .reduce(
        (target, cur) => ({
            ...target,
            ...cur,
        }),
        {}
    ) as { [key in Elevation]: ThemeElevation };

const dataviz: Theme['dataviz'] = {
    sequence01: colors.blue070,
    sequence02: colors.orange050,
    sequence03: colors.teal050,
    sequence04: colors.yellow040,
    sequence05: colors.purple050,
    sequence06: colors.ultramarine090,
    sequence07: colors.lime050,
    sequence08: colors.pink060,
    sequence09: colors.aqua040,
    sequence10: colors.green060,
};

const typography = Object.entries(typographyVariants).reduce(
    (obj, [name, value]) => {
        obj[name as ThemeTypographyVariant] = value;
        return obj;
    },
    {} as { [key in ThemeTypographyVariant]: TypographyVariant }
);

export const lightTheme: Theme = {
    colorMode: 'light',
    color,
    status,
    colorScheme,
    text,
    surface,
    border,
    state,
    dataviz,
    elevation,
    typography,
    fonts: getFontFaces(),
    getColorInteractionShades: (...args) => getColorShades(lightTheme, 'light', ...args),
    getSurfaceInteractionShades: (...args) => getSurfaceShades(lightTheme, 'light', ...args),
    increaseContrast: (...args) => changeContrast(...args, darken),
    decreaseContrast: (...args) => changeContrast(...args, lighten),
};
