import { CSSProperties, HTMLAttributes, FC, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';

import {
    LoadingIconProps as CommonLoadingIconProps,
    loadingIconDefaultProps,
    loadingIconDefaultLabelText,
    loadingIconStyleSheet,
    getLoadingIconClasses,
    getLoadingIconPointClasses,
    loadingIconColorNames,
    loadingIconSizes,
    loadingIconShapes,
    getLoadingIconLabelClasses,
    getLoadingIconPulseClasses,
    getLoadingIconCircleClasses,
    getCircleIconSvgAttributes,
    getSquareIconSvgAttributes,
} from '@gs-ux-uitoolkit-common/loading';

import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { isIE } from '@gs-ux-uitoolkit-react/shared';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { componentAnalytics } from './analytics-tracking';

export interface LoadingIconProps
    extends CommonLoadingIconProps<CSSProperties>,
        Omit<HTMLAttributes<HTMLElement>, 'color'> {}

/**
 * A LoadingIcon is used as part of a loading overlay.
 */
export const LoadingIcon: FC<LoadingIconProps> = memo(props => {
    const {
        className,
        classes: overrideClasses,
        color = loadingIconDefaultProps.color,
        label = loadingIconDefaultProps.label,
        size = loadingIconDefaultProps.size,
        shape = loadingIconDefaultProps.shape,
        ...attributes
    } = props;

    const theme = useTheme();
    const cssClasses = useStyleSheet(loadingIconStyleSheet, { color, size, shape, theme });
    const iconClasses = getLoadingIconClasses({
        cssClasses,
        className,
        overrideClasses,
    });
    const labelClasses = getLoadingIconLabelClasses({ cssClasses, overrideClasses });
    const labelText = typeof label === 'boolean' ? loadingIconDefaultLabelText : label;

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'loading' });
    }, []); // Only run once

    return (
        <div
            data-gs-uitk-component="loading-icon"
            className={iconClasses}
            aria-live="polite"
            {...attributes}
        >
            {isIE && (
                <Icon
                    aria-label={label ? '' : 'loading'}
                    aria-hidden={label ? 'true' : 'false'}
                    name="loading"
                    type="filled"
                    pulse
                    className={getLoadingIconPulseClasses({
                        cssClasses,
                        overrideClasses,
                    })}
                />
            )}
            {!isIE && shape === 'square' && (
                <svg
                    aria-label={label ? '' : 'loading'}
                    aria-hidden={label ? 'true' : 'false'}
                    data-cy="gs-uitk-loading-icon__svg"
                    width={getSquareIconSvgAttributes({ size }).svgSize}
                    height={getSquareIconSvgAttributes({ size }).svgSize}
                    viewBox={getSquareIconSvgAttributes({ size }).viewBox}
                >
                    <polygon
                        className={getLoadingIconPointClasses({
                            cssClasses,
                            overrideClasses,
                            point: 'center',
                        })}
                        points="50,0 100,50 50,100 0,50"
                    />
                    <polygon
                        className={getLoadingIconPointClasses({
                            cssClasses,
                            overrideClasses,
                            point: 'corner-top-left',
                        })}
                        points="0,0 50,0 0,50"
                    />
                    <polygon
                        className={getLoadingIconPointClasses({
                            cssClasses,
                            overrideClasses,
                            point: 'corner-top-right',
                        })}
                        points="50,0 100,0 100,50"
                    />
                    <polygon
                        className={getLoadingIconPointClasses({
                            cssClasses,
                            overrideClasses,
                            point: 'corner-bottom-right',
                        })}
                        points="50,100 100,100 100,50"
                    />
                    <polygon
                        className={getLoadingIconPointClasses({
                            cssClasses,
                            overrideClasses,
                            point: 'corner-bottom-left',
                        })}
                        points="0,50 0,100 50,100"
                    />
                </svg>
            )}

            {!isIE && shape === 'circle' && (
                <svg
                    aria-label={label ? '' : 'loading'}
                    aria-hidden={label ? 'true' : 'false'}
                    data-cy="gs-uitk-loading-icon__svg"
                    width={getCircleIconSvgAttributes({ size }).svgSize}
                    height={getCircleIconSvgAttributes({ size }).svgSize}
                    x="0px"
                    y="0px"
                    viewBox={getCircleIconSvgAttributes({ size }).viewBox}
                >
                    <circle
                        className={getLoadingIconCircleClasses({
                            cssClasses,
                            overrideClasses,
                        })}
                    />
                </svg>
            )}
            {label ? (
                <span data-cy="gs-uitk-loading-icon__label" className={labelClasses}>
                    {labelText}
                </span>
            ) : (
                ''
            )}
        </div>
    );
});
LoadingIcon.displayName = 'LoadingIcon';

LoadingIcon.propTypes = {
    color: PropTypes.oneOf(loadingIconColorNames),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    size: PropTypes.oneOf(loadingIconSizes),
    shape: PropTypes.oneOf(loadingIconShapes),
};
