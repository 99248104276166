import { IconName } from './design-system-icons/full-set';
import { v16ToV17IconMapping } from './design-system-icons/v16-to-v17-outlined-icon-mapping';

/**
 * Resolves v16 icon names to v17 Icons. This is required for certain
 * Material Icons in the "outlined" set that were changed to have a
 * filled appearance in a more recent version. We are simply mapping
 * these cases to an alternate version that is actually outlined so users
 * specifying a type of "outlined" don't see a "filled" icon.
 *
 * @example
 * // returns 'account'
 * resolveIconFromV16IconName('account');
 *
 * @example
 * // returns 'help-outline'
 * resolveIconFromV16IconName('help');
 *
 * @example
 * // returns 'my-custom-icon' since it is not a GS Design System Icon
 * resolveIconFromV16IconName('my-custom-icon');
 *
 * @param {string} iconName - Name of v16 outlined icon to resolve to a GS Design System Icon
 * @returns { string} Name of outlined icon in v17
 */
export function resolveIconFromV16IconName(iconName: IconName): IconName {
    return v16ToV17IconMapping[iconName] || iconName;
}
