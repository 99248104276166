import { logger } from '@gs-ux-uitoolkit-common/datacore';
import { KeyboardShortcut, NumericShortcutOperation } from './keyboard-shortcut';

class ShortcutHelper {
    public computeKeyboardShortcut(
        editorValue: string,
        keyboardShortcut: KeyboardShortcut
    ): number {
        const valueAsNumber = parseFloat(editorValue);
        if (isNaN(valueAsNumber)) {
            logger.warn('Editor value is not a number', editorValue);
        }
        switch (keyboardShortcut.operation) {
            case NumericShortcutOperation.Add:
                return valueAsNumber + keyboardShortcut.value;
            case NumericShortcutOperation.Divide:
                return valueAsNumber / keyboardShortcut.value;
            case NumericShortcutOperation.Multiply:
                return valueAsNumber * keyboardShortcut.value;
            case NumericShortcutOperation.Replace:
                return keyboardShortcut.value;
            case NumericShortcutOperation.Subtract:
                return valueAsNumber - keyboardShortcut.value;
        }
        return valueAsNumber;
    }
}

export const shortcutHelper = new ShortcutHelper();
