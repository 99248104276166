import {
    StyleSheet,
    CssClassDefinitionsObject,
    CssProperties,
    CssPropertyType,
} from '@gs-ux-uitoolkit-common/style';
import { colors } from '@gs-ux-uitoolkit-common/design-system';
import {
    Theme,
    ThemeTypographyVariant,
    createComponentClassDefinitions,
} from '@gs-ux-uitoolkit-common/theme';
import { TabsOrientation, TabsSize } from './tabs-props';
import { DeepReadonly } from 'ts-essentials';
import './tabs-theme-overrides';

export interface TabsCssClasses {
    root: string;
    container: string;
    content: string;
}

export interface TabsStyleSheetProps {
    theme: Theme;
    contentUnderneath?: boolean;
    minWidth?: number | null;
    orientation?: TabsOrientation;
    size?: TabsSize;
    type?: 'tabs' | 'pills';
    justify?: string;
}

export type TabsStyledClasses = CssClassDefinitionsObject<keyof TabsCssClasses>;

export interface TabsStyleOverridesParams {
    props: DeepReadonly<TabsStyleSheetProps>;
    createDefaultStyledClasses: () => TabsStyledClasses;
}

export const tabsStyleSheet = new StyleSheet('tabs', (props: TabsStyleSheetProps) => {
    return createComponentClassDefinitions<TabsStyleSheetProps, TabsStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.tabs
    );
});

function createDefaultStyledClasses({
    theme,
    contentUnderneath,
    minWidth,
    orientation,
    size = 'md',
    type,
    justify,
}: TabsStyleSheetProps): TabsStyledClasses {
    return {
        root: {
            display: 'flex',
            flexFlow: contentUnderneath ? 'column' : 'row',
            minWidth: `${minWidth}px`,
        },
        container: {
            display: 'flex',
            flexWrap: 'wrap',
            paddingLeft: 0,
            listStyle: 'none',
            boxSizing: 'border-box',
            marginTop: 0,
            marginBottom: '16px',
            ...navTabContainerStyles(theme, type, orientation),
            ...borderForVerticalTabs(type, orientation),
            '.nav-item': {
                ...navItemStyles(justify, orientation, type),
                '&.dropdown': {
                    position: 'relative',
                },
                '.nav-link': {
                    ...navLinkStyles(size, theme, type, orientation),
                },
                '.dropdown-menu': {
                    ...dropdownMenuStyles(theme, type),
                    '.dropdown-item': {
                        ...dropdownItemStyles(theme, size),
                    },
                },
            },
        },
        content: {
            ...theme.typography.body02,
            p: {
                margin: '6px 0 12px',
            },
            '.tab-pane': {
                display: 'none',
                '&.active': {
                    display: 'block',
                },
            },
        },
    };
}

interface TabsSizeVariant {
    tabItemHeight: CssPropertyType['height'];
    tabItemMarginRight: CssPropertyType['marginRight'];
    tabItemPadding: CssPropertyType['padding'];
    tabItemTypography: ThemeTypographyVariant;
    tabItemSelectedTypography: ThemeTypographyVariant;

    dropdownMenuItemPadding: CssPropertyType['padding'];
    dropdownMenuItemTypography: ThemeTypographyVariant;
}

const tabsSizeVariants: { [name in TabsSize]: TabsSizeVariant } = {
    md: {
        tabItemHeight: '40px',
        tabItemMarginRight: '16px',
        tabItemPadding: '9px 0px',
        tabItemTypography: 'body02',
        tabItemSelectedTypography: 'heading06',

        dropdownMenuItemPadding: '6px 16px',
        dropdownMenuItemTypography: 'body02',
    },
    lg: {
        tabItemHeight: '48px',
        tabItemMarginRight: '24px',
        tabItemPadding: '11px 0px',
        tabItemTypography: 'body01',
        tabItemSelectedTypography: 'heading05',

        dropdownMenuItemPadding: '10px 24px',
        dropdownMenuItemTypography: 'body01',
    },
};

function getOrientationStyle(orientation?: TabsOrientation): CssProperties {
    if (orientation === 'vertical') {
        return {
            borderLeftColor: colors.blue060,
        };
    } else {
        return {
            borderBottom: `2px solid ${colors.blue060}`,
        };
    }
}

function borderForVerticalTabs(type?: string, orientation?: TabsOrientation) {
    if (type === 'tabs' && orientation === 'vertical') {
        return {
            height: '100%',
            borderBottom: 'none',
            width: '160px',
        };
    }

    return {};
}

function navTabContainerStyles(theme: Theme, type?: string, orientation?: TabsOrientation) {
    let styles = {};

    if (orientation === 'vertical') {
        styles = {
            flexDirection: 'column',
        };
    }

    if (type === 'tabs') {
        styles = {
            ...styles,
            minHeight: '40px',
            borderBottom: `2px solid ${theme.border.minimal}`,
        };
    }

    return styles;
}

function navItemStyles(justify?: string, orientation?: TabsOrientation, type?: string) {
    if (type === 'tabs') {
        let justifyStyles = {};
        if (orientation === 'horizontal') {
            if (justify === 'justified') {
                justifyStyles = {
                    flex: '1 1 auto',
                    textAlign: 'center',
                };
            } else if (justify === 'fill') {
                justifyStyles = {
                    flexBasis: 0,
                    flexGrow: 1,
                    textAlign: 'center',
                };
            }
        }

        return {
            marginBottom: '-2px',
            whiteSpace: 'nowrap',
            ...justifyStyles,
        };
    }

    return {};
}

function navLinkStyles(size: TabsSize, theme: Theme, type?: string, orientation?: TabsOrientation) {
    let styles: any = {
        display: 'block',
        padding: '8px 16px',
        textDecoration: 'none',
        boxSizing: 'border-box',
        '&:hover, &:focus': {
            textDecoration: 'none',
        },
        '&.disabled': {
            opacity: 0.3,
            pointerEvents: 'none',
            cursor: 'default',
        },
        '&.show': {
            color: colors.gray070,
            backgroundColor: 'white',
            borderColor: `${colors.gray020} ${colors.gray020} white`,
        },
    };

    if (type === 'tabs') {
        styles = {
            ...styles,
            ...theme.typography[tabsSizeVariants[size].tabItemTypography],
            padding: tabsSizeVariants[size].tabItemPadding,
            marginRight: tabsSizeVariants[size].tabItemMarginRight,
            backgroundColor: 'initial',
            border: 'none',
            color: theme.text.primary,
            height: tabsSizeVariants[size].tabItemHeight,
            '&:hover': {
                color: theme.text.link,
            },
            '&:active': {
                borderRadius: '0',
            },
            '&.active': {
                ...getOrientationStyle(orientation),
                color: theme.text.link,
                ...theme.typography[tabsSizeVariants[size].tabItemSelectedTypography],
            },
            '&.disabled': {
                pointerEvents: 'none',
                cursor: 'default',
                opacity: 0.3,
            },
        };

        if (orientation === 'vertical') {
            styles = {
                ...styles,
                padding: '10px 0px 10px 12px',
                borderTopLeftRadius: '0px',
                borderLeft: `2px solid ${theme.border.minimal}`,
            };
        }
    }

    if (type === 'pills') {
        styles = {
            ...styles,
            ...theme.typography[tabsSizeVariants[size].tabItemTypography],
            borderRadius: '4px',
            color: theme.text.link,
            '&:hover:not(&.active)': {
                color: theme.text.link,
                backgroundColor: theme.getColorInteractionShades('primary', 'minimal').hover,
            },
            '&.active, .show': {
                borderColor: `${theme.border.minimal} ${theme.border.minimal} white`,
                backgroundColor: theme.text.link,
                color: theme.text.reversed,
            },
        };
    }

    return styles;
}

function dropdownMenuStyles(theme: Theme, type?: string) {
    const surfaceShades = theme.getSurfaceInteractionShades('primary');

    let styles: any = {
        position: 'absolute',
        top: '100%',
        left: 0,
        zIndex: 1000,
        display: 'none',
        float: 'left',
        minWidth: '160px',
        padding: '4px 0',
        margin: '2px 0 0',
        textAlign: 'left',
        listStyle: 'none',
        backgroundColor: theme.elevation['05'].background,
        backgroundClip: 'padding-box',
        border: `1px solid ${surfaceShades.border}`,
        borderRadius: '4px',
        "&[x-placement^='top'], &[x-placement^='right'], &[x-placement^='bottom'], &[x-placement^='left']":
            {
                right: 'auto',
                bottom: 'auto',
            },
        outline: 'none',
        boxShadow: theme.elevation['05'].shadow,
        '&.show': {
            display: 'block',
        },
    };

    if (type === 'tabs') {
        styles = {
            ...styles,
            marginTop: '-1px',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
        };
    }

    return styles;
}

function dropdownItemStyles(theme: Theme, size: TabsSize) {
    const surfaceShades = theme.getSurfaceInteractionShades('primary');

    const styles: any = {
        display: 'block',
        width: '100%',
        padding: tabsSizeVariants[size].dropdownMenuItemPadding,
        clear: 'both',
        color: theme.text.primary,
        textAlign: 'inherit',
        whiteSpace: 'nowrap',
        backgroundColor: 'transparent',
        border: 0,
        ...theme.typography[tabsSizeVariants[size].dropdownMenuItemTypography],
        outline: 'none',
        '&:hover, &:focus': {
            background: surfaceShades.hover,
            cursor: 'pointer',
        },
        '&:active': {
            background: surfaceShades.active,
            cursor: 'pointer',
        },
        '&.disabled': {
            opacity: theme.state.disabledOpacity,
            pointerEvents: 'none',
            backgroundColor: 'transparent',
        },
    };

    return styles;
}
