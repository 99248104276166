import {
    Expression,
    QuickFilter,
    ExpressionQuery,
    ExpressionMode,
} from '@gs-ux-uitoolkit-common/datacore';
import { Component, Fragment } from 'react';
import * as Redux from 'redux';
import {
    GridColumn,
    GetFormattedValueFromColumn,
    DatasourceType,
} from '../../../grid-wrappers/grid-wrapper';
import { BasicExpression } from '../../../components/expression/component/basic-expression';
import {
    DataGridState,
    ColumnValueListState,
    BasicExpressionColumnValueState,
} from '../../../redux/datagrid-state';
import { connect } from 'react-redux';
import { QuickFilterAdvancedExpression } from './quick-filter-advanced-expression';
import {
    GridGetBasicExpressionColumnValueList,
    GridResetBasicExpressionColumnValueList,
} from '../../../redux/actions/grid-action';

export interface QuickFilterExpressionProps extends QuickFilterExpressionOwnProps {
    columnValueList: ColumnValueListState[];
    quickFilterList: QuickFilter[];
    expressionMode: ExpressionMode;
    basicExpressionColumnValues: BasicExpressionColumnValueState[];
    resetColumnValues: () => void;
    getColumnValues: (columnId: string) => void;
    isDra: boolean;
}

export interface QuickFilterExpressionOwnProps {
    expression: Expression;
    columns: GridColumn[];
    currentQuickFilter?: QuickFilter;
    showRowGroups?: boolean;
    gridGetColumnValueList: (columnId: string) => void;
    gridResetColumnValueList: (columnId: string | null) => void;
    onChangeExpression: (expression: Expression) => void;
    getFormattedValueFromColumn: GetFormattedValueFromColumn;
}

export class QuickFilterExpressionComponent extends Component<QuickFilterExpressionProps> {
    public render() {
        return (
            <Fragment>
                {this.props.expressionMode === ExpressionMode.Basic ? (
                    <BasicExpression
                        onChangeExpressionQuery={this.onExpressionChange}
                        expressionQuery={this.props.expression.query}
                        columns={this.props.columns}
                        columnValueList={this.props.columnValueList}
                        gridGetColumnValueList={this.props.gridGetColumnValueList}
                        gridResetColumnValueList={this.props.gridResetColumnValueList}
                        quickFilterList={this.props.quickFilterList}
                        currentQuickFilter={this.props.currentQuickFilter}
                        getFormattedValueFromColumn={this.props.getFormattedValueFromColumn}
                        showRowGroups={this.props.showRowGroups}
                        hidePreview={true}
                        getColumnValues={this.props.getColumnValues}
                        basicExpressionColumnValues={this.props.basicExpressionColumnValues}
                        isDra={this.props.isDra}
                    />
                ) : (
                    <QuickFilterAdvancedExpression
                        columns={this.props.columns}
                        expression={this.props.expression}
                        gridGetColumnValueList={this.props.gridGetColumnValueList}
                        gridResetColumnValueList={this.props.gridResetColumnValueList}
                        getFormattedValueFromColumn={this.props.getFormattedValueFromColumn}
                        onChangeExpression={this.props.onChangeExpression}
                    ></QuickFilterAdvancedExpression>
                )}
            </Fragment>
        );
    }
    private onExpressionChange = (query: ExpressionQuery) => {
        // need to reset the basic column values state
        this.props.onChangeExpression({ query, mode: this.props.expression.mode });
    };
}
const mapStateToProps = (state: DataGridState, ownProps: QuickFilterExpressionOwnProps) => {
    return {
        columns: ownProps.columns,
        columnValueList: state.grid.columnValueList,
        basicExpressionColumnValues: state.grid.basicExpressionColumnValues,
        expression: ownProps.expression,
        gridGetColumnValueList: ownProps.gridGetColumnValueList,
        gridResetColumnValueList: ownProps.gridResetColumnValueList,
        onChangeExpression: ownProps.onChangeExpression,
        quickFilterList: state.quickFilter.configItemList,
        isDra:
            state.systemConfiguration.gridWrapperConfiguration.datasourceConfiguration
                ?.datasourceType === DatasourceType.DRA,
    };
};

const mapDispatchToProps = (dispatch: (action: Redux.Action) => void) => {
    return {
        getColumnValues: (columnId: string) =>
            dispatch(GridGetBasicExpressionColumnValueList(columnId)),
        resetColumnValues: () => dispatch(GridResetBasicExpressionColumnValueList()),
    };
};

export const QuickFilterExpression = connect(
    mapStateToProps,
    mapDispatchToProps
)(QuickFilterExpressionComponent);
