import React from 'react';
import Snackbar from '../../../../../components/core/Snackbar';
import useSnackBar from '../../hooks/useSnackbar';

const SnackbarList = () => {
  const { snackbars } = useSnackBar();
  return (
    snackbars && snackbars.map(data =>
      <Snackbar
          key={data.id} {...data}
          type={data.type}
          msgCopy={data.message}
      />
  ));
};

export default SnackbarList;
