import cloneDeep  from 'lodash/cloneDeep';
import map  from 'lodash/map';
import filter from 'lodash/filter';
import remove from 'lodash/remove';
import actionTypes from '../../../actions/app/reports/actionTypes';

const merge = (arr1, arr2, key) => {
  const list = arr1 && arr1.length > 0 && arr1.map(itm => ({...itm, ...arr2.find(elm => elm[key] === itm[key])})) || [...arr2];
  const list2 = arr2.filter(item  => map(list, 'token').indexOf(item.token) < 0);
  return [...list, ...list2];
};

const getFileDownloadSignature = (state, data) => {
  const {fileDownloadSignatures} = state;
  let files = [];
  if(fileDownloadSignatures) {
    files = cloneDeep(fileDownloadSignatures);
    files.push(data);
  }  else {
    files.push(data);
  }
  return files;
};

const removeFileFromDownloadQueue = (state, token) => {
    const {fileDownloadPollingStatus, fileDownloadSignatures, fileDownloadSignature} = state;
    const pollStatusList = filter(fileDownloadPollingStatus, file => file.token !== token);
    const signatures = filter(fileDownloadSignatures, file => file.token !== token);
    return {...state,
      fileDownloadPollingStatus: pollStatusList,
      fileDownloadSignatures: signatures,
      fileDownloadSignature: (fileDownloadSignature.token !== token) && fileDownloadSignature
    };
};

const removeAllFileFromDownloadQueue = (state) => {
    return {...state,
    fileDownloadPollingStatus: [],
    fileDownloadSignatures: [],
    fileDownloadSignature: undefined
  };
};

const addFileDownloadPollingData = (state, data) => {
  const {fileDownloadPollingStatus} = state;
  const pollStatusList =  merge(fileDownloadPollingStatus, data, 'token');
  return {...state, fileDownloadPollingStatus: pollStatusList};
};

const updateFileDownloadPollingData = (state, data) => {
  const {token, status} = data.fileDownloadStatus;
  const {fileDownloadPollingStatus} = state;
  const pollStatusList =  map(fileDownloadPollingStatus, (item) => {
     if(token === item.token) {
       return {...item, status};
     } else {
       return item;
     }
  });
  return {...state, fileDownloadPollingStatus: pollStatusList};
};


export function reportsAppDataReducer(state = {}, action) {
  const { type, data = {} } = action;
  switch (type) {

    case actionTypes.ADD_PDF_COLUMNS_CONFIGURATION: {
      const {pdfColumnsMetadata } = data;
      return { ...state, pdfColumnsMetadata };
    }
    case actionTypes.SET_FILTER_CONFIG_DATA: {
      const { filterData, nodeMap } = data;
      return {  
        ...state, 
        reports: {
          ...state.reports, 
          selectedFilterData: filterData, 
          selectedNodeMap: nodeMap
        }
      };
    }
    case actionTypes.SET_SHOULD_REFRESH_ENTITLEMENT_FILTERS: {
      const {reports: {shouldRefreshEntitlementFilters}} = data;
      state.reports = {...state.reports, shouldRefreshEntitlementFilters};
      return state;
    }

    default:
      return state;
  }
}

export function queueExcelExportStatusSnackbar(state = {}, data) {
  const { id, fileName } = data;
  const {excelDownloadStatusSnackbarList = []} = state;
  excelDownloadStatusSnackbarList.push({id, fileName});
  return {...state, excelDownloadStatusSnackbarList: [...excelDownloadStatusSnackbarList]};
}

export function dequeueExcelExportStatusSnackbar(state = {}, data) {
  const {excelDownloadStatusSnackbarList} = state;
  const { id} = data;
  const list = cloneDeep(excelDownloadStatusSnackbarList);
  remove(list, {id});
  return {...state, excelDownloadStatusSnackbarList: list};
}

export function reportsAppContextReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.START_POLLING_FILE_DOWNLOAD:
    case actionTypes.STOP_POLLING_FILE_DOWNLOAD:
      return {...state, ...action.data};
    case actionTypes.ADD_FILE_DOWNLOAD_POLLING_DATA:
      return {...addFileDownloadPollingData(state, action.data)};
    case actionTypes.DEQUEUE_FILE_DOWNLOAD:
      return {...removeFileFromDownloadQueue(state, action.data)};
    case actionTypes.DEQUEUE_ALL_FILE_DOWNLOAD:
      return {...removeAllFileFromDownloadQueue(state)};
    case actionTypes.QUEUE_FILE_DOWNLOAD:
      return {...state, ...{fileDownloadSignatures: getFileDownloadSignature(state, action.data), fileDownloadSignature: action.data}};
    case actionTypes.OPEN_DOWNLOAD_MODAL: {
      return {...state, ...{downloadModalState: 'open'}};
    }
    case actionTypes.CLOSE_DOWNLOAD_MODAL: {
      return {...state, ...{downloadModalState: 'close'}};
    }
    case actionTypes.REPORT_PDF_DOWNLOAD_STATUS: {
      return {...updateFileDownloadPollingData(state, action.data)};
    }
    case actionTypes.QUEUE_EXEL_EXPORT_SNACKBAR: {
      return {...queueExcelExportStatusSnackbar(state, action.data)};
    }
    case actionTypes.DEQUEUE_EXEL_EXPORT_SNACKBAR: {
      return {...dequeueExcelExportStatusSnackbar(state, action.data)};
    }
    case actionTypes.ADD_REPORTS_CONFIG_DATA: {
      const {  reportsConfigData } = action.data;
      return { ...state, reportsConfigData };
    }
    case actionTypes.SET_CUSTOM_REPORT_CRUD_RESPONSE: {
      const {customReportCrudResponse} = action.data;
      return { ...state, customReportCrudResponse};
    }
    default:
      return state;
  }
}
