import { ButtonBase, ButtonProps } from './button-base';

/**
 * The Delete button
 * @param props the props of the delete button
 */
export const ButtonDelete = (props: ButtonProps) => {
    const icon = props.icon || { name: 'delete', type: 'outlined' };
    return (
        <ButtonBase
            icon={icon}
            label={props.label || 'Delete'}
            disabled={props.disabled}
            className={props.className}
            onClick={props.onClick}
            displayType={props.displayType}
            actionType={props.actionType || 'secondary'}
            size={props.size}
            emphasis={props.emphasis}
        />
    );
};
