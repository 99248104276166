import { rgba } from 'polished';
import { zIndex } from '@gs-ux-uitoolkit-common/design-system';
import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './loading-overlay-theme-overrides';

export interface LoadingOverlayCssClasses {
    root: string;
}

export interface LoadingOverlayStyleSheetProps {
    theme: Theme;
    fullScreen?: boolean;
    visible?: boolean;
}

export type LoadingOverlayStyledClasses = CssClassDefinitionsObject<keyof LoadingOverlayCssClasses>;

export interface LoadingOverlayStyleOverridesParams {
    props: DeepReadonly<LoadingOverlayStyleSheetProps>;
    createDefaultStyledClasses: () => LoadingOverlayStyledClasses;
}

export const loadingOverlayStyleSheet = new StyleSheet(
    'loading-overlay',
    (props: LoadingOverlayStyleSheetProps) => {
        return createComponentClassDefinitions<
            LoadingOverlayStyleSheetProps,
            LoadingOverlayStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.loadingOverlay);
    }
);

function createDefaultStyledClasses({
    theme,
    fullScreen,
    visible,
}: LoadingOverlayStyleSheetProps): LoadingOverlayStyledClasses {
    return {
        root: {
            position: fullScreen ? 'fixed' : 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            backgroundColor: rgba(theme.surface.primary as string, 0.75),
            display: visible ? 'flex' : 'none',
            zIndex: fullScreen ? zIndex.overlayFixed : zIndex.overlay,
            '> div, > gs-loading-icon': {
                margin: 'auto',
            },
        },
    };
}
