import React, {useEffect, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Icon} from '@gs-ux-uitoolkit-react/icon-font';
import {Button} from '@gs-ux-uitoolkit-react/button';
import {FUND_TRACKER} from "../../../../constants/pageConstants";
import Select from '../../../../components/core/Select/Simple';
import SearchMetrics from '../SearchMetrics';
import {useChartContext} from '../../context';
import {simpleRenderer} from './renderer';
import {useAsync} from '../../reducers/Hooks';
import {Conditional} from '../../../../components/core/Conditional';
import {
  updateChartSeries, setMetricBenchmarksToState, setFieldForQuery,
  updateChartOptions, getUpdateSeriesWithNameAndLegend,
  clearDataMissingMessages, isBenchmarksSeriesAlreadyExists
} from '../../utils';
import {
  addMetricToSeries, fetchFundsOrBenchmarksData, fetchInitialFundSeries
} from '../../services';

const ChartDropdown = ({
   allMetricOptions, benchmarkMetricsOptions, selectedTabMetricsOptions,
   primaryMetricFromFundFinderOrPreference, pageId
}) => {
  const {dispatch: chartDispatch, state: chartState} = useChartContext();
  const {
    allShareClassData, primaryShareClassId, selection,
    metrics: selectedMetrics = [], benchmarks: selectedBenchMarks = [],
    isLoadingTopOpeningFunds
  } = chartState;
  const [primaryMetricItem, compareMetricItem] = selectedMetrics;
  const {run} = useAsync(chartDispatch);
  const isMetricsCompareMode = (selection === FUND_TRACKER.METRICS);
  const defaultMetricItem = useMemo(() => {
    return selectedTabMetricsOptions.find((item) => {
      if (primaryMetricFromFundFinderOrPreference) {
        return (item.enum === primaryMetricFromFundFinderOrPreference);
      }
      return item.default;
    });
  }, [selectedTabMetricsOptions, primaryMetricFromFundFinderOrPreference]);

  // ******************************************************************************
  //                  Below effect loads the data for the chart
  // ******************************************************************************
  useEffect(() => {
    const loadPrimaryFund = isLoadingTopOpeningFunds ? (pageId === FUND_TRACKER.PERFORMANCE) : true;
    // Set the default metrics & query field to store
    if (selectedTabMetricsOptions.length > 0 && loadPrimaryFund) {
      const metricItems = isMetricsCompareMode ? [defaultMetricItem, compareMetricItem] : [defaultMetricItem];
      const updatedData = {
        metrics: metricItems
      };
      setMetricBenchmarksToState(chartDispatch, updatedData);
      setFieldForQuery(chartDispatch, defaultMetricItem);

      if (primaryShareClassId) {
        const {
          label: fieldLabel,
          value: fieldValue
        } = defaultMetricItem;
        setTimeout(() => {
          fetchInitialFundSeries(
            run,
            chartDispatch,
            primaryShareClassId,
            {
              fieldLabel,
              fieldValue
            },
            selectedBenchMarks
          );
        }, 1);
      }
    }
  }, [selectedTabMetricsOptions, primaryShareClassId]);

  const getSelectedMetricOption = () => {
    if (!primaryMetricItem) {
      return defaultMetricItem;
    } else if (!isMetricsCompareMode) {
      return primaryMetricItem;
    }
    return compareMetricItem || primaryMetricItem;
  };

  const getMetricsOptions = () => {
    return selectedTabMetricsOptions.filter(item => {
      if (isMetricsCompareMode && compareMetricItem) {
        return compareMetricItem.value !== item.value
          && primaryMetricItem.value !== item.value;
      }
      return true;
    });
  };

  const getPrimaryMetricsLabel = () => {
    const {label = ''} = primaryMetricItem || {};
    return label;
  };

  const updateMetricItems = (metricItemToRemove, metricItemToAddToSeries, metricItemToAddToState, selection, fetchMetricDetailsFromDB = true) => {
    // Remove the previous metrics from chart dropdown.
    const updatedChartOptions = updateChartSeries(metricItemToRemove, [], chartState, undefined, benchmarkMetricsOptions);
    // Set axis direction to opposite, if we are adding compare item
    const addOppositeAxis = !compareMetricItem;
    // Add the selected metrics item to the series
    if (fetchMetricDetailsFromDB) {
      addMetricToSeries(metricItemToAddToSeries, run, chartDispatch, updatedChartOptions, chartState, selectedMetrics, addOppositeAxis);
    } else {
      // Get the default series updated with name and legend
      const chartOptions = {
        ...updatedChartOptions,
        series: getUpdateSeriesWithNameAndLegend(updatedChartOptions.series, allShareClassData, primaryShareClassId)
      };
      updateChartOptions(chartDispatch, chartOptions);
    }
    // Add the selected metric item to the state
    const updatedData = {
      metrics: metricItemToAddToState,
      selection
    };
    setMetricBenchmarksToState(chartDispatch, updatedData);
  };

  const updateBenchmarksItems = (
    metricItemToRemove,
    metricItemToAddToSeries,
    metricItemToAddToState,
    selection,
    fetchMetricDetailsFromDB = true,
    benchmarks
  ) => {
    // Remove the previous metrics from chart dropdown.
    const updatedChartOptions = updateChartSeries(metricItemToRemove, benchmarks, chartState, metricItemToRemove.value, benchmarkMetricsOptions);
    const {primaryShareClassId} = chartState;
    // Add the selected metrics item to the series
    const benchmarksSeriesAlreadyExists = isBenchmarksSeriesAlreadyExists(updatedChartOptions, benchmarkMetricsOptions);
    if (fetchMetricDetailsFromDB && !benchmarksSeriesAlreadyExists) {
      const benchmarksToFetch = [{ value: primaryShareClassId, type: 'fund' }, ...benchmarks];
      const fieldForQuery = {fieldValue: metricItemToAddToSeries.value};
      fetchFundsOrBenchmarksData(
        run,
        fieldForQuery,
        benchmarkMetricsOptions,
        benchmarksToFetch,
        chartDispatch,
        updatedChartOptions,
        chartState,
        false,
        metricItemToAddToSeries.label,
        selectedMetrics
      );
    } else {
      updateChartOptions(chartDispatch, updatedChartOptions);
    }
    // Add the selected metric item to the state
    const updatedData = {
      metrics: metricItemToAddToState,
      benchmarks,
      selection
    };
    setMetricBenchmarksToState(chartDispatch, updatedData);
  };

  /* Selection will be either benchmarks/metrics if the use is changing the
     metrics after performing comparison, otherwise it is null. */
  const handleSelection = useCallback((selectedOption) => {
    if ((selection && compareMetricItem && selectedOption.value === compareMetricItem.value) ||
      (!selection && primaryMetricItem && selectedOption.value === primaryMetricItem.value)) {
      return;
    }
    // Clear the Fund/Benchmark data missing message
    clearDataMissingMessages(chartDispatch);

    if (!isMetricsCompareMode) {
      setFieldForQuery(chartDispatch, selectedOption);
    }

    if (selectedOption && selectedOption.value) {
      switch(selection) {
        case FUND_TRACKER.BENCHMARKS: {
          const metricItemToRemove = getSelectedMetricOption();
          const metricItemToAddToState = [selectedOption];
          updateBenchmarksItems(metricItemToRemove, selectedOption, metricItemToAddToState, selection, true, selectedBenchMarks);
          break;
        }
        case FUND_TRACKER.METRICS: {
          const metricItemToRemove = compareMetricItem;
          const metricItemToAddToState = [...selectedMetrics.slice(0, 1), selectedOption];
          updateMetricItems(metricItemToRemove, selectedOption, metricItemToAddToState, FUND_TRACKER.METRICS);
          break;
        }
        default: {
          const metricItemToRemove = primaryMetricItem;
          const metricItemToAddToState = [selectedOption];
          updateMetricItems(metricItemToRemove, selectedOption, metricItemToAddToState, null);
        }
      }
    }
  });

  const handleChange = (action, data) => {
    handleSelection(data);
  };

  const handleDeSelection = () => {
    const { chartInstance } = chartState;
    delete chartInstance.dummyObject;
    const metricItemToRemove = compareMetricItem;
    const metricItemToAddToState = [primaryMetricItem];
    updateMetricItems(metricItemToRemove, primaryMetricItem, metricItemToAddToState, null, false);
  };

  const metricOptions = getMetricsOptions();

  return (
    <div className="metricsDropdownContainer">
      <Conditional condition={isMetricsCompareMode}>
        <div className="item-wrapper">
          <span className="primaryMetrics">
            {getPrimaryMetricsLabel()}
          </span>
          <span className="separator">{'VS'}</span>
        </div>
      </Conditional>
      <div className="item-wrapper">
        <Conditional condition={isMetricsCompareMode}>
          <SearchMetrics
            allMetricOptions={allMetricOptions}
            onSelectCompareItem={handleChange}
          />
          <Select
            valueKey="value"
            selectedOption={getSelectedMetricOption()}
            isActive={true}
            isDisabled={!primaryShareClassId}
            hasError={false}
            clickHandler={handleSelection}
            optionRenderer={simpleRenderer}
            labelKey="label"
            options={metricOptions}
            testId="test-metrics"
          />
        </Conditional>
        <Conditional condition={isMetricsCompareMode}>
          <Button
            actionType="contrast"
            emphasis="bold"
            onClick={handleDeSelection}>
            <Icon name="cancel" type="outlined" />
          </Button>
        </Conditional>
      </div>
    </div>
  );
};

ChartDropdown.propTypes = {
  allMetricOptions: PropTypes.array,
  selectedTabMetricsOptions: PropTypes.array,
  benchmarkMetricsOptions: PropTypes.array,
  primaryMetricFromFundFinderOrPreference: PropTypes.string,
  pageId: PropTypes.string
};

export default ChartDropdown;
