export const LEVEL = {
  FIRM: 'FIRM',
  BRANCH: 'BRANCH',
  ACCOUNT: 'ACCOUNT'
};

export const RULE_TYPE_TRANSLATION_KEYS = {
  PCT_OF_TOTAL_CASH_IN_SINGLE_FUND: {
    titleKey: 'tkPortfolioExposure',
    descriptionKey: 'tkPortfolioExposureDescription'
  },
  TOTAL_CASH_IN_SINGLE_FUND: {
    titleKey: 'tkFundConcentration',
    descriptionKey: 'tkFundConcentrationDescription'
  },
  EXPOSURE_IN_SINGLE_FUND: {
    titleKey: 'tkFundOwnership',
    descriptionKey: 'tkFundOwnershipDescription'
  },
  // TODO: Currently not in scope but added as mentioned in contract. it is for future use.
  PCT_OF_TOTAL_CASH_IN_SINGLE_FUND_FAMILY: {
    titleKey: 'N/A',
    descriptionKey: 'N/A'
  },
  // TODO: Currently not in scope but added as mentioned in contract. it is for future use.
  TOTAL_CASH_IN_SINGLE_FUND_FAMILY: {
    titleKey: 'N/A',
    descriptionKey: 'N/A'
  }
};
export const TRADE_BEHAVIOR = {
  WARN:'WARN',
  BLOCK:'BLOCK'
};

export const POLICY_STATUS = {
  IN_POLICY : 'IN_POLICY',
  OUT_OF_POLICY : 'OUT_OF_POLICY'
}

export const POLICY_STATUS_LABEL = {
  IN_POLICY : 'tkInPolicy',
  OUT_OF_POLICY : 'tkOutofPolicy'
}

export const tradeBehaviors = [
  {
    label:'tkWarn',
    value: TRADE_BEHAVIOR.WARN
  }, {
    label:'tkBlock',
    value: TRADE_BEHAVIOR.BLOCK
  }
];

export const RULE_TYPE = {
  FUND_OWNERSHIP: 'PCT_OF_TOTAL_CASH_IN_SINGLE_FUND',
  FUND_CONCENTRATION: 'TOTAL_CASH_IN_SINGLE_FUND',
  PORTFOLIO_EXPOSURE: 'EXPOSURE_IN_SINGLE_FUND'
};

export const GROUP_TYPE = {
  SETTLEMENT_GROUP: 'settlementGroup',
  SETTLEMENT_LOCATION: 'settlementLocation',
  ACCOUNT_ID: 'accountId'
};

export const WS_DEFAULT_GROUPBY =
  'firmDisplay/branchDisplay/settlementGroup/settlementLocation/investmentCurrency/tradeType';
