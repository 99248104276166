// tslint:disable-next-line
import FontFaceObserver from 'fontfaceobserver-es';
import {
    kebabCase,
    isTestEnv,
    logWarningOnce,
    isBrowser,
    isIE,
} from '@gs-ux-uitoolkit-common/shared';

export interface FontState {
    loaded: boolean;
    loadPromise: Promise<void>;
}

const fontState: { [fontFamily: string]: FontState } = {};

// Observer set in global state to resolve when the icon font is loaded: https://fontfaceobserver.com/
export class FontLoadingStore {
    private constructor() {
        /* no instances */
    }

    public static getFont(fontFamily: string) {
        // skip IE11 because the observer times out and doesnt remove the loading class.
        if (!isBrowser || isIE) return null;

        if (!fontState[fontFamily]) {
            addBodyLoadingClass({ fontFamily });
            fontState[fontFamily] = {
                loaded: false,
                loadPromise: new FontFaceObserver(fontFamily).load(undefined, 30000).then(
                    () => {
                        fontState[fontFamily].loaded = true;
                        removeBodyLoadingClass({ fontFamily });
                    },
                    error => {
                        // Only log warning if not in a test environment
                        if (!isTestEnv()) {
                            logWarningOnce(`Font ${fontFamily} cannot be loaded: ${error}`);
                        }
                    }
                ),
            };
        }
        return fontState[fontFamily];
    }
}

function addBodyLoadingClass(props: { fontFamily: string }) {
    const { fontFamily } = props;
    const bodyClasses = document.body.classList;
    const fontLoadedClass = getIconFontLoadingClass({ fontFamily });
    if (!bodyClasses.contains(fontLoadedClass)) {
        bodyClasses.add(fontLoadedClass);
    }
}

function removeBodyLoadingClass(props: { fontFamily: string }) {
    const { fontFamily } = props;
    const bodyClasses = document.body.classList;
    const fontLoadedClass = getIconFontLoadingClass({ fontFamily });
    bodyClasses.remove(fontLoadedClass);
}

export function getIconFontLoadingClass(props: { fontFamily: string }) {
    return `gs-uitk-icon-font-loading-${kebabCase(props.fontFamily)}`;
}
