import React, {useRef, useState} from 'react';
import { unstable_batchedUpdates as batch } from 'react-dom';
import PropTypes from 'prop-types';
import {FUND_TRACKER} from "../../../../constants/pageConstants";
import Heading from './Heading'
import Modal from '../../../../components/core/Modal';
import Button from '../../../../components/core/Button';
import RadioGroup from '../../../../components/app/RadioGroup';
import translator from '../../../../services/translator';
import {Conditional} from '../../../../components/core/Conditional';
import SearchBenchmark from '../SearchBenchMarks';
import SearchMetrics from '../SearchMetrics';
import {useChartContext} from '../../context';
import {
  diffArrays, updateChartSeries, setMetricBenchmarksToState,
  addFundsOrBenchmarkSeriesToChart, addMetricsSeriesToChart,
  cleanupGridData, updateChartOptions, updateShareclassPerformanceData
} from '../../utils';
import {useAsync} from '../../reducers/Hooks';
import './index.scss';

const {translate: t} = translator;

const customModalStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  height: 'auto',
  transform: 'translate(-50%, -50%)'
};

const CompareModal = ({
  allMetricOptions, benchmarkMetricsOptions, isModalOpen,
  saveAppPreferences, handleClose
}) => {
  const {BENCHMARKS, METRICS} = FUND_TRACKER;
  const compareModalRef = useRef();
  const {state: chartState, dispatch: chartDispatch} = useChartContext();
  const {
    fieldForQuery, allShareClassData, primaryShareClassId,
    selection: compareSelection, metrics: selectedMetrics,
    benchmarks, shareClassPerformanceData, asOfDate
  } = chartState;
  const {run} = useAsync(chartDispatch);
  const selectedFundOrBenchmarks = useRef([]);
  const selectedMetricItem = useRef({});
  const selection = useRef(BENCHMARKS);
  const [selectedCompareType, setSelectedCompareType] = useState(compareSelection || BENCHMARKS);
  const [primaryMetricItem, compareMetricItem] = selectedMetrics;

  const radioOptions = () => {
    return [{
      value: BENCHMARKS,
      label: t('tkBenchMarks'),
      isSelected: selectedCompareType === BENCHMARKS
    },
    {
      value: METRICS,
      label: t('tkMetrics'),
      isSelected: selectedCompareType === METRICS
    }];
  };

  const handleChange = React.useCallback((action, data) => {
    if (action === BENCHMARKS) {
      selectedFundOrBenchmarks.current = data;
      selection.current = BENCHMARKS;
    } else if (action === METRICS) {
      selectedMetricItem.current = data;
      selection.current = METRICS;
    }
  }, []);

  const onClose = (status) => {
    compareModalRef.current.closeModal(() => handleClose(status));
  };

  // ***************************************************************************************
  //              To get the metric item that needs to removed from the chart
  // ***************************************************************************************
  const getMetricsItemToRemove = (selectedCompareMetricItem) => {
    // If user has selected same compare metric item, which was already selected, no need to remove it.
    if (selectedCompareMetricItem && compareMetricItem && (selectedCompareMetricItem.value === compareMetricItem.value)) {
      return undefined;
    }
    // return the existing metric item that needs to be removed, and newly selected compare metric item will set the state
    // down the line
    return compareMetricItem;
  };

  // ***************************************************************************************
  //            To remove Metrics and Funds/Benchmark series from chart
  // ***************************************************************************************
  const removeMetricsAndBenchmarksFromSeries = (selectedFundOrBenchmarks, metricsItemToRemove) => {
    const fundOrBenchmarksToRemove = diffArrays(
      benchmarks,
      selectedFundOrBenchmarks,
      (v1, v2) => v1.value === v2.value
    );
    return {
      fundOrBenchmarksToRemove,
      updatedChartOptions: updateChartSeries(
        metricsItemToRemove,
        fundOrBenchmarksToRemove,
        chartState,
        primaryMetricItem.value,
        benchmarkMetricsOptions
      )
    }
  };

  // ***************************************************************************************
  //            To process added Funds/Benchmark data added from modal
  // ***************************************************************************************
  const processBenchmarkItems = () => {
    // Get the metric item to be removed from series
    const metricsItemToRemove = getMetricsItemToRemove();
    const selectedFundOrBenchmarksData = selectedFundOrBenchmarks.current || [];
    /* Remove the series from Chart for the benchmarks that were removed from Modal.
       We also need to remove if there were any compare metrics item that were set earlier */
    const {
      fundOrBenchmarksToRemove,
      updatedChartOptions
    } = removeMetricsAndBenchmarksFromSeries(selectedFundOrBenchmarksData, metricsItemToRemove);

    // Set it as user selected primary Share Class
    const selectedCompareShareClassId = selectedFundOrBenchmarksData.map(({value}) => value);
    const lastShareclassIds = [primaryShareClassId, ...selectedCompareShareClassId];
    saveAppPreferences({
      preferenceRootKey: 'fundTracker',
      preferenceData: {
        lastShareclassIds
      }
    });

    // Add/remove data from bottom grid
    cleanupGridData(
      selectedFundOrBenchmarksData,
      fundOrBenchmarksToRemove,
      allShareClassData,
      primaryShareClassId,
      chartDispatch
    );

    const newBenchmarkData = diffArrays(selectedFundOrBenchmarksData, benchmarks, (v1, v2) => v1.value === v2.value);

    // Get the newly added benchmarks to the chart series
    if (newBenchmarkData.length > 0) {
      addFundsOrBenchmarkSeriesToChart(
        run,
        fieldForQuery,
        benchmarkMetricsOptions,
        newBenchmarkData,
        chartDispatch,
        updatedChartOptions,
        chartState,
        true,
        [{value: primaryShareClassId, type: "fund"}, ...selectedFundOrBenchmarksData]
      );
    } else if (fundOrBenchmarksToRemove && fundOrBenchmarksToRemove.length >  0) {
      const results = diffArrays(shareClassPerformanceData, fundOrBenchmarksToRemove, (v1, v2) => {
        return v1.id === v2.value;
      });
      batch(() => {
        updateChartOptions(chartDispatch, updatedChartOptions);
        updateShareclassPerformanceData(chartDispatch, { results, asOfDate })
      })
    }

    // Set the selected benchmark items to context
    const updatedData = {
      metrics: [primaryMetricItem],
      benchmarks: selectedFundOrBenchmarksData,
      selection: (!!selectedFundOrBenchmarksData.length && BENCHMARKS)
    };
    setMetricBenchmarksToState(chartDispatch, updatedData);
  };

  // ***************************************************************************************
  //                To process added Metrics data added from modal
  // ***************************************************************************************
  const processMetricItems = () => {
    // Get currently selected metric item
    const selectedCompareMetricItem = selectedMetricItem.current;
    // Get the metric item to be removed from series
    const metricsItemToRemove = getMetricsItemToRemove(selectedCompareMetricItem);
    // Capture the compare benchmarks and metrics items, which needs to be removed.
    const updatedChartOptions = updateChartSeries(
      metricsItemToRemove,
      benchmarks,
      chartState,
      primaryMetricItem.value,
      benchmarkMetricsOptions
    );
    // Add the newly selected metric item to the chart
    const selectedMetricItems = [primaryMetricItem, selectedCompareMetricItem];
    addMetricsSeriesToChart(
      selectedCompareMetricItem,
      run,
      chartDispatch,
      updatedChartOptions,
      chartState,
      selectedMetricItems,
      false
    );
    // Add the selected compare metric item
    const metricsItemToAddToState = [...selectedMetrics.slice(0, 1), selectedCompareMetricItem];
    const updatedData = {
      benchmarks: [],
      metrics: metricsItemToAddToState,
      selection: selection.current
    };
    setMetricBenchmarksToState(chartDispatch, updatedData);
  };

  // ***************************************************************************************
  //       To handle the comparision of Multiple Metrics or Multiple Funds/Benchmarks
  // ***************************************************************************************
  const handleCompareBtnClick = (status) => {
    if (selection.current === BENCHMARKS) {
      // If the benchmark option was selected in the modal
      processBenchmarkItems();
    } else if (selection.current === METRICS) {
      // If the Metrics option was selected in the modal
      processMetricItems();
    }
    compareModalRef.current.closeModal(() => handleClose(status));
  };

  const handleCompareTypeChange = (event, isChecked, option) => {
    setSelectedCompareType(option.id);
  };

  return (
    <Modal
      ref={compareModalRef}
      customModalStyle={customModalStyle}
      suppressScrollOnActive={true}
      customClass="modal--center compare-modal compare-modal__index"
      backdropCustomClass="compare-modal__backdrop-index"
      open={isModalOpen}
      handleClose={handleClose}
      animation={false}>
      <div className="compare-modal__innerBody" >
        <Heading />
        <div className="compare-modal__inputContainer">
          <div className="compare-modal__section">
            <div className="margin-neg-8px">
              <RadioGroup
                testId="compare-modal-radio-group"
                onChange={handleCompareTypeChange}
                options={radioOptions()}
              />
            </div>
            <div className={'compare-modal__search'}>
              <Conditional condition={selectedCompareType === BENCHMARKS}>
                <SearchBenchmark
                  title={t('tkSearchFundOrBenchmark')}
                  placeholder={`${t('tkSearchFundOrBenchmark')}...`}
                  primaryShareClassId={primaryShareClassId}
                  onSelectCompareItem={handleChange}
                />
                <SearchMetrics
                  title={t('tkSelectSecondaryMetric')}
                  allMetricOptions={allMetricOptions}
                  onSelectCompareItem={handleChange}
                />
              </Conditional>
            </div>
          </div>
        </div>
        <div className="compare-modal__buttonRow">
          <Button
            label={t('tkCancel')}
            customClass='button-secondary-small'
            clickHandler={(event) => onClose(event)}
          />
          <Button
            label={t('tkOk')}
            customClass='button-primary-small'
            clickHandler={(event) => handleCompareBtnClick(event)}
          />
        </div>
      </div>
    </Modal>
  );
};

CompareModal.propTypes = {
  allMetricOptions: PropTypes.array,
  benchmarkMetricsOptions: PropTypes.array,
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  saveAppPreferences: PropTypes.func
};

export default CompareModal;
