import { getGlobalThis } from '@gs-ux-uitoolkit-common/shared';

const singletonGlobalThis = getGlobalThis();

export function getSingletonInstance<T>(key: string, instanceCreator: () => T): T {
    // we create a runtime-wide symbol. That's cool. I didn't know that stuff!
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Symbol/for
    const REGISTRY_CONTEXT_KEY: any = Symbol.for(key);
    // if it's the first time it's being called we create the instance
    if (!singletonGlobalThis[REGISTRY_CONTEXT_KEY]) {
        // the global object definition has been extended in the global.d.ts to allow property indexer i.e. doing global[REGISTRY_CONTEXT_KEY]
        singletonGlobalThis[REGISTRY_CONTEXT_KEY] = instanceCreator();
    }
    return singletonGlobalThis[REGISTRY_CONTEXT_KEY];
}
