import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { InputSize } from './types';
import { inputHeights } from './input-style-sheet';
import { getFormTheme } from './form-theme';
import { DeepReadonly } from 'ts-essentials';
import './input-internal-icons-ui-theme-overrides';

export interface InputInternalIconsUiStyleSheetProps {
    theme: Theme;
}
export interface InputInternalIconsUiCssClasses {
    root: string;
}

export type InputInternalIconsUiStyledClasses = CssClassDefinitionsObject<
    keyof InputInternalIconsUiCssClasses
>;

export interface InputInternalIconsUiStyleOverridesParams {
    props: DeepReadonly<InputInternalIconsUiStyleSheetProps>;
    createDefaultStyledClasses: () => InputInternalIconsUiStyledClasses;
}

export const inputInternalIconsUiStyleSheet = new StyleSheet(
    'input-internal-icons-ui',
    (props: InputInternalIconsUiStyleSheetProps) => {
        return createComponentClassDefinitions<
            InputInternalIconsUiStyleSheetProps,
            InputInternalIconsUiStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.inputInternalIconsUi);
    }
);

export interface InputInternalIconsUiSearchClearStyleSheetProps
    extends InputInternalIconsUiStyleSheetProps {
    size: InputSize;
    empty: boolean;
}

export interface InputInternalIconsUiSearchClearCssClasses {
    button: string;
}

export type InputInternalIconsUiSearchClearStyledClasses = CssClassDefinitionsObject<
    keyof InputInternalIconsUiSearchClearCssClasses
>;

export interface InputInternalIconsUiSearchClearStyleOverridesParams {
    props: DeepReadonly<InputInternalIconsUiSearchClearStyleSheetProps>;
    createDefaultStyledClasses: () => InputInternalIconsUiSearchClearStyledClasses;
}

export const inputInternalIconsUiSearchClearStyleSheet = new StyleSheet(
    'input-internal-icons-ui-search-clear',
    (props: InputInternalIconsUiSearchClearStyleSheetProps) => {
        return createComponentClassDefinitions<
            InputInternalIconsUiSearchClearStyleSheetProps,
            InputInternalIconsUiSearchClearStyledClasses
        >(
            props,
            createDefaultStyledClassesSearchClear,
            props.theme.styleOverrides?.inputInternalIconsUiSearchClear
        );
    }
);

const defaultInputInternalIconsUiStyles = {
    display: 'flex',
    alignItems: 'center',
};

const emptyProps = {
    cursor: 'default',
    opacity: 0,
};

function getClearButtonStyles({ theme }: { theme: Theme }) {
    const formTheme = getFormTheme(theme);

    return {
        borderWidth: 0,
        backgroundColor: 'transparent',
        padding: 0,
        opacity: 1,
        // Put above the file input control so it can be
        // clicked instead of opening the file dialog
        zIndex: 1,
        transition: 'opacity 0.15s ease-in-out',

        '&:active': {
            outline: 'none',
        },
        '&:focus': {
            outline: 'none',
        },

        '& [data-cy=gs-uitk-icon__content]': {
            verticalAlign: 'middle',
            color: formTheme.clearButton.iconColor,
            transition: 'color 0.15s ease-in-out',
        },
        '&:hover:not(:disabled) [data-cy=gs-uitk-icon__content]': {
            color: formTheme.clearButton.iconHoverColor,
        },
        '&:focus:not(:disabled) [data-cy=gs-uitk-icon__content]': {
            color: formTheme.clearButton.iconHoverColor,
        },
        '&:active:not(:disabled) [data-cy=gs-uitk-icon__content]': {
            color: formTheme.clearButton.iconActiveColor,
        },
    };
}

function createDefaultStyledClasses(
    _props: InputInternalIconsUiStyleSheetProps
): InputInternalIconsUiStyledClasses {
    return {
        root: {
            ...defaultInputInternalIconsUiStyles,
        },
    };
}

function createDefaultStyledClassesSearchClear({
    theme,
    size,
    empty,
}: InputInternalIconsUiSearchClearStyleSheetProps): InputInternalIconsUiSearchClearStyledClasses {
    return {
        button: {
            ...getClearButtonStyles({ theme }),
            height: inputHeights[size],
            ...(empty ? emptyProps : {}),

            // opacity also needs to be set on the icon to hide in IE
            '[data-gs-uitk-component="icon"]': {
                ...(empty ? emptyProps : {}),
            },
        },
    };
}
