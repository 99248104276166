import { DropdownCommonProps, DropdownSize } from './dropdown-props';
import { MenuCommonProps, MenuBlurEvent } from '@gs-ux-uitoolkit-common/menu';

export interface DropdownCommonContext {
    size: DropdownSize;
    menuVisible: DropdownCommonProps['menuVisible'];
    menuTargetEl?: MenuCommonProps['target'];
    menuTriggerEl?: HTMLElement;
    toggleMenu?: () => void;
    menuOnBlur?: (event: MenuBlurEvent) => void;
}

export const defaultDropdownContext: DropdownCommonContext = {
    size: 'md',
    menuVisible: false,
};
