import { colors } from '@gs-ux-uitoolkit-react/design-system';
import { StyleSheet } from '@gs-ux-uitoolkit-common/style';

export const maskedColumnStyleSheet = new StyleSheet('column-masked', () => {
    return {
        root: {
            background: `repeating-linear-gradient(
                    -45deg,
                    ${colors.gray070},
                    ${colors.gray070} 5px,
                    rgba(255,255,255,0) 5px,
                    rgba(255,255,255,0) 20px
                )`,
            display: 'table',

            '.column-masked-text': {
                borderStyle: 'double',
                fontSize: 'large',
                background: colors.white,
            },

            '.column-masked-text-container': {
                textAlign: 'center',
                display: 'block',
                position: 'absolute',
                top: '50%',
                transform: 'translate(-50%) rotate(-90deg)',
                left: '50%',
            },
        },
    };
});
