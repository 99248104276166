import {createSelector} from 'reselect';
import find from 'lodash/find';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import translator from '../../../services/translator';
import {REPORTS_STATUS_CODES, SHOW_ERROR_ON} from "../../../constants/pageConstants";
import errorTypes from "../../../error/errorType";
import {FILE_DOWNLOAD_STATE, SnackbarTypes} from "../../../constants/appConstants";
import {labelSelector} from "../index";

const {translate: t} = translator;

export const reportsConfigDataSelector = state => state.appContext.reportsConfigData;
export const customReportCrudResponseSelector = state => state.appContext.customReportCrudResponse;
export const mySavedReportsDataSelector = state => state.appContext.mySavedReports;
export const selectedSavedReportDataSelector = state => state.appContext.selectedSavedReportData;
export const pdfColumnsData = state => get(state, 'appData.pdfColumnsMetadata.columns', []);

export const fileDownloadSignature = state => state.appContext && state.appContext.fileDownloadSignature;
export const fileDownloadSignatures = state => state.appContext && state.appContext.fileDownloadSignatures;
export const pollingFilesStatusList = state => get(state, 'appContext.fileDownloadPollingStatus', []);
export const stopPollingFileDownloadApiSelector = state => state.appContext && state.appContext.stopPollingFileDownloadApi;
export const isOpenFileDownloadModal = state => state.appContext.downloadModalState === 'open';
export const exportExcelsList = state => state.appContext.excelDownloadStatusSnackbarList;

export const getErrorObject = state => get(state, 'appContext.errorObject', {});
export const showErrorOn = state => ((state.appContext.errorObject && state.pageContext.errorObject.showErrorOn) || '');

export const getFileDownloadPollingQueue = createSelector(
  pollingFilesStatusList,
  (list) => {
    return list.filter((signature) => signature.ready === false);
  }
);

export const getDownloadedFilesTokens = createSelector(
  pollingFilesStatusList,
  (list) => {
    const tokens = [];
    forEach(list, (item) => {
      if (item.ready) {
        tokens.push(item.token);
      }
    });
    return tokens;
  }
);

export const getFileDownloadSignaturesSelector = createSelector(
  fileDownloadSignatures,
  getDownloadedFilesTokens,
  (signatures, downloadedFileTokens) => {
    return signatures && signatures.filter(item => downloadedFileTokens.indexOf(item.token) < 0);
  }
);

const getSnackbar = (isReady, token, fileName, status) => {
  const messageProgress = `${t('tkDownloadProgressMessage')} ${fileName}`;
  const messageSuccess = t('tkDownloadCompleteMessage');
  const failedMessage = t('tkPDFDownloadError', fileName);

  if (status === FILE_DOWNLOAD_STATE.FAILED) {
    return {
      displayMultiple: true,
      type: SnackbarTypes.ERROR,
      message: failedMessage,
      autoHide: false,
      showCloseCTA: true,
      id: token,
      useCustomBody: true,
      showLoader: !isReady,
      affix: true,
      showIcon: true
    };
  }

  const isRunning = (status === FILE_DOWNLOAD_STATE.INITIATED);
  const isDownloaded = (status === FILE_DOWNLOAD_STATE.DOWNLOADED) || (status === FILE_DOWNLOAD_STATE.READY_TO_DOWNLOAD);
  const message =  isDownloaded ? messageSuccess : (isRunning && messageProgress);
  const type = isRunning ? SnackbarTypes.INFO : isDownloaded && SnackbarTypes.SUCCESS;

  return {
    displayMultiple: true,
    type,
    message,
    autoHide: isDownloaded,
    showCloseCTA: true,
    id: token,
    useCustomBody: true,
    showLoader: !isReady,
    fileName,
    affix: false,
    showIcon: isReady
  };
};

export const getFileDownloadStatusSnackbars = createSelector(
  pollingFilesStatusList,
  fileDownloadSignature,
  (list, fileToDownload) => {
     const statusList = cloneDeep(list);
     const isExist = fileToDownload && find(statusList, {token: fileToDownload.token});
     if(fileToDownload && !isExist) {
      const file = {...fileToDownload, ready: false};
       statusList && statusList.length && statusList.push(file);
     }

   return statusList.map((file) => {
     const {fileName, token, ready, status} = file;
     return getSnackbar(ready, token, fileName, status);
    });
  }
);

export const getFileDownloadStatusSnackbarsExcel = createSelector(
  exportExcelsList,
  (downloadedExcelsList) => {
    if (!downloadedExcelsList || !downloadedExcelsList.length) {
      return [];
    }
    return downloadedExcelsList.reduce((result, {id, fileName}) => {
      // only include current excel if it has filename set
      if(fileName) {
        result.push({
          id,
          fileName,
          displayMultiple: true,
          type: SnackbarTypes.SUCCESS,
          message: t('tkDownloadExcelProgressMessage'),
          showCloseCTA: true,
          useCustomBody: true,
          affix: false,
        });
      }
      return result;
    }, []);
  }
);

export const stopPollingFileDownloadSelector = createSelector(
  getFileDownloadPollingQueue,
  (signatures) => {
    return signatures && signatures.length === 0;
  }
);

export const savedReportFailedSnackbarSelector = createSelector(
  customReportCrudResponseSelector,
  labelSelector,
  (customReportCrudResponse = {}, labels = {}) => {
    if (!isEmpty(customReportCrudResponse)) {
      const {status} = customReportCrudResponse;
      let message;
      const {
        SAVE_VIEW_FAILED, SAVE_REPORT_FAILED,
        REPORT_NAME_MISSING_ERROR
      } = REPORTS_STATUS_CODES;
      switch (status) {
        case SAVE_VIEW_FAILED: {
          message = labels.tkSaveViewFailedMsg;
          break;
        }
        case SAVE_REPORT_FAILED: {
          message = labels.tkSaveReportFailedMsg;
          break;
        }
        case REPORT_NAME_MISSING_ERROR: {
          message = labels.tkReportNameMissingError;
          break;
        }
        default:
          message = ''
      }
      return {
        id: Date.now(),
        type: SnackbarTypes.ERROR,
        sticky: false,
        autoHide: false,
        msgCopy: message
      }
    }
    return {};
  }
);

export const savedReportResponseSnackbarSelector = createSelector(
  customReportCrudResponseSelector,
  (customReportCrudResponse = {}) => {
    if (!isEmpty(customReportCrudResponse)) {
      const {
        reportName,
        description,
        viewId: savedReportViewId,
        status,
        request: requestPayLoad,
        isDeleteReport,
        isExistingReportUpdated
      } = customReportCrudResponse;

      if (!isDeleteReport) {
        if (status === SnackbarTypes.SUCCESS.toUpperCase()) {
          const message = isExistingReportUpdated ? t('tkUpdateCustomReportMsg', reportName) : t('tkSaveNewCustomReportMsg', reportName);
          const savedReportDetails = {
            selectedSavedReportData: {
              reportName,
              reportDescription: description,
              savedReportViewId,
              requestPayLoad
            }
          };
          return [{
            displayMultiple: true,
            type: SnackbarTypes.SUCCESS,
            message,
            autoHide: false,
            showCloseCTA: true,
            id: isExistingReportUpdated ? 'updateExistingReport' : savedReportViewId,
            savedReportDetails,
            useCustomBody: true,
            isCustomSaveReportMsg: true,
            affix: false
          }]
        }

        if (status === 'MAX_COUNT') {
          const message = t('tkMaxLimitReportMsg', reportName);
          return [{
            displayMultiple: true,
            type: SnackbarTypes.ERROR,
            message,
            autoHide: false,
            showCloseCTA: true,
            id: 'maxReportsExceedsError',
            useCustomBody: true,
            isCustomSaveReportMsg: true,
            affix: false
          }]
        }
      }
    }
    return [];
  }
);

// to handle snackbar error
// don't add any extra selectors here, only for error
export const snackbarErrorList = createSelector(
  getErrorObject, (errorObject) => {
    const list = [];
    /**
     * if SHOW_ERROR_ON.SNACKBAR is true errorObject.errorCode will always be UNKNOWN_SERVICE_ERROR
     * because we are setting it from errorHandler
     * */
    if (errorObject.showErrorOn === SHOW_ERROR_ON.SNACKBAR && (errorObject.errorCode === errorTypes.UNKNOWN_SERVICE_ERROR)){
      const message = errorObject.errorMessage && t(errorObject.errorMessage) ||  t('tkPDFGenerateError');
      list.push({
        displayMultiple:true,
        autoHide: false,
        showCloseCTA: true,
        id: new Date().toLocaleString(),
        type: SnackbarTypes.ERROR,
        message
      });
    }
    return list;
  }
);
