import { PluginBase, stringHelper, PluginIcon } from '@gs-ux-uitoolkit-common/datacore';
import { ModuleIdentfier } from '../module-identfier';
import { GridWrapper } from '../../grid-wrappers/grid-wrapper';
import { DataGridState, SearchableState } from '../../redux/datagrid-state';
import { Categories, Plugins } from '../plugin-enum';
import { SearchablePluginWidget } from './view/react/searchable-plugin-widget';
import { DataGridToolbarItem } from '../../toolbar-state';

const mainIcon: PluginIcon = { name: 'search', type: 'filled' };

export class SearchablePlugin extends PluginBase<GridWrapper, DataGridState, SearchableState> {
    protected static requiredModules: ModuleIdentfier[] = [
        ModuleIdentfier.SetFilterModule,
        ModuleIdentfier.MultiFilterModule,
    ];
    constructor(wrapper: GridWrapper) {
        super(
            Plugins.SearchablePlugin,
            Categories.View,
            mainIcon,
            wrapper,
            state => state.searchable
        );

        this.widgets = [
            {
                componentId: 'SearchWidget' as DataGridToolbarItem,
                hideIcon: true,
                icon: mainIcon,
                label: '',
                name: 'Search',
                store: wrapper.getReduxStore(),
                context: wrapper.getReduxStoreContext(),
                widget: SearchablePluginWidget(wrapper.getReduxStoreContext()),
            },
        ];
    }

    protected internalStop(): void {
        this.wrapper.addQuickFilter('');
    }

    protected stateChangedOrStart(): void {
        const previousPluginState = this.getPreviousPluginState();
        const currentPluginState = this.getPluginState();
        if (
            (!previousPluginState &&
                !stringHelper.isNullOrEmpty(currentPluginState?.currentSearch)) ||
            (previousPluginState &&
                (!stringHelper.isNullOrEmpty(previousPluginState.currentSearch) ||
                    !stringHelper.isNullOrEmpty(currentPluginState?.currentSearch)))
        ) {
            if (currentPluginState) this.wrapper.addQuickFilter(currentPluginState.currentSearch);
        }
    }
}
