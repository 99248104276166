import { Component, Fragment } from 'react';
import { FormGroup } from '@gs-ux-uitoolkit-react/form';
import { Checkbox, CheckboxChangeEvent } from '@gs-ux-uitoolkit-react/checkbox';
import { HintList } from '@gs-ux-uitoolkit-common/datacore';
import { FormatControlProps } from '../column-hint-format-manager';
import { columnFormattingPluginStylePrefix } from '../../../../../style/plugins/column-hint-plugin-stylesheet';
import { Label } from '@gs-ux-uitoolkit-react/label';

export class ColumnHintColumnHeaderWrapping extends Component<FormatControlProps> {
    public render() {
        return (
            <Fragment>
                <div className={`${columnFormattingPluginStylePrefix}_column-header-wrapping`}>
                    <Label>Column Header Wrapping</Label>
                    <FormGroup inline={true}>
                        <Checkbox
                            data-cy="gs-uitk-column-hints_column-header-wrapping"
                            onChange={this.onChangeShowGridLines}
                            checked={!!this.props.columnHint.columnHeaderWrapping}
                        >
                            Enable wrapping
                        </Checkbox>
                    </FormGroup>
                </div>
            </Fragment>
        );
    }

    private onChangeShowGridLines = (e: CheckboxChangeEvent) => {
        const value = e.checkedState === true;
        const clonedHint: HintList = {
            ...this.props.columnHint,
            columnHeaderWrapping: value,
        };
        this.props.onChangeHint(clonedHint);
    };
}
