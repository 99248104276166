import { Size, Orientation } from '@gs-ux-uitoolkit-common/design-system';
import { ComponentProps, CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { StepsCssClasses } from './steps-style-sheet';

/**
 * General configuration for Steps
 */
export interface StepsProps extends ComponentProps<CommonStyleType> {
    /**
     * Style classes to override.
     */
    classes?: Partial<StepsCssClasses>;

    /**
     * The index of the current (active) Step.
     *
     * Defaults to `undefined` for "no current step" (which can be used in
     * conjunction with the individual Step `status` and `expanded` props)
     */
    currentStep?: number;

    /**
     * `true` to fill the step dots (completion indicators) with the color
     * passed to it.
     */
    fillIndicators?: boolean;

    /**
     * When the {@link #orientation} is 'horizontal', moves the labels to be
     * inline with the bars separating steps.
     */
    inline?: boolean;

    /**
     * When the {@link #orientation} is 'vertical', applies styling to show that
     * each Step can be expanded for more content, and collapses all
     * descriptions. Individual Steps can then be expanded with the controlled
     * `expanded` prop to show/hide descriptions individually.
     */
    expandable?: boolean;

    /**
     * When the hideLabels prop is set to true, hides all labels while spanning the steps to the edge of the div container.
     */
    hideLabels?: boolean;

    /**
     * Orientation of the navigation bar.
     */
    orientation?: StepsOrientation;

    /**
     * Size of the steps component instance.
     */
    size?: StepsSize;

    /**
     * Called when a Step is clicked on by the user
     */
    onStepClick?: (event: StepClickEvent) => void;
}

export type StepsSize = Extract<Size, 'sm' | 'md'>;

export type StepsOrientation = Extract<Orientation, 'horizontal' | 'vertical'>;

/**
 * Event object for when a Step is clicked.
 */
export interface StepClickEvent {
    /**
     * The index of the Step that was clicked.
     */
    stepIndex: number;
}

/**
 * Default props for steps
 */
export const stepsDefaultProps: Required<
    Pick<
        StepsProps,
        'inline' | 'expandable' | 'hideLabels' | 'fillIndicators' | 'orientation' | 'size'
    >
> = {
    inline: false,
    expandable: false,
    hideLabels: false,
    fillIndicators: false,
    orientation: 'horizontal',
    size: 'md',
};
