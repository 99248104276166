export const injectKeys = (msg, ...args) => {
  if(args.length > 0){
    args.forEach((value, index) => {
      msg = msg.replace(`@{${index+1}}`, value);
    });
  }
  return msg;
};

export const injectElements = (string, ...elements) => {
  const result = [];
  let stringCopy = string;
  for(let i = 0; i < elements.length; i++) {
    const [firstElement, secondElement] = stringCopy.split(`@{${i+1}}`);
    result.push(firstElement);
    result.push(elements[i]);
    stringCopy = secondElement;
  }

  if(stringCopy.length) {
    result.push(stringCopy);
  }

  return result;
};
