import { math } from 'polished';
import {
    CssPropertyType,
    CssClassDefinitionsObject,
    StyleSheet,
} from '@gs-ux-uitoolkit-common/style';
import {
    Theme,
    ThemeTypographyVariant,
    createComponentClassDefinitions,
} from '@gs-ux-uitoolkit-common/theme';
import { AlertEmphasis, AlertSize, AlertStatus } from './alert-props';
import { fontWeight } from '@gs-ux-uitoolkit-common/design-system';
import { DeepReadonly } from 'ts-essentials';
import './alert-theme-overrides';

export interface AlertStyleSheetProps {
    theme: Theme;
    emphasis: AlertEmphasis;
    size: AlertSize;
    status: AlertStatus;
    dismissible: boolean;
    visible: boolean;
}

export interface AlertCssClasses {
    root: string;
    dismissButton: string;
    icon: string;
}

export type AlertStyledClasses = CssClassDefinitionsObject<keyof AlertCssClasses>;

export interface AlertStyleOverridesParams {
    props: DeepReadonly<AlertStyleSheetProps>;
    createDefaultStyledClasses: () => AlertStyledClasses;
}

/**
 * StyleSheet definition for Alert
 */
export const alertStyleSheet = new StyleSheet('alert', (props: AlertStyleSheetProps) => {
    return createComponentClassDefinitions<AlertStyleSheetProps, AlertStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.alert
    );
});

function createDefaultStyledClasses({
    theme,
    emphasis,
    size,
    status,
    dismissible,
    visible,
}: AlertStyleSheetProps): AlertStyledClasses {
    const {
        rootPaddingRight,
        rootPaddingY,
        rootTypography,
        closeButtonPadding,
        iconFontSize,
        iconLeft,
        iconWidth,
    } = alertSizeVariants[size];

    const interactionShades = theme.getColorInteractionShades(status, emphasis);
    const paddingRight = dismissible
        ? getDismissiblePaddingRight(rootPaddingY, iconFontSize)
        : rootPaddingRight;

    return {
        root: {
            ...theme.typography[rootTypography],

            display: visible ? 'block' : 'none',

            position: 'relative',
            padding: `${rootPaddingY} ${paddingRight} ${rootPaddingY} ${iconWidth}`,
            margin: 0,
            borderRadius: '2px',
            border: 'none',

            // Custom overrides we've added
            'h1, h2, h3, h4, h5, h6, p': {
                margin: '0', // remove the default margin
            },

            a: {
                color: 'inherit',
                fontWeight: fontWeight.medium,
            },

            color: interactionShades.text,
            backgroundColor: interactionShades.background,

            // Provide class for links that match alerts
            '.alert-link, [data-gs-uitk-component="link"]': {
                color: `${interactionShades.text} !important`,
                // Feb 4, 2022:  We needed to remove this because some apps, such as ION Dealbook are using
                //    Marquee Desktop / Openfin and using Chrome 80 where :focus-visible is not supported and
                //    throwing errors.  https://jira.site.gs.com/browse/UX-15248
                // '&:focus, &:focus-visible': {
                '&:focus': {
                    color: 'inherit',
                    boxShadow: `0 0 0 1px ${interactionShades.text}, 0 0 0 0 ${interactionShades.text}`,
                },
            },

            // Provide class for buttons that match alerts
            '.alert-button, [data-gs-uitk-component="button"] .gs-button__button': {
                color: interactionShades.text,
                borderColor: interactionShades.text,
                background: 'transparent',
                '&:hover': {
                    background: 'rgba(255, 255, 255, 0.1)',
                },
                // Feb 4, 2022):  We needed to remove this because some apps, such as ION Dealbook are using
                //    Marquee Desktop / Openfin and using Chrome 80 where :focus-visible is not supported and
                //    throwing errors.  https://jira.site.gs.com/browse/UX-15248
                // '&:focus, &:focus-visible': {
                '&:focus': {
                    outline: 'none',
                    color: interactionShades.text,
                    borderColor: interactionShades.border,
                    background: 'transparent',
                    boxShadow: `0 0 0 1px ${interactionShades.text}, 0 0 0 2px ${interactionShades.text}`,
                },
            },

            // Provide styles for headings in alert
            '.alert-heading, [data-gs-uitk-component="heading"]': {
                color: interactionShades.text,
            },
        },
        dismissButton: {
            position: 'absolute',
            top: 0,
            right: 0,
            margin: 0,
            padding: closeButtonPadding,
        },
        icon: {
            fontSize: iconFontSize,
            position: 'absolute',
            top: '0px',
            left: iconLeft,
            height: '100%',
            paddingTop: rootPaddingY,
            textAlign: 'center',
            color: interactionShades.text,
        },
    };
}

function getDismissiblePaddingRight(
    padding: CssPropertyType['padding'],
    closeFontSize: CssPropertyType['fontSize']
) {
    // TODO: Using hard-coded 'padding' here, but this should come from Theme sizing or override

    // Expand the right padding and account for the close button's positioning
    const paddingValue = String(([] as (string | number)[]).concat(padding)[0]);

    // prettier-ignore
    // (prettier keeps moving the comment on the 3rd line up behind 'paddingTop' for some reason)
    const [
        paddingTop = '0px',
        paddingRight = paddingTop,
        , // paddingBottom = paddingTop,  -- bottom padding not needed
        paddingLeft = paddingRight,
    ] = paddingValue.split(/\s+/);

    return math(`${closeFontSize} + ${paddingLeft} + ${paddingTop}`);
}

const alertSizeVariants: { [name in AlertSize]: AlertSizeVariant } = {
    sm: {
        rootPaddingRight: '8px',
        rootPaddingY: '6px',
        rootTypography: 'body03',
        closeButtonPadding: '3px',

        iconFontSize: '16px',
        iconLeft: '6px',
        iconWidth: '30px',
    },
    md: {
        rootPaddingRight: '12px',
        rootPaddingY: '14px',
        rootTypography: 'body02',
        closeButtonPadding: '9px',

        iconFontSize: '20px',
        iconLeft: '12px',
        iconWidth: '44px',
    },
    lg: {
        rootPaddingRight: '18px',
        rootPaddingY: '16px',
        rootTypography: 'body01',
        closeButtonPadding: '7px',

        iconFontSize: '24px',
        iconLeft: '14px',
        iconWidth: '52px',
    },
};

interface AlertSizeVariant {
    rootPaddingRight: CssPropertyType['paddingRight'];
    rootPaddingY: CssPropertyType['padding'];
    rootTypography: ThemeTypographyVariant;
    closeButtonPadding: CssPropertyType['padding'];

    iconFontSize: CssPropertyType['fontSize'];
    iconLeft: CssPropertyType['left'];
    iconWidth: CssPropertyType['width'];
}
