import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-react/style';
import { elevationVariants } from '@gs-ux-uitoolkit-react/design-system';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DragAndDropHandleVisibility, DragAndDropIconPosition } from './draggable-props';
import { DeepReadonly } from 'ts-essentials';
import './draggable-theme-overrides';

export interface DraggableStyleSheetProps {
    theme: Theme;
    spacing: number;
    dragIconVisibility: DragAndDropHandleVisibility;
    disabled: boolean;
    iconPosition?: DragAndDropIconPosition;
}

export interface DraggableCssClasses {
    box: string;
    boxInner: string;
    boxInnerDrag: string;
}

export type DraggableStyledClasses = CssClassDefinitionsObject<keyof DraggableCssClasses>;

export interface DraggableStyleOverridesParams {
    props: DeepReadonly<DraggableStyleSheetProps>;
    createDefaultStyledClasses: () => DraggableStyledClasses;
}

export const draggableStyleSheet = new StyleSheet(
    'draggable',
    (props: DraggableStyleSheetProps) => {
        return createComponentClassDefinitions<DraggableStyleSheetProps, DraggableStyledClasses>(
            props,
            createDefaultStyledClasses,
            props.theme.styleOverrides?.draggable
        );
    }
);

function createDefaultStyledClasses({
    theme,
    spacing,
    dragIconVisibility,
    disabled,
    iconPosition,
}: DraggableStyleSheetProps): DraggableStyledClasses {
    return {
        box: {
            position: 'relative',
            display: 'block',
            padding: spacing,
        },
        boxInner: {
            backgroundColor: theme.surface.primary,
            position: 'relative',
            display: 'flex',
            flexDirection: iconPosition === 'leading' ? 'row-reverse' : 'row',
            justifyContent: iconPosition === 'leading' ? 'flex-end' : 'space-between',
            /*
             * If parameter dragIconVisibility === 'onHover' showing icon only when user hover droppable
             * If parameter dragIconVisibility === 'visible' this doesn't change anything
             * If parameter dragIconVisibility === 'none', the DragAndDropHandle is not mounted and for the style it doesn't apply
             */
            '&:hover .gs-uitk-component-draggable-handle': {
                opacity: disabled ? 0.3 : 1,
            },
            '.gs-uitk-component-draggable-handle:hover': {
                opacity: disabled ? 0.3 : 1,
            },
            '.gs-uitk-component-draggable-handle:focus': {
                opacity: disabled ? 0.3 : 1,
            },
            '.gs-uitk-component-draggable-handle': {
                opacity: dragIconVisibility === 'visible' ? (disabled ? 0.3 : 1) : 0,
            },
            '.gs-uitk-component-draggable-handle:focus-visible:not(:disabled)': {
                outline: `1px solid ${theme.text.link}`,
                outlineOffset: '-1px',
            },
            '&:focus-visible:not(:disabled)': {
                outline: `1px solid ${theme.text.link}`,
                outlineOffset: '-1px',
            },
        },
        boxInnerDrag: {
            ...elevationVariants['03'],
            border: `1px solid ${theme.colorScheme.primary}`,
            '.gs-uitk-component-draggable-handle': {
                opacity: disabled ? 0.3 : 1,
            },
            '.gs-uitk-component-draggable-handle:focus-visible:not(:disabled)': {
                outline: 'none',
            },
        },
    };
}
