import useFetch from '../useFetch';
import useSnackBar from '../useSnackbar';
import endPointsMapper from '../../../../../configs/endPointsMapper';

export default function useGetFirmsToMapList() {
  const { openSnackbar } = useSnackBar();

  const onError = () => {
    openSnackbar({
      type: 'error',
      message: 'Failed to fetch unmapped firms list',
    });
  };

  const {
    doFetch: getAvailableFirmsToMap,
    loading: loadingFirmsList,
    data: firmsAvailableToMap,
  } = useFetch(endPointsMapper.FETCH_UNMAPPED_FIRMS, undefined, onError);
  return { getAvailableFirmsToMap, firmsAvailableToMap, loadingFirmsList };
}
