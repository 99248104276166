import { Button, ButtonActionType } from '@gs-ux-uitoolkit-react/button';
import { Component } from 'react';
import { pluginFooterStyleSheet } from '../../style/component/plugin-footer-stylesheet';

export interface PluginFooterProps {
    createButtonDisplayName: string;
    onCreate: () => void;
    onCancel: () => void;
    cancelButtonDisplayName?: string;
    createDisabled?: boolean;
    createActionType?: ButtonActionType;
}
export class PluginFooter extends Component<PluginFooterProps> {
    private onClick = () => this.props.onCreate();
    private onCancelClick = () => this.props.onCancel();

    public componentWillUnmount() {
        pluginFooterStyleSheet.unmount(this);
    }
    public render() {
        const cssClasses = pluginFooterStyleSheet.mount(this, null);
        return (
            <div className={`gs-uitk-plugin-screen-footer-container ${cssClasses.root}`}>
                <Button
                    onClick={this.onCancelClick}
                    value="Cancel"
                    data-cy="gs-uitk-plugin-screen-footer__cancel-btn"
                >
                    {this.props.cancelButtonDisplayName || 'Cancel'}
                </Button>
                <Button
                    actionType={this.props.createActionType || undefined}
                    onClick={this.onClick}
                    disabled={this.props.createDisabled || false}
                    value="Create"
                    data-cy="gs-uitk-plugin-screen-footer__create-btn"
                >
                    {this.props.createButtonDisplayName}
                </Button>
            </div>
        );
    }
}
