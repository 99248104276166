import { PluginBase, PluginIcon } from '@gs-ux-uitoolkit-common/datacore';
import { ModuleIdentfier } from '../module-identfier';
import { DebouncedFunc, debounce } from 'gs-uitk-lodash';
import { DataUpdatedEventArgs, GridWrapper } from '../../grid-wrappers/grid-wrapper';
import { DataGridState, MaskedColumnState } from '../../redux/datagrid-state';
import { Categories, Plugins } from '../plugin-enum';

const mainIcon: PluginIcon = { name: 'visibility-off', type: 'filled' };

/**
 * The Masked Column plugin. Mask a column when one of the value being rendered is truncated
 */
export class MaskedColumnPlugin extends PluginBase<GridWrapper, DataGridState, MaskedColumnState> {
    protected static requiredModules: ModuleIdentfier[] = [];
    private debouncedMaskedColumnMap: Map<string, DebouncedFunc<() => void>> = new Map();

    constructor(wrapper: GridWrapper) {
        super(
            Plugins.MaskedColumnPlugin,
            Categories.Data,
            mainIcon,
            wrapper,
            state => state.maskedColumn
        );
    }

    protected stateChangedOrStart(): void {
        this.unsubscribeEvents();
        if (this.getPluginState()?.enabled) {
            this.wrapper.columnResized.subscribe(this.columnResizedFunc);
            this.wrapper.gridScrolled.subscribe(this.gridScrolledFunc);
            this.wrapper.dataUpdated.subscribe(this.dataUpdatedFunc);
            this.wrapper.columnPinned.subscribe(this.columnPinnedFunc);

            this.getPluginState()?.columnIdList.forEach(colId => {
                const debouncedFunc = debounce(() => {
                    this.wrapper.hasColumnTruncatedValues(colId)
                        ? this.wrapper.maskColumn(colId)
                        : this.wrapper.unmaskColumn(colId);
                }, 200);
                this.debouncedMaskedColumnMap.set(colId, debouncedFunc);
                debouncedFunc();
            });
        }
    }

    protected internalStop(): void {
        this.unsubscribeEvents();
    }

    private unsubscribeEvents() {
        this.wrapper.columnResized.unsubscribe(this.columnResizedFunc);
        this.wrapper.gridScrolled.unsubscribe(this.gridScrolledFunc);
        this.wrapper.dataUpdated.unsubscribe(this.dataUpdatedFunc);
        this.wrapper.columnPinned.unsubscribe(this.columnPinnedFunc);
        this.debouncedMaskedColumnMap.forEach(x => x.cancel());
        this.debouncedMaskedColumnMap.clear();
    }

    private columnResizedFunc = () => this.columnResized();
    private columnResized(): void {
        // when we resize we need to replace all the Masks as they have an obsolute position
        this.getPluginState()?.columnIdList.forEach(columnId => {
            this.wrapper.unmaskColumn(columnId);
            const debouncedFunction = this.debouncedMaskedColumnMap.get(columnId);
            if (debouncedFunction) {
                debouncedFunction();
            }
        });
    }

    private gridScrolledFunc = () => this.gridScrolled();
    private gridScrolled(): void {
        this.getPluginState()?.columnIdList.forEach(columnId => {
            const debouncedFunction = this.debouncedMaskedColumnMap.get(columnId);
            if (debouncedFunction) {
                debouncedFunction();
            }
        });
    }

    private dataUpdatedFunc = (arg: DataUpdatedEventArgs) => this.dataUpdated(arg);
    private dataUpdated(arg: DataUpdatedEventArgs): void {
        if (this.getPluginState()?.columnIdList.includes(arg.columnId)) {
            const debouncedFunction = this.debouncedMaskedColumnMap.get(arg.columnId);
            if (debouncedFunction) {
                debouncedFunction();
            }
        }
    }

    private columnPinnedFunc = () => this.columnPinned();
    private columnPinned(): void {
        // when we pin a column we need to replace all the Masks as they have an obsolute position
        this.getPluginState()?.columnIdList.forEach(columnId => {
            this.wrapper.unmaskColumn(columnId);
            const debouncedFunction = this.debouncedMaskedColumnMap.get(columnId);
            if (debouncedFunction) {
                debouncedFunction();
            }
        });
    }
}
