import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../components/app/PageHeader';
import PreferenceSections from '../../containers/Preferences';
import translator from '../../services/translator';
import './index.scss';
const {translate: t} = translator;

const Preferences = ({subRoutes}) => (
  <div className="preferences">
    <PageHeader title={t('tkNavPreference')} />
    <PreferenceSections routes={subRoutes} />
  </div>
);

Preferences.propTypes = {
  subRoutes: PropTypes.array
};

export default Preferences;
