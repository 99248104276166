import { StyleSheet } from '@gs-ux-uitoolkit-react/style';

export const rowCustomisationPluginStyleSheet = new StyleSheet(
    'gs-uitk-style-builder-preview ',
    ({ height, backgroundColor }: { height?: string; backgroundColor?: string }) => {
        return {
            root: {
                overflowX: 'hidden',
            },
            rowCustomisationModal: {
                '.gs-layout-row': {
                    maxWidth: '100%',
                },
                '.gs-uitk-html-select': {
                    width: '100% !important',
                    maxWidth: 'none !important',
                    backgroundColor: 'transparent !important',
                    border: 'none !important',
                    padding: '0 !important',
                },
                '.expression-builder-root': {
                    '.gs-tabs__content': {
                        overflow: 'visible',
                    },
                },
                '.config-items-table': {
                    table: {
                        height: '100%',
                        td: {
                            '.gs-uitk-row-customisation-preview': {
                                // RowCustomisationPreviewer should only fill the full height when within a table
                                height: '100% !important',
                            },
                        },
                    },
                },
                '.gs-color-picker': {
                    flex: 1,
                },
                '.gs-uitk-color-setter': {
                    display: 'flex',
                    alignItems: 'center',
                    a: {
                        marginLeft: '0.5rem',
                    },
                },
            },
            rowCustomisationPreview: {
                '&.gs-uitk-row-customisation-preview': {
                    margin: '0',
                    height: height,
                    div: {
                        height: '100%',
                    },
                    '.gs-uitk-row-customisation-preview-cell': {
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: backgroundColor,
                    },
                },
            },
        };
    }
);
