import { FunctionComponent } from 'react';
import {
    getDividerClass,
    MenuDividerProps as MenuDividerCommonProps,
    menuDividerStyleSheet,
} from '@gs-ux-uitoolkit-common/menu';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';

export interface MenuDividerProps extends MenuDividerCommonProps {}

export const MenuDivider: FunctionComponent<MenuDividerProps> = (props: MenuDividerProps) => {
    const { className, classes: overrideClasses, ...otherProps } = props;
    const theme = useTheme();
    const cssClasses = useStyleSheet(menuDividerStyleSheet, { theme });
    return (
        <hr
            data-gs-uitk-component="menu-divider"
            tabIndex={-1}
            className={getDividerClass({ cssClasses, className, overrideClasses })}
            {...otherProps}
        />
    );
};
