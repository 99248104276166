import { StyleSheet, CssClasses, cx } from '@gs-ux-uitoolkit-react/style';

export type SearchWidgetCssClasses = CssClasses<typeof searchWidgetStyleSheet>;

export const searchWidgetStyleSheet = new StyleSheet('searchWidget', () => {
    return {
        root: {
            '&.uitk-search-input-wrapper': {
                width: '100%',
            },
        },
    };
});
export const getSearchInputWrapperClassNames = (props: {
    cssClasses: SearchWidgetCssClasses;
}): string => {
    const { cssClasses } = props;

    return cx(cssClasses.root, `uitk-search-input-wrapper`);
};
