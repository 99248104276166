import { FC } from 'react';
import { Theme, injectFontsForTheme } from '@gs-ux-uitoolkit-common/theme';
import { useEmotionInstance } from '@gs-ux-uitoolkit-react/style';
import { ThemeContext } from './theme-context';

export interface ThemeConsumerProps {
    children: (theme: Theme) => ReturnType<FC>;
}

export const ThemeConsumer: FC<ThemeConsumerProps> = ({ children }) => {
    // For SSR in Next.js's App Router, we need to use a context-provided
    // Emotion instance provided by <AppRouterStyleRegistry> in the
    // '@gs-ux-uitoolkit-react/next' package. Otherwise this will be the global
    // Emotion instance.
    const emotionInstance = useEmotionInstance();

    // Note: had this as simply returning children(theme) and using the
    // `useTheme` hook to grab the theme, but this broke Enzyme in the datagrid
    // tests. Once we have the datagrid tests onto React Testing Library, we
    // should be able to go back
    return (
        <ThemeContext.Consumer>
            {(theme: Theme) => {
                // Inject any fonts into the DOM that the Theme requires
                injectFontsForTheme(theme, { emotionInstance });

                return children(theme);
            }}
        </ThemeContext.Consumer>
    );
};
