import { InputNumber, InputSize } from '@gs-ux-uitoolkit-react/input';
import { FormEvent } from 'react';
import { debounce } from 'gs-uitk-lodash';
import { defaultDebounceTime } from '../../../../grid-wrappers/grid-wrapper';

export interface NumberRangeControlProps {
    value?: string;
    defaultValue?: string;
    callback: (event: FormEvent<HTMLInputElement>) => void;
    callbackOnlyOnBlur?: boolean;
    disabled?: boolean;
    className?: string;
    size?: InputSize;
}
export const NumberRangeControl = (props: NumberRangeControlProps) => {
    // Remove props that we don't want to pass down
    const { callback, callbackOnlyOnBlur, ...inputProps } = props;

    const debouncedSaved = debounce((event: FormEvent<HTMLInputElement>) => {
        props.callback(event);
    }, defaultDebounceTime);
    return (
        <InputNumber
            placeholder="Enter number"
            {...inputProps}
            data-cy={`${props.className}-number-input`}
            className={`expression-range-input-value ${props.className || ''}`}
            onChange={(event: FormEvent<HTMLInputElement>) =>
                !props.callbackOnlyOnBlur ? debouncedSaved(event) : null
            }
            onBlur={(event: FormEvent<HTMLInputElement>) => props.callback(event)}
        />
    );
};
