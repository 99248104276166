import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Conditional} from "../../../../../core/Conditional";
import {
  REBATE_NO_ERROR,
  REBATE_NOT_A_NUMBER,
  REBATE_OUT_OF_RANGE,
  REBATE_OUT_OF_RANGE_AND_EXCEEDS_PRECISION
} from "../../constants";
import "./index.scss"
import {eventAnalytics} from "../../../../../../actions/sagas";
import {getRebateErrorAnalyticsData} from "../../../../../../utils/portfolioAnalytics";

const ErrorMessage = ({labels, errorState, sendAnalytics}) => {

  const {tkRebateAmountRangeError, tkRebateAmountDecimalPointsError } = labels;

  const renderErrorMessages = () => {
    if (errorState === REBATE_NOT_A_NUMBER || errorState === REBATE_OUT_OF_RANGE) {
      sendAnalytics(getRebateErrorAnalyticsData(tkRebateAmountRangeError));
      return <span>{tkRebateAmountRangeError}</span>;
    } else if (errorState === REBATE_OUT_OF_RANGE_AND_EXCEEDS_PRECISION) {
      sendAnalytics(getRebateErrorAnalyticsData(tkRebateAmountRangeError + tkRebateAmountDecimalPointsError))
      return (
        <ul>
          <li><span>{tkRebateAmountRangeError}</span></li>
          <li><span>{tkRebateAmountDecimalPointsError}</span></li>
        </ul>
      );
    }
    if (errorState !== REBATE_NO_ERROR) {
      sendAnalytics(getRebateErrorAnalyticsData(tkRebateAmountDecimalPointsError))
    }
    return <span>{tkRebateAmountDecimalPointsError}</span>;
  };

  return (
    <Conditional condition={errorState !== REBATE_NO_ERROR}>
      <div className='fund-rebate-error-message'>
        {renderErrorMessages()}
      </div>
    </Conditional>
  );
};

ErrorMessage.propTypes = {
  labels: PropTypes.object,
  errorState: PropTypes.number,
  sendAnalytics: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  sendAnalytics: (data) => dispatch(eventAnalytics(data))
});

export default connect(
  null,
  mapDispatchToProps
)(ErrorMessage);
