import { LoadingOverlayCssClasses } from './loading-overlay-style-sheet';
import { LoadingOverlayProps } from './loading-overlay-props';
import { cx } from '@gs-ux-uitoolkit-common/style';

export const getLoadingOverlayClasses = (props: {
    cssClasses: LoadingOverlayCssClasses;
    overrideClasses?: LoadingOverlayProps['classes'];
    className?: LoadingOverlayProps['className'];
}): string => {
    const { cssClasses, className, overrideClasses } = props;
    return cx(
        cssClasses.root,
        className,
        overrideClasses && overrideClasses.root,
        'gs-loading-overlay'
    );
};
