/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle} from 'react';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';
import cn from 'classnames';
import ModalPortal from './ModalPortal';
import './index.scss';
import {Conditional} from '../Conditional';
import {toggleBrowserScrollVisiblilty} from '../../../utils/dom';
import { ThreeSquaresLoader } from '../Loaders';
export const Modal = forwardRef((props, ref) => {

  const { animation, animationTiming, suppressScrollOnActive, isCloseDisabled,
    children, customClass, animationClassClose, animationClassOpen, open, hideCloseCTA,
    customModalStyle, customBackdropStyle, closeIcon, backdropCustomClass, isLoading,
    overrideCloseHandler, closeOnOutsideClick, testId} = props;

  const closeButtonRef = useRef();
  const [isOpen, setIsOpen] = useState(open);
  const [isOpenAnimation, setIsOpenAnimation] = useState(true);
  const modalRef = useRef();

  const closeModal = (callback) => {
    if (!isCloseDisabled) {
      setIsOpenAnimation(false);
      if (animation) {
        setTimeout(() => {
          callback && callback();
          setIsOpen(false);
          suppressScrollOnActive && toggleBrowserScrollVisiblilty(false);
        }, animationTiming);
      } else {
        setIsOpen(false);
        suppressScrollOnActive && toggleBrowserScrollVisiblilty(false);
        callback && callback();
      }
    }
  };


  useEffect(() => {
    const handleClick = (event) => {
      event.stopPropagation();

      if((document.getElementsByClassName('modal').length > 1) ||
        (event.target && event.target.classList.value.includes('icon-x')) ){// prevent close on outside click if another modal is present
        return false;
      }
      const clickedOnComponent = modalRef && modalRef.current.contains(event.target);
      if(!clickedOnComponent) closeModal(props.handleClose);
      return false;
    };
    closeOnOutsideClick && window.addEventListener('click', handleClick);
    return () => {
      closeOnOutsideClick && window.removeEventListener('click', handleClick);
    };
  }, []);

  useImperativeHandle(ref, () => ({
    closeModal
  }));

  useEffect(() => {
    if (open) {
      suppressScrollOnActive && toggleBrowserScrollVisiblilty(open);
      setIsOpen(true);
      setIsOpenAnimation(true);
      closeButtonRef.current && closeButtonRef.current.focus();
    } else {
      closeModal(props.onClose);
    }
  }, [open]);

  // called when closed using Cross button
  const handleClick = () => {
    if(overrideCloseHandler !== null){
      overrideCloseHandler();
    } else {
      closeModal(props.handleClose);
    }
  };

  const handleKeyDown = (e) => {
    const {keyCode} = e;
    if (keyCode === 13 || keyCode === 32) {
      if (keyCode === 32) e.preventDefault();
      closeModal(props.handleClose);
    }
  };

  const className = animation ? (!isOpenAnimation ? animationClassClose : animationClassOpen) : '';
  return (
    <Conditional condition={isOpen}>
      <ModalPortal>
        <FocusLock>
          <div ref={modalRef} data-testid={testId} className={`modal ${customClass} ${className}`} style={customModalStyle}>
            <Conditional condition={!hideCloseCTA}>
              <div data-testid={`${testId}-icon-close`} className={cn('modal__close', {'modal__close--disabled': isCloseDisabled})} ref={closeButtonRef} onClick={handleClick} tabIndex="0" onKeyDown={handleKeyDown}>
                {closeIcon || <span className="icon-x-large" /> }
              </div>
            </Conditional>
            <div className={`modal__body ${isLoading ? 'loading' : ''}`}>
              {Array.isArray(children) ? children.map(child => child) : children}
            </div>
            <Conditional condition={!!isLoading}>
              <div className="modal__loading">
                <ThreeSquaresLoader />
              </div>
            </Conditional>
          </div>
          <Conditional condition={isOpen}>
            <div className={`${isOpen ? 'backdrop backdrop--open' : 'backdrop backdrop--close'} ${backdropCustomClass}`}  style={customBackdropStyle} />
          </Conditional>
        </FocusLock>
      </ModalPortal>
    </Conditional>
  );

});


Modal.defaultProps = {
  customClass: 'modal--right',
  animationTiming: 320,
  animation: false,
  animationClassClose: 'animation-slide-out-right',
  animationClassOpen: 'animation-slide-in-right',
  backdropCustomClass: '',
  suppressScrollOnActive: false,
  isCloseDisabled: false,
  hideCloseCTA: false,
  closeOnOutsideClick: false,
  overrideCloseHandler: null,
  testId: 'modal'
};

Modal.propTypes = {
  handleClose: PropTypes.func,
  children: PropTypes.any,
  open: PropTypes.bool,
  customClass: PropTypes.string,
  animationClassClose: PropTypes.string,
  animationClassOpen: PropTypes.string,
  animation: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
  animationTiming: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
  ]),
  customModalStyle: PropTypes.object,
  customBackdropStyle: PropTypes.object,
  closeIcon: PropTypes.element,
  backdropCustomClass: PropTypes.string,
  suppressScrollOnActive: PropTypes.bool,
  isCloseDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  hideCloseCTA: PropTypes.bool,
  overrideCloseHandler: PropTypes.func,
  testId: PropTypes.string
};

export default Modal;
