import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { selectCollapseState, preDefinedSortingState, getCurrentViewsKeys, gridHeader,
  currentViewSelector, getGridData, noRowDataSelector, selectedGroup } from '../../../../selectors/pages/tradeActivity/currentTrades';
import GridModule from '../../../../modules/Grid';
import { gridData, startExcelExport } from '../../../../modules/Grid/selector'

export const TradeActivityGrid = (props) => {

  const groupRowAggNodes = data => () => data;

  const gridConfig = {
    groupRowAggNodes: groupRowAggNodes(props.currentViewsKeys),
    floatingFiltersHeight: 0
  };

  return <GridModule gridConfig={gridConfig} {...props} />;
};

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  return {
    collapseState: selectCollapseState(state, id),
    currentViewsKeys: getCurrentViewsKeys(state, id),
    columnDefs: gridHeader(state, id),
    data: getGridData(state, id),
    currentView: currentViewSelector(state, id),
    preDefinedSortingState: preDefinedSortingState(state, id),
    gridRawData: gridData(state, id),
    startExcelExport: startExcelExport(state, id),
    noRowDataSelector: noRowDataSelector(state, id),
    selectedGroup : selectedGroup(state, id)
  }
}

TradeActivityGrid.propTypes = {
  collapseState: PropTypes.bool,
  currentViewsKeys: PropTypes.object,
  gridRawData: PropTypes.array,
  columnDefs: PropTypes.array,
  data: PropTypes.array,
  currentView: PropTypes.object,
  preDefinedSortingState: PropTypes.array,
  startExcelExport: PropTypes.object,
  noRowDataSelector: PropTypes.string,
  selectedGroup: PropTypes.object
};

export default connect(mapStateToProps)(TradeActivityGrid);
