import { v11ToV12IconMapping } from './v11-to-v12-icon-mapping';
import { LegacyV12IconName } from '../generated-legacy-snapshot/v12-icon-types';

/**
 * @const {object} allIconMappings - Object containing all icon name mappings
 */
const legacyAllIconMappings: { [key: string]: LegacyV12IconName } = {
    ...(v11ToV12IconMapping.solid.icons as { [key: string]: LegacyV12IconName }),
    ...(v11ToV12IconMapping.regular.icons as { [key: string]: LegacyV12IconName }),
    ...(v11ToV12IconMapping.brands.icons as { [key: string]: LegacyV12IconName }),
    ...(v11ToV12IconMapping.aliases.icons as { [key: string]: LegacyV12IconName }),
};

/**
 * Resolves Font Awesome names to Material Design Icon names
 *
 * @example
 * // returns 'calendar-blank'
 * legacyResolveV12IconName('calendar');
 *
 * @example
 * // returns 'bookmark'
 * legacyResolveV12IconName('bookmark');
 *
 * @example
 * // returns 'my-custom-icon'
 * legacyResolveV12IconName('my-custom-icon');
 *
 * @param {string} iconName - Name of Font Awesome Icon to resolve to Material Design Icons Icon
 * @returns {string} The name of the Material Design Icon
 */
export function legacyResolveV12IconName(iconName: string): LegacyV12IconName {
    return legacyAllIconMappings[iconName] || iconName;
}
