import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const RadioButton = (props) => {
  const { id, label, value, customClass, isChecked, isDisabled, changeHandler, name} = props;
  const className = ['radioButton-container'];
  if (customClass) {
    className.push(customClass);
  }
  if (isChecked) {
    className.push('radioButton-container--active');
  }
  if(isDisabled){
    className.push('disabled');
  }

  return (
    <div className={className.join(' ')}>
      <label htmlFor={id} className="radioButton-container__label">
        <input
          type="radio"
          id={id}
          value={value}
          checked={isChecked}
          name={name || value}
          onChange={(event) => changeHandler(event, !isChecked, props)}
          disabled={isDisabled}
          className='visually-hidden radioButton-container__input'
        />
        <span className={isChecked ? 'icon-checkbox-filled' : 'icon-checkbox-outlined'} />
        {label}
      </label>
    </div>
  );
};

RadioButton.defaultProps = {
  changeHandler: () => false
};

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  changeHandler: PropTypes.func,
  value: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  customClass: PropTypes.string,
  name: PropTypes.any
};

export default RadioButton;
