import map from 'lodash/map';
import {
  sortChange, columnWidthChanged, columnRemoved, columnMoved,
  applyFilter
} from '../../../actions/page/portfolio';
import {addPageContext} from '../../../actions/page/index';
import {dispatchAnalytics, getColumnSortingAnalyticsData, getColumnSearchAnalyticsData,
  getGridEndLoadAnalyticsData, getColumnRearrangeAnalyticsData,
  getColumnRemoveAnalyticsData} from '../analytics';
import {
  getNewOrderedColumns,
  getNewVisibleColumns,
  getNewWidthedColumns,
  getSortParams
} from '../../../helpers/gridFund';
import {addToTradeBlotter, removeFromTradeBlotter} from '../../../actions/sagas';


const mapDispatchToProps = (dispatch) => ({
  dispatchSortChange: (colId, columnName, order, props) => {
    dispatchAnalytics(dispatch, getColumnSortingAnalyticsData(order, columnName));
    const params = getSortParams(colId, order, props);
    return dispatch(sortChange(params));
  },

  dispatchCellClick: (event) => {
    const accountDetailsColumns = ['accountName', 'taAccountNumber', 'account'];
    if(event.colDef.colId === 'fundName' && event.data){
      if(event.data.productLink){
        window.open(event.data.productLink, '_blank');
      } else { // Set active FundFact in order to open fund fact card model
        event.data.factCardType = "Fund";
        dispatch(addPageContext({activeFundFact: event.data}));
      }
    } else if (accountDetailsColumns.includes(event.colDef.colId) && event.data && event.colDef.cellRenderer) {
      event.data.factCardType = "AccountDetail";
      dispatch(addPageContext({activeFundFact: event.data}));
    }
  },

  dispatchPageChange: data => dispatch(sortChange(data)),

  dispatchRowSelectionChange: (rowSelected, node, props) => {
    console.log(rowSelected, node, props);
    // send analytics only implementation is already in PortfolioGridComp
  },

  dispatchColumnMoved: (columns, params, props) => {
    const data = getNewOrderedColumns(columns, props.currentView);
    dispatch(columnMoved(data));
    dispatchAnalytics(dispatch, getColumnRearrangeAnalyticsData(params.column.colDef.headerName));
  },

  dispatchFilterChange: (filterModel) => {
    const filters = map(filterModel, (obj, key) => ({field: key, term: obj.filter}));
    dispatch(applyFilter({filters, filterModel}));
  },

  dispatchFilterFocus: data => {
    dispatchAnalytics(dispatch, getColumnSearchAnalyticsData(data.colDef.headerName));
  },
  dispatchGroupExpandCollapseChange: data => {
    dispatch(sortChange(data));
  },
  dispatchGridLoadEnd: (event, totalRecords, columns, selectedView, filterBy, time, snackbarList) => {
    dispatchAnalytics(dispatch, getGridEndLoadAnalyticsData(event, columns, selectedView, totalRecords, filterBy, time, snackbarList));
  },
  dispatchColumnWidthChanged: (columns, props) => {
    const {currentView} = props;
    const updatedView = getNewWidthedColumns(columns, currentView);
    dispatch(columnWidthChanged(updatedView));
  },
  dispatchColumnRemove: (colId, visibility, props) => {
    const {currentView, columnDefs} = props;
    // const column = currentView.columns.find(column => column.colId === colId);
    const updatedView = getNewVisibleColumns(colId, currentView);
    dispatch(columnRemoved(updatedView));
    const updatedColumnDefs = columnDefs.filter(column => column.colId !== colId);
    dispatchAnalytics(dispatch, getColumnRemoveAnalyticsData(updatedColumnDefs, colId, updatedView.label));
  },
  dispatchAddTrade: data => dispatch(addToTradeBlotter(data)),
  dispatchRemoveTrade: data => dispatch(removeFromTradeBlotter(data))
});

export default mapDispatchToProps;
