import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../../../../../../components/core/Grid';
import { TABS } from '../../UserDetails/TabSwitchBar';
import './index.scss';

const ChangeManagementDetailsGrid = props => {
  const { gridData, colDef, tab, setProfileGridApi, setAccountGridApi } = props;

  const onGridReady = params => {
    if (tab === TABS.PROFILE) {
      setProfileGridApi(params.api);
    } else {
      setAccountGridApi(params.api);
    }
    params.api.sizeColumnsToFit();
  };

  const config = {
    filter: true,
    floatingFilter: true,
    resizable: true,
    sortable: true,
    sortingOrder: ['desc', 'asc'],
  };

  return (
    <div className='changeManagementDetails__container' data-testid='changeMgmtDetails-grid'>
      <Grid
        rowData={gridData}
        columnDefs={colDef}
        id={'changeManagementDetails'}
        onGridReady={onGridReady}
        config={config}
        gsToolKit={true}
        onFilterChanged={() => {}}
      />
    </div>
  );
};

ChangeManagementDetailsGrid.propTypes = {
  gridData: PropTypes.array,
  colDef: PropTypes.array,
  tab: PropTypes.string,
  setProfileGridApi: PropTypes.func,
  setAccountGridApi: PropTypes.func,
};

export default ChangeManagementDetailsGrid;
