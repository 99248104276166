import {
    DOMAttributes,
    CSSProperties,
    FunctionComponent,
    useMemo,
    useEffect,
    ReactHTML,
} from 'react';
import PropTypes from 'prop-types';
import {
    ContainerProps as CommonContainerProps,
    containerStyleSheet,
    LayoutContext as CommonLayoutContext,
    getContainerClassNames,
    layoutDensities,
    defaultContainerProps,
    LayoutColumns,
} from '@gs-ux-uitoolkit-common/layout';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { LayoutContext } from './layout-context';
import { componentAnalytics } from './analytics-tracking';

export interface ContainerProps
    extends DOMAttributes<HTMLElement>,
        CommonContainerProps<CSSProperties> {
    /**
     * Specifies the container HTML tag. Default is `div`.
     */
    tag?: string;
}

export const Container: FunctionComponent<ContainerProps> = (props: ContainerProps) => {
    const {
        className,
        breakpoints,
        grid,
        density = defaultContainerProps.density,
        maxWidth,
        noGutters = defaultContainerProps.noGutters,
        tag: Tag = 'div',
        ...otherProps
    } = props;

    const layoutContext: CommonLayoutContext = useMemo(
        () => ({
            breakpoints,
            grid,
            density,
            noGutters,
        }),
        [breakpoints, grid, density, noGutters]
    );

    const theme = useTheme();
    const cssClasses: any = useStyleSheet(containerStyleSheet, {
        theme,
        breakpoints,
        grid,
        density,
        maxWidth,
    });
    const rootClasses = getContainerClassNames({ cssClasses, className });

    const TypedTag = Tag as keyof ReactHTML;

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({
            componentName: 'container',
            officialComponentName: 'other',
        });
    }, []); // Only run once

    return (
        <LayoutContext.Provider value={layoutContext}>
            <TypedTag
                {...otherProps}
                className={rootClasses}
                data-gs-uitk-component="container"
                data-density={density}
            />
        </LayoutContext.Provider>
    );
};

export const breakpointPropType = PropTypes.shape({
    minWidth: PropTypes.number.isRequired,
    maxWidth: PropTypes.number.isRequired,
}).isRequired;

export const breakpointsPropType = PropTypes.shape({
    xs: breakpointPropType,
    sm: breakpointPropType,
    md: breakpointPropType,
    lg: breakpointPropType,
    xl: breakpointPropType,
    xxl: breakpointPropType,
    xxxl: breakpointPropType,
});

const columnsPropType: PropTypes.Validator<LayoutColumns> = (
    props: any,
    propName: string,
    componentName: string
) => {
    const value = props[propName];
    if (value === undefined) {
        return null;
    }
    const num = +value;
    if (isNaN(num) || num < 1 || num > 12) {
        return new Error(
            `Invalid prop "${propName}" supplied to "${componentName}". Validation failed.`
        );
    }
    return null;
};

export const gridBreakpointType = PropTypes.shape({
    margin: PropTypes.number.isRequired,
    gutter: PropTypes.number.isRequired,
    columns: columnsPropType,
}).isRequired;

export const gridPropType = PropTypes.shape({
    xs: gridBreakpointType,
    sm: gridBreakpointType,
    md: gridBreakpointType,
    lg: gridBreakpointType,
    xl: gridBreakpointType,
    xxl: gridBreakpointType,
    xxxl: gridBreakpointType,
});

Container.propTypes = {
    breakpoints: breakpointsPropType,
    grid: gridPropType,
    density: PropTypes.oneOf(layoutDensities),
    maxWidth: PropTypes.number,
    noGutters: PropTypes.bool,
    tag: PropTypes.string,
};
