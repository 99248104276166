import {
    Density,
    Size,
    TypographyVariant,
    typographyVariants,
} from '@gs-ux-uitoolkit-common/design-system';

export type DataToolbarDensity = Extract<Density, 'extraCompact' | 'compact' | 'standard'>;
export const DATA_TOOLBAR_DENSITIES: Readonly<DataToolbarDensity[]> = [
    'extraCompact',
    'compact',
    'standard',
];

export type DataToolbarSize = Extract<Size, 'sm' | 'md'>;
export const DATA_TOOLBAR_SIZES: Readonly<DataToolbarSize[]> = ['sm', 'md'];

export type DataGridDensity = Extract<Density, 'extraCompact' | 'compact' | 'standard'>;
export const DATAGRID_DENSITIES: Readonly<DataGridDensity[]> = [
    'extraCompact',
    'compact',
    'standard',
];

export type DataGridSize = Extract<Size, 'sm' | 'md' | 'lg' | 'xs'>;
export const DATAGRID_SIZES: Readonly<DataGridSize[]> = ['sm', 'md'];
export const DATAGRID_SIZE_NAMES_TO_PROP_VALUE_MAP: Map<string, DataGridSize> = new Map([
    ['ExtraSmall', 'xs'],
    ['Small', 'sm'],
    ['Medium', 'md'],
    ['Large', 'lg'],
]);

export const DATAGRID_DENSITY_AND_SIZE_TO_HEADER_VERTICAL_PADDING_MAP: Map<string, number> =
    new Map([
        ['extraCompact-xs', 1],
        ['compact-xs', 3],
        ['standard-sm', 6],
        ['extraCompact-sm', 1],
        ['compact-sm', 3],
        ['compact-md', 6],
        ['compact-lg', 12],
        ['standard-sm', 6],
        ['standard-md', 6],
        ['standard-lg', 12],
    ]);
export const DATAGRID_DENSITY_AND_SIZE_TO_ROW_VERTICAL_PADDING_MAP: Map<string, number> = new Map([
    ['extraCompact-xs', 1],
    ['compact-xs', 3],
    ['standard-xs', 6],
    ['extraCompact-sm', 1],
    ['compact-sm', 3],
    ['compact-md', 6],
    ['compact-lg', 12],
    ['standard-sm', 6],
    ['standard-md', 10],
    ['standard-lg', 20],
]);
export const DATAGRID_SIZE_TO_TYPOGRAPHY_MAP: Map<DataGridSize, TypographyVariant> = new Map([
    ['xs', typographyVariants.body04],
    ['sm', typographyVariants.body03],
    ['md', typographyVariants.body02],
    ['lg', typographyVariants.body01],
]);

export const getLineHeightForSize = (size: DataGridSize): number => {
    const stripPxAndParseToNumber = (str: string): number => parseInt(str.replace('px', ''));
    const typography = DATAGRID_SIZE_TO_TYPOGRAPHY_MAP.get(size);
    return stripPxAndParseToNumber(typography!.lineHeight);
};
