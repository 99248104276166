import React, { useState, memo } from 'react';
import { Dropdown, DropdownButton, DropdownMenu, MenuOption } from '@gs-ux-uitoolkit-react/dropdown';
import PropTypes from 'prop-types';

const CardDropdown = ({ name, dropdownOptions, selectedOption, updateSelectedOption }) => {
  const [displayDropdown, setDisplayDropdown] = useState(false);

  const toggleDropdownDisplay = () => {
    setDisplayDropdown(!displayDropdown);
  };

  const handleDropdownChange = value => {
    updateSelectedOption(value, name);
    setDisplayDropdown(false);
  };

  return (
    <Dropdown menuVisible={displayDropdown} onMenuToggle={toggleDropdownDisplay}>
      <DropdownButton>{selectedOption}</DropdownButton>
      <DropdownMenu onChange={handleDropdownChange}>
        {dropdownOptions.map((option, idx) => (
          <MenuOption key={idx} value={option}>
            <span className={`dbcard__bodyRow${option}Icon dropdown-icons`} />
            <span className={selectedOption === option ? 'dbcard__bodyRowText' : ''}>{option}</span>
          </MenuOption>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

CardDropdown.propTypes = {
  name: PropTypes.string,
  dropdownOptions: PropTypes.array,
  selectedOption: PropTypes.string,
  updateSelectedOption: PropTypes.func,
};

CardDropdown.defaultProps = {
  dropdownOptions: [],
};

export default memo(CardDropdown);
