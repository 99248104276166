import React from "react";
import useUserMaintenance from '../../../context';
import translator from '../../../../../../services/translator';
import './index.scss';

const { translate: t } = translator;

const Review = () => {

  const {
    state: { userDetails },
  } = useUserMaintenance();

  const { firstName, lastName, contactDetails: { email, phone }, accessTypes } = userDetails

  return (
    <div className="review-page">
      <div className="review-page__title">
        <div className="review-page__title--main">{t('tkReviewAndConfirm')}</div>
        <div>{t('tkReviewAndConfirmSubTitle')}</div>
      </div>
      <div className="review-page__section">
        <div className="review-page__section--main">{t('tkNewUsers')}</div>
        <ul>
          <li>
            <span className="review-page__section--header">{`${firstName} ${lastName}`}</span>
            <span>{`, ${email}`}</span>
            {phone && <span>{`, ${phone}`}</span>}
            <span>{`, ${accessTypes}`}</span>
          </li>
        </ul>
      </div>
    </div>

  )
}

export default Review;