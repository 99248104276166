import { breakpoints } from '../breakpoints';

/**
 * Utils used by:
 *   src/gs-ux-uitoolkit-react/src/components/adaptive/AdaptiveContainer.jsx
 *   src/gs-ux-uitoolkit-angular/src/components/adaptive/adaptive-container.component.ts
 */

// Default breakpoints match (approximately) $container-max-widths in
// src/gs-ux-uitoolkit-common/style/_variables.scss
import MobileDetect from 'mobile-detect';

export type AdaptiveBreakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
export type AdaptiveBreakpoints = { [K in AdaptiveBreakpoint]: number };

export const DEFAULT_BREAKPOINTS: AdaptiveBreakpoints = {
    xs: breakpoints.xs.maxWidth,
    sm: breakpoints.sm.maxWidth,
    md: breakpoints.md.maxWidth,
    lg: breakpoints.lg.maxWidth,
    xl: breakpoints.xl.maxWidth,
    xxl: breakpoints.xxl.maxWidth,
    xxxl: breakpoints.xxxl.maxWidth,
};

// Returns the best breakpoint component object given a breakpoint key. For
// example, given breakpoint 'xl' and a components object with no key 'xl' but
// with a key 'desktop', returns components.desktop as the next best fit.
export function getBreakpointObject(breakpoint: AdaptiveBreakpoint, components: any) {
    const c = components;
    const md = new MobileDetect(window.navigator.userAgent);
    if (md.mobile() && c.mobile) {
        return c.mobile;
    }
    switch (breakpoint) {
        case 'xxxl':
            return c.xxxl || c.desktop || c.xxl || c.xl || null;
        case 'xxl':
            return c.xxl || c.desktop || c.xl || c.lg || null;
        case 'xl':
            return c.xl || c.desktop || c.lg || c.md || null;
        case 'lg':
            return c.lg || c.desktop || c.tablet || c.md || c.xl || null;
        case 'md':
            return c.md || c.tablet || c.phone || c.sm || c.lg || null;
        case 'sm':
            return c.sm || c.phone || c.xs || c.md || null;
        case 'xs':
            return c.xs || c.phone || c.sm || c.md || null;
        default:
            return c[breakpoint] || null;
    }
}

// Given an array of breakpoint widths, components that correspond to those
// widths, and a given width, returns the best fit.
export function getBreakpoint(breakpoints: AdaptiveBreakpoints, components: any, width: any) {
    // Find the current breakpoint.
    const bpArray = Object.keys(breakpoints)
        .map((key: any) => ({
            key,
            value: breakpoints[key as AdaptiveBreakpoint],
        }))
        .sort((a: any, b: any) => a.value - b.value);
    let bpIndex = -1;
    for (let i = bpArray.length - 1; i >= 0; i--) {
        if (width <= bpArray[i].value) {
            bpIndex = i;
        } else {
            break;
        }
    }

    // If breakpoint matched, render the corresponding component.
    if (bpIndex !== -1) {
        const bpKey = bpArray[bpIndex].key;
        const bpObj = getBreakpointObject(bpKey, components);
        if (bpObj.component) {
            return bpObj;
        }
    }
    return null;
}
