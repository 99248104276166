import errorTypes from './errorType';

// Update labels to be fetched from store.
const labels = {};

export default class GLSError extends Error {
  constructor(code = '', error = '', message = '', response = {}) {
    super();
    this.message = message;
    this.stack = error ? error.stack : (new Error()).stack;
    this.code = code;
    this.name = 'GLSError';
    this.response = response;
  }

  assign() {
    if (Object.prototype.hasOwnProperty.call(errorTypes, this.code) && Object.prototype.hasOwnProperty.call(labels, this.code)) {
      this.message = labels[this.code];
    } else if (Object.prototype.hasOwnProperty.call(labels, 'DEFAULT_ERROR_MSG')) {
      this.message = labels.DEFAULT_ERROR_MSG;
    } else {
      this.message = 'Unknown Error';
    }
    this.log();
  }

  log() {
    console.group();
    console.error(this.name);
    console.error(this.message);
    console.error(this.stack);
    console.groupEnd();
  }

  toString() {
    return `Name: ${this.name}
            Message: ${this.message}
            Stack: ${this.stack}`;
  }
}
