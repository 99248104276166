import React, {useEffect, useState} from 'react';
import {PropTypes} from 'prop-types';
import {Input} from '@gs-ux-uitoolkit-react/input';
import cn from 'classnames';
import {usePasswordVisibilityIcon} from '../Login/loginHook';
import LeftPanel from '../../core/LeftPanel';
import Button from '../../core/Button';
import ValidationItem from '../ValidationItem';
import HelpContainer from '../../../containers/HelpContainer';
import Constants from '../../../constants/appConstants';
import {Conditional} from '../../core/Conditional';
import {ThreeSquaresLoader} from '../../core/Loaders';
import {analyticsEventName} from './analytics';
import {getLoginRoute} from '../../../utils/commonUtils';
import './index.scss';

const CreatePin = (props) => {
  const [newPin, setNewPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');
  const [pinDonotMatch, setPinDonotMatch] = useState(null);

  const {
    passwordEnabled: newPinEnabled,
    passwordTrailingContent: newPinTrailingContent
  } = usePasswordVisibilityIcon();
  const {
    passwordEnabled: confirmPinEnabled,
    passwordTrailingContent: confirmPinTrailingContent
  } = usePasswordVisibilityIcon();

  useEffect(() => {
    if (!props.userId) {
      props.history.push(getLoginRoute(Constants.LOGIN_ROUTE));
    }
  });

  const isNewAndConfirmPinMatch = () => {
    return (newPin.length > 0 && newPin === confirmPin);
  };

  const onCreatePin = () => {
    if (!isNewAndConfirmPinMatch()) {
      setPinDonotMatch(true);
    } else {
      setPinDonotMatch(false);
      props.createNewPin({
        newPin,
        confirmPin,
        username: props.userId
      });
    }
  };

  const onNewPinInputChange = (event)  => {
    const {value} = event.target;
    const isAlphanumeric = value.match(/^[a-z0-9]+$/i);
    props.pinValidationList[0].valid = ((value.length <= 8) && (value.length >= 4) && isAlphanumeric) ? 'valid' : 'invalid';
    setNewPin(value);
  };

  const onConfirmPinInputChange = ({target: {value}}) => {
    setConfirmPin(value);
  };

  const helpClickHandler = () => {
    props.isShowMosaicHelp(true);
  };

  const isSubmitDisabled = ()  => {
    return !!((!newPin || newPin.length === 0) ||
      (!confirmPin || confirmPin.length === 0)) ||
      props.pinValidationList && props.pinValidationList.find(validationItem => {
        return validationItem.valid === 'invalid';
      }) || props.isLoginInProgress;
  };

  const {
    labels, pinValidationList, submitCredentialsError,
    isShowMosaicHelpFlag, isLoginInProgress
  } = props;
  const leftPanelProps = {
    title: labels.tkLeftPanelHeader,
    subTitle: labels.tkLeftPanelSubHeader
  };

  return (
    <React.Fragment>
      <Conditional condition={isLoginInProgress}>
        <ThreeSquaresLoader/>
      </Conditional>
      <div className="create-pin-container">
        <div className="create-pin-container__left-panel">
          <div className="panel-title">
            <LeftPanel {...leftPanelProps} />
          </div>
        </div>
        {
          !isShowMosaicHelpFlag ?
            <div className="create-pin-container__right-panel">
              <div className="fields">
                <div className="fields__header">
                  {labels.tkCreatePinHeader}
                </div>
                <div className="fields__subheader">
                  {labels.tkCreatePinSubheader}
                </div>
                <form onSubmit={onCreatePin}>
                  <div className="fields__inputs">
                    {submitCredentialsError ?
                      <div
                        className="fields__error-text">
                        {submitCredentialsError}
                      </div>
                      : ' '}
                    <div className="input-container">
                      <Input
                        name="newPin"
                        placeholder={labels.tkCreateNewPinPlaceholder}
                        value={newPin}
                        type={newPinEnabled ? 'password' : 'text'}
                        size="md"
                        autoFocus={true}
                        onChange={onNewPinInputChange}
                        trailingContent={newPinTrailingContent}
                        className={cn({'pin-spaced': newPin}, 'login-input')}
                      />
                    </div>
                    <div className="fields__subheader">
                      Pin requirements:
                    </div>

                    <div className="fields__list">
                      {Array.isArray(pinValidationList) ? pinValidationList.map(item =>
                        (<ValidationItem
                          key={item.text}
                          customClass="validation-list"
                          className="check-mark"
                          iconStatus={item.valid}
                          lineText={item.text}/>)
                      ) : null}
                    </div>
                    <div className="input-container">
                      <Input
                        name="confirmPin"
                        placeholder={labels.tkConfirmPinPlaceholder}
                        value={confirmPin}
                        type={confirmPinEnabled ? 'password' : 'text'}
                        size="md"
                        onChange={onConfirmPinInputChange}
                        trailingContent={confirmPinTrailingContent}
                        className={cn({'pin-spaced': confirmPin}, 'login-input')}
                      />
                    </div>
                    <Conditional condition={pinDonotMatch}>
                      <ValidationItem
                        key={labels.tkPinMatch}
                        customClass="validation-list"
                        className="check-mark"
                        iconStatus='invalid'
                        lineText={labels.tkPinMatch}
                      />
                    </Conditional>
                  </div>
                  <div className="login-button">
                    <Button
                      extraProps={{'analytics-attribute': analyticsEventName.LOGIN_PAGE_CREATE_PIN_BUTTON}}
                      type="submit"
                      customClass="button-primary-transparent"
                      label={labels.tkCreatePin}
                      isDisabled={isSubmitDisabled()}
                      clickHandler={onCreatePin}/>
                  </div>
                </form>
                <div className="login-sub-button-container">
                  <span
                    analytics-attribute={analyticsEventName.CREATE_PIN_PAGE_HELP_LINK}
                    className="login-sub-button"
                    onClick={helpClickHandler}>
                    {labels.tkNeedHelp}
                  </span>
                </div>
              </div>
            </div> :
            <HelpContainer/>
        }
      </div>
    </React.Fragment>
  );
};

CreatePin.propTypes = {
  labels: PropTypes.object,
  createNewPin: PropTypes.func,
  pinValidationList: PropTypes.array,
  submitCredentialsError: PropTypes.string,
  isShowMosaicHelp: PropTypes.func,
  isShowMosaicHelpFlag: PropTypes.bool,
  isLoginInProgress: PropTypes.bool,
  userId: PropTypes.string,
  history: PropTypes.object
};

export default CreatePin;
