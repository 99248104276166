import {
    CellValueChangedEvent,
    ColDef,
    Column,
    ColumnApi,
    ColumnGroupOpenedEvent,
    Events,
    EventService,
    GridApi,
    RowEvent,
    RowGroupOpenedEvent,
    ICellRendererFunc,
    SuppressKeyboardEventParams,
    ColGroupDef,
} from '@ag-grid-community/core';
import {
    arrayHelper,
    ColumnHint,
    DataType,
    DraDatasource,
    DraEdit,
    DraPivotDimension,
    DraProvider,
    DraSelectionOperation,
    DraSortInfo,
    ExpressionQuery,
    logger,
    PluginBase,
    stringHelper,
    DraViewConfig,
    Record,
    PluginIcon,
} from '@gs-ux-uitoolkit-common/datacore';
import { ModuleIdentfier } from '../module-identfier';
import { debounce, isEqual } from 'gs-uitk-lodash';
import { rowGroupColumnColId } from '../../datasources/dra-viewport-datasource';
import { draTechnicalFieldHelper } from '../../datasources/technical-field-helper';
import { GridWrapper } from '../../grid-wrappers/grid-wrapper';
import { buildCompositeFilterExpression } from '../../libraries/helpers/filter-helper';
import { AddColumnHintList, RemoveColumnHintList } from '../../redux/actions/column-hint-action';

import { DataGridState } from '../../redux/datagrid-state';
import { Categories, Plugins } from '../plugin-enum';
import { checkboxSelectionDatacy } from '../../datasources/dra-group-renderer';
import { getGridOptionsService } from '../../util/getGridOptionsService';
import { hasOwnProperty } from 'gs-uitk-object-utils';

const mainIcon: PluginIcon = { name: 'sort', type: 'filled' };

export const DRA_DEFAULT_SORT = 'default';
export const DRA_ABSOLUTE_SORT = 'abs';
export const DRA_ASCENDING_SORT = 'ascending';
export const DRA_DESCENDING_SORT = 'descending';
export interface CustomDRAOptions {
    rowSelection?: string;
    checkboxSelection?: boolean;
    hideLeafCheckbox?: boolean;
    draFilterTransformer?: (query: ExpressionQuery) => ExpressionQuery;
    columnDefs?: (ColDef | ColGroupDef)[];
    showRowGroupLeafName?: boolean;
    enableRowGroupColumnSorting?: boolean;
    treeColInnerRenderer?: ICellRendererFunc;
    groupSelectsChildren?: boolean;
    getRowId?: (record: Record) => string;
}

export const onDataGridSuppressKeyboardEventForDra = (
    params: SuppressKeyboardEventParams
): boolean => {
    // We handle the Ctrl + Shift + A shortcut for DRA select all so that the selection gets handled on the back end
    // Consequently we need to suppress Ctrl + A in ag grid so that we can handle it ourselves
    const event = params.event;
    const key = event.which;

    // We want to prevent the select all shortcut from selecting everything else in the page
    if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
        event.stopPropagation();
        event.preventDefault();
    }

    const KEY_A = 65;
    const keysToSuppress = [];
    if ((event.ctrlKey || event.metaKey) && event.shiftKey) {
        keysToSuppress.push(KEY_A);
    }
    return keysToSuppress.indexOf(key) >= 0;
};

/**
 * The DRA plugin.
 * This is the only plugin where we allow leaking of ag-grid specific implementation
 * This will have to change once overall architecture is stabilized
 */
export class DraPlugin extends PluginBase<GridWrapper, DataGridState> {
    protected static requiredModules: ModuleIdentfier[] = [ModuleIdentfier.ViewportRowModelModule];
    private eventService: EventService;
    private columnHints: ColumnHint[] = [];
    private draProvider: DraProvider;
    private lastSelectedNodeData: Record | null = null;
    private apiEventListenerSubscription: { event: string; func: (args: any) => void }[] = [];

    // we debounce it because of the absolute sort that gets changed afteer sort order has been changed
    // i.e. the 5clicks sort
    private sortChangedFuncDebounced = debounce(() => this.sortChangedFunc(), 50);

    constructor(
        wrapper: GridWrapper,
        private gridApi: GridApi,
        private columnApi: ColumnApi,
        private draDatasource: DraDatasource,
        private customOptions: CustomDRAOptions
    ) {
        super(Plugins.DraPlugin, Categories.Data, mainIcon, wrapper, () => true);

        // Accessing private member of ag-grid
        this.eventService = (this.gridApi as any).eventService;
        if (!this.eventService) {
            const errorMsg = 'Cannot get the eventService';
            logger.error(errorMsg, this.gridApi);
            throw Error(errorMsg);
        }
        this.draProvider = this.draDatasource.provider;

        this.draProvider.on('view-config', this.onViewConfig);
    }

    /**
     * When the view config changes this method gets called
     */
    private onViewConfig = () => {
        // This is to ensure effectiveHeaderIndices is set correctly after a reset.
        // without this the effectiveHeaderIndices are reset to the initial state and not the current state
        // this method will resolve this
        this.buildAndSendDynamicAggregationColumnsFunc();
    };

    /**
     * Determine the type of a DRA column
     */
    public getColumnDataType(columnField: string): DataType {
        let draType = this.draProvider.viewConfig.getType(columnField);
        if (!draType || draType === 'Unknown') {
            // if hPivoted we want to assign the type of the main column to hpivoted column
            const splitColIds = columnField.split('_');
            const lastColId = arrayHelper.last(splitColIds);
            if (lastColId) {
                draType = this.draProvider.viewConfig.getType(lastColId);
            }
        }

        if (draType) {
            switch (draType) {
                case 'String':
                    return DataType.String;
                case 'Number':
                    return DataType.Number;
                case 'Date':
                    return DataType.Date;
                default:
                    return DataType.Unknown;
            }
        }
        return DataType.Unknown;
    }

    public getViewConfig(): DraViewConfig {
        return this.draProvider.viewConfig;
    }
    public getDraProvider(): DraProvider {
        return this.draProvider;
    }
    public getDraDatasource(): DraDatasource {
        return this.draDatasource;
    }

    protected internalStop(): void {
        // we unsubscribe to all the event we subscribed on for ag-grid api
        this.apiEventListenerSubscription.forEach(subscription => {
            this.gridApi.removeEventListener(subscription.event, subscription.func);
        });
        this.wrapper.getReduxStore().dispatch(RemoveColumnHintList(this.columnHints));
        this.gridApi.removeEventListener(
            Events.EVENT_COLUMN_PIVOT_MODE_CHANGED,
            this.pivotModeChangedFunc
        );
    }

    // for dra-plugin this gets called only on start
    protected stateChangedOrStart(): void {
        // we listen for ag-Grid changes in the rowGrouping definition
        this.apiEventListenerSubscription.push({
            event: Events.EVENT_COLUMN_ROW_GROUP_CHANGED,
            func: this.columnRowGroupChangedFunc,
        });

        // Listen for row group open and raise our own internal event
        this.apiEventListenerSubscription.push({
            event: Events.EVENT_ROW_GROUP_OPENED,
            func: this.rowGroupOpenedFunc,
        });

        this.apiEventListenerSubscription.push({
            event: Events.EVENT_ROW_CLICKED,
            func: this.rowInteractedMouseFunc,
        });

        this.apiEventListenerSubscription.push({
            event: Events.EVENT_CELL_KEY_DOWN,
            func: this.rowInteractedKeyboardFunc,
        });

        // we listen for ag-Grid changes in the sort definition
        this.apiEventListenerSubscription.push({
            event: Events.EVENT_SORT_CHANGED,
            func: this.sortChangedFuncDebounced,
        });

        this.apiEventListenerSubscription.push({
            event: Events.EVENT_FILTER_CHANGED,
            func: this.filterChangedFunc,
        });

        // we listen for ag-Grid changes for column group open/close
        this.apiEventListenerSubscription.push({
            event: Events.EVENT_COLUMN_GROUP_OPENED,
            func: this.columnGroupOpenedFunc,
        });

        // we listen for ag-Grid changes for hPivot dimensions
        this.apiEventListenerSubscription.push({
            event: Events.EVENT_COLUMN_PIVOT_CHANGED,
            func: this.columnPivotChangedFunc,
        });

        // we listen for ag-Grid editing
        this.apiEventListenerSubscription.push({
            event: Events.EVENT_CELL_VALUE_CHANGED,
            func: this.editedFunc,
        });

        this.apiEventListenerSubscription.push({
            event: Events.EVENT_COLUMN_PIVOT_MODE_CHANGED,
            func: this.pivotModeChangedFunc,
        });

        if (
            this.draProvider.viewConfig.columnHintProcessor &&
            this.draProvider.viewConfig.columnHintProcessor.hints
        ) {
            const hints = this.draProvider.viewConfig.columnHintProcessor.hints;
            this.columnHints = Object.keys(hints).map(columnId => ({
                columnId,
                hints: hints[columnId],
            }));
            if (this.columnHints.length > 0) {
                this.wrapper.getReduxStore().dispatch(AddColumnHintList(this.columnHints));
            }
        }

        if (this.draProvider.viewConfig.isDynamicAggregationEnabled) {
            if (this.columnApi.isPivotMode()) {
                // when pivoting we listened for value column changes.
                this.apiEventListenerSubscription.push({
                    event: Events.EVENT_COLUMN_VALUE_CHANGED,
                    func: this.buildAndSendDynamicAggregationColumnsFunc,
                });
                this.buildAndSendDynamicAggregationColumnsFunc();
            } else {
                // when not pivoting we listen for normal column events
                this.apiEventListenerSubscription.push({
                    event: Events.EVENT_COLUMN_VISIBLE,
                    func: this.buildAndSendDynamicAggregationColumnsFunc,
                });
                this.buildAndSendDynamicAggregationColumnsFunc();
            }
        }

        // finally subscribe to all the events
        this.apiEventListenerSubscription.forEach(subscription => {
            this.gridApi.addEventListener(subscription.event, subscription.func);
        });

        this.wrapper
            .getReduxStore()
            .getState()
            .grid.columnList.forEach(col => {
                if (col.dataType === DataType.Number) {
                    this.wrapper.addCellClassRule(
                        col.columnId,
                        `dra-numeric-${col.columnId}`,
                        () => true
                    );
                }
            });
    }

    private pivotModeChangedFunc = () => {
        this.buildAndSendDynamicAggregationColumnsFunc();
        if (!this.columnApi.isPivotMode()) {
            this.draProvider.pivotAndDepth([], DraPivotDimension.Horizontal, 1);
        } else {
            const newPivots = this.columnApi.getPivotColumns().map(x => x.getColId());
            logger.debug(`Changing hPivots to be ${newPivots}`);
            this.draProvider.pivotAndDepth(newPivots, DraPivotDimension.Horizontal, 1);
        }
    };

    private rowGroupOpenedFunc = (e: RowGroupOpenedEvent) => {
        if (
            e.node.group &&
            e.node.expanded !== e.node.data[this.draProvider.viewConfig.isExpandedField]
        ) {
            logger.debug('RowGroup opened/collapsed', e);
            const path =
                e.node.data[this.draProvider.viewConfig.pathField].path[DraPivotDimension.Vertical];
            e.node.data[this.draProvider.viewConfig.isExpandedField]
                ? this.draProvider.closePath(DraPivotDimension.Vertical, path)
                : this.draProvider.openPath(DraPivotDimension.Vertical, path);
        }
    };

    /**
     * This method is the one to call to update the aggregation columns on the DRA backend
     * if lazy aggreagation deactivated it won't do anything
     * if the aggregation columns are the same as before it will escape the call
     */
    private buildAndSendDynamicAggregationColumnsFunc = () => {
        if (this.draProvider.viewConfig.isDynamicAggregationEnabled) {
            if (this.columnApi.isPivotMode()) {
                // when pivoting we listened for value column changes.
                this.buildAndSendDynamicAggregationColumnsFuncWhenPivot();
            } else {
                const allColumns = this.columnApi.getColumns();
                // when not pivoting we listen for normal column events
                if (allColumns)
                    this.buildAndSendDynamicAggregationColumns(
                        allColumns.filter(col => col.isVisible())
                    );
            }
        }
    };

    /**
     * Specific function of the lazy aggregation logic when in pivot
     * It makes sure that the pivoted columns are handled properly when the master
     * one visibility is changed
     */
    private buildAndSendDynamicAggregationColumnsFuncWhenPivot() {
        const valueCol = this.columnApi.getValueColumns();

        this.buildAndSendDynamicAggregationColumns(valueCol);

        const allColumns = this.columnApi.getAllGridColumns().slice().reverse();
        if (allColumns) {
            // we manage the visibility of the corresponding hpivoted columns
            allColumns.forEach(col => {
                // is the corresponding column value displayed
                const correspondingColumnValueVisible =
                    valueCol.findIndex(vc => vc.getColId() === col.getColId()) > -1;
                const isPrimaryColumnVisible = col.isVisible();
                const isNonPivotedColumn = !col.getColId().includes('_');
                if (correspondingColumnValueVisible !== isPrimaryColumnVisible) {
                    if (isNonPivotedColumn) {
                        allColumns.forEach(anotherCol => {
                            if (anotherCol.getColId().endsWith(`_${col.getColId()}`)) {
                                anotherCol.setVisible(correspondingColumnValueVisible);
                            }
                        });
                        col.setVisible(correspondingColumnValueVisible);
                    } else {
                        // check if the nonpivoted is visible
                        const splitColId = col.getColId().split('_');
                        const nonPivotedColId = arrayHelper.last(splitColId);
                        if (nonPivotedColId) {
                            const nonPivotedCol = allColumns.find(
                                nonPivoted => nonPivoted.getColId() === nonPivotedColId
                            );
                            if (nonPivotedCol) {
                                col.setVisible(
                                    correspondingColumnValueVisible && nonPivotedCol.isVisible()
                                );
                            }
                        }
                    }
                }
            });
        }
        const allNewValueColumns = this.columnApi
            .getAllGridColumns()
            .filter(col => col.isVisible());
        if (allNewValueColumns.length !== valueCol.length) {
            this.columnApi.setValueColumns(allNewValueColumns);
        }
    }

    private columnPivotChangedFunc = () => {
        // if lazy aggregation activated we will send the setAggregation column to DRA prior setting the hpivots one
        this.buildAndSendDynamicAggregationColumnsFunc();
        const newPivots = this.columnApi.getPivotColumns().map(x => x.getColId());
        logger.debug(`Changing hPivots to be ${newPivots}`);
        this.draProvider.pivotAndDepth(newPivots, DraPivotDimension.Horizontal, 1);
    };

    private columnGroupOpenedFunc = (event: ColumnGroupOpenedEvent) => {
        const pivotArray = event.columnGroup.getId().split('_');
        if (event.columnGroup.isExpanded()) {
            logger.debug('Opening Column Group', pivotArray);
            this.draProvider.openPath(DraPivotDimension.Horizontal, pivotArray);
        } else {
            logger.debug('Closing Column Group', pivotArray);
            this.draProvider.closePath(DraPivotDimension.Horizontal, pivotArray);
        }
    };

    private filterChangedFunc = () => {
        const group = buildCompositeFilterExpression(this.wrapper);
        if (this.customOptions.draFilterTransformer) {
            this.draProvider.filter(this.customOptions.draFilterTransformer({ ...group }) || group);
        } else {
            this.draProvider.filter(group);
        }
    };

    private isRowGroupColumnSort = (sortModel: any[]) => {
        return sortModel.length === 1 && sortModel[0].colId === rowGroupColumnColId;
    };

    private rowGroupColumnSortModel = (sort: string) => {
        const groupedColumnIds = this.columnApi
            .getRowGroupColumns()
            .map(column => column.getColId());
        if (
            // it has to be populated
            !stringHelper.isNullOrEmpty(this.draProvider.viewConfig.treeColumn) &&
            // not part of the rowGroup already
            !groupedColumnIds.find(col => col === this.draProvider.viewConfig.treeColumn) &&
            // has to be a valid column
            this.wrapper
                .getReduxStore()
                .getState()
                .grid.columnList.find(
                    col => col.columnId === this.draProvider.viewConfig.treeColumn
                )
        ) {
            groupedColumnIds.push(this.draProvider.viewConfig.treeColumn);
        }
        return groupedColumnIds.map(id => ({ sort, colId: id }));
    };
    private sortChangedFunc = () => {
        let sortModel = arrayHelper
            .sort(
                this.columnApi.getColumnState().filter(col => col.sort),
                false,
                'sortIndex'
            )
            .map(col => ({ colId: col.colId || '', sort: col.sort || '' }));
        if (this.isRowGroupColumnSort(sortModel)) {
            sortModel = this.rowGroupColumnSortModel(sortModel[0].sort);
        }
        const gridState = this.wrapper.getReduxStore().getState();

        const draSortInfos: DraSortInfo[] = sortModel.map(sort => {
            const customSort = gridState.customSort.configItemList.find(
                cs => cs.columnId === sort.colId
            );
            const absoluteSort = customSort && customSort.absoluteSortActive;
            return [
                sort.colId,
                sort.sort === 'asc' ? DRA_ASCENDING_SORT : DRA_DESCENDING_SORT,
                absoluteSort ? DRA_ABSOLUTE_SORT : DRA_DEFAULT_SORT,
            ] as DraSortInfo;
        });
        this.draProvider.sort(draSortInfos);
    };

    // Need to handle when a row is clicked or selected with the keyboard since ag-grid logic bails out for click selection early if it's a group
    private rowInteractedMouseFunc = (event: RowEvent) => {
        if (!event.event) {
            logger.error('Cannot find mouse event', event);
            return;
        }
        const mouseEvent = event.event as MouseEvent;
        const clickTarget = mouseEvent.target as Element | null;
        /**
         * If the rowSelect checkboxes are enabled then we need to ignore some of the row clicks
         */
        if (
            this.customOptions.checkboxSelection &&
            (!clickTarget || clickTarget.getAttribute('data-cy') !== checkboxSelectionDatacy)
        ) {
            return;
        }

        const ctrl = mouseEvent.ctrlKey || mouseEvent.metaKey;
        const shift = mouseEvent.shiftKey;

        /**
         * when rowSelection is single we actually set the rowSelection to multiple on ag-grid side as DRA automatically
         * selects the children of a group
         */
        if (this.customOptions.rowSelection === 'single') {
            // if selected on the UI then we just update the DRA server
            if (
                !this.lastSelectedNodeData ||
                this.lastSelectedNodeData[this.draProvider.viewConfig.idxField] !==
                    event.node.data[this.draProvider.viewConfig.idxField]
            ) {
                event.node.setSelected(true, true);
                this.draProvider.setSelection(event.data, DraSelectionOperation.CLEAR);
                this.draProvider.setSelection(event.data, DraSelectionOperation.APPEND);
                this.lastSelectedNodeData = event.data;
            } else {
                event.node.setSelected(false, true);
                // if it's been deselected as ag-grid has the deselection enabled then we just clear the back end
                this.draProvider.setSelection(event.data, DraSelectionOperation.CLEAR);
                this.lastSelectedNodeData = null;
            }
        } else if (this.customOptions.rowSelection === 'multiple') {
            // if ctrl and shift aren't pressed then we start by clearing the selection like on windows explorer
            if (!ctrl && !shift && !this.customOptions.checkboxSelection) {
                // we clear out the selectedNodes from ag-grid as some previously selected nodes can be outside the viewport and we won't be notified by the server for those
                event.api.deselectAll();
                this.draProvider.setSelection(event.data, DraSelectionOperation.CLEAR);
            }

            // if shift is pressed then we do a rangeSelection
            if (shift && this.lastSelectedNodeData) {
                this.draProvider.setRangeSelection(this.lastSelectedNodeData, event.data);
            } else {
                // otherwise we just append the clicked row
                this.draProvider.setSelection(event.data, DraSelectionOperation.APPEND);
            }

            this.lastSelectedNodeData = event.data;
        }
    };
    private rowInteractedKeyboardFunc = (event: RowEvent) => {
        if (!event.event) {
            logger.error('Cannot find keyboard event', event);
            return;
        }
        const keyboardEvent = event.event as KeyboardEvent;
        if (keyboardEvent.keyCode === 32) {
            if (this.customOptions.rowSelection === 'single') {
                // if selected on the UI then we just update the DRA server
                if (
                    !this.lastSelectedNodeData ||
                    this.lastSelectedNodeData[this.draProvider.viewConfig.idxField] !==
                        event.node.data[this.draProvider.viewConfig.idxField]
                ) {
                    event.node.setSelected(true, true);
                    this.draProvider.setSelection(event.data, DraSelectionOperation.CLEAR);
                    this.draProvider.setSelection(event.data, DraSelectionOperation.APPEND);
                    this.lastSelectedNodeData = event.data;
                } else {
                    event.node.setSelected(false, true);
                    // if it's been deselected as ag-grid has the deselection enabled then we just clear the back end
                    this.draProvider.setSelection(event.data, DraSelectionOperation.CLEAR);
                    this.lastSelectedNodeData = null;
                }
            } else {
                this.draProvider.setSelection(event.data, DraSelectionOperation.APPEND);
            }
        }

        if (
            (keyboardEvent.ctrlKey || keyboardEvent.metaKey) &&
            keyboardEvent.shiftKey &&
            keyboardEvent.code === 'KeyA'
        ) {
            this.draProvider.setRangeSelection(
                {
                    [this.draProvider.viewConfig.idxField]: 0,
                    [this.draProvider.viewConfig.pathField]: { path: [['UNUSED']] },
                },
                {
                    [this.draProvider.viewConfig.idxField]: this.draDatasource.getDataSize() - 1,
                    [this.draProvider.viewConfig.pathField]: { path: [['UNUSED']] },
                }
            );
            this.gridApi.showLoadingOverlay();
            // in case no data comes back then we hide the loading indicator.
            // It will happen if the user hit twice selectAll since all rows are already selected.
            // So a very unlikely case
            setTimeout(() => this.gridApi.hideOverlay(), 10000);
        }
    };

    private updateGroupDefaultExpanded(pivots: string[]) {
        const groupDefaultExpanded = getGridOptionsService(this.gridApi).getNum(
            'groupDefaultExpanded'
        );
        if (groupDefaultExpanded != null) {
            const minExpansionDepth = pivots.length + 1;
            if (groupDefaultExpanded > minExpansionDepth) {
                getGridOptionsService(this.gridApi).set('groupDefaultExpanded', minExpansionDepth);
                this.gridApi.dispatchEvent({ type: Events.EVENT_COLUMN_ROW_GROUP_CHANGED });
            }
        }
    }

    private columnRowGroupChangedFunc = () => {
        const columnGroups = this.columnApi.getRowGroupColumns();
        const newPivots = columnGroups.map(x => x.getColId());
        if (newPivots.length === 0) {
            this.columnApi.setColumnVisible(rowGroupColumnColId, false);
        } else if (newPivots.length !== 0) {
            this.columnApi.setColumnVisible(rowGroupColumnColId, true);
        }
        this.updateGroupDefaultExpanded(newPivots);
        const groupDefaultExpanded = getGridOptionsService(this.gridApi).getNum(
            'groupDefaultExpanded'
        );
        // if lazy aggregation activated we will send the setAggregation column to DRA prior setting the pivots one
        this.buildAndSendDynamicAggregationColumnsFunc();
        logger.debug('Changing pivotAndDepth to be', newPivots);
        this.draProvider.pivotAndDepth(
            newPivots,
            DraPivotDimension.Vertical,
            groupDefaultExpanded == null ? 1 : groupDefaultExpanded
        );

        // if it was sorted by the pivot column we need to update the sort on DRA side
        const sortModel = this.columnApi
            .getColumnState()
            .filter(col => col.sort)
            .map(col => ({ colId: col.colId || '', sort: col.sort || '' }));
        if (this.isRowGroupColumnSort(sortModel)) {
            this.sortChangedFuncDebounced();
        }
    };

    private editedFunc = (ev: CellValueChangedEvent) => {
        const edit: DraEdit = { row: { ...ev.data }, updates: {} };
        // we reset the record to have the old value when we send it down to DRA
        edit.row[ev.column.getColId()] = ev.oldValue;
        // we create the update part of the editing
        edit.updates[ev.column.getColId()] = ev.newValue;
        this.draProvider.edit([edit]);
    };

    /**
     * We build the dynamic aggregation column list that we will send to DRA
     * It includes the vilsible + mandatory + sort + pivot + row group columns
     * @param visibleColumns either value or normal columns
     */
    private buildAndSendDynamicAggregationColumns(visibleColumns: Column[]): void {
        const aggColumns = Object.keys(this.draProvider.viewConfig.aggregationDefinitions);
        let enabledAggColumns = aggColumns.filter(x =>
            visibleColumns.find(vc => vc.getColId() === x)
        );
        const sortColumns = arrayHelper
            .sort(
                this.columnApi.getColumnState().filter(col => col.sort),
                false,
                'sortIndex'
            )
            .map(col => ({ colId: col.colId || '', sort: col.sort || '' }))
            .filter(x => x.colId !== rowGroupColumnColId)
            .map(sort => sort.colId);
        const columnGroups = this.columnApi.getRowGroupColumns().map(rg => rg.getColId());
        const pivotColumns = this.columnApi.getPivotColumns().map(x => x.getColId());

        enabledAggColumns = [
            ...enabledAggColumns,
            ...sortColumns,
            ...columnGroups,
            ...pivotColumns,
            ...this.draProvider.viewConfig.mandatoryAggregationColumns,
        ];
        if (!stringHelper.isNullOrEmpty(this.draProvider.viewConfig.treeColumn)) {
            enabledAggColumns.push(this.draProvider.viewConfig.treeColumn);
        }
        enabledAggColumns = this.calculateDependencyColumnSet(enabledAggColumns).filter(Boolean);
        const effectiveHeader: number[] = [];
        this.draProvider.viewConfig.allFields.forEach((x, idx) => {
            if (
                // if agg enabled
                enabledAggColumns.find(col => col === x) ||
                // if it's a technical field but not STYLE[XXX}]
                (draTechnicalFieldHelper.isTechnicalField(x, this.draProvider.viewConfig) &&
                    !draTechnicalFieldHelper.isStyleColumn(x)) ||
                // if STYLE[XXX] and XXX is visible
                (draTechnicalFieldHelper.isStyleColumn(x) &&
                    enabledAggColumns.find(
                        col => col === draTechnicalFieldHelper.getStyleColumnField(x)
                    ))
            ) {
                effectiveHeader.push(idx);
            }
        });
        const lastEffectiveHeader = this.draProvider.viewConfig.effectiveHeaderIndices.slice(0);
        const currentEffectiveHeader = effectiveHeader.slice(0);
        if (!isEqual(lastEffectiveHeader.sort(), currentEffectiveHeader.sort())) {
            logger.debug(
                'Changing setAggregationColumns to be',
                enabledAggColumns,
                effectiveHeader
            );
            this.draProvider.setAggregationColumns(enabledAggColumns, effectiveHeader);
            this.draDatasource.setEffectiveHeaderIndices(effectiveHeader);
        }
    }

    // enabledAggColumns = ['col_a', 'col_b']
    // aggregationdefinitons = {
    //      col_a = {                   !! direct dependency
    //          Sum = {
    //              column = 'col_c',
    //              stopIfColumn = 'col_d'
    //          }
    //      }
    //      col_e = {
    //          Sum = {
    //              column = 'col_b',   !! reverse dependency
    //              stopIfColumn = 'col_f'
    //          }
    //      }
    // }
    // enabledAggColumns -> ['col_a', 'col_b', 'col_c', 'col_d', 'col_e', 'col_f']
    private calculateDependencyColumnSet(enabledAggColumns: string[]): string[] {
        const enabledAggColumnsSet: Set<string> = new Set<string>(enabledAggColumns);
        let dependentColumns: Set<string> = new Set<string>();
        let reverseDependentColumns: Set<string> = new Set<string>();
        let preCount: number = 0;

        while (preCount < enabledAggColumnsSet.size) {
            preCount = enabledAggColumnsSet.size;
            dependentColumns = this.calculateDependentColumns(enabledAggColumnsSet);
            if (!this.draProvider.viewConfig.onlyForwardDependentColumns) {
                reverseDependentColumns =
                    this.calculateReverseDependentColumns(enabledAggColumnsSet);
            }
            dependentColumns.forEach(enabledAggColumnsSet.add, enabledAggColumnsSet);
            reverseDependentColumns.forEach(enabledAggColumnsSet.add, enabledAggColumnsSet);
        }
        return Array.from(enabledAggColumnsSet.values());
    }

    private calculateDependentColumns(enabledAggColumnsSet: Set<string>): Set<string> {
        const aggregationDefinitions = this.draProvider.viewConfig.aggregationDefinitions;
        const dependentColumns: Set<string> = new Set<string>();

        enabledAggColumnsSet.forEach(colName => {
            if (hasOwnProperty(aggregationDefinitions, colName)) {
                const colAggrAttributes = Object.values(aggregationDefinitions[colName])[0];
                if (
                    null != colAggrAttributes.column &&
                    (colAggrAttributes.column.trim() as string).length !== 0
                ) {
                    dependentColumns.add(colAggrAttributes.column as string);
                }
                if (
                    null != colAggrAttributes.stopIfColumn &&
                    (colAggrAttributes.stopIfColumn.trim() as string).length !== 0
                ) {
                    dependentColumns.add(colAggrAttributes.stopIfColumn as string);
                }
            }
        });
        return dependentColumns;
    }

    private calculateReverseDependentColumns(enabledAggColumnsSet: Set<string>): Set<string> {
        const aggregationDefinitions = this.draProvider.viewConfig.aggregationDefinitions;
        const reverseDependentColumns: Set<string> = new Set<string>();

        for (const colName of Object.keys(aggregationDefinitions)) {
            const aggregationAttributes = Object.values(aggregationDefinitions[colName])[0];
            if (
                (aggregationAttributes.stopIfColumn != null &&
                    enabledAggColumnsSet.has(aggregationAttributes.stopIfColumn)) ||
                (aggregationAttributes.column != null &&
                    enabledAggColumnsSet.has(aggregationAttributes.column))
            ) {
                reverseDependentColumns
                    .add(colName)
                    .add(aggregationAttributes.stopIfColumn as string)
                    .add(aggregationAttributes.column as string);
            }
        }
        return reverseDependentColumns;
    }
}
