import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from '@gs-ux-uitoolkit-react/tooltip';
import translator from "../../../../services/translator";
import {Conditional} from "../../../../components/core/Conditional";

const {translate: t} = translator;

export const ThresholdGroupLabelRenderer = ({
  metricLabel,
  lowerMetricValue,
  upperMetricValue,
  metricValueType,
  lowerThreshold,
  upperThreshold,
  items = []
}) => {
  const groupLabelId = `fund-groups-${Math.floor(Math.random() * 1000000000)}`;
  const shareClassNames = items.map(({shareClassName}) => <li className="tooltip-label">{shareClassName}</li>);
  return (
    <div className="label-wrapper">
      <span>{metricLabel}</span>
      <span className="label-wrapper__normal">{` ${t('tkFor')} `}</span>
      <Tooltip target={groupLabelId} placement="bottom">
        <ul>
          {shareClassNames}
        </ul>
      </Tooltip>
      <span id={groupLabelId} className="label-wrapper__fund">{`${items.length} ${t('tkFunds')}`}</span>
      <span className="label-wrapper__normal">{` ${t('tkare')} `}</span>
      <Conditional condition={lowerThreshold}>
        <>
          <span>{` ${t('tkLessThan').toLowerCase()} `}</span>
          <span>{`${lowerThreshold}${metricValueType}`}</span>
        </>
      </Conditional>
      <Conditional condition={lowerThreshold && upperThreshold}>
        <span className="label-wrapper__normal">{` ${t('tkand')} `}</span>
      </Conditional>
      <Conditional condition={upperThreshold}>
        <>
          <span>{`${t('tkGreaterThan').toLowerCase()} `}</span>
          <span>{`${upperThreshold}${metricValueType}`}</span>
        </>
      </Conditional>
      <Conditional condition={lowerMetricValue && lowerMetricValue}>
        <span className="label-wrapper__light">
          {` (${t('tkCurrently').toLowerCase()}: ${lowerMetricValue}${metricValueType} - ${upperMetricValue}${metricValueType})`}
        </span>
      </Conditional>
    </div>
  );
};

ThresholdGroupLabelRenderer.propTypes = {
  metricLabel: PropTypes.object,
  lowerMetricValue: PropTypes.string,
  upperMetricValue: PropTypes.string,
  metricValueType: PropTypes.string,
  lowerThreshold: PropTypes.func,
  upperThreshold: PropTypes.string,
  items: PropTypes.array
};
