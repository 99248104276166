import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const LeftPanel = ({title, subTitle}) => (
  <div className="left-panel">
    <div className="left-panel--header">{title}</div>
    <div className="left-panel--subheader">{subTitle}</div>
  </div>
);

LeftPanel.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
}
export default LeftPanel;
