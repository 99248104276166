import { PropsWithChildren, AriaAttributes, HTMLAttributes, memo, FC, useEffect } from 'react';
import {
    HeadingCommonProps,
    headingStyleSheet,
    getHeadingRootClassNames,
    defaultHeadingProps,
} from '@gs-ux-uitoolkit-common/text';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { ReactComponentProps } from '@gs-ux-uitoolkit-react/component';
import { componentAnalytics } from './analytics-tracking';

export type HeadingProps = PropsWithChildren<ReactComponentProps & HeadingCommonProps> & {
    /**
     * The HTML tag to use when rendering the text.
     */
    tag?: keyof JSX.IntrinsicElements;
};

/**
 * The Heading component conveniently applies GS Design System typography and colors to your application heading text.
 */
export const Heading: FC<HeadingProps> = memo(
    ({
        typography,
        color,
        fontFamily,
        fontSize,
        fontWeight,
        lineHeight,
        display,
        className,
        classes: overrideClasses,
        disabled,
        tag,
        bold = defaultHeadingProps.bold,
        italic = defaultHeadingProps.italic,
        underline = defaultHeadingProps.underline,
        ...props
    }) => {
        const theme = useTheme();
        const cssClasses = useStyleSheet(headingStyleSheet, {
            theme,
            typography,
            color,
            fontFamily,
            fontSize,
            fontWeight,
            lineHeight,
            display,
            bold,
            italic,
            underline,
            disabled,
        });

        const [HtmlTag, ariaProps] = getHtmlProps({ tag, typography });

        useEffect(() => {
            //track component has rendered
            componentAnalytics.trackRender({ officialComponentName: 'heading' });
        }, []); // Only run once

        return (
            <HtmlTag
                {...props}
                data-gs-uitk-component="heading"
                className={getHeadingRootClassNames({ cssClasses, overrideClasses, className })}
                {...ariaProps}
            />
        );
    }
);
Heading.displayName = 'Heading';

/**
 * Returns a tuple of the HtmlTag and any required ariaProps
 * (tuple is used instead of object for better bundle size).
 *
 * @param props
 */
function getHtmlProps(props: {
    tag: HeadingProps['tag'];
    typography: HeadingProps['typography'];
}): [
    // HtmlTag
    keyof JSX.IntrinsicElements,
    // ariaProps
    AriaAttributes & { role?: HTMLAttributes<any>['role'] },
] {
    const { tag, typography } = props;

    if (tag) {
        // prefer the user's tag choice
        return [tag, {}];
    } else {
        // map the typography choice to an `h` tag
        switch (typography) {
            case 'display01':
            case 'heading01':
                return ['h1', {}];
            case 'display02':
            case 'heading02':
                return ['h2', {}];
            case 'display03':
            case 'heading03':
                return ['h3', {}];
            case 'heading04':
                return ['h4', {}];
            case 'heading05':
                return ['h5', {}];
            case 'heading06':
                return ['h6', {}];
            case 'heading07':
                return ['h6', { role: 'heading', 'aria-level': 7 }];
            default:
                return ['h1', {}];
        }
    }
}
