import React from 'react';
import PropTypes from 'prop-types';
import {Conditional} from '../../../core/Conditional';
import './index.scss';

const Notification = ({item}) => {
  const {message, type} = item;
  return (
    <Conditional condition={message}>
      <div className="trade-row-error__row approval-error" >
        <span className={`trade-row__icon trade-row__icon--${type}`} >&nbsp;</span>
        <span>{message}</span>
      </div>
    </Conditional>
  );
};

Notification.propTypes = {
  item: PropTypes.object
};

export  default Notification;
