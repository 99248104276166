import { StyleSheet } from '@gs-ux-uitoolkit-react/style';
import { DataToolbarSize } from '@gs-ux-uitoolkit-common/datacore';

export interface SavedViewsWidgetStylesheetProps {
    size: DataToolbarSize | undefined;
}

export const savedViewsWidgetStylesheet = new StyleSheet(
    'saved-views',
    (props: SavedViewsWidgetStylesheetProps) => {
        let buttonWidth = 26;
        let selectSize = 127;

        if (props.size === 'md') {
            buttonWidth = 34;
            selectSize = 111; // reduce the size based on two button size increases
        }

        return {
            root: {
                width: ' 100%',
                display: 'flex',
                boxSizing: 'border-box',
                '.gs-uitk-saved-views-widget__add_button button': {
                    borderRight: 'none',
                    maxWidth: `${buttonWidth}px`,
                    width: `${buttonWidth}px`,
                },
                '.gs-uitk-saved-views-widget__add_button,.gs-uitk-saved-views-widget__save_button':
                    {
                        maxWidth: `${buttonWidth}px`,
                        width: `${buttonWidth}px`,
                    },
                '.gs-uitk-saved-views-widget__select': {
                    flex: 1,
                    width: `${selectSize}px`,
                    '.gs-uitk-select-multiple__inner': {
                        maxHeight: '24px',
                    },
                },
            },
        };
    }
);
