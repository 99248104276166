import { arrayHelper, DataType, ExpressionOperator } from '@gs-ux-uitoolkit-common/datacore';
import { SelectOption } from '@gs-ux-uitoolkit-react/select';

export const numberOperators: ExpressionOperator[] = [
    ExpressionOperator.GreaterThan,
    ExpressionOperator.GreaterThanOrEqual,
    ExpressionOperator.LessThan,
    ExpressionOperator.LessThanOrEqual,
    ExpressionOperator.NotEquals,
    ExpressionOperator.Equals,
];

export const dateOperators: ExpressionOperator[] = [
    ExpressionOperator.GreaterThan,
    ExpressionOperator.GreaterThanOrEqual,
    ExpressionOperator.LessThan,
    ExpressionOperator.LessThanOrEqual,
    ExpressionOperator.NotEquals,
    ExpressionOperator.Equals,
];

export const stringOperators: ExpressionOperator[] = [
    ExpressionOperator.Contains,
    ExpressionOperator.ContainsCaseInsensitive,
    ExpressionOperator.NotContains,
    ExpressionOperator.NotContainsCaseInsensitive,
    ExpressionOperator.NotEquals,
    ExpressionOperator.Equals,
];

export class TwoWayMappedObject {
    fwdObject = {};
    revObject = {};

    constructor(object: KeyValuePair) {
        this.fwdObject = { ...object };
        this.revObject = Object.keys(object).reduce(
            (acc, cur) => ({
                ...acc,
                [object[cur]]: cur,
            }),
            {}
        );
    }

    get = (key: string): string | undefined => {
        return (this.fwdObject as KeyValuePair)[key] || (this.revObject as KeyValuePair)[key];
    };
}

interface KeyValuePair {
    [key: string]: string;
}

const operatorsWithInverses = new TwoWayMappedObject({
    [ExpressionOperator.GreaterThan]: ExpressionOperator.LessThanOrEqual,
    [ExpressionOperator.GreaterThanOrEqual]: ExpressionOperator.LessThan,
    [ExpressionOperator.Equals]: ExpressionOperator.NotEquals,
    [ExpressionOperator.Contains]: ExpressionOperator.NotContains,
    [ExpressionOperator.ContainsCaseInsensitive]: ExpressionOperator.NotContainsCaseInsensitive,
});

export class RangeInputHelper {
    public getOperatorOptions(dataType: DataType): SelectOption[] {
        return this.getOperatorList(dataType).map(operator => {
            const foundOperator = Object.entries(ExpressionOperator).find(
                value => value[1] === operator
            );
            let displayName = 'Operator not found.';
            if (foundOperator) {
                displayName = arrayHelper.first(foundOperator) || displayName;
            }
            return { value: operator, label: displayName };
        });
    }

    public getNegatedOperators = (operator: ExpressionOperator): ExpressionOperator | undefined => {
        return operatorsWithInverses.get(operator) as ExpressionOperator;
    };

    public getOperatorList(dataType: DataType): ExpressionOperator[] {
        switch (dataType) {
            case DataType.Number:
                return numberOperators;
            case DataType.DateTime:
            case DataType.Date:
                return dateOperators;
            case DataType.String:
                return stringOperators;
            default:
                return stringOperators;
        }
    }
}

export const rangeInputHelper = new RangeInputHelper();
