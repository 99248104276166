import {useState, useEffect} from 'react';

export function useFormInput(initialValue){
  const [value, setValue] = useState(initialValue);
  function handleChange(event) {
    setValue(event.target.value);
  }

  return {
    value,
    onChange: handleChange
  };
}

const selectParent = (parentId, items) => {
  return items.find((item) => item.nodeId === parentId);
};

export function useToggleParentSelection(lastSelectedItem, items, selectItem, deSelectItem, isAny = false) {
  useEffect(() => {
    const parent = lastSelectedItem && selectParent(lastSelectedItem.parentId, items);
    if(parent) {
      const selectedItemCount = parent.items.filter((item) => item.selected).length;
      const itemCount = parent.items.length;
      return isAny ? selectedItemCount === 0 ? deSelectItem(parent.id) :  selectItem(parent.id)  : (itemCount === selectedItemCount ? selectItem(parent.id) : deSelectItem(parent.id));
    }
  }, [lastSelectedItem]);
}

export function useSetup(options, id, dispatch) {
  useEffect(() => {
    let counter = 0;
    const listItems = options.map((item) => {
      if(typeof item === 'object'){
        const {type = 'select', customClass=''} = item;
        counter++;
        return {
          id: `${id}-item-${counter}`,
          ...item,
          type,
          customClass
        };
      } else {
        return {
          type: 'divider'
        };
      }
    });

    dispatch({type: 'UPDATE_LENGTH', length: counter});
    dispatch({type: 'UPDATE_ITEMS', items: listItems});
    dispatch({type: 'UPDATE_APPLIED_OPTIONS_STATE'});
  }, [options]);
}
