import { LayoutColumns } from './layout-types';

export interface GridBreakpoint {
    margin: number;
    gutter: number;
    columns?: LayoutColumns;
}

export interface Grid {
    xs: GridBreakpoint;
    sm: GridBreakpoint;
    md: GridBreakpoint;
    lg: GridBreakpoint;
    xl: GridBreakpoint;
    xxl: GridBreakpoint;
    xxxl: GridBreakpoint;
}

export interface LayoutGrid {
    standard: Grid;
    compact: Grid;
    spacious: Grid;
}

/**
 * GSDS Grid.
 */
export const grid: LayoutGrid = {
    // Compact is also referred to as Prosumer.
    compact: {
        xs: {
            margin: 8,
            gutter: 8,
            columns: 2,
        },
        sm: {
            margin: 8,
            gutter: 8,
            columns: 6,
        },
        md: {
            margin: 16,
            gutter: 8,
            columns: 8,
        },
        lg: {
            margin: 16,
            gutter: 8,
            columns: 12,
        },
        xl: {
            margin: 16,
            gutter: 8,
            columns: 12,
        },
        xxl: {
            margin: 16,
            gutter: 8,
            columns: 12,
        },
        xxxl: {
            margin: 16,
            gutter: 8,
            columns: 12,
        },
    },
    // Standard is also referred to as Consumer.
    standard: {
        xs: {
            margin: 16,
            gutter: 8,
            columns: 2,
        },
        sm: {
            margin: 16,
            gutter: 16,
            columns: 6,
        },
        md: {
            margin: 24,
            gutter: 16,
            columns: 8,
        },
        lg: {
            margin: 32,
            gutter: 24,
            columns: 12,
        },
        xl: {
            margin: 32,
            gutter: 24,
            columns: 12,
        },
        xxl: {
            margin: 32,
            gutter: 24,
            columns: 12,
        },
        xxxl: {
            margin: 32,
            gutter: 24,
            columns: 12,
        },
    },
    spacious: {
        xs: {
            margin: 16,
            gutter: 12,
            columns: 2,
        },
        sm: {
            margin: 24,
            gutter: 16,
            columns: 6,
        },
        md: {
            margin: 32,
            gutter: 24,
            columns: 8,
        },
        lg: {
            margin: 40,
            gutter: 32,
            columns: 12,
        },
        xl: {
            margin: 40,
            gutter: 32,
            columns: 12,
        },
        xxl: {
            margin: 40,
            gutter: 32,
            columns: 12,
        },
        xxxl: {
            margin: 40,
            gutter: 32,
            columns: 12,
        },
    },
};
