import { Status } from '@gs-ux-uitoolkit-common/design-system';
import { IconBaseProps } from './props';

/**
 * Common icons used across toolkit components. Currently these are not themable as we provide
 * a single icon set. If multiple icon sets are provided (say, font awesome and material), these
 * icons can be made themable. For now, these are used as shared variables to align all components
 * to a shared set of icon for each status.
 */
export const statusIcons: {
    [key in Status]: IconBaseProps;
} = {
    none: {
        name: 'info',
        type: 'outlined',
    },
    information: {
        name: 'info',
        type: 'outlined',
    },
    success: {
        name: 'check-circle',
        type: 'outlined',
    },
    warning: {
        name: 'warning',
        type: 'outlined',
    },
    error: {
        name: 'error',
        type: 'outlined',
    },
    loading: {
        name: 'loading',
        type: 'filled',
    },
};
