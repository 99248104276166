export default {
  'Login': {
    'pageId': 'Login'
  },
  'CreatePassword': {
    'pageId': 'CreatePassword'
  },
  'Logout': {
    'pageId': 'Logout'
  },
  'Error': {
    'pageId': 'Error'
  },
  'CreatePin': {
    'pageId': 'CreatePin'
  },
  'ForgotPassword': {
    'pageId': 'ForgotPassword'
  }
};
