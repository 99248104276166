import React, {useState}  from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import translator from '../../../../../services/translator';
import {LEVEL} from '../../../../../constants/preferences';
const {translate: t} = translator;

const Item = (props) => {

  const {selected, disabled, firmCode, branchCode, level, onClick} = props;
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);

  const handleClick =  (selectedItemValue, selectedItemProps) => {
    onClick && onClick(selectedItemValue, selectedItemProps);
  };

  const textPrefix = level === LEVEL.ACCOUNT ? t('tkAllAccounts') : t('tkSpecificAccounts');
  const textSuffix = `${t('tkinFirmOrBranch')} ${firmCode}${branchCode ? `-${branchCode}` : ''}`;
  return (
    <li
      className={cn('scope-list__item', {'scope-list__item--selected': selected}, {'scope-list__item--hovered': hover},
         {'scope-list__item--disabled': disabled}, {'scope-list__item--focused': focus})}
      onFocus={(e) => {e.stopPropagation(); setFocus(true);}}
      onBlur={(e) => {e.stopPropagation();  setFocus(false);}}
      onMouseEnter={(e) => {e.stopPropagation(); setHover(true);}}
      onMouseLeave={(e) => {e.stopPropagation();  setHover(false);}}>
      <a  className={cn("scope-list__link", {'scope-list__link--disabled': disabled})} onClick={handleClick} disabled={disabled} >{textPrefix}</a>
      <span className="scope-list__text"  >{textSuffix}</span>
    </li>
  );
};

Item.defaultProps = {
  selected: false,
  disabled: false
};

Item.propTypes = {
  firmCode: PropTypes.string,
  branchCode: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  level: PropTypes.string,
  onClick: PropTypes.func
};

export default Item;
