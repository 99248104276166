import {
    CSSProperties,
    InputHTMLAttributes,
    ReactNode,
    MouseEvent,
    FunctionComponent,
    useCallback,
    useEffect,
    useRef,
} from 'react';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import {
    RadioProps as RadioCommonProps,
    radioDefaultProps,
    radioStyleSheet,
    getRadioRootClasses,
    getRadioInputClasses,
    getRadioLabelClasses,
    getRadioContainerClasses,
    getRadioInnerClasses,
    getRadioChildrenClasses,
    RadioChangeEventProps,
    RadioGroupService,
    getRadioUid,
} from '@gs-ux-uitoolkit-common/radio';

import { omit } from 'gs-uitk-lodash';
import { extractDataAttributes } from '@gs-ux-uitoolkit-react/shared';

import { Label } from '@gs-ux-uitoolkit-react/label';
import { componentAnalytics } from './analytics-tracking';

export interface RadioProps
    extends RadioCommonProps<CSSProperties>,
        Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'value' | 'onChange'> {
    /**
     * Custom attributes for the input element used as the radio button.
     *
     * This can be used to set analytics attributes.
     */
    radioInputAttrs?: { [attrName: string]: string };

    /**
     * Children will display as the radio's label.
     */
    children?: ReactNode;

    /**
     * Called when the checked state changes.
     */
    onChange?: (event: RadioChangeEvent) => void;
}

export type RadioChangeEvent = RadioChangeEventProps & MouseEvent<HTMLInputElement>;

/**
 * Radio Buttons allow users to make a single selection from a set of options.
 */
export const Radio: FunctionComponent<RadioProps> = ({
    checked,
    children,
    className,
    classes: overrideClasses,
    disabled,
    name,
    value,
    status = radioDefaultProps.status,
    defaultChecked,
    inline = radioDefaultProps.inline,
    size = radioDefaultProps.size,
    labelPlacement = radioDefaultProps.labelPlacement,
    onChange = () => undefined,
    onClick = () => undefined,
    style,
    title,
    id,
    radioInputAttrs,
    ...otherProps
}) => {
    const dataAttributes = extractDataAttributes(otherProps);
    const nonDataAttributes = omit(otherProps, Object.keys(dataAttributes));

    const theme = useTheme();
    const cssClasses = useStyleSheet(radioStyleSheet, {
        theme,
        status,
        size,
        inline,
        labelPlacement,
    });
    const rootClasses = getRadioRootClasses({
        cssClasses,
        className,
        overrideClasses,
    });
    const inputClasses = getRadioInputClasses({ cssClasses, overrideClasses });
    const radioLabelClasses = getRadioLabelClasses({ cssClasses, overrideClasses });
    const containerClasses = getRadioContainerClasses({ cssClasses, overrideClasses });
    const innerClasses = getRadioInnerClasses({ cssClasses, overrideClasses });
    const childrenClasses = getRadioChildrenClasses({ cssClasses, overrideClasses });

    // Used to reset the input's checked state manually when controlled:
    const inputEl = useRef<HTMLInputElement>(null);

    // Tracks whether the radio has changed on click:
    const inputElChecked = useRef(!!checked);

    const radioGroup = RadioGroupService.instance;
    const prevName = useRef(name);
    const uid = useRef(getRadioUid());

    // Store a "fresh" copy of `checked` so it is not "baked" into onRadioGroupClick():
    const propChecked = useRef(checked);
    useEffect(() => {
        propChecked.current = checked;
    }, [checked]);

    const isControlled = useCallback(() => {
        return checked != undefined;
    }, [checked]);

    useEffect(() => {
        // Remove and add this component to the RadioGroupService when `name` changes.
        function removeFromRadioGroup() {
            if (prevName.current) {
                radioGroup.removeRadio(prevName.current, onRadioGroupClick);
            }
        }
        function onRadioGroupClick(clickUid: string) {
            if (isControlled()) {
                inputElChecked.current = !!propChecked.current;
            } else if (uid.current !== clickUid) {
                if (inputEl.current) {
                    inputElChecked.current = (inputEl.current as HTMLInputElement).checked;
                }
            }
        }
        if (name) {
            radioGroup.addRadio(name, onRadioGroupClick);
        }
        prevName.current = name;
        return removeFromRadioGroup;
    }, [name, isControlled, radioGroup]);

    function onInternalClick(event: MouseEvent<HTMLInputElement>) {
        onClick(event);
        if (!event.isDefaultPrevented()) {
            handleChange(event);
        }
    }

    function handleChange(event: MouseEvent<HTMLInputElement>) {
        if (name) {
            radioGroup.clickRadio(name, uid.current);
        }
        // Change events are sent only when `checked` changes from false to true.
        // `checked` turns true on click, so if inputElChecked is still false then
        // the `checked` has just changed to true as a result of the click, as
        // opposed to having already been true at the time of the click.
        if (!inputElChecked.current) {
            if (!isControlled()) {
                inputElChecked.current = true;
            }
            Object.assign(event, { checked: true, name, value });
            event.persist(); // See https://reactjs.org/docs/legacy-event-pooling.html
            onChange(event as RadioChangeEvent);
            if (isControlled()) {
                // When controlled, the input's checked state is reset to the
                // controlled checked value. Developers can update the checked value
                // in a change event handler.
                inputEl.current!.checked = !!checked;
            }
        }
    }
    /**
     * Avoids this React error:
     * ```
     * Warning: Failed prop type: You provided a `checked` prop to a form field
     * without an `onChange` handler. This will render a read-only field. If the
     * field should be mutable use `defaultChecked`. Otherwise, set either
     * `onChange` or `readOnly`.
     * ```
     */
    function onInternalChange() {}

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'radio' });
    }, []); // Only run once

    return (
        <div
            data-gs-uitk-component="radio"
            data-size={size}
            data-label-placement={labelPlacement}
            className={rootClasses}
            style={style}
            title={title}
            id={id}
            {...dataAttributes}
        >
            <Label
                size={size}
                classes={{ label: radioLabelClasses }}
                disabled={disabled}
                data-cy="gs-uitk-radio__label"
            >
                <span data-cy="gs-uitk-radio__container" className={containerClasses}>
                    <input
                        {...radioInputAttrs}
                        {...nonDataAttributes}
                        type="radio"
                        data-cy="gs-uitk-radio__input"
                        checked={checked}
                        defaultChecked={
                            // defaultChecked should only be set if checked is not set;
                            // i.e., if the input is uncontrolled.
                            checked === undefined ? defaultChecked : undefined
                        }
                        name={name}
                        value={value}
                        className={inputClasses}
                        disabled={disabled}
                        // onClick is used to register changed events to support all browsers:
                        // https://stackoverflow.com/questions/5575338/what-the-difference-between-click-and-change-on-a-checkbox/5575369)
                        onClick={onInternalClick}
                        onChange={onInternalChange}
                        ref={inputEl}
                    />
                    <span data-cy="gs-uitk-radio__inner" className={innerClasses}></span>
                </span>
                <span data-cy="gs-uitk-radio__children" className={childrenClasses}>
                    {children}
                </span>
            </Label>
        </div>
    );
};
