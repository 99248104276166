import { cx } from '@gs-ux-uitoolkit-common/style';
import { AccordionPanelCssClasses } from './accordion-panel-style-sheet';
import { AccordionPanelProps, AccordionProps } from './accordion-props';

const globalAccordionClassPrefix = 'gs-accordion';
const globalAccordionPanelClassPrefix = 'gs-accordion-panel';

export const getAccordionClassNames = (props: {
    overrideClasses?: AccordionProps['classes'];
    className?: AccordionProps['className'];
}): string => {
    const { overrideClasses, className } = props;
    return cx(className, overrideClasses && overrideClasses.root, globalAccordionClassPrefix);
};

export function getAccordionPanelClassNames(props: {
    cssClasses: AccordionPanelCssClasses;
    overrideClasses?: AccordionPanelProps['classes'];
    className?: AccordionPanelProps['className'];
}): string {
    const { cssClasses, className, overrideClasses } = props;
    return cx(
        cssClasses.root,
        className,
        overrideClasses && overrideClasses.root,
        globalAccordionPanelClassPrefix
    );
}

export const getAccordionPanelBodyClassNames = (props: {
    cssClasses: AccordionPanelCssClasses;
    overrideClasses?: AccordionPanelProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.body,
        overrideClasses && overrideClasses.body,
        `${globalAccordionPanelClassPrefix}__body`
    );
};

export const getAccordionPanelHeaderClassNames = (props: {
    cssClasses: AccordionPanelCssClasses;
    overrideClasses?: AccordionPanelProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.header,
        overrideClasses && overrideClasses.header,
        `${globalAccordionPanelClassPrefix}__header`
    );
};

export const getAccordionPanelSummaryClassNames = (props: {
    cssClasses: AccordionPanelCssClasses;
    overrideClasses?: AccordionPanelProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.summary,
        overrideClasses && overrideClasses.summary,
        `${globalAccordionPanelClassPrefix}__summary`
    );
};

export const getAccordionPanelToggleClassNames = (props: {
    cssClasses: AccordionPanelCssClasses;
    overrideClasses?: AccordionPanelProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.toggle,
        overrideClasses && overrideClasses.toggle,
        `${globalAccordionPanelClassPrefix}__toggle`
    );
};
