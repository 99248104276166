import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import translator from '../../../../../services/translator';
import Dropdown from '../../../../../components/core/Dropdown';
import { Conditional } from '../../../../../components/core/Conditional';
import GearButton from '../../../../../components/core/GearButton';
import DownloadButton from '../../../../../components/core/DownloadButton/DownloadButton';
import { toggleInActiveUsers } from '../../actions';
import {
  groupByColumns,
  dropdownViewNamesSelector,
  currentViewSelector,
  getCreateCustomViewCTALabel,
  gridHeader,
  getGridData,
  showInActiveUser,
} from '../../selectors';
import { controlPanleMapDispatchToProps } from '../../../../../modules/Grid/mapDispatchToProps';
import { MODEL_ACTION, MAX_VIEWS } from '../../../../../constants/pageConstants';

import Checkbox from '../../../../../components/core/CheckBox';
import './index.scss';

const { translate: t } = translator;

const ControlPanel = props => {
  const { header, groupByList, views, currentView, customViewCTALabel, inActiveUser, data = [] } = props;

  const handleGroupChange = (value, selectedItems) => {
    props.dispatchGroupChange({ value, selectedItems, currentView });
  };

  const handleViewChange = (selectedValue, selectedItems) => {
    props.dispatchViewChange(selectedValue, selectedItems.label, header);
  };

  const handleDownloadExcel = () => props.dispatchDownloadExcel(props);

  const handleViewEdit = () => {
    props.dispatchOpenCustomViewModal(true, MODEL_ACTION.EDIT, currentView);
  };

  const handleViewAdd = () => {
    props.dispatchOpenCustomViewModal(true, MODEL_ACTION.CREATE, currentView);
  };

  const handleInactiveUsers = (_, value) => {
    props.toggleInActiveUsers(value);
  };

  return (
    <Conditional condition={currentView && currentView.columns && currentView.columns.length}>
      <div className='usersearch__controlpanel'>
        <div className='controlpanel-left'>
          <Dropdown
            id='usersearch-groups'
            onSelect={handleGroupChange}
            options={groupByList}
            placeholder={t('tkGroupByColonCaps')}
            prepender={t('tkGroupByColonCaps')}
          />
          <Dropdown
            id='usersearch-views'
            onSelect={handleViewChange}
            options={views}
            placeholder={t('tkViewColon')}
            prepender={t('tkViewColon')}
            onFooterCTAClick={handleViewAdd}
            footerCTA={true}
            footerCTAText={customViewCTALabel}
            footerCTADisabled={customViewCTALabel === t(MAX_VIEWS.name)}
          />
          <Checkbox
            id='toggle-inactive-users'
            value={`${!!inActiveUser}`}
            isChecked={!!inActiveUser}
            label={t('tkIncludeInactiveUsers')}
            changeHandler={handleInactiveUsers}
          />
        </div>
        <div className='controlpanel-right'>
          <GearButton onClick={handleViewEdit} />
          <DownloadButton disabled={!data.length} onClick={handleDownloadExcel} onKeyPress={handleDownloadExcel} />
        </div>
      </div>
    </Conditional>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  return {
    header: gridHeader(state, id),
    groupByList: groupByColumns(state, id),
    views: dropdownViewNamesSelector(state, id),
    currentView: currentViewSelector(state, id),
    customViewCTALabel: getCreateCustomViewCTALabel(state, id),
    data: getGridData(state, id),
    inActiveUser: showInActiveUser(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    ...controlPanleMapDispatchToProps(dispatch, ownProps),
    toggleInActiveUsers: data => dispatch(toggleInActiveUsers(data)),
  };
};

ControlPanel.propTypes = {
  groupByList: PropTypes.array,
  views: PropTypes.array,
  currentView: PropTypes.object,
  dispatchGroupChange: PropTypes.func,
  dispatchViewChange: PropTypes.func,
  dispatchDownloadExcel: PropTypes.func,
  dispatchOpenCustomViewModal: PropTypes.func,
  customViewCTALabel: PropTypes.string,
  inActiveUser: PropTypes.bool,
  toggleInActiveUsers: PropTypes.func,
  data: PropTypes.array,
  header: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlPanel);
