import { cx } from '@gs-ux-uitoolkit-common/style';
import { ButtonSelectCommonProps } from './button-select-props';
import { ButtonSelectCssClasses } from './button-select-style-sheet';

const globalClassName = 'gs-button-select';

export const getButtonSelectRootClassNames = (props: {
    cssClasses: ButtonSelectCssClasses;
    overrideClasses?: ButtonSelectCommonProps['classes'];
    className?: ButtonSelectCommonProps['className'];
}): string => {
    const { cssClasses, overrideClasses, className } = props;

    return cx(
        cssClasses.root,
        cssClasses.size,
        className,
        overrideClasses && overrideClasses.root,
        globalClassName
    );
};

export const getButtonSelectLabelClassNames = (props: {
    cssClasses: ButtonSelectCssClasses;
    overrideClasses?: ButtonSelectCommonProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;

    return cx(
        cssClasses.label,
        cssClasses.focusBorder,
        overrideClasses && overrideClasses.label,
        `${globalClassName}__label`
    );
};

export const getButtonSelectInputClassNames = (props: {
    cssClasses: ButtonSelectCssClasses;
    overrideClasses?: ButtonSelectCommonProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;

    return cx(
        cssClasses.input,
        overrideClasses && overrideClasses.input,
        `${globalClassName}__input`
    );
};

export const getButtonSelectTargetClassNames = (props: {
    cssClasses: ButtonSelectCssClasses;
    overrideClasses?: ButtonSelectCommonProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;

    return cx(
        cssClasses.target,
        overrideClasses && overrideClasses.target,
        `${globalClassName}__target`
    );
};
