import { StyleSheet, CssClasses } from '@gs-ux-uitoolkit-react/style';

export type PluginHeaderCssClasses = CssClasses<typeof pluginHeaderStyleSheet>;

export const pluginHeaderStyleSheet = new StyleSheet('pluginHeader', () => {
    return {
        root: {
            justifyContent: 'space-between',
            '.plugin-header-title': {
                marginTop: 0,
                fontWeight: 400,
            },
            '.gs-uitk-create-button-container': {
                marginTop: 'auto',
                marginBottom: '0.75rem',
            },
            '.plugin-header-subtitle': {
                marginTop: '0.25rem',
                marginBottom: '0.75rem',
            },

            '.plugin-header-with-secondary-action': {
                marginBottom: '2rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',

                '.gs-uitk-create-button-container': {
                    marginBottom: '0',
                },
            },
        },
    };
});
