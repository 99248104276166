import ServiceManager from '../../../services';
import endPointsMapper from '../../../configs/endPointsMapper';
import {orderShareclassPerformance} from '../utils'

const fetchDataFromService = async (payLoad, endPointConfig) => {
  const response = await ServiceManager({
    endPointConfig,
    payLoad
  });
  const {data} = response || {};
  const responseData = data || response;
  const error = (responseData && responseData.error || response && response.error);
  if (error) {
    return Promise.reject(error);
  }
  return responseData;
};

const fetchShareClassesAndBenchmarkData = async ({userGuid}) => {
  const payLoad = { userGuid };
  const responseData = await fetchDataFromService(payLoad, endPointsMapper.FETCH_SHARE_CLASSES_AND_BENCHMARKS);
  const {getShareClassesAndBenchmarksForUser} = responseData;
  return getShareClassesAndBenchmarksForUser;
};

const fetchChartData = async ({startDate, endDate, shareclassIds, field}) => {
  const payLoad = { startDate, endDate, shareclassIds, field };
  const serviceResponse = await fetchDataFromService(payLoad, endPointsMapper.FETCH_FUND_TRACKER_WIZARD_DATA);
  let {fundTracker} = serviceResponse;
  if (fundTracker) {
    fundTracker = orderShareclassPerformance(fundTracker, shareclassIds, "shareclass.id")
  }
  return fundTracker;
};

const fetchBenchmarkChartData = ({startDate, endDate, benchmarkIds, field}) => {
  const payLoad = { startDate, endDate, benchmarkIds, field };
  const serviceResponse = fetchDataFromService(payLoad, endPointsMapper.FETCH_FUND_TRACKER_BENCHMARK);
  return serviceResponse.then(responseData => {
    const {fundTrackerBenchmark} = responseData;
    return fundTrackerBenchmark;
  });
};

export const saveFundTrackerThresholdForUser = (thresholdPayload) => {
  return fetchDataFromService(thresholdPayload, endPointsMapper.SAVE_FUND_TRACKER_THRESHOLD);
};

export const getFundTrackerThresholdsForUser = (userGuid) => {
  const endPointConfig = {
    ...endPointsMapper.FETCH_FUND_TRACKER_THRESHOLDS,
    path: endPointsMapper.FETCH_FUND_TRACKER_THRESHOLDS.path.replace('{userGuid}', userGuid)
  };
  return fetchDataFromService(null, endPointConfig);
};

const getShareClassPerformanceData = ({ asOfDate,  cusips = [], isins = [] }) => {
  const payLoad = {include: 'all', asOfDate};
  if (cusips.length > 0) {
    payLoad.cusip = cusips.join(',');
  }
  if (isins.length > 0) {
    payLoad.isin = isins.join(',');
  }
  const serviceResponse = fetchDataFromService(payLoad, endPointsMapper.FETCH_SHARE_CLASS_PERFORMANCE);
  return serviceResponse.then(responseData => {
    return responseData;
  })
};
const fetchExportData = ({startDate, endDate, shareclassIds, field}) => {
  const payLoad = { startDate, endDate, shareclassIds, field };
  const serviceResponse = fetchDataFromService(payLoad, endPointsMapper.FETCH_FUND_TRACKER_EXPORT_DATA);
  return serviceResponse.then(responseData => {
    const {fundTrackerExport} = responseData;
    return fundTrackerExport;
  });
};

export {
  fetchChartData,
  fetchBenchmarkChartData,
  fetchShareClassesAndBenchmarkData,
  getShareClassPerformanceData,
  fetchExportData
};
