import { LoadingIconCssClasses } from './loading-icon-style-sheet';
import { LoadingIconProps, LoadingIconSize, loadingIconDefaultProps } from './loading-icon-props';
import { cx } from '@gs-ux-uitoolkit-common/style';

const globalClassName = 'gs-loading-icon';

export type LoadingIconPoint =
    | 'center'
    | 'corner-top-left'
    | 'corner-top-right'
    | 'corner-bottom-left'
    | 'corner-bottom-right';

export const getLoadingIconClasses = (props: {
    cssClasses: LoadingIconCssClasses;
    overrideClasses?: LoadingIconProps['classes'];
    className?: LoadingIconProps['className'];
}): string => {
    const { cssClasses, overrideClasses, className } = props;
    return cx(cssClasses.root, className, overrideClasses && overrideClasses.root, globalClassName);
};

export const getLoadingIconPulseClasses = (props: {
    cssClasses: LoadingIconCssClasses;
    overrideClasses?: LoadingIconProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses['pulse-icon'],
        overrideClasses && overrideClasses['pulse-icon'],
        `${globalClassName}__pulse-icon`
    );
};

export const getLoadingIconPointClasses = (props: {
    cssClasses: LoadingIconCssClasses;
    overrideClasses?: LoadingIconProps['classes'];
    point: LoadingIconPoint;
}): string => {
    const { cssClasses, overrideClasses, point } = props;
    return point === 'center'
        ? cx(
              cssClasses.center,
              overrideClasses && overrideClasses.center,
              `${globalClassName}__center`
          )
        : cx(
              (cssClasses as any)[point],
              overrideClasses && overrideClasses[point],
              `${globalClassName}__${point}`
          );
};

export const getLoadingIconLabelClasses = (props: {
    cssClasses: LoadingIconCssClasses;
    overrideClasses?: LoadingIconProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.label,
        overrideClasses && overrideClasses.label,
        `${globalClassName}__label`
    );
};

export const getLoadingIconCircleClasses = (props: {
    cssClasses: LoadingIconCssClasses;
    overrideClasses?: LoadingIconProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses['circle-icon'],
        overrideClasses && overrideClasses['circle-icon'],
        `${globalClassName}circle-icon`
    );
};

export const getCircleIconSvgAttributes = (props: {
    size: LoadingIconProps['size'];
}): { svgSize: string; viewBox: string } => {
    const { size = loadingIconDefaultProps.size } = props;

    const svgSize = circleSvgSizeVariants[size].svgSize;
    const viewBox = circleSvgSizeVariants[size].viewBox;

    return { svgSize, viewBox };
};

export const getSquareIconSvgAttributes = (props: {
    size: LoadingIconProps['size'];
}): { svgSize: string; viewBox: string } => {
    const { size = loadingIconDefaultProps.size } = props;

    const svgSize = squareSvgSizeVariants[size].svgSize;
    const viewBox = squareSvgSizeVariants[size].viewBox;

    return { svgSize, viewBox };
};

const circleSvgSizeVariants: { [name in LoadingIconSize]: CircleSvgSizeVariant } = {
    sm: {
        svgSize: '24px',
        viewBox: '0 0 64 64',
    },
    md: {
        svgSize: '48px',
        viewBox: '0 0 116 116',
    },
    lg: {
        svgSize: '64px',
        viewBox: '0 0 152 152',
    },
};

const squareSvgSizeVariants: { [name in LoadingIconSize]: SquareSvgSizeVariant } = {
    sm: {
        svgSize: '16px',
        viewBox: '0 0 100 100',
    },
    md: {
        svgSize: '32px',
        viewBox: '0 0 100 100',
    },
    lg: {
        svgSize: '64px',
        viewBox: '0 0 100 100',
    },
};

interface CircleSvgSizeVariant {
    svgSize: string;
    viewBox: string;
}

interface SquareSvgSizeVariant {
    svgSize: string;
    viewBox: string;
}
