export const ROW_HEIGHT = 38;

export const config = {
  groupUseEntireRow: false,
  enableRangeSelection: true,
  suppressAggFuncInHeader: true,
  enableSorting: true,
  // overlayNoRowsTemplate :  'Your search returned [0] results.. Please check your search term(s) and try again.' ,
  floatingFilter: true,
  floatingFiltersHeight: 38,
  enableFilter: true,
  groupMultiAutoColumn: false,
  suppressPropertyNamesCheck: true,
  groupDefaultExpanded: 3,
  pagination: false,
  suppressCsvExport: true,
  groupSelectsFiltered: true,
  suppressBrowserResizeObserver: true,
  enableColResize: true,
  pivotPanelShow: false,
  suppressMovableColumns: false,
  isResponsive: true,
  rowSelection: 'multiple',
  enableServerSideFilter: true,
  rowHeight: `${ROW_HEIGHT}`,
  headerHeight: '68',
  suppressAutoSize: false,
  autoSizePadding: 4,
  rememberGroupStateWhenNewData: true,
  enableServerSideSorting: false,
  cacheQuickFilter: true,
  stopEditingWhenGridLosesFocus: true,
  groupSuppressAutoColumn: false,
  groupSuppressBlankHeader: false,
  suppressContextMenu: true,
  suppressDragLeaveHidesColumns: false,
  suppressRowClickSelection: true,
  groupRowRendererParams: { suppressCount: true, checkbox: false },
  suppressMakeColumnVisibleAfterUnGroup: true,
  animateRows: false,
  rowBuffer: 5,
  suppressAnimationFrame: true,
  rowMultiSelectWithClick: true,
  suppressColumnVirtualisation: process.env.NODE_ENV === 'test', // Virtualisation enabled only for test cases to test ag grid columns
  isRowSelectable: function (rowNode) {
    return rowNode.group === false;
  },
  alignedGrids: false,
  suppressCopyRowsToClipboard: true,
  columnPriority: 'default',
  agGridTheme: 'ag-theme-balham',
};

export const toolKitConfig = {
  rowHeight: '28',
  headerHeight: '28',
};
