import { cx } from '@gs-ux-uitoolkit-common/style';
import { LinkCssClasses } from './link-style-sheet';

const globalClassName = 'gs-link';

export const getLinkRootClassNames = (props: {
    cssClasses: LinkCssClasses;
    className?: string;
}): string => {
    const { cssClasses, className } = props;
    return cx(cssClasses.root, cssClasses.anchor, className, globalClassName);
};
