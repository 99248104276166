import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';
import Dropdown from '../../../../components/core/Dropdown';
import translator from '../../../../services/translator';
import {dropDownLabelTemplateTks} from '../../../../utils/reportingUtils';

const {translate: t} = translator;

const dropdownLabelTemplate = (selected, all) => {
  const [firstItem = {}] = all;
  const {nodeId = ''} = firstItem;
  const splitStr = nodeId.includes('all') ? 'all' : 'flatList';
  const [itemId] = nodeId.split(splitStr).filter(item => item);
  const dropDownLabelTemplateTk = dropDownLabelTemplateTks[itemId];
  if (dropDownLabelTemplateTk) {
    const [tk1, tk2, tk3] = dropDownLabelTemplateTk;
    if (!tk3) {
      if (selected.length + 1 >= all.length) {
        return startCase(t(tk1).toLowerCase());
      } else if (selected.length === 1) {
        return `${t(tk2)}: ${selected[0].label}`;
      } else if (selected.length === 2) {
        return `${t(tk2)}: ${selected[0].label} & ${selected[1].label}`;
      } else if (selected.length === 3) {
        return `${t(tk2)}: ${selected[0].label}, ${selected[1].label}, ${selected[2].label}`;
      } else {
        return `${t(tk2)}: ${selected.length} ${t(tk2).toUpperCase()}`;
      }
    } else {
      return `${t(tk1)}: ${selected[0].label}`;
    }
  }
  return '';
};

const DropDownItem = React.memo((props) => {
  const {item, handleClick} = props;
  const {itemId, items, dropDownType = 'tree'} = item;
  const handleOnApply = useCallback((selectedItems) => {
    handleClick(itemId, selectedItems);
  }, []);
  const handleSelectItem = useCallback((selectedItem) => {
    handleClick(itemId, [{value: selectedItem}]);
  }, []);

  return (
    <Dropdown
      type={dropDownType}
      id={`id-${itemId}`}
      indentationPadding={28}
      multiSelect={!!dropDownType}
      onApply={handleOnApply}
      onSelect={handleSelectItem}
      labelTemplate={dropdownLabelTemplate}
      disabled={!items || items.length < 2}
      options={items}
      footerCTAText={t('tkApplyChanges')}
    />
  );
});

DropDownItem.propTypes = {
  item: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired
};

export default DropDownItem;
