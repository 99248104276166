import { Component, HTMLAttributes, CSSProperties, ReactNode } from 'react';
import { Fade } from '@gs-ux-uitoolkit-react/fade';
import { extractDataAttributes } from '@gs-ux-uitoolkit-react/shared';
import { extractAriaAttributes } from '@gs-ux-uitoolkit-react/shared';
import {
    ModalProps as CommonModalProps,
    modalDefaultProps as modalCommonDefaultProps,
    modalStyleSheet,
    getDocumentBodyClasses,
    getModalClasses,
    getModalDialogClasses,
    getModalContentClasses,
    getModalBackdropClasses,
    getModalWrapperClasses,
    ModalCssClasses,
    modalClassNameSelector,
} from '@gs-ux-uitoolkit-common/modal';
import { Theme, ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';
import { SsrCompatiblePortal } from '@gs-ux-uitoolkit-react/shared';
import { ModalSizeContext } from './modal-size-context';
import { componentAnalytics } from './analytics-tracking';
import { EmotionInstanceContext, StyleSheet } from '@gs-ux-uitoolkit-react/style';

const focusableElements = [
    'a[href]',
    'area[href]',
    'input:not([disabled]):not([type=hidden])',
    'select:not([disabled])',
    'textarea:not([disabled])',
    'button:not([disabled])',
    'object',
    'embed',
    '[tabindex]:not(.modal)',
    'audio[controls]',
    'video[controls]',
    '[contenteditable]:not([contenteditable="false"])',
];

function getScrollbarWidth() {
    const scrollDiv = document.createElement('div');
    scrollDiv.style.position = 'absolute';
    scrollDiv.style.top = '-9999px';
    scrollDiv.style.width = '50px';
    scrollDiv.style.height = '50px';
    scrollDiv.style.overflow = 'scroll';
    document.body.appendChild(scrollDiv);
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    return scrollbarWidth;
}

function setScrollbarWidth(padding: number) {
    (document as any).body.style.paddingRight = padding > 0 ? `${padding}px` : null;
}

function isBodyOverflowing() {
    return document.body.clientWidth < window.innerWidth;
}

function conditionallyUpdateScrollbar() {
    const scrollbarWidth = getScrollbarWidth();
    const fixedContent = document.querySelectorAll(
        '.fixed-top, .fixed-bottom, .is-fixed, .sticky-top'
    )[0] as HTMLElement;
    const bodyPadding = fixedContent ? parseInt(fixedContent.style.paddingRight || '0', 10) : 0;

    if (isBodyOverflowing()) {
        setScrollbarWidth(bodyPadding + scrollbarWidth);
    }
}

export interface ModalProps extends CommonModalProps<CSSProperties>, HTMLAttributes<HTMLElement> {
    /**
     * Focuses the modal when it is shown.
     */
    autoFocus?: boolean;

    /**
     * Content to display inside the modal.
     */
    children?: ReactNode;

    /**
     * Controls the modal's visibility.
     */
    visible?: boolean;

    /**
     * Refocuses the modal trigger element after the modal is hidden.
     */
    returnFocusAfterHide?: boolean;

    /**
     * Removes the modal from DOM after being hidden.
     */
    unmountOnHide?: boolean;

    /**
     * Called when the modal is about to be shown.
     */
    onBeforeShow?: () => void;

    /**
     * Called after the modal is shown.
     */
    onShow?: () => void;

    /**
     * Called when the modal is about to be hidden.
     */
    onBeforeHide?: () => void;

    /**
     * Called after the modal is hidden.
     */
    onHide?: () => void;
}

export interface ModalState {
    visible: boolean;
}

export type ModalDefaultProps = Required<
    Pick<
        ModalProps,
        | 'backdrop'
        | 'hideOnEscape'
        | 'size'
        | 'placement'
        | 'scrollable'
        | 'autoFocus'
        | 'returnFocusAfterHide'
        | 'unmountOnHide'
    >
>;
export const modalDefaultProps: ModalDefaultProps = {
    backdrop: modalCommonDefaultProps.backdrop,
    hideOnEscape: modalCommonDefaultProps.hideOnEscape,
    placement: modalCommonDefaultProps.placement,
    scrollable: modalCommonDefaultProps.scrollable,
    size: modalCommonDefaultProps.size,
    autoFocus: true,
    returnFocusAfterHide: true,
    unmountOnHide: true,
};

/**
 * A modal dialog displays content on top of the screen and requires user action.
 */
export class Modal extends Component<ModalProps, ModalState> {
    private element?: HTMLElement;
    private originalBodyPadding: number = 0;
    private isModalMounted: boolean = false;
    private triggeringElement?: HTMLElement;
    private mouseDownElement?: EventTarget;
    private mountContainer?: HTMLElement;
    private dialog?: HTMLDivElement;
    private cssClasses!: ModalCssClasses;

    static visibleCount: number = 0;
    static defaultProps: ModalProps = {
        ...modalDefaultProps,
    };

    constructor(props: ModalProps) {
        super(props);

        this.getFocusableChildren = this.getFocusableChildren.bind(this);
        this.handleBackdropClick = this.handleBackdropClick.bind(this);
        this.handleBackdropMouseDown = this.handleBackdropMouseDown.bind(this);
        this.handleEscape = this.handleEscape.bind(this);
        this.handleTab = this.handleTab.bind(this);
        this.onShow = this.onShow.bind(this);
        this.onHide = this.onHide.bind(this);
        this.manageFocusAfterHide = this.manageFocusAfterHide.bind(this);

        this.state = {
            visible: false,
        };
    }

    componentDidMount() {
        const { visible, autoFocus, onBeforeShow } = this.props;

        if (visible) {
            this.init();
            this.setState({ visible: true });
            if (autoFocus) {
                this.setFocus();
            }
        }

        if (onBeforeShow) {
            onBeforeShow();
        }

        this.isModalMounted = true;

        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'modal' });
    }

    componentDidUpdate(prevProps: ModalProps, prevState: ModalState) {
        if (this.props.visible && !prevProps.visible) {
            this.init();
            this.setState({ visible: true });
            // let render() renders Modal Dialog first
            return;
        }

        // now Modal Dialog is rendered and we can refer this.element and this.dialog
        if (this.props.autoFocus && this.state.visible && !prevState.visible) {
            this.setFocus();
        }
    }

    componentWillUnmount() {
        if (this.props.onBeforeHide) {
            this.props.onBeforeHide();
        }

        if (this.element) {
            this.destroy();
            if (this.props.visible || this.state.visible) {
                this.hide();
            }
        }

        this.isModalMounted = false;
        modalStyleSheet.unmount(this);
    }

    onShow() {
        if (this.props.onShow) {
            this.props.onShow();
        }
    }

    onHide() {
        const { unmountOnHide } = this.props;
        // so all methods get called before it is unmounted
        if (this.props.onHide) {
            this.props.onHide();
        }

        if (unmountOnHide) {
            this.destroy();
        }
        this.hide();

        if (this.isModalMounted) {
            this.setState({ visible: false });
        }
    }

    setFocus() {
        if (
            this.dialog &&
            this.dialog.parentElement &&
            typeof this.dialog.parentElement.focus === 'function'
        ) {
            this.dialog.parentElement.focus();
        }
    }

    getFocusableChildren(): NodeListOf<HTMLElement> | null {
        return this.element ? this.element.querySelectorAll(focusableElements.join(', ')) : null;
    }

    getFocusedChild() {
        let currentFocus;
        const focusableChildren = this.getFocusableChildren();

        try {
            currentFocus = document.activeElement;
        } catch (err: any) {
            currentFocus = focusableChildren ? focusableChildren[0] : undefined;
        }
        return currentFocus;
    }

    // not mouseUp because scrollbar fires it, shouldn't hide when user scrolls
    handleBackdropClick(e: MouseEvent) {
        if (e.target === this.mouseDownElement) {
            const backdrop = this.dialog ? this.dialog.parentNode : null;

            if (!this.props.visible || this.props.backdrop!.toString() !== 'true') {
                return;
            }

            if (backdrop && e.target === backdrop && this.props.onVisibilityToggle) {
                e.stopPropagation();
                this.props.onVisibilityToggle({
                    reason: 'backdropClick',
                    target: e.target,
                });
            }
        }
    }

    handleTab(e: KeyboardEvent) {
        if (e.key !== 'Tab') {
            return;
        }
        // removes the wrapper element which should not be focusable
        const focusableChildren = Array.from(this.getFocusableChildren() || []).filter(
            node => !node.classList.contains(modalClassNameSelector)
        );

        if (!focusableChildren || focusableChildren.length === 0) {
            return;
        }
        const totalFocusable = (focusableChildren || []).length;
        const currentFocus = this.getFocusedChild();

        let focusedIndex = 0;
        for (let i = 0; i < totalFocusable; i += 1) {
            if (focusableChildren[i] === currentFocus) {
                focusedIndex = i;
                break;
            }
        }

        if (e.shiftKey && focusedIndex === 0) {
            e.preventDefault();
            focusableChildren[totalFocusable - 1].focus();
        } else if (!e.shiftKey && focusedIndex === totalFocusable - 1) {
            e.preventDefault();
            focusableChildren[0].focus();
        }
    }

    handleBackdropMouseDown(e: MouseEvent) {
        this.mouseDownElement = e.target || undefined;
    }

    handleEscape(e: KeyboardEvent) {
        if (this.props.visible && e.keyCode === 27 && this.props.onVisibilityToggle) {
            if (this.props.hideOnEscape) {
                e.preventDefault();
                e.stopPropagation();

                this.props.onVisibilityToggle({
                    reason: 'esc',
                    target: e.target,
                });
            } else if (this.props.backdrop === 'static') {
                e.preventDefault();
                e.stopPropagation();
            }
        }
    }

    init() {
        try {
            this.triggeringElement = document.activeElement as HTMLElement;
        } catch (err: any) {
            this.triggeringElement = undefined;
        }

        if (!this.element) {
            this.element = document.createElement('div');
            this.element.setAttribute(
                'class',
                getModalWrapperClasses({
                    cssClasses: this.cssClasses,
                    overrideClasses: this.props.classes,
                })
            );
            this.element.setAttribute('tabindex', '-1');
            this.mountContainer = document.querySelector('body') || undefined;
            if (this.mountContainer) {
                this.mountContainer.appendChild(this.element);
            }
        }

        const style = window.getComputedStyle(document.body, null);
        this.originalBodyPadding = parseInt(
            (style && style.getPropertyValue('padding-right')) || '0',
            10
        );

        conditionallyUpdateScrollbar();

        if (Modal.visibleCount === 0) {
            document.body.className = getDocumentBodyClasses({
                cssClasses: this.cssClasses,
                className: document.body.className,
                overrideClasses: this.props.classes,
            });
        }

        Modal.visibleCount += 1;
    }

    destroy() {
        if (this.element) {
            if (this.mountContainer) {
                this.mountContainer.removeChild(this.element);
            }
            this.element = undefined;
        }

        this.manageFocusAfterHide();
    }

    manageFocusAfterHide() {
        if (this.triggeringElement) {
            const { returnFocusAfterHide } = this.props;
            if (this.triggeringElement.focus && returnFocusAfterHide)
                this.triggeringElement.focus();
            this.triggeringElement = undefined;
        }
    }

    hide() {
        if (Modal.visibleCount <= 1) {
            const documentScrollLockClassName = this.cssClasses.documentScrollLock;
            // Use regex to prevent matching `gs-uitk-modal-document-scroll-lock` as part of a different class, e.g. `document-scroll-lock`
            const documentScrollLockClassNameRegex = new RegExp(
                `(^| )${documentScrollLockClassName}( |$)`
            );
            document.body.className = document.body.className
                .replace(documentScrollLockClassNameRegex, ' ')
                .trim();
        }
        this.manageFocusAfterHide();
        Modal.visibleCount = Math.max(0, Modal.visibleCount - 1);

        setScrollbarWidth(this.originalBodyPadding);
    }

    private setDialogEl = (dialog: HTMLDivElement) => {
        this.dialog = dialog;

        // Focus on the dialog element, if the autoFocus prop is true and it is visible
        if (this.props.autoFocus && this.state.visible) {
            this.setFocus();
        }
    };

    renderModalDialog() {
        const { id, title } = this.props;
        const dataAttrs = extractDataAttributes(this.props);
        const ariaAttrs = extractAriaAttributes(this.props);

        return (
            <ModalSizeContext.Provider value={this.props.size || modalDefaultProps.size}>
                <div
                    {...dataAttrs}
                    {...ariaAttrs}
                    data-gs-uitk-component="modal"
                    className={getModalDialogClasses({
                        cssClasses: this.cssClasses,
                        className: this.props.className,
                        overrideClasses: this.props.classes,
                    })}
                    id={id}
                    title={title}
                    data-size={this.props.size}
                    style={this.props.style}
                    role="document"
                    ref={this.setDialogEl}
                >
                    <div
                        className={getModalContentClasses({
                            cssClasses: this.cssClasses,
                            overrideClasses: this.props.classes,
                        })}
                    >
                        {this.props.children}
                    </div>
                </div>
            </ModalSizeContext.Provider>
        );
    }

    render() {
        return (
            <EmotionInstanceContext.Consumer>
                {emotionInstance => (
                    <ThemeConsumer>
                        {(theme: Theme) => {
                            const {
                                unmountOnHide,
                                size = modalDefaultProps.size,
                                placement = modalDefaultProps.placement,
                            } = this.props;
                            this.cssClasses = modalStyleSheet.mount(
                                this,
                                { theme, size, placement },
                                emotionInstance
                            );

                            if (!!this.element && (this.state.visible || !unmountOnHide)) {
                                const isModalHidden =
                                    !!this.element && !this.state.visible && !unmountOnHide;
                                this.element.style.display = isModalHidden ? 'none' : 'block';

                                const { backdrop, visible, classes: overrideClasses } = this.props;
                                const { cssClasses } = this;

                                const modalAttributes = {
                                    onClick: this.handleBackdropClick,
                                    onMouseDown: this.handleBackdropMouseDown,
                                    onKeyUp: this.handleEscape,
                                    onKeyDown: this.handleTab,
                                    style: { display: 'block' },
                                    tabIndex: '-1',
                                };

                                const transition = 'transform .3s ease-out';
                                const fadeStyleSheet = new StyleSheet('modal-fade', {
                                    fade: {
                                        transition: 'opacity 0.15s linear',
                                        '> div': {
                                            transition,
                                        },
                                    },
                                    show: {
                                        '> div': {
                                            transform: 'none',
                                            transition,
                                        },
                                    },
                                });
                                const fadeClasses = fadeStyleSheet.mount(
                                    this,
                                    null,
                                    emotionInstance
                                );

                                const modalTransition = {
                                    tag: 'div',
                                    baseClass: fadeClasses.fade,
                                    baseClassActive: fadeClasses.show,
                                    timeout: 150,
                                    appear: true,
                                    enter: true,
                                    exit: true,
                                    in: true,
                                };
                                const Backdrop = backdrop && (
                                    <div
                                        className={getModalBackdropClasses({
                                            cssClasses,
                                            overrideClasses,
                                        })}
                                    />
                                );

                                return (
                                    <SsrCompatiblePortal parentElement={this.element}>
                                        <Fade
                                            {...modalAttributes}
                                            {...modalTransition}
                                            in={visible}
                                            onEntered={this.onShow}
                                            onExited={this.onHide}
                                            className={getModalClasses({
                                                cssClasses,
                                                overrideClasses,
                                            })}
                                        >
                                            {this.renderModalDialog()}
                                        </Fade>
                                        {Backdrop}
                                    </SsrCompatiblePortal>
                                );
                            }
                            return null;
                        }}
                    </ThemeConsumer>
                )}
            </EmotionInstanceContext.Consumer>
        );
    }
}
