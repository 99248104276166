import {
    AddConfigItemAction,
    CancelEditedConfigItemAction,
    EditConfigItemAction,
    NewConfigItemAction,
    RemoveConfigItemAction,
    SaveEditedConfigItemAction,
    UpdateEditedConfigItemAction,
} from '@gs-ux-uitoolkit-common/datacore';
import { CustomSort } from '../../plugins/custom-sort/custom-sort-plugin';
import { CustomSortActionKeys } from './action-constants';

export type CustomSortActionTypes =
    | RemoveCustomSortAction
    | AddCustomSortAction
    | AddCustomSortListAction
    | NewCustomSortAction
    | EditCustomSortAction
    | UpdateEditedCustomSortAction
    | SaveEditedCustomSortAction
    | CancelEditedCustomSortAction
    | SetAbsoluteSortStatusAction;

export interface RemoveCustomSortAction extends RemoveConfigItemAction<CustomSort> {
    type: CustomSortActionKeys.REMOVE_CUSTOM_SORT;
}

export const RemoveCustomSort = (customSort: CustomSort): RemoveCustomSortAction => ({
    configItem: customSort,
    type: CustomSortActionKeys.REMOVE_CUSTOM_SORT,
});

export interface EditCustomSortAction extends EditConfigItemAction<CustomSort> {
    type: CustomSortActionKeys.EDIT_CUSTOM_SORT;
}

export const EditCustomSort = (customSort: CustomSort): EditCustomSortAction => ({
    configItem: customSort,
    type: CustomSortActionKeys.EDIT_CUSTOM_SORT,
});

export interface NewCustomSortAction extends NewConfigItemAction {
    type: CustomSortActionKeys.NEW_CUSTOM_SORT;
}

export const NewCustomSort = (): NewCustomSortAction => ({
    type: CustomSortActionKeys.NEW_CUSTOM_SORT,
});

export interface UpdateEditedCustomSortAction extends UpdateEditedConfigItemAction<CustomSort> {
    type: CustomSortActionKeys.UPDATE_EDITED_CUSTOM_SORT;
}

export const UpdateEditedCustomSort = (
    customSortUpdatedPart: Partial<CustomSort>
): UpdateEditedCustomSortAction => ({
    configItemUpdatedPart: customSortUpdatedPart,
    type: CustomSortActionKeys.UPDATE_EDITED_CUSTOM_SORT,
});

export interface SaveEditedCustomSortAction extends SaveEditedConfigItemAction {
    type: CustomSortActionKeys.SAVE_EDITED_CUSTOM_SORT;
}

export const SaveEditedCustomSort = (): SaveEditedCustomSortAction => ({
    type: CustomSortActionKeys.SAVE_EDITED_CUSTOM_SORT,
});

export interface CancelEditedCustomSortAction extends CancelEditedConfigItemAction {
    type: CustomSortActionKeys.CANCEL_EDITED_CUSTOM_SORT;
}

export const CancelEditedCustomSort = (): CancelEditedCustomSortAction => ({
    type: CustomSortActionKeys.CANCEL_EDITED_CUSTOM_SORT,
});

export interface AddCustomSortAction extends AddConfigItemAction<CustomSort> {
    type: CustomSortActionKeys.ADD_CUSTOM_SORT;
}

export const AddCustomSort = (customSort: CustomSort): AddCustomSortAction => ({
    configItem: customSort,
    type: CustomSortActionKeys.ADD_CUSTOM_SORT,
});

export interface AddCustomSortListAction extends AddConfigItemAction<CustomSort[]> {
    type: CustomSortActionKeys.ADD_CUSTOM_SORT_LIST;
}

export const AddCustomSortList = (customSortList: CustomSort[]): AddCustomSortListAction => ({
    configItem: customSortList,
    type: CustomSortActionKeys.ADD_CUSTOM_SORT_LIST,
});

export interface SetAbsoluteSortStatusAction {
    type: CustomSortActionKeys.SET_ABSOLUTE_SORT_STATUS;
    customSort: CustomSort;
    active: boolean;
}

export const SetAbsoluteSortStatus = (
    customSort: CustomSort,
    active: boolean
): SetAbsoluteSortStatusAction => ({
    active,
    customSort,
    type: CustomSortActionKeys.SET_ABSOLUTE_SORT_STATUS,
});
