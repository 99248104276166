import { fontWeight } from '@gs-ux-uitoolkit-common/design-system';
import { StyleSheet, CssClassDefinitionsObject, CssColor } from '@gs-ux-uitoolkit-common/style';
import { getIconStyles } from '@gs-ux-uitoolkit-common/icon-font';
import {
    Theme,
    ThemeTypographyVariant,
    createComponentClassDefinitions,
} from '@gs-ux-uitoolkit-common/theme';
import { AccordionSize, AccordionEmphasis, accordionDefaultProps } from './accordion-props';
import { DeepReadonly } from 'ts-essentials';
import './accordion-panel-theme-overrides';

export interface AccordionPanelStyleSheetProps {
    theme: Theme;
    disabled?: boolean;
    emphasis?: AccordionEmphasis;
    size?: AccordionSize;
    expanded?: boolean;
    expandHidden?: boolean;
}

export interface AccordionPanelCssClasses {
    root: string;
    toggle: string;
    header: string;
    summary: string;
    body: string;
}

export type AccordionPanelStyledClasses = CssClassDefinitionsObject<keyof AccordionPanelCssClasses>;

export interface AccordionPanelStyleOverridesParams {
    props: DeepReadonly<AccordionPanelStyleSheetProps>;
    createDefaultStyledClasses: () => AccordionPanelStyledClasses;
}

export const accordionPanelStyleSheet = new StyleSheet(
    'accordion-panel',
    (props: AccordionPanelStyleSheetProps) => {
        return createComponentClassDefinitions<
            AccordionPanelStyleSheetProps,
            AccordionPanelStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.accordionPanel);
    }
);

function createDefaultStyledClasses({
    theme,
    disabled = false,
    emphasis = accordionDefaultProps.emphasis,
    size = accordionDefaultProps.size,
    expanded,
    expandHidden,
}: AccordionPanelStyleSheetProps): AccordionPanelStyledClasses {
    const sizeVariant: AccordionSizeVariant = accordionSizeVariants[size];

    const emphasisVariant: AccordionEmphasisVariant = disabled
        ? getDisabledAccordionEmphasisVariants(theme)[emphasis]
        : getAccordionEmphasisVariants(theme)[emphasis];

    let headerStyle = {};

    // When an accordion is disabled force all child elements to have a muted grey color.
    // As accordion panels can have any nested component we use a '*' selector with !important
    // to force the styles to be overridden from the header
    if (disabled) {
        headerStyle = {
            opacity: theme.state.disabledOpacity,

            '*': {
                color: `${emphasisVariant.textColor} !important`,
            },
        };
    }

    return {
        root: {
            overflow: 'hidden',
            position: 'relative',
            listStyle: 'none',
            '&:last-child': {
                // Border bottom will be the same as border top for the last child
                ...(emphasis !== 'bold' && {
                    borderBottom: emphasisVariant.borderTop,
                }),
            },
        },
        toggle: {
            ...theme.typography[sizeVariant.typography],
            fontWeight: fontWeight.medium,
            backgroundColor: expanded
                ? `${emphasisVariant.expandedBackgroundColor} !important`
                : emphasisVariant.backgroundColor,
            cursor: disabled ? 'default' : 'pointer',
            color: expanded
                ? `${emphasisVariant.expandedColor} !important`
                : emphasisVariant.textColor,
            // Reduce top & bottom padding by 1px to account for the border
            padding: expandHidden
                ? `${sizeVariant.paddingY}px ${emphasisVariant.paddingX}px`
                : `
                ${sizeVariant.paddingY - 1}px 
                ${sizeVariant.paddingRight}px 
                ${sizeVariant.paddingY - 1}px 
                ${emphasisVariant.paddingX}px
            `,
            textDecoration: 'none',
            boxSizing: 'border-box',
            // Override button styles
            display: 'block',
            width: '100%',
            textAlign: 'left',
            border: '1px solid transparent',
            // Border styles
            borderTop: emphasisVariant.borderTop,
            borderBottom: emphasisVariant.borderBottom,
            ...(expanded && emphasis === 'bold' && { borderBottom: `1px solid transparent` }),

            '&:before': {
                color: expanded ? emphasisVariant.expandedColor : emphasisVariant.toggleIconColor,
                position: 'absolute',
                top: sizeVariant.toggleTop,
                right: sizeVariant.toggleRight,
                ...getIconStyles({ name: 'keyboard-arrow-down', type: 'filled' }),
                fontSize: sizeVariant.toggleFontSize,
                transform: expanded ? 'rotateX(-180deg)' : '',
                display: expandHidden ? 'none' : 'inline-block',
            },
            '&:hover': {
                backgroundColor: disabled ? 'none' : emphasisVariant.hoverBackgroundColor,
                color: emphasisVariant.textColor,
                textDecoration: 'none',
            },
            // a11y focus styles
            '&:focus-visible': {
                outline: 'none',
                border: `1px solid ${theme.colorScheme.primary}`,
            },
        },
        header: {
            ...headerStyle,
        },
        summary: {
            borderTop: `1px solid ${theme.border.minimal}`,
        },
        body: {
            ...theme.typography.body02,
            padding: emphasisVariant.bodyPadding,
        },
    };
}

type AccordionSizeVariant = {
    typography: ThemeTypographyVariant;
    toggleFontSize: string;
    toggleRight: string;
    toggleTop: string;
    paddingRight: number;
    paddingY: number;
};

const accordionSizeVariants: { [key: string]: AccordionSizeVariant } = {
    sm: {
        typography: 'body03',
        toggleFontSize: '16px',
        toggleRight: '6px',
        toggleTop: '8px',
        paddingRight: 26,
        paddingY: 8,
    },
    md: {
        typography: 'body02',
        toggleFontSize: '20px',
        toggleTop: '10px',
        toggleRight: '8px',
        paddingRight: 30,
        paddingY: 10,
    },
    lg: {
        typography: 'body01',
        toggleFontSize: '24px',
        toggleRight: '10px',
        toggleTop: '10px',
        paddingRight: 40,
        paddingY: 10,
    },
};

type AccordionEmphasisVariant = {
    textColor: CssColor;
    toggleIconColor: CssColor;
    backgroundColor: CssColor;
    hoverBackgroundColor: CssColor;
    borderTop: string;
    borderBottom: string;
    paddingX: number;
    bodyPadding: string;
    expandedBackgroundColor: CssColor;
    expandedColor: CssColor;
};

const getAccordionEmphasisVariants = (
    theme: Theme
): { [key: string]: AccordionEmphasisVariant } => {
    const subtleShades = theme.getSurfaceInteractionShades('primary');
    const boldShades = theme.getSurfaceInteractionShades('tertiary');

    return {
        subtle: {
            textColor: theme.text.primary,
            toggleIconColor: theme.text.secondary,
            backgroundColor: 'transparent',
            hoverBackgroundColor: subtleShades.hover,
            borderTop: `1px solid ${theme.border.moderate}`,
            borderBottom: '1px solid transparent',
            expandedColor: theme.text.primary,
            expandedBackgroundColor: 'transparent',
            paddingX: 0,
            bodyPadding: '4px 0px 12px 0px',
        },
        bold: {
            textColor: theme.text.primary,
            toggleIconColor: theme.text.secondary,
            backgroundColor: boldShades.background,
            hoverBackgroundColor: boldShades.hover,
            borderTop: `1px solid transparent`,
            // For bold accordion the bottom border is the same as the background color.
            // Using a transparent color will reveal the background. Ideally we should use the same
            // color as the surface. Until we have a SurfaceContext to work with, we'll default to
            // the primary surface background color to handle the most common case
            borderBottom: `1px solid ${theme.surface.primary}`,
            expandedColor: theme.text.reversed,
            expandedBackgroundColor: theme.surface.strong,
            paddingX: 12,
            bodyPadding: '4px 12px 12px 12px',
        },
    };
};

const getDisabledAccordionEmphasisVariants = (
    theme: Theme
): { [key: string]: AccordionEmphasisVariant } => ({
    subtle: {
        ...getAccordionEmphasisVariants(theme).subtle,
        backgroundColor: 'transparent',
        hoverBackgroundColor: 'transparent',
    },
    bold: {
        ...getAccordionEmphasisVariants(theme).bold,
    },
});
