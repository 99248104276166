import { cx } from '@gs-ux-uitoolkit-common/style';
import { RadioProps } from './radio-props';
import { RadioCssClasses } from './radio-style-sheet';

const globalClassName = 'gs-radio';

export const getRadioRootClasses = (props: {
    cssClasses: RadioCssClasses;
    className?: RadioProps['className'];
    overrideClasses?: RadioProps['classes'];
}): string => {
    const { cssClasses, className, overrideClasses } = props;
    return cx(cssClasses.root, className, overrideClasses && overrideClasses.root, globalClassName);
};

export const getRadioInputClasses = (props: {
    cssClasses: RadioCssClasses;
    overrideClasses?: RadioProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.input,
        overrideClasses && overrideClasses.input,
        `${globalClassName}__input`
    );
};

export const getRadioContainerClasses = (props: {
    cssClasses: RadioCssClasses;
    overrideClasses?: RadioProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.container,
        overrideClasses && overrideClasses.container,
        `${globalClassName}__container`
    );
};

export const getRadioInnerClasses = (props: {
    cssClasses: RadioCssClasses;
    overrideClasses?: RadioProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.inner,
        overrideClasses && overrideClasses.inner,
        `${globalClassName}__inner`
    );
};

export const getRadioLabelClasses = (props: {
    cssClasses: RadioCssClasses;
    overrideClasses?: RadioProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.label,
        overrideClasses && overrideClasses.label,
        `${globalClassName}__label`
    );
};

export const getRadioChildrenClasses = (props: {
    cssClasses: RadioCssClasses;
    overrideClasses?: RadioProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.children,
        overrideClasses && overrideClasses.children,
        `${globalClassName}__children`
    );
};
