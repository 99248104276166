export enum Plugins {
    AutofitPivotPlugin = 'Autofit Pivot',
    ColumnFilterPlugin = 'Column Filter',
    ColumnHintPlugin = 'Column Hint',
    CrossModelPlugin = 'Cross Model',
    CustomSortPlugin = 'Custom Sort',
    DraPlugin = 'DRA',
    KeyboardShortcutPlugin = 'Keyboard Shortcut',
    ExportPlugin = 'Export',
    GridConfigurationPlugin = 'Grid Configuration',
    RowCustomisationPlugin = 'Row Customisation',
    SavedViewPlugin = 'Saved View',
    MaskedColumnPlugin = 'Masked Column',
    QuickFilterPlugin = 'Quick Filter',
    SearchablePlugin = 'Searchable',
    SizeAndDensityPlugin = 'Size And Density',
    ToolbarConfigPlugin = 'Toolbar Config',
    NotificationPlugin = 'Notification',
    ZebraStripesPlugin = 'Zebra Stripes',
    FloatingFilterPlugin = 'Floating Filter',
}

export enum Categories {
    Config = 'Config',
    Data = 'Data',
    Edit = 'Edit',
    View = 'View',
}

export enum CreateButtonText {
    Create = 'Create',
    Update = 'Update',
}

export enum ToolPanelComponents {
    Columns = 'columns',
    Filters = 'filters',
}
