import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { StepsProps } from './';
import { DeepReadonly } from 'ts-essentials';
import './steps-theme-overrides';

export interface StepsStyleSheetProps {
    orientation: StepsProps['orientation'];
    hideLabels: StepsProps['hideLabels'];
    totalNumberOfSteps: number;
    size: StepsProps['size'];
    theme: Theme;
}

export interface StepsCssClasses {
    root: string;
    list: string;
    container: string;
}

export type StepsStyledClasses = CssClassDefinitionsObject<keyof StepsCssClasses>;

export interface StepsStyleOverridesParams {
    props: DeepReadonly<StepsStyleSheetProps>;
    createDefaultStyledClasses: () => StepsStyledClasses;
}

export const stepsStyleSheet = new StyleSheet('steps', (props: StepsStyleSheetProps) => {
    return createComponentClassDefinitions<StepsStyleSheetProps, StepsStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.steps
    );
});

function createDefaultStyledClasses({
    theme,
    size,
    orientation,
    hideLabels,
    totalNumberOfSteps,
}: StepsStyleSheetProps): StepsStyledClasses {
    const isHorizontallyAligned = orientation === 'horizontal';
    const isToEdged = hideLabels === true;
    const iconIndicatorSize = size === 'md' ? 19 : 8;

    // Space to the left needed since indicator icons take up space
    const leftSpace = iconIndicatorSize / 2 + 0.5;

    const stepsContainer = isHorizontallyAligned
        ? isToEdged
            ? ({
                  flexDirection: 'row',
                  width: `calc((100% - ${iconIndicatorSize}px) * ${
                      totalNumberOfSteps / (totalNumberOfSteps - 1)
                  })`,
                  left: `${leftSpace}px`,
                  transform: `translateX(-${100 / (totalNumberOfSteps * 2)}%)`,
                  padding: '0',
              } as const)
            : ({
                  flexDirection: 'row',
                  padding: '0',
              } as const)
        : ({
              flexDirection: 'column',
              paddingLeft: '0',
          } as const);

    return {
        root: {},
        list: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            listStyleType: 'none',
            ...stepsContainer,
            ...theme.typography.body02,
        },
        container: {
            overflow: 'hidden',
        },
    };
}
