import React, {useState} from 'react';
import PropTypes from 'prop-types';
import translator from '../../../../services/translator';
import Button from '../../../../components/core/Button';
import CompareModal from '../CompareModal';
import {useChartContext} from "../../context";

const {translate: t} = translator;

const CompareComponent = ({allMetricOptions, benchmarkMetricsOptions, saveAppPreferences}) => {
  // console.log('%c \t \t Rendering CompareComponent Component ', 'color: gray;');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {state: {primaryShareClassId}} = useChartContext();

  const toggleVisibility = () => {
    setIsModalOpen(!isModalOpen);
  };

  const modalProps = {
    title: t('tkAddComparisons'),
    animation: false,
    suppressScrollOnActive: true,
    customModalStyle: {overflow: 'hidden'},
    isModalOpen,
    handleClose: toggleVisibility,
    allMetricOptions,
    benchmarkMetricsOptions,
    saveAppPreferences
  };

  return (
    <React.Fragment>
      <CompareModal {...modalProps} />
      <Button
        isDisabled={!primaryShareClassId}
        label={t('tkCompare')}
        extraClass='fundmetric-compare-button'
        customClass='button-secondary-small'
        clickHandler={toggleVisibility}
      />
    </React.Fragment>
  );
};

CompareComponent.propTypes = {
  allMetricOptions: PropTypes.array,
  benchmarkMetricsOptions: PropTypes.array,
  saveAppPreferences: PropTypes.func
};

export default CompareComponent;
