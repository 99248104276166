import React from 'react';
import translator from '../../../../../../services/translator';
import FundGrid from './FundGrid';
import ControlPanel from './ControlPanel';
import useUserMaintenance from '../../../context';
import './index.scss';

const { translate: t } = translator;

const Funds = () => {
  const {
    state: { isEditable },
  } = useUserMaintenance();

  return (
    <div className='userdetailsfunds gs-scrollbar'>
      <div className='userdetailsfunds__header'>{t('tkFundsEntitlements')}</div>
      <div className='userdetailsfunds__subheader'>{t(isEditable ? 'tkFundSubTitle' : 'tkFundSubTitleReadonly')}</div>
      {isEditable ? <ControlPanel /> : <div />}
      <FundGrid />
    </div>
  );
};

export default Funds;
