/* eslint-disable react-hooks/exhaustive-deps */
// TODO: https://jira.site.gs.com/browse/UX-14776

import { FunctionComponent, memo, useContext, useEffect, useRef, CSSProperties } from 'react';

import PropTypes from 'prop-types';
import {
    getMenuClass,
    getMenuInnerClass,
    MenuEvent,
    MenuBlurEvent,
    MenuCommonProps,
    MenuStateViewModel,
    MenuAttributes,
    menuStyleSheet,
} from '@gs-ux-uitoolkit-common/menu';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { isBrowser } from '@gs-ux-uitoolkit-react/shared';
import {
    MenuSingleValueProps as MenuSingleValueCommonProps,
    MenuMultipleValueProps as MenuMultipleValueCommonProps,
} from './menu-react-props';
import { useMenuContextCreator } from './use-menu-context-creator';
import { MenuContext } from './menu-context';
import { MenuPortal } from './menu-portal';
import { SubmenuContext } from './submenu-context';
import { componentAnalytics } from './analytics-tracking';

export interface MenuSingleValueProps
    extends MenuCommonProps<CSSProperties>,
        Omit<MenuSingleValueCommonProps, 'style'> {}

export interface MenuMultipleValueProps
    extends MenuCommonProps<CSSProperties>,
        Omit<MenuMultipleValueCommonProps, 'style'> {}

export type MenuProps = MenuSingleValueProps | MenuMultipleValueProps;

/**
 * Menu can be used on its own (such as as an alternative contextual menu) or
 * as a module within a larger component (for example, it is used to render the
 * options in the Dropdown component).
 */
export const Menu: FunctionComponent<MenuProps> = memo((props: MenuProps) => {
    const parentContext = useContext(MenuContext);
    const submenuContext = useContext(SubmenuContext);

    const menuAttrs = useRef<MenuAttributes | null>(null);
    function getMenuAttrs(): MenuAttributes {
        if (!menuAttrs.current) {
            menuAttrs.current = new MenuAttributes(props);
        }
        return menuAttrs.current;
    }
    if (menuAttrs.current) {
        getMenuAttrs().updateProps(props);
    }

    const stateVm = useRef<MenuStateViewModel | null>(null);
    function getStateVm(): MenuStateViewModel {
        if (!stateVm.current) {
            const { autoFocus, visible, fixed, offset, placement, target } = getMenuAttrs();
            const { isSubmenu } = submenuContext;
            stateVm.current = new MenuStateViewModel({
                autoFocus,
                visible,
                fixed,
                offset,
                placement,
                target,
                isSubmenu,
                onBlur,
                onFocus,
            });
        }
        return stateVm.current;
    }

    function onBlur(event: MenuBlurEvent) {
        const { input, menuElement, nextActiveElement } = event;
        const nextActiveEl = nextActiveElement as any;
        const eventIsOutsideDocument = isBrowser && window === nextActiveEl;

        const shouldBlur =
            !menuElement ||
            input !== 'pointer' ||
            eventIsOutsideDocument ||
            !menuElement.contains(nextActiveEl);
        if (shouldBlur && !getStateVm().getSubmenuVisible()) {
            if (props.onBlur) {
                props.onBlur(event);
            }
        }
    }

    function onFocus() {
        if (props.onShow) {
            props.onShow(new MenuEvent({ menuElement: getStateVm().getMenuEl() }));
        }
    }

    function setMenuElement(element: HTMLDivElement) {
        if (element && !getStateVm().getMenuEl()) {
            getStateVm().setMenuEl(element);
        }
    }

    function getStyle(): CSSProperties {
        return {
            zIndex: parentContext.depth,
            visibility: props.visible ? 'visible' : 'hidden',
            ...props.style,
        };
    }

    const menuContext = useMenuContextCreator({
        props,
        getStateVm,
        onBlur,
    });

    const theme = useTheme();
    const cssClasses = useStyleSheet(menuStyleSheet, {
        theme,
        size: props.size || menuContext.size,
        visible: props.visible || false,
        disabled: props.disabled || false,
    });

    useEffect(() => {
        const currentVisible = getStateVm().isVisible();
        const { autoFocus, visible, fixed, offset, placement, target } = getMenuAttrs();
        const { isSubmenu } = submenuContext;
        getStateVm().update({
            autoFocus,
            visible,
            fixed,
            offset,
            placement,
            target,
            isSubmenu,
        });
        if (props.onHide && currentVisible && !visible) {
            props.onHide(new MenuEvent({ menuElement: getStateVm().getMenuEl() }));
        }
    }, [
        props.autoFocus,
        props.visible,
        props.fixed,
        props.offset,
        props.placement,
        props.target,
        submenuContext.isSubmenu,
    ]);

    useEffect(() => {
        return () => {
            if (stateVm.current) {
                getStateVm().destroy();
            }
        };
    }, []);

    function getOtherProps() {
        const {
            appendToBody,
            autoFocus,
            disabled,
            visible,
            fixed,
            offset,
            placement,
            target,
            size,
            multiple,
            value,
            defaultValue,
            children,
            className,
            onHide,
            onBlur: onBlur,
            onShow,
            onChange,
            ...otherProps
        } = props;
        return otherProps;
    }

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'menu' });
    }, []); // Only run once

    return (
        <MenuPortal appendToBody={!(props.appendToBody === false)}>
            <MenuContext.Provider value={menuContext}>
                <div
                    className={getMenuClass({
                        cssClasses,
                        className: props.className,
                        overrideClasses: props.classes,
                    })}
                    style={getStyle()}
                    data-gs-uitk-component="menu"
                    ref={setMenuElement}
                    role="dialog"
                    {...getOtherProps()}
                >
                    <div
                        className={getMenuInnerClass({
                            cssClasses,
                            overrideClasses: props.classes,
                        })}
                        data-cy="gs-uitk-menu__inner"
                        role="menu"
                    >
                        {props.children}
                    </div>
                </div>
            </MenuContext.Provider>
        </MenuPortal>
    );
});
Menu.displayName = 'Menu';

export const valuePropType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
]);

// We have some TypeScript fanciness above to differentiate between multiselect
// and single select Menus which is probably not possible (or worth it) to
// 100% replicate with PropTypes, hence the @ts-expect-error.
Menu.propTypes = {
    appendToBody: PropTypes.bool,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    visible: PropTypes.bool,
    fixed: PropTypes.bool,
    // @ts-expect-error TODO: describe this
    multiple: PropTypes.bool,
    // @ts-expect-error TODO: describe this
    offset: PropTypes.arrayOf(PropTypes.number),
    placement: PropTypes.oneOf([
        'top',
        'top-left',
        'top-right',
        'bottom',
        'bottom-left',
        'bottom-right',
        'left',
        'left-top',
        'left-bottom',
        'right',
        'right-top',
        'right-bottom',
        'auto',
    ]),
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    // @ts-expect-error TODO: describe this
    target: (props, propName: string, componentName: string) => {
        const propValue: MenuCommonProps['target'] = props[propName];
        if (
            propValue !== undefined &&
            typeof propValue !== 'string' &&
            !(propValue instanceof HTMLElement)
        ) {
            return new Error(
                `Invalid prop \`${propName}\` supplied to '${componentName}'. Validation failed.`
            );
        }
        return;
    },
    // @ts-expect-error TODO: describe this
    value: valuePropType,
    // @ts-expect-error TODO: describe this
    defaultValue: valuePropType,
    onHide: PropTypes.func,
    onBlur: PropTypes.func,
    onShow: PropTypes.func,
    onChange: PropTypes.func,
};
