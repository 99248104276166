import {
    BodyScrollEvent,
    Events,
    IHeaderGroupParams,
    ProvidedColumnGroup,
} from '@ag-grid-community/core';
import { AG_HEADER_VIEWPORT_CLASS } from '../grid-wrappers/grid-wrapper';

/**
 * This is our Custom ColumnGroupRenderer that we use when using a DRA connection
 * @param viewConfig The viewconfig coming from DRA connection
 * @param onClick Callback when user clicks on the GroupRenderer
 */
export const DraColumnGroupRenderer = (
    showExpandCollapse: boolean,
    dynamicAlignment: 'flex-start' | 'flex-end' | 'center' | 'none'
) => {
    return class GroupRenderer {
        public eGui: HTMLDivElement;
        public eExpandButton: HTMLDivElement | null = null;
        public eCollapseButton: HTMLDivElement | null = null;
        public eOffsetLeft: HTMLDivElement | null = null;
        public eOffsetRight: HTMLDivElement | null = null;
        public params: IHeaderGroupParams | null;
        constructor() {
            // create the header
            this.eGui = document.createElement('div');

            this.params = null;
        }
        public init(params: IHeaderGroupParams) {
            this.params = params;
            if (params) {
                this.eGui.className = 'ag-header-group-cell-label';
                if (dynamicAlignment !== 'none') {
                    this.eGui.style.justifyContent = dynamicAlignment;
                }
                this.eGui.innerHTML = `<div>
                    ${this.params.displayName}
                    </div>`;
                if (showExpandCollapse || params.columnGroup.isExpandable()) {
                    this.eGui.innerHTML = `<div class="column-group-expand"><i class="column-group-expand-icon"></i></div>
                        <div class="column-group-collapse"><i class="column-group-collapse-icon"></i></div>
                        ${this.eGui.innerHTML}`;
                }
                this.eGui.innerHTML = `<div class="column-group-offset-left"></div>${this.eGui.innerHTML}<div class="column-group-offset-right">`;
                this.eExpandButton = this.eGui.querySelector('.column-group-expand');
                this.eCollapseButton = this.eGui.querySelector('.column-group-collapse');
                this.eOffsetLeft = this.eGui.querySelector('.column-group-offset-left');
                this.eOffsetRight = this.eGui.querySelector('.column-group-offset-right');
                if (this.eExpandButton) {
                    this.eExpandButton.addEventListener(
                        'click',
                        this.onExpandButtonClickedListener
                    );
                }
                if (this.eCollapseButton) {
                    this.eCollapseButton.addEventListener(
                        'click',
                        this.onExpandButtonClickedListener
                    );
                }

                this.params.columnGroup
                    .getProvidedColumnGroup()
                    .addEventListener(
                        ProvidedColumnGroup.EVENT_EXPANDED_CHANGED,
                        this.onExpandChangedListener
                    );

                this.syncExpandButtons();

                if (dynamicAlignment !== 'none') {
                    this.params.api.addEventListener(
                        Events.EVENT_BODY_SCROLL,
                        this.onBodyScrollListener
                    );
                    this.params.api.addEventListener(
                        Events.EVENT_COLUMN_GROUP_OPENED,
                        this.onEventsToComputeOffsetDiv
                    );
                    this.params.api.addEventListener(
                        Events.EVENT_COLUMN_VISIBLE,
                        this.onEventsToComputeOffsetDiv
                    );
                    this.params.api.addEventListener(
                        Events.EVENT_GRID_SIZE_CHANGED,
                        this.onEventsToComputeOffsetDiv
                    );
                }
            }
        }

        public getGui() {
            return this.eGui;
        }

        public destroy() {
            if (this.eExpandButton) {
                this.eExpandButton.removeEventListener('click', this.onExpandButtonClickedListener);
            }
            if (this.eCollapseButton) {
                this.eCollapseButton.removeEventListener(
                    'click',
                    this.onExpandButtonClickedListener
                );
            }
            if (this.params && dynamicAlignment !== 'none') {
                this.params.api.removeEventListener(
                    Events.EVENT_BODY_SCROLL,
                    this.onBodyScrollListener
                );
                this.params.api.addEventListener(
                    Events.EVENT_COLUMN_GROUP_OPENED,
                    this.onEventsToComputeOffsetDiv
                );
                this.params.api.addEventListener(
                    Events.EVENT_COLUMN_VISIBLE,
                    this.onEventsToComputeOffsetDiv
                );
                this.params.api.addEventListener(
                    Events.EVENT_GRID_SIZE_CHANGED,
                    this.onEventsToComputeOffsetDiv
                );
            }
        }

        public onExpandButtonClickedListener: () => void = () => this.expandOrCollapse();
        public onExpandChangedListener: () => void = () => this.syncExpandButtons();
        public onBodyScrollListener: (event: BodyScrollEvent) => void = (
            event: BodyScrollEvent
        ) => {
            if (event.direction === 'horizontal') {
                setTimeout(() => {
                    this.computeOffsetDiv();
                }, 100);
            }
        };
        public onEventsToComputeOffsetDiv: () => void = () => {
            setTimeout(() => {
                this.computeOffsetDiv();
            }, 200);
        };
        public expandOrCollapse(): void {
            if (this.params) {
                const currentState = this.params.columnGroup.getProvidedColumnGroup().isExpanded();
                this.params.setExpanded(!currentState);
            }
        }

        public syncExpandButtons() {
            if (this.params && this.eExpandButton && this.eCollapseButton) {
                const isExpanded = this.params.columnGroup.getProvidedColumnGroup().isExpanded();
                if (isExpanded) {
                    this.eExpandButton.hidden = true;
                    this.eCollapseButton.hidden = false;
                } else {
                    this.eExpandButton.hidden = false;
                    this.eCollapseButton.hidden = true;
                }
            }
        }

        public computeOffsetDiv() {
            if (this.params) {
                const gridHeader = this.eGui.closest(`.${AG_HEADER_VIEWPORT_CLASS}`);
                if (gridHeader) {
                    const gridHeaderRight = gridHeader.getBoundingClientRect().right;
                    const gridHeaderLeft = gridHeader.getBoundingClientRect().left;
                    const columnGroupRight = this.eGui.getBoundingClientRect().right;
                    const columnGroupLeft = this.eGui.getBoundingClientRect().left;
                    if (gridHeaderRight < columnGroupRight) {
                        if (this.eOffsetRight) {
                            const offset = columnGroupRight - gridHeaderRight;
                            this.eOffsetRight.style.width = offset + 'px';
                        }
                    } else if (columnGroupLeft < gridHeaderLeft) {
                        if (this.eOffsetLeft) {
                            const offset = gridHeaderLeft - columnGroupLeft;
                            this.eOffsetLeft.style.width = offset + 'px';
                        }
                    } else {
                        if (this.eOffsetRight) {
                            this.eOffsetRight.style.width = 0 + 'px';
                        }
                        if (this.eOffsetLeft) {
                            this.eOffsetLeft.style.width = 0 + 'px';
                        }
                    }
                }
            }
        }
    };
};
