const FAQ_TOPIC_SELECT_EVENT = 'FAQ_Topic_Select';
const FAQ_EXPANDED_EVENT = 'FAQ_Expanded';
export const FAQ_TOPIC_CLICK_ANALYTICS_EVENT = 'faq_topic';
export const READ_MORE_LINK_EVENT = 'read_more_link';

export const faqTopicChosenAnalyticsData = (topicName) => {
  return {
    event: {
      eventName: FAQ_TOPIC_SELECT_EVENT,
      valueChosen: topicName
    }
  };
};

export const faqQuestionAnswerExpandedAnalyticsData = (articleName) => {
  return {
    event: {
      eventName: FAQ_EXPANDED_EVENT,
      valueChosen: articleName
    }
  };
};
