import actionTypes from '../../../actions/page/login/actionTypes';

export function loginPageDataReducer(state = {}, action) {
  const { type, data = {} } = action;
  switch (type) {
    case actionTypes.IS_USER_LOGGED_IN:
    case actionTypes.IS_LOGIN_IN_PROGRESS:
    case actionTypes.IS_MFA_FLOW_IN_PROGRESS:
    case actionTypes.IS_SHOW_MOSAIC_HELP:
    case actionTypes.UPDATE_TARGET_URL: {
      return { ...state, ...data };
    }
    default:
      return state;
  }
}
