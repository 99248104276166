import { Component, FC, ReactNode, RefObject, createRef } from 'react';
import PropTypes from 'prop-types';
import { TabTitles } from './TabTitles';
import { TabPanes } from './TabPanes';
import {
    tabsStyleSheet,
    getTabsRootClasses,
    TabsCommonProps,
    TabsSize,
} from '@gs-ux-uitoolkit-common/tabs';
import { Theme, ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';
import MeasureImpl, { MeasureProps } from 'react-measure';
import { v4 as uuidv4 } from 'uuid';
import { EmotionInstanceContext } from '@gs-ux-uitoolkit-react/style';

// Hack for 'react-measure@2.5.2': in the ESM module, the default export is the
// Measure component itself. In the commonjs (UMD) export, there's a `.default`
// property, which is used when including Measure in a Jest or Vitest test
// (Vitest uses the UMD export because there's only "main" and "module"
// properties in its package.json, and Node.js will never read the "module"
// property)
const Measure: FC<MeasureProps> = (MeasureImpl as any).default || MeasureImpl;

// Not sure why we're having problems importing

// Note: `typeof window` check needed for SSR (Server-side Rendering)
// environments where `window` doesn't exist
const win = typeof window === 'undefined' ? undefined : (window as any);

const logDebug = (): boolean => win && win.GS_UX_UITOOLKIT_RESPONSIVE_TABS_DEBUG;

export interface ControlledTabsProps {
    activeTabKey: string | number;
    onSelect: (tabKey: string | number) => void;
    responsive?: boolean;
    vertical?: boolean;
    iconOnly?: boolean;
    size: TabsSize;
    variant: 'tabs' | 'pills';
    contentUnderneath?: boolean;
    withVerticalBar?: boolean;
    classes?: TabsCommonProps['classes'];
    children?: ReactNode;
}

export interface ControlledTabsState {
    minWidth?: number;
    overflowingTabCount: number;
}

export class ControlledTabs extends Component<ControlledTabsProps, ControlledTabsState> {
    public static propTypes: { [key in keyof ControlledTabsProps]: any } = {
        activeTabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        children: PropTypes.node.isRequired,
        onSelect: PropTypes.func.isRequired,
        responsive: PropTypes.bool,
        vertical: PropTypes.bool,
        iconOnly: PropTypes.bool,
        size: PropTypes.oneOf(['md', 'lg']),
        variant: PropTypes.oneOf(['tabs', 'pills']).isRequired,
        contentUnderneath: PropTypes.bool,
        withVerticalBar: PropTypes.bool,
        classes: PropTypes.object,
    };

    public static defaultProps = {
        variant: 'tabs',
        vertical: false,
        responsive: false,
        iconOnly: false,
        size: 'md',
        contentUnderneath: true,
        withVerticalBar: false,
        onSelect: () => {},
    };

    private dom?: HTMLElement;
    private reOrderedChildren?: any;
    private childTabSizeMap?: any;
    private overflowNeedsRecalc: boolean = true;
    private ref: RefObject<HTMLDivElement>;
    // Tab key for responsive dropdown tab to make it keyboard accessible
    //  we need this tab key as this component is not generated by user
    private responsiveTabKey: string | number;

    constructor(props: ControlledTabsProps) {
        super(props);
        this.state = { overflowingTabCount: 0 }; // for props.responsive
        this.onResize = this.onResize.bind(this);
        this.ref = createRef();
        this.responsiveTabKey = uuidv4();
    }

    componentDidMount() {
        this.dom = this.ref.current as HTMLElement;
        this.recalcOverflow(true);

        // Due to a lazy rendering initialization sequence when running the tabs demo locally
        // via the webpack watch, the DOM is not fully rendered at this point in time.  The sizes
        // of the tabs are smaller than what they should be compared to a normal environment.  You can
        // put a breakpoint in resetChildTabSizeMap() and look at the browser to repro.  If this
        // happens to apps for some reason, they can set this, and we can take a closer look.
        if (win && win.GS_UX_UITOOLKIT_RESPONSIVE_TABS_RECALC_DELAY) {
            // if set to 'true', default to 100ms
            const delay = isNaN(win.GS_UX_UITOOLKIT_RESPONSIVE_TABS_RECALC_TIMER)
                ? 100
                : win.GS_UX_UITOOLKIT_RESPONSIVE_TABS_RECALC_DELAY;
            setTimeout(() => this.recalcOverflow(true), delay);
        }
    }

    componentDidUpdate() {
        if (this.overflowNeedsRecalc) {
            this.recalcOverflow(true);
        } else {
            this.overflowNeedsRecalc = true;
        }
    }

    componentWillUnmount() {
        tabsStyleSheet.unmount(this);
    }

    recalcOverflow(resetChildTabSizes: boolean) {
        // TODO:  optimize when to recalc.  eg. if the tab titles and sequence didn't change,
        // then we don't need to recalc and rerender

        if (!this.dom || !Array.isArray(this.reOrderedChildren) || !this.props.responsive) {
            return;
        }
        const dimension = this.props.vertical ? 'offsetHeight' : 'offsetWidth';
        const ulContainer = this.dom.querySelector('ul.nav-tabs') as HTMLUListElement;
        const containerSize = ulContainer ? ulContainer[dimension] : this.dom[dimension]; // ul may not be in DOM yet
        const validTabs = this.reOrderedChildren;
        const dropdown = this.dom.querySelector('li.nav-item.dropdown') as HTMLLIElement;

        // TODO:  need to render the overflow ellipse button to the DOM so we can get its size.
        //        Currently, we are hardcoding it to 44 pixels which may not be correct if
        //        there are styling differences.
        const overflowElementSize = dropdown ? dropdown[dimension] : 44;

        if (resetChildTabSizes) {
            this.resetChildTabSizeMap();
        }

        // set min width so at least 1 tab is displayed
        const firstTabKey = validTabs[0].props.tabKey;
        const minWidth = overflowElementSize + this.childTabSizeMap[firstTabKey];

        // calculate if and where the tabs start to overflow
        let currentTabKey: string | number;
        let currentSize: number = 0;
        let i = 0;
        if (logDebug()) {
            console.log(`------ recalcOverflow() ----------`);
            console.log(`resetChildTabSizes: ${resetChildTabSizes}`);
            console.log(`container size: ${containerSize}`);
        }
        do {
            currentTabKey = validTabs[i].props.tabKey;
            currentSize += this.childTabSizeMap[currentTabKey];
            if (logDebug()) {
                console.log(
                    `tabKey: ${currentTabKey} tabsize: ${this.childTabSizeMap[currentTabKey]} runningTally: ${currentSize}`
                );
            }
            i += 1;
        } while (i < validTabs.length && currentSize < containerSize);

        // calculate overflow tab count
        let overflowingTabCount = 0;
        if (i !== validTabs.length || currentSize > containerSize) {
            // reset currentSize and i to previous tab before overflow
            currentSize -= this.childTabSizeMap[currentTabKey];
            i -= 1;
            // take off a tab if added overflow element doesn't fit
            overflowingTabCount =
                currentSize + overflowElementSize > containerSize
                    ? validTabs.length - i + 1
                    : validTabs.length - i;
        }
        overflowingTabCount = Math.min(validTabs.length, overflowingTabCount);

        // reset marker so we don't recalc and re-render
        this.overflowNeedsRecalc = false;

        // re-render with updated overflowingTabCount
        this.setState({
            minWidth,
            overflowingTabCount,
        });
    }

    onResize() {
        //shouldMeasure is removed in react-measure 2.0, adding the check here
        //to stop the rerender when responsive is set to false
        if (this.props.responsive) {
            // recalcOverflow(false) because we wish to rerender due to containersize change,
            // our child Tabs are not changing - so we can reuse cached measurements
            // and avoid rendering tabs to the DOM + re-render for overflow calc cycle
            this.recalcOverflow(false);
        }
    }

    resetChildTabSizeMap() {
        const validTabs = this.reOrderedChildren;
        const dimension = this.props.vertical ? 'offsetHeight' : 'offsetWidth';
        if (this.dom) {
            const tabsInDom = Array.prototype.slice.call(this.dom.querySelectorAll('li.nav-item'));
            if (tabsInDom.length === 0) {
                return;
            }
            this.childTabSizeMap = {};
            validTabs.forEach((tab: any, i: number) => {
                const tabEl = tabsInDom[i];
                if (tabEl === undefined) return;
                this.childTabSizeMap[tab.props.tabKey] = tabEl[dimension];
            });
        }
    }

    render() {
        const { minWidth, overflowingTabCount } = this.state;
        const {
            activeTabKey,
            children,
            onSelect,
            responsive,
            vertical,
            variant,
            iconOnly,
            size,
            contentUnderneath,
            withVerticalBar,
            classes: overrideClasses,
            ...props
        } = this.props;
        this.reOrderedChildren = children;
        const orientation = vertical ? 'vertical' : 'horizontal';

        return (
            <EmotionInstanceContext.Consumer>
                {emotionInstance => (
                    <ThemeConsumer>
                        {(theme: Theme) => {
                            const cssClasses = tabsStyleSheet.mount(
                                this,
                                {
                                    theme,
                                    contentUnderneath,
                                    minWidth,
                                    orientation,
                                    size,
                                    type: variant,
                                    justify: undefined,
                                },
                                emotionInstance
                            );

                            return (
                                <Measure innerRef={this.ref} onResize={this.onResize}>
                                    {({ measureRef }: any) => (
                                        <div
                                            className={getTabsRootClasses({
                                                cssClasses,
                                                overrideClasses,
                                            })}
                                            data-gs-uitk-component="tabs"
                                            data-orientation={orientation}
                                            ref={measureRef}
                                            {...props}
                                        >
                                            {/*
                                            // if overflowNeedsRecalc=true, then we need to go through 2 render cycles because 
                                            // we need the tabs written to the DOM to get their offsetWidth or offsetHeight. 
                                            // Examples: child Tabs have changed by either adding, removing, changing their titles, 
                                            // or changing their order.  So we will render out all the tabs to the DOM in one 
                                            // render cycle by setting overflowTabCount = 0, then in componentDidUpdate() we will 
                                            // recalculate the overflow bits and re-render with overflowNeedsRecalc=true.
                                        */}
                                            <TabTitles
                                                overflowingTabCount={
                                                    this.overflowNeedsRecalc
                                                        ? 0
                                                        : overflowingTabCount
                                                }
                                                activeTabKey={activeTabKey}
                                                onSelect={onSelect}
                                                responsive={!!responsive}
                                                vertical={!!vertical}
                                                variant={variant}
                                                iconOnly={!!iconOnly}
                                                size={size}
                                                tabRef={this.ref}
                                                responsiveTabKey={this.responsiveTabKey}
                                                withVerticalBar={
                                                    vertical &&
                                                    !contentUnderneath &&
                                                    withVerticalBar
                                                }
                                                classes={overrideClasses}
                                            >
                                                {this.reOrderedChildren}
                                            </TabTitles>
                                            <TabPanes
                                                activeTabKey={activeTabKey}
                                                size={size}
                                                classes={overrideClasses}
                                            >
                                                {this.reOrderedChildren}
                                            </TabPanes>
                                        </div>
                                    )}
                                </Measure>
                            );
                        }}
                    </ThemeConsumer>
                )}
            </EmotionInstanceContext.Consumer>
        );
    }
}
