import { arrayHelper } from '@gs-ux-uitoolkit-common/datacore';
import { Component } from 'react';
import { DisplayValueTuple } from '../../../../grid-wrappers/grid-wrapper';
import { ColumnValueListState } from '../../../../redux/datagrid-state';
import { CustomSortListSelector } from './custom-sort-list-selector';

export interface CustomSortColumnValuesCriteriaProps {
    selectedColumnValues: string[];
    columnValueList: ColumnValueListState | null;
    onAddColumnValues: (newColumnValues: string[]) => void;
    onRemoveColumnValues: (removedColumnValues: string[]) => void;
}

export class CustomSortColumnValuesCriteria extends Component<
    CustomSortColumnValuesCriteriaProps,
    object
> {
    public render() {
        const consolidatedColumnValues: DisplayValueTuple[] = this.props.columnValueList
            ? this.props.columnValueList.valueList.slice()
            : [];

        const colValues = arrayHelper
            .sort(consolidatedColumnValues, false, 'displayValue')
            .map(x => x.displayValue);
        return (
            <div className="gs-uitk-criteria-container-value-list">
                {this.props.columnValueList ? (
                    <CustomSortListSelector
                        consolidateValueList={false}
                        selectedValues={this.props.selectedColumnValues}
                        sort={false}
                        valueList={colValues}
                        searchLabel={this.props.columnValueList.columnId}
                        onAddValues={values => this.props.onAddColumnValues(values)}
                        onRemoveValues={values => this.props.onRemoveColumnValues(values)}
                    />
                ) : null}
            </div>
        );
    }
}
