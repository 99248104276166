/* eslint-disable react-hooks/exhaustive-deps */
// TODO: https://jira.site.gs.com/browse/UX-14776

import {
    FunctionComponent,
    useContext,
    useRef,
    useState,
    useEffect,
    ReactNode,
    ReactElement,
    CSSProperties,
    HTMLAttributes,
    Children,
    Component,
    MouseEvent,
    KeyboardEvent,
    isValidElement,
} from 'react';

import PropTypes from 'prop-types';
import { Icon, IconName } from '@gs-ux-uitoolkit-react/icon-font';
import {
    getOptionClass,
    getLabelClass,
    getIconClass,
    getSecondaryLabelClass,
    getSubmenuIconClass,
    MenuBlurEvent,
    MenuOptionProps as MenuOptionCommonProps,
    SubmenuContext as SubmenuContextType,
    menuOptionStyleSheet,
    MenuOptionSelectEvent,
} from '@gs-ux-uitoolkit-common/menu';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { KeyHelpers, isBrowser, logWarningOnce } from '@gs-ux-uitoolkit-react/shared';
import { Menu, MenuProps } from './menu';
import { CollapseMenu, CollapseMenuProps } from './collapse-menu';
import { MenuContext } from './menu-context';
import { SubmenuContext } from './submenu-context';

export interface MenuOptionProps
    extends MenuOptionCommonProps<CSSProperties>,
        Omit<HTMLAttributes<HTMLElement>, 'onClick'> {
    children?: ReactNode;

    /**
     * @deprecated Use the `onSelect` event instead to handle the event for clicking
     * an item which takes the disabled state into account
     */
    onClick?: (event: MouseEvent) => void;

    /**
     * Called when a user clicks on the menu option. Does not get called if the menu option is disabled.
     */
    onSelect?: (event: MenuOptionSelectEvent) => void;
}

export const MenuOption: FunctionComponent<MenuOptionProps> = (props: MenuOptionProps) => {
    const {
        children,
        className,
        classes: overrideClasses,
        size,
        icon,
        disabled,
        secondaryLabel,
        value,
        onClick,
        onSelect,
        ...otherProps
    } = props;

    let validChildren: ReactNode[] | null = null;
    let submenuProps: MenuProps | CollapseMenuProps = {};
    let submenuType: SubmenuContextType['submenuType'];

    Children.forEach(children, (child: any) => {
        const childIsObject = typeof child === 'object' && child != null;
        if (childIsObject && (child as any).type === Menu) {
            submenuType = 'flyout';
            submenuProps = (child as Component).props as MenuProps;
        } else if (childIsObject && (child as any).type === CollapseMenu) {
            submenuType = 'collapse';
            submenuProps = (child as Component).props as CollapseMenuProps;
        } else {
            submenuType = undefined;
            if (validChildren) {
                validChildren.push(child);
            } else {
                validChildren = [child];
            }
        }
    });

    const menuContext = useContext(MenuContext);
    const submenuContext = useContext(SubmenuContext);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const labelRef = useRef<HTMLSpanElement>(null);

    // A regular submenu refers to a completely separate Menu instance, such as
    // a flyout Menu.
    const [submenuVisible, setSubmenuVisible] = useState(
        (hasSubmenu() && submenuProps.visible) || false
    );

    // In contrast, with a collapse-style menu, the root Menu retains focus.
    const [collapseMenuVisible, setCollapseMenuVisible] = useState(
        (hasCollapseMenu() && submenuProps.visible) || false
    );

    const [valueForSelected, setValueForSelected] = useState(value || '');

    function getValue(): string | number {
        return value || valueForSelected;
    }

    function isSelected() {
        return menuContext.values.has(getValue());
    }

    // If props.value is not set, then label text is used in place of props.value.
    // However, it may not have rendered yet. This useEffect will catch the
    // just-rendered label text and, only if it needs to, force a new render
    // which will update the proxy value and therefore the selected state.
    useEffect(() => {
        if (
            !(value != null) &&
            labelRef.current &&
            labelRef.current.textContent !== valueForSelected
        ) {
            setValueForSelected(labelRef.current.textContent || '');
        }
    }, [menuContext.values, value]);

    function isDisabled() {
        return disabled !== undefined
            ? disabled
            : menuContext.disabled !== undefined
              ? menuContext.disabled
              : isSelected() && !menuContext.multiple;
    }

    function hasSubmenu() {
        return submenuType === 'flyout';
    }

    function hasCollapseMenu() {
        return submenuType === 'collapse';
    }

    function hasAnyChildMenu() {
        return hasSubmenu() || hasCollapseMenu();
    }

    function onOptionClick(event: MouseEvent) {
        if (onClick) {
            logWarningOnce(
                'UI Toolkit MenuOption: `onClick` is deprecated and will be removed in an upcoming major release. Use the `onSelect` event instead.'
            );
            onClick(event);
        }
        if (event.isDefaultPrevented()) {
            return;
        }
        if (onSelect) {
            let defaultPrevented = false;
            const menuSelectArgs: MenuOptionSelectEvent = {
                value: getValue(),
                preventDefault: () => {
                    defaultPrevented = true;
                },
            };
            onSelect(menuSelectArgs);
            if (defaultPrevented) {
                return;
            }
        }
        if (hasSubmenu()) {
            showSubmenu(
                new MenuBlurEvent({
                    menuElement: null,
                    nextActiveElement: event.target as HTMLElement,
                    input: 'pointer',
                })
            );
        } else if (hasCollapseMenu()) {
            showCollapseMenu();
        } else {
            menuContext.optionClick();
            if (menuContext.multiple) {
                if (isSelected()) {
                    menuContext.optionDeselect(getValue());
                } else {
                    menuContext.optionSelect(getValue());
                }
            } else {
                menuContext.optionSelect(getValue());
            }
        }
    }

    function onKeyDown(event: KeyboardEvent) {
        // In Cypress, event.which, event.keyCode, and event.code are all
        // undefined but event.nativeEvent.which is defined.
        const which = event.which || event.nativeEvent.which;
        if (which === KeyHelpers.keyCode.ARROW_RIGHT) {
            if (hasSubmenu()) {
                showSubmenu(
                    new MenuBlurEvent({
                        menuElement: null,
                        nextActiveElement: null,
                        input: 'keyboard',
                    })
                );
            } else if (hasCollapseMenu()) {
                showCollapseMenu();
            }
        } else if (which === KeyHelpers.keyCode.ARROW_LEFT) {
            if (hasCollapseMenu()) {
                hideCollapseMenu();
            }
        }
    }

    // Tests if a click outside of a submenu is on this MenuOption or not.
    // Important because this MenuOption is the target of the submenu and thus,
    // unlike a click that is otherwise outside of the submenu, a click on this
    // MenuOption should not lead to the submenu closing.
    function isClickOnSubmenuTarget(event: MenuBlurEvent) {
        const { input, nextActiveElement } = event;
        const nextActiveEl = nextActiveElement as any;
        const eventIsInsideDocument = isBrowser && window !== nextActiveEl;

        return (
            (input === 'pointer' && nextActiveElement === buttonRef.current) ||
            (eventIsInsideDocument &&
                buttonRef.current &&
                buttonRef.current.contains(nextActiveElement))
        );
    }

    function onSubmenuBlur(event: MenuBlurEvent) {
        if (isClickOnSubmenuTarget(event)) {
            return;
        }
        if (submenuProps && submenuProps.onBlur) {
            submenuProps.onBlur(event);
        }
        if (hasSubmenu()) {
            hideSubmenu(event);
        }
        if (hasCollapseMenu() && event.input !== 'selection') {
            hideCollapseMenu();
        }
        menuContext.submenuHide(event);
    }

    function showSubmenu(event: MenuBlurEvent) {
        if (submenuVisible) {
            hideSubmenu(event);
        } else {
            setSubmenuVisible(true);
            menuContext.submenuShow();
        }
    }

    function hideSubmenu(event: MenuBlurEvent) {
        setSubmenuVisible(false);
        menuContext.submenuHide(event);
    }

    function showCollapseMenu() {
        if (collapseMenuVisible) {
            hideCollapseMenu();
        } else {
            setCollapseMenuVisible(true);
        }
    }

    function hideCollapseMenu() {
        setCollapseMenuVisible(false);
    }

    function getSubmenuMenuProps(): MenuProps {
        return {
            // Default submenu props that can be overwritten by props set
            // directly on the sub Menu:
            autoFocus: true,
            offset: [0, -4],
            placement: 'right-top',
            target: buttonRef.current || undefined,

            // Static/user-defined sub menu props:
            ...submenuProps,

            // Props overwritten by this MenuOption:
            onBlur: onSubmenuBlur,
            visible: submenuVisible,
        };
    }

    function getSubmenuCollapseProps(): CollapseMenuProps {
        return {
            // Static/user-defined sub menu props:
            ...submenuProps,

            // Props overwritten by this MenuOption:
            onBlur: onSubmenuBlur,
            visible: collapseMenuVisible,
        };
    }

    function getChildMenuContext() {
        return {
            ...menuContext,
            size: size || menuContext.size,
        };
    }

    const theme = useTheme();
    const selected = isSelected();
    const cssClasses = useStyleSheet(menuOptionStyleSheet, {
        theme,
        size: size || menuContext.size,
        selected: selected || false,
        disabled: disabled || false,
        submenuVisible: submenuVisible || false,
        indented: submenuContext.isSubmenu && submenuContext.submenuType === 'collapse',
    });

    const label = validChildren || value || '';
    const buttonClass = getOptionClass({
        cssClasses,
        className,
        overrideClasses,
    });
    const labelClass = getLabelClass({
        cssClasses,
        overrideClasses,
    });
    const iconClass = icon && getIconClass({ cssClasses, overrideClasses });
    const submenuIconClass = hasAnyChildMenu()
        ? getSubmenuIconClass({ cssClasses, overrideClasses })
        : undefined;

    return (
        <>
            <button
                data-gs-uitk-component="menu-option"
                className={buttonClass}
                onClick={onOptionClick}
                aria-current={isSelected()}
                aria-expanded={hasCollapseMenu() ? !!collapseMenuVisible : undefined}
                disabled={isDisabled()}
                onKeyDown={onKeyDown}
                tabIndex={isDisabled() ? -1 : 0}
                role="menuitem"
                ref={buttonRef}
                {...otherProps}
            >
                {icon && (
                    <Icon {...icon} className={iconClass} data-cy="gs-uitk-menu__option-icon" />
                )}
                <span className={labelClass} ref={labelRef}>
                    {label}
                </span>
                {secondaryLabel && (
                    <kbd
                        className={getSecondaryLabelClass({
                            cssClasses,
                            overrideClasses,
                        })}
                    >
                        {secondaryLabel}
                    </kbd>
                )}
                {hasSubmenu() && (
                    <Icon name="arrow-right" type="filled" className={submenuIconClass} />
                )}
                {hasCollapseMenu() && (
                    <Icon
                        name={`keyboard-arrow-${collapseMenuVisible ? 'up' : 'down'}` as IconName}
                        type="filled"
                        className={submenuIconClass}
                    />
                )}
            </button>
            <MenuContext.Provider value={getChildMenuContext()}>
                {hasSubmenu() && (
                    <SubmenuContext.Provider value={{ isSubmenu: hasSubmenu(), submenuType }}>
                        <Menu {...getSubmenuMenuProps()} />
                    </SubmenuContext.Provider>
                )}
                {hasCollapseMenu() && (
                    <SubmenuContext.Provider
                        value={{
                            isSubmenu: hasCollapseMenu(),
                            submenuType,
                            parentElement: buttonRef.current as HTMLElement,
                        }}
                    >
                        <CollapseMenu {...getSubmenuCollapseProps()} />
                    </SubmenuContext.Provider>
                )}
            </MenuContext.Provider>
        </>
    );
};

MenuOption.propTypes = {
    disabled: PropTypes.bool,
    icon: PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }) as any, // TS gets confused from the IconBaseProps filled | outlined type
    secondaryLabel: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    onSelect: PropTypes.func,
};

export function isMenuOption(component: any): component is ReactElement<MenuOptionProps> {
    return isValidElement(component) && component.type === MenuOption;
}
