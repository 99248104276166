import { dateComparator, numericComparator } from '../../../../modules/Grid/helper';
import { gridCompositeFormatter, gridGroupCompositeFormatter } from '../../../../utils/portfolioGridFormatter';
import { blankCellFormatter } from '../../../../utils/portfolioGridFormatter';
import translator from '../../../../services/translator';

const {translate: t} = translator;

const countDecimals =  (value) => {
  if (value) {
    if (Math.floor(value) === value) {
      return 0;
    } else if (value.toString().split(".").length > 1) {
      return value.toString().split(".")[1].length
    } else {
      return 0
    }
  }
}
const numberConfig = [{"one": 1}, {"two": 2}, {"three": 3}, {"four": 4}, {"five": 5},
  {"six": 6}, {"seven": 7}, {"eight": 8}, {"nine": 9}]

const cellClassIteratingFunc = (cellClassName)=>{
  const formatClassesObject = {};
  numberConfig.forEach(number  => {
    formatClassesObject[cellClassName.replace("{num}", Object.keys(number)[0])]= function (params) {
      return countDecimals(params.data ? params.data[params.colDef.colId]: null) === Object.values(number)[0]
    }
  })
  return formatClassesObject;
}

const columnTypes = {
  'compositeFormatter': {valueFormatter: gridCompositeFormatter},
  'groupColumnFormatter': {
    cellRendererParams: {
      suppressCount: true,
      checkbox: false,
      innerRenderer: function (params) {
        if (params.value === "--" || params.valueFormatted === "--") {
          return t("tkCopyPort23")
        } else {
          let value = params.value
          if(params.colDef.groupFormatters){
            value = gridGroupCompositeFormatter(params) // formatting group columns
          }
          return (<span title={value}>{value}</span>)
        }
      }
    },
    colSpan: function(params) {
      return params.node.group ? 2 : 1;
    }
  },
  'booleanColumn': {
    valueFormatter: blankCellFormatter,
  },
  'numericSortColumn': {
    valueFormatter: gridCompositeFormatter,
    cellClassRules:  cellClassIteratingFunc("{num}-digit-format"),
    comparator: numericComparator
  },
  'navColumn': {
    valueFormatter: gridCompositeFormatter,
    cellClassRules:  cellClassIteratingFunc("{num}-digit-nav"),
    comparator: numericComparator
  },
  'variableNumericColumn': {
    valueFormatter: gridCompositeFormatter,
    comparator: numericComparator
  },
  'dateColumn': {
    valueFormatter: gridCompositeFormatter,
    comparator: dateComparator
  },
};

export default columnTypes;
