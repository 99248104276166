/* eslint-disable react/no-multi-comp */
import React, {PureComponent, useState} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './fieldTemplates.scss';
import {Conditional} from '../../../core/Conditional';
import Link from '../../../core/Link';
import translator  from '../../../../services/translator';

const {translate: t} = translator;

const AccountNumberToggleComp = (props) => {
  const {accountNumber} = props;
  const maskedAccountNumber = `*****${accountNumber.substr(accountNumber.length - 4, accountNumber.length)}`;
  const [showAccountNumber, setShowAccountNumber] = useState(false);
  const toggleBankAccountNumber = () => {
    setShowAccountNumber(!showAccountNumber);
  };

  return (
    <div>
      <span>{`${t('tkBankAccountId')}`}</span> (<Link
        extraProps={{
          id: 'showHideLink'
        }}
        classType="link-primary-small"
        handleClick={toggleBankAccountNumber}
        label={showAccountNumber ? t('tkHide') : t('tkShow')} />)<span>: </span><span>{showAccountNumber ? accountNumber : maskedAccountNumber}</span>
    </div>
  );
};

AccountNumberToggleComp.propTypes = {
  accountNumber: PropTypes.number
};
/*eslint-disable */
export const firmBranchRenderer = () => {
  class firmBranchOptionRenderer extends PureComponent {
    render() {
      const {data, index, style} = this.props;
      const {options, handleSelect} = data;
      const item = options[index];

      return (
        <div
          role="listitem"
          style={style}
          onMouseDown={() => {
            handleSelect(item);
          }}
        >
          <div
            className={cn('select-renderer__option', {'select-renderer__option--selected': item.isSelected}, {'select-renderer__option--hovered': item.isHovered})}>
            <div className="select-renderer__row">
              <span>{item.firmCode && `${t('tkFirmId')}: ${item.firmCode} `}</span>
              <span>{item.branchCode && `${t('tkBranchId')}: ${item.branchCode}`}</span>
            </div>
            <div className="select-renderer__row">{`${item.branchName}`}</div>
          </div>
          <div className="select-renderer__divider" />
        </div>
      );
    }
  }
  /*eslint-disable */
  firmBranchOptionRenderer.propTypes = {
    data: PropTypes.object,
    index: PropTypes.number,
    style: PropTypes.object
  };

  return firmBranchOptionRenderer;
};

export const accountRenderer = (includeTaAccount = true) => {
  class accountOptionRenderer extends PureComponent {
    render() {
      const {data, index, style} = this.props;
      const {options, handleSelect} = data;
      const item = options[index];
      return (
        <div role="listitem" style={style} onMouseDown={() => handleSelect(item)}>
          <div
            className={cn('select-renderer__option', {'select-renderer__option--selected': item.isSelected}, {'select-renderer__option--hovered': item.isHovered})}>
            <div className="select-renderer__row">
              {(item.bin || item.taAccount) && `${t('tkAccountId')}: ${item.bin || `-${item.taAccount}-`}`}
            </div>
            {
              includeTaAccount &&
              <div className="select-renderer__row">
                {item.taAccount && `${t('tkTAAccountId')}: ${item.taAccount}`}
              </div>
            }
            <div className="select-renderer__row">{`${t('tkAccountName')}: ${item.name ? item.name : '--'}`}</div>
          </div>
          <div className="select-renderer__divider" />
        </div>
      );
    }
  }

  accountOptionRenderer.propTypes = {
    data: PropTypes.object,
    index: PropTypes.number,
    style: PropTypes.object
  };

  return accountOptionRenderer;
};

export class fundRenderer extends PureComponent {
  render() {
    const {data, index, style} = this.props;
    const {options, handleSelect} = data;
    const item = options[index];
    return (
      <div role="listitem" style={style} onMouseDown={() => handleSelect(item)}>
        <div
          className={cn('select-renderer__option', {'select-renderer__option--selected': item.isSelected}, {'select-renderer__option--hovered': item.isHovered})}>
          <div className="select-renderer__row">
            {item.name} - {item.currency}
          </div>
          <div className="select-renderer__row">
            <span>{item.nasdaqTicker && `${item.nasdaqTicker} | `}</span>
            <span>{item.taId === 1 && item.cusip && `${item.cusip} | `}</span>
            <span>{item.taId === 2 && item.isin && `${item.isin} | `}</span>
            <span>{item.displayCode && `#${item.displayCode}`}</span>
          </div>
        </div>
        <div className="select-renderer__divider" />
      </div>
    );
  }
}

fundRenderer.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  style: PropTypes.object
};

export const simpleRenderer = ({option, hoverOption, isHovered, selectOption, labelKey, valueKey, selectedLabel}) => {
  const isSelected = selectedLabel === option[labelKey];
  return (
    <li
      aria-label={option[labelKey]}
      key={option[valueKey]}
      className={cn('trade-dropdown__row', {'trade-dropdown__row--selected': isSelected}, {'trade-dropdown__row--hovered': isHovered})}
      onKeyPress={hoverOption}
      onMouseDown={() => selectOption(option)}>
      {option[labelKey]}
    </li>
  );
};

simpleRenderer.propTypes = {
  hoverOption: PropTypes.func,
  option: PropTypes.object,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  selectOption: PropTypes.func,
  selectedLabel: PropTypes.string,
  isHovered: PropTypes.bool
};

export const paymentInstructionsRenderer = () => {
  const paymentInstructionsOptionRenderer = ({option, hoverOption, selectOption, valueKey, labelKey, selectedLabel, isHovered}) => {
    const {label, aba, bankName, accountNumber, accountName, currency, additionalInfo = {}} = option;
    const {subAccountNumber = '', subAccountName = '', refField1 = '', refField2 = ''} = additionalInfo;
    const isSelected = selectedLabel === option[labelKey];
    const isStringNotEmpty = (value) => (value && value.trim() !== '');

    return (
      <li
        aria-label={label}
        key={option[valueKey]}
        className={cn(
          'trade-dropdown__row',
          'trade-dropdown__row-settlement',
          {'trade-dropdown__row-settlement--selected': isSelected},
          {'trade-dropdown__row-settlement--hovered': isHovered})
        }
        onKeyPress={hoverOption}
        onMouseDown={(e = {target:{}}) => {
          const {id} = e.target;
          selectOption(option, e, null, (id !== 'showHideLink'));
        }}>
        {label && <div className={cn('instructionId', {'instructionId--selected': (isSelected)})}>{`${t('tkInstructionId')}: ${label}`}</div>}
        {aba && <div>{`${t('tkBankId')}: ${aba}`}</div>}
        {bankName && <div>{`${t('tkBankName')}: ${bankName}`}</div>}
        {accountNumber && <AccountNumberToggleComp accountNumber={accountNumber} />}
        {accountName && <div>{`${t('tkBankAccountName')}: ${accountName}`}</div>}
        {currency && <div>{`${t('tkCurrency')}: ${currency}`}</div>}
        <Conditional
          condition={additionalInfo && Object.keys(additionalInfo).some(key => isStringNotEmpty(additionalInfo[key]))}>
          <div>{`${t('tkAdditionalInfo')}: `}
            <Conditional condition={isStringNotEmpty(subAccountNumber)}><span>{subAccountNumber}<br /></span></Conditional>
            <Conditional condition={isStringNotEmpty(subAccountName)}><span>{subAccountName}<br /></span></Conditional>
            <Conditional condition={isStringNotEmpty(refField1)}><span>{refField1}<br /></span></Conditional>
            <Conditional condition={isStringNotEmpty(refField2)}><span>{refField2}<br /></span></Conditional>
          </div>
        </Conditional>
      </li>
    );
  };

  paymentInstructionsOptionRenderer.propTypes = {
    option: PropTypes.object,
    selectOption: PropTypes.func,
    valueKey: PropTypes.string,
    labelKey: PropTypes.string,
    selectedLabel: PropTypes.string,
    isHovered: PropTypes.bool,
    hoverOption: PropTypes.func
  };

  return paymentInstructionsOptionRenderer;
};

export const optionsRenderer = (key) => {
  switch (key) {
    case 'firm':
      return firmBranchRenderer();
    case 'account':
      return accountRenderer();
    case 'shareClass':
      return fundRenderer;
    case 'paymentInstructionId':
      return paymentInstructionsRenderer();
    case 'shellAccount':
      return accountRenderer(false);
    default:
      return simpleRenderer;
  }
};
