import { GridWrapperConfiguration } from '../../grid-wrappers/grid-wrapper';
import { SystemConfigurationActionKeys } from './action-constants';

export type SystemConfigurationActionTypes =
    | SystemConfigurationSetGridWrapperAction
    | SystemConfigurationStoreReadyAction;

export interface SystemConfigurationSetGridWrapperAction {
    type: SystemConfigurationActionKeys.SET_GRID_WRAPPER_CONFIGURATION;
    configuration: GridWrapperConfiguration;
}

export const SystemConfigurationSetGridWrapper = (
    configuration: GridWrapperConfiguration
): SystemConfigurationSetGridWrapperAction => ({
    configuration,
    type: SystemConfigurationActionKeys.SET_GRID_WRAPPER_CONFIGURATION,
});

export interface SystemConfigurationStoreReadyAction {
    type: SystemConfigurationActionKeys.STORE_READY;
}
export const SystemConfigurationStoreReady = (): SystemConfigurationStoreReadyAction => ({
    type: SystemConfigurationActionKeys.STORE_READY,
});
