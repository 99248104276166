import {
    StyleSheet,
    CssPropertyType,
    CssClassDefinitionsObject,
} from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { FormSize } from './form-types';
import { DeepReadonly } from 'ts-essentials';
import './form-text-theme-overrides';

export interface FormTextStyleSheetProps {
    theme: Theme;
    size: FormSize;
    disabled: boolean;
}

export function getFormTextStyles({ theme, disabled }: { theme: Theme; disabled: boolean }) {
    return {
        display: 'block',
        margin: '4px 0 0 0',
        color: theme.text.secondary,
        opacity: disabled ? 0.3 : 1.0,
        ...theme.typography.body03,
    };
}

export interface FormTextCssClasses {
    root: string;
}

export type FormTextStyledClasses = CssClassDefinitionsObject<keyof FormTextCssClasses>;

export interface FormTextStyleOverridesParams {
    props: DeepReadonly<FormTextStyleSheetProps>;
    createDefaultStyledClasses: () => FormTextStyledClasses;
}

export const formTextStyleSheet = new StyleSheet('form-text', (props: FormTextStyleSheetProps) => {
    return createComponentClassDefinitions<FormTextStyleSheetProps, FormTextStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.formText
    );
});

function createDefaultStyledClasses({
    theme,
    size,
    disabled,
}: FormTextStyleSheetProps): FormTextStyledClasses {
    return {
        root: {
            ...getFormTextStyles({ theme, disabled }),
            fontSize: formTextSizeVariants[size].fontSize,
        },
    };
}

export const formTextSizeVariants: { [name in FormSize]: FormGroupSizeVariant } = {
    sm: {
        fontSize: '11px',
    },
    md: {
        fontSize: '11px',
    },
    lg: {
        fontSize: '12px',
    },
};

interface FormGroupSizeVariant {
    fontSize: CssPropertyType['fontSize'];
}
