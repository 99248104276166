import {createSelector} from 'reselect';
import isEmpty from 'lodash/isEmpty';
import {labelSelector} from '../../../app';
import {createDeepEqualSelector} from '../../../../utils/selectorUtils';
import {customReportCrudResponseSelector, mySavedReportsDataSelector} from '../../../app/reports';
import translator from '../../../../services/translator';
import {REPORTS_STATUS_CODES, SHOW_ERROR_ON, SORT} from '../../../../constants/pageConstants';
import {getErrorObject} from '../individualReport';
const {translate: t} = translator;

// ADD selector only pagaData
export const groupColumns = state => state.pageData.groupColumns;
export const columnsMetadataSelector = state => state.pageData.columnsMetadata && state.pageData.columnsMetadata.columns || [];
export const reportDataToDeleteSelector = state => state.pageContext.reportDataToDelete;
const myReportSortColumnSelector = state => state.appContext.myReportSortColumn;

// ADD selector only pageContext
export const isLoading = state => state.pageContext.loading; // Don't assign any fallback

export const metaDataSelector = createSelector(
  columnsMetadataSelector,
  labelSelector,
  myReportSortColumnSelector,
  (metaData, labels, myReportSortColumn) => {
    return metaData.map( column => {
      const columnData = {...column};
      columnData.headerTooltip = labels[column.headerName];
      columnData.headerName = labels[column.headerName];
      if(column.category) {
        columnData.category = labels[column.category];
      }
      if (myReportSortColumn && (myReportSortColumn.colId === columnData.colId)) {
        columnData.sort = myReportSortColumn.sort;
      }
      if (column.width) {
        columnData.width = column.width;
      }
      if (column.suppressSizeToFit) {
        columnData.suppressSizeToFit = column.suppressSizeToFit;
      }
      return columnData;
    });
  }
);

export const hasServiceErrorOccurredSelector = createSelector(
  getErrorObject,
  (errorObject) => {
    return (errorObject && errorObject.showErrorOn === SHOW_ERROR_ON.INSIDE_REPORTS_GRID);
  }
);

export const myReportsCountSelector = createSelector(
  mySavedReportsDataSelector,
  hasServiceErrorOccurredSelector,
  (mySavedReports = [], hasServiceErrorOccurred) => {
    return !hasServiceErrorOccurred && mySavedReports.length;
  }
);

export const gridDataSelector = createSelector(
  mySavedReportsDataSelector,
  (mySavedReportsData = []) => (
    mySavedReportsData
  )
);

export const snackbarListSelector = createDeepEqualSelector(
  labelSelector,
  customReportCrudResponseSelector,
  (labels, customReportCrudResponse) => {
    const {SUCCESS, DELETE_REPORT_VIEW_FAILED, DELETE_REPORT_FAILED} = REPORTS_STATUS_CODES;
    const list = [];
    if (!isEmpty(customReportCrudResponse)) {
      const {
        reportName,
        status,
        isDeleteReport
      } = customReportCrudResponse;
      if (isDeleteReport) {
        let additionalProps = {};
        if (status === SUCCESS) {
          additionalProps = {
            id: 'deleteReportMsg',
            affix: false,
            type: 'success',
            msgCopy: t('tkReportDeleteSuccessMsg', reportName),
          };
        } else if (status === DELETE_REPORT_VIEW_FAILED) {
          additionalProps = {
            id: 'deleteReportMsg',
            type: 'error',
            msgCopy: t('tkErrorDeletingReportView', reportName),
          };
        } else if (status === DELETE_REPORT_FAILED) {
          additionalProps = {
            id: 'deleteReportFailedMsg',
            type: 'error',
            msgCopy: t('tkErrorDeletingReport', reportName),
          };
        }
        list.push({
          displayMultiple: true,
          autoHide: false,
          showCloseCTA: true,
          ...additionalProps
        });
      }
    }
    return list;
  }
);

export const noRowDataMsgSelector = createSelector(
  getErrorObject,
  labelSelector,
  mySavedReportsDataSelector,
  (errorObject, labels, mySavedReportsData) => {
    if ((errorObject && errorObject.showErrorOn === SHOW_ERROR_ON.INSIDE_REPORTS_GRID)) {
      return labels[errorObject.errorMessage];
    }
    return isEmpty(mySavedReportsData) && labels.tkNoSavedReports;
  }
);

export const preDefinedSortingStateSelector = createSelector(
  metaDataSelector,
  (columnsMetadata) => {
    const preDefinedSortingColumn = columnsMetadata.find(({colId}) => colId === 'dateModified') || {};
    return preDefinedSortingColumn ? [{...preDefinedSortingColumn, sort: SORT.DESC}] : [];
  }
);
