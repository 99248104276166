import { Component } from 'react';
import PropTypes from 'prop-types';
import {
    DEFAULT_BREAKPOINTS,
    getBreakpoint,
    AdaptiveBreakpoints,
} from '@gs-ux-uitoolkit-common/layout';

export {
    getBreakpoint as getAdaptiveBreakpoint,
    type AdaptiveBreakpoints,
} from '@gs-ux-uitoolkit-common/layout';

export interface AdaptiveContainerProps {
    /**
     * Customizable `Breakpoints` that determines at what pixel width a specific component will be
     *    rendered (the width is measured from the div element which AdaptiveContainer renders as a
     *    container; it normally expands to fill the width of whichever element it is appended to).
     */
    breakpoints?: AdaptiveBreakpoints;

    /**
     * A dictionary of components, each with associated properties or attributes,
     *    that correspond to given breakpoints.
     */
    components: object;

    /**
     * Allows you to explicitly set the width of the container.
     */
    width?: number | string;

    /**
     * A CSS class to customize the appearance of the component.
     */
    className?: string;

    /**
     * Can be used to specify inline styles to customize the appearance of the component.
     */
    style?: object;
}

export interface AdaptiveContainerState {
    width: number | string | undefined;
}

export const adaptiveContainerDefaultProps = {
    breakpoints: DEFAULT_BREAKPOINTS,
    components: {},
    // Example components configuration.
    // components: {
    //         xl: {
    //                 component: Grid,
    //                 props: {...}
    //         },
    //         lg: {...},
    //         md: {...},
    //         sm: {...},
    //         xs: {...},
    // }
};

/**
 * The AdaptiveContainer instantiates specific component/configuration
 * combinations for specific breakpoints. It is designed primarily to
 * instantiate Grid or custom components that use Model instances.
 */
export class AdaptiveContainer extends Component<AdaptiveContainerProps, AdaptiveContainerState> {
    // PropTypes formats for breakpoints and components are deliberately left
    // undefined to enable custom breakpoint names.
    public static propTypes: { [key in keyof AdaptiveContainerProps]: any } = {
        // eslint-disable-next-line react/forbid-prop-types
        breakpoints: PropTypes.object,
        // eslint-disable-next-line react/forbid-prop-types
        components: PropTypes.object,
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        className: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types
        style: PropTypes.object,
    };

    public static defaultProps = {
        breakpoints: adaptiveContainerDefaultProps.breakpoints,
        components: adaptiveContainerDefaultProps.components,
    };

    private el?: HTMLDivElement | null;
    private throttleNewWidth?: number;

    constructor(props: AdaptiveContainerProps) {
        super(props);
        this.state = { width: isNaN(props.width as number) ? 0 / 0 : props.width };
    }

    componentDidMount() {
        this.onResize();
        // Other possibility: use MediaQueryList
        // @see https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList
        window.addEventListener('resize', this.onResize);
    }

    componentDidUpdate(prevProps: AdaptiveContainerProps) {
        if (this.props.width !== prevProps.width) {
            this.setState({ width: +(this.props.width as number) });
        }
        this.onResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
        if (isNaN(+(this.props.width as number))) {
            const width = this.el ? this.el.clientWidth : this.state.width;
            if (width !== this.state.width) {
                // Re-render on resize, but only as often as necessary.
                window.clearTimeout(this.throttleNewWidth);
                this.throttleNewWidth = window.setTimeout(() => {
                    this.setState({ width });
                }, 100);
            }
        }
    };

    renderComponent() {
        const { breakpoints, components } = this.props;
        const bpObj = getBreakpoint(breakpoints!, components, this.state.width);
        if (bpObj) {
            const Component = bpObj.component;
            return <Component {...bpObj.props} />;
        }
        return null;
    }

    render() {
        const { className, style } = this.props;
        return (
            <div
                ref={el => {
                    this.el = el;
                }}
                className={className}
                style={style}
                data-gs-uitk-component="adaptive-container"
            >
                {this.renderComponent()}
            </div>
        );
    }
}
