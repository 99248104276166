import { connect } from 'react-redux';
import FAQ from '../../components/app/FAQ';
import {faqGlossaryPageLoadEventSelector, faqGlossaryContentsSelector} from '../../selectors/app';
import { eventAnalytics } from '../../actions/sagas';
import {
  faqQuestionAnswerExpandedAnalyticsData,
  faqTopicChosenAnalyticsData
} from '../../components/app/FAQ/analytics';

const mapStateToProps = (state) => ({
  faqContents: faqGlossaryContentsSelector(state),
  faqPageLoadEventData: faqGlossaryPageLoadEventSelector(state)
});

const mapDispatchToProps = (dispatch) => {
  return {
    onPageLoad: (faqPageOnLoadAnalyticsData) => (
      dispatch(eventAnalytics(faqPageOnLoadAnalyticsData))
    ),
    onClickFaqTopic: (topicName) => (
      dispatch(eventAnalytics(faqTopicChosenAnalyticsData(topicName)))
    ),
    onExpandFaqQuestionAnswer: (articleName) => (
      dispatch(eventAnalytics(faqQuestionAnswerExpandedAnalyticsData(articleName)))
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
