export const KeyHelpers: any = {};
let i;

KeyHelpers.isPrintableChar = (
    keyCode: any //a-z
) =>
    //special chars (229 for Asian chars)
    keyCode === 32 || //space
    (keyCode >= 48 && keyCode <= 57) || //0-9
    (keyCode >= 96 && keyCode <= 111) || //numpad
    (keyCode >= 186 && keyCode <= 192) || //;=,-./`
    (keyCode >= 219 && keyCode <= 222) || //[]{}\|"'
    keyCode >= 226 ||
    (keyCode >= 65 && keyCode <= 90);

KeyHelpers.fromKeyCode = (keyCode: any, shiftKey: any) => {
    let value;

    // in numpad subtract 48 from keyCode to get the number
    if (keyCode >= 48 && keyCode <= 57) {
        value = keyCode - 48;
    } else if (keyCode >= 96 && keyCode <= 105) {
        value = keyCode - 96;
    } else if (keyCode === 110 || keyCode === 190) {
        value = '.';
    } else if (keyCode === 109 || keyCode === 189) {
        value = '-';
    } else if (keyCode === 187) {
        value = shiftKey ? '+' : '=';
        // A - Z letters
    } else if (keyCode >= 65 && keyCode <= 90) {
        value = String.fromCharCode(keyCode);
        value = shiftKey ? value.toUpperCase() : value.toLowerCase();
    }

    return value === undefined ? '' : value;
};

KeyHelpers.alphabet = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
];

KeyHelpers.isMetaKey = (keyCode: number) => {
    const keyCodes = KeyHelpers.keyCode;
    const metaKeys = [
        keyCodes.ARROW_DOWN,
        keyCodes.ARROW_UP,
        keyCodes.ARROW_LEFT,
        keyCodes.ARROW_RIGHT,
        keyCodes.HOME,
        keyCodes.END,
        keyCodes.DELETE,
        keyCodes.BACKSPACE,
        keyCodes.F1,
        keyCodes.F2,
        keyCodes.F3,
        keyCodes.F4,
        keyCodes.F5,
        keyCodes.F6,
        keyCodes.F7,
        keyCodes.F8,
        keyCodes.F9,
        keyCodes.F10,
        keyCodes.F11,
        keyCodes.F12,
        keyCodes.TAB,
        keyCodes.PAGE_DOWN,
        keyCodes.PAGE_UP,
        keyCodes.ENTER,
        keyCodes.ESCAPE,
        keyCodes.SHIFT,
        keyCodes.CAPS_LOCK,
        keyCodes.ALT,
    ];

    return metaKeys.indexOf(keyCode) !== -1;
};

KeyHelpers.isCtrlKey = (keyCode: number) => {
    const keys = KeyHelpers.keyCode;

    return [keys.CONTROL_LEFT, 224, keys.COMMAND_LEFT, keys.COMMAND_RIGHT].indexOf(keyCode) !== -1;
};

// Unicode decimal values should come from here:  https://en.wikipedia.org/wiki/List_of_Unicode_characters#Basic_Latin
// TODO:  We need to take a look at the usage of these keyCodes.  Seems they were mistakenly used
//        as charCodes which are different.  For example, the charCode of a comma is 44, but the
//        keyCode is 188 (it was set to 44 below prior to Nov 28 2022).  Single quotation and Double
//        quotation marks have the same keyCode 222 - the double quotation marks have
//        shiftKey=true in the onKeyDown() event.
KeyHelpers.keyCode = {
    MOUSE_LEFT: 1,
    MOUSE_RIGHT: 3,
    MOUSE_MIDDLE: 2,
    BACKSPACE: 8,
    AMPERSAND: 55,
    '&': 55,
    INSERT: 45,
    DELETE: 46,
    END: 35,
    ENTER: 13,
    ESCAPE: 27,
    CONTROL_LEFT: 91,
    COMMAND_LEFT: 17,
    COMMAND_RIGHT: 93,
    CTRL: 17,
    ALT: 18,
    HOME: 36,
    PAGE_DOWN: 34,
    PAGE_UP: 33,
    PERIOD: 190,
    SPACE: 32,
    ' ': 32,
    SHIFT: 16,
    CAPS_LOCK: 20,
    TAB: 9,
    ARROW_RIGHT: 39,
    ARROW_LEFT: 37,
    ARROW_UP: 38,
    ARROW_DOWN: 40,
    F1: 112,
    F2: 113,
    F3: 114,
    F4: 115,
    F5: 116,
    F6: 117,
    F7: 118,
    F8: 119,
    F9: 120,
    F10: 121,
    F11: 122,
    F12: 123,
    A: 65,
    X: 88,
    C: 67,
    V: 86,
    EQUALS: 187,
    COMMA: 188,
    ',': 188,
    QUOTATION_MARK: 222,
    '"': 222,
    APOSTROPHE: 222,
    "'": 222,
};

// lower and upper case chars
for (i = 97; i < 123; i++) {
    KeyHelpers.keyCode[String.fromCharCode(i)] = i;
    KeyHelpers.keyCode[String.fromCharCode(i).toUpperCase()] = i - 32;
}

// numbers
for (i = 48; i < 58; i++) {
    KeyHelpers.keyCode[i - 48] = i;
}
