import React, { memo, Fragment } from 'react';
import { FixedSizeList } from 'react-window';
import { Card, CardBody } from '@gs-ux-uitoolkit-react/card';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { ThreeSquaresLoader } from '../../../../../../components/core/Loaders';
import CardDropdown from './CardDropdown';
import CardError from './CardError';
import translator from '../../../../../../services/translator';
import './index.scss';

const { translate: t } = translator;

const CardComponent = ({ loading, error, title, activeFilters, onFiltersUpdate, RowRenderer, cardRows, onRowClick }) => (
  <Card className='dbcard gs-scrollbar gs-uitk-override-css' bordered elevation='00'>
    <div className={cn('dbcard__controlpanel', { dbcard__loading: loading })}>
      <h4 className='dbcard__header'>{title}</h4>
      {!loading && (
        <Fragment>
          {activeFilters.map(({ selected, value, name }) => (
            <div key={name} className='dbcard__dropdown'>
              <CardDropdown name={name} selectedOption={selected} dropdownOptions={value} updateSelectedOption={onFiltersUpdate} />
            </div>
          ))}
        </Fragment>
      )}
    </div>
    <CardBody className='dbcard__body'>
      {!loading && (!cardRows.length || error) ? (
        <CardError errorMessage={error ? t('tkDashboardCardError') : t('tkFilterNoResultsFound')} />
      ) : loading ? (
        <ThreeSquaresLoader />
      ) : (
        <FixedSizeList itemData={cardRows} innerElementType='ul' itemCount={cardRows.length} itemSize={50} height={250}>
          {({ index, style, data }) => (
            <li style={style} key={index}>
              <div className='dbcard__bodyRow' onClick={() => onRowClick(data[index])}>
                <RowRenderer {...data[index]} />
              </div>
            </li>
          )}
        </FixedSizeList>
      )}
    </CardBody>
  </Card>
);

CardComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  title: PropTypes.string,
  activeFilters: PropTypes.array,
  onFiltersUpdate: PropTypes.func,
  RowRenderer: PropTypes.object,
  cardRows: PropTypes.array,
  onRowClick: PropTypes.func,
};

export default memo(CardComponent);
