import { createContext, useContext } from 'react';

export const UserMaintenanceContext = createContext({});

const useUserMaintenance = () => {
  const context = useContext(UserMaintenanceContext);
  if (!context) {
    throw new Error('useUserMaintenance must be used within UserMaintenanceContext');
  }
  return context;
};

export default useUserMaintenance;
