import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import HelpGuideProvider from '../../../../../components/core/HelpGuideTray/HelpGuideProvider';
import Header from './Header';
import TabSwitchBar from './TabSwitchBar';
import { helpGuideConfig } from '../../constants/configs/helpGuideConfig';
import useUserMaintenance from '../../context';
import { ADD_ORGANIZATION_DETAILS } from '../../actions';
import translator from '../../../../../services/translator';
import './index.scss';

const { translate: t } = translator;

const relationshipTypes = ['SUBSIDIARY', 'INTERMEDIARY', 'CLEARING'];

const OrganizationDetails = ({ organizationDetails }) => {
  const {
    dispatch,
    state: { organizationDetails: ctxOrganizationDetails },
  } = useUserMaintenance();

  const { relationships = [] } = ctxOrganizationDetails || {};

  useEffect(() => {
    if (organizationDetails) {
      const { relationships = [] } = organizationDetails || {};
      const newRelationships = [...relationships];
      relationshipTypes.forEach(relationshipType => {
        if (!newRelationships.find(data => data.relationshipType === relationshipType)) {
          newRelationships.push({
            organizationId: '',
            organizationName: '',
            relationshipType,
            targetOrganizationType: 'TARGET',
          });
        }
      });
      const updatedOrganizationDetails = { ...organizationDetails, relationships: newRelationships };
      dispatch({ type: ADD_ORGANIZATION_DETAILS, payload: updatedOrganizationDetails });
    }
  }, [organizationDetails]);

  const memoizedHelpGuideConfig = useMemo(() => {
    const {
      organizationDetails: { profile },
    } = helpGuideConfig;
    const newConfig = [];
    const relationshipInsertIdx = 9;
    const translateField = (fieldName, content) => (content === 'tkClearingAgentHelpGuide' ? t(fieldName, 1) : t(fieldName));

    for (let i = 0; i < relationshipInsertIdx; i++) {
      newConfig.push({ ...profile[i], fieldName: translateField(profile[i].fieldName, profile[i].content) });
    }

    if (relationships.length > 3) {
      relationships.slice(2).forEach((_, idx) => {
        newConfig.push({
          index: relationshipInsertIdx + idx,
          section: 'tkClearingAgents',
          fieldName: t('tkClearingAgentField', idx + 1),
          content: 'tkClearingAgentHelpGuide',
        });
      });
    } else {
      newConfig.push({
        ...profile[relationshipInsertIdx],
        index: relationshipInsertIdx,
        fieldName: translateField(profile[relationshipInsertIdx].fieldName, profile[relationshipInsertIdx].content),
      });
    }

    for (let i = relationshipInsertIdx + 1; i < profile.length; i++) {
      newConfig.push({ ...profile[i], index: newConfig.length, fieldName: translateField(profile[i].fieldName, profile[i].content) });
    }

    return newConfig;
  }, [relationships]);

  return (
    <HelpGuideProvider helpGuideConfig={memoizedHelpGuideConfig}>
      <Header />
      <div className='usersearch-details-container organization-details'>
        <TabSwitchBar />
      </div>
    </HelpGuideProvider>
  );
};

OrganizationDetails.propTypes = {
  organizationDetails: PropTypes.object,
};

export default OrganizationDetails;
