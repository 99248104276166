import { ButtonDisplay } from '../buttons/button-base';
import { ButtonCreate } from '../buttons/button-create';
import { pluginHeaderStyleSheet } from '../../style/component/plugin-header-stylesheet';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
export interface PluginHeaderProps {
    createButtonCallback?: () => void;
    displayCreateButton: boolean;
    subTitle?: string;
    secondaryAction?: JSX.Element;
}
export const PluginHeader = (props: PluginHeaderProps) => {
    const cssClasses = useStyleSheet(pluginHeaderStyleSheet, null);
    const actionCallBack =
        props.createButtonCallback && props.displayCreateButton ? (
            <ButtonCreate
                onClick={props.createButtonCallback}
                displayType={ButtonDisplay.IconLabel}
                label={'Create New'}
                actionType={'primary'}
            />
        ) : null;
    const actionCallBackWithSecondaryAction = (
        <span className="plugin-header-with-secondary-action">
            {actionCallBack}
            {props.secondaryAction}
        </span>
    );

    return (
        <div>
            <div className={`gs-uitk-plugin-screen-header-container ${cssClasses.root}`}>
                {props.subTitle && (
                    <h5 className="plugin-header-subtitle" data-cy="plugin-header-subtitle">
                        {props.subTitle}
                    </h5>
                )}
                {props.secondaryAction ? actionCallBackWithSecondaryAction : actionCallBack}
            </div>
        </div>
    );
};
