import { Component, Fragment, ChangeEvent } from 'react';
import { FormGroup } from '@gs-ux-uitoolkit-react/form';
import { Input } from '@gs-ux-uitoolkit-react/input';
import { Label } from '@gs-ux-uitoolkit-react/label';
import { HintList } from '@gs-ux-uitoolkit-common/datacore';
import { FormatControlProps } from '../column-hint-format-manager';

export class ColumnHintControlPrefixSuffix extends Component<FormatControlProps> {
    public render() {
        return (
            <Fragment>
                <FormGroup>
                    <Label>Prefix</Label>
                    <Input
                        data-cy="gs-uitk-datagrid-column-hint-prefix"
                        value={this.props.columnHint.format?.prefix || ''}
                        placeholder="e.g. $"
                        onChange={this.onChangePrefix}
                    />
                </FormGroup>

                <FormGroup>
                    <Label>Suffix</Label>
                    <Input
                        data-cy="gs-uitk-datagrid-column-hint-suffix"
                        value={this.props.columnHint.format?.postfix || ''}
                        placeholder="e.g. %"
                        onChange={this.onChangeSuffix}
                    />
                </FormGroup>
            </Fragment>
        );
    }

    private onChangePrefix = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const clonedHint: HintList = {
            ...this.props.columnHint,
            format: { ...this.props.columnHint.format, prefix: value },
        };
        this.props.onChangeHint(clonedHint);
    };

    private onChangeSuffix = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const clonedHint: HintList = {
            ...this.props.columnHint,
            format: { ...this.props.columnHint.format, postfix: value },
        };
        this.props.onChangeHint(clonedHint);
    };
}
