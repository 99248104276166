import { ColDef, ColGroupDef, ColumnApi, GridApi } from '@ag-grid-community/core';
import { logger } from '@gs-ux-uitoolkit-common/datacore';
import { DatasourceConfiguration } from '../grid-wrappers/grid-wrapper';
import { DashGridToagGridColumnConverter } from './dash-grid-to-ag-grid-column-converter';
import { DatasourceDynamicSchema } from './datasource-dynamic-schema';
import { Datasource } from './datasource-factory';
import { getGridOptionsService } from '../util/getGridOptionsService';

export class InMemoryDatasource implements Datasource {
    constructor(
        private datasourceConfiguration: DatasourceConfiguration,
        private gridApi: GridApi,
        private columnApi: ColumnApi
    ) {}
    // tslint:disable-next-line:no-empty
    public destroy(): void {}
    public connect(defaultData: any[] | null): Promise<void> {
        logger.debug('DatasourceConfig is ', this.datasourceConfiguration);
        if (this.datasourceConfiguration.url) {
            return fetch(this.datasourceConfiguration.url, {
                credentials: 'include',
            })
                .then(response => {
                    if (response.status !== 200) {
                        throw new Error(
                            'Looks like there was a problem. Status Code: ' + response.status
                        );
                    }

                    return response.json();
                })
                .then(json => {
                    const columnsAreDashGridFormat = json.options.columns.find(
                        (column: { groupingDelimiter: string }) => column.groupingDelimiter
                    );

                    let agGridColumns: (ColDef | ColGroupDef)[];
                    if (columnsAreDashGridFormat) {
                        const defaultGroupColDef = getGridOptionsService(this.gridApi).get(
                            'defaultColGroupDef'
                        );
                        const defaultHeaderGroupComponentParams =
                            defaultGroupColDef && defaultGroupColDef.headerGroupComponentParams;

                        agGridColumns = DashGridToagGridColumnConverter.convert(
                            json.options.columns,
                            undefined,
                            defaultHeaderGroupComponentParams
                                ? defaultHeaderGroupComponentParams.dynamicAlignment
                                : undefined
                        );
                    } else {
                        agGridColumns = json.options.columns;
                    }

                    this.gridApi.setColumnDefs(agGridColumns);
                    this.gridApi.setRowData(json.data);
                })
                .then(() => this.gridApi.hideOverlay());
        }
        if (!this.columnApi.getAllGridColumns()) {
            let columnDefs: (ColDef | ColGroupDef)[];
            if (!this.datasourceConfiguration.columns) {
                if (defaultData) {
                    columnDefs = DatasourceDynamicSchema.createagGridSchema(defaultData);
                } else {
                    throw new Error(
                        'No data and no column definitions were found. Please correct your configuration'
                    );
                }
            } else {
                columnDefs = this.datasourceConfiguration.columns;
            }
            if (!columnDefs) {
                throw new Error(
                    'Could not create the column definition. Please correct your configuration'
                );
            }
            this.gridApi.setColumnDefs(columnDefs);
        }
        this.gridApi.hideOverlay();
        return Promise.resolve();
    }
}
