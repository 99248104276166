import {
    ColorHex,
    ColorName,
    colors,
    ColorSwatch,
    ColorValue,
} from '@gs-ux-uitoolkit-common/design-system';
import { CssColor } from '@gs-ux-uitoolkit-common/style';
import { ColorContrastAmount } from '../theme';

/**
 * A reverse lookup map to get the color value from a hex value. This reverse lookup is only
 * used by the helpers in this file and should not be exposed outside the theme package
 */
const colorLookup: { [hex in ColorHex]?: ColorValue } = {};
(Object.keys(colors) as ColorValue[]).forEach(value => {
    colorLookup[colors[value]] = value;
});

/**
 * Converts a given hex value to a known GS Design System color name if a match was found.
 * Returns null if the hex value didn't match any defined colors.
 * @param hex A hex color value
 * @internal
 */
export function getColorValueFromHex(hex: string): ColorValue | undefined {
    return colorLookup[hex as ColorHex];
}

/**
 * Converts a given color value to it's name and swatch parts. For example, the value
 * 'gray040' will yield:
 *
 * {
 *   name: 'gray'
 *   swatch: '040'
 * }
 *
 * @internal
 */
export const parseColorValue = (
    value: ColorValue
): { name: ColorName; swatch: ColorSwatch } | null => {
    const parts = `${value}`.match(/([a-z]+)([0-9]+)/); // This can be improved

    // Parts will always have a length of 3 or null as we have 2 capturing groups
    if (parts) {
        const name = parts[1] as ColorName;
        const swatch = parts[2] as ColorSwatch;

        return { name, swatch };
    }

    return null;
};

/**
 * Lightens a given color hue by the number of steps in the color ramp corresponding to that hue.
 * For example:
 *
 *   lighten('gray050', 2) ==> 'gray030'
 *   lighten('blue080', 5) ==> 'blue030'
 *
 * If a given value can't be darkened, the value is returned as-is
 * @internal
 */
export const lighten = (
    value: ColorValue,
    steps: ColorContrastAmount,
    useWhite?: boolean
): ColorValue => {
    const parsedColor = parseColorValue(value);

    if (!parsedColor) {
        return value;
    }

    let swatchNum = Number(parsedColor.swatch) - steps * 10;
    if (swatchNum < 10) {
        if (useWhite) {
            return 'white';
        }
        swatchNum = 10;
    }

    return `${parsedColor.name}${swatchNum < 100 ? '0' : ''}${swatchNum}` as ColorValue;
};

/**
 * Darkens a given color hue by the number of steps in the color ramp corresponding to that hue.
 * For example:
 *
 *   darken('gray050', 2) ==> 'gray070'
 *   darken('blue010', 8) ==> 'blue090'
 *
 * If a given value can't be darkened, the value is returned as-is
 *
 * @internal
 */
export const darken = (
    value: ColorValue,
    steps: ColorContrastAmount,
    useBlack?: boolean
): ColorValue => {
    const parsedColor = parseColorValue(value);

    if (!parsedColor) {
        return value;
    }

    // Only gray supports a 110 shade.
    const maxSwatchNum = value.startsWith('gray') ? 110 : 100;
    let swatchNum = Number(parsedColor.swatch) + steps * 10;
    if (swatchNum > maxSwatchNum) {
        if (useBlack) {
            return 'black';
        }
        swatchNum = maxSwatchNum;
    }

    return `${parsedColor.name}${swatchNum < 100 ? '0' : ''}${swatchNum}` as ColorValue;
};

/**
 * Common helper function that takes 'lighten(...)' or `darken(...)` as an argument
 * @internal
 */
export function changeContrast(
    color: CssColor,
    amount: ColorContrastAmount,
    fn: typeof lighten | typeof darken
): CssColor {
    const colorValue = getColorValueFromHex(color.toString());
    if (!colorValue) {
        throw new Error(
            [
                `Invalid color "${color}" provided to "changeContrast(...)". `,
                `Only valid GS Design System colors are allowed in this theme`,
            ].join(' ')
        );
    }
    return colors[fn(colorValue, amount, true)];
}
