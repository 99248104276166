import { stringComparator } from '../../../../modules/Grid/helper';

export const defaultColDef = {
  filter: 'agTextColumnFilter',
  sortable: true,
  suppressMenu: true,
  filterParams: {
    suppressFilterButton: true,
    suppressMenu: true,
    newRowsAction: 'keep',
    caseSensitive: false,
  },
  floatingFilterParams: {
    suppressFilterButton: true,
    newRowsAction: 'keep',
  },
  floatingFilter: true,
  floatingFilterComponent: 'gsTextFloatingFilter',
  floatingFilterComponentParams: {
    suppressFilterButton: true,
    filterVisibility: 'hidden',
  },
  menuTabs: [' '],
  minWidth: 140,
  resizable: true,
  comparator: stringComparator,
};
