export const TRADE_STATES = Object.freeze({
  ENTRY: 0,
  REVIEW: 1,
  SUMMARY: 2
});

export const INTENDED_SOURCE_SYSTEM = {
  MOSAIC_FF : "MOSAIC_FF",
  MOSAIC_PF : "MOSAIC_PF",
  MOSAIC_TB : "MOSAIC_TB",
  MOSAIC_TI : "MOSAIC_TI"
};

export const IMPORT_TRADES_ACTION_TYPES = {
  REMOVE_ALL_TRADES : "REMOVE_ALL_TRADES",
  REMOVE_TRADE : "REMOVE_TRADE",
  SAVE_TRADES : "SAVE_TRADES",
};

const TRADDING_NOT_ALLOWED_PAGEID = ['UserSearch'];

export {
  TRADDING_NOT_ALLOWED_PAGEID
}
