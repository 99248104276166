export const helpGuideConfig = {
  userDetails: {
    profile: [
      // Identifying Information
      { index: 0, section: 'tkIdentifyingInformation', fieldName: 'tkFirstName', content: 'tkFirstNameHelpGuide' },
      { index: 1, section: 'tkIdentifyingInformation', fieldName: 'tkLastName', content: 'tkLastNameHelpGuide' },
      { index: 2, section: 'tkIdentifyingInformation', fieldName: 'tkUserId', content: 'tkUserIdHelpGuide' },
      {
        index: 3,
        section: 'tkIdentifyingInformation',
        fieldName: 'tkOrganization',
        content: 'tkOrganizationHelpGuide',
      },
      { index: 4, section: 'tkIdentifyingInformation', fieldName: 'tkGuid', content: 'tkGUIDHelpGuide' },
      {
        index: 5,
        section: 'tkIdentifyingInformation',
        fieldName: 'tkiMoneyNetID',
        content: 'tkIMoneyNetIDHelpGuide',
        internalUserField: true,
      },
      {
        index: 6,
        section: 'tkIdentifyingInformation',
        fieldName: 'tkGSInternal',
        content: 'tkGSInternalHelpGuide',
        internalUserField: true,
      },
      {
        index: 7,
        section: 'tkIdentifyingInformation',
        fieldName: 'tkMosaicInternal',
        content: 'tkMosaicInternalHelpGuide',
        internalUserField: true,
      },

      // Contact Information
      { index: 8, section: 'tkContactInformation', fieldName: 'tkWorkEmailAddress', content: 'tkWorkEmailHelpGuide' },
      { index: 9, section: 'tkContactInformation', fieldName: 'tkWorkPhone', content: 'tkWorkPhoneHelpGuide' },

      // Third Party Partner SSO Entitlements
      {
        index: 10,
        section: 'tkThirdPartyPartnerSsoEntitlements',
        fieldName: 'tkClearwaterId',
        content: 'tkClearwaterIDHelpGuide',
      },
      {
        index: 11,
        section: 'tkThirdPartyPartnerSsoEntitlements',
        fieldName: 'tkIonTreasuryId',
        content: 'tkIONTreasuryIDHelpGuide',
      },
      {
        index: 12,
        section: 'tkThirdPartyPartnerSsoEntitlements',
        fieldName: 'tkGTreasuryId',
        content: 'tkGTreasuryIDHelpGuide',
      },

      // Setup
      { index: 13, section: 'tkSetup', fieldName: 'tkProfile', content: 'tkProfileHelpGuide', internalUserField: true },
      { index: 14, section: 'tkSetup', fieldName: 'tkAccessType', content: 'tkAccessTypeHelpGuide', internalUserField: true },
      { index: 15, section: 'tkSetup', fieldName: 'tkTimeZone', content: 'tkTimezoneHelpGuide' },
      { index: 16, section: 'tkSetup', fieldName: 'tkNSCCAccess', content: 'tkNSCCAccessHelpGuide', internalUserField: true },
      { index: 17, section: 'tkSetup', fieldName: 'tkJurisdiction', content: 'tkJurisdictionHelpGuide' },

      // Functionality
      {
        index: 18,
        section: 'tkFunctionality',
        fieldName: 'tkEndClientBuffer',
        content: 'tkEndClientBufferHelpGuide',
        internalUserField: true,
      },
      {
        index: 19,
        section: 'tkFunctionality',
        fieldName: 'tkAllowShellAccounts',
        content: 'tkAllowShellAccountsHelpGuide',
        internalUserField: true,
      },
      {
        index: 20,
        section: 'tkFunctionality',
        fieldName: 'tkAllowCloseouts',
        content: 'tkAllowCloseoutsHelpGuide',
        internalUserField: true,
      },

      // Access
      {
        index: 21,
        section: 'tkAccess',
        fieldName: 'tkMosaicLastLogin',
        content: 'tkMosaicLastLoginHelpGuide',
      },
      { index: 22, section: 'tkAccess', fieldName: 'tkSSOOnly', content: 'tkSSOOnlyHelpGuide' },

      // Change Management Details
      {
        index: 23,
        section: 'tkChangeManagementDetails',
        fieldName: 'tkAccountCreationDate',
        content: 'tkAccountCreationDateHelpGuide',
      },
      {
        index: 24,
        section: 'tkChangeManagementDetails',
        fieldName: 'tkAccountProfileLastModifiedDate',
        content: 'tkAccountProfileLastModifiedDateHelpGuide',
      },
    ],
    features: [
      // Feature Entitlements - Core
      {
        index: 0,
        section: 'tkFeatureEntitlementsCore',
        fieldName: 'tkContactUs',
        content: 'tkContactUsHelpGuide',
      },
      {
        index: 1,
        section: 'tkFeatureEntitlementsCore',
        fieldName: 'tkFAQAndGlossary',
        content: 'tkFAQ&GlossaryHelpGuide',
      },
      {
        index: 2,
        section: 'tkFeatureEntitlementsCore',
        fieldName: 'tkMessageCenter',
        content: 'tkMessageCenterHelpGuide',
      },

      // Feature Entitlements - Account
      {
        index: 3,
        section: 'tkFeatureEntitlementsAccount',
        fieldName: 'tkAccountDetails',
        content: 'tkAccountDetailsHelpGuide',
      },
      {
        index: 4,
        section: 'tkFeatureEntitlementsAccount',
        fieldName: 'tkActivityTransactions',
        content: 'tkActivityTransactionHelpGuide',
      },
      {
        index: 5,
        section: 'tkFeatureEntitlementsAccount',
        fieldName: 'tkInvestmentPolicy',
        content: 'tkInvestmentPolicyHelpGuide',
      },
      {
        index: 6,
        section: 'tkFeatureEntitlementsAccount',
        fieldName: 'tkYourPortfolio',
        content: 'tkPortfolioHelpGuide',
      },
      {
        index: 7,
        section: 'tkFeatureEntitlementsAccount',
        fieldName: 'tkReporting',
        content: 'tkReportingHelpGuide',
      },

      // Feature Entitlements - Fund
      {
        index: 8,
        section: 'tkFeatureEntitlementsFund',
        fieldName: 'tkFundFinder',
        content: 'tkFundFinderHelpGuide',
      },
      {
        index: 9,
        section: 'tkFeatureEntitlementsFund',
        fieldName: 'tkFundTracker',
        content: 'tkFundTrackerHelpGuide',
      },
      {
        index: 10,
        section: 'tkFeatureEntitlementsFund',
        fieldName: 'tkGSAMFloatingNAVCalc',
        content: 'tkGSAMFloatingNAVCalculatorHelpGuide',
      },
      {
        index: 11,
        section: 'tkFeatureEntitlementsFund',
        fieldName: 'tkNavTransparencyInsights',
        content: 'tkTransparencyInsightsHelpGuide',
      },

      // Feature Entitlements - Trading
      {
        index: 12,
        section: 'tkFeatureEntitlementsTrading',
        fieldName: 'tkTradeBlotter',
        content: 'tkTradeBlotterHelpGuide',
      },
      {
        index: 13,
        section: 'tkFeatureEntitlementsTrading',
        fieldName: 'tkTradeImport',
        content: 'tkTradeImportHelpGuide',
      },
      {
        index: 14,
        section: 'tkFeatureEntitlementsTrading',
        fieldName: 'tkTradeApproval',
        content: 'tkTradeApprovalHelpGuide',
      },

      // Reporting Entitlements - Core Reports
      {
        index: 15,
        section: 'tkReportingEntitlementsCoreReports',
        fieldName: 'tkAccountBalances',
        content: 'tkAccountBalancesHelpGuide',
      },
      {
        index: 16,
        section: 'tkReportingEntitlementsCoreReports',
        fieldName: 'tkCurrentTradeStatus',
        content: 'tkCurrentTradeStatusHelpGuide',
      },
      {
        index: 17,
        section: 'tkReportingEntitlementsCoreReports',
        fieldName: 'tkCurrentTradeTotalsbyFund',
        content: 'tkCurrentTradeTotalsbyFundHelpGuide',
      },
      {
        index: 18,
        section: 'tkReportingEntitlementsCoreReports',
        fieldName: 'tkFundBalances',
        content: 'tkFundBalancesHelpGuide',
      },
      {
        index: 19,
        section: 'tkReportingEntitlementsCoreReports',
        fieldName: 'tkFundRatesandFactors',
        content: 'tkFundRatesandFactorsHelpGuide',
      },
      {
        index: 20,
        section: 'tkReportingEntitlementsCoreReports',
        fieldName: 'tkGainLoss',
        content: 'tkGainLossNAVMethodsforFNAVMMFHelpGuide',
      },
      {
        index: 21,
        section: 'tkReportingEntitlementsCoreReports',
        fieldName: 'tkHistoricalTransaction',
        content: 'tkHistoricalTransactionHelpGuide',
      },
      {
        index: 22,
        section: 'tkReportingEntitlementsCoreReports',
        fieldName: 'tkMonthlyDividendActivity',
        content: 'tkMonthlyDividendActivityHelpGuide',
      },
      {
        index: 23,
        section: 'tkReportingEntitlementsCoreReports',
        fieldName: 'tkWireSettlement',
        content: 'tkWireSettlementHelpGuide',
      },

      // Reporting Entitlements - Other Reports
      {
        index: 24,
        section: 'tkReportingEntitlementsOtherReports',
        fieldName: 'tkClassFee',
        content: 'tkClassFeesHelpGuide',
      },
      {
        index: 25,
        section: 'tkReportingEntitlementsOtherReports',
        fieldName: 'tkDebitCreditAccountListing',
        content: 'tkDebitCreditAccountListingHelpGuide',
      },
      {
        index: 26,
        section: 'tkReportingEntitlementsOtherReports',
        fieldName: 'tkDebitCreditDailyFeedSummaryActivity',
        content: 'tkDebitCreditDailyFeedSummaryActivityHelpGuide',
      },
      {
        index: 27,
        section: 'tkReportingEntitlementsOtherReports',
        fieldName: 'tkDebitCreditDailyTransactionActivity',
        content: 'tkDebitCreditDailyTransactionActivityHelpGuide',
      },
      {
        index: 28,
        section: 'tkReportingEntitlementsOtherReports',
        fieldName: 'tkDebitCreditSettlementSummary',
        content: 'tkDebitCreditSettlementSummaryHelpGuide',
      },
      {
        index: 29,
        section: 'tkReportingEntitlementsOtherReports',
        fieldName: 'tkExPostCostsChargesSummary',
        content: 'tkExPostCosts&ChargesSummaryHelpGuide',
      },
      {
        index: 30,
        section: 'tkReportingEntitlementsOtherReports',
        fieldName: 'tkHistoricalTradeApprovalException',
        content: 'tkHistoricalTradeApprovalExceptionHelpGuide',
      },
      {
        index: 31,
        section: 'tkReportingEntitlementsOtherReports',
        fieldName: 'tkMonthlyServiceFee',
        content: 'tkMonthlyServiceFeeHelpGuide',
      },
    ],
    accounts: [],
    funds: [
      // Fund Type Filters
      {
        index: 0,
        section: 'tkFundTypeFilters',
        fieldName: 'tkMonthlyServiceFee',
        content: 'tkFundTypeFiltersMonthlyServiceFeeHelpGuide',
      },
      {
        index: 1,
        section: 'tkFundTypeFilters',
        fieldName: 'tkFundFamily',
        content: 'tkFundFamilyHelpGuide',
      },
      {
        index: 2,
        section: 'tkFundTypeFilters',
        fieldName: 'tkDomicile',
        content: 'tkDomicileHelpGuide',
      },
      {
        index: 3,
        section: 'tkFundTypeFilters',
        fieldName: 'tkInvestmentType',
        content: 'tkInvestmentTypeHelpGudie',
      },
      {
        index: 4,
        section: 'tkFundTypeFilters',
        fieldName: 'tkShareClassType',
        content: 'tkShareClassTypeHelpGuide',
      },
    ],
  },
  organizationDetails: {
    profile: [
      // Identifying Information
      { index: 0, section: 'tkIdentifyingInformation', fieldName: 'tkOrganizationName', content: 'tkOrganizationNameHelpGuide' },
      { index: 1, section: 'tkIdentifyingInformation', fieldName: 'tkOrganizationId', content: 'tkOrganizationIdHelpGuide' },
      { index: 2, section: 'tkIdentifyingInformation', fieldName: 'tkCity', content: 'tkCityHelpGuide' },
      { index: 3, section: 'tkIdentifyingInformation', fieldName: 'tkState', content: 'tkStateHelpGuide' },
      { index: 4, section: 'tkIdentifyingInformation', fieldName: 'tkCountry', content: 'tkCountryHelpGuide' },
      { index: 5, section: 'tkIdentifyingInformation', fieldName: 'tkChannel', content: 'tkChannelHelpGuide' },
      { index: 6, section: 'tkIdentifyingInformation', fieldName: 'tkCoverage', content: 'tkCoverageHelpGuide' },

      // Organization Relationships
      { index: 7, section: 'tkOrganizationRelationship', fieldName: 'tkSubsidiaryOf', content: 'tkSubsidiaryOfHelpGuide' },
      { index: 8, section: 'tkOrganizationRelationship', fieldName: 'tkIntermediary', content: 'tkIntermediaryHelpGuide' },

      // Clearing Agents
      { index: 9, section: 'tkClearingAgents', fieldName: 'tkClearingAgentField', content: 'tkClearingAgentHelpGuide' },

      // Setup
      { index: 10, section: 'tkSetup', fieldName: 'tkStatus', content: 'tkStatusHelpGuide' },
      { index: 11, section: 'tkSetup', fieldName: 'tkMakerCheckerApprovals', content: 'tkMakerCheckerHelpGuide' },

      // Fund Rebates
      { index: 12, section: 'tkFundRebates', fieldName: 'tkShareClassRebates', content: 'tkShareClassRebatesHelpGuide' },
      { index: 13, section: 'tkFundRebates', fieldName: 'tkFundFamilyRebates', content: 'tkFundFamilyRebatesHelpGuide' },

      // Change Management Details
      { index: 14, section: 'tkChangeManagementDetails', fieldName: 'tkCreationDate', content: 'tkOrgCreationDateHelpGuide' },
      { index: 15, section: 'tkChangeManagementDetails', fieldName: 'tkLastModifiedDate', content: 'tkOrgLastModifiedHelpGuide' },
    ],
    accounts: [],
    funds: [
      // Fund Type Filters
      {
        index: 0,
        section: 'tkFundTypeFilters',
        fieldName: 'tkMonthlyServiceFee',
        content: 'tkFundTypeFiltersMonthlyServiceFeeHelpGuide',
      },
      {
        index: 1,
        section: 'tkFundTypeFilters',
        fieldName: 'tkFundFamily',
        content: 'tkFundFamilyHelpGuide',
      },
      {
        index: 2,
        section: 'tkFundTypeFilters',
        fieldName: 'tkDomicile',
        content: 'tkDomicileHelpGuide',
      },
      {
        index: 3,
        section: 'tkFundTypeFilters',
        fieldName: 'tkInvestmentType',
        content: 'tkInvestmentTypeHelpGudie',
      },
      {
        index: 4,
        section: 'tkFundTypeFilters',
        fieldName: 'tkShareClassType',
        content: 'tkShareClassTypeHelpGuide',
      },
    ],
    users: [],
  },
};
