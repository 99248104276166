import { StyleSheet } from '@gs-ux-uitoolkit-react/style';
import { MOBILE_BREAKPOINT } from '../style-constant';
import { Theme } from '@gs-ux-uitoolkit-react/theme';

interface KeyboardShortcutStyleSheetProps {
    theme: Theme;
}

export const classNamePrefix = 'gs-uitk-keyboard-shortcuts';
export const keyboardShortcutStylesheet = new StyleSheet(
    'keyboard-shortcuts',
    ({ theme }: KeyboardShortcutStyleSheetProps) => {
        return {
            root: {
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',

                [`.${classNamePrefix}_container`]: {
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    overflow: 'auto',

                    [`[data-cy="${classNamePrefix}_preview-component"]`]: {
                        [`.${classNamePrefix}_preview-component-body`]: {
                            whiteSpace: 'pre-line',
                            color: theme.text.primary,
                        },
                    },
                    [`.${classNamePrefix}_title`]: {
                        marginBottom: '16px',
                        display: 'block',
                    },

                    [`.${classNamePrefix}_body`]: {
                        flex: 1,
                        minWidth: '70%',
                        [`.${classNamePrefix}_select, .gs-input`]: {
                            maxWidth: '83px',
                        },
                    },
                },

                [`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
                    [`.${classNamePrefix}_container`]: {
                        flexDirection: 'column',
                    },
                    [`.${classNamePrefix}_body`]: {
                        width: '100%',
                    },
                },
            },
        };
    }
);
