import appActionTypes from './app/actionTypes';
import preferencesActionTypes from './preferences/actionTypes';
import sagasActionTypes from './sagas/actionTypes';
import userActionTypes from './user/actionTypes';
import pageActionTypes from './page/actionTypes';

export default {
  ...appActionTypes,
  ...preferencesActionTypes,
  ...sagasActionTypes,
  ...userActionTypes,
  ...pageActionTypes
};
