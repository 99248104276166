import { useEffect } from 'react';
import useUserMaintenance from '../../context';
import { SHOW_LOADER } from '../../actions';

const useLoader = loading => {
  const { dispatch: ctxDispatch } = useUserMaintenance();
  useEffect(() => {
    ctxDispatch({ type: SHOW_LOADER, payload: !!loading });
  }, [loading]);
};

export default useLoader;
