export const pageIdSelector = state => state.pageContext && state.pageContext.pageId || '';
export const locationStateSelector = state => state.router.location.state !== '' ? state.router.location.state : null;
export const preferencesSavedSelector = state => state.pageContext.isPreferencesSaved;
export const preferencesSuccessSelector = state => state.pageContext.isPreferencesSuccess;
export const isLogoutFailedSelector = state => state.pageContext.isLogoutFailed;
export const isLoading = state => state.pageContext.isLoading;

const defaultErrorObj = {};

export const checkIsPageInErrorMode = state => state.pageContext.isPageInErrorMode || false;
export const getErrorObject = state => state.pageContext.errorObject || defaultErrorObj;

export default {
  pageIdSelector,
  locationStateSelector
};
