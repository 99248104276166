import { DragAndDropPlaceholderState, DragAndDropSizesCache } from './drag-and-drop-context';
import { DragAndDropStart, DragAndDropUpdate } from './drag-and-drop-external';
import { MutableRefObject } from 'react';

/**
 * Calculates absolute position of the placeholder for given `source` and `destination`.
 */
export const getPlaceholderState = (
    sizeCache: MutableRefObject<DragAndDropSizesCache>,
    initial: DragAndDropStart | DragAndDropUpdate,
    direction: 'horizontal' | 'vertical',
    allowSource?: boolean
): Pick<DragAndDropPlaceholderState, 'top' | 'left' | 'isPlaceholderVisible' | 'droppableId'> => {
    // calculate placeholder index
    const [sourceIndex, destinationIndex] = [
        initial.source?.index,
        (initial as DragAndDropUpdate).destination?.index,
    ];
    const [sourceDroppableId, destinationDroppableId] = [
        initial.source?.droppableId,
        (initial as DragAndDropUpdate).destination?.droppableId,
    ];

    let index = -1;

    if (destinationIndex !== undefined) {
        index = destinationIndex || 0;
    } else if (allowSource) {
        index = sourceIndex;
    }

    const droppableId = destinationDroppableId || sourceDroppableId;

    const isPlaceholderVisible = index !== -1;

    const position = isPlaceholderVisible
        ? sizeCache.current[droppableId]
              // remove element that is pulled out of list
              .filter(
                  (_, index) =>
                      sourceDroppableId !== destinationDroppableId || index !== sourceIndex
              )
              .slice(0, index)
              .reduce(
                  (previousValue, currentValue) => {
                      return {
                          top: direction === 'vertical' ? previousValue.top + currentValue.top : 0,
                          left:
                              direction === 'horizontal'
                                  ? previousValue.left + currentValue.left
                                  : 0,
                      };
                  },
                  { top: 0, left: 0 }
              )
        : { top: 0, left: 0 };

    return {
        ...position,
        isPlaceholderVisible,
        droppableId,
    };
};
