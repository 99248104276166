import { Component, Fragment, ChangeEvent } from 'react';
import { FormGroup } from '@gs-ux-uitoolkit-react/form';
import { Input } from '@gs-ux-uitoolkit-react/input';
import { Label } from '@gs-ux-uitoolkit-react/label';
import { HintList } from '@gs-ux-uitoolkit-common/datacore';
import { FormatControlProps } from '../column-hint-format-manager';

export class ColumnHintControlMultiplier extends Component<FormatControlProps> {
    public render() {
        return (
            <Fragment>
                <FormGroup>
                    <Label>Multiplier</Label>
                    <Input
                        className={'column-hint-control-column-width-pixel-input'}
                        defaultValue={
                            this.props.columnHint.format?.multiplier != null
                                ? String(this.props.columnHint.format.multiplier)
                                : ''
                        }
                        placeholder="e.g 0.1"
                        onChange={this.onChangeMultiplier}
                    />
                </FormGroup>
            </Fragment>
        );
    }

    private onChangeMultiplier = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        if (!isNaN(Number(value))) {
            const valueNumber = parseFloat(value);
            const clonedHint: HintList = {
                ...this.props.columnHint,
                format: { ...this.props.columnHint.format, multiplier: valueNumber },
            };
            if (value === '' && clonedHint.format) {
                delete clonedHint.format.multiplier;
            }
            this.props.onChangeHint(clonedHint);
        }
    };
}
