import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import OptionRenderer from './OptionRenderer';
import useDocumentClick from '../../../utils/customHooks/useDocumentClick';
import './index.scss';

const KEYS = {
  UP: 38,
  DOWN: 40,
  ENTER: 13
};

// eslint-disable-next-line max-statements
const AutoComplete = React.forwardRef((props, ref) => {
  const { UP, DOWN, ENTER } = KEYS;
  const {
    options: lists,
    isLoading,
    placeholderText,
    minChar,
    debounceTime,
    minCharForFreeText,
    defaultSearchTerm,
    searchIconPosition,
    iconSize,
    showClearIcon,
    onBlur,
    onClear,
    onFocus,
    defaultOptions,
    onOutSideClick,
    iconInOptions
  } = props;

  const [searchTerm, updateSearchterm] = useState(defaultSearchTerm);

  const [show, toggleShow] = useState(false);
  const [activeDrop, setActiveDrop] = useState(1);
  const [focusedListItem, setFocusedListItem] = useState(-1);

  const searchRef = useRef();
  const inputRef = useRef();
  const optionsRef = useRef();

  const showList = show && ((searchTerm.length >= minChar && lists.length) || defaultOptions.length);
  const options = !searchTerm.length ? defaultOptions : searchTerm.length >= minChar ? lists : []

  const onClickHandler = event => {
    if (
      searchRef.current.contains(event.target) &&
      optionsRef.current &&
      !optionsRef.current.contains(event.target)
    ) {
      !show && toggleShow(true);
      inputRef.current.focus();
    } else {
      show && toggleShow(false);
    }
    setActiveDrop(1);
    setFocusedListItem(-1);
  };

  const onOptionSelection = option => {
    updateSearchterm(option.label);
    props.onOptionSelection && props.onOptionSelection(option);
    toggleShow(false);
  };

  const fetchData = (term, isFreeText) => {
    if (focusedListItem === -1) {
      props.onInputChange(term, !!isFreeText);
    } else {
      onOptionSelection(options[activeDrop - 1].values[focusedListItem]);
    }
  };

  useDocumentClick(onClickHandler);

  useImperativeHandle(ref, () => ({
    setSearchTerm: (searchTerm) => {
      updateSearchterm(searchTerm);
    }
  }));

  const debounceFn = useCallback(debounce(fetchData, debounceTime), []);

  const onInputChange = event => {
    const { value } = event.target;
    !show && toggleShow(true);
    updateSearchterm(value);
    if (debounceTime) {
      debounceFn(value);
    } else {
      fetchData(value);
    }
  };

  const handleClear = () => {
    updateSearchterm('');
    fetchData('');
    onClear && onClear();
  };

  useEffect(() => {
    updateSearchterm(defaultSearchTerm);
  }, [defaultSearchTerm]);

  const newHandleKeyUpAndDown = e => {

    if (!options.length) return;

    if (e.keyCode === UP) {
      if (activeDrop === 1 && focusedListItem === 0) {
        return;
      }

      if (activeDrop !== 0 && focusedListItem === 0) {
        setFocusedListItem(options[activeDrop - 2].values.length - 1);
        setActiveDrop(state => state - 1);
        return;
      }

      setFocusedListItem(state => {
        const currentFocused = state;
        const focused = currentFocused <= 0 ? 0 : currentFocused - 1;
        return focused;
      });
    }

    if (e.keyCode === DOWN) {
      if (
        activeDrop === options.length &&
        focusedListItem === options[activeDrop - 1].values.length - 1
      ) {
        return;
      }

      if (focusedListItem === options[activeDrop - 1].values.length - 1) {
        setActiveDrop(state => state + 1);
        setFocusedListItem(-1);
      }
      setFocusedListItem(state => {
        const currentFocused = state;
        const focused =
          currentFocused >= options[activeDrop - 1].values.length - 1
            ? options[activeDrop - 1].values.length - 1
            : currentFocused + 1;
        return focused;
      });
    }
  };

  const handleKeyDown = event => {
    switch (event.keyCode) {
      case ENTER:
        if (searchTerm.length >= minCharForFreeText || focusedListItem > -1) {
          fetchData(searchTerm, true);
          toggleShow(false);
        }
        break;
      case UP:
        newHandleKeyUpAndDown(event);
        break;
      case DOWN:
        newHandleKeyUpAndDown(event);
        break;
      default:
        break;
    }
  };

  const handleOnFocus = (e) => {
    !show && toggleShow(true);
    onFocus && onFocus(e);
  };

  const handleOnBlur = (event) => {
    if (!optionsRef.current.contains(event.relatedTarget) ) {
      onOutSideClick(searchTerm);
    }
    onBlur && onBlur(event);
  };

  const dropDownCls = cn({
    autocomplete__options: showList,
    'autocomplete__options hideSearch': !showList
  });
  const borderClass = cn({
    'autocomplete blueBorder': show,
    autocomplete: !show
  });

  return (
    <div
      className={cn("autocomplete-search", {
        "autocomplete-search-md--icon": iconSize === "md",
      })}
      ref={searchRef}
    >
      <div className={borderClass}>
        {searchIconPosition === 'left' && <div className='autocomplete__icon' />}
        <div className='autocomplete__input'>
          <input
            ref={inputRef}
            className='autocomplete__input-field'
            onChange={onInputChange}
            onKeyDown={handleKeyDown}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            value={searchTerm}
            aria-label='search-field'
            placeholder={placeholderText}
            spellCheck={false}
          />
          {isLoading && <div className='autocomplete__loadingIcon' />}
          {showClearIcon && !!searchTerm.length && (
            <button
              aria-label='clear-icon'
              className='autocomplete__clearIcon'
              onClick={handleClear}
            />
          )}
          {searchIconPosition === 'right' && <div className='autocomplete__icon' />}
        </div>
      </div>
      <div
        className={dropDownCls}
        ref={optionsRef}
        data-testid='autocomplete-main-options'
      >
        {options && !!options.length &&
          options.map((config, i) => {
            return (
              <OptionRenderer
                key={i}
                focusedListItem={focusedListItem}
                dropIndex={i + 1}
                activeDrop={activeDrop}
                onSelection={onOptionSelection}
                title={config.title}
                list={config.values}
                searchTerm={searchTerm}
                iconInOptions={iconInOptions}
              />
            );
          })}
      </div>
    </div>
  );
});

AutoComplete.propTypes = {
  options: PropTypes.array,
  defaultOptions: PropTypes.array,
  onFocus: PropTypes.func,
  onInputChange: PropTypes.func,
  onOptionSelection: PropTypes.func,
  isLoading: PropTypes.bool,
  placeholderText: PropTypes.string,
  minChar: PropTypes.number,
  minCharForFreeText: PropTypes.number,
  debounceTime: PropTypes.number,
  defaultSearchTerm: PropTypes.string,
  searchIconPosition: PropTypes.string,
  iconSize: PropTypes.string,
  showClearIcon: PropTypes.bool,
  onBlur: PropTypes.func,
  onClear: PropTypes.func,
  onOutSideClick: PropTypes.func,
  iconInOptions: PropTypes.node
};

AutoComplete.defaultProps = {
  debounceTime: 0,
  placeholderText: 'Search',
  onInputChange: () => { },
  onFocus: () => { },
  onOptionSelection: () => { },
  onOutSideClick: () => { },
  options: [],
  defaultOptions: [],
  minChar: 0,
  minCharForFreeText: 2,
  defaultSearchTerm: '',
  searchIconPosition: 'left',
  iconSize: '',
  showClearIcon: true
};

export default AutoComplete;
