import {
    addConfigItem,
    cancelEditedConfigItem,
    editConfigItem,
    Expression,
    ExpressionCondition,
    ExpressionMode,
    newConfigItem,
    removeConfigItem,
    saveEditedConfigItem,
    updateEditedConfigItem,
} from '@gs-ux-uitoolkit-common/datacore';
import { FontSizes, RowCustomisation } from '../../plugins/row-customisation/row-customisation';
import { RowCustomisationActionKeys } from '../actions/action-constants';
import { RowCustomisationActionTypes } from '../actions/row-customisation-action';
import { RowCustomisationState } from '../datagrid-state';

/**
 * Initial state of the RowCustomisation State
 */
export const defaultRowCustomisationState: RowCustomisationState = {
    configItemList: [],
    editedItem: null,
    originalEditedItem: null,
};

const expression: Expression = {
    mode: ExpressionMode.Basic,
    query: { condition: ExpressionCondition.And, rules: [] },
};

const emptyHighlight: RowCustomisation = {
    expression,
    columnId: null,
    pivotKeys: [],
    style: {
        backColor: '',
        fontStyle: {
            bold: false,
            fontSize: FontSizes.NotOveridden,
            italic: false,
            underline: false,
        },
        foreColor: '',
    },
};

/**
 * Reducer for the CustomSortState
 * @param state State of the CustomSortState
 * @param action Action received
 */
export const rowCustomisationReducer = (
    state: RowCustomisationState = defaultRowCustomisationState,
    action: RowCustomisationActionTypes
): RowCustomisationState => {
    switch (action.type) {
        case RowCustomisationActionKeys.REMOVE_ROW_CUSTOMISATION:
            return removeConfigItem(state, action);
        case RowCustomisationActionKeys.EDIT_ROW_CUSTOMISATION:
            return editConfigItem(state, action);
        case RowCustomisationActionKeys.NEW_ROW_CUSTOMISATION:
            return newConfigItem(state, emptyHighlight);
        case RowCustomisationActionKeys.SAVE_EDITED_ROW_CUSTOMISATION:
            return saveEditedConfigItem(state);
        case RowCustomisationActionKeys.UPDATE_EDITED_ROW_CUSTOMISATION:
            return updateEditedConfigItem(state, action);
        case RowCustomisationActionKeys.CANCEL_EDITED_ROW_CUSTOMISATION:
            return cancelEditedConfigItem(state);
        case RowCustomisationActionKeys.ADD_ROW_CUSTOMISATION:
            return addConfigItem(state, action);
        default:
            return state;
    }
};
