import { useState, useCallback, useMemo, useEffect } from 'react';
import CapabilityRendererWithOnlyIcon from './CapabilityRendererWithOnlyIcon';
import CapabilityRendererWithIconLabel from './CapabilityRendererWithIconLabel';

export function useAgTree(showAccountSidePanel) {
  const [gridRef, setGridRef] = useState();
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: '',
      field: 'capabilityRenderer',
      colId: 'CapabilityRendererWithIconLabel',
      cellRenderer: CapabilityRendererWithIconLabel,
      minWidth: 260,
      flex: 1
    },
  ]);

  useEffect(() => {
    setColumnDefs(showAccountSidePanel ? [{
      headerName: '',
      field: 'capabilityRenderer',
      colId: 'capabilityRenderer',
      cellRenderer: CapabilityRendererWithOnlyIcon,
      minWidth: 80,
      flex: 1
    }] : [
      {
        headerName: '',
        field: 'capabilityRenderer',
        colId: 'CapabilityRendererWithIconLabel',
        cellRenderer: CapabilityRendererWithIconLabel,
        minWidth: 260,
        flex: 1
      }
    ])
  }, [showAccountSidePanel])

  const rowClassRules = useMemo(
    () => ({
      'row-disabled': params => params.data.disabled,
      'different-capabilities-from-parent': params => params.data.orangeNode && params.data.allowBranchLevelTradeApproval,
      'not-configured-for-trade-approval': params =>
        'allowBranchLevelTradeApproval' in params.data ? !params.data.allowBranchLevelTradeApproval : false,
    }),
    []
  );
  const onGridReady = (params) => {
    setGridRef(params);
  }

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 730,
      maxWidth: 730,
      flex: 1,
      suppressSizeToFit: true,
      headerName: '',
      field: 'label',
      colId: 'ag-Grid-AutoColumn',
      cellRenderer: 'agGroupCellRenderer',
      sort: 'asc',
      cellRendererParams: {
        suppressDoubleClickExpand: true,
        suppressEnterExpand: true,
        suppressCount: true,
      },
    };
  }, [showAccountSidePanel]);

  const getDataPath = useCallback(data => data.orgHierarchy, []);

  return {
    columnDefs,
    rowClassRules,
    onGridReady,
    autoGroupColumnDef,
    getDataPath,
    gridRef
  };
}

export const treeConfig = {
  domLayout: 'normal',
  treeData: true,
  suppressColumnVirtualisation: true,
  rowHeight: 38,
  groupDefaultExpanded: 0,
  rowSelection: 'multiple',
  groupSelectsChildren: false,
  suppressRowClickSelection: true,
  suppressContextMenu: true,
  getRowId: (params) => params.data.rowId,
  suppressScrollOnNewData: false,
};
