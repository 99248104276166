import { FC } from 'react';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { connect } from 'react-redux';
import * as Redux from 'redux';
import { Button } from '@gs-ux-uitoolkit-react/button';
import { DataGridState } from '../../redux/datagrid-state';
import { SetGridConfigurationCurrentPluginName } from '../../redux/actions/grid-configuration-action';

/**
 * The props of the PluginTitleNavigationComponent component
 */
export interface PluginTitleNavigationProps {
    /**
     * Text to display if No Component Selected
     */
    label: string | null;
    /**
     * A function that dispatches an action to update the current selected plugin in the config
     */
    onUpdateSelectedPlugin: (pluginName: string | undefined) => void;
    pluginId: string | undefined;
}

/**
 * React component that hosts all the modal screens from the plugin
 * @param props The props of the PluginTitleNavigationComponent
 */
export const PluginTitleNavigationComponent: FC<PluginTitleNavigationProps> = (
    props: PluginTitleNavigationProps
) => {
    const bemCls = `gs-uitk-modals-container`;

    return (
        <span className={`${bemCls}-header-span-plugin-title`}>
            {props.pluginId ? (
                <>
                    <Button
                        emphasis="minimal"
                        onClick={() => props.onUpdateSelectedPlugin(undefined)}
                        size="md"
                    >
                        <Icon name="arrow-back" type="filled" />
                    </Button>
                    {props.label}
                </>
            ) : (
                props.label
            )}
        </span>
    );
};

const mapStateToProps = (state: DataGridState) => {
    return {
        pluginId: state.gridConfiguration.currentPluginName,
    };
};

const mapDispatchToProps = (dispatch: (action: Redux.Action) => void) => {
    return {
        onUpdateSelectedPlugin: (pluginName: string | undefined) =>
            dispatch(SetGridConfigurationCurrentPluginName(pluginName)),
    };
};

export const PluginTitleNavigation = connect(
    mapStateToProps,
    mapDispatchToProps
)(PluginTitleNavigationComponent);
