import { MenuCommonProps, MenuPlacement, MenuSize } from './menu-props';
import { SubmenuContext, defaultSubmenuContext } from './submenu-context';

export class MenuAttributes {
    constructor(
        private props: MenuCommonProps = {},
        private context: SubmenuContext = defaultSubmenuContext
    ) {}

    updateProps(props: MenuCommonProps) {
        this.props = props;
    }

    get autoFocus(): boolean {
        if (this.props.autoFocus !== undefined) {
            return this.props.autoFocus;
        }
        if (this.context.isSubmenu && this.context.autoFocus !== undefined) {
            return this.context.autoFocus;
        }
        return true;
    }

    get visible(): boolean {
        return this.props.visible !== undefined
            ? this.props.visible
            : this.context.isSubmenu
              ? !!this.context.visible
              : false;
    }

    get fixed(): boolean {
        return this.props.fixed !== undefined
            ? this.props.fixed
            : this.context.isSubmenu
              ? !!this.context.fixed
              : false;
    }

    get offset(): [number, number] {
        if (this.props.offset !== undefined) {
            return this.props.offset;
        } else if (this.context.isSubmenu && this.context.offset) {
            return this.context.offset;
        }
        return [0, 0];
    }

    get placement(): MenuPlacement | undefined {
        return this.props.placement !== undefined
            ? this.props.placement
            : this.context.isSubmenu
              ? this.context.placement
              : 'auto';
    }

    get target() {
        return this.props.target !== undefined
            ? this.props.target
            : this.context.isSubmenu
              ? this.context.target
              : undefined;
    }

    get size(): MenuSize {
        return this.props.size !== undefined
            ? this.props.size
            : this.context.isSubmenu && this.context.size
              ? this.context.size
              : 'md';
    }
}
