/* eslint-disable max-statements */
import React, { useMemo, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import cn from 'classnames';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import withGrid from '../../../../../../components/hoc/withGrid';
import Grid from '../../../../../../components/core/Grid';
import useUserMaintenance from '../../../context';
import useOrganizationFunds from '../../../hooks/useOrganizationFunds';
import AutoComplete from '../../../../../../components/core/AutoComplete';
import FundsRemovalWarningModal from '../FundsRemovalWarningModal';
import GridNotification from './GridNotification';
import { MODULES_KEYS } from '../../../../../../constants/pageConstants';
import { orgFundsTabGridHeader } from '../../../selectors';
import { FILTER_FUND_GROUPS, UPDATE_SHARECLASSES_AND_FUND_GROUPS, ORG_FUNDS_GRID_BACKUP } from '../../../actions';
import { getSortedStringData } from '../../../../../../utils/sorting';
import translator from '../../../../../../services/translator';
import './index.scss';

const { translate: t } = translator;

export const selectedRowsComparator = (selectedRows, rowData) => {
  if (!selectedRows.length) return;
  return !!rowData.fundGroupName && rowData.fundGroupName.some(item => selectedRows.includes(item.trim()));
};

function sortAndFormat(fundGroups) {
  return fundGroups
    .map(data => ({ id: data.id, name: data.name.replace(/^./, data.name[0].toUpperCase()) }))
    .sort((a, b) => a.name.localeCompare(b.name));
}

export function getInitialGridData(funds) {
  const uniqueData = {};
  funds?.individualShareclasses.forEach(item => {
    if (!uniqueData[item.id]) {
      uniqueData[item.id] = item;
    }
  });

  funds?.fundGroups.forEach(item => {
    if (item.shareclasses && item.shareclasses.length) {
      item.shareclasses.forEach(shareclass => {
        if (!uniqueData[shareclass.id]) {
          uniqueData[shareclass.id] = { ...shareclass, fundGroupName: [item.name.trim()] };
        } else {
          uniqueData[shareclass.id] = {
            ...uniqueData[shareclass.id],
            ...(shareclass.fundGroupId && { fundGroupId: shareclass.fundGroupId }),
            fundGroupName: uniqueData[shareclass.id].fundGroupName
              ? [...uniqueData[shareclass.id].fundGroupName, item.name.trim()]
              : [item.name.trim()],
          };
        }
      });
    }
  });
  return uniqueData;
}

const OrgDetailsGrid = ({ columnDefs: columns }) => {
  const {
    state: {
      organizationDetails: { organizationName, funds },
      filters = [],
      disablingEdit: isOrgEditable,
      organizationFundGroupsBackup,
      organizationFundsGridBackup,
      isOrgSaved,
    },
    dispatch,
  } = useUserMaintenance();

  const [columnDefs, setColumnDefs] = useState(columns.slice(1));
  const [filterBy, setFilterBy] = useState([]);

  const [gridDataRendered, setGridDataRendered] = useState(false);

  const [gridData, setGridData] = useState(organizationFundsGridBackup || Object.values(getInitialGridData(funds)));

  const [gridRef, setGridRef] = useState(null);

  const [sortState, setSortState] = useState({ colId: 'longName', order: 'asc' });

  const [displayFundsWarningModal, setDisplayFundsWarningModal] = useState(false);
  const [modalFundsList, setModalFundsList] = useState([]);

  const closeModal = () => {
    setDisplayFundsWarningModal(false);
  };

  const autoCompleteRef = useRef();

  const [fundGroups, setFundGroups] = useState(organizationFundGroupsBackup || sortAndFormat(funds.fundGroups));

  const onFundGroupsFetchSuccess = ({ id, name }) => {
    setFundGroups(prevGroups => [...prevGroups, { id, name, isNewlyAdded: true }]);
    gridRef.gridApi.setPinnedTopRowData([{ notificationMsg: t('tkFundAddedToOrganization', name), notificationType: 'success' }]);
    dispatch({ type: UPDATE_SHARECLASSES_AND_FUND_GROUPS, payload: [{ id, operation: 'map', isShareclass: false }] });
  };

  const { autoComplete, autoCompleteLoading, fetchAutoCompleteData, fetchFundGroupFunds, fundGroupsData } =
    useOrganizationFunds(onFundGroupsFetchSuccess);

  useEffect(() => {
    const [, ...restColumns] = columns;
    setColumnDefs(isOrgEditable ? columns : restColumns);
    if (!isOrgEditable && !isOrgSaved) {
      setGridData(organizationFundsGridBackup || Object.values(getInitialGridData(funds)));
      setFundGroups(organizationFundGroupsBackup || sortAndFormat(funds.fundGroups));
    }
    if (isOrgSaved) {
      dispatch({ type: ORG_FUNDS_GRID_BACKUP, payload: { fundGroups, gridData } });
    }
  }, [isOrgEditable, isOrgSaved]);

  const noGridRows = useMemo(() => (!gridData.length ? t('tkNoFundsWithOrganization') : ''), [gridData]);

  const fundGroupsFilterIds = useMemo(() => filters.map(filter => filter.fundGroupId), [filters]);

  const dispatchFirstDataRendered = () => {
    if (filters.length) {
      setTimeout(() => {
        setGridDataRendered(true);
      }, 10);
    }
  };

  const selectedFundRows = useMemo(() => filters?.map(filter => filter.fundGroupName.trim()), [filters, gridData, gridDataRendered]);

  const sortedData = useMemo(() => {
    const selected = [];
    let unSelected = [];
    if (selectedFundRows.length) {
      gridData.forEach(rowData => {
        if (rowData.fundGroupName && rowData.fundGroupName.some(item => selectedFundRows.includes(item.trim()))) {
          selected.push(rowData);
        } else {
          unSelected.push(rowData);
        }
      });
    } else {
      unSelected = gridData;
    }
    const sorttedSelected = selected.length ? getSortedStringData(selected, sortState.colId, sortState.order === 'asc') : [];
    const sortedUnSelected = getSortedStringData(unSelected, sortState.colId, sortState.order === 'asc');
    return [...sorttedSelected, ...sortedUnSelected];
  }, [gridData, sortState, selectedFundRows, fundGroupsData]);

  const dispatchSortChange = (colId, _, order) => {
    setSortState({ colId, order });
    setColumnDefs(
      columnDefs.map(column => {
        if (column.colId === colId) {
          return { ...column, sort: order };
        }
        return column;
      })
    );
  };

  const dispatchColumnWidthChanged = col => {
    setColumnDefs(
      columnDefs.map(column => {
        if (column.colId === col[0].colId) {
          return { ...column, ...col[0], suppressSizeToFit: true };
        }
        return column;
      })
    );
  };

  const dispatchFilterChange = filterModel => {
    const filters = map(filterModel, (obj, key) => ({ field: key, term: obj.filter }));
    setFilterBy(filters);
  };

  const dispatchCellClick = params => {
    if (params.colDef.colId === 'excluded') {
      params.node.setSelected(!params.node.isSelected());
      setDisplayFundsWarningModal(true);
      setModalFundsList([{ ...params.data, deletingFund: true }]);
    }
  };

  const updatedProps = {
    columnDefs,
    data: sortedData,
    gsToolKit: true,
    config: {
      enableServerSideFilter: false,
      enableServerSideSorting: false,
      suppressScrollOnNewData: true,
      sortingOrder: ['desc', 'asc'],
      rowClass: 'selectedRow',
      isFullWidthRow: params => params.rowNode?.rowPinned === 'top',
      fullWidthCellRenderer: GridNotification,
    },
    dispatchFilterChange,
    filterBy,
    selectedRows: selectedFundRows,
    dispatchFirstDataRendered,
    selectedRowsComparator,
    noRowDataSelector: noGridRows,
    dispatchSortChange,
    dispatchColumnWidthChanged,
    dispatchCellClick,
    dispatchGridReady: params => {
      setGridRef(params);
    },
  };

  const handleFundGroupSelectionChange = (fundGroupId, fundGroupName) => {
    const updatedFilters = filters.find(item => item.fundGroupId === fundGroupId)
      ? filters.filter(item => item.fundGroupId !== fundGroupId)
      : [{ fundGroupId, fundGroupName: fundGroupName.trim() }, ...filters];
    dispatch({ type: FILTER_FUND_GROUPS, payload: { filters: updatedFilters } });
  };

  const onInputChange = data => {
    data.length >= 3 && fetchAutoCompleteData({ searchString: data, searchGroups: ['shareclass', 'fund_groups'], searchMaxResults: 5 });
  };

  const updatedOptions = useMemo(() => {
    if (!autoComplete) return [];
    const result = [];
    Object.keys(autoComplete).forEach(key => {
      if (autoComplete[key]?.values?.length) {
        const values = autoComplete[key].values.map(list => {
          return {
            ...list,
            label: list.name || list.longName,
            labels: [{ name: list.name || list.longName, isScGreyText: false }],
          };
        });
        result.push({
          title: t(`tk${key[0].toUpperCase() + key.slice(1)}`).toUpperCase(),
          values: getSortedStringData(values, 'label', true),
        });
      }
    });
    return result;
  }, [autoComplete]);

  const onOptionSelection = data => {
    if ('longName' in data) {
      if (!gridData.find(fund => fund.id === data.id)) {
        setGridData(prevData => [...prevData, data]);
        dispatch({ type: UPDATE_SHARECLASSES_AND_FUND_GROUPS, payload: [{ id: data.id, operation: 'map', isShareclass: true }] });
        gridRef.gridApi.setPinnedTopRowData([{ notificationMsg: t('tkFundAddedToOrganization', data.longName), notificationType: 'success' }]);
      } else {
        gridRef.gridApi.setPinnedTopRowData([
          { notificationMsg: t('tkShareclassOrFundGroupDuplicateErrorMsg', data.longName), notificationType: 'error' },
        ]);
      }
    } else {
      if (!fundGroups.find(fundGroup => fundGroup.id === data.id)) {
        fetchFundGroupFunds(data.id);
      } else {
        gridRef.gridApi.setPinnedTopRowData([
          { notificationMsg: t('tkShareclassOrFundGroupDuplicateErrorMsg', data.name), notificationType: 'error' },
        ]);
      }
    }
    autoCompleteRef.current.setSearchTerm('');
  };

  useEffect(() => {
    !gridData.length &&
      setTimeout(() => {
        gridRef?.gridApi?.showNoRowsOverlay();
      }, 200);
    return () => {
      isOrgEditable && dispatch({ type: ORG_FUNDS_GRID_BACKUP, payload: { fundGroups, gridData } });
    };
  }, [gridRef, gridData]);

  useEffect(() => {
    if (fundGroupsData) {
      const newFunds = [];
      fundGroupsData.shareclasses?.forEach(shareclass => {
        newFunds.push({ ...shareclass, fundGroupName: [fundGroupsData.name.trim()] });
      });
      setGridData(prevData => [...prevData, ...newFunds]);
    }
  }, [fundGroupsData]);

  const removeFundGroup = fundGroupId => {
    setDisplayFundsWarningModal(true);
    setModalFundsList(gridData.filter(data => data.fundGroupId === fundGroupId));
  };

  const updateFunds = (id, deletingFund) => {
    if (deletingFund) {
      const { fundGroupId: fgId, longName, isNewlyAdded } = gridData.find(data => data.id === id);
      setGridData(prevData => prevData.filter(data => data.id !== id));
      gridRef.gridApi.setPinnedTopRowData([{ notificationMsg: t('tkFundRemovedFromOrganization', longName), notificationType: 'error' }]);
      fgId && setFundGroups(fundGroups.filter(fundGroup => fundGroup.id !== fgId));
      if (fundGroupsFilterIds.includes(fgId)) {
        const payload = [];
        gridData.forEach(data => {
          if (data.fundGroupId === fgId) {
            payload.push({ id: data.id, operation: 'map', isShareclass: true });
          }
        });
        dispatch({ type: UPDATE_SHARECLASSES_AND_FUND_GROUPS, payload });
        dispatch({ type: FILTER_FUND_GROUPS, payload: { filters: filters.filter(item => item.fundGroupId !== fgId) } });
        if (!isNewlyAdded) {
          dispatch({ type: UPDATE_SHARECLASSES_AND_FUND_GROUPS, payload: [{ id: fgId, operation: 'unmap', isShareclass: false }] });
        }
      } else {
        dispatch({ type: UPDATE_SHARECLASSES_AND_FUND_GROUPS, payload: [{ id, operation: 'unmap', isShareclass: true }] });
      }
    } else {
      setFundGroups(fundGroups.filter(fundGroup => fundGroup.id !== id));
      const { name } = fundGroups.find(fundGroup => fundGroup.id === id);
      setGridData(prevData => prevData.filter(data => data.fundGroupId !== id));
      gridRef.gridApi.setPinnedTopRowData([{ notificationMsg: t('tkFundRemovedFromOrganization', name), notificationType: 'error' }]);
      dispatch({ type: UPDATE_SHARECLASSES_AND_FUND_GROUPS, payload: [{ id, operation: 'unmap', isShareclass: false }] });
    }
  };

  return (
    <div className={cn('userdetailsfunds__container org_funds', { 'excluded-row-hide': isOrgEditable })}>
      {isOrgEditable ? (
        <div className='fundsAutcomplete'>
          <AutoComplete
            ref={autoCompleteRef}
            placeholderText={t('tkOrgFundsAutocompletePlaceholder')}
            searchIconPosition='left'
            onInputChange={onInputChange}
            debounceTime={150}
            minChar={3}
            isLoading={autoCompleteLoading}
            options={updatedOptions}
            onOptionSelection={onOptionSelection}
            iconInOptions={<Icon name='add-circle-outline' type='filled' style={{ color: '#186ADE', paddingRight: '10px', marginBottom: '2px' }} />}
          />
        </div>
      ) : <div className='fundsAutcomplete' />}
      {fundGroups?.length ? (
        <div className='fundGroupsFilter__container'>
          <div className='fundGroupsFilter__header'>{`${t('tkFundGroups')} :`}</div>
          <div className='fundGroupsFilter__btns'>
            {fundGroups.map(({ id, name: fundGroupName }) => (
              <button
                className={cn({ 'fundGroupsFilter__btns-select': fundGroupsFilterIds.includes(id) })}
                key={id}
                onClick={() => handleFundGroupSelectionChange(id, fundGroupName)}>
                {fundGroupName}
                {isOrgEditable && <span className='fundGroupsFilter__closeIcon' onClick={() => removeFundGroup(id)} />}
              </button>
            ))}
          </div>
        </div>
      ) : <div className='fundGroupsFilter__container' />}
      <div className='orgdetailsfunds__gridHeader'>{`${t('tkFundToVisible')} ${organizationName} (${gridData.length})`}</div>
      {withGrid(Grid)(updatedProps)}
      {displayFundsWarningModal && <FundsRemovalWarningModal closeModal={closeModal} fundsList={modalFundsList} updateFunds={updateFunds} />}
    </div>
  );
};

const mapStateToProps = state => ({
  columnDefs: orgFundsTabGridHeader(state, MODULES_KEYS.USER_SEARCH),
});

OrgDetailsGrid.propTypes = {
  columnDefs: PropTypes.array,
};

export default connect(mapStateToProps)(OrgDetailsGrid);
