import { ReactNode, Component, KeyboardEvent } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { cx } from '@gs-ux-uitoolkit-react/style';
import {
    TabCommonProps,
    TabsCommonProps,
    tabStyleSheet,
    getTabLeadingIconClasses,
    getDropdownToggleClasses,
} from '@gs-ux-uitoolkit-common/tabs';
import { Icon, IconBaseProps } from '@gs-ux-uitoolkit-react/icon-font';
import { Theme, ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';
import { EmotionInstanceContext } from '@gs-ux-uitoolkit-react/style';

export interface DropdownTabProps {
    /**
     * Whether or not the dropdown tab is active.
     */
    active?: boolean;
    /**
     * Whether or not the dropdown tab is disabled.
     */
    disabled?: boolean;
    /**
     * Callback to be fired when `openDropdownsOnHover` is true and user hovers over dropdown tab (therefore opening dropdown submenu).
     */
    onMouseEnterAddBorder?: () => void;
    /**
     * Callback to be fired when `openDropdownsOnHover` is true and user stops hovering over dropdown tab (therefore closing dropdown submenu).
     */
    onMouseExitRemoveBorder?: () => void;
    /**
     * Event fired when dropdown tab is selected, accepting the `tabKey` of the dropdown tab as the parameter
     */
    onSelect?: (tabKey: number | string) => void;
    /**
     * Callback fired when dropdown tab is clicked
     */
    onClick?: () => void;
    /**
     * Whether or not to open the dropdown when hovering (rather than only on click)
     */
    openDropdownsOnHover?: boolean;
    /**
     * Key of the dropdown tab, can be used to programmatically select/manage dropdown tab
     */
    tabKey?: string | number;
    /**
     * Title of the dropdown tab
     */
    title: string;
    /**
     * Leading icon of the dropdown tab
     */
    leadingIcon?: TabCommonProps['leadingIcon'];
    /**
     * Additional classes to apply to the dropdown tab
     */
    classes?: TabCommonProps['classes'];
    /**
     * Whether or not the tab is in icon-only mode (no text title to display)
     */
    iconOnly?: TabsCommonProps['iconOnly'];
    /**
     * Size of the tab, should be specified on the parent Tabs component.
     */
    size?: TabsCommonProps['size'];
    /**
     * Whether or not the tab is in vertical orientation. Controls the display of the toggle.
     */
    vertical?: boolean;
    /**
     * Whether or not the expand icon should be displayed. It is strongly recommended to display the expand icon if there are sub-menus to indicate that more items are available.
     */
    showExpandIcon?: boolean;
    children?: ReactNode;
    onKeyDown?: (props: { event: KeyboardEvent; tabKey: string | number | undefined }) => void;
}

export interface DropdownTabState {
    dropdownOpen: boolean;
}

// this dropdown tab is for visible tab with submenu item(s)
// this is slightly different than the overflow dropdown tab, DropdownTabTitle,
// it's deceiptively similar in name and functionally so the components should  probably be merged (later)
// TODO: deprecate this functionality in Toolkit v10
export class DropdownTab extends Component<DropdownTabProps, DropdownTabState> {
    public static propTypes: { [key in keyof DropdownTabProps]: any } = {
        active: PropTypes.bool,
        children: PropTypes.node.isRequired,
        disabled: PropTypes.bool,
        onMouseExitRemoveBorder: PropTypes.func,
        onSelect: PropTypes.func,
        openDropdownsOnHover: PropTypes.bool,
        tabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string.isRequired,
        leadingIcon: PropTypes.object,
        iconOnly: PropTypes.bool,
        size: PropTypes.oneOf(['md', 'lg']),
        vertical: PropTypes.bool,
        onMouseEnterAddBorder: PropTypes.func,
        onClick: PropTypes.func,
        onKeyDown: PropTypes.func,
    };

    constructor(props: DropdownTabProps) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.openDropdown = this.openDropdown.bind(this);
        this.closeDropdown = this.closeDropdown.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseExit = this.handleMouseExit.bind(this);
        this.handleOnClickParent = this.handleOnClickParent.bind(this);
        this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
        this.state = {
            dropdownOpen: false,
        };
    }

    _handleClick(tabKey: string | number, onClick: () => void) {
        // handles onClick of each submenu
        const { onSelect } = this.props;
        if (typeof onClick === 'function') {
            onClick();
        }
        if (tabKey && onSelect) {
            onSelect(tabKey);
        }
        this.closeDropdown();
    }

    handleOnClickParent() {
        // handles onClick of parent with submenu
        const { onClick } = this.props;
        if (typeof onClick === 'function') {
            this._handleClick('', onClick);
        }
    }

    toggle() {
        if (!this.props.disabled) {
            this.setState({ dropdownOpen: !this.state.dropdownOpen });
        }
    }

    openDropdown() {
        if (!this.props.disabled) {
            this.setState({
                dropdownOpen: true,
            });
        }
    }

    closeDropdown() {
        this.setState({
            dropdownOpen: false,
        });
    }

    handleMouseEnter() {
        if (this.props.openDropdownsOnHover) {
            this.openDropdown();
            if (this.props.vertical && this.props.onMouseEnterAddBorder) {
                this.props.onMouseEnterAddBorder();
            }
        }
    }

    handleMouseExit() {
        if (this.props.openDropdownsOnHover) {
            this.closeDropdown();
            if (this.props.vertical && this.props.onMouseExitRemoveBorder) {
                this.props.onMouseExitRemoveBorder();
            }
        }
    }

    handleOnKeyDown(e: React.KeyboardEvent) {
        if (this.props.onKeyDown) this.props.onKeyDown({ event: e, tabKey: this.props.tabKey });
    }

    render() {
        const {
            active,
            children,
            disabled,
            title,
            iconOnly,
            size,
            leadingIcon,
            classes: overrideClasses,
            showExpandIcon = false,
            vertical,
        } = this.props;

        return (
            <EmotionInstanceContext.Consumer>
                {emotionInstance => (
                    <ThemeConsumer>
                        {(theme: Theme) => {
                            const cssClasses = tabStyleSheet.mount(
                                this,
                                {
                                    theme,
                                    size: size || 'md',
                                    showExpandIcon,
                                    iconOnly,
                                    isDropdownOpen: this.state.dropdownOpen,
                                    orientation: vertical ? 'vertical' : 'horizontal',
                                },
                                emotionInstance
                            );

                            const leadingIconClasses = getTabLeadingIconClasses({
                                cssClasses,
                                overrideClasses,
                            });

                            const tabTitleContent = (
                                <>
                                    {leadingIcon != null && (
                                        <Icon
                                            {...(leadingIcon as IconBaseProps)}
                                            className={leadingIconClasses}
                                            data-cy="tab__leading-icon"
                                        />
                                    )}
                                    {iconOnly && leadingIcon ? '' : title}
                                </>
                            );

                            return (
                                <Dropdown
                                    isOpen={this.state.dropdownOpen}
                                    onMouseEnter={this.handleMouseEnter}
                                    onMouseLeave={this.handleMouseExit}
                                    toggle={this.toggle}
                                    tag="li"
                                    className="nav-item dropdown"
                                    data-gs-uitk-component="tab"
                                >
                                    <DropdownToggle
                                        nav
                                        onClick={this.handleOnClickParent} // this onclick passes from parent
                                        tabIndex={active ? 0 : -1}
                                        onKeyDown={this.handleOnKeyDown}
                                        className={cx(
                                            getDropdownToggleClasses({
                                                cssClasses,
                                                overrideClasses,
                                            }),
                                            {
                                                disabled,
                                                active,
                                            }
                                        )}
                                        aria-disabled={disabled ? 'true' : 'false'}
                                        data-tab-key={this.props.tabKey}
                                    >
                                        {tabTitleContent}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {children && Array.isArray(children)
                                            ? children.map(({ props }: any) => (
                                                  <DropdownItem
                                                      key={props.title}
                                                      onClick={this._handleClick.bind(
                                                          // eslint-disable-line react/jsx-no-bind
                                                          this,
                                                          props.tabKey,
                                                          props.onClick
                                                      )}
                                                      disabled={props.disabled}
                                                      aria-disabled={
                                                          props.disabled ? 'true' : 'false'
                                                      }
                                                  >
                                                      <a
                                                          href={props.href}
                                                          onClick={e => e.preventDefault()}
                                                      >
                                                          {props.title}
                                                      </a>
                                                  </DropdownItem>
                                              ))
                                            : null}
                                    </DropdownMenu>
                                </Dropdown>
                            );
                        }}
                    </ThemeConsumer>
                )}
            </EmotionInstanceContext.Consumer>
        );
    }

    componentWillUnmount() {
        tabStyleSheet.unmount(this);
    }
}
