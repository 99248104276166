import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@gs-ux-uitoolkit-react/switch';
import translator from '../../../../../services/translator';

const { translate: t } = translator;

const SelectAll = ({ handleSelectAllChange, selected }) => {
  const [allToggle, setAllToggle] = useState(selected);

  useEffect(() => {
    setAllToggle(selected);
  }, [selected]);

  const onSelectAllChange = e => {
    setAllToggle(e.target.checked);
    handleSelectAllChange && handleSelectAllChange(e.target.checked);
  };

  return (
    <div className='select-all'>
      <label className='select-all__label' htmlFor='select-all'>
        {t('tkSelectAll')}
      </label>
      <Switch inline toggledOn={allToggle} onChange={onSelectAllChange} className='edit-switch' size='md' />
    </div>
  );
};

SelectAll.propTypes = {
  handleSelectAllChange: PropTypes.func,
  selected: PropTypes.bool,
};

const propsAreEqual = (prevProps, nextProps) => prevProps.selected === nextProps.selected;

export default memo(SelectAll, propsAreEqual);
