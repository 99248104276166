import { ColorPickerCssClasses } from './color-picker-style-sheet';

export interface ColorPickerColor {
    hex: string;
    label: string;
}

export interface ColorRamp {
    id: string;
    colors: ColorPickerColor[];
}

/**
 * Determines how the selector is dislpayed
 */
export enum ColorPickerSelectorMode {
    Chip = 'Chip',
    FormChip = 'FormChip',
    Icon = 'Icon',
}

/**
 * Determines the size of the selector
 */
export enum ColorPickerSelectorSize {
    Sm = 'sm',
    Md = 'md',
    Lg = 'lg',
}

export interface ColorPickerState {
    /**
     * Current color value
     */
    selectedColor: ColorPickerColor;
    /**
     * Is the reactstrap popover currently visible
     */
    popoverOpen: boolean;
    /**
     * Color the user is currently hovering over
     */
    hoveredColor: ColorPickerColor | null;
    /**
     * Whether the popup contents are scrolled to the top
     */
    scrolledToTop: boolean;
}

export interface ColorPickerProps {
    /**
     * Event fired on tile select
     */
    onSelect?: (hex: string) => void;
    /**
     * Populate selected color hex value e.g. #001100
     */
    selectedColor?: string;
    /**
     * Optional classname to add to the container
     */
    className?: string;
    /**
     * Style classes to override.
     */
    classes?: Partial<ColorPickerCssClasses>;
    /**
     * Size mode - defaults to md
     */
    size?: ColorPickerSelectorSize;
    /**
     * How the selector is displayed - defaults to inline-chip
     */
    mode?: ColorPickerSelectorMode;
}

export type ColorPickerDefaultProps = Required<Pick<ColorPickerProps, 'size'>>;

export const colorPickerDefaultProps: ColorPickerDefaultProps = {
    size: ColorPickerSelectorSize.Md,
};
