import { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import aggFuncs from './aggFuncs';
import { colDef } from './columnConfig';
import { ROW_HEIGHT } from '../../../../components/core/Grid/config/defaultConfig';
import Grid from '../../../../components/core/Grid';
import { getExcelExportConfigs } from '../../../../modules/Grid/helper';
import {
  getGridData,
  gridHeader,
  filterVisibility,
  currentViewSelector,
  columnsMetadata,
  preDefinedSortingState,
  noRowDataSelector,
  selectedGroup,
  viewName,
  getFilters,
  selectCollapseState,
  columnSortingStateMappedData,
  groupByFieldSelector,
  startExcelExport,
  getFilterModel,
  filterByMappedData,
  getLastEvent,
  allViewsSelector,
  columnsMetadataSelector,
  currentReportIdSelector,
  isReportGridDataLoadingSelector,
  isWireOrDebitCreditSettlementSelector
} from '../../../../selectors/pages/reports/individualReport';
import mapDispatchToProps from './actionsHandler';
import withGrid from '../../../../components/hoc/withGrid';
import { userSelector } from '../../../../selectors/user';
import { reportDisclaimerSelector, labelSelector, reportsSelectedFirms } from '../../../../selectors/app';
import { STANDARD_REPORT_VIEW_NAMES, getUpdatedColumns, onEditView } from '../../utils';
import { GROUP_TYPE } from '../../../../constants/preferences';
import { getNewOrderedColumns, getNewVisibleColumns, getNewWidthedColumns } from '../../../../helpers/gridFund';
import { accountIdentifierPreference, fundIdentifierPreference } from '../../../../selectors/preferences';
import { REPORT_PAGE_IDS } from '../../../../constants/appConstants';

const checkRowGroup = (data, currentReportId) => {
  if (currentReportId === REPORT_PAGE_IDS.WS) {
    return data.some(item =>
      [GROUP_TYPE.SETTLEMENT_GROUP, GROUP_TYPE.SETTLEMENT_LOCATION].includes(item.colId)
    );
  }
  return true;
};

export function getSheetName(label) {
  if (label?.length <= 31) return label;
  switch (label) {
    case STANDARD_REPORT_VIEW_NAMES["trade-approval-exceptions"]:
      return "HTAE";
    case STANDARD_REPORT_VIEW_NAMES["debit-credit-transactions"]:
      return "DCTA";
    default:
      return label;
  }
}

const IndividualReportGrid = props => {
  /* eslint-disable-next-line no-unused-vars */
  const { addEditDeleteViewData, allViews, currentReportId, currentView, groupByField = '', isWireOrDebitCreditSettlement } = props;
  const config = {
    aggFuncs,
    enableHandleGrouping: true,
    groupDefaultExpanded: props.collapseState === true ? 0 : -1,
    enableServerSideSorting: true,
    suppressScrollOnNewData: true,
    groupSuppressAutoColumn: true,
    context: {
      // custom prop
      suppressGroupCheckbox: true
    },
    enableServerSideFilter: false,
    sortingOrder: ['desc', 'asc'],
    noRowsOverlayComponentParams: {
      noRowMsg: props.isInvalidFilter ? props.labels.tkCopyPort17 : props.noRowDataSelector
    },
    floatingFiltersHeight: 0,
    ...props.gridConfig
  };

  if (isWireOrDebitCreditSettlement) {
    /** dynamically calculates height for wire settlement and debit credit settlement reports */
    config.getRowHeight = params => {
      const { node, columnApi } = params;
      let rowHeight = Number(ROW_HEIGHT);
      const leafNodeRows = node.allLeafChildren;
      const data = !isEmpty(leafNodeRows) ? leafNodeRows[0].data : null;
      if (
        node &&
        data &&
        node.leafGroup &&
        checkRowGroup(columnApi.getRowGroupColumns(), currentReportId)
      ) {
        const { wireInstruction = '', dcssInstruction = '' } = data;
        const instruction =
          currentReportId === REPORT_PAGE_IDS.WS ? wireInstruction : dcssInstruction;
        const splits = instruction
          .trim()
          .split('<br/>')
          .filter(r => r);
        if (splits.length > 3) {
          rowHeight *= splits.length / 1.5;
        } else {
          rowHeight += 8;
        }
      }
      return rowHeight;
    };
    config.size = 'sm';
  }

  const excelExportConfig = () => {
    const {
      columnDefs,
      currentView,
      disclaimer,
      filterModel,
      labels,
      selectedFirms,
      userInformation
    } = props;

    currentView.label = currentView.name;

    return getExcelExportConfigs({
      columnDefs,
      currentView,
      disclaimer,
      userInformation,
      selectedFirms,
      labels,
      filterModel,
      // all indiv. reports show selected firms except fund rates & factors
      hideSelectedFirm: currentView.name === STANDARD_REPORT_VIEW_NAMES['fund-rates-factors'],
      sheetName: getSheetName(currentView.label)
    });
  };

  const [startTime, setStartTime] = useState(null);
  const onDispatchLoadStart = () => setStartTime(new Date().getTime());
  const onDispatchLoadEnd = () => {
    const { lastEvent, data, columnDefs, currentView, filterBy } = props;
    lastEvent && props.dispatchGridLoadEnd(lastEvent, data.length, columnDefs, currentView.name, filterBy, startTime);
  };

  const dispatchColumnWidthChanged = columns => {
    const updatedColumns = getUpdatedColumns(columns);
    const updatedView = getNewWidthedColumns(updatedColumns, currentView);
    onEditView(updatedView, allViews, addEditDeleteViewData);
  };

  const dispatchColumnMoved = columns => {
    const activeView = getNewOrderedColumns(columns, currentView, true);
    onEditView(activeView, allViews, addEditDeleteViewData);
  };

  const dispatchColumnRemove = colId => {
    const [updatedColId] = colId.split('_');
    const updatedView = getNewVisibleColumns(updatedColId, currentView);
    onEditView(updatedView, allViews, addEditDeleteViewData);
  };

  const updatedProps = {
    colDef,
    config,
    excelExportConfig: props.excelExportConfig || excelExportConfig,
    dispatchLoadEnd: onDispatchLoadEnd,
    dispatchLoadStart: onDispatchLoadStart,
    dispatchColumnWidthChanged,
    dispatchColumnMoved,
    dispatchColumnRemove,
    ...props,
    clientGroupBy: true
  };
  return withGrid(Grid)(updatedProps);
};

// keeps props sorted in A-Z order
const mapStateToProps = state => ({
  allViews: allViewsSelector(state),
  collapseState: selectCollapseState(state),
  columnDefs: gridHeader(state),
  columnSortingStateMappedData: columnSortingStateMappedData(state),
  columnsMetadata: columnsMetadata(state),
  columnsMetadataWithKey: columnsMetadataSelector(state),
  currentReportId: currentReportIdSelector(state),
  currentView: currentViewSelector(state),
  data: getGridData(state),
  disclaimer: reportDisclaimerSelector(state),
  enableAddTradeToBlotter: true,
  filterBy: getFilters(state),
  filterByMappedData: filterByMappedData(state),
  filterModel: getFilterModel(state),
  filterVisibility: filterVisibility(state),
  groupByField: groupByFieldSelector(state),
  isWireOrDebitCreditSettlement: isWireOrDebitCreditSettlementSelector(state),
  labels: labelSelector(state),
  lastEvent: getLastEvent(state),
  loading: isReportGridDataLoadingSelector(state),
  noRowDataSelector: noRowDataSelector(state),
  preDefinedSortingState: preDefinedSortingState(state),
  preferredAccountIdentifier: accountIdentifierPreference(state),
  preferredFundIdentifier: fundIdentifierPreference(state),
  selectedFirms: reportsSelectedFirms(state),
  selectedGroup: selectedGroup(state),
  startExcelExport: startExcelExport(state),
  userInformation: userSelector(state),
  viewName: viewName(state)
});

IndividualReportGrid.propTypes = {
  addEditDeleteViewData: PropTypes.func,
  allViews: PropTypes.array,
  collapseState: PropTypes.bool,
  columnDefs: PropTypes.array,
  currentReportId: PropTypes.string,
  currentView: PropTypes.object,
  data: PropTypes.array,
  disclaimer: PropTypes.array,
  dispatchGridLoadEnd: PropTypes.func,
  excelExportConfig: PropTypes.func,
  filterBy: PropTypes.array,
  filterModel: PropTypes.object,
  gridConfig: PropTypes.object,
  groupByField: PropTypes.string,
  isInvalidFilter: PropTypes.bool,
  labels: PropTypes.object,
  lastEvent: PropTypes.object,
  noRowDataSelector: PropTypes.string,
  selectedFirms: PropTypes.array,
  userInformation: PropTypes.object,
  isWireOrDebitCreditSettlement: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(IndividualReportGrid);
