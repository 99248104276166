import { ReactElement, Component, MouseEvent } from 'react';
import PropTypes from 'prop-types';
import { NavItem, NavLink } from 'reactstrap';
import { IconBaseProps } from '@gs-ux-uitoolkit-common/icon-font';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';

import {
    TabCommonProps,
    tabStyleSheet,
    getTabLeadingIconClasses,
    TabsCommonProps,
    TabsSize,
} from '@gs-ux-uitoolkit-common/tabs';
import { extractAriaAttributes } from '@gs-ux-uitoolkit-react/shared';
import { Theme, ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';
import { EmotionInstanceContext } from '@gs-ux-uitoolkit-react/style';

export interface NormalTabTitleProps {
    active: boolean;
    disabled?: boolean;
    onClick?: () => void;
    onSelect: (tabKey: string | number) => void;
    tabKey: string | number;
    title?: ReactElement;
    leadingIcon?: TabCommonProps['leadingIcon'];
    classes?: TabCommonProps['classes'];
    iconOnly?: TabsCommonProps['iconOnly'];
    size?: TabsSize;
    href?: string;
    variant: 'tabs' | 'pills';
    orientation: 'vertical' | 'horizontal';
    onKeyDown: (props: { event: React.KeyboardEvent; tabKey: string | number }) => void;
}

export class NormalTabTitle extends Component<NormalTabTitleProps> {
    public static propTypes: { [key in keyof NormalTabTitleProps]: any } = {
        active: PropTypes.bool.isRequired,
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
        onSelect: PropTypes.func.isRequired,
        tabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        title: PropTypes.any,
        size: PropTypes.oneOf(['md', 'lg']),
        leadingIcon: PropTypes.object,
        iconOnly: PropTypes.bool,
        variant: PropTypes.string,
        orientation: PropTypes.string,
        onKeyDown: PropTypes.func,
    };

    constructor(props: NormalTabTitleProps) {
        super(props);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick(e: MouseEvent) {
        e.preventDefault();
        const { onSelect, tabKey, onClick } = this.props;
        if (typeof onClick === 'function') {
            onClick();
        }
        onSelect(tabKey);
    }

    render() {
        const {
            active,
            disabled,
            title,
            href,
            iconOnly,
            size,
            orientation,
            leadingIcon,
            classes: overrideClasses,
            onSelect,
            tabKey,
            onKeyDown,
            ...rest
        } = this.props;

        return (
            <EmotionInstanceContext.Consumer>
                {emotionInstance => (
                    <ThemeConsumer>
                        {(theme: Theme) => {
                            const ariaAttributes = {
                                ...extractAriaAttributes(rest),
                                'aria-disabled': disabled,
                            };

                            const cssClasses = tabStyleSheet.mount(
                                this,
                                {
                                    theme,
                                    size: size || 'md',
                                    showExpandIcon: false,
                                    iconOnly,
                                    orientation: 'horizontal',
                                },
                                emotionInstance
                            );

                            const leadingIconClasses = getTabLeadingIconClasses({
                                cssClasses,
                                overrideClasses,
                            });

                            const tabTitleContent = (
                                <>
                                    {leadingIcon != null && (
                                        <Icon
                                            {...(leadingIcon as IconBaseProps)}
                                            className={leadingIconClasses}
                                            data-cy="tab__leading-icon"
                                        />
                                    )}
                                    {iconOnly && leadingIcon ? '' : title}
                                </>
                            );

                            return (
                                <NavItem data-gs-uitk-component="tab">
                                    <NavLink
                                        className={active ? 'active' : ''}
                                        disabled={disabled}
                                        onKeyDown={event => onKeyDown({ event, tabKey })}
                                        data-tab-key={tabKey}
                                        tabIndex={active ? 0 : -1}
                                        href={href || '#'}
                                        onClick={this._handleClick}
                                        {...ariaAttributes}
                                        role="button"
                                    >
                                        {tabTitleContent}
                                    </NavLink>
                                </NavItem>
                            );
                        }}
                    </ThemeConsumer>
                )}
            </EmotionInstanceContext.Consumer>
        );
    }

    componentWillUnmount() {
        tabStyleSheet.unmount(this);
    }
}
