import React, { useState, useEffect, useCallback } from 'react';
import { Tooltip } from '@gs-ux-uitoolkit-react/tooltip';
import PropTypes from 'prop-types';
import { Button } from '@gs-ux-uitoolkit-react/button';
import { isEqual, cloneDeep } from 'lodash';
import CapabilitiesRow from './CapabilitiesRow';
import { defaultCapabilities } from '../../../utils/accountsTree';
import translator from '../../../../../../services/translator';

const { translate: t } = translator;

const SidePanel = ({ selectedNodes = [], onCloseSidePanel, overrideContainerDisplay, onCapabilitiesChange }) => {
  const [capabilitiesObj, setCapabilitiesObj] = useState(cloneDeep(defaultCapabilities));
  const [override, setOverride] = useState(false);
  const capabilitiesRows = ['View', 'Trade', 'Approve'];
  const [allowTradeApproval, setAllowTradeApproval] = useState(false);
  const [oldCapabilities, setOldCapabilities] = useState(cloneDeep(defaultCapabilities));

  const onCapChanges = data => {
    setCapabilitiesObj(data);
    onCapabilitiesChange(data, override);
  };

  const shouldDisableCapabiities = useCallback(node => 'allowBranchLevelTradeApproval' in node && !node.allowBranchLevelTradeApproval, []);

  useEffect(() => {
    if (!selectedNodes.length) return;
    if (selectedNodes.length === 1) {
      setCapabilitiesObj(selectedNodes[0].data.capabilities);
      setOldCapabilities(cloneDeep(selectedNodes[0].data.capabilities));
    } else {
      setCapabilitiesObj(defaultCapabilities);
      setOldCapabilities(cloneDeep(defaultCapabilities));
    }

    const disable = selectedNodes.some(node => {
      if (node.level === 0) {
        return node.allLeafChildren.some(branch => shouldDisableCapabiities(branch.data));
      }
      return shouldDisableCapabiities(node.data);
    });

    setAllowTradeApproval(disable);
  }, [selectedNodes]);

  const handleOverride = overrideValue => {
    setOverride(overrideValue);
    onCapabilitiesChange(capabilitiesObj, overrideValue);
  };

  const onClose = () => {
    onCloseSidePanel(false, capabilitiesObj);
  };

  const onApplyAccountsChanges = () => {
    const isCapabilitiesUpdated = override || !isEqual(oldCapabilities, capabilitiesObj);
    onCloseSidePanel(isCapabilitiesUpdated, capabilitiesObj, !!override);
  };

  return (
    <div className='side-panel'>
      <div className='side-panel__header'>
        <div className='side-panel__headerText'>{t('tkAccountsSidePanelHeaderText')}</div>
        <span onClick={onClose} className='side-panel__closeIcon' />
      </div>
      <div className='side-panel__selectedNode'>
        {selectedNodes.length === 1 ? selectedNodes[0].data.label : `${selectedNodes.length} ${t('tkItemsSelected')}`}
      </div>
      <div className='side-panel__subtitle'>{t('tkAccountsSidePanelSubtitle')}</div>
      <div className='side-panel__capabilitiesRow'>
        {capabilitiesRows.map((item, idx) => (
          <CapabilitiesRow
            key={idx}
            label={item}
            capabilities={capabilitiesObj}
            capabilitiesUpdater={onCapChanges}
            allowTradeApproval={allowTradeApproval}
            selectedNodes={selectedNodes}
          />
        ))}
      </div>
      {allowTradeApproval && (
        <div className='side-panel__branchEntitlements'>
          {t('tkAccountsBranchEntitlementMsg')}
          <span id='branch-entitlement-tooltip' className='side-panel__branchEntitlementsTooltip' />
          <Tooltip target='branch-entitlement-tooltip' size='sm' fade>
            {t('tkAccountsBranchEntitlementTooltip')}
          </Tooltip>
        </div>
      )}
      {overrideContainerDisplay && (
        <div className='side-panel__overrideEntitlements'>
          <div className='side-panel__overrideEntitlementsText'>
            {t('tkOverrideExistingEntitlements')}
            <span id='override-tooltip' className='side-panel__overrideEntitlementsTooltip' />
            <Tooltip target='override-tooltip' size='sm' fade>
              {t('tkAccountsOverrideTooltip')}
            </Tooltip>
          </div>
          <div className='side-panel__overrideEntitlementsRadioBtns'>
            <label>
              <input type='radio' name='override' defaultChecked onChange={() => handleOverride(false)} />
              {t('tkNoFirstLetterCapital')}
            </label>
            <label>
              <input type='radio' name='override' onChange={() => handleOverride(true)} />
              {t('tkYesFirstLetterCapital')}
            </label>
          </div>
        </div>
      )}
      <div className='side-panel__buttonContainer'>
        <Button actionType='primary' onClick={onApplyAccountsChanges}>
          {t('tkAccountsSidePanelPrimaryCTA').toUpperCase()}
        </Button>
        <Button onClick={onClose} className='side-panel__cancelBtn' actionType='primary' emphasis='minimal'>
          {t('tkAccountsSidePanelSecondaryCTA')}
        </Button>
      </div>
    </div>
  );
};

SidePanel.propTypes = {
  selectedNodes: PropTypes.array,
  overrideContainerDisplay: PropTypes.bool,
  onCloseSidePanel: PropTypes.func,
  onCapabilitiesChange: PropTypes.func,
};

export default SidePanel;
