import { typographyFontFamilyVariants } from '@gs-ux-uitoolkit-common/design-system';
import {
    StyleSheet,
    CssProperties,
    CssClassDefinitionsObject,
} from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { LabelEmphasis, LabelSize } from './label-props';
import { DeepReadonly } from 'ts-essentials';
import './label-theme-overrides';

export interface LabelStyleSheetProps {
    theme: Theme;
    size: LabelSize;
    emphasis: LabelEmphasis;
    inline: boolean;
    disabled: boolean;
}

export interface LabelCssClasses {
    root: string;
    label: string;
}

export type LabelStyledClasses = CssClassDefinitionsObject<keyof LabelCssClasses>;

export interface LabelStyleOverridesParams {
    props: DeepReadonly<LabelStyleSheetProps>;
    createDefaultStyledClasses: () => LabelStyledClasses;
}

export const labelStyleSheet = new StyleSheet('label', (props: LabelStyleSheetProps) => {
    return createComponentClassDefinitions<LabelStyleSheetProps, LabelStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.label
    );
});

function getLabelSizeMap(theme: Theme): { [size in LabelSize]: CssProperties } {
    return {
        sm: {
            ...theme.typography.body03,
            paddingBottom: 0,
            paddingTop: 0,
        },
        md: {
            ...theme.typography.body02,
        },
        lg: {
            ...theme.typography.body01,
        },
    };
}

function createDefaultStyledClasses({
    theme,
    emphasis,
    size,
    inline,
    disabled,
}: LabelStyleSheetProps): LabelStyledClasses {
    return {
        root: {},
        label: {
            display: inline ? 'inline' : 'block',
            position: 'relative',
            color: theme.text.secondary,
            marginBottom: '4px',
            ...(getLabelSizeMap(theme)[size] as CssProperties),
            ...applyFontWeight(emphasis),
            opacity: disabled ? theme.state.disabledOpacity : 1,
        },
    };
}

function applyFontWeight(emphasis: LabelEmphasis) {
    return emphasis === 'bold'
        ? typographyFontFamilyVariants['goldmanSans'].bold // changing it to goldmanSans from goldmanSansRegular, master currently doesn't have the deprecation code Ref: https://gitlab.aws.site.gs.com/fp/mwp-ui/gs-ux-uitoolkit/-/merge_requests/2018/diffs
        : typographyFontFamilyVariants['goldmanSans'].regular;
}
