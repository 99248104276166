import actionTypes from './actionTypes';

export const loadFundTrackerCompareData = data => (
  {
    type: actionTypes.LOAD_FUND_TRACKER_COMPARE_DATA,
    data
  }
);

export const fetchFundTrackerThresholds = data => (
  {
    type: actionTypes.FETCH_FUND_TRACKER_THRESHOLDS,
    data
  }
);

export const saveFundTrackerThresholds = data => (
  {
    type: actionTypes.SAVE_FUND_TRACKER_THRESHOLDS,
    data
  }
);

export const deleteFundTrackerThresholds = data => (
  {
    type: actionTypes.DELETE_FUND_TRACKER_THRESHOLDS,
    data
  }
);

export const isPrintingInProgress = data => (
  {
    type: actionTypes.IS_PRINTING_IN_PROGRESS,
    data
  }
);

export const thresholdsDeleted = data => (
  {
    type: actionTypes.THRESHOLDS_DELETED,
    data
  }
);
