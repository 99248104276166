const RIGHT = 'right';
const LEFT = 'left';
const TOP = 'top';
const BOTTOM = 'bottom';

/*
 * WARNING: offsets are calculated based on the current rotation provided of -45 degrees on the arrow.
 * TODO: create a rotation-agnostic offset
 */

const calcOverlayPlacement = (elementRef, offsetAmount, placement) => {
  const {height, width} = elementRef.getBoundingClientRect();
  const {x, y} = offsetAmount;
  const placementStyle = {};
  let offset;

  switch(placement) {
    case BOTTOM:
      offset = height + y;
      placementStyle.top = `${offset}px`;
      break;
    case TOP:
      offset = height + y;
      placementStyle.bottom = `${offset}px`;
      break;
    case LEFT:
      offset = width + x;
      placementStyle.right = `${offset}px`;
      break;
    case RIGHT:
      offset = width + x;
      placementStyle.left = `${offset}px`;
      break;
    default:
      break;
  }

  return placementStyle;
};

const calcOverlayAlignment = (elementRef, overlayRef, placement, alignment) => {
  const isHorizontalAlignment = placement === BOTTOM || placement === TOP;
  const {height: elementHeight, width: elementWidth} = elementRef.getBoundingClientRect();
  const {height: overlayHeight, width: overlayWidth} = overlayRef.getBoundingClientRect();
  const alignmentStyle = {};

  switch(alignment) {
    case LEFT:
      alignmentStyle.left = 0;
      break;
    case RIGHT:
      alignmentStyle.right = 0;
      break;
    case TOP:
      alignmentStyle.top = 0;
      break;
    case BOTTOM:
      alignmentStyle.bottom = 0;
      break;
    default: {// center - applies to both vertical or horizontal alignment
      const elementOffset = isHorizontalAlignment ? elementWidth / 2 : elementHeight / 2;
      const overlayOffset = isHorizontalAlignment ? overlayWidth / 2 : overlayHeight / 2;
      const finalOffset = elementOffset - overlayOffset;
      if (isHorizontalAlignment) {
        alignmentStyle.left = `${finalOffset}px`;
      } else {
        alignmentStyle.top = `${finalOffset}px`;
      }
      break;
    }
  }
  return alignmentStyle;
};

export const calcOverlayStyle = (elementRef, overlayRef, offsetAmount, placement, alignment) => {
  const placementStyle = calcOverlayPlacement(elementRef, offsetAmount, placement);
  const alignmentStyle = calcOverlayAlignment(elementRef, overlayRef, placement, alignment);
  return {...placementStyle, ...alignmentStyle};
};

const getArrowPlacement = (overlayPlacement) => {
  const cleansedPlacement = overlayPlacement.trim().toLowerCase();

  // arrow is always placed at the side closest to the elementRef or side opposite of overlay placement
  switch(cleansedPlacement) {
    case BOTTOM:
      return TOP;
    case TOP:
      return BOTTOM;
    case RIGHT:
      return LEFT;
    case LEFT:
      return RIGHT;
    default:
      // No Default
      // TODO: clarify errors to be used in this statement
      /* eslint-disable-next-line no-useless-return */
      return;
  }
};

// Arrow should always align to center of ref element
export const calcArrowStyle = (elementRef, overlayRef, arrowRef, overlayPlacement, overlayAlignment) => {
  const arrowStyle = {};
  const arrowPlacement = getArrowPlacement(overlayPlacement);
  const {width: overlayWidth, height: overlayHeight} = overlayRef.getBoundingClientRect();
  const {width: arrowWidth} = arrowRef.getBoundingClientRect();
  const {width: elementWidth, height: elementHeight} = elementRef.getBoundingClientRect();

  let placementOffset;
  let alignmentOffset;

  switch(arrowPlacement) {
    case BOTTOM:
      placementOffset = overlayHeight;
      arrowStyle.top = `${placementOffset}px`;
      break;
    case TOP:
      arrowStyle.top = 0;
      break;
    case RIGHT:
      placementOffset = overlayWidth - arrowWidth/2;
      arrowStyle.left = `${placementOffset}px`;
      break;
    case LEFT:
      placementOffset = arrowWidth/2 * (-1);
      arrowStyle.left = `${placementOffset}px`;
      break;
    default:
      // No Default
      break;
  }

  // alignment for Overlay is the same as alignment for Arrow
  switch(overlayAlignment) {
    case RIGHT:
      alignmentOffset = overlayWidth - elementWidth/2 - arrowWidth/2;
      arrowStyle.left = `${alignmentOffset}px`;
      break;
    case LEFT:
      alignmentOffset = elementWidth - arrowWidth/2;
      arrowStyle.left = `${alignmentOffset}px`;
      break;
    case TOP:
      alignmentOffset = elementHeight/2;
      arrowStyle.top = `${alignmentOffset}px`;
      break;
    case BOTTOM:
      alignmentOffset = overlayHeight - elementHeight/2;
      arrowStyle.top = `${alignmentOffset}px`;
      break;
    default:
      // No Default
      break;
  }

  return arrowStyle;
};
