import {
    arrayHelper,
    DataType,
    ExpressionOperator,
    ExpressionRule,
} from '@gs-ux-uitoolkit-common/datacore';
import { Input } from '@gs-ux-uitoolkit-react/input';
import { Component } from 'react';
import { Text } from '@gs-ux-uitoolkit-react/text';
import { GridColumn, GetFormattedValueFromColumn } from '../../../grid-wrappers/grid-wrapper';
import { ButtonDisplay } from '../../buttons/button-base';
import { ButtonDelete } from '../../buttons/button-delete';
import { ColumnSelector } from '../../column-selector/column-selector';
import { RangeInputControl, RangeInputControlValues } from './range-inputs/range-input-control';
import { rangeInputHelper } from './range-inputs/range-input-helper';
import { Select, SelectChangeEvent } from '@gs-ux-uitoolkit-react/select';
import { ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';

export interface ExperiencedExpressionRuleProps {
    expressionRule: ExpressionRule;
    columns: GridColumn[];
    onEditExpression: (newExpression: ExpressionRule) => void;
    onDeleteExpression: () => void;
    getFormattedValueFromColumn: GetFormattedValueFromColumn;
    showRowGroups?: boolean;
    ruleNumber: number;
}

export class ExperiencedExpressionRule extends Component<ExperiencedExpressionRuleProps> {
    constructor(props: ExperiencedExpressionRuleProps) {
        super(props);
    }
    public render() {
        const selectedColumn = this.props.columns.find(
            col =>
                this.props.expressionRule.field === col.columnId ||
                (col.primaryColumnId === this.props.expressionRule.field &&
                    arrayHelper.areIdentical(
                        col.pivotKeys || [],
                        this.props.expressionRule.pivotKeys || []
                    ))
        );
        const columnDataType = selectedColumn ? selectedColumn.dataType : DataType.Unknown;
        const isQuickFilter = this.props.expressionRule.operator === ExpressionOperator.QuickFilter;
        const classNamePrefix = `gs-uitk-advanced-expression`;
        return (
            <ThemeConsumer>
                {_theme => {
                    return (
                        <div
                            data-cy={`${classNamePrefix}-rule-${this.props.ruleNumber}`}
                            className={`${classNamePrefix}-rule-container`}
                        >
                            <div className={`${classNamePrefix}-header`}>
                                <Text typography="body03" display="block">
                                    Rule {this.props.ruleNumber}
                                </Text>
                                <ButtonDelete
                                    displayType={ButtonDisplay.Icon}
                                    className={`${classNamePrefix}-header-delete`}
                                    onClick={() => this.props.onDeleteExpression()}
                                    emphasis={'minimal'}
                                    size="sm"
                                />
                            </div>
                            <div className={`${classNamePrefix}-rule`}>
                                <span className={`${classNamePrefix}-rule-row-span`}>
                                    <ColumnSelector
                                        selectedColumnId={this.props.expressionRule.field}
                                        selectedColumnPivotKeys={
                                            this.props.expressionRule.pivotKeys
                                        }
                                        className={`${classNamePrefix}-rule-input`}
                                        columns={this.props.columns}
                                        hideLabel={true}
                                        clearable={false}
                                        size="sm"
                                        autoSize={true}
                                        onChange={columnId => this.onChangeColumn(columnId)}
                                        disabled={isQuickFilter}
                                        showRowGroups={this.props.showRowGroups}
                                    />

                                    <Select
                                        onChange={(event: SelectChangeEvent) =>
                                            this.onChangeOperator(event)
                                        }
                                        className={`${classNamePrefix}-rule-input`}
                                        placeholder={'Select Operator'}
                                        data-cy={`${classNamePrefix}-rule-operator`}
                                        size="sm"
                                        disabled={selectedColumn ? isQuickFilter : true}
                                        options={
                                            isQuickFilter
                                                ? [
                                                      {
                                                          value: ExpressionOperator.QuickFilter,
                                                          label: ExpressionOperator.QuickFilter,
                                                      },
                                                  ]
                                                : rangeInputHelper.getOperatorOptions(
                                                      columnDataType
                                                  )
                                        }
                                        clearable={false}
                                        selectedValue={this.props.expressionRule.operator}
                                    />
                                </span>
                                {isQuickFilter ? (
                                    <Input
                                        disabled={true}
                                        className={`${classNamePrefix}-rule-input`}
                                        size="sm"
                                        value={
                                            this.props.expressionRule.value instanceof Date
                                                ? this.props.expressionRule.value.toISOString()
                                                : this.props.expressionRule.value ?? undefined
                                        }
                                    />
                                ) : (
                                    <RangeInputControl
                                        defaultValue={String(this.props.expressionRule.value)}
                                        className={`${classNamePrefix}-rule-input`}
                                        formattedValue={
                                            this.props.expressionRule.formattedValue
                                                ? String(this.props.expressionRule.formattedValue)
                                                : undefined
                                        }
                                        dataType={columnDataType}
                                        callback={rangeValues => {
                                            this.onInputBlur(rangeValues);
                                        }}
                                        size="sm"
                                        callbackOnlyOnBlur={true}
                                        disabled={selectedColumn ? false : true}
                                        selectedColumnId={this.props.expressionRule.field}
                                        getFormattedValueFromColumn={
                                            this.props.getFormattedValueFromColumn
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    );
                }}
            </ThemeConsumer>
        );
    }

    private onChangeColumn(columnId: string) {
        if (columnId) {
            const selectedColumn = this.props.columns.find(col => columnId === col.columnId);
            const columnDataType = selectedColumn ? selectedColumn.dataType : DataType.Unknown;
            const newrule = { ...this.props.expressionRule };

            const isPivoted =
                selectedColumn && selectedColumn.pivotKeys && selectedColumn.pivotKeys.length;

            newrule.field =
                isPivoted && selectedColumn && selectedColumn.primaryColumnId
                    ? selectedColumn.primaryColumnId
                    : columnId;
            newrule.operator = arrayHelper.first(
                rangeInputHelper.getOperatorList(columnDataType)
            ) as ExpressionOperator;
            newrule.value = '';
            newrule.pivotKeys = isPivoted && selectedColumn ? selectedColumn.pivotKeys : [];
            this.props.onEditExpression(newrule);
        }
    }
    private onChangeOperator(event: SelectChangeEvent) {
        if (event.selectedValue) {
            const newRule = { ...this.props.expressionRule };
            newRule.operator = event.selectedValue as ExpressionOperator;
            this.props.onEditExpression(newRule);
        }
    }
    private onInputBlur(rangeValues: RangeInputControlValues) {
        const newrule = { ...this.props.expressionRule };
        newrule.value = rangeValues.value;
        newrule.formattedValue = rangeValues.formattedValue;
        this.props.onEditExpression(newrule);
    }
}
