import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const CardError = ({ errorMessage, allModulesError }) => {
  return (
    <div className={cn('dbcard__error', { dbcard__errorAllModules: allModulesError })}>
      <span className='dbcard__errorLogo' />
      <div className='dbcard__errorMessage'>{errorMessage}</div>
    </div>
  );
};

CardError.propTypes = {
  errorMessage: PropTypes.string,
  allModulesError: PropTypes.bool,
};

CardError.defaultProps = {
  allModulesError: false,
};

export default CardError;
