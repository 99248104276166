import { MouseEvent, FC } from 'react';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import {
    InputInternalIconsUiProps as InputInternalIconsUiCommonProps,
    inputInternalIconsUiStyleSheet,
    inputIconStyleSheet,
    inputInternalIconsUiSearchClearStyleSheet,
    InputStatus,
    SpecialIcon,
} from '@gs-ux-uitoolkit-common/input';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { VisuallyHidden } from '@gs-ux-uitoolkit-react/accessibility';

export interface InputInternalIconsUiProps extends InputInternalIconsUiCommonProps {
    onClearClick?: () => void;
}

export const InputInternalIconsUi: FC<InputInternalIconsUiProps> = (
    props: InputInternalIconsUiProps
) => {
    const {
        clearable = false,
        componentType = 'text',
        disabled = false,
        size = 'md',
        status = 'none',
        value,
        onClearClick = () => undefined,
    } = props;

    const theme = useTheme();

    function onInternalSearchClearClick(event: MouseEvent) {
        // preventDefault prevents a containing form from being submitted:
        event.preventDefault();
        onClearClick();
    }

    // Prevents the input losing focus.
    // When clearable='onFocus', the input component will lose focus when we click the
    // clear button, preventing the click handlers to be invoked.
    // e.g. if clicking the clear button, the number does not get cleared.
    function onInternalSearchClearMouseDown(event: MouseEvent) {
        event.preventDefault();
    }

    function useIconClassName({
        status = 'none',
        special,
    }: {
        status?: InputStatus;
        special?: SpecialIcon;
    }) {
        return useStyleSheet(inputIconStyleSheet, {
            theme,
            size,
            status,
            special,
            disabled: disabled || false,
            position: 'trailing',
            isTextarea: componentType === 'textarea',
        }).root;
    }

    const containerClassName = useStyleSheet(inputInternalIconsUiStyleSheet, {
        theme,
        size,
        empty: value === undefined || value === '',
    }).root;

    const searchClearClassName = useStyleSheet(inputInternalIconsUiSearchClearStyleSheet, {
        theme,
        size,
        empty: value === undefined || value === '',
    }).button;

    const searchIconClassName = useIconClassName({ special: 'cancel' });
    const loadingIconClassName = useIconClassName({ status: 'loading' });
    const errorIconClassName = useIconClassName({ status: 'error' });
    const warningIconClassName = useIconClassName({ status: 'warning' });
    const successIconClassName = useIconClassName({ status: 'success' });

    return (
        <div className={containerClassName} data-gs-uitk-component="input-ui">
            {clearable && (
                <button
                    className={searchClearClassName}
                    data-cy="gs-uitk-input-search-clear"
                    onClick={onInternalSearchClearClick}
                    onMouseDown={onInternalSearchClearMouseDown}
                    disabled={disabled}
                    title="Clear value"
                    type="button"
                >
                    <Icon name="cancel" type="filled" className={searchIconClassName} />
                </button>
            )}
            {status === 'loading' && (
                <Icon
                    name="loading"
                    aria-label="Loading"
                    type="filled"
                    pulse
                    className={loadingIconClassName}
                />
            )}
            {status === 'error' && (
                <Icon
                    name="error"
                    aria-label="Incorrect value"
                    type="filled"
                    className={errorIconClassName}
                />
            )}
            {status === 'warning' && (
                <>
                    <Icon
                        aria-disabled="true"
                        name="warning"
                        type="filled"
                        className={warningIconClassName}
                    />
                    <VisuallyHidden>{status}icon</VisuallyHidden>
                </>
            )}
            {status === 'success' && (
                <Icon
                    name="check-circle"
                    aria-label="Correct value"
                    type="filled"
                    className={successIconClassName}
                />
            )}
        </div>
    );
};
