import { ComponentProps, CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { Emphasis, Size } from '@gs-ux-uitoolkit-common/design-system';
import { AccordionPanelCssClasses } from './accordion-panel-style-sheet';
import { AccordionCssClasses } from './accordion-style-sheet';

export interface AccordionProps<StyleType = CommonStyleType> extends ComponentProps<StyleType> {
    /**
     * Style classes to override.
     */
    classes?: Partial<AccordionCssClasses>;

    /**
     * Size of the component.
     */
    size?: AccordionSize;

    /**
     * Either 'bold' or 'subtle' for the style of accordion.
     */
    emphasis?: AccordionEmphasis;
}

export interface AccordionPanelProps<StyleType = CommonStyleType>
    extends ComponentProps<StyleType> {
    /**
     * Style classes to override.
     */
    classes?: Partial<AccordionPanelCssClasses>;

    /**
     * If false, the panel will be not expand or collapse on click. True by default.
     */
    toggleOnClick?: boolean;

    /**
     * If true, the expand icon will be hidden.
     */
    hideExpandIcon?: boolean;

    /**
     * If true, the panel will start in an expanded state.
     */
    defaultExpanded?: boolean;

    /**
     * If defined, the panel will be in controlled mode, meaning it can be only expanded or
     * collapsed programmatically by updating the value of this prop. By default, this prop
     * is not defined and AccordionPanel is not controlled.
     */
    expanded?: boolean;

    /**
     * If true, the panel will be disabled, inhibiting user interaction.
     */
    disabled?: boolean;

    /**
     * An optional ID to assign to the panel which will be available on the
     * AccordionPanelToggleEvent object provided to the {@link #onExpand},
     * {@link #onCollapse}, and {@link #onToggle} callbacks.
     *
     * Useful for assigning the function to all onExpand/onCollapse/onToggle
     * callbacks, and handling the expand/collapse by string ID.
     */
    panelId?: string;

    /**
     * Function called when the panel is expanded.
     */
    onExpand?: (panel?: AccordionPanelToggleEvent) => void;

    /**
     * Function called when the panel is collapsed.
     */
    onCollapse?: (panel?: AccordionPanelToggleEvent) => void;

    /**
     * Function called when the panel is expanded or collapsed.
     */
    onToggle?: (panel: AccordionPanelToggleEvent) => void;
}

export type AccordionEmphasis = Extract<Emphasis, 'bold' | 'subtle'>;
export const accordionEmphases: AccordionEmphasis[] = ['bold', 'subtle'];

export type AccordionSize = Extract<Size, 'sm' | 'md' | 'lg'>;
export const accordionSizes: AccordionSize[] = ['sm', 'md', 'lg'];

export type AccordionDefaultProps = Required<Pick<AccordionProps, 'emphasis' | 'size'>>;

export const accordionDefaultProps: AccordionDefaultProps = {
    emphasis: 'subtle',
    size: 'md',
};

export type AccordionPanelDefaultProps = Required<
    Pick<AccordionPanelProps, 'disabled' | 'hideExpandIcon' | 'defaultExpanded' | 'toggleOnClick'>
>;

export const accordionPanelDefaultProps: AccordionPanelDefaultProps = {
    disabled: false,
    hideExpandIcon: false,
    defaultExpanded: false,
    toggleOnClick: true,
};

export interface AccordionPanelToggleEvent {
    expanded: boolean;
    panelId?: string;
}
