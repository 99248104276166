import { CssClasses, CssClassDefinitions, CssFactoryPropsType } from '../types';
import { getClassNamesInDependencyOrder } from '../nested-class-references/get-class-names-in-dependency-order';
import { interpolateNestedSelectors } from '../nested-class-references/interpolate-nested-selectors';
import { applyClassLabel } from '../emotion/apply-class-label';
import { Emotion } from '@emotion/css/types/create-instance';

/**
 * Creates an actual stylesheet (`<style>` tag) and mounts it in the DOM. Returns the
 * object which holds the generated CSS classes and a reference count to how many keys
 * (components) are using it.
 *
 * @param jsStyleSheetName The name of the StyleSheet which is used to generate
 *   the final CSS class names that are injected into the DOM.
 * @param classDefinitions The CSS in JS class definitions to be mounted into the DOM.
 * @param props The Props object to fill in dynamic values (values defined as functions) in the
 *   `classDefinitions`
 */
export function createMountedStyleSheet<
    ClassNames extends string,
    Props extends CssFactoryPropsType,
>(
    jsStyleSheetName: string,
    classDefinitions: CssClassDefinitions<ClassNames, Props>,
    props: Props,
    emotionInstance: Emotion
): MountedStyleSheet<ClassNames> {
    const classDefinitionsObject =
        typeof classDefinitions === 'function' ? classDefinitions(props) : classDefinitions;

    // Create CSS classes in the DOM for all of the CSS class definitions
    const resultClasses = {} as CssClasses<ClassNames>;
    const classNames = getClassNamesInDependencyOrder(classDefinitionsObject);
    classNames.forEach(className => {
        const cssProperties = classDefinitionsObject[className];

        // Interpolate the #{className} references in nested selectors
        const interpolatedNestedSelectors = interpolateNestedSelectors(
            cssProperties,
            resultClasses
        );

        // Apply a label to the generated class names so we can more easily
        // debug it in the DOM (in non-production mode only)
        let finalCssProperties = interpolatedNestedSelectors;
        if (process.env.NODE_ENV !== 'production') {
            finalCssProperties = applyClassLabel(
                jsStyleSheetName,
                className,
                interpolatedNestedSelectors
            );
        }

        // And mount in the DOM
        (resultClasses as any)[className] = emotionInstance.css(finalCssProperties);
    });

    return new MountedStyleSheet(resultClasses);
}

/**
 * Represents a StyleSheet that has been mounted into the DOM. Maintains a reference
 * count of how many keys (components) are using the StyleSheet, and when all are
 * unmounted, removes the StyleSheet from the DOM.
 */
export class MountedStyleSheet<ClassNames extends string> {
    private referenceCount = 1; // the number of references (components) using this set of CSS classes

    constructor(public readonly cssClasses: CssClasses<ClassNames>) {}

    /**
     * Increments the reference count of keys (components) using this StyleSheet.
     */
    public incrementReferenceCount() {
        this.referenceCount++;
    }

    /**
     * Decrements the reference count of the MountedStyleSheet, and if the reference
     * count has reached 0, removes the StyleSheet from the DOM.
     *
     * @return `true` if the StyleSheet has been removed from the DOM, `false` otherwise.
     */
    public decrementReferenceCount(): boolean {
        this.referenceCount--;

        if (this.referenceCount === 0) {
            // NOTE: We had functionality here to delete the styles that are
            // no longer needed using emotionInstance.flush(), where we had
            // one Emotion instance per StyleSheet. Unfortunately we needed to
            // have exactly one Emotion instance though so that we could use its
            // cx() function between StyleSheets to combine their styles correctly.
            // We'll need a different way of deleting styles if we want to
            // implement that at some point
            //this.emotionInstance.flush(); // delete the stylesheet from the DOM

            return true; // MountedStyleSheet was unmounted from the DOM
        } else {
            return false; // references to this MountedStyleSheet still exist
        }
    }
}
