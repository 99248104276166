import { cx } from '@gs-ux-uitoolkit-common/style';
import { BadgeProps } from './badge-props';
import { BadgeCssClasses } from './badge-style-sheet';

export const getBadgeRootClasses = (props: {
    cssClasses: BadgeCssClasses;
    overrideClasses?: BadgeProps['classes'];
    className?: BadgeProps['className'];
}): string => {
    const { cssClasses, className, overrideClasses } = props;
    return cx(cssClasses.root, className, overrideClasses && overrideClasses.root, 'gs-badge');
};
