import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import useHelpGuide from './useHelpGuide';

export const HelpGuideContext = createContext({});

const HelpGuideProvider = ({ children, helpGuideConfig }) => {
  const providerValue = useHelpGuide(helpGuideConfig);
  return <HelpGuideContext.Provider value={providerValue}>{children}</HelpGuideContext.Provider>;
};

HelpGuideProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  helpGuideConfig: PropTypes.array,
};

export default HelpGuideProvider;
