import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import {amountFormatter, getHighestDp, reportingAmountFormatter} from './amountFormatter';
import {compositeFormatter} from './compositeFormatter';
import {dateFormatter, booleanFormatter, booleanStringFormatter, dateTimeFormatter, dateTimeFormatterTradeEntry, dateTimeFormatterWithoutSeconds} from './portfolioFormatter';
import { NAV_TYPE, FUND_TYPE } from '../constants/pageConstants';
import translator from '../services/translator';

const { translate: t } = translator;

// ***************************** GRID formatters starts here *********************************
export const nullValueFormatter = (params) => {
  if(!Object.prototype.hasOwnProperty.call(params, 'value') || params.value === null ||
    isUndefined(params.value) || params.value === '' || (Array.isArray(params.value) && !params.value.length)) {
    return {
      ...params,
      value: t('tkNullDataPlaceholder'),
      isValueNull: true
    };
  } else{
    return params;
  }
};
export const blankCellFormatter = () => {
  return '';
};

export const formatNavValue = (params) => {
  if(!Object.prototype.hasOwnProperty.call(params, 'value') || params.value === null || isUndefined(params.value)
    || params.value === '' || (Array.isArray(params.value) && !params.value.length)) {
    return {
      ...params,
      value: 'N/A'
    };
  } else {
    return params;
  }
};

export const dpFormatter = (params) => {
  const { value, isValueNull, colDef : { dp } } = params;
  if (isUndefined(value) || isValueNull || !dp) {
    return params;
  }
  const decimalMultiple = 10 ** dp;
  const formattedValue = (Math.round(value * decimalMultiple) / decimalMultiple).toFixed(dp);
  return {
    ...params,
    value: formattedValue
  };
};

export const settlementCycleFormatter = (params) => {
  const { value, isValueNull } = params;
  if(isUndefined(value) || value === ' ' || isValueNull) {
    return params;
  }
  const numValue = Number(value);
  if (Number.isNaN(numValue)) {
    return {
      ...params,
      value
    };
  }
  const settlementCycleValue = Number(value) <= 0 ? 'T' : `T + ${value}`;
  return {
    ...params,
    value: settlementCycleValue
  };
};

export const currencyFormatter = (params) => {
  const { value, isValueNull, colDef : { dp } } = params;
  if (isUndefined(value) || isValueNull) {
    return params;
  }
  const formattedValue = amountFormatter(value, dp, !dp);
  return {
    ...params,
    value: formattedValue
  };
};

export const reportingCurrencyFormatter = (params) => {
  const { value, isValueNull } = params;
  if (isUndefined(value) || isValueNull){
    return params;
  }
  const formattedValue = reportingAmountFormatter(value);
  return {
    ...params,
    value: formattedValue
  };
};

export const absoluteValueFormatter = (params) => {
  const { value, isValueNull } = params;
  if (isUndefined(value) || value === ' ' || isValueNull) {
    return params;
  }
  if ((typeof value === "string") && value.substring(0, 1) === '-') {
    const formattedValue = value.substring(1);
    return {
      ...params,
      value: formattedValue
    };
  } else {
    return {
      ...params,
      value
    };
  }
};

export const gridDateFormatter = (params) => {
  const { value, isValueNull } = params;
  if (isUndefined(value) || isValueNull) {
    return params;
  }
  const formattedValue = dateFormatter(value);
  return {
    ...params,
    value: formattedValue
  };
};

export const gridDateTimeFormatter = (params) => {
  const {value} = params;
  if (isUndefined(value)) {
    return params;
  }
  const formattedValue = dateTimeFormatter(value);
  return {
    ...params,
    value: formattedValue
  };
};

export const tradeEntryDateTimeFormatter = (params) => {
  const {value} = params;
  if (isUndefined(value)) {
    return params;
  }
  const formattedValue = dateTimeFormatterTradeEntry(value);
  return {
    ...params,
    value: formattedValue
  };
};

export const feedCreationDateTimeFormatter = (params) => {
  const {value} = params;
  if (isUndefined(value)) {
    return params;
  }
  const formattedValue = dateTimeFormatterWithoutSeconds(value);
  return {
    ...params,
    value: formattedValue
  };
};

export const feeSubjectFormatter = (params) => {
  const { value } = params;
  if (isUndefined(value)) {
    return params;
  }
  const formattedValue = booleanFormatter(value);
  return {
    ...params,
    value: formattedValue
  };
};

export const zeroValueFormatter = (params) => {
  let { value } = params;
  if (isUndefined(value)) {
    return params;
  } else if (value === '' || value === null) {
    value = 0;
  }
  return {
    ...params,
    value,
  };
};

const navAsOfTimeFormatter = (params) => {
  const { value, data = {} } = params;
  if(isUndefined(value)) {
    return params;
  }

  const { navAsOfTime, recentNavTime, navType, fundType } = data;
  let formattedValue = dateFormatter(navAsOfTime);
  const navs = [NAV_TYPE.FNAV, NAV_TYPE.FNAV_SINGLE_STRIKE, NAV_TYPE.FNAV_MULTIPLE_STRIKE];
  if(fundType === FUND_TYPE.MONEY_MARKET && navs.includes(navType) && recentNavTime) {
    formattedValue = `${dateFormatter(navAsOfTime)} ${recentNavTime}`;
  }
  return {
    ...params,
    value: formattedValue
  };
};

const navScheduleFormatter = (params) => {
  const { value } = params;
  if(isUndefined(value) || !Array.isArray(value)) {
    return params;
  }
  const formattedValue = [];
  value.forEach(navItem => {
    const {navTime} = navItem;
    formattedValue.push(navTime);
  });
  return {
    ...params,
    value: formattedValue.join(', ')
  };
};

const formatPhoneNumber = (params) => {
  const { value } = params;
  if(!value) {
    return params;
  }
  const cleaned = `${value}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = (match[1] ? '+1 ' : '');
    return {
      ...params,
      value: [intlCode, ' ', match[2], ' ', match[3], ' ', match[4]].join('')
    };
  }
  return params;
};

export const toSentenceCaseFormatter = (params) => {
  const { value } = params;
  if(value === 'YES') {
    return {
      ...params,
      value: 'Yes'
    };
  } else if(value === 'NO') {
      return {
        ...params,
        value: 'No'
    };
  }
  return params;

};

export const branchNameGroupLabelFormatter = (params) => {
  const { colDef } = params;
  const colId = get(colDef, 'colId', '');

  if (colId === 'ag-Grid-AutoColumn') {
    // safely get the first node
    const firstNode = get(params, 'node.allLeafChildren[0]', []);
    const {data: {firmCode, branchCode, branchName}} = firstNode;
    const firmBranchCode = `${firmCode}${branchCode}`;
    const value = !branchName ? firmBranchCode : `${firmBranchCode} - ${branchName}`;
    return {...params, value};
  }
  return params;
};

export const tradeTypeFormatter = (params) => {
  const { value, isValueNull } = params;
  if(isUndefined(value) || isValueNull) {
    return params;
  }

  const tradeType = value.toUpperCase();
  const tradeTypeValue = tradeType.replaceAll('_', ' ');
  return {
    ...params,
    value: tradeTypeValue
  };
};

export const wsTradeTypeFormatter = (params) => {
  const { value, isValueNull, node, data } = params;
  // this means it is an excel
  if (! node) {
    const excelValue = data.tradeTypeData;
    return {
      ...params,
      value: excelValue
    };
  }
  if(value === undefined || isValueNull || isEmpty(value.trim())) {
    return params;
  }

  let tradeTypeValue = value.toUpperCase();
  const isRowGroupRow = get(params, 'node.group');

  if (!isRowGroupRow) {
    if(tradeTypeValue === t("tkPurchases").toUpperCase()){
      tradeTypeValue = data.tradeTypeData;
    } else if((tradeTypeValue === t("tkRedemptionsCashDiv").toUpperCase())) {
      tradeTypeValue = data.tradeTypeData;
    }
  }

  return {
    ...params,
    value: tradeTypeValue
  };
};

const adjustedFieldsFormatter = (params) => {
  const {data = {}, colDef} = params;
  let value = null;

  if  (data.rebateDetails) {
    value = data.rebateDetails[colDef.field];
  }

  return {
    ...params,
    value
  };
};

export const nameFormatter = (name, defaultValue) => {
  if (!name) {
    return defaultValue;
  }
  const [firstName, lastName] = name.split(' ');
  if (!firstName || !lastName) {
    return defaultValue;
  }
  return `${firstName.charAt(0)}. ${lastName}`;
};

export const reviewerFormatter = (params) => {
  const isRowGroupRow = get(params, 'node.group');

  // If no reviewer info, return Not Applicable
  let rtnValue = isRowGroupRow ? ' ' : t('tkNA2');
  const { value, key } = params;
  // In case new Reports, we get reviewer name as one single String
  if (typeof value === 'string') {
    rtnValue = nameFormatter(value, rtnValue);
  } else {
    if (value && value.length !== 0) {
      const levelToCheck = key[key.length - 1];
      for (let i = 0; i < value.length; i++) {
        const {level, approverDetails: {firstName, lastName}} = value[i];
        if (level.toString() === levelToCheck) {
          rtnValue = nameFormatter(`${firstName} ${lastName}`, rtnValue);
          break;
        }
      }
    }
  }
  return {
    ...params,
    value: rtnValue
  };
};

const allFormatters = {
  'nullValueFormatter': nullValueFormatter,
  'currencyFormatter': currencyFormatter,
  'absoluteValueFormatter': absoluteValueFormatter,
  'blankCellFormatter': blankCellFormatter,
  'dateFormatter': gridDateFormatter,
  'dateTimeFormatter': gridDateTimeFormatter,
  'booleanFormatter': feeSubjectFormatter,
  'booleanStringFormatter': booleanStringFormatter,
  'navScheduleFormatter': navScheduleFormatter,
  'navAsOfTimeFormatter': navAsOfTimeFormatter,
  'phoneNumber': formatPhoneNumber,
  'navValueFormatter': formatNavValue,
  'toSentenceCaseFormatter': toSentenceCaseFormatter,
  'dpFormatter': dpFormatter,
  'branchNameGroupLabelFormatter': branchNameGroupLabelFormatter,
  'settlementCycleFormatter': settlementCycleFormatter,
  'tradeEntryDateTimeFormatter': tradeEntryDateTimeFormatter,
  'feedCreationDateTimeFormatter': feedCreationDateTimeFormatter,
  'zeroValueFormatter': zeroValueFormatter,
  'tradeTypeFormatter': tradeTypeFormatter,
  "wsTradeTypeFormatter": wsTradeTypeFormatter,
  'adjustedFieldsFormatter': adjustedFieldsFormatter,
  'reportingCurrencyFormatter': reportingCurrencyFormatter,
  'reviewerFormatter': reviewerFormatter
};

export const gridCompositeFormatter = (params) => {
  const {colDef} = params;
  const formatters = colDef.formatters
    .split(',')
    .map(item => item.trim())
    .map(formatterString => allFormatters[formatterString]);
  const {value} = compositeFormatter(params, formatters);
  return value;
};

export const gridGroupCompositeFormatter = (params) => {
  const {colDef: {groupFormatters} = {}, node} = params;
  const groupFormatterForLevel = groupFormatters[node.level];
  const groupFormatter = groupFormatterForLevel && groupFormatterForLevel.split(',') || [];
  const formatters = groupFormatter
    .filter(data => data !== 'nullValueFormatter')
    .map(item => item.trim())
    .map(formatterString => allFormatters[formatterString]);
  const {value} = compositeFormatter(params, formatters);
  return value;
};

// ******************** GRID Standard/Custom Aggregators starts here **************************

export const numValuesAgg = ({values}) => {
  const isValueEmpty = values.every(value =>
    (value === null || isUndefined(value) || value === '' || value === ' ')
  );

  // If not all of the Agg Nodes have empty value, then proceed to aggregating
  if (!isValueEmpty) {
    let totalFloat = 0;
    values
      .filter(value => {
        /* This will be the case of empty Agg Node value (Refer end of this function)
           In this case as there may be other Agg Node have number value, filter this
           out, so it won't be part of aggregation */
        return value !== ' ';
      })
      .forEach(value => {
        const valueToParse = ((typeof value) === 'string') ? value.replace(/,/g, '') : (value ? value.toString() : value);
        if (!isEmpty(valueToParse)) {
          totalFloat += parseFloat(valueToParse);
        }
      });
    const decimalPoint = getHighestDp(values);
    const formattedAmount = amountFormatter(totalFloat.toString(), decimalPoint);
    return formattedAmount.replace(/,/g, '');
  }
  return ' '; // To display empty Agg Node if the field value is returned empty from service
};

// To remove '--' from non-aggregated columns in case of empty values
const removeNonAggregatedTotal = () => (' ');

const allAggFuncs = {
  'numValuesAgg': numValuesAgg,
  'sum': 'sum',
  'removeNonAggregatedTotal': removeNonAggregatedTotal
};

export const getAggFunc = (aggFunc = 'removeNonAggregatedTotal') => {
  /* In case of editing existing view (like sorting or moving columns
     around we end up in this scenario) */
  if (typeof aggFunc === 'function') {
    return aggFunc;
  }
  return allAggFuncs[aggFunc];
};
