import { isFunction, isObject } from 'gs-uitk-lodash';

import { save as actionSave } from './actions';
import { LOAD, SAVE } from './constants';
import { Middleware } from 'redux';
import { StorageEngine } from './types';

function isValidAction(action: any) {
    const isFunc = isFunction(action);
    const isObj = isObject(action);
    const hasType = isObj && Object.hasOwnProperty.call(action, 'type');

    if (!isFunc && isObj && hasType) {
        return true;
    }

    return false;
}

function handleWhitelist(action: any, actionWhitelist: any) {
    if (Array.isArray(actionWhitelist)) {
        return actionWhitelist.length === 0
            ? true // Don't filter if the whitelist is empty
            : actionWhitelist.indexOf(action.type) !== -1;
    }

    // actionWhitelist is a function that returns true or false
    return actionWhitelist(action);
}
export function createMiddleware(
    engine: StorageEngine,
    actionBlacklist: string[] = [],
    actionWhitelist: string[] = []
): Middleware {
    // Also don't save if we process our own actions
    const blacklistedActions = [...actionBlacklist, LOAD, SAVE];

    return ({ dispatch, getState }) => {
        return next => action => {
            const result = next(action);

            if (!isValidAction(action)) {
                return result;
            }

            const isOnBlacklist = blacklistedActions.indexOf(action.type) !== -1;
            const isOnWhitelist = handleWhitelist(action, actionWhitelist);

            // Skip blacklisted actions
            if (!isOnBlacklist && isOnWhitelist) {
                const saveState = getState();
                const saveAction = actionSave(saveState);
                const dispatchSave = () => dispatch(saveAction);
                engine
                    .save(saveState)
                    .then(dispatchSave)
                    .catch(() => {});
            }

            return result;
        };
    };
}
