import { Component, KeyboardEvent } from 'react';
import { basicExpressionClassNamePrefix } from '../../style/component/basic-expression-stylesheet';
import { ColumnSelector } from '../column-selector/column-selector';
import {
    Select,
    SelectChangeEvent,
    SelectMultipleChangeEvent,
} from '@gs-ux-uitoolkit-react/select';
import { Input } from '@gs-ux-uitoolkit-react/input';
import { Button } from '@gs-ux-uitoolkit-react/button';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { GridColumn, DisplayValueTuple } from '../../grid-wrappers/grid-wrapper';
import { SelectMultiple } from '@gs-ux-uitoolkit-react/select-multiple';
import { rangeInputHelper } from './component/range-inputs/range-input-helper';
import { BasicExpressionColumnValueState } from '../../redux/datagrid-state';
import { debounce } from 'gs-uitk-lodash';

// TODO: Quickfilter basic mode values can be selected and not just free text UX-14604
// import { ExpressionOperator } from '@gs-ux-uitoolkit-common/datacore';

export interface BasicExpressionRuleProps {
    ruleId: string;
    columns: GridColumn[];
    showRowGroups?: boolean;
    basicExpressionColumnValues: BasicExpressionColumnValueState[];
    selectedColumn?: GridColumn | null;
    operator?: string | null;
    values?: string[];
    getColumnValues: (columnId: string) => void;
    updateRuleDetails(
        ruleId: string,
        column: GridColumn | null,
        operator: string | null,
        values: string[]
    ): void;
    deleteRule(ruleId: string): void;
    isDra: boolean;
}

export interface BasicExpressionRuleState {
    selectedColumn: GridColumn | null;
    operator: string | null;
    values: string[];
}

export class BasicExpressionRule extends Component<
    BasicExpressionRuleProps,
    BasicExpressionRuleState
> {
    constructor(props: BasicExpressionRuleProps) {
        super(props);
        this.state = {
            operator: this.props.operator || null,
            selectedColumn: this.props.selectedColumn || null,
            values: this.props.values || [],
        };
    }
    public componentDidMount() {
        if (this.props.selectedColumn?.columnId)
            this.props.getColumnValues(this.props.selectedColumn.columnId);
    }
    public render() {
        const columnValues = this.getColumnValues();
        return this.state.values.map((value, index) => {
            return (
                <div
                    className={`${basicExpressionClassNamePrefix}__expression-container`}
                    key={index}
                >
                    <div className={`${basicExpressionClassNamePrefix}__column-selector`}>
                        <ColumnSelector
                            size="sm"
                            autoSize={true}
                            hideLabel={true}
                            columns={this.props.columns}
                            onChange={(columnId: string) => this.onColumnSelected(columnId)}
                            selectedColumnId={
                                this.state.selectedColumn ? this.state.selectedColumn.columnId : ''
                            }
                            showRowGroups={this.props.showRowGroups}
                        />
                    </div>
                    <div className={`${basicExpressionClassNamePrefix}__operator-selector`}>
                        <Select
                            options={this.getOperatorOptions()}
                            size="sm"
                            onChange={(event: SelectChangeEvent) =>
                                this.onSetOperator(event.selectedValue)
                            }
                            selectedValue={this.state.operator}
                        ></Select>
                    </div>
                    <div className={`${basicExpressionClassNamePrefix}__value-selector`}>
                        {!this.showInputValueSelector() ? (
                            <SelectMultiple
                                size="sm"
                                options={columnValues}
                                renderSelectedOptions={true}
                                constrainSelectedOptions={true}
                                onChange={this.onValuesSelected}
                                selectedValues={this.state.values}
                                disabled={!columnValues.length}
                            ></SelectMultiple>
                        ) : (
                            <Input
                                size="sm"
                                defaultValue={value || ''}
                                onInput={this.onInputValueChanged}
                            ></Input>
                        )}
                    </div>
                    <div className={`${basicExpressionClassNamePrefix}__delete`}>
                        <Button
                            size="sm"
                            onClick={() => this.props.deleteRule(this.props.ruleId)}
                            emphasis="minimal"
                        >
                            <Icon name="delete" type="outlined" size="sm"></Icon>
                        </Button>
                    </div>
                </div>
            );
        });
    }

    showInputValueSelector = (): boolean => {
        // TODO: Quickfilter basic mode values can be selected and not just free text UX-14604
        return true;
        // (
        //     this.props.isDra ||
        //     this.state.operator === ExpressionOperator.Contains ||
        //     this.state.operator === ExpressionOperator.NotContains ||
        //     this.state.operator === ExpressionOperator.ContainsCaseInsensitive ||
        //     this.state.operator === ExpressionOperator.NotContainsCaseInsensitive
        // );
    };

    private onValuesSelected = (event: SelectMultipleChangeEvent) => {
        this.updateValues(event.selectedValues);
    };
    private onInputValueChanged = (event: KeyboardEvent<HTMLInputElement>) => {
        const target = (event.target || event.currentTarget) as HTMLInputElement;
        const delayedCall = debounce(() => {
            if (target) this.updateValues([target.value]);
        }, 50);

        delayedCall();
    };
    updateValues(selectedValues: string[]) {
        this.setState(
            {
                values: selectedValues,
            },
            () => {
                this.props.updateRuleDetails(
                    this.props.ruleId,
                    this.state.selectedColumn,
                    this.state.operator,
                    this.state.values
                );
            }
        );
    }

    private getColumnValues = () => {
        const columnValueState = this.state.selectedColumn
            ? this.props.basicExpressionColumnValues.find(listItem => {
                  return listItem.columnId === this.getSelectedColumnId(this.state.selectedColumn);
              })
            : undefined;

        const columnValueOptions =
            columnValueState && columnValueState.columnValues
                ? columnValueState.columnValues.map((value: DisplayValueTuple) => {
                      return {
                          label: value.displayValue,
                          value: value.displayValue,
                      };
                  })
                : [];

        return columnValueOptions;
    };
    private isSelectedColumnPivoted(selectedColumn: GridColumn | null): boolean {
        return !!(selectedColumn && selectedColumn.pivotKeys && selectedColumn.pivotKeys.length);
    }
    private getSelectedColumnId(selectedColumn: GridColumn | null): string {
        if (!selectedColumn) {
            return '';
        }
        return this.isSelectedColumnPivoted(selectedColumn) && selectedColumn.primaryColumnId
            ? selectedColumn.primaryColumnId
            : selectedColumn.columnId;
    }

    private getOperatorOptions = () => {
        return this.state.selectedColumn
            ? rangeInputHelper.getOperatorOptions(this.state.selectedColumn.dataType)
            : [];
    };

    private onSetOperator(operator: string | null) {
        this.setState(
            {
                operator,
            },
            () => {
                this.props.updateRuleDetails(
                    this.props.ruleId,
                    this.state.selectedColumn,
                    this.state.operator,
                    this.state.values
                );
            }
        );
    }
    private onColumnSelected = (columnId: string) => {
        const selectedColumn =
            this.props.columns.find(col => {
                return col.columnId === columnId;
            }) || null;

        this.setState(
            {
                selectedColumn: selectedColumn,
            },
            () => {
                this.props.updateRuleDetails(
                    this.props.ruleId,
                    this.state.selectedColumn,
                    this.state.operator,
                    this.state.values
                );
            }
        );

        if (selectedColumn) {
            this.props.getColumnValues(selectedColumn.columnId);
        }
    };
}
