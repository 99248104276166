import { CssClasses, StyleSheet } from '@gs-ux-uitoolkit-react/style';
import { lightTheme, Theme } from '@gs-ux-uitoolkit-react/theme';

export interface ZebraStripesStylesheetProps {
    stripeBackgroundColour: string | undefined;
    nonStripeBackgroundColour: string | undefined;
    theme: Theme;
}

export const zebraStripesStylesheet = new StyleSheet(
    'zebra-stripes',
    ({ stripeBackgroundColour, nonStripeBackgroundColour, theme }: ZebraStripesStylesheetProps) => {
        const themeObject = theme || lightTheme;
        const selectedStripeBackgroundColour =
            stripeBackgroundColour !== undefined
                ? stripeBackgroundColour
                : themeObject.surface.secondary;
        const selectedNonStripeBackgroundColour =
            nonStripeBackgroundColour !== undefined
                ? nonStripeBackgroundColour
                : themeObject.surface.primary;

        return {
            // AG-Grid-Balham theme sets a default zebra striped pattern on odd rows. We overwrite this by setting the non-striped row to the ag-grid themed background default
            '.zebra-stripes': {
                backgroundColor: `${selectedStripeBackgroundColour} !important`,
                '&.ag-row-selected': {
                    backgroundColor: `${
                        themeObject.getSurfaceInteractionShades('primary').active
                    } !important`,
                },
                '&.ag-row-hover': {
                    backgroundColor: `${
                        themeObject.getSurfaceInteractionShades('primary').hover
                    } !important`,
                },
            },
            '.non-zebra-stripes': {
                backgroundColor: `${selectedNonStripeBackgroundColour} !important`,
                '&.ag-row-selected': {
                    backgroundColor: `${
                        themeObject.getSurfaceInteractionShades('secondary').active
                    } !important`,
                },
                '&.ag-row-hover': {
                    backgroundColor: `${
                        themeObject.getSurfaceInteractionShades('secondary').hover
                    } !important`,
                },
            },
        };
    }
);

export const getStripedClassNames = (props: {
    cssClasses: CssClasses<typeof zebraStripesStylesheet>;
}): string => {
    return props.cssClasses['.zebra-stripes'];
};
export const getNonStripedClassNames = (props: {
    cssClasses: CssClasses<typeof zebraStripesStylesheet>;
}): string => {
    return props.cssClasses['.non-zebra-stripes'];
};
