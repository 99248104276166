import { useState, useCallback } from 'react';
import {
    applyOnChangeHooks,
    applyOnCloseHooks,
    DatePickerHooks,
    DatePickerOptions,
} from '@gs-ux-uitoolkit-common/datepicker';
import { Instance } from 'flatpickr/dist/types/instance';

export const useDatePickerHooks = ({ options }: { options: DatePickerOptions }) => {
    const [inputValue, setInputValue] = useState('');
    const onChange = useCallback(
        (selectedDates: Date[], dateStr: string, instance: Instance) => {
            applyOnChangeHooks(
                selectedDates,
                dateStr,
                instance,
                (options?.onChange as DatePickerHooks) || null
            );
            setInputValue(dateStr);
        },
        [options.onChange]
    );
    const onClose = useCallback(
        (selectedDates: Date[], dateStr: string, instance: Instance) => {
            applyOnCloseHooks(
                selectedDates,
                dateStr,
                instance,
                (options?.onClose as DatePickerHooks) || null
            );
            // The clear button appears when the value changes after selecting
            // one date, so if it's a range and the calendar closes without
            // selecting a full range, call clear so the clear button is hidden
            if (options.mode === 'range' && selectedDates.length === 1) {
                // Add a logic to select the same selected date as the second date
                instance.setDate(
                    [
                        selectedDates[0].setHours(0, 0, 0, 0),
                        selectedDates[0].setHours(23, 59, 59, 999),
                    ],
                    true
                );
            }
        },
        [options.onClose, options.mode]
    );

    return {
        onChange,
        onClose,
        inputValue,
        setInputValue,
    };
};
