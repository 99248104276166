import { IconName } from './design-system-icons/full-set';
import { IconCssClasses } from './icon-style-sheet';
import { Theme } from '@gs-ux-uitoolkit-common/theme';

export type IconBaseProps = IconRequiredProps & IconOptionalProps;
export interface IconRequiredProps {
    name: IconName;
    type: IconType;
}

export type IconElementComponentProps = IconBaseProps & {
    attributes?: { [attributeName: string]: string };
    theme?: Theme;
};

export interface IconOptionalProps {
    /**
     * Size of the icon.
     */
    size?: IconSize;

    /**
     * Add a spin effect to the icon.
     */
    spin?: boolean;

    /**
     * Add a pulse effect to the icon.
     */
    pulse?: boolean;

    /**
     * Additional classes to add on the element.
     */
    className?: string;

    /**
     * Style classes to override.
     */
    classes?: Partial<IconCssClasses>;
}

export type IconType = 'filled' | 'outlined';
export const iconTypes: IconType[] = ['filled', 'outlined'];

export type IconSize = 'sm' | 'md' | 'lg'; // can't use Size from design-system since it would cause a cyclical dependency
export const iconSizes: IconSize[] = ['sm', 'md', 'lg'];

export const defaultIconProps: DefaultIconProps = {
    size: 'md',
    type: 'filled',
};

export type DefaultIconProps = Required<Pick<IconBaseProps, 'size' | 'type'>>;
