import React from 'react';
import { ThreeSquaresLoader } from '../../Loaders';
import './overlays.scss';

export const CustomLoadingOverlay = () => {
  return (
    <div className='customLoading'>
      <ThreeSquaresLoader />
    </div>
  );
}
