import React, { useEffect, useMemo, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';
import { toggleBrowserScrollVisiblilty } from '../../../../utils/dom';
import { Conditional } from '../../../../components/core/Conditional';
import Modal from '../../../../components/core/Modal';
import { ThreeSquaresLoader } from '../../../../components/core/Loaders';
import Constants from '../../../../constants/appConstants';
import Snackbar from '../../../../components/core/Snackbar';
import RadioButton from '../../../../components/core/RadioButton';
import Button from '../../../../components/core/Button';
import { REPORTS_STATUS_CODES } from '../../../../constants/pageConstants';
import translator from '../../../../services/translator';
import { regex as regexMatchers } from '../../../../helpers/regex';
import { additionalFiltersServiceFieldMapper } from '../../../../services/reports/common';
import { NewReportDetails } from './NewReportDetails';

const { translate: t } = translator;

export const SaveReportModal = ({
  handleClose,
  selectedSavedReportData,
  mySavedReports,
  customReportCrudResponse,
  setCustomReportCrudResponse,
  selectedFilterData,
  dispatchSaveCustomReport,
  saveCustomReportPendingFlag,
  labels,
  savedReportFailedSnackbarMsg,
  initialReportData,
  defaultAdditionalFilters,
  filterConfig: { additionalCriteria },
}) => {
  const {
    reportName: existingReportName,
    reportDescription: existingReportDescription,
    savedReportViewId,
  } = selectedSavedReportData || {};
  const isNewReport = !savedReportViewId;
  const [reportSaveOptions, setReportSaveOptions] = useState([
    {
      label: t('tkOverrideExistingReport'),
      value: 'overrideExistingReport',
      isSelected: true,
    },
    {
      label: t('tkSaveAsNewReport'),
      value: 'saveAsNewReport',
      isSelected: false,
    },
  ]);
  const [option1, option2] = reportSaveOptions;
  const [newReportDetails, setNewReportDetails] = useState(initialReportData);

  const onCloseModal = () => {
    toggleBrowserScrollVisiblilty(false);
    handleClose();
  };

  useEffect(() => {
    const { status } = customReportCrudResponse || {};
    if (
      status === REPORTS_STATUS_CODES.SUCCESS ||
      status === REPORTS_STATUS_CODES.MAX_COUNT
    ) {
      onCloseModal();
    }
  }, [customReportCrudResponse]);

  const onHandleApply = () => {
    let isReportValidToSave = true;
    setCustomReportCrudResponse(null);
    const { reportName: newReportName, description: newReportDescription } =
      newReportDetails;
    const isOverrideExistingReport = option1.isSelected;
    const isSaveExistingReportAsNew = option2.isSelected;

    // Check these for saving as 'New Report'
    if (isNewReport || !isOverrideExistingReport) {
      const isReportExists = mySavedReports.some(
        ({ reportName }) =>
          reportName.trim().toLowerCase() === newReportName.trim().toLowerCase()
      );
      const isReportNameEmpty = newReportName.trim().length === 0;
      const isReportNameInvalid = !newReportName.match(
        regexMatchers.validSavedReportName
      );
      const isReportDescInvalid = !newReportDescription.match(
        regexMatchers.validSavedReportDesc
      );
      const isReportLimitExceeded = mySavedReports.length === 100;
      isReportValidToSave =
        !isReportExists &&
        !isReportNameEmpty &&
        !isReportNameInvalid &&
        !isReportLimitExceeded &&
        !isReportDescInvalid;

      if (isReportNameEmpty) {
        setCustomReportCrudResponse({
          status: REPORTS_STATUS_CODES.REPORT_NAME_MISSING_ERROR,
        });
      } else if (isReportNameInvalid) {
        setCustomReportCrudResponse({
          status: REPORTS_STATUS_CODES.REPORT_NAME_ERROR,
        });
      } else if (isReportExists) {
        const errorCode = isNewReport
          ? REPORTS_STATUS_CODES.NEW_REPORT_VIEW_EXISTS
          : REPORTS_STATUS_CODES.REPORT_EXISTS;
        setCustomReportCrudResponse({ status: errorCode });
      } else if (isReportLimitExceeded) {
        setCustomReportCrudResponse({ status: REPORTS_STATUS_CODES.MAX_COUNT });
      } else if (isReportDescInvalid) {
        setCustomReportCrudResponse({
          status: REPORTS_STATUS_CODES.REPORT_DESC_ERROR,
        });
      }
    }

    // Save it only if it's OK to save
    if (isReportValidToSave) {
      const overrideExistingReport = !isNewReport && isOverrideExistingReport;
      const updatedReportName = overrideExistingReport
        ? existingReportName
        : newReportName;
      const updatedReportDescription =
        (overrideExistingReport
          ? existingReportDescription
          : newReportDescription) || '';
      const reportDetails = {
        reportName: updatedReportName.trim(),
        description: updatedReportDescription.trim(),
      };

      // Apple default additional filters
      let updatedSelectedFilterData;
      const { additionalCriteria: userSelectedAdditionalCriteria } =
        selectedFilterData;
      if (!userSelectedAdditionalCriteria) {
        // Default filter values
        const updatedFilters = additionalCriteria.reduce((obj, key) => {
          const filterKey = additionalFiltersServiceFieldMapper[key];
          if (filterKey) {
            const filterValues = defaultAdditionalFilters[filterKey];
            if (filterValues) {
              obj[filterKey] = filterValues;
            }
          }
          return obj;
        }, {});
        updatedSelectedFilterData = {
          ...selectedFilterData,
          additionalCriteria: {
            additionalFilters: Object.entries(updatedFilters).reduce(
              (obj, [filterKey, filterValues]) => {
                obj[filterKey] = filterValues.map((value) => ({ value }));
                return obj;
              },
              {}
            ),
            filters: updatedFilters,
          },
        };
      } else {
        updatedSelectedFilterData = selectedFilterData;
      }

      dispatchSaveCustomReport({
        selectedFilterData: updatedSelectedFilterData,
        newReportDetails: {
          ...reportDetails,
          isNewReport: isNewReport || isSaveExistingReportAsNew,
          savedReportViewId,
        },
      });
    }
  };

  const onChangeReportOption = (event) => {
    reportSaveOptions.forEach((item) => {
      item.isSelected = item.value === event.target.value;
    });
    setReportSaveOptions([...reportSaveOptions]);
  };

  const onReportNameChange = (event) => {
    const { value: reportName } = event.target;
    setNewReportDetails((prevState) => ({
      ...prevState,
      reportName: reportName.trimStart(),
    }));
  };

  const onReportDescrChange = (event) => {
    const { value: description } = event.target;
    setNewReportDetails((prevState) => ({ ...prevState, description }));
  };

  const snackBarMsgProps = useMemo(
    () =>
      !isEmpty(savedReportFailedSnackbarMsg)
        ? { labels, ...savedReportFailedSnackbarMsg }
        : {},
    [savedReportFailedSnackbarMsg]
  );

  return (
    <Modal
      customClass={cn('save-report-modal', {
        'save-report-modal--loading': saveCustomReportPendingFlag,
      })}
      open={true}
      overrideCloseHandler={onCloseModal}
      suppressScrollOnActive={true}
      testId='save-report-modal'
    >
      <div className=''>
        <h2 className='save-report-modal__header'>{t('tkSaveToMyReports')}</h2>
        <Conditional condition={saveCustomReportPendingFlag}>
          <ThreeSquaresLoader />
        </Conditional>
        <div className={cn('save-report-modal__body')}>
          <TransitionGroup component={null}>
            <CSSTransition
              in={true}
              appear={true}
              classNames='snackbar__transition'
              timeout={Constants.CSS_DURATION_SLOTH}
            >
              <Snackbar {...snackBarMsgProps} />
            </CSSTransition>
          </TransitionGroup>
          <div className='save-report__container'>
            <Conditional condition={isNewReport}>
              <NewReportDetails
                newReportDetails={newReportDetails}
                onReportNameChange={onReportNameChange}
                onReportDescrChange={onReportDescrChange}
                customReportCrudResponse={customReportCrudResponse}
              />
              <>
                <div className='save-report__override'>
                  <RadioButton
                    id={option1.value}
                    changeHandler={onChangeReportOption}
                    label={option1.label}
                    value={option1.value}
                    key={option1.value}
                    isChecked={option1.isSelected}
                  />
                  <span className='report-name'>
                    {`'${existingReportName}'`}
                  </span>
                </div>
                <div className='save-report__existing'>
                  <RadioButton
                    id={option2.value}
                    changeHandler={onChangeReportOption}
                    label={option2.label}
                    value={option2.value}
                    key={option2.value}
                    isChecked={option2.isSelected}
                  />
                  <Conditional condition={option2.isSelected}>
                    <NewReportDetails
                      newReportDetails={newReportDetails}
                      onReportNameChange={onReportNameChange}
                      onReportDescrChange={onReportDescrChange}
                      customReportCrudResponse={customReportCrudResponse}
                    />
                  </Conditional>
                </div>
              </>
            </Conditional>
          </div>
          <div className='save-report-modal__footer'>
            <Button
              clickHandler={onCloseModal}
              isDisabled={saveCustomReportPendingFlag}
              customClass='button-secondary-small'
              label={t('tkCancel')}
            />
            <Button
              clickHandler={onHandleApply}
              isDisabled={saveCustomReportPendingFlag}
              customClass='button-primary-small'
              label={t('tkSave')}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

SaveReportModal.propTypes = {
  selectedSavedReportData: PropTypes.array,
  mySavedReports: PropTypes.array,
  handleClose: PropTypes.func,
  dispatchSaveCustomReport: PropTypes.func,
  selectedFilterData: PropTypes.object,
  customReportCrudResponse: PropTypes.object,
  saveCustomReportPendingFlag: PropTypes.bool,
  setCustomReportCrudResponse: PropTypes.func,
  savedReportFailedSnackbarMsg: PropTypes.object,
  labels: PropTypes.object,
  initialReportData: PropTypes.object,
  filterConfig: PropTypes.object,
  defaultAdditionalFilters: PropTypes.object,
};
