// tslint:disable-next-line: import-name
import { Logger } from './logger';

/**
 * @see https://wicg.github.io/ResizeObserver/
 */
export class UiToolkitResizeObserver {
    public callback: any;
    public resizeObserver: any;

    constructor(target: any, callback: any) {
        this.callback = callback;
        this.observe(target);
    }

    public observe(target: any) {
        this.resizeObserver = this.resizeObserver || UiToolkitResizeObserver.create(this.callback);
        if (this.resizeObserver) {
            this.resizeObserver.observe(target);
        }
    }

    public unobserve(target: any = null) {
        if (this.resizeObserver) {
            if (target) {
                this.resizeObserver.unobserve(target);
            } else {
                this.resizeObserver.disconnect();
            }
        }
    }

    public static create(callback: any) {
        const win = window as any;
        if (win.ResizeObserver) {
            return new win.ResizeObserver(callback);
        }
        Logger.warn(
            [
                'Warning: Components of this application depend upon ResizeObserver, which is undefined in this browser.',
                'If you are the application developer, please install a polyfill:',
                '  jsb npm i -S resize-observer-polyfill',
                'Import the polyfill into your application:',
                "  import ResizeObserver from 'resize-observer-polyfill';",
                '  window.ResizeObserver = window.ResizeObserver || ResizeObserver;',
            ].join('\n')
        );
        return null;
    }
}
