import { PropsWithChildren, useContext, FC } from 'react';
import {
    lightTheme,
    ThemeContainerCommonProps,
    Theme,
    generateCssVars,
} from '@gs-ux-uitoolkit-common/theme';
import { ThemeContext } from './theme-context';
import { styled } from '@gs-ux-uitoolkit-react/style';

export interface ThemeContainerProps extends ThemeContainerCommonProps {
    generateCssVars?: boolean;
}

const CssVarContainer = styled(
    'div',
    ({
        theme,
        generateCssVars: shouldGenerateCssVars,
    }: {
        theme: Theme;
        generateCssVars?: boolean;
    }) => {
        const cssVars = shouldGenerateCssVars ? generateCssVars(theme) : {};
        return {
            background: theme.surface.primary as string,
            color: theme.text.primary as string,
            ...cssVars,
        };
    }
);

export const ThemeContainer: FC<PropsWithChildren<ThemeContainerProps>> = ({
    theme,
    children,
    className,
    generateCssVars,
}) => {
    const contextTheme = useContext(ThemeContext);
    const themeValue = theme || contextTheme || lightTheme;
    return (
        <ThemeContext.Provider value={themeValue}>
            <CssVarContainer
                theme={themeValue}
                generateCssVars={generateCssVars}
                data-gs-uitk-component="theme-container"
                className={className}
            >
                {children}
            </CssVarContainer>
        </ThemeContext.Provider>
    );
};
