import { ComponentProps, CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { Bordered, Elevation, Size } from '@gs-ux-uitoolkit-common/design-system';

export interface CardProps<StyleType = CommonStyleType> extends ComponentProps<StyleType> {
    /**
     * Adds a border to the Card.
     */
    bordered?: Bordered;

    /**
     * Elevation of the Card.
     */
    elevation?: CardElevation;

    /**
     * Size of the Card.
     */
    size?: CardSize;
}

export type CardSize = Extract<Size, 'sm' | 'md' | 'lg'>;
export const cardSizes: CardSize[] = ['sm', 'md', 'lg'];

export type CardElevation = Extract<
    Elevation,
    '00' | '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08'
>;
export const cardElevations: CardElevation[] = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
];

export type CardDefaultProps = Required<Pick<CardProps, 'bordered' | 'elevation' | 'size'>>;

export const cardDefaultProps: CardDefaultProps = {
    bordered: false,
    elevation: '02',
    size: 'md',
};
