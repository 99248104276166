import { useState, useCallback } from 'react';

const useHelpGuide = (helpGuideArray) => {
  const [helpGuideActive, setHelpGuideActive] = useState(true);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const handlePreviousBtn = useCallback(
    (idx) => {
      const matchedIndex = helpGuideArray.findIndex(
        (el) => el.fieldName === idx
      );
      if (matchedIndex === helpGuideArray[0].index) {
        setSelectedRowData(helpGuideArray[helpGuideArray.length - 1]);
      } else {
        setSelectedRowData(helpGuideArray[matchedIndex - 1]);
      }
    },
    [helpGuideArray]
  );

  const handleNextBtn = useCallback(
    (idx) => {
      const matchedIndex = helpGuideArray.findIndex(
        (el) => el.fieldName === idx
      );
      if (matchedIndex === helpGuideArray.length - 1) {
        setSelectedRowData(helpGuideArray[0]);
      } else {
        setSelectedRowData(helpGuideArray[matchedIndex + 1]);
      }
    },
    [helpGuideArray]
  );

  const setSelectedRowDataById = useCallback(
    (label) => {
      const config = helpGuideArray.find((el) => el.fieldName === label);
      setSelectedRowData(config);
    },
    [helpGuideArray]
  );

  const closeHelpGuide = useCallback(() => {
    setSelectedRowData(null);
  }, []);

  const toggleHelpGuideActive = useCallback((active) => {
    setSelectedRowData(null);
    setHelpGuideActive(active);
  }, []);

  return {
    selectedRowData,
    helpGuideActive,
    toggleHelpGuideActive,
    closeHelpGuide,
    setSelectedRowDataById,
    handleNextBtn,
    handlePreviousBtn,
  };
};

export default useHelpGuide;
