import {
    colors,
    TypographyColor,
    ColorValue,
    TypographyVariant,
    FontStyle,
    typographyFontFamilyVariants,
    typographyFonts,
} from '@gs-ux-uitoolkit-common/design-system';
import { CssProperties } from '@gs-ux-uitoolkit-common/style';
import { Theme, ThemeTypographyVariant } from '@gs-ux-uitoolkit-common/theme';
import { TypographyCommonProps } from './typography-common-props';

export function getTextColor(props: {
    theme: Theme;
    color: TypographyCommonProps['color'];
}): CssProperties['color'] {
    const { theme, color } = props;
    const typographyColor = theme.text[color as TypographyColor];

    if (typographyColor != null) {
        return typographyColor;
    } else {
        return colors[color as ColorValue];
    }
}

export function getBrowserResetStyles(): CssProperties {
    return {
        marginTop: '0px', // reset browser margins
        marginBottom: '0px', // reset browser margins
    };
}

export function getFontStyles(args: {
    theme: Theme;
    typography: ThemeTypographyVariant;
    bold: TypographyCommonProps['bold'];
    italic: TypographyCommonProps['italic'];
}): TypographyVariant {
    const { typography, bold, italic, theme } = args;
    const font = typographyFonts[typography];

    let fontStyle: FontStyle = 'regular';
    if (bold && italic) {
        fontStyle = 'boldItalic';
    } else if (bold) {
        fontStyle = 'bold';
    } else if (italic) {
        fontStyle = 'italic';
    }

    return {
        ...theme.typography[typography],
        ...typographyFontFamilyVariants[font][fontStyle],
    };
}
