/* eslint-disable jsx-a11y/no-autofocus */
// TODO: https://jira.site.gs.com/browse/UX-14773

import { Component, Context } from 'react';
import { connect, ReactReduxContextValue } from 'react-redux';
import { WidgetContentCommonProps } from '@gs-ux-uitoolkit-common/datacore';
import { Dropdown, DropdownButton, DropdownMenu, MenuOption } from '@gs-ux-uitoolkit-react/input';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { Button } from '@gs-ux-uitoolkit-react/button';
import { CreateExportByType } from '../../../redux/actions/exports-action';
import { ExportType } from '../export';
import { OpenGridConfiguration } from '../../../redux/actions/grid-configuration-action';
import { Plugins } from '../../plugin-enum';

interface ExportWidgetComponentReduxProps {
    createExportByType: (exportType: ExportType) => void;
    openModalScreen: () => void;
}

export interface ExportWidgetComponentProps
    extends ExportWidgetComponentReduxProps,
        WidgetContentCommonProps {}

export interface ExportWidgetState {
    dropdownIsOpen: boolean;
}

/**
 * The Export Plugin Widget Component
 */
export class ExportWidgetComponent extends Component<
    ExportWidgetComponentProps,
    ExportWidgetState
> {
    constructor(props: ExportWidgetComponentProps) {
        super(props);
        this.state = {
            dropdownIsOpen: false,
        };
    }
    public render() {
        return (
            <div className="uitk-toolbar-export-widget">
                <Dropdown
                    style={{ marginRight: '8px' }}
                    size={this.props.widgetContentCommonProps?.size || 'sm'}
                    menuVisible={this.state.dropdownIsOpen}
                >
                    <Button
                        size={this.props.widgetContentCommonProps?.size || 'sm'}
                        emphasis="minimal"
                        onClick={() =>
                            this.setState({
                                dropdownIsOpen: !this.state.dropdownIsOpen,
                            })
                        }
                    >
                        <Icon name="download" type="outlined"></Icon>
                    </Button>
                    <DropdownButton
                        style={{
                            display: 'none',
                        }}
                    >
                        <Icon name="download" type="outlined"></Icon>{' '}
                    </DropdownButton>
                    <DropdownMenu
                        onBlur={() => this.setState({ dropdownIsOpen: false })}
                        value=""
                        autoFocus={false}
                    >
                        <MenuOption
                            value="xlsx"
                            onClick={() => this.props.createExportByType(ExportType.Excel)}
                        >
                            XLSX
                        </MenuOption>
                        <MenuOption
                            value="csv"
                            onClick={() => this.props.createExportByType(ExportType.Csv)}
                        >
                            CSV
                        </MenuOption>
                        <MenuOption value="settings" onClick={this.props.openModalScreen}>
                            Export Settings
                        </MenuOption>
                    </DropdownMenu>
                </Dropdown>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch: (action: any) => void) => {
    return {
        createExportByType: (exportType: ExportType) => dispatch(CreateExportByType(exportType)),
        openModalScreen: () => dispatch(OpenGridConfiguration(Plugins.ExportPlugin)),
    };
};

export const getExportWidget = (context?: Context<ReactReduxContextValue>) =>
    connect(mapStateToProps, mapDispatchToProps, null, { context })(ExportWidgetComponent);
