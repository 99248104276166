import { StyleSheet, CssClasses } from '@gs-ux-uitoolkit-react/style';
import { lightTheme, Theme } from '@gs-ux-uitoolkit-react/theme';

export const quickfilterStyleSheet = new StyleSheet(
    'gs-uitk-enhanced-quick-filter-container',
    ({ theme }: { theme: Theme }) => {
        const themeObject = theme || lightTheme;
        return {
            root: {
                margin: '0.5rem',
                color: themeObject.text.primary,
                '.gs-uitk-enhanced-quick-filter-column-filters-name-container': {
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '0.25rem',
                    '.gs-uitk-enhanced-quick-filter-column-filters-name': {
                        marginLeft: '0.5rem',
                    },
                },
            },
            '.gs-uitk-quick-filter-shortcut': {
                padding: '0.75rem',
                '& > *': {
                    padding: '0 0 0.5rem',
                },
                '.button-span-group': {
                    display: 'flex',
                    padding: 0,
                    '.gs-uitk-create-button-container': {
                        marginRight: '0.5rem',
                        '& > span ': {
                            width: '100%',
                        },
                    },
                    '& > *': {
                        flex: 1,
                    },
                },
            },
            '.gs-uitk-quick-filter-plugin': {
                flex: 1,
                height: 'calc(100% - 70px)',
                '.column-name-column': {
                    width: '20%',
                },
                '.list-column': {
                    width: '45%',
                },
                '.enable-filter': {
                    width: '10%',
                },
                '.gs-alert': {
                    zIndex: 9999,
                    position: 'sticky',
                    top: 0,
                },
                '.gs-tabs': {
                    '.tab-content': {
                        overflow: 'scroll',
                    },
                },
            },
        };
    }
);

export const getQuickFilterPluginClassNames = (props: {
    cssClasses: CssClasses<typeof quickfilterStyleSheet>;
}): string => {
    return props.cssClasses['.gs-uitk-quick-filter-plugin'];
};

export const getQuickFilterShortcutClassNames = (props: {
    cssClasses: CssClasses<typeof quickfilterStyleSheet>;
}): string => {
    return props.cssClasses['.gs-uitk-quick-filter-shortcut'];
};
