import {
    CSSProperties,
    ReactNode,
    useMemo,
    useContext,
    FunctionComponent,
    ElementType,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
    FormContext as FormCommonContext,
    defaultFormContext,
    FormGroupProps as FormGroupCommonProps,
    defaultFormGroupProps,
    formGroupStyleSheet,
    formSizes,
    formStatuses,
    updateContext,
    getFormGroupClassNames,
} from '@gs-ux-uitoolkit-common/form';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { FormContext } from './form-context';
import { componentAnalytics } from './analytics-tracking';

export interface FormGroupProps extends FormGroupCommonProps<CSSProperties> {
    children?: ReactNode;

    /**
     * Specify the container HTML tag. Default is `div`.
     */
    tag?: string;
}

/**
 * FormGroup provides appropriate spacing and grouping for a set of interactive
 * controls inside a Form.
 */
export const FormGroup: FunctionComponent<FormGroupProps> = (props: FormGroupProps) => {
    const {
        children,
        className,
        disabled,
        inline,
        size = defaultFormGroupProps.size,
        status,
        tag = 'div',
        ...otherProps
    } = props;

    const parentFormContext = useContext(FormContext);

    // A useMemo hook over here prevents the updateContext from being called when there are prop
    // changes that doesn't necessarily demand a change to the context. The dependency on
    // parentFormContext's disabled, inline and status has been explicitly mentioned as we want
    // to force a context update with the latest values from the form group's disabled, inline
    // and status
    const formContext: FormCommonContext = useMemo(
        () =>
            updateContext([
                defaultFormContext,
                {
                    ...parentFormContext,
                    disabled: parentFormContext.disabled,
                    inline: parentFormContext.inline,
                    status: parentFormContext.status,
                    size: size !== undefined ? size : parentFormContext.size,
                },
                { disabled, inline, status },
            ]),
        [disabled, inline, status, parentFormContext, size]
    );
    const theme = useTheme();
    const isInline = inline != null ? inline : !!parentFormContext.inline;
    const isDisabled = disabled != null ? disabled : !!parentFormContext.disabled;
    const cssClasses: any = useStyleSheet(formGroupStyleSheet, {
        theme: theme,
        inline: isInline,
        size: size!,
    });
    const containerClasses = getFormGroupClassNames({ className, cssClasses });

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({
            componentName: 'form-group',
            officialComponentName: 'other',
        });
    }, []); // Only run once

    const Tag = (tag || 'div') as ElementType<FormGroupProps>;
    return (
        <FormContext.Provider value={formContext}>
            <Tag
                data-gs-uitk-component="form-group"
                data-inline={isInline}
                data-disabled={isDisabled}
                className={containerClasses}
                {...otherProps}
            >
                {children}
            </Tag>
        </FormContext.Provider>
    );
};

FormGroup.propTypes = {
    disabled: PropTypes.bool,
    inline: PropTypes.bool,
    tag: PropTypes.string,
    size: PropTypes.oneOf(formSizes),
    status: PropTypes.oneOf(formStatuses),
};
