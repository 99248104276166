import { ListGroup } from '@gs-ux-uitoolkit-react/list-group';
import { Container, Row } from '@gs-ux-uitoolkit-react/layout';
import { Checkbox } from '@gs-ux-uitoolkit-react/checkbox';
import { Input } from '@gs-ux-uitoolkit-react/input';
import { Component, FormEvent } from 'react';
import { listSelectorStyleSheet } from '../../../../style/component/list-selector-stylesheet';

export interface ListSelectorProps {
    selectedValues: string[];
    valueList: string[];
    onAddValues: (newValues: string[]) => void;
    onRemoveValues: (removedValues: string[]) => void;
    consolidateValueList: boolean;
    searchLabel?: string;
    sort: boolean;
}
export interface CustomSortListSelectorState {
    searchValue: string;
}

export class CustomSortListSelector extends Component<
    ListSelectorProps,
    CustomSortListSelectorState
> {
    constructor(props: ListSelectorProps) {
        super(props);
        this.state = { searchValue: '' };
    }
    public componentWillUnmount() {
        listSelectorStyleSheet.unmount(this);
    }
    public render() {
        const cssClasses = listSelectorStyleSheet.mount(this, null);

        let consolidatedValues: string[] = [...this.props.valueList];
        if (this.props.consolidateValueList) {
            // we add the selected valueList that are not part of the valueList
            consolidatedValues = consolidatedValues.concat(
                this.props.selectedValues.filter(x => !consolidatedValues.find(y => y === x))
            );
        }
        if (this.props.sort) {
            // we sort the list now
            consolidatedValues.sort();
        }
        const searchResults = consolidatedValues.filter(x =>
            x.toLowerCase().includes(this.state.searchValue.toLowerCase())
        );

        const values = searchResults.map((value, index) => {
            const className = 'gs-uitk-list-selector-checkbox-div';
            return (
                <Checkbox
                    size="sm"
                    className={className}
                    key={`item-${value}-${index}`}
                    tabIndex={-1}
                    checked={this.props.selectedValues.includes(value)}
                    onChange={() => this.onItemClicked(value)}
                >
                    {value}
                </Checkbox>
            );
        });

        return (
            <Container className={`gs-uitk-list-selector ${cssClasses.root}`}>
                <Row>
                    <Input
                        size="sm"
                        type="search"
                        placeholder={'Filter Values'}
                        className="list-selector-search-input"
                        onChange={(event: FormEvent<HTMLInputElement>) =>
                            this.onSearchChanged(event)
                        }
                    />
                </Row>

                <Row className="gs-uitk-list-selector-values-row">
                    <ListGroup>
                        {searchResults.length !== 0 ? (
                            <div className="gs-uitk-select-all-checkbox-div" key={`item-addAll`}>
                                <Checkbox
                                    size="sm"
                                    checked={
                                        this.props.selectedValues.length !== 0 &&
                                        !searchResults.every(val =>
                                            this.props.selectedValues.includes(val)
                                        )
                                            ? 'indeterminate'
                                            : searchResults.every(val =>
                                                  this.props.selectedValues.includes(val)
                                              )
                                    }
                                    onChange={() => {
                                        this.addAll(searchResults);
                                    }}
                                >
                                    Add all
                                </Checkbox>
                            </div>
                        ) : null}
                        {values}
                    </ListGroup>
                </Row>
            </Container>
        );
    }

    private onItemClicked(value: string) {
        const selected = this.props.selectedValues.includes(value);
        selected ? this.props.onRemoveValues([value]) : this.props.onAddValues([value]);
    }

    private addAll(values: string[]) {
        const selected = this.props.selectedValues.length !== 0;
        selected ? this.props.onRemoveValues(values) : this.props.onAddValues(values);
    }

    private onSearchChanged(event: FormEvent<HTMLInputElement>) {
        this.setState({ searchValue: event.currentTarget.value });
    }
}
