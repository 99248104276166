/**
 * Utility function used mainly in unit testing that will make sure the `value`
 * that is passed to the function is non-nullable (i.e. not null or undefined),
 * or else will throw an error with the provided `errorMessage`.
 *
 * @param value The value to check for non-null or undefined
 * @param errorMessage The error message to throw if the `value` was not truthy.
 */
export function nonNullableValueOrThrow<T>(value: T, errorMessage: string): NonNullable<T> {
    if (value == null) {
        throw new Error(errorMessage);
    } else {
        return value as NonNullable<T>;
    }
}
