import { useCallback } from 'react';
import useUserMaintenance from '../../context';
import useFetch from '../useFetch';
import useLoader from '../useLoader';
import endPointsMapper from '../../../../../configs/endPointsMapper';
import { customizedAccountsAgTree } from '../../utils/accountsTree';
import { EXTERNAL_USER_ACCOUNTS, UPDATE_ORGANIZATION_ACCOUNTS } from '../../actions';

export default function useGetOrganizationAccounts() {
  const {
    state: {
      userDetails: { isMosaicInternal },
      activeAccountsType,
    },
    dispatch,
    storeValues: { isInternalUser },
  } = useUserMaintenance();

  const onSuccess = useCallback(
    response => {
      if (isInternalUser && isMosaicInternal) {
        dispatch({
          type: UPDATE_ORGANIZATION_ACCOUNTS,
          payload: {
            operation: 'ADD',
            organizationId: response.id,
            accountDetails: customizedAccountsAgTree(response.accountDetails),
          },
        });
      } else {
        dispatch({
          type: EXTERNAL_USER_ACCOUNTS,
          payload: { key: activeAccountsType, accountDetails: customizedAccountsAgTree(response.accountDetails) },
        });
      }
    },
    [isInternalUser, isMosaicInternal, activeAccountsType]
  );

  const { doFetch: getOrganizationAccounts, loading } = useFetch(endPointsMapper.FETCH_ORGANIZATION_ACCOUNTS, onSuccess);

  useLoader(loading);

  return { getOrganizationAccounts };
}
