import { CSSProperties, HTMLAttributes, ReactNode, FunctionComponent, useContext } from 'react';
import {
    ModalHeaderProps as ModalHeaderCommonProps,
    modalHeaderDefaultProps,
    modalHeaderStyleSheet,
    getModalHeaderClasses,
    getModalHeaderTitleContainerClasses,
    getModalHeaderSubtitleClasses,
    getModalHeaderTitleClasses,
    getModalHeaderCloseButtonClasses,
} from '@gs-ux-uitoolkit-common/modal';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { ModalSizeContext } from './modal-size-context';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';

export interface ModalHeaderProps
    extends ModalHeaderCommonProps<CSSProperties>,
        HTMLAttributes<HTMLElement> {
    /**
     * Content to display inside the Modal Header
     */
    children?: ReactNode;

    /**
     * Content to display for the subtitle of the Modal Header
     */
    subtitle?: ReactNode;
}

/**
 * Provides appropriate spacing around the header section of the Modal's content.
 */
export const ModalHeader: FunctionComponent<ModalHeaderProps> = ({
    className,
    classes: overrideClasses,
    children,
    dismissible = modalHeaderDefaultProps.dismissible,
    size,
    subtitle,
    onDismissButtonClick,
    ...attributes
}) => {
    const sizeContext = useContext(ModalSizeContext);
    const theme = useTheme();
    const cssClasses = useStyleSheet(modalHeaderStyleSheet, { theme, size: size || sizeContext });
    return (
        <div
            {...attributes}
            data-gs-uitk-component="modal-header"
            className={getModalHeaderClasses({ cssClasses, className, overrideClasses })}
        >
            <div
                data-cy="gs-uitk-modal-header__title-container"
                className={getModalHeaderTitleContainerClasses({ cssClasses, overrideClasses })}
            >
                {subtitle && (
                    <div
                        data-cy="gs-uitk-modal-header__subtitle"
                        className={getModalHeaderSubtitleClasses({ cssClasses, overrideClasses })}
                    >
                        {subtitle}
                    </div>
                )}
                {children && (
                    <h5
                        data-cy="gs-uitk-modal-header__title"
                        className={getModalHeaderTitleClasses({ cssClasses, overrideClasses })}
                    >
                        {children}
                    </h5>
                )}
            </div>
            {dismissible && (
                <button
                    data-cy="gs-uitk-modal-header__dismiss-button"
                    type="button"
                    onClick={onDismissButtonClick}
                    className={getModalHeaderCloseButtonClasses({ cssClasses, overrideClasses })}
                    aria-label="Dismiss Modal"
                >
                    <Icon aria-hidden="true" name="close" type="filled" />
                </button>
            )}
        </div>
    );
};
