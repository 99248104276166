import React from "react";
import PropTypes from "prop-types";
import {Select} from "@gs-ux-uitoolkit-react/select";
import {DROPDOWN, OPTIONAL} from "../../constants";
import '../AmountInput/index.scss'

const Dropdown = ({value, field, handleEditFields, options}) => {
  return (
    <Select
      searchable={false}
      options={options}
      onChange={(val) =>
        handleEditFields(val.selectedValue, field, DROPDOWN)
      }
      defaultValue={value}
      clearable={true}
      className='fund-rebate-edit'
      size='sm'
      placeholder={OPTIONAL}
    />
  );
};

Dropdown.propTypes = {
  value:  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  field: PropTypes.string,
  handleEditFields: PropTypes.func,
  options: PropTypes.array
};


export default Dropdown;
