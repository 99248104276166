import {
    ForwardRefExoticComponent,
    FunctionComponent,
    PropsWithChildren,
    memo,
    RefObject,
    useEffect,
    useState,
    Children,
} from 'react';
import {
    PopoverProps as CommonPopoverProps,
    popoverStyleSheet,
    defaultPopoverProps,
    getPopoverRootClasses,
    getPopoverCloseButtonClasses,
    PopoverCssClasses,
    disableFocusOnAllChildren,
} from '@gs-ux-uitoolkit-common/popover';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import TippyImpl, { TippyProps } from '@tippyjs/react';
import { ReactComponentProps } from '@gs-ux-uitoolkit-react/component';
import {
    GsPopoverInputs,
    createTippyOptions,
    tippyStyleSheet,
} from '@gs-ux-uitoolkit-common/popover-base';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { isRefObject, splitDataAttributes } from '@gs-ux-uitoolkit-react/shared';
import { componentAnalytics } from './analytics-tracking';
import { uniqueId } from 'gs-uitk-lodash';

// Hack for '@tippyjs/react': in the ESM module, the default export is the
// Tippy component itself. In the commonjs (UMD) export, there's a `.default`
// property, which is used when including Popover in a Jest or Vitest test
// (Vitest uses the UMD export because there's only "main" and "module"
// properties in its package.json, and Node.js will never read the "module"
// property)
const Tippy: ForwardRefExoticComponent<TippyProps> = (TippyImpl as any).default || TippyImpl;

// shared by both `Popover` and `PopoverTarget`
// className is defined in common props with its own jsDoc comment
export interface CommonReactPopoverProps
    extends CommonPopoverProps,
        Omit<ReactComponentProps, 'className'> {
    /**
     * callback method triggered when popover is shown
     */
    onShow?: () => void;

    /**
     * callback method triggered when popover is hidden
     */
    onHide?: () => void;

    /**
     * callback method triggered when popover is about to be shown
     */
    onBeforeShow?: () => boolean | void;

    /**
     * callback method triggered when popover is about to be hidden
     */
    onBeforeHide?: () => boolean | void;

    /**
     * callback method triggered when user clicks outside of the popover
     */
    onClickOutside?: () => boolean | void;
}

export interface PopoverProps extends CommonReactPopoverProps {
    /**
     * Host element that triggers the popover
     */
    target: string | HTMLElement | RefObject<HTMLElement>;
}

/**
 * Popovers display informative text in temporary windows.
 * This requires a target element to bind the popover to.
 * See the 'PopoverTarget' variation (recommended) which allows you to have
 * the target defined as a child element, eliminating the need
 * for defining an id for target.
 */
export const Popover: FunctionComponent<PropsWithChildren<PopoverProps>> = memo(props => {
    const theme = useTheme();
    const [target, setTarget] = useState<HTMLElement | RefObject<HTMLElement> | null>(null);
    const [id] = useState(() => uniqueId('gs-uitk-popover-'));
    const cssClasses = useStyleSheet(popoverStyleSheet, { size: props.size, theme });
    const tippyCssClasses = useStyleSheet(tippyStyleSheet, { theme: theme });

    useTheme(); // not consuming the theme just yet, but injects the fonts into the DOM
    useEffect(() => {
        let hostEl: HTMLElement | null = null;
        // get target host element; props.target can be string, element or a RefObject
        if (isRefObject(props.target)) {
            if (props.target.current) {
                hostEl = props.target.current;
                setTarget(props.target);
            }
        } else {
            let targetEl: HTMLElement | null;
            if (typeof props.target === 'string') {
                // if string, it can be a CSS selector or an id
                targetEl = document.querySelector(props.target);
                if (!targetEl) {
                    targetEl = document.querySelector(`#${props.target}`);
                }
            } else {
                targetEl = props.target;
            }

            if (targetEl) {
                // Makes target element focusable to open tooltip on keyboard navigation
                targetEl.setAttribute('tabindex', '0');
                targetEl.setAttribute('aria-live', 'polite');
                // Make childrens unfocusable to prevent double focus
                Array.from(targetEl.children).forEach(disableFocusOnAllChildren);
                setTarget(targetEl);
                hostEl = targetEl;
            } else {
                throw new Error(
                    `Popover: The target '${props.target}' could not be identified in the DOM. Tip: check spelling`
                );
            }
        }

        if (hostEl) {
            hostEl.setAttribute('data-gs-uitk-popover-id', id);
        }
    }, [props.target, id]);

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'popover' });
    }, []); // Only run once

    return (
        <>
            {target && (
                <Tippy
                    reference={target}
                    {...getTippyOptions(props, id, cssClasses)}
                    className={getPopoverRootClasses({
                        tippyCssClasses,
                        className: props.className,
                        overrideClasses: props.classes,
                    })}
                />
            )}
        </>
    );
});
Popover.displayName = 'Popover';

const getTippyOptions = (
    props: PropsWithChildren<PopoverProps>,
    id: string,
    cssClasses: PopoverCssClasses
): TippyProps => {
    const options: GsPopoverInputs = {
        id: id,
        size: props.size || defaultPopoverProps.size,
        placement: props.placement,
        showTip: props.showTip,
        hideDelay: props.hideDelay,
        showDelay: props.showDelay,
        dismissible: props.dismissible,
        flip: props.flip,
        fade: props.fade,
        container: props.container,
        classes: props.classes,
        triggers: props.triggers,
        visible: props.visible,
        onShow: props.onShow,
        onHide: props.onHide,
        onBeforeShow: props.onBeforeShow,
        onBeforeHide: props.onBeforeHide,
        onClickOutside: props.onClickOutside,
    };

    return {
        ...createTippyOptions('popover', options, cssClasses),
        content: <PopoverContent props={props} cssClasses={cssClasses} />,
        visible: props.visible,
    };
};

const PopoverContent = memo(
    ({
        props,
        cssClasses,
    }: {
        props: PropsWithChildren<PopoverProps>;
        cssClasses: PopoverCssClasses;
    }) => {
        const { children, dismissible, classes: overrideClasses, ...otherProps } = props;
        const { split: dataAttributeProps } = splitDataAttributes(otherProps);
        const content = Children.toArray(children);

        return (
            <>
                <span {...dataAttributeProps} style={{ display: 'contents' }}>
                    {dismissible && (
                        <button
                            data-cy="gs-uitk-popover__dismiss-button"
                            className={getPopoverCloseButtonClasses({
                                cssClasses,
                                overrideClasses,
                            })}
                            key={`gs-uitk-tooltip-dismiss-button-${uniqueId}`}
                        >
                            <Icon name="close" type="filled" />
                        </button>
                    )}
                    {content}
                </span>
            </>
        );
    }
);
PopoverContent.displayName = 'PopoverContent';
