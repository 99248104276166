import { useContext, CSSProperties, ReactNode, FC, memo } from 'react';
import PropTypes from 'prop-types';
import { cardStyleSheet, getCardTitleClasses } from '@gs-ux-uitoolkit-common/card';

import { ComponentProps } from '@gs-ux-uitoolkit-react/component';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { CardContext } from './card-context';
import { CardContainerContext } from './card-container';

export interface CardTitleProps extends ComponentProps<CSSProperties> {
    children?: ReactNode;
    /**
     * The HTML semantic tag to be used for title (preferably heading tag)
     */
    tag?: keyof JSX.IntrinsicElements;
}

/**
 * CardTitle styles the title of a Card appropriately.
 */
export const CardTitle: FC<CardTitleProps> = memo(props => {
    const { className, children, tag, ...attributes } = props;

    const theme = useTheme();
    const { iconPosition, size } = useContext(CardContext);
    const { dragAndDrop } = useContext(CardContainerContext);
    const cssClasses = useStyleSheet(cardStyleSheet, { theme, size, iconPosition, dragAndDrop });
    const HTMLTag = tag ? tag : 'div';
    return (
        <HTMLTag
            data-gs-uitk-component="card-title"
            {...attributes}
            className={getCardTitleClasses({ cssClasses, className })}
        >
            {children}
        </HTMLTag>
    );
});
CardTitle.displayName = 'CardTitle';

CardTitle.propTypes = {
    className: PropTypes.string,
};
