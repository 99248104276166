import {
    DataType,
    ExpressionCondition,
    ExpressionGroup,
    ExpressionOperator,
    ExpressionRule,
    ExpressionValue,
    FILTERABLE_SPECIAL_VALUES_MAP,
    genericExpressionHelper,
    ValueTypeRule,
} from '@gs-ux-uitoolkit-common/datacore';
import { Component } from 'react';
import { basicExpressionStyleSheet } from '../../../style/component/basic-expression-stylesheet';
import { AddExpressionButton } from '../add-expression-button';
import { Alert } from '@gs-ux-uitoolkit-react/alert';
import { ExpressionProps } from '../expression-props';
import { GridColumn } from '../../../grid-wrappers/grid-wrapper';
import { BasicExpressionColumnValueState } from '../../../redux/datagrid-state';
import { BasicExpressionRule } from '../basic-expression-rule';
import { uniqueId, debounce } from 'gs-uitk-lodash';

export enum CriteriaType {
    Ranges = 'Ranges',
    Values = 'Values',
    QuickFilter = 'Quick Filter',
}
interface UniqueRuleState {
    columnId: string;
    rules: {
        operator: string;
        values: string[];
    }[];
    dataType: DataType;
}
export interface RuleState {
    selectedColumn: GridColumn | null;
    operator: string | null;
    values: string[];
    ruleId: string;
}
export interface BasicExpressionState {
    rulesList: RuleState[];
}
export interface BasicExpressionProps extends ExpressionProps {
    getColumnValues: (columnId: string) => void;
    basicExpressionColumnValues: BasicExpressionColumnValueState[];
    isDra: boolean;
}
export class BasicExpression extends Component<BasicExpressionProps, BasicExpressionState> {
    constructor(props: BasicExpressionProps) {
        super(props);
        const rulesList = this.convertExpressionQueryToRuleList();
        this.state = {
            rulesList: rulesList.length
                ? rulesList
                : [
                      {
                          selectedColumn: null,
                          operator: null,
                          values: [],
                          ruleId: this.getUniqueRuleId(),
                      },
                  ],
        };
    }

    public componentWillUnmount() {
        basicExpressionStyleSheet.unmount(this);
    }
    public render() {
        const isBasicExpression = genericExpressionHelper.isBasicCompatibleExpression(
            this.props.expressionQuery,
            true
        );
        // should only have one level of sub rules

        if (!isBasicExpression) {
            return (
                <Alert status="error">
                    This expression is not compatible with the Basic Mode. Please switch to the
                    Advanced Mode.
                </Alert>
            );
        }

        const cssClasses = basicExpressionStyleSheet.mount(this, null);
        return (
            <div className={`${cssClasses.root}`}>
                {this.state.rulesList.map((ruleState: RuleState) => {
                    return (
                        <BasicExpressionRule
                            basicExpressionColumnValues={this.props.basicExpressionColumnValues}
                            columns={this.props.columns}
                            getColumnValues={this.props.getColumnValues}
                            ruleId={ruleState.ruleId}
                            key={ruleState.ruleId}
                            updateRuleDetails={this.updateRuleDetails}
                            deleteRule={this.deleteRule}
                            operator={ruleState.operator}
                            selectedColumn={ruleState.selectedColumn}
                            values={ruleState.values}
                            isDra={this.props.isDra}
                        ></BasicExpressionRule>
                    );
                })}
                <AddExpressionButton
                    data-cy={'gs-uitk-basic-expression-add-rule'}
                    onClickCallback={this.addNewRule}
                ></AddExpressionButton>
            </div>
        );
    }

    private convertExpressionQueryToRuleList() {
        const ruleList: RuleState[] = [];
        if (
            (this.props.expressionQuery as ExpressionGroup) &&
            (this.props.expressionQuery as ExpressionGroup).rules
        ) {
            (this.props.expressionQuery as ExpressionGroup).rules.forEach(topLevelRule => {
                const topLevelExpressionRule = topLevelRule as ExpressionGroup;

                if (!topLevelExpressionRule.rules) return;

                topLevelExpressionRule.rules.forEach(rule => {
                    const expressionRule = rule as ExpressionRule;
                    const selectedColumn =
                        this.props.columns.find(col => {
                            return col.columnId === expressionRule.field;
                        }) || null;
                    if (selectedColumn) {
                        const ruleState = ruleList.find(
                            item => item.selectedColumn?.columnId === expressionRule.field
                        );

                        let value = String(expressionRule.value);
                        // we need to handle the special values and convert them to the displayed values
                        const specialValue = FILTERABLE_SPECIAL_VALUES_MAP.find(
                            item => item.rawValue === expressionRule.value
                        );
                        if (specialValue) value = specialValue.displayedValue;

                        if (ruleState) {
                            ruleState.values.push(value);
                        } else {
                            ruleList.push({
                                selectedColumn,
                                operator: expressionRule.operator,
                                ruleId: this.getUniqueRuleId(),
                                values: [value],
                            });
                        }
                    }
                });
            });
        }

        return ruleList;
    }

    private getUniqueRuleId() {
        return uniqueId('rule-');
    }

    private updateRuleDetails = debounce(
        (ruleId: string, column: GridColumn | null, operator: string | null, values: string[]) => {
            let ruleIndex = 0;
            const ruleState = this.state.rulesList.find((rule, index) => {
                if (rule.ruleId === ruleId) {
                    ruleIndex = index;
                    return true;
                }
                return false;
            });

            if (ruleState) {
                ruleState.selectedColumn = column;
                ruleState.operator = operator;
                ruleState.values = values;

                const rulesList = this.state.rulesList;
                rulesList.splice(ruleIndex, 1, ruleState);
                this.setState({
                    rulesList: rulesList,
                });
            }

            const expressionQuery = BasicExpression.createExpressionQuery(this.state.rulesList);
            this.props.onChangeExpressionQuery(expressionQuery);
        },
        10
    );

    static createExpressionQuery(rulesList: RuleState[]): ExpressionGroup {
        const uniqueRules: UniqueRuleState[] = [];

        rulesList.forEach(ruleItem => {
            if (!ruleItem.selectedColumn || !ruleItem.operator) return;

            let uniqueRule = uniqueRules.find(
                uniqueRuleItem => ruleItem.selectedColumn?.columnId === uniqueRuleItem.columnId
            );
            if (!uniqueRule) {
                uniqueRule = {
                    columnId: ruleItem.selectedColumn.columnId,
                    dataType: ruleItem.selectedColumn.dataType,
                    rules: [],
                };

                uniqueRules.push(uniqueRule);
            }
            uniqueRule.rules.push({
                operator: ruleItem.operator,
                values: ruleItem.values,
            });
        });

        const queryExpression: ExpressionGroup = {
            condition: ExpressionCondition.And,
            rules: [],
        };

        queryExpression.rules = uniqueRules.map(uniqueRule => {
            const rules: ExpressionRule[] = [];

            uniqueRule.rules.forEach(rule => {
                rule.values.forEach(ruleValue => {
                    let value: ExpressionValue = ruleValue;
                    let valueTypeRule = ValueTypeRule.DisplayValue;

                    const isSpecialValue = FILTERABLE_SPECIAL_VALUES_MAP.some(
                        special => special.displayedValue === ruleValue
                    );

                    if (!isSpecialValue && uniqueRule.dataType === DataType.Number) {
                        value = Number(ruleValue);
                        valueTypeRule = ValueTypeRule.RawValue;
                    }

                    rules.push({
                        field: uniqueRule.columnId,
                        operator: rule.operator as ExpressionOperator,
                        value: value,
                        valueTypeRule: valueTypeRule,
                    });
                });
            });
            return {
                condition: ExpressionCondition.Or,
                rules: rules,
            };
        });

        return queryExpression;
    }

    private addNewRule = () => {
        this.setState({
            rulesList: [
                ...this.state.rulesList,
                {
                    selectedColumn: null,
                    operator: null,
                    values: [''],
                    ruleId: this.getUniqueRuleId(),
                },
            ],
        });
    };

    private deleteRule = (ruleId: string) => {
        const rulesList = this.state.rulesList.filter(rule => rule.ruleId !== ruleId);
        this.setState({
            rulesList: rulesList,
        });
        this.updateRuleDetails(ruleId, null, null, []);
    };
}
