import {
    ColumnHint,
    ColumnHintDefaults,
    HintList,
    DefaultColumnHintTypes,
} from '@gs-ux-uitoolkit-common/datacore';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as Redux from 'redux';
import { PluginHeader } from '../../../components/plugin-header/plugin-header';
import { ModalWithItems, ModalProps } from '../../../components/modal-props';
import { GridColumn } from '../../../grid-wrappers/grid-wrapper';
import {
    CancelEditedColumnHint,
    EditColumnHint,
    RemoveColumnHint,
    SaveEditedColumnHint,
    UpdateEditedColumnHint,
    NewColumnHint,
    RemoveDefaultColumnHint,
    EditDefaultColumnHint,
    UpdateEditedDefaultColumnHint,
    SaveEditedDefaultColumnHint,
    NewDefaultColumnHint,
} from '../../../redux/actions/column-hint-action';
import { DataGridState } from '../../../redux/datagrid-state';
import { ColumnHintConfigTable } from './components/column-hint-config-table';
import { ColumnHintTypePicker } from './components/column-hint-type-picker';
import { SelectChangeEvent } from '@gs-ux-uitoolkit-react/select';
import { ColumnHintFormatManager } from './components/column-hint-format-manager';
import { columnHintHelper, ColumnHintFormatTypes } from '../column-hint-helper';
import { PluginFooter } from '../../../components/plugin-footer/plugin-footer';
import { RadioChangeEvent } from '@gs-ux-uitoolkit-react/radio';
import { columnHintStylesheet } from '../../../style/plugins/column-hint-plugin-stylesheet';
import { ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';

/**
 * The props for the ColumnHintModal
 */
export interface ColumnHintModalProps extends ModalProps<ColumnHint>, ModalWithItems<ColumnHint> {
    /**
     * Columns from the grid
     */
    columns: GridColumn[];
    /**
     * Data for the currently editing column hint item
     */
    data: ColumnHint | null;
    /**
     * Default column hints
     */
    defaults?: ColumnHintDefaults;
    /**
     * Edited column hints
     */
    editedDefaults: ColumnHintDefaults | null;
    /**
     * When a defauly column hint is removed
     */
    removeDefaultColumnHint: (hintType: DefaultColumnHintTypes) => void;
    /**
     * When a default column hint is edited
     */
    editDefaultColumnHint: () => void;
    /**
     * When updating the default column hints
     */
    updateEditedDefaultHint: (hint: HintList, defaultType: DefaultColumnHintTypes) => void;
    /**
     * Save default hint
     */
    saveEditedDefaultHint: () => void;
    /**
     * New default column hint
     */
    newDefaultColumnHint: () => void;
}

export enum ColumnHintModalScreens {
    Default = 'Default',
    TypePicker = 'TypePicker',
    Format = 'Format',
}

export enum CreateButtonText {
    Create = 'Create',
    Update = 'Update',
}

export interface ColumnHintModalState {
    columnHintModalScreen: ColumnHintModalScreens;
    selectedTypeToFormat: ColumnHintFormatTypes | null;
    selectedColumn: string | null;
    createButtonDisplayName: CreateButtonText;
}

/**
 * * The main custom sort modal
 */
export class ColumnHintModalComponent extends Component<
    ColumnHintModalProps,
    ColumnHintModalState
> {
    constructor(props: ColumnHintModalProps) {
        super(props);
        this.state = {
            columnHintModalScreen: ColumnHintModalScreens.Default,
            createButtonDisplayName: CreateButtonText.Create,
            selectedColumn: null,
            selectedTypeToFormat: null,
        };
    }
    public componentWillUnmount() {
        columnHintStylesheet.unmount(this);
    }

    public render() {
        const subTitle = this.getSubtitle(this.state.columnHintModalScreen);
        const visibleColumns = this.props.columns.filter(column => !column.hidden);

        const { defaults, editedDefaults } = this.props;

        return (
            <ThemeConsumer>
                {theme => {
                    const cssClasses = columnHintStylesheet.mount(this, { theme });
                    return (
                        <Fragment>
                            <div className={cssClasses.root}>
                                <div className="config-items-container gs-uitk-column-hint-plugin">
                                    {this.state.columnHintModalScreen ===
                                    ColumnHintModalScreens.Default ? (
                                        <PluginHeader
                                            displayCreateButton={
                                                this.state.columnHintModalScreen ===
                                                ColumnHintModalScreens.Default
                                            }
                                            subTitle={subTitle}
                                            createButtonCallback={() =>
                                                this.navigateToColumnHintScreen(
                                                    ColumnHintModalScreens.TypePicker
                                                )
                                            }
                                        />
                                    ) : null}
                                    <div className="gs-uitk-plugin-content-container">
                                        {this.state.columnHintModalScreen ===
                                        ColumnHintModalScreens.Default ? (
                                            <ColumnHintConfigTable
                                                configItemList={this.props.configItemList}
                                                columns={visibleColumns}
                                                defaults={defaults || {}}
                                                removeConfigItem={this.props.removeConfigItem}
                                                editConfigItem={this.editConfigItem}
                                                removeDefaultColumnHint={
                                                    this.props.removeDefaultColumnHint
                                                }
                                                editDefaultColumnHint={this.editDefaultColumnHint}
                                            />
                                        ) : (
                                            <div className="gs-uitk-datagrid_column-hint_container">
                                                <p>
                                                    Create a new ruleset for column highlight and
                                                    formatting.{' '}
                                                </p>
                                                {this.state.columnHintModalScreen ===
                                                    ColumnHintModalScreens.TypePicker && (
                                                    <ColumnHintTypePicker
                                                        onRadioChanged={
                                                            this.onColumnHintTypeRadioChanged
                                                        }
                                                        columns={visibleColumns}
                                                        checkedRadio={
                                                            this.state.selectedTypeToFormat
                                                        }
                                                        cancel={this.cancelCreate}
                                                        continue={this.continueTypePicker}
                                                        selectedColumn={this.state.selectedColumn}
                                                        selectedColumnChanged={
                                                            this.selectedColumnChanged
                                                        }
                                                        specificColumnHints={
                                                            this.props.configItemList
                                                        }
                                                        defaultColumnHints={defaults || {}}
                                                    />
                                                )}
                                                {this.state.columnHintModalScreen ===
                                                    ColumnHintModalScreens.Format && (
                                                    <Fragment>
                                                        <ColumnHintFormatManager
                                                            selectedTypeToFormat={
                                                                this.state.selectedTypeToFormat
                                                            }
                                                            specificColumnToFormat={
                                                                this.state.selectedColumn
                                                            }
                                                            columns={visibleColumns}
                                                            defaultHints={editedDefaults || {}}
                                                            specificColumnHints={this.props.data}
                                                            onChangeHint={this.onChangeHint}
                                                        />
                                                    </Fragment>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {this.state.columnHintModalScreen === ColumnHintModalScreens.Format && (
                                <PluginFooter
                                    createButtonDisplayName={this.state.createButtonDisplayName}
                                    createActionType={'primary'}
                                    onCreate={this.finishCreate}
                                    onCancel={this.cancelCreate}
                                    createDisabled={false}
                                />
                            )}
                        </Fragment>
                    );
                }}
            </ThemeConsumer>
        );
    }

    private editConfigItem = (columnHint: ColumnHint) => {
        this.props.editConfigItem(columnHint);
        this.setState({
            columnHintModalScreen: ColumnHintModalScreens.Format,
            createButtonDisplayName: CreateButtonText.Update,
            selectedColumn: columnHintHelper.getPivotedColumnId(columnHint, this.props.columns),
            selectedTypeToFormat: ColumnHintFormatTypes.SpecificColumn,
        });
    };

    private editDefaultColumnHint = (hintType: DefaultColumnHintTypes) => {
        this.props.editDefaultColumnHint();

        let typeToFormat: ColumnHintFormatTypes = ColumnHintFormatTypes.StringColumns;

        switch (hintType) {
            case DefaultColumnHintTypes.String:
                typeToFormat = ColumnHintFormatTypes.StringColumns;
                break;
            case DefaultColumnHintTypes.Number:
                typeToFormat = ColumnHintFormatTypes.NumericColumns;
                break;
            case DefaultColumnHintTypes.Date:
                typeToFormat = ColumnHintFormatTypes.DateColumns;
                break;
            case DefaultColumnHintTypes.DateTime:
                typeToFormat = ColumnHintFormatTypes.DateTimeColumns;
                break;
            case DefaultColumnHintTypes.Default:
                typeToFormat = ColumnHintFormatTypes.AllColumns;
                break;
            default:
                typeToFormat = ColumnHintFormatTypes.StringColumns;
                break;
        }

        this.setState({
            columnHintModalScreen: ColumnHintModalScreens.Format,
            createButtonDisplayName: CreateButtonText.Update,
            selectedTypeToFormat: typeToFormat,
        });
    };

    private onChangeHint = (hint: HintList) => {
        // Config item is only for specific column
        if (this.state.selectedTypeToFormat === ColumnHintFormatTypes.SpecificColumn) {
            this.props.updateEditedConfigItem({ hints: hint });
        } else if (this.state.selectedTypeToFormat) {
            this.props.updateEditedDefaultHint(
                hint,
                columnHintHelper.selectedFormatToDefaultType(this.state.selectedTypeToFormat)
            );
        } else {
            throw new Error('Unknown type, onChangeHint');
        }
    };

    private selectedColumnChanged = (e: SelectChangeEvent) => {
        this.setState({ selectedColumn: e.selectedValue });
    };

    private getSubtitle = (screen: ColumnHintModalScreens): string => {
        switch (screen) {
            case ColumnHintModalScreens.Default:
                return 'Set rules and formatting for columns.';

            case ColumnHintModalScreens.TypePicker:
                return 'What would you like to format?';

            case ColumnHintModalScreens.Format:
                if (
                    this.state.selectedTypeToFormat === ColumnHintFormatTypes.SpecificColumn &&
                    this.state.selectedColumn
                ) {
                    const foundColumn = this.props.columns.find(
                        column => column.columnId === this.state.selectedColumn
                    );

                    if (foundColumn) {
                        return `Formatting for ${foundColumn.columnLabel} Column (${foundColumn.dataType} data type)`;
                    }
                    return `Formatting for ${this.state.selectedColumn} Column (Unknown data type)`;
                }
                return `Formatting for ${this.state.selectedTypeToFormat}`;

            default:
                return 'Current Column Hints';
        }
    };

    private onColumnHintTypeRadioChanged = (e: RadioChangeEvent) => {
        this.setState({
            selectedTypeToFormat: e.value as ColumnHintFormatTypes,
        });
    };

    private cancelCreate = () => {
        this.props.cancelEditedConfigItem();
        this.resetState();
    };

    private finishCreate = () => {
        if (this.state.selectedTypeToFormat === ColumnHintFormatTypes.SpecificColumn) {
            this.props.saveEditedConfigItem();
        } else if (this.state.selectedTypeToFormat) {
            this.props.saveEditedDefaultHint();
        } else {
            throw new Error('Unknown type, create/update');
        }

        this.resetState();
    };

    private continueTypePicker = () => {
        this.navigateToColumnHintScreen(ColumnHintModalScreens.Format);

        if (this.state.selectedTypeToFormat === ColumnHintFormatTypes.SpecificColumn) {
            // New configItem if it's column specific
            this.props.newConfigItem();
            if (this.state.selectedColumn) {
                this.props.updateEditedConfigItem({
                    columnId: this.getSelectedColumnId(),
                    pivotKeys: this.getPivotKeys(),
                });
            }
        } else {
            // must be creating a new default
            this.props.newDefaultColumnHint();
        }
    };

    private navigateToColumnHintScreen = (screen: ColumnHintModalScreens): void => {
        this.setState({
            columnHintModalScreen: screen,
        });
    };

    private resetState = () => {
        this.setState({
            columnHintModalScreen: ColumnHintModalScreens.Default,
            createButtonDisplayName: CreateButtonText.Create,
            selectedColumn: null,
            selectedTypeToFormat: null,
        });
    };
    private isSelectedColumnPivoted(): boolean {
        const { selectedColumn } = this.state;
        const gridColumn = this.props.columns.find(column => column.columnId === selectedColumn);
        return !!(gridColumn && gridColumn.pivotKeys && gridColumn.pivotKeys.length);
    }
    private getSelectedColumnId(): string {
        const { selectedColumn } = this.state;
        const gridColumn = this.props.columns.find(column => column.columnId === selectedColumn);
        if (!gridColumn) {
            return selectedColumn || '';
        }
        return this.isSelectedColumnPivoted() && gridColumn.primaryColumnId
            ? gridColumn.primaryColumnId
            : gridColumn.columnId;
    }
    private getPivotKeys(): string[] | undefined {
        const { selectedColumn } = this.state;
        const gridColumn = this.props.columns.find(column => column.columnId === selectedColumn);
        if (!gridColumn) {
            return undefined;
        }
        return this.isSelectedColumnPivoted() ? gridColumn.pivotKeys : undefined;
    }
}

const mapStateToProps = (state: DataGridState) => {
    return {
        columns: state.grid.columnList,
        configItemList: state.columnHint.configItemList,
        data: state.columnHint.editedItem,
        defaults: state.columnHint.defaults,
        editedDefaults: state.columnHint.editedDefaults,
    };
};

const mapDispatchToProps = (dispatch: (action: Redux.Action) => void) => {
    return {
        // Cancels both edited config item and default hints
        cancelEditedConfigItem: () => dispatch(CancelEditedColumnHint()),
        editConfigItem: (columnHint: ColumnHint) => dispatch(EditColumnHint(columnHint)),
        editDefaultColumnHint: () => dispatch(EditDefaultColumnHint()),
        newConfigItem: () => dispatch(NewColumnHint()),
        newDefaultColumnHint: () => dispatch(NewDefaultColumnHint()),
        removeConfigItem: (columnHint: ColumnHint) => dispatch(RemoveColumnHint(columnHint)),
        removeDefaultColumnHint: (hintType: DefaultColumnHintTypes) =>
            dispatch(RemoveDefaultColumnHint(hintType)),
        saveEditedConfigItem: () => dispatch(SaveEditedColumnHint()),
        saveEditedDefaultHint: () => dispatch(SaveEditedDefaultColumnHint()),
        updateEditedConfigItem: (newData: Partial<ColumnHint>) =>
            dispatch(UpdateEditedColumnHint(newData)),
        updateEditedDefaultHint: (hints: HintList, defaultType: DefaultColumnHintTypes) =>
            dispatch(UpdateEditedDefaultColumnHint(hints, defaultType)),
    };
};

export const ColumnHintPluginModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ColumnHintModalComponent);
