import { createContext } from 'react';
import { InputSize } from '@gs-ux-uitoolkit-common/input';

export interface InputIconContextProps {
    disabled: boolean;
    position: 'leading' | 'trailing';
    size: InputSize;
}

export const InputIconContext = createContext<InputIconContextProps>({
    disabled: false,
    position: 'leading',
    size: 'md',
});
