import { ComponentProps, CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { Size } from '@gs-ux-uitoolkit-common/design-system';
import { Emphasis } from '@gs-ux-uitoolkit-common/design-system';
import { LabelCssClasses } from './label-style-sheet';

export interface LabelProps<StyleType = CommonStyleType> extends ComponentProps<StyleType> {
    /**
     * Style classes to override.
     */
    classes?: Partial<LabelCssClasses>;

    /**
     * The html for property
     */
    for?: string;

    /**
     * Size of the label
     */
    size?: LabelSize;

    /**
     * Whether or not the label is disabled
     */
    disabled?: boolean;

    /**
     * By default, the component displays as "block" and expands to the full
     * width of its container. To disable this behavior, set to true.
     */
    inline?: boolean;

    /**
     * Visual emphasis of the button.
     */
    emphasis?: LabelEmphasis;
}

export type LabelSize = Extract<Size, 'sm' | 'md' | 'lg'>;
export const labelSizes: LabelSize[] = ['sm', 'md', 'lg'];

export type LabelEmphasis = Extract<Emphasis, 'bold' | 'subtle'>;
export const labelEmphasises: LabelEmphasis[] = ['bold', 'subtle'];
export const defaultLabelProps: DefaultLabelProps = {
    emphasis: 'subtle',
    inline: false,
    size: 'md',
    disabled: false,
};

export type DefaultLabelProps = Required<
    Pick<LabelProps, 'size' | 'inline' | 'emphasis' | 'disabled'>
>;
