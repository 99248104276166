import React from 'react';
import Logout from '../../containers/Logout';

const MainLogout = () => (
  <div className="viewport-container__login-view">
      <div className="login-container-cls">
        <Logout />
      </div>
    </div>
);

export default MainLogout;
