import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {PORTFOLIO_FILTER_STATE, popoverOffset, MODULES_KEYS} from '../../../../constants/pageConstants';
import FileDownloadModal from '../FileDownload';
import FilterButton from '../../../../components/core/FilterButton';
import {Conditional} from '../../../../components/core/Conditional';
import {dropdownViewNamesSelector, selectCollapseState, currentViewSelector, gridHeader, groupByColumns, disableFileDownload } from '../../../../selectors/pages/tradeActivity/currentTrades';
import translator  from '../../../../services/translator';
import Dropdown from '../../../../components/core/Dropdown';
import DownloadButton from '../../../../components/core/DownloadButton/DownloadButton';
import PopOver from '../../../../components/core/PopOver/index';
import SwitchButton from '../../../../components/core/SwitchButton/index';
import { filterVisibility, groupByFieldNew } from '../../../../modules/Grid/selector';
import { controlPanleMapDispatchToProps } from '../../../../modules/Grid/mapDispatchToProps';
import {allowPDFDownloadFeatureSelector} from "../../../../selectors/global";
const {translate: t} = translator;


export const ControlPanel = props => {

  const { columnDefs, groupByList, views, currentView, collapseState, groupByField, disableFileDownload, allowPDFDownload } = props;
  const [isActiveGroupCollapse, setIsActiveGroupCollapse] = useState(collapseState);

  useEffect(() => {
    if(groupByField !== 'none') setIsActiveGroupCollapse(collapseState); else setIsActiveGroupCollapse(false);
  }, [groupByField]);

  const handleToggleFilterVisibility = () => {
    const filterVisibilityData = props.filterVisibility === PORTFOLIO_FILTER_STATE.VISIBLE ? PORTFOLIO_FILTER_STATE.HIDDEN : PORTFOLIO_FILTER_STATE.VISIBLE;
    props.dispatchFilterVisibility(filterVisibilityData);
  };

  const handleGroupChange = (value, selectedItems) => {
    props.dispatchGroupChange({value, selectedItems, currentView});
  };


  const handleExpandCollapseToggle = state => {
    props.dispatchToggleGroupCollapse(state);
    setIsActiveGroupCollapse(state);
  };

  const handleDownloadExcel = () => {
    props.dispatchDownloadExcel(props);
  };


  const handleOpenFileDownloadModal = () => {
    props.dispatchOpenFileDownloadModal(props);
  };

  const handleDownload = () => {
    if (allowPDFDownload) {
      handleOpenFileDownloadModal();
    } else {
      handleDownloadExcel();
    }
  };


  return (
    <Conditional condition={columnDefs.length}>
      <div className="currentTrades__controlPanel">
        <div className="controlPanel">
          <div className="l-margin-left">
            <Dropdown
              id="current-trades-groups"
              onSelect={handleGroupChange}
              options={groupByList}
              placeholder={t('tkGroupByColonCaps')}
              prepender={t('tkGroupByColonCaps')}
            />
          </div>
          <div className="l-margin-left">
            <Dropdown
              id="current-trades-views"
              options={views}
              placeholder={t('tkFilterColon')}
              prepender={t('tkFilterColon')}
            />
          </div>
          <div className="l-margin-left">
            <FilterButton
              onClick={handleToggleFilterVisibility}
              visibilityState={props.filterVisibility === PORTFOLIO_FILTER_STATE.VISIBLE}
            />
          </div>
        </div>
        <div className="controlPanel">
          <div className="l-margin-right">
            <DownloadButton
              disabled={disableFileDownload}
              onClick={handleDownload}
              onKeyPress={handleDownload} />
            <FileDownloadModal id={MODULES_KEYS.CURRENT_TRADES} />
          </div>
          <PopOver overlayOffset={popoverOffset} buttonClass="transparent-button icon-more-menu">
            <div className="popover-element">
              <span className="portfolio-container__action-name">{t('tkCollapseAll')}</span>
              <SwitchButton
                key={isActiveGroupCollapse}
                active={isActiveGroupCollapse}
                onClick={handleExpandCollapseToggle}
                disabled={groupByField === 'none'}
              />
            </div>
          </PopOver>
        </div>
      </div>
    </Conditional>
  );
};


ControlPanel.propTypes = {
  filterVisibility:PropTypes.string,
  columnDefs: PropTypes.array,
  groupByList: PropTypes.array,
  views: PropTypes.array,
  currentView: PropTypes.object,
  groupByField: PropTypes.string,
  collapseState: PropTypes.bool,
  disableFileDownload: PropTypes.bool,
  dispatchFilterVisibility: PropTypes.func,
  dispatchGroupChange: PropTypes.func,
  dispatchToggleGroupCollapse: PropTypes.func,
  dispatchDownloadExcel: PropTypes.func,
  dispatchOpenFileDownloadModal: PropTypes.func,
  allowPDFDownload: PropTypes.bool
};

export const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  return {
    filterVisibility: filterVisibility(state, id),
    columnDefs: gridHeader(state, id),
    groupByList: groupByColumns(state, id),
    views: dropdownViewNamesSelector(state, id),
    currentView: currentViewSelector(state, id),
    collapseState: selectCollapseState(state, id),
    groupByField: groupByFieldNew(state, id),
    disableFileDownload: disableFileDownload(state, id),
    allowPDFDownload: allowPDFDownloadFeatureSelector(state),
  }
}

export default connect(mapStateToProps, controlPanleMapDispatchToProps)(ControlPanel);

