import { Status } from '@gs-ux-uitoolkit-common/design-system';
import { IconBaseProps } from '@gs-ux-uitoolkit-common/icon-font';
import { ComponentProps, CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { StepCssClasses } from './step-style-sheet';

/**
 * Configuration for a step in the Steps component
 */
export interface StepProps extends ComponentProps<CommonStyleType> {
    /**
     * Style classes to override.
     */
    classes?: Partial<StepCssClasses>;

    /**
     * Whether the step is disabled (click event is disabled)
     */
    disabled?: boolean;

    /**
     * When the {@link #orientation} is 'vertical' and `expandable` is `true`
     * on the Steps component, this prop controls whether or not the description
     * of the Step is expanded.
     */
    expanded?: boolean;

    /**
     * Icon to be passed to the step. Will override status if both are set, including 'null' to clear the icon on a status.
     */
    icon?: IconBaseProps | null;

    /**
     * The label for the step.
     */
    label?: string;

    /**
     * Set a pre-styled status for the step, including a color and an icon.
     *
     * Note that the icon can be overridden by the {@link #icon} prop, and
     * colors can be overridden using the {@link #classes} prop.
     *
     * Defaults to 'success' for a complete step, 'information' for the current
     * step, or 'none' for an incomplete step.
     */
    status?: StepStatus;
}

export type StepOverrideClasses = Partial<
    Record<'root' | 'button' | 'indicator' | 'label' | 'content' | 'icon', string>
>;

/**
 * The available `status` property values
 */
export type StepStatus = Extract<Status, 'none' | 'information' | 'success' | 'warning' | 'error'>;

/**
 * Default props for step
 */
export const stepDefaultProps: Required<Pick<StepProps, 'disabled'>> = {
    disabled: false,
};
