/* eslint-disable react/no-multi-comp */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import CardHeader from './cardHeader';
import CardBody from './cardBody';
import Resources from './resources';
import Modal from '../../core/Modal';
import {Conditional} from '../../core/Conditional';
import {
  getOpenFundFactCardAnalyticsData, getCloseFundFactCardAnalyticsData,
  getOpenAccountDetailsFactCardAnalyticsData, getCloseAccountDetailsFactCardAnalyticsData
} from '../../../utils/portfolioAnalytics';
import Link from '../../core/Link';
import {scrollToBottom} from '../../../utils/dom';
import {SHOW_ERROR_ON} from '../../../constants/pageConstants';
import Error from '../../core/Error';
import {withScroll} from '../../core/Scrollbar';
import AccountDetailBody from "./accountDetailBody";
import translator from "../../../services/translator";
import './index.scss';
import history from "../../../utils/history";

const {translate: t} = translator;

const List = ({
  config, labels, bodyData, resources, sendAnalytics, instructionList,
  statementList, registrationState, downloadStatementPDF
}) => (
  <>
    <div className="fundFactView__list">
      <CardBody items={config && config.fields || []} fundData={bodyData} labels={labels} registrationState={registrationState} />
      <Conditional condition={resources.length && config.factCardType === "Fund"}>
        <div>
          <div className='factHead'>
            <span className='title'>{labels.tkResource}</span>
          </div>
          <Resources
            items={resources}
            investmentManager={bodyData.investmentManager || null}
            labels={labels}
            sendAnalytics={sendAnalytics} />
        </div>
      </Conditional>
    </div>
    <Conditional condition={config && config.factCardType === "AccountDetail"}>
      <AccountDetailBody
        instructionList={instructionList}
        items={config && config.fields || []}
        labels={labels}
        statementList={statementList}
        downloadStatementPDF={downloadStatementPDF} />
    </Conditional>
  </>
);

List.propTypes = {
  config: PropTypes.object,
  labels: PropTypes.object,
  bodyData: PropTypes.object,
  resources: PropTypes.array,
  sendAnalytics: PropTypes.func,
  instructionList: PropTypes.array,
  statementList: PropTypes.array,
  registrationState: PropTypes.number,
  downloadStatementPDF: PropTypes.func
};

function getFundDisplay (activeFundFact, fundIdentifier) {
  if (!activeFundFact.fundName) return '';
  let fundDisplay = '';
  switch(fundIdentifier) {
    case 'CUSIP':
      fundDisplay =  activeFundFact.cusip ? activeFundFact.cusip : '';
      break;
    case 'ISIN':
      fundDisplay =  activeFundFact.isin ? activeFundFact.isin : '';
      break;
    case 'FUND_NUMBER':
      fundDisplay =  activeFundFact.fundNumber ? activeFundFact.fundNumber : '';
      break;
    case 'TICKER':
      fundDisplay =  activeFundFact.nasdaqTicker ? activeFundFact.nasdaqTicker : '';
      break;
    case 'FUND_NAME': return activeFundFact.fundName;
    default: return '';
  }
  return fundDisplay === '' ? activeFundFact.fundName : activeFundFact.fundName.concat(' (', fundDisplay, ')');
}

function getDisplayFormat (fieldName, fieldNumber) {
  if (!fieldName) return fieldNumber;
  if (!fieldNumber) return fieldName;
  return fieldName.concat(' - ', fieldNumber);
}

export const FundFactView = (props) => {
  const {
    config, labels, resources, sendAnalytics, isPageInErrorMode, downloadStatementPDF,
    registrationState, errorObject, label, clickTrackCTA, registration, statementList,
    unsavedRebateChanges, updateRebateStatusToNavigationWithUnsavedChanges,
    closeFundFactCard, unsetCloseFundFactCard
  } = props;
  const [shareClassId, setShareClassId] = useState();
  const [activeFundFact, setActivateFundFact] = useState(props.activeFundFact);
  const [instructionList, setInstructionList] = useState(props.instruction);
  const [bodyTop, setBodyTop] = useState(120);

  const headerRef = useRef(null);
  const modalRef = useRef();
  const unblockRef = useRef();

  if(activeFundFact !== props.activeFundFact){
    setActivateFundFact( props.activeFundFact);
  }

  if(instructionList !== props.instruction){
    setInstructionList(props.instruction);
  }

  const openModal = () => {
    if(config && config.factCardType) {
      if (config.factCardType === "AccountDetail") {
        props.fetchAccountRegistration(activeFundFact.accountId);
        props.fetchSettlementInstruction();
        props.fetchDebitCreditInfo(activeFundFact.accountId);
        props.fetchStatement(activeFundFact.accountId);
        props.sendAnalytics(getOpenAccountDetailsFactCardAnalyticsData());
      }
      else if (config.factCardType === "Fund")  {
        props.fetchLiterature(activeFundFact.shareClassId);
        props.sendAnalytics(getOpenFundFactCardAnalyticsData(activeFundFact.rebateDetails && activeFundFact.rebateDetails.rebateAmount, activeFundFact.isin));
      }
    }
  };

  useEffect(() => {
    const headerHeight = headerRef.current && headerRef.current.clientHeight;
    (bodyTop !== headerHeight) &&  setBodyTop(headerHeight);
    if(activeFundFact && config &&
      (shareClassId !== activeFundFact.shareClassId) ){
      openModal();
    }
  }, [activeFundFact]);


  useEffect(() => {
    setShareClassId(activeFundFact.shareClassId);
  }, [resources]);

  const handleClose = () => {
    props.updateActiveFundFact(null);
    if(config && config.factCardType){
      if (config.factCardType === "AccountDetail") {
        props.sendAnalytics(getCloseAccountDetailsFactCardAnalyticsData());
        props.clearAccountDetailData();
      }
      else if (config.factCardType === "Fund")  {
        props.sendAnalytics(getCloseFundFactCardAnalyticsData());
        props.clearLiterature();
      }
    }
    props.isPageInErrorMode && props.resetError();
  };

  const onClose = () => {
    handleClose();
    scrollToBottom();
  };

  useEffect(() => {
    if (closeFundFactCard) {
      modalRef.current.closeModal(handleClose);
      unsetCloseFundFactCard();
    }
    return unsetCloseFundFactCard;
  }, [closeFundFactCard]);

  useEffect(() => {
    const beforeunload = (event) => {
      event.preventDefault();
      return (event.returnValue = "");
    };

    if (unsavedRebateChanges) {
      window.addEventListener("beforeunload", beforeunload);
    } else {
      window.removeEventListener("beforeunload", beforeunload);
    }

    return () => {
      window.removeEventListener("beforeunload", beforeunload);
    };
  }, [unsavedRebateChanges]);

  useEffect(() => {
    unblockRef.current = history.block(() => {
      if (unsavedRebateChanges) {
        updateRebateStatusToNavigationWithUnsavedChanges();
        return false;
      }
      return true;
    });
    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [unsavedRebateChanges]);

  const disclaimerClick = () => {
    if (unsavedRebateChanges) {
      updateRebateStatusToNavigationWithUnsavedChanges();
      return;
    }
    modalRef.current.closeModal(onClose);
  };

  const modalProps = {
    animation: true,
    suppressScrollOnActive: true,
    closeOnOutsideClick: false,
    open: !!(config),
    overrideCloseHandler: unsavedRebateChanges?
      updateRebateStatusToNavigationWithUnsavedChanges: null,
    handleClose,
  };
  const headerData = {};
  config && config.headerFields && config.headerFields.forEach((item) => {
    headerData[item.field] = activeFundFact[item.field];
  });

  const bodyData = {};
  config && config.fields && config.fields.forEach((item) => {
    bodyData[item.field] = activeFundFact[item.field];
  });

  headerData.navAsOfTime = activeFundFact.navAsOfTime;
  headerData.shareClassId = activeFundFact.shareClassId;
  headerData.showFundTrackerButton = props.showFundTrackerButton;
  bodyData.fundCcy = activeFundFact.fundCcy;
  bodyData.investmentManager = activeFundFact.investmentManager;

  if (activeFundFact.rebateDetails) {
    bodyData.rebateAmount = activeFundFact.rebateDetails.rebateAmount;
    bodyData.investmentOption = activeFundFact.rebateDetails.investmentOption;
    bodyData.paymentDate = activeFundFact.rebateDetails.paymentDate;
    bodyData.adjustedOneDayYield = activeFundFact.rebateDetails.adjustedOneDayYield;
    bodyData.adjustedSevenDayCurYield = activeFundFact.rebateDetails.adjustedSevenDayCurYield;
    bodyData.adjustedSevenDayEffYield = activeFundFact.rebateDetails.adjustedSevenDayEffYield;
    bodyData.adjustedThirtyDayYield = activeFundFact.rebateDetails.adjustedThirtyDayYield;
  }

  if (config && config.factCardType && config.factCardType === "AccountDetail") {
    headerData.factCardHeader = getFundDisplay(activeFundFact, props.fundIdentifierPreference);
    bodyData.firmInfo = getDisplayFormat(activeFundFact.firmName, activeFundFact.firmNumber);
    bodyData.branchInfo =  getDisplayFormat(activeFundFact.branchName, activeFundFact.branchNumber);
    bodyData.registrationField = registration;
  }

  const scrollConfig = {
    cssTrackY: 'modal-scroll__track modal-scroll__track--Y',
    cssContent: 'modal-scroll__content',
    cssWrapper: 'modal-scroll__wrapper',
    overflowX: 'hidden',
    overflowY: 'scroll',
    style: {
      position: 'static'
    }
  };

  return (
    <Modal   {...modalProps} ref={modalRef}>
      <div className="fundFactView">
        <Conditional
          condition={isPageInErrorMode &&
              (errorObject.showErrorOn === SHOW_ERROR_ON.FUND_FACT_MODAL)}>
          <div className="fundFactView__header">
            <h2>{headerData.fundName}</h2>
            <Error code={errorObject.errorCode} label={label} />
          </div>
          <div>
            <div ref={headerRef} className="fundFactView__header">
              <Conditional condition={(config && config.factCardType) === "Fund" }>
                <span className="header" > {t('tkFundDetailHeader')} </span>
              </Conditional>
              <CardHeader
                items={config && config.headerFields || []}
                fundData={headerData}
                labels={labels}
                cardType={config && config.factCardType || ''}
                clickTrackCTA={clickTrackCTA}
              />
              <hr className="fundFactView__separator" />
            </div>
            <div className="fundFactView__body" style={{top: bodyTop}}>
              {withScroll(List, scrollConfig)({
                config,
                labels,
                bodyData,
                resources,
                sendAnalytics,
                bodyTop,
                instructionList,
                statementList,
                registrationState,
                downloadStatementPDF
              })}
            </div>
            <div className="fundFactView__buttonRow">
              <Conditional condition={config.factCardType === "Fund"}>
                <Link classType="link-primary-small" handleClick={disclaimerClick} label={labels.tkDisclaimers} />
              </Conditional>
            </div>
          </div>
        </Conditional>
      </div>
    </Modal>
  );
};

FundFactView.propTypes = {
  activeFundFact: PropTypes.object,
  updateActiveFundFact: PropTypes.func,
  labels: PropTypes.object,
  label: PropTypes.string,
  fetchLiterature: PropTypes.func,
  registration: PropTypes.array,
  fetchAccountRegistration: PropTypes.func,
  instruction: PropTypes.array,
  fetchSettlementInstruction: PropTypes.func,
  statementList: PropTypes.array,
  registrationState: PropTypes.number,
  fetchStatement: PropTypes.func,
  config: PropTypes.object,
  resources: PropTypes.array,
  sendAnalytics: PropTypes.func,
  downloadStatementPDF: PropTypes.func,
  isPageInErrorMode: PropTypes.bool,
  errorObject: PropTypes.object,
  resetError: PropTypes.func,
  clearLiterature: PropTypes.func,
  clickTrackCTA: PropTypes.func,
  showFundTrackerButton: PropTypes.bool,
  fundIdentifierPreference: PropTypes.string,
  fetchDebitCreditInfo: PropTypes.func,
  achUser: PropTypes.bool,
  clearAccountDetailData: PropTypes.func,
  unsavedRebateChanges: PropTypes.bool,
  updateRebateStatusToNavigationWithUnsavedChanges: PropTypes.func,
  closeFundFactCard: PropTypes.bool,
  unsetCloseFundFactCard: PropTypes.func
};

export default FundFactView;
