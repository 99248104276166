import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Snackbar from '../../../components/core/Snackbar';
import { snackbarDataList } from '../../../selectors/pages/tradeActivity/currentTrades';
import { approvalSnackbarDataList, approvalSnackbarExceptionList } from '../../../selectors/pages/tradeActivity/approvals';
import { labelSelector } from '../../../selectors/app';
import { MODULES_KEYS } from '../../../constants/pageConstants';
import Constants from '../../../constants/appConstants';

const TradeActivityHeader = ({snackbarItems, labels}) => (
  <TransitionGroup component={null}>
    {snackbarItems.map( (data, idx) => {
      return(
        <CSSTransition
          key={idx}
          classNames="snackbar__transition"
          timeout={Constants.CSS_DURATION_HORSE}>
          <Snackbar
            labels={labels}
            {...data}
          />
        </CSSTransition>
      );
    })}
  </TransitionGroup>
);

TradeActivityHeader.propTypes = {
  snackbarItems: PropTypes.array,
  labels: PropTypes.object,
};

const getSnackbarItems = (state) => [...snackbarDataList(state, MODULES_KEYS.CURRENT_TRADES),
  ...approvalSnackbarDataList(state), ...approvalSnackbarExceptionList(state)];
const mapStateToProps = (state) => (
  {
    labels: labelSelector(state),
    snackbarItems: getSnackbarItems(state),
  });

export default connect(mapStateToProps)(TradeActivityHeader);
