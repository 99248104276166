import { MutableRefObject, useCallback } from 'react';

export type UseUnregisterRefType = typeof useUnregisterRef;

/**
 * Callback returned from the hook should be used to unregister reference to `<Droppable />` components.
 * This is needed for calculating the dimensions cache.
 */
export const useUnregisterRef = (
    refs: MutableRefObject<Record<string, MutableRefObject<HTMLElement | null>>>
) => {
    return useCallback(
        (id: string) => {
            delete refs.current[id];
        },
        [refs]
    );
};
