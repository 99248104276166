import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import translator from "../../../../../../services/translator";
import "./index.scss";

const { translate: t } = translator;

const AccountNumberToggler = ({ title, accountNumber }) => {
  const maskedAccountNumber = `*****${accountNumber.substr(
    accountNumber.length - 4,
    accountNumber.length
  )}`;
  const [showAccountNumber, setShowAccountNumber] = useState(false);
  const toggleBankAccountNumber = () => {
    setShowAccountNumber(!showAccountNumber);
  };

  return (
    <div data-testid="account-toggler-container" className="factData">
      <span className="fundFactView__label">{title}</span>
      <span className="account-number-container">
        (
        <span className="account-link" onClick={toggleBankAccountNumber}>
          {showAccountNumber ? `${t("tkHide")}` : `${t("tkShow")}`}
        </span>
        ):
        <span className={classNames({ "account-number": showAccountNumber })}>
          {showAccountNumber ? accountNumber : maskedAccountNumber}
        </span>
      </span>
    </div>
  );
};

AccountNumberToggler.propTypes = {
  accountNumber: PropTypes.string,
  title: PropTypes.string,
};

export default AccountNumberToggler;
