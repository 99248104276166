import {
    ColDef,
    ColGroupDef,
    ColumnApi,
    Events,
    GridApi,
    IRowNode,
    IViewportDatasource,
    IViewportDatasourceParams,
} from '@ag-grid-community/core';
import {
    arrayHelper,
    DraDatasource,
    DraPivotDimension,
    DraProvider,
    DraSetupReturn,
    DraViewConfig,
    logger,
    Record,
    RecordInsertedEventArgs,
    RecordUpdatedEventArgs,
} from '@gs-ux-uitoolkit-common/datacore';
import { DatasourceConfiguration } from '../grid-wrappers/grid-wrapper';
import { CustomDRAOptions } from '../plugins/dra/dra-plugin';
import { DashGridToagGridColumnConverter } from './dash-grid-to-ag-grid-column-converter';
import { Datasource } from './datasource-factory';
import { DraColumnGroupRenderer } from './dra-column-group-renderer';
import { draTechnicalFieldHelper, ExtraDraTechnicalFields } from './technical-field-helper';
import { getGridOptionsService } from '../util/getGridOptionsService';
import { hasOwnProperty } from 'gs-uitk-object-utils';

export const rowGroupColumnColId = 'rowGroupColumnColId';

/**
 * Datasource created when using the DRA mode. It manages the interface between DRA and ag-Grid
 * Note: This class needs a big refactoring in order to be testable!
 */
export class DraViewportDatasource implements IViewportDatasource, Datasource {
    private isDatasourceReady: Promise<void> | null;
    private draProvider: DraProvider | null;
    private dataSource: DraDatasource | null;
    private rowCount: number;
    private viewportDatasourceParams: IViewportDatasourceParams | null;
    private mainColumnsDefs: (ColDef | ColGroupDef)[] | null = null;
    private currentSchemaHash: string = '';
    private id: number = 0;
    private receivedFirstData = false;
    private rowGroupColumnFieldDependencies: string[] = [];
    // ag-grid do not expose the ChartService type. Once they do replace that ugly type
    private chartService: {
        activeChartComps: Set<{
            chartController: {
                updateForGridChange: () => void;
            };
        }>;
    };
    constructor(
        private datasourceConfig: DatasourceConfiguration,
        private gridApi: GridApi,
        private columnApi: ColumnApi,
        private customDraOptions: CustomDRAOptions
    ) {
        this.draProvider = null;
        this.dataSource = null;
        this.viewportDatasourceParams = null;
        this.rowCount = 0;
        this.isDatasourceReady = null;
        this.chartService = (this.gridApi as any).context.beanWrappers.chartService.beanInstance;
        if (!this.chartService) {
            logger.error('Cannot get the chartService', this.gridApi);
        }
        if (!this.chartService.activeChartComps) {
            logger.error('Cannot get the chartService.activeChartComps', this.chartService);
        }
        gridApi.setViewportDatasource(this);
    }

    public destroy(): void {
        if (this.draProvider) {
            this.draProvider.close();
        }
        if (this.dataSource) {
            this.dataSource.destroy();
        }
    }

    public connect(): Promise<void> {
        if (!this.isDatasourceReady) {
            const msg = 'DraDatasource is not correctly initialized';
            logger.error(msg);
            throw new Error(msg);
        }
        return this.isDatasourceReady;
    }
    public init(params: IViewportDatasourceParams): void {
        this.viewportDatasourceParams = params;
        const oldOnConnect = this.datasourceConfig.onConnect;
        this.datasourceConfig.onConnect = eb => {
            this.onConnect(eb);
            if (oldOnConnect) {
                oldOnConnect(eb);
            }
        };

        const oldonDisconnect = this.datasourceConfig.onDisconnect;
        this.datasourceConfig.onDisconnect = eb => {
            this.onDisconnect(eb);
            if (oldonDisconnect) {
                oldonDisconnect(eb);
            }
        };

        const oldonCloseView = this.datasourceConfig.onCloseView;
        this.datasourceConfig.onCloseView = eb => {
            this.onCloseView(eb);
            if (oldonCloseView) {
                oldonCloseView(eb);
            }
        };
        this.datasourceConfig.datasource = DraDatasource;
        this.datasourceConfig.viewConfig = DraViewConfig;
        let dataSourceResource = {};
        if (this.datasourceConfig.url) {
            dataSourceResource = this.datasourceConfig.url;
            if (this.datasourceConfig.authUrl) {
                dataSourceResource += `|${this.datasourceConfig.authUrl}`; // hack to make it backwards compatible with existing code
            }
        }
        if (!this.datasourceConfig.code) {
            const msg = 'code property of the DatasourceConfiguration not setup to the DRASetup';
            logger.error(msg);
            throw new Error(msg);
        }
        this.isDatasourceReady = this.datasourceConfig
            .code(dataSourceResource, this.datasourceConfig)
            .then((draSetupReturn: DraSetupReturn) => {
                this.draProvider = draSetupReturn.datasource.provider;

                if (this.datasourceConfig.defaultExpansionDepth) {
                    getGridOptionsService(this.gridApi).set(
                        'groupDefaultExpanded',
                        this.datasourceConfig.defaultExpansionDepth
                    );
                }
                const groupDefaultExpanded = getGridOptionsService(this.gridApi).getNum(
                    'groupDefaultExpanded'
                );
                if (groupDefaultExpanded) {
                    this.draProvider.setExpansionDepth(groupDefaultExpanded);
                }

                if (this.datasourceConfig.fixedDRAFilter) {
                    this.draProvider.filter(this.datasourceConfig.fixedDRAFilter);
                }

                this.dataSource = draSetupReturn.datasource;
                this.dataSource.recordInserted.subscribe(args =>
                    this.processRecordInserted(args, params)
                );
                this.dataSource.recordUpdated.subscribe(args =>
                    this.processRecordUpdated(args, params)
                );
                this.dataSource.recordDeleted.subscribe(() => this.processRecordDeleted());

                const displayedColumns = this.draProvider.viewConfig.getColumns();
                const defaultGroupColDef = getGridOptionsService(this.gridApi).get(
                    'defaultColGroupDef'
                );
                const defaultHeaderGroupComponentParams =
                    defaultGroupColDef && defaultGroupColDef.headerGroupComponentParams;

                // we convert the columns from Dra to agGrid format
                const draDisplayedColumns = DashGridToagGridColumnConverter.convert(
                    displayedColumns,
                    this.draProvider.viewConfig,
                    defaultHeaderGroupComponentParams
                        ? defaultHeaderGroupComponentParams.dynamicAlignment
                        : undefined
                );

                const columnsRest = this.draProvider.viewConfig.allFields
                    .filter(x => !displayedColumns.find(dc => dc.field === x))
                    .map(field => {
                        return {
                            field,
                            name: field,
                        };
                    });

                const draDolumnsRest = DashGridToagGridColumnConverter.convert(
                    columnsRest,
                    this.draProvider.viewConfig,
                    defaultHeaderGroupComponentParams
                );

                draDolumnsRest.forEach(col => {
                    const columnDef = col as ColDef;
                    columnDef.hide = true;
                    if (columnDef.field && this.draProvider) {
                        const isTechnical = draTechnicalFieldHelper.isTechnicalField(
                            columnDef.field,
                            this.draProvider.viewConfig
                        );
                        columnDef.suppressColumnsToolPanel = isTechnical;
                        columnDef.suppressFiltersToolPanel = isTechnical;
                        columnDef.lockVisible = isTechnical;
                        if (isTechnical) {
                            columnDef.filter = false;
                        }
                    }
                });

                this.mainColumnsDefs = DashGridToagGridColumnConverter.handleRowGroup(
                    [...draDisplayedColumns, ...draDolumnsRest],
                    rowGroupColumnColId,
                    this.draProvider.viewConfig.pivotBy,
                    this.draProvider.viewConfig,
                    this.customDraOptions
                );

                const agGridMainColumnHints = this.mainColumnsDefs.map(x => {
                    let foundColDef;
                    if (this.customDraOptions.columnDefs) {
                        foundColDef = this.customDraOptions.columnDefs.find(
                            y => (y as ColDef).field === (x as ColDef).colId
                        );
                    }
                    return { ...x, ...foundColDef };
                });
                this.mainColumnsDefs = [...agGridMainColumnHints];

                // we set the Column Definition
                this.gridApi.setColumnDefs(this.mainColumnsDefs);

                // if at least one column is pivotable then we switch to pivot active
                if (
                    this.draProvider.viewConfig.hPivotBy.length > 0 &&
                    this.draProvider.viewConfig.isPivotable.length > 0
                ) {
                    let allColumns = this.columnApi.getColumns();
                    if (allColumns) {
                        allColumns = allColumns.filter(col => col.isVisible());
                        // we add all the columns as value columns so they are visible in PivotMode
                        this.columnApi.setValueColumns([...allColumns]);
                    }
                }

                this.columnApi.setPivotMode(this.draProvider.viewConfig.hPivotBy.length > 0);

                // When one of those fields is updated we need to refresh the dra rowgroup
                this.rowGroupColumnFieldDependencies.push(this.draProvider.viewConfig.depthField);
                this.rowGroupColumnFieldDependencies.push(
                    this.draProvider.viewConfig.isExpandedField
                );
                this.rowGroupColumnFieldDependencies.push(ExtraDraTechnicalFields.isSelectedField);

                // finally we initiate the DRA subscription
                this.draProvider.subscribe();
                this.gridApi.showLoadingOverlay();
            })
            .catch(error => {
                logger.error(
                    'Error setting up DraViewportDatasource for the DRA connection',
                    error
                );
                // we rethrow the error as in the Wrapper we show an alert in that scenario
                throw new Error('Error setting up DraViewportDatasource for the DRA connection');
            });
    }

    public setViewportRange(firstRow: number, lastRow: number): void {
        if (this.draProvider) {
            logger.debug(`setViewportRange: ${firstRow} to ${lastRow}`);

            // Don't update the viewRange if there are no rows.
            // Needed for when a filter returns no values so that filter can be removed and still display rows after
            if (firstRow === 0 && lastRow === -1) {
                return;
            }
            this.draProvider.setViewRange(firstRow, lastRow + 1);
        }
    }

    public getDraDatasource(): DraDatasource | null {
        return this.dataSource;
    }

    private processRecordInserted(
        args: RecordInsertedEventArgs,
        params: IViewportDatasourceParams
    ): void {
        args.recordList.forEach(record => {
            if (this.draProvider) {
                this.buildNewColumnDefinitionIfNeeded(record[ExtraDraTechnicalFields.schemaField]);
                // We treat the main pivot differently as we change the name of the field for pivoted data
                // we could avoid the if but I feel that it's clearer like that. I might change my mind later...
                if (
                    !record[this.draProvider.viewConfig.pathField] ||
                    record[this.draProvider.viewConfig.pathField].path[DraPivotDimension.Horizontal]
                        .length === 0
                ) {
                    // we set only the new rows which is a big difference with the existing dash grid
                    const rowNode = params.getRow(record[this.draProvider.viewConfig.idxField]);
                    if (this.customDraOptions?.getRowId) {
                        if (typeof this.customDraOptions.getRowId === 'function') {
                            const customRowId = this.customDraOptions.getRowId(record);
                            if (typeof customRowId === 'string') {
                                rowNode.id = customRowId;
                            } else {
                                throw new Error('Invalid row id type, Expected a string');
                            }
                        } else {
                            throw new Error('Expected getRowId to be a function');
                        }
                    } else {
                        this.id = this.id + 1;
                        rowNode.id = this.id.toString();
                    }
                    rowNode.setData({ ...record });
                    this.manageRowNodeState(rowNode, record);
                } else {
                    const rowNode = params.getRow(record[this.draProvider.viewConfig.idxField]);
                    const newRow = this.buildPivotedRecord(record);
                    rowNode.updateData({ ...rowNode.data, ...newRow });
                }
            }
        });
        if (this.dataSource) {
            this.setRowCount(this.dataSource.getDataSize());
        }
        // we update the charts so they tick as well
        this.chartService.activeChartComps.forEach(element =>
            element.chartController.updateForGridChange()
        );
        if (!this.receivedFirstData) {
            this.gridApi.hideOverlay();
            this.receivedFirstData = true;
            let raisedRowGroup = false;
            // we raise a opened/collapse for the first group we find on the viewport
            // in order to trigger the autofit pivot
            this.gridApi.forEachNode(x => {
                if (x.group && !raisedRowGroup) {
                    this.gridApi.dispatchEvent({
                        node: x,
                        type: Events.EVENT_ROW_GROUP_OPENED,
                    } as any);
                    raisedRowGroup = true;
                }
            });
        }
    }

    private processRecordUpdated(
        args: RecordUpdatedEventArgs,
        params: IViewportDatasourceParams
    ): void {
        const nodesToRefreshDraRowGroup: IRowNode[] = [];
        if (this.draProvider) {
            const viewConfig = this.draProvider.viewConfig;
            this.buildNewColumnDefinitionIfNeeded(args.record[ExtraDraTechnicalFields.schemaField]);
            // if main record
            if (
                !args.record[viewConfig.pathField] ||
                args.record[viewConfig.pathField].path[DraPivotDimension.Horizontal].length === 0
            ) {
                const rowNode = params.getRow(args.record[viewConfig.idxField]);
                const hasRowMoved =
                    args.deltaUpdates.findIndex(x => x.field === viewConfig.idxField) > -1;
                if (
                    args.deltaUpdates.find(update =>
                        this.rowGroupColumnFieldDependencies.includes(update.field)
                    )
                ) {
                    nodesToRefreshDraRowGroup.push(rowNode);
                }

                // in case DRA sends an update that just moves a record
                if (hasRowMoved) {
                    rowNode.setData({ ...args.record });

                    // if we received an update for data as part as the same message as the row move then we force the flash
                    const columnToFlash = args.deltaUpdates
                        .filter(x => x.field !== viewConfig.idxField)
                        .map(x => x.field);
                    if (
                        columnToFlash.length > 0 &&
                        getGridOptionsService(this.gridApi).is('enableCellChangeFlash')
                    ) {
                        this.gridApi.flashCells({ rowNodes: [rowNode], columns: columnToFlash });
                    }
                } else {
                    rowNode.updateData({ ...rowNode.data, ...args.record });
                }
                this.manageRowNodeState(rowNode, args.record);
            } else {
                // we set only the new rows which is a big difference with the existing dash grid
                const rowNode = params.getRow(args.record[viewConfig.idxField]);
                const newRow = this.buildPivotedRecord(args.record);
                rowNode.updateData({ ...rowNode.data, ...newRow });
            }
        }
        if (this.dataSource) {
            this.setRowCount(this.dataSource.getDataSize());
        }
        // we update the charts so they tick as well
        this.chartService.activeChartComps.forEach(element =>
            element.chartController.updateForGridChange()
        );
        // If some rowNodes need to have the draRowGroup redrawn
        if (nodesToRefreshDraRowGroup.length) {
            this.gridApi.refreshCells({
                columns: [rowGroupColumnColId],
                force: true,
                rowNodes: nodesToRefreshDraRowGroup,
            });
        }
    }

    private processRecordDeleted(): void {
        if (this.dataSource) {
            this.setRowCount(this.dataSource.getDataSize());
        }
    }

    private buildPivotedRecord(record: Record): Record {
        if (this.draProvider) {
            // if pivoted record
            const pathString =
                record[this.draProvider.viewConfig.pathField].path[
                    DraPivotDimension.Horizontal
                ].join('_');
            const newRecord = { ...record };
            // we replace the property names with the property path
            // it will allow us to create an aggrid field like Blue_Model
            for (const key in record) {
                if (hasOwnProperty(record, key)) {
                    newRecord[`${pathString}_${key}`] = newRecord[key];
                    delete newRecord[key];
                }
            }
            return newRecord;
        }
        return record;
    }

    //
    private manageRowNodeState(rowNode: IRowNode, record: Record): void {
        if (this.draProvider) {
            rowNode.expanded = record[this.draProvider.viewConfig.isExpandedField];

            rowNode.group = !record[this.draProvider.viewConfig.isLeafField];
            rowNode.level = record[this.draProvider.viewConfig.depthField];
            rowNode.uiLevel = record[this.draProvider.viewConfig.depthField];
            // Set level (depth of pivot) of rowNode for DRA grid
            rowNode.level = record[this.draProvider.viewConfig.depthField];
            //ag-grid throws a warning if the rowNode does not have an id
            //  ag-grid rowNode.setSelectedParams sends console.warn('ag-Grid: cannot select node until id for node is known');

            let isRowSelected = !!record[ExtraDraTechnicalFields.selectionStateField];
            // we set the isSelectedField technical field if groupSelectsChildren has not been defined to prevent a breaking change.
            // if groupSelectsChildren == true then we want to use isSelectedField otherwise we use selectionStateField so row group does not select all children
            if (
                this.customDraOptions.groupSelectsChildren == undefined ||
                this.customDraOptions.groupSelectsChildren
            ) {
                isRowSelected = record[ExtraDraTechnicalFields.isSelectedField];
            }
            if (rowNode.id) {
                rowNode.setSelected(isRowSelected);
            }
            this.gridApi.hideOverlay();
        }
    }

    private setRowCount(rowCount: number) {
        if (this.rowCount !== rowCount && this.viewportDatasourceParams) {
            this.rowCount = rowCount;
            this.viewportDatasourceParams.setRowCount(rowCount, false);
        }
    }

    private onConnect(eventBus: any) {
        logger.info('DraViewportDatasource onConnect triggered for', eventBus);
    }

    private onDisconnect(eventBusUrl: any) {
        logger.info('DraViewportDatasource onDisconnect triggered for', eventBusUrl);
        this.gridApi.showNoRowsOverlay();
    }

    private onCloseView(viewId: any) {
        logger.info('DRA stopped serving the view', viewId);
    }

    private duplicateForPivot(colDef: ColDef | ColGroupDef, path: string[]): ColDef | ColGroupDef {
        if (hasOwnProperty(colDef, 'children')) {
            const group = colDef as ColGroupDef;
            const newGroup = { ...group };
            newGroup.children = group.children.map(oldColDef =>
                this.duplicateForPivot(oldColDef, path)
            );
            return newGroup;
        }
        const col = colDef as ColDef;
        const fieldName = `${path.join('_')}_${col.field}`;
        const originalColDef = this.gridApi.getColumnDef(fieldName);
        if (originalColDef) {
            return originalColDef;
        }
        const newColdef: ColDef = {
            aggFunc: col.aggFunc,
            allowedAggFuncs: col.allowedAggFuncs,
            cellClassRules: col.cellClassRules,
            colId: fieldName,
            enableValue: true,
            field: fieldName,
            filter: false,
            headerName: col.headerName,
            hide: col.hide,
            sortable: false,
        };
        return newColdef;
    }

    private duplicateColumnDefinitionsForPivot(
        colDefList: (ColDef | ColGroupDef)[],
        path: string[]
    ): (ColDef | ColGroupDef)[] {
        return colDefList
            .filter(x => {
                if (hasOwnProperty(x, 'children')) {
                    return false;
                }
                const col = x as ColDef;
                if (col.colId === rowGroupColumnColId) {
                    return false;
                }
                if (this.draProvider) {
                    return !draTechnicalFieldHelper.isTechnicalField(
                        col.field || '',
                        this.draProvider.viewConfig
                    );
                }
                return false;
            })
            .map(x => this.duplicateForPivot(x, path));
    }

    private computeSchemaHash(schema: string[][]): string {
        return arrayHelper.flatten<string>(schema).join();
    }

    private buildNewColumnDefinitionIfNeeded(schema: string[][] | undefined) {
        if (!schema) {
            return;
        }
        const newSchemaHash = this.computeSchemaHash(schema);
        if (this.currentSchemaHash !== newSchemaHash && this.mainColumnsDefs) {
            this.currentSchemaHash = newSchemaHash;
            let newColumnDefs: (ColDef | ColGroupDef)[] = [];
            // we are storing only the pivoted column. We don't care about the main columns
            const allFlatPivotedColDef: ColDef[] = [];
            schema.forEach(path => {
                if (this.mainColumnsDefs) {
                    if (path.length === 0) {
                        const agGridMainColumnHints = this.mainColumnsDefs.map(x =>
                            this.gridApi.getColumnDef((x as ColDef).colId as string)
                        );
                        newColumnDefs = agGridMainColumnHints
                            ? [...(agGridMainColumnHints as ColDef[])]
                            : [];
                    } else {
                        const duplicatedColDef = this.duplicateColumnDefinitionsForPivot(
                            this.mainColumnsDefs,
                            path
                        );
                        allFlatPivotedColDef.push(...duplicatedColDef);

                        let parent: (ColGroupDef | ColDef)[] = newColumnDefs;
                        for (let index = 0; index < path.length - 1; index = index + 1) {
                            let group: ColGroupDef | ColDef | null = null;
                            // TODO : change the logic to do it on GroupId instead of HeaderName?
                            if (
                                parent.length > 0 &&
                                arrayHelper.last(parent)!.headerName === path[index]
                            ) {
                                // use the group if already created
                                group = arrayHelper.last(parent);
                            }
                            // We should never get in that scenario. Parent group should always have been created before since there should be columns displayed in them
                            if (!group || !hasOwnProperty(group, 'children')) {
                                const msg = `Critical error creating the group for the pivot ${path}, cannot find parent pivot ${path[index]}`;
                                logger.error(msg);
                                throw new Error(msg);
                            }
                            // we know parent is always a group
                            const theGroup = group as ColGroupDef;
                            parent = theGroup.children;
                            // we set it to be opened since we are adding new group after
                            theGroup.openByDefault = true;
                        }
                        // if there was already a group displayed we want to recreate it with the same state
                        const groupId = path.join('_');
                        const groupWillHavePivotChildren =
                            path.length < this.columnApi.getPivotColumns().length;
                        const originalColumnGroup = this.columnApi.getColumnGroup(groupId);
                        const defaultGroupColDef = getGridOptionsService(this.gridApi).get(
                            'defaultColGroupDef'
                        );
                        const defaultHeaderGroupComponentParams =
                            defaultGroupColDef && defaultGroupColDef.headerGroupComponentParams;
                        const newPivotGroup: ColGroupDef = {
                            groupId,
                            children: duplicatedColDef,
                            headerGroupComponent: DraColumnGroupRenderer(
                                groupWillHavePivotChildren,
                                (defaultHeaderGroupComponentParams &&
                                    defaultHeaderGroupComponentParams.dynamicAlignment) ||
                                    'none'
                            ),
                            headerName: arrayHelper.last(path) || '',
                            openByDefault: originalColumnGroup
                                ? originalColumnGroup.isExpanded()
                                : false,
                        };
                        parent.push(newPivotGroup);
                    }
                }
            });

            // we set again the Column Definitions
            this.gridApi.setColumnDefs(newColumnDefs);
            let allColumns = this.columnApi.getColumns();
            if (allColumns) {
                allColumns = allColumns.filter(col => col.isVisible());

                // we add all the columns as value columns so they are visible in PivotMode
                this.columnApi.setValueColumns([...allColumns]);
            }
        }
    }
}
