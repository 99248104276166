import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from "prop-types";
import isEmpty from 'lodash/isEmpty';
import Snackbar from '../../../../components/core/Snackbar';
import SubHeader from '../Headers/SubHeader';
import CustomSelect from "../../../../components/core/Select/Custom";
import FundBenchmarkOption from "../SearchBenchMarks/FundBenchmarkOption"
import translator from '../../../../services/translator';
import {fetchUserEntitledShareClassesAndBenchmarks} from "../../services";
import {useChartContext} from "../../context";
import {useAsync} from "../../reducers/Hooks";
import {ChartActionTypes} from "../../actions";
import {clearDataMissingMessages, getFundValueToSearch} from "../../utils";
import {useEffectDebugger} from "../../../../utils/customHooks"
import {FUND_TRACKER} from "../../../../constants/pageConstants";

const {translate: t} = translator;

const PrimaryFundSearch = ({
  userGuid, saveUserPreferredFunds, tailEndFundIds
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [searchItems, setSearchItems] = useState([]);
  const {dispatch: chartDispatch, state: chartState} = useChartContext();
  const {shareClassAndBenchmarkData: {shareClasses = []} = {}} = chartState;
  const {run} = useAsync(chartDispatch);
  const myTopHoldingOption = useMemo(() => ({value: 'myTopHoldings', label: t('tkMyTopHoldings'), __isNew__: true}));
  const selectRef = useRef();

  const handleChange = useCallback((data) => {
    const {value} = data;
    if (value !== "myTopHoldings") {
      // Clear the Fund/Benchmark data missing message
      clearDataMissingMessages(chartDispatch);

      // Set it as user selected primary Share Class
      saveUserPreferredFunds([value], 'lastShareclassIds');

      chartDispatch({
        type: ChartActionTypes.setInitShareClassId,
        data: {
          primaryShareClassId: value,
          benchmarks: []
        }
      });
    } else {
      saveUserPreferredFunds([], 'lastShareclassIds');
      const [primaryShareClassId, ...benchmarks] = tailEndFundIds || [];
      const updatedBenchMarks = benchmarks.map(value => ({type: 'fund', value, id: value}));
      chartDispatch({
        type: ChartActionTypes.setInitShareClassId,
        data: {
          primaryShareClassId,
          benchmarks: updatedBenchMarks,
          selection: FUND_TRACKER.BENCHMARKS,
          isLoadingTopOpeningFunds: true
        }
      });
    }

    setTimeout(() => {
      selectRef.current && selectRef.current.blur();
    });
  }, []);

  useEffectDebugger(() => {
    fetchUserEntitledShareClassesAndBenchmarks(
      run,
      chartDispatch,
      userGuid
    )
  }, [userGuid]);

  const dropDownOptions = useMemo(() => {
    // Prepare the plain list of Share Classes
    const shareClassPlainList = [];
    if (!isEmpty(shareClasses)) {
      const tailEndFunds = [];
      shareClasses
        .forEach(item => {
          const shareclass = getFundValueToSearch(item);
          if (!isEmpty(shareclass)) {
            const isTailEndFund = tailEndFundIds.some(id => shareclass.value === id);
            if (!isTailEndFund) {
              shareClassPlainList.push(shareclass);
            } else {
              tailEndFunds.push(shareclass);
            }
          }
        });
      const fundsList = shareClassPlainList.concat(tailEndFunds);
      // Add this option as the last option
      if (tailEndFundIds && !!tailEndFundIds.length) {
        fundsList.push(myTopHoldingOption);
      }
      return fundsList;
    }
    return null;
  }, [shareClasses, tailEndFundIds]);

  useEffect(() => {
    if (dropDownOptions) {
      setSearchItems(dropDownOptions);
    }
  }, [dropDownOptions]);

  const isDataLoading = useMemo(() => (
    !searchItems || !searchItems.length
  ), [searchItems]);

  const hasTailEndFunds = useMemo(() => (
    tailEndFundIds && !!tailEndFundIds.length
  ), [tailEndFundIds]);

  const handleOnFocus = () => {
    if (hasTailEndFunds) {
      setSearchItems([myTopHoldingOption]);
      setOpenMenu(true);
    }
  };

  const handleInputChange = (query) => {
    if (query) {
      if (hasTailEndFunds) {
        if (query.length >= 3) {
          const containsMyTopHoldingOption = searchItems.some(({value}) => (value === 'myTopHoldings'));
          if (!containsMyTopHoldingOption) {
            const updatedItems = dropDownOptions.concat([myTopHoldingOption]);
            setSearchItems(updatedItems);
          } else {
            setSearchItems(dropDownOptions);
          }
        } else {
          setSearchItems([myTopHoldingOption]);
        }
      } else {
        if (query.length >= 3) {
          setOpenMenu(true);
        } else {
          setOpenMenu(false);
        }
      }
    }
  };

  const handleOnBlur = () => {
    setOpenMenu(false);
  };

  return (
    <div className="fundsearch">
      <Snackbar/>
      <SubHeader
        isShowBackLink={false}
        saveUserPreferredFunds={saveUserPreferredFunds}
      />
      <div style={{width: '30%'}}>
        <div className="benchmark">
          <div>
            <span className="benchmark__heading">{t('tkTrackFund')}</span>
            <div className="benchmark__search">
              <CustomSelect
                isDataLoading={isDataLoading}
                isDisabled={isDataLoading}
                placeholder={t('tkSearchFunds')}
                searchItems={searchItems}
                handleOnFocus={handleOnFocus}
                handleSelection={handleChange}
                handleInputChange={handleInputChange}
                handleOnBlur={handleOnBlur}
                ref={selectRef}
                isOpenMenu={openMenu}
                option={FundBenchmarkOption}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

PrimaryFundSearch.propTypes = {
  userGuid: PropTypes.string,
  saveUserPreferredFunds: PropTypes.func,
  tailEndFundIds: PropTypes.array
};

export default PrimaryFundSearch;
