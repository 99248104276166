import React, {useEffect, useState} from 'react';
import DOMPurify from 'dompurify';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';
import PropTypes from 'prop-types';
import translator from '../../../services/translator';
import {Conditional} from '../../core/Conditional';
import Link from '../../core/Link';
import {EXTERNAL_LINK_SKIP_ATTRIBUTE} from '../../../constants/pageConstants';
import {getAppRoute} from '../../../utils/commonUtils';
import Constants from '../../../constants/appConstants';
import {READ_MORE_LINK_EVENT} from './analytics';
import './index.scss';

DOMPurify.setConfig({ ADD_ATTR: ['target'] });
const {translate: t} = translator;

const Collapsible = ({title, children, onExpandGlossaryQuestionAnswer}) => {
  const [open, setOpen] = useState(false);
  const togglePanel = () => {
    onExpandGlossaryQuestionAnswer(title);
    setOpen(!open);
  };

  return (
    <div className="glossary__item-wrapper">
      <div className="header-wrapper">
        <Link
          customClass={cn({'expand': open}, {'collapse': !open})}
          extraProps={{'analytics-attribute': READ_MORE_LINK_EVENT}}
          handleClick={togglePanel}
        />
        <Link
          handleClick={togglePanel}
          extraProps={{'analytics-attribute': READ_MORE_LINK_EVENT}}
          customClass={cn('header', {'header--selected': open})}
          label={title}
        />
      </div>
      <Conditional condition={open}>
        <div className='content'>
          {children}
        </div>
      </Conditional>
    </div>
  );
};

Collapsible.propTypes = {
  title: PropTypes.string,
  children: PropTypes.object,
  onExpandGlossaryQuestionAnswer: PropTypes.func
};

const GlossaryItems = ({children, glossaryItems}) => {
  const fieldRefs = [];

  const onNavLinkClick = (e, {label}) => {
    const [selectedItem] = fieldRefs.filter(item => (item.props.children === `- ${label} -`));
    const headerOffset = 75;
    const elementPosition = selectedItem.ref.current.getBoundingClientRect().top;
    const offsetPosition = elementPosition - headerOffset;

    window.scroll(0, offsetPosition);
  };

  const recursiveInjectProps = (children) => {
    let childPropsChildren;

    return React.Children.map(children, (child) => {
      if (child && child.props && child.props.children) {
        childPropsChildren = recursiveInjectProps(child.props.children);
      } else {
        childPropsChildren = null;
      }
      if (child.type === 'div' && child.props && child.props.className === 'glossary__section-header') {
        const ref = React.createRef();
        fieldRefs.push({ref, props: child.props});
        return React.cloneElement(child, {ref}, childPropsChildren);
      } else if(child.type) {
        return React.cloneElement(child, {}, childPropsChildren);
      }
      return child;
    });
  };

  return (
    <React.Fragment>
      <div className="glossary__navlinks">
        {
          glossaryItems.map(({section, items}) => {
            const isDisabled = isEmpty(items);
            return (
              <Link
                label={section}
                customClass={cn('glossary__link', {'glossary__link--disabled': isDisabled})}
                isDisabled={isDisabled}
                handleClick={onNavLinkClick}
              />
            );
          })
        }
      </div>
      {
        recursiveInjectProps(children)
      }
    </React.Fragment>
  );
};

GlossaryItems.propTypes = {
  children: PropTypes.object,
  glossaryItems: PropTypes.array
};

const GlossaryDetails = ({
   glossaryContents = [],
   onPageLoad,
   onExpandGlossaryQuestionAnswer,
   glossaryPageLoadEventData} = {}
) => {
  useEffect(() => {
    // Dispatch analytics call
    onPageLoad(glossaryPageLoadEventData);
  }, []);

  const onScrollToTopClick = () => {
    window.scrollTo({
      top: 0,
      left: 100,
      behavior: 'smooth'
    });
  };

  return (
    <div className="glossary main-container__content">
      <div className="glossary__top-section">
        <span className="heading">{t('tkGlossary')}</span>
        <div className="glossary__info">
          <div className="subheading">
            {t('tkBrowseGlossaryDefinitions')}
            <Link
              label={`${t('tkFAQ')}.`}
              href={getAppRoute(Constants.FAQ_ROUTE)}
              extraProps={{[EXTERNAL_LINK_SKIP_ATTRIBUTE]: true}}
            />
          </div>
        </div>
      </div>
      <div className="glossary__nav-section">
        <GlossaryItems glossaryItems={glossaryContents}>
          {
            glossaryContents.map(({section, items}) => (
              <Conditional condition={!isEmpty(items)}>
                <div className='glossary__details'>
                  <div className="glossary__section-header">{`- ${section} -`}</div>
                  {
                    items.map(({title, details}) => (
                      <Collapsible
                        title={title}
                        onExpandGlossaryQuestionAnswer={onExpandGlossaryQuestionAnswer}>
                        <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(details || '--')}}/>
                      </Collapsible>
                    ))
                  }
                  <div className='glossary__back-to-top'>
                    <Link
                      handleClick={onScrollToTopClick}
                    />
                    <div className="title" onClick={onScrollToTopClick}>
                      {t('tkBackToTop')}
                    </div>
                  </div>
                </div>
              </Conditional>
            ))
          }
        </GlossaryItems>
      </div>
    </div>
  );
};

GlossaryDetails.propTypes = {
  glossaryContents: PropTypes.object,
  onPageLoad: PropTypes.func,
  glossaryPageLoadEventData: PropTypes.object,
  onExpandGlossaryQuestionAnswer: PropTypes.func
};

export default GlossaryDetails;
