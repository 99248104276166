import React from 'react';
import PropTypes from 'prop-types';

import TreeList from './Tree';
import SimpleList from './SimpleList';

const List = (props) => {
  const {type} = props;
  return  type === 'tree' ? <TreeList {...props} /> : <SimpleList {...props} />;
};

List.propTypes = {
  type: PropTypes.string
};

export default List;
