import React  from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import TradeApprovals from './tradeApprovals';
import {Conditional} from '../../../components/core/Conditional';
import {SHOW_ERROR_ON} from '../../../constants/pageConstants';
import Error from '../../../components/core/Error';
import {checkIsPageInErrorMode, getErrorObject} from '../../../selectors/pages/fundFinder';
import {getLabel} from '../../../selectors/app';


export const Approvals = (props) => {
  const {label, errorObject} = props;
  const error = {
    code: errorObject && errorObject.errorCode,
    label
  };
  return (
    <Conditional condition={!(props.isPageInErrorMode &&
      (errorObject.showErrorOn === SHOW_ERROR_ON.PAGE))}>
      <TradeApprovals />
      <div className="js-main-content main-container__content">
        <Error {...error} />
      </div>
    </Conditional>
  );
};

Approvals.propTypes = {
  isPageInErrorMode: PropTypes.bool,
  label: PropTypes.func,
  errorObject: PropTypes.object
};

const mapStateToProps = (state) => (
  {
    isPageInErrorMode: checkIsPageInErrorMode(state),
    errorObject: getErrorObject(state),
    label: key => getLabel(state, key)
  }
);
export default connect (mapStateToProps)(Approvals);
