
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {  LoadingIcon } from "@gs-ux-uitoolkit-react/loading";
import {dateFormatter, currencyFormatter, booleanFormatter } from '../../../utils/portfolioFormatter';
import {toSentenceCaseFormatter} from "../../../utils/portfolioGridFormatter";
import Repeater from '../../core/Repeater';
import {Conditional} from '../../core/Conditional';
import translator from "../../../services/translator";
import AdjustedYieldToggler from './FundRebate/components/AdjustedYieldToggler/index';
import './index.scss';
import FundRebate from "./FundRebate";
const {translate: t} = translator;

const RegistrationItem = ({item}) => {
  return (
    <div className='factData'>
      <span className={cn('fundFactView__label')}>{item}</span>
    </div>
  );
};

RegistrationItem.propTypes = {
  item: PropTypes.object,
};

const FieldItem = ({item, index, title}) => {

  return (

    <div className='factData'>
      <span className='title'>
        {index ? ' ' : (title || item)}
      </span>
      <span className='factData-item'>
        {(typeof item === 'object' && item !== null) && item.navTime || title}
      </span>
    </div>
  );
};

FieldItem.propTypes = {
  title: PropTypes.string,
  item: PropTypes.object,
  index: PropTypes.number
};


function getAsOfTime(time, label) {
  return (time && `${label}${dateFormatter(time)}`);// use formatter here
}

function getValue(fieldType, fieldValue, labels, unAvailable) {
  return (fieldType === 'group' || fieldType === 'subGroup') ?  (getAsOfTime(fieldValue, labels.tkAsOf) || '') :  (fieldValue ||  unAvailable);
}

function filterBySubCategory(items, subCategory) {
  return items.filter(item => {
    if (item.subCategory){
      return (item.subCategory === subCategory);
    }
    return true;
  });
}

const Item = ({item, items, fundData, labels, registrationState, onChangeYieldState}) => {
  const {field, headerName, formatters} = item;

  let fieldValue = fundData[field];
  if(fieldValue && formatters && formatters.indexOf('currencyFormatterWithCodeName') !== -1) {
    const value = currencyFormatter({value: fieldValue, fundCcy: fundData.fundCcy});
    fieldValue = value && value.value;
  }
  if(fieldValue && formatters && formatters.indexOf('toSentenceCaseFormatterWithCodeName') !== -1) {
    const value = toSentenceCaseFormatter({value: fieldValue});
    fieldValue = value && value.value;
  }

  const title = labels[headerName] || headerName;

  let classType = 'factData';
  if(item.fieldType === 'group'){
    classType = 'factHead';
  }else if(item.fieldType === 'subGroup'){
    classType = `factSubHead ${headerName}`;
  }

  // Handling 'Unavailable' vs. '--'
  let unAvailable = labels.tkUnavailable;
  if (item.category === 'tkESGCharacteristics') {
    unAvailable = labels.tkNullDataPlaceholder;
  }

  fieldValue = getValue(item.fieldType, fieldValue, labels, unAvailable);

  if (Array.isArray(fieldValue) && fieldValue.length && field === "registrationField") {
    return (
      <Repeater items={fieldValue}>
        <RegistrationItem />
      </Repeater>
    );
  }

  if (headerName === 'tkPerformance' && fundData.rebateAmount) {
    return (
      <div>
        <div className={classType}>
          <span className={cn('fundFactView__label')}>{title}</span>
          <AdjustedYieldToggler onChangeYieldState={onChangeYieldState} fundIdentifier={fundData.isin}/>
          <span className='factData-item'>{(typeof(fieldValue) === typeof(true)) ? booleanFormatter(fieldValue) : fieldValue}</span>
        </div>
      </div>
    )
  }

  if (registrationState && headerName === "tkRegistrationInformation") {
    return (
      <>
        <div className={classType}>
          <span className="accountFactLabel">
            <span>{title}</span>
            <Conditional condition={registrationState === 1}>
              <span className="fundFactView__loadingSpinner">
                <LoadingIcon shape="circle" size="sm"/>
              </span>
            </Conditional>
          </span>
        </div>
        <Conditional condition={registrationState === 2}>
          <div className='factData'>{t("tkRegistrationErrorMsg")}</div>
        </Conditional>
      </>
    )
  }

  if (headerName === 'tkFundRebate' || item.category === 'tkFundRebate') {
    return (
      <Conditional condition={headerName === 'tkFundRebate'}>
        <FundRebate items={items} labels={labels} onChangeYieldState={onChangeYieldState}/>
      </Conditional>
    )
  }

  return (
    <Conditional condition={Array.isArray(fieldValue)}>
      <Repeater items={fieldValue} props={{title, classType}}>
        <FieldItem />
      </Repeater>
      <Conditional condition={(item.category !== 'tkESGCharacteristics' && item.category !== 'tkSettlementInstructions' &&
        item.category !== 'tkDebitCreditInstructions')|| false}>
        <div className={classType}>
          <span className={cn('fundFactView__label')}>{title}</span>
          <span className='factData-item'>{(typeof(fieldValue) === typeof(true)) ? booleanFormatter(fieldValue) : fieldValue}</span>
        </div>
      </Conditional>
    </Conditional>
  );
};

Item.propTypes = {
  labels: PropTypes.object,
  item: PropTypes.object,
  items: PropTypes.array,
  registrationState: PropTypes.number,
  fundData: PropTypes.object,
  onChangeYieldState: PropTypes.func,
};

const CardBody = ({items, fundData, labels, registrationState}) => {
  const selectedItems = fundData.rebateAmount?
    filterBySubCategory(items, 'Adjusted') :
    filterBySubCategory(items, 'Unadjusted');

  const [updatedItems, setUpdatedItems] = useState(selectedItems);
  const onChangeYieldState = (value) => {
    setUpdatedItems(filterBySubCategory(items, value));
  };

  return (
    <Repeater items={updatedItems} props={{items, labels, fundData, registrationState, onChangeYieldState}}>
      <Item />
    </Repeater>
  );
};

CardBody.propTypes = {
  items: PropTypes.array,
  labels: PropTypes.object,
  registrationState: PropTypes.number,
  fundData: PropTypes.object
};

export default CardBody;
