import { ButtonBase, ButtonProps } from './button-base';
import { IconBaseProps } from '@gs-ux-uitoolkit-react/icon-font';
const addIcon: IconBaseProps = { name: 'add', type: 'filled' };
/**
 * The Create button
 * @param props the props of the edit button
 */
export const ButtonCreate = (props: ButtonProps) => {
    const icon = props.icon || addIcon;
    return (
        <div className="gs-uitk-create-button-container">
            <ButtonBase
                icon={icon}
                label={props.label || 'Create'}
                disabled={props.disabled}
                onClick={props.onClick}
                className={props.className}
                displayType={props.displayType}
                size={props.size}
                actionType={props.actionType || 'secondary'}
            />
        </div>
    );
};
