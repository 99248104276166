export default {
  FETCH_FUND_FINDER_MANAGERS_CONFIGURATIONS: {
    'path': `/api/v1/fundfinder/fundmanagers`,
    'method': 'GET'
  },
  FETCH_FUND_FINDER_CURRENCIES: {
    'path': `/api/v1/fundfinder/fundcurrencies`,
    'method': 'GET'
  },
  FETCH_FUND_TYPES: {
    'path': `/api/v1/fundfinder/fundinvestmenttypes`,
    'method': 'GET'
  },
  FETCH_FUND_FINDER_DATA: {
    'path': '/api/v1/fundfinder/funds',
    'method': 'POST',
    'parameters': {
      'preferences.fundFinder.openAccountsOnly': 'openAccountsOnly',
      'preferences.fundFinder.institutionalShareClassOnly': 'institutionalShareClassOnly',
      'preferences.fundFinder.fundTypes': 'fundTypes',
      'preferences.fundFinder.currencies': 'currencies',
      'pageContext.fundManagers':  "fundManagers",
      "pageContext.view": "view"
    }
  }
};
