import {
    CssPropertyType,
    CssClassDefinitionsObject,
    StyleSheet,
    getEmotionInstance,
} from '@gs-ux-uitoolkit-common/style';
import { mix } from 'polished';
import { colors } from '@gs-ux-uitoolkit-common/design-system';
import {
    loadingIconDefaultProps,
    LoadingIconSize,
    LoadingIconShape,
    LoadingIconColorName,
} from './loading-icon-props';
import {
    Theme,
    ThemeTypographyVariant,
    createComponentClassDefinitions,
} from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './loading-icon-theme-overrides';

export interface LoadingIconStyleSheetProps {
    color?: LoadingIconColorName;
    size?: LoadingIconSize;
    shape?: LoadingIconShape;
    theme: Theme;
}

export interface LoadingIconCssClasses {
    root: string;
    center: string;
    'corner-top-left': string;
    'corner-top-right': string;
    'corner-bottom-left': string;
    'corner-bottom-right': string;
    'pulse-icon': string;
    label: string;
    'circle-icon': string;
}

export type LoadingIconStyledClasses = CssClassDefinitionsObject<keyof LoadingIconCssClasses>;

export interface LoadingIconStyleOverridesParams {
    props: DeepReadonly<LoadingIconStyleSheetProps>;
    createDefaultStyledClasses: () => LoadingIconStyledClasses;
}

const animationFrames = 39;
const animationFrameRate = 100 / animationFrames;
const animationFullDuration = 70 * animationFrames;
const animationFrameDuration = animationFullDuration / animationFrames;

function paperLoadingState(transform: string, stroke: string, strokeWidth?: number): string {
    return `
        transform: ${transform};
        fill: ${stroke};
        stroke: ${stroke};
        stroke-width: ${strokeWidth || 0}px;
        stroke-linecap: square; 
    `;
}

function paperCenterAnimation(colorLight: string, colorDark: string): string {
    return getEmotionInstance().keyframes`
        0% {
            fill: ${colorLight};
            stroke: ${colorLight};
        }
        ${animationFrameRate * 9}% {
            fill: ${colorLight};
            stroke: ${colorLight};
        }
        ${animationFrameRate * 11}% {
            fill: ${colorDark};
            stroke: ${colorDark};
        }
        ${animationFrameRate * 29}% {
            fill: ${colorDark};
            stroke: ${colorDark};
        }
        ${animationFrameRate * 31}% {
            fill: ${colorLight};
            stroke: ${colorLight};
        }`;
}

function paperFoldAnimation(
    rotateY: number,
    colorLight: string,
    colorMed: string,
    colorDark: string
): string {
    const transform0 = `rotate3d(0, 0, 0, 0deg)`;
    const transform45 = `rotate3d(1, ${rotateY}, 0, 45deg)`;
    const transform90 = `rotate3d(1, ${rotateY}, 0, 90deg)`;
    const transform135 = `rotate3d(1, ${rotateY}, 0, 135deg)`;
    const transform180 = `rotate3d(1, ${rotateY}, 0, 180deg)`;

    return getEmotionInstance().keyframes`
        0% {
            ${paperLoadingState(transform0, colorLight, 2)}
        }
        ${animationFrameRate * 2}% {
            ${paperLoadingState(transform45, mix(0.25, colorLight, colorMed))};
        }
        ${animationFrameRate * 3}% {
            ${paperLoadingState(transform90, mix(0.5, colorLight, colorMed))}
        }
        ${animationFrameRate * 4}% {
            ${paperLoadingState(transform135, mix(0.75, colorLight, colorMed))}
        }
        ${animationFrameRate * 5}% {
            ${paperLoadingState(transform180, colorMed)}
        }
        ${animationFrameRate * 8}% {
            ${paperLoadingState(transform180, colorMed)}
        }
        ${animationFrameRate * 9}% {
            ${paperLoadingState(transform135, mix(0.25, colorMed, colorDark))}
        }
        ${animationFrameRate * 10}% {
            ${paperLoadingState(transform90, mix(0.5, colorMed, colorDark))}
        }
        ${animationFrameRate * 11}% {
            ${paperLoadingState(transform45, mix(0.75, colorMed, colorDark))}
        }
        ${animationFrameRate * 12}% {
            ${paperLoadingState(transform0, colorDark, 2)}
        }

        ${animationFrameRate * 20}% {
            ${paperLoadingState(transform0, colorDark, 2)}
        }
        ${animationFrameRate * 21}% {
            ${paperLoadingState(transform45, mix(0.25, colorDark, colorMed), 2)}
        }
        ${animationFrameRate * 22}% {
            ${paperLoadingState(transform90, mix(0.5, colorDark, colorMed), 2)}
        }
        ${animationFrameRate * 23}% {
            ${paperLoadingState(transform135, mix(0.75, colorDark, colorMed), 2)}
        }
        ${animationFrameRate * 24}% {
            ${paperLoadingState(transform180, colorMed)}
        }
        ${animationFrameRate * 28}% {
            ${paperLoadingState(transform180, colorMed)}
        }
        ${animationFrameRate * 29}% {
            ${paperLoadingState(transform135, mix(0.25, colorMed, colorLight))}
        }
        ${animationFrameRate * 30}% {
            ${paperLoadingState(transform90, mix(0.5, colorMed, colorLight))}
        }
        ${animationFrameRate * 31}% {
            ${paperLoadingState(transform45, mix(0.75, colorMed, colorLight))}
        }
        ${animationFrameRate * 32}% {
            ${paperLoadingState(transform0, colorLight, 2)}
        }
    `;
}

export const loadingIconStyleSheet = new StyleSheet(
    'loading-icon',
    (props: LoadingIconStyleSheetProps) => {
        return createComponentClassDefinitions<
            LoadingIconStyleSheetProps,
            LoadingIconStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.loadingIcon);
    }
);

function createDefaultStyledClasses({
    theme,
    size = loadingIconDefaultProps.size,
    color = loadingIconDefaultProps.color,
    shape = loadingIconDefaultProps.shape,
}: LoadingIconStyleSheetProps): LoadingIconStyledClasses {
    let squareColorLight = colors.blue040;
    let squareColorMedium = colors.blue060;
    let squareColorDark = colors.blue060;
    let iconOpacity = 1.0;
    let circleColor = theme.color.blue.bold;

    if (color === 'gray') {
        squareColorLight = colors.gray010;
        squareColorMedium = colors.gray020;
        squareColorDark = colors.gray030;
        circleColor = theme.color.gray.bold;
        iconOpacity = 0.65;
    }

    const circleCircumference =
        2 * Math.PI * parseInt(loadingIconSizeVariants[size].circleRadius, 10);

    const spinAnimation = getEmotionInstance().keyframes`
    0% {
        transform: rotate(0deg);
        stroke-dashoffset: ${0 * circleCircumference};
    }
    50% {
        transform: rotate(720deg);
        stroke-dashoffset: ${1 * circleCircumference};
    }
    100% {
        transform: rotate(1080deg);
        stroke-dashoffset: ${0 * circleCircumference};
    }
    `;

    const cornerAnimation = {
        animationTimingFunction: 'cubic-bezier(0.55, 0.085, 0.68, 0.53)',
    };

    return {
        root: {
            display: 'block',
            position: 'relative',
            svg: {
                fill: shape === 'square' ? squareColorLight : circleColor,
                stroke: shape === 'square' ? squareColorLight : circleColor,
                opacity: iconOpacity,
                width: '100%',
            },
        },
        label: {
            ...theme.typography[loadingIconSizeVariants[size].labelTypographyVariant],
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            paddingTop: loadingIconSizeVariants[size].labelPaddingTop,
        },
        center: {
            animation: `${paperCenterAnimation(
                squareColorLight,
                squareColorDark
            )} ${animationFullDuration}ms linear infinite`,
        },
        'corner-top-left': {
            transformOrigin: '25% 25%',
            animation: `${paperFoldAnimation(
                -1,
                squareColorLight,
                squareColorMedium,
                squareColorDark
            )} ${animationFullDuration}ms linear infinite`,
            ...cornerAnimation,
        },
        'corner-top-right': {
            transformOrigin: '75% 25%',
            animation: `${paperFoldAnimation(
                1,
                squareColorLight,
                squareColorMedium,
                squareColorDark
            )} ${animationFullDuration}ms linear ${animationFrameDuration * 2}ms infinite`,
            ...cornerAnimation,
        },
        'corner-bottom-right': {
            transformOrigin: '75% 75%',
            animation: `${paperFoldAnimation(
                -1,
                squareColorLight,
                squareColorMedium,
                squareColorDark
            )} ${animationFullDuration}ms linear ${animationFrameDuration * 4}ms infinite`,
            ...cornerAnimation,
        },
        'corner-bottom-left': {
            transformOrigin: '25% 75%',
            animation: `${paperFoldAnimation(
                1,
                squareColorLight,
                squareColorMedium,
                squareColorDark
            )} ${animationFullDuration}ms linear ${animationFrameDuration * 6}ms infinite`,
            ...cornerAnimation,
        },
        // Used for IE 11 to display a simple icon since animated svg's dont work
        'pulse-icon': {
            fontSize: loadingIconSizeVariants[size].fontSize,
            color: squareColorMedium,
        },
        'circle-icon': {
            cx: loadingIconSizeVariants[size].circleCx,
            cy: loadingIconSizeVariants[size].circleCy,
            r: loadingIconSizeVariants[size].circleRadius,
            strokeWidth: loadingIconSizeVariants[size].circleStroke,
            fill: 'transparent',
            animation: `${spinAnimation} 3s ease-in-out infinite`,
            transform: 'rotate(-90deg)',
            transformOrigin: '50% 50%',
            strokeDasharray: circleCircumference,
        },
    };
}

const loadingIconSizeVariants: { [name in LoadingIconSize]: LoadingIconSizeVariant } = {
    sm: {
        squareDimensionWidth: '16px',
        squareDimensionHeight: '16px',
        circleRadius: '24px',
        circleStroke: '8px',
        circleCx: '32px',
        circleCy: '32px',
        circleDimensionWidth: '24px',
        circleDimensionHeight: '24px',
        labelPaddingTop: '12px',
        labelTypographyVariant: 'body03',
        fontSize: '16px',
    },
    md: {
        squareDimensionWidth: '32px',
        squareDimensionHeight: '32px',
        circleRadius: '48px',
        circleStroke: '10px',
        circleCx: '58px',
        circleCy: '58px',
        circleDimensionWidth: '48px',
        circleDimensionHeight: '48px',
        labelPaddingTop: '14px',
        labelTypographyVariant: 'body02',
        fontSize: '32px',
    },
    lg: {
        squareDimensionWidth: '64px',
        squareDimensionHeight: '64px',
        circleRadius: '64px',
        circleStroke: '12px',
        circleCx: '76px',
        circleCy: '76px',
        circleDimensionWidth: '64px',
        circleDimensionHeight: '64px',
        labelPaddingTop: '18px',
        labelTypographyVariant: 'body02',
        fontSize: '64px',
    },
};

interface LoadingIconSizeVariant {
    squareDimensionWidth: CssPropertyType['width'];
    squareDimensionHeight: CssPropertyType['height'];
    circleRadius: string;
    circleStroke: string;
    circleCx: string;
    circleCy: string;
    circleDimensionWidth: CssPropertyType['width'];
    circleDimensionHeight: CssPropertyType['height'];
    labelPaddingTop: CssPropertyType['paddingTop'];
    labelTypographyVariant: ThemeTypographyVariant;
    fontSize: CssPropertyType['fontSize'];
}
