import { AnalyticsItem, AbstractAnalytics } from 'gs-uitk-analytics-common';
import { getBrowserName, getIsTouchDevice, getOsName, isMobile } from './helper';

/**
 * This is the analytics class that handles all analytic call for all components
 */
export class Analytics extends AbstractAnalytics {
    /**
     * Posts the data as a JSON string to the configured endpoint. Does not wait
     * for a return or perform a callback. If this call fails the error will not leak to the window.
     * @param {Object} data The data to be posted to the endpoint
     */
    protected post(data: AnalyticsItem[]) {
        return new Promise<void>((resolve, reject) => {
            const xmlhttp = new XMLHttpRequest();
            xmlhttp.open('POST', this.config.url);
            xmlhttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            xmlhttp.withCredentials = !!this.config.credentials;
            xmlhttp.onreadystatechange = () => {
                if (xmlhttp.readyState === 4) {
                    // request complete
                    if (xmlhttp.status >= 200 && xmlhttp.status <= 299) {
                        resolve();
                    } else {
                        reject();
                    }
                }
            };
            xmlhttp.send(JSON.stringify(data));
        });
    }

    protected getClientConfig(): AnalyticsItem['context']['client'] {
        return {
            os: {
                name: getOsName(),
            },
            browser: {
                userAgent: navigator.userAgent,
                name: getBrowserName(),
                isMobile: isMobile(),
                isTouchDevice: getIsTouchDevice(),
            },
            screen: {
                height: window.screen.height,
                width: window.screen.width,
                viewportWidth: window.innerWidth || 0,
                viewportHeight: window.innerHeight || 0,
            },
        };
    }
}
