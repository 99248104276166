import {
    StyleSheet,
    CssClassDefinitionsObject,
    CssProperties,
} from '@gs-ux-uitoolkit-common/style';

import {
    CommonSelectMultipleProps,
    selectMultipleDefaultProps,
    SelectPlacement,
} from '../select-props';
import {
    baseInputStyles,
    commonContainerInnerStyles,
    disabledMenuOptionStyles,
    menuOptionStyles,
    groupHeadingStyles,
    getRootStyles,
    isLargeSize,
    getRemoveButtonStyles,
    getClearAllButtonStyles,
    getMenuStyles,
    getSelectListStyles,
    isSmallSize,
    listStyles,
    placeholderStyles,
    selectableOptionStyles,
    SelectClassNames,
} from './common-styles';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './select-multiple-theme-overrides';

export const inputPlaceholderHasSelectedOptionsAttr = 'data-has-selected-option';

/**
 * Interface used to describe the SelectMultiple CSS props.
 */
export interface SelectMultipleStyleSheetProps {
    /**
     * The size of the component
     */
    size: CommonSelectMultipleProps['size'];

    /**
     * Status of the component
     */
    status: CommonSelectMultipleProps['status'];

    /**
     * The CSS classes names used to style the component.
     */
    cssClassNames: SelectClassNames;

    /**
     * By default, the component displays as "block" and expands to the full
     * width of its container. To disable this behavior, set to true.
     */
    inline: CommonSelectMultipleProps['inline'];
    /**
     * Shows clear button
     */
    clearable: boolean | undefined;

    /**
     * The content that gets shown in the menu when no options are remaining in the select
     */
    noOptionsContent: CommonSelectMultipleProps['noOptionsContent'];

    /**
     * Where the dropdown menu should open
     */
    menuPlacement?: SelectPlacement;
    /**
     * The corresponding theme passed to the component
     */
    theme: Theme;
}

/**
 * Overrides the common select-multiple style for inner container
 */
function selectMultipleCommonContainerInnerPadding(
    status: SelectMultipleStyleSheetProps['status'] = 'none',
    isLargeSizeSelect: boolean,
    clearable: boolean | undefined
) {
    const statusPadding = status && status !== 'none' ? 30 : 0;
    const sizePadding = isLargeSizeSelect ? 40 : 26;
    const clearButtonPadding = clearable ? (isLargeSizeSelect ? 40 : 24) : 0; // 24 is the maxWidth applied on the button by default

    return `0 ${statusPadding + sizePadding + clearButtonPadding}px 0 0`;
}

export interface SelectMultipleCssClasses {
    root: string;
}

export type SelectMultipleStyledClasses = CssClassDefinitionsObject<keyof SelectMultipleCssClasses>;

export interface SelectMultipleStyleOverridesParams {
    props: DeepReadonly<SelectMultipleStyleSheetProps>;
    createDefaultStyledClasses: () => SelectMultipleStyledClasses;
}

/**
 * SelectMultiple Stylesheet
 */
export const selectMultipleStyleSheet = new StyleSheet(
    'select-multiple',
    (props: SelectMultipleStyleSheetProps) => {
        return createComponentClassDefinitions<
            SelectMultipleStyleSheetProps,
            SelectMultipleStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.selectMultiple);
    }
);

function createDefaultStyledClasses({
    theme,
    size = selectMultipleDefaultProps.size,
    status = selectMultipleDefaultProps.status,
    cssClassNames,
    inline,
    clearable,
    noOptionsContent,
    menuPlacement,
}: SelectMultipleStyleSheetProps): SelectMultipleStyledClasses {
    let selectHeight = '32px';
    let iconFontSize = '20px';
    let lineHeight = '28px';
    let currentTypographyVariant = { ...theme.typography.body02 };
    const isLargeSizeSelect = isLargeSize(size);
    if (isSmallSize(size)) {
        selectHeight = '24px';
        iconFontSize = '16px';
        lineHeight = '22px';
        currentTypographyVariant = { ...theme.typography.body03 };
    } else if (isLargeSizeSelect) {
        selectHeight = '40px';
        iconFontSize = '24px';
        currentTypographyVariant = { ...theme.typography.body01 };
    }
    const clearAllButtonStyles = getClearAllButtonStyles({
        size,
        status,
        theme,
    });

    return {
        root: {
            display: inline ? 'inline-block' : 'block',
            [`.${cssClassNames.containerOuter}`]: getRootStyles({
                size,
                iconFontSize,
                selectHeight,
                cssClassNames,
                status,
                searchable: false,
                isSingleSelect: false,
                theme,
            }),
            [`.${cssClassNames.containerInner}`]: {
                ...currentTypographyVariant,
                ...commonContainerInnerStyles(selectHeight, lineHeight, status, theme),
                padding: selectMultipleCommonContainerInnerPadding(
                    status,
                    isLargeSizeSelect,
                    clearable
                ),
                ...getInnerContainerSelectedOptionsStyles(size, cssClassNames, theme),
                ...getSelectListStyles(cssClassNames, theme),
            },
            [`.${cssClassNames.itemDisabled}`]: disabledMenuOptionStyles(theme),
            [`.${cssClassNames.item}`]: {
                ...currentTypographyVariant,
                ...menuOptionStyles(theme),
                ...(noOptionsContent === ''
                    ? {
                          [`&.${cssClassNames.noChoices}`]: {
                              padding: '0!important',
                          },
                      }
                    : {}),
            },
            [`.${cssClassNames.groupHeading}`]: {
                ...theme.typography.heading07,
                ...groupHeadingStyles(theme),
            },
            [`.${cssClassNames.input}`]: {
                ...baseInputStyles('6px 0 4px 8px', size, currentTypographyVariant, theme),
                boxSizing: 'content-box', // the text was getting cut, use content box so the padding is not counted for the width
                maxWidth: '100%',
                background: theme.surface.primary,
                // choices.js sets inline styles for min-width and width dynamically with "ch" units,
                // to match the size of the user input.
                // Overwrite to full width when no options are selected yet.
                // Overwrite to 0 to handle case with FireFox not hiding correctly when options are selected.
                [`&[${inputPlaceholderHasSelectedOptionsAttr}=true]`]: {
                    '&:placeholder-shown': {
                        minWidth: '0px !important',
                        width: '0px !important',
                    },
                },
                [`&:not([${inputPlaceholderHasSelectedOptionsAttr}])`]: {
                    '&:placeholder-shown': {
                        minWidth: 'auto !important',
                        width: '100% !important',
                    },
                },
            },
            [`.${cssClassNames.inputCloned}`]: {
                border: 0,
                '&:focus': {
                    outline: 'none',
                },
            },
            [`.${cssClassNames.flippedState} .${cssClassNames.listDropdown}`]: {
                bottom: '100%',
            },
            [`.${cssClassNames.itemSelectable}`]: selectableOptionStyles(cssClassNames, theme),
            [`.${cssClassNames.highlightedState}`]: selectableOptionStyles(cssClassNames, theme),
            [`.${cssClassNames.list}`]: listStyles(cssClassNames, theme),
            [`.${cssClassNames.listDropdown}`]: getMenuStyles({
                cssClassNames,
                isSingleSelect: false,
                searchable: false,
                size,
                menuPlacement,
                theme,
            }),
            // The block of code below limits the size of the select menu and pins the
            // input when a scroll bar comes up.
            [`.${cssClassNames.list}.${cssClassNames.listDropdown}`]: {
                maxHeight: '300px',
                backgroundColor: theme.elevation['05'].background,
                [`.${cssClassNames.list}`]: {
                    maxHeight: '250px',
                    overflow: 'auto',
                },
            },
            [`.${cssClassNames.placeholder}`]: placeholderStyles(theme),
            [`.${cssClassNames.button}`]: getRemoveButtonStyles({
                size,
                rightOffset: '7px',
                isSingleSelect: false,
                isMultipleSelectClearable: true,
            }),
            '.gs-uitk-select-multiple__clear-all-button': { ...clearAllButtonStyles },
            [`.${cssClassNames.containerInner}.gs-uitk-select-multiple__constrained-inner`]: {
                whiteSpace: 'nowrap',
                display: 'flex',
            },
            [`.${cssClassNames.list}.gs-uitk-select-multiple__constrained-list`]: {
                whiteSpace: 'nowrap',
            },
            [`.${cssClassNames.item}.gs-uitk-select-multiple__hide-item`]: {
                //angular causing re-renders using display none
                position: 'absolute',
                opacity: '0',
                pointerEvents: 'none',
            },
            [`.${cssClassNames.item}.gs-uitk-select-multiple__constrained-item`]: {
                paddingRight: '12px',
            },
            [`.${cssClassNames.containerInner}.gs-uitk-select-multiple__constrained-inner .gs-uitk-select-multiple__input`]:
                {
                    //odd issue where the input pushes the pills of to the left out of visibility randomly
                    minWidth: '0px!important',
                },
        },
    };
}

/**
 * Returns the styles for the selectOption "pills"
 *
 * @param size the size of the component.
 */
export function getInnerContainerSelectedOptionsStyles(
    size: SelectMultipleStyleSheetProps['size'],
    cssClassNames: SelectClassNames,
    theme: Theme
): CssProperties {
    let padding = '0 24px 0 12px';
    let margin = '4px 0 4px 4px';

    if (isSmallSize(size)) {
        padding = '0 22px 0 8px';
        margin = '2px 0 2px 4px';
    }

    if (isLargeSize(size)) {
        padding = '0 28px 0 8px';
        margin = '7px 0 7px 5px';
    }

    const commonStyles = {
        display: 'inline-block',
        color: theme.getColorInteractionShades('information', 'subtle').text,
        padding,
        borderRadius: '10px',
        border: '1px solid transparent',
        backgroundColor: theme.getColorInteractionShades('information', 'subtle').background,
        margin,
        verticalAlign: 'top',
    };

    return {
        [`.${cssClassNames.itemSelectable}`]: {
            ...commonStyles,
            backgroundColor: theme.getColorInteractionShades('information', 'subtle').background,
        },
        [`.${cssClassNames.highlightedState}`]: {
            ...commonStyles,
            backgroundColor: `${theme.status.information.bold} !important`,
            color: theme.text.reversed,
            [`.${cssClassNames.button}::after`]: {
                color: theme.text.reversed,
            },
        },
    };
}
