import { TypographyVariant } from '@gs-ux-uitoolkit-common/design-system';
import {
    StyleSheet,
    CssPropertyType,
    CssProperties,
    CssClassDefinitionsObject,
} from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { LinkEmphasis, LinkSize } from './link-props';
import { DeepReadonly } from 'ts-essentials';
import './link-theme-overrides';

export type LinkStyleSheetProps = {
    theme: Theme;
    emphasis?: LinkEmphasis;
    size?: LinkSize;
};

export interface LinkSizeVariant {
    iconInnerSidePadding: CssPropertyType['padding'];
    iconSize: CssPropertyType['fontSize'];
    typographyVariant: TypographyVariant;
}

export function getLinkSizeVariants(theme: Theme): { [name in LinkSize]: LinkSizeVariant } {
    return {
        sm: {
            iconInnerSidePadding: '2px',
            iconSize: '14px',
            typographyVariant: theme.typography.body03,
        },
        md: {
            iconInnerSidePadding: '4px',
            iconSize: '16px',
            typographyVariant: theme.typography.body02,
        },
        lg: {
            iconInnerSidePadding: '4px',
            iconSize: '18px',
            typographyVariant: theme.typography.body01,
        },
    };
}

export function applyIconPosition(props: { size?: LinkSize; theme: Theme }): CssProperties {
    const { size = 'md', theme } = props;

    return {
        "> [data-gs-uitk-component='icon']:first-child:not(:only-child)": {
            // leading icon
            paddingRight: getLinkSizeVariants(theme)[size].iconInnerSidePadding,
            fontSize: getLinkSizeVariants(theme)[size].iconSize,
            verticalAlign: 'sub',
        },

        "> [data-gs-uitk-component='icon']:last-child:not(:only-child)": {
            // trailing icon
            paddingLeft: getLinkSizeVariants(theme)[size].iconInnerSidePadding,
            fontSize: getLinkSizeVariants(theme)[size].iconSize,
            verticalAlign: 'sub',
        },
    };
}

export interface LinkCssClasses {
    root: string;
    anchor: string;
}

export type LinkStyledClasses = CssClassDefinitionsObject<keyof LinkCssClasses>;

export interface LinkStyleOverridesParams {
    props: DeepReadonly<LinkStyleSheetProps>;
    createDefaultStyledClasses: () => LinkStyledClasses;
}

export const linkStyleSheet = new StyleSheet('link', (props: LinkStyleSheetProps) => {
    return createComponentClassDefinitions<LinkStyleSheetProps, LinkStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.link
    );
});

function createDefaultStyledClasses({
    theme,
    emphasis,
    size,
}: LinkStyleSheetProps): LinkStyledClasses {
    const textDecoration = emphasis === 'bold' ? 'underline' : 'none';
    const sizeVariant = size ? getLinkSizeVariants(theme)[size].typographyVariant : {};

    return {
        root: applyIconPosition({ size, theme }),
        anchor: {
            ...sizeVariant,
            textDecoration,
            borderRadius: '1px',
            '&:focus': {
                color: `${theme.colorScheme.primary}`,
                outline: 'none',
                boxShadow: `0 0 0 1px ${theme.colorScheme.primary}, 0 0 0 1px ${theme.colorScheme.primary}`,
            },
            '&:link': {
                color: `${theme.colorScheme.primary}`,
            },
            '&:hover': {
                color: `${theme.increaseContrast(theme.colorScheme.primary, 1)}`,
            },
            '&:active': {
                color: `${theme.increaseContrast(theme.colorScheme.primary, 1)}`,
            },
            '&:visited': {
                color: `${theme.increaseContrast(theme.color.purple.bold, 1)}`,
            },
        },
    };
}
