import { StyleSheet } from '@gs-ux-uitoolkit-react/style';
import { MOBILE_BREAKPOINT } from '../style-constant';
import { lightTheme, Theme } from '@gs-ux-uitoolkit-common/theme';

interface CustomSortStyleSheetProps {
    theme: Theme;
}

export const customSortStylesheet = new StyleSheet(
    'custom-sort',
    ({ theme }: CustomSortStyleSheetProps) => {
        const themeObject = theme || lightTheme;
        return {
            root: {
                '.gs-uitk-custom-sort-loading-icon-container': {
                    height: '100%',
                    flex: 1,
                    '.gs-uitk-loading-icon': {
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        top: '48%',
                    },
                },
                overflowY: 'scroll',
                height: '100%',
                '.gs-uitk-column-name-column': {
                    width: '20%',
                },
                '.gs-uitk-list-column': {
                    width: '55%',
                },
                '.gs-uitk-create-container_main': {
                    height: '100%',
                    paddingLeft: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                },
                '.gs-uitk-createContainer': {
                    display: 'flex',
                    height: '80%',
                    overflow: 'hidden',
                },
                '.gs-uitk-list-selector-values-row': {
                    overflowY: 'scroll',
                    flex: 1,
                    marginLeft: 0,
                    ul: {
                        paddingLeft: '2px',
                    },
                },
                '.gs-uitk-select-all-checkbox-div': {
                    marginBottom: '8px',
                    marginTop: '8px',
                },
                '.gs-uitk-list-selector-checkbox-div': {
                    marginBottom: '8px',
                    marginTop: '8px',
                },
                '.gs-uitk-columnValueSelection': {
                    height: '100%',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    label: {
                        color: themeObject.text.primary,
                    },
                },
                '.gs-uitk-toolbar-layout-toolbar-dnd-box': {
                    marginBottom: '1rem',
                    fontSize: '12px',
                    li: {
                        paddingLeft: 0,
                        span: {
                            paddingRight: '10px',
                            paddingLeft: '2px',
                            width: '10px',
                        },
                    },
                    '.gs-list-group__item': {
                        backgroundColor: themeObject.surface.secondary,
                        color: themeObject.text.primary,
                    },
                },

                '.gs-uitk-customsort-modal__expression-preview-container': {
                    height: '100%',
                    flex: 1,
                    backgroundColor: themeObject.surface.secondary,
                    color: themeObject.text.primary,
                    overflowY: 'scroll',
                },
                '.gs-uitk-custom-sort-plugin gs-uitk-plugin-flex': {
                    maxWidth: '100%',
                    overflowX: 'hidden',
                },
                '.gs-uitk-basic-expression-preview.gs-uitk-custom-sort-preview': {
                    height: '100%',
                    overflowY: 'scroll',
                    padding: '12px 12px 12px 2px',
                },
                '.gs-uitk-criteria-container-value-list': {
                    height: '100%',
                    overflow: 'hidden',
                },
                '.gs-uitk-column-selector-container input': {
                    maxWidth: '100% !important',
                },
                'custom-sort-loading-icon-container': {
                    display: 'block',
                    margin: 'auto',
                    flex: 1,
                    height: '100%',
                    '.loading-icon': {
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        top: '48%',
                    },
                },
                '.gs-uitk-list-selector': {
                    paddingLeft: '8px',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                },
                '.gs-uitk-c-1s0epvn--custom-sort-root.gs-layout-row': {
                    flex: 0,
                },
                '.gs-uitk-column-selector-main__container': {
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '50%',
                    '.gs-form-group': {
                        flex: '1',
                        paddingRight: '18px',
                    },
                },
                '.gs-switch': {
                    marginTop: '22px',
                    marginLeft: '24px',
                },
                '@media (min-width: 1024px)': {
                    '.gs-uitk-list-selector': {
                        paddingLeft: '12px',
                    },
                },
                [`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
                    '.gs-uitk-createContainer': {
                        flexDirection: 'column',
                        display: 'block',
                    },
                    '.gs-switch': {
                        marginTop: '0',
                        marginLeft: '0',
                        width: '100%',
                    },
                    '.gs-uitk-list-selector-values-row': {
                        maxHeight: '300px',
                    },
                    '.gs-uitk-customsort-modal__expression-preview-container': {
                        height: 'auto',
                        flex: 1,
                        marginTop: '8px',
                    },
                    '.gs-uitk-create-container_main': {
                        height: 'auto',
                    },
                    '.gs-uitk-column-selector-main__container': {
                        display: 'block',
                        width: '100%',
                        '.gs-uitk-c-c0n309--form-group-container.gs-form-group': {
                            flex: '1',
                        },
                        '.gs-form-group': {
                            paddingRight: 0,
                        },
                    },
                    '.gs-uitk-c-1s0epvn--custom-sort-root.gs-layout-row': {
                        marginRight: '-8px',
                        marginLeft: '-8px',
                    },
                    '.gs-uitk-list-selector': {
                        marginLeft: 0,
                        marginRight: 0,
                        padding: 0,
                        '.gs-layout-row': {
                            marginLeft: 0,
                            marginRight: 0,
                        },
                    },
                },
            },
        };
    }
);
