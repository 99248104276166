export const getPageNameAndSections = (pageHierarchy, pageId) => {
  const pageSectionLabels = pageHierarchy[pageId] || [];
  const pageName = pageSectionLabels.join(':');
  const [section, subSection = ''] = pageSectionLabels;
  return {
    pageName,
    section,
    subSection
  };
};

export const getLanguage = () => {
  return window.navigator.userLanguage || window.navigator.language;
};

export const extendOnlyObject = (previousData, data) => {
  const obj = {...previousData};
  Object.keys(data).forEach(key => {
    if (previousData.hasOwnProperty(key)) {
      if (typeof data[key] === 'object' && data[key] !== null && !Array.isArray(data[key])) {
        obj[key] = extendOnlyObject(previousData[key], data[key]);
      } else {
        obj[key] = data[key];
      }
    } else {
      obj[key] = data[key];
    }
  });
  return obj;
};
