import { PluginsState } from '../..';
import { Plugins } from '../../plugins/plugin-enum';
import { PluginsActionTypes } from '../actions/plugins-action';

export const defaultPluginsState: PluginsState = {
    enabled: Object.values(Plugins),
    displayed: Object.values(Plugins),
};

export const pluginsReducer = (
    state: PluginsState = defaultPluginsState,
    action: PluginsActionTypes
): PluginsState => {
    switch (action.type) {
        default:
            return state;
    }
};
