import React, {useEffect, useState} from 'react';
import filter from 'lodash/filter';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import translator  from '../../../services/translator';
import Dropdown from '../../../components/core/Dropdown';
import DownloadButton from '../../../components/core/DownloadButton/DownloadButton';
import GearButton from '../../../components/core/GearButton';
import FileDownloadModal from '../FileDownload';
import PopOver from '../../../components/core/PopOver/index';
import SwitchButton from '../../../components/core/SwitchButton/index';
import {addPageContext} from '../../../actions/page/index';
import { Conditional } from '../../../components/core/Conditional';
import FilterButton from '../../../components/core/FilterButton';
import {
  getManagersList, getViewsList, isOpenAccountsOnly, getActiveColumnsName, isInstitutionalShareClassOnly,
  gridHeader, currentViewSelector, disableFileDownload, getFundInvestmentTypes, getCurrencies, getEsgList
} from '../../../selectors/pages/fundFinder';
import {MODEL_ACTION, PORTFOLIO_FILTER_STATE} from '../../../constants/pageConstants';
import { getViewChangeAnalyticsData, getManagerChangeAnalyticsData, dispatchAnalytics, getExportViewAnalyticsData } from '../analytics/index';

import {viewChange, openCustomViewModal, startExportToExcel} from '../../../actions/page/fundFinder/index';
import {
  toggleOpenAccountsOnly,
  toggleInstShareClassesOnly,
  editAppPreferences,
  managersChange,
  fundCurrenciesChange,
  fundInvestmentTypesChange,
  esgFundChange
} from '../../../actions/preferences/index';
import {labelSelector} from '../../../selectors/app';
import {isInternalUserSelector} from '../../../selectors/user';
import {allowPDFDownloadFeatureSelector} from '../../../selectors/global';
import {openDownloadModal} from "../../../actions/app/reports";

const {translate: t} = translator;

export const ControlPanel =(props) => {
  const {dispatchEsgChange, dispatchManagerChange, dispatchToggleOpenAccountsOnly, dispatchToggleShareClassesOnly, currentView,
    institutionalShareClassOnly, openAccountsOnly, dispatchViewEdit, dispatchDownloadExcel,
    disableFileDownload, isInternalUser, currencies, fundInvestmentTypes, dispatchFundCurrencyChange, dispatchFundInvestmentTypesChange,
    dispatchOpenFileDownloadModal, allowPDFDownload, esg} = props;
  const {views = [], managers = []} = props;


  const [filterVisibilityState, setFilterVisibilityState] = useState(false);
  const [isActiveShareClassOnly, setIsActiveShareClassOnly] = useState(institutionalShareClassOnly);
  const [isActiveOpenAccountOnly, setIsActiveOpenAccountOnly] = useState(openAccountsOnly);

  useEffect(() => {

  }, [managers]);

  const popoverOffset = {
    x: 0,
    y: 14
  };

  const managerLabel = (selected, all) => {
    if((selected.length + 1) >= all.length){
      return `${t('tkManagerColon')} ${t('tkAllManagers')}`;
    }else if(selected.length === 1){
      return `${t('tkManagerColon')} ${selected[0].label}`;
    }else{
      return `${t('tkManagerColon')} ${selected.length} ${t('tkManagers')}`;
    }
  };

  const currencyLabel = (selected, all) => {
    if((selected.length + 1) >= all.length){
      return `${t('tkCurrencyColon')} ${t('tkAllCurrencies').toUpperCase()}`;
    } else if(selected.length === 1){
      return `${t('tkCurrencyColon')} ${selected[0].label}`;
    } else if(selected.length === 2){
      return `${t('tkCurrencyColon')} ${selected[0].label} & ${selected[1].label}`;
    } else if(selected.length === 3){
      return `${t('tkCurrencyColon')} ${selected[0].label}, ${selected[1].label}, ${selected[2].label}`;
    } else{
      return `${t('tkCurrencyColon')} ${selected.length} ${t('tkCurrencies').toUpperCase()}`;
    }
  };

  const esgLabel = (selected, all) => {
    if((selected.length + 1) >= all.length){
      return `${t('tkIncludeAllEsg').toUpperCase()}`;
    } else if(selected.length === 1){
      return `${selected[0].label}`;
    } else{
      return `${selected.length} ${t('tkESGAttributes').toUpperCase()}`;
    }
  };

  const fundTypeLabel = (selected, all) => {


    const titleList = [];
    // for root items parentId is undefined, we are assuming that only 1 level of nesting is allowed
    const allRoot = filter(all, {parentId: undefined});

    // filter and store all root items which has its all child selected = true
    allRoot.forEach((rItem) => {
      const childLength  = rItem.items.length;
      const selectedChild = filter(rItem.items, {selected: true});
      if(childLength === selectedChild.length) {
        titleList.push(`${t('tkAll')} ${rItem.label}`);
      }
    });

    // filter all selected root items
    const selectedParents = filter(allRoot, {selected: true});

    // when all items are selected:
    // show dropdown text => FUND TYPE: ALL FUND TYPES
    if (selected.length === all.length && selectedParents.length === titleList.length) {
      return `${t('tkFundTypeColon')} ${t('tkAllFundTypes')}`.toUpperCase();
    }
    // when more than 1 fund type is selected:
    // show dropdown text => FUND TYPE: <fund types count> FUND TYPES
    else if (titleList.length > 1 && selectedParents.length === titleList.length) {
      return `${t('tkFundTypeColon')} ${titleList.length} ${t('tkFundTypes')}`.toUpperCase();
    }
    // when only 1 investment type is selected : show dropdown text => FUND TYPE: <investment type name>
    else if(selected.length === 2 && selectedParents.length === 1){
      return `${t('tkFundTypeColon')} ${selected[1].label}`.toUpperCase();
    }
    // case when only 1 fund type is selected : show dropdown text => FUND TYPE: ALL <fund type name>
    else if(titleList.length === 1 && selectedParents.length === 1) {
      return `${t('tkFundTypeColon')} ${titleList[0]}`.toUpperCase();
    }
    // when more than one investment type is selected
    // show dropdown text => FUND TYPES: <selected investment types count> INVEST TYPES
    else if(selectedParents.length >= 1 && selected.length > 2) {
      return `${t('tkFundTypeColon')} ${selected.length - selectedParents.length} ${t('tkInvestTypes')}`.toUpperCase();
    }
  };


  const changeFilterVisibility = (state) => {
    const filterVisibility = state ? PORTFOLIO_FILTER_STATE.VISIBLE : PORTFOLIO_FILTER_STATE.HIDDEN;
    props.addPageContext({filterVisibility, filterBy: [], filterModel: {}});
    setFilterVisibilityState(state);
  };

  const handleToggleFilterVisibility = () => {
    changeFilterVisibility(!filterVisibilityState);
  };

  const handleOpenAccountOnlyToggle = (state) => {
    // call dispatch action here to send server call
    dispatchToggleOpenAccountsOnly(state);
    setIsActiveOpenAccountOnly(state);
  };

  const handleShareClassOnlyToggle = (state) => {
    // call dispatch action here to send server call
    dispatchToggleShareClassesOnly(state);
    setIsActiveShareClassOnly(state);
  };

  const handleManagersApply = (selectedItems) => {
    dispatchManagerChange(selectedItems);
  };

  const handleCurrenciesApply = (selectedItems) => {
    dispatchFundCurrencyChange(selectedItems);
  };

  const handleInvestmentTypesApply = (selectedItems) => {
    dispatchFundInvestmentTypesChange(selectedItems);
  };

  const handleEsgApply = (data) => {
    dispatchEsgChange(data)
  };

  // const handleViewChange = (selectedValue, selectedItems) => {
  //   changeFilterVisibility(false);
  //   dispatchViewChange(selectedValue, selectedItems, activeColumnString);
  // };

  const handleViewEdit = () => {
    dispatchViewEdit(currentView);
  };

  const handleDownloadExcel = () => {
    dispatchDownloadExcel(props);
  };


  const handleOpenFileDownloadModal = (props) => {
    dispatchOpenFileDownloadModal(props);
  };

  const handleDownload = () => {
    if (allowPDFDownload) {
      handleOpenFileDownloadModal();
    } else {
      handleDownloadExcel();
    }
  };

  const currencyDropdownPlaceholder = currencies && currencies.length > 1 ?  t('tkCurrencyColon') :
    `${t('tkCurrencyColon')} ${t('tkAllCurrencies')}`;

  const fundTypesDropdownPlaceholder = fundInvestmentTypes && fundInvestmentTypes.length > 0 ? t('tkFundTypeColon') :
    `${t('tkFundTypeColon')} ${t('tkAllFundTypes')}`;

  const managersDropdownPlaceholder = managers && managers.length > 1  ? t('tkManagerColon') :
    `${t('tkManagerColon')} ${t('tkAllManagers')}`;


  return (
    <Conditional condition={views.length}>
      <div className="fundfinder-container__descRow1">
        <div className="fundfinder-container__action l-margin-left">
          <Dropdown
            type='tree'
            id="fund-finder-currencies-ddl"
            indentationPadding={28}
            multiSelect={true}
            onApply={handleCurrenciesApply}
            labelTemplate={currencyLabel}
            disabled={!currencies || currencies.length < 2}
            options={currencies}
            placeholder={currencyDropdownPlaceholder}
            footerCTAText={t('tkApplyChanges')}
          />
        </div>
        <div className="fundfinder-container__action l-margin-left">
          <Dropdown
            type='tree'
            id="fund-finder-investment-types-ddl"
            options={fundInvestmentTypes}
            selectParentOnAny={true}
            multiSelect={true}
            onApply={handleInvestmentTypesApply}
            labelTemplate={fundTypeLabel}
            disabled={!fundInvestmentTypes || fundInvestmentTypes.length < 0}
            placeholder={fundTypesDropdownPlaceholder}
            footerCTAText={t('tkApplyChanges')}
         />
        </div>
        <div className="fundfinder-container__action l-margin-left">
          <Dropdown
            type='tree'
            id="fund-finder-managers-ddl"
            indentationPadding={28}
            multiSelect={true}
            onApply={handleManagersApply}
            labelTemplate={managerLabel}
            options={managers}
            disabled={!managers || managers.length < 2}
            placeholder={managersDropdownPlaceholder}
            footerCTAText={t('tkApplyChanges')}
          />
        </div>
        <Conditional condition={false}>
          <div className="fundfinder-container__action l-margin-left">
            <Dropdown
              type='tree'
              id="fund-finder-esg-ddl"
              indentationPadding={28}
              multiSelect={true}
              onApply={handleEsgApply}
              labelTemplate={esgLabel}
              options={esg}
              disabled={false}
              placeholder={t('tkIncludeAllEsg')}
              footerCTAText={t('tkApplyChanges')}
            />
          </div>
        </Conditional>
        <div className="fundfinder-container__action l-margin-left">
          <FilterButton
            onClick={handleToggleFilterVisibility}
            visibilityState={filterVisibilityState}
        />
        </div>
        <div className="fundfinder-container__action">
          <div className="fundfinder-container__action-child">
            <GearButton onClick={handleViewEdit} />
          </div>
          <div className="fundfinder-container__action-child">
            <DownloadButton
              disabled={disableFileDownload}
              onClick={handleDownload}
              onKeyPress={handleDownload} />
            <FileDownloadModal currentView={currentView} />
          </div>
          <div className="fundfinder-container__action-child">
            <PopOver overlayOffset={popoverOffset} buttonClass="transparent-button icon-more-menu">
              <div className="fundfinder-container__action-dropdown-menu">
                <div className="fundfinder-container__action-dropdown-item">
                  <span className="fundfinder-container__action-zero">
                    <span className={`fundfinder-container__action-name ${isInternalUser ? 'text-disabled' : ''}`}>{t('tkOpenAccountsOnly')}</span>
                    <SwitchButton
                      active={isActiveOpenAccountOnly}
                      disabled={isInternalUser}
                      onClick={handleOpenAccountOnlyToggle}
                  />
                  </span>
                </div>
                <div className="fundfinder-container__action-dropdown-item">
                  <span className="fundfinder-container__action-zero">
                    <span className="fundfinder-container__action-name">
                      {t('tkShareClassOnly')}
                    </span>
                    <SwitchButton
                      active={isActiveShareClassOnly}
                      onClick={handleShareClassOnlyToggle}
                  />
                  </span>
                </div>
              </div>
            </PopOver>
          </div>
        </div>
      </div>
    </Conditional>
  );
};


ControlPanel.propTypes = {
  managers: PropTypes.array,
  addPageContext: PropTypes.func,
  dispatchManagerChange: PropTypes.func,
  views: PropTypes.array,
  dispatchToggleOpenAccountsOnly: PropTypes.func,
  dispatchToggleShareClassesOnly: PropTypes.func,
  openAccountsOnly: PropTypes.bool,
  dispatchViewEdit: PropTypes.func,
  dispatchEsgChange: PropTypes.func,
  dispatchFundCurrencyChange: PropTypes.func,
  institutionalShareClassOnly: PropTypes.bool,
  dispatchFundInvestmentTypesChange: PropTypes.func,
  dispatchDownloadExcel: PropTypes.func,
  disableFileDownload: PropTypes.bool,
  isInternalUser: PropTypes.bool,
  currentView: PropTypes.object,
  currencies: PropTypes.array,
  esg: PropTypes.array,
  fundInvestmentTypes: PropTypes.array,
  dispatchOpenFileDownloadModal: PropTypes.func,
  allowPDFDownload: PropTypes.bool
};

export const mapStateToProps = (state) => ({
  // currentView: currentViewSelector(state),
  institutionalShareClassOnly : isInstitutionalShareClassOnly(state),
  openAccountsOnly: isOpenAccountsOnly(state),
  managers: getManagersList(state),
  fundInvestmentTypes: getFundInvestmentTypes(state),
  esg: getEsgList(state),
  currencies: getCurrencies(state),
  views: getViewsList(state),
  activeColumnString: getActiveColumnsName(state),
  header: gridHeader(state),
  currentView: currentViewSelector(state),
  labels: labelSelector(state),
  isInternalUser: isInternalUserSelector(state),
  disableFileDownload: disableFileDownload(state),
  allowPDFDownload: allowPDFDownloadFeatureSelector(state),
});

export const mapDispatchToProps = (dispatch) => (
  {
    dispatchToggleOpenAccountsOnly: data => {
      dispatch(toggleOpenAccountsOnly(data));
      dispatch(editAppPreferences());
    },
    dispatchToggleShareClassesOnly: data =>  {
      dispatch(toggleInstShareClassesOnly(data));
      dispatch(editAppPreferences());
    },
    dispatchManagerChange: data => {
      const managers = [];
      data.forEach((item) => {
        if(item.value){
          managers.push (item.value);
        }
      });
      dispatch(managersChange(managers));
      dispatch(editAppPreferences());
      dispatchAnalytics(dispatch, getManagerChangeAnalyticsData(data));
    },

    dispatchFundInvestmentTypesChange: data => {
      const fundTypes = [];
      data.forEach((item) => {
        if(item.value && !item.parentId){
          const noItems = !item.items.length;
          const isAllItemsSelected = item.items.every((itm) => itm.selected);
          const isAllSelected = isAllItemsSelected || noItems;
          fundTypes.push ({code: item.value, isAllSelected});
        } else {
          fundTypes.forEach((fundType) => {
            if(fundType.code === item.parentId) {
              if(!fundType.investmentTypes) {
                fundType.investmentTypes = [item.value];
              }else {
                fundType.investmentTypes.push(item.value);
              }
            }
          });
        }
      });
      dispatch(fundInvestmentTypesChange(fundTypes));
      dispatch(editAppPreferences());
      // TODO: analytics
      // dispatchAnalytics(dispatch, getFundTypesChangeAnalyticsData(data));
    },

    dispatchFundCurrencyChange: data => {
      const currencies = [];
      data.forEach((item) => {
        if(item.value){
          currencies.push (item.value);
        }
      });
      dispatch(fundCurrenciesChange(currencies));
      dispatch(editAppPreferences());
      // TODO: analytics
    //  dispatchAnalytics(dispatch, getFundCurrenciesChangeAnalyticsData(data));
    },

    dispatchEsgChange: data => {
      const esg = data.filter(({value}) => value).map(({value}) => value);
      dispatch(esgFundChange(esg));
      dispatch(editAppPreferences());
    },

    dispatchViewChange: (viewName, data, colString) => {
      dispatchAnalytics(dispatch, getViewChangeAnalyticsData(colString, viewName));
      dispatch(viewChange({id: data.nodeId}));
    },
    dispatchViewEdit: (data) => {
      const params = {'modelActiveMode': MODEL_ACTION.EDIT, 'selectedViewId': data.id};
      dispatch(openCustomViewModal(params));
    },
    addPageContext: (obj) => dispatch(addPageContext(obj)),
    dispatchDownloadExcel: (props) => {
      dispatch(startExportToExcel());
      dispatchAnalytics(dispatch, getExportViewAnalyticsData(props.header, props.currentView.name, 'Excel'));
    },
    dispatchOpenFileDownloadModal: (props) => {
      dispatch(openDownloadModal(props));
    }
  });

export default connect(mapStateToProps, mapDispatchToProps)(ControlPanel);


