
import React from 'react';
import PropTypes from 'prop-types';

const withCTAFooter =  ItemComponent => (props) => {
  return (
    <ItemComponent {...props}   />
  );

};

export const Button = ({disabled, clickHandler, text = 'Apply'}) => {
  return (
    <div className="dropdown__footer">
      <button disabled={disabled }  className="button button__secondary button__secondary--small dropdown__btn-apply" onClick={clickHandler}>{text}</button>
    </div>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  clickHandler: PropTypes.func,
  text: PropTypes.string
};


export const Stationary = ({disabled, clickHandler, text = 'Apply'}) => {
  return (
    <div className="dropdown__footer left">
      {disabled || <span className="dropdown__icon dropdown__icon--invisible icon-plus footerCTA__icon" />}
      <button
        disabled={disabled}
        className={`footerCTA__stationary ${disabled ? 'disabled' : ''}`}
        onClick={clickHandler}>
        {text}
      </button>
    </div>
  );
};


Stationary.propTypes = {
  disabled: PropTypes.bool,
  clickHandler: PropTypes.func,
  text: PropTypes.string
};

const Footer  = (props) => {
  const {type = ''} = props;
  return (
    <React.Fragment>
      {(() => {
      switch(type) {
        case 'button':
          return withCTAFooter(Button)(props);
        case 'stationary':
          return withCTAFooter(Stationary)(props);
        default:
          return null;
      }
    })()
    }
    </React.Fragment>
  );
};


Footer.propTypes = {
  type: PropTypes.string
};

export default Footer;
