import { IRowNode } from '@ag-grid-community/core';
import { DataType, logger, PluginBase, PluginIcon } from '@gs-ux-uitoolkit-common/datacore';
import { ModuleIdentfier } from '../module-identfier';
import { GridWrapper } from '../../grid-wrappers/grid-wrapper';
import { AddCustomSortList } from '../../redux/actions/custom-sort-action';
import { CustomSortState, DataGridState } from '../../redux/datagrid-state';
import { Categories, Plugins } from '../plugin-enum';
import { CustomSortPluginModal } from './view/react/custom-sort-plugin-modal';
import { OpenGridConfiguration } from '../../redux/actions/grid-configuration-action';

const mainIcon: PluginIcon = { name: 'sort-variant', type: 'filled' };
const componentId = 'CustomSortModal';

/**
 * The Custom Sort plugin. Allows the developers and end-users to create custom sorts at runtime and design time
 */
export class CustomSortPlugin extends PluginBase<GridWrapper, DataGridState, CustomSortState> {
    protected static requiredModules: ModuleIdentfier[] = [];
    private hasDefaultAbsCustomSortsBeenCreated = false;
    constructor(wrapper: GridWrapper) {
        super(
            Plugins.CustomSortPlugin,
            Categories.Data,
            mainIcon,
            wrapper,
            state => state.customSort
        );
        this.actions = [
            {
                action: OpenGridConfiguration(Plugins.CustomSortPlugin),
                componentId: 'CustomSortShortcut',
                icon: mainIcon,
                label: 'Custom Sort',
                store: wrapper.getReduxStore(),
                context: wrapper.getReduxStoreContext(),
            },
        ];
        this.screens = [
            {
                componentId,
                icon: mainIcon,
                label: 'Custom Sort',
                screen: CustomSortPluginModal,
                store: wrapper.getReduxStore(),
                context: wrapper.getReduxStoreContext(),
            },
        ];
    }

    protected stateChangedOrStart(): void {
        this.removeCustomSorts(this.getPreviousPluginState());
        this.addCustomSorts();
        if (
            this.getPluginState()?.defaultAbsoluteCustomSortNumeric &&
            !this.hasDefaultAbsCustomSortsBeenCreated
        ) {
            const customSortToCreate = this.wrapper
                .getReduxStore()
                .getState()
                .grid.columnList.filter(col => col.isSortable && col.dataType === DataType.Number)
                .filter(
                    col =>
                        !this.getPluginState()?.configItemList.find(
                            cs => cs.columnId === col.columnId
                        )
                )
                .map(col => ({
                    columnId: col.columnId,

                    absoluteSort: true,
                    absoluteSortActive: true,
                    dataList: [],
                }));
            this.hasDefaultAbsCustomSortsBeenCreated = true;
            if (customSortToCreate.length > 0) {
                this.wrapper.getReduxStore().dispatch(AddCustomSortList(customSortToCreate));
            }
            logger.debug('Default Absolute Custom Sort created', customSortToCreate);
        }
    }

    protected internalStop(): void {
        this.removeCustomSorts(this.getPluginState());
    }

    private removeCustomSorts(state: CustomSortState | null) {
        if (state !== null) {
            state.configItemList.forEach(customSort => {
                this.wrapper.removeCustomSort(customSort.columnId);
            });
        }
    }

    private addCustomSorts() {
        this.getPluginState()?.configItemList.forEach(customSort => {
            this.wrapper.addCustomSort(customSort, this.buildComparator(customSort));
        });
    }

    private buildComparator(
        customSort: CustomSort
    ): (
        valueA: any,
        valueB: any,
        nodeA?: IRowNode,
        nodeB?: IRowNode,
        isInverted?: boolean
    ) => number {
        return (valueA: any, valueB: any, nodeA?: IRowNode, nodeB?: IRowNode) => {
            const computedValueA =
                customSort.absoluteSort && customSort.absoluteSortActive
                    ? Math.abs(valueA)
                    : valueA;
            const computedValueB =
                customSort.absoluteSort && customSort.absoluteSortActive
                    ? Math.abs(valueB)
                    : valueB;

            // if we don't have the the RowNodes we return the normal compare since we cannot compute
            // the display value
            if (!nodeA || !nodeB || customSort.dataList.length === 0) {
                if (computedValueA === computedValueB) {
                    return 0;
                }
                return computedValueA < computedValueB ? -1 : 1;
            }
            const valueADisplayValue = this.wrapper.getDisplayValue(nodeA, customSort.columnId);
            const valueBDisplayValue = this.wrapper.getDisplayValue(nodeB, customSort.columnId);
            const indexValueA = customSort.dataList.indexOf(valueADisplayValue);
            const indexValueB = customSort.dataList.indexOf(valueBDisplayValue);
            const customSortContainsValueA = indexValueA >= 0;
            const customSortContainsValueB = indexValueB >= 0;

            // if custom sort contains neither value just retuen normal comparator
            if (!customSortContainsValueA && !customSortContainsValueB) {
                if (computedValueA === computedValueB) {
                    return 0;
                }
                return computedValueA < computedValueB ? -1 : 1;
            }

            // if Custom sort doesn't contain valueA then valueB comes before
            if (!customSortContainsValueA) {
                return 1;
            }

            // if Custom sort doesn't contain valueB then valueA comes before
            if (!customSortContainsValueB) {
                return -1;
            }

            // return the order in the custom sort when both values are in the custom sort
            return indexValueA - indexValueB;
        };
    }
}

/**
 * Interface representing a CustomSort
 */
export interface CustomSort {
    /**
     * Id of the column where we will apply the custom Sort
     */
    columnId: string;
    /**
     * List of the data that will defined the order
     * Those are strings as we sort on DisplayValue
     */
    dataList: string[];
    /**
     * if the sort is absolute
     */
    absoluteSort: boolean;
    /**
     * if the sort is active
     */
    absoluteSortActive: boolean;
}

export const ABSOLUTE_CLASSNAME = 'ag-header-absolute';
