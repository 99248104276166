import { FC, useContext, useEffect } from 'react';
import { ReactComponentProps } from '@gs-ux-uitoolkit-react/component';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import {
    InputIconProps as InputIconCommonProps,
    inputIconStyleSheet,
    globalInputIconClass,
} from '@gs-ux-uitoolkit-common/input';
import { cx, useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { InputIconContext } from './input-icon-context';
import { componentAnalytics } from './analytics-tracking';

export type InputIconProps = InputIconCommonProps & ReactComponentProps;

/**
 * InputIcon applies appropriate size and margin styles to an icon to provide an
 * easy way to display an icon inside of an Input or InputNumber.
 */
export const InputIcon: FC<InputIconProps> = props => {
    const { className, classes, position, status = 'none', color, disabled, ...iconProps } = props;

    const context = useContext(InputIconContext);

    const theme = useTheme();
    const cssClasses = useStyleSheet(inputIconStyleSheet, {
        theme,
        position: position || context.position,
        size: props.size || context.size,
        status,
        color,
        disabled: disabled || false,
        isTextarea: false,
    });
    const containerClasses = cx(
        cssClasses.root,
        className,
        classes && classes.root,
        globalInputIconClass
    );

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({
            componentName: 'input-icon',
            officialComponentName: 'other',
        });
    }, []); // Only run once

    // Note: data-gs-uitk-component is set by Icon.
    return <Icon {...iconProps} className={containerClasses} />;
};
