/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
// TODO: Fix a11y errors. <li> elements shouldn't have onClick(). Use a <button> inside <li>

import { Component } from 'react';
import { DataGridState } from '../../redux/datagrid-state';
import { connect, Provider } from 'react-redux';
import * as Redux from 'redux';
import { Tab, Tabs } from '@gs-ux-uitoolkit-react/tabs';
import { PluginBase, arrayHelper, PluginScreen } from '@gs-ux-uitoolkit-common/datacore';
import { GridWrapper } from '../../grid-wrappers/grid-wrapper';
import { SetGridConfigurationCurrentPluginName } from '../../redux/actions/grid-configuration-action';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { Button } from '@gs-ux-uitoolkit-react/button';
import { Text } from '@gs-ux-uitoolkit-react/text';
import { Plugins } from '../../plugins/plugin-enum';
import { gridConfigurationmodalsContainerStyleSheet } from '../../style/plugins/grid-configuration-modals-container-stylesheet';
import { Theme } from '@gs-ux-uitoolkit-react/theme';

export interface GridConfigurationModalContentProps {
    currentPluginName: string | undefined;
    plugins: PluginBase<GridWrapper, DataGridState, object>[];
    onUpdateSelectedPlugin: (pluginName: string | undefined) => void;
    theme: Theme;
    pluginState: DataGridState['plugins'];
}

export interface GridConfigurationModalContentState {
    fullScreenMode: boolean;
}

export class GridConfigurationModalContentComponent extends Component<
    GridConfigurationModalContentProps,
    GridConfigurationModalContentState
> {
    constructor(props: GridConfigurationModalContentProps) {
        super(props);
        this.state = {
            fullScreenMode: false,
        };
    }

    public componentWillUnmount() {
        gridConfigurationmodalsContainerStyleSheet.unmount(this);
    }

    public render() {
        let modalBody: JSX.Element = <div></div>;
        const pluginsWithScreens = this.props.plugins.filter(
            plugin =>
                plugin.getScreens().length &&
                this.props.pluginState.displayed.includes(plugin.getName())
        );

        const currentPluginName: string = this.props.currentPluginName || Plugins.ColumnHintPlugin;

        let currentPluginScreen: PluginScreen | null = null;
        const plugin = pluginsWithScreens.find(
            sortedPlugin => sortedPlugin.getName() === currentPluginName
        );
        if (plugin) {
            // if plugins have more than one screen we'll advise
            currentPluginScreen = arrayHelper.first(plugin.getScreens());
            if (currentPluginScreen?.store) {
                const Screen = currentPluginScreen.screen;
                modalBody = (
                    <Provider store={currentPluginScreen.store}>
                        <Screen />
                    </Provider>
                );
            }
        }
        const cssBodyClasses = gridConfigurationmodalsContainerStyleSheet.mount(this, {
            theme: this.props.theme,
        });

        const bemCls = `gs-uitk-modals-container`;
        const bemPluginCls = 'gs-uitk-datagrid-config-plugin';
        return (
            <div
                className={`${bemCls} ${cssBodyClasses.root} ${
                    this.state.fullScreenMode ? 'responsive-mode ' : ''
                }`}
            >
                <Tabs
                    variant="tabs"
                    activeTabKey={currentPluginName || undefined}
                    vertical={true}
                    contentUnderneath={false}
                >
                    {pluginsWithScreens.map(plugin => {
                        const firstPluginScreen = arrayHelper.first(plugin.getScreens());
                        const pluginName = plugin.getName();
                        return (
                            <Tab
                                key={pluginName}
                                onClick={() => this.props.onUpdateSelectedPlugin(pluginName)}
                                tabKey={pluginName}
                                className={currentPluginName === pluginName ? 'active' : ''}
                                title={
                                    <span
                                        data-cy={`${bemPluginCls}-${
                                            firstPluginScreen
                                                ? firstPluginScreen.label
                                                      .replace(' ', '-')
                                                      .toLowerCase()
                                                : pluginName.replace(' ', '-').toLowerCase()
                                        }`}
                                        className={bemPluginCls}
                                    >
                                        {firstPluginScreen ? firstPluginScreen.label : pluginName}
                                    </span>
                                }
                            >
                                {currentPluginName === pluginName ? modalBody : null}
                            </Tab>
                        );
                    })}
                </Tabs>
                <div className={`${bemPluginCls}-responsive-mode`}>
                    {this.props.currentPluginName ? (
                        <div className={`${bemPluginCls}-responsive-mode-body`}>{modalBody}</div>
                    ) : (
                        <ul>
                            {pluginsWithScreens.map(plugin => {
                                const firstPluginScreen = arrayHelper.first(plugin.getScreens());
                                const pluginName = plugin.getName();
                                return (
                                    <li
                                        onClick={() =>
                                            this.props.onUpdateSelectedPlugin(pluginName)
                                        }
                                        key={pluginName}
                                    >
                                        <Button actionType="secondary">
                                            <Text typography="body02" display="block">
                                                {' '}
                                                {firstPluginScreen
                                                    ? firstPluginScreen.label
                                                    : pluginName}
                                            </Text>
                                            <Icon name="chevron-right" type="filled" />
                                        </Button>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            </div>
        );
    }
}

export const GridConfigurationModalContent = (
    plugins: PluginBase<GridWrapper, DataGridState, object>[],
    theme: Theme
) => {
    const mapStateToProps = (state: DataGridState) => {
        return {
            plugins,
            theme,
            currentPluginName: state.gridConfiguration.currentPluginName,
            pluginState: state.plugins,
        };
    };

    const mapDispatchToProps = (dispatch: (action: Redux.Action) => void) => {
        return {
            onUpdateSelectedPlugin: (pluginName: string | undefined) =>
                dispatch(SetGridConfigurationCurrentPluginName(pluginName)),
        };
    };

    return connect(mapStateToProps, mapDispatchToProps)(GridConfigurationModalContentComponent);
};
