import React, {useMemo, useReducer} from 'react';
import PropTypes from 'prop-types';
import { UserMaintenanceContext } from '../index';
import { userMaintenanceReducer, userMaintenanceInitialState } from '../../reducers';

const UserMaintenanceProvider = ({ children, storeValues, reduxDispatch }) => {
  const [state, dispatch] = useReducer(userMaintenanceReducer, userMaintenanceInitialState);
  const providerValue = useMemo(() => ({state, dispatch, storeValues, reduxDispatch}));
  return <UserMaintenanceContext.Provider value={providerValue}>
    {children}
  </UserMaintenanceContext.Provider>;
};

UserMaintenanceProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  storeValues: PropTypes.object,
  reduxDispatch: PropTypes.func,
};

export default UserMaintenanceProvider;
