import { ComponentProps, CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { Density, Emphasis, Size } from '@gs-ux-uitoolkit-common/design-system';
import { TableCssClasses } from './table-style-sheet';

export interface TableProps<StyleType = CommonStyleType> extends ComponentProps<StyleType> {
    /**
     * Adds a border around the perimeter of the table.
     */
    bordered?: boolean;

    /**
     * Alignment of the text in the table's cells.
     */
    cellAlignment?: TableCellAlignment;

    /**
     * Style classes to override.
     */
    classes?: Partial<TableCssClasses>;

    /**
     * The density of the table's cells.
     */
    density?: TableDensity;

    /**
     * The background shading of the table's headers.
     */
    emphasis?: TableEmphasis;

    /**
     * Hides the separators between the rows of the table.
     */
    hideRowSeparators?: boolean;

    /**
     * Highlights the rows gray when hovered.
     */
    highlightOnRowHover?: boolean;

    /**
     * Adds a separator between the columns of the table.
     */
    showColumnSeparators?: boolean;

    /**
     * Size of the table's fonts.
     */
    size?: TableSize;

    /**
     * Striped styling, making the background color gray every other row.
     */
    striped?: boolean;
}

export type TableCellAlignment = 'left' | 'center' | 'right';
export const tableCellAlignments: TableCellAlignment[] = ['left', 'center', 'right'];

export type TableDataType = 'text' | 'numerical';
export const tableDataTypes: TableDataType[] = ['text', 'numerical'];

export type TableDensity = Extract<Density, 'compact' | 'standard'>;
export const tableDensities: TableDensity[] = ['standard', 'compact'];

export type TableEmphasis = Extract<Emphasis, 'bold' | 'subtle'>;
export const tableEmphases: TableEmphasis[] = ['bold', 'subtle'];

export type TableSize = Extract<Size, 'sm' | 'md' | 'lg'>;
export const tableSizes: TableSize[] = ['sm', 'md', 'lg'];

export const defaultTableProps: DefaultTableProps = {
    cellAlignment: 'left',
    density: 'standard',
    emphasis: 'subtle',
    size: 'md',
    bordered: false,
    showColumnSeparators: false,
    hideRowSeparators: false,
    highlightOnRowHover: false,
    striped: false,
};

type DefaultTableProps = Required<
    Pick<
        TableProps,
        | 'bordered'
        | 'cellAlignment'
        | 'density'
        | 'emphasis'
        | 'hideRowSeparators'
        | 'highlightOnRowHover'
        | 'showColumnSeparators'
        | 'size'
        | 'striped'
    >
>;
