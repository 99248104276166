import 'whatwg-fetch';
import AppConstants from '../constants/appConstants';
import LoginConstants from '../constants/loginConstants';
import GLSError from '../error';
import errorTypes from '../error/errorType';
import {getErrorEnum} from './statusEnumMap';
import {
  mosaicDefaultOptions, graphQLOptions,
  pingDefaultOptionsDefault, pingDefaultOptionsInitialLogin
} from '../utils/serviceUtils';
import {dispatcherService} from './dispatcherService';
import {getUUID} from '../utils/commonUtils';

const {dispatcher} = dispatcherService;
const ABORT_REQ_CONTROLLERS = new Map();

export const abortRequestSafe = (key, reason = 'CANCELLED') => {
  const controller = ABORT_REQ_CONTROLLERS.get(key);
  if (controller) {
    ABORT_REQ_CONTROLLERS.delete(key);
    controller.abort(reason);
  }
};

const abortAndGetSignalSafe = (key) => {
  abortRequestSafe(key);
  const controller = new AbortController();
  ABORT_REQ_CONTROLLERS.set(key, controller);
  return controller.signal;
};

const getContentType = (response) => {
  const {headers = {}} = response;
  return typeof headers.get === 'function' ? headers.get('Content-Type') : headers['content-type'];
};

const isContentTypeHTML = (response) => {
  const contentType = getContentType(response);
  return contentType && contentType.indexOf('text/html') > -1;
};

export const isContentTypeJSON = (response) => {
  const contentType = getContentType(response);
  return contentType && contentType.indexOf('application/json') > -1;
};

export const isContentTypePDF = (response) => {
  const contentType = getContentType(response);
  return contentType && contentType.indexOf('application/pdf') > -1;
};

const isResponseAuthRedirect = (response) => {
  const {headers = {}} = response;
  const contentType = getContentType(response);
  const auth = typeof headers.get === 'function' ? headers.get('GS_AUTH_REDIRECT') : headers.GS_AUTH_REDIRECT;
  return auth && contentType && contentType.indexOf('text/html') > -1;
};

const isResponseTypeOpaqueRedirect = (response = {}) => {
  const {type = ''} = response;
  return type === 'opaqueredirect';
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
    1. if we receive GS_AUTH_REDIRECT header redirect to Login pages
    2. if status code is 302 or 307 expire the session and redirect the user to login page
    3. if response type is Opaque Redirect
    4. if the redirect resulted in 404 and if we find the route it forwarded to,
       in response object (it happens only in IE)
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
const isSessionExpired = (response) => {
  const {status = ''} = response;
  return ['302', '307'].includes(status.toString()) ||
    isResponseAuthRedirect(response) ||
    isResponseTypeOpaqueRedirect(response);
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  * call sessionExpired Event registered in app component
  * it will dispatch the isSessionExpired()  action from
  * app component, that will open session expired modal
  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
const redirectToSessionExpiry = () => {
  dispatcher.dispatch('sessionExpired');
};

const isResponseValid = (response = {}) => {
  const {status} = response;
  return status === 'OK';
};

const isErrorResponse = (response = {}) => {
  const {status} = response;
  return status === 'ERROR';
};

const apiError = (status = null, error = {}) => {
  const errCode = (error.errorCode || error.code);
  const errMsg = (error.errorMessage || error.message);
  if (errCode || errMsg) { // If the Service Call had a response payload
    return new GLSError(errCode, '', errMsg, error);
  }
  return new GLSError(
    errorTypes.UNKNOWN_SERVICE_ERROR,
    status,
    errorTypes.UNKNOWN_SERVICE_ERROR, error
  );
};

const checkStatus = (response) => {
  const {status = ''} = response;
  const statusCode = status.toString();

  if (isSessionExpired(response)) {
    redirectToSessionExpiry();
  } else if (['401', '403'].includes(statusCode)) {
    throw new GLSError(errorTypes.AUTHORIZATION, status, errorTypes.AUTHORIZATION);
  } else if (isContentTypeHTML(response) || !getContentType(response)) {
    return response.text()
      .then((resp) => {
        // We hit the below case as well on Session Expiry.
        if (['200', '404'].includes(statusCode) && resp && resp.includes(LoginConstants.LOGIN_PATHS[statusCode])) {
          redirectToSessionExpiry();
        } else if (statusCode === '502') {
          throw new GLSError(
            errorTypes.UNKNOWN_SERVICE_ERROR,
            response.status,
            errorTypes.UNKNOWN_SERVICE_ERROR
          );
        } else { // If not pass the response back
          return resp;
        }
      })
      .catch(() => {
        throw new GLSError(errorTypes.UNKNOWN_SERVICE_ERROR, response.status);
      });
  } else if (isContentTypePDF(response)) {
    return response;
    // if content type JSON but response is not JSON show error message based on http status code.
  } else if (isContentTypeJSON(response)) {
    return response.json().catch(() => {
      const errorEnum = getErrorEnum(status);
      throw apiError(errorEnum, status, errorEnum);
    });
  }
  else {
    const errorEnum = getErrorEnum(status);
    throw apiError(errorEnum, status, errorEnum);
  }
};

const checkResponse = (json) => {
  if (isResponseValid(json)) {
    return json.response;
  } else if(isErrorResponse(json)){
    throw apiError(null, json.response || json.error);
  } else {
    return json;
  }
};

const fetchService = (
  endpoint, params, method, contentType, serviceType, serviceSubType, signalKey
) => {
  // Get the default options
  const isPingService = (serviceType === AppConstants.PING);
  const isGraphQLService = (serviceType === AppConstants.GRAPHQL);
  let defaultOptions;
  if (isPingService) {
    if (serviceSubType === AppConstants.PING_INITIAL_CALL) {
      defaultOptions = pingDefaultOptionsInitialLogin;
    } else {
      defaultOptions = pingDefaultOptionsDefault;
    }
  } else if (isGraphQLService) {
    defaultOptions = graphQLOptions;
  } else {
    defaultOptions = mosaicDefaultOptions;
  }
  const options = {
    ...defaultOptions,
    method
  };

  if (!isPingService && contentType && contentType.length) {
    options.headers.set('Content-Type', contentType);
    options.headers.set('X-Request-Id', getUUID());
  }

  if (['POST', 'DELETE', 'PUT'].includes(method)) {
    options.body = params;
  }

  if (signalKey) {
    options.signal = abortAndGetSignalSafe(signalKey);
  }

  return fetch(endpoint, options)
    .then(checkStatus)
    .then(checkResponse);
};


export async function downloadService (endpoint, params, method, contentType) {
  // Get the default options
  const options = {
    ...mosaicDefaultOptions,
    method
  };

  if (contentType && contentType.length) {
    options.headers['Content-Type'] = contentType;
    options.headers.set('X-Request-Id', getUUID());
  }
  if (method === 'POST') {
    options.data = params;
  }

  return fetch(endpoint, options)
    .then(checkStatus)
    .then(response => response.blob());
}

export default fetchService;
