import { mergeWith } from 'gs-uitk-lodash';
import { maskedColumnStyleSheet } from '../../style/masked-column-stylesheet';

/**
 * Helper for anything really
 */
export class GlobalHelper {
    public cloneObject<T>(obj: T): T {
        return JSON.parse(JSON.stringify(obj));
    }
    private cssClasses = maskedColumnStyleSheet.mount(this, null);

    public destroy(): void {
        maskedColumnStyleSheet.unmount(this);
    }

    public createColumnMaskingDiv(colId: string): HTMLDivElement {
        const maskedElement = document.createElement('div');
        const maskedElementTextContainer = document.createElement('div');
        maskedElement.setAttribute('data-id', colId);
        maskedElement.setAttribute('data-cy', `masked-column-${colId}`);
        maskedElementTextContainer.className = 'column-masked-text-container';
        const maskedElementText = document.createElement('div');
        maskedElementText.innerText = 'Masked';
        maskedElementText.className = 'column-masked-text';
        maskedElementTextContainer.appendChild(maskedElementText);
        maskedElement.appendChild(maskedElementTextContainer);
        maskedElement.className = `column-masked ${this.cssClasses.root}`;
        maskedElement.hidden = true;
        return maskedElement;
    }

    public convertNumberToPixelCss(value: number): string {
        return `${value}px`;
    }

    public mergeStates<T>(baseState: T, newState: T): T {
        const baseStateClone = this.cloneObject(baseState);
        const customMerge = (objValue: any, srcValue: any) => {
            if (Array.isArray(objValue)) {
                if (srcValue) {
                    return srcValue;
                }
            }
        };
        mergeWith(baseStateClone, newState, customMerge);
        return baseStateClone;
    }
}

export const globalHelper = new GlobalHelper();
