import { SelectCommonConfig, SelectOptionLeaf, SelectSize, SelectStatus } from './select-options';

/**
 * Only used internally to normalize the select configuration.
 *  Helps us keep our code cleaner by removing the `undefined` check
 *  from various places.
 */
export interface NormalizedSelectMultipleConfig extends SelectMultipleConfigWithClassName {
    disabled: boolean;
    size: SelectSize;
    status: SelectStatus;
    className: string;
}
/**
 * Main props for the SelectMultiple component.
 * Used to customize the behavior of the SelectMultiple component.
 */
export interface SelectMultipleConfig extends SelectCommonConfig {
    /**
     * Determines which options are pre-selected upon instantiation of the component.
     *
     * Use this prop if you would like the Select component to control the option - i.e. the selected values
     * are changed automatically once a user has selected a new option ("uncontrolled mode").
     *
     * If you would like to use the component in a "controlled" fashion where the values
     * that are selected always match the values in the given input prop, use the
     * {@link #selectedValues} prop instead.
     *
     * An empty array [] represents "no value"
     */
    defaultValues?: string[];

    /**
     * The amount of items a user can input/select `("-1" indicates no limit)`.
     *
     * This prop is useful when you wish to limit the amount of selected options to display.
     * When the user reaches the limit of options set a message will displayed in the menu
     * instead of options. Use {@link maxSelectionsContent} in order to customize the message.
     */
    maxSelectedOptions?: number;

    /**
     * The text that is shown when a user has focus on the input but has already reached the max
     * selected options count.
     *
     * Only displayed while {@link #maxSelectedOptions} is set.
     */
    maxSelectionsContent?: string;

    /**
     * Whether a user can paste text into the component's input box.
     */
    pasteable?: boolean;

    /**
     * Whether options should be removed from the menu once selected.
     *
     * Set to `true` to always render (keep) selected options in the menu.
     */
    renderSelectedOptions?: boolean;

    /**
     * Determines if the "remove" button should be rendered on each pill (`selectedOption`).
     */
    removeButtonsVisible?: boolean;

    /**
     * Whether the scroll position should reset after adding an item.
     */
    resetScrollPosition?: boolean;

    /**
     * The function that will sort selected options.
     *
     * If true, selected options will sorted alphabetically.
     */
    sortSelectedOptions?: ((a: SelectOptionLeaf, b: SelectOptionLeaf) => number) | boolean;

    /**
     * Determines which options are selected on the component. This is a "controlled" prop
     * where component will always reflect the selected values specified in this array.
     *
     * This prop is useful when using a state management solution like Redux (for instance), where you
     * always want the component to reflect the state of the store.
     *
     * In order to have the Select component reflect when a user selects new option,
     * you *must* add an {@link #onChange} callback and provide
     * a new array of values to this prop.
     *
     * Note: If you would like to simply specify a default value when the component is instantiated
     * and allow the Select component itself to update when users
     * select new options, use {@link #defaultValues} instead. If both {@link #defaultValues} and
     * this prop are specified, this prop takes precedence.
     *
     * `[]` represents "no values"
     */
    selectedValues?: string[];

    /**
     * For multiple pills. If true, constrains the selections to one tag to inform users the number of selections made.
     */
    constrainSelectedOptions?: boolean | ConstrainSelectedOptionsConfig;
    /**
     * Event emitted when multiple options are removed by the user.
     */
    onChange?: (evt: SelectMultipleChangeEvent) => void;
}

/**
 * Used internally to have a config with the root className as a prop.
 */
export interface SelectMultipleConfigWithClassName extends SelectMultipleConfig {
    /**
     * The main class passed to root element of the component.
     */
    className: string;
}

/**
 * Represents the event emitted for the 'onChange' callback.
 * Triggered each time a user adds/removes an option.
 */
export type SelectMultipleChangeEvent = SelectMultipleAddEvent | SelectMultipleRemoveEvent;

/**
 * Represents the the event object for an "add" Event.
 * Calls the {@link #onChange} callback each time a user adds an option.
 */
export interface SelectMultipleAddEvent {
    /**
     * The Type of {@link #onChange} event being emitted.
     * You can use this to tell discriminate when an option is being "added"
     */
    type: 'add';

    /**
     * The SelectOption(s) that were just added by the user (newly-added options).
     */
    addedOptions: SelectOptionLeaf[];

    /**
     * The values of the options that were just added by the user.
     */
    addedValues: string[];

    /**
     * An array of all of the currently selected options (grand total).
     * For "uncontrolled" mode, this will return the current options selected.
     * For "controlled" mode, this will return the current options selected,
     * plus the option that triggered this event.
     */
    selectedOptions: SelectOptionLeaf[];

    /**
     * An array of all of the currently selected values (grand total).
     * For "uncontrolled" mode, this will return the values of the currently selected options.
     * For "controlled" mode, this will return the current selected values(ie. the state of {@link #selectedValues}),
     * plus the value of the option that triggered this event.
     */
    selectedValues: string[];
}

/**
 * Represents the the event object for an "remove" Event
 * Calls the {@link #onChange} callback each time a user removes an option.
 */
export interface SelectMultipleRemoveEvent {
    /**
     * The Type of {@link #onChange} event being emitted.
     * You can use this to tell discriminate when an option is being "removed"
     */
    type: 'remove';

    /**
     * The SelectOption(s) that were just removed by the user (newly-removed options).
     */
    removedOptions: SelectOptionLeaf[];

    /**
     * The values of the options that were just removed by the user.
     */
    removedValues: string[];

    /**
     * An array of all of the currently selected options (grand total).
     * This will return the current selected options for "controlled" and "uncontrolled" mode
     */
    selectedOptions: SelectOptionLeaf[];

    /**
     * An array of all of the currently selected values (grand total).
     * This will return the values of the currently selected options for
     * "controlled" and "uncontrolled" mode
     */
    selectedValues: string[];
}
export interface ConstrainSelectedOptionsFormatterData {
    numOverflowOptions: number;
    numDisplayedOptions: number;
}

export interface ConstrainSelectedOptionsConfig {
    maxDisplayedOptions?: number; // default: Infinity
    formatter?: (data: ConstrainSelectedOptionsFormatterData) => string;
}

export const selectMultipleClassPrefix = 'gs-uitk-select-multiple';
