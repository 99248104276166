import { IconBaseProps } from '@gs-ux-uitoolkit-react/icon-font';
import {
    DragAndDropProvidedDragHandleProps,
    DragAndDropRubric,
    DragAndDropStateSnapshot,
} from './drag-and-drop-external';
import { HTMLAttributes, ReactElement } from 'react';
import { OverrideDragHandleClasses } from './drag-and-drop-handle-style-sheet';

export type DraggableDragHandleProps = DragAndDropProvidedDragHandleProps & {
    'data-drag-handle-draggable-id': string;
};

export type DragAndDropHandleVisibility = 'visible' | 'onHover' | 'none';

export type DragAndDropDragArea = 'anywhere' | 'icon';

export type DragAndDropIconPosition = 'trailing' | 'leading';

export type DragAndDropIconPlacement = 'top' | 'center' | 'bottom';

export type DefaultDraggableProps = Required<
    Pick<DraggableProps, 'iconPosition' | 'iconPlacement' | 'dragIconVisibility' | 'grabArea'>
>;

export const defaultDraggableProps: DefaultDraggableProps = {
    iconPosition: 'trailing',
    iconPlacement: 'top',
    dragIconVisibility: 'visible',
    grabArea: 'anywhere',
};
export interface DraggableProps extends Omit<HTMLAttributes<HTMLElement>, 'children'> {
    draggableId?: string;
    index: number;
    dragIconVisibility?: DragAndDropHandleVisibility;
    grabArea?: DragAndDropDragArea;
    dragHandleConfig?: IconBaseProps;
    disabled?: boolean;
    iconPosition?: DragAndDropIconPosition;
    iconPlacement?: DragAndDropIconPlacement;
    children: (
        dragHandleProps: DraggableDragHandleProps | undefined,
        snapshot: DragAndDropStateSnapshot | undefined,
        rubric: DragAndDropRubric | undefined
    ) => ReactElement<HTMLElement>;
    classes?: DraggableOverrideClasses;
}

export type DraggableOverrideClasses = Partial<
    Record<'box' | 'boxInner' | 'boxInnerDrag', string> & {
        dragHandleClasses: OverrideDragHandleClasses;
    }
>;
