import { CSSProperties, HTMLAttributes, ReactNode, useEffect, FunctionComponent } from 'react';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import {
    LabelProps as LabelCommonProps,
    labelStyleSheet,
    getLabelRootClassNames,
    getLabelClassNames,
    defaultLabelProps,
} from '@gs-ux-uitoolkit-common/label';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { componentAnalytics } from './analytics-tracking';

export interface LabelProps
    extends LabelCommonProps<CSSProperties>,
        Omit<HTMLAttributes<HTMLElement>, 'size'> {
    /**
     * Additional children to render with the label
     */
    children?: ReactNode;
}

/**
 * Label component to render a simple <label> control
 */
export const Label: FunctionComponent<LabelProps> = ({
    children,
    classes: overrideClasses,
    for: htmlFor,
    inline = defaultLabelProps.inline,
    size = defaultLabelProps.size,
    className,
    emphasis = defaultLabelProps.emphasis,
    disabled = defaultLabelProps.disabled,
    ...attributes
}) => {
    const theme = useTheme();
    const cssClasses = useStyleSheet(labelStyleSheet, { theme, size, emphasis, inline, disabled });
    const rootClasses = getLabelRootClassNames({ cssClasses, className, overrideClasses });
    const labelClasses = getLabelClassNames({
        cssClasses,
        overrideClasses,
    });

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'label' });
    }, []); // Only run once

    return (
        <span
            data-gs-uitk-component="label"
            className={rootClasses}
            data-size={size}
            {...attributes}
        >
            <label data-cy="gs-uitk-label" htmlFor={htmlFor} className={labelClasses}>
                {children}
            </label>
        </span>
    );
};
