import { Component, Fragment, ChangeEvent } from 'react';
import {
    ColorPicker,
    ColorPickerSelectorMode,
    ColorPickerSelectorSize,
} from '@gs-ux-uitoolkit-react/color-picker';
import { Form, FormGroup } from '@gs-ux-uitoolkit-react/form';
import { Input, InputGroup, InputGroupContent } from '@gs-ux-uitoolkit-react/input';
import { Label } from '@gs-ux-uitoolkit-react/label';
import { HintList } from '@gs-ux-uitoolkit-common/datacore';
import { FormatControlProps } from '../column-hint-format-manager';
import { columnFormattingPluginStylePrefix } from '../../../../../style/plugins/column-hint-plugin-stylesheet';

export interface ColumnHintControlColorProps extends FormatControlProps {
    title: string;
    colorPath: string;
}

export class ColumnHintControlColor extends Component<ColumnHintControlColorProps> {
    public render() {
        const color = this.props.columnHint[this.props.colorPath];
        return (
            <Fragment>
                <FormGroup>
                    <Form preventSubmit={true} inline={true}>
                        <Label
                            className={`${columnFormattingPluginStylePrefix}_colour-picker-label`}
                        >
                            {this.props.title}
                        </Label>
                        <div
                            className={`${columnFormattingPluginStylePrefix}_colour-picker-container ${columnFormattingPluginStylePrefix}_flex`}
                        >
                            <InputGroup>
                                <InputGroupContent>
                                    <div className="input-group-text">#</div>
                                </InputGroupContent>
                                <Input
                                    style={{ width: '6rem' }}
                                    value={((color as string) || '').replace(/^#/, '')}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                        this.onColorChange(`#${e.target.value}`)
                                    }
                                    maxLength={6}
                                />
                            </InputGroup>
                            <ColorPicker
                                mode={ColorPickerSelectorMode.Chip}
                                size={ColorPickerSelectorSize.Md}
                                onSelect={this.onColorChange}
                                selectedColor={
                                    this.isHexValue(color as string) ? (color as string) : '#FFFFFF'
                                }
                            />
                        </div>
                    </Form>
                </FormGroup>
            </Fragment>
        );
    }

    private isHexValue = (color: string) => {
        const hexColorRegex = /^#[\dA-Fa-f]{6}$/;

        return hexColorRegex.test(color);
    };

    private onColorChange = (color: string) => {
        const clonedHint: HintList = {
            ...this.props.columnHint,
            [this.props.colorPath]: color != null ? color : undefined,
        };
        this.props.onChangeHint(clonedHint);
    };
}
