import { cx } from '@gs-ux-uitoolkit-common/style';
import { ButtonCommonProps } from './button-props';

// api-extractor does not support dynamic imports.  TSC compiler will
// reduce ButtonActionType to ActionType, since they are equivalent
// @ts-expect-error Issue:  https://github.com/microsoft/rushstack/issues/1050
import { ActionType } from '@gs-ux-uitoolkit-common/design-system';
import { ButtonCssClasses } from './button-style-sheet';

const globalClassName = 'gs-button';

export const getRootClassNames = (props: {
    cssClasses: ButtonCssClasses;
    overrideClasses?: ButtonCommonProps['classes'];
    className?: ButtonCommonProps['className'];
}): string => {
    const { cssClasses, overrideClasses, className } = props;

    return cx(cssClasses.root, className, overrideClasses && overrideClasses.root, globalClassName);
};

export const getButtonClassNames = (props: {
    cssClasses: ButtonCssClasses;
    overrideClasses?: ButtonCommonProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;

    return cx(
        cssClasses.button,
        overrideClasses && overrideClasses.button,
        `${globalClassName}__button`
    );
};
