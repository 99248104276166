import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import PageHeader from '../../containers/FundFinder/Header';
import FundFinderGrid from '../../containers/FundFinder/Grid';
import ControlPanel from '../../containers/FundFinder/ControlPanel';
import FundFinderCustomView from '../../containers/FundFinder/CustomView';
import FundFactView from '../../containers/FundFactView';
import Error from '../../components/core/Error';
import {Conditional} from '../../components/core/Conditional';
import {checkIsPageInErrorMode, getErrorObject} from '../../selectors/pages/fundFinder';
import {getLabel} from '../../selectors/app';
import {SHOW_ERROR_ON} from '../../constants/pageConstants';
import './index.scss';

const FundFinder = () => (
  <div className="js-main-content main-container__content">
    <FundFinderCustomView />
    <FundFactView />
    <section className="fundfinder" id="fundfinder">
      <PageHeader />
      <div className="fundfinder-container">
        <ControlPanel />
        <FundFinderGrid />
      </div>
    </section>
  </div>
);


export const fundFinder = (props) => {
  const {label, errorObject} = props;
  const error = {
    code: errorObject && errorObject.errorCode,
    label
  };
  return (
    <Conditional condition={!(props.isPageInErrorMode &&
      (errorObject.showErrorOn === SHOW_ERROR_ON.PAGE))}>
      <FundFinder />
      <div className="js-main-content main-container__content">
        <Error {...error} />
      </div>
    </Conditional>
  );
};

fundFinder.propTypes = {
  isPageInErrorMode: PropTypes.bool,
  label: PropTypes.object,
  errorObject: PropTypes.object
};

const mapStateToProps = (state) => (
  {
    isPageInErrorMode: checkIsPageInErrorMode(state),
    errorObject: getErrorObject(state),
    label: key => getLabel(state, key)
  }
);
export default connect(mapStateToProps)(fundFinder);

