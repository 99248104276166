import { ZebraStripesActionKeys } from '../actions/action-constants';
import { ZebraStripesActionTypes } from '../actions/zebra-stripes-action';
import { ZebraStripesState } from '../datagrid-state';

/**
 * Initial state of the Searchable State
 */
export const defaultZebraStripesState: ZebraStripesState = {
    stripeInterval: undefined,
};

/**
 * Reducer for the ZebraStripesState
 * @param state State of the ZebraStripesState
 * @param action Action received
 */
export const zebraStripesReducer = (
    state: ZebraStripesState = defaultZebraStripesState,
    action: ZebraStripesActionTypes
): ZebraStripesState => {
    switch (action.type) {
        case ZebraStripesActionKeys.SET_STRIPE_INTERVAL:
            return {
                ...state,
                stripeInterval: action.stripeInterval,
            };

        case ZebraStripesActionKeys.SET_BACKGROUND_COLOURS:
            return {
                ...state,
                stripeBackgroundColour: action.stripeBackgroundColour,
                nonStripeBackgroundColour: action.nonStripeBackgroundColour,
            };

        default:
            return state;
    }
};
