import { Size, Status } from '@gs-ux-uitoolkit-common/design-system';

export type FormSize = Extract<Size, 'sm' | 'md' | 'lg'>;

export const formSizes: FormSize[] = ['sm', 'md', 'lg'];

export type FormStatus = Extract<
    Status,
    'none' | 'information' | 'success' | 'warning' | 'error' | 'loading'
>;

export const formStatuses: FormStatus[] = [
    'none',
    'information',
    'success',
    'warning',
    'error',
    'loading',
];
