import React from 'react';
import PropTypes from 'prop-types';
import TreeNode from './TreeNode';

function convert(array) {
  const map = {};
  for (let i = 0; i < array.length; i++) {
    const obj = array[i];
    obj.items = [];
    map[obj.nodeId] = obj;

    const parent = obj.parentId || '-';
    if (!map[parent]) {
      map[parent] = {
        items: []
      };
    }
    map[parent].items.push(obj);
  }
  return map['-'].items;
}

const Tree = ({items, props, indentationPadding}) => {

  const treeNodes = items.length > 0 ? convert(items) : [];
  const getRootNodes = () => {
    return treeNodes.map(node => node);
  };
  const getChildNodes = (node) => {
    if (!node.items) return [];
    return node.items.map((item) => {
      return item;
    });
    // return nodes.filter(node => node.children.map(path => nodes[path]);
  };
  // const onToggle = (node) => {
  //   const { nodes } = this.state;
  //   nodes[node.path].isOpen = !node.isOpen;
  //   setNodes({ nodes });
  // };

  const rootNodes = getRootNodes();

  return (
    <div>
      {rootNodes.map((node, index) => {
        return (<TreeNode
          {...props}
          node={{...node}}
          indentationPadding={indentationPadding}
          getChildNodes={getChildNodes}
          onToggle={() => {
          }}
          key={index}
        />);
      })
      }
    </div>
  );

};
Tree.propTypes = {
  items: PropTypes.array.isRequired,
  props: PropTypes.object,
  indentationPadding: PropTypes.number
};
export default Tree;
