/* eslint-disable */
import React, {useCallback} from 'react';
import { Link } from 'react-router-dom';
import RadioButton from "../../RadioButton";

const Item = (props)=> {
  const  {handleClick, selected = false, padding , value, label, hover = false, focus = false, iconCls1, iconCls2, customClass, handleEdit = ()=>{}} = props;
  const divStyle = {
    'paddingLeft': padding,
    'display': 'flex',
    'justifyContent': 'space-between'
 };
  return (
    <div  onClick={(e) => {e.stopPropagation(); handleClick(e, props)}} data-value={value} className={`dropdown__row ${selected ? 'dropdown__row--selected' : ''} ${focus ? 'dropdown__row--focus' :''} ${customClass}`}>
     <div style={divStyle}>
      <div className="dropdown__rowElement">{label}</div>
      <div className="dropdown__rowElement dropdown__rowElement--right"  onClick={(e)=> {e.stopPropagation(); handleEdit(e, props)}}>
        <span className={`dropdown__icon dropdown__icon--invisible  ${hover ? iconCls1 : ''} ${iconCls1 === 'icon-plus' ? iconCls1 : ''}`}>&nbsp;</span>
        <span className={`dropdown__icon dropdown__icon--invisible ${selected ? 'icon-checkmark-filled' : (hover ? iconCls2 : '')}`}>&nbsp;</span>
      </div>
     </div>
    </div>
  )
};


export const Edit = (itemProps) => {
  const props = {...itemProps, handleClick: itemProps.handleSelect, handleEdit: itemProps.handleEdit, iconCls1: 'icon-edit', iconCls2:'icon-checkmark-outline'};
//  props.handleEdit = itemProps.handleEdit;
  return <Item {...props} />
};

export const Add = (itemProps) => {
  const props = {...itemProps, handleClick: itemProps.handleAdd, iconCls1: 'icon-plus'};
//  const props = {id, handleClick: handleAdd, handleEdit, value, label, hover, focus, iconCls1: 'icon-plus'};
  return <Item {...props} />
};

export const Select = (itemProps) => {
  const props = {...itemProps, iconCls1: '', iconCls2:'icon-checkmark-outline' };
  props.handleClick = itemProps.handleSelect;
  return <Item {...props} />
};

export const Section = (itemProps) => {
  const  {padding , value, label, customClass} = itemProps;
  const divStyle = {
    'paddingLeft': padding
  };
  return (
    <div data-value={value} className={`dropdown__row dropdown__row--section ${customClass}`}>
      <div style={divStyle}>
        <div className="dropdown__rowElement">{label}</div>
      </div>
    </div>
  )
};

export const MultiLine = (itemProps) => {
  const props = {...itemProps, iconCls1: '', iconCls2:'icon-checkmark-outline' };
  props.handleClick = itemProps.handleSelect;
  return <Item {...props} />
};


 export const CheckSelect = (itemProps) => {
   const {handleCheckSelect, label, focus, padding, selected, disabled = false, highlight = '', } = itemProps;
   const handleChange = (event) => {
    event.stopPropagation();
    if(!disabled){
      handleCheckSelect && handleCheckSelect(event, itemProps, !selected);
    }
  };
   const divStyle = {
     'paddingLeft': padding
   };

   const className = ['checkbox-container'];

   if (selected) {
     className.push('checkbox-container--active');
   }
   if(disabled){
     className.push('disabled');
   }

  return (
    <div  className={`dropdown__row ${focus ? 'dropdown__row--focus' :''}`}>
      <div style={divStyle} onClick={handleChange} className={className.join(' ')}>
        <input checked={selected} disabled={disabled} type="checkbox" className={
          (selected ?
          'checkbox-container__input icon-checkbox-filled' :
          'checkbox-container__input icon-checkbox-outlined')}/>
        <label className="dropdown__rowElement checkbox-container__label">{label}</label>
      </div>
    </div>
  )
};

export const LinkItem = (itemProps) => {
  const props = {...itemProps, handleClick: itemProps.handleSelect, iconCls1: '', iconCls2:'icon-checkmark-outline' };
  const  {handleClick, selected = false, padding, routingUrl, value, label, hover = false, focus = false, iconCls1, iconCls2, customClass, handleEdit = ()=>{}} = props;
  const divStyle = {
    'paddingLeft': padding,
    'display': 'flex',
    'justifyContent': 'space-between'
  };
  const onClickHandler = (e) => {
    e.stopPropagation();
    if(selected){
      e.preventDefault();
    }
    handleClick(e, props)
  };
  return (
    <Link to={routingUrl} onClick={onClickHandler} data-value={value} className={`dropdown__row dropdown__row--link ${selected ? 'dropdown__row--selected' : ''} ${focus ? 'dropdown__row--focus' :''} ${customClass}`}>
      <div style={divStyle}>
        <div className="dropdown__rowElement">{label}</div>
        <div className="dropdown__rowElement dropdown__rowElement--right"  onClick={(e)=> {e.stopPropagation(); handleEdit(e, props)}}>
          <span className={`dropdown__icon dropdown__icon--invisible  ${hover ? iconCls1 : ''} ${iconCls1 === 'icon-plus' ? iconCls1 : ''}`}>&nbsp;</span>
          <span className={`dropdown__icon dropdown__icon--invisible ${selected ? 'icon-checkmark-filled' : (hover ? iconCls2 : '')}`}>&nbsp;</span>
        </div>
      </div>
    </Link>
  )
};

export const RadioItem = (itemProps) => {
  const props = {...itemProps, handleRadioSelect: itemProps.handleRadioSelect};
  const {handleRadioSelect, selected = false, value, label} = props;
  const id = `${props.id}-${label}-${value}`;
  const divStyle = {
    'padding': '6px 10px',
    'display': 'flex',
    'justifyContent': 'space-between'
  };
  const onChangeHandler = useCallback((e) => {
    e.stopPropagation();
    handleRadioSelect(props, !selected);
  }, [props, selected]);
  return (
    <div style={divStyle}>
      <RadioButton
        changeHandler={onChangeHandler}
        label={label}
        value={value}
        isChecked={selected}
        id={id}
      />
    </div>
  )
};
