import React from 'react';
import PropTypes from 'prop-types';
import extend from 'lodash/extend';
import filter from 'lodash/filter';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import isEqual from 'lodash/isEqual';

// Component
import Link from '../../core/Link/index';
import Button from '../../core/Button/index';
import {Conditional} from '../../core/Conditional';
import {ThreeSquaresLoader} from '../../core/Loaders';
import ViewOptionsSection from './ViewOptionsSection';
import Error from '../../core/Error';
import {withScroll} from '../../core/Scrollbar';

// utils
import {validators} from '../../../utils/validators/validators';
import {injectKeys} from '../../../utils/dynamici18nInjection';
import {STANDARD_REPORT_VIEW_NAMES} from '../../../containers/Reports/utils';

// consts
import {MODEL_ACTION, SHOW_ERROR_ON} from '../../../constants/pageConstants';
import './index.scss';

let lastHeader;

const List = ({isError, errorText, filteredGroupedColumn, toggleOption}) => {
  return (
    <div className="saveNewView__list">
      <Conditional condition={isError}>
        <span className="saveNewView__errorMessage">{errorText}</span>
        <div>
          {filteredGroupedColumn.length && filteredGroupedColumn.map((columnGroup) => (
            <ViewOptionsSection clickHandler={toggleOption} {...columnGroup} key={columnGroup.category}/>
          ))}
        </div>
      </Conditional>
    </div>
  );
};


List.propTypes = {
  isError: PropTypes.bool,
  errorText: PropTypes.string,
  filteredGroupedColumn: PropTypes.array,
  toggleOption: PropTypes.func
};

const checkHardDependent = (header, headerGroups, updatedNewColumnQueue, allColumnStatus) => {
  if (header.isChecked && !header.isDisabled) {
    header.isHardDependentEnabled = true;
  }
  if (header.isHardDependentEnabled) {
    if (header.isChecked) {
      if (!updatedNewColumnQueue.includes(header.field) && header.dependentColumns.every(field => !allColumnStatus[field])) {
        updatedNewColumnQueue.push(header.field);
        headerGroups.headers.forEach(head => {
          if ((header.dependentColumns).includes(head.field)) {
            head.isChecked = true;
            head.isDisabled = true;
            updatedNewColumnQueue.push(head.field);
            header.isInGroupedColumn = true;
          }
        });
      }
      if (!header.isInGroupedColumn && allColumnStatus[header.field]) {
        headerGroups.headers.forEach(head => {
          if ((header.dependentColumns).includes(head.field)) {
            head.isDisabled = true;
            header.flag = true;
          }
        });
      }
    } else {
      headerGroups.headers.forEach(head => {
        if ((header.dependentColumns).includes(head.field)) {
          head.isChecked = false;
          head.isDisabled = false;
        }
      });
      header.isHardDependentEnabled = false;
    }
  }
  return {updatedNewColumnQueue, headerGroups};
};

const getGroupedColumns = (groupedColumns = []) => {
  const allColumnStatus = {};
  let newColumnQueue = [];
  const updatedGroupedColumns = groupedColumns.map(group => (
    {
      ...group,
      headers: group.headers.map(header => {
        const {field, isChecked} = header;
        allColumnStatus[field] = isChecked;
        return {...header};
      })
    }
  ));
  updatedGroupedColumns.forEach(group => {
    group.headers.forEach(header => {
      const {dependentColumns = [], isChecked = false} = header;
      if (dependentColumns.length && dependentColumns.some(field => allColumnStatus[field])) {
        if (!isChecked) {
          newColumnQueue.push(header.field);
          header.isChecked = true;
        }
        if (header.hardDependent) {
          const hardDependentValue = checkHardDependent(header, group, newColumnQueue, allColumnStatus);
          newColumnQueue = hardDependentValue.updatedNewColumnQueue;
          group = hardDependentValue.headerGroups;
        } else {
          header.isDisabled = true;
        }
      }
    });
  });
  return {
    groupedColumns: updatedGroupedColumns,
    newColumnQueue
  };
};

let searchSubmitted = false;

class CustomView extends React.Component {

  constructor(props) {
    super(props);
    const {groupedColumns, newColumnQueue} = getGroupedColumns(this.props.groupedColumns);
    this.refModalBody = null;
    this.state = {
      groupedColumns,
      newColumnQueue,
      viewName: ([MODEL_ACTION.EDIT, MODEL_ACTION.SELECT_COLUMNS].includes(this.props.modelActiveMode)) ? this.props.currentView.name : '', // viewName
      baseView: this.props.currentView && this.props.currentView.baseView,
      searchInput: '', // column search
      isValidInput: true, // viewName validation
      level2: false, // any warning or screen 2
      isNotDuplicate: true,  // viewName Duplicate
      isDeleteActivated: false, // user entered in delete mode
      level2Message: '', // any warning for level2,
      isVisible: true
    };

    this.handleClose = this.handleClose.bind(this);
    this.toggleOption = this.toggleOption.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.cancelBtn = this.cancelBtn.bind(this);
    this.getHeaderLabel = this.getHeaderLabel.bind(this);
    this.getLinkBtnProps = this.getLinkBtnProps.bind(this);
    this.getBtnProps = this.getBtnProps.bind(this);
    this.deleteView = this.deleteView.bind(this);
    this.reStoreToDefault = this.reStoreToDefault.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.clearName = this.clearName.bind(this);
    this.submitSearch = this.submitSearch.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClose() {
    this.props.closeModal({
      'modelActiveMode': '',
      'isModalLoading': false,
      'duplicateViewName': undefined,
      isPageInErrorMode: false
    });
  }

  getOldState() {
    const {groupedColumns} = this.props;
    const allColumnInitialStatus = {};
    if (!this.state.allColumnInitialStatus) {
      groupedColumns.forEach((group) => {
        group.headers.forEach((header) => {
          const {field, isChecked} = header;
          allColumnInitialStatus[field] = isChecked;
        });
      });
      this.setState({
        allColumnInitialStatus
      });
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
    this.getOldState();
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  isStateChanged() {
    const {allColumnInitialStatus, allColumnStatus} = this.state;
    return allColumnStatus && !isEqual(allColumnInitialStatus, allColumnStatus);
  }

  handleClickOutside(event) {
    event.stopPropagation();
    const modal = this.refModalBody && this.refModalBody.closest('.modal');
    const clickedOnComponent = modal && modal.contains(event.target);
    if (!clickedOnComponent) {
      if (this.isStateChanged() && !this.isDisabled())
        this.handleSubmit(event);
      else
        this.handleClose();
    }
    return false;
  }

  toggleOption(headerField, isChecked) {
    const {newColumnQueue} = this.state;
    let updatedNewColumnQueue = [...newColumnQueue];
    const allColumnStatus = {};

    const updatedHeaders = this.state.groupedColumns.map(headerGroups => {
      const headerGroupsCopy = extend({}, headerGroups);
      headerGroupsCopy.headers = headerGroupsCopy.headers.map(header => {
        const headerCopy = {...header};
        if (headerCopy.field === headerField) {
          headerCopy.isChecked = isChecked;
          if (isChecked && !header.hardDependent) {
            updatedNewColumnQueue.push(headerField);
          } else {
            const index = updatedNewColumnQueue.indexOf(headerField);
            if (index !== -1) {
              updatedNewColumnQueue.splice(index, 1);
            }
            if (headerCopy.hardDependent) {
              header.dependentColumns.forEach(column => {
                  const dependentIndex = updatedNewColumnQueue.indexOf(column);
                  if (dependentIndex !== -1) {
                    updatedNewColumnQueue.splice(dependentIndex, 1);
                  }
                }
              );
            }
          }
        }
        allColumnStatus[headerCopy.field] = headerCopy.isChecked;
        return headerCopy;
      });
      return headerGroupsCopy;
    });

    updatedHeaders.forEach(headerGroups => {
      headerGroups.headers.forEach(header => {

        const {dependentColumns = [], isChecked = false} = header;
        if (dependentColumns.length && dependentColumns.some(field => allColumnStatus[field]) && !header.hardDependent) {
          if (!isChecked) {
            header.isChecked = true;
            updatedNewColumnQueue.push(header.field);
          }
          header.isDisabled = true;
        }
        if (dependentColumns.length && dependentColumns.every(field => !allColumnStatus[field]) && isChecked && !header.hardDependent) {
          header.isDisabled = false;
        }
        if (header.hardDependent) {
          const hardDependentValue = checkHardDependent(header, headerGroups, updatedNewColumnQueue, allColumnStatus);
          updatedNewColumnQueue = hardDependentValue.updatedNewColumnQueue;
          headerGroups = hardDependentValue.headerGroups;
        }
      });
    });

    this.setState({
      groupedColumns: updatedHeaders,
      newColumnQueue: updatedNewColumnQueue,
      allColumnStatus
    });
    if(isChecked && headerField && headerField.startsWith("adjusted")) {
      this.props.doAnalytics(
        {
          event: {
            eventDesc: "RebateFlowInteraction",
            eventDetail: headerField,
            eventAction: "Checkbox Enabled",
            satelliteTrackEventName: 'rebatevents',
            componentInfo: {
              componentName: 'View Settings'
            }
          }
        });
    }

  }

  isValidInput(input) {
    return validators.regex({
      optional: true,
      rule: 'alphaNumeric',
      requiredRule: null,
      invalidRule: null
    }, input).isValid;
  }

  isValidSearch(input) {
    return validators.regex({
      optional: true,
      rule: 'portfolioInputText',
      requiredRule: null,
      invalidRule: null
    }, input).isValid;
  }

  handleNameChange(e) {
    const {labels} = this.props;
    const isValidViewName = this.isValidInput(e.target.value);
    const isValidSearchInput = this.isValidInput(this.state.searchInput);
    const isValidInput = (isValidViewName && isValidSearchInput);
    this.setState({
      isValidInput,
      viewName: e.target.value,
      errorMessage: isValidViewName ? labels.tkCopyPort17 : labels.tkCopyPort25
    });
  }

  submitSearch(e) {
    e.preventDefault();
    if (this.state.searchInput.length === 1) {
      searchSubmitted = true;
      this.forceUpdate();
    }
  }

  handleSearchChange(e) {
    const {value} = e.target;
    const {labels, dispatchColumnSearchNoResultAnalytics} = this.props;
    const isValidSearchInput = this.isValidSearch(value);
    const isValidViewName = this.isValidSearch(this.state.viewName);
    const isValidInput = (isValidSearchInput && isValidViewName);
    this.setState({
      isValidInput,
      searchInput: value,
      errorMessage: isValidSearchInput ? labels.tkCopyPort25 : labels.tkCopyPort17
    }, () => {
      if (dispatchColumnSearchNoResultAnalytics) {
        const filteredGroupedColumn = this.getFilteredGroupedColumn();
        const columnsLengthArray = filteredGroupedColumn.map(data => data.headers.length);
        const searchResult = columnsLengthArray && columnsLengthArray.reduce((a, b) => a + b, 0);
        (!searchResult) && dispatchColumnSearchNoResultAnalytics(this.state.searchInput);
      }
    });
  }

  clearSearch() {
    this.handleSearchChange({target: {value: ''}});
  }

  clearName() {
    this.handleNameChange({target: {value: ''}});
  }

  isViewExist() {
    const {allViews, labels} = this.props;
    const {viewName} = this.state;
    for (let i = 0; i < allViews.length; i++) {
      if (allViews[i].name === viewName) {
        this.setState({
          level2: true,
          isNotDuplicate: false,
          level2Message: labels.tkCopyPort16
        });
        return true;
      }
    }
    return false;
  }

  getSelectedColumns() {
    const {columnsMetadata, currentView: {columns: viewColumns = []}, labels} = this.props;
    const {groupedColumns, newColumnQueue} = this.state;
    const customViewStatus = {};
    groupedColumns.forEach(group => {
      group.headers.forEach(header => {
        const {field, isChecked} = header;
        customViewStatus[field] = isChecked;
      });
    });

    const updatedViewColumns = viewColumns
      .filter(column => {
        const {field} = column;
        return !customViewStatus.hasOwnProperty(field) ||
          (customViewStatus.hasOwnProperty(field) && !newColumnQueue.includes(field) && customViewStatus[field]);
      });

    const columnQueueData = {};
    columnsMetadata.forEach(column => {
      const {field} = column;
      if (newColumnQueue.includes(field)) {
        columnQueueData[field] = column;
      }
    });

    const updatedNewColumnQueue = newColumnQueue.map(field => {
      const item = columnQueueData[field];
      item.headerText = labels[item.headerName];
      return item;
    });

    const startingColumns = filter(updatedViewColumns, (column) => ['canTrade', 'ag-Grid-AutoColumn'].includes(column.field));

    const {length} = startingColumns;

    const splitIndex = ((length === 1 && startingColumns[0].colId === 'ag-Grid-AutoColumn') || length > 1) ? length + 1 : length;

    const updatedColumnsStart = updatedViewColumns.slice(0, splitIndex);

    const updatedColumnsEnd = updatedViewColumns.slice(splitIndex);

    const newColumns = [...updatedColumnsStart, ...updatedNewColumnQueue, ...updatedColumnsEnd];

    return newColumns.map((column, i) => ({
      ...{
        colId: column.colId,
        field: column.field,
        sort: column.sort || 'none',
        columnIndex: i
      },
      ...(column.width ? {width: column.width} : {}),
      ...(column.suppressSizeToFit ? {suppressSizeToFit: column.suppressSizeToFit} : {}),
      ...(column.groupColSpan ? {groupColSpan: column.groupColSpan} : {}),
      ...(column.hide ? {hide: column.hide} : {}),
      ...(column.cellRenderer ? {cellRenderer: column.cellRenderer} : {}),
      ...(column.dataType ? {dataType: column.dataType} : {}),
      ...(column.aggFunc ? {aggFunc: column.aggFunc} : {}),
      ...(column.aggGroupColums? {aggGroupColums: column.aggGroupColums} : {}),
      ...(column.cellRendererKey ? {cellRendererKey: column.cellRendererKey} : {}),
      ...(column.aggFieldKey ? {aggFieldKey: column.aggFieldKey} : {}),
    }));
  }

  handleSubmit(e) {
    e.preventDefault();
    const {viewName, level2, isDeleteActivated} = this.state;
    const {allViews, currentView, modelActiveMode, columnsMetadata, labels} = this.props;
    if (isDeleteActivated) {
      this.props.deleteCustomView(currentView.id);
      return;
    }
    // Do All type of validation here
    if (!level2 && MODEL_ACTION.CREATE === modelActiveMode) {
      if (this.isViewExist()) return;
    }
    // Final view
    const newView = {
      id: currentView.id,
      name: viewName,
      isActive: true,
      columns: this.getSelectedColumns(),
      baseView: currentView.baseView
    };

    if (MODEL_ACTION.CREATE === modelActiveMode) {
      if (level2 && this.isViewExist()) {
        const {id} = allViews.find((view) => view.name === viewName);
        newView.id = id;
        this.props.editCustomView(newView); // Edit existing view
      } else {
        delete newView.id;
        this.props.saveCustomView(newView); // Create new view
      }
    } else if ([MODEL_ACTION.EDIT, MODEL_ACTION.SELECT_COLUMNS].includes(modelActiveMode)) {
      if (currentView.name === viewName) {
        this.props.editCustomView(newView); // Edit existing view
      } else if (this.isViewExist()) {
        if (level2) {
          const {id} = allViews.find((view) => view.name === viewName);
          newView.id = id;
          this.props.editCustomView(newView); // Edit existing  view
        } else {
          this.setState({
            level2: true,
            isNotDuplicate: false,
            level2Message: labels.tkCopyPort16
          });
          return;
        }
      } else {
        if (allViews.length >= 10 && viewName !== currentView.name) {
          this.setState({
            level2: true,
            level2Message: labels.tkCopyPort26,
            isVisible: false
          });
          return;

        } else {
          delete newView.id;
          this.props.saveCustomView(newView); // Create new view
        }
      }
    }

    const newColsMetaData = newView.columns.map(col => {
      col.hide = false;
      return (columnsMetadata && columnsMetadata.find((column) => column.colId === col.colId));
    });
    this.props.dispatchViewSaveAnalytics(newColsMetaData, viewName);
  }

  cancelBtn() {
    this.setState({level2: false, isNotDuplicate: true, isDeleteActivated: false, level2Message: '', isVisible: true});
  }

  deleteView() {
    const {currentView, labels} = this.props;
    this.setState({
      level2: true,
      isDeleteActivated: true,
      level2Message: injectKeys(labels.tkCopyPort14, currentView.name)
    });
  }

  reStoreToDefault() {
    const {labels, currentView} = this.props;
    this.setState({
      level2: true,
      isDeleteActivated: true,
      level2Message: injectKeys(labels.tkCopyPort15, currentView.name)
    });
  }

  getHeaderLabel() {
    const {modelActiveMode, currentView, labels, hideEdit} = this.props;
    const {level2, isNotDuplicate} = this.state;

    if (modelActiveMode === MODEL_ACTION.EDIT) {
      if (!level2 || !isNotDuplicate) {
        lastHeader = hideEdit ? `${labels.tkModify} ${startCase(toLower(currentView.name))} ${labels.tkView}` : labels.tkEditView;
        return lastHeader;
      } else if (level2 && currentView.canRestoreToDefault && isNotDuplicate) {
        lastHeader = labels.tkRestoreView;
        return lastHeader;
      }
      lastHeader = labels.tkDeleteViewheader;
      return lastHeader;
    } else if (modelActiveMode === MODEL_ACTION.CREATE) {
      lastHeader = labels.tkCreateANewView;
      return lastHeader;
    } else if (modelActiveMode === MODEL_ACTION.SELECT_COLUMNS) {
      lastHeader = labels.tkSelectColumns;
      return lastHeader;
    }
    return lastHeader;
  }

  isDisabled() {
    const {viewName, level2, isValidInput} = this.state;
    if ((!level2 && (!isValidInput || !viewName)) || this.props.duplicateViewName) return true;
  };

  getBtnProps() {
    const {modelActiveMode, currentView, labels} = this.props;
    const {level2, isNotDuplicate, baseView} = this.state;
    const getLabel = () => {
      if (modelActiveMode === MODEL_ACTION.CREATE) {
        return labels.tkSaveView;
      } else if ([MODEL_ACTION.EDIT, MODEL_ACTION.SELECT_COLUMNS].includes(modelActiveMode)) {
        if (!level2 || !isNotDuplicate) {
          if (Object.values(STANDARD_REPORT_VIEW_NAMES).includes(baseView)) {
            return labels.tkApplyChangesReportingBtn;
          }
          return labels.tkSaveChanges;
        } else if (level2 && currentView.canRestoreToDefault && isNotDuplicate) {
          return labels.tkRestoreViewBtn;
        }
        return labels.tkDelete;
      }
    };

    return {
      isDisabled: this.isDisabled(),
      label: getLabel(),
      clickHandler: this.handleSubmit,
      customClass: 'button-primary-small'
    };
  }

  getLinkBtnProps() {
    const {modelActiveMode, currentView, labels, duplicateViewName} = this.props;
    const {level2} = this.state;
    const isDisabled = () => {
      if (duplicateViewName) return true;
      if (modelActiveMode === MODEL_ACTION.EDIT) {
        return (!currentView.isCustom && !level2);
      } else if (modelActiveMode === MODEL_ACTION.CREATE) {
        return !level2;
      }
    };

    const getLabel = () => {
      if (modelActiveMode === MODEL_ACTION.EDIT) {
        if (level2) {
          return labels.tkCancel;
        } else if (currentView.canRestoreToDefault) {
          return labels.tkRevertToDefault;
        }
        return labels.tkDeleteView;
      } else if (modelActiveMode === MODEL_ACTION.CREATE) {
        return level2 ? labels.tkCancel : labels.tkDeleteView;
      }
    };

    const getHandler = () => {
      const {level2, isNotDuplicate} = this.state;
      if (modelActiveMode === MODEL_ACTION.EDIT && !level2) {
        if (currentView.canRestoreToDefault) {
          return this.reStoreToDefault;
        } else if (isNotDuplicate) {
          return this.deleteView;
        }
      }
      return this.cancelBtn;
    };

    return {
      isDisabled: isDisabled(),
      label: getLabel(),
      handleClick: getHandler(),
      customClass: (!currentView.canRestoreToDefault && this.state.isNotDuplicate && this.props.hideDelete) ? 'link-primary-small hide' : 'link-primary-small'
    };
  }

  getFilteredGroupedColumn() {
    const {groupedColumns, searchInput} = this.state;
    if (searchInput && searchInput.length === 1 && !searchSubmitted) return groupedColumns;
    else if (searchSubmitted) searchSubmitted = false;
    return groupedColumns.map(category => (
      {
        ...category,
        headers: category.headers.filter(column => column.headerName.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1)
      }
    ));
  }

  getErrorMessage(searchResult) {
    const {labels} = this.props;
    const {isValidInput, errorMessage} = this.state;
    if (!isValidInput) return errorMessage;
    else if (!searchResult) return labels.tkCopyPort04;
  }

  render() {
    const {labels, isLoading, duplicateViewName, modelActiveMode, errorObject, label} = this.props;
    const {viewName, searchInput, isValidInput, level2, level2Message, isVisible} = this.state;
    const btnProps = this.getBtnProps();
    const linkBtnProps = this.getLinkBtnProps();
    const headerName = this.getHeaderLabel();
    const filteredGroupedColumn = this.getFilteredGroupedColumn();
    const columnsLengthArray = filteredGroupedColumn.map(data => data.headers.length);
    const searchResult = columnsLengthArray && columnsLengthArray.reduce((a, b) => a + b, 0);
    const isError = (!isValidInput || !searchResult);
    const errorText = isError && this.getErrorMessage(searchResult);
    const duplicateView = duplicateViewName && labels[duplicateViewName.errorMessage];

    const viewNameInputProps = {
      value: viewName,
      onChange: this.handleNameChange,
      maxLength: '14',
      className: 'saveNewView__input',
      placeholder: labels.tkNameThisView
    };
    const searchInputProps = {
      value: searchInput,
      onChange: this.handleSearchChange,
      className: 'saveNewView__input',
      placeholder: labels.tkSearch
    };
    const iconName = viewName ? 'saveNewView__icon close' : '';
    const icon = searchInput ? 'saveNewView__icon close' : 'saveNewView__icon';
    const modelClassName = isLoading ? 'modal-child-conatiner' : '';

    const error = {
      code: errorObject && errorObject.errorCode,
      label
    };
    const config = {

      cssTrackY: 'modal-scroll__track modal-scroll__track--Y',
      cssContent: 'modal-scroll__content',
      cssWrapper: 'modal-scroll__wrapper',
      overflowX: 'hidden',
      overflowY: 'scroll',
      style: {
        position: 'static'
      }
    };
    const selectColumnsMode = modelActiveMode === MODEL_ACTION.SELECT_COLUMNS;

    return (
      <div className={modelClassName} ref={(ref) => this.refModalBody = ref}>
        <Conditional condition={isLoading}><ThreeSquaresLoader/></Conditional>
        <div className="saveNewView">
          <div className="saveNewView__heading">{this.props.headerName || headerName}</div>
          <main>
            <hr className="saveNewView__separator saveNewView__separator--black"/>
            <Conditional
              condition={!(this.props.isPageInErrorMode && (errorObject.showErrorOn === SHOW_ERROR_ON.CUSTOM_VIEW_MODAL))}>
              <div>
                <Conditional condition={duplicateViewName}>
                  <div className="saveNewView__errorMessage">{duplicateView}</div>
                  <Conditional condition={!level2}>
                    <div>
                      <div className="saveNewView__inputContainerGroup">
                        <Conditional condition={!this.props.hideEdit && !selectColumnsMode}>
                          <div className="saveNewView__inputContainer">
                            <input {...viewNameInputProps} />
                            <span className={iconName} onClick={this.clearName}>&nbsp;</span>
                          </div>
                        </Conditional>
                        <div
                          className={`saveNewView__inputContainer ${this.props.hideEdit ? 'saveNewView__inputContainer--fullWidth' : ''}`}>
                          <form onSubmit={this.submitSearch}><input {...searchInputProps} />
                            <span className={icon} onClick={this.clearSearch}>&nbsp;</span>
                          </form>
                        </div>
                      </div>
                      <div
                        className={`saveNewView__body ${this.props.hideEdit ? 'saveNewView__body--top-position' : ''}`}>
                        {withScroll(List, config)({
                          isError,
                          errorText,
                          filteredGroupedColumn,
                          toggleOption: this.toggleOption
                        })}
                      </div>
                    </div>
                    <div className="saveNewView__errorMessage">{level2Message}</div>
                  </Conditional>
                </Conditional>
                <div className="saveNewView__buttonRow">
                  <hr className="saveNewView__separator--black-bottom"/>
                  <Conditional condition={isVisible}>
                    <Button {...btnProps} />
                  </Conditional>
                  <Link {...linkBtnProps} />
                </div>
              </div>
              <Error {...error} />
            </Conditional>
          </main>
        </div>
      </div>
    );
  }
}

CustomView.propTypes = {
  closeModal: PropTypes.func,
  groupedColumns: PropTypes.array,
  saveCustomView: PropTypes.func,
  deleteCustomView: PropTypes.func,
  editCustomView: PropTypes.func,
  viewName: PropTypes.string,
  dispatchViewSaveAnalytics: PropTypes.func,
  allViews: PropTypes.array,
  currentView: PropTypes.object,
  columnsMetadata: PropTypes.array,
  labels: PropTypes.object,
  label: PropTypes.func,
  modelActiveMode: PropTypes.string,
  isLoading: PropTypes.bool,
  duplicateViewName: PropTypes.object,
  errorObject: PropTypes.object,
  isPageInErrorMode: PropTypes.bool,
  hideEdit: PropTypes.bool,
  hideDelete: PropTypes.bool,
  dispatchColumnSearchNoResultAnalytics: PropTypes.func,
  open: PropTypes.bool,
  headerName: PropTypes.string,
  doAnalytics: PropTypes.func
};


export default CustomView;
