import { Accordion, AccordionPanel } from '@gs-ux-uitoolkit-react/accordion';
import { Label } from '@gs-ux-uitoolkit-react/label';
import { ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';
import { Component } from 'react';
import { pluginPreviewComponentStyleSheet } from '../../style/component/plugin-preview-layout-stylesheet';

export interface PluginPreviewComponentProps {
    previewTitle: string;
    previewDescription?: string;
    previewBody: JSX.Element | null;
    ['data-cy']?: string;
}

export class PluginPreviewComponent extends Component<PluginPreviewComponentProps> {
    constructor(props: PluginPreviewComponentProps) {
        super(props);
    }

    public componentWillUnmount() {
        pluginPreviewComponentStyleSheet.unmount(this);
    }
    public render() {
        const { previewTitle, previewDescription, previewBody } = this.props;

        const getPreviewContent = (showMetaData: boolean) => {
            return (
                <div className="gs-uitk-plugin-preview-container">
                    <div
                        className="gs-uitk-basic-expression-preview gs-uitk-custom-sort-preview"
                        data-cy="gs-uitk-basic-expression-preview"
                    >
                        {showMetaData == false ? null : (
                            <>
                                <Label className="gs-uitk-basic-expression-preview-heading">
                                    <strong className="gs-uitk-basic-expression-preview-heading-strong">
                                        {previewTitle}
                                    </strong>
                                </Label>

                                <p className="gs-uitk-basic-expression-preview-description">
                                    {previewDescription}
                                </p>
                            </>
                        )}
                        {previewBody}
                    </div>
                </div>
            );
        };
        return (
            <ThemeConsumer>
                {theme => {
                    const cssClasses = pluginPreviewComponentStyleSheet.mount(this, { theme });

                    return (
                        <div className={`${cssClasses.root}`} data-cy={this.props['data-cy']}>
                            {getPreviewContent(true)}
                            <Accordion
                                emphasis="bold"
                                className="gs-uitk-plugin-preview-accordion-container"
                            >
                                <AccordionPanel header={previewTitle}>
                                    {getPreviewContent(false)}
                                </AccordionPanel>
                            </Accordion>
                        </div>
                    );
                }}
            </ThemeConsumer>
        );
    }
}
