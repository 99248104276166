import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { InputSize, InputStatus } from './types';
import { getInputStyleSheet, getInputBaseStyles } from './input-style-sheet';
import {
    getInputControlStyleSheet,
    getInputControlStyles,
    getInputFontSizes,
    inputPaddingSize,
    textareaLineHeights,
} from './input-control-style-sheet';
import {
    getInputGroupContentStyles,
    getDefaultInputGroupContentTypographyTheme,
} from './input-group-content-style-sheet';
import { inputGroupBorderRadius } from './input-group-style-sheet';
import { colors } from '@gs-ux-uitoolkit-common/design-system';
import { DeepReadonly } from 'ts-essentials';
import './file-upload-theme-overrides';

export interface FileUploadStyleSheetProps {
    theme: Theme;
    disabled: boolean;
    inline: boolean;
    placeholder: boolean;
    size: InputSize;
    status: InputStatus;
}

export interface FileUploadCssClasses {
    root: string;
    input: string;
    text: string;
    button: string;
    spacer: string;
}

export type FileUploadStyledClasses = CssClassDefinitionsObject<keyof FileUploadCssClasses>;

export interface FileUploadStyleOverridesParams {
    props: DeepReadonly<FileUploadStyleSheetProps>;
    createDefaultStyledClasses: () => FileUploadStyledClasses;
}

export const fileUploadStyleSheet = new StyleSheet(
    'file-upload',
    (props: FileUploadStyleSheetProps) => {
        return createComponentClassDefinitions<FileUploadStyleSheetProps, FileUploadStyledClasses>(
            props,
            createDefaultStyledClasses,
            props.theme.styleOverrides?.fileUpload
        );
    }
);

export const globalFileUploadClass = 'gs-file-upload';

// When "inline", returns a width that is roughly equivalent to <input> width.
function getInlineWidth(size: InputSize) {
    if (size === 'sm') {
        return '10rem';
    } else if (size === 'lg') {
        return '14rem';
    }
    return '12rem';
}

function getFileUploadClassStyles({
    theme,
    inline = false,
    size = 'md',
}: {
    theme: Theme;
    inline?: boolean;
    size?: InputSize;
}) {
    return {
        root: {
            ...getInputBaseStyles({ theme }),
            padding: 0,
            width: inline ? getInlineWidth(size) : 'auto',
        },

        input: {
            opacity: 0,
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            cursor: 'pointer',

            '&:disabled': {
                cursor: 'default',
            },
        },

        text: {
            ...getInputControlStyles({ theme }),
            whiteSpace: 'nowrap',
            '&::placeholder': {
                color: colors.gray060,
            },
        },

        button: {
            ...getInputGroupContentStyles({ theme }),
            backgroundColor: theme.colorScheme.secondary,
        },
    };
}

function createDefaultStyledClasses({
    theme,
    disabled,
    inline,
    placeholder,
    size,
    status,
}: FileUploadStyleSheetProps): FileUploadStyledClasses {
    const fileUploadClasses = getFileUploadClassStyles({ theme, inline, size });
    return {
        root: {
            ...fileUploadClasses.root,
            ...getInputStyleSheet({ theme, disabled, inline, size, status }),
        },

        input: {
            ...fileUploadClasses.input,
        },

        text: {
            ...fileUploadClasses.text,
            ...getInputControlStyleSheet({ theme, size }),
            ...(placeholder ? fileUploadClasses.text['&::placeholder'] : {}),
            fontSize: getInputFontSizes(theme)[size],
            lineHeight: textareaLineHeights[size],
            padding: inputPaddingSize[size],
        },

        button: {
            ...getDefaultInputGroupContentTypographyTheme(theme)[size],
            ...fileUploadClasses.button,
            borderRadius: inputGroupBorderRadius.lastChild,
            alignSelf: 'stretch',
        },
        spacer: {
            height: { sm: 8, md: 12, lg: 16 }[size],
        },
    } as {
        root: any;
        input: any;
        text: any;
        button: any;
        spacer: any;
    };
}
