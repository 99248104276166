import { IconFont } from './generated/fonts';
import { IconRequiredProps } from './props';
import { IconName } from './design-system-icons/full-set';
import { customIconMap, CustomIcon } from './design-system-icons/custom-icon-set';

/**
 * Returns the Font Name for the provided icon
 *
 * @param name Name of the icon
 * @param type Type of the icon
 */
export function resolveIconFont(props: IconRequiredProps): IconFont | null {
    const { name } = props;

    if (isCustomIcon(name)) {
        return 'custom';
    }
    return 'materialSymbols';
}

function isCustomIcon(name: IconName): boolean {
    return customIconMap[name as CustomIcon] || false;
}
