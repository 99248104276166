import React, {forwardRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Select, {components, createFilter} from 'react-select';
import {Icon} from '@gs-ux-uitoolkit-react/icon-font';
import {ThreeSquaresLoader} from '../../Loaders';
import {Conditional} from '../../Conditional';
import './index.scss';

const DropdownIndicator = props => (
    components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <Icon name="search" type="filled" />
        </components.DropdownIndicator>
    )
);

export const CustomSelect = forwardRef((props, ref) => {
  const {isOpenMenu = false, placeholder, searchItems} = props;
  const [openMenu, setOpenMenu] = useState(isOpenMenu);

  useEffect(() => {
    setOpenMenu(isOpenMenu);
  }, [isOpenMenu]);

  const customStyles = {
    valueContainer: base => ({
      ...base,
      paddingLeft: 4
    }),
    control: (base) => ({
      ...base,
      borderRadius: '1px',
      paddingLeft: '10px'
    }),
    menuList: (base) => ({
      ...base,
      paddingTop: '0px'
    }),
    group: (base) => ({
      ...base,
      paddingTop: '0px'
    }),
    groupHeading: (base) => ({
      ...base,
      fontWeight: 600,
      padding: '12px 12px 10px 15px',
      fontSize: '14px',
      color: '#3E5463',
      textTransform: 'none',
      backgroundColor: '#F2F5F7'
    })
  };

  const onBlurHandler = () => {
    if (openMenu) {
      setOpenMenu(false);
      props.handleOnBlur && props.handleOnBlur();
    }
  };

  const handleOnFocus = () => {
    props.handleOnFocus && props.handleOnFocus();
  };

  const handleSelection = (option) => {
    onBlurHandler();
    props.handleSelection(option);
  };

  const handleInputChange = (query, {action}) => {
    if (props.handleInputChange) {
      props.handleInputChange(query, {action});
    } else {
      const isMenuOpen = (action === 'input-change' && query.length >= 3);
      setOpenMenu(isMenuOpen);
    }
  };

  return (
    <div className={cn('select-wrapper', {'select-wrapper--loading': props.isDataLoading})}>
      <Conditional condition={props.isDataLoading}>
        <ThreeSquaresLoader />
      </Conditional>
      <div className="select-comp">
        <Select
          classNamePrefix="custom-select"
          isDisabled={props.isDisabled}
          styles={customStyles}
          filterOption={createFilter({ ignoreAccents: false })}
          components={{DropdownIndicator, Option: props.option}}
          placeholder={placeholder}
          options={searchItems}
          onFocus={handleOnFocus}
          onChange={handleSelection}
          onInputChange={handleInputChange}
          onBlur={onBlurHandler}
          ref={ref}
          menuIsOpen={openMenu}
          controlShouldRenderValue={false}
        />
      </div>
    </div>
  );
});

CustomSelect.propTypes = {
  isDisabled: PropTypes.bool,
  isDataLoading: PropTypes.bool,
  placeholder: PropTypes.string,
  searchItems: PropTypes.array,
  handleSelection: PropTypes.func,
  handleOnFocus: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleOnBlur: PropTypes.func,
  option: PropTypes.func,
  isOpenMenu: PropTypes.bool
};

export default CustomSelect;
