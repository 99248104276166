export enum QuickFilterActionKeys {
    REMOVE_QUICK_FILTER = 'REMOVE_QUICK_FILTER',
    EDIT_QUICK_FILTER = 'EDIT_QUICK_FILTER',
    NEW_QUICK_FILTER = 'NEW_QUICK_FILTER',
    UPDATE_EDITED_QUICK_FILTER = 'UPDATE_EDITED_QUICK_FILTER',
    TOGGLE_ENABLED_QUICK_FILTER = 'TOGGLE_ENABLED_QUICK_FILTER',
    SAVE_EDITED_QUICK_FILTER = 'SAVE_EDITED_QUICK_FILTER',
    CANCEL_EDITED_QUICK_FILTER = 'CANCEL_EDITED_QUICK_FILTER',
    SET_ENABLED_QUICK_FILTER_LIST = 'SET_ENABLED_QUICK_FILTER_LIST',
    ADD_QUICK_FILTER = 'ADD_QUICK_FILTER',
}
