import { PropsWithChildren, useState, CSSProperties, FC, memo, useEffect, AriaRole } from 'react';
import PropTypes from 'prop-types';
import { Fade, FadeProps, fadeDefaultProps } from '@gs-ux-uitoolkit-react/fade';
import { ReactComponentProps } from '@gs-ux-uitoolkit-react/component';
import { CloseButton } from '@gs-ux-uitoolkit-react/close-button';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import {
    AlertCommonProps,
    alertCommonDefaultProps,
    AlertCommonDefaultProps,
    alertStatuses,
    alertStyleSheet,
    alertEmphases,
    alertSizes,
    getRootClassNames,
    getIconClassNames,
    getDismissButtonClassNames,
} from '@gs-ux-uitoolkit-common/alert';
import { VisuallyHidden } from '@gs-ux-uitoolkit-react/accessibility';
import { Icon, statusIcons } from '@gs-ux-uitoolkit-react/icon-font';
import { componentAnalytics } from './analytics-tracking';

export type AlertProps = PropsWithChildren<
    ReactComponentProps & AlertCommonProps<CSSProperties> & AlertReactOnlyProps
>;

interface AlertReactOnlyProps {
    /**
     * Set to false to disable a subtle fade transition that occurs when the alert is dismissed.
     * The transition is enabled by default.
     */
    fade?: boolean | FadeProps;
    /**
     * ARIA role. This is applied automatically based on status, but can be overriden.
     */
    role?: AriaRole;

    onShow?: () => void;
    onHide?: () => void;
    onDismiss?: () => void;
}

const noopFn = () => {};
export const alertDefaultProps: AlertDefaultProps = {
    ...alertCommonDefaultProps,
    fade: true,
    onShow: noopFn,
    onHide: noopFn,
    onDismiss: noopFn,
};

export interface AlertDefaultProps
    extends AlertCommonDefaultProps,
        Required<Pick<AlertProps, 'fade' | 'onShow' | 'onHide' | 'onDismiss'>> {}

/**
 * Alerts display a status update, reflecting a user or system action.
 */
export const Alert: FC<AlertProps> = memo(
    ({
        children,
        className,
        role: providedRole,
        classes: overrideClasses,
        dismissButtonAriaLabel = alertDefaultProps.dismissButtonAriaLabel,
        dismissible = alertDefaultProps.dismissible,
        emphasis = alertDefaultProps.emphasis,
        fade = alertDefaultProps.fade,
        icon,
        onDismiss = alertDefaultProps.onDismiss,
        onHide = alertDefaultProps.onHide,
        onShow = alertDefaultProps.onShow,
        size = alertDefaultProps.size,
        status = alertDefaultProps.status,
        visible: controlledVisible,
        ...otherProps
    }) => {
        const [uncontrolledVisible, setUncontrolledVisible] = useState(true);
        const visible = controlledVisible != null ? controlledVisible : uncontrolledVisible;

        const role = providedRole
            ? providedRole
            : status === 'error' || status === 'warning'
              ? 'alert'
              : 'status';

        const onDismissButtonClick = () => {
            onDismiss();
            setUncontrolledVisible(false);
        };

        const theme = useTheme();
        const cssClasses = useStyleSheet(alertStyleSheet, {
            theme,
            status,
            size,
            emphasis,
            dismissible,
            visible,
        });
        const themeIcon = statusIcons[status];

        const fadeProps = fade === true ? fadeDefaultProps : fade === false ? { timeout: 0 } : fade;

        const alertTransition = {
            ...fadeProps,
            unmountOnExit: true,
            mountOnEnter: true,
            baseClass: fadeProps.baseClass ? fadeProps.baseClass : '',
            timeout: fadeProps.timeout ? fadeProps.timeout : 0,
            onEntered: onShow,
            onExited: onHide,
        };

        useEffect(() => {
            //track component has rendered
            componentAnalytics.trackRender({ officialComponentName: 'alert' });
        }, []); // Only run once

        return (
            <Fade
                data-gs-uitk-component="alert"
                data-status={status}
                data-emphasis={emphasis}
                data-visible={visible}
                {...otherProps}
                {...alertTransition}
                className={getRootClassNames({
                    cssClasses,
                    overrideClasses,
                    className,
                })}
                in={visible}
                role={role}
            >
                <Icon
                    data-cy="gs-uitk-alert__icon"
                    aria-hidden={true}
                    {...(icon || themeIcon)}
                    className={getIconClassNames({ cssClasses, overrideClasses })}
                />
                {status && <VisuallyHidden>{status} alert!</VisuallyHidden>}
                {children}
                {dismissible ? (
                    <span
                        className={getDismissButtonClassNames({
                            cssClasses,
                            overrideClasses,
                        })}
                    >
                        <CloseButton
                            surface={status}
                            emphasis={emphasis}
                            size={size}
                            aria-label={dismissButtonAriaLabel}
                            classes={{ button: overrideClasses?.dismissButton }}
                            onClick={onDismissButtonClick}
                        />
                    </span>
                ) : null}
            </Fade>
        );
    }
);
Alert.displayName = 'Alert';

Alert.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    classes: PropTypes.shape({
        root: PropTypes.string,
        dismissButton: PropTypes.string,
        icon: PropTypes.string,
    }),
    dismissButtonAriaLabel: PropTypes.string,
    dismissible: PropTypes.bool,
    emphasis: PropTypes.oneOf(alertEmphases),
    fade: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape(Fade.propTypes!)]),
    onDismiss: PropTypes.func,
    onHide: PropTypes.func,
    onShow: PropTypes.func,
    size: PropTypes.oneOf(alertSizes),
    status: PropTypes.oneOf(alertStatuses),
    visible: PropTypes.bool,
} as any;
