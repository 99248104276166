import { ComponentProps, CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { Placement, Size } from '@gs-ux-uitoolkit-common/design-system';
import { MenuBlurEvent } from './menu-event';
import { MenuCssClasses } from './menu-style-sheet';

export type MenuPlacement = Extract<
    Placement,
    | 'top'
    | 'top-left'
    | 'top-right'
    | 'bottom'
    | 'bottom-left'
    | 'bottom-right'
    | 'left'
    | 'left-top'
    | 'left-bottom'
    | 'right'
    | 'right-top'
    | 'right-bottom'
    | 'auto'
>;

export type MenuSize = Extract<Size, 'sm' | 'md' | 'lg'>;

export type MenuValue = string | number;

export interface MenuStateProps {
    /**
     * Whether the Menu should be appended to `document.body` (default) or
     * should render "inline" in the DOM hierarchy of the React or Angular
     * application. Defaults to `true`.
     */
    appendToBody?: boolean;

    /**
     * Whether to focus the first menu option when the menu is shown.
     */
    autoFocus?: boolean;

    /**
     * Whether the Menu is visible or not.
     */
    visible?: boolean;

    /**
     * Fixes the Menu at its initial position when visible, preventing it
     * from adjusting to window scroll or resize events.
     */
    fixed?: boolean;

    /**
     * Offsets the Menu from its `target`. Values will be treated as pixels. For
     * examples, the value `[10, 10]` will both be interpreted as x offset
     * `10px` and y offset `10px`.
     *
     * To show Menu at specific x and y coordinates instead of relative to a
     * DOM element (useful when using Menu as a context menu, for example),
     * leave `target` undefined and the offset value will then be treated as
     * relative to the upper left corner of the window. See "As a context menu"
     * below for an example.
     */
    offset?: [number, number];

    /**
     * Intended placement of the Menu relative to its `target`.
     */
    placement?: MenuPlacement;

    /**
     * The HTML element relative to which the Menu should be positioned. If
     * `target` is a string, then it will be assumed to be a CSS selector.
     *
     * To render a Menu at specific coordinates instead of relative to a HTML
     * element, leave `target` undefined and set `offset` instead.
     */
    target?: string | HTMLElement;
}

export interface MenuCommonProps<StyleType = CommonStyleType>
    extends ComponentProps<StyleType>,
        MenuStateProps {
    /**
     * Style classes to override.
     */
    classes?: Partial<MenuCssClasses>;

    /**
     * Sets the rendered size.
     */
    size?: MenuSize;

    /**
     * Sets the menu to a disabled state.
     */
    disabled?: boolean;

    /**
     * Called when the Menu loses focus, such as when a user clicks on another
     * part of the page.
     *
     * This would be a good time to close the Menu by setting `visible` to `false`.
     */
    onBlur?: (event: MenuBlurEvent) => void;
}

// Used by CollapseMenu and any other menu types that do not need all the props
// necessary for a component that uses Popper.js.
export interface SimpleMenuProps<StyleType = CommonStyleType> extends ComponentProps<StyleType> {
    autoFocus?: MenuStateProps['autoFocus'];
    disabled?: MenuCommonProps['disabled'];
    visible?: MenuStateProps['visible'];
    size?: MenuCommonProps['size'];
    classes?: MenuCommonProps['classes'];
}

export interface SingleValueProps {
    /**
     * Whether the Menu allows single or multiple simultaneous selected values.
     */
    multiple?: boolean;

    /**
     * The selected value of the Menu. Set to `null` to deselect all values.
     */
    value?: MenuValue | null;

    /**
     * The initial value of the Menu. It works in a manner similar to that of
     * a text input's [defaultValue](https://www.w3schools.com/jsref/prop_text_defaultvalue.asp).
     */
    defaultValue?: MenuValue;
}

export interface MultipleValueProps {
    /**
     * Whether the Menu allows single or multiple simultaneous selected values.
     */
    multiple?: true;

    /**
     * Array of selected values of the Menu.
     */
    value?: MenuValue[];

    /**
     * The initial value of the Menu. It works in a manner similar to that of
     * a text input's [defaultValue](https://www.w3schools.com/jsref/prop_text_defaultvalue.asp).
     */
    defaultValue?: MenuValue[];
}

export interface MenuOptionSelectEvent {
    /**
     * The value of the selected menu option
     */
    value?: MenuValue;

    /**
     * Function to call if the menu option's selection should be canceled
     */
    preventDefault: () => void;
}

export type MenuValueType = SingleValueProps['value'] | MultipleValueProps['value'];

export type MenuProps = SingleValueProps | MultipleValueProps;

export const defaultMenuProps: DefaultMenuProps = {
    size: 'md',
};

export type DefaultMenuProps = Required<Pick<MenuCommonProps, 'size'>>;
