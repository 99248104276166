import React from 'react';
import {PropTypes} from 'prop-types';
import map from 'lodash/map';
import translator from '../../../../../services/translator';
import './index.scss';

const {translate: t} = translator;

const ErrorContainer = ({helpContents}) => (
  <div className="bmo-error-container">
    <div className="bmo-error-container__wrapper">
      <div className="bmo-error-container__header-wrapper">
        <h1>{t('tkMosaicLoginErrorHeader')}</h1>
        <p>{t('tkMosaicLoginErrorMsg')}</p>
        <p>{t('tkMosaicLoginErrorMsg2')}</p>
      </div>
      <div className="bmo-error-container__content-wrapper">
        {
          map(helpContents, (item, idx) => {
            return (
              <div key={idx} className="bmo-error-container__content">
                <div className="item">
                  <span className='item-header'>{t('tkGroupName')}</span>
                  <span>{item.groupName}</span>
                </div>
                <div className="item">
                  <span className='item-header'>{t('tkPhoneNumber')}</span>
                  <span>{item.phoneNumber}</span>
                </div>
                <div className="item">
                  <span className='item-header'>{t('tkEmail')}</span>
                  <span>{item.emailAddress}</span>
                </div>
                <div className="item">
                  <span className='item-header'>{t('tkHoursOfOperation')}</span>
                  <span>{item.hoursOfOperation}</span>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  </div>
);

ErrorContainer.propTypes = {
  helpContents: PropTypes.array
};

export default ErrorContainer;
