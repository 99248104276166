import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Constants from '../../../constants/appConstants';
import {reportHeaderAndIFrameURLSelector} from "../../../selectors/pages/reports/individualReport";
import IFrameComponent from "../../../components/core/IFrame";
import './index.scss';

const StitchedReport = ({reportHeaderAndIFrameURL}) => (
  <div className="stitched-report">
    <IFrameComponent
      title="reporting"
      src={reportHeaderAndIFrameURL}
      iFrameCustomHeight={Constants.REPORTING_IFRAME_WIDTH}
    />
  </div>
);

StitchedReport.propTypes = {
  reportHeaderAndIFrameURL: PropTypes.object
};

const mapStateToProps = (state) => ({
  reportHeaderAndIFrameURL: reportHeaderAndIFrameURLSelector(state)
});

export default connect(mapStateToProps)(StitchedReport);
