import {
    AddCrossModel,
    AddQuickFilter,
    ColumnHint,
    CrossModel,
    CrossModelState,
    enumHelper,
    ExpressionQuery,
    globalHelper,
    QuickFilter,
    RemoveCrossModel,
    RemoveQuickFilter,
    SimpleEventDispatcher,
    ToggleEnabledQuickFilter,
    UIToolkitDeepPartial,
    UpdateCrossModel,
    DraViewConfig,
    DraSelectionOperation,
    arrayHelper,
    invariantQuery,
    ExpressionMode,
    DraProvider,
    ReduxStorage,
} from '@gs-ux-uitoolkit-common/datacore';
import { isEqual } from 'gs-uitk-lodash';
import * as Redux from 'redux';
import { buildCompositeFilterExpression } from '../libraries/helpers/filter-helper';
import { CustomSort } from '../plugins/custom-sort/custom-sort-plugin';
import { KeyboardShortcut } from '../plugins/keyboard-shortcut/keyboard-shortcut';
import { RowCustomisation } from '../plugins/row-customisation/row-customisation';
import { Plugins } from '../plugins/plugin-enum';
import { NotificationItem } from '../plugins/notification/notification-plugin';
import { ToggleEnablePlugin } from '../redux/actions/autofit-pivot-action';
import { AddColumnCustomisation, RemoveColumnHint } from '../redux/actions/column-hint-action';
import { Provider } from 'react-redux';
import { SavedView, SavedViewOptions } from '../plugins/saved-view/saved-view';
import { AddCustomSort, RemoveCustomSort } from '../redux/actions/custom-sort-action';
import {
    AddKeyboardShortcut,
    RemoveKeyboardShortcut,
} from '../redux/actions/keyboard-shortcut-action';
import {
    AddRowCustomisation,
    RemoveRowCustomisation,
} from '../redux/actions/row-customisation-action';
import {
    AddSavedView,
    CreateSavedView,
    RemoveSavedView,
    SaveSavedView,
    SetSavedView,
} from '../redux/actions/saved-view-action';
import {
    AddColumnMasking,
    AddColumnMaskingList,
    RemoveColumnMaskingList,
} from '../redux/actions/masked-column-action';
import { SetDRASearchableColumnList, SetSearch } from '../redux/actions/searchable-action';
import {
    AddNotification,
    RemoveAllDismissibleNotification,
    RemoveAllNotification,
    RemoveNotification,
} from '../redux/actions/notification-action';
import {
    SetStripeInterval,
    SetZebraStripesBackgroundColours,
} from '../redux/actions/zebra-stripes-action';
import { ExportOptions } from '../plugins/exports/export';
import { CreateExport, SetExportOptions } from '../redux/actions/exports-action';
import { DataGridState, SavedViewState, PluginStateChangedPayload } from '../redux/datagrid-state';
import { GridWrapper } from './grid-wrapper';
import { DraPlugin } from '../plugins/dra/dra-plugin';
import { GridConfigurationModalContent } from '../components/grid-configuration-modal/grid-configuration-modal-content';
import { hasOwnProperty } from 'gs-uitk-object-utils';

const subStateToIgnore = ['grid'];

export class DataGridApi {
    public autoFitPivotChanged: SimpleEventDispatcher<boolean> =
        new SimpleEventDispatcher<boolean>();
    public columnCustomisationsChanged: SimpleEventDispatcher<ColumnHint[]> =
        new SimpleEventDispatcher();
    public crossModelsChanged: SimpleEventDispatcher<CrossModelState> = new SimpleEventDispatcher();
    public customSortPluginChanged: SimpleEventDispatcher<CustomSort[]> =
        new SimpleEventDispatcher();
    // TODO to be removed in v17? No events are dispatched to this
    public draChanged: SimpleEventDispatcher<object> = new SimpleEventDispatcher();
    public keyboardShortcutsChanged: SimpleEventDispatcher<KeyboardShortcut[]> =
        new SimpleEventDispatcher();
    public rowCustomisationsChanged: SimpleEventDispatcher<RowCustomisation[]> =
        new SimpleEventDispatcher();
    public savedViewsChanged: SimpleEventDispatcher<SavedViewState> =
        new SimpleEventDispatcher<SavedViewState>();
    public maskedColumnsChanged: SimpleEventDispatcher<string[]> = new SimpleEventDispatcher();
    public quickFiltersChanged: SimpleEventDispatcher<QuickFilter[]> = new SimpleEventDispatcher();
    public searchChanged: SimpleEventDispatcher<string> = new SimpleEventDispatcher();
    public notificationsChanged: SimpleEventDispatcher<NotificationItem[]> =
        new SimpleEventDispatcher();
    public rowStripesChanged: SimpleEventDispatcher<number> = new SimpleEventDispatcher();
    public datagridStateChanged: SimpleEventDispatcher<DataGridState> = new SimpleEventDispatcher();
    public pluginStateChanged: SimpleEventDispatcher<PluginStateChangedPayload> =
        new SimpleEventDispatcher();

    private stateMap: Map<Plugins, any> = new Map();

    constructor(private gridWrapper: GridWrapper) {
        this.gridWrapper.getReduxStore().subscribe(() => this.handleStateChange());
        this.gridWrapper.pluginStateChanged.subscribe((state: PluginStateChangedPayload) =>
            this.handlePluginStateChange(state)
        );
    }

    /**
     * Plugin events
     */

    public get savedViewSaved() {
        return this.gridWrapper.savedViewSaved;
    }
    public get savedViewCreated() {
        return this.gridWrapper.savedViewCreated;
    }
    public get savedViewDeleted() {
        return this.gridWrapper.savedViewDeleted;
    }
    public get quickFilterCreated() {
        return this.gridWrapper.quickFilterCreated;
    }
    public get quickFilterDeleted() {
        return this.gridWrapper.quickFilterDeleted;
    }
    public get quickFilterSaved() {
        return this.gridWrapper.quickFilterSaved;
    }

    public getState(): DataGridState {
        const clonedState = globalHelper.cloneObject(this.gridWrapper.getReduxStore().getState());
        const baseState = this.gridWrapper.getBaseState();
        for (const key in clonedState) {
            if (hasOwnProperty(clonedState, key)) {
                if (subStateToIgnore.indexOf(key) !== -1) {
                    delete (clonedState as any)[key];
                }
                if (
                    hasOwnProperty(clonedState, key) &&
                    baseState &&
                    hasOwnProperty(baseState, key)
                ) {
                    const currentSubState = (clonedState as any)[key];
                    const baseSubState = (baseState as any)[key];
                    if (isEqual(currentSubState, baseSubState)) {
                        delete (clonedState as any)[key];
                    }
                }
            }
        }
        return clonedState;
    }

    private handlePluginStateChange(state: PluginStateChangedPayload) {
        this.pluginStateChanged.dispatch(state);
    }

    public getFullState(): DataGridState {
        const clonedState = globalHelper.cloneObject(this.gridWrapper.getReduxStore().getState());
        return clonedState;
    }

    public dispatchAction(action: Redux.Action): void {
        this.gridWrapper.getReduxStore().dispatch(action);
    }

    public loadState(state: UIToolkitDeepPartial<DataGridState>): void {
        this.gridWrapper.getReduxStore().dispatch({
            payload: state,
            type: ReduxStorage.LOAD,
        });
    }

    public getCompositeFilterExpression(): ExpressionQuery {
        return buildCompositeFilterExpression(this.gridWrapper);
    }

    // AutofitPivotPlugin
    public autoFitPivotEnabled(enable: boolean): void {
        if (this.isAutoFitPivotEnabled() !== enable) {
            this.gridWrapper.getReduxStore().dispatch(ToggleEnablePlugin());
        }
    }
    public isAutoFitPivotEnabled(): boolean {
        return this.gridWrapper.getReduxStore().getState().autofitPivot.enabled;
    }

    // ColumnCustomisationPlugin
    public getColumnCustomisationList(): ColumnHint[] {
        return this.gridWrapper.getReduxStore().getState().columnHint.configItemList;
    }
    public addColumnCustomisation(hint: ColumnHint): void {
        this.gridWrapper.getReduxStore().dispatch(AddColumnCustomisation(hint));
    }
    public removeColumnCustomisation(hint: ColumnHint): void {
        this.gridWrapper.getReduxStore().dispatch(RemoveColumnHint(hint));
    }

    // CrossModelPlugin
    public getCrossModelState(): CrossModelState {
        return this.gridWrapper.getReduxStore().getState().crossModel;
    }
    public addCrossModel(crossModelKey: string, crossModel: CrossModel): void {
        this.gridWrapper.getReduxStore().dispatch(AddCrossModel(crossModelKey, crossModel));
    }
    public updateCrossModel(
        crossModelKey: string,
        crossModel: CrossModel,
        newCrossModelKey?: string
    ): void {
        this.gridWrapper
            .getReduxStore()
            .dispatch(UpdateCrossModel(crossModelKey, crossModel, newCrossModelKey));
    }
    public removeCrossModel(crossModelKey: string): void {
        this.gridWrapper.getReduxStore().dispatch(RemoveCrossModel(crossModelKey));
        const quickFilterToDelete = this.getQuickFilterList().find(
            quickFilter => quickFilter.name === crossModelKey
        );
        if (quickFilterToDelete) {
            this.gridWrapper.getReduxStore().dispatch(RemoveQuickFilter(quickFilterToDelete));
        }
        this.gridWrapper.getSelectedNodes().forEach(rowNode => {
            rowNode.setSelected(false);
        });
    }

    // CustomSortPlugin
    public addCustomSort(customSort: CustomSort): void {
        if (this.getCustomSortList().find(x => x.columnId === customSort.columnId)) {
            throw Error('There can be only one customSort per column');
        }
        this.gridWrapper.getReduxStore().dispatch(AddCustomSort(customSort));
    }
    public removeCustomSort(customSort: CustomSort): void {
        this.gridWrapper.getReduxStore().dispatch(RemoveCustomSort(customSort));
    }
    public getCustomSortList(): CustomSort[] {
        return this.gridWrapper.getReduxStore().getState().customSort.configItemList;
    }

    // DraPlugin
    public getDraViewConfig(): DraViewConfig | null {
        const draPlugin = this.gridWrapper
            .getPlugins()
            .find(plugin => plugin.getName() === Plugins.DraPlugin);
        if (!draPlugin) {
            return null;
        }
        const castedDraPlugin = draPlugin as DraPlugin;
        return castedDraPlugin.getViewConfig();
    }
    public draDeselectAll(): void {
        const draPlugin = this.gridWrapper
            .getPlugins()
            .find(plugin => plugin.getName() === Plugins.DraPlugin);
        if (!draPlugin) {
            return;
        }
        const castedDraPlugin = draPlugin as DraPlugin;
        const draProvider = castedDraPlugin.getDraProvider();
        const draDatasource = castedDraPlugin.getDraDatasource();
        const firstRecord = arrayHelper.first(draDatasource.getData());
        if (firstRecord) {
            draProvider.setSelection(firstRecord, DraSelectionOperation.CLEAR);
        }
    }

    public getDraProvider(): DraProvider | undefined {
        const draPlugin = this.gridWrapper
            .getPlugins()
            .find(plugin => plugin.getName() === Plugins.DraPlugin);
        if (!draPlugin) {
            return;
        }
        const castedDraPlugin = draPlugin as DraPlugin;
        const draProvider = castedDraPlugin.getDraProvider();
        return draProvider;
    }

    // KeyboardShortcutPlugin
    public addKeyboardShortcut(shortcut: KeyboardShortcut): void {
        if (this.getKeyboardShortcutList().find(x => x.key === shortcut.key)) {
            throw Error('KeyboardShortcut Key is already taken');
        }
        this.gridWrapper.getReduxStore().dispatch(AddKeyboardShortcut(shortcut));
    }
    public removeKeyboardShortcut(shortcut: KeyboardShortcut): void {
        this.gridWrapper.getReduxStore().dispatch(RemoveKeyboardShortcut(shortcut));
    }
    public getKeyboardShortcutList(): KeyboardShortcut[] {
        return this.gridWrapper.getReduxStore().getState().keyboardShortcut.configItemList;
    }

    // Export Plugin
    /**
     * Triggers an export action on the grid, by default the file created will be an excel file.
     * If you would like to customize the export actions please set the export options
     * using {@link setExportOptions} and then call this method.
     */
    public createExport(): void {
        this.gridWrapper.getReduxStore().dispatch(CreateExport());
    }

    /**
     * Allows you to customize and set the different export options that will be used when calling
     * {@link createExport}
     * @param options object containing the different export options.
     */
    public setExportOptions(options: ExportOptions): void {
        this.gridWrapper.getReduxStore().dispatch(SetExportOptions(options));
    }

    // RowCustomisationPlugin
    public addRowCustomisation(rowCustomisation: RowCustomisation): void {
        this.gridWrapper.getReduxStore().dispatch(AddRowCustomisation(rowCustomisation));
    }
    public removeRowCustomisation(rowCustomisation: RowCustomisation): void {
        this.gridWrapper.getReduxStore().dispatch(RemoveRowCustomisation(rowCustomisation));
    }
    public getRowCustomisationList(): RowCustomisation[] {
        return this.gridWrapper.getReduxStore().getState().rowCustomisation.configItemList;
    }

    // SavedViewsPlugin
    public getSavedViewsList(): SavedView[] {
        return this.gridWrapper.getReduxStore().getState().savedView.savedViewList;
    }
    public getCurrentSavedViews(): string | null {
        return this.gridWrapper.getReduxStore().getState().savedView.currentSavedView;
    }
    public getSavedViewsState(): SavedViewState {
        return this.gridWrapper.getReduxStore().getState().savedView;
    }
    public removeSavedView(name: string): void {
        this.gridWrapper.getReduxStore().dispatch(RemoveSavedView(name));
    }
    public addSavedView(savedView: SavedView): void {
        if (this.getSavedViewsList().find(x => x.name === savedView.name)) {
            throw Error('SavedView name need to be unique');
        }
        this.gridWrapper.getReduxStore().dispatch(AddSavedView(savedView));
    }
    public setSavedView(name: string): void {
        this.gridWrapper.getReduxStore().dispatch(SetSavedView(name));
    }
    public saveSavedView(savedView: SavedView): void {
        this.gridWrapper.getReduxStore().dispatch(SaveSavedView(savedView));
    }
    public createSavedView(savedViewOption: SavedViewOptions, name: string): void {
        const nameAlreadyExists = this.gridWrapper
            .getReduxStore()
            .getState()
            .savedView.savedViewList.some(savedView => savedView.name === name.trim());
        if (nameAlreadyExists) {
            throw new Error(`Unable to create savedView. SavedView ${name} already exists.`);
        } else {
            this.gridWrapper.getReduxStore().dispatch(CreateSavedView(savedViewOption, name));
        }
    }

    // MaskedColumnPlugin
    public getColumnMaskingList(): string[] {
        return this.gridWrapper.getReduxStore().getState().maskedColumn.columnIdList;
    }
    public addColumnMasking(columnId: string): void {
        this.gridWrapper.getReduxStore().dispatch(AddColumnMasking(columnId));
    }
    public addColumnMaskingList(columnIdList: string[]): void {
        this.gridWrapper.getReduxStore().dispatch(AddColumnMaskingList(columnIdList));
    }
    public removeColumnMaskingList(columnIdList: string[]): void {
        this.gridWrapper.getReduxStore().dispatch(RemoveColumnMaskingList(columnIdList));
    }

    // QuickFilterPlugin
    public getQuickFilterList(): QuickFilter[] {
        return this.gridWrapper.getReduxStore().getState().quickFilter.configItemList;
    }
    public addQuickFilter(quickFilter: QuickFilter): void {
        if (this.getQuickFilterList().find(x => x.name === quickFilter.name)) {
            throw Error('QuickFilter Name needs to be unique');
        }

        if (quickFilter.isComputedExternally) {
            quickFilter.expression = {
                mode: ExpressionMode.Experienced,
                query: invariantQuery,
            };
        }

        this.gridWrapper.getReduxStore().dispatch(AddQuickFilter(quickFilter));
    }
    public removeQuickFilter(quickFilter: QuickFilter): void {
        this.gridWrapper.getReduxStore().dispatch(RemoveQuickFilter(quickFilter));
    }
    public toggleEnabledQuickFilter(quickFilter: QuickFilter): void {
        this.gridWrapper.getReduxStore().dispatch(ToggleEnabledQuickFilter(quickFilter));
    }

    // SearchablePlugin
    public getSearchValue(): string {
        return this.gridWrapper.getReduxStore().getState().searchable.currentSearch;
    }
    public setSearchValue(search: string): void {
        this.gridWrapper.getReduxStore().dispatch(SetSearch(search));
    }
    public setDRASearchableColumnList(DRASearchableColumnList: string[] | null): void {
        this.gridWrapper
            .getReduxStore()
            .dispatch(SetDRASearchableColumnList(DRASearchableColumnList));
    }
    public getDraSearchableColumnList(): string[] | null {
        return this.gridWrapper.getReduxStore().getState().searchable.DRASearchableColumnList;
    }

    // NotificationPlugin
    public getAllNotifications(): NotificationItem[] {
        return this.gridWrapper.getReduxStore().getState().notification.notificationList;
    }
    public addNotification(notificationItem: NotificationItem): void {
        this.gridWrapper.getReduxStore().dispatch(AddNotification(notificationItem));
    }
    public removeNotification(notificationItem: NotificationItem): void {
        this.gridWrapper.getReduxStore().dispatch(RemoveNotification(notificationItem));
    }
    public removeAllNotifications(): void {
        this.gridWrapper.getReduxStore().dispatch(RemoveAllNotification());
    }
    public removeAllDismissibleNotifications(): void {
        this.gridWrapper.getReduxStore().dispatch(RemoveAllDismissibleNotification());
    }
    // TODO UX-14416 - Should accept undefined type as well
    // ZebraStripesPlugin
    public setRowStripeInterval(stripeInterval: number): void {
        this.gridWrapper.getReduxStore().dispatch(SetStripeInterval(stripeInterval));
    }
    public getRowStripeInterval(): number {
        // TODO UX-14416 - Should return undefined as well
        return this.gridWrapper.getReduxStore().getState().zebraStripes.stripeInterval || 0;
    }
    public setRowStripeBackgroundColours(
        stripeBackgroundColour: string | undefined,
        nonStripeBackgroundColour: string | undefined
    ): void {
        this.gridWrapper
            .getReduxStore()
            .dispatch(
                SetZebraStripesBackgroundColours(stripeBackgroundColour, nonStripeBackgroundColour)
            );
    }

    private handleStateChange(): void {
        this.datagridStateChanged.dispatchAsync(this.getState());
        Object.entries(Plugins).forEach(entry => {
            const plugin = enumHelper.stringToStringEnum<Plugins>(entry[1], Plugins);
            if (plugin) {
                const oldState = this.stateMap.get(plugin);
                const newSate = this.getSubState(plugin);
                if (oldState !== newSate) {
                    switch (plugin) {
                        case Plugins.AutofitPivotPlugin:
                            this.autoFitPivotChanged.dispatchAsync(newSate);
                            break;
                        case Plugins.ColumnHintPlugin:
                            this.columnCustomisationsChanged.dispatchAsync(newSate);
                            break;
                        case Plugins.CrossModelPlugin:
                            this.crossModelsChanged.dispatchAsync(newSate);
                            break;
                        case Plugins.CustomSortPlugin:
                            this.customSortPluginChanged.dispatchAsync(newSate);
                            break;
                        case Plugins.KeyboardShortcutPlugin:
                            this.keyboardShortcutsChanged.dispatchAsync(newSate);
                            break;
                        case Plugins.RowCustomisationPlugin:
                            this.rowCustomisationsChanged.dispatchAsync(newSate);
                            break;
                        case Plugins.SavedViewPlugin:
                            this.savedViewsChanged.dispatchAsync(newSate);
                            break;
                        case Plugins.MaskedColumnPlugin:
                            this.maskedColumnsChanged.dispatchAsync(newSate);
                            break;
                        case Plugins.QuickFilterPlugin:
                            this.quickFiltersChanged.dispatchAsync(newSate);
                            break;
                        case Plugins.SearchablePlugin:
                            this.searchChanged.dispatchAsync(newSate);
                            break;
                        case Plugins.NotificationPlugin:
                            this.notificationsChanged.dispatchAsync(newSate);
                            break;
                        case Plugins.ZebraStripesPlugin:
                            this.rowStripesChanged.dispatchAsync(newSate);
                            break;
                    }
                    this.stateMap.set(plugin, newSate);
                }
            }
        });
    }

    private getSubState(plugin: Plugins): any {
        switch (plugin) {
            case Plugins.AutofitPivotPlugin:
                return this.isAutoFitPivotEnabled();
            case Plugins.ColumnHintPlugin:
                return this.getColumnCustomisationList();
            case Plugins.CrossModelPlugin:
                return this.getCrossModelState();
            case Plugins.CustomSortPlugin:
                return this.getCustomSortList();
            case Plugins.KeyboardShortcutPlugin:
                return this.getKeyboardShortcutList();
            case Plugins.RowCustomisationPlugin:
                return this.getRowCustomisationList();
            case Plugins.SavedViewPlugin:
                return this.getSavedViewsState();
            case Plugins.MaskedColumnPlugin:
                return this.getColumnMaskingList();
            case Plugins.QuickFilterPlugin:
                return this.getQuickFilterList();
            case Plugins.SearchablePlugin:
                return this.getSearchValue();
            case Plugins.NotificationPlugin:
                return this.getAllNotifications();
            case Plugins.ZebraStripesPlugin:
                return this.getRowStripeInterval();
        }
    }

    public getConfigPanel(): JSX.Element {
        const GridConfiguration = GridConfigurationModalContent(
            this.gridWrapper.getPlugins(),
            this.gridWrapper.getTheme()
        );
        return (
            <Provider store={this.gridWrapper.getReduxStore()}>
                <GridConfiguration />
            </Provider>
        );
    }
}
