import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { getOverflowStyles } from '@gs-ux-uitoolkit-common/shared';
import { TextCommonProps, defaultTextProps } from './text-props';
import { getBrowserResetStyles, getFontStyles, getTextColor } from './typography-common-util';
import { DeepReadonly } from 'ts-essentials';
import './text-theme-overrides';

export type TextStyleSheetProps = Pick<
    TextCommonProps,
    | 'typography'
    | 'color'
    | 'fontFamily'
    | 'fontSize'
    | 'fontWeight'
    | 'lineHeight'
    | 'display'
    | 'bold'
    | 'italic'
    | 'underline'
    | 'disabled'
> & { theme: Theme };

export interface TextCssClasses {
    root: string;
}

export type TextStyledClasses = CssClassDefinitionsObject<keyof TextCssClasses>;

export interface TextStyleOverridesParams {
    props: DeepReadonly<TextStyleSheetProps>;
    createDefaultStyledClasses: () => TextStyledClasses;
}

export const textStyleSheet = new StyleSheet('text', (props: TextStyleSheetProps) => {
    return createComponentClassDefinitions<TextStyleSheetProps, TextStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.text
    );
});

function createDefaultStyledClasses({
    theme,
    typography = defaultTextProps.typography,
    color,
    fontFamily,
    fontSize,
    fontWeight,
    lineHeight,
    display = defaultTextProps.display,
    bold,
    italic,
    underline,
    disabled,
}: TextStyleSheetProps): TextStyledClasses {
    const fontStyles = getFontStyles({ theme, typography, bold, italic });
    return {
        root: {
            ...getBrowserResetStyles(),
            display,
            ...getOverflowStyles('wrap'),
            ...fontStyles,
            textDecoration: underline ? 'underline' : undefined,
            color: color ? getTextColor({ theme, color }) : 'inherit',
            fontWeight: bold ? 'bold' : fontWeight || fontStyles.fontWeight,
            fontStyle: italic ? 'italic' : 'inherit',
            opacity: disabled ? theme.state.disabledOpacity : 1,
            ...(fontFamily && { fontFamily }),
            ...(fontSize && { fontSize }),
            ...(lineHeight && { lineHeight }),
        },
    };
}
