import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { zIndex, elevationVariants, Elevation } from '@gs-ux-uitoolkit-common/design-system';
import { MenuSize } from './menu-props';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './menu-theme-overrides';

export interface MenuStyleSheetProps {
    theme: Theme;
    size: MenuSize;
    visible: boolean;
    disabled: boolean;
}

export interface MenuCssClasses {
    root: string;
    collapseMenu: string;
    optionsContainer: string;
}

export type MenuStyledClasses = CssClassDefinitionsObject<keyof MenuCssClasses>;

export interface MenuStyleOverridesParams {
    props: DeepReadonly<MenuStyleSheetProps>;
    createDefaultStyledClasses: () => MenuStyledClasses;
}

export const menuStyleSheet = new StyleSheet('menu', (props: MenuStyleSheetProps) => {
    return createComponentClassDefinitions<MenuStyleSheetProps, MenuStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.menu
    );
});

const menuElevation: Elevation = '05';

function createDefaultStyledClasses({
    theme,
    size,
    visible,
    disabled,
}: MenuStyleSheetProps): MenuStyledClasses {
    let minHeight = '32px';
    let maxWidth = '248px';
    let minWidth = '190px';
    if (size === 'sm') {
        minHeight = '28px';
        maxWidth = '180px';
        minWidth = '130px';
    } else if (size === 'lg') {
        minHeight = '42px';
        maxWidth = '260px';
        minWidth = '200px';
    }
    return {
        root: {
            backgroundColor: theme.surface.primary,
            background: theme.elevation[menuElevation].background,
            borderRadius: '2px',
            ...elevationVariants['05'],
            display: visible ? 'block' : 'none',
            position: 'absolute',
            left: 0,
            top: 0,
            maxHeight: '320px',
            minHeight,
            maxWidth,
            minWidth,
            overflowX: 'hidden',
            overflowY: 'auto',
            zIndex: zIndex.menu,
            opacity: disabled ? theme.state.disabledOpacity : 1.0,
        },
        collapseMenu: {
            display: visible ? 'block' : 'none',
            '&:focus': {
                outline: 'none',
            },
        },
        optionsContainer: {
            padding: '4px 0px',
        },
    };
}
