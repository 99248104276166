import { rgba } from 'polished';
import { CssProperties } from '@gs-ux-uitoolkit-common/style';
import { getIconStyles, getSpinStyles } from '@gs-ux-uitoolkit-common/icon-font';
import {
    elevationVariants,
    TypographyVariant,
    fontWeight,
    zIndex,
} from '@gs-ux-uitoolkit-common/design-system';

import { SelectCommonConfig, SelectSize } from '../options';
import { ChoicesConfig } from '../select';
import { selectMultipleDefaultProps, SelectPlacement } from '../select-props';
import { Theme } from '@gs-ux-uitoolkit-common/theme';

/**
 * The CSS class names used by the component.
 */
export type SelectClassNames = NonNullable<ChoicesConfig['classNames']>;

/**
 * Determines if the component is in currently set to `small`
 *
 * @param size The current size of the component.
 */
export function isSmallSize(size: SelectCommonConfig['size']) {
    return size === 'sm';
}

/**
 * Determines if the component is in currently set to `large`
 *
 * @param size The current size of the component.
 */
export function isLargeSize(size: SelectCommonConfig['size']) {
    return size === 'lg';
}

export function getIconSpinStyles(status: SelectCommonConfig['status']) {
    if (status === 'loading') {
        return getSpinStyles();
    } else {
        return {};
    }
}

export function getIconPropsByStatus(
    status: SelectCommonConfig['status'],
    size: SelectCommonConfig['size']
) {
    const props: any = {};
    switch (status) {
        case 'success':
            props.name = 'check-circle';
            break;
        case 'warning':
            props.name = 'warning';
            break;
        case 'error':
            props.name = 'error';
            break;
        case 'loading':
            props.name = 'loading';
            break;
        default:
            return null;
    }

    if (size === 'sm') {
        props.size = '14px';
        props.right = '25px';
    } else if (size === 'lg') {
        props.size = '20px';
        props.right = '40px';
    } else {
        props.size = '18px';
        props.right = '30px';
    }

    return props;
}

/**
 * Interface for the different customize the root styles options.
 */
interface SelectRootStylesOptions extends CommonSelectStylesOptions {
    /**
     * The size of the icon.
     */
    iconFontSize: string;

    /**
     * The height of the component.
     */
    selectHeight: string;

    /**
     * The CSS class names used by the component
     */
    cssClassNames: SelectClassNames;

    /**
     * Status of the select validation, if any
     */
    status: SelectCommonConfig['status'];

    /**
     * The corresponding theme passed to the component
     */
    theme: Theme;
}

function getStatusColor(
    theme: Theme,
    status: SelectCommonConfig['status']
): CssProperties['color'] {
    if (status && status !== 'none') {
        let statusColor = theme.status[status].bold;
        if (status === 'warning') {
            statusColor = theme.status.warningAlt.bold;
        }
        return statusColor;
    } else {
        return theme.text.secondary;
    }
}
/**
 * Returns the appropriate styles for the outermost element in the component.
 *
 * @param param0 An object containing options to customize the root styles.
 */
export function getRootStyles(option: SelectRootStylesOptions): CssProperties {
    const { selectHeight, isSingleSelect, cssClassNames, status, theme } = option;
    const statusIcon = getIconPropsByStatus(status, 'md');
    return {
        alignItems: 'center',
        cursor: 'pointer',
        minHeight: selectHeight,
        position: 'relative',

        '&:hover': {
            [`.${cssClassNames.containerInner}`]: {
                borderColor: theme.border.bold,
            },
        },
        '&:focus': {
            outline: 'none',
        },
        '&::before': statusIcon
            ? {
                  color: statusIcon.color,
              }
            : {},
        [`&.${cssClassNames.openState}`]: {
            transform: 'translate(0, 0)',
            zIndex: zIndex.dropdown,
            [`.${cssClassNames.containerInner}`]: {
                minHeight: selectHeight,
                borderRadius: '2px',
            },
        },

        [`&.${cssClassNames.disabledState}`]: {
            cursor: 'default',
            opacity: theme.state.disabledOpacity,
            '*': {
                cursor: 'default',
            },
            [`.${cssClassNames.input}`]: {
                background: 'initial',
            },

            ...(isSingleSelect
                ? {
                      [`.${cssClassNames.button}`]: {
                          display: 'none',
                      },
                  }
                : {
                      [`.${cssClassNames.button}`]: {
                          '&::after': {
                              color: rgba(
                                  theme.getColorInteractionShades('information', 'subtle')
                                      .text as string,
                                  0.6
                              ),
                          },
                      },
                  }),
        },

        [`&.${cssClassNames.flippedState}`]: {
            [`.${cssClassNames.listDropdown}`]: {
                marginTop: '0',
                marginBottom: '-1px',
                [`&.${cssClassNames.activeState}`]: {
                    top: 'auto',
                    borderRadius: '0',
                },
            },
        },
    };
}

/**
 * Returns the appropriate styles for the "single list" element.
 *
 * @param size The current size of the component
 */
export function getSelectListSingleStyles(
    size: SelectCommonConfig['size'],
    cssClassNames: SelectClassNames
): CssProperties {
    let paddingVariant = '5px 0 5px 8px';

    if (isSmallSize(size)) {
        paddingVariant = '3px 0 3px 8px';
    }

    if (isLargeSize(size)) {
        paddingVariant = '7px 0 7px 8px';
    }

    return {
        padding: paddingVariant,

        // Remove the "removeButton" for placeholders
        [`.${cssClassNames.placeholder} > .${cssClassNames.button}`]: {
            display: 'none',
        },
        [`.${cssClassNames.itemSelectable}`]: {
            padding: '0px',
        },
    };
}

/**
 * This returns the styling for the gs-uitk-multiple__list element which holds the items
 * selected.  Importantly, we inspect the number of items in this list and if empty, show the
 * <input>'s placeholder; else hide it.
 * @param cssClassNames the classNames used by Choices
 */
export function getSelectListStyles(cssClassNames: SelectClassNames, theme: Theme): CssProperties {
    return {
        [`.${cssClassNames.list}`]: {
            display: 'inline',
            [`+.${cssClassNames.input}`]: {
                '&::-webkit-input-placeholder': {
                    opacity: 0,
                },
            },
            '&:empty': {
                [`+.${cssClassNames.input}`]: {
                    '&::-webkit-input-placeholder': {
                        opacity: 1,
                        ...placeholderStyles(theme),
                    },
                },
            },
        },
    };
}

/**
 * Interface for the different options to customize the menu styles.
 */
interface SelectMenuStylesOptions extends CommonSelectStylesOptions {
    /**
     * The font size of the icon.
     */
    iconFontSize?: string;

    /**
     * The current height of the component.
     */
    selectHeight?: string;

    /**
     * The CSS Classes used by the component
     */
    cssClassNames: SelectClassNames;

    /**
     * Where the dropdown menu should open
     */
    menuPlacement?: SelectPlacement;
    /**
     * The corresponding theme passed to the component
     */
    theme: Theme;
}

/**
 * Returns the appropriate menu styles based on the component props.
 *
 * @param0 An object containing options to customize the menu styles.
 */
export function getMenuStyles(options: SelectMenuStylesOptions): CssProperties {
    const { searchable, selectHeight, isSingleSelect, iconFontSize, size, cssClassNames, theme } =
        options;

    let isActiveCssProperties: CssProperties;
    let selectTopOffset = 1;

    if (!searchable && selectHeight) {
        selectTopOffset = selectTopOffset + parseInt(selectHeight, 10);
    }

    if (isSingleSelect && iconFontSize && searchable && size) {
        isActiveCssProperties = {
            top: `${selectTopOffset}px`,
        };
    } else {
        isActiveCssProperties = {
            top: '100%',
            marginTop: 0,
        };
    }

    return {
        top: 'auto',
        display: 'none',
        position: 'absolute',
        width: '100%',
        backgroundColor: theme.surface.primary,
        marginTop: '-1px',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        overflow: 'hidden',
        wordBreak: 'break-all',

        [`&.${cssClassNames.activeState}`]: {
            display: 'block',
            ...isActiveCssProperties,
            [`&:not(.${cssClassNames.item})`]: {
                '&:first-child': {
                    marginTop: '4px',
                },
                '&:last-child': {
                    marginBottom: '4px',
                },
            },
        },
        [`.${cssClassNames.placeholder}`]: {
            display: 'none',
        },
    };
}

/**
 * Size variant to be used for chevron up and chevron down icons
 */
const chevronIconSize = {
    sm: {
        iconSize: '16px',
    },
    md: {
        iconSize: '20px',
    },
    lg: {
        iconSize: '24px',
    },
};

/**
 * Generates the appropriate styles for an "Chevron down" icon. This icon is usually rendered at the
 *   right end of the input box.
 *
 * @param searchable Flag used to determine if the search styling should be applied.
 */
export function getSelectChevronDownStyles(
    size: SelectCommonConfig['size'] = 'md',
    theme: Theme
): CssProperties {
    let properties: CssProperties = {
        top: '50%',
        margin: '0 4px 0 0',
    };
    const isLargeSizeSelect = isLargeSize(size);
    if (isLargeSizeSelect) {
        properties = {
            ...properties,
            margin: '0',
            padding: '8px',
        };
    }

    return {
        ...getIconStyles({ name: 'keyboard-arrow-down', type: 'filled' }),
        ...properties,
        position: 'absolute',
        fontSize: chevronIconSize[size].iconSize,
        color: theme.text.secondary,
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
    };
}
/**
 * Interface for the different customizable options as part of the remove button styles.
 */
interface SelectRemoveButtonStylesOptions extends Omit<CommonSelectStylesOptions, 'searchable'> {
    /**
     * A value to offset the remove button from the right
     */
    rightOffset: string | number;

    /**
     * If a status is set on the select
     */
    status?: SelectCommonConfig['status'];
}

/**
 * Interface for the different customizable options as part of the clear all button styles.
 */
interface SelectClearAllButtonStylesOptions {
    /**
     * If a status is set on the select
     */
    status?: SelectCommonConfig['status'];

    /**
     * The current size of the component
     */
    size: SelectCommonConfig['size'];

    /**
     * The corresponding theme passed to the component
     */
    theme: Theme;
}

/**
 * Returns the appropriate styles for the remove button element.
 *
 * @param param0 An object containing options to customize the remove button styles.
 */
export function getRemoveButtonStyles(options: SelectRemoveButtonStylesOptions): CssProperties {
    let { rightOffset } = options;
    const {
        size = selectMultipleDefaultProps.size,
        isSingleSelect,
        isMultipleSelectClearable,
        status,
    } = options;

    if (isSingleSelect) {
        if (isSmallSize(size)) {
            rightOffset = '5px';
        }

        if (isLargeSize(size)) {
            rightOffset = '9px';
        }
    }

    if (isMultipleSelectClearable) {
        if (isSmallSize(size)) {
            rightOffset = '6px';
        }

        if (isLargeSize(size)) {
            rightOffset = '8px';
        }
    }

    const checkboxSizeVariants = {
        sm: {
            multipleSelectTop: '2px',
            multipleSelectButtonOffset: '5px',
            singleSelectButtonOffset: '20px',
            multipleButtonClearableOffset: '45px',
        },
        md: {
            multipleSelectTop: '4px',
            multipleSelectButtonOffset: '20px',
            singleSelectButtonOffset: '27px',
            multipleButtonClearableOffset: '54px',
        },
        lg: {
            multipleSelectTop: '6px',
            multipleSelectButtonOffset: '30px',
            singleSelectButtonOffset: '28px',
            multipleButtonClearableOffset: '74px',
        },
    };
    return {
        backgroundColor: 'transparent',
        border: 0,
        display: 'flex',
        color: 'transparent',
        cursor: 'pointer',
        lineHeight: 2,
        padding: 0,
        position: 'absolute',
        right:
            status && status !== 'none'
                ? isSingleSelect
                    ? checkboxSizeVariants[size || 'md'].singleSelectButtonOffset
                    : checkboxSizeVariants[size || 'md'].multipleSelectButtonOffset
                : // ? isMultipleSelectClearable
                  //     ? checkboxSizeVariants[size || selectMultipleDefaultProps.size]
                  //           .multipleButtonClearableOffset
                  //     : checkboxSizeVariants[size || selectMultipleDefaultProps.size].buttonOffset
                  rightOffset,
        top: isSingleSelect ? '1px' : checkboxSizeVariants[size].multipleSelectTop,
        whiteSpace: 'nowrap',

        // This prevents an issue when a pill's label is shorter than the hidden
        // remove button's text (usually "Remove Item") and the remove button
        // overlays invisibly over the previous pill/button. This results in the
        // user clicking an invisible button instead of the button it appears
        // they are clicking, and thus removing the wrong pill.
        maxWidth: '24px',
        overflow: 'hidden',
        margin: 0,
    };
}

/**
 * Returns the appropriate styles for the clear all button element.
 *
 * @param param0 An object containing options to customize the clear all button styles.
 */
export function getClearAllButtonStyles(options: SelectClearAllButtonStylesOptions): CssProperties {
    const { size = 'md', status } = options;

    const clearAllButtonSizeVariants = {
        sm: {
            multipleSelectClearableTop: '-2%',
            rightOffsetWithStatus: '48px',
            rightOffset: '34px',
        },
        md: {
            multipleSelectClearableTop: '0%',
            rightOffsetWithStatus: '56px',
            rightOffset: '37px',
        },
        lg: {
            multipleSelectClearableTop: '0%',
            rightOffsetWithStatus: '70px',
            rightOffset: '51px',
        },
    };
    return {
        backgroundColor: 'transparent',
        border: 0,
        cursor: 'pointer',
        padding: 0,
        position: 'absolute',
        whiteSpace: 'nowrap',
        right:
            status && status !== 'none'
                ? clearAllButtonSizeVariants[size || 'md'].rightOffsetWithStatus
                : clearAllButtonSizeVariants[size || 'md'].rightOffset,
        top: clearAllButtonSizeVariants[size].multipleSelectClearableTop,
        bottom: 0,
    };
}

/**
 * Interface for the different customizable options as part of the chevron up styles.
 */
interface SelectChevronUpStylesOptions extends CommonSelectStylesOptions {
    /**
     * The font size of the icon.
     */
    menuPlacement?: SelectPlacement;
    theme: Theme;
}

/**
 * Generates the appropriate styles for an "Chevron up" icon. This icon is usually rendered at the
 *   right end of the input box.
 *
 * @param param0 Interface for the different customizable options as part of the up icon styles.
 */
export function getSelectChevronUpIconStyles(options: SelectChevronUpStylesOptions): CssProperties {
    const { isSingleSelect, size = 'md', menuPlacement, theme } = options;

    const isLargeSizeSelect = isLargeSize(size);
    let properties: CssProperties = {
        top: '50%',
        margin: '0 4px 0 0',
        transform: 'translateY(-50%)',
        right: '0',
    };

    if (isSingleSelect) {
        properties = {
            ...properties,
            top: '5px',
            transform: 'none',
        };
        if (isSmallSize(size)) {
            properties = {
                ...properties,
                top: '0',
                margin: '4px 4px 4px 0',
            };
        }
        if (isLargeSizeSelect) {
            properties = {
                ...properties,
                top: '0',
            };
        }
    }
    if (isLargeSizeSelect) {
        properties = {
            ...properties,
            margin: '0',
            padding: '8px',
        };
    }

    //common styles adjusted for the chevronUp icon when menuPlacement = top
    if (menuPlacement === 'top') {
        properties = {
            ...properties,
            bottom: '7px',
            top: '',
        };
        if (isLargeSizeSelect || isSmallSize(size)) {
            properties = {
                ...properties,
                bottom: '1px',
                top: '',
            };
        }
    }

    return {
        ...getIconStyles({ name: 'keyboard-arrow-up', type: 'filled' }),
        ...properties,
        color: theme.text.primary,
        fontSize: chevronIconSize[size].iconSize,
        position: 'absolute',
    };
}

function getCommonContainerInnerBorderColor(status: SelectCommonConfig['status'], theme: Theme) {
    if (!status || status === 'none') {
        return theme.border.input;
    }

    let statusColor = theme.status[status || 'none'].bold;
    if (status === 'warning') {
        statusColor = theme.status.warningAlt.bold;
    }
    return statusColor;
}

/**
 * Styles for the "inner container" elements
 *
 * @param selectHeight The height of the component
 */
export function commonContainerInnerStyles(
    selectHeight: string,
    lineHeight: string = selectHeight,
    status: SelectCommonConfig['status'] = 'none',
    theme: Theme
): CssProperties {
    return {
        backgroundColor: theme.surface.primary,
        border: `1px solid ${getCommonContainerInnerBorderColor(status, theme)}`,
        borderRadius: '2px',
        boxSizing: 'border-box',
        display: 'inline-block',
        fontSize: '14px',
        minHeight: selectHeight,
        lineHeight,
        overflow: 'hidden',
        verticalAlign: 'top',
        width: '100%',
    };
}

/**
 * Styles for a disabled option
 */
export function disabledMenuOptionStyles(theme: Theme): CssProperties {
    return {
        cursor: 'not-allowed',
        userSelect: 'none',
        opacity: theme.state.disabledOpacity,
    };
}

/**
 * Styles for a normal menu option
 */
export function menuOptionStyles(theme: Theme): CssProperties {
    return {
        color: theme.text.primary,
        verticalAlign: 'inherit',
        padding: '6px 0 6px 16px',
    };
}

/**
 * Styles for the group heading
 */
export function groupHeadingStyles(theme: Theme): CssProperties {
    return {
        color: theme.text.secondary,
        letterSpacing: '0.4px',
        marginTop: '8px',
        padding: '8px 0  8px 16px',
        textTransform: 'uppercase',
    };
}

/**
 * Styles for a selectable option
 */
export function selectableOptionStyles(
    cssClassNames: SelectClassNames,
    theme: Theme
): CssProperties {
    const interactionColor: string = theme.increaseContrast(
        theme.colorScheme.secondary,
        1
    ) as string;
    return {
        paddingRight: 0,
        position: 'relative',
        cursor: 'pointer',
        [`&.${cssClassNames.highlightedState}`]: {
            backgroundColor: rgba(interactionColor, 0.4),
        },
        [`&.${cssClassNames.selectedState}`]: {
            backgroundColor: rgba(theme.colorScheme.primary as string, 0.2),
            fontWeight: fontWeight.medium,
        },
        '&:after': {
            display: 'none',
        },
    };
}

/**
 * Styles for the list element
 */
export function listStyles(cssClassNames: SelectClassNames, theme: Theme): CssProperties {
    return {
        [`&.${cssClassNames.activeState}`]: {
            'div[role="listbox"]': {
                overflow: 'auto',
                //setting z-index to negative so it's boxShadow won't cover neighboring elements
                zIndex: -1,
                position: 'fixed',
                minWidth: '200px',
                width: 'inherit',
                backgroundColor: theme.elevation['05'].background,
                borderColor: 'transparent',
                borderRadius: '0.125rem',
                borderWidth: '0',
                boxShadow: elevationVariants['05'].boxShadow,
                '::-webkit-scrollbar-track:hover': {
                    background: 'none',
                },
                '::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.surface.moderate,
                    border: 'none',
                },
            },
        },
    };
}

/**
 * Styles for the placeholder
 */
export function placeholderStyles(theme: Theme): CssProperties {
    return {
        color: theme.text.tertiary,
        padding: 0,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    };
}

/**
 * Styles for the main input element
 *
 * @param padding the padding to use for the input
 */
export function baseInputStyles(
    padding: string,
    size: SelectSize,
    typographyVariant: TypographyVariant,
    theme: Theme
): CssProperties {
    if (isSmallSize(size)) {
        padding = '3px 0 3px 8px';
    }

    return {
        ...typographyVariant,
        boxSizing: 'border-box',
        marginBottom: '0',
        padding,
        color: theme.text.primary,
    };
}

/**
 * The Styles for the input cloned element.
 *
 * @param typographyVariant The current Typography variant
 */
export function getInputClonedStyles(
    typographyVariant: TypographyVariant,
    theme: Theme
): CssProperties {
    return {
        backgroundColor: theme.surface.primary,
        border: `1px solid ${theme.colorScheme.primary}`,
        outline: 'none',
        fontSize: typographyVariant.fontSize,
        margin: 0,
        maxWidth: '100%',
        padding: '3px 0 4px 8px',
        verticalAlign: 'baseline',
        width: '100%',
    };
}

/**
 * Contains common options that are used to customize the styles.
 */
interface CommonSelectStylesOptions {
    /**
     * Flag to determine if the styles are for the Select or the SelectMultiple component.
     */
    isSingleSelect: boolean;

    /**
     * Flag to determine if the styles are for SelectMultiple with clearable set to true
     */
    isMultipleSelectClearable?: boolean;

    /**
     * Flag to determine if the component is searchable ot not (only applicable to Select)
     */
    searchable: boolean;

    /**
     * The current size of the component
     */
    size: SelectCommonConfig['size'];
}

/**
 * Remove icon size variant
 */
export const removeIconSizeVariant = {
    sm: {
        fontSize: '14px',
    },
    md: {
        fontSize: '18px',
    },
    lg: {
        fontSize: '20px',
    },
};

/**
 * Returns the appropriate styles for the status icons
 */
export function getStatusIconStyles(options: SelectClearAllButtonStylesOptions): CssProperties {
    const { size = 'md', status, theme } = options;
    const topSize = {
        sm: {
            topSize: '5px',
        },
        md: {
            topSize: '7px',
        },
        lg: {
            topSize: '11px',
        },
    };

    const statusIcon = getIconPropsByStatus(status, size);
    return {
        ...getIconStyles({ name: statusIcon.name, type: 'filled' }),
        float: 'right',
        color: getStatusColor(theme, status),
        fontSize: statusIcon.size,
        position: 'absolute',
        right: statusIcon.right,
        top: topSize[size].topSize,
        // transform rotate hack below - circular icons such as status are
        // blurry on Windows:  https://github.com/google/material-design-icons/issues/648
        transform: 'rotate(0.03deg)',
    };
}
