import { useCallback } from 'react';
import useFetch from '../useFetch';
import useUserMaintenance from '../../context';
import useSnackBar from '../useSnackbar';
import useLoader from '../useLoader';
import endPointsMapper from '../../../../../configs/endPointsMapper';
import translator from '../../../../../services/translator';

const { translate: t } = translator;

export default function useOrganizationFunds(fgSuccessCb) {
  const {
    state: {
      organizationDetails: { organizationName },
    },
  } = useUserMaintenance();
  const { openSnackbar } = useSnackBar();

  const onFundGroupsFetchError = useCallback(() => {
    openSnackbar({ type: 'error', message: t('tkFundMappingErrorMsg', organizationName) });
  }, []);

  const fundGroupsEndpoint = structuredClone(endPointsMapper.FETCH_FUND_GROUPS);

  const onAutoCompleteError = useCallback(() => {
    openSnackbar({ type: 'error', message: t('tkFundSearchErrorMsg') });
  }, []);

  const {
    loading: autoCompleteLoading,
    doFetch: fetchAutoCompleteData,
    data: autoComplete,
  } = useFetch(endPointsMapper.FETCH_USER_SEARCH, undefined, onAutoCompleteError);

  const { loading, doFetch, data: fundGroupsData } = useFetch(fundGroupsEndpoint, fgSuccessCb, onFundGroupsFetchError);

  async function fetchFundGroupFunds(fundGroupId) {
    fundGroupsEndpoint.path = `${fundGroupsEndpoint.path}/${fundGroupId}`;
    await doFetch();
  }

  useLoader(loading);

  return { autoCompleteLoading, fetchAutoCompleteData, autoComplete, fetchFundGroupFunds, fundGroupsData };
}
