import { Choices } from '@gs-ux-uitoolkit-common/choices';
import { nonNullableValueOrThrow } from '@gs-ux-uitoolkit-common/shared';
import { NormalizedSelectMultipleConfig } from './options/select-multiple-options';
import {
    ChoicesOptionRenderer,
    isSelectOptionLeaf,
    NormalizedSelectConfig,
    SelectOption,
    SelectOptionLeaf,
} from './options/select-options';
import { ChoicesConfig } from './select';
import { SelectRenderCache } from './select-render-cache';
import {
    ConvertStringToElementFn,
    convertStringToOptionElement,
    convertStringToSelectedOptionElement,
    EMPTY_OPTIONS,
    EMPTY_OPTIONS_MAP,
    generateCommonChoicesConfig,
    labelOptionRenderer,
    renderOption,
} from './utils';

/**
 * Base class for Select and SelectMultiple to share common functionality.
 */
export class AbstractSelect<
    NormalizedSelectConfigType extends NormalizedSelectConfig | NormalizedSelectMultipleConfig,
> {
    /**
     * The HTML element that choices.js attaches it self to.
     */
    protected selectEl: HTMLSelectElement;

    /**
     * The outermost HTML Element for the Select component.
     */
    protected selectOuterContainer: HTMLDivElement;

    /**
     * Props passed down from the client's component.
     */
    protected props!: NormalizedSelectConfigType;

    /**
     * In order to use the {@link #renderCache}, we need to map option
     * values -> SelectOptionLeaf objects.
     *
     * This is needed because the `optionRenderer` is not provided the original
     * SelectOptionLeaf object when it is called, but rather is provided some
     * "data" object with extended properties of the SelectOptionLeaf. So we map
     * the `value` back to the original `Option` object in order to use the
     * `SelectOptionLeaf` object as the {@link #renderCache} key.
     *
     * Note: this Map is only built if the `enableRenderCache` option is set to
     * `true`, and there are no duplicate `value` properties in the options
     * array. It will be empty otherwise.
     */
    protected optionsMap: Map<string, SelectOptionLeaf> = EMPTY_OPTIONS_MAP;

    /**
     * Unfortunately choices.js probably has a bug and start rerender loop second time
     * after we changed options and rebuilt options map. That's why we need keep
     * previous version of options map.
     */
    protected previousOptionsMap = EMPTY_OPTIONS_MAP;

    /**
     * The cache for the optionRenderer to not be called for the same Option
     * objects, thus improving re-render and selection performance.
     */
    protected readonly renderCache = new SelectRenderCache();

    /**
     * Flag to determine if we should use the render cache. This will only be
     * `true` if the app's config has set `enableRenderCache` to `true`, and when
     * we know that there are no duplicate `values` in the options.
     */
    protected enableRenderCache = false;

    constructor(selectContainerEl: HTMLDivElement, selector: string) {
        this.selectOuterContainer = selectContainerEl;

        this.selectEl = nonNullableValueOrThrow(
            selectContainerEl.querySelector<HTMLSelectElement>(selector),
            `CommonSelectComponent.constructor(): Unable to find the inner "select" element, ` +
                `please ensure the first argument of this component is the outermost element containing a '<select>' element`
        );
    }

    /**
     * Setup tasks to capture events and config from wrapper component
     */
    protected setup(config: NormalizedSelectConfigType) {
        // Store the initial optionsMap and either enable/disable render caching
        // before instantiating Choices itself so that the cached optionsRenderer
        // applies
        if (config.enableRenderCache) {
            this.buildOptionsMap(config.options || []);
        } else {
            this.optionsMap = EMPTY_OPTIONS_MAP;
            this.enableRenderCache = false;
        }
    }

    /**
     * Updates the options and selected options
     */
    protected async updateSelectConfig(
        config: NormalizedSelectConfigType,
        prevConfig?: NormalizedSelectConfigType
    ) {
        const { options = EMPTY_OPTIONS } = config;

        if (prevConfig && options !== prevConfig.options) {
            if (config.enableRenderCache) {
                this.previousOptionsMap = this.optionsMap;
                this.buildOptionsMap(options);
            } else {
                this.optionsMap = EMPTY_OPTIONS_MAP;
                this.enableRenderCache = false;
            }
        }
    }

    /**
     * Returns an object that maps components config to choicesJS config options
     * @param componentConfig - Properties from client's react component
     */
    protected generateChoicesConfig(componentConfig: NormalizedSelectConfigType): ChoicesConfig {
        const commonConfig = generateCommonChoicesConfig(componentConfig);

        // TODO: We must always assign the Choices renderer templates so that
        //       apps can provide an 'optionRenderer' or 'selectedOptionRenderer'
        //       after instantiation, even if there wasn't one initially. However,
        //       this currently breaks some of the tests with the 'remove' button
        //       and such so only assigning for initial config
        const { optionRenderer, selectedOptionRenderer } = componentConfig;
        if (optionRenderer || selectedOptionRenderer) {
            commonConfig.callbackOnCreateTemplates = () => {
                const templates: Partial<Choices.Templates> = {};

                if (optionRenderer) {
                    templates.choice = this.createCachedOptionRenderer(
                        'optionRenderer',
                        convertStringToOptionElement
                    );
                }
                if (selectedOptionRenderer) {
                    templates.item = this.createCachedOptionRenderer(
                        'selectedOptionRenderer',
                        convertStringToSelectedOptionElement
                    );
                }
                return templates;
            };
        }

        return commonConfig;
    }

    /**
     * Helper function to build the optionsMap (a map of value->SelectOptionLeaf)
     * for the given array of `options`.
     *
     * If a duplicate is detected, that duplicate value is returned with an empty
     * `optionsMap`.
     */
    private buildOptionsMap(options: SelectOption[]) {
        const optionsMap = new Map<string, SelectOptionLeaf>();
        let duplicateValue: string | undefined = undefined;

        for (const option of options) {
            if (isSelectOptionLeaf(option)) {
                const value = option.value;

                if (optionsMap.has(value)) {
                    // Found a duplicate
                    duplicateValue = value;
                    break;
                }
                optionsMap.set(value, option);
            }
        }

        const hasDuplicates = duplicateValue !== undefined;
        if (!hasDuplicates) {
            this.optionsMap = optionsMap;
            this.enableRenderCache = true;
        } else {
            this.optionsMap = EMPTY_OPTIONS_MAP;
            this.enableRenderCache = false;
            console.warn(
                `Select: 'enableRenderCache' was true but we detected duplicate Option values. ` +
                    `Cache will be disabled. Please let us know about your use case to have multiple ` +
                    `options with the same 'value' at https://ui.web.gs.com/support. The duplicate ` +
                    `value detected was '${duplicateValue}'`
            );
        }
    }

    /**
     * Creates a wrapper function of a SelectOptionRenderer function which handles
     * caching the results for the same input SelectOptionLeaf objects.
     *
     * @param rendererName The property name of the renderer. This is so that we can
     *   dynamically look up the renderer on each call. We need to do this because
     *   apps may switch the option renderer, but we declare this callback at Choices
     *   instantiation time.
     * @param stringToHtmlElementFn The function to convert a string return value from
     *   the renderer to an HTMLElement, which Choices expects as the return value.
     *   This is different for `optionRenderer` and `selectedOptionRenderer`.
     */
    private createCachedOptionRenderer(
        rendererName: 'optionRenderer' | 'selectedOptionRenderer',
        stringToHtmlElementFn: ConvertStringToElementFn
    ): ChoicesOptionRenderer {
        return (classNames, data) => {
            // Note: we must always look up the renderer in the props by name
            // here because the renderer prop (either optionRenderer or
            // selectedOptionRenderer) may have been swapped out with a new
            // function
            const renderFn = this.props[rendererName] || labelOptionRenderer; // if there is no renderer, just render a string from the label

            // Not using the render cache, or rendering just the placeholder, always re-render
            if (!this.enableRenderCache || data.placeholder) {
                return renderOption(
                    renderFn,
                    stringToHtmlElementFn,
                    data,
                    classNames,
                    rendererName
                );
            } else {
                // Choices.js has 2 re-render iterations and when options are change between those iterations (for ex. in onChangeHandler)
                // then second iteration has different options and options map so we need to keep old options map
                // which we use here as a backup.
                const selectOptionLeaf =
                    this.optionsMap.get(data.value) || this.previousOptionsMap.get(data.value)!; // the value should always exist in this map, otherwise we are not building it correctly
                const selected = !!data.selected;

                const cachedElement = this.renderCache.get(renderFn, selectOptionLeaf, selected);
                if (cachedElement) {
                    return cachedElement;
                }

                // Render the element if not in the cache
                const renderedElement = renderOption(
                    renderFn,
                    stringToHtmlElementFn,
                    data,
                    classNames,
                    rendererName
                );
                this.renderCache.set(renderFn, selectOptionLeaf, selected, renderedElement);
                return renderedElement;
            }
        };
    }
}
