import moment from "moment/moment";
import {DefaultChartConfig} from '../configs';
import {ChartActionTypes} from '../actions';
import {hasTopHoldingsShareclasses, filterShareclassData} from '../utils';

export const chartReducerInitialValue = {
  chartInstance: undefined,
  dropdown: {},
  chartOptions: {...DefaultChartConfig},
  allShareClassData: [],
  shareClassPerformanceData: [],
  asOfDate: moment(new Date()).format("YYYY-MM-DD"),
  primaryShareClassId: undefined,
  shareclass: undefined,
  loading: false,
  benchmarks: [],
  metrics: [],
  shareClassAndBenchmarkData: [],
  excelExportData: [],
  loadDataforMyTopHoldings: false
};

const asyncInitiated = [];


export const chartReducer = (state, action) => {
  const {type, data} = action;
  const newState = {...state};
  const {chartInstance} = state;
  console.log('Chart Reducer Action = ', action, ', State = ', state);

  switch (type) {
    case ChartActionTypes.registerChart: {
      const {chart} = data;
      newState.chartInstance = chart;
      return newState;
    }
    case ChartActionTypes.setInitShareClassId: {
      const {primaryShareClassId, benchmarks, selection, isLoadingTopOpeningFunds} = data;
      const { benchmarks: stateBenchmarks, primaryShareClassId: statePrimaryShareclassIds} = newState;
      let isTopHoldingPresentInState = hasTopHoldingsShareclasses(benchmarks, stateBenchmarks);
      isTopHoldingPresentInState = isTopHoldingPresentInState && primaryShareClassId === statePrimaryShareclassIds;

      newState.primaryShareClassId = primaryShareClassId;
      newState.benchmarks = benchmarks;
      if (selection) {
        newState.selection = selection;
      }
      newState.isLoadingTopOpeningFunds = isLoadingTopOpeningFunds;
      if (isTopHoldingPresentInState) {
        const {series, allShareClassData, shareClassPerformanceData} = filterShareclassData(newState, [{value: primaryShareClassId}, ...benchmarks]);
        const newChartOptions = {...newState.chartOptions};
        newState.chartOptions = newChartOptions;

        newState.chartOptions.series = series;
        newState.allShareClassData = allShareClassData;
        newState.shareClassPerformanceData = shareClassPerformanceData;
      } else {
        if (isLoadingTopOpeningFunds) {
          newState.loadDataforMyTopHoldings = true
        }
      }

      return newState;
    }
    case ChartActionTypes.setDefaultState: {
      const {chartInstance} = data;
      const {shareClassAndBenchmarkData, thresholdList} = newState;
      const resetState = {...chartReducerInitialValue, shareClassAndBenchmarkData, thresholdList};
      resetState.chartInstance = chartInstance;
      return resetState;
    }
    case ChartActionTypes.setShareClassAndBenchmarkData: {
      const {shareClassAndBenchmarkData} = data;
      newState.shareClassAndBenchmarkData = shareClassAndBenchmarkData;
      return newState;
    }
    case ChartActionTypes.setShareClassPerformanceData: {
      const {results, asOfDate} = data;
      newState.shareClassPerformanceData = results;
      newState.asOfDate = asOfDate;
      return newState;
    }
    case ChartActionTypes.initialLoad: {
      const {series, fieldLabel, id, shareClassDetails, asOfDate} = data;
      newState.chartOptions = {...state.chartOptions};
      newState.chartOptions.series = series;
      newState.chartOptions.yAxis = [
        {
          title: {
            text: fieldLabel
          },
          opposite: true,
          offset: 40
        }
      ];
      newState.primaryShareClassId = id;
      // Adding only primary shareclass details
      newState.shareclass = {
        ...shareClassDetails[0],
        ...{points: []}
      };
      newState.allShareClassData = shareClassDetails;
      newState.asOfDate = asOfDate;
      newState.shareClassPerformanceData = [];
      newState.isLoadingTopOpeningFunds = false;
      return newState;
    }
    case ChartActionTypes.updateShareClassList: {
      const {allShareClassData, replaceShareClassData} = data;
      if (!replaceShareClassData) {
        const existingShareClassData = [...state.allShareClassData];
        newState.allShareClassData = [
          ...existingShareClassData,
          ...allShareClassData
        ];
      } else {
        newState.allShareClassData = allShareClassData;
      }
      return newState;
    }
    case ChartActionTypes.updateChartOptions: {
      const {chartOptions} = data;
      newState.chartOptions = chartOptions;
      newState.loadDataforMyTopHoldings = false;
      return newState;
    }
    case ChartActionTypes.setFieldForQuery: {
      const {fieldForQuery} = data;
      newState.fieldForQuery = fieldForQuery;
      return newState;
    }
    case ChartActionTypes.setMetricBenchmarksToState: {
      return {...newState, ...data};
    }
    case ChartActionTypes.isMetricDataMissing: {
      const {isMetricDataMissing} = data;
      newState.isFundOrBenchmarkDataMissing = false;
      newState.isFundOrBenchmarkSeriesDataMissing = false;
      newState.isMetricDataMissing = isMetricDataMissing;
      return newState;
    }
    case ChartActionTypes.isFundOrBenchmarkDataMissing: {
      const {isFundOrBenchmarkDataMissing} = data;
      newState.isMetricDataMissing = false;
      newState.isFundOrBenchmarkSeriesDataMissing = false;
      newState.isFundOrBenchmarkDataMissing = isFundOrBenchmarkDataMissing;
      return newState;
    }
    case ChartActionTypes.isFundOrBenchmarkSeriesDataMissing: {
      const {isFundOrBenchmarkSeriesDataMissing} = data;
      newState.isMetricDataMissing = false;
      newState.isFundOrBenchmarkDataMissing = false;
      newState.isFundOrBenchmarkSeriesDataMissing = isFundOrBenchmarkSeriesDataMissing;
      return newState;
    }
    case ChartActionTypes.setThresholdList: {
      const {thresholdList} = data;
      newState.thresholdList = thresholdList;
      return newState;
    }
    case ChartActionTypes.setChartRangeOptions: {
      const {selectedZoomOption, selectedChartDateRange} = data;
      return {...newState, selectedZoomOption, selectedChartDateRange};
    }
    case ChartActionTypes.setExcelExportData: {
      const {excelExportData} = data;
      newState.excelExportData = excelExportData;
      return newState;
    }
    case ChartActionTypes.pending: {
      if (asyncInitiated.length === 0) {
        chartInstance && chartInstance.showLoading();
      }
      if (chartInstance) {
        asyncInitiated.push("pending");
      }
      newState.loading = true;
      return newState;
    }
    case ChartActionTypes.resolved:
    case ChartActionTypes.error: {
      if (chartInstance) {
        asyncInitiated.pop();
      }
      if (asyncInitiated.length === 0) {
        chartInstance && chartInstance.hideLoading();
      }
      newState.loading = false;
      return newState;
    }
    case ChartActionTypes.isFetchShareClsPerfDataInProgress: {
      const {isFetchShareClsPerfDataInProgress} = data;
      newState.isFetchShareClsPerfDataInProgress = isFetchShareClsPerfDataInProgress;
      return newState;
    }
    default:
      console.warn(`Action type ${type} not found in reducer.`);
      return state;
  }
};
