// tslint:disable:no-submodule-imports
import { ReactNode, FunctionComponent, useMemo } from 'react';

import {
    DatePickerProps,
    defaultDatePickerProps,
    DatePickerOptions,
} from '@gs-ux-uitoolkit-common/datepicker';

import { Input, InputIcon } from '@gs-ux-uitoolkit-react/input';

import { useDatePickerHooks } from './hooks/useDatePickerHooks';
import { useDatePickerInputCallbacks } from './hooks/useDatePickerInputCallbacks';
import { useDatePickerInstance } from './hooks/useDatePickerInstance';

export interface ReactDatePickerProps extends Omit<DatePickerProps, 'options'> {
    /**
     * Children passed to the DatePicker instance, used to wrap custom inputs
     */
    children?: ReactNode;
    placeholder?: string;
    options?: DatePickerOptions;
}

export const DatePicker: FunctionComponent<ReactDatePickerProps> = props => {
    const {
        children,
        options = {},
        classes,
        clearable = defaultDatePickerProps.clearable,
        placeholder,
        instanceCallback,
        ...otherProps
    } = props;
    const { mode, enableTime } = options;

    const { setInputValue, inputValue, onChange, onClose } = useDatePickerHooks({ options });
    const { flatpickrInstance, dataId, datePickerContainerRef, theme } = useDatePickerInstance({
        options,
        onChange,
        onClose,
        instanceCallback,
        classes,
    });
    const { onClearClick, onInputChange } = useDatePickerInputCallbacks({
        setInputValue,
        flatpickrInstance,
    });

    const placeHldr = useMemo(() => {
        return (
            placeholder ||
            (mode === 'range' ? '4 May 2021 - 5 May 2021' : '4 May 2021') +
                (enableTime ? ' 00:00' : '')
        );
    }, [mode, enableTime, placeholder]);

    const commonRootDataProps = {
        'data-id': dataId,
        'data-gs-uitk-component': 'datepicker',
    };
    const commonInputDataProps = {
        'data-cy': 'datepicker.input',
        'data-input': true,
    };

    // Custom wrapped input element
    if (options.wrap) {
        return (
            <div {...commonRootDataProps} ref={datePickerContainerRef} {...otherProps}>
                {children}
            </div>
        );
    }

    // Input is hidden
    if (options.inline) {
        return (
            <div {...commonRootDataProps} ref={datePickerContainerRef} {...otherProps}>
                <Input
                    {...commonInputDataProps}
                    value={inputValue}
                    onChange={onInputChange}
                    type="hidden"
                />
            </div>
        );
    }

    return (
        <div {...commonRootDataProps} ref={datePickerContainerRef} {...otherProps}>
            <Input
                {...commonInputDataProps}
                value={inputValue}
                leadingContent={
                    <InputIcon
                        name="calendar-today"
                        type="outlined"
                        color={theme.text.primary as string}
                    />
                }
                onChange={onInputChange}
                placeholder={placeHldr}
                clearable={clearable}
                onClearClick={onClearClick}
            />
        </div>
    );
};
