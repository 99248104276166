import ChoicesComponent, { Choices } from '@gs-ux-uitoolkit-common/choices';
import {
    defaults,
    KeyHelpers,
    sanitizeHtml,
    unsanitizeHtml,
    isTouchDevice,
} from '@gs-ux-uitoolkit-common/shared';
import { CssClasses, StyleSheet } from '@gs-ux-uitoolkit-common/style';
import { selectStyleSheet } from './style-sheets/select-style-sheet';
import {
    decodeHTMLEntityCodes,
    toChoiceOption,
    getSelectClassNames,
    updateDropdownTopPosition,
    resetDropdownTopPosition,
    EMPTY_OPTIONS,
} from './utils';
import {
    ChoicesSelectedOption,
    NormalizedSelectConfig,
    SelectSize,
    SelectChangeEvent,
    SelectEvent,
    SelectConfig,
    SelectConfigWithClassName,
    selectClassPrefix,
    SelectSearchEvent,
    SelectStatus,
} from './options';
import { IconElementComponent } from '@gs-ux-uitoolkit-common/icon-font';
import {
    removeIconSizeVariant,
    getIconPropsByStatus,
    getSelectChevronDownStyles,
    getSelectChevronUpIconStyles,
    getStatusIconStyles,
} from './style-sheets/common-styles';
import { SelectPlacement, selectDefaultProps } from './select-props';
import { Theme } from '@gs-ux-uitoolkit-common/theme';
import { AbstractSelect } from './abstract-select';

export type ChoicesConfig = Partial<ChoicesComponent['config']>;

const selectClassNames = getSelectClassNames(selectClassPrefix);
const statusIconStyleSheet = new StyleSheet(
    'statusIcon',
    ({ size, status, theme }: { size: SelectSize; status: SelectStatus; theme: Theme }) => ({
        root: getStatusIconStyles({ size, status, theme }),
    })
);

const removeIconStyleSheet = new StyleSheet(
    'removeIcon',
    ({ size, theme }: { size: SelectSize; theme: Theme }) => ({
        root: {
            color: theme.color.gray.bold,
            fontSize: removeIconSizeVariant[size || 'md'].fontSize,
        },
    })
);

const chevronDownIconStyleSheet = new StyleSheet(
    'chevronDownIcon',
    ({ size, theme }: { size: SelectSize; theme: Theme }) => ({
        root: getSelectChevronDownStyles(size, theme),
    })
);

const chevronUpIconStyleSheet = new StyleSheet(
    'chevronUpIcon',
    ({ size, searchable, theme }: { size: SelectSize; searchable: boolean; theme: Theme }) => ({
        root: getSelectChevronUpIconStyles({
            isSingleSelect: true,
            size,
            searchable,
            theme,
        }),
    })
);

/**
 * Common implementation of the <Select /> component.
 * instantiates choices.js generates configuration options and updates the configuration when possible.
 *
 * @param selectEl The <select> element to decorate with our Select component.
 * @param config The configuration to instantiate the Select component with.
 */
export class CommonSelectComponent extends AbstractSelect<NormalizedSelectConfig> {
    /**
     * The Choices.js object created for the given select component
     */
    private choices!: ChoicesComponent;

    /**
     * The HTML element which takes focus on Tab.
     */
    private selectFocusEl: HTMLDivElement | null = null;

    /**
     * The HTML element which wraps the <select> element.
     */
    private selectInnerEl: HTMLDivElement | null = null;

    /**
     * Flag used to determine if callbacks should be called.
     * You can use this to update the select box, but prevent from calling the developers callback.
     */
    private suppressEvents: boolean = true;

    /**
     * Flag used to determine if the searchEvent has been fixed. We only want to "fix" the issue
     *  once. For more details on the issue please see `fixSearchEvent()` in this class.
     */
    private isSearchEventFixed: boolean = false;

    /**
     * Flag used to determine if the menu has been shown, as the show event is called
     * twice in some cases. This is needed to make sure it is positioned properly when at a boundary.
     */
    private isMenuShowing: boolean = false;

    /**
     * The CSS classes used by the component.
     */
    private mountedCssClassNames!: CssClasses<typeof selectStyleSheet>;

    /**
     * Reference to the instantiated remove icon
     */
    private removeIcon?: IconElementComponent;

    /**
     * Reference to the instantiated status icon
     */
    private statusIcon?: IconElementComponent;

    /**
     * Reference to the instantiated chevron down icon, this is displayed when the dropdown menu is closed
     */
    private chevronDownIcon?: IconElementComponent;

    /**
     * Reference to the instantiated chevron up icon, this is displayed when the dropdown menu is opened
     */
    private chevronUpIcon?: IconElementComponent;

    /**
     * Theme passed in from React or Angular to set the style
     */
    private theme: Theme | undefined;

    constructor(selectContainerEl: HTMLDivElement, config: SelectConfig) {
        super(selectContainerEl, '[data-cy="gs-uitk-select__inner-select"]');

        const normalizedConfig = this.normalizeConfig(config);
        this.props = normalizedConfig;
        this.setup(normalizedConfig);
    }

    /***
     * Normalizes the select configuration.
     */
    private normalizeConfig(config: SelectConfig): NormalizedSelectConfig {
        return defaults({}, config, {
            className: '',
            disabled: false,
            inline: selectDefaultProps.inline,
            menuPlacement: selectDefaultProps.menuPlacement,
            searchable: selectDefaultProps.searchable,
            size: selectDefaultProps.size,
            status: selectDefaultProps.status,
        });
    }

    /**
     * Setup tasks to capture events and config from wrapper component
     */
    protected setup(config: NormalizedSelectConfig) {
        super.setup(config);

        this.updateStyles(
            config.size,
            config.status,
            config.searchable,
            this.theme,
            config.inline,
            config.noOptionsContent
        );

        const selectConfig: ChoicesConfig = this.generateChoicesConfig(config);
        this.choices = new ChoicesComponent(this.selectEl, selectConfig);
        this.choices.removeActiveItems(0);
        this.updateSelectConfig(config);
        this.attachEventListeners();
        this.fixSearchEvent(config);
        this.suppressEvents = false;
    }

    /**
     * Function used to "update" the styles for the component. This function mounts the CSS-in-JS
     *    stylesheet which used to style the component. The stylesheet itself only returns one
     *    CSS class by the name of "root". This class is only added to the outermost (root) element
     *    of the component. We do this since we are leveraging CSS selectors to allow the `size`
     *    prop to be changed programmatically after the component is instantiated.
     *
     * @param size The current size of the component.
     * @param status Status of the component: 'success', 'warning', 'error', 'none'.
     * @param searchable Determines if the component has the ability for the options to be searched.
     * @param inline Whether the component should display as inline or block.
     */
    private updateStyles(
        size: SelectSize,
        status: SelectStatus,
        searchable: boolean,
        theme?: Theme,
        inline?: boolean,
        noOptionsContent?: string,
        menuPlacement?: SelectPlacement
    ) {
        // remove the root class name if its mounted
        const containerClassList = this.selectOuterContainer.classList;

        if (
            this.mountedCssClassNames &&
            containerClassList.contains(this.mountedCssClassNames.root)
        ) {
            containerClassList.remove(this.mountedCssClassNames.root);
        }

        //should only mount the stylesheet when theme exists
        if (theme) {
            // Mount the CSS-in-JS Stylesheet
            const mountedClasses = selectStyleSheet.mount(this, {
                size,
                status,
                searchable,
                cssClassNames: selectClassNames,
                inline,
                noOptionsContent,
                theme,
                menuPlacement,
            });
            this.mountedCssClassNames = mountedClasses;

            // We only add the CSS class to the root element so we can later on generate a new
            //    stylesheet if the size changes and only swap out the root class name. (The Style sheet
            //    uses decedent selectors to actually style the components)
            containerClassList.add(mountedClasses.root);

            this.updateRemoveIcon();
        }
    }

    /**
     * Used to update the configuration on the component
     * @param newConfig - Current config on the component
     * @param prevConfig - Previous config on the component
     */
    public setConfig(newConfig: SelectConfigWithClassName, prevConfig: SelectConfigWithClassName) {
        const newNormalizedConfig = this.normalizeConfig(newConfig);
        const prevNormalizedConfig = this.normalizeConfig(prevConfig);
        this.props = newNormalizedConfig;
        this.updateSelectConfig(newNormalizedConfig, prevNormalizedConfig);
        this.fixSearchEvent(newNormalizedConfig);
    }

    /**
     * Function used to focus the select component.
     */
    public focus = () => {
        if (!this.props.disabled) {
            this.choices.showDropdown();
        }
    };

    /**
     * Function used to blur the select component.
     */
    public blur() {
        this.choices.hideDropdown();
    }

    /**
     * Method to update the status icon when status !== undefined or 'none'
     */
    private updateStatusIcon = () => {
        const { size, status } = this.props;
        if (this.statusIcon) {
            this.statusIcon.destroy();
        }
        if (status !== undefined && status !== 'none') {
            //stored the newly instantiated statusIcon to the pointer object
            this.statusIcon = new IconElementComponent({
                name: getIconPropsByStatus(status, size).name,
                type: 'filled',
                //set the icon spin to true if the select status is loading
                spin: status === 'loading',
                classes: statusIconStyleSheet.mount(this, { status, size, theme: this.theme! }),
                attributes: { 'data-icon': 'status' },
            });
            this.statusIcon.render(this.getSelectInnerEl());
        }
    };

    /**
     * Method to add the remove icon when there is removeButton
     * @param event - Custom Event emitted from choices event.
     */
    private updateRemoveIcon = (event?: Event) => {
        if (event) {
            event.stopPropagation();
        }
        if (this.removeIcon) {
            this.removeIcon.destroy();
        }
        const { size } = this.props;
        //stored the newly instantiated removeIcon to the pointer object
        this.removeIcon = new IconElementComponent({
            name: 'cancel',
            type: 'filled',
            classes: removeIconStyleSheet.mount(this, { size, theme: this.theme! }),
        });

        const removeButton = this.getRemoveButton();
        if (removeButton) {
            removeButton.innerHTML = '';
            this.removeIcon.render(removeButton);
        }
    };

    /**
     * Method to add the chevron down icon when the dropdown menu is closed
     */
    private updateChevronDownIcon = () => {
        //add the icon if there is no active dropdownlist

        if (this.getActiveDropdownListEl() === null) {
            const { size } = this.props;
            if (this.chevronDownIcon) {
                this.chevronDownIcon.destroy();
            }

            //stored the newly instantiated chevronDownIcon to the pointer object
            this.chevronDownIcon = new IconElementComponent({
                name: 'keyboard-arrow-down',
                type: 'filled',
                classes: chevronDownIconStyleSheet.mount(this, {
                    size,
                    theme: this.theme!,
                }),
            });
            const innerEl = this.getSelectInnerEl();
            this.chevronDownIcon.render(innerEl);
        }
    };

    /**
     ** Method to add the chevron up icon when the dropdown menu is opened
     */
    private updateChevronUpIcon = () => {
        //add the icon if there is an active dropdownlist
        if (this.getActiveDropdownListEl() !== null) {
            const { size, searchable } = this.props;
            if (this.chevronUpIcon) {
                this.chevronUpIcon.destroy();
            }
            //stored the newly instantiated chevronUpIcon to the pointer object
            this.chevronUpIcon = new IconElementComponent({
                name: 'keyboard-arrow-up',
                type: 'filled',
                classes: chevronUpIconStyleSheet.mount(this, {
                    size,
                    searchable,
                    theme: this.theme!,
                }),
            });
            let containerEl = this.getSelectInnerEl();
            //if the select component is not searchable, the icon should be appended to the
            //dropdown list instead of the innerEl
            if (searchable !== false) {
                containerEl = this.getSelectInnerEl()!;
            }
            this.chevronUpIcon.render(containerEl);
        }
    };

    /**
     * Returns an object that maps components config to choicesJS config options
     * @param componentConfig - Properties from client's react component
     */
    protected generateChoicesConfig(componentConfig: NormalizedSelectConfig): ChoicesConfig {
        const commonConfig = super.generateChoicesConfig(componentConfig);

        const overrideConfig: ChoicesConfig = {};
        overrideConfig.classNames = selectClassNames;

        // Set the initial selection
        const { selectedValue, defaultValue } = componentConfig;
        if (selectedValue != null) {
            overrideConfig.items = [selectedValue];
        } else if (defaultValue != null) {
            overrideConfig.items = [defaultValue];
        }

        //The scroll position should not reset after each selection.
        //By setting the resetScrollPosition config to false, the dropdown menu's scroll
        //would remain where it was if the new selection remains within the visible area.
        overrideConfig.resetScrollPosition = false;

        if (componentConfig.searchable === false) {
            overrideConfig.searchEnabled = componentConfig.searchable;
        }
        if (componentConfig.clearable !== undefined) {
            overrideConfig.removeItemButton = componentConfig.clearable;
        }

        return { ...commonConfig, ...overrideConfig };
    }

    /**
     * Updates the options and selected options
     */
    protected async updateSelectConfig(
        config: NormalizedSelectConfig,
        prevConfig?: NormalizedSelectConfig
    ) {
        super.updateSelectConfig(config, prevConfig);

        this.suppressEvents = true;
        const {
            disabled,
            defaultValue,
            onChange,
            options = EMPTY_OPTIONS,
            placeholder,
            searchable,
            selectedValue,
            size,
            status,
            className,
            inline,
            noOptionsContent,
            menuPlacement,
        } = config;

        const hasPrevConfig = !!prevConfig;
        prevConfig = prevConfig || {
            className: '',
            disabled: false,
            inline: selectDefaultProps.inline,
            menuPlacement: selectDefaultProps.menuPlacement,
            searchable: selectDefaultProps.searchable,
            searchFields: selectDefaultProps.searchFields,
            size: selectDefaultProps.size,
            status: selectDefaultProps.status,
        };

        const choicesInstance = this.choices;
        // Choices typings are wrong so asserting to our own interface here
        const currentValue = choicesInstance.getValue() as unknown as Choices.Choice;

        if (hasPrevConfig && options !== prevConfig.options) {
            // We only run this code if there is a previous config (i.e., not
            // during initialization) because first time there will be no
            // active items to remove and toChoice will already have been run
            // on options when calling generateChoicesConfig().
            choicesInstance.removeActiveItems(0);
            const newOptions = (options || []).map(toChoiceOption);
            choicesInstance.setChoices(newOptions, 'value', 'label', true);
        }

        disabled ? choicesInstance.disable() : choicesInstance.enable();

        // Sets the placeholder prop when the component is in un-controlled mode,
        // if there is no current selected value or if the current selected
        // value is a  placeholder.
        if (
            (placeholder && currentValue === undefined) ||
            (currentValue && currentValue.placeholder)
        ) {
            this.showPlaceholder();
        }

        // We want to respect the `selectedValue` prop unless it is `undefined`.
        if (selectedValue !== undefined) {
            await this.updateSelectedValue();

            // Sets the placeholder prop when the component is in controlled mode.
            if (placeholder && selectedValue === null) {
                this.showPlaceholder();
            }
        }

        if (defaultValue != null && selectedValue === undefined) {
            choicesInstance.setChoiceByValue(defaultValue);

            // If a developer tries to set the defaultValue as something that is not valid.
            // We want to alert them that nothing was set.
            if (onChange && !currentValue) {
                this.suppressEvents = false;
                this.onOptionRemoved();
                this.suppressEvents = true;
            }
        }
        if (
            size !== prevConfig.size ||
            status !== prevConfig.status ||
            className !== prevConfig.className ||
            menuPlacement != prevConfig.menuPlacement ||
            (noOptionsContent !== prevConfig.noOptionsContent && this.theme)
        ) {
            this.updateStyles(
                size,
                status,
                searchable,
                this.theme,
                inline,
                noOptionsContent,
                menuPlacement
            );
        }
        updateDropdownTopPosition(this.getDropdownListboxEl(), this.props.menuPlacement);
        this.suppressEvents = false;
    }

    /**
     * Used to update the `selectedValue` of the component when the component is in "controlled" mode (i.e. the
     * parent component is controlling the selected value.
     */
    private async updateSelectedValue() {
        const { selectedValue } = this.props;
        const currentValue = this.choices.getValue();

        // If the 'selectedValue' is being controlled by the parent component, we need to reset the value
        // here. The parent component will provide a new 'selectedValue' prop to update the Select component later
        if (selectedValue !== undefined) {
            this.choices.removeActiveItems(0);
            this.choices.setChoiceByValue(selectedValue!);

            // If a developer tries to set a `selectedValue` that does not exist, we must notify them that the `selectedValue` was "reset" to null
            if (selectedValue && selectedValue !== currentValue) {
                this.suppressEvents = false;
                this.onOptionRemoved();
                this.suppressEvents = true;
            }
        }
    }

    /**
     * Displays (Shows) the placeholder provided by the developer.
     */
    private showPlaceholder(): void {
        const { placeholder } = this.props;
        const sanitizedPlaceholder = sanitizeHtml(placeholder || '');
        const selectListEl = this.getSelectListEl();

        //whether if the dropdown list already contains a placeholder option
        const placeholderOption = selectListEl
            ? selectListEl.querySelector<HTMLDivElement>(`.${selectClassNames.placeholder}`)
            : undefined;

        //only set a new placeholder option to choices if there is none added before
        if (placeholderOption === null) {
            //add placeholder as a new selectable choice to the existing options
            this.choices.setChoices(
                [
                    {
                        placeholder: true,
                        label: sanitizedPlaceholder,
                        value: placeholder,
                    },
                ],
                'value',
                'label'
            );
        }
        //setChoiceByValue selects the placeholder option, so its selected state becomes true and thus visible
        this.choices.setChoiceByValue(placeholder as string);
    }

    /**
     * Adds provided event listeners to the select container
     * @param componentConfig - Properties from client react component
     */
    private attachEventListeners(): void {
        this.addManagedEventListener(this.selectEl, 'search', this.onSearch);
        this.addManagedEventListener(this.selectEl, 'addItem', this.onOptionSelected);
        this.addManagedEventListener(this.selectEl, 'removeItem', this.onOptionRemoved);
        this.addManagedEventListener(this.selectEl, 'showDropdown', this.onMenuShow);
        this.addManagedEventListener(this.selectEl, 'hideDropdown', this.onMenuHide);
        this.addManagedEventListener(this.selectEl, 'choice', this.onChoiceSelected);
        this.addManagedEventListener(this.selectEl, 'addItem', this.updateRemoveIcon);

        this.selectFocusEl = this.getSelectOuterEl();
        const inputEl = this.getInputEl();

        this.selectInnerEl = this.getSelectInnerEl();

        /**
         * The focus always sets on the input element and not on the select element.
         * 'onblur' will be called only when input looses focus.
         */
        if (inputEl) {
            this.addManagedEventListener(inputEl, 'blur', this.onBlur);
        } else {
            /**
             * If input doesn't exist (non-searchable mode), attach to the outer select element instead
             */
            this.addManagedEventListener(this.selectFocusEl, 'blur', this.onBlur);
        }

        // our Angular wrapper uses an EventEmitter called 'change' to emit the change, but parent components subscribing to it
        // could also receive the DOM event called 'change' which is emitted by Choices itself. We want to prevent developers from
        // receiving Choices' event
        this.selectEl.addEventListener(
            'change',
            event => {
                event.stopPropagation();
            },
            false
        );

        const hideDropdown = () => this.choices.hideDropdown();
        /**
         * In mobile browsers especially Safari, the native keyboard is visible when the user focuses the input element.
         * In order to focus the input element on user click, the following listener which hides the dropdown, should not get executed on mobile
         */
        if (!isTouchDevice()) {
            this.addManagedEventListener(this.selectOuterContainer, 'click', hideDropdown);
        }

        // On focus, open the select immediately.
        this.addManagedEventListener(this.selectFocusEl, 'focus', this.focus);
    }

    private managedEventHandlers: [HTMLElement, string, EventListenerOrEventListenerObject][] = [];

    private addManagedEventListener(
        el: HTMLElement,
        type: string,
        listener: EventListenerOrEventListenerObject
    ) {
        el.addEventListener(type, listener, false);
        this.managedEventHandlers.push([el, type, listener]);
    }

    private removeManagedEventListeners() {
        let managedEventHandler = this.managedEventHandlers.pop();
        while (managedEventHandler) {
            const [el, type, listener] = managedEventHandler;
            el.removeEventListener(type, listener, false);
            managedEventHandler = this.managedEventHandlers.pop();
        }
    }

    /**
     * Currently Choices.js does not fire a search event when the input is cleared.
     *    This function fixes that behavior. For more details please see
     *    https://github.com/jshjohnson/Choices/issues/630
     *
     * @param config Current config on the component
     */
    private fixSearchEvent(config: NormalizedSelectConfig) {
        if (!this.isSearchEventFixed && config.searchable && !config.disabled) {
            const inputEl = this.getInputEl();

            // Choices.js currently does not fire an event when the input is
            // cleared. The fix below is a workaround so we fire the "search"
            // event when the input is cleared.
            inputEl!.addEventListener('keyup', event => {
                const value = (event.target as HTMLInputElement).value;
                // No easy way to check for CTRL/CMD + X below. the check for CTRL/CMD should
                // be enough since we are also checking the value.
                if (
                    (event.which === KeyHelpers.keyCode.BACKSPACE ||
                        event.which === KeyHelpers.keyCode.DELETE ||
                        KeyHelpers.isCtrlKey(event.which)) &&
                    value === ''
                ) {
                    // `triggerEvent` is a choices specific function.
                    // Currently missing from the choices.js typings.
                    // Hence the cast to "any" below.
                    (this.choices.passedElement as any).triggerEvent('search', {
                        value,
                        resultCount: 0,
                    });
                }
            });
            /**
             * Needed this event listener because Choices.js triggers the search on
             * keyup and the keyup event doesn't work properly when generated programatically.
             * Added an input event listener so that when the input event is triggered programatically / by user's input,
             * this listener gets triggered
             */
            inputEl!.addEventListener('input', event => {
                /**
                 * The cancelable property is false by default for all user events. It is being used
                 * as a flag here, as the saucelabs tests will trigger the input event with
                 * this property as true. This will not impact any other functionality of
                 * select/multi-select.
                 */
                if (event.cancelable) {
                    const value = (event.target as HTMLInputElement).value;
                    const choicesObj = this.choices as any;
                    choicesObj?._handleSearch(value);
                }
            });

            this.isSearchEventFixed = true;
        }
    }

    /**
     * Updates the component's style based on theme
     */
    public setTheme = (theme: Theme) => {
        const { searchable, size, status, inline, noOptionsContent, menuPlacement } = this.props;
        this.theme = theme;
        this.updateStyles(size, status, searchable, theme, inline, noOptionsContent, menuPlacement);
        this.updateStatusIcon();
        this.updateChevronDownIcon();
        this.updateRemoveIcon();
    };

    /**
     * Reformats the 'search' event data and calls the Select components 'onSearch' callback.
     * @param event - Custom Event emitted from choices event.
     */
    private onSearch = (event: any) => {
        event.stopPropagation();
        const { onSearch, name } = this.props;
        if (!this.suppressEvents) {
            if (onSearch) {
                const searchEvent: SelectSearchEvent = {
                    searchValue: decodeHTMLEntityCodes(event.detail.value),
                    resultsCount: event.detail.resultCount,
                };
                if (name) {
                    searchEvent.componentName = name;
                }
                onSearch(searchEvent);
            }
            updateDropdownTopPosition(this.getDropdownListboxEl(), this.props.menuPlacement);
        }
    };

    /**
     * Reformats the 'addItem' event data and calls the Select components 'onChange' callback.
     * @param event - Custom Event emitted from choices event.
     */
    private onOptionSelected = async (event: any) => {
        event.stopPropagation();
        const { onChange, selectedValue, placeholder, name } = this.props;
        const choicesOption = this.choices.getValue() as unknown as ChoicesSelectedOption;
        const currentlySelectedValue = event.detail.value;
        const currentlySelectedLabel = event.detail.label;

        this.selectInnerEl!.title = decodeHTMLEntityCodes(currentlySelectedLabel);

        // It looks like choices.js is firing an event when we remove an existing option to re-add a placeholder.
        // We are checking to make sure the value to give developers is not a placeholder being added back.
        if (!this.suppressEvents && currentlySelectedValue !== placeholder) {
            if (onChange) {
                const changeEvent: SelectChangeEvent = {
                    selectedOption: {
                        value: currentlySelectedValue,
                        label: unsanitizeHtml(event.detail.label),
                        ...(event.detail.customProperties && {
                            customProperties: event.detail.customProperties,
                        }),
                    },
                    selectedValue: currentlySelectedValue,
                };
                if (name) {
                    changeEvent.componentName = name;
                }
                onChange(changeEvent);
            }
            if (choicesOption.value !== selectedValue && selectedValue !== undefined) {
                this.suppressEvents = true;
                await this.updateSelectedValue();
                this.suppressEvents = false;
            }
        }
    };

    /**
     * Reformats the 'choice' event data and calls the Select components 'onSelect' callback.
     * @param event - Custom Event emitted from choices event.
     */
    private onChoiceSelected = async (event: any) => {
        event.stopPropagation();
        const { onSelect, name } = this.props;

        const currentlySelectedValue = event.detail.choice.value;
        if (onSelect && !this.suppressEvents) {
            const choiceEvent: SelectEvent = {
                selectedOption: {
                    value: currentlySelectedValue,
                    label: unsanitizeHtml(event.detail.choice.label),
                    ...(event.detail.choice.customProperties && {
                        customProperties: event.detail.choice.customProperties,
                    }),
                },
                selectedValue: currentlySelectedValue,
            };
            if (name) {
                choiceEvent.componentName = name;
            }
            onSelect(choiceEvent);
        }
    };

    /**
     * Reformats the 'removeItem' event data and calls the Select components 'onChange' callback.
     * @param event - Custom Event emitted from choices event.
     */
    private onOptionRemoved = (event?: any) => {
        if (event) {
            event.stopPropagation();
        }
        const { onChange, placeholder, name } = this.props;
        if (onChange && !this.suppressEvents && this.choices.getValue() === undefined) {
            const changeEvent: SelectChangeEvent = {
                selectedOption: null,
                selectedValue: null,
            };
            if (name) {
                changeEvent.componentName = name;
            }
            onChange(changeEvent);
        }

        const currentSelectedValue = this.choices.getValue(true);

        if (currentSelectedValue === undefined) {
            if (this.selectInnerEl) {
                this.selectInnerEl.title = '';
            }
            if (placeholder !== undefined) {
                this.showPlaceholder();
            }
        }
    };

    /**
     * Calls the Select components 'onMenuShow' callback.
     * @param event - Custom Event emitted from choices event.
     */
    private onMenuShow = (event: any) => {
        event.stopPropagation();

        const { onMenuShow, placeholder, options, menuPlacement } = this.props;
        if (!this.isMenuShowing) {
            updateDropdownTopPosition(this.getDropdownListboxEl(), menuPlacement);
            if (onMenuShow && !this.suppressEvents) {
                onMenuShow();
            }
            //remove the chevron down icon
            if (this.chevronDownIcon) {
                this.chevronDownIcon.destroy();
            }
            this.updateChevronUpIcon();
        }
        // retrieves the htmlElement that contains the choices option list
        const selectListEl = this.getSelectListEl();
        const highlightedClassList = this.selectOuterContainer.querySelectorAll<HTMLDivElement>(
            `.${selectClassNames.highlightedState}`
        );
        const currentlySelectedOptionValue = this.choices.getValue(true);

        //When the user hovers a different selected option,
        //then scrolls to an area where the selected option is not in view,
        //and then closes the dropdown,the dropdown should be displayed as how it
        //was when closed when the user reopens the dropdown.
        //Was discussed with UX designer, and best approach is to reset it to
        //the selected option with every open.  If there is no selected option,
        //the list should be displayed starting at the beginning with the first option highlighted.
        //Another approach was considered to bring the selected option to the first in the list,
        //but this proved difficult to implement when option is grouped.
        //To simplify, we will scroll to the location of the selected option.

        if (selectListEl && options && options.length > 0) {
            //clear existing highlighted class on all highlighted options
            highlightedClassList.forEach(option => {
                (option as HTMLElement).classList.remove(selectClassNames.highlightedState);
            });
            // if there is a currently selected option that is not the placeholder or is not undefined
            if (
                currentlySelectedOptionValue !== placeholder &&
                currentlySelectedOptionValue !== undefined
            ) {
                //the currently selected option is highlighted
                const currentlySelectedOptionId = (
                    this.choices.getValue() as unknown as ChoicesSelectedOption
                ).choiceId;

                const currentlySelectedOptionEl = selectListEl.querySelector(
                    `[data-id='${currentlySelectedOptionId}']`
                );

                //Note: attempted to use choices.js's highlight API methods, which is not working as intended
                //thus we are manipulating the classes directly
                currentlySelectedOptionEl?.classList.add(selectClassNames.highlightedState);

                //adjust the scroll so that the selected option would always be in view
                //Note: scrollIntoView does not exist in jsdom (used by jest)
                currentlySelectedOptionEl?.scrollIntoView?.({
                    behavior: 'auto',
                    block: 'nearest',
                    inline: 'nearest',
                });
            } else {
                //only the first option is highlighted
                selectListEl
                    .querySelector('[data-id="1"]')
                    ?.classList.add(selectClassNames.highlightedState);
                //scroll to top of the dropdown if there is no selected option
                selectListEl.scrollTop = 0;
            }
        }
        this.isMenuShowing = true;
    };

    /**
     * Calls the Select components 'onMenuHide' callback.
     * @param event - Custom Event emitted from choices event.
     * @param componentConfig - config for client component.
     */
    private onMenuHide = (event: any) => {
        event.stopPropagation();
        this.isMenuShowing = false;
        const { onMenuHide } = this.props;
        resetDropdownTopPosition(this.getDropdownListboxEl());
        if (onMenuHide && !this.suppressEvents) {
            onMenuHide();
        }
        //remove the chevron up icon
        if (this.chevronUpIcon) {
            this.chevronUpIcon.destroy();
        }
        this.updateChevronDownIcon();
    };

    /**
     * Calls the Select components 'onBlur' callback.
     * @param event - Custom Event emitted from choices event.
     */
    private onBlur = (event: any) => {
        event.stopPropagation();
        const { onBlur } = this.props;
        if (onBlur && !this.suppressEvents) {
            onBlur(event);
        }
    };

    private getInputEl(): HTMLInputElement | null {
        const inputEl = this.selectOuterContainer.querySelector<HTMLInputElement>(
            `.${selectClassNames.inputCloned}`
        );

        return inputEl;
    }

    private getSelectOuterEl(): HTMLDivElement {
        const selectOuterEl =
            this.selectOuterContainer.querySelector<HTMLDivElement>('.gs-uitk-select__outer');

        return selectOuterEl!;
    }

    private getSelectInnerEl(): HTMLDivElement {
        const selectInnerEl =
            this.selectOuterContainer.querySelector<HTMLDivElement>('.gs-uitk-select__inner');

        return selectInnerEl!;
    }

    private getDropdownListboxEl(): HTMLDivElement {
        const dropdownEl = this.selectOuterContainer.querySelector<HTMLDivElement>(
            `.${selectClassNames!.listDropdown} div[role="listbox"]`
        );
        return dropdownEl!;
    }

    private getRemoveButton(): HTMLButtonElement | null {
        const removeButton = this.selectOuterContainer.querySelector<HTMLButtonElement>(
            `.${selectClassNames!.button}`
        );
        return removeButton;
    }

    private getActiveDropdownListEl(): HTMLDivElement | null {
        const activeDropdownListEl = this.selectOuterContainer.querySelector<HTMLDivElement>(
            `.${selectClassNames!.activeState}`
        );
        return activeDropdownListEl;
    }

    private getSelectListEl(): HTMLDivElement {
        const selectListEl = this.selectOuterContainer.querySelector<HTMLDivElement>(
            `.${selectClassNames.listDropdown} .${selectClassNames.list}`
        );
        return selectListEl!;
    }

    /**
     * Destroys the component.
     */
    public destroy() {
        if (this.removeIcon) {
            this.removeIcon.destroy();
        }
        if (this.statusIcon) {
            this.statusIcon.destroy();
        }
        if (this.chevronDownIcon) {
            this.chevronDownIcon.destroy();
        }
        if (this.chevronUpIcon) {
            this.chevronUpIcon.destroy();
        }
        //unmount the icon stylesheets
        statusIconStyleSheet.unmount(this);
        removeIconStyleSheet.unmount(this);
        chevronDownIconStyleSheet.unmount(this);
        chevronUpIconStyleSheet.unmount(this);
        this.removeManagedEventListeners();
        this.choices.destroy();
        selectStyleSheet.unmount(this);
    }
}
