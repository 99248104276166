import { MutableRefObject, useLayoutEffect, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { BaseOptions } from 'flatpickr/dist/types/options';

import {
    datepickerOptions,
    destroyFlatpickr,
    styleDatePicker,
    hasCalendar,
    getPrevMonthElement,
    getNextMonthElement,
    prevMonthIconName,
    nextMonthIconName,
    prevMonthIconType,
    nextMonthIconType,
    getNextDatepickerId,
    DatePickerOptions,
    datePickerStyleSheet,
} from '@gs-ux-uitoolkit-common/datepicker';
import { Icon, IconType } from '@gs-ux-uitoolkit-react/icon-font';
import { isBrowser } from '@gs-ux-uitoolkit-react/shared';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { Theme, useTheme } from '@gs-ux-uitoolkit-react/theme';
import { StringKeysOf } from 'gs-uitk-type-utils';

import flatpickr from 'flatpickr';
import { Instance } from 'flatpickr/dist/types/instance';
import { componentAnalytics } from '../analytics-tracking';

// Avoid issues with SSR and jest when calling useLayoutEffect
// https://stackoverflow.com/a/66580539
const useIsomorphicLayoutEffect = isBrowser ? useLayoutEffect : useEffect;

export function useDatePickerInstance({
    options,
    instanceCallback,
    onChange,
    onClose,
    classes,
}: UseDatePickerInstanceArgs) {
    const flatpickrInstanceRef = useRef(null as Instance | null);
    const previousOptionsRef = useRef(null as DatePickerOptions | null);
    const datePickerContainerRef = useRef(null as HTMLDivElement | null);
    const dataIdRef = useRef(getNextDatepickerId());
    const theme = useTheme();
    const cssClasses = useStyleSheet(datePickerStyleSheet, { theme });

    // The 3 useLayoutEffect() calls below are intentionally sequenced to optimize
    // initialization and avoid running the same code multiple times on mounting/initialization
    //
    // Note: useLayoutEffect() is preferred over useEffect() here because we are
    // modifying the DOM directly, and want to do that before the browser's paint cycle.
    // We are using useIsomorphicLayoutEffect() to avoid issues using useLayoutEffect
    // with SSR and Jest (see comment above)

    // In this first useIsomorphicLayoutEffect below, we don't want to run updateFlatpickrOptions()
    // upon mounting, since createDatePickrInstance() will do the same thing.  So we'll schedule
    // this first, and bail out by checking if "flatpickrInstanceRef.current == null".  We
    // only want to run this after we initialized when our props/dependencies change.
    useIsomorphicLayoutEffect(() => {
        if (flatpickrInstanceRef.current == null) {
            return;
        }
        updateFlatpickrOptions({
            flatpickrInstanceRef,
            previousOptionsRef,
            options,
            onChange,
            onClose,
        });
    }, [
        options.altFormat,
        options.altInput,
        options.altInputClass,
        options.allowInput,
        options.allowInvalidPreload,
        options.appendTo,
        options.ariaDateFormat,
        options.conjunction,
        options.clickOpens,
        options.dateFormat,
        options.defaultDate,
        options.defaultHour,
        options.defaultMinute,
        options.disable,
        options.disableMobile,
        options.enable,
        options.enableTime,
        options.enableSeconds,
        options.formatDate,
        options.hourIncrement,
        options.inline,
        options.maxDate,
        options.minDate,
        options.minuteIncrement,
        options.mode,
        options.nextArrow,
        options.noCalendar,
        options.onChange,
        options.onClose,
        options.onOpen,
        options.onReady,
        options.parseDate,
        options.position,
        options.positionElement,
        options.prevArrow,
        options.shorthandCurrentMonth,
        options.static,
        options.showMonths,
        options.time_24hr,
        options.weekNumbers,
        options.wrap,
        options.monthSelectorType,
    ]);

    // Creates and initializes the instance on mount, and destroys on cleanup.
    // This useIsomorphicLayoutEffect() is intentionally sequenced to run after the
    // useIsomorphicLayoutEffect() above.  Note the dependencies specified here
    // as "[]": we are separating this from the other useIsomorphicLayoutEffect()'s
    // by only running this on mount and unmount
    useIsomorphicLayoutEffect(() => {
        createFlatpickrInstance({
            flatpickrInstanceRef,
            datePickerContainerRef,
            previousOptionsRef,
            options,
            onChange,
            onClose,
        });
        initFlatpickrInstance({
            flatpickrInstanceRef: flatpickrInstanceRef as MutableRefObject<Instance>,
            dataIdRef,
            options,
            instanceCallback,
        });
        componentAnalytics.trackRender({ officialComponentName: 'datepicker' });
        return () => {
            if (flatpickrInstanceRef.current) {
                destroyFlatpickr(flatpickrInstanceRef.current);
                flatpickrInstanceRef.current = null;
            }
        };
    }, []);

    // Runs after the flatpickr instance is intialized in the previous useIsomorphicLayoutEffect()
    // to apply styling.  This should also run when our props/dependencies are updated,
    // which is why it is separated from above.
    useIsomorphicLayoutEffect(() => {
        styleDatePicker(flatpickrInstanceRef.current!, options, cssClasses, classes);
    }, [
        options.altInput,
        ...Object.entries(cssClasses).flat(),
        ...(classes ? Object.entries(classes).flat() : []),
    ]);

    return {
        flatpickrInstance: flatpickrInstanceRef.current,
        dataId: dataIdRef.current,
        datePickerContainerRef,
        theme,
    };
}

function createFlatpickrInstance({
    flatpickrInstanceRef,
    datePickerContainerRef,
    previousOptionsRef,
    options,
    onChange,
    onClose,
}: {
    flatpickrInstanceRef: MutableRefObject<Instance | null>;
    datePickerContainerRef: MutableRefObject<HTMLDivElement | null>;
    previousOptionsRef: MutableRefObject<DatePickerOptions | null>;
    options: DatePickerOptions;
    onChange: (selectedDates: Date[], dateStr: string, instance: Instance) => void;
    onClose: (selectedDates: Date[], dateStr: string, instance: Instance) => void;
}) {
    const flatpickerOptions: DatePickerOptions = {
        ...datepickerOptions(options),
        // always `wrap` to enable flatpickr to use the UITK custom Input component,
        // unless `inline` is passed by the user (which is not compatible with `wrap`)
        wrap: options.inline ? false : true,

        // Set disableMobile to true if unspecified to prevent both the mobile flatpickr
        // version and our version of the calendar from displaying
        disableMobile: options.disableMobile === undefined ? true : options.disableMobile,
        onChange,
        onClose,
    };

    const flatpickrInstance = flatpickr(datePickerContainerRef?.current as any, flatpickerOptions);

    // Flatpickr does this on instantiation:
    //     Creates empty array
    //     Loops through provided nodes and creates instances of flatpickr for each
    //     Checks the length of the array
    //         If length is 1, return object at index of 0
    //         Else return array of instances
    //
    // The problem is:
    //    If flatpickr throws an error (related to not finding the node), it will not create an instance
    //    Since we only pass in one node, we will then get back an empty array (because it only checks if length===1)
    //
    // So to provide our users with a better understanding of what's wrong we must check if the instance is an empty array
    // And throw an error to alert the user that something is wrong
    // --note: Only need to check when wrap:true as users provide the input as a child :)
    if (Array.isArray(flatpickrInstance)) {
        throw new Error(
            flatpickrInstance.length
                ? `Multiple Flatpickr instances were returned. Please only wrap one input element for each Date Picker`
                : `No Flatpickr instances were returned. A possible cause is that the 'data-input' attribute is not set on the input.`
        );
    }

    // set flatpickr instance in ref
    flatpickrInstanceRef.current = flatpickrInstance;

    // save options for comparison later
    previousOptionsRef.current = flatpickerOptions;
}

function initFlatpickrInstance({
    flatpickrInstanceRef,
    dataIdRef,
    options,
    instanceCallback,
}: {
    flatpickrInstanceRef: MutableRefObject<Instance>;
    dataIdRef: MutableRefObject<number>;
    options: DatePickerOptions;
    instanceCallback: ((instance: Instance) => void) | undefined;
}) {
    if (flatpickrInstanceRef.current.calendarContainer) {
        flatpickrInstanceRef.current.calendarContainer.setAttribute(
            'data-id',
            String(dataIdRef.current)
        );
    }
    if (options.defaultDate) {
        flatpickrInstanceRef.current.setDate(options.defaultDate as string, true);
    }
    if (hasCalendar(flatpickrInstanceRef.current)) {
        const prevMonthEl = getPrevMonthElement(flatpickrInstanceRef.current);
        const nextMonthEl = getNextMonthElement(flatpickrInstanceRef.current);
        renderMonthChangeElement(prevMonthEl, prevMonthIconName, prevMonthIconType);
        renderMonthChangeElement(nextMonthEl, nextMonthIconName, nextMonthIconType);
    }
    if (instanceCallback) {
        instanceCallback(flatpickrInstanceRef.current);
    }
}

function updateFlatpickrOptions({
    flatpickrInstanceRef,
    previousOptionsRef,
    options,
    onChange,
    onClose,
}: {
    flatpickrInstanceRef: MutableRefObject<Instance | null>;
    previousOptionsRef: MutableRefObject<DatePickerOptions | null>;
    options: DatePickerOptions;
    onChange: (selectedDates: Date[], dateStr: string, instance: Instance) => void;
    onClose: (selectedDates: Date[], dateStr: string, instance: Instance) => void;
}) {
    const flatpickerOptions: { [key: string]: any } = {
        ...datepickerOptions(options),
        wrap: options.inline ? false : true,
        disableMobile: options.disableMobile === undefined ? true : options.disableMobile,
        onChange,
        onClose,
    } as const;

    const optionsKeys = Object.keys(flatpickerOptions);

    // const hooksAndOptions = [
    //     'altFormat',
    //     'altInput',
    //     'altInputClass',
    //     'allowInput',
    //     'allowInvalidPreload',
    //     'appendTo',
    //     'ariaDateFormat',
    //     'conjunction',
    //     'clickOpens',
    //     'dateFormat',
    //     'defaultDate',
    //     'defaultHour',
    //     'defaultMinute',
    //     'disable',
    //     'disableMobile',
    //     'enable',
    //     'enableTime',
    //     'enableSeconds',
    //     'formatDate',
    //     'hourIncrement',
    //     'inline',
    //     'maxDate',
    //     'minDate',
    //     'minuteIncrement',
    //     'mode',
    //     'nextArrow',
    //     'noCalendar',
    //     'onChange',
    //     'onClose',
    //     'onOpen',
    //     'onReady',
    //     'parseDate',
    //     'position',
    //     'positionElement',
    //     'prevArrow',
    //     'shorthandCurrentMonth',
    //     'static',
    //     'showMonths',
    //     'time_24hr',
    //     'weekNumbers',
    //     'wrap',
    //     'monthSelectorType',
    // ];

    optionsKeys.forEach((key: StringKeysOf<typeof flatpickerOptions>) => {
        let value = flatpickerOptions[key];

        if (value !== (previousOptionsRef.current || {})[key as keyof BaseOptions]) {
            // Hook handlers must be set as an array
            // TODO: Not understanding this next if statement, but doesn't seem
            //       to do anything and eslint pointed it out. Remove?
            // if (hooksAndOptions.indexOf(key) !== -1 && !Array.isArray(value)) {
            //     value = value;
            // }
            if (key === 'onChange') {
                value = onChange;
            }
            if (key === 'onClose') {
                value = onClose;
            }
            flatpickrInstanceRef.current?.set(key as keyof BaseOptions, value);
        }
    });

    previousOptionsRef.current = flatpickerOptions;
}

function renderMonthChangeElement(
    element: HTMLElement | null,
    iconName: 'arrow-forward' | 'arrow-back',
    iconType: IconType
) {
    if (!element) return;
    const root = createRoot(element);
    root.render(<Icon name={iconName} type={iconType} />);
}

export interface UseDatePickerInstanceArgs {
    options: DatePickerOptions;
    instanceCallback: ((instance: Instance) => void) | undefined;
    onChange: (selectedDates: Date[], dateStr: string, instance: Instance) => void;
    onClose: (selectedDates: Date[], dateStr: string, instance: Instance) => void;
    classes: Partial<Record<'root', string>> | undefined;
}

export interface UseDatePickerInstanceResult {
    flatpickrInstance: Instance | null;
    id: number;
    datePickerContainerRef: MutableRefObject<null>;
    theme: Theme;
}
