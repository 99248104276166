import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import UserMaintenanceProvider from '../../context/provider/UserMaintenanceProvider';
import UserDetails from '../UserDetails';
import OrganizationDetails from '../OrganizationDetails';
import useUserMaintenance from '../../context';
import { ThreeSquaresLoader } from '../../../../../components/core/Loaders';
import { ORGANIZATIONS, USERS, CREATE_USER } from '../../constants';
import { getUserData } from '../UserDetails/helper';
import { EDIT_MODE_ACTIVE } from '../../actions';
import './index.scss';

const Wrapper = ({ userDetails, organizationDetails }) => {
  const {
    state: { showLoader },
    storeValues: { pageKey },
    dispatch,
  } = useUserMaintenance();

  const detailsPageClass = classNames(
    'usersearch-details',
    { organizationdetails: pageKey === ORGANIZATIONS },
    { 'has-opacity': showLoader }
  );

  const createUserData = useMemo(() => {
    if (pageKey === CREATE_USER) {
      return getUserData();
    }
  }, [pageKey]);

  useEffect(() => {
    if (pageKey === CREATE_USER) {
      dispatch({ type: EDIT_MODE_ACTIVE, payload: { isEditable: true } });
    }
  }, [pageKey]);

  return (
    <section data-testid='user-details-page' className={detailsPageClass}>
      {showLoader && <ThreeSquaresLoader />}
      {pageKey === USERS && <UserDetails userDetails={userDetails} />}
      {pageKey === CREATE_USER && <UserDetails userDetails={createUserData} />}
      {pageKey === ORGANIZATIONS && <OrganizationDetails organizationDetails={organizationDetails} />}
    </section>
  );
};

Wrapper.propTypes = {
  userDetails: PropTypes.object,
  organizationDetails: PropTypes.object,
};

const UserMaintenanceWithProvider = ({ storeValues, userDetails, organizationDetails, reduxDispatch }) => {
  return (
    <UserMaintenanceProvider storeValues={storeValues} reduxDispatch={reduxDispatch}>
      <Wrapper organizationDetails={organizationDetails} userDetails={userDetails} />
    </UserMaintenanceProvider>
  );
};

UserMaintenanceWithProvider.propTypes = {
  userDetails: PropTypes.object,
  reduxDispatch: PropTypes.func,
  storeValues: PropTypes.object,
  organizationDetails: PropTypes.object,
};

export default UserMaintenanceWithProvider;
