import {
    addConfigItem,
    cancelEditedConfigItem,
    editConfigItem,
    newConfigItem,
    removeConfigItem,
    saveEditedConfigItem,
    updateEditedConfigItem,
} from '@gs-ux-uitoolkit-common/datacore';
import {
    KeyboardShortcut,
    KeyboardShortcutType,
    NumericShortcutOperation,
    ShortcutKey,
} from '../../plugins/keyboard-shortcut/keyboard-shortcut';
import { KeyboardShortcutActionKeys } from '../actions/action-constants';
import { KeyboardShortcutActionTypes } from '../actions/keyboard-shortcut-action';
import { KeyboardShortcutState } from '../datagrid-state';

/**
 * Initial state of the Custom Sort State
 */
export const defaultKeyboardShortcutState: KeyboardShortcutState = {
    configItemList: [],
    editedItem: null,
    originalEditedItem: null,
};

const emptyKeyboardShortcut: KeyboardShortcut = {
    // might need to pass the first non selected key from the lilst. Will be done as part of the wizard
    key: ShortcutKey.A,
    operation: NumericShortcutOperation.Add,
    type: KeyboardShortcutType.Numeric,
    value: 0,
};

/**
 * Reducer for the KeyboardShortcutState
 * @param state State of the KeyboardShortcutState
 * @param action Action received
 */
export const keyboardShortcutReducer = (
    state: KeyboardShortcutState = defaultKeyboardShortcutState,
    action: KeyboardShortcutActionTypes
): KeyboardShortcutState => {
    switch (action.type) {
        case KeyboardShortcutActionKeys.REMOVE_KEYBOARD_SHORTCUT:
            return removeConfigItem(state, action);
        case KeyboardShortcutActionKeys.EDIT_KEYBOARD_SHORTCUT:
            return editConfigItem(state, action);
        case KeyboardShortcutActionKeys.NEW_KEYBOARD_SHORTCUT:
            return newConfigItem(state, emptyKeyboardShortcut);
        case KeyboardShortcutActionKeys.SAVE_EDITED_KEYBOARD_SHORTCUT:
            return saveEditedConfigItem(state);
        case KeyboardShortcutActionKeys.UPDATE_EDITED_KEYBOARD_SHORTCUT:
            return updateEditedConfigItem(state, action);
        case KeyboardShortcutActionKeys.CANCEL_EDITED_KEYBOARD_SHORTCUT:
            return cancelEditedConfigItem(state);
        case KeyboardShortcutActionKeys.ADD_KEYBOARD_SHORTCUT:
            return addConfigItem(state, action);
        default:
            return state;
    }
};
