import { FormatControlProps } from '../../plugins/column-hint/view/components/column-hint-format-manager';
import { ColumnHintFormatSection } from '../../plugins/column-hint/view/components/formatScreens/column-hint-format-section';
import { Row, Col } from '@gs-ux-uitoolkit-react/layout';
import { columnFormattingPluginStylePrefix } from '../../style/plugins/column-hint-plugin-stylesheet';
import { ColumnHintControlFontWeight } from '../../plugins/column-hint/view/components/formatControls/column-hint-control-font-weight';
import { ColumnHintControlFontSize } from '../../plugins/column-hint/view/components/formatControls/column-hint-control-font-size';
export const ColumnHintFontsSection = (props: FormatControlProps) => {
    return (
        <ColumnHintFormatSection title="Fonts">
            <Row>
                <Col sm={12}>
                    <div
                        className={`${columnFormattingPluginStylePrefix}_flex-two-column-container ${columnFormattingPluginStylePrefix}_font ${columnFormattingPluginStylePrefix}_flex-column`}
                    >
                        <ColumnHintControlFontWeight
                            columnHint={props.columnHint}
                            onChangeHint={props.onChangeHint}
                        />
                        <ColumnHintControlFontSize
                            columnHint={props.columnHint}
                            onChangeHint={props.onChangeHint}
                        />
                    </div>
                </Col>
            </Row>
        </ColumnHintFormatSection>
    );
};
