import {
    ICellRendererComp,
    ICellRendererFunc,
    ICellRendererParams,
    RowNode,
} from '@ag-grid-community/core';
import { DraViewConfig } from '@gs-ux-uitoolkit-common/datacore';
import { CustomDRAOptions } from '../plugins/dra/dra-plugin';
import { ExtraDraTechnicalFields } from './technical-field-helper';

const KEY_ENTER = 13;
export const checkboxSelectionDatacy = 'gs-uitk-row-group-checkbox';
/**
 * This is our Custom RowGroupRenderer that we use when using a DRA connection
 * @param viewConfig The viewconfig coming from DRA connection
 * @param customDraOptions Some options specific to DRA in the Datagrid
 */
export const DraGroupRenderer = (
    viewConfig: DraViewConfig,
    customDraOptions: CustomDRAOptions,
    cellRenderer?: ICellRendererFunc | undefined
) => {
    return class GroupRenderer implements ICellRendererComp {
        public depth: number;
        public eGui: HTMLSpanElement;
        public params: ICellRendererParams | null;
        public isLeaf: boolean;
        public isExpanded: boolean;
        public isLoading: boolean;
        public groupName: string;
        public eExpanderExpanded: HTMLSpanElement | null = null;
        public eExpanderContracted: HTMLSpanElement | null = null;
        public eExpanderLoading: HTMLSpanElement | null = null;
        public eCheckbox: HTMLSpanElement | null = null;
        public checkboxRowSelection: boolean | undefined;
        public hideLeafCheckbox: boolean | undefined;
        public showRowGroupLeafName: boolean | undefined;
        constructor() {
            // create the cell
            this.eGui = document.createElement('span');
            this.params = null;
            this.isLeaf = false;
            this.depth = 0;
            this.isExpanded = false;
            this.isLoading = false;
            this.groupName = 'dummy';

            this.checkboxRowSelection = customDraOptions.checkboxSelection;
            this.hideLeafCheckbox = customDraOptions.hideLeafCheckbox;
            this.showRowGroupLeafName =
                customDraOptions.showRowGroupLeafName === undefined
                    ? true
                    : customDraOptions.showRowGroupLeafName;
        }
        public init(params: ICellRendererParams) {
            this.params = params;
            if (params.data) {
                this.isLeaf = params.data[viewConfig.isLeafField];
                this.depth = params.data[viewConfig.depthField];
                this.isExpanded = params.data[viewConfig.isExpandedField];
                this.groupName = params.data.TreeCol;

                this.eGui.innerHTML = this.createHtml(params);
                this.eExpanderExpanded = this.eGui.querySelector('.ag-group-expanded');
                this.eExpanderContracted = this.eGui.querySelector('.ag-group-contracted');
                this.eExpanderLoading = this.eGui.querySelector('.ag-group-loading');

                this.eCheckbox = this.eGui.querySelector('.ag-checkbox-input-wrapper');

                this.params.node.addEventListener(
                    RowNode.EVENT_EXPANDED_CHANGED,
                    this.onExpandCollapsed
                );

                params.eGridCell.addEventListener('keydown', this.onKeyDown);

                if (this.eExpanderExpanded && this.eExpanderContracted) {
                    // we attach the click event so we can tell the DRA source to expand the group
                    this.eExpanderExpanded.addEventListener('click', this.onClickGroup);
                    this.eExpanderContracted.addEventListener('click', this.onClickGroup);
                }

                this.setCheckbox(params.data[ExtraDraTechnicalFields.isSelectedField]);
            }
        }

        public createHtml(params: ICellRendererParams): string {
            const formattedGroupName = customDraOptions.treeColInnerRenderer
                ? customDraOptions.treeColInnerRenderer({ ...params, value: this.groupName })
                : cellRenderer
                  ? cellRenderer({ ...params, value: this.groupName })
                  : this.groupName;
            let html = ``;
            const checkboxHtml = `
                <span class="ag-group-checkbox" ref="eCheckbox">
                    <div class="ag-selection-checkbox">
                        <div role="presentation" ref="eCheckbox" class="ag-labeled ag-label-align-right ag-checkbox ag-input-field">
                            <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper" role="presentation">
                                <input ref="eInput" class="ag-input-field-input ag-checkbox-input" data-cy="${checkboxSelectionDatacy}" type="checkbox">
                            </div>
                        </div>
                    </div>
                </span>`;

            if (!this.isLeaf) {
                html = `<span class="ag-cell-wrapper ag-row-group-indent-${this.depth}">
                            <span class="ag-group-expanded ${
                                this.isExpanded ? '' : 'ag-hidden'
                            }" ref="eExpanded">
                                <span class="ag-icon ag-icon-tree-open"></span>
                            </span>
                            <span class="ag-group-contracted ${
                                this.isExpanded ? 'ag-hidden' : ''
                            }" ref="eContracted">
                                <span class="ag-icon ag-icon-tree-closed">
                                </span>
                            </span>
                            <span class="ag-group-loading ${
                                this.isLoading ? '' : 'ag-hidden'
                            }" ref="eLoading">
                                <span class="ag-stub-cell">
                                    <span class="ag-loading-icon" ref="eLoadingIcon">
                                        <span class="ag-icon ag-icon-loading" unselectable="on">
                                        </span>
                                    </span>
                                </span>
                            </span>
                            ${this.checkboxRowSelection ? checkboxHtml : ''}
                            <span class="ag-group-value" ref="eValue">${
                                formattedGroupName || ''
                            }</span>
                            <span class="ag-group-child-count ag-hidden" ref="eChildCount"></span></span>`;
            } else {
                // I am a leaf
                html = `<span class="ag-cell-wrapper ag-row-group-indent-${this.depth}"/>
                                        ${
                                            this.checkboxRowSelection && !this.hideLeafCheckbox
                                                ? checkboxHtml
                                                : ''
                                        }
                                        <span class="ag-group-contracted ag-hidden" ref="eContracted">
                                            <span class="ag-icon ag-icon-expanded"></span>
                                        </span>
                                        <span class="ag-group-value" ref="eValue">${
                                            (this.showRowGroupLeafName && formattedGroupName) || ''
                                        }</span>`;
            }
            return html;
        }

        public getGui() {
            return this.eGui;
        }

        public refresh() {
            // return false to tell the grid we want to recreate the cellRenderer.
            return false;
        }

        public destroy() {
            // do cleanup, remove event listener from button
            if (this.eExpanderExpanded && this.eExpanderContracted) {
                this.eExpanderExpanded.removeEventListener('click', this.onClickGroup);
                this.eExpanderContracted.removeEventListener('click', this.onClickGroup);
            }

            if (this.params) {
                this.params.node.removeEventListener(
                    RowNode.EVENT_EXPANDED_CHANGED,
                    this.onExpandCollapsed
                );
                this.params.eGridCell.removeEventListener('keydown', this.onKeyDown);
            }
        }

        public onClickGroup = (e: MouseEvent) => {
            // Don't want selecting an expander to trigger RowSelection
            e.stopPropagation();
            if (this.params) {
                this.params.node.setExpanded(!this.params.node.expanded);
            }
        };

        public onKeyDown = (event: KeyboardEvent): void => {
            const pressedKey = event.which || event.keyCode;
            if (this.params && this.params.column && pressedKey === KEY_ENTER) {
                const cellEditable = this.params.column.isCellEditable(this.params.node);
                if (cellEditable) {
                    return;
                }
                event.preventDefault();
                if (this.params) {
                    this.params.node.setExpanded(!this.params.node.expanded);
                }
            }
        };

        public onExpandCollapsed = () => {
            if (this.params) {
                if (this.eExpanderContracted && this.eExpanderExpanded) {
                    if (this.params.node.expanded) {
                        this.eExpanderExpanded.classList.remove('ag-hidden');
                        this.eExpanderContracted.classList.add('ag-hidden');
                    } else {
                        this.eExpanderExpanded.classList.add('ag-hidden');
                        this.eExpanderContracted.classList.remove('ag-hidden');
                    }
                }
                if (
                    this.eExpanderLoading &&
                    this.params.node.expanded !== this.params.data[viewConfig.isExpandedField]
                ) {
                    this.eExpanderLoading.classList.remove('ag-hidden');
                }
            }
        };

        public setCheckbox(checked: boolean): void {
            if (this.eCheckbox) {
                if (checked) {
                    this.eCheckbox.classList.add('ag-checked');
                } else {
                    this.eCheckbox.classList.remove('ag-checked');
                }
            }
        }
    };
};
