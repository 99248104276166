import { FC, memo } from 'react';
import { pStyleSheet } from '@gs-ux-uitoolkit-common/text';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { Text, TextProps } from './text';

/**
 * A wrapper around the Text component that applies the expected margins for the <p> tag
 */
export const P: FC<TextProps> = memo(props => {
    const classes = useStyleSheet(pStyleSheet, null);
    return (
        <Text
            tag="p"
            {...props}
            className={`${classes.p + (props.className ? ' ' + props.className : '')}`}
        >
            {props.children}
        </Text>
    );
});
P.displayName = 'P';
