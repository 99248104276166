import { QuickFilter } from '../../../plugin/quick-filter/quickfilter';
import {
    CancelEditedConfigItemAction,
    EditConfigItemAction,
    NewConfigItemAction,
    RemoveConfigItemAction,
    SaveEditedConfigItemAction,
    UpdateEditedConfigItemAction,
} from '../plugin-based-actions/plugin-based-actions';
import { QuickFilterActionKeys } from './quick-filter-action-keys';

export type QuickFilterActionTypes =
    | RemoveQuickFilterAction
    | NewQuickFilterAction
    | EditQuickFilterAction
    | UpdateEditedQuickFilterAction
    | ToggleEnabledQuickFilterAction
    | SetEnabledQuickFilterListAction
    | SaveEditedQuickFilterAction
    | CancelEditedQuickFilterAction
    | AddQuickFilterAction;

export interface RemoveQuickFilterAction extends RemoveConfigItemAction<QuickFilter> {
    type: QuickFilterActionKeys.REMOVE_QUICK_FILTER;
}

export const RemoveQuickFilter = (quickFilter: QuickFilter): RemoveQuickFilterAction => ({
    configItem: quickFilter,
    type: QuickFilterActionKeys.REMOVE_QUICK_FILTER,
});

export interface AddQuickFilterAction {
    type: QuickFilterActionKeys.ADD_QUICK_FILTER;
    quickFilter: QuickFilter;
}

export const AddQuickFilter = (quickFilter: QuickFilter): AddQuickFilterAction => ({
    quickFilter,
    type: QuickFilterActionKeys.ADD_QUICK_FILTER,
});

export interface EditQuickFilterAction extends EditConfigItemAction<QuickFilter> {
    type: QuickFilterActionKeys.EDIT_QUICK_FILTER;
}

export const EditQuickFilter = (quickFilter: QuickFilter): EditQuickFilterAction => ({
    configItem: quickFilter,
    type: QuickFilterActionKeys.EDIT_QUICK_FILTER,
});

export interface ToggleEnabledQuickFilterAction {
    type: QuickFilterActionKeys.TOGGLE_ENABLED_QUICK_FILTER;
    quickFilter: QuickFilter;
}

export const ToggleEnabledQuickFilter = (
    quickFilter: QuickFilter
): ToggleEnabledQuickFilterAction => ({
    quickFilter,
    type: QuickFilterActionKeys.TOGGLE_ENABLED_QUICK_FILTER,
});

export interface SetEnabledQuickFilterListAction {
    type: QuickFilterActionKeys.SET_ENABLED_QUICK_FILTER_LIST;
    quickFilterList: QuickFilter[];
}

export const SetEnabledQuickFilterList = (
    quickFilterList: QuickFilter[]
): SetEnabledQuickFilterListAction => ({
    quickFilterList,
    type: QuickFilterActionKeys.SET_ENABLED_QUICK_FILTER_LIST,
});

export interface NewQuickFilterAction extends NewConfigItemAction {
    type: QuickFilterActionKeys.NEW_QUICK_FILTER;
}

export const NewQuickFilter = (): NewQuickFilterAction => ({
    type: QuickFilterActionKeys.NEW_QUICK_FILTER,
});

export interface UpdateEditedQuickFilterAction extends UpdateEditedConfigItemAction<QuickFilter> {
    type: QuickFilterActionKeys.UPDATE_EDITED_QUICK_FILTER;
}

export const UpdateEditedQuickFilter = (
    QuickFilterUpdatedPart: Partial<QuickFilter>
): UpdateEditedQuickFilterAction => ({
    configItemUpdatedPart: QuickFilterUpdatedPart,
    type: QuickFilterActionKeys.UPDATE_EDITED_QUICK_FILTER,
});

export interface SaveEditedQuickFilterAction extends SaveEditedConfigItemAction {
    type: QuickFilterActionKeys.SAVE_EDITED_QUICK_FILTER;
}

export const SaveEditedQuickFilter = (): SaveEditedQuickFilterAction => ({
    type: QuickFilterActionKeys.SAVE_EDITED_QUICK_FILTER,
});

export interface CancelEditedQuickFilterAction extends CancelEditedConfigItemAction {
    type: QuickFilterActionKeys.CANCEL_EDITED_QUICK_FILTER;
}

export const CancelEditedQuickFilter = (): CancelEditedQuickFilterAction => ({
    type: QuickFilterActionKeys.CANCEL_EDITED_QUICK_FILTER,
});
