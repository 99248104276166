import { Component } from 'react';
import { ColumnHintFormatSection } from './column-hint-format-section';
import { Row, Col } from '@gs-ux-uitoolkit-react/layout';
import { ColumnHintControlHyperlink } from '../formatControls/column-hint-control-hyperlink';
import { FormatControlProps } from '../column-hint-format-manager';
import { ColumnHintLayoutSection } from '../../../../../components/column-hint/layouts-section';
import { ColumnHintFontsSection } from '../../../../../components/column-hint/fonts-section';
import { ColumnHintColoursSection } from '../../../../../components/column-hint/colours-section';
import { ColumnHintOtherSection } from '../../../../../components/column-hint/other-section';

export class ColumnHintAllFormat extends Component<FormatControlProps> {
    public render() {
        return (
            <div>
                <ColumnHintLayoutSection
                    columnHint={this.props.columnHint}
                    onChangeHint={this.props.onChangeHint}
                />

                <ColumnHintFontsSection
                    columnHint={this.props.columnHint}
                    onChangeHint={this.props.onChangeHint}
                />
                <ColumnHintColoursSection
                    columnHint={this.props.columnHint}
                    onChangeHint={this.props.onChangeHint}
                />
                <ColumnHintFormatSection title="Hyperlinks">
                    <Row>
                        <Col sm={12}>
                            <ColumnHintControlHyperlink
                                columnHint={this.props.columnHint}
                                onChangeHint={this.props.onChangeHint}
                            />
                        </Col>
                    </Row>
                </ColumnHintFormatSection>
                <ColumnHintOtherSection
                    columnHint={this.props.columnHint}
                    onChangeHint={this.props.onChangeHint}
                />
            </div>
        );
    }
}
