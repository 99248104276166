import { ExpressionValue } from '@gs-ux-uitoolkit-common/dra';
import { CrossModelActionKeys } from './action-constants';

export type AllActionTypes =
    | CreateControllerStateAction
    | CreateComponentStateAction
    | UpdateComponentRecordSelectionAction;

export interface CreateControllerStateAction {
    type: CrossModelActionKeys.CREATE_CONTROLLER_STATE;
    controllerName: string;
}

export const CreateControllerState = (controllerName: string): CreateControllerStateAction => ({
    controllerName,
    type: CrossModelActionKeys.CREATE_CONTROLLER_STATE,
});

export interface CreateComponentStateAction {
    type: CrossModelActionKeys.CREATE_COMPONENT_STATE;
    controllerName: string;
    componentId: string;
}

export const CreateComponentState = (
    controllerName: string,
    componentId: string
): CreateComponentStateAction => ({
    componentId,
    controllerName,
    type: CrossModelActionKeys.CREATE_COMPONENT_STATE,
});

export interface UpdateComponentRecordSelectionAction {
    type: CrossModelActionKeys.UPDATE_COMPONENT_ROW_SELECTION;
    controllerName: string;
    componentId: string;
    selectedRecordIdList: string[];
    selectedRecordIndexList: number[];
    selectedRecordCompositeIdList: { [key: string]: ExpressionValue }[];
}

export type UpdateComponentRecordSelectionType = (
    controllerName: string,
    componentId: string,
    selectedRecordIdList: string[],
    selectedRecordIndexList: number[],
    selectedRecordCompositeIdList: { [key: string]: ExpressionValue }[]
) => UpdateComponentRecordSelectionAction;

export const UpdateComponentRecordSelection: UpdateComponentRecordSelectionType = (
    controllerName: string,
    componentId: string,
    selectedRecordIdList: string[],
    selectedRecordIndexList: number[],
    selectedRecordCompositeIdList: { [key: string]: ExpressionValue }[]
) => ({
    componentId,
    controllerName,
    selectedRecordCompositeIdList,
    selectedRecordIdList,
    selectedRecordIndexList,
    type: CrossModelActionKeys.UPDATE_COMPONENT_ROW_SELECTION,
});
