export default {
  APP_INITIAL_LOAD: 'APP_INITIAL_LOAD',
  APP_LOADING: 'APP_LOADING',
  ADD_APP_DATA: 'ADD_APP_DATA',
  CLEAR_APP_DATA: 'CLEAR_APP_DATA',
  ADD_APP_CONTEXT: 'ADD_APP_CONTEXT',
  CLEAR_APP_CONTEXT: 'CLEAR_APP_CONTEXT',
  UPDATE_SELECTED_ROWS: 'UPDATE_SELECTED_ROWS',
  CHANGE_ACTIVE_TRADE_ROW: 'CHANGE_ACTIVE_TRADE_ROW',
  UPDATE_ACTIVE_TRADE: 'UPDATE_ACTIVE_TRADE',
  UPDATE_TRADE_OPTIONS: 'UPDATE_TRADE_OPTIONS',
  UPDATE_DRAFT_TRADES: 'UPDATE_DRAFT_TRADES',
  DRAFT_TRADES_LOADING: 'DRAFT_TRADES_LOADING',
  TRADE_OPTIONS_LOADING: 'TRADE_OPTIONS_LOADING',
  UPDATE_VALID_TRADES: 'UPDATE_VALID_TRADES',
  UPDATE_COMPLETE_TRADES: 'UPDATE_COMPLETE_TRADES',
  UPDATE_RETRIEVE_QUEUED_STATUS: 'UPDATE_RETRIEVE_QUEUED_STATUS',
  BLOTTER_DIRTY_STATUS: 'BLOTTER_DIRTY_STATUS',
  SAVING_TRADES_IN_PROGRESS: 'SAVING_TRADES_IN_PROGRESS',
  VERIFY_TRADES_IN_PROGRESS: 'VERIFY_TRADES_IN_PROGRESS',
  SAVE_QUEUED_STATUS: 'SAVE_QUEUED_STATUS',
  SHOW_NEW_ACCOUNT_MODAL: 'SHOW_NEW_ACCOUNT_MODAL',
  CLOSE_NEW_ACCOUNT_MODAL: 'CLOSE_NEW_ACCOUNT_MODAL',
  SHELL_ACCOUNT_OPTIONS_LOADING: 'SHELL_ACCOUNT_OPTIONS_LOADING',
  LOGOUT: 'LOGOUT',
  CACHED_TRADE_OPTIONS_LOADING: 'CACHED_TRADE_OPTIONS_LOADING',
  UPDATE_CACHED_TRADE_OPTIONS: 'UPDATE_CACHED_TRADE_OPTIONS',
  TRADE_INPUTS_LOADING: 'TRADE_INPUTS_LOADING',
  PLACING_TRADES_IN_PROGRESS: 'PLACING_TRADES_IN_PROGRESS',
  SHOW_TRADE_SAVE_FAIL_SNACKBAR: 'SHOW_TRADE_SAVE_FAIL_SNACKBAR',
  SHOW_TRADE_BLOTTER_SNACKBAR: 'SHOW_TRADE_BLOTTER_SNACKBAR',
  SHOW_NEW_ACCOUNT_MODAL_SNACKBAR: 'SHOW_NEW_ACCOUNT_MODAL_SNACKBAR',
  UPDATE_MESSAGES: 'UPDATE_MESSAGES',
  UPDATE_MESSAGE_COUNT: 'UPDATE_MESSAGE_COUNT',
  UPDATE_PENDING_TRADES_FOR_APPROVAL_FLAG: 'UPDATE_PENDING_TRADES_FOR_APPROVAL_FLAG',
  PENDING_TRADES_FOR_APPROVAL_FLAG_DATA_LOADING: 'PENDING_TRADES_FOR_APPROVAL_FLAG_DATA_LOADING',
  FETCH_THIRD_PARTY_REDIRECT_URL: 'FETCH_THIRD_PARTY_REDIRECT_URL',
  CLEARWATER_SSO_OUTBOUND: 'CLEARWATER_SSO_OUTBOUND',
  IS_SESSION_EXPIRED: 'IS_SESSION_EXPIRED',
  STOP_POLLING_TICKER_API: 'STOP_POLLING_TICKER_API',
  UPDATE_SHELL_ACCOUNT_STATE: 'UPDATE_SHELL_ACCOUNT_STATE',
  ADD_SHELL_ACCOUNT_CONFIGS: 'ADD_SHELL_ACCOUNT_CONFIGS',
  ADD_REPORTING_PERMIT_ROLES: 'ADD_REPORTING_PERMIT_ROLES',
  IS_TMX_PROFILING_COMPLETE: 'IS_TMX_PROFILING_COMPLETE',
  REFRESH_PAGA_DATA: 'REFRESH_PAGA_DATA',
  UPDATE_TRADE_INLINE_WARNING: 'UPDATE_TRADE_INLINE_WARNING',
  SET_GLOBAL_ERROR: 'SET_GLOBAL_ERROR'
};
