export const analyticsEventName = {
  SAVE_PREFERENCES: 'save_preferences'
};

export const saveDisplaySettingsPreferences = (labels) => {
  return {
    event: {
      valueChosen: labels.join('|')
    }
  };
};

export const saveEmailNotificationPreferences = (preferenceData) => {
  const data = Object.keys(preferenceData.emailNotificationSettings).filter(key => {
    return !!preferenceData.emailNotificationSettings[key].isChecked;
  });
  return {
    event: {
      valueChosen: data.join('|')
    }
  };
};

export const onPreferencesPageLoadAnalytics = () => {
  return {
    event: {
      eventName: 'pageLoad'
    }
  };
};
