import {
    ProcessCellForExportParams,
    ValueFormatterParams,
    ValueGetterParams,
} from '@ag-grid-community/core';
import { DataType, logger } from '@gs-ux-uitoolkit-common/datacore';
import { GridColumn } from '../grid-wrappers/grid-wrapper';
import { ExportOptions } from '../plugins/exports/export';
import { CellRendererValue } from '../redux/datagrid-state';

type ValueGetterFormatterType =
    | string
    | ((params: ValueGetterParams | ValueFormatterParams) => any);

// TODO Make evaluateValueFormatter mandatory
export const processCellForExport = (
    paramsCell: ProcessCellForExportParams,
    exportOptions: ExportOptions | null,
    columnList: GridColumn[],
    appFunc?: (params: ProcessCellForExportParams) => string,
    evaluateValueFormatter?: (
        valueFormatter: ValueGetterFormatterType,
        params: ValueGetterParams | ValueFormatterParams,
        useExpressionEvaluator?: boolean | undefined
    ) => any
) => {
    if (paramsCell.api && paramsCell.node) {
        let value: string | number = paramsCell.api.getValue(
            paramsCell.column.getColId(),
            paramsCell.node
        );
        const valueFormatter = paramsCell.column.getColDef().valueFormatter as
            | string
            | ((params: ValueFormatterParams | ValueGetterParams) => any);

        const shouldIncludeFormatting = exportOptions?.includeFormatting;

        // if either a hyperlink render or a dra row group renderer is set on the column then the value will be a serialised object.
        // We need to get the value from the json object
        if (typeof value === 'string' && value.includes('"columnValue":')) {
            let columnData: CellRendererValue = {
                columnValue: value,
            };
            try {
                const dataJson = JSON.parse(value);
                if (typeof dataJson === 'object') {
                    columnData = dataJson;
                    if (value.includes('"url":')) {
                        return shouldIncludeFormatting
                            ? `=HYPERLINK("${columnData.url}", "${columnData.columnValue}")`
                            : columnData.url;
                    }
                }
            } catch {
                // Couldn't convert params value for this column
                logger.warn('could not parse row group value');
            }
            return columnData.columnValue;
        }

        const column = columnList.find(col => col.columnId === paramsCell.column.getColId());
        if (column && column.dataType === DataType.Number && typeof value === 'string') {
            const numberValue = Number(value.replace(/,/g, '').replace(/ /g, ''));
            if (!isNaN(numberValue)) {
                value = numberValue;
            }
        }
        let processedValue = value;
        if (shouldIncludeFormatting && evaluateValueFormatter && valueFormatter) {
            processedValue = evaluateValueFormatter(valueFormatter, {
                ...paramsCell,
                data: paramsCell.node.data,
                node: paramsCell.node,
                colDef: paramsCell.column.getColDef(),
            });
        }
        return appFunc ? appFunc(paramsCell) : processedValue;
    }
    return appFunc ? appFunc(paramsCell) : paramsCell.value;
};
