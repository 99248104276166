// TODO remove onKeyDown when following Flatpickr issue is resolved: https://github.com/flatpickr/flatpickr/issues/1329
// Temporary fix for Datepicker/Flatpickr keyboard navigation issues
import { onKeyDown } from './date-picker-navigation-helper';

// tslint:disable:no-submodule-imports
import { weekdayHover } from '../plugins/weekday-hover';

import confirmDate, { Config } from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
// @ts-expect-error: needed because api-extractor can not parse dynamic imports in .d.ts files
import { DatePickerOptions, BaseOptionsModified } from '../options/date-picker-options';

const hasOwnProperty = (obj: any, prop: string) => Object.prototype.hasOwnProperty.call(obj, prop);

const confirmDateConfig: Config = {
    confirmIcon: '',
    confirmText: 'Done',
};

export function datepickerOptions(userOptions: DatePickerOptions) {
    let options: DatePickerOptions = {};
    options = { ...userOptions };

    if (!hasOwnProperty(userOptions, 'allowInput')) {
        options.allowInput = true;
    }

    if (!hasOwnProperty(userOptions, 'locale')) {
        options.locale = {
            firstDayOfWeek: 1,
        } as any; // flatpickr options types for locale require weekdays and months props as non-optional
    }

    if (!hasOwnProperty(userOptions, 'dateFormat')) {
        // Date format is "4 Mar 2021" according to GSDS spec, with no leading 0's for the day
        const dateFormat = userOptions.enableTime ? 'j M Y H:i' : 'j M Y';
        options.dateFormat = dateFormat;
        options.ariaDateFormat = dateFormat;
    }

    options.altFormat = userOptions.altFormat || (userOptions.enableTime ? 'd M Y H:i' : 'd M Y');
    options.animate = false;
    options.onKeyDown = onKeyDown;
    options.weekNumbers = false; // always disable week numbers for styling purposes; we've never had it active but keeping true if set messes up styling

    options.plugins = [
        ...(userOptions.plugins ? userOptions.plugins : []),
        weekdayHover(),
        confirmDate(confirmDateConfig),
    ];

    return options;
}
