/**
 * Simple implementation of the `String.prototype.startsWith` function for
 * environments that don't support it.
 *
 * This allows us to support older browsers such as IE11.
 *
 * @param targetString The string to search on (ie. the one that we will test)
 * @param searchString The string to search (ie. the pattern we are looking for)
 */
export function startsWith(targetString: string, searchString: string) {
    return targetString.slice(0, searchString.length) === searchString;
}

/**
 * Capitalizes the first letter of a string.
 */
export function upperFirst(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Given the arguments of a tagged template function, recombines the original string.
 *
 * Example Usage:
 *
 *     // Client (call site) usage
 *     myTaggedTemplateFn`Some ${value}`
 *
 *     // Tagged template function
 *     function myTaggedTemplateFn(
 *         strings: TemplateStringsArray,
 *         ...args: string[]
 *     ) {
 *         return recombineTaggedTemplateStr(strings, args);  // Simply return the original string
 *     }
 *
 * @param strings The TemplateStringsArray provided to a tagged template function.
 * @param args The interpolated values provided to a tagged template function.
 */
export function recombineTaggedTemplateStr(strings: TemplateStringsArray, args: string[]) {
    const strArr: string[] = [];

    // Piece the original string back together
    for (let i = 0, len = strings.length, argsLen = args.length; i < len; i++) {
        strArr.push(strings[i]);
        if (i < argsLen) strArr.push(args[i]);
    }

    return strArr.join('');
}

const upperCaseLetterRe = /[A-Z]/;
const lowerCaseLetterRe = /[a-z]/;
const digitRe = /\d/;

/**
 * Converts the given string to kebab-case
 *
 *     kebabCase('Foo Bar')
 *     // => 'foo-bar'
 *
 *     kebabCase('fooBar')
 *     // => 'foo-bar'
 *
 *     kebabCase('__FOO_BAR__')
 *     // => 'foo-bar'
 *
 * @param str String to convert to kebab-case
 */
export function kebabCase(str: string): string {
    // Developer's note: this method was previously implemented with a series
    // of .replace() calls. Switching it to a state machine parser sped this up
    // by a factor of 2.5x. Because this method is used so heavily by our
    // CSS-in-JS implementation, we want it as fast as possible.
    //
    // Previous implementation:
    //    return str
    //        .replace(/(\d)(\D)/g, (_, a, b) => `${a}-${b}`)
    //        .replace(/(\D)(\d)/g, (_, a, b) => `${a}-${b}`)
    //        .replace(/([a-z])([A-Z])/g, (_, a, b) => `${a}-${b.toLowerCase()}`)
    //        .toLowerCase()
    //        .replace(/[^a-z0-9]/g, '-')
    //        .replace(/-{2,}/g, '-')
    //        .replace(/^-|-$/g, '');
    //
    // Benchmark.js results:
    //     replace() x 40,890 ops/sec ±0.49% (92 runs sampled)
    //     State machine x 101,787 ops/sec ±0.44% (94 runs sampled)

    if (str.length === 0) return str;

    let result = '';
    let state = KebabCaseState.Start as KebabCaseState;

    for (let i = 0, len = str.length; i < len; i++) {
        const char = str.charAt(i);

        switch (state) {
            case KebabCaseState.Start:
                handleStateStart(char);
                break;
            case KebabCaseState.LowerCaseLetter:
                handleStateLowerCaseLetter(char);
                break;
            case KebabCaseState.UpperCaseLetter:
                handleStateUpperCaseLetter(char);
                break;
            case KebabCaseState.Digit:
                handleStateDigit(char);
                break;
            case KebabCaseState.Dash:
                handleStateDash(char);
                break;
        }
    }

    // Remove any trailing dash
    if (result.charAt(result.length - 1) === '-') {
        result = result.slice(0, result.length - 1);
    }
    return result;

    function handleStateStart(char: string) {
        if (lowerCaseLetterRe.test(char)) {
            result = char;
            state = KebabCaseState.LowerCaseLetter;
        } else if (upperCaseLetterRe.test(char)) {
            result = char.toLowerCase();
            state = KebabCaseState.UpperCaseLetter;
        } else if (digitRe.test(char)) {
            result = char;
            state = KebabCaseState.Digit;
        }
        // Simply ignore any non letter or digit character at the start of the string
    }

    function handleStateLowerCaseLetter(char: string) {
        if (lowerCaseLetterRe.test(char)) {
            result += char;
        } else if (upperCaseLetterRe.test(char)) {
            result += '-' + char.toLowerCase();
            state = KebabCaseState.UpperCaseLetter;
        } else if (digitRe.test(char)) {
            result += '-' + char;
            state = KebabCaseState.Digit;
        } else {
            result += '-';
            state = KebabCaseState.Dash;
        }
    }

    function handleStateUpperCaseLetter(char: string) {
        if (lowerCaseLetterRe.test(char)) {
            result += char;
            state = KebabCaseState.LowerCaseLetter;
        } else if (upperCaseLetterRe.test(char)) {
            result += char.toLowerCase();
        } else if (digitRe.test(char)) {
            result += '-' + char;
            state = KebabCaseState.Digit;
        } else {
            result += '-';
            state = KebabCaseState.Dash;
        }
    }

    function handleStateDigit(char: string) {
        if (lowerCaseLetterRe.test(char)) {
            result += '-' + char;
            state = KebabCaseState.LowerCaseLetter;
        } else if (upperCaseLetterRe.test(char)) {
            result += '-' + char.toLowerCase();
            state = KebabCaseState.UpperCaseLetter;
        } else if (digitRe.test(char)) {
            result += char;
        } else {
            result += '-';
            state = KebabCaseState.Dash;
        }
    }

    function handleStateDash(char: string) {
        if (lowerCaseLetterRe.test(char)) {
            result += char;
            state = KebabCaseState.LowerCaseLetter;
        } else if (upperCaseLetterRe.test(char)) {
            result += char.toLowerCase();
            state = KebabCaseState.UpperCaseLetter;
        } else if (digitRe.test(char)) {
            result += char;
            state = KebabCaseState.Digit;
        }
        // successive non-alphanumeric characters, ignore and stay in Dash state
    }
}

const enum KebabCaseState {
    Start,
    UpperCaseLetter,
    LowerCaseLetter,
    Digit,
    Dash,
}

/**
 * A rated string match
 */
interface Ratings {
    target: string;
    rating: number;
}

/**
 * Compares two strings and returns a rating of how equivalent they are based on a dice coefficient.
 * Extracted from the string similiarity utility: : https://www.npmjs.com/package/string-similarity#403
 *
 * @param first First string to compare
 * @param second second string to compare
 * @return The similarity score of the two strings based on a dice coefficient.
 * Dice's coefficient measures how similar a set and another set are, and is used to
 * measure how similar two strings are in terms of the number of common bigrams
 * (a bigram is a pair of adjacent letters in the string).
 */
function findStringSimilarityScore(first: string, second: string): number {
    first = first.replace(/\s+/g, '');
    second = second.replace(/\s+/g, '');

    if (first === second) return 1; // identical or empty
    if (first.length < 2 || second.length < 2) return 0; // if either is a 0-letter or 1-letter string

    const firstBigrams = new Map();
    for (let i = 0; i < first.length - 1; i++) {
        const bigram = first.substring(i, i + 2);
        const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1;

        firstBigrams.set(bigram, count);
    }

    let intersectionSize = 0;
    for (let i = 0; i < second.length - 1; i++) {
        const bigram = second.substring(i, i + 2);
        const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0;

        if (count > 0) {
            firstBigrams.set(bigram, count - 1);
            intersectionSize++;
        }
    }

    return (2.0 * intersectionSize) / (first.length + second.length - 2);
}

/**
 * Returns the best matched string from an array of strings. Dervied from the string-similiarity utility: https://www.npmjs.com/package/string-similarity#403
 * @param mainString String to find best match of
 * @param stringsToMatch Array of strings to match from
 * @return The closest matching string based on Dice's coefficent. See {@link #findStringSimilarity} for info about this value.
 */
export function findBestStringMatch(mainString: string, stringsToMatch: string[]): string {
    const ratings: Ratings[] = [];
    let bestMatchIndex = 0;

    for (let i = 0; i < stringsToMatch.length; i++) {
        const currentTargetString = stringsToMatch[i];
        const currentRating = findStringSimilarityScore(mainString, currentTargetString);
        ratings.push({ target: currentTargetString, rating: currentRating });
        if (currentRating > ratings[bestMatchIndex].rating) {
            bestMatchIndex = i;
        }
    }

    return ratings[bestMatchIndex].target;
}
