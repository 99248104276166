import React from 'react';
import PropTypes from 'prop-types';
import RadioButton from '../../../../../core/RadioButton';
import './index.scss';
const GuidelinesRadioGroup = ({options, onChange}) => (
  <div className='guidelines-item__radio-group'>
    {
      options.map((data, index) => (
        <div>
          <RadioButton
            id={data.id}
            changeHandler={onChange}
            label={data.label}
            value={data.value}
            key={index}
            isChecked={data.isChecked}
            isDisabled={data.isDisabled}
            name={data.name}
          />
        </div>
          ))
    }
  </div>
);

GuidelinesRadioGroup.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
};

export default GuidelinesRadioGroup;

