import actionTypes from '../../actions/preferences/actionTypes';
import { gridActionTypes, withId as curretTrades } from '../../modules/Grid/actionsCreator';
import { MODULES_KEYS } from '../../constants/pageConstants';
const defaultPreferance = {};


export function preferencesReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.ADD_APP_PREFERENCE:
    case actionTypes.EDIT_APP_PREFERENCE:
    case actionTypes.ADD_SAVED_PREFERENCES:
      return {...state, ...action.data};
    case actionTypes.CLEAR_APP_PREFERENCE:
      return defaultPreferance;
    case actionTypes.TOGGLE_ZBP:
      return {...state, portfolio: {...state.portfolio, ...action.data}};
    case actionTypes.TOGGLE_COLLAPSE:
      return {...state, portfolio: {...state.portfolio, ...action.data}};
    case curretTrades(gridActionTypes.GRID_TOGGLE_GROUP_COLLAPSE, MODULES_KEYS.CURRENT_TRADES):
      return {...state, currentTradeStatus: { ...state.currentTradeStatus, isCollapsedAll: action.data}};
    case actionTypes.FIRM_PREFERENCES_CHANGED:
      return {...state, global: {...state.global, selectedFirms: action.data}};
    case actionTypes.TOGGLE_INST_SHARE_CLASSES_ONLY:
    case actionTypes.TOGGLE_OPEN_ACCOUNT_ONLY:
    case actionTypes.MANAGERS_PREFERENCE_CHANGE:
    case actionTypes.FUND_TYPES_PREFERENCE_CHANGE:
    case actionTypes.FUND_CURRENCIES_PREFERENCE_CHANGE:
    case actionTypes.ESG_FUND_PREFERENCE_CHANGE:
      return {...state, fundFinder:{...state.fundFinder, ...action.data}};
    case actionTypes.TRADE_TEMPLATE_PREFERENCE_CHANGE:
      return {...state, global: {...state.global, selectedTradeTemplateId: action.data}};
    case actionTypes.FUND_TRACKER_PREFERENCE_CHANGE:
      return {...state, fundTracker: {...state.fundTracker, ...action.data}};
    case actionTypes.TOGGLE_COLLAPSE_REPORT:
      return {...state, report: {...state.report, ...action.data}}; 
    default:
      return state;
  }
}
