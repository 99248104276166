export const oldKeyMapping = {
  id: 'value',
  name: 'label'
};


export const  PRESET_VIEWS = {
  MONEY_MARKET: {
    id: '0000000f0000000f0000000f',
    name: 'Money Market'
  },
  SHORT_DURATION: {
    id: '0000002e0000002e0000002e',
    name: 'Short Duration'
  }
};

export const DEFAULT_VIEW = PRESET_VIEWS.MONEY_MARKET;

export const DEFAULT_GROUP = {
  value: '',
  label: 'NONE'
};

export const VIEW_ACTION = {
  name: 'tkCustomView', id:'newView'
};
export const MAX_VIEWS = {
  name: 'tkCopyPort11', id:'maxViews'
};

export const PORTFOLIO_FILTER_STATE = {
  VISIBLE: 'visible',
  HIDDEN: 'hidden'
};

export const REPORTING_FILTER_STATE = {
  VISIBLE: 'visible',
  HIDDEN: 'hidden'
};

export const COLUMN_TYPE = {
  NUMERIC : 'numericSortColumn'
};

export const CELL_RENDERER = {
  highlightCellRenderer: 'highlightCellRenderer'
}

export const COLUMN_COLID = {
  TRADE: 'canTrade',
  GROUP: 'ag-Grid-AutoColumn',
  SUBJECT_TO_FEE_OR_GATE:'subjectToFeeOrGate',
  INVEST_ONLY : 'investOnly',
  INCEPTION_DATE : 'inceptionDate',
  NAV_SCHEDULE : 'navSchedule',
  NAV_AS_OF_TIME : 'navAsOfTime',
  PERFORMANCE_AS_OF: 'performanceAsOfTime',
  ASSETS_AS_OF: 'totalFundAssetsAsOfTime',
  DURATION_AS_OF: 'durationAsOfTime',
  LIQUIDITY_ASSETS_AS_OF : 'liquidityAsOfDate',
  HOME_AWAY: 'homeAway',
  ADJUSTED_ONE_DAY_YIELD: 'adjustedOneDayYield',
  ADJUSTED_SEVEN_DAY_CURR_YIELD: 'adjustedSevenDayCurYield',
  ADJUSTED_SEVEN_DAY_EFF_YIELD: 'adjustedSevenDayEffYield',
  ADJUSTED_THIRTY_DAY_YIELD: 'adjustedThirtyDayYield',

};

export const SORT_DATE_TYPE = {
  string: 'string',
  number: 'number',
  date: 'date',
  checkbox: 'checkbox',
  AM: 'AM'
};

export const MODEL_ACTION = {
  EDIT: 'edit',
  CREATE: 'create',
  SELECT_COLUMNS: 'select_columns'
};

export const BOOLEAN ={
  YES: 'Yes',
  NO: 'No',
  FALSE: false,
  TRUE: true,
  STRTRUE: 'true',
  STRFALSE: 'false'
};

export const GROUP_LABEL_KEY = 'label';
export const EXTERNAL_LINK_SKIP_ATTRIBUTE = 'data-skipexternallinkevent';

export const SORT = {
  ASC: 'asc',
  DESC: 'desc'
};

export const TRADE_COLUMN_CONFIG = {
  colId: COLUMN_COLID.TRADE,
  field: COLUMN_COLID.TRADE
};

export const GROUP_COLUMN_CONFIG = {
  colId: COLUMN_COLID.GROUP,
  field: COLUMN_COLID.GROUP
};

export const LOCKED_COLUMN_CONFIG = {
  pinned: 'left',
  lockPinned: true,
  lockPosition: true,
  suppressMenu: true,
  lockVisible: true
};

export const RESET_PINNED_COLUMN_CONFIG = {
  pinned: 'null'
};

export const GROUP_NO_LABEL = ['GROUP_UNDEFINED', 'NO_POLICY_SCOPE'];
export const GROUP_AGGREGATOR_SPLITTER = '|#|&|#|';

export const FUND_TYPE = {
  MONEY_MARKET : 'Money Market'
};

export const FUND_DOMICILE = {
  US : 'US'
};

export const INVESTMENT_TYPE = {
  SHORT_DURATION : 'Short Duration'
};

export const NAV_TYPE = {
  FNAV: 'Floating NAV',
  FNAV_SINGLE_STRIKE: 'Floating NAV - Single Strike',
  FNAV_MULTIPLE_STRIKE: 'Floating NAV - Multiple Strike'
};

export const portfolioAnalytics = {
  EVENT_GRID_START_LOAD: 'start_load',
  EVENT_GRID_LOAD: 'load',
  EVENT_COLUMN_REARRANGE: 'rearrange',
  EVENT_COLUMN_SORTING: 'sorting',
  EVENT_COLUMN_SEARCH: 'search',
  EVENT_COLUMN_REMOVE: 'drag_remove',
  EVENT_GROUP_CHANGE: 'group_by',
  EVENT_VIEW_CHANGE: 'view_change',
  EVENT_EXPAND_COLLAPSE: 'expand_collapse',
  EVENT_EXPORT_VIEW: 'export_view',
  EVENT_ADD_TO_BLOTTER: 'add_to_blotter',
  EVENT_VIEW_SAVE: 'view_save',
  EVENT_FUND_FACT_OPEN: 'fund_fact_resource_open',
  EVENT_FUND_FACT_CLOSE: 'fund_fact_resource_close',
  EVENT_FUND_FACT_RESOURCE: 'fund_fact_resource',
  EVENT_ACCOUNT_DETAILS_FACT_OPEN: 'account_details_fact_card_open',
  EVENT_ACCOUNT_DETAILS_CLOSE: 'account_details_fact_card_close',
  EVENT_ZERO_BALANCE_FUNDS: 'zero_balance_funds'
};

export const fundFinderAnalytics = {
  EVENT_COLUMN_REARRANGE: 'rearrange',
  EVENT_COLUMN_REMOVE: 'drag_remove',
  EVENT_VIEW_CHANGE: 'view_change',
  EVENT_MANAGERS_CHANGE: 'manager',
  EVENT_CURRENCIES_CHANGE: 'currencies',
  EVENT_FUND_TYPES_CHANGE: 'fund_types',
  EVENT_COLUMN_SORTING: 'sorting',
  EVENT_COLUMN_SEARCH: 'search',
  EVENT_GRID_START_LOAD: 'start_load',
  EVENT_GRID_LOAD: 'load',
  EVENT_EXPORT_VIEW: 'export_view',
  EVENT_VIEW_SAVE: 'view_save',
  EVENT_SEARCH_COLUMN_0_RESULTS : 'column_search_0_results'
};

export const reportingAnalytics = {
  EVENT_GRID_START_LOAD: 'start_load',
  EVENT_GRID_LOAD: 'load',
  EVENT_COLUMN_REARRANGE: 'rearrange',
  EVENT_COLUMN_SORTING: 'sorting',
  EVENT_COLUMN_SEARCH: 'search',
  EVENT_COLUMN_REMOVE: 'drag_remove',
  EVENT_GROUP_CHANGE: 'group_by',
  EVENT_VIEW_CHANGE: 'view_change',
  EVENT_EXPAND_COLLAPSE: 'expand_collapse',
  EVENT_EXPORT_VIEW: 'export_view',
  EVENT_ADD_TO_BLOTTER: 'add_to_blotter',
  EVENT_VIEW_SAVE: 'view_save',
  EVENT_FUND_FACT_OPEN: 'fund_fact_resource_open',
  EVENT_FUND_FACT_CLOSE: 'fund_fact_resource_close',
  EVENT_FUND_FACT_RESOURCE: 'fund_fact_resource',
  EVENT_ZERO_BALANCE_FUNDS: 'zero_balance_funds'
};

export const reportAnalytics = {
  EVENT_GRID_START_LOAD: 'start_load',
  EVENT_GRID_LOAD: 'load',
  EVENT_COLUMN_REARRANGE: 'rearrange',
  EVENT_COLUMN_SORTING: 'sorting',
  EVENT_COLUMN_SEARCH: 'search',
  EVENT_COLUMN_REMOVE: 'drag_remove',
  EVENT_GROUP_CHANGE: 'group_by',
  EVENT_VIEW_CHANGE: 'view_change',
  EVENT_EXPAND_COLLAPSE: 'expand_collapse',
  EVENT_EXPORT_VIEW: 'export_view',
  EVENT_ADD_TO_BLOTTER: 'add_to_blotter',
  EVENT_VIEW_SAVE: 'view_save',
  EVENT_FUND_FACT_OPEN: 'fund_fact_resource_open',
  EVENT_FUND_FACT_CLOSE: 'fund_fact_resource_close',
  EVENT_FUND_FACT_RESOURCE: 'fund_fact_resource',
  EVENT_ZERO_BALANCE_FUNDS: 'zero_balance_funds'
};

const pageConstants = {
  oldKeyMapping,
  DEFAULT_GROUP,
  DEFAULT_VIEW,
  VIEW_ACTION,
  PRESET_VIEWS,
  PORTFOLIO_FILTER_STATE,
  LOCKED_COLUMN_CONFIG,
  GROUP_COLUMN_CONFIG,
  COLUMN_TYPE,
  COLUMN_COLID,
  GROUP_NO_LABEL,
  MODEL_ACTION,
  MAX_VIEWS,
  SORT,
  BOOLEAN
};

export const SHOW_ERROR_ON = {
  'PAGE': 'page',
  'FUND_FACT_MODAL': 'fund_fact_card_modal',
  'CUSTOM_VIEW_MODAL': 'custom_view_modal',
  'SNACKBAR': 'snackbar',
  'TRADE_REJECTION_MODAL': 'trade_rejection_modal',
  'INSIDE_GRID' :'within_portfolio_grid',
  'IMPORT_TRADES_MODAL' :'import_trades_modal',
  'INSIDE_REPORTS_GRID': 'inside_reports_grid'
};

export const LOGIN_NEXT_STEP_ERROR = {
  'CHECK_CREDENTIAL': 'tkEmailOrPasswordIncorrect',
  'NEXT_CODE_REQUIRED': 'tkCodeExpired',
  'WARN_INPUT_NOT_VALID': 'tkUsernameOrPasswordInvalid',
  'WARN_TRY_AGAIN': 'tkSomethingWentWrong',
  'CHANGE_PASSWORD': 'tkAnErrorOccurred',
  'PING_DOWN': 'tkAnErrorOccurred'
};

export const LOGIN_NEXT_STEP_CODES = {
  'CHECK_CREDENTIAL':  'CHECK_CREDENTIAL',
  'NEXT_CODE_REQUIRED': 'NEXT_CODE_REQUIRED',
  'NEW_PIN_REQUIRED': 'NEW_PIN_REQUIRED',
  'CHANGE_PASSWORD':  'CHANGE_PASSWORD',
  'FORGOT_PASSWORD_RESET' : 'STEP_FORGOTTEN_PASSWORD_RESET',
  'MFA_CHECK_REQUIRED': 'MFA_CHECK_REQUIRED',
  'FORGOT_PASSWORD_OTP': 'STEP_FORGOTTEN_PASSWORD_OTP'
};

export const FORGOT_PASSWORD_STEP_CODES = {
  'UID' : 'uid',
  'OTP' : 'otp'
};

export const CHECK_CREDENTIAL_ERROR_CODES = {
  'WARN_INPUT_NOT_VALID': 'tkEmailOrPasswordIncorrect',
  'WARN_TRY_AGAIN': 'tkEmailOrPasswordIncorrect',
  'PASSWORD_EXPIRED': 'tkPasswordExpired',
  'INFO_CREDEDENTIAL_NO_LONGER_VALID': 'tkTempPasswordExpired'
};

export const CHANGE_PASSWORD_ERROR_CODES = {
  'WARN_INPUT_NOT_VALID': 'tkPasswordNotValid',
  'WARN_TRY_AGAIN': 'tkPasswordNotValid',
  'PASSWORD_REUSED': 'tkPasswordReused',
  'PASSWORD_POLICY_NOT_MET': 'tkPasswordPolicyNotMet',
  'PWD_POLICY_NOT_MET': 'tkPasswordPolicyNotMet',
  'PWD_POLICY_NOT_MET_BAD_CHARS': "tkPasswordPolicyNotMetBadChars"
};

export const CHANGE_PIN_ERROR_CODES = {
  'WARN_INPUT_NOT_VALID': 'tkPinNotValid',
  'WARN_TRY_AGAIN': 'tkPinNotValid',
};

export const FORGOT_PASSWORD_ERROR_CODES = {
  'WARN_INPUT_NOT_VALID': 'tkInvalidPasscode',
  'WARN_TRY_AGAIN': 'tkIncorrectPasscode',
};

export const LOGIN_ERROR_CODES = {
  WARN_USERNAME_LOCKED: 'WARN_USERNAME_LOCKED'
};

export const MODULES_KEYS = {
  CURRENT_TRADES: 'currentTradeStatus',
  FUND_TRACKER_COMPARE_DATA_GRID: 'fundTrackerCompareDataGrid',
  USER_SEARCH: 'userSearch'
};

export const popoverOffset = {
  x: 0,
  y: 14
};

export const AMOUNT_TYPE = {
  ALL: 'ALL',
  CURRENCY: 'CURRENCY',
  SHARES: 'SHARES'
};

export const TRADE_TYPE = {
  PURCHASE: 'PURCHASE',
  REDEMPTION: 'REDEMPTION'
};

export const EMAIL_PREFERENCES_CONFIG = {
  NOTIFICATION_ROOT_KEY: 'emailNotificationSettings',
  IS_CHECKED_KEY: 'isChecked',
  IS_ENABLED_KEY: 'isEnabled',
  CUSTOM_TRADE_APPROVAL_REMINDER_EMAIL: 'customTradeApprovalReminderEmail'
};

export const STORE = {
  TRADE_APPROVALS_ERROR_MESSAGES_STORE: '@tradeApprovalsErrorMessagesStore'
};

export const INVESTMENT_POLICY = {
  INVESTMENT_POLICY : 'INVESTMENT POLICY'
};

export const POLICYSCOPE_AND_FUNDNAME = {
  POLICYSCOPE_AND_FUNDNAME : 'policyNameAndfundName'
};

export const FUND_TRACKER = {
  BENCHMARKS: 'benchmarks',
  METRICS: 'metrics',
  PERFORMANCE: 'Performance'
};

export const REPORT_FILTER_STATE = {
  VISIBLE: 'visible',
  HIDDEN: 'hidden'
};

export const REPORTS_STATUS_CODES = {
  SAVED: 'SAVED',
  SUCCESS: 'SUCCESS',
  SAVE_VIEW_FAILED: 'SAVE_VIEW_FAILED',
  SAVE_REPORT_FAILED: 'SAVE_REPORT_FAILED',
  REPORT_NAME_MISSING_ERROR: 'REPORT_NAME_MISSING_ERROR',
  REPORT_NAME_ERROR: 'REPORT_NAME_ERROR',
  REPORT_DESC_ERROR: 'REPORT_DESC_ERROR',
  DELETE_REPORT_VIEW_FAILED: 'DELETE_REPORT_VIEW_FAILED',
  DELETE_REPORT_FAILED: 'DELETE_REPORT_FAILED',
  REPORT_EXISTS: 'REPORT_EXISTS',
  NEW_REPORT_VIEW_EXISTS: 'NEW_REPORT_VIEW_EXISTS',
  MAX_COUNT: 'MAX_COUNT',
  CANCEL: 'CANCEL'
};

export const REPORT_ANALYTICS_DATA = {
  VIEW_REPORT: 'view_report',
  BACK_TO_OLD_REPORT: 'back_to_old_report',
  REPORT_DOWNLOAD: 'report_download',
  GTAM_OLD_REPORT: 'gtam old report',
  MOSAIC_DEFAULT_REPORT: 'mosaic default report'
};

export const REBATE_FIELDS = [
    'adjustedOneDayYield',
    'adjustedSevenDayCurYield',
    'adjustedSevenDayEffYield',
    'adjustedThirtyDayYield'
]

export default pageConstants;
