import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import TradeGroup from '../../../components/app/TradeApprovals/TradeGroup';
import {labelSelector, currencySymbolSelector, hasPendingTradesForApproval, isMimicSessionSelector} from '../../../selectors/app/index';
import {getUserTimeZone, homeAwaySelector} from '../../../selectors/user';
import {tradeApprovalTransactionData, isTradeApprovalDataLoading, isTradeApprovalInitialDataLoading, isTradeApprovalModalOpen,
  getCurrentRowWarningData, isTradeRejectionModalOpen, getReasonsList, selectCurrentRowData,
  getRejectionErrorOrWarningData, isRejectionInProgress, isApprovalInProgress,
  isPendingApprovalFlagDataLoading} from '../../../selectors/pages/tradeActivity/approvals';
import {approveTrade, rejectTrade, openRejectionModal, closeRejectionModal, openApprovalModal,
  closeApprovalModal, reloadTradeApprovalsData, disableCurrentTrade, clearTradeRejectionResponse } from '../../../actions/page/tradeActivity/approvals';
import ApprovalModal from './ApproveModal';
import RejectionModal from './RejectionModal';
import {Conditional} from '../../../components/core/Conditional';
import {ThreeSquaresLoader} from '../../../components/core/Loaders';
import {setTradeCutoffFlag} from '../../../containers/TradeActivity/TradeApprovals/helper';
import {addPageContext} from '../../../actions/page';
import {TRADEBLOTTER_FUND_FEES} from "../../../constants/appConstants";
import './tradeApprovals.scss';

const TradeApprovals = (props) => {
  const {
    labels, tradeData, dispatchTradeApproval, dispatchTradeRejection, isOpen, currentRowData,
    dispatchApprovalModalOpen, dispatchApprovalModalClose, approvalData, isOpenRejection,
    dispatchRejectionModalOpen, dispatchRejectionModalClose, dispatchRejectionChange, dispatchReloadTrades,
    reasonsList, isTradeApprovalDataLoading, isTradeApprovalInitialDataLoading, userTimeZone, currencySymbols,
    homeAway, rejectionErrorOrWarning, isApprovalInProgress, isRejectionInProgress, dispatchDisableCurrentTrade,
    hasPendingApproval
    , isPendingApprovalFlagDataLoading, isMimicSession
  } = props;

  const [tradeGroupHeight, setTradeGroupHeight] = useState(0);
  const [tradeApprovalData, setTradeApprovalData] = useState([]);

  useEffect(() => {
    if (tradeData && tradeData.length > 0) {
      setTradeApprovalData(tradeData);
      const cutOffCheck = setInterval(() => {
        setTradeApprovalData(setTradeCutoffFlag(tradeData, userTimeZone));
      }, 15000);
      return () =>  clearInterval(cutOffCheck);
    }else{
      setTradeApprovalData([]);
    }
  }, [tradeData]);

  const getFeeDetails = (tradeRow = {}) => {
    const {
      feeAmount: activeLiquidityFeeAmount, feePercent: activeLiquidityFeePercent, fundFees = []
    } = tradeRow ;
    const discretionaryFee = fundFees.find(({feeTypeShortCode}) => (
      feeTypeShortCode === TRADEBLOTTER_FUND_FEES.DISCRETIONARY_FEE
    )) || {};
    const {feePercent: discretionaryFeePercent, feeAmount: discretionaryFeeAmount} = discretionaryFee;
    const feeAmount = (discretionaryFee && discretionaryFeeAmount) || activeLiquidityFeeAmount;
    const feePercent = (discretionaryFee && discretionaryFeePercent) || activeLiquidityFeePercent;
    return {feeAmount, feePercent};
  };

  const handleTradeApproval = (data) => {
    const {approvalResponseWarning} = data;
    const {feeAmount, feePercent} = getFeeDetails(data);
    const hasWarning = !!(approvalResponseWarning && approvalResponseWarning.message);
    if(feeAmount && feePercent && !hasWarning){
      dispatchApprovalModalOpen(data);
    } else {
      dispatchTradeApproval(data);
    }
  };

  const handleOk = () => {
    dispatchDisableCurrentTrade(currentRowData);
  };

  const handleTradeRejection = (data) => {
    dispatchRejectionModalOpen(data);
  };
  const handleWarningApproval = () => {
    dispatchTradeApproval(currentRowData);
    dispatchApprovalModalClose();
  };

  const handleRejection = () => {
    dispatchTradeRejection(currentRowData);
  };

  const handleApprovalModalClose = () => {
    dispatchApprovalModalClose();
  };


  const handleRejectionModalClose = (status) => {
    dispatchRejectionModalClose(status);
  };

  const handleRejectionReasonChange = (selectedValue, selectedItem) => {
    dispatchRejectionChange(selectedValue, selectedItem);
  };

  const handleAnimationComplete = (tradeId) => {
    const updatedTrades = tradeApprovalData.filter(trade => trade.id !== tradeId);
    dispatchReloadTrades(updatedTrades);
  };

  const handleResize = () => {
    const activitySection = document.getElementsByClassName('tradeActivity__wrapper');
    setTradeGroupHeight(activitySection && (activitySection[0].offsetHeight-162) ); // pageHeader + tab's Height = 162
  };

  const {feeAmount} = getFeeDetails(currentRowData);

  return (
    <div className="tradeApproval">
      <ReactResizeDetector handleHeight onResize={handleResize} />
      <Conditional condition={isOpen}>
        <ApprovalModal
          handleClose={handleApprovalModalClose}
          handleApproval={handleWarningApproval}
          labels={labels}
          isModalOpen={isOpen}
          currencyCode={currentRowData && currencySymbols && currencySymbols[currentRowData.currencyCode]}
          fee={feeAmount}
        />
      </Conditional>
      <Conditional condition={isOpenRejection && currentRowData}>
        <RejectionModal
          reasonsList={reasonsList}
          currentRowData={currentRowData}
          approvalData={approvalData}
          rejectionErrorOrWarning={rejectionErrorOrWarning}
          handleRejectionReasonChange={handleRejectionReasonChange}
          handleClose={handleRejectionModalClose}
          handleRejection={handleRejection}
          labels={labels}
          isModalOpen={isOpenRejection}
          isRejectionInProgress={isRejectionInProgress}
          handleOk={handleOk}
        />
      </Conditional>
      <Conditional condition={!isTradeApprovalInitialDataLoading}>
        <React.Fragment>
          <Conditional condition={tradeApprovalData.length}>
            <div className="tradeApproval__body" style={{height: tradeGroupHeight}}>
              <TradeGroup
                data={tradeApprovalData}
                userTimeZone={userTimeZone}
                handleApproval={handleTradeApproval}
                handleRejection={handleTradeRejection}
                handleAnimationComplete={handleAnimationComplete}
                homeAway={homeAway}
                height={tradeGroupHeight}
                isMimicSession={isMimicSession}
              />
            </div>
            <div className="tradeApproval__notrades">
              <Conditional condition={!isPendingApprovalFlagDataLoading}>
                <Conditional condition={hasPendingApproval}>
                  <span>{labels.tkNoDataPendingApproval}</span>
                  <span>{labels.tkNoTrades}</span>
                </Conditional>
                <ThreeSquaresLoader />
              </Conditional>
            </div>
          </Conditional>
          <Conditional condition={isTradeApprovalDataLoading || isApprovalInProgress}>
            <div className="tradeApproval__loader">
              <ThreeSquaresLoader />
            </div>
          </Conditional>
        </React.Fragment>
        <div className="tradeApproval__loader">
          <ThreeSquaresLoader />
        </div>
      </Conditional>
    </div>
  );
};

export const mapDispatchToProps = (dispatch) => (
  {
    dispatchTradeApproval: data => {
      dispatch(approveTrade(data));
    },
    dispatchApprovalModalOpen: data => {
      dispatch(openApprovalModal(data));
    },
    dispatchTradeRejection: data => {
      dispatch(rejectTrade(data));
    },
    dispatchRejectionModalOpen: (data) => {
      dispatch(openRejectionModal(data));
    },
    dispatchRejectionChange: (selectedValue, selectedItem) => {
      dispatch(addPageContext({reject: {reason: selectedValue, description: selectedItem.text}}));
    },
    dispatchApprovalModalClose: () => {
      dispatch(closeApprovalModal());
    },
    dispatchRejectionModalClose: (status) => {
      dispatch(closeRejectionModal(status));
      status === 'clear' && dispatch(clearTradeRejectionResponse());
    },
    dispatchReloadTrades: (trades) => {
      dispatch(reloadTradeApprovalsData(trades));
    },
    dispatchDisableCurrentTrade: (data) => {
      dispatch(disableCurrentTrade(data));
      dispatch(closeRejectionModal());
    }

  });

TradeApprovals.propTypes = {
  labels: PropTypes.object,
  tradeData: PropTypes.array,
  isOpen: PropTypes.bool,
  isOpenRejection: PropTypes.bool,
  approvalData: PropTypes.object,
  dispatchTradeApproval: PropTypes.func,
  dispatchApprovalModalOpen: PropTypes.func,
  dispatchApprovalModalClose: PropTypes.func,
  dispatchTradeRejection: PropTypes.func,
  dispatchRejectionModalOpen: PropTypes.func,
  dispatchRejectionModalClose: PropTypes.func,
  dispatchRejectionChange: PropTypes.func,
  dispatchReloadTrades: PropTypes.func,
  currentRowData: PropTypes.object,
  reasonsList: PropTypes.array,
  isTradeApprovalDataLoading: PropTypes.bool,
  isTradeApprovalInitialDataLoading: PropTypes.bool,
  userTimeZone: PropTypes.string,
  currencySymbols: PropTypes.object,
  homeAway: PropTypes.bool,
  rejectionErrorOrWarning: PropTypes.object,
  isApprovalInProgress: PropTypes.bool,
  isRejectionInProgress: PropTypes.bool,
  dispatchDisableCurrentTrade: PropTypes.func,
  hasPendingApproval: PropTypes.bool,
  isPendingApprovalFlagDataLoading: PropTypes.bool,
  isMimicSession: PropTypes.bool
};

const mapStateToProps = state => ({
  labels : labelSelector(state),
  tradeData : tradeApprovalTransactionData(state),
  isOpen: isTradeApprovalModalOpen(state),
  isOpenRejection: isTradeRejectionModalOpen(state),
  rejectionErrorOrWarning: getRejectionErrorOrWarningData(state),
  warningData: getCurrentRowWarningData(state),
  currentRowData: selectCurrentRowData(state),
  reasonsList: getReasonsList(state),
  isTradeApprovalDataLoading: isTradeApprovalDataLoading(state),
  isTradeApprovalInitialDataLoading: isTradeApprovalInitialDataLoading(state),
  userTimeZone: getUserTimeZone(state),
  homeAway: homeAwaySelector(state),
  isApprovalInProgress: isApprovalInProgress(state),
  isRejectionInProgress: isRejectionInProgress(state),
  currencySymbols : currencySymbolSelector(state),
  hasPendingApproval: hasPendingTradesForApproval(state),
  isPendingApprovalFlagDataLoading: isPendingApprovalFlagDataLoading(state),
  isMimicSession: isMimicSessionSelector(state)
});

export default connect (mapStateToProps, mapDispatchToProps)(TradeApprovals);
