import { useContext, CSSProperties, ImgHTMLAttributes, FC, memo } from 'react';
import PropTypes from 'prop-types';
import { cardStyleSheet, getCardImgClasses } from '@gs-ux-uitoolkit-common/card';
import { ComponentProps } from '@gs-ux-uitoolkit-react/component';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { CardContext } from './card-context';

export interface CardImgProps
    extends ComponentProps<CSSProperties>,
        ImgHTMLAttributes<HTMLImageElement> {}

/**
 * CardImg styles an image appropriately inside a Card.
 */
export const CardImg: FC<CardImgProps> = memo(props => {
    const { className, alt, ...attributes } = props;
    const theme = useTheme();
    const { size } = useContext(CardContext);
    const cssClasses = useStyleSheet(cardStyleSheet, { theme, size });
    return (
        <img
            className={getCardImgClasses({ cssClasses, className })}
            data-gs-uitk-component="card-img"
            alt={alt}
            {...attributes}
        />
    );
});
CardImg.displayName = 'CardImg';

CardImg.propTypes = {
    className: PropTypes.string,
};
