import { cx } from '@gs-ux-uitoolkit-common/style';
import { MenuCssClasses } from './menu-style-sheet';
import { MenuOptionCssClasses } from './menu-option-style-sheet';
import { MenuCheckboxOptionCssClasses } from './menu-checkbox-option-style-sheet';
import { MenuDividerCssClasses } from './menu-divider-style-sheet';
import { MenuHeaderCssClasses } from './menu-header-style-sheet';
import { MenuCheckboxOptionProps } from './menu-checkbox-option-props';
import { MenuCommonProps } from './menu-props';
import { MenuOptionProps } from './menu-option-props';
import { MenuDividerProps } from './menu-divider-props';
import { MenuHeaderProps } from './menu-header-props';

const globalClassName = 'gs-menu';

export const getMenuClass = (props: {
    cssClasses: MenuCssClasses;
    className?: MenuCommonProps['className'];
    overrideClasses?: MenuCommonProps['classes'];
}): string => {
    const { cssClasses, className, overrideClasses } = props;
    return cx(cssClasses.root, className, overrideClasses && overrideClasses.root, globalClassName);
};

export const getMenuInnerClass = (props: {
    cssClasses: MenuCssClasses;
    overrideClasses?: MenuCommonProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.optionsContainer,
        overrideClasses && overrideClasses.optionsContainer,
        `${globalClassName}__inner`
    );
};

export const getCollapseMenuClass = (props: {
    cssClasses: MenuCssClasses;
    className?: MenuCommonProps['className'];
    overrideClasses?: MenuCommonProps['classes'];
}): string => {
    const { cssClasses, className, overrideClasses } = props;
    return cx(
        cssClasses.collapseMenu,
        className,
        overrideClasses && overrideClasses.collapseMenu,
        globalClassName
    );
};

export const getOptionClass = (props: {
    cssClasses: MenuOptionCssClasses;
    className?: MenuOptionProps['className'];
    overrideClasses?: MenuOptionProps['classes'];
}): string => {
    const { cssClasses, className, overrideClasses } = props;
    return cx(
        cssClasses.root,
        className,
        overrideClasses && overrideClasses.root,
        `${globalClassName}__option`
    );
};

export const getLabelClass = (props: {
    cssClasses: MenuOptionCssClasses;
    className?: MenuOptionProps['className'];
    overrideClasses?: MenuOptionProps['classes'];
}): string => {
    const { cssClasses, className, overrideClasses } = props;
    return cx(
        cssClasses.label,
        className,
        overrideClasses && overrideClasses.label,
        `${globalClassName}__option-label`
    );
};

export const getIconClass = (props: {
    cssClasses: MenuOptionCssClasses;
    overrideClasses?: MenuOptionProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.icon,
        overrideClasses && overrideClasses.icon,
        `${globalClassName}__option-icon`
    );
};

export const getSubmenuIconClass = (props: {
    cssClasses: MenuOptionCssClasses;
    overrideClasses?: MenuOptionProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.submenuIcon,
        overrideClasses && overrideClasses.submenuIcon,
        `${globalClassName}__submenu-icon`
    );
};

export const getSecondaryLabelClass = (props: {
    cssClasses: MenuOptionCssClasses;
    overrideClasses?: MenuOptionProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.secondaryLabel,
        overrideClasses && overrideClasses.secondaryLabel,
        `${globalClassName}__option-secondary-label`
    );
};

export const getCheckboxOptionClass = (props: {
    cssClasses: MenuCheckboxOptionCssClasses;
    className?: MenuCheckboxOptionProps['className'];
    overrideClasses?: MenuCheckboxOptionProps['classes'];
}): string => {
    const { cssClasses, className, overrideClasses } = props;
    return cx(
        cssClasses.root,
        className,
        overrideClasses && overrideClasses.root,
        `${globalClassName}__checkbox-option`
    );
};

export const getCheckboxOptionComponentClass = (props: {
    cssClasses: MenuCheckboxOptionCssClasses;
    overrideClasses?: MenuCheckboxOptionProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.checkbox,
        overrideClasses && overrideClasses.checkbox,
        `${globalClassName}__checkbox-option-component`
    );
};

export const getDividerClass = (props: {
    cssClasses: MenuDividerCssClasses;
    className?: MenuDividerProps['className'];
    overrideClasses?: MenuDividerProps['classes'];
}): string => {
    const { cssClasses, className, overrideClasses } = props;
    return cx(
        cssClasses.root,
        className,
        overrideClasses && overrideClasses.root,
        `${globalClassName}__divider`
    );
};
export const getHeaderClass = (props: {
    cssClasses: MenuHeaderCssClasses;
    className?: MenuHeaderProps['className'];
    overrideClasses?: MenuHeaderProps['classes'];
}): string => {
    const { cssClasses, className, overrideClasses } = props;
    return cx(
        cssClasses.root,
        className,
        overrideClasses && overrideClasses.root,
        `${globalClassName}__header`
    );
};
