import {
    createPopper,
    Instance as PopperInstance,
    Placement as PopperPlacement,
} from '@popperjs/core';
import { BaseStateViewModel, BaseStateViewModelProps } from './base-state-view-model';
import { MenuStateProps } from './menu-props';

export interface MenuStateViewModelProps extends BaseStateViewModelProps, MenuStateProps {}

/**
 * Contains utility methods shared between Angular and React Menu components.
 * It extends BaseStateViewModel in order to create and destroy a Popper.js
 * instance.
 */
export class MenuStateViewModel extends BaseStateViewModel {
    protected props: MenuStateViewModelProps;
    private context: HTMLDivElement | null = null;
    private popper: PopperInstance | null = null;

    constructor(menuProps: MenuStateViewModelProps) {
        super(menuProps);
        this.props = this.updateProps(menuProps);
    }

    destroy() {
        super.destroy();
        this.destroyPopper();
    }

    setMenuEl(value: HTMLDivElement | null) {
        this.menuEl = value;
        if (this.menuEl) {
            const innerElIndex = Array.from(this.menuEl.children).findIndex(
                el => el instanceof HTMLElement
            );
            this.childrenContainerEl =
                innerElIndex === -1 ? null : (this.menuEl.children[innerElIndex] as HTMLElement);
            this.destroyPopper();
            this.createPopper();
        } else {
            this.destroyPopper();
        }
    }

    update(props: MenuStateViewModelProps) {
        this.props = this.updateProps(props);
        if (this.props.visible) {
            this.createPopper();
        } else {
            this.destroyPopper();
        }
    }

    focus(element?: HTMLElement | null) {
        if (this.popper) {
            super.focus(element);
        }
    }

    // Creates an element to use as a reference target if one is not provided by
    // the user. Useful for opening a context menu at specific coordinates.
    private createContext() {
        this.context = document.createElement('div');
        this.context.setAttribute('data-cy', 'gs-uitk-menu__context');
        this.context.style.position = 'fixed';
        this.moveContext();
        document.body.appendChild(this.context);
        return this.context;
    }

    private moveContext() {
        if (this.context) {
            const contextOffset = Array.isArray(this.props.offset) ? this.props.offset : [0, 0];
            this.context.style.left = `${contextOffset[0]}px`;
            this.context.style.top = `${contextOffset[1]}px`;
        }
    }

    private destroyContext() {
        if (this.context && this.context.parentElement) {
            this.context.parentElement.removeChild(this.context);
            this.context = null;
        }
    }

    // Checks whether target and/or offset have been set and returns a value
    // acceptable to Popper.js.
    private getOffset(): [number, number] {
        const { offset, target } = this.props;
        if (target === undefined || offset === undefined) {
            // If no target is defined, a "context" element will be created as a
            // reference element; the target element will be positioned directly
            // against it.
            return [0, 0];
        }
        // Popper.js's values are [y, x]:
        return [offset[1], offset[0]];
    }

    // Returns options customized for Popper.js.
    private getModifiers() {
        return [
            {
                name: 'offset',
                options: {
                    offset: this.getOffset(),
                },
            },
        ];
    }

    // Checks whether target is set and, if so, in what format, and returns a
    // valid reference target to position the menu relative to.
    private getReference(): HTMLElement {
        const { target } = this.props;
        if (!target) {
            this.destroyContext();
            return this.createContext();
        }
        const defaultRef = document.body;
        if (typeof target === 'string') {
            return document.querySelector(target) || defaultRef;
        }
        return target || defaultRef;
    }

    // Translates UITK placement values into values acceptable to Popper.js.
    private getPlacement(): PopperPlacement {
        const { placement } = this.props;
        switch (placement) {
            case 'bottom-left':
                return 'bottom-start';
            case 'top-left':
                return 'top-start';
            case 'top-right':
                return 'top-end';
            case 'right-top':
                return 'right-start';
            case 'right-bottom':
                return 'right-end';
            case 'bottom-right':
                return 'bottom-end';
            case 'left-bottom':
                return 'left-end';
            case 'left-top':
                return 'left-start';
            case 'top':
            case 'right':
            case 'bottom':
            case 'left':
                return placement as PopperPlacement;
            case 'auto':
            default:
                return 'bottom-start';
        }
    }

    private createPopper() {
        if (this.props.visible && this.getMenuEl()) {
            const popperOptions = {
                modifiers: this.getModifiers(),
                placement: this.getPlacement(),
                strategy: this.props.fixed ? 'fixed' : 'absolute',
            } as const;
            if (this.popper) {
                this.popper.setOptions(popperOptions);
                this.focus();
            } else {
                this.popper = createPopper(
                    this.getReference(),
                    this.getMenuEl() as HTMLElement,
                    popperOptions
                );
            }
            this.focus();
        }
    }

    private destroyPopper() {
        if (this.popper) {
            this.popper.destroy();
            this.popper = null;
            this.destroyContext();
        }
    }
}
