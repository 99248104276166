import { DataType } from '@gs-ux-uitoolkit-common/datacore';
import { Component } from 'react';
import { connect } from 'react-redux';
import * as Redux from 'redux';
import { ButtonGroup } from '../../../../components/buttons/buttons-group';
import { PluginHeader } from '../../../../components/plugin-header/plugin-header';
import { ManagementTable, TableHeadings } from '../../../../components/table/management-table';
import { ModalProps, ModalWithItems } from '../../../../components/modal-props';
import { DatasourceType, GridColumn } from '../../../../grid-wrappers/grid-wrapper';
import {
    CancelEditedCustomSort,
    EditCustomSort,
    NewCustomSort,
    RemoveCustomSort,
    SaveEditedCustomSort,
    UpdateEditedCustomSort,
} from '../../../../redux/actions/custom-sort-action';
import {
    GridGetColumnValueList,
    GridResetColumnValueList,
} from '../../../../redux/actions/grid-action';
import { ColumnValueListState, DataGridState } from '../../../../redux/datagrid-state';
import { customSortStylesheet } from '../../../../style/plugins/custom-sort-stylesheet';
import { CustomSort } from '../../custom-sort-plugin';
import { CustomSortCreateComponent } from './custom-sort-create';
import { CustomSortValueSelectorComponent } from './custom-sort-value-selector';
import { PluginFooter } from '../../../../components/plugin-footer/plugin-footer';
import { PluginPreviewComponent } from '../../../../components/plugin-preview/plugin-preview-layout';
import { CustomSortDragItemComponent } from './custom-sort-drag-item';
import { Alert } from '@gs-ux-uitoolkit-react/alert';
import { ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';
/**
 * The props for the CustomSortModalComponent
 */
export interface CustomSortModalProps extends ModalProps<CustomSort>, ModalWithItems<CustomSort> {
    data: CustomSort | null;
    /**
     * Columns from the grid
     */
    columns: GridColumn[];
    /**
     * ColumnValues of a column
     */
    columnValueList: ColumnValueListState[];
    /**
     * Get the valueList for a column
     */
    gridGetColumnValueList: (columnId: string) => void;
    /**
     * Reset the column valueList in the store
     */
    gridResetColumnValueList: (columnId: string) => void;
    /**
     * If the grid is connected to DRA
     */
    isUsingDra: boolean;
}

/**
 * * The main custom sort modal
 */
export class CustomSortModalComponent extends Component<CustomSortModalProps> {
    constructor(props: CustomSortModalProps) {
        super(props);
    }

    public componentWillUnmount() {
        customSortStylesheet.unmount(this);
    }
    public render() {
        let availableColumns = this.props.columns.filter(
            column =>
                !column.hidden &&
                column.isSortable &&
                !this.props.configItemList.find(
                    cs =>
                        cs.columnId === column.columnId &&
                        (this.props.data ? cs.columnId !== this.props.data.columnId : true)
                )
        );
        if (this.props.isUsingDra) {
            availableColumns = availableColumns.filter(x => x.dataType === DataType.Number);
        }

        const customSortElements = this.props.configItemList.map(customSort => {
            const column = this.props.columns.find(col => col.columnId === customSort.columnId);

            if (column) {
                const editCustomSort = () => this.props.editConfigItem(customSort);
                const removeCustomSort = () => this.props.removeConfigItem(customSort);
                return (
                    <tr key={customSort.columnId}>
                        <td>{column.columnLabel}</td>
                        <td style={{ wordWrap: 'normal' }}>
                            {customSort.absoluteSort ? '[Absolute] ' : ' '}
                            {customSort.dataList.join(', ')}
                        </td>
                        <td>
                            <ButtonGroup
                                editClick={editCustomSort}
                                deleteClick={removeCustomSort}
                            />
                        </td>
                    </tr>
                );
            }
            return null;
        });

        const columnValueListItem = this.props.columnValueList.find(listItem => {
            return listItem.columnId === (this.props.data && this.props.data.columnId);
        });

        const customSortheadings: TableHeadings[] = [
            {
                label: 'Column Name',
                props: {
                    className: 'column-name-column',
                },
            },
            {
                label: 'Sort',
                props: {
                    className: 'column-name-column',
                },
            },
            {
                label: '',
                props: {
                    className: 'column-name-column',
                },
            },
        ];

        return (
            <ThemeConsumer>
                {theme => {
                    const cssClasses = customSortStylesheet.mount(this, { theme });
                    return (
                        <>
                            <div
                                className={`config-items-container gs-uitk-custom-sort-plugin uitk-custom-sort-modal__main-container ${cssClasses.root}`}
                            >
                                {!this.props.data ? (
                                    <>
                                        <PluginHeader
                                            displayCreateButton={true}
                                            createButtonCallback={this.props.newConfigItem}
                                            subTitle="Create and edit custom sorting for your data grid"
                                        />
                                        <ManagementTable
                                            headingConfig={customSortheadings}
                                            bodyRowElements={customSortElements}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <div className={'gs-uitk-create-container_main'}>
                                            <CustomSortCreateComponent
                                                columns={availableColumns}
                                                data={this.props.data}
                                                gridResetColumnValueList={
                                                    this.props.gridResetColumnValueList
                                                }
                                                updateEditedConfigItem={
                                                    this.props.updateEditedConfigItem
                                                }
                                                gridGetColumnValueList={
                                                    this.props.gridGetColumnValueList
                                                }
                                            />
                                            {this.props.data.absoluteSort ? (
                                                <Alert status="success">
                                                    No data list customization is available for
                                                    absolute sort. Please save.
                                                </Alert>
                                            ) : (
                                                <div className={'gs-uitk-createContainer'}>
                                                    <CustomSortValueSelectorComponent
                                                        columns={availableColumns}
                                                        data={this.props.data}
                                                        gridResetColumnValueList={
                                                            this.props.gridResetColumnValueList
                                                        }
                                                        updateEditedConfigItem={
                                                            this.props.updateEditedConfigItem
                                                        }
                                                        gridGetColumnValueList={
                                                            this.props.gridGetColumnValueList
                                                        }
                                                        columnValueList={
                                                            columnValueListItem || null
                                                        }
                                                    />
                                                    <div
                                                        className={
                                                            'gs-uitk-customsort-modal__expression-preview-container'
                                                        }
                                                    >
                                                        <PluginPreviewComponent
                                                            previewTitle={'SELECTED VALUES'}
                                                            previewBody={
                                                                this.props.columnValueList ? (
                                                                    <>
                                                                        <CustomSortDragItemComponent
                                                                            data={this.props.data}
                                                                            columnValueList={
                                                                                this.props
                                                                                    .columnValueList
                                                                            }
                                                                            selectedColumnValues={
                                                                                this.props.data
                                                                                    .dataList || []
                                                                            }
                                                                            updateEditedConfigItem={
                                                                                this.props
                                                                                    .updateEditedConfigItem
                                                                            }
                                                                        ></CustomSortDragItemComponent>
                                                                        <div>
                                                                            The remainder of the
                                                                            columns will use default
                                                                            sort after the
                                                                            priorities set above.
                                                                        </div>
                                                                    </>
                                                                ) : null
                                                            }
                                                        ></PluginPreviewComponent>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                            {this.props.data ? (
                                <PluginFooter
                                    createButtonDisplayName={'Save'}
                                    createActionType={'primary'}
                                    onCreate={
                                        !this.props.data
                                            ? this.props.newConfigItem
                                            : () => this.customSortFinish()
                                    }
                                    onCancel={() => this.customSortCancel()}
                                />
                            ) : null}
                        </>
                    );
                }}
            </ThemeConsumer>
        );
    }

    private customSortFinish = () => {
        if (this.props.data) {
            this.props.gridResetColumnValueList(this.props.data.columnId);
        }
        this.props.saveEditedConfigItem();
    };
    private customSortCancel = () => {
        if (this.props.data) {
            this.props.gridResetColumnValueList(this.props.data.columnId);
        }
        this.props.cancelEditedConfigItem();
    };
}

const mapStateToProps = (state: DataGridState) => {
    return {
        columns: state.grid.columnList,
        columnValueList: state.grid.columnValueList,
        configItemList: state.customSort.configItemList,
        isUsingDra:
            (state.systemConfiguration.gridWrapperConfiguration.datasourceConfiguration &&
                state.systemConfiguration.gridWrapperConfiguration.datasourceConfiguration
                    .datasourceType === DatasourceType.DRA) ||
            false,
        data: state.customSort.editedItem,
    };
};

const mapDispatchToProps = (dispatch: (action: Redux.Action) => void) => {
    return {
        cancelEditedConfigItem: () => dispatch(CancelEditedCustomSort()),
        editConfigItem: (customSort: CustomSort) => dispatch(EditCustomSort(customSort)),
        gridGetColumnValueList: (columnId: string) => dispatch(GridGetColumnValueList(columnId)),
        gridResetColumnValueList: (columnId: string) =>
            dispatch(GridResetColumnValueList(columnId)),
        newConfigItem: () => dispatch(NewCustomSort()),
        removeConfigItem: (customSort: CustomSort) => dispatch(RemoveCustomSort(customSort)),
        saveEditedConfigItem: () => dispatch(SaveEditedCustomSort()),
        updateEditedConfigItem: (newData: Partial<CustomSort>) =>
            dispatch(UpdateEditedCustomSort(newData)),
    };
};

export const CustomSortPluginModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomSortModalComponent);
