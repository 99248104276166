import {jurisdictionSelector} from "../../../selectors/user";
import {getBrandName, getDomainMapper} from "../../../utils/commonUtils";
const domainMapper = getDomainMapper();

export default {
  FETCH_FIRMS_DATA: {
    'path': '/api/v1/firms/expfilterfirms',
    'method': 'GET'
  },
  FETCH_LABELS: {
    'path': `/content/${domainMapper}/nodes/contentkeys/en.json`,
    'method': 'GET'
  },
  FETCH_CONFIGURABLE_CONSTANTS: {
    'path': `/content/${domainMapper}/nodes/configurable-constants.json`,
    'method': 'GET'
  },
  FETCH_CLIENT_SERVICE_CONFIGURATIONS: {
    'path': `/content/${domainMapper}/nodes/client-service-configuration.json`,
    'method': 'GET'
  },
  FETCH_GRID_CONFIGURATIONS: {
    'path': `/content/${domainMapper}/nodes/columns-configurations.json`,
    'method': 'GET'
  },
  FETCH_PDF_CONFIGURATIONS: {
    'path': `/content/${domainMapper}/nodes/pdf-columns-configurations.json`,
    'method': 'GET'
  },
  FETCH_PORTFOLIO_GROUPING: {
    'path': `/content/${domainMapper}/nodes/groups-configurations.json`,
    'method': 'GET'
  },
  FETCH_PORTFOLIO_VIEWS_AEM: {
    'path': `/content/${domainMapper}/nodes/portfolio-views.json`,
    'method': 'GET'
  },
  FETCH_TRADE_BLOTTER_CONFIGURATIONS: {
    'path': `/content/${domainMapper}/nodes/trade-configurations.json`,
    'method': 'GET'
  },
  FETCH_CURRENCY_INFORMATION: {
    'path': `/content/${domainMapper}/nodes/currency-symbol.json`,
    'method': 'GET'
  },
  FETCH_LITERATURE: {
    'path': '/api/v1/shareclasses',
    'method': 'GET'
  },
  FETCH_ACCOUNT_REGISTRATION: {
    'path': '/api/v1/mfaccounts/{accountId}/registration',
    'method': 'GET'
  },
  FETCH_SETTLEMENT_INSTRUCTION: {
    'path': '/api/v1/instructions/settlementInstructions',
    'method': 'POST',
  },
  FETCH_FUND_FACT_CARD_CONFIGURATIONS: {
    'path': `/content/${domainMapper}/nodes/fund-fact-card-configurations.json`,
    'method': 'GET'
  },
  FETCH_ACCOUNT_STATEMENT: {
    'path': '/api/v1/mfaccounts/{accountId}/statements',
    'method': 'GET'
  },
  DOWNLOAD_STATEMENT_PDF: {
    'path': '/api/v1/mfaccounts/{accountId}/statements/{statementId}',
    'method': 'GET'
  },
  FETCH_PREFERENCES_CONFIGURATIONS: {
    'path': `/content/${domainMapper}/nodes/user-preferences-configurations.json`,
    'method': 'GET'
  },
  FETCH_TRADE_BLOTTER_NEW_SHELLACCOUNT_CONFIGURATIONS: {
    'path': `/content/${domainMapper}/nodes/shell-account-configurations.json`,
    'method': 'GET'
  },
  FETCH_JURISDICTION_DISCLAIMER_DATA: {
    'path': state => `/content/${domainMapper}/disclaimers/jurisdiction/${jurisdictionSelector(state)}.caas.json`,
    'method': 'GET'
  },
  FETCH_STATIC_DISCLAIMER_DATA: {
    'path': `/content/${domainMapper}/disclaimers/static-disclaimers-data.caas.json`,
    'method': 'GET'
  },
  FETCH_LOGO: {
    'path': `/content/dam/${getBrandName()}/assets/pdf_header_logo.png`,
    'method': 'GET'
  },
  FETCH_CSS_TEMPLATES_TABLE: {
    'path': `/etc/clientlibs/mosaic-ui/pdf/css/${getBrandName()}/table.css`,
    'method': 'GET'
  },
  FETCH_CSS_TEMPLATES_COVERPAGE: {
    'path': `/etc/clientlibs/mosaic-ui/pdf/css/${getBrandName()}/coverpage.css`,
    'method': 'GET'
  },
  FETCH_CSS_TEMPLATES_DISCLAIMERS: {
    'path': `/etc/clientlibs/mosaic-ui/pdf/css/${getBrandName()}/disclaimer.css`,
    'method': 'GET'
  },
  FETCH_NAVBAR_CONFIGURATIONS_DATA: {
    'path': `/content/${domainMapper}/nodes/navbar-configurations.json`,
    'method': 'GET'
  },
  FETCH_INTERNAL_TRADE_BLOTTER_CONFIGURATIONS: {
    'path': `/content/${domainMapper}/nodes/internal-trade-configurations.json`,
    'method': 'GET'
  },
  FETCH_FUND_FINDER_GRID_CONFIGURATIONS: {
    'path': `/content/${domainMapper}/nodes/fund-finder-columns-configurations.json`,
    'method': 'GET'
  },
  FETCH_CURRENT_TRADES_GRID_CONFIGURATIONS: {
    'path': `/content/${domainMapper}/nodes/current-trades-columns-group-configurations.json`,
    'method': 'GET'
  },
  FETCH_CURRENT_TRADES_GROUP_CONFIGURATIONS: {
    'path': `/content/${domainMapper}/nodes/current-trades-group-configurations.json`,
    'method': 'GET'
  },
  FETCH_TRADE_APPROVALS_CONFIGURATIONS: {
    'path': `/content/${domainMapper}/nodes/trade-approvals-columns-configurations.json`,
    'method': 'GET'
  },
  FETCH_TRADE_REJECTION_REASONS_CONFIGURATIONS: {
    'path': `/content/${domainMapper}/nodes/trade-rejection-reasons.json`,
    'method': 'GET'
  },
  FETCH_ACCOUNT_DETAILS_CONFIGURATIONS: {
    'path': `/content/${domainMapper}/nodes/account-details-configurations.json`,
    'method': 'GET'
  },
  FETCH_FAQ_GLOSSARY_DATA: {
    'path': `/content/${domainMapper}/contents/faq-glossary-data.caas.json`,
    'method': 'GET'
  },
  FETCH_PROFILE_HOST_MAPPINGS: {
    'path': `/content/${domainMapper}/nodes/profileHostMappings.json`,
    'method': 'GET'
  },
  FETCH_THIRD_PARTY_RESOURCE_DATA: {
    'path': '/api/v1/dataservice/transparencyInsight',
    'method': 'POST'
  },
  FETCH_CLEARWATER_SSO_OUTBOUND_DATA: {
    'path': '/gs-sso/userapi',
    'method': 'POST'
  }
}
