import { Placement as TippyPlacement } from 'tippy.js';
import { PopoverPlacement } from './popover-base-props';

/**
 * Converts placement value to a value accepted by tippy.js/popper.js
 */
export function getTippyPlacement(placement: PopoverPlacement): TippyPlacement {
    let result: TippyPlacement;

    switch (placement) {
        case 'left-top':
            result = 'left-start';
            break;

        case 'left-bottom':
            result = 'left-end';
            break;

        case 'right-top':
            result = 'right-start';
            break;

        case 'right-bottom':
            result = 'right-end';
            break;

        case 'top-left':
            result = 'top-start';
            break;

        case 'top-right':
            result = 'top-end';
            break;

        case 'bottom-left':
            result = 'bottom-start';
            break;

        case 'bottom-right':
            result = 'bottom-end';
            break;

        case 'top':
        case 'bottom':
        case 'left':
        case 'right':
        case 'auto':
            result = placement;
            break;
    }

    return result;
}
