import { cx } from '@gs-ux-uitoolkit-common/style';
import { ModalCssClasses } from './modal-style-sheet';
import { ModalHeaderCssClasses } from './modal-header-style-sheet';
import { ModalBodyCssClasses } from './modal-body-style-sheet';
import { ModalFooterCssClasses } from './modal-footer-style-sheet';
import { ModalProps, ModalHeaderProps, ModalBodyProps, ModalFooterProps } from './modal-props';

const globalClassName = 'gs-modal';
export const modalClassNameSelector = 'gs-uitk-modal__internal-wrapper';

export const getDocumentBodyClasses = (props: {
    cssClasses: ModalCssClasses;
    overrideClasses?: ModalProps['classes'];
    className?: ModalProps['className'];
}): string => {
    const { cssClasses, className, overrideClasses } = props;
    return cx(
        cssClasses.documentScrollLock,
        className,
        overrideClasses && overrideClasses.documentScrollLock
    );
};

export const getModalWrapperClasses = (props: {
    cssClasses: ModalCssClasses;
    overrideClasses?: ModalProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.wrapper,
        overrideClasses && overrideClasses.wrapper,
        `${globalClassName}__wrapper`
    );
};

export const getModalClasses = (props: {
    cssClasses: ModalCssClasses;
    overrideClasses?: ModalProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.root,
        overrideClasses && overrideClasses.root,
        globalClassName,
        modalClassNameSelector
    );
};

export const getModalDialogClasses = (props: {
    cssClasses: ModalCssClasses;
    overrideClasses?: ModalProps['classes'];
    className?: ModalProps['className'];
}): string => {
    const { cssClasses, className, overrideClasses } = props;
    return cx(
        cssClasses.dialog,
        className,
        overrideClasses && overrideClasses.dialog,
        `${globalClassName}__dialog`
    );
};

export const getModalContentClasses = (props: {
    cssClasses: ModalCssClasses;
    overrideClasses?: ModalProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.content,
        overrideClasses && overrideClasses.content,
        `${globalClassName}__content`
    );
};

export const getModalBackdropClasses = (props: {
    cssClasses: ModalCssClasses;
    overrideClasses?: ModalProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.backdrop,
        overrideClasses && overrideClasses.backdrop,
        `${globalClassName}__backdrop`
    );
};

export const getModalHeaderClasses = (props: {
    cssClasses: ModalHeaderCssClasses;
    overrideClasses?: ModalHeaderProps['classes'];
    className?: ModalHeaderProps['className'];
}): string => {
    const { cssClasses, className, overrideClasses } = props;
    return cx(
        cssClasses.root,
        className,
        overrideClasses && overrideClasses.root,
        `${globalClassName}__header`
    );
};

export const getModalHeaderTitleContainerClasses = (props: {
    cssClasses: ModalHeaderCssClasses;
    overrideClasses?: ModalHeaderProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.titleContainer,
        overrideClasses && overrideClasses.titleContainer,
        `${globalClassName}__title-container`
    );
};

export const getModalHeaderTitleClasses = (props: {
    cssClasses: ModalHeaderCssClasses;
    overrideClasses?: ModalHeaderProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.title,
        overrideClasses && overrideClasses.title,
        `${globalClassName}__title`
    );
};

export const getModalHeaderSubtitleClasses = (props: {
    cssClasses: ModalHeaderCssClasses;
    overrideClasses?: ModalHeaderProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.subtitle,
        overrideClasses && overrideClasses.subtitle,
        `${globalClassName}__subtitle`
    );
};

export const getModalHeaderCloseButtonClasses = (props: {
    cssClasses: ModalHeaderCssClasses;
    overrideClasses?: ModalHeaderProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.dismissButton,
        overrideClasses && overrideClasses.dismissButton,
        `${globalClassName}__dismiss-button`
    );
};

export const getModalBodyClasses = (props: {
    cssClasses: ModalBodyCssClasses;
    overrideClasses?: ModalBodyProps['classes'];
    className?: ModalBodyProps['className'];
}): string => {
    const { cssClasses, className, overrideClasses } = props;
    return cx(
        cssClasses.root,
        className,
        overrideClasses && overrideClasses.root,
        `${globalClassName}__body`
    );
};

export const getModalFooterClasses = (props: {
    cssClasses: ModalFooterCssClasses;
    overrideClasses?: ModalFooterProps['classes'];
    className?: ModalFooterProps['className'];
}): string => {
    const { cssClasses, className, overrideClasses } = props;
    return cx(
        cssClasses.root,
        className,
        overrideClasses && overrideClasses.root,
        `${globalClassName}__footer`
    );
};
