import { MenuCommonProps } from './menu-props';

export interface SubmenuContext extends MenuCommonProps {
    isSubmenu: boolean;
    submenuType?: 'flyout' | 'collapse';
    parentElement?: HTMLElement;
}

export const defaultSubmenuContext: SubmenuContext = {
    isSubmenu: false,
};
