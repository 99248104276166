import { createContext } from 'react';
import { DragAndDropPlaceholderState } from './drag-and-drop-context';
import { UseRegisterRefType } from './hooks/use-register-ref';
import { UseUnregisterRefType } from './hooks/use-unregister-ref';

export interface DragAndDropInternalContextValue {
    registerRef: ReturnType<UseRegisterRefType>;
    unregisterRef: ReturnType<UseUnregisterRefType>;
    placeholderState: DragAndDropPlaceholderState;
    direction: 'horizontal' | 'vertical';
    spacing: number;
}

export const DragAndDropInternalContext = createContext<
    DragAndDropInternalContextValue | undefined
>(undefined);
