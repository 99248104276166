import { SelectConfig } from './options/select-options';
import { SelectMultipleConfig } from './options/select-multiple-options';
import { Placement } from '@gs-ux-uitoolkit-common/design-system';

export interface CommonSelectProps extends Readonly<SelectConfig> {}
export interface CommonSelectMultipleProps extends Readonly<SelectMultipleConfig> {}

export type SelectPlacement = Extract<Placement, 'top' | 'auto'>;

export type SelectDefaultProps = Required<
    Pick<
        CommonSelectProps,
        | 'clearable'
        | 'filterOptionsOnSearch'
        | 'inline'
        | 'menuPlacement'
        | 'noOptionsContent'
        | 'noResultsContent'
        | 'searchable'
        | 'searchFields'
        | 'size'
        | 'status'
        | 'enableRenderCache'
    >
>;

export const selectDefaultProps: SelectDefaultProps = {
    clearable: true,
    filterOptionsOnSearch: true,
    inline: false,
    menuPlacement: 'auto',
    noOptionsContent: 'No options to choose from',
    noResultsContent: 'No results found',
    searchable: true,
    searchFields: ['label', 'value'],
    size: 'md',
    status: 'none',
    enableRenderCache: false,
};

export type SelectMultipleDefaultProps = Required<
    Pick<
        CommonSelectMultipleProps,
        | 'clearable'
        | 'filterOptionsOnSearch'
        | 'inline'
        | 'maxSelectedOptions'
        | 'maxSelectionsContent'
        | 'noOptionsContent'
        | 'noResultsContent'
        | 'pasteable'
        | 'removeButtonsVisible'
        | 'menuPlacement'
        | 'renderSelectedOptions'
        | 'resetScrollPosition'
        | 'searchFields'
        | 'size'
        | 'sortSelectedOptions'
        | 'status'
        | 'enableRenderCache'
    >
>;

export const selectMultipleDefaultProps: SelectMultipleDefaultProps = {
    clearable: false,
    filterOptionsOnSearch: true,
    inline: false,
    maxSelectedOptions: -1,
    maxSelectionsContent: 'Maximum selected options reached',
    menuPlacement: 'auto',
    noOptionsContent: 'No options to choose from',
    noResultsContent: 'No results found',
    pasteable: true,
    removeButtonsVisible: true,
    renderSelectedOptions: false,
    resetScrollPosition: true,
    searchFields: ['label', 'value'],
    size: 'md',
    sortSelectedOptions: false,
    status: 'none',
    enableRenderCache: false,
};
