import { CSSProperties, HTMLAttributes, ReactNode, FC, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    AccordionProps as CommonAccordionProps,
    accordionDefaultProps,
    accordionEmphases,
    accordionSizes,
    getAccordionClassNames,
} from '@gs-ux-uitoolkit-common/accordion';
import { AccordionContext } from './accordion-context';
import { componentAnalytics } from './analytics-tracking';

export interface AccordionProps
    extends CommonAccordionProps<CSSProperties>,
        HTMLAttributes<HTMLElement> {
    /**
     * Content to display inside the Accordion. This should be a set of `<AccordionPanel>` tags.
     */
    children?: ReactNode;
}

/**
 * Accordion presents one or more content panels that may be collapsed or expanded interactively.
 */
export const Accordion: FC<AccordionProps> = memo(props => {
    const {
        className,
        classes: overrideClasses,
        emphasis = accordionDefaultProps.emphasis,
        size = accordionDefaultProps.size,
        children,
        ...attributes
    } = props;

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'accordion' });
    }, []); // Only run once

    return (
        <AccordionContext.Provider
            value={{
                size: size,
                emphasis: emphasis,
            }}
        >
            <div
                data-gs-uitk-component="accordion"
                className={getAccordionClassNames({
                    overrideClasses,
                    className,
                })}
                {...attributes}
            >
                <div
                    aria-multiselectable="true"
                    className={overrideClasses && overrideClasses.accordion}
                >
                    {children}
                </div>
            </div>
        </AccordionContext.Provider>
    );
});
Accordion.displayName = 'Accordion';

Accordion.propTypes = {
    className: PropTypes.string,
    emphasis: PropTypes.oneOf(accordionEmphases),
    size: PropTypes.oneOf(accordionSizes),
};
