// TODO: A duplicate of EnumHelper in datacore, but all of them should be deleted entirely, with
//  the relevant Enums converted to Union String types instead
export class EnumHelper {
    public static stringToEnum<T extends number>(stringRep: string, selectedEnum: any): T {
        return selectedEnum[stringRep];
    }

    public static stringToStringEnum<T>(stringRep: string, enumType: any): T | null {
        const item = Object.entries(enumType).find(x => x[1] === stringRep);
        return item ? (item[1] as T) : null;
    }
}
