const constants = {
  'defaultAgTheme': 'ag-theme-balham',
  'defaultCustomTheme': 'ag-theme-balham',
  'warning': 'Warning:',
  'propertyNotSupported': 'property is not supported by Ag grid:',
  'eventNotSupported': 'events is not supported by Ag grid:',
  'customFilter': 'CustomFilter',
  'filterMenuTab': 'filterMenuTab',
  'buttonType': 'button',
  'buttonRenderer': 'Button',
  'exceptionOnGridResized': 'Exception in onGridSizeChanged event in Grid Component'

};

export default constants;

export const cssClassConst = {
  checkboxRenderer: 'checkboxRenderer',
  checkboxDisabled: 'checkbox-disabled'
};

export const KEY_CODES = {
  ENTER: 13,
  ESCAPE: 27,
  DELETE: 46,
  BACKSPACE: 8
};

/* This maps JS data type to excel recognized data type */
export const EXCEL_DATA_TYPE_MAPPER = {
  string: 'String',
  number: 'Number',
  boolean: 'Boolean',
  date: 'DateTime',
};
