import {
    Bean,
    Autowired,
    BeanStub,
    PostConstruct,
    IAggFuncParams,
    Column,
    IAggFunc,
} from '@ag-grid-community/core';

/**
 * Service that replaces broken aggregate function in RowGroupingModule
 *
 * TODO: Remove this service when upgrading to >=30.0.0
 * https://jira.work.gs.com/browse/UX-18506
 */
@Bean('uitkAggFuncPatchService')
export class UitkAggFuncPatchService extends BeanStub {
    @Autowired('aggFuncService') private aggFuncService!: AggFuncServiceSkeleton;

    @PostConstruct
    protected init() {
        this.aggFuncService.addAggFunc('count', aggCount);
    }
}

/**
 * Interface based on ag-grid's implementation of aggFuncService.
 * The type is not exported, sot we have to redefine it here (version 28.2)
 */
interface AggFuncServiceSkeleton {
    getDefaultAggFunc(column: Column): string | null;
    addAggFuncs(aggFuncs?: { [key: string]: IAggFunc }): void;
    addAggFunc(key: string, aggFunc: IAggFunc): void;
    getAggFunc(name: string): IAggFunc;
    getFuncNames(column: Column): string[];
    clear(): void;
}

/**
 * Fixed implementation copied from ag-grid source code (version 30.0.6).
 */
function aggCount(params: IAggFuncParams) {
    const { values } = params;
    let result = 0;

    // for optimum performance, we use a for loop here rather than calling any helper methods or using functional code
    for (let i = 0; i < values.length; i++) {
        const value = values[i];

        // check if the value is from a group, in which case use the group's count
        result += value != null && typeof value.value === 'number' ? value.value : 1;
    }

    // the previous aggregation data
    const existingAggData = params.rowNode?.aggData?.[params.column.getColId()];
    if (existingAggData && existingAggData.value === result) {
        // the underlying values haven't changed, return the old object to avoid triggering change detection
        return existingAggData;
    }

    // it's important to wrap it in the object so we can determine if this is a group level
    return {
        value: result,
        toString: function () {
            return this.value.toString();
        },
        // used for sorting
        toNumber: function () {
            return this.value;
        },
    };
}
