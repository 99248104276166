import { connect } from 'react-redux';
import {CreatePassword} from '../../components/app/CreatePassword';
import {labelSelector, pwdSelector, usernameSelector} from '../../selectors/app';
import {
  nextStepSelector, pingConfigSelector, submitCredentialsErrorSelector, isShowMosaicHelpSelector, isLoginInProgressSelector, pwdMustContainListSelector,
  pwdCannotContainListSelector} from '../../selectors/pages/login';
import { createNewPassword, isShowMosaicHelp } from '../../actions/page/login';
import {addAppContext} from "../../actions/app";
import {eventAnalytics} from "../../actions/sagas";

const mapStateToProps = (state) => ({
  labels: labelSelector(state),
  pwdMustContainList : pwdMustContainListSelector(state),
  pwdCannotContainList : pwdCannotContainListSelector(state),
  submitCredentialsError : submitCredentialsErrorSelector(state),
  nextStep: nextStepSelector(state),
  pingConfig: pingConfigSelector(state),
  isShowMosaicHelpFlag: isShowMosaicHelpSelector(state),
  userId: usernameSelector(state),
  pwd: pwdSelector(state),
  isLoginInProgress: isLoginInProgressSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  createNewPassword: (data) => dispatch(createNewPassword(data)),
  isShowMosaicHelp: flag => dispatch(isShowMosaicHelp(flag)),
  addAppContext: data => dispatch(addAppContext(data)),
  createPwdAnalytics: (data) => dispatch(eventAnalytics(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePassword);
