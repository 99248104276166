import { Dispatch, MutableRefObject, SetStateAction, useCallback } from 'react';
import { DragAndDropPlaceholderState, DragAndDropSizesCache } from '../drag-and-drop-context';
import { DragAndDropUpdate, ExternalDragAndDropOnDragUpdate } from '../drag-and-drop-external';
import { getPlaceholderState } from '../get-placeholder-state';

export type UseOnDragUpdateCallbackType = typeof useOnDragUpdateCallback;

export interface DragAndDropUpdateEvent {
    initial: DragAndDropUpdate;
    announce: (message: string) => void;
}

export type DragAndDropOnDragUpdate = (event: DragAndDropUpdateEvent) => void;

/**
 * Callback returned from the hook should run when predicted drop location changes.
 * Callback updates position of the placeholder.
 */
export const useOnDragUpdateCallback = (
    setPlaceholderState: Dispatch<SetStateAction<DragAndDropPlaceholderState>>,
    sizesCache: MutableRefObject<DragAndDropSizesCache>,
    direction: 'horizontal' | 'vertical',
    placeholderDisabled?: boolean,
    onDragUpdate?: DragAndDropOnDragUpdate
) => {
    return useCallback<ExternalDragAndDropOnDragUpdate>(
        (initial, provided) => {
            // call function if provided by user
            onDragUpdate ? onDragUpdate({ initial, announce: provided.announce }) : null;

            // disable placeholder logic if placeholder is disabled
            if (placeholderDisabled) {
                return;
            }

            setPlaceholderState(prevState => {
                return {
                    ...prevState,
                    ...getPlaceholderState(sizesCache, initial, direction),
                };
            });
        },
        [setPlaceholderState, sizesCache, direction, placeholderDisabled, onDragUpdate]
    );
};
