import {
    ForwardRefExoticComponent,
    CSSProperties,
    HTMLAttributes,
    ReactNode,
    Ref,
    forwardRef,
    useContext,
} from 'react';
import {
    ModalBodyProps as ModalBodyCommonProps,
    modalBodyStyleSheet,
    getModalBodyClasses,
} from '@gs-ux-uitoolkit-common/modal';
import { ModalSizeContext } from './modal-size-context';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';

export interface ModalBodyProps
    extends ModalBodyCommonProps<CSSProperties>,
        HTMLAttributes<HTMLElement> {
    /**
     * Content to display inside the Modal Body.
     */
    children?: ReactNode;
}

/**
 * Provides appropriate spacing around the body section of the Modal's content.
 */
export const ModalBody: ForwardRefExoticComponent<ModalBodyProps & { ref?: Ref<HTMLDivElement> }> =
    forwardRef(({ className, classes: overrideClasses, size, ...attributes }, ref) => {
        const sizeContext = useContext(ModalSizeContext);
        const theme = useTheme();
        const cssClasses = useStyleSheet(modalBodyStyleSheet, { size: size || sizeContext, theme });
        return (
            <div
                {...attributes}
                ref={ref}
                data-gs-uitk-component="modal-body"
                className={getModalBodyClasses({ cssClasses, className, overrideClasses })}
            />
        );
    });
ModalBody.displayName = 'ModalBody';
