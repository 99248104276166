import {
    AddConfigItemAction,
    CancelEditedConfigItemAction,
    EditConfigItemAction,
    NewConfigItemAction,
    RemoveConfigItemAction,
    SaveEditedConfigItemAction,
    UpdateEditedConfigItemAction,
} from '@gs-ux-uitoolkit-common/datacore';
import { RowCustomisation } from '../../plugins/row-customisation/row-customisation';
import { RowCustomisationActionKeys } from './action-constants';

export type RowCustomisationActionTypes =
    | AddRowCustomisationAction
    | RemoveRowCustomisationAction
    | NewRowCustomisationAction
    | EditRowCustomisationAction
    | UpdateEditedRowCustomisationAction
    | SaveEditedRowCustomisationAction
    | CancelEditedRowCustomisationAction;

export interface RemoveRowCustomisationAction extends RemoveConfigItemAction<RowCustomisation> {
    type: RowCustomisationActionKeys.REMOVE_ROW_CUSTOMISATION;
}

export const RemoveRowCustomisation = (
    rowCustomisation: RowCustomisation
): RemoveRowCustomisationAction => ({
    configItem: rowCustomisation,
    type: RowCustomisationActionKeys.REMOVE_ROW_CUSTOMISATION,
});

export interface AddRowCustomisationAction extends AddConfigItemAction<RowCustomisation> {
    type: RowCustomisationActionKeys.ADD_ROW_CUSTOMISATION;
}

export const AddRowCustomisation = (
    rowCustomisation: RowCustomisation
): AddRowCustomisationAction => ({
    configItem: rowCustomisation,
    type: RowCustomisationActionKeys.ADD_ROW_CUSTOMISATION,
});

export interface EditRowCustomisationAction extends EditConfigItemAction<RowCustomisation> {
    type: RowCustomisationActionKeys.EDIT_ROW_CUSTOMISATION;
}

export const EditRowCustomisation = (
    rowCustomisation: RowCustomisation
): EditRowCustomisationAction => ({
    configItem: rowCustomisation,
    type: RowCustomisationActionKeys.EDIT_ROW_CUSTOMISATION,
});

export interface NewRowCustomisationAction extends NewConfigItemAction {
    type: RowCustomisationActionKeys.NEW_ROW_CUSTOMISATION;
}

export const NewRowCustomisation = (): NewRowCustomisationAction => ({
    type: RowCustomisationActionKeys.NEW_ROW_CUSTOMISATION,
});

export interface UpdateEditedRowCustomisationAction
    extends UpdateEditedConfigItemAction<RowCustomisation> {
    type: RowCustomisationActionKeys.UPDATE_EDITED_ROW_CUSTOMISATION;
}

export const UpdateEditedRowCustomisation = (
    rowCustomisationUpdatedPart: Partial<RowCustomisation>
): UpdateEditedRowCustomisationAction => ({
    configItemUpdatedPart: rowCustomisationUpdatedPart,
    type: RowCustomisationActionKeys.UPDATE_EDITED_ROW_CUSTOMISATION,
});

export interface SaveEditedRowCustomisationAction extends SaveEditedConfigItemAction {
    type: RowCustomisationActionKeys.SAVE_EDITED_ROW_CUSTOMISATION;
}

export const SaveEditedRowCustomisation = (): SaveEditedRowCustomisationAction => ({
    type: RowCustomisationActionKeys.SAVE_EDITED_ROW_CUSTOMISATION,
});

export interface CancelEditedRowCustomisationAction extends CancelEditedConfigItemAction {
    type: RowCustomisationActionKeys.CANCEL_EDITED_ROW_CUSTOMISATION;
}

export const CancelEditedRowCustomisation = (): CancelEditedRowCustomisationAction => ({
    type: RowCustomisationActionKeys.CANCEL_EDITED_ROW_CUSTOMISATION,
});
