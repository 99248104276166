import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import CustomView from '../../../../components/app/CustomView';
import {
  allViewsSelector, isOpenCustomViewModal, groupedColumnsSelector,
  modelActiveMode, isModalLoading, duplicateViewName,
  getErrorObject, checkIsPageInErrorMode, columnsMetadata,
  currentViewSelector
} from '../../../../selectors/pages/reports/individualReport';
import {
  closeCustomViewModal, deleteCustomView, addEditDeleteViewData
} from '../../../../actions/page/reports';
import {eventAnalytics} from '../../../../actions/sagas';
import {addPageContext} from '../../../../actions/page';
import {labelSelector, getLabel} from '../../../../selectors/app/index';
import {getSaveViewAnalyticsData} from '../../analytics';
import Modal from '../../../../components/core/Modal';
import {onEditView} from '../../utils';

export const ReportsCustomViewModel = (props) => {
  const handleClose = () => {
    props.closeModal({
      modelActiveMode: '',
      isModalLoading: false,
      duplicateViewName: undefined,
      isPageInErrorMode: false
    });
  };

  const modalProps = {
    animation: true,
    suppressScrollOnActive: true,
    customModalStyle: {overflow: 'hidden'},
    open: props.open,
    handleClose
  };

  const onEditCustomView = (data) => {
    const {
      currentView: {baseView, value, date, groupBy},
      allViews,
      addEditDeleteViewData
    } = props;
    const newView = {baseView, value, date, groupBy, ...data};
    onEditView(newView, allViews, addEditDeleteViewData);
  };

  const updatedProps = {
    ...props,
    editCustomView: onEditCustomView
  };

  return (
    <Modal {...modalProps}>
      <CustomView {...updatedProps} />
    </Modal>
  );
};

const mapStateToProps = state => ({
  columnsMetadata: columnsMetadata(state),
  groupedColumns: groupedColumnsSelector(state),
  allViews: allViewsSelector(state),
  open: isOpenCustomViewModal(state),
  currentView: currentViewSelector(state),
  labels: labelSelector(state),
  label: key => getLabel(state, key),
  modelActiveMode: modelActiveMode(state),
  isLoading: isModalLoading(state),
  duplicateViewName: duplicateViewName(state),
  errorObject: getErrorObject(state),
  isPageInErrorMode: checkIsPageInErrorMode(state)
});

export const mapDispatchToProps = dispatch => ({
  closeModal: data => dispatch(closeCustomViewModal(data)),
  doAnalytics: data => dispatch(eventAnalytics(data)),
  deleteCustomView: data => dispatch(deleteCustomView(data)),
  addPageContext: (obj) => dispatch(addPageContext(obj)),
  dispatchViewSaveAnalytics: (viewColumns) => {
    const data = getSaveViewAnalyticsData(viewColumns);
    dispatch(eventAnalytics(data));
  },
  addEditDeleteViewData: (data) => {
    dispatch(addEditDeleteViewData(data));
  }
});

ReportsCustomViewModel.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  errorObject: PropTypes.object,
  currentView: PropTypes.object,
  allViews: PropTypes.array,
  addEditDeleteViewData: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsCustomViewModel);
