import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import cn from "classnames";
import { connect } from "react-redux";
import {  LoadingIcon } from "@gs-ux-uitoolkit-react/loading";
import Select from "../../core/Select/Simple";
import Repeater from "../../core/Repeater";
import { Conditional } from "../../core/Conditional";
import Link from "../../core/Link";
import { EXTERNAL_LINK_SKIP_ATTRIBUTE } from "../../../constants/pageConstants";
import translator from "../../../services/translator";
import DebitCreditInstructions from "./DebitCreditInstructions";
import {
  getIsACHUser,
  getIsDownloadStatementFailed,
  getInstructionState,
  getStatementState,
  getDebitCreditState,
} from "../../../selectors/containers/fundFactView";
import {
  clearDownloadStatementFailureMessage,
} from "../../../actions/containers/fundFact";
import Toast from "../../core/Toast";

const { translate: t } = translator;

const AccountNumberToggleComp = (props) => {
  const { accountNumber } = props;
  const maskedAccountNumber = `*****${accountNumber.substr(
    accountNumber.length - 4,
    accountNumber.length
  )}`;
  const [showAccountNumber, setShowAccountNumber] = useState(false);
  const toggleBankAccountNumber = () => {
    setShowAccountNumber(!showAccountNumber);
  };

  return (
    <div className="factData">
      <span className="fundFactView__label">Bank Account Number</span>
      <span className="factData--accountDetail">
        (
        <Link
          handleClick={toggleBankAccountNumber}
          label={showAccountNumber ? `${t('tkHide')}` : `${t('tkShow')}`}
        />
        ): {showAccountNumber ? accountNumber : maskedAccountNumber}
      </span>
    </div>
  );
};

AccountNumberToggleComp.propTypes = {
  accountNumber: PropTypes.number,
};

function getAdditionalInfo(additionalInfo) {
  const fields = [
    "subAccountNumber",
    "subAccountName",
    "refField1",
    "refField2",
  ];
  let res = "";
  res = fields
    .filter((x) => additionalInfo[x])
    .map((y) => res.concat(additionalInfo[y], "\n"))
    .join("");
  return res === "" ? "--" : res;
}

const Item = ({ item, selectedInstruction, labels }) => {
  const { field, headerName, category } = item;
  let fieldContent = selectedInstruction[field];

  if (field === "additionalInfo") {
    fieldContent = selectedInstruction[field]
      ? getAdditionalInfo(fieldContent)
      : "--";
  }

  if (
    category === "tkSettlementInstructions" &&
    fieldContent &&
    field === "accountNumber"
  ) {
    return <AccountNumberToggleComp accountNumber={fieldContent} />;
  }

  return (
    <Conditional
      condition={category === "tkSettlementInstructions" && fieldContent}
    >
      <div className="factData">
        <span className="fundFactView__label">{labels[headerName]}</span>
        <Conditional condition={field === "additionalInfo"}>
          <span
            className="factData-item"
            style={{ whiteSpace: "break-spaces" }}
          >
            {fieldContent}
          </span>
          <span className="factData-item">{fieldContent}</span>
        </Conditional>
      </div>
    </Conditional>
  );
};

Item.propTypes = {
  item: PropTypes.object,
  selectedInstruction: PropTypes.object,
  labels: PropTypes.object,
};

const StatementItem = ({ item, downloadStatementPDF }) => {
  const handleStatementClick = (statementId, fileName) => {
    downloadStatementPDF({statementId, fileName});
  };

  return (
    <div className="factData--statement">
      <Link
        isDisabled={!item.statementId}
        extraProps={{[EXTERNAL_LINK_SKIP_ATTRIBUTE]: true }}
        handleClick={() => handleStatementClick(item.statementId, item.statementDateMonth.concat('_', item.statementDateYear, '_Statement.pdf'))}
        label={item.statementDateMonth.concat(' ', item.statementDateYear)}
      />
    </div>
  );
};
StatementItem.propTypes = {
  item: PropTypes.object,
  downloadStatementPDF: PropTypes.func
};

function getStatementList(showShowStatement, statementList) {
  return showShowStatement ? statementList : statementList.slice(0, 6);
}

const Statement = ({ statementList, downloadStatementPDF }) => {
  const [showShowStatement, setShowStatement] = useState(false);
  const toggleStatementView = () => {
    setShowStatement(!showShowStatement);
  };

  return (
    <div>
      <Repeater
        items={getStatementList(showShowStatement, statementList)}
        props={{ downloadStatementPDF }}
      >
        <StatementItem />
      </Repeater>
      <br />
      <div className="factData--statement">
        <Link
          handleClick={toggleStatementView}
          label={
            showShowStatement ? `${t("tkViewLess")}` : `${t("tkViewMore")}`
          }
        />
      </div>
    </div>
  );
};

Statement.propTypes = {
  statementList: PropTypes.array,
  downloadStatementPDF: PropTypes.func
};

/*eslint-disable */
const simpleRenderer = ({
  option,
  hoverOption,
  isHovered,
  selectOption,
  labelKey,
  valueKey,
  selectedLabel,
}) => {
  const isSelected = selectedLabel === option[labelKey];
  return (
    <li
      aria-label={option[labelKey]}
      key={option[valueKey]}
      className={cn(
        "trade-dropdown__row",
        { "trade-dropdown__row--selected": isSelected },
        { "trade-dropdown__row--hovered": isHovered }
      )}
      onKeyPress={hoverOption}
      onMouseDown={() => selectOption(option)}
    >
      {option[labelKey]}
    </li>
  );
};

function isDummySSI(instruction) {
  const dummySSI = {
    paymentInstructionId: "000000000000000000000000",
    label: "***",
    bankName: "************",
    accountName: "***********",
    accountNumber: "************",
    swiftCode: "******",
    currency: "***",
  };
  return (
    instruction.paymentInstructionId === dummySSI.paymentInstructionId &&
    instruction.label === dummySSI.label &&
    instruction.bankName === dummySSI.bankName &&
    instruction.accountName === dummySSI.accountName &&
    instruction.accountNumber === dummySSI.accountNumber &&
    instruction.swiftCode === dummySSI.swiftCode &&
    instruction.currency === dummySSI.currency
  );
}

const AccountDetailBody = ({
  instructionList,
  items,
  labels,
  statementList,
  downloadStatementPDF,
  achUser,
  debitCreditState,
  settlementState,
  statementState,
  isDownloadStatementFailed
}) => {
  const [selectedInstruction, setSelectedInstruction] = useState(
    instructionList.length > 0 ? instructionList[0] : {}
  );

  useEffect(() => {
    setSelectedInstruction(
      instructionList.length > 0 ? instructionList[0] : {}
    );
  }, [instructionList]);

  const onInstructionIdChange = (data) => {
    setSelectedInstruction(data);
  };

  const isSuppressed =
    instructionList.length === 1 && isDummySSI(instructionList[0]);

  return (
    <div>
      <div className="fundFactView__list--accountDetail">
        <div className="factHead">
          <Conditional condition={settlementState === 1}>
            <span className="accountFactLabel">
              <span>
                {t("tkSettlementInstructions")}
              </span>
              <span className="fundFactView__loadingSpinner">
                <LoadingIcon shape="circle" size="sm"/>
              </span>
            </span>
            <span className="fundFactView__label">
              {t("tkSettlementInstructions")}
            </span>
          </Conditional>
          <Conditional condition={!isSuppressed && settlementState === 0}>
            <span className="factData-item">
              <Select
                labelKey="label"
                optionRenderer={simpleRenderer}
                options={instructionList}
                testId={`trade-row-tradeType-`}
                selectedOption={selectedInstruction}
                clickHandler={onInstructionIdChange}
                isDisabled={instructionList.length === 1}
              />
            </span>
          </Conditional>
        </div>
        {settlementState === 0 &&
          <Conditional condition={isSuppressed }>
            <div className="factData">{labels["tkEndClientSuppressionMsg"]}</div>
            <Repeater items={items} props={{ selectedInstruction, labels }}>
              <Item />
            </Repeater>
          </Conditional>
        }
        <Conditional condition={settlementState === 2}>
          <div className='factData'>{t("tkSettlementErrorMsg")}</div>
        </Conditional>
      </div>

      {(debitCreditState > 0 ) &&
        <div className="factHead">
          <span className="accountFactLabel">
            <span>
              {t("tkDebitCreditInstructions")}
            </span>
            <Conditional condition={debitCreditState === 1}>
              <span className="fundFactView__loadingSpinner">
                <LoadingIcon shape="circle" size="sm"/>
              </span>
            </Conditional>
          </span>
        </div>
      }
      <Conditional condition={debitCreditState === 2}>
        <div className='factData'>{t("tkDebtCredErrorMsg")}</div>
      </Conditional>
      {(achUser && debitCreditState ===0) && <DebitCreditInstructions items={items} />}

      <div id='pdf-link-container' className="factHead">
        <span className="accountFactLabel">
          {t('tkStatements')}
          {(statementState===1)  &&
            <span className="fundFactView__loadingSpinner">
              <LoadingIcon shape="circle" size="sm"/>
            </span>
          }
        </span>
      </div>
      {isDownloadStatementFailed && <Toast
        type={"error"}
        autoHide={false}
        onClear={clearDownloadStatementFailureMessage}
        displayText={"tkStatementDownloadError"}
        classNameProp={'margin-bottom-18 fact-card'}
      />}

      {(statementState===0) &&
        <Conditional condition={statementList.length === 0}>
          <div className="factData">{labels["tkNoStatementMsg"]}</div>
          <Statement
            labels={labels}
            statementList={statementList}
            downloadStatementPDF={downloadStatementPDF}
          />
        </Conditional>
      }
      <Conditional condition={statementState === 2}>
        <div className='factData'>{t("tkStatementErrorMsg")}</div>
      </Conditional>
    </div>
  );
};

AccountDetailBody.propTypes = {
  instructionList: PropTypes.array,
  items: PropTypes.array,
  labels: PropTypes.object,
  statementList: PropTypes.array,
  downloadStatementPDF: PropTypes.func,
  achUser: PropTypes.bool,
  isDownloadStatementFailed: PropTypes.bool
};

const mapStateToProps = (state) => ({
  achUser: getIsACHUser(state),
  debitCreditState: getDebitCreditState(state),
  settlementState: getInstructionState(state),
  statementState: getStatementState(state),
  isDownloadStatementFailed: getIsDownloadStatementFailed(state)
});

const mapDispatchToProps = (dispatch) => ({
  clearDebitCreditEditStatus: () => dispatch(clearDownloadStatementFailureMessage()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetailBody);
