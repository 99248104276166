import { HeadingTypography } from '@gs-ux-uitoolkit-common/design-system';
import { HeadingCssClasses } from './heading-style-sheet';
import { TypographyCommonProps } from './typography-common-props';

export interface HeadingCommonProps extends TypographyCommonProps {
    /**
     * Typography variant of the GS Design System.
     *
     * View the Typography page in the Styling Foundation section of the UI Toolkit docs
     * for more info on when to use each variant.
     */
    typography?: HeadingTypography;

    /**
     * Style classes to override.
     */
    classes?: Partial<HeadingCssClasses>;
}

export const defaultHeadingProps: DefaultHeadingProps = {
    typography: 'heading01',
    color: 'primary',
    display: 'block',
    bold: false,
    italic: false,
    underline: false,
};

export type DefaultHeadingProps = Required<
    Pick<HeadingCommonProps, 'typography' | 'color' | 'bold' | 'italic' | 'underline' | 'display'>
>;
