import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {pageIdSelector} from '../../selectors/pages';
import ControlPanel from './ControlPanel';
import {preferenceNavLinksSelector, preferenceRouteConfigSelector} from '../../selectors/global';
import Constants, {ROUTE_ID} from '../../constants/appConstants';
import {WithPageError} from '../WithPageError';
import {InjectRoutes} from '../../routes';
import {canShowEmailPreferenceNavItemSelector, isInvestmentPolicyAllowedSelector} from '../../selectors/user';
import PolicyDetails from './Sections/InvestmentPolicy/PolicyDetails';
import {getAppRoute} from '../../utils/commonUtils';
import {getInvestmentPolicyConfig} from '../../selectors/pages/preferences/investmentPolicy';

export const Preferences = ({
  preferencesStepperConfig, currentPageId, routes, preferenceNavLinks, dispatch, canShowEmailPreferenceNavItem
  , childRoutes, isInvestmentPolicyAllowed}) => {
  const extendedRoutes = [];
  const extendedConfig = [];
  // inject child component in routes
  const subRoutes =  childRoutes.map((config) => {
    return {...config, component: PolicyDetails};
  });

   // insert subRoutes in in InvestmentPolicy page route
  routes.forEach((route) => {
    if(route.id === ROUTE_ID.INVESTMENT_POLICY){
      isInvestmentPolicyAllowed && extendedRoutes.push({...route,  subRoutes});
    } else {
      extendedRoutes.push(route);
    }
  });

   preferencesStepperConfig.forEach((config) => {
    if(config.id ===  ROUTE_ID.INVESTMENT_POLICY){
       isInvestmentPolicyAllowed && extendedConfig.push({...config,  subRoutes});
    } else {
      extendedConfig.push(config);
    }
  });
  return (
    <div className="preferences__content">
      <ControlPanel
        config={extendedConfig}
        currentPageId={currentPageId}
      />
      <div className="section">
        <WithPageError>
          <InjectRoutes
            routes={extendedRoutes}
            canShowEmailPreferenceNavItem={canShowEmailPreferenceNavItem}
            navLinks={preferenceNavLinks}
            redirectRoutes={[getAppRoute(Constants.PREFERENCES_ROUTE)]}
            dispatch={dispatch}
          />
        </WithPageError>
      </div>
    </div>
  );
};

Preferences.propTypes = {
  preferencesStepperConfig: PropTypes.array,
  currentPageId: PropTypes.string,
  preferenceNavLinks: PropTypes.array,
  routes: PropTypes.array,
  dispatch: PropTypes.func,
  isInvestmentPolicyAllowed: PropTypes.bool,
  canShowEmailPreferenceNavItem: PropTypes.bool,
  childRoutes: PropTypes.string
};

const mapStateToProps = (state) => (
  {
    preferencesStepperConfig: preferenceRouteConfigSelector(state),
    currentPageId: pageIdSelector(state),
    preferenceNavLinks: preferenceNavLinksSelector(state),
    canShowEmailPreferenceNavItem: canShowEmailPreferenceNavItemSelector(state),
    childRoutes: getInvestmentPolicyConfig(state),
    isInvestmentPolicyAllowed: isInvestmentPolicyAllowedSelector(state)
  }
);

export default withRouter(connect(mapStateToProps)(Preferences));
