export default {
  FETCH_LITERATURE: 'FETCH_LITERATURE',
  FETCH_FUND_FACT_CARD_CONFIGURATIONS: 'FETCH_FUND_FACT_CARD_CONFIGURATIONS',
  FETCH_ACCOUNT_REGISTRATION: 'FETCH_ACCOUNT_REGISTRATION',
  FETCH_TRADE_BLOTTER_ACCOUNT_INPUTS: 'FETCH_TRADE_BLOTTER_ACCOUNT_INPUTS',
  FETCH_SETTLEMENT_INSTRUCTION: 'FETCH_SETTLEMENT_INSTRUCTION',
  FETCH_ACCOUNT_STATEMENT: 'FETCH_ACCOUNT_STATEMENT',
  DOWNLOAD_STATEMENT_PDF: 'DOWNLOAD_STATEMENT_PDF',
  FETCH_DEBIT_CREDIT_INFO: 'FETCH_DEBIT_CREDIT_INFO',
  UPDATE_DEBIT_CREDIT: 'UPDATE_DEBIT_CREDIT',
  CLEAR_DEBIT_CREDIT_EDIT_STATUS: 'CLEAR_DEBIT_CREDIT_EDIT_STATUS',
  CLEAR_DOWNLOAD_STATEMENT_FAILURE_MESSAGE: 'CLEAR_DOWNLOAD_STATEMENT_FAILURE_MESSAGE',
  CLEAR_ACCOUNT_DETAIL_DATA: 'CLEAR_ACCOUNT_DETAIL_DATA',
  SAVE_FUND_REBATE: 'SAVE_FUND_REBATE'
};
