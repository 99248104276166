import { Size } from '@gs-ux-uitoolkit-common/design-system';
import { DropdownCssClasses } from './dropdown-style-sheet';

export interface DropdownCommonProps {
    /**
     * Size of the dropdown.
     */
    size?: DropdownSize;

    /**
     * Controls the visible state of the dropdown menu.
     *
     * Do not set `visible` if you want the dropdown to show/hide the menu on its own.
     */
    menuVisible?: boolean;

    /**
     * Handler called when the dropdown menu is shown or hidden.
     */
    onMenuToggle?: (event: DropdownMenuToggleEvent) => void;

    /**
     * Additional classes to add on the root element.
     */
    className?: string;

    /**
     * Style classes to override.
     */
    classes?: Partial<DropdownCssClasses>;
}

export const defaultDropdownProps: DefaultDropdownProps = {
    size: 'md',
};

export type DefaultDropdownProps = Required<Pick<DropdownCommonProps, 'size'>>;

export type DropdownSize = Extract<Size, 'sm' | 'md' | 'lg' | 'xl'>;

export const dropdownSizes: DropdownSize[] = ['sm', 'md', 'lg', 'xl'];

export interface DropdownMenuToggleEvent {
    visible: boolean;
}
