import { CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { StyleSheet } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './menu-divider-theme-overrides';

export interface MenuDividerStyleSheetProps {
    theme: Theme;
}

export interface MenuDividerCssClasses {
    root: string;
}

export type MenuDividerStyledClasses = CssClassDefinitionsObject<keyof MenuDividerCssClasses>;

export interface MenuDividerStyleOverridesParams {
    props: DeepReadonly<MenuDividerStyleSheetProps>;
    createDefaultStyledClasses: () => MenuDividerStyledClasses;
}

export const menuDividerStyleSheet = new StyleSheet(
    'menuDivider',
    (props: MenuDividerStyleSheetProps) => {
        return createComponentClassDefinitions<
            MenuDividerStyleSheetProps,
            MenuDividerStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.menuDivider);
    }
);

function createDefaultStyledClasses({
    theme,
}: MenuDividerStyleSheetProps): MenuDividerStyledClasses {
    return {
        root: {
            margin: '2px 0 3px',
            borderTopColor: theme.border.minimal,
            borderWidth: '1px 0 0 0',
            borderStyle: 'solid',
        },
    };
}
