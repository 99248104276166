import * as Redux from 'redux';
import { AllActionTypes } from './actions/all-action-types';
import { CrossModelState } from './cross-model-state';
import { crossModelReducer } from './reducers/cross-model-reducer';

/**
 * The store related to the crossmodel
 */
export class CrossModelStore {
    private reduxStore: Redux.Store<CrossModelState, AllActionTypes>;
    constructor() {
        const middleware = Redux.applyMiddleware();

        this.reduxStore = Redux.legacy_createStore<CrossModelState, AllActionTypes, object, object>(
            crossModelReducer,
            middleware
        );
    }

    public getReduxStore(): Redux.Store<CrossModelState, AllActionTypes> {
        return this.reduxStore;
    }
}
