import { FunctionComponent } from 'react';
import { useStyleSheet, cx } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { dragAndDropPlaceholderStyleSheet } from './drag-and-drop-placeholder-style-sheet';

export interface DragAndDropPlaceholderProps {
    width: number;
    height: number;
    top: number;
    left: number;
    spacing: number;
    classes?: Record<'placeholder' | 'placeholderInner', string | undefined>;
}

export const DragAndDropPlaceholder: FunctionComponent<DragAndDropPlaceholderProps> = ({
    spacing,
    classes: overrideClasses,
    ...size
}) => {
    const theme = useTheme();
    const dragAndDropPlaceholderClasses = useStyleSheet(dragAndDropPlaceholderStyleSheet, {
        theme,
        spacing,
    });

    const placeholderClasses = cx(
        dragAndDropPlaceholderClasses.placeholder,
        overrideClasses?.placeholder
    );
    const placeholderInnerClasses = cx(
        dragAndDropPlaceholderClasses.placeholderInner,
        overrideClasses?.placeholderInner
    );

    return (
        <div
            data-cy="gs-uitk-droppable__placeholder"
            data-testid="droppablePlaceholder"
            className={placeholderClasses}
            style={size}
        >
            <div
                data-cy="gs-uitk-droppable__placeholder__inner"
                data-testid="droppablePlaceholderInner"
                className={placeholderInnerClasses}
            ></div>
        </div>
    );
};
