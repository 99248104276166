import React from 'react';
import {PropTypes} from 'prop-types';
import './index.scss';

const BuildInfo = ({labels}) => (
  <div className="commitversion">
    <div className="commitversion__container">
      <div className="commitversion__header">
        {labels.tkBuildDetails}
      </div>
      <div className="commitversion__item-container">
        <span>{labels.tkCommitHash}</span>
        <span className="commitversion__value">{process.env.GIT_COMMIT_HASH}</span>
      </div>
      <div className="commitversion__item-container">
        <span>{labels.tkCommitDate}</span>
        <span className="commitversion__value">{process.env.GIT_COMMIT_DATE}</span>
      </div>
    </div>
  </div>
);

BuildInfo.propTypes = {
  labels: PropTypes.object
};

export default BuildInfo;
