export default {
  ADD_TRADE_APPROVAL_DATA: 'ADD_TRADE_APPROVAL_DATA',
  UPDATE_TRADE_APPROVAL_DATA: 'UPDATE_TRADE_APPROVAL_DATA',
  TRADE_APPROVAL_DATA_LOADING: 'TRADE_APPROVAL_DATA_LOADING',
  APPROVE_TRADE: 'APPROVE_TRADE',
  REJECT_TRADE: 'REJECT_TRADE',
  ADD_TRADE_APPROVAL_RESPONSE:'ADD_TRADE_APPROVAL_RESPONSE',
  ADD_TRADE_REJECTION_RESPONSE: 'ADD_TRADE_REJECTION_RESPONSE',
  OPEN_TRADE_REJECTION_MODAL:'OPEN_TRADE_REJECTION_MODAL',
  CLOSE_TRADE_REJECTION_MODAL:'CLOSE_TRADE_REJECTION_MODAL',
  CLOSE_TRADE_APPROVAL_MODAL:'CLOSE_TRADE_APPROVAL_MODAL',
  OPEN_TRADE_APPROVAL_MODAL:'OPEN_TRADE_APPROVAL_MODAL',
  RELOAD_TRADE_APPROVAL_DATA: 'RELOAD_TRADE_APPROVAL_DATA',
  TRADE_APPROVAL_LOADER: 'TRADE_APPROVAL_LOADER',
  TRADE_REJECTION_LOADER: 'TRADE_REJECTION_LOADER',
  CLEAR_TRADE_APPROVAL_RESPONSE:'CLEAR_TRADE_APPROVAL_RESPONSE',
  CLEAR_TRADE_REJECTION_RESPONSE: 'CLEAR_TRADE_REJECTION_RESPONSE',
  DISABLE_CURRENT_TRADE: 'DISABLE_CURRENT_TRADE'
};
