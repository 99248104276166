import { FC, useContext, useEffect } from 'react';
import { DropdownButton, DropdownButtonProps } from '@gs-ux-uitoolkit-react/dropdown';
import { FormContext } from '@gs-ux-uitoolkit-react/form';
import {
    inputGroupDropdownButtonStyleSheet,
    globalInputGroupDropdownButtonClass,
} from '@gs-ux-uitoolkit-common/input';
import { cx, useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { componentAnalytics } from './analytics-tracking';

export interface InputGroupDropdownButtonProps extends Omit<DropdownButtonProps, 'size'> {}

/**
 * InputGroupDropdownButton allows a [Dropdown](./dropdown) to be integrated cohesively into a containing
 * InputGroup component.
 */
export const InputGroupDropdownButton: FC<InputGroupDropdownButtonProps> = props => {
    const { className, classes, disabled, status = 'none', ...otherProps } = props;

    const formContext = useContext(FormContext);

    const theme = useTheme();
    const internalSize = formContext.size || 'md';
    const cssClasses = useStyleSheet(inputGroupDropdownButtonStyleSheet, {
        theme,
        size: internalSize,
        status,
    });

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({
            componentName: 'input-group-dropdown-button',
            officialComponentName: 'other',
        });
    }, []); // Only run once

    return (
        <DropdownButton
            className={cx(
                cssClasses.root,
                className,
                classes && classes.root,
                globalInputGroupDropdownButtonClass
            )}
            disabled={disabled !== undefined ? disabled : formContext.disabled}
            size={internalSize}
            classes={classes}
            status={status}
            {...otherProps}
            // Note: data-gs-uitk-component is set by DropdownButton.
        />
    );
};

InputGroupDropdownButton.propTypes = DropdownButton.propTypes;
