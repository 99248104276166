import { Component, Fragment, ChangeEvent } from 'react';
import { FormGroup } from '@gs-ux-uitoolkit-react/form';
import { InputNumber } from '@gs-ux-uitoolkit-react/input';
import { Label } from '@gs-ux-uitoolkit-react/label';
import { Radio, RadioChangeEvent } from '@gs-ux-uitoolkit-react/radio';
import { HintList } from '@gs-ux-uitoolkit-common/datacore';
import { FormatControlProps } from '../column-hint-format-manager';
import { columnFormattingPluginStylePrefix } from '../../../../../style/plugins/column-hint-plugin-stylesheet';

export enum ColumnHintWidthOption {
    Auto = 'Auto',
    Pixels = 'Pixels',
}

export class ColumnHintControlColumnWidth extends Component<FormatControlProps> {
    public render() {
        const autoWidthChecked = this.props.columnHint.columnWidth === undefined;
        return (
            <Fragment>
                <FormGroup>
                    <Label>Column Width</Label>
                    <div
                        className={`${columnFormattingPluginStylePrefix}_flex-two-column-container ${columnFormattingPluginStylePrefix}_layout-column-size`}
                    >
                        <Radio
                            name={ColumnHintWidthOption.Auto}
                            value={ColumnHintWidthOption.Auto}
                            onChange={this.onChangeWidthOption}
                            checked={autoWidthChecked}
                            data-cy={`${columnFormattingPluginStylePrefix}_auto-radio`}
                        >
                            Auto-width
                        </Radio>
                        <div className={`${columnFormattingPluginStylePrefix}_layout-column-pixel`}>
                            <Radio
                                name={ColumnHintWidthOption.Pixels}
                                value={ColumnHintWidthOption.Pixels}
                                onChange={this.onChangeWidthOption}
                                checked={!autoWidthChecked}
                                data-cy={`${columnFormattingPluginStylePrefix}_pixel-radio`}
                            >
                                Pixels
                            </Radio>
                            <InputNumber
                                className={'column-hint-control-column-width-pixel-input'}
                                value={this.props.columnHint.columnWidth}
                                defaultValue={100}
                                onChange={this.onChangePixelValue}
                                disabled={autoWidthChecked}
                                data-cy={`${columnFormattingPluginStylePrefix}_pixel-input`}
                            />
                        </div>
                    </div>
                </FormGroup>
            </Fragment>
        );
    }

    private onChangeWidthOption = (e: RadioChangeEvent) => {
        const value = e.value;

        if (value === ColumnHintWidthOption.Auto) {
            const clonedHint: HintList = {
                ...this.props.columnHint,
            };
            delete clonedHint.columnWidth;
            this.props.onChangeHint(clonedHint);
        } else if (value === ColumnHintWidthOption.Pixels) {
            const clonedHint: HintList = {
                ...this.props.columnHint,
                columnWidth: 100,
            };
            this.props.onChangeHint(clonedHint);
        }
    };

    private onChangePixelValue = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const clonedHint: HintList = {
            ...this.props.columnHint,
            columnWidth: parseInt(value, 10),
        };
        this.props.onChangeHint(clonedHint);
    };
}
