import {
    ExpressionCondition,
    ExpressionGroup,
    ExpressionRule,
    genericExpressionHelper,
} from '@gs-ux-uitoolkit-common/datacore';
import { Accordion } from '@gs-ux-uitoolkit-react/accordion';
import { Component } from 'react';
import { expressionHelper } from '../../../libraries/helpers/expression-helper';
import { ButtonDisplay } from '../../buttons/button-base';
import { ButtonCreate } from '../../buttons/button-create';
import { ExpressionProps } from '../expression-props';
import { ExperiencedExpressionGroup } from './experienced-expression-group';
import { ExperiencedExpressionRule } from './experienced-expression-rule';
import { rangeInputHelper } from './range-inputs/range-input-helper';
import { isEqual } from 'gs-uitk-lodash';

export class ExperiencedExpression extends Component<ExpressionProps> {
    constructor(props: ExpressionProps) {
        super(props);
    }
    public shouldComponentUpdate(nextProps: ExpressionProps) {
        if (isEqual(this.props, nextProps)) {
            return false;
        }
        return true;
    }
    public render() {
        if (genericExpressionHelper.isGroupRule(this.props.expressionQuery)) {
            const groupRule = this.props.expressionQuery as ExpressionGroup;

            return (
                <>
                    <Accordion>
                        <ExperiencedExpressionGroup
                            columns={this.props.columns}
                            expressionGroup={groupRule}
                            handleNegatedRules={this.handleNegatedRules}
                            isLastChild={true}
                            invertBackgroundColor={false}
                            level={'0'}
                            index={0}
                            onEditExpression={newGroup =>
                                this.props.onChangeExpressionQuery(newGroup)
                            }
                            onDeleteExpression={() => this.onDeleteExpression()}
                            getFormattedValueFromColumn={this.props.getFormattedValueFromColumn}
                            showRowGroups={this.props.showRowGroups}
                        />
                    </Accordion>

                    <ButtonCreate
                        displayType={ButtonDisplay.IconLabel}
                        label={'Add Group'}
                        className={'gs-uitk-experienced-expression-add-group-button'}
                        onClick={this.onButtonCreateClicked}
                        actionType="secondary"
                        emphasis="minimal"
                        size="sm"
                    />
                </>
            );
        }
        const simpleRule = this.props.expressionQuery as ExpressionRule;
        return (
            <ExperiencedExpressionRule
                columns={this.props.columns}
                ruleNumber={1}
                expressionRule={this.handleNegatedRules(simpleRule)}
                onEditExpression={newExpression =>
                    this.props.onChangeExpressionQuery(newExpression)
                }
                onDeleteExpression={() => this.onDeleteExpression()}
                getFormattedValueFromColumn={this.props.getFormattedValueFromColumn}
                showRowGroups={this.props.showRowGroups}
            />
        );
    }
    private onButtonCreateClicked = () => {
        this.addNewGroup();
    };

    private handleNegatedRules = (query: ExpressionRule): ExpressionRule => {
        const newQuery = query;
        if (newQuery.negate) {
            const newOperator = rangeInputHelper.getNegatedOperators(newQuery.operator);
            if (newOperator) {
                newQuery.operator = newOperator;
            }
        }
        newQuery.negate = undefined;
        return newQuery;
    };

    private addNewGroup() {
        const newGroup = expressionHelper.addNewExpressionGroupToNode(
            this.props.expressionQuery as ExpressionGroup
        );
        this.props.onChangeExpressionQuery(newGroup);
    }
    private onDeleteExpression() {
        // we reinit to a Group Expression all the time
        this.props.onChangeExpressionQuery({ condition: ExpressionCondition.And, rules: [] });
    }
}
