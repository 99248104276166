import { ContainerProps, defaultContainerProps } from './container-props';
import { RowProps } from './row-props';

export interface LayoutContext {
    breakpoints?: ContainerProps['breakpoints'];
    grid?: ContainerProps['grid'];
    density?: ContainerProps['density'];
    noGutters: ContainerProps['noGutters'];
    colsAtXs?: RowProps['colsAtXs'];
    colsAtSm?: RowProps['colsAtSm'];
    colsAtMd?: RowProps['colsAtMd'];
    colsAtLg?: RowProps['colsAtLg'];
    colsAtXl?: RowProps['colsAtXl'];
    colsAtXXl?: RowProps['colsAtXXl'];
    colsAtXXXl?: RowProps['colsAtXXXl'];
}

export const defaultLayoutContext: LayoutContext = {
    density: defaultContainerProps.density,
    noGutters: defaultContainerProps.noGutters,
};
