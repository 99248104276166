import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './popover-target-theme-overrides';

export interface PopoverTargetStyleSheetProps {
    theme: Theme;
    inline?: boolean;
}

export interface PopoverTargetCssClasses {
    target: string;
}

export type PopoverTargetStyledClasses = CssClassDefinitionsObject<keyof PopoverTargetCssClasses>;

export interface PopoverTargetStyleOverridesParams {
    props: DeepReadonly<PopoverTargetStyleSheetProps>;
    createDefaultStyledClasses: () => PopoverTargetStyledClasses;
}

export const popoverTargetStyleSheet = new StyleSheet(
    'popover-target',
    (props: PopoverTargetStyleSheetProps) => {
        return createComponentClassDefinitions<
            PopoverTargetStyleSheetProps,
            PopoverTargetStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.popoverTarget);
    }
);

function createDefaultStyledClasses({
    inline,
}: PopoverTargetStyleSheetProps): PopoverTargetStyledClasses {
    return {
        target: {
            // 'table-cell' allows the PopoverTarget to have a width based
            // on its content (instead of defaulting to 100%) while still
            // behaving as 'block'.
            display: inline ? 'inline-block' : 'table-cell',
        },
    };
}
