import { Plugins } from './plugin-enum';

import { AutofitPivotPlugin } from './autofit-pivot/autofit-pivot-plugin';
import { ColumnHintPlugin } from './column-hint/column-hint-plugin';
import { CrossModelPlugin } from './cross-model/cross-model-plugin';
import { CustomSortPlugin } from './custom-sort/custom-sort-plugin';
import { KeyboardShortcutPlugin } from './keyboard-shortcut/keyboard-shortcut-plugin';
import { ExportsPlugin } from './exports/exports-plugin';
import { RowCustomisationPlugin } from './row-customisation/row-customisation-plugin';
import { SavedViewPlugin } from './saved-view/saved-view-plugin';
import { MaskedColumnPlugin } from './masked-column/masked-column-plugin';
import { QuickFilterPlugin } from './quick-filter/quick-filter-plugin';
import { SearchablePlugin } from './searchable/searchable-plugin';
import { SizeAndDensityPlugin } from './size-density/size-density-plugin';
import { NotificationPlugin } from './notification/notification-plugin';
import { ZebraStripesPlugin } from './zebra-stripes/zebra-stripes-plugin';
import { GridConfigurationPlugin } from './grid-configuration/grid-configuration-plugin';
import { DraPlugin } from './dra/dra-plugin';

type EnumToPlugin = { [key in Plugins]: any };

const enumToPlugin: EnumToPlugin = {
    [Plugins.AutofitPivotPlugin]: AutofitPivotPlugin,
    [Plugins.ColumnHintPlugin]: ColumnHintPlugin,
    [Plugins.CrossModelPlugin]: CrossModelPlugin,
    [Plugins.CustomSortPlugin]: CustomSortPlugin,
    [Plugins.KeyboardShortcutPlugin]: KeyboardShortcutPlugin,
    [Plugins.RowCustomisationPlugin]: RowCustomisationPlugin,
    [Plugins.SavedViewPlugin]: SavedViewPlugin,
    [Plugins.ExportPlugin]: ExportsPlugin,
    [Plugins.MaskedColumnPlugin]: MaskedColumnPlugin,
    [Plugins.QuickFilterPlugin]: QuickFilterPlugin,
    [Plugins.SearchablePlugin]: SearchablePlugin,
    [Plugins.SizeAndDensityPlugin]: SizeAndDensityPlugin,
    [Plugins.NotificationPlugin]: NotificationPlugin,
    [Plugins.ZebraStripesPlugin]: ZebraStripesPlugin,
    [Plugins.GridConfigurationPlugin]: GridConfigurationPlugin,
    [Plugins.ColumnFilterPlugin]: undefined,
    [Plugins.DraPlugin]: DraPlugin,
    [Plugins.ToolbarConfigPlugin]: undefined,
    [Plugins.FloatingFilterPlugin]: undefined,
};

export const getRequiredModulesByPluginName = (plugin: Plugins): string[] =>
    enumToPlugin[plugin]?.requiredModules ?? [];
