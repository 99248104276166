import { ZebraStripesActionKeys } from './action-constants';

export type ZebraStripesActionTypes =
    | SetZebraStripesIntervalAction
    | SetZebraStripesBackgroundColoursAction;

export interface SetZebraStripesIntervalAction {
    type: ZebraStripesActionKeys.SET_STRIPE_INTERVAL;
    stripeInterval: number;
}

export interface SetZebraStripesBackgroundColoursAction {
    type: ZebraStripesActionKeys.SET_BACKGROUND_COLOURS;
    stripeBackgroundColour: string | undefined;
    nonStripeBackgroundColour: string | undefined;
}

export const SetStripeInterval = (stripeInterval: number): SetZebraStripesIntervalAction => ({
    stripeInterval,
    type: ZebraStripesActionKeys.SET_STRIPE_INTERVAL,
});

export const SetZebraStripesBackgroundColours = (
    stripeBackgroundColour: string | undefined,
    nonStripeBackgroundColour: string | undefined
): SetZebraStripesBackgroundColoursAction => ({
    stripeBackgroundColour,
    nonStripeBackgroundColour,
    type: ZebraStripesActionKeys.SET_BACKGROUND_COLOURS,
});
