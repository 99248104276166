export default {
  'PING': 'PING',
  'PING_INITIAL_CALL': 'PING_INITIAL_CALL',
  'LOGIN': 'Login',
  'CREATE_PASSWORD': 'CreatePassword',
  'CREATE_PIN': 'CreatePin',
  'LOGOUT': 'Logout',
  'INTERSTITIAL_MODAL': 'InterstitialModal',
  'HOME_ROUTE': '/',
  'CONTENT_ROUTE': '/content',
  'LOGIN_PATHS': {
    '200': 'etc/clientlibs/mosaic-ui/login',
    '404': 'Cannot GET /content/login'
  },
  'FORGOT_PWD_INIT_TIMESTAMP' : 'forgotPwdInitTimeStamp',
  'LOGIN_PAGES': [
    'Login',
    'CreatePassword',
    'CreatePin',
    'ForgotPassword'
  ]
};
