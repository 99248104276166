import { FC, memo, useInsertionEffect } from 'react';
import { isBrowser } from '@gs-ux-uitoolkit-common/shared';
import { CssProperties } from '@gs-ux-uitoolkit-common/style';
import { useEmotionInstance } from './emotion-instance-context';

/**
 * The `<GlobalStyles>` component allows for CSS styles to be injected into the
 * document without any of the normal CSS-in-JS style encapsulation (i.e.
 * without any checksum hashes being added to the CSS class names).
 *
 * > **Note:** it is **very rare** that you will need this component. Only use if
 * no alternatives are available. It is especially dangerous to use this in a
 * multi-tenant system with multiple microfrontends because you can accidentally
 * affect the styling of other apps you don't own. See
 * [Styling Components](/docs/react/styling-components) and
 * [styled() and styledClasses()](/docs/react/styled)
 * articles first about how styles can be encapsulated before using this component.
 *
 * This component is useful to style say, the `<html>` or `<body>` tags, other
 * element tags, or other global CSS classes.
 *
 * This may also be useful to style 3rd party components that cannot be styled
 * otherwise. However, it is preferred to use the [styled()](/docs/react/styled)
 * function to create a component that will wrap the 3rd party component, and
 * use descendant selectors to style the component instead (so you don't
 * accidentally style CSS classes that may be used by others in different a
 * context, such as within a different microfrontend entirely).
 *
 * Note: Use this component instead of the old `injectGlobal()` function to
 * support server-side rendering (SSR) of global styles with Next.js's
 * [App Router](https://nextjs.org/docs/app). See
 * [Server-Side Rendering (SSR)](/docs/react/ssr) for details on using
 * server-side rendering with the UI Toolkit.
 *
 * ### Performance Note
 *
 * In order to make the `<GlobalStyles>` component as efficient as possible when
 * it is re-rendered, use the same exact "styles object" on each re-render.
 *
 * For example:
 *
 * ```tsx
 * // BAD ❌ - style object is re-created in memory on each render
 * const MyComponent = () => {
 *     return (
 *         <GlobalStyles
 *             styles={{  // <-- this object is re-created on each render of MyComponent
 *                 html: {
 *                     height: '100%',
 *                 },
 *                 body: {
 *                     height: '100%',
 *                     backgroundColor: 'green',
 *                     color: 'red',
 *                 }
 *                 '.some-class': {
 *                     width: '50%',
 *                 }
 *             }}
 *         />
 *     );
 * };
 * ```
 *
 * ```tsx
 * // Good ✅ - `myStyles` is not part of React render tree, so object is only
 * // created once and reference is always the same
 * const myStyles = {
 *     html: {
 *         height: '100%',
 *     },
 *     body: {
 *         height: '100%',
 *         backgroundColor: 'green',
 *         color: 'red',
 *     }
 *     '.some-class': {
 *         width: '50%',
 *     }
 * };
 *
 * const MyComponent = () => {
 *     return (
 *         <GlobalStyles styles={myStyles} />
 *     );
 * };
 * ```
 */
export const GlobalStyles: FC<GlobalStylesProps> = memo(({ styles }) => {
    const emotion = useEmotionInstance();

    /* istanbul ignore if */
    if (!isBrowser) {
        // The useInsertionEffect() (in the below 'else' clause) will not run
        // for server-side rendering, so we need to run it here
        emotion.injectGlobal(styles);
    } else {
        // Note: the below hook is called conditionally, but it is based on a
        // constant depending on the JavaScript runtime environment.
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useInsertionEffect(() => {
            emotion.injectGlobal(styles);
        }, [emotion]);
    }

    return null; // no UI for this component
});
GlobalStyles.displayName = 'GlobalStyles';

export interface GlobalStylesProps {
    /**
     * The set of CSS style rules to inject globally into the document. For
     * example:
     *
     * ```tsx
     * const myStyles = {
     *     html: { height: '100%' },
     *     body: { height: '100%', backgroundColor: 'blue' },
     *
     *     // some global class name (generally don't use this)
     *     '.some-global-class': {
     *         color: 'green',
     *     },
     *
     *     // media queries and other "at-rules" supported
     *     '@media screen and (min-width: 768px)': {
     *         '.some-global-class': {
     *             color: 'red',
     *         },
     *     },
     * };
     *
     * // ...
     *
     * <GlobalStyles styles={myStyles} />
     * ```
     */
    styles: { [selector: string]: CssProperties };
}
