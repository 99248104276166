import { cx } from '@gs-ux-uitoolkit-common/style';
import { IconBaseProps, IconRequiredProps } from './props';
import { IconCssClasses } from './icon-style-sheet';
import { getCodePointFromIconName } from './generated/fonts';
import { resolveIconFont } from './icon-to-fonts';
import { IconName } from './design-system-icons/full-set';
import { resolveIconFromV16IconName } from './resolve-icon-from-v16';
import { resolveIconFromV17IconName } from './resolve-icon-from-v17';

export const getIconClassNames = (props: {
    cssClasses: IconCssClasses;
    name: IconBaseProps['name'];
    className?: IconBaseProps['className'];
    overrideClasses?: IconBaseProps['classes'];
}): string => {
    const { cssClasses: classes, className, overrideClasses } = props;

    return cx(classes.root, className, overrideClasses && overrideClasses.root, 'gs-icon');
};

export const getIconContentClassNames = (props: {
    cssClasses: IconCssClasses;
    overrideClasses?: IconBaseProps['classes'];
}): string => {
    const { cssClasses: classes, overrideClasses } = props;

    return cx(classes.content, overrideClasses && overrideClasses.content);
};

/**
 * Returns the code point for the provided icon props.
 *
 * Code points are used to render the icon ligature from a font file.
 *
 * Note: If the provided icon `name` and `type` combination is not supported in the GSDS,
 * the code point will render as text instead of an icon.
 */
export function getIconCodePoint(props: IconRequiredProps): string {
    const { name, type } = props;

    const font = resolveIconFont(props);
    if (font === 'custom' && type === 'outlined') {
        const outlinedSuffix = '-outline';
        const iconName = name + outlinedSuffix;
        return getCodePointFromIconName(iconName);
    }

    return getCodePointFromIconName(name);
}

export function getAriaLabel(iconName: IconName) {
    return iconName
        .split('-')
        .map(str => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase())
        .join(' ');
}
export const resolveIconName = (name: IconName) => {
    return resolveIconFromV17IconName(resolveIconFromV16IconName(name));
};
