import { CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { StyleSheet } from '@gs-ux-uitoolkit-common/style';
import { MenuSize } from './menu-props';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './menu-checkbox-option-theme-overrides';

export interface MenuCheckboxOptionStyleSheetProps {
    theme: Theme;
    size?: MenuSize;
}

export interface MenuCheckboxOptionCssClasses {
    root: string;
    checkbox: string;
}

export type MenuCheckboxOptionStyledClasses = CssClassDefinitionsObject<
    keyof MenuCheckboxOptionCssClasses
>;

export interface MenuCheckboxOptionStyleOverridesParams {
    props: DeepReadonly<MenuCheckboxOptionStyleSheetProps>;
    createDefaultStyledClasses: () => MenuCheckboxOptionStyledClasses;
}

export const menuCheckboxOptionStyleSheet = new StyleSheet(
    'menu-checkbox-option',
    (props: MenuCheckboxOptionStyleSheetProps) => {
        return createComponentClassDefinitions<
            MenuCheckboxOptionStyleSheetProps,
            MenuCheckboxOptionStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.menuCheckboxOption);
    }
);

function createDefaultStyledClasses({
    theme,
    size,
}: MenuCheckboxOptionStyleSheetProps): MenuCheckboxOptionStyledClasses {
    let optionHeight = '32px';
    let checkboxOptionPadding = '0 12px';
    if (size === 'sm') {
        optionHeight = '28px';
        checkboxOptionPadding = '0 10px';
    } else if (size === 'lg') {
        optionHeight = '44px';
        checkboxOptionPadding = '0 16px';
    }
    const interactionShades = theme.getSurfaceInteractionShades('primary');
    return {
        root: {
            width: '100%',
            maxWidth: '100%',
            height: optionHeight,
            lineHeight: optionHeight,
            display: 'block',
            overflow: 'hidden',
            padding: checkboxOptionPadding,
            '&:hover:not(:disabled), &:focus:not(:disabled)': {
                backgroundColor: interactionShades.hover,
                outline: 'none',
                cursor: 'pointer',
            },
        },
        checkbox: {
            display: 'inline-block',
            width: '100%',
            '> span, > gs-label': {
                width: '100%',
                maxWidth: '100%',
                '> label': {
                    display: 'block', // refer https://gitlab.aws.site.gs.com/wf/mwp-ui/gs-ux-uitoolkit/-/merge_requests/3258/diffs
                    paddingLeft: '2px',
                    width: '100%',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    lineHeight: optionHeight,
                    '&:hover:not(:disabled), &:focus:not(:disabled)': {
                        cursor: 'pointer',
                    },
                    '> span:first-child': {
                        position: 'relative',
                        top: '2px',
                    },
                },
            },
        },
    };
}
