import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../core/CheckBox/index';
import { Conditional } from '../../core/Conditional';
import './index.scss';
const ViewOptionsSection = ({ category, headers, clickHandler }) => {
  const half = Math.ceil(headers.length / 2);
  const total = headers.map(column => (
    <Checkbox
      key={column.headerName}
      value={column.field}
      id={column.field}
      isChecked={column.isChecked}
      label={column.headerName}
      changeHandler={clickHandler}
      isDisabled={category === 'Required' || column.isDisabled}
    />
  ))
  return (
    <Conditional condition={headers.length}>
      <React.Fragment>
        <div className="saveNewView__optionSection">
          <div className="saveNewView__optionsHeader">{category}</div>
          <div className="saveNewView__options-list">
            <div className="saveNewView__options-list--left">
              {total.slice(0, half)}
            </div>
            <div className="saveNewView__options-list--right">
              {total.slice(half)}
            </div>
          </div>
        </div>
        <hr className="saveNewView__separator" />
      </React.Fragment>
    </Conditional>
  );
};

ViewOptionsSection.propTypes = {
  category: PropTypes.string,
  headers: PropTypes.array,
  clickHandler: PropTypes.func,
};

export default ViewOptionsSection;
