import reduceReducers from 'reduce-reducers';
import actionTypes from '../../actions/page/actionTypes';
import preferenceActions from '../../actions/preferences/actionTypes';
import sagaActions from '../../actions/sagas/actionTypes';
import {loginPageDataReducer} from './login';
import {portfolioPageContextReducer, portfolioPageDataReducer} from './portfolio';
import {fundFinderPageContextReducer, fundFinderPageDataReducer} from './fundFinder';
import {fundTrackerPageDataReducer} from './fundTracker';
import {reportPageContextReducer, reportPageDataReducer} from './reports';
import {tradeActivityPageContextReducer, tradeActivityPageDataReducer} from './tradeActivity';
import {tradeApprovalsPageContextReducer, tradeApprovalsPageDataReducer} from './tradeActivity/approvals';
import {tncPageDataReducer} from './termsAndCondition';
import {userSearchPageDataReducer, userSearchPageContextReducer} from '../../modules/admin/UserSearch/reducers'
import {importTradesPageContextReducer} from '../app/tradeBlotter/importTrades';
import {investmentPolicyDataReducer} from './preferences/investmentPolicy';
import {mimicUserPageDataReducer} from './mimic'

const defaultPageData = {};
const defaultPageContext = {};

export function rootPageDataReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.ADD_PAGE_DATA:
    case actionTypes.IS_SHOW_MOSAIC_HELP:
      return {...state, ...action.data};
    case actionTypes.CLEAR_PAGE_DATA:
    case actionTypes.UPDATE_PAGE_ID:
      return defaultPageData;
    default:
      return state;
  }
}

export function rootPageContextReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.ADD_PAGE_CONTEXT:
    case actionTypes.IS_PAGE_LOADING:
      return {...state, ...action.data};
    case actionTypes.UPDATE_PAGE_ID:
      return {...action.data};
    case preferenceActions.ADD_SAVED_PREFERENCES:
      return {...state, isPreferencesSaved: true, isPreferencesSuccess: true};
    case preferenceActions.ADD_PREFERENCES_ERROR: {
      return {...state, isPreferencesSaved: true, isPreferencesSuccess: false};
    }
    case preferenceActions.SAVE_APP_PREFERENCES: {
      return {...state, isPreferencesSaved: false};
    }
    case sagaActions.LOCATION_CHANGE:
    case preferenceActions.FIRM_PREFERENCES_CHANGED: {
      return {...state, errorObject: undefined, isPageInErrorMode: false}
    }
    case actionTypes.CLEAR_PAGE_CONTEXT:
      return defaultPageContext;
    default:
      return state;
  }
}

const contextReducers = [
  portfolioPageContextReducer,
  fundFinderPageContextReducer,
  tradeActivityPageContextReducer,
  tradeApprovalsPageContextReducer,
  importTradesPageContextReducer,
  userSearchPageContextReducer,
  reportPageContextReducer,
]; // Add more reducers here

const dataReducers = [
  loginPageDataReducer,
  portfolioPageDataReducer,
  fundFinderPageDataReducer,
  fundTrackerPageDataReducer,
  tncPageDataReducer,
  tradeActivityPageDataReducer,
  tradeApprovalsPageDataReducer,
  investmentPolicyDataReducer,
  userSearchPageDataReducer,
  mimicUserPageDataReducer,
  reportPageDataReducer,
]; // Add more reducers here

export const pageDataReducer = reduceReducers(...dataReducers, rootPageDataReducer);
export const pageContextReducer = reduceReducers(...contextReducers, rootPageContextReducer);
