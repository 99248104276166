import React, { useEffect, useState } from "react";
import DOMPurify from 'dompurify'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import {TooltipTarget} from "@gs-ux-uitoolkit-react/tooltip";
import Repeater from "../../../core/Repeater";
import { ThreeSquaresLoader } from "../../../core/Loaders";
import "./index.scss";
import Item from "./components/Item";
import {
  entitledToRebates,
  getFundRebate, getFundRebateEditStatus,
  getFundRebateLoading, getFundRebateStatus
} from "../../../../selectors/containers/fundFactView";
import {Conditional} from "../../../core/Conditional";
import {
  INPUT, REBATE_EXCEEDS_PRECISION,
  REBATE_NO_ERROR,
  REBATE_NOT_A_NUMBER,
  REBATE_OUT_OF_RANGE, REBATE_OUT_OF_RANGE_AND_EXCEEDS_PRECISION,
  REBATE_UNSAVED_CHANGES,
  regexForRebateAmount
} from "./constants";
import {saveFundRebate} from "../../../../actions/containers/fundFact";
import {isMimicSessionSelector} from "../../../../selectors/app";
import {addPageContext} from "../../../../actions/page";
import AddEditSaveButtons from "./components/AddEditSaveButtons";
import {eventAnalytics} from "../../../../actions/sagas";
import {getRebateCTAAnalyticsData} from "../../../../utils/portfolioAnalytics";

export const countDecimals = (number) => {
  if (number.includes('.'))
    return number.toString().split(".")[1].length || 0;
  return 0;
};

const validateRebateAmount = (rebateAmount, rebateExists=false) => {
  let error = REBATE_NO_ERROR;
  if(rebateAmount || ( rebateAmount === '' && !rebateExists)) {
    if (!rebateAmount.match(regexForRebateAmount)) {
      error = REBATE_NOT_A_NUMBER;
    } else if ( rebateAmount < 0 || rebateAmount > 100 ) {
      error = REBATE_OUT_OF_RANGE;
    }
    if (error === 2 && countDecimals(rebateAmount) > 2) {
      error = REBATE_OUT_OF_RANGE_AND_EXCEEDS_PRECISION;
    } else if (countDecimals(rebateAmount) > 2) {
      error = REBATE_EXCEEDS_PRECISION;
    }
  }
  return error;
};

const FundRebate = ({
  items,
  labels,
  fundRebate,
  saveFundRebate,
  fundRebateLoading,
  entitledToRebates,
  isMimicSession,
  onChangeYieldState,
  updateRebateStatusToUnsavedChanges,
  fundRebateEditStatus,
  setFundRebateEditStatus,
  setUnsavedRebateChanges,
  unsetUnsavedRebateChanges,
  sendAnalytics
}) => {

  const {tkFundRebate, tkFundRebateTooltipContent, tkRebateDisclaimer} = labels;

  const [toggleEditSave, setToggleEditSave] = useState(false);
  const [fundRebateInfo, setFundRebateInfo] = useState(fundRebate || {});
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [errorState, setErrorState] = useState(0);

  useEffect(() => {
    setFundRebateInfo(fundRebate || {});
  }, [fundRebate, fundRebateLoading]);

  useEffect(() => {
    if (fundRebateEditStatus === false) {
      setFundRebateInfo(fundRebate || {});
      setToggleEditSave(false);
      setErrorState(0);
    }
  }, [fundRebateEditStatus]);

  const onEditClick = () => {
    if(fundRebate && fundRebate.rebateAmount) {
      sendAnalytics(getRebateCTAAnalyticsData('Edit CTA', 'Fund Fact Card'));
    } else {
      sendAnalytics(getRebateCTAAnalyticsData('Add CTA', 'Fund Fact Card'));
    }
    setErrorState(validateRebateAmount(fundRebateInfo.rebateAmount, fundRebate && fundRebate.rebateAmount));
    setToggleEditSave(true);
    setSaveButtonDisabled(true);
    setFundRebateEditStatus();
  };

  const onSaveClick = async (e) => {
    sendAnalytics(getRebateCTAAnalyticsData('Save CTA', 'Fund Fact Card'));
    e.preventDefault();
    e.stopPropagation();
    await saveFundRebate(fundRebateInfo);
    if (!fundRebateInfo.rebateAmount) {
      // When rebate is removed, unadjusted fields should be displayed
      onChangeYieldState("Unadjusted");
    } else if((!fundRebate || !fundRebate.rebateAmount) && fundRebateInfo.rebateAmount) {
      onChangeYieldState("Adjusted");
    }
    setFundRebateInfo(fundRebateInfo);
    setToggleEditSave(false);
  };

  const userHasMadeEdits = () => {
    // Rebate amount exists and is changed or removed
    if (fundRebate && fundRebate.rebateAmount && fundRebate.rebateAmount !== fundRebateInfo.rebateAmount) {
      return true;
    }

    // Rebate amount does not exist and is added
    if ( (!fundRebate || !fundRebate.rebateAmount) && fundRebateInfo.rebateAmount) {
      return true;
    }

    // Investment option exists and is changed or removed
    if (fundRebate && fundRebate.investmentOption && fundRebate.investmentOption !== fundRebateInfo.investmentOption){
      return true;
    }

    // Investment does not exist and is added
    if ( (!fundRebate || !fundRebate.investmentOption) && fundRebateInfo.investmentOption){
      return true;
    }

    // Payment date exists and is changed or removed
    if (fundRebate && fundRebate.paymentDate && fundRebate.paymentDate !== fundRebateInfo.paymentDate){
      return true;
    }

    // Payment date does not exist and is added
    if ( (!fundRebate || !fundRebate.paymentDate) && fundRebateInfo.paymentDate){
      return true;
    }
  };

  useEffect(() => {
    if (userHasMadeEdits()) {
      setUnsavedRebateChanges();
    } else {
      unsetUnsavedRebateChanges();
    }
    return unsetUnsavedRebateChanges;
  }, [fundRebateInfo]);

  const onCancelClick = () => {
    sendAnalytics(getRebateCTAAnalyticsData('Cancel CTA', 'Fund Fact Card'));
    if (userHasMadeEdits()) {
      updateRebateStatusToUnsavedChanges();
      return;
    }
    setFundRebateInfo(fundRebate || {});
    setToggleEditSave(false);
    setErrorState(REBATE_NO_ERROR);
  };

  const handleError = (value) => {
    const errorState = validateRebateAmount(value, fundRebate && fundRebate.rebateAmount);
    setErrorState(errorState);
    setSaveButtonDisabled(errorState !== REBATE_NO_ERROR);
  };

  const handleEditFields = (value, field, fieldType) => {
    setFundRebateInfo({
      ...fundRebateInfo,
      [field]: value,
    });

    if (fieldType === INPUT) {
      handleError(value, field);
    } else {
      setSaveButtonDisabled(errorState !== REBATE_NO_ERROR || (!fundRebateInfo.rebateAmount && (!fundRebate || !fundRebate.rebateAmount)));
    }
  };

  if (!entitledToRebates) {
    return null;
  }

  return (
    <div
      className={
        classNames(
          {
            'debit-container': fundRebateLoading,
          }
      )}
    >
      <div className="factHead">
        <span className='fundFactView__label'>
          {tkFundRebate}
          <TooltipTarget
            inline={true}
            label={
              <p
                className={"rebate-hyperlink"}
                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(tkFundRebateTooltipContent)}}
              />
            }
          >
            <span data-testid="info-icon" className="info-icon" />
          </TooltipTarget>
        </span>

        <span className="account-edit-button">
          <AddEditSaveButtons
            toggleEditSave={toggleEditSave}
            rebateAmountExists={!!fundRebateInfo.rebateAmount}
            saveButtonDisabled={saveButtonDisabled}
            isMimicSession={isMimicSession}
            labels={labels}
            onEditClick={onEditClick}
            onCancelClick={onCancelClick}
            onSaveClick={onSaveClick}
            sendAnalytics={sendAnalytics}
          />
        </span>
      </div>

      <Conditional condition={fundRebateInfo.rebateAmount || toggleEditSave}>
        <>
          <Repeater
            items={items}
            props={{
              fundRebateInfo,
              isEdit: toggleEditSave,
              handleEditFields,
              errorState,
              labels
            }}
          >

            <Item />

          </Repeater>

          <div className="rebate-disclaimer" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(tkRebateDisclaimer)}}/>
        </>
      </Conditional>

      {fundRebateLoading && <ThreeSquaresLoader />}
    </div>
  );
};

FundRebate.propTypes = {
  items: PropTypes.array,
  labels: PropTypes.object,
  fundRebate: PropTypes.object,
  saveFundRebate: PropTypes.func,
  fundRebateLoading: PropTypes.bool,
  entitledToRebates: PropTypes.bool,
  isMimicSession: PropTypes.bool,
  onChangeYieldState: PropTypes.func,
  updateRebateStatusToUnsavedChanges: PropTypes.func,
  fundRebateEditStatus: PropTypes.bool,
  setFundRebateEditStatus: PropTypes.func,
  setUnsavedRebateChanges: PropTypes.func,
  unsetUnsavedRebateChanges: PropTypes.func,
  sendAnalytics: PropTypes.func
};

const mapStateToProps = (state) => ({
  fundRebate: getFundRebate(state),
  fundRebateLoading: getFundRebateLoading(state),
  fundRebateStatus: getFundRebateStatus(state),
  entitledToRebates: entitledToRebates(state),
  isMimicSession: isMimicSessionSelector(state),
  fundRebateEditStatus: getFundRebateEditStatus(state)
});

const mapDispatchToProps = (dispatch) => ({
  saveFundRebate: (data) => dispatch(saveFundRebate(data)),
  updateRebateStatusToUnsavedChanges: () => dispatch(addPageContext({fundRebateStatus: {type: REBATE_UNSAVED_CHANGES}})),
  setFundRebateEditStatus: () => dispatch(addPageContext({fundRebateEditStatus: true})),
  setUnsavedRebateChanges: () => dispatch(addPageContext({unsavedRebateChanges: true})),
  unsetUnsavedRebateChanges: () => dispatch(addPageContext({unsavedRebateChanges: false})),
  sendAnalytics: (data) => dispatch(eventAnalytics(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FundRebate);
