import { DragAndDropStateUpdateEvent } from '@gs-ux-uitoolkit-common/drag-and-drop';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { DragAndDropPlaceholderState } from '../drag-and-drop-context';
import { DragAndDropResult, ExternalDragAndDropOnDragEnd } from '../drag-and-drop-external';

export type UseOnDragEndCallbackType = typeof useOnDragEndCallback;

export interface DragAndDropEndEvent {
    result: DragAndDropResult;
    announce: (message: string) => void;
}

export type DragAndDropOnDragEnd = (event: DragAndDropEndEvent) => void;

/**
 * Callback returned from the hook should run when drag operation ends.
 * Callback hides the placeholder, and calls the `onStateUpdate` callback
 * if drop destination and source are different.
 */
export const useOnDragEndCallback = (
    onStateUpdate: (event: DragAndDropStateUpdateEvent) => void,
    setPlaceholderState: Dispatch<SetStateAction<DragAndDropPlaceholderState>>,
    onDragEnd?: DragAndDropOnDragEnd
) => {
    return useCallback<ExternalDragAndDropOnDragEnd>(
        (result, provided) => {
            // call function if provided by user
            onDragEnd ? onDragEnd({ result, announce: provided.announce }) : null;

            // disable placeholder logic if placeholder is disabled

            const { destination, source } = result;

            // hide placeholder
            setPlaceholderState(prevState => {
                return {
                    ...prevState,
                    width: 0,
                    height: 0,
                    isPlaceholderVisible: false,
                };
            });

            // mutate data only if changed
            if (!destination) {
                return;
            }
            if (
                destination.droppableId === source.droppableId &&
                destination.index === source.index
            ) {
                return;
            }

            onStateUpdate({ destination, source });
        },
        [onStateUpdate, setPlaceholderState, onDragEnd]
    );
};
