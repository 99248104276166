/* tslint:disable */

// This file will be modified (UX-3442) and the tslint can be enabled again
// TODO remove when following Flatpickr issue is resolved: https://github.com/flatpickr/flatpickr/issues/1329
// Temporary fix for Datepicker/Flatpickr keyboard navigation issues

export function onKeyDown(_: any, __: any, instance: any, e: any) {
    const isCalendarElem = (elem: any, context: any) =>
        (context.config.appendTo && context.config.appendTo.contains(elem)) ||
        context.calendarContainer.contains(elem);
    const focusOnDayElem = (targetNode: any) => {
        targetNode.focus();
    };
    const isInView = (elem: any) => {
        if (instance.daysContainer !== undefined) {
            return elem.className.indexOf('hidden') === -1 && instance.daysContainer.contains(elem);
        }
        return false;
    };
    const getFirstAvailableDay = (delta: any) => {
        const startMonth = delta > 0 ? 0 : instance.config.showMonths - 1;
        const endMonth = delta > 0 ? instance.config.showMonths : -1;

        for (let m = startMonth; m !== endMonth; m += delta) {
            const month = instance.daysContainer.children[m];
            const startIndex = delta > 0 ? 0 : month.children.length - 1;
            const endIndex = delta > 0 ? month.children.length : -1;

            for (let i = startIndex; i !== endIndex; i += delta) {
                const c = month.children[i];
                if (
                    c.className.indexOf('hidden') === -1 &&
                    c.className.indexOf('disabled') === -1
                ) {
                    return c;
                }
            }
        }
        return undefined;
    };
    const focusOnDay = (current: any, offset: any) => {
        const dayFocused = isInView(document.activeElement || document.body);
        const startElem =
            current !== undefined
                ? current
                : dayFocused
                  ? document.activeElement
                  : instance.selectedDateElem !== undefined && isInView(instance.selectedDateElem)
                    ? instance.selectedDateElem
                    : instance.todayDateElem !== undefined && isInView(instance.todayDateElem)
                      ? instance.todayDateElem
                      : getFirstAvailableDay(offset > 0 ? 1 : -1);

        if (startElem === undefined) return instance._input.focus();

        if (!dayFocused) return focusOnDayElem(startElem);

        getNextAvailableDay(startElem, offset);
        return undefined;
    };
    const getNextAvailableDay = (current: any, delta: any) => {
        const givenMonth =
            current.className.indexOf('Month') === -1
                ? current.dateObj.getMonth()
                : instance.currentMonth;
        const endMonth = delta > 0 ? instance.config.showMonths : -1;
        const loopDelta = delta > 0 ? 1 : -1;

        for (let m = givenMonth - instance.currentMonth; m !== endMonth; m += loopDelta) {
            const month = instance.daysContainer.children[m];
            const startIndex =
                givenMonth - instance.currentMonth === m
                    ? current.$i + delta
                    : delta < 0
                      ? month.children.length - 1
                      : 0;
            const numMonthDays = month.children.length;

            for (
                let i = startIndex;
                i >= 0 && i < numMonthDays && i !== (delta > 0 ? numMonthDays : -1);
                i += loopDelta
            ) {
                const c = month.children[i];
                if (
                    c.className.indexOf('hidden') === -1 &&
                    c.className.indexOf('disabled') === -1 &&
                    Math.abs(current.$i - i) >= Math.abs(delta)
                ) {
                    return focusOnDayElem(c);
                }
            }
        }
        instance.changeMonth(loopDelta);
        focusOnDay(getFirstAvailableDay(loopDelta), 0);
        return undefined;
    };

    const isInput = e.target === instance._input;
    const calendarElem = isCalendarElem(e.target, instance);
    const allowInput = instance.config.allowInput;
    const allowKeydown = instance.isOpen && (!allowInput || !isInput);
    const allowInlineKeydown = instance.config.inline && isInput && !allowInput;

    if (calendarElem || allowKeydown || allowInlineKeydown) {
        const isTimeObj = !!instance.timeContainer && instance.timeContainer.contains(e.target);

        switch (e.keyCode) {
            case 37:
            case 39:
                if (!isTimeObj) {
                    e.preventDefault();

                    if (instance.daysContainer !== undefined) {
                        const delta = e.keyCode === 39 ? 1 : -1;
                        if (!e.ctrlKey) {
                            focusOnDay(undefined, delta);
                        } else {
                            instance.changeMonth(delta);
                            focusOnDay(getFirstAvailableDay(1), 0);
                        }
                    }
                } else if (instance.hourElement) instance.hourElement.focus();

                break;
            default:
                break;
        }
    }
}
