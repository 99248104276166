
import React from 'react';
import PropTypes from 'prop-types';
import Repeater from '../../core/Repeater';
import ExternalLink from '../../core/ExternalLink';
import { getFundFactCardAnalyticsData } from '../../../utils/portfolioAnalytics';

const Item = ({item, labels, investmentManager, sendAnalytics}) => {

  const handleResourceClick = (resourceName, resourceLink) => {
    sendAnalytics(getFundFactCardAnalyticsData(resourceName, resourceLink));
  };
  return (
    <div className='factData'>
      <ExternalLink
        href={item.path}
        handleClick={() => handleResourceClick(item.documentName, item.path)}
        target='_blank'
        isDisabled={!item.path}
        label={item.documentName || item.path}
        labels={labels}
        investmentManager={investmentManager}
    />
    </div>
  );
};


Item.propTypes = {
  labels: PropTypes.object,
  item: PropTypes.object,
  investmentManager: PropTypes.string,
  sendAnalytics: PropTypes.func
};

const Resources = ({ items, labels, investmentManager, sendAnalytics }) => {
  return (
    <Repeater items={items} props={{labels, investmentManager, sendAnalytics}} >
      <Item />
    </Repeater>
  );
};

Resources.propTypes = {
  items: PropTypes.array,
  labels: PropTypes.object,
  investmentManager: PropTypes.string,
  sendAnalytics: PropTypes.func
};

export default Resources;
