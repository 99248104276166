import errorTypes from '../error/errorType';

/**
 * This method will only be used when there is service error and response type is not JSON or
 * return response is not valid JSON.
 * Node: Please do not use this method for other scenario.
 * */
export const getErrorEnum = (status) => {
  switch (true) {
    case (status === 401 ||  status === 403):
      return errorTypes.AUTHORIZATION;
    case (status === 404):
      return errorTypes.NOT_FOUND;
    case ((status >= 405 && status <= 417) || status === 402 || status === 404):
      return errorTypes.SERVICE_ERROR;
    case (status >= 500 && status <= 505):
    default:
      return errorTypes.UNKNOWN_SERVICE_ERROR;
  }
};
