import { ComponentProps, CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { BadgeStatus } from './badge-status';
import { BadgeEmphasis } from './badge-emphasis';
import { BadgeColor } from './badge-color';
import { BadgeSize } from './badge-size';
import { BadgeShape } from './badge-shape';

/**
 * Common Badge props shared between Angular and React
 */
export interface BadgeProps<StyleType = CommonStyleType> extends ComponentProps<StyleType> {
    /**
     * Style classes to override.
     */
    classes?: BadgeOverrideClasses;

    /**
     * Status of the component.  'status' and 'color' are mutually exclusive,
     * with status taking precedence.  Default is 'none' if color is not defined.
     */
    status?: BadgeStatus;

    /**
     * Color of the component.  'status' and 'color' are mutually exclusive,
     * with status taking precedence.  Default is to set status='none' if color is not defined.
     */
    color?: BadgeColor;

    /**
     * Emphasis of the component.  Default is 'bold'
     */
    emphasis?: BadgeEmphasis;

    /**
     * Size of the component.  Default is 'md'.
     */
    size?: BadgeSize;

    /**
     * Shape of the component.  Default is 'square'.
     */
    shape?: BadgeShape;
}

export type BadgeOverrideClasses = Partial<Record<'root', string>>;

export type DefaultBadgeProps = Required<Pick<BadgeProps, 'emphasis' | 'size' | 'shape'>>;

export const defaultBadgeProps: DefaultBadgeProps = {
    emphasis: 'bold',
    size: 'md',
    shape: 'square',
};
