import { useEffect, ReactNode, FC } from 'react';
import { Theme } from '@gs-ux-uitoolkit-common/theme';
import { ThemeContext } from './theme-context';
import { componentAnalytics } from './analytics-tracking';

export interface ThemeProviderProps {
    theme: Theme;
    children: ReactNode;
}
export const ThemeProvider: FC<ThemeProviderProps> = ({ theme, children }) => {
    useEffect(() => {
        componentAnalytics.track({
            officialComponentName: 'other',
            eventName: 'Rendered',
            featureName: 'Theme',
            properties: {
                colorMode: theme.colorMode,
            },
        });
    }, [theme]);
    return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
};
