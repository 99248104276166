import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import Modal from '../../core/Modal';
import Button from '../../core/Button';
import translator from '../../../services/translator';
import {ThreeSquaresLoader} from '../../core/Loaders';
import {Conditional} from '../../core/Conditional';
import {getLoadOfTNCData} from '../../../utils/loginAnalytics';
import {withScroll} from '../../core/Scrollbar';
import {analyticsEventName} from './analytics';
import {isMosaic} from "../../../utils/commonUtils";
import './index.scss';

const {translate: t} = translator;

const TNCDataSection = ({termsAndConditionContents} = {}) => {
  return (
    <div className="tnc-modal__contents">
      <Conditional condition={isMosaic()}>
        <div className="tnc-modal__contents-subheader">
          {t('tkTermsAndConditionsSubHeader')}
        </div>
      </Conditional>
      <div className="tnc-modal__contents-wrapper">
        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(termsAndConditionContents)}}/>
      </div>
    </div>
  );
};

TNCDataSection.propTypes = {
  termsAndConditionContents: PropTypes.string
};

class TermsAndConditionsModal extends Component {
  constructor(props) {
    super(props);
    this.onAcceptOrRejectTermsAndConditions = this.onAcceptOrRejectTermsAndConditions.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.sendAnalytics = this.sendAnalytics.bind(this);
    this.tncScroll = React.createRef();
    this.state = {
      analyticsSent: false,
      disclaimerReadNotice: ''
    };
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  sendAnalytics() {
    if (this.props.isShowModal === true && this.state.analyticsSent === false) {
      this.setState({
        analyticsSent: true
      });
      this.props.eventAnalytics(getLoadOfTNCData());
    }
  }

  componentDidUpdate() {
    setTimeout(() => {
      if (this.props.termsAndConditionContents && this.isPageHasScroll() && !this.hasReachedEndOfScreen()) {
        this.props.setTncCtaButtonsDisabled(true);
      }
    }, 10);
  }

  updateDimensions() {
    if (this.props.termsAndConditionContents && this.props.isTncCtaButtonsDisabled && this.hasReachedEndOfScreen()) {
      this.props.setTncCtaButtonsDisabled(false);
      this.setState({disclaimerReadNotice: ''});
    }
  }

  onAcceptOrRejectTermsAndConditions(e) {
    if (!this.props.isTncCtaButtonsDisabled) {
      const hasAcceptedTnc = (e.currentTarget.id === 'ACCEPT_TNC');
      this.props.onAcceptTermsAndConditions(hasAcceptedTnc);
      this.props.setTncCtaButtonsDisabled(true);
    } else {
      this.setState({disclaimerReadNotice: t('tkDisclaimerReadNotice')});
    }
  }

  hasReachedEndOfScreen() {
    if (this.tncScroll && this.tncScroll.current) {
      const {scrollTop, scrollHeight, clientHeight} = this.tncScroll.current.scrollValues;
      this.sendAnalytics();
      const totalBodyHeight = Math.round(scrollTop + clientHeight);
      return ([scrollHeight, scrollHeight - 1, scrollHeight + 1].includes(totalBodyHeight));
    }
    return true;
  }

  isPageHasScroll() {
    if (this.tncScroll && this.tncScroll.current) {
      const {clientHeight, scrollHeight} = this.tncScroll.current.scrollValues;
      return scrollHeight > clientHeight;
    }
    return false;
  }

  render() {
    const {
      termsAndConditionContents, handleClose, submitTncError, isShowModal, isTncCtaButtonsDisabled,
      isTncServiceCallInProgress, isAppLoading
    } = this.props;
    const scrollConfig = {
      cssTrackY: 'modal-scroll__track modal-scroll__track--Y',
      cssContent: 'modal-scroll__content',
      cssWrapper: 'modal-scroll__wrapper',
      overflowX: 'hidden',
      overflowY: 'scroll',
      style: {
        position: 'static',
        width: '100%',
        maxHeight: '500px'
      },
      onScroll: this.updateDimensions,
      ref: this.tncScroll
    };

    return (
      <Modal
        open={isShowModal && !isAppLoading}
        customClass="modal--right modal--tnc"
        hideCloseCTA={true}
        handleClose={handleClose}>
        <React.Fragment>
          <Conditional condition={!termsAndConditionContents || isTncServiceCallInProgress}>
            <ThreeSquaresLoader/>
          </Conditional>
          <div className="tnc-modal">
            <div className="tnc-modal__header">
              <h2>
                {t('tkTermsAndConditionsHeader')}
              </h2>
            </div>
            <div className="tnc-modal__body">
              {withScroll(TNCDataSection, scrollConfig)({termsAndConditionContents})}
            </div>
            <div className="tnc-modal__footer">
              <div className="tnc-modal__footer-contents">
                <div className="tnc-modal__footer-btn-container">
                  <Button
                    extraProps={{'analytics-attribute': analyticsEventName.TERMS_ACCEPT, id: "ACCEPT_TNC"}}
                    customClass={isTncCtaButtonsDisabled ? 'button-in-active' : 'button-primary'}
                    label={t('tkAcceptBtnLabel')}
                    clickHandler={this.onAcceptOrRejectTermsAndConditions}/>
                  <Button
                    extraProps={{'analytics-attribute': analyticsEventName.TERMS_DECLINE}}
                    customClass={isTncCtaButtonsDisabled ? 'button-in-active' : 'button-secondary'}
                    label={t('tkDoNotAcceptBtnLabel')}
                    clickHandler={this.onAcceptOrRejectTermsAndConditions}/>
                  <span className="tnc-modal__footer-error">
                    {submitTncError || this.state.disclaimerReadNotice}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </Modal>
    );
  }
}

TermsAndConditionsModal.propTypes = {
  handleClose: PropTypes.func,
  isShowModal: PropTypes.bool,
  isAppLoading: PropTypes.bool,
  isTncServiceCallInProgress: PropTypes.bool,
  isTncCtaButtonsDisabled: PropTypes.bool,
  onAcceptTermsAndConditions: PropTypes.func,
  eventAnalytics: PropTypes.func,
  submitTncError: PropTypes.string,
  setTncCtaButtonsDisabled: PropTypes.func,
  termsAndConditionContents: PropTypes.string
};

export default TermsAndConditionsModal;

