// need to type these constants as [key: string]: string instead of [key: string]: LegacyV12IconName
// because they are used in the script that generates the LegacyV12IconName type
const solidMap: { [key: string]: string } = {
    'align-center': 'format-align-center',
    'align-left': 'format-align-left',
    'align-right': 'format-align-right',
    at: 'at',
    'angle-double-down': 'chevron-double-down',
    'angle-double-left': 'chevron-double-left',
    'angle-double-right': 'chevron-double-right',
    'angle-double-up': 'chevron-double-up',
    'angle-down': 'chevron-down',
    'angle-left': 'chevron-left',
    'angle-right': 'chevron-right',
    'angle-up': 'chevron-up',
    'arrow-down': 'arrow-down',
    'arrow-left': 'arrow-left',
    'arrow-right': 'arrow-right',
    'arrow-up': 'arrow-up',
    'arrows-alt': 'arrow-all',
    'arrows-alt-h': 'arrow-left-right',
    'arrows-alt-v': 'arrow-up-down',
    ban: 'cancel',
    bars: 'menu',
    bell: 'bell',
    bolt: 'flash',
    book: 'book',
    bookmark: 'bookmark',
    briefcase: 'briefcase',
    bug: 'bug',
    bullseye: 'bullseye',
    calculator: 'calculator',
    calendar: 'calendar-blank',
    'calendar-minus': 'calendar-minus',
    'calendar-plus': 'calendar-plus',
    camera: 'camera',
    'caret-down': 'menu-down',
    'caret-left': 'menu-left',
    'caret-right': 'menu-right',
    'caret-up': 'menu-up',
    'chart-area': 'chart-areaspline',
    'chart-bar': 'chart-bar',
    'chart-line': 'chart-line',
    'chart-pie': 'chart-pie',
    check: 'check',
    'check-circle': 'checkbox-marked-circle',
    'check-square': 'checkbox-marked',
    'chevron-down': 'chevron-down',
    'chevron-left': 'chevron-left',
    'chevron-right': 'chevron-right',
    'chevron-up': 'chevron-up',
    circle: 'circle',
    clipboard: 'clipboard',
    clock: 'clock',
    'cloud-download-alt': 'cloud-download',
    'cloud-upload-alt': 'cloud-upload',
    code: 'code-tags',
    cog: 'settings',
    columns: 'view-column',
    comment: 'comment',
    comments: 'comment-multiple',
    copy: 'file-multiple',
    'credit-card': 'credit-card',
    database: 'database',
    desktop: 'monitor',
    'dollar-sign': 'currency-usd',
    download: 'download',
    edit: 'file-document-edit-outline',
    'ellipsis-h': 'dots-horizontal',
    'ellipsis-v': 'dots-vertical',
    envelope: 'email',
    'euro-sign': 'currency-eur',
    exclamation: 'exclamation',
    'exclamation-circle': 'alert-circle',
    'exclamation-circle-outline': 'alert-circle-outline',
    'exclamation-triangle': 'alert',
    'expand-arrows-alt': 'arrow-expand-all',
    'external-link-alt': 'open-in-new',
    eye: 'eye',
    'eye-slash': 'eye-off',
    flag: 'flag',
    flask: 'flask',
    file: 'file',
    'file-alt': 'file-document',
    'file-code': 'file-code',
    'file-image': 'file-image',
    'file-pdf': 'file-pdf',
    filter: 'filter',
    folder: 'folder',
    'folder-open': 'folder-open',
    heart: 'heart',
    home: 'home',
    image: 'image',
    images: 'image-multiple',
    inbox: 'inbox',
    industry: 'factory',
    info: 'information-outline',
    'info-circle': 'information',
    keyboard: 'keyboard',
    lightbulb: 'lightbulb',
    list: 'format-list-bulleted-square',
    'list-alt': 'format-list-bulleted',
    'list-ol': 'format-list-numbered',
    link: 'link',
    lock: 'lock',
    'lock-open': 'lock-open',
    magic: 'auto-fix',
    'map-marker-alt': 'map-marker',
    'mobile-alt': 'cellphone',
    'mouse-pointer': 'cursor-default',
    'paint-brush': 'brush',
    'paper-plane': 'send',
    paperclip: 'paperclip',
    pause: 'pause',
    'pencil-alt': 'pencil',
    phone: 'phone',
    play: 'play',
    plus: 'plus',
    'minus-circle': 'minus-circle',
    minus: 'minus',
    'plus-circle': 'plus-circle',
    print: 'printer',
    'project-diagram': 'vector-polyline',
    'pound-sign': 'currency-gbp',
    'power-off': 'power',
    question: 'help',
    'question-circle': 'help-circle',
    'quote-right': 'format-quote-close',
    random: 'shuffle-variant',
    redo: 'redo',
    reply: 'reply',
    'reply-all': 'reply-all',
    road: 'road-variant',
    'ruble-sign': 'currency-rub',
    'rupee-sign': 'currency-inr',
    save: 'content-save',
    search: 'magnify',
    'search-minus': 'magnify-minus-outline',
    'search-plus': 'magnify-plus-outline',
    server: 'server',
    share: 'share',
    'shield-alt': 'shield-half-full',
    'sign-in-alt': 'login',
    'sign-out-alt': 'logout',
    sitemap: 'sitemap',
    'sliders-h': 'tune',
    // 'space-shuttle': '',
    spinner: 'loading',
    sort: 'sort',
    'sort-down': 'sort-descending',
    'sort-up': 'sort-ascending',
    square: 'square',
    star: 'star',
    sync: 'sync',
    table: 'table',
    tag: 'tag',
    tags: 'tag-multiple',
    th: 'grid',
    'th-large': 'grid-large',
    'th-list': 'view-list',
    thumbtack: 'pin',
    'thumbs-down': 'thumb-down',
    'thumbs-up': 'thumb-up',
    times: 'close',
    'times-circle': 'close-circle',
    trash: 'delete',
    undo: 'undo',
    unlink: 'link-off',
    upload: 'upload',
    user: 'account',
    users: 'account-group',
    'window-maximize': 'window-maximize',
    'window-minimize': 'window-minimize',
    'window-restore': 'window-restore',
    'window-close': 'close-box',
    'won-sign': 'currency-krw',
    wrench: 'wrench',
    'yen-sign': 'currency-jpy',
};
const regularMap: { [key: string]: string } = {
    bookmark: 'bookmark-outline',
    square: 'square-outline',
    circle: 'circle-outline',
    heart: 'heart-outline',
    clock: 'clock-outline',
    calendar: 'calendar-blank',
    'window-maximize': 'window-maximize',
    'window-minimize': 'window-minimize',
    'window-restore': 'window-restore',
};
const brandsMap: { [key: string]: string } = {
    angular: 'angular',
    react: 'react',
};
const aliasMap: { [key: string]: string } = {
    // from JSI
    'gi-pencil-square-o': 'file-document-edit-outline',
    repeat: 'redo',
    'external-link': 'open-in-new',
    'file-text-o': 'file-document',
    'lightbulb-o': 'lightbulb',
    mobile: 'cellphone',
    refresh: 'sync',
    remove: 'close',
    pencil: 'pencil',
    'flag-o': 'flag',
    'trash-o': 'delete',
    warning: 'alert',
    'clock-o': 'clock',
    'arrows-h': 'arrow-left-right',
    'arrows-v': 'arrow-up-down',
    'folder-open-o': 'folder-open',
    electricity: 'flash',
    // from goldman brands
    aspen: 'aspen',
    'deal-harbor': 'deal-harbor',
    'honest-dollar': 'honest-dollar',
    lithium: 'lithium',
    roae: 'roae',
};

export const v11ToV12IconMapping = {
    solid: {
        icons: solidMap,
        prefix: 'gi',
    },
    regular: {
        icons: regularMap,
        prefix: 'gir',
    },
    brands: {
        icons: brandsMap,
        prefix: 'gib',
    },
    aliases: {
        icons: aliasMap,
        prefix: 'gi',
    },
};
