import { isBrowser, KeyHelpers } from '@gs-ux-uitoolkit-common/shared';
import { StateViewModelController } from './state-view-model-controller';
import { MenuBlurEvent, InputMethod as MenuBlurEventInput } from './menu-event';
import { hasWindow } from './window';

export interface BaseStateViewModelProps {
    autoFocus?: boolean;
    visible?: boolean;
    isSubmenu?: boolean;
    isAngular?: boolean;
    onBlur?: (event: MenuBlurEvent) => void;
    onFocus?: () => void;
}

export interface StateViewModel {
    update(props: BaseStateViewModelProps): void;
    destroy(): void;

    setMenuEl(value: HTMLDivElement | null): void;
    getMenuEl(): HTMLDivElement | null;

    addChildren(children: HTMLElement[], afterElement: HTMLElement, autoFocus?: boolean): void;
    removeChildren(afterElement: HTMLElement): void;

    setSubmenuVisible(value: boolean, focusKey?: number): void;
    getSubmenuVisible(): boolean;

    setupBlurHandler(): void;
    cancelBlurHandler(): void;

    focus(element?: HTMLElement | null): void;

    isVisible(): boolean;
}

/**
 * Contains utility methods shared between Angular and React Menu-type
 * components. It is designed to be extended: Menu uses MenuStateViewModel
 * and CollapseMenu uses CollapseStateViewModel, both of which extend this class.
 */
export class BaseStateViewModel implements StateViewModel {
    protected svmCtrl: StateViewModelController;

    protected menuEl: HTMLDivElement | null = null;
    protected childrenContainerEl: HTMLElement | null = null;

    protected submenuVisible = false;
    protected props: BaseStateViewModelProps;

    // `children` are direct MenuOption children.
    protected children: HTMLElement[] = [];

    // `addedChildren` are children of child menus, such as a child CollapseMenu.
    protected addedChildren: { children: HTMLElement[]; afterElement: HTMLElement }[] = [];

    protected focusIndex: number = -1;
    protected submenuParentIndex: number = -1;

    constructor(baseProps: BaseStateViewModelProps) {
        this.props = { ...baseProps };
        this.svmCtrl = StateViewModelController.instance;
        this.onWindowBlur = this.onWindowBlur.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    update(props: BaseStateViewModelProps) {
        this.props = this.updateProps(props);
    }

    destroy() {
        this.cancelBlurHandler();
    }

    setMenuEl(value: HTMLDivElement | null) {
        this.menuEl = this.childrenContainerEl = value;
        if (this.props.visible) {
            this.focusChildOnMenuFocus();
        }
    }
    getMenuEl(): HTMLDivElement | null {
        return this.menuEl;
    }

    addChildren = (children: HTMLElement[], afterElement: HTMLElement, autoFocus = false) => {
        const afterIndex = this.addedChildren.findIndex(
            added => added.afterElement === afterElement
        );
        if (afterIndex !== -1) {
            this.addedChildren.splice(afterIndex, 1);
        }
        this.addedChildren.push({ children, afterElement });
        this.updateChildren();
        if (autoFocus) {
            children.some(child => {
                const index = this.children.indexOf(child);
                if (index !== -1) {
                    this.focusIndex = index;
                    this.focusChildOnMenuFocus();
                    return true;
                }
                return false;
            });
        }
    };
    removeChildren = (afterElement: HTMLElement) => {
        const afterIndex = this.addedChildren.findIndex(
            added => added.afterElement === afterElement
        );
        if (afterIndex !== -1) {
            this.addedChildren.splice(afterIndex, 1);
        }
        this.updateChildren();
    };

    setSubmenuVisible(value: boolean) {
        this.submenuParentIndex = value ? this.getFocusIndex() : -1;
        this.submenuVisible = value;
    }
    getSubmenuVisible(): boolean {
        return this.submenuVisible;
    }

    setupBlurHandler() {
        if (hasWindow()) {
            window.addEventListener('mousedown', this.onWindowBlur);
            window.addEventListener('blur', this.onWindowBlur);
            window.addEventListener('keydown', this.onKeyDown);
        }
    }

    cancelBlurHandler() {
        if (hasWindow()) {
            window.removeEventListener('mousedown', this.onWindowBlur);
            window.removeEventListener('blur', this.onWindowBlur);
            window.removeEventListener('keydown', this.onKeyDown);
        }
    }

    /**
     * Focuses the Menu and, if autoFocus is enabled, the first option child.
     * If @param element is defined, a child option that is or contains that
     * element will be focussed; this is necessary in certain cases including
     * when an option that opens a submenu is toggled, thus closing the
     * submenu and focussing this menu, in which case the focus should be placed
     * not on the first child option but on the child option that was clicked.
     */
    focus(element?: HTMLElement | null) {
        if (this.svmCtrl.isFocussed(this)) {
            return;
        }
        this.svmCtrl.focus(this);
        if (this.props.visible) {
            this.updateChildren();
            if (this.hasChildren()) {
                if (element) {
                    const index = this.getIndexForChildWithElement(element);
                    this.focusIndex = index === -1 ? this.focusIndex : index;
                }
                if (this.focusIndex !== -1 || this.props.autoFocus) {
                    this.focusChildOnMenuFocus();
                }
            }
        }
        if (this.props.onFocus) {
            this.props.onFocus();
        }
    }

    isVisible() {
        return this.props.visible || false;
    }

    // Calls onBlur if a click/touch occurs outside of the menu element, which
    // should correspond to the menu being blurred.
    protected onWindowBlur(event: MouseEvent | TouchEvent | Event) {
        if (!this.menuEl) return;
        if (
            (hasWindow() && event.target === window) ||
            (event.target &&
                event.target !== this.menuEl &&
                !this.menuEl.contains(event.target as Node))
        ) {
            this.callOnBlur(this.getBlurEvent(event.target, 'pointer'));
        }
    }

    protected onKeyDown(event: KeyboardEvent) {
        this.respondToKeyDown(event);
    }

    // Returns true if action taken, false otherwise, allowing onKeyDown to be
    // overridden by an extending class but only perform an action if
    // respondToKeyDown returns false.
    protected respondToKeyDown(event: KeyboardEvent): boolean {
        if (!this.svmCtrl.isFocussed(this)) {
            return true;
        }
        // handle focus menu options that start with key clicked by user
        const regex = /^[\w-_.]$/;
        if (regex.test(event.key)) {
            this.focusNext(event.key.toLowerCase());
            return true;
        }
        switch (event.which) {
            case KeyHelpers.keyCode.TAB:
                // When a context menu or a <select> is open in Chrome, keyboard
                // navigation via Tab is disabled. This does the same for Menu.
                event.preventDefault();
                return true;
            case KeyHelpers.keyCode.ESCAPE:
                // On Escape, hint to the user via blur to close the Menu.
                this.callOnBlur(this.getBlurEvent(event.target, 'keyboard'));
                return true;
            case KeyHelpers.keyCode.ARROW_DOWN:
                this.onFocusDirection(event, 'down');
                return true;
            case KeyHelpers.keyCode.ARROW_UP:
                this.onFocusDirection(event, 'up');
                return true;
            case KeyHelpers.keyCode.ARROW_LEFT:
                if (this.props.isSubmenu) {
                    this.callOnBlur(this.getBlurEvent(event.target, 'arrow'));
                    return true;
                }
                // Note: The corresponding ARROW_RIGHT is handled by MenuOption
                // because it is only triggered by a focussed MenuOption.
                break;
            default:
                break;
        }
        return false;
    }

    protected onFocusDirection(event: KeyboardEvent, direction: 'up' | 'down') {
        event.preventDefault();
        this.updateChildren();
        if (this.hasChildren()) {
            this.focusIndex = this.getFocusIndex();
            if (direction === 'up') {
                this.focusUp();
            } else {
                this.focusDown();
            }
        }
    }

    protected focusDown() {
        while (this.focusIndex < this.children.length - 1) {
            this.focusIndex += 1;
            const child = this.children[this.focusIndex];
            if (this.isElementFocusable(child)) {
                child.focus();
                return true;
            }
        }
        return false;
    }

    protected focusUp() {
        while (this.focusIndex > 0) {
            this.focusIndex -= 1;
            const child = this.children[this.focusIndex];
            if (this.isElementFocusable(child)) {
                child.focus();
                return true;
            }
        }
        return false;
    }

    protected focusNext(char: string) {
        const eligibleChildren = this.children.filter((element: HTMLElement) => {
            const labelSpan = element.querySelector(
                '.gs-menu__option-label, .gs-checkbox__children'
            ) as HTMLElement;
            return labelSpan?.innerText?.toLowerCase()?.startsWith(char);
        });

        if (eligibleChildren.length === 0) return;

        const currentFocus = document.activeElement ?? eligibleChildren?.[0];
        const totalFocusable = (eligibleChildren || []).length;
        const focusedIndex = Math.max(
            Array.from(eligibleChildren).findIndex(el => el === currentFocus),
            0
        );
        let elementToBeFocused;
        if (focusedIndex === totalFocusable - 1) elementToBeFocused = eligibleChildren[0];
        else elementToBeFocused = eligibleChildren[focusedIndex + 1];
        if (this.isElementFocusable(elementToBeFocused)) {
            elementToBeFocused.focus();
            return true;
        }
        return false;
    }

    protected getFocusIndex() {
        return this.children.findIndex(
            el => el === document.activeElement || el.contains(document.activeElement)
        );
    }

    protected callOnBlur(event: MenuBlurEvent) {
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    protected getBlurEvent(
        eventTarget: EventTarget | null,
        input: MenuBlurEventInput
    ): MenuBlurEvent {
        const nextActiveElement = (
            eventTarget ? eventTarget : document.activeElement || document.body
        ) as HTMLElement;
        return new MenuBlurEvent({ menuElement: this.getMenuEl(), nextActiveElement, input });
    }

    protected updateChildren() {
        if (this.childrenContainerEl) {
            this.children = Array.from(this.childrenContainerEl.children) as HTMLElement[];

            this.addedChildren.forEach(added => {
                const index = this.children.findIndex(child => {
                    return (
                        child === added.afterElement ||
                        (this.props.isAngular && child.firstChild === added.afterElement)
                    );
                });
                if (index !== -1) {
                    this.children.splice(index + 1, 0, ...added.children);
                }
            });

            this.children = this.children
                .map(el => {
                    if (this.isElementFocusable(el)) {
                        return el;
                    }
                    if (
                        this.props.isAngular &&
                        el.firstChild &&
                        this.isElementFocusable(el.firstChild)
                    ) {
                        return el.firstChild;
                    }
                    return null;
                })
                .filter(el => !!el)
                .map(el => el as HTMLElement);
        } else {
            this.children = [];
        }
    }

    protected focusChildOnMenuFocus() {
        if (!this.hasFocussedChild() && !this.focusChildAtIndex(this.focusIndex)) {
            // If no child is already focussed and the last focusIndex cannot be
            // focussed, then focus the first child.
            this.focusChildAtIndex(this.getFirstFocusableIndex());
        }
    }

    protected hasFocussedChild() {
        return this.children.some(child => child === document.activeElement);
    }

    protected focusChild(child: HTMLElement) {
        // setTimeout ensures that no other element claims focus during
        // the same render tick, such as (for example) a button that
        // serves as a trigger to show this menu.
        setTimeout(() => {
            // Resetting scrollY immediately after setting focus
            // prevents the browser from centering the focussed item.
            const scrollY = window.scrollY;
            child.focus();
            // Scoll values are undefined in IE
            if (window.scrollX !== undefined && window.scrollY !== undefined) {
                window.scrollTo(window.scrollX, scrollY);
            }
        }, 1);
    }

    protected focusChildAtIndex(index: number) {
        if (index !== -1 && this.children[index]) {
            this.focusChild(this.children[index]);
            return true;
        }
        return false;
    }

    protected getFirstFocusableIndex() {
        for (let i = 0; i < this.children.length; i++) {
            if (this.isElementFocusable(this.children[i])) {
                return i;
            }
        }
        return -1;
    }

    protected getIndexForChildWithElement(element: HTMLElement) {
        const eventIsOutsideDocument = isBrowser && window === (element as any);

        return this.children.findIndex(
            child => eventIsOutsideDocument || child === element || child.contains(element)
        );
    }

    protected isElementFocusable(el: ChildNode | null) {
        if (el instanceof HTMLElement) {
            const tabIndexStr = el.getAttribute('tabindex');
            if (tabIndexStr) {
                return +tabIndexStr >= 0;
            }
        }
        return false;
    }

    protected hasChildren() {
        return this.children && this.children.length;
    }

    protected updateProps(props: BaseStateViewModelProps): BaseStateViewModelProps {
        if (!props.visible && this.props.visible) {
            this.hide();
        }
        return { ...this.props, ...props };
    }

    protected hide() {
        this.svmCtrl.blur(this);
    }
}
