import {
    StyleSheet,
    CssClassDefinitionsObject,
    CssPropertyType,
} from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { ButtonSize } from '@gs-ux-uitoolkit-common/button';
import { DeepReadonly } from 'ts-essentials';
import './dropdown-button-theme-overrides';

export interface DropdownButtonStyleSheetProps {
    theme: Theme;
    size: ButtonSize;
}

export interface DropdownButtonCssClasses {
    root: string;
    buttonGroup: string;
    singleButton: string;
    singleButtonInnerButton: string;
    splitButton: string;
    splitButtonInnerButton: string;
    toggleButton: string;
}

export type DropdownButtonStyledClasses = CssClassDefinitionsObject<keyof DropdownButtonCssClasses>;

export interface DropdownButtonStyleOverridesParams {
    props: DeepReadonly<DropdownButtonStyleSheetProps>;
    createDefaultStyledClasses: () => DropdownButtonStyledClasses;
}

export const dropdownButtonStyleSheet = new StyleSheet(
    'dropdown-button',
    (props: DropdownButtonStyleSheetProps) => {
        return createComponentClassDefinitions<
            DropdownButtonStyleSheetProps,
            DropdownButtonStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.dropdownButton);
    }
);

function createDefaultStyledClasses({
    size,
}: DropdownButtonStyleSheetProps): DropdownButtonStyledClasses {
    return {
        root: { display: 'inline-block' },
        buttonGroup: {},
        singleButton: {},
        singleButtonInnerButton: {
            [`> [data-gs-uitk-component='icon']:last-child:not(:only-child)`]: {
                paddingLeft: dropdownButtonSizeVariants[size].singleButtonIconPaddingInnerSide,
            },
        },
        splitButton: {},
        splitButtonInnerButton: {
            [`> :only-child:not([data-gs-uitk-component='icon']),
            > :last-child:not(:only-child):not([data-gs-uitk-component='icon'])`]: {
                paddingRight: dropdownButtonSizeVariants[size].splitButtonTextPaddingInnerSide,
            },
        },
        toggleButton: {},
    };
}

const dropdownButtonSizeVariants: {
    [name in ButtonSize]: DropdownButtonSizeVariant;
} = {
    sm: {
        singleButtonIconPaddingInnerSide: '2px',
        splitButtonTextPaddingInnerSide: '8px',
    },
    md: {
        singleButtonIconPaddingInnerSide: '4px',
        splitButtonTextPaddingInnerSide: '12px',
    },
    lg: {
        singleButtonIconPaddingInnerSide: '6px',
        splitButtonTextPaddingInnerSide: '16px',
    },
    xl: {
        singleButtonIconPaddingInnerSide: '8px',
        splitButtonTextPaddingInnerSide: '32px',
    },
};

interface DropdownButtonSizeVariant {
    singleButtonIconPaddingInnerSide: CssPropertyType['padding'];
    splitButtonTextPaddingInnerSide: CssPropertyType['padding'];
}
