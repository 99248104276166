import { StyleSheet } from '@gs-ux-uitoolkit-react/style';
import { lightTheme, Theme } from '@gs-ux-uitoolkit-react/theme';
import { MOBILE_BREAKPOINT } from '../style-constant';

export const experiencedExpressionStyleSheet = new StyleSheet(
    'quickfilterAdvancedExpression',
    ({ theme }: { theme: Theme }) => {
        const themeObject = theme || lightTheme;
        return {
            root: {
                margin: '0.15rem',
                '.gs-uitk-experienced-expression-group-container': {
                    '.gs-uitk-experienced-expression-group-container': {
                        backgroundColor: themeObject.surface.secondary,
                    },
                    '&.inverted-background': {
                        backgroundColor: themeObject.surface.primary,
                    },
                    overflow: 'visible',
                    '&, &:last-child': {
                        border: 'none',
                    },
                    '.gs-uitk-experienced-expression-group-or-rule-container': {
                        padding: '0 2px 0.5rem',
                        '.gs-accordion-panel__body': {
                            padding: '0 0.15rem 0.5rem',
                        },
                        p: {
                            margin: 0,
                        },
                        '.gs-accordion-panel__toggle': {
                            padding: '0',
                            backgroundColor: 'inherit',
                            border: 'none',
                        },
                        '.gs-uitk-advanced-expression-header': {
                            display: 'flex',
                            alignItems: 'center',
                            fontFamily: 'Goldman Sans',
                            padding: '8px 0',

                            '& > .gs-icon': {
                                marginLeft: '0.5rem',
                            },

                            '&-group-label': {
                                marginLeft: '0.25rem',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whitespace: 'nowrap',
                            },
                            '&-delete': {
                                marginLeft: 'auto',
                                marginRight: '0.5rem',
                            },
                        },

                        '.gs-uitk-experienced-expression-condition-dropdown': {
                            margin: '1rem 0.5rem',
                        },
                    },

                    '& > .gs-uitk-experienced-expression-condition-dropdown': {
                        margin: '1rem 0',
                    },
                    '& > .gs-uitk-experienced-expression-group-or-rule-container': {
                        padding: '0',
                        boxShadow: themeObject.elevation['04'].shadow,
                    },

                    '.gs-uitk-experienced-expression-button-group': {
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '0.5rem 0.5rem 0',
                        '.gs-uitk-create-button-container': {
                            outline: 'none',
                        },
                    },
                },
                '.gs-uitk-advanced-expression-rule-container': {
                    marginLeft: '0.25rem',
                    '.gs-uitk-advanced-expression-rule': {
                        display: 'flex',
                        flexDirection: 'column',
                        '&-row-span': {
                            display: 'flex',
                            marginBottom: '0.5rem',
                        },

                        '.expression-range-input-value': {
                            input: {
                                minHeight: '22px',
                                maxWidth: 'unset',
                            },
                            '.gs-input-number__buttons-container': {
                                height: '22px',
                            },
                        },
                        '&-input, .flatpickr-input': {
                            flex: 1,
                            minWidth: 0,
                            marginRight: '0.5rem',
                            whiteSpace: 'nowrap',
                        },
                    },
                },
                '.gs-uitk-experienced-expression-add-group-button': {
                    margin: '1rem 0',
                },

                [`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
                    '.gs-uitk-experienced-expression-group-container': {
                        '.gs-uitk-advanced-expression-rule-container': {
                            '.gs-uitk-advanced-expression-rule': {
                                '&-row-span': {
                                    flexDirection: 'column',
                                    marginBottom: '0',
                                },
                                '&-input': {
                                    marginBottom: '0.5rem',
                                },
                            },
                        },
                    },
                },
            },
        };
    }
);
