import React from 'react';

const Trading = () => {
  return (
    <div className="trading">
      {`Trading page here`}
    </div>
  );
};

export default Trading;
