import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import { Conditional } from "../Conditional";
import Highlighter from "./Highlighter";

const OptionRenderer = ({
  title,
  list,
  searchTerm = "",
  onSelection,
  focusedListItem,
  activeDrop,
  dropIndex,
  iconInOptions
}) => {
  return (
    <ul>
      {title && (
        <ul>
          <li className="title" aria-label="dropdown-title">
            {title}
          </li>
        </ul>
      )}
      <ul className="options">
        {list.map((data, i) => {
          return (
            <li
              role="presentation"
              title={data.labels.reduce((acc, cur, idx, arr) =>  `${acc}${cur.name}${idx < arr.length - 1 ? ',' : ''} `, '')}
              tabIndex="0"
              key={i}
              onClick={() => onSelection(data)}
              aria-label="list-options"
              className={cn("list", {
                "list-focused":
                  i === focusedListItem && activeDrop === dropIndex,
              })}
            >
              {iconInOptions}
              <Conditional condition={Array.isArray(data.labels)}>
                <>
                  {data.labels.map((lab, i) => {
                    return (
                      <Highlighter
                        key={i}
                        isLastItem={data.labels.length - 1 === i}
                        input={lab.name}
                        keywords={searchTerm.split(" ")}
                        isGreyText={lab.isScGreyText}
                      />
                    );
                  })}
                </>
                <Highlighter
                  isLastItem
                  input={data.labels}
                  keywords={searchTerm.split(" ")}
                  isGreyText={false}
                />
              </Conditional>
            </li>
          );
        })}
      </ul>
    </ul>
  );
};

OptionRenderer.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
  onSelection: PropTypes.func,
  searchTerm: PropTypes.string,
  activeDrop: PropTypes.number,
  dropIndex: PropTypes.number,
  focusedListItem: PropTypes.number,
  iconInOptions: PropTypes.node,
};

export default OptionRenderer;
