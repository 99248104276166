import {
    Border,
    ColorName,
    emphases,
    Emphasis,
    Surface,
} from '@gs-ux-uitoolkit-common/design-system';
import { ColorSchemeVariantProps, InteractionShades, Theme, ThemeStatus } from './theme';

type ThemeToken = {
    token: string;
    value: string;
};

type ThemeObject = { [key: string]: string };

type ThemeKey = keyof Theme;

const isUpperCase = (character: string) => character === character.toUpperCase();
const isHyphenChar = (character: string) => character === '-';
const isNum = (character: string) => !isNaN(Number(character));

/**
 * Formats the camelCased token name to kebab case since the token name is used as a CSS variable
 * @param token - token to be formatted kebab case
 * @returns kebab-cased token name
 */
export function formatTokenName(token: string) {
    return token
        .split('')
        .map((char: string) =>
            // converts uppercase letter of camelCase to lowercase excluding the hyphens as we already might have a hyphen character in between. uppercase of number is the number itself so it might introduce unnnecessary hyphens
            isUpperCase(char) && !isHyphenChar(char) && !isNum(char)
                ? `-${char.toLowerCase()}`
                : char
        )
        .join('');
}

/**
 * Generates core tokens
 * @param theme theme of the application passed to the components
 * @returns array of objects containing the design token and it's respective value
 */
function getCoreTokens(theme: Theme | ThemeObject) {
    const tokens: ThemeToken[] = [];
    const keys = Object.keys(theme) as ThemeKey[];
    const coreTokenKeys = ['color'];
    const colorTokenKeys = ['surface', 'text', 'border', 'dataviz', 'status'];
    keys.forEach(key => {
        // Generate tokens for all keys except for the fonts since these are not actual
        // supported tokens and are only in the theme for supplying fonts to be injected
        if (key !== 'fonts') {
            const tokenValue = theme[key];
            const tokenKey =
                coreTokenKeys.indexOf(key) > -1
                    ? `${key}-core`
                    : colorTokenKeys.indexOf(key) > -1
                      ? `color-${key}`
                      : key;
            if (typeof tokenValue === 'object') {
                const childTokens = getCoreTokens(tokenValue as ThemeObject);
                const nestedTokens = childTokens.map(childToken => ({
                    ...childToken,
                    token: formatTokenName(`${tokenKey}-${childToken.token}`),
                }));
                tokens.push(...nestedTokens);
            } else if (typeof tokenValue === 'string' || typeof tokenValue === 'number') {
                tokens.push({
                    token: formatTokenName(tokenKey),
                    value: tokenValue,
                });
            }
        }
    });
    return tokens;
}

function getEmphasisTokens({
    coreTokenObject,
    tokenKey,
    prefix,
    theme,
}: {
    coreTokenObject: ThemeObject;
    tokenKey: ColorName | ThemeStatus | keyof ColorSchemeVariantProps | Surface;
    prefix: string;
    theme: Theme;
}) {
    const tokens: ThemeToken[] = [];
    const replacementEmphasis: { [key: string]: string } = {
        minimal: 'bold',
    };
    const interactions = ['hover', 'active', 'text'];
    emphases.forEach((emphasis: Emphasis) => {
        tokens.push({
            token: formatTokenName(`${prefix}-${tokenKey}-${emphasis}`),
            value: coreTokenObject[replacementEmphasis[emphasis as string] || emphasis].toString(),
        });
        const interactionShades = theme.getColorInteractionShades(
            tokenKey as ColorName | ThemeStatus,
            emphasis
        );
        interactions.forEach(interaction => {
            tokens.push({
                token: formatTokenName(`${prefix}-${tokenKey}-${emphasis}-${interaction}`),
                value: interactionShades[interaction as keyof InteractionShades].toString(),
            });
        });
    });

    return tokens;
}

function getColorDerivedTokens(theme: Theme) {
    const { color } = theme;
    const colors = Object.keys(color) as ColorName[];
    let tokens: ThemeToken[] = [];
    const themeObject = theme.color as Theme['color'];
    colors.forEach(color => {
        tokens = [
            ...tokens,
            ...getEmphasisTokens({
                coreTokenObject: themeObject[color] as ThemeObject,
                tokenKey: color,
                prefix: 'color-core',
                theme,
            }),
        ];
    });
    return tokens;
}

function getStatusDerivedTokens(theme: Theme) {
    const { status } = theme;
    const statuses = Object.keys(status) as ThemeStatus[];
    let tokens: ThemeToken[] = [];
    const themeObject = theme.status as Theme['status'];
    statuses.forEach(status => {
        tokens = [
            ...tokens,
            ...getEmphasisTokens({
                coreTokenObject: themeObject[status] as ThemeObject,
                tokenKey: status,
                prefix: 'color-status',
                theme,
            }),
        ];
    });
    return tokens;
}

function getSurfaceInteractionTokens(surface: Surface, theme: Theme) {
    const tokens: ThemeToken[] = [];
    type Interactions = keyof InteractionShades;
    const interactions: Interactions[] = ['hover', 'active', 'text', 'border'];
    const surfaceInteractionShades = theme.getSurfaceInteractionShades(surface);
    interactions.forEach(interaction => {
        tokens.push({
            token: formatTokenName(`color-surface-${surface}-${interaction}`),
            value: surfaceInteractionShades[interaction].toString(),
        });
    });
    return tokens;
}

function getSurfaceEmphasisInteractionTokens(surface: Surface, theme: Theme) {
    const tokens: ThemeToken[] = [];
    type Interactions = keyof InteractionShades;
    const interactions: Interactions[] = ['background', 'hover', 'active', 'text', 'border'];
    emphases.forEach((emphasis: Emphasis) => {
        const surfaceInteractionShades = theme.getSurfaceInteractionShades(surface, emphasis);
        if (emphasis !== 'minimal') {
            interactions.forEach(interaction => {
                if (interaction === 'background') {
                    tokens.push({
                        token: formatTokenName(`color-surface-${surface}-selected-${emphasis}`),
                        value: surfaceInteractionShades[interaction].toString(),
                    });
                    return;
                }
                tokens.push({
                    token: formatTokenName(
                        `color-surface-${surface}-selected-${emphasis}-${interaction}`
                    ),
                    value: surfaceInteractionShades[interaction].toString(),
                });
            });
        }
    });
    return tokens;
}

/* 
    Generates derived tokens from core surface tokens
*/
function getSurfaceDerivedTokens(theme: Theme) {
    const { surface } = theme;
    const surfaces = Object.keys(surface) as Surface[];
    let tokens: ThemeToken[] = [];
    surfaces.forEach(surface => {
        tokens = [
            ...tokens,
            ...getSurfaceInteractionTokens(surface, theme),
            ...getSurfaceEmphasisInteractionTokens(surface, theme),
        ];
    });
    return tokens;
}

/* 
    Generates derived tokens from core border tokens
*/
function getBorderDerivedTokens(theme: Theme) {
    const tokens: ThemeToken[] = [];
    const { border } = theme;
    const borders = Object.keys(border) as Border[];
    borders.forEach(border => {
        tokens.push({
            token: formatTokenName(`color-border-${border}-hover`),
            value: theme.increaseContrast(theme.border[border], 1).toString(),
        });
    });
    return tokens;
}

/* 
    Generates derived tokens from core tokens
*/
function getDerivedTokens(theme: Theme) {
    return [
        ...getColorDerivedTokens(theme),
        ...getStatusDerivedTokens(theme),
        ...getSurfaceDerivedTokens(theme),
        ...getBorderDerivedTokens(theme),
    ];
}

/**
 * A utility that generates an object containing GSDS design tokens and their values that can be consumed in
 * @param theme - theme of the application passed to the components
 * @returns object having property name as CSS variable name and value as the token value for that CSS variable
 */
export function generateCssVars(theme: Theme): { [key: string]: string } {
    const coreTokens = getCoreTokens(theme);
    const derivedTokens = getDerivedTokens(theme);
    return [...coreTokens, ...derivedTokens].reduce(
        (tokens, currentToken) => ({
            ...tokens,
            [`--gs-uitk-${currentToken.token}`]: currentToken.value,
        }),
        {}
    );
}

/**
 * A utility to inject CSS vars to the specified DOM element
 * @param theme - theme of the application passed to the components
 * @param element - HTML DOM element to inhect CSS variables to
 */
export function injectCssVars(theme: Theme, element: HTMLElement) {
    const cssVariables = generateCssVars(theme);
    Object.keys(cssVariables).forEach((cssVariable: string) => {
        element.style.setProperty(cssVariable, cssVariables[cssVariable]);
    });
}
