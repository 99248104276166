import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import momentTimezone from 'moment-timezone';
import {Card, CardBody, CardHeader, CardTitle} from '@gs-ux-uitoolkit-react/card';
import cloneDeep from 'lodash/cloneDeep';
import {withScroll} from "../../../../components/core/Scrollbar";
import {currencyFormatter} from "../../../../utils/portfolioFormatter";
import {EXTERNAL_LINK_SKIP_ATTRIBUTE} from "../../../../constants/pageConstants";

const offShoreGSFundsDocumnets = [
  { name: 'Monthly Fund Summary', mandatory: true },
  { name: 'Prospectus', mandatory: true },
  { name: 'Fund Literature', mandatory: true },
  { name: 'KIID', mandatory: false },
  { name: 'Ex-Ante Cost And Charges', mandatory: false },
];

const onShoreGSFundsDocumnets = [
  { name: 'Monthly Fund Summary', mandatory: true },
  { name: 'Summary Prospectus', mandatory: true },
  { name: 'Prospectus', mandatory: true },
  { name: 'Annual Report', mandatory: true },
  { name: 'Semi-Annual Report', mandatory: true },
  { name: 'SAI', mandatory: true },
  { name: 'Weekly Holdings', mandatory: false },
  { name: 'Monthly Holdings', mandatory: false },
  { name: 'Fund Literature', mandatory: false },
]
const notPresent = 'Unavailable';

export const getObject = (data, key) => {
  return key.split('.').reduce((d, k) => {
    return d ? d[k] : undefined;
  }, data);
};

export const getData = (data, key) => {
  const keyObj = getObject(data, key);
  if (keyObj === undefined) {
    return notPresent;
  }
  return keyObj;
};

const getDateInFormat = (date) => {
  if (!date || date === notPresent) {
    return  notPresent;
  }
  return moment(date).format('DD.MMM.YYYY');
};

const getDecimalRoundOff = (value, n) => {
  if (!value || value === notPresent) {
    return  notPresent;
  }
  return Number(value).toFixed(n);
};

const getTimeZoneAbbr = (timezone) => {
  if (timezone !== 'Unavailable') {
    return momentTimezone.tz(timezone).zoneAbbr()
  }
  return '';
};

const cardConfig = [
  {
    title: 'Performance',
    id: 'performance',
    asOfDate: 'shareclassPerformance.prevTradeDate',
    innerConfig: [
      {
        key: 'shareclassPerformance.rate',
        label: 'Daily Factor',
        isLabelLink: true
      },
      {
        key: 'shareclassPerformance.yield',
        label: 'Yield: 1 Day',
        isLabelLink: true,
        formatter: (data) => {
          return getData(data, 'shareclassPerformance.yield.amount');
        }
      },
      {
        key: 'shareclassPerformance.curYield7Day.amount',
        label: 'Yield: 7 Day Current',
        isLabelLink: true
      },
      {
        key: 'shareclassPerformance.effYield7Day.amount',
        label: 'Yield: 7 Day Effective',
        isLabelLink: true
      },
      {
        key: 'shareclassPerformance.yield30Day.amount',
        label: 'Yield: 30 Day',
        isLabelLink: true
      }
    ]
  },
  {
    title: 'Fund Information',
    id: 'fundInformation',
    innerConfig: [
      {
        subCards: {
          title: 'Assets',
          id: 'assets',
          asOfDate: 'shareclassPerformance.totalAssetsAsOfTime',
          config: [
            {
              key: 'shareclassPerformance.portfolioAssets.amount',
              label: 'Total Fund Assets',
              formatter: (data) => {
                let portfolioAssetAmount = getData(data, 'shareclassPerformance.portfolioAssets.amount');
                portfolioAssetAmount = getDecimalRoundOff(portfolioAssetAmount, 2);
                const portfolioAssetCurrency = getData(data, 'shareclassPerformance.portfolioAssets.currency');
                const {value} = currencyFormatter({value: portfolioAssetAmount, fundCcy: portfolioAssetCurrency});
                return portfolioAssetAmount === notPresent ? notPresent : value;
              },
              isLabelLink: true
            }
          ]
        }
      },
      {
        subCards: {
          title: 'Liquidity',
          id: 'liquidity',
          asOfDate: 'shareclassPerformance.liquidityAsOfDate',
          config: [
            {
              key: 'shareclassPerformance.liqDly.amount',
              label: 'Daily Liquid Assets (%)',
              isLabelLink: true
            },
            {
              key: 'shareclassPerformance.ligWkly.amount',
              label: 'Weekly Liquid Assets (%)',
              isLabelLink: true
            }
          ]
        }
      },
      {
        subCards: {
          title: 'Duration',
          id: 'duration',
          asOfDate: 'shareclassPerformance.durationAsOfTime',
          config: [
            {
              key: 'shareclassPerformance.wam',
              label: 'WAM (Days)',
              isLabelLink: true
            },
            {
              key: 'shareclassPerformance.wal',
              label: 'WAL (Days)',
              isLabelLink: true
            }
          ]
        }
      }
    ]
  },
  {
    title: 'Fund Facts',
    id: 'fundFacts',
    innerConfig: [
      {
        key: 'shareclass.fundDetails.fundSchedules.schedule.timeOfDay',
        label: 'NAV Pricing Schedule',
        formatter: (data) => {
          const fundSchedules = getData(data, 'shareclass.fundDetails.fundSchedules');
          if (fundSchedules !== notPresent && fundSchedules.length > 0) {
            return fundSchedules.map((fundSchedule) => {
              const { schedule } = fundSchedule;
              if (schedule && schedule.timeOfDay) {
                return (
                  <div>{`${schedule.timeOfDay} ${getTimeZoneAbbr(schedule.timeZone)}`}</div>
                );
              }
              return <div>{notPresent}</div>;
            })
          }
          return <div>{notPresent}</div>;
        }
      },
      {
        key: 'shareclass.cusip',
        label: 'CUSIP'
      },
      {
        key: 'shareclass.shareClassDetails.displayCode',
        label: 'Fund #'
      },
      {
        key: 'shareclass.shareClassDetails.cutOffs.subsCutoff',
        label: 'Purchase Cutoff',
        formatter: (data) => {
          const cutOff = getData(data, 'shareclass.shareClassDetails.cutOffs.subsCutoff');
          const timezone = getData(data, 'shareclass.shareClassDetails.cutOffs.cutoffTimeZone');
          if (cutOff !== notPresent) {
            return `${cutOff} ${timezone ? getTimeZoneAbbr(timezone) : ''}`;
          }
          return notPresent;
        }
      },
      {
        key: 'shareclass.shareClassDetails.cutOffs.redCutoff',
        label: 'Redemption Cutoff',
        formatter: (data) => {
          const cutOff = getData(data, 'shareclass.shareClassDetails.cutOffs.redCutoff');
          const timezone = getData(data, 'shareclass.shareClassDetails.cutOffs.cutoffTimeZone');
          if (cutOff !== notPresent) {
            return `${cutOff} ${timezone ? getTimeZoneAbbr(timezone) : ''}`;
          }
          return notPresent;
        }
      },
      {
        key: 'shareclass.shareClassDetails.settlementCycle',
        label: 'Settlement Cycle',
        formatter: (data) => {
          const settlementCycle = getData(data, 'shareclass.shareClassDetails.settlementCycle');
          if (settlementCycle !== notPresent) {
            let value = 'T';
            if (settlementCycle !== 0) {
              value = `${value}${settlementCycle > 0 ? `+${settlementCycle}` : settlementCycle}`
            }
            return value;
          }
          return notPresent;
        }
      },
      {
        key: 'shareclass.fundDetails.fundType',
        label: 'Fund Type'
      },
      {
        key: 'shareclass.fundDetails.navType',
        label: 'NAV Type'
      }
    ]
  },
  {
    title: 'Resources',
    id: 'resources',
    innerConfig: [
      {
        key: 'shareclass.documentLinks',
        label: 'Daily Factor',
        labelFormatter: (data) => {
          const docLinks = getData(data, 'shareclass.documentLinks');
          const taId = getData(data, 'shareclass.fundDetails.taInfo.gtamTaId');
          // let fundCode = getData(data, 'shareclass.fundDetails.fundFamily.code');
          let fundsDocLinks = onShoreGSFundsDocumnets;
          if (taId === 2) {
            fundsDocLinks = offShoreGSFundsDocumnets;
          }
          const docNameMap = {};
          if (docLinks !== notPresent) {
            docLinks.forEach((link) => {
              const {linkName, path} = link;
              docNameMap[linkName.toLowerCase()] = path;
            })
          }
          return (
            <div>
              {
                fundsDocLinks.map((link) => {
                  const {name, mandatory} = link;
                  const path = docNameMap[name.toLowerCase()];
                  if (mandatory) {
                    return (
                      <div key={`${name}`}>
                        { path ? <a style={{color: '#186ADE'}} href={path} target={'_blank'} {...{[EXTERNAL_LINK_SKIP_ATTRIBUTE]:true}}>{name}</a> : name }
                      </div>
                    );
                  } else if (path) {
                    return (
                      <div key={`${name}`}>
                        <a style={{color: '#186ADE'}} href={path} target={'_blank'} {...{[EXTERNAL_LINK_SKIP_ATTRIBUTE]:true}}>{name}</a>
                      </div>
                    );
                  }
                  return null;
                })
              }
            </div>
          );
        },
        formatter: () => {
          return <div/>;
        }
      }
    ]
  }
];

const getLabel = ({label, isLabelLink, labelFormatter}, data, index) => {
  if (labelFormatter) {
    return labelFormatter.apply(this, [data]);
  }

  return <div style={{color: isLabelLink ? '#186ADE' : '#000'}} data-test={`label-${index}`}>{label}</div>;
};

const isVisible = (visible, dataValue, getIndex, idCard, setVisible) =>{
  const arr = visible;
  if(dataValue!==notPresent){
    arr[getIndex.indexOf(idCard)]=true;
    setVisible(arr);
  }
};
const CardText = (idCard, config, data, headerSize, index, setVisible, visible) => {
  const { subCards, key, subKey, formatter} = config;
  const getIndex = ['performance', 'assets', 'liquidity', 'duration'];
  if (subCards) {
    const {title, id, config, asOfDate} = subCards;
    const show = visible[getIndex.indexOf(id)];
    return (
      <div key={`${key}-${index}`} style={{marginBottom: 12}}>
        <CardTitle style={{margin: 0, display: 'flex'}} tag={`h${headerSize + 1}`}>
          <div style={{flex: 1, color: '#000000', fontSize: '14px', lineHeight: '20px' }}>{title}</div>
          {asOfDate && show && <div style={{color: '#3E5463', fontWeight: 400, fontSize: '14px', lineHeight: '20px'}}>{`As of ${getDateInFormat(getData(data, asOfDate))}`}</div>}
        </CardTitle>
        <div>{
          config.map((c) => {
            return CardText(id, c, data, headerSize + 1, index, setVisible, visible);
          })
        }
        </div>
      </div>
    );
  }
  else{
    const valuesToRoundOff = [
      'shareclassPerformance.yield',
      'shareclassPerformance.curYield7Day.amount',
      'shareclassPerformance.effYield7Day.amount',
      'shareclassPerformance.yield30Day.amount',
      'shareclassPerformance.liqDly.amount',
      'shareclassPerformance.ligWkly.amount'
    ];
    let dataValue;
    if (formatter) {
      dataValue = formatter.apply(this, [data]);
    } else if (valuesToRoundOff.indexOf(key) !== -1) {
      dataValue = getDecimalRoundOff(getData(data, key, subKey), 2);
    } else {
      dataValue = getData(data, key, subKey);
    }
    isVisible(visible, dataValue, getIndex, idCard, setVisible);
    return (

      <div key={`${key}-${index}`} style={{display: 'flex'}}>
        <div style={{flex: 1}} >{getLabel(config, data, index)}</div>
        <div style={{fontWeight: 500, color: '#000000', textAlign: 'right'}} data-test={`value-${index}`}>
          {dataValue}
        </div>
      </div>
    );
  }
};

const CardBodyComponent = ({title, innerConfig, id, data, setVisible, visible}) => (
  <div data-test={title}>
    {
      innerConfig.map((ic, index) => {
        return CardText(id, ic, data, 3, index, setVisible, visible);
      })
    }
  </div>
);

CardBodyComponent.propTypes = {
  title: PropTypes.object,
  innerConfig: PropTypes.object,
  id: PropTypes.object,
  data: PropTypes.object,
  setVisible: PropTypes.func,
  visible: PropTypes.array

};

const ShareClassCard = (props) => {
  const {config, data, index} = props;
  const {title, innerConfig, id, asOfDate} = config;
  const [visible, setVisible] = useState([false, false, false, false]);
  return (
    <Card style={{height: '100%'}}>
      <CardHeader>
        <CardTitle style={{display: 'flex'}}>
          <div style={{flex: 1, color:'#000000', fontSize: '16px', lineHeight: '24px' }} data-test={`title-${index}`}>{title}</div>
          {
            asOfDate && visible[0] &&
            <div style={{color: '#3E5463', fontWeight: 400, fontSize: '14px', lineHeight: '20px'}} data-test={`titleAsOfDate-${index}`}>
              {`As of ${getDateInFormat(getData(data, asOfDate))}`}
            </div>
          }
        </CardTitle>
      </CardHeader>
      <CardBody>
        {
          withScroll(CardBodyComponent)({
            title,
            innerConfig,
            id,
            data,
            setVisible,
            visible
          })
        }
      </CardBody>
    </Card>
  );
};

ShareClassCard.propTypes = {
  config: PropTypes.object,
  data: PropTypes.object,
  index: PropTypes.object
};

const CardComponent = ({shareclass = {}}) => {
  const staticCard = cloneDeep(cardConfig);
  const [newCardConfig, setCardConfig] = useState(staticCard);
  useEffect(() => {
    const tempCardConfig = newCardConfig.map((conf)=> {
      if (conf.id === 'fundFacts') {
        const newInnerConfig= [...conf.innerConfig];
        const data = shareclass.shareclass;
        if (data) {
          const { shareClassDetails: {domicile} = {} } = data;
          if (domicile && domicile !== 'US') {
            newInnerConfig.splice(1, 2, {
              key: 'shareclass.isin',
              label: 'ISIN'
            });
            conf.innerConfig = newInnerConfig;
          }
        }
      }
      return conf;
    });
    setCardConfig(tempCardConfig);
  }, []);

  return (
    <div className='fund-fact-card'>
      {
        newCardConfig.map((config, index) => {
          return (
            <div
              key={`${config.title}-${index}`}
              style={{
                height: 335,
                width: 'calc(25% - 12px)',
                marginRight: '12px'
              }}>
              <ShareClassCard config={config} data={shareclass} index={index}/>
            </div>
          );
        })
      }
    </div>
  );
};

CardComponent.propTypes = {
  shareclass: PropTypes.object
};

export default CardComponent;
