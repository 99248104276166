import reduceReducers from 'reduce-reducers';
import actionTypes from '../../actions/app/actionTypes';
import logInActionTypes from '../../actions/page/login/actionTypes';
import { tradeBlotterContextReducer, tradeBlotterDataReducer } from './tradeBlotter';
import {reportsAppContextReducer, reportsAppDataReducer} from './reports';
import {importTradesContextReducer} from './tradeBlotter/importTrades';
import pageActionTypes from '../../actions/page/actionTypes';

const defaultAppData = {};
const defaultAppContext = {};

export function rootAppDataReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.CLEAR_APP_DATA:
      return defaultAppData;
    case actionTypes.LOGOUT:
      return {...state};
    case actionTypes.ADD_APP_DATA:
      return {...state, ...action.data};
    default:
      return state;
  }
}

export function rootAppContextReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.CLEAR_APP_CONTEXT:
      return defaultAppContext;
    case actionTypes.APP_INITIAL_LOAD:
    case actionTypes.APP_LOADING:
    case actionTypes.ADD_APP_CONTEXT:
    case actionTypes.UPDATE_MESSAGES:
    case actionTypes.UPDATE_MESSAGE_COUNT:
    case actionTypes.IS_SESSION_EXPIRED:
    case actionTypes.ADD_REPORTING_PERMIT_ROLES:
    case actionTypes.STOP_POLLING_TICKER_API:
    case actionTypes.IS_TMX_PROFILING_COMPLETE:
    case actionTypes.UPDATE_PENDING_TRADES_FOR_APPROVAL_FLAG:
    case actionTypes.PENDING_TRADES_FOR_APPROVAL_FLAG_DATA_LOADING: {
      return {...state, ...action.data};
    }
    case logInActionTypes.SUBMIT_CREDENTIALS:
      return { ...state, username: action.data.username};
    case pageActionTypes.UPDATE_PAGE_ID:
      return { ...state, tradeSaveFailed: undefined };
    default:
      return state;
  }
}

const contextReducers= [tradeBlotterContextReducer, importTradesContextReducer, reportsAppContextReducer]; // Add more reducers here
const dataReducers = [tradeBlotterDataReducer, reportsAppDataReducer]; // Add more reducers here

export const appDataReducer = reduceReducers(...dataReducers, rootAppDataReducer);
export const appContextReducer = reduceReducers(...contextReducers, rootAppContextReducer);
