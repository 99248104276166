import {
    ExpressionCondition,
    ExpressionGroup,
    globalHelper,
    ExpressionRule,
    ExpressionOperator,
    ExpressionQuery,
    genericExpressionHelper,
    invariantQuery,
} from '@gs-ux-uitoolkit-common/datacore';
import { DataGridState } from '../../redux/datagrid-state';
import { searchableHelper } from './searchable-helper';
import { GridWrapper } from '../../grid-wrappers/grid-wrapper';
import {
    TextFilterModel,
    NumberFilterModel,
    DateFilterModel,
    SimpleFilter,
    ICombinedSimpleModel,
} from '@ag-grid-community/core';
import {
    DRA_FLOATING_FILTER_OPERATOR_VALUE_SEPARATOR,
    DRA_FLOATING_FILTER_PREFIX,
    DRA_FLOATING_FILTER_SEPARATOR,
} from '../../floating-filters/floating-filter-base';
import { hasOwnProperty } from 'gs-uitk-object-utils';

/**
 * Builds a combined ExpressionQuery by combining all QuickFilters, ag-Grid column filters FilterModel and Searchable
 * @param gridWrapper The grid wrapper
 */
export const buildCompositeFilterExpression: (gridWrapper: GridWrapper) => ExpressionGroup = (
    gridWrapper: GridWrapper
) => {
    const state: DataGridState = gridWrapper.getReduxStore().getState();
    const group: ExpressionGroup = {
        condition: ExpressionCondition.And,
        rules: [],
    };

    const dsConfig = state.systemConfiguration.gridWrapperConfiguration.datasourceConfiguration;
    if (dsConfig && dsConfig.fixedDRAFilter) {
        group.rules.push(dsConfig.fixedDRAFilter);
    }

    // QuickFilter Management
    state.quickFilter.configItemList.forEach(quickFilter => {
        if (quickFilter.isEnabled) {
            group.rules.push(
                genericExpressionHelper.expandQuickFiltersFromExpressionQuery(
                    globalHelper.cloneObject(quickFilter.expression?.query ?? invariantQuery),
                    null,
                    state.quickFilter.configItemList
                )
            );
        }
    });

    const filterModel = gridWrapper.getColumnFilterModel();
    Object.entries(filterModel).forEach(filterEntry => {
        // if has property operator then it's a ICombinedSimpleModel otherwise it's a TextFilterModel,NumberFilterModel or DateFilterModel
        if (hasOwnProperty(filterEntry[1], 'operator')) {
            const expressionCondition: ExpressionCondition =
                filterEntry[1].operator === 'AND'
                    ? ExpressionCondition.And
                    : ExpressionCondition.Or;
            const expressionGroup: ExpressionGroup = {
                condition: expressionCondition,
                rules: [],
            };
            if (filterEntry[1].filterType === 'text') {
                const combinedSimpleFilter: ICombinedSimpleModel<TextFilterModel> = filterEntry[1];
                const condition1 = convertTextFilterModelToExpressionRule(
                    filterEntry[0] as string,
                    combinedSimpleFilter.condition1
                );
                if (condition1) {
                    expressionGroup.rules.push(condition1);
                }
                if (
                    combinedSimpleFilter.condition2.filter?.startsWith(DRA_FLOATING_FILTER_PREFIX)
                ) {
                    convertDRAMultipleCondition2(
                        filterEntry[0] as string,
                        combinedSimpleFilter.condition2.filter,
                        expressionGroup
                    );
                } else {
                    const condition2 = convertTextFilterModelToExpressionRule(
                        filterEntry[0] as string,
                        combinedSimpleFilter.condition2
                    );
                    if (condition2) {
                        expressionGroup.rules.push(condition2);
                    }
                }
            } else if (filterEntry[1].filterType === 'number') {
                const combinedSimpleFilter: ICombinedSimpleModel<NumberFilterModel> =
                    filterEntry[1];
                const condition1 = convertNumberFilterModelToExpressionRule(
                    filterEntry[0] as string,
                    combinedSimpleFilter.condition1
                );
                if (condition1) {
                    expressionGroup.rules.push(condition1);
                }
                const condition2 = convertNumberFilterModelToExpressionRule(
                    filterEntry[0] as string,
                    combinedSimpleFilter.condition2
                );
                if (condition2) {
                    expressionGroup.rules.push(condition2);
                }
            } else if (filterEntry[1].filterType === 'date') {
                const combinedSimpleFilter: ICombinedSimpleModel<DateFilterModel> = filterEntry[1];
                const condition1 = convertDateFilterModelToExpressionRule(
                    filterEntry[0] as string,
                    combinedSimpleFilter.condition1
                );
                const condition2 = convertDateFilterModelToExpressionRule(
                    filterEntry[0] as string,
                    combinedSimpleFilter.condition2
                );
                if (condition1) {
                    expressionGroup.rules.push(condition1);
                }
                if (condition2) {
                    expressionGroup.rules.push(condition2);
                }
            }
            group.rules.push(expressionGroup);
        } else if (filterEntry[1].filterType === 'text') {
            const draExpressionQuery = convertTextFilterModelToExpressionRule(
                filterEntry[0] as string,
                filterEntry[1] as TextFilterModel
            );
            if (draExpressionQuery) {
                group.rules.push(draExpressionQuery);
            }
        } else if (filterEntry[1].filterType === 'number') {
            const draExpressionQuery = convertNumberFilterModelToExpressionRule(
                filterEntry[0] as string,
                filterEntry[1] as NumberFilterModel
            );
            if (draExpressionQuery) {
                group.rules.push(draExpressionQuery);
            }
        } else if (filterEntry[1].filterType === 'date') {
            const draExpressionQuery = convertDateFilterModelToExpressionRule(
                filterEntry[0] as string,
                filterEntry[1] as DateFilterModel
            );
            if (draExpressionQuery) {
                group.rules.push(draExpressionQuery);
            }
        } else if (filterEntry[1].filterType === 'set') {
            group.rules.push({
                condition: ExpressionCondition.Or,

                rules: filterEntry[1].values.map((value: any) => {
                    return convertSetFilterModelToExpressionRule(filterEntry[0], value);
                }),
            });
        }
    });

    // Searchable Management
    if (state.searchable.currentSearch !== '') {
        const columnList = state.grid.columnList;
        const searchableFilter = searchableHelper.buildSearchableFilter(
            state.searchable.currentSearch,
            state.searchable.DRASearchableColumnList,
            columnList
        );
        group.rules.push(searchableFilter);
    }

    return group;
};

/**
 * ag-Grid supports only 2 criteria for simple filters. Our floating filter can create more and therefore concatenate them in the condition2
 * ex: "*DRA*contains|Toyota?contains|Honda"
 * @param columnId The columnId
 * @param filter The concatenated string
 * @param expressionGroup The expression group we are building from the ag-grid model
 */
const convertDRAMultipleCondition2 = (
    columnId: string,
    filter: string,
    expressionGroup: ExpressionGroup
) => {
    filter
        .replace(DRA_FLOATING_FILTER_PREFIX, '')
        .split(DRA_FLOATING_FILTER_SEPARATOR)
        .forEach(entry => {
            const operatorAndValue = entry.split(DRA_FLOATING_FILTER_OPERATOR_VALUE_SEPARATOR);
            const condition = {
                field: columnId,
                operator: convertFilterModelOperatorToExpressionOperator(operatorAndValue[0] || ''),
                value: operatorAndValue[1] || '',
            };
            if (condition) {
                expressionGroup.rules.push(condition);
            }
        });
};

/**
 * Converts a simple ProvidedFilterModel to an ExpressionRule
 * @param columnId The column Id
 * @param value a value
 */
const convertSetFilterModelToExpressionRule: (
    columnId: string,
    value: any
) => ExpressionQuery | null = (columnId: string, value: any) => {
    const expressionRule: ExpressionRule = {
        value,
        field: columnId,
        operator: ExpressionOperator.Equals,
    };
    return expressionRule;
};

/**
 * Converts a simple TextFilterModel to an ExpressionRule
 * @param columnId The column Id
 * @param textFilterModel The ag-Grid filter model for the column
 */
const convertTextFilterModelToExpressionRule: (
    columnId: string,
    textFilterModel: TextFilterModel
) => ExpressionQuery | null = (columnId: string, textFilterModel: TextFilterModel) => {
    const expressionRule: ExpressionRule = {
        field: columnId,
        operator: convertFilterModelOperatorToExpressionOperator(textFilterModel.type || ''),
        value: textFilterModel.filter || '',
    };
    return expressionRule;
};

/**
 * Converts a simple NumberFilterModel to an ExpressionRule
 * @param columnId The column Id
 * @param numberFilterModel The ag-Grid filter model for the column
 */
const convertNumberFilterModelToExpressionRule: (
    columnId: string,
    numberFilterModel: NumberFilterModel
) => ExpressionQuery | null = (columnId: string, numberFilterModel: NumberFilterModel) => {
    if (numberFilterModel.filterTo != null && numberFilterModel.filter != null) {
        const expressionRuleFrom: ExpressionRule = {
            field: columnId,
            operator: ExpressionOperator.GreaterThanOrEqual,
            value: numberFilterModel.filter,
        };
        const expressionRuleTo: ExpressionRule = {
            field: columnId,
            operator: ExpressionOperator.LessThanOrEqual,
            value: numberFilterModel.filterTo,
        };
        const expressionGroup: ExpressionGroup = {
            condition: ExpressionCondition.And,
            rules: [expressionRuleFrom, expressionRuleTo],
        };
        return expressionGroup;
    }
    if (numberFilterModel.filter != null) {
        const expressionRule: ExpressionRule = {
            field: columnId,
            operator: convertFilterModelOperatorToExpressionOperator(numberFilterModel.type || ''),
            value: numberFilterModel.filter,
        };
        return expressionRule;
    }
    return null;
};

/**
 * Converts a simple DateFilterModel to an ExpressionRule
 * The date format for ag-Grid is YYYY-MM-DD
 * @param columnId The column Id
 * @param dateFilterModel The ag-Grid filter model for the column
 */
const convertDateFilterModelToExpressionRule: (
    columnId: string,
    dateFilterModel: DateFilterModel
) => ExpressionQuery | null = (columnId: string, dateFilterModel: DateFilterModel) => {
    if (dateFilterModel.dateFrom && dateFilterModel.dateTo) {
        const expressionRuleFrom: ExpressionRule = {
            field: columnId,
            operator: ExpressionOperator.GreaterThan,
            value: new Date(dateFilterModel.dateFrom).toISOString(),
        };
        const expressionRuleTo: ExpressionRule = {
            field: columnId,
            operator: ExpressionOperator.LessThan,
            value: new Date(dateFilterModel.dateTo).toISOString(),
        };
        const expressionGroup: ExpressionGroup = {
            condition: ExpressionCondition.And,
            rules: [expressionRuleFrom, expressionRuleTo],
        };
        return expressionGroup;
    }
    if (dateFilterModel.dateFrom) {
        const expressionRule: ExpressionRule = {
            field: columnId,
            operator: convertFilterModelOperatorToExpressionOperator(dateFilterModel.type || ''),
            value: new Date(dateFilterModel.dateFrom).toISOString(),
        };
        return expressionRule;
    }
    return null;
};

/**
 * Converts an ag-Grid operator to an ExpressionOperator
 * @param operator The ag-Grid operator
 */
const convertFilterModelOperatorToExpressionOperator: (operator: string) => ExpressionOperator = (
    operator: string
) => {
    switch (operator) {
        case SimpleFilter.CONTAINS:
            return ExpressionOperator.ContainsCaseInsensitive;
        case SimpleFilter.NOT_CONTAINS:
            return ExpressionOperator.NotContainsCaseInsensitive;
        case SimpleFilter.STARTS_WITH:
            return ExpressionOperator.StartsWith;
        case SimpleFilter.ENDS_WITH:
            return ExpressionOperator.EndsWith;
        case SimpleFilter.EQUALS:
            return ExpressionOperator.Equals;
        case SimpleFilter.NOT_EQUAL:
            return ExpressionOperator.NotEquals;
        case SimpleFilter.LESS_THAN:
            return ExpressionOperator.LessThan;
        case SimpleFilter.LESS_THAN_OR_EQUAL:
            return ExpressionOperator.LessThanOrEqual;
        case SimpleFilter.GREATER_THAN:
            return ExpressionOperator.GreaterThan;
        case SimpleFilter.GREATER_THAN_OR_EQUAL:
            return ExpressionOperator.GreaterThanOrEqual;
        case SimpleFilter.IN_RANGE:
            throw Error('Handled separately. Should not get here.');
    }
    return ExpressionOperator.Equals;
};
