import appConfig from '../configs/appConfig';
import {getDeviceType} from '../utils/deviceDetector';
import {envSelector} from '../selectors/app';
import constants from '../constants/appConstants';

export const getServiceUrl = (state) => {
  const deviceType = getDeviceType().toLowerCase();
  const environment = envSelector(state).toLowerCase();
  if (deviceType.includes(constants.DESKTOP.toLowerCase())) {
    if (environment === constants.PRODUCTION.toLowerCase()) {
      return appConfig.DESKTOP_SERVICE.PROD_SERVICE_URL;
    } else if (environment === constants.DEVELOPMENT.toLowerCase()) {
      return appConfig.DESKTOP_SERVICE.DEV_SERVICE_URL;
    } else if(environment === constants.LOCAL.toLowerCase()){
      return appConfig.DESKTOP_SERVICE.LOCAL_SERVICE_URL;
    } else {
      return appConfig.DESKTOP_SERVICE.TEST_SERVICE_URL;
    }
  } else {
    if (environment === constants.PRODUCTION.toLowerCase()) {
      return appConfig.TABLET_SERVICE.PROD_SERVICE_URL;
    } else if (environment === constants.DEVELOPMENT.toLowerCase()) {
      return appConfig.TABLET_SERVICE.DEV_SERVICE_URL;
    } else if(environment === constants.LOCAL.toLowerCase()){
      return appConfig.DESKTOP_SERVICE.LOCAL_SERVICE_URL;
    } else {
      return appConfig.TABLET_SERVICE.TEST_SERVICE_URL;
    }
  }
};

export const endPointGenerator = (state, path) => (path);
