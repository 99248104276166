import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {getAppRoute} from '../../utils/commonUtils';
import {fundTrackerPreferenceChange, saveAppPreferences} from "../../actions/preferences";
import {
  getFundTrackerNavLinks,
  isPrintingInProgressSelector,
  snackbarListSelector
} from '../../selectors/pages/fundTracker';
import {
  defaultFundTrackerShareClassIdsSelector,
  lastShareclassIdsSelector,
  topOpeningBalanceShareclassIdsSelector
} from "../../selectors/preferences";
import PrimaryFundSearch from './components/PrimaryFundSearch';
import MainHeader from './components/Headers/MainHeader';
import SubHeader from './components/Headers/SubHeader';
import ActionBar from './components/ActionBar';
import WithPageError from '../../containers/WithPageError';
import InjectRoutes from '../../routes';
import Constants from '../../constants/appConstants';
import ChartContextProvider from './context/provider/ChartContextProvider';
import {userGuidSelector} from "../../selectors/user";
import Snackbar from "../../components/core/Snackbar";
import {labelSelector, shareClassFromFundFinderOrPreferenceSelector} from "../../selectors/app";
import {isPrintingInProgress} from "../../actions/page/fundTracker";
import {addAppContext} from "../../actions/app";
import {MODULES_KEYS} from "../../constants/pageConstants";
import DataDownloadModal from "./components/DataDownloadModal";
import Footer from "./components/Footer";
import history from '../../utils/history';
import './index.scss';

const FundTracker = ({
  subRoutes = [], labels, snackbarList, fundTrackerNavLinks,
  userGuid, clearFundTrackerInitShareClassData, setIsPrintingInProgress,
  isPrintingInProgress, saveAppPreferences, fundTrackerPreferenceChange,
  topOpeningBalanceShareclassIds, lastShareclassIds, shareclassIdsFromPreference,
  shareClassFromFundFinderOrPreference
}) => {
  const [displayFooterDisclaimer, setDisplayFooterDisclaimer] = useState(false);
  const componentRef = React.useRef(null);

  const setIsPrintingInProgressFlag = (flag) => {
    setIsPrintingInProgress(flag);
    setDisplayFooterDisclaimer(flag);
  };

  const saveUserPreferredFunds = (ids, key) => {
    saveAppPreferences({
      preferenceRootKey: 'fundTracker',
      preferenceData: {
        [key]: ids
      }
    });
  };

  /*
    If user lands on Fund Tracker screen from "Fund Finder" or "Threshold Preference", then save the
    funds to preferences
  */
  useEffect(() => {
    const {primaryShareClassDetails} = shareClassFromFundFinderOrPreference || {};
    if (primaryShareClassDetails) {
      const {shareClassId} = primaryShareClassDetails;
      saveUserPreferredFunds([shareClassId], 'lastShareclassIds');
    }
  }, [shareClassFromFundFinderOrPreference]);

  const isPreferenceOnlyHasTopHoldingFunds = useMemo(() => {
    const {primaryShareClassDetails} = shareClassFromFundFinderOrPreference || {};
    const isShareClassFromFundFinderOrPreference = !!primaryShareClassDetails;
    return (!isShareClassFromFundFinderOrPreference &&
      !isEmpty(topOpeningBalanceShareclassIds) && isEmpty(lastShareclassIds));
  }, [shareClassFromFundFinderOrPreference, topOpeningBalanceShareclassIds, lastShareclassIds]);

  useEffect(() => {
    /* If user landing on Fund Tracker screen (not via "Fund Finder => TRACK Fund" or "Threshold Preferences" screen) with only Top Five Holding
       Funds from their preferences (which was grabbed from portfolio screen), default them to PERFORMANCE Tab */
    if (isPreferenceOnlyHasTopHoldingFunds) {
      history.push(getAppRoute(Constants.FUND_TRACKER_PERFORMANCE));
    }
  }, [isPreferenceOnlyHasTopHoldingFunds]);

  const tailEndFundIds = !isEmpty(topOpeningBalanceShareclassIds) ? topOpeningBalanceShareclassIds : [];

  return (
    <ChartContextProvider shareclassIdsFromPreference={shareclassIdsFromPreference}>
      <div className="fundTracker">
        <TransitionGroup component={null}>
          {snackbarList.map((data, idx) => (
            <CSSTransition key={idx} classNames='snackbar__transition' timeout={Constants.CSS_DURATION_HORSE}>
              <Snackbar labels={labels} {...data} />
            </CSSTransition>
          ))}
        </TransitionGroup>
        <PrimaryFundSearch
          userGuid={userGuid}
          saveUserPreferredFunds={saveUserPreferredFunds}
          fundTrackerPreferenceChange={fundTrackerPreferenceChange}
          tailEndFundIds={tailEndFundIds}
          clearFundTrackerInitShareClassData={clearFundTrackerInitShareClassData}
        />
        <div className="fundTracker__container">
          <div className="fundTracker__tabs">
            <ActionBar />
          </div>
          <div className="fundTracker__section" ref={componentRef}>
            <div className={cn({'show-footer': displayFooterDisclaimer}, {'hide-footer': !displayFooterDisclaimer})}>
              <div className='fundTracker__pdfHeader'>
                <MainHeader />
                <SubHeader
                  isShowBackLink={false}
                  saveUserPreferredFunds={saveUserPreferredFunds}
                />
              </div>
            </div>
            <WithPageError>
              <InjectRoutes
                routes={subRoutes}
                navLinks={fundTrackerNavLinks}
                redirectRoutes={[getAppRoute(Constants.FUND_TRACKER_ROUTE)]}
              />
            </WithPageError>
            <div className={cn({'show-footer': displayFooterDisclaimer}, {'hide-footer': !displayFooterDisclaimer})}>
              <Footer authorized={true} />
            </div>
          </div>
        </div>
        <DataDownloadModal
          id={MODULES_KEYS.FUND_TRACKER_COMPARE_DATA_GRID}
          isPrintingInProgress={isPrintingInProgress}
          setIsPrintingInProgress={setIsPrintingInProgressFlag}
          componentRef={componentRef}
        />
      </div>
    </ChartContextProvider>
  );
};

FundTracker.propTypes = {
  subRoutes: PropTypes.array,
  snackbarList: PropTypes.array,
  fundTrackerNavLinks: PropTypes.array,
  labels: PropTypes.object,
  userGuid: PropTypes.string,
  clearFundTrackerInitShareClassData: PropTypes.object,
  setIsPrintingInProgress: PropTypes.func,
  isPrintingInProgress: PropTypes.bool,
  saveAppPreferences: PropTypes.func,
  fundTrackerPreferenceChange: PropTypes.func,
  topOpeningBalanceShareclassIds: PropTypes.array,
  lastShareclassIds: PropTypes.array,
  shareclassIdsFromPreference: PropTypes.array,
  shareClassFromFundFinderOrPreference: PropTypes.object
};

const mapStateToProps = (state) => ({
  userGuid: userGuidSelector(state),
  snackbarList: snackbarListSelector(state),
  labels: labelSelector(state),
  fundTrackerNavLinks: getFundTrackerNavLinks(state),
  isPrintingInProgress: isPrintingInProgressSelector(state),
  shareclassIdsFromPreference: defaultFundTrackerShareClassIdsSelector(state),
  topOpeningBalanceShareclassIds: topOpeningBalanceShareclassIdsSelector(state),
  lastShareclassIds: lastShareclassIdsSelector(state),
  shareClassFromFundFinderOrPreference: shareClassFromFundFinderOrPreferenceSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  clearFundTrackerInitShareClassData: (data) => dispatch(addAppContext(data)),
  setIsPrintingInProgress: (flag) => dispatch(isPrintingInProgress(flag)),
  saveAppPreferences: (data) => dispatch(saveAppPreferences(data)),
  fundTrackerPreferenceChange: (data) => dispatch(fundTrackerPreferenceChange(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FundTracker));
