import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { TypographyVariant } from '@gs-ux-uitoolkit-common/design-system';
import { FormSize } from '@gs-ux-uitoolkit-common/form';
import { getFormTheme } from './form-theme';
import { DeepReadonly } from 'ts-essentials';
import './input-group-content-theme-overrides';

export interface InputGroupContentStyleSheetProps {
    theme: Theme;
    size: FormSize;
}

export interface InputGroupContentCssClasses {
    root: string;
}

export type InputGroupContentStyledClasses = CssClassDefinitionsObject<
    keyof InputGroupContentCssClasses
>;

export interface InputGroupContentStyleOverridesParams {
    props: DeepReadonly<InputGroupContentStyleSheetProps>;
    createDefaultStyledClasses: () => InputGroupContentStyledClasses;
}

export const inputGroupContentStyleSheet = new StyleSheet(
    'input-group-content',
    (props: InputGroupContentStyleSheetProps) => {
        return createComponentClassDefinitions<
            InputGroupContentStyleSheetProps,
            InputGroupContentStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.inputGroupContent);
    }
);

export const globalInputGroupContentClass = 'gs-input-group-content';

export function getInputGroupContentStyles({ theme }: { theme: Theme }) {
    const formTheme = getFormTheme(theme);

    return {
        alignItems: 'center',
        backgroundColor: theme.surface.secondary,
        border: `1px solid ${formTheme.input.borderColor}`,
        color: theme.text.secondary,
        borderRadius: 0,
        display: 'inline-flex',
        lineHeight: 'normal',
        margin: '-1px',
        padding: '0 8px',
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
    };
}

export function getDefaultInputGroupContentTypographyTheme(theme: Theme) {
    return {
        sm: theme.typography.body03 as TypographyVariant,
        md: theme.typography.body02 as TypographyVariant,
        lg: theme.typography.body01 as TypographyVariant,
    };
}

// Explicit heights must be applied because not all the children of InputGroup that need an exact
// height are direct children of InputGroup:
const heights = {
    sm: '24px',
    md: '32px',
    lg: '40px',
};

function createDefaultStyledClasses({
    theme,
    size = 'md',
}: InputGroupContentStyleSheetProps): InputGroupContentStyledClasses {
    return {
        root: {
            height: heights[size],
            ...getDefaultInputGroupContentTypographyTheme(theme)[size],
            ...getInputGroupContentStyles({ theme }),
        },
    } as { root: any };
}
