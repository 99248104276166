/**
 * Sanitizes a string that may contain HTML or code so that it becomes unexecutable.
 */
export function sanitizeHtml(str: string): string {
    const p = document.createElement('p');
    p.appendChild(document.createTextNode(str));
    return p.innerHTML;
}

/**
 * Reverses sanitization of a string so that it is readable.
 */
export function unsanitizeHtml(str: string): string {
    str = str.replace(/&lt;/g, '<');
    str = str.replace(/&gt;/g, '>');
    str = str.replace(/&quot;/g, '"');
    str = str.replace(/&#39;/g, "'");
    str = str.replace(/&amp;/g, '&');
    return str;
}
