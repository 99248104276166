import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {ThreeSquaresLoader} from '../../components/core/Loaders';
import {Conditional} from '../../components/core/Conditional';
import {isMosaic} from '../../utils/commonUtils';
import translator from '../../services/translator';
import './index.scss';

const {translate: t} = translator;

const LoginViewTemplate = ({
   children, isLoginInProgress, isAppLoading, isTmxProfilingComplete, pageId
}) => {
  return (
    <div className={cn('viewport-container__login-view', `${pageId}`)}>
      <Conditional condition={isAppLoading || isLoginInProgress || !isTmxProfilingComplete}>
        <ThreeSquaresLoader />
      </Conditional>
      <div className="viewport-container__header-container">
        <div className="viewport-container__header">
          <span className="viewport-container__header-logo" />
          <Conditional condition={!isMosaic()}>
            <div className="viewport-container__product-name-container">
              <div className="viewport-container__header-separator"/>
              <div className="viewport-container__header-product-name">{t('tkLiquidityPortal')}</div>
            </div>
          </Conditional>
        </div>
      </div>
      <div className='viewport-container__contents'>
        {children}
      </div>
    </div>
  );
};

LoginViewTemplate.propTypes = {
  children: PropTypes.node,
  isLoginInProgress: PropTypes.bool,
  isAppLoading: PropTypes.bool,
  isTmxProfilingComplete: PropTypes.bool,
  pageId: PropTypes.string
};

export default LoginViewTemplate;

