import { useStyleSheet, cx } from '@gs-ux-uitoolkit-react/style';
import { NoStrictMode } from '@gs-ux-uitoolkit-react/shared';

import {
    PropsWithChildren,
    FunctionComponent,
    useContext,
    useRef,
    useEffect,
    HTMLAttributes,
    ComponentClass,
    useState,
    createElement,
    MutableRefObject,
} from 'react';

import { droppableStyleSheet } from './droppable-style-sheet';
import { ExternalDroppable } from './drag-and-drop-external';
import { DragAndDropInternalContext } from './drag-and-drop-internal-context';
import { v1 as uuid } from 'uuid';
import { dragAndDropContextCommonPropsDefaults } from '@gs-ux-uitoolkit-common/drag-and-drop';
import { DragAndDropPlaceholder, DragAndDropPlaceholderProps } from './drag-and-drop-placeholder';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';

export interface DroppableChildlessProps
    extends Omit<HTMLAttributes<HTMLElement>, 'placeholderOverride'> {
    droppableId?: string;
    placeholderOverride?:
        | FunctionComponent<DragAndDropPlaceholderProps>
        | ComponentClass<DragAndDropPlaceholderProps, any>;
    elementTypeOverride?: string;
    forcePlaceholder?: boolean;
    classes?: DroppableOverrideClasses;
}

export type DroppableProps = PropsWithChildren<DroppableChildlessProps>;
export type DroppableOverrideClasses = Partial<
    Record<'list' | 'placeholder' | 'placeholderInner', string>
>;
export const Droppable: FunctionComponent<DroppableProps> = ({
    children,
    droppableId,
    placeholderOverride: placeholder,
    elementTypeOverride: overrideElementType,
    classes: overrideClasses,
    forcePlaceholder,
    ...attributes
}) => {
    const [stableUuid] = useState(uuid());
    const normalizedDroppableId = droppableId || stableUuid;
    const context = useContext(DragAndDropInternalContext);
    const ref = useRef<null | HTMLDivElement>(null);
    const theme = useTheme();

    useEffect(() => {
        context?.registerRef(ref, normalizedDroppableId);

        return () => {
            context?.unregisterRef(normalizedDroppableId);
        };
    }, [ref, normalizedDroppableId, context]);

    const showPlaceholder =
        (normalizedDroppableId === context?.placeholderState.droppableId &&
            context?.placeholderState.isPlaceholderVisible) ||
        !!forcePlaceholder;

    const droppableClasses = useStyleSheet(droppableStyleSheet, {
        theme,
        spacing: context?.spacing ?? dragAndDropContextCommonPropsDefaults.spacing,
        direction: context?.direction ?? dragAndDropContextCommonPropsDefaults.direction,
    });

    const listClasses = cx(droppableClasses.list, overrideClasses?.list);

    if (!context) {
        console.warn(
            'No DragAndDropContext detected! The drag and drop functionality will not work unless used within DragAndDropContext'
        );
        return createElement(
            overrideElementType ? overrideElementType : 'div',
            {
                'data-gs-uitk-component': 'droppable',
                'data-droppable-id': normalizedDroppableId,
                'data-droppable-no-context': true,
                className: listClasses,
                ...attributes,
            },
            <>{children}</>
        );
    }

    return (
        <NoStrictMode>
            <ExternalDroppable droppableId={normalizedDroppableId} direction={context.direction}>
                {provided => {
                    return (
                        <>
                            {createElement(
                                overrideElementType ? overrideElementType : 'div',
                                {
                                    'data-gs-uitk-component': 'droppable',
                                    'data-droppable-id': normalizedDroppableId,
                                    className: listClasses,
                                    ref: mergeRefs(provided.innerRef, ref),
                                    ...attributes,
                                },
                                <>
                                    {children}
                                    {provided.placeholder}
                                    {showPlaceholder &&
                                        (placeholder ? (
                                            createElement(placeholder, {
                                                ...context.placeholderState,
                                                spacing: context.spacing,
                                                classes: {
                                                    placeholder: overrideClasses?.placeholder,
                                                    placeholderInner:
                                                        overrideClasses?.placeholderInner,
                                                },
                                            })
                                        ) : (
                                            <DragAndDropPlaceholder
                                                {...context.placeholderState}
                                                spacing={context.spacing}
                                                classes={{
                                                    placeholder: overrideClasses?.placeholder,
                                                    placeholderInner:
                                                        overrideClasses?.placeholderInner,
                                                }}
                                            />
                                        ))}
                                </>
                            )}
                        </>
                    );
                }}
            </ExternalDroppable>
        </NoStrictMode>
    );
};

const mergeRefs = (
    ...refs: (MutableRefObject<HTMLElement | null> | ((element: HTMLElement | null) => any))[]
) => {
    return (node: HTMLElement | null) => {
        for (const ref of refs) {
            if (typeof ref === 'function') {
                ref(node);
            } else if (ref != null) {
                ref.current = node;
            }
        }
    };
};
