import { FunctionComponent } from 'react';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { useStyleSheet, cx } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { dragAndDropHandleStyles } from './drag-and-drop-handle-style-sheet';

import {
    dragAndDropHandleDefaultProps,
    DragAndDropHandleProps,
} from './drag-and-drop-handle-style-sheet';

export const DragAndDropHandle: FunctionComponent<DragAndDropHandleProps> = ({
    draggableDragHandleProps,
    classes,
    iconPlacement,
    iconBaseProps = dragAndDropHandleDefaultProps.iconBaseProps,
}) => {
    const theme = useTheme();
    const cssClasses = useStyleSheet(dragAndDropHandleStyles, { theme, iconPlacement });
    const dragHandleClass = cx(cssClasses.dragHandle, classes?.dragHandle);
    const dragHandleIconClass = cx(cssClasses.dragHandleIcon, classes?.dragHandleIcon);
    return (
        <div
            {...draggableDragHandleProps}
            className={`${dragHandleClass} gs-uitk-component-draggable-handle`}
        >
            <Icon {...iconBaseProps} className={dragHandleIconClass} />
        </div>
    );
};
