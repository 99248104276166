import {createSelector} from 'reselect/lib';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import {userSelector, errorSelector} from '../user';
import {labelSelector, isMimicSessionSelector, isLogoutInProgressSelector} from '../app';
export const isTncServiceCallInProgressSelector = state => {
  if (isBoolean(state.pageData.isTncServiceCallInProgress)) {
    return state.pageData.isTncServiceCallInProgress;
  }
  return false;
};
export const isTncCtaButtonsDisabled = state => isBoolean(state.pageData.isTncCtaButtonsDisabled) ? state.pageData.isTncCtaButtonsDisabled : true;
export const isTncCtaButtonsDisabledSelector = createSelector(
  isTncCtaButtonsDisabled,
  isTncServiceCallInProgressSelector,
  (isTncCtaButtonsDisabled, isTncServiceCallInProgress) => {
    return (isTncCtaButtonsDisabled || isTncServiceCallInProgress);
  }
);
export const submitTncErrorSelector = state => state.appContext ? state.appContext.submitTncError : null;
export const isShowTermsAndConditionsSelector = createSelector(
  labelSelector,
  userSelector,
  errorSelector,
  isMimicSessionSelector,
  isLogoutInProgressSelector,
  (labels, user, userError, isMimicSession, isLogoutInProgress) => {
    if (isEmpty(labels) || (isMimicSession || isLogoutInProgress)) {
      return false;
    } else if (!isEmpty(user) && !userError) {
      if (user.termsAndCondition) {
        const hasNoTNCFlag = !user.termsAndCondition;
        const hasNotAccepted = user.termsAndCondition && !user.termsAndCondition.hasAccepted;
        const {userRecentlyAcceptedOrDeclined} = user.termsAndCondition;
        const isUserDeclinedTNC = (userRecentlyAcceptedOrDeclined !== undefined && userRecentlyAcceptedOrDeclined);
        return (hasNoTNCFlag || hasNotAccepted) && !isUserDeclinedTNC;
      } else {
        return true;
      }
    }
    return false;
  }
);
