import { StyleSheet, CssClasses } from '@gs-ux-uitoolkit-react/style';
import { Theme } from '@gs-ux-uitoolkit-react/theme';

export const createdSavedViewsMenuClassNamePrefix = 'gs-uitk-create-saved-views-menu';

export type CreatedSavedViewsMenuCssClasses = CssClasses<typeof createdSavedViewsMenuStyleSheet>;

export const createdSavedViewsMenuStyleSheet = new StyleSheet(
    'created-saved-views-menu',
    ({ theme }: { theme: Theme }) => {
        return {
            root: {
                padding: '12px 16px',
                width: '224px',
                boxSizing: 'border-box',
                color: theme.text.primary,

                [`.${createdSavedViewsMenuClassNamePrefix}__header`]: {
                    marginTop: 0,
                },
                [`.${createdSavedViewsMenuClassNamePrefix}__collapse label`]: {
                    marginTop: '8px',
                },
                [`.${createdSavedViewsMenuClassNamePrefix}__save-button`]: {
                    width: '100%',
                    marginTop: '16px',
                },
                [`.${createdSavedViewsMenuClassNamePrefix}__menu-advanced-container`]: {
                    marginTop: '16px',
                },
            },
        };
    }
);
