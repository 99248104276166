const fixed = 1030;
const overlay = 100;

export const zIndex = {
    dropdown: 1000,
    sticky: 1020,
    fixed,
    modalBackdrop: 1040,
    bottomSheetBackdrop: 1040,
    modal: 1050,
    bottomSheet: 1050,
    popover: 1060,
    tooltip: 1070,
    overlay,
    overlayFixed: fixed + overlay,
    header: fixed,
    menu: 9999,
};
