import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { getOverflowStyles } from '@gs-ux-uitoolkit-common/shared';
import {
    Theme,
    ThemeTypographyVariant,
    createComponentClassDefinitions,
} from '@gs-ux-uitoolkit-common/theme';
import { HeadingCommonProps, defaultHeadingProps } from './heading-props';
import { getTextColor, getBrowserResetStyles, getFontStyles } from './typography-common-util';
import { DeepReadonly } from 'ts-essentials';
import './heading-theme-overrides';

export type HeadingStyleSheetProps = Pick<
    HeadingCommonProps,
    | 'typography'
    | 'color'
    | 'fontFamily'
    | 'fontSize'
    | 'fontWeight'
    | 'lineHeight'
    | 'display'
    | 'bold'
    | 'italic'
    | 'underline'
    | 'disabled'
> & { theme: Theme };

export interface HeadingCssClasses {
    root: string;
}

export type HeadingStyledClasses = CssClassDefinitionsObject<keyof HeadingCssClasses>;

export interface HeadingStyleOverridesParams {
    props: DeepReadonly<HeadingStyleSheetProps>;
    createDefaultStyledClasses: () => HeadingStyledClasses;
}

export const headingStyleSheet = new StyleSheet('heading', (props: HeadingStyleSheetProps) => {
    return createComponentClassDefinitions<HeadingStyleSheetProps, HeadingStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.heading
    );
});

function createDefaultStyledClasses({
    theme,
    typography = defaultHeadingProps.typography,
    color,
    fontFamily,
    fontSize,
    fontWeight,
    lineHeight,
    display = defaultHeadingProps.display,
    bold,
    italic,
    underline,
    disabled,
}: HeadingStyleSheetProps): HeadingStyledClasses {
    const fontStyles = getFontStyles({
        theme,
        typography: typography as ThemeTypographyVariant,
        bold,
        italic,
    });
    return {
        root: {
            ...getBrowserResetStyles(),
            display,
            ...getOverflowStyles('wrap'),
            ...fontStyles,
            textDecoration: underline ? 'underline' : undefined,
            color: color ? getTextColor({ theme, color }) : 'inherit',
            opacity: disabled ? theme.state.disabledOpacity : 1,
            fontWeight: bold ? 'bold' : fontWeight || fontStyles.fontWeight,
            fontStyle: italic ? 'italic' : 'inherit',
            ...(fontFamily && { fontFamily }),
            ...(fontSize && { fontSize }),
            ...(lineHeight && { lineHeight }),
        },
    };
}
