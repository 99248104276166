import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Conditional } from "../../../../../core/Conditional";
import AccountNumberToggler from "../AccountNumberToggler";
import translator from "../../../../../../services/translator";
import ItemEdit from "../ItemEdit";
import {
  ACCOUNT_TYPE,
  ACCOUNT_TYPE_MAPPER,
  INVEST_ONLY,
  INVEST_ONLY_MAPPER,
  NONE,
  TRUIST_DOMAIN,
} from "../../constants";
import { getDomainMapper } from "../../../../../../utils/commonUtils";
import "./index.scss";

const { translate: t } = translator;

const Item = ({
  item,
  debitCreditInfo,
  isEdit,
  handleEditFields,
  errorState
}) => {
  const {
    field,
    headerName,
    category,
    toggleInfo,
    fieldType,
    charsLengthRange,
    maxLength
  } = item;

  const getFieldContent = () => {
    if (field === INVEST_ONLY) {
      return debitCreditInfo[field] || "N";
    }

    if (field === ACCOUNT_TYPE) {
      return debitCreditInfo[field] || NONE;
    }

    return debitCreditInfo[field] || "--";
  };

  const fieldContent = getFieldContent();

  const [isTruist, setIsTruist] = useState(false);

  useEffect(() => {
    const domain = getDomainMapper();
    if (domain === TRUIST_DOMAIN) {
      setIsTruist(true);
    }
  }, []);

  if (toggleInfo && !isEdit) {
    return (
      <AccountNumberToggler
        title={t(headerName)}
        accountNumber={fieldContent}
      />
    );
  }

  const renderFieldContent = () => {
    if (field === INVEST_ONLY) {
      return isEdit ? debitCreditInfo[field] : INVEST_ONLY_MAPPER[fieldContent];
    }

    if (field === ACCOUNT_TYPE) {
      return isEdit
        ? (debitCreditInfo[field] || NONE)
        : ACCOUNT_TYPE_MAPPER[fieldContent];
    }
    return isEdit ? debitCreditInfo[field] : fieldContent;
  };

  const renderReadEdit = () => {
    if (isEdit) {
      return (
        <ItemEdit
          value={renderFieldContent()}
          handleEditFields={handleEditFields}
          field={field}
          fieldType={fieldType}
          isTruist={isTruist}
          errorState={errorState}
          charsLengthRange={charsLengthRange}
          maxLength={maxLength}
        />
      );
    }
    return <span className="factData-item">{renderFieldContent()}</span>;
  };

  return (
    <Conditional condition={category === "tkDebitCreditInstructions"}>
      <div data-testid="item-container" className="factData">
        <span className={classNames("fundFactView__label", {"edit-label": isEdit})}>
          {headerName && t(headerName)}
        </span>
        {renderReadEdit()}
      </div>
    </Conditional>
  );
};

Item.propTypes = {
  item: PropTypes.object,
  debitCreditInfo: PropTypes.object,
  isEdit: PropTypes.bool,
  handleEditFields: PropTypes.func,
  errorState: PropTypes.object,
};

export default Item;
