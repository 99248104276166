/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
// TODO: https://jira.site.gs.com/browse/UX-14773

import { Component, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    ButtonGroup,
    ButtonSelect,
    ButtonSelectChangeEvent,
} from '@gs-ux-uitoolkit-react/button';
import { Form, FormGroup } from '@gs-ux-uitoolkit-react/form';
import { Checkbox, CheckboxChangeEvent } from '@gs-ux-uitoolkit-react/checkbox';
import { Label } from '@gs-ux-uitoolkit-react/label';
import { Radio, RadioChangeEvent } from '@gs-ux-uitoolkit-react/radio';
import { TooltipTarget } from '@gs-ux-uitoolkit-react/tooltip';
import {
    SelectMultiple,
    SelectMultipleChangeEvent,
    SelectMultipleProps,
} from '@gs-ux-uitoolkit-react/select-multiple';
import { GridColumn } from '../../../grid-wrappers/grid-wrapper';
import {
    CreateExport,
    ExportsActionTypes,
    SetExportOptions,
} from '../../../redux/actions/exports-action';
import { DataGridState } from '../../../redux/datagrid-state';
import { ExportColumnOptionType, ExportOptions, ExportRowOptionType, ExportType } from '../export';
import { exportPluginStyleSheet } from '../../../style/plugins/exports-plugin-stylesheet';
import { Input } from '@gs-ux-uitoolkit-react/input';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';

/**
 * The props for the ExportModalComponent
 */
export interface ExportModalProps {
    exportOptions: ExportOptions;
    columnList: GridColumn[];
    createExport: () => void;
    setExportOptions: (options: ExportOptions) => void;
}

export const FormItemName = {
    columnGroups: 'column-groups',
    exportColumns: 'export-columns',
    exportRows: 'export-rows',
    exportType: 'export-type',
    includeFormatting: 'include-formatting',
    includeStyles: 'include-styles',
    indentPivotColumn: 'format-pivot-column',
    onlySelected: 'only-selected',
    onlySelectedPages: 'only-selected-pages',
    skipGroups: 'skip-groups',
    skipHeader: 'skip-header',
    fileName: 'file-name',
};

export const InfoName = {
    columnGroupsInfo: 'columnGroupsInfo',
    displayedColumnInfo: 'displayedColumnInfo',
    displayedRowInfo: 'displayedRowInfo',
    onlySelectedInfo: 'onlySelectedInfo',
    onlySelectedInfoPages: 'onlySelectedInfoPages',
};
/**
 * * The main Export modal
 */
export class ExportModalComponent extends Component<ExportModalProps> {
    constructor(props: ExportModalProps) {
        super(props);
    }

    public componentWillUnmount() {
        exportPluginStyleSheet.unmount(this);
    }
    public render() {
        const selectedList = this.props.columnList
            .filter(col => this.props.exportOptions.selectedColumns.find(id => id === col.columnId))
            .map(gc => gc.columnId);

        const options = this.props.columnList.map(gc => {
            return {
                label: gc.columnLabel,
                value: gc.columnId,
            };
        });

        const selectMultipleConfig: SelectMultipleProps = {
            options,
            onChange: evt => this.onColumnSelected(evt),
            placeholder: 'select columns',
            selectedValues: selectedList,
        };
        const onChange = (
            event:
                | CheckboxChangeEvent
                | RadioChangeEvent
                | ButtonSelectChangeEvent
                | ChangeEvent<HTMLInputElement>
        ) => this.onChange(event);

        return (
            <ThemeConsumer>
                {theme => {
                    const cssClasses = exportPluginStyleSheet.mount(this, { theme });
                    return (
                        <div className={`gs-uitk-export-plugin-modal ${cssClasses.root}`}>
                            <div className="gs-uitk-export-plugin-modal-container">
                                <Form preventSubmit={true}>
                                    <Label className="gs-uitk-export-modal-intro">
                                        Edit the default export settings below.
                                    </Label>
                                    <FormGroup>
                                        <Label>File Name</Label>
                                        <Input
                                            data-cy="gs-uitk-export-plugin-modal-filename-input"
                                            className="gs-uitk-export-plugin-modal-filename-input"
                                            value={this.props.exportOptions.fileName}
                                            name={FormItemName.fileName}
                                            onChange={onChange}
                                        ></Input>
                                    </FormGroup>
                                    <Label>EXPORT TYPE</Label>
                                    <FormGroup>
                                        <ButtonGroup>
                                            <ButtonSelect
                                                type="single"
                                                value={ExportType.Excel}
                                                data-name={FormItemName.exportType}
                                                name={FormItemName.exportType}
                                                selected={
                                                    this.props.exportOptions.type ===
                                                    ExportType.Excel
                                                }
                                                onChange={onChange}
                                            >
                                                XSLX
                                            </ButtonSelect>
                                            <ButtonSelect
                                                data-name={FormItemName.exportType}
                                                name={FormItemName.exportType}
                                                type="single"
                                                value={ExportType.Csv}
                                                selected={
                                                    this.props.exportOptions.type === ExportType.Csv
                                                }
                                                onChange={onChange}
                                            >
                                                CSV
                                            </ButtonSelect>
                                        </ButtonGroup>
                                    </FormGroup>
                                    <div className="uitk-export-options-columns-container">
                                        <div className="uitk-export-options-column">
                                            <FormGroup>
                                                <Label>COLUMNS TO EXPORT</Label>
                                                <Radio
                                                    data-name={FormItemName.exportColumns}
                                                    name={FormItemName.exportColumns}
                                                    value={ExportColumnOptionType.AllVisibleColumns}
                                                    checked={
                                                        this.props.exportOptions.columnsToExport ===
                                                        ExportColumnOptionType.AllVisibleColumns
                                                    }
                                                    onChange={onChange}
                                                >
                                                    All Visible Columns
                                                </Radio>
                                                <Radio
                                                    data-name={FormItemName.exportColumns}
                                                    name={FormItemName.exportColumns}
                                                    value={ExportColumnOptionType.AllColumns}
                                                    checked={
                                                        this.props.exportOptions.columnsToExport ===
                                                        ExportColumnOptionType.AllColumns
                                                    }
                                                    onChange={onChange}
                                                >
                                                    All Columns
                                                </Radio>
                                                <span className="info-span-wrapper">
                                                    <Radio
                                                        data-name={FormItemName.exportColumns}
                                                        name={FormItemName.exportColumns}
                                                        value={
                                                            ExportColumnOptionType.DisplayedColumns
                                                        }
                                                        checked={
                                                            this.props.exportOptions
                                                                .columnsToExport ===
                                                            ExportColumnOptionType.DisplayedColumns
                                                        }
                                                        onChange={onChange}
                                                    >
                                                        All Displayed Columns
                                                    </Radio>

                                                    {this.getInfoIcon(InfoName.displayedColumnInfo)}
                                                </span>
                                                <Radio
                                                    data-name={FormItemName.exportColumns}
                                                    name={FormItemName.exportColumns}
                                                    value={ExportColumnOptionType.SelectedColumns}
                                                    checked={
                                                        this.props.exportOptions.columnsToExport ===
                                                        ExportColumnOptionType.SelectedColumns
                                                    }
                                                    onChange={onChange}
                                                >
                                                    Select Columns
                                                </Radio>
                                                {this.props.exportOptions.columnsToExport ===
                                                    ExportColumnOptionType.SelectedColumns && (
                                                    <SelectMultiple
                                                        {...selectMultipleConfig}
                                                        data-cy="gs-uitk-datagrid-exports-plugin-selector"
                                                    />
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className="uitk-export-options-column">
                                            <FormGroup>
                                                <Label>ROWS TO EXPORT</Label>
                                                <Radio
                                                    data-name={FormItemName.exportRows}
                                                    name={FormItemName.exportRows}
                                                    value={ExportRowOptionType.AllRows}
                                                    checked={
                                                        this.props.exportOptions.rowsToExport ===
                                                        ExportRowOptionType.AllRows
                                                    }
                                                    onChange={onChange}
                                                >
                                                    All Rows
                                                </Radio>
                                                <span className="info-span-wrapper">
                                                    <Radio
                                                        data-name={FormItemName.exportRows}
                                                        name={FormItemName.exportRows}
                                                        value={ExportRowOptionType.DisplayedRows}
                                                        checked={
                                                            this.props.exportOptions
                                                                .rowsToExport ===
                                                            ExportRowOptionType.DisplayedRows
                                                        }
                                                        onChange={onChange}
                                                    >
                                                        All Displayed Rows
                                                    </Radio>
                                                    {this.getInfoIcon(InfoName.displayedRowInfo)}
                                                </span>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <FormGroup>
                                        <Label>ADDITIONAL OPTIONS</Label>
                                        <span className="info-span-wrapper">
                                            <Checkbox
                                                data-name={FormItemName.columnGroups}
                                                name={FormItemName.columnGroups}
                                                checked={this.props.exportOptions.columnGroups}
                                                onChange={onChange}
                                            >
                                                Column Groups
                                            </Checkbox>
                                            {this.getInfoIcon(InfoName.columnGroupsInfo)}
                                        </span>
                                        <Checkbox
                                            data-name={FormItemName.includeFormatting}
                                            name={FormItemName.includeFormatting}
                                            checked={this.props.exportOptions.includeFormatting}
                                            onChange={onChange}
                                        >
                                            Include Formatting
                                        </Checkbox>
                                        <Checkbox
                                            data-name={FormItemName.includeStyles}
                                            name={FormItemName.includeStyles}
                                            checked={this.props.exportOptions.includeStyles}
                                            onChange={onChange}
                                        >
                                            Include Styles
                                        </Checkbox>
                                        <Checkbox
                                            data-name={FormItemName.indentPivotColumn}
                                            name={FormItemName.indentPivotColumn}
                                            checked={this.props.exportOptions.indentPivotColumn}
                                            onChange={onChange}
                                        >
                                            Indent Pivot Column
                                        </Checkbox>
                                        <span className="info-span-wrapper">
                                            <Checkbox
                                                data-name={FormItemName.onlySelected}
                                                name={FormItemName.onlySelected}
                                                checked={this.props.exportOptions.onlySelected}
                                                onChange={onChange}
                                            >
                                                Only Selected
                                            </Checkbox>
                                            {this.getInfoIcon(InfoName.onlySelectedInfo)}
                                        </span>
                                        <span className="info-span-wrapper">
                                            <Checkbox
                                                data-name={FormItemName.onlySelectedPages}
                                                name={FormItemName.onlySelectedPages}
                                                checked={this.props.exportOptions.onlySelectedPages}
                                                onChange={onChange}
                                            >
                                                Only Selected Pages
                                            </Checkbox>
                                            {this.getInfoIcon(InfoName.onlySelectedInfoPages)}
                                        </span>
                                        <Checkbox
                                            data-name={FormItemName.skipHeader}
                                            name={FormItemName.skipHeader}
                                            checked={this.props.exportOptions.skipColumnHeaders}
                                            onChange={onChange}
                                        >
                                            Skip Header
                                        </Checkbox>
                                        <Checkbox
                                            data-name={FormItemName.skipGroups}
                                            name={FormItemName.skipGroups}
                                            checked={this.props.exportOptions.skipGroups}
                                            onChange={onChange}
                                        >
                                            Skip Row Groups
                                        </Checkbox>
                                    </FormGroup>
                                </Form>
                            </div>
                            <div className="gs-uitk-export-plugin-modal-button-container">
                                <Button actionType="primary" onClick={this.props.createExport}>
                                    Export
                                </Button>
                            </div>
                        </div>
                    );
                }}
            </ThemeConsumer>
        );
    }

    private getInfoIcon(dataRef: string): JSX.Element {
        return (
            <TooltipTarget
                className="gs-uitk-info"
                id={dataRef}
                label={this.getTooltipLabel(dataRef)}
            >
                <Icon name="info" type="outlined" />
            </TooltipTarget>
        );
    }

    private getTooltipLabel = (dataRef: string) => {
        let label = '';
        switch (dataRef) {
            case InfoName.columnGroupsInfo:
                label = 'Export header column groupings.';
                break;
            case InfoName.displayedColumnInfo:
                label = `Only the rendered columns will be exported.
                Columns not within the viewport that are not
                rendered, due to column virtualisation, are not
                displayed.`;
                break;
            case InfoName.displayedRowInfo:
                label = `Export rendered rows. Due to virtualisation this
                will contain only the current visible rows and
                the amount in the buffer.`;
                break;
            case InfoName.onlySelectedInfo:
                label = `Only export the selected rows.`;
                break;
            case InfoName.onlySelectedInfoPages:
                label = `Only export selected rows including other pages (only
                    applicable when using pagination)`;
                break;
            default:
                break;
        }

        return label;
    };
    private onColumnSelected(evt: SelectMultipleChangeEvent) {
        const options: ExportOptions = { ...this.props.exportOptions };
        options.selectedColumns = evt.selectedValues;
        this.props.setExportOptions(options);
    }

    private onChange(
        event:
            | RadioChangeEvent
            | CheckboxChangeEvent
            | ButtonSelectChangeEvent
            | ChangeEvent<HTMLInputElement>
    ) {
        const options: ExportOptions = { ...this.props.exportOptions };

        switch (event.currentTarget.name) {
            case FormItemName.fileName:
                options.fileName = (event as ChangeEvent<HTMLInputElement>).currentTarget.value;
                break;
            case FormItemName.exportType:
                options.type = (event as ButtonSelectChangeEvent).value as ExportType;
                break;
            case FormItemName.onlySelected:
                options.onlySelected = !options.onlySelected;
                break;
            case FormItemName.onlySelectedPages:
                options.onlySelectedPages = !options.onlySelectedPages;
                break;
            case FormItemName.columnGroups:
                options.columnGroups = !options.columnGroups;
                break;
            case FormItemName.includeFormatting:
                options.includeFormatting = !options.includeFormatting;
                break;
            case FormItemName.includeStyles:
                options.includeStyles = !options.includeStyles;
                break;
            case FormItemName.skipHeader:
                options.skipColumnHeaders = !options.skipColumnHeaders;
                break;
            case FormItemName.skipGroups:
                options.skipGroups = !options.skipGroups;
                break;
            case FormItemName.exportColumns:
                options.columnsToExport = event.currentTarget.value as ExportColumnOptionType;
                break;
            case FormItemName.exportRows:
                options.rowsToExport = event.currentTarget.value as ExportRowOptionType;
                break;
            case FormItemName.indentPivotColumn:
                options.indentPivotColumn = !options.indentPivotColumn;
                break;
        }

        this.props.setExportOptions(options);
    }
}

const mapStateToProps = (state: DataGridState) => {
    return {
        columnList: state.grid.columnList,
        exportOptions: state.export.options,
    };
};

const mapDispatchToProps = (dispatch: (action: ExportsActionTypes) => void) => {
    return {
        createExport: () => dispatch(CreateExport()),
        setExportOptions: (options: ExportOptions) => dispatch(SetExportOptions(options)),
    };
};

export const ExportModal = connect(mapStateToProps, mapDispatchToProps)(ExportModalComponent);
