// Use this declaration to check documentMode and StyleMedia existence
declare global {
    interface Document {
        documentMode?: any;
    }
    interface Window {
        StyleMedia?: any;
        HTMLElement?: any;
    }
}
declare const InstallTrigger: any;
declare const safari: any;
declare const jasmine: any;
declare const jest: any;
declare const mocha: any;

/**
 * Determines when we're in a browser environment (i.e. the `window` object is
 * available). This is as opposed to a server-side rendering (SSR) environment
 * where the `window` object doesn't exist.
 */
export const isBrowser = typeof window !== 'undefined';

/**
 * Determines whether or not the current browser is IE.
 */
export const isIE: boolean = typeof window === 'undefined' ? false : !!window.document.documentMode;

/**
 * Determines whether or not the current browser is IE.
 */
export const isEdge: boolean = typeof window === 'undefined' ? false : !isIE && !!window.StyleMedia;

/**
 * Determines whether or not the current browser is Firefox.
 */
export const isFirefox: boolean = typeof InstallTrigger !== 'undefined';

/**
 * Determines whether or not the current browser is Firefox.
 */
export const isSafari: boolean =
    typeof window === 'undefined'
        ? false
        : /constructor/i.test(window.HTMLElement) ||
          (function (p) {
              return p.toString() === '[object SafariRemoteNotification]';
          })(
              !(window as any)['safari'] ||
                  (typeof safari !== 'undefined' && (window as any)['safari'].pushNotification)
          );

/**
 * Determines whether or not the current environment is a test environment (jest, jasmine, or mocha)
 */
export const isTestEnv = (): boolean => {
    return (
        typeof jasmine !== 'undefined' ||
        typeof jest !== 'undefined' ||
        typeof mocha !== 'undefined'
    );
};

/**
 * Determines whether the current browser is present on a touch device or not
 */
export const isTouchDevice = (): boolean =>
    typeof window !== 'undefined' && // for SSR environments
    ('ontouchstart' in window ||
        ((window as any).DocumentTouch && document instanceof (window as any).DocumentTouch) ||
        navigator.maxTouchPoints > 0);
