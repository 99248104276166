import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { InputSize } from './types';
import { DeepReadonly } from 'ts-essentials';
import './input-number-theme-overrides';

export interface InputNumberStyleSheetProps {
    theme: Theme;
    size: InputSize;
}

export interface InputNumberCssClasses {
    ui: string;
    buttonUp: string;
    buttonDown: string;
}

export type InputNumberStyledClasses = CssClassDefinitionsObject<keyof InputNumberCssClasses>;

export interface InputNumberStyleOverridesParams {
    props: DeepReadonly<InputNumberStyleSheetProps>;
    createDefaultStyledClasses: () => InputNumberStyledClasses;
}

export const inputNumberStyleSheet = new StyleSheet(
    'input-number',
    (props: InputNumberStyleSheetProps) => {
        return createComponentClassDefinitions<
            InputNumberStyleSheetProps,
            InputNumberStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.inputNumber);
    }
);

export const globalInputNumberClass = 'gs-input-number';

const buttonSizeTheme = {
    sm: '16px',
    md: '22px',
    lg: '24px',
};

const iconFontSizeTheme = {
    sm: '14px',
    md: '14px',
    lg: '18px',
};

function getButtonStyles({ theme, size = 'md' }: { theme: Theme; size?: InputSize }) {
    const interactionShades = theme.getSurfaceInteractionShades('primary');

    return {
        width: buttonSizeTheme[size],
        backgroundColor: interactionShades.background,
        borderColor: theme.border.minimal,
        borderStyle: 'solid',
        outlineWidth: 0,
        padding: 0,
        height: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '&:hover:not(:disabled)': {
            backgroundColor: interactionShades.hover,
        },
        '&:active:not(:disabled)': {
            backgroundColor: interactionShades.active,
        },

        // If "up" is focussed, it is actually the "down" button's top border
        // which shows up in focussed color.
        '*:focus + &': {
            borderTopColor: theme.colorScheme.primary,
        },

        '&:focus': {
            borderRadius: 0,
            borderColor: theme.colorScheme.primary,
            outlineWidth: 0,
        },

        '& [data-gs-uitk-component=icon]': {
            fontSize: iconFontSizeTheme[size],
            color: theme.text.secondary,
        },
    };
}

function createDefaultStyledClasses({
    theme,
    size,
}: InputNumberStyleSheetProps): InputNumberStyledClasses {
    return {
        ui: {
            cursor: 'default',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },

        buttonUp: {
            ...getButtonStyles({ theme, size }),
            borderWidth: '0 0 0 1px',
        },

        buttonDown: {
            ...getButtonStyles({ theme, size }),
            borderWidth: '1px 0 0 1px',
        },
    };
}
