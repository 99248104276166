import actionTypes from './actionTypes';

export const onAcceptTermsAndConditions = (flag) => ({
  type: actionTypes.ON_ACCEPT_TERMS_AND_CONDITIONS,
  data: {hasAccepted: flag}
});

export const setTncCtaButtonsDisabled = (isTncCtaButtonsDisabled) => ({
  type: actionTypes.SET_CTA_BUTTONS_DISABLED,
  data: {isTncCtaButtonsDisabled}
});

export const isTncServiceCallInProgress = (flag) => ({
  type: actionTypes.IS_TNC_SERVICE_CALL_IN_PROGRESS,
  data: {
    isTncServiceCallInProgress: flag
  }
});
