import { componentAnalytics } from './analytics-tracking';
import { FC, useEffect } from 'react';
import { InputInternal } from './input-internal';
import { InputProps } from './input-props';

/**
 * Inputs allow users to enter text. They can be used to collect virtually any
 * string value, including  email addresses, passwords, search queries,
 * telephone numbers, and URLs.
 */
export const Input: FC<InputProps> = props => {
    const { inputRef, onInputRef = () => undefined } = props;
    // Purpose of this logic is simply to pass the deprecated onInputRef prop to the inputRef. This will ensure backward compatibility.
    function inputCallbackRef(el: HTMLInputElement | HTMLTextAreaElement) {
        if (typeof inputRef === 'function') {
            inputRef(el);
        } else if (inputRef) {
            inputRef.current = el!;
        }
        onInputRef(el);
    }
    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'input' });
    }, []); // Only run once
    return <InputInternal {...props} inputRef={inputCallbackRef} />;
};
