import { ComponentClass } from 'react';
import * as Redux from 'redux';
import { DatasourceType, GridWrapper } from '../../grid-wrappers/grid-wrapper';
import { SavedView } from '../../plugins/saved-view/saved-view';
import {
    ExportsActionKeys,
    GridActionKeys,
    SavedViewActionKeys,
    SystemConfigurationActionKeys,
    GridConfigurationActionKeys,
} from '../actions/action-constants';
import { AllActionTypes } from '../actions/all-action-types';
import {
    GridSetColumnValueList,
    GridSetBasicExpressionColumnValueList,
} from '../actions/grid-action';
import { AddSavedView, SetSavedView, UpdateSavedViewConfig } from '../actions/saved-view-action';
import { QuickFilterActionKeys } from '@gs-ux-uitoolkit-common/datacore';
import { DataGridState } from '../datagrid-state';
import {
    setOverlayContainer,
    clearOverlayContainer,
} from '../../components/modal-container/overlay-container';
import { GridConfigurationModal } from '../../components/grid-configuration-modal/grid-configuration-modal';

export const gridMiddleware =
    (gridWrapper: GridWrapper) =>
    (api: Redux.MiddlewareAPI<Redux.Dispatch<Redux.AnyAction>, DataGridState>) =>
    (next: Redux.Dispatch<Redux.AnyAction>) =>
    (action: AllActionTypes) => {
        let basicExpressionColumnValueTimer = null;
        switch (action.type) {
            case GridActionKeys.GET_COLUMN_VALUE_LIST:
                // we do it asyncronously. no need to be smart for now but maybe introduce SAga or something like that if it gets complex
                setTimeout(() => {
                    gridWrapper
                        .getDistinctColumnValueList(action.columnId)
                        .then(valueList =>
                            api.dispatch(GridSetColumnValueList(action.columnId, valueList))
                        );
                });
                break;
            case GridActionKeys.GET_BASIC_EXPRESSION_COLUMN_VALUE_LIST:
                // we do it asyncronously. no need to be smart for now but maybe introduce SAga or something like that if it gets complex
                if (basicExpressionColumnValueTimer) {
                    clearTimeout(basicExpressionColumnValueTimer);
                    basicExpressionColumnValueTimer = null;
                }
                basicExpressionColumnValueTimer = setTimeout(() => {
                    gridWrapper
                        .getDistinctColumnValueList(action.columnId)
                        .then(valueList =>
                            api.dispatch(
                                GridSetBasicExpressionColumnValueList(action.columnId, valueList)
                            )
                        );
                }, 30);
                break;
            case SavedViewActionKeys.CREATE_SAVED_VIEW:
                {
                    const savedView = gridWrapper.getSavedView(action.savedViewOption, action.name);
                    next(AddSavedView(savedView));
                    next(SetSavedView(action.name));
                    gridWrapper.savedViewCreated.dispatchAsync(savedView);
                }
                break;
            case SavedViewActionKeys.SAVE_SAVED_VIEW:
                {
                    action.savedView = gridWrapper.getSavedView(
                        action.savedView.savedViewOptions,
                        action.savedView.name
                    );
                    gridWrapper.savedViewSaved.dispatchAsync(action.savedView);
                }
                break;
            case SavedViewActionKeys.REMOVE_SAVED_VIEW:
                {
                    gridWrapper.savedViewDeleted.dispatchAsync(action.name);
                }
                break;
            case QuickFilterActionKeys.SAVE_EDITED_QUICK_FILTER:
                {
                    const state = api.getState();
                    if (state.quickFilter.editedItem) {
                        if (state.quickFilter.originalEditedItem) {
                            gridWrapper.quickFilterSaved.dispatchAsync(
                                state.quickFilter.editedItem
                            );
                        } else {
                            gridWrapper.quickFilterCreated.dispatchAsync(
                                state.quickFilter.editedItem
                            );
                        }
                    }
                }
                break;
            case QuickFilterActionKeys.REMOVE_QUICK_FILTER:
                {
                    gridWrapper.quickFilterDeleted.dispatchAsync(action.configItem);
                }
                break;
            case SavedViewActionKeys.SET_SAVED_VIEW:
                {
                    const savedView: SavedView | undefined = gridWrapper
                        .getReduxStore()
                        .getState()
                        .savedView.savedViewList.find(item => action.name === item.name);

                    if (savedView) {
                        gridWrapper.setSavedView(savedView);
                    }
                }

                break;
            case ExportsActionKeys.CREATE_EXPORT_BY_TYPE: {
                const exportOptions = api.getState().export.options;
                gridWrapper.export({
                    ...exportOptions,
                    type: action.exportType,
                });
                break;
            }
            case ExportsActionKeys.CREATE_EXPORT: {
                const exportOptions = api.getState().export.options;
                gridWrapper.export(exportOptions);
                break;
            }
            case SystemConfigurationActionKeys.SET_GRID_WRAPPER_CONFIGURATION: {
                if (
                    action.configuration.datasourceConfiguration &&
                    action.configuration.datasourceConfiguration.datasourceType ===
                        DatasourceType.DRA &&
                    action.configuration.datasourceConfiguration.fixedDRAFilter
                ) {
                    gridWrapper.refreshFiltering();
                }
                break;
            }
            case SystemConfigurationActionKeys.STORE_READY: {
                const state = gridWrapper.getReduxStore().getState();
                if (state.savedView.currentSavedView) {
                    api.dispatch(SetSavedView(state.savedView.currentSavedView));
                }
                if (state.savedView.savedViewConfig) {
                    api.dispatch(UpdateSavedViewConfig(state.savedView.savedViewConfig));
                }
                break;
            }
            case GridActionKeys.REMOVE_COLUMN_FILTER: {
                gridWrapper.removeColumnFilter(action.columnId);
                break;
            }
            case GridActionKeys.REMOVE_ALL_COLUMN_FILTERS: {
                gridWrapper.removeAllColumnFilters();
                break;
            }
            case GridConfigurationActionKeys.OPEN_GRID_CONFIGURATION: {
                const overlayContainer = gridWrapper.getOverlayContainer();
                const plugins = gridWrapper.getPlugins();
                const onHide = () => {
                    clearOverlayContainer();
                };

                setOverlayContainer(
                    overlayContainer,
                    GridConfigurationModal(
                        onHide,
                        plugins,
                        gridWrapper.getTheme()
                    ) as ComponentClass,
                    gridWrapper
                );
                break;
            }

            default:
                break;
        }
        const result = next(action);
        return result;
    };
