import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import useUserMaintenance from '../../../context';
import FieldRenderer from '../../FieldRenderer';
import translator from '../../../../../../services/translator';
import { UPDATE_ORG_FIELDS } from '../../../actions';
import './index.scss';

const { translate: t } = translator;

export default function ProfileGroup({ data, groupTitle, testId }) {
  const {
    state: { organizationDetails, isOrgEditable },
    storeValues: { editModeDropdownData },
    dispatch,
  } = useUserMaintenance();

  const customGetValue = value => {
    let returnValue = get(organizationDetails, value);
    if (['shareClassRebates', 'fundFamilyRebates'].includes(value) && typeof returnValue === 'undefined') {
      returnValue = t('tkNone');
    } else if (['channel', 'coverage'].includes(value) && typeof returnValue !== 'undefined') {
      returnValue = returnValue.replace(/;/g, '; ');
    }
    return returnValue === 'null' ? t('tkNullDataPlaceholder') : returnValue;
  };

  const handleChange = ({ value, config: { value: path } = {} }) => {
    dispatch({ type: UPDATE_ORG_FIELDS, payload: { newValue: value, path } });
  };

  return (
    <div className='profile-main-container__info-section-container'>
      <div data-testid={testId} className='profile-main-container__header-title'>
        {t(groupTitle)}
      </div>
      {data.map((coreItem, idx, array) => (
        <FieldRenderer
          key={coreItem.id}
          isEditable={isOrgEditable}
          config={coreItem}
          handleChange={handleChange}
          editModeDropdownData={editModeDropdownData}
          value={customGetValue(coreItem.value)}
          sectionStart={idx === 0}
          sectionEnd={idx === array.length - 1}
          activeIcon={coreItem.activeIcon}
        />
      ))}
    </div>
  );
}

ProfileGroup.propTypes = {
  data: PropTypes.array,
  groupTitle: PropTypes.string,
  testId: PropTypes.string,
};
