import { PluginBase, PluginIcon } from '@gs-ux-uitoolkit-common/datacore';
import { ModuleIdentfier } from '../module-identfier';
import { GridWrapper } from '../../grid-wrappers/grid-wrapper';
import { DataGridState, ZebraStripesState } from '../../redux/datagrid-state';
import { Categories, Plugins } from '../plugin-enum';
import { RowClassParams } from '@ag-grid-community/core';
import {
    getNonStripedClassNames,
    getStripedClassNames,
    zebraStripesStylesheet,
} from '../../style/plugins/zebra-stripes-stylesheet';
import { isEqual } from 'gs-uitk-lodash';

const mainIcon: PluginIcon = { name: 'menu', type: 'filled' };

export class ZebraStripesPlugin extends PluginBase<GridWrapper, DataGridState, ZebraStripesState> {
    protected static requiredModules: ModuleIdentfier[] = [];
    private gridWrapper: GridWrapper;
    private previousZebraStripeState: ZebraStripesState | undefined;
    private cssClasses;
    private isListenerAttached = false;
    constructor(wrapper: GridWrapper) {
        super(
            Plugins.ZebraStripesPlugin,
            Categories.View,
            mainIcon,
            wrapper,
            state => state.zebraStripes
        );

        this.gridWrapper = wrapper;

        this.cssClasses = zebraStripesStylesheet.mount(this, {
            stripeBackgroundColour: undefined,
            nonStripeBackgroundColour: undefined,
            theme: this.gridWrapper.getTheme(),
        });
    }

    private onViewportChanged = () => {
        this.stateChangedOrStart();
    };

    public destroy(): void {
        zebraStripesStylesheet.unmount(this);
        this.gridWrapper.removeGridViewportChangedListener(this.onViewportChanged);
        this.gridWrapper.themeChanged.unsubscribe(() =>
            this.setCssClasses(this.previousZebraStripeState)
        );
    }

    // AG-Grid-Balham theme sets a default zebra striped pattern on odd rows. We overwrite this by setting the non-striped row to the ag-grid themed background default
    private getStripeClass() {
        return `zebra-stripes ${getStripedClassNames({ cssClasses: this.cssClasses })}`;
    }
    private getNonStripeClass() {
        return `non-zebra-stripes ${getNonStripedClassNames({
            cssClasses: this.cssClasses,
        })}`;
    }

    protected internalStop(): void {
        this.removeZebraStripes();
    }

    private setCssClasses = (state?: ZebraStripesState) => {
        if (state?.stripeInterval !== undefined) {
            this.removeZebraStripes();
            this.previousZebraStripeState = state;
            zebraStripesStylesheet.unmount(this);
            this.cssClasses = zebraStripesStylesheet.mount(this, {
                stripeBackgroundColour: state.stripeBackgroundColour,
                nonStripeBackgroundColour: state.nonStripeBackgroundColour,
                theme: this.gridWrapper.getTheme(),
            });
            this.addZebraStripes();
            this.wrapper.refreshGrid();
        }
    };
    protected stateChangedOrStart(): void {
        if (!this.isListenerAttached) {
            this.gridWrapper.addGridViewportChangedListener(this.onViewportChanged);
            this.isListenerAttached = true;
        }
        const state = this.getPluginState();
        this.gridWrapper.themeChanged.subscribe(() =>
            this.setCssClasses(this.previousZebraStripeState)
        );

        // only update the row style if state has changed otherwise there will be an issue with performance
        if (state && !isEqual(this.previousZebraStripeState, state)) {
            this.setCssClasses(state);
        }
    }

    private addZebraStripes() {
        const stripeInterval = this.getPluginState()?.stripeInterval;
        if (stripeInterval !== undefined && stripeInterval > 0) {
            this.wrapper.addRowClassRule(this.getStripeClass(), this.getStripedRule);
            this.wrapper.addRowClassRule(this.getNonStripeClass(), this.getNonStripedRule);
        } else if (stripeInterval === 0) {
            this.wrapper.addRowClassRule(this.getNonStripeClass(), () => true);
        }
    }

    private getStripedRule = (params: RowClassParams): boolean => {
        const interval = this.getPluginState()?.stripeInterval;
        return interval !== undefined ? Math.floor((params.rowIndex / interval) % 2) === 0 : false;
    };

    private getNonStripedRule = (params: RowClassParams): boolean => {
        return !this.getStripedRule(params);
    };

    private removeZebraStripes() {
        this.wrapper.removeRowClassRule(this.getStripeClass());
        this.wrapper.removeRowClassRule(this.getNonStripeClass());
    }
}
