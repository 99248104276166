import { FC, memo, CSSProperties, HTMLAttributes, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    BadgeProps as CommonBadgeProps,
    defaultBadgeProps,
    determineDefaults,
    badgeSizes,
    badgeColors,
    badgeStatuses,
    badgeShapes,
    badgeEmphases,
    badgeStyleSheet,
    convertPropsForBackwardsCompat,
    getBadgeRootClasses,
} from '@gs-ux-uitoolkit-common/badge';
import { VisuallyHidden } from '@gs-ux-uitoolkit-react/accessibility';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { componentAnalytics } from './analytics-tracking';

interface BadgeProps
    extends CommonBadgeProps<CSSProperties>,
        Omit<HTMLAttributes<HTMLElement>, 'color'> {}

/**
 * A badge is a way of providing a very visible highlight or notification on a page. Badges are composed of text, which may be alphabetic or numeric, surrounded by a box of color.
 */
const Badge: FC<BadgeProps> = memo(props => {
    const {
        className,
        classes: overrideClasses,
        color,
        status,
        size = defaultBadgeProps.size,
        shape = defaultBadgeProps.shape,
        emphasis = defaultBadgeProps.emphasis,
        children,
        ...attributes
    } = props;

    // check for backwards-compatibility color values such as 'danger' or 'purple-light'
    const badgeDeprecatedConvertedProps = convertPropsForBackwardsCompat({
        color,
        status,
        emphasis,
    });

    const badgePropsWithDefaults = determineDefaults({
        ...badgeDeprecatedConvertedProps,
        size,
        shape,
    });

    const theme = useTheme();
    const cssClasses = useStyleSheet(badgeStyleSheet, {
        theme,
        ...badgePropsWithDefaults,
    });

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'badge' });
    }, []); // Only run once

    return (
        <span
            data-gs-uitk-component="badge"
            data-color={badgePropsWithDefaults.color}
            data-emphasis={badgePropsWithDefaults.emphasis}
            data-size={badgePropsWithDefaults.size}
            data-shape={badgePropsWithDefaults.shape}
            data-status={badgePropsWithDefaults.status}
            className={getBadgeRootClasses({ className, cssClasses, overrideClasses })}
            {...attributes}
        >
            {children}
            {status && <VisuallyHidden>status: {status}</VisuallyHidden>}
        </span>
    );
});
Badge.displayName = 'Badge';

Badge.propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf(badgeColors),
    status: PropTypes.oneOf(badgeStatuses),
    size: PropTypes.oneOf(badgeSizes),
    shape: PropTypes.oneOf(badgeShapes),
    emphasis: PropTypes.oneOf(badgeEmphases),
};

export { Badge, type BadgeProps };
