import { IRowNode } from '@ag-grid-community/core';
import {
    expressionEvaluator,
    PluginBase,
    QuickFilterState,
    shouldTriggerUpdateForPluginWithItems,
    PluginIcon,
    invariantQuery,
} from '@gs-ux-uitoolkit-common/datacore';
import { ModuleIdentfier } from '../module-identfier';
import { GridWrapper } from '../../grid-wrappers/grid-wrapper';
import { DataGridState, FloatingFilterState } from '../../redux/datagrid-state';
import { Categories, Plugins } from '../plugin-enum';
import { QuickFilterPluginModal } from './view/quick-filter-plugin-modal';
import { getEnhancedQuickFilterPluginWidget } from './view/widgets/enhanced-quick-filter-plugin-widget';
import { getQuickFilterPluginWidget } from './view/widgets/quick-filter-plugin-widget';
import { DataGridToolbarItem } from '../../toolbar-state';
import { getQuickFilterShortcutWidget } from './view/widgets/quick-filter-shortcut';
import { isEqual } from 'gs-uitk-lodash';

const quickFilterIcon: PluginIcon = { name: 'filter-list', type: 'outlined' };
const enhancedQuickFilterIcon: PluginIcon = { name: 'filter-alt', type: 'filled' };
const componentId = 'QuickFilterModal';

/**
 * The Quick Filter plugin. Allows the developers and end-users to create quick filters at runtime and design time
 */
export class QuickFilterPlugin extends PluginBase<
    GridWrapper,
    DataGridState,
    QuickFilterState & FloatingFilterState
> {
    protected static requiredModules: ModuleIdentfier[] = [
        ModuleIdentfier.SetFilterModule,
        ModuleIdentfier.MultiFilterModule,
    ];
    constructor(wrapper: GridWrapper) {
        super(Plugins.QuickFilterPlugin, Categories.Data, quickFilterIcon, wrapper, state => {
            return { ...state.quickFilter, ...state.grid.floatingFilter };
        });
        this.screens = [
            {
                componentId,
                icon: quickFilterIcon,
                label: 'Quick Filter',
                screen: QuickFilterPluginModal((columnId: string, value: string | number | Date) =>
                    wrapper.getFormattedValueFromColumn(columnId, value)
                ),
                store: wrapper.getReduxStore(),
                context: wrapper.getReduxStoreContext(),
            },
        ];
        this.widgets = [
            {
                componentId: 'QuickFilterWidget' as DataGridToolbarItem,
                hideIcon: true,
                icon: quickFilterIcon,
                label: '',
                name: 'Quick Filter',
                store: wrapper.getReduxStore(),
                context: wrapper.getReduxStoreContext(),
                widget: getQuickFilterPluginWidget(wrapper.getReduxStoreContext()),
            },
            {
                componentId: 'EnhancedQuickFilterWidget' as DataGridToolbarItem,
                hideIcon: true,
                icon: enhancedQuickFilterIcon,
                label: '',
                name: 'Enhanced Quick Filter',
                store: wrapper.getReduxStore(),
                context: wrapper.getReduxStoreContext(),
                widget: getEnhancedQuickFilterPluginWidget(wrapper.getReduxStoreContext()),
            },

            {
                componentId: 'QuickFilterShortcut' as DataGridToolbarItem,
                hideIcon: true,
                icon: quickFilterIcon,
                label: '',
                name: 'Quick Filter Shortcut',
                maxWidth: 24,
                minWidth: 24,
                store: wrapper.getReduxStore(),
                widget: getQuickFilterShortcutWidget(wrapper.getReduxStoreContext()),
                width: 24,
                context: wrapper.getReduxStoreContext(),
            },
        ];
    }
    public start() {
        super.start();
        if (this.wrapper.isUsingDra()) {
            // if we are using DRA then we don't do anything and let the DRAPlugin take over...
            return;
        }
        // we attach our callbacks so the wrapper will call us at filter time
        this.wrapper.onIsFilterPresent(() => this.isFilterPresent());
        this.wrapper.onFilterPass(rowNode => this.filterPass(rowNode));
    }

    protected stateChangedOrStart(): void {
        const previousPluginState = this.getPreviousPluginState();
        const currentPluginState = this.getPluginState();

        if (
            !isEqual(previousPluginState?.columns, currentPluginState?.columns) ||
            !isEqual(previousPluginState?.enabled, currentPluginState?.enabled)
        ) {
            this.wrapper.toggleFloatingFilters();
        }
        if (
            currentPluginState &&
            shouldTriggerUpdateForPluginWithItems(previousPluginState, currentPluginState)
        ) {
            setTimeout(() => this.wrapper.refreshFiltering());
        }
    }

    protected internalStop(): void {
        // need to do it but not needed for now
    }

    private isFilterPresent(): boolean {
        const pluginState = this.getPluginState();
        return pluginState ? pluginState.configItemList.length > 0 : false;
    }
    private filterPass(rowNode: IRowNode): boolean {
        const pluginState = this.getPluginState();
        return pluginState
            ? pluginState?.configItemList.every(quickFilter => {
                  if (quickFilter.isEnabled) {
                      return expressionEvaluator.evaluate(
                          rowNode,
                          quickFilter.isComputedExternally
                              ? invariantQuery
                              : quickFilter.expression?.query ?? invariantQuery,
                          this.wrapper,
                          this.wrapper.getReduxStore().getState().quickFilter.configItemList
                      );
                  }
                  return true;
              })
            : false;
    }
}
