import { logger, PluginBase, PluginIcon } from '@gs-ux-uitoolkit-common/datacore';
import { ModuleIdentfier } from '../module-identfier';
import { createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import { GridWrapper } from '../../grid-wrappers/grid-wrapper';
import { DataGridState } from '../../redux/datagrid-state';
import { Categories, Plugins } from '../plugin-enum';
import { NotificationContainer } from './view/notification-container';

const mainIcon: PluginIcon = { name: 'priority-high', type: 'filled' };

export class NotificationPlugin extends PluginBase<GridWrapper, DataGridState> {
    private root!: Root;
    private containerElement: HTMLElement;
    protected static requiredModules: ModuleIdentfier[] = [];
    constructor(wrapper: GridWrapper) {
        super(Plugins.NotificationPlugin, Categories.Data, mainIcon, wrapper, () => true);
        this.containerElement = document.createElement('div');
    }

    protected internalStop(): void {
        setTimeout(() => {
            this.root?.unmount();
            this.removeContainerFromDom();
        });
    }

    protected stateChangedOrStart(): void {
        this.addContainerToDom();
        this.renderNotificationContainer();
    }

    private addContainerToDom() {
        const wrapperElement = this.wrapper.wrapperRef;
        if (wrapperElement) {
            wrapperElement.insertBefore(this.containerElement, wrapperElement.firstChild);
        } else {
            logger.error(`Cannot find wrapper element with the id ${this.wrapper.getId()}`);
        }
    }
    private removeContainerFromDom() {
        const wrapperElement = this.wrapper.wrapperRef;
        if (wrapperElement?.contains(this.containerElement)) {
            wrapperElement.removeChild(this.containerElement);
        }
    }

    private renderNotificationContainer() {
        const notificationContainerWrapper = (
            <Provider store={this.wrapper.getReduxStore()}>
                <NotificationContainer wrapper={this.wrapper} />
            </Provider>
        );
        this.root = createRoot(this.containerElement);
        this.root.render(notificationContainerWrapper);
    }
}

export enum NotificationType {
    WARNING = 'warning',
    DANGER = 'error',
    INFO = 'information',
    SUCCESS = 'success',
}
export interface NotificationItem {
    type: NotificationType;
    dismissible: boolean;
    title?: string;
    message?: string;
}
