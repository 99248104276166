import { RefObject, ReactNode, ReactElement } from 'react';

/**
 * Checks if the given variable is React's 'RefObject'.
 */
export function isRefObject<T>(ref: RefObject<T> | any): ref is RefObject<T> {
    return typeof ref === 'object' && ref !== null && Object.hasOwnProperty.call(ref, 'current');
}

/**
 * Checks if the provided React node is a React element (a Component as opposed to text)
 *
 * @param node
 */
export function isReactEl(node: ReactNode): node is ReactElement {
    return (node as ReactElement).type !== undefined;
}
