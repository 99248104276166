import actionTypes from './actionTypes';

export const addAppPreferences = data => (
  {
    type: actionTypes.ADD_APP_PREFERENCE,
    data: {...data}
  }
);
export const editAppPreferences = data => (
  {
    type: actionTypes.EDIT_APP_PREFERENCE,
    data
  }
);

export const saveAppPreferences = data => (
  {
    type: actionTypes.SAVE_APP_PREFERENCES,
    data
  }
);

export const addSavedPreferences = data => (
  {
    type: actionTypes.ADD_SAVED_PREFERENCES,
    data
  }
);

export const addPreferencesError = data => (
  {
    type: actionTypes.ADD_PREFERENCES_ERROR,
    data
  }
);

export const managersChange = data => (
  {
    type: actionTypes.MANAGERS_PREFERENCE_CHANGE,
    data : {'fundManagers': data}
  }
);

export const fundCurrenciesChange = data => (
  {
    type: actionTypes.FUND_CURRENCIES_PREFERENCE_CHANGE,
    data : {'currencies': data}
  }
);

export const esgFundChange = data => (
  {
    type: actionTypes.ESG_FUND_PREFERENCE_CHANGE,
    data : {'esg': data}
  }
);


export const tradeTemplateChange = data => (
  {
    type: actionTypes.TRADE_TEMPLATE_PREFERENCE_CHANGE,
    data
  }
);

export const fundInvestmentTypesChange = data => (
  {
    type: actionTypes.FUND_TYPES_PREFERENCE_CHANGE,
    data : {'fundTypes': data}
  }
);

export const clearAppPreferences = () => (
  {
    type: actionTypes.CLEAR_APP_PREFERENCE
  }
);
export const toggleZBP = data => (
  {
    type: actionTypes.TOGGLE_ZBP,
    data: {'includeZeroPositions': data}
  }
);
export const toggleCollapsedAllState = data => (
  {
    type: actionTypes.TOGGLE_COLLAPSE,
    data: {'isCollapsedAll': data}
  }
);

export const onFirmPreferencesChanged = data => (
  {
    type: actionTypes.FIRM_PREFERENCES_CHANGED,
    data
  }
);

export const onFirmloaded = () => (
  {
    type: actionTypes.FIRM_LOADED
  }
);

export const toggleInstShareClassesOnly = (data) => (
  {
    type: actionTypes.TOGGLE_INST_SHARE_CLASSES_ONLY,
    data: {'institutionalShareClassOnly' : data }
  }
);

export const toggleOpenAccountsOnly = (data) => (
  {
    type: actionTypes.TOGGLE_OPEN_ACCOUNT_ONLY,
    data: {'openAccountsOnly' : data }
  }
);

export const fundTrackerPreferenceChange = data => (
  {
    type: actionTypes.FUND_TRACKER_PREFERENCE_CHANGE,
    data
  }
);

export const toggleCollapsedAllReportState = data => (
  {
    type: actionTypes.TOGGLE_COLLAPSE_REPORT,
    data: {'isCollapsedAll': data}
  }
);
