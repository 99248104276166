import { CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { BadgeProps, defaultBadgeProps } from './badge-props';

export function determineDefaults(props: BadgeProps<CommonStyleType>): BadgeProps<CommonStyleType> {
    let { status } = props;
    const {
        color,
        emphasis = defaultBadgeProps.emphasis,
        shape = defaultBadgeProps.shape,
        size = defaultBadgeProps.size,
    } = props;

    // status takes precedence over color
    if (status && color) {
        console.warn(
            `Badge: Both props 'status' and 'color' were defined.  Please use one or the other, not both.  Using 'status', ignoring 'color'...`
        );
    }
    // if both status or color are not provided, default status='none'
    if (status == null && color == null) {
        status = 'none';
    }

    return {
        status,
        size,
        color,
        emphasis,
        shape,
    };
}
