import { connect } from 'react-redux';
import TermsAndConditionsModal from '../../components/app/TermsAndConditionsModal';
import {logoutAction} from '../../actions/app';
import {isAppLoadingSelector, termsAndConditionContentsSelector} from '../../selectors/app';
import {
  isShowTermsAndConditionsSelector, isTncCtaButtonsDisabledSelector, isTncServiceCallInProgressSelector,
  submitTncErrorSelector
} from '../../selectors/pages/termsAndCondition';
import { onAcceptTermsAndConditions, setTncCtaButtonsDisabled } from '../../actions/page/termsAndCondition';
import { eventAnalytics } from '../../actions/sagas';

const mapStateToProps = (state) => ({
  isShowModal: isShowTermsAndConditionsSelector(state),
  isTncCtaButtonsDisabled: isTncCtaButtonsDisabledSelector(state),
  isTncServiceCallInProgress: isTncServiceCallInProgressSelector(state),
  submitTncError: submitTncErrorSelector(state),
  isAppLoading: isAppLoadingSelector(state),
  termsAndConditionContents: termsAndConditionContentsSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  doAnalytics: (data) => dispatch(eventAnalytics(data)),
  onAcceptTermsAndConditions: (flag) => dispatch(onAcceptTermsAndConditions(flag)),
  eventAnalytics: (data) => dispatch(eventAnalytics(data)),
  setTncCtaButtonsDisabled: (flag) => dispatch(setTncCtaButtonsDisabled(flag)),
  logoutAction: (data) => dispatch(logoutAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditionsModal);
