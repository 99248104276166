import { getSingletonInstance } from '../../libraries/singleton';
import { CrossModelController } from './cross-model-controller';
import { CrossModelStore } from './redux/cross-model-store';

export class CrossModelService {
    private crossModelController: Map<string, CrossModelController> = new Map();
    private crossModelStore = new CrossModelStore();
    public registerCrossModelController(controllerName: string): CrossModelController {
        let crossModelController = this.crossModelController.get(controllerName);
        if (!crossModelController) {
            crossModelController = new CrossModelController(controllerName, this.crossModelStore);
            this.crossModelController.set(controllerName, crossModelController);
        }
        return crossModelController;
    }
}

export const getCrossModelService = () =>
    getSingletonInstance('DataCore.CrossModelService', () => new CrossModelService());
