import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import PageHeader from '../../../components/app/PageHeader';
import Snackbar from '../../../components/core/Snackbar/index';
import {snackbarDataList, snackbarErrorList} from '../../../selectors/pages/fundFinder';
import {labelSelector} from '../../../selectors/app';
import Constants from '../../../constants/appConstants';
import {getUpdatedGlossaryText} from '../../../utils/commonUtils';

const FundFinder = ({snackbarItems, labels}) => (
  <div className="portfolio__header">
    <TransitionGroup component={null}>
      {snackbarItems.map((data, idx) => {
        return (
          <CSSTransition
            key={idx}
            classNames="snackbar__transition"
            timeout={Constants.CSS_DURATION_HORSE}>
            <Snackbar
              labels={labels}
              id={data.id}
              type={data.type}
              msgCopy={data.message}
            />
          </CSSTransition>
        );
      })}
    </TransitionGroup>
    {
      PageHeader({
        title: labels.tkFundFinder,
        description: labels.tkFundFinderTitleText + getUpdatedGlossaryText({glossaryTxt: labels.tkTitleText}) || '--'
      })
    }
  </div>
);

FundFinder.propTypes = {
  snackbarItems: PropTypes.array,
  labels: PropTypes.object
};

const getSnackbarItems = (state) => [...snackbarErrorList(state), ...snackbarDataList(state)];

const mapStateToProps = (state) => (
  {
    labels: labelSelector(state),
    snackbarItems: getSnackbarItems(state)
  });

export default connect(mapStateToProps)(FundFinder);
