import { StyleSheet, CssClasses } from '@gs-ux-uitoolkit-react/style';

export type HeatmapRendererCssClasses = CssClasses<typeof heatmapRendererStyleSheet>;

export const heatmapRendererStyleSheet = new StyleSheet('heatmapRenderer', () => {
    return {
        root: {
            // when 'enableCellTextSelection' is set to true then the 'ag-cell-value' span doesn't take up the whole width of the cell.
            // the style below will ensure the entire cell will have the heatmap background color.
            // We also need to maintain text alignment (numbers right aligned for example)
            // We need to ensure the numbers don't fall out of the cell hence overflow is set to hidden
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            overflow: 'hidden',
            '.heatmap-renderer-value': {
                padding: '0px 8px',
            },
            '.ag-condensed': {
                '.ag-ultra-condensed': {
                    '.heatmap-renderer-value': {
                        padding: '0px 4px',
                    },
                },
            },
        },
    };
});
