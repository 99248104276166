import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Conditional} from '../../../../components/core/Conditional';
import {
  customReportCrudResponseSelector, mySavedReportsDataSelector,
  selectedSavedReportDataSelector, savedReportFailedSnackbarSelector
} from '../../../../selectors/app/reports';
import {saveCustomReport} from '../../../../actions/page/reports';
import {
  defaultAdditionalFiltersSelector,
  filterConfigSelector,
  filterDataSelector, saveCustomReportPendingFlagSelector
} from '../../../../selectors/pages/reports/individualReport';
import {setCustomReportCrudResponse} from '../../../../actions/app/reports';
import Button from '../../../../components/core/Button';
import {labelSelector} from '../../../../selectors/app';
import { allReportsGridDataSelector } from '../../../../selectors/pages/reports/allReports';
import { pageIdSelector } from '../../../../selectors/pages';
import {SaveReportModal} from './SaveReportModal';
import {REPORTS_STATUS_CODES} from '../../../../constants/pageConstants';
import translator from '../../../../services/translator';
import { applyPatternFormatter } from '../../../../utils/formatters';
import { regexConstants } from '../../../../helpers/regex';
import './index.scss';

const { translate: t } = translator;

export const SaveCustomReports = ({
  selectedFilterData, selectedSavedReportData, mySavedReports, dispatchSaveCustomReport,
  savedReportFailedSnackbarMsg, setCustomReportCrudResponse, customReportCrudResponse,
  saveCustomReportPendingFlag, labels, pageHeaderData, pageId, defaultAdditionalFilters,
  filterConfig
}) => {
  const [isShowSaveReportModal, setIsShowSaveReportModal] = useState(false);
  const {status} = customReportCrudResponse || {};

  const onShowSaveReportModal = () => {
    setCustomReportCrudResponse(null);
    setTimeout(() => {
      setIsShowSaveReportModal(true);
    });
  };

  const handleClose = () => {
    if (status !== REPORTS_STATUS_CODES.SUCCESS && status!== REPORTS_STATUS_CODES.MAX_COUNT) {
      setCustomReportCrudResponse({status : REPORTS_STATUS_CODES.CANCEL});
    }
    setIsShowSaveReportModal(false);
  };

  useEffect(() => {
    if (status === REPORTS_STATUS_CODES.SAVED || status === REPORTS_STATUS_CODES.MAX_COUNT) {
      handleClose();
    }
  }, [customReportCrudResponse]);

  const getInitialReportData = () => {
    if (pageId === 'SavedReport') {
      const { reportName, reportDescription: description } = selectedSavedReportData;
      return { reportName, description };
    } else {
      const { report, description } = 
      (
        pageHeaderData && 
        pageHeaderData.find(({ legacyReportId, newReportId }) => {
            return [legacyReportId, newReportId].includes(pageId);
        })
      ) || {};

      const reportName = `${t('tkMy')} ${applyPatternFormatter(report, regexConstants.validSaveReportName)}`.trim();
      const formattedDesc = applyPatternFormatter(description, regexConstants.validSaveReportDesc).trim();

      return { reportName, description: formattedDesc };
    }
  };

  return (
    <>
      <Button
        iconClass='save-icon'
        clickHandler={onShowSaveReportModal}
      />
      <Conditional condition={isShowSaveReportModal}>
        <SaveReportModal
          customReportCrudResponse={customReportCrudResponse}
          selectedFilterData={selectedFilterData}
          dispatchSaveCustomReport={dispatchSaveCustomReport}
          selectedSavedReportData={selectedSavedReportData}
          mySavedReports={mySavedReports}
          saveCustomReportPendingFlag={saveCustomReportPendingFlag}
          setCustomReportCrudResponse={setCustomReportCrudResponse}
          savedReportFailedSnackbarMsg={savedReportFailedSnackbarMsg}
          labels={labels}
          filterConfig={filterConfig}
          defaultAdditionalFilters={defaultAdditionalFilters}
          handleClose={handleClose}
          initialReportData={getInitialReportData}
          pageId={pageId}
        />
      </Conditional>
    </>
  );
};

SaveCustomReports.propTypes = {
  selectedSavedReportData: PropTypes.array,
  mySavedReports: PropTypes.array,
  selectedFilterData: PropTypes.object,
  customReportCrudResponse: PropTypes.object,
  saveCustomReportPendingFlag: PropTypes.bool,
  dispatchSaveCustomReport: PropTypes.func,
  setCustomReportCrudResponse: PropTypes.func,
  savedReportFailedSnackbarMsg: PropTypes.object,
  labels: PropTypes.object,
  pageHeaderData: PropTypes.object,
  pageId: PropTypes.object,
  filterConfig: PropTypes.object,
  defaultAdditionalFilters: PropTypes.object
};

const mapStateToProps = (state) => ({
  selectedSavedReportData: selectedSavedReportDataSelector(state),
  mySavedReports: mySavedReportsDataSelector(state),
  selectedFilterData: filterDataSelector(state),
  customReportCrudResponse: customReportCrudResponseSelector(state),
  saveCustomReportPendingFlag: saveCustomReportPendingFlagSelector(state),
  savedReportFailedSnackbarMsg: savedReportFailedSnackbarSelector(state),
  filterConfig: filterConfigSelector(state),
  defaultAdditionalFilters: defaultAdditionalFiltersSelector(state),
  labels: labelSelector(state),
  pageHeaderData: allReportsGridDataSelector(state),
  pageId: pageIdSelector(state)
});

export const mapDispatchToProps = (dispatch) => ({
  dispatchSaveCustomReport: (payLoad) => dispatch(saveCustomReport(payLoad)),
  setCustomReportCrudResponse: (data) => dispatch(setCustomReportCrudResponse(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveCustomReports);
