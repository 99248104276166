import { cx, CssClasses } from '@gs-ux-uitoolkit-common/style';
import { TableProps } from './table-props';
import { tableStyleSheet } from './table-style-sheet';

const globalClassName = 'gs-table';

export const getRootClassNames = (props: {
    cssClasses: CssClasses<typeof tableStyleSheet>;
    overrideClasses?: TableProps['classes'];
    className?: TableProps['className'];
}): string => {
    const { cssClasses, className, overrideClasses } = props;
    return cx(cssClasses.root, className, overrideClasses && overrideClasses.root, globalClassName);
};

export const getTableClassNames = (props: {
    cssClasses: CssClasses<typeof tableStyleSheet>;
    overrideClasses?: TableProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.table,
        overrideClasses && overrideClasses.table,
        `${globalClassName}__table`
    );
};
