import { getWindow } from './dom';
import constants from '../constants/appConstants';

const getCssVariables = (key) => {
  const result = getWindow().getComputedStyle(document.querySelectorAll('body')[0], 'after')[key].replace(/"/g, '');
  return Object.prototype.hasOwnProperty.call(constants, result) ? result : constants.DESKTOP;
};

export const isMobile = () => (
  getCssVariables('content') === constants.MOBILE
);

export const isTablet = () => (
  getCssVariables('content') === constants.TABLET
);

export const isDesktop = () => (
  getCssVariables('content') === constants.DESKTOP || getCssVariables('content') === constants.LGDESKTOP || getCssVariables('content') === constants.XLGDESKTOP
);

export const isNormalDesktop = () => (
  getCssVariables('content') === constants.DESKTOP
);

export const isLgDesktop = () => (
  getCssVariables('content') === constants.LGDESKTOP
);

export const isXlgDesktop = () => (
  getCssVariables('content') === constants.XLGDESKTOP
);

export const getDeviceType = () => getCssVariables('content');
