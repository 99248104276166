import {
    addConfigItem,
    cancelEditedConfigItem,
    editConfigItem,
    newConfigItem,
    removeConfigItem,
    saveEditedConfigItem,
    updateEditedConfigItem,
} from '@gs-ux-uitoolkit-common/datacore';
import { CustomSort } from '../../plugins/custom-sort/custom-sort-plugin';
import { CustomSortActionKeys } from '../actions/action-constants';
import { CustomSortActionTypes } from '../actions/custom-sort-action';
import { CustomSortState } from '../datagrid-state';

/**
 * Initial state of the Custom Sort State
 */
export const defaultCustomSortState: CustomSortState = {
    configItemList: [],
    defaultAbsoluteCustomSortNumeric: false,
    editedItem: null,
    originalEditedItem: null,
};

const emptyCustomSort: CustomSort = {
    absoluteSort: false,
    absoluteSortActive: false,
    columnId: '',
    dataList: [],
};

/**
 * Reducer for the CustomSortState
 * @param state State of the CustomSortState
 * @param action Action received
 */
export const customSortReducer = (
    state: CustomSortState = defaultCustomSortState,
    action: CustomSortActionTypes
): CustomSortState => {
    switch (action.type) {
        case CustomSortActionKeys.REMOVE_CUSTOM_SORT:
            return removeConfigItem(state, action);
        case CustomSortActionKeys.EDIT_CUSTOM_SORT:
            return editConfigItem(state, action);
        case CustomSortActionKeys.NEW_CUSTOM_SORT:
            return newConfigItem(state, emptyCustomSort);
        case CustomSortActionKeys.SAVE_EDITED_CUSTOM_SORT:
            return saveEditedConfigItem(state);
        case CustomSortActionKeys.UPDATE_EDITED_CUSTOM_SORT:
            return updateEditedConfigItem(state, action);
        case CustomSortActionKeys.CANCEL_EDITED_CUSTOM_SORT:
            return cancelEditedConfigItem(state);
        case CustomSortActionKeys.ADD_CUSTOM_SORT:
            return addConfigItem(state, action);
        case CustomSortActionKeys.ADD_CUSTOM_SORT_LIST:
            return {
                ...state,
                configItemList: [...state.configItemList, ...action.configItem],
            };
        case CustomSortActionKeys.SET_ABSOLUTE_SORT_STATUS: {
            const newCustomSort = { ...action.customSort };
            newCustomSort.absoluteSortActive = action.active;
            return {
                ...state,
                configItemList: [
                    ...state.configItemList.filter(cs => cs !== action.customSort),
                    newCustomSort,
                ],
            };
        }
        default:
            return state;
    }
};
