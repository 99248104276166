import {
    StyleSheet,
    CssClassDefinitionsObject,
    CssPropertyType,
} from '@gs-ux-uitoolkit-common/style';
import { TabsOrientation, TabsSize } from './tabs-props';
import { getIconStyles, IconName } from '@gs-ux-uitoolkit-common/icon-font';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './tab-theme-overrides';

export interface TabStyleSheetProps {
    theme: Theme;
    size: TabsSize;
    showExpandIcon: boolean;
    orientation: 'vertical' | 'horizontal';
    iconOnly?: boolean;
    isDropdownOpen?: boolean;
}

export interface TabCssClasses {
    leadingIcon: string;
    dropdownToggle: string;
}

export type TabStyledClasses = CssClassDefinitionsObject<keyof TabCssClasses>;

export interface TabStyleOverridesParams {
    props: DeepReadonly<TabStyleSheetProps>;
    createDefaultStyledClasses: () => TabStyledClasses;
}

/**
 * StyleSheet definition for Tab
 */
export const tabStyleSheet = new StyleSheet('tab', (props: TabStyleSheetProps) => {
    return createComponentClassDefinitions<TabStyleSheetProps, TabStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.tab
    );
});

function createDefaultStyledClasses({
    size = 'md',
    showExpandIcon,
    iconOnly,
    isDropdownOpen = false,
    orientation,
}: TabStyleSheetProps): TabStyledClasses {
    return {
        leadingIcon: {
            marginRight: iconOnly ? '0px' : tabSizeVariants[size].iconMarginRight,
            fontSize: tabSizeVariants[size].iconFontSize,
        },
        dropdownToggle: {
            ...getExpandIconStyles({
                showExpandIcon,
                isDropdownOpen,
                orientation,
                size,
            }),
        },
    };
}

function getExpandIconStyles({
    size,
    orientation,
    showExpandIcon,
    isDropdownOpen,
}: {
    size: TabsSize;
    orientation: 'horizontal' | 'vertical';
    showExpandIcon: boolean;
    isDropdownOpen: boolean;
}) {
    if (!showExpandIcon) {
        return {};
    }

    return {
        '::after': {
            ...getIconStyles({
                name: isDropdownOpen
                    ? getOpenedDropdownToggleStyleName(orientation)
                    : getClosedDropdownToggleStyleName(orientation),
                type: 'filled',
                size,
            }),
            marginLeft: '7px',
        },
    };
}

function getOpenedDropdownToggleStyleName(orientation: TabsOrientation): IconName {
    if (orientation === 'vertical') {
        return 'keyboard-arrow-left';
    }
    return 'keyboard-arrow-up';
}

function getClosedDropdownToggleStyleName(orientation: TabsOrientation): IconName {
    if (orientation === 'vertical') {
        return 'keyboard-arrow-right';
    }
    return 'keyboard-arrow-down';
}

const tabSizeVariants: { [name in TabsSize]: TabSizeVariant } = {
    md: {
        iconFontSize: '20px',
        iconMarginRight: '8px',
    },
    lg: {
        iconFontSize: '24px',
        iconMarginRight: '10px',
    },
};

interface TabSizeVariant {
    iconFontSize: CssPropertyType['fontSize'];
    iconMarginRight: CssPropertyType['marginRight'];
}
