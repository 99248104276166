export class EnumHelper {
    public stringToEnum<T extends number>(stringRep: string, selectedEnum: any): T {
        return selectedEnum[stringRep];
    }

    public stringToStringEnum<T>(stringRep: string, enumType: any): T | null {
        const item = Object.entries(enumType).find(x => x[1] === stringRep);
        return item ? (item[1] as T) : null;
    }

    public numberToNumberEnum<T>(numberRep: number, enumType: any): T | null {
        const item = Object.entries(enumType).find(x => x[1] === numberRep);
        return item ? (item[1] as T) : null;
    }

    public getOptionsFromEnum<T, U>(enumType: T, notToInlude: U[] = []): JSX.Element[] {
        // Note: had to add 'any' types for TS 4.8.4.
        // TODO: Remove and add better typings. The type parameter T may need a
        // constraint like: `T extends { [s: string]: unknown; } | ArrayLike<unknown>`,
        // but didn't want to accidentally break backward compatibility if this
        // is not the case
        return Object.entries(enumType as any)
            .filter(x => !notToInlude.includes((x as any)[1]))
            .map(x => (
                <option key={x['0']} value={(x as any)['1']}>
                    {x['0']}
                </option>
            ));
    }
}

export const enumHelper = new EnumHelper();
