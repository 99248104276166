import { CellClassParams, ValueGetterParams } from '@ag-grid-community/core';
import {
    arrayHelper,
    CellFormat,
    ColumnHint,
    ColumnHintDefaults,
    ColumnHintFormat,
    ColumnHintType,
    DataType,
    logger,
    HintList,
    PluginIcon,
} from '@gs-ux-uitoolkit-common/datacore';
import { ModuleIdentfier } from '../module-identfier';
import { GridColumn, GridWrapper } from '../../grid-wrappers/grid-wrapper';
import { ColumnHintState, DataGridState } from '../../redux/datagrid-state';
import { addHyperlinkRenderer, removeHyperlinkRenderer } from '../../renderer/hyperlink-renderer';
import { ColumnRefreshPluginBase } from '../column-refresh/column-refresh-plugin-base';
import { Categories, Plugins } from '../plugin-enum';
import { columnHintHelper } from './column-hint-helper';
import { buildColumnHintFormatter } from './formatters';
import { ColumnHintPluginModal } from './view/column-hint-modal';
import { isEmpty, debounce } from 'gs-uitk-lodash';
import {
    columnHintStylesheet,
    getRedNegativeClassNames,
} from '../../style/plugins/column-hint-plugin-stylesheet';
import { OpenGridConfiguration } from '../../redux/actions/grid-configuration-action';
import { DataGridToolbarItem } from '../../toolbar-state';

const mainIcon: PluginIcon = {
    name: 'tune',
    type: 'filled',
};
const componentId = 'ColumnHintModal';

export const COLUMN_HINT_PREFIX = 'column-hint';
export const DEFAULT_COLUMN_HINT_PREFIX = 'column-hint-defaults';
export const COLUMN_HINT_RED_IF_NEGATIVE = COLUMN_HINT_PREFIX + '-red-if-negative';
export const formatGroupedColumn = (id: string) => {
    return `${id
        .replace(/\./g, '_')
        .replace(/\s+/g, '-')
        .replace(/[^A-Za-z0-9_-]/g, m => {
            return m.charCodeAt(0).toString();
        })}`;
};
export class ColumnHintPlugin extends ColumnRefreshPluginBase<
    GridWrapper,
    DataGridState,
    ColumnHintState
> {
    protected static requiredModules: ModuleIdentfier[] = [];
    private originalValueGetters: Map<
        string,
        string | null | ((params: ValueGetterParams) => any)
    > = new Map();

    constructor(wrapper: GridWrapper) {
        super(
            Plugins.ColumnHintPlugin,
            Categories.View,
            mainIcon,
            wrapper,
            state => state.columnHint
        );

        this.actions = [
            {
                action: OpenGridConfiguration(Plugins.ColumnHintPlugin),
                componentId: 'ColumnFormattingShortcut' as DataGridToolbarItem,
                icon: mainIcon,
                label: 'Column Formatting',
                store: wrapper.getReduxStore(),
                context: wrapper.getReduxStoreContext(),
            },
        ];

        this.screens = [
            {
                componentId,
                icon: mainIcon,
                label: 'Column Formatting',
                screen: ColumnHintPluginModal,
                store: wrapper.getReduxStore(),
                context: wrapper.getReduxStoreContext(),
            },
        ];

        this.onColumnResizeDebounced = debounce(this.onColumnResize, 300);
    }

    private onColumnResizeDebounced: () => void;

    private hasListenerForHeaderColumnWrapping: boolean = false;

    private cssClasses = columnHintStylesheet.mount(this, {});

    private redIfNegativeClassName =
        COLUMN_HINT_RED_IF_NEGATIVE +
        ' ' +
        getRedNegativeClassNames({ cssClasses: this.cssClasses });

    public destroy(): void {
        columnHintStylesheet.unmount(this);
    }

    protected stateChangedOrStart(): void {
        const previousPluginState = this.getPreviousPluginState();
        const previousColumnHintsLength = previousPluginState
            ? previousPluginState.configItemList.length
            : 0;
        const previousDefaults = previousPluginState ? previousPluginState.defaults : null;
        const pluginState = this.getPluginState();
        // Hints haven't changed so no need to update them
        if (
            pluginState &&
            pluginState.configItemList ===
                (previousPluginState && previousPluginState.configItemList) &&
            pluginState.defaults === (previousPluginState && previousPluginState.defaults)
        ) {
            return;
        }

        this.removeColumnHints();
        this.addColumnHints();
        if (
            previousColumnHintsLength !== 0 ||
            pluginState?.configItemList.length !== 0 ||
            isEmpty(previousDefaults) ||
            isEmpty(pluginState?.defaults) ||
            this.shouldForceRefresh()
        ) {
            this.setRefreshComplete();
            this.wrapper.refreshGrid();
        }
    }

    private getOriginalValueGetters(
        colId: string
    ): string | null | ((params: ValueGetterParams) => any) | undefined {
        const originalValueGetter = this.wrapper.getColumnValueGetter(colId);

        if (!this.originalValueGetters.has(colId)) {
            this.originalValueGetters.set(colId, originalValueGetter || null);
        }

        return this.originalValueGetters.get(colId);
    }

    protected internalStop(): void {
        super.internalStop();
        this.removeColumnHints();
    }

    private removeColumnHints(): void {
        const previousPluginState = this.getPreviousPluginState();

        if (previousPluginState !== null) {
            previousPluginState.configItemList.forEach((columnHint, index) => {
                // if grid is in pivot mode, get the updated pivoted column id
                const columnId = this.wrapper.getPivotedColumnId(
                    columnHint.columnId,
                    columnHint.pivotKeys
                );
                this.wrapper.removeCellClassRule(
                    columnId,
                    `${COLUMN_HINT_PREFIX}-${this.wrapper.getId()}-${index}`
                );

                if (columnHint.hints.cssClasses) {
                    this.wrapper.removeCellClassRule(columnId, columnHint.hints.cssClasses);
                }
                if (columnHint.hints.styleColumn) {
                    this.wrapper.removeColumnStyle(columnId);
                }

                if (columnHint.hints.format) {
                    // remove the negative colour if redIfNegative is now false
                    if (columnHint.hints.format.redIfNegative) {
                        this.wrapper.removeCellClassRule(
                            columnHint.columnId,
                            this.redIfNegativeClassName
                        );
                    }
                    this.wrapper.removeFormatter(columnId);
                    // Scoped removing the formatter to hyperlink so that we don't remove the bar renderer and heatmap renderer
                    if (columnHint.hints.format.hyperlinkFormat) {
                        removeHyperlinkRenderer(this.wrapper, columnId);
                    }
                }
            });
            /**
             * Handles removing the hints for defaults
             */
            if (previousPluginState.defaults && !isEmpty(previousPluginState.defaults)) {
                const stringDefaults = previousPluginState.defaults.stringDefaults;
                const numberDefaults = previousPluginState.defaults.numberDefaults;
                const dateDefaults = previousPluginState.defaults.dateDefaults;
                const dateTimeDefaults = previousPluginState.defaults.dateTimeDefaults;
                const defaults = previousPluginState.defaults;
                const currentDefaults = this.getPluginState()?.defaults;

                // check if column header wrapping
                if (currentDefaults && !currentDefaults.columnHeaderWrapping) {
                    this.removeColumnHeaderWrapping();
                }

                const columnList = this.wrapper.getReduxStore().getState().grid.columnList;
                columnList.forEach(column => {
                    this.wrapper.removeCellClassRule(
                        column.columnId,
                        `${DEFAULT_COLUMN_HINT_PREFIX}-${this.wrapper.getId()}`
                    );
                    this.wrapper.removeCellClassRule(
                        column.columnId,
                        `${DEFAULT_COLUMN_HINT_PREFIX}-${column.dataType}-${this.wrapper.getId()}`
                    );
                    if (stringDefaults && stringDefaults.cssClasses) {
                        this.wrapper.removeCellClassRule(
                            column.columnId,
                            stringDefaults.cssClasses
                        );
                    }
                    // remove the negative colour if redIfNegative is now false
                    if (numberDefaults?.format?.redIfNegative) {
                        this.wrapper.removeCellClassRule(
                            column.columnId,
                            this.redIfNegativeClassName
                        );
                    }
                    if (numberDefaults && numberDefaults.cssClasses) {
                        this.wrapper.removeCellClassRule(
                            column.columnId,
                            numberDefaults.cssClasses
                        );
                    }
                    if (dateDefaults && dateDefaults.cssClasses) {
                        this.wrapper.removeCellClassRule(column.columnId, dateDefaults.cssClasses);
                    }
                    if (dateTimeDefaults && dateTimeDefaults.cssClasses) {
                        this.wrapper.removeCellClassRule(
                            column.columnId,
                            dateTimeDefaults.cssClasses
                        );
                    }
                    if (defaults.cssClasses) {
                        this.wrapper.removeCellClassRule(column.columnId, defaults.cssClasses);
                    }

                    this.wrapper.removeCellClassRule(
                        column.columnId,
                        'column-hint-vertical-grid-lines'
                    );
                    this.wrapper.removeColumnStyle(column.columnId);

                    // Remove the formatter if there is a format applied
                    if (this.shouldRemoveDefaultFormatter(defaults, column)) {
                        this.wrapper.removeFormatter(column.columnId);
                    }
                    // TODO: Need to work out how to handle removing cell renderer given that it's used for bar and heatmap rendering
                    // this.wrapper.removeCellRenderer(column.columnId);
                });
            }
        }
    }

    private shouldRemoveDefaultFormatter = (
        defaultHints: ColumnHintDefaults,
        column: GridColumn
    ) => {
        if (defaultHints) {
            const stringDefaults = defaultHints.stringDefaults;
            const numberDefaults = defaultHints.numberDefaults;
            const dateDefaults = defaultHints.dateDefaults;
            const dateTimeDefaults = defaultHints.dateTimeDefaults;

            if (
                (column.dataType === DataType.String &&
                    this.hintListHasFormatter(stringDefaults)) ||
                (column.dataType === DataType.Number &&
                    this.hintListHasFormatter(numberDefaults)) ||
                (column.dataType === DataType.Date && this.hintListHasFormatter(dateDefaults)) ||
                (column.dataType === DataType.DateTime &&
                    this.hintListHasFormatter(dateTimeDefaults)) ||
                this.hintListHasFormatter(defaultHints)
            ) {
                return true;
            }
        }
        return false;
    };

    private hintListHasFormatter = (hintList: HintList | undefined): boolean => {
        return !!(hintList && hintList.format);
    };

    private createCellClassRuleFunc(columnHint: ColumnHint): (params: CellClassParams) => boolean {
        // if grid is in pivot mode, get the updated pivoted column id
        const columnId = this.wrapper.getPivotedColumnId(columnHint.columnId, columnHint.pivotKeys);

        return cellClassParams => {
            return cellClassParams.colDef
                ? cellClassParams.colDef.colId === columnId ||
                      cellClassParams.colDef.field === columnId
                : false;
        };
    }

    private addColumnHeaderWrapping(): void {
        if (!this.hasListenerForHeaderColumnWrapping) {
            this.wrapper.appendHeaderClass('gs-uitk-datagrid-column-header-wrapping');
            this.wrapper.addColumnResizeListener(this.onColumnResizeDebounced);
            this.hasListenerForHeaderColumnWrapping = true;
            this.onColumnResize();
        }
    }

    private removeColumnHeaderWrapping(): void {
        if (this.hasListenerForHeaderColumnWrapping) {
            this.wrapper.removeAppendedHeaderClass('gs-uitk-datagrid-column-header-wrapping');
            this.wrapper.removeColumnResizeListener(this.onColumnResizeDebounced);
            this.hasListenerForHeaderColumnWrapping = false;
        }
    }

    private onColumnResize = () => {
        this.setHeaderHeight();
    };

    /**
     * Sets the height of the header based on the largest column header.
     */
    private setHeaderHeight = () => {
        const height = this.getColumnHeaderHeight();
        this.wrapper.setColumnHeaderHeights(height);
    };

    /**
     * Returns the height of the highest header
     */
    private getColumnHeaderHeight() {
        const columnHeaderTexts = [
            ...Array.prototype.slice.call(
                document.querySelectorAll(
                    '.ag-header-cell .ag-header-cell-label .ag-header-cell-text'
                )
            ),
        ];
        const clientHeights = columnHeaderTexts.map(headerText => headerText.clientHeight);
        const tallestHeaderTextHeight = Math.max(...clientHeights);

        if (tallestHeaderTextHeight <= 11) {
            // one line
            return 24;
        }
        if (tallestHeaderTextHeight <= 22) {
            // two lines
            return 34;
        }
        // three lines
        return 44;
    }

    private addColumnHints(): void {
        if (this.getPluginState()?.defaults) {
            this.addDefaultHints();
        }

        this.getPluginState()?.configItemList.forEach(columnHint => {
            // if grid is in pivot mode, get the updated pivoted column id
            const columnId = this.wrapper.getPivotedColumnId(
                columnHint.columnId,
                columnHint.pivotKeys
            );
            if (this.getPluginState()) {
                this.wrapper.addCellClassRule(
                    columnId,
                    `${COLUMN_HINT_PREFIX}-${this.wrapper.getId()}-${this.getPluginState()?.configItemList.findIndex(
                        myColumnHint => columnHint === myColumnHint
                    )}`,
                    this.createCellClassRuleFunc(columnHint)
                );
            }

            if (columnHint.hints.enabled != null) {
                this.wrapper.setColumnVisibilty(columnId, columnHint.hints.enabled as boolean);
            }
            if (columnHint.hints.name) {
                let headerName = columnHint.hints.name;
                if (columnHint.hints.groupingDelimiter) {
                    headerName = String(
                        arrayHelper.last(headerName.split(columnHint.hints.groupingDelimiter))
                    );
                }
                this.wrapper.setHeaderName(columnId, headerName);
            }

            if (columnHint.hints.columnWidth) {
                this.wrapper.setColumnWidth(columnId, columnHint.hints.columnWidth);
            }

            if (columnHint.hints.cssClasses) {
                this.wrapper.addCellClassRule(
                    columnId,
                    columnHint.hints.cssClasses,
                    this.createCellClassRuleFunc(columnHint)
                );
            }

            if (columnHint.hints.headerTooltip) {
                this.wrapper.setHeaderTooltip(columnId, columnHint.hints.headerTooltip);
            }

            if (columnHint.hints.tooltip) {
                this.wrapper.setTooltip(columnId, columnHint.hints.tooltip);
            }

            if (columnHint.hints.styleColumn) {
                const styleColumn = columnHint.hints.styleColumn;
                this.wrapper.setColumnStyle(columnId, params => {
                    if (!params.api) {
                        logger.error('Cannot access ag-grid API', params);
                        return {};
                    }
                    const stringHintList: string = params.api.getValue(styleColumn, params.node);
                    const hintList = columnHintHelper.getHintList(stringHintList);
                    return columnHintHelper.getStyles(hintList);
                });
            }

            if (columnHint.hints.format && Object.entries(columnHint.hints.format).length > 0) {
                const column = this.wrapper
                    .getReduxStore()
                    .getState()
                    .grid.columnList.find(col => col.columnId === columnId);
                if (column) {
                    this.handleFormatHint(column.columnId, columnHint.hints.format);
                }
            }

            if (columnHint.hints.editable != null) {
                this.wrapper.setColumnEditable(columnId, columnHint.hints.editable);
            }
            if (columnHint.hints.bgHeaderGroups && columnHint.hints.groupingDelimiter) {
                const groupsColors = columnHint.hints.bgHeaderGroups.split(
                    columnHint.hints.groupingDelimiter
                );
                const groupSplit = columnId.split(columnHint.hints.groupingDelimiter);
                for (let index = 0; index < groupsColors.length; index = index + 1) {
                    const groupId = groupSplit
                        .slice(0, index + 1)
                        .join(columnHint.hints.groupingDelimiter);
                    const groupClassName = `${COLUMN_HINT_PREFIX}-${this.wrapper.getId()}-group-${formatGroupedColumn(
                        groupId
                    )}`;
                    index === groupsColors.length - 1
                        ? this.wrapper.setHeaderClass(groupId, groupClassName)
                        : this.wrapper.setHeaderGroupClass(groupId, groupClassName);
                }
            }
        });
    }

    private addDefaultHints() {
        const allColumns = this.wrapper.getReduxStore().getState().grid.columnList;
        const defaults = this.getPluginState()?.defaults;

        allColumns.map((column, index) => {
            const columnId = column.columnId;

            this.wrapper.addCellClassRule(
                columnId,
                `${DEFAULT_COLUMN_HINT_PREFIX}-${this.wrapper.getId()}`,
                () => true
            );

            this.wrapper.addCellClassRule(
                columnId,
                `${DEFAULT_COLUMN_HINT_PREFIX}-${column.dataType}-${this.wrapper.getId()}`,
                () => true
            );
            if (defaults) {
                // check if column header wrapping
                if (defaults.columnHeaderWrapping) {
                    this.addColumnHeaderWrapping();
                }

                const nameValue = this.getDefaultProperty('name', column, defaults);
                if (nameValue) {
                    this.wrapper.setHeaderName(columnId, nameValue as string);
                }

                const columnWidthValue = this.getDefaultProperty('columnWidth', column, defaults);
                if (columnWidthValue) {
                    this.wrapper.setColumnWidth(columnId, columnWidthValue as number);
                }

                const cssClassesValue = this.getDefaultProperty('cssClasses', column, defaults);
                if (cssClassesValue) {
                    this.wrapper.addCellClassRule(columnId, cssClassesValue as string, () => true);
                }

                const headerTooltipValue = this.getDefaultProperty(
                    'headerTooltip',
                    column,
                    defaults
                );
                if (headerTooltipValue) {
                    this.wrapper.setHeaderTooltip(columnId, headerTooltipValue as string);
                }

                const tooltipValue = this.getDefaultProperty('tooltip', column, defaults);
                if (tooltipValue) {
                    this.wrapper.setTooltip(columnId, tooltipValue as string);
                }

                const formatHint = this.getDefaultProperty('format', column, defaults);
                if (formatHint) {
                    this.handleFormatHint(columnId, formatHint as ColumnHintFormat);
                }

                const verticalGridLines = this.getDefaultProperty(
                    'verticalGridLines',
                    column,
                    defaults
                );
                // Note: We do not want to apply the css class to the first column.
                // Hence we only apply the css class to any column grater than 0.
                if (verticalGridLines && index > 0) {
                    this.wrapper.addCellClassRule(
                        columnId,
                        'column-hint-vertical-grid-lines',
                        () => true
                    );
                }
            }
        });
    }

    private getDefaultProperty(property: string, column: GridColumn, defaults: ColumnHintDefaults) {
        if (defaults.dateDefaults) {
            if (column.dataType === DataType.Date && defaults.dateDefaults[property]) {
                return defaults.dateDefaults[property];
            }
        }
        if (defaults.dateTimeDefaults) {
            if (column.dataType === DataType.DateTime && defaults.dateTimeDefaults[property]) {
                return defaults.dateTimeDefaults[property];
            }
        }
        if (defaults.numberDefaults) {
            if (column.dataType === DataType.Number && defaults.numberDefaults[property]) {
                return defaults.numberDefaults[property];
            }
        }
        if (defaults.stringDefaults) {
            if (column.dataType === DataType.String && defaults.stringDefaults[property]) {
                return defaults.stringDefaults[property];
            }
        }
        if (defaults[property]) {
            return defaults[property];
        }
        return null;
    }

    private handleFormatHint(columnId: string, columnHintFormat: ColumnHintFormat) {
        const column = this.wrapper
            .getReduxStore()
            .getState()
            .grid.columnList.find(col => col.columnId === columnId);
        if (column) {
            const formatter = buildColumnHintFormatter(
                columnHintFormat,
                column.dataType === DataType.Date ||
                    column.dataType === DataType.DateTime ||
                    columnHintFormat.type === ColumnHintType.Date,
                column.dataType
            );
            this.wrapper.setFormatter(columnId, formatter);
            if (columnHintFormat.redIfNegative) {
                this.wrapper.addCellClassRule(
                    column.columnId,
                    this.redIfNegativeClassName,
                    params => params.value < 0
                );
            }
            if (columnHintFormat.specialNumbersFormat) {
                const specialNumbersFormat = columnHintFormat.specialNumbersFormat;
                this.addCellClassRuleForSpecialNumberFormat(
                    column.columnId,
                    param => {
                        const lowerCaseValue = String(param.value).toLowerCase();
                        return /^(-?infinity)$/.test(lowerCaseValue);
                    },

                    specialNumbersFormat.infinity
                );
                this.addCellClassRuleForSpecialNumberFormat(
                    column.columnId,
                    param => {
                        return Number.isNaN(param.value);
                    },

                    specialNumbersFormat.nan
                );
                this.addCellClassRuleForSpecialNumberFormat(
                    column.columnId,
                    param => param.value == null,

                    specialNumbersFormat.null
                );
                this.addCellClassRuleForSpecialNumberFormat(
                    column.columnId,
                    param => param.value === '',

                    specialNumbersFormat.empty
                );
                this.addCellClassRuleForSpecialNumberFormat(
                    column.columnId,
                    param => param.value === undefined,

                    specialNumbersFormat.undefined
                );
                this.addCellClassRuleForSpecialNumberFormat(
                    column.columnId,
                    param => {
                        const lowerCaseValue = String(param.value).toLowerCase();
                        return parseFloat(lowerCaseValue) === 0;
                    },

                    specialNumbersFormat.zero
                );
            }
            // if column hint is for hyperlinks then add the hyperlink cell render to that column
            if (columnHintFormat.hyperlinkFormat) {
                const originalValueGetter = this.getOriginalValueGetters(column.columnId);
                addHyperlinkRenderer(
                    this.wrapper,
                    column.columnId,
                    columnHintFormat.hyperlinkFormat,
                    originalValueGetter || undefined
                );
            }
        }
    }

    private addCellClassRuleForSpecialNumberFormat(
        columnId: string,
        func: (params: CellClassParams) => boolean,
        cellFormat?: CellFormat
    ) {
        if (cellFormat && cellFormat.cssClass) {
            this.wrapper.addCellClassRule(columnId, cellFormat.cssClass, func);
        }
    }
}
