import {
    ColumnHintFormat,
    logger,
    SpecialNumbersFormat,
    NumberNamedPattern,
    DateNamedPattern,
    DateFormat,
    DataType,
} from '@gs-ux-uitoolkit-common/datacore';
import { format } from 'd3-format';
import { timeFormat, utcFormat } from 'd3-time-format';

export const buildColumnHintFormatter = (
    columnHintFormat: ColumnHintFormat,
    isDate: boolean,
    columnDataType: DataType
): ((value: any) => string) => {
    return (value: any) => {
        try {
            const specialNumbersFormattedValue = specialNumbersFormatForValue(
                value,
                columnHintFormat.specialNumbersFormat
            );
            if (specialNumbersFormattedValue) {
                return specialNumbersFormattedValue;
            }
            if (value == null) {
                return value;
            }
            if (columnHintFormat.valueMapping) {
                const replacementItem = columnHintFormat.valueMapping.find(
                    item => String(value) === String(item.value)
                );
                if (replacementItem) return replacementItem.replacement;
            }
            if (/^(NaN|null|-?Infinity|undefined)$/i.test(value)) {
                return '';
            }
            let preProcessedValue = isDate ? new Date(value) : value;
            if (
                columnHintFormat.preProcessor === 'text' &&
                columnHintFormat.preProcessorD3Specifier
            ) {
                const d3Formatter = timeFormat(columnHintFormat.preProcessorD3Specifier);
                preProcessedValue = d3Formatter(value);
            } else if (columnHintFormat.preProcessor === 'seconds') {
                preProcessedValue = new Date(value * 1000);
            }

            let formattedValue = String(preProcessedValue);
            if (columnHintFormat.multiplier) {
                const numberValue = parseFloat(preProcessedValue);
                if (!isFinite(numberValue)) {
                    logger.error(
                        `The value ${preProcessedValue} is not a number. Cannot apply the column hints multiplier`
                    );
                    return '';
                }
                preProcessedValue = numberValue * columnHintFormat.multiplier;
            }
            if (columnHintFormat.d3Specifier || columnHintFormat.namedSpecifier || isDate) {
                let d3Specifier = columnHintFormat.d3Specifier || (isDate ? '%d %b %H:%M' : '');
                if (columnHintFormat.namedSpecifier) {
                    d3Specifier = getNamedFormatter(columnHintFormat.namedSpecifier);
                }
                if (!isDate) {
                    const d3Formatter = format(d3Specifier);
                    formattedValue = d3Formatter(preProcessedValue);
                } else {
                    if (
                        (isNaN(preProcessedValue) || !(preProcessedValue instanceof Date)) &&
                        columnHintFormat.invalidDateText !== undefined
                    ) {
                        formattedValue = columnHintFormat.invalidDateText;
                    } else {
                        let d3Formatter = timeFormat(d3Specifier);

                        // For dates prefer timeFormat
                        if (columnDataType === DataType.Date) {
                            d3Formatter =
                                columnHintFormat.dateFormat === DateFormat.UTC
                                    ? utcFormat(d3Specifier)
                                    : timeFormat(d3Specifier);
                        }

                        // For date time prefer utcFormat
                        if (columnDataType === DataType.DateTime) {
                            d3Formatter =
                                columnHintFormat.dateFormat === DateFormat.Local
                                    ? timeFormat(d3Specifier)
                                    : utcFormat(d3Specifier);
                        }
                        formattedValue = d3Formatter(preProcessedValue);
                    }
                }
            } else {
                formattedValue = preProcessedValue;
            }

            if (columnHintFormat.currencySymbol) {
                formattedValue = formattedValue.replace('$', columnHintFormat.currencySymbol);
            }

            if (columnHintFormat.parenthesesIfNegative && value < 0) {
                // If the formattedValue does not start with `-` we still want to addd a `(`
                // For the use case of rounding a number greater than -0.5
                // More details https://jira.site.gs.com/browse/UX-9014
                formattedValue = `(${String(formattedValue).replace('-', '')})`;
            }

            if (columnHintFormat.prefix) {
                formattedValue = columnHintFormat.prefix + formattedValue;
            }

            if (columnHintFormat.postfix) {
                formattedValue = formattedValue + columnHintFormat.postfix;
            }
            // The returned formattedValue should always be a string
            return formattedValue + '';
        } catch (error: any) {
            logger.warn('Error formatting the value', value, columnHintFormat);
            return value;
        }
    };
};

const specialNumbersFormatForValue = (
    value: any,
    specialNumbersFormat?: SpecialNumbersFormat
): string | undefined => {
    const lowerCaseValue = String(value).toLowerCase();
    if (specialNumbersFormat) {
        if (
            specialNumbersFormat.infinity &&
            specialNumbersFormat.infinity.text &&
            /^(-?infinity)$/.test(lowerCaseValue)
        ) {
            return specialNumbersFormat.infinity.text;
        }
        if (
            specialNumbersFormat.null &&
            specialNumbersFormat.null.text &&
            lowerCaseValue === 'null'
        ) {
            return specialNumbersFormat.null.text;
        }
        if (
            specialNumbersFormat.empty &&
            specialNumbersFormat.empty.text &&
            lowerCaseValue === ''
        ) {
            return specialNumbersFormat.empty.text;
        }
        if (
            specialNumbersFormat.undefined &&
            specialNumbersFormat.undefined.text &&
            lowerCaseValue === 'undefined'
        ) {
            return specialNumbersFormat.undefined.text;
        }
        if (
            specialNumbersFormat.zero &&
            specialNumbersFormat.zero.text &&
            parseFloat(lowerCaseValue) === 0
        ) {
            return specialNumbersFormat.zero.text;
        }
        if (specialNumbersFormat.nan && specialNumbersFormat.nan.text && lowerCaseValue === 'nan') {
            return specialNumbersFormat.nan.text;
        }
    }
    return;
};

export const getNamedFormatter = (name: NumberNamedPattern | DateNamedPattern): string => {
    switch (name) {
        case DateNamedPattern.Date:
            return '%d%b%y';
        case DateNamedPattern.DateTime:
            return '%d%b %H:%M';
        case DateNamedPattern.Time:
            return '%H:%M';
        case DateNamedPattern.DayMonthYear:
            return '%e%b %Y';
        case NumberNamedPattern.Currency:
            return '$.2f';
        case NumberNamedPattern.ZeroPercentage:
            return '.0%';
        case NumberNamedPattern.OnePercentage:
            return '.1%';
        case NumberNamedPattern.TwoPercentage:
            return '.2%';
        case NumberNamedPattern.ThreePercentage:
            return '.3%';
        case NumberNamedPattern.FourPercentage:
            return '.4%';
        case NumberNamedPattern.LargeNumber:
            return ',.0f';
        case NumberNamedPattern.ZeroDP:
            return '.0f';
        case NumberNamedPattern.OneDP:
            return '.1f';
        case NumberNamedPattern.TwoDP:
            return '.2f';
        case NumberNamedPattern.ThreeDP:
            return '.3f';
        case NumberNamedPattern.FourDP:
            return '.4f';
        case NumberNamedPattern.FiveDP:
            return '.5f';
        default:
            throw new Error('Unrecgonised formatter');
    }
};
