export const analyticsEventName = {
  LOGIN_PAGE_CREATE_PASSWORD_BUTTON: 'login_page_create_password',
  CREATE_PASSWORD_PAGE_HELP_LINK: 'login_page_create_password_help',
  PASSWORD_CREATE: 'password_create'
};

export const getCreatePwdAnalytics = (initTime, status = 'success') => {
  return ({
    event : {
      eventName : analyticsEventName.PASSWORD_CREATE,
      pass_time_taken : `${(new Date().getTime() - initTime)/1000} seconds`,
      pass_status : status
    }
  });
};
