/* eslint-disable jsx-a11y/no-onchange */
// TODO: https://jira.site.gs.com/browse/UX-14773

import { globalHelper } from '@gs-ux-uitoolkit-common/datacore';
import { ColorPicker, ColorPickerSelectorMode } from '@gs-ux-uitoolkit-react/color-picker';
import { Row, Col } from '@gs-ux-uitoolkit-react/layout';
import { Form, FormGroup } from '@gs-ux-uitoolkit-react/form';
import { Label } from '@gs-ux-uitoolkit-react/label';

import { Component } from 'react';
import { ColumnSelector } from '../../../../components/column-selector/column-selector';
import { GridColumn } from '../../../../grid-wrappers/grid-wrapper';
import { FontSizes, FontStyles, RowCustomisation } from '../../row-customisation';
import { RowCustomisationPreviewer } from '../row-customisation-previewer';
import { rowCustomisationHelper } from '../../row-customisation-helper';
import { colors } from '@gs-ux-uitoolkit-common/design-system';
import { Select, SelectChangeEvent, SelectOption } from '@gs-ux-uitoolkit-react/select';
import { Link, Text } from '@gs-ux-uitoolkit-react/text';

/**
 * The props for RowCustomisationStyleBuilder
 */
export interface RowCustomisationStyleBuilderProps {
    /**
     * Columns from the grid
     */
    columns: GridColumn[];
    /**
     * RowCustomisation data
     */
    data: RowCustomisation;
    /**
     * Update the state with the new data from the state
     */
    updatePartialData: (newData: Partial<RowCustomisation>) => void;
}

/**
 * Component to build the style
 */
export class RowCustomisationStyleBuilder extends Component<RowCustomisationStyleBuilderProps> {
    public render() {
        const { data, columns } = this.props;
        const fontStyle = data.style.fontStyle;
        const selectedStyle = fontStyle.bold
            ? FontStyles.Bold
            : fontStyle.italic
              ? FontStyles.Italic
              : fontStyle.underline
                ? FontStyles.Underline
                : '';

        const style = (data || {}).style || {};
        const foreColor = style.foreColor || colors.black;
        const backColor = style.backColor || colors.white;

        function getTextSizesOptions(): SelectOption[] {
            const selectOptions: SelectOption[] = [];

            for (const [label, value] of Object.entries(FontSizes)) {
                selectOptions.push({ value, label });
            }

            return selectOptions;
        }

        return (
            <Form preventSubmit={true}>
                <Row data-cy="gs-uitk-row-customisation-style-builder-container">
                    <Col md={3} sm={6} xs={12}>
                        <FormGroup>
                            <Label>Text Color</Label>
                            <div className="gs-uitk-color-setter">
                                <ColorPicker
                                    mode={ColorPickerSelectorMode.FormChip}
                                    onSelect={this.onChangeForeColor}
                                    selectedColor={foreColor}
                                />
                                <Link onClick={() => this.onChangeForeColor('')}>Unset</Link>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col md={3} sm={6} xs={12}>
                        <FormGroup>
                            <Label>Background</Label>
                            <div className="gs-uitk-color-setter">
                                <ColorPicker
                                    mode={ColorPickerSelectorMode.FormChip}
                                    onSelect={this.onChangeBackColor}
                                    selectedColor={backColor}
                                />
                                <Link onClick={() => this.onChangeBackColor('')}>Unset</Link>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col md={3} sm={6} xs={12}>
                        <FormGroup>
                            <Label>Text Style</Label>
                            <Select
                                name="row-customisation-builder-text-style"
                                className="gs-uitk-html-select"
                                onChange={(e: SelectChangeEvent) => {
                                    this.updateFontStyle(e.selectedValue);
                                }}
                                selectedValue={selectedStyle}
                                options={[
                                    {
                                        value: '',
                                        label: 'None',
                                    },
                                    {
                                        value: FontStyles.Bold,
                                        label: 'Bold',
                                    },
                                    {
                                        value: FontStyles.Italic,
                                        label: 'Italic',
                                    },
                                    {
                                        value: FontStyles.Underline,
                                        label: 'Underline',
                                    },
                                ]}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3} sm={6} xs={12}>
                        <FormGroup>
                            <Label>Text Size</Label>
                            <Select
                                className="gs-uitk-html-select"
                                onChange={this.onChangeFontSize}
                                name="row-customisation-builder-text-size"
                                selectedValue={data.style.fontStyle.fontSize}
                                options={getTextSizesOptions()}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6} sm={6} xs={12}>
                        <FormGroup>
                            <ColumnSelector
                                data-cy="gs-uitk-row-customisation-style-builder-column-selector"
                                autoSize={true}
                                columns={columns}
                                selectedColumnId={
                                    rowCustomisationHelper.getPivotedApplyToColumnId(
                                        data,
                                        this.props.columns
                                    ) || ''
                                }
                                onChange={this.onChangeColumn}
                                labelText={'Apply Only To'}
                            />
                            <Text color={'secondary'} typography={'body03'}>
                                Optional
                            </Text>
                        </FormGroup>
                    </Col>
                    <Col md={6} sm={6} xs={12}>
                        <FormGroup>
                            <Label>Row Customisation Preview</Label>
                            <RowCustomisationPreviewer style={data.style} />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        );
    }

    private onChangeBackColor = (color: string) => {
        this.updateStyleColor(color, 'backColor');
    };
    private onChangeForeColor = (color: string) => {
        this.updateStyleColor(color, 'foreColor');
    };
    private onChangeFontSize = (e: SelectChangeEvent) => {
        const newStyle = globalHelper.cloneObject(this.props.data.style);
        newStyle.fontStyle.fontSize = e.selectedValue as FontSizes;
        this.props.updatePartialData({ style: newStyle });
    };
    private onChangeColumn = (columnId: string) => {
        const gridColumn =
            this.props.columns.find(col => {
                return col.columnId === columnId;
            }) || null;
        const isPivoted = !!(gridColumn && gridColumn.pivotKeys && gridColumn.pivotKeys.length);
        if (gridColumn || !columnId) {
            let gridColumnId;

            // if its pivoted then use primary column id
            if (gridColumn && isPivoted && gridColumn.primaryColumnId) {
                gridColumnId = gridColumn.primaryColumnId;
            } else if (gridColumn) {
                gridColumnId = gridColumn.columnId;
            }

            this.props.updatePartialData({
                columnId: gridColumnId,
                pivotKeys: isPivoted && gridColumn ? gridColumn.pivotKeys : undefined,
            });
        }
    };

    private updateStyleColor(value: string, styleType: 'foreColor' | 'backColor') {
        const newStyle = globalHelper.cloneObject(this.props.data.style);
        newStyle[styleType] = value;
        this.props.updatePartialData({ style: newStyle });
    }

    private updateFontStyle(styleType: string | null) {
        const newStyle = globalHelper.cloneObject(this.props.data.style);
        newStyle.fontStyle.bold = styleType === FontStyles.Bold;
        newStyle.fontStyle.italic = styleType === FontStyles.Italic;
        newStyle.fontStyle.underline = styleType === FontStyles.Underline;
        this.props.updatePartialData({ style: newStyle });
    }
}
