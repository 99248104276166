import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { calcOverlayStyle } from './Utils';

// TODO: will separate Popover Overlay from the reference element in future sprint

const defaultButton = ({buttonClass, handleToggle, disabled = false}) => (
  <button
    data-testid="popover-btn"
    disabled={disabled}
    className={buttonClass}
    onClick={handleToggle}
  />
);

defaultButton.propTypes = {
  buttonClass: PropTypes.string,
  handleToggle: PropTypes.func,
  disabled: PropTypes.bool
};

export const PopOverFactory = (WrappedRef = defaultButton) => {
  class PopOverComp extends React.Component {
    constructor() {
      super();
      this.state = {
        isOpen: false
      };
      this.elementRef = React.createRef();
      this.overlayRef = React.createRef();

      this.addStyle = this.addStyle.bind(this);
      this.close = this.close.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);
      this.open = this.open.bind(this);
      this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
      document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
      document.removeEventListener('click', this.handleClickOutside, true);
    }

    addStyle(ref, styles) {
      Object.keys(styles).forEach((key) => {
        ref.style[key] = styles[key];
      });
    }

    close() {
      this.setState({isOpen: false});
    }

    handleClickOutside(event) {
      if (this.state.isOpen) {
        const overlayRef = this.overlayRef.current;
        const elementRef = this.elementRef.current;

        if (overlayRef && !overlayRef.contains(event.target) && !elementRef.contains(event.target)) {
          this.close();
        }
      }
    }

    open() {
      this.setState({isOpen: true}, () => {
        const { overlayOffset, placement, alignment } = this.props;
        const elementRef = this.elementRef.current;
        const overlayRef = this.overlayRef.current;
        const overlayStyle = calcOverlayStyle(elementRef, overlayRef, overlayOffset, placement, alignment);
        this.addStyle(overlayRef, overlayStyle);
      });
    }

    toggle() {
      if(this.state.isOpen) {
        this.close();
      } else {
        this.open();
      }
    }
    render() {
      const { isOpen } = this.state;
      const { children, buttonClass, placement, disabled } = this.props;

      return (
        <div className="popover" data-testid="popover-comp" >
          <div className="popover__container" ref={this.elementRef}>
            <WrappedRef
              disabled={disabled}
              buttonClass={`${buttonClass} ${isOpen ? 'active':''}`}
              handleToggle={this.toggle} />
          </div>
          <div className={`${isOpen ? 'popover--open' : 'popover--close'}`} >
            <div className={`popover__overlay popover__overlay--${placement}`} ref={this.overlayRef}>
              {children}
            </div>
          </div>
        </div>
      );
    }
  }

  PopOverComp.propTypes = {
    placement: PropTypes.string, // overlay placement
    alignment: PropTypes.string, // overlay alignment
    buttonClass: PropTypes.string,
    children: PropTypes.any.isRequired,
    overlayOffset: PropTypes.object,
    disabled: PropTypes.bool
  };

  PopOverComp.defaultProps = {
    placement: 'bottom',
    alignment: 'right',
    disabled: false,
    overlayOffset: { // in pixels based on direction that is offset
      x: 0,
      y: 0
    }
  };

  return PopOverComp;
};

const PopOver = PopOverFactory();
export default PopOver;
