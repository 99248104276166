import { BigNumber } from 'bignumber.js';

function sum(params) {
  let x = new BigNumber(0);
  if (Object.prototype.toString.call(params) === '[object Array]' && params.length > 0) {
    params.forEach( (value) => {
      if (value && typeof(value) == 'string' && (value !== '' && value !== '--')) {
        x = x.plus(Number(value.replace(/,/g, ''))).dp(2);
      } else {
        console.log('Entered Else for = ', value);
      }
    });
  }
  else if (params && params.value) {
    params.values.forEach( (value) => {
      if (value && value !== '' ) {
        x = x.plus(value);
      }
    });
  }
  return x;
}

// Register all the group column functions here.
export default {
  total: sum
};
