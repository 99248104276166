import { Component, Context } from 'react';
import { connect, ReactReduxContextValue } from 'react-redux';
import { WidgetContentCommonProps } from '@gs-ux-uitoolkit-common/datacore';
import { DataGridState } from '../../../../redux/datagrid-state';
import { Density, Size } from '@gs-ux-uitoolkit-common/design-system';
import { SetSize, SetDensity } from '../../../../redux/actions/grid-configuration-action';
import { Dropdown, DropdownMenu } from '@gs-ux-uitoolkit-react/input';
import {
    Button,
    ButtonSelect,
    ButtonGroup,
    ButtonSelectChangeEvent,
} from '@gs-ux-uitoolkit-react/button';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { Text } from '@gs-ux-uitoolkit-react/text';
import { ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';
import { sizeAndDensityStyleSheet } from '../../../../style/plugins/size-density-stylesheet';

let id = 0;
const getId = () => {
    return id++;
};

interface SizeAndDensityWidgetComponentReduxProps {
    size?: Size;
    density?: Density;
    setSize?: (size: Size) => void;
    setDensity?: (density: Density) => void;
}

export interface SizeAndDensityWidgetComponentProps
    extends SizeAndDensityWidgetComponentReduxProps,
        WidgetContentCommonProps {}
export interface SizeAndDensityState {
    dropdownIsOpen: boolean;
}
/**
 * The SizeAndDensity Plugin Widget Component
 */
export class SizeAndDensityWidgetComponent extends Component<
    SizeAndDensityWidgetComponentProps,
    SizeAndDensityState
> {
    constructor(props: SizeAndDensityWidgetComponentProps) {
        super(props);

        this.state = {
            dropdownIsOpen: false,
        };
    }

    private sizeName = getId();
    private densityName = getId();
    private onSizeChange = (event: ButtonSelectChangeEvent) => {
        const size = (event.target as HTMLInputElement).value as Size;
        if (this.props.setSize) {
            this.props.setSize(size);
        }
    };

    private onDensityChange = (event: ButtonSelectChangeEvent) => {
        const density = (event.target as HTMLInputElement).value as Density;
        if (this.props.setDensity) {
            this.props.setDensity(density);
        }
    };

    private onBlur = () => this.setState({ dropdownIsOpen: false });

    private setDropDownState = () =>
        this.setState({
            dropdownIsOpen: !this.state.dropdownIsOpen,
        });
    public render() {
        const size = this.props.widgetContentCommonProps?.size || 'sm';
        return (
            <ThemeConsumer>
                {theme => {
                    const cssClasses = sizeAndDensityStyleSheet.mount(this, theme);
                    return (
                        <div>
                            <Dropdown menuVisible={this.state.dropdownIsOpen}>
                                <Button
                                    size={size}
                                    emphasis="minimal"
                                    onClick={this.setDropDownState}
                                >
                                    <Icon name="text-fields" type="outlined"></Icon>
                                </Button>
                                <DropdownMenu
                                    className={cssClasses.root}
                                    onBlur={this.onBlur}
                                    value=""
                                >
                                    <div>
                                        <Text color={'secondary'} typography={'body03'}>
                                            Size
                                        </Text>
                                        <div style={{ marginBottom: '8px' }}>
                                            <ButtonGroup size={size}>
                                                <ButtonSelect
                                                    type="single"
                                                    name={this.sizeName + 'size'}
                                                    value="sm"
                                                    onChange={this.onSizeChange}
                                                    defaultSelected={this.props.size === 'sm'}
                                                >
                                                    Small
                                                </ButtonSelect>
                                                <ButtonSelect
                                                    type="single"
                                                    name={this.sizeName + 'size'}
                                                    value="md"
                                                    onChange={this.onSizeChange}
                                                    defaultSelected={this.props.size === 'md'}
                                                >
                                                    Medium
                                                </ButtonSelect>
                                                <ButtonSelect
                                                    type="single"
                                                    name={this.sizeName + 'size'}
                                                    value="lg"
                                                    onChange={this.onSizeChange}
                                                    defaultSelected={this.props.size === 'lg'}
                                                    disabled={this.props.density === 'extraCompact'}
                                                >
                                                    Large
                                                </ButtonSelect>
                                            </ButtonGroup>
                                        </div>
                                        <Text color={'secondary'} typography={'body03'}>
                                            Density
                                        </Text>
                                        <div style={{ marginBottom: '8px' }}>
                                            <ButtonGroup size={size}>
                                                <ButtonSelect
                                                    type="single"
                                                    name={this.densityName + 'density'}
                                                    value="extraCompact"
                                                    onChange={this.onDensityChange}
                                                    disabled={this.props.size === 'lg'}
                                                    defaultSelected={
                                                        this.props.density === 'extraCompact'
                                                    }
                                                >
                                                    X-Compact
                                                </ButtonSelect>
                                                <ButtonSelect
                                                    type="single"
                                                    name={this.densityName + 'density'}
                                                    value="compact"
                                                    onChange={this.onDensityChange}
                                                    defaultSelected={
                                                        this.props.density === 'compact'
                                                    }
                                                >
                                                    Compact
                                                </ButtonSelect>
                                                <ButtonSelect
                                                    type="single"
                                                    name={this.densityName + 'density'}
                                                    value="standard"
                                                    onChange={this.onDensityChange}
                                                    defaultSelected={
                                                        this.props.density === 'standard'
                                                    }
                                                >
                                                    Standard
                                                </ButtonSelect>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    );
                }}
            </ThemeConsumer>
        );
    }
}

const mapStateToProps = (state: DataGridState) => {
    return {
        size: state.gridConfiguration.size,
        density: state.gridConfiguration.density,
    };
};

const mapDispatchToProps = (dispatch: (action: any) => void) => {
    const setSize = (size: Size) => {
        dispatch(SetSize(size));
    };
    const setDensity = (density: Density) => {
        dispatch(SetDensity(density));
    };
    return {
        setSize,
        setDensity,
    };
};

export const SizeAndDensityWidget = (context?: Context<ReactReduxContextValue>) => {
    return connect(mapStateToProps, mapDispatchToProps, null, { context })(
        SizeAndDensityWidgetComponent
    );
};
