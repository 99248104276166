import { AUTOCOMPLETE_ORG_SEARCH, CLEARING, INTERMEDIARY, SUBSIDIARY } from '../../../constants';

const relationshipsValueGetter = (relationships = [], config, i) => {
  if (relationships.length) {
    const data = relationships.filter(data => data.relationshipType === config.dataType);
    if (config.dataType === CLEARING) {
      if (data && data.length) {
        return data.find((_, index) => index === i);
      }
    }
    return data && data.length === 1 && data[0];
  }
};

const relationshipsValueFormatter = value => {
  return value && value.organizationName;
};

const getClearingAgent = () => {
  const clearingAgentArray = [];
  for (let index = 1; index <= 10; index++) {
    clearingAgentArray.push({
      id: 9 + index,
      label: 'tkClearingAgentField',
      value: `CLEARING ${index}`,
      dataType: CLEARING,
      canDeleteField: true,
      valueGetter: relationshipsValueGetter,
      valueFormatter: relationshipsValueFormatter,
      editableFieldType: AUTOCOMPLETE_ORG_SEARCH,
      index,
    });
  }
  return clearingAgentArray;
};

export const profileConfig = {
  identifyingInformationConfig: [
    {
      id: 1,
      label: 'tkOrganizationName',
      value: 'organizationName',
    },
    {
      id: 2,
      label: 'tkOrganizationId',
      value: 'organizationId',
    },
    {
      id: 3,
      label: 'tkCity',
      value: 'city',
    },
    {
      id: 4,
      label: 'tkState',
      value: 'state',
    },
    {
      id: 5,
      label: 'tkCountry',
      value: 'country',
    },
    {
      id: 6,
      label: 'tkChannel',
      value: 'channel',
    },
    {
      id: 7,
      label: 'tkCoverage',
      value: 'coverage',
    },
  ],
  orgRelationshipConfig: [
    {
      id: 8,
      label: 'tkSubsidiaryOf',
      value: SUBSIDIARY,
      dataType: SUBSIDIARY,
      valueGetter: relationshipsValueGetter,
      valueFormatter: relationshipsValueFormatter,
      editableFieldType: AUTOCOMPLETE_ORG_SEARCH,
    },
    {
      id: 9,
      label: 'tkIntermediary',
      value: INTERMEDIARY,
      dataType: INTERMEDIARY,
      valueGetter: relationshipsValueGetter,
      valueFormatter: relationshipsValueFormatter,
      editableFieldType: AUTOCOMPLETE_ORG_SEARCH,
    },
  ],
  clearingAgentConfig: [...getClearingAgent()],
  setupConfig: [
    {
      id: 20,
      label: 'tkStatus',
      value: 'status',
      activeIcon: true,
    },
    {
      id: 21,
      label: 'tkMakerCheckerApprovals',
      value: 'makerCheckerApprovals',
      dropdownKey: 'makeCheckerApprovals',
    },
  ],
  fundRebatesConfig: [
    {
      id: 22,
      label: 'tkShareClassRebates',
      value: 'shareClassRebates',
    },
    {
      id: 23,
      label: 'tkFundFamilyRebates',
      value: 'fundFamilyRebates',
    },
  ],
  changeManagementDetailsConfig: [
    {
      id: 24,
      label: 'tkCreationDate',
      value: 'creationDate',
    },
    {
      id: 25,
      label: 'tkLastModifiedDate',
      value: 'lastModifiedDate',
    },
  ],
};
