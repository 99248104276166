import { getOverflowStyles } from '@gs-ux-uitoolkit-common/shared';
import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';

import { CommonSelectProps, selectDefaultProps, SelectPlacement } from '../select-props';
import {
    baseInputStyles,
    commonContainerInnerStyles,
    disabledMenuOptionStyles,
    menuOptionStyles,
    getMenuStyles,
    getInputClonedStyles,
    getRemoveButtonStyles,
    getRootStyles,
    getSelectListSingleStyles,
    groupHeadingStyles,
    isLargeSize,
    isSmallSize,
    listStyles,
    placeholderStyles,
    selectableOptionStyles,
    SelectClassNames,
} from './common-styles';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './select-theme-overrides';

/**
 * Interface used to describe the Select CSS props.
 */
export interface SelectStyleSheetProps {
    /**
     * The size of the component
     */
    size: CommonSelectProps['size'];

    /**
     * Status of the component
     */
    status: CommonSelectProps['status'];

    /**
     * Determines if the component is searchable
     */
    searchable: CommonSelectProps['searchable'];

    /**
     * The CSS classes names used to style the component.
     */
    cssClassNames: SelectClassNames;

    /**
     * By default, the component displays as "block" and expands to the full
     * width of its container. To disable this behavior, set to true.
     */
    inline: CommonSelectProps['inline'];

    /**
     * The content that gets shown in the menu when no options are remaining in the select
     */
    noOptionsContent: CommonSelectProps['noOptionsContent'];

    /**
     * Where the dropdown menu should open
     */
    menuPlacement?: SelectPlacement;

    /**
     * The corresponding theme passed to the component
     */
    theme: Theme;
}

/**
 * Override the common padding style for select inner container
 */
function selectCommonContainerInnerPadding(
    status: SelectStyleSheetProps['status'] = 'none',
    isLargeSizeSelect?: boolean
) {
    if (isLargeSizeSelect) {
        return status && status !== 'none' ? '0 40px 0 0' : '0 26px 0 0';
    }
    return '0 26px 0 0';
}

/**
 * Overrides the common padding style of select text container
 */
const overrideItemSelectablePadding = (
    selector: string,
    size: SelectStyleSheetProps['size'],
    status: SelectStyleSheetProps['status']
) => {
    if (isLargeSize(size)) {
        return {
            [selector]: {
                padding: status && status !== 'none' ? '0 60px 0 0' : '0 40px 0 0',
            },
        };
    }
    if (isSmallSize(size)) {
        return {
            [selector]: {
                padding: status && status !== 'none' ? '0 45px 0 0' : '0 24px 0 0',
            },
        };
    }
    return {
        [selector]: {
            padding: status && status !== 'none' ? '0 48px 0 0' : '0 40px 0 0',
        },
    };
};

export interface SelectCssClasses {
    root: string;
}

export type SelectStyledClasses = CssClassDefinitionsObject<keyof SelectCssClasses>;

export interface SelectStyleOverridesParams {
    props: DeepReadonly<SelectStyleSheetProps>;
    createDefaultStyledClasses: () => SelectStyledClasses;
}

/**
 * Select Stylesheet
 */
export const selectStyleSheet = new StyleSheet('select', (props: SelectStyleSheetProps) => {
    return createComponentClassDefinitions<SelectStyleSheetProps, SelectStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.select
    );
});

function createDefaultStyledClasses({
    theme,
    size = selectDefaultProps.size,
    searchable = selectDefaultProps.searchable,
    status = selectDefaultProps.status,
    cssClassNames,
    inline,
    noOptionsContent,
    menuPlacement,
}: SelectStyleSheetProps): SelectStyledClasses {
    let selectHeight = '32px';
    let iconFontSize = '20px';
    let lineHeight;
    let currentTypographyVariant = { ...theme.typography.body02 };
    const isSmallSizeSelect = isSmallSize(size);
    const isLargeSizeSelect = isLargeSize(size);
    const selectListSingleStyle = getSelectListSingleStyles(size, cssClassNames);
    const singleSelectOptionStyles = selectableOptionStyles(cssClassNames, theme);
    const removeButtonStyles = getRemoveButtonStyles({
        size,
        rightOffset: '5px',
        isSingleSelect: true,
        status,
    });

    if (isSmallSizeSelect) {
        selectHeight = '24px';
        iconFontSize = '16px';
        // need the line height override to make this component sm size consistent height with other form components
        lineHeight = '22px';
        currentTypographyVariant = { ...theme.typography.body03 };
    } else if (isLargeSizeSelect) {
        selectHeight = '40px';
        iconFontSize = '24px';
        currentTypographyVariant = { ...theme.typography.body01 };
    }

    return {
        root: {
            display: inline ? 'inline-block' : 'block',
            [`.${cssClassNames.containerOuter}`]: getRootStyles({
                iconFontSize,
                selectHeight,
                searchable,
                size,
                isSingleSelect: true,
                cssClassNames,
                status,
                theme,
            }),
            [`.${cssClassNames.containerInner}`]: {
                ...currentTypographyVariant,
                ...commonContainerInnerStyles(selectHeight, lineHeight, status, theme),
                padding: selectCommonContainerInnerPadding(status, isLargeSizeSelect),
            },
            [`.${cssClassNames.itemDisabled}`]: disabledMenuOptionStyles(theme),
            [`.${cssClassNames.item}`]: {
                ...currentTypographyVariant,
                ...menuOptionStyles(theme),
                ...(noOptionsContent === ''
                    ? {
                          [`&.${cssClassNames.noChoices}`]: {
                              padding: '0!important',
                          },
                      }
                    : {}),
            },
            [`.${cssClassNames.groupHeading}`]: {
                ...theme.typography.heading07,
                ...groupHeadingStyles(theme),
            },
            [`.${cssClassNames.input}`]: {
                ...baseInputStyles('3px 0 4px 8px', size, currentTypographyVariant, theme),
                height: selectHeight,

                // Hide native 'x' button in IE
                '::-ms-clear': {
                    display: 'none',
                },
            },

            [`.${cssClassNames.flippedState} .${cssClassNames.listDropdown}`]: {
                bottom: '-4px',
            },
            [`.${cssClassNames.inputCloned}`]: getInputClonedStyles(
                currentTypographyVariant,
                theme
            ),
            [`.${cssClassNames.itemSelectable}`]: {
                ...singleSelectOptionStyles,
                [`&[data-item]`]: {
                    ...getOverflowStyles('truncate'),
                },
            },
            [`.${cssClassNames.list}`]: listStyles(cssClassNames, theme),
            // The block of code below limits the size of the select dropdown and pins the
            // input when a scroll bar comes up.
            [`.${cssClassNames.list}.${cssClassNames.listDropdown}`]: {
                maxHeight: '300px',
                backgroundColor: theme.elevation['05'].background,
                [`.${cssClassNames.list}`]: {
                    maxHeight: '250px',
                    overflow: 'auto',
                },
            },
            [`.${cssClassNames.listDropdown}`]: getMenuStyles({
                iconFontSize,
                selectHeight,
                searchable,
                size,
                isSingleSelect: true,
                cssClassNames,
                menuPlacement,
                theme,
            }),
            [`.${cssClassNames.listSingle}`]: {
                ...selectListSingleStyle,
                ...overrideItemSelectablePadding(`.${cssClassNames.itemSelectable}`, size, status),
            },
            [`.${cssClassNames.placeholder}`]: placeholderStyles(theme),
            [`.${cssClassNames.button}`]: {
                ...removeButtonStyles,
            },
        },
    };
}
