import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-react/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DraggableDragHandleProps, DragAndDropIconPlacement } from './draggable-props';
import { IconBaseProps } from '@gs-ux-uitoolkit-react/icon-font';
import { DeepReadonly } from 'ts-essentials';
import './drag-and-drop-handle-theme-overrides';

export type OverrideDragHandleClasses = Partial<Record<'dragHandle' | 'dragHandleIcon', string>>;

export type DragAndDropHandleProps = {
    draggableDragHandleProps?: DraggableDragHandleProps;

    iconPlacement?: DragAndDropIconPlacement;

    iconBaseProps?: IconBaseProps;

    /**
     * Style classes to override.
     */
    classes?: Partial<DragAndDropHandleCssClasses>;
};

export type DragAndDropHandleDefaultProps = Required<Pick<DragAndDropHandleProps, 'iconBaseProps'>>;

export interface DragAndDropHandleStyleSheetProps {
    theme: Theme;
    iconPlacement?: DragAndDropIconPlacement;
}

export interface DragAndDropHandleCssClasses {
    dragHandle: string;
    dragHandleIcon: string;
}

export type DragAndDropHandleStyledClasses = CssClassDefinitionsObject<
    keyof DragAndDropHandleCssClasses
>;

export interface DragAndDropHandleStyleOverridesParams {
    props: DeepReadonly<DragAndDropHandleStyleSheetProps>;
    createDefaultStyledClasses: () => DragAndDropHandleStyledClasses;
}

export const dragAndDropHandleDefaultProps: DragAndDropHandleDefaultProps = {
    iconBaseProps: {
        name: 'drag-handle',
        type: 'filled',
        size: 'md',
    },
};

export const dragAndDropHandleStyles = new StyleSheet(
    'drag-and-drop-handle',
    (props: DragAndDropHandleStyleSheetProps) => {
        return createComponentClassDefinitions<
            DragAndDropHandleStyleSheetProps,
            DragAndDropHandleStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.dragAndDropHandle);
    }
);

function createDefaultStyledClasses({
    theme,
    iconPlacement,
}: DragAndDropHandleStyleSheetProps): DragAndDropHandleStyledClasses {
    return {
        dragHandle: {
            display: 'flex',
            flexDirection: 'row',
            top: '0px',
            alignSelf:
                iconPlacement === 'center'
                    ? 'center'
                    : iconPlacement === 'top'
                      ? 'flex-start'
                      : 'flex-end',
            justifyContent: 'center',
            backgroundColor: 'transparent',

            margin: '-0.05rem',
            padding: '0.05rem',

            '&:hover': {
                backgroundColor: theme.getSurfaceInteractionShades('primary').hover,
            },
        },
        dragHandleIcon: {
            color: theme.text.secondary,
        },
    };
}
