import { cx } from '@gs-ux-uitoolkit-common/style';
import { AlertCommonProps } from './alert-props';
import { AlertCssClasses } from './alert-style-sheet';

const globalClassName = 'gs-alert';

export const getRootClassNames = (props: {
    cssClasses: AlertCssClasses;
    overrideClasses?: AlertCommonProps['classes'];
    className?: AlertCommonProps['className'];
}): string => {
    const { cssClasses, overrideClasses, className } = props;
    return cx(cssClasses.root, className, overrideClasses && overrideClasses.root, globalClassName);
};

export const getIconClassNames = (props: {
    cssClasses: AlertCssClasses;
    overrideClasses?: AlertCommonProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(cssClasses.icon, overrideClasses && overrideClasses.icon, `${globalClassName}__icon`);
};

export const getDismissButtonClassNames = (props: {
    cssClasses: AlertCssClasses;
    overrideClasses?: AlertCommonProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.dismissButton,
        overrideClasses && overrideClasses.dismissButton,
        `${globalClassName}__dismissButton`
    );
};
