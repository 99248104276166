import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { fontFamily } from '@gs-ux-uitoolkit-common/design-system';
import { rgba } from 'polished';
import { InputSize, TextareaResize } from './types';
import { DeepReadonly } from 'ts-essentials';
import './input-control-theme-overrides';

export interface InputControlStyleSheetProps {
    theme: Theme;
    size: InputSize;
}

export interface InputNumberControlStyleSheetProps {
    theme: Theme;
    size: InputSize;
}

export interface InputTextareaControlStyleSheetProps {
    theme: Theme;
    size: InputSize;
    resize?: TextareaResize;
}

export interface InputControlCssClasses {
    input: string;
}

export interface InputNumberControlCssClasses {
    input: string;
}

export interface InputTextAreaCssClasses {
    input: string;
}

export type InputControlStyledClasses = CssClassDefinitionsObject<keyof InputControlCssClasses>;
export type InputNumberControlStyledClasses = CssClassDefinitionsObject<
    keyof InputNumberControlCssClasses
>;
export type InputTextareaControlStyledClasses = CssClassDefinitionsObject<
    keyof InputTextAreaCssClasses
>;

export interface InputControlStyleOverridesParams {
    props: DeepReadonly<InputControlStyleSheetProps>;
    createDefaultStyledClasses: () => InputControlStyledClasses;
}
export interface InputNumberControlStyleOverridesParams {
    props: DeepReadonly<InputNumberControlStyleSheetProps>;
    createDefaultStyledClasses: () => InputNumberControlStyledClasses;
}
export interface InputTextareaControlStyleOverridesParams {
    props: DeepReadonly<InputTextareaControlStyleSheetProps>;
    createDefaultStyledClasses: () => InputTextareaControlStyledClasses;
}

export function getInputFontSizes(theme: Theme) {
    return {
        sm: theme.typography.body03.fontSize,
        md: theme.typography.body02.fontSize,
        lg: theme.typography.body01.fontSize,
    };
}

export const inputPaddingSize = {
    sm: '0 6px',
    md: '0 8px',
    lg: '0 10px',
};

export function getInputControlStyles({ theme }: { theme: Theme }) {
    return {
        borderWidth: 0,
        flexGrow: 1,
        outline: 0,
        color: theme.text.primary,
        backgroundColor: 'transparent',
        overflow: 'hidden',
        fontFamily: fontFamily.goldmanSans,
        letterSpacing: 0,
        lineHeight: 'normal',
        marginTop: 0,

        '&::placeholder': {
            color: theme.text.tertiary,
        },

        // Hide native 'x' button in IE
        '::-ms-clear': {
            display: 'none',
        },

        /**
         * Chrome v90 removed these native date styles, which highlighted the focused portion of the date text
         * Removing them caused a bug where users could no longer tell that a date field was editable.
         * This adds the text highlighting back with the GS Design System colors
         */
        [`&::-webkit-datetime-edit-ampm-field:focus,
        &::-webkit-datetime-edit-day-field:focus,
        &::-webkit-datetime-edit-hour-field:focus,
        &::-webkit-datetime-edit-millisecond-field:focus,
        &::-webkit-datetime-edit-minute-field:focus,
        &::-webkit-datetime-edit-month-field:focus,
        &::-webkit-datetime-edit-second-field:focus,
        &::-webkit-datetime-edit-week-field:focus,
        &::-webkit-datetime-edit-year-field:focus`]: {
            backgroundColor: rgba(theme.colorScheme.primary.toString(), 0.2),
            color: theme.text.primary,
            outline: 'none',
        },
    };
}

function getInputNumberControlStyles({ theme }: { theme: Theme }) {
    return {
        ...getInputControlStyles({ theme }),
        marginRight: '-2px',
        width: 'inherit',
    };
}

export const textareaLineHeights = {
    sm: '16px',
    md: '20px',
    lg: '24px',
};

export const textareaPadding = {
    sm: '8px 24px 8px 8px',
    md: '8px 32px 8px 8px',
    lg: '10px 40px 10px 12px',
};

function getInputTextareaControlStyles({ theme }: { theme: Theme }) {
    return {
        ...getInputControlStyles({ theme }),
        height: 'auto',
    };
}

export function getInputControlStyleSheet({ theme, size = 'md' }: InputControlStyleSheetProps): {
    input: any;
} {
    return {
        input: {
            ...getInputControlStyles({ theme }),
            padding: inputPaddingSize[size],
            fontSize: getInputFontSizes(theme)[size],
        },
    };
}

export const inputControlStyleSheet = new StyleSheet(
    'input-control',
    (props: InputControlStyleSheetProps) => {
        return createComponentClassDefinitions<
            InputControlStyleSheetProps,
            InputControlStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.inputControl);
    }
);
export const inputNumberControlStyleSheet = new StyleSheet(
    'input-number',
    (props: InputNumberControlStyleSheetProps) => {
        return createComponentClassDefinitions<
            InputNumberControlStyleSheetProps,
            InputNumberControlStyledClasses
        >(props, createDefaultStyledClassesNumber, props.theme.styleOverrides?.inputNumberControl);
    }
);
export const inputTextareaStyleSheet = new StyleSheet(
    'input-textarea',
    (props: InputTextareaControlStyleSheetProps) => {
        return createComponentClassDefinitions<
            InputTextareaControlStyleSheetProps,
            InputTextareaControlStyledClasses
        >(
            props,
            createDefaultStyledClassesTextarea,
            props.theme.styleOverrides?.inputTextareaControl
        );
    }
);

function createDefaultStyledClasses(props: InputControlStyleSheetProps): InputControlStyledClasses {
    return getInputControlStyleSheet(props);
}

function createDefaultStyledClassesNumber({
    theme,
    size = 'md',
}: InputNumberControlStyleSheetProps): InputNumberControlStyledClasses {
    return {
        input: {
            ...getInputNumberControlStyles({ theme }),
            padding: inputPaddingSize[size],
            fontSize: getInputFontSizes(theme)[size],
        },
    };
}

function createDefaultStyledClassesTextarea({
    theme,
    size = 'md',
    resize = 'both',
}: InputTextareaControlStyleSheetProps): InputTextareaControlStyledClasses {
    return {
        input: {
            ...getInputTextareaControlStyles({ theme }),
            fontSize: getInputFontSizes(theme)[size],
            lineHeight: textareaLineHeights[size],
            padding: textareaPadding[size],
            resize,
            overflow: 'auto',
        },
    };
}
