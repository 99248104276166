import { PopoverBaseProps } from './popover-base-props';
import { Props, Instance, Plugin } from 'tippy.js';
import { getTippyPlacement } from './translate-placement';
import { cx, CssClasses } from '@gs-ux-uitoolkit-common/style';

const POPOVER_MAX_WIDTH_MD = '320px';
const POPOVER_MAX_WIDTH_SM = '260px';
const OFFSET_DISTANCE = 5; // width of arrow

// inputs coming from the gs-popover/tooltip component
export interface GsPopoverInputs extends PopoverBaseProps {
    maxWidth?: Props['maxWidth'];
    onShow?: () => void;
    onHide?: () => void;
    onBeforeShow?: () => boolean | void;
    onBeforeHide?: () => boolean | void;
    onClickOutside?: () => boolean | void;
    size: string;
    id: string;
}

// tippy.js specific props returned from createTippyOptions()
export type TippyProps = Pick<
    Partial<Props>,
    | 'placement'
    | 'offset'
    | 'arrow'
    | 'delay'
    | 'trigger'
    | 'interactive'
    | 'animation'
    | 'plugins'
    | 'appendTo'
    | 'maxWidth'
    | 'onShow'
    | 'onHide'
    | 'onShown'
    | 'onHidden'
    | 'onClickOutside'
    | 'hideOnClick'
    | 'popperOptions'
    | 'aria'
>;

export function createTippyOptions(
    componentName: 'tooltip' | 'popover',
    inputs: GsPopoverInputs,
    cssClasses: CssClasses<any> // either PopoverCssClasses or TooltipCssClasses, which are forward references
): TippyProps {
    const gsPrefixedName = `gs-uitk-${componentName}`;

    const {
        id,
        size = 'md',
        placement = 'auto',
        showTip = true,
        showDelay = 0,
        hideDelay = 0,
        dismissible = false,
        flip = true,
        fade = false,
        container,
        classes,
        visible,
        onShow,
        onHide,
        onBeforeShow,
        onBeforeHide,
        onClickOutside,
    } = inputs;

    let triggers;

    // unless explicitly defined, set trigger as 'click' if either popover or dismissible
    if (inputs.triggers) {
        triggers =
            typeof inputs.triggers === 'string' ? inputs.triggers : inputs.triggers.join(' '); // tippy accepts space separated list of triggers
    } else if (visible === undefined) {
        triggers = dismissible || componentName === 'popover' ? 'click focus' : 'mouseenter focus';
    }

    const usePlacement = getTippyPlacement(placement);

    /**
     * Updates attributes on the popover container element.
     * Called at 'onCreate' hook of tippy popover.
     */
    const updateContainerAttrs: Plugin<TippyProps> = {
        fn: (instance: Instance) => {
            const container = instance.popper.firstElementChild!;
            const contentBox = container.querySelector('.tippy-content div')!;
            contentBox.setAttribute('data-cy', `${gsPrefixedName}__content`);
            const contentBoxClasses = cx(cssClasses.content, classes && classes.content);
            const arrowEl = container.querySelector('.tippy-arrow');
            return {
                onCreate: () => {
                    container.setAttribute('data-gs-uitk-component', componentName);
                    container.setAttribute('data-size', size);
                    container.setAttribute('id', id);
                    addClasses(contentBox, contentBoxClasses);
                    if (arrowEl) {
                        addClasses(
                            arrowEl,
                            cx(`${gsPrefixedName}__arrow`, classes && classes.arrow)
                        );
                    }
                },
            };
        },
    };

    /**
     * Enables hiding of popover using key bindings and when 'x' button is clicked
     * Called at 'onShow' hook of tippy popover.
     */
    const bindCloseEvents: Plugin<TippyProps> = {
        fn: (instance: Instance) => {
            let closeButton: Element | null = null;
            let referenceElement: Element | null = null;

            const closePopover = () => instance.hide();

            const closePopoverOnKeyPress = ({ key }: KeyboardEvent) => {
                if (key === 'Escape' || key === 'Enter') {
                    closePopover();
                }
            };

            const closeOtherPopovers = () => {
                document.querySelectorAll('[id*="tippy"]').forEach(popper => {
                    if (popper !== instance.popper) {
                        (popper as any)._tippy?.hide();
                    }
                });
            };
            return {
                onShow() {
                    closeButton = instance.popper.firstElementChild!.querySelector(
                        `[data-cy="${gsPrefixedName}__dismiss-button"]`
                    );
                    referenceElement = instance.reference;
                    closeButton?.addEventListener('click', closePopover);
                    referenceElement?.addEventListener('focusin', closeOtherPopovers);
                    document.addEventListener('keydown', closePopoverOnKeyPress);
                },
                onHide() {
                    closeButton?.removeEventListener('click', closePopover);
                    referenceElement?.removeEventListener('focusin', closeOtherPopovers);
                    document.addEventListener('keydown', closePopoverOnKeyPress);
                },
            };
        },
    };

    const tippyOptions: TippyProps = {
        // position popover a distance equal to height of arrow away from element
        offset: [0, OFFSET_DISTANCE],
        arrow: showTip,
        delay: [showDelay, hideDelay],
        placement: usePlacement,
        trigger: triggers,
        interactive: true,
        aria: {
            content: 'labelledby',
        },
        plugins: [updateContainerAttrs],
    };

    tippyOptions.plugins!.push(bindCloseEvents);

    if (dismissible) {
        tippyOptions.hideOnClick = false;
    }

    if (container) {
        tippyOptions.appendTo = container;
    }

    if (onBeforeShow) {
        // tippy.js accepts a return type of `false | void`
        // this wraps around the given `onBeforeShow` method
        // to satisfy the type.
        const onBeforeShowWrapper: () => false | void = () => {
            if (onBeforeShow() === false) {
                return false;
            }
            return;
        };
        tippyOptions.onShow = onBeforeShowWrapper;
    }
    if (onBeforeHide) {
        // tippy.js accepts a return type of `false | void`
        // this wraps around the given `onBeforeHide` method
        // to satisfy the type.
        const onBeforeHideWrapper: () => false | void = () => {
            if (onBeforeHide() === false) {
                return false;
            }
            return;
        };
        tippyOptions.onHide = onBeforeHideWrapper;
    }
    if (onHide) {
        tippyOptions.onHidden = onHide;
    }
    if (onShow) {
        tippyOptions.onShown = onShow;
    }
    if (onClickOutside) {
        tippyOptions.onClickOutside = onClickOutside;
    }

    tippyOptions.animation = fade ? 'fade' : false;

    // if flip is false, disable auto-flipping to other positions
    // by restricting fallback placements to the given placement
    if (!flip) {
        tippyOptions.popperOptions = {
            modifiers: [
                {
                    name: 'flip',
                    options: {
                        fallbackPlacements: [placement],
                    },
                },
            ],
        };
    }

    if (componentName === 'popover') {
        tippyOptions.maxWidth = size === 'sm' ? POPOVER_MAX_WIDTH_SM : POPOVER_MAX_WIDTH_MD;
    }

    return tippyOptions;
}

/**
 * Splits a space separated list of CSS class names
 * and adds to the element's classList
 */
export function addClasses(el: Element, classNames: string) {
    classNames.split(/\s+/).forEach((name: string) => {
        if (name) {
            el.classList.add(name);
        }
    });
}
