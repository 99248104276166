import { IconName } from './full-set';

export const v17ToV18IconMapping: {
    [key: string]: IconName;
} = {
    'play-circle-filled': 'play-circle',
    'play-circle-outline': 'play-circle',
    'person-add-alt-1': 'person-add',
    'person-remove-alt-1': 'person-remove',
    'no-cell': 'mobile-off',
    outbond: 'outbound',
    paste: 'content-paste',
};
