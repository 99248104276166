import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-react/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './drag-and-drop-placeholder-theme-overrides';

export interface DragAndDropPlaceholderStyleSheetProps {
    theme: Theme;
    spacing: number;
}

export interface DragAndDropPlaceholderCssClasses {
    placeholder: string;
    placeholderInner: string;
}

export type DragAndDropPlaceholderStyledClasses = CssClassDefinitionsObject<
    keyof DragAndDropPlaceholderCssClasses
>;

export interface DragAndDropPlaceholderStyleOverridesParams {
    props: DeepReadonly<DragAndDropPlaceholderStyleSheetProps>;
    createDefaultStyledClasses: () => DragAndDropPlaceholderStyledClasses;
}

export const dragAndDropPlaceholderStyleSheet = new StyleSheet(
    'drag-and-drop-placeholder',
    (props: DragAndDropPlaceholderStyleSheetProps) => {
        return createComponentClassDefinitions<
            DragAndDropPlaceholderStyleSheetProps,
            DragAndDropPlaceholderStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.dragAndDropPlaceholder);
    }
);

function createDefaultStyledClasses({
    theme,
    spacing,
}: DragAndDropPlaceholderStyleSheetProps): DragAndDropPlaceholderStyledClasses {
    return {
        placeholder: {
            position: 'absolute',
            boxSizing: 'border-box',
            display: 'flex',
            margin: spacing,
        },
        placeholderInner: {
            position: 'relative',
            backgroundColor: theme.getSurfaceInteractionShades('primary').hover,
            flexGrow: 1,
            borderRadius: 2,
        },
    };
}
