import {
    ExportColumnOptionType,
    ExportRowOptionType,
    ExportType,
} from '../../plugins/exports/export';
import { ExportsActionKeys } from '../actions/action-constants';
import { ExportsActionTypes } from '../actions/exports-action';
import { ExportState } from '../datagrid-state';

/**
 * Initial state of the Export State
 */
export const defaultExportState: ExportState = {
    options: {
        columnGroups: true,
        columnsToExport: ExportColumnOptionType.AllVisibleColumns,
        includeFormatting: false,
        includeStyles: false,
        indentPivotColumn: false,
        onlySelected: false,
        onlySelectedPages: false,
        rowsToExport: ExportRowOptionType.AllRows,
        selectedColumns: [],
        skipGroups: false,
        skipColumnHeaders: false,
        type: ExportType.Excel,
        fileName: 'export',
    },
};

/**
 * Reducer for the ExportState
 * @param state State of the ExportState
 * @param action Action received
 */
export const exportReducer = (
    state: ExportState = defaultExportState,
    action: ExportsActionTypes
): ExportState => {
    switch (action.type) {
        case ExportsActionKeys.SET_OPTIONS:
            return { ...state, options: action.options };
        default:
            return state;
    }
};
