import {
    IFloatingFilterComp,
    IFloatingFilterParams,
    FilterChangedEvent,
    NumberFilter,
    TextFilter,
    DateFilter,
} from '@ag-grid-community/core';
import { logger } from '@gs-ux-uitoolkit-common/datacore';
import { inputStyleSheet } from '@gs-ux-uitoolkit-common/input';
import { lightTheme } from '@gs-ux-uitoolkit-common/theme';
import { debounce, DebouncedFunc } from 'gs-uitk-lodash';
import { defaultDebounceTime } from '../grid-wrappers/grid-wrapper';
import { floatingFilterStyleSheet } from '../style/floating-filter/floating-filter-stylesheet';

export enum FloatingFilterLogicalOperator {
    And = '&&', //'Exp1 || Exp2 - matching expression 1 or expression 2\n' +
    Or = '||', //'Exp1 && Exp2 - matching expression 1 and expression 2';
}

export enum FloatingFilterOperators {
    GreaterThan = '>',
    GreaterThanOrEqual = '>=',
    LessThan = '<',
    LessThanOrEqual = '<=',
    Equals = '=',
    NotEquals = '!=',
    ContainsCaseSensitive = '~',
    NotContainsCaseSensitive = '!~',
    Contains = '%',
    NotContains = '!%',
    InRange = '::',
}

export const DRA_FLOATING_FILTER_PREFIX = '*DRA*';
export const DRA_FLOATING_FILTER_SEPARATOR = '|_|';
export const DRA_FLOATING_FILTER_OPERATOR_VALUE_SEPARATOR = '{_}';

export const FLOATING_FILTER_OPERATORS_REGEX = />=|<=|>|<|=|!=|%|!%|~|!~/;

export interface GSFloatingFilterParams {
    inputDebounceTime?: number;
}

const defaultInputDebounce: number = defaultDebounceTime;

export interface GSFloatingFilter {
    filterType: string;
    updateFilter(value: string): void;
}

export class GSFloatingFilterBase implements IFloatingFilterComp {
    private rootElement: HTMLElement;
    private inputElement: HTMLInputElement | null = null;
    public params: (IFloatingFilterParams & GSFloatingFilterParams) | undefined = undefined;
    private inputChangeHandler: DebouncedFunc<() => void> | undefined = undefined;
    private parentModelChangeHandler: DebouncedFunc<() => void> | undefined = undefined;
    private debounceTime: number = defaultInputDebounce;
    private isTyping: boolean = false;
    public filterType: string = '';

    constructor() {
        this.rootElement = document.createElement('div');
        this.inputElement = document.createElement('input');

        this.rootElement.classList.add('uitk-datagrid-floating-filter');
        this.inputElement.classList.add('uitk-datagrid-floating-filter-input');

        this.rootElement.appendChild(this.inputElement);

        const floatingFilterClasses = floatingFilterStyleSheet.mount(this.rootElement, null);
        this.rootElement.classList.add(floatingFilterClasses.root);
    }

    public destroy(): void {
        if (this.rootElement && this.inputElement) {
            //unmount the styles
            floatingFilterStyleSheet.unmount(this.rootElement);
            inputStyleSheet.unmount(this.rootElement);
            this.inputElement.removeEventListener('input', this.onInputChange);
        }
        if (this.inputChangeHandler) this.inputChangeHandler.cancel();
        if (this.parentModelChangeHandler) this.parentModelChangeHandler.cancel();
    }

    public init(params: IFloatingFilterParams) {
        this.params = params;
        // mount the styles
        const inputClasses = inputStyleSheet.mount(this.rootElement, {
            theme: params.context?.theme || lightTheme,
        });
        this.inputElement?.classList.add(inputClasses.root);

        this.setInputValueFromFilterModel(this.params.api.getFilterModel());

        this.debounceTime =
            this.params && this.params.inputDebounceTime !== undefined
                ? this.params.inputDebounceTime
                : defaultInputDebounce;

        if (this.inputElement) this.inputElement.addEventListener('input', this.onInputChange);
    }
    public onParentModelChanged(_parentModel: any, filterChangedEvent?: FilterChangedEvent): void {
        if (filterChangedEvent && !this.isTyping) {
            this.setInputValueFromFilterModel(filterChangedEvent.api.getFilterModel());
        }
    }

    public getInputValueWithOperator(operator: string, model: { [key: string]: any }): string {
        return `${operator}${this.getConditionValue(model)}`;
    }

    public getGui(): HTMLElement {
        return this.rootElement;
    }

    public getModel() {
        const columnId = this.params ? this.params.filterParams.column.getColId() : undefined;
        return columnId !== undefined && this.params
            ? this.params.api.getFilterModel()[columnId]
            : null;
    }

    public updateFilter(value: string): void {
        if (!this.params) return;
        if (this.isValueEmpty(value)) return;
        //override this method for each floating filter
    }

    public getConditionsSplitFromValue(value: string): string[] {
        return value.split(/(&&|\|\|)/);
    }

    // If value is empty return and also update the model
    public isValueEmpty(value: string): boolean {
        if (!this.params) return false;

        const columnId = this.params.filterParams.column.getColId();
        //if empty string then send it as a model
        if (columnId !== undefined && value.trim() === '') {
            const currentFilterModel = this.params.api.getFilterModel();
            const model = {
                ...currentFilterModel,
                [columnId]: null,
            };

            this.params.api.setFilterModel(model);

            return true;
        }
        return false;
    }
    public getConditionValue(condition: { [key: string]: any }): string | number {
        return condition.filter;
    }
    public getMultipleConditionalModel(
        columnId: string,
        currentFilterModel: { [key: string]: any },
        filterModels: { [key: string]: any }[]
    ): { [key: string]: any } {
        const condition1 = filterModels.shift();
        if (
            filterModels.length > 1 &&
            this.params &&
            this.params.api.getModel().getType() === 'viewport' &&
            this.filterType === 'text'
        ) {
            return {
                ...currentFilterModel,
                [columnId]: {
                    filterType: this.filterType,
                    operator: filterModels[0].condition,
                    condition1: {
                        type: condition1?.operator,
                        filter: condition1?.operandValue,
                    },
                    condition2: {
                        type: filterModels[0].operator,
                        filter: `${DRA_FLOATING_FILTER_PREFIX}${filterModels
                            .map(
                                x =>
                                    `${x.operator.trim()}${DRA_FLOATING_FILTER_OPERATOR_VALUE_SEPARATOR}${x.operandValue.trim()}`
                            )
                            .join(DRA_FLOATING_FILTER_SEPARATOR)}`,
                    },
                },
            };
        }
        if (filterModels.length > 1) {
            logger.error(
                'GSFloatingFilter with InMemory datasource supports a maximum of two criteria'
            );
        }
        return {
            ...currentFilterModel,
            [columnId]: {
                filterType: this.filterType,
                operator: filterModels[0].condition,
                condition1: {
                    type: condition1?.operator,
                    filter: condition1?.operandValue,
                },
                condition2: {
                    type: filterModels[0].operator,
                    filter: filterModels[0].operandValue,
                },
            },
        };
    }
    public getSingleConditionalModel(
        columnId: string,
        currentFilterModel: { [key: string]: any },
        filterModels: { [key: string]: any }[]
    ): { [key: string]: any } {
        return {
            ...currentFilterModel,
            [columnId]: {
                filterType: this.filterType,
                type: filterModels[0].operator,
                filter: filterModels[0].operandValue,
            },
        };
    }
    public setFilterModel(filterModels: { [key: string]: any }[]) {
        if (!this.params) return;
        const columnId = this.params.filterParams.column.getColId();
        if (columnId !== undefined) {
            const filterInstance = this.params.api.getFilterInstance(columnId);
            const currentFilterModel = this.params.api.getFilterModel();
            // if theres more than one condition
            if (filterModels.length > 1) {
                const model = this.getMultipleConditionalModel(
                    columnId,
                    currentFilterModel,
                    filterModels
                );
                this.params.api.setFilterModel(model);
                // if we have one filter
            } else if (filterModels.length) {
                const model = this.getSingleConditionalModel(
                    columnId,
                    currentFilterModel,
                    filterModels
                );
                this.params.api.setFilterModel(model);
                // otherwise empty the model
            } else {
                if (filterInstance) {
                    filterInstance.setModel(null);
                }
            }
        }
    }
    private onInputChange = (event: Event): void => {
        this.isTyping = true;
        if (this.inputChangeHandler) this.inputChangeHandler.cancel();
        if (this.parentModelChangeHandler) this.parentModelChangeHandler.cancel();

        this.inputChangeHandler = debounce((): void => {
            const inputEvent = event as InputEvent;
            const value = (<HTMLInputElement>inputEvent.target).value;

            this.updateFilter(value);
            this.isTyping = false;
        }, this.debounceTime);
        this.inputChangeHandler();
    };
    private getLogicalOperatorFromCondition(condition: string): string {
        if (condition === 'OR') return FloatingFilterLogicalOperator.Or;
        return FloatingFilterLogicalOperator.And;
    }

    private getFilterOperatorFromModel(operatorType: string): string {
        let operator = '';
        switch (operatorType) {
            case NumberFilter.GREATER_THAN:
                operator = FloatingFilterOperators.GreaterThan;
                break;
            case NumberFilter.GREATER_THAN_OR_EQUAL:
                operator = FloatingFilterOperators.GreaterThanOrEqual;
                break;
            case NumberFilter.LESS_THAN:
                operator = FloatingFilterOperators.LessThan;
                break;
            case NumberFilter.LESS_THAN_OR_EQUAL:
                operator = FloatingFilterOperators.LessThanOrEqual;
                break;
            case NumberFilter.EQUALS:
                operator = FloatingFilterOperators.Equals;
                break;
            case NumberFilter.NOT_EQUAL:
                operator = FloatingFilterOperators.NotEquals;
                break;
            case TextFilter.CONTAINS:
                operator = FloatingFilterOperators.Contains;
                break;
            case TextFilter.NOT_CONTAINS:
                operator = FloatingFilterOperators.NotContains;
                break;
            case DateFilter.IN_RANGE:
                operator = FloatingFilterOperators.InRange;
                break;
        }

        return operator;
    }
    private setInputValueFromFilterModel(model: { [key: string]: any }): void {
        const columnId = this.params && this.params.filterParams.column.getColId();
        if (columnId !== undefined && model[columnId]) {
            //check if its a conditional modal
            if (model[columnId].condition1) {
                // handle multiple conditions
                const conditionOneOperator = this.getFilterOperatorFromModel(
                    model[columnId].condition1.type
                );
                const conditionTwoOperator = this.getFilterOperatorFromModel(
                    model[columnId].condition2.type
                );
                const joinLogicalOperator = this.getLogicalOperatorFromCondition(
                    model[columnId].operator
                );
                const condition2Value = this.getConditionValue(model[columnId].condition2);
                let value = `${conditionOneOperator} ${this.getConditionValue(
                    model[columnId].condition1
                )}`;
                if (
                    typeof condition2Value === 'string' &&
                    condition2Value.startsWith(DRA_FLOATING_FILTER_PREFIX)
                ) {
                    condition2Value
                        .replace(DRA_FLOATING_FILTER_PREFIX, '')
                        .split(DRA_FLOATING_FILTER_SEPARATOR)
                        .forEach(entry => {
                            const operatorAndValue = entry.split(
                                DRA_FLOATING_FILTER_OPERATOR_VALUE_SEPARATOR
                            );
                            value = `${value} ${joinLogicalOperator} ${this.getFilterOperatorFromModel(
                                operatorAndValue[0]
                            )} ${operatorAndValue[1]}`;
                        });
                } else {
                    value = `${value} ${joinLogicalOperator} ${conditionTwoOperator} ${this.getConditionValue(
                        model[columnId].condition2
                    )}`;
                }

                if (this.inputElement) {
                    this.inputElement.value = value;
                }
            } else {
                const operator = this.getFilterOperatorFromModel(model[columnId].type);
                if (this.inputElement) {
                    const value = this.getInputValueWithOperator(operator, model[columnId]);
                    this.inputElement.value = value;
                }
            }
        } else {
            if (this.inputElement) {
                this.inputElement.value = '';
            }
        }
    }
}
