import React from 'react';
import PropTypes from 'prop-types';

const ValidationItem = ({className, iconStatus, customClass, lineText}) => {
  const classNameTheme = !customClass ?  'dropdown' : customClass;
  return (
    <div className={classNameTheme}>
      <span className={`${classNameTheme}__rowElement ${classNameTheme}__rowElement--left`}>
        <span className={`${classNameTheme}__icon validation-list__icon ${className} ${iconStatus}`}>&nbsp;</span>
      </span>
      <span className={`${classNameTheme}__rowElement ${classNameTheme}__rowElement__${iconStatus}`}>{lineText}</span>
    </div>
  );
};

ValidationItem.propTypes = {
  lineText: PropTypes.string,
  className: PropTypes.string,
  customClass: PropTypes.string,
  iconStatus: PropTypes.string
};

export default ValidationItem;

