import React from 'react';
import PropTypes from 'prop-types';
import TradeActivitySection from '../../containers/TradeActivity';

const TradeActivity = ({subRoutes}) => {
  return (
    <div className="js-main-content main-container__content tradeActivity">
      <section className="tradeActivity__wrapper">
        <TradeActivitySection subRoutes={subRoutes} />
      </section>
    </div>
  );
};

TradeActivity.propTypes = {
  subRoutes: PropTypes.array
};

export default TradeActivity;
