import React from "react";
import PropTypes from "prop-types";

  // eslint-disable-next-line
const escapeRegExp = (str) => str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");

const highlight = (input = '', regexes) => {
  if (!regexes.length) {
    return input;
  }
  const split = input.split(regexes[0]);
  const replacements = input.match(regexes[0]);
  const result = [];
  for (let i = 0; i < split.length - 1; i++) {
    result.push(highlight(split[i], regexes.slice(1)));
    result.push(<span key={i} className="hightlight-blue">{replacements[i]}</span>);
  }
  result.push(highlight(split[split.length - 1], regexes.slice(1)));
  return result;
};

const Highlighter = props => {
  const { input, keywords, isGreyText, isLastItem } = props;
  const regexes = keywords.map(word => new RegExp(escapeRegExp(word), "ig"));
  return (
    <>
      <span className={isGreyText ? 'hightlight-grey': ''}>
        {highlight(input, regexes)}
      </span>
      {!isLastItem && <span className="spacer">, </span>}
    </>
  );
};

Highlighter.propTypes = {
  input: PropTypes.any,
  keywords: PropTypes.array,
  isGreyText: PropTypes.bool,
  isLastItem: PropTypes.bool
};

export default Highlighter;
