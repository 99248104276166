import { InternalInputType, InputStatus } from './types';

const TEXT_TYPES = ['password', 'search', 'tel', 'text', 'url', 'email'];
const MULTIPLE_TYPES = ['email', 'file'];

/**
 * Contains utility methods shared between Angular and React Input components.
 */
export class InputStateViewModel {
    constructor(
        public type: InternalInputType,
        public status: InputStatus
    ) {}

    // Returns the type that the input control should actually be defined as to
    // achieve design goals. For type "search", for example, GSDS mandates a
    // specific "x" button and behavior, but setting type to "search" would
    // leave this UI to be rendered (or possibly not rendered) diffently in
    // each browser; solution is to set to "text" and render a custom UI.
    public getType() {
        return this.isSearch() ? 'text' : this.type;
    }

    public isSearch() {
        return this.type === 'search';
    }

    public isText() {
        return TEXT_TYPES.indexOf(this.type) !== -1;
    }

    public supportsMultiple() {
        return MULTIPLE_TYPES.indexOf(this.type) !== -1;
    }

    public isError() {
        return this.status === 'error';
    }

    public isWarning() {
        return this.status === 'warning';
    }

    public isSuccess() {
        return this.status === 'success';
    }

    public isLoading() {
        return this.status === 'loading';
    }
}
