import { MouseEvent } from 'react';
import { Link } from '@gs-ux-uitoolkit-react/text';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';

export interface AddExpressionButtonProps {
    ['data-cy']?: string;
    onClickCallback: (event: MouseEvent) => void;
    isGroup?: boolean;
}

export const AddExpressionButton = (props: AddExpressionButtonProps) => {
    return (
        <Link
            data-cy={props['data-cy']}
            size="sm"
            emphasis={'subtle'}
            onClick={props.onClickCallback}
        >
            <Icon name="add" type="filled" />
            {props.isGroup ? 'Add Group' : 'Add Rule'}
        </Link>
    );
};
