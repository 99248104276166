import React from 'react';
import PageHeader from '../../../../../components/app/PageHeader';
import translator from '../../../../../services/translator';
const {translate: t} = translator;

const Header = () => (
  <PageHeader
    title={t('tkNavMenuFundTracker')}
    description={t('tkFundTrackerSubHeader')}
  />
);

export default Header;
