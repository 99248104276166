import { NotificationActionKeys } from '../actions/action-constants';
import { NotificationActionTypes } from '../actions/notification-action';
import { NotificationState } from '../datagrid-state';

/**
 * Initial state of the Notification State
 */
export const defaultNotificationState: NotificationState = {
    notificationList: [],
    maxDisplayedItems: 10,
};

/**
 * Reducer for the NotificationState
 * @param state State of the NotificationState
 * @param action Action received
 */

export const notificationReducer = (
    state: NotificationState = defaultNotificationState,
    action: NotificationActionTypes
): NotificationState => {
    switch (action.type) {
        case NotificationActionKeys.ADD_NOTIFICATION:
            return {
                ...state,
                notificationList: [...state.notificationList, action.notificationItem],
            };
        case NotificationActionKeys.REMOVE_NOTIFICATION:
            return {
                ...state,
                notificationList: state.notificationList.filter(x => x !== action.notificationItem),
            };
        case NotificationActionKeys.REMOVE_ALL_NOTIFICATION:
            return {
                ...state,
                notificationList: [],
            };
        case NotificationActionKeys.REMOVE_ALL_DISMISSABLE_NOTIFICATION:
            return {
                ...state,
                notificationList: state.notificationList.filter(x => !x.dismissible),
            };
        default:
            return state;
    }
};
