import { isEqual } from 'gs-uitk-lodash';

/**
 * Represents the state of a plugin that manages a list of config items and have a wizard
 */
export interface PluginWithWizardAndItemsState<ConfigDataType>
    extends PluginWithItemsState<ConfigDataType>,
        PluginWithWizardState<ConfigDataType> {}

/**
 * Represents the state of a plugin that manages a list of config items
 */
export interface PluginWithItemsState<ConfigDataType> {
    /**
     * the list of items
     */
    configItemList: ConfigDataType[];
}

export interface PluginWithWizardState<ConfigDataType> {
    /**
     * The cloned edited data
     */
    editedItem: ConfigDataType | null;
    /**
     * The original edited item
     */
    originalEditedItem: ConfigDataType | null;
}

/**
 * helper function to decide ifthe plugin should trigger the update
 * the reason is that for most plugins the process is easy we remove the
 * old configItems then we add the new ones with a forEach so it doesn't matter
 * For the filter plugins it's slightly different as we just refresh Filter on the grid
 * and then the grid will find out the filters. So we want to avoid unnecessary
 * grid refresh filter
 * @param previousPluginState
 * @param currentPluginState
 */
export function shouldTriggerUpdateForPluginWithItems<T extends PluginWithItemsState<any>>(
    previousPluginState: T | null,
    currentPluginState: T
) {
    if (
        // if there is no previous state and in the current one I've got configItems
        (!previousPluginState && currentPluginState.configItemList.length !== 0) ||
        // If there is a previous state then either previous or current state should have a config item
        (previousPluginState &&
            !isEqual(previousPluginState.configItemList, currentPluginState.configItemList))
    ) {
        return true;
    }
    return false;
}
