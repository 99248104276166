import isEmpty from 'lodash/isEmpty';
import Highcharts from 'highcharts/highstock';
import moment from "moment/moment";
import { cloneDeep, memoize } from 'lodash';
import ChartColorsInstance from './chartColors';
import {FUND_TRACKER} from '../../../constants/pageConstants';
import {ChartActionTypes} from '../actions';
import {addMetricToSeries, fetchFundsOrBenchmarksData, fetchShareClassPerformanceData} from "../services";
import {amountFormatter} from "../../../utils/amountFormatter";

export const orderShareclassPerformance = (shareclassPerfResult, shareclassOrder, keys) => {
  if (isEmpty(keys)) return shareclassPerfResult;

  const keysArr = keys.split(".");
  const getValue = (data, keyArr, index = 0) => {
    if (keyArr.length === index) return data;

    const key = keyArr[index];
    const curValue = data[key];
    return getValue(curValue, keyArr, index + 1);
  }

  const shareclassNotFoundInOrderList = new Set();
  const sortFunc = (resultA, resultB) => {
    const valueA = getValue(resultA, keysArr);
    const valueB = getValue(resultB, keysArr);
    const indexA = shareclassOrder.indexOf(valueA);
    const indexB = shareclassOrder.indexOf(valueB);
    if (indexA === -1) shareclassNotFoundInOrderList.add(valueA);
    if (indexB === -1) shareclassNotFoundInOrderList.add(valueB);
    return indexA - indexB;
  }

  let orderedResult = cloneDeep(shareclassPerfResult);
  orderedResult.sort(sortFunc);

  if (shareclassNotFoundInOrderList.size > 0) {
    orderedResult = orderedResult.slice(shareclassNotFoundInOrderList.size);
  }
  return orderedResult;
}

export const getHighChartSeries = (data, id = '', yAxisLabel = '', isSecondaryYAxis = false) => {
  const {points, shareclass: {longName} = {}, benchmarkPerformance: {category} = {}} = data;
  let noData = true;
  const seriesPoint = points.reduce((result, point) => {
    const { x, y } = point;
    const time = new Date(x).getTime();
    if (point.y) {
      noData = false;
      result.push([ time, Number(y) ]);
    } else if (noData) {
      result.push([ time, undefined ]);
    }
    return result;
  }, []);
  seriesPoint.sort((a, b) => {
    if (a[0] < b[0]) {
      return -1;
    } else if (a[0] > b[0]) {
      return 1;
    }
    return 0;
  });

  const color = ChartColorsInstance.getSingleChartColor() || '#077d55';
  const secondaryYAxis = isSecondaryYAxis ? {yAxis: 1} : {};
  const additionalConfig = yAxisLabel ? {...secondaryYAxis, legendItem: {textStr: yAxisLabel}} : {};
  const chartSeries = {
    name: (yAxisLabel || longName || category),
    id,
    data: seriesPoint,
    color,
    noData,
    ...additionalConfig,
    showInNavigator: true
  };
  ChartColorsInstance.setUsedColor(color);
  return chartSeries;
};

export const getAsOfDate = (data) => {
  const presentDate = moment(new Date()).format("YYYY-MM-DD");
  if(data.length) {
    for(let i = data.length-1; i>=0; i--){
      if(data[i][1]) return moment(new Date(data[i][0])).format("YYYY-MM-DD")
    }
  }
  return presentDate;
};

// Entries which are present in currentArray and not in prevArray.
const diffArrays = (curArray, prevArray, compareFunction) => {
  return curArray.filter(curValue =>
    !prevArray.some(prevValue => compareFunction(curValue, prevValue)));
};
export const getNormalizedGridData = (shareClassPerformanceData) => (
  shareClassPerformanceData.map((item)=> {
      const {
        nav: {
          nav,
          mtmNav
        } = {},
        assets: {
          totalFundAssets,
          dailyNetShareholderFlowsPercent,
          dailyNetShareholderFlows
        } = {},
        liquidity: {
          liquidAssetsDaily,
          liquidAssetsWeekly
        } = {},
        duration: {
          wal,
          wam
        } = {},
        name,
        identifiers: {
          cusip,
          isin
        } = {},
        performance: {
          dailyFactor,
          yield1Day,
          yield7DayCurrent,
          yield7DayEffective,
          yield30Day
        } = {}
      } = item;

      return {
        shareClassName: name,
        nav: nav && nav.amount || "--",
        mtmNav: mtmNav && mtmNav.amount|| "--",
        dailyFactor: dailyFactor || "--",
        yield1Day: yield1Day || "--",
        curYield7Day: yield7DayCurrent || "--",
        effYield7Day: yield7DayEffective || "--",
        yield30Day: yield30Day || "--",
        liquidAssetsDaily: liquidAssetsDaily || "--",
        liquidAssetsWeekly: liquidAssetsWeekly || "--",
        wam: wam || "--",
        wal: wal || "--",
        totalFundAssets: totalFundAssets && `${amountFormatter(totalFundAssets.amount, null, true)} ${totalFundAssets.currency}` || "--",
        dailyNetShareholderFlowsPercent : dailyNetShareholderFlowsPercent || "--",
        dailyNetShareholderFlows: dailyNetShareholderFlows && amountFormatter(dailyNetShareholderFlows.amount, null, true) || "--",
        cusip: cusip || "--",
        isin: isin || "--"
      };
    }
  )
  );

export const setMetricBenchmarksToState = (dispatch, data) => {
  dispatch({
    type: ChartActionTypes.setMetricBenchmarksToState,
    data
  });
};

export const updateShareClassListToState = (dispatch, allShareClassData) => {
  dispatch({
    type: ChartActionTypes.updateShareClassList,
    data: {
      allShareClassData,
      replaceShareClassData: true
    }
  });
};

export const updateChartOptions = (dispatch, updateChartOptions) => {
  dispatch({
    type: ChartActionTypes.updateChartOptions,
    data: {
      chartOptions: updateChartOptions
    }
  });
};

export const updateShareclassPerformanceData = (dispatch, { results, asOfDate }) => (
  dispatch({
    type: ChartActionTypes.setShareClassPerformanceData,
    data: {results, asOfDate}
  })
)

export const setFieldForQuery = (dispatch, defaultMetricItem) => {
  const {label: fieldLabel, value: fieldValue} = defaultMetricItem;
  dispatch({
    type: ChartActionTypes.setFieldForQuery,
    data: {
      fieldForQuery: {
        fieldLabel,
        fieldValue
      }
    }
  });
};

export const clearDataMissingMessages = (dispatch) => {
  dispatch({
    type: ChartActionTypes.isMetricDataMissing,
    data: {
      isMetricDataMissing: false
    }
  });
};

export const removeChartSeriesColor = (chartInstance, id) => {
  const chart = chartInstance.get(id);
  if (chart) {
    const {color} = chart;
    chart.remove(true);
    ChartColorsInstance.removeUsedColor(color);
  }
};

export const getBenchmarkFieldValue = (benchmarkMetricsOptions, field) => {
  const {benchmarkMetricOption} = benchmarkMetricsOptions.find(({defaultMetricOption}) =>
    defaultMetricOption === field
  ) || {};
  return benchmarkMetricOption;
};

export const removeFundOrBenchmarkSeriesFromChart = (fundOrBenchmarksToRemove, field, chartInstance, chartOptions, benchmarkMetricsOptions = []) => {
  const benchmarkFieldId = getBenchmarkFieldValue(benchmarkMetricsOptions, field);
  const fundOrBenchmarksIdList = fundOrBenchmarksToRemove.map(({value, type}) => {
    const fieldId = (type === 'benchmark') ? benchmarkFieldId : field;
    return (`${value}-${fieldId}`)
  });
  // Remove the colors from chart
  fundOrBenchmarksIdList.forEach(identifier =>
    removeChartSeriesColor(chartInstance, identifier)
  );
  // Remove series from chart options
  const {series} = chartOptions;
  // return the items that are not matched with list of items to be removed
  const updatedSeries = series.filter(item => {
    const someMatchFound = fundOrBenchmarksIdList.some(identifier => {
      return item.id === identifier;
    });
    return !someMatchFound;
  });
  return {
    ...chartOptions,
    series: updatedSeries
  };
};

export const removeMetricSeriesFromChartOptions = (chartInstance, chartOptions, identifier, yAxisLabel = "") => {
  // Remove series from chart
  removeChartSeriesColor(chartInstance, identifier);
  // Update the series and yAxis
  const {series, yAxis} = chartOptions;
  const updateSeries = series && series.filter(item => (item.id !== identifier));
  const updateYAxis = yAxis && yAxis.filter(({title: {text}}) => (
    text !== yAxisLabel
  ));
  return {
    ...chartOptions,
    series: updateSeries,
    yAxis: updateYAxis
  };
};

export const updateChartSeries = (
  metricsItemToRemove,
  fundOrBenchmarksToRemove,
  chartState,
  fieldValue = undefined,
  benchmarkMetricsOptions = []
) => {
  const {chartOptions, chartInstance, primaryShareClassId} = chartState;
  if (metricsItemToRemove) {
    const {label, value} = metricsItemToRemove;
    const identifier = `${primaryShareClassId}-${value}`;
    // Remove metrics From chart options
    const updatedChartOptions = removeMetricSeriesFromChartOptions(chartInstance, chartOptions, identifier, label);
    // Remove benchmarks From chart options
    return removeFundOrBenchmarkSeriesFromChart(
      fundOrBenchmarksToRemove,
      fieldValue,
      chartInstance,
      updatedChartOptions,
      benchmarkMetricsOptions
    );
  } else {
    return removeFundOrBenchmarkSeriesFromChart(
      fundOrBenchmarksToRemove,
      fieldValue,
      chartInstance,
      chartOptions,
      benchmarkMetricsOptions
    );
  }
};

export const getUpdateSeriesWithNameAndLegend = (series, allShareClassData, primaryShareClassId, selectedMetrics = []) => {
  let updateNameAndLegendData = {};
  const [primaryMetricItem, compareMetricItem] = selectedMetrics;
  const {label: primaryMetricLabel, value: primaryMetricField} = primaryMetricItem || {};
  const updateType = compareMetricItem ? FUND_TRACKER.METRICS : FUND_TRACKER.BENCHMARKS;
  if (compareMetricItem) {
    // Update primary series name and legend with primary metric item
    updateNameAndLegendData = {
      name: primaryMetricLabel,
      legendItem: {textStr: primaryMetricLabel}
    }
  } else {
    // Reset the primary series name and legend with Primary Shareclass details
    const primaryShareClassData = allShareClassData.filter(({id}) => (id === primaryShareClassId))[0];
    if (primaryShareClassData) {
      const {
        shareclass: {
          longName: shareClassName
        }
      } = primaryShareClassData;
      updateNameAndLegendData = {
        name: shareClassName,
        legendItem: {textStr: shareClassName}
      };
    }
  }
  return series.map(item => {
    const [id, field] = item.id.split("-");
    const updateLegendFlag = ((updateType === FUND_TRACKER.METRICS) && (field === primaryMetricField)) ||
      ((updateType === FUND_TRACKER.BENCHMARKS) && (id === primaryShareClassId));
    if (updateLegendFlag) {
      return {
        ...item,
        ...updateNameAndLegendData
      }
    }
    return item;
  });
};

export const shareClassFound = (data, shareClassList) => {
  return shareClassList.some(item => (data.id === item.id));
};

export const isChartSeriesDataEmpty = (chartSeriesData) => {
  return chartSeriesData && chartSeriesData.data
    && isEmpty(chartSeriesData.data);
};

export const isYAxisAlreadyExists = (updatedChartOptions, label) => {
  if (updatedChartOptions.yAxis) {
    return updatedChartOptions.yAxis.some(({title: {text}} = {}) => {
      return text === label;
    });
  }
  return false;
};

export const processServiceResponse = (
  allShareClassData,
  updatedChartOptions,
  yAxisLabel,
  completeData,
  fieldValue,
  addShareClassData,
  chartDispatch,
  chartInstance,
  primaryShareClassId,
  selectedMetrics,
  benchmarks
) => {
  // console.log(benchmarks);
  const seriesData = [];
  const shareClassesData = [];
  const updatedChartOptionsClone = {...updatedChartOptions};
  let clonseShareclassData = cloneDeep(allShareClassData);
  // This would be, only if we change metrics from Dropdown above chart
  if (yAxisLabel && !isYAxisAlreadyExists(updatedChartOptions, yAxisLabel)) {
    updatedChartOptions.yAxis.push({
      title: {
        text: yAxisLabel
      },
      opposite: true,
      offset: 40
    });
  }

  completeData.forEach((data = {}) => {
    // Add the series to chart options
    const chartKey = `${data.id}-${fieldValue}`;
    const series = getHighChartSeries(data, chartKey);
    const otherSeries = updatedChartOptionsClone.series.filter(({id}) => id !== chartKey);
    updatedChartOptionsClone.series = otherSeries;
    updatedChartOptionsClone.series.push(series);
    seriesData.push(series);

    // Collect the shareclass data (only if we are navigating from Compare Modal)
    if (addShareClassData && !shareClassFound(data, allShareClassData)) {
      shareClassesData.push(data);
    }
  });

  if (benchmarks) {
    const shareclassOrder = benchmarks.map(({value}) => `${value}-${fieldValue}`);
    updatedChartOptionsClone.series = orderShareclassPerformance(updatedChartOptionsClone.series, shareclassOrder, "id");
  }

  const {series} = updatedChartOptionsClone;
  const seriesShareclassId = series.map(series => series.id.split("-")[0]);
  const perfShareclasses = allShareClassData.filter(sc => {
    return seriesShareclassId.indexOf(sc.id) !== -1
  });
  clonseShareclassData = [...perfShareclasses, ...completeData];
  // Remove duplicates shareclasses
  clonseShareclassData = clonseShareclassData.filter((v, i, a)=> a.findIndex(v2 => (v2.id === v.id)) === i);

  // Add the shareclass data to the list (to Show it in bottom grid)
  chartDispatch({
    type: ChartActionTypes.updateShareClassList,
    data: {
      allShareClassData: clonseShareclassData,
      replaceShareClassData: true
    }
  });

  // Update the Chart Options to the state
  chartDispatch({
    type: ChartActionTypes.updateChartOptions,
    data: {
      chartOptions: {
        ...updatedChartOptionsClone,
        series: getUpdateSeriesWithNameAndLegend(
          updatedChartOptionsClone.series,
          allShareClassData,
          primaryShareClassId,
          selectedMetrics
        )
      }
    }
  });

  // Check if data missing for some Funds or Benchmarks
  const isFundOrBenchmarkDataMissing = isEmpty(seriesData) || seriesData.some(data =>
    isChartSeriesDataEmpty(data)
  );
  chartDispatch({
    type: ChartActionTypes.isFundOrBenchmarkDataMissing,
    data: {
      isFundOrBenchmarkDataMissing
    }
  });

  if (addShareClassData && shareClassesData.length > 0) {
    const asOfDate = getAsOfDate(series[0].data);
    const shareclasses = clonseShareclassData.map(({shareclass}) => shareclass);
    // // Remove duplicates from shareclasses
    // shareclasses = shareclasses.filter((v, i, a)=> a.findIndex(v2 => (v2.id === v.id)) === i);
    return {
      asOfDate,
      shareclasses
    }
  }
  return {};
};

// ************************************************************************************
//                       Fund/Benchmark OR Metrics series
// ************************************************************************************

// ********************************************************************************************
//    This determines if the Metric series already exits on the chart, this is useful to
//    to determine if we need to add the selected Fund/Benchmark when user switches the tabs
// ********************************************************************************************
export const isMetricsSeriesAlreadyExists = (compareMetricItem, chartOptions) => {
  const {label} = compareMetricItem;
  if (chartOptions && chartOptions.series && chartOptions.series.length > 0) {
    return chartOptions.series.some(item => item.name === label);
  }
  return true;
};

// **************************************************************************************************
//    This determines if the Fund/Benchmark series already exits on the chart, this is useful to
//    to determine if we need to add the selected Fund/Benchmark when user switches the tabs
// **************************************************************************************************

export const isBenchmarksSeriesAlreadyExists = (chartOptions, selectedFundsOrBenchMarks) => {
  if (chartOptions && chartOptions.series) {
    const itemsWithData = selectedFundsOrBenchMarks;
    if (chartOptions.series.length > 0) {
      if (itemsWithData.length > 0) {
        const benchmarksSeriesAlreadyExists = itemsWithData.every(benchmark => {
          const itemExistsInChartSeries = chartOptions.series.some(item =>
            item.name === benchmark.label
          );
          return itemExistsInChartSeries;
        });
        return benchmarksSeriesAlreadyExists;
      }
    } else {
      return !itemsWithData.length;
    }
  }
  return true;
};

export const addFundsOrBenchmarkSeriesToChart = (
  run,
  fieldForQuery,
  benchmarkMetricsOptions,
  selectedFundsOrBenchMarks,
  chartDispatch,
  chartOptions,
  chartState,
  primaryMetricItem,
  allSelectedShareclasses
) => {
  if (!isBenchmarksSeriesAlreadyExists(chartOptions, selectedFundsOrBenchMarks)) {
    fetchFundsOrBenchmarksData(
      run,
      fieldForQuery,
      benchmarkMetricsOptions,
      selectedFundsOrBenchMarks,
      chartDispatch,
      chartOptions,
      chartState,
      true,
      "",
      [primaryMetricItem],
      allSelectedShareclasses
    );
  } else {
    // Just update the Chart Options to the state, as there may be an update to it
    chartDispatch({
      type: ChartActionTypes.updateChartOptions,
      data: {
        chartOptions
      }
    });
  }
};

export const removeFundsOrBenchmarkSeriesToChart = (
  completeData,
  run,
  fieldForQuery,
  benchmarkMetricsOptions,
  selectedFundsOrBenchMarks,
  chartDispatch,
  chartOptions,
  chartState,
  primaryMetricItem
) => {
  if (!isBenchmarksSeriesAlreadyExists(chartOptions, selectedFundsOrBenchMarks)) {
    const {allShareClassData, chartInstance, primaryShareClassId} = chartState;
    const {fieldValue} = fieldForQuery;
    processServiceResponse(
      allShareClassData,
      chartOptions,
      "",
      completeData,
      fieldValue,
      true,
      chartDispatch,
      chartInstance,
      primaryShareClassId,
      [primaryMetricItem]
    );
  } else {
    // Just update the Chart Options to the state, as there may be an update to it
    chartDispatch({
      type: ChartActionTypes.updateChartOptions,
      data: {
        chartOptions
      }
    });
  }
};

export const addMetricsSeriesToChart = (
  compareMetricItem,
  run,
  chartDispatch,
  chartOptions,
  chartState,
  selectedMetrics
) => {
  if (!isMetricsSeriesAlreadyExists(compareMetricItem, chartOptions)) {
    addMetricToSeries(
      compareMetricItem,
      run,
      chartDispatch,
      chartOptions,
      chartState,
      selectedMetrics,
      false
    );
  }
};

export const getFundDisplay = (data = {}, fundIdentifier) => {
  if (!data.shareclass) return '';
  const { shareclass } = data;
  switch(fundIdentifier) {
    case 'CUSIP': return shareclass.cusip;
    case 'ISIN': return shareclass.isin;
    case 'FUND_NUMBER': return shareclass.shareClassDetails.displayCode;
    case 'TICKER': return shareclass.nasdaqTicker;
    default: return '';
  }
};

export const getSelectedShareClassIds = (primaryShareClassId, isBenchMarksCompareMode, selectedFundsOrBenchMarks) => {
  const listWithPrimaryShareClass = [primaryShareClassId];
  if (isBenchMarksCompareMode) {
    const compareShareClsList = selectedFundsOrBenchMarks.map(({value}) => value);
    return [...listWithPrimaryShareClass, ...compareShareClsList];
  }
  return listWithPrimaryShareClass;
};

export const getThresholdsForSelectedFunds = (
  primaryShareClassId, isBenchMarksCompareMode,
  selectedFundsOrBenchMarks, metricEnum, thresholdList
) => {
  const selectedShareClassIds = getSelectedShareClassIds(
    primaryShareClassId, isBenchMarksCompareMode,
    selectedFundsOrBenchMarks
  );
  return thresholdList
    .filter(({metric, shareClassId}) => (
      (metric === metricEnum) && selectedShareClassIds.includes(shareClassId)
    ));
};

const getThresholdItem = (thresholdList, metricEnum, selectedShareClassId) => {
  return thresholdList.find(({metric, shareClassId}) => (
    (metric === metricEnum) && (shareClassId === selectedShareClassId)
  ));
};

export const getAllThresholdsForSelectedFunds = (
  primaryShareClassId, isBenchMarksCompareMode, selectedFundsOrBenchMarks,
  selectedMetrics, thresholdList
) => {
  const [{enum: primaryMetricEnum} = {}] = selectedMetrics;
  const selectedShareClassIds = getSelectedShareClassIds(
    primaryShareClassId, isBenchMarksCompareMode, selectedFundsOrBenchMarks
  );
  return selectedShareClassIds
    .map(shareClassId => getThresholdItem(thresholdList, primaryMetricEnum, shareClassId))
    .filter(item => item)
};

export const verifyThresholds = (
  primaryShareClassId,
  selectedFundsOrBenchMarks,
  selectedMetrics,
  thresholdList,
  isBenchMarksCompareMode
) => {
  const [primaryMetricItem, compareMetricItem] = selectedMetrics;
  if (primaryShareClassId && primaryMetricItem && !compareMetricItem && thresholdList.length > 0) {
    // Get the lower and upper thresholds for the Primary Fund Selected
    const {enum: primaryMetricEnum} = primaryMetricItem;
    const primaryThresholdItem = getThresholdItem(thresholdList, primaryMetricEnum, primaryShareClassId);
    // If the primary threshold item has the threshold set
    if (primaryThresholdItem) {
      const {
        lowerThreshold: baseLowerThreshold,
        upperThreshold: baseUpperThreshold,
        emailNotificationEnabled
      } = primaryThresholdItem;
      // If only Primary fund is selected, check if the Primary Fund has threshold set
      if (!isBenchMarksCompareMode) {
        return {
          isExistingThresholdItem: true,
          isAllThresholdHasSameLimits: true,
          baseLowerThreshold,
          baseUpperThreshold,
          emailNotificationEnabled
        };
      } else {
        const thresholdsForSelectedFunds = getThresholdsForSelectedFunds(
          primaryShareClassId,
          isBenchMarksCompareMode,
          selectedFundsOrBenchMarks,
          primaryMetricEnum,
          thresholdList
        );
        // Add one (primary fund which is selected on landing page) to the compare funds (selected in compare modal)
        const totalFundsOrBenchmarksSelected = (selectedFundsOrBenchMarks.length + 1);
        // If we are in Funds/Benchmark comparison mode, then see if Number of thresholds equals to the number of Funds/Benchmark selected
        if (thresholdsForSelectedFunds.length === totalFundsOrBenchmarksSelected) {
          // Check if all the thresholds are same
          const isAllThresholdHasSameLimits = thresholdsForSelectedFunds.every(({lowerThreshold, upperThreshold}) => {
            return (lowerThreshold === baseLowerThreshold) && (upperThreshold === baseUpperThreshold)
          });
          // If all the thresholds are same, return the threshold values to populate it in Threshold Modal
          if (isAllThresholdHasSameLimits) {
            return {
              isExistingThresholdItem: true,
              isAllThresholdHasSameLimits,
              baseLowerThreshold,
              baseUpperThreshold,
              emailNotificationEnabled,
            }
          }
        }
        return {
          isExistingThresholdItem: true,
          isAllThresholdHasSameLimits: false
        }
      }
    }
  }
  return {
    isExistingThresholdItem: false,
    isAllThresholdHasSameLimits: true
  }
};

export const isAllThresholdsEmpty = (
  primaryShareClassId, isBenchMarksCompareMode,
  selectedFundsOrBenchMarks, selectedMetrics, thresholdList
) => {
  const [{enum: primaryMetricEnum} = {}] = selectedMetrics;
  const thresholdsForSelectedFunds = getThresholdsForSelectedFunds(
    primaryShareClassId, isBenchMarksCompareMode,
    selectedFundsOrBenchMarks, primaryMetricEnum, thresholdList
  );
  return thresholdsForSelectedFunds.every(({lowerThreshold, upperThreshold}) => {
    return (!lowerThreshold && !upperThreshold)
  });
};

// ***************************************************************************************
//            To clean up the grid data after removing series from chart
// ***************************************************************************************
export const cleanupGridData = (
  selectedFundOrBenchmarksData = [],
  fundOrBenchmarksToRemove,
  allShareClassData,
  primaryShareClassId,
  chartDispatch
) => {
  /* If all the selected items were removed, get the default share class
     item added to the grid data */
  if (selectedFundOrBenchmarksData && !selectedFundOrBenchmarksData.length && allShareClassData.length > 1) {
    const initialShareClassData = allShareClassData.filter(item =>
      item.id === primaryShareClassId
    );
    updateShareClassListToState(chartDispatch, initialShareClassData);
  } else {
    /* Remove the benchmark data from the Grid Data list as well,
       if any share class was removed from modal */
    const shareClassDataForGrid = diffArrays(allShareClassData, fundOrBenchmarksToRemove, (v1, v2) => {
      return v1.id === v2.value;
    });
    updateShareClassListToState(chartDispatch, shareClassDataForGrid);
  }
};

export const addPlotLinesAndMarker = (e, allShareClassData, run, chartDispatch, chartOptions) => {
  const myPlotLineId = "myPlotLine";
  const {point} = e;
  const xValue = e.point.x;
  const {xAxis} = e.point.series;
  const xDate = Highcharts.dateFormat('%Y-%m-%d', xValue);
  const labelDate = Highcharts.dateFormat('%d.%B.%Y', xValue);
  const shareClassPerformanceData = allShareClassData.map(data => {
    return data.shareclass;
  });
  if(xAxis.plotLinesAndBands.length === 0){
    xAxis.addPlotLine({
      point,
      value: xValue,
      width: 1,
      dashStyle: 'dash',
      color: 'black',
      id: myPlotLineId,
      label: {
        useHTML: true,
        text: `<div style="color: black; background: lightgrey; text-align: center">${labelDate} <br/> ${point.series.name}: ${point.y}</div>`,
        rotation: 0,
        align: "center",
        verticalAlign: "bottom"
      }
    });
    fetchShareClassPerformanceData(xDate, shareClassPerformanceData, run, chartDispatch);
  }
  else {
    const prevValue = xAxis.plotLinesAndBands[0].options.value;
    if(xValue === prevValue){
      try {
        xAxis.removePlotLine(myPlotLineId);
      } catch (err) {}
      const asOfDate = getAsOfDate(chartOptions.series[0].data);
      fetchShareClassPerformanceData(asOfDate, shareClassPerformanceData, run, chartDispatch);
    }
    else {
      try {
        xAxis.removePlotLine(myPlotLineId);
      } catch (err) {}
      xAxis.addPlotLine({
        point,
        value: xValue,
        width: 1,
        dashStyle: 'dash',
        color: 'black',
        id: myPlotLineId,
        label: {
          useHTML: true,
          text: `<div style="color: black; background: lightgrey; text-align: center">${labelDate} <br/> ${point.series.name}: ${point.y}</div>`,
          rotation: 0,
          align: "center",
          verticalAlign: "bottom"
        }
      });
      fetchShareClassPerformanceData(xDate, shareClassPerformanceData, run, chartDispatch);
    }
  }
};
export const allShareClassPerformanceData = (allShareClassData, fundOrBenchmarksToRemove) => {
  const shareClassDataForGrid = diffArrays(allShareClassData, fundOrBenchmarksToRemove, (v1, v2) => {
    return v1.id === v2.value;
  });
  return shareClassDataForGrid.map(data => {
    return data.shareclass;
  });
};

export const updateShareClassDetails = (items, shareClasses) => (
  items.map(item => {
    if (!item.label) {
      const matchedItem = shareClasses.find(({id}) => id === item.value);
      if (matchedItem) {
        const {longName: label, cusip, isin} = matchedItem;
        return {...item, label, cusip, isin}
      }
    }
    return item;
  })
);

export const getChartZoomLevel = (zoomPreset) => {
  const ranges = ["1m", "3m", "6m", "YTD", "1y", "All"];
  return ranges.indexOf(zoomPreset);
};

export const getFundValueToSearch = memoize((shareclass) => {
  // Prepare the plain list of Share Classes
  const {
    id: value,
    longName,
    isin,
    cusip,
    nasdaqTicker,
    taInfo: { gtamTaId } = {},
    shareClassDetails = {},
    fundDetails = {}
  } = shareclass;
  let label = longName;
  if (fundDetails.fundType && fundDetails.fundType.toLowerCase() === "moneymarket" && fundDetails.status && fundDetails.status.toLowerCase() === "open" ) {
    if (gtamTaId === 1) {
      label = `${longName} - ${shareClassDetails.currencyCode} | ${nasdaqTicker ? `${nasdaqTicker} | ` : ''}
      ${cusip ? `${cusip} | ` : ''}
      ${shareClassDetails.displayCode ? `${shareClassDetails.displayCode}` : '' }`;
    } else {
      label = `${longName} - ${shareClassDetails.currencyCode} |
      ${isin ? `${isin} | ` : ''}
      ${shareClassDetails.displayCode ? `${shareClassDetails.displayCode}` : '' }`;
    }
    return {
      value,
      label,
      customProperties: {
        type: 'fund',
        cusip,
        isin
      }
    }
  }
  return {};
});

export const getSelectedFilterMap = (shareclasses, fieldValue) => {
  const filterShareclassFieldIdObj = shareclasses.reduce((finalObj, item) => {
    let key = item.value;
    if (fieldValue) {
      key += `-${fieldValue}`
    }
    finalObj[key] = true;
    return finalObj;
  }, {});
  return filterShareclassFieldIdObj;
}


const filterData = (data, shareclasses, fieldValue) => {
  const filterShareclassFieldIdObj = getSelectedFilterMap(shareclasses, fieldValue);
  const filteredData = data.filter(d => filterShareclassFieldIdObj[d.id]);
  return filteredData;
}

export const filterShareclassData = ({chartOptions, allShareClassData, shareClassPerformanceData, fieldForQuery}, shareclasses) => {
  const {fieldValue} = fieldForQuery;
  const series = filterData(chartOptions.series, shareclasses, fieldValue);
  const filteredAllShareClassData = filterData(allShareClassData, shareclasses);
  const filteredShareClassPerformanceData = filterData(shareClassPerformanceData, shareclasses);

  return {
    series,
    allShareClassData: filteredAllShareClassData,
    shareClassPerformanceData: filteredShareClassPerformanceData
  }
}

export const hasTopHoldingsShareclasses = (topHoldingShareclasses, currentShareclasses) => {
  const currentShareclassMap = getSelectedFilterMap(currentShareclasses);

  let hasShareclasses = true;
  topHoldingShareclasses.every(({value}) => {
    if (!currentShareclassMap[value]) {
      hasShareclasses = false;
      return false;
    }
    return true;
  });

  return hasShareclasses;
}

export {
  diffArrays
};
