import React, {useMemo, useState} from 'react';
import {styled} from '@gs-ux-uitoolkit-react/style';
import {InputIcon} from '@gs-ux-uitoolkit-react/input';

const StyledButton = styled('button', {
  borderWidth: 0,
  outlineWidth: 0,
  background: 'transparent',
  '&:focus': {
    outlineWidth: 0
  },
});

const getPasswordVisibilityIcon = (hidePassword, togglePasswordVisibility) => (
  <StyledButton
    type="button"
    tabIndex={-1}
    onClick={togglePasswordVisibility}
  >
    <InputIcon
      name={hidePassword ? 'visibility' : 'visibility-off'}
      size="lg"
      type="filled"
    />
  </StyledButton>
);

export const usePasswordVisibilityIcon = () => {
  const [passwordEnabled, setPasswordEnabled] = useState(true);

  const passwordTrailingContent = useMemo(() => (
    getPasswordVisibilityIcon(passwordEnabled, (e) => {
      if (e.detail > 0) {
        setPasswordEnabled(!passwordEnabled);
      }
    })
  ), [passwordEnabled]);

  return {passwordEnabled, passwordTrailingContent};
};
