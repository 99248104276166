import { StyleSheet } from '@gs-ux-uitoolkit-react/style';
import { Theme } from '@gs-ux-uitoolkit-react/theme';

export const sizeAndDensityStyleSheet = new StyleSheet(
    'gs-uitk-size-and-density-container',
    (theme: Theme) => {
        const themeObject = theme;
        themeObject;
        return {
            root: {
                margin: '0.5rem',
                padding: 16,
                minWidth: 'inherit',
                maxWidth: 'initial',
                textAlign: 'left',
            },
        };
    }
);
