import { StyleSheet } from '@gs-ux-uitoolkit-react/style';
import { MOBILE_BREAKPOINT } from '../style-constant';
import { lightTheme, Theme } from '@gs-ux-uitoolkit-react/theme';
interface PluginPreviewStyleSheetProps {
    theme: Theme;
}

export const pluginPreviewComponentStyleSheet = new StyleSheet(
    'previewComponent',
    ({ theme }: PluginPreviewStyleSheetProps) => {
        const themeObject = theme || lightTheme;
        return {
            root: {
                flex: 1,
                marginLeft: '10px',
                '.gs-uitk-basic-expression-preview-heading label': {},
                '.gs-uitk-plugin-preview-container': {
                    backgroundColor: themeObject.surface.secondary,
                    height: '100%',
                },
                '.gs-uitk-plugin-preview-accordion-container': {
                    display: 'none',
                },
                '.gs-layout-container': {
                    paddingLeft: '0',
                    paddingRight: '0',
                    '.gs-layout-row': {
                        marginLeft: '0',
                        marginRight: '0',
                    },
                    '.gs-badge': {
                        marginTop: '14px',
                    },
                },
                '.gs-accordion-panel__body': {
                    padding: 0,
                },
                '.gs-uitk-basic-expression-preview': {
                    padding: '20px',
                },
                '.basic-expression-preview': {
                    padding: '10px 15px',
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '100%',
                    boxSizing: 'border-box',
                    '.gs-list-group': {
                        width: '100%',
                    },
                },
                '.gs-accordion': {
                    width: '100%',
                },
                '.basic-expression': {
                    width: '100%',
                },
                '.experienced-expression-filter-preview': {
                    padding: '0.5rem',
                },
                '.gs-layout-container .gs-layout-row ul': {
                    width: '100%',
                },

                [`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
                    marginLeft: '0',
                    '.gs-uitk-plugin-preview-container': {
                        display: 'none',
                    },
                    '.gs-uitk-plugin-preview-accordion-container': {
                        display: 'block',
                        width: '100%',
                        '.gs-uitk-plugin-preview-container': {
                            display: 'block',
                        },
                    },

                    '.basic-expression.gs-layout-row': {
                        marginRight: '0',
                        marginLeft: '0',
                    },
                },
            },
        };
    }
);
