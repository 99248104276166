import { CssClasses, CssClassDefinitions, CssFactoryPropsType } from './types';
import {
    MountedStyleSheet,
    createMountedStyleSheet,
} from './props-memoization/mounted-style-sheet';
import { PropsTrie } from './props-memoization/props-trie';
import { isBrowser } from '@gs-ux-uitoolkit-common/shared';
import { shallowEqualObjects } from 'shallow-equal';
import { Emotion } from '@emotion/css/types/create-instance';
import { getEmotionInstance } from './emotion';

/**
 * A CSS-in-JS Stylesheet which is used to dynamically create CSS at runtime
 * based on a CSS classes definition object or factory function. A factory
 * function can be used to accept parameters that will customize the CSS
 * properties.
 *
 * For Example:
 *
 *     const styleSheet = new StyleSheet('alert', ({ theme }: AlertStyleSheetProps) => {
 *         root: {
 *             position: 'relative',
 *             padding: theme.padding,
 *             paddingLeft: '44px',
 *             // ...
 *         },
 *         dismissible: {
 *             paddingRight: '14px',
 *             // ...
 *         }
 *     });
 *
 * A single instance of a StyleSheet may be mounted by one or more view
 * components, with one or more Props objects to fill in dynamic values (see
 * above). Essentially, a new set of CSS rules will be mounted into the DOM for
 * each different `Props` object that is used to mount the StyleSheet.
 *
 * For efficiency, if multiple component instances mount the StyleSheet with the
 * same `Props`, then only one set of CSS rules will be added to the DOM. The
 * `Props` objects need not be the exact same object reference; the object's
 * properties are shallowly compared to the previous object (much like React's
 * `Props` object), and if each of the properties are the same as a previous
 * mounting of the `StyleSheet`, we know that we do not need to mount a new set
 * of CSS rules.
 *
 * Furthermore, when _all_ instances of components using the `StyleSheet` are
 * destroyed and the `StyleSheet` itself is {@link #unmount unmounted}, the
 * CSS rules will be removed from the DOM. In order to facilitate this, a
 * reference counting scheme is used for keeping the CSS rules in the DOM for as
 * long as they're needed. Each call to {@link #mount} and {@link #unmount}
 * increment and decrement the reference count. However, in order to determine
 * if the same component instance is mounting the stylesheet for a second time
 * (render method is called again), {@link #mount} requires a `key` to track
 * this. The `key` is usually going to be the component instance itself
 * (`this`) for class components, or a new `Ref` generated for React function
 * components (see the useRef hook: https://reactjs.org/docs/hooks-reference.html#useref).
 *
 * Example of manually mounting the StyleSheet:
 *
 *     // Mount the StyleSheet in the DOM, and return the generated class names
 *     // (which will all be unique)
 *
 *     const key = {};  // just an object to keep track of the stylesheet's lifetime vs. other users of the same JsStyleShet
 *     const classes = styleSheet.mount(key, myAlertTheme);
 *     // ->
 *     //   {
 *     //       classes.container = 'css-1teaf32-alert-container',
 *     //       classes.dismissible = 'css-f794ba61-alert-dismissible'
 *     //   }
 *
 *     // Use classes on elements. Example:
 *     //     <div className={classes.container}>...</div>
 *
 *     // Use cx() function provided from the 'style' package to combine classes in the order
 *     // provided (not dependent on source order). Examples:
 *     //     <div className={cx(classes.container, classes.dismissible)}>...</div>
 *     //     <div className={cx(classes.dismissible, classes.container)}>...</div>  // reversed order is respected - CSS classes are not based on source order
 *
 *     // When the stylesheet is no longer needed (for instance, the component is destroyed),
 *     // unmount it to free memory
 *     styleSheet.unmount(key);
 */
export class StyleSheet<ClassNames extends string, Props extends CssFactoryPropsType = null> {
    /**
     * Holds references to the mounted CssClasses, keyed by both the `key` from
     * the {@link #mount} method and the `props` object that the StyleSheet
     * was mounted with. `key` + `props` = `MountedStyleSheet`
     *
     * This is used to unmount the correct stylesheet from the DOM when it is no
     * longer used, or to simply reduce the reference count on shared usages of
     * the StyleSheet if component instances which use the CSS classes are
     * still alive.
     */
    private mountedStyleSheets = new PropsTrie<MountedStyleSheet<ClassNames>>();

    /**
     * A Map which determines if the stylesheet was mounted for the given `key`,
     * and which Props object it was mounted with for that key.
     *
     * This is used when {@link #mount} is called with the same key to determine
     * if a new `Props` object has been used for the `key`, or the same `Props`
     * object has been used for the `key`. If it is a new object, the previous
     * stylesheet is unmounted and a new one (for the new `Props` object) is
     * mounted.
     */
    private mountedPropsObjects = new Map<object, Props>();

    constructor(
        /**
         * A name for the stylesheet, usually the name of the component that the
         * stylesheet is for. Example: 'alert'
         *
         * This is used as part of the name of all generated CSS classes, and
         * assists in debugging so we can know which CSS classes belong to which
         * components.
         */
        public readonly name: string,

        /**
         * The CSS class definitions for the StyleSheet. These define the CSS
         * classes and their associated CSS properties.
         */
        public readonly classDefinitions: CssClassDefinitions<ClassNames, Props>
    ) {}

    /**
     * Using the {@link #classDefinitions}, creates or updates the stylesheet in the DOM
     * based on the given `props` which is passed to the {@link #classDefinitions}.
     *
     * Example:
     *
     *     const styleSheet = new StyleSheet('alert', ({ theme }: AlertStyleSheetProps) => ({
     *         root: {
     *             padding: '5px',
     *             backgroundColor: theme.bgColor,
     *         },
     *         dismissible: {
     *             paddingRight: '44px'
     *         },
     *     }));
     *
     *     // ...
     *
     *     const classes = styleSheet.mount(componentInstance, {
     *         theme: { bgColor: 'blue' }
     *     );
     *
     * `mount()` returns an object that maps the original class names to the
     * generated unique CSS class names that are mounted in the DOM. For
     * example:
     *
     *     {
     *         root: 'gs-uitk-c-1teaf32--alert-root',
     *         dismissible: 'gs-uitk-c-f794ba61--alert-dismissible',
     *     }
     *
     * ## Memoization
     *
     * The `mount()` method has a memoization layer built in where mounting the
     * same `props` object will result in a no-op as far as style generation goes.
     * Basically, if it's detected that we've already mounted the given set of
     * `props`, then the generated style rules would be exactly the same and
     * therefore we don't have to process them again.
     *
     * @param key The key to mount the stylesheet under. This is for reference counting
     *   purposes where StyleSheet can unmount its actual CSS code from the DOM when no
     *   components are using it anymore. The key is usually the component instance, or a
     *   ref in the case of a function component. A key can only ever mount exactly one
     *   `props` object, and if the `props` object changes for the `key`, the stylesheets
     *   will adjusted accordingly.
     * @param props The properties object to use to fill in dynamic values (specified with a
     *   function for the CSS class definitions - see above example)
     * @param [emotionInstance] By default, the global singleton Emotion instance is
     *   used. However for Next.js's App Router, we need an instance per request
     *   to populate the correct styles per request.
     */
    public mount(key: object, props: Props, emotionInstance?: Emotion): CssClasses<ClassNames> {
        emotionInstance = emotionInstance || getEmotionInstance();

        const {
            name: styleSheetName,
            classDefinitions,
            mountedStyleSheets,
            mountedPropsObjects,
        } = this;

        const mountedPropsObject = mountedPropsObjects.get(key);
        if (mountedPropsObject && shallowEqualObjects(props, mountedPropsObject)) {
            // Props object is already mounted for this key, nothing to do
            const mountedStyleSheet = mountedStyleSheets.get(mountedPropsObject)!;
            return mountedStyleSheet.cssClasses; // return the current set of classes
        }

        if (mountedPropsObject) {
            // This `key` has previously mounted a different `props` object.
            // Unmount it in preparation to set the key to a new
            // MountedStyleSheet (either one that already exists due to another
            // component mounting the new `props` object, or one that we'll
            // newly mount if none exists yet)
            this.unmount(key);
        }

        const previouslyMountedStyleSheet = mountedStyleSheets.get(props);
        if (previouslyMountedStyleSheet) {
            // CSS classes are already mounted for this `props` object by another
            // key (i.e. another component instance). Update this key to point
            // to the existing `props` object and return its CSS classes. No
            // need to update the DOM with new styles
            mountedPropsObjects.set(key, props);
            previouslyMountedStyleSheet.incrementReferenceCount();
            return previouslyMountedStyleSheet.cssClasses;
        } else {
            // No mounted CSS classes for this `props` object exist yet. Create
            // a new one
            const mountedStyleSheet: MountedStyleSheet<ClassNames> = createMountedStyleSheet(
                styleSheetName,
                classDefinitions,
                props,
                emotionInstance
            );

            // We only want to memoize stylesheet mounting in the browser because we
            // can't unmount the stylesheets in an SSR (server-side rendering)
            // environment. React lifecycle methods like `componentWillUnmount` and
            // hooks like `useEffect()` do not run in an SSR environment, and
            // therefore memoizing in that environment simply creates a memory
            // leak
            if (isBrowser) {
                mountedStyleSheets.set(props, mountedStyleSheet);
                mountedPropsObjects.set(key, props);
            }

            return mountedStyleSheet.cssClasses;
        }
    }

    /**
     * When the stylesheet is no longer needed, unmounts it from the DOM to
     * clean up memory and improve browser selector matching performance.
     */
    public unmount(key: object): void {
        const mountedPropsObject = this.mountedPropsObjects.get(key);
        if (mountedPropsObject === undefined) {
            // This `key` hasn't mounted anything - nothing to do
            return;
        }

        const mountedStyleSheet = this.mountedStyleSheets.get(mountedPropsObject)!;
        const removedFromDom: boolean = mountedStyleSheet.decrementReferenceCount();
        if (removedFromDom) {
            // After the reference count has reached 0 for the MountedStyleSheet,
            // we can clean up our reference to it
            this.mountedStyleSheets.delete(mountedPropsObject);
        }
        this.mountedPropsObjects.delete(key);
    }
}
