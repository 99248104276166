import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import {createSelector} from 'reselect/lib/index';
import Constants from '../../../../constants/appConstants';
import InvestmentPolicy from '../../../../containers/Preferences/Sections/InvestmentPolicy';
import {currencyLabelSelector} from '../../../app/index';
import {RULE_TYPE_TRANSLATION_KEYS, tradeBehaviors} from '../../../../constants/preferences';
import translator  from '../../../../services/translator';
import {formatDateForInvstPlcy} from '../../../../utils/dateFormatter';
import {getAppRoute} from '../../../../utils/commonUtils';
const {translate: t} = translator;

export const investmentPoliciesSelector = state =>  state.pageData && state.pageData.investmentPolicies || [];
export const selectedPolicyId = state =>  state.pageData && state.pageData.selectedPolicyId ;
export const isDataLoadingSelector = state => state.pageData  && state.pageData.isInvestmentPolicyDataLoading || false;

const DEFAULT_TAB_ID = 'investmentpolicy';

export const activeInvestmentPolicySelector = createSelector(
  investmentPoliciesSelector,
  (investmentPolicies) => {
    return investmentPolicies.filter(policy => policy.isActive)
  }
)
export const getInvestmentPolicyConfig = createSelector(
  activeInvestmentPolicySelector,
  (investmentPolicies) => {

    const defaultPolicyName = t('tkInvestmentPolicy');
    const policies = investmentPolicies.map((policy) => {
      const {policyName, gtamPolicyId} = policy;
      const routeId = policyName.replace(/\s/g, '');
      return {
        'exact': true,
        'path': `${getAppRoute(Constants.PREFERENCES_INVESTMENT_POLICY_ROUTE)}/${routeId.toLowerCase()}`,
        'component': InvestmentPolicy,
        'id': gtamPolicyId,
        'label': policyName,
        'resourceName': 'INVESTMENTPOLICY'
      };
    });
    return policies.length > 0 ? policies : [{
      'exact': true,
      'path': `${getAppRoute(Constants.PREFERENCES_INVESTMENT_POLICY_ROUTE)}/${DEFAULT_TAB_ID}`,
      'component': InvestmentPolicy,
      'id': DEFAULT_TAB_ID,
      'label': defaultPolicyName,
      'resourceName': 'INVESTMENTPOLICY'
    }];
  }
);


export const getSelectedInvestmentPolicy = createSelector(activeInvestmentPolicySelector, selectedPolicyId, (policies, policyId) => {
  const {length} = policies;
  if(length > 0) {
    return policyId && find(policies, {gtamPolicyId: policyId}) || policies[0];
  }
  return {};
});

const getAccountsAndScopes = (scopes, isEnabled) => {
  const selectedAccounts = [];
  const selectedScopes = [];

  scopes.forEach((scope) => {
    const {accounts, firmCode, branchCode, level} = scope;
    accounts.forEach((account) => {
      const {accountName, taAccount} = account;
      selectedAccounts.push({
        label: `${accountName} | ${taAccount}`,
        value: taAccount,
        disabled: !isEnabled,
        firmCode,
        branchCode,
        level
      });
    });

    selectedScopes.push({
      firmCode,
      branchCode,
      level,
      disabled: !isEnabled
    });

  });
  return {selectedScopes, selectedAccounts};
};

const getPolicyGuidelines = (rules) => {
  return rules && rules.length > 0 && rules.map((rule, index) => {
      const {onBreachAction, isEnabled, ruleType} = rule;
      const tradeBehaviorList = tradeBehaviors.map((behaviour) => {
        const {label, value} = behaviour;
        return {
          label: t(label),
          value,
          isChecked: value === onBreachAction,
          isDisabled: !isEnabled,
          name: index,
          id: index
        };
      });
      const keyObject = RULE_TYPE_TRANSLATION_KEYS[ruleType] || {};
      const {titleKey, descriptionKey} = keyObject;
      return {...rule, title: t(titleKey), description: t(descriptionKey), tradeBehaviorList};
  }) || [];
};

export const getInvestmentPolicyData = createSelector(getSelectedInvestmentPolicy, (selectedPolicy) => {

  if(isEmpty(selectedPolicy)) return {};

  const {scopes, rules, gtamPolicyId, policyName, requestedBy, modifiedBy, createdAt, modifiedAt, isActive, isEnabled,
    isCompliancePanelViewable, fundTypes} = selectedPolicy;

  const {selectedScopes, selectedAccounts} = scopes && getAccountsAndScopes(scopes, isEnabled) || {};
  const activeRules= rules.filter(rule => rule.isActive)
  const guidelines = activeRules && getPolicyGuidelines(activeRules) ||  [];
  const createdBy = `${t('tkCreatedColon')} ${formatDateForInvstPlcy(createdAt)} ${t('tkby')} ${requestedBy}`;
  const lastUpdatedBy = `${t('tkLastUpdatedColon')} ${formatDateForInvstPlcy(modifiedAt)} ${t('tkby')} ${modifiedBy}`;

  return {
    isActive,
    isEnabled: false,
    createdBy,
    fundTypes,
    modifiedAt,
    modifiedBy,
    policyName,
    guidelines,
    requestedBy,
    gtamPolicyId,
    lastUpdatedBy,
    selectedAccounts,
    scopes: selectedScopes,
    isCompliancePanelViewable
  };

});

export const getActivityNavLinks = createSelector(
  getInvestmentPolicyConfig,
  () => {
    // TODO: code and logic will be added for active link
    return [];
  }
);

export const getCurrencyList = createSelector(
  currencyLabelSelector,
  (currencyLabel) => {
    return Object.keys(currencyLabel).map((key) => {
      return {label:t(currencyLabel[key]), value:key};
    });
  }
);
