import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/core/Modal';
import Button from '../../../../components/core/Button';
import NewDropdown from '../../../../components/core/Dropdown';
import translator from '../../../../services/translator';
import {convert2PascalCase} from  '../../../../utils/commonUtils';
import {amountFormatter, amountWithSymbol} from '../../../../utils/amountFormatter';
import {translateTradeMessage} from  '../../../../utils/tradeApprovals';
import './index.scss';
import Snackbar from '../../../../components/core/Snackbar';
import {AMOUNT_TYPE} from '../../../../constants/pageConstants';
import {formatDate} from '../../../../utils/dateFormatter';
import {Conditional} from '../../../../components/core/Conditional';
import {ThreeSquaresLoader} from '../../../../components/core/Loaders';
const {translate: t} = translator;

const customModalStyle = {position: 'fixed'};


const RejectionModal = ({reasonsList, isModalOpen, handleClose, currentRowData, handleRejection,
  handleRejectionReasonChange, rejectionErrorOrWarning, isRejectionInProgress, handleOk}) => {
  const {fundName, tradeDate, tradeType, amountType, currencyCode, tradeAmount, id,
    accountIdentifier, fundIdentifier} = currentRowData;

  const [isDisabled, setIsDisabled] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [hasWarning, setHasWarning] = useState(false);
  const [notification, setNotification] = useState({});
  const modalRef = useRef();
  const handleRejectionReasonChangeFunc = (selectedValue, selectedItem) => {
    setIsDisabled(false);
    handleRejectionReasonChange(selectedValue, selectedItem);
  };

  const getStatus = (status) => {
    return (status || (Object.keys(notification).length > 0 ? 'clear' : undefined));
  };

  const onClose = (status) => {
    modalRef.current.closeModal(() => handleClose(getStatus(status)));
  };

  const onOk = () => {
    modalRef.current.closeModal(() => handleOk());
  };

  useEffect(() => {
    if(rejectionErrorOrWarning && id) {
      const {transactionId, warning, error, success, exception} = rejectionErrorOrWarning;

      if(success && transactionId === id){
        onClose('success');
      } else if(exception)  {
        const item = {type: 'error', message: exception.message} ;
        setNotification(item);
      } else if(transactionId !== id){
        setNotification({});
      } else if(error){
        setHasError(true);
        const item =  {type: 'error', message: translateTradeMessage(error.code, error.message, currentRowData)};
        setNotification(item);
      } else if(warning)  {
        const item = {type: 'warning', message: translateTradeMessage(warning.code, warning.message, currentRowData)} ;
        setHasWarning(true);
        setNotification(item);
      }
    }

  }, [rejectionErrorOrWarning, id]);

  const decimalPoint  = amountType === AMOUNT_TYPE.CURRENCY ? 2 : 3;
  const tradeAmountTxt = amountWithSymbol(amountFormatter(tradeAmount, decimalPoint), (amountType === AMOUNT_TYPE.CURRENCY) ? currencyCode : '') || '--';
  const disable = isDisabled || isRejectionInProgress;

  return (
    <Modal
      ref={modalRef}
      customModalStyle={customModalStyle}
      animation={true}
      animationTiming={500}
      customClass="modal--center external-modal-index trade-rejection-modal"
      backdropCustomClass="external-modal-backdrop-index"
      suppressScrollOnActive={true}
      open={isModalOpen}
      animationClassOpen="animation-expand-center"
      animationClassClose="animation-collapse-center"
      handleClose={() => handleClose(getStatus())}>
      <Conditional condition={isRejectionInProgress}>
        <div className="tradeApproval__loader">
          <ThreeSquaresLoader />
        </div>
      </Conditional>
      <div className="trade-rejection-modal-header">
        <h2>{t('tkRejectTrade')}</h2>
      </div>
      <div className="trade-rejection-modal-body">
        <Snackbar
          id={id}
          type={notification.type}
          msgCopy={notification.message}
          autoHide={false}
          sticky={false}
          />
        <div>
          <div className="trade-rejection-modal-body__grid1-panel">
            <div className="trade-rejection-modal-body__grid0">
              <div className="trade-rejection-modal-body__grid1-item">
                <span className="trade-rejection-modal-body__item-title">{t('tkFund')}</span>
                <span className="trade-rejection-modal-body__item-text">{fundName}</span>
                <span className="trade-rejection-modal-body__item-text">{fundIdentifier}</span>
              </div>
            </div>
            <div className="trade-rejection-modal-body__grid1">
              <div className="trade-rejection-modal-body__grid1-item">
                <span className="trade-rejection-modal-body__item-title">{t('tkAccountText')}</span>
                <span className="trade-rejection-modal-body__item-text">{accountIdentifier}</span>
              </div>
              <div className="trade-rejection-modal-body__grid1-item">
                <span className="trade-rejection-modal-body__item-title">{t('tkTradeDate')}</span>
                <span className="trade-rejection-modal-body__item-text">{formatDate(tradeDate, 'YYYY-MM-DD', 'DD.MMM.YYYY')}</span>
              </div>
            </div>
          </div>
          <div className="trade-rejection-modal-body__separator" />
          <div className="trade-rejection-modal-body__grid2">
            <div className="trade-rejection-modal-body__grid2-item">
              <span className="trade-rejection-modal-body__item-title">{t('tkTradeType')}</span>
              <span className="trade-rejection-modal-body__item-text">{convert2PascalCase(tradeType)}</span>
            </div>
            <div className="trade-rejection-modal-body__grid2-item">
              <span className="trade-rejection-modal-body__item-title">{t('tkAmountType')}</span>
              <span className="trade-rejection-modal-body__item-text">{convert2PascalCase(amountType)}</span>
            </div>
            <div className="trade-rejection-modal-body__grid2-item align-right">
              <span className="trade-rejection-modal-body__item-title">{t('tkTradeAmount')}</span>
              <span className="trade-rejection-modal-body__item-text">{tradeAmountTxt}</span>
            </div>
            <div className="trade-rejection-modal-body__grid2-item">
              <span className="trade-rejection-modal-body__item-title">{t('tkCurrency')}</span>
              <span className="trade-rejection-modal-body__item-text">{currencyCode}</span>
            </div>
          </div>
          <div className="trade-rejection-modal-body__separator" />
          <div className="trade-rejection-modal-body__grid">
            <div className="trade-rejection-modal-body__item-title">{t('tkRejectionReason')}</div>
            <div className='trade-rejection-modal-body__dropdown'>
              <NewDropdown
                id="approval-rejection-reasons"
                disabled={hasError || isRejectionInProgress}
                onSelect={handleRejectionReasonChangeFunc}
                options={reasonsList}
                width="282px"
                placeholder={t('tkSelectRejectionReason')}
            />
            </div>
          </div>
          <div className="extNavConfirmation__buttonRow">
            <div className="trade-rejection-modal-body__separator trade-rejection-modal-body__separator--footer" />
            <Button
              label={hasError ? t('tkOk') : (hasWarning ? t('tkAcknowledgeAndReject') : t('tkReject'))}
              isDisabled={disable}
              customClass={`button-primary-small${(disable) ? '-disabled' : ''}`}
              extraClass={!(disable) && 'reject'}
              clickHandler={(event) => {return hasError ? onOk(event, true) : handleRejection(event);}}
          />
            <Button
              label={t('tkCancel')}
              customClass='button-secondary-small'
              clickHandler={() => onClose()}
          />
          </div>
        </div>
      </div>
    </Modal>
  );
};

RejectionModal.propTypes = {
  currentRowData: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleRejectionReasonChange: PropTypes.func,
  handleRejection: PropTypes.func,
  rejectionErrorOrWarning: PropTypes.func,
  reasonsList: PropTypes.object,
  isRejectionInProgress: PropTypes.bool,
  handleOk: PropTypes.func,

};

export default RejectionModal;
