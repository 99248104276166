import React, { useState, useEffect } from 'react';
import { Switch } from '@gs-ux-uitoolkit-react/switch';
import PropTypes from 'prop-types';
import { YES, NO, BOOLEAN } from '../../constants';

const SwitchField = props => {
  const { handleChange, value, config } = props;
  const { dataType } = config;
  const initialToggleValue = () => (dataType === BOOLEAN ? !!value : value === YES);
  const [toggleValue, setToggleValue] = useState(initialToggleValue);

  useEffect(() => {
    setToggleValue(initialToggleValue());
  }, [value]);

  const onSwitchToggle = event => {
    setToggleValue(event.target.checked);
    handleChange({
      event,
      value: dataType === BOOLEAN ? event.target.checked : event.target.checked ? YES : NO,
      config,
    });
  };

  return <Switch inline toggledOn={toggleValue} onChange={onSwitchToggle} className='edit-switch' size='sm' />;
};

SwitchField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  handleChange: PropTypes.func,
  config: PropTypes.object,
};

export default SwitchField;
