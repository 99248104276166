/**
 * Determines if two sets, represented by arrays, have all of the same elements.
 * @param a first array to compare
 * @param b second array to compare
 */
export const arraySetsEqual = (a: any[], b: any[]): boolean => {
    if (a.length !== b.length) {
        return false;
    }

    const set = new Set(a);
    b.forEach(el => set.delete(el));

    return set.size === 0;
};
