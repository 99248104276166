import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import PageHeader from '../../../../components/app/PageHeader';
import translator from '../../../../services/translator';
import {onPreferencesPageLoadAnalytics} from '../../analytics';
import {eventAnalytics} from "../../../../actions/sagas";
import {isDataLoadingSelector} from '../../../../selectors/pages/preferences/investmentPolicy';
import TabPanel from '../../../../components/core/TabPanel';
import {changeInvestmentPolicyTab} from '../../../../actions/page/preferences/investmentPolicy';
import {Conditional} from '../../../../components/core/Conditional';
import {ThreeSquaresLoader} from '../../../../components/core/Loaders';
import './index.scss';

const {translate: t} = translator;

export const InvestmentPolicy = ({subRoutes, dispatchTabChange, isPolicyDataLoading, onPageLoad}) => {
  const onTabChange = (tabId) => {
    dispatchTabChange(tabId);
  };

  useEffect(() => {
    onPageLoad()
  }, [])

  const testId = 'investment-policy';
  return (
    <div className="invt-policy" data-testid={testId}>
      <PageHeader title={t('tkInvestmentPolicy')} description={t('tkInvestmentPolicySubTitle')} type='Secondary'  />
      <div className="invt-policy__divider" />
      <Conditional condition={!isPolicyDataLoading && subRoutes}>
        <TabPanel  tabs={subRoutes} onTabChange={onTabChange}  testId={`${testId}-tab-panel`} />
        <ThreeSquaresLoader />
      </Conditional>
    </div>
  );
};

InvestmentPolicy.propTypes = {
  subRoutes: PropTypes.array,
  dispatchTabChange: PropTypes.func,
  onPageLoad: PropTypes.func,
  isPolicyDataLoading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isPolicyDataLoading: isDataLoadingSelector(state)
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchTabChange: (data) => dispatch(changeInvestmentPolicyTab(data)),
    onPageLoad: () => dispatch(eventAnalytics(onPreferencesPageLoadAnalytics()))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestmentPolicy);
