import { CSSProperties, ReactNode, FunctionComponent, useContext, ElementType } from 'react';
import PropTypes from 'prop-types';
import {
    FormFeedbackProps as FormFeedbackCommonProps,
    defaultFormFeedbackProps,
    formFeedbackStyleSheet,
    formSizes,
    FormStatus,
    formStatuses,
    getFormFeedbackClassNames,
    FormSize,
} from '@gs-ux-uitoolkit-common/form';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { FormContext } from './form-context';

export interface FormFeedbackProps extends FormFeedbackCommonProps<CSSProperties> {
    children?: ReactNode;

    /**
     * Specify the container HTML tag. Default is `p`.
     */
    tag?: string;
}

/**
 * FormFeedback contains text that provides validation information about a
 * specific input such as an error or a warning. It differs from FormText in that
 * FormText is used to convey static information that does not change with a
 * validation state.
 *
 * When using both a FormText and a FormFeedback to convey information about a
 * single input component, it is highly recommended that only one of them is
 * displayed at a time.
 */
export const FormFeedback: FunctionComponent<FormFeedbackProps> = (props: FormFeedbackProps) => {
    const {
        children,
        className,
        disabled,
        size,
        tag = 'p',
        status: propStatus,
        ...otherProps
    } = props;

    const formContext = useContext(FormContext);
    const formFeedbackSize = size || formContext.size || defaultFormFeedbackProps.size;
    const formFeedbackDisabled =
        disabled !== undefined
            ? disabled
            : formContext.disabled || defaultFormFeedbackProps.disabled;

    function getStatus(): FormStatus {
        return propStatus || formContext.status || 'none';
    }

    function canDisplay() {
        const contextStatus = formContext.status;
        const isContextInvalid = contextStatus === 'warning' || contextStatus === 'error';
        if (propStatus === undefined && isContextInvalid) {
            return true;
        } else if (
            propStatus === 'warning' ||
            propStatus === 'error' ||
            propStatus === 'success' ||
            propStatus === 'information'
        ) {
            return true;
        }
        return false;
    }

    const theme = useTheme();
    const cssClasses: any = useStyleSheet(formFeedbackStyleSheet, {
        theme: theme,
        size: formFeedbackSize as FormSize,
        status: getStatus(),
        disabled: formFeedbackDisabled || false,
    });
    const containerClasses = getFormFeedbackClassNames({ cssClasses, className });

    const Tag = (tag || 'p') as ElementType<FormFeedbackProps>;
    return canDisplay() ? (
        <Tag
            data-gs-uitk-component="form-feedback"
            className={containerClasses}
            {...otherProps}
            data-size={formFeedbackSize}
            data-disabled={formFeedbackDisabled}
        >
            {children}
        </Tag>
    ) : null;
};

FormFeedback.propTypes = {
    tag: PropTypes.string,
    size: PropTypes.oneOf(formSizes),
    status: PropTypes.oneOf(formStatuses),
};
