import { Component, Context } from 'react';
import { connect, ReactReduxContextValue } from 'react-redux';
import { WidgetContentCommonProps } from '@gs-ux-uitoolkit-common/datacore';
import { SearchInput } from '../../../../components/search/search-input';
import { SetSearch, SetSearchAction } from '../../../../redux/actions/searchable-action';
import { DataGridState } from '../../../../redux/datagrid-state';
import {
    searchWidgetStyleSheet,
    getSearchInputWrapperClassNames,
} from '../../../../style/widget/search-widget-stylesheet';

interface SearchablePluginWidgetComponentReduxProps {
    /**
     * A function that dispatches an action to set a filter on the grid
     */
    submitSearch: (search: string) => void;
    /**
     * The current search on the grid
     */
    currentSearch: string;
    /**
     * The debounce time from the system configuration
     */
    debounceTime: number;
}

export interface SearchablePluginWidgetComponentProps
    extends SearchablePluginWidgetComponentReduxProps,
        WidgetContentCommonProps {}

/**
 * The Searchable Plugin Widget Component
 */
export class SearchablePluginWidgetComponent extends Component<SearchablePluginWidgetComponentProps> {
    public componentWillUnmount() {
        searchWidgetStyleSheet.unmount(this);
    }
    public render() {
        const size = this.props.widgetContentCommonProps?.size;
        const cssClasses = searchWidgetStyleSheet.mount(this, null);
        return (
            <div className={getSearchInputWrapperClassNames({ cssClasses })}>
                <SearchInput
                    value={this.props.currentSearch}
                    onChange={this.props.submitSearch}
                    debounceTime={this.props.debounceTime}
                    placeholder={'Search'}
                    size={size}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: DataGridState) => {
    return {
        currentSearch: state.searchable.currentSearch,
        debounceTime:
            state.systemConfiguration.gridWrapperConfiguration.uiConfiguration!.debounceTime,
    };
};

const mapDispatchToProps = (dispatch: (action: SetSearchAction) => void) => {
    const submitSearch = (search: string) => {
        dispatch(SetSearch(search));
    };
    return {
        submitSearch,
    };
};

export const SearchablePluginWidget = (context?: Context<ReactReduxContextValue>) => {
    return connect(mapStateToProps, mapDispatchToProps, null, { context })(
        SearchablePluginWidgetComponent
    );
};
