import { Component, Fragment, ChangeEvent } from 'react';
import { FormGroup } from '@gs-ux-uitoolkit-react/form';
import { InputNumber, InputGroup } from '@gs-ux-uitoolkit-react/input';
import { Label } from '@gs-ux-uitoolkit-react/label';
import { HintList } from '@gs-ux-uitoolkit-common/datacore';
import { FormatControlProps } from '../column-hint-format-manager';
import { columnFormattingPluginStylePrefix } from '../../../../../style/plugins/column-hint-plugin-stylesheet';

export class ColumnHintControlFontSize extends Component<FormatControlProps> {
    public render() {
        return (
            <Fragment>
                <FormGroup>
                    <Label>Text Size (px)</Label>
                    <InputGroup
                        className={`${columnFormattingPluginStylePrefix}_text-size-container`}
                    >
                        <InputNumber
                            value={(this.props.columnHint.fontSize || '').replace(/\D/g, '')} // Remove alpha chars, i.e the px at the end
                            placeholder="e.g. 20"
                            onChange={this.onFontSizeChanged}
                        />
                    </InputGroup>
                </FormGroup>
            </Fragment>
        );
    }

    private onFontSizeChanged = (e: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);

        const absValue = Math.abs(value);

        if (!isNaN(absValue)) {
            const clonedHint: HintList = {
                ...this.props.columnHint,
                fontSize: Math.abs(value) + 'px',
            };
            this.props.onChangeHint(clonedHint);
        } else {
            const clonedHint: HintList = {
                ...this.props.columnHint,
            };
            delete clonedHint.fontSize;

            this.props.onChangeHint(clonedHint);
        }
    };
}
