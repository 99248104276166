import { getEmotionInstance } from './emotion/emotion';

// This interface is used by injectGlobal but without importing it here and
// using an explicit type for `injectGlobal`, this creates a type error for
// consumers of inject-global.d.ts which can't find the type `CSSInterpolation`
import type { CSSInterpolation } from '@emotion/css/types/create-instance';

/**
 * Injects a global CSS rule into the document.
 *
 * You normally will not want to use this function, but it is needed for things like
 * @font-face rules. However, the {@link #injectFont} function is an easier way
 * to inject an @font-face than this function.
 */
export const injectGlobal: {
    (template: TemplateStringsArray, ...args: CSSInterpolation[]): void;
    (...args: CSSInterpolation[]): void;
} = getEmotionInstance().injectGlobal;
