import { cx } from '@gs-ux-uitoolkit-common/style';
import { TextCssClasses } from './text-style-sheet';
import { TextCommonProps } from './text-props';

const globalClassName = 'gs-text';

export const getTextRootClassNames = (props: {
    cssClasses: TextCssClasses;
    overrideClasses?: TextCommonProps['classes'];
    className?: TextCommonProps['className'];
}): string => {
    const { cssClasses, overrideClasses, className } = props;

    return cx(cssClasses.root, className, overrideClasses && overrideClasses.root, globalClassName);
};
