import moment from 'moment';
import get from 'lodash/get';
import { formatDate } from '../../../utils/dateFormatter';
import actionTypes from '../../../actions/page/reports/actionTypes';
import { replaceActiveView, updateActiveView, replaceMatchedView } from '../../../helpers/gridFund';

export function reportPageDataReducer(state = {}, action) {
  const { type, data = {} } = action;
  switch (type) {
    case actionTypes.ADD_REPORT_LOADED_DATA: {
      const {columnsMetadata, groupColumns } = data;
      return { ...state, columnsMetadata, groupColumns };
    }
    default:
      return state;
  }
}

export function reportPageContextReducer(state = {}, action) {
  const { type, data = {} } = action;
  switch (type) {
    case actionTypes.OPEN_CUSTOM_VIEW_MODAL_REPORT:
    case actionTypes.CLOSE_CUSTOM_VIEW_MODAL_REPORT:
    case actionTypes.APPLY_FILTER_REPORT:
    case actionTypes.SAVE_CUSTOM_REPORT_PENDING: {
      return {...state, ...data};
    }
    case actionTypes.ADD_REPORT_DATA: {
      let newData = data;
      // safely get report columns
      const columns = get(state, 'views[0].columns', []);
      const isReportDateExists = columns.filter((col) => col.field === 'reportDate')
      if(isReportDateExists.length>0){
        const {reportData} = data.reportResponse;
        const today = moment().toDate();
        const formattedDate = formatDate(today, 'YYYY-MM-DD', 'DD.MMM.YYYY');
        const dataWithReportDate = reportData && reportData.map((row) => ({...row, reportDate: formattedDate}));
        newData = {...data, reportResponse: {reportData: dataWithReportDate} }
      }
      return { ...state, ...newData };
    }
    case actionTypes.SAVE_CUSTOM_VIEW_REPORT:
    case actionTypes.DELETE_CUSTOM_VIEW_REPORT:
    case actionTypes.EDIT_CUSTOM_VIEW_REPORT: {
      return { ...state, isModalLoading: true };
    }
    case actionTypes.START_EXPORT_TO_EXCEL_REPORT: {
      return { ...state, exportExcel: data };
    }
    case actionTypes.REPORT_GRID_LOADING: {
      return {  ...state, loading: data };
    }
    case actionTypes.CHANGE_GROUP_REPORT: {
      return { ...state, groupBy: data.groupBy};
    }
    // TODO: will be removed if partial view data implementation is done
    case actionTypes.CHANGE_VIEW_REPORT:
      return { ...state, ...updateActiveView(state.views, data.id)};
    case actionTypes.CHANGE_COLUMN_ORDER_REPORT:
    case actionTypes.CHANGE_COLUMN_WIDTH_REPORT: {
      return { ...state, views: replaceActiveView(state.views, data)};
    }
    case actionTypes.CHANGE_COLUMN_SORT_REPORT: {
      if (!state.reportResponse) {
        return state;
      }
      const views = replaceMatchedView(state.views, data);
      return { ...state, views };
    }
    case actionTypes.ADD_REPORT_LOADED_DATA: {
      const { views, groupBy, filterBy, isOpenCustomViewModal, loading, tableData} = data;
      return { ...state, views, groupBy, filterBy, isOpenCustomViewModal, loading, table: { data: tableData } };
    }
    case actionTypes.ADD_GROUP_CHANGED_DATA_REPORT: {
      const { loading} = data;
      return { ...state, loading };
    }
    case actionTypes.ADD_SAVE_VIEW_DATA_REPORT: {
      const { views, isOpenCustomViewModal, isModalLoading } = data;
      return { ...state, views, isOpenCustomViewModal, isModalLoading };
    }
    case actionTypes.ADD_EDIT_DELETE_VIEW_DATA_REPORT: {
      const { views, isOpenCustomViewModal, isModalLoading, groupBy } = data;
      return { ...state, views, isOpenCustomViewModal, isModalLoading, groupBy };
    }
    case actionTypes.MODAL_LOADING_REPORT: {
      return { ...state, isModalLoading: data.isModalLoading };
    }
    case actionTypes.TOGGLE_FILTER_VISIBILITY_REPORT: {
      return { ...state, filterVisibility: data.filterVisibility, filterBy: [], filterModel: null };
    }
    case actionTypes.CHANGE_FILE_TYPE: {
      return {...state, selectedFileType: data};
    }
    case actionTypes.SET_REPORT_DATA_TO_DELETE: {
      return {...state, reportDataToDelete: data};
    }
    case actionTypes.HIDE_REPORT_ROLLBACK_SNACKBAR_MSG: {
      const {isHideReportRollBackSnackBarMsg} = data;
      return { ...state, isHideReportRollBackSnackBarMsg};
    }
    case actionTypes.CLEAR_REPORT_SERVICE_ERROR: {
      return {...state, errorObject: undefined, isPageInErrorMode: false};
    }
    default:
      return state;
  }
}

