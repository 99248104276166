import React from "react";
import PropTypes from "prop-types";
import { Select } from "@gs-ux-uitoolkit-react/select";
import { Input } from "@gs-ux-uitoolkit-react/input";
import classNames from "classnames";
import {connect} from "react-redux";
import { dropdownData } from "./dropdownOptions";
import { DROPDOWN, INPUT, INVEST_ONLY } from "../../constants";
import "./index.scss";
import translator from "../../../../../../services/translator";
import {isInternalUserSelector} from '../../../../../../selectors/user';

const { translate: t } = translator;

const ItemEdit = ({
  value,
  handleEditFields,
  field,
  fieldType,
  isTruist,
  errorState,
  charsLengthRange,
  isInternal,
  maxLength
}) => {
  const disableSelect = field === INVEST_ONLY && !(isTruist || isInternal);

  const checkError = () => {
    if (fieldType === INPUT) {
      if (errorState && Object.keys(errorState).length && errorState[field]) {
        return true;
      }
    }

    return false;
  };

  const myOptionRenderer = ({ label }) => {
    return <span>{t(label)}</span>;
  };

  const renderFields = () => {
    switch (fieldType) {
      case INPUT:
        return (
          <>
            <Input
              className={classNames("factData-item-edit__input", {
                "input-error": checkError(),
              })}
              size="sm"
              value={value}
              maxLength={maxLength}
              onChange={(e) =>
                handleEditFields(
                  e.target.value,
                  field,
                  fieldType,
                  charsLengthRange
                )
              }
              status={checkError() ? "error" : "none"}
            />
            {checkError() && (
              <div data-testid="error-container" className="factData-item-edit__error">
                {t('tkDebitCreditInvalidInputText')}
              </div>
            )}
          </>
        );
      case DROPDOWN:
        return (
          <Select
            options={dropdownData[field]}
            optionRenderer={myOptionRenderer}
            selectedOptionRenderer={myOptionRenderer}
            onSelect={(val) =>
              handleEditFields(val.selectedValue, field, fieldType)
            }
            selectedValue={value}
            clearable={false}
            className="factData-item-edit__dropdown"
            size="sm"
            disabled={disableSelect}
            data-testid="item-edit-select"
          />
        );
      default:
        return null;
    }
  };

  return (
    <span data-testid="item-edit-container" className="factData-item-edit">
      {renderFields()}
    </span>
  );
};

ItemEdit.propTypes = {
  value: PropTypes.string,
  handleEditFields: PropTypes.func,
  field: PropTypes.string,
  fieldType: PropTypes.string,
  isTruist: PropTypes.bool,
  errorState: PropTypes.object,
  charsLengthRange: PropTypes.array,
  isInternal: PropTypes.bool,
  maxLength: PropTypes.number
};

const mapStateToProps = (state) => ({
  isInternal: isInternalUserSelector(state)
});

export default connect(mapStateToProps)(ItemEdit);
