import {
    StyleSheet,
    CssPropertyType,
    CssClassDefinitionsObject,
} from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { SwitchSize, SwitchProps, SwitchStatus } from './switch-props';
import { DeepReadonly } from 'ts-essentials';
import './switch-theme-overrides';

export interface SwitchStyleSheetProps {
    theme: Theme;
    inline: SwitchProps['inline'];
    labelPlacement: SwitchProps['labelPlacement'];
    size: SwitchSize;
    status: SwitchStatus;
}

export interface SwitchCssClasses {
    root: string;
    input: string;
    label: string;
}

export type SwitchStyledClasses = CssClassDefinitionsObject<keyof SwitchCssClasses>;

export interface SwitchStyleOverridesParams {
    props: DeepReadonly<SwitchStyleSheetProps>;
    createDefaultStyledClasses: () => SwitchStyledClasses;
}

export const switchStyleSheet = new StyleSheet('switch', (props: SwitchStyleSheetProps) => {
    return createComponentClassDefinitions<SwitchStyleSheetProps, SwitchStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.switch
    );
});

const labelSelector = '~ span > label, ~ gs-label > label';

function createDefaultStyledClasses({
    theme,
    inline,
    labelPlacement,
    size,
    status,
}: SwitchStyleSheetProps): SwitchStyledClasses {
    const switchTheme = {
        handleColor: theme.colorScheme.primary,
        trackColor: theme.decreaseContrast(theme.colorScheme.primary, 3), // colors.blue030,
    };

    const errorStyles =
        status === 'error'
            ? {
                  '&:focus&:checked': {
                      [labelSelector]: {
                          '&::after': {
                              backgroundColor: theme.status.error.bold,
                          },
                      },
                  },
                  [labelSelector]: {
                      '&::before': {
                          backgroundColor: theme.status.error.subtle,
                      },
                      '&::after': {
                          backgroundColor: theme.status.error.bold,
                      },
                  },
              }
            : {};

    return {
        root: {
            display: inline ? 'inline-flex' : 'flex',
            paddingLeft: labelPlacement === 'right' ? '36px' : 0,
            paddingRight: labelPlacement === 'left' ? '36px' : 0,

            // Solves a bug where the 'input' element was being positioned thousands of pixels
            // away from the Switch's own location, and when the Switch was focused, the browser
            // would scroll the page to the <input>'s position thousands of pixels away. (Issue
            // most apparent in Styleguidist without this style)
            position: 'relative',
        },

        input: {
            opacity: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '16px',

            '&:focus': {
                [labelSelector]: {
                    '&::after': {
                        border: `1px solid ${
                            status === 'error' ? theme.status.error.bold : theme.surface.primary
                        }`,
                        boxShadow:
                            status === 'error'
                                ? `0 0 0 1px ${theme.surface.primary}, 0 0 0 2px ${theme.status.error.bold}`
                                : `0 0 0 1px ${theme.colorScheme.primary}`,
                    },
                },
            },

            '&:checked': {
                [labelSelector]: {
                    '&::before': {
                        backgroundColor:
                            status === 'error' ? theme.status.error.subtle : switchTheme.trackColor,
                    },
                    '&::after': {
                        backgroundColor:
                            status === 'error' ? theme.status.error.bold : switchTheme.handleColor,
                        transform: switchSizeVariants[size].handleSelectedTransform,
                    },
                },
            },

            '&:active&:checked': {
                [labelSelector]: {
                    '&::before': {
                        backgroundColor:
                            status === 'error' ? theme.status.error.subtle : switchTheme.trackColor,
                    },
                },
            },

            '&:disabled': {
                [labelSelector]: {
                    cursor: 'default',
                },
            },
            ...errorStyles,
        },

        label: {
            cursor: 'pointer',
            marginBottom: 0,
            lineHeight: '26px',
            marginLeft: labelPlacement === 'right' ? '-36px' : 0,
            marginRight: labelPlacement === 'left' ? '-36px' : 0,
            paddingLeft: labelPlacement === 'right' ? switchSizeVariants[size].labelPaddingLeft : 0,
            paddingRight: labelPlacement === 'left' ? switchSizeVariants[size].labelPaddingLeft : 0,

            '&::before': {
                backgroundColor: theme.surface.moderate,
                borderRadius: '8px',
                content: '""',
                left: labelPlacement === 'right' ? 0 : 'auto',
                right: labelPlacement === 'left' ? 0 : 'auto',
                pointerEvents: 'all',
                position: 'absolute',
                transition:
                    'background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                height: switchSizeVariants[size].trackHeight,
                top: switchSizeVariants[size].trackTop,
                width: switchSizeVariants[size].trackWidth,
            },
            '&::after': {
                backgroundColor: theme.surface.bold,
                borderRadius: '50%',
                content: '""',
                left: labelPlacement === 'right' ? 0 : 'auto',
                right: labelPlacement === 'left' ? '12px' : 'auto',
                position: 'absolute',
                transition:
                    'transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                height: switchSizeVariants[size].handleHeight,
                top: switchSizeVariants[size].handleTop,
                width: switchSizeVariants[size].handleWidth,
            },
        },
    };
}

const switchSizeVariants: { [name in SwitchSize]: SwitchSizeVariant } = {
    sm: {
        handleHeight: '12px',
        handleSelectedTransform: 'translateX(12px)',
        handleTop: '8px',
        handleWidth: '12px',

        labelPaddingLeft: '30px',

        trackHeight: '4px',
        trackTop: '12px',
        trackWidth: '22px',
    },
    md: {
        handleHeight: '16px',
        handleSelectedTransform: 'translateX(12px)',
        handleTop: '5px',
        handleWidth: '16px',

        labelPaddingLeft: '36px',

        trackHeight: '4px',
        trackTop: '11px',
        trackWidth: '28px',
    },
    lg: {
        handleHeight: '20px',
        handleSelectedTransform: 'translateX(18px)',
        handleTop: '3px',
        handleWidth: '20px',

        labelPaddingLeft: '54px',

        trackHeight: '6px',
        trackTop: '10px',
        trackWidth: '38px',
    },
};

interface SwitchSizeVariant {
    handleHeight: CssPropertyType['height'];
    handleSelectedTransform: CssPropertyType['transform'];
    handleTop: CssPropertyType['top'];
    handleWidth: CssPropertyType['width'];

    labelPaddingLeft: CssPropertyType['paddingLeft'];

    trackHeight: CssPropertyType['height'];
    trackTop: CssPropertyType['top'];
    trackWidth: CssPropertyType['width'];
}
