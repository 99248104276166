import { ExportOptions, ExportType } from '../../plugins/exports/export';
import { ExportsActionKeys } from './action-constants';

export type ExportsActionTypes =
    | CreateExportAction
    | SetExportOptionsAction
    | CreateExportByTypeAction;

export interface CreateExportAction {
    type: ExportsActionKeys.CREATE_EXPORT;
}
export interface CreateExportByTypeAction {
    exportType: ExportType;
    type: ExportsActionKeys.CREATE_EXPORT_BY_TYPE;
}

export interface SetExportOptionsAction {
    type: ExportsActionKeys.SET_OPTIONS;
    options: ExportOptions;
}

export const CreateExport = (): CreateExportAction => ({ type: ExportsActionKeys.CREATE_EXPORT });

export const CreateExportByType = (exportType: ExportType): CreateExportByTypeAction => ({
    exportType,
    type: ExportsActionKeys.CREATE_EXPORT_BY_TYPE,
});

export const SetExportOptions = (options: ExportOptions): SetExportOptionsAction => ({
    options,
    type: ExportsActionKeys.SET_OPTIONS,
});
