/* eslint-disable max-len */
/**
 *
 * @type {{user: {}, preferences: {}, viewData: {}, viewContext: {}, appData: {}, appContext: {}}}
 * user: Contains data related to the user.
 * preferences: Contains all the preferences
 * viewData: Contains data related to the current page. It would contain all the data fetched from the server, in it's original form
 * viewContext: Contains updated state of components which would affect other components in the current page.
 * appData: Contains data related to the application. It would contain data in it's original form.
 * appContext: Contains updated state which would affect other pages in the application.
 *
 */


const initialState = {
  'appData': {},
  'appContext': {},
  'preferences': {},
  'user': {},
  'pageData': {},
  'pageContext': {}
};

export default initialState;
