import { Component, ReactElement, Fragment, Context } from 'react';
import { connect } from 'react-redux';
import {
    QuickFilter,
    QuickFilterActionTypes,
    SetEnabledQuickFilterList,
    WidgetContentCommonProps,
} from '@gs-ux-uitoolkit-common/datacore';
import { Dropdown, DropdownButton, DropdownMenu } from '@gs-ux-uitoolkit-react/dropdown';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { ButtonDisplay } from '../../../../components/buttons/button-base';
import { ButtonDelete } from '../../../../components/buttons/button-delete';
import { QuickFilterSelector } from '../../../../components/quick-filter/quick-filter-selector';
import { SearchInput } from '../../../../components/search/search-input';
import { GridColumn } from '../../../../grid-wrappers/grid-wrapper';
import { SearchableActionTypes, SetSearch } from '../../../../redux/actions/searchable-action';
import { DataGridState } from '../../../../redux/datagrid-state';
import {
    gridRemoveColumnFilter,
    gridRemoveAllColumnFilters,
    GridActionTypes,
} from '../../../../redux/actions/grid-action';
import { Link } from '@gs-ux-uitoolkit-react/text';
import { quickfilterStyleSheet } from '../../../../style/plugin/quickfilter-plugin-stylesheet';
import { ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';
import { ReactReduxContextValue } from 'react-redux';

interface EnhancedQuickFilterPluginWidgetComponentReduxProps {
    columnIdListFiltered: string[];
    columnList: GridColumn[];
    debounceTime: number;
    quickFilterList: QuickFilter[];
    searchableValue: string;
    setEnabledQuickFilterList: (quickFilterList: QuickFilter[]) => void;
    submitSearch: (search: string) => void;
    removeAllColumnFilters: () => void;
    removeColumnFilter: (columnId: string) => void;
}

export interface EnhancedQuickFilterPluginWidgetComponentProps
    extends EnhancedQuickFilterPluginWidgetComponentReduxProps,
        WidgetContentCommonProps {}

/**
 * The Enhanced QuickFilter Plugin Widget Component
 */
export class EnhancedQuickFilterPluginWidgetComponent extends Component<EnhancedQuickFilterPluginWidgetComponentProps> {
    public componentWillUnmount() {
        quickfilterStyleSheet.unmount(this);
    }

    public render() {
        return (
            <ThemeConsumer>
                {theme => {
                    const cssClasses = quickfilterStyleSheet.mount(this, { theme });
                    return (
                        <Dropdown className="gs-uitk-toolbar-widget-dropdown">
                            <DropdownButton
                                actionType="secondary"
                                emphasis="minimal"
                                size={this.props.widgetContentCommonProps?.size || 'sm'}
                                className="gs-uitk-toolbar-widget-dropdown-button"
                            >
                                <Icon
                                    name="filter-alt"
                                    type="filled"
                                    size={this.props.widgetContentCommonProps?.size || 'sm'}
                                />
                                <span className="gs-uitk-toolbar-widget-dropdown-label">
                                    Search & Filters
                                </span>
                            </DropdownButton>
                            <DropdownMenu className="toolbar-plugin-menu widget-container">
                                <div
                                    className={`gs-uitk-enhanced-quick-filter-container ${cssClasses.root}`}
                                >
                                    {this.renderRemoveAllFilters()}
                                    <SearchInput
                                        value={this.props.searchableValue}
                                        onChange={this.props.submitSearch}
                                        debounceTime={this.props.debounceTime}
                                        placeholder="Search"
                                    />
                                    <h6>Saved Filters</h6>
                                    <QuickFilterSelector
                                        quickFilterList={this.props.quickFilterList}
                                        setEnabledQuickFilterList={
                                            this.props.setEnabledQuickFilterList
                                        }
                                    />
                                    <h6>Column Filters</h6>
                                    {this.renderColumnFilters()}
                                </div>
                            </DropdownMenu>
                        </Dropdown>
                    );
                }}
            </ThemeConsumer>
        );
    }

    private filterActive(): boolean {
        return !!(
            this.props.searchableValue ||
            this.props.quickFilterList.filter(x => x.isEnabled).length > 0 ||
            this.props.columnIdListFiltered.length > 0
        );
    }

    private renderRemoveAllFilters(): ReactElement | null {
        if (this.filterActive()) {
            return (
                <Link
                    className="gs-uitk-clear-all-filters"
                    onClick={e => {
                        e.preventDefault();
                        this.clearAllFilters();
                    }}
                >
                    Clear all filters
                </Link>
            );
        }
        return null;
    }

    private renderColumnFilters(): ReactElement {
        const columnFilters = this.props.columnIdListFiltered.map(columnId => {
            const foundColumn = this.props.columnList.find(column => {
                return column.columnId === columnId;
            });
            let nameToDisplay = columnId;

            if (foundColumn && foundColumn.columnLabel) {
                nameToDisplay = foundColumn.columnLabel;
            }

            return (
                <div
                    key={columnId}
                    className="gs-uitk-enhanced-quick-filter-column-filters-name-container"
                >
                    <ButtonDelete
                        onClick={() => {
                            this.removeColumnFilter(columnId);
                        }}
                        size={'sm'}
                        actionType={'secondary'}
                        displayType={ButtonDisplay.Icon}
                    />
                    <div className="gs-uitk-enhanced-quick-filter-column-filters-name">
                        {nameToDisplay}
                    </div>
                </div>
            );
        });

        const columnsWithFilters =
            columnFilters.length > 0 ? columnFilters : <div>No filters have been created yet.</div>;

        return (
            <Fragment>
                {columnFilters.length > 2 && (
                    <ButtonDelete
                        label="Remove All Column Filters"
                        displayType={ButtonDisplay.IconLabel}
                        actionType={'secondary'}
                        size={'sm'}
                        onClick={() => this.props.removeAllColumnFilters()}
                    />
                )}
                <div className="gs-uitk-enhanced-quick-filter-column-filters">
                    {columnsWithFilters}
                </div>
            </Fragment>
        );
    }

    private removeColumnFilter(columnId: string) {
        this.props.removeColumnFilter(columnId);
    }

    private clearAllFilters() {
        this.props.submitSearch('');
        this.props.setEnabledQuickFilterList([]);
        this.props.removeAllColumnFilters();
    }
}

const mapStateToProps = (state: DataGridState) => {
    return {
        columnIdListFiltered: state.grid.columnIdListFiltered,
        columnList: state.grid.primaryColumnList,
        debounceTime:
            state.systemConfiguration.gridWrapperConfiguration.uiConfiguration!.debounceTime,
        quickFilterList: state.quickFilter.configItemList,
        searchableValue: state.searchable.currentSearch,
    };
};

const mapDispatchToProps = (
    dispatch: (action: QuickFilterActionTypes | SearchableActionTypes | GridActionTypes) => void
) => {
    return {
        removeAllColumnFilters: () => {
            dispatch(gridRemoveAllColumnFilters());
        },
        removeColumnFilter: (columnId: string) => {
            dispatch(gridRemoveColumnFilter(columnId));
        },
        setEnabledQuickFilterList: (quickFilterList: QuickFilter[]) =>
            dispatch(SetEnabledQuickFilterList(quickFilterList)),
        submitSearch: (search: string) => {
            dispatch(SetSearch(search));
        },
    };
};

export const getEnhancedQuickFilterPluginWidget = (context?: Context<ReactReduxContextValue>) =>
    connect(mapStateToProps, mapDispatchToProps, null, { context })(
        EnhancedQuickFilterPluginWidgetComponent
    );
