export const mosaicDefaultOptions = {
  method: 'POST',
  mode: 'cors',
  redirect: 'manual',
  credentials: 'include',
  'Access-Control-Allow-Origin': '*',
  headers: new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Requested-By': 'Any',
    'Cache-Control': 'no-cache, must-revalidate, post-check=0, pre-check=0, max-age=0',
    'Expires': 'Tue, 01 Jan 1980 1:00:00 GMT',
    'Pragma': 'no-cache'
  })
};

export const graphQLOptions = {
  method: 'POST',
  'Access-Control-Allow-Origin': '*',
  headers: new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Requested-By': 'Any',
    'Cache-Control': 'no-cache, must-revalidate, post-check=0, pre-check=0, max-age=0',
    'Expires': 'Tue, 01 Jan 1980 1:00:00 GMT',
    'Pragma': 'no-cache'
  })
};

export const pingDefaultOptionsInitialLogin = {
  mode: 'cors',
  credentials: 'include',
  redirect: 'follow',
  headers: new Headers({
    'Accept': 'text/html, application/xhtml+xml, application/xml;q=0.9, */*;q=0.8'
  })
};

export const pingDefaultOptionsDefault = {
  mode: 'cors',
  credentials: 'include',
  redirect: 'follow',
  headers: new Headers({
    'Accept': '*/*',
    'Content-Type': 'text/html; charset=utf-8'
  })
};
