import { cx } from '@gs-ux-uitoolkit-common/style';
import { LabelProps } from './label-props';
import { LabelCssClasses } from './label-style-sheet';

const globalClassName = 'gs-label';

export const getLabelRootClassNames = (props: {
    cssClasses: LabelCssClasses;
    overrideClasses?: LabelProps['classes'];
    className?: LabelProps['className'];
}): string => {
    const { cssClasses, className, overrideClasses } = props;
    return cx(cssClasses.root, className, overrideClasses && overrideClasses.root, globalClassName);
};

export const getLabelClassNames = (props: {
    cssClasses: LabelCssClasses;
    overrideClasses?: LabelProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;

    return cx(
        cssClasses.label,
        overrideClasses && overrideClasses.label,
        `${globalClassName}__label`
    );
};
