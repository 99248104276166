import { PropsWithChildren, useEffect, useState, FC } from 'react';

/**
 * Helper component to render the children only on the client side. This defers the rendering
 * to the client side. Avoid using this as a standard way to avoid rendering on the server side.
 * This is intended to prevent SSR errors only for components that have 3rd party dependencies
 * that do not support SSR
 */
export const NoSsr: FC<PropsWithChildren> = ({ children }) => {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    return mounted ? <>{children}</> : null;
};
