import { ComponentProps, CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { Placement, Size, Status } from '@gs-ux-uitoolkit-common/design-system';
import { SwitchCssClasses } from './switch-style-sheet';

export interface SwitchProps<StyleType = CommonStyleType> extends ComponentProps<StyleType> {
    /**
     * Style classes to override.
     */
    classes?: Partial<SwitchCssClasses>;

    /**
     * Controls the switch's toggle state.
     */
    toggledOn?: boolean;

    /**
     * Sets an initial {@link #toggledOn} state when the switch is uncontrolled.
     */
    defaultToggledOn?: boolean;

    /**
     * Whether or not the switch is in a disabled state.
     */
    disabled?: boolean;

    /**
     * The name to set for the control.
     */
    name?: string;

    /**
     * Size of the switch.
     */
    size?: SwitchSize;

    /**
     * Status of the switch.
     */
    status?: SwitchStatus;

    /**
     * By default, the component displays as "block".
     */
    inline?: boolean;

    /**
     * By default, the component displays label on right
     */
    labelPlacement?: SwitchLabelPlacement;

    /**
     * Used for testing only. Replaced by an emit in Angular and overriden by a
     * custom onChange in React.
     * @ignore
     */
    onChange?: (event: any) => void;
}

export type SwitchSize = Extract<Size, 'sm' | 'md' | 'lg'>;
export type SwitchStatus = Extract<Status, 'none' | 'error'>;
export type SwitchLabelPlacement = Extract<Placement, 'left' | 'right'>;

export const switchSizes: SwitchSize[] = ['sm', 'md', 'lg'];
export const switchStatuses: SwitchStatus[] = ['none', 'error'];

export type SwitchDefaultProps = Required<
    Pick<SwitchProps, 'size' | 'status' | 'inline' | 'labelPlacement'>
>;

export const switchDefaultProps: SwitchDefaultProps = {
    size: 'md',
    status: 'none',
    inline: false,
    labelPlacement: 'right',
};

export interface SwitchChangeEventProps {
    toggledOn: boolean;
    name?: SwitchProps['name'];
}
