import {
    CellClassParams,
    ColDef,
    ICellRendererComp,
    ICellRendererParams,
} from '@ag-grid-community/core';
import { heatmapRendererStyleSheet } from '../style/renderer/heatmap-renderer-stylesheet';
import { CssClasses } from '@gs-ux-uitoolkit-react/style';

export enum ColorScales {
    Purple = 'purple',
    DarkerBlue = 'darker-blue',
    DarkBlue = 'dark-blue',
    Blue = 'blue',
    Teal = 'teal',
    Green = 'green',
    Red = 'red',
    Orange = 'orange',
    LightOrange = 'light-orange',
    Yellow = 'yellow',
    YellowGreen = 'yellow-green',
    Pastel = 'pastel',
}
interface ColorMap {
    [key: string]: string[];
}
const datavizColorMap: ColorMap = {};
const buildDatavizColorMap = () => {
    const colors = Object.values(ColorScales);

    // Most dataviz scales have 10 values currently so we can generate like this
    const generateColor = (colorName: string, numColors: number, reverse?: boolean): string[] => {
        const colorScale: string[] = [];
        for (let i = 1; i < numColors + 1; i = i + 1) {
            const val = reverse ? numColors + 1 - i : i;
            // dataviz colors start from 10, not 0
            colorScale.push(`dataviz-${colorName}-${val * 10}`);
        }
        return colorScale;
    };

    colors.forEach(color => {
        datavizColorMap[color] =
            color === ColorScales.YellowGreen
                ? generateColor(ColorScales.Yellow, 10, true).concat(
                      generateColor(ColorScales.Green, 10)
                  )
                : generateColor(color, 10);
    });
};
buildDatavizColorMap();

export interface HeatmapRendererParams {
    minValue: number;
    midValue?: number;
    maxValue: number;
    colorScale?: ColorScales;
}
export class HeatmapRenderer implements ICellRendererComp {
    private eGui: HTMLElement;
    private eValue: HTMLSpanElement | null;
    private minValue = Number.NEGATIVE_INFINITY;
    private midValue = 0;
    private maxValue = Number.POSITIVE_INFINITY;
    private colorScale = ColorScales.Pastel;
    private cssClasses: CssClasses<string> = heatmapRendererStyleSheet.mount(this, null);

    constructor() {
        this.eGui = document.createElement('div');
        this.eGui.innerHTML = '<div class="heatmap-renderer-value"></div>';

        this.eValue = this.eGui.querySelector('.heatmap-renderer-value');
    }
    public init(params: ICellRendererParams & HeatmapRendererParams): void {
        this.setDefaults(params);

        const colDef = (params.colDef = params.colDef || {});

        this.updatePaddingStyle(colDef);

        const valueDisplay =
            params.valueFormatted != null
                ? params.valueFormatted
                : params.value != null
                  ? params.value
                  : '';
        this.updateTemplate(valueDisplay);
    }
    private updatePaddingStyle(colDef: ColDef) {
        const originalCellStyle = (colDef.cellStyle = colDef.cellStyle || {});
        if (!(originalCellStyle instanceof Function)) {
            colDef.cellStyle = () => {
                return {
                    ...originalCellStyle,
                    padding: originalCellStyle.padding || '0px',
                };
            };
        } else {
            colDef.cellStyle = (cellClassParams: CellClassParams) => {
                const styles = originalCellStyle(cellClassParams);
                return {
                    ...styles,
                    padding: (styles && styles.padding) || '0px',
                };
            };
        }
    }
    public refresh(params: ICellRendererParams): boolean {
        const valueDisplay = params.valueFormatted
            ? params.valueFormatted
            : params.value
              ? params.value
              : '';
        this.updateTemplate(valueDisplay);
        // return true to tell the grid we refreshed successfully
        return true;
    }
    public getGui(): HTMLElement {
        return this.eGui;
    }
    public destroy(): void {
        heatmapRendererStyleSheet.unmount(this);
        // do cleanup, remove event listener from button
    }

    private setDefaults(params: HeatmapRendererParams) {
        this.minValue = params.minValue;
        this.maxValue = params.maxValue;
        this.midValue = params.midValue || (this.minValue + this.maxValue) / 2;
        this.colorScale = params.colorScale || ColorScales.Pastel;
    }

    private updateTemplate(valueDisplay: any) {
        if (this.eValue) {
            // set value into cell
            this.eGui.className = `heatmap-renderer-wrapper ${
                this.cssClasses.root
            } ${this.getHeatmapClass(valueDisplay)}`;
            this.eValue.innerHTML = valueDisplay;
        }
    }

    private getHeatmapClass(value: any) {
        // getHeatmapClass should only work with numbers
        if (isNaN(value)) {
            return '';
        }

        const numberDatavizColors = datavizColorMap[this.colorScale].length;
        const reversed = this.minValue > this.maxValue;
        // Clamp the value to between the min and max
        if (reversed) {
            value = Math.min(this.minValue, Math.max(value, this.maxValue));
        } else {
            value = Math.min(this.maxValue, Math.max(value, this.minValue));
        }
        let scaledResult = 0;
        if (reversed) {
            if (value > this.midValue) {
                scaledResult = this.scale(
                    value,
                    this.minValue,
                    this.midValue,
                    0,
                    numberDatavizColors / 2 - 1
                );
            } else {
                scaledResult = this.scale(
                    value,
                    this.midValue,
                    this.maxValue,
                    numberDatavizColors / 2,
                    numberDatavizColors - 1
                );
            }
        } else {
            if (value < this.midValue) {
                scaledResult = this.scale(
                    value,
                    this.minValue,
                    this.midValue,
                    0,
                    numberDatavizColors / 2 - 1
                );
            } else {
                scaledResult = this.scale(
                    value,
                    this.midValue,
                    this.maxValue,
                    numberDatavizColors / 2,
                    numberDatavizColors - 1
                );
            }
        }

        return datavizColorMap[this.colorScale][Math.round(scaledResult)];
    }

    /**
     * Scale a value from a domain to a range
     */
    private scale(
        value: number,
        domainMin: number,
        domainMax: number,
        rangeMin: number,
        rangeMax: number
    ) {
        return ((value - domainMin) * (rangeMax - rangeMin)) / (domainMax - domainMin) + rangeMin;
    }
}
