import map from 'lodash/map';
import {
  sortChange, applyFilter, addEditDeleteViewData
} from '../../../../actions/page/reports';
import {
  dispatchAnalytics, getColumnSearchAnalyticsData,
  getGridEndLoadAnalyticsData, getColumnSortingAnalyticsData
} from '../../analytics';
import {addToTradeBlotter, removeFromTradeBlotter} from '../../../../actions/sagas';
import { getSortParams } from '../../utils';

const mapDispatchToProps = (dispatch) => ({
  dispatchSortChange: (colId, columnName, order, props) => {
    const [updatedColId] = colId.split('_');
    const params = getSortParams(updatedColId, order, props);
    dispatch(sortChange(params));
    return dispatchAnalytics(dispatch, getColumnSortingAnalyticsData(order, columnName));
  },
  dispatchPageChange: data => dispatch(sortChange(data)),
  dispatchFilterChange: (filterModel, filteredDataLength) => {
    const filters = map(filterModel, (obj, key) => ({field: key, term: obj.filter}));
    dispatch(applyFilter({filters, filterModel, filteredDataLength}));
  },
  dispatchFilterFocus: data => {
    dispatchAnalytics(dispatch, getColumnSearchAnalyticsData(data.colDef.headerName));
  },
  dispatchGroupExpandCollapseChange: data => {
    dispatch(sortChange(data));
  },
  dispatchGridLoadEnd: (event, totalRecords, columns, selectedView, filterBy, time, snackbarList) => {
    dispatchAnalytics(dispatch, getGridEndLoadAnalyticsData(event, columns, selectedView, totalRecords, filterBy, time, snackbarList));
  },
  dispatchAddTrade: data => dispatch(addToTradeBlotter(data)),
  dispatchRemoveTrade: data => dispatch(removeFromTradeBlotter(data)),
  addEditDeleteViewData: data => dispatch(addEditDeleteViewData(data))
});

export default mapDispatchToProps;
