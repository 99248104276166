import { useContext, CSSProperties, HTMLAttributes, ReactNode, FC, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    CardProps as CommonCardProps,
    cardDefaultProps as cardCommonDefaultProps,
    CardDefaultProps as CardCommonDefaultProps,
    cardStyleSheet,
    getCardClasses,
    cardElevations,
    cardSizes,
} from '@gs-ux-uitoolkit-common/card';

import { useStyleSheet, cx } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { CardContext } from './card-context';
import { componentAnalytics } from './analytics-tracking';
import {
    Draggable,
    dragAndDropHandleDefaultProps,
    DraggableProps,
} from '@gs-ux-uitoolkit-react/drag-and-drop';
import { CardContainerContext } from './card-container';

export interface CardProps extends CommonCardProps<CSSProperties>, HTMLAttributes<HTMLElement> {
    /**
     * Content to display inside the Card.
     */
    index?: number;
    draggableId?: string;
    children?: ReactNode;
    draggableConfig?: Omit<DraggableProps, 'children'>;
}

export const cardReactDefaultProps: CardCommonDefaultProps &
    Required<Pick<CardProps, 'draggableConfig'>> = {
    ...cardCommonDefaultProps,
    draggableConfig: {
        index: 0,
        draggableId: undefined,
        dragHandleConfig: { size: 'md', type: 'outlined', name: 'drag-handle' },
        dragIconVisibility: 'visible',
        grabArea: 'anywhere',
        disabled: false,
        iconPosition: 'trailing',
        iconPlacement: 'top',
        classes: {},
    },
};

/**
 * Cards display content or actions related to a single subject.
 */
export const Card: FC<CardProps> = memo(
    ({
        className,
        bordered = cardReactDefaultProps.bordered,
        elevation = cardReactDefaultProps.elevation,
        draggableConfig = cardReactDefaultProps.draggableConfig,
        size,
        children,
        ...attributes
    }) => {
        const theme = useTheme();
        const { dragAndDrop, size: internalSize } = useContext(CardContainerContext);

        const computedSize = size ?? internalSize ?? cardReactDefaultProps.size;
        const computedGrabArea = draggableConfig.grabArea ?? (dragAndDrop ? 'anywhere' : undefined);
        const computedIconPosition =
            dragAndDrop && draggableConfig.dragIconVisibility !== 'none'
                ? draggableConfig.iconPosition ?? cardReactDefaultProps.draggableConfig.iconPosition
                : undefined;

        const cssClasses = useStyleSheet(cardStyleSheet, {
            theme,
            elevation,
            grabArea: computedGrabArea,
            iconPosition: computedIconPosition,
            size: computedSize,
            dragAndDrop,
            bordered,
        });
        const cardClasses = getCardClasses({ cssClasses, className });

        useEffect(() => {
            //track component has rendered
            componentAnalytics.trackRender({ officialComponentName: 'card' });
        }, []); // Only run once

        return (
            <CardContext.Provider
                value={{ size: computedSize, iconPosition: draggableConfig?.iconPosition }}
            >
                {dragAndDrop ? (
                    <Draggable
                        {...draggableConfig}
                        index={draggableConfig?.index ?? cardReactDefaultProps.draggableConfig}
                        data-gs-uitk-component="card"
                        dragHandleConfig={{
                            ...dragAndDropHandleDefaultProps.iconBaseProps,
                            size: computedSize,
                        }}
                        classes={{
                            ...draggableConfig.classes,
                            boxInner: cx(cardClasses, draggableConfig?.classes?.boxInner),
                            dragHandleClasses: {
                                ...draggableConfig.classes?.dragHandleClasses,
                                dragHandle: cx(
                                    cssClasses.icon,
                                    draggableConfig.classes?.dragHandleClasses?.dragHandle
                                ),
                            },
                        }}
                        data-size={computedSize}
                        {...attributes}
                    >
                        {() => <>{children}</>}
                    </Draggable>
                ) : (
                    <div
                        data-gs-uitk-component="card"
                        {...attributes}
                        className={cardClasses}
                        data-size={computedSize}
                    >
                        {children}
                    </div>
                )}
            </CardContext.Provider>
        );
    }
);
Card.displayName = 'Card';

Card.propTypes = {
    bordered: PropTypes.bool,
    className: PropTypes.string,
    elevation: PropTypes.oneOf(cardElevations),
    size: PropTypes.oneOf(cardSizes),
    // TODO: add more specyfic type (UX-16759)
    draggableConfig: PropTypes.any,
};
