import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import DOMPurify from 'dompurify';
import {Conditional} from '../Conditional';
import constants from '../../../constants/appConstants';
import BodyEnd from '../BodyEnd';
import translator from '../../../services/translator';
import './index.scss';

const {translate: t} = translator;

export const getAffix = (type) => {
  const configs = {
    success: { className: 'success', affix: t('tkAffixSuccess') },
    error: { className: 'error', affix: t('tkAffixError') },
    alert: { className: 'alert', affix: t('tkAffixAlert') },
    info: { className: 'info', affix: t('tkAffixInfo') },
    note: { className: 'info', affix: t('tkAffixNote') },
    warning: { className: 'warning', affix: t('tkAffixWarning') },
    laststep: { className: 'info', affix: t('tkAffixLaststep') },
  };
  return configs[type || 'success'];
};

const SnackbarBody = (props) => {
  const {type, msgCopy, showCloseCTA, handleClose, showIcon, isHtmlMsg} = props;
  const {className, affix} = getAffix(type);

  return (
    <div className='snackbar'>
      <div className={cn('snackbar-container', props.className || className)}>
        <div className='snackbar-container-content'>
          <Conditional condition={showIcon === true}>
            <span className='icon'/>
          </Conditional>
          <span className='msgTxt'>
            <Conditional condition={props.affix !== false}>
              <span className='snackbar-container__type'>
                {props.affix || affix}
              </span>
            </Conditional>
            <Conditional condition={isHtmlMsg}>
              <span>{msgCopy}</span>
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(msgCopy || '--'),
                }}
              />
            </Conditional>
          </span>
          {(showCloseCTA === true || type === "error") && (
            <button
              className='transparent-button exit-icon'
              onClick={handleClose}
            />
          )}
        </div>
      </div>
    </div>
  );
};

SnackbarBody.propTypes = {
  /* eslint-disable-next-line react/no-unused-prop-types */
  id: PropTypes.number,
  type: PropTypes.string,
  msgCopy: PropTypes.string,
  handleClose: PropTypes.func,
  affix: PropTypes.string,
  className: PropTypes.string,
  showCloseCTA: PropTypes.bool,
  showIcon: PropTypes.bool,
  isHtmlMsg: PropTypes.bool,
};

const Snackbar = (props) => {
  const { type, msgCopy, headerHeight, id, appendTo, children, useCustomBody, onSnackbarClose, hideTime, autoHide } = props;
  let timer = null;
  const [hideSnackbar, setHideSnackbar] = useState(false);

  const closeSnackbar = (event) => {
    setHideSnackbar(true);
    onSnackbarClose && onSnackbarClose(event, props);
  };

  useEffect(() => {
    const handleScroll = (event) => {
      event.stopPropagation();
      const el = document.querySelector(appendTo);
      if (window.pageYOffset > el.offsetTop && el.offsetTop !== 0) {
        el.nextSibling.style.paddingTop = `${el.clientHeight}px`;
        el.classList.add('fixed-snackbar');
      } else if (window.pageYOffset < headerHeight) {
        el.nextSibling.style.paddingTop = 0;
        el.classList.remove('fixed-snackbar');
      }
      return false;
    };
    props.sticky && window.addEventListener('scroll', handleScroll);
    return () => {
      props.sticky && window.removeEventListener('scroll', handleScroll);
    };
  }, [props.sticky]);

  const hideAfterNSeconds = () => {
    if (autoHide) {
      timer = setTimeout(() => {
        setHideSnackbar(true);
        onSnackbarClose && onSnackbarClose(null, props);
      }, hideTime);
    }
  };

  useEffect(() => {
    setHideSnackbar();
  }, [msgCopy, type, id]);

  useEffect(() => {
    clearTimeout(timer);
    hideAfterNSeconds();
    return () => {
      clearTimeout(timer);
    };
  }, [timer, msgCopy, type, id, hideTime, autoHide]);

  const childrenArray = React.Children.toArray(children);
  const child = childrenArray[0];
  const ChildComponent = child && child.type;
  const Body = (useCustomBody && ChildComponent) || SnackbarBody;
  return (
    <Conditional condition={!hideSnackbar && msgCopy}>
      <Conditional condition={props.sticky}>
        <BodyEnd containerSelector={appendTo}>
          <Body {...props} handleClose={closeSnackbar} />
        </BodyEnd>
        <Body {...props} handleClose={closeSnackbar} />
      </Conditional>
    </Conditional>
  );
};

Snackbar.propTypes = {
  /* eslint-disable-next-line react/no-unused-prop-types */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  msgCopy: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  sticky: PropTypes.bool,
  headerHeight: PropTypes.number,
  appendTo: PropTypes.string,
  children: PropTypes.node,
  useCustomBody: PropTypes.bool,
  showIcon: PropTypes.bool,
  onSnackbarClose: PropTypes.func,
  hideTime: PropTypes.number,
  autoHide: PropTypes.bool
};

Snackbar.defaultProps = {
  sticky: true,
  headerHeight: 88,
  appendTo: '.global-snackbar',
  useCustomBody: false,
  showIcon: true,
  autoHide: true,
  hideTime: constants.SNACKBAR_HIDE_TIME
};

export default Snackbar;
