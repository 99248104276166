import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {List, AutoSizer, CellMeasurer, CellMeasurerCache} from 'react-virtualized';
import memoize from 'memoize-one';
import TradeHeader from '../TradeHeader/index';
import TradeItem from '../TradeItem';
import {Conditional} from '../../../core/Conditional';
import './index.scss';

const getTradeItemData = memoize((transactions, userTimeZone, handleApproval, handleRejection, headerMargin, onAnimationComplete, homeAway, isMimicSession) => ({
  transactions,
  userTimeZone,
  handleApproval,
  handleRejection,
  headerMargin,
  onAnimationComplete,
  homeAway,
  isMimicSession
}));

const TradeItemTemplate = ({data, index, style}) => {
  const {
    transactions,
    userTimeZone,
    handleApproval,
    handleRejection,
    onAnimationComplete,
    homeAway,
    isMimicSession
  } = data;
  const transaction = transactions[index];
  return (
    <div style={style} className="tradeItemTemplate">
      <Conditional condition={transaction.showGroup}>
        <TradeHeader tradeData={transaction} homeAway={homeAway}/>
      </Conditional>
      <TradeItem
        txnData={transaction}
        userTimeZone={userTimeZone}
        onApprove={handleApproval}
        onReject={handleRejection}
        onAnimationComplete={onAnimationComplete}
        homeAway={homeAway}
        isMimicSession={isMimicSession}
      />
    </div>
  );
};
TradeItemTemplate.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  style: PropTypes.string
};

const getRenderRowComp = (itemData, cache) => {
  const RenderRow = ({index, key, style, parent}) => (
    <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
      <TradeItemTemplate data={itemData} style={style} index={index}/>
    </CellMeasurer>
  );

  RenderRow.propTypes = {
    index: PropTypes.object,
    key: PropTypes.number,
    style: PropTypes.string,
    parent: PropTypes.node
  };
  return RenderRow;
};

export const TradeGroup = ({
  data, handleApproval, handleRejection,
  userTimeZone, handleAnimationComplete,
  homeAway, height, isMimicSession
}) => {
  const itemData = getTradeItemData(
    data, userTimeZone, handleApproval,
    handleRejection, 0, handleAnimationComplete,
    homeAway, isMimicSession
  );
  let list;
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 209
  });

  useEffect(() => {
    cache.clearAll();
    if (list) {
      list.recomputeRowHeights && list.recomputeRowHeights();
    }
  }, [data]);

  const _setListRef = ref => {
    list = ref;
  };

  return (
    <div className="approval-groups" style={{height: height - 15}}>
      <AutoSizer>
        {({height, width}) => {
          return (
            <List
              ref={_setListRef}
              height={height}
              width={width < 400 ? width - 56 : width} // in Mobile view we need to reduce the scroll bar width
              rowHeight={cache.rowHeight}
              deferredMeasurementCache={cache}
              rowRenderer={getRenderRowComp(itemData, cache)}
              rowCount={data.length}
              overscanRowCount={1}
            />
          );
        }}
      </AutoSizer>
    </div>
  );
};

TradeGroup.propTypes = {
  data: PropTypes.array,
  height: PropTypes.number,
  handleApproval: PropTypes.func,
  handleRejection: PropTypes.func,
  userTimeZone: PropTypes.string,
  handleAnimationComplete: PropTypes.func,
  homeAway: PropTypes.bool,
  isMimicSession: PropTypes.bool
};

export default TradeGroup;
