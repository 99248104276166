import Routes from './appRoutes';
import AppConstants from '../constants/appConstants';

export const findRoute = (routeId) => {
  const filteredRoutes = Routes.filter(route => route.id === routeId);
  return filteredRoutes.length ? filteredRoutes[0] : false;
};

export const getRoutePath = (routeId, defaultRoute = '/') => {
  const route = findRoute(routeId);
  if(route) {
    return route.path;
  }
  return defaultRoute;
};

export const getBaseRoutePath = (navLinks = [], routes = [], permissions = [], isLogin) => {
  let BASE_NAV = '';
  if (!isLogin) {
    const navConfig = navLinks.find(navLink => permissions.includes(navLink.resourceName) || !navLink.resourceName) || {};
    BASE_NAV = navConfig.routingPathId;
  } else {
    BASE_NAV = AppConstants.LOGIN;
  }
  const BASE_ROUTE = routes.find(route => route.id === BASE_NAV) || {};
  return BASE_ROUTE.path || '';
};

export const getRootPageId = (routeId) => {
  let rootPageId = '';
  const mainRoute = getRoutePath(routeId);
  if (mainRoute === '/') {
    Routes.forEach(route => {
      if (route.subRoutes) {
        const matchedSubRoute = route.subRoutes.find(subRoute => subRoute.id === routeId);
        if (matchedSubRoute) {
          rootPageId = route.id;
        }
      }
    });
  }
  return (rootPageId || routeId);
};
