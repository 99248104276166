import { cx } from '@gs-ux-uitoolkit-common/style';
import { ColorPickerProps } from './color-picker-props';
import { ColorPickerCssClasses } from './color-picker-style-sheet';

const globalClassName = 'gs-color-picker';

export const getColorPickerRootClasses = (props: {
    cssClasses?: ColorPickerCssClasses;
    className?: string;
    overrideClasses?: ColorPickerProps['classes'];
}): string => {
    const { className, overrideClasses } = props;
    return cx(className, overrideClasses?.root, globalClassName);
};

export const getColorPickerSelectorChipClasses = (props: {
    cssClasses: ColorPickerCssClasses;
    overrideClasses?: ColorPickerProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.selector,
        cssClasses.selectorChip,
        overrideClasses?.selectorChip,
        `${globalClassName}__selector-chip`
    );
};

export const getColorPickerSelectorChipInnerClasses = (props: {
    cssClasses: ColorPickerCssClasses;
    overrideClasses?: ColorPickerProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.selectorInner,
        cssClasses.selectorChipInner,
        overrideClasses?.selectorChipInner,
        `${globalClassName}__selector-chip-inner`
    );
};

export const getColorPickerSelectorFormClasses = (props: {
    cssClasses: ColorPickerCssClasses;
    overrideClasses?: ColorPickerProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.selector,
        cssClasses.selectorForm,
        overrideClasses?.selectorForm,
        `${globalClassName}__selector-form`
    );
};

export const getColorPickerSelectorFormInnerClasses = (props: {
    cssClasses: ColorPickerCssClasses;
    overrideClasses?: ColorPickerProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.selectorInner,
        cssClasses.selectorFormInner,
        overrideClasses?.selectorInner,
        `${globalClassName}__selector-form-inner`
    );
};

export const getColorPickerSelectorIconClasses = (props: {
    cssClasses: ColorPickerCssClasses;
    overrideClasses?: ColorPickerProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.selectorIcon,
        overrideClasses?.selectorIcon,
        `${globalClassName}__selector-icon`
    );
};

export const getColorPickerPopupContainerClasses = (props: {
    cssClasses: ColorPickerCssClasses;
    overrideClasses?: ColorPickerProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.popupContainer,
        overrideClasses?.popupContainer,
        `${globalClassName}__popup-container`
    );
};

export const getColorPickerPopupClasses = (props: {
    cssClasses: ColorPickerCssClasses;
    overrideClasses?: ColorPickerProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(cssClasses.popup, overrideClasses?.popup, `${globalClassName}__popup`);
};

export const getColorPickerPopupSubheaderClasses = (props: {
    cssClasses: ColorPickerCssClasses;
    overrideClasses?: ColorPickerProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.popupSubheader,
        overrideClasses?.popupSubheader,
        `${globalClassName}__popup-subheader`
    );
};

export const getColorPickerPopupContentsClasses = (props: {
    cssClasses: ColorPickerCssClasses;
    overrideClasses?: ColorPickerProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.popupContents,
        overrideClasses?.popupContents,
        `${globalClassName}__popup-contents`
    );
};

export const getColorPickerRampsContainerClasses = (props: {
    cssClasses: ColorPickerCssClasses;
    overrideClasses?: ColorPickerProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.rampsContainer,
        overrideClasses?.rampsContainer,
        `${globalClassName}__ramps-container`
    );
};

export const getColorPickerFooterClasses = (props: {
    cssClasses: ColorPickerCssClasses;
    overrideClasses?: ColorPickerProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(cssClasses.footer, overrideClasses?.footer, `${globalClassName}__footer`);
};

export const getColorPickerFooterItemClasses = (props: {
    cssClasses: ColorPickerCssClasses;
    overrideClasses?: ColorPickerProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.footerItem,
        overrideClasses?.footerItem,
        `${globalClassName}__footer-item`
    );
};

export const getColorPickerFooterLabelClasses = (props: {
    cssClasses: ColorPickerCssClasses;
    overrideClasses?: ColorPickerProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.footerLabel,
        overrideClasses?.footerLabel,
        `${globalClassName}__footer-label`
    );
};

export const getColorPickerRampTileClasses = (props: {
    cssClasses: ColorPickerCssClasses;
    overrideClasses?: ColorPickerProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(cssClasses.rampTile, overrideClasses?.rampTile, `${globalClassName}__ramp-tile`);
};

export const getColorPickerActiveMarkerClasses = (props: {
    cssClasses: ColorPickerCssClasses;
    overrideClasses?: ColorPickerProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.activeMarker,
        overrideClasses?.activeMarker,
        `${globalClassName}__active-marker`
    );
};

export const getColorPickerCloseButtonClasses = (props: {
    cssClasses: ColorPickerCssClasses;
    overrideClasses?: ColorPickerProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.closeButton,
        overrideClasses?.closeButton,
        `${globalClassName}__close-button`
    );
};

export const getColorPickerScrollScreenClasses = (props: {
    cssClasses: ColorPickerCssClasses;
    overrideClasses?: ColorPickerProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.scrollScreen,
        overrideClasses?.scrollScreen,
        `${globalClassName}__scroll-screen`
    );
};
