import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import Constants from '../../constants/appConstants';
import Stepper from '../../components/core/Stepper';
import WithPageError from '../WithPageError';
import {
  getActivityNavLinks, getTradeActivityConfig, ctsReportConfigSelector
} from '../../selectors/pages/tradeActivity';
import {rollBackToLegacyReportSelector} from '../../selectors/pages/reports/allReports';
import { pageIdSelector } from '../../selectors/pages';
import InjectRoutes from '../../routes';
import Snackbar from './Snackbar';
import PageHeader from '../../components/app/PageHeader';
import SubHeader from './SubHeader';
import translator from '../../services/translator';
import {getAppRoute} from '../../utils/commonUtils';
import './index.scss';

const {translate: t} = translator;

const TradeActivity = ({
  activityNavLinks, subRoutes, currentPageId,
  stepperConfig, rollBackToLegacyReport, currentReportConfig
}) => {

  return (
    <React.Fragment>
      <Snackbar />
      <PageHeader title={t('tkYourActivity')} />
      <div className="tradeActivity__container">
        <div className="tradeActivity__tabs">
          <Stepper stepperConfig={stepperConfig} currentPageId={currentPageId} />
          <div className="tradeActivity__subTitle">
            <SubHeader
              pageId={currentPageId}
              rollBackToLegacyReport={rollBackToLegacyReport}
              currentReportConfig={currentReportConfig}
            />
          </div>
        </div>
        <div className="tradeActivity__section">
          <WithPageError>
            <InjectRoutes
              routes={subRoutes}
              navLinks={activityNavLinks}
              redirectRoutes={[getAppRoute(Constants.TRADE_ACTIVITY_ROUTE)]}
            />
          </WithPageError>
        </div>
      </div>
    </React.Fragment>
  );
};

TradeActivity.propTypes = {
  activityNavLinks: PropTypes.array,
  subRoutes: PropTypes.array,
  currentPageId: PropTypes.string,
  stepperConfig: PropTypes.array,
  rollBackToLegacyReport: PropTypes.bool,
  currentReportConfig: PropTypes.object
};

const mapStateToProps = (state) => ({
  activityNavLinks: getActivityNavLinks(state),
  currentPageId: pageIdSelector(state),
  stepperConfig: getTradeActivityConfig(state),
  rollBackToLegacyReport: rollBackToLegacyReportSelector(state),
  currentReportConfig: ctsReportConfigSelector(state)
});

export default withRouter(connect(mapStateToProps)(TradeActivity));
