import { Alert } from '@gs-ux-uitoolkit-react/alert';
import { lightTheme, Theme, ThemeProvider } from '@gs-ux-uitoolkit-react/theme';
import { Component } from 'react';
import { connect } from 'react-redux';
import { GridWrapper } from '../../../grid-wrappers/grid-wrapper';
import {
    RemoveAllDismissibleNotification,
    RemoveNotification,
    NotificationActionTypes,
} from '../../../redux/actions/notification-action';
import { DataGridState } from '../../../redux/datagrid-state';
import { NotificationItem } from '../notification-plugin';
/**
 * The props for NotificationContainer
 */
export interface NotificationContainerProps {
    notificationList: NotificationItem[];
    maxDisplayedItems: number;
    removeNotification: (notificationItem: NotificationItem) => void;
    removeDismissibleAllNotification: () => void;
    wrapper: GridWrapper;
}

interface NotificationContainerState {
    theme: Theme;
}

export class NotificationContainerComponent extends Component<
    NotificationContainerProps,
    NotificationContainerState
> {
    constructor(props: NotificationContainerProps) {
        super(props);
        this.state = {
            theme: lightTheme,
        };
    }

    componentDidMount() {
        this.setState({ theme: this.props.wrapper.getTheme() });
        this.props.wrapper.themeChanged.subscribe(() => this.setTheme());
    }

    componentWillUnmount() {
        this.props.wrapper.themeChanged.unsubscribe(() => this.setTheme());
    }
    public render() {
        const { notificationList } = this.props;
        return (
            <ThemeProvider theme={this.state.theme}>
                {notificationList.length > 0 && (
                    <div key={notificationList.length}>{this.getNotificationItems()}</div>
                )}
            </ThemeProvider>
        );
    }

    private setTheme() {
        const theme = this.props.wrapper.getTheme();
        if (this.state.theme.colorMode !== theme.colorMode) {
            this.setState({ theme });
        }
    }
    private getNotificationItems() {
        return this.props.notificationList
            .slice(0, this.props.maxDisplayedItems)
            .map((item, index) => {
                const onDismiss = () => {
                    this.props.removeNotification(item);
                };
                return (
                    <Alert
                        key={index}
                        size={'sm'}
                        fade={false}
                        status={item.type}
                        dismissible={item.dismissible}
                        onDismiss={item.dismissible ? onDismiss : undefined}
                    >
                        <strong>{item.title}</strong> {item.message}
                    </Alert>
                );
            });
    }
}
const mapStateToProps = (state: DataGridState) => {
    return {
        notificationList: state.notification.notificationList,
        maxDisplayedItems: state.notification.maxDisplayedItems,
    };
};

const mapDispatchToProps = (dispatch: (action: NotificationActionTypes) => void) => {
    return {
        removeDismissibleAllNotification: () => {
            dispatch(RemoveAllDismissibleNotification());
        },
        removeNotification: (notificationItem: NotificationItem) => {
            dispatch(RemoveNotification(notificationItem));
        },
    };
};

export const NotificationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationContainerComponent);
