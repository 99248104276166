import { StyleSheet } from '@gs-ux-uitoolkit-react/style';
import { Theme } from '@gs-ux-uitoolkit-react/theme';

interface ExportPluginStyleSheetProps {
    theme: Theme;
}

export const exportPluginStyleSheet = new StyleSheet(
    'gs-uitk-export-plugin-modal',
    ({ theme }: ExportPluginStyleSheetProps) => {
        return {
            root: {
                '.form-control': {
                    height: 'auto !important',
                    padding: '3px 12px !important',
                },

                '.gs-uitk-info': {
                    marginLeft: '0.5rem',
                    fontSize: '1rem',
                    lineHeight: '1rem',
                    cursor: 'pointer',
                },
                '.gs-uitk-info-content': {
                    maxHeight: 0,
                    margin: 0,
                    overflow: 'hidden',
                    display: 'block',
                    transition: 'max-height ease-out 0.2s',
                    '&.gs-uitk-show': {
                        maxHeight: '5rem',
                        marginTop: '1rem',
                        marginBottom: '1rem',
                        padding: '0.5rem',
                        color: theme.text.secondary,
                    },
                },
                height: '100%',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                '.gs-uitk-export-modal-intro .gs-label__label': {
                    marginBottom: '12px',
                },
                '.gs-form-group': {
                    marginBottom: '20px',
                },
                '.gs-uitk-export-plugin-modal-container': {
                    flex: 1,
                    overflow: 'auto',
                    paddingRight: '12px',
                },
                '.gs-uitk-export-plugin-modal-button-container': {
                    marginTop: '12px',
                    alignSelf: 'flex-end',
                },
                '.gs-uitk-export-plugin-modal-filename-input': {
                    maxWidth: '400px',
                },
                '.uitk-export-options-columns-container': {
                    display: 'flex',
                    flexWrap: 'wrap',
                    '.uitk-export-options-column': {
                        flex: 1,
                        minWidth: '180px',
                        '&:first-child': {
                            marginRight: '10px',
                        },
                    },
                },

                '.info-span-wrapper': {
                    display: 'flex',
                    '& > span': {
                        marginLeft: '0.5rem',
                    },
                },
            },
        };
    }
);
