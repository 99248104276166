import React from "react";
import PropTypes from "prop-types";
import {Input} from "@gs-ux-uitoolkit-react/input";
import {ENTER_AMOUNT, INPUT, REBATE_NO_ERROR} from "../../constants";
import ErrorMessage from "../ErrorMessage";
import "./index.scss";

const AmountInput = ({value, field, handleEditFields, errorState, labels}) => {
  return (
    <>
      <Input
        className="fund-rebate-edit"
        size="sm"
        defaultValue={value}
        clearable={true}
        placeholder={ENTER_AMOUNT}
        onBlur={(val) =>
          handleEditFields(val.target.value.trim(), field, INPUT)
        }
        onClearClick={
          () => handleEditFields("", field, INPUT)
        }
        status={errorState !== REBATE_NO_ERROR ? "error" : "none"}
      />
      <ErrorMessage labels={labels} errorState={errorState}/>
    </>
  );
};

AmountInput.propTypes = {
  value:  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  field: PropTypes.string,
  handleEditFields: PropTypes.func,
  errorState: PropTypes.number,
  labels: PropTypes.object
};

export default AmountInput;
