export const colDef = {
  filter: false,
  filterParams:{
    suppressFilterButton:false,
    caseSensitive: false,
    filters: [
      {
        filter: 'agTextColumnFilter',
        floatingFilterComponent: 'customSearchFilter',
        filterParams: {
          debounceMs: 200,
          suppressAndOrCondition: true
        }
      },
      { filter: 'agSetColumnFilter',
        filterParams: {
          buttons: ['clear', 'apply'],
          suppressAndOrCondition: true
        }
      }
    ]
  },
  floatingFilterParams:{
    suppressFilterButton: false,
    newRowsAction: 'keep'
  },
  floatingFilterComponent: undefined,
  floatingFilterComponentParams:{
    suppressFilterButton:false,
    filterVisibility: 'hidden'
  },
  // headerComponentParams: { menuIcon: 'fa-bars' },
//  menuTabs: [' '],
  minWidth: 140
};

