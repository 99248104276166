import React, {useState, useEffect} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import cn from 'classnames';
import {EMAIL_PREFERENCES_CONFIG} from "../../../../constants/pageConstants";
import {Conditional} from '../../../../components/core/Conditional';
import Select from '../../../../components/core/Select/Simple';
import PageHeader from '../../../../components/app/PageHeader';
import Button from '../../../../components/core/Button';
import Link from '../../../../components/core/Link';
import SwitchButton from '../../../../components/core/SwitchButton';
import Checkbox from "../../../../components/core/CheckBox";
import translator from '../../../../services/translator';
import {analyticsEventName, saveEmailNotificationPreferences, onPreferencesPageLoadAnalytics} from '../../analytics';
import {pageIdSelector, preferencesSavedSelector, preferencesSuccessSelector} from "../../../../selectors/pages";
import { getUserTimeZoneId } from '../../../../selectors/user'
import {eventAnalytics} from "../../../../actions/sagas";
import {contactUsLinkSelector} from "../../../../selectors/app";
import {saveAppPreferences} from "../../../../actions/preferences";
import { simpleRenderer } from '../../../../components/app/TradeBlotter/TradeRow/fieldTemplates'
import {
  preferenceNavLinksSelector, preferenceRouteConfigSelector, emailNotificationsConfigSelector
} from "../../../../selectors/global";
import { timeIntervalList } from '../../../../utils/commonUtils';
import './index.scss';
const {translate: t} = translator;

const SubTitleRenderer = props => {
  if(props.subTitleKeyComponent === 'TimeSelection') {
    const selectedOption = timeIntervalList.find(data => data.value === props.sendEmailTime);
    const option = selectedOption && {...selectedOption, label: `${selectedOption.label} ${props.zoneId}`} || {label: '', value: ''}
    return (
      <div className="time-dropdown">
        <Select
          valueKey="value"
          isDisabled={!props.isEnabled}
          selectedOption={option}
          clickHandler={props.onDropdowSelection}
          optionRenderer={simpleRenderer}
          labelKey="label"
          options={timeIntervalList}
        />
      </div>
    )
  }
  return null;
}

SubTitleRenderer.propTypes = {
  onDropdowSelection: PropTypes.func,
  subTitleKeyComponent: PropTypes.string,
  sendEmailTime: PropTypes.string,
  isEnabled: PropTypes.bool,
  zoneId: PropTypes.string
}
export const CheckBoxGroup = ({config = [], onHeaderBtnToggle, onCheckBoxSelection, onDropdowSelection, zoneId}) => (
  <div className='block-container'>
    {
      config.map(({headerKey, header, subHeader, isActive, isGroupEntitled, notificationItems}) => {
        const disableHeaderSection = notificationItems.every(item => !item.isEnabled);
        return (
          <div className='block-container__section-wrapper'>
            <div className='block-container__header-section-wrapper'>
              <div className="block-container__left-section">
                <span className={cn("h2", {"disabled": disableHeaderSection})}>{header}</span>
                <div className={cn({"disabled": disableHeaderSection})}>{subHeader}</div>
              </div>
              <div className="block-container__right-section">
                {
                  isGroupEntitled &&
                  <div className="block-container__checkbox">
                    <SwitchButton
                      id={headerKey}
                      active={isActive}
                      onClick={onHeaderBtnToggle}
                    />
                  </div>
                }
              </div>
            </div>
            <div className='block-container__options-section-wrapper'>
              {
                notificationItems.map((data, index) => (
                  <div className='block-container__options-subsection-wrapper'>
                    <div className="block-container__left-section">
                      <div className={cn("bold", {"disabled": !data.isEnabled})}>{data.title}</div>
                      <div className={cn("block-container__left-section--subTitle", {"disabled": !data.isEnabled})}>
                        <div>{data.subTitle}</div>
                        <Conditional condition={data.subTitleKeyComponent}>
                          <div className="block-container__left-section--subTitle--child">
                            <SubTitleRenderer zoneId={zoneId} onDropdowSelection={onDropdowSelection(data)} {...data} />
                          </div>
                        </Conditional>
                      </div>
                    </div>
                    <div className="block-container__right-section">
                      <div className="block-container__checkbox">
                        <Checkbox
                          id={data.id}
                          key={index}
                          value="true"
                          rootKey={data.rootKey}
                          parentKey={data.parentKey}
                          changeHandler={onCheckBoxSelection}
                          isDisabled={!data.isEnabled}
                          isChecked={data.isChecked}
                        />
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        )
      })
    }
  </div>
);

CheckBoxGroup.propTypes = {
  onHeaderBtnToggle: PropTypes.func,
  onCheckBoxSelection: PropTypes.func,
  config: PropTypes.object,
  onDropdowSelection: PropTypes.func,
  zoneId: PropTypes.string
};

export const EmailNotifications = (props) => {
  const {contactUsLink = {}, isPreferencesSaved, isPreferencesSuccess, config, zoneId } = props;
  const [emailNotificationsConfig, setEmailNotificationsConfig] = useState(cloneDeep(config));
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    props.onPageLoad()
  }, [])

  const onSavePreferences = () => {
    const preferences = {};
    emailNotificationsConfig.forEach(({notificationItems}) => {
      notificationItems.forEach(data => {
        const {rootKey, isChecked } = data;
        if (!preferences[rootKey]) {
          preferences[rootKey] = {};
        }
        if(data.sendEmailTime){
          preferences[rootKey].sendEmailTime = data.sendEmailTime
        }
        preferences[rootKey][EMAIL_PREFERENCES_CONFIG.IS_CHECKED_KEY] = isChecked;
      })
    })
    setIsDirty(false);
    props.saveAppPreferences({[EMAIL_PREFERENCES_CONFIG.NOTIFICATION_ROOT_KEY]: {...preferences}});
  };

  const onHeaderBtnToggle = (isActive, id) => {
    const section = emailNotificationsConfig.find(({headerKey}) => (headerKey === id));
    if (section) {
      section.isActive = isActive;
      section && section.notificationItems.forEach(item => {
        if(item.rootKey === EMAIL_PREFERENCES_CONFIG.CUSTOM_TRADE_APPROVAL_REMINDER_EMAIL){
          item.sendEmailTime = isActive ? timeIntervalList[0].value : ''
        }
        item.isChecked = isActive && item.isEnabled;
      });
      setEmailNotificationsConfig(emailNotificationsConfig.slice());
    }
    setIsDirty(true);
  }

  const onCheckBoxSelection = (event, isChecked, propsData, onTimeChange) => {
    const {rootKey, parentKey} = propsData;
    const section = emailNotificationsConfig.find(({headerKey}) => (headerKey === parentKey));
    const {notificationItems} = section;
    const preferenceItem = notificationItems.find(item => (item.rootKey === rootKey));
    preferenceItem.isChecked = isChecked;
    section.isActive = section.notificationItems.some(item => (item.isEnabled && item.isChecked));
    if(rootKey === EMAIL_PREFERENCES_CONFIG.CUSTOM_TRADE_APPROVAL_REMINDER_EMAIL && !onTimeChange){
      preferenceItem.sendEmailTime = isChecked ? timeIntervalList[0].value : ''
    }
    setEmailNotificationsConfig(emailNotificationsConfig.slice());
    setIsDirty(true);
  };

  const onDropdowSelection = data => option => {
    data.sendEmailTime = option.value
    onCheckBoxSelection(null, true, data, true)
  }

  return (
    <div className='email-notifications'>
      <div className='email-notifications__header'>
        <PageHeader
          title={t('tkEmailNotificationsTitle')}
          description={t('tkEmailNotificationsSubTitle')}
          type='Secondary' />
        <Conditional condition={Object.keys(contactUsLink).length}>
          <div className='email-notifications__header-link'>
            <Link
              handleClick={(e) => e.preventDefault()}
              label={t('tkNeedHelp')}
              href={contactUsLink.URL}
              target={contactUsLink.newTab ? '_blank' : ''}
              classType='link-primary--light'
            />
          </div>
        </Conditional>
      </div>
      <div className='email-notifications__container'>
        <CheckBoxGroup
          zoneId={zoneId}
          onDropdowSelection={onDropdowSelection}
          onHeaderBtnToggle={onHeaderBtnToggle}
          onCheckBoxSelection={onCheckBoxSelection}
          config={emailNotificationsConfig}
        />
      </div>
      <div className='email-notifications__footer'>
        <Button
          extraProps={{'analytics-attribute': analyticsEventName.SAVE_PREFERENCES}}
          customClass='button-primary'
          label={t('tkSave')}
          clickHandler={onSavePreferences}
          isDisabled={!isDirty}
        />
        <Conditional condition={isPreferencesSaved && !isDirty}>
          <Conditional condition={isPreferencesSuccess}>
            <div className='success-message'>{t('tkPreferencesSaveSuccess')}</div>
            <div className='success-message'>{t('tkPreferencesSaveError')}</div>
          </Conditional>
        </Conditional>
      </div>
    </div>
  );
};

EmailNotifications.propTypes = {
  config: PropTypes.array,
  saveAppPreferences: PropTypes.func,
  contactUsLink: PropTypes.object,
  isPreferencesSaved: PropTypes.bool,
  isPreferencesSuccess: PropTypes.bool,
  onPageLoad: PropTypes.func,
  zoneId: PropTypes.string
};

const mapStateToProps = (state) => (
  {
    config: emailNotificationsConfigSelector(state),
    preferencesStepperConfig: preferenceRouteConfigSelector(state),
    contactUsLink: contactUsLinkSelector(state),
    isPreferencesSaved: preferencesSavedSelector(state),
    isPreferencesSuccess: preferencesSuccessSelector(state),
    currentPageId: pageIdSelector(state),
    preferenceNavLinks: preferenceNavLinksSelector(state),
    zoneId: getUserTimeZoneId(state)
  }
);

export const mapDispatchToProps = (dispatch) => (
  {
    saveAppPreferences: (data) => {
      dispatch(saveAppPreferences({
        preferenceRootKey: 'global',
        preferenceData: data
      }));
      dispatch(eventAnalytics(saveEmailNotificationPreferences(data)));
    },
    onPageLoad: () => {
      dispatch(eventAnalytics(onPreferencesPageLoadAnalytics()));
    }
  }
);
export default connect(mapStateToProps, mapDispatchToProps)(EmailNotifications);
