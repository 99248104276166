import { StyleSheet, CssClasses } from '@gs-ux-uitoolkit-react/style';

export type FloatingFilterCssClasses = CssClasses<typeof floatingFilterStyleSheet>;

export const floatingFilterStyleSheet = new StyleSheet('floatingFilter', () => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            '.uitk-datagrid-floating-filter-input': {
                height: 'auto',
                width: '100%',
                outline: 'none',
            },
        },
    };
});
