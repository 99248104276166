import isEmpty from "lodash/isEmpty";
import { getAccountRelations } from "../../../../utils/reportingUtils";

const getInitialSelectedMap = (nodeMap) => {
  return Object.keys(nodeMap).reduce((obj, key) => {
    obj[key] = 1;
    return obj;
  }, {})
}

const selectEntity = ({ accountId, id, nodeMap, nodeChageSet }) => {
  if (! nodeMap[id]) {
    nodeMap[id] = getAccountRelations();
    nodeChageSet.add(id);
  }
  nodeMap[id].accountIds[accountId] = 1;
}

const addEntity = ({ nodeChageSet, nodeDataMap, nodeValuesObj, nodeSelected }) => {
  Array.from(nodeChageSet).forEach(id => {
    const node = {...nodeDataMap[id]};
    const {label, value, order} = node;
    if (! nodeValuesObj[order]) {
      nodeValuesObj[order] = {label, value, order};
      nodeSelected[id] = 1;
    }
  });
}

const deSelectEntity = ({ accountId, id, nodeMap, nodeChangeSet }) => {
  nodeChangeSet.add(id)
  if (nodeMap[id] && nodeMap[id].accountIds[accountId]) {
    delete nodeMap[id].accountIds[accountId];
  }
}

const changeFirmSelection = (firmNode, {firmNodeMapSelected, branchNodeMapSelected, checked}) => {
  const {children, value: firmId} = firmNode;
  const branchIsSelected = children.some(({value: branchId}) => {
    return branchNodeMapSelected[branchId];
  })
  if (checked) {
    if (branchIsSelected) {
      firmNodeMapSelected[firmId] = 1;
    }
  } else {
    if (! branchIsSelected) {
      delete firmNodeMapSelected[firmId];
    }
  }
}

const removeEntity = ({ nodeChageSet, nodeMap, nodeDataMap, nodeValuesObj, nodeSelected }) => {
  Array.from(nodeChageSet).forEach(id => {
    if (nodeMap[id] && isEmpty(nodeMap[id].accountIds) && nodeDataMap[id]) {
      const {order} = nodeDataMap[id];
      delete nodeValuesObj[order];
      delete nodeSelected[id];
      delete nodeMap[id];
    }
  });
}

const changeFirmBranch = (state, { type, checked, value, children, parent }) => {
  const {
    initial: {accountMap: initialAccountMap},
    selected: {
      firmBranchSelected,
      taAccountsSelected,
      shareclassSelected
    },
    taAccountNodeMap,
    shareclassNodeMap,
    accountMap,
    firmMap,
    branchMap,
    taAccountMap,
    shareclassMap,
    firmBranchValuesObj,
    taAccountValuesObj,
    shareclassValuesObj
  } = state;

  const accountIds = type === "firm" ? Object.keys(firmMap[value].accountIds) : Object.keys(branchMap[value].accountIds);
  const taAccountsChange = new Set();
  const shareclassChange = new Set();

  if (checked) {
    accountIds.forEach((accountId) => {
      const accountObj = {...initialAccountMap[accountId]};
      const {shareclassId, taAccountId} = accountObj;

      selectEntity({ accountId, id: taAccountId, nodeMap: taAccountMap, nodeChageSet: taAccountsChange });
      selectEntity({ accountId, id: shareclassId, nodeMap: shareclassMap, nodeChageSet: shareclassChange });
      accountMap[accountId] = accountObj;
    })
    addEntity({ nodeChageSet: taAccountsChange, nodeDataMap: taAccountNodeMap, nodeValuesObj: taAccountValuesObj, nodeSelected: taAccountsSelected });
    addEntity({ nodeChageSet: shareclassChange, nodeDataMap: shareclassNodeMap, nodeValuesObj: shareclassValuesObj, nodeSelected: shareclassSelected });
  } else {
    accountIds.forEach((accountId) => {
      const {shareclassId, taAccountId} = initialAccountMap[accountId];

      deSelectEntity({ accountId, id: taAccountId, nodeMap: taAccountMap, nodeChangeSet: taAccountsChange});
      deSelectEntity({ accountId, id: shareclassId, nodeMap: shareclassMap, nodeChangeSet: shareclassChange});
      delete accountMap[accountId];
    });

    removeEntity({
      nodeChageSet: taAccountsChange,
      nodeMap: taAccountMap,
      nodeDataMap: taAccountNodeMap,
      nodeValuesObj: taAccountValuesObj,
      nodeSelected: taAccountsSelected
    });
    removeEntity({
      nodeChageSet: shareclassChange,
      nodeMap: shareclassMap,
      nodeDataMap: shareclassNodeMap,
      nodeValuesObj: shareclassValuesObj,
      nodeSelected: shareclassSelected
    });
  }

  if (type === "firm") {
    const {
      firmNodeMapSelected, branchNodeMapSelected
    } = firmBranchSelected;
    if (checked) {
      firmNodeMapSelected[value] = 1;
      children.forEach(({value: branchId}) => {
        branchNodeMapSelected[branchId] = 1;
      })
    } else {
      delete firmNodeMapSelected[value];
      children.forEach(({value: branchId}) => {
        delete branchNodeMapSelected[branchId];
      })
    }
  } else {
    const {
      firmNodeMapSelected,
      branchNodeMapSelected
    } = firmBranchSelected;
    if (checked) {
      branchNodeMapSelected[value] = 1;
    } else {
      delete branchNodeMapSelected[value];
    }
    const { order: firmOrder } = parent;
    changeFirmSelection(firmBranchValuesObj[firmOrder], { firmNodeMapSelected, branchNodeMapSelected, checked });
  }
  state.taAccountValues = Object.values(taAccountValuesObj);
  state.shareclassValues = Object.values(shareclassValuesObj);

  return state;
}

const changeAllFirmBranch = (state, { checked }) => {
  const {
    initial: {
      accountMap,
      taAccountMap,
      shareclassMap,
      taAccountValuesObj,
      shareclassValuesObj
    },
    firmNodeMap,
    branchNodeMap
  } = state;

  const newState = {...state};
  newState.selected = checked ? {
    taAccountsSelected: getInitialSelectedMap(taAccountMap),
    shareclassSelected: getInitialSelectedMap(shareclassMap),
    firmBranchSelected: {
      firmNodeMapSelected: getInitialSelectedMap(firmNodeMap), branchNodeMapSelected: getInitialSelectedMap(branchNodeMap)
    }
  }: {
    taAccountsSelected: {},
    shareclassSelected: {},
    firmBranchSelected: {
      firmNodeMapSelected: {}, branchNodeMapSelected: {}
    }
  }
  newState.accountMap = checked ? accountMap : {};
  newState.taAccountMap = checked ?  taAccountMap : {};
  newState.shareclassMap = checked ? shareclassMap : {};

  newState.taAccountValuesObj = checked ? taAccountValuesObj : {};
  newState.taAccountValues = checked ? Object.values(taAccountValuesObj) : [];
  newState.shareclassValuesObj = checked ? shareclassValuesObj : {};
  newState.shareclassValues = checked ? Object.values(shareclassValuesObj) : [];

  return newState;
}

const changeTaAccounts = (state, {checked, value}) => {
  const {
    initial: {
      accountMap: initialAccountMap
    },
    selected: {
      taAccountsSelected,
      shareclassSelected,
    },
    shareclassNodeMap,
    taAccountMap,
    accountMap,
    shareclassMap,
    shareclassValuesObj
  } = state;

  const accountIds = Object.keys(taAccountMap[value].accountIds);
  const shareclassChange = new Set();
  if (checked) {
    accountIds.forEach((accountId) => {
      const accountObj = {...initialAccountMap[accountId]};
      const {shareclassId} = accountObj;
      selectEntity({ accountId, id: shareclassId, nodeMap: shareclassMap, nodeChageSet: shareclassChange });
      accountMap[accountId] = accountObj;
    });

    addEntity({ nodeChageSet: shareclassChange, nodeDataMap: shareclassNodeMap, nodeValuesObj: shareclassValuesObj, nodeSelected: shareclassSelected });
  } else {
    accountIds.forEach((accountId) => {
      const {shareclassId} = initialAccountMap[accountId];
      deSelectEntity({ accountId, id: shareclassId, nodeMap: shareclassMap, nodeChangeSet: shareclassChange});
      delete accountMap[accountId];
    });
    removeEntity({
      nodeChageSet: shareclassChange,
      nodeMap: shareclassMap,
      nodeDataMap: shareclassNodeMap,
      nodeValuesObj: shareclassValuesObj,
      nodeSelected: shareclassSelected
    });
  }

  if (checked) {
    taAccountsSelected[value] = 1;
  } else {
    delete taAccountsSelected[value]
  }
  state.shareclassValues = Object.values(shareclassValuesObj);
  return state;
}

const changeAllTaAccounts = (state, {checked}) => {
  const {
    initial: {
      accountMap: initialAccountMap
    },
    shareclassNodeMap,
    taAccountMap,
    taAccountValues,
  } = state;

  const newState = {...state};
  const accountMap = {};
  const shareclassMap = {};
  const taAccountsSelected = {};
  const shareclassSelected = {};
  const shareclassValuesObj = {};
  if (checked) {
    let accountIds = [];
    taAccountValues.forEach(({value}) => {
      const { accountIds: ids } = taAccountMap[value];
      const accountIdsArr = Object.keys(ids);
      accountIds = [...accountIds, ...accountIdsArr];
    });
    accountIds.forEach((accountId) => {
      const accountObj = initialAccountMap[accountId];
      const {shareclassId, taAccountId} = accountObj;

      accountMap[accountId] = accountObj;
      if (! shareclassMap[shareclassId]) {
        shareclassMap[shareclassId] = getAccountRelations();
        shareclassSelected[shareclassId] = 1;
        const node = {...shareclassNodeMap[shareclassId]};
        const {order} = node;
        shareclassValuesObj[order] = node;
      }
      shareclassMap[shareclassId].accountIds[accountId] = 1;
      taAccountsSelected[taAccountId] = 1;
    });
  }
  newState.selected.taAccountsSelected = checked ? taAccountsSelected : {};
  newState.selected.shareclassSelected = checked ? shareclassSelected : {};
  newState.accountMap = checked ? accountMap : {};
  newState.shareclassMap = checked ? shareclassMap : {};

  newState.shareclassValuesObj = checked ? shareclassValuesObj : {};
  newState.shareclassValues = checked ? Object.values(shareclassValuesObj) : [];

  return newState;
}

const changeShareclass = (state, { checked, value }) => {
  const {
    initial: {
      accountMap: initialAccountMap
    },
    selected: {
      shareclassSelected,
      shareclassSelectedWihtoutAcct
    },
    accountMap,
    shareclassMap
  } = state;

  const isUnfundedShareclass = isEmpty(shareclassMap[value]);
  const accountIds = isUnfundedShareclass ? [] : Object.keys(shareclassMap[value].accountIds);
  if (checked) {
    accountIds.forEach((accountId) => {
      const accountObj = {...initialAccountMap[accountId]};
      accountMap[accountId] = accountObj;
    });
  } else {
    accountIds.forEach((accountId) => {
      delete accountMap[accountId];
    });
  }

  const obj = isUnfundedShareclass ? shareclassSelectedWihtoutAcct : shareclassSelected
  if (checked) {
    obj[value] = 1;
  } else {
    delete obj[value]
  }
  return state;
}

const changeAllShareclasses = (state, {checked}) => {
  const {
    initial: {
      accountMap: initialAccountMap,
      shareclassMap: initialShareclassMap
    },
    shareclassValues,
    shareclassValuesWithoutAcct
  } = state;

  const newState = {...state};
  if (checked) {
    let accountIds = [];
    shareclassValues.forEach(({value}) => {
      const { accountIds: ids } = initialShareclassMap[value];
      const accountIdsArr = Object.keys(ids);
      accountIds = [...accountIds, ...accountIdsArr];
    });

    const accountMap = {};
    const shareclassSelected = {};
    accountIds.forEach((accountId) => {
      const accountObj = initialAccountMap[accountId];
      const {shareclassId} = accountObj;
      accountMap[accountId] = accountObj;
      shareclassSelected[shareclassId] = 1;
    });
    newState.selected.shareclassSelected = shareclassSelected;
    newState.selected.shareclassSelectedWihtoutAcct = shareclassValuesWithoutAcct.reduce((final, {value}) => {
      final[value] = 1;
      return final;
    }, {})
    newState.accountMap = accountMap;
  } else {
    newState.selected.shareclassSelected = {};
    newState.selected.shareclassSelectedWihtoutAcct = {};
    newState.accountMap = {};
  }

  return newState;
}


export {
  changeFirmBranch,
  changeAllFirmBranch,
  changeTaAccounts,
  changeAllTaAccounts,
  changeShareclass,
  changeAllShareclasses,
  getInitialSelectedMap
}
