import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import useUserMaintenance from '../../../context';
import SelectAll from '../../FieldRenderer/SelectAll';
import FieldRenderer from '../../FieldRenderer';
import translator from '../../../../../../services/translator';
import { featureConfig } from './config';
import { UPDATE_FIELDS, UPDATE_FEATURES_SELECT_ALL } from '../../../actions';
import { YES, NO, CREATE_USER } from '../../../constants';

const { translate: t } = translator;

const FeaturesGroup = ({ data, selectedKey, title, testId }) => {
  const {
    state: { userDetails, isEditable },
    storeValues: { pageKey },
    dispatch,
  } = useUserMaintenance();

  const { isInternal, entitlements = {} } = userDetails;

  const disableSelectAll = isInternal || entitlements.featureEntitlements?.all === YES;

  const editable = (isEditable && !disableSelectAll) || pageKey === CREATE_USER;

  const handleChange = useCallback(({ value, config }) => {
    dispatch({ type: UPDATE_FIELDS, payload: { newValue: value, path: `entitlements.featureEntitlements.${config.value}` } });
  }, []);

  const handleSelectAllChange = useCallback(isSelected => {
    const featureEntitlements = data.reduce((final, { value }) => {
      final[value] = isSelected ? YES : NO;
      return final;
    }, {});
    dispatch({ type: UPDATE_FEATURES_SELECT_ALL, payload: { featureEntitlements } });
  }, [data]);

  const selectAll = useMemo(() => {
    const totalSelected = data.filter(coreItem => {
      const value = userDetails?.entitlements?.featureEntitlements[`${coreItem.value}`];
      return value === YES || value === null || value === undefined;
    });
    return totalSelected.length === featureConfig[selectedKey].length;
  }, [userDetails, featureConfig]);

  return (
    <>
      <div data-testid={testId} className='sub-header'>
        {t(title)}
        {editable && <SelectAll selected={selectAll} handleSelectAllChange={handleSelectAllChange} />}
      </div>
      {data.map((coreItem, idx, array) => {
        const value = userDetails?.entitlements?.featureEntitlements[`${coreItem.value}`];
        return (
          <FieldRenderer
            key={coreItem.id}
            config={coreItem}
            featuresIcon={value === YES}
            value={value}
            handleChange={handleChange}
            showNAIfNull
            isEditable={editable}
            sectionStart={idx === 0}
            sectionEnd={idx === array.length - 1}
          />
        );
      })}
    </>
  );
};

FeaturesGroup.propTypes = {
  data: PropTypes.array,
  selectedKey: PropTypes.string,
  title: PropTypes.string,
  testId: PropTypes.string,
};

export default FeaturesGroup;
