import {
    colorNames,
    colors,
    ColorValue,
    Elevation,
    elevationVariants,
    TypographyVariant,
    typographyVariants,
    getFontFaces,
} from '@gs-ux-uitoolkit-common/design-system';
import { mix, rgba } from 'polished';
import { changeContrast, darken, lighten } from '../utils/color';
import { Theme, ThemeTypographyVariant } from '../theme';
import { getColorShades, getSurfaceShades } from '../utils/interaction';

const color: Theme['color'] = colorNames.reduce(
    (obj, colorName) => {
        obj[colorName] = {
            bold: colors[`${colorName}050` as ColorValue],
            subtle: colors[`${colorName}080` as ColorValue],
        };
        return obj;
    },
    {} as Theme['color']
);

// Overrides for a few color hues to adjust the visual value
color.yellow.bold = colors.yellow030;
color.yellow.subtle = colors.yellow050;
color.orange.bold = colors.orange040;

const colorScheme: Theme['colorScheme'] = {
    primary: colors.blue050,
    secondary: colors.gray080,
    info: colors.white,
    header: colors.gray090,
    overlay: rgba(colors.gray110, 0.8),
};

const status: Theme['status'] = {
    none: color.gray,
    information: color.blue,
    success: color.green,
    warning: color.yellow,
    warningAlt: color.yellow,
    error: color.red,
    loading: color.blue,
};

const text: Theme['text'] = {
    primary: colors.gray010,
    secondary: colors.gray040,
    tertiary: colors.gray060,
    link: colors.blue050,
    destructive: colors.red050,
    reversed: colors.gray110,
};

const surface: Theme['surface'] = {
    primary: colors.gray110,
    secondary: colors.gray100,
    tertiary: colors.gray090,
    moderate: colors.gray080,
    bold: colors.gray060,
    strong: colors.gray040,
    contrast: colors.white,
    none: rgba(colors.gray110, 0.0),
};

const border: Theme['border'] = {
    minimal: colors.gray080,
    subtle: colors.gray070,
    moderate: colors.gray060,
    strong: colors.gray050,
    bold: colors.gray040,
    contrast: colors.gray020,
    reversed: colors.gray110,
    input: colors.gray060,
};

const state: Theme['state'] = {
    disabledOpacity: 0.3,
};

const elevation: Theme['elevation'] = (Object.keys(elevationVariants) as Elevation[]).reduce(
    (obj, elevation, i) => {
        obj[elevation] = {
            shadow: elevationVariants[elevation].boxShadow,
            background:
                i === 0
                    ? surface.primary
                    : mix(0.2 + i * 0.04, colors.gray070, surface.primary.toString()).toString(),
        };
        return obj;
    },
    {} as Theme['elevation']
);

const dataviz: Theme['dataviz'] = {
    sequence01: colors.blue050,
    sequence02: colors.orange050,
    sequence03: colors.teal050,
    sequence04: colors.yellow030,
    sequence05: colors.purple050,
    sequence06: colors.ultramarine030,
    sequence07: colors.lime050,
    sequence08: colors.pink040,
    sequence09: colors.aqua040,
    sequence10: colors.green040,
};

const typography = Object.entries(typographyVariants).reduce(
    (obj, [name, value]) => {
        obj[name as ThemeTypographyVariant] = value;
        return obj;
    },
    {} as { [key in ThemeTypographyVariant]: TypographyVariant }
);

export const darkTheme: Theme = {
    colorMode: 'dark',
    color,
    status,
    colorScheme,
    text,
    surface,
    border,
    state,
    dataviz,
    elevation,
    typography,
    fonts: getFontFaces(),
    getColorInteractionShades: (...args) => getColorShades(darkTheme, 'dark', ...args),
    getSurfaceInteractionShades: (...args) => getSurfaceShades(darkTheme, 'dark', ...args),
    increaseContrast: (...args) => changeContrast(...args, lighten),
    decreaseContrast: (...args) => changeContrast(...args, darken),
};
