import React, {useState, useRef}  from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';

const CustomInput = (props) => {
  const { open, selectedDate, handleOpen, handleKeyDown, onClick, isDisabled } = props;
  const [isArrowClicked, setIsArrowClicked] = useState(false);
  const dpInput = useRef();

  const clickArrow = () => {
    setIsArrowClicked(isArrowClicked);
  };

  const onFocus = () => {
     // Trigger click event to show date picker when popup input get focus on tab change
     dpInput.current && dpInput.current.click();
  };

  const onOpen = (e) => {
    onClick(e);
    handleOpen(e);
  };


  const formattedDate = selectedDate ? moment(selectedDate).format('DD.MMM.YYYY') : '';

  return (
    <div onClick={onOpen}>
      <input
        ref={dpInput}
        className="dp-input"
        tabIndex={isDisabled ? '-1' : null}
        data-tooltip={true}
        data-label={formattedDate}
        value={formattedDate}
        onKeyDown={handleKeyDown}
        onFocus={onFocus} />
      <span className={cn('datepicker__icon', {'datepicker__icon--closed': !open, 'datepicker__icon--opened': open})} onFocus={clickArrow} onBlur={clickArrow} tabIndex={isArrowClicked ? 0 : -1} onKeyDown={handleKeyDown}>&nbsp;</span>
    </div>
  );
};

CustomInput.propTypes = {
  open: PropTypes.bool,
  selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  handleOpen: PropTypes.func,
  handleKeyDown: PropTypes.func,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func // this is an internal React Date-picker func
};

export default CustomInput;
