import { ComponentProps, CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { Emphasis, Size, Status } from '@gs-ux-uitoolkit-common/design-system';
import { IconBaseProps } from '@gs-ux-uitoolkit-common/icon-font';
import { AlertCssClasses } from './alert-style-sheet';

/**
 * Common Alert props shared between Angular and React
 */
export interface AlertCommonProps<StyleType = CommonStyleType> extends ComponentProps<StyleType> {
    /**
     * Style classes to override.
     */
    classes?: Partial<AlertCssClasses>;

    /**
     * Determines if the alert has a "close" (dismiss) icon that removes it from view.
     */
    dismissible?: boolean;

    /**
     * The ARIA label text for the dismiss button. Useful for accessiblity because the close button
     * is a graphical "X" without visible text. By default the label is "Close". Learn more at
     * [Mozilla Developer Network](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-label_attribute)
     */
    dismissButtonAriaLabel?: string;

    /**
     * The emphasis of the alert.
     */
    emphasis?: AlertEmphasis;

    /**
     * Allows customization of the icon. By default, an icon appropriate to the {@link #status} is rendered.
     *
     * To see available icons, visit UI Toolkit [Iconography](http://ui.web.gs.com/toolkit/react/#/Styling%20Foundation/Iconography).
     */
    icon?: IconBaseProps;

    /**
     * The size of the alert.
     */
    size?: AlertSize;

    /**
     * The display status of the alert.
     */
    status?: AlertStatus;

    /**
     * Determines if the alert is visible.
     */
    visible?: boolean;
}

export const alertCommonDefaultProps: AlertCommonDefaultProps = {
    dismissButtonAriaLabel: 'Close alert',
    dismissible: false,
    emphasis: 'bold',
    size: 'md',
    status: 'information',
};

export type AlertCommonDefaultProps = Required<
    Pick<
        AlertCommonProps,
        'dismissButtonAriaLabel' | 'dismissible' | 'emphasis' | 'size' | 'status'
    >
>;

export type AlertEmphasis = Extract<Emphasis, 'bold' | 'subtle'>;
export type AlertSize = Extract<Size, 'sm' | 'md' | 'lg'>;
export type AlertStatus = Extract<Status, 'none' | 'information' | 'success' | 'warning' | 'error'>;

export const alertEmphases: AlertEmphasis[] = ['bold', 'subtle'];
export const alertSizes: AlertSize[] = ['sm', 'md', 'lg'];
export const alertStatuses: AlertStatus[] = ['none', 'information', 'success', 'warning', 'error'];
