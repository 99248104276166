import { PropsWithChildren, FC, memo, useEffect } from 'react';
import { ReactComponentProps } from '@gs-ux-uitoolkit-react/component';
import {
    textStyleSheet,
    TextCommonProps,
    getTextRootClassNames,
    defaultTextProps,
} from '@gs-ux-uitoolkit-common/text';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { componentAnalytics } from './analytics-tracking';

export type TextProps = PropsWithChildren<ReactComponentProps & TextCommonProps> & {
    /**
     * The HTML tag to use when rendering the text.
     */
    tag?: keyof JSX.IntrinsicElements;
};

/**
 * The Text component conveniently applies GS Design System typography and colors to your application text.
 */
export const Text: FC<TextProps> = memo(
    ({
        typography,
        color,
        fontFamily,
        fontSize,
        fontWeight,
        lineHeight,
        display,
        className,
        classes: overrideClasses,
        tag = 'span',
        bold = defaultTextProps.bold,
        italic = defaultTextProps.italic,
        underline = defaultTextProps.underline,
        disabled,
        ...props
    }) => {
        const HtmlTag = tag;
        const theme = useTheme();
        const cssClasses = useStyleSheet(textStyleSheet, {
            theme,
            typography,
            color,
            fontFamily,
            fontSize,
            fontWeight,
            lineHeight,
            display,
            bold,
            italic,
            underline,
            disabled,
        });

        useEffect(() => {
            //track component has rendered
            componentAnalytics.trackRender({ officialComponentName: 'text' });
        }, []); // Only run once

        return (
            <HtmlTag
                data-gs-uitk-component="text"
                className={getTextRootClassNames({ cssClasses, overrideClasses, className })}
                {...props}
            />
        );
    }
);
Text.displayName = 'Text';
