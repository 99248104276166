import { Component, Context, ContextType, isValidElement } from 'react';
import { createRoot } from 'react-dom/client';
import { renderToString } from 'react-dom/server';

import { FormContext, FormProps } from '@gs-ux-uitoolkit-react/form';

import {
    CommonSelectComponent,
    CommonSelectProps,
    OptionRendererData,
    createOptionRendererData,
    SelectOptionRenderer,
    SelectStatus,
    selectDefaultProps,
} from '@gs-ux-uitoolkit-common/select';
import { extractDataAttributes } from '@gs-ux-uitoolkit-react/shared';
import { Theme, ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';

import { SelectProps } from './select-props';
import { componentAnalytics } from './analytics-tracking';
/**
 * The Select component is used to select a single option from a list of options.
 * If you need to select multiple options please use the
 *    [SelectMultiple](#/Components/SelectMultiple) component.
 *
 * **Terminology**
 * - **option:** is defined as a single option inside the Select component's menu.
 * - **selectedOption:** is defined as the active option in the input box.
 *
 * <small>Note: Select is currently powered by the excellent [Choices.js](https://github.com/jshjohnson/Choices)
 * library. However, please do not rely on this fact because the underlying implementation may be
 * changed at a future date as new business requirements and feature requests come forward.
 * The UI Toolkit Select API will remain the same, however, regardless of the underlying
 * implementation.</small><br><br>
 *
 * @visibleName Select
 */
export class Select extends Component<SelectProps> {
    public static defaultProps: Partial<SelectProps> = {
        disabled: false,
        filterOptionsOnSearch: selectDefaultProps.filterOptionsOnSearch,
        defaultValue: null,
        noOptionsContent: selectDefaultProps.noOptionsContent,
        noResultsContent: selectDefaultProps.noResultsContent,
        optionRenderer: null,
        options: [],
        clearable: selectDefaultProps.clearable,
        searchable: selectDefaultProps.searchable,
        menuPlacement: selectDefaultProps.menuPlacement,
        selectedOptionRenderer: null,
        size: selectDefaultProps.size,
        inline: selectDefaultProps.inline,
        enableRenderCache: selectDefaultProps.enableRenderCache,
        enableRenderToString: false, // not using selectDefaultProps, because prop is React-specific
    };

    static contextType: Context<FormProps> = FormContext;

    declare context: ContextType<typeof FormContext>;

    private selectContainerEl: HTMLDivElement | undefined;
    private commonSelectComponent!: CommonSelectComponent;
    private status: SelectStatus | undefined;
    private theme: Theme | undefined;

    /**
     * Cache of optionRenderers -> React-aware optionRenderers.
     *
     * This is a WeakMap keyed by the original optionRenderer function, and the
     * values are the renderer function that converts a JSX return value to an
     * HTMLElement.
     *
     * This map is needed to keep the option renderer function references stable
     * for the cache inside the common select component. (It also helps to just
     * not create new option renderer functions each time the Select component
     * is updated.)
     */
    private reactAwareOptionRenderers = new WeakMap<
        NonNullable<SelectProps['optionRenderer']>,
        SelectOptionRenderer
    >();

    public getContextStatus() {
        return this.context.status === 'information' || this.context.status === undefined
            ? 'none'
            : this.context.status;
    }

    public componentDidMount() {
        this.status = this.props.status || this.getContextStatus();
        const initialProps = { ...this.props, status: this.status };

        this.commonSelectComponent = new CommonSelectComponent(
            this.selectContainerEl!,
            this.toCommonSelectProps(initialProps)
        );
        if (this.theme) {
            this.commonSelectComponent.setTheme(this.theme);
        }
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'select' });
    }

    public componentDidUpdate(prevProps: SelectProps) {
        const currentProps = {
            ...this.toCommonSelectProps(this.props),
            className: this.props.className!,
        };
        const previousProps = {
            ...this.toCommonSelectProps(prevProps),
            className: prevProps.className!,
        };

        const contextStatus = this.getContextStatus();
        let newStatus;

        // status from props should always take precedence
        // update the internal state so formContext can still track if needed after updates
        if (this.props.status && this.props.status !== this.status) {
            // if formContext was recently undefined, then prevProps will not accurately reflect the previous status, so rely on internal state
            previousProps.status = this.status;
            newStatus = this.props.status;

            // else if there is no status set on the Select directly and contextStatus is updated, update the status
        } else if (!this.props.status && this.status !== contextStatus) {
            previousProps.status = this.status;
            newStatus = contextStatus;
        }
        // If status was updated, update the local state reference
        if (newStatus) {
            this.status = newStatus;
        }

        currentProps.status = this.status;
        this.commonSelectComponent.setConfig(currentProps, previousProps);
        this.commonSelectComponent.setTheme(this.theme!);
    }

    public componentWillUnmount() {
        if (this.commonSelectComponent) {
            this.commonSelectComponent.destroy();
        }
    }

    /**
     * Function used to focus the select component.
     * This will focus the input element and show the menu.
     * @public
     */
    public focus() {
        this.commonSelectComponent.focus();
    }

    /**
     * Function used to blur the select component.
     * This will blur the input element and hide the menu.
     * @public
     */
    public blur() {
        this.commonSelectComponent.blur();
    }

    public render() {
        const { className, size, style, name, form, inputId } = this.props;
        const dataAttrs = extractDataAttributes(this.props);

        return (
            <ThemeConsumer>
                {(theme: Theme) => {
                    this.theme = theme;
                    if (this.commonSelectComponent) {
                        this.commonSelectComponent.setTheme(this.theme);
                    }
                    return (
                        <div
                            {...dataAttrs}
                            className={className}
                            data-gs-uitk-component="select"
                            data-size={size}
                            style={style}
                            ref={this.setSelectContainerEl}
                        >
                            <select
                                data-cy="gs-uitk-select__inner-select"
                                name={name}
                                form={form}
                                id={inputId}
                            />
                        </div>
                    );
                }}
            </ThemeConsumer>
        );
    }

    private setSelectContainerEl = (selectContainerEl: HTMLDivElement) => {
        this.selectContainerEl = selectContainerEl;
    };

    /**
     * Function used when we need to transform any of the React-specific
     * component props to the common Select props for consumption by the common
     * implementation.
     *
     * @param props The components props to transform.
     */
    private toCommonSelectProps(props: SelectProps): CommonSelectProps {
        const {
            optionRenderer: userOptionRenderer,
            selectedOptionRenderer: userSelectedOptionRenderer,
        } = props;
        let newOptionRenderer: CommonSelectProps['optionRenderer'] = undefined;
        let newSelectedOptionRenderer: CommonSelectProps['selectedOptionRenderer'] = undefined;

        if (userOptionRenderer) {
            newOptionRenderer = this.reactAwareOptionRenderers.get(userOptionRenderer);

            if (!newOptionRenderer) {
                newOptionRenderer = this.createReactAwareOptionRenderer(userOptionRenderer);
                this.reactAwareOptionRenderers.set(userOptionRenderer, newOptionRenderer);
            }
        }

        if (userSelectedOptionRenderer) {
            newSelectedOptionRenderer = this.reactAwareOptionRenderers.get(
                userSelectedOptionRenderer
            );

            if (!newSelectedOptionRenderer) {
                newSelectedOptionRenderer = this.createReactAwareOptionRenderer(
                    userSelectedOptionRenderer
                );
                this.reactAwareOptionRenderers.set(
                    userSelectedOptionRenderer,
                    newSelectedOptionRenderer
                );
            }
        }

        return {
            ...props,
            optionRenderer: newOptionRenderer,
            selectedOptionRenderer: newSelectedOptionRenderer,
        };
    }

    /**
     * Creates a React-aware optionRenderer wrapper which allows users to return JSX
     * from their `optionRenderer` and `selectedOptionRender` and we translate it
     * to an HTMLElement for the common Select implementation.
     */
    private createReactAwareOptionRenderer(
        rendererFn: NonNullable<SelectProps['optionRenderer']>
    ): SelectOptionRenderer {
        return (dataAttributes: OptionRendererData) => {
            const result = rendererFn(createOptionRendererData(dataAttributes));

            // If the developer provides us a ReactElement, render it using react-dom.
            if (isValidElement(result)) {
                if (this.props.enableRenderToString) {
                    const renderedHtml = renderToString(result);
                    return renderedHtml;
                } else {
                    const div = document.createElement('div');
                    const root = createRoot(div);
                    root.render(result);
                    return div;
                }
            } else {
                // Let the common implementation of Select handle it.
                return result;
            }
        };
    }
}
