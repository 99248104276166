import { logger } from '@gs-ux-uitoolkit-common/datacore';
import { DatePicker, DatePickerOptions } from '@gs-ux-uitoolkit-react/datepicker';

import { Input, InputIcon } from '@gs-ux-uitoolkit-react/input';

import { Component } from 'react';

export interface DateRangeControlProps {
    value?: string;
    formattedValue?: string;
    callback: (date: Date | null) => void;
    callbackOnClose?: boolean;
    disabled?: boolean;
    popupMode?: boolean;
    enableTime: boolean;
    className?: string;
}

export class DateRangeControl extends Component<DateRangeControlProps> {
    public render() {
        const handleCallback = (selectedDates: Date[]) => {
            // if no dates we reset the input
            if (selectedDates.length === 0) {
                this.props.callback(null);
                // Currently only support single date value, no range selection yet
            } else if (selectedDates.length === 1) {
                this.props.callback(selectedDates[0]);
            } else {
                logger.warn('Only single date values should be selected.');
            }
        };
        const options: DatePickerOptions = {
            wrap: true,
            dateFormat: this.props.enableTime ? 'Y-m-d h:i K' : 'Y-m-d',
            enableTime: this.props.enableTime,
            defaultDate: this.props.value ? this.props.value : '',
        };

        options.onChange = handleCallback;

        return (
            <DatePicker options={options} data-cy={`${this.props.className}-date-input`}>
                <Input
                    data-input={true}
                    size="sm"
                    placeholder="Choose date..."
                    className={`expression-range-input-value ${this.props.className || ''}`}
                    type="text"
                    name="date"
                    inputId="text-input-date"
                    value={this.props.formattedValue}
                    leadingContent={<InputIcon name="calendar-today" type="filled" />}
                    disabled={this.props.disabled}
                />
            </DatePicker>
        );
    }
}
