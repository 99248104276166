import { FunctionComponent, useContext, useState } from 'react';
import { ExternalDraggable } from './drag-and-drop-external';
import { v1 as uuid } from 'uuid';
import { dragAndDropContextCommonPropsDefaults } from '@gs-ux-uitoolkit-common/drag-and-drop';
import { draggableStyleSheet } from './draggable-style-sheet';

import { useStyleSheet, cx } from '@gs-ux-uitoolkit-react/style';
import { DragAndDropInternalContext } from './drag-and-drop-internal-context';
import { DragAndDropHandle } from './drag-and-drop-handle';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { defaultDraggableProps, DraggableProps } from './draggable-props';

export const Draggable: FunctionComponent<DraggableProps> = ({
    draggableId,
    index,
    dragIconVisibility = defaultDraggableProps.dragIconVisibility,
    grabArea = defaultDraggableProps.grabArea,
    dragHandleConfig,
    disabled = false,
    iconPosition = defaultDraggableProps.iconPosition,
    iconPlacement = defaultDraggableProps.iconPlacement,
    children,
    classes: overrideClasses,
    ...attributes
}) => {
    const theme = useTheme();
    const context = useContext(DragAndDropInternalContext);
    const draggableClasses = useStyleSheet(draggableStyleSheet, {
        spacing: context?.spacing ?? dragAndDropContextCommonPropsDefaults.spacing,
        theme,
        dragIconVisibility,
        disabled,
        iconPosition,
        iconPlacement,
    });
    const boxClasses = cx(draggableClasses.box, overrideClasses?.box);

    let boxInnerClasses = cx(draggableClasses.boxInner, overrideClasses?.boxInner);

    const [stableUuid] = useState(uuid());
    const normalizedDraggableId = draggableId || stableUuid;

    if (!context) {
        console.warn(
            'No DragAndDropContext detected! The drag and drop functionality will not work unless used within DragAndDropContext'
        );

        return (
            <div
                data-gs-uitk-component="draggable"
                data-draggable-id={normalizedDraggableId}
                data-draggable-no-context
                className={boxClasses}
                {...attributes}
            >
                <div
                    className={boxInnerClasses}
                    data-cy="gs-uitk-draggable__inner"
                    data-testid="draggableBoxInner"
                >
                    {children(undefined, undefined, undefined)}
                </div>
            </div>
        );
    }

    return (
        <ExternalDraggable
            draggableId={normalizedDraggableId}
            index={index}
            isDragDisabled={disabled}
        >
            {(provided, snapshot, rubric) => {
                if (snapshot.isDragging) {
                    boxInnerClasses = cx(
                        draggableClasses.boxInner,
                        overrideClasses?.boxInner,
                        draggableClasses.boxInnerDrag,
                        overrideClasses?.boxInnerDrag
                    );
                }

                return (
                    <div
                        data-gs-uitk-component="draggable"
                        data-draggable-id={normalizedDraggableId}
                        className={boxClasses}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...attributes}
                    >
                        <div
                            {...(grabArea === 'anywhere' || disabled
                                ? {
                                      ...provided.dragHandleProps,
                                      'data-drag-handle-draggable-id': normalizedDraggableId,
                                  }
                                : null)}
                            className={boxInnerClasses}
                            data-cy="gs-uitk-draggable__inner"
                            data-testid="draggableBoxInner"
                        >
                            {children(undefined, snapshot, rubric)}
                            {dragIconVisibility !== 'none' ? (
                                <DragAndDropHandle
                                    iconBaseProps={dragHandleConfig}
                                    iconPlacement={iconPlacement}
                                    draggableDragHandleProps={
                                        grabArea === 'icon' && provided.dragHandleProps
                                            ? {
                                                  'data-drag-handle-draggable-id':
                                                      normalizedDraggableId,
                                                  ...provided.dragHandleProps,
                                              }
                                            : undefined
                                    }
                                    classes={overrideClasses?.dragHandleClasses}
                                />
                            ) : null}
                        </div>
                    </div>
                );
            }}
        </ExternalDraggable>
    );
};
