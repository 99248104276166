import React from 'react';
import DOMPurify from 'dompurify';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import {Conditional} from "../../../../components/core/Conditional";
import translator from '../../../../services/translator';
import {addPlotLinesAndMarker, getChartZoomLevel} from "../../utils";

const {translate: t} = translator;

const HighChartComponent = (props) => {
  const {
    chartComponent, chartOptions, chartDispatch, onChartDateRangeChange,
    onChartZoomRangeChange, allShareClassData, selectedZoomOption,
    isFundOrBenchmarkSeriesDataMissing, isMetricDataMissing, run
  } = props;

  const showNoDataLabel = () => {
    const {series} = chartOptions;
    let noData = false;
    if (series && series.length === 1) {
      noData = series[0].noData;
    }
    return noData;
  };

  const getChartOptions = () => {
    const {chart = {}, xAxis = {}, ...restChartOptionProps} = chartOptions;
    return {
      chart: {
        ...chart
      },
      xAxis: {
        events: {
          setExtremes: (e) => {
            const {trigger} = e;
            // ZOOM OPTIONS CHANGE EVENT
            if (trigger === "rangeSelectorButton") {
              const {rangeSelectorButton: {text:zoomPreset}} = e;
              const currentZoomOption = getChartZoomLevel(zoomPreset);
              if (selectedZoomOption !== currentZoomOption) {
                onChartZoomRangeChange && onChartZoomRangeChange(e);
              }
            }
            // FROM/TO DATE CHANGE
            else if (trigger === "rangeSelectorInput" || trigger === "navigator") {
              onChartDateRangeChange && onChartDateRangeChange(e);
            }
          }
        },
        ...xAxis
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          dataGrouping: {
            enabled: false
          },
          point:{
            events: {
              click:(e) => {
                addPlotLinesAndMarker(e, allShareClassData, run, chartDispatch, chartOptions);
              }
            }
          }
        }
      },
      ...restChartOptionProps
    }
  };

  const updatedChartOptions = getChartOptions();
  const showNoDataText = showNoDataLabel(chartOptions);

  return (
    <div className="chart-wrapper">
      <HighchartsReact
        ref={chartComponent}
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={updatedChartOptions}
      />
      <Conditional condition={isFundOrBenchmarkSeriesDataMissing}>
        <div className="fund-data-missing">{t('tkFundBenchmarkUnavailableToTrack')} </div>
      </Conditional>
      <Conditional condition={isMetricDataMissing}>
        <div className="fund-data-missing">{t('tkMetricsUnavailableToTrack')}</div>
      </Conditional>
      <Conditional condition={showNoDataText}>
        <div
          className="fund-selection-missing"
          dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(t('tkNoDataAvailable'))}}
          style={{top: '25%'}}
        />
      </Conditional>
    </div>
  );
};

HighChartComponent.propTypes = {
  chartComponent: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  chartOptions: PropTypes.object,
  chartDispatch: PropTypes.func,
  onChartDateRangeChange: PropTypes.func,
  onChartZoomRangeChange: PropTypes.func,
  isFundOrBenchmarkSeriesDataMissing: PropTypes.bool,
  isMetricDataMissing: PropTypes.bool,
  allShareClassData: PropTypes.object,
  run: PropTypes.func,
  selectedZoomOption: PropTypes.object
};

export default HighChartComponent;
