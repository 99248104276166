import { isBrowser } from '@gs-ux-uitoolkit-common/shared';

// Memoization for browserSupportsSelector to avoid generating a <style> tag each time
// it's called with the same value
const browserSupportsSelectorResults: { [selector: string]: boolean } = {};

/**
 * Determines if the given CSS selector is supported by the current browser.
 *
 * Example:
 *
 *     browserSupportsSelector(':focus-visible');  // true in Chrome 86+, false in Chrome 85 and below
 */
export function browserSupportsSelector(cssSelector: string): boolean {
    // When on a server-side rendering (SSR) environment, we'll throw an error since we won't know what browser.
    // The passed CSS selector will be used in. This will force developers to make a decision about what styles to
    // apply when rendering server-side.
    if (!isBrowser) {
        throw new Error(
            'This browserSupportsSelector() function should only be called from a browser, not server-side.'
        );
    }

    const memoizedValue = browserSupportsSelectorResults[cssSelector];
    if (typeof memoizedValue === 'boolean') {
        return memoizedValue; // cache hit, return
    }

    const styleEl = document.createElement('style');
    let isSupported: boolean;

    try {
        document.head.appendChild(styleEl);
        styleEl.sheet!.insertRule(`${cssSelector} {}`); // throws for unsupported selectors
        isSupported = true;
    } catch {
        isSupported = false;
    } finally {
        styleEl.remove();
    }

    return (browserSupportsSelectorResults[cssSelector] = isSupported);
}
