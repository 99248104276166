export const fundFactCardTrackCTAEventData = () => ({
  page: {
    pageInfo: {
      component: "Fund Fact card"
    }
  },
  event: {
    eventName: "fund_fact_card_track_button"
  }
});
