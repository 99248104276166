import {getDomainMapper} from "../../../utils/commonUtils";
const domainMapper = getDomainMapper();
// End Temp Code

export default {
  FETCH_REPORTS: {
    'path': '/api/v1/reports',
    'method': 'POST'
  },
  FETCH_REPORT_FILTERS: {
    'path': '/api/v1/entitlements/getEntitledFirmsAndBranches',
    'method': 'GET'
  },
  REQUEST_REPORT_PDF: {
    'path': ' /api/v1/reports/export/pdf',
    'method': 'POST'
  },
  POLL_REPORTS_PDF: {
    'path': ' /api/v1/reports/export/pdf/poll',
    'method': 'POST'
  },
  DOWNLOAD_REPORTS_PDF: {
    'path': ' /api/v1/reports/export/pdf/download',
    'method': 'GET'
  },
  FETCH_ALL_REPORTS_GRID_DATA: {
    'path': `/content/${domainMapper}/nodes/reports-allReports-configurations.json`,
    'method': 'GET'
  },
  FETCH_ALL_REPORTS_GRID_CONFIGURATIONS: {
    'path': `/content/${domainMapper}/nodes/reports-allReportsGrid-columns-configurations.json`,
    'method': 'GET'
  },
  FETCH_MY_REPORTS_GRID_CONFIGURATIONS: {
    'path': `/content/${domainMapper}/nodes/reports-myReportsGrid-columns-configurations.json`,
    'method': 'GET'
  },
  FETCH_INDIVIDUAL_REPORT_GRID_VIEW: {
    'path': `/content/${domainMapper}/nodes/{viewId}`,
    'method': 'GET'
  },
  FETCH_INDIVIDUAL_REPORT_GRID_CONFIGURATIONS: {
    'path': `/content/${domainMapper}/nodes/reports-columns-configurations.json`,
    'method': 'GET'
  },
  FETCH_INDIVIDUAL_REPORT_ADDITIONAL_FILTER_CONFIGURATIONS: {
    'path': `/content/${domainMapper}/nodes/reports-additional-filter-configurations.json`,
    'method': 'GET'
  },
  FETCH_SAVED_REPORTS: {
    'path': '/api/v1/reports/getSavedReports',
    'method': 'GET'
  },
  FETCH_REPORT_VIEWS: {
    'path': '/api/v1/users/views/REPORTING',
    'method': 'GET'
  },
  SAVE_CUSTOM_REPORT_VIEW: {
    'path': '/api/v1/users/views/REPORTING',
    'method': 'POST'
  },
  SAVE_CUSTOM_REPORT: {
    'path': '/api/v1/reports/save',
    'method': 'POST'
  },
  DELETE_CUSTOM_REPORT: {
    'path': '/api/v1/reports/delete',
    'method': 'POST'
  },
  DELETE_REPORT_CUSTOM_VIEW: {
    'path': '/api/v1/users/views/REPORTING',
    'method': 'POST'
  },
  GENERATE_REPORT: {
    'path': '/api/v1/reports/generateReport',
    'method': 'POST'
  }
};
