import { Choices } from '@gs-ux-uitoolkit-common/choices';
import { Size, Status } from '@gs-ux-uitoolkit-common/design-system';
import { SelectPlacement } from '../select-props';
/**
 * Only used internally to normalize the select configuration.
 *  Helps us keep our code cleaner by removing the `undefined` check
 *  from various places.
 */
export interface NormalizedSelectConfig extends SelectConfigWithClassName {
    disabled: boolean;
    searchable: boolean;
    size: SelectSize;
    status: SelectStatus;
}

/**
 * Main props for the <Select /> component.
 * Used to customize the behavior of the select component.
 */
export interface SelectConfig extends SelectCommonConfig {
    /**
     * Determines which option is pre-selected upon instantiation of the component.
     *
     * Use this prop if you would like the Select component to control the option - i.e. the value
     * is changed automatically once a user has selected a new option ("uncontrolled mode").
     *
     * If you would like to use the component in a "controlled" fashion where the option
     * that is selected always match the value in the given input prop, use the
     * {@link #selectedValue} prop instead.
     */
    defaultValue?: null | string;

    /**
     * Whether the search input is displayed
     */
    searchable?: boolean;

    /**
     * Determines which options are selected on the component. This is a "controlled" prop
     * where component will always reflect the selected value specified as a string.
     *
     * This prop is useful when using a state management solution like Redux (for instance), where you
     * always want the component to reflect the state of the store.
     *
     * In order to have the Select component reflect when a user selects a new option,
     * you *must* add an {@link #onChange} callback and provide a new string to this prop.
     *
     * Note: If you would like to simply specify a default value when the component is instantiated
     * and allow the Select component itself to update when users
     * select a new option, use {@link #defaultValue} instead. If both {@link #defaultValue} and
     * this prop are specified, this prop takes precedence.
     */
    selectedValue?: string | null; // `null` represents no value.

    /**
     * Event emitted when a new option is selected by the user, or the selected option is removed.
     */
    onChange?: (evt: SelectChangeEvent) => void;

    /**
     * Event emitted when an option is selected by the user.
     */
    onSelect?: (evt: SelectEvent) => void;
}

/**
 * Used internally to have a config with the root className as a prop.
 */
export interface SelectConfigWithClassName extends SelectConfig {
    /**
     * The main class passed to root element of the component.
     */
    className: string;
}

/**
 * Common props between the <Select /> and </SelectMultiple /> components.
 * Used to customize the behavior of the two components.
 */
export interface SelectCommonConfig {
    /**
     * Whether the component is disabled.
     */
    disabled?: boolean;

    /**
     * Determines if the clear all button should be rendered on the component.
     */
    clearable?: boolean;

    /**
     * Associates this component with a form element, if it is not already
     * contained by a form element. Learn more at [Mozilla Developer Network](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/select#attr-form).
     */
    form?: string;

    /**
     * Whether options should be filtered by input characters or not.
     * If false, the search event will still emit, but options will not be filtered.
     *
     * This can be set to `false`, for example, if you wish to filter the options outside of the
     * Select component, and pass the new options back into the select component.
     */
    filterOptionsOnSearch?: SelectFilterOptions | boolean;

    /**
     * In vanilla HTML, a `<select>` can be associated with a `<label>` via an
     * `id` attribute. For this specific purpose in the UI Toolkit, `inputId`
     * fulfills this same role. A dedicated attribute makes this relationship
     * more explicit and also allows the `id` attribute to be set on the host
     * element as it is for all other components in Angular and React alike.
     *
     * Note that explicitly associating a Label with a Select is not necessary
     * when the Select is rendered as a child of the Label: `<Label><Select></Label>`.
     * This implementation is recommended.
     *
     * Because `inputId` will be applied to a HTML element as an `id`
     * attribute when rendered by the UI Toolkit component, `inputId` should be
     * a unique value just like `id` should be a unique value.
     */
    inputId?: string;

    /**
     * Attaches a name to the component. If this component is associated with a
     * form, this name will be paired with this component's value when the form
     * is submitted.
     */
    name?: string;

    /**
     * The text shown when the select box search has no results as a result of filtering the options.
     *
     * Only displayed while {@link #filterOptionsOnSearch} is `true`
     */
    noResultsContent?: string;

    /**
     * The text that is shown when a user has selected all the possible options or no options are loaded.
     */
    noOptionsContent?: string;

    /**
     * The options to display in the menu.
     *
     * Example:
     *
     *     [
     *         { label: 'Option 1', value: 'option-1' },
     *         { label: 'Option 2', value: 'option-2' },
     *
     *         // option with optional props:
     *         {
     *              label: 'Option 3',
     *              value: 'option-3',
     *              disabled: true, // disables the option in the menu
     *              customProperties: { // your own extra props here
     *                  myProp: 'myValue',
     *              }
     *         }
     *     ]
     */
    options?: SelectOption[];

    /**
     * Placeholder content for the select box when no selections have been made.
     */
    placeholder?: string;

    /**
     * Which direction the dropdown menu should open toward.
     */
    menuPlacement?: SelectPlacement;

    /**
     * The fields to search on (searches by 'label' and 'value' by default)
     */
    searchFields?: string[];

    /**
     * Size of the component.
     */
    size?: SelectSize;

    /**
     * Status of the component.
     */
    status?: SelectStatus;

    /**
     * By default, the component displays as "block" and expands to the full
     * width of its container. To disable this behavior, set to true.
     */
    inline?: boolean;

    /**
     * The HTML to render for any given menu option.
     *
     * This can be used if you wish to customize the HTML content for each menu option.
     */
    optionRenderer?: SelectOptionRenderer;

    /**
     * The HTML to render for any given selected option.
     *
     * This can be used if you wish to customize the HTML content for the selected options.
     */
    selectedOptionRenderer?: SelectOptionRenderer;

    /**
     * Event emitted when the Select's menu is hidden.
     */
    onMenuHide?: () => void;

    /**
     * Event emitted when the Select's menu is shown.
     */
    onMenuShow?: () => void;

    /**
     * Event emitted when a user types a character into the search box.
     */
    onSearch?: (evt: SelectSearchEvent) => void;

    /**
     * Handler for the change event fired when selected object loses the input focus
     */
    onBlur?: (evt: SelectInputBlurEvent) => void;

    /**
     * Enables Select's render cache which optimizes re-render performance.
     *
     * Set this to `true` when populating the Select with many options (generally
     * 300+), and you are using an {@link #optionRenderer}. The renderer's return values
     * will be cached, preventing extraneous re-renders.
     *
     * For this to work, the following must be true:
     *
     * 1. All {@link #options} must have a unique `value` property.
     * 2. The {@link #options} objects themselves must be both:
     *    A. Reference-stable: The same Option objects must be used if the {@link #options} array
     *    changes. Do not create new Option objects for Options that haven't changed.
     *    B. Immutable: Only the Option objects themselves are the cache keys, so mutating the Option's
     *    properties without creating a new object will not be picked up for rendering.
     */
    enableRenderCache?: boolean;
}

/**
 * Represents a single option in the menu.
 */
export type SelectOption = SelectOptionLeaf | SelectOptionGroup;

/**
 * Represents the various sizes we currently support.
 */
export type SelectSize = Extract<Size, 'sm' | 'md' | 'lg'>;

/**
 * The status allowed on Select and SelectMultiple.
 */
export type SelectStatus = Extract<Status, 'none' | 'success' | 'warning' | 'error' | 'loading'>;

/**
 * Represents a single option in the menu.
 */
export interface SelectOptionLeaf {
    /**
     * Specifies whether the option is disabled. If the option is disabled, the user will not be
     * able to select it.
     *
     * Defaults to `false`
     */
    disabled?: boolean;

    /**
     * Label to display for the option in the menu.
     */
    label: string;

    /**
     * The underlying value for the given option.
     */
    value: string;

    /**
     * Object containing custom information that can be stored in each option.
     */
    customProperties?: Record<string, any>;
}

/**
 * Represents an option group or a single option in the menu.
 */
export interface SelectOptionGroup {
    /**
     * Label to display for the option group in the menu.
     */
    label: string;

    /**
     * An array of child options. Using this property turns the SelectOption into an Option Group.
     */
    options: SelectOptionLeaf[];
}

/**
 * Determines if the given `option` is a `SelectOptionGroup` (as opposed to a `SelectOptionLeaf`)
 */
export function isSelectOptionGroup(option: SelectOption): option is SelectOptionGroup {
    return !!(option as SelectOptionGroup).options;
}

/**
 * Determines if the given `option` is a `SelectOptionLeaf` (as opposed to a `SelectOptionGroup`)
 */
export function isSelectOptionLeaf(option: SelectOption): option is SelectOptionLeaf {
    return 'value' in option;
}

/**
 * Props used to customize filter capabilities
 */
export interface SelectFilterOptions {
    /**
     * Minimum number of characters to type before a search is executed.
     *
     * Defaults to `1`
     */
    minChars?: number;

    /**
     * Maximum number of options to display on any given search.
     *
     * Defaults to `-1` for no limit
     */
    maxSearchResults?: number;
}

/**
 * The type of the functions for the optionRenderer and selectedOptionRenderer
 * props.
 */
export type SelectOptionRenderer = (data: OptionRendererData) => string | HTMLElement;

/**
 * The type of the functions for optionRenderer and selectedOptionRenderer that
 * Choices.js expects.
 */
export type ChoicesOptionRenderer = (
    classNames: Choices.ClassNames,
    data: Choices.Choice
) => HTMLElement;

/**
 * The data object passed to SelectOptionRenderer functions.
 */
export interface OptionRendererData extends SelectOptionLeaf {
    /**
     * Signifies if the current option is selected.
     */
    selected: boolean;
}

/**
 * Represents the event emitted for the 'onSearch' callback.
 * Triggered each time a user types a character into the select component.
 */
export interface SelectSearchEvent {
    /**
     * The character(s) being searched.
     */
    searchValue: string;

    /**
     * The number of options being returned from the given search.
     */
    resultsCount: number;

    /**
     * The `name` of the component triggering the event, if it is specified
     */
    componentName?: string;
}

/**
 * Represents the event emitted for the 'onChange' callback.
 * Triggered each time a user add/removes an option
 */
export interface SelectChangeEvent {
    /**
     * Currently selected option;
     */
    selectedOption: SelectOptionLeaf | null;

    /**
     * Currently selected value;
     */
    selectedValue: string | null;

    /**
     * The `name` of the component triggering the event, if it is specified
     */
    componentName?: string;
}

/**
 * Represents the event emitted for the 'onSelect' callback.
 * Triggered each time a user selects an option
 */
export interface SelectEvent {
    /**
     * Currently selected option;
     */
    selectedOption: SelectOptionLeaf | null;

    /**
     * Currently selected value;
     */
    selectedValue: string | null;

    /**
     * The `name` of the component triggering the event, if it is specified
     */
    componentName?: string;
}

/**
 * Represents the object of a choices.js selected option.
 * Used since choices.js does not expose that interface.
 */
export interface ChoicesSelectedOption {
    active: boolean;
    choiceId: number;
    highlighted: boolean;
    id: number;
    label: string;
    value: string;
    customProperties?: Record<string, any>;
}

export const selectClassPrefix = 'gs-uitk-select';

export type SelectInputBlurEvent = FocusEvent;
