import { Component } from 'react';
import { ColumnHintControlPrefixSuffix } from '../formatControls/column-hint-control-prefix-suffix';
import { ColumnHintFormatSection } from './column-hint-format-section';
import { Col, Row } from '@gs-ux-uitoolkit-react/layout';
import { FormatControlProps } from '../column-hint-format-manager';
import { ColumnHintLayoutSection } from '../../../../../components/column-hint/layouts-section';
import { ColumnHintFontsSection } from '../../../../../components/column-hint/fonts-section';
import { ColumnHintColoursSection } from '../../../../../components/column-hint/colours-section';
import { ColumnHintOtherSection } from '../../../../../components/column-hint/other-section';
import { ColumnHintHyperlinksSection } from '../../../../../components/column-hint/hyperlinks-section';

export class ColumnHintStringFormat extends Component<FormatControlProps> {
    public render() {
        return (
            <div>
                <ColumnHintFormatSection title="String (text)">
                    <Row>
                        <Col sm={4}>
                            <ColumnHintControlPrefixSuffix
                                columnHint={this.props.columnHint}
                                onChangeHint={this.props.onChangeHint}
                            />
                        </Col>
                    </Row>
                </ColumnHintFormatSection>
                <ColumnHintLayoutSection
                    columnHint={this.props.columnHint}
                    onChangeHint={this.props.onChangeHint}
                />
                <ColumnHintFontsSection
                    columnHint={this.props.columnHint}
                    onChangeHint={this.props.onChangeHint}
                />
                <ColumnHintColoursSection
                    columnHint={this.props.columnHint}
                    onChangeHint={this.props.onChangeHint}
                />
                <ColumnHintHyperlinksSection
                    columnHint={this.props.columnHint}
                    onChangeHint={this.props.onChangeHint}
                />

                <ColumnHintOtherSection
                    columnHint={this.props.columnHint}
                    onChangeHint={this.props.onChangeHint}
                />
            </div>
        );
    }
}
