export interface Breakpoint {
    minWidth: number;
    maxWidth: number;
}

export interface Breakpoints {
    xs: Breakpoint;
    sm: Breakpoint;
    md: Breakpoint;
    lg: Breakpoint;
    xl: Breakpoint;
    xxl: Breakpoint;
    xxxl: Breakpoint;
}

export const breakpointNames = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl'];

/**
 * GSDS Breakpoints.
 */
export const breakpoints: Breakpoints = {
    xs: {
        minWidth: 0,
        maxWidth: 599,
    },
    sm: {
        minWidth: 600,
        maxWidth: 767,
    },
    md: {
        minWidth: 768,
        maxWidth: 1023,
    },
    lg: {
        minWidth: 1024,
        maxWidth: 1279,
    },
    xl: {
        minWidth: 1280,
        maxWidth: 1439,
    },
    xxl: {
        minWidth: 1440,
        maxWidth: 1599,
    },
    xxxl: {
        minWidth: 1600,
        maxWidth: Number.MAX_VALUE,
    },
};
