import {
    useState,
    useEffect,
    CSSProperties,
    InputHTMLAttributes,
    ReactNode,
    MouseEvent,
    FunctionComponent,
} from 'react';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import {
    SwitchProps as SwitchCommonProps,
    switchDefaultProps,
    switchStyleSheet,
    getSwitchRootClasses,
    getSwitchInputClasses,
    getSwitchLabelClasses,
    SwitchChangeEventProps,
} from '@gs-ux-uitoolkit-common/switch';
import { extractDataAttributes } from '@gs-ux-uitoolkit-react/shared';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { Label } from '@gs-ux-uitoolkit-react/label';
import { componentAnalytics } from './analytics-tracking';
import { omit } from 'gs-uitk-lodash';

export interface SwitchProps
    extends SwitchCommonProps<CSSProperties>,
        Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'onChange'> {
    /**
     * Children will display as the switch's label.
     */
    children?: ReactNode;

    /**
     * Event raised when the switch toggle value changes.
     */
    onChange?: (event: SwitchChangeEvent) => void;
}

export type SwitchChangeEvent = SwitchChangeEventProps & MouseEvent<HTMLInputElement>;

// With custom form controls, input elements cannot be nested inside labels, so
// we must link labels to inputs by id.
let uidIndex = 0;
function getUid() {
    uidIndex += 1;
    return `gs-uitk-toggle-switch-${uidIndex}`;
}

/**
 * Switches toggle an option on or off.
 */
export const Switch: FunctionComponent<SwitchProps> = ({
    children,
    className,
    classes: overrideClasses,
    disabled,
    status = switchDefaultProps.status,
    defaultToggledOn,
    toggledOn,
    size = switchDefaultProps.size,
    labelPlacement = switchDefaultProps.labelPlacement,
    style,
    title,
    id,
    inline = switchDefaultProps.inline,
    onChange = () => {},
    onClick = () => {},
    ...otherProps
}) => {
    const [uid, setUid] = useState<string | null>(null);
    const dataAttributes = extractDataAttributes(otherProps);
    const nonDataAttributes = omit(otherProps, Object.keys(dataAttributes));

    const theme = useTheme();

    const cssClasses = useStyleSheet(switchStyleSheet, {
        theme,
        inline,
        labelPlacement,
        size,
        status,
    });
    const rootClasses = getSwitchRootClasses({
        className,
        cssClasses,
        overrideClasses,
    });
    const inputClasses = getSwitchInputClasses({ cssClasses, overrideClasses });
    const labelClasses = getSwitchLabelClasses({ cssClasses, overrideClasses });

    useEffect(() => {
        setUid(getUid());
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'switch' });
    }, []);

    function onInternalClick(event: MouseEvent<HTMLInputElement>) {
        onClick(event);
        if (!event.isDefaultPrevented()) {
            handleChange(event);
        }
    }

    function handleChange(event: MouseEvent<HTMLInputElement>) {
        const { name } = otherProps;
        const target = event.target as HTMLInputElement;

        // Sync aria-checked with target.checked:
        target.setAttribute('aria-checked', target.checked ? 'true' : 'false');

        // Add "toggledOn" and "name" to the event:
        Object.assign(event, { toggledOn: target.checked, name });
        event.persist(); // See https://reactjs.org/docs/legacy-event-pooling.html

        onChange(event as SwitchChangeEvent);
        if (isControlled()) {
            // When controlled, the input's checked state is reset to the
            // controlled checked value. Developers can update the checked value
            // in a change event handler.
            target.checked = !!toggledOn;
            target.setAttribute('aria-checked', target.checked ? 'true' : 'false');
        }
    }

    /**
     * Avoids this React error:
     * ```
     * Warning: Failed prop type: You provided a `checked` prop to a form field
     * without an `onChange` handler. This will render a read-only field. If the
     * field should be mutable use `defaultChecked`. Otherwise, set either
     * `onChange` or `readOnly`.
     * ```
     */
    function onInternalChange() {}

    function isControlled() {
        return toggledOn != undefined;
    }

    return (
        <div
            data-gs-uitk-component="switch"
            data-label-placement={labelPlacement}
            data-size={size}
            className={rootClasses}
            style={style}
            title={title}
            id={id}
            aria-disabled={disabled}
            {...dataAttributes}
        >
            <input
                {...nonDataAttributes}
                {...(uid && { id: uid })}
                type="checkbox"
                data-cy="gs-uitk-switch__input"
                checked={toggledOn}
                defaultChecked={
                    // defaultChecked should only be set if checked is not set;
                    // i.e., if the input is uncontrolled.
                    toggledOn === undefined ? defaultToggledOn : undefined
                }
                disabled={disabled}
                className={inputClasses}
                // onClick is used to register changed events to support all browsers:
                // https://stackoverflow.com/questions/5575338/what-the-difference-between-click-and-change-on-a-checkbox/5575369)
                onClick={onInternalClick}
                onChange={onInternalChange}
                role="switch"
                aria-checked={toggledOn ? 'true' : 'false'}
                aria-disabled={disabled}
            />
            <Label
                size={size}
                classes={{ label: labelClasses }}
                aria-disabled={disabled}
                disabled={disabled}
                {...(uid && { for: uid })}
                data-cy="gs-uitk-switch__label"
            >
                {children}
            </Label>
        </div>
    );
};
