import { Module } from '@ag-grid-community/core';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { UitkAggFuncPatchService } from './agg-func-patch-service';

/**
 * Module to apply custom uitk changes and patches to the grid
 */
export const UitkModule: Module = {
    version: '29.3.5',
    moduleName: 'uitk-module',
    beans: [UitkAggFuncPatchService],
    dependantModules: [RowGroupingModule],
};
