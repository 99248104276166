import { MaskedColumnActionKeys } from '../actions/action-constants';
import { MaskedColumnActionTypes } from '../actions/masked-column-action';
import { MaskedColumnState } from '../datagrid-state';

/**
 * Initial state of the Masked Column State
 */
export const defaultMaskedColumnState: MaskedColumnState = {
    columnIdList: [],
    enabled: true,
};

/**
 * Reducer for the Masked Column
 * @param state State of the Masked Column
 * @param action Action received
 */
export const maskedColumnReducer = (
    state: MaskedColumnState = defaultMaskedColumnState,
    action: MaskedColumnActionTypes
): MaskedColumnState => {
    switch (action.type) {
        case MaskedColumnActionKeys.ADD_MASKED_COLUMN:
            return {
                ...state,
                columnIdList: [...state.columnIdList, action.columnId],
            };
        case MaskedColumnActionKeys.ADD_MASKED_COLUMN_LIST:
            return {
                ...state,
                columnIdList: [...state.columnIdList, ...action.columnIdList],
            };
        case MaskedColumnActionKeys.REMOVE_MASKED_COLUMN_LIST:
            return {
                ...state,
                columnIdList: state.columnIdList.filter(x => !action.columnIdList.includes(x)),
            };
        default:
            return state;
    }
};
