import { Theme } from './theme';

/**
 * Creates class definitions for a component given specific style
 * overrides, and passes the specified props to the specified function
 * for handling the creation of the definitions
 * @param props Props used by the class definition creation
 * @param createDefaultStyledClasses Custom function called to apply overrides
 * @param styleOverrides Component specific theme overrides
 * @returns The class definitions for the component
 */
export function createComponentClassDefinitions<CssProps extends { theme: Theme }, StyledClasses>(
    props: CssProps,
    createDefaultStyledClasses: (props: CssProps) => StyledClasses,
    styleOverrides?: (params: {
        props: CssProps;
        createDefaultStyledClasses: () => StyledClasses;
    }) => StyledClasses
): StyledClasses {
    if (!styleOverrides) {
        return createDefaultStyledClasses(props);
    } else {
        if (typeof styleOverrides === 'function') {
            return styleOverrides({
                props,
                createDefaultStyledClasses: () => createDefaultStyledClasses(props),
            });
        } else {
            return styleOverrides;
        }
    }
}
