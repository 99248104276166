import { StyleSheet } from '@gs-ux-uitoolkit-common/style';
import { focusedBorderMargin } from '../common/common-style-sheet';

export interface ButtonGroupStyleSheetProps {}

export interface ButtonGroupCssClasses {
    root: string;
    'orientation-horizontal': string;
    'orientation-vertical': string;
}

export const buttonGroupStyleSheet = new StyleSheet(
    'button-group',
    (_props: ButtonGroupStyleSheetProps) => {
        return {
            root: {
                position: 'relative',
                display: 'inline-flex',
                verticalAlign: 'middle',
            },
            ['orientation-horizontal']: {
                // buttons except first
                [`> [data-gs-uitk-component="button"]:not(:first-child),
                    > [data-gs-uitk-component="button-select"]:not(:first-child)`]: {
                    marginLeft: '1px',

                    [`[data-cy="gs-uitk-button__button"],
                            [data-cy="gs-uitk-button-select__label"],
                            [data-cy="gs-uitk-button-select__target"]`]: {
                        borderTopLeftRadius: '0',
                        borderBottomLeftRadius: '0',

                        '&:focus, &:focus-within': {
                            '&:before': {
                                borderTopLeftRadius: '0',
                                borderBottomLeftRadius: '0',
                            },
                        },
                    },
                    [`&[data-emphasis='subtle']`]: {
                        marginLeft: '-1px',

                        [`[data-cy="gs-uitk-button__button"]`]: {
                            '&:focus, &:focus-within': {
                                '&:before': {
                                    left: '-1px',
                                },
                            },
                        },
                    },
                },

                // subtle styles dont apply when next to a non-subtle button.
                // styles must be set here instead of nested in the "buttons except first" rule
                // so that it also applies to subtle buttons in the 2nd position
                [`> [data-gs-uitk-component="button"]:not([data-emphasis='subtle'])
                    + [data-gs-uitk-component="button"][data-emphasis='subtle']`]: {
                    marginLeft: '1px',

                    [`[data-cy="gs-uitk-button__button"]`]: {
                        '&:focus, &:focus-within': {
                            '&:before': {
                                left: focusedBorderMargin,
                            },
                        },
                    },
                },

                // buttons except last
                [`> [data-gs-uitk-component="button"]:not(:last-child),
                    > [data-gs-uitk-component="button-select"]:not(:last-child)`]: {
                    [`[data-cy="gs-uitk-button__button"],
                            [data-cy="gs-uitk-button-select__label"],
                            [data-cy="gs-uitk-button-select__target"]`]: {
                        borderTopRightRadius: '0',
                        borderBottomRightRadius: '0',

                        '&:focus, &:focus-within': {
                            '&:before': {
                                borderTopRightRadius: '0',
                                borderBottomRightRadius: '0',
                            },
                        },
                        [`&[data-emphasis='subtle']`]: {
                            '&:focus, &:focus-within': {
                                '&:before': {
                                    right: '-1px',
                                },
                            },
                        },
                    },
                    [`&:not([data-emphasis='subtle'])
                            + [data-gs-uitk-component="button"][data-emphasis='subtle']`]: {
                        [`> [data-cy="gs-uitk-button__button"]`]: {
                            '&:focus, &:focus-within': {
                                '&:before': {
                                    right: focusedBorderMargin,
                                },
                            },
                        },
                    },
                },
            },
            ['orientation-vertical']: {
                flexDirection: 'column',

                // buttons except first
                [`> [data-gs-uitk-component="button"]:not(:first-child),
                    > [data-gs-uitk-component="button-select"]:not(:first-child)`]: {
                    marginTop: '1px',

                    [`[data-cy="gs-uitk-button__button"],
                            [data-cy="gs-uitk-button-select__label"],
                            [data-cy="gs-uitk-button-select__target"]`]: {
                        borderTopLeftRadius: '0',
                        borderTopRightRadius: '0',

                        '&:focus, &:focus-within': {
                            '&:before': {
                                borderTopLeftRadius: '0',
                                borderTopRightRadius: '0',
                            },
                        },
                        [`&[data-emphasis='subtle']`]: {
                            marginTop: '-1px',

                            [`[data-cy="gs-uitk-button__button"]`]: {
                                '&:focus, &:focus-within': {
                                    '&:before': {
                                        top: '-1px',
                                    },
                                },
                            },
                        },
                    },
                },

                // subtle styles dont apply when next to a non-subtle button.
                // styles must be set here instead of nested in the "buttons except first" rule
                // so that it also applies to subtle buttons in the 2nd position
                [`> [data-gs-uitk-component="button"]:not([data-emphasis='subtle'])
                    + [data-gs-uitk-component="button"][data-emphasis='subtle']`]: {
                    marginTop: '1px',

                    [`[data-cy="gs-uitk-button__button"]`]: {
                        '&:focus, &:focus-within': {
                            '&:before': {
                                top: focusedBorderMargin,
                            },
                        },
                    },
                },

                // buttons except last
                [`> [data-gs-uitk-component="button"]:not(:last-child),
                    > [data-gs-uitk-component="button-select"]:not(:last-child)`]: {
                    [`[data-cy="gs-uitk-button__button"],
                            [data-cy="gs-uitk-button-select__label"],
                            [data-cy="gs-uitk-button-select__target"]`]: {
                        borderBottomLeftRadius: '0',
                        borderBottomRightRadius: '0',

                        '&:focus, &:focus-within': {
                            '&:before': {
                                borderBottomLeftRadius: '0',
                            },
                            [`&[data-emphasis='subtle']`]: {
                                '&:focus, &:focus-within': {
                                    '&:before': {
                                        bottom: '-1px',
                                    },
                                },
                            },
                        },
                    },
                    [`&:not([data-emphasis='subtle'])
                            + [data-gs-uitk-component="button"][data-emphasis='subtle']`]: {
                        [`> [data-cy="gs-uitk-button__button"]`]: {
                            '&:focus, &:focus-within': {
                                '&:before': {
                                    bottom: focusedBorderMargin,
                                },
                            },
                        },
                    },
                },
            },
        };
    }
);
