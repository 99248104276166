import { ReactNode, FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { TabPane, TabPaneProps } from 'reactstrap';
import { TabCommonProps } from '@gs-ux-uitoolkit-common/tabs';

// Tab title accepts a ReactElement, but the type in reactstrap is set to string
type TabPanePropsWithoutTitle = Omit<TabPaneProps, 'title'>;

export interface TabProps extends TabPanePropsWithoutTitle {
    /**
     * Leading icon of the tab
     */
    leadingIcon?: TabCommonProps['leadingIcon'];
    /**
     * Key of the tab, can be used to programmatically select/manage tab
     */
    tabKey?: string | number;
    /**
     * Title of the tab
     */
    title?: ReactNode;
}

export const Tab: FunctionComponent<TabProps> = ({
    tabKey,
    children,
    leadingIcon,
    title,
    ...other
}) => (
    <TabPane {...other} tabId={tabKey}>
        {children}
    </TabPane>
);

Tab.propTypes = {
    children: PropTypes.node,
    leadingIcon: PropTypes.object,
    tabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.node,
} as { [key: string]: any };
