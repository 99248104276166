import React, { forwardRef, useState, useImperativeHandle } from 'react';
import { Input } from '@gs-ux-uitoolkit-react/input';
import { Form, FormGroup, FormFeedback } from '@gs-ux-uitoolkit-react/form';
import PropTypes from 'prop-types';
import translator from '../../../../../../services/translator';

const { translate: t } = translator;

const SearchForm = forwardRef(({ onSubmit }, ref) => {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    if (inputValue) {
      onSubmit(inputValue);
    }
  };

  const onClearClick = () => {
    setInputValue('');
    setError(false);
  };

  const onChange = e => {
    setInputValue(e.target.value);
    if (error) {
      setError(false);
    }
  };

  useImperativeHandle(ref, () => ({ onClearClick, setError }), []);

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup status={error ? 'error' : 'none'} className={error && 'accounts__errorGroup'}>
        <Input
          size='sm'
          type='search'
          placeholder={t('tkFirmBranchAccount')}
          data-testid='account-search-input'
          value={inputValue}
          clearable
          onClearClick={onClearClick}
          onChange={onChange}
          status={error && 'error'}
          className={error ? 'accounts__searchInput-error' : 'accounts__searchInput'}
        />
        {error && <FormFeedback className='accounts__errorFeedback'>{t('tkFirmBranchAccountError')}</FormFeedback>}
      </FormGroup>
    </Form>
  );
});

SearchForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default SearchForm;
