import { ColumnHintFormatSection } from '../../plugins/column-hint/view/components/formatScreens/column-hint-format-section';
import { columnFormattingPluginStylePrefix } from '../../style/plugins/column-hint-plugin-stylesheet';
import { Row, Col } from '@gs-ux-uitoolkit-react/layout';
import { FormatControlProps } from '../../plugins/column-hint/view/components/column-hint-format-manager';
import { ColumnHintControlGroupingDelimeter } from '../../plugins/column-hint/view/components/formatControls/column-hint-control-grouping-delimiter';
import { ColumnHintControlCssClasses } from '../../plugins/column-hint/view/components/formatControls/column-hint-control-css-classes';
export const ColumnHintOtherSection = (props: FormatControlProps) => {
    return (
        <ColumnHintFormatSection title="Other">
            <Row>
                <Col sm={12}>
                    <div
                        className={`${columnFormattingPluginStylePrefix}_flex-two-column-container ${columnFormattingPluginStylePrefix}_other`}
                    >
                        <ColumnHintControlGroupingDelimeter
                            columnHint={props.columnHint}
                            onChangeHint={props.onChangeHint}
                        />
                        <ColumnHintControlCssClasses
                            columnHint={props.columnHint}
                            onChangeHint={props.onChangeHint}
                        />
                    </div>
                </Col>
            </Row>
        </ColumnHintFormatSection>
    );
};
