import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import Button from '../Button';
import './ExternalLinkModal.scss';

const customModalStyle={position: 'fixed', top: '50%', left: '50%', height: 'auto', width: '432px', transform: 'translate(-50%, -50%)'};

const ExternalLinkModal = ({isModalOpen, handleClose, labels, handleUserPreferenceOnExtNav}) => {
  return(
    <Modal customModalStyle={customModalStyle} customClass="modal--center external-modal-index" backdropCustomClass="external-modal-backdrop-index" open={isModalOpen} handleClose={handleClose}>
      <div className="extNavConfirmation">
        <div className="extNavConfirmation__heading">{labels.tkCopyPort185Head}</div>
        <hr className="extNavConfirmation__separator" />
        <div className="extNavConfirmation__inputContainer">
          {labels.tkCopyPort185SubHead}
        </div>
        <div className="extNavConfirmation__buttonRow">
          <Button label={labels.tkCancel} customClass='button-primary-small' clickHandler={(event) => handleUserPreferenceOnExtNav(event, false)} />
          <Button label={labels.tkContinue}   customClass='button-secondary-small' clickHandler={(event) => handleUserPreferenceOnExtNav(event, true)} />
        </div>
      </div>
    </Modal>
  );
};

ExternalLinkModal.propTypes = {
  labels: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleUserPreferenceOnExtNav: PropTypes.func
};

export default ExternalLinkModal;
