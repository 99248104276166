import {
    ExpressionCondition,
    ExpressionGroup,
    ExpressionOperator,
    ExpressionQuery,
    ExpressionRule,
    ValueTypeRule,
} from '@gs-ux-uitoolkit-common/dra';
import { logger } from '../libraries/logger';
import { QuickFilter } from '../plugin/quick-filter/quickfilter';
import { genericExpressionHelper, invariantQuery } from './helpers/expression-helper';

export interface ExpressionComponentWrapper {
    getRawValue: (row: any, field: string) => any;
    getDisplayValue: (row: any, field: string) => any;
    doesColumnExist?: (field: string) => boolean;
    getPivotedColumnId?: (field: string | null, pivotKeys?: string[]) => string;
}

export type RowData = any;

// TODO: Possibly duplicate logic of method {@link getDisplayValueWithColumn}
export const FILTERABLE_SPECIAL_VALUES_MAP = [
    {
        displayedValue: '[null]',
        rawValue: null,
    },
    {
        displayedValue: '[NaN]',
        rawValue: NaN,
    },
    {
        displayedValue: '[undefined]',
        rawValue: undefined,
    },
];

const FILTERABLE_SPECIAL_VALUES = FILTERABLE_SPECIAL_VALUES_MAP.map(kv => kv.displayedValue);

export class ExpressionEvaluator {
    public evaluate(
        rowNode: RowData,
        expressionQuery: ExpressionQuery,
        wrapper: ExpressionComponentWrapper,
        quickFilterList: QuickFilter[],
        filterIfNoColumn: boolean = true
    ): boolean {
        if (genericExpressionHelper.isEmptyRule(expressionQuery)) {
            return false;
        }
        if (genericExpressionHelper.isSingleRule(expressionQuery)) {
            const expressionRule = expressionQuery as ExpressionRule;
            let expressionRuleField = expressionRule.field;

            if (
                wrapper.getPivotedColumnId &&
                expressionRule.pivotKeys &&
                expressionRule.pivotKeys.length
            ) {
                expressionRuleField = wrapper.getPivotedColumnId(
                    expressionRule.field,
                    expressionRule.pivotKeys
                );
            }

            let actualValue: any = wrapper.getRawValue(rowNode, expressionRuleField);
            if (expressionRule.valueTypeRule) {
                switch (expressionRule.valueTypeRule) {
                    case ValueTypeRule.DisplayValue:
                        actualValue = wrapper.getDisplayValue(rowNode, expressionRuleField);
                        break;
                    case ValueTypeRule.RawValue:
                        actualValue = wrapper.getRawValue(rowNode, expressionRuleField);
                        break;
                    default: {
                        const msg = `Expression DisplayValue ${expressionRule.valueTypeRule} is not recognized`;
                        logger.error(msg, expressionRule);
                        throw new Error(msg);
                    }
                }
            }

            return this.evaluateExpressionRule(
                actualValue,
                expressionQuery as ExpressionRule,
                wrapper,
                rowNode,
                quickFilterList,
                filterIfNoColumn
            );
        }
        const groupRuleQuery = expressionQuery as ExpressionGroup;
        let returnResult: boolean;
        switch (groupRuleQuery.condition) {
            case ExpressionCondition.And:
                returnResult = true;
                break;
            case ExpressionCondition.Or:
                returnResult = false;
                break;
            default: {
                const msg = `Expression Condition ${groupRuleQuery.condition} is not recognized`;
                logger.error(msg, groupRuleQuery);
                throw new Error(msg);
            }
        }
        groupRuleQuery.rules.forEach(rule => {
            switch (groupRuleQuery.condition) {
                case ExpressionCondition.And:
                    returnResult =
                        returnResult &&
                        this.evaluate(rowNode, rule, wrapper, quickFilterList, filterIfNoColumn);
                    break;
                case ExpressionCondition.Or:
                    returnResult =
                        returnResult ||
                        this.evaluate(rowNode, rule, wrapper, quickFilterList, filterIfNoColumn);
                    break;
            }
        });
        return groupRuleQuery.negate ? !returnResult : returnResult;
    }

    private evaluateExpressionRule(
        actualValue: any,
        expressionRule: ExpressionRule,
        gridWrapper: ExpressionComponentWrapper,
        rowNode: RowData,
        quickFilters: QuickFilter[],
        filterIfNoColumn: boolean
    ): boolean {
        if (gridWrapper.doesColumnExist && !gridWrapper.doesColumnExist(expressionRule.field)) {
            return filterIfNoColumn ? false : true;
        }
        if (!filterIfNoColumn && !Object.keys(rowNode).includes(expressionRule.field)) {
            return true;
        }
        const actualValueType = typeof actualValue;
        let filterValue: any = expressionRule.value;

        if (actualValue instanceof Date) {
            filterValue = new Date(filterValue);
        }

        let returnResult: boolean;
        switch (expressionRule.operator) {
            case ExpressionOperator.Equals:
            case ExpressionOperator.NotEquals:
                if (FILTERABLE_SPECIAL_VALUES.includes(filterValue)) {
                    // Special values such as undefined are represented as strings
                    // (e.g. "[undefined]") rather than raw values.
                    returnResult = actualValue === filterValue;
                } else if (actualValueType === 'string') {
                    returnResult =
                        String(actualValue).toLowerCase() === String(filterValue).toLowerCase();
                } else if (actualValueType === 'number') {
                    returnResult = actualValue === parseFloat(filterValue);
                } else if (
                    actualValue instanceof Date &&
                    filterValue instanceof Date &&
                    filterValue.getUTCHours() === 0 &&
                    filterValue.getUTCMinutes() === 0 &&
                    filterValue.getUTCSeconds() === 0 &&
                    filterValue.getUTCMilliseconds() === 0
                ) {
                    // Compare date only
                    returnResult =
                        actualValue.getUTCFullYear() === filterValue.getUTCFullYear() &&
                        actualValue.getUTCMonth() === filterValue.getUTCMonth() &&
                        actualValue.getUTCDate() === filterValue.getUTCDate();
                } else if (actualValue instanceof Date && filterValue instanceof Date) {
                    // Compare date & time
                    returnResult = actualValue.getTime() === filterValue.getTime();
                } else {
                    returnResult = actualValue === filterValue;
                }

                if (expressionRule.operator === ExpressionOperator.NotEquals) {
                    returnResult = !returnResult;
                }
                break;
            case ExpressionOperator.GreaterThan:
                returnResult = actualValue > filterValue;
                break;
            case ExpressionOperator.GreaterThanOrEqual:
                returnResult = actualValue >= filterValue;
                break;
            case ExpressionOperator.LessThan:
                returnResult = actualValue < filterValue;
                break;
            case ExpressionOperator.LessThanOrEqual:
                returnResult = actualValue <= filterValue;
                break;
            case ExpressionOperator.Contains:
                returnResult = String(actualValue).indexOf(String(filterValue)) > -1;
                break;
            case ExpressionOperator.EndsWith:
                returnResult = String(actualValue).endsWith(String(filterValue));
                break;
            case ExpressionOperator.StartsWith:
                returnResult = String(actualValue).startsWith(String(filterValue));
                break;
            case ExpressionOperator.ContainsCaseInsensitive:
                returnResult =
                    String(actualValue).toLowerCase().indexOf(String(filterValue).toLowerCase()) >
                    -1;
                break;
            case ExpressionOperator.NotContains:
                returnResult = String(actualValue).indexOf(String(filterValue)) === -1;
                break;
            case ExpressionOperator.NotContainsCaseInsensitive:
                returnResult =
                    String(actualValue).toLowerCase().indexOf(String(filterValue).toLowerCase()) ===
                    -1;
                break;
            case ExpressionOperator.QuickFilter: {
                const foundQuickFilter = quickFilters.find(quickFilter => {
                    return quickFilter.name === expressionRule.value;
                });
                if (foundQuickFilter) {
                    returnResult = this.evaluate(
                        rowNode,
                        foundQuickFilter.expression?.query ?? invariantQuery,
                        gridWrapper,
                        quickFilters
                    );
                } else {
                    const msgQuickFilter = `Quick Filter ${expressionRule.value} is not recognized, ignoring`;
                    logger.warn(msgQuickFilter, expressionRule);
                    returnResult = true;
                }
                break;
            }
            default: {
                const msg = `Expression Operator ${expressionRule.operator} is not recognized`;
                logger.error(msg, expressionRule);
                throw new Error(msg);
            }
        }
        return expressionRule.negate ? !returnResult : returnResult;
    }
}
export const expressionEvaluator = new ExpressionEvaluator();
