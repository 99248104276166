import { startsWith } from '@gs-ux-uitoolkit-common/shared';

/**
 * Extracts and returns props whose keys begin with a specific prefix. Used to
 * extract data-* and aria-* props from a larger object of React props. Note
 * that it is probably more convenient to use extractDataAttributes() or
 * extractAriaAttributes().
 * @param props The props of the given component.
 * @param prefix The prefix to filter on.
 */
export function extractAttributesWithPrefix(props: AnyProps, prefix: string): AnyProps {
    const attrs: { [key: string]: string } = {};
    for (const key of Object.keys(props)) {
        if (startsWith(key, prefix)) {
            attrs[key] = props[key];
        }
    }
    return attrs;
}

/**
 * Extracts and returns props whose keys start with "data-*" from a larger
 * object of React props.
 *
 * A common pattern is to add "data-cy" attribute to components.
 * This comes in handy when writing e2e test since you can target the
 * component by that attribute. Usually these attributes will never change.
 *
 * @param props The props of the given component.
 */
export function extractDataAttributes(props: AnyProps): AnyProps {
    return extractAttributesWithPrefix(props, 'data-');
}

/**
 * Extracts and returns props whose keys start with "aria-*" from a larger
 * object of React props.
 * @param props The props of the given component.
 */
export function extractAriaAttributes(props: AnyProps): AnyProps {
    return extractAttributesWithPrefix(props, 'aria-');
}

/**
 * Splits the props whose keys begin with a specific prefix from the remaining props.
 *
 * Used to separate data-* and aria-* props from a larger object of React props, where you need the 2 groups divided.
 *
 * Note that it is probably more convenient to use splitDataAttributes() or splitAriaAttributes().
 * @param props The props of the given component.
 * @param prefix The prefix to split on.
 */
export function splitAttributesWithPrefix(props: AnyProps, prefix: string): SplitProps {
    const split: { [key: string]: string } = {};
    const remaining: { [key: string]: string } = {};
    for (const key of Object.keys(props)) {
        if (startsWith(key, prefix)) {
            split[key] = props[key];
        } else {
            remaining[key] = props[key];
        }
    }
    return {
        split,
        remaining,
    };
}

/**
 * Splits props whose keys start with "data-*" from a larger object of React props.
 *
 * A common pattern is to add "data-cy" attribute to components.
 * This comes in handy when writing e2e test since you can target the
 * component by that attribute. Usually these attributes will never change.
 *
 * @param props The props of the given component.
 */
export function splitDataAttributes(props: AnyProps): SplitProps {
    return splitAttributesWithPrefix(props, 'data-');
}

/**
 * Splits props whose keys start with "aria-*" from a larger object of React props.
 *
 * @param props The props of the given component.
 */
export function splitAriaAttributes(props: AnyProps): SplitProps {
    return splitAttributesWithPrefix(props, 'aria-');
}

export type AnyProps = { [key: string]: any };

export interface SplitProps {
    split: AnyProps;
    remaining: AnyProps;
}
