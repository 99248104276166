import { ComponentProps, CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { LoadingOverlayCssClasses } from './loading-overlay-style-sheet';

export interface LoadingOverlayProps<StyleType = CommonStyleType>
    extends ComponentProps<StyleType> {
    /**
     * Style classes to override.
     */
    classes?: Partial<LoadingOverlayCssClasses>;

    /**
     * Whether or not to show the loading overlay.
     */
    visible?: boolean;

    /**
     * Whether or not the loading overlay should occupy the full screen.
     */
    fullScreen?: boolean;
}

export type LoadingOverlayDefaultProps = Required<
    Pick<LoadingOverlayProps, 'visible' | 'fullScreen'>
>;

export const loadingOverlayDefaultProps: LoadingOverlayDefaultProps = {
    visible: false,
    fullScreen: false,
};
