import { Component, Context, ContextType, isValidElement } from 'react';
import { createRoot } from 'react-dom/client';
import { renderToString } from 'react-dom/server';

import { FormContext, FormProps } from '@gs-ux-uitoolkit-react/form';

import {
    CommonSelectMultipleComponent,
    CommonSelectMultipleProps,
    createOptionRendererData,
    OptionRendererData,
    SelectStatus,
    selectMultipleDefaultProps,
    SelectOptionRenderer,
} from '@gs-ux-uitoolkit-common/select';
import { extractDataAttributes } from '@gs-ux-uitoolkit-react/shared';

import { SelectMultipleProps } from './select-multiple-props';
import { Theme, ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';
import { componentAnalytics } from './analytics-tracking';

/**
 * The SelectMultiple component is used to select multiple options from a list of options.
 * If you need to select a single option please use the [Select](#/Components/Select) component.
 *
 * **Terminology**
 * - **option:** is defined as a single option inside the SelectMultiple's menu.
 * - **selectedOption:** is defined as a single option which is currently active in the input box.
 *
 * <small>Note: SelectMultiple is currently powered by the excellent [Choices.js](https://github.com/jshjohnson/Choices)
 * library. However, please do not rely on this fact because the underlying implementation may be
 * changed at a future date as new business requirements and feature requests come forward.
 * The UI Toolkit SelectMultiple API will remain the same, however, regardless of the underlying
 * implementation.</small><br><br>
 */
export class SelectMultiple extends Component<SelectMultipleProps> {
    public static defaultProps: Partial<SelectMultipleProps> = {
        disabled: false,
        filterOptionsOnSearch: selectMultipleDefaultProps.filterOptionsOnSearch,
        maxSelectedOptions: selectMultipleDefaultProps.maxSelectedOptions,
        maxSelectionsContent: selectMultipleDefaultProps.maxSelectionsContent,
        noOptionsContent: selectMultipleDefaultProps.noOptionsContent,
        noResultsContent: selectMultipleDefaultProps.noResultsContent,
        optionRenderer: null,
        clearable: selectMultipleDefaultProps.clearable,
        options: [],
        pasteable: selectMultipleDefaultProps.pasteable,
        removeButtonsVisible: selectMultipleDefaultProps.removeButtonsVisible,
        menuPlacement: selectMultipleDefaultProps.menuPlacement,
        renderSelectedOptions: selectMultipleDefaultProps.renderSelectedOptions,
        resetScrollPosition: selectMultipleDefaultProps.resetScrollPosition,
        selectedOptionRenderer: null,
        size: selectMultipleDefaultProps.size,
        sortSelectedOptions: selectMultipleDefaultProps.sortSelectedOptions,
        inline: selectMultipleDefaultProps.inline,
        enableRenderCache: selectMultipleDefaultProps.enableRenderCache,
        enableRenderToString: false, // not using selectMultipleDefaultProps, because enableRenderToString is React-specific
    };

    private selectContainerEl: HTMLDivElement | undefined;
    private commonSelectMultipleComponent!: CommonSelectMultipleComponent;
    private status: SelectStatus | undefined;
    private theme: Theme | undefined;

    static contextType: Context<FormProps> = FormContext;

    declare context: ContextType<typeof FormContext>;

    /**
     * Cache of optionRenderers -> React-aware optionRenderers.
     *
     * This is a WeakMap keyed by the original optionRenderer function, and the
     * values are the renderer function that converts a JSX return value to an
     * HTMLElement.
     *
     * This map is needed to keep the option renderer function references stable
     * for the cache inside the common select component. (It also helps to just
     * not create new option renderer functions each time the Select component
     * is updated.)
     */
    private reactAwareOptionRenderers = new WeakMap<
        NonNullable<SelectMultipleProps['optionRenderer']>,
        SelectOptionRenderer
    >();

    public getContextStatus() {
        return this.context.status === 'information' || this.context.status === undefined
            ? 'none'
            : this.context.status;
    }

    public componentDidMount() {
        this.status = this.props.status || this.getContextStatus();
        const initialProps = { ...this.props, status: this.status };

        this.commonSelectMultipleComponent = new CommonSelectMultipleComponent(
            this.selectContainerEl!,
            this.toCommonSelectProps(initialProps)
        );
        if (this.theme) {
            this.commonSelectMultipleComponent.setTheme(this.theme);
        }
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'select' });
    }

    public componentDidUpdate(prevProps: SelectMultipleProps) {
        const currentProps = {
            ...this.toCommonSelectProps(this.props),
            className: this.props.className!,
        };
        const previousProps = {
            ...this.toCommonSelectProps(prevProps),
            className: prevProps.className!,
        };

        const contextStatus = this.getContextStatus();
        let newStatus;

        // status from props should always take precedence
        // update the internal state so formContext can still track if needed after updates
        if (this.props.status && this.props.status !== this.status) {
            // if formContext was recently undefined, then prevProps will not accurately reflect the previous status, so rely on internal state
            previousProps.status = this.status;
            newStatus = this.props.status;

            // else if there is no status set on the Select directly and contextStatus is updated, update the status
        } else if (!this.props.status && this.status !== contextStatus) {
            previousProps.status = this.status;
            newStatus = contextStatus;
        }

        // If status was updated, update the local state reference
        if (newStatus) {
            this.status = newStatus;
        }

        currentProps.status = this.status;
        this.commonSelectMultipleComponent.setConfig(currentProps, previousProps);
        this.commonSelectMultipleComponent.setTheme(this.theme!);
    }

    public componentWillUnmount() {
        if (this.commonSelectMultipleComponent) {
            this.commonSelectMultipleComponent.destroy();
        }
    }

    /**
     * Function used to focus the select component.
     * This will focus the input element and show the menu.
     * @public
     */
    public focus() {
        this.commonSelectMultipleComponent.focus();
    }

    /**
     * Function used to blur the select component.
     * This will blur the input element and hide the menu.
     * @public
     */
    public blur() {
        this.commonSelectMultipleComponent.blur();
    }

    public render() {
        const { className, size, style, name, form, inputId } = this.props;
        const dataAttrs = extractDataAttributes(this.props);

        return (
            <ThemeConsumer>
                {(theme: Theme) => {
                    this.theme = theme;
                    if (this.commonSelectMultipleComponent) {
                        this.commonSelectMultipleComponent.setTheme(this.theme);
                    }
                    return (
                        <div
                            {...dataAttrs}
                            className={className}
                            data-gs-uitk-component="select-multiple"
                            data-size={size}
                            style={style}
                            ref={this.setSelectOuterEl}
                        >
                            <select
                                multiple
                                data-cy="gs-uitk-select-multiple__inner-select"
                                name={name}
                                form={form}
                                id={inputId}
                            />
                        </div>
                    );
                }}
            </ThemeConsumer>
        );
    }

    private setSelectOuterEl = (selectOuterEl: HTMLDivElement) => {
        this.selectContainerEl = selectOuterEl;
    };

    /**
     * Function used when we need to transform any of the React-specific
     * component props to the common Select props for consumption by the common
     * implementation.
     *
     * @param props The components props to transform.
     */
    private toCommonSelectProps(props: SelectMultipleProps): CommonSelectMultipleProps {
        const {
            optionRenderer: userOptionRenderer,
            selectedOptionRenderer: userSelectedOptionRenderer,
        } = props;
        let newOptionRenderer: CommonSelectMultipleProps['optionRenderer'] = undefined;
        let newSelectedOptionRenderer: CommonSelectMultipleProps['selectedOptionRenderer'] =
            undefined;

        if (userOptionRenderer) {
            newOptionRenderer = this.reactAwareOptionRenderers.get(userOptionRenderer);

            if (!newOptionRenderer) {
                newOptionRenderer = this.createReactAwareOptionRenderer(userOptionRenderer);
                this.reactAwareOptionRenderers.set(userOptionRenderer, newOptionRenderer);
            }
        }

        if (userSelectedOptionRenderer) {
            newSelectedOptionRenderer = this.reactAwareOptionRenderers.get(
                userSelectedOptionRenderer
            );

            if (!newSelectedOptionRenderer) {
                newSelectedOptionRenderer = this.createReactAwareOptionRenderer(
                    userSelectedOptionRenderer
                );
                this.reactAwareOptionRenderers.set(
                    userSelectedOptionRenderer,
                    newSelectedOptionRenderer
                );
            }
        }

        return {
            ...props,
            optionRenderer: newOptionRenderer,
            selectedOptionRenderer: newSelectedOptionRenderer,
        };
    }

    /**
     * Creates a React-aware optionRenderer wrapper which allows users to return JSX
     * from their `optionRenderer` and `selectedOptionRender` and we translate it
     * to an HTMLElement for the common Select implementation.
     */
    private createReactAwareOptionRenderer(
        rendererFn: NonNullable<SelectMultipleProps['optionRenderer']>
    ): SelectOptionRenderer {
        return (dataAttributes: OptionRendererData) => {
            const result = rendererFn(createOptionRendererData(dataAttributes));

            // If the developer provides us a ReactElement, render it using react-dom.
            if (isValidElement(result)) {
                if (this.props.enableRenderToString) {
                    const renderedHtml = renderToString(result);
                    return renderedHtml;
                } else {
                    const div = document.createElement('div');
                    const root = createRoot(div);
                    root.render(result);
                    return div;
                }
            } else {
                // Let the common implementation of Select handle it.
                return result;
            }
        };
    }
}
