import React from 'react';
import PropTypes from 'prop-types';
import './download.scss';

const DownloadButton = ({disabled, active, onClick}) => {
  const className = `transparent-button icon-download ${active ? 'active' : ''}${disabled ? 'disabled' : ''}`;
  return (
    <button className={className} onClick={() => !disabled && onClick(!active)} aria-label='download grid data button'/>
  );
};

DownloadButton.defaultProps = {
  onClick: () => false
};

DownloadButton.propTypes = {
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func
};

export default DownloadButton;
