import { ColumnHint, ColumnHintDefaults, DataType } from '@gs-ux-uitoolkit-common/datacore';
import * as Redux from 'redux';
import { GridWrapper } from '../grid-wrappers/grid-wrapper';
import { columnHintHelper } from '../plugins/column-hint/column-hint-helper';
import {
    COLUMN_HINT_PREFIX,
    DEFAULT_COLUMN_HINT_PREFIX,
    formatGroupedColumn,
} from '../plugins/column-hint/column-hint-plugin';
import { RowCustomisation } from '../plugins/row-customisation/row-customisation';
import { CSSProperties } from 'react';

/**
 * StyleService that handles putting stlyes onto the DOM
 */
export class StyleService {
    private styleContainer: HTMLStyleElement;
    private currentHighlights: RowCustomisation[] = [];
    private currentColumnHints: ColumnHint[] = [];
    private currentColumnHintDefaults: ColumnHintDefaults | undefined;

    private storeSubscription: Redux.Unsubscribe | null = null;

    constructor(private gridWrapper: GridWrapper) {
        const id = `${gridWrapper.getId()}-style`;
        this.styleContainer = document.createElement('style');

        const head = document.head;
        if (head && !document.getElementById(id)) {
            head.appendChild(this.styleContainer);
            this.styleContainer.setAttribute('id', id);
        } else if (head) {
            this.styleContainer = document.getElementById(id) as HTMLStyleElement;
        }
    }

    public start() {
        this.updateStyles();
        this.storeSubscription = this.gridWrapper
            .getReduxStore()
            .subscribe(() => this.updateStyles());
    }
    public stop() {
        if (this.storeSubscription) {
            this.storeSubscription();
        }
    }

    private updateStyles() {
        const newHighlights = this.gridWrapper.getReduxStore().getState()
            .rowCustomisation.configItemList;
        const newColumnHints = this.gridWrapper.getReduxStore().getState()
            .columnHint.configItemList;
        const newColumnHintDefaults = this.gridWrapper.getReduxStore().getState()
            .columnHint.defaults;

        if (
            this.currentHighlights !== newHighlights ||
            this.currentColumnHints !== newColumnHints ||
            this.currentColumnHintDefaults !== newColumnHintDefaults
        ) {
            this.currentHighlights = newHighlights;
            this.currentColumnHints = newColumnHints;
            this.currentColumnHintDefaults = newColumnHintDefaults;

            // Clear the style
            this.styleContainer.textContent = '';
            let localStyleClasses: StyleClasses[] = this.getRowCustomisationStyles(newHighlights);

            const columnHintDefaults = this.gridWrapper.getReduxStore().getState()
                .columnHint.defaults;

            if (columnHintDefaults) {
                localStyleClasses = localStyleClasses.concat(
                    this.getColumnHintDefaultStyle(columnHintDefaults)
                );

                if (columnHintDefaults.numberDefaults) {
                    localStyleClasses = localStyleClasses.concat(
                        this.getColumnHintDefaultStyle(
                            columnHintDefaults.numberDefaults,
                            DataType.Number
                        )
                    );
                }

                if (columnHintDefaults.stringDefaults) {
                    localStyleClasses = localStyleClasses.concat(
                        this.getColumnHintDefaultStyle(
                            columnHintDefaults.stringDefaults,
                            DataType.String
                        )
                    );
                }

                if (columnHintDefaults.dateDefaults) {
                    localStyleClasses = localStyleClasses.concat(
                        this.getColumnHintDefaultStyle(
                            columnHintDefaults.dateDefaults,
                            DataType.Date
                        )
                    );
                }

                if (columnHintDefaults.dateTimeDefaults) {
                    localStyleClasses = localStyleClasses.concat(
                        this.getColumnHintDefaultStyle(
                            columnHintDefaults.dateTimeDefaults,
                            DataType.DateTime
                        )
                    );
                }
            }
            localStyleClasses = localStyleClasses.concat(this.getColumnHintStyles(newColumnHints));

            localStyleClasses.forEach(classStyle => {
                this.styleContainer.textContent += `${this.convertClassStyleToString(
                    classStyle
                )}\n`;
            });
        }
    }

    private convertClassStyleToString(classStyle: StyleClasses) {
        // https://stackoverflow.com/a/45205645
        const styles: string = Object.entries(classStyle.styles).reduce(
            (styleString, [propName, propValue]) => {
                const propRename = propName.replace(
                    /([A-Z])/g,
                    matches => `-${matches[0].toLowerCase()}`
                );
                return `${styleString}${propRename}:${propValue};`;
            },
            ''
        );

        return `.${classStyle.className} {${styles}}`;
    }

    private getRowCustomisationStyles(highlights: RowCustomisation[]) {
        let classStyles: StyleClasses[] = [];

        /**
         * Column highlights need to be applied after row highlights so that they take precedence
         */
        const rowHighlights = highlights.filter(highlight => highlight.columnId === null);
        const columnHighlights = highlights.filter(highlight => highlight.columnId !== null);
        classStyles = classStyles.concat(this.getHighlightClassStyles(rowHighlights, highlights));
        classStyles = classStyles.concat(
            this.getHighlightClassStyles(columnHighlights, highlights)
        );
        return classStyles;
    }

    private getHighlightClassStyles(
        highlights: RowCustomisation[],
        allHighlights: RowCustomisation[]
    ) {
        const classStyles: StyleClasses[] = [];
        highlights.forEach(highlight => {
            const className = `row-customisation-${this.gridWrapper.getId()}-${allHighlights.findIndex(
                myHighlight => highlight === myHighlight
            )}`;
            const styles = {
                backgroundColor: `${highlight.style.backColor} !important`,
                color: `${highlight.style.foreColor} !important`,
                fontSize: `${highlight.style.fontStyle.fontSize} !important`,
                fontStyle: `${highlight.style.fontStyle.italic ? 'italic' : 'normal'} !important`,
                fontWeight: `${
                    highlight.style.fontStyle.bold ? 'bold' : 'normal'
                } !important` as FontWeight,
                textDecoration: `${
                    highlight.style.fontStyle.underline ? 'underline' : 'initial'
                } !important`,
            };

            classStyles.push({ className, styles });
        });
        return classStyles;
    }

    private getColumnHintStyles(columnHints: ColumnHint[]) {
        let classStyles: StyleClasses[] = [];

        classStyles = classStyles.concat(this.getColumnHintClassStyles(columnHints));
        return classStyles;
    }

    private getColumnHintClassStyles(columnHints: ColumnHint[]) {
        const classStyles: StyleClasses[] = [];
        columnHints.forEach((columnHint, i) => {
            const className = `${COLUMN_HINT_PREFIX}-${this.gridWrapper.getId()}-${i}`;

            const styles = columnHintHelper.getStyles(columnHint.hints);
            if (Object.getOwnPropertyNames(styles).length > 0) {
                classStyles.push({ className, styles });
            }
            if (columnHint.hints.bgHeaderGroups && columnHint.hints.groupingDelimiter) {
                const groupsColors = columnHint.hints.bgHeaderGroups.split(
                    columnHint.hints.groupingDelimiter
                );
                const groupSplit = columnHint.columnId.split(columnHint.hints.groupingDelimiter);
                for (let index = 0; index < groupsColors.length; index = index + 1) {
                    const color = groupsColors[index];
                    const groupId = groupSplit
                        .slice(0, index + 1)
                        .join(columnHint.hints.groupingDelimiter);
                    const groupClassName = `${COLUMN_HINT_PREFIX}-${this.gridWrapper.getId()}-group-${formatGroupedColumn(
                        groupId
                    )}`;
                    classStyles.push({ className: groupClassName, styles: { background: color } });
                }
            }
        });
        return classStyles;
    }

    private getColumnHintDefaultStyle(
        defaultColumnHints: ColumnHintDefaults,
        defaultType?: DataType
    ) {
        const classStyles: StyleClasses[] = [];
        const className = `${DEFAULT_COLUMN_HINT_PREFIX}-${
            defaultType ? defaultType + '-' : ''
        }${this.gridWrapper.getId()}`;

        const styles = columnHintHelper.getStyles(defaultColumnHints);
        if (Object.getOwnPropertyNames(styles).length > 0) {
            classStyles.push({ className, styles });
        }

        return classStyles;
    }
}

export type FontWeight =
    | number
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'bold'
    | 'normal'
    | 'bolder'
    | 'lighter'
    | undefined;

export interface StyleClasses {
    className: string;
    styles: CSSProperties;
}
