import { Reducer } from 'redux';
import { LOAD } from './constants';

export interface StateMerger {
    (oldState: any, newState: any): any;
}

export function reducer<TState>(reducer: Reducer<TState>, merger: StateMerger): Reducer<TState> {
    return (state, action) =>
        reducer(action.type === LOAD ? merger(state, action.payload) : state, action);
}
