import find from 'lodash/find';
import {createSelector} from 'reselect/lib/index';
import extend from 'lodash/extend';
import translator  from '../../../../services/translator';
import {labelSelector} from '../../../app';
import { setTradeCutoffFlag, sortTradeApprovalFlatData } from '../../../../containers/TradeActivity/TradeApprovals/helper';
import { getUserTimeZone } from '../../../../selectors/user';
import Constants from '../../../../constants/appConstants';
import {SessionStorage} from '../../../../utils/storage';
import {SHOW_ERROR_ON, STORE} from '../../../../constants/pageConstants';
import errorTypes, {errorMessages, pageErrorMessage} from '../../../../error/errorType';
const defaultErrorObject = {};
const tradeApprovalResponse = {};
const tradeRejectionResponse = {};
const {translate: t} = translator;
let lastUsedId;
// trade approval
export const tradeApprovalData = state => (state.pageContext.tradeApprovalsData ? state.pageContext.tradeApprovalsData.trades : []);
export const tradeApprovalDataLoadTimeStamp = state => (state.pageContext.tradeApprovalsData ? state.pageContext.tradeApprovalsData.timeStamp : undefined);
export const tradeApprovalError = state => (state.pageContext.tradeApprovalsData ? state.pageContext.tradeApprovalsData.error : {});
export const currentTxnId = state => (state.pageContext.currentTxnId);
export const isRejectionInProgress = state => (state.pageContext.isRejectionInProgress);
export const isApprovalInProgress = state => (state.pageContext.isApprovalInProgress);
export const isTradeApprovalDataLoading = state => state.pageContext.isTradeApprovalDataLoading || false;
export const isTradeApprovalInitialDataLoading = state => state.pageContext.isTradeApprovalInitialDataLoading || false;
export const getTradeApprovalResponse = state => state.pageContext.tradeApprovalResponse || tradeApprovalResponse;
export const getTradeRejectionResponse = state => state.pageContext.tradeRejectionResponse || tradeRejectionResponse;
export const reasonsList = (state) => state.pageData.tradeRejectionReasons && state.pageData.tradeRejectionReasons.rejectionReasons || [];
export const checkIsPageInErrorMode = state => state.pageContext.isPageInErrorMode;
export const getErrorObject = state => state.pageContext.errorObject || defaultErrorObject;
export const getCurrentApprovalRowData = state => state.pageContext.currentApprovalRowData;
export const isTradeRejectionSuccessful = state => state.pageContext.rejectionModalState &&
  state.pageContext.rejectionModalState === 'success';
export const displaySettingsPrefConfigSelector = state => state.appData && state.appData.displaySettingsConfig || [];
export const isPendingApprovalFlagDataLoading = state => state.appContext && state.appContext.isPendingApprovalFlagDataLoading || false;
export const accountIdentifierPreference = state => state.preferences && state.preferences.global && state.preferences.global.accountIdentifier || '';
export const fundIdentifierPreference = state => state.preferences && state.preferences.global && state.preferences.global.fundIdentifier || '';


export const getLastDisabledTradeId = (state) => state.pageContext.lastDisabledTradeId;

const getErrorItem = () => {
  const storeString = SessionStorage.getItem(STORE.TRADE_APPROVALS_ERROR_MESSAGES_STORE);
  return storeString &&  JSON.parse(storeString) || [];
};

const setErrorItem = (errorObject) => {
  const store =  getErrorItem();
  store.push(errorObject);
  SessionStorage.setItem(STORE.TRADE_APPROVALS_ERROR_MESSAGES_STORE, JSON.stringify(store));
};

// to handle snackbar error
export const getExceptionObject = createSelector(
  labelSelector, getErrorObject, (labels, errorObject) => {
    const exception = {};
    if(errorObject.showErrorOn === SHOW_ERROR_ON.TRADE_REJECTION_MODAL && (errorObject.errorCode === errorTypes.UNKNOWN_SERVICE_ERROR)){
      const message = errorObject.errorMessage && labels[errorObject.errorMessage] ||  labels[errorMessages[errorObject.errorCode].tkBody];
      exception.message = message;
    }
    return exception;
  }
);

export const getCurrentRowData = createSelector(
  getTradeApprovalResponse,
  getTradeRejectionResponse,
  isTradeRejectionSuccessful,
  getLastDisabledTradeId,
  (approvalResponse, rejectionResponse, isSuccess, disabledTradeId) => {
    const approvalResLen = Object.keys(approvalResponse).length;
    const rejectResLen = Object.keys(rejectionResponse).length;

    let warningObject = {};
    let animationClass = '';

    const getWarning = (response, type) => {
      const {transactionId, error, warning} = response;
      animationClass = type === 'APPROVE' ? 'animation-slide-out-right-remove' : (rejectResLen && isSuccess ? 'animation-slide-out-left-remove' : ''); // : (rejectResLen ? 'animation-slide-out-left-remove' : '');

      if((type === 'APPROVE' && error) || (type === 'REJECT' && disabledTradeId === transactionId && transactionId)) {
        setErrorItem({transactionId, disabled: true, responseError: error});
      }

      warningObject = type === 'APPROVE' ?  {approvalResponseWarning: warning} : {rejectionResponseWarning: warning};
      warningObject.transactionId = transactionId;

      return  (error ? {transactionId, responseError: error} :
        (warning ?  warningObject:
          {transactionId, animationClass } ));
    };

    const responseType = ( (approvalResLen === 1) || (rejectResLen === 1) ) ?
      (approvalResLen === 1 ? 'APPROVE' : 'REJECT') :
      (approvalResLen > rejectResLen ? 'APPROVE' : 'REJECT');

    return getWarning(responseType === 'REJECT' ? rejectionResponse :  approvalResponse,  responseType);
  }
);

export const getCurrentRowWarningData = createSelector(
  getTradeApprovalResponse,
  (approvalResponse) => {
    const {transactionId, warning} = approvalResponse;
    return warning ?  {transactionId, warning} : null;
  }
);


export const isApprovalSuccessful = createSelector(getTradeApprovalResponse, (approvalResponse) => {
  const {transactionId, error, warning} = approvalResponse;
  return transactionId && (!error && !warning);
});

export const getRejectionErrorOrWarningData = createSelector(
  getTradeRejectionResponse,
  getExceptionObject,
  (rejectionResponse, exception) => {
    const {transactionId, warning, error} = rejectionResponse;
    if(error){
      return {transactionId, error};
    } else if(Object.keys(exception).length > 0){
      return {transactionId, exception};
    } else if(warning){
      return {transactionId, warning};
    } else if(transactionId){
      return {transactionId, success : true};
    } else{
      return null;
    }
  }
);

export const animationAddedTradeApprovalData = createSelector(
  tradeApprovalData,
  getCurrentRowData, (trades, rowData) => {
    if(rowData && trades && trades.length > 0){
      const items = getErrorItem();
      const {transactionId} = rowData;
      const tradesCopy = trades.map((trade) => {
        const errObj = find(items, { 'transactionId': trade.id});
        if(trade.id === transactionId) {
          trade = extend({}, trade, rowData);
        }
        return extend({}, trade, errObj);
      });
      return tradesCopy;
    }
    return trades;
  }
);

export const preferenceIdentifier = createSelector(
  accountIdentifierPreference, fundIdentifierPreference, displaySettingsPrefConfigSelector,
  (accountPref, fundPref, displaySettingsConfig) => {
    const accountPrefConfig = displaySettingsConfig.find(config => config.valueKey === Constants.ACCOUNT_IDENTIFIER) || {list: []};
    const accountIdentifierConfig = accountPrefConfig.list.find(data => data.value === accountPref) ||  {tradeApprovalKey: ''};
    const accountPrefIdentifier = accountIdentifierConfig.tradeApprovalKey || '';

    const fundPrefConfig = displaySettingsConfig.find(config => config.valueKey === Constants.FUND_IDENTIFIER) || {list: []};
    const fundIdentifierConfig = fundPrefConfig.list.find(data => data.value === fundPref) ||  {tradeApprovalKey: ''};
    const fundPrefIdentifier = fundIdentifierConfig.tradeApprovalKey || '';

    return {accountPrefIdentifier, fundPrefIdentifier};
  }
);

export const tradeApprovalTransactionData = createSelector(
  animationAddedTradeApprovalData, getUserTimeZone, preferenceIdentifier,
  (data, timeZone, preferences) => {
    if(data && data.length) {
      const sortedData = sortTradeApprovalFlatData(data);
      const { accountPrefIdentifier, fundPrefIdentifier } = preferences;
      const flatData = [];
      sortedData.forEach(group => {
        const { transactions = [] } = group;
        const groupCount = transactions.length;
        transactions.forEach((txn, i) => {
          const { account = {}, taId, cusip, isin, ticker = ''} = txn;

          /* Account Identifier: Preference > name > bin > taAccount */
          const accountIdentifier = account[accountPrefIdentifier] || account[Constants.TRADE_APPROVAL_ACCOUNT_NAME] ||
            account[Constants.TRADE_APPROVAL_ACCOUNT_BIN] || account[Constants.TRADE_APPROVAL_ACCOUNT_TA_ACCOUNT] || '';

          /* Fund Identifier: preference !== fundName && Preference >  (taId === 1 && cusip) || (taId === 2 && isin) > ticker */
          const fundIdentifier = ( fundPrefIdentifier !== Constants.TRADE_APPROVAL_FUND_NAME && txn[fundPrefIdentifier] ) ||
            (taId === 1 && cusip) || (taId === 2 && isin) || ticker;

          flatData.push({...txn, showGroup : i === 0, accountIdentifier, fundIdentifier, groupCount});
        });
      });

      // Set the 'Passed Cut Off Flag'
      return setTradeCutoffFlag(flatData, timeZone);
    }
    return [];
  }
);

export const showWarningModal = createSelector(
  getCurrentRowData,
  (rowData) => {
    return rowData && rowData.warning;
  }
);

export const selectCurrentRowData = createSelector(
  tradeApprovalTransactionData, currentTxnId,
  (trades = [], currentTxnId) => {
    return currentTxnId ? find(trades, { 'id': currentTxnId}) : undefined;
  }
);


export const isTradeRejectionModalOpen = state => state.pageContext.rejectionModalState &&
  state.pageContext.rejectionModalState === 'open';

export const isTradeApprovalModalOpen = state => state.pageContext.approvalModalState &&
  state.pageContext.approvalModalState === 'open';

export const getReasonsList= createSelector(
  reasonsList,
  labelSelector,
  (reasons) => {
    const reasonsType = reasons && reasons.map(item => {
      return {value: item.value, text: t(item.label), label: t(item.label).toUpperCase()};
    });
    return reasonsType && reasonsType.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
  }
);

export const hasRowWarning = createSelector(tradeApprovalData, (approvalData) => (
  approvalData.some((item) => item.warnings &&  item.warnings.length > 0)
));

export const approvalSnackbarDataList = createSelector(
  labelSelector, getTradeApprovalResponse, getTradeRejectionResponse, tradeApprovalError,
  isTradeRejectionSuccessful, isApprovalSuccessful, hasRowWarning, tradeApprovalDataLoadTimeStamp, (labels, approvalResponse,
    rejectionResponse, tradeApprovalError, isRejectionSuccessful, isApprovalSuccessful, hasRowWarning, loadWarningTimeStamp) => {
    const list = [];
    const {error, warning, timeStamp, transactionId} = approvalResponse;
    const id = `${transactionId}-${timeStamp}`;
    const rejectionId = `${rejectionResponse.transactionId}-${timeStamp}`;
    const error1 = tradeApprovalError;

    const config = {displayMultiple:true, type:'warning', msgCopy: labels.tkTradeApprovalWarningMessage, id};
    const msgCopy = t('tkTradeApprovalMultipleWarningMessage');
    const warningConfig = warning && warning.code ? {...config, id} : {...config, msgCopy, id: loadWarningTimeStamp,
      autoHide: false, showCloseCTA: true};

    if(warning && warning.code || (hasRowWarning && lastUsedId !== loadWarningTimeStamp)){
      list.push(warningConfig);
    }

    if(hasRowWarning && lastUsedId !== loadWarningTimeStamp) {
      lastUsedId = loadWarningTimeStamp;
    }

    if(isApprovalSuccessful) {
      list.push({displayMultiple: true, type: 'success', msgCopy: labels.tkTradeApprovalSuccessMessage, id});
    }
    else if(error && error.code){
      list.push({displayMultiple:true, type:'error', msgCopy: labels.tkTradeApprovalErrorMessage, id});
    } else if(isRejectionSuccessful) {
      list.push({displayMultiple:true, type:'success', msgCopy: labels.tkTradeRejectionSuccessMessage, id: rejectionId});
    }

    if(error1 && error1.code){
      list.push({displayMultiple:true, type:'error', msgCopy: labels[pageErrorMessage[error1.code]], id: new Date().getTime()});
    }
    return list;
  }
);

export const approvalSnackbarExceptionList = createSelector(
  labelSelector, getErrorObject, (labels, errorObject) => {
    const list = [];
    const {showErrorOn, errorCode, errorMessage} = errorObject;
    if (showErrorOn === SHOW_ERROR_ON.SNACKBAR &&
      (errorCode === errorTypes.UNKNOWN_SERVICE_ERROR || errorCode === errorTypes.TRADE_APPROVALS_DUPLICATE)) {
      const message = errorMessage && labels[errorMessage] || labels[errorMessages[errorCode].tkBody];
      list.push({displayMultiple: true, id: new Date().getTime().toLocaleString(), type: 'error', msgCopy: message});
    }
    return list;
  }
);
