import { crossModelReducer, quickFilterReducer } from '@gs-ux-uitoolkit-common/datacore';
import * as Redux from 'redux';
import { DataGridState } from '../datagrid-state';
import { autofitPivotReducer } from './autofit-pivot-reducer';
import { columnHintReducer } from './column-hint-reducer';
import { customSortReducer } from './custom-sort-reducer';
import { keyboardShortcutReducer } from './keyboard-shortcut-reducer';
import { exportReducer } from './export-reducer';
import { gridReducer } from './grid-reducer';
import { rowCustomisationReducer } from './row-customisation-reducer';
import { savedViewReducer } from './saved-view-reducer';
import { maskedColumnReducer } from './masked-column-reducer';
import { searchableReducer } from './searchable-reducer';
import { systemConfigurationReducer } from './system-configuration-reducer';
import { notificationReducer } from './notification-reducer';
import { zebraStripesReducer } from './zebra-stripes-reducer';
import { gridConfigurationReducer } from './grid-configuration-reducer';
import { pluginsReducer } from './plugins-reducer';

/**
 * Root reducer of the application
 */
export const rootReducer: Redux.Reducer<DataGridState> = Redux.combineReducers<DataGridState>({
    autofitPivot: autofitPivotReducer,
    columnHint: columnHintReducer,
    crossModel: crossModelReducer,
    customSort: customSortReducer,
    keyboardShortcut: keyboardShortcutReducer,
    export: exportReducer,
    grid: gridReducer,
    gridConfiguration: gridConfigurationReducer,
    rowCustomisation: rowCustomisationReducer,
    savedView: savedViewReducer,
    maskedColumn: maskedColumnReducer,
    quickFilter: quickFilterReducer,
    searchable: searchableReducer,
    systemConfiguration: systemConfigurationReducer,
    notification: notificationReducer,
    version: () => 0,
    zebraStripes: zebraStripesReducer,
    plugins: pluginsReducer,
});
