import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import {Tooltip} from '@gs-ux-uitoolkit-react/tooltip';
import translator from "../../../../services/translator";
import {Conditional} from "../../../../components/core/Conditional";

const {translate: t} = translator;

export const ThresholdLabelRenderer = ({
  metricLabel,
  metricValueType,
  metricValue,
  shareClassName,
  isShowTooltipForShareClassName = true,
  lowerThreshold,
  upperThreshold,
  showThresholdDetails = true
}) => {
  const shareClassNameId = `shareClassName-${Math.floor(Math.random() * 1000000000)}`;
  return (
    <div className="label-wrapper">
      <Conditional condition={!!metricLabel}>
        <>
          <span>{metricLabel}</span>
          <span className="label-wrapper__normal">{` ${t('tkFor')} `}</span>
        </>
      </Conditional>
      <Conditional condition={isShowTooltipForShareClassName && showThresholdDetails}>
        <>
          <Tooltip target={shareClassNameId} placement="bottom">
            {shareClassName}
          </Tooltip>
          <div id={shareClassNameId} className="label-wrapper__truncate">
            {shareClassName}
          </div>
        </>
        <div className={cn({"label-wrapper__truncate": showThresholdDetails}, {"label-wrapper__fund": !showThresholdDetails})}>
          {shareClassName}
        </div>
      </Conditional>
      <Conditional condition={showThresholdDetails}>
        <>
          <span className="label-wrapper__normal">
            {` ${t('is')} `}
          </span>
          <Conditional condition={lowerThreshold}>
            <>
              <span>{` ${t('tkLessThan').toLowerCase()} `}</span>
              <span>{`${lowerThreshold}${metricValueType}`}</span>
            </>
          </Conditional>
          <Conditional condition={lowerThreshold && upperThreshold}>
            <span className="label-wrapper__normal">{` ${t('tkand')} `}</span>
          </Conditional>
          <Conditional condition={upperThreshold}>
            <>
              <span>{`${t('tkGreaterThan').toLowerCase()} `}</span>
              <span>{`${upperThreshold}${metricValueType}`}</span>
            </>
          </Conditional>
          <Conditional condition={metricValue}>
            <span className="label-wrapper__light">
              {` (${t('tkCurrently').toLowerCase()}: ${metricValue})`}
            </span>
          </Conditional>
        </>
      </Conditional>
    </div>
  );
};

ThresholdLabelRenderer.propTypes = {
  metricLabel: PropTypes.object,
  metricValue: PropTypes.string,
  metricValueType: PropTypes.string,
  shareClassName: PropTypes.string,
  isShowTooltipForShareClassName: PropTypes.bool,
  lowerThreshold: PropTypes.func,
  upperThreshold: PropTypes.string,
  showThresholdDetails: PropTypes.bool
};
