import React from 'react';

export const getNoDataComponent = (id, placeholder) => {
  return () => {
    const className = id ? `${id}__no-data` : '';
    return (
      <div className={className}>{placeholder}</div>
    );
  };
};
