import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from '@gs-ux-uitoolkit-react/card';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import translator from '../../../../../services/translator';

const { translate: t } = translator;

const DummyCards = ({ moduleName }) => {
  return (
    <Card bordered elevation='00' className='dbcard dummycontainer  gs-uitk-override-css'>
      <CardBody>
        <h4 className='dbcard__header'>{moduleName}</h4>
        <div className='dbcard__dummyCards'>
          <Icon name='react' type='filled' />
          <p>{t('tkComingSoon')}</p>
        </div>
      </CardBody>
    </Card>
  );
};

DummyCards.propTypes = {
  moduleName: PropTypes.string,
};

export default DummyCards;
