import { cx } from '@gs-ux-uitoolkit-common/style';
import { TippyCssClasses, getTippyRootClasses } from '@gs-ux-uitoolkit-common/popover-base';
import { TooltipProps } from './tooltip-props';
import { TooltipCssClasses } from './tooltip-style-sheet';

const globalClassName = 'gs-tooltip';

export const getTooltipRootClasses = (props: {
    cssClasses?: TooltipCssClasses;
    tippyCssClasses: TippyCssClasses;
    overrideClasses?: TooltipProps['classes'];
    className?: TooltipProps['className'];
}): string => {
    const { tippyCssClasses, className, overrideClasses } = props;
    return cx(
        getTippyRootClasses({ cssClasses: tippyCssClasses }),
        className,
        overrideClasses && overrideClasses.root,
        globalClassName
    );
};

export const getTooltipCloseButtonClasses = (props: {
    cssClasses: TooltipCssClasses;
    overrideClasses?: TooltipProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.dismissButton,
        overrideClasses && overrideClasses.dismissButton,
        `${globalClassName}__dismiss-button`
    );
};

export const getTooltipLabelClasses = (props: {
    cssClasses: TooltipCssClasses;
    overrideClasses?: TooltipProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.label,
        overrideClasses && overrideClasses.label,
        `${globalClassName}__label`
    );
};

export const removeFocusFromAllChildrens = (element: HTMLElement | Element) => {
    element.setAttribute('tabindex', '-1');
    return Array.from(element.children).forEach(removeFocusFromAllChildrens);
};

export const getTooltipContentInnerClasses = (props: {
    cssClasses: TooltipCssClasses;
    overrideClasses?: TooltipProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.contentInner,
        overrideClasses && overrideClasses.contentInner,
        `${globalClassName}__content-inner`
    );
};
