import { ColDef, ColGroupDef, HeaderValueGetterParams } from '@ag-grid-community/core';
import { DashGridColumn, DraViewConfig } from '@gs-ux-uitoolkit-common/datacore';
import { CustomDRAOptions } from '../plugins/dra/dra-plugin';
import { DraColumnGroupRenderer } from './dra-column-group-renderer';
import { DraGroupRenderer } from './dra-group-renderer';
import { ExpansionDepthHeaderRenderer } from './expansion-depth-header-renderer';
import { hasOwnProperty } from 'gs-uitk-object-utils';

export const groupIdSuffix = '_group';
/**
 * Class used to convert column schema from DashGrid format to aggrig format
 */
export class DashGridToagGridColumnConverter {
    /**
     * Takes a Dashrid column definition and output the corresponding agGrid column definition
     * It handles Column grouping (with the groupingDelimiter)
     * @param columns Dashgrid column definition
     */
    public static convert(
        columns: DashGridColumn[],
        viewConfig?: DraViewConfig,
        columnGroupDynamicAlignment?: 'flex-start' | 'flex-end' | 'center' | 'none'
    ): (ColDef | ColGroupDef)[] {
        const schema: (ColGroupDef | ColDef)[] = [];

        for (const col of columns) {
            let parent: (ColGroupDef | ColDef)[] = schema;
            // we have some grouping. Yeah!
            if (col.groupingDelimiter && col.name && col.name.indexOf(col.groupingDelimiter) > 0) {
                // we split the columnName into individual items
                const colSplit = col.name.split(col.groupingDelimiter);
                // we iterate and see it the last group processed is the same one as the one for that column
                // if it is then we use it. If not we create another group
                // note: this can be at any level above the column
                for (let index = 0; index < colSplit.length - 1; index = index + 1) {
                    let group: ColGroupDef | ColDef | null = null;
                    // we used to create multiple groups with the same id if DRA was sending split grouped column definition
                    // ag-grid have the concept of id and instance id for groups so you can split groups at runtime
                    // but it doesn't work when creating them. So we need to add the column to the original group
                    // it means that potentially column order is going to be slightly different but since it will happen
                    // probably only for Freddie datasource and they are overriding the column orders on the client side we should be fine
                    // We add _group suffix to group_id so that it does not conflict with colId.

                    const groupId =
                        colSplit.slice(0, index + 1).join(col.groupingDelimiter) + groupIdSuffix;

                    // find a group based on groupId
                    group =
                        parent.find(
                            x =>
                                hasOwnProperty(x, 'groupId') &&
                                (x as ColGroupDef).groupId === groupId
                        ) || null;

                    // if we are processing a new group. Create it
                    if (!group || !hasOwnProperty(group, 'children')) {
                        group = {
                            groupId,
                            children: [],
                            columnGroupShow: 'open',
                            headerGroupComponent: DraColumnGroupRenderer(
                                false,
                                columnGroupDynamicAlignment || 'none'
                            ),
                            headerName: colSplit[index],
                            openByDefault: true,
                        };
                        parent.push(group);
                    }
                    parent = (group as ColGroupDef).children;
                }
            }
            let headerName: string;
            // we now add the column. If grouped column then we want the last part for the headername
            if (col.groupingDelimiter && col.name && col.name.indexOf(col.groupingDelimiter) > 0) {
                const colSplit = col.name.split(col.groupingDelimiter);
                headerName = colSplit[colSplit.length - 1];
            } else {
                headerName = col.name || col.field;
            }
            const columnDef: ColDef = {
                headerName,
                colId: col.field,
                columnGroupShow: 'open',
                field: col.field,
                hide: !!col.hidden,
                tooltipField: headerName,
            };
            if (viewConfig) {
                // if the column is pivotable in DRA we set the columnds definition to be groupable
                if (
                    viewConfig.isPivotable.find(
                        pivotable => pivotable.field === columnDef.field && pivotable.vertical
                    )
                ) {
                    columnDef.enableRowGroup = true;
                }
                if (viewConfig.pivotBy) {
                    // if the column is rowgrouped in the DRA source
                    const rowGroupIndex = viewConfig.pivotBy.findIndex(
                        x => x.field === columnDef.field
                    );
                    if (rowGroupIndex > -1) {
                        columnDef.rowGroup = true;
                        columnDef.rowGroupIndex = rowGroupIndex;
                    }
                }
                // if one of the column is hPivotable then we add by default the enableValue to the columnDef
                if (viewConfig.isPivotable.find(pivotable => pivotable.horizontal)) {
                    columnDef.enableValue = true;
                }
                if (viewConfig.aggregationDefinitions) {
                    const aggDef = viewConfig.aggregationDefinitions[col.field];
                    if (aggDef) {
                        const aggKeys = Object.keys(aggDef);
                        if (aggKeys.length > 0) {
                            columnDef.aggFunc = aggKeys[0];
                            columnDef.allowedAggFuncs = [aggKeys[0]];
                        }
                    } else {
                        columnDef.aggFunc = 'Dra Unknown';
                        columnDef.allowedAggFuncs = ['Dra Unknown'];
                    }
                } else {
                    columnDef.aggFunc = 'Dra Unknown';
                    columnDef.allowedAggFuncs = ['Dra Unknown'];
                }
                // if the column is hPivotable in DRA we set the columnds definition to be pivotable
                if (
                    viewConfig.isPivotable.find(
                        pivotable => pivotable.field === columnDef.field && pivotable.horizontal
                    )
                ) {
                    columnDef.enablePivot = true;
                }

                if (viewConfig.hPivotBy) {
                    // if it's hPivoted in DRA we set it to be pivot in agGrid
                    const hPivotIndex = viewConfig.hPivotBy.findIndex(
                        hPivot => hPivot.field === columnDef.field
                    );
                    if (hPivotIndex > -1) {
                        columnDef.pivot = true;
                        columnDef.pivotIndex = hPivotIndex;
                    }
                }
                // Set the sortable state from the viewconfig
                columnDef.sortable = !!viewConfig.isSortable.find(
                    sortable => sortable.field === columnDef.field && sortable.vertical
                );
                // if the column is sorted in the ViewConfig we tell aggrid!
                if (viewConfig.sortBy) {
                    const colSortBy = viewConfig.sortBy.find(
                        sort => sort.field === columnDef.field
                    );

                    if (colSortBy) {
                        const colSortByIndex = viewConfig.sortBy.indexOf(colSortBy);
                        columnDef.sort =
                            colSortBy.direction.toLowerCase() === 'ascending' ? 'asc' : 'desc';
                        columnDef.sortIndex = colSortByIndex;
                    }
                }
                if (viewConfig.isDynamicAggregationEnabled) {
                    columnDef.hide = !viewConfig.defaultAggregationColumns.includes(col.field);
                }
                // filter component to show for the column
                switch (viewConfig.getType(col.field)) {
                    case 'Number':
                        columnDef.filter = 'agNumberColumnFilter';
                        break;
                    case 'Date':
                        columnDef.filter = 'agDateColumnFilter';
                        break;
                    case 'RDate':
                        columnDef.filter = 'agDateColumnFilter';
                        break;
                    case 'String':
                    case 'Unknown':
                        columnDef.filter = 'agTextColumnFilter';
                        columnDef.filterParams = {
                            defaultOption: 'contains',
                            // we remove startWith and EndWith as not supported yet in DRA
                            filterOptions: ['contains', 'notContains', 'equals', 'notEqual'],
                        };
                        break;
                }
                columnDef.chartDataType =
                    viewConfig.getType(col.field) === 'Number' ? 'series' : 'category';
            }
            parent.push(columnDef);
        }

        return schema;
    }

    /**
     * Adds the Treecol column to the ColumnDefinition
     * @param columnDefinition agGrid col Def
     * @param rowGroupColumnColId Column Id to create for the RowGrouping
     * @param pivotBy The pivotby coming from DRA
     * @param onClick Callback when we click on the RowGroup
     */
    public static handleRowGroup(
        columnDefinition: (ColGroupDef | ColDef)[],
        rowGroupColumnColId: string,
        pivotBy: DashGridColumn[],
        viewConfig: DraViewConfig,
        customDraOptions: CustomDRAOptions
    ): (ColGroupDef | ColDef)[] {
        const sortable = !!customDraOptions.enableRowGroupColumnSorting;
        const rowGroupColumn: ColDef = {
            sortable,
            cellRenderer: DraGroupRenderer(viewConfig, customDraOptions),
            colId: rowGroupColumnColId,
            field: viewConfig.groupKeyField,
            filter: false,
            headerComponent: ExpansionDepthHeaderRenderer,
            headerName: 'TreeCol',
            // TODO: Update type to HeaderValueGetterParams in upgrade to v26.2.0 UX-14973
            headerValueGetter: (params: HeaderValueGetterParams): string => {
                // Do not show header's name in Grid display. All the others (csv,xls,charts) will display TreeCol
                return params.location === 'header' ? '' : 'TreeCol';
            },
            hide: true,
            lockPosition: true,
            lockVisible: true,
            suppressColumnsToolPanel: true,
            suppressFiltersToolPanel: true,
        };
        // if we pivotBy then we make our rowGrouCol visible
        if (pivotBy && pivotBy.length > 0) {
            rowGroupColumn.hide = false;
        }
        return [rowGroupColumn, ...columnDefinition];
    }
}
