import {
    Button,
    ButtonActionType,
    ButtonEmphasis,
    ButtonSize,
} from '@gs-ux-uitoolkit-react/button';
import { Icon, IconBaseProps } from '@gs-ux-uitoolkit-react/icon-font';
import { Component, ReactElement } from 'react';
import { isEqual } from 'gs-uitk-lodash';

/**
 * The ButtonBase props
 */
export interface ButtonBaseProps extends ButtonProps {
    /**
     * Icon that will be displayed on the button
     */
    icon: IconBaseProps;
    /**
     * Label that will be displayed in the button
     */
    label: string;
}

/**
 * Props used by all that are specific. i.e. ButtonDelete and ButtonEdit...
 */
export interface ButtonProps {
    /**
     * The type of display for the button
     */
    displayType: ButtonDisplay;
    /**
     * if the button is displayed disabled
     */
    disabled?: boolean;
    /**
     * function called when the user click on the button
     */
    onClick?: () => void;
    /**
     * Action type of the button.
     */
    actionType?: ButtonActionType;
    /**
     * Override default Label
     */
    label?: string;
    /**
     * Override default icon
     */
    icon?: IconBaseProps;
    /**
     * Size of the button
     */
    size?: ButtonSize;
    emphasis?: ButtonEmphasis;
    className?: string;
}

/**
 * Define how a button will be displayed
 */
export enum ButtonDisplay {
    /**
     * Just showing the icon on the button
     */
    Icon = 'Icon',
    /**
     * Showing both the icon and the label on the button
     */
    IconLabel = 'IconLabel',
}

/**
 * Shortcut component that will be displayed in the Toolbar. Currently just an icon with a tooltip
 */
export class ButtonBase extends Component<ButtonBaseProps> {
    private content: ReactElement = (<div>Error in the props</div>);
    public static defaultProps: Partial<ButtonBaseProps> = {
        actionType: 'primary',
        disabled: false,
    };
    public shouldComponentUpdate(nextProps: ButtonBaseProps) {
        if (isEqual(this.props, nextProps)) {
            return false;
        }
        return true;
    }
    public render() {
        const className = `button-${this.props.label.toLowerCase()}`;
        switch (this.props.displayType) {
            case ButtonDisplay.Icon:
                this.content = <Icon {...this.props.icon} />;
                break;
            case ButtonDisplay.IconLabel:
                this.content = (
                    <>
                        <Icon {...this.props.icon} />
                        <span>{this.props.label}</span>
                    </>
                );
                break;
            default:
                this.content = <div>Error in the props</div>;
                break;
        }
        const buttonClassName = className + ' ' + `${this.props.className || ''}`;
        return (
            <Button
                disabled={this.props.disabled}
                actionType={this.props.actionType}
                onClick={this.onClick}
                data-cy={className}
                className={buttonClassName}
                size={this.props.size}
                emphasis={this.props.emphasis}
            >
                {this.content}
            </Button>
        );
    }
    private onClick = () => this.props.onClick && this.props.onClick();
}
