import { StyleSheet } from '@gs-ux-uitoolkit-react/style';
import { colors } from '@gs-ux-uitoolkit-common/design-system';
import { MOBILE_BREAKPOINT } from '../style-constant';
import { Theme } from '@gs-ux-uitoolkit-react/theme';

export const modalsContainerStyleSheet = new StyleSheet(
    'gs-uitk-modals-container',
    ({ theme }: { theme: Theme }) => {
        return {
            root: {
                display: 'flex',
                '.gs-modal__content': {
                    maxWidth: '1280px',
                    margin: 'auto',
                },
                '&.responsive-mode': {
                    '.gs-modal__content': {
                        position: 'fixed',
                        inset: 0,
                        '.gs-uitk-modals-container-body': {
                            height: '100%',
                        },
                    },
                },
                width: 'unset',
                '.uitk-datagrid-modal-table-buttons': {
                    textAlign: 'right',
                },
                '.gs-tabs': {
                    height: '100%',
                },

                '.gs-uitk-plugin-management': {
                    height: '100%',
                    overflow: 'hidden',
                    width: '100%',
                    marginRight: 0,
                    marginLeft: 0,
                },
                '.gs-uitk-modals-container-header': {
                    paddingBottom: '0.2rem',
                    width: '100%',
                    boxSizing: 'border-box',
                    alignItems: 'center',
                    '.gs-modal__title-container': {
                        width: '100%',
                    },
                    '&-span': {
                        display: 'flex',
                        alignItems: 'center',
                        '.gs-button': {
                            marginLeft: 'auto',
                            marginTop: '-2px',
                        },
                        '&-plugin-title': {
                            display: 'none',
                        },
                    },
                },

                '.gs-uitk-modals-container-body': {
                    maxHeight: 'unset',
                    height: '60vh',
                    overflow: 'scroll',
                    padding: '12px 4px 0px 16px',

                    '.tabs-container': {
                        height: '100%',
                        '& > .tab-content': {
                            height: 'calc(100% - 2rem) !important', // Minus the height of the tabs
                            minHeight: '20rem !important',

                            marginTop: '0.625rem',
                            '::-webkit-scrollbar-track': {
                                borderRadius: '1rem',
                            },
                            '::-webkit-scrollbar-thumb ': {
                                borderRadius: '1rem',
                            },
                            '& > .tab-pane': {
                                height: '100%',
                                '& > .gs-layout-row': {
                                    height: '100%',
                                },
                            },
                        },

                        '.gs-list-group': {
                            height: '100%',
                            overflow: 'auto',
                        },
                    },
                },
                '.config-items-container': {
                    '.gs-uitk-button-group': {
                        display: 'flex',
                        justifyContent: 'flex-end',

                        '.button-edit button, .button-delete button': {
                            background: 'none',
                            border: 'none',

                            '&:hover, &:focus, &:disabled': {
                                background: 'none',
                            },
                        },
                    },
                    '.config-items-table': {
                        tableLayout: 'fixed',
                        overflowY: 'auto',
                        marginTop: '0.5rem',
                        wordBreak: 'break-word',
                        table: {
                            backgroundColor: 'unset',
                        },
                        thead: {
                            backgroundColor: theme.surface.secondary,
                            'tr th': {
                                borderBottom: `1px solid ${colors.gray020}`,
                                verticalAlign: 'middle',
                            },
                        },
                    },

                    'li.nav-item:not(.vertical-item)': {
                        [`&,a.nav-link`]: {
                            '&:after': {
                                left: '0',
                                width: '100%',
                            },
                            [`&:hover:not(.disabled):not(.active):after,
                        &.active:after,
                        &.contains-active:after`]: {
                                width: '100%',
                                left: '0',
                            },
                        },
                    },

                    [`input:not([type='checkbox']):not([type='radio']),
                select,
                input.form-control:not([type='radio']):not([type='checkbox'])`]: {
                        maxWidth: '20rem',
                    },
                },
                '.gs-uitk-show-toolbar-container': {
                    display: 'flex',
                    fontSize: '0.875rem',
                    '.gs-uitk-show-toolbar-value': {
                        marginBottom: '0',
                        marginRight: '1rem',
                    },
                    '.toggle-switch-container': {
                        paddingTop: '0.5rem',
                    },
                },

                [`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
                    '.gs-uitk-modals-container-body': {
                        padding: '16px',
                    },
                    '.gs-uitk-modals-container-header': {
                        '&-span': {
                            '&-plugin-title': {
                                display: 'flex',
                                alignItems: 'center',
                                '.gs-button': {
                                    marginRight: '8px',
                                },
                            },

                            '&-default-title': {
                                display: 'none',
                            },
                        },
                    },
                },
            },
        };
    }
);
