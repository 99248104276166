// tslint:disable:no-submodule-imports
import { DayElement, Instance } from 'flatpickr/dist/types/instance';
import { hasCalendar } from '../utils/plugin-helpers';

export const weekdayHover = () => {
    return (instance: Instance) => {
        if (!hasCalendar(instance)) {
            return {};
        }

        let dayContainerEl: DayElement | null = null;
        const removeActiveClass = () => {
            instance.calendarContainer
                .querySelectorAll('.flatpickr-weekday.active')
                .forEach(el => el.classList.remove('active'));
        };
        const mouseOverHandler = (e: MouseEvent) => {
            const target = e.target as DayElement;
            if (target && target.classList.contains('flatpickr-day')) {
                removeActiveClass();
                const index =
                    Array.prototype.indexOf.call(target.parentElement!.children, target) % 7;
                const activeWeekday = instance.calendarContainer.querySelectorAll(
                    '.flatpickr-weekdaycontainer .flatpickr-weekday'
                )[index];
                if (activeWeekday) {
                    activeWeekday.classList.add('active');
                }
            }
        };

        return {
            onReady() {
                dayContainerEl = instance.calendarContainer.querySelector('.flatpickr-days');
                if (dayContainerEl) {
                    dayContainerEl.addEventListener('mouseover', mouseOverHandler);
                    dayContainerEl.addEventListener('mouseout', removeActiveClass);
                }

                const allWeekdays =
                    instance.calendarContainer.querySelectorAll('.flatpickr-weekday');
                const allDays = instance.calendarContainer.querySelectorAll('.flatpickr-day');

                allWeekdays.forEach((weekday, index) => {
                    weekday.classList.add('disabled');
                    for (let dayIndex = index; dayIndex < allDays.length; dayIndex = dayIndex + 7) {
                        if (!allDays[dayIndex].classList.contains('disabled')) {
                            weekday.classList.remove('disabled');
                            break;
                        }
                    }
                });
            },
            onDestroy() {
                if (dayContainerEl) {
                    dayContainerEl.removeEventListener('mouseover', mouseOverHandler);
                    dayContainerEl.removeEventListener('mouseout', removeActiveClass);
                }
            },
        };
    };
};
