import styles  from '../../stylesheets/abstracts/mixins/_excel_variables.scss'
const numberConfig = [
  {"one": "#,##0.0"},
  {"two": "#,##0.00"},
  {"three": "#,##0.000"},
  {"four": "#,##0.0000"},
  {"five": "#,##0.00000"},
  {"six": "#,##0.000000"},
  {"seven": "#,##0.0000000"},
  {"eight": "#,##0.00000000"},
  {"nine": "#,##0.000000000"}

];
const processStringQuotes = (stringWithQuotes) => {
  stringWithQuotes = stringWithQuotes.replace(new RegExp('\'', 'g'), '');
  stringWithQuotes = stringWithQuotes.replace(new RegExp('\"', 'g'), '');
  return stringWithQuotes;
};
const processFontSize = (fontSize) => {
  fontSize = processStringQuotes(fontSize);
  return parseFloat(fontSize.split('px')[0]);
};

const headerFontStyle = processStringQuotes(styles.excelHeaderFontStyle);
const headerFontSize = processFontSize(styles.excelHeaderFontSize);
const headerColor = processStringQuotes(styles.excelHeaderColor);
const headerPattern = processStringQuotes(styles.excelHeaderPattern);
const headerBold = (processStringQuotes(styles.excelHeaderBold) == 'true');

const footerFontStyle = processStringQuotes(styles.excelFooterFontRegular);
const footerFontSize = processFontSize(styles.excelFooterFontSize);
const textFontStyle = processStringQuotes(styles.excelTextFontStyle);
const textFontSize = processFontSize(styles.excelTextFontSize);
const userinfoFontStyle = processStringQuotes(styles.excelUserinfoFontStyle);
const userinfoFontSize = processFontSize(styles.excelUserinfoFontSize);
const numberFontSize = processFontSize(styles.excelNumberFontSize);
const numberFontStyle = processStringQuotes(styles.excelNumberFontStyle);

const numberIteration  = (number, alignment, nameID) => {
  let copyConfig = {
    id: "",
    alignment: {
      horizontal: ""
    },
    numberFormat: { format: "" },
    font:{
      fontName: numberFontStyle,
      size: numberFontSize
    }
  };
  copyConfig.id = nameID.replace("{num}", Object.keys(number)[0]);
  copyConfig.alignment.horizontal = alignment;
  copyConfig.numberFormat.format = number[Object.keys(number)[0]];
  return copyConfig;
};
const excelStyleConfigGenerator  = () => {
  const excelStyleConfig  =[
    {
      id: "text-format",
      alignment: {
        horizontal: 'Center'
      },
      dataType: "string",
      font:{
        fontName: textFontStyle,
        size: textFontSize
      }
    },
    {
      id: "header",
      interior: {
        color: headerColor,
        pattern: headerPattern
      },
      font: {
        bold: headerBold,
        fontName: headerFontStyle,
        size: headerFontSize
      },
      alignment: {
        horizontal: 'Center'
      }
    },
    {
      id: "align-right",
      alignment: {
        horizontal: 'Right'
      },
      font:{
        fontName: textFontStyle,
        size: textFontSize
      }
    },
    {
      id: "num-align-right",
      alignment: {
        horizontal: 'Right'
      },
      font:{
        fontName: textFontStyle,
        size: textFontSize
      },
      dataType: "string"
    },
    {
      id: "align-left",
      alignment: {
        horizontal: 'Left',
      },
      font:{
        fontName: textFontStyle,
        size: textFontSize
      }
    },
    {
      id: "footer-style",
      alignment: {
        horizontal: 'Left'
      },
      font: {
        fontName: footerFontStyle,
        size: footerFontSize
      }
    },
    {
      id: "bold-footer-style",
      alignment: {
        horizontal: 'Left'
      },
      font: {
        size: footerFontSize,
        fontName: footerFontStyle,
        bold: true
      }
    },
    {
      id: "text-font-style",
      font: {
        size: textFontSize,
        fontName: textFontStyle
      }
    },
    {
      id: "userinfo-font-style",
      font: {
        size: userinfoFontSize,
        fontName: userinfoFontStyle //"Arial"
      },
    },
    {
      id: "excel-date-format",
      dataType: "dateTime",
      numberFormat: {
        format: 'DD-mmm-YYYY;;;'
      }
    }
  ];
  numberConfig.forEach(number => {
    excelStyleConfig.push(numberIteration(number, "Left", "{num}-digit-nav"));
    excelStyleConfig.push(numberIteration(number, "Right", "{num}-digit-format"))
  });
  return excelStyleConfig;
};

export default excelStyleConfigGenerator()
