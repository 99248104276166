import {
    Children,
    FunctionComponent,
    PropsWithChildren,
    ReactNode,
    cloneElement,
    isValidElement,
    memo,
    useCallback,
    useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Popover, CommonReactPopoverProps } from './popover';
import { PopoverHeader } from './popover-header';
import { PopoverBody } from './popover-body';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { getPopoverTargetClasses, popoverTargetStyleSheet } from '@gs-ux-uitoolkit-common/popover';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';

export interface PopoverTargetProps extends CommonReactPopoverProps {
    header?: ReactNode;
    body?: ReactNode;
    inline?: boolean;
}

export const PopoverTarget: FunctionComponent<PropsWithChildren<PopoverTargetProps>> = memo(
    props => {
        const targetRef = useRef<HTMLSpanElement>(null);
        const { header, body, inline = false } = props;
        const theme = useTheme();

        const cssClasses = useStyleSheet(popoverTargetStyleSheet, { inline, theme });

        const disableFocusOnChildrenNodes = useCallback(
            (children: ReactNode): ReactNode =>
                Children.map(children, child => {
                    if (!isValidElement(child)) return child;

                    return cloneElement(child, {
                        ...child.props,
                        /** Removes focus on all custom triggers passed by users.
                         * Buttons, inputs are focusable by default so we need to remove that in order to prevent double "focus"
                         */
                        tabIndex: -1,
                        children: disableFocusOnChildrenNodes(child.props.children),
                    });
                }),
            []
        );

        return (
            <>
                <span
                    ref={targetRef}
                    className={getPopoverTargetClasses({
                        cssClasses,
                        overrideClasses: props.classes,
                    })}
                    data-gs-uitk-component="popover-target"
                    tabIndex={0}
                    role="button"
                    aria-pressed="false"
                    aria-live="polite"
                >
                    {disableFocusOnChildrenNodes(props.children)}
                </span>
                <Popover {...props} target={targetRef}>
                    {header && (
                        <PopoverHeader size={props.size} classes={props.classes}>
                            {header}
                        </PopoverHeader>
                    )}
                    {body && (
                        <PopoverBody size={props.size} classes={props.classes}>
                            {body}
                        </PopoverBody>
                    )}
                </Popover>
            </>
        );
    }
);
PopoverTarget.displayName = 'PopoverTarget';

PopoverTarget.propTypes = {
    inline: PropTypes.bool,
};
