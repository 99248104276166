import { ComponentProps, CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { ColorName, Emphasis, Size, Status, Surface } from '@gs-ux-uitoolkit-common/design-system';
import { CloseButtonCssClasses } from './close-style-sheet';

export interface CloseButtonProps<StyleType = CommonStyleType> extends ComponentProps<StyleType> {
    /**
     * Style classes to override.
     */
    classes?: Partial<CloseButtonCssClasses>;

    /**
     * Size of the close button
     */
    size?: CloseButtonSize;

    /**
     * Defines the surface on which the close button will be placed. This should be a value
     * that matches the surfaces or status defined as a part of UI Toolkit theme tokens. The
     * color of the close button and the hover, active and focus styles adapt to provide
     * sufficient contrast on the surface or status.
     */
    surface?: ColorName | Status | Surface;

    /**
     * Emphasis determines the visual focus the component should receive when the close button
     * is interacted with. When using this in a component which supports a subtle prop (Eg: alert)
     * use the same value the parent component receives.
     */
    emphasis?: CloseEmphasis;

    /**
     * If set to true the the color of the close button will take the same styles as a link. Use
     * this if the close button is being used with other components that have a link-like style.
     */
    link?: boolean;

    /**
     * Overrides the tabindex on the button. This needs to be modified only in special
     * circumstances, such as if CloseButton is implemented in the context of a larger component
     * that has its own keyboard-accessibility pattern.
     */
    tabIndex?: number;

    /**
     * Sets the disabled state of the button.
     */
    disabled?: boolean;

    /**
     * A className to be applied on the button. This has been created to allow compatibility with
     * components that allow custom classes to be applied on the component's close button
     * (Eg: Alert.dismissButtonClassName)
     */
    buttonClassName?: string;
}

export type CloseButtonSize = Extract<Size, 'sm' | 'md' | 'lg'>;
export const closeSizes: CloseButtonSize[] = ['sm', 'md', 'lg'];

export type CloseEmphasis = Extract<Emphasis, 'bold' | 'subtle' | 'minimal'>;

export const defaultCloseButtonProps: DefaultCloseButtonProps = {
    size: 'md',
    surface: 'primary',
    emphasis: 'bold',
};

export type DefaultCloseButtonProps = Required<
    Pick<CloseButtonProps, 'size' | 'surface' | 'emphasis'>
>;
