import { Component, Fragment } from 'react';
import { Row, Col } from '@gs-ux-uitoolkit-react/layout';
import { FormGroup } from '@gs-ux-uitoolkit-react/form';
import { Checkbox, CheckboxChangeEvent } from '@gs-ux-uitoolkit-react/checkbox';
import { Label } from '@gs-ux-uitoolkit-react/label';
import { HintList } from '@gs-ux-uitoolkit-common/datacore';
import { FormatControlProps } from '../column-hint-format-manager';
import { columnFormattingPluginStylePrefix } from '../../../../../style/plugins/column-hint-plugin-stylesheet';

export class ColumnHintControlNegative extends Component<FormatControlProps> {
    public render() {
        return (
            <Fragment>
                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            <Label>Negative Value Formatting</Label>
                            <div
                                className={`${columnFormattingPluginStylePrefix}_numeric-negative-container`}
                            >
                                <Checkbox
                                    name="useBrackets"
                                    checked={!!this.props.columnHint.format?.parenthesesIfNegative}
                                    onChange={this.onChangeUseBrackets}
                                >
                                    Use (Brackets)
                                </Checkbox>
                                <Checkbox
                                    name="showRed"
                                    checked={!!this.props.columnHint.format?.redIfNegative}
                                    onChange={this.onChangeDisplayRed}
                                >
                                    <span
                                        className={`${columnFormattingPluginStylePrefix}_display_in_red`}
                                    >
                                        Display in red
                                    </span>
                                </Checkbox>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </Fragment>
        );
    }

    private onChangeUseBrackets = (e: CheckboxChangeEvent) => {
        const value = e.checkedState === true;
        const clonedHint: HintList = {
            ...this.props.columnHint,
            format: { ...this.props.columnHint.format, parenthesesIfNegative: value },
        };
        this.props.onChangeHint(clonedHint);
    };

    private onChangeDisplayRed = (e: CheckboxChangeEvent) => {
        const value = e.checkedState === true;
        const clonedHint: HintList = {
            ...this.props.columnHint,
            format: { ...this.props.columnHint.format, redIfNegative: value },
        };
        this.props.onChangeHint(clonedHint);
    };
}
