import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Repeater from '../Repeater';
import {Conditional} from '../Conditional';
import './index.scss';

const Tab = (props) => {
  const {onClick, id, label, isActive, disabled, testId, key, index} = props;
  const handleClick = (event) => {
    if(!disabled) {
      event.preventDefault();
      onClick(id, props);
    }
  };
  return (
    <li data-testid={`${testId}-tab-${index}`} key={key} className={cn('tabs__item', {'tabs__item--active': isActive}, {'tabs__item--disabled': disabled})}>
      <a onClick={handleClick} className="tabs__link">
        <span className="tabs__label">{label}</span>
      </a>
    </li>);
};

Tab.defaultProps = {
  isActive: false,
  disabled: false
};

Tab.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
  testId:PropTypes.string,
  key:PropTypes.string,
  index:PropTypes.number
};

const TabPanel = ({tabs, activeTabId, onTabChange, testId}) => {

  const [currentTab, setCurrentTab] = useState({});
  const [currentTabId, setCurrentTabId] = useState(activeTabId);
  const handleTabClick = (id, tab) => {
    if(currentTabId === id) return;
    setCurrentTabId(id);
    onTabChange(id, tab);
  };

  const getCurrentTab = (tabId) => {
    return tabs.length > 0 && tabs.find((tab) => {
      const {id} = tab;
      return id === tabId;
    }) || tabs[0];
  };

  useEffect(() => {
    const activeTab = getCurrentTab(currentTabId);
    setCurrentTab(activeTab);
  }, [currentTabId]);

  const items = tabs.map((tab) => {
    const activeTab = getCurrentTab(currentTabId);
    return {...tab, isActive: tab.id === activeTab.id};
  });

  return (
    <div className="tabs" data-testid={testId}>
      <ul className="tabs__list">
        <Repeater passItemsAsProps={true} items={items} props={{onClick: handleTabClick, testId}}>
          <Tab />
        </Repeater>
      </ul>
      <div className="tabs_content" data-testid={`${testId}-tab-content`}>
        <Conditional condition={currentTab && Object.keys(currentTab).length > 1}>
          <currentTab.component />
        </Conditional>
      </div>
    </div>
  );
};

TabPanel.defaultProps = {
  onTabChange: () => {},
  tabs: [],
  testId: ' '
};

TabPanel.propTypes = {
  activeTabId: PropTypes.string,
  tabs: PropTypes.array,
  onTabChange: PropTypes.func,
  testId: PropTypes.string
};

export default TabPanel;
