import {
    ColumnHint,
    ColumnHintDefaults,
    DataType,
    stringHelper,
} from '@gs-ux-uitoolkit-common/datacore';
import { CSSProperties } from 'react';
import * as Redux from 'redux';
import { AgGridWrapper } from '../grid-wrappers/ag-grid/ag-grid-wrapper';
import { columnHintHelper } from '../plugins/column-hint/column-hint-helper';
import {
    COLUMN_HINT_PREFIX,
    COLUMN_HINT_RED_IF_NEGATIVE,
    DEFAULT_COLUMN_HINT_PREFIX,
} from '../plugins/column-hint/column-hint-plugin';
import { FontSizes, RowCustomisation } from '../plugins/row-customisation/row-customisation';

export const PIVOT_COLUMN_CLASS_PREFIX = 'pivot-column-depth-';
export const DEFAUTL_PIVOT_INDENT = 5;
const redIfNegativeExcelStyle = {
    font: {
        color: '#eb423c', // red-600
        pattern: 'Solid',
    },
    id: COLUMN_HINT_RED_IF_NEGATIVE,
};
/**
 * ExcelStyleService that handles putting stlyes onto the gridoptions for export
 */
export class ExcelStyleService {
    private currentHighlights: RowCustomisation[] = [];
    private currentColumnHints: ColumnHint[] = [];
    private includeStyles = false;
    private storeSubscription: Redux.Unsubscribe | null = null;
    private originalExcelStyles: any[] | undefined;
    private indentPivotColumnStyles: {
        alignment: {
            indent: number;
        };
        id: string;
    }[] = [];
    private indentPivotColumn: boolean = false;

    constructor(private gridWrapper: AgGridWrapper) {}

    public start() {
        const gridOptions = this.gridWrapper.getGridOptions();
        this.originalExcelStyles = gridOptions.excelStyles;
        this.updateStyles();
        this.storeSubscription = this.gridWrapper
            .getReduxStore()
            .subscribe(() => this.updateStyles());
    }
    public stop() {
        if (this.storeSubscription) {
            this.storeSubscription();
        }
    }
    public addPivotColumnStyle = (level: number) => {
        const style = {
            alignment: {
                indent: level,
            },
            id: `${PIVOT_COLUMN_CLASS_PREFIX}${level}`,
        };
        this.indentPivotColumnStyles.push(style);
    };
    public addPivotColumnStyles = (numRowGroupColumn: number) => {
        for (let i = 1; i <= numRowGroupColumn; i += 1) {
            this.addPivotColumnStyle(i);
        }
    };
    private getFontSizePoint(fontSize: FontSizes): number {
        switch (fontSize) {
            case FontSizes.Large:
                return 18;
            case FontSizes.Medium:
                return 16;
            case FontSizes.Small:
                return 13;
            case FontSizes.XLarge:
                return 24;
            case FontSizes.XSmall:
                return 10;
            case FontSizes.XXLarge:
                return 32;
            case FontSizes.XXSmall:
                return 9;
        }
        return 12;
    }

    private updateStyles() {
        const newHighlights = this.gridWrapper.getReduxStore().getState()
            .rowCustomisation.configItemList;
        const newColumnHints = this.gridWrapper.getReduxStore().getState()
            .columnHint.configItemList;
        const newIncludeStyles = this.gridWrapper.getReduxStore().getState().export
            .options.includeStyles;
        const newIndentPivotColumn = this.gridWrapper.getReduxStore().getState().export
            .options.indentPivotColumn;

        if (
            this.currentHighlights !== newHighlights ||
            this.currentColumnHints !== newColumnHints ||
            this.includeStyles !== newIncludeStyles ||
            this.indentPivotColumn !== newIndentPivotColumn
        ) {
            this.currentHighlights = newHighlights;
            this.currentColumnHints = newColumnHints;
            this.includeStyles = newIncludeStyles;
            this.indentPivotColumn = newIndentPivotColumn;

            let localStyleClasses: any[] = []; // Use any type as there are no type for style classes

            if (this.includeStyles) {
                localStyleClasses = this.getRowCustomisationStyles(newHighlights);

                const columnHintDefaults = this.gridWrapper.getReduxStore().getState()
                    .columnHint.defaults;

                if (columnHintDefaults) {
                    localStyleClasses = localStyleClasses.concat(
                        this.getColumnHintDefaultStyle(columnHintDefaults)
                    );

                    if (columnHintDefaults.numberDefaults) {
                        localStyleClasses = localStyleClasses.concat(
                            this.getColumnHintDefaultStyle(
                                columnHintDefaults.numberDefaults,
                                DataType.Number
                            )
                        );
                    }

                    if (columnHintDefaults.stringDefaults) {
                        localStyleClasses = localStyleClasses.concat(
                            this.getColumnHintDefaultStyle(
                                columnHintDefaults.stringDefaults,
                                DataType.String
                            )
                        );
                    }

                    if (columnHintDefaults.dateDefaults) {
                        localStyleClasses = localStyleClasses.concat(
                            this.getColumnHintDefaultStyle(
                                columnHintDefaults.dateDefaults,
                                DataType.Date
                            )
                        );
                    }

                    if (columnHintDefaults.dateTimeDefaults) {
                        localStyleClasses = localStyleClasses.concat(
                            this.getColumnHintDefaultStyle(
                                columnHintDefaults.dateTimeDefaults,
                                DataType.DateTime
                            )
                        );
                    }
                }
                localStyleClasses = localStyleClasses.concat(
                    this.getColumnHintStyles(newColumnHints)
                );
                localStyleClasses.push(redIfNegativeExcelStyle);
            }
            if (this.indentPivotColumn) {
                // Get number of row group columns if columnApi is provided
                // Otherwise set it to 5 to cater a maximum of 5 levels of row group columns
                const columnApi = this.gridWrapper.getGridOptions().columnApi;
                const numRowGroupColumns = columnApi
                    ? columnApi.getRowGroupColumns().length
                    : DEFAUTL_PIVOT_INDENT;
                this.addPivotColumnStyles(numRowGroupColumns);
                this.indentPivotColumnStyles.forEach(style => {
                    localStyleClasses.push(style);
                });
            }
            if (this.gridWrapper.isUsingDra()) {
                const numericHintsToConvert = this.currentColumnHints.filter(
                    hint => hint.columnId && hint.hints.formatFunction
                );
                numericHintsToConvert.forEach(hint => {
                    if (hint.hints.formatFunction) {
                        const numericStyle: any = {
                            id: `dra-numeric-${hint.columnId}`,
                        };
                        numericStyle.numberFormat = {
                            // it comes back as ""##,###,###.##"" so we remove the extra "
                            format: hint.hints.formatFunction[3][0].replace(/"/g, ''),
                        };
                        localStyleClasses.push(numericStyle);
                    }
                });
            }
            const gridOptions = this.gridWrapper.getGridOptions();
            gridOptions.excelStyles = [...localStyleClasses];
            if (this.originalExcelStyles) {
                gridOptions.excelStyles = [...gridOptions.excelStyles, ...this.originalExcelStyles];
            }
        }
    }
    private getRowCustomisationStyles(highlights: RowCustomisation[]) {
        let excelStyles: any[] = [];

        /**
         * Column highlights need to be applied after row highlights so that they take precedence
         */
        const rowHighlights = highlights.filter(highlight => highlight.columnId === null);
        const columnHighlights = highlights.filter(highlight => highlight.columnId !== null);
        excelStyles = excelStyles.concat(this.getHighlightStyles(rowHighlights, highlights));
        excelStyles = excelStyles.concat(this.getHighlightStyles(columnHighlights, highlights));
        return excelStyles;
    }

    private getHighlightStyles(highlights: RowCustomisation[], allHighlights: RowCustomisation[]) {
        const styles: any[] = [];
        highlights.forEach(highlight => {
            const id = `row-customisation-${this.gridWrapper.getId()}-${allHighlights.findIndex(
                myHighlight => highlight === myHighlight
            )}`;
            const style: any = {};
            if (!stringHelper.isNullOrEmpty(highlight.style.backColor)) {
                style.interior = {
                    color: highlight.style.backColor,
                    pattern: 'Solid',
                };
            }
            style.font = {
                bold: highlight.style.fontStyle.bold,
                color: !stringHelper.isNullOrEmpty(highlight.style.foreColor)
                    ? highlight.style.foreColor
                    : '#000000',
                italic: highlight.style.fontStyle.italic,
                underline: highlight.style.fontStyle.underline ? 'Single' : '',
            };
            if (highlight.style.fontStyle.fontSize !== FontSizes.NotOveridden) {
                style.font.size = this.getFontSizePoint(highlight.style.fontStyle.fontSize);
            }

            styles.push({ id, ...style });
        });
        return styles;
    }

    private getColumnHintStyles(columnHints: ColumnHint[]) {
        const styles: any[] = [];
        columnHints.forEach((columnHint, i) => {
            const id = `${COLUMN_HINT_PREFIX}-${this.gridWrapper.getId()}-${i}`;

            const cssStyle = columnHintHelper.getStyles(columnHint.hints);
            if (Object.getOwnPropertyNames(cssStyle).length > 0) {
                const style = this.getExcelStyleFromColumnHintCssStyle(cssStyle);
                styles.push({ id, ...style });
            }
        });
        return styles;
    }

    private getColumnHintDefaultStyle(
        defaultColumnHints: ColumnHintDefaults,
        defaultType?: DataType
    ) {
        const styles: any[] = [];
        const id = `${DEFAULT_COLUMN_HINT_PREFIX}-${
            defaultType ? defaultType + '-' : ''
        }${this.gridWrapper.getId()}`;

        const cssStyle = columnHintHelper.getStyles(defaultColumnHints);
        if (Object.getOwnPropertyNames(cssStyle).length > 0) {
            const style = this.getExcelStyleFromColumnHintCssStyle(cssStyle);
            styles.push({ id, ...style });
        }

        return styles;
    }

    private getExcelStyleFromColumnHintCssStyle(cssStyle: CSSProperties) {
        const style: any = {};
        if (cssStyle.backgroundColor) {
            style.interior = {
                color: cssStyle.backgroundColor,
                pattern: 'Solid',
            };
        }
        style.font = {
            bold: cssStyle.fontWeight === 'bold',
            color: cssStyle.color ? cssStyle.color : '#000000',
            size: cssStyle.fontSize,
        };
        style.alignment = {
            horizontal: cssStyle.textAlign
                ? cssStyle.textAlign.charAt(0).toUpperCase() + cssStyle.textAlign.slice(1)
                : undefined,
        };
        return style;
    }
}
