/**
 * Inspired by https://github.com/KeesCBakker/Strongly-Typed-Events-for-TypeScript
 * The project has grown quite a lot since I used it and it brings a lot of cool feature that we don't really need for now.
 */

export interface GenericEvent<TSender, TArgs> {
    subscribe(fn: (sender: TSender, args: TArgs) => void): void;
    unsubscribe(fn: (sender: TSender, args: TArgs) => void): void;
}

export interface GenericSimpleEvent<TArgs> {
    subscribe(fn: (args: TArgs) => void): void;
    unsubscribe(fn: (args: TArgs) => void): void;
}

export class SimpleEventDispatcher<TArgs> implements GenericSimpleEvent<TArgs> {
    private subscriptions: ((args: TArgs) => void)[] = [];

    public subscribe(fn: (args: TArgs) => void): void {
        if (fn) {
            this.subscriptions.push(fn);
        }
    }

    public unsubscribe(fn: (args: TArgs) => void): void {
        const i = this.subscriptions.indexOf(fn);
        if (i > -1) {
            this.subscriptions.splice(i, 1);
        }
    }

    public dispatch(args: TArgs): void {
        for (const handler of this.subscriptions) {
            handler(args);
        }
    }

    public dispatchAsync(args: TArgs): void {
        for (const handler of this.subscriptions) {
            setTimeout(() => {
                handler(args);
            }, 1);
        }
    }

    public destroy() {
        this.subscriptions.length = 0;
    }
}
