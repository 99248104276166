import { Component, Fragment, ChangeEvent } from 'react';
import { FormGroup } from '@gs-ux-uitoolkit-react/form';
import { Input } from '@gs-ux-uitoolkit-react/input';
import { Label } from '@gs-ux-uitoolkit-react/label';
import { HintList } from '@gs-ux-uitoolkit-common/datacore';
import { FormatControlProps } from '../column-hint-format-manager';

export class ColumnHintControlCssClasses extends Component<FormatControlProps> {
    public render() {
        return (
            <Fragment>
                <FormGroup>
                    <Label>CSS Classes</Label>
                    <Input
                        value={this.props.columnHint.cssClasses || ''} // Remove alpha chars, i.e the px at the end
                        placeholder="e.g. bg-warning"
                        onChange={this.onCssClassesChanged}
                    />
                </FormGroup>
            </Fragment>
        );
    }

    private onCssClassesChanged = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        const clonedHint: HintList = {
            ...this.props.columnHint,
            cssClasses: value,
        };
        this.props.onChangeHint(clonedHint);
    };
}
