import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { FormFeedback, FormGroup } from '@gs-ux-uitoolkit-react/form';
import { Label } from '@gs-ux-uitoolkit-react/label';
import { Input } from '@gs-ux-uitoolkit-react/input';
import { Text } from '@gs-ux-uitoolkit-react/text';
import { Conditional } from '../../../../components/core/Conditional';
import { REPORTS_STATUS_CODES } from '../../../../constants/pageConstants';
import translator from '../../../../services/translator';

const { translate: t } = translator;

export const NewReportDetails = ({
    newReportDetails,
    onReportNameChange,
    onReportDescrChange,
    customReportCrudResponse
  }) => {
    const { reportName: newReportName, description } = newReportDetails;
    const status = get(customReportCrudResponse, 'status');

    const isSavedReportAlreadyExists =
      status === REPORTS_STATUS_CODES.REPORT_EXISTS;
    const isNewReportAlreadyExists =
      status === REPORTS_STATUS_CODES.NEW_REPORT_VIEW_EXISTS;
    const isReportNameInvalid =
      status === REPORTS_STATUS_CODES.REPORT_NAME_ERROR;
    const isReportDescError = status === REPORTS_STATUS_CODES.REPORT_DESC_ERROR;

    const isReportNameError =
      isSavedReportAlreadyExists ||
      isNewReportAlreadyExists ||
      isReportNameInvalid;
    return (
      <div className='report-fields'>
        <FormGroup status={isReportNameError ? 'error' : 'none'}>
          <Label>
            {t('tkReportName')}
            <Text color='destructive' style={{ marginLeft: '2px' }}>
              *
            </Text>
            <Input
              value={newReportName}
              placeholder={t('tkNameThisReport')}
              onChange={onReportNameChange}
              className={'report-fields__input'}
              required={true}
              maxLength={50}
              minLength={1}
              status={isReportNameError ? 'error' : 'none'}
            />
            <Conditional condition={isSavedReportAlreadyExists}>
              <FormFeedback>{t('tkReportAlreadyExists')}</FormFeedback>
            </Conditional>
            <Conditional condition={isNewReportAlreadyExists}>
              <FormFeedback>{t('tkNewReportAlreadyExists')}</FormFeedback>
            </Conditional>
            <Conditional condition={isReportNameInvalid}>
              <FormFeedback>{t('tkReportNameError')}</FormFeedback>
            </Conditional>
          </Label>
        </FormGroup>
        <FormGroup status={isReportDescError ? 'error' : 'none'}>
          <Label>
            {t('tkDescription')}
            <Input
              value={description}
              placeholder={t('tkEnterTheDescription')}
              onChange={onReportDescrChange}
              className={'report-fields__input'}
              maxLength={150}
              status={isReportDescError ? 'error' : 'none'}
            />
            <Conditional condition={isReportDescError}>
              <FormFeedback>{t('tkReportDescError')}</FormFeedback>
            </Conditional>
          </Label>
        </FormGroup>
      </div>
    );
  };
  
  NewReportDetails.propTypes = {
    newReportDetails: PropTypes.object,
    onReportNameChange: PropTypes.func,
    onReportDescrChange: PropTypes.func,
    customReportCrudResponse: PropTypes.object,
  };