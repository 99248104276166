import { cx } from '@gs-ux-uitoolkit-common/style';
import { TippyCssClasses, getTippyRootClasses } from '@gs-ux-uitoolkit-common/popover-base';
import { PopoverProps } from './popover-props';
import { PopoverCssClasses } from './popover-style-sheet';
import { PopoverTargetCssClasses } from './popover-target-style-sheet';

const globalClassName = 'gs-popover';

export const getPopoverRootClasses = (props: {
    cssClasses?: PopoverCssClasses;
    tippyCssClasses: TippyCssClasses;
    overrideClasses?: PopoverProps['classes'];
    className?: PopoverProps['className'];
}): string => {
    const { tippyCssClasses, className, overrideClasses } = props;
    return cx(
        getTippyRootClasses({ cssClasses: tippyCssClasses }),
        className,
        overrideClasses && overrideClasses.root,
        globalClassName
    );
};

export const getPopoverHeaderClasses = (props: {
    cssClasses: PopoverCssClasses;
    overrideClasses?: PopoverProps['classes'];
    className?: PopoverProps['className'];
}): string => {
    const { cssClasses, overrideClasses, className } = props;
    return cx(
        cssClasses.header,
        overrideClasses && overrideClasses.header,
        `${globalClassName}__header`,
        className
    );
};

export const getPopoverBodyClasses = (props: {
    cssClasses: PopoverCssClasses;
    overrideClasses?: PopoverProps['classes'];
    className?: PopoverProps['className'];
}): string => {
    const { cssClasses, overrideClasses, className } = props;
    return cx(
        cssClasses.body,
        overrideClasses && overrideClasses.body,
        `${globalClassName}__body`,
        className
    );
};

export const getPopoverCloseButtonClasses = (props: {
    cssClasses: PopoverCssClasses;
    overrideClasses?: PopoverProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.dismissButton,
        overrideClasses && overrideClasses.dismissButton,
        `${globalClassName}__dismiss-button`
    );
};

export const getPopoverTargetClasses = (props: {
    cssClasses: PopoverTargetCssClasses;
    overrideClasses: PopoverProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(cssClasses.target, overrideClasses?.target);
};

export const disableFocusOnAllChildren = (element: HTMLElement | Element) => {
    element.setAttribute('tabindex', '-1');
    return Array.from(element.children).forEach(disableFocusOnAllChildren);
};
