import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { AccordionEmphasis } from './accordion-props';
import { DeepReadonly } from 'ts-essentials';
import './accordion-theme-overrides';

export interface AccordionStyleSheetProps {
    theme: Theme;
    disabled?: boolean;
    emphasis?: AccordionEmphasis;
}

export interface AccordionCssClasses {
    root: string;
    accordion: string;
}

export type AccordionStyledClasses = CssClassDefinitionsObject<keyof AccordionCssClasses>;

export interface AccordionStyleOverridesParams {
    props: DeepReadonly<AccordionStyleSheetProps>;
    createDefaultStyledClasses: () => AccordionStyledClasses;
}

export const accordionStyleSheet = new StyleSheet(
    'accordion',
    (props: AccordionStyleSheetProps) => {
        return createComponentClassDefinitions<AccordionStyleSheetProps, AccordionStyledClasses>(
            props,
            createDefaultStyledClasses,
            props.theme.styleOverrides?.accordion
        );
    }
);

function createDefaultStyledClasses(): AccordionStyledClasses {
    return {
        root: {},
        accordion: {},
    };
}
