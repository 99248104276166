import React, { useCallback } from "react";
import PropTypes from 'prop-types';
import CheckBox from "../../../../../../components/core/CheckBox";


const dispatchEntityTypeAction = (dispatch, entityType, selected, additionalProp) => {
  dispatch({
     type: entityType === "taAccount" ? "TA_ACCOUNT_CHANGED" : "FUND_CHANGED",
     data: { isChecked: selected, ...additionalProp }
  })
}


const Entity = React.memo((props) => {
  const { entityMap, handleChange, selected, filterText, entityType } = props;

  const showAllCheckbox = () => {
    return (filterText === "" || !filterText) && entityMap.length > 0
  }

  const handleAllClick = useCallback((_value, selected) => {
    dispatchEntityTypeAction(handleChange, entityType, selected, {isAll: true});
  }, []);

  const handleCheckbox = useCallback((value, selected) => {
    dispatchEntityTypeAction(handleChange, entityType, selected, { value });
  }, [])


  return (
    <div>
      <ul className="filter-list">
        {showAllCheckbox() &&
          <li key={`${entityType}-all`} className="filter-item">
            <CheckBox
              changeHandler={handleAllClick}
              isChecked={
                Object.keys(selected).length === entityMap.length
              }
              value="all"
              label="All"
              id={`${entityType}-id`}
            />
          </li>
          // </div>
        }
        {
          entityMap.map(({label, value, order}) => {
            return (
              <li key={`${order}-${value}`} className="filter-item">
                <CheckBox
                  changeHandler={handleCheckbox}
                  isChecked={selected[value]}
                  label={label}
                  value={value}
                  id={`${order}-${value}`}
                />
              </li>
            )
          })
        }
      </ul>
    </div>
  )
});

Entity.propTypes = {
  entityMap: PropTypes.array,
  handleChange: PropTypes.func,
  selected: PropTypes.object,
  filterText: PropTypes.string,
  entityType: PropTypes.string
}

export default Entity;
