const Colors = ["#0d4ea6", "#e86427", "#279c9c", "#d9a514", "#ac71f0", "#23254d", "#52a31d", "#cc1d92", "#48b8f0", "#077d55"];

const DefaultChartConfig = {
    chart: {
      height: 575
    },
    rangeSelector: {
        selected: 1,
        inputPosition: {
            x: -250
        }
    },
    tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
        split: true,
        xDateFormat: '%d.%B.%Y'
    },
    legend: {
        enabled: true,
        labelFormatter: function() {
            const { chart, userOptions } = this;
            if (chart) {
                if (chart.dummyObject) {
                    return userOptions.fieldLabel
                } else {
                    return this.name;
                }
            }
            return "";
        },
        align: 'left',
        layout: "horizontal",
        itemMarginTop: 10,
        y: 0
    },
    credits: {
        enabled: false
    },
    yAxis: {
         title: false
    },
    series: []
};

export {
  Colors,
  DefaultChartConfig
};
