import { CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { StyleSheet } from '@gs-ux-uitoolkit-common/style';
import { MenuSize } from './menu-props';
import {
    Theme,
    createComponentClassDefinitions,
    ThemeTypographyVariant,
} from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './menu-header-theme-overrides';

export interface MenuHeaderStyleSheetProps {
    theme: Theme;
    size?: MenuSize;
}

export interface MenuHeaderCssClasses {
    root: string;
}

export type MenuHeaderStyledClasses = CssClassDefinitionsObject<keyof MenuHeaderCssClasses>;

export interface MenuHeaderStyleOverridesParams {
    props: DeepReadonly<MenuHeaderStyleSheetProps>;
    createDefaultStyledClasses: () => MenuHeaderStyledClasses;
}

export const menuHeaderStyleSheet = new StyleSheet(
    'menuHeader',
    (props: MenuHeaderStyleSheetProps) => {
        return createComponentClassDefinitions<MenuHeaderStyleSheetProps, MenuHeaderStyledClasses>(
            props,
            createDefaultStyledClasses,
            props.theme.styleOverrides?.menuHeader
        );
    }
);

function createDefaultStyledClasses({
    theme,
    size,
}: MenuHeaderStyleSheetProps): MenuHeaderStyledClasses {
    const color = theme.text.secondary;
    let headerVariant: ThemeTypographyVariant = 'heading07';
    let headerHeight = '32px';
    if (size === 'sm') {
        headerVariant = 'ui02';
        headerHeight = '24px';
    } else if (size === 'lg') {
        headerVariant = 'heading06';
        headerHeight = '40px';
    }
    return {
        root: {
            ...theme.typography[headerVariant],
            color,
            textTransform: 'uppercase',
            letterSpacing: '0.4px',
            margin: 0,
            height: headerHeight,
            lineHeight: headerHeight,
            display: 'block',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            padding: '0 16px',
            '&:focus': {
                outline: 'none',
            },
        },
    };
}
