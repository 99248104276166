import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Conditional} from '../../components/core/Conditional';
import { isLoading } from '../../selectors/pages';
import { ThreeSquaresLoader } from '../../components/core/Loaders';
import { addPageContext } from '../../actions/page';

const WithLoader = ({loading = true, isSkipInitialLoading = false, updateLoadingState, children }) => {

  useEffect(() => {
    if (!isSkipInitialLoading) {
      updateLoadingState(true);
    }

    return () => {
      if(loading === true){
        updateLoadingState(false);
      }
    };
  }, []);

  return (
    <Conditional condition={loading !== true && loading !== undefined}>
      {children}
      <ThreeSquaresLoader />
    </Conditional>
  );
};

WithLoader.propTypes = {
  loading: PropTypes.bool,
  updateLoadingState: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  isSkipInitialLoading: PropTypes.bool
};

const mapStateToProps = (state) => (
  {
    loading: isLoading(state)
  }
);
const mapDispatchToProps = dispatch => ({
  updateLoadingState: data => dispatch(addPageContext({isLoading: data}))
});

export default connect(mapStateToProps, mapDispatchToProps)(WithLoader);
