import { FunctionComponent, PropsWithChildren, useRef, useState, MutableRefObject } from 'react';
import { NoSsr } from '@gs-ux-uitoolkit-react/shared';
import {
    DragAndDropContextCommonProps,
    dragAndDropContextCommonPropsDefaults,
} from '@gs-ux-uitoolkit-common/drag-and-drop';

import { DragAndDropInternalContext } from './drag-and-drop-internal-context';
import { DragAndDropOnDragStart, useOnDragStartCallback } from './hooks/use-on-drag-start-callback';
import {
    DragAndDropOnDragUpdate,
    useOnDragUpdateCallback,
} from './hooks/use-on-drag-update-callback';
import { DragAndDropOnDragEnd, useOnDragEndCallback } from './hooks/use-on-drag-end-callback';
import { useRegisterRef } from './hooks/use-register-ref';
import { useUnregisterRef } from './hooks/use-unregister-ref';
import { ExternalDragAndDropContext } from './drag-and-drop-external';

export interface DragAndDropPlaceholderState {
    width: number;
    height: number;
    top: number;
    left: number;
    isPlaceholderVisible: boolean;
    droppableId: string;
}

export interface DragAndDropSizesCache {
    [key: string]: {
        left: number;
        top: number;
    }[];
}

export interface DragAndDropContextChildlessProps extends DragAndDropContextCommonProps {
    onDragStart?: DragAndDropOnDragStart;
    onDragUpdate?: DragAndDropOnDragUpdate;
    onDragEnd?: DragAndDropOnDragEnd;
}

export type DragAndDropContextProps = PropsWithChildren<DragAndDropContextChildlessProps>;

export const DragAndDropContext: FunctionComponent<DragAndDropContextProps> = ({
    children,
    onStateUpdate,
    direction = dragAndDropContextCommonPropsDefaults.direction,
    spacing = dragAndDropContextCommonPropsDefaults.spacing,
    placeholderDisabled,
    onDragStart,
    onDragUpdate,
    onDragEnd,
}) => {
    const refs = useRef<Record<string, MutableRefObject<HTMLElement | null>>>({});
    const [placeholderState, setPlaceholderState] = useState<DragAndDropPlaceholderState>({
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        isPlaceholderVisible: false,
        droppableId: '',
    });
    const sizesCache = useRef<DragAndDropSizesCache>({});

    const internalOnDragStart = useOnDragStartCallback(
        setPlaceholderState,
        refs,
        sizesCache,
        direction,
        placeholderDisabled,
        onDragStart
    );

    const internalOnDragUpdate = useOnDragUpdateCallback(
        setPlaceholderState,
        sizesCache,
        direction,
        placeholderDisabled,
        onDragUpdate
    );

    const internalOnDragEnd = useOnDragEndCallback(onStateUpdate, setPlaceholderState, onDragEnd);

    const registerRef = useRegisterRef(refs);
    const unregisterRef = useUnregisterRef(refs);

    return (
        <NoSsr>
            {/* solution to ssr https://github.com/atlassian/react-beautiful-dnd/issues/1533 */}
            <DragAndDropInternalContext.Provider
                value={{
                    registerRef,
                    unregisterRef,
                    placeholderState,
                    direction,
                    spacing,
                }}
            >
                <ExternalDragAndDropContext
                    onDragStart={internalOnDragStart}
                    onDragUpdate={internalOnDragUpdate}
                    onDragEnd={internalOnDragEnd}
                >
                    {children}
                </ExternalDragAndDropContext>
            </DragAndDropInternalContext.Provider>
        </NoSsr>
    );
};
