import { FC, CSSProperties, HTMLAttributes, memo } from 'react';
import PropTypes from 'prop-types';
import { Badge } from './badge';
import {
    badgeOverlayStyleSheet,
    badgeStyleSheet,
    BadgeOverlayProps as CommonBadgeOverlayProps,
    defaultBadgeOverlayProps,
    badgeColors,
    badgeStatuses,
    badgeSizes,
    badgeEmphases,
    badgeShapes,
    badgeOverlayPlacements,
    getBadgeOverlayRootClasses,
    getBadgeOverlayBadgeClasses,
} from '@gs-ux-uitoolkit-common/badge';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';

interface BadgeOverlayProps
    extends CommonBadgeOverlayProps<CSSProperties>,
        Omit<HTMLAttributes<HTMLElement>, 'color'> {}

/**
 * BadgeOverlay is a special type of Badge that can be overlaid visually over other components.
 * It is ideal for concise bits of information, especially numbers.
 */
const BadgeOverlay: FC<BadgeOverlayProps> = memo(props => {
    const {
        className,
        classes: overrideClasses,
        placement = defaultBadgeOverlayProps.placement,
        shape = defaultBadgeOverlayProps.shape,
        size = defaultBadgeOverlayProps.size,
        ...otherProps
    } = props;
    const theme = useTheme();
    const overlayClasses = useStyleSheet(badgeOverlayStyleSheet, props);

    const badgeProps = { size, shape, ...otherProps };
    // adds the padding-overlay class to override badge padding
    const badgeClasses = useStyleSheet(badgeStyleSheet, { theme, ...badgeProps });

    return (
        <div
            data-gs-uitk-component="badge-overlay"
            className={getBadgeOverlayRootClasses({
                className,
                overrideClasses,
                cssClasses: overlayClasses,
            })}
        >
            <Badge
                data-cy="gs-uitk-badge-overlay__badge"
                className={getBadgeOverlayBadgeClasses({
                    overrideClasses,
                    cssClasses: badgeClasses,
                })}
                {...badgeProps}
            />
        </div>
    );
});
BadgeOverlay.displayName = 'BadgeOverlay';

BadgeOverlay.propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf(badgeColors),
    status: PropTypes.oneOf(badgeStatuses),
    size: PropTypes.oneOf(badgeSizes),
    shape: PropTypes.oneOf(badgeShapes),
    emphasis: PropTypes.oneOf(badgeEmphases),
    placement: PropTypes.oneOf(badgeOverlayPlacements),
};

export { BadgeOverlay, type BadgeOverlayProps };
