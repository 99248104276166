import { useRef, useEffect, ReactNode, FC, AriaRole } from 'react';
import { CSSTransition } from 'react-transition-group';
import { TransitionProps } from 'react-transition-group/Transition';
import { cx, StyleSheet, useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { componentAnalytics } from './analytics-tracking';

function omit(obj: any, omitKeys: string[]) {
    const result: { [id: string]: string } = {};
    Object.keys(obj).forEach(key => {
        if (omitKeys.indexOf(key) === -1) {
            result[key] = obj[key];
        }
    });
    return result;
}

/**
 * Returns a filtered copy of an object with only the specified keys.
 */
function pick(obj: any, keys: string[]) {
    const pickKeys = Array.isArray(keys) ? keys : [keys];
    let length = pickKeys.length;
    let key;
    const result: { [id: string]: any } = {};

    while (length > 0) {
        length -= 1;
        key = pickKeys[length];
        result[key] = obj[key];
    }
    return result;
}

// Duplicated Transition.propType keys to ensure that Reactstrap builds
// for distribution properly exclude these keys for nested child HTML attributes
// since `react-transition-group` removes propTypes in production builds.
const TransitionPropTypeKeys = [
    'in',
    'mountOnEnter',
    'unmountOnExit',
    'appear',
    'enter',
    'exit',
    'timeout',
    'onEnter',
    'onEntering',
    'onEntered',
    'onExit',
    'onExiting',
    'onExited',
];

type CssTransitionClassNames =
    | 'appear'
    | 'appearActive'
    | 'appearDone'
    | 'enter'
    | 'enterActive'
    | 'enterDone'
    | 'exit'
    | 'exitActive'
    | 'exitDone';

const cssTransitionClassNames: CssTransitionClassNames[] = [
    'appear',
    'appearActive',
    'appearDone',
    'enter',
    'enterActive',
    'enterDone',
    'exit',
    'exitActive',
    'exitDone',
];

interface FadeStyleSheetProps {
    timeout: number;
}

const fadeStyleSheet = new StyleSheet('fade', ({ timeout }: FadeStyleSheetProps) => {
    return {
        appear: {
            opacity: 0,
        },
        appearActive: {
            opacity: 1,
            transition: `opacity ${timeout}ms linear`,
        },
        appearDone: {
            opacity: 1,
        },
        enter: {
            opacity: 0,
        },
        enterActive: {
            opacity: 1,
            transition: `opacity ${timeout}ms linear`,
        },
        enterDone: {
            opacity: 1,
        },
        exit: {
            opacity: 1,
        },
        exitActive: {
            opacity: 0,
            transition: `opacity ${timeout}ms linear`,
        },
        exitDone: {
            opacity: 0,
        },
    };
});

export interface FadeProps
    extends Partial<
        Pick<
            TransitionProps,
            | 'in'
            | 'mountOnEnter'
            | 'unmountOnExit'
            | 'appear'
            | 'enter'
            | 'exit'
            | 'timeout'
            | 'onEnter'
            | 'onEntering'
            | 'onEntered'
            | 'onExit'
            | 'onExiting'
            | 'onExited'
        >
    > {
    children?: ReactNode;
    baseClass?: string;
    baseClassActive?: string;
    className?: string;
    role?: AriaRole;
}

export type FadeDefaultProps = Required<
    Pick<
        FadeProps,
        'baseClass' | 'baseClassActive' | 'timeout' | 'appear' | 'enter' | 'in' | 'exit'
    >
>;

export const fadeDefaultProps: FadeDefaultProps = {
    baseClass: 'fade',
    baseClassActive: 'show',
    timeout: 150,
    appear: true,
    enter: true,
    in: true,
    exit: true,
};

export const Fade: FC<FadeProps> = props => {
    const ref = useRef(null);
    const {
        baseClass = fadeDefaultProps.baseClass,
        baseClassActive = fadeDefaultProps.baseClassActive,
        className,
        children,
        role,
        timeout = fadeDefaultProps.timeout,
        appear = fadeDefaultProps.appear,
        enter = fadeDefaultProps.enter,
        in: inProp = fadeDefaultProps.in,
        exit = fadeDefaultProps.exit,
        ...otherProps
    } = props;

    const transitionProps = {
        ...pick(otherProps, TransitionPropTypeKeys),
        ...{
            appear,
            enter,
            in: inProp,
            exit,
            timeout,
        },
    };
    const childProps = omit(otherProps, TransitionPropTypeKeys);
    useTheme(); // not consuming the theme just yet, but injects the fonts into the DOM
    const cssClasses = useStyleSheet(fadeStyleSheet, {
        timeout: transitionProps.timeout as number,
    });
    const transitionClassNames = pick(cssClasses, cssTransitionClassNames);

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'other' });
    }, []); // Only run once

    return (
        <CSSTransition
            nodeRef={ref}
            addEndListener={() => {}}
            classNames={transitionClassNames}
            {...transitionProps}
        >
            {status => {
                const isActive = status === 'entered';
                const classes = cx(baseClass, isActive && baseClassActive, className);
                return (
                    <div role={role} ref={ref} className={classes} {...childProps}>
                        {children}
                    </div>
                );
            }}
        </CSSTransition>
    );
};
