import React, { useEffect, useState } from 'react';
import {NavLink} from 'react-router-dom';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {EXTERNAL_LINK_SKIP_ATTRIBUTE} from '../../../constants/pageConstants';
import translator from "../../../services/translator";
import './index.scss';

const {translate: t} = translator;

const Stepper = ({stepperConfig, currentPageId, itemsCount}) => {
  const [currentTab, setCurrentTab] = useState(currentPageId);

  const onTabClick = (event, tab) => {
    if(currentPageId === tab.id) return;
    setCurrentTab(tab.id);
  };

  useEffect(() => {
    setCurrentTab(currentPageId);
  }, [currentPageId]);

  return (
    <div className="stepper">
      {
        stepperConfig.map((tab) => {
          const isTabMatched = (currentPageId === tab.id) || (currentTab === tab.id);
          const count = ((tab.label === t('tkNavMenuMyReports')) && itemsCount) ? `(${itemsCount})` : '';
          const tabLabel = `${tab.label} ${count}`;
          return (
            <NavLink
              exact={tab.exact}
              key={tab.routingPathId}
              to={{
                pathname: tab.path,
                state: {pageId: currentPageId || tab.id}
              }}
              onClick={(e) => onTabClick(e, tab)}
              isActive={() => isTabMatched}
              className={cn('stepper__text', 'stepper__tab', {'stepper__tab--disabled': isTabMatched})}
              activeClassName="stepper__tab--active"
              {...{[EXTERNAL_LINK_SKIP_ATTRIBUTE]: true}}
            >
              {tabLabel}
            </NavLink>
          );
        })
      }
    </div>
  );
};

Stepper.propTypes = {
  currentPageId: PropTypes.string,
  stepperConfig: PropTypes.object,
  itemsCount: PropTypes.string
};

export default Stepper;
