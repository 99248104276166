import { win } from '@gs-ux-uitoolkit-common/shared';

/**
 * Gets the application title
 */
export function getAppTitle() {
    return document.title;
}

/**
 * Attempts to find the application name
 *
 * Note that it looks for v3 style header classes so
 * this won't work for current UI Toolkit Header
 *
 * To be removed in v19
 */
export function getAppName() {
    return document
        .querySelector('.app-header .app-name h1')
        ?.innerHTML.replace(/<[^>]*>/g, '')
        .replace(/&nbsp;/g, ' ')
        .trim();
}

/**
 * A method that checks if the given hostname is an alias for a deployed
 * environment or a dev environment that can be ignored
 * @param  {[type]}  host [description]
 * @return {Boolean}      [description]
 */
export function isAlias(host: any) {
    const nonAliasPatterns = [
        /.*\.desktop\.services\.gs\.com/,
        /nds[0-9a-z]+\.firmwide\.corp\.gs\.com/,
        /localhost(\.gs\.com)?/,
        /.+\.dc\.gs\.com/,
    ];

    return !nonAliasPatterns.reduce((total: any, cur: any) => total || cur.test(host), null);
}

/**
 * Camelizes a given string
 * @param  {[type]} str [description]
 * @return {[type]}     [description]
 */
export function camelize(str: any) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match: any, index: any) => {
        if (+match === 0) {
            return ''; // or if (/\s+/.test(match)) for white spaces
        }
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
}

export function getOsName(): string {
    if (hasNameInUserAgent('windows')) return 'windows';
    if (hasNameInUserAgent('mac')) return 'mac';
    if (hasNameInUserAgent('android')) return 'android';
    if (hasNameInUserAgent('iphone') || hasNameInUserAgent('ipad')) return 'ios';
    return 'other';
}

export function getIsTouchDevice(): boolean {
    return (
        'ontouchstart' in win ||
        ((win as any).DocumentTouch && document instanceof win.DocumentTouch) ||
        navigator.maxTouchPoints > 0 ||
        win.navigator.maxTouchPoints > 0
    );
}

export function getVersionNumberParts(version: string): {
    major: number;
    minor: number;
    patch: number;
} {
    const match = version.match(/(\d+)\.(\d+)\.(\d+)/);

    return {
        major: (match && Number(match[1])) || 0,
        minor: (match && Number(match[2])) || 0,
        patch: (match && Number(match[3])) || 0,
    };
}

export function getBrowserName(): string {
    const { userAgent } = win.navigator;
    const userAgentString = userAgent.toLowerCase();
    let browserName = 'other';

    if (userAgentString.includes('firefox')) {
        browserName = 'firefox';
    } else if (userAgentString.includes('edg/') || userAgentString.includes('edge')) {
        browserName = 'edge';
    } else if (userAgentString.includes('chrome')) {
        browserName = 'chrome';
    } else if (userAgentString.includes('safari')) {
        browserName = 'safari';
    } else if (userAgentString.includes('trident')) {
        browserName = 'ie';
    }

    return browserName;
}
export function hasNameInUserAgent(name: string): boolean {
    const userAgentString = win.navigator.userAgent.toLowerCase();
    return userAgentString.includes(name.toLowerCase());
}
export function isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        win.navigator?.userAgent || ''
    );
}
