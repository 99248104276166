import { CSSProperties, HTMLAttributes, FC, useEffect, memo } from 'react';
import {
    overlayContainerStyleSheet,
    OverlayContainerProps as CommonOverlayContainerProps,
    getOverlayContainerClassNames,
} from '@gs-ux-uitoolkit-common/layout';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { componentAnalytics } from './analytics-tracking';

export interface OverlayContainerProps
    extends CommonOverlayContainerProps<CSSProperties>,
        HTMLAttributes<HTMLElement> {}

/**
 * OverlayContainer provides a context in which components that are designed to overlay
 * other components can be positioned.
 */
export const OverlayContainer: FC<OverlayContainerProps> = memo(props => {
    const { className, children, ...attributes } = props;

    const theme = useTheme();
    const cssClasses = useStyleSheet(overlayContainerStyleSheet, { theme });

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({
            componentName: 'overlay-container',
            officialComponentName: 'other',
        });
    }, []); // Only run once

    return (
        <div
            {...attributes}
            data-gs-uitk-component="overlay-container"
            className={getOverlayContainerClassNames({ cssClasses, className })}
        >
            {children}
        </div>
    );
});
OverlayContainer.displayName = 'OverlayContainer';
