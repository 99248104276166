import { useCallback, MutableRefObject } from 'react';

export type UseRegisterRefType = typeof useRegisterRef;

/**
 * Callback returned from the hook should be used to register reference to `<Droppable />` components.
 * This is needed for calculating the dimensions cache.
 */
export const useRegisterRef = (
    refs: MutableRefObject<Record<string, MutableRefObject<HTMLElement | null>>>
) => {
    return useCallback(
        (ref: MutableRefObject<HTMLElement | null>, id: string) => {
            refs.current[id] = ref;
        },
        [refs]
    );
};
