import { FC, memo } from 'react';
import { headingsStyleSheet } from '@gs-ux-uitoolkit-common/text';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { Heading, HeadingProps } from './heading';

/**
 * A wrapper around the Heading component that applies the expected margins for the <h5> tag
 */
export const H5: FC<HeadingProps> = memo(props => {
    const classes = useStyleSheet(headingsStyleSheet, null);
    return (
        <Heading
            typography="heading05"
            {...props}
            className={`${classes.h5 + (props.className ? ' ' + props.className : '')}`}
        >
            {props.children}
        </Heading>
    );
});
H5.displayName = 'H5';
