import React from 'react';
import cn from 'classnames';
import {isEmpty} from 'lodash';
import {cssClassConst} from './constants';
import translator from '../../../services/translator';
import {GROUP_TYPE, POLICY_STATUS, POLICY_STATUS_LABEL} from '../../../constants/preferences';
import {getStartingGroupByRowIndex} from './utils';
import {reportingAmountFormatter} from '../../../utils/amountFormatter';

const {translate: t} = translator;

const wsRowGroup = [
  GROUP_TYPE.SETTLEMENT_GROUP,
  GROUP_TYPE.SETTLEMENT_LOCATION,
];

const checkboxRenderer = (params) => {
  if (params.node.group && params.context && params.context.suppressGroupCheckbox) return;
  const className = params.node.data && !params.node.data[params.colDef.field] ?
      cssClassConst.checkboxDisabled: `ag-checkbox-checked ${cssClassConst.checkboxRenderer}`;
  return (
    <span className="ag-selection-checkbox">
      <span className={`ag-icon ${className}`} />
    </span>
  );
};

const excludeRenderer = (params) => {
  if (params.node.group && params.context && params.context.suppressGroupExclude)
    return;
  return <span className="ag-selection-exclude"><span className={`ag-icon ag-icon-exclude ${cssClassConst.checkboxRenderer}`} /></span>;
};

const linkRenderer = params => {
  const cellLabel = (params.hasOwnProperty('value')
    && (params.value !== undefined
      && params.value !== null
      && params.value !== '')) ? params.value : '--';
  return <a href="javascript:void(0);">{cellLabel}</a>;
};

const toleranceRenderer = params => {
  const cellValue = Object.prototype.hasOwnProperty.call(params, 'value') ? params.value : '';
  const label = POLICY_STATUS_LABEL[cellValue] || '--';
  const policyStatus = POLICY_STATUS[cellValue];
  const emptyStr = '';
  const classes = cn({
    'out-of-policy': policyStatus === POLICY_STATUS.OUT_OF_POLICY,
    'in-policy': policyStatus === POLICY_STATUS.IN_POLICY,
    [emptyStr]: (cellValue === '--')
  });
  return <span className={classes}>{t(label)}</span>;
};

const reportNameRenderer  = params => {
  const {data: {type} = {}} = params;
  const cellLabel = (params.hasOwnProperty('value')
    && (params.value !== undefined
      && params.value !== null
      && params.value !== '')) ? params.value : '--';
  const reportType = type || '';
  const reportTypeCls = type ? `release-phase-cls release-phase-${type.toLowerCase()}-cls` : '';

  return <a href="javascript:void(0);">
    {cellLabel} <span className={reportTypeCls}>{reportType}</span>
  </a>;
};

const customReportRemoveRenderer = () => {
  const removeLinkText = `x ${t('tkRemove')}`;
  return <a href="javascript:void(0);">{removeLinkText}</a>;
};

const highlightCellRenderer = params => {
  const { valueFormatted, value = '--', colDef } = params;
  const { searchTerm: highlight } = colDef;
  if (!highlight) {
    return valueFormatted;
  }
  const name = `${valueFormatted}` || `${value}`;
  if (!highlight.trim()) {
    return <span className="highlight-cellRenderer">{name}</span>;
  }
  const escapeRegExp = (str = '') => str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
  const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi');
  const parts = name.split(regex).filter(part => part);
  const result = [];
  for (let i = 0; i < parts.length; i++) {
    const cls = regex.test(parts[i]) ? 'green-text highlight-cellRenderer' : 'highlight-cellRenderer';
    result.push(<span key={i} className={`${cls}`}>{parts[i]}</span>);
  }
  return <div className="highlight-cellRenderer">{result}</div>;
};

const checkRowGroup = (data) => {
  return data.some((item) => wsRowGroup.includes(item.colId));
};

const wsInstructionRenderer = params => {
  const { value = '--', node, columnApi, eGridCell } = params;
  if (node && node.leafGroup && checkRowGroup(columnApi.getRowGroupColumns())) {
    const leafNodeRows = node.allLeafChildren;
    const data = !isEmpty(leafNodeRows) ? leafNodeRows[0].data : null;
    if (data) {
      const { aggData } = node;
      let { wireInstruction = '' } = data;
      const amountSplitter = wireInstruction.split('%');
      if (amountSplitter.length > 1) {
        const amount = aggData && aggData.tradeValue ? aggData.tradeValue : amountSplitter[1];
        amountSplitter[1] = Number(amount) < 0 ?
          <span style={{paddingLeft:'2px'}}>{reportingAmountFormatter(amount)}</span> : reportingAmountFormatter(amount);
      }
      wireInstruction = amountSplitter.join(' ');
      return (
        <div className="ws-instruction-renderer-parent">
          <p className="ws-instruction-renderer" dangerouslySetInnerHTML={{ __html: wireInstruction }} />
        </div>
      );
    }
  }
  if (eGridCell) {
    eGridCell.style.width = "auto"
  }

  return <div className="ag-react-container">{value}</div>;
};

const wsTradeValue = params => {
  const { node, value } = params;
  if (node && !node.group) {
    return value;
  }
  if (node && node.leafGroup && checkRowGroup(node.rowGroupColumn.getColId())) {
    const leafNodeRows = node.allLeafChildren;
    const data = ! isEmpty(leafNodeRows) ? leafNodeRows[0].data : null;
    if (data){
      const { aggData } = node;
      let amount = aggData && aggData.tradeValue ? aggData.tradeValue : 0;
      amount = Number(amount);
      const decimalMultiple = 10 * 2;
      return (Math.round(amount * decimalMultiple) / decimalMultiple).toFixed(2);
    }
  }
  return '';
};

const aggGroupValueRenderer = params => {
  const { node, colDef, valueFormatted, columnApi } = params;
  if (node && !node.group) {
    return valueFormatted;
  }

  const rowGroupColumns = columnApi.getRowGroupColumns();
  if (colDef && rowGroupColumns && colDef.aggGroupColums) {
    const rowIndex = getStartingGroupByRowIndex(rowGroupColumns, colDef.aggGroupColums);
    const {level} = node;
    if (rowIndex !== -1 && rowIndex <= level) {
      return valueFormatted;
    }
  }
  return '';
};

const dcssInstructionRenderer = params => {
  const { value = '--', node, eGridCell } = params;
  if (node && node.leafGroup) {
    const leafNodeRows = node.allLeafChildren;
    const data = ! isEmpty(leafNodeRows) ? leafNodeRows[0].data : null;
    if(data) {
      const { dcssInstruction = '' } = data;
      return (
        <div className='ws-instruction-renderer-parent'>
          <p className="ws-instruction-renderer" dangerouslySetInnerHTML={{ __html: dcssInstruction }} />
        </div>
      );
    }
  }
  if (eGridCell) {
    eGridCell.style.width = "auto"
  }
  return value === '' || value === null ? 0 : `${value}`;
};

const dctaTradeValueRenderer = (params) => {
  const { valueFormatted = '--', node } = params;
  return node.field === 'investmentCurrency' || !node.field ? valueFormatted : '--';
};

const gainLossSummaryDataRenderer = (params) => {
  const { value = '--', node, colDef, valueFormatted } = params;
  let renderValue = valueFormatted || value;
  const isDefaultGroupBy = node.field && node.field === GROUP_TYPE.ACCOUNT_ID;
  if (node && node.leafGroup && isDefaultGroupBy) {
    const leafNodeRows = node.allLeafChildren;
    const data = ! isEmpty(leafNodeRows) ? leafNodeRows[0].data : '';
    if(data) {
      const { summaryResponse = {} } = data;
        switch(colDef.field){
          case 'endingBalance' :
            renderValue = summaryResponse.endingBalance;
            break;
          case 'beginningBalance' :
            renderValue = summaryResponse.beginningBalance;
            break;
          case 'gainLoss':
            renderValue = summaryResponse.gainLoss;
            break;
          case 'tradeBalance':
            renderValue = summaryResponse.tradeBalance;
            break;
          case 'NAVTrading':
            renderValue = summaryResponse.NAVTrading;
            break;
          case 'shareBalance':
            renderValue = summaryResponse.shareBalance;
            break;
          default :
            renderValue = '';
            break;
        }
    }
  }
  return `${renderValue}`;
};

export default {
  checkboxRenderer,
  excludeRenderer,
  linkRenderer,
  toleranceRenderer,
  highlightCellRenderer,
  reportNameRenderer,
  customReportRemoveRenderer,
  wsInstructionRenderer,
  wsTradeValue,
  aggGroupValueRenderer,
  dcssInstructionRenderer,
  dctaTradeValueRenderer,
  gainLossSummaryDataRenderer
};
