import { zIndex, Elevation } from '@gs-ux-uitoolkit-common/design-system';
import { DEFAULT_BREAKPOINTS } from '@gs-ux-uitoolkit-common/layout';
import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { ModalPlacement, ModalSize } from './modal-props';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './modal-theme-overrides';

export interface ModalStyleSheetProps {
    theme: Theme;
    size: ModalSize;
    placement: ModalPlacement;
}

export interface ModalCssClasses {
    root: string;
    documentScrollLock: string;
    wrapper: string;
    backdrop: string;
    dialog: string;
    content: string;
}

export type ModalStyledClasses = CssClassDefinitionsObject<keyof ModalCssClasses>;

export interface ModalStyleOverridesParams {
    props: DeepReadonly<ModalStyleSheetProps>;
    createDefaultStyledClasses: () => ModalStyledClasses;
}

export const modalStyleSheet = new StyleSheet('modal', (props: ModalStyleSheetProps) => {
    return createComponentClassDefinitions<ModalStyleSheetProps, ModalStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.modal
    );
});

function createDefaultStyledClasses({
    theme,
    size,
    placement,
}: ModalStyleSheetProps): ModalStyledClasses {
    const modalElevation: Elevation = '05';
    let dialogWidth = '608px';
    if (size === 'sm') {
        dialogWidth = '400px';
    } else if (size === 'lg') {
        dialogWidth = '1024px';
    }
    const centeredStyles =
        placement === 'center'
            ? {
                  alignItems: 'center',
                  minHeight: 'calc(100% - 1rem)',
              }
            : {};

    return {
        wrapper: {
            position: 'relative',
        },
        documentScrollLock: {
            overflow: 'hidden',
            '#{root}': {
                overflowX: 'hidden',
                overflowY: 'auto',
            },
        },
        root: {
            boxSizing: 'border-box',
            position: 'fixed',
            top: 0,
            left: 0,
            padding: '40px 16px',
            zIndex: zIndex.modal,
            width: '100%',
            height: '100%',

            [`@media (min-width: ${DEFAULT_BREAKPOINTS.xs}px)`]: {
                paddingLeft: '16px',
                paddingRight: '16px',
            },
            [`@media (min-width: ${DEFAULT_BREAKPOINTS.sm}px)`]: {
                paddingLeft: '24px',
                paddingRight: '24px',
            },
            [`@media (min-width: ${DEFAULT_BREAKPOINTS.md}px)`]: {
                paddingLeft: '32px',
                paddingRight: '32px',
            },
        },
        dialog: {
            color: theme.text.primary,
            position: 'relative',
            maxWidth: '100%',
            display: 'flex',
            margin: '0 auto',
            pointerEvents: 'none',
            width: dialogWidth,
            ...centeredStyles,
        },
        backdrop: {
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: zIndex.modalBackdrop,
            width: '100vw',
            height: '100vh',
            backgroundColor: theme.colorScheme.overlay,
            opacity: 0.5,
            '> div': {
                transform: 'none',
                transition: 'transform .3s ease-out',
            },
        },
        content: {
            background: theme.elevation[modalElevation].background,
            boxShadow: theme.elevation[modalElevation].shadow,
            display: 'flex',
            flexDirection: 'column',
            pointerEvents: 'auto',
            position: 'relative',
            width: '100%',
        },
    };
}
