import { useState, useEffect, FC, memo } from 'react';
import PropTypes from 'prop-types';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import {
    getAriaLabel,
    getIconClassNames,
    getIconContentClassNames,
    iconStyleSheet,
    defaultIconProps,
    getIconCodePoint,
    resolveFontFamily,
    resolveIconName,
    iconSizes,
    IconRequiredProps,
} from '@gs-ux-uitoolkit-common/icon-font';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { IconProps } from './props';
import { componentAnalytics } from './analytics-tracking';

/**
 * Icons are graphical representations of actions, entities or concepts.
 */
export const Icon: FC<IconProps> = memo(props => {
    const {
        name,
        type = 'outlined',
        size = defaultIconProps.size,
        spin = false,
        pulse = false,
        className,
        classes: overrideClasses,
        ...otherProps
    } = props;

    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        setHasMounted(true);
    }, []);

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'icon' });
    }, []); // Only run once

    let resolvedName = name;
    resolvedName = resolveIconName(name);

    const theme = useTheme();
    const fontFamily = resolvedName ? resolveFontFamily(props) : '';
    const cssClasses = useStyleSheet(iconStyleSheet, {
        theme,
        fontFamily,
        size,
        type,
        spin,
        pulse,
        loading: !hasMounted,
    });
    if (!resolvedName) {
        return (
            <span data-gs-uitk-component="icon">
                <span data-cy="gs-uitk-icon__content"></span>
            </span>
        );
    }
    const iconContent = getIconCodePoint({
        name: resolvedName,
        type,
    } as IconRequiredProps);
    const ariaLabel =
        'aria-label' in otherProps ? otherProps['aria-label'] : getAriaLabel(resolvedName);

    const rootClass = getIconClassNames({
        name: resolvedName,
        cssClasses,
        className,
        overrideClasses,
    });

    return (
        <span
            role="img"
            aria-label={ariaLabel}
            {...otherProps}
            data-gs-uitk-component="icon"
            data-size={size}
            data-type={fontFamily === 'CustomIcons' ? 'custom' : type}
            data-spin={spin}
            data-pulse={pulse}
            className={rootClass}
        >
            <span
                data-cy="gs-uitk-icon__content"
                className={getIconContentClassNames({ cssClasses, overrideClasses })}
                role="img"
                aria-hidden="true"
            >
                {iconContent}
            </span>
        </span>
    );
});
Icon.displayName = 'Icon';

Icon.propTypes = {
    name: PropTypes.string.isRequired as any, // TS gets confused from the Icon prop filled | outlined
    type: PropTypes.string.isRequired as any,
    size: PropTypes.oneOf(iconSizes),
    spin: PropTypes.bool,
    pulse: PropTypes.bool,
    className: PropTypes.string,
};
