import moment from 'moment-timezone';
import get from 'lodash/get';
import {
  printList,
  sortList,
  formatToExcelRowData,
  handleHtml,
} from '../../utils/exportFormatter';
import { nameFormatter } from '../../utils/portfolioGridFormatter';

/**
 * retrieves the list of selected firms to be displayed in the footer
 */
const getSelectedFirms = (hideSelectedFirm, selectedFirms, labels) => {
  if (!hideSelectedFirm) {
    const firms = [];
    selectedFirms.forEach((firm) => {
      firms.push(`${firm.name} - ${firm.firmCode}`);
    });
    const commaSeparatedFirmList = firms.join(', ');
    return [
      {
        value: selectedFirms
          ? `${labels.tkGlobalFirmFilter} : ${commaSeparatedFirmList}`
          : null,
        mergeAcross: selectedFirms ? 6 : 0,
      },
    ];
  }
  return [];
};

/**
 * retrieves the grid configurations to be displayed in the footer
 */
const getGridConfigurations = ({
  selectedManagers,
  openAccountsOnly,
  institutionalShareClassOnly,
  includeZeroBalancePositions,
  labels,
  selectedFirms,
}) => {
  const config = {
    value: labels.tkGridConfigurations,
    mergeAcross: 2,
    bold: true,
  };
  const gridConfig = [];

  if (includeZeroBalancePositions != null) {
    gridConfig.push({
      value: `${labels.tkZeroBalanceFunds}: ${
        includeZeroBalancePositions ? labels.tkShown : labels.tkHidden
      }`,
      mergeAcross: selectedFirms ? 3 : 0,
    });
  }
  if (selectedManagers != null) {
    gridConfig.push({
      value: `${labels.tkManagerColon}: ${selectedManagers}`,
    });
  }
  if (openAccountsOnly != null) {
    gridConfig.push({
      value: `${labels.tkOpenAccountsOnly}: ${
        openAccountsOnly ? labels.tkShown : labels.tkHidden
      }`,
    });
  }
  if (institutionalShareClassOnly != null) {
    gridConfig.push({
      value: `${labels.tkShareClassOnly}: ${
        institutionalShareClassOnly ? labels.tkShown : labels.tkHidden
      }`,
    });
  }

  return gridConfig.length ? [config, ...gridConfig] : [];
};

/**
 * Sets up the configuration required for footer content to be used in excel export.
 * Note that this is a variation of `exportFooterConfig` and is built for Grid
 */
export const excelExportFooterConfiguration = (config) => {
  const {
    disclaimer = [],
    userInformation: userInfo,
    filterByMappedData: filterBy = [],
    columnSortingStateMappedData: sortBy = [],
    selectedFirms = [],
    includeZeroBalancePositions,
    labels = {},
    selectedManagers,
    openAccountsOnly,
    institutionalShareClassOnly,
    hideSelectedFirm = false,
  } = config;

  // safely retrieve user information
  const firstName = get(userInfo, 'firstName');
  const lastName = get(userInfo, 'lastName');
  const timeZone = get(userInfo, 'userTimeZone.timeZone', null);
  const timeZoneId = get(userInfo, 'userTimeZone.zoneId', null);
  const formattedUserName = nameFormatter(`${firstName} ${lastName}`, '');
  const momentTime = timeZone ? moment().tz(timeZone) : moment();
  const createdByInfo = `${labels.tkCreatedBy}: ${formattedUserName} ${labels.tkon} ${momentTime.format(
    'DD.MMMM.YYYY h:mm A'
  )} ${timeZoneId}`;

  const footerLines = [
    {
      value: userInfo ? createdByInfo : null,
      mergeAcross: userInfo ? 5 : 0,
    },
    ...getSelectedFirms(hideSelectedFirm, selectedFirms, labels),
    ...getGridConfigurations({
      selectedManagers,
      openAccountsOnly,
      institutionalShareClassOnly,
      includeZeroBalancePositions,
      labels,
      selectedFirms,
    }),
    {
      value: labels.tkColumnConfigurations,
      mergeAcross: 2,
      bold: true,
    },
    {
      value: `${labels.tkFilter}: ${
        filterBy.length > 0 ? printList(filterBy, 'term') : `${labels.tkNoFirstLetterCapital} ${labels.tkFilter}`
      }`,
      mergeAcross: filterBy.length > 0 ? 5 : 0,
    },
    {
      value: `${labels.tkSort}: ${sortList(sortBy)}`,
      mergeAcross: 2,
    },
    { value: '' },
  ];

  const footer = [{ cells: [] }];
  footer.push(...formatToExcelRowData(footerLines));
  disclaimer.forEach((section) => {
    section && section.trim() && footer.push(...handleHtml(section));
  });

  return footer;
};
