export default {
  FETCH_USER_MESSAGES: {
    'path': '/api/v1/messageCenter/retrieve',
    'method': 'POST',
    'parameters': {
      'user.id': 'id'
    }
  },
  UPDATE_USER_MESSAGES: {
    'path': '/api/v1/messageCenter/update',
    'method': 'POST'
  }
}
