import { kebabCase } from '@gs-ux-uitoolkit-common/shared';
import { CssProperties, CssPropertiesWithLabel } from '../types';

/**
 * Given the set of final CSS Properties, the StyleSheet name, and the CSS
 * class name, this function add a 'label' property so that we generate a
 * human-readable CSS class name in the DOM. Emotion reads this `label` property
 * in order to do so. For example:
 *
 *     @import { css } from 'emotion';
 *
 *     // CSS class without a label
 *     const class1 = css({ color: 'blue' });  // "css-12y2ba3"
 *
 *     // CSS class with a label
 *     const class1 = css({ label: 'alert', color: 'blue' });  // "css-12y2ba3-alert"
 *
 * We generate the `label` property in this function as a combination of the {@link StyleSheet#name}
 * and the CSS class name. Example:
 *
 *     applyClassLabel('alert', 'root', {
 *         color: 'blue',
 *         backgroundColor: 'green'
 *     });
 *
 *     // Returned object:
 *     {
 *         color: 'blue',
 *         backgroundColor: 'green',
 *         label: '-alert-root'
 *     }
 *
 * This functionality could be done inline with other code, but this function is
 * convenient in that it returns the correct CssPropertiesWithLabel type.
 */
export function applyClassLabel(
    styleSheetName: string,
    className: string,
    cssProperties: CssProperties
): CssPropertiesWithLabel {
    return {
        ...cssProperties,
        label: createLabel(styleSheetName, className),
    };
}

function createLabel(styleSheetName: string, className: string): string {
    // If the styleSheetName is an empty string, don't prefix an extra '-' char.
    // Otherwise, prepending a '-' so that the class comes out as, for example:
    //   'gs-uitk-c-[hash]--alert'
    const prefix = !styleSheetName ? '' : `-${kebabCase(styleSheetName)}`;

    return `${prefix}-${kebabCase(className)}`;
}
