import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@gs-ux-uitoolkit-react/modal';
import { Button } from '@gs-ux-uitoolkit-react/button';
import PropTypes from 'prop-types';
import { ModalTabs } from './ModalTabs';
import translator from '../../../../../../services/translator';
import './index.scss';

const { translate: t } = translator;

const ChangeManagementDetailsModal = ({ closeModal }) => {
  return (
    <Modal visible={true} placement='center' className='changemanagement-modal gs-uitk-override-css'>
      <ModalHeader onDismissButtonClick={closeModal} className='changemanagement-modal__header' data-testid='changemanagement-modal'>
        {t('tkChangeManagementDetails')}
      </ModalHeader>
      <ModalBody className='changemanagement-modal__body'>
        <ModalTabs />
      </ModalBody>
      <ModalFooter className='changemanagement-modal__footer'>
        <Button actionType='primary' onClick={closeModal}>
          {t('tkOk')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ChangeManagementDetailsModal.propTypes = {
  closeModal: PropTypes.func,
};

export default ChangeManagementDetailsModal;
