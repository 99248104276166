import { FormSize, FormStatus } from './form-types';

export interface FormContext {
    containedByInputGroup?: boolean;
    disabled?: boolean;
    inline?: boolean;
    size?: FormSize;
    status?: FormStatus;
}

// All default values are deliberately undefined.
export const defaultFormContext: FormContext = {
    containedByInputGroup: false,
};

// This updates the context but, unlike with the ... operator, it does not
// allow `undefined` to overwrite a non-`undefined` value.
export function updateContext(inputs: FormContext[]): FormContext {
    const ctx: { [key: string]: boolean | FormStatus } = {};
    const keys = ['containedByInputGroup', 'disabled', 'inline', 'size', 'status'];
    inputs.forEach((input: { [key: string]: any }) => {
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (input[key] != null) {
                ctx[key] = input[key];
            }
        }
    });
    return ctx;
}
