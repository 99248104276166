import { colors } from '@gs-ux-uitoolkit-react/design-system';

// TODO Remove v16, this is not used anymore, but kept for backward compatibility
/**
 * Colors used by the charting libraries in the Toolkit. At the moment, these
 * include ag-Chart and Highcharts
 */
// TODO: Should be deleted in favour of using dataviz tokens from Theme package
export const datavizColors: string[] = [
    colors.blue070,
    colors.orange050,
    colors.teal050,
    colors.yellow040,
    colors.purple050,
    colors.ultramarine090,
    colors.lime050,
    colors.pink060,
    colors.aqua040,
    colors.green060,
];
