import { Component, PropsWithChildren } from 'react';

export interface ColumnHintFormatSectionProps {
    title: string;
}

export class ColumnHintFormatSection extends Component<
    PropsWithChildren<ColumnHintFormatSectionProps>
> {
    public render() {
        return (
            <div className="column-hint-format-section">
                <h5>{this.props.title || 'Unknown'}</h5>
                <div>{this.props.children}</div>
            </div>
        );
    }
}
