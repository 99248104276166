import { StyleSheet } from '@gs-ux-uitoolkit-react/style';
import { Theme } from '@gs-ux-uitoolkit-react/theme';

export const checkboxStylesheet = new StyleSheet(
    'checkbox-quickfilter',
    ({ theme }: { theme: Theme }) => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        checkbox: {
            paddingTop: '3px',
            color: theme.text.primary,
        },
        secondaryLabel: {
            paddingRight: '10px',
            color: theme.text.secondary,
        },
    })
);
