import { ComponentProps, CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { Breakpoints } from './breakpoints';
import { Grid } from './grid';
import { LayoutDensity } from './layout-density';

export interface ContainerProps<StyleType = CommonStyleType> extends ComponentProps<StyleType> {
    /**
     * Allows custom breakpoints to be set for extra small, small, medium, large,
     * and extra large viewport widths. The default breakpoints conform to the
     * Goldman Sachs Design System and define a maximum width of 600 pixels for
     * an extra small viewport, 768 for small, 1024 for medium, and values above
     * 1024 as large.
     */
    breakpoints?: Breakpoints;

    /**
     * Margins, gutters, and number of columns for each breakpoint can be
     * customized. The default grid conforms to Goldman Sachs Design System.
     */
    grid?: Grid;

    /**
     * Condenses the margins and gutters between rows and columns. If a custom
     * value for {@link #grid} is defined, it takes precedence over this setting.
     */
    density?: LayoutDensity;

    /**
     * Sets the maximum width (in pixels) that the container will resize to.
     * When the viewport is narrower than this width, the container will resize
     * fluidly and responsively. To resize fluidly no matter the viewport width,
     * set maxWidth either to Number.POSITIVE_INFINITY or 0. The default value is 1280.
     */
    maxWidth?: number;

    /**
     * Removes margins and gutters from between rows and columns.
     */
    noGutters?: boolean;
}

export const defaultContainerProps: DefaultContainerProps = {
    density: 'standard',
    maxWidth: 1280,
    noGutters: false,
};

export type DefaultContainerProps = Required<
    Pick<ContainerProps, 'density' | 'maxWidth' | 'noGutters'>
>;
