import translator from '../../../../services/translator';

const { translate: t } = translator;

export const decideHelpGuideArray = (helpGuideConfig, currentTab, internalUser) => {
  const currentTabHelpGuideConfig = (helpGuideConfig && helpGuideConfig[currentTab.toLowerCase()]) || [];
  const helpGuideArray = internalUser
    ? currentTabHelpGuideConfig.sort((firstItem, secondItem) => firstItem.index - secondItem.index)
    : currentTabHelpGuideConfig.filter(item => !('internalUserField' in item)).sort((firstItem, secondItem) => firstItem.index - secondItem.index);
  return helpGuideArray.map(item => ({ ...item, fieldName: t(item.fieldName) }));
};
