export const INVEST_ONLY = "investOnlyType";
export const INPUT = "input";
export const DROPDOWN = "dropdown";
export const TRUIST_DOMAIN = "truist-ui";
export const INVEST_ONLY_MAPPER = { N: "None", C: "Check", W: "Wire" };
export const ACCOUNT_TYPE = "accountType";
export const ACCOUNT_TYPE_MAPPER = {
  NONE: "None",
  CHECKING: "Checking",
  SAVINGS: "Savings",
};
export const NONE = 'NONE';
export const regexForInputs = /^[A-Za-z0-9\s-.&#,*_[\]/]*$/;

