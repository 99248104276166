import { logger } from '@gs-ux-uitoolkit-common/datacore';
import * as Redux from 'redux';

/**
 * Logs every redux actions dispatched. Will probably be removed
 * @param api The store api
 */
export const loggerMiddleware: Redux.Middleware = api => next => action => {
    logger.debug('Redux store dispatching ' + action.type, action);
    const result = next(action);
    logger.debug('Redux store next state', api.getState());
    return result;
};
