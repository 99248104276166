import { CrossModel } from '../cross-model-state';
import { CrossModelActionKeys } from './action-constants';

export type CrossModelActionTypes =
    | AddCrossModelAction
    | RemoveCrossModelAction
    | UpdateCrossModelAction;

export interface RemoveCrossModelAction {
    type: CrossModelActionKeys.REMOVE_CROSS_MODEL;
    crossModelKey: string;
}
export const RemoveCrossModel = (crossModelKey: string): RemoveCrossModelAction => ({
    crossModelKey,
    type: CrossModelActionKeys.REMOVE_CROSS_MODEL,
});

export interface AddCrossModelAction {
    crossModelKey: string;
    crossModel: CrossModel;
    type: CrossModelActionKeys.ADD_CROSS_MODEL;
}
export const AddCrossModel = (
    crossModelKey: string,
    crossModel: CrossModel
): AddCrossModelAction => ({
    crossModel,
    crossModelKey,
    type: CrossModelActionKeys.ADD_CROSS_MODEL,
});

export interface UpdateCrossModelAction {
    crossModelKey: string;
    crossModel: CrossModel;
    newCrossModelKey?: string;
    type: CrossModelActionKeys.UPDATE_CROSS_MODEL;
}
export const UpdateCrossModel = (
    crossModelKey: string,
    crossModel: CrossModel,
    newCrossModelKey?: string
): UpdateCrossModelAction => ({
    crossModel,
    crossModelKey,
    newCrossModelKey,
    type: CrossModelActionKeys.UPDATE_CROSS_MODEL,
});
