import actionTypes from '../../../actions/page/fundTracker/actionTypes';

export function fundTrackerPageDataReducer(state = {}, action) {
  const { type, data = {} } = action;
  switch (type) {
    case actionTypes.IS_FUND_TRACKER_DATA_LOADING: {
      return { ...state, isFundTrackerDataLoading: data};
    }
    case actionTypes.IS_PRINTING_IN_PROGRESS: {
      return { ...state, isPrintingInProgress: data};
    }
    case actionTypes.THRESHOLDS_DELETED: {
      return { ...state, thresholdsDeleted: data};
    }
    default:
      return state;
  }
}
