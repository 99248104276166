import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import logger from 'redux-logger';
import debounce from 'lodash/debounce';
import createSagaMiddleware from 'redux-saga';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { batchedSubscribe } from 'redux-batched-subscribe';
import initialState from './initialState';
import reducers from '../reducers';
import constants from '../constants/appConstants';

const getStore = (history, sagaModule) => {
  const sagaMiddleware = createSagaMiddleware();

  // Build the middleware for intercepting and dispatching navigation actions
  const middleware = [];
  middleware.push(routerMiddleware(history));
  middleware.push(sagaMiddleware);

  if(process.env.NODE_ENV.toLowerCase() !== constants.PRODUCTION.toLowerCase()){
    middleware.push(logger);
  }
  const devMode = process.env.NODE_ENV === 'development';
  const allReducers = combineReducers({
    ...reducers,
    router: connectRouter(history)
  });
  const debounceNotify = debounce((notify) => notify());
  const store = configureStore({
    reducer: allReducers,
    devTools: devMode,
    middleware: () => [
      ...middleware
    ],
    preloadedState: initialState,
    enhancers: (getDefaultEnhancers) =>
      getDefaultEnhancers({autoBatch: false,}).concat(batchedSubscribe(debounceNotify))
  });
  sagaMiddleware.run(sagaModule);
  return store;
};

export default getStore;
