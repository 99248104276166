type LogLevel = 'error' | 'warn' | 'info' | 'debug';
const LOG_LEVELS: LogLevel[] = ['error', 'warn', 'info', 'debug'];

//Log level by default is warn.

function debug(...args: any[]) {
    if (globalLogLevelIsAtLeast('debug')) {
        console.log(...args); //using .log() rather than .debug() to avoid having to also set level "Verbose" in Chrome.
    }
}

function info(...args: any[]) {
    if (globalLogLevelIsAtLeast('info')) {
        console.log(...args);
    }
}

function warn(...args: any[]) {
    if (globalLogLevelIsAtLeast('warn')) {
        console.warn(...args);
    }
}

function error(...args: any[]) {
    if (globalLogLevelIsAtLeast('error')) {
        console.error(...args);
    }
}

export const Logger = { debug, info, warn, error };

/**
 * Determines if the given `logLevel` meets the global log level defined by
 * `window.UITOOLKIT_LOG_LEVEL` (or the default of 'warn').
 *
 * We use this to determine whether a debug/log/warn/error statement should be output or
 * not.
 *
 * @param logLevel
 */
function globalLogLevelIsAtLeast(logLevel: LogLevel): boolean {
    let globalLogLevel: LogLevel = 'warn'; // default is 'warn'

    // Note: `typeof window` check needed for SSR (Server-side Rendering) environments where
    // `window` doesn't exist
    const win = typeof window === 'undefined' ? undefined : (window as any);
    if (win && win.UITOOLKIT_LOG_LEVEL) {
        globalLogLevel = win.UITOOLKIT_LOG_LEVEL.toLowerCase();
    }

    return LOG_LEVELS.indexOf(globalLogLevel) >= LOG_LEVELS.indexOf(logLevel);
}
