/* eslint-disable */
import React, {useState, useEffect, useReducer, useRef, useCallback} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import _ from 'lodash';
import translator from '../../../services/translator';
import {useFormInput, useSetup, useToggleParentSelection} from './customHooks';
import {useDocumentClick, useWindowScroll} from '../../../utils/customHooks';
import {getPosition} from '../../../utils/dom';
import dropdownReducer from './dropdownReducer';
import {withScroll} from '../Scrollbar';
import BodyEnd from '../BodyEnd';
import List from './List/index';
import FooterCTA from './Footer';
import './index.scss';

const {translate: t} = translator;

const KEYS = {
  UP : 38,
  DOWN : 40,
  ENTER : 13,
};

const Dropdown = (props) => {

  const {options, id,multiSelect, prepender, appender, placeholder, type, labelTemplate,
    searchFilter, disabled, fixedMenu, openTop, footerCTAType, footerCTA, footerCTAText,
    footerCTADisabled, indentationPadding, width, selectParentOnAny} = props;
  const dropdownRef = useRef();
  const menuRef = useRef();

  const [label , setLabel] = useState('');
  const position = fixedMenu && getPosition(dropdownRef.current);
  const [offset, setOffset] = useState(position);
  const [state, dispatch]  = useReducer(dropdownReducer, { expanded: false, currentIndex: 0, length: 0, items: [],lastSelectedItem : {}, lastAppliedOptionsState : options, id});
  const {length, items, expanded, currentIndex, lastSelectedItem, lastAppliedOptionsState} = state;

  const filter = useFormInput('');


  useSetup(options, id, dispatch );
  useToggleParentSelection(lastSelectedItem, items, selectItem, deSelectItem, selectParentOnAny);

  useDocumentClick((event)=> {
    if (expanded) {
      const clickedOnComponent = dropdownRef && dropdownRef.current.contains(event.target)
        || menuRef && menuRef.current.contains(event.target);
      clickedOnComponent ? '' : closeMenu();
    }
  }, id);

  useWindowScroll((event)=> {
    if(expanded) {
      const pos = getPosition(dropdownRef.current);
      setOffset(pos);
    }
  }, [expanded]);

  const elementHeight = dropdownRef.current && dropdownRef.current.offsetHeight || 0;
  const menuWidth = menuRef.current && menuRef.current.offsetWidth || 0;
  /*
  * following useEffect function is used to set label once only first time when component renders
  * */
  useEffect(() => {
    populateSelected();
  }, [length, items]);

  /*
  * following useEffect function is used as callback onChange of lastAppliedOptionsState
  * */
  useEffect(() => {
    closeMenu();
  }, [lastAppliedOptionsState]);


  function selectItem  (id) {
    toggleItemSelection(id, true);
  }

  function deSelectItem (id) {
    toggleItemSelection(id, false);
  }

  /*
  * returns list of all selected items
  * */
  const getSelectedItems = (listItems) => {
    return items.filter((item)=> item.selected);
  };

  const populateSelected = () => {
    const text = formatLabel();
    setLabel(text);
  };

  /*
  * 1. format the display label.
  * 2. you can pass your own "labelTemplate" callback function
  * for custom formatting
  * */
  const formatLabel = () => {
    const selectedItems =  getSelectedItems();
    if(selectedItems.length <= 0) return '';
    if(typeof labelTemplate === 'function'){
      return labelTemplate(selectedItems, items);
    } else if(selectedItems.length > 1 && appender) {
      return`${prepender} ${selectedItems.length} ${appender}` ;
    } else {
      return `${prepender} ${selectedItems[0].label}`;
    }
  };

  const getItemData = (id) => {
    return _.find(items, { 'id': id});
  };

  /*
  * toggle the item selection the item based on based on action performed
  * id: id of the item
  * state: (true || false)
  * update the selected items list in state
  * */
  const toggleItemSelection = (id, state) => {
    const listItems =  _.map(items, (item) => {
      if(multiSelect){
        return item.id === id ? _.extend(item, {selected: state}) :  _.extend(item, {selected: item.selected});
      }
      return item.id === id ? _.extend(item, {selected: true}) :  _.extend(item, {selected: false});
    });
    dispatch({type: 'UPDATE_ITEMS', items: listItems});
  };

  const reset = ()=>{
    const listItems = lastAppliedOptionsState.map((item) => ({...item}));
    dispatch({type: 'UPDATE_ITEMS', items: listItems});
  };

  /*
  * select item on keyboard enter
  * */
  const enter = () =>{
    const elId = `${id}-item-${currentIndex}`;
    selectItem(elId);
    closeMenu();
  };

  const defaultSelection = () => {
    const selectedItem = getSelectedItems()
    selectItem(selectedItem[0].id);
  }

  /*
  * handle the key board events
  * allow to navigate dropdown items using keyboard arrow keys
  * */
  const onKeyDown =(event) => {
    const keyCode = event.keyCode && event.keyCode || event.which;
    const {UP, DOWN, ENTER} = KEYS;
    switch(keyCode){
      case UP:
        dispatch({type: "GOTO", direction: "UP", length});
        break;
      case DOWN:
        dispatch({type: "GOTO", direction: "DOWN", length});
        break;
      case ENTER:
        enter();
        break;
      default:
        defaultSelection();
        break;
    }
    // prevent default is required for stoping
    // window.event.preventDefault();
    (event.preventDefault) ? event.preventDefault() : event.returnValue = false;
    event.stopPropagation();
    return false;

  };

  /*
  * open and close dropdown list
  * */
  const toggleMenu = (event) => {
    // event.stopPropagation();
    expanded ?  closeMenu() : openMenu();
  };

  /*
  * dispatch action to expand the dropdown
  * set state expanded=true
  * */
  const openMenu = () => {
    setOffset(getPosition(dropdownRef.current));
    dispatch({type: 'EXPAND'});
  };

  /*
  * dispatch action to expand the dropdown
  * set state expanded=false
  * */
  const closeMenu = ()=> {
    multiSelect && reset();
    dispatch({type: 'COLLAPSE'});
  };


  const handleEdit = (event, item) => {
    const {value} = item;
    props.onEdit(value, item);
    closeMenu();
  };

  const handleAdd = (event) => {
    const item = getSelectedItems();
    props.onAdd(item);
    closeMenu();
  };

  const handleSelect = (event, item) => {
    const {id, value} = item;
    selectItem(id);
    const label = formatLabel();
    setLabel(label);
    props.onSelect(value, item);
    closeMenu();
  };

  const selectParent = (parentId) => {
    return items.find((item) => item.nodeId === parentId);
  };

  const handleCheckSelect = (event, selectedItem, state) => {
    const {id, value, items} = selectedItem;
    items && items.forEach(item => {
      state ? selectItem(item.id) : deSelectItem(item.id);
    });
    dispatch({type: 'UPDATE_LAST_ITEM', lastSelectedItem: selectedItem});
    return state ? selectItem(id) : deSelectItem(id);
  };

  const handleRadioSelect = useCallback((selectedItem, state) => {
    const {id} = selectedItem;
    items && items.forEach(item => {
      deSelectItem(item.id);
    });
    dispatch({type: 'UPDATE_LAST_ITEM', lastSelectedItem: selectedItem});
    return state ? selectItem(id) : deSelectItem(id);
  });

  const handleApply  =(event) => {
    event.stopPropagation();
    const selectedItems = getSelectedItems();
    const label = formatLabel();
    setLabel(label);
    if(typeof props.onApply === 'function') {
      props.onApply(selectedItems);
    }
    multiSelect ? dispatch({type: 'UPDATE_APPLIED_OPTIONS_STATE'}) : closeMenu();
  };

  const handleFooterCTA = (event) => {
    const item = getSelectedItems();
    props.onFooterCTAClick(item);
    closeMenu();
  };


  const defaultItemConfig =   {
    handleEdit,
    handleSelect,
    handleAdd,
    handleCheckSelect,
    handleRadioSelect
  };

  const listProps = {
    items,
    props: defaultItemConfig,
    type,
    indentationPadding
  };

  const height = menuRef.current && menuRef.current.offsetHeight || 0;
  const top = (offset.top || position.top) - ((openTop ? height : 0) + (openTop ? 0 : -elementHeight));
  const style =  {left: (offset.left || position.left),  top, width};

  return (
    <div className={`new ${openTop ? 'top': ''}`}>
      <div  className={`dropdown ${expanded ? 'show' : ''}`} style={{width: width || menuWidth}} ref={dropdownRef} onKeyDown={onKeyDown} tabIndex={0}>
        <button disabled={disabled || !items.length} onClick={toggleMenu}   className={`dropdown__label ${expanded ? 'dropdown__label--open' : ''}`} id={id}>
          <span className="dropdown__labelText" >{label || placeholder}</span>
          <span className={`dropdown__labelIcon dropdown__chevron--${expanded ? 'open' : 'closed'}`} >&nbsp;</span>
        </button>
        <BodyEnd cssClass={`new ${openTop ? 'top': ''}`}>
          <div className="dropdown " aria-label='dropdown-container'>
            <div ref={menuRef} style={style} className={`dropdown__menu dropdown__menu--${expanded ? 'show' : 'hide'}`} aria-labelledby={id} tabIndex="0" >
              {searchFilter &&
              <div className="dropdown__header">
                <input type='text' placeholder="Search" {...filter} className="dropdown__search-box"/>
              </div>
              }
              <div className="dropdown-seperater"></div>
              {items &&  withScroll(List)(listProps)}
              { multiSelect &&
              <FooterCTA type="button" disabled={getSelectedItems().length <= 0 }  text={footerCTAText} clickHandler={handleApply} />
              }
              {footerCTA &&
              <FooterCTA type={footerCTAType} text={footerCTAText} disabled={footerCTADisabled}  clickHandler={handleFooterCTA} />
              }
            </div>
          </div>
        </BodyEnd>
      </div>
    </div>
  );
};


Dropdown.defaultProps = {
  id : 'dropdown-component-id',
  multiSelect : false,
  footerCTA: false,
  footerCTADisabled: false,
  footerCTAText: t('tkApplyChanges'),
  footerCTAType: 'stationary',
  openTop: false,
  fixedMenu: true,
  disabled: false,
  selectParentOnAny: false,
  indentationPadding: 13,
  onEdit: () => {},
  onAdd: () => {},
  onSelect: () => {},
  onFooterCTAClick: () => {},
  searchFilter: false,
  prepender : '',
  appender : 'ITEMS SELECTED',
  placeholder : 'SELECT OPTION',
  type : 'list',
  labelTemplate : null,
  width: '250px'
};
export default Dropdown;
