import Choices, { Choices as ChoicesNamespace } from '@gs-ux-uitoolkit-common/choices';
import {
    defaults,
    nonNullableValueOrThrow,
    KeyHelpers,
    UiToolkitResizeObserver,
    unsanitizeHtml,
} from '@gs-ux-uitoolkit-common/shared';
import { arraySetsEqual, isTouchDevice } from '@gs-ux-uitoolkit-common/shared';
import { ChoicesConfig } from './select';
import {
    selectMultipleStyleSheet,
    inputPlaceholderHasSelectedOptionsAttr,
} from './style-sheets/select-multiple-style-sheet';
import { CssClasses, StyleSheet } from '@gs-ux-uitoolkit-common/style';
import {
    removeIconSizeVariant,
    getIconPropsByStatus,
    getSelectChevronDownStyles,
    getSelectChevronUpIconStyles,
    getStatusIconStyles,
} from './style-sheets/common-styles';
import {
    ChoicesSelectedOption,
    NormalizedSelectMultipleConfig,
    SelectMultipleAddEvent,
    SelectMultipleChangeEvent,
    selectMultipleClassPrefix,
    SelectMultipleConfigWithClassName,
    SelectMultipleConfig,
    SelectMultipleRemoveEvent,
    SelectOptionLeaf,
    SelectSearchEvent,
    SelectSize,
    SelectStatus,
} from './options';
import {
    decodeHTMLEntityCodes,
    getSelectClassNames,
    getSelectedOptions,
    getSelectedValues,
    toChoiceOption,
    updateDropdownTopPosition,
    resetDropdownTopPosition,
} from './utils';
import { SelectPlacement, selectMultipleDefaultProps } from './select-props';
import { Theme } from '@gs-ux-uitoolkit-common/theme';

import {
    ConstrainSelectedOptionsConfig,
    ConstrainSelectedOptionsFormatterData,
} from './options/select-multiple-options';
import { IconElementComponent } from '@gs-ux-uitoolkit-common/icon-font';
import { rgba } from 'polished';
import { AbstractSelect } from './abstract-select';

const selectMultipleClassNames = getSelectClassNames(selectMultipleClassPrefix);
const constrainedOptionsDefaultLabel = '+';
const constrainedOptionsPillMargin = 4;
const constrainedOptionsDropdownWidth = 24;
const constrainedOptionsClassNames = {
    item: 'gs-uitk-select-multiple__constrained-item',
    hideItem: 'gs-uitk-select-multiple__hide-item',
    inner: 'gs-uitk-select-multiple__constrained-inner',
    list: 'gs-uitk-select-multiple__constrained-list',
    clearAllButton: 'gs-uitk-select-multiple__clear-all-button',
};
const REMOVE_ITEM_TEXT = 'Remove all items';

/**
 * @deprecated Use `selectMultipleDefaultProps` instead.
 */
export const defaultMaxSelectionsContent = 'Maximum selected options reached';

const statusIconStyleSheet = new StyleSheet(
    'statusIcon',
    ({ size, status, theme }: { size: SelectSize; status: SelectStatus; theme: Theme }) => ({
        root: getStatusIconStyles({ size, status, theme }),
    })
);
const chevronDownIconStyleSheet = new StyleSheet(
    'chevronDownIcon',
    ({ size, theme }: { size: SelectSize; theme: Theme }) => ({
        root: getSelectChevronDownStyles(size, theme),
    })
);

const chevronUpIconStyleSheet = new StyleSheet(
    'chevronUpIcon',
    ({ size, theme }: { size: SelectSize; theme: Theme }) => ({
        root: getSelectChevronUpIconStyles({
            isSingleSelect: true,
            size,
            searchable: false,
            theme,
        }),
    })
);

const clearAllIconStyleSheet = new StyleSheet(
    'clearAllIcon',
    ({ size, theme }: { size: SelectSize; theme: Theme }) => ({
        root: {
            color: theme.color.gray.bold,
            fontSize: removeIconSizeVariant[size || 'md'].fontSize,
        },
    })
);

const removeIconStyleSheet = new StyleSheet(
    'removeIcon',
    ({ theme, disabled }: { theme: Theme; disabled: boolean }) => ({
        root: {
            color: rgba(
                theme.getColorInteractionShades('information', 'subtle').text as string,
                disabled ? 0.6 : 1
            ),
            fontSize: '12px',
        },
    })
);

const removeHighlightIconStyleSheet = new StyleSheet(
    'removeIcon',
    ({ theme }: { theme: Theme }) => ({
        root: {
            color: theme.text.reversed,
            fontSize: '12px',
        },
    })
);

/**
 * Common implementation of the <SelectMultiple /> component.
 * instantiates choices.js generates configuration options and updates the configuration when possible.
 *
 * @param selectEl The <select> element to decorate with our Select component.
 * @param config The configuration to instantiate the Select component with.
 */
export class CommonSelectMultipleComponent extends AbstractSelect<NormalizedSelectMultipleConfig> {
    /**
     * The Choices.js object created for the given select component
     */
    private choices!: Choices;

    /**
     * Used to "collect" multiple options during a remove event
     */
    private removedEventsList: SelectOptionLeaf[] = [];

    /**
     * Flag used to determine if callbacks should be called.
     * You can use this to update the select box, but prevent from calling the developers callback
     */
    private suppressEvents = false;

    /**
     * Flag used to determine if the component's menu is permitted to hide.
     * Look for description in fixMenuHideBehavior() for more details.
     */
    private canCloseMenu: boolean = false;

    /**
     * Flag used to determine if the searchEvent has been fixed. We only want to "fix" the issue
     *  once. For more details on the issue please see `fixSearchEvent()` in this class.
     */
    private isSearchEventFixed: boolean = false;

    /**
     * Flag used to determine if the menu has been shown, as the show event is called
     * twice in some cases. This is needed to make sure it is positioned properly when at a boundary.
     */
    private isMenuShowing: boolean = false;

    /**
     * The CSS classes used by the component.
     */
    private mountedCssClassNames!: CssClasses<typeof selectMultipleStyleSheet>;
    /**
     * This resize observer is to listen for size changes on the select element
     * This will be used when there's `constrainSelectedOptions` prop, this ResizeObserver re-constrains the displayed pills when the element's size is changed
     */
    private resizeObserver?: UiToolkitResizeObserver;

    /**
     * Reference to the instantiated status icon
     */
    private statusIcon?: IconElementComponent;

    /**
     * Reference to the instantiated clear all icon
     */
    private clearAllIcon?: IconElementComponent;

    /**
     * Reference to the instantiated chevron down icon, this is displayed when the dropdown menu is closed
     */
    private chevronDownIcon?: IconElementComponent;

    /**
     * Reference to the instantiated chevron up icon, this is displayed when the dropdown menu is opened
     */
    private chevronUpIcon?: IconElementComponent;

    /**
     * Reference to the list of remove icons for individual pills
     */
    private removeIcons: Array<IconElementComponent> = [];

    /**
     * Theme passed in from React or Angular to set the style
     */
    private theme: Theme | undefined;

    /**
     * Store the event listeners, so they can be removed when choices.js instance is destroyed
     */
    private managedEventHandlers: [HTMLElement, string, EventListenerOrEventListenerObject][] = [];

    constructor(selectContainerEl: HTMLDivElement, config: SelectMultipleConfig) {
        super(selectContainerEl, '[data-cy=gs-uitk-select-multiple__inner-select]');

        const normalizedConfig = this.normalizeConfig(config);
        this.props = normalizedConfig;
        this.setup(normalizedConfig);
    }

    /***
     * Normalizes the select configuration.
     */
    private normalizeConfig(config: SelectMultipleConfig): NormalizedSelectMultipleConfig {
        return defaults({}, config, {
            className: '',
            disabled: false,
            inline: selectMultipleDefaultProps.inline,
            menuPlacement: selectMultipleDefaultProps.menuPlacement,
            searchFields: selectMultipleDefaultProps.searchFields,
            size: selectMultipleDefaultProps.size,
            status: selectMultipleDefaultProps.status,
        });
    }

    /**
     * Setup tasks to capture events and config from wrapper component
     */
    protected setup(config: NormalizedSelectMultipleConfig) {
        super.setup(config);

        const { size, status, inline, clearable, noOptionsContent } = config;
        this.updateStyles(size, status, this.theme, inline, clearable, noOptionsContent);

        const selectConfig: ChoicesConfig = this.generateChoicesConfig(config);

        this.choices = new Choices(this.selectEl, selectConfig);

        this.choices.removeActiveItems(0);
        this.updateSelectConfig(config);
        this.attachEventListeners();
        this.fixSearchEvent(config);
        this.fixMenuPosition();
        this.suppressEvents = false;
    }
    /**
     * This function will add a class name for constraint mode
     */
    private applyConstrainedOptionsMode(config: ChoicesConfig) {
        return {
            ...config,
            callbackOnCreateTemplates: (template: ChoicesNamespace.Types.strToEl) => {
                return {
                    ...(config.callbackOnCreateTemplates
                        ? config.callbackOnCreateTemplates(template)
                        : undefined),
                    containerInner: (classNames: ChoicesNamespace.ClassNames) => {
                        let updatedClassNames = classNames;
                        if (this.props.constrainSelectedOptions) {
                            updatedClassNames = {
                                ...classNames,
                                containerInner: `${classNames.containerInner} ${constrainedOptionsClassNames.inner}`,
                            };
                        }

                        return Choices.defaults.templates.containerInner.apply(this as any, [
                            updatedClassNames,
                        ]);
                    },
                    itemList: (
                        classNames: ChoicesNamespace.ClassNames,
                        isSelectOneElement: boolean
                    ) => {
                        let updatedClassNames = classNames;
                        if (this.props.constrainSelectedOptions) {
                            updatedClassNames = {
                                ...classNames,
                                list: `${classNames.list} ${constrainedOptionsClassNames.list}`,
                            };
                        }

                        return Choices.defaults.templates.itemList.apply(this as any, [
                            updatedClassNames,
                            isSelectOneElement,
                        ]);
                    },
                };
            },
        };
    }

    /**
     * Function used to "update" the styles for the component. This function mounts the CSS-in-JS
     *    stylesheet which used to style the component. The stylesheet itself only returns one
     *    CSS class by the name of "root". This class is only added to the outermost (root) element
     *    of the component. We do this since we are leveraging CSS descendant selectors to allow
     *    the `size` prop to be changed programmatically after the component is instantiated.
     *
     * @param size The size of the component
     */
    private updateStyles(
        size: SelectSize,
        status: SelectStatus,
        theme?: Theme,
        inline?: boolean,
        clearable?: boolean,
        noOptionsContent?: string,
        menuPlacement?: SelectPlacement
    ) {
        // remove the root class name if its mounted
        const containerClassList = this.selectOuterContainer.classList;

        if (
            this.mountedCssClassNames &&
            containerClassList.contains(this.mountedCssClassNames.root)
        ) {
            containerClassList.remove(this.mountedCssClassNames.root);
        }

        //should only mount the stylesheet when theme exists
        if (theme) {
            // Mount the CSS-in-JS Stylesheet
            const mountedCssClasses = selectMultipleStyleSheet.mount(this, {
                size,
                status,
                cssClassNames: selectMultipleClassNames,
                theme,
                menuPlacement,
                inline,
                clearable,
                noOptionsContent,
            });

            this.mountedCssClassNames = mountedCssClasses;

            // We only add the CSS class to the root element so we can later on generate a new
            //    stylesheet if the size changes and only swap out the root class name. (The Style sheet
            //    uses descendant selectors to actually style the components)
            containerClassList.add(mountedCssClasses.root);
        }
    }

    /**
     * Function used to focus the select component.
     * @public
     */
    public focus() {
        if (!this.props.disabled) {
            this.choices.showDropdown();
        }
    }

    /**
     * Function used to blur the select component.
     * @public
     */
    public blur() {
        this.choices.hideDropdown();
    }

    /**
     * Used to update the configuration on the component
     * @param newConfig - Current config on the component
     * @param prevConfig - Previous config on the component
     */
    public setConfig(
        newConfig: SelectMultipleConfigWithClassName,
        prevConfig: SelectMultipleConfigWithClassName
    ) {
        const newNormalizedConfig = this.normalizeConfig(newConfig);
        const prevNormalizedConfig = this.normalizeConfig(prevConfig);
        this.props = newNormalizedConfig;
        this.updateSelectConfig(newNormalizedConfig, prevNormalizedConfig);
        this.fixSearchEvent(newNormalizedConfig);
    }

    private addManagedEventListener(
        el: HTMLElement,
        type: string,
        listener: EventListenerOrEventListenerObject
    ) {
        el.addEventListener(type, listener, false);
        this.managedEventHandlers.push([el, type, listener]);
    }

    /**
     * Remove all the stored event listeners when destroying the choices.js instance
     * Select and SelectMultiple would not support StrictMode without manually removing the event listeners,
     * since StrictMode automatically unmount and remount the newly mounted components by default
     */
    private removeManagedEventListeners() {
        let managedEventHandler = this.managedEventHandlers.pop();
        while (managedEventHandler) {
            const [el, type, listener] = managedEventHandler;
            el.removeEventListener(type, listener, false);
            managedEventHandler = this.managedEventHandlers.pop();
        }
    }

    /**
     * Destroys the choices.js instance.
     */
    public destroy() {
        if (this.statusIcon) {
            this.statusIcon.destroy();
        }
        if (this.clearAllIcon) {
            this.clearAllIcon.destroy();
        }
        if (this.chevronDownIcon) {
            this.chevronDownIcon.destroy();
        }
        if (this.chevronUpIcon) {
            this.chevronUpIcon.destroy();
        }
        this.removeIcons.forEach(element => {
            element.destroy();
        });
        //unmount the icon stylesheets
        statusIconStyleSheet.unmount(this);
        removeIconStyleSheet.unmount(this);
        removeHighlightIconStyleSheet.unmount(this);
        chevronDownIconStyleSheet.unmount(this);
        chevronUpIconStyleSheet.unmount(this);
        clearAllIconStyleSheet.unmount(this);
        this.removeManagedEventListeners();
        this.choices.destroy();
        if (this.resizeObserver) this.resizeObserver.unobserve(this.selectOuterContainer);
    }

    /**
     * Currently Choices.js does not fire a search event when the input is cleared.
     *    This function fixes that behavior. For more details please see
     *    https://github.com/jshjohnson/Choices/issues/630
     *
     * @param config Current config on the component
     */
    private fixSearchEvent(config: NormalizedSelectMultipleConfig) {
        if (!this.isSearchEventFixed && !config.disabled) {
            const inputEl = this.getInputElOrThrow();

            // Choices.js currently does not fire an event when the input is
            // cleared. The fix below is a workaround so we fire the "search"
            // event when the input is cleared.
            inputEl.addEventListener('keyup', event => {
                const value = (event.target as HTMLInputElement).value;
                // No easy way to check for CTRL/CMD + X below. the check for CTRL/CMD should
                // be enough since we are also checking the value.
                if (
                    (event.which === KeyHelpers.keyCode.BACKSPACE ||
                        event.which === KeyHelpers.keyCode.DELETE ||
                        KeyHelpers.isCtrlKey(event.which)) &&
                    value === ''
                ) {
                    // `triggerEvent` is a choices specific function.
                    // Currently missing from the choices.js typings.
                    // Hence the cast to "any" below.
                    (this.choices.passedElement as any).triggerEvent('search', {
                        value,
                        resultCount: 0,
                    });
                }
            });
            /**
             * Needed this event listener because Choices.js triggers the search on
             * keyup and the keyup event doesn't work properly when generated programatically.
             * Added an input event listener so that when the input event is triggered programatically / by user's input,
             * this listener gets triggered
             */
            inputEl!.addEventListener('input', event => {
                /**
                 * The cancelable property is false by default for all user events. It is being used
                 * as a flag here, as the saucelabs tests will trigger the input event with
                 * this property as true. This will not impact any other functionality of
                 * select/multi-select.
                 */
                if (event.cancelable) {
                    const value = (event.target as HTMLInputElement).value;
                    const choicesObj = this.choices as any;
                    choicesObj?._handleSearch(value);
                }
            });

            this.isSearchEventFixed = true;
        }
    }

    /**
     * Returns an object that maps components config to choicesJS config options
     * @param componentConfig - Properties from client's react component
     */
    protected generateChoicesConfig(
        componentConfig: NormalizedSelectMultipleConfig
    ): ChoicesConfig {
        let commonConfig = super.generateChoicesConfig(componentConfig);

        const config: ChoicesConfig = {};
        config.duplicateItemsAllowed = false;
        config.classNames = selectMultipleClassNames;

        if (componentConfig.sortSelectedOptions !== undefined) {
            if (typeof componentConfig.sortSelectedOptions === 'function') {
                config.shouldSortItems = true;
                config.sorter = componentConfig.sortSelectedOptions;
            } else {
                config.shouldSortItems = componentConfig.sortSelectedOptions as boolean;
            }
        }
        if (componentConfig.renderSelectedOptions) {
            config.renderSelectedChoices = 'always';
        }
        if (componentConfig.pasteable) {
            config.paste = componentConfig.pasteable;
        }
        if (componentConfig.maxSelectedOptions !== undefined) {
            config.maxItemCount = componentConfig.maxSelectedOptions;
        }
        if (componentConfig.maxSelectionsContent !== undefined) {
            config.maxItemText = componentConfig.maxSelectionsContent;
        } else {
            config.maxItemText = selectMultipleDefaultProps.maxSelectionsContent;
        }
        if (componentConfig.removeButtonsVisible !== undefined) {
            config.removeItemButton = componentConfig.removeButtonsVisible;
        }
        if (componentConfig.resetScrollPosition !== undefined) {
            config.resetScrollPosition = componentConfig.resetScrollPosition;
        }
        // if constrainSelectedOptions the we should provide templates
        if (componentConfig.constrainSelectedOptions) {
            commonConfig = this.applyConstrainedOptionsMode(commonConfig);
        }

        return { ...commonConfig, ...config };
    }

    /**
     * Updates the options and selected options
     */
    protected async updateSelectConfig(
        config: NormalizedSelectMultipleConfig,
        prevConfig?: NormalizedSelectMultipleConfig
    ) {
        super.updateSelectConfig(config, prevConfig);

        this.suppressEvents = true;
        const {
            defaultValues,
            options,
            disabled,
            selectedValues,
            size,
            status,
            className,
            placeholder,
            inline,
            clearable,
            maxSelectedOptions,
            noOptionsContent,
            menuPlacement,
        } = config;

        const hasPrevConfig = !!prevConfig;
        prevConfig = prevConfig || {
            className: '',
            disabled: false,
            inline: selectMultipleDefaultProps.inline,
            menuPlacement: selectMultipleDefaultProps.menuPlacement,
            size: selectMultipleDefaultProps.size,
            status: selectMultipleDefaultProps.status,
        };

        const choicesInstance = this.choices;
        const hasOptionsChanged = options !== prevConfig.options;
        const hasMaxSelectedOptionsChanged = maxSelectedOptions !== prevConfig.maxSelectedOptions;
        let needsOptionsUpdate = false;

        if (hasMaxSelectedOptionsChanged) {
            choicesInstance.config.maxItemCount = maxSelectedOptions || -1;
        }

        if (hasPrevConfig) {
            // We only 'update' the options below if there is a previous config
            // (i.e., not during initialization) because first time there will
            // be no active items to remove and toChoiceOption will already have
            // been run on options when calling generateChoicesConfig()
            if (hasOptionsChanged) {
                needsOptionsUpdate = true;
            }
            if (hasMaxSelectedOptionsChanged) {
                // We need to update the options after re-configuring choices
                // (above) in order to get the menu to re-render
                needsOptionsUpdate = true;
            }

            // If a previous selectedValue is not in new selectedValues, remove it
            // to handle the controlled case. We need to remove items this way
            // instead of using the `removeActiveItems` API as it has severe performance
            // problems when called with a large number of selected items.
            if (prevConfig.selectedValues) {
                prevConfig.selectedValues.forEach((value: string) => {
                    if (!config.selectedValues?.includes(value)) {
                        choicesInstance.removeActiveItemsByValue(value);
                    }
                });
            }
        }

        if (needsOptionsUpdate) {
            //update the dropdown menu
            const optionChoices = (options || []).map(toChoiceOption);

            // Seems that we need to capture the selected values and re-apply
            // them after updating the options in order to get Choices to update
            // correctly
            const choicesValues = choicesInstance.getValue(true);

            choicesInstance.removeActiveItems(0);
            choicesInstance.setChoices(optionChoices as SelectOptionLeaf[], 'value', 'label', true);
            choicesInstance.setChoiceByValue(choicesValues);
        }

        if (selectedValues !== undefined) {
            this.updateValues();
        }

        if (placeholder && this.getInputEl()) {
            const inputEl = this.getInputElOrThrow();
            inputEl.placeholder = placeholder;

            if (selectedValues && selectedValues.length > 0) {
                inputEl.setAttribute(inputPlaceholderHasSelectedOptionsAttr, 'true');
            } else {
                inputEl.removeAttribute(inputPlaceholderHasSelectedOptionsAttr);
            }
        }
        if (defaultValues) {
            choicesInstance.setChoiceByValue(defaultValues);

            // If default values are selected and there is a placeholder, make sure
            // to set the attribute that options are selected
            if (choicesInstance.getValue().length > 0 && placeholder && this.getInputEl()) {
                const inputEl = this.getInputElOrThrow();
                inputEl.setAttribute(inputPlaceholderHasSelectedOptionsAttr, 'true');
            }
        }

        disabled ? choicesInstance.disable() : choicesInstance.enable();

        if (
            size !== prevConfig.size ||
            status !== prevConfig.status ||
            className !== prevConfig.className ||
            noOptionsContent !== prevConfig.noOptionsContent ||
            menuPlacement != prevConfig.menuPlacement
        ) {
            this.updateStyles(
                size,
                status,
                this.theme,
                inline,
                clearable,
                noOptionsContent,
                menuPlacement
            );
        }
        updateDropdownTopPosition(this.getDropdownEl(), this.props.menuPlacement);
        this.suppressEvents = false;
    }

    /**
     * Method used to update all remove buttons using the GSDS icon for all selected options
     */
    private updateRemoveIcon(highlight?: boolean) {
        const { disabled } = this.props;
        const allRemoveButtons = this.getRemoveButtons();
        const highlightedButtons = this.getHighlightedRemoveButtons();

        this.removeIcons.forEach(element => {
            // destroy all icons generated from previous call of updateRemoveIcon()
            element.destroy();
        });

        // empty the reference so it can be populated with new icon instances
        this.removeIcons = [];
        // Append a remove icon for each of the selected options
        allRemoveButtons.forEach(element => {
            const removeIcon = new IconElementComponent({
                name: 'close',
                type: 'outlined',
                classes: removeIconStyleSheet.mount(this, { theme: this.theme!, disabled }),
            });
            element.innerHTML = '';
            removeIcon.render(element);

            this.removeIcons.push(removeIcon);
        });

        // If there is highlighted pills, add the icons again with highlighted style
        if (highlight || highlightedButtons.length > 0) {
            highlightedButtons.forEach(element => {
                const removeHighlightedIcon = new IconElementComponent({
                    name: 'close',
                    type: 'outlined',
                    classes: removeHighlightIconStyleSheet.mount(this, { theme: this.theme! }),
                });
                element.innerHTML = '';
                removeHighlightedIcon.render(element);

                this.removeIcons.push(removeHighlightedIcon);
            });
        }
    }

    /**
     * Method to add the status icon when status !== undefined or 'none'
     */
    private updateStatusIcon = () => {
        const { size, status } = this.props;
        if (this.statusIcon) {
            this.statusIcon.destroy();
        }
        if (status !== undefined && status !== 'none') {
            //stored the newly instantiated statusIcon to the pointer object
            this.statusIcon = new IconElementComponent({
                name: getIconPropsByStatus(status, size).name,
                type: 'filled',
                //set the icon spin to true if the select status is loading
                spin: status === 'loading',
                classes: statusIconStyleSheet.mount(this, { status, size, theme: this.theme! }),
                attributes: { 'data-icon': 'status' },
            });
            this.statusIcon.render(this.getInnerElOrThrow());
        }
    };

    /**
     * Updates the the component's style based on theme
     */
    public setTheme = (theme: Theme) => {
        const { inline, clearable, noOptionsContent, size, status, menuPlacement } = this.props;
        this.theme = theme;
        this.updateStyles(size, status, theme, inline, clearable, noOptionsContent, menuPlacement);
        this.updateChevronDownIcon();
        this.updateStatusIcon();
        if (this.getInnerEl()) {
            this.updateClearAllButtonVisibility();
            this.updateRemoveIcon();
        }
    };
    /**
     * Used to update the value of the component when the value is in "controlled" mode (i.e. the
     * parent component is controlling the selected value(s))
     *
     */
    private updateValues() {
        const { onChange, selectedValues } = this.props;
        if (selectedValues !== undefined) {
            this.choices.setChoiceByValue(selectedValues!);
        }

        if (onChange && !this.suppressEvents) {
            const choicesValues = this.choices.getValue() as unknown as ChoicesSelectedOption[];
            const changeEvent: SelectMultipleChangeEvent = {
                type: 'remove',
                removedOptions: getSelectedOptions(choicesValues),
                removedValues: getSelectedValues(choicesValues),
                selectedOptions: getSelectedOptions(choicesValues),
                selectedValues: getSelectedValues(choicesValues),
            };
            onChange(changeEvent);
        }
    }
    /**
     * Method to add the chevron down icon when the dropdown menu is closed
     */
    private updateChevronDownIcon = () => {
        //only add the chevron down icon if the dropdownlist is not active
        if (this.getActiveDropdownListEl() === null) {
            const { size } = this.props;
            if (this.chevronDownIcon) {
                this.chevronDownIcon.destroy();
            }

            //stored the newly instantiated chevronDownIcon to the pointer object
            this.chevronDownIcon = new IconElementComponent({
                name: 'keyboard-arrow-down',
                type: 'filled',
                classes: chevronDownIconStyleSheet.mount(this, {
                    size,
                    theme: this.theme!,
                }),
            });

            const innerEl = this.getInnerElOrThrow();

            this.chevronDownIcon.render(innerEl);
        }
    };

    /**
     ** Method to add the chevron up icon when the dropdown menu is opened
     */
    private updateChevronUpIcon = () => {
        //only add the chevron up icon if the dropdownlist is active
        if (this.getActiveDropdownListEl() !== null) {
            const { size } = this.props;
            if (this.chevronUpIcon) {
                this.chevronUpIcon.destroy();
            }

            //stored the newly instantiated chevronUpIcon to the pointer object
            this.chevronUpIcon = new IconElementComponent({
                name: 'keyboard-arrow-up',
                type: 'filled',
                classes: chevronUpIconStyleSheet.mount(this, { size, theme: this.theme! }),
            });

            const containerEl = this.getInnerElOrThrow();

            this.chevronUpIcon.render(containerEl);
        }
    };
    /**
     * Adds provided event listeners to the select container
     * @param componentConfig - Properties from client react component
     */
    private attachEventListeners(): void {
        this.addManagedEventListener(this.selectEl, 'search', event => this.onSearch(event));

        this.addManagedEventListener(this.selectEl, 'addItem', event =>
            this.onOptionSelected(event)
        );
        this.addManagedEventListener(this.selectEl, 'removeItem', event => {
            this.onOptionRemoved(event);
        });
        this.addManagedEventListener(this.selectEl, 'showDropdown', event =>
            this.onMenuShow(event)
        );
        this.addManagedEventListener(this.selectEl, 'hideDropdown', event =>
            this.onMenuHide(event)
        );
        this.addManagedEventListener(this.selectEl, 'change', event => this.buttonUpdate(event));

        this.addManagedEventListener(this.selectEl, 'highlightItem', event =>
            this.buttonUpdate(event, true)
        );
        /**
         * The focus always sets on the input element and not on the select element.
         * 'onblur' will be called only when input looses focus.
         */
        const inputEl = this.getInputEl();
        if (inputEl) {
            this.addManagedEventListener(inputEl, 'blur', event => this.onBlur(event));
        }

        // our Angular wrapper uses an EventEmitter called 'change' to emit the change, but parent components subscribing to it
        // could also receive the DOM event called 'change' which is emitted by Choices itself. We want to prevent developers from
        // receiving Choices' event

        this.addManagedEventListener(this.selectEl, 'change', event => {
            event.stopPropagation();
        });
        this.addManagedEventListener(this.selectEl, 'choice', event => this.onOptionClicked(event));
        this.fixMenuHideBehavior();

        //used for when the select is in constrained mode
        if (this.props.constrainSelectedOptions) {
            this.resizeObserver = new UiToolkitResizeObserver(
                this.selectOuterContainer,
                this.onElementResize
            );
        }
    }

    /**
     * We want to allow users the ability to hide the menu (when the menu is visible) by clicking the select component.
     * Since we want users to be able to click anywhere in the "input" (whether it be the input itself or anywhere else)
     * we attach an event listener on the outermost element to listen for a click.
     * There is an issue, if a user clicks on the actual input element (input.gs-uitk-select-multiple__input)
     * The menu briefly shows and then it immediately hides. This function allow us to "fix" the normal behavior
     * choices.js has (not closing dropdown on click of the component)
     */
    private fixMenuHideBehavior(): void {
        // Get a reference to the inputEl
        const inputEl = this.getInputElOrThrow();

        // Handle clicking on the input element itself
        // The menu (dropdown) will hide if it's currently visible
        inputEl.addEventListener('click', e => {
            e.stopPropagation();
            if (this.canCloseMenu) {
                this.choices.hideDropdown();
                this.canCloseMenu = false;
            } else {
                this.canCloseMenu = true;
            }
        });

        // Handle clicking on the outer element (SelectMultiple Component)
        // The menu (dropdown) will hide if it's currently visible
        this.selectOuterContainer.addEventListener('click', () => {
            if (this.canCloseMenu) {
                this.choices.hideDropdown();
                this.canCloseMenu = false;
            } else {
                this.canCloseMenu = true;
            }
        });

        // Clean-up our attributes we set in case a user clicks outside the component
        // and does not click the input to close.
        // inputEl.addEventListener('blur', () => (this.canCloseMenu = false));
    }

    /** Method to update the remove all selection button for select multiple when the clearable prop is set to true
     */
    private updateClearAllButtonVisibility(): void {
        const { clearable, size } = this.props;
        // Get a reference to the clear all button el
        let clearAllButtonEl = this.getClearAllButtonEl();
        // Get a reference to the inner container el
        const innerElOrThrow = this.getInnerElOrThrow();

        if (clearable) {
            const choicesValues = this.choices.getValue() as unknown as ChoicesSelectedOption[];

            const clearAllButton = Object.assign(document.createElement('button'), {
                type: 'button',
                className: `${constrainedOptionsClassNames.clearAllButton}`,
            });
            clearAllButton.setAttribute('aria-label', REMOVE_ITEM_TEXT);
            clearAllButton.addEventListener('click', e => {
                e.stopPropagation();
                // Remove all selected items
                this.choices.removeActiveItems(0);
                // Remove the button once it is clicked
                innerElOrThrow.removeChild(clearAllButton);
            });

            //destroy the clearAll icon and remove the clearAll button
            if (this.clearAllIcon) {
                this.clearAllIcon.destroy();
                clearAllButtonEl?.remove();
                //retrieves the clearAll button again, should be null since the element it removed
                clearAllButtonEl = this.getClearAllButtonEl();
            }

            this.clearAllIcon = new IconElementComponent({
                name: 'cancel',
                type: 'filled',
                classes: clearAllIconStyleSheet.mount(this, { size, theme: this.theme! }),
            });

            this.clearAllIcon.render(clearAllButton);

            if (!this.props.disabled) {
                if (clearAllButtonEl) {
                    // Remove the clear all button if no option is selected
                    if (choicesValues.length === 0) {
                        innerElOrThrow.removeChild(clearAllButtonEl);
                    }
                } else {
                    // Add the clear all button if the button doesn't exist
                    if (choicesValues.length > 0) {
                        innerElOrThrow.appendChild(clearAllButton);
                    }
                }
            } else {
                //Remove the button when the component is disabled
                if (clearAllButtonEl) {
                    innerElOrThrow.removeChild(clearAllButtonEl);
                }
            }
        } else {
            //Remove the button when the clearable is set to false
            if (clearAllButtonEl) {
                innerElOrThrow.removeChild(clearAllButtonEl);
            }
        }
    }
    /**
     * Method called when the 'change' and 'highlightItem' callbacks are emitted
     * @param event - Custom Event emitted from choices event.
     */
    private buttonUpdate(event: Event, highlight?: boolean): void {
        event.stopPropagation();
        this.updateClearAllButtonVisibility();
        //If the event is fired from highlightItem, pass in the boolean for highlight
        this.updateRemoveIcon(highlight);
    }
    /**
     * This method ensures that the input always scrolls at a proper position so that the options dropdown has maximum
     * area to be shown on the screen even if the keyboard pops out for half of the screen
     */
    private fixMenuPosition(): void {
        const inputEl = this.getInputEl() as HTMLInputElement;
        inputEl.addEventListener(
            'focus',
            e => {
                const viewPortHeight = window.innerHeight;
                const inputElBoundingRect = inputEl.getBoundingClientRect();
                const documentEl = document.documentElement;
                /**
                 * If the page has reached at the last of the scroll, the inputs at the bottom might not
                 * scroll to the top of the page. In that case, to achieve maximum visible area for the dropdown,
                 * the element is scrolled at the bottom of the page
                 */
                const isScrollTop =
                    documentEl.scrollHeight - (documentEl.scrollTop + inputElBoundingRect.top) >=
                    viewPortHeight;
                if (isTouchDevice()) {
                    const element = e.target as HTMLElement;
                    // Scroll the input element to the desired view https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
                    // Note: scrollIntoView does not exist in jsdom (used by jest)
                    element.scrollIntoView?.(isScrollTop);
                    (this.choices as any)?.showDropdown(false);
                }
            },
            true // setting the handler in capture phase just to ensure that it is executed first in the order
        );
    }
    /**
     * Reformats the 'search' event data and calls the Select components 'onSearch' callback.
     * @param event - Custom Event emitted from choices event.
     */
    private onSearch(event: any): void {
        event.stopPropagation();
        const { onSearch, name } = this.props;
        if (!this.suppressEvents) {
            if (onSearch) {
                const searchEvent: SelectSearchEvent = {
                    searchValue: decodeHTMLEntityCodes(event.detail.value),
                    resultsCount: event.detail.resultCount,
                };
                if (name) {
                    searchEvent.componentName = name;
                }
                onSearch(searchEvent);
            }
            updateDropdownTopPosition(this.getDropdownEl(), this.props.menuPlacement);
        }
    }

    /**
     * Reformats the 'addItem' event data and calls the Select components 'onChange' callback.
     * @param event - Custom Event emitted from choices event.
     */
    private async onOptionSelected(event: any): Promise<any> {
        event.stopPropagation();
        const { selectedValues, onChange } = this.props;
        const type: SelectMultipleAddEvent['type'] = 'add';
        const choicesValues = this.choices.getValue() as unknown as ChoicesSelectedOption[];

        if (!this.suppressEvents) {
            if (onChange) {
                const changeEvent: SelectMultipleChangeEvent = {
                    type,
                    addedOptions: [
                        {
                            value: event.detail.value,
                            label: unsanitizeHtml(event.detail.label),
                            ...(event.detail.customProperties && {
                                customProperties: event.detail.customProperties,
                            }),
                        },
                    ],
                    addedValues: [event.detail.value],
                    selectedOptions: getSelectedOptions(choicesValues),
                    selectedValues: getSelectedValues(choicesValues),
                };
                onChange(changeEvent);
            }
            // If an option gets selected when in "controlled" mode we need to unselect the option
            // the user selected and select the option the developer has passed. Below we compare the
            // Currently selected options with the ones the developer provided.
            if (
                selectedValues &&
                !arraySetsEqual(getSelectedValues(choicesValues), selectedValues)
            ) {
                this.suppressEvents = true;

                // If the 'value' is being controlled by the parent component, we need to reset the value
                // here. The parent component will provide a new 'value' prop to update the SelectMultiple component later
                this.choices.removeActiveItemsByValue(event.detail.value);

                // For now we are awaiting `updateValues` although it is not an async function.
                // If the component is in controlled mode and a user clicks an option(developer does not update the value prop).
                // Then a user clicks another option(developer again does not update t he value prop).
                // The onChange is called 3 times instead of the expected 2.
                await this.updateValues();
                this.suppressEvents = false;
            }
        }

        if (this.props.constrainSelectedOptions) {
            this.constrainPills();
        }

        const inputEl = this.getInputEl();
        if (inputEl && this.choices.getValue().length > 0) {
            // update placeholder input styles when an option is selected
            inputEl.setAttribute(inputPlaceholderHasSelectedOptionsAttr, 'true');
        }
        updateDropdownTopPosition(this.getDropdownEl(), this.props.menuPlacement);
    }

    /**
     * This method will check the pills and create a overflow pill if required. It will only do this if constrainSelectedOptions is set
     */
    private onElementResize = () => {
        if (this.props.constrainSelectedOptions) this.constrainPills();
    };

    private normalizeConstrainSelectedOptions(
        config: SelectMultipleConfig['constrainSelectedOptions']
    ): Required<ConstrainSelectedOptionsConfig> {
        const configDefaults = {
            maxDisplayedOptions: Infinity,
            formatter: (data: ConstrainSelectedOptionsFormatterData) =>
                !data.numDisplayedOptions
                    ? `${data.numOverflowOptions} selected`
                    : `${constrainedOptionsDefaultLabel}${data.numOverflowOptions}`,
        };

        if (config === true) {
            return configDefaults;
        } else {
            return {
                ...configDefaults,
                ...(config as ConstrainSelectedOptionsConfig),
            };
        }
    }

    /**
     * This method is used with constrainSelectedOptions and checks to see if pills fit the width. If not then it will hide the pills that
     * do not fit and create a overflow pill
     */
    private constrainPills() {
        const constrainSelectedOptions = this.normalizeConstrainSelectedOptions(
            this.props.constrainSelectedOptions
        );

        const maxDisplayedOptions = constrainSelectedOptions.maxDisplayedOptions;

        const formatter = constrainSelectedOptions.formatter;

        const choicesClassnames = this.choices.config.classNames;

        // lets get the containers
        const innerContainer = this.selectOuterContainer.querySelector(
            `.${choicesClassnames.containerInner}`
        );
        const listContainer = this.selectOuterContainer.querySelector(`.${choicesClassnames.list}`);
        if (innerContainer && listContainer) {
            // we need to work out the max width and the max x position the pills can go to
            const innerRect = innerContainer.getBoundingClientRect();
            const maxXPosition = innerRect.x + innerRect.width;

            //get all pills
            const pillNodeList = listContainer.querySelectorAll<HTMLElement>(
                `.${choicesClassnames.item}`
            );
            if (pillNodeList) {
                //creating the grouped pill from choices template
                const existingGroupElement = listContainer.querySelector(
                    `.${constrainedOptionsClassNames.item}`
                );
                if (existingGroupElement)
                    existingGroupElement.parentElement?.removeChild(existingGroupElement);
                const groupPillElement =
                    existingGroupElement ||
                    Choices.defaults.templates.item.apply(this.choices as any, [
                        choicesClassnames,
                        {} as any,
                        false,
                    ]);
                //lets sort the pills so the shortest ones come first
                const pillsElements = Array.prototype.slice
                    .call(pillNodeList)
                    .filter(
                        (element: HTMLElement) =>
                            !element.classList.contains(constrainedOptionsClassNames.item)
                    )
                    .sort((firstElement, secondElement) => {
                        const firstWidth = firstElement.getBoundingClientRect().width;
                        const secondWidth = secondElement.getBoundingClientRect().width;

                        return parseFloat(firstWidth) - parseFloat(secondWidth);
                    });
                //we need to know the last pill that can fit so we can hide that one to and replace it with the grouped pill
                let lastPillElementThatFits: HTMLElement | undefined = undefined;
                let numberOfItemsThatDoNotFit = 0;
                let numberOfPillsThatFit = 0;
                //need to store the x position to calculate how many can fit
                let startXPosition = innerRect.x;
                pillsElements.forEach(pillElement =>
                    pillElement.classList.remove(`.${constrainedOptionsClassNames.hideItem}`)
                );
                //looping through to work out which pills fit and which don't
                pillsElements.forEach((pillElement: HTMLElement) => {
                    pillElement.classList.remove(`.${constrainedOptionsClassNames.hideItem}`);

                    const pillRect = pillElement.getBoundingClientRect();
                    //x position is virtual as we are hiding elements
                    const pillOffset =
                        startXPosition + pillRect.width + constrainedOptionsPillMargin * 2;
                    //removing the display prop incase we've already set it
                    let hide = false;
                    //if the pill doesn't fit we need to hide it
                    if (pillOffset > maxXPosition - constrainedOptionsDropdownWidth) {
                        hide = true;
                        numberOfItemsThatDoNotFit++;
                    } else {
                        //checking to see if we have the maximum number of pills the user wants to see
                        if (
                            maxDisplayedOptions != undefined &&
                            numberOfPillsThatFit >= maxDisplayedOptions
                        ) {
                            //if not then we need to hide it
                            hide = true;
                            numberOfItemsThatDoNotFit++;
                            //we don't want to replace the last displayable item
                            lastPillElementThatFits = undefined;
                        } else {
                            //this one fits so lets store it as the last one that will be displayed
                            lastPillElementThatFits = pillElement;
                            numberOfPillsThatFit++;
                        }
                    }
                    if (hide) {
                        pillElement.classList.add(`${constrainedOptionsClassNames.hideItem}`);
                    } else {
                        pillElement.classList.remove(`${constrainedOptionsClassNames.hideItem}`);
                    }
                    //update the start x position for the next element
                    startXPosition = pillOffset;
                });
                //if the last pill that fits is not the only one then we hide it because we replace it with the grouped pill
                if (
                    lastPillElementThatFits &&
                    numberOfPillsThatFit > 1 &&
                    numberOfItemsThatDoNotFit
                ) {
                    numberOfItemsThatDoNotFit = numberOfItemsThatDoNotFit + 1;
                    (lastPillElementThatFits as HTMLElement).classList.add(
                        `${constrainedOptionsClassNames.hideItem}`
                    );
                }
                // if we have hidden items then we need to create the grouped pill.
                if (numberOfItemsThatDoNotFit) {
                    groupPillElement.classList.add(constrainedOptionsClassNames.item);
                    (groupPillElement as HTMLElement).style.display = 'inline-block';
                    //set the label
                    const label = formatter({
                        numOverflowOptions: numberOfItemsThatDoNotFit,
                        numDisplayedOptions: numberOfPillsThatFit,
                    });

                    groupPillElement.innerHTML = `<span>${label}</span>`;

                    // We need to get the parent element of the pills.
                    // In order to ensure we get the correct wrapping element we do not assume that the innerContainer
                    // is the parent but grab the parent from the first pill item to add the grouped pill
                    // We need to make sure it exists which it should do if there are any pills displayed
                    if (pillsElements[0]) {
                        pillsElements[0].parentElement?.appendChild(groupPillElement);
                        //check if the the group and the last pill fits
                        if (lastPillElementThatFits) {
                            const groupRect = groupPillElement.getBoundingClientRect();
                            const lastPillRect = (
                                lastPillElementThatFits as HTMLElement
                            ).getBoundingClientRect();
                            const pillsFit =
                                lastPillRect.width + groupRect.width <= innerRect.width;

                            if (!pillsFit) {
                                //looks like this pill doesn't fit so lets hide it
                                (lastPillElementThatFits as HTMLElement).classList.add(
                                    constrainedOptionsClassNames.hideItem
                                );
                                const numberOfItemsThatDoNotFitWithLastPill =
                                    numberOfItemsThatDoNotFit + 1;
                                const label = formatter({
                                    numOverflowOptions: numberOfItemsThatDoNotFitWithLastPill,
                                    numDisplayedOptions: 0,
                                });
                                groupPillElement.innerHTML = `<span>${label}</span>`;
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Reformats the 'removeItem' event data and calls the Select components 'onChange' callback.
     * @param event - Custom Event emitted from choices event.
     */
    private onOptionRemoved(event: any) {
        event.stopPropagation();
        // The choices.js 'remove' event is fired in an asynchronous fashion.
        // Since users can "remove" multiple items at once and we want to return all those in one event
        // we wait one "tick" to "collect" all of the events together.
        if (this.removedEventsList.length === 0) {
            Promise.resolve(this.suppressEvents).then(suppressEvents =>
                this.onOptionsRemoved(suppressEvents)
            );
        }
        this.removedEventsList.push({
            value: event.detail.value,
            label: unsanitizeHtml(event.detail.label),
        });

        if (this.props.constrainSelectedOptions) {
            this.constrainPills();
        }

        const inputEl = this.getInputEl();
        if (inputEl && this.choices.getValue().length == 0) {
            // unhide the placeholder input when no options are selected
            inputEl.removeAttribute(inputPlaceholderHasSelectedOptionsAttr);
        }
        this.updateRemoveIcon();
    }

    /**
     * Used to call the 'onChanges' callback, this is called when multiple options are removed
     * from the select widget.
     */
    private onOptionsRemoved(suppressEvents: boolean): void {
        const { onChange } = this.props;
        const type: SelectMultipleRemoveEvent['type'] = 'remove';
        const choicesValues = this.choices.getValue() as unknown as ChoicesSelectedOption[];
        if (onChange && !suppressEvents) {
            const options: SelectOptionLeaf[] = [...this.removedEventsList];
            const removedValues = options.map(option => option.value);
            const changeEvent: SelectMultipleChangeEvent = {
                type,
                removedOptions: options,
                removedValues,
                selectedOptions: getSelectedOptions(choicesValues),
                selectedValues: getSelectedValues(choicesValues),
            };
            onChange(changeEvent);
        }
        this.removedEventsList.length = 0;
    }

    /**
     * Function used to listen to the choices.js "choice" event.
     *
     * We used this function to 'un-select' an option when a user clicks
     * an option that has already been selected.
     *
     * The option will only be unselected if the renderSelectedOptions prop is true
     *
     * @param event - Custom Event emitted from choices event.
     */
    private onOptionClicked(event: any) {
        event.stopPropagation();
        const { renderSelectedOptions } = this.props;
        const choicesValues = this.choices.getValue(true) as unknown as ChoicesSelectedOption[];
        const optionClickedValue = event.detail.choice.value;

        if (
            renderSelectedOptions &&
            choicesValues.includes(optionClickedValue) &&
            !event.detail.choice.disabled
        ) {
            this.choices.removeActiveItemsByValue(optionClickedValue);
        }
    }

    /**
     * Calls the Select components 'onMenuShow' callback.
     * @param event - Custom Event emitted from choices event.
     */
    private onMenuShow(event: any): void {
        event.stopPropagation();
        const { onMenuShow: onMenuShow, menuPlacement } = this.props;

        if (!this.isMenuShowing) {
            updateDropdownTopPosition(this.getDropdownEl(), menuPlacement);
            if (onMenuShow && !this.suppressEvents) {
                onMenuShow();
            }
            //remove the chevron down icon and setting the stored reference to null
            if (this.chevronDownIcon) {
                this.chevronDownIcon.destroy();
            }
            this.updateChevronUpIcon();
        }
        this.isMenuShowing = true;
    }

    /**
     * Calls the Select components 'onMenuHide' callback.
     * @param event - Custom Event emitted from choices event.
     * @param componentConfig - config for client component.
     */
    private onMenuHide(event: any): void {
        event.stopPropagation();
        this.isMenuShowing = false;
        const { onMenuHide: onMenuHide } = this.props;
        resetDropdownTopPosition(this.getDropdownEl());
        if (onMenuHide && !this.suppressEvents) {
            onMenuHide();
        }
        //remove the chevron up icon before updating the chevron down icon
        if (this.chevronUpIcon) {
            this.chevronUpIcon.destroy();
        }
        this.updateChevronDownIcon();
    }

    private onBlur(event: any): void {
        event.stopPropagation();

        // Clean-up our attributes we set in case a user clicks outside the component
        // and does not click the input to close.
        this.canCloseMenu = false;

        const { onBlur: onBlur } = this.props;
        if (onBlur && !this.suppressEvents) {
            onBlur(event);
        }
    }

    private getInputElOrThrow(): HTMLInputElement {
        return nonNullableValueOrThrow(
            this.getInputEl(),
            'CommonSelectMultipleComponent.getInputEl: Unable to find the SelectMultiple input element'
        );
    }

    private getInputEl(): HTMLInputElement | null {
        const inputEl = this.selectOuterContainer.querySelector<HTMLInputElement>(
            `input.${selectMultipleClassNames.input}`
        );
        return inputEl;
    }

    private getInnerElOrThrow(): HTMLDivElement {
        return nonNullableValueOrThrow(
            this.getInnerEl(),
            'CommonSelectMultipleComponent.getInnerEl: Unable to find the SelectMultiple inner container element'
        );
    }

    private getInnerEl(): HTMLDivElement | null {
        const inputEl = this.selectOuterContainer.querySelector<HTMLInputElement>(
            `.${selectMultipleClassNames.containerInner}`
        );
        return inputEl;
    }

    private getDropdownEl(): HTMLDivElement {
        const dropdownEl = this.selectOuterContainer.querySelector<HTMLDivElement>(
            `.${selectMultipleClassNames.listDropdown} div[role="listbox"]`
        );
        return dropdownEl!;
    }

    private getClearAllButtonEl(): HTMLButtonElement | null {
        const buttonEl = this.selectOuterContainer.querySelector<HTMLButtonElement>(
            `[aria-label="${REMOVE_ITEM_TEXT}"]`
        );
        return buttonEl;
    }

    private getRemoveButtons(): NodeListOf<HTMLButtonElement> {
        const removeButtonList = this.selectOuterContainer.querySelectorAll<HTMLButtonElement>(
            `.${selectMultipleClassNames.button}`
        );
        return removeButtonList;
    }

    private getHighlightedRemoveButtons(): NodeListOf<HTMLButtonElement> {
        const highlightedButtonList = this.selectOuterContainer.querySelectorAll<HTMLButtonElement>(
            `.${selectMultipleClassNames.highlightedState} button`
        );
        return highlightedButtonList;
    }

    private getActiveDropdownListEl(): HTMLDivElement | null {
        const activeDropdownListEl = this.selectOuterContainer.querySelector<HTMLDivElement>(
            `.${selectMultipleClassNames.activeState}`
        );
        return activeDropdownListEl;
    }
}
