// This file is just the first scaffold of the variables migrated to JS from _spacing.scss file.
// Feel free to migrate the rest of the variables as you require.

const spacerBaseline = 0.5;

/**
 * List of possible spacer variants in the Design System
 */
export type Spacer =
    | 'baseline'
    | '0'
    | '1px'
    | '0_25'
    | '0_5'
    | '0_75'
    | '1'
    | '1_5'
    | '2'
    | '2_5'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10';

/**
 * GS Design System Spacing
 */
export const spacers: { [key in Spacer]: string } = {
    baseline: `${spacerBaseline}rem`,
    '0': '0',
    '1px': '1px',
    '0_25': `${spacerBaseline * 0.25}rem`,
    '0_5': `${spacerBaseline * 0.5}rem`,
    '0_75': `${spacerBaseline * 0.75}rem`,
    '1': `${spacerBaseline}rem`,
    '1_5': `${spacerBaseline * 1.5}rem`,
    '2': `${spacerBaseline * 2}rem`,
    '2_5': `${spacerBaseline * 2.5}rem`,
    '3': `${spacerBaseline * 3}rem`,
    '4': `${spacerBaseline * 4}rem`,
    '5': `${spacerBaseline * 5}rem`,
    '6': `${spacerBaseline * 6}rem`,
    '7': `${spacerBaseline * 7}rem`,
    '8': `${spacerBaseline * 8}rem`,
    '9': `${spacerBaseline * 9}rem`,
    '10': `${spacerBaseline * 10}rem`,
};
