import {
    StyleSheet,
    CssClassDefinitionsObject,
    CssProperties,
} from '@gs-ux-uitoolkit-common/style';
import {
    Theme,
    ThemeTypographyVariant,
    createComponentClassDefinitions,
} from '@gs-ux-uitoolkit-common/theme';
import { ListGroupSize } from './list-group-props';
import { DragAndDropIconPosition } from '@gs-ux-uitoolkit-react/drag-and-drop';
import { DeepReadonly } from 'ts-essentials';
import './list-group-item-theme-overrides';

export interface ListGroupItemStyleSheetProps {
    theme: Theme;
    size: ListGroupSize;
    dragAndDrop: boolean;
    action: boolean;
    active: boolean;
    disabled: boolean;
    iconPosition?: DragAndDropIconPosition;
}

export interface ListGroupItemCssClasses {
    root: string;
    itemDrag: string;
    dragHandle: string;
    itemText: string;
    itemHeading: string;
}

export type ListGroupItemStyledClasses = CssClassDefinitionsObject<keyof ListGroupItemCssClasses>;

export interface ListGroupItemStyleOverridesParams {
    props: DeepReadonly<ListGroupItemStyleSheetProps>;
    createDefaultStyledClasses: () => ListGroupItemStyledClasses;
}

export const listGroupItemStyleSheet = new StyleSheet(
    'list-group-item',
    (props: ListGroupItemStyleSheetProps) => {
        return createComponentClassDefinitions<
            ListGroupItemStyleSheetProps,
            ListGroupItemStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.listGroupItem);
    }
);

function createDefaultStyledClasses({
    theme,
    size,
    dragAndDrop,
    action,
    active,
    disabled,
    iconPosition = 'leading',
}: ListGroupItemStyleSheetProps): ListGroupItemStyledClasses {
    let itemHeadingTypography: ThemeTypographyVariant = 'heading06';
    let itemTextTypography: ThemeTypographyVariant = 'body02';

    let itemPaddingX = '16px';
    if (dragAndDrop) {
        itemPaddingX = '8px';
    }

    let itemPaddingY = '6px';
    if (dragAndDrop && size === 'lg') {
        itemPaddingY = '8px';
    }

    if (size === 'sm') {
        itemHeadingTypography = 'body03';
        itemTextTypography = 'caption01';
    } else if (size === 'lg') {
        itemHeadingTypography = 'heading05';
        itemTextTypography = 'body01';
    }

    const shades = theme.getSurfaceInteractionShades('primary');
    const selectedShades = theme.getSurfaceInteractionShades('primary', 'subtle');

    const hoverProperties: CssProperties = {};
    if (action) {
        hoverProperties.background = shades.hover;
        hoverProperties.cursor = 'pointer';
    }
    if (active) {
        hoverProperties.background = selectedShades.background;
    }
    if (disabled) {
        hoverProperties.background = shades.background;
        hoverProperties.cursor = 'default';
    }

    const hoverStyles = hoverProperties.background
        ? {
              '&:hover': {
                  ...hoverProperties,
              },
          }
        : {};

    return {
        root: {
            background: active ? selectedShades.background : shades.background,
            borderBottom: `1px solid ${theme.border.minimal}`,
            color: active ? selectedShades.text : shades.text,
            padding: `${itemPaddingY} ${itemPaddingX}`,
            margin: '0px 0px',
            opacity: disabled ? theme.state.disabledOpacity : 1.0,
            ...hoverStyles,
        },
        itemDrag: {
            padding: `calc(${itemPaddingY} - 1px) calc(${itemPaddingX} - 1px)`,
        },
        dragHandle:
            iconPosition === 'leading'
                ? {
                      marginRight: '8px',
                  }
                : {
                      marginLeft: '8px',
                  },

        itemText: {
            ...theme.typography[itemTextTypography],
            color: theme.text.secondary,
        },
        itemHeading: {
            ...theme.typography[itemHeadingTypography],
            marginBottom: '4px',
        },
    };
}
