import {useEffect} from 'react';

function useDocumentClick(callback) {
  useEffect(() => {
    const handleClick = (event) => {
      event.stopPropagation();
      callback(event);
      return false;
    };
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  });
}

export default useDocumentClick;
