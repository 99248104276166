import { useContext } from 'react';
import { ThemeContext } from './theme-context';
import { Theme, injectFontsForTheme } from '@gs-ux-uitoolkit-common/theme';
import { useEmotionInstance } from '@gs-ux-uitoolkit-react/style';

/**
 * React Hook to consume the UI Toolkit Theme
 */
export function useTheme(): Theme {
    const theme = useContext(ThemeContext);

    // For SSR in Next.js's App Router, we need to use a context-provided
    // Emotion instance provided by <AppRouterStyleRegistry> in the
    // '@gs-ux-uitoolkit-react/next' package. Otherwise this will be the global
    // Emotion instance.
    const emotionInstance = useEmotionInstance();

    // Inject any fonts into the DOM that the Theme requires
    injectFontsForTheme(theme, { emotionInstance });

    return theme;
}
