import React from 'react';
import PropTypes from 'prop-types';
const Repeater = ({ items, passItemsAsProps = false,  itemKeyName='item', props, noDataMessage, children }) => {
  const childrenArray = React.Children.toArray(children);
  const Child = childrenArray[0].type;
  return (
    items && items.length > 0 && items.map((item, index) => {
      const obj = {[itemKeyName]: item};
      return  passItemsAsProps ? <Child {...item} key={index} index={index} {...props} /> : <Child {...obj} key={index} {...props} index={index} />;
    }
    ) || <div>{noDataMessage}</div>
  );
};

Repeater.defaultProps = {
  noDataMessage: ''
}
Repeater.propTypes = {
  items:PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  props: PropTypes.object,
  noDataMessage: PropTypes.string,
  passItemsAsProps: PropTypes.bool,
  itemKeyName: PropTypes.string
};

export default Repeater;
