import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  Edit, CheckSelect, Select, Add, Section,
  MultiLine, LinkItem, RadioItem
} from './Item';
import Divider from '../Divider';

/* eslint-disable */
const withItem =  ItemComponent => (props) => {
  const {id, selected} = props;
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);

  return (
    <a
      className=""
      id={id}
      onFocus={(e)=> {e.stopPropagation(); setFocus(true)}}
      onBlur={(e)=> {e.stopPropagation();  setFocus(false)}}
      onMouseEnter={(e)=> {e.stopPropagation(); setHover(true)}}
      onMouseLeave={(e)=> {e.stopPropagation();  setHover(false)}}
      href="javascript:void(0)">
      <ItemComponent {...props}  hover={hover} focus={focus} selected={selected} />
    </a>
  );

};
/* eslint-enabled */




const Row  = (props) => {
  const {type = 'select'} = props;
  return <React.Fragment>
    {(() => {
      switch(type) {
        case 'checkbox':
          return withItem(CheckSelect)(props);
        case 'edit':
          return withItem(Edit)(props);
        case 'add':
          return withItem(Add)(props);
        case 'section':
          return withItem(Section)(props);
        case 'multi-line':
          return withItem(MultiLine)(props);
        case 'divider':
          return <Divider/>;
        case 'link':
          return withItem(LinkItem)(props);
        case 'radio':
          return withItem(RadioItem)(props);
        default:
          return withItem(Select)(props);
      }
    })()}
  </React.Fragment>
};


Row.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  remove:PropTypes.bool,
  type: PropTypes.string,
  checkIcon:PropTypes.bool
};

export default Row;
