import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { ContainerProps, defaultContainerProps } from './container-props';
import { breakpoints as defaultBreakpoints } from './breakpoints';
import { grid as defaultGrids } from './grid';
import { DeepReadonly } from 'ts-essentials';
import './container-theme-overrides';

export interface ContainerStyleSheetProps {
    theme: Theme;
    breakpoints?: ContainerProps['breakpoints'];
    grid?: ContainerProps['grid'];
    density?: ContainerProps['density'];
    maxWidth?: ContainerProps['maxWidth'];
}

export interface ContainerCssClasses {
    root: string;
}

export type ContainerStyledClasses = CssClassDefinitionsObject<keyof ContainerCssClasses>;

export interface ContainerStyleOverridesParams {
    props: DeepReadonly<ContainerStyleSheetProps>;
    createDefaultStyledClasses: () => ContainerStyledClasses;
}

export const containerStyleSheet = new StyleSheet(
    'container',
    (props: ContainerStyleSheetProps) => {
        return createComponentClassDefinitions<ContainerStyleSheetProps, ContainerStyledClasses>(
            props,
            createDefaultStyledClasses,
            props.theme.styleOverrides?.container
        );
    }
);

function createDefaultStyledClasses({
    breakpoints = defaultBreakpoints,
    density = defaultContainerProps.density,
    maxWidth = defaultContainerProps.maxWidth,
    grid: customGrid,
}: ContainerStyleSheetProps): ContainerStyledClasses {
    const grid = customGrid || defaultGrids[density];
    return {
        root: {
            display: 'block',
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: maxWidth <= 0 || maxWidth >= Number.MAX_VALUE ? '100%' : `${maxWidth}px`,
            boxSizing: 'border-box',

            '& > *': {
                boxSizing: 'border-box',
            },

            [`@media (min-width:${breakpoints.xs.minWidth})`]: {
                paddingLeft: `${grid.xs.margin}px`,
                paddingRight: `${grid.xs.margin}px`,
            },
            [`@media (min-width:${breakpoints.sm.minWidth}px)`]: {
                paddingLeft: `${grid.sm.margin}px`,
                paddingRight: `${grid.sm.margin}px`,
            },
            [`@media (min-width:${breakpoints.md.minWidth}px)`]: {
                paddingLeft: `${grid.md.margin}px`,
                paddingRight: `${grid.md.margin}px`,
            },
            [`@media (min-width:${breakpoints.lg.minWidth}px)`]: {
                paddingLeft: `${grid.lg.margin}px`,
                paddingRight: `${grid.lg.margin}px`,
            },
            [`@media (min-width:${breakpoints.xl.minWidth}px)`]: {
                paddingLeft: `${grid.xl.margin}px`,
                paddingRight: `${grid.xl.margin}px`,
            },
            [`@media (min-width:${breakpoints.xxl.minWidth}px)`]: {
                paddingLeft: `${grid.xxl.margin}px`,
                paddingRight: `${grid.xxl.margin}px`,
            },
            [`@media (min-width:${breakpoints.xxxl.minWidth}px)`]: {
                paddingLeft: `${grid.xxxl.margin}px`,
                paddingRight: `${grid.xxxl.margin}px`,
            },
        },
    };
}
