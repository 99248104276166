const errorTypes = {
  'UNKNOWN_ERROR': 'UNKNOWN_ERROR',
  'PAGE_NOT_FOUND_ERROR': 'PAGE_NOT_FOUND_ERROR',
  'NOT_FOUND': 'NOT_FOUND',
  'SYSTEM_FAILURE': 'SYSTEM_FAILURE',
  'BUSINESS_FAILURE': 'BUSINESS_FAILURE',
  'SERVICE_ERROR': 'SERVICE_ERROR',
  'UNKNOWN_SERVICE_ERROR': 'UNKNOWN_SERVICE_ERROR',
  'END_POINT_NOT_AVAILABLE': 'END_POINT_NOT_AVAILABLE',
  'CONFIG_ERROR': 'CONFIG_ERROR',
  'CONFIG_ERROR_TYPE_STRING': 'CONFIG_ERROR_TYPE_STRING',
  'PARAMETER_ERROR': 'PARAMETER_ERROR',
  'AUTHORIZATION': 'AUTHORIZATION',
  'STALE_VERSION': 'STALE_VERSION',
  'TOO_MUCH_DATA': 'TOO_MUCH_DATA',
  'DUPLICATE_VIEW_NAME': 'DUPLICATE_VIEW_NAME',
  'VIEW_DOES_NOT_EXIST': 'VIEW_DOES_NOT_EXIST',
  'VERIFY_FAIL': 'VERIFY_FAIL',
  'PLACE_FAIL': 'PLACE_FAIL',
  'TA_ACCOUNT_NOT_FOUND': 'TA_ACCOUNT_NOT_FOUND',
  'UNABLE_TO_FETCH_ACCOUNTS': 'UNABLE_TO_FETCH_ACCOUNTS',
  'ACCOUNT_INVALID': 'ACCOUNT_INVALID',
  'INVALID_BIN': 'INVALID_BIN',
  'TRADE_APPROVAL_RETRIEVE_FAIL': 'TRADE_APPROVAL_RETRIEVE_FAIL',
  'TRADE_APPROVAL_TRANSACTION_FETCH_FAIL': 'TRADE_APPROVAL_TRANSACTION_FETCH_FAIL',
  'TRADE_ACTIVITY_STATUS_FAIL': 'TRADE_ACTIVITY_STATUS_FAIL',
  'TRANSACTION_DETAILS_MISSING': 'TRANSACTION_DETAILS_MISSING',
  'TRADE_APPROVALS_FAIL': 'TRADE_APPROVALS_FAIL',
  'TRADE_APPROVALS_DUPLICATE': 'TRADE_APPROVALS_DUPLICATE'
};

// note: Please use same constant as above to create following error messages enum
export const errorMessages = {
  '403': {
    'tkTitle': 'tkError403Title',
    'tkBody': 'tkError403Body'
  },
  '404': {
    'tkTitle': 'tkError404Title',
    'tkBody': 'tkError404Body',
    'tkButton': 'tkError404Button',
    'tkLink': '\\'
  },
  '500': {
    'tkTitle': 'tkError500Title',
    'tkBody': 'tkError500Body'
  },
  'AUTHORIZATION' : {
    'tkTitle': 'tkError403Title',
    'tkBody': 'tkError403Body'
  },
  'PAGE_NOT_FOUND_ERROR': {
    'tkTitle': 'tkError404Title',
    'tkBody': 'tkError404Body',
    'tkButton': 'tkError404Button'
  },
  'UNKNOWN_SERVICE_ERROR': {
    'tkTitle': 'tkError500Title',
    'tkBody': 'tkError500Body'
  },
  'SYSTEM_FAILURE': {
    'tkTitle': 'tkError500Title',
    'tkBody': 'tkError500Body'
  },
  'BUSINESS_FAILURE': {
    'tkTitle': 'tkError500Title',
    'tkBody': 'tkError500Body'
  },
  'TRADE_ACTIVITY_STATUS_FAIL': {
    'tkTitle': 'tkError500Title',
    'tkBody': 'tkError500Body'
  },
  'TRADE_APPROVALS_DUPLICATE': {
    'tkTitle': 'tkTradeDuplicateApprovalErrorTitle',
    'tkBody': 'tkTradeDuplicateApprovalErrorBody'
  }
};

export const pageErrorMessage = {
  [errorTypes.TRADE_APPROVAL_TRANSACTION_FETCH_FAIL]: 'tkTradeAprovalTransactionFail',
  [errorTypes.TRADE_APPROVAL_RETRIEVE_FAIL]: 'tkTradeApprovalFail',
  [errorTypes.TRANSACTION_DETAILS_MISSING]: 'tkCtsTransactionFail'
};

export default errorTypes;
