export default {
  ADD_IMPORTED_TRADES:'ADD_IMPORTED_TRADES',
  REMOVE_IMPORTED_TRADES:'REMOVE_IMPORTED_TRADES',
  OPEN_IMPORT_TRADES_MODAL: 'OPEN_IMPORT_TRADES_MODAL',
  CLOSE_IMPORT_TRADES_MODAL: 'CLOSE_IMPORT_TRADES_MODAL',
  RESET_IMPORT_TRADES_MODAL_STATE: 'RESET_IMPORT_TRADES_MODAL_STATE',
  SAVING_IMPORTED_TRADES_DATA_LOADING: 'SAVING_IMPORTED_TRADES_DATA_LOADING',
  ADD_IMPORTED_TRADES_SAVE_RESPONSE_DATA: 'ADD_IMPORTED_TRADES_SAVE_RESPONSE_DATA',
  SELECT_IMPORT_TRADES_FIRM_AND_BRANCH: 'SELECT_IMPORT_TRADES_FIRM_AND_BRANCH',
  EDIT_IMPORT_TRADES_PREFERENCES: 'EDIT_IMPORT_TRADES_PREFERENCES',
  RESET_IMPORT_TRADES_MODAL_SNACKBAR_STATE: 'RESET_IMPORT_TRADES_MODAL_SNACKBAR_STATE'
};
