import { DraViewConfig } from '@gs-ux-uitoolkit-common/datacore';

/**
 * Extra Technical Field names from the ones in the viewConfig
 */
export const ExtraDraTechnicalFields = {
    // isSelected means it's selected but include children of groups
    isSelectedField: '__IS_SELECTED',
    leafField: '__LEAF',
    numRecursiveField: 'NumRecursive',
    rowIdUniqueField: 'RowId_unique',
    schemaField: '__SCHEMA',
    // Selection state means that it's a top row being selected i.e. excluding children of a group
    selectionStateField: '__SELECTION_STATE',
    styleFieldPrefix: 'STYLE',
};

const styleFieldRegex = /STYLE\[(.*)\]/;

export class DraTechnicalFieldHelper {
    public isTechnicalField(field: string, viewConfig: DraViewConfig): boolean {
        return (
            field === viewConfig.depthField ||
            field === viewConfig.groupKeyField ||
            field === viewConfig.idxField ||
            field === viewConfig.isExpandedField ||
            field === viewConfig.isLeafField ||
            field === viewConfig.nodeIdField ||
            field === viewConfig.numRowsField ||
            field === viewConfig.pathField ||
            field === ExtraDraTechnicalFields.numRecursiveField ||
            field === ExtraDraTechnicalFields.leafField ||
            field === ExtraDraTechnicalFields.isSelectedField ||
            field === ExtraDraTechnicalFields.schemaField ||
            field === ExtraDraTechnicalFields.selectionStateField ||
            field === ExtraDraTechnicalFields.rowIdUniqueField ||
            field.includes(ExtraDraTechnicalFields.styleFieldPrefix)
        );
    }
    public isStyleColumn(field: string): boolean {
        return field.includes(ExtraDraTechnicalFields.styleFieldPrefix);
    }
    public getStyleColumnField(field: string): string {
        const result = styleFieldRegex.exec(field);
        if (result) {
            return result[1];
        }
        return '';
    }
}

export const draTechnicalFieldHelper = new DraTechnicalFieldHelper();
