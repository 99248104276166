import { MaskedColumnActionKeys } from './action-constants';

export type MaskedColumnActionTypes =
    | AddMaskedColumnAction
    | AddMaskedColumnListAction
    | RemoveMaskedColumnListAction;

export interface AddMaskedColumnAction {
    columnId: string;
    type: MaskedColumnActionKeys.ADD_MASKED_COLUMN;
}

export const AddColumnMasking = (columnId: string): AddMaskedColumnAction => ({
    columnId,
    type: MaskedColumnActionKeys.ADD_MASKED_COLUMN,
});
export interface AddMaskedColumnListAction {
    columnIdList: string[];
    type: MaskedColumnActionKeys.ADD_MASKED_COLUMN_LIST;
}

export const AddColumnMaskingList = (columnIdList: string[]): AddMaskedColumnListAction => ({
    columnIdList,
    type: MaskedColumnActionKeys.ADD_MASKED_COLUMN_LIST,
});
export interface RemoveMaskedColumnListAction {
    columnIdList: string[];
    type: MaskedColumnActionKeys.REMOVE_MASKED_COLUMN_LIST;
}

export const RemoveColumnMaskingList = (columnIdList: string[]): RemoveMaskedColumnListAction => ({
    columnIdList,
    type: MaskedColumnActionKeys.REMOVE_MASKED_COLUMN_LIST,
});
