import { FC, memo, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    buttonGroupStyleSheet,
    getButtonGroupClassNames,
    defaultButtonGroupProps,
    buttonSizes,
    buttonGroupOrientations,
    ButtonGroupContext as ButtonGroupContextType,
} from '@gs-ux-uitoolkit-common/button';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { ButtonGroupProps } from './button-group-props';
import { ButtonGroupContext } from './button-group-context';
import { componentAnalytics } from '../analytics-tracking';
/**
 * ButtonGroups style buttons into a logical unit.
 */
export const ButtonGroup: FC<ButtonGroupProps> = memo(props => {
    const {
        size,
        name,
        orientation = defaultButtonGroupProps.orientation,
        className,
        classes: overrideClasses,
        ...otherProps
    } = props;

    useTheme(); // not consuming the theme just yet, but injects the fonts into the DOM
    const cssClasses = useStyleSheet(buttonGroupStyleSheet, {
        orientation,
    });

    const rootClasses = getButtonGroupClassNames({
        orientation,
        cssClasses,
        overrideClasses,
        className,
    });

    const context: ButtonGroupContextType = useMemo(() => ({ name, size }), [name, size]);

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({
            componentName: 'button-group',
            officialComponentName: 'other',
        });
    }, []); // Only run once

    return (
        <ButtonGroupContext.Provider value={context}>
            <div
                {...otherProps}
                className={rootClasses}
                data-gs-uitk-component="button-group"
                data-orientation={orientation}
            />
        </ButtonGroupContext.Provider>
    );
});
ButtonGroup.displayName = 'ButtonGroup';

ButtonGroup.propTypes = {
    name: PropTypes.string,
    size: PropTypes.oneOf(buttonSizes),
    orientation: PropTypes.oneOf(buttonGroupOrientations),
    className: PropTypes.string,
};
