// tslint:disable:no-submodule-imports
import { DayElement, Instance } from 'flatpickr/dist/types/instance';
// @ts-expect-error: Hook is required to be imported because api-extractor can not parse dynamic import()'s in .d.ts files.
import { BaseOptions, Hook } from 'flatpickr/dist/types/options';
import { DatePickerProps } from '../date-picker-props';
import { getDatePickerRootClasses } from '../date-picker-util';
// @ts-expect-error BaseOptionsModified is required to be imported because api-extractor can not parse dynamic import()'s in .d.ts files
import { DatePickerHooks, DatePickerOptions, BaseOptionsModified } from '../options';

import { DatePickerCssClasses } from './../date-picker-style-sheet';

// Helper function to style a flatpickr instance with the correct gs styling
export const styleDatePicker = (
    instance: Instance,
    options: Partial<BaseOptions> | DatePickerOptions,
    cssClasses: DatePickerCssClasses,
    overrideClasses?: DatePickerProps['classes']
): Instance => {
    if (instance.calendarContainer) {
        // Need to manually unset the calculated width as it is wrong.
        instance.calendarContainer.style.width = '';
        const flatpickrDays = instance.calendarContainer.querySelector(
            '.flatpickr-days'
        ) as DayElement;
        if (flatpickrDays) {
            flatpickrDays.style.width = '';
        }
        //remove the existing root class so there won't be more than one
        //root class when updating the datepicker style
        const fullClassList = instance.calendarContainer.classList;
        for (let i = 0; i < fullClassList.length; ++i) {
            if (fullClassList[i].includes('gs-uitk-c-')) {
                fullClassList.remove(fullClassList[i]);
                break;
            }
        }

        applyRootClasses(instance, cssClasses, overrideClasses);
        //Adding aria-disabled=true to all prevMonthDay and nextMonthDay dates,
        //so a11y check won't throw error for color-contrast
        const adjacentMonthsDates = document.querySelectorAll('.prevMonthDay, .nextMonthDay');
        adjacentMonthsDates.forEach(function (el) {
            el.setAttribute('aria-disabled', 'true');
        });
    }

    if (Object.prototype.hasOwnProperty.call(options, 'altInput')) {
        instance.element.classList.toggle('flatpickr-altInput', options.altInput);
    }

    // When altInput is instantiated, its class should dynamically match the class for
    // input. Since the instantiation of the toolkit input component is not controlled
    // within the datepicker component, setTimeout is needed to ensure the rendering of
    // the input component is completed before we retrieves the input class.
    // Note: altInput is instantiated by flatpickr.
    setTimeout(() => {
        if (instance.altInput) {
            const inputClassList = instance.input.classList;

            const altInputClassList = instance.altInput.classList;
            for (let i = 0; i < altInputClassList.length; ++i) {
                //remove the existing class on inputAlt
                if (altInputClassList[i].includes('gs-uitk-c-')) {
                    altInputClassList.remove(altInputClassList[i]);
                    break;
                }
            }

            for (let i = 0; i < inputClassList.length; ++i) {
                //the class on inputAlt should match the input class
                if (inputClassList[i].includes('gs-uitk-c-')) {
                    instance.altInput.classList.add(inputClassList[i]);
                    break;
                }
            }
        }
    });

    return instance;
};

export const destroyFlatpickr = (flatpickr: Instance) => {
    // covering all bases on destruction to prevent
    // 'this.flatpickr.destroy is not a function' errors
    if (typeof flatpickr.destroy === 'function') {
        flatpickr.destroy();
    }
};

export const applyOnChangeHooks = (
    selectedDates: Date[],
    dateStr: string,
    instance: Instance,
    userOnChange: DatePickerHooks | null
) => {
    instance.calendarContainer.classList.toggle('rangeSelected', selectedDates.length > 1);

    if (userOnChange) {
        if (Array.isArray(userOnChange)) {
            userOnChange.forEach(func => func(selectedDates, dateStr, instance));
        } else {
            userOnChange(selectedDates, dateStr, instance);
        }
    }
};

export const applyOnCloseHooks = (
    selectedDates: Date[],
    dateStr: string,
    instance: Instance,
    userOnClose: DatePickerHooks | null
) => {
    if (userOnClose) {
        if (Array.isArray(userOnClose)) {
            userOnClose.forEach(func => func(selectedDates, dateStr, instance));
        } else {
            userOnClose(selectedDates, dateStr, instance);
        }
    }
};

export const applyRootClasses = (
    instance: Instance,
    cssClasses: DatePickerCssClasses,
    overrideClasses?: DatePickerProps['classes']
) => {
    // In order to support the scoped stylesheet (main-scoped.min.css,
    // which allows the toolkit global styles to be scoped to only a
    // subsection of a page), we need to add the 'gs-uitk-scoped' css
    // class to the calendar element. This is because the calendar
    // element is attached to the document body by default, and would
    // therefore not fall under the element that a developer has added
    // the 'gs-uitk-scoped' CSS class to
    instance.calendarContainer.classList.add('gs-uitk-scoped');
    const rootClasses = getDatePickerRootClasses({ cssClasses, overrideClasses });
    for (const rootClass of rootClasses.split(' ')) {
        instance.calendarContainer.classList.add(rootClass);
    }
};
