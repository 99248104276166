import { ComponentProps, CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { FormSize, FormStatus } from './form-types';

export interface FormGroupProps<StyleType = CommonStyleType> extends ComponentProps<StyleType> {
    /**
     * Sets the `disabled` state for all child input components.
     */
    disabled?: boolean;

    /**
     * Set `inline` on descendant input components.
     */
    inline?: boolean;

    /**
     * Sets margin spacing appropriate for the size.
     */
    size?: FormSize;

    /**
     * Sets the `status` value for all child input components.
     */
    status?: FormStatus;
}

export const defaultFormGroupProps: DefaultFormGroupProps = {
    size: 'md',
};

export type DefaultFormGroupProps = Required<Pick<FormGroupProps, 'size'>>;
