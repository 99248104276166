/* ####################################################################################

    DIFFERENT MODULES HAVE DIFFERENT KEYS(ID) IN REDUCER AND ACCORDING TO THAT WE ARE
    UPDATE REDUCER. TO ADD NEW REDUCER, ADD KEY IN ROOTREDUCER UNDER
    MODULE REDUCER. CODE WILL CREATE REDUCER WITH DEFAULT DATA.

######################################################################################### */

import { gridActionTypes, withId } from '../actionsCreator';
import { replaceActiveView } from '../../../helpers/gridFund';
import actionTypes from '../../../actions/page/actionTypes';
import { COLUMN_COLID, SORT } from '../../../constants/pageConstants';
import { sortWithFlatDataWithGrouping } from '../../../utils/sorting';

export class DefaultGridState {
  constructor(data = {}){
    this.views = data.views || [{
      id: '',
      columns: [],
      groupBy: 'none'
    }];
    this.columnsMetadata = data.columnsMetadata || { columns: [] }
    this.groupBy = data.groupBy || '';
    this.groupColumns = data.groupColumns || [];
    this.gridData = data.gridData || {};
    this.filterBy = data.filterBy || [];
    this.filterModel = data.filterModel || null;
    this.loading = data.loading || false;
    this.filterVisibility = data.filterVisibility || 'hidden';
    this.gridData = data.gridData || { data : [] };
    this.filteredDataLength = data.filteredDataLength || null
    this.filterInErrorState = !!data.filterInErrorState
    this.exportExcel = data.exportExcel || undefined
    this.filteredDataLength = data.filteredDataLength || undefined
    this.dataMapping = data.dataMapping || {};
  }
}

const defaultGridData = new DefaultGridState();

export function gridModuleReducer(state = defaultGridData, action){
  const withGridId = actionType => withId(actionType, action.gridId);

  switch (action.type) {
    case actionTypes.UPDATE_PAGE_ID: {
      return new DefaultGridState();
    }

    case withGridId(gridActionTypes.ADD_GRID_DATA): {
      return { ...state, ...action.data };
    }

    case withGridId(gridActionTypes.GRID_FILTER_CHNAGE): {
      return { ...state, filterBy: action.data.filterBy, filterModel: action.data.filterModel, filteredDataLength: action.data.filteredDataLength, filterInErrorState: false };
    }

    case withGridId(gridActionTypes.GRID_GROUP_CHNAGE): {
      return { ...state, groupBy: action.data.groupBy, views: replaceActiveView(state.views, action.data) };
    }

    case withGridId(gridActionTypes.GRID_COLUMN_WIDTH_CHANGE):
    case withGridId(gridActionTypes.GRID_COLUMN_REMOVE_CHANGE):
    case withGridId(gridActionTypes.GRID_COLUMN_ORDER_CHANGE): {
      return { ...state, views: replaceActiveView(state.views, action.data)};
    }
    case withGridId(gridActionTypes.GRID_TOGGLE_FILTER_VISIBILITY): {
      return { ...state, filterVisibility: action.data, filterModel: {}, filterBy: [], filterInErrorState: false };
    }

    case withGridId(gridActionTypes.GRID_COLUMN_SORT_CHANGE): {
      const views = replaceActiveView(state.views, action.data);
      return { ...state, views, gridData: { data: action.sortedData } };
    }

    case withGridId(gridActionTypes.GRID_VIEW_CHNAGE): {
      const views = state.views.map(data => ({...data, isActive: data.id === action.data.id}));
      const view = views.find(data => data.isActive);
      return { ...state,  views, groupBy: view.groupBy };
    }

    case withGridId(gridActionTypes.GRID_CLEAN_DATA): {
      return { ...defaultGridData };
    }

    case withGridId(gridActionTypes.GRID_FILTER_WITH_ERROR): {
      return { ...state, filterInErrorState : true}
    }

    case withGridId(gridActionTypes.GRID_START_EXPORT_TO_EXCEL): {
      return { ...state, exportExcel : action.data }
    }

    case withGridId(gridActionTypes.CHANGE_DOWNLOAD_FILE_TYPE): {
      return { ...state, transactionsFileDownloadType: action.data };
    }

    case withGridId(gridActionTypes.SAVE_CUSTOM_VIEW):
    case withGridId(gridActionTypes.EDIT_CUSTOM_VIEW):
    case withGridId(gridActionTypes.DELETE_CUSTOM_VIEW): {
      return { ...state, isModalLoading: true };
    }

    case withGridId(gridActionTypes.OPEN_CUSTOM_VIEW_MODAL): {
      return {
        ...state,
        isOpenCustomViewModal: action.data.open,
        selectedViewId: action.data.selectedViewId,
        modelActiveMode: action.data.modelActiveMode
      };
    }

    case withGridId(gridActionTypes.GRID_SORT_DATA): {
      const activeView = state.views.find(view => view.isActive);
      const columns = activeView.columns.filter(col => (col.sort === SORT.ASC || col.sort === SORT.DESC));
      const sortedMeta = columns.map(column => {
        const meta = state.columnsMetadata.columns.find((col => col.colId === column.colId))
        return {
          ...column,
          ...meta
        }
      });
      if (!sortedMeta.find(col => col.colId === COLUMN_COLID.GROUP)) {
        const { dataType } = state.columnsMetadata.columns.find(col => col.field === activeView.groupBy) || { dataType: 'string'};
        sortedMeta.push({colId: COLUMN_COLID.GROUP, field: COLUMN_COLID.GROUP, dataType, sort: SORT.ASC })
      }
      const selectedRow = action.data && action.data.selectedRow || [];
      const gridData = sortWithFlatDataWithGrouping(state.gridData.data, sortedMeta, selectedRow, activeView.groupBy);
      return {
        ...state,
        gridData: { data: gridData }
      }
    }

    case withGridId(gridActionTypes.CLOSE_CUSTOM_VIEW_MODAL): {
      return {
        ...state,
        modelActiveMode: '',
        selectedViewId: '',
        isOpenCustomViewModal: false
      };
    }

    default:
      return state;
  }
}

function createWrapperReducer(reducerFunction, reducerName) {
  return (state, action) => {
    const { gridId, type } = action
   const isInitializationCall = state === undefined
   if (gridId !== reducerName && !isInitializationCall && type !== actionTypes.UPDATE_PAGE_ID) return state
    return reducerFunction(state, action)
  }
}

export const getAllGridReducers = keys => {
  const reducers = {};
  keys.forEach(key => {
      reducers[key] = createWrapperReducer(gridModuleReducer, key)
    }
  )
  return reducers;
}
