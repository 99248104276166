import {
    StyleSheet,
    CssClassDefinitionsObject,
    CssPropertyType,
} from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { FormSize } from './form-types';
import { FormGroupProps } from './form-group-props';
import { DeepReadonly } from 'ts-essentials';
import './form-group-theme-overrides';

export interface FormGroupStyleSheetProps {
    theme: Theme;
    inline: FormGroupProps['inline'];
    size: FormSize;
}

export interface FormGroupCssClasses {
    root: string;
}

export type FormGroupStyledClasses = CssClassDefinitionsObject<keyof FormGroupCssClasses>;

export interface FormGroupStyleOverridesParams {
    props: DeepReadonly<FormGroupStyleSheetProps>;
    createDefaultStyledClasses: () => FormGroupStyledClasses;
}

export const formGroupStyleSheet = new StyleSheet(
    'form-group',
    (props: FormGroupStyleSheetProps) => {
        return createComponentClassDefinitions<FormGroupStyleSheetProps, FormGroupStyledClasses>(
            props,
            createDefaultStyledClasses,
            props.theme.styleOverrides?.formGroup
        );
    }
);

function createDefaultStyledClasses({
    size,
    inline,
}: FormGroupStyleSheetProps): FormGroupStyledClasses {
    return {
        root: {
            marginBottom: formGroupSizeVariants[size].marginBottom,
            display: inline ? 'inline-block' : 'block',
        },
    };
}

const formGroupSizeVariants: { [name in FormSize]: FormGroupSizeVariant } = {
    sm: {
        marginBottom: '12px',
    },
    md: {
        marginBottom: '16px',
    },
    lg: {
        marginBottom: '24px',
    },
};

interface FormGroupSizeVariant {
    marginBottom: CssPropertyType['marginBottom'];
}
