/* eslint-disable react-hooks/exhaustive-deps */
// TODO: https://jira.site.gs.com/browse/UX-14776
// TODO: Remove the dependency of context from Menu (Keeping everything inside a context object is making it less optimisation friendly) https://jira.site.gs.com/browse/UX-15680
import { useContext, useMemo, useState } from 'react';
import {
    MenuBlurEvent,
    MenuValue,
    getStateValues,
    StateViewModel,
    SimpleMenuProps,
} from '@gs-ux-uitoolkit-common/menu';
import { isBrowser } from '@gs-ux-uitoolkit-react/shared';
import { MenuSingleValueProps, MenuMultipleValueProps } from './menu-react-props';
import { ReactMenuContext, MenuContext } from './menu-context';

export interface UseMenuContextCreatorSingleValue extends SimpleMenuProps, MenuSingleValueProps {}
export interface UseMenuContextCreatorMultipleValue
    extends SimpleMenuProps,
        MenuMultipleValueProps {}

export interface UseMenuContextCreatorProps {
    props: UseMenuContextCreatorSingleValue | UseMenuContextCreatorMultipleValue;
    getStateVm?: () => StateViewModel;
    onBlur: (event: MenuBlurEvent) => void;
}

export function useMenuContextCreator({
    props,
    getStateVm,
    onBlur,
}: UseMenuContextCreatorProps): ReactMenuContext {
    const parentContext = useContext(MenuContext);

    const [values, setValues] = useState(
        isUncontrolled() ? getStateValues(props.defaultValue) : getStateValues(props.value)
    );

    function isUncontrolled() {
        return !isControlled();
    }
    function isControlled() {
        return props.value != null;
    }

    function getValues(): Set<MenuValue> {
        return isUncontrolled() ? values : menuContext.values;
    }

    function onSubmenuShow() {
        if (getStateVm) {
            getStateVm().setSubmenuVisible(true);
        }
    }

    function onSubmenuHide(event: MenuBlurEvent) {
        let offMenuClick = false;
        if (getStateVm) {
            const menuEl = getStateVm().getMenuEl();
            const nextActiveEl = event.nextActiveElement as any;
            const eventIsOutsideDocument = isBrowser && window === nextActiveEl;
            if (eventIsOutsideDocument || (menuEl && !menuEl.contains(event.nextActiveElement))) {
                offMenuClick = true;
            }
            getStateVm().setSubmenuVisible(false);
        }
        if (event.input !== 'arrow' && offMenuClick) {
            onBlur(event);
        }
        if (getStateVm) {
            getStateVm().focus(event.nextActiveElement);
        }
    }

    function onAddChildren(
        children: HTMLElement[],
        afterElement: HTMLElement,
        autoFocus?: boolean
    ) {
        if (getStateVm) {
            getStateVm().addChildren(children, afterElement, autoFocus);
        }
    }

    function onRemoveChildren(afterElement: HTMLElement) {
        if (getStateVm) {
            getStateVm().removeChildren(afterElement);
        }
    }

    function onMenuOptionClick() {
        if (!props.multiple) {
            onBlur(
                new MenuBlurEvent({
                    menuElement: getStateVm ? getStateVm().getMenuEl() : null,
                    nextActiveElement: null,
                    input: 'selection',
                })
            );
        }
    }

    function onMenuOptionSelect(selectedValue: MenuValue) {
        const newValues: Set<MenuValue> = props.multiple
            ? new Set(getValues().add(selectedValue).values())
            : new Set([selectedValue]);
        updateValuesAfterChange(newValues);
    }

    function onMenuOptionDeselect(selectedValue: MenuValue) {
        getValues().delete(selectedValue);
        updateValuesAfterChange(new Set(getValues().values()));
    }

    function updateValuesAfterChange(newValues: Set<MenuValue>) {
        if (isUncontrolled()) {
            // If `value` is undefined, the component is uncontrolled and keeps
            // its own state internally.
            setValues(newValues);
        }
        // The mildly convoluted code below is necessary to pass type checking:
        const { onChange, multiple } = props;
        if (onChange) {
            const valuesArray = Array.from(newValues.values());
            if (multiple) {
                const onChangeMultiple = (onChange as MenuMultipleValueProps['onChange'])!;
                onChangeMultiple(valuesArray);
            } else {
                const onChangeSingle = (onChange as MenuSingleValueProps['onChange'])!;
                onChangeSingle(valuesArray.length ? valuesArray[0] : null);
            }
        }
    }

    function getDisabled() {
        if (props.disabled !== undefined) {
            return props.disabled;
        } else if (parentContext.disabled !== undefined) {
            return parentContext.disabled;
        }
        return false;
    }

    function getSize() {
        return props.size || parentContext.size || 'md';
    }

    function getMultiple() {
        if (props.multiple !== undefined) {
            return props.multiple;
        } else if (parentContext.multiple !== undefined) {
            return parentContext.multiple;
        }
        return false;
    }

    const menuContext = useMemo(() => {
        return {
            optionClick: onMenuOptionClick,
            submenuShow: onSubmenuShow,
            submenuHide: onSubmenuHide,
            addChildren: onAddChildren,
            removeChildren: onRemoveChildren,
            optionSelect: onMenuOptionSelect,
            optionDeselect: onMenuOptionDeselect,
            disabled: getDisabled(),
            size: getSize(),
            multiple: getMultiple(),
            values: isUncontrolled() ? values : getStateValues(props.value),
            depth: parentContext.depth + 1,
        };
    }, [
        props.value,
        props.size,
        props.multiple,
        props.disabled,
        props.onChange,
        parentContext.size,
        parentContext.multiple,
        parentContext.depth,
        parentContext.disabled,
        values,
    ]);
    return menuContext;
}
