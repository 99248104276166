import { Component, Fragment } from 'react';
import { FormGroup } from '@gs-ux-uitoolkit-react/form';
import { Label } from '@gs-ux-uitoolkit-react/label';
import { HintList, Alignment } from '@gs-ux-uitoolkit-common/datacore';
import { FormatControlProps } from '../column-hint-format-manager';
import { ButtonGroup, ButtonSelect, ButtonSelectChangeEvent } from '@gs-ux-uitoolkit-react/button';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { columnFormattingPluginStylePrefix } from '../../../../../style/plugins/column-hint-plugin-stylesheet';

export class ColumnHintControlAlignment extends Component<FormatControlProps> {
    public render() {
        return (
            <Fragment>
                <div className={`${columnFormattingPluginStylePrefix}_alignment-container`}>
                    <Label>Alignment</Label>
                    <FormGroup inline={true}>
                        <ButtonGroup>
                            <ButtonSelect
                                type="single"
                                onChange={this.onChangeAlignment}
                                value={'left'}
                                name="column-hints-alignment"
                                selected={this.props.columnHint.alignment === 'left'}
                                data-cy={`${columnFormattingPluginStylePrefix}_alignment-left`}
                            >
                                <Icon name="format-align-left" type="filled"></Icon>
                            </ButtonSelect>
                            <ButtonSelect
                                type="single"
                                onChange={this.onChangeAlignment}
                                value={'center'}
                                name="column-hints-alignment"
                                selected={this.props.columnHint.alignment === 'center'}
                                data-cy={`${columnFormattingPluginStylePrefix}_alignment-center`}
                            >
                                <Icon name="format-align-center" type="filled"></Icon>
                            </ButtonSelect>
                            <ButtonSelect
                                type="single"
                                onChange={this.onChangeAlignment}
                                value={'right'}
                                name="column-hints-alignment"
                                selected={this.props.columnHint.alignment === 'right'}
                                data-cy={`${columnFormattingPluginStylePrefix}_alignment-right`}
                            >
                                <Icon name="format-align-right" type="filled"></Icon>
                            </ButtonSelect>
                        </ButtonGroup>
                    </FormGroup>
                </div>
            </Fragment>
        );
    }

    private onChangeAlignment = (e: ButtonSelectChangeEvent) => {
        const value = e.currentTarget.value as Alignment;
        const clonedHint: HintList = {
            ...this.props.columnHint,
            alignment: value,
        };
        this.props.onChangeHint(clonedHint);
    };
}
