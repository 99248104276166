import { Placement, Size, Status } from '@gs-ux-uitoolkit-common/design-system';
import { CheckboxCssClasses } from './checkbox-style-sheet';

export type CheckboxCheckedState = boolean | 'indeterminate';

export interface CheckboxCommonProps<ChangeEventType = Event> {
    /**
     * Whether or not the checkbox is checked / indeterminate.
     */
    checked?: CheckboxCheckedState;

    /**
     * Additional classes to add on the root element.
     */
    className?: string;

    /**
     * Style classes to override.
     */
    classes?: Partial<CheckboxCssClasses>;

    /**
     * Whether or not the checkbox is in a disabled state.
     */
    disabled?: boolean;

    /**
     * Whether or not the checkbox starts off checked / indeterminate.
     */
    defaultChecked?: CheckboxCheckedState;

    /**
     * Whether or not the checkbox is inline.
     */
    inline?: boolean;

    /**
     * The name to set for the control.
     */
    name?: string;

    /**
     * Size of the checkbox.
     */
    size?: CheckboxSize;

    /**
     * Status of the checkbox.
     */
    status?: CheckboxStatus;

    /**
     * A value that can be paired with a name for form submission. Learn more at
     * [Mozilla Developer Network](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#Value).
     */
    value?: string;

    /**
     * Sets the `tabindex` attribute to customize how the checkbox participates in sequential
     * keyboard navigation. Avoid using this to prevent accessibility issues with screen readers.
     *
     * Learn more at [MDN docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex).
     */
    tabIndex?: number;

    /**
     * Handler for the value change event.
     */
    onChange?: (event: ChangeEventType) => void;

    labelPlacement?: CheckboxLabelPlacement;
}

export type CheckboxSize = Extract<Size, 'sm' | 'md' | 'lg'>;
export type CheckboxStatus = Extract<Status, 'none' | 'error'>;
export type CheckboxLabelPlacement = Extract<Placement, 'left' | 'right'>;

export const checkboxSizes: CheckboxSize[] = ['sm', 'md', 'lg'];
export const checkboxStatuses: CheckboxStatus[] = ['none', 'error'];

export type CheckboxDefaultProps = Required<
    Pick<CheckboxCommonProps, 'inline' | 'size' | 'status' | 'labelPlacement'>
>;

export const checkboxDefaultProps: CheckboxDefaultProps = {
    inline: false,
    labelPlacement: 'right',
    size: 'md',
    status: 'none',
};

export interface CheckboxChangeEventProps {
    /**
     * @deprecated Use `checkedState` instead
     */
    checked: boolean;

    /**
     * @deprecated Use `checkedState` instead
     */
    indeterminate?: boolean;
    checkedState: CheckboxCommonProps['checked'];
    name?: CheckboxCommonProps['name'];
    value?: CheckboxCommonProps['value'];
}
