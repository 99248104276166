import { ICellRendererParams, ValueGetterParams } from '@ag-grid-community/core';
import { HyperlinkFormat } from '@gs-ux-uitoolkit-common/datacore';
import { GridWrapper, agGridAutoColumn } from '../grid-wrappers/grid-wrapper';
import { rowGroupColumnColId } from '../datasources/dra-viewport-datasource';
import { DraGroupRenderer } from '../datasources/dra-group-renderer';
export const COLUMN_PREFIX: string = 'Column';
export const HyperlinkValueSeparator: string = '||';
export type CellValue = string | number | Date;
export interface ColumnData {
    [key: string]: CellValue;
}

export const HyperlinkRendererGenerator = (
    hyperlinkFormat: HyperlinkFormat,
    columnHintColumnId: string,
    wrapper: GridWrapper,
    originalValueGetter: string | ((params: ValueGetterParams) => any) | undefined
) => {
    const HyperlinkColumnValueGetter = {
        valueGetter(params: ValueGetterParams) {
            const data =
                params.node && params.node.group && params.node.groupData && !wrapper.isUsingDra()
                    ? params.node.groupData[agGridAutoColumn]
                    : params.data;
            if (!data) return '';

            // Keep data here as we have a valueGetter under the hood and we want the original value

            let columnValue = data[columnHintColumnId] || '';
            const valueGetter = originalValueGetter;
            if (valueGetter) {
                columnValue = wrapper.evaluateValueGetter(valueGetter, params, true);
            }
            columnValue = wrapper.getFormattedValueFromColumn(columnHintColumnId, columnValue);
            let url = !hyperlinkFormat.replace
                ? hyperlinkFormat.url
                : hyperlinkFormat.url.replace(
                      hyperlinkFormat.replace,
                      this.getColumnValueAsString(columnValue)
                  );
            // we use the valueGetter for other columns
            url = this.replaceColumnReferences(url, params.getValue);

            return JSON.stringify({
                columnValue,
                url,
            });
        },
        getColumnValueAsString(columnValue: CellValue) {
            return columnValue instanceof Date ? columnValue.toString() : String(columnValue);
        },
        replaceColumnReferences(url: string, getValue: (field: string) => any) {
            const replacedUrl = url.replace(
                new RegExp(`{${COLUMN_PREFIX}.(.*?)}`, `g`),
                (match: string, name: string) => {
                    if (name) {
                        const columnValue = this.getColumnValueAsString(getValue(name));
                        if (columnValue) {
                            return !columnValue ? '' : columnValue;
                        }
                    }
                    return match;
                }
            );
            return replacedUrl;
        },
    };
    const hyperlinkColumnValueGetter = HyperlinkColumnValueGetter.valueGetter.bind(
        HyperlinkColumnValueGetter
    );

    wrapper.setColumnValueGetter(
        columnHintColumnId,
        hyperlinkColumnValueGetter,
        originalValueGetter
    );

    return (params: ICellRendererParams) => {
        const Renderer = {
            getValue() {
                // data should be a stringified json object so we should convert it
                let data = { columnValue: params.value || '', url: '' };
                try {
                    const dataJson = JSON.parse(params.value);
                    data = typeof dataJson === 'object' ? dataJson : data;
                } catch {
                    data = { columnValue: params.value || '', url: '' };
                    // Couldn't convert params value for this column
                }

                if (!data.url) {
                    let url = !hyperlinkFormat.replace
                        ? hyperlinkFormat.url
                        : hyperlinkFormat.url.replace(
                              hyperlinkFormat.replace,
                              HyperlinkColumnValueGetter.getColumnValueAsString(params.value)
                          );
                    url = HyperlinkColumnValueGetter.replaceColumnReferences(url, params.getValue!);
                    data.url = url;
                }
                if (
                    (columnHintColumnId !== rowGroupColumnColId ||
                        (columnHintColumnId === rowGroupColumnColId &&
                            hyperlinkFormat.rowGroup === true)) &&
                    data.url !== '' &&
                    data.columnValue !== ''
                ) {
                    const color = wrapper.getTheme().text.link as string;
                    return (
                        <a
                            className="hyperlink-renderer"
                            href={data.url}
                            style={{ color }}
                            target={hyperlinkFormat.target || '_blank'}
                        >
                            {data.columnValue}
                        </a>
                    );
                }
                return data.columnValue || '';
            },
        };
        return Renderer.getValue();
    };
};

export const removeHyperlinkRenderer = (wrapper: GridWrapper, columnId: string) => {
    const datasource = wrapper.getDRADatasource();
    // If this is a dra grid then we need to remove the hyperlink cell renderer but keep the dra group cell renderer
    if (columnId === rowGroupColumnColId) {
        if (datasource) {
            wrapper.setCellRenderer(
                columnId,
                DraGroupRenderer(datasource.provider.viewConfig, wrapper.getCustomDRAOptions())
            );
        }
    } else {
        wrapper.removeCellRenderer(columnId);
    }
    // reset the column value getter
    wrapper.resetColumnValueGetterToInitialGetter(columnId);
};

export const addHyperlinkRenderer = (
    wrapper: GridWrapper,
    columnId: string,
    hyperlinkFormat: HyperlinkFormat,
    originalValueGetter: string | ((params: ValueGetterParams) => any) | undefined
) => {
    const datasource = wrapper.getDRADatasource();
    // If it's a dra pivot column then we need to ensure we use both the group and hyperlink renderers
    if (columnId === rowGroupColumnColId) {
        if (datasource) {
            wrapper.setCellRenderer(
                columnId,
                DraGroupRenderer(
                    datasource.provider.viewConfig,
                    wrapper.getCustomDRAOptions(),
                    HyperlinkRendererGenerator(
                        hyperlinkFormat,
                        columnId,
                        wrapper,
                        originalValueGetter
                    )
                )
            );
        }
    } else {
        wrapper.setCellRenderer(
            columnId,
            HyperlinkRendererGenerator(hyperlinkFormat, columnId, wrapper, originalValueGetter)
        );
    }
};
