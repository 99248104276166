import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './input-group-theme-overrides';

const RADIUS_PX = '2px';
export const inputGroupBorderRadius = {
    firstChild: `${RADIUS_PX} 0 0 ${RADIUS_PX}`,
    lastChild: `0 ${RADIUS_PX} ${RADIUS_PX} 0`,
};

export interface InputGroupStyleSheetProps {
    theme: Theme;
}

export interface InputGroupCssClasses {
    root: string;
}

export type InputGroupStyledClasses = CssClassDefinitionsObject<keyof InputGroupCssClasses>;

export interface InputGroupStyleOverridesParams {
    props: DeepReadonly<InputGroupStyleSheetProps>;
    createDefaultStyledClasses: () => InputGroupStyledClasses;
}

export const inputGroupStyleSheet = new StyleSheet(
    'input-group',
    (props: InputGroupStyleSheetProps) => {
        return createComponentClassDefinitions<InputGroupStyleSheetProps, InputGroupStyledClasses>(
            props,
            createDefaultStyledClasses,
            props.theme.styleOverrides?.inputGroup
        );
    }
);

export const globalInputGroupClass = 'gs-input-group';

export const BUTTON = '[data-gs-uitk-component=button]';
export const BUTTON_GROUP = '[data-gs-uitk-component=button-group]';
export const CONTENT = '[data-gs-uitk-component=input-group-content]';
export const INPUT = '[data-gs-uitk-component=input]';
export const INPUT_INTERNAL = '[data-gs-uitk-component=input-internal]';
export const DROPDOWN_SINGLE = '[data-cy=gs-uitk-dropdown-button-single]';
export const DROPDOWN_SPLIT = '[data-cy=gs-uitk-dropdown-button-split]';
export const DROPDOWN_TOGGLE = '[data-cy=gs-uitk-dropdown-button-toggle]';

function createDefaultStyledClasses(_props: InputGroupStyleSheetProps): InputGroupStyledClasses {
    return {
        root: {
            display: 'flex',
            position: 'relative',
            boxSizing: 'border-box',

            // Child InputGroup components have negative margins to allow the
            // borders to overlap. Padding offsets this to ensure the
            // component as a whole is positioned correctly.
            paddingLeft: '1px',
            paddingRight: '1px',
            '*': {
                boxSizing: 'border-box',
            },
            '*::before': {
                boxSizing: 'border-box',
            },
            '*::after': {
                boxSizing: 'border-box',
            },
            '& > *': {
                minWidth: 0,
            },
            // InputGroupContent:
            [`& > *:first-child${CONTENT}`]: {
                borderRadius: inputGroupBorderRadius.firstChild,
            },
            [`& > *:last-child${CONTENT}`]: {
                borderRadius: inputGroupBorderRadius.lastChild,
            },

            // Button:
            [`& > *:first-child${BUTTON}`]: {
                borderRadius: inputGroupBorderRadius.firstChild,
            },
            [`& > *:last-child${BUTTON}`]: {
                borderRadius: inputGroupBorderRadius.lastChild,
            },

            // Input:
            [`& > *:first-child${INPUT}`]: {
                borderRadius: inputGroupBorderRadius.firstChild,
            },
            [`& > *:last-child${INPUT}`]: {
                borderRadius: inputGroupBorderRadius.lastChild,
            },

            // DropdownButton:
            [`& > *:first-child ${DROPDOWN_SINGLE}`]: {
                borderRadius: inputGroupBorderRadius.firstChild,
            },
            [`& > *:last-child ${DROPDOWN_SINGLE}`]: {
                borderRadius: inputGroupBorderRadius.lastChild,
            },

            // DropdownButton splitToggle:
            [`& > *:first-child ${DROPDOWN_SPLIT}`]: {
                borderRadius: inputGroupBorderRadius.firstChild,
            },
            [`& > *:last-child ${DROPDOWN_TOGGLE}`]: {
                borderRadius: inputGroupBorderRadius.lastChild,
            },
        },
    };
}
