import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  benchmarkMetricsOptionsSelector,
  metricsOptionsSelector,
  selectedTabMetricsOptionSelector,
  thresholdsDeletedFlagSelector
} from '../../../selectors/pages/fundTracker';
import {
  lastShareclassIdsSelector,
  primaryMetricFromFundFinderOrPreferenceSelector,
  topOpeningBalanceShareclassIdsSelector
} from "../../../selectors/preferences";
import {FUND_TRACKER, MODULES_KEYS, SHOW_ERROR_ON} from "../../../constants/pageConstants";
import {useChartContext} from "../context";
import {deleteFundTrackerThresholds, loadFundTrackerCompareData} from '../../../actions/page/fundTracker';
import Button from "../../../components/core/Button";
import ChartDropdown from '../components/ChartDropdown';
import ChartComponent from '../components/HighCharts';
import CompareComponent from '../components/CompareComponent';
import ThresholdModal from '../components/ThresholdModal';
import {ChartActionTypes} from "../actions";
import {saveAppPreferences} from "../../../actions/preferences";
import {DefaultChartConfig} from "../configs";
import {
  addFundsOrBenchmarkSeriesToChart,
  addMetricsSeriesToChart,
  clearDataMissingMessages,
  getAllThresholdsForSelectedFunds,
  getChartZoomLevel,
  getNormalizedGridData,
  getSelectedShareClassIds,
  isAllThresholdsEmpty,
  verifyThresholds
} from "../utils";
import {useAsync} from "../reducers/Hooks";
import ChartColorsInstance from '../utils/chartColors';
import {addAppContext, setGlobalError} from "../../../actions/app";
import {getUTCDateFromMillis} from "../../../utils/dateFormatter";
import {Conditional} from "../../../components/core/Conditional";
import {getFundTrackerThresholds, saveFundTrackerThreshold} from "../services";
import {userGuidSelector} from "../../../selectors/user";
import CardComponent from "../components/CardComponent";
import CompareDataGrid from "../components/CompareDataGrid";
import ControlPanel from "../components/CompareDataGrid/ControlPanel";
import translator from "../../../services/translator";
import './index.scss';
import {shareClassFromFundFinderOrPreferenceSelector} from "../../../selectors/app";
import {pageIdSelector} from "../../../selectors/pages";

const {translate: t} = translator;

const FundTracker = (props) => {
  const {
    dispatch: chartDispatch,
    state: chartState,
  } = useChartContext();
  const {
    shareClassPerformanceData, primaryShareClassId, allShareClassData,
    shareclass, thresholdList = [], selection, fieldForQuery,
    metrics: selectedMetrics = [], benchmarks: selectedFundsOrBenchMarks = [],
    chartOptions, chartInstance, selectedZoomOption, selectedChartDateRange,
    loadDataforMyTopHoldings
  } = chartState;
  const [showThresholdModal, setShowThresholdModal] = useState(false);
  const [thresholdConfig, setThresholdConfig] = useState({});
  const [primaryMetricItem = {}, compareMetricItem] = selectedMetrics;
  const isMetricsCompareMode = (selection === FUND_TRACKER.METRICS);
  const isBenchMarksCompareMode = (selection === FUND_TRACKER.BENCHMARKS);
  const {run} = useAsync(chartDispatch);
  const {
    benchmarkMetricsOptions, setSnackBarMsgOnMissingData, allMetricOptions,
    userGuid, clearFundTrackerInitShareClassData, handleDeleteThreshold,
    thresholdsDeletedFlag, shareClassFromFundFinderOrPreference, topOpeningBalanceShareclassIds,
    lastShareclassIds
  } = props;


  // ************************************************************************************
  //         Below class determines which Fund/Benchmark has data from backend
  // ************************************************************************************
  // useEffect(() => {
  //   if (benchmarks && benchmarks.length > 0) {
  //     const updatedFundsAndBenchmarks = benchmarks.map(item => {
  //       const isDataFound = allShareClassData.some(({id}) => {
  //         return (id === item.value);
  //       });
  //       return {...item, isDataFound}
  //     });
  //     const updatedData = {
  //       benchmarks: updatedFundsAndBenchmarks
  //     };
  //     setMetricBenchmarksToState(chartDispatch, updatedData);
  //   }
  // }, [allShareClassData]);


  // **************************************************************************************
  //         Load the threshold list for the user
  // **************************************************************************************
  useEffect(() => {
    getFundTrackerThresholds(run, chartDispatch, userGuid);
  }, []);

  const hasOnlyPrimaryShareClassChartDataLoaded = () => {
    const hasChartLoaded = chartInstance && chartInstance.series
      && chartInstance.series.length > 0;
    if (hasChartLoaded) {
      const initialSeriesId = chartOptions && chartOptions.series
        && chartOptions.series.length === 1 && chartOptions.series[0].id || "";
      if (initialSeriesId) {
        const [chartId] = initialSeriesId.split("-");
        return (chartId === primaryShareClassId);
      }
    }
    return false;
  };

  useEffect(() => {
    if (!isEmpty(allShareClassData)) {
      props.loadFundTrackerCompareData(getNormalizedGridData(shareClassPerformanceData));
    }
  }, [allShareClassData, shareClassPerformanceData]);

  // ***************************************************************************************************************
  //       This is to add the "Compare Metric Item" or "Compare Benchmark" series to the chart
  //       when we switch between Metric Groups (TAB switch, like NAV/PERFORMANCE/LIQUIDITY/DURATION/ASSETS )
  // ***************************************************************************************************************
  useEffect(() => {
    if (hasOnlyPrimaryShareClassChartDataLoaded()) {
      if (isMetricsCompareMode) {
        addMetricsSeriesToChart(
          compareMetricItem,
          run,
          chartDispatch,
          chartOptions,
          chartState,
          selectedMetrics,
          false
        );
      } else if (isBenchMarksCompareMode) {
        addFundsOrBenchmarkSeriesToChart(
          run,
          fieldForQuery,
          benchmarkMetricsOptions,
          selectedFundsOrBenchMarks,
          chartDispatch,
          chartOptions,
          chartState,
          true
        );
      }
    }
  }, [chartOptions]);

  useEffect(() => {
    if (loadDataforMyTopHoldings) {
      addFundsOrBenchmarkSeriesToChart(
        run,
        fieldForQuery,
        benchmarkMetricsOptions,
        selectedFundsOrBenchMarks,
        chartDispatch,
        chartOptions,
        chartState,
        true,
        [{value: primaryShareClassId, type: "fund"}, ...selectedFundsOrBenchMarks]
      );
    }
  }, [loadDataforMyTopHoldings]);

  const setChartRangeSelections = (selectedZoomOption, selectedChartDateRange) => {
    chartDispatch({
      type: ChartActionTypes.setChartRangeOptions,
      data: {
        selectedZoomOption,
        selectedChartDateRange
      }
    });
  };

  useEffect(() => {
    return () => {
      // Clear the shareclass data which was set when user navigated from Preference / Fund Finder screen
      clearFundTrackerInitShareClassData({
        fundTrackerInitialShareClassData: {
          primaryShareClassDetails: null,
          additionalShareClassIds: []
        }
      });

      // Clear the Fund/Benchmark data missing message
      clearDataMissingMessages(chartDispatch);

      // Reset the chart options
      chartDispatch({
        type: ChartActionTypes.updateChartOptions,
        data: {
          chartOptions: {...DefaultChartConfig}
        }
      });

      setChartRangeSelections(1, null);

      ChartColorsInstance.removedAllColors();
    }
  }, []);

  const toggleShowThresholdsModal = useCallback(() => {
    const {
      isAllThresholdHasSameLimits,
      isExistingThresholdItem,
      baseLowerThreshold,
      baseUpperThreshold,
      emailNotificationEnabled
    } = verifyThresholds(
      primaryShareClassId,
      selectedFundsOrBenchMarks,
      selectedMetrics,
      thresholdList,
      isBenchMarksCompareMode
    );
    const allThresholdsEmpty = isAllThresholdsEmpty(
      primaryShareClassId, isBenchMarksCompareMode,
      selectedFundsOrBenchMarks, selectedMetrics, thresholdList
    );
    // Set the values to the state
    setThresholdConfig({
      isAllThresholdHasSameLimits: (isAllThresholdHasSameLimits || allThresholdsEmpty),
      isExistingThresholdItem,
      baseLowerThreshold,
      baseUpperThreshold,
      emailNotificationEnabled
    });
    setShowThresholdModal(!showThresholdModal);
  });

  const handleSetThreshold = (thresholdList) => {
    const [
      metric, lowerThreshold,
      upperThreshold, emailNotificationEnabled
    ] = thresholdList;
    const thresholds = getSelectedShareClassIds(
      primaryShareClassId, isBenchMarksCompareMode, selectedFundsOrBenchMarks
    ).map(shareClassId => ({
      metric,
      shareClassId,
      lowerThreshold,
      upperThreshold,
      emailNotificationEnabled
    }));
    const thresholdPayload = {
      userGuid,
      thresholds
    };
    saveFundTrackerThreshold(
      run,
      chartDispatch,
      thresholdPayload
    );
    // Close the modal
    setShowThresholdModal(!showThresholdModal);
  };

  const onChartZoomRangeChange = (e) => {
    if (!selectedChartDateRange) {
      const {rangeSelectorButton: {text: zoomPreset}} = e;
      const currentZoomOptionSelected = getChartZoomLevel(zoomPreset);
      if (selectedZoomOption !== currentZoomOptionSelected) {
        setChartRangeSelections(currentZoomOptionSelected, null);
      }
    }
  };

  const onChartDateRangeChange = (e) => {
    if (chartOptions.series && chartOptions.series.length >= 1) {
      const {min: fromDate, max: toDate} = e;
      const fromDateStr = getUTCDateFromMillis(fromDate);

      const isDataAvailable = chartOptions.series.every(({data}) => {
        return data.some(item => {
          const [seriesDate, dataPoint] = item;
          const seriesDateStr = getUTCDateFromMillis(seriesDate);
          const hasDateMatchFound = (fromDateStr === seriesDateStr);
          return hasDateMatchFound && dataPoint;
        });
      });

      setChartRangeSelections(-1, {fromDate, toDate});

      if (!isDataAvailable) {
        setSnackBarMsgOnMissingData({
          error: {code: "BUSINESS_FAILURE"},
          isPageLoaded: true,
          showErrorOn: SHOW_ERROR_ON.SNACKBAR,
          errorMessage: 'tkNoDataForSelectedDate'
        })
      }
    }
  };

  const thresholdModalProps = useMemo(() => ({
    title: t('tkSetThresholds'),
    animation: false,
    suppressScrollOnActive: true,
    customModalStyle: {overflow: 'hidden'},
    isModalOpen: showThresholdModal,
    additionalProps: {
      selectedMetric: primaryMetricItem.enum,
      metricOptions: allMetricOptions,
      isAllThresholdLimitsEqual: thresholdConfig.isAllThresholdHasSameLimits,
      isExistingThresholdItem: thresholdConfig.isExistingThresholdItem,
      lowerThreshold: thresholdConfig.baseLowerThreshold,
      upperThreshold: thresholdConfig.baseUpperThreshold,
      emailNotificationEnabled: thresholdConfig.emailNotificationEnabled,
      primaryShareClassId,
      isBenchMarksCompareMode,
      selectedFundsOrBenchMarks,
      userGuid,
      thresholdList: getAllThresholdsForSelectedFunds(
        primaryShareClassId, isBenchMarksCompareMode,
        selectedFundsOrBenchMarks, selectedMetrics,
        thresholdList
      ),
      thresholdsDeletedFlag
    },
    handleSetThreshold,
    handleDeleteThreshold,
    handleClose: toggleShowThresholdsModal,
  }));

  const showCardComponent = () => {
    const isMetricsCompareMode = (selection === FUND_TRACKER.METRICS);
    return !selection || isMetricsCompareMode || allShareClassData.length <= 1;
  };

  const disableSetThreshold = !primaryShareClassId || isMetricsCompareMode;

  const isPreferenceOnlyHasTopHoldingFunds = useMemo(() => {
    const {primaryShareClassDetails} = shareClassFromFundFinderOrPreference || {};
    const isShareClassFromFundFinderOrPreference = !!primaryShareClassDetails;
    return (!isShareClassFromFundFinderOrPreference &&
      !isEmpty(topOpeningBalanceShareclassIds) && isEmpty(lastShareclassIds));
  }, [shareClassFromFundFinderOrPreference, topOpeningBalanceShareclassIds, lastShareclassIds]);

  return (
    <div className="chartsection">
      <div className="chartsection__centerPane">
        <div className="chartsection__chart">
          <div className="chartsection__actionitems">
            <div className="left-section">
              <ChartDropdown
                {...props}
                isPreferenceOnlyHasTopHoldingFunds={isPreferenceOnlyHasTopHoldingFunds} />
            </div>
            <div className="right-section">
              <div className="compare">
                <CompareComponent {...props} />
              </div>
              <div className="notification">
                <Button
                  clickHandler={toggleShowThresholdsModal}
                  iconClass="button-icon"
                  extraClass='set-threshold-button'
                  isDisabled={disableSetThreshold}
                  label={t('tkSetThresholds')}
                />
              </div>
            </div>
          </div>
          <ChartComponent
            onChartDateRangeChange={onChartDateRangeChange}
            onChartZoomRangeChange={onChartZoomRangeChange}
            primaryShareClassId={primaryShareClassId}
          />
        </div>
      </div>
      {
        !!shareclass &&
          <div className="chartsection__data">
            <div className="controlPanel">
              <ControlPanel id={MODULES_KEYS.FUND_TRACKER_COMPARE_DATA_GRID} />
            </div>
            <Conditional condition={showCardComponent()}>
              <CardComponent shareclass={shareclass}/>
              <CompareDataGrid {...props} />
            </Conditional>
          </div>
        }
      <ThresholdModal {...thresholdModalProps} />
    </div>
  );
};

FundTracker.propTypes = {
  loadFundTrackerCompareData: PropTypes.func,
  setSnackBarMsgOnMissingData: PropTypes.func,
  selectedTabMetricsOptions: PropTypes.array,
  allMetricOptions: PropTypes.array,
  benchmarkMetricsOptions: PropTypes.array,
  userGuid: PropTypes.string,
  clearFundTrackerInitShareClassData: PropTypes.func,
  handleDeleteThreshold: PropTypes.func,
  thresholdsDeletedFlag: PropTypes.bool,
  saveAppPreferences: PropTypes.func,
  primaryMetricFromFundFinderOrPreference: PropTypes.string,
  shareClassFromFundFinderOrPreference: PropTypes.object,
  topOpeningBalanceShareclassIds: PropTypes.array,
  lastShareclassIds: PropTypes.array,
  pageId: PropTypes.string
};

const mapStateToProps = (state) => ({
  selectedTabMetricsOptions: selectedTabMetricsOptionSelector(state),
  allMetricOptions: metricsOptionsSelector(state),
  benchmarkMetricsOptions: benchmarkMetricsOptionsSelector(state),
  userGuid: userGuidSelector(state),
  thresholdsDeletedFlag: thresholdsDeletedFlagSelector(state),
  primaryMetricFromFundFinderOrPreference: primaryMetricFromFundFinderOrPreferenceSelector(state),
  shareClassFromFundFinderOrPreference: shareClassFromFundFinderOrPreferenceSelector(state),
  topOpeningBalanceShareclassIds: topOpeningBalanceShareclassIdsSelector(state),
  lastShareclassIds: lastShareclassIdsSelector(state),
  pageId: pageIdSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  loadFundTrackerCompareData: (data) => dispatch(loadFundTrackerCompareData(data)),
  setSnackBarMsgOnMissingData: error => dispatch(setGlobalError(error)),
  clearFundTrackerInitShareClassData: (data) => dispatch(addAppContext(data)),
  handleDeleteThreshold: (data) => dispatch(deleteFundTrackerThresholds(data)),
  saveAppPreferences: (data) => dispatch(saveAppPreferences(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FundTracker));
