import { FunctionComponent, PropsWithChildren, memo } from 'react';
import { CommonReactPopoverProps } from './popover';
import { ReactComponentProps } from '@gs-ux-uitoolkit-react/component';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { popoverStyleSheet, getPopoverBodyClasses } from '@gs-ux-uitoolkit-common/popover';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';

export interface PopoverBodyProps extends ReactComponentProps {
    classes?: CommonReactPopoverProps['classes'];
    size?: CommonReactPopoverProps['size'];
}

/**
 * Provides appropriate spacing and styling around the body section of the Popover's content.
 */
export const PopoverBody: FunctionComponent<PropsWithChildren<PopoverBodyProps>> = memo(props => {
    const { classes: overrideClasses, size, className, ...children } = props;
    const theme = useTheme();
    const cssClasses = useStyleSheet(popoverStyleSheet, { size, theme });
    return (
        <div
            data-gs-uitk-component="popover-body"
            data-cy="gs-uitk-popover__body"
            {...children}
            className={getPopoverBodyClasses({ cssClasses, overrideClasses, className })}
        />
    );
});
PopoverBody.displayName = 'PopoverBody';
