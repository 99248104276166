import { cx } from '@gs-ux-uitoolkit-common/style';
import { HeadingCssClasses } from './heading-style-sheet';
import { HeadingCommonProps } from './heading-props';

const globalClassName = 'gs-heading';

export const getHeadingRootClassNames = (props: {
    cssClasses: HeadingCssClasses;
    overrideClasses?: HeadingCommonProps['classes'];
    className?: HeadingCommonProps['className'];
}): string => {
    const { cssClasses, overrideClasses, className } = props;

    return cx(cssClasses.root, className, overrideClasses && overrideClasses.root, globalClassName);
};
