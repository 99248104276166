import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import CustomView from '../../../components/app/CustomView';
import {
  allViews, isOpenCustomViewModal, selectedModelView,
  groupedColumnsSelector, columnsMetadata, modelActiveMode, isModalLoading, duplicateViewName, getErrorObject,
  checkIsPageInErrorMode
} from '../../../selectors/pages/fundFinder';
import {closeCustomViewModal, deleteCustomView, editCustomView} from '../../../actions/page/fundFinder';
import { eventAnalytics } from '../../../actions/sagas';
import { addPageContext } from '../../../actions/page';
import { labelSelector, getLabel } from '../../../selectors/app/index';
import {getSaveViewAnalyticsData, getColumnSearchNoResultAnalyticsData} from '../analytics/index';
import Modal from '../../../components/core/Modal';
import translator from "../../../services/translator";
const {translate: t} = translator;
export const FundFinderCustomViewModel =(props) => {
  const handleClose =() => {
    props.closeModal({'modelActiveMode': '', 'isModalLoading': false, 'duplicateViewName': undefined, isPageInErrorMode: false});
  };

  const dispatchViewSaveAnalytics = (columns) => {
    props.dispatchViewSaveAnalytics(columns, props.currentView);
  };

  const modalProps = {
    animation: true,
    suppressScrollOnActive: true,
    customModalStyle: {overflow: 'hidden'},
    open: props.open,
    handleClose,
    dispatchViewSaveAnalytics
  };

  const newProps = {...props, headerName: t('tkModifyFundFinderView'), dispatchViewSaveAnalytics};

  return (
    <Modal {...modalProps}>
      <CustomView {...newProps} />
    </Modal>
  );
};


const mapStateToProps = state => (
  {
    columnsMetadata: columnsMetadata(state),
    hideEdit: true,
    hideDelete: true,
    groupedColumns: groupedColumnsSelector(state),
    allViews: allViews(state),
    open: isOpenCustomViewModal(state),
    currentView: selectedModelView(state),
    labels: labelSelector(state),
    label: key => getLabel(state, key),
    modelActiveMode: modelActiveMode(state),
    isLoading: isModalLoading(state),
    duplicateViewName: duplicateViewName(state),
    errorObject: getErrorObject(state),
    isPageInErrorMode: checkIsPageInErrorMode(state)
  }
);

export const mapDispatchToProps = dispatch => (
  {
    closeModal: data => dispatch(closeCustomViewModal(data)),
    doAnalytics: data => dispatch(eventAnalytics(data)),
    saveCustomView: () => {},
    editCustomView: data => {dispatch(editCustomView(data));},
    deleteCustomView: (data) => {dispatch(deleteCustomView(data));},
    addPageContext: (obj) => dispatch(addPageContext(obj)),
    dispatchViewSaveAnalytics: (viewColumns, view) => {
      const data = getSaveViewAnalyticsData(viewColumns, view);
      dispatch(eventAnalytics(data));
    },
    dispatchColumnSearchNoResultAnalytics: (searchTerm) => {
      const data = getColumnSearchNoResultAnalyticsData(searchTerm);
      dispatch(eventAnalytics(data));
    }
  }
);

FundFinderCustomViewModel.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  errorObject: PropTypes.object,
  dispatchViewSaveAnalytics: PropTypes.func,
  currentView: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(FundFinderCustomViewModel);
