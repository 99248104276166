import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { ColorPickerSelectorSize } from './color-picker-props';
import { colors, fontWeight, zIndex } from '@gs-ux-uitoolkit-common/design-system';
import { DeepReadonly } from 'ts-essentials';

export interface ColorPickerStyleSheetProps {
    theme: Theme;
    size?: ColorPickerSelectorSize;
    scrollScreenActive?: boolean;
}

export interface ColorPickerCssClasses {
    root: string;
    selector: string;
    selectorInner: string;
    selectorForm: string;
    selectorFormInner: string;
    selectorChip: string;
    selectorChipInner: string;
    selectorIcon: string;
    popupContainer: string;
    popup: string;
    popupSubheader: string;
    popupContents: string;
    rampsContainer: string;
    footer: string;
    footerItem: string;
    footerLabel: string;
    rampTile: string;
    activeMarker: string;
    closeButton: string;
    scrollScreen: string;
}

export type ColorPickerStyledClasses = CssClassDefinitionsObject<keyof ColorPickerCssClasses>;

export interface ColorPickerStyleOverridesParams {
    props: DeepReadonly<ColorPickerStyleSheetProps>;
    createDefaultStyledClasses: () => ColorPickerStyledClasses;
}

export const colorPickerStyleSheet = new StyleSheet(
    'color-picker',
    (props: ColorPickerStyleSheetProps) => {
        return createComponentClassDefinitions<
            ColorPickerStyleSheetProps,
            ColorPickerStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.colorPicker);
    }
);

function createDefaultStyledClasses({
    theme,
    size,
    scrollScreenActive,
}: ColorPickerStyleSheetProps): ColorPickerStyledClasses {
    let selectorDimension = '32px';
    let selectorInnerDimension = '16px';
    let selectorInnerMargin = '8px';
    if (size === ColorPickerSelectorSize.Sm) {
        selectorDimension = '24px';
        selectorInnerDimension = '12px';
        selectorInnerMargin = '6px';
    } else if (size === ColorPickerSelectorSize.Lg) {
        selectorDimension = '40px';
        selectorInnerDimension = '20px';
        selectorInnerMargin = '10px';
    }
    return {
        root: {},
        selector: {
            cursor: 'pointer',
            border: `1px solid ${colors.gray050}`,
            borderRadius: '2px',
            height: selectorDimension,
        },
        selectorInner: {
            borderRadius: '2px',
            height: selectorInnerDimension,
        },
        selectorForm: {},
        selectorFormInner: {
            margin: `${selectorInnerMargin} 8px`,
        },
        selectorChip: {
            width: selectorDimension,
        },
        selectorChipInner: {
            width: selectorInnerDimension,
            margin: `${selectorInnerMargin} auto`,
        },
        selectorIcon: {
            cursor: 'pointer',
            margin: '3.2px',
            width: '19.2px',
            height: '19.2px',
        },
        popupContainer: {
            '.popover': {
                border: 'none',
                background: 'none',
                width: 0,
                height: 0,
                zIndex: zIndex.popover,
            },
        },
        popup: {
            border: `1px solid ${theme.border.minimal}`,
            boxShadow: theme.elevation['03'].shadow,
            textAlign: 'left',
            width: 'fit-content',
            background: theme.elevation['03'].background,
            color: theme.text.primary,
            borderRadius: '3px',
            overflow: 'hidden',
        },
        popupSubheader: {
            margin: '5px 0',
        },
        popupContents: {
            height: '176px',
            overflow: 'auto',
            padding: '10px 16px',
            fontSize: '12.8px',
        },
        rampsContainer: {
            display: 'flex',
        },
        footer: {
            background: theme.surface.primary,
            color: theme.text.primary,
            padding: '7px 16px',
            fontSize: '12.8px',
            display: 'flex',
        },
        footerItem: {
            '&:first-child': {
                width: '144px',
            },
        },
        footerLabel: {
            fontWeight: fontWeight.medium,
            paddingRight: '3.2px',
        },
        rampTile: {
            cursor: 'pointer',
            height: '16px',
            width: '16px',
            margin: '5px 5px 5px 0px',
            position: 'relative',
            borderRadius: '2px',
        },
        activeMarker: {
            background: colors.white,
            border: `1px solid ${colors.black}`,
            width: '8px',
            height: '8px',
            borderRadius: '4px',
            position: 'absolute',
            left: '3px',
            top: '3px',
        },
        closeButton: {
            position: 'absolute',
            top: '5px',
            right: '18px',
            fontSize: '20px',
            padding: '5px',
            cursor: 'pointer',
            color: theme.text.primary,
        },
        scrollScreen: {
            width: '274px',
            height: scrollScreenActive ? '40px' : 0,
            position: 'absolute',
            left: 0,
            top: 0,
            transition: 'all 0.3s ease-out',
        },
    };
}
