import { ReactNode, Component, KeyboardEvent } from 'react';
import PropTypes from 'prop-types';
import {
    UncontrolledButtonDropdown,
    UncontrolledButtonDropdownProps,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { cx } from '@gs-ux-uitoolkit-react/style';
import { OmitIndexSignature } from '@gs-ux-uitoolkit-react/shared';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';

export interface DropdownTabTitleProps extends OmitIndexSignature<UncontrolledButtonDropdownProps> {
    active?: boolean;
    caret?: boolean;
    disabled?: boolean;
    onSelect: (...args: any[]) => any;
    onClick?: (...args: any[]) => any;
    onKeydown?: (props: { event: KeyboardEvent; tabKey: string | number }) => void;
    tabKey: string | number;
    title: string;
    inOverflow: boolean;
    children: ReactNode;
    className?: string;
}

export interface DropdownTabTitleState {
    selectedTabKey: any | number;
    dropdownOpen: boolean;
}

// this is the overflow menu
export class DropdownTabTitle extends Component<DropdownTabTitleProps, DropdownTabTitleState> {
    public static propTypes: { [key in keyof DropdownTabTitleProps]: any } = {
        active: PropTypes.bool,
        caret: PropTypes.bool,
        children: PropTypes.node.isRequired,
        disabled: PropTypes.bool,
        onSelect: PropTypes.func.isRequired,
        onKeydown: PropTypes.func,
        tabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string.isRequired,
        inOverflow: PropTypes.bool,
    };

    constructor(props: DropdownTabTitleProps) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
        this.state = {
            dropdownOpen: false,
            selectedTabKey: 0,
        };
    }

    _handleClick(tabKey: any | number, onClick: (...args: any[]) => any) {
        const { onSelect, inOverflow } = this.props;
        if (typeof onClick === 'function') {
            onClick();
        }
        if (tabKey) {
            this.setState({
                selectedTabKey: tabKey,
            });
            onSelect(tabKey, inOverflow);
        }
    }

    toggle() {
        if (!this.props.disabled) {
            this.setState({
                dropdownOpen: !this.state.dropdownOpen,
            });
        }
    }

    _renderDropdownItem(props: any) {
        return (
            <DropdownItem
                className={cx({
                    active: this.props.active && props.tabKey === this.state.selectedTabKey,
                })}
                key={props.tabKey}
                onClick={this._handleClick.bind(
                    // eslint-disable-line react/jsx-no-bind
                    this,
                    props.tabKey,
                    props.onClick
                )}
                disabled={props.disabled}
            >
                {props.title}
            </DropdownItem>
        );
    }

    _getOverflowItems(children: any) {
        return children.map((child: any) => {
            return this._renderDropdownItem(child.props);
        });
    }

    handleOnKeyDown(e: React.KeyboardEvent) {
        if (this.props.onKeydown) this.props.onKeydown({ event: e, tabKey: this.props.tabKey });
    }

    render() {
        const {
            active,
            caret = true,
            children,
            disabled,
            onClick,
            onKeydown,
            title,
            inOverflow,
            className,
            tabKey,
            ...other
        } = this.props;
        // If the supplied text was '...' use the FA ellipsis for better
        // visual treatment
        let titleElement: string | ReactNode = title;
        if (title === '...') {
            titleElement = <Icon name="more-horiz" type="filled" />;
        }
        return (
            <UncontrolledButtonDropdown
                {...other}
                className={cx(className, 'nav-item', {
                    active: active,
                })}
                disabled={!!disabled}
                isOpen={this.state.dropdownOpen}
                onClick={onClick}
                tag="li"
                toggle={this.toggle}
            >
                <DropdownToggle
                    nav
                    caret={caret}
                    className={cx({ active: active })}
                    onKeyDown={this.handleOnKeyDown}
                    data-tab-key={tabKey}
                >
                    {titleElement}
                </DropdownToggle>
                <DropdownMenu>{this._getOverflowItems(children)}</DropdownMenu>
            </UncontrolledButtonDropdown>
        );
    }
}
