import { CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { fontWeight } from '@gs-ux-uitoolkit-common/design-system';
import { StyleSheet } from '@gs-ux-uitoolkit-common/style';
import { MenuSize } from './menu-props';
import {
    Theme,
    createComponentClassDefinitions,
    ThemeTypographyVariant,
} from '@gs-ux-uitoolkit-common/theme';
import { rgba } from 'polished';
import { DeepReadonly } from 'ts-essentials';
import './menu-option-theme-overrides';

export interface MenuOptionStyleSheetProps {
    theme: Theme;
    size: MenuSize;
    selected: boolean;
    disabled: boolean;
    indented: boolean;
    submenuVisible: boolean;
}

export interface MenuOptionCssClasses {
    root: string;
    icon: string;
    label: string;
    secondaryLabel: string;
    submenuIcon: string;
}

export type MenuOptionStyledClasses = CssClassDefinitionsObject<keyof MenuOptionCssClasses>;

export interface MenuOptionStyleOverridesParams {
    props: DeepReadonly<MenuOptionStyleSheetProps>;
    createDefaultStyledClasses: () => MenuOptionStyledClasses;
}

export const menuOptionStyleSheet = new StyleSheet(
    'menuOption',
    (props: MenuOptionStyleSheetProps) => {
        return createComponentClassDefinitions<MenuOptionStyleSheetProps, MenuOptionStyledClasses>(
            props,
            createDefaultStyledClasses,
            props.theme.styleOverrides?.menuOption
        );
    }
);

function createDefaultStyledClasses({
    theme,
    size,
    selected,
    disabled,
    indented,
    submenuVisible,
}: MenuOptionStyleSheetProps): MenuOptionStyledClasses {
    const color = theme.text.secondary;
    let optionHeight = '32px';
    let optionPadding = `0 16px 0 ${indented ? '16px' : '8px'}`;
    let optionIconDimension = '20px';
    let optionLabelVariant: ThemeTypographyVariant = 'body02';
    let optionIconMenuMarginRight = '-8px';
    if (size === 'sm') {
        optionHeight = '28px';
        optionIconDimension = '16px';
        optionLabelVariant = 'body03';
        optionIconMenuMarginRight = '-12px';
    } else if (size === 'lg') {
        optionHeight = '44px';
        optionPadding = `0 24px 0 ${indented ? '20px' : '12px'}`;
        optionIconDimension = '24px';
        optionLabelVariant = 'body01';
        optionIconMenuMarginRight = '-4px';
    }

    const interactionColor: string = theme.increaseContrast(
        theme.colorScheme.secondary,
        1
    ) as string;

    const backgroundColor = submenuVisible
        ? theme.surface.secondary
        : selected
          ? rgba(theme.colorScheme.primary as string, 0.2)
          : 'transparent';
    const outlineStyle = submenuVisible ? { outlineWidth: 0 } : {};

    return {
        root: {
            width: '100%',
            maxWidth: '100%',
            height: optionHeight,
            border: 'none',
            borderRadius: '0px',
            backgroundColor,
            color: theme.text.primary,
            textAlign: 'left',
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: optionPadding,
            opacity: disabled ? theme.state.disabledOpacity : 1.0,
            ...outlineStyle,
            '&:hover:not(:disabled)': {
                backgroundColor: rgba(interactionColor, 0.2),
                cursor: 'pointer',
            },
            '&:active:not(:disabled)': {
                background: rgba(interactionColor, 0.4),
            },
            '&:focus-visible:not(:disabled)': {
                outline: `1px solid ${theme.text.link}`,
                outlineOffset: '-1px',
            },
        },
        icon: {
            color,
            fontSize: optionIconDimension,
            width: optionIconDimension,
            maxWidth: optionIconDimension,
        },
        submenuIcon: {
            color,
            fontSize: '20px',
            marginRight: optionIconMenuMarginRight,
        },
        label: {
            ...theme.typography[optionLabelVariant],
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            flexGrow: 1,
            margin: '0 0 0 8px',
            fontWeight: selected && !disabled ? fontWeight.medium : fontWeight.normal,
        },
        secondaryLabel: {
            ...theme.typography.body03,
            color,
            margin: '0 0 0 8px',
            padding: 0,
            backgroundColor: 'transparent',
            borderRadius: '0px',
        },
    };
}
