import Big from 'big.js';
import isUndefined from 'lodash/isUndefined';
import {regex as regexRules} from '../helpers/regex';
import translator from '../services/translator';

export const DECIMAL_FORMAT = {
  TWO : 2,
  THREE : 3,
  FOUR : 4
};

export const amountWithSymbol = (value = '', currencyCode, prepend = true) => {
  if(value){
    return (prepend ? `${translator.getSymbol(currencyCode)}${value}` : `${value}${translator.getSymbol(currencyCode)}`);
  }
  return '--';
};

export const currencyFormatter = (value = '', scale) => {
  const r = /\B(?=(\d{3})+(?!\d))/g;
  const formattedValue = scale && value ?  Big(value).toFixed(scale).toString() : value && value.toString();
  const splitValue = formattedValue.split('.');
  const whole = splitValue[0];
  const decimal = splitValue[1];
  const formattedWhole = whole && whole.replace(r, ',');
  return !isUndefined(decimal) ? `${formattedWhole}.${decimal}` : `${formattedWhole}`;
};

export const fixDigit = (value = '', count = 2) => {
  const roundedValue = Number(value) ? Number(value).toFixed(count) : value;
  return roundedValue.toString();
};

export const getHighestDp = (values) => {
  const dpLengths = values.map(item => {
    const valueStr = item ? `${item}` : '';
    const decimalVal = valueStr && valueStr.split('.')[1] || '';
    return decimalVal.length;
  });
  return Math.max(...dpLengths);
};

export const amountFormatter = (value = '', precision = DECIMAL_FORMAT.TWO, skipPrecision = false) => {
  if ((typeof value === 'string' && value.trim() === '') || value === undefined || value === null || value === 'null') return value;
  const data = typeof value !== 'string' ? value.toString() : value;
  const removedCommas = data.replace(/,/g, '');
  const r = /\B(?=(\d{3})+(?!\d))/g;
  let roundedValue = '';
  try {
    if (!skipPrecision) {
      roundedValue = precision && removedCommas ? Big(removedCommas).toFixed(precision).toString() : removedCommas && removedCommas.toString();
    } else {
      roundedValue = removedCommas;
    }
  } catch (err) {
    console.log(err);
  }
  const numArr = roundedValue.split('.');
  const whole = numArr[0];
  const decimal = numArr[1];
  const formattedWhole = whole && whole.replace(r, ',');
  return decimal ? `${formattedWhole}.${decimal}` : `${formattedWhole}`;
};

export const reportingAmountFormatter = (value = '') => {
  if (value === '' || isUndefined(value) || value === null) return value;
  const data = typeof value !== 'string' ? value.toString() : value;
  const removedCommas = data.replace(/,/g, '');
  const r = /\B(?=(\d{3})+(?!\d))/g;
  const numArr = removedCommas.split('.');
  const whole = numArr[0];
  const decimal = numArr[1];
  const formattedWhole = whole && whole.replace(r, ',');
  return decimal ? `${formattedWhole}.${decimal}` : `${formattedWhole}`;
};

export default currencyFormatter;

export const parseWhole = (val = '') => {
  const whole = val.replace(regexRules.numberModel, '$1');
  return whole || '';
};

export const parseDecimal = (val = '') => {
  const decimal = val.replace(regexRules.numberModel, '$2');
  return decimal || '';
};


export const parseShortcut = (val = '') => {
  const shortcutMultiplier = val.replace(regexRules.numberModel, '$3');
  return shortcutMultiplier || '';
};

export const isValidInput = (val = '') => {
  const isValidNumber = (new RegExp(regexRules.numberModel)).test(val);
  const isZero = (new RegExp(regexRules.isZero)).test(val);

  return (isValidNumber && !isZero && val.length);
};

const getShortcutMultiplier = (val = '') => {
  const convertToNumber = {
    b: '000000000',
    m: '000000',
    k: '000'
  };

  const shortcut = parseShortcut(val);
  const zeroes = shortcut ? convertToNumber[shortcut.toLowerCase()] : '';
  return zeroes;
};

export const padZeroes = (whole = '', decimal = '', zeroes = '') => {
  // only a shortcut was passed in
  if (!whole && !decimal && zeroes) {
    whole = '1';
  }

  // remove leading zeroes
  if (whole === '0') {
    whole = '';
  }

  // remove decimal
  if (decimal) {
    decimal = decimal.substring(1);
  }

  const decimalPlaceFromRight = zeroes.length - decimal.length;
  const numberWithoutDecimal = whole + decimal;
  let finalValue = '';

  // more zeroes to pad than decimal places
  if (decimalPlaceFromRight > 0) {
    finalValue = numberWithoutDecimal + zeroes.substring(0, decimalPlaceFromRight);
  } else if (decimalPlaceFromRight < 0) {
    // less zeroes to pad than decimal places
    const newDecimalLocation = whole.length + zeroes.length;
    const newWhole = numberWithoutDecimal.substring(0, newDecimalLocation);
    const newDecimal = numberWithoutDecimal.substring(newDecimalLocation);
    finalValue = `${newWhole}.${newDecimal}`;
  } else {
    finalValue = numberWithoutDecimal;
  }

  // remove leading zeroes
  finalValue = finalValue.replace(regexRules.noLeadingZeros, '');

  return finalValue;
};


export const shortcutAmountFormatter = (val = '', limit) => {
  let cleansed = val.toString().replace(/[,\s]/g, '');
  const whole = parseWhole(cleansed);
  const decimal = parseDecimal(cleansed);
  const shortcut = parseShortcut(cleansed);

  cleansed = limit && (+cleansed > +limit) ? cleansed.slice(0, -1) : cleansed;
  if (!isValidInput(cleansed)) {
    return cleansed;
  }
  let numWithoutShortcut;
  if (shortcut) {
    const zeroes = getShortcutMultiplier(cleansed);
    cleansed = padZeroes(whole, decimal, zeroes);
    numWithoutShortcut = padZeroes(whole, decimal, '');
  }
  cleansed = limit && (+cleansed > +limit) ? numWithoutShortcut : cleansed;

  return cleansed;
};

export const  moveDecimalLeft =  (number,  dpNum) =>  {

  if(!number) {
    return {
      data: number
    };
  }

  // remove existing number formatting if any
  number =  number.toString().replace(/,/g, '');


  const amountArr = number.split('.');

  // initialBeforeDp: string before decimal place
  const initialBeforeDp = amountArr[0];

  // initialAfterDpString: string after decimal place
  const initialAfterDpString = amountArr[1];

  if (!Number.isNaN(number)) {
    if(initialBeforeDp.length <= dpNum) {
       const movedNumber =  Number(number) / 10**dpNum;
      return {data: movedNumber.toString()};
    }
    const moveIndex = initialBeforeDp.length - dpNum;
    const beforeDpString = initialBeforeDp.substr(0, moveIndex); // Gets the first part
    const afterDpString = initialBeforeDp.substr(moveIndex);

    /* convert decimal part to number to removed unnecessary zero(s) after the decimal value. Example .012000 should be
     * converted to 0.012 and convert again the new number (0.012) to string inorder to perform string operations. */
    const formattedDPNumber = Number(`.${afterDpString}${initialAfterDpString && initialAfterDpString || ''}`).toString();
    const parts = formattedDPNumber.split('.');
    const decimalPart = parts.length > 0 && parts[1] || '';
    let formattedString = decimalPart && `${beforeDpString}.${decimalPart}` || beforeDpString;
        formattedString = (initialAfterDpString || dpNum > 0) ? formattedString : number;
    return {data: formattedString};
  }

  return {
    data: number,
    error: {
      message: 'Invalid Amount Input',
      reason: 'Number of decimal places to move is greater than the length of the before decimal string/ or could not parse input as number'
    }};
};
