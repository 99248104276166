import {
    CellClassParams,
    CellEditingStartedEvent,
    CellEditingStoppedEvent,
    ColDef,
    Column,
    ColumnApi,
    ColumnEvent,
    ColumnGroup,
    CsvExportParams,
    Events,
    ExcelExportParams,
    ExpressionService,
    GridApi,
    GridOptions,
    ICellRendererComp,
    ICellRendererFunc,
    ModelUpdatedEvent,
    NewValueParams,
    RowGroupOpenedEvent,
    RowNode,
    RowRenderer,
    ShouldRowBeSkippedParams,
    ValueFormatterParams,
    ValueFormatterService,
    ValueGetterParams,
    ColGroupDef,
    RowClassParams,
    ICellEditorComp,
    CellStyleFunc,
    ModuleRegistry,
    IRowNode,
} from '@ag-grid-community/core';
import { LicenseManager } from '@ag-grid-enterprise/core';
import {
    arrayHelper,
    DataType,
    DraDatasource,
    FILTERABLE_SPECIAL_VALUES_MAP,
    globalHelper,
    logger,
    PluginAction,
    PluginBase,
    PluginScreen,
    PluginWidget,
    RecordInsertedEventArgs,
    Registry,
    SimpleEventDispatcher,
    ThemeTypeClassName,
    UIToolkitDeepPartial,
    QuickFilter,
} from '@gs-ux-uitoolkit-common/datacore';
import { Size, Density } from '@gs-ux-uitoolkit-common/design-system';
import { isEqual, debounce } from 'gs-uitk-lodash';
import * as Redux from 'redux';
import { rowGroupColumnColId } from '../../datasources/dra-viewport-datasource';
import { processCellForExport } from '../../datasources/export-functions';
import { ExcelStyleService, PIVOT_COLUMN_CLASS_PREFIX } from '../../libraries/excelStyleService';
import { pluginRegistry } from '../../libraries/plugin-registry';
import { StyleService } from '../../libraries/styleService';
import { ABSOLUTE_CLASSNAME, CustomSort } from '../../plugins/custom-sort/custom-sort-plugin';
import { CustomDRAOptions, DraPlugin } from '../../plugins/dra/dra-plugin';
import {
    ExportCallbacks,
    ExportColumnOptionType,
    ExportOptions,
    ExportRowOptionType,
    ExportType,
} from '../../plugins/exports/export';
import { SavedView, SavedViewOptions } from '../../plugins/saved-view/saved-view';
import { Plugins } from '../../plugins/plugin-enum';
import '../../plugins/plugin-registration';
import { NotificationType } from '../../plugins/notification/notification-plugin';
import { SetAbsoluteSortStatus } from '../../redux/actions/custom-sort-action';
import {
    GridSetColumnDefinitions,
    GridSetPrimaryColumnDefinitions,
    gridSetColumnFilters,
    GridSetFloatingFilter,
} from '../../redux/actions/grid-action';
import { SetDensity, SetSize } from '../../redux/actions/grid-configuration-action';
import { SystemConfigurationSetGridWrapper } from '../../redux/actions/system-configuration-action';
import { AddNotification, RemoveNotification } from '../../redux/actions/notification-action';
import {
    DataGridState,
    PluginStateChangedPayload,
    PluginStateType,
} from '../../redux/datagrid-state';
import { DataGridStore } from '../../redux/datagrid-store';
import {
    AG_BODY_VIEWPORT_CLASS,
    ColumnPinnedEventArgs,
    DatasourceConfiguration,
    DatasourceType,
    DataUpdatedEventArgs,
    DisplayValueTuple,
    EditorKeyDownEventArgs,
    GridColumn,
    GridWrapper,
    GridWrapperColumnResizedEventArgs,
    GridWrapperConfiguration,
    agGridAutoColumn,
} from '../grid-wrapper';
import { GridWrapperConfigurationValidator } from '../grid-wrapper-configuration-validator';

import { groupIdSuffix } from '../../datasources/dash-grid-to-ag-grid-column-converter';
import { lightTheme, Theme } from '@gs-ux-uitoolkit-react/theme';
import { ReactReduxContextValue } from 'react-redux';
import { Context } from 'react';
import { getGridOptionsService } from '../../util/getGridOptionsService';
LicenseManager.setLicenseKey(
    'Using_this_AG_Grid_Enterprise_key_( AG-043245 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( The Goldman Sachs Group, Inc. )_is_granted_a_( Single Application )_Developer_License_for_the_application_( GS Site )_only_for_( Unlimited )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( GS Site )_need_to_be_licensed___( GS Site )_has_been_granted_a_Deployment_License_Add-on_for_( Unlimited )_Production_Environments___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 18 August 2024 )____[v2]_MTcyMzkzNTYwMDAwMA==c26308c6d5d219f83e18f6277025742d'
);

export interface ActiveAbsoluteSort {
    customSort: CustomSort;
    active: boolean;
}

/**
 * ag-Grid specific version of a GridWrapper.
 * Used to make the agnostic system to be able to interface itself with the grid
 */
export class AgGridWrapper implements GridWrapper {
    /**
     * Event section
     */
    public columnResized: SimpleEventDispatcher<GridWrapperColumnResizedEventArgs> =
        new SimpleEventDispatcher<GridWrapperColumnResizedEventArgs>();
    // we don't need any args for now
    public gridScrolled: SimpleEventDispatcher<object> = new SimpleEventDispatcher<object>();
    public dataUpdated: SimpleEventDispatcher<DataUpdatedEventArgs> =
        new SimpleEventDispatcher<DataUpdatedEventArgs>();
    public columnPinned: SimpleEventDispatcher<ColumnPinnedEventArgs> =
        new SimpleEventDispatcher<ColumnPinnedEventArgs>();
    public rowGroupCollapseChanged: SimpleEventDispatcher<object> =
        new SimpleEventDispatcher<object>();
    public rowGroupExpanded: SimpleEventDispatcher<object> = new SimpleEventDispatcher<object>();
    public rowGroupChanged: SimpleEventDispatcher<object> = new SimpleEventDispatcher<object>();
    public editorKeyDown: SimpleEventDispatcher<EditorKeyDownEventArgs> =
        new SimpleEventDispatcher<EditorKeyDownEventArgs>();
    public pluginStateChanged: SimpleEventDispatcher<PluginStateChangedPayload> =
        new SimpleEventDispatcher();
    public rowSelectionChanged: SimpleEventDispatcher<object> = new SimpleEventDispatcher<object>();
    public firstDataRendered: SimpleEventDispatcher<object> = new SimpleEventDispatcher<object>();

    public savedViewSaved: SimpleEventDispatcher<SavedView> =
        new SimpleEventDispatcher<SavedView>();
    public savedViewCreated: SimpleEventDispatcher<SavedView> =
        new SimpleEventDispatcher<SavedView>();
    public savedViewDeleted: SimpleEventDispatcher<string> = new SimpleEventDispatcher<string>();
    public quickFilterSaved: SimpleEventDispatcher<QuickFilter> =
        new SimpleEventDispatcher<QuickFilter>();
    public quickFilterCreated: SimpleEventDispatcher<QuickFilter> =
        new SimpleEventDispatcher<QuickFilter>();
    public quickFilterDeleted: SimpleEventDispatcher<QuickFilter> =
        new SimpleEventDispatcher<QuickFilter>();

    public themeChanged: SimpleEventDispatcher<Theme> = new SimpleEventDispatcher<Theme>();

    private theme: Theme = lightTheme;
    public openModalScreen: (screenId: string) => void = () => {};

    private dataGridStore: DataGridStore;
    private plugins: PluginBase<GridWrapper, DataGridState>[];
    private cachedMaskedColumn: Map<string, HTMLDivElement> = new Map();
    private screensRegistry: Registry<PluginScreen>;
    private widgetsRegistry: Registry<PluginWidget>;
    private actionsRegistry: Registry<PluginAction>;
    private registeredModulesNames: string[];
    private valueFormatterService: ValueFormatterService;
    private expressionService: ExpressionService;
    private isFilterPresentListCallbacks: (() => boolean)[] = [];
    private filterPassListCallbacks: ((rowNode: IRowNode) => boolean)[] = [];
    private editorListeners: Map<
        string,
        { editor: HTMLElement; listener: (event: KeyboardEvent) => void }
    > = new Map();
    private debouncedRefreshHeader: (() => void) | null = null;
    private debouncedRefreshFiltering: (() => void) | null = null;
    private styleService: StyleService;
    private excelStyleService: ExcelStyleService;
    private storedViewportRowModelBufferSize: number | undefined;
    private storedCacheBlockSize: number | undefined;
    private apiEventListenerSubscription: { event: string; func: (args: any) => void }[] = [];
    // a list of initial column value getters for columns that can be restored later
    private initialColumnValueGetterList: {
        [columnId: string]: string | ((params: ValueGetterParams) => any) | undefined;
    } = {};
    private onPluginAddedCallback:
        | ((plugin: PluginBase<GridWrapper, DataGridState>) => void)
        | undefined;

    private previousSortModel: {
        colId: string;
        sort: string;
    }[] = [];

    /**
     * Constructor of the AgGridWrapper
     */
    constructor(
        private gridWrapperConfiguration: GridWrapperConfiguration,
        private gridOptions: GridOptions,
        private gridApi: GridApi,
        private columnApi: ColumnApi,
        private columnDataTypeDefinition: { [index: string]: DataType } | undefined,
        private draDatasource: DraDatasource | null,
        defaultGridState: UIToolkitDeepPartial<DataGridState>,
        private customOptions: CustomDRAOptions,
        private exportCallbacks: ExportCallbacks = {},
        public wrapperRef: HTMLElement | null = null,
        private overlayContainerRef: HTMLElement | null = null
    ) {
        if (!this.gridApi) {
            const errorMsg = 'Cannot find the reference to the grid api';
            logger.error(errorMsg, this);
            throw Error(errorMsg);
        }
        if (!this.columnApi) {
            const errorMsg = 'Cannot find the reference to the grid column api';
            logger.error(errorMsg, this);
            throw Error(errorMsg);
        }

        // here we are accessing private members of ag-Grid. Not ideal!
        this.valueFormatterService = (this.gridApi as any).context?.beanWrappers
            ?.valueFormatterService?.beanInstance;
        if (!this.valueFormatterService) {
            const errorMsg = 'Cannot get the valueFormatterService';
            logger.error(errorMsg, this.gridApi);
            throw Error(errorMsg);
        }
        // here we are accessing private members of ag-Grid. Not ideal!
        this.expressionService = (this.gridApi as any).context?.beanWrappers?.expressionService
            ?.beanInstance;
        if (!this.expressionService) {
            const errorMsg = 'Cannot get the expressionService';
            logger.error(errorMsg, this.gridApi);
            throw Error(errorMsg);
        }
        // will ask ag-grid to do it on their side long term
        if (this.gridWrapperConfiguration.swallowFormattingError) {
            // we wrap ag-Grid formatterService so we can swallow errors when formatting instead of making the whole grid to crash
            const oldFormatValue = this.valueFormatterService.formatValue;
            this.valueFormatterService.formatValue = (column, rowNode, value) => {
                try {
                    return oldFormatValue.apply(this.valueFormatterService, [
                        column,
                        rowNode,
                        value,
                    ]);
                } catch (err: any) {
                    logger.error('Error formatting cell', column, rowNode, value, err);
                    return this.gridWrapperConfiguration.swallowFormattingErrorCellValue || '';
                }
            };
        }

        // we validate the configuration
        if (!GridWrapperConfigurationValidator.validate(this.gridWrapperConfiguration)) {
            const errorMsg = 'The grid wrapper configuration is invalid';
            logger.error(errorMsg, this.gridWrapperConfiguration);
            throw Error(errorMsg);
        }

        // we create all the component registries
        this.screensRegistry = new Registry<PluginScreen>();
        this.widgetsRegistry = new Registry<PluginWidget>();
        this.actionsRegistry = new Registry<PluginAction>();

        // we create a store for this instance of the grid
        this.dataGridStore = new DataGridStore(this, defaultGridState);

        // get list of modules available in grid (for plugin detection)
        this.registeredModulesNames = ModuleRegistry.getRegisteredModules().map(
            el => el.moduleName
        );

        // we create all the plugins that are registered
        // we might want to allow dev to pass the list of plugins to used in the futur
        this.plugins = pluginRegistry.getAll().map(constructor => new constructor(this));

        this.wrapperRef = wrapperRef;

        if (draDatasource) {
            const draPlugin = new DraPlugin(this, gridApi, columnApi, draDatasource, customOptions);
            this.plugins.push(draPlugin);
        }

        // UX-14334
        const enabledPlugins = defaultGridState?.plugins?.enabled ?? Object.values(Plugins);
        this.plugins.forEach(plugin => {
            if (!enabledPlugins?.includes(plugin.getName())) {
                return;
            }
            this.addPluginChangeListener(plugin);
            plugin.initialize();
        });

        this.styleService = new StyleService(this);
        this.styleService.start();
        this.excelStyleService = new ExcelStyleService(this);
        this.excelStyleService.start();

        this.hookTheGrid();

        // we put the current config in the state
        this.dataGridStore
            .getReduxStore()
            .dispatch(SystemConfigurationSetGridWrapper(this.gridWrapperConfiguration));

        this.plugins.forEach(plugin => {
            if (!enabledPlugins.includes(plugin.getName())) {
                return;
            }
            plugin.start();
        });
    }
    public removeColumnFilter(columnId: string): void {
        this.gridApi.setFilterModel({ ...this.gridApi.getFilterModel(), [columnId]: undefined });
    }
    public removeAllColumnFilters(): void {
        this.gridApi.setFilterModel({});
    }
    public getColumnFilterModel() {
        return { ...this.gridApi.getFilterModel() };
    }
    public getAbsoluteSort(
        previousSortModelItem: {
            colId: string;
            sort: string;
        },
        absCS: CustomSort | undefined,
        gridOptions: GridOptions
    ): undefined | ActiveAbsoluteSort {
        const sortModel = this.columnApi
            .getColumnState()
            .filter(col => col.sort)
            .map(col => ({ colId: col.colId || '', sort: col.sort || '' }));
        if (absCS) {
            const columnDef = this.gridApi.getColumnDef(absCS.columnId);
            if (!columnDef) {
                logger.warn(`Cannot find column for columnId: ${absCS.columnId}`);
                return;
            }

            const descFirstGridOptions =
                gridOptions.sortingOrder &&
                gridOptions.sortingOrder.length >= 2 &&
                gridOptions.sortingOrder[0] === 'desc';

            const descFirstColDef =
                columnDef.sortingOrder &&
                columnDef.sortingOrder.length >= 2 &&
                columnDef.sortingOrder[0] === 'desc';

            let order: ('desc' | 'asc' | null)[] = ['asc', 'desc'];
            if (descFirstColDef || descFirstGridOptions) {
                order = ['desc', 'asc'];
            }

            absCS.absoluteSortActive
                ? (columnDef.sortingOrder = order)
                : // need cast due to ag-grid definition not supporting null values :(
                  (columnDef.sortingOrder = [...order, null] as any);

            const currentColSortModel = sortModel.find(
                sm => sm.colId === previousSortModelItem.colId
            );
            if (!currentColSortModel) {
                return {
                    active: true,
                    customSort: absCS,
                };
            }
            if (
                (order[0] === 'asc' &&
                    currentColSortModel.sort === 'asc' &&
                    previousSortModelItem.sort === 'desc' &&
                    absCS.absoluteSortActive) ||
                (order[0] === 'desc' &&
                    currentColSortModel.sort === 'desc' &&
                    previousSortModelItem.sort === 'asc' &&
                    absCS.absoluteSortActive)
            ) {
                return {
                    active: false,
                    customSort: absCS,
                };
            }
        }
        return undefined;
    }

    public getFormattedValueFromColumn(columnId: string, value: string | number | Date): string {
        const column = this.getColumn(columnId);
        let formattedValue = column
            ? this.valueFormatterService.formatValue(column, null, value)
            : null;
        // if we don't have a formatted and value is a date then convert it to a string.
        if (!formattedValue && value instanceof Date) {
            formattedValue = value.toString();
        }
        return formattedValue !== null ? formattedValue : String(value) || '';
    }

    public getCustomDRAOptions(): CustomDRAOptions {
        return this.customOptions;
    }

    public getDRADatasource(): DraDatasource | null {
        return this.draDatasource;
    }

    public setCellRenderer(
        columnId: string,
        cellRenderer: string | (new () => ICellRendererComp) | ICellRendererFunc | undefined
    ) {
        const columnDefinition = this.gridApi.getColumnDef(columnId);
        if (columnDefinition) {
            columnDefinition.cellRenderer = cellRenderer;
        } else {
            logger.warn(`Cannot find column definition for columnId: ${columnId}`);
        }
    }

    public setTheme(theme: Theme) {
        this.theme = theme;
        this.themeChanged.dispatch(theme);
    }
    public setDensity(density: Density) {
        this.dataGridStore.getReduxStore().dispatch(SetDensity(density));
    }
    public setSize(size: Size) {
        this.dataGridStore.getReduxStore().dispatch(SetSize(size));
    }

    public getTheme() {
        return this.theme;
    }

    public setColumnValueGetter(
        columnId: string,
        valueGetter: string | ((params: ValueGetterParams) => any) | undefined,
        initialValueGetter?: string | ((params: ValueGetterParams) => any) | undefined
    ) {
        // check if initialValueGetter has been set and if so then we need to store it so we can restore it later
        if (!this.initialColumnValueGetterList[columnId] && initialValueGetter != undefined) {
            this.initialColumnValueGetterList[columnId] = initialValueGetter;
        }
        const columnDefinition = this.gridApi.getColumnDef(columnId);
        if (columnDefinition) {
            columnDefinition.valueGetter = valueGetter;
        } else {
            logger.warn(`Cannot find column definition for columnId: ${columnId}`);
        }
    }

    public resetColumnValueGetterToInitialGetter(columnId: string): void {
        if (this.initialColumnValueGetterList[columnId]) {
            const columnDefinition = this.gridApi.getColumnDef(columnId);
            if (columnDefinition) {
                columnDefinition.valueGetter = this.initialColumnValueGetterList[columnId];
            } else {
                logger.warn(`Cannot find column definition for columnId: ${columnId}`);
            }
        }
    }

    public getColumnValueGetter(
        columnId: string
    ): string | ((params: ValueGetterParams) => any) | undefined {
        const columnDefinition = this.gridApi.getColumnDef(columnId);
        if (columnDefinition) {
            return columnDefinition.valueGetter;
        }
        logger.warn(`Cannot find column definition for columnId: ${columnId}`);

        return undefined;
    }

    public removeCellRenderer(columnId: string) {
        const columnDefinition = this.gridApi.getColumnDef(columnId);
        if (columnDefinition) {
            columnDefinition.cellRenderer = undefined;
        } else {
            logger.warn(`Cannot find column definition for columnId: ${columnId}`);
        }
    }

    public setColumnEditable(columnId: string, editable: boolean): void {
        const columnDef = this.gridApi.getColumnDef(columnId);
        if (!columnDef) {
            logger.warn(`Cannot find column for columnId: ${columnId}`);
            return;
        }
        columnDef.editable = editable;
    }

    public setColumnVisibilty(columnId: string, visible: boolean): void {
        this.columnApi.setColumnVisible(columnId, visible);
    }

    // parse all columns and store the Id of all columns with a floating filter enabled
    public initialiseFloatingFilters = () => {
        const columns = this.columnApi.getAllGridColumns();
        const activeFloativeFilters = columns
            .filter(col => col.getColDef().floatingFilter)
            .map(col => col.getColId());

        if (columns.length === activeFloativeFilters.length) {
            activeFloativeFilters.push('All');
        }
        this.dataGridStore
            .getReduxStore()
            .dispatch(
                GridSetFloatingFilter(
                    !!activeFloativeFilters.length,
                    new Set(activeFloativeFilters)
                )
            );
    };

    public toggleFloatingFilters = () => {
        const { enabled, columns } = this.dataGridStore.getReduxStore().getState()
            .grid.floatingFilter;
        // Check if the state of the floating filter has been initialised
        if (!(columns instanceof Set)) {
            this.initialiseFloatingFilters();
        } else {
            const newColumnDefs = this.setPropertyOnColDefs(
                this.gridApi.getColumnDefs(),
                'floatingFilter',
                enabled,
                columns
            );
            this.gridApi.setColumnDefs(newColumnDefs);

            if (columns.has(agGridAutoColumn)) {
                const coldef = this.columnApi.getColumn(agGridAutoColumn)?.getColDef();
                if (coldef) {
                    this.gridApi.setAutoGroupColumnDef({
                        ...coldef,
                        floatingFilter: enabled,
                    });
                }
            }
            this.gridApi.refreshHeader();
        }
    };
    // Grouped Columns have their coldefs nested in the children property. Function recursively checks and updates properties accrodingly
    public setPropertyOnColDefs = (
        colDefs: (ColDef | ColGroupDef)[] | undefined,
        property: string,
        value: any,
        matchSet: Set<string>
    ): (ColDef | ColGroupDef)[] => {
        return (colDefs || []).map(colDef => {
            if ((colDef as ColGroupDef).children) {
                return {
                    ...colDef,
                    children: this.setPropertyOnColDefs(
                        (colDef as ColGroupDef).children,
                        property,
                        value,
                        matchSet
                    ),
                };
            }
            // If the set is empty, the property applies to all the columns. Column Id can be the ColId or field : https://www.ag-grid.com/javascript-data-grid/column-definitions/#column-ids
            if (
                matchSet.size === 0 ||
                matchSet.has('All') ||
                matchSet.has((colDef as ColDef).colId || '') ||
                matchSet.has((colDef as ColDef).field || '')
            ) {
                return { ...colDef, [property]: value };
            }
            return colDef;
        });
    };
    /**
     * Recreate a column, useful for updating colDefs that need the column reconstructed
     * Before calling this ensure that you have updated the colDef values that you want to update
     * Note: Does not work for grouped columns
     */
    private recreateColumn(columnId: string) {
        const columnDefs = this.gridApi.getColumnDefs();
        let colDefToUpdate: ColDef | null = null;
        let indexToInsert = -1;
        const updatedColumnDefs = columnDefs
            ? columnDefs.filter((x, i) => {
                  const isColToUpdate =
                      (x as ColDef).colId === columnId || (x as ColDef).field === columnId;
                  if (isColToUpdate) {
                      colDefToUpdate = x;
                      indexToInsert = i;
                  }
                  return !isColToUpdate;
              })
            : [];
        if (colDefToUpdate && indexToInsert >= 0) {
            this.gridApi.setColumnDefs(updatedColumnDefs);
            updatedColumnDefs.splice(indexToInsert, 0, colDefToUpdate);
            this.gridApi.setColumnDefs(updatedColumnDefs);
        }
    }

    /**
     * Set the min width for a column. Note: Does not work for grouped columns
     */
    public setColumnMinWidth(columnId: string, width: number): void {
        const column = this.columnApi.getColumn(columnId);
        if (column) {
            const colDef = column.getColDef();
            colDef.minWidth = width;
            if (column.getColId() === agGridAutoColumn) {
                this.gridApi.setAutoGroupColumnDef(colDef);
            } else {
                this.recreateColumn(columnId);
            }
        }
    }

    /**
     * Set the max width for a column. Note: Does not work for grouped columns
     */
    public setColumnMaxWidth(columnId: string, width: number): void {
        const column = this.columnApi.getColumn(columnId);
        if (column) {
            const colDef = column.getColDef();
            colDef.maxWidth = width;
            if (column.getColId() === agGridAutoColumn) {
                this.gridApi.setAutoGroupColumnDef(colDef);
            } else {
                this.recreateColumn(columnId);
            }
        }
    }

    public setId(id: string) {
        this.gridWrapperConfiguration.id = id;
    }

    public setColumnDataTypeDefinition(columnDataTypeDefinition: { [index: string]: DataType }) {
        this.columnDataTypeDefinition = columnDataTypeDefinition;
        this.updateStoreWithGridDefinition();
    }

    public getReduxStore(): Redux.Store<DataGridState> {
        return this.dataGridStore.getReduxStore();
    }
    public getReduxStoreContext(): Context<ReactReduxContextValue> | undefined {
        return this.dataGridStore.getStoreContext();
    }
    public getBaseState(): DataGridState | null {
        return this.dataGridStore.getBaseState();
    }

    public getScreensRegistry(): Registry<PluginScreen> {
        return this.screensRegistry;
    }

    public getWidgetsRegistry(): Registry<PluginWidget> {
        return this.widgetsRegistry;
    }

    public getActionsRegistry(): Registry<PluginAction> {
        return this.actionsRegistry;
    }

    public getRegisteredModulesNames(): string[] {
        return this.registeredModulesNames;
    }

    public setEditorValue(rowIndex: number, columnId: string, value: string): void {
        if (
            this.gridApi
                .getEditingCells()
                .every(x => x.column.getColId() !== columnId || x.rowIndex !== rowIndex)
        ) {
            logger.error(
                `The cell at row ${rowIndex} and column ${columnId} is not being edited. Cannot set the editor value`
            );
            return;
        }
        // we need to cancel the editing as ag-grid doesn't recreate the editor if we start editing the same cell
        // so we can override the editor value after
        this.gridApi.stopEditing(true);
        // Starting again the editing on the cell with new initial value
        this.gridApi.startEditingCell({ rowIndex, colKey: columnId, charPress: value });
    }

    public getDistinctColumnValueList(columnId: string): Promise<DisplayValueTuple[]> {
        const distinctValues: Map<string, DisplayValueTuple> = new Map();
        const column = this.columnApi.getColumn(columnId);
        if (!column) {
            logger.warn(`Cannot find columnId ${columnId}`);
            return Promise.resolve([]);
        }
        const colDef = this.gridApi.getColumnDef(columnId);
        if (colDef && colDef.filterParams && colDef.filterParams.values) {
            if (Array.isArray(colDef.filterParams.values)) {
                const returnArray = colDef.filterParams.values.map((x: any) => ({
                    displayValue: x,
                    rawValue: x,
                }));
                return Promise.resolve(returnArray);
            }
            if (typeof colDef.filterParams.values === 'function') {
                return new Promise(resolve => {
                    colDef.filterParams.values({
                        colDef,
                        success: (values: string[]) =>
                            resolve(
                                values.map(x => ({
                                    displayValue: x,
                                    rawValue: x,
                                }))
                            ),
                    });
                });
            }
        }
        if (!this.isUsingDra()) {
            this.gridApi.forEachNode(rowNode => {
                if (!rowNode.group) {
                    const rawValue = this.gridApi.getValue(columnId, rowNode);
                    const displayValue = this.getDisplayValueWithColumn(rowNode, column);
                    distinctValues.set(displayValue, { displayValue, rawValue });
                }
            });
        }

        return Promise.resolve(Array.from(distinctValues.values()));
    }

    public evaluateValueGetter(
        valueGetter: string | ((params: ValueGetterParams | ValueFormatterParams) => any),
        params: ValueGetterParams | ValueFormatterParams,
        useExpressionEvaluator?: boolean
    ): any {
        if (
            typeof valueGetter === 'string' &&
            (useExpressionEvaluator || this.getGridOptions().enableCellExpressions)
        ) {
            return this.expressionService.evaluate(valueGetter, params);
        }
        if (typeof valueGetter === 'string') {
            return valueGetter;
        }
        if (valueGetter) {
            return valueGetter(params);
        }
    }

    public getDisplayValue(rowNode: RowNode, columnId: string): string {
        const column = this.columnApi.getColumn(columnId);
        if (!column) {
            logger.warn(`Cannot find columnId ${columnId}`);
            return '';
        }
        return this.getDisplayValueWithColumn(rowNode, column);
    }

    public getRawValue(rowNode: IRowNode, columnId: string) {
        return this.gridApi.getValue(columnId, rowNode);
    }

    /**
     * Finds and returns the original value of the first row on a pivoted column.
     * @param rowNode - first row with data
     * @param columnId - Id of the pivoted column
     */
    public getPivotedColumnOriginalFirstRowValue(rowNode: IRowNode, columnId: string) {
        const column = this.getDisplayedColumn(this.columnApi.getAllGridColumns(), columnId);
        let colDef: ColDef;
        let pivotValCol: Column;
        let originalColumnId: string | undefined;
        if (column) {
            colDef = column.getColDef();
            if (colDef && colDef.pivotValueColumn) {
                pivotValCol = colDef.pivotValueColumn;
                if (pivotValCol) {
                    originalColumnId = pivotValCol.getColId();
                }
            }
        }
        if (rowNode && rowNode.data && originalColumnId) {
            return this.getRawValue(rowNode, originalColumnId);
        }
        return null;
    }

    public doesColumnExist(columnId: string) {
        return this.getColumn(columnId) !== null;
    }

    public getPivotedColumnId(columnId: string | null, pivotKeys: string[] = []) {
        if (pivotKeys && pivotKeys.length) {
            const columnList: GridColumn[] = this.getReduxStore().getState().grid.columnList;
            const pivotedColumn = columnList.find(col => {
                return (
                    arrayHelper.areIdentical(col.pivotKeys || [], pivotKeys) &&
                    col.primaryColumnId === columnId
                );
            });
            return (pivotedColumn && pivotedColumn.columnId) || 'notfound';
        }
        return columnId || '';
    }

    public refreshFiltering(): void {
        if (!this.debouncedRefreshFiltering) {
            logger.error('Cannot refresh filtering as debouncedRefreshFiltering is null');
            return;
        }
        this.debouncedRefreshFiltering();
    }

    public onIsFilterPresent(isFilterPresent: () => boolean): void {
        this.isFilterPresentListCallbacks.push(isFilterPresent);
    }
    public onFilterPass(filterPass: (rowNode: IRowNode) => boolean): void {
        this.filterPassListCallbacks.push(filterPass);
    }

    public refreshGrid() {
        this.gridApi.redrawRows();
    }

    private addPluginChangeListener(plugin: PluginBase<GridWrapper, DataGridState>) {
        plugin
            .getStateChangedEventDispatcher()
            .subscribe(
                ({ eventType, pluginState }: { eventType: PluginStateType; pluginState: any }) => {
                    this.pluginStateChanged.dispatchAsync({
                        pluginName: plugin.getName(),
                        pluginState,
                        eventType,
                    });
                }
            );
    }

    public addPlugin(plugin: PluginBase<GridWrapper, DataGridState>): void {
        this.addPluginChangeListener(plugin);
        plugin.initialize();
        plugin.start();
        this.plugins.push(plugin);
        if (this.onPluginAddedCallback) {
            this.onPluginAddedCallback(plugin);
        }
    }

    public onPluginAdded(
        onPluginAddedCallback: (plugin: PluginBase<GridWrapper, DataGridState>) => void
    ): void {
        this.onPluginAddedCallback = onPluginAddedCallback;
    }

    public getPlugins(): PluginBase<GridWrapper, DataGridState, object>[] {
        return [...this.plugins];
    }

    public addCustomSort(
        customSort: CustomSort,
        comparator: (
            valueA: any,
            valueB: any,
            nodeA?: IRowNode,
            nodeB?: IRowNode,
            isInverted?: boolean
        ) => number
    ): void {
        const columnState = this.columnApi.getColumnState();
        const columnDef = this.gridApi.getColumnDef(customSort.columnId);
        if (!columnDef) {
            logger.warn(`Cannot find column for columnId: ${customSort.columnId}`);
            return;
        }

        if (columnDef.comparator) {
            logger.error('There is already a custom sort set for that column', customSort.columnId);
            return;
        }
        columnDef.comparator = comparator;
        // if the column was in the column state and sorted then we refresh the sort
        if (
            columnState.find(
                columnStateEntry =>
                    columnStateEntry.colId === customSort.columnId && columnStateEntry.sort
            )
        ) {
            this.columnApi.applyColumnState({
                state: columnState,
                applyOrder: true,
            });
        }
        if (customSort.absoluteSortActive) {
            columnDef.headerClass = ABSOLUTE_CLASSNAME;
            this.refreshHeader();
        }
    }

    public removeCustomSort(columnId: string): void {
        const columnState = this.columnApi.getColumnState();
        const columnDef = this.gridApi.getColumnDef(columnId);

        if (!columnDef) {
            logger.warn(`Cannot find column for columnId: ${columnId}`);
            return;
        }

        columnDef.headerClass = '';
        this.refreshHeader();

        columnDef.comparator = undefined;
        // if the column was in the column state and sorted then we refresh the sort
        if (
            columnState.find(
                columnStateEntry => columnStateEntry.colId === columnId && columnStateEntry.sort
            )
        ) {
            this.columnApi.applyColumnState({
                state: columnState,
                applyOrder: true,
            });
        }
    }

    public addQuickFilter(search: string): void {
        if (this.isUsingDra() || this.isServerSide()) {
            this.refreshFiltering();
        } else {
            this.gridApi.setQuickFilter(search);
        }
    }

    public isUsingDra(): boolean {
        if (this.gridWrapperConfiguration.datasourceConfiguration) {
            return (
                this.gridWrapperConfiguration.datasourceConfiguration.datasourceType ===
                DatasourceType.DRA
            );
        }

        // No datasource configuration defined so not DRA
        return false;
    }

    public isServerSide() {
        return this.gridOptions.rowModelType === 'serverSide';
    }

    public mapColumnsToDefinitions(agGridColumns: Column[]) {
        const partialColumnDataTypeDefinition =
            !this.columnDataTypeDefinition ||
            agGridColumns.length !== Object.keys(this.columnDataTypeDefinition).length;

        /**
         * We get the dataType differently for DRA sources and In-mem
         * In mem we look at the first row of data and determine the type from that
         * DRA we look at the type that is sent down from the datasource.
         */
        let firstRowWithData: IRowNode;
        if (!this.isUsingDra() && partialColumnDataTypeDefinition && !this.isServerSide()) {
            const firstRow: IRowNode | undefined =
                this.gridApi.getDisplayedRowAtIndex(0) || undefined;
            if (firstRow) {
                firstRowWithData = firstRow.group
                    ? arrayHelper.first(firstRow.allLeafChildren) || firstRow
                    : firstRow;
            }
        }

        const columnDefinitions: GridColumn[] = agGridColumns.map((col: Column) => {
            const agGridColDef = col.getColDef();
            let dataType: DataType = DataType.Unknown;
            if (
                col.getColId().includes(agGridAutoColumn) ||
                (this.isUsingDra() && col.getColId() === rowGroupColumnColId)
            ) {
                const pivotDepth = this.columnApi.getRowGroupColumns().length;
                for (let i = 1; i <= pivotDepth; i += 1) {
                    this.addCellClassRule(
                        col.getColId(),
                        `${PIVOT_COLUMN_CLASS_PREFIX}${i}`,
                        (params: CellClassParams) => {
                            return params.node.level === i;
                        }
                    );
                }
            }

            if (this.columnDataTypeDefinition && this.columnDataTypeDefinition[col.getColId()]) {
                dataType = this.columnDataTypeFromColumnDataTypeDefinition(col.getColId());
            } else if (this.isUsingDra()) {
                // There should always be a DRA plugin when the datasource is DRA
                const draPlugin = this.plugins.find(plugin => {
                    return plugin.getName() === Plugins.DraPlugin;
                });
                dataType = (draPlugin as DraPlugin).getColumnDataType(col.getColId());
            } else if (firstRowWithData) {
                let value = this.getRawValue(firstRowWithData, col.getColId());
                if (!value && this.columnApi.isPivotMode()) {
                    value = this.getPivotedColumnOriginalFirstRowValue(
                        firstRowWithData,
                        col.getColId()
                    );
                }
                dataType = this.determineDataType(value);
            }

            const primaryColumnId =
                this.columnApi.isPivotMode() &&
                agGridColDef.pivotKeys &&
                agGridColDef.pivotKeys.length &&
                agGridColDef.pivotValueColumn
                    ? agGridColDef.pivotValueColumn.getColId()
                    : col.getColId();

            const gridCol: GridColumn = {
                dataType,
                primaryColumnId,
                columnId: col.getColId(),
                columnLabel: this.getFullColumnLabel(col),
                hidden: agGridColDef.suppressColumnsToolPanel || false,
                isEditable: false,
                isFilterable: !!agGridColDef.filter || false,
                isRowGroup:
                    // if the column name contains generated auto column or is the DRA pivot column
                    col.getColId().includes(agGridAutoColumn) ||
                    (this.isUsingDra() && col.getColId() === rowGroupColumnColId),
                // we need both the grid and column to be sortable
                isSortable: agGridColDef.sortable || false,
                pivotKeys: agGridColDef.pivotKeys || [],
                visible: col.isVisible(),
                visibleIndex: 0,
            };
            return gridCol;
        });

        return columnDefinitions;
    }

    public updateStoreWithGridDefinition() {
        // we need the primary columns when filtering in pivot mode
        const agGridPrimaryColumns: Column[] = this.columnApi.getColumns() || [];
        const agGridColumns: Column[] = this.columnApi.getAllGridColumns();
        if (!agGridColumns) {
            logger.error(
                'No columns were found in ag-Grid schema. Please check your configuration'
            );
            return;
        }

        const columnDefinitions = this.mapColumnsToDefinitions(agGridColumns);
        const primaryColumnDefinitions = this.mapColumnsToDefinitions(agGridPrimaryColumns);

        const currentColumnList = this.getReduxStore().getState().grid.columnList;
        const currentPrimaryColumnList = this.getReduxStore().getState().grid.primaryColumnList;
        // we do a deep comparison here as ag-grid raises the events quit a few times on startup and we are interested only in a subset of the info
        if (!isEqual(columnDefinitions, currentColumnList)) {
            this.dataGridStore
                .getReduxStore()
                .dispatch(GridSetColumnDefinitions(columnDefinitions));
        }
        if (!isEqual(primaryColumnDefinitions, currentPrimaryColumnList)) {
            this.dataGridStore
                .getReduxStore()
                .dispatch(GridSetPrimaryColumnDefinitions(primaryColumnDefinitions));
        }
    }

    public getColumnDataType(columnId: string): DataType {
        const columnList = this.getReduxStore().getState().grid.columnList;
        const foundCol = columnList.find(col => col.columnId === columnId);
        if (foundCol && foundCol.dataType) {
            return foundCol.dataType;
        }
        return DataType.Unknown;
    }

    public autofitColumn(columnId: string): void {
        this.columnApi.autoSizeColumn(columnId);
    }

    public hasColumnTruncatedValues(colId: string): boolean {
        const rowRenderer = this.getRowRenderer();

        const column = this.getColumn(colId);
        if (!column) {
            return false;
        }

        const allCells = rowRenderer.getAllCellsForColumn(column);
        return allCells.some(x => x.scrollWidth > x.clientWidth);
    }

    public maskColumn(colId: string): void {
        const rowRenderer = this.getRowRenderer();

        const column = this.getColumn(colId);
        if (!column) {
            return;
        }

        const allCells = rowRenderer.getAllCellsForColumn(column);
        if (allCells.length === 0) {
            return;
        }
        let maskedElement = this.cachedMaskedColumn.get(colId);
        const firstCell = arrayHelper.first(allCells);
        if (firstCell) {
            const body = firstCell.closest(`.${AG_BODY_VIEWPORT_CLASS}`);
            const mainCustomDiv = firstCell.closest(`.${ThemeTypeClassName.ROOT}`);
            if (!maskedElement && mainCustomDiv) {
                maskedElement = globalHelper.createColumnMaskingDiv(colId);
                this.cachedMaskedColumn.set(colId, maskedElement);
                mainCustomDiv.appendChild(maskedElement);
            }
            if (maskedElement && mainCustomDiv) {
                if (body) {
                    const leftMask =
                        firstCell.getBoundingClientRect().left -
                        mainCustomDiv.getBoundingClientRect().left;
                    maskedElement.style.left = globalHelper.convertNumberToPixelCss(leftMask);
                    maskedElement.style.top = globalHelper.convertNumberToPixelCss(
                        body.getBoundingClientRect().top - mainCustomDiv.getBoundingClientRect().top
                    );
                    maskedElement.style.position = 'absolute';
                    maskedElement.style.height = globalHelper.convertNumberToPixelCss(
                        body.clientHeight
                    );
                    maskedElement.style.width = globalHelper.convertNumberToPixelCss(
                        Math.min(
                            column.getActualWidth(),
                            body.getBoundingClientRect().width - leftMask
                        )
                    );
                } else {
                    logger.error('Cannot find ag-grid body');
                }

                maskedElement.hidden = false;
            }
        } else {
            logger.error('Cannot get the first cell of the column');
        }
    }

    public unmaskColumn(colId: string): void {
        const maskedElement = this.cachedMaskedColumn.get(colId);
        if (maskedElement) {
            const parentNode = maskedElement.parentNode;
            if (parentNode) {
                this.cachedMaskedColumn.delete(colId);
                parentNode.removeChild(maskedElement);
            }
        }
    }

    public removeCellClassRule(columnId: string | null, propertyName: string): void {
        const deleteClassRules = (column: Column) => {
            const colDef = column.getColDef();
            if (colDef.cellClassRules && colDef.cellClassRules[propertyName]) {
                delete colDef.cellClassRules[propertyName];
            }
        };
        if (columnId) {
            const column = this.getColumn(columnId);
            if (column) {
                deleteClassRules(column);
            }
        } else {
            const allColumns = this.columnApi.getAllGridColumns();
            if (allColumns) {
                allColumns.forEach(column => {
                    deleteClassRules(column);
                });
            }
        }
    }
    public addCellClassRule(
        columnId: string | null,
        propertyName: string,
        func: (params: CellClassParams) => boolean
    ): void {
        const addClassRules = (column: Column, rule: (params: CellClassParams) => boolean) => {
            const colDef = column.getColDef();
            if (!colDef.cellClassRules) {
                colDef.cellClassRules = {};
            }
            colDef.cellClassRules[propertyName] = rule;
        };
        if (columnId) {
            const column = this.getColumn(columnId);
            if (column) {
                addClassRules(column, func);
            }
        } else {
            this.columnApi.getAllGridColumns().forEach(column => {
                addClassRules(column, func);
            });
        }
    }

    public removeRowClassRule(propertyName: string): void {
        if (this.gridOptions.rowClassRules && this.gridOptions.rowClassRules[propertyName]) {
            delete this.gridOptions.rowClassRules[propertyName];
        }
    }

    public setColumnStyle(columnId: string, func: CellStyleFunc): void {
        const columndef = this.gridApi.getColumnDef(columnId);
        if (!columndef) {
            logger.warn(`Cannot find column for columnId: ${columnId}`);
            return;
        }
        if (columndef.cellStyle) {
            logger.warn(
                'Overwriting cellStyle of the column definition',
                columndef.cellStyle,
                columndef
            );
        }
        if (columndef) {
            columndef.cellStyle = func;
        }
    }

    public removeColumnStyle(columnId: string): void {
        const columndef = this.gridApi.getColumnDef(columnId);
        if (columndef) {
            columndef.cellStyle = undefined;
        }
    }

    public addRowClassRule(propertyName: string, rule: (params: RowClassParams) => boolean): void {
        if (!this.gridOptions.rowClassRules) {
            this.gridOptions.rowClassRules = {};
        }
        this.gridOptions.rowClassRules[propertyName] = rule;
    }

    public getSavedView(options: SavedViewOptions, name: string): SavedView {
        const savedView: SavedView = {
            name,
            columnGroups: options.columnGroups ? [...this.columnApi.getColumnGroupState()] : null,
            columnState: options.columns ? [...this.columnApi.getColumnState()] : null,
            expansionDepth: options.expansionDepth
                ? getGridOptionsService(this.gridApi).getNum('groupDefaultExpanded') || null
                : null,
            savedViewOptions: options,
            pivotState: options.pivot
                ? {
                      isPivotMode: this.columnApi.isPivotMode(),
                      pivotColumns: [
                          ...this.columnApi.getPivotColumns().map(column => column.getColId()),
                      ],
                  }
                : null,
            rowGroupColumns: options.rowGroupColumns
                ? [...this.columnApi.getRowGroupColumns().map(column => column.getColId())]
                : null,
        };

        return savedView;
    }
    public setSavedView(savedView: SavedView) {
        if (savedView.pivotState) {
            this.columnApi.setPivotMode(savedView.pivotState.isPivotMode);
            if (savedView.pivotState.pivotColumns) {
                this.columnApi.setPivotColumns(savedView.pivotState.pivotColumns);
            }
        } else if (this.columnApi.isPivotMode() && savedView.pivotState === undefined) {
            this.columnApi.setPivotMode(false);
        }

        if (savedView.columnState) {
            this.columnApi.applyColumnState({
                state: savedView.columnState,
                applyOrder: true,
            });
        }
        if (savedView.columnGroups) {
            this.columnApi.setColumnGroupState(savedView.columnGroups);
        }
        if (savedView.rowGroupColumns) {
            this.columnApi.setRowGroupColumns(savedView.rowGroupColumns);
        }
        if (savedView.expansionDepth !== null && savedView.expansionDepth !== undefined) {
            getGridOptionsService(this.gridApi).set(
                'groupDefaultExpanded',
                savedView.expansionDepth
            );
            this.gridApi.dispatchEvent({ type: Events.EVENT_COLUMN_ROW_GROUP_CHANGED });
        }
    }

    public getId() {
        return this.gridWrapperConfiguration.id;
    }

    public setHeaderName(columnId: string, name: string): void {
        const colDef = this.gridApi.getColumnDef(columnId);
        if (!colDef) {
            logger.warn(`Cannot find column for columnId: ${columnId}`);
            return;
        }
        colDef.headerName = name;
        this.refreshHeader();
    }

    public setHeaderGroupClass(groupId: string, className: string): void {
        const groupDef = this.columnApi.getColumnGroup(groupId + groupIdSuffix);
        if (!groupDef) {
            logger.warn(`Cannot find group for groupId: ${groupId}`);
            return;
        }
        const definition = groupDef.getDefinition();
        if (definition) {
            definition.headerClass = className;
            this.refreshHeader();
        }
    }

    public setHeaderClass(columnId: string, className: string): void {
        const colDef = this.gridApi.getColumnDef(columnId);

        if (!colDef) {
            logger.warn(`Cannot find Column Definition for columnId: ${columnId}`);
            return;
        }
        colDef.headerClass = className;
        this.refreshHeader();
    }

    public appendHeaderClass(className: string, columnId?: string): void {
        const colDefs = columnId
            ? [this.gridApi.getColumnDef(columnId)]
            : this.gridApi.getColumnDefs();

        if (colDefs && !colDefs.length) {
            logger.warn(
                `Cannot find Column Definition for columnId: ${columnId || ' all columns'}`
            );
            return;
        }
        (colDefs || []).forEach((colDef: ColDef | ColGroupDef | null) => {
            if (colDef) {
                const currentHeaderClasses = Array.isArray(colDef.headerClass)
                    ? colDef.headerClass
                    : colDef.headerClass
                      ? [colDef.headerClass]
                      : [];
                if (!currentHeaderClasses.includes(className)) {
                    colDef.headerClass = [...currentHeaderClasses, className] as string[];
                }
            }
        });
        if (this.gridApi && columnId !== undefined) {
            const columnDefs = this.gridApi.getColumnDefs();
            if (columnDefs) {
                const colDefsFiltered = columnDefs.filter(
                    colDef => colDef && (colDef as ColDef).colId !== columnId
                );

                this.gridApi.setColumnDefs([...colDefsFiltered, ...(colDefs as ColDef[])]);
            }
        } else {
            this.gridApi.setColumnDefs(colDefs as ColDef[]);
        }

        this.refreshHeader();
    }

    public removeAppendedHeaderClass(className: string, columnId?: string): void {
        const colDefs = columnId
            ? [this.gridApi.getColumnDef(columnId)]
            : this.gridApi.getColumnDefs();

        if (colDefs && !colDefs.length) {
            logger.warn(
                `Cannot find Column Definition for columnId: ${columnId || ' all columns'}`
            );
            return;
        }
        if (colDefs) {
            colDefs.forEach((colDef: ColDef | ColGroupDef | null) => {
                if (colDef && colDef.headerClass && Array.isArray(colDef.headerClass)) {
                    colDef.headerClass = (colDef.headerClass as string[]).filter(
                        name => name !== className
                    );
                }
            });
        }

        if (this.gridApi && columnId !== undefined) {
            const columnDefs = this.gridApi.getColumnDefs();
            if (columnDefs) {
                const colDefsFiltered = columnDefs.filter(
                    colDef => colDef && (colDef as ColDef).colId !== columnId
                );

                this.gridApi.setColumnDefs([...colDefsFiltered, ...(colDefs as ColDef[])]);
            }
        } else {
            this.gridApi.setColumnDefs(colDefs as ColDef[]);
        }
        this.refreshHeader();
    }

    public setHeaderTooltip(columnId: string, tooltip: string): void {
        const colDef = this.gridApi.getColumnDef(columnId);
        if (!colDef) {
            logger.warn(`Cannot find column for columnId: ${columnId}`);
            return;
        }
        colDef.headerTooltip = tooltip;
        this.refreshHeader();
    }

    public setColumnWidth(columnId: string, width: number): void {
        this.columnApi.setColumnWidth(columnId, width);
    }

    public setTooltip(columnId: string, tooltip: string): void {
        const col = this.getColumn(columnId);
        if (col) {
            const colDef = col.getColDef();

            colDef.tooltipValueGetter = () => {
                return tooltip;
            };
        }
    }

    public setFormatter(columnId: string, formatter: (value: any) => string): void {
        const column = this.getDisplayedColumn(this.columnApi.getAllGridColumns(), columnId);
        if (!column) {
            logger.warn(`Cannot find column for columnId: ${columnId}`);
            return;
        }
        const colDef = column.getColDef();
        colDef.valueFormatter = (params: ValueFormatterParams) => {
            return formatter(params.value);
        };
    }

    public removeFormatter(columnId: string): void {
        const column = this.getDisplayedColumn(this.columnApi.getAllGridColumns(), columnId);
        if (!column) {
            logger.warn(`Cannot find column for columnId: ${columnId}`);
            return;
        }
        const colDef = column.getColDef();
        // This is going to clear out the user's formatter if they've set one :(
        // We need to clear the value formatter when removing column hints
        colDef.valueFormatter = undefined;
    }

    public getSelectedNodes(): IRowNode[] {
        return this.gridApi.getSelectedNodes();
    }

    public forEachNode(func: (rowNode: IRowNode) => void): void {
        this.gridApi.forEachNode(func);
    }

    public forEachNodeAfterFilterAndSort(func: (rowNode: IRowNode) => void): void {
        this.gridApi.forEachNodeAfterFilterAndSort(func);
    }

    public async export(
        options: ExportOptions,
        callbacks: ExportCallbacks = this.exportCallbacks
    ): Promise<void> {
        if (options.type === ExportType.Excel) {
            if (this.isUsingDra() && options.rowsToExport === ExportRowOptionType.AllRows) {
                await this.useMaximisedDRAData(() =>
                    this.gridApi.exportDataAsExcel(
                        this.getExportParams(options, callbacks) as ExcelExportParams
                    )
                );
            } else if (
                this.isServerSide() &&
                options.rowsToExport === ExportRowOptionType.AllRows
            ) {
                await this.useMaximisedSSRMData(() =>
                    this.gridApi.exportDataAsExcel(
                        this.getExportParams(options, callbacks) as ExcelExportParams
                    )
                );
            } else {
                this.gridApi.exportDataAsExcel(
                    this.getExportParams(options, callbacks) as ExcelExportParams
                );
            }
        } else {
            if (this.isUsingDra() && options.rowsToExport === ExportRowOptionType.AllRows) {
                await this.useMaximisedDRAData(() =>
                    this.gridApi.exportDataAsCsv(
                        this.getExportParams(options, callbacks) as CsvExportParams
                    )
                );
            } else if (
                this.isServerSide() &&
                options.rowsToExport === ExportRowOptionType.AllRows
            ) {
                await this.useMaximisedSSRMData(() =>
                    this.gridApi.exportDataAsCsv(
                        this.getExportParams(options, callbacks) as CsvExportParams
                    )
                );
            } else {
                this.gridApi.exportDataAsCsv(
                    this.getExportParams(options, callbacks) as CsvExportParams
                );
            }
        }
    }

    public getGridOptions(): GridOptions {
        return this.gridOptions;
    }

    public addColumnResizeListener(callback: () => void) {
        this.gridApi.addEventListener(Events.EVENT_COLUMN_RESIZED, callback);
    }

    public removeColumnResizeListener(callback: () => void) {
        this.gridApi.removeEventListener(Events.EVENT_COLUMN_RESIZED, callback);
    }

    public addGridViewportChangedListener(callback: () => void) {
        this.gridApi.addEventListener(Events.EVENT_VIEWPORT_CHANGED, callback);
    }

    public removeGridViewportChangedListener(callback: () => void) {
        this.gridApi.removeEventListener(Events.EVENT_VIEWPORT_CHANGED, callback);
    }

    public setColumnHeaderHeights(value: number): void {
        this.gridApi.setHeaderHeight(value);
        this.gridApi.resetRowHeights();
    }

    public getOverlayContainer() {
        return this.overlayContainerRef;
    }

    public destroy(): void {
        this.debouncedRefreshHeader = null;
        // we unsubscribe to all the event we subscribed on for ag-grid api
        this.apiEventListenerSubscription.forEach(subscription => {
            this.gridApi.removeEventListener(subscription.event, subscription.func);
        });
        this.gridApi.removeGlobalListener(this.updateStoreWithGridDefinitionFunc);

        this.plugins.forEach(plugin => plugin.stop());
        this.styleService.stop();
        this.excelStyleService.stop();
    }

    public reset(
        draDatasource: DraDatasource | null,
        datasourceConfiguration: DatasourceConfiguration
    ): void {
        const oldDraIndex = this.plugins.findIndex(x => x instanceof DraPlugin);
        if (oldDraIndex > -1) {
            this.plugins[oldDraIndex].stop();
            this.plugins.splice(oldDraIndex, 1);
        }
        if (draDatasource) {
            const draPlugin = new DraPlugin(
                this,
                this.gridApi,
                this.columnApi,
                draDatasource,
                this.customOptions
            );
            this.plugins.push(draPlugin);
            draPlugin.initialize();
            draPlugin.start();
        }

        const gridWrapperConfigClone = { ...this.gridWrapperConfiguration };
        gridWrapperConfigClone.datasourceConfiguration = datasourceConfiguration;

        this.dataGridStore
            .getReduxStore()
            .dispatch(SystemConfigurationSetGridWrapper(gridWrapperConfigClone));
    }

    public setOpenModalScreenCallback(callback: (screenId: string) => void): void {
        this.openModalScreen = callback;
    }

    private columnDataTypeFromColumnDataTypeDefinition(columnId: string): DataType {
        let dataType = DataType.Unknown;
        if (this.columnDataTypeDefinition && columnId in this.columnDataTypeDefinition) {
            dataType = this.columnDataTypeDefinition[columnId];
        } else {
            logger.warn(`Column type definition doesn't exist for ${columnId}`);
        }
        return dataType;
    }

    private getFullColumnLabel(column: Column) {
        let columnLabelBuilder = this.columnApi.getDisplayNameForColumn(column, null);
        let usingCol: Column | ColumnGroup = column;

        while (
            usingCol.getParent() &&
            this.columnApi.getDisplayNameForColumnGroup(usingCol.getParent(), 'ignore')
        ) {
            // Location is where we want the group to be displayed,
            // giving it a dummy value since ag-grid doesn't allow null for it not to be changed from this method
            const groupName = this.columnApi.getDisplayNameForColumnGroup(
                usingCol.getParent(),
                'ignore'
            );
            columnLabelBuilder = `${groupName}, ${columnLabelBuilder} `;
            usingCol = usingCol.getParent();
        }
        return columnLabelBuilder;
    }

    private getExportParams(
        options: ExportOptions,
        callbacks: ExportCallbacks = {}
    ): ExcelExportParams | CsvExportParams {
        const params: ExcelExportParams | CsvExportParams = {
            allColumns: options.columnsToExport === ExportColumnOptionType.AllColumns,
            columnGroups: options.columnGroups,
            onlySelected: options.onlySelected,
            onlySelectedAllPages: options.onlySelectedPages,

            // we return cell value except for rowGroupColumnColId where we return the TreeCol value
            autoConvertFormulas: options.includeFormatting,

            // TODO Null value for valueFormatterService should ould be removed in v18 (UX-17801)
            processCellCallback: paramsCell =>
                processCellForExport(
                    paramsCell,
                    options,
                    this.getReduxStore().getState().grid.columnList,
                    callbacks.processCellCallback,
                    this.evaluateValueGetter.bind(this)
                ),

            processHeaderCallback: callbacks.processHeaderCallback,

            // Skip groups doesn't do anything so we use it to set skipRowGroups
            skipRowGroups: options.skipGroups,
            skipColumnHeaders: options.skipColumnHeaders,
            fileName: options.fileName,
        };

        if (callbacks.shouldRowBeSkipped) {
            params.shouldRowBeSkipped = callbacks.shouldRowBeSkipped;
        }
        if (callbacks.processGroupHeaderCallback) {
            params.processGroupHeaderCallback = callbacks.processGroupHeaderCallback;
        }

        if (callbacks.getCustomHeader) {
            params.prependContent = callbacks.getCustomHeader();
        }

        if (callbacks.getCustomFooter) {
            params.appendContent = callbacks.getCustomFooter();
        }

        if (options.columnsToExport === ExportColumnOptionType.DisplayedColumns) {
            params.columnKeys = this.columnApi
                .getAllDisplayedVirtualColumns()
                .map(col => col.getColId());
        } else if (options.columnsToExport === ExportColumnOptionType.SelectedColumns) {
            params.columnKeys = options.selectedColumns;
        }

        if (
            !params.shouldRowBeSkipped &&
            options.rowsToExport === ExportRowOptionType.DisplayedRows
        ) {
            const displayedRows = this.gridApi.getRenderedNodes();
            params.shouldRowBeSkipped = (skipParams: ShouldRowBeSkippedParams) => {
                return !displayedRows.find(dNode => dNode.id === skipParams.node.id);
            };
        }

        return params;
    }
    /**
     * Maximises all the data on the grid by waiting for all incoming rows from the datasource.
     * Once received will perform an action.
     * @param onMaximisedData A callback to call once all the row data has been fetched
     */
    private useMaximisedSSRMData(onMaximisedData: () => void): Promise<void> {
        return new Promise(resolve => {
            this.storedCacheBlockSize = this.gridOptions.cacheBlockSize;
            this.gridOptions.cacheBlockSize = 10000;

            const notification = {
                dismissible: true,
                message: `Attempting to load all rows (10k rows max), this will take some time... `,
                type: NotificationType.WARNING,
            };

            const onModelUpdated = (params: ModelUpdatedEvent) => {
                const firstRow = params.api.getModel().getRow(0);
                if (firstRow && firstRow.data) {
                    this.gridApi.removeEventListener(Events.EVENT_MODEL_UPDATED, onModelUpdated);
                    this.dataGridStore.getReduxStore().dispatch(RemoveNotification(notification));
                    onMaximisedData();
                    this.gridApi.ensureIndexVisible(0, 'top');
                    this.gridOptions.cacheBlockSize = this.storedCacheBlockSize;
                    (this.gridApi.getModel() as any).resetRootStore();
                    resolve();
                }
            };
            this.gridApi.addEventListener(Events.EVENT_MODEL_UPDATED, onModelUpdated);
            (this.gridApi.getModel() as any).resetRootStore();
            this.dataGridStore.getReduxStore().dispatch(AddNotification(notification));
        });
    }

    /**
     * Maximises all the data on the grid by waiting for all incoming rows from the datasource.
     * Once received will perform an action.
     * @param onMaximisedData A callback to call once all the row data has been fetched
     */
    private useMaximisedDRAData(onMaximisedData: () => void): Promise<void> {
        const bufferSize = this.gridApi.getModel().getRowCount();
        // if we already have all the data in the viewport we just resolve
        // TODO : for aggrid upgrade check that lastRow is still behaving the same
        if (bufferSize === (this.gridApi.getModel() as any).lastRow + 1) {
            onMaximisedData();
            return Promise.resolve();
        }
        this.storedViewportRowModelBufferSize = this.gridOptions.viewportRowModelBufferSize;
        this.gridOptions.viewportRowModelBufferSize = bufferSize;
        this.gridApi.dispatchEvent({
            type: Events.EVENT_VIEWPORT_CHANGED,

            firstRow: 0,
            lastRow: bufferSize + 1,
        } as any);

        const notification = {
            dismissible: true,
            message: `Attempting to load ${bufferSize} rows, this will take some time... `,
            type: NotificationType.WARNING,
        };
        if (bufferSize > 500) {
            this.dataGridStore.getReduxStore().dispatch(AddNotification(notification));
        }

        return new Promise((resolve, reject) => {
            if (!this.draDatasource) {
                reject('Dra Datasource is null so unable to export all the data');
                return;
            }
            const draDatasource = this.draDatasource;
            const checkData = (data: RecordInsertedEventArgs) => {
                const dataFound = data.recordList.find(
                    d => d[draDatasource.provider.viewConfig.idxField] === bufferSize - 1
                );
                if (dataFound) {
                    draDatasource.recordInserted.unsubscribe(checkData);
                    this.dataGridStore.getReduxStore().dispatch(RemoveNotification(notification));
                    onMaximisedData();
                    this.resetBuffer();
                    resolve();
                }
            };
            draDatasource.recordInserted.subscribe(checkData);
        });
    }

    private resetBuffer() {
        this.gridOptions.viewportRowModelBufferSize = this.storedViewportRowModelBufferSize;
        this.gridApi.ensureIndexVisible(0, 'top');
        this.gridApi.dispatchEvent({
            type: Events.EVENT_VIEWPORT_CHANGED,

            firstRow: 0,
            lastRow: this.gridApi.getDisplayedRowAtIndex(this.gridApi.getDisplayedRowCount()),
        } as any);
    }

    private updateStoreWithGridDefinitionFunc = (type: string) => {
        // Those are the events that will trigger a rebuild of our internal column definition
        const columnEvents = [
            Events.EVENT_COLUMN_MOVED,
            Events.EVENT_COLUMN_ROW_GROUP_CHANGED,
            Events.EVENT_COLUMN_PIVOT_CHANGED,
            Events.EVENT_GRID_COLUMNS_CHANGED,
            Events.EVENT_COLUMN_VALUE_CHANGED,
            Events.EVENT_COLUMN_VISIBLE,
            Events.EVENT_NEW_COLUMNS_LOADED,
            Events.EVENT_FIRST_DATA_RENDERED,
        ];
        if (columnEvents.indexOf(type as (typeof columnEvents)[number]) > -1) {
            this.updateStoreWithGridDefinition();
        }
    };

    private columnResizedFunc = (e: ColumnEvent) => {
        if (e.column) {
            this.columnResized.dispatchAsync({ columnId: e.column.getColId() });
        } else {
            // if e.column not set it means that multiple columns have been resized so we raise the event for all columns
            const visibleColumns = this.getReduxStore()
                .getState()
                .grid.columnList.filter(col => col.visible);
            visibleColumns.forEach(col => {
                this.columnResized.dispatchAsync({ columnId: col.columnId });
            });
        }
    };

    private eventBodyScrollFunc = () => {
        this.gridScrolled.dispatchAsync({});
    };

    private eventColumnPinnedFunc = (e: ColumnEvent) => {
        if (e.column) {
            this.columnPinned.dispatchAsync({ columnId: e.column.getColId() });
        }
    };

    private rowGroupOpenedFunc = (e: RowGroupOpenedEvent) => {
        if (e.node.group) {
            logger.debug('RowGroup opened/collapsed', e);
            this.rowGroupCollapseChanged.dispatchAsync({});
            if (e.node.expanded) {
                this.rowGroupExpanded.dispatchAsync({});
            }
        }
    };

    private columnRowGroupChangedFunc = () => {
        logger.debug('RowGroup Changed');
        this.rowGroupChanged.dispatchAsync({});
    };

    private cellCalueChangedFunc = (e: NewValueParams) => {
        this.dataUpdated.dispatchAsync({
            columnId: e.column.getColId(),
            newValue: e.newValue,
            oldValue: e.oldValue,
        });
    };

    private cellEditingStartedFunc = (e: CellEditingStartedEvent) => {
        const editors = this.gridApi.getCellEditorInstances({
            columns: [e.column],
            rowNodes: [e.node],
        });
        // Cast return type to ICellEditorComp while reactUi prop is false. When reactUi is
        // eventually set to true, the cell editor component is no longer wrapped and returned
        // directly by getCellEditorInstances. We'll have to change how we get currentEditorValue
        // and supposedly we can assume currentEditor below will be the equivalent of eGui already.
        // See https://ag-grid.zendesk.com/hc/en-us/requests/19973
        const currentEditor = arrayHelper.first(editors) as ICellEditorComp | null;
        if (editors.length !== 1 || !currentEditor) {
            logger.error("Couldn't get current cell editors", editors);
            return;
        }
        const keyDownListener = (event: KeyboardEvent) =>
            // we want the event to be synchronous since we are going to preventPropagation in some cases
            this.editorKeyDown.dispatch({
                columnId: e.column.getColId(),
                currentEditorValue: currentEditor.getValue(),
                keyboardEvent: event,
                rowIndex: e.rowIndex,
            });
        const eGui = currentEditor.getGui();
        this.editorListeners.set(this.buildEditorListenerKey(e), {
            editor: eGui,
            listener: keyDownListener,
        });
        currentEditor.getGui().addEventListener('keydown', keyDownListener);
    };

    private cellEditingStoppedFunc = (e: CellEditingStoppedEvent) => {
        if (!this.editorListeners.delete(this.buildEditorListenerKey(e))) {
            logger.error("Couldn't find the previous editor. Please check.", this.editorListeners);
        }
    };

    private rowSelectionChangedFunc = () => {
        this.rowSelectionChanged.dispatchAsync({});
    };

    private firstDataRenderedFunc = () => {
        this.firstDataRendered.dispatchAsync({});
    };

    private hookTheGrid() {
        // we want to avoid calling refreshHeader multiple times at startup so we debounce the call
        this.debouncedRefreshHeader = debounce(() => {
            try {
                this.gridApi.refreshHeader();
                this.gridApi.refreshToolPanel();
            } catch (e: any) {
                logger.warn(`Couldn't refresh the headers`);
            }
        }, 100);

        // we want to avoid calling onFilterChanged multiple times at startup so we debounce the call
        this.debouncedRefreshFiltering = debounce(() => {
            this.gridApi.onFilterChanged();
        }, 100);

        // we do not care about the content of the event itself since we rebuild the grid definition from scratch
        this.gridApi.addGlobalListener(this.updateStoreWithGridDefinitionFunc);

        // TODO: we call it but that shouldn't be done here
        // probably be called when we'll do redux store initialisation or something like that
        this.updateStoreWithGridDefinition();

        const originalExternalFilterPresent = this.gridOptions.isExternalFilterPresent;
        // Tells ag-Grid if we have some custom filtering done
        this.gridOptions.isExternalFilterPresent = () => {
            // if any of the callback returns true we return true
            return (
                this.isFilterPresentListCallbacks.some(callback => callback()) ||
                (originalExternalFilterPresent
                    ? originalExternalFilterPresent({
                          api: this.gridApi,
                          columnApi: this.columnApi,
                          context: this.gridOptions.context,
                      })
                    : false)
            );
        };

        const originalDoesExternalFilterPass = this.gridOptions.doesExternalFilterPass;
        // Tells ag-Grid if the rowNode should be filtered out or not
        this.gridOptions.doesExternalFilterPass = (rowNode: IRowNode) => {
            // if any of the callback return false we return false
            return (
                this.filterPassListCallbacks.every(callback => callback(rowNode)) &&
                (originalDoesExternalFilterPass ? originalDoesExternalFilterPass(rowNode) : true)
            );
        };

        // Listen for column width changes and raise our own internal event
        // we dispatch the event async in that case because we don't really care what the subscriber will do and we want to avoid blocking ag-Grid in case
        // it doesnt raise the event asynchronously (It think it does but we are never too safe)
        this.apiEventListenerSubscription.push({
            event: Events.EVENT_COLUMN_RESIZED,
            func: this.columnResizedFunc,
        });
        // Listen for scroll in the body and raise our own internal event
        this.apiEventListenerSubscription.push({
            event: Events.EVENT_BODY_SCROLL,
            func: this.eventBodyScrollFunc,
        });

        // Listen for scroll in the body and raise our own internal event
        this.apiEventListenerSubscription.push({
            event: Events.EVENT_COLUMN_PINNED,
            func: this.eventColumnPinnedFunc,
        });
        // Listen for row group open and raise our own internal event
        this.apiEventListenerSubscription.push({
            event: Events.EVENT_ROW_GROUP_OPENED,
            func: this.rowGroupOpenedFunc,
        });
        // Listen for row group changed and raise our own internal event
        this.apiEventListenerSubscription.push({
            event: Events.EVENT_COLUMN_ROW_GROUP_CHANGED,
            func: this.columnRowGroupChangedFunc,
        });

        // Listen for dataupdated and raise our own internal event
        // we do not send the rownode or data yet as we need to decide if we leak the Rownode outside or need to map rownodes with a primarykey
        this.apiEventListenerSubscription.push({
            event: Events.EVENT_CELL_VALUE_CHANGED,
            func: this.cellCalueChangedFunc,
        });
        this.apiEventListenerSubscription.push({
            event: Events.EVENT_CELL_EDITING_STARTED,
            func: this.cellEditingStartedFunc,
        });

        this.apiEventListenerSubscription.push({
            event: Events.EVENT_CELL_EDITING_STOPPED,
            func: this.cellEditingStoppedFunc,
        });
        this.apiEventListenerSubscription.push({
            event: Events.EVENT_SELECTION_CHANGED,
            func: this.rowSelectionChangedFunc,
        });
        // we listen for ag-Grid changes in the sort definition
        this.apiEventListenerSubscription.push({
            event: Events.EVENT_SORT_CHANGED,
            func: this.sortChangedFunc,
        });

        // we want to know when the data has been rendered for the first time
        this.apiEventListenerSubscription.push({
            event: Events.EVENT_FIRST_DATA_RENDERED,
            func: this.firstDataRenderedFunc,
        });
        // We want to track which columns have a filter for the EnhancedQuickFilterWidget
        this.apiEventListenerSubscription.push({
            event: Events.EVENT_FILTER_CHANGED,
            func: this.filterChangedFunc,
        });
        this.apiEventListenerSubscription.forEach(subscription => {
            this.gridApi.addEventListener(subscription.event, subscription.func);
        });
    }

    public listenToGridColumnStateChanges(callback: () => void) {
        this.gridApi.addEventListener(Events.EVENT_DISPLAYED_COLUMNS_CHANGED, callback);

        this.apiEventListenerSubscription.push({
            event: Events.EVENT_DISPLAYED_COLUMNS_CHANGED,
            func: callback,
        });
    }

    private filterChangedFunc = () => {
        const filterModel = this.gridApi.getFilterModel() || {};
        const columnIdList = Object.keys(filterModel);
        this.getReduxStore().dispatch(gridSetColumnFilters(columnIdList));
    };

    private sortChangedFunc = () => {
        const sortModel = this.columnApi
            .getColumnState()
            .filter(col => col.sort)
            .map(col => ({ colId: col.colId || '', sort: col.sort || '' }));
        this.previousSortModel.forEach(x => {
            const absCS = this.getReduxStore()
                .getState()
                .customSort.configItemList.find(cs => cs.columnId === x.colId && cs.absoluteSort);
            const activeAbsoluteSort = this.getAbsoluteSort(x, absCS, this.gridOptions);
            if (activeAbsoluteSort) {
                this.getReduxStore().dispatch(
                    SetAbsoluteSortStatus(activeAbsoluteSort.customSort, activeAbsoluteSort.active)
                );
            }
        });
        this.previousSortModel = sortModel;
    };

    private buildEditorListenerKey(e: CellEditingStartedEvent | CellEditingStoppedEvent): string {
        return e.column.getColId() + e.rowIndex;
    }

    /**
     * Use a value to determine what the type is
     * @param rawValue The value of which to determine the type
     */
    private determineDataType(rawValue: any): DataType {
        if (rawValue instanceof Date) {
            return DataType.Date;
        }
        switch (typeof rawValue) {
            case 'string':
                return DataType.String;
            case 'number':
                return DataType.Number;
            case 'boolean':
                return DataType.Boolean;
            default:
                return DataType.Unknown;
        }
    }

    private getDisplayValueWithColumn(rowNode: IRowNode, column: Column): string {
        const rawValue = this.gridApi.getValue(column.getColId(), rowNode);
        if (rawValue === '') {
            return '[empty]';
        }
        for (const kv of FILTERABLE_SPECIAL_VALUES_MAP) {
            if (rawValue === kv.rawValue) {
                return kv.displayedValue;
            }
        }
        if (typeof rawValue !== 'string' && Number.isNaN(rawValue)) {
            const kv = FILTERABLE_SPECIAL_VALUES_MAP.find(kv => Number.isNaN(kv.rawValue));
            if (kv) {
                return kv.displayedValue;
            }
        }

        // if there is no formatted value we return the rawValue as a string
        return (
            this.valueFormatterService.formatValue(column, rowNode, rawValue) || String(rawValue)
        );
    }

    private getRowRenderer(): RowRenderer {
        const rowRenderer = (this.gridApi as any).rowRenderer as RowRenderer;
        if (!rowRenderer) {
            const msg = 'Cannot find the RowRenderer';
            logger.error(msg);
            throw new Error(msg);
        }
        return rowRenderer;
    }
    private getColumn(colId: string): Column | null {
        const allGridColumns = this.columnApi.getAllGridColumns();
        const primaryColumns = this.columnApi.getColumns();

        const column =
            (allGridColumns && this.getDisplayedColumn(allGridColumns, colId)) ||
            (primaryColumns && this.getDisplayedColumn(primaryColumns, colId));
        if (!column) {
            const msg = `Cannot find the column ${colId}`;
            logger.warn(msg);
            return null;
        }
        return column;
    }
    private refreshHeader() {
        if (!this.debouncedRefreshHeader) {
            return;
        }
        if (this.debouncedRefreshHeader) {
            this.debouncedRefreshHeader();
        }
    }

    private getDisplayedColumn(columns: Column[], columnId: string): Column | null {
        for (const column of columns) {
            const colId = column.getColId();
            if (colId === columnId) {
                return column;
            }
        }
        return null;
    }
    // this is to allow the tests to set the grid api
    public setGridApi(gridApi: GridApi) {
        this.gridApi = gridApi;
    }
}
