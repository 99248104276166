import { CSSProperties, ReactNode, HTMLAttributes, FC, memo } from 'react';
import PropTypes from 'prop-types';

import {
    LoadingOverlayProps as CommonLoadingOverlayProps,
    loadingOverlayDefaultProps,
    loadingOverlayStyleSheet,
    getLoadingOverlayClasses,
} from '@gs-ux-uitoolkit-common/loading';

import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';

export interface LoadingOverlayProps
    extends CommonLoadingOverlayProps<CSSProperties>,
        HTMLAttributes<HTMLElement> {
    children?: ReactNode;
}

/**
 * A LoadingOverlay is used to indicate a page or section
 * of a page is loading.
 */
export const LoadingOverlay: FC<LoadingOverlayProps> = memo(props => {
    const {
        fullScreen = loadingOverlayDefaultProps.fullScreen,
        visible = loadingOverlayDefaultProps.visible,
        children,
        className,
        classes: overrideClasses,
        ...attributes
    } = props;

    const theme = useTheme();
    const cssClasses = useStyleSheet(loadingOverlayStyleSheet, { theme, fullScreen, visible });
    const overlayClasses = getLoadingOverlayClasses({
        cssClasses,
        overrideClasses,
        className,
    });
    return (
        <div
            data-gs-uitk-component="loading-overlay"
            aria-busy={visible}
            className={overlayClasses}
            {...attributes}
        >
            {children}
        </div>
    );
});
LoadingOverlay.displayName = 'LoadingOverlay';

LoadingOverlay.propTypes = {
    className: PropTypes.string,
    fullScreen: PropTypes.bool,
    visible: PropTypes.bool,
};
