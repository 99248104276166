import { customIconFontFamily, materialSymbolsFontFamily } from './generated/fonts';
import { FontFace, injectFont } from '@gs-ux-uitoolkit-common/style';
import { getCdnBaseUrl } from '@gs-ux-uitoolkit-common/design-system';

export const iconFontFamilies = {
    materialSymbols: materialSymbolsFontFamily,
    custom: customIconFontFamily,
};

export const iconFontSizes = {
    sm: '16px',
    md: '20px',
    lg: '24px',
};

// The font weight specified in the font-face declaration and icon styles must
// match to ensure that the browser matches the font correctly.
// Sometimes an app will bring in a custom definition for 'Material Icons' and
// without an exact font weight match the UI Toolkit icon might use the other font.
export const iconFontWeight = 900;

// Expose v14-backward-compatible font face const. These are always hard-coded
// to use 'cdn.gs.com' and therefore can't be changed with setBaseCdnUrl().
// These const should be removed in v15 and developers should use the
// getFontFaces() function instead.
const fontFaces = getFontFaces();
export const materialDesignIconFonts = fontFaces;

/**
 * Retreives the font faces for icons.
 *
 * NOTE: this is a private function for now. If we expose this in the future,
 * we should probably give a better return structure that allows a particular
 * font face to be accessed without filtering the array. This function is
 * currently only exported for unit tests.
 */
export function getFontFaces(): FontFace[] {
    // Note: Must call getCdnBaseUrl() inside this function to retrieve the
    // latest base url for the CDN which could be set with the global var.
    const fontBaseUrl = `${getCdnBaseUrl()}/fonts/gs-ux-uitoolkit-icons/v7`;

    return [
        {
            family: iconFontFamilies['materialSymbols'],
            weight: 900,
            style: 'normal',
            src: [`${fontBaseUrl}/material-symbols-outlined/material-symbols-outlined.woff2`],
        },
        {
            family: iconFontFamilies['custom'],
            weight: 900,
            style: 'normal',
            src: [
                `${fontBaseUrl}/custom-icons/custom-icons.woff2`,
                `${fontBaseUrl}/custom-icons/custom-icons.woff`,
                // Comment above two lines and uncomment below two lines to test
                // out local changes to custom fonts after following instructions here:
                // https://gitlab.aws.site.gs.com/wf/mwp-ui/gs-ux-uitoolkit/-/wikis/Components/Iconography
                //`http://localhost:9000/CustomIcons.woff2`,
                //`http://localhost:9000/CustomIcons.woff`,
            ],
        },
    ];
}

/**
 * A variable to ensure the fonts are only injected once.
 */
let hasInjectedIconFonts = false;

/**
 * Injects the Font Faces for the UI Toolkit icon sets via CSS-in-JS.
 */
export function injectIconFonts() {
    if (!hasInjectedIconFonts) {
        // Note: must call getFontFaces() instead of using `materialDesignIconFonts`
        // const so that it takes into account the cdn url being set via
        // the global var.
        getFontFaces().forEach(fontFace => injectFont(fontFace));
        hasInjectedIconFonts = true;
    }
}
