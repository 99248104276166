import { Component, Context } from 'react';
import { connect, ReactReduxContextValue } from 'react-redux';
import { NewQuickFilter, WidgetContentCommonProps } from '@gs-ux-uitoolkit-common/datacore';
import { Dropdown, DropdownMenu, MenuDivider } from '@gs-ux-uitoolkit-react/input';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { Button } from '@gs-ux-uitoolkit-react/button';
import { SwitchChangeEvent, Switch } from '@gs-ux-uitoolkit-react/switch';
import { Heading, Text } from '@gs-ux-uitoolkit-react/text';
import { ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';
import { DataGridState } from '../../../..';
import { ButtonDisplay } from '../../../../components/buttons/button-base';
import { ButtonCreate } from '../../../../components/buttons/button-create';
import { ButtonEdit } from '../../../../components/buttons/button-edit';
import {
    GridToggleFloatingFilter,
    GridResetBasicExpressionColumnValueList,
} from '../../../../redux/actions/grid-action';
import { OpenGridConfiguration } from '../../../../redux/actions/grid-configuration-action';
import {
    getQuickFilterShortcutClassNames,
    quickfilterStyleSheet,
} from '../../../../style/plugin/quickfilter-plugin-stylesheet';
import { Plugins } from '../../../plugin-enum';

interface QuickFilterShortcutComponentReduxProps {
    toggleFloatingFilter: (value: boolean) => void;
    floatingFilterValue: boolean;
    openModalScreen: () => void;
    openCreateScreen: () => void;
}

export interface QuickFilterShortcutComponentProps
    extends QuickFilterShortcutComponentReduxProps,
        WidgetContentCommonProps {}

export interface QuickFilterShortcutState {
    dropdownIsOpen: boolean;
}

export class QuickFilterShortcutComponent extends Component<
    QuickFilterShortcutComponentProps,
    QuickFilterShortcutState
> {
    constructor(props: QuickFilterShortcutComponentProps) {
        super(props);
        this.state = {
            dropdownIsOpen: false,
        };
    }

    private setFloatingFilterValue = (e: SwitchChangeEvent) => {
        this.props.toggleFloatingFilter(e.toggledOn);
    };

    private openModal = () => {
        this.props.openModalScreen();
        this.setState({ dropdownIsOpen: false });
    };
    private openCreateScreen = () => {
        this.props.openCreateScreen();
        this.setState({ dropdownIsOpen: false });
    };

    private onBlur = () => this.setState({ dropdownIsOpen: false });

    private setDropDownState = () => {
        this.setState({
            dropdownIsOpen: !this.state.dropdownIsOpen,
        });
    };

    public componentWillUnmount() {
        quickfilterStyleSheet.unmount(this);
    }
    public render() {
        const size = this.props.widgetContentCommonProps?.size || 'sm';

        return (
            <ThemeConsumer>
                {theme => {
                    const cssClasses = quickfilterStyleSheet.mount(this, { theme });
                    return (
                        <div className="uitk-toolbar-floating-filter-widget">
                            <Dropdown
                                style={{ marginRight: '8px' }}
                                size={size}
                                menuVisible={this.state.dropdownIsOpen}
                            >
                                <Button
                                    size={size}
                                    emphasis="minimal"
                                    onClick={this.setDropDownState}
                                >
                                    <Icon name="filter-list" type="outlined"></Icon>
                                </Button>
                                <DropdownMenu
                                    className="widget-container"
                                    onBlur={this.onBlur}
                                    value=""
                                >
                                    <div
                                        className={` ${getQuickFilterShortcutClassNames({
                                            cssClasses,
                                        })}`}
                                    >
                                        <Switch
                                            toggledOn={this.props.floatingFilterValue}
                                            onChange={this.setFloatingFilterValue}
                                            name="toggle"
                                            size={size}
                                        >
                                            <Text typography="body03">Floating Column Filters</Text>
                                        </Switch>
                                        <MenuDivider />
                                        <Heading typography="heading07">Quick Filters</Heading>
                                        <span className={'button-span-group'}>
                                            <ButtonCreate
                                                displayType={ButtonDisplay.IconLabel}
                                                actionType={'primary'}
                                                label={'New'}
                                                onClick={this.openCreateScreen}
                                                size={size}
                                            />
                                            <ButtonEdit
                                                displayType={ButtonDisplay.IconLabel}
                                                onClick={this.openModal}
                                                size={size}
                                            />
                                        </span>
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    );
                }}
            </ThemeConsumer>
        );
    }
}

const mapStateToProps = (state: DataGridState) => {
    return {
        floatingFilterValue: state.grid.floatingFilter.enabled,
    };
};

const mapDispatchToProps = (dispatch: (action: any) => void) => {
    return {
        toggleFloatingFilter: (value: boolean) => dispatch(GridToggleFloatingFilter(value)),
        openModalScreen: () => dispatch(OpenGridConfiguration(Plugins.QuickFilterPlugin)),
        openCreateScreen: () => {
            dispatch(OpenGridConfiguration(Plugins.QuickFilterPlugin));
            dispatch(NewQuickFilter());
            dispatch(GridResetBasicExpressionColumnValueList());
        },
    };
};

export const getQuickFilterShortcutWidget = (context?: Context<ReactReduxContextValue>) =>
    connect(mapStateToProps, mapDispatchToProps, null, { context })(QuickFilterShortcutComponent);
