import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Input from '../../../../core/Input';
import Select from '../../../../core/Select/Simple';
import GuidelinesRadioGroup from './GuidelinesRadioGroup/index';
import {simpleRenderer} from './renderer';
import {Conditional} from '../../../../core/Conditional';
import {RULE_TYPE} from '../../../../../constants/preferences';

const Item = (props) => {
  const {ruleType, limitValue, currencyCode, tradeBehaviorList, title, description, isEnabled, testId, index} = props;
  const selectedOpt = { label:currencyCode, value: currencyCode};
  const {currencyList} = props;
  const itemTestId =`${testId}-item-${index}`;

  return (
    <li className="guideline-list__item" >
      <div>
        <span className="guideline-list__label" >{title}</span>
        <span className="guideline-list__description">{description}</span>
      </div>
      <div className='guideline-list__limit-value'>
        <Input
          type="text"
          name='limitValue'
          textAlignment='right'
          value={limitValue}
          isDisabled={!isEnabled}
          customClass='input-disabled'
          changeHandler={() => {/* TODO: code will be added in future */}}
        />
      </div>
      <Conditional condition={ruleType === RULE_TYPE.FUND_CONCENTRATION}>
        <div className='guideline-list__unit-currency'>
          <Select
            labelKey='label'
            optionRenderer={simpleRenderer}
            options={currencyList}
            valueKey='value'
            isActive={true}
            clickHandler={() => {/* TODO: code will be added in future */}}
            isDisabled={!isEnabled}
            hasWarning={false}
            hasError={false}
            selectedOption={selectedOpt}
            testId={`${itemTestId}-unit-currency`}
          />
        </div>
        <div  className={cn('guideline-list__unit-percent', {'guideline-list__unit-percent--disabled': !isEnabled})}>%</div>
      </Conditional>
      <div className='guideline-list__breach-action'>
        <GuidelinesRadioGroup
          onChange={() => { /* TODO: code will be added in future */ }}
          options={tradeBehaviorList}
        />
      </div>
    </li>
  );
};

Item.propTypes = {
  currencyList: PropTypes.array,
  ruleType: PropTypes.string,
  limitValue: PropTypes.number,
  currencyCode: PropTypes.string,
  tradeBehaviorList: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  isEnabled: PropTypes.bool,
  testId: PropTypes.string,
  index: PropTypes.number
};

export default Item;
