import { fontFamily, fontWeight, TypographyVariant } from '@gs-ux-uitoolkit-common/design-system';
import {
    StyleSheet,
    CssClasses,
    CssPropertyType,
    CssClassDefinitionsObject,
} from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { BadgeSize } from './badge-size';
import { BadgeProps, defaultBadgeProps } from './badge-props';
import { BadgeEmphasis } from './badge-emphasis';
import { BadgeColor } from './badge-color';
import { BadgeStatus } from './badge-status';
import { DeepReadonly } from 'ts-essentials';
import './badge-theme-overrides';

export interface BadgeStyleSheetProps {
    theme: Theme;
    color?: BadgeProps['color'];
    emphasis?: BadgeProps['emphasis'];
    shape?: BadgeProps['shape'];
    size?: BadgeProps['size'];
    status?: BadgeProps['status'];
}

export type BadgeCssClasses = CssClasses<typeof badgeStyleSheet>;

export type BadgeStyledClasses = CssClassDefinitionsObject<'root' | 'badge'>;

export interface BadgeStyleOverridesParams {
    props: DeepReadonly<BadgeStyleSheetProps>;
    createDefaultStyledClasses: () => BadgeStyledClasses;
}

/**
 * Because we need to populate data-* attributes in the component, the defaulting between status
 * and color will be done prior to this call.  Therefore, we will not call that function again here.
 * @see {@link determineDefaults}
 */
export const badgeStyleSheet = new StyleSheet('badge', (props: BadgeStyleSheetProps) => {
    return createComponentClassDefinitions<BadgeStyleSheetProps, BadgeStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.badge
    );
});

function createDefaultStyledClasses({
    theme,
    color,
    size = defaultBadgeProps.size,
    emphasis = defaultBadgeProps.emphasis,
    shape = defaultBadgeProps.shape,
    status,
}: BadgeStyleSheetProps): BadgeStyledClasses {
    const badgeSizeVariant = getBadgeSizeVariants(theme)[size];
    return {
        root: {
            display: 'inline-block',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            verticalAlign: 'baseline',
            textTransform: 'uppercase',
            borderRadius: shape === 'square' ? '2px' : badgeSizeVariant.rootRoundedBorderRadius,
            ...badgeSizeVariant.rootTypography,
            padding: badgeSizeVariant.emphasis[emphasis].rootPadding,
            border: emphasis === 'minimal' ? `1px solid ${theme.border.moderate}` : undefined,
            ...getBadgeColors({ theme, emphasis, color, status }),

            // Note: for size='sm', text is not vertically centered.  Workaround
            // with setting lineheight='-1px' and padding-top='+1px'
            lineHeight: size === 'sm' ? '15px' : badgeSizeVariant.rootTypography.lineHeight,
        },
        badge: {
            padding: badgeSizeVariant.emphasis[emphasis].overlayPadding,
        },
    };
}

function getBadgeColors(props: {
    theme: Theme;
    emphasis: BadgeEmphasis;
    color?: BadgeColor;
    status?: BadgeStatus;
}): { color?: CssPropertyType['color']; background?: CssPropertyType['background'] } {
    const { theme, color, emphasis, status } = props;

    const baseColor = status || color || 'gray';
    const colorShades = theme.getColorInteractionShades(baseColor, emphasis);

    return {
        color: colorShades.text,
        background: colorShades.background,
    };
}

// the typography for size 'xs' is not defined by the design-system
const xsTypography: TypographyVariant = {
    fontFamily: fontFamily.goldmanSans,
    fontWeight: fontWeight.medium,
    fontSize: '9px',
    lineHeight: '12px',
    letterSpacing: '0',
};

function getBadgeSizeVariants(theme: Theme): { [name in BadgeSize]: BadgeSizeVariant } {
    return {
        xs: {
            rootRoundedBorderRadius: '12px',
            rootTypography: xsTypography,

            emphasis: {
                bold: { rootPadding: '0 6px', overlayPadding: '0 4px' },
                subtle: { rootPadding: '0 6px', overlayPadding: '0 4px' },
                minimal: { rootPadding: '0 5px', overlayPadding: '0 3px' },
            },
        },
        sm: {
            rootRoundedBorderRadius: '16px',
            rootTypography: theme.typography.ui02,

            // Note: for size='sm', text is not vertically centered.
            // Workaround with setting lineheight='-1px' and padding-top='+1px'
            emphasis: {
                bold: { rootPadding: '1px 6px 0 6px', overlayPadding: '1px 4px 0 4px' },
                subtle: { rootPadding: '1px 6px 0 6px', overlayPadding: '1px 4px 0 4px' },
                minimal: { rootPadding: '1px 5px 0 5px', overlayPadding: '1px 3px 0 3px' },
            },
        },
        md: {
            rootRoundedBorderRadius: '20px',
            rootTypography: theme.typography.heading07,

            emphasis: {
                bold: { rootPadding: '2px 8px', overlayPadding: '2px 4px' },
                subtle: { rootPadding: '2px 8px', overlayPadding: '2px 4px' },
                minimal: { rootPadding: '1px 7px', overlayPadding: '1px 3px' },
            },
        },
        lg: {
            rootRoundedBorderRadius: '28px',
            rootTypography: theme.typography.heading06,

            emphasis: {
                bold: { rootPadding: '4px 10px', overlayPadding: '4px 6px' },
                subtle: { rootPadding: '4px 10px', overlayPadding: '4px 6px' },
                minimal: { rootPadding: '3px 9px', overlayPadding: '3px 5px' },
            },
        },
    };
}

interface BadgeSizeVariant {
    rootRoundedBorderRadius: CssPropertyType['marginBottom'];
    rootTypography: TypographyVariant;
    emphasis: { [emphasis in BadgeEmphasis]: BadgeEmphasisVariant };
}

interface BadgeEmphasisVariant {
    rootPadding: CssPropertyType['border'];
    overlayPadding: CssPropertyType['padding'];
}
