import { MenuValue } from './menu-props';

export type ValuesType = MenuValue | MenuValue[];

/**
 * Menu accepts input `value` as either MenuValue[] (for multiple) or
 * MenuValue (for single) but internally uses Set<MenuValue>. This function
 * converts a value in the accepted input type to the accepted internal type.
 */
export function getStateValues(value?: ValuesType | null): Set<MenuValue> {
    if (value != null) {
        if (Array.isArray(value)) {
            return new Set(value);
        } else if (typeof value === 'string' || !isNaN(value)) {
            return new Set([value]);
        }
    }
    return new Set();
}
