import { getLanguage } from './helper';
import { getDeviceType } from '../utils/deviceDetector';
import Constants from '../constants/appConstants';

export const setAnalyticsData = () => {
  const tag = document.querySelector('meta#domain');
  const content = tag && tag.getAttribute('content');
  const subDomain = content && content.split('-ui')[0] || '';
  const loginMethod = sessionStorage.getItem(Constants.MOSAIC_LOGIN_METHOD);
  return {
    'page': {
      'pageInfo': {
        'pageName': '',
        'sysEnv': getDeviceType(),
        'language': getLanguage(), // TODO should be taken from server
        'section': '',
        'subSection': '',
        'component': ''
      },
      'attributes': {
        'internalCampaign': 'New campaign'
      }
    },
    'user': {
      loginMethod,
      'status': 'Logged In',
      'currTime': Date.now(),
      'profile': {
        'userGuid': 'N/A', // <GUID>
        'isInternal': 'N/A', // <true/false>,
        'firmNo': 'N/A', // <firm#>,
        'branchNo': 'N/A', // <branch#>,
        'iMoneyNetAccess': 'N/A', // <true/false>,
        'clearWaterAccess': 'N/A', // <true/false>,
        'tradeAccess': 'N/A', // <true/false>,
        'tradeApprovalAccess': 'N/A', // <true/false>,
        'NSCCTradeAccess': 'N/A', // <true/false>,
        'jurisdiction': 'N/A' // <jurisdiction>
      }
    },
    'siteInfo': {
      'country': 'USA',
      'language': getLanguage(), // TODO should be taken from server,
      'referrerURL': document.referrer,
      'partnerSite': 'GLS',
      'sysEnv': document.body.getAttribute('data-sys-env') || 'DEV',
      'subDomain': subDomain
    }
  };
};
