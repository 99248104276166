import React, { useState, useEffect, useCallback, useMemo, memo, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import useDashBoard from '../../hooks/useDashBoard';
import useFirmToOrganizationMapping from '../../hooks/useFirmToOrganizationMapping';
import CardComponent from './Card';
import FirmOrganizationMappingModal from '../FirmOrganizationMappingModal';
import { UNMAPPED_FIRMS } from '../../constants';
import translator from '../../../../../services/translator';

const { translate: t } = translator;

const RowRenderer = memo(({ priority, displayValue: { firmName, firmCode } }) => {
  const title = t('tkUnmappedFirmAlertMessage', firmName, firmCode);
  return (
    <Fragment>
      <div className='dbcard__bodyRowText' title={title}>
        {title}
      </div>
      <span className={`dbcard__bodyRow${priority}Icon`} />
    </Fragment>
  );
});

RowRenderer.propTypes = {
  priority: PropTypes.string,
  displayValue: PropTypes.object,
};

const MaintenanceAlerts = ({ moduleName, reduxDispatch }) => {
  const defaultFilters = useMemo(
    () => [
      { name: 'type', selected: 'All Alerts' },
      { name: 'priority', selected: 'All Priorities' },
    ],
    []
  );
  const { loading, error, activeFilters, onFiltersUpdate, cardRows, reFetchDashBoardData } = useDashBoard(moduleName, defaultFilters);
  const [firmData, setFirmData] = useState(null);
  const { onMapFirmToOrganization, success } = useFirmToOrganizationMapping(reduxDispatch);

  useEffect(() => {
    if (success) {
      reFetchDashBoardData();
    }
  }, [success, reFetchDashBoardData]);

  const closeModal = useCallback(() => {
    setFirmData(null);
  }, []);

  const onRowClick = useCallback(({ type, displayValue: { firmName, firmCode, id } }) => {
    if (type === UNMAPPED_FIRMS) {
      setFirmData({ firmName, firmCode, id });
    }
  }, []);

  const sortedCardRows = useMemo(() => {
    return orderBy(
      cardRows,
      [
        row => row.displayValue?.modifiedAt?.split('T')[0],
        row => row.displayValue?.firmName,
        row => row.displayValue?.firmCode
      ],
      ['desc', 'asc', 'asc']
    );
  }, [cardRows]);

  return (
    <Fragment>
      <CardComponent
        title={t('tkMaintenanceAlerts')}
        onFiltersUpdate={onFiltersUpdate}
        activeFilters={activeFilters}
        loading={loading}
        error={error}
        cardRows={sortedCardRows}
        onRowClick={onRowClick}
        RowRenderer={RowRenderer}
      />
      {!!firmData && <FirmOrganizationMappingModal closeModal={closeModal} firmData={firmData} onMapFirmToOrganization={onMapFirmToOrganization} />}
    </Fragment>
  );
};

MaintenanceAlerts.propTypes = {
  moduleName: PropTypes.string,
  reduxDispatch: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  reduxDispatch: dispatch,
});

export default memo(connect(null, mapDispatchToProps)(MaintenanceAlerts));
