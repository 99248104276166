import { getCdnBaseUrl } from './cdn-base-url';
import { FontFace, injectFont } from '@gs-ux-uitoolkit-common/style';

/**
 * GSDS Font Families
 * We need to use separate families for different font weights and styles
 * as font-weight/style is not supported in IE11.
 */
export const fontFamily = {
    goldmanSans: 'Goldman Sans',
    /**
     * @deprecated Use `goldmanSans` and fontWeight instead
     */
    goldmanSansThin: 'Goldman Sans Thin',
    /**
     * @deprecated Use `goldmanSans` and fontWeight instead
     */
    goldmanSansLight: 'Goldman Sans Light',
    /**
     * @deprecated Use `goldmanSans` and fontWeight instead
     */
    goldmanSansItalic: 'Goldman Sans Italic',
    /**
     * @deprecated Use `goldmanSans` and fontWeight instead
     */
    goldmanSansMedium: 'Goldman Sans Medium',
    /**
     * @deprecated Use `goldmanSans` and fontWeight instead
     */
    goldmanSansMediumItalic: 'Goldman Sans Medium Italic',
    /**
     * @deprecated Use `goldmanSans` and fontWeight instead
     */
    goldmanSansBold: 'Goldman Sans Bold',
    /**
     * @deprecated Use `goldmanSans` and fontWeight instead
     */
    goldmanSansBoldItalic: 'Goldman Sans Bold Italic',
    /**
     * @deprecated Use `goldmanSans` and fontWeight instead
     */
    goldmanSansBlack: 'Goldman Sans Black',
    goldmanSansVariable: 'Goldman Sans Variable',
    goldmanSansVariableItalic: 'Goldman Sans Variable Italic',
    mono: 'Roboto Mono',
    robotoMonoItalic: 'Roboto Mono Italic',
    robotoMonoMedium: 'Roboto Mono Medium',
    robotoMonoMediumItalic: 'Roboto Mono Medium Italic',
    sabonRoman: 'Sabon',
    sabonItalic: 'Sabon Italic',
    sabonBold: 'Sabon Bold',
    sabonBoldItalic: 'Sabon Bold Italic',
    basis: 'Basis',
    basisItalic: 'Basis Italic',
    basisBold: 'Basis Bold',
    basisBoldItalic: 'Basis Bold Italic',
    basisLight: 'Basis Light',
    basisLightItalic: 'Basis Light Italic',
    basisMedium: 'Basis Medium',
    basisMediumItalic: 'Basis Medium Italic',
    basisOffWhite: 'Basis Off White',
    basisOffWhiteItalic: 'Basis Off White Italic',
    basisMonoRegular: 'Basis Mono Regular',
    basisMonoMedium: 'Basis Mono Medium',
};

/**
 * GSDS Font Weights
 */
export const fontWeight = {
    thin: 100,
    ultraLight: 200,
    light: 300,
    regular: 400,
    normal: 400,
    medium: 500,
    bold: 700,
    strong: 700,
    black: 900,
};

/**
 * A variable to ensure the fonts are only injected once.
 */
let hasInjectedIconFonts = false;

/**
 * CSS-in-JS method to inject the GS Design System @font-face rules.
 *
 * This function can be used to inject the GSDS @font-face rules manually on app startup. If using
 * a GSDS theme (light or dark), they are automatically injected into the DOM upon the theme being
 * consumed (i.e. the instantiation of any UI Toolkit component, or any custom component which uses
 * a GSDS theme).
 *
 * Alternatively, these @font-face rules can be added using SASS.
 */
export function injectGsdsFonts() {
    if (!hasInjectedIconFonts) {
        // Note: Must call getFontFaces() inside this function to retrieve the font
        // faces with the latest base url for the CDN that can be set with
        // the global var.
        getFontFaces().forEach(fontFace => injectFont(fontFace));

        hasInjectedIconFonts = true;
    }
}

/**
 * Retrieves the font faces for the design system.
 *
 */
export function getFontFaces(): FontFace[] {
    const { goldmanSans, robotoMono, sabon, basis, deprecatedGoldmanSans } = getFontFacesByFamily();

    return [...goldmanSans, ...robotoMono, ...sabon, ...basis, ...deprecatedGoldmanSans];
}

export function getFontFacesByFamily(): DesignSystemFontFaces {
    // Note: Must call getCdnBaseUrl() inside this function to retrieve the
    // latest base url for the CDN which could be set with the global var.
    const cdnBaseUrl = getCdnBaseUrl();
    const goldmanSansBaseUrl = `${cdnBaseUrl}/fonts/goldman-sans/v1`;
    const robotoMonoBaseUrl = `${cdnBaseUrl}/fonts/roboto-mono/v1`;
    const sabonBaseUrl = `${cdnBaseUrl}/fonts/sabon/v1`;
    const basisBaseUrl = `${cdnBaseUrl}/fonts/basis/v1`;

    const goldmanSans: FontFace[] = [
        {
            family: fontFamily.goldmanSans,
            weight: 100, // "thin"
            style: 'normal',
            src: [
                `${goldmanSansBaseUrl}/goldman-sans-thin.woff2`,
                `${goldmanSansBaseUrl}/goldman-sans-thin.woff`,
            ],
        },
        {
            family: fontFamily.goldmanSans,
            weight: 300, // "light"
            style: 'normal',
            src: [
                `${goldmanSansBaseUrl}/goldman-sans-light.woff2`,
                `${goldmanSansBaseUrl}/goldman-sans-light.woff`,
            ],
        },
        {
            family: fontFamily.goldmanSans,
            weight: 400, // "regular"
            style: 'normal',
            src: [
                `${goldmanSansBaseUrl}/goldman-sans-regular.woff2`,
                `${goldmanSansBaseUrl}/goldman-sans-regular.woff`,
            ],
        },
        {
            family: fontFamily.goldmanSans,
            weight: 400, // "regular"
            style: 'italic',
            src: [
                `${goldmanSansBaseUrl}/goldman-sans-regular-italic.woff2`,
                `${goldmanSansBaseUrl}/goldman-sans-regular-italic.woff`,
            ],
        },
        {
            family: fontFamily.goldmanSans,
            weight: 500, // "medium"
            style: 'normal',
            src: [
                `${goldmanSansBaseUrl}/goldman-sans-medium.woff2`,
                `${goldmanSansBaseUrl}/goldman-sans-medium.woff`,
            ],
        },
        {
            family: fontFamily.goldmanSans,
            weight: 500, // "medium"
            style: 'italic',
            src: [
                `${goldmanSansBaseUrl}/goldman-sans-medium-italic.woff2`,
                `${goldmanSansBaseUrl}/goldman-sans-medium-italic.woff`,
            ],
        },
        {
            family: fontFamily.goldmanSans,
            weight: 700, // "bold"
            style: 'normal',
            src: [
                `${goldmanSansBaseUrl}/goldman-sans-bold.woff2`,
                `${goldmanSansBaseUrl}/goldman-sans-bold.woff`,
            ],
        },
        {
            family: fontFamily.goldmanSans,
            weight: 700, // "bold"
            style: 'italic',
            src: [
                `${goldmanSansBaseUrl}/goldman-sans-bold-italic.woff2`,
                `${goldmanSansBaseUrl}/goldman-sans-bold-italic.woff`,
            ],
        },
        {
            family: fontFamily.goldmanSans,
            weight: 900, // "black"
            style: 'normal',
            src: [
                `${goldmanSansBaseUrl}/goldman-sans-black.woff2`,
                `${goldmanSansBaseUrl}/goldman-sans-black.woff`,
            ],
        },
        {
            family: fontFamily.goldmanSansVariable,
            weight: '1 999', // variable font weights are specified as a range
            style: 'normal',
            src: [`${goldmanSansBaseUrl}/goldman-sans-variable.woff2`],
        },
        {
            family: fontFamily.goldmanSansVariableItalic,
            weight: '1 999', // variable font weights are specified as a range
            style: 'normal',
            src: [`${goldmanSansBaseUrl}/goldman-sans-variable-italic.woff2`],
        },
    ];

    const robotoMono: FontFace[] = [
        {
            family: fontFamily.mono,
            weight: 400, // "regular"
            style: 'normal',
            src: [
                `${robotoMonoBaseUrl}/roboto-mono-regular.woff2`,
                `${robotoMonoBaseUrl}/roboto-mono-regular.woff`,
            ],
        },
        {
            family: fontFamily.robotoMonoItalic,
            weight: 400,
            style: 'normal',
            src: [
                `${robotoMonoBaseUrl}/roboto-mono-regular-italic.woff2`,
                `${robotoMonoBaseUrl}/roboto-mono-regular-italic.woff`,
            ],
        },
        {
            family: fontFamily.robotoMonoMedium,
            weight: 400,
            style: 'normal',
            src: [
                `${robotoMonoBaseUrl}/roboto-mono-medium.woff2`,
                `${robotoMonoBaseUrl}/roboto-mono-medium.woff`,
            ],
        },
        {
            family: fontFamily.robotoMonoMediumItalic,
            weight: 400,
            style: 'normal',
            src: [
                `${robotoMonoBaseUrl}/roboto-mono-medium-italic.woff2`,
                `${robotoMonoBaseUrl}/roboto-mono-medium-italic.woff`,
            ],
        },
    ];

    const sabon: FontFace[] = [
        {
            family: fontFamily.sabonRoman,
            weight: 400,
            style: 'normal',
            src: [`${sabonBaseUrl}/sabon.woff2`, `${sabonBaseUrl}/sabon.woff`],
        },
        {
            family: fontFamily.sabonItalic,
            weight: 400,
            style: 'normal',
            src: [`${sabonBaseUrl}/sabon-italic.woff2`, `${sabonBaseUrl}/sabon-italic.woff`],
        },
        {
            family: fontFamily.sabonBold,
            weight: 400,
            style: 'normal',
            src: [`${sabonBaseUrl}/sabon-bold.woff2`, `${sabonBaseUrl}/sabon-bold.woff`],
        },
        {
            family: fontFamily.sabonBoldItalic,
            weight: 400,
            style: 'normal',
            src: [
                `${sabonBaseUrl}/sabon-bold-italic.woff2`,
                `${sabonBaseUrl}/sabon-bold-italic.woff`,
            ],
        },
    ];

    const basis: FontFace[] = [
        {
            family: fontFamily.basis,
            weight: 400,
            style: 'normal',
            src: [`${basisBaseUrl}/basis-regular.woff2`, `${basisBaseUrl}/basis-regular.woff`],
        },
        {
            family: fontFamily.basisItalic,
            weight: 400,
            style: 'normal',
            src: [`${basisBaseUrl}/basis-italic.woff2`, `${basisBaseUrl}/basis-italic.woff`],
        },
        {
            family: fontFamily.basisBold,
            weight: 400,
            style: 'normal',
            src: [`${basisBaseUrl}/basis-bold.woff2`, `${basisBaseUrl}/basis-bold.woff`],
        },
        {
            family: fontFamily.basisBoldItalic,
            weight: 400,
            style: 'normal',
            src: [
                `${basisBaseUrl}/basis-bold-italic.woff2`,
                `${basisBaseUrl}/basis-bold-italic.woff`,
            ],
        },
        {
            family: fontFamily.basisLight,
            weight: 400,
            style: 'normal',
            src: [`${basisBaseUrl}/basis-light.woff2`, `${basisBaseUrl}/basis-light.woff`],
        },
        {
            family: fontFamily.basisLightItalic,
            weight: 400,
            style: 'normal',
            src: [
                `${basisBaseUrl}/basis-light-italic.woff2`,
                `${basisBaseUrl}/basis-light-italic.woff`,
            ],
        },
        {
            family: fontFamily.basisMedium,
            weight: 400,
            style: 'normal',
            src: [`${basisBaseUrl}/basis-medium.woff2`, `${basisBaseUrl}/basis-medium.woff`],
        },
        {
            family: fontFamily.basisMediumItalic,
            weight: 400,
            style: 'normal',
            src: [
                `${basisBaseUrl}/basis-medium-italic.woff2`,
                `${basisBaseUrl}/basis-medium-italic.woff`,
            ],
        },
        {
            family: fontFamily.basisOffWhite,
            weight: 400,
            style: 'normal',
            src: [`${basisBaseUrl}/basis-off-white.woff2`, `${basisBaseUrl}/basis-off-white.woff`],
        },
        {
            family: fontFamily.basisOffWhiteItalic,
            weight: 400,
            style: 'normal',
            src: [
                `${basisBaseUrl}/basis-off-white-italic.woff2`,
                `${basisBaseUrl}/basis-off-white-italic.woff`,
            ],
        },
        {
            family: fontFamily.basisMonoRegular,
            weight: 400,
            style: 'normal',
            src: [
                `${basisBaseUrl}/basis-mono-regular.woff2`,
                `${basisBaseUrl}/basis-mono-regular.woff`,
            ],
        },
        {
            family: fontFamily.basisMonoMedium,
            weight: 400,
            style: 'normal',
            src: [
                `${basisBaseUrl}/basis-mono-medium.woff2`,
                `${basisBaseUrl}/basis-mono-medium.woff`,
            ],
        },
    ];

    const deprecatedGoldmanSans: FontFace[] = [
        {
            family: fontFamily.goldmanSansThin,
            weight: 100, // "thin"
            style: 'normal',
            src: [
                `${goldmanSansBaseUrl}/goldman-sans-thin.woff2`,
                `${goldmanSansBaseUrl}/goldman-sans-thin.woff`,
            ],
        },
        {
            family: fontFamily.goldmanSansLight,
            weight: 300, // "light"
            style: 'normal',
            src: [
                `${goldmanSansBaseUrl}/goldman-sans-light.woff2`,
                `${goldmanSansBaseUrl}/goldman-sans-light.woff`,
            ],
        },
        {
            family: fontFamily.goldmanSans,
            weight: 400, // "regular"
            style: 'normal',
            src: [
                `${goldmanSansBaseUrl}/goldman-sans-regular.woff2`,
                `${goldmanSansBaseUrl}/goldman-sans-regular.woff`,
            ],
        },
        {
            family: fontFamily.goldmanSansItalic,
            weight: 400, // "regular"
            style: 'italic',
            src: [
                `${goldmanSansBaseUrl}/goldman-sans-regular-italic.woff2`,
                `${goldmanSansBaseUrl}/goldman-sans-regular-italic.woff`,
            ],
        },
        {
            family: fontFamily.goldmanSansMedium,
            weight: 500, // "medium"
            style: 'normal',
            src: [
                `${goldmanSansBaseUrl}/goldman-sans-medium.woff2`,
                `${goldmanSansBaseUrl}/goldman-sans-medium.woff`,
            ],
        },
        {
            family: fontFamily.goldmanSansMediumItalic,
            weight: 500, // "medium"
            style: 'italic',
            src: [
                `${goldmanSansBaseUrl}/goldman-sans-medium-italic.woff2`,
                `${goldmanSansBaseUrl}/goldman-sans-medium-italic.woff`,
            ],
        },
        {
            family: fontFamily.goldmanSansBold,
            weight: 700, // "bold"
            style: 'normal',
            src: [
                `${goldmanSansBaseUrl}/goldman-sans-bold.woff2`,
                `${goldmanSansBaseUrl}/goldman-sans-bold.woff`,
            ],
        },
        {
            family: fontFamily.goldmanSansBoldItalic,
            weight: 700, // "bold"
            style: 'italic',
            src: [
                `${goldmanSansBaseUrl}/goldman-sans-bold-italic.woff2`,
                `${goldmanSansBaseUrl}/goldman-sans-bold-italic.woff`,
            ],
        },
        {
            family: fontFamily.goldmanSansBlack,
            weight: 900, // "black"
            style: 'normal',
            src: [
                `${goldmanSansBaseUrl}/goldman-sans-black.woff2`,
                `${goldmanSansBaseUrl}/goldman-sans-black.woff`,
            ],
        },
        {
            family: fontFamily.goldmanSansVariable,
            weight: '1 999', // variable font weights are specified as a range
            style: 'normal',
            src: [`${goldmanSansBaseUrl}/goldman-sans-variable.woff2`],
        },
        {
            family: fontFamily.goldmanSansVariableItalic,
            weight: '1 999', // variable font weights are specified as a range
            style: 'normal',
            src: [`${goldmanSansBaseUrl}/goldman-sans-variable-italic.woff2`],
        },
    ];

    return { goldmanSans, deprecatedGoldmanSans, robotoMono, sabon, basis };
}

interface DesignSystemFontFaces {
    goldmanSans: FontFace[];
    deprecatedGoldmanSans: FontFace[];
    robotoMono: FontFace[];
    sabon: FontFace[];
    basis: FontFace[];
}
