export default {
  SUBMIT_CREDENTIALS: 'SUBMIT_CREDENTIALS',
  CREATE_NEW_PASSWORD: 'CREATE_NEW_PASSWORD',
  CREATE_NEW_PIN: 'CREATE_NEW_PIN',
  SUBMIT_FPW_UID: 'SUBMIT_FPW_UID',
  SUBMIT_FPW_OTP: 'SUBMIT_FPW_OTP',
  IS_LOGIN_IN_PROGRESS: 'IS_LOGIN_IN_PROGRESS',
  IS_MFA_FLOW_IN_PROGRESS: 'IS_MFA_FLOW_IN_PROGRESS',
  IS_USER_LOGGED_IN: 'IS_USER_LOGGED_IN',
  IS_SHOW_MOSAIC_HELP: 'IS_SHOW_MOSAIC_HELP',
  UPDATE_TARGET_URL: 'UPDATE_TARGET_URL'
};
