import { arrayHelper, PluginBase, PluginIcon } from '@gs-ux-uitoolkit-common/datacore';
import * as Redux from 'redux';
import { agGridAutoColumn, GridColumn, GridWrapper } from '../../grid-wrappers/grid-wrapper';

/**
 * The base class of the Plugins that listen to column definition changes and auto-refresh
 */

export abstract class ColumnRefreshPluginBase<
    U extends GridWrapper,
    V,
    T = object,
> extends PluginBase<U, V, T> {
    private columnDefinitions: GridColumn[] | null = null;
    private columnStoreSubscription: Redux.Unsubscribe | null = null;
    private forceRefresh = false;
    constructor(
        pluginName: string,
        category: string,
        icon: PluginIcon,
        wrapper: U,
        stateNeeded: (state: V) => T
    ) {
        super(pluginName, category, icon, wrapper, stateNeeded);
    }

    public start() {
        super.start();
        const reduxStore = this.wrapper.getReduxStore();
        this.columnDefinitions = reduxStore.getState().grid.columnList;
        this.columnStoreSubscription = reduxStore.subscribe(() =>
            this.hasColumnDefinitionsChanged()
        );
    }

    public shouldForceRefresh(): boolean {
        return this.forceRefresh;
    }

    public setRefreshComplete(): void {
        this.forceRefresh = false;
    }

    protected internalStop(): void {
        if (this.columnStoreSubscription) {
            this.columnStoreSubscription();
        }
    }

    private hasColumnDefinitionsChanged(): void {
        const reduxStore = this.wrapper.getReduxStore();

        /// has the column definitions have been updated
        if (
            this.columnDefinitions &&
            this.columnDefinitions !== reduxStore.getState().grid.columnList
        ) {
            const previousColIdList = this.columnDefinitions
                .map(col => col.columnId)
                .filter(col => col !== agGridAutoColumn);
            this.columnDefinitions = reduxStore.getState().grid.columnList;
            const currentColIdList = this.columnDefinitions
                .map(col => col.columnId)
                .filter(col => col !== agGridAutoColumn);

            // is it a change that require a column hint refresh
            // i.e. new column as opposed to a show/hide column
            if (!arrayHelper.areIdentical(previousColIdList.sort(), currentColIdList.sort())) {
                // we have to force refresh as the list of column hints
                // hasn't changed but we want them to be applied again
                this.forceRefresh = true;
                this.stateChangedOrStart();
            }
        }
    }
}
