const GLOSSARY_DEFINITION_VIEWED_EVENT = 'Glossary_definition_viewed';
export const READ_MORE_LINK_EVENT = 'read_more_link';
export const glossaryDefinitionViewedAnalyticsData = (articleName) => {
  return {
    event: {
      eventName: GLOSSARY_DEFINITION_VIEWED_EVENT,
      valueChosen: articleName
    }
  };
};
