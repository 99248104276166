import { DataType, ExpressionValue, stringHelper } from '@gs-ux-uitoolkit-common/datacore';
import { DateRangeControl } from './date-range-control';
import { NumberRangeControl } from './number-range-control';
import { StringRangeControl } from './string-range-control';
import { GetFormattedValueFromColumn } from '../../../../grid-wrappers/grid-wrapper';
import { InputSize } from '@gs-ux-uitoolkit-react/input';

export interface RangeInputControlValues {
    value: ExpressionValue;
    formattedValue: ExpressionValue;
}

export interface RangeInputControlProps {
    value?: string;
    formattedValue?: string | undefined;
    defaultValue?: string;
    dataType: DataType;
    callback: (values: RangeInputControlValues) => void;
    callbackOnlyOnBlur?: boolean;
    disabled?: boolean;
    popupMode?: boolean;
    getFormattedValueFromColumn: GetFormattedValueFromColumn;
    selectedColumnId: string;
    className?: string;
    size?: InputSize;
}

export const RangeInputControl = (props: RangeInputControlProps) => {
    // Remove props that we don't want to pass down
    const {
        dataType,
        getFormattedValueFromColumn,
        selectedColumnId,
        formattedValue,
        popupMode,
        ...inputProps
    } = props;

    const getFormattedValue = (value: string | number | Date) => {
        let formattedValue: string = value instanceof Date ? value.toString() : String(value);
        formattedValue = props.getFormattedValueFromColumn(props.selectedColumnId, formattedValue);
        return formattedValue;
    };
    // ToISOString Only does not factor in BST timezone. We correct this by adding the timezone to the offset
    const dateToISoString = (date: Date) => {
        const tzoffset = date.getTimezoneOffset() * 60; //offset in seconds
        date.setSeconds(date.getSeconds() - tzoffset);
        return date.toISOString();
    };

    switch (props.dataType) {
        case DataType.Number:
            return (
                <NumberRangeControl
                    {...inputProps}
                    callbackOnlyOnBlur={false}
                    callback={event => {
                        if (stringHelper.isNullOrEmpty(event.currentTarget.value)) {
                            props.callback({
                                value: '',
                                formattedValue: '',
                            });
                        } else {
                            const numberValue = Number(event.currentTarget.value);
                            props.callback({
                                value: numberValue,
                                formattedValue: getFormattedValue(numberValue),
                            });
                        }
                    }}
                />
            );
        case DataType.Date:
            return (
                <DateRangeControl
                    {...inputProps}
                    callback={date => {
                        const dateValue = date ? dateToISoString(date) : '';
                        props.callback({
                            formattedValue: date ? getFormattedValue(dateValue) : '',
                            value: date ? dateValue : '',
                        });
                    }}
                    callbackOnClose={false}
                    value={props.defaultValue || props.value}
                    formattedValue={props.formattedValue}
                    popupMode={props.popupMode}
                    enableTime={false}
                />
            );
        case DataType.DateTime:
            return (
                <DateRangeControl
                    {...inputProps}
                    callback={date => {
                        const dateValue = date ? dateToISoString(date) : '';
                        props.callback({
                            formattedValue: date ? getFormattedValue(dateValue) : '',
                            value: date ? dateValue : '',
                        });
                    }}
                    callbackOnClose={false}
                    value={props.defaultValue || props.value}
                    popupMode={props.popupMode}
                    enableTime={true}
                    formattedValue={props.formattedValue}
                />
            );
        default:
            return (
                <StringRangeControl
                    {...inputProps}
                    callbackOnlyOnBlur={false}
                    callback={event => {
                        props.callback({
                            value: event.target.value,
                            formattedValue: getFormattedValue(event.target.value),
                        });
                    }}
                />
            );
    }
};
