import { Input } from '@gs-ux-uitoolkit-react/input';
import { ChangeEvent } from 'react';
import { debounce } from 'gs-uitk-lodash';
import { defaultDebounceTime } from '../../../../grid-wrappers/grid-wrapper';
export interface StringRangeControlProps {
    value?: string;
    defaultValue?: string;
    callback: (event: ChangeEvent<HTMLInputElement>) => void;
    callbackOnlyOnBlur?: boolean;
    disabled?: boolean;
    className?: string;
}
export const StringRangeControl = (props: StringRangeControlProps) => {
    // Remove props that we don't want to pass down
    const { callback, callbackOnlyOnBlur, ...inputProps } = props;

    const debouncedSaved = debounce((event: ChangeEvent<HTMLInputElement>) => {
        props.callback(event);
    }, defaultDebounceTime);
    return (
        <Input
            placeholder="Enter text"
            {...inputProps}
            className={`expression-range-input-value ${props.className || ''}`}
            data-cy={`${props.className}-string-input`}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                !props.callbackOnlyOnBlur ? debouncedSaved(event) : null
            }
            onBlur={(event: ChangeEvent<HTMLInputElement>) => props.callback(event)}
        />
    );
};
