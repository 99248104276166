import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Link from '../Link';
import ExternalLinkModal from './ExternalLinkModal';
import { EXTERNAL_LINK_SKIP_ATTRIBUTE } from '../../../constants/pageConstants';

const ExternalLink = (WrappedComponent) => {
  class ExternalLinkComp extends Component {
    constructor(props) {
      super(props);

      this.state={
        isModalOpen: false
      };

      this.handleNavigation = this.handleNavigation.bind(this);
      this.handleUserPreferenceOnExtNav = this.handleUserPreferenceOnExtNav.bind(this);
      this.handleClose = this.handleClose.bind(this);
    }

    handleClose(){
      this.setState({isModalOpen: false})
    }

    handleNavigation(event) {
      event.preventDefault();

      const {investmentManager, labels, handleClick} = this.props;
      const url = event.target.href;

      /* TODO: to enable once we have the allowed domains */
      // let domainName = null;
      // const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
      // if (match !== null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
      //   [ , , domainName] = match;
      // }

      // allowedDomains.forEach((allowedDomain) => {
      //   const pattern = new RegExp(`${allowedDomain}$`);
      //   if(pattern.test(domainName)){
      //     isExternalURL = false;
      //   }
      // });

      let isExternalURL = true;
      /* TODO: If link comes from a fund, check for investment manager, for now
      /* TODO: only checking for GS since we do not have other domains */
      if(investmentManager && labels){
        const investmentManagerArray = labels.investmentManagers.split(',');
        if(investmentManagerArray.includes(investmentManager)){
          isExternalURL = false;
        }
      }

      if (isExternalURL) {
        this.setState({isModalOpen: true});
      }else{
        window.open(url, this.props.target);
      }

      handleClick && handleClick();
    }

    handleUserPreferenceOnExtNav(event, allowExternalNavigation){
      const {href, target} = this.props;
      if(allowExternalNavigation){
        window.open(href, target);
      }
      this.setState({isModalOpen: false});
    }

    render(){
      const {isModalOpen} = this.state;
      const {allowedDomains, labels, ...rest} = this.props;
      /* TODO: Change this once we have whitelabel */
      return(
        <Fragment>
          <WrappedComponent {...rest} handleClick={this.handleNavigation} extraProps={{[EXTERNAL_LINK_SKIP_ATTRIBUTE]:true}} />
          <ExternalLinkModal isModalOpen={isModalOpen} handleClose={this.handleClose} labels={labels} handleUserPreferenceOnExtNav={this.handleUserPreferenceOnExtNav} />
        </Fragment>
      );
    }
  }
  ExternalLinkComp.propTypes = {
    href: PropTypes.string.isRequired,
    target: PropTypes.string,
    labels: PropTypes.object.isRequired,
    investmentManager: PropTypes.string,
    allowedDomains: PropTypes.array,
    handleClick: PropTypes.func
  };
  ExternalLinkComp.defaultProps = {
    target: '_blank'
  };
  return ExternalLinkComp;
};

export default ExternalLink(Link);
