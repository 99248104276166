import { FC, Children, memo, ReactElement } from 'react';
import PropTypes from 'prop-types';
import { TabContent, TabContentProps } from 'reactstrap';
import {
    tabsStyleSheet,
    getTabsContentClasses,
    TabsCommonProps,
    TabsSize,
    TabCommonProps,
} from '@gs-ux-uitoolkit-common/tabs';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { extractAriaAttributes } from '@gs-ux-uitoolkit-react/shared';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { omit } from 'gs-uitk-lodash';
import { isDropdownTab, isValidTabsChild } from './tab-utils';

export interface TabPanesProps extends TabContentProps {
    activeTabKey?: string | number;
    size: TabsSize;
    classes?: TabsCommonProps['classes'];
    /**
     * Leading icon of the tab. This is required to prevent a warning thrown as this is currently
     * being applied in the output HTML
     */
    leadingIcon?: TabCommonProps['leadingIcon'];
}

export const TabPanes: FC<TabPanesProps> = memo(props => {
    const { size, activeTabKey, classes, children, ...rest } = props;

    const _children: ReactElement[] = Children.toArray(children).map(child => {
        const props = (child as ReactElement).props;
        const ariaAttrs = extractAriaAttributes(props);
        const newProps = omit(props, [...Object.keys(ariaAttrs), 'leadingIcon']);
        return {
            ...(child as ReactElement),
            props: newProps,
        };
    });

    const theme = useTheme();
    const cssClasses = useStyleSheet(tabsStyleSheet, { size, theme });

    return (
        <TabContent
            {...rest}
            activeTab={activeTabKey}
            className={getTabsContentClasses({ cssClasses, overrideClasses: classes })}
            tabIndex={activeTabKey ? 0 : -1}
        >
            {createTabPanes(_children)}
        </TabContent>
    );
});
TabPanes.displayName = 'TabPanes';

TabPanes.propTypes = {
    activeTabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    size: PropTypes.oneOf(['md', 'lg']).isRequired,
    classes: PropTypes.object,
    children: PropTypes.node.isRequired,
} as { [key: string]: any };

function createTabPanes(nodes: ReactElement[]) {
    const children = Children.toArray(nodes);
    return flattenTabPanes(children.filter(isValidTabsChild));
}

/**
 *  <DropdownTab>
 *      <Tab />
 *  <DropdownTab>
 *  <Tab />
 *
 *  flattened to...
 *
 *  <Tab />
 *  <Tab />
 */
function flattenTabPanes(nestedTabPanes: ReactElement[]): ReactElement[] {
    return nestedTabPanes.reduce(
        (a: ReactElement[], b: ReactElement) =>
            a.concat(isDropdownTab(b) ? flattenTabPanes(b.props.children as ReactElement[]) : b),
        []
    );
}
