import map from 'lodash/map';
import {sortChange,  columnWidthChanged,  columnRemove, columnMoved, applyFilter } from '../../../actions/page/fundFinder';
import {addPageContext} from '../../../actions/page/index';
import {dispatchAnalytics, getColumnSortingAnalyticsData, getColumnSearchAnalyticsData,
  getGridEndLoadAnalyticsData, getColumnRearrangeAnalyticsData,
  getColumnRemoveAnalyticsData, getGridEndLoadOnSearchAnalyticsData} from '../analytics/index';
import {
  getNewOrderedColumns,
  getNewVisibleColumns,
  getNewWidthedColumns,
  getSortParams
} from '../../../helpers/gridFund';
import {addToTradeBlotter, removeFromTradeBlotter} from '../../../actions/sagas';

const mapDispatchToProps = (dispatch) => ({
  dispatchSortChange: (colId, columnName, order, props) => {
    dispatchAnalytics(dispatch, getColumnSortingAnalyticsData(order, columnName));
    const params = getSortParams(colId, order, props);
    return dispatch(sortChange(params));
  },
  dispatchCellClick: (event) => {
    if (event.colDef.colId === 'fundName' && event.data) {
      if(event.data.productLink){
        window.open(event.data.productLink, '_blank');
      } else { // Set active FundFact in order to open fund fact card model
        event.data.factCardType = "Fund";
        dispatch(addPageContext({activeFundFact: event.data}));
      }
    }
  },
  dispatchPageChange: data => dispatch(sortChange(data)),
  // dispatchRowSelection: (rowSelected, data) => {
  // console.log(rowSelected, data);
  // dispatch trade blotter action here.
  // },

  dispatchColumnMoved: (columns, params, props) => {
    const data = getNewOrderedColumns(columns, props.currentView);
    dispatch(columnMoved(data));
    dispatchAnalytics(dispatch, getColumnRearrangeAnalyticsData(params.column.colDef.headerName));
  },

  dispatchFilterApplied: (filters, totalRecords, activeColumns, selectedView, selectedManagers) => {
    dispatchAnalytics(dispatch, getGridEndLoadOnSearchAnalyticsData(filters, totalRecords, activeColumns, selectedView, selectedManagers));
  },

  dispatchFilterChange: (filterModel, filteredDataLength) => {
    const filters = map(filterModel, (obj, key) => ({field: key, term: obj.filter}));
    dispatch(applyFilter({filters, filterModel, filteredDataLength}));
  },

  dispatchFilterFocus: data => {
    dispatchAnalytics(dispatch, getColumnSearchAnalyticsData(data.colDef.headerName));
  },
  dispatchGroupExpandCollapseChange: data => dispatch(sortChange(data)),
  dispatchLoadEnd: (event, totalRecords, columns, selectedView, selectedManagers, time, snackbarList) => {
    dispatchAnalytics(dispatch, getGridEndLoadAnalyticsData(event, totalRecords, columns, selectedView, selectedManagers, time, snackbarList));
  },

  dispatchColumnWidthChanged: (columns, props) => {
    const {currentView} = props;
    const updatedView = getNewWidthedColumns(columns, currentView);
    dispatch(columnWidthChanged(updatedView));
  },
  dispatchColumnRemove: (colId, visibility, props) => {
    const {currentView, columnDefs} = props;
    // const column = currentView.columns.find(column => column.colId === colId);
    const updatedView = getNewVisibleColumns(colId, currentView);
    dispatch(columnRemove(updatedView));
    const updatedColumnDefs = columnDefs.filter(column => column.colId !== colId);
    dispatchAnalytics(dispatch, getColumnRemoveAnalyticsData(updatedColumnDefs, colId, updatedView.name));
  },
  dispatchAddTrade: data => dispatch(addToTradeBlotter(data)),
  dispatchRemoveTrade: data => dispatch(removeFromTradeBlotter(data))
});

export default mapDispatchToProps;
