import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Conditional } from "../../../../../core/Conditional";
import ItemEdit from "../ItemEdit"
import "./index.scss";

export const formatPaymentDate = (paymentDate, labels) => {
  if (paymentDate) {
    return labels.tkPaymentDatePrefix + paymentDate + labels.tkPaymentDateSuffix
  }
};

const Item = ({
  item,
  labels,
  fundRebateInfo,
  isEdit,
  handleEditFields,
  errorState
}) => {
  const {
    field,
    headerName,
    category,
    fieldType
  } = item;

  const fieldContent = fundRebateInfo ? fundRebateInfo[field] : null;

  const getFieldContent = () => {
    return isEdit ?
      fundRebateInfo ? fundRebateInfo[field] : null
      : fieldContent;
  };

  const renderFieldContent = () => {
    if (!getFieldContent()) {
      return labels.tkNullDataPlaceholder;
    }
    if (field === "paymentDate") {
        return formatPaymentDate(getFieldContent(), labels);
    }
    return getFieldContent();
  };

  return (
    <Conditional condition={headerName !== "tkFundRebate" && category === "tkFundRebate"}>
      <div className="factData">
        <span className={classNames("fundFactView__label", {"edit-label": isEdit})}>
          {headerName && labels[headerName]}
        </span>
        <Conditional condition={isEdit}>
          <ItemEdit
            value={getFieldContent()}
            handleEditFields={handleEditFields}
            field={field}
            fieldType={fieldType}
            errorState={errorState}
            labels={labels}
          />
          <span className="factData-item">{renderFieldContent()}</span>;
        </Conditional>
      </div>
    </Conditional>
  );
};

Item.propTypes = {
  item: PropTypes.object,
  labels: PropTypes.object,
  fundRebateInfo: PropTypes.object,
  isEdit: PropTypes.bool,
  handleEditFields: PropTypes.func,
  errorState: PropTypes.number,
};

export default Item;
