import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {useChartContext} from '../../../context';
import translator from '../../../../../services/translator';
import {fundIdentifierPreference} from "../../../../../selectors/preferences";
import {getFundDisplay} from '../../../utils'

const {translate: t} = translator;

const Heading = (props) => {
  const {state: {shareclass} = {}} = useChartContext();
  if (!shareclass || !(shareclass.shareclass)) {
    return null;
  }
  const { fundIdentifier } = props;
  const fundDisplay = getFundDisplay(shareclass, fundIdentifier);
  const {
    shareclass: {
      longName
    }
  } = shareclass;
  const fundName = (fundDisplay !== '' && fundDisplay !== undefined) ? `${longName} (${fundDisplay})` : longName;
  const heading = `${t('tkCompare')} ${fundName}`;

  return (
    <div className="compare-modal__heading">
      {heading}
    </div>
  );
};

const mapStateToProps = (state) => ({
  fundIdentifier: fundIdentifierPreference(state)
});

Heading.propTypes = {
  fundIdentifier: PropTypes.string
};

export default withRouter(connect(mapStateToProps)(Heading));

