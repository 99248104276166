import {diffArrays} from '..';

class ChartColors {
  constructor() {
    this.colors = ['#0d4ea6', '#e86427', '#279c9c', '#d9a514', '#ac71f0', '#23254d', '#52a31d', '#cc1d92', '#48b8f0', '#077d55', '#7a909e', '#bf4815', '#54341f', '#4ebfb9', '#067a91', '#545fe8', '#102e2d', '#2e5c0e'];
    this.usedColors = [];
  }

  getSingleChartColor = () => {
    const remainingColors = diffArrays(this.colors, this.usedColors, (a, b) => a === b);
    if (remainingColors.length === 0) {
      return this.usedColors[0];
    }
    return remainingColors[0];
  };

  setUsedColor = (color) => {
    if (this.usedColors.indexOf(color) !== -1) {
      return;
    }
    this.usedColors = [...this.usedColors, color];
  };

  removeUsedColor = (color) => {
    this.usedColors = this.usedColors.filter(usedColor => usedColor !== color);
  };

  removedAllColors = () => {
    this.usedColors = [];
  }
}

const ChartColorsInstance = new ChartColors();
export default ChartColorsInstance;
