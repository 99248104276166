import React from 'react';
import PropTypes from 'prop-types';
import RadioButton from '../../core/RadioButton';
const RadioGroup = ({options, onChange, testId=''}) => (
  <div className='radio-group' data-testid={testId}>
    {
      options.map((data, index) => (
        <RadioButton
          id={data.value}
          changeHandler={onChange}
          label={data.label}
          value={data.value}
          isDisabled={data.disabled}
          key={index}
          isChecked={data.isSelected}
          /> ))
      }
  </div>
);

RadioGroup.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  testId: PropTypes.string
};

export default RadioGroup;
