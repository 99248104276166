import React from 'react';
import PropTypes from 'prop-types';
import translator from '../../../../../../services/translator';

const { translate: t } = translator;

const ExpandCollapseAll = props => {
  const { gridRef, showAccountSidePanel } = props;

  // Expands all the nodes in the tree
  const onExpandAll = () => {
    gridRef.api.expandAll();
    gridRef.api.ensureIndexVisible(0, 'top');
  };

  // Collapses all the nodes, if sidepanel on any node selection those nodes and its children won't be collapsed
  const onCollapseAll = () => {
    if (showAccountSidePanel) {
      const rowsToOpen = {};
      const selectedNodes = [];
      // get all parents from child node
      const nodesToOpenFn = nodes => {
        if (nodes.parent) {
          nodes.expanded = true;
          selectedNodes.push(nodes);
          rowsToOpen[nodes.key] = true; // assign truly value
          nodesToOpenFn(nodes.parent);
        }
      };

      gridRef.api.getSelectedNodes().forEach(node => {
        node.allLeafChildren.forEach(childNode => {
          nodesToOpenFn(childNode);
          selectedNodes.push(childNode);
          rowsToOpen[childNode.key] = childNode.key;
          childNode.expanded = true;
        });
      });
      gridRef.api.forEachNode(node => {
        node.expanded = rowsToOpen[node.key];
      });
      const sortedNode = selectedNodes.sort((a, b) => a.rowIndex - b.rowIndex);
      const [firstNode] = sortedNode;
      gridRef.api.ensureIndexVisible(firstNode.rowIndex, 'middle');
      gridRef && gridRef.api && gridRef.api.onGroupExpandedOrCollapsed();
    } else {
      gridRef.api.collapseAll();
      gridRef.api.ensureIndexVisible(0, 'top');
    }
  };

  return (
    <div className='accounts__buttonsContainer'>
      <button className='accounts__expandBtn' onClick={onExpandAll}>
        {t('tkExpandAll')}
      </button>
      <button className='accounts__collapseBtn' onClick={onCollapseAll}>
        {t('tkCollapseAll')}
      </button>
    </div>
  );
};

ExpandCollapseAll.propTypes = {
  showAccountSidePanel: PropTypes.bool,
  gridRef: PropTypes.any
};

export default ExpandCollapseAll;
