import React, { useMemo, useState } from 'react';
import { Button } from '@gs-ux-uitoolkit-react/button';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { Badge } from '@gs-ux-uitoolkit-react/badge';
import useUserMaintenance from '../../../context';
import UserDetailsModal from '../Modal';
import { Conditional } from '../../../../../../components/core/Conditional';
import PageHeader from '../../../../../../components/app/PageHeader';
import translator from '../../../../../../services/translator';
import { MODAL } from '../../../constants';
import { PAGES } from '../../../reducers';
import { addPageContext } from '../../../../../../actions/page';
import { OPEN_MODAL, updateCurrentPage } from '../../../actions';
import { dispatchAnalytics, userDetailsClose } from '../../../analytics';
import './index.scss';

const { translate: t } = translator;

const CreateUserHeader = () => {
  const {
    reduxDispatch,
    dispatch,
    state: {
      userDetails,
      isPageEdited,
      modal: [unsavedChangesModal],
    },
  } = useUserMaintenance();

  const { firstName, lastName } = userDetails;
  const title = useMemo(() => {
    const fullName = firstName && lastName ? `: ${firstName} ${lastName}` : '';
    return (
      <span className='new-user-title'>
        <Icon name='person' type='filled' size='lg' />
        <span>{`${t('tkSetUpUser')}${fullName}`}</span>
      </span>
    );
  }, [firstName, lastName]);

  const [isBackBtnClicked, setIsBackBtnClicked] = useState(false);

  const onBackClickWithoutSave = () => {
    setIsBackBtnClicked(true);
    dispatch({ type: OPEN_MODAL, payload: { modalName: MODAL.unsavedChanges } });
  };

  const onBack = () => {
    dispatchAnalytics(reduxDispatch, userDetailsClose());
    reduxDispatch(updateCurrentPage(PAGES.LANDING_PAGE));
    reduxDispatch(addPageContext({ pageKey: null }));
  };

  const updateBackClickState = () => {
    setIsBackBtnClicked(false);
  };

  return (
    <div className='user-profile createUser'>
      <Conditional condition={unsavedChangesModal.display}>
        <UserDetailsModal
          modalName='unsavedChanges'
          onBack={onBack}
          isBackBtnClicked={isBackBtnClicked}
          updateBackClickState={updateBackClickState}
        />
      </Conditional>
      <Button className='back-btn' actionType='primary' emphasis='minimal' onClick={isPageEdited ? onBackClickWithoutSave : onBack}>
        <Icon name='keyboard-arrow-left' type='filled' />
        {t('tkBacktoAdminDashboard')}
      </Button>
      <div className='user-profile-header'>
        {PageHeader({
          title,
          description: '',
        })}
        <div className='user-profile-header__status'>
          <Badge>{t('tkDraft')}</Badge>
        </div>
      </div>
    </div>
  );
};

export default CreateUserHeader;
