import { DataGridSize } from '@gs-ux-uitoolkit-common/datacore';
import { StyleSheet, CssProperties, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-react/style';
import {
    generateCssVars,
    lightTheme,
    Theme as UitkTheme,
    createComponentClassDefinitions,
} from '@gs-ux-uitoolkit-react/theme';
import { getIconStyles } from '@gs-ux-uitoolkit-react/icon-font';
import _agGridBaseStylesScss from './generated-styles/generated-stylesheets.css';
import { DeepReadonly } from 'ts-essentials';
import './datagrid-theme-overrides';

interface DataGridStyleSheetProps {
    theme: UitkTheme;
    size?: DataGridSize;
    disableBuiltInThemes?: boolean;
}

export const globalClassName = 'gs-datagrid';

export interface DataGridCssClasses {
    root: string;
}

export type DataGridOverrideClasses = Partial<Pick<DataGridCssClasses, 'root'>>;

export type DataGridStyledClasses = CssClassDefinitionsObject<keyof DataGridCssClasses>;

export interface DataGridStyleOverridesParams {
    props: DeepReadonly<DataGridStyleSheetProps>;
    createDefaultStyledClasses: () => DataGridStyledClasses;
}

export const dataGridStyleSheet = new StyleSheet('datagrid', (props: DataGridStyleSheetProps) => {
    return createComponentClassDefinitions<DataGridStyleSheetProps, DataGridStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.datagrid
    );
});

// CssProperties enforces that the 'visibility' property should be one of "collapse" | "hidden" |
// "visible" but weirdly doesn't accept the string "hidden !important", a mistake on their end?
function createDefaultStyledClasses({
    theme = lightTheme,
    disableBuiltInThemes,
}: DataGridStyleSheetProps): DataGridStyledClasses {
    if (disableBuiltInThemes) {
        return { root: {} };
    }
    return {
        root: {
            color: theme.text.primary,
            ...(_agGridBaseStylesScss as CssProperties),
            ...generateCssVars(theme),
            '&.gs-uitk-datagrid-size-sm': {
                '.ag-row .ag-cell': {
                    fontSize: '12px',
                },
                '.ag-header-group-row': {
                    fontSize: '12px',
                },
                '.ag-header-row': {
                    fontSize: '12px',
                },
            },
            '&.gs-uitk-datagrid-size-md': {
                '.ag-row .ag-cell': {
                    fontSize: '14px',
                },
                '.ag-header-group-row': {
                    fontSize: '14px',
                },
                '.ag-header-row': {
                    fontSize: '14px',
                },
            },
            '&.gs-uitk-datagrid-size-lg': {
                '.ag-row .ag-cell': {
                    fontSize: '16px',
                },
                '.ag-header-group-row': {
                    fontSize: '16px',
                },
                '.ag-header-row': {
                    fontSize: '16px',
                },
            },
            /**
             * If you are adding a selector that exists in themeStyles, please include a spread
             * before your additional styles i.e. '.my-class': { ...themeStyles['.my-class] }
             * TODO:
             *  1. Port all styles below to SASS to move computation to compile time and also
             *  not have to worry about style overriding here
             *  2. OR Port all styles apart from those generated directly by ag-theme-balham
             *  mixin to have a more native CSS-in-JS experience
             */
            '.ag-overlay-no-rows-wrapper': {
                backgroundColor: `${theme.surface.primary}80`,
            },
            // For pivoted column expand and collapse
            '.column-group-expand-icon:before': {
                ...getIconStyles({ name: 'keyboard-arrow-right', type: 'filled' }),
            },
            // For pivoted column expand and collapse
            '.column-group-collapse-icon:before': {
                ...getIconStyles({ name: 'keyboard-arrow-down', type: 'filled' }),
            },
            // For column hints column header wrapping
            '.gs-uitk-datagrid-column-header-wrapping .ag-header-cell-label .ag-header-cell-text': {
                whiteSpace: 'normal',
            },
            '.ag-cell.column-hint-vertical-grid-lines': {
                borderLeft: `1px solid ${theme.border.minimal}`,
            },
        },
    };
}
