import { rgba } from 'polished';
import { colors } from '@gs-ux-uitoolkit-common/design-system';
import { darkTheme, Theme } from '@gs-ux-uitoolkit-common/theme';
import {
    AgChartThemeOptions,
    AgCartesianAxesTheme,
    AgCartesianSeriesTheme,
    AgPieSeriesOptions,
    AgLineSeriesOptions,
    AgAreaSeriesOptions,
    AgBarSeriesOptions,
    AgScatterSeriesOptions,
    AgHistogramSeriesOptions,
    AgCartesianThemeOptions,
    AgPolarThemeOptions,
    AgChartThemeOverrides,
    AgChartTheme,
    AgCartesianSeriesTooltipRendererParams,
    AgBarSeriesTooltipRendererParams,
} from 'ag-charts-community';
import { isEqual } from 'gs-uitk-lodash';

export enum ChartThemeTypes {
    GsCharts = 'GsCharts',
}

export type ChartThemeTypeKeys = keyof typeof ChartThemeTypes;

export type CustomChartThemes = {
    [key in ChartThemeTypeKeys]: AgChartTheme;
};

export const defaultChartThemes = [ChartThemeTypes.GsCharts];

const stripPx = (fontSize: string) => {
    return fontSize.slice(0, -2);
};

const tooltipPadding = 'padding: 2px 4px;';

function getSeriesCommonDefaultLabelOptions(
    theme: Theme
): AgLineSeriesOptions | AgAreaSeriesOptions | AgBarSeriesOptions | AgPieSeriesOptions {
    return {
        label: {
            enabled: false,
            fontSize: parseInt(stripPx(theme.typography.body03.fontSize)),
            fontFamily: theme.typography.body03.fontFamily,
            fontWeight: 'normal',
            color: theme.text.primary as string,
        },
    };
}

function getSeriesDefaultHighlightStyle(
    _theme: Theme
): AgLineSeriesOptions | AgAreaSeriesOptions | AgBarSeriesOptions | AgPieSeriesOptions {
    return {
        highlightStyle: {
            item: {
                fill: rgba(colors.gray060, 0.5),
                stroke: rgba(colors.white, 0),
            },
        },
    };
}

function getSeriesCartesianDefaultTooltipRenderer(
    theme: Theme
): AgLineSeriesOptions | AgAreaSeriesOptions | AgBarSeriesOptions {
    return {
        tooltip: {
            renderer(
                params: AgCartesianSeriesTooltipRendererParams | AgBarSeriesTooltipRendererParams
            ) {
                document.documentElement.style.setProperty(
                    '--gs-uitk-ag-chart-tooltip-arrow-color',
                    `${params.color}`
                );
                return `<div class="gs-uitk-text-caption-01" style="background-color:${
                    params.color
                };${tooltipPadding};color:${theme.text.reversed}">
                        <div>${params.yName ? params.yName : ''}</div><div>${params.xValue}: <b>${
                            params.yValue
                        }</b></div></div>`;
            },
        },
    };
}

function getSeriesPieDefaultTooltipRenderer(theme: Theme): AgPieSeriesOptions {
    return {
        tooltip: {
            renderer(params) {
                document.documentElement.style.setProperty(
                    '--gs-uitk-ag-chart-tooltip-arrow-color',
                    `${params.color}`
                );
                return `<div class="gs-uitk-text-caption-01" style="background-color:${
                    params.color
                };${tooltipPadding};color:${theme.text.reversed};"><div>${
                    params.calloutLabelKey ? params.datum[params.calloutLabelKey] : params.angleKey
                } : <b>${params.angleValue}</b></div></div>`;
            },
        },
    };
}

function getDefaultCartesianCommonAxesConfig(theme: Theme): any {
    return {
        title: {
            fontSize: parseInt(stripPx(theme.typography.body03.fontSize)),
            fontFamily: theme.typography.body03.fontFamily,
            fontWeight: 'normal',
            color: theme.text.secondary,
        },
        gridStyle: {
            stroke: 'yellow',
        },

        line: {
            width: 1,
        },
        tick: {
            color: theme.border.minimal,
            width: 1,
            size: 8,
        },
        label: {
            fontSize: parseInt(stripPx(theme.typography.body03.fontSize)),
            fontFamily: theme.typography.body03.fontFamily,
            fontWeight: 'normal',
            color: theme.text.primary,
            padding: 10,
            rotation: 0,
        },
    };
}

function getDefaultPieSeries(theme: Theme): AgPieSeriesOptions {
    return {
        calloutLabel: {
            enabled: false,
            fontSize: parseInt(stripPx(theme.typography.ui02.fontSize)),
            fontFamily: theme.typography.ui02.fontFamily,
            fontWeight: 'normal',
            color: theme.text.primary as string,
        },
    };
}

function getDefaultChartCommonOverrides(theme: Theme): AgChartThemeOverrides['common'] {
    return {
        title: {
            enabled: false,
            fontSize: parseInt(stripPx(theme.typography.heading04.fontSize)),
            fontFamily: theme.typography.heading04.fontFamily,
            fontWeight: 'normal',
            color: theme.text.primary,
        },
        subtitle: {
            enabled: false,
            fontSize: parseInt(stripPx(theme.typography.body02.fontSize)),
            fontFamily: theme.typography.body02.fontFamily,
            fontWeight: 'normal',
            color: theme.text.primary,
        },
        series: {
            bar: {
                ...getSeriesCommonDefaultLabelOptions(theme),
                ...getSeriesCartesianDefaultTooltipRenderer(theme),
                ...getSeriesDefaultHighlightStyle(theme),
            },
            column: {
                ...getSeriesCommonDefaultLabelOptions(theme),
                ...getSeriesCartesianDefaultTooltipRenderer(theme),
                ...getSeriesDefaultHighlightStyle(theme),
            },
            histogram: {
                ...getSeriesCommonDefaultLabelOptions(theme),
                ...getSeriesCartesianDefaultTooltipRenderer(theme),
                ...getSeriesDefaultHighlightStyle(theme),
            },
            line: {
                ...getSeriesCommonDefaultLabelOptions(theme),
                ...getSeriesCartesianDefaultTooltipRenderer(theme),
                ...getSeriesDefaultHighlightStyle(theme),
            },
            scatter: {
                ...getSeriesCommonDefaultLabelOptions(theme),
                ...getSeriesCartesianDefaultTooltipRenderer(theme),
                ...getSeriesDefaultHighlightStyle(theme),
            },
            area: {
                ...getSeriesCommonDefaultLabelOptions(theme),
                ...getSeriesCartesianDefaultTooltipRenderer(theme),
                ...getSeriesDefaultHighlightStyle(theme),
            },
            pie: {
                ...getSeriesCommonDefaultLabelOptions(theme),
                ...getSeriesPieDefaultTooltipRenderer(theme),
                ...getSeriesDefaultHighlightStyle(theme),
            },
        },
        legend: {
            enabled: true,
            position: 'top',
            item: {
                label: {
                    fontSize: parseInt(stripPx(theme.typography.ui02.fontSize)),
                    fontFamily: theme.typography.ui02.fontFamily,
                    fontWeight: 'normal',
                    color: theme.text.primary,
                },
                marker: {
                    size: 10,
                    padding: 5,
                    strokeWidth: 2,
                },
                paddingX: 8,
                paddingY: 10,
            },
        },
        background: {
            fill: theme.surface.primary,
        },
    };
}
function getDefaultOverridesCartesianAxes(theme: Theme): AgCartesianAxesTheme {
    return {
        number: {
            ...getDefaultCartesianCommonAxesConfig(theme),
        },
        category: {
            ...getDefaultCartesianCommonAxesConfig(theme),
        },
        time: {
            ...getDefaultCartesianCommonAxesConfig(theme),
        },
    };
}
function getCartesianChartThemeOverridesDefault(
    theme: Theme
): AgCartesianThemeOptions<AgCartesianSeriesTheme> {
    return {
        axes: getDefaultOverridesCartesianAxes(theme),
    };
}
function getDefaultOverridesLineScatterAndAreaAxes(
    theme: Theme
): AgCartesianThemeOptions<AgLineSeriesOptions> {
    return {
        axes: {
            number: {
                ...getDefaultCartesianCommonAxesConfig(theme),
                gridStyle: [
                    {
                        stroke: colors.gray020,
                        lineDash: [1, 0],
                    },
                ],
            },
            category: {
                ...getDefaultCartesianCommonAxesConfig(theme),
                gridStyle: [
                    {
                        stroke: colors.gray020,
                        lineDash: [1, 0],
                    },
                ],
            },
            time: {
                ...getDefaultCartesianCommonAxesConfig(theme),
                gridStyle: [
                    {
                        stroke: colors.gray020,
                        lineDash: [1, 0],
                    },
                ],
            },
        },
    };
}
function getAreaChartThemeDefaultOverrides(
    theme: Theme
): AgCartesianThemeOptions<AgAreaSeriesOptions> {
    return {
        axes: {
            number: {
                ...getDefaultCartesianCommonAxesConfig(theme),
                gridStyle: [
                    {
                        stroke: colors.gray020,
                        lineDash: [1, 0],
                    },
                ],
            },
            category: {
                ...getDefaultCartesianCommonAxesConfig(theme),
                gridStyle: [
                    {
                        stroke: colors.gray020,
                        lineDash: [1, 0],
                    },
                ],
            },
            time: {
                ...getDefaultCartesianCommonAxesConfig(theme),
                gridStyle: [
                    {
                        stroke: colors.gray020,
                        lineDash: [1, 0],
                    },
                ],
            },
        },
    };
}
function getColumnChartThemeDefaultOverrides(
    theme: Theme
): AgCartesianThemeOptions<AgBarSeriesOptions> {
    return {
        axes: {
            number: {
                ...getDefaultCartesianCommonAxesConfig(theme),
                gridStyle: [
                    {
                        stroke: colors.gray020,
                        lineDash: [1, 0],
                    },
                ],
            },
            category: {
                ...getDefaultCartesianCommonAxesConfig(theme),
                gridStyle: [
                    {
                        stroke: colors.gray020,
                        lineDash: [0, 1],
                    },
                ],
            },
            time: {
                ...getDefaultCartesianCommonAxesConfig(theme),
                gridStyle: [
                    {
                        stroke: colors.gray020,
                        lineDash: [0, 1],
                    },
                ],
            },
        },
    };
}
function getBarChartThemeDefaultOverrides(theme: Theme) {
    return {
        axes: {
            number: {
                ...getDefaultCartesianCommonAxesConfig(theme),
                gridStyle: [
                    {
                        stroke: colors.gray020,
                        lineDash: [1, 0],
                    },
                ],
            },
            category: {
                ...getDefaultCartesianCommonAxesConfig(theme),
                gridStyle: [
                    {
                        stroke: colors.gray020,
                        lineDash: [0, 1],
                    },
                ],
            },
            time: {
                ...getDefaultCartesianCommonAxesConfig(theme),
                gridStyle: [
                    {
                        stroke: colors.gray020,
                        lineDash: [0, 1],
                    },
                ],
            },
        },
    };
}
function getPieChartThemeOverridesDefault(theme: Theme): AgPolarThemeOptions<AgPieSeriesOptions> {
    return {
        series: {
            ...getDefaultPieSeries(theme),
        },
        padding: {
            top: 35,
        },
    };
}
function getScatterChartThemeOverridesDefault(
    theme: Theme
): AgCartesianThemeOptions<AgScatterSeriesOptions> {
    return {
        axes: {
            number: {
                ...getDefaultCartesianCommonAxesConfig(theme),
                gridStyle: [
                    {
                        stroke: colors.gray020,
                        lineDash: [1, 0],
                    },
                ],
            },
            category: {
                ...getDefaultCartesianCommonAxesConfig(theme),
                gridStyle: [
                    {
                        stroke: colors.gray020,
                        lineDash: [1, 0],
                    },
                ],
            },
            time: {
                ...getDefaultCartesianCommonAxesConfig(theme),
                gridStyle: [
                    {
                        stroke: colors.gray020,
                        lineDash: [1, 0],
                    },
                ],
            },
        },
    };
}
function getHistogramChartThemeOverridesDefault(
    theme: Theme
): AgCartesianThemeOptions<AgHistogramSeriesOptions> {
    return {
        axes: {
            number: {
                ...getDefaultCartesianCommonAxesConfig(theme),
                gridStyle: [
                    {
                        stroke: colors.gray020,
                        lineDash: [1, 0],
                    },
                ],
            },
            category: {
                ...getDefaultCartesianCommonAxesConfig(theme),
                gridStyle: [
                    {
                        stroke: colors.gray020,
                        lineDash: [0, 1],
                    },
                ],
            },
            time: {
                ...getDefaultCartesianCommonAxesConfig(theme),
                gridStyle: [
                    {
                        stroke: colors.gray020,
                        lineDash: [0, 1],
                    },
                ],
            },
        },
    };
}
function getDefaultChartThemeOverrides(theme: Theme): AgChartThemeOptions {
    return {
        overrides: {
            cartesian: getCartesianChartThemeOverridesDefault(theme),
            line: getDefaultOverridesLineScatterAndAreaAxes(theme),
            area: getAreaChartThemeDefaultOverrides(theme),
            column: getColumnChartThemeDefaultOverrides(theme),
            bar: getBarChartThemeDefaultOverrides(theme),
            pie: getPieChartThemeOverridesDefault(theme),
            scatter: getScatterChartThemeOverridesDefault(theme),
            histogram: getHistogramChartThemeOverridesDefault(theme),
            common: getDefaultChartCommonOverrides(theme),
        },
    };
}

export function getDefaultCustomChartThemes(theme: Theme): CustomChartThemes {
    return {
        GsCharts: {
            palette: {
                fills: Object.values(theme.dataviz) as string[],
                strokes: Object.values(theme.dataviz) as string[],
            },
            ...getDefaultChartThemeOverrides(theme),
            ...(isEqual(darkTheme, theme) && { baseTheme: 'ag-material-dark' }),
        },
    };
}

export function getGsChartsTheme(theme: Theme): CustomChartThemes['GsCharts'] {
    return getDefaultCustomChartThemes(theme).GsCharts;
}
