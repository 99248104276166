import { useContext, CSSProperties, ReactNode, FC, memo } from 'react';
import PropTypes from 'prop-types';
import { cardStyleSheet, getCardHeaderClasses } from '@gs-ux-uitoolkit-common/card';

import { ComponentProps } from '@gs-ux-uitoolkit-react/component';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { CardContext } from './card-context';

export interface CardHeaderProps extends ComponentProps<CSSProperties> {
    children?: ReactNode;
}

/**
 * CardHeader provides appropriate spacing around the header section of a Card.
 */
export const CardHeader: FC<CardHeaderProps> = memo(props => {
    const { className, children, ...attributes } = props;

    const theme = useTheme();
    const { size } = useContext(CardContext);
    const cssClasses = useStyleSheet(cardStyleSheet, { theme, size });

    return (
        <div
            data-gs-uitk-component="card-header"
            {...attributes}
            className={getCardHeaderClasses({ cssClasses, className })}
        >
            {children}
        </div>
    );
});
CardHeader.displayName = 'CardHeader';

CardHeader.propTypes = {
    className: PropTypes.string,
};
