import React from "react";
import PropTypes from "prop-types";
import {INPUT, PAYMENT_DATE_FROM, PAYMENT_DATE_TO} from "../../constants";
import translator from "../../../../../../services/translator";
import AmountInput from "../AmountInput";
import {Conditional} from "../../../../../core/Conditional";
import Dropdown from "../Dropdown";
import {formatPaymentDate} from "../Item";

const { translate: t } = translator;

const ItemEdit = ({
  value,
  labels,
  handleEditFields,
  field,
  fieldType,
  errorState,
}) => {

  const paymentDateOptions = () => {
    const paymentDateOptions = [];
    for (let i = PAYMENT_DATE_FROM; i <= PAYMENT_DATE_TO ; i++) {
      paymentDateOptions.push({
        label : formatPaymentDate(i, labels),
        value: i
      })
    }
    return paymentDateOptions;
  };

  const dropdownData = {
    investmentOption: [
      {
        value: "Cash",
        label: t("tkCash"),
      },
      {
        value: "Reinvest",
        label: t("tkReinvest"),
      }
    ],
    paymentDate: paymentDateOptions()
  };

  return (
    <span className="factData-item-edit">
      <Conditional condition={fieldType === INPUT}>
        <AmountInput
          value={value}
          field={field}
          handleEditFields={handleEditFields}
          errorState={errorState}
          labels={labels}
        />
        <Dropdown
          value={value}
          field={field}
          handleEditFields={handleEditFields}
          options={dropdownData[field]}
        />
      </Conditional>
    </span>
  );
};

ItemEdit.propTypes = {
  value:  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  labels: PropTypes.object,
  handleEditFields: PropTypes.func,
  field: PropTypes.string,
  fieldType: PropTypes.string,
  errorState: PropTypes.number
};

export default ItemEdit;
