import { cx } from '@gs-ux-uitoolkit-common/style';
import { FormFeedbackProps } from './form-feedback-props';
import { FormFeedbackCssClasses } from './form-feedback-style-sheet';
import { FormGroupCssClasses } from './form-group-style-sheet';
import { FormTextCssClasses } from './form-text-style-sheet';
import { FormGroupProps } from './form-group-props';
import { FormTextProps } from './form-text-props';

const globalClassName = 'gs-form';

export const getFormFeedbackClassNames = (props: {
    cssClasses: FormFeedbackCssClasses;
    className: FormFeedbackProps['className'];
}): string => {
    const { cssClasses, className } = props;
    return cx(cssClasses.root, className, `${globalClassName}-feedback`);
};

export const getFormGroupClassNames = (props: {
    cssClasses: FormGroupCssClasses;
    className: FormGroupProps['className'];
}): string => {
    const { cssClasses, className } = props;
    return cx(cssClasses.root, className, `${globalClassName}-group`);
};

export const getFormTextClassNames = (props: {
    cssClasses: FormTextCssClasses;
    className: FormTextProps['className'];
}): string => {
    const { cssClasses, className } = props;
    return cx(cssClasses.root, className, `${globalClassName}-text`);
};
