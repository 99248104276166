import { colors } from '@gs-ux-uitoolkit-common/design-system';
import { StyleSheet, CssClasses, getEmotionInstance } from '@gs-ux-uitoolkit-react/style';
import { Theme } from '@gs-ux-uitoolkit-react/theme';
import { rgba } from 'polished';
import { MOBILE_BREAKPOINT, TOOLBAR_CONFIG_MAX_HEIGHT_CONTENT } from '../style-constant';

const slideInFromLeft = getEmotionInstance().keyframes`
0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const gridConfigurationmodalsContainerStyleSheet = new StyleSheet(
    'gs-uitk-plugin-management-plugin-list',
    ({ theme }: { theme: Theme }) => {
        return {
            root: {
                height: '100%',
                '& > .gs-tabs > ul.nav.nav-tabs': {
                    backgroundColor: theme.surface.secondary,
                    width: '160px',
                    flexWrap: 'nowrap',
                    height: 'initial',
                    minHeight: '56vh',
                    '.nav-item .nav-link': {
                        marginRight: '0',
                        border: 'none',
                        '&.active': {
                            backgroundColor: rgba(colors.blue060, 0.2),
                            borderLeft: `2px solid ${colors.blue060}`,
                            borderRadius: '0',
                        },
                    },
                },
                '.gs-tabs__content': {
                    padding: '0 0 0 1rem',
                    display: 'flex',
                    flex: 1,
                    overflow: 'scroll',
                    '.tab-pane': {
                        width: '100%',
                    },
                },
                '.gs-uitk-plugin-management-plugin-list': {
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    maxHeight: TOOLBAR_CONFIG_MAX_HEIGHT_CONTENT,
                    height: '100%',
                    paddingRight: 0,
                    paddingLeft: 0,

                    '.gs-uitk-datagrid-config-plugin': {
                        cursor: 'default',
                    },

                    // Nav overrides to be removed once the toolkit is updated to the new styles
                    'ul.nav.flex-column.nav-tabs': {
                        width: '100%',
                        height: 'auto',
                        color: colors.gray060,
                        '.active': {
                            color: colors.blue060,
                        },
                        'li.nav-item a.nav-link': {
                            height: '2.5rem',
                            padding: '0.75rem 1rem',
                        },
                    },
                    // END OF OVERRIDES
                },
                '.gs-uitk-datagrid-config-plugin-responsive-mode': {
                    display: 'none',
                },

                [`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
                    '.gs-uitk-datagrid-config-plugin-responsive-mode': {
                        display: 'block',
                        height: '100%',
                        overflow: 'scroll',
                        '&-body': {
                            animation: `0.25s 0s 1 ${slideInFromLeft}`,
                        },
                        '> ul': {
                            padding: 0,
                            margin: 0,
                            height: '100%',
                            backgroundColor: theme.surface.secondary,
                            '.gs-button': {
                                borderBottom: `${colors.gray020} 1px solid`,
                                width: '100%',
                                button: {
                                    textAlign: 'left',
                                    padding: '10px 0',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    background: theme.surface.secondary,
                                    boxSizing: 'content-box',
                                    'span:last-child:not(:only-child)': {
                                        paddingRight: '12px',
                                    },
                                },
                            },
                        },
                    },
                    '> .gs-tabs': {
                        display: 'none',
                    },
                },
            },
            '.plugin-info': {
                fontSize: '1.5rem',
                color: '',
                letterSpacing: '-1.5px',
                textAlign: 'left',
                lineHeight: 1.5,
            },
            '.remove-mouse-interaction': {
                pointerEvents: 'none',
            },
        };
    }
);

export const getRemoveMouseInteractionClassNames = (props: {
    cssClasses: CssClasses<typeof gridConfigurationmodalsContainerStyleSheet>;
}): string => {
    return props.cssClasses['.remove-mouse-interaction'];
};

export const getPluginInfoClassNames = (props: {
    cssClasses: CssClasses<typeof gridConfigurationmodalsContainerStyleSheet>;
}): string => {
    return props.cssClasses['.plugin-info'];
};
