import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Constants from '../../../constants/appConstants';
import ControlPanel  from './ControlPanel';
import CustomView  from './CustomView';
import Snackbar from '../../../components/core/Snackbar';
import ReportGrid from './IndividualReportGrid';
import FundFactView from '../../../containers/FundFactView';
import {
  currentViewSelector, filterDataSelector, initialFilterDataSelector,
  initialNodeMapSelector, snackbarList
} from '../../../selectors/pages/reports/individualReport';
import {usePrevious} from '../../../utils/customHooks/usePrevious';
import {loadReportGridData} from '../../../actions/page/reports';
import { useEffectDebugger } from '../../../utils/customHooks';
import { labelSelector } from '../../../selectors/app';
import {selectedSavedReportDataSelector} from '../../../selectors/app/reports';
import {getAppRoute} from '../../../utils/commonUtils';
import {pageIdSelector} from '../../../selectors/pages';
import {hasViewColumnsChanged} from '../utils';
import {setCustomReportCrudResponse, setSelectedFilterData} from '../../../actions/app/reports';
import {abortRequestSafe} from '../../../services/fetchService';
import './index.scss';


const IndividualReport = ({
  dispatchLoadReportGridData, currentView: activeView, selectedFilterData,
  selectedSavedReportData, pageId, history, snackbarList, labels,
  setCustomReportCrudResponse, initialFilterData, initialNodeMap,
  dispatchSelectedFilterData
}) => {
  const previousSelectedFilterData = usePrevious(selectedFilterData);
  const previousActiveView = usePrevious(activeView);
  const {date: prevDate, columns: prevColumns} = previousActiveView || {};
  const {date: currentDate, columns: currentColumnsData} = activeView;
  const isSavedReportPage = (pageId === 'SavedReport');
  const hasFilterDataUpdated = !isEmpty(selectedFilterData) &&
    !isEqual(previousSelectedFilterData, selectedFilterData);
  const hasDatesUpdated = !isEqual(prevDate, currentDate);
  const hasActiveViewCriteriaChanged = (hasDatesUpdated);
  const hasActiveViewColumnsChanged = hasViewColumnsChanged(currentColumnsData, prevColumns);
  const hasCriteriaUpdated = (hasFilterDataUpdated || hasActiveViewCriteriaChanged
    || hasActiveViewColumnsChanged);

  useEffect(() => {
    // If it's a Saved Report page
    if (isSavedReportPage) {
      if (!selectedSavedReportData) {
        history.push(getAppRoute(Constants.REPORT_MY_REPORTS_TAB_ROUTE));
      }
    } else { // If it's Standard Reports
      // Reset the selected filter data upon loading with Initial Filter Data
      dispatchSelectedFilterData({filterData: initialFilterData, nodeMap: initialNodeMap});
    }
    return () => {
      abortRequestSafe('loadStandardReportData');
      setCustomReportCrudResponse(null);
    };
  }, []);

  useEffectDebugger(() => {
    if (!isEmpty(activeView) && hasCriteriaUpdated && !isEmpty(selectedFilterData)) {
      dispatchLoadReportGridData({
        activeView,
        ...selectedFilterData,
      });
    }
  }, [selectedFilterData, activeView],  ['reports', 'activeView']);

  return (
    <div className="js-main-content main-container__content report">
      <FundFactView />
      <CustomView />
      <div className="report__container">
        <TransitionGroup component={null}>
          {
            snackbarList.length > 0 && snackbarList.map((data, idx) => (
              <CSSTransition
                key={idx}
                classNames="snackbar__transition"
                timeout={Constants.CSS_DURATION_HORSE}
              >
                <Snackbar
                  id={data.id}
                  labels={labels}
                  type={data.type}
                  msgCopy={data.msgCopy}
                  autoHide={data.autoHide}
                  showCloseCTA={data.showCloseCTA}
                  showIcon={data.showIcon}
                />
              </CSSTransition>
            ))
          }
        </TransitionGroup>
        <ControlPanel />
        <ReportGrid />
      </div>
    </div>
  );
};

IndividualReport.propTypes = {
  dispatchLoadReportGridData: PropTypes.func,
  currentView: PropTypes.object,
  selectedFilterData: PropTypes.object,
  selectedSavedReportData: PropTypes.object,
  pageId: PropTypes.string,
  history: PropTypes.object,
  snackbarList: PropTypes.array,
  labels: PropTypes.object,
  setCustomReportCrudResponse: PropTypes.func,
  initialNodeMap: PropTypes.object,
  initialFilterData: PropTypes.object,
  dispatchSelectedFilterData: PropTypes.func
};

const mapStateToProps = (state) => ({
  pageId: pageIdSelector(state),
  currentView: currentViewSelector(state),
  selectedFilterData: filterDataSelector(state),
  selectedSavedReportData: selectedSavedReportDataSelector(state),
  snackbarList: snackbarList(state),
  labels: labelSelector(state),
  initialNodeMap: initialNodeMapSelector(state),
  initialFilterData: initialFilterDataSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  dispatchLoadReportGridData: (payLoad) => {
    dispatch(loadReportGridData(payLoad));
  },
  setCustomReportCrudResponse: (data) => {
    dispatch(setCustomReportCrudResponse(data));
  },
  dispatchSelectedFilterData: (props) => {
    dispatch(setSelectedFilterData(props));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(IndividualReport);
