// NOTE: This file really belongs in the design-system package, but because
// design-system currently depends on icon-font (only for Sass) and they both
// need the URL, this must exist here for now.
// When we remove the legacy Sass, we move this to design-system and have
// icon-font depend on design-system instead.
import { getGlobalThis } from '@gs-ux-uitoolkit-common/shared';

const cdnBaseUrl = 'https://cdn.gs.com';

declare global {
    let GS_UITOOLKIT_CDN_BASE_URL: string;
}

/**
 * Retrieves the base URL of the CDN that hosts Toolkit assets such as font
 * files and images.
 */
export function getCdnBaseUrl(): string {
    return getGlobalThis().GS_UITOOLKIT_CDN_BASE_URL || cdnBaseUrl;
}
