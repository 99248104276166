/* eslint-disable react-hooks/exhaustive-deps */
// TODO: https://jira.site.gs.com/browse/UX-14776

import { FunctionComponent, useContext, useEffect, useRef, memo, CSSProperties } from 'react';

import PropTypes from 'prop-types';
import {
    getCollapseMenuClass,
    SimpleMenuProps,
    MenuEvent,
    MenuBlurEvent,
    menuStyleSheet,
} from '@gs-ux-uitoolkit-common/menu';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { valuePropType } from './menu';
import { MenuContext } from './menu-context';
import {
    MenuSingleValueProps as MenuSingleValueCommonProps,
    MenuMultipleValueProps as MenuMultipleValueCommonProps,
} from './menu-react-props';
import { SubmenuContext } from './submenu-context';
import { useMenuContextCreator } from './use-menu-context-creator';

export interface CollapseMenuSingleValueProps
    extends SimpleMenuProps<CSSProperties>,
        Omit<MenuSingleValueCommonProps, 'style'> {}

export interface CollapseMenuMultipleValueProps
    extends SimpleMenuProps<CSSProperties>,
        Omit<MenuMultipleValueCommonProps, 'style'> {}

export type CollapseMenuProps = CollapseMenuSingleValueProps | CollapseMenuMultipleValueProps;

/**
 * CollapseMenu is a type of submenu. It is not designed to be used on its own;
 * it should be used only as a child of a MenuOption which in turn is a child
 * of a Menu.
 */
export const CollapseMenu: FunctionComponent<CollapseMenuProps> = memo(
    (props: CollapseMenuProps) => {
        const {
            autoFocus,
            visible,
            className,
            classes: overrideClasses,
            size,
            value,
            defaultValue,
            onShow,
            onHide,
            onBlur: onBlurProp,
            onChange,
            disabled,
            ...otherProps
        } = props;

        const parentContext = useContext(MenuContext);
        const submenuContext = useContext(SubmenuContext);

        const currentVisible = useRef(visible);

        const menuEl = useRef<HTMLDivElement>(null);
        useEffect(() => {
            const { parentElement } = submenuContext;
            if (parentElement) {
                if (visible) {
                    if (menuEl.current) {
                        parentContext.addChildren(
                            Array.from(menuEl.current.children) as HTMLElement[],
                            parentElement,
                            autoFocus
                        );
                        if (!currentVisible.current && onShow) {
                            currentVisible.current = true;
                            onShow(new MenuEvent({ menuElement: menuEl.current }));
                        }
                    }
                } else {
                    parentContext.removeChildren(parentElement);
                    if (currentVisible.current && onHide) {
                        currentVisible.current = false;
                        onHide(new MenuEvent({ menuElement: menuEl.current }));
                    }
                }
            }
        }, [autoFocus, visible, menuEl.current, submenuContext.parentElement]);

        function onBlur(event: MenuBlurEvent) {
            const { input, menuElement, nextActiveElement } = event;
            const nextActiveEl = nextActiveElement as any;
            const eventIsOutsideDocument = window === nextActiveEl;

            const shouldBlur =
                !menuElement ||
                input !== 'pointer' ||
                eventIsOutsideDocument ||
                !menuElement.contains(nextActiveEl);
            if (shouldBlur) {
                if (onBlurProp) {
                    onBlurProp(event);
                }
            }
        }

        const menuContext = useMenuContextCreator({
            props: { ...props, size: size || parentContext.size },
            onBlur,
        });

        const theme = useTheme();
        const cssClasses = useStyleSheet(menuStyleSheet, {
            theme,
            size: size || menuContext.size,
            visible: visible || false,
            disabled: disabled || false,
        });

        return (
            <MenuContext.Provider value={menuContext}>
                <div
                    {...otherProps}
                    className={getCollapseMenuClass({
                        cssClasses,
                        className,
                        overrideClasses,
                    })}
                    aria-multiselectable={props.multiple}
                    data-gs-uitk-component="collapse-menu"
                    tabIndex={-1}
                    ref={menuEl}
                >
                    {props.children}
                </div>
            </MenuContext.Provider>
        );
    }
);
CollapseMenu.displayName = 'CollapseMenu';

// We have some TypeScript fanciness above to differentiate between multiselect
// and single select Menus which is probably not possible (or worth it) to
// 100% replicate with PropTypes, hence the @ts-expect-error.
CollapseMenu.propTypes = {
    visible: PropTypes.bool,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    // @ts-expect-error TODO: describe this
    multiple: PropTypes.oneOf([true, false]),
    // @ts-expect-error TODO: describe this
    value: valuePropType,
    // @ts-expect-error TODO: describe this
    defaultValue: valuePropType,
    onHide: PropTypes.func,
    onBlur: PropTypes.func,
    onShow: PropTypes.func,
    onChange: PropTypes.func,
};
