import { ColumnHintFormatSection } from '../../plugins/column-hint/view/components/formatScreens/column-hint-format-section';
import { ColumnHintControlColumnWidth } from '../../plugins/column-hint/view/components/formatControls/column-hint-control-column-width';
import { ColumnHintControlAlignment } from '../../plugins/column-hint/view/components/formatControls/column-hint-control-alignment';
import { columnFormattingPluginStylePrefix } from '../../style/plugins/column-hint-plugin-stylesheet';
import { Row, Col } from '@gs-ux-uitoolkit-react/layout';
import { FormatControlProps } from '../../plugins/column-hint/view/components/column-hint-format-manager';
import { ColumnHintColumnHeaderWrapping } from '../../plugins/column-hint/view/components/formatControls/column-hint-control-column-header-wrapping';
export const ColumnHintLayoutSection = (props: FormatControlProps) => {
    return (
        <ColumnHintFormatSection title="Layouts">
            <Row>
                <Col sm={12}>
                    <div
                        className={`${columnFormattingPluginStylePrefix}_flex-two-column-container ${columnFormattingPluginStylePrefix}_layout`}
                    >
                        <ColumnHintControlColumnWidth
                            columnHint={props.columnHint}
                            onChangeHint={props.onChangeHint}
                        />
                        <ColumnHintControlAlignment
                            columnHint={props.columnHint}
                            onChangeHint={props.onChangeHint}
                        />
                        <ColumnHintColumnHeaderWrapping
                            columnHint={props.columnHint}
                            onChangeHint={props.onChangeHint}
                        />
                    </div>
                </Col>
            </Row>
        </ColumnHintFormatSection>
    );
};
