// tslint:disable:no-submodule-imports
import { Instance } from 'flatpickr/dist/types/instance';
import { DatePickerCssClasses } from './date-picker-style-sheet';
import { DatePickerOptions } from './options/date-picker-options';

export interface DatePickerProps {
    /**
     * A callback function to apply to a flatpickr instance during setup.
     */
    readonly instanceCallback?: (instance: Instance) => void;
    /**
     * Set of options to customize flatpickr instance. See https://flatpickr.js.org/options/
     */
    readonly options: DatePickerOptions;
    /**
     * Style classes to override.
     */
    classes?: Partial<DatePickerCssClasses>;
    /**
     * Whether or not the clear button should be rendered when a date is selected.
     */
    clearable?: boolean;
}

export const defaultDatePickerProps: DefaultDatePickerProps = {
    clearable: true,
};

export type DefaultDatePickerProps = Required<Pick<DatePickerProps, 'clearable'>>;
