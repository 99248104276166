import { ComponentProps, CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { StepsSize } from '.';

export type StepsSummaryOverrideClasses = Partial<Record<'root', string>>;

/**
 * General configuration for Steps Summary
 */
export interface StepsSummaryProps extends ComponentProps<CommonStyleType> {
    /**
     * Style classes to override.
     */
    classes?: StepsSummaryOverrideClasses;

    /**
     * The size of the steps summary
     */
    size?: StepsSize;

    /**
     * The index of the current (active) Step.
     *
     */
    currentStep?: number;

    /**
     * The array of steps strings that serve as the labels.
     */
    stepsLabels?: string[];
}

export const stepsSummaryDefaultProps: Required<Pick<StepsSummaryProps, 'size'>> = {
    size: 'md',
};
