/**
 * The prefix to give all computed CSS in JS classes.
 *
 * The 'c' at the end stands for "computed", and is used to distinguish between computed
 * CSS in JS class names and other 'gs-uitk-' prefixed class names left in SASS.
 *
 * Example computed CSS class:
 *
 *     'gs-uitk-c-w9fcfl-alert-container'
 *
 * @private
 */
export const classNamePrefix = 'gs-uitk-c';
