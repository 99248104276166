import actionTypes from '../../../../actions/containers/importTrades/actionTypes';

export function importTradesContextReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.ADD_IMPORTED_TRADES_SAVE_RESPONSE_DATA:
    case actionTypes.EDIT_IMPORT_TRADES_PREFERENCES:
    case actionTypes.SAVING_IMPORTED_TRADES_DATA_LOADING:
    case actionTypes.SELECT_IMPORT_TRADES_FIRM_AND_BRANCH:
    case actionTypes.RESET_IMPORT_TRADES_MODAL_STATE:
    case actionTypes.OPEN_IMPORT_TRADES_MODAL:
    case actionTypes.CLOSE_IMPORT_TRADES_MODAL:
      return {...state, ...action.data};
    default:
      return state;
  }
}

export function importTradesPageContextReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.RESET_IMPORT_TRADES_MODAL_SNACKBAR_STATE:
      return {...state, ...action.data};
    default:
      return state;
  }
}


