import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './index.scss';
import {Conditional} from "../Conditional";

const classes = {
  'button-primary': 'button button__primary',
  'button-primary-dark': 'button button__primary button__primary--dark',
  'button-primary-transparent': 'button button__primary button__primary--transparent',
  'button-secondary': 'button button__secondary',
  'button-secondary-dark': 'button button__secondary button__secondary--dark',
  'button-primary-small': 'button button__primary button__primary--small',
  'button-primary-small-disabled': 'button button__primary button__primary--small disabled',
  'button-secondary-small': 'button button__secondary button__secondary--small',
  'button-primary-dark-small': 'button button__primary button__primary--dark button__primary--dark-small',
  'button-secondary-dark-small': 'button button__secondary button__secondary--dark-small',
  'button-positive': 'button button__positive',
  'button-negative': 'button button__negative',
  'button-in-active' : 'button button--in-active',
  'button-link': 'button button__link',
  'button-inline': 'button button__inline'
};

class Button extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    if (this.props.clickHandler) {
      this.props.clickHandler(e, this.props);
    }
  }

  handleMouseDown(e) {
    e.preventDefault();
    if (this.props.mouseDownHandler) {
      this.props.mouseDownHandler(e, this.props);
    }
  }

  render() {
    const {customClass, type, isDisabled, isVisible, extraProps, extraClass, iconClass, testId } = this.props;
    const options = {};
    options.type = type || 'button';
    options.disabled = isDisabled;

    return (
      <button
        data-testid={testId}
        {...extraProps}
        onClick={this.handleClick}
        onMouseDown={this.handleMouseDown}
        className={cn(
          {[`${classes[customClass]} ${extraClass}`]: isVisible},
          {'button__hide': !isVisible},
          {'button__no-padding': !!iconClass}
        )}
        {...options}>
        <Conditional condition={!iconClass}>
          {this.props.label}
          <div className="icon-label-wrapper">
            <span className={iconClass} />
            {
              this.props.label &&
                <span className="icon-label">{this.props.label}</span>
            }
          </div>
        </Conditional>
      </button>
    );
  }
}

Button.defaultProps = {
  customClass: 'button-primary',
  isDisabled: false,
  isVisible: true,
  label: "",
  extraProps: {},
  extraClass: '',
  testId: ''
};

Button.propTypes = {
  label: PropTypes.string,
  clickHandler: PropTypes.func,
  mouseDownHandler: PropTypes.func,
  customClass: PropTypes.string,
  type: PropTypes.string,
  isDisabled: PropTypes.bool,
  isVisible: PropTypes.bool,
  extraProps: PropTypes.object,
  extraClass: PropTypes.string,
  iconClass: PropTypes.string,
  testId: PropTypes.string
};

export default Button;
