import React, {Component} from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import Link from '../../core/Link';
import {Conditional} from '../../core/Conditional';
import translator from '../../../services/translator';
import './index.scss';

const {translate: t} = translator;

class HelpSection extends Component {
  constructor(props) {
    super(props);
    this.onHideHelpSection = this.onHideHelpSection.bind(this);
  }

  onHideHelpSection() {
    this.props.isShowMosaicHelpSection(false);
  }

  render() {
    const {helpContents = [], labels, title} = this.props;
    return (
      <div className="help">
        <div className="help__header-wrapper">
          <div className="help__header">
            {title}
          </div>
          <div className="help__header-sub">
            {labels.tkMosaicLoginHelpMsg}
          </div>
        </div>
        <div className="help__content-wrapper">
          <Conditional condition={helpContents.length > 1}>
            <React.Fragment>
              {
                map(helpContents, (item, idx) => {
                  return (
                    <div key={idx} className="help__content-flex">
                      <div className="help__content help__content-key">{item.region}</div>
                      <div className="help__content help__content-value">{item.phoneNumber}</div>
                      <div className="help__content help__content-time">{item.time}</div>
                    </div>
                  )
                })
              }
            </React.Fragment>
            <React.Fragment>
              {
                map(helpContents, (item, idx) => {
                  return (
                    <div key={idx} className="help__content-row">
                      <div className="help__content"><span className='item-header'>{t('tkGroupName')}</span>{item.groupName}</div>
                      <div className="help__content"><span className='item-header'>{t('tkPhoneNumber')}</span>{item.phoneNumber}</div>
                      <div className="help__content"><span className='item-header'>{t('tkEmail')}</span>{item.emailAddress}</div>
                      <div className="help__content"><span className='item-header'>{t('tkHoursOfOperation')}</span>{item.hoursOfOperation}</div>
                    </div>
                  )
                })
              }
            </React.Fragment>
          </Conditional>
        </div>
        <div className="help__content-help">
          <Link
            label={labels.tkBack}
            isDisabled={false}
            handleClick={this.onHideHelpSection}
          />
        </div>
      </div>
    );
  }
}

HelpSection.propTypes = {
  isShowMosaicHelpSection: PropTypes.func,
  helpContents: PropTypes.array,
  labels: PropTypes.object,
  title: PropTypes.string
};

export default HelpSection;
