import React from 'react';
import {MODULES_KEYS} from '../../../../constants/pageConstants';
import WithLoader from '../../../../containers/WithLoader';
import Grid from './Grid';

const CompareDataGrid = () => (
  <WithLoader>
    <div className="grid">
      <Grid id={MODULES_KEYS.FUND_TRACKER_COMPARE_DATA_GRID} />
    </div>
  </WithLoader>
);

export default CompareDataGrid;
