import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Error from '../../components/core/Error';
import {SHOW_ERROR_ON} from '../../constants/pageConstants';
import {Conditional} from '../../components/core/Conditional';
import {getLabel} from '../../selectors/app';
import {checkIsPageInErrorMode, getErrorObject} from '../../selectors/pages';

export const WithPageError = ({label, errorObject, isPageInErrorMode, children}) => {
  const error = {
    code: errorObject && errorObject.errorCode,
    label
  };
  return (
    <Conditional condition={!(isPageInErrorMode && (errorObject.showErrorOn === SHOW_ERROR_ON.PAGE))}>
      {children}
      <div className="js-main-content main-container__content">
        <Error {...error} />
      </div>
    </Conditional>
  );
};

WithPageError.propTypes = {
  isPageInErrorMode: PropTypes.bool,
  label: PropTypes.func,
  errorObject: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

const mapStateToProps = (state) => (
  {
    isPageInErrorMode: checkIsPageInErrorMode(state),
    errorObject: getErrorObject(state),
    label: key => getLabel(state, key)
  }
);
export default connect(mapStateToProps)(WithPageError);
