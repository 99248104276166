import {
    ForwardRefExoticComponent,
    FunctionComponent,
    PropsWithChildren,
    useState,
    memo,
    RefObject,
    useEffect,
} from 'react';
import {
    TooltipProps as CommonTooltipProps,
    tooltipStyleSheet,
    defaultTooltipProps,
    getTooltipRootClasses,
    getTooltipCloseButtonClasses,
    getTooltipLabelClasses,
    TooltipCssClasses,
    removeFocusFromAllChildrens,
    getTooltipContentInnerClasses,
} from '@gs-ux-uitoolkit-common/tooltip';
import TippyImpl, { TippyProps } from '@tippyjs/react';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { ReactComponentProps } from '@gs-ux-uitoolkit-react/component';
import {
    GsPopoverInputs,
    createTippyOptions,
    tippyStyleSheet,
    TippyCssClasses,
} from '@gs-ux-uitoolkit-common/popover-base';
import { uniqueId } from 'gs-uitk-lodash';
import { isRefObject, splitDataAttributes } from '@gs-ux-uitoolkit-react/shared';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { componentAnalytics } from './analytics-tracking';

// Hack for '@tippyjs/react': in the ESM module, the default export is the
// Tippy component itself. In the commonjs (UMD) export, there's a `.default`
// property, which is used when including Tooltip in a Jest or Vitest test
// (Vitest uses the UMD export because there's only "main" and "module"
// properties in its package.json, and Node.js will never read the "module"
// property)
const Tippy: ForwardRefExoticComponent<TippyProps> = (TippyImpl as any).default || TippyImpl;

// shared by both `Tooltip` and `TooltipTarget`
// className is defined in common props with its own jsDoc comment
export interface CommonReactTooltipProps
    extends CommonTooltipProps,
        Omit<ReactComponentProps, 'className'> {
    /**
     * callback method triggered when tooltip is shown
     */
    onShow?: () => void;

    /**
     * callback method triggered when tooltip is hidden
     */
    onHide?: () => void;

    /**
     * callback method triggered when tooltip is about to be shown
     */
    onBeforeShow?: () => boolean | void;

    /**
     * callback method triggered when tooltip is about to be hidden
     */
    onBeforeHide?: () => boolean | void;
}

export interface TooltipProps extends CommonReactTooltipProps {
    /**
     * Host element that triggers the tooltip
     */
    target: string | HTMLElement | RefObject<HTMLElement>;
}

/**
 * Tooltips display contextual information relating to a specific target element
 * in an application user interface. The recommended TooltipTarget variation is
 * the simplest way to link a Tooltip to a target.
 */

export const Tooltip: FunctionComponent<PropsWithChildren<TooltipProps>> = memo(props => {
    const theme = useTheme();
    const [target, setTarget] = useState<HTMLElement | RefObject<HTMLElement> | null>(null);
    const [id] = useState(() => uniqueId('gs-uitk-tooltip-'));
    const cssClasses = useStyleSheet(tooltipStyleSheet, { size: props.size, theme });
    const tippyCssClasses = useStyleSheet(tippyStyleSheet, { theme });

    useEffect(() => {
        let hostEl: HTMLElement | null = null;
        // get target host element; props.target can be string, element or a RefObject
        if (isRefObject(props.target)) {
            if (props.target.current) {
                hostEl = props.target.current;
                setTarget(props.target);
            }
        } else {
            let targetEl: HTMLElement | null;
            if (typeof props.target === 'string') {
                // if string, it can be a CSS selector or an id
                targetEl = document.querySelector(props.target);
                if (!targetEl) {
                    targetEl = document.querySelector(`#${props.target}`);
                }
            } else {
                targetEl = props.target;
            }

            if (targetEl) {
                // Makes target element focusable to open tooltip on keyboard navigation
                targetEl.setAttribute('tabindex', '0');
                targetEl.setAttribute('aria-live', 'polite');
                // Make childrens unfocusable to prevent double focus
                Array.from(targetEl.children).forEach(removeFocusFromAllChildrens);
                setTarget(targetEl);
                hostEl = targetEl;
            } else {
                throw new Error(
                    `Tooltip: The target '${props.target}' could not be identified in the DOM. Tip: check spelling`
                );
            }
        }

        if (hostEl) {
            hostEl.setAttribute('data-gs-uitk-tooltip-id', id);
        }
    }, [props.target, id]);

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'tooltip' });
    }, []); // Only run once

    return (
        <>
            {target && (
                <Tippy
                    reference={target}
                    {...getTippyOptions(props, id, cssClasses, tippyCssClasses)}
                    className={getTooltipRootClasses({
                        tippyCssClasses,
                        className: props.className,
                        overrideClasses: props.classes,
                    })}
                />
            )}
        </>
    );
});
Tooltip.displayName = 'Tooltip';

const getTippyOptions = (
    props: PropsWithChildren<TooltipProps>,
    id: string,
    cssClasses: TooltipCssClasses,
    tippyCssClasses: TippyCssClasses
): TippyProps => {
    const {
        placement,
        showTip,
        hideDelay,
        showDelay,
        dismissible,
        flip,
        fade,
        container,
        className,
        classes,
        triggers,
        visible,
        size,
        onShow,
        onHide,
        onBeforeShow,
        onBeforeHide,
    } = props;

    const options: GsPopoverInputs = {
        id,
        size: size || defaultTooltipProps.size,
        placement,
        showTip,
        hideDelay,
        showDelay,
        dismissible,
        flip,
        fade,
        container,
        className: getTooltipRootClasses({
            tippyCssClasses,
            className,
            overrideClasses: classes,
        }),
        classes,
        triggers,
        visible,
        onShow,
        onHide,
        onBeforeShow,
        onBeforeHide,
    };

    return {
        ...createTippyOptions('tooltip', options, cssClasses),
        content: <TooltipContent props={props} cssClasses={cssClasses} />,
        visible,
    };
};

const TooltipContent = memo(
    ({
        props,
        cssClasses,
    }: {
        props: PropsWithChildren<TooltipProps>;
        cssClasses: TooltipCssClasses;
    }) => {
        const { children, dismissible, classes: overrideClasses, ...otherProps } = props;
        const { split: dataAttributeProps } = splitDataAttributes(otherProps);

        return (
            <>
                <span
                    {...dataAttributeProps}
                    key={`gs-uitk-tooltip-children-${uniqueId}`}
                    className={getTooltipContentInnerClasses({ cssClasses, overrideClasses })}
                >
                    <span
                        className={getTooltipLabelClasses({ cssClasses, overrideClasses })}
                        data-cy="gs-uitk-tooltip__label"
                    >
                        {children}
                    </span>
                    {dismissible && (
                        <button
                            data-cy="gs-uitk-tooltip__dismiss-button"
                            className={getTooltipCloseButtonClasses({
                                cssClasses,
                                overrideClasses,
                            })}
                            key={`gs-uitk-tooltip-dismiss-button-${uniqueId}`}
                        >
                            <Icon name="close" type="filled" />
                        </button>
                    )}
                </span>
            </>
        );
    }
);
TooltipContent.displayName = 'TooltipContent';
