import { TypographyVariant } from '@gs-ux-uitoolkit-common/design-system';
import {
    StyleSheet,
    CssPropertyType,
    CssProperties,
    CssClassDefinitionsObject,
} from '@gs-ux-uitoolkit-common/style';
import { CardElevation, cardDefaultProps, CardSize } from './card-props';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './card-theme-overrides';

export interface CardStyleSheetProps {
    theme: Theme;
    elevation?: CardElevation;
    size?: CardSize;
    iconPosition?: 'trailing' | 'leading';
    grabArea?: 'anywhere' | 'icon';
    dragAndDrop?: boolean;
    bordered?: boolean;
}

export interface CardCssClasses {
    root: string;
    body: string;
    footer: string;
    header: string;
    subtitle: string;
    text: string;
    title: string;
    img: string;
    icon: string;
}

export type CardStyledClasses = CssClassDefinitionsObject<keyof CardCssClasses>;

export interface CardStyleOverridesParams {
    props: DeepReadonly<CardStyleSheetProps>;
    createDefaultStyledClasses: () => CardStyledClasses;
}

export const cardStyleSheet = new StyleSheet('card', (props: CardStyleSheetProps) => {
    return createComponentClassDefinitions<CardStyleSheetProps, CardStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.card
    );
});

function createDefaultStyledClasses({
    theme,
    iconPosition,
    grabArea,
    elevation = cardDefaultProps.elevation,
    size = cardDefaultProps.size,
    dragAndDrop,
    bordered,
}: CardStyleSheetProps): CardStyledClasses {
    const cardTheme = cardSizeMap[size];
    const cardIconPadding = { sm: 22, md: 28, lg: 32 }[size];

    const titlePaddingsOverrides = iconPosition
        ? {
              '&:first-child': {
                  paddingLeft: iconPosition === 'leading' ? cardIconPadding : undefined,
                  paddingRight: iconPosition === 'trailing' ? cardIconPadding : undefined,
              },
          }
        : {};

    const icon: CssProperties = {
        position: 'absolute',
    };

    if (dragAndDrop) {
        icon.margin = cardTheme.iconPadding;
    } else {
        icon.padding = cardTheme.iconPadding;
    }

    return {
        root: {
            background: theme.elevation[elevation].background,
            border: bordered ? `1px solid ${theme.border.subtle}` : 'none',
            borderRadius: '2px',
            boxShadow: theme.elevation[elevation].shadow,
            color: theme.text.secondary,
            display: 'flex',
            flexDirection: iconPosition ? 'row-reverse' : 'column',
            position: 'relative',
            ...getCardBodyTypographyMap(theme)[size],
            ...(grabArea === 'anywhere' && dragAndDrop
                ? {
                      '&:hover': {
                          backgroundColor: theme.getSurfaceInteractionShades('primary').hover,
                      },
                  }
                : {}),
        },
        body: {
            display: 'block',
            flex: '1 1 auto',
            padding: cardTheme.bodyPadding,
        },
        footer: {
            display: 'block',
            margin: cardTheme.footerMargin,
        },
        header: {
            display: 'block',
            margin: cardTheme.headerMargin,
        },
        subtitle: {
            display: 'block',
            ...getCardBodyTypographyMap(theme)[size],
            color: theme.text.secondary,
            marginTop: cardTheme.subtitleMarginTop,
            marginBottom: cardTheme.subtitleMarginBottom,
        },
        text: {
            display: 'block',
            '&:last-child': {
                marginBottom: '0px',
            },
            margin: cardTheme.textMargin,
        },
        title: {
            display: 'block',
            margin: '0',
            color: theme.text.primary,
            ...getCardHeadingTypographyMap(theme)[size],
            marginBottom: cardTheme.titleMarginBottom,
            ...(dragAndDrop ? titlePaddingsOverrides : null),
        },
        img: {
            width: '100%',
        },
        icon,
    };
}

interface CardTheme {
    bodyPadding: CssPropertyType['padding'];
    iconPadding: CssPropertyType['padding'];
    footerMargin: CssPropertyType['margin'];
    headerMargin: CssPropertyType['margin'];
    subtitleMarginTop: CssPropertyType['marginTop'];
    subtitleMarginBottom: CssPropertyType['marginTop'];
    textMargin: CssPropertyType['margin'];
    titleMarginBottom: CssPropertyType['marginBottom'];
}

const cardSizeMap: { [size in CardSize]: CardTheme } = {
    sm: {
        bodyPadding: '12px 16px 16px 16px',
        iconPadding: '15px',
        footerMargin: '0 16px 12px',
        headerMargin: '16px 16px -16px',
        subtitleMarginTop: '-5px',
        subtitleMarginBottom: '0px',
        textMargin: '8px 0px 12px',
        titleMarginBottom: '10px',
    },
    md: {
        bodyPadding: '12px 16px 16px 16px',
        iconPadding: '15px',
        footerMargin: '0 16px 12px',
        headerMargin: '20px 16px -16px',
        subtitleMarginTop: '-5px',
        subtitleMarginBottom: '1px',
        textMargin: '12px 0px 16px',
        titleMarginBottom: '12px',
    },
    lg: {
        bodyPadding: '20px 24px 24px 24px',
        iconPadding: '23px',
        footerMargin: '0 24px 20px',
        headerMargin: '24px 24px -24px',
        subtitleMarginTop: '-5px',
        subtitleMarginBottom: '2px',
        textMargin: '20px 0px 24px',
        titleMarginBottom: '14px',
    },
};

function getCardBodyTypographyMap(theme: Theme): { [size in CardSize]: TypographyVariant } {
    return {
        sm: {
            ...theme.typography.body03,
        },
        md: {
            ...theme.typography.body02,
        },
        lg: {
            ...theme.typography.body01,
        },
    };
}

function getCardHeadingTypographyMap(theme: Theme): { [size in CardSize]: TypographyVariant } {
    return {
        sm: {
            ...theme.typography.heading05,
        },
        md: {
            ...theme.typography.heading04,
        },
        lg: {
            ...theme.typography.heading03,
        },
    };
}
