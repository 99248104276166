import { ColorName, Status, Surface } from '@gs-ux-uitoolkit-common/design-system';
import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { CloseButtonProps, CloseButtonSize, defaultCloseButtonProps } from './close-props';
import { DeepReadonly } from 'ts-essentials';
import './close-button-theme-overrides';

export interface CloseButtonStyleSheetProps extends CloseButtonProps {
    theme: Theme;
}
export interface CloseButtonCssClasses {
    root: string;
    button: string;
    icon: string;
}

export type CloseButtonStyledClasses = CssClassDefinitionsObject<keyof CloseButtonCssClasses>;

export interface CloseButtonStyleOverridesParams {
    props: DeepReadonly<CloseButtonStyleSheetProps>;
    createDefaultStyledClasses: () => CloseButtonStyledClasses;
}

export const closeStyleSheet = new StyleSheet(
    'close-button',
    (props: CloseButtonStyleSheetProps) => {
        return createComponentClassDefinitions<
            CloseButtonStyleSheetProps,
            CloseButtonStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.closeButton);
    }
);

function createDefaultStyledClasses({
    theme,
    size = defaultCloseButtonProps.size,
    surface = defaultCloseButtonProps.surface,
    emphasis = defaultCloseButtonProps.emphasis,
    link,
}: CloseButtonStyleSheetProps): CloseButtonStyledClasses {
    const buttonSize = closeButtonSizeVariants[size].buttonSize;
    const iconSize = closeButtonSizeVariants[size].iconSize;

    // Use primary minimal styles as the default for the close button
    let interactionShades = theme.getColorInteractionShades('primary', 'minimal');

    // Get the interaction shades for surfaces or status colors as well as the emphasis
    // Need to add this check for backward-compatibility since 'none' was
    // previously not a valid surface and it would change the color value for status = none
    if (surface in theme.surface && surface !== 'none') {
        interactionShades = theme.getSurfaceInteractionShades(surface as Surface);
        interactionShades.border = interactionShades.text;
    } else if (surface in theme.status || surface in theme.color) {
        interactionShades = theme.getColorInteractionShades(
            surface as Status | ColorName,
            emphasis
        );
        if (emphasis === 'bold' || emphasis === 'minimal') {
            interactionShades.border = interactionShades.text;
        }
    }

    // For link types override the text color to be primary
    if (link) {
        interactionShades.text = theme.colorScheme.primary;
        interactionShades.border = interactionShades.text;
    }

    return {
        root: {},
        button: {
            border: 0,
            background: 'transparent',
            color: interactionShades.text,
            display: 'inline-flex',
            width: buttonSize,
            height: buttonSize,
            padding: 0,
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover:not(:disabled)': {
                '#{icon}': {
                    background: interactionShades.hover,
                },
            },
            // Feb 4, 2022:  We needed to remove this because some apps, such as ION Dealbook are using
            //    Marquee Desktop / Openfin and using Chrome 80 where :focus-visible is not supported and
            //    throwing errors.  https://jira.site.gs.com/browse/UX-15248
            // '&:focus': {
            //     outline: 'none',
            //     '#{icon}': {
            //         color: focusColor,
            //     },
            // },
            // '&:focus-visible': {
            '&:focus:not(:disabled)': {
                outline: 'none',
                '#{icon}': {
                    borderColor: interactionShades.border,
                },
            },
            '&:active:not(:disabled)': {
                '#{icon}': {
                    background: interactionShades.active,
                },
            },
            '&:disabled': {
                opacity: theme.state.disabledOpacity,
                '#{icon}:hover': {
                    cursor: 'default',
                },
            },
        },
        icon: {
            border: '1px solid transparent',
            borderRadius: '2px',
            width: iconSize,
            height: iconSize,

            transition: 'background 0.15s linear',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    };
}

export type CloseButtonSizeVariant = {
    buttonSize: string;
    iconSize: string;
};

/**
 * Defines the sizes defined in the
 */
export const closeButtonSizeVariants: { [name in CloseButtonSize]: CloseButtonSizeVariant } = {
    sm: {
        iconSize: '16px',
        buttonSize: '24px',
    },
    md: {
        iconSize: '20px',
        buttonSize: '32px',
    },
    lg: {
        iconSize: '24px',
        buttonSize: '44px',
    },
};
