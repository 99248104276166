import React, { memo } from "react";
import PropTypes from "prop-types";
import translator from "../../../services/translator";
import "./index.scss";

const { translate: t } = translator;

const HelpGuideTray = ({
  selectedRowData,
  handlePreviousBtn,
  handleNextBtn,
  closeHelpGuide,
}) => {
  const { section, fieldName, content } = selectedRowData;
  return (
    <div data-testid="help-guide-container" className="helpguide">
      <div className="helpguide__header">
        <span>{`${t(section)} : ${fieldName}`}</span>
        <button
          data-testid="help-guide-close-button"
          className="helpguide__closeIcon"
          onClick={closeHelpGuide}
        />
      </div>
      <div className="helpguide__content">{t(content)}</div>
      <div className="helpguide__navigation">
        <button onClick={() => handlePreviousBtn(fieldName)}>
          <span className="helpguide__navigation-backwardIcon" />
          {t("tkPrevious")}
        </button>
        <button onClick={() => handleNextBtn(fieldName)}>
          {t("tkNext")}
          <span className="helpguide__navigation-forwardIcon" />
        </button>
      </div>
    </div>
  );
};

HelpGuideTray.propTypes = {
  selectedRowData: PropTypes.object,
  handlePreviousBtn: PropTypes.func,
  handleNextBtn: PropTypes.func,
  closeHelpGuide: PropTypes.func,
};

export default memo(HelpGuideTray);
