import { PopoverBaseProps } from '@gs-ux-uitoolkit-common/popover-base';
import { Size } from '@gs-ux-uitoolkit-common/design-system';

export interface TooltipProps extends PopoverBaseProps {
    /**
     * Size of the tooltip
     */
    size?: TooltipSize;

    /**
     * Style classes to override.
     */
    classes?: TooltipOverrideClasses;
}

export type TooltipSize = Extract<Size, 'xs' | 'sm' | 'md' | 'lg'>;

export const defaultTooltipProps: DefaultTooltipProps = {
    size: 'md',
};

export type DefaultTooltipProps = Required<Pick<TooltipProps, 'size'>>;

export type TooltipOverrideClasses = PopoverBaseProps['classes'] &
    Partial<Record<'label' | 'contentInner', string>>;
