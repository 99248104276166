import { useCallback } from 'react';
import endPointsMapper from '../../../../../configs/endPointsMapper';
import useUserMaintenance from '../../context';
import useFetch from '../useFetch';
import useLoader from '../useLoader';
import { SWITCH_USER_ACCOUNT_STATUS } from '../../actions';
import useSnackbar from '../useSnackbar';
import translator from '../../../../../services/translator';

const { translate: t } = translator;

const useSaveUserAccountStatus = () => {
  const { dispatch: ctxDispatch } = useUserMaintenance();
  const { openSnackbar } = useSnackbar();

  const onSuccess = useCallback(payload => {
    ctxDispatch({ type: SWITCH_USER_ACCOUNT_STATUS, payload });
    openSnackbar({ type: 'success', message: t('tkChangesSavedSuccessfully') });
  }, []);

  const onError = useCallback(() => {
    openSnackbar({ type: 'error', message: t('tkAnErrorPleaseTryAgain') });
  }, []);

  const endpoint = endPointsMapper.FETCH_USER_ACCOUNT_STATUS;
  const { doFetch, loading } = useFetch(endpoint, onSuccess, onError);
  useLoader(loading);

  return {
    onSaveUserAccountStatus: doFetch,
  };
};

export default useSaveUserAccountStatus;
