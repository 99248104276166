import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './dropdown-theme-overrides';

export interface DropdownStyleSheetProps {
    theme: Theme;
}

export interface DropdownCssClasses {
    root: string;
}

export type DropdownStyledClasses = CssClassDefinitionsObject<keyof DropdownCssClasses>;

export interface DropdownStyleOverridesParams {
    props: DeepReadonly<DropdownStyleSheetProps>;
    createDefaultStyledClasses: () => DropdownStyledClasses;
}

export const dropdownStyleSheet = new StyleSheet('dropdown', (props: DropdownStyleSheetProps) => {
    return createComponentClassDefinitions<DropdownStyleSheetProps, DropdownStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.dropdown
    );
});

function createDefaultStyledClasses(_props: DropdownStyleSheetProps): DropdownStyledClasses {
    return {
        root: {
            display: 'inline-block',
        },
    };
}
