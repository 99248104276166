import { connect } from 'react-redux';
import CreatePin from '../../components/app/CreatePin';
import { labelSelector, usernameSelector } from '../../selectors/app';
import {
  nextStepSelector, pingConfigSelector, submitCredentialsErrorSelector,
  pinValidationListSelector, isShowMosaicHelpSelector, isLoginInProgressSelector
} from '../../selectors/pages/login';
import { createNewPin, isShowMosaicHelp } from '../../actions/page/login';

const mapStateToProps = (state) => ({
  labels: labelSelector(state),
  pinValidationList : pinValidationListSelector(state),
  submitCredentialsError : submitCredentialsErrorSelector(state),
  nextStep: nextStepSelector(state),
  pingConfig: pingConfigSelector(state),
  isShowMosaicHelpFlag: isShowMosaicHelpSelector(state),
  userId: usernameSelector(state),
  isLoginInProgress: isLoginInProgressSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  createNewPin: (data) => dispatch(createNewPin(data)),
  isShowMosaicHelp: flag => dispatch(isShowMosaicHelp(flag)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePin);
