import {put} from 'redux-saga/effects';
import errorTypes from '../../error/errorType';
import {addPageContext} from '../../actions/page/index';
import {addAppContext} from '../../actions/app/index';
import {SHOW_ERROR_ON} from '../../constants/pageConstants';
import { addTradeApprovalData } from '../../actions/page/tradeActivity/approvals';

export default function* reportError(error, isPageLoad, showErrorOn = SHOW_ERROR_ON.PAGE, errorMessage) {// By default error will be on page
  const {code, componentType } = error;
  const type = componentType ? { componentType } : {};
  switch(code) {
    case errorTypes.UNKNOWN_SERVICE_ERROR:
    case errorTypes.AUTHORIZATION:
    case errorTypes.SYSTEM_FAILURE:
    case errorTypes.BUSINESS_FAILURE:
    case errorTypes.TRADE_ACTIVITY_STATUS_FAIL:
    case errorTypes.TRADE_APPROVALS_FAIL:
      /*
      * Always return errorType === UNKNOWN_SERVICE_ERROR in case of snackbar error irrespective of
      * what errorTypes received from server
      */
      yield put(addPageContext({isPageInErrorMode: true,
        errorObject: (showErrorOn === SHOW_ERROR_ON.SNACKBAR || showErrorOn === SHOW_ERROR_ON.TRADE_REJECTION_MODAL) ?
          {showErrorOn, errorCode: errorTypes.UNKNOWN_SERVICE_ERROR, errorMessage, ...type}
          : {showErrorOn, errorCode: code, errorMessage, ...type}}));
      break;
    case errorTypes.TRADE_APPROVALS_DUPLICATE: {
      yield put(addPageContext({
        isPageInErrorMode: true,
        errorObject: {showErrorOn, errorCode: code, errorMessage, ...type}
      }));
      break;
    }
    case errorTypes.VIEW_DOES_NOT_EXIST:
      yield put(addPageContext({'duplicateViewName': {errorMessage :'tkCopyPort28'}}));
      break;
    case errorTypes.DUPLICATE_VIEW_NAME:
      yield put(addPageContext({'duplicateViewName': {errorMessage :'tkCopyPort27'}}));
      break;
    case errorTypes.TRADE_APPROVAL_RETRIEVE_FAIL:
      yield put(addTradeApprovalData({tradeApprovalsData: {error}}));
      break;
    case errorTypes.TOO_MUCH_DATA:
      break;
    default:
      yield put(addPageContext({isPageInErrorMode: true,
        errorObject: {showErrorOn,  errorCode: errorTypes.UNKNOWN_SERVICE_ERROR, errorMessage, ...type}}));
      break;
  }
}


export function* reportGlobalError(error, isPageLoad, showErrorOn = SHOW_ERROR_ON.SNACKBAR, errorMessage) {// By default error will be on page
  const {code} = error;
  switch(code){
    case errorTypes.UNKNOWN_SERVICE_ERROR:
    case errorTypes.AUTHORIZATION:
    case errorTypes.SYSTEM_FAILURE:
    case errorTypes.BUSINESS_FAILURE:
    case errorTypes.TRADE_ACTIVITY_STATUS_FAIL:
    case errorTypes.TRADE_APPROVALS_FAIL:
      /*
      * Always return errorType === UNKNOWN_SERVICE_ERROR in case of snackbar error irrespective of
      * what errorTypes received from server
      */
      yield put(addAppContext({isPageInErrorMode: true,
        errorObject: (showErrorOn === SHOW_ERROR_ON.SNACKBAR) ?
          {showErrorOn, errorCode: errorTypes.UNKNOWN_SERVICE_ERROR, errorMessage}
          : {showErrorOn, errorCode: code, errorMessage}}));
      break;
    default:
      yield put(addAppContext({isPageInErrorMode: true,
        errorObject: {showErrorOn,  errorCode: errorTypes.UNKNOWN_SERVICE_ERROR, errorMessage}}));
      break;
  }
}

