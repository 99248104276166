import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Button from "../../../../../../components/core/Button";
import Checkbox from "../../../../../../components/core/CheckBox";
import Link from "../../../../../../components/core/Link";
import {Conditional} from "../../../../../../components/core/Conditional";
import {ThresholdLabelRenderer} from "../../../../../../modules/FundTracker/components/ThresholdLabelRenderer";
import {ThresholdGroupLabelRenderer} from "../../../../../../modules/FundTracker/components/ThresholdGroupLabelRenderer";

const getPaddingLeft = (level, indentationPadding) => {
  return level * indentationPadding;
};

const getCustomLabelRenderer = (isParentWithNodes) => {
  return isParentWithNodes ?
    ThresholdGroupLabelRenderer : ThresholdLabelRenderer;
};

export const ItemRenderer = ({
  level,
  node,
  indentationPadding,
  hasAtleastOneParentNode,
  onToggleSelectThresholdItem,
  onViewThreshold,
  onDeleteThreshold,
  onExpandCollapseThresholdGroup
}) => {
  const {nodeId, parentId, items = [], open, isIntermediateChecked, emailNotificationEnabled} = node;
  const isParentWithNodes = !parentId && items.length > 0;
  const additionalPadding = {'paddingLeft': getPaddingLeft(level, indentationPadding)};
  const addPaddingForEmptyToggle = (level === 0 && !isParentWithNodes && hasAtleastOneParentNode);
  const expandCollapseThresholdGroup = () => onExpandCollapseThresholdGroup(nodeId);
  const toggleSelectThresholdItem = (val, isChecked) => {
    onToggleSelectThresholdItem(val, isChecked, parentId);
  };
  const customLabelRendererProps = parentId ? {...node, showThresholdDetails: false} : {...node};

  return (
    <div style={additionalPadding} className="thresholds__item-wrapper">
      <div className="thresholds__item-select">
        <div className={cn({'thresholds__item-toggle': addPaddingForEmptyToggle})}>
          <Conditional condition={isParentWithNodes}>
            <Link
              customClass={cn({'expand': open}, {'collapse': !open})}
              handleClick={expandCollapseThresholdGroup}
            />
          </Conditional>
        </div>
        <Checkbox
          id={`thresholdItem-item-${nodeId}`}
          customLabelRenderer={getCustomLabelRenderer(isParentWithNodes)}
          customLabelRendererProps={customLabelRendererProps}
          changeHandler={toggleSelectThresholdItem}
          isChecked={emailNotificationEnabled}
          isIntermediateChecked={isIntermediateChecked}
          value={nodeId}
        />
      </div>
      <Conditional condition={!isParentWithNodes}>
        <div style={{display: 'flex'}}>
          <Button
            iconClass="thresholds__view-btn-icon"
            customClass='button-secondary-small'
            extraClass='thresholds__action-button'
            clickHandler={() => {
              onViewThreshold(node)
            }}
          />
          <Button
            iconClass="thresholds__delete-btn-icon"
            customClass='button-primary-small'
            extraClass='thresholds__action-button'
            clickHandler={() => {
              onDeleteThreshold(node)
            }}
          />
        </div>
      </Conditional>
    </div>
  );
};

ItemRenderer.propTypes = {
  level: PropTypes.number,
  node: PropTypes.object,
  indentationPadding: PropTypes.number,
  onToggleSelectThresholdItem: PropTypes.func,
  onViewThreshold: PropTypes.func,
  onDeleteThreshold: PropTypes.func,
  onExpandCollapseThresholdGroup: PropTypes.func,
  hasAtleastOneParentNode: PropTypes.bool
};
