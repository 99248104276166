import { Component } from 'react';
import { CustomSort } from '../../custom-sort-plugin';
import { ColumnValueListState } from '../../../../redux/datagrid-state';
import { ListGroupItem, ListGroup } from '@gs-ux-uitoolkit-react/list-group';
import { DragAndDropStateUpdateEvent } from '@gs-ux-uitoolkit-react/drag-and-drop';

export interface CustomSortDragItemProps {
    data: CustomSort;
    columnValueList: ColumnValueListState[] | null;
    selectedColumnValues: string[];
    updateEditedConfigItem: (newData: Partial<CustomSort>) => void;
}

/**
 * Drag and drop for preview panel
 */
export class CustomSortDragItemComponent extends Component<CustomSortDragItemProps> {
    public render() {
        return (
            <ListGroup
                dragAndDrop
                onStateUpdate={this.onStateUpdate}
                droppableId="droppableColumn"
                size="sm"
                className={'gs-uitk-toolbar-layout-toolbar-dnd-box'}
            >
                {this.props.data.dataList.map((value, index) => (
                    <ListGroupItem
                        key={`draggable-${value + index}`}
                        draggableConfig={{
                            index: index,
                            draggableId: `draggable-${value}`,
                            iconPosition: 'leading',
                            grabArea: 'anywhere',
                            dragIconVisibility: 'visible',
                        }}
                    >
                        {value}
                    </ListGroupItem>
                ))}
            </ListGroup>
        );
    }

    private onStateUpdate = (result: DragAndDropStateUpdateEvent) => {
        const { destination, source } = result;

        if (!destination) return;
        if (destination.droppableId === source.droppableId && destination.index === source.index)
            return;

        const newAvailableList = [...this.props.data.dataList];

        const replaced = newAvailableList.splice(source.index, 1);

        newAvailableList.splice(destination.index, 0, replaced[0]);

        const newDisplayedList = newAvailableList.filter(itemId =>
            this.props.data.dataList.some(displayed => itemId === displayed)
        );

        this.props.updateEditedConfigItem({ dataList: newDisplayedList });
    };
}
