export default {
  FETCH_PORTFOLIO_DETAILS: {
    'path': '/api/v1/portfolios/details',
    'method': 'POST',
    'parameters': {
      'pageContext.groupBy': 'groupBy',
      'pageContext.filterBy': 'filterBy',
      'preferences.portfolio.includeZeroPositions': 'includeZeroPositions'
    }
  },
}
