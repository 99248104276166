import {
    Expression,
    ExpressionMode,
    ExpressionQuery,
    globalHelper,
    QuickFilter,
} from '@gs-ux-uitoolkit-common/datacore';
import { Col, Row } from '@gs-ux-uitoolkit-react/layout';
import { Tab, Tabs } from '@gs-ux-uitoolkit-react/tabs';
import { Component } from 'react';
import * as Redux from 'redux';
import { connect } from 'react-redux';
import {
    GridColumn,
    GetFormattedValueFromColumn,
    DatasourceType,
} from '../../grid-wrappers/grid-wrapper';
import {
    ColumnValueListState,
    DataGridState,
    BasicExpressionColumnValueState,
} from '../../redux/datagrid-state';
import { experiencedExpressionStyleSheet } from '../../style/component/experienced-expression-stylesheet';
import { BasicExpression } from './component/basic-expression';
import { ExperiencedExpression } from './component/experienced-expression';
import { GridGetBasicExpressionColumnValueList } from '../../redux/actions/grid-action';
import { ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';

/**
 * Props for the expression builder
 */
export interface ExpressionBuilderProps extends ExpressionBuilderComponentOwnProps {
    columnValueList: ColumnValueListState[];
    quickFilterList: QuickFilter[];
    basicExpressionColumnValues: BasicExpressionColumnValueState[];
    isDra: boolean;
    getFormattedValueFromColumn: GetFormattedValueFromColumn;
    getColumnValues: (columnId: string) => void;
}

export interface ExpressionBuilderComponentOwnProps {
    columns: GridColumn[];
    expression: Expression;
    /**
     * the expression should always be a new instance of the expression
     * i.e. we've cloned it
     */
    onChangeExpression: (expression: Expression) => void;
    gridGetColumnValueList: (columnId: string) => void;
    /**
     * Reset the column valueList in the store
     */
    gridResetColumnValueList: (columnId: string | null) => void;
    currentQuickFilter?: QuickFilter;
    showRowGroups?: boolean;
}

export class ExpressionBuilderComponent extends Component<ExpressionBuilderProps> {
    public componentWillUnmount() {
        experiencedExpressionStyleSheet.unmount(this);
    }
    public render() {
        return (
            <ThemeConsumer>
                {theme => {
                    const cssClasses = experiencedExpressionStyleSheet.mount(this, { theme });
                    return (
                        <div className="expression-builder-root">
                            <Row>
                                <Col>
                                    <Tabs
                                        activeTabKey={
                                            this.props.expression.mode || ExpressionMode.Basic
                                        }
                                        onSelect={this.onModeChange}
                                    >
                                        <Tab tabKey={ExpressionMode.Basic} title="Basic">
                                            <BasicExpression
                                                onChangeExpressionQuery={this.onExpressionChange}
                                                expressionQuery={this.props.expression.query}
                                                columns={this.props.columns}
                                                columnValueList={this.props.columnValueList}
                                                gridGetColumnValueList={
                                                    this.props.gridGetColumnValueList
                                                }
                                                gridResetColumnValueList={
                                                    this.props.gridResetColumnValueList
                                                }
                                                quickFilterList={this.props.quickFilterList}
                                                currentQuickFilter={this.props.currentQuickFilter}
                                                getFormattedValueFromColumn={
                                                    this.props.getFormattedValueFromColumn
                                                }
                                                showRowGroups={this.props.showRowGroups}
                                                basicExpressionColumnValues={
                                                    this.props.basicExpressionColumnValues
                                                }
                                                getColumnValues={this.props.getColumnValues}
                                                isDra={this.props.isDra}
                                            />
                                        </Tab>
                                        <Tab
                                            tabKey={ExpressionMode.Experienced}
                                            title="Experienced"
                                        >
                                            <div className={cssClasses.root}>
                                                <ExperiencedExpression
                                                    onChangeExpressionQuery={
                                                        this.onExpressionChange
                                                    }
                                                    expressionQuery={this.props.expression.query}
                                                    columns={this.props.columns}
                                                    columnValueList={this.props.columnValueList}
                                                    gridGetColumnValueList={
                                                        this.props.gridGetColumnValueList
                                                    }
                                                    gridResetColumnValueList={
                                                        this.props.gridResetColumnValueList
                                                    }
                                                    quickFilterList={this.props.quickFilterList} // Not currently used in experienced mode
                                                    currentQuickFilter={
                                                        this.props.currentQuickFilter
                                                    } // Not currently used in experienced mode
                                                    getFormattedValueFromColumn={
                                                        this.props.getFormattedValueFromColumn
                                                    }
                                                    showRowGroups={this.props.showRowGroups}
                                                />
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </Col>
                            </Row>
                        </div>
                    );
                }}
            </ThemeConsumer>
        );
    }

    private onExpressionChange = (query: ExpressionQuery) => {
        this.props.onChangeExpression({ query, mode: this.props.expression.mode });
    };

    private onModeChange = (mode: ExpressionMode) => {
        this.props.onChangeExpression({
            mode,
            query: globalHelper.cloneObject(this.props.expression.query),
        });
    };
}

const mapStateToProps = (state: DataGridState, ownProps: ExpressionBuilderComponentOwnProps) => {
    return {
        columns: ownProps.columns,
        columnValueList: state.grid.columnValueList,
        expression: ownProps.expression,
        quickFilterList: state.quickFilter.configItemList,
        basicExpressionColumnValues: state.grid.basicExpressionColumnValues,
        gridGetColumnValueList: ownProps.gridGetColumnValueList,
        gridResetColumnValueList: ownProps.gridResetColumnValueList,
        onChangeExpression: ownProps.onChangeExpression,
        isDra:
            state.systemConfiguration.gridWrapperConfiguration.datasourceConfiguration
                ?.datasourceType === DatasourceType.DRA,
    };
};

const mapDispatchToProps = (dispatch: (action: Redux.Action) => void) => {
    return {
        getColumnValues: (columnId: string) =>
            dispatch(GridGetBasicExpressionColumnValueList(columnId)),
    };
};

export const ExpressionBuilder = connect(
    mapStateToProps,
    mapDispatchToProps
)(ExpressionBuilderComponent);
