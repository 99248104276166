import React, {useEffect, useState} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import translator  from '../../../services/translator';
import Dropdown from '../../../components/core/Dropdown';
import FileDownloadModal from '../FileDownload';
import DownloadButton from '../../../components/core/DownloadButton/DownloadButton';
import GearButton from '../../../components/core/GearButton';
import PopOver from '../../../components/core/PopOver/index';
import SwitchButton from '../../../components/core/SwitchButton/index';
import { Conditional } from '../../../components/core/Conditional';
import FilterButton from '../../../components/core/FilterButton';
import {isInvalidFilter} from '../../../utils/validators/validators';

import {
  COLUMN_COLID, GROUP_COLUMN_CONFIG, MAX_VIEWS, MODEL_ACTION,
  PORTFOLIO_FILTER_STATE, SORT
} from '../../../constants/pageConstants';
import { getViewChangeAnalyticsData, dispatchAnalytics, getZeroBalanaceAccount
  , getExportViewAnalyticsData, getExpandCollapseAnalyticsData, getGroupChangeAnalyticsData } from '../analytics';

import {editAppPreferences, toggleZBP, toggleCollapsedAllState} from '../../../actions/preferences/index';
import {
  openCustomViewModal, viewChange, groupChange,
  startExportToExcel, toggleFilterVisibility
} from '../../../actions/page/portfolio';
import {
  openDownloadModal
} from '../../../actions/app/reports/index';
import {
  currentViewSelector, dropdownViewNamesSelector, disableFileDownload, gridHeader, groupByColumns,
  groupByFieldNew,
  includeZBPSelector,
  selectCollapseState,
  getCreateCustomViewCTALabel,
  selectedGroup, startExcelExport,
  viewName, getFilters
} from '../../../selectors/pages/portfolio';
import {labelSelector} from '../../../selectors/app';
import {allowPDFDownloadFeatureSelector} from '../../../selectors/global';

const {translate: t} = translator;

export const ControlPanel = (props) => {
  const {dispatchViewChange, dispatchGroupChange, collapseState, groupByField,
    dispatchToggleZeroBalanceAccount, dispatchToggleGroupCollapse,
    includeZeroBalancePositions, dispatchViewEdit, dispatchViewAdd,
    customViewCTALabel, header
  } = props;
  const {groupByColumns = [], views = [], currentView, disableFileDownload,
    dispatchOpenFileDownloadModal, dispatchDownloadExcel, allowPDFDownload} = props;

  const [filterVisibilityState, setFilterVisibilityState] = useState(false);
  const [isActiveZeroBalanceAccount, setIsActiveZeroBalanceAccount] = useState(includeZeroBalancePositions);
  const [isActiveGroupCollapse, setIsActiveGroupCollapse] = useState(collapseState);
  const [isFilterInvalid] = useState(isInvalidFilter);

  useEffect(() => {
    if(groupByField !== 'none') setIsActiveGroupCollapse(collapseState); else setIsActiveGroupCollapse(false);
  }, [groupByField]);

  const popoverOffset = {
    x: 0,
    y: 14
  };


  const handleGroupChange = (value, selectedItems) => {
    if(isFilterInvalid){
      return;
    }

    const activeView = cloneDeep(currentView);
    if(value === 'none'){
      activeView.columns = activeView.columns.filter(column => column.colId !== COLUMN_COLID.GROUP);
    } else {
      const index = activeView.columns.findIndex(column => column.colId === COLUMN_COLID.GROUP);
      if(index !== 1){
        const tradeColumn = activeView.columns.findIndex(column => column.colId === COLUMN_COLID.TRADE);
        activeView.columns.splice(tradeColumn + 1, 0, cloneDeep(GROUP_COLUMN_CONFIG));
      }
    }
    activeView.groupBy = value;
    activeView && activeView.columns.forEach((column, i) => {
      if(column.sort !== SORT.ASC && column.sort !== SORT.DESC){
        column.sort = 'none';
      }
      column.columnIndex = i;
    });

    dispatchGroupChange({groupBy: value, activeView}, selectedItems.label);
  };

  const handleToggleFilterVisibility = () => {
    const state = !filterVisibilityState;
    const filterVisibility = state ? PORTFOLIO_FILTER_STATE.VISIBLE : PORTFOLIO_FILTER_STATE.HIDDEN;
    props.toggleFilterVisibility({filterVisibility, fetchNewData: !!props.filtersData.length });
    setFilterVisibilityState(state);
  };

  const handleZeroBalanceAccountToggle = (state) => {
    // call dispatch action here to send server call
    dispatchToggleZeroBalanceAccount(state);
    setIsActiveZeroBalanceAccount(state);
  };

  const handleExpandCollapseToggle = (state) => {
    // call dispatch action here to send server call
    dispatchToggleGroupCollapse(state);
    setIsActiveGroupCollapse(state);


    // isResetGroupCollapseBtnState && setIsResetGroupCollapseBtnState(false);// setIsActiveGroupCollapse(state);
  };


  const handleViewChange = (selectedValue, selectedItems) => {
    // this.setState({resetBtnState: true});
    // this.props.onViewChange({ id: value});
  //  setIsResetGroupCollapseBtnState(true);
    dispatchViewChange(selectedItems.label, selectedItems, header);
  };

  const handleViewEdit = () => {
    dispatchViewEdit(currentView);
  };

  const handleViewAdd = (items) => {
    const item = items[0];
    dispatchViewAdd(item);
  };

  const handleOpenFileDownloadModal = (props) => {
    dispatchOpenFileDownloadModal(props);
  };

  const handleDownloadExcel = () => {
    dispatchDownloadExcel(props);
  };

  const handleDownload = () => {
    if (allowPDFDownload) {
      handleOpenFileDownloadModal();
    } else {
      handleDownloadExcel();
    }
  };


  return (
    <Conditional condition={views.length > 0}>
      <div className="portfolio-container__descRow1">
        <div className="portfolio-container__action l-margin-left">
          <Dropdown
            id="portfolio-groups"
            onSelect={handleGroupChange}
            options={groupByColumns}
            placeholder={t('tkGroupByColonCaps')}
            prepender={t('tkGroupByColonCaps')}
          />
        </div>
        <div className="portfolio-container__action l-margin-left">
          <Dropdown
            id="portfolio-view"
            onSelect={handleViewChange}
            onFooterCTAClick={handleViewAdd}
            footerCTA={true}
            footerCTAText={customViewCTALabel}
            footerCTADisabled={customViewCTALabel === t(MAX_VIEWS.name)}
            options={views}
            placeholder={t('tkViewColon')}
            prepender={t('tkViewColon')}
          />
        </div>
        <div className="fundfinder-container__action l-margin-left">
          <FilterButton
            onClick={handleToggleFilterVisibility}
            visibilityState={filterVisibilityState}
          />
        </div>
        <div className="portfolio-container__action">
          <div className="portfolio-container__action-child">
            <GearButton onClick={handleViewEdit} />
          </div>
          <div className="portfolio-container__action-child">
            <DownloadButton
              disabled={disableFileDownload}
              onClick={handleDownload}
              onKeyPress={handleDownload} />
            <FileDownloadModal />
          </div>
          <div className="portfolio-container__action-child">
            <PopOver overlayOffset={popoverOffset} buttonClass="transparent-button icon-more-menu">
              <div className="portfolio-container__action-dropdown-menu">
                <div className="portfolio-container__action-dropdown-item">
                  <span className="portfolio-container__action-zero">
                    <span className="portfolio-container__action-name">{t('tkCollapseAll')}</span>
                    <SwitchButton
                      active={isActiveGroupCollapse}
                      onClick={handleExpandCollapseToggle}
                      disabled={groupByField === 'none'}
                      />
                  </span>
                </div>
                <div className="portfolio-container__action-dropdown-item">
                  <span className="portfolio-container__action-zero">
                    <span className="portfolio-container__action-name">
                      {t('tkShowZeroBalanceFunds')}
                    </span>
                    <SwitchButton
                      active={isActiveZeroBalanceAccount}
                      onClick={handleZeroBalanceAccountToggle} />
                  </span>
                </div>
              </div>
            </PopOver>
          </div>
        </div>
      </div>
    </Conditional>
  );
};


ControlPanel.propTypes = {
  groupByField: PropTypes.string,
  customViewCTALabel: PropTypes.string,
  groupByColumns: PropTypes.array,
  views: PropTypes.array,
  collapseState: PropTypes.bool,
  includeZeroBalancePositions:PropTypes.bool,
  disableFileDownload:PropTypes.bool,
  currentView: PropTypes.object,
  toggleFilterVisibility: PropTypes.func,
  dispatchViewChange: PropTypes.func,
  dispatchGroupChange: PropTypes.func,
  dispatchViewEdit: PropTypes.func,
  dispatchToggleGroupCollapse: PropTypes.func,
  dispatchToggleZeroBalanceAccount: PropTypes.func,
  dispatchViewAdd: PropTypes.func,
  header: PropTypes.array,
  filtersData: PropTypes.array,
  dispatchOpenFileDownloadModal: PropTypes.func,
  dispatchDownloadExcel: PropTypes.func,
  allowPDFDownload: PropTypes.bool

};

export const mapStateToProps = (state) => ({
  header: gridHeader(state),
  disableFileDownload: disableFileDownload(state),
  groupByField: groupByFieldNew(state),
  viewName: viewName(state),
  groupByColumns: groupByColumns(state),
  collapseState: selectCollapseState(state),
  selectedGroup: selectedGroup(state),
  currentView: currentViewSelector(state),
  labels: labelSelector(state),
  views: dropdownViewNamesSelector(state),
  startExcelExport: startExcelExport(state),
  customViewCTALabel: getCreateCustomViewCTALabel(state),
  includeZeroBalancePositions: includeZBPSelector(state),
  filtersData: getFilters(state),
  allowPDFDownload: allowPDFDownloadFeatureSelector(state)

});

export const mapDispatchToProps = (dispatch) => (
  {
    dispatchToggleZeroBalanceAccount: data => {
      dispatch(toggleZBP(data));
      dispatch(editAppPreferences());
      dispatchAnalytics(dispatch, getZeroBalanaceAccount(data));
    },
    dispatchToggleGroupCollapse: state =>  {
      dispatch(toggleCollapsedAllState(state));
      dispatch(editAppPreferences());
      dispatchAnalytics(dispatch,  getExpandCollapseAnalyticsData(state));
    },


    dispatchGroupChange: (data, columnName = '') => {
      dispatch(groupChange(data));
      dispatchAnalytics(dispatch, getGroupChangeAnalyticsData(columnName));
    },

    dispatchViewChange: (viewName, data, column) => {
      dispatch(viewChange({id: data.nodeId}));
      dispatchAnalytics(dispatch, getViewChangeAnalyticsData(column, viewName));
    },

    dispatchViewEdit: (data) => {
      const params = {'modelActiveMode': MODEL_ACTION.EDIT, 'selectedViewId': data.id};
      dispatch(openCustomViewModal(params));
    },

    dispatchViewAdd: (data) => {
      const params = {'modelActiveMode': MODEL_ACTION.CREATE, 'selectedViewId': data.nodeId};
      dispatch(openCustomViewModal(params));
    },

    dispatchDownloadExcel: (props) => {
      dispatch(startExportToExcel());
      dispatchAnalytics(dispatch, getExportViewAnalyticsData(props.header, props.currentView.label, 'Excel'));
    },
    dispatchOpenFileDownloadModal: (props) => {
      dispatch(openDownloadModal(props));
    },
    toggleFilterVisibility: (obj) => dispatch(toggleFilterVisibility(obj)),
  });

export default connect(mapStateToProps, mapDispatchToProps)(ControlPanel);
