import { useEffect, useCallback } from 'react';
import useUserMaintenance from '../../context';
import useFetch from '../useFetch';
import useLoader from '../useLoader';
import useSnackBar from '../useSnackbar';
import endPointsMapper from '../../../../../configs/endPointsMapper';
import translator from '../../../../../services/translator';
import { SELECTED_ORGANIZATION } from '../../actions';

const { translate: t } = translator;

function useOrganizationAccounts() {
  const {
    state: {
      userDetails: { isMosaicInternal },
      selectedOrganization,
    },
    dispatch,
    storeValues: { isInternalUser },
  } = useUserMaintenance();
  const { organizationName = t('tkOrganization') } = selectedOrganization;
  const { openSnackbar } = useSnackBar();

  const onAutoCompleteError = useCallback(() => {
    openSnackbar({ type: 'error', messageId: 'organizationAutoCompleteErrorMsg', message: t('tkOrganizationAutoCompleteErrorMsg') });
  }, []);

  const {
    loading: autoCompleteLoading,
    doFetch: fetchAutoCompleteData,
    data: autoComplete,
  } = useFetch(endPointsMapper.FETCH_USER_SEARCH, undefined, onAutoCompleteError);

  const { doFetch: fetchOrganizationAccounts, loading, data, error } = useFetch(endPointsMapper.FETCH_ORGANIZATION_ACCOUNTS);

  useEffect(() => {
    const allowOrganizationSearch = isInternalUser && isMosaicInternal;
    if (error) {
      openSnackbar({ type: 'error', message: t('tkOrganizationServerErrorMsg', organizationName) });
      allowOrganizationSearch && dispatch({ type: SELECTED_ORGANIZATION, payload: {} });
    }
  }, [error]);

  useLoader(loading);

  return { fetchAutoCompleteData, autoCompleteLoading, autoComplete, fetchOrganizationAccounts, loading, data };
}

export default useOrganizationAccounts;
