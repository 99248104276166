import { Placement } from '@gs-ux-uitoolkit-common/design-system';

// TODO: Add 'loading' status
export type BadgeOverlayPlacement = Extract<
    Placement,
    'top-right' | 'bottom-right' | 'top-left' | 'bottom-left'
>;
export const badgeOverlayPlacements: BadgeOverlayPlacement[] = [
    'top-right',
    'bottom-right',
    'top-left',
    'bottom-left',
];
