import { FunctionComponent, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import {
    dropdownButtonStyleSheet,
    getDropdownButtonRootClassNames,
    getDropdownButtonGroupClassNames,
    getDropdownButtonSingleClassNames,
    getDropdownButtonSplitClassNames,
    getDropdownButtonToggleClassNames,
    getDropdownButtonSingleClassOverrides,
    getDropdownButtonSplitClassOverrides,
    getToggleButtonDisabledState,
} from '@gs-ux-uitoolkit-common/dropdown';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';

import {
    Button,
    ButtonGroup,
    buttonTypes,
    ButtonType,
    ButtonSize,
    buttonStatuses,
    ButtonProps,
    defaultButtonProps,
} from '@gs-ux-uitoolkit-react/button';
import { splitDataAttributes } from '@gs-ux-uitoolkit-react/shared';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { DropdownButtonProps } from '.';
import { DropdownContext } from './dropdown-context';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';

/**
 * Due to https://github.com/microsoft/web-build-tools/issues/1050, we need
 * ButtonType imported in this file, even though it is only used implicitly in the propTypes `...Button.propTypes,`.
 * This _uses_ ButtonType so it is not accidentally removed.
 */
const buttonTypesFixed: ButtonType[] = buttonTypes;

/**
 * DropdownButton is a sub-component of the Dropdown, used to trigger the menu.
 */
export const DropdownButton: FunctionComponent<DropdownButtonProps> = memo(
    (props: DropdownButtonProps) => {
        const {
            splitToggle,
            toggleStatus,
            toggleDisabled,
            onToggleClick,
            hideToggleIcon,
            status,
            elementRef,
            toggleElementRef,
            children,
            className,
            classes,
            style,
            title,
            id,
            autoFocus,
            name,
            value,
            onClick,
            onMouseDown,
            onMouseUp,
            buttonAttrs,
            toggleButtonAttrs,
            ...otherProps
        } = props;
        const { split: dataAttributes, remaining: sharedButtonProps } =
            splitDataAttributes(otherProps);

        const theme = useTheme();
        const dropdownContext = useContext(DropdownContext);
        const finalSize = getSize({ propsSize: props.size, contextSize: dropdownContext.size });
        const cssClasses = useStyleSheet(dropdownButtonStyleSheet, {
            theme,
            size: finalSize || defaultButtonProps.size,
        });

        const rootClasses = getDropdownButtonRootClassNames({
            cssClasses,
            overrideClasses: classes,
            className,
        });

        const buttonGroupClasses = getDropdownButtonGroupClassNames({
            cssClasses,
            overrideClasses: classes,
        });

        const buttonSingleClasses = getDropdownButtonSingleClassNames({
            cssClasses,
            overrideClasses: classes,
        });

        const buttonSplitClasses = getDropdownButtonSplitClassNames({
            cssClasses,
            overrideClasses: classes,
        });

        const buttonToggleClasses = getDropdownButtonToggleClassNames({
            cssClasses,
            overrideClasses: classes,
        });

        const buttonSingleClassOverrides = getDropdownButtonSingleClassOverrides({
            cssClasses,
        });

        const buttonSplitClassOverrides = getDropdownButtonSplitClassOverrides({
            cssClasses,
        });

        const dropdownIconName = dropdownContext.menuVisible ? 'expand-less' : 'expand-more';

        return (
            <span
                {...dataAttributes}
                data-gs-uitk-component="dropdown-button"
                className={rootClasses}
                style={style}
                title={title}
                id={id}
            >
                <ButtonGroup className={buttonGroupClasses} data-cy="gs-uitk-dropdown-button-group">
                    {splitToggle ? (
                        <>
                            {/* eslint-disable jsx-a11y/no-autofocus */}
                            <Button
                                buttonAttrs={buttonAttrs}
                                {...sharedButtonProps}
                                size={finalSize}
                                className={buttonSplitClasses}
                                classes={buttonSplitClassOverrides}
                                elementRef={elementRef}
                                status={status}
                                autoFocus={autoFocus}
                                name={name}
                                value={value}
                                onClick={onClick}
                                onMouseDown={onMouseDown}
                                onMouseUp={onMouseUp}
                                data-cy="gs-uitk-dropdown-button-split"
                            >
                                {children}
                            </Button>
                            <Button
                                buttonAttrs={toggleButtonAttrs}
                                {...sharedButtonProps}
                                disabled={getToggleButtonDisabledState({
                                    toggleDisabled,
                                    disabled: props.disabled,
                                })}
                                size={finalSize}
                                active={dropdownContext.menuVisible}
                                className={buttonToggleClasses}
                                elementRef={dropdownContext.menuTriggerRef}
                                status={toggleStatus}
                                type="button"
                                onClick={getOnClick({
                                    propsOnClick: onToggleClick,
                                    contextOnClick: dropdownContext.toggleMenu,
                                })}
                                data-cy="gs-uitk-dropdown-button-toggle"
                            >
                                <Icon
                                    data-cy="gs-uitk-dropdown-button-split-icon"
                                    name={dropdownIconName}
                                    type="filled"
                                ></Icon>
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                buttonAttrs={buttonAttrs}
                                {...sharedButtonProps}
                                size={finalSize}
                                active={dropdownContext.menuVisible}
                                className={buttonSingleClasses}
                                classes={buttonSingleClassOverrides}
                                elementRef={dropdownContext.menuTriggerRef}
                                status={status}
                                autoFocus={autoFocus}
                                name={name}
                                value={value}
                                onClick={getOnClick({
                                    propsOnClick: onClick,
                                    contextOnClick: dropdownContext.toggleMenu,
                                })}
                                onMouseDown={onMouseDown}
                                onMouseUp={onMouseUp}
                                data-cy="gs-uitk-dropdown-button-single"
                            >
                                {children}
                                {!hideToggleIcon && (
                                    <Icon
                                        data-cy="gs-uitk-dropdown-button-single-icon"
                                        name={dropdownIconName}
                                        aria-hidden={true}
                                        type="filled"
                                    ></Icon>
                                )}
                            </Button>
                        </>
                    )}
                </ButtonGroup>
            </span>
        );
    }
);
DropdownButton.displayName = 'DropdownButton';

function getSize(args: {
    propsSize?: ButtonSize;
    contextSize?: ButtonSize;
}): ButtonSize | undefined {
    const { propsSize, contextSize } = args;
    // use context size when the direct props dont have a size
    if (contextSize != null && propsSize == null) {
        return contextSize;
    }
    return propsSize;
}

function getOnClick(args: {
    propsOnClick?: ButtonProps['onClick'];
    contextOnClick?: ButtonProps['onClick'];
}): ButtonProps['onClick'] {
    const { propsOnClick, contextOnClick } = args;

    return event => {
        contextOnClick && contextOnClick(event);
        propsOnClick && propsOnClick(event);
    };
}

DropdownButton.propTypes = {
    ...Button.propTypes,
    actionType: PropTypes.oneOf(['primary', 'secondary', 'destructive', 'info', 'contrast']),
    splitToggle: PropTypes.bool,
    toggleStatus: PropTypes.oneOf(buttonStatuses),
    onToggleClick: PropTypes.func,
    // API Extractor cant handle dynamic import() which results from doing `...Button.propTypes,` so we need to explictly import and use it here
    type: PropTypes.oneOf(buttonTypesFixed),
};
