import { createSelector } from "reselect/lib/index";
import { allowFeaturesSelector } from "../user";
import { FUND_TYPE } from "../../constants/pageConstants";
import { MONTH_LIST } from "../../constants/appConstants";

const defaultDebitCreditData = {
  routingNumber: "",
  accountNumber: "",
  accountName: "",
  accountType: "",
  investOnlyType: "N",
  accountInstrId: 0,
  accountInstrSeq: "900",
};

export const columnsMetadata = (state) => {
  return (
    (state.pageData.columnsMetadata &&
      state.pageData.columnsMetadata.columns) ||
    (state.appData.columnsMetadata &&
      state.appData.columnsMetadata.columns) ||
    []
  );
};
export const getFundFactConfig = (state) =>
  state.pageContext.fundFactConfig || [];
export const getFundLiterature = (state) =>
  state.pageContext.fundLiterature || [];
export const getInstruction = (state) =>
  state.pageContext.settlementInstructions || [];
export const getRegistrationState = (state) =>
  state.pageContext.registrationState || 0;
export const getInstructionState = (state) =>
  state.pageContext.settlementInstructionsState || 0;
export const getDebitCreditState = (state) =>
  state.pageContext.debitCreditState || 0;
export const getAccountStatement = (state) =>
  state.pageContext.statements || [];
export const getStatementState = (state) =>
  state.pageContext.statementState || 0;
export const getAccountRegistration = (state) =>
  state.pageContext.registration || [];
export const getActiveFundFact = (state) =>
  state.pageContext.activeFundFact || null;
export const getErrorObject = (state) => state.pageContext.errorObject || {};
export const checkIsPageInErrorMode = (state) =>
  state.pageContext.isPageInErrorMode || false;
export const getAccountFactDebitCreditInfo = (state) =>
  state.pageContext.accountFactDebitCreditInfo || defaultDebitCreditData;
export const getDebitCreditEditStatus = (state) =>
  state.pageContext.debitCreditEditStatus || null;
export const getDebitCreditLoading = (state) =>
  state.pageContext.debitCreditLoading || false;
export const getIsDownloadStatementFailed = (state) =>
  state.pageContext.isDownloadStatementFailed || false;
export const getFundRebateLoading = (state) =>
  state.pageContext.fundRebateLoading || false;
export const getFundRebateStatus = (state) =>
  state.pageContext.fundRebateStatus || false;
export const getFundRebateEditStatus = (state) =>
  state.pageContext.fundRebateEditStatus;
export const getUnsavedRebateChanges = (state) =>
  state.pageContext.unsavedRebateChanges || false;
export const getCloseFundFactCard = (state) =>
  state.pageContext.closeFundFactCard || false;
export const getIsACHUser = (state) => state.pageContext.isAchUser || false;

export const getFundRebate = createSelector(
  getActiveFundFact,
  (activeFundFact) => {
    return activeFundFact.rebateDetails;
  }
);

export const showFundTrackerButtonSelector = createSelector(
  getActiveFundFact,
  allowFeaturesSelector,
  (activeFundFact, allowedFeatures) => {
    const hasPermitRole =
      (allowedFeatures && allowedFeatures.includes("FUNDTRACKERNEW")) || false;
    const allowedFundType =
      (activeFundFact && activeFundFact.fundType === FUND_TYPE.MONEY_MARKET) ||
      false;
    return hasPermitRole && allowedFundType;
  }
);

export const entitledToRebates = createSelector(
  allowFeaturesSelector,
  (allowedFeatures) => {
      return allowedFeatures && allowedFeatures.includes("REBATES")
  }
);

export const updatedFundFactConfig = createSelector(
  columnsMetadata,
  getFundFactConfig,
  getActiveFundFact,
  (colConfig, fundFactConfig, activeFundFact) => {
    const finalConfigList = [];

    colConfig.length &&
      fundFactConfig.forEach((factObj) => {
        // config merge logic
        const fieldsArr = [];
        factObj.fields.forEach((fact) => {
          const column = colConfig.find((col) => col.field === fact.field);

          if (column) {
            fieldsArr.push({ ...column, ...fact });
          } else {
            fieldsArr.push({ ...fact });
          }
        });
        finalConfigList.push({ ...factObj, fields: fieldsArr });
      });

    function selectFundFactConfig(card) {
      const isFundTypeMatching =
        typeof card.fundType === "string"
          ? activeFundFact.fundType === card.fundType
          : card.fundType.indexOf(activeFundFact.fundType) > -1;

      if (!isFundTypeMatching) return false;

      const isFundDomicileMatching =
        activeFundFact.fundDomicile === card.fundDomicile;

      // check for non US fund type in config !prefix
      const isNotFundDomicileMatching =
        card.fundDomicile.split("").indexOf("!") >= 0 &&
        card.fundDomicile.indexOf(`!${activeFundFact.fundDomicile}`) === -1;

      if (!(isFundDomicileMatching || isNotFundDomicileMatching)) return false;

      const isNavTypeMatching =
        (activeFundFact.navType &&
          card.navTypes &&
          card.navTypes.indexOf(activeFundFact.navType) >= 0) ||
        !card.navTypes;

      // check if nav type is found in notInNavTypes field in card
      const isNotNavTypeMatching =
        activeFundFact.navType &&
        card.navTypes &&
        card.notInNavTypes &&
        card.notInNavTypes.indexOf(activeFundFact.navType) === -1;

      // check for investment type in card
      const isMatchingInvestmentType =
        activeFundFact.investmentType &&
        activeFundFact.investmentType === card.investmentType;

      if (card.investmentType) {
        // check for non investment type in card
        const isNotInvestmentTypeMatching =
          card.investmentType.split("").indexOf("!") >= 0 &&
          card.investmentType.indexOf(`!${activeFundFact.investmentType}`) ===
            -1;

        if (!(isMatchingInvestmentType || isNotInvestmentTypeMatching))
          return false;
      }

      return (
        isNavTypeMatching || isMatchingInvestmentType || isNotNavTypeMatching
      );
    }

    function selectFactCardType(card) {
      return activeFundFact.factCardType &&
        activeFundFact.factCardType === "AccountDetail"
        ? card.factCardType && card.factCardType === "AccountDetail"
        : selectFundFactConfig(card);
    }

    return (activeFundFact && finalConfigList.find(selectFactCardType)) || null;
  }
);

export const getRegistration = createSelector(
  getAccountRegistration,
  (registration) => {
    const addressOrder = [
      "address1",
      "address2",
      "address3",
      "address4",
      "address5",
      "address6",
      "address7",
      "city",
      "state",
      "country",
      "postalCode",
    ];
    if (registration) {
      return addressOrder
        .filter((x) => registration[x])
        .map((y) => registration[y]);
    }
    return [];
  }
);

export const getAccountFactDebitCredit = createSelector(
  getAccountFactDebitCreditInfo,
  (debitCreditInfo) => {
    const {bankAccountInfo, ...restProps} = debitCreditInfo;
    if (bankAccountInfo) {
       Object.keys(bankAccountInfo)
          .filter(x => bankAccountInfo[x])
          .forEach(x => {restProps[x] = bankAccountInfo[x]});
       return restProps;
    }
    return defaultDebitCreditData;

  }
);

export const getStatement = createSelector(
  getAccountStatement,
  (statements) => {
    const finalStatementList = [];

    statements.forEach((statementObj) => {
      const date = new Date(statementObj.statementDate);
      finalStatementList.push({
        ...statementObj,
        statementDateMonth: MONTH_LIST[date.getMonth()],
        statementDateYear: date.getFullYear(),
      });
    });
    return finalStatementList;
  }
);
