export interface DataGridFeatures {
    datasourceConfiguration: {
        datasourceType: string;
        hasCode: boolean;
        hasColumns: boolean;
        hasDraFilterTransformer: boolean;
        hasFixedDRAFilter: boolean;
        hasOnCloseView: boolean;
        hasOnConnect: boolean;
        hasOnDisconnect: boolean;
        hasProtocols: boolean;
        hasUrl: boolean;
        defaultExpansionDepth: number | undefined;
        numberOfColumns: number | undefined;
    };
    draOptions: {
        checkboxSelection: boolean | undefined;
    };
    hasDatasourceConfiguration: boolean;
    hasDraOptions: boolean;
    hasInitialGridState: boolean;
    hasSwallowFormattingErrorCellValue: boolean;
    id: string;
    defaultGridState: {
        hasAutofitPivot: boolean;
        hasColumnFilter: boolean;
        hasColumnHint: boolean;
        hasCrossModel: boolean;
        hasCustomSort: boolean;
        hasKeyboardShortcut: boolean;
        hasExport: boolean;
        hasGrid: boolean;
        hasRowCustomisation: boolean;
        hasSavedView: boolean;
        hasMaskedColumn: boolean;
        hasQuickFilter: boolean;
        hasSearchable: boolean;
        hasSystemConfiguration: boolean;
        hasNotification: boolean;
        hasZebraStripes: boolean;
    };
    swallowFormattingError: boolean | undefined;
}

const normalizeFeatures = (properties: any): DataGridFeatures => ({
    datasourceConfiguration: {
        datasourceType:
            properties.datasourceConfiguration && properties.datasourceConfiguration.datasourceType,
        hasCode: !!(properties.datasourceConfiguration && properties.datasourceConfiguration.code),
        hasColumns: !!(
            properties.datasourceConfiguration && properties.datasourceConfiguration.columns
        ),
        hasDraFilterTransformer: !!(
            properties.datasourceConfiguration &&
            properties.datasourceConfiguration.draFilterTransformer
        ),
        hasFixedDRAFilter: !!(
            properties.datasourceConfiguration && properties.datasourceConfiguration.fixedDRAFilter
        ),
        hasOnCloseView: !!(
            properties.datasourceConfiguration && properties.datasourceConfiguration.onCloseView
        ),
        hasOnConnect: !!(
            properties.datasourceConfiguration && properties.datasourceConfiguration.onConnect
        ),
        hasOnDisconnect: !!(
            properties.datasourceConfiguration && properties.datasourceConfiguration.onDisconnect
        ),
        hasProtocols: !!(
            properties.datasourceConfiguration && properties.datasourceConfiguration.protocols
        ),
        hasUrl: !!(properties.datasourceConfiguration && properties.datasourceConfiguration.url),
        defaultExpansionDepth:
            properties.datasourceConfiguration &&
            properties.datasourceConfiguration.defaultExpansionDepth,
        numberOfColumns:
            properties.datasourceConfiguration &&
            properties.datasourceConfiguration.columns &&
            properties.datasourceConfiguration.columns.length,
    },
    draOptions: {
        checkboxSelection: properties.draOptions && properties.draOptions.checkboxSelection,
    },
    hasDatasourceConfiguration: !!properties.datasourceConfiguration,
    hasDraOptions: !!properties.draOptions,
    hasInitialGridState: !!properties.defaultGridState,
    hasSwallowFormattingErrorCellValue: !!properties.swallowFormattingErrorCellValue,
    id: properties.id,
    defaultGridState: {
        hasAutofitPivot: !!(
            properties.defaultGridState && properties.defaultGridState.autofitPivot
        ),
        hasColumnFilter: !!(
            properties.defaultGridState && properties.defaultGridState.columnFilter
        ),
        hasColumnHint: !!(properties.defaultGridState && properties.defaultGridState.columnHint),
        hasCrossModel: !!(properties.defaultGridState && properties.defaultGridState.crossModel),
        hasCustomSort: !!(properties.defaultGridState && properties.defaultGridState.customSort),
        hasKeyboardShortcut: !!(
            properties.defaultGridState && properties.defaultGridState.keyboardShortcut
        ),
        hasExport: !!(properties.defaultGridState && properties.defaultGridState.export),
        hasGrid: !!(properties.defaultGridState && properties.defaultGridState.grid),
        hasRowCustomisation: !!(
            properties.defaultGridState && properties.defaultGridState.rowCustomisation
        ),
        hasSavedView: !!(properties.defaultGridState && properties.defaultGridState.savedView),
        hasMaskedColumn: !!(
            properties.defaultGridState && properties.defaultGridState.maskedColumn
        ),
        hasQuickFilter: !!(properties.defaultGridState && properties.defaultGridState.quickFilter),
        hasSearchable: !!(properties.defaultGridState && properties.defaultGridState.searchable),
        hasSystemConfiguration: !!(
            properties.defaultGridState && properties.defaultGridState.systemConfiguration
        ),
        hasNotification: !!(
            properties.defaultGridState && properties.defaultGridState.notification
        ),
        hasZebraStripes: !!(
            properties.defaultGridState && properties.defaultGridState.zebraStripes
        ),
    },
    swallowFormattingError: properties.swallowFormattingError,
});

export { normalizeFeatures };
