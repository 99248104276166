import { compositeFormatter } from '../../../utils/compositeFormatter';
import { regex as regexRules } from '../../../helpers/regex';

const upperCaseFormatter = (value = '') => value.toUpperCase();
const alphaNumericValidator = (value = '') => value.replace(/[^a-zA-Z0-9]/g, '');
const shellAccountBinValidator = (bin = '') => bin.replace(new RegExp(regexRules.invalidShellAccountInput), '');

const mapStringToFunction = {
  'alphaNumericValidator': alphaNumericValidator,
  'upperCaseFormatter': upperCaseFormatter,
  'shellAccountBinValidator': shellAccountBinValidator
};

export const applyFormatters = (value, formatters) => {
  const formatterStringList = formatters.map(item => item.trim());
  const formatterList = formatterStringList.map(formatterString => mapStringToFunction[formatterString]);
  return compositeFormatter(value, formatterList);
};

