import { StyleSheet, CssClasses } from '@gs-ux-uitoolkit-react/style';

export type PluginFooterCssClasses = CssClasses<typeof pluginFooterStyleSheet>;

export const pluginFooterStyleSheet = new StyleSheet('pluginFooter', () => {
    return {
        root: {
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            padding: '1rem',
            flexWrap: 'wrap',
            span: {
                margin: '3px',
            },
        },
    };
});
