import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {useChartContext} from '../../context';
import {Conditional} from "../../../../components/core/Conditional";
import CustomSelect from "../../../../components/core/Select/Custom";
import FundBenchmarkOption from "./FundBenchmarkOption"
import FundBenchmarkList from "../FundBenchmarkList";
import translator from '../../../../services/translator';
import {getFundValueToSearch, updateShareClassDetails} from "../../utils";
import './index.scss';

const {translate: t} = translator;

const SearchBenchmark = ({
  title,
  onSelectCompareItem,
  primaryShareClassId,
  displaySelectedBenchmarks = true,
  skipBenchmarks = true,
  placeholder
}) => {
  const {state: chartState} = useChartContext() || {};
  const {
    benchmarks: selectedBenchmarks,
    shareClassAndBenchmarkData: {
      shareClasses = [],
      benchmarks: benchMarksData = []
    } = {}
  } = chartState;
  const [benchmarks, setBenchmarks] = useState(selectedBenchmarks);

  useEffect(() => {
    const benchMarkItems = updateShareClassDetails(selectedBenchmarks, shareClasses);
    setBenchmarks(benchMarkItems);
  }, [selectedBenchmarks]);

  useEffect(() => {
    onSelectCompareItem('benchmarks', benchmarks);
  }, [benchmarks]);

  const handleSelection = useCallback((option) => {
    const {label, value, customProperties: {type, cusip, isin}} = option || {};
    const containsBenchmark = benchmarks.find(benchmark => benchmark.value === value);
    if (!containsBenchmark) {
      setBenchmarks([...benchmarks, {label, value, type, cusip, isin}]);
    }
  }, [benchmarks]);

  const handleDeSelection = useCallback((value) => {
    const newBenchmarks = benchmarks.filter(benchmark =>
      benchmark.value !== value
    );
    setBenchmarks(newBenchmarks);
  }, [benchmarks]);

  const searchItems = useMemo(() => {
    // Prepare the plain list of Share Classes
    const shareClassPlainList = [];
    shareClasses
      .filter(({id}) => {
        const notPrimaryShareClassId = (id !== primaryShareClassId);
        const notAlreadySelected = !benchmarks.some(fundItem => fundItem.value === id);
        return notPrimaryShareClassId && notAlreadySelected;
      })
      .forEach(item => {
        const shareclass = getFundValueToSearch(item);
        if (!isEmpty(shareclass)) {
          shareClassPlainList.push(shareclass);
        }
      });

    // If we need to add the Benchmarks as well
    if (!skipBenchmarks) {
      const shareClassGrouped = {
        "label": t("tkFunds"),
        "options": shareClassPlainList
      };

      // Prepare the benchmark list
      const benchmarkList = {
        "label": t("tkIMoneyNetAverages"),
        "options": benchMarksData
          .filter(({vendorId}) => (
            !benchmarks.some(benchmarkItem => benchmarkItem.value === vendorId)
          ))
          .map((item) => ({
            value: item.vendorId,
            label: item.category,
            customProperties: {
              type: 'benchmark'
            }
          }))
      };
      return [shareClassGrouped, benchmarkList];
    }
    // Just return the plain list
    return shareClassPlainList;
  });

  const isDataLoading = !searchItems || !searchItems.length;
  const isDisableSearch = isDataLoading || benchmarks.length > 14;

  return (
    <div className="benchmark">
      <div>
        <span className="benchmark__heading">{title}</span>
        <div className="benchmark__search">
          <CustomSelect
            isDataLoading={isDataLoading}
            isDisabled={isDisableSearch}
            placeholder={placeholder}
            searchItems={searchItems}
            handleSelection={handleSelection}
            option={FundBenchmarkOption}
          />
        </div>
      </div>
      <Conditional condition={displaySelectedBenchmarks}>
        <div className="benchmark__selectedItems">
          <FundBenchmarkList
            items={benchmarks}
            disabled={false}
            placeholder={t('tkNoBenchmarkFundSelected')}
            onRemove={handleDeSelection}
          />
        </div>
      </Conditional>
    </div>
  );
};

SearchBenchmark.propTypes = {
  title: PropTypes.string,
  onSelectCompareItem: PropTypes.func,
  displaySelectedBenchmarks: PropTypes.bool,
  primaryShareClassId: PropTypes.string,
  skipBenchmarks: PropTypes.bool,
  placeholder: PropTypes.string
};

export default SearchBenchmark;
