import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./index.scss";

const Exclude = ({ selected, onClick, label, tooltip }) => {
  const [excluded, setExcluded] = useState(!!selected);

  const onClickHandle = () => {
    setExcluded(!excluded);
    onClick && onClick(!excluded);
  };

  useEffect(() => {
    if (selected !== excluded) {
      setExcluded(selected);
    }
  }, [selected]);

  const title = tooltip && label ? { title: label } : label;

  return (
    <div className="exclude-container" onClick={onClickHandle} {...title}>
      <button className={classNames("icon", { excluded })} />
      {label && <label className={classNames({ excluded })}>{label}</label>}
    </div>
  );
};

Exclude.defaultProps = {
  selected: false,
  label: "",
  tooltip: true,
  onClick: () => {},
};

Exclude.propTypes = {
  tooltip: PropTypes.bool,
  selected: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export default Exclude;
