import { StyleSheet, CssClasses } from '@gs-ux-uitoolkit-react/style';
import { Theme } from '@gs-ux-uitoolkit-react/theme';

export type BarRendererCssClasses = CssClasses<typeof barRendererStyleSheet>;

export const barRendererStyleSheet = new StyleSheet(
    'barRenderer',
    ({ theme }: { theme: Theme }) => {
        const databarPositiveColourSuperLight = theme.color.green.subtle;
        const databarNegativeColourSuperLight = theme.color.red.subtle;
        const databarPositiveColour = theme.getColorInteractionShades('green', 'subtle').active;
        const databarNegativeColour = theme.getColorInteractionShades('red', 'subtle').active;
        const databarNegativeColourLight = theme.color.red.subtle;
        const databarPositiveColourLight = theme.color.green.subtle;

        return {
            root: {
                position: 'relative',
                width: '100%',
                height: '100%',
                '&.bar-renderer-div-positive': {
                    background: `${databarPositiveColourSuperLight}`,
                },
                '&.bar-renderer-div-negative': {
                    background: `${databarNegativeColourSuperLight}`,
                },

                '.bar-renderer-border': {
                    width: '0%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    animation: 'grow 0.5s',
                },

                '@keyframes grow': {
                    '0%': {
                        transform: 'scaleX(0)',
                    },
                    '100%': {
                        transform: 'scale(1.0)',
                    },
                },

                '.bar-renderer-value': {
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    padding: '0px 4px',
                },

                '.ag-condensed, .ag-ultra-condensed': {
                    '.bar-renderer-value': {
                        padding: '0px 4px',
                    },
                },

                '.bar-renderer-positive': {
                    background: `${databarPositiveColour}`,
                    transformOrigin: 'left',
                },

                '.bar-renderer-negative': {
                    background: `${databarNegativeColour}`,
                    transformOrigin: 'right',
                },
                '.bar-renderer-positive-gradient': {
                    background: `linear-gradient(to right, ${databarPositiveColourLight}, ${databarPositiveColour})`,
                    transformOrigin: 'left',
                },

                '.bar-renderer-negative-gradient': {
                    background: `linear-gradient(to left, ${databarNegativeColourLight}, ${databarNegativeColour})`,
                    transformOrigin: 'right',
                },
            },
        };
    }
);
