import { useContext } from 'react';
import { HelpGuideContext } from './HelpGuideProvider';

const useConsumeHelpGuide = () => {
  const context = useContext(HelpGuideContext);

  if (!context) {
    throw new Error('useConsumeHelpGuide must be used within HelpGuideContext');
  }

  return context;
};

export default useConsumeHelpGuide;
