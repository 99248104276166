import actionTypes from './actionTypes';

export const loadReportGridData = payLoad => ({
  type: actionTypes.LOAD_REPORT_GRID_DATA,
  payLoad
});

export const reportsDataLoading = data => ({
  type: actionTypes.REPORTS_GRID_LOADING,
  data
});

export const openCustomViewModal = (data) => ({
  type: actionTypes.OPEN_CUSTOM_VIEW_MODAL_REPORT,
  data: {'isOpenCustomViewModal': true, ...data}
});

export const closeCustomViewModal = (data) => ({
  type: actionTypes.CLOSE_CUSTOM_VIEW_MODAL_REPORT,
  data: {'isOpenCustomViewModal': false, ...data}
});

export const saveCustomView = data => ({
  type: actionTypes.SAVE_CUSTOM_VIEW_REPORT,
  data
});

export const editCustomView = data => ({
  type: actionTypes.EDIT_CUSTOM_VIEW_REPORT,
  data
});

export const deleteCustomView = data => ({
  type: actionTypes.DELETE_CUSTOM_VIEW_REPORT,
  data
});

export const sortChange = (data) => ({
  type: actionTypes.CHANGE_COLUMN_SORT_REPORT,
  data: data.activeView,
  reducerHelper: data.params
});

export const columnMoved = data => ({
  type: actionTypes.CHANGE_COLUMN_ORDER_REPORT,
  data
});

export const columnRemoved = data => ({
  type: actionTypes.REMOVE_COLUMN_REPORT,
  data
});

export const groupChange = data => ({
  type: actionTypes.CHANGE_GROUP_REPORT,
  data: {
    groupBy: data.groupBy,
    activeView: data.activeView
  }
});

export const columnWidthChanged = data => ({
  type: actionTypes.CHANGE_COLUMN_WIDTH_REPORT,
  data
});

export const viewChange = data => ({
  type: actionTypes.CHANGE_VIEW_REPORT,
  data
});

export const dateChange = data => ({
  type: actionTypes.CHANGE_DATE_REPORT,
  data
});

export const applyFilter = data => ({
  type: actionTypes.APPLY_FILTER_REPORT,
  data: {
    filterBy: data.filters,
    filterModel: data.filterModel,
    filteredDataLength: data.filteredDataLength
  }
});

export const toggleFilterVisibility = data => ({
  type: actionTypes.TOGGLE_FILTER_VISIBILITY_REPORT,
  data
});

export const addReportLoadedData = data => ({
  type: actionTypes.ADD_REPORT_LOADED_DATA,
  data: {
    columnsMetadata: data.columnsMetadata,
    groupColumns: data.groupColumns,
    loading: data.loading,
    views: data.views,
    groupBy: data.groupBy,
    filterBy: data.filterBy,
    isOpenCustomViewModal: false
  }
});

export const addReportData = data => ({
  type: actionTypes.ADD_REPORT_DATA,
  data
});

export const reportDataLoading = data => ({
  type: actionTypes.GRID_LOADING_REPORT,
  data
});

export const addSaveViewData = data => ({
  type: actionTypes.ADD_SAVE_VIEW_DATA_REPORT,
  data: {
    views: data.views,
    isOpenCustomViewModal: data.isOpenCustomViewModal,
    isModalLoading: data.isModalLoading
  }
});

export const addEditDeleteViewData = data => ({
  type: actionTypes.ADD_EDIT_DELETE_VIEW_DATA_REPORT,
  data: {
    views: data.views,
    isOpenCustomViewModal: data.isOpenCustomViewModal,
    isModalLoading: data.isModalLoading,
    groupBy: data.groupBy
  }
});

export const modalLoading = data => ({
  type: actionTypes.MODAL_LOADING_REPORT,
  data
});

export const startExportToExcel = (data) => ({
  type: actionTypes.START_EXPORT_TO_EXCEL_REPORT,
  data
});

export const startExportToPdf = (data) => ({
  type: actionTypes.START_EXPORT_TO_PDF_REPORT,
  data
});

export const fileTypeChange = data => ({
  type: actionTypes.CHANGE_FILE_TYPE,
  data
});

export const saveCustomReport = (payLoad) => ({
  type: actionTypes.SAVE_CUSTOM_REPORT,
  payLoad
});

export const saveCustomReportPending = (flag) => ({
  type: actionTypes.SAVE_CUSTOM_REPORT_PENDING,
  data: {saveCustomReportPending: flag}
});

export const deleteCustomReport = (payLoad) => ({
  type: actionTypes.DELETE_CUSTOM_REPORT,
  payLoad
});

export const setReportDataToDelete = (data) => ({
  type: actionTypes.SET_REPORT_DATA_TO_DELETE,
  data
});

export const clearReportServiceError = () => ({
  type: actionTypes.CLEAR_REPORT_SERVICE_ERROR
});

export const reportDateRangeChange = (data) => ({
  type: actionTypes.REPORT_DATE_RANGE_CHANGE,
  data
});

export const isHideReportRollBackSnackBarMsg = (flag) => ({
  type: actionTypes.HIDE_REPORT_ROLLBACK_SNACKBAR_MSG,
  data: {isHideReportRollBackSnackBarMsg: flag}
});
