import { Density, Size } from '@gs-ux-uitoolkit-common/design-system';
import { GridConfigurationActionKeys } from './action-constants';

export type GridConfigurationActionTypes =
    | OpenGridConfigurationAction
    | SetCurrentPluginNameGridConfigurationAction
    | SetSizeAction
    | SetDensityAction;

export interface OpenGridConfigurationAction {
    type: GridConfigurationActionKeys.OPEN_GRID_CONFIGURATION;
    pluginName: string | undefined;
}

export interface SetCurrentPluginNameGridConfigurationAction {
    type: GridConfigurationActionKeys.SET_CURRENT_PLUGIN_NAME;
    pluginName: string | undefined;
}

export const OpenGridConfiguration = (
    pluginName: string | undefined
): OpenGridConfigurationAction => ({
    type: GridConfigurationActionKeys.OPEN_GRID_CONFIGURATION,
    pluginName,
});

export const SetGridConfigurationCurrentPluginName = (
    pluginName: string | undefined
): SetCurrentPluginNameGridConfigurationAction => ({
    type: GridConfigurationActionKeys.SET_CURRENT_PLUGIN_NAME,
    pluginName,
});

export interface SetSizeAction {
    type: GridConfigurationActionKeys.SET_SIZE;
    size: Size;
}

export interface SetDensityAction {
    type: GridConfigurationActionKeys.SET_DENSITY;
    density: Density;
}

export const SetSize = (size: Size): SetSizeAction => ({
    type: GridConfigurationActionKeys.SET_SIZE,
    size,
});

export const SetDensity = (density: Density): SetDensityAction => ({
    type: GridConfigurationActionKeys.SET_DENSITY,
    density,
});
