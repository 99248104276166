import { cx } from '@gs-ux-uitoolkit-common/style';
import { CheckboxCommonProps } from './checkbox-props';
import { CheckboxCssClasses } from './checkbox-style-sheet';

const globalClassName = 'gs-checkbox';

export const getCheckboxRootClasses = (props: {
    cssClasses: CheckboxCssClasses;
    className?: CheckboxCommonProps['className'];
    overrideClasses?: CheckboxCommonProps['classes'];
}): string => {
    const { cssClasses, className, overrideClasses } = props;
    return cx(cssClasses.root, className, overrideClasses && overrideClasses.root, globalClassName);
};

export const getCheckboxInputClasses = (props: {
    cssClasses: CheckboxCssClasses;
    overrideClasses?: CheckboxCommonProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.input,
        overrideClasses && overrideClasses.input,
        `${globalClassName}__input`
    );
};

export const getCheckboxContainerClasses = (props: {
    cssClasses: CheckboxCssClasses;
    overrideClasses?: CheckboxCommonProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.container,
        overrideClasses && overrideClasses.container,
        `${globalClassName}__container`
    );
};
export const getCheckboxInnerClasses = (props: {
    cssClasses: CheckboxCssClasses;
    overrideClasses?: CheckboxCommonProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.inner,
        overrideClasses && overrideClasses.inner,
        `${globalClassName}__inner`
    );
};

export const getCheckboxLabelClasses = (props: {
    cssClasses: CheckboxCssClasses;
    overrideClasses?: CheckboxCommonProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.label,
        overrideClasses && overrideClasses.label,
        `${globalClassName}__label`
    );
};

export const getCheckboxChildrenClasses = (props: {
    cssClasses: CheckboxCssClasses;
    overrideClasses?: CheckboxCommonProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.children,
        overrideClasses && overrideClasses.children,
        `${globalClassName}__children`
    );
};
