import {
    AddConfigItemAction,
    CancelEditedConfigItemAction,
    EditConfigItemAction,
    NewConfigItemAction,
    RemoveConfigItemAction,
    SaveEditedConfigItemAction,
    UpdateEditedConfigItemAction,
} from '@gs-ux-uitoolkit-common/datacore';
import { KeyboardShortcut } from '../../plugins/keyboard-shortcut/keyboard-shortcut';
import { KeyboardShortcutActionKeys } from './action-constants';

export type KeyboardShortcutActionTypes =
    | AddKeyboardShortcutAction
    | RemoveKeyboardShortcutAction
    | NewKeyboardShortcutAction
    | EditKeyboardShortcutAction
    | UpdateEditedKeyboardShortcutAction
    | SaveEditedKeyboardShortcutAction
    | CancelEditedKeyboardShortcutAction;

export interface RemoveKeyboardShortcutAction extends RemoveConfigItemAction<KeyboardShortcut> {
    type: KeyboardShortcutActionKeys.REMOVE_KEYBOARD_SHORTCUT;
}

export const RemoveKeyboardShortcut = (
    keyboardShortcut: KeyboardShortcut
): RemoveKeyboardShortcutAction => ({
    configItem: keyboardShortcut,
    type: KeyboardShortcutActionKeys.REMOVE_KEYBOARD_SHORTCUT,
});

export interface AddKeyboardShortcutAction extends AddConfigItemAction<KeyboardShortcut> {
    type: KeyboardShortcutActionKeys.ADD_KEYBOARD_SHORTCUT;
}

export const AddKeyboardShortcut = (
    keyboardShortcut: KeyboardShortcut
): AddKeyboardShortcutAction => ({
    configItem: keyboardShortcut,
    type: KeyboardShortcutActionKeys.ADD_KEYBOARD_SHORTCUT,
});

export interface EditKeyboardShortcutAction extends EditConfigItemAction<KeyboardShortcut> {
    type: KeyboardShortcutActionKeys.EDIT_KEYBOARD_SHORTCUT;
}

export const EditKeyboardShortcut = (
    keyboardShortcut: KeyboardShortcut
): EditKeyboardShortcutAction => ({
    configItem: keyboardShortcut,
    type: KeyboardShortcutActionKeys.EDIT_KEYBOARD_SHORTCUT,
});

export interface NewKeyboardShortcutAction extends NewConfigItemAction {
    type: KeyboardShortcutActionKeys.NEW_KEYBOARD_SHORTCUT;
}

export const NewKeyboardShortcut = (): NewKeyboardShortcutAction => ({
    type: KeyboardShortcutActionKeys.NEW_KEYBOARD_SHORTCUT,
});

export interface UpdateEditedKeyboardShortcutAction
    extends UpdateEditedConfigItemAction<KeyboardShortcut> {
    type: KeyboardShortcutActionKeys.UPDATE_EDITED_KEYBOARD_SHORTCUT;
}

export const UpdateEditedKeyboardShortcut = (
    keyboardShortcutUpdatedPart: Partial<KeyboardShortcut>
): UpdateEditedKeyboardShortcutAction => ({
    configItemUpdatedPart: keyboardShortcutUpdatedPart,
    type: KeyboardShortcutActionKeys.UPDATE_EDITED_KEYBOARD_SHORTCUT,
});

export interface SaveEditedKeyboardShortcutAction extends SaveEditedConfigItemAction {
    type: KeyboardShortcutActionKeys.SAVE_EDITED_KEYBOARD_SHORTCUT;
}

export const SaveEditedKeyboardShortcut = (): SaveEditedKeyboardShortcutAction => ({
    type: KeyboardShortcutActionKeys.SAVE_EDITED_KEYBOARD_SHORTCUT,
});

export interface CancelEditedKeyboardShortcutAction extends CancelEditedConfigItemAction {
    type: KeyboardShortcutActionKeys.CANCEL_EDITED_KEYBOARD_SHORTCUT;
}

export const CancelEditedKeyboardShortcut = (): CancelEditedKeyboardShortcutAction => ({
    type: KeyboardShortcutActionKeys.CANCEL_EDITED_KEYBOARD_SHORTCUT,
});
