// THIS FILE IS AUTO-GENERATED!! DO NOT MANUALLY EDIT!!

export type IconFont = 'materialSymbols' | 'custom';
export const iconFonts: IconFont[] = ['materialSymbols', 'custom']

/**
 * Icon Font Families
 */
export const materialSymbolsFontFamily = 'Material Symbols Outlined';


export const customIconFontFamily = 'CustomIcons';

/**
 * Icon Font Weight
 */
export const iconFontWeight: number = 400;               

export function getCodePointFromIconName(name: string): string {
    return name.replace(/-/g, '_');
}

// THIS FILE IS AUTO-GENERATED!! DO NOT MANUALLY EDIT!!
