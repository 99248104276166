import { win, trackComponentVersion } from '@gs-ux-uitoolkit-common/shared';
import { AnalyticsDetails } from 'gs-uitk-analytics-common';
import { getVersionNumberParts } from './helper';
import { toolkitAnalytics, ToolkitAnalyticsApplicationDetails } from './toolkit-analytics';

// These are title cased as we are already tracking events this way
export type ComponentAnalyticsEventName = 'Load' | 'Rendered';
export type AnalyticsComponentFramework = 'react' | 'angular' | 'unknown';
export const toolkitAnalyticsName = 'UI Toolkit';

export type OfficialComponentName =
    | 'pull-to-refresh'
    | 'queryfield'
    | 'radio'
    | 'search'
    | 'select'
    | 'skeleton-loader'
    | 'side-panel'
    | 'slider'
    | 'steps'
    | 'switch'
    | 'table'
    | 'tabs'
    | 'tag'
    | 'toast'
    | 'tooltip'
    | 'text-area'
    | 'text'
    | 'tree'
    | 'wizard'
    | 'accordion'
    | 'alert'
    | 'avatar'
    | 'badge'
    | 'breadcrumb'
    | 'bottom-sheet'
    | 'box'
    | 'button'
    | 'card'
    | 'chart-editor'
    | 'chart'
    | 'checkbox'
    | 'collapse'
    | 'datagrid'
    | 'datatoolbar'
    | 'datepicker'
    | 'dropdown'
    | 'flexlayout'
    | 'form'
    | 'graph'
    | 'grid-layout'
    | 'header'
    | 'heading'
    | 'horizontal-scroll'
    | 'icon'
    | 'input'
    | 'label'
    | 'layout'
    | 'link'
    | 'list-group'
    | 'loading'
    | 'menu'
    | 'metric'
    | 'modal'
    | 'navigation'
    | 'pagination'
    | 'person'
    | 'popover'
    | 'progress'
    | 'other';

export interface ComponentProperties {
    name: string;
    packageName: string;
    officialComponentName: OfficialComponentName;
    version: {
        full: string;
        major: string;
        minor: string;
        patch: string;
    };
    framework: string;
    config: {
        [k: string]: unknown;
    };
}

/**
 * Interface for the class arguments
 */
export interface ComponentAnalyticsArgs {
    packageName: string;
    version: string;
}
/**
 * Interface for the tracking function arguments
 */
export interface ComponentAnalyticsTrackArgs {
    eventName: ComponentAnalyticsEventName;
    properties?: AnalyticsDetails;
    featureName?: string;
    officialComponentName?: OfficialComponentName;
}

/**
 * Interface for the render tracking function arguments
 */
export interface ComponentAnalyticsTrackRenderArgs {
    features?: AnalyticsDetails;
    componentName?: string;
    officialComponentName: OfficialComponentName;
}

/**
 * This class handles analytics for a component
 */
export class ComponentAnalytics {
    private packageName: string;
    private componentName: string;
    private version: string;
    private framework: AnalyticsComponentFramework;

    constructor(componentAnalyticsArgs: ComponentAnalyticsArgs) {
        this.packageName = componentAnalyticsArgs.packageName;
        this.version = componentAnalyticsArgs.version;
        this.framework = this.getComponentFramework(componentAnalyticsArgs.packageName);
        this.componentName = this.getComponentName(componentAnalyticsArgs.packageName);

        this.validateSameComponentVersion();
    }

    // check to see if all components are using the same version number
    private validateSameComponentVersion(): void {
        trackComponentVersion(this.packageName, this.version);
    }

    /**
     * track component package loaded/used
     */
    public trackPackageLoad(): void {
        // Only log things if we're in a browser environment (i.e. not in a Server-side
        // rendering environment). If this happens in an SSR environment, it will throw
        // an error due to `document` or `location` not existing in the next method and an
        // SSR watch server will quit
        if (win) {
            this.track({
                eventName: 'Load',
            });
        }
    }

    /**
     * tracks Component render
     */
    public trackRender(trackingArgs: ComponentAnalyticsTrackRenderArgs) {
        this.track({
            eventName: 'Rendered',
            properties: trackingArgs.features,
            featureName: trackingArgs.componentName,
            officialComponentName: trackingArgs.officialComponentName,
        });
    }

    /**
     * tracking function to tracking an event
     * Example:
     * this.track({
     *      eventName: 'Rendered',
     *      features: {isDra:true},
     *     featureName: 'DataGrid',
     *   });
     */
    public track(trackingArgs: ComponentAnalyticsTrackArgs): void {
        const versionParts = getVersionNumberParts(this.version || '');
        const componentProperty: ComponentProperties = {
            name: trackingArgs.featureName || this.componentName,
            framework: this.framework,
            officialComponentName: trackingArgs.officialComponentName || 'other',
            packageName: this.packageName,
            version: {
                full: this.version,
                major: String(versionParts.major),
                minor: String(versionParts.minor),
                patch: String(versionParts.patch),
            },
            config: {
                ...trackingArgs.properties,
            },
        };
        toolkitAnalytics.track({
            eventName: trackingArgs.eventName,
            properties: {
                component: componentProperty,
            },
            applicationDetails: this.getApplicationTrackingDetails(),
        });
    }

    private getComponentName(packageName: string): string {
        return packageName.substring(packageName.lastIndexOf('/') + 1, packageName.length);
    }
    private getComponentFramework(packageName: string): AnalyticsComponentFramework {
        const parts = packageName.split('@gs-ux-uitoolkit-');
        if (parts[1]) {
            return parts[1].split('/')[0] as AnalyticsComponentFramework;
        }
        return 'unknown';
    }

    private getApplicationTrackingDetails(): ToolkitAnalyticsApplicationDetails {
        return {
            version: this.version,
            component: this.packageName,
            platform: this.framework,
            name: toolkitAnalyticsName,
        };
    }
}
