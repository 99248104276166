import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  selectCollapseState,
  preDefinedSortingState,
  getCurrentViewsKeys,
  gridHeader,
  currentViewSelector,
  getGridData,
  noRowDataSelector,
  selectedGroup,
  getSearchTerm,
  getFooterInfo,
  getDestroyFilters,
} from '../../selectors';
import GridModule from '../../../../Grid';
import { gridData, startExcelExport } from '../../../../Grid/selector';
import { sortWithFlatDataWithGrouping } from '../../../../../utils/sorting';
import { fetchUserSearchDetails, updateCurrentPage } from '../../actions';
import { addPageContext } from '../../../../../actions/page';
import { PAGES } from '../../reducers';
import { USERS } from '../../constants';
import translator from '../../../../../services/translator';

const { translate: t } = translator;

export const UserSearchGrid = props => {
  const groupRowAggNodes = data => () => data;

  const gridConfig = {
    groupRowAggNodes: groupRowAggNodes(props.currentViewsKeys),
  };

  const sortFunction = (params, allProps) => {
    const { sortedMeta, selectedRows } = params;
    return sortWithFlatDataWithGrouping(props.gridRawData, sortedMeta, selectedRows, allProps.currentView.groupBy);
  };

  const dispatchCellClick = params => {
    if (params.data && params.colDef.openDetailsPage) {
      props.onDispatchCellClick({
        isUser: true,
        ...params.data,
      });
    }
  };

  const overrideGridMapDispatchToProps = {
    dispatchCellClick,
  };

  const { searchTerm, ...otherProps } = props;
  const gridProps = {
    context: {
      searchTerm
    },
    ...otherProps
  };

  return (
    <GridModule
      gridConfig={gridConfig}
      sortFunction={sortFunction}
      {...gridProps}
      allowSortingOnGroupChange
      hideSelectedFirm={true}
      overrideGridMapDispatchToProps={overrideGridMapDispatchToProps}
      sheetName={t('tkUserSearch')}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  return {
    collapseState: selectCollapseState(state, id),
    currentViewsKeys: getCurrentViewsKeys(state, id),
    columnDefs: gridHeader(state, id),
    data: getGridData(state, id),
    currentView: currentViewSelector(state, id),
    preDefinedSortingState: preDefinedSortingState(state, id),
    gridRawData: gridData(state, id),
    startExcelExport: startExcelExport(state, id),
    noRowDataSelector: noRowDataSelector(state, id),
    selectedGroup: selectedGroup(state, id),
    searchTerm: getSearchTerm(state),
    footerInfo: getFooterInfo(state, id),
    destroyFilters: getDestroyFilters(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onDispatchCellClick: data => {
    dispatch(updateCurrentPage(PAGES.DETAILS_PAGE));
    dispatch(addPageContext({ pageKey: USERS }));
    dispatch(fetchUserSearchDetails(data));
  },
});

UserSearchGrid.propTypes = {
  collapseState: PropTypes.bool,
  currentViewsKeys: PropTypes.object,
  gridRawData: PropTypes.array,
  columnDefs: PropTypes.array,
  data: PropTypes.array,
  currentView: PropTypes.object,
  preDefinedSortingState: PropTypes.array,
  startExcelExport: PropTypes.object,
  noRowDataSelector: PropTypes.string,
  selectedGroup: PropTypes.object,
  onDispatchCellClick: PropTypes.func,
  searchTerm: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSearchGrid);
