import { useEffect, MouseEvent, KeyboardEvent, PropsWithChildren, FunctionComponent } from 'react';
import { PopoverTarget } from '@gs-ux-uitoolkit-react/popover';
import {
    visuallyHiddenStyles,
    VisuallyHiddenProps as CommonVisuallyHiddenProps,
} from '@gs-ux-uitoolkit-common/accessibility';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { ReactComponentProps } from '@gs-ux-uitoolkit-react/component';
import { cx } from '@gs-ux-uitoolkit-common/style';
import { extractDataAttributes } from '@gs-ux-uitoolkit-react/shared';
import { componentAnalytics } from './analytics-tracking';
import { omit } from 'gs-uitk-lodash';

export interface VisuallyHiddenProps extends Omit<CommonVisuallyHiddenProps, 'onClick'> {
    /**
     * Handler for the click event.
     */
    onClick?: (e: MouseEvent | KeyboardEvent) => void;
}

export const VisuallyHidden: FunctionComponent<
    PropsWithChildren<ReactComponentProps & VisuallyHiddenProps>
> = props => {
    const {
        displayOnFocus,
        onClick,
        children,
        classes: overrideClasses,
        className,
        title,
        style,
        id,
        ...otherProps
    } = props;
    const isInteractable = displayOnFocus || onClick;
    const classes = useStyleSheet(visuallyHiddenStyles, null);

    const dataAttributes = extractDataAttributes(otherProps);
    const rest = omit(otherProps, Object.keys(dataAttributes));

    const handleClick = (e: MouseEvent | KeyboardEvent) => {
        if (onClick) {
            onClick(e);
        }
    };

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({
            componentName: 'visually-hidden',
            officialComponentName: 'other',
        });
    }, []); // Only run once

    return (
        <span
            data-gs-uitk-component="visually-hidden"
            className={cx(classes.root, overrideClasses && overrideClasses.root, className)}
            title={title}
            style={style}
            id={id}
            {...dataAttributes}
        >
            {isInteractable ? (
                <PopoverTarget
                    header={''}
                    body={children as string}
                    triggers="focusin"
                    placement="bottom-right"
                    showTip={false}
                    {...rest}
                >
                    <button
                        data-cy="visually-hidden-target"
                        onClick={handleClick}
                        className={classes.hidden}
                    >
                        &nbsp;{children}&nbsp;
                    </button>
                </PopoverTarget>
            ) : (
                <span {...rest} className={classes.hidden} data-cy="visually-hidden-target">
                    &nbsp;{children}&nbsp;
                </span>
            )}
        </span>
    );
};
