import { cx } from '@gs-ux-uitoolkit-common/style';
// api-extractor does not support dynamic imports.  TSC compiler will
// reduce ButtonActionType to ActionType, since they are equivalent
// @ts-expect-error Issue:  https://github.com/microsoft/rushstack/issues/1050
import { Orientation } from '@gs-ux-uitoolkit-common/design-system';
import { ButtonGroupCommonProps, ButtonGroupOrientation } from './button-group-props';
import { ButtonGroupCssClasses } from './button-group-style-sheet';

const globalClassName = 'gs-button-group';

export const getButtonGroupClassNames = (props: {
    orientation: ButtonGroupOrientation;
    cssClasses: ButtonGroupCssClasses;
    overrideClasses?: ButtonGroupCommonProps['classes'];
    className?: ButtonGroupCommonProps['className'];
}): string => {
    const { orientation, cssClasses, overrideClasses, className } = props;
    const rootOverride = overrideClasses && overrideClasses.root;

    return cx(
        cssClasses.root,
        {
            [cssClasses['orientation-horizontal']]: orientation === 'horizontal',
            [cssClasses['orientation-vertical']]: orientation === 'vertical',
        },
        className,
        rootOverride,
        globalClassName
    );
};
