import {
    StyleSheet,
    CssPropertyType,
    CssClassDefinitionsObject,
    CssColor,
} from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { RadioSize, RadioProps } from './radio-props';
import { DeepReadonly } from 'ts-essentials';
import './radio-theme-overrides';

export interface RadioStyleSheetProps {
    theme: Theme;
    status: RadioProps['status'];
    size: RadioSize;
    inline: RadioProps['inline'];
    labelPlacement: RadioProps['labelPlacement'];
}

export interface RadioCssClasses {
    root: string;
    input: string;
    container: string;
    inner: string;
    label: string;
    children: string;
}

export type RadioStyledClasses = CssClassDefinitionsObject<keyof RadioCssClasses>;

export interface RadioStyleOverridesParams {
    props: DeepReadonly<RadioStyleSheetProps>;
    createDefaultStyledClasses: () => RadioStyledClasses;
}

export const radioStyleSheet = new StyleSheet('radio', (props: RadioStyleSheetProps) => {
    return createComponentClassDefinitions<RadioStyleSheetProps, RadioStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.radio
    );
});

function createDefaultStyledClasses({
    theme,
    size,
    inline,
    labelPlacement,
    status,
}: RadioStyleSheetProps): RadioStyledClasses {
    const errorColors = theme.status.error;

    const borderColor = theme.border.strong;
    const borderHoverColor = theme.increaseContrast(borderColor, 1);
    const checkedIconColor: CssColor =
        status === 'error' ? errorColors.bold : theme.colorScheme.primary;

    const errorStyles =
        status === 'error'
            ? {
                  '&:not(:disabled)': {
                      '+ span': {
                          borderColor: errorColors.bold,
                      },
                  },
                  '&:focus': {
                      '&:not(:disabled)': {
                          '+ span': {
                              borderColor: errorColors.bold,
                          },
                      },
                  },
              }
            : {
                  '&:focus': {
                      '&:not(:disabled)': {
                          '+ span': {
                              boxShadow: `
                                0 0 0 1px ${theme.surface.primary}, 
                                0 0 0 2px ${checkedIconColor}
                            `,
                          },
                      },
                  },
              };

    return {
        root: {
            display: inline ? 'inline-flex' : 'block',
            marginBottom: radioSizeVariants[size].rootMarginBottom,
            paddingBottom: radioSizeVariants[size].rootPaddingBottom,
            alignItems: inline ? 'baseline' : 'normal',
            marginRight: inline ? radioSizeVariants[size].inlineMarginRight : '0px',
        },
        input: {
            cursor: 'pointer',
            transition:
                'background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
            bottom: 0,
            left: 0,
            opacity: 0,
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 1,

            '&:hover:not(:disabled):not(:checked)': {
                '+ span': {
                    borderColor: status === 'error' ? errorColors.bold : borderHoverColor,
                },
            },
            '&:checked + span': {
                borderColor: theme.border.contrast,
                '&::after': {
                    backgroundColor: checkedIconColor,
                },
            },
            '&:disabled': {
                cursor: 'default',
            },
            ...errorStyles,
        },
        container: {
            display: 'inline-flex',
            order: 1,
        },
        inner: {
            border: '1px solid',
            borderRadius: '50%',
            borderColor: borderColor,
            boxSizing: 'border-box',
            backgroundColor: theme.surface.primary,
            display: 'block',
            position: 'relative',
            transition:
                'background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
            height: radioSizeVariants[size].inputHeight,
            marginRight: labelPlacement === 'right' ? radioSizeVariants[size].inputMarginRight : 0,
            marginLeft: labelPlacement === 'left' ? radioSizeVariants[size].inputMarginRight : 0,
            top: radioSizeVariants[size].inputTop,
            width: radioSizeVariants[size].inputWidth,
            '&::after': {
                borderRadius: '50%',
                boxSizing: 'border-box',
                content: '""',
                display: 'table',
                position: 'absolute',

                height: radioSizeVariants[size].checkedHeight,
                left: radioSizeVariants[size].checkedLeft,
                top: radioSizeVariants[size].checkedTop,
                width: radioSizeVariants[size].checkedWidth,
            },
        },
        label: {
            marginBottom: 0,
            display: 'flex',
        },
        children: {
            verticalAlign: inline ? 'baseline' : 'top',
            order: labelPlacement === 'right' ? 2 : 0,
        },
    };
}

const radioSizeVariants: { [name in RadioSize]: RadioSizeVariant } = {
    sm: {
        rootMarginBottom: '2px',
        rootPaddingBottom: '2px',
        inputFontSize: '12px',
        inputHeight: '14px',
        inputMarginRight: '8px',
        inputTop: '1px',
        inputWidth: '14px',
        inputFocusHeight: '14px',
        inputFocusWidth: '14px',
        inlineMarginRight: '16px',
        checkedHeight: '6px',
        checkedLeft: '3px',
        checkedTop: '3px',
        checkedWidth: '6px',
    },
    md: {
        rootMarginBottom: '6px',
        rootPaddingBottom: '2px',
        inputFontSize: '14px',
        inputHeight: '16px',
        inputMarginRight: '10px',
        inputTop: '2px',
        inputWidth: '16px',
        inputFocusHeight: '16px',
        inputFocusWidth: '16px',
        inlineMarginRight: '24px',
        checkedHeight: '6px',
        checkedLeft: '4px',
        checkedTop: '4px',
        checkedWidth: '6px',
    },
    lg: {
        rootMarginBottom: '14px',
        rootPaddingBottom: '1px',
        inputFontSize: '16px',
        inputHeight: '18px',
        inputMarginRight: '10px',
        inputTop: '3px',
        inputWidth: '18px',
        inputFocusHeight: '18px',
        inputFocusWidth: '18px',
        inlineMarginRight: '32px',
        checkedHeight: '8px',
        checkedLeft: '4px',
        checkedTop: '4px',
        checkedWidth: '8px',
    },
};

interface RadioSizeVariant {
    rootMarginBottom: CssPropertyType['marginBottom'];
    rootPaddingBottom: CssPropertyType['paddingBottom']; // fixing height of component after changing display to flex - remove in v17
    inputFontSize: CssPropertyType['fontSize'];
    inputHeight: CssPropertyType['height'];
    inputMarginRight?: CssPropertyType['marginRight'];
    inputTop: CssPropertyType['top'];
    inputWidth: CssPropertyType['width'];
    inputFocusHeight: CssPropertyType['height'];
    inputFocusWidth: CssPropertyType['width'];
    inlineMarginRight: CssPropertyType['marginRight'];
    checkedHeight: CssPropertyType['height'];
    checkedLeft: CssPropertyType['left'];
    checkedTop: CssPropertyType['top'];
    checkedWidth: CssPropertyType['width'];
}
