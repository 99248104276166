import { ActionType, Emphasis, Size, Shape, Status } from '@gs-ux-uitoolkit-common/design-system';
import { ButtonCssClasses } from './button-style-sheet';

export interface ButtonCommonProps<MouseEventType = MouseEvent> {
    /**
     * Action type of the button.
     */
    actionType?: ButtonActionType;

    /**
     * Status of the button.
     *
     * Used as a temporary state and takes priority over {@link #actionType}.
     */
    status?: ButtonStatus;

    /**
     * Visual emphasis of the button.
     */
    emphasis?: ButtonEmphasis;

    /**
     * Size of the button.
     */
    size?: ButtonSize;

    /**
     * Shape of the button.
     */
    shape?: ButtonShape;

    /**
     * Sets the `id` attribute of the underlying `<button>` element of the
     * component.
     *
     * The Button component itself is wrapped in an extra element for styling
     * purposes, and therefore the {@link #id} prop refers to that element. Use
     * this prop to set an `id` to the actual `<button>` element itself.
     */
    buttonId?: string;

    /**
     * Sets the HTML `disabled` state on the button.
     *
     * Read the [MDN docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-disabled) for more info on this attribute.
     */
    disabled?: boolean;

    /**
     * Sets the HTML `type` attribute on the button.
     *
     * Read the [MDN docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type) for more info on this attribute.
     */
    type?: ButtonType;

    /**
     * Additional classes to add on the root element.
     */
    className?: string;

    /**
     * Style classes to override.
     */
    classes?: Partial<ButtonCssClasses>;

    /**
     * Focuses the button on the initial render.
     */
    autoFocus?: boolean;

    /**
     * Sets the HTML `name` attribute on the button.
     *
     * Read the [MDN docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-name) for more info on this attribute.
     */
    name?: string;

    /**
     * Sets the HTML `value` attribute on the button.
     *
     * Read the [MDN docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-value) for more info on this attribute.
     */
    value?: string | number | string[];

    /**
     * Sets the `tabindex` attribute to customize how the button participates in sequential keyboard
     * navigation. Avoid using this to prevent accessibility issues with screen readers.
     *
     * Learn more at [MDN docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex).
     */
    tabIndex?: number;

    /**
     * Styles the button in its "active" state.
     *
     * Used when the button triggers another element, such as a menu in a Dropdown.
     */
    active?: boolean;

    /**
     * Handler for the click event.
     */
    onClick?: (event: MouseEventType) => void;

    /**
     * Handler for the mouse-down event.
     */
    onMouseDown?: (event: MouseEventType) => void;

    /**
     * Handler for the mouse-up event.
     */
    onMouseUp?: (event: MouseEventType) => void;
}

export const defaultButtonProps: DefaultButtonProps = {
    type: 'button',
    actionType: 'secondary',
    status: 'none',
    emphasis: 'bold',
    size: 'md',
    shape: 'square',
    disabled: false,
    autoFocus: false,
    name: '',
    value: '',
    active: false,
};

export type DefaultButtonProps = Required<
    Pick<
        ButtonCommonProps,
        | 'type'
        | 'actionType'
        | 'status'
        | 'emphasis'
        | 'size'
        | 'shape'
        | 'disabled'
        | 'autoFocus'
        | 'name'
        | 'value'
        | 'active'
    >
>;

export type ButtonActionType = Extract<
    ActionType,
    'primary' | 'secondary' | 'destructive' | 'info' | 'contrast'
>;

export type ButtonStatus = Extract<Status, 'none' | 'loading' | 'success' | 'error'>;

export type ButtonEmphasis = Extract<Emphasis, 'bold' | 'minimal' | 'subtle'>;

export type ButtonSize = Extract<Size, 'sm' | 'md' | 'lg' | 'xl'>;

export type ButtonShape = Extract<Shape, 'square' | 'circle'>;

export type ButtonType = 'submit' | 'reset' | 'button';

export const buttonActionTypes: ButtonActionType[] = [
    'primary',
    'secondary',
    'destructive',
    'info',
    'contrast',
];
export const buttonStatuses: ButtonStatus[] = ['none', 'loading', 'success', 'error'];
export const buttonEmphasises: ButtonEmphasis[] = ['bold', 'minimal', 'subtle'];
export const buttonSizes: ButtonSize[] = ['sm', 'md', 'lg', 'xl'];
export const buttonShapes: ButtonShape[] = ['square', 'circle'];
export const buttonTypes: ButtonType[] = ['submit', 'reset', 'button'];
