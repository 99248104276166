import { Dispatch, MutableRefObject, SetStateAction, useCallback } from 'react';
import { DragAndDropPlaceholderState, DragAndDropSizesCache } from '../drag-and-drop-context';
import { DragAndDropStart, ExternalDragAndDropOnDragStart } from '../drag-and-drop-external';
import { getPlaceholderState } from '../get-placeholder-state';

export type UseOnDragStartCallbackType = typeof useOnDragStartCallback;
export interface DragAndDropStartEvent {
    initial: DragAndDropStart;
    announce: (message: string) => void;
}

export type DragAndDropOnDragStart = (event: DragAndDropStartEvent) => void;

/**
 * Callback returned from the hook should run when drag operation starts.
 * Callback prepares cache of the dimensions of `<Draggable /> components` and displays placeholder.
 * This is needed to position placeholder in the correct place.
 */
export const useOnDragStartCallback = (
    setPlaceholderState: Dispatch<SetStateAction<DragAndDropPlaceholderState>>,
    refs: MutableRefObject<Record<string, MutableRefObject<HTMLElement | null>>>,
    sizesCache: MutableRefObject<DragAndDropSizesCache>,
    direction: 'horizontal' | 'vertical',
    placeholderDisabled?: boolean,
    onDragStart?: DragAndDropOnDragStart
) => {
    return useCallback<ExternalDragAndDropOnDragStart>(
        (initial, provided) => {
            // call function if provided by user
            onDragStart ? onDragStart({ initial, announce: provided.announce }) : null;

            // disable placeholder logic if placeholder is disabled
            if (placeholderDisabled) {
                return;
            }

            // get dragged elements width and height
            const { offsetWidth: width, offsetHeight: height } = getChildAtIndex(
                getDroppableChildrenById(refs, initial.source.droppableId)[initial.source.index],
                0
            );

            setPlaceholderState(prevState => {
                return { ...prevState, width, height };
            });

            // get cache of dimensions of droppable children
            sizesCache.current = Object.keys(refs.current)
                .map(key => {
                    return {
                        [key]: Array.from(refs.current[key].current?.children || []).map(el => {
                            const { offsetHeight: top, offsetWidth: left } = el as HTMLDivElement;
                            return { top, left };
                        }),
                    };
                })
                .reduce((o1, o2) => {
                    return { ...o1, ...o2 };
                }, {});

            setPlaceholderState(prevState => {
                return {
                    ...prevState,
                    ...getPlaceholderState(sizesCache, initial, direction, true),
                };
            });
        },
        [setPlaceholderState, refs, sizesCache, direction, placeholderDisabled, onDragStart]
    );
};

const getDroppableChildrenById = (
    refs: MutableRefObject<Record<string, MutableRefObject<HTMLElement | null>>>,
    id: string
): HTMLElement[] => {
    return Array.from(refs.current[id].current?.children ?? []) as HTMLElement[];
};

const getChildAtIndex = (element: HTMLElement, index: number): HTMLElement => {
    return element.children[index] as HTMLElement;
};
