export class SessionStorage {
  static isStorageAvailable() {
    return typeof(Storage) !== 'undefined';
  }

  static setItem(key, value) {
    sessionStorage.setItem(key, value);
  }

  static getItem(key) {
    return sessionStorage.getItem(key) || '';
  }

  static removeItem(key) {
    sessionStorage.removeItem(key);
  }

  static clear() {
    sessionStorage.clear();
  }
}

export class LocalStorage {
  static isStorageAvailable() {
    return typeof(Storage) !== 'undefined';
  }

  static setItem(key, value) {
    localStorage.setItem(key, value);
  }

  static getItem(key) {
    return localStorage.getItem(key) || '';
  }

  static removeItem(key) {
    localStorage.removeItem(key);
  }

  static clear() {
    localStorage.clear();
  }
}
