import {
    KeyboardEvent,
    ChangeEvent,
    FC,
    useState,
    useEffect,
    useRef,
    useReducer,
    useCallback,
    FocusEvent,
    MouseEvent,
    FormEvent,
    WheelEvent,
} from 'react';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { InputInternal } from './input-internal';
import {
    convertSign,
    convertToNumber,
    isAbbrevChar,
    expandAbbrevChar,
    formatAndAbbreviate,
    getValidNumericValue,
    getValueAsNumber,
    increment,
    decrement,
    removeNonNumericChars,
    valueIsNotValid,
    isCharDigitAt,
    isCharNonZeroDigitAt,
    isCharDigitDecimalOrSignAt,
    inputNumberStyleSheet,
    globalInputNumberClass,
    InputNumberChangeEventProps,
    setSelectionRangeOnFocus,
} from '@gs-ux-uitoolkit-common/input';
import {
    KeyHelpers,
    createCrossBrowserEvent,
    isBrowser,
    isSafari,
    logWarningOnce,
    isTouchDevice,
} from '@gs-ux-uitoolkit-react/shared';
import { cx, useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { useFormInputContext } from './hooks/use-form-input-context';
import { InternalInputNumberProps } from './input-props';
import { componentAnalytics } from './analytics-tracking';

export type InputNumberKeyboardEvent = InputNumberChangeEventProps &
    KeyboardEvent<HTMLInputElement>;
export type InputNumberChangeEvent = InputNumberChangeEventProps & ChangeEvent<HTMLInputElement>;

export interface InputNumberProps
    extends Omit<
        InternalInputNumberProps,
        'onChange' | 'onInput' | 'onKeyDown' | 'trailingInternalContent'
    > {
    /**
     * Called when the input value changes.
     */
    onChange?: (event: InputNumberChangeEvent) => void;

    /**
     * Called when the input value is updated.
     */
    onInput?: (event: InputNumberKeyboardEvent) => void;

    /**
     * Fires when the component is focused and a "keydown" event is triggered.
     */
    onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;

    /**
     * Fires when the component is focused and a "keydown" event is triggered,
     * but before the value is changed.
     *
     * This is useful for preventing the number value from changing automatically
     * for certain keys, such as for the 'up' or 'down' arrow keys. For example:
     *
     *     onBeforeKeyDown={event => {
     *         if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
     *             event.preventDefault(); // don't change the number
     *         }
     *     }}
     */
    onBeforeKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
}

/**
 * InputNumber allows numeric data to be incremented and decremented according
 * to an optional step value. Minimum and maximum values can also be .
 */
export const InputNumber: FC<InputNumberProps> = props => {
    const {
        classes,
        disabled,
        hideArrowButtons,
        defaultValue,
        arrowButtons = true,
        min,
        max,
        roundByStep,
        size,
        step,
        value,
        selectTextOnClick,
        selectOnFocus,
        onBlur,
        onFocus,
        onChange, // Prevent user's onChange handler from being passed down.
        inputRef,
        changeValueOnWheel,
        format,
        fixedDecimalSeparator,
        onBeforeKeyDown,
        ...otherProps
    } = props;

    if (hideArrowButtons !== undefined) {
        logWarningOnce(
            'UI Toolkit InputNumber: `hideArrowButtons` is deprecated and will be removed in an upcoming major release. Please use `arrowButtons` instead.'
        );
    }
    if (selectTextOnClick !== undefined) {
        logWarningOnce(
            'UI Toolkit InputNumber: `selectTextOnClick` is deprecated and will be removed in an upcoming major release. Please use `selectOnFocus` instead.'
        );
    }
    if (roundByStep !== undefined) {
        logWarningOnce(
            'UI Toolkit InputNumber: `roundByStep` is not supported and will be removed in an upcoming major release. If this is required, please submit a support ticket.'
        );
    }

    const [arrowButtonsVisible, setArrowButtonsVisible] = useState(
        typeof arrowButtons === 'boolean' && !isTouchDevice() ? arrowButtons : false
    );

    // stores the internal value for uncontrolled mode, for calculations such as increment and decrement
    const [internalValue, setInternalValue] = useState<InputNumberProps['value']>(
        value || defaultValue || ''
    );

    // used to revert to the previous value if an input is invalid, or the app doesn't
    // update 'value' from a controlled event.
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    // stores the previous 'value' from the last render cycle - is used to determine if
    // this render cycle is a revert, or a new value from the app
    const previousValue = useRef<InputNumberProps['value']>(value);

    //set to true if formatter non- '.' decimal separator error is thrown
    const hasCheckedDecimalSeparator = useRef(false);

    // true if forward delete (DELETE, not BACKSPACE) was pressed on a revert render cycle,
    // then the cursor position needs to stay in its current position
    const forwardDeleteWasPressed = useRef<boolean>(false);

    // Would have like to avoid keeping this var, but can't simulate an event with
    // event.nativeEvent.data === '-' in testing environment.  If that was possible,
    // we could keep the check isolated to onInput() and not store this var.
    let signCharWasPressed = false;

    const { disabled: internalDisabled, size: internalSize } = useFormInputContext({
        disabled,
        size,
    });

    const internalSelectOnFocus = selectOnFocus || 'browser-default';

    const theme = useTheme();
    const cssClasses = useStyleSheet(inputNumberStyleSheet, {
        theme,
        size: internalSize || 'md',
    });

    const inputNumberRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);

    const inputNumberCallbackRef = useCallback(
        (inputEl: HTMLInputElement | HTMLTextAreaElement) => {
            inputNumberRef.current = inputEl;

            if (typeof inputRef === 'function') {
                inputRef(inputEl);
            } else if (inputRef) {
                inputRef.current = inputEl;
            }
        },
        [inputNumberRef, inputRef]
    );

    const isControlled = useCallback(() => {
        return value != null;
    }, [value]);

    function onMouseEnterInternal() {
        if (arrowButtons === 'onHover' || arrowButtons === 'onFocusOrHover') {
            setArrowButtonsVisible(true);
        }
    }
    function onMouseLeaveInternal() {
        if (arrowButtons === 'onHover' || (arrowButtons === 'onFocusOrHover' && !hasFocus())) {
            setArrowButtonsVisible(false);
        }
    }

    function onBlurInternal(event: FocusEvent<HTMLInputElement>) {
        if (arrowButtons === 'onFocus' || arrowButtons === 'onFocusOrHover') {
            setArrowButtonsVisible(false);
        }
        if (format?.abbrevOnBlur) {
            forceUpdate();
        }
        onBlur && onBlur(event);
    }

    function isRangeSelected() {
        return inputNumberRef.current!.selectionEnd !== inputNumberRef.current!.selectionStart;
    }

    /**
     * @returns true if the character at `index` is should be "skipped" and the cursor placed
     * on the other side. For example, if BACKSPACE is pressed, and the next character is
     * insignificant, we will move the cursor over that character: 123,|456  =>  123|,456
     */
    function shouldMoveCursorOverChar(value: string, index: number) {
        return (
            !isRangeSelected() &&
            (!isCharDigitDecimalOrSignAt(value, index) ||
                (fixedDecimalSeparator && value[index] === '.'))
        );
    }

    function onKeyDown(event: KeyboardEvent<HTMLInputElement>) {
        // Sometimes in Cypress React's event.which is undefined although the
        // native event.which is defined:

        // This code fixes problem with event default prevented by user.
        // When event is default prevented than we should stop code execution
        // issue ticket https://jira.work.gs.com/browse/UX-18233
        // To avoid breaking changes we decided to add new callback onBeforeKeyDown
        // which is called before logic execution.
        // so if onBeforeKeyDown callback is not defined than component work as it was before.
        // This code should stay here and only the name of callback should be change
        // from onBeforeKeyDown to onKeyDown and remove callback call from the bottom of this function.
        if (props.onBeforeKeyDown) {
            props.onBeforeKeyDown(event);
            if (event.isDefaultPrevented()) {
                return;
            }
        }

        const which = event.which || event.nativeEvent.which;
        const input = inputNumberRef.current!;
        if (which === KeyHelpers.keyCode.ARROW_UP) {
            onIncrement();
            event.preventDefault();
        } else if (which === KeyHelpers.keyCode.ARROW_DOWN) {
            onDecrement();
            event.preventDefault();
        } else if (which === KeyHelpers.keyCode.BACKSPACE) {
            // Move the cursor past insignificant characters:  For example, 123,|456  =>  123|,456
            const charPosition = input.selectionEnd! - 1;
            if (shouldMoveCursorOverChar(input.value, charPosition)) {
                input.setSelectionRange(charPosition, charPosition);
                event.preventDefault();
            }
        } else if (which === KeyHelpers.keyCode.DELETE) {
            // Move the cursor past insignificant characters:  For example, 123|,456  =>  123,|456
            const charPosition = input.selectionEnd!;
            if (shouldMoveCursorOverChar(input.value, charPosition)) {
                input.setSelectionRange(charPosition + 1, charPosition + 1);
                event.preventDefault();
            } else {
                // if it was a significant character deleted, we need to track if foward DELETE was pressed
                // in case the app does not apply the change, and we need to revert the cursor position
                forwardDeleteWasPressed.current = true;
            }
        } else if (format?.expandAbbrevChar && isAbbrevChar(event.key)) {
            const currentValue = convertToNumber(effectiveValue);
            const convertedValue = expandAbbrevChar({
                value: currentValue,
                abbrev: event.key,
                abbrevStyle: format.abbrevStyle,
            });
            updateInputValue(convertedValue);
            setInternalValue(convertedValue);
            event.preventDefault();
        } else if (
            !isRangeSelected() &&
            !isCharDigitAt(input.value, input.selectionEnd!) &&
            event.key === input.value.charAt(input.selectionEnd!)
        ) {
            // if cursor is in front of $, comma, or decimal, or any other non-digits,
            // AND that same character is pressed, move the cursor over one position
            input.setSelectionRange(input.selectionEnd! + 1, input.selectionEnd! + 1);
            event.preventDefault();
        }

        //when arrowButtons is set to onHover, the buttons should be hidden when any keyboard typing action takes place
        if (arrowButtons === 'onHover') {
            setArrowButtonsVisible(false);
        }
        // this is to be checked in onInput
        signCharWasPressed = false;
        if (event.key === '-') {
            signCharWasPressed = true;
        }

        // This code is for back compatibility. Should be removed
        // in next version of UITK when code from begining of this function will use onKeyDown callback.
        if (props.onKeyDown) {
            props.onKeyDown(event);
        }
    }

    function onInput(event: FormEvent<HTMLInputElement>) {
        event.preventDefault();
        const input = event.target as HTMLInputElement;
        let eventValue = removeNonNumericChars(input.value);
        if (signCharWasPressed) {
            eventValue = convertSign(eventValue);
        }
        const valueAsNumber = getValueAsNumber(eventValue);

        // If value is invalid, rerender with the previous value and restore cursor position
        if (valueIsNotValid(eventValue, valueAsNumber)) {
            // adjustCursorIfMultipleDecimalOrDash();
            forceUpdate();
            return;
        }

        Object.assign(event, {
            value: eventValue,
            valueAsNumber,
        });
        if (props.onInput) {
            props.onInput(event as InputNumberKeyboardEvent);
        }

        if (props.onChange) {
            // Convert the KeyboardEvent to a ChangeEvent.
            const changeEvent = {
                currentTarget: event.currentTarget,
                target: event.target,
                nativeEvent: createCrossBrowserEvent({ type: 'change' }),
                value: eventValue,
                valueAsNumber,
            } as InputNumberChangeEvent;
            props.onChange(changeEvent);
        }
        setInternalValue(eventValue);

        // Need to re-render to update the cursor position, in both controlled and uncontrolled cases, especially if there is a formatter applied.
        forceUpdate();
    }

    function onClearClick() {
        if (otherProps.onClearClick) {
            otherProps.onClearClick();
        }
    }

    /**
     * Call this to update the <input>'s value, dispatch an event, and revert back
     * to previous value if controlled
     * @param updatedValue
     */
    function updateInputValue(updatedValue: number) {
        inputNumberRef.current!.value = `${updatedValue}`;
        inputNumberRef.current!.dispatchEvent(createCrossBrowserEvent({ type: 'input' }));
        // revert to previous value if controlled, in case app doesn't accept the change and set it back
        if (isControlled()) {
            inputNumberRef.current!.value = `${value}`;
        }
    }

    function hasFocus() {
        return isBrowser && inputNumberRef.current === document.activeElement;
    }

    // Prevents the input losing focus.
    // When clearable='onFocus', the input component will lose focus when we click the
    // clear button, preventing the click handlers to be invoked.
    // e.g. if clicking the clear button, the number does not get cleared.
    function onMouseDown(event: MouseEvent) {
        event.preventDefault();
    }

    function onIncrement(event?: MouseEvent) {
        const updatedValue = increment(effectiveValue, step, min, max);
        updateInputValue(updatedValue);
        setInternalValue(updatedValue);
        focusInternalInput();

        if (event) {
            // Prevent form submission:
            event.preventDefault();
        }
    }

    // Note: we need to handle the wheel events on both native 'wheel' event AND the react
    // onWheel event. Because calling onIncrement() from native handler doesn't flush
    // the stateChange calls (setInternalValue used in onIncrement and onDecrement)
    function onWheel(e: WheelEvent) {
        if (changeValueOnWheel && hasFocus()) {
            // preventDefault doesn't work with react onWheel events because react listens
            // to native wheel events with passive=true. https://stackoverflow.com/a/65795791
            // Therefore we are calling preventDefault() via native 'wheel' event in useEffect() below
            // event.preventDefault();
            if (e.deltaY < 0) {
                onIncrement();
            } else {
                onDecrement();
            }
        }
    }

    useEffect(() => {
        if (changeValueOnWheel && inputNumberRef.current) {
            const onWheelPreventDefault = (event: Event) => hasFocus() && event.preventDefault();
            inputNumberRef.current.addEventListener('wheel', onWheelPreventDefault, {
                passive: false,
            });
            return () =>
                inputNumberRef.current!.removeEventListener('wheel', onWheelPreventDefault);
        }
        return undefined;
    }, [changeValueOnWheel]);

    function onDecrement(event?: MouseEvent) {
        const updatedValue = decrement(effectiveValue, step, min, max);
        updateInputValue(updatedValue);
        setInternalValue(updatedValue);
        focusInternalInput();
        if (event) {
            // Prevent form submission:
            event.preventDefault();
        }
    }

    function focusInternalInput() {
        if (inputNumberRef.current) {
            inputNumberRef.current.focus();
        }
    }

    const effectiveValue = isControlled()
        ? getValidNumericValue(value)
        : internalValue != null
          ? internalValue
          : '';

    /**
     * If the input has value 22.20|0, and the user hits BACKSPACE, the new value is 22.2|0,
     * but the formatter will likely fill with 0's to 22.200.  Since this is the same value as the
     * previous value, we don't have a way to distinguish between the cases of:
     * a)  The app accepted the onChange and applied a formatted value by setting the 'value' controlled prop
     * b)  The app rejected the onChange and doesn't want any changes made
     * While the value is the same in both (a) and (b), we want to revert the cursor position
     * in the case of (b).  We need to make a choice which is most likely which is (a).
     * We minimize the risk of (b) by only allowing the case where all digits to the right
     * of the cursor are fractional (to the right of the decimal point) and they are all 0's.
     * The better way is to invoke the formatter separately from the onChange event.  Then we
     * can more easily separate these cases.
     */
    function areDigitsRightOfCursorFractionalAndZero() {
        // check if the cursor is on the right side of the last decimal point index
        if (
            inputNumberRef.current!.selectionEnd! <=
            getDecimalPointIndexOrStringLength(inputNumberRef.current!.value)
        ) {
            return false;
        }

        // check if all digits right of cursor are 0
        const input = inputNumberRef.current!;
        for (let i = input.selectionEnd!; i < input.value.length; i++) {
            if (isCharNonZeroDigitAt(input.value, i)) {
                return false;
            }
        }
        return true;
    }

    /**
     *  If a controlled app chooses not to update value from controlled event,
     *  we need to restore the cursor position - react doesn't handle this natively
     *  @returns a number if the position should be restored, undefined if not
     */
    function calcCursorPositionIfAppDidNotUpdateValue(
        currentCursorIndex: number
    ): number | undefined {
        if (value == null || value !== previousValue.current) {
            return undefined;
        }
        if (forwardDeleteWasPressed.current || areDigitsRightOfCursorFractionalAndZero()) {
            return currentCursorIndex;
        }
        // characters were either added or removed (via Backspace key).
        const numberOfCharsDiff =
            inputNumberRef.current!.value.length - formattedValue!.toString().length;
        // depending on if characters were added or removed, the cursor
        // should move left or right, back to its original position
        return currentCursorIndex - numberOfCharsDiff;
    }

    function onFocusInternal(event: FocusEvent<HTMLInputElement>) {
        if (arrowButtons === 'onFocus' || arrowButtons === 'onFocusOrHover') {
            setArrowButtonsVisible(true);
        }

        // if there is an abbreviation, need to rerender
        if (format?.abbrevOnBlur && effectiveValue != null && effectiveValue >= 1000) {
            forceUpdate();
        }

        const el = inputNumberRef.current as HTMLInputElement;
        setSelectionRangeOnFocus({
            value: internalValue,
            el,
            selectOnFocus: internalSelectOnFocus,
        });
        onFocus && onFocus(event);
    }

    /**
     * Find the first decimal point starting from the right.  If a decimal
     * point is not found, the value's length is returned.
     */
    function getDecimalPointIndexOrStringLength(value: string): number {
        for (let i = value.length - 1; i >= 0; i--) {
            if (value[i] === '.') {
                return i;
            }
        }
        return value.length;
    }

    /**
     * For controlled apps, the user can type a number such as 12345.34, the app will then
     * format this number into $12,345.34.  If the user just finished typing the "3", the
     * cursor will be at position 3, but with the formatted value, it should be at 5.
     *
     * Formatting can change the number of digits.  For example, 123.432 can get formatted to
     * 123.43, or 99.999 can be formatted to 100.00, we can not maintain the cursor position
     * from the left or right side - so we calculate based on the number of digits from the
     * decimal point.
     */
    function calcCursorPositionWithFormattedValue(): number | undefined {
        if (!inputNumberRef.current || isRangeSelected()) {
            return undefined;
        }

        let newPosition: number | undefined = undefined;
        const currentCursorIndex = inputNumberRef.current!.selectionEnd!;

        //selection range (selectionEnd and selectionStart) always resets to 0, returning undefined here
        //so we don't set the cursor position when selectionEnd is 0 (ex. when component is re-rendered on onFocus click)
        if (currentCursorIndex === 0) {
            return undefined;
        }

        // if the app did not update the value from the controlled event,
        // the original value will be restored, and this will calculate the cursor position
        newPosition = calcCursorPositionIfAppDidNotUpdateValue(currentCursorIndex);
        if (newPosition !== undefined) {
            return newPosition;
        }

        const valueFromInput = inputNumberRef.current!.value;
        const decimalIndexFromInput = getDecimalPointIndexOrStringLength(valueFromInput);

        // walk left-to-right (+1) or right-to-left (-1) from decimal point
        const walkDirection = currentCursorIndex > decimalIndexFromInput ? 1 : -1;

        // include left character of cursor if on right of decimal, For example:
        // 1|2,345.6789 : walkDirection = -1, walkTo the 2 (cursorIndex-1)
        // 12,345.678|9 : walkDirection = +1, walkTo the 8 (cursorIndex)
        const walkTo = walkDirection === 1 ? currentCursorIndex : currentCursorIndex - 1;

        // count the number of digits from the Input's value string from the
        // decimal point to the cursor.  For example "1|2,345.67" = 4 digits
        let digitsCountFromDecimal = 0;
        for (let i = decimalIndexFromInput + walkDirection; i !== walkTo; i = i + walkDirection) {
            const char = valueFromInput[i];
            if (/^\d|\.$/.test(char)) {
                digitsCountFromDecimal++;
            }
        }

        // Find the corresponding decimal point position from the 'value' prop.  This
        // may be different than the position in the input.
        const effectiveValueAsString = formattedValue!.toString();
        const decimalIndexFromValueProp =
            getDecimalPointIndexOrStringLength(effectiveValueAsString);

        // Count the same amount of digits from the 'value' prop's decimal point
        // For example, the user could type a 2 into the input leaving "12|34567.22"
        // The formatted value with the cursor should be "$1,2|34,567.22"
        let effectiveValDigitsCount = 0;
        if (digitsCountFromDecimal === 0) {
            // if the cursor is next to the decimal.  123.|45 or 123|.45
            newPosition =
                walkDirection === 1 ? decimalIndexFromValueProp + 1 : decimalIndexFromValueProp;
        } else {
            for (
                let i = decimalIndexFromValueProp + walkDirection;
                i >= 0 && i < effectiveValueAsString.length;
                i = i + walkDirection
            ) {
                const char = effectiveValueAsString[i];
                if (/^\d|\.$/.test(char)) {
                    effectiveValDigitsCount++;
                    if (effectiveValDigitsCount === digitsCountFromDecimal) {
                        newPosition = walkDirection === 1 ? i + 1 : i;
                        break;
                    }
                }
            }
        }
        // If going left from the decimal, move cursor past any grouping separators such as commas,
        // up to the next digit.  So if the cursor was here due to inserting commas:  $123,|456.78
        // move it to here:  $123|,456.78   That is where new digits will get placed and
        // is more intuitive to the end user. But don't go further than the last digit going left.
        if (newPosition !== undefined && walkDirection === -1) {
            let temp = newPosition - 1;
            while (temp >= 0 && /^\d$/.test(effectiveValueAsString[temp]) === false) {
                temp--;
            }
            newPosition = temp < 0 ? newPosition : temp + 1;
        }
        return newPosition;
    }

    // Apply formatter
    const formattedValue = formatAndAbbreviate({
        format,
        effectiveValue,
        hasFocus: hasFocus(),
        checkDecimalSeparator: hasCheckedDecimalSeparator.current,
    });
    hasCheckedDecimalSeparator.current = true;

    // If controlled, calculate the new cursor position using the current input state
    // and set it after rendering (after the formatted value has been applied)
    // Note: calling setSelectionRange() on mac will grab focus on Safari (stealing focus from other components)
    let cursorPositionWithFormattedValue: number | undefined = undefined;
    if (hasFocus()) {
        cursorPositionWithFormattedValue = calcCursorPositionWithFormattedValue();
    }
    useEffect(() => {
        if (cursorPositionWithFormattedValue !== undefined) {
            inputNumberRef.current!.setSelectionRange(
                cursorPositionWithFormattedValue,
                cursorPositionWithFormattedValue
            );
        }
        // update previousValue
        previousValue.current = value;
        // clear forward-delete-was-pressed check
        forwardDeleteWasPressed.current = false;
    });

    // These props are needed to:
    //   * accept hyphens for negative numbers
    //   * open the numeric keyboard on mobile
    // for both iOS/Safari and Android/Chrome platforms.  In the future,
    // we should display commas and spaces for locale-based formatting,
    // and shortcuts such as 20K or 8M.
    const numberPropsForBrowserPlatform = isSafari
        ? ({
              type: 'number',
          } as const)
        : ({
              inputMode: 'decimal',
              type: 'text',
          } as const);

    const incrementNumbers = arrowButtonsVisible ? (
        <div
            className={cx(
                cssClasses.ui,
                classes && classes.buttonsContainer,
                `${globalInputNumberClass}__buttons-container`
            )}
        >
            <button
                data-cy="gs-uitk-input-number__increment"
                className={cx(
                    cssClasses.buttonUp,
                    classes && classes.buttonUp,
                    `${globalInputNumberClass}__button-up`
                )}
                disabled={internalDisabled}
                tabIndex={-1}
                onClick={onIncrement}
                onMouseDown={onMouseDown}
                type="button"
            >
                <Icon name="arrow-drop-up" type="filled" />
            </button>
            <button
                data-cy="gs-uitk-input-number__decrement"
                className={cx(
                    cssClasses.buttonDown,
                    classes && classes.buttonDown,
                    `${globalInputNumberClass}__button-down`
                )}
                disabled={internalDisabled}
                tabIndex={-1}
                onClick={onDecrement}
                onMouseDown={onMouseDown}
                type="button"
            >
                <Icon name="arrow-drop-down" type="filled" />
            </button>
        </div>
    ) : null;

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({
            componentName: 'input-number',
            officialComponentName: 'other',
        });
    }, []); // Only run once

    return (
        <InputInternal
            trailingInternalContent={incrementNumbers}
            {...numberPropsForBrowserPlatform}
            {...otherProps}
            classes={classes}
            disabled={disabled}
            size={size}
            componentType="number"
            value={formattedValue}
            onWheel={onWheel}
            onKeyDown={onKeyDown}
            onMouseEnter={onMouseEnterInternal}
            onMouseLeave={onMouseLeaveInternal}
            onBlur={onBlurInternal}
            onFocus={onFocusInternal}
            onInput={onInput}
            onClearClick={onClearClick}
            inputRef={inputNumberCallbackRef}
            changeValueOnWheel={changeValueOnWheel}
        />
    );
};
