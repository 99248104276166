import { createRef, Component } from 'react';
import { connect } from 'react-redux';
import * as Redux from 'redux';
import { Tabs, Tab } from '@gs-ux-uitoolkit-react/tabs';
import {
    ExpressionMode,
    Expression,
    QuickFilter,
    globalHelper,
    UpdateEditedQuickFilter,
    genericExpressionHelper,
    ExpressionQuery,
    ExpressionGroup,
    ExpressionRule,
} from '@gs-ux-uitoolkit-common/datacore';
import { DataGridState } from '../../../redux/datagrid-state';
import { QuickFilterName } from './quick-filter-name';
import { QuickFilterExpression } from './quick-filter-expression';
import { GridColumn, GetFormattedValueFromColumn } from '../../../grid-wrappers/grid-wrapper';
import { quickfilterModalStyleSheet } from '../../../style/component/quickfilter-modal-stylesheet';
import { ExpressionPreview } from '../../../components/expression/component/expression-previewer';
import { PluginPreviewComponent } from '../../../components/plugin-preview/plugin-preview-layout';
import { expressionHelper } from '../../../libraries/helpers/expression-helper';

export interface QuickFilterCreateEditProps {
    expression: Expression;
    quickfiler: QuickFilter;
    configItemList: QuickFilter[];
    columns: GridColumn[];
    /**
     * update the edited config item
     */
    updateEditedConfigItem: (newData: Partial<QuickFilter>) => void;
    onChangeExpression: (newData: Partial<QuickFilter>) => void;
    gridGetColumnValueList: (columnId: string) => void;
    /**
     * Reset the column valueList in the store
     */
    gridResetColumnValueList: (columnId: string | null) => void;

    getFormattedValueFromColumn: GetFormattedValueFromColumn;
}
export interface QuickFilterCreateEditState {
    nameIsNull: string;
    nameIsDuplicate: string;
    expressionNotValid: string;
}

export class QuickFilterCreateEditComponent extends Component<
    QuickFilterCreateEditProps,
    QuickFilterCreateEditState
> {
    private containerRef = createRef<HTMLDivElement>();
    public constructor(props: QuickFilterCreateEditProps) {
        super(props);
        this.state = {
            expressionNotValid: '',
            nameIsDuplicate: '',
            nameIsNull: '',
        };
    }
    public componentWillUnmount() {
        quickfilterModalStyleSheet.unmount(this);
    }
    public getRef() {
        return this.containerRef;
    }

    public render() {
        const cssClasses = quickfilterModalStyleSheet.mount(this, null);
        return (
            <div
                className={`${cssClasses.quickFilterContainer} gs-uitk-quickfilter__${(
                    this.props.expression.mode || ExpressionMode.Basic
                ).toLowerCase()}`}
            >
                <Tabs
                    activeTabKey={this.props.expression.mode || ExpressionMode.Basic}
                    onSelect={this.onTabChange}
                >
                    {this.getExpressionTabs()}
                </Tabs>
            </div>
        );
    }
    private ruleHasEmptyField(query: ExpressionQuery): boolean {
        //is it a group ?
        let hasEmptyField = false;
        const isAGroup = expressionHelper.isGroupRule(query);

        if (isAGroup) {
            hasEmptyField = !!(query as ExpressionGroup).rules.find(rule => {
                return this.ruleHasEmptyField(rule);
            });
        } else if ((query as ExpressionRule).field === '') {
            hasEmptyField = true;
        }
        return hasEmptyField;
    }
    private getExpressionTabs = () => {
        return Object.keys(ExpressionMode).map(key => {
            let previewString = '';
            if (!this.ruleHasEmptyField(this.props.expression.query)) {
                previewString = genericExpressionHelper.toString(
                    this.props.expression.query,
                    this.props.columns.map(column => ({
                        columnId: column.columnId,
                        columnLabel: column.columnLabel,
                        pivotKeys: column.pivotKeys,
                        primaryColumnId: column.primaryColumnId || '',
                    })),
                    true
                );
            }
            return (
                <Tab
                    tabKey={key}
                    title={key === ExpressionMode.Experienced ? 'Advanced' : key}
                    key={key}
                >
                    <div className="uitk-quickfilter-modal__expression-main-container">
                        {this.getNameElement()}
                        <div className="uitk-quickfilter-modal__expression-container">
                            <div className="uitk-quickfilter-modal__expression">
                                <QuickFilterExpression
                                    columns={this.props.columns}
                                    expression={this.props.expression}
                                    gridGetColumnValueList={this.props.gridGetColumnValueList}
                                    gridResetColumnValueList={this.props.gridResetColumnValueList}
                                    getFormattedValueFromColumn={
                                        this.props.getFormattedValueFromColumn
                                    }
                                    onChangeExpression={this.onChangeExpression}
                                    expressionMode={key as ExpressionMode}
                                ></QuickFilterExpression>
                            </div>

                            <PluginPreviewComponent
                                previewTitle={'Filter Preview'}
                                previewBody={
                                    this.isExpressionBasic() && key === ExpressionMode.Basic ? (
                                        <ExpressionPreview
                                            expressionQuery={this.props.expression.query}
                                            gridColumnList={this.props.columns}
                                            showTitle={false}
                                        />
                                    ) : (
                                        <div className="experienced-expression-filter-preview">
                                            {previewString}
                                        </div>
                                    )
                                }
                            />
                        </div>
                    </div>
                </Tab>
            );
        });
    };
    private isExpressionBasic = () => {
        return genericExpressionHelper.isBasicCompatibleExpression(this.props.expression.query);
    };

    private getNameElement = () => {
        return (
            <QuickFilterName
                data={this.props.quickfiler}
                nameIsDuplicate={this.state.nameIsDuplicate}
                nameIsNull={this.state.nameIsNull}
                expressionNotValid={this.state.expressionNotValid}
                configItemList={this.props.configItemList}
                onNameChange={data => {
                    this.onNameChange(data);
                }}
            />
        );
    };
    /**
     * Updates config item on name change
     */
    private onNameChange(data: Partial<QuickFilter>) {
        this.props.updateEditedConfigItem(data);
    }
    private onChangeExpression = (expression: Expression) => {
        this.props.onChangeExpression({ expression });
    };
    private onTabChange = (mode: ExpressionMode) => {
        this.props.onChangeExpression({
            expression: {
                mode,
                query: globalHelper.cloneObject(this.props.expression.query),
            },
        });
    };
}

const mapStateToProps = (state: DataGridState) => {
    return {
        configItemList: state.quickFilter.configItemList,
    };
};

const mapDispatchToProps = (dispatch: (action: Redux.Action) => void) => {
    return {
        updateEditedConfigItem: (newData: Partial<QuickFilter>) =>
            dispatch(UpdateEditedQuickFilter(newData)),
    };
};
export const QuickFilterCreateEdit = connect(
    mapStateToProps,
    mapDispatchToProps
)(QuickFilterCreateEditComponent);
