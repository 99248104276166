import { CssPropertyType, CssProperties } from '@gs-ux-uitoolkit-common/style';
import { ButtonSize, ButtonEmphasis } from '../button/button-props';
import { Theme } from '@gs-ux-uitoolkit-common/theme';

// Temporary hard-coded values until Button is Themed
export const focusedBorderMargin = '-2px';

export function applyButtonCommonBase(): CssProperties {
    return {
        display: 'inline-block',
        cursor: 'pointer',
        userSelect: 'none',
        whiteSpace: 'nowrap', // fix header bug
        position: 'relative', // to position the ::before focus element
        boxSizing: 'border-box',
        width: '100%',
    };
}

export function applyButtonTypography(props: { size: ButtonSize; theme: Theme }) {
    const { size, theme } = props;
    if (size === 'sm') {
        return theme.typography.heading07;
    }
    if (size === 'md') {
        return theme.typography.heading06;
    }
    return theme.typography.heading05;
}

export function applyButtonIconAndTextPositions(props: {
    emphasis?: ButtonEmphasis;
    size: ButtonSize;
}): CssProperties {
    const { emphasis, size } = props;

    const subtractBorders = emphasis === 'subtle' ? '1px' : '0px';

    return {
        "> :only-child:not([data-gs-uitk-component='icon'])": {
            paddingLeft: `calc(${buttonSizeVariants[size].textOnlyPadding} - ${subtractBorders})`,
            paddingRight: `calc(${buttonSizeVariants[size].textOnlyPadding} - ${subtractBorders})`,
        },

        "> [data-gs-uitk-component='icon']:only-child": {
            paddingLeft: `calc(${buttonSizeVariants[size].iconOnlyPadding} - ${subtractBorders})`,
            paddingRight: `calc(${buttonSizeVariants[size].iconOnlyPadding} - ${subtractBorders})`,
        },

        "> [data-gs-uitk-component='icon']:first-child:not(:only-child)": {
            paddingLeft: buttonSizeVariants[size].iconOuterSidePadding,
            paddingRight: buttonSizeVariants[size].iconInnerSidePadding,
        },

        "> :last-child:not(:only-child):not([data-gs-uitk-component='icon'])": {
            paddingRight: buttonSizeVariants[size].textOnlyPadding,
        },

        "> :not([data-gs-uitk-component='icon']):first-child:not(:only-child)": {
            paddingLeft: buttonSizeVariants[size].textOnlyPadding,
        },

        "> [data-gs-uitk-component='icon']:last-child:not(:only-child)": {
            paddingLeft: buttonSizeVariants[size].iconInnerSidePadding,
            paddingRight: buttonSizeVariants[size].iconOuterSidePadding,
        },
    };
}

/**
 * Private variable for internal UI Toolkit code, we reserve the right to change this var as needed.
 */
export const buttonSizeVariants: { [name in ButtonSize]: ButtonSizeVariant } = {
    sm: {
        buttonHeight: '24px',
        buttonPaddingY: '4px',
        iconFontSize: '16px',
        textOnlyPadding: '12px',
        iconOnlyPadding: '4px',
        iconOuterSidePadding: '6px',
        iconInnerSidePadding: '4px',
        circleRadius: '14px',
    },
    md: {
        buttonHeight: '32px',
        buttonPaddingY: '6px',
        iconFontSize: '20px',
        textOnlyPadding: '16px',
        iconOnlyPadding: '6px',
        iconOuterSidePadding: '8px',
        iconInnerSidePadding: '6px',
        circleRadius: '18px',
    },
    lg: {
        buttonHeight: '40px',
        buttonPaddingY: '8px',
        iconFontSize: '24px',
        textOnlyPadding: '20px',
        iconOnlyPadding: '8px',
        iconOuterSidePadding: '10px',
        iconInnerSidePadding: '8px',
        circleRadius: '22px',
    },
    xl: {
        buttonHeight: '48px',
        buttonPaddingY: '12px',
        iconFontSize: '24px',
        textOnlyPadding: '32px',
        iconOnlyPadding: '12px',
        iconOuterSidePadding: '24px',
        iconInnerSidePadding: '12px',
        circleRadius: '26px',
    },
};

export interface ButtonSizeVariant {
    buttonHeight: CssPropertyType['height'];
    buttonPaddingY: CssPropertyType['padding'];
    iconFontSize: CssPropertyType['fontSize'];
    textOnlyPadding: CssPropertyType['padding'];
    iconOnlyPadding: CssPropertyType['padding'];
    iconOuterSidePadding: CssPropertyType['padding'];
    iconInnerSidePadding: CssPropertyType['padding'];
    circleRadius: CssPropertyType['borderRadius'];
}
