import { Button } from '@gs-ux-uitoolkit-react/button';
import { ListGroup, ListGroupItem } from '@gs-ux-uitoolkit-react/list-group';
import { Container, Col, Row } from '@gs-ux-uitoolkit-react/layout';

import { MouseEvent, Component } from 'react';
import { arrayHelper } from '../../libraries/helpers/array-helper';
import {
    dualListBoxStyleSheet,
    getDualListBoxClassNames,
} from '../../style/dual-list-box-stylesheet';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';

export interface DualListBoxCategoryInfo {
    name: string;
    ui?: JSX.Element;
}

export interface DualListBoxProps {
    firstCategoryValues: DualListBoxCategoryInfo[];
    secondCategoryValues: DualListBoxCategoryInfo[];
    firstCategoryLabel?: string;
    secondCategoryLabel?: string;
    itemChanged: (
        firstCategoryItems: DualListBoxCategoryInfo[],
        secondCategoryItems: DualListBoxCategoryInfo[]
    ) => void;
}

export interface DualListBoxState {
    firstCategoryValuesSelected: DualListBoxCategoryInfo[];
    lastFirstCategoryValuesSelected: DualListBoxCategoryInfo | null;
    secondCategoryValuesSelected: DualListBoxCategoryInfo[];
    lastSecondCategoryValuesSelected: DualListBoxCategoryInfo | null;
}

export const addAllLabel = 'Add All ';
export const removeAllLabel = ' Remove All';
export const addLabel = 'Add ';
export const removeLabel = ' Remove';
export const topLabel = ' Top';
export const upLabel = ' Up';
export const downLabel = ' Down';
export const bottomLabel = ' Bottom';

export class DualListBox extends Component<DualListBoxProps, DualListBoxState> {
    public static defaultProps: Partial<DualListBoxProps> = {
        firstCategoryLabel: 'Available',
        secondCategoryLabel: 'Selected',
    };
    constructor(props: DualListBoxProps) {
        super(props);

        this.state = {
            firstCategoryValuesSelected: [],
            lastFirstCategoryValuesSelected: null,
            lastSecondCategoryValuesSelected: null,
            secondCategoryValuesSelected: [],
        };
    }
    public componentWillUnmount() {
        dualListBoxStyleSheet.unmount(this);
    }
    public render() {
        const cssClasses = dualListBoxStyleSheet.mount(this, null);

        const firstCategoryLisboxItems = this.props.firstCategoryValues.map(value => (
            <ListGroupItem
                key={value.name}
                action={true}
                active={!!this.state.firstCategoryValuesSelected.find(x => x.name === value.name)}
                onClick={(e: MouseEvent<HTMLElement>) => this.onClickFirstCategoryItem(e, value)}
            >
                {value.ui ? value.ui : value.name}
            </ListGroupItem>
        ));
        const secondCategoryLisboxItems = this.props.secondCategoryValues.map(value => (
            <ListGroupItem
                key={value.name}
                action={true}
                active={!!this.state.secondCategoryValuesSelected.find(x => x.name === value.name)}
                onClick={(e: MouseEvent<HTMLElement>) => this.onClickSecondCategoryItem(e, value)}
            >
                {value.ui ? value.ui : value.name}
            </ListGroupItem>
        ));
        return (
            <Container className={`dual-list-box ${getDualListBoxClassNames({ cssClasses })}`}>
                <Row>
                    <Col>
                        <h3 className="category-label">{this.props.firstCategoryLabel}</h3>
                    </Col>
                    <Col xs={3} />
                    <Col>
                        <h3 className="category-label">{this.props.secondCategoryLabel}</h3>
                    </Col>
                    <Col xs={3} />
                </Row>
                <Row className="row-list-group">
                    <Col>
                        <ListGroup data-cy="dual-listbox-available">
                            {firstCategoryLisboxItems}
                        </ListGroup>
                    </Col>
                    <Col className="column-action" xs={3} data-cy="dual-listbox-addall">
                        <Button
                            size="sm"
                            disabled={!this.canAddAll()}
                            onClick={() => this.addAll()}
                        >
                            {addAllLabel}
                            <Icon name="chevron-double-right" type="filled" />
                        </Button>
                        <Button
                            size="sm"
                            disabled={!this.canAdd()}
                            onClick={() => this.add()}
                            data-cy="dual-listbox-add"
                        >
                            {addLabel}
                            <Icon name="keyboard-arrow-right" type="filled" />
                        </Button>
                        <Button
                            size="sm"
                            disabled={!this.canRemove()}
                            onClick={() => this.remove()}
                            data-cy="dual-listbox-remove"
                        >
                            <Icon name="keyboard-arrow-left" type="filled" />
                            {removeLabel}
                        </Button>
                        <Button
                            size="sm"
                            disabled={!this.canRemoveAll()}
                            onClick={() => this.removeAll()}
                            data-cy="dual-listbox-removeall"
                        >
                            <Icon name="chevron-double-left" type="filled" />
                            {removeAllLabel}
                        </Button>
                    </Col>
                    <Col>
                        <ListGroup data-cy="dual-listbox-selected">
                            {secondCategoryLisboxItems}
                        </ListGroup>
                    </Col>
                    <Col className="column-action" xs={3}>
                        <Button size="sm" disabled={!this.canTop()} onClick={() => this.top()}>
                            <Icon name="keyboard-arrow-up" type="filled" />
                            {topLabel}
                        </Button>
                        <Button size="sm" disabled={!this.canUp()} onClick={() => this.up()}>
                            <Icon name="keyboard-arrow-up" type="filled" />
                            {upLabel}
                        </Button>
                        <Button size="sm" disabled={!this.canDown()} onClick={() => this.down()}>
                            <Icon name="keyboard-arrow-down" type="filled" />
                            {downLabel}
                        </Button>
                        <Button
                            size="sm"
                            disabled={!this.canBottom()}
                            onClick={() => this.bottom()}
                        >
                            <Icon name="keyboard-arrow-down" type="filled" />
                            {bottomLabel}
                        </Button>
                    </Col>
                </Row>
            </Container>
        );
    }

    private onClickFirstCategoryItem(
        e: MouseEvent<HTMLElement>,
        clickedValue: DualListBoxCategoryInfo
    ) {
        if (this.state.firstCategoryValuesSelected.find(x => x.name === clickedValue.name)) {
            this.setState({
                firstCategoryValuesSelected: this.state.firstCategoryValuesSelected.filter(
                    fc => fc.name !== clickedValue.name
                ),
                lastFirstCategoryValuesSelected: clickedValue,
            });
        } else {
            const indexOfClickedValue = this.props.firstCategoryValues.findIndex(
                x => x.name === clickedValue.name
            );
            const indexOfLastSelectedValue = e.shiftKey
                ? this.props.firstCategoryValues.findIndex(
                      x =>
                          x.name ===
                          (this.state.lastFirstCategoryValuesSelected &&
                              this.state.lastFirstCategoryValuesSelected.name)
                  )
                : indexOfClickedValue;
            const firstIndex = Math.min(indexOfLastSelectedValue, indexOfClickedValue);
            const lastIndex = Math.max(indexOfLastSelectedValue, indexOfClickedValue);
            this.setState({
                firstCategoryValuesSelected: this.props.firstCategoryValues.filter(
                    value =>
                        this.state.firstCategoryValuesSelected.find(x => x.name === value.name) ||
                        (this.props.firstCategoryValues.findIndex(x => x.name === value.name) >=
                            firstIndex &&
                            this.props.firstCategoryValues.findIndex(x => x.name === value.name) <=
                                lastIndex)
                ),
                lastFirstCategoryValuesSelected: clickedValue,
            });
        }
    }
    private onClickSecondCategoryItem(
        e: MouseEvent<HTMLElement>,
        clickedValue: DualListBoxCategoryInfo
    ) {
        if (this.state.secondCategoryValuesSelected.find(x => x.name === clickedValue.name)) {
            this.setState({
                lastSecondCategoryValuesSelected: clickedValue,
                secondCategoryValuesSelected: this.state.secondCategoryValuesSelected.filter(
                    fc => fc.name !== clickedValue.name
                ),
            });
        } else {
            const indexOfClickedValue = this.props.secondCategoryValues.findIndex(
                x => x.name === clickedValue.name
            );
            const indexOfLastSelectedValue = e.shiftKey
                ? this.props.secondCategoryValues.findIndex(
                      x =>
                          x.name ===
                          (this.state.lastSecondCategoryValuesSelected &&
                              this.state.lastSecondCategoryValuesSelected.name)
                  )
                : indexOfClickedValue;
            const firstIndex = Math.min(indexOfLastSelectedValue, indexOfClickedValue);
            const lastIndex = Math.max(indexOfLastSelectedValue, indexOfClickedValue);
            this.setState({
                lastSecondCategoryValuesSelected: clickedValue,
                secondCategoryValuesSelected: this.props.secondCategoryValues.filter(
                    value =>
                        this.state.secondCategoryValuesSelected.find(x => x.name === value.name) ||
                        (this.props.secondCategoryValues.findIndex(x => x.name === value.name) >=
                            firstIndex &&
                            this.props.secondCategoryValues.findIndex(x => x.name === value.name) <=
                                lastIndex)
                ),
            });
        }
    }
    private canAddAll(): boolean {
        return this.props.firstCategoryValues.length !== 0;
    }
    private addAll() {
        this.props.itemChanged(
            [],
            [...this.props.secondCategoryValues, ...this.props.firstCategoryValues]
        );
        this.setState({
            firstCategoryValuesSelected: [],
            lastFirstCategoryValuesSelected: null,
        });
    }

    private canRemoveAll(): boolean {
        return this.props.secondCategoryValues.length !== 0;
    }
    private removeAll() {
        this.props.itemChanged(
            [...this.props.firstCategoryValues, ...this.props.secondCategoryValues],
            []
        );
        this.setState({
            lastSecondCategoryValuesSelected: null,
            secondCategoryValuesSelected: [],
        });
    }

    private canAdd(): boolean {
        return this.state.firstCategoryValuesSelected.length !== 0;
    }
    private add() {
        this.props.itemChanged(
            this.props.firstCategoryValues.filter(
                value => !this.state.firstCategoryValuesSelected.includes(value)
            ),
            [...this.props.secondCategoryValues, ...this.state.firstCategoryValuesSelected]
        );
        this.setState({
            firstCategoryValuesSelected: [],
            lastFirstCategoryValuesSelected: null,
        });
    }

    private canRemove(): boolean {
        return this.state.secondCategoryValuesSelected.length !== 0;
    }
    private remove() {
        this.props.itemChanged(
            [...this.props.firstCategoryValues, ...this.state.secondCategoryValuesSelected],
            this.props.secondCategoryValues.filter(
                value => !this.state.secondCategoryValuesSelected.find(x => x.name === value.name)
            )
        );
        this.setState({
            lastSecondCategoryValuesSelected: null,
            secondCategoryValuesSelected: [],
        });
    }

    private canTop(): boolean {
        const firstSelectedElement = arrayHelper.first(this.state.secondCategoryValuesSelected);
        const firstSecondCategoryValues = arrayHelper.first(this.props.secondCategoryValues);
        return (
            this.state.secondCategoryValuesSelected.length !== 0 &&
            firstSelectedElement != null &&
            firstSecondCategoryValues != null &&
            firstSecondCategoryValues.name !== firstSelectedElement.name
        );
    }
    private top() {
        this.props.itemChanged(
            [...this.props.firstCategoryValues],
            [
                ...this.state.secondCategoryValuesSelected,
                ...this.props.secondCategoryValues.filter(
                    value =>
                        !this.state.secondCategoryValuesSelected.find(x => x.name === value.name)
                ),
            ]
        );
    }

    private canBottom(): boolean {
        const lastSelectedElement = arrayHelper.last(this.state.secondCategoryValuesSelected);
        const lastSecondCategoryValues = arrayHelper.last(this.props.secondCategoryValues);
        return (
            this.state.secondCategoryValuesSelected.length !== 0 &&
            lastSelectedElement != null &&
            lastSecondCategoryValues != null &&
            lastSecondCategoryValues.name !== lastSelectedElement.name
        );
    }
    private bottom() {
        this.props.itemChanged(
            [...this.props.firstCategoryValues],
            [
                ...this.props.secondCategoryValues.filter(
                    value =>
                        !this.state.secondCategoryValuesSelected.find(x => x.name === value.name)
                ),
                ...this.state.secondCategoryValuesSelected,
            ]
        );
    }

    private canUp(): boolean {
        return this.canTop();
    }
    private up() {
        let newSecondCategoryValues = [...this.props.secondCategoryValues];
        this.state.secondCategoryValuesSelected.forEach(value => {
            const indexOfValue = newSecondCategoryValues.findIndex(x => x.name === value.name);
            newSecondCategoryValues = arrayHelper.moveItem(
                newSecondCategoryValues,
                value,
                indexOfValue - 1,
                'name'
            );
        });

        this.props.itemChanged([...this.props.firstCategoryValues], newSecondCategoryValues);
    }

    private canDown(): boolean {
        return this.canBottom();
    }
    private down() {
        let newSecondCategoryValues = [...this.props.secondCategoryValues];
        const secondCategoryValuesSelected = [...this.state.secondCategoryValuesSelected];
        secondCategoryValuesSelected.reverse().forEach(value => {
            const indexOfValue = newSecondCategoryValues.findIndex(x => x.name === value.name);
            newSecondCategoryValues = arrayHelper.moveItem(
                newSecondCategoryValues,
                value,
                indexOfValue + 1,
                'name'
            );
        });

        this.props.itemChanged([...this.props.firstCategoryValues], newSecondCategoryValues);
    }
}
