export default {
  FETCH_USER_DATA: {
    'path': '/api/v1/users/current',
    'method': 'GET'
  },
  FETCH_USER_PROFILE_DETAILS: {
    'path': '/api/v1/users/profile/{profileId}',
    'method': 'GET'
  },
  SAVE_CUSTOM_VIEW: {
    'path': '/api/v1/users/views/PORTFOLIO',
    'method': 'POST',
    'parameters': {
      'pageContext.groupBy': 'groupBy'
    }
  },
  EDIT_CUSTOM_VIEW: {
    'path': '/api/v1/users/views/PORTFOLIO',
    'method': 'POST',
    'parameters': {
      'pageContext.groupBy': 'groupBy'
    }
  },
  DELETE_CUSTOM_VIEW: {
    'path': '/api/v1/users/views/PORTFOLIO',
    'method': 'POST'
  },
  ACTIVATE_PORTFOLIO_VIEW: {
    'path': '/api/v1/users/views/PORTFOLIO/',
    'method': 'POST'
  },
  FETCH_PORTFOLIO_VIEWS: {
    'path': '/api/v1/users/views/PORTFOLIO',
    'method': 'GET'
  },
  FETCH_USER_PREFERENCES: {
    'path': '/api/v1/users/preferences',
    'method': 'GET'
  },
  EDIT_USER_PREFERENCES: {
    'path': '/api/v1/users/preferences',
    'method': 'POST'
  },
  LOGOUT: {
    'path': '/api/v1/users/logout',
    'method': 'GET'
  },
  FETCH_BATCH_DELAY: {
    'path': '/api/v1/users/checkbatchdelay',
    'method': 'GET'
  },
  FETCH_FUND_FINDER_VIEWS: {
    'path': '/api/v1/users/views/FUNDFINDER',
    'method': 'GET'
  },

  ACTIVATE_FUND_FINDER_VIEW: {
    'path': '/api/v1/users/views/FUNDFINDER/',
    'method': 'POST'
  },

  DELETE_FUND_FINDER_VIEW: {
    'path': '/api/v1/users/views/FUNDFINDER',
    'method': 'POST'
  },
  EDIT_FUND_FINDER_VIEW: {
    'path': '/api/v1/users/views/FUNDFINDER',
    'method': 'POST',
    'parameters': {
      'pageContext.fundManagers': 'fundManagers',
      'pageContext.groupBy': 'groupBy'
    }
  },
  EDIT_CURRENT_TRADES_VIEW: {
    'path': '/api/v1/users/views/CURRENTTRADESTATUS',
    'method': 'POST',
  },
  EDIT_USER_SEARCH_VIEW: {
    'path': '/api/v1/users/views/SELFSERVICEADMINUSERSEARCH',
    'method': 'POST',
  },
  FETCH_REPORTING_PERMIT_ROLES: {
    'path': `/api/v1/entitlements/getroles/rpt`,
    'method': 'GET'
  },
  FETCH_USER_ENTITLED_SHARECLASS_LIST: {
    'path': '/api/v1/entitlements/getshareclasseswithdetails/currentuser',
    'method': 'GET'
  },
  POLL_TICKER_API: {
    'path': '/api/v1/users/ticker',
    'method': 'GET'
  },
  FETCH_TRADE_ACTIVITY_CURRENT_TRADE_VIEWS: {
    'path': '/api/v1/users/views/CURRENTTRADESTATUS',
    'method': 'GET'
  },
  FETCH_USER_LAST_LOGIN_TIME: {
    'path': '/api/v1/users/capturelogintime?isMimic={isMimicSession}',
    'method':'POST'
  },
  SAVE_FUND_TRACKER_THRESHOLD: {
    'path': '/api/v1/users/fundTrackerThresholdSave',
    'method': 'POST'
  },
  DELETE_FUND_TRACKER_THRESHOLD: {
    'path': '/api/v1/users/fundTrackerThresholdDelete',
    'method': 'POST'
  },
  FETCH_FUND_TRACKER_THRESHOLDS: {
    'path': '/api/v1/users/fundTrackerThreshold/{userGuid}',
    'method': 'GET'
  }
}
