import { ReactNode, FunctionComponent, useContext, CSSProperties, ElementType } from 'react';
import PropTypes from 'prop-types';
import {
    FormTextProps as FormTextCommonProps,
    defaultFormTextProps,
    formTextStyleSheet,
    formSizes,
    getFormTextClassNames,
} from '@gs-ux-uitoolkit-common/form';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { FormContext } from './form-context';

export interface FormTextProps extends FormTextCommonProps<CSSProperties> {
    children?: ReactNode;

    /**
     * Specify the container HTML tag. Default is `p`.
     */
    tag?: string;
}

/**
 * FormText contains text that provides information a user will need in order to
 * enter data into a specific input field. Unlike FormFeedback, which conveys
 * the current status of an input (such as information about an error), FormText
 * conveys information that does not change with the status (such as the correct
 * telephone number format for an input of type "tel").
 *
 * When using both a FormText and a FormFeedback to convey information about a
 * single input component, it is highly recommended that only one of them is
 * displayed at a time.
 */
export const FormText: FunctionComponent<FormTextProps> = (props: FormTextProps) => {
    const { children, className, disabled, size, tag = 'p', ...otherProps } = props;
    const formContext = useContext(FormContext);

    const formTextSize = size! || formContext.size || defaultFormTextProps.size;
    const formTextDisabled =
        disabled !== undefined ? disabled : formContext.disabled || defaultFormTextProps.disabled;

    const theme = useTheme();
    const cssClasses: any = useStyleSheet(formTextStyleSheet, {
        theme: theme,
        size: formTextSize,
        disabled: formTextDisabled || false,
    });
    const containerClasses = getFormTextClassNames({ className, cssClasses });

    const Tag = (tag || 'p') as ElementType<FormTextProps>;
    return (
        <Tag
            data-gs-uitk-component="form-text"
            data-disabled={formTextDisabled}
            className={containerClasses}
            {...otherProps}
            data-size={formTextSize}
        >
            {children}
        </Tag>
    );
};

FormText.propTypes = {
    tag: PropTypes.string,
    size: PropTypes.oneOf(formSizes),
};
