import { cx } from '@gs-ux-uitoolkit-common/style';
import { BadgeOverlayProps } from './badge-overlay-props';
import { BadgeCssClasses } from './badge-style-sheet';
import { BadgeOverlayCssClasses } from './badge-overlay-style-sheet';

const globalClassName = 'gs-badge-overlay';

export const getBadgeOverlayRootClasses = (props: {
    cssClasses: BadgeOverlayCssClasses;
    overrideClasses?: BadgeOverlayProps['classes'];
    className?: BadgeOverlayProps['className'];
}): string => {
    const { cssClasses, className, overrideClasses } = props;
    return cx(cssClasses.root, className, overrideClasses && overrideClasses.root, globalClassName);
};

export const getBadgeOverlayBadgeClasses = (props: {
    cssClasses: BadgeCssClasses;
    overrideClasses?: BadgeOverlayProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.badge,
        overrideClasses && overrideClasses.badge,
        `${globalClassName}__badge`
    );
};
