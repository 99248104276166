import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
// Containers
import PortfolioCustomView from '../../containers/Portfolio/CustomView';
import FundFactView from '../../containers/FundFactView';
import ControlPanel from '../../containers/Portfolio/ControlPanel';
import PortfolioGrid from '../../containers/Portfolio/Grid';
import PortfolioHeader from '../../containers/Portfolio/Header';
import Error from '../../components/core/Error';
import './index.scss';
import {SHOW_ERROR_ON} from '../../constants/pageConstants';
import {Conditional} from '../../components/core/Conditional';
import {getLabel} from '../../selectors/app';
import {
  checkIsPageInErrorMode,
  getErrorObject
} from '../../selectors/pages/portfolio';

const Portfolio = () => (
  <div className="js-main-content main-container__content">
    <PortfolioCustomView />
    <FundFactView />
    <section className="portfolio" id="portfolio">
      <PortfolioHeader />
      <div className="portfolio-container">
        <ControlPanel />
        <PortfolioGrid />
      </div>
    </section>
  </div>);

export const portfolio = (props) => {
  const {label, errorObject} = props;
  const error = {
    code: errorObject && errorObject.errorCode,
    label
  };
  return (
    <Conditional condition={!(props.isPageInErrorMode &&
        (errorObject.showErrorOn === SHOW_ERROR_ON.PAGE))}>
      <Portfolio />
      <div className="js-main-content main-container__content">
        <Error {...error} />
      </div>
    </Conditional>
  );
};

portfolio.propTypes = {
  isPageInErrorMode: PropTypes.bool,
  label: PropTypes.func,
  errorObject: PropTypes.object
};

const mapStateToProps = (state) => (
  {
    isPageInErrorMode: checkIsPageInErrorMode(state),
    errorObject: getErrorObject(state),
    label: key => getLabel(state, key)
  }
);
export default connect(mapStateToProps)(portfolio);
