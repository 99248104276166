import { ReactElement, ReactNode, Component, Children, RefObject } from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'reactstrap';
import { NormalTabTitle } from './NormalTabTitle';
import { DropdownTabTitle } from './DropdownTabTitle'; // this is for overflow 'tab' in responsive tabs
import { DropdownTab } from './DropdownTab'; // this is for dropdown tab (tab with a submenu)
import { containsActiveTab, isDropdown, isDropdownTab, isValidTabsChild } from './tab-utils';
import {
    tabsStyleSheet,
    getTabsContainerClasses,
    TabsCommonProps,
    TabsSize,
} from '@gs-ux-uitoolkit-common/tabs';
import { ThemeConsumer, Theme } from '@gs-ux-uitoolkit-react/theme';
import { EmotionInstanceContext } from '@gs-ux-uitoolkit-react/style';

const createResponsiveTabTitles = (
    tabs: ReactElement[],
    activeTabKey: string | number,
    overflowCount: number,
    onSelect: (tabKey: string | number) => void,
    onKeydown: (props: { event: React.KeyboardEvent; tabKey: string | number }) => void,
    vertical: boolean,
    variant: 'tabs' | 'pills',
    size: TabsSize,
    responsiveTabKey: string | number
): JSX.Element[] => {
    const pivot = tabs.length - overflowCount;
    const visibleTabs = tabs.slice(0, pivot);
    const overflowTabs = tabs.slice(pivot, tabs.length);
    return [
        ...visibleTabs.map((tab: ReactElement) => {
            const {
                props: { disabled, tabKey, title, ...rest },
                props,
            } = tab;

            if (isDropdownTab(tab)) {
                const submenuSelected =
                    isDropdown(props.children) && containsActiveTab(props.children, activeTabKey);
                const level2NavItems = props.children;
                // this is dropdown tab while in the visible section of responsive tabs
                return (
                    <DropdownTab
                        key={tabKey}
                        active={submenuSelected}
                        disabled={disabled}
                        onSelect={onSelect}
                        onKeyDown={onKeydown}
                        tabKey={tabKey}
                        title={title}
                        variant={variant}
                        size={size}
                        orientation={vertical ? 'vertical' : 'horizontal'}
                        {...rest}
                    >
                        {level2NavItems}
                    </DropdownTab>
                );
            }
            return (
                <NormalTabTitle
                    key={tabKey}
                    active={tabKey === activeTabKey}
                    disabled={disabled}
                    onSelect={onSelect}
                    onKeyDown={onKeydown}
                    tabKey={tabKey}
                    title={title}
                    variant={variant}
                    size={size}
                    orientation={vertical ? 'vertical' : 'horizontal'}
                    {...rest}
                />
            );
        }),
        // this is the overflow tab
        <DropdownTabTitle
            active={containsActiveTab(overflowTabs, activeTabKey)}
            caret={false}
            key="uitoolkit-dropdown-tab-title"
            inOverflow
            onSelect={onSelect}
            tabKey={responsiveTabKey}
            onKeydown={onKeydown}
            title="..."
        >
            {overflowTabs}
        </DropdownTabTitle>,
    ];
};

export interface TabTitlesProps {
    activeTabKey: string | number;
    onSelect: (tabKey: string | number) => void;
    overflowingTabCount: number;
    responsive: boolean;
    vertical: boolean;
    iconOnly: boolean;
    size: TabsSize;
    variant: 'tabs' | 'pills';
    withVerticalBar?: boolean;
    classes?: TabsCommonProps['classes'];
    children?: ReactNode;
    tabRef: RefObject<HTMLDivElement>;
    responsiveTabKey: string | number;
}

export interface TabTitlesState {
    submenuHasHover: boolean;
}
// "vertical" prop on <Nav /> applies "nav-vertical" class instead
// of "flex-column". switch from using className to "vertical"
// prop once resolved:
// https://github.com/reactstrap/reactstrap/issues/295
export class TabTitles extends Component<TabTitlesProps, TabTitlesState> {
    public static propTypes: { [key in keyof TabTitlesProps]: any } = {
        activeTabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        children: PropTypes.node.isRequired,
        onSelect: PropTypes.func.isRequired,
        overflowingTabCount: PropTypes.number.isRequired,
        responsive: PropTypes.bool.isRequired,
        vertical: PropTypes.bool.isRequired,
        iconOnly: PropTypes.bool.isRequired,
        size: PropTypes.oneOf(['md', 'lg']).isRequired,
        variant: PropTypes.oneOf(['tabs', 'pills']).isRequired,
        withVerticalBar: PropTypes.bool,
        classes: PropTypes.object,
        tabRef: PropTypes.object,
        responsiveTabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    };

    constructor(props: TabTitlesProps) {
        super(props);
        this.onMouseEnterAddBorder = this.onMouseEnterAddBorder.bind(this);
        this.onMouseExitRemoveBorder = this.onMouseExitRemoveBorder.bind(this);
        this.onKeydown = this.onKeydown.bind(this);
        this.state = {
            submenuHasHover: false,
        };
    }

    onMouseEnterAddBorder() {
        if (this.props.vertical) {
            this.setState({ submenuHasHover: true });
        }
    }

    onMouseExitRemoveBorder() {
        if (this.props.vertical) {
            this.setState({ submenuHasHover: false });
        }
    }

    getTabTitles() {
        const validComponents = (
            Children.toArray(this.props.children as ReactElement) as any
        ).filter(isValidTabsChild);

        let tabTitles: JSX.Element[];
        if (this.props.overflowingTabCount > 0) {
            tabTitles = createResponsiveTabTitles(
                validComponents,
                this.props.activeTabKey,
                this.props.overflowingTabCount,
                this.props.onSelect,
                this.onKeydown,
                this.props.vertical,
                this.props.variant,
                this.props.size,
                this.props.responsiveTabKey
            );
        } else tabTitles = validComponents;
        return Children.toArray(tabTitles) as any;
    }

    onKeydown(props: { event: React.KeyboardEvent; tabKey: string | number }) {
        const tabTitles = this.getTabTitles();
        const length = tabTitles.length;
        const firstTab = tabTitles[0];
        const lastTab = tabTitles[length - 1];

        const moveFocusToTab = (index: number) => {
            const tabkey = tabTitles[index].props.tabKey;
            const currentTabRef = this.props.tabRef?.current;
            const tabElement = currentTabRef?.querySelector<HTMLAnchorElement>(
                `[data-tab-key="${tabkey}"]`
            );
            if (tabElement?.getAttribute('disabled') !== '') tabElement?.focus();
        };

        const moveFocusToAdjacentTab = (
            tabKey: string | number,
            direction: 'forward' | 'backward'
        ) => {
            let index;
            if (direction === 'backward' && tabKey === firstTab.props.tabKey) {
                index = 0;
            } else if (direction === 'forward' && tabKey === lastTab.props.tabKey) {
                index = length - 1;
            } else {
                index = tabTitles.findIndex((element: any) => element.props.tabKey === tabKey);
            }
            let nextIndex = index;
            nextIndex = findIndex(nextIndex, direction);
            while (nextIndex != index) {
                const tabkey = tabTitles[nextIndex].props.tabKey;
                const currentTabRef = this.props.tabRef?.current;
                const tabElement = currentTabRef?.querySelector<HTMLAnchorElement>(
                    `[data-tab-key="${tabkey}"]`
                );
                if (tabElement?.getAttribute('disabled') === '') {
                    nextIndex = findIndex(nextIndex, direction);
                } else {
                    tabElement?.focus();
                    break;
                }
            }
        };
        const findIndex = (nextIndex: number, direction: 'forward' | 'backward') => {
            if (direction === 'backward') {
                if (nextIndex > 0) nextIndex--;
                else nextIndex = length - 1;
            } else {
                if (nextIndex < length - 1) nextIndex++;
                else nextIndex = 0;
            }
            return nextIndex;
        };

        let flag = false;
        switch (props.event.key) {
            case 'ArrowLeft':
                if (!this.props.vertical) {
                    moveFocusToAdjacentTab(props.tabKey, 'backward');
                    flag = true;
                }
                break;

            case 'ArrowRight':
                if (!this.props.vertical) {
                    moveFocusToAdjacentTab(props.tabKey, 'forward');
                    flag = true;
                }
                break;

            case 'ArrowUp':
                if (this.props.vertical) {
                    moveFocusToAdjacentTab(props.tabKey, 'backward');
                    flag = true;
                }
                break;

            case 'ArrowDown':
                if (this.props.vertical) {
                    moveFocusToAdjacentTab(props.tabKey, 'forward');
                    flag = true;
                }
                break;

            case 'Home':
                moveFocusToTab(firstTab);
                flag = true;
                break;

            case 'End':
                moveFocusToTab(lastTab);
                flag = true;
                break;

            default:
                break;
        }

        if (flag) {
            props.event.stopPropagation();
            props.event.preventDefault();
        }
    }

    componentWillUnmount() {
        tabsStyleSheet.unmount(this);
    }

    render() {
        const {
            activeTabKey,
            children,
            onSelect,
            overflowingTabCount,
            vertical,
            variant,
            iconOnly,
            size,
            responsiveTabKey,
            classes: overrideClasses,
        } = this.props;
        let tabTitles: JSX.Element[];
        const validComponents = (Children.toArray(children as ReactElement) as any).filter(
            isValidTabsChild
        );
        if (overflowingTabCount > 0) {
            tabTitles = createResponsiveTabTitles(
                validComponents,
                activeTabKey,
                overflowingTabCount,
                onSelect,
                this.onKeydown,
                vertical,
                variant,
                size,
                responsiveTabKey
            );
        } else {
            tabTitles = validComponents.map((component: ReactElement) => {
                const {
                    props: { tabKey, disabled, title, ...rest },
                    props,
                } = component;
                const compChildren = props.children;
                const tabIsActive = tabKey === activeTabKey;
                const dropdownIsActive =
                    isDropdown(compChildren) && containsActiveTab(compChildren, activeTabKey);
                const active = tabIsActive || dropdownIsActive;
                // tabs with submenus when in vertical or horiztonal (ie non-responsive tabs)
                if (isDropdownTab(component)) {
                    return (
                        <DropdownTab
                            active={dropdownIsActive}
                            key={tabKey}
                            disabled={disabled}
                            onSelect={onSelect}
                            onMouseEnterAddBorder={this.onMouseEnterAddBorder}
                            onMouseExitRemoveBorder={this.onMouseExitRemoveBorder}
                            tabKey={tabKey}
                            onKeyDown={this.onKeydown}
                            title={title}
                            iconOnly={iconOnly}
                            size={size}
                            vertical={vertical}
                            variant={variant}
                            {...rest}
                        >
                            {props.children}
                        </DropdownTab>
                    );
                }
                return (
                    <NormalTabTitle
                        active={active}
                        key={tabKey}
                        disabled={disabled}
                        onSelect={onSelect}
                        onKeyDown={this.onKeydown}
                        tabKey={tabKey}
                        title={title}
                        iconOnly={iconOnly}
                        size={size}
                        orientation={vertical ? 'vertical' : 'horizontal'}
                        variant={variant}
                        {...rest}
                    >
                        {props.children}
                    </NormalTabTitle>
                );
            });
        }

        return (
            <EmotionInstanceContext.Consumer>
                {emotionInstance => (
                    <ThemeConsumer>
                        {(theme: Theme) => {
                            const cssClasses = tabsStyleSheet.mount(
                                this,
                                {
                                    theme,
                                    contentUnderneath: undefined,
                                    minWidth: undefined,
                                    orientation: vertical ? 'vertical' : 'horizontal',
                                    size,
                                    type: variant,
                                    justify: undefined,
                                },
                                emotionInstance
                            );

                            return (
                                <Nav
                                    tabs={variant === 'tabs'}
                                    pills={variant === 'pills'}
                                    className={getTabsContainerClasses({
                                        cssClasses,
                                        overrideClasses,
                                    })}
                                >
                                    {tabTitles}
                                </Nav>
                            );
                        }}
                    </ThemeConsumer>
                )}
            </EmotionInstanceContext.Consumer>
        );
    }
}
