import { DropdownTab } from './DropdownTab';
import { Tab } from './Tab';
import { ReactElement, ReactNode, isValidElement } from 'react';

/**
 * Determines if the given React node is a `<Tab>` or `<DropdownTab>` element
 * (i.e. a valid child of the `<Tabs>` element).
 */
export function isValidTabsChild(node: ReactNode): node is ReactElement {
    return isTab(node) || isDropdownTab(node);
}

/**
 * Determines if the given React node is a `<Tab>` element.
 */
export function isTab(node: ReactNode): node is ReactElement {
    return isValidElement(node) && node.type === Tab;
}

/**
 * Determines if the given React node is a `<DropdownTab>` element.
 */
export function isDropdownTab(node: ReactNode): node is ReactElement {
    return isValidElement(node) && node.type === DropdownTab;
}

export function containsActiveTab(tabs: ReactElement[], activeTabKey: string | number): boolean {
    return tabs.filter(isValidTabsChild).some(({ props: { tabKey } }) => tabKey === activeTabKey);
}

export const isDropdown = (tabChildren: ReactElement[]): boolean =>
    tabChildren !== undefined &&
    Object.prototype.toString.call(tabChildren) === '[object Array]' &&
    tabChildren.some(isTab);
