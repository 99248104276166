import { StyleSheet, CssClasses } from '@gs-ux-uitoolkit-react/style';
import { colors } from '@gs-ux-uitoolkit-common/design-system';
import { MOBILE_BREAKPOINT } from '../style-constant';

export type QuickfilterModalCssClasses = CssClasses<typeof quickfilterModalStyleSheet>;

export interface QuickfilterModalStyleSheetProps {
    containerWidth: number;
}

export const quickfilterModalStyleSheet = new StyleSheet('quickfilter-modal', () => {
    return {
        root: {},
        quickFilterContainer: {
            minHeight: '100%',
            height: '100%',
            '.uitk-quickfilter-modal__expression-main-container': {
                minHeight: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                padding: '16px 0 0',
            },
            '.uitk-quickfilter-modal__expression-container': {
                display: 'flex',
                flex: 1,
                flexWrap: 'wrap',
                minHeight: '100%',
                boxSizing: 'border-box',
                '.uitk-quickfilter-modal__expression': {
                    flex: 1,
                    width: '100%',
                    maxWidth: '100%',
                    minWidth: '70%',
                },
                '.uitk-quickfilter-modal__expression-preview-container': {
                    flex: 1,
                    backgroundColor: colors.gray010,
                },
                '.basic-expression': {
                    width: '100%',
                },
                '.gs-uitk-quick-filter-plugin gs-uitk-plugin-flex': {
                    maxWidth: '100%',
                    overflowX: 'hidden',
                },
            },
            '.gs-accordion-panel__body': {
                padding: 0,
            },
            '&.gs-uitk-quickfilter__basic': {
                '.gs-uitk-quickfilter-expression-name': {
                    maxWidth: '70%',
                    marginBottom: '24px',
                },
                '.gs-tabs': {
                    height: '100%',
                },
            },

            [`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
                '.uitk-quickfilter-modal__expression-container': {
                    flexDirection: 'column',
                    '.uitk-quickfilter-modal__expression': {
                        minWidth: 'unset',
                    },
                },
                '&.gs-uitk-quickfilter__basic': {
                    '.gs-uitk-quickfilter-expression-name': {
                        maxWidth: '100%',
                    },
                },
            },
        },
    };
});
