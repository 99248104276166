import { cardStyleSheet } from './card-style-sheet';
import { CardProps } from './card-props';
import { cx, CssClasses } from '@gs-ux-uitoolkit-common/style';

const globalClassName = 'gs-card';

export interface CardClassNameProps {
    cssClasses: CssClasses<typeof cardStyleSheet>;
    className?: CardProps['className'];
}

export function getCardClasses(props: CardClassNameProps): string {
    const { cssClasses, className } = props;
    return cx(cssClasses.root, className, globalClassName);
}

export function getCardBodyClasses(props: CardClassNameProps): string {
    const { cssClasses, className } = props;
    return cx(cssClasses.body, className, `${globalClassName}-body`);
}

export function getCardHeaderClasses(props: CardClassNameProps): string {
    const { cssClasses, className } = props;
    return cx(cssClasses.header, className, `${globalClassName}-header`);
}

export function getCardFooterClasses(props: CardClassNameProps): string {
    const { cssClasses, className } = props;
    return cx(cssClasses.footer, className, `${globalClassName}-footer`);
}

export function getCardSubtitleClasses(props: CardClassNameProps): string {
    const { cssClasses, className } = props;
    return cx(cssClasses.subtitle, className, `${globalClassName}-subtitle`);
}

export function getCardTextClasses(props: CardClassNameProps): string {
    const { cssClasses, className } = props;
    return cx(cssClasses.text, className, `${globalClassName}-text`);
}

export function getCardTitleClasses(props: CardClassNameProps): string {
    const { cssClasses, className } = props;
    return cx(cssClasses.title, className, `${globalClassName}-title`);
}

export function getCardImgClasses(props: CardClassNameProps): string {
    const { cssClasses, className } = props;
    return cx(cssClasses.img, className, `${globalClassName}-img`);
}
