import { DefaultRegistryState } from '@gs-ux-uitoolkit-common/datacore';

export enum DataGridToolbarItemKeys {
    CustomSortShortcut = 'CustomSortShortcut',
    ColumnFormattingShortcut = 'ColumnFormattingShortcut',
    ExportShortcut = 'ExportShortcut',
    GridConfigurationShortcut = 'GridConfigurationShortcut',
    RowCustomisationShortcut = 'RowCustomisationShortcut',
    KeyboardShortcutsShortcut = 'KeyboardShortcutsShortcut',
    QuickFilterShortcut = 'QuickFilterShortcut',
    SavedViewsShortcut = 'SavedViewsShortcut',
    EnhancedQuickFilterWidget = 'EnhancedQuickFilterWidget',
    QuickFilterWidget = 'QuickFilterWidget',
    SavedViewsWidget = 'SavedViewsWidget',
    SearchWidget = 'SearchWidget',
    SizeAndDensityWidget = 'SizeAndDensityWidget',
    ExportWidget = 'ExportWidget',
}

export type DataGridToolbarItem = `${DataGridToolbarItemKeys}`;

export const defaultToolbarShortcuts: DataGridToolbarItem[] = [
    'CustomSortShortcut',
    'QuickFilterShortcut',
    'RowCustomisationShortcut',
    'GridConfigurationShortcut',
];
export const defaultToolbarWidgets: DataGridToolbarItem[] = [
    'SearchWidget',
    'QuickFilterWidget',
    'SavedViewsWidget',
];
const allItems = [
    'CustomSortShortcut',
    'ColumnFormattingShortcut',
    'ExportShortcut',
    'GridConfigurationShortcut',
    'RowCustomisationShortcut',
    'KeyboardShortcutsShortcut',
    'QuickFilterShortcut',
    'SavedViewsShortcut',
    'EnhancedQuickFilterWidget',
    'QuickFilterWidget',
    'SavedViewsWidget',
    'SearchWidget',
    'SizeAndDensityWidget',
    'ExportWidget',
];
// TODO: Replace with available and displayed items
export const defaultToolbarState: DefaultRegistryState = {
    displayedItems: [...defaultToolbarWidgets, ...defaultToolbarShortcuts],
    availableItems: [...allItems],
};
