import actionTypes from '../../../actions/page/termsAndCondition/actionTypes';

export function tncPageDataReducer(state = {}, action) {
  const { type, data = {} } = action;
  switch (type) {
    case actionTypes.IS_TNC_SERVICE_CALL_IN_PROGRESS:
    case actionTypes.SET_CTA_BUTTONS_DISABLED: {
      return { ...state, ...data };
    }
    default:
      return state;
  }
}
