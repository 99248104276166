import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from './Grid';
import ControlPanel from './ControlPanel';
import { MODULES_KEYS } from '../../../../../constants/pageConstants';
import './index.scss';

const FirmAndFreeTextSearch = (props) => {

  const { isShowDetailsGrid } = props;

  const showDetailsGridCls = classNames('usersearch-container', { 'hide-usersearch-grid': !isShowDetailsGrid });

  return (
    <div className={showDetailsGridCls}>
      <ControlPanel id={MODULES_KEYS.USER_SEARCH} allowSortingOnGroupChange />
      <Grid id={MODULES_KEYS.USER_SEARCH} gsToolKit={true} />
    </div>
  )
}

FirmAndFreeTextSearch.propTypes = {
  isShowDetailsGrid: PropTypes.bool
};

export default FirmAndFreeTextSearch;