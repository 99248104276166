import { Component, Fragment, ChangeEvent } from 'react';
import { FormGroup } from '@gs-ux-uitoolkit-react/form';
import { Input } from '@gs-ux-uitoolkit-react/input';
import { Label } from '@gs-ux-uitoolkit-react/label';
import { Radio, RadioChangeEvent } from '@gs-ux-uitoolkit-react/radio';
import {
    HintList,
    NumberNamedPattern,
    DateNamedPattern,
    DateFormat,
} from '@gs-ux-uitoolkit-common/datacore';
import { Select, SelectOption, SelectChangeEvent } from '@gs-ux-uitoolkit-react/select';
import { FormatControlProps } from '../column-hint-format-manager';
import { columnFormattingPluginStylePrefix } from '../../../../../style/plugins/column-hint-plugin-stylesheet';
import { Checkbox, CheckboxChangeEvent } from '@gs-ux-uitoolkit-react/checkbox';

export interface ColumnHintControlNamedFormatProps extends FormatControlProps {
    dateFormat?: boolean;
}

export class ColumnHintControlNamedFormat extends Component<ColumnHintControlNamedFormatProps> {
    constructor(props: ColumnHintControlNamedFormatProps) {
        super(props);
    }
    public render() {
        const namedFormatter = this.props.dateFormat ? DateNamedPattern : NumberNamedPattern;
        const namedFormatterOptions: SelectOption[] = Object.keys(namedFormatter).map(key => {
            const value = (namedFormatter as any)[key];
            return { value, label: key };
        });

        const radioNamedChecked: boolean = !!(
            this.props.columnHint.format?.d3Specifier === undefined
        );

        return (
            <Fragment>
                <FormGroup>
                    {this.props.dateFormat ? (
                        <div>
                            <Label>Date Format Type</Label>{' '}
                            <div
                                className={`${columnFormattingPluginStylePrefix}_flex ${columnFormattingPluginStylePrefix}_date-format-type`}
                            >
                                <Radio
                                    name="utc"
                                    value="utc"
                                    onChange={this.onChangeDateFormatRadio}
                                    checked={
                                        this.props.columnHint.format?.dateFormat === DateFormat.UTC
                                    }
                                    inline={true}
                                >
                                    UTC
                                </Radio>
                                <Radio
                                    name="local"
                                    value="local"
                                    onChange={this.onChangeDateFormatRadio}
                                    checked={
                                        this.props.columnHint.format?.dateFormat ===
                                        DateFormat.Local
                                    }
                                    inline={true}
                                >
                                    Local
                                </Radio>
                            </div>
                        </div>
                    ) : null}
                    <Label>
                        {this.props.dateFormat ? 'Use Date Format' : 'Use Numeric Format'}
                    </Label>
                    <div className={`${columnFormattingPluginStylePrefix}_numeric-date-container`}>
                        <div
                            className={`${columnFormattingPluginStylePrefix}_flex-column-container`}
                        >
                            <Select
                                options={namedFormatterOptions}
                                onChange={this.onChangeNamedFormatter}
                                selectedValue={
                                    (radioNamedChecked &&
                                        (this.props.columnHint.format?.namedSpecifier as string)) ||
                                    undefined
                                }
                                placeholder={'Select column'}
                                disabled={!radioNamedChecked}
                            />
                        </div>
                        <div
                            className={`${columnFormattingPluginStylePrefix}_flex-column-container`}
                        >
                            <Checkbox
                                onChange={this.onChangeFormatCheckbox}
                                defaultChecked={!radioNamedChecked}
                                name={`uitk-numeric-formatter`}
                            >
                                <a
                                    href="https://github.com/d3/d3-format"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    D3 Specifier
                                </a>
                            </Checkbox>
                            <Input
                                className={`column-hint-control-column-width-pixel-input ${columnFormattingPluginStylePrefix}_specifier-input`}
                                value={
                                    (!radioNamedChecked &&
                                        this.props.columnHint.format?.d3Specifier) ||
                                    ''
                                }
                                placeholder="e.g %A"
                                onChange={this.onChangeD3Specifier}
                            />
                        </div>
                    </div>
                </FormGroup>
            </Fragment>
        );
    }

    private onChangeFormatCheckbox = (e: CheckboxChangeEvent) => {
        const checked = e.checkedState === true;
        if (!checked) {
            const clonedHint: HintList = {
                ...this.props.columnHint,
                format: {
                    ...this.props.columnHint.format,
                    namedSpecifier: undefined,
                },
            };
            if (clonedHint.format) {
                delete clonedHint.format.d3Specifier;
            }
            this.props.onChangeHint(clonedHint);
        } else {
            const clonedHint: HintList = {
                ...this.props.columnHint,
                format: { ...this.props.columnHint.format, d3Specifier: '' },
            };
            if (clonedHint.format) {
                delete clonedHint.format.namedSpecifier;
            }
            this.props.onChangeHint(clonedHint);
        }
    };

    private onChangeNamedFormatter = (e: SelectChangeEvent) => {
        const value = e.selectedValue as NumberNamedPattern;

        const clonedHint: HintList = {
            ...this.props.columnHint,
            format: { ...this.props.columnHint.format, namedSpecifier: value },
        };

        if (value === null && clonedHint.format) {
            delete clonedHint.format.namedSpecifier;
        }

        this.props.onChangeHint(clonedHint);
    };

    private onChangeD3Specifier = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        const clonedHint: HintList = {
            ...this.props.columnHint,
            format: { ...this.props.columnHint.format, d3Specifier: value },
        };
        if (clonedHint.format) {
            delete clonedHint.format.namedSpecifier;
            if (value === '') {
                delete clonedHint.format.d3Specifier;
            }
        }

        this.props.onChangeHint(clonedHint);
    };

    private onChangeDateFormatRadio = (e: RadioChangeEvent) => {
        const value = e.value;
        if (value === 'local') {
            const clonedHint: HintList = {
                ...this.props.columnHint,
                format: {
                    ...this.props.columnHint.format,
                    dateFormat: DateFormat.Local,
                },
            };
            this.props.onChangeHint(clonedHint);
        } else if (value === 'utc') {
            const clonedHint: HintList = {
                ...this.props.columnHint,
                format: {
                    ...this.props.columnHint.format,
                    dateFormat: DateFormat.UTC,
                },
            };
            this.props.onChangeHint(clonedHint);
        }
    };
}
