import { IRowNode } from '@ag-grid-community/core';
import {
    AddQuickFilter,
    CrossModel,
    CrossModelControllerApi,
    CrossModelSelect,
    CrossModelState,
    getCrossModelService,
    PluginBase,
    RemoveQuickFilter,
    UpdateFilterEvent,
    UpdateRecordSelectionEvent,
    EMPTY_EXPRESSION,
    ExpressionMode,
    PluginIcon,
    ColumnMapping,
    ExpressionValue,
} from '@gs-ux-uitoolkit-common/datacore';
import { ModuleIdentfier } from '../module-identfier';

import { uniqWith, isEqual } from 'gs-uitk-lodash';
import { GridWrapper } from '../../grid-wrappers/grid-wrapper';
import { DataGridState } from '../../redux/datagrid-state';
import { Categories, Plugins } from '../plugin-enum';
import { hasOwnProperty } from 'gs-uitk-object-utils';

type CompositeId = {
    [key in any]: ExpressionValue;
};

const mainIcon: PluginIcon = { name: 'shuffle', type: 'filled' };

/**
 * The Cross Model plugin. Allows the developers and end-users to create some cross modeling at runtime and design time
 */
export class CrossModelPlugin extends PluginBase<GridWrapper, DataGridState, CrossModelState> {
    protected static requiredModules: ModuleIdentfier[] = [
        ModuleIdentfier.SetFilterModule,
        ModuleIdentfier.MultiFilterModule,
    ];
    private rowSelectionSubscriptions: ((args: any) => void)[] = [];
    private crossModelControllerApis: CrossModelControllerApi[] = [];

    constructor(wrapper: GridWrapper) {
        super(
            Plugins.CrossModelPlugin,
            Categories.View,
            mainIcon,
            wrapper,
            state => state.crossModel
        );
    }

    protected stateChangedOrStart(): void {
        const crossModelState = this.getPluginState();
        const crossModelKeys = Object.keys(crossModelState || []);
        this.clearSubscriptions();
        if (crossModelKeys.length > 0) {
            for (const crossModel in crossModelState) {
                if (hasOwnProperty(crossModelState, crossModel)) {
                    this.setupCrossModel(crossModel, crossModelState[crossModel]);
                }
            }
        }
    }

    protected internalStop(): void {
        this.clearSubscriptions();
    }

    private setupCrossModel(crossModel: string, crossModelConfig: CrossModel): any {
        const controller = getCrossModelService().registerCrossModelController(crossModel);
        const crossModelControllerApi = controller.registerComponent(
            this.wrapper.getId(),
            crossModelConfig.onSelect || CrossModelSelect.None
        );

        if (!crossModelConfig.targetOnly) {
            const onRowSelectionChanged = () => {
                const selectedNodes = this.wrapper.getSelectedNodes();
                //current we expect there to be ids in the node
                const selectedRowIdList = selectedNodes
                    .filter(node => node.id !== undefined)
                    .map(node => node.id) as string[];
                //current we expect there to be a rowIndex value in the node
                const selectedRowIndexList = selectedNodes
                    .filter(node => node.rowIndex !== undefined)
                    .map(node => node.rowIndex) as number[];
                const selectedRowcompositeIdList = crossModelConfig.compositeIdColumns
                    ? this.computeUniqueCompositeIdList(
                          selectedNodes,
                          crossModelConfig.compositeIdColumns,
                          crossModelConfig.columnMappings
                      )
                    : [];
                crossModelControllerApi.onRecordSelectionChange(
                    selectedRowIdList,
                    selectedRowIndexList,
                    selectedRowcompositeIdList
                );
            };
            this.wrapper.rowSelectionChanged.subscribe(onRowSelectionChanged);
            this.rowSelectionSubscriptions.push(onRowSelectionChanged);
        }

        crossModelControllerApi.updateRecordSelection.subscribe(this.recordSelectionSubscription);
        crossModelControllerApi.updateFilter.subscribe(event => {
            this.updateFilterSubscription(
                event,
                crossModel,
                !!crossModelConfig.emptyFilterShowNoData
            );
        });

        if (crossModelConfig.emptyFilterShowNoData) {
            this.setEmptyFilter(crossModel);
        }

        this.crossModelControllerApis.push(crossModelControllerApi);
    }

    private recordSelectionSubscription = (event: UpdateRecordSelectionEvent) => {
        // remove all listeners for row selection to not trigger cross model selection
        if (this.rowSelectionSubscriptions.length > 0) {
            this.rowSelectionSubscriptions.forEach(subscription =>
                this.wrapper.rowSelectionChanged.unsubscribe(subscription)
            );
        }
        if (event.rowIndexList.length > 0) {
            if (this.wrapper.isUsingDra()) {
                this.wrapper.forEachNode(rowNode => {
                    if (rowNode.rowIndex != undefined)
                        rowNode.setSelected(event.rowIndexList.includes(rowNode.rowIndex));
                });
            } else {
                this.wrapper.forEachNodeAfterFilterAndSort(rowNode => {
                    if (rowNode.rowIndex != undefined)
                        rowNode.setSelected(event.rowIndexList.includes(rowNode.rowIndex));
                });
            }
        }
        if (event.rowIdList.length > 0) {
            this.wrapper.forEachNode(rowNode => {
                if (rowNode.id !== undefined)
                    rowNode.setSelected(event.rowIdList.includes(rowNode.id));
            });
        }
        if (event.rowCompositeIdList.length > 0) {
            this.wrapper.forEachNode(rowNode => {
                rowNode.setSelected(
                    event.rowCompositeIdList.some(id => {
                        return Object.keys(id).every(
                            col => id[col] === this.wrapper.getRawValue(rowNode, col)
                        );
                    })
                );
            });
        }

        if (
            event.rowIdList.length === 0 &&
            event.rowIndexList.length === 0 &&
            event.rowCompositeIdList.length === 0
        ) {
            this.wrapper.forEachNode(rowNode => {
                rowNode.setSelected(false);
            });
        }
        // add all listeners back on timeout 100ms
        setTimeout(() => {
            if (this.rowSelectionSubscriptions.length > 0) {
                this.rowSelectionSubscriptions.forEach(subscription =>
                    this.wrapper.rowSelectionChanged.subscribe(subscription)
                );
            }
        }, 100);
    };

    private updateFilterSubscription = (
        event: UpdateFilterEvent,
        crossModel: string,
        emptyFilterShowNoData: boolean
    ) => {
        const crossModelFilter = this.wrapper
            .getReduxStore()
            .getState()
            .quickFilter.configItemList.find(quickFilter => quickFilter.name === crossModel);
        if (crossModelFilter) {
            this.wrapper.getReduxStore().dispatch(RemoveQuickFilter(crossModelFilter));
        }
        if (event.filterExpression) {
            this.wrapper.getReduxStore().dispatch(
                AddQuickFilter({
                    expression: event.filterExpression,
                    isEnabled: true,
                    name: crossModel,
                    isRuntime: true,
                })
            );
        } else if (emptyFilterShowNoData) {
            this.setEmptyFilter(crossModel);
        }
    };

    private computeUniqueCompositeIdList(
        rowNodes: IRowNode[],
        columnList: string[],
        columnMappings: ColumnMapping | undefined
    ) {
        const compositeIds = rowNodes.map(rowNode => {
            const obj: any = {};

            columnList.forEach(col => {
                if (rowNode.group) {
                    // if a group iterate up the tree until the root is reached
                    // if the group field is part of the CompositeId
                    // use the Node group key as the value for the cross model
                    let currentRowNode: IRowNode | null = rowNode;
                    // we initialize with the current node then walk the tree
                    // useful for DRA since we do not have the parents
                    obj[col] = this.wrapper.getRawValue(rowNode, col);
                    while (currentRowNode && currentRowNode.level > -1) {
                        if (col === currentRowNode.field) {
                            obj[col] = currentRowNode.key;
                        }
                        currentRowNode = currentRowNode.parent;
                    }
                } else {
                    // gets raw value for each column using colId
                    obj[col] = this.wrapper.getRawValue(rowNode, col);
                }
            });
            return obj;
        });

        return uniqWith(
            this.generateMappedColumns(compositeIds, columnList, columnMappings),
            isEqual
        );
    }

    private generateMappedColumns = (
        compositeIds: CompositeId[],
        columnList: string[],
        columnMappings: ColumnMapping | undefined
    ) => {
        if (columnMappings) {
            return compositeIds.map(compositeId => {
                const mappedCompositeIDs: CompositeId = {};
                columnList.forEach(col => {
                    if (hasOwnProperty(compositeId, col)) {
                        const mappedColumnId = columnMappings[col];
                        if (mappedColumnId) {
                            mappedCompositeIDs[mappedColumnId] = compositeId[col];
                        } else {
                            mappedCompositeIDs[col] = compositeId[col];
                        }
                    }
                });
                return mappedCompositeIDs;
            });
        }
        return compositeIds;
    };
    private setEmptyFilter = (crossModel: string) => {
        this.wrapper.getReduxStore().dispatch(
            AddQuickFilter({
                expression: {
                    mode: ExpressionMode.Experienced,
                    query: {
                        type: EMPTY_EXPRESSION,
                        emptyFilterShowAll: false,
                    },
                },
                isEnabled: true,
                name: crossModel,
                isReadOnly: true,
                isRuntime: true,
            })
        );
    };

    private clearSubscriptions = () => {
        if (this.crossModelControllerApis.length > 0) {
            this.crossModelControllerApis.forEach(api => {
                api.updateFilter.destroy();
                api.updateRecordSelection.destroy();
            });
            this.crossModelControllerApis = [];
        }
        if (this.rowSelectionSubscriptions.length > 0) {
            this.rowSelectionSubscriptions.forEach(subscription =>
                this.wrapper.rowSelectionChanged.unsubscribe(subscription)
            );
            this.rowSelectionSubscriptions = [];
        }
    };
}
