import React from 'react';
import PropTypes from 'prop-types';
import {formatDate} from '../../../../utils/dateFormatter';
import './index.scss';
import translator from '../../../../services/translator';
import {Conditional} from '../../../core/Conditional';

const {translate: t} = translator;

const TradeHeader = ({tradeData, style, homeAway}) => {
  const {groupCount, animationClass } = tradeData;
  return (
    <div data-remove={groupCount === 1} className={groupCount === 1 ? animationClass: ''}>
      <div className="approval-group-header" style={style}>
        <span>{t('tkTradeDate')}: <span className="approval-group-header__field">{formatDate(tradeData.tradeDate, 'YYYY-MM-DD', 'DD.MMM.YYYY')}</span></span>
        <span className="approval-group-header__field-divider">|</span>
        <span>{t('tkFirm')}: <span className="approval-group-header__field">{tradeData.firm}-{tradeData.branch}</span></span>
      </div>
      <div className="trade-lg">
        <div className="approval-group-column-header">
          <div className="approval-group-column-header__group">
            <span>{t('tkFund')}</span>
          </div>
          <div className="approval-group-column-header__group">
            <div className={`approval-group-column-header__center ${!homeAway ? 'no-homeAway' : ''}`}>
              <div className="ml5">{t('tkAccountText')}</div>
              <div>{t('tkSettlementDate')} </div>
              <div>{t('tkTradeType')} </div>
              <div>{t('tkAmountType')} </div>
              <div className="approval-group-column-header__ralign">{t('tkTradeAmount')} </div>
              <div className="approval-group-column-header__lmargin">{t('tkSettleInst')} </div>
              <Conditional condition={homeAway}>
                <div>{t('tkHomeAway')} </div>
              </Conditional>
            </div>
          </div>
          <div className="approval-group-column-header__group">
            <span className="approval-group-column-header__lmargin">{t('tkStatus')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

TradeHeader.propTypes = {
  tradeData: PropTypes.object,
  style: PropTypes.object,
  homeAway: PropTypes.bool
};

export default TradeHeader;
