import { Typography } from '@gs-ux-uitoolkit-common/design-system';
import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { ModalSize } from './modal-props';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './modal-body-theme-overrides';

export interface ModalBodyStyleSheetProps {
    theme: Theme;
    size: ModalSize;
}

export interface ModalBodyCssClasses {
    root: string;
}

export type ModalBodyStyledClasses = CssClassDefinitionsObject<keyof ModalBodyCssClasses>;

export interface ModalBodyStyleOverridesParams {
    props: DeepReadonly<ModalBodyStyleSheetProps>;
    createDefaultStyledClasses: () => ModalBodyStyledClasses;
}

export const modalBodyStyleSheet = new StyleSheet(
    'modalBody',
    (props: ModalBodyStyleSheetProps) => {
        return createComponentClassDefinitions<ModalBodyStyleSheetProps, ModalBodyStyledClasses>(
            props,
            createDefaultStyledClasses,
            props.theme.styleOverrides?.modalBody
        );
    }
);

function createDefaultStyledClasses({
    theme,
    size,
}: ModalBodyStyleSheetProps): ModalBodyStyledClasses {
    let bodyVariant: Typography = 'body02';
    let bodyPadding = '12px 16px 8px';
    if (size === 'sm') {
        bodyVariant = 'body03';
        bodyPadding = '4px 12px 12px';
    }
    return {
        root: {
            ...theme.typography[bodyVariant],
            maxHeight: '70vh',
            overflowY: 'auto',
            padding: bodyPadding,
            position: 'relative',
        },
    };
}
