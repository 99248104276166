import { InputNumberProps, FormatOptions } from '../props';
import { SelectOnFocus } from '../types';

// Regex to accept various number formats, such as "-$45,123.45", "$ - 123.45", "-123", and "-67.88 €"
// note the inputted value needs to have at least one number or '-'

// Regex for testing currency symbols.  Would have liked to use \p{Sc}, but Javascript doesn't support it :(
// Got this regex from https://stackoverflow.com/questions/25910808/javascript-regex-currency-symbol-in-a-string
const currencyRegEx =
    '[$\xA2-\xA5\u058F\u060B\u09F2\u09F3\u09FB\u0AF1\u0BF9\u0E3F\u17DB\u20A0-\u20BD\uA838\uFDFC\uFE69\uFF04\uFFE0\uFFE1\uFFE5\uFFE6]';

/**
 * The number format for the InputNumber component is [prefix][number][suffix]
 * [prefix] - The part for currency symbols, signs (+/-) and a period character. -, +, all currency symbols and a period character (.)
 * [number] - The part to match natural numbers, decimal numbers, currency formatted numbers. 28, 28.12, 100,000.00, -100,000.00, 100.000.000,00, 100 123 222, 0.1
 * [suffix] - The part that matches metrics, percentages and every character. Examples are kg, cm, %, $, ¢...
 */

// Matcher for the prefix part: (+ or - optional) followed by any currency symbol - optional - then an optional period
// Matches $-20 and -$20
const currencyWithSignedOptionalPeriod = `((([\\-\\+])?${currencyRegEx}?|${currencyRegEx}?[\\-\\+]?)\\s?)?\\.?`;

// Matcher for space separated formatted numbers. Matches 100 123 222, 100 123 222.20 100 123 2
const spaceSeparatedNumber = '(?:\\ [0-9]{3})*(?:[ ,.][0-9]{1,})?)';

// Matcher for period separated formatted numbers. Matches 100.123.222,50 or 100.123.222
const periodThousandSeperatedNumber = '(?:\\.[0-9]{3})*(?:,[0-9]{1,})?';

// Matcher for comma separated formatted numbers. Matches 100,123,222,.0 or 100,123,222
const commaThousandSeperatedNumber = '(?:,[0-9]{3})*(?:\\.[0-9]{1,})?';

// Matcher for natural and decimal numbers
const naturalOrSeparatedNumber = '[0-9]{1,3}(?:[0-9]*(?:[., ][0-9]{1,})?';

// Alternating all number formats for [number] matcher
const numbeRegex = `${naturalOrSeparatedNumber}|${commaThousandSeperatedNumber}|${periodThousandSeperatedNumber}|${spaceSeparatedNumber}`;

// Matcher for [suffix] matches spaces followed by currency, character or percentage symbols
const currencyRegexOrPercentOrUnits = `(\\s*(${currencyRegEx}|[a-zA-Z/]*|%)?)?`;

// Single character matcher for . or - which are valid inputs
const singleValidChars = '^[.-]$';

const numberFormatRegEx = new RegExp(
    `^${currencyWithSignedOptionalPeriod}${numbeRegex}${currencyRegexOrPercentOrUnits}$|${singleValidChars}`
);

const charCodeZero = '0'.charCodeAt(0);
const charCodeOne = '1'.charCodeAt(0);
const charCodeNine = '9'.charCodeAt(0);
const charCodeDecimal = '.'.charCodeAt(0);
const charCodeSign = '-'.charCodeAt(0);

// Used for typing keys and multiplying values
const greekMapping: { [index: string]: number } = {
    k: 1000,
    m: 1000000,
    b: 1000000000,
};
const romanMapping: { [index: string]: number } = {
    m: 1000,
    mm: 1000000,
    b: 1000000000,
};

/**
 * Returns @param value or @param defaultValue if either is a valid number or
 * numerical string including formatting (e.g., "-$12,337.25"), otherwise returns "".
 * Return value is either a number or a string; the latter is critical especially
 * when fast typing is enabled to allow values such as "25k".
 */
export function getValidNumericValue(
    value?: InputNumberProps['value'],
    defaultValue?: InputNumberProps['defaultValue']
): InputNumberProps['defaultValue'] {
    const valueAsString = value?.toString();
    if (valueAsString != null && numberFormatRegEx.test(valueAsString)) {
        return value;
    }
    const defaultValueAsString = defaultValue?.toString();
    if (defaultValueAsString != null && numberFormatRegEx.test(defaultValueAsString)) {
        return defaultValue;
    }
    return '';
}

/**
 * Converts a string to a number for use setting a `valueAsNumber` property.
 * See https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement
 */
export function getValueAsNumber(value: string) {
    return value === '' ? NaN : +value;
}

/**
 * Select certain part of the value string when component receives focus based on selectOnFocus prop
 */
export function setSelectionRangeOnFocus(args: {
    value: string | number | undefined;
    el: HTMLInputElement;
    selectOnFocus: SelectOnFocus;
}) {
    const { value, el, selectOnFocus } = args;
    if (selectOnFocus === 'all') {
        el.select();
        return;
    }
    if (selectOnFocus === 'fractional-part') {
        const valueToString = value ? value.toString() : '';
        const decimalPos = valueToString.indexOf('.');
        //if there is no decimal part of the value string, don't select anything
        if (decimalPos + 1 === valueToString.length || decimalPos === -1) {
            el.setSelectionRange(valueToString.length, valueToString.length);
        } else {
            //select the fractional digits, not including the suffix
            el.setSelectionRange(decimalPos + 1, lastNumberIndex(valueToString) + 1);
        }
    }
}

/**
 * Returns @param value incremented by @param step that is constrained by @param
 * min and @param max. If @param value is either undefined or cannot be converted
 * to a number, it defaults to 0. @param step defaults to 1. If @param min or
 * @param max is undefined, then there is no minimum or maximum constraints.
 */
export function increment(
    value?: InputNumberProps['value'],
    step?: InputNumberProps['step'],
    min?: InputNumberProps['min'],
    max?: InputNumberProps['max']
) {
    value = convertToNumber(value, 0);
    step = convertToNumber(step, 1);
    const result = safeFloatingPointAddition(value, step);
    return constrainToMinMax(result, min, max);
}

export function decrement(
    value?: InputNumberProps['value'],
    step?: InputNumberProps['step'],
    min?: InputNumberProps['min'],
    max?: InputNumberProps['max']
) {
    step = convertToNumber(step, 1) * -1;
    return increment(value, step, min, max);
}

export function isAbbrevChar(char: string, abbrevStyle: 'roman' | 'greek' | undefined = 'greek') {
    const charLower = char.toLowerCase();
    return abbrevStyle === 'greek'
        ? charLower === 'k' || charLower === 'm' || charLower === 'b'
        : charLower === 'm' || charLower === 'b';
}

export function expandAbbrevChar({
    value,
    abbrev,
    abbrevStyle = 'greek',
}: {
    value: number;
    abbrev: string;
    abbrevStyle?: 'greek' | 'roman' | undefined;
}) {
    // do not convert if number is 10,000,000 or higher
    if (value >= 10000000) {
        return value;
    }
    const styleMapping = abbrevStyle === 'greek' ? greekMapping : romanMapping;
    const multiplier = styleMapping[abbrev.toLowerCase()] ?? 1; // if a mapping doesn't exist, multipy by 1 so the value doesn't change
    return value * multiplier;
}

export function formatAndAbbreviate({
    format,
    effectiveValue,
    hasFocus,
    checkDecimalSeparator,
}: {
    format?: FormatOptions | undefined;
    effectiveValue?: string | number | undefined;
    hasFocus: boolean;
    checkDecimalSeparator: boolean;
}) {
    if (!format) {
        return effectiveValue;
    }

    // set defaults if not provided
    let formattedValue = effectiveValue;
    const {
        abbrevOnBlur = false,
        abbrevMinFractionDigits = 0,
        abbrevMaxFractionDigits = 3,
        abbrevStyle = 'greek',
    } = format;

    let effectiveValueAsNumber = convertToNumber(effectiveValue);
    let abbreviationApplied = undefined;
    if (abbrevOnBlur && !hasFocus) {
        if (effectiveValueAsNumber >= 1000000000) {
            abbreviationApplied = abbrevStyle == 'greek' ? 'b' : 'B';
            effectiveValueAsNumber = effectiveValueAsNumber / 1000000000;
        } else if (effectiveValueAsNumber >= 1000000) {
            abbreviationApplied = abbrevStyle == 'greek' ? 'm' : 'MM';
            effectiveValueAsNumber = effectiveValueAsNumber / 1000000;
        } else if (effectiveValueAsNumber >= 1000) {
            abbreviationApplied = abbrevStyle == 'greek' ? 'k' : 'M';
            effectiveValueAsNumber = effectiveValueAsNumber / 1000;
        }
    }

    let intlNumberFormatOptions = {
        style: format?.style,
        currency: format?.currency,
        minimumFractionDigits: format?.minFractionDigits,
        maximumFractionDigits: format?.maxFractionDigits,
        minimumIntegerDigits: format?.minIntegerDigits,
    };
    // if abbrevOnBlur && input does not have focus (user is not editing) - modify format config to not show decimal
    if (abbrevOnBlur && abbreviationApplied && !hasFocus) {
        intlNumberFormatOptions = Object.assign({}, intlNumberFormatOptions, {
            minimumFractionDigits: abbrevMinFractionDigits,
            maximumFractionDigits: abbrevMaxFractionDigits,
        });
    }

    const numberFormatter = new Intl.NumberFormat(format?.locale, intlNumberFormatOptions);
    formattedValue = numberFormatter.format(effectiveValueAsNumber);

    // insert abbreviation character
    if (abbreviationApplied) {
        const valueAsString = formattedValue.toString();
        const insertAbbrevIndex = lastNumberIndex(valueAsString) + 1;
        if (valueAsString.charAt(insertAbbrevIndex).trim().length !== 0) {
            abbreviationApplied += ' ';
        }
        formattedValue =
            valueAsString.slice(0, insertAbbrevIndex) +
            abbreviationApplied +
            valueAsString.slice(insertAbbrevIndex);
    }

    // add suffix
    if (format.suffix) {
        formattedValue = formattedValue + format.suffix;
    }

    // check decimal separator is '.' We will support other decimal separators in the future
    if (
        checkDecimalSeparator &&
        typeof process !== 'undefined' &&
        process.env.NODE_ENV !== 'production'
    ) {
        if (intlNumberFormatOptions.maximumFractionDigits !== 0) {
            const formatString = numberFormatter.format(3.8);
            if (formatString.indexOf('.') < 0) {
                throw Error(
                    `Input Number currently only supports a formatter where the decimal separator is a '.'.  Please submit a support ticket to request this functionality:  https://ui.web.gs.com/support.  This is condition is not checked with production code.`
                );
            }
        }
    }

    return formattedValue;
}

/**
 * Returns the index of the last number digit in the given string
 */
export function lastNumberIndex(str: string) {
    for (let i = str.length - 1; i >= 0; i--) {
        if (str.charCodeAt(i) >= charCodeZero && str.charCodeAt(i) <= charCodeNine) {
            return i;
        }
    }
    return -1;
}
/**
 * Rounds @param value up (if positive) and down (if negative) to the nearest
 * multiple of @param step while constraining to @param min and @param max, if
 * they are defined. For example:
 * ```
 * roundByStep(6.5, 2) == 8
 * roundByStep(6.5, 3) == 9
 * roundByStep(6.5, 3, 3, 4) == 4
 * roundByStep(5.00001, 0.3, 2, 10) == 5.3
 * ```
 */
export function roundByStep(
    value?: InputNumberProps['value'],
    step: InputNumberProps['step'] = 1,
    min?: InputNumberProps['min'],
    max?: InputNumberProps['max']
) {
    if (isNotNumeric(value)) {
        return value;
    }

    value = convertToNumber(value, 0);
    if (min !== undefined && value <= min) {
        return min;
    }
    if (max !== undefined && value >= max) {
        return max;
    }

    step = convertToNumber(step, 1);

    // Get the precision the final result must conform to:
    const decimalPrecision = getDecimalPlaces(step);

    // Transform the number of decimals in the value into an integer:
    const precisionFactor = Math.pow(10, Math.max(getDecimalPlaces(value), decimalPrecision));

    const valueWithoutMin =
        min === 0 || min === undefined
            ? value
            : Math.abs(value * precisionFactor - min * precisionFactor) / precisionFactor;
    const stepsValue = valueWithoutMin / step;

    // Negative should work in reverse to positive (i.e., -0.15 becomes -1 not 0 just as 0.15 becomes 1.)
    const roundedStepsValue = value >= 0 ? Math.ceil(stepsValue) : Math.floor(stepsValue);

    const startValue = min || 0;
    let result =
        (startValue * precisionFactor + step * roundedStepsValue * precisionFactor) /
        precisionFactor;
    if (getDecimalPlaces(result) > decimalPrecision) {
        result = roundToDecimalPlaces(result, decimalPrecision);
    }
    return constrainToMinMax(result, min, max);
}

export function constrainToMinMax(
    value: number,
    min?: InputNumberProps['min'],
    max?: InputNumberProps['max']
) {
    if (min !== undefined && max !== undefined) {
        // If both min and max are defined, min can never be greater than max.
        min = Math.min(min, max);
    }
    if (min !== undefined) {
        value = Math.max(value, min);
    }
    if (max !== undefined) {
        value = Math.min(value, max);
    }
    return value;
}

// By default, number inputs do not accept non-numeric characters other than "-" and ",".
// This function strips non-numeric characters from @param value.
export function removeNonNumericChars(value: InputNumberProps['value']) {
    return value === undefined ? '' : (value + '').replace(/([^.\-\d])/g, '');
}

/**
 * if 1 sign exists, returns the value as negative,
 * if 2 signs exist, returns the value as positive,
 * if more than 2 signs exist, returns the original value unchanged
 */
export function convertSign(value: string) {
    let signCount = 0;
    let newValue = '';
    for (let i = 0; i < value.length; i++) {
        if (value[i] === '-') {
            signCount++;
        } else {
            newValue += value[i];
        }
    }
    if (signCount === 1) {
        // return the value as negative
        return '-' + newValue;
    } else if (signCount === 2) {
        // return the value as positive
        return newValue;
    } else {
        // if there are more than 2 sign characters, return the original value
        return value;
    }
}

// Returns `true` if @param value is a valid number or numerical string (e.g., "-7.25").
export function isNumeric(value: InputNumberProps['value']) {
    return value !== undefined && `${value}`.trim() !== '' && !isNaN(+value);
}

export function isNotNumeric(value: InputNumberProps['value']) {
    return !isNumeric(value);
}

export function valueIsNotValid(value: string, valueAsNumber: number) {
    return value !== '' && Number.isNaN(valueAsNumber) && !/^(-|\.|-\.)$/.test(value);
}

/**
 *
 * @returns true if char at `index` is a digit 0-9
 */
export function isCharDigitAt(value: string, index: number = 0) {
    const charCode = value.charCodeAt(index);
    return charCode >= charCodeZero && charCode <= charCodeNine;
}

/**
 * @returns true if the character at `index` is a digit 0-9 or '.' or '-'.  This can be used
 * to determine if the character is significant versus a grouping separator such as ',' or
 * currency symbol '$'.
 */
export function isCharDigitDecimalOrSignAt(value: string, index: number = 0) {
    const charCode = value.charCodeAt(index);
    return (
        charCode === charCodeDecimal ||
        charCode === charCodeSign ||
        (charCode >= charCodeZero && charCode <= charCodeNine)
    );
}

/**
 * @returns true if the character at `index` is a digit 1-9 (if 0, returns false).
 */
export function isCharNonZeroDigitAt(value: string, index: number = 0) {
    const charCode = value.charCodeAt(index);
    return charCode >= charCodeOne && charCode < charCodeNine;
}

// If @param value can be converted to a number, the converted numeric value will
// be returned. Otherwise, @param defaultValue will be returned.
export function convertToNumber(value: InputNumberProps['value'], defaultValue = 0) {
    const numericValue = removeNonNumericChars(value);
    return isNumeric(numericValue) ? +numericValue! : defaultValue;
}

// Returns the number of decimal places in @param value.
function getDecimalPlaces(value: number) {
    return isNumeric(value) ? (`${value}`.split('.')[1] || []).length : 0;
}

function roundToDecimalPlaces(value: number, decimalPrecision: number) {
    return isNumeric(value)
        ? +(Math.round(+(value + 'e+' + decimalPrecision)) + 'e-' + decimalPrecision)
        : value;
}

/**
 * Resolving: https://jira.work.gs.com/browse/UX-16911
 * Floating point errors in js / nodejs: https://ellenaua.medium.com/floating-point-errors-in-javascript-node-js-21aadd897bf8
 * For example if we try to sum up 5.33 + 5.2, we will get 10.530000000000001 as a result instead of 10.53
 */
function safeFloatingPointAddition(a: number, b: number) {
    const maxDecimalPlaces = Math.max(getDecimalPlaces(a), getDecimalPlaces(b));
    return Number.parseFloat((a + b).toFixed(maxDecimalPlaces));
}
