import { FC, memo } from 'react';
import PropTypes from 'prop-types';
import { Label } from '@gs-ux-uitoolkit-react/label';
import {
    stepsSummaryDefaultProps,
    StepsSummaryProps,
    getStepsSummaryClasses,
    getStepsSummaryContent,
} from '@gs-ux-uitoolkit-common/steps';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { stepsSummaryStyleSheet } from '@gs-ux-uitoolkit-common/steps';
import { Text } from '@gs-ux-uitoolkit-react/text';
import { fontWeight } from '@gs-ux-uitoolkit-react/design-system';

/**
 * Steps summary is used to indicate the current state of sequence in process
 * @visibleName StepsSummary
 */
export const StepsSummary: FC<StepsSummaryProps> = memo(props => {
    const theme = useTheme();
    const {
        classes: overrideClasses,
        currentStep,
        stepsLabels,
        size = stepsSummaryDefaultProps.size,
    } = props;

    const cssClasses = useStyleSheet(stepsSummaryStyleSheet, {
        size,
        theme,
    });

    const stepsLabelSize = size === 'md' ? '14px' : '12px';

    return (
        <div
            className={getStepsSummaryClasses({ cssClasses, overrideClasses })}
            data-cy="gs-uitk-steps__summary"
            data-size={size}
            data-gs-uitk-component="steps-summary"
        >
            <Label size={size}>
                {getStepsSummaryContent({ currentStep, stepsLabels }).stepsSummaryStateContent}
                <Text fontSize={stepsLabelSize} style={{ fontWeight: fontWeight.medium }}>
                    {getStepsSummaryContent({ currentStep, stepsLabels }).stepsSummaryLabelContent}
                </Text>
            </Label>
        </div>
    );
});
StepsSummary.displayName = 'StepsSummary';

StepsSummary.propTypes = {
    classes: PropTypes.object,
    currentStep: PropTypes.number,
    stepsLabels: PropTypes.array,
    size: PropTypes.oneOf(['sm', 'md']),
};
