import { GridActionKeys } from '../actions/action-constants';
import { GridActionTypes } from '../actions/grid-action';
import { GridState } from '../datagrid-state';

/**
 * Initial state of the Grid State
 */
export const defaultGridState: GridState = {
    columnIdListFiltered: [],
    columnList: [],
    columnValueList: [],
    primaryColumnList: [],
    basicExpressionColumnValues: [],
    floatingFilter: {
        enabled: false,
        columns: undefined,
    },
};

/**
 * Reducer for the GridState
 * @param state State of the GridState
 * @param action Action received
 */
export const gridReducer = (
    state: GridState = defaultGridState,
    action: GridActionTypes
): GridState => {
    switch (action.type) {
        case GridActionKeys.SET_COLUMN_DEFINITIONS:
            return {
                ...state,
                columnList: action.columnList,
            };
        case GridActionKeys.SET_PRIMARY_COLUMN_DEFINITIONS:
            return {
                ...state,
                primaryColumnList: action.primaryColumnList,
            };
        case GridActionKeys.SET_COLUMN_VALUE_LIST:
            return {
                ...state,
                columnValueList: [
                    ...state.columnValueList,
                    {
                        columnId: action.columnId,
                        valueList: action.columnValueList,
                    },
                ],
            };
        case GridActionKeys.RESET_BASIC_EXPRESSION_COLUMN_VALUE_LIST:
            return {
                ...state,
                basicExpressionColumnValues: [],
            };
        case GridActionKeys.SET_BASIC_EXPRESSION_COLUMN_VALUE_LIST: {
            const basicExpressionColumnValues = state.basicExpressionColumnValues;
            state.basicExpressionColumnValues.filter(
                columnValueState => columnValueState.columnId === action.columnId
            );
            return {
                ...state,
                basicExpressionColumnValues: [
                    ...basicExpressionColumnValues,
                    {
                        columnId: action.columnId,
                        columnValues: action.columnValueList,
                    },
                ],
            };
        }
        case GridActionKeys.RESET_COLUMN_VALUE_LIST:
            return action.columnId
                ? {
                      ...state,
                      columnValueList: state.columnValueList.filter(
                          cv => cv.columnId !== action.columnId
                      ),
                  }
                : {
                      ...state,
                      columnValueList: [],
                  };
        case GridActionKeys.SET_COLUMN_FILTERS:
            return {
                ...state,
                columnIdListFiltered: action.columnIdList,
            };
        case GridActionKeys.GET_COLUMN_VALUE_LIST:
            // nothing to do
            return state;
        case GridActionKeys.SET_FLOATING_FILTER:
            return {
                ...state,
                floatingFilter: {
                    columns: action.columns,
                    enabled: action.enabled,
                },
            };
        case GridActionKeys.TOGGLE_FLOATING_FILTER:
            return {
                ...state,
                floatingFilter: {
                    ...state.floatingFilter,
                    enabled: action.value,
                },
            };
        default:
            return state;
    }
};
