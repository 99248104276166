import { DisplayValueTuple, GridColumn } from '../../grid-wrappers/grid-wrapper';
import { GridActionKeys } from './action-constants';

export type GridActionTypes =
    | GridSetColumnDefinitionsAction
    | GridSetPrimaryColumnDefinitionsAction
    | GridGetColumnValuesAction
    | GridSetColumnValuesAction
    | GridResetColumnValuesAction
    | GridSetColumnFiltersAction
    | GridRemoveColumnFilterAction
    | GridGetBasicExpressionColumnValuesAction
    | GridSetBasicExpressionColumnValuesAction
    | GridResetBasicExpressionColumnValuesAction
    | GridRemoveAllColumnFiltersAction
    | GridSetFloatingFilterAction
    | GridToggleFloatingFilterAction;

export interface GridSetColumnDefinitionsAction {
    type: GridActionKeys.SET_COLUMN_DEFINITIONS;
    columnList: GridColumn[];
}

export interface GridSetPrimaryColumnDefinitionsAction {
    type: GridActionKeys.SET_PRIMARY_COLUMN_DEFINITIONS;
    primaryColumnList: GridColumn[];
}

export interface GridGetBasicExpressionColumnValuesAction {
    type: GridActionKeys.GET_BASIC_EXPRESSION_COLUMN_VALUE_LIST;
    columnId: string;
}

export interface GridSetBasicExpressionColumnValuesAction {
    type: GridActionKeys.SET_BASIC_EXPRESSION_COLUMN_VALUE_LIST;
    columnId: string;
    columnValueList: DisplayValueTuple[];
}

export interface GridResetBasicExpressionColumnValuesAction {
    type: GridActionKeys.RESET_BASIC_EXPRESSION_COLUMN_VALUE_LIST;
}

export interface GridGetColumnValuesAction {
    type: GridActionKeys.GET_COLUMN_VALUE_LIST;
    columnId: string;
}

export interface GridSetColumnValuesAction {
    type: GridActionKeys.SET_COLUMN_VALUE_LIST;
    columnId: string;
    columnValueList: DisplayValueTuple[];
}
export interface GridResetColumnValuesAction {
    type: GridActionKeys.RESET_COLUMN_VALUE_LIST;
    columnId: string | null;
}

export interface GridSetColumnFiltersAction {
    type: GridActionKeys.SET_COLUMN_FILTERS;
    columnIdList: string[];
}

export interface GridRemoveColumnFilterAction {
    type: GridActionKeys.REMOVE_COLUMN_FILTER;
    columnId: string;
}

export interface GridRemoveAllColumnFiltersAction {
    type: GridActionKeys.REMOVE_ALL_COLUMN_FILTERS;
}

export interface GridSetFloatingFilterAction {
    type: GridActionKeys.SET_FLOATING_FILTER;
    enabled: boolean;
    columns: Set<string>;
}

export interface GridToggleFloatingFilterAction {
    type: GridActionKeys.TOGGLE_FLOATING_FILTER;
    value: boolean;
}

export const GridSetColumnDefinitions = (
    columnList: GridColumn[]
): GridSetColumnDefinitionsAction => ({
    columnList,
    type: GridActionKeys.SET_COLUMN_DEFINITIONS,
});

export const GridSetFloatingFilter = (
    enabled: boolean,
    columns: Set<string>
): GridSetFloatingFilterAction => ({
    enabled,
    columns,
    type: GridActionKeys.SET_FLOATING_FILTER,
});

export const GridToggleFloatingFilter = (value: boolean): GridToggleFloatingFilterAction => ({
    value,
    type: GridActionKeys.TOGGLE_FLOATING_FILTER,
});

export const GridSetPrimaryColumnDefinitions = (
    primaryColumnList: GridColumn[]
): GridSetPrimaryColumnDefinitionsAction => ({
    primaryColumnList,
    type: GridActionKeys.SET_PRIMARY_COLUMN_DEFINITIONS,
});

export const GridGetBasicExpressionColumnValueList = (
    columnId: string
): GridGetBasicExpressionColumnValuesAction => ({
    columnId,
    type: GridActionKeys.GET_BASIC_EXPRESSION_COLUMN_VALUE_LIST,
});

export const GridSetBasicExpressionColumnValueList = (
    columnId: string,
    columnValueList: DisplayValueTuple[]
): GridSetBasicExpressionColumnValuesAction => ({
    columnId,
    columnValueList,
    type: GridActionKeys.SET_BASIC_EXPRESSION_COLUMN_VALUE_LIST,
});

export const GridResetBasicExpressionColumnValueList =
    (): GridResetBasicExpressionColumnValuesAction => ({
        type: GridActionKeys.RESET_BASIC_EXPRESSION_COLUMN_VALUE_LIST,
    });

export const GridGetColumnValueList = (columnId: string): GridGetColumnValuesAction => ({
    columnId,
    type: GridActionKeys.GET_COLUMN_VALUE_LIST,
});

export const GridSetColumnValueList = (
    columnId: string,
    columnValueList: DisplayValueTuple[]
): GridSetColumnValuesAction => ({
    columnId,
    columnValueList,
    type: GridActionKeys.SET_COLUMN_VALUE_LIST,
});
export const GridResetColumnValueList = (columnId: string | null): GridResetColumnValuesAction => ({
    columnId,
    type: GridActionKeys.RESET_COLUMN_VALUE_LIST,
});

export const gridSetColumnFilters = (columnIdList: string[]): GridSetColumnFiltersAction => ({
    columnIdList,
    type: GridActionKeys.SET_COLUMN_FILTERS,
});

export const gridRemoveColumnFilter = (columnId: string): GridRemoveColumnFilterAction => ({
    columnId,
    type: GridActionKeys.REMOVE_COLUMN_FILTER,
});

export const gridRemoveAllColumnFilters = (): GridRemoveAllColumnFiltersAction => ({
    type: GridActionKeys.REMOVE_ALL_COLUMN_FILTERS,
});
