export const jsonToNameValue = (json) => {
  let response = '';
  const keys = Object.keys(json);
  if (keys.length) {
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      response += `${key}=${json[key]}&`;
    }
    return response.slice(0, response.length - 1);
  }

  return response;
};
