import actionTypes from './actionTypes';

export const openCustomViewModal = (data) => (
  {
    type: actionTypes.OPEN_CUSTOM_VIEW_MODAL,
    data: {'isOpenCustomViewModal': true, ...data}
  }
);

export const closeCustomViewModal = (data) => (
  {
    type: actionTypes.CLOSE_CUSTOM_VIEW_MODAL,
    data: {'isOpenCustomViewModal': false, ...data}
  }
);


export const viewChange = data => (
  {
    type: actionTypes.CHANGE_FUND_FINDER_VIEW,
    data
  }
);


export const editCustomView = data => (
  {
    type: actionTypes.EDIT_FUND_FINDER_CUSTOM_VIEW,
    data
  }
);

export const deleteCustomView = data => (
  {
    type: actionTypes.DELETE_FUND_FINDER_CUSTOM_VIEW,
    data
  }
);

export const modalLoading = data => (
  {
    type: actionTypes.MODAL_LOADING,
    data
  }
);

export const addSaveViewData = data => (
  {
    type: actionTypes.ADD_SAVE_VIEW_DATA,
    data: {
      views: data.views,
      isOpenCustomViewModal: data.isOpenCustomViewModal,
      isModalLoading: data.isModalLoading
    }
  }
);

export const updateViewColumns = data => (
  {
    type: actionTypes.UPDATE_FINDER_VIEW_COLUMNS_DATA,
    data: {
      views: data.views,
      groupBy: data.groupBy
    }
  }
);

export const addEditDeleteViewData = data => (
  {
    type: actionTypes.ADD_EDIT_DELETE_FUND_FINDER_VIEW_DATA,
    data: {
      selectedViewId: data.selectedViewId,
      views: data.views,
      isOpenCustomViewModal: data.isOpenCustomViewModal,
      isModalLoading: data.isModalLoading,
      groupBy: data.groupBy
    }
  }
);

export const applyFilter = data => ({
  type: actionTypes.APPLY_FUND_FINDER_FILTER,
  data: {'filterBy': data.filters, 'filterModel': data.filterModel, 'filteredDataLength': data.filteredDataLength}
});

export const sortChange = data => (
  {
    type: actionTypes.FUND_FINDER_CHANGE_COLUMN_SORT,
    data: data.activeView,
    reducerHelper: data.params
  }
);
export const columnMoved = data => (
  {
    type: actionTypes.FUND_FINDER_COLUMN_ORDER_CHANGE,
    data
  }
);
export const columnRemove = data => (
  {
    type: actionTypes.FUND_FINDER_COLUMN_REMOVE,
    data
  }
);

export const columnWidthChanged = data => (
  {
    type: actionTypes.FUND_FINDER_COLUMN_WIDTH_CHANGE,
    data
  }
);

export const updateViews = data => (
  {
    type: actionTypes.VIEWS_UPDATE,
    data: {'views': data}
  }
);

export const addFundFinderData = data => (
  {
    type: actionTypes.ADD_FUND_FINDER_DATA,
    data: {
      loading: data.loading,
      fundFinderData: data.fundFinderData
    }
  }
);

export const fundFinderDataLoading = data => (
  {
    type: actionTypes.FUND_FINDER_GRID_LOADING,
    data
  }
);

export const addFundFinderLoadedData = data => (
  {
    type: actionTypes.ADD_FUND_FINDER_LOADED_DATA,
    data: {
      columnsMetadata: data.columnsMetadata,
      loading: data.loading,
      views: data.views,
      managers: data.managers,
      fundCurrencies: data.fundCurrencies,
      esgFund: data.esgFund,
      fundInvestmentTypes: data.fundInvestmentTypes,
      isOpenCustomViewModal: false
    }
  }
);

export const startExportToExcel = data => (
  {
    type: actionTypes.FUND_FINDER_START_EXPORT_TO_EXCEL,
    data
  });

export const startExportToPdf = (data) => (
  {
    type: actionTypes.FUND_FINDER_START_EXPORT_TO_PDF,
    data
  }
);
export const changeFileType = data => (
  {
    type: actionTypes.CHANGE_DOWNLOAD_FILE_TYPE,
    data
  }
);
