export const customFilledIcons: {
    brand: CustomFilledBrandIcon[];
    grid: CustomFilledGridIcon[];
    marquee: CustomFilledMarqueeIcon[];
    toolkit: CustomFilledToolkitIcon[];
} = {
    brand: ['gs-logo-box', 'gs-logo-sig', 'mq-logo', 'mq-logo-full'],
    grid: [
        'grid-child-bottom',
        'grid-child-middle',
        'grid-child-pipe',
        'grid-child-top-point',
        'grid-child-top',
        'grid-collapse',
        'grid-delta-negative',
        'grid-delta-positive',
        'grid-expand',
        'grid-hide-children',
        'grid-input-resizing',
        'grid-large',
        'grid-reorder-column',
        'grid-reorder-row',
        'grid-show-children',
        'grid-table-columns',
        'grid-table-rows',
    ],
    marquee: [
        'mq-angel',
        'mq-asset',
        'mq-aurora-developer',
        'mq-client-hub',
        'mq-commentary',
        'mq-data-services',
        'mq-devil',
        'mq-down-arrow',
        'mq-enable-1-click',
        'mq-enhanced-trading-grid',
        'mq-hammer',
        'mq-hedge',
        'mq-hedging',
        'mq-intelligence',
        'mq-investment',
        'mq-iselect-customize',
        'mq-ladder',
        'mq-left-axis',
        'mq-light-bulb-off',
        'mq-light-bulb-on',
        'mq-log-out',
        'mq-logo-full',
        'mq-logo',
        'mq-metrics',
        'mq-moon',
        'mq-network',
        'mq-order-blotter-2',
        'mq-order-blotter',
        'mq-platform-profile',
        'mq-plottool',
        'mq-portfolio-choose-type-credit',
        'mq-portfolio-choose-type-equity',
        'mq-portfolio',
        'mq-rebalance',
        'mq-research-home',
        'mq-right-axis',
        'mq-save-as-workspace',
        'mq-single-trading-tile',
        'mq-sun',
        'mq-ticket',
        'mq-trading-blotter',
        'mq-trading-tiles',
        'mq-trend-down',
        'mq-trend-up',
        'mq-up-arrow',
    ],
    toolkit: [
        'add-shopping-basket',
        'alteryx',
        'angular',
        'arrow-left-right',
        'arrow-up-down',
        'aspen',
        'auto-fix',
        'bullseye',
        'calculator',
        'calendar-clock',
        'calendar-minus',
        'calendar-plus',
        'chart-areaspline',
        'chart-bar',
        'chart-line',
        'chevron-double-down',
        'chevron-double-left',
        'chevron-double-right',
        'chevron-double-up',
        'clipboard',
        'close-box',
        'currency-eur',
        'currency-gbp',
        'currency-inr',
        'currency-jpy',
        'currency-krw',
        'currency-rub',
        'currency-usd',
        'cursor-default',
        'database',
        'deal-harbor',
        'emoji-events',
        'facebook',
        'factory',
        'file-code',
        'file-document-edit',
        'file-document',
        'file-excel',
        'file-image',
        'file-multiple',
        'file-pdf',
        'file-powerpoint',
        'file-word',
        'file',
        'flask',
        'folder-open-variant',
        'grid-large',
        'gs-equal',
        'highlight_alt',
        'honest-dollar',
        'instagram',
        'lithium',
        'linkedin',
        'loading',
        'login-variant',
        'logout-variant',
        'metric-arrow-drop-down',
        'metric-arrow-drop-up',
        'motion-photos-on',
        'powerbi',
        'priority-high-gs',
        'qlik',
        'qliksense',
        'qlikview',
        'quicksight',
        'rapidminer',
        'react',
        'real-estate',
        'road-variant',
        'roae',
        'server',
        'share-variant',
        'sitemap',
        'skip-left',
        'skip-right',
        'sort-ascending-variant',
        'sort-descending-variant',
        'sort-variant',
        'snapchat',
        'square-variant',
        'symphony',
        'table',
        'tableau',
        'tag-multiple',
        'tiktok',
        'twitter',
        'vector-polyline',
        'window-maximize',
        'window-minimize',
        'window-restore',
        'x',
        'youtube',
    ],
};

export const customOutlinedIcons: {
    brand: CustomOutlinedBrandIcon[];
    grid: CustomOutlinedGridIcon[];
    marquee: CustomOutlinedMarqueeIcon[];
    toolkit: CustomOutlinedToolkitIcon[];
} = {
    brand: ['gs-logo-box'],
    grid: [],
    marquee: [],
    toolkit: [
        'add-shopping-basket',
        'emoji-events',
        'file-code',
        'file-document-edit',
        'file-document',
        'file-excel',
        'file-image',
        'file-multiple',
        'file-pdf',
        'file-powerpoint',
        'file-word',
        'file',
        'folder-open-variant',
        'highlight_alt',
        'motion-photos-on',
        'square-variant',
        'tag-multiple',
    ],
};

/**
 * Map of custom icon names.
 *
 * Used for quick look up to determine if an icon is a custom icon,
 * so that we are not repeated searching through the entire list of custom icons during runtime.
 */
export const customIconMap = createCustomIconMap();

/**
 * Creates a map of custom icon names.
 *
 * Used for quick look up to determine if an icon is a custom icon,
 * so that we are not repeated searching through the entire list of custom icons during runtime.
 */
function createCustomIconMap(): { [key in CustomIcon]: true } {
    const customIconMap = {} as { [key in CustomIcon]: true };

    const customIcons: CustomIcon[] = Array.from(
        new Set<CustomIcon>([
            ...customFilledIcons.brand,
            ...customFilledIcons.grid,
            ...customFilledIcons.marquee,
            ...customFilledIcons.toolkit,
            ...customOutlinedIcons.brand,
            ...customOutlinedIcons.grid,
            ...customOutlinedIcons.marquee,
            ...customOutlinedIcons.toolkit,
        ])
    );

    customIcons.forEach(iconName => (customIconMap[iconName] = true));

    return customIconMap;
}

export type CustomFilledBrandIcon = 'gs-logo-box' | 'gs-logo-sig' | 'mq-logo' | 'mq-logo-full';
export type CustomOutlinedBrandIcon = 'gs-logo-box';

export type CustomFilledGridIcon =
    | 'grid-child-bottom'
    | 'grid-child-middle'
    | 'grid-child-pipe'
    | 'grid-child-top-point'
    | 'grid-child-top'
    | 'grid-collapse'
    | 'grid-delta-negative'
    | 'grid-delta-positive'
    | 'grid-expand'
    | 'grid-hide-children'
    | 'grid-input-resizing'
    | 'grid-large'
    | 'grid-reorder-column'
    | 'grid-reorder-row'
    | 'grid-show-children'
    | 'grid-table-columns'
    | 'grid-table-rows';

export type CustomOutlinedGridIcon = never;

export type CustomFilledMarqueeIcon =
    | 'mq-angel'
    | 'mq-asset'
    | 'mq-aurora-developer'
    | 'mq-client-hub'
    | 'mq-commentary'
    | 'mq-data-services'
    | 'mq-devil'
    | 'mq-down-arrow'
    | 'mq-enable-1-click'
    | 'mq-enhanced-trading-grid'
    | 'mq-hammer'
    | 'mq-hedge'
    | 'mq-hedging'
    | 'mq-intelligence'
    | 'mq-investment'
    | 'mq-iselect-customize'
    | 'mq-ladder'
    | 'mq-left-axis'
    | 'mq-light-bulb-off'
    | 'mq-light-bulb-on'
    | 'mq-log-out'
    | 'mq-logo-full'
    | 'mq-logo'
    | 'mq-metrics'
    | 'mq-moon'
    | 'mq-network'
    | 'mq-order-blotter-2'
    | 'mq-order-blotter'
    | 'mq-platform-profile'
    | 'mq-plottool'
    | 'mq-portfolio-choose-type-credit'
    | 'mq-portfolio-choose-type-equity'
    | 'mq-portfolio'
    | 'mq-rebalance'
    | 'mq-research-home'
    | 'mq-right-axis'
    | 'mq-save-as-workspace'
    | 'mq-single-trading-tile'
    | 'mq-sun'
    | 'mq-ticket'
    | 'mq-trading-blotter'
    | 'mq-trading-tiles'
    | 'mq-trend-down'
    | 'mq-trend-up'
    | 'mq-up-arrow';

export type CustomOutlinedMarqueeIcon = never;

export type CustomFilledToolkitIcon =
    | 'add-shopping-basket'
    | 'alteryx'
    | 'angular'
    | 'arrow-left-right'
    | 'arrow-up-down'
    | 'aspen'
    | 'auto-fix'
    | 'bullseye'
    | 'calculator'
    | 'calendar-clock'
    | 'calendar-minus'
    | 'calendar-plus'
    | 'chart-areaspline'
    | 'chart-bar'
    | 'chart-line'
    | 'chevron-double-down'
    | 'chevron-double-left'
    | 'chevron-double-right'
    | 'chevron-double-up'
    | 'clipboard'
    | 'close-box'
    | 'currency-eur'
    | 'currency-gbp'
    | 'currency-inr'
    | 'currency-jpy'
    | 'currency-krw'
    | 'currency-rub'
    | 'currency-rub'
    | 'currency-usd'
    | 'cursor-default'
    | 'database'
    | 'deal-harbor'
    | 'emoji-events'
    | 'facebook'
    | 'factory'
    | 'file-code'
    | 'file-document-edit'
    | 'file-document'
    | 'file-excel'
    | 'file-image'
    | 'file-multiple'
    | 'file-pdf'
    | 'file-powerpoint'
    | 'file-word'
    | 'file'
    | 'flask'
    | 'folder-open-variant'
    | 'grid-large'
    | 'gs-equal'
    | 'highlight_alt'
    | 'honest-dollar'
    | 'instagram'
    | 'linkedin'
    | 'lithium'
    | 'loading'
    | 'login-variant'
    | 'logout-variant'
    | 'metric-arrow-drop-down'
    | 'metric-arrow-drop-up'
    | 'motion-photos-on'
    | 'powerbi'
    | 'priority-high-gs'
    | 'qlik'
    | 'qliksense'
    | 'qlikview'
    | 'quicksight'
    | 'rapidminer'
    | 'rapidview'
    | 'react'
    | 'real-estate'
    | 'road-variant'
    | 'roae'
    | 'server'
    | 'share-variant'
    | 'sitemap'
    | 'skip-left'
    | 'skip-right'
    | 'sort-ascending-variant'
    | 'sort-descending-variant'
    | 'sort-variant'
    | 'snapchat'
    | 'square-variant'
    | 'symphony'
    | 'table'
    | 'tableau'
    | 'tag-multiple'
    | 'tiktok'
    | 'twitter'
    | 'vector-polyline'
    | 'window-maximize'
    | 'window-minimize'
    | 'window-restore'
    | 'x'
    | 'youtube';

export type CustomOutlinedToolkitIcon =
    | 'add-shopping-basket'
    | 'emoji-events'
    | 'file-code'
    | 'file-document-edit'
    | 'file-document'
    | 'file-excel'
    | 'file-image'
    | 'file-multiple'
    | 'file-pdf'
    | 'file-powerpoint'
    | 'file-word'
    | 'file'
    | 'folder-open-variant'
    | 'highlight_alt'
    | 'motion-photos-on'
    | 'square-variant'
    | 'tag-multiple';

export type CustomIcon = CustomFilledIcon | CustomOutlinedBrandIcon;

export type CustomFilledIcon =
    | CustomFilledBrandIcon
    | CustomFilledGridIcon
    | CustomFilledMarqueeIcon
    | CustomFilledToolkitIcon;

export type CustomOutlinedIcon =
    | CustomOutlinedBrandIcon
    | CustomOutlinedGridIcon
    | CustomOutlinedMarqueeIcon
    | CustomOutlinedToolkitIcon;
