import React, {useState, useEffect, useCallback} from 'react';
import {Label} from '@gs-ux-uitoolkit-react/label';
import {Select} from '@gs-ux-uitoolkit-react/select';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import {useChartContext} from '../../context';
import {Conditional} from "../../../../components/core/Conditional";
import './index.scss';

const SearchMetrics = ({title, onSelectCompareItem, allMetricOptions}) => {
  const { state: {metrics: selectedMetrics = []} = {}} = useChartContext();
  const [primaryMetricsItem, compareMetricsItem] = selectedMetrics;
  const initiallySelectedItem = selectedMetrics.length > 1 ? compareMetricsItem : {};
  const [metrics, setMetrics] = useState(initiallySelectedItem);

  useEffect(() => {
    onSelectCompareItem('metrics', metrics);
  }, [metrics]);

  useEffect(() => {
    if (Object.keys(initiallySelectedItem).length > 0 && metrics.value !== initiallySelectedItem.value) {
      setMetrics(initiallySelectedItem);
    }
  }, [initiallySelectedItem]);

  const handleSelection = useCallback((event) => {
    if (event.selectedOption) {
      setMetrics(event.selectedOption);
    }
  }, [metrics]);
  const allMetricOptionsClone = cloneDeep(allMetricOptions);
  const op = allMetricOptionsClone.map((item)=> {
    item.items.map(i => {
      if(i.label === primaryMetricsItem.label) i.disabled = true;
      return i;
    })
    return item;
  });
  const filteredMetricsOptions = () => (
    op.map(item => ({
      label: item.label,
      options: item.items
    }))
  );
  return (
    <div className="metrics">
      <Conditional condition={!!title}>
        <Label>{title}</Label>
      </Conditional>
      <Select
        selectedValue={metrics.value}
        options={filteredMetricsOptions()}
        className="metrics__items"
        onChange={handleSelection}
        clearable={false}
      />
    </div>
  );
};

SearchMetrics.propTypes = {
  title: PropTypes.string,
  onSelectCompareItem: PropTypes.func,
  allMetricOptions: PropTypes.array
};

export default SearchMetrics;
