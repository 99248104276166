import {
    arrayHelper,
    ExpressionQuery,
    ExpressionRule,
    logger,
    genericExpressionHelper,
} from '@gs-ux-uitoolkit-common/datacore';
import { Badge } from '@gs-ux-uitoolkit-react/badge';
import { ListGroup, ListGroupItem } from '@gs-ux-uitoolkit-react/list-group';
import { Container, Row } from '@gs-ux-uitoolkit-react/layout';
import { Component } from 'react';
import { GridColumn } from '../../../grid-wrappers/grid-wrapper';
import { expressionHelper } from '../../../libraries/helpers/expression-helper';

export interface ExpressionPreviewProps {
    expressionQuery: ExpressionQuery;
    gridColumnList: GridColumn[];
    showTitle?: boolean;
}

export class ExpressionPreview extends Component<ExpressionPreviewProps> {
    constructor(props: ExpressionPreviewProps) {
        super(props);
    }

    public render() {
        const columnIdListWithPivots = expressionHelper.getBasicExpressionColumnIdList(
            this.props.expressionQuery
        );
        const previewContent = columnIdListWithPivots.map(columnIdWithPivot => {
            const columnGroup = expressionHelper.getBasicExpressionColumnGroup(
                columnIdWithPivot.columnId,
                this.props.expressionQuery,
                columnIdWithPivot.pivotKeys
            );
            const gridColumn = columnIdWithPivot.pivotKeys
                ? this.props.gridColumnList.find(
                      col =>
                          col.primaryColumnId === columnIdWithPivot.columnId &&
                          arrayHelper.areIdentical(
                              col.pivotKeys || [],
                              columnIdWithPivot.pivotKeys || []
                          )
                  )
                : this.props.gridColumnList.find(
                      col => col.columnId === columnIdWithPivot.columnId
                  );
            if (!gridColumn) {
                logger.warn('Cannot find column', columnIdWithPivot);
            }
            const columnLabel = gridColumn ? gridColumn.columnLabel : columnIdWithPivot.columnId;
            let colGroupItems: JSX.Element[] | null = null;
            if (columnGroup) {
                colGroupItems = columnGroup.rules.map((rule, idx) => {
                    // we know it's a simple rule
                    const expressionAsString = genericExpressionHelper.simpleExpressionRuletoString(
                        rule as ExpressionRule
                    );
                    return (
                        <ListGroupItem
                            key={'rule' + idx}
                            className="expression-previewer-simple-rule"
                        >
                            {expressionAsString}
                        </ListGroupItem>
                    );
                });
            }
            const lastColumn = arrayHelper.last(columnIdListWithPivots);
            const isLastPreview = !!(
                lastColumn &&
                lastColumn.columnId === columnIdWithPivot.columnId &&
                arrayHelper.areIdentical(
                    lastColumn.pivotKeys || [],
                    columnIdWithPivot.pivotKeys || []
                )
            );
            return (
                <Container key={'rules' + columnLabel}>
                    <Row>
                        <ListGroup>
                            <ListGroupItem
                                color="info"
                                className="basic-expression-preview-list-group-item-column"
                            >
                                {columnLabel}
                            </ListGroupItem>
                            {colGroupItems}
                        </ListGroup>
                    </Row>

                    {!isLastPreview ? (
                        <Row>
                            <Badge status="success" className="basic-expression-preview-or-badge">
                                And
                            </Badge>
                        </Row>
                    ) : null}
                </Container>
            );
        });
        return previewContent;
    }
}
