export type InputMethod = 'pointer' | 'keyboard' | 'selection' | 'arrow';

export interface MenuEventProps {
    menuElement: HTMLElement | null;
}

export interface MenuBlurEventProps extends MenuEventProps {
    nextActiveElement: HTMLElement | null;
    input: InputMethod;
}

export class MenuEvent implements MenuEventProps {
    public menuElement: MenuEventProps['menuElement'];

    constructor(props: MenuEventProps) {
        this.menuElement = props.menuElement;
    }
}

export class MenuBlurEvent extends MenuEvent implements MenuBlurEventProps {
    public nextActiveElement: MenuBlurEventProps['nextActiveElement'];
    public input: MenuBlurEventProps['input'];

    constructor(props: MenuBlurEventProps) {
        super(props);
        this.nextActiveElement = props.nextActiveElement;
        this.input = props.input;
    }
}
