import {connect} from 'react-redux';
import HelpSection from '../../components/app/HelpSection';
import {getHelpTitleSelector} from '../../selectors/pages/login';
import {labelSelector, helpContentsSelector} from '../../selectors/app';
import {isShowMosaicHelp} from '../../actions/page/login';

const mapStateToProps = (state) => ({
  labels: labelSelector(state),
  helpContents: helpContentsSelector(state),
  title: getHelpTitleSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  isShowMosaicHelpSection: (flag) => dispatch(isShowMosaicHelp(flag)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HelpSection);
