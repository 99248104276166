import React from 'react';
import useUserMaintenance from '../../../context';
import { renderNodeLabel } from '../../../utils/accountsTree';
import translator from '../../../../../../services/translator';

const { translate: t } = translator;

const InnerRenderer = params => {
  const {
    state: { isOrgEditable: isEditable },
  } = useUserMaintenance();

  const { searchTerm } = params.context;

  const { taAccountFullLabel, label } = params.data;
  const title = `${label}`;
  let firmUnmap = '';

  const result = searchTerm && label ? [] : label;
  if (searchTerm && label) {
    const escapeRegExp = (str = '') => str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    const regex = new RegExp(`(${escapeRegExp(searchTerm)})`, 'gi');
    const parts = label.split(regex).filter(part => part);
    for (let i = 0; i < parts.length; i++) {
      const cls = regex.test(parts[i]) ? 'highlightedText' : '';
      result.push(
        <span key={i} className={`${cls}`}>
          {parts[i]}
        </span>
      );
    }
  }

  const innerLabel = params.node.level !== 4 ? <span className='light-label'>{renderNodeLabel(params.node.level)}</span> : '';

  if ('firmCode' in params.data) {
    firmUnmap = (
      <span className='unmap-container'>
        <span className='vector-icon' data-testid='unmap-icon' />
        {t('tkUnmapFirm')}
      </span>
    );
  }

  const tooltip = params.node.level === 3 ? taAccountFullLabel : title;
  const firmUnmapStr = isEditable ? firmUnmap : <span className='dummy-container' />;

  return (
    <span title={tooltip}>
      {result}
      {innerLabel}
      {firmUnmapStr}
    </span>
  );
};

export default InnerRenderer;
