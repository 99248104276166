import { cx } from '@gs-ux-uitoolkit-common/style';
import { StepProps, StepStatus } from './step-props';
import { StepCssClasses } from './step-style-sheet';
import { IconBaseProps } from '@gs-ux-uitoolkit-common/icon-font';

const globalClassName = 'gs-step';

export const getStepRootClasses = (props: {
    cssClasses: StepCssClasses;
    overrideClasses?: StepProps['classes'];
    className?: StepProps['className'];
}): string => {
    const { cssClasses, className, overrideClasses } = props;
    return cx(cssClasses.root, className, overrideClasses && overrideClasses.root, globalClassName);
};

export const getStepButtonClasses = (props: {
    cssClasses: StepCssClasses;
    overrideClasses?: StepProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.button,
        overrideClasses && overrideClasses.button,
        `${globalClassName}__button`
    );
};

export const getStepIndicatorClasses = (props: {
    cssClasses: StepCssClasses;
    overrideClasses?: StepProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.indicator,
        overrideClasses && overrideClasses.indicator,
        `${globalClassName}__indicator`
    );
};

export const getStepIconClasses = (props: {
    cssClasses: StepCssClasses;
    overrideClasses?: StepProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(cssClasses.icon, overrideClasses && overrideClasses.icon, `${globalClassName}__icon`);
};

export const getStepLabelClasses = (props: {
    cssClasses: StepCssClasses;
    overrideClasses?: StepProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.label,
        overrideClasses && overrideClasses.label,
        `${globalClassName}__label`
    );
};

export const getStepContentClasses = (props: {
    cssClasses: StepCssClasses;
    overrideClasses?: StepProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.content,
        overrideClasses && overrideClasses.label,
        `${globalClassName}__content`
    );
};

/**
 * Used internally, returns the effective status based on the props given to the
 * Step component.
 *
 * If a particular `status` is provided as a prop, that is always used.
 * Otherwise the status is determined based on the `index` of the Step and the
 * `currentStep`
 */
export function getStepEffectiveStatus({
    statusProp,
    currentStep,
    index,
}: {
    statusProp: StepProps['status'];
    currentStep: number | undefined;
    index: number;
}): StepStatus {
    if (statusProp) {
        // An explicit 'status' was provided as a prop, use that
        return statusProp;
    } else if (index < currentStep!) {
        return 'success';
    } else if (index === currentStep) {
        return 'information';
    } else {
        return 'none';
    }
}

/**
 * Used internally, derives the icon name from the Step component's props.
 *
 * If an `icon` prop is provided, that is used as-is. Otherwise the icon is
 * derived from the `status`.
 */
export function getStepIcon(
    icon: StepProps['icon'],
    status: StepProps['status']
): IconBaseProps | null {
    if (icon === null) {
        // icon is set to null, override status pre-set icon
        return null;
    } else if (icon) {
        // 'icon' is provided as property, takes precedence over 'status'
        return icon;
    } else if (status === 'error') {
        return { name: 'close', type: 'filled' };
    } else if (status === 'success') {
        return { name: 'check', type: 'filled' };
    } else if (status === 'warning') {
        return { name: 'priority-high-gs', type: 'filled' };
    } else {
        // default to null
        return null;
    }
}
