import { CSSProperties, HTMLAttributes, ReactNode, FunctionComponent, useContext } from 'react';
import {
    ModalFooterProps as ModalFooterCommonProps,
    modalFooterStyleSheet,
    getModalFooterClasses,
} from '@gs-ux-uitoolkit-common/modal';
import { ModalSizeContext } from './modal-size-context';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';

export interface ModalFooterProps
    extends ModalFooterCommonProps<CSSProperties>,
        HTMLAttributes<HTMLElement> {
    /**
     * Content to display inside the Modal Footer.
     */
    children?: ReactNode;
}

/**
 * Provides appropriate spacing around the footer section of the Modal's content.
 */
export const ModalFooter: FunctionComponent<ModalFooterProps> = ({
    className,
    classes: overrideClasses,
    size,
    ...attributes
}) => {
    const sizeContext = useContext(ModalSizeContext);
    const theme = useTheme();
    const cssClasses = useStyleSheet(modalFooterStyleSheet, { size: size || sizeContext, theme });
    return (
        <div
            {...attributes}
            data-gs-uitk-component="modal-footer"
            className={getModalFooterClasses({ cssClasses, className, overrideClasses })}
        />
    );
};
