import {
    DataType,
    ExpressionCondition,
    ExpressionGroup,
    ExpressionOperator,
} from '@gs-ux-uitoolkit-common/datacore';
import { rowGroupColumnColId } from '../../datasources/dra-viewport-datasource';
import { GridColumn } from '../../grid-wrappers/grid-wrapper';

export class SearchableHelper {
    /**
     * Build up the filter for the searchable plugin
     */
    public buildSearchableFilter(
        searchTerm: string,
        searchableColumns: string[] | null,
        columnList: GridColumn[]
    ) {
        let searchedColumns: GridColumn[];
        if (searchableColumns) {
            searchedColumns = columnList.filter(col => searchableColumns.includes(col.columnId));
        } else {
            searchedColumns = columnList
                .filter(col => col.visible)
                .filter(col => col.columnId !== rowGroupColumnColId);
        }

        const searchableQuery: ExpressionGroup = {
            condition: ExpressionCondition.Or,
            rules: [],
        };

        searchedColumns.forEach(col => {
            if (col.isFilterable) {
                switch (col.dataType) {
                    case DataType.Number: {
                        const parsedFloat = parseFloat(searchTerm);
                        if (!isNaN(parsedFloat)) {
                            searchableQuery.rules.push({
                                field: col.columnId,
                                operator: ExpressionOperator.Equals,
                                value: parsedFloat,
                            });
                        }
                        break;
                    }
                    case DataType.Date:
                    case DataType.DateTime: {
                        const parsedDate = Date.parse(searchTerm);
                        if (!isNaN(parsedDate)) {
                            searchableQuery.rules.push({
                                field: col.columnId,
                                operator: ExpressionOperator.ContainsCaseInsensitive,
                                value: parsedDate,
                            });
                        }
                        break;
                    }
                    default: {
                        searchableQuery.rules.push({
                            field: col.columnId,
                            operator: ExpressionOperator.ContainsCaseInsensitive,
                            value: searchTerm,
                        });
                    }
                }
            }
        });

        return searchableQuery;
    }
}

export const searchableHelper = new SearchableHelper();
