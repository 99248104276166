import Login from '../containers/Login';
import CreatePasswordPage from '../containers/CreatePassword';
import CreatePinPage from '../containers/CreatePin';
import InterstitialModal from '../containers/InterstitialModal';
import Constants from '../constants/appConstants';
import ForgotPassword from '../containers/ForgotPassword';
import {getClientMigrationModalSubUrl, getLoginRoute} from '../utils/commonUtils';

export default [
  {
    'exact': true,
    'path': getLoginRoute(Constants.LOGIN_ROUTE),
    'component': Login,
    'id': 'Login'
  },
  {
    'exact': true,
    'path': getLoginRoute(Constants.CREATE_PASSWORD_ROUTE),
    'component': CreatePasswordPage,
    'id': 'CreatePassword'
  },
  {
    'exact': true,
    'path': getLoginRoute(Constants.CREATE_PIN_ROUTE),
    'component': CreatePinPage,
    'id': 'CreatePin'
  },
  {
    'path': getLoginRoute(getClientMigrationModalSubUrl()),
    'exact': true,
    'component': InterstitialModal,
    'id': 'InterstitialModal'
  },
  {
    'exact': true,
    'path': getLoginRoute(Constants.FORGOT_PASSWORD_ROUTE),
    'component': ForgotPassword,
    'id': 'ForgotPassword'
  }
];
