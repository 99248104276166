import React, {useState}  from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
const ListType = {
  ADD: 'add',
  REMOVE: 'remove'
};

const Item = (props) => {
  const {selected, disabled, label, value, index, testId, type, onSelect, onAdd, onRemove} = props;
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);
  const itemTestId = `${testId}-item-${index}`;

  const handleAddClick = (addedItemValue, addedItemProps) => {
    onAdd && onAdd(addedItemValue, addedItemProps);
  };
  const handleRemoveClick = (removedItemValue, removedItemProps) => {
    onRemove && onRemove(removedItemValue, removedItemProps);
  };

  const handleSelect =  (selectedItemValue, selectedItemProps) => {
    onSelect && onSelect(selectedItemValue, selectedItemProps);
  };
  const onActionClick = type === ListType.ADD ? handleAddClick : handleRemoveClick;

  return (
    <li
      className={cn('account-list__item', {'account-list__item--selected': selected}, {'account-list__item--hovered': hover},
         {'account-list__item--disabled': disabled}, {'account-list__item--focused': focus})}
      data-testid={`${itemTestId}-item-${index}`}
      onFocus={(e) => {e.stopPropagation(); setFocus(true);}}
      onBlur={(e) => {e.stopPropagation();  setFocus(false);}}
      onMouseEnter={(e) => {e.stopPropagation(); setHover(true);}}
      onMouseLeave={(e) => {e.stopPropagation();  setHover(false);}}>
      <div data-value={value} onClick={handleSelect}>
        <label>{label}</label>
        <span onClick={onActionClick} className={cn('account-list__remove', {'account-list__remove--disabled': disabled})} data-testid={`${itemTestId}-icon`}>
          <span className="icon" />
        </span>
      </div>
    </li>
  );
};

Item.defaultProps = {
  testId: '',
  selected: false,
  disabled: false,
  onSelect: () =>  { /* TODO: This will be implemented when edit mode is enabled */ }
};

Item.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  index: PropTypes.number,
  testId: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onSelect: PropTypes.func,
};

export default Item;
