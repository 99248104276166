import { StyleSheet, CssClasses, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { getOverflowStyles } from '@gs-ux-uitoolkit-common/shared';
import { defaultTooltipProps } from './tooltip-props';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './tooltip-theme-overrides';

export interface TooltipStyleSheetProps {
    size?: string;
    theme: Theme;
}

export type TooltipCssClasses = CssClasses<typeof tooltipStyleSheet>;

export type TooltipStyledClasses = CssClassDefinitionsObject<
    'content' | 'dismissButton' | 'label' | 'contentInner'
>;

export interface TooltipStyleOverridesParams {
    props: DeepReadonly<TooltipStyleSheetProps>;
    createDefaultStyledClasses: () => TooltipStyledClasses;
}

interface StylesForSize {
    paddingY: string;
    paddingX: string;
    fontSize: string;
}

enum Sizes {
    xs = 'xs',
    sm = 'sm',
    md = 'md',
    lg = 'lg',
}

export const tooltipStyleSheet = new StyleSheet('tooltip', (props: TooltipStyleSheetProps) => {
    return createComponentClassDefinitions<TooltipStyleSheetProps, TooltipStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.tooltip
    );
});

function createDefaultStyledClasses({ size }: TooltipStyleSheetProps): TooltipStyledClasses {
    const stylesBasedOnSize: { [key in Sizes]: StylesForSize } = {
        xs: {
            paddingY: '2px',
            paddingX: '6px',
            fontSize: '12px',
        },
        sm: {
            paddingY: '4px',
            paddingX: '8px',
            fontSize: '12px',
        },
        md: {
            paddingY: '6px',
            paddingX: '12px',
            fontSize: '14px',
        },
        lg: {
            paddingY: '8px',
            paddingX: '14px',
            fontSize: '16px',
        },
    };

    const tooltipSize = size || defaultTooltipProps.size;
    const tooltipStyles = stylesBasedOnSize[tooltipSize as Sizes];

    return {
        content: {
            fontSize: tooltipStyles.fontSize,
            borderRadius: '2px',
            display: 'flex',
            alignItems: 'center',
            padding: `${tooltipStyles.paddingY} ${tooltipStyles.paddingX}`,
        },
        contentInner: {
            display: 'flex',
        },
        dismissButton: {
            marginLeft: tooltipStyles.paddingY,
            padding: 0,
            background: 'none',
            color: 'inherit',
            border: 'none',
            font: 'inherit',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '> span': {
                fontSize: tooltipStyles.fontSize,
            },
        },
        label: {
            ...getOverflowStyles('wrap'),
        },
    };
}
