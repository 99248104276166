import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Conditional} from "../../../../components/core/Conditional";
import {deleteCustomReport, setReportDataToDelete} from "../../../../actions/page/reports";
import DeleteReportConfirmModal from "./DeleteReportConfirmModal";
import {reportDataToDeleteSelector} from "../../../../selectors/pages/reports/myReports";
import './index.scss';

export const DeleteReportConfirmation = (props) => {
  const {reportDataToDelete, deleteReport} = props;
  const {reportName, customReportViewId} = reportDataToDelete || {};
  const isModalOpen = !!reportDataToDelete;

  const handleClose = () => {
    props.setReportDataToDelete(null);
  };

  const onHandleDeleteReport = () => {
    deleteReport({
      customReportViewId,
      reportName
    });

    setTimeout(() => {
      handleClose();
    });
  };

  return (
    <Conditional condition={isModalOpen}>
      <DeleteReportConfirmModal
        isModalOpen={isModalOpen}
        reportName={reportName}
        handleDeleteReport={onHandleDeleteReport}
        handleClose={handleClose}
      />
    </Conditional>
  )
};

DeleteReportConfirmation.propTypes = {
  reportDataToDelete: PropTypes.object,
  setReportDataToDelete: PropTypes.func,
  deleteReport: PropTypes.func
};

const mapStateToProps = (state) => ({
  reportDataToDelete: reportDataToDeleteSelector(state)
});

export const mapDispatchToProps = (dispatch) => ({
  deleteReport: data => dispatch(deleteCustomReport(data)),
  setReportDataToDelete: data => dispatch(setReportDataToDelete(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteReportConfirmation);
