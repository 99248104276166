const formatMessage = (message) => {
  const maxLength = 42;
  let currentIndex = 0;
  let currentLineEnd = maxLength;
  let formattedMessage = '';

  while(currentIndex < message.length) {
    formattedMessage = `${formattedMessage}${message.slice(currentIndex, currentLineEnd)}\n`;
    currentIndex += maxLength;
    currentLineEnd += maxLength;
  }
  return formattedMessage;
};

const altTag = document.getElementById('altTag');
const fixedMargin = 5;

const handleHideAltTag = () => {
  altTag.textContent = '';
  altTag.style.visibility = 'hidden';
};

const handleShowAltTag = (e) => {
  const {dataset: {tooltip} = {tooltip: null}} = e.target || {};
  if(tooltip) {
    const {label} = e.target.dataset;

    // render full text to compare for ellipses case
    const {fontSize, paddingLeft, paddingRight} = window.getComputedStyle(e.target, null);
    const targetLeftPadding = paddingLeft && paddingLeft.slice(0, -2) ? (+paddingLeft.slice(0, -2)) : 0;
    const targetRightPadding = paddingRight && paddingRight.slice(0, -2) ? (+paddingRight.slice(0, -2)) : 0;

    altTag.textContent = label;
    altTag.style.fontSize = fontSize;
    const {top, left, bottom, width: targetWidth} = e.target.getBoundingClientRect();
    const { clientWidth: altTagWidth } = altTag;

    const {paddingLeft: ownPaddingLeft, paddingRight: ownPaddingRight} = window.getComputedStyle(altTag, null);
    const altTagLeftPadding = ownPaddingLeft && ownPaddingLeft.slice(0, -2) ? (+ownPaddingLeft.slice(0, -2)) : 0;
    const altTagRightPadding = ownPaddingRight && ownPaddingRight.slice(0, -2) ? (+ownPaddingRight.slice(0, -2)) : 0;
    if(Math.floor(altTagWidth - altTagLeftPadding - altTagRightPadding) > Math.floor(targetWidth - targetLeftPadding - targetRightPadding)) {
      altTag.textContent = formatMessage(label);
      const { clientHeight: height } = altTag;
      const altTagY = top - fixedMargin - height < 0 ? bottom + fixedMargin + height : top - fixedMargin - height;
      const altTagX = left;

      altTag.style.top = `${altTagY}px`;
      altTag.style.left = `${altTagX}px`;
      altTag.style.visibility = 'visible';
    }
    altTag.style.fontSize = '12px';
  }
};

if(altTag){
  document.addEventListener('mouseover', handleShowAltTag);

  document.addEventListener('mouseout', handleHideAltTag);

  document.addEventListener('click', handleHideAltTag);
}
