import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { getIconStyles } from '@gs-ux-uitoolkit-common/icon-font';
import { colors, zIndex, Elevation, fontWeight } from '@gs-ux-uitoolkit-common/design-system';
import { rgba } from 'polished';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './date-picker-theme-overrides';

export interface DatePickerCssClasses {
    root: string;
}

export interface DatepickerStyleSheetProps {
    theme: Theme;
}

export type DatePickerStyledClasses = CssClassDefinitionsObject<keyof DatePickerCssClasses>;

export interface DatePickerStyleOverridesParams {
    props: DeepReadonly<DatepickerStyleSheetProps>;
    createDefaultStyledClasses: () => DatePickerStyledClasses;
}

export const datePickerStyleSheet = new StyleSheet(
    'date-picker',
    (props: DatepickerStyleSheetProps) => {
        return createComponentClassDefinitions<DatepickerStyleSheetProps, DatePickerStyledClasses>(
            props,
            createDefaultStyledClasses,
            props.theme.styleOverrides?.datePicker
        );
    }
);

function createDefaultStyledClasses({ theme }: DatepickerStyleSheetProps): DatePickerStyledClasses {
    const maxWidth = '280px';
    const themeBorder = theme.border;
    const themeText = theme.text;
    const themeState = theme.state;
    const themeSurface = theme.surface;
    const themeColor = theme.color;

    const themeColorScheme = theme.colorScheme;
    const datepickerElevation: Elevation = '05';
    const rangeDatesText = theme.getColorInteractionShades('blue', 'subtle').text;
    const rangeSurfaceShade = theme.getSurfaceInteractionShades('primary', 'subtle');

    return {
        root: {
            '&.flatpickr-calendar': {
                '&:not(.with-shortcuts-plugin)': {
                    width: '100% !important',
                    maxWidth,
                    padding: '12px 8px 8px 8px',
                    '@media (pointer: coarse)': {
                        maxWidth: '420px',
                    },
                    '@media (max-width: 420px)': {
                        maxWidth,
                    },
                },
                '&.with-shortcuts-plugin': {
                    maxWidth: '416px',
                    width: '100% !important',
                    '@media (pointer: coarse)': {
                        maxWidth: '540px',
                    },
                    '@media (max-width: 540px)': {
                        left: '0 !important',
                        right: '0 !important',
                    },
                },
                position: 'absolute',
                animation: 'none',
                direction: 'ltr',
                touchAction: 'manipulation',
                color: themeText.primary,
                background: theme.elevation[datepickerElevation].background,

                boxShadow: theme.elevation[datepickerElevation].shadow,
                textAlign: 'center',
                display: 'none',
                visibility: 'hidden',
                overflow: 'hidden',
                maxHeight: 0,
                opacity: 0,

                '&.open, &.inline': {
                    display: 'block',
                    visibility: 'visible',
                    overflow: 'visible',
                    maxHeight: '640px',
                    opacity: 1,
                },

                '&.open': {
                    marginTop: '6px',
                    zIndex: zIndex.popover, // the flatpickr pop-up will be generated outside the modal DOM, therefore the z-index must be greater than the modals'.
                },
                '&.inline': {
                    position: 'relative',
                    border: 'none',
                    paddingLeft: '8px',
                    boxShadow: 'none',
                    // Hide arrows for inline calendar
                    '&:before, &:after': {
                        display: 'none',
                    },
                },

                // Hide native input number arrows since flatpickr
                // does not handle them correctly
                'input[type="number"].numInput': {
                    cursor: 'text',
                    padding: '0px',
                    fontWeight: 'inherit',
                    color: 'inherit',

                    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                    },
                    // for firefox
                    MozAppearance: 'textfield',

                    '&:focus': {
                        outline: 'none',
                    },
                },
                '&.noCalendar.hasTime': {
                    padding: 0,
                    '.flatpickr-time': {
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        height: 'auto',
                        width: '150px',
                        '&.hasSeconds': {
                            width: '175px',
                        },
                        '&.time24hr': {
                            width: '100px',
                        },
                        '&.time24hr.hasSeconds': {
                            width: '125px',
                        },
                        '.flatpickr-am-pm': {
                            margin: '0px',
                            paddingRight: '10px',
                        },
                    },
                },
                '&:before, &:after': {
                    position: 'absolute',
                    display: 'block',
                    pointerEvents: 'none',
                    border: '1px solid transparent',
                    content: '""',
                    height: '0px',
                    width: '0px',
                    left: '50%',
                    marginLeft: '10px',
                },
                '&.rightMost': {
                    '&:before, &:after': {
                        left: 'auto',
                        right: '22px',
                    },
                },
                '&:before': {
                    borderWidth: '9px',
                    margin: '0 -9px',
                },
                '&:after': {
                    borderWidth: '8px',
                    margin: '0 -8px',
                },
                '&.arrowTop, &.arrowBottom': {
                    '&:before, &:after': {
                        display: 'none',
                    },
                },
                '&:focus': {
                    outline: 0,
                },
                '.numInputWrapper': {
                    position: 'relative',
                    boxSizing: 'border-box',
                    height: '20px',
                    background: 'transparent',
                    border: `1px solid ${themeBorder.moderate}`,
                    borderRadius: '2px',
                    '.numInput': {
                        height: '100%',
                        width: '100%',
                        textAlign: 'center',
                        ...theme.typography.heading06,
                        border: 'none',
                        backgroundColor: 'transparent',
                        padding: 0,
                        '&[disabled]': {
                            opacity: themeState.disabledOpacity,
                            pointerEvents: 'none',
                        },
                    },
                    '.arrowUp, .arrowDown': {
                        display: 'none',
                    },
                },
                // Month container styles
                '.flatpickr-months': {
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '8px',
                    overflow: 'hidden',
                    userSelect: 'none',
                    fontWeight: fontWeight.medium,
                    maxWidth,

                    '.flatpickr-month': {
                        order: 2,
                        flex: 1,
                    },
                    '.flatpickr-prev-month': {
                        order: 2,
                        textAlign: 'left',
                    },
                    '.flatpickr-next-month': {
                        order: 3,
                        textAlign: 'right',
                    },

                    '.flatpickr-prev-month,.flatpickr-next-month': {
                        flexGrow: 1,
                        maxWidth: '50px',
                        textDecoration: 'none',
                        cursor: 'pointer',
                        fontSize: '20px',
                        color: themeText.secondary,
                        svg: {
                            display: 'none',
                        },
                        '&:before': {
                            width: '20px',
                            height: '20px',
                            display: 'inline-block',
                        },
                    },
                    '.flatpickr-current-month, .flatpickr-monthDropdown-months': {
                        ...theme.typography.heading06,
                        borderRadius: '2px',
                        justifyContent: 'center',
                        lineHeight: '16px !important',
                        background: theme.elevation[datepickerElevation].background,
                        color: `${themeText.primary} !important`,
                        padding: '0px',
                        height: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                        transform: 'translate3d(0px, 0px, 0px)',

                        '.numInputWrapper': {
                            width: '6ch',
                            marginLeft: '6px',
                            border: `1px solid ${themeBorder.moderate}`,
                            '&:hover': {
                                border: `1px solid ${theme.increaseContrast(
                                    themeBorder.moderate,
                                    1
                                )}`,
                            },
                            '&:focus-within': {
                                borderColor: themeColorScheme.primary,
                            },
                            height: '20px',

                            // adds the dropdown arrow for the Month <select>
                            '&:before': {
                                ...getIconStyles({
                                    name: 'keyboard-arrow-down',
                                    type: 'filled',
                                    size: 'md',
                                }),
                                lineHeight: 'inherit',
                                position: 'absolute',
                                pointerEvents: 'none',
                                display: 'inline-block',
                                left: '-26px', // use the marginLeft offset
                            },
                        },
                    },
                    '.flatpickr-monthDropdown-months': {
                        border: 'none',
                        cursor: 'pointer',

                        // <select> styling fix
                        // the dropdown arrow is added as a before element on the year <input> el since
                        // <select> els cannot have a :before or :after pseudo class
                        WebkitAppearance: 'none', // chrome / safari
                        MozAppearance: 'none', // firefox
                        paddingRight: '22px', // 2px padding after the month text + 20px for the dropdown icon width

                        // Feb 4, 2022:  We needed to remove this because some apps, such as ION Dealbook are using
                        //    Marquee Desktop / Openfin and using Chrome 80 where :focus-visible is not supported and
                        //    throwing errors.  https://jira.site.gs.com/browse/UX-15248
                        // '&:focus, &:focus-visible': {
                        '&:focus': {
                            outline: 'none',
                        },
                    },
                },

                '.extra-calendar-container': {
                    flexGrow: 1,
                    padding: '12px 8px 8px 8px',
                },
                '.flatpickr-innerContainer': {
                    display: 'flex',
                    boxSizing: 'border-box',
                    overflow: 'hidden',
                    maxWidth,
                    '.flatpickr-rContainer': {
                        width: '100%',
                        '.flatpickr-weekdaycontainer': {
                            display: 'flex',
                            marginTop: '8px',
                            marginBottom: '4px',
                            height: '20px',
                        },
                        '.flatpickr-weekday': {
                            ...theme.typography.heading07,
                            lineHeight: '20px !important',
                            color: themeText.secondary,
                            cursor: 'default',
                            textAlign: 'center',
                            display: 'block',
                            flex: 1,
                            textTransform: 'capitalize',
                            maxWidth: '40px',
                            '&.flatpickr-disabled': {
                                opacity: themeState.disabledOpacity,
                            },
                        },
                        '.flatpickr-days': {
                            position: 'relative',
                            display: 'flex',
                            width: '100% !important',
                            '&:focus': {
                                outline: 0,
                            },
                            '.dayContainer': {
                                outline: 0,
                                textAlign: 'left',
                                boxSizing: 'border-box',
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'space-around',
                                transform: 'translate3d(0px, 0px, 0px)',
                                opacity: 1,
                                width: '100%',
                                'span.flatpickr-day': {
                                    maxWidth: '40px',
                                    maxHeight: '36px',
                                    '&:hover, &:focus': {
                                        background: rgba(themeSurface.bold as string, 0.2),
                                        '&:before': {
                                            content: '""',
                                            width: '100%',
                                            height: '100%',
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            bottom: 0,
                                            left: 0,
                                            borderFadius: 0,
                                            display: 'block',
                                            zIndex: -1,
                                        },
                                    },
                                    '&.today': {
                                        maxWidth: '38px',
                                        maxHeight: '34px',
                                    },
                                    '&.prevMonthDay, &.nextMonthDay': {
                                        borderRadius: 0,
                                        color: themeText.tertiary,
                                        zIndex: -1,
                                        '&.selected': {
                                            color: themeText.reversed,
                                        },
                                    },
                                    '&.startRange, &.startRange.inRange, &.endRange, &.endRange.inRange':
                                        {
                                            color: themeText.reversed,
                                            background: themeColorScheme.primary,

                                            '&.prevMonthDay, &.nextMonthDay': {
                                                color: themeText.reversed,
                                                background: `${themeColorScheme.primary} !important`,

                                                '&:before': {
                                                    borderColor: themeColorScheme.primary,
                                                },
                                            },
                                        },
                                    '&.inRange': {
                                        background: `${rangeSurfaceShade.background} !important`,
                                        color: rangeDatesText,
                                        '&:hover, &:focus': {
                                            background: `${rangeSurfaceShade.hover} !important`,
                                        },

                                        '&.prevMonthDay, &.nextMonthDay': {
                                            background: rgba(
                                                themeColorScheme.primary as string,
                                                0.2
                                            ),
                                        },
                                    },
                                    '&.selected': {
                                        boxShadow: 'none',
                                        color: themeText.reversed,
                                        background: themeColorScheme.primary,
                                        outline: 'none',
                                    },
                                    '&.flatpickr-disabled, &.flatpickr-disabled:hover': {
                                        color: rgba(themeText.primary as string, 0.3),
                                        backgroundColor: 'transparent',
                                        cursor: 'default',
                                    },
                                },
                            },
                        },
                    },
                    '.flatpickr-day': {
                        ...theme.typography.body02,
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        flexGrow: 1,
                        flexBasis: '14%',
                        '&:before': {
                            // Make height 1:1 ratio to width
                            content: '""',
                            display: 'block',
                            float: 'left',
                            marginBottom: '100%',
                        },
                        '&.today': {
                            color: rangeDatesText,
                            border: `1px solid ${themeColorScheme.primary}`,
                        },
                        '&.flatpickr-disabled, &.flatpickr-disabled:hover': {
                            pointerEvents: 'none',
                        },
                        '&.notAllowed, &.notAllowed.prevMonthDay, &.notAllowed.nextMonthDay': {
                            background: 'transparent',
                            cursor: 'default',
                        },
                        '&.week.selected': {
                            borderRadius: 0,
                            boxShadow: `-5px 0 0 ${themeColorScheme.primary}, 5px 0 0 ${themeColorScheme.primary}`,
                        },
                    },
                    '.flatpickr-weekwrapper': {
                        display: 'none',
                    },
                },
                '&.hasWeeks .flatpickr-innerContainer .rContainer': {
                    flex: '0 0 87.5%',
                },
                '.flatpickr-time': {
                    display: 'flex',
                    paddingTop: '16px',
                    justifyContent: 'center',
                    boxShadow: 'none',
                    '.flatpickr-time-separator': {
                        display: 'none',
                    },
                    '.numInputWrapper': {
                        ...theme.typography.heading06,
                        lineHeight: '16px !important', // centers the input in this container
                        width: '6ch',
                        marginLeft: '6px',
                        border: `1px solid ${themeBorder.moderate}`,
                        '&:hover': {
                            borderColor: theme.increaseContrast(themeBorder.moderate, 1),
                        },
                        height: '20px',

                        '&:focus-within': {
                            borderColor: colors.blue060,
                        },
                    },
                    '.flatpickr-am-pm': {
                        width: 'auto',
                        flexBasis: '40px',
                        margin: '0 5px',
                        borderColor: themeBorder.moderate,
                        '&:hover': {
                            borderColor: themeBorder.strong,
                        },
                        ...theme.typography.heading06,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        userSelect: 'none',
                        outline: 0,
                        cursor: 'pointer',
                        '&:hover, &:focus': {
                            color: themeText.link,
                        },
                    },
                },
                '.rangeMode .flatpickr-day': {
                    marginTop: '1px',
                },
            },
            '.flatpickr-wrapper': {
                position: 'relative',
                display: 'inline-block',
            },
            '.flatpickr-input[readonly]': {
                cursor: 'pointer',
            },
            '.flatpickr-input, .flatpickr-calendar .numInputWrapper': {
                '@media (pointer: coarse)': {
                    fontSize: '16px !important', // Stop mobile devices auto-zooming into inputs on touch
                },
            },
            '.input-group': {
                '.form-control': {
                    ...theme.typography.body02,

                    '&::-webkit-input-placeholder': {
                        color: themeText.tertiary,
                    },
                    '&::-moz-placeholder': {
                        color: themeText.tertiary,
                    },
                    '&:-ms-input-placeholder': {
                        color: themeText.tertiary,
                    },
                    '&:-moz-placeholder': {
                        color: themeText.tertiary,
                    },
                    '&::placeholder': {
                        color: themeText.tertiary,
                    },
                    border: `1px solid ${themeText.tertiary}`,
                },
                '.input-group-append': {
                    '.input-group-text': {
                        backgroundColor: themeSurface.primary,
                        borderRight: 'none',
                        padding: '4px 6px 4px 8px',
                        i: {
                            width: '20px',
                            height: '20px',
                            fontSize: '20px',
                            transform: 'translateY(1px)',
                        },
                    },
                    '~.form-control': {
                        borderLeft: 'none',
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                },
                '.form-control + .input-group-append': {
                    '.input-group-text': {
                        borderLeft: 'none',
                        padding: '4px 8px',
                    },
                },
                '&:hover': {
                    '.form-control': {
                        borderColor: themeBorder.strong,
                    },
                    '.input-group-append': {
                        borderColor: themeBorder.strong,
                    },
                },
                '&.focused, &:focus': {
                    '.form-control': {
                        boxShadow: 'none',
                        borderColor: `${themeColorScheme.primary} !important`,
                    },
                    '.input-group-append': {
                        borderColor: `${themeColorScheme.primary} !important`,
                    },
                },
                '.form-control[disabled]': {
                    opacity: themeState.disabledOpacity,
                    '+ .input-group-append': {
                        borderColor: rgba(colors.gray060, 0.15),
                        '.input-group-text': {
                            backgroundColor: rgba(colors.gray060, 0.15),
                            color: rgba(colors.gray090, 0.4),
                        },
                    },
                },
            },
            '.flatpickr-confirm': {
                display: 'none',
            },
            '&.with-shortcuts-plugin': {
                '.calendar-with-shortcuts': {
                    display: 'flex',
                },
                '.shortcuts-container': {
                    position: 'relative',
                    display: 'flex',
                    flexShrink: 0,
                    width: '120px',
                    '@media (max-width: 375px)': {
                        width: '74px',
                    },
                    background: themeSurface.secondary,
                    '.dates-shortcuts': {
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        overflow: 'auto',
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                        label: {
                            ...theme.typography.ui02,
                            letterSpacing: '0.4px',
                            display: 'flex',
                            '@media (max-width: 375px)': {
                                display: 'inline',
                            },
                            width: '100%',
                            padding: '4px 0px 4px 12px',
                            textTransform: 'uppercase',
                            color: themeText.secondary,
                            borderBottom: '0px',
                            margin: '8px 0 0 0',
                            whiteSpace: 'nowrap',
                            '&::before': {
                                // Create a pseudo-element border to control size and placement
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                height: 0,
                                width: '120px',
                                borderTop: `1px solid ${themeBorder.minimal}`,

                                margin: '4px 0 3px',
                                transform: 'translate(-12px, -12px)',
                            },
                        },
                        '.btn': {
                            ...theme.typography.body02,
                            cursor: 'pointer',
                            display: 'flex',
                            '@media (max-width: 375px)': {
                                display: 'inline',
                                '&.last-btn': {
                                    paddingBottom: '18px',
                                },
                                ' span': {
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    display: 'block',
                                    textOverflow: 'ellipsis',
                                },
                            },
                            width: '100%',
                            color: themeText.primary,
                            border: 'none',
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            textAlign: 'left',
                            textTransform: 'capitalize',
                            padding: '6px 0px 6px 12px',
                            '&:hover, &:focus': {
                                color: themeText.link,
                            },
                            '&.today-btn': {
                                paddingTop: '10px',
                            },
                        },
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '10px',
                            backgroundColor: colors.gray040,
                            '&:hover': {
                                backgroundColor: colors.gray050,
                            },
                        },
                    },
                },
            },
            '.schedule-overlay': {
                display: 'block',
                position: 'absolute',
                bottom: '1px',
                '.schedule-container': {
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0 1px 1px 1px',
                    '.event': {
                        display: 'flex',
                        height: '4px',
                        width: '4px',
                        borderRadius: '50%',
                        '&.purple': {
                            background: themeColor.purple.bold,
                        },
                        '&.green': {
                            background: themeColor.teal.bold,
                        },
                        '&.orange': {
                            background: themeColor.orange.bold,
                        },
                        '&.yellow': {
                            background: themeColor.yellow.bold,
                        },
                        '&.blue': {
                            background: themeColor.blue.bold,
                        },
                    },
                },
                '&.space-between .schedule-container .event:not(:first-child)': {
                    marginLeft: '2px',
                },
            },
            '.schedule-legend': {
                display: 'flex',
                flexFlow: 'column',
                marginTop: '8px',
                paddingTop: '8px',
                borderTop: `1px solid ${themeBorder.subtle}`,

                '&::before': {
                    // Pseudo element border to extend the full width of the container using the border on schedule legend as reference
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    height: 0,
                    borderTop: `1px solid ${themeBorder.subtle}`,

                    transform: 'translate(-8px, -9px)',
                },
                '.legend-item': {
                    position: 'relative',
                    marginLeft: '5px',
                    '&:before': {
                        width: '6px',
                        height: '6px',
                        content: '""',
                        position: 'absolute',
                        top: '12px',
                        left: 0,
                        transform: 'translateY(-80%)',
                        borderRadius: '50%',
                    },
                    '&.purple:before': {
                        background: themeColor.purple.bold,
                    },
                    '&.green:before': {
                        background: themeColor.teal.bold,
                    },
                    '&.orange:before': {
                        background: themeColor.orange.bold,
                    },
                    '&.yellow:before': {
                        background: themeColor.yellow.bold,
                    },
                    '&.blue:before': {
                        background: themeColor.blue.bold,
                    },
                    '.legend-label': {
                        ...theme.typography.body03,
                        display: 'flex',
                        margin: 0,
                        padding: '2px 12px',
                    },
                },
                '> :last-child': {
                    '.legend-label': {
                        marginBottom: 0,
                    },
                },
            },
        },
    };
}
