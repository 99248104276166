import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import PortfolioTotalHeader from '../../../components/app/PortfolioTotalHeader/index';
import Snackbar from '../../../components/core/Snackbar/index';
import {getTotals, snackbarDataList, snackbarErrorList, getBaseCcy} from '../../../selectors/pages/portfolio';
import {labelSelector} from '../../../selectors/app';
import Constants from '../../../constants/appConstants';

const PortfolioHeader = ({snackbarData, snackbarError, labels, totals, baseCurrency}) => (
  <div className="portfolio__header">
    <TransitionGroup component={null}>
      {[...snackbarError, ...snackbarData].map((data, idx) => (
        <CSSTransition key={idx} classNames="snackbar__transition" timeout={Constants.CSS_DURATION_HORSE}>
          <Snackbar labels={labels} {...data} />
        </CSSTransition>
      ))}
    </TransitionGroup>
    <PortfolioTotalHeader
      labels={labels}
      totals={totals}
      baseCurrency={baseCurrency}
    />
  </div>);

PortfolioHeader.propTypes = {
  snackbarData: PropTypes.array,
  snackbarError: PropTypes.array,
  labels: PropTypes.object,
  totals: PropTypes.object,
  baseCurrency: PropTypes.string
};

const mapStateToProps = (state) => (
  {
    labels: labelSelector(state),
    totals: getTotals(state),
    snackbarData: snackbarDataList(state),
    snackbarError: snackbarErrorList(state),
    baseCurrency: getBaseCcy(state)
  });

export default connect(mapStateToProps)(PortfolioHeader);
