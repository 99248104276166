import {SessionStorage} from './storage';
import AppConstants from '../constants/appConstants';

export const getLoadOfPageLoginData = () => ({
  page: {
    pageInfo: {
      section: 'Log in',
      subSection: 'Enter Details',
      pageName: 'Log In:Enter Details'
    }
  },
  user: {
    status: 'Logged Out'
  }
});

export const getLoadOfCreatePasswordData = () => ({
  page: {
    pageInfo: {
      section: 'Log in',
      subSection: 'Log In:Create Password',
      pageName: 'Create Password'
    }
  },
  user: {
    status: 'Logged Out'
  }
});

export const getLoadOfEnterOTP = () => ({
  page: {
    pageInfo: {
      section: 'Log in',
      subSection: 'Log In:Enter OTP',
      pageName: 'Enter OTP'
    }
  },
  user: {
    status: 'Logged Out'
  }
});

export const getLoadOfEnterUID = () => ({
  page: {
    pageInfo: {
      section: 'Log in',
      subSection: 'Log In:Enter UID',
      pageName: 'Enter UID'
    }
  },
  user: {
    status: 'Logged Out'
  }
});

export const getLoadOfCreatePinData = () => ({
  page: {
    pageInfo: {
      section: 'Log in',
      subSection: 'Log In:Create Pin',
      pageName: 'Create Pin'
    }
  },
  user: {
    status: 'Logged Out'
  }
});

export const getLoadOfTNCData = () => ({
  page: {
    pageInfo: {
      section: 'Log in',
      subSection: 'Log In:Terms and Conditions',
      pageName: 'Terms and Conditions'
    }
  },
  user: {
    status: 'Logged Out'
  }
});

export const setLoginMethodIdentifier = (type) => {
  const loginMethod = (type === 1? 'Password' : 'SSO');
  SessionStorage.setItem(AppConstants.MOSAIC_LOGIN_METHOD, loginMethod);
};
