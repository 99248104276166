import { BadgeProps, DefaultBadgeProps, defaultBadgeProps } from './badge-props';
import { CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { BadgeOverlayPlacement } from './badge-overlay-placement';
import { BadgeShape } from './badge-shape';

/**
 * Common Badge props shared between Angular and React
 */
export interface BadgeOverlayProps<StyleType = CommonStyleType> extends BadgeProps<StyleType> {
    /**
     * Style classes to override.
     */
    classes?: BadgeOverlayOverrideClasses;

    /**
     * The placement of the badge when the Badge is overlayed onto another component
     */
    placement?: BadgeOverlayPlacement;

    /**
     * Shape of the component.  Default is 'rounded'.
     */
    shape?: BadgeShape;
    // 'shape' is inherited from BadgeProps, but added here to document that the
    // default is different for BadgeOverlay.
}

export type BadgeOverlayOverrideClasses = Partial<Record<'root' | 'badge', string>>;

export type DefaultBadgeOverlayProps = Required<
    Pick<BadgeOverlayProps, 'placement'> & DefaultBadgeProps
>;

export const defaultBadgeOverlayProps: DefaultBadgeOverlayProps = {
    placement: 'top-right',
    ...defaultBadgeProps,
    shape: 'rounded',
    size: 'sm',
};
