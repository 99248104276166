import { connect } from 'react-redux';
import Login from '../../components/app/Login';
import {
  labelSelector, isAppLoadingSelector, financialConditionsLinkSelector, helpContentsSelector
} from '../../selectors/app';
import {
  localStorageUserNameSelector, nextStepSelector, pingConfigSelector,
  isLoginInProgressSelector, loginServiceErrorSelector, isShowMosaicHelpSelector, mfaConfigSelector,
  canPerformTMXProfilingSelector, isTmxProfilingCompleteSelector, isInternalUserMFASelector
} from '../../selectors/pages/login';
import { submitCredentials, isShowMosaicHelp } from '../../actions/page/login';
import {userIdSelector} from '../../selectors/user';
import {addAppContext, onTmxProfilingComplete} from "../../actions/app";

const mapStateToProps = (state) => ({
  labels: labelSelector(state),
  nextStep: nextStepSelector(state),
  pingConfig: pingConfigSelector(state),
  userName: localStorageUserNameSelector(),
  loginServiceError: loginServiceErrorSelector(state),
  isShowMosaicHelpFlag: isShowMosaicHelpSelector(state),
  isLoginInProgress: isLoginInProgressSelector(state),
  isAppLoading: isAppLoadingSelector(state),
  userId:userIdSelector(state),
  helpContents: helpContentsSelector(state),
  financialConditionsLink: financialConditionsLinkSelector(state),
  isTmxProfilingComplete: isTmxProfilingCompleteSelector(state),
  canPerformTMXProfiling: canPerformTMXProfilingSelector(state),
  isInternalUserMFA: isInternalUserMFASelector(state),
  mfaConfig: mfaConfigSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  submitCredentials: (data) => dispatch(submitCredentials(data)),
  isShowMosaicHelp: flag => dispatch(isShowMosaicHelp(flag)),
  onTmxProfilingComplete: flag => dispatch(onTmxProfilingComplete(flag)),
  addAppContext: data => dispatch(addAppContext(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
