export interface UiEventParams {
    readonly nativeEvent: Event;
    readonly type?: string;
}

export class UiEvent {
    readonly nativeEvent: Event;
    readonly type: string;
    readonly target: any = null;

    // Aliases for nativeEvent properties for convenience and compatibility:
    readonly bubbles: boolean;
    readonly cancelable: boolean;
    readonly isTrusted: boolean;
    readonly timeStamp: number;

    get defaultPrevented() {
        return this.nativeEvent.defaultPrevented;
    }

    get eventPhase() {
        return this.nativeEvent.eventPhase;
    }

    get currentTarget() {
        return this.nativeEvent.currentTarget;
    }

    constructor({ nativeEvent, type }: UiEventParams) {
        this.nativeEvent = nativeEvent;
        this.type = type || this.nativeEvent.type;
        this.bubbles = this.nativeEvent.bubbles;
        this.cancelable = this.nativeEvent.cancelable;
        this.isTrusted = this.nativeEvent.isTrusted;
        this.timeStamp = this.nativeEvent.timeStamp;
    }

    preventDefault() {
        this.nativeEvent.preventDefault();
    }

    stopImmediatePropagation() {
        this.nativeEvent.stopImmediatePropagation();
    }

    stopPropagation() {
        this.nativeEvent.stopPropagation();
    }
}
