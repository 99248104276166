import { PluginBase, Registry } from '@gs-ux-uitoolkit-common/datacore';
import { GridWrapper } from '../grid-wrappers/grid-wrapper';
import { DataGridState } from '../redux/datagrid-state';

type PluginConstructor = new (gridWrapper: GridWrapper) => PluginBase<GridWrapper, DataGridState>;

/**
 * The global plugin registry
 */
export const pluginRegistry = new Registry<PluginConstructor>();
