
import React from 'react';
import PropTypes from 'prop-types';
import Row from '../Row';

const SimpleList = ({ items, props }) => {
  return (
    items && items.length > 0 && items.map((item, index) => {
        return item.hide ? null : <Row {...item} key={index} {...props} />;
      }
    )
  );
};

SimpleList.propTypes = {
  items:PropTypes.array.isRequired,
  props: PropTypes.object
};

export default SimpleList;
