import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { Dropdown, DropdownButton, DropdownMenu } from '@gs-ux-uitoolkit-react/input';
import { MenuOption } from '@gs-ux-uitoolkit-react/menu';
import { ButtonDisplay } from './button-base';
import { ButtonDelete } from './button-delete';
import { ButtonEdit } from './button-edit';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { buttonGroupStyleSheet } from '../../style/component/button-group-stylesheet';
/**
 * The props of the ButtonGroup
 */
export interface ButtonGroupProps {
    /**
     * Hide the Edit button
     */
    hideEdit?: boolean;
    /**
     * Hide the delete button
     */
    hideDelete?: boolean;
    /**
     * Disable the Edit button
     */
    disableEdit?: boolean;
    /**
     * Disable the Delete button
     */
    disableDelete?: boolean;
    /**
     * EditClick function
     */
    editClick?: () => void;
    /**
     * Delete click function
     */
    deleteClick?: () => void;
}

/**
 * The button group used in modals
 * @param props The props of the ButtonGroup
 */
export const ButtonGroup = (props: ButtonGroupProps) => {
    const cssClasses = useStyleSheet(buttonGroupStyleSheet, null);

    return (
        <div className={cssClasses.root}>
            <div className="gs-uitk-button-group">
                {!props.hideEdit && (
                    <ButtonEdit
                        displayType={ButtonDisplay.Icon}
                        emphasis={'minimal'}
                        onClick={props.editClick}
                        disabled={props.disableEdit}
                    />
                )}
                {!props.hideDelete && (
                    <ButtonDelete
                        displayType={ButtonDisplay.Icon}
                        emphasis={'minimal'}
                        onClick={props.deleteClick}
                        disabled={props.disableDelete}
                    />
                )}
            </div>
            {!props.hideDelete || !props.hideEdit ? (
                <div className="gs-uitk-responsive-button-dropdown">
                    <Dropdown style={{ marginRight: '8px' }}>
                        <DropdownButton hideToggleIcon emphasis="minimal">
                            <Icon name="more-vert" type="filled" />
                        </DropdownButton>
                        <DropdownMenu data-cy="gs-uitk-plugin-management-drowdown-menu">
                            {!props.hideEdit && (
                                <MenuOption
                                    onClick={props.editClick}
                                    disabled={props.disableEdit}
                                    data-cy="gs-uitk-plugin-management-drowdown-menu-edit"
                                >
                                    <Icon name={'edit'} type={'outlined'} />
                                    Edit
                                </MenuOption>
                            )}
                            {!props.hideDelete && (
                                <MenuOption
                                    onClick={props.deleteClick}
                                    disabled={props.disableDelete}
                                    data-cy="gs-uitk-plugin-management-drowdown-menu-delete"
                                >
                                    <Icon name={'delete'} type={'outlined'} />
                                    Delete
                                </MenuOption>
                            )}
                        </DropdownMenu>
                    </Dropdown>
                </div>
            ) : null}
        </div>
    );
};
