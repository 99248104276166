import { ComponentProps, CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { Dismissible, Placement, Size } from '@gs-ux-uitoolkit-common/design-system';
import { lightTheme, Theme } from '@gs-ux-uitoolkit-common/theme';
import { ModalBodyCssClasses } from './modal-body-style-sheet';
import { ModalFooterCssClasses } from './modal-footer-style-sheet';
import { ModalHeaderCssClasses } from './modal-header-style-sheet';
import { ModalCssClasses } from './modal-style-sheet';

export interface ModalProps<StyleType = CommonStyleType> extends ComponentProps<StyleType> {
    /**
     * `true` or `false` to include a backdrop element, or `static` to keep it from closing when it is clicked.
     */
    backdrop?: boolean | 'static';

    /**
     * Style classes to override.
     */
    classes?: Partial<ModalCssClasses>;

    /**
     * Hides the modal when the Escape key is pressed.
     */
    hideOnEscape?: boolean;

    /**
     * How to position the modal
     */
    placement?: ModalPlacement;

    /**
     * Allows the modal content to be scrolled.
     */
    scrollable?: boolean;

    /**
     * The size of the modal.
     */
    size?: ModalSize;

    /**
     * Called when the modal's visibility changes (it is shown/hidden).
     */
    onVisibilityToggle?: (args?: ModalVisibilityEvent) => void;
}

export interface ModalHeaderProps<StyleType = CommonStyleType> extends ComponentProps<StyleType> {
    /**
     * Adds a dismiss button that hides the modal when clicked.
     */
    dismissible?: Dismissible;

    /**
     * Style classes to override.
     */
    classes?: Partial<ModalHeaderCssClasses>;

    /**
     * The size of the modal
     */
    size?: ModalSize;

    /**
     * Called when the dismiss button is clicked.
     */
    onDismissButtonClick?: () => void;
}

export interface ModalBodyProps<StyleType = CommonStyleType> extends ComponentProps<StyleType> {
    /**
     * Style classes to override.
     */
    classes?: Partial<ModalBodyCssClasses>;

    /**
     * The size of the modal
     */
    size?: ModalSize;
}

export interface ModalFooterProps<StyleType = CommonStyleType> extends ComponentProps<StyleType> {
    /**
     * Style classes to override.
     */
    classes?: Partial<ModalFooterCssClasses>;

    /**
     * The size of the modal
     */
    size?: ModalSize;
}

export type ModalDismissReason = 'esc' | 'backdropClick';
export type ModalVisibilityEvent = {
    reason: ModalDismissReason;
    target: EventTarget | null;
};
export type ModalSize = Extract<Size, 'sm' | 'md' | 'lg'>;
export type ModalPlacement = Extract<Placement, 'top' | 'center'>;

const defaultSize: ModalSize = 'md';

export type ModalDefaultProps = Required<
    Pick<ModalProps, 'backdrop' | 'hideOnEscape' | 'size' | 'placement' | 'scrollable'> & {
        theme: Theme;
    }
>;
export const modalDefaultProps: ModalDefaultProps = {
    backdrop: true,
    hideOnEscape: true,
    placement: 'top',
    scrollable: false,
    size: defaultSize,
    theme: lightTheme,
};

export type ModalHeaderDefaultProps = Required<Pick<ModalHeaderProps, 'dismissible' | 'size'>>;
export const modalHeaderDefaultProps: ModalHeaderDefaultProps = {
    dismissible: true,
    size: defaultSize,
};

export type ModalBodyDefaultProps = Required<Pick<ModalBodyProps, 'size'>>;
export const modalBodyDefaultProps: ModalBodyDefaultProps = {
    size: defaultSize,
};

export type ModalFooterDefaultProps = Required<Pick<ModalFooterProps, 'size'>>;
export const modalFooterDefaultProps: ModalFooterDefaultProps = {
    size: defaultSize,
};
