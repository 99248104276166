import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Tooltip from 'react-tooltip-lite';
import Notification from '../Notification';
import {currencyFormatter, amountFormatter, amountWithSymbol } from '../../../../utils/amountFormatter';
import {formatDate, convertDateToUserTZWithOn} from '../../../../utils/dateFormatter';
import Button from '../../../core/Button';
import Repeater from '../../../core/Repeater';
import './index.scss';
import translator  from '../../../../services/translator';
import { getAnalyticsAttribute } from '../analytics';
import {Conditional} from '../../../core/Conditional';
import {AMOUNT_TYPE, TRADE_TYPE} from '../../../../constants/pageConstants';
import {convert2PascalCase} from  '../../../../utils/commonUtils';
import {translateTradeMessage} from  '../../../../utils/tradeApprovals';

const {translate: t} = translator;

const TradeItem = ({txnData, userTimeZone, onApprove, onReject, onAnimationComplete, homeAway, isMimicSession}) => {
  const row = useRef();

  const {accountIdentifier = '--', settlementDate, tradeType = '--', fundName = '--', id, isPassedCutOff, amountType,
    fundCutOffTime = '--', fundIdentifier = '--', nav, navStrikeTime, nextNavStrikeTime, tradeAmount, settlementInstruction = '--',
    trader, availableShares, availableBalance, mtdAccrual, currencyCode, approvalCutoff} = txnData;

  const isAmountTypeCurrency = amountType === AMOUNT_TYPE.CURRENCY;
  const settlementDateTxt = settlementDate ? formatDate(settlementDate, 'YYYY-MM-DD', 'DD.MMM.YYYY') : '--';
  const amountTypeTxt = (isAmountTypeCurrency) ? currencyCode : convert2PascalCase(amountType);
  const navAsOfDateTxt = `${t('tkNAV')}: ${nav} (${t('tkasof')} ${ navStrikeTime})`;// convertDateToUserTZ(navStrikeTime, userTimeZone)
  const decimalPoint  = isAmountTypeCurrency ? 2 : txnData.taId === 2 ? 4 : 3;
  const tradeAmountTxt = amountWithSymbol(amountFormatter(tradeAmount, decimalPoint), isAmountTypeCurrency ? currencyCode : '') || '--';
  const settInstruction = settlementInstruction || '--';
  const [isDisabled, setIsDisabled] = useState(false);

  const removeRow = (event) => {

    if(event.target) {
      const row = event.target;
      const {id} = row.dataset;
      const parent = row.parentElement;
      const headerToHide = parent.querySelector('[data-remove=true]');
      headerToHide && headerToHide.classList.add('hide');
      row.classList.add('hide');
      onAnimationComplete(id);
    }

  };

  useEffect(() => {
    row.current.addEventListener('webkitAnimationEnd', removeRow);
    row.current.addEventListener('animationend', removeRow);
    row.current.addEventListener('MSAnimationEnd', removeRow);
    return () => {
      row.current && row.current.removeEventListener('webkitAnimationEnd', removeRow);
      row.current && row.current.removeEventListener('animationend', removeRow);
      row.current && row.current.removeEventListener('MSAnimationEnd', removeRow);
    };
  }, []);

  const handleApprovalClick = () => {
    onApprove(txnData);
  };

  const handleRejectionClick = () => {
    onReject(txnData);
  };
  const getTitle = (approver) => {
    return  approver && `${t('tkApprovedBy')} ${approver.firstName.charAt(0).toLocaleUpperCase()}.${convert2PascalCase(approver.lastName)} ${t('tkat')} ${convertDateToUserTZWithOn(approver.approvedAt, userTimeZone, t('tkon'))}`;
  };

  const getClassNames = (approver) => `${cn({'dots__pastCutOff': approver && approver.approverDetails && isPassedCutOff },
    {'dots__approved': approver && approver.approverDetails && !isPassedCutOff },
    {'dots__pending':  !approver.approverDetails})}`;

  const {responseError = {}, disabled, approvalResponseWarning = {}, animationClass, isNewAccount, error = {}, warnings=[]} = txnData;
  const notifications = [];

  if(isNewAccount){
    notifications.push( {type: 'information', message: `${t('tkNewAccountInfo')}`} );
  }
  if(error.code){
    notifications.push( {type: 'error', message: translateTradeMessage(error.code, error.message, txnData)} );
  }

  if(responseError.code && disabled){
    notifications.push( {type: 'error', message: translateTradeMessage(responseError.code, responseError.message, txnData)} );
  }
  if(approvalResponseWarning.code){
    notifications.push( {type: 'warning', message: translateTradeMessage(approvalResponseWarning.code,
      approvalResponseWarning.message, txnData)} );
  }

  warnings && warnings.forEach((warning) => {
    const msg = translateTradeMessage(warning.code, warning.message, txnData);
    const found = notifications.find(obj => obj.message === msg);
    if(!found){
      notifications.push( {type: 'warning', message: msg} );
    }
  });


  useEffect(() => {
    if(disabled) {
      setIsDisabled(true);
    }
  }, [responseError]);

  const hasWarning = !!approvalResponseWarning.message;
  const showMTDAccrual = (tradeType === TRADE_TYPE.REDEMPTION) && (amountType === AMOUNT_TYPE.ALL);
  const traderFullName = trader && `${trader.firstName.charAt(0).toLocaleUpperCase()}.${convert2PascalCase(trader.lastName)}` || '--';
  const availableSharesDecimalPoint = txnData.taId === 2 ? 4 : 3;
  const availableSharesTxt = amountFormatter(availableShares, availableSharesDecimalPoint, false) || '--';
  const availableBalanceTxt = amountWithSymbol(currencyFormatter(availableBalance), currencyCode);
  const mtdAccrualTxt = showMTDAccrual ? (amountWithSymbol(currencyFormatter(mtdAccrual), currencyCode) || '--') : '';

  const disableCTA = isDisabled || isMimicSession;

  const fundNameWithDateSection = (
    <div className="approval-trade__shareclass">
      <div className="approval-trade__shareclass-identifier approval-trade__topPrefix">
        <div>{fundName}</div>
        <div>{fundIdentifier}</div>
      </div>
      <div className="approval-trade__shareclass-performance approval-trade__bottomPrefix">
        <div>
          <span>{navAsOfDateTxt}</span>
          <Conditional condition={nextNavStrikeTime}>
            <span>{`${t('tkNextNAVTime')}: ${nextNavStrikeTime || '--'}`}</span>
          </Conditional>
        </div>
      </div>
    </div>
  );

  return (
    <div ref={row} data-id={id} className={`approval-trade ${animationClass || ''}`}>
      <div className="trade-sm">
        <div className="approval-trade__content">
          <div className={cn(
            'approval-trade__status',
            {'approval-trade__status--pastCutOff': isPassedCutOff})
          }>
            <div className="approval-trade__status-message approval-trade__topPrefix">
              <span>{t('tkPendingApproval')}</span>
            </div>
            <div className="approval-trade__status-cutoff-time approval-trade__topPrefix">
              <Conditional condition={approvalCutoff}>
                <div>
                  <span className="approval-trade__status-cutoff-label">{t('tkTradeApprovalsCutoff')}</span>
                  <span className="approval-trade__status-cutoff-value">{approvalCutoff || '--'}</span>
                </div>
                <div>
                  <span>{t('tkCutOffTime')}: </span>
                  <span className="approval-trade__status-cutoff-value">{fundCutOffTime}</span>
                </div>
              </Conditional>
            </div>
          </div>
          {fundNameWithDateSection}
          <div className="approval-trade__transaction">
            <div
              className={`approval-trade__transaction-labels approval-trade__topPrefix ${!homeAway ? 'no-homeAway' : ''} `}>
              <div>{t('tkAccountText')}</div>
              <div>{accountIdentifier} </div>
              <div>{t('tkSettlementDate')} </div>
              <div>{settlementDateTxt} </div>
              <div>{t('tkTradeType')} </div>
              <div>{convert2PascalCase(tradeType)} </div>
              <div>{t('tkAmountType')} </div>
              <div>{amountTypeTxt} </div>
              <div>{t('tkTradeAmount')} </div>
              <div>{tradeAmountTxt} </div>
              <div>{t('tkSettleInst')} </div>
              <div>{settInstruction}</div>
              <Conditional condition={homeAway}>
                <div>{t('tkHomeAway') } {convert2PascalCase(txnData.homeAway)} </div>
              </Conditional>
              <Conditional condition={homeAway}>
                <div>{convert2PascalCase(txnData.homeAway)} </div>
              </Conditional>
            </div>
          </div>
          <div className="approval-trade__positions">
            <div className="approval-trade__positions-labels approval-trade__topPrefix">
              <div>{t('tkTrader')}</div><div>{traderFullName}</div>
              <div>{t('tkShareBalance')}</div><div>{availableSharesTxt}</div>
              <div>{t('tkAvailableBalance')}</div>  <div>{availableBalanceTxt}</div>
              <div>{showMTDAccrual ? t('tkMTDAccrual') : ''}</div>  <div>{mtdAccrualTxt}</div>
            </div>
          </div>
        </div>
        <div className="approval-trade__line" />
      </div>
      <div className="trade-lg">
        <div className="approval-trade__content">
          {fundNameWithDateSection}
          <div className="approval-trade__txn-position-outline">
            <div className={`approval-trade__transaction approval-trade__topPrefix ${!homeAway ? 'no-homeAway' : ''}`}>
              <div>{accountIdentifier} </div>
              <div>{settlementDateTxt} </div>
              <div>{convert2PascalCase(tradeType)} </div>
              <div>{amountTypeTxt} </div>
              <div className="approval-trade__transaction-ralign">{tradeAmountTxt} </div>
              <div className="approval-trade__transaction-lmargin">{settInstruction} </div>

              <Conditional condition={homeAway}>
                <div className="approval-trade__transaction-lmargin">{convert2PascalCase(txnData.homeAway) || '--'} </div>
              </Conditional>
              {homeAway}
            </div>
            <div className="approval-trade__positions approval-trade__bottomPrefix">
              <div>
                <span>{`${t('tkTrader')}: ${ traderFullName }`}</span>
                <span>{`${t('tkShareBalance')}: ${availableSharesTxt}`}</span>
                <span>{`${t('tkAvailableBalance')}: ${availableBalanceTxt}`}</span>
                <span>{showMTDAccrual ? (`${t('tkMTDAccrual')}: ${mtdAccrualTxt}`) : '' }</span>
              </div>
            </div>
          </div>
          <div className={cn('approval-trade__status',
            {'approval-trade__status--pastCutOff': isPassedCutOff})}>
            <div className="approval-trade__status-message approval-trade__topPrefix">
              <div>{t('tkPendingApproval')}</div>
            </div>
            <div className="approval-trade__status-approvers">
              {
                txnData.allApprovers.length > 1 && txnData.allApprovers.map((approver, index) => (
                  <Conditional condition={approver.approverDetails}>
                    <Tooltip direction="down" content={getTitle(approver.approverDetails)}>
                      <div
                        key={index}
                        className={getClassNames(approver)}
                    />
                    </Tooltip>
                    <div
                      key={index}
                      className={getClassNames(approver)}
                   />
                  </Conditional>
                ))
              }
            </div>
            <div className="approval-trade__status-cutoff-time approval-trade__bottomPrefix">
              <Conditional condition={approvalCutoff}>
                <div>
                  <span className="approval-trade__status-cutoff-label">{t('tkTradeApprovalsCutoff')}</span>
                  <span className="approval-trade__status-cutoff-value">{approvalCutoff || '--'}</span>
                </div>
                <div>
                  <span>{t('tkCutOffTime')}: </span>
                  <span>{fundCutOffTime}</span>
                </div>
              </Conditional>
            </div>
          </div>
        </div>
      </div>
      <Repeater items={notifications}>
        <Notification />
      </Repeater>
      <div className="approval-trade__btn-container">
        <div>
          <Button
            label={hasWarning ? t('tkAcknowledgeAndApprove') :t('tkApproveTrades')}
            extraClass={'approve' }
            isDisabled={disableCTA}
            customClass={`button-primary-small${disableCTA ? '-disabled' : ''}`}
            extraProps={hasWarning ? getAnalyticsAttribute('acknowledge') : getAnalyticsAttribute('approve')}
            clickHandler={handleApprovalClick}
          />
        </div>
        <div>
          <Button
            label={t('tkReject')}
            isDisabled={disableCTA}
            customClass={`button-primary-small${disableCTA ? '-disabled' : ''}`}
            extraClass={!disableCTA && 'reject'}
            extraProps={getAnalyticsAttribute('reject')}
            clickHandler={handleRejectionClick}
          />
        </div>
      </div>
    </div>
  );
};

TradeItem.propTypes = {
  txnData: PropTypes.object,
  onApprove: PropTypes.func,
  onReject: PropTypes.func,
  onAnimationComplete: PropTypes.func,
  userTimeZone: PropTypes.string,
  homeAway: PropTypes.bool,
  isMimicSession: PropTypes.bool
};

export default TradeItem;
