import { CommonStyleType, ComponentProps } from '@gs-ux-uitoolkit-common/component';
import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './overlay-container-theme-overrides';

export interface OverlayContainerProps<StyleType = CommonStyleType>
    extends ComponentProps<StyleType> {}

export type OverlayContainerStyleSheetProps = {
    theme: Theme;
};

export interface OverlayContainerCssClasses {
    root: string;
}

export type OverlayContainerStyledClasses = CssClassDefinitionsObject<
    keyof OverlayContainerCssClasses
>;

export interface OverlayContainerStyleOverridesParams {
    props: DeepReadonly<OverlayContainerStyleSheetProps>;
    createDefaultStyledClasses: () => OverlayContainerStyledClasses;
}

export const overlayContainerStyleSheet = new StyleSheet(
    'overlayContainer',
    (props: OverlayContainerStyleSheetProps) => {
        return createComponentClassDefinitions<
            OverlayContainerStyleSheetProps,
            OverlayContainerStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.overlayContainer);
    }
);

function createDefaultStyledClasses(
    _props: OverlayContainerStyleSheetProps
): OverlayContainerStyledClasses {
    return {
        root: {
            display: 'inline-block',
            position: 'relative',
        },
    };
}
