/**
 * Trie which allows us to map an object's properties to a value. Almost works like
 * `Map<object, value>`, except the exact object reference is not used as the key - the
 * object's shallow set of properties are.
 *
 * This Trie uses key/value pairs as the links in the Trie so that lookups can be done in
 * time proportional to the number of properties in the object.
 *
 * Example usage:
 *
 *     const propsTrie = new PropsTrie<string>();
 *
 *     propsTrie.set({ a: 1, b: 2 }, 'str1');
 *     propsTrie.get({ a: 1, b: 2 });  // 'str1' - note how the same object as provided to set() is not needed, the key/value combination of the props are the "links" in the trie
 *
 *     propsTrie.set({ a: 1, b: 3 }, 'str2');
 *     propsTrie.get({ a: 1, b: 3 });  // 'str2' - node 'a' is shared and holds children `b: 2` and `b: 3`
 *
 * Trie structure for above example:
 *
 *                     |--------------------|
 *                     |       Root         |
 *                     |  value: undefined  |
 *                     |--------------------|
 *                            /
 *                   {a: 1}  /
 *                          /
 *              |--------------------|
 *              |  value: undefined  |
 *              |--------------------|
 *                    /        \
 *           {b: 2}  /          \   {b: 3}
 *                  /            \
 *      |----------------|     |----------------|
 *      | value: 'str1'  |     |  value: 'str2' |
 *      |----------------|     |----------------|
 *
 *
 * This implementation relies on a constant iteration order of the properties in the object.
 * Even though the iteration order of JavaScript objects is not guaranteed, most JS
 * engines iterate the keys in property insertion order. For our usage in StyleSheet, this
 * allows us to not need to sort the keys first because objects passed into `StyleSheet.mount()`
 * are generally constructed in the same property insertion order from inside UI components.
 *
 * To generalize this implementation outside of StyleSheet, we could sort the keys in alphabetical
 * order first.
 */
export class PropsTrie<Value> {
    private root = new PropsTrieNode<Value>();

    /**
     * Retrieves the value associated with the properties of the given `props`
     * object.
     *
     *     propsTrie.set({ a: 1, b: 2 }, 'str1');
     *     propsTrie.get({ a: 1, b: 2 });  // 'str1' - note how the same object as provided to set() is not needed, the key/value combination of the props are the "links" in the trie
     */
    public get(props: object | null): Value | undefined {
        let node: PropsTrieNode<Value> | undefined = this.root;

        if (props === null) {
            return node.value;
        } else {
            for (const key of Object.keys(props as object)) {
                const propValue = (props as any)[key];

                node = node.get(key, propValue);
                if (!node) {
                    return undefined;
                }
            }
            return node.value;
        }
    }

    /**
     * Set a value to be associated with the properties of the given `props`
     * object.
     *
     *     propsTrie.set({ a: 1, b: 2 }, 'str1');
     *     propsTrie.get({ a: 1, b: 2 });  // 'str1' - note how the same object as provided to set() is not needed, the key/value combination of the props are the "links" in the trie
     */
    public set(props: object | null, value: Value) {
        let node: PropsTrieNode<Value> = this.root;

        if (props === null) {
            node.value = value;
        } else {
            for (const key of Object.keys(props as object)) {
                const propValue = (props as any)[key];
                let childNode = node.get(key, propValue);

                // Fill in missing nodes
                if (!childNode) {
                    childNode = new PropsTrieNode<Value>();
                    node.set(key, propValue, childNode);
                }

                node = childNode;
            }
            node.value = value;
        }
    }

    /**
     * Deletes a value from being associated with the properties of the given
     * `props` object.
     *
     *     propsTrie.set({ a: 1, b: 2 }, 'str1');
     *     propsTrie.get({ a: 1, b: 2 });  // 'str1'
     *
     *     propsTrie.delete({ a: 1, b: 2 });  // note how the same object as provided to set() is not needed, the key/value combination of the props are the "links" in the trie
     *     propsTrie.get({ a: 1, b: 2 });  // `undefined`
     */
    public delete(props: object | null) {
        let node: PropsTrieNode<Value> = this.root;

        if (props === null) {
            node.value = undefined;
        } else {
            const nodeStack: {
                key: string | undefined;
                propValue: any;
                node: PropsTrieNode<Value>;
            }[] = [{ key: undefined, propValue: undefined, node }];

            // Walk down the tree to the value node, collecting the key/value
            // pairs that form the path to it
            for (const key of Object.keys(props as object)) {
                const propValue = (props as any)[key];
                const childNode = node.get(key, propValue);

                if (!childNode) {
                    return; // the `props` object doesn't exist in the trie - nothing to do
                }
                nodeStack.push({ key, propValue, node: childNode });
                node = childNode;
            }

            // Delete the value
            node.value = undefined;

            // Now walk back up the stack, and delete the nodes on each iteration that no longer
            // hold a value and don't have any child nodes of their own
            for (let i = nodeStack.length - 1; i > 0; i--) {
                const parent = nodeStack[i - 1].node;
                const current = nodeStack[i];
                const currentNode = current.node;

                if (currentNode.value === undefined && currentNode.size === 0) {
                    parent.delete(current.key!, current.propValue);
                }
            }
        }
    }

    /**
     * Clears all entries from the PropsTrie
     */
    public clear() {
        this.root = new PropsTrieNode<Value>();
    }
}

/**
 * Private node class for the {@link PropsTrie}.
 *
 * PropsTrieNodes have children which are linked to the node via the key/propValue
 * of an object key:
 *
 *              |--------------------|
 *              |        Root        |
 *              |  value: undefined  |
 *              |--------------------|
 *                    /        \
 *           {a: 1}  /          \   {b: 2}
 *                  /            \
 *    |-----------------|     |----------------|
 *    |  value: 'str1'  |     |  value: 'str2' |
 *    |-----------------|     |----------------|
 *
 * It accomplishes this using nested maps: the first for the property key ('a' or 'b' in the
 * above example), and second for the property value (`1` or `2` in the above example)
 */
class PropsTrieNode<Value> {
    private readonly children = new Map<string, Map<any, PropsTrieNode<Value>>>();
    public value: Value | undefined = undefined;

    public get size() {
        return this.children.size;
    }

    /**
     * Retrieves a child PropsTrieNode given the key/propValue pair that forms
     * the edge to the node.
     */
    public get(key: string, propValue: any): PropsTrieNode<Value> | undefined {
        const propertyValueMap = this.children.get(key);

        return propertyValueMap && propertyValueMap.get(propValue);
    }

    /**
     * Sets a child PropsTrieNode given the key/propValue pair that should form
     * the edge to the new node.
     */
    public set(key: string, propValue: any, node: PropsTrieNode<Value>) {
        let propertyValueMap = this.children.get(key);
        if (!propertyValueMap) {
            propertyValueMap = new Map<any, PropsTrieNode<Value>>();
            this.children.set(key, propertyValueMap);
        }

        propertyValueMap.set(propValue, node);
    }

    /**
     * Deletes a child PropsTrieNode given the key/propValue pair that forms
     * the edge to the node.
     */
    public delete(key: string, propValue: any) {
        const propertyValueMap = this.children.get(key);

        if (propertyValueMap) {
            propertyValueMap.delete(propValue);

            if (propertyValueMap.size === 0) {
                this.children.delete(key);
            }
        }
    }
}
