import { AutofitPivotActionKeys } from '../actions/action-constants';
import { ToggleEnablePluginAction } from '../actions/autofit-pivot-action';
import { AutofitPivotState } from '../datagrid-state';

/**
 * Initial state of the AutofitPivotState
 */
export const defaultAutofitPivotState: AutofitPivotState = {
    enabled: true,
    pivotMax: null,
    pivotMin: null,
    resizeOnExpandOnly: false,
};

/**
 * Reducer for the AutofitPivotState
 * @param state State of the AutofitPivotState
 * @param action Action received
 */
export const autofitPivotReducer = (
    state: AutofitPivotState = defaultAutofitPivotState,
    action: ToggleEnablePluginAction
): AutofitPivotState => {
    switch (action.type) {
        case AutofitPivotActionKeys.TOGGLE_ENABLE_PLUGIN:
            return { ...state, enabled: !state.enabled };
        default:
            return state;
    }
};
