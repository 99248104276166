import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./index.scss";

const Tooltip = ({ delay, children, direction, content }) => {
  let timeout;
  const containerRef = useRef(null);
  const [active, setActive] = useState(false);
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.clientHeight);
    }
  });

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="Tooltip-Wrapper"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      data-testid='tooltip-container'
    >
      {children}
      {active && (
        <div
          data-testid='tooltip-content'
          ref={containerRef}
          style={direction === "top" && { top: `-${containerHeight + 10}px` }}
          className={`Tooltip-Tip ${direction || "top"}`}
        >
          {content}
        </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  delay: PropTypes.number,
  direction: PropTypes.string,
  content: PropTypes.string,
  children: PropTypes.node,
};

export default Tooltip;
