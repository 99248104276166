import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ThreeSquaresLoader } from '../Loaders';
import './index.scss';

const IFrame = ({src, title, className, iFrameCustomHeight}) => {
  const [isLoading, setIsLoading] = useState(true);
  const iframeRef = useRef();

  const handleLoad = () => {
    setIsLoading(false);
    if(iframeRef.current && iframeRef.current.contentDocument){
      const style = document.createElement('style');
      style.textContent = '.global-header, .global-footer {display: none;} body, .viewport-container, viewport-container__view {height: initial} .global-server-error{width: initial}';
      iframeRef.current.contentDocument.head.appendChild(style);
    }
  };

  return(
    <div className='iframe' style={{'height': iFrameCustomHeight}}>
      <iframe
        ref={iframeRef}
        title={title}
        src={src}
        className={cn('iframe__content', {'iframe--hidden': isLoading}, className)}
        onLoad={handleLoad}
      />
      <div className="iframe__loading" hidden={!isLoading}>
        <ThreeSquaresLoader />
      </div>
    </div>
  );
};

IFrame.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  iFrameCustomHeight: PropTypes.number
};

export default IFrame;
