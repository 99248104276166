import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/core/Modal';
import Button from '../../../../components/core/Button';
import translator from '../../../../services/translator';
import {toggleBrowserScrollVisiblilty} from '../../../../utils/dom';
import './index.scss';

const {translate: t} = translator;

const deleteReportConfirmModalStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  height: 'auto',
  transform: 'translate(-50%, -50%)'
};

export const DeleteReportConfirmModal = ({reportName, isModalOpen, handleClose, handleDeleteReport}) => {
  const deleteReportConfirmModalRef = useRef();

  const onClose = (status) => {
    toggleBrowserScrollVisiblilty(false);
    deleteReportConfirmModalRef.current.closeModal(() => handleClose && handleClose(status));
  };

  const handleDeleteReportBtnClick = () => {
    toggleBrowserScrollVisiblilty(false);
    handleDeleteReport();
  };

  return (
    <Modal
      ref={deleteReportConfirmModalRef}
      setThresholdModalStyle={deleteReportConfirmModalStyle}
      suppressScrollOnActive={true}
      customClass="modal--center delete-report-confirm-modal delete-report-confirm-modal__index"
      backdropCustomClass="delete-report-confirm-modal__backdrop-index"
      open={isModalOpen}
      handleClose={handleClose}
      animation={false}>
      <div className="delete-report-confirm-modal">
        <div className="delete-report-confirm-modal__heading">{t('tkDeleteCustomReport')}</div>
        <div className="delete-report-confirm-modal__subHeader">
          {t('tkDeleteCustomReportAlertMsg')}
        </div>
        <div className="delete-report-confirm-modal__message">
          <ul>
            <li>
              {reportName}
            </li>
          </ul>
        </div>
        <div className="delete-report-confirm-modal__bottomRow">
          <div className="button-container">
            <Button
              label={t('tkCancel')}
              customClass='button-secondary-small'
              clickHandler={(event) => onClose(event)}
            />
            <Button
              label={t('tkDelete')}
              customClass='button-primary-small'
              clickHandler={(event) => handleDeleteReportBtnClick(event)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

DeleteReportConfirmModal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  reportName: PropTypes.string,
  handleDeleteReport: PropTypes.func
};

export default DeleteReportConfirmModal;
