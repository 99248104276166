import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import { Conditional } from '../../../../components/core/Conditional';
import PageHeader from '../../../../components/app/PageHeader';
import Button from '../../../../components/core/Button';
import Link from '../../../../components/core/Link';
import RadioButton from '../../../../components/core/RadioButton';
import translator from '../../../../services/translator';
import {analyticsEventName, saveDisplaySettingsPreferences, onPreferencesPageLoadAnalytics} from '../../analytics';
import {pageIdSelector, preferencesSavedSelector, preferencesSuccessSelector} from "../../../../selectors/pages";
import {eventAnalytics} from "../../../../actions/sagas";
import {contactUsLinkSelector} from "../../../../selectors/app";
import {saveAppPreferences} from "../../../../actions/preferences";
import {
  preferenceNavLinksSelector, preferenceRouteConfigSelector, displaySettingsConfigSelector
} from "../../../../selectors/global";
import './index.scss';
import {setShouldRefreshEntitlementFilters} from "../../../../actions/app/reports/index"
const {translate: t} = translator;

export const RadioButtonGroup = ({preference, onSelectionChange, valueKey}) => (
  <form className='display-settings__container-list'>
    <div className='display-settings__container-title'>{preference.title}</div>
    <div className='display-settings__container-radio'>
      {
        preference.list.map((data, index) => (
          <RadioButton
            id={data.value}
            valueKey={valueKey}
            changeHandler={onSelectionChange}
            label={data.label}
            value={data.value}
            key={index}
            isChecked={data.isSelected}
          /> ))
      }
    </div>
  </form>
);

RadioButtonGroup.propTypes = {
  valueKey: PropTypes.string,
  onSelectionChange: PropTypes.func,
  preference: PropTypes.object
};

export const DisplaySettings = (props) => {
  const { contactUsLink = {}, isPreferencesSaved, isPreferencesSuccess } = props;
  const [displaySettingsConfig, setDisplaySettingsConfig] = useState(props.displaySettingsConfig);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    props.onPageLoad()
  }, [])

  const onSavePreferences = () => {
    let preferences = {};
    const labels = [];
    displaySettingsConfig.forEach(preference => {
      const updatedData = preference.list.find(data => data.isSelected);
      if(updatedData){
        preferences = {...preferences, [preference.valueKey]: updatedData.value};
        labels.push(updatedData.label);
      }
    });
    setIsDirty(false);
    props.saveAppPreferences(preferences, labels);
  };

  const onSelectionChange = (event, isSelected, propsData) => {
    const { valueKey } = propsData;
    const preference = displaySettingsConfig.find(data => data.valueKey === valueKey);
    if(preference){
      preference.list.forEach(data => data.isSelected = data.value === event.target.value);
    }
    setDisplaySettingsConfig(displaySettingsConfig.slice());
    setIsDirty(true);
  };

  return(
    <div className='display-settings'>
      <div className='display-settings__header'>
        <PageHeader title={t('tkDisplaySettings')} description={t('tkDisplaySettingSubTitle')} type='Secondary' />
        <Conditional condition={Object.keys(contactUsLink).length}>
          <div className='display-settings__header-link'>
            <Link handleClick={(e) => e.preventDefault()} label={t('tkNeedHelp')} href={contactUsLink.URL} target={contactUsLink.newTab ? '_blank': ''} classType='link-primary--light' />
          </div>
        </Conditional>
      </div>
      <div className='display-settings__container'>
        {
          displaySettingsConfig && displaySettingsConfig.map((preference, i) => (
            <RadioButtonGroup valueKey={preference.valueKey} onSelectionChange={onSelectionChange} preference={preference} key={i} />
          ))
        }
      </div>
      <div className='display-settings__footer'>
        <Button extraProps={{'analytics-attribute': analyticsEventName.SAVE_PREFERENCES}} customClass='button-primary' label={t('tkSave')} clickHandler={onSavePreferences} isDisabled={!isDirty} />
        <Conditional condition={isPreferencesSaved && !isDirty}>
          <Conditional condition={isPreferencesSuccess}>
            <div className='success-message'>{t('tkPreferencesSaveSuccess')}</div>
            <div className='success-message'>{t('tkPreferencesSaveError')}</div>
          </Conditional>
        </Conditional>
      </div>
    </div>
  );
};

DisplaySettings.propTypes = {
  displaySettingsConfig: PropTypes.array,
  saveAppPreferences: PropTypes.func,
  contactUsLink: PropTypes.object,
  isPreferencesSaved: PropTypes.bool,
  isPreferencesSuccess: PropTypes.bool,
  onPageLoad: PropTypes.func
};

const mapStateToProps = (state) => (
  {
    displaySettingsConfig: displaySettingsConfigSelector(state),
    preferencesStepperConfig: preferenceRouteConfigSelector(state),
    contactUsLink: contactUsLinkSelector(state),
    isPreferencesSaved: preferencesSavedSelector(state),
    isPreferencesSuccess: preferencesSuccessSelector(state),
    currentPageId: pageIdSelector(state),
    preferenceNavLinks: preferenceNavLinksSelector(state),
  }
);

export const mapDispatchToProps = (dispatch) => (
  {
    saveAppPreferences: (data, labels) => {
      dispatch(saveAppPreferences({
        preferenceRootKey: 'global',
        preferenceData: data
      }));
      dispatch(setShouldRefreshEntitlementFilters({
        reports: {
          shouldRefreshEntitlementFilters: true
        }
      }));
      dispatch(eventAnalytics(saveDisplaySettingsPreferences(labels)));
    },
    onPageLoad: () => {
      dispatch(eventAnalytics(onPreferencesPageLoadAnalytics()));
    }
  }
);
export default connect(mapStateToProps, mapDispatchToProps)(DisplaySettings);
