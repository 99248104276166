import { StyleSheet } from '@gs-ux-uitoolkit-common/style';

export const collapseStyleSheet = new StyleSheet('collapse', () => {
    return {
        collapsing: {
            position: 'relative',
            height: '0',
            overflow: 'hidden',
            transition: 'height .3s cubic-bezier(.455,.03,.515,.955)',
        },
        collapse: {
            display: 'none',
        },
        show: {},
    };
});
