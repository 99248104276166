import { QuickFilter } from '@gs-ux-uitoolkit-common/datacore';
import { FC, useState, useEffect } from 'react';
import {
    SelectMultiple,
    SelectMultipleProps,
    OptionRendererData,
    SelectSize,
    SelectMultipleChangeEvent,
} from '@gs-ux-uitoolkit-react/select-multiple';
import { Checkbox } from '@gs-ux-uitoolkit-react/checkbox';
import { Text } from '@gs-ux-uitoolkit-react/text';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { checkboxStylesheet } from './quick-filter-selector-stylesheet';

export interface QuickFilterSelectorProps {
    quickFilterList: QuickFilter[];
    setEnabledQuickFilterList: (quickFilterList: QuickFilter[]) => void;
    size?: SelectSize;
}

const QuickFilterCheckbox: FC<{ data: OptionRendererData; size?: SelectSize }> = ({
    data,
    size,
}) => {
    const theme = useTheme();
    const checkboxClasses = useStyleSheet(checkboxStylesheet, { theme });

    return (
        <div className={checkboxClasses.root}>
            <Checkbox
                name={data.value}
                size={size || 'sm'}
                checked={data.selected}
                className={checkboxClasses.checkbox}
            >
                {data.label}
            </Checkbox>
            {data.customProperties && (
                <Text typography="body03" className={checkboxClasses.secondaryLabel}>
                    {data.customProperties.secondaryLabel}
                </Text>
            )}
        </div>
    );
};

export interface QuickFilterSelectorProps {
    quickFilterList: QuickFilter[];
    setEnabledQuickFilterList: (quickFilterList: QuickFilter[]) => void;
    size?: SelectSize;
}

export const QuickFilterSelector = (props: QuickFilterSelectorProps) => {
    const getSelectedValues = () =>
        props.quickFilterList
            .filter(quickFilter => quickFilter.isEnabled)
            .map(quickFilter => quickFilter.name);

    const getOptions = () =>
        props.quickFilterList.map(({ name, secondaryLabel }) => ({
            label: name,
            value: name,
            customProperties: { secondaryLabel },
        }));

    const getSize = () => props.size || 'sm';

    const [options, setOptions] = useState(getOptions());
    const [size, setSize] = useState(getSize());

    useEffect(() => {
        setOptions(getOptions());
        setSize(getSize());
    }, [props.size, props.quickFilterList]); // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeEvent = (evt: SelectMultipleChangeEvent) => {
        props.setEnabledQuickFilterList(
            props.quickFilterList.filter(quickFilter =>
                evt.selectedValues.includes(quickFilter.name)
            )
        );
    };

    const checkboxRenderer = (data: OptionRendererData) => {
        return <QuickFilterCheckbox data={data} size={props.size} />;
    };

    const selectMultipleProps: SelectMultipleProps = {
        onChange: onChangeEvent,
        options,
        selectedValues: getSelectedValues(),
        placeholder: 'Quick Filters',
        size: size,
        constrainSelectedOptions: true,
        renderSelectedOptions: true,
        optionRenderer: checkboxRenderer,
    };

    return (
        <div className="gs-uitk-quick-filter-selector-container">
            <div className="gs-uitk-quick-filter-selector-wrapper">
                <SelectMultiple {...selectMultipleProps} />
            </div>
        </div>
    );
};
