import {
    SavedView,
    SavedViewConfig,
    SavedViewFormState,
    SavedViewOptions,
} from '../../plugins/saved-view/saved-view';
import { SavedViewActionKeys } from './action-constants';

export type SavedViewActionTypes =
    | RemoveSavedViewAction
    | AddSavedViewAction
    | CreateSavedViewAction
    | SetSavedViewAction
    | SaveSavedViewAction
    | UpdateSavedViewConfigAction
    | UpdateSavedViewFormAction;

export const RemoveSavedView = (name: string): RemoveSavedViewAction => ({
    name,
    type: SavedViewActionKeys.REMOVE_SAVED_VIEW,
});

export const AddSavedView = (savedView: SavedView): AddSavedViewAction => ({
    savedView,
    type: SavedViewActionKeys.ADD_SAVED_VIEW,
});

export const CreateSavedView = (
    savedViewOption: SavedViewOptions,
    name: string
): CreateSavedViewAction => ({
    savedViewOption,
    name,
    type: SavedViewActionKeys.CREATE_SAVED_VIEW,
});

export const SetSavedView = (name: string | null): SetSavedViewAction => ({
    name,
    type: SavedViewActionKeys.SET_SAVED_VIEW,
});

export const SaveSavedView = (savedView: SavedView): SaveSavedViewAction => ({
    savedView,
    type: SavedViewActionKeys.SAVE_SAVED_VIEW,
});

export const UpdateSavedViewConfig = (
    savedViewConfig: SavedViewConfig
): UpdateSavedViewConfigAction => ({
    savedViewConfig,
    type: SavedViewActionKeys.UPDATE_SAVED_VIEW_CONFIG,
});

export const UpdateSavedViewForm = (
    savedViewForm: SavedViewFormState
): UpdateSavedViewFormAction => ({
    savedViewForm,
    type: SavedViewActionKeys.UPDATE_SAVED_VIEW_FORM,
});

export interface RemoveSavedViewAction {
    type: SavedViewActionKeys.REMOVE_SAVED_VIEW;
    name: string;
}

export interface AddSavedViewAction {
    type: SavedViewActionKeys.ADD_SAVED_VIEW;
    savedView: SavedView;
}

export interface CreateSavedViewAction {
    type: SavedViewActionKeys.CREATE_SAVED_VIEW;
    savedViewOption: SavedViewOptions;
    name: string;
}

export interface SetSavedViewAction {
    type: SavedViewActionKeys.SET_SAVED_VIEW;
    name: string | null;
}

export interface SaveSavedViewAction {
    type: SavedViewActionKeys.SAVE_SAVED_VIEW;
    savedView: SavedView;
}

export interface UpdateSavedViewConfigAction {
    type: SavedViewActionKeys.UPDATE_SAVED_VIEW_CONFIG;
    savedViewConfig: SavedViewConfig;
}

export interface UpdateSavedViewFormAction {
    type: SavedViewActionKeys.UPDATE_SAVED_VIEW_FORM;
    savedViewForm: SavedViewFormState;
}
