const ChartActionTypes = {
  registerChart: 'registerChart',
  initialLoad: 'initialLoad',
  setInitShareClassId: 'setInitShareClassId',
  setDefaultState: 'setDefaultState',
  setFieldForQuery: 'setFieldForQuery',
  setMetricBenchmarksToState: 'setMetricBenchmarksToState',
  setShareClassAndBenchmarkData: 'setShareClassAndBenchmarkData',
  setChartRangeOptions: 'setChartRangeOptions',
  setShareClassPerformanceData: 'setShareClassPerformanceData',
  setExcelExportData: 'setExcelExportData',
  updateChartOptions: 'updateChartOptions',
  updateShareClassList: 'updateShareClassList',
  isMetricDataMissing: 'isMetricDataMissing',
  isFundOrBenchmarkDataMissing: 'isFundOrBenchmarkDataMissing',
  isFundOrBenchmarkSeriesDataMissing: 'isFundOrBenchmarkSeriesDataMissing',
  isFetchShareClsPerfDataInProgress: 'isFetchShareClsPerfDataInProgress',
  isThresholdSaved: 'isThresholdSaved',
  setThresholdList: 'setThresholdList',
  pending: 'pending',
  resolved: 'resolved',
  error: 'error',
};

export { ChartActionTypes };
