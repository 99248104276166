import { defaultIconProps, IconElementComponentProps } from './props';
import {
    getAriaLabel,
    getIconClassNames,
    getIconCodePoint,
    getIconContentClassNames,
} from './util';
import { iconStyleSheet, resolveFontFamily } from './icon-style-sheet';
import { setAttributes } from '@gs-ux-uitoolkit-common/shared';
import { lightTheme } from '@gs-ux-uitoolkit-common/theme';

export class IconElementComponent {
    private iconEl?: HTMLSpanElement;

    constructor(private props: IconElementComponentProps) {
        if (!props.name) {
            throw new Error(
                "<IconElementComponent /> error: 'name' input is required, please provide one!"
            );
        }
    }

    render(containerEl: HTMLElement) {
        const {
            size = defaultIconProps.size,
            spin = false,
            pulse = false,
            name,
            className,
            classes: overrideClasses,
            attributes,
            type = defaultIconProps.type,
            theme = lightTheme,
        } = this.props;

        if (this.iconEl === undefined) {
            const iconContent = getIconCodePoint(this.props);
            const fontFamily = resolveFontFamily(this.props);
            const cssClasses = iconStyleSheet.mount(this, {
                theme,
                fontFamily,
                size,
                type,
                spin,
                pulse,
                loading: false,
            });

            this.iconEl = document.createElement('span');
            setAttributes(this.iconEl, {
                'data-gs-uitk-component': 'icon',
                role: 'img',
                'data-size': size,
                'data-spin': spin ? 'true' : 'false',
                'data-pulse': pulse ? 'true' : 'false',
                'aria-label': getAriaLabel(name),
                class: getIconClassNames({ name, cssClasses, className, overrideClasses }),
                ...attributes,
            });

            const contentEl = document.createElement('span');
            setAttributes(contentEl, {
                'data-cy': 'gs-uitk-icon__content',
                role: 'img',
                'aria-hidden': 'true',
                class: getIconContentClassNames({ cssClasses, overrideClasses }),
            });

            contentEl.innerHTML = iconContent;

            this.iconEl.appendChild(contentEl);
        }
        containerEl.appendChild(this.iconEl);
    }

    destroy() {
        iconStyleSheet.unmount(this);
        this.iconEl?.remove();
    }
}
