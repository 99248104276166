import { IconName } from './design-system-icons/full-set';
import { v17ToV18IconMapping } from './design-system-icons/v17-to-v18-icon-mapping';

/**
 * Resolves v17 icon names to v18 Icons. This is required for certain
 * Material Icons that have got renamed in a more recent version of Material Symbols.
 *
 * @param {string} iconName - Name of icon that needs to be resolved
 * @returns { string} Name of outlined icon in Material Symbols
 */
export function resolveIconFromV17IconName(iconName: IconName): IconName {
    return v17ToV18IconMapping[iconName] || iconName;
}
