import React from 'react';
import {PropTypes} from 'prop-types';
import Button from '../../core/Button';
import './index.scss';

export const MainLogout = (props) => {
  const redirectToLogin = () => {
    window.location = '/';
  };
  return(
    <div className='panel'>
      <div className='header-text'>
        {props.label.tkLogoutHeader1}
      </div>
      <div className='message-panel'>
        {props.label.tkLogoutMessage}
      </div>
      <div className="redirect-login-button">
        <Button
          type="submit"
          customClass="button-primary"
          label={props.label.tkLogIn}
          clickHandler={redirectToLogin} />
      </div>
    </div>
  );
};


MainLogout.propTypes= {
  label : PropTypes.object
};


export default MainLogout;
