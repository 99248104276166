import { StyleSheet, CssClasses } from '@gs-ux-uitoolkit-common/style';
import { Theme } from '@gs-ux-uitoolkit-common/theme';
import { StepsProps } from './';

export interface StepsSummaryCssProps {
    size: StepsProps['size'];
    theme: Theme;
}

export type StepsSummaryCssClasses = CssClasses<typeof stepsSummaryStyleSheet>;

/**
 * JsStyleSheet definition for Steps Summary
 */
export const stepsSummaryStyleSheet = new StyleSheet(
    'steps summary',
    ({ size, theme }: StepsSummaryCssProps) => {
        const stepsSummarySpacing =
            size === 'md'
                ? {
                      marginTop: '-6px',
                  }
                : {
                      marginTop: '-10px',
                  };

        const stepsSummaryLabelTypography =
            size === 'md' ? theme.typography.heading06 : theme.typography.heading07;

        return {
            summary: {
                position: 'relative',
                ...stepsSummarySpacing,
            },
            boldLabel: {
                ...stepsSummaryLabelTypography,
            },
        };
    }
);
