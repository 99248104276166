import moment from 'moment';
import isUndefined from 'lodash/isUndefined';
import {amountFormatter} from './amountFormatter';
import {BOOLEAN} from '../constants/pageConstants';

export const booleanFormatter = (value = '') => {
  if(value === BOOLEAN.TRUE){
    return BOOLEAN.YES;
  } else if(value === BOOLEAN.FALSE){
    return BOOLEAN.NO;
  }
  return value;

};

export const booleanStringFormatter = (params) => {
  const { value } = params;
  if(value === BOOLEAN.STRTRUE){
    return { ...params, value: BOOLEAN.YES };
  } else if(value === BOOLEAN.STRFALSE){
    return { ...params, value: BOOLEAN.NO };
  }
  return params;
};

export const dateFormatter = (value = '') => {
  if(value !== ' ' &&  value){
    return moment.utc(value).format('DD.MMM.YYYY');
  } else {
    return value;
  }
};

export const dateTimeFormatter = (value = '') => {
  if (value !== ' ' && value) {
    const userTimeZone = moment.tz.guess();
    return moment(value).tz(userTimeZone).format('DD.MMM.YYYY hh:mm:ss A z');
  } else {
    return value;
  }
};

export const dateTimeFormatterTradeEntry = (value = '') => {
  if (value !== ' ' && value !== '--' && value ) {
    return `${moment(value, 'DD MMM YYYY hh:mm:ss A').format(
      'DD.MMM.YYYY hh:mm:ss A'
    )} ${value.split(' ')[value.split(' ').length - 1]}`;
  } else {
    return value;
  }
};

export const dateTimeFormatterWithoutSeconds = (value = '') => {
  if (value !== ' ' && value !== '--' && value ) {
    return `${moment(value, 'DD MMM YYYY hh:mm A').format(
      'DD.MMM.YYYY hh:mm A'
    )} ${value.split(' ')[value.split(' ').length - 1]}`;
  } else {
    return value;
  }
};

export const currencyFormatter = (params) => {
  const { value, isValueNull } = params;
  if(isUndefined(value) || isValueNull) {
    return params;
  }

  let formattedValue = amountFormatter(value, null, true);
  formattedValue = params.fundCcy ? (`${formattedValue} ${params.fundCcy}`) : formattedValue;
  return {
    ...params,
    value: formattedValue
  };
};
