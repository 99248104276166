import { Component, Fragment } from 'react';
import { Form, FormGroup } from '@gs-ux-uitoolkit-react/form';
import { Radio, RadioChangeEvent } from '@gs-ux-uitoolkit-react/radio';
import { Select, SelectChangeEvent, SelectOption } from '@gs-ux-uitoolkit-react/select';
import { GridColumn } from '../../../../grid-wrappers/grid-wrapper';
import { PluginFooter } from '../../../../components/plugin-footer/plugin-footer';
import { ColumnHint, ColumnHintDefaults } from '@gs-ux-uitoolkit-common/datacore';
import { columnHintHelper, ColumnHintFormatTypes } from '../../column-hint-helper';

export interface ColumnHintTypePickerProps {
    /**
     * Callback when the status of the radio button is changed
     */
    onRadioChanged: (e: RadioChangeEvent) => void;
    /**
     * Columns from the grid
     */
    columns: GridColumn[];
    /**
     * The radio that is checked
     */
    checkedRadio: ColumnHintFormatTypes | null;
    /**
     * The selected column in the Select
     */
    selectedColumn: string | null;
    /**
     * Cancel creating the hint
     */
    cancel: () => void;
    /**
     * Move onto the next step
     */
    continue: () => void;
    /**
     * Column selector changed
     */
    selectedColumnChanged: (e: SelectChangeEvent) => void;
    /**
     * Column hints that are already applied
     */
    specificColumnHints: ColumnHint[];
    /**
     * The default column hints
     */
    defaultColumnHints: ColumnHintDefaults;
}

export class ColumnHintTypePicker extends Component<ColumnHintTypePickerProps> {
    public render() {
        const selectOptions: SelectOption[] = this.props.columns
            .filter(
                column =>
                    // Filter out columns that already have a hint defined
                    !this.props.specificColumnHints.find(
                        colHint => colHint.columnId === column.columnId
                    )
            )
            .map(column => {
                return {
                    label: column.columnLabel,
                    value: column.columnId,
                };
            })
            .sort((a, b) => {
                const x = a.label.toLowerCase();
                const y = b.label.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
            });
        return (
            <Fragment>
                <p>
                    <strong>What would you like to create?</strong>
                </p>
                <Form preventSubmit={true}>
                    <FormGroup>
                        <div style={{ display: 'flex' }}>
                            <Radio
                                name={ColumnHintFormatTypes.SpecificColumn}
                                value={ColumnHintFormatTypes.SpecificColumn}
                                checked={
                                    this.props.checkedRadio === ColumnHintFormatTypes.SpecificColumn
                                }
                                onChange={this.props.onRadioChanged}
                                disabled={selectOptions.length === 0}
                            >
                                A specific column*
                            </Radio>
                            <Select
                                style={{ width: '300px', marginLeft: '8px' }}
                                data-cy={'uitk-column-hint-column-selector'}
                                options={selectOptions}
                                onChange={this.props.selectedColumnChanged}
                                disabled={
                                    this.props.checkedRadio !==
                                        ColumnHintFormatTypes.SpecificColumn ||
                                    selectOptions.length === 0
                                }
                                selectedValue={this.props.selectedColumn}
                            />
                        </div>
                        <Radio
                            name={ColumnHintFormatTypes.DateColumns}
                            value={ColumnHintFormatTypes.DateColumns}
                            checked={this.props.checkedRadio === ColumnHintFormatTypes.DateColumns}
                            onChange={this.props.onRadioChanged}
                            disabled={!!this.props.defaultColumnHints.dateDefaults}
                        >
                            All date columns
                        </Radio>
                        <Radio
                            name={ColumnHintFormatTypes.NumericColumns}
                            value={ColumnHintFormatTypes.NumericColumns}
                            checked={
                                this.props.checkedRadio === ColumnHintFormatTypes.NumericColumns
                            }
                            onChange={this.props.onRadioChanged}
                            disabled={!!this.props.defaultColumnHints.numberDefaults}
                        >
                            All numeric columns
                        </Radio>
                        <Radio
                            name={ColumnHintFormatTypes.StringColumns}
                            value={ColumnHintFormatTypes.StringColumns}
                            checked={
                                this.props.checkedRadio === ColumnHintFormatTypes.StringColumns
                            }
                            onChange={this.props.onRadioChanged}
                            disabled={!!this.props.defaultColumnHints.stringDefaults}
                        >
                            All string (text) columns
                        </Radio>
                        <Radio
                            name={ColumnHintFormatTypes.AllColumns}
                            value={ColumnHintFormatTypes.AllColumns}
                            checked={this.props.checkedRadio === ColumnHintFormatTypes.AllColumns}
                            onChange={this.props.onRadioChanged}
                            disabled={columnHintHelper.hasDefaultHints(
                                this.props.defaultColumnHints
                            )}
                        >
                            All columns
                        </Radio>
                    </FormGroup>
                </Form>
                <span>
                    * <strong>Specific column</strong> formatting will override column type, which
                    will override <strong>All columns</strong>
                </span>
                <PluginFooter
                    createButtonDisplayName="Continue"
                    onCreate={() => this.props.continue()}
                    onCancel={() => this.props.cancel()}
                    createDisabled={this.props.checkedRadio === null}
                    createActionType="primary"
                />
            </Fragment>
        );
    }
}
