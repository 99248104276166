import { Table } from '@gs-ux-uitoolkit-react/table';
import { HTMLAttributes } from 'react';

export interface ManagementTableProps {
    headingConfig: TableHeadings[];
    bodyRowElements: (JSX.Element | null)[];
}

export interface TableHeadings {
    props?: HTMLAttributes<HTMLTableHeaderCellElement>;
    label: string;
}

export const ManagementTable = (props: ManagementTableProps) => {
    return (
        <Table className="config-items-table">
            <thead>
                <tr>
                    {props.headingConfig.map((heading, index) => (
                        <th key={index} {...heading.props}>
                            {heading.label}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>{props.bodyRowElements}</tbody>
        </Table>
    );
};
