import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import { Button } from "@gs-ux-uitoolkit-react/button";
import Repeater from "../../../core/Repeater";
import translator from "../../../../services/translator";
import Item from "./components/Item";
import {
  getAccountFactDebitCredit,
  getDebitCreditEditStatus,
  getDebitCreditLoading,
} from "../../../../selectors/containers/fundFactView";
import Tooltip from "./components/Tooltip";
import Toast from "../../../core/Toast";
import { INPUT, regexForInputs} from "./constants";
import {
  clearDebitCreditEditStatus,
  updateDebitCredit,
} from "../../../../actions/containers/fundFact";
import { ThreeSquaresLoader } from "../../../core/Loaders";
import "./index.scss";
import {EDIT_SUCCESS} from "../../../core/Toast/constants";

const { translate: t } = translator;

const DebitCreditInstructions = ({
  items,
  debitCredit,
  updateDebitCredit,
  editStatus,
  clearDebitCreditEditStatus,
  debitCreditLoading,
}) => {
  const [toggleEditSave, setToggleEditSave] = useState(false);
  const [debitCreditInfo, setDebitCreditInfo] = useState(debitCredit);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [errorState, setErrorState] = useState({
    routingNumber: false,
    accountNumber: false,
    accountName: false,
  });

  useEffect(() => {
    setDebitCreditInfo(debitCredit);
  }, [debitCredit, debitCreditLoading]);

  const onEditClick = () => {
    const initialValues = Object.entries(debitCreditInfo);
    const errorFields = {
      routingNumber: true,
      accountNumber: true,
      accountName: true,
    };

    for (let i = 0; i < initialValues.length; i++) {
      const [field, value] = initialValues[i];
      if (errorFields[field] && value.match(regexForInputs) && value.trim().length) {
        errorFields[field] = false;
      }
    }
    setErrorState(errorFields);
    setToggleEditSave(true);
    setSaveButtonDisabled(true);
    clearDebitCreditEditStatus();
  };

  const onSaveClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    await updateDebitCredit(debitCreditInfo);

    setToggleEditSave(false);
  };

  const onCancelClick = () => {
    setDebitCreditInfo(debitCredit);
    setToggleEditSave(false);
    setErrorState({
      routingNumber: false,
      accountNumber: false,
      accountName: false,
    });
  };

  const renderEditSaveButtons = () => {
    if (toggleEditSave) {
      return (
        <>
          <Button
            className="cancel-button"
            onClick={onCancelClick}
            actionType="secondary"
          >
            {t("tkCancel")}
          </Button>
          <Button
            disabled={saveButtonDisabled}
            onClick={onSaveClick}
            actionType="primary"
          >
            {t("tkSave")}
          </Button>
        </>
      );
    }
    return (
      <Button onClick={onEditClick} actionType="secondary">
        {t("tkEdit")}
      </Button>
    );
  };

  const enableSaveButton = (field) => {
    const errors = Object.entries(errorState);
    let enable = true;
    for (let i = 0; i < errors.length; i++) {
      const [errField, value] = errors[i];
      if (value && errField !== field) {
        enable = false;
      }
    }
    setSaveButtonDisabled(!enable);
  };

  const checkCharsLength = (value, range) => {
    const charLength = value.length;
    const [min, max] = range;
    return charLength >= min && charLength <= max;
  };

  const handleError = (value, field, range) => {
    const reg = regexForInputs;
    let error = false;
    if (value.match(reg) && checkCharsLength(value, range) && value.trim().length) {
      error = false;
      enableSaveButton(field);
    } else {
      error = true;
      setSaveButtonDisabled(true);
    }

    setErrorState({
      ...errorState,
      [field]: error,
    });
  };

  const handleEditFields = (value, field, fieldType, range) => {
    setDebitCreditInfo({
      ...debitCreditInfo,
      [field]: value,
    });

    if (fieldType === INPUT) {
      handleError(value, field, range);
    } else {
      enableSaveButton("");
    }
  };

  return (
    <div
      data-testid="debit-credit-instructions-container"
      className={classNames("fundFactView__list--accountDetail", {
        "debit-container": debitCreditLoading,
      })}
    >
      <div className="factHead">
        <span className="account-fact-label">
          {t("tkDebitCreditInstructions")}
          <Tooltip direction="top" content={t("tkDebitCreditTooltipContent")}>
            <span data-testid="info-icon" className="info-icon" />
          </Tooltip>
        </span>

        <span className="account-edit-button">{renderEditSaveButtons()}</span>
      </div>
      <Repeater
        items={items}
        props={{
          debitCreditInfo,
          isEdit: toggleEditSave,
          handleEditFields,
          errorState,
        }}
      >
        <Item />
      </Repeater>
      {editStatus && (
        <Toast
          type={editStatus.type}
          autoHide={false}
          onClear={clearDebitCreditEditStatus}
          displayText={
            editStatus.type === EDIT_SUCCESS
              ? "tkDebitCreditSuccessMessage"
              : "tkDebitCreditErrorMessage"
          }
        />
      )}
      {debitCreditLoading && <ThreeSquaresLoader />}
    </div>
  );
};

DebitCreditInstructions.propTypes = {
  items: PropTypes.array,
  debitCredit: PropTypes.object,
  updateDebitCredit: PropTypes.func,
  debitCreditLoading: PropTypes.bool,
  editStatus: PropTypes.object,
  clearDebitCreditEditStatus: PropTypes.func,
};

const mapStateToProps = (state) => ({
  debitCredit: getAccountFactDebitCredit(state),
  editStatus: getDebitCreditEditStatus(state),
  debitCreditLoading: getDebitCreditLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearDebitCreditEditStatus: () => dispatch(clearDebitCreditEditStatus()),
  updateDebitCredit: (data) => dispatch(updateDebitCredit(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DebitCreditInstructions);
