import { isEqual } from 'lodash';

// search and center the first match
export function searchAndCenter(gridRef, searchTerm) {
  const matchedNodes = [];
  if (!searchTerm) {
    gridRef && gridRef.api && gridRef.api.onGroupExpandedOrCollapsed();
    gridRef && gridRef.api && gridRef.api.ensureIndexVisible(0, 'top');
    return 0;
  }
  const lowerCasedSearchTerm = searchTerm.toLowerCase();
  gridRef.api.forEachNode(node => {
    if (node.data.label.toLowerCase().includes(lowerCasedSearchTerm)) {
      matchedNodes.push(node);
    }
  });
  if (matchedNodes.length) {
    gridRef.api.expandAll();
    matchedNodes.sort((a, b) => a.rowIndex - b.rowIndex);
    setTimeout(() => {
      gridRef.api.ensureIndexVisible(matchedNodes[0].rowIndex, 'middle');
    }, 0);
  } else {
    gridRef && gridRef.api && gridRef.api.onGroupExpandedOrCollapsed();
    gridRef && gridRef.api && gridRef.api.ensureIndexVisible(0, 'top');
  }
  return matchedNodes.length;
}

export const updateOverrideContainerDisplay = nodes => {
  const overrideLevels = new Set([0, 1, 3]);
  return nodes.some(parentNode => {
    const {
      data: { capabilities: parentCapabilities },
      allLeafChildren,
    } = parentNode;
    return allLeafChildren.some(childNode => {
      const {
        data: { capabilities: childCapabilities },
        level,
      } = childNode;
      return overrideLevels.has(level) && !isEqual(parentCapabilities, childCapabilities);
    });
  });
};

export const flattenTheResponse = (allNodeData, responseObject) => {
  allNodeData.forEach(node => {
    if ('firmName' in node) {
      responseObject.firmName = node.firmName;
      responseObject.firmCode = node.firmCode;
      responseObject.firmId = node.firmId;
    } else if ('branchName' in node) {
      responseObject.branchName = node.branchName;
      responseObject.branchCode = node.branchCode;
      responseObject.branchId = node.branchId;
    } else if ('name' in node && node.orgHierarchy.length === 3) {
      responseObject.accountName = node.name;
    } else if ('taAccount' in node) {
      responseObject.taAccount = node.taAccount;
      responseObject.clientAccountName = node.clientAccountName;
    }
  });
  return responseObject;
};
