import {
    PropsWithChildren,
    ReactElement,
    Children,
    Component,
    cloneElement,
    ReactNode,
} from 'react';
import PropTypes from 'prop-types';
import { ControlledTabs } from './ControlledTabs';
import { componentAnalytics } from './analytics-tracking';
import { isDropdownTab, isValidTabsChild } from './tab-utils';

const getActiveTabKey = (children: ReactElement) => {
    // Array.prototype.findIndex not supported in IE
    let defaultActiveIndex = -1;
    getTabChildren(children).some((child: any, i) => {
        // if dropdown check the next level
        if (isDropdownTab(child)) {
            return getTabChildren(child.props.children as any).some((dropdownChild: any, j) => {
                if (dropdownChild.props.defaultActive) {
                    defaultActiveIndex = Number(`${i}.${j + 1}`);
                    return true;
                }
                return false;
            });
        }
        // otherwise check this level
        if (child.props.defaultActive) {
            defaultActiveIndex = i;
            return true;
        }
        return false;
    });
    // no defaultActive specified --> use first
    return defaultActiveIndex === -1 ? 0 : defaultActiveIndex;
};

export interface UncontrolledTabsState {
    activeTabKey: string | number;
}

export class UncontrolledTabs extends Component<PropsWithChildren, UncontrolledTabsState> {
    public static propTypes: { [key: string]: any } = {
        children: PropTypes.node,
    };

    private _onSelect = (tabKey: string | number) => this.setState({ activeTabKey: tabKey });

    constructor(props: any) {
        super(props);
        // first tab is active unless defaultActive
        // specified on a tab
        const activeTabKey = getActiveTabKey(props.children);
        this.state = { activeTabKey };
        this._renderTab = this._renderTab.bind(this);
        this._renderDropdownTab = this._renderDropdownTab.bind(this);
        this._renderTabs = this._renderTabs.bind(this);
    }

    _renderTab(tab: ReactElement, tabKey: string | number) {
        // eslint-disable-line class-methods-use-this
        // bind click callback with proper tabKey
        const props = Object.assign({}, tab.props, {
            key: tabKey,
            tabKey,
        });
        return cloneElement(tab, props);
    }

    _renderDropdownTab(dropdownTab: ReactElement, dropdownTabKey: string | number) {
        const props = Object.assign({}, dropdownTab.props, {
            tabKey: dropdownTabKey,
        });
        const children = getTabChildren(dropdownTab.props.children).map((child: any, i) => {
            // inner tabs use parent tabKey as base
            // if i = 0, child tabKey would be same as parent
            // so add 1 to index to avoid 0 case
            const tabKey = Number(`${dropdownTabKey}.${i + 1}`);
            return this._renderTab(child, tabKey);
        });
        return cloneElement(dropdownTab, props, children);
    }

    _renderTabs() {
        // filter out anything that's not a
        // Tab or DropdownTab
        return getTabChildren(this.props.children).map((child: any, i) => {
            if (isDropdownTab(child)) {
                return this._renderDropdownTab(child, i);
            }
            return this._renderTab(child, i);
        });
    }

    public componentDidMount() {
        componentAnalytics.trackRender({ officialComponentName: 'tabs' });
    }

    render() {
        const { activeTabKey } = this.state;
        return (
            <ControlledTabs activeTabKey={activeTabKey} onSelect={this._onSelect} {...this.props}>
                {this._renderTabs()}
            </ControlledTabs>
        );
    }
}

function getTabChildren(children: ReactNode) {
    return Children.toArray(children).filter(isValidTabsChild);
}
