import {
    PropsWithChildren,
    InputHTMLAttributes,
    ReactNode,
    FunctionComponent,
    useEffect,
    useRef,
    MouseEvent,
} from 'react';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { ReactComponentProps } from '@gs-ux-uitoolkit-react/component';
import {
    CheckboxCommonProps,
    checkboxDefaultProps,
    checkboxStyleSheet,
    getCheckboxRootClasses,
    getCheckboxInputClasses,
    getCheckboxLabelClasses,
    getCheckboxContainerClasses,
    getCheckboxInnerClasses,
    getCheckboxChildrenClasses,
    CheckboxChangeEventProps,
} from '@gs-ux-uitoolkit-common/checkbox';
import { extractDataAttributes } from '@gs-ux-uitoolkit-react/shared';

import { Label } from '@gs-ux-uitoolkit-react/label';
import { componentAnalytics } from './analytics-tracking';
import { omit } from 'gs-uitk-lodash';

export interface CheckboxProps
    extends PropsWithChildren<
        ReactComponentProps &
            CheckboxCommonProps<CheckboxChangeEvent> &
            Omit<
                InputHTMLAttributes<HTMLInputElement>,
                'defaultChecked' | 'checked' | 'size' | 'value' | 'onChange'
            >
    > {
    /**
     * Custom attributes for the input element used as the checkbox.
     *
     * This can be used to set analytics attributes.
     */
    checkboxInputAttrs?: { [attrName: string]: string };
    /**
     * Children will display as the radio's label.
     */
    children?: ReactNode;

    /**
     * Called when the checked state changes.
     */
    onChange?: (event: CheckboxChangeEvent) => void;
}

export type CheckboxChangeEvent = CheckboxChangeEventProps & MouseEvent<HTMLInputElement>;

/**
 * Checkboxes allow multiple selections from a set of options.
 */
export const Checkbox: FunctionComponent<CheckboxProps> = ({
    checked,
    children,
    className,
    classes: overrideClasses,
    disabled,
    status = checkboxDefaultProps.status,
    defaultChecked,
    inline = checkboxDefaultProps.inline,
    size = checkboxDefaultProps.size,
    tabIndex,
    onChange = () => undefined,
    onClick = () => undefined,
    style,
    title,
    id,
    labelPlacement = checkboxDefaultProps.labelPlacement,
    checkboxInputAttrs,
    ...otherProps
}) => {
    const dataAttributes = extractDataAttributes(otherProps);
    const nonDataAttributes = omit(otherProps, Object.keys(dataAttributes));

    const theme = useTheme();
    const cssClasses = useStyleSheet(checkboxStyleSheet, {
        theme,
        size,
        inline,
        labelPlacement,
        status,
    });
    const rootClasses = getCheckboxRootClasses({
        cssClasses,
        className,
        overrideClasses,
    });
    const inputClasses = getCheckboxInputClasses({ cssClasses, overrideClasses });
    const checkboxLabelClasses = getCheckboxLabelClasses({ cssClasses, overrideClasses });
    const containerClasses = getCheckboxContainerClasses({ cssClasses, overrideClasses });
    const innerClasses = getCheckboxInnerClasses({ cssClasses, overrideClasses });
    const childrenClasses = getCheckboxChildrenClasses({ cssClasses, overrideClasses });

    // Used to reset the input's checked state manually when controlled:
    const inputEl = useRef<HTMLInputElement>();

    function onInputEl(input: HTMLInputElement) {
        // `indeterminate` can only be set imperatively, not via attribute.
        // See https://developer.mozilla.org/en-us/docs/Web/HTML/Element/input/checkbox#htmlattrdefindeterminate
        if (input) {
            input.indeterminate = defaultChecked === 'indeterminate' || checked === 'indeterminate';
            inputEl.current = input;
        }
    }

    function onInternalClick(event: MouseEvent<HTMLInputElement>) {
        onClick(event);
        if (!event.isDefaultPrevented()) {
            handleChange(event);
        }
    }

    function handleChange(event: MouseEvent<HTMLInputElement>) {
        const { name, value } = otherProps;
        Object.assign(event, {
            checked: inputEl.current!.indeterminate ? false : inputEl.current!.checked,
            indeterminate: !!inputEl.current!.indeterminate,
            checkedState: inputEl.current!.indeterminate
                ? 'indeterminate'
                : inputEl.current!.checked,
            name,
            value,
        });
        event.persist(); // See https://reactjs.org/docs/legacy-event-pooling.html
        onChange(event as CheckboxChangeEvent);
        if (isControlled()) {
            // When controlled, the input's checked state is reset to the
            // controlled checked value. Developers can update the checked value
            // in a change event handler.
            if (checked === 'indeterminate') {
                inputEl.current!.indeterminate = true;
            } else {
                inputEl.current!.indeterminate = false;
                inputEl.current!.checked = !!checked;
            }
        }
    }

    /**
     * Avoids this React error:
     * ```
     * Warning: Failed prop type: You provided a `checked` prop to a form field
     * without an `onChange` handler. This will render a read-only field. If the
     * field should be mutable use `defaultChecked`. Otherwise, set either
     * `onChange` or `readOnly`.
     * ```
     */
    function onInternalChange() {}

    function isControlled() {
        return checked != undefined;
    }

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'checkbox' });
    }, []); // Only run once

    return (
        <div
            data-gs-uitk-component="checkbox"
            data-size={size}
            data-label-placement={labelPlacement}
            className={rootClasses}
            style={style}
            title={title}
            id={id}
            {...dataAttributes}
        >
            <Label
                size={size}
                classes={{ label: checkboxLabelClasses }}
                disabled={disabled}
                data-cy="gs-uitk-checkbox__label"
            >
                <span data-cy="gs-uitk-checkbox__container" className={containerClasses}>
                    <input
                        {...checkboxInputAttrs}
                        {...nonDataAttributes}
                        type="checkbox"
                        data-cy="gs-uitk-checkbox__input"
                        checked={checked as boolean}
                        defaultChecked={
                            // defaultChecked should only be set if checked is not set;
                            // i.e., if the input is uncontrolled.
                            checked === undefined ? (defaultChecked as boolean) : undefined
                        }
                        className={inputClasses}
                        disabled={disabled}
                        tabIndex={tabIndex}
                        // onClick is used to register changed events to support all browsers:
                        // https://stackoverflow.com/questions/5575338/what-the-difference-between-click-and-change-on-a-checkbox/5575369)
                        onClick={onInternalClick}
                        onChange={onInternalChange}
                        ref={onInputEl}
                    />
                    <span data-cy="gs-uitk-checkbox__inner" className={innerClasses}></span>
                </span>
                <span data-cy="gs-uitk-checkbox__children" className={childrenClasses}>
                    {children}
                </span>
            </Label>
        </div>
    );
};
