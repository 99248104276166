import React, { useState, useEffect } from 'react';
import { Input } from '@gs-ux-uitoolkit-react/input';
import PropTypes from 'prop-types';

const InputField = props => {
  const { handleChange, value, config, errorState } = props;
  const { validation } = config;
  const [textInput, setTextInput] = useState(value);
  const [errorMessage, setErrorMessage] = useState('');

  const onChange = event => {
    setTextInput(event.target.value);
    let error = null;
    if (validation) {
      const { errorMsg } = validation && validation(event.target.value, config);
      error = errorMsg;
    }
    if (errorMessage && !error) {
      setErrorMessage(error);
    }
    handleChange({
      event,
      value: event.target.value,
      config,
      errorMessage: errorMessage && !error,
    });
  };

  const onBlur = event => {
    if (textInput !== value) {
      const { errorMsg } = validation && validation(event.target.value, config) || {}
      setErrorMessage(errorMsg);
      handleChange({
        event,
        value: textInput,
        config,
        errorMessage: errorMsg,
      });
    }
  };

  useEffect(() => {
    if (errorState) {
      setErrorMessage(errorState.errorMessage);
    }
  }, [errorState]);

  return (
    <div className='input-edit' data-testid='editable-inputField'>
      <Input
        value={textInput}
        onChange={onChange}
        onBlur={onBlur}
        status={errorMessage ? 'error' : 'none'}
        className={errorMessage ? 'input-edit__error' : 'input-edit__default'}
      />
      {errorMessage && (
        <span className='error-message' data-testid='text-errorMessage'>
          {errorMessage}
        </span>
      )}
    </div>
  );
};

InputField.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.string,
  config: PropTypes.object,
  errorState: PropTypes.object,
};

export default InputField;
