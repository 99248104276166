import React from 'react';
import PropTypes from 'prop-types';
import TradeApprovalHeader from '../../../components/app/TradeApprovals/Header';
import CTSHeader from '../CurrentTrades/Header';

const Header = ({pageId, rollBackToLegacyReport, currentReportConfig}) => {
  switch (pageId) {
    case 'TradeApproval':
      return <TradeApprovalHeader/>;
    case 'CurrentTradeStatus':
      return <CTSHeader
        rollBackToLegacyReport={rollBackToLegacyReport}
        currentReportConfig={currentReportConfig}
      />;
    default:
      return null;
  }
};
Header.propTypes = {
  pageId: PropTypes.string,
  rollBackToLegacyReport: PropTypes.bool,
  currentReportConfig: PropTypes.object
};

export default Header;
