import { cx } from '@gs-ux-uitoolkit-common/style';
import { TabsCssClasses } from './tabs-style-sheet';
import { TabCssClasses } from './tab-style-sheet';
import { TabCommonProps } from './tab-props';
import { TabsCommonProps } from './tabs-props';

const globalTabsClassName = 'gs-tabs';
const globalTabClassName = 'gs-tab';

export const getTabsRootClasses = (props: {
    cssClasses: TabsCssClasses;
    overrideClasses?: TabsCommonProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(cssClasses.root, overrideClasses?.root, globalTabsClassName);
};

export const getTabsContainerClasses = (props: {
    cssClasses: TabsCssClasses;
    overrideClasses?: TabsCommonProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.container,
        overrideClasses?.container,
        `${globalTabsClassName}__container`
    );
};

export const getTabsContentClasses = (props: {
    cssClasses: TabsCssClasses;
    overrideClasses?: TabsCommonProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(cssClasses.content, overrideClasses?.content, `${globalTabsClassName}__content`);
};

export const getTabLeadingIconClasses = (props: {
    cssClasses: TabCssClasses;
    overrideClasses?: TabCommonProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.leadingIcon,
        overrideClasses?.leadingIcon,
        `${globalTabClassName}__leadingIcon`
    );
};

export const getDropdownToggleClasses = (props: {
    cssClasses: TabCssClasses;
    overrideClasses?: TabCommonProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;

    return cx('nav-link', cssClasses.dropdownToggle, overrideClasses?.dropdownToggle);
};
