import React from 'react';
import PropTypes from 'prop-types';

const Divider  = ({className}) => (<div className={`dropdown__divider ${className}`} />);

Divider.propTypes = {
  className: PropTypes.string,
};

export default Divider;
