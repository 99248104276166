import { ComponentProps, CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { FormStatus } from './form-types';

export interface FormProps<StyleType = CommonStyleType> extends ComponentProps<StyleType> {
    /**
     * From [Mozilla Developer Network](https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/acceptCharset):
     * "The HTMLFormElement.acceptCharset property represents a list of the
     * supported character encodings for the given <form> element. This list can
     * be comma-separated or space-separated.
     */
    acceptCharset?: string;

    /**
     * The [action](https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/action)
     * of the form element.
     */
    action?: string;

    /**
     * Whether the input elements in the form should use assistance from the
     * user's browser to autocomplete form field values. See
     * [Mozilla Developer Network](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete)
     * to learn more.
     */
    autoComplete?: string;

    /**
     * Sets the `disabled` state for all child FormGroup and input components.
     */
    disabled?: boolean;

    /**
     * According to [Mozilla Developer Network](https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/enctype),
     * encType specifies "the MIME type of content that is used to submit the
     * form to the server".
     */
    encType?: string;

    /**
     * Set `inline` on descendant FormGroup and input components.
     */
    inline?: boolean;

    /**
     * Specifies the HTTP method used to submit the form. Default is "GET".
     */
    method?: string;

    /**
     * Allows a name to be given to a form which in turn allows input elements
     * outside of the form to be associated with this form by defining the
     * input's `form` value to match this form's `name`. Mozilla Developer
     * Network has more information about [form name](https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/name)
     * and [input form](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Input#htmlattrdefform).
     */
    name?: string;

    /**
     * Disables form validation performed by the browser. Defaults to true to
     * avoid inconsistencies and unexpected behavior between browsers.
     */
    noValidate?: boolean;

    /**
     * Prevents the form's action
     * ( See https://www.w3schools.com/tags/att_form_action.asp ) that could trigger an unwanted page reload when the form is submitted.
     * Note: This doesn't stop the onSubmit event but prevents the legacy HTML form action from being triggered when the event is raised
     */
    preventSubmit?: boolean;

    /**
     * According to [Mozilla Developer Network](https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/target),
     * `target` "represents the target of the form's action (i.e., the frame in
     * which to render its output)."
     */
    target?: string;

    /**
     * Sets the `status` value for all child FormGroup and input components.
     */
    status?: FormStatus;
}

export const defaultFormProps: FormProps = {
    inline: false,
    noValidate: true,
    preventSubmit: false,
};
