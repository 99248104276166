export default {
  BACK: 'BACK',
  EVENT_ANALYTICS: 'EVENT_ANALYTICS',
  LOCATION_CHANGE: '@@router/LOCATION_CHANGE',
  FETCH_TRADE_OPTIONS: 'FETCH_TRADE_OPTIONS',
  FETCH_DRAFT_TRADES: 'FETCH_DRAFT_TRADES',
  FETCH_TRADE_BLOTTER_CART_VERSION: 'FETCH_TRADE_BLOTTER_CART_VERSION',
  SAVE_DRAFT_TRADES: 'SAVE_DRAFT_TRADES',
  VERIFY_TRADES: 'VERIFY_TRADES',
  PLACE_TRADES: 'PLACE_TRADES',
  ADD_TO_TRADE_BLOTTER: 'ADD_TO_TRADE_BLOTTER',
  REMOVE_FROM_TRADE_BLOTTER: 'REMOVE_FROM_TRADE_BLOTTER',
  FETCH_ACCOUNT_INPUTS: 'FETCH_ACCOUNT_INPUTS',
  TRADE_EVENT_ANALYTICS: 'TRADE_EVENT_ANALYTICS',
  FETCH_CACHED_TRADE_OPTIONS: 'FETCH_CACHED_TRADE_OPTIONS',
  MESSAGE_CENTER_EVENT_ANALYTICS: 'MESSAGE_CENTER_EVENT_ANALYTICS',
  FETCH_SHELL_ACCOUNT_OPTIONS: 'FETCH_SHELL_ACCOUNT_OPTIONS'
};
