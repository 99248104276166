import { Component } from 'react';
import { DataGridState } from '../../redux/datagrid-state';
import { connect } from 'react-redux';
import * as Redux from 'redux';
import { Modal, ModalHeader, ModalBody } from '@gs-ux-uitoolkit-react/modal';
import { PluginBase, arrayHelper, PluginScreen } from '@gs-ux-uitoolkit-common/datacore';
import { GridWrapper } from '../../grid-wrappers/grid-wrapper';
import { SetGridConfigurationCurrentPluginName } from '../../redux/actions/grid-configuration-action';
import { modalsContainerStyleSheet } from '../../style/component/modals-container-stylesheet';
import { PluginTitleNavigation } from '../modal-container/pluginTitleNavigation';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { Button } from '@gs-ux-uitoolkit-react/button';
import { Plugins } from '../../plugins/plugin-enum';
import { GridConfigurationModalContent } from './grid-configuration-modal-content';
import { Theme, ThemeProvider } from '@gs-ux-uitoolkit-react/theme';

export interface GridConfigurationModalProps {
    currentPluginName: string | undefined;
    onHide: () => void;
    plugins: PluginBase<GridWrapper, DataGridState, object>[];
    theme: Theme;
    onUpdateSelectedPlugin: (pluginName: string | undefined) => void;
    pluginState: DataGridState['plugins'];
}

export interface GridConfigurationModalState {
    fullScreenMode: boolean;
}

export class GridConfigurationModalComponent extends Component<
    GridConfigurationModalProps,
    GridConfigurationModalState
> {
    constructor(props: GridConfigurationModalProps) {
        super(props);
        this.state = {
            fullScreenMode: false,
        };
    }

    public componentWillUnmount() {
        modalsContainerStyleSheet.unmount(this);
    }
    public render() {
        const pluginsWithScreens = this.props.plugins.filter(
            plugin =>
                plugin.getScreens().length &&
                this.props.pluginState.displayed.includes(plugin.getName())
        );

        const currentPluginName: string = this.props.currentPluginName || Plugins.ColumnHintPlugin;

        let currentPluginScreen: PluginScreen | null = null;
        const plugin = pluginsWithScreens.find(
            sortedPlugin => sortedPlugin.getName() === currentPluginName
        );
        if (plugin) {
            // if plugins have more than one screen we'll advise
            currentPluginScreen = arrayHelper.first(plugin.getScreens());
        }
        const toggleFullScreenMode = () =>
            this.setState({ fullScreenMode: !this.state.fullScreenMode });

        const cssModalClasses = modalsContainerStyleSheet.mount(this, { theme: this.props.theme });
        const bemCls = `gs-uitk-modals-container`;

        const ModalContent = GridConfigurationModalContent(pluginsWithScreens, this.props.theme);
        return (
            <ThemeProvider theme={this.props.theme}>
                <Modal
                    visible={true}
                    placement={'center'}
                    onVisibilityToggle={this.props.onHide}
                    className={`${bemCls} ${cssModalClasses.root} ${
                        this.state.fullScreenMode ? 'responsive-mode ' : ''
                    }`}
                >
                    <ModalHeader
                        className={`${bemCls}-header`}
                        onDismissButtonClick={this.props.onHide}
                    >
                        <span className={`${bemCls}-header-span`}>
                            {currentPluginScreen ? (
                                <>
                                    <PluginTitleNavigation
                                        label={
                                            this.props.currentPluginName
                                                ? currentPluginScreen.label
                                                : 'Grid Configurations'
                                        }
                                    />
                                    <span className={`${bemCls}-header-span-default-title`}>
                                        {this.props.currentPluginName &&
                                        currentPluginScreen.icon ? (
                                            <Icon
                                                className="widget-icon"
                                                {...currentPluginScreen.icon}
                                            />
                                        ) : null}
                                        {this.props.currentPluginName
                                            ? currentPluginScreen.label
                                            : 'Grid Configurations'}
                                    </span>
                                </>
                            ) : null}
                            <Button
                                emphasis="minimal"
                                onClick={() => toggleFullScreenMode()}
                                size="sm"
                            >
                                <Icon
                                    name={
                                        this.state.fullScreenMode ? 'fullscreen-exit' : 'fullscreen'
                                    }
                                    type="filled"
                                />
                            </Button>
                        </span>
                    </ModalHeader>
                    <ModalBody className={`${bemCls}-body`}>
                        <ModalContent />
                    </ModalBody>
                </Modal>
            </ThemeProvider>
        );
    }
}

export const GridConfigurationModal = (
    onHide: () => void,
    plugins: PluginBase<GridWrapper, DataGridState, object>[],
    theme: Theme
) => {
    const mapStateToProps = (
        state: DataGridState
    ): Omit<GridConfigurationModalProps, 'onUpdateSelectedPlugin'> => {
        return {
            onHide,
            plugins,
            theme,
            currentPluginName: state.gridConfiguration.currentPluginName,
            pluginState: state.plugins,
        };
    };

    const mapDispatchToProps = (
        dispatch: (action: Redux.Action) => void
    ): Pick<GridConfigurationModalProps, 'onUpdateSelectedPlugin'> => {
        return {
            onUpdateSelectedPlugin: (pluginName: string | undefined) =>
                dispatch(SetGridConfigurationCurrentPluginName(pluginName)),
        };
    };

    return connect(mapStateToProps, mapDispatchToProps)(GridConfigurationModalComponent);
};
