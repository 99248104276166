import { logger, stringHelper } from '@gs-ux-uitoolkit-common/datacore';
import { DatasourceType, GridWrapperConfiguration } from './grid-wrapper';

/**
 * it is used to validated the configuration of the gridWrapper
 * it can be simple existence test or more complex test on the validity of the values or
 * even coherency of the data
 */
export class GridWrapperConfigurationValidator {
    public static validate(gridWrapperConfiguration: GridWrapperConfiguration): boolean {
        if (gridWrapperConfiguration.datasourceConfiguration) {
            if (!gridWrapperConfiguration.datasourceConfiguration.datasourceType) {
                logger.error('Cannot find the datasourceType in the configuration');
            }
            if (
                gridWrapperConfiguration.datasourceConfiguration.datasourceType &&
                gridWrapperConfiguration.datasourceConfiguration.datasourceType ===
                    DatasourceType.DRA
            ) {
                if (!gridWrapperConfiguration.datasourceConfiguration.url) {
                    logger.error('With a DRA datasource, url should be defined');
                }
                if (!gridWrapperConfiguration.datasourceConfiguration.protocols) {
                    logger.error('With a DRA datasource, protocols should be defined');
                }
            }
        }
        if (stringHelper.isNullOrEmpty(gridWrapperConfiguration.id)) {
            logger.error('You must provide an Id for the Grid');
        }
        return true;
    }
}
