import React from 'react';
import Error from '../../containers/Error';

const ErrorPage = () => {
  return (
    <Error code='404' />
  );
};

export default ErrorPage;
