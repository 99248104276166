import { SelectOptionLeaf, SelectOptionRenderer } from './options/select-options';

/**
 * The render cache for the Select component. This cache is keyed by 3 things:
 *
 * 1) The `optionRenderer` function itself (in case multiple `optionRenderer`s are used)
 * 2) The `SelectOptionLeaf` object
 * 3) The `selected` state of the option (true or false)
 *
 * Because this cache uses WeakMaps, there is no issue of memory leaks - as soon as either the
 * `optionRenderer` function or any `option` objects are garbage collected, their corresponding
 * entries in the cache are automatically cleaned up as well.
 */
export class SelectRenderCache {
    private optionRendererMap: OptionRendererMap = new WeakMap();

    /**
     * Retrieves an element from the render cache.
     */
    public get(
        optionRenderer: SelectOptionRenderer,
        option: SelectOptionLeaf,
        selected: boolean
    ): HTMLElement | undefined {
        return this.optionRendererMap.get(optionRenderer)?.get(option)?.get(selected);
    }

    /**
     * Sets (assigns) an element into the render cache.
     */
    public set(
        optionRenderer: SelectOptionRenderer,
        option: SelectOptionLeaf,
        selected: boolean,
        element: HTMLElement
    ): void {
        let optionMap: OptionMap | undefined = this.optionRendererMap.get(optionRenderer);
        if (!optionMap) {
            optionMap = new WeakMap();
            this.optionRendererMap.set(optionRenderer, optionMap);
        }

        let selectedMap: SelectedMap | undefined = optionMap.get(option);
        if (!selectedMap) {
            selectedMap = new Map();
            optionMap.set(option, selectedMap);
        }
        selectedMap.set(selected, element);
    }
}

type OptionRendererMap = WeakMap<SelectOptionRenderer, OptionMap>;
type OptionMap = WeakMap<SelectOptionLeaf, SelectedMap>;
type SelectedMap = Map<boolean, HTMLElement>;
