import { Expression } from '@gs-ux-uitoolkit-common/datacore';

export interface RowCustomisation {
    /**
     * The column to customise, if null then entire row
     */
    columnId: string | null;
    /**
     * An Expression tree
     */
    expression: Expression;
    /**
     * Pivot keys for identifying pivoted columns that are generated
     */
    pivotKeys?: string[];
    /**
     * The style for the customisation
     */
    style: Style;
}

export interface Style {
    /**
     * Background color
     */
    backColor: string;
    /**
     * Foreground color
     */
    foreColor: string;
    /**
     * Font style
     */
    fontStyle: RowCustomisationFontStyle;
}

export interface RowCustomisationFontStyle {
    bold: boolean;
    italic: boolean;
    underline: boolean;
    /**
     * Size of the font
     */
    fontSize: FontSizes;
}

export enum FontStyles {
    Bold = 'bold',
    Italic = 'italic',
    Underline = 'underline',
}
/**
 * CSS Font Sizes
 */
export enum FontSizes {
    NotOveridden = '',
    XXSmall = 'xx-small',
    XSmall = 'x-small',
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
    XLarge = 'x-large',
    XXLarge = 'xx-large',
}

export const EntireRowLabel = 'Entire Row';
