const validateId = (id, selectorName) => {
  if(!id){
    console.warn(`Looks like you forgot to pass ID while calling ${selectorName} selector`)
  }
}

// TAKE ALL THE KEYS FROM STORE
export const groupBy = ({modules}, id) => {
  validateId(id, 'groupBy');
  return modules[id].groupBy;
}
export const allViewsSelector = ({modules}, id) => {
  validateId(id, 'allViewsSelector');
  return modules[id].views;
}

export const groupColumns = ({modules}, id) => {
  validateId(id, 'groupColumns');
  return modules[id].groupColumns
}

export const columnsConfigs = ({ modules }, id) => {
  validateId(id, 'columnsConfigs');
  return modules[id].columnsMetadata;
};

export const columnsMetadata = ({modules}, id) => {
  validateId(id, 'columnsMetadata');
  return modules[id].columnsMetadata.columns
}

const defaultData = [];
export const gridData = ({modules}, id) => {
  validateId(id, 'gridData');
  return modules[id].gridData.data || defaultData;
}

export const gridError = ({modules}, id) => {
  validateId(id, 'gridError');
  return modules[id].gridData.error
}

export const isLoading = ({modules}, id) => {
  validateId(id, 'isLoading');
  return modules[id].loading
}

export const startExcelExport = ({modules}, id) => {
  validateId(id, 'exportExcel');
  return modules[id].exportExcel
}

export const filterVisibility = ({modules}, id) => {
  validateId(id, 'filterVisibility');
  return modules[id].filterVisibility
}

export const getFilterModel = ({modules}, id) => {
  validateId(id, 'getFilterModel');
  return modules[id].filterModel
}

export const getFilters = ({modules}, id) => {
  validateId(id, 'getFilters');
  return modules[id].filterBy
}

export const getFilteredDataLength = ({modules}, id) => {
  validateId(id, 'getFilteredDataLength');
  return modules[id].filteredDataLength
}

export const idSelector = (state, id) => {
  validateId(id, 'idSelector');
  return id;
}

export const getLastEvent = ({modules}, id) => {
  validateId(id, 'getLastEvent');
  return modules[id].gridData.event
}

export const groupByFieldNew = ({modules}, id) => {
  validateId(id, 'groupByFieldNew');
  const activeView = modules[id].views.find(view => view.isActive) || {};
  return activeView.groupBy || 'none';
}

export const getFilterInErrorState = ({modules}, id) => {
  validateId(id, 'getFilterInErrorState');
  return modules[id].filterInErrorState
}

export const filteredDataLength = ({modules}, id) => {
  validateId(id, 'filteredDataLength');
  return modules[id] &&  modules[id].filteredDataLength
}

export const filterInErrorState = ({modules}, id) => {
  validateId(id, 'filterInErrorState');
  return modules[id] &&  modules[id].filterInErrorState
}

export const dataMappingSelector = ({modules}, id) => {
  validateId(id, 'dataMappingSelector');
  return modules[id] &&  modules[id].dataMapping
}

export const openCustomViewModal = ({modules}, id) => {
  validateId(id, 'openCustomViewModal');
  return modules[id] &&  modules[id].isOpenCustomViewModal
}

export const modelActiveMode = ({modules}, id) => {
  validateId(id, 'modelActiveMode');
  return modules[id] &&  modules[id].modelActiveMode
}

export const selectedViewId = ({modules}, id) => {
  validateId(id, 'selectedViewId');
  return modules[id] &&  modules[id].selectedViewId
}

export const modalLoading = ({modules}, id) => {
  validateId(id, 'isModalLoading');
  return modules[id] &&  modules[id].isModalLoading
};
