import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {EXTERNAL_LINK_SKIP_ATTRIBUTE} from "../../../constants/pageConstants";

const analytics = { "analytics-attribute": "pref_nav_link" };

const ControlPanel = ({config = [], currentPageId}) => {
  const [currentTab, setCurrentTab] = useState("");

  const onTabClick = (event, id) => {
    if (currentTab === id) return;
    setCurrentTab(id);
  };

  return(
    <div className="control-panel">
      {
        config.map(({id, exact, path, label, routingPathId}) => {
          const isTabMatched = (currentPageId === id) || (currentTab === id);
          return (
            <NavLink
              exact={exact}
              key={routingPathId}
              to={{
                pathname: path,
                state: {pageId: currentPageId || id}
              }}
              {...analytics}
              onClick={(e) => onTabClick(e, id)}
              isActive={() => isTabMatched}
              className={cn({"control-panel__action": !isTabMatched})}
              activeClassName="control-panel__action--disabled"
              {...{[EXTERNAL_LINK_SKIP_ATTRIBUTE]: true}}
            >
              {label}
            </NavLink>
          );
        })
      }
    </div>
  );
};

ControlPanel.propTypes = {
  config: PropTypes.object,
  currentPageId: PropTypes.string
};

export default ControlPanel;

