import React from "react";
import PropTypes from "prop-types";
import {Button} from "@gs-ux-uitoolkit-react/button";

const AddEditSaveButtons = (
  {
    toggleEditSave,
    rebateAmountExists,
    saveButtonDisabled,
    isMimicSession,
    labels,
    onEditClick,
    onCancelClick,
    onSaveClick
  }) => {

  const {tkCancel, tkSave, tkEdit, tkAddRebate} = labels;

  if (toggleEditSave) {
    return (
      <>
        <Button
          className="cancel-button"
          onClick={onCancelClick}
          actionType="secondary"
        >
          {tkCancel}
        </Button>
        <Button
          disabled={saveButtonDisabled}
          onClick={onSaveClick}
          actionType="primary"
        >
          {tkSave}
        </Button>
      </>
    );
  }

  if (rebateAmountExists) {
    return (
      <Button onClick={onEditClick} actionType="secondary" disabled={isMimicSession}>
        {tkEdit}
      </Button>
    );
  }
  return (
    <Button onClick={onEditClick} actionType="secondary" disabled={isMimicSession}>
      {tkAddRebate}
    </Button>
  );
};

AddEditSaveButtons.propTypes = {
  toggleEditSave: PropTypes.bool,
  rebateAmountExists: PropTypes.bool,
  saveButtonDisabled: PropTypes.bool,
  isMimicSession : PropTypes.bool,
  labels: PropTypes.object,
  onEditClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  onSaveClick: PropTypes.func
};


export default AddEditSaveButtons;
