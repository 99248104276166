import { Properties } from 'csstype';
import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './box-theme-overrides';

export type BoxStyleSheetProps = {
    theme: Theme;
    styles: Properties;
};

export interface BoxCssClasses {
    root: string;
}

export type BoxStyledClasses = CssClassDefinitionsObject<keyof BoxCssClasses>;

export interface BoxStyleOverridesParams {
    props: DeepReadonly<BoxStyleSheetProps>;
    createDefaultStyledClasses: () => BoxStyledClasses;
}

export const boxStyleSheet = new StyleSheet('box', (props: BoxStyleSheetProps) => {
    return createComponentClassDefinitions<BoxStyleSheetProps, BoxStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.box
    );
});

function createDefaultStyledClasses({ styles }: BoxStyleSheetProps): BoxStyledClasses {
    return {
        root: {
            ...styles,
        },
    };
}
