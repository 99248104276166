import React from 'react';
import PropTypes from 'prop-types';
import {ItemRenderer} from "./ItemRenderer";

const getChildNodes = ({items}) => {
  if (!items) return [];
  return items.map(item => item);
};

const TreeNode = (props) => {
  const {
    node,
    level = 0,
    indentationPadding,
    metricOptions,
    onToggleSelectThresholdItem,
    onViewThreshold,
    onDeleteThreshold,
    onExpandCollapseThresholdGroup,
    hasAtleastOneParentNode
  } = props;

  const childNodes = node.open && getChildNodes(node) || [];

  return (
    <React.Fragment>
      <ItemRenderer
        indentationPadding={indentationPadding}
        level={level}
        metricOptions={metricOptions}
        node={node}
        hasAtleastOneParentNode={hasAtleastOneParentNode}
        onDeleteThreshold={onDeleteThreshold}
        onToggleSelectThresholdItem={onToggleSelectThresholdItem}
        onViewThreshold={onViewThreshold}
        onExpandCollapseThresholdGroup={onExpandCollapseThresholdGroup}
      />
      {
        childNodes.map((childNode, index) => (
          <TreeNode
            {...props}
            node={childNode}
            level={level + 1}
            key={index}
          />
        ))
      }
    </React.Fragment>
  );
};

TreeNode.propTypes = {
  node: PropTypes.object.isRequired,
  level: PropTypes.number,
  onToggle: PropTypes.func.isRequired,
  indentationPadding: PropTypes.number,
  metricOptions: PropTypes.array,
  onToggleSelectThresholdItem: PropTypes.func,
  onViewThreshold: PropTypes.func,
  onDeleteThreshold: PropTypes.func,
  onExpandCollapseThresholdGroup: PropTypes.func,
  hasAtleastOneParentNode: PropTypes.bool
};

TreeNode.defaultProps = {
  level: 0
};

export default TreeNode;
