import { PluginBase, PluginIcon } from '@gs-ux-uitoolkit-common/datacore';
import { ModuleIdentfier } from '../module-identfier';
import { GridWrapper } from '../../grid-wrappers/grid-wrapper';
import { DataGridState, GridConfigurationState } from '../../redux/datagrid-state';
import { DataGridToolbarItem } from '../../toolbar-state';
import { Categories, Plugins } from '../plugin-enum';
import {} from 'gs-uitk-lodash';
import { SizeAndDensityWidget } from './view/react/size-density-plugin-widget';

const sizeAndDensityIcon: PluginIcon = { name: 'text-fields', type: 'filled' };

/**
 * The Size and Density plugin. Allows the developers and end-users to change size and density of the grid.
 */
export class SizeAndDensityPlugin extends PluginBase<
    GridWrapper,
    DataGridState,
    GridConfigurationState
> {
    protected static requiredModules: ModuleIdentfier[] = [];
    constructor(wrapper: GridWrapper) {
        super(Plugins.SizeAndDensityPlugin, Categories.Data, sizeAndDensityIcon, wrapper, state => {
            return { ...state.gridConfiguration };
        });
        this.widgets = [
            {
                componentId: 'SizeAndDensityWidget' as DataGridToolbarItem,
                hideIcon: true,
                icon: sizeAndDensityIcon,
                label: '',
                name: 'Size And Density Widget',
                maxWidth: 24,
                minWidth: 24,
                width: 24,
                store: wrapper.getReduxStore(),
                context: wrapper.getReduxStoreContext(),
                widget: SizeAndDensityWidget(wrapper.getReduxStoreContext()),
            },
        ];
    }

    protected internalStop(): void {}

    protected stateChangedOrStart(): void {}
}
