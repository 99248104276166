import { PluginBase, PluginIcon } from '@gs-ux-uitoolkit-common/datacore';
import { ModuleIdentfier } from '../module-identfier';
import { debounce } from 'gs-uitk-lodash';
import { GridColumn, GridWrapper } from '../../grid-wrappers/grid-wrapper';
import { AutofitPivotState, DataGridState } from '../../redux/datagrid-state';
import { Categories, Plugins } from '../plugin-enum';

const mainIcon: PluginIcon = {
    name: 'arrow-left-right',
    type: 'filled',
};

/**
 * The Autofit Pivot plugin. Allows the developers and end-users to auto size the column pivot
 */
export class AutofitPivotPlugin extends PluginBase<GridWrapper, DataGridState, AutofitPivotState> {
    protected static requiredModules: ModuleIdentfier[] = [ModuleIdentfier.RowGroupingModule];
    // we debounce it for two reason :
    // 1. autofit cost performance
    // 2. when DRA expand, we first get the data of teh field being expanded then only in a second batch of updates we get the children
    private debouncedOnRowGroupFunc = debounce(() => this.onRowGroup(), 500);

    constructor(wrapper: GridWrapper) {
        super(
            Plugins.AutofitPivotPlugin,
            Categories.Data,
            mainIcon,
            wrapper,
            state => state.autofitPivot
        );
    }

    protected stateChangedOrStart(): void {
        this.unsubscribeEvents();
        const pluginState = this.getPluginState();
        if (pluginState && pluginState?.enabled) {
            const { pivotMin, pivotMax, resizeOnExpandOnly } = pluginState;

            this.getPivotColumns().forEach(col => {
                if (pivotMin !== null) {
                    this.wrapper.setColumnMinWidth(col.columnId, pivotMin);
                }
                if (pivotMax !== null) {
                    this.wrapper.setColumnMaxWidth(col.columnId, pivotMax);
                }
            });

            // when in DRA mode, the DRA-ViewPort-Datasource sets the node to be expanded/collapsed which then raises the ag-grid event
            // We only care about the open events for resizeOnExpandOnly
            if (resizeOnExpandOnly) {
                this.wrapper.rowGroupExpanded.subscribe(this.debouncedOnRowGroupFunc);
            } else {
                this.wrapper.rowGroupCollapseChanged.subscribe(this.debouncedOnRowGroupFunc);
            }

            // we listen for changes in the rowgroup as if people add or switch pivot the valueList of the pivot will change
            this.wrapper.rowGroupChanged.subscribe(this.debouncedOnRowGroupFunc);

            // sometimes data is rendered by the time we call to autofit the pivot. Here we wait until the first data is rendered to autofit on that
            this.wrapper.firstDataRendered.subscribe(this.debouncedOnRowGroupFunc);

            this.debouncedOnRowGroupFunc();
        }
    }

    protected internalStop(): void {
        this.unsubscribeEvents();
    }

    private unsubscribeEvents() {
        this.wrapper.rowGroupCollapseChanged.unsubscribe(this.debouncedOnRowGroupFunc);
        this.wrapper.rowGroupChanged.unsubscribe(this.debouncedOnRowGroupFunc);
        this.wrapper.rowGroupExpanded.unsubscribe(this.debouncedOnRowGroupFunc);
        this.wrapper.firstDataRendered.unsubscribe(this.debouncedOnRowGroupFunc);
        this.debouncedOnRowGroupFunc.cancel();
    }

    private getPivotColumns(): GridColumn[] {
        return this.wrapper
            .getReduxStore()
            .getState()
            .grid.columnList.filter(col => col.isRowGroup);
    }

    private onRowGroup(): void {
        this.getPivotColumns().forEach(col => {
            this.wrapper.autofitColumn(col.columnId);
        });
    }
}
