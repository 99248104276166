export const config = {
  firm: {
    'headerKey': 'tkFirmBranch',
    'isKeyField': true,
    'validationRules': {
      'UNABLE_TO_FETCH_FIRMS_AND_BRANCHES': true
    },
    'fieldWidth': '7.4%'
  },
  account: {
    'headerKey': 'tkAccount',
    'isKeyField': true,
    'validationRules': {
      'TA_ACCOUNT_NOT_FOUND': true,
      'UNABLE_TO_FETCH_ACCOUNTS': true,
      'ACCOUNT_BIN_MISSING': true
    },
    'fieldWidth': '9.3%'
  },
  shareClass: {
    'headerKey': 'tkFund',
    'isKeyField': true,
    'validationRules': {
      'REDEMPTION_GATE_WARNING': true,
      'IS_SUSPENSION_ACTIVE_ERROR': true,
      'TRADEBLOTTER_9682': true,
      'EU_REDEMPTION_GATE_WARNING': true,
      'UNABLE_TO_FETCH_SHARECLASSES': true,
      'TRADEBLOTTER_200010': true
    },
    'fieldWidth': '10.6%'
  },
  tradeDate: {
    'headerKey': 'tkTradeDate',
    'validationRules': {
      'TRADEBLOTTER_2999': true,
      'TRADE_DATE_AUTO_UPDATE_WARNING': true,
      'TRADE_DATE_CUTOFF_WARNING': true,
      'TRADE_DATE_NOTICE_INFO': true,

    },
    'fieldWidth': '8%',
    'associatedFields': ['tradeType', 'amountType', 'settlementCycle']
  },
  settlementCycle: {
    'headerKey': 'tkSettlement',
    'fieldWidth': '6.3%',
    'associatedFields': ['tradeType', 'amountType']
  },
  tradeType: {
    'headerKey': 'tkTradeType',
    'fieldWidth': '8.3%',
    'associatedFields': ['amountType', 'tradeAmount', 'paymentInstructionId']
  },
  amountType: {
    'fieldWidth': '6.5%',
    'headerKey': 'tkAmountType',
    'associatedFields': ['tradeAmount']
  },
  tradeAmount: {
    'headerKey': 'tkTradeAmount',
    'decimalPrecisionKey': 'tradeAmountDecimalPrecision',
    'dpPrecision': {
      'shares': '3',
      'currency': '2'
    },
    'fieldWidth': '11.3%',
    'valid': {
      'amountType': 'all'
    },
    'validationRules': {
      'TRADEBLOTTER_9611': true,
      'TRADEBLOTTER_9610': true,
      'TRADEBLOTTER_9619': true,
      'TRADEBLOTTER_9629': true,
      'TRADEBLOTTER_9618': true,
      'TRADEBLOTTER_2001': true,
      'TRADEBLOTTER_2002': true,
      'TRADEBLOTTER_2004': true,
      'TRADEBLOTTER_2005': true,
      'TRADEBLOTTER_9678': true,
      'TRADEBLOTTER_9684': true,
      'MAX_TRADE_AMOUNT_ERROR': true
    }
  },
  paymentInstructionId: {
    'headerKey': 'tkSettlementInst',
    'validationRules': {
      'TRADEBLOTTER_22047': true
    },
    'fieldWidth': '11.2%',
    'valid': {
      'tradeType': 'purchase'
    }
  },
  homeAwayType: {
    'headerKey': 'tkHomeAway',
    'fieldWidth': '4.6%'
  }
};


export const configKeys = ['firm', 'account', 'shareClass', 'tradeDate', 'settlementCycle', 'tradeType', 'amountType', 'tradeAmount', 'paymentInstructionId', 'homeAwayType'];

export const tradeRowValidationRules = ['NAV_UNAVAILABLE_ERROR', 'MODIFY_NEW_ACCOUNT_INFO'];
