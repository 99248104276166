import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Conditional } from "../Conditional";
import { EDIT_ERROR, EDIT_SUCCESS } from "./constants";
import translator from "../../../services/translator";
import "./index.scss";

const { translate: t } = translator;

const Toast = ({ type, autoHide, onClear, displayText, classNameProp, showClose }) => {
  let timer = null;
  const [hideToast, setHideToast] = useState(false);

  const hideAfterNSeconds = (autoHide = true) => {
    if (autoHide) {
      timer = setTimeout(() => {
        setHideToast(true);
        onClear();
      }, 10000);
    }
  };

  useEffect(() => {
    clearTimeout(timer);
    hideAfterNSeconds(autoHide);
    return () => {
      clearTimeout(timer);
    };
  }, [type, timer]);

  const onCloseToast = async (e) => {
    e.stopPropagation();
    setHideToast(true);
    onClear();
  };

  return (
    <Conditional condition={!hideToast}>
      <div data-testid="toast-container" className={`toast ${type} ${classNameProp} `}>
        <div data-testid="icon-container" className="toast__left">
          <span data-testid={`${type}-icon`} className={`${type}-icon`} />
        </div>
        <div data-testid="text-content" className="toast__right">{t(displayText)}</div>
        {showClose && type === EDIT_ERROR && (
          <div data-testid="close-icon" onClick={onCloseToast} className="toast__close">
            <span className="toast__icon">X</span>
          </div>
        )}
      </div>
    </Conditional>
  );
};

Toast.defaultProps = {
  type: EDIT_SUCCESS,
  autoHide: true,
  displayText: '',
  classNameProp: '',
  showClose: true,
  onClear: () => {}
};

Toast.propTypes = {
  type: PropTypes.string,
  autoHide: PropTypes.bool,
  onClear: PropTypes.func,
  displayText: PropTypes.string,
  classNameProp: PropTypes.string,
  showClose: PropTypes.bool
};

export default Toast;
