import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

export const simpleRenderer= ({option, hoverOption, isHovered, selectOption, labelKey, valueKey, selectedLabel}) => {
  const isSelected = selectedLabel === option[labelKey];
  return (
    <div
      key={option[valueKey]}
      className={cn('trade-dropdown__row', {'trade-dropdown__row--selected': isSelected}, {'trade-dropdown__row--hovered': isHovered})}
      onKeyPress={hoverOption}
      onMouseDown={() => selectOption(option)}>
      {option[labelKey]}
    </div>
  );
};

simpleRenderer.propTypes = {
  hoverOption: PropTypes.func,
  option: PropTypes.object,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  selectOption: PropTypes.func,
  selectedLabel: PropTypes.string,
  isHovered: PropTypes.bool
};
