import {useEffect} from 'react';

 function useWindowScroll(callback) {
  useEffect(() => {
    const handleClick = (event) => {
      event.stopPropagation();
      callback(event);
      return false;
    };
    window.addEventListener('scroll', handleClick);
    return () => {
      window.removeEventListener('scroll', handleClick);
    };
  }, []);
}

export default useWindowScroll;
