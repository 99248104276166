/* eslint-disable */
// TODO : Need to fix es-lint
import { regex as regexMatchers } from '../../helpers/regex';
export const INVALID = { isValid: false };
export const VALID = { isValid: true };


export const validators = {
  regex: ({
    optional, rule, requiredRule, invalidRule
  }, val) => {
    if (!optional && !val.trim()) {
      return { ...INVALID, error: requiredRule ? 'invalid' : 'required'};
    }
    const returnvalue = val.match(regexMatchers[rule] || rule) ?
      VALID :
      { ...INVALID, error: invalidRule ? 'invalid' : 'invalid' };
    return returnvalue;
  },

};

export const isInvalidFilter = function (filters, ruleFor = 'portfolioInputText'){
  let isInvalid = false;

  for(const item in filters){
    if(filters.hasOwnProperty(item)){
      const filterObj = validators.regex({optional: true, rule: ruleFor, requiredRule: null,
        invalidRule:null}, filters[item].filter);
      if(!filterObj.isValid){
        return true;
      }
    }
  }

  return isInvalid;
};
