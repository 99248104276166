import { Dispatch, SetStateAction, ChangeEvent } from 'react';
import { Instance } from 'flatpickr/dist/types/instance';

export const useDatePickerInputCallbacks = ({
    flatpickrInstance,
    setInputValue,
}: {
    flatpickrInstance: Instance | null;
    setInputValue: Dispatch<SetStateAction<string>>;
}) => {
    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };
    const onClearClick = () => {
        if (flatpickrInstance) {
            flatpickrInstance.clear();
            Promise.resolve().then(() => flatpickrInstance!.close());
        }
    };
    return {
        onInputChange,
        onClearClick,
    };
};
