import { StyleSheet, CssClasses } from '@gs-ux-uitoolkit-react/style';
import { colors } from '@gs-ux-uitoolkit-common/design-system';

export type ListSelectorCssClasses = CssClasses<typeof listSelectorStyleSheet>;

export const listSelectorStyleSheet = new StyleSheet('listSelector', () => {
    return {
        root: {
            '.list-selector-search-input': {
                width: '100%',
                margin: ' 6px 0px',
                color: colors.gray060,
                border: `1px solid ${colors.gray040}`,
                borderRadius: '2px',
            },
            '.list-selector-selected-row': {
                display: 'block',
                //important because ag-grid sets it to be 100%
                maxHeight: '7.3rem !important',
                overflowY: 'auto',
                marginBottom: '2px',
            },
            '.list-selector': {
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                height: '100%',
            },
            '.list-selector-values-row': {
                display: 'block',
                flex: 1,
                overflow: 'auto',
                '.list-group': {
                    paddingInlineStart: '2px',
                },
            },
        },
    };
});
