import flatten from 'lodash/flatten';
import { EXCEL_DATA_TYPE_MAPPER } from '../components/core/Grid/constants';

export const printList = (columns, key) => {
  let stringToPrint = '';
  columns.forEach(column => {
    stringToPrint = `${stringToPrint} ${column.colId} = ${column[key]},`;
  });
  return stringToPrint.substring(0, stringToPrint.length - 1);
};

export const printFilter = (columns) => columns.map(column => column.colId).join(' ');

export const sortList = (columns) => columns.map(
  column => `${column.colId} : ${column.sort === 'asc' ? 'Ascending' : 'Descending'}`
).join(' ');

export const splitter = (item, l) => {
  let str = item.line;
  const strArray = [];
  while(str.length > l){
    let pos = str.substring(0, l).lastIndexOf(' ');
    pos = pos <= 0 ? l : pos;
    strArray.push({line: str.substring(0, pos), bold: item.bold});
    let i = str.indexOf(' ', pos) + 1;
    if(i < pos || i > pos+l)
      i = pos;
    str = str.substring(i);
  }
  strArray.push({line: str, bold: item.bold});
  return strArray;
};

export const  stripHtml = (html) => {
  const newHtml = html.split('<br clear="none">');
  let newText = newHtml.map(h => {
    const doc = new DOMParser().parseFromString(h, 'text/html');
    const bold = h.startsWith('<b>') && h.endsWith('</b><br>');
    return { line : doc.body && doc.body.textContent.trim() || '', bold};
  });
  newText = newText.filter(item => { return item.line !== '';});
  if(newText.length < 1){
    newText.push({line : '', bold: false});
  }
  newText = newText.map(element => splitter(element, 227));
  newText = flatten(newText);
  return newText.map(t => ([{
    styleId: t.bold ? 'bold-footer-style': 'footer-style',
    data: {
      type: 'String',
      value: t.line || ''
    },
    mergeAcross: 5
  }]));
};

/**
 * Given a list of objects containing excel cell items, fn presents the data
 * in a way that is exploitable by excel export api using ExcelRow[] format.
 */
export const formatToExcelRowData = items => {
  if (items != null) {
    let itemsArray = items;
    if (!Array.isArray(items)) {
      itemsArray = [items];
    }
    return itemsArray.map(({ value, line, mergeAcross, bold }) => ({
      cells: [
        {
          data: {
            type: EXCEL_DATA_TYPE_MAPPER[typeof value] || EXCEL_DATA_TYPE_MAPPER.string,
            // we will either have value of line, not both
            value: value || line || '',
          },
          // when line is provided, this is html text, so we add mergeAcross to 8 (previously used to be 5)
          mergeAcross: line != null ? 6 : mergeAcross,
          styleId: bold ? 'bold-footer-style' : 'userinfo-font-style',
        },
      ],
    }));
  }
  return [];
};

/**
 * Will format html text and oprepare for footer on excel export
 * Note: This is a variation of `stripHtml` and is built for Grid
 */
export const handleHtml = (html) => {
  const newHtml = html.split('<br clear="none">');
  let newText = newHtml.map(h => {
    const doc = new DOMParser().parseFromString(h, 'text/html');
    const bold = h.startsWith('<b>') && h.endsWith('</b><br>');
    return { line : doc.body && doc.body.textContent.trim() || '', bold};
  });
  newText = newText.filter(item => { return item.line !== '';});
  if(newText.length < 1){
    newText.push({line : '', bold: false});
  }
  newText = newText.map(element => splitter(element, 227));
  newText = flatten(newText);
  return formatToExcelRowData(newText);
};

export const footerConfig = (arrayOfLinesAndSpanAcross) => {
  const val = arrayOfLinesAndSpanAcross.map(t => ([{
    styleId:  t.bold ? 'bold-footer-style': 'userinfo-font-style',
    data: {
      type: 'String',
      value: t.value || ''
    },
    mergeAcross: t.mergeAcross
  }]));
  return val;
};
