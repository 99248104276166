export default {
  FETCH_TRADE_APPROVAL_FLAG: {
    'path': '/api/v1/tradeActivity/trades/pending-approval',
    'method': 'POST'
  },
  FETCH_TRADE_APPROVAL_DETAILS: {
    'path': '/api/v1/tradeActivity/trades/pending-approval/firms/firmIds',
    'method': 'POST'
  },
  FETCH_CURRENT_TRADE_DETAILS: {
    'path': '/api/v1/tradeActivity/trades/active/firms/firmIds',
    'method': 'POST'
  },
  APPROVE_TRADE_ACTIVITY_TRADES: {
    'path': '/api/v1/tradeActivity/trades/approve',
    'method': 'POST'
  },
  REJECT_TRADE_ACTIVITY_TRADES: {
    'path': '/api/v1/tradeActivity/trades/reject',
    'method': 'POST'
  }
}
