import React from 'react';
import {Conditional} from "../../Conditional";
import './overlays.scss';

export const CustomNoRowsMsgOverlay = (prop) => {
  return (
    <div className='noRowData'>
      {prop.noRowMsg}
      <Conditional condition={(prop.pageId === 'MyReports' && !prop.hasServiceErrorOccurred)}>
        <span className='reportsLink' onClick={prop.handleClickNoRows}>{`${prop.reportsTab}.`}</span>
      </Conditional>
    </div>
  );
}
