import { ReactNode, Children } from 'react';

/**
 * This function wraps React children that are text nodes in span elements so that they can be styled.
 *
 * Text nodes cannot be targeted with CSS selectors.
 * Button text needs to be targetable, since we position the button contents differently
 * depending on whether there is text-only, icon-only, icon before text, and icon after text.
 * There would be no way to determine whether there was text present in the button if we did not wrap the
 * text in span tags (which are targetable).
 *
 * @param children React children whose text nodes will be wrapped in span elements
 */
export const wrapTextChildrenInSpan = (children: ReactNode) =>
    Children.map(children, child => {
        return typeof child === 'string' ? <span>{child}</span> : child;
    });
