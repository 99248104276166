import { PluginBase, PluginIcon } from '@gs-ux-uitoolkit-common/datacore';
import { ModuleIdentfier } from '../module-identfier';
import { GridWrapper } from '../../grid-wrappers/grid-wrapper';
import { DataGridState } from '../../redux/datagrid-state';
import { Categories, Plugins } from '../plugin-enum';
import { ExportModal } from './view/exports-plugin-modal';
import { getExportWidget } from './view/export-widget';
import { OpenGridConfiguration } from '../../redux/actions/grid-configuration-action';
import { DataGridToolbarItem } from '../../toolbar-state';

const mainIcon: PluginIcon = { name: 'download', type: 'filled' };
const widgetIcon: PluginIcon = { name: 'download', type: 'outlined' };

/**
 * The Exports plugin allows the developers and end-users export their grid to various formats
 */
export class ExportsPlugin extends PluginBase<GridWrapper, DataGridState, object> {
    protected static requiredModules: ModuleIdentfier[] = [
        ModuleIdentfier.CsvExportModule,
        ModuleIdentfier.ExcelExportModule,
    ];
    constructor(wrapper: GridWrapper) {
        super(Plugins.ExportPlugin, Categories.Data, mainIcon, wrapper, state => state.export);
        this.actions = [
            {
                action: OpenGridConfiguration(Plugins.ExportPlugin),
                componentId: 'ExportShortcut' as DataGridToolbarItem,
                icon: mainIcon,
                label: 'Export',
                store: wrapper.getReduxStore(),
                context: wrapper.getReduxStoreContext(),
            },
        ];
        this.screens = [
            {
                componentId: 'ExportModal',
                icon: mainIcon,
                label: 'Export',
                screen: ExportModal,
                store: wrapper.getReduxStore(),
                context: wrapper.getReduxStoreContext(),
            },
        ];
        this.widgets = [
            {
                componentId: 'ExportWidget' as DataGridToolbarItem,
                hideIcon: true,
                icon: widgetIcon,
                label: '',
                // TODO: Size is no longer fixed as of UX-13382. If the Toolbar size is medium, the
                //  icon width should be 32. Because the size prop is passed directly to the widget
                //  content element, it's difficult to specify it here in the higher order
                //  component. We still shouldn't hard code it, but it requires improvement to the
                //  width calculation logic in {@link Widget} (as of writing, results in 179 px).
                maxWidth: 24,
                minWidth: 24,
                name: 'Export Widget',
                store: wrapper.getReduxStore(),
                context: wrapper.getReduxStoreContext(),
                widget: getExportWidget(wrapper.getReduxStoreContext()),
                width: 24,
            },
        ];
    }

    protected internalStop(): void {
        // to be done
    }
    protected stateChangedOrStart(): void {
        return;
    }
}
