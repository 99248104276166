import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty, memoize } from 'lodash';
import { chartReducerInitialValue, chartReducer } from '../../reducers/ChartReducer';
import { useSafeDispatch } from '../../reducers/Hooks';
import {ChartContext} from '../index';
import {FUND_TRACKER} from '../../../../constants/pageConstants';


const getChartInitialValue = memoize((shareclassIdsFromPreference) => {
  if (isEmpty(shareclassIdsFromPreference)) {
    return chartReducerInitialValue;
  }
  const [primaryShareClassId, ...restOfShareclassIds] = shareclassIdsFromPreference;
  const cloneInitialValue = cloneDeep(chartReducerInitialValue);
  cloneInitialValue.primaryShareClassId = primaryShareClassId;
  if (restOfShareclassIds && restOfShareclassIds.length > 0) {
    cloneInitialValue.benchmarks = cloneDeep(restOfShareclassIds);
    cloneInitialValue.selection = FUND_TRACKER.BENCHMARKS;
  }
  return cloneInitialValue;
});

export const ChartContextProvider = ({children, shareclassIdsFromPreference}) => {
  const [ state, dispatch ] = React.useReducer(chartReducer, getChartInitialValue(shareclassIdsFromPreference));
  const safeDispatch = useSafeDispatch(dispatch);
  const providerValue = useMemo(() => ({state, dispatch: safeDispatch}));
  return (
    <ChartContext.Provider value={providerValue}>
      {children}
    </ChartContext.Provider>
  );
};

ChartContextProvider.propTypes = {
  children: PropTypes.object,
  shareclassIdsFromPreference: PropTypes.array
};

export default ChartContextProvider;
