import { useContext, FC, ReactNode, CSSProperties, memo } from 'react';
import PropTypes from 'prop-types';
import {
    listGroupItemStyleSheet,
    ListGroupSize,
    listGroupItemDefaultProps,
} from '@gs-ux-uitoolkit-common/list-group';
import { ComponentProps } from '@gs-ux-uitoolkit-react/component';
import { cx, useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { ListGroupContext } from './list-group-context';

export interface ListGroupItemHeadingProps extends ComponentProps<CSSProperties> {
    children?: ReactNode;
}

export const ListGroupItemHeading: FC<ListGroupItemHeadingProps> = memo(props => {
    const { className, children, ...attributes } = props;

    const theme = useTheme();
    const sizeContext: ListGroupSize = useContext(ListGroupContext).size;
    const dragAndDrop = useContext(ListGroupContext).dragAndDrop;
    const cssClasses = useStyleSheet(listGroupItemStyleSheet, {
        theme,
        size: sizeContext,
        dragAndDrop,
        action: listGroupItemDefaultProps.action,
        active: listGroupItemDefaultProps.active,
        disabled: listGroupItemDefaultProps.disabled,
    });
    return (
        <div
            data-gs-uitk-component="list-group-item-heading"
            {...attributes}
            className={cx(cssClasses.itemHeading, className)}
        >
            {children}
        </div>
    );
});
ListGroupItemHeading.displayName = 'ListGroupItemHeading';

ListGroupItemHeading.propTypes = {
    className: PropTypes.string,
};
