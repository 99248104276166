import { CssClasses, CssProperties } from '../types';
import { interpolationRe } from './interpolation-regex';
import { isNestedPropertiesObj } from './util';

/**
 * Given a CssProperties object with nested selectors that may refer to other
 * CSS classes in the StyleSheet (using the `#{className}` syntax), this
 * function interpolates the selectors with the actual class name.
 *
 * The `mountedCssClasses` object holds the full computed class that Emotion has
 * injected into the DOM so that we can interpolate the computed CSS class into
 * the nested selectors.
 *
 * We can assume that all interpolated CSS classes (`#{className}`) exist as
 * keys in the `mountedCssClasses` object by the time this function is called,
 * as all of the class injection order and checking has already been done.
 */
export function interpolateNestedSelectors<ClassNames extends string>(
    cssProperties: CssProperties,
    mountedCssClasses: CssClasses<ClassNames>
): CssProperties {
    const keys = Object.keys(cssProperties);

    // First, find if there are any nested selectors with interpolation (i.e. '#{someClass}').
    // If not, we'll want to return the same CssProperties object that was passed into this function
    // so we don't allocate any new objects or do any copying
    let i = 0;
    const keysLen = keys.length;
    for (; i < keysLen; i++) {
        const key = keys[i];
        const value = cssProperties[key];

        if (isNestedPropertiesObj(value)) {
            if (interpolationRe.test(key)) {
                // found interpolation - will need to create a new CssProperties object (below)
                break;
            } else {
                // nested selector but no interpolation: handle interpolation in nested object
                cssProperties[key] = interpolateNestedSelectors(value, mountedCssClasses);
            }
        }
    }
    if (i === keysLen) {
        // No nested selectors with interpolation found - return original object
        return cssProperties;
    }

    // Create a new object for the result and copy in properties. We don't want to
    // mutate the original object by deleting properties on it because that would
    // cause VM deoptimizations
    const result: CssProperties = {};

    // Copy non-interpolation-selector properties in first (any keys we already checked above to be
    // non-interpolation selectors)
    for (let j = 0; j < i; j++) {
        const key = keys[j];
        result[key] = cssProperties[key];
    }

    // And now start processing interpolation nested objects from the first interpolation properties
    // object we found
    for (let j = i; j < keysLen; j++) {
        const key = keys[j];
        const value = cssProperties[key];

        if (!isNestedPropertiesObj(value)) {
            // non-nested properties object, copy directly
            result[key] = cssProperties[key];
        } else {
            const selector = key; // for clarity

            // Interpolate the computed CSS class.
            // Ex: '#{class1}' --> '.gs-uitk-c-123456--alert-container'
            const newSelector = selector.replace(interpolationRe, (_match, className) => {
                return `.${mountedCssClasses[className as ClassNames]}`;
            });

            // Recurse if needed and assign to the new selector
            result[newSelector] = interpolateNestedSelectors(value, mountedCssClasses);
        }
    }
    return result;
}
