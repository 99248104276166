import { ComponentProps } from '@gs-ux-uitoolkit-common/component';

export interface DragAndDropStateUpdateEvent {
    destination: DragAndDropRecordLocation;
    source: DragAndDropRecordLocation;
}

export interface DragAndDropContextCommonProps extends ComponentProps {
    direction?: 'vertical' | 'horizontal';
    spacing?: number;
    placeholderDisabled?: boolean;
    onStateUpdate: (event: DragAndDropStateUpdateEvent) => void;
}

export interface DragAndDropRecordLocation {
    droppableId: string;
    index: number;
}

export const dragAndDropContextCommonPropsDefaults: Required<
    Pick<DragAndDropContextCommonProps, 'spacing' | 'direction'>
> = {
    spacing: 5,
    direction: 'vertical',
};
