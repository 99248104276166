import React from 'react';
import PropTypes from 'prop-types';
import GuidelinesList from '../GuidelinesList';
import translator from '../../../../../services/translator';
import './index.scss';

const {translate: t} = translator;

const PolicyGuidelines = (props) => {

  const {policyGuidelines, currencyList} = props;
  return (
    <div className='policy-guidelines'>
      <div className='policy-guidelines__header'>
        {t('tkPolicyGuidelines')}
      </div>
      <GuidelinesList guidelines={policyGuidelines} currencyList={currencyList} />
    </div>
  );};

PolicyGuidelines.propTypes={
  policyGuidelines:PropTypes.array,
  currencyList: PropTypes.array
};

export default PolicyGuidelines;
