import { Emphasis, Size } from '@gs-ux-uitoolkit-common/design-system';

export interface LinkCommonProps<MouseEventType = MouseEvent> {
    /**
     * The URL for the Link
     *
     * Read the [MDN docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a) for more info on this attribute.
     */
    href?: string;
    /**
     * Handler for the click event.
     */
    onClick?: (event: MouseEventType) => void;
    /**
     * The underline styling of the Link component. Defaults to 'bold', or underlined
     */
    emphasis?: LinkEmphasis;
    /**
     * Where to display the linked URL
     *
     * Read the [MDN docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a) for more info on this attribute.
     */
    target?: LinkTarget;
    /**
     * Prompts the user to save the linked URL instead of navigating to it
     *
     * Read the [MDN docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a) for more info on this attribute.
     */
    download?: string;
    /**
     * The relationship of the linked URL as space-separated link types.
     *
     * Read the [MDN docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a) for more info on this attribute.
     */
    rel?: string;
    /**
     * Size of the link content, including styling icon size and padding appropriately if passed as child
     */
    size?: LinkSize;
}

export type LinkTarget = '_self' | '_blank' | '_parent' | '_top' | string;

export const defaultLinkProps: DefaultLinkProps = {
    href: '#',
    emphasis: 'bold',
};

export type DefaultLinkProps = Required<Pick<LinkCommonProps, 'href' | 'emphasis'>>;
export type LinkEmphasis = Extract<Emphasis, 'bold' | 'subtle'>;
export type LinkSize = Extract<Size, 'sm' | 'md' | 'lg'>;
