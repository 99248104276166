import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {applyFormatters} from './validators';
import Constants from '../../../constants/appConstants';
import './index.scss';

export class Input extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if(nextProps.value !== prevState.previousValue){
      return { value: nextProps.value, previousValue: nextProps.value};
    }
    return null;
  }

  constructor(props){
    super(props);
    this.state = {
      value: props.value || '',
      previousValue: props.value || '',
      fieldActivate: false
    };

    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleChange(e) {
    let {value} = e.target;
    const { changeHandler, validators: {onChange: onChangeValidators = []} = {},
      formatters: {onChange: onChangeFormatters = []} = {}}  = this.props;

    value = onChangeValidators.length ? applyFormatters(value, onChangeValidators) : value;
    value = onChangeFormatters.length ? applyFormatters(value, onChangeFormatters) : value;
    this.setState({ value });

    if(changeHandler){
      changeHandler(value, e);
    }
  }

  handleBlur(e) {
    let {value} = e.target;
    const { blurHandler, validators: {onBlur: onBlurValidators = []} = {},
      formatters: {onBlur: onBlurFormatters = []} = {} } = this.props;

    value = onBlurValidators.length ? applyFormatters(value, onBlurValidators) : value;
    value = onBlurFormatters.length ? applyFormatters(value, onBlurFormatters) : value;
    this.setState({ value });
    if (e.target.value === '') {
      this.setState({
        fieldActivate: false
      });
    }
    blurHandler && blurHandler(value);
  }

  handleFocus() {
    this.setState({
      fieldActivate: true
    });
    const {focusHandler} = this.props;
    if(focusHandler) {
      focusHandler();
    }
  }

  handleKeyDown(e) {
    const {clearHandler} = this.props;
    const {keyCode}= e;
    if(keyCode === 13 || keyCode === 32) {
      if(keyCode === 32) {
        e.preventDefault();
      }
      clearHandler(Constants.ANALYTICS_KEYBOARD_INTERACTION);
    }
  }

  handleClick(event) {
    event.stopPropagation();
    this.setState({
      fieldActivate: true
    });
    const {focusHandler} = this.props;
    if(focusHandler) {
      focusHandler();
    }
  }

  render(){
    const { type, customClass, isDisabled, hasError, placeholder, length, hasWarning,
      hasVerification, textAlignment, clearHandler, keyDownHandler, inputRef, readOnly,
      selectedValue, label, name, testId, enableClickEvent, tabindex} = this.props;
    const { value } = this.state;
    const isPasswordFiled = (type === 'password' && value) ? 'password-spaced': '';
    return (
      <React.Fragment>
        { label && <label className={this.state.fieldActivate ? 'input-label label__field-active' : 'input-label label'}>{label}</label>}
        <input
          data-testid={testId}
          ref={inputRef}
          tabIndex={tabindex}
          name={name}
          onClick={enableClickEvent ? this.handleClick : undefined}
          className={cn('input', isPasswordFiled, `text-${textAlignment}`, {warning: hasWarning}, {error: hasError}, {[customClass]: customClass})}
          type={type}
          value={value}
          maxLength={length}
          placeholder={placeholder}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          disabled={isDisabled}
          onFocus={this.handleFocus}
          onKeyDown={keyDownHandler}
          readOnly={readOnly}
        />
        {(!isDisabled && clearHandler && value) ? <span role="button" className="input__clear-icon" onClick={() => clearHandler()} tabIndex={value || selectedValue ? 0 : -1} onKeyDown={this.handleKeyDown} readOnly={readOnly} /> : null}
        {(hasVerification) ? <span className="input__verify-icon" /> : null}
      </React.Fragment>
    );
  }
}

Input.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  length: PropTypes.number,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  blurHandler: PropTypes.func,
  changeHandler: PropTypes.func,
  validators: PropTypes.shape({
    onChange: PropTypes.arrayOf(PropTypes.string),
    onBlur: PropTypes.arrayOf(PropTypes.string)
  }),
  formatters: PropTypes.shape({
    onChange: PropTypes.arrayOf(PropTypes.string),
    onBlur: PropTypes.arrayOf(PropTypes.string)
  }),
  type: PropTypes.string,
  customClass: PropTypes.string,
  hasError: PropTypes.bool,
  hasWarning: PropTypes.bool,
  textAlignment: PropTypes.oneOf(['right', 'left']),
  clearHandler: PropTypes.func,
  inputRef: PropTypes.object,
  focusHandler: PropTypes.func,
  keyDownHandler: PropTypes.func,
  readOnly: PropTypes.bool,
  selectedValue: PropTypes.string,
  hasVerification: PropTypes.bool,
  label: PropTypes.string,
  testId: PropTypes.string,
  enableClickEvent: PropTypes.bool,
  tabindex: PropTypes.number
};

Input.defaultProps = {
  readOnly: false,
  textAlignment: 'left',
  type: 'text',
  enableClickEvent: false
};

export default React.forwardRef((props, ref) => <Input inputRef={ref} {...props} />);
