import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {getSortedDates, getSortedAmountData, getSortedStringData, getSortedNumberData} from '../../../utils/sorting';
import {DATE_TIME_FORMATTERS} from '../../../constants/appConstants';

/** **********************************************************************************************************************************
   If the Fund and User are in different timezone, below conversion is needed as Fund Cut Off Time returned
   by service is converted based on User Timezone, so need to convert it back based on Fund Cut Off Timezone
************************************************************************************************************************************ */
const getFormattedFundCutoffTime = (fundCutOffTime, fundCutOffTimeZone, userTimeZone) => {
  const fundCutOffTimeWithoutZoneAbbr = fundCutOffTime.split(' ').splice(0, 2).join(' ');
  const fundCutOffTimeIn24HrsFormat = moment(fundCutOffTimeWithoutZoneAbbr, ["h:mm A"]).format(DATE_TIME_FORMATTERS.HH_mm);
  if (userTimeZone !== fundCutOffTimeZone) {
    const currentDateInUserTimezone = moment().tz(userTimeZone).format(DATE_TIME_FORMATTERS.YYYY_MM_DD);
    const fundCutOffAsPerUserTimeZone = moment.tz(`${currentDateInUserTimezone} ${fundCutOffTimeIn24HrsFormat}`, userTimeZone);
    const fundCutOffTimeAsPerFundTimeZone = fundCutOffAsPerUserTimeZone.clone().tz(fundCutOffTimeZone).format(DATE_TIME_FORMATTERS.HH_mm);
    return moment.duration(fundCutOffTimeAsPerFundTimeZone).asMinutes();
  }
  return moment.duration(fundCutOffTimeIn24HrsFormat).asMinutes();
}

export const setNavCutOffFlag = (txn, userTimeZone) => {

    const {fundCutOff: {time: fundCutOffTime = "", timeZone: fundCutOffTimeZone = ""} = {}} = txn;
    const tradeDateTime = (new Date(txn.tradeDate)).getTime();
    const fundCutOffDateTime = (new Date(moment().tz(fundCutOffTimeZone).format(DATE_TIME_FORMATTERS.YYYY_MM_DD))).getTime();
    const fundCutoffTimeFormatted = getFormattedFundCutoffTime(fundCutOffTime, fundCutOffTimeZone, userTimeZone);
    const currentTimeInFundTimezone = moment.duration(moment.tz(fundCutOffTimeZone).format(DATE_TIME_FORMATTERS.HH_mm)).asMinutes();
    const isPassedCutOff = (tradeDateTime < fundCutOffDateTime) ||
          ((fundCutOffDateTime === tradeDateTime) && (fundCutoffTimeFormatted - currentTimeInFundTimezone) <= 30);
    return {...txn, isPassedCutOff};
};

const getFormattedCutoff = (tradeCutOffDateTimeAsPerUserTimeZone) => {
   const zFormat = tradeCutOffDateTimeAsPerUserTimeZone.format('z');
  const cutofftTime = tradeCutOffDateTimeAsPerUserTimeZone.format('hh:mm A');
  const cutoffDate = tradeCutOffDateTimeAsPerUserTimeZone.format('DD.MMM.YYYY');
  return `${cutofftTime} ${zFormat} ${cutoffDate}`;

}

export const setTradeCutoffFlag = (transactions, userTimeZone) => {
  return transactions && transactions.map((txn) => {
    const {tradeCutoff: {cutoff: tradeCutoffDateTime = "", timezone: tradeCutoffTimezone = ""} = {}} = txn;

    // if trade cutoff is not present fallback to old fund cutoff logic
    if(!tradeCutoffDateTime) return setNavCutOffFlag(txn, userTimeZone);

    // when trade cutoff is present following logic will run
    const [cutoffTime, format, , cutoffDate] = tradeCutoffDateTime.split(' ');
    const cutoff = `${cutoffDate} ${cutoffTime}${format}`;
    const tradeCutOffAsPerTradeTimeZone = moment.tz(cutoff, 'DD.MMM.YYYY hh:mm:A', tradeCutoffTimezone);

    const tradeCutOffDateTimeAsPerUserTimeZone = userTimeZone !== tradeCutoffTimezone ? tradeCutOffAsPerTradeTimeZone.clone().tz(userTimeZone) : tradeCutOffAsPerTradeTimeZone;
    const currentDateTimeInUserTimezone = moment().tz(userTimeZone).format('YYYY-MM-DD HH:mm');
    const tradeCutOffDateTimeAsPerUserTimeZoneFormatted =  moment(tradeCutOffDateTimeAsPerUserTimeZone, 'DD.MMM.YYYY hh:mm:A').format('YYYY-MM-DD HH:mm');
    const timeDifference = moment(tradeCutOffDateTimeAsPerUserTimeZoneFormatted, 'YYYY-MM-DD HH:mm').diff(currentDateTimeInUserTimezone, 'YYYY-MM-DD HH:mm');
    const timeDiffInMinutes = moment.duration(timeDifference).asMinutes();
    const isPassedCutOff = timeDiffInMinutes <= 30;
    const formattedCutoff = getFormattedCutoff(tradeCutOffDateTimeAsPerUserTimeZone);
    return {...txn, isPassedCutOff, approvalCutoff: formattedCutoff};
  });
};

export const groupApprovalFlatData = (tradesData) => {
  const array = [];
  (tradesData && tradesData.length > 0) && tradesData.forEach(trade => {
    const {firm, branch, tradeDate} = trade;
    const tradeFirmBranch = `${firm}-${branch}`;
    const tradesKey = `${tradeDate}-${tradeFirmBranch}`;
    const matchedItem = array.find(object => tradesKey === object.tradesKey);
    const updatedTrade = {...trade, fundCutOffTime: trade.fundCutOff.time};
    if (!array.length || !matchedItem) {
      array.push({
        tradesKey,
        tradeDate,
        tradeFirmBranch,
        transactions: [updatedTrade]
      });
    } else {
      matchedItem.transactions.push(updatedTrade);
    }
  });
  return array;
};

export const sortTradeApprovalFlatData = (tradesData) => {
  const groupedTradeData = groupApprovalFlatData(tradesData);
  const sortedDataByFirmBranch = getSortedStringData(groupedTradeData, 'tradeFirmBranch', true);
  const sortedDataByTradeDate = getSortedDates(sortedDataByFirmBranch, 'tradeDate', true, '-');
  sortedDataByTradeDate.forEach((trades) => {
    const sortedCutOffData = getSortedStringData(trades.transactions, 'fundCutOffTime', true);
    const sortedAmountData = getSortedAmountData(sortedCutOffData, 'tradeAmount', false);
    trades.transactions = getSortedStringData(sortedAmountData, 'tradeType', true);
  });
  return getSortedNumberData(groupedTradeData, 'id', true);
};

export const getNavConfigForPageId = (navConfigs, pageId, isCheckSubRoute = true) => {
  let [matchedRoute] = navConfigs ? navConfigs.filter(config => config.routingPathId && config.routingPathId === pageId) : [];
  if (isEmpty(matchedRoute) && isCheckSubRoute) {
    [matchedRoute] = navConfigs
      .map(config => getNavConfigForPageId(config.subMenu, pageId, false))
      .filter(navItem => !isEmpty(navItem));
  }
  return matchedRoute;
};

