import {connect} from 'react-redux';
import {pathSelector, labelSelector} from '../../selectors/app';
import {InterstitialModal} from '../../components/app/InterstitialModal';

const mapStateToProps = state => ({
  pathMapping : pathSelector(state),
  labels : labelSelector(state)
});

export default connect(mapStateToProps)(InterstitialModal);
