import * as React from 'react';

const ChartContext = React.createContext({});
ChartContext.displayName = 'ChartContext';

export const useChartContext = () => {
    const context = React.useContext(ChartContext);
    if (!context) {
        throw new Error('ChartContext Provider is missing.');
    }
    return context;
};

export {
  ChartContext
};
