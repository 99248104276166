/**
 * Helper for anything String related
 */
export class StringHelper {
    public isNullOrEmpty(theString: string | undefined | null) {
        return !theString || theString === '';
    }
}

export const stringHelper = new StringHelper();
