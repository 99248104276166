import React from 'react';
import PropTypes from 'prop-types';
import List from '../../../../core/List';
import Item from './item';
import {getNoDataComponent} from '../../../../core/NoDataComponent';
import translator from '../../../../../services/translator';
import './index.scss';

const {translate: t} = translator;

const GuidelineListHeader = ({testId}) => {
  return(
    <div className="guideline-list__header" data-testid={`${testId}-header`}>
      <div className="guideline-list__title--mrl0" >{t('tkRuleNameAndDescription')}</div>
      <div className="guideline-list__title" >{t('tkThreshold')}</div>
      <div className="guideline-list__title" >{t('tkUnit')}</div>
      <div className="guideline-list__title" >{t('tkTradeBehavior')}</div>
    </div>
  );
};

const GuidelinesList = (props) => {
  const {guidelines} = props;
  const testId = 'guidelines';
  const scrollStyle={
    'maxHeight':'235px'
  };
  const id = 'guideline-list';
  const placeholder = t('tkNoGuidelinesAvailable');

  return (
    <div className={id}>
      <GuidelineListHeader testId={testId} />
      <List
        items={guidelines}
        NoDataComponent={getNoDataComponent(id, placeholder)}
        props={props}
        cssName={'scope-list'}
        testId={testId}
        scrollStyle={scrollStyle}
      >
        <Item />
      </List>
    </div>
  );
};


GuidelinesList.propTypes = {
  guidelines: PropTypes.array
};

GuidelineListHeader.propTypes = {
  testId: PropTypes.string
};

export default GuidelinesList;

