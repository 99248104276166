import React, { useRef, useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useFetch from '../../hooks/useFetch';
import useSnackBar from '../../hooks/useSnackbar';
import AutoComplete from '../../../../../components/core/AutoComplete';
import endPointsMapper from '../../../../../configs/endPointsMapper';
import { getSortedStringData } from '../../../../../utils/sorting';
import translator from '../../../../../services/translator';

const { translate: t } = translator;

const AutoCompleteOrgSearch = props => {

  const { value: { organizationName = '', organizationId } = {}, config, handleChange, errorState: { errorMessage } = {}, fieldIndex } = props;

  const [defaultSearchTerm, setDefaultSearchTerm] = useState(organizationName);
  const autoCompleteRef = useRef();
  const { openSnackbar } = useSnackBar();

  const onAutoCompleteError = useCallback(() => {
    openSnackbar({ type: 'error', messageId: 'organizationAutoCompleteErrorMsg', message: t('tkOrganizationAutoCompleteNewErrorMsg') });
  }, []);

  const { loading, doFetch, data } = useFetch(endPointsMapper.FETCH_USER_SEARCH, undefined, onAutoCompleteError);

  const { organizations: { values = [] } = {} } = data || {};

  const onInputChange = data => {
    data.length >= 3 && doFetch({ searchString: data, searchGroups: ['organization'], searchMaxResults: 7 });
  };

  useEffect(() => {
    setDefaultSearchTerm(organizationName);
    autoCompleteRef.current.setSearchTerm(organizationName);
  }, [organizationName]);

  const updatedOptions = useMemo(() => {
    if (!data) return [];
    const result = [];
    if (values.length) {
      const allMatches = values
        .map(item => ({ ...item, label: item.name, labels: [{ name: item.name, isScGreyText: false }] }));
      result.push({ title: null, values: getSortedStringData(allMatches, 'label', true) });
    }
    return result;
  }, [data]);

  const onOptionSelection = newValue => {
    handleChange({
      value: {
        organizationId: newValue.id,
        organizationName: newValue.name,
      },
      fieldIndex,
      config,
      action: 'change',
    });
  };

  const onOutSideClick = searchTerm => {
    if (searchTerm !== organizationName) {
      autoCompleteRef.current.setSearchTerm(organizationName);
    }
  };

  return (
    <div className='userdetails__autoCompleteOrgSearch'>
      <div className='userdetails__autoCompleteOrgSearch--field userdetails__dropdown'>
        <AutoComplete
          ref={autoCompleteRef}
          placeholderText={t('tkOrganization')}
          searchIconPosition={organizationId ? null : 'right'}
          onInputChange={onInputChange}
          debounceTime={150}
          minChar={3}
          iconSize='md'
          isLoading={loading}
          options={updatedOptions}
          onOptionSelection={onOptionSelection}
          defaultSearchTerm={defaultSearchTerm}
          showClearIcon={false}
          onOutSideClick={onOutSideClick}
        />
      </div>
      {errorMessage && (
        <span className='error-message' data-testid='text-errorMessage'>
          {errorMessage}
        </span>
      )}
    </div>
  );
};

AutoCompleteOrgSearch.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.object,
  config: PropTypes.object,
  errorState: PropTypes.object,
  fieldIndex: PropTypes.number,
};

export default AutoCompleteOrgSearch;
