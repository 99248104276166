import { createSelector } from "reselect";
import {
  labelSelector,
  navBarSelector,
  reportingPermitRolesSelector,
} from "../../app";
import appRoutes from "../../../routes/appRoutes";
import Constants from "../../../constants/appConstants";
import { swap } from "../../../utils/commonUtils";
import { allowFeaturesSelector } from "../../user";
import {allowFeatureSelector} from "./individualReport";

// ADD selector only pageContext
export const isLoading = (state) => state.pageContext.loading; // Don't assign any fallback

const getSubMenu = (reportsRoute, swapTab) => {
  const subMenu = reportsRoute ? reportsRoute.subMenu : [];
  return (swapTab && subMenu.length > 1 && swap([...subMenu])) || subMenu;
};

export const getReportsConfig = createSelector(
  labelSelector,
  navBarSelector,
  (labels, navBar) => {
    const reportsNavConfigs =
      navBar &&
      navBar.navRootConfig &&
      navBar.navRootConfig.find(
        (data) => data.routingPathId === Constants.REPORTS
      );
    const reportsRoutes = appRoutes.find(
      (item) => item.id === Constants.REPORTS
    ).subRoutes;
    return (
      (reportsNavConfigs &&
        reportsNavConfigs.subMenu.map((data) => {
          const matchedRoute =
            reportsRoutes.find((item) => item.id === data.routingPathId) || "";
          const { id, exact, path } = matchedRoute;
          return { id, exact, path, label: labels[data.displayNameKey] };
        })) ||
      []
    );
  }
);

export const getReportsTabConfig = createSelector(
  labelSelector,
  navBarSelector,
  (labels, navBar) => {
    const reportsNavConfigRoutes = navBar && navBar.navRootConfig &&
      navBar.navRootConfig.find(({routingPathId}) => routingPathId === Constants.REPORTS);
    const reportsAppRoutes = appRoutes.find(({id}) => id === Constants.REPORTS);
    return (
      reportsNavConfigRoutes &&
      reportsNavConfigRoutes.subMenu
        .filter(item => item.isTab)
        .map((data) => {
          const matchedRoute = reportsAppRoutes.subRoutes
            .find((item) => item.id === data.routingPathId) || "";
          const {id, exact, path} = matchedRoute;
          return {
            id,
            exact,
            path,
            label: labels[data.displayNameKey]
          };
        }) || []
    );
  }
);

export const isUserEntitledToMyReportsSelector = createSelector(
  allowFeatureSelector,
  (allowedFeatures) => (
    ((allowedFeatures && allowedFeatures.includes("REPORTINGNEW")) || false)
  ));

export const getReportsNavLinks = createSelector(navBarSelector, (navBar) => {
  const reportsRoute =
    navBar &&
    navBar.navRootConfig.find(
      (data) => data.routingPathId === Constants.REPORTS
    );
  return reportsRoute ? getSubMenu(reportsRoute) : [];
});

export const getAllPermitRoles = createSelector(
  reportingPermitRolesSelector,
  allowFeaturesSelector,
  (reportingPermitRoles, allowFeatures) => {
    let allRoles = [];
    allRoles = allRoles.concat(reportingPermitRoles);
    allRoles = allRoles.concat(allowFeatures);
    return allRoles;
  }
);
