import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@gs-ux-uitoolkit-react/modal';
import { Radio } from '@gs-ux-uitoolkit-react/radio';
import { Button } from '@gs-ux-uitoolkit-react/button';
import PropTypes from 'prop-types';
import { BOOLEAN } from '../../../../../../constants/pageConstants';
import translator from '../../../../../../services/translator';
import './index.scss';

const { translate: t } = translator;

const ParentOrgWarningModal = ({ modalType, closeModal, isModalOpen, onModalSubmit }) => {
  const [selectedOption, setSelectedOption] = useState();
  const [error, setError] = useState(false);

  const handleSubmit = () => {
    if (selectedOption === BOOLEAN.YES) {
      onModalSubmit(true);
      closeModal();
    } else if (selectedOption === BOOLEAN.NO) {
      onModalSubmit(false);
      closeModal();
    } else {
      setError(true);
    }
  };

  const handleOptionChange = event => {
    setSelectedOption(event.target.value);
    setError(false);
  };

  const modalLabels = {
    changeContent: t('tkParentOrgChangeWarning'),
    changeYesBtn: t('tkParentOrgChangeYes'),
    changeNoBtn: t('tkParentOrgChangeNo'),
    removeContent: t('tkParentOrgRemoveWarning'),
    removeYesBtn: t('tkParentOrgRemoveYes'),
    removeNoBtn: t('tkParentOrgRemoveNo'),
  };

  const handleModalClose = () => {
    onModalSubmit(false);
    closeModal();
  };

  return (
    <Modal visible={isModalOpen} placement='center' className='parentorg-warning-modal gs-uitk-override-css' data-testid='unmap-modal'>
      <ModalHeader onDismissButtonClick={handleModalClose} className='parentorg-warning-modal__header'>
        {t('tkAffixWarning')}
      </ModalHeader>
      <ModalBody className='parentorg-warning-modal__body'>
        <div>{modalLabels[`${modalType}Content`]}</div>
        <div className='parentorg-warning-modal__radio'>
          <Radio name='modalRadio' value={BOOLEAN.YES} inline onChange={handleOptionChange} status={error ? 'error' : 'none'}>
            {modalLabels[`${modalType}YesBtn`]}
          </Radio>
          <Radio name='modalRadio' value={BOOLEAN.NO} inline onChange={handleOptionChange} status={error ? 'error' : 'none'}>
            {modalLabels[`${modalType}NoBtn`]}
          </Radio>
        </div>
        {error && <div className='parentorg-warning-modal__errorMsg'>{t('tkParentOrgSelectionWarning')}</div>}
      </ModalBody>
      <ModalFooter className='parentorg-warning-modal__footer'>
        <Button actionType='secondary' onClick={handleModalClose}>
          {t('tkCancelModal')}
        </Button>
        <Button actionType='primary' onClick={handleSubmit}>
          {t('tkOk')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ParentOrgWarningModal.propTypes = {
  modalType: PropTypes.string,
  closeModal: PropTypes.func,
  isModalOpen: PropTypes.bool,
  onModalSubmit: PropTypes.func,
};

export default ParentOrgWarningModal;
