/**
 * Class used by the system in order to create Registries
 */
export class Registry<T> {
    private objectRegistry: Map<string, T>;

    constructor() {
        this.objectRegistry = new Map();
    }

    /**
     * Register an object
     * @param key unique key
     * @param object the Object instance
     */
    public register(key: string, object: T): Registry<T> {
        if (this.objectRegistry.has(key)) {
            throw new Error('Duplicate name ' + key);
        }
        this.objectRegistry.set(key, object);
        return this;
    }

    /**
     * Get a registered object
     * @param key The key of the object
     */
    public get(key: string): T {
        const object = this.objectRegistry.get(key);

        if (!object) {
            throw new Error('Invalid object: ' + key);
        }

        return object;
    }

    /**
     * Returns all registered objects
     */
    public getAll(): T[] {
        return Array.from(this.objectRegistry.values());
    }
}
