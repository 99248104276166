import { PureComponent, createRef, RefObject, ContextType } from 'react';
import PropTypes from 'prop-types';
import {
    buttonStyleSheet,
    getRootClassNames,
    getButtonClassNames,
    defaultButtonProps,
    DefaultButtonProps,
    buttonStatuses,
    buttonEmphasises,
    buttonSizes,
    buttonShapes,
    buttonTypes,
} from '@gs-ux-uitoolkit-common/button';

// @ts-expect-error: needed because api-extractor can not parse dynamic imports in .d.ts files
import {
    ButtonType,
    ButtonStatus,
    ButtonEmphasis,
    ButtonShape,
    ButtonSize,
} from '@gs-ux-uitoolkit-common/button';

import { ButtonProps } from './button-props';
import { ButtonGroupContext } from '../button-group/button-group-context';
import { splitDataAttributes, wrapTextChildrenInSpan } from '@gs-ux-uitoolkit-react/shared';
import { Theme, ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';
import { componentAnalytics } from '../analytics-tracking';
import { EmotionInstanceContext } from '@gs-ux-uitoolkit-react/style';

type PropsWithDefaults = ButtonProps & DefaultButtonProps;

/**
 * Buttons allow users to take an action or submit a form.
 */
export class Button extends PureComponent<ButtonProps> {
    static contextType = ButtonGroupContext;

    declare context: ContextType<typeof ButtonGroupContext>;

    private htmlButton: RefObject<HTMLButtonElement>;

    constructor(props: ButtonProps) {
        super(props);
        this.htmlButton = createRef<HTMLButtonElement>();
    }
    public componentDidMount() {
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'button' });
    }

    render() {
        const {
            actionType = defaultButtonProps.actionType,
            status = defaultButtonProps.status,
            emphasis = defaultButtonProps.emphasis,
            size,
            shape = defaultButtonProps.shape,
            type = defaultButtonProps.type,
            disabled = defaultButtonProps.disabled,
            autoFocus = defaultButtonProps.autoFocus,
            name,
            value = defaultButtonProps.value,
            active = defaultButtonProps.active,
            children,
            className,
            classes: overrideClasses,
            elementRef,
            tabIndex,
            style,
            title,
            id,
            buttonId,
            buttonAttrs,
            ...otherProps
        } = this.props as PropsWithDefaults;

        // Sets values by precedence: local prop, then context, then default.
        const internal = {
            name: name || this.context.name || defaultButtonProps.name,
            size: size || this.context.size || defaultButtonProps.size,
        };

        const { split: dataAttributeProps, remaining: buttonProps } =
            splitDataAttributes(otherProps);

        return (
            <EmotionInstanceContext.Consumer>
                {emotionInstance => (
                    <ThemeConsumer>
                        {(theme: Theme) => {
                            const cssClasses = buttonStyleSheet.mount(
                                this,
                                {
                                    theme,
                                    actionType,
                                    status,
                                    emphasis,
                                    size: internal.size,
                                    shape,
                                    active,
                                    disabled,
                                },
                                emotionInstance
                            );

                            const rootClasses = getRootClassNames({
                                cssClasses,
                                overrideClasses,
                                className,
                            });

                            const buttonClasses = getButtonClassNames({
                                cssClasses,
                                overrideClasses,
                            });

                            return (
                                // wrapping the button in a <span> to mimic the angular button's host el simplifies the styling rules
                                <span
                                    {...{ style, title, id, ...dataAttributeProps }}
                                    data-gs-uitk-component="button"
                                    data-action-type={actionType}
                                    data-emphasis={emphasis}
                                    className={rootClasses}
                                >
                                    {/* eslint-disable jsx-a11y/no-autofocus */}
                                    <button
                                        {...buttonAttrs}
                                        {...buttonProps}
                                        id={buttonId}
                                        data-cy="gs-uitk-button__button"
                                        data-action-type={actionType}
                                        data-emphasis={emphasis}
                                        data-size={internal.size}
                                        data-shape={shape}
                                        data-status={status}
                                        data-active={active}
                                        className={buttonClasses}
                                        ref={this.attachRefs}
                                        type={type}
                                        disabled={disabled}
                                        autoFocus={autoFocus}
                                        tabIndex={tabIndex}
                                        name={internal.name}
                                        value={value}
                                    >
                                        {wrapTextChildrenInSpan(children)}
                                    </button>
                                </span>
                            );
                        }}
                    </ThemeConsumer>
                )}
            </EmotionInstanceContext.Consumer>
        );
    }

    componentWillUnmount() {
        buttonStyleSheet.unmount(this);
    }

    /**
     * Function used to focus the button component.
     * @public
     */
    public focus() {
        const button = this.htmlButton.current;

        if (button) {
            button.focus();
        }
    }

    /**
     * Function used to blur (unfocus) the button component.
     * @public
     */
    public blur() {
        const button = this.htmlButton.current;

        if (button) {
            button.blur();
        }
    }

    private attachRefs = (buttonEl: HTMLButtonElement | null) => {
        (this.htmlButton as any).current = buttonEl; // read-only types not updated yet: https://github.com/facebook/react/issues/13029

        if (typeof this.props.elementRef === 'function') {
            this.props.elementRef(buttonEl);
        } else if (this.props.elementRef) {
            (this.props.elementRef as any).current = buttonEl;
        }
    };

    static propTypes = {
        actionType: PropTypes.oneOf([
            'primary',
            'secondary',
            'destructive',
            'info',
            'contrast',
        ] as const),
        buttonId: PropTypes.string,
        status: PropTypes.oneOf(buttonStatuses),
        emphasis: PropTypes.oneOf(buttonEmphasises),
        size: PropTypes.oneOf(buttonSizes),
        shape: PropTypes.oneOf(buttonShapes),
        type: PropTypes.oneOf(buttonTypes),
        disabled: PropTypes.bool,
        autoFocus: PropTypes.bool,
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
        onClick: PropTypes.func,
        onMouseDown: PropTypes.func,
        onMouseUp: PropTypes.func,
    };
}
