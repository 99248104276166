import { createContext } from 'react';
import { SimpleEventDispatcher } from '@gs-ux-uitoolkit-common/dra';

import { DataStackPlugin } from '../interfaces/plugins';
import { Registries } from '../interfaces/registries';
import { getSingletonInstance } from '../libraries/singleton';

export interface DefaultRegistryState {
    displayedItems: string[];
    availableItems: string[];
}
export interface RegistryAndStoreContextArgs {
    registries?: Registries;
    plugins?: DataStackPlugin[];
    defaultState?: DefaultRegistryState;
    setRegistriesAndStore?: (
        registries: Registries,
        plugins: DataStackPlugin[],
        defaultState?: DefaultRegistryState
    ) => void;
    setDefaultState?: (defaultState: DefaultRegistryState) => void;
    //used for dispatching events to and from the grid and toolbar. Mainly used for opening the modal.
    eventDispatcher?: SimpleEventDispatcher<{ screenId: string }>;
}

export const RegistryContext = getSingletonInstance('DataCore.RegistryContext', () =>
    createContext<RegistryAndStoreContextArgs>({})
);
