import actionTypes from '../../actions/user/actionTypes';

const defaultUserState = {};

export function userReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.ADD_USER_DATA:
      return {...state, ...action.data};
    case actionTypes.CLEAR_USER_DATA:
      return defaultUserState;
    default:
      return state;
  }
}
