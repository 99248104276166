import actionTypes from './actionTypes';

export const updateAppLoading = data => (
  {
    type: actionTypes.APP_LOADING,
    data: {'isAppLoading': data}
  }
);

export const updateAppInitialLoadFlag = data => (
  {
    type: actionTypes.APP_INITIAL_LOAD,
    data: {'isAppInitialLoad': data}
  }
);

export const addAppData = data => (
  {
    type: actionTypes.ADD_APP_DATA,
    data: {...data}
  }
);

export const clearAppData = () => (
  {
    type: actionTypes.CLEAR_APP_DATA
  }
);

export const addAppContext = data => (
  {
    type: actionTypes.ADD_APP_CONTEXT,
    data: {...data}
  }
);
export const clearAppContext = () => (
  {
    type: actionTypes.CLEAR_APP_CONTEXT
  }
);

export const updateSelectedRow = data => (
  {
    type: actionTypes.UPDATE_SELECTED_ROWS,
    data: {'selectedRows': data}
  }
);

export const changeActiveTradeRow = data => (
  {
    type: actionTypes.CHANGE_ACTIVE_TRADE_ROW,
    data: {'activeTrade': data}
  }
);

export const updateActiveTrade = data => (
  {
    type: actionTypes.UPDATE_ACTIVE_TRADE,
    data: {'activeTrade': data}
  }
);

export const updateTradeOptions = data => (
  {
    type: actionTypes.UPDATE_TRADE_OPTIONS,
    data: {'tradeOptions': data}
  }
);

export const updateDraftTrades = data => (
  {
    type: actionTypes.UPDATE_DRAFT_TRADES,
    data: {'draftTrades': data}
  }
);

export const updateDraftTradesLoading = data => (
  {
    type: actionTypes.DRAFT_TRADES_LOADING,
    data: {'isDraftTradesLoading': data}
  }
);

export const updateTradeOptionsLoading = data => (
  {
    type: actionTypes.TRADE_OPTIONS_LOADING,
    data: {'isTradeOptionsLoading': data}
  }
);

export const updateValidTrades = data => (
  {
    type: actionTypes.UPDATE_VALID_TRADES,
    data: {'validTrades': data}
  }
);

export const updateCompleteTrades = data => (
  {
    type: actionTypes.UPDATE_COMPLETE_TRADES,
    data: {'completeTrades': data}
  }
);

export const updateBlotterDirtyStatus = data => (
  {
    type: actionTypes.BLOTTER_DIRTY_STATUS,
    data: {'isBlotterDirty': data}
  }
);

export const updateSavingTradesProgress = data => (
  {
    type: actionTypes.SAVING_TRADES_IN_PROGRESS,
    data: {'isSavingTrades': data}
  }
);

export const updateSaveQueuedStatus = data => (
  {
    type: actionTypes.SAVE_QUEUED_STATUS,
    data: {'isSaveQueued': data}
  }
);

export const updateRetrieveQueuedStatus = data => (
  {
    type: actionTypes.UPDATE_RETRIEVE_QUEUED_STATUS,
    data: {'isRetrieveQueued': data}
  }
);

export const updateVerifyTradesProgress = data => (
  {
    type: actionTypes.VERIFY_TRADES_IN_PROGRESS,
    data: {'isVerifyingTrades': data}
  }
);

export const showNewAccountModal = () => (
  {
    type: actionTypes.SHOW_NEW_ACCOUNT_MODAL,
    data: {'showNewAccountModal': true}
  }
);

export const closeNewAccountModal = () => (
  {
    type: actionTypes.CLOSE_NEW_ACCOUNT_MODAL,
    data: {'showNewAccountModal': false}
  }
);

export const updateShellAccountOptionsLoading = data => (
  {
    type: actionTypes.SHELL_ACCOUNT_OPTIONS_LOADING,
    data: {'isShellAccountOptionsLoading': data}
  }
);

export const logoutAction = data => (
  {
    type: actionTypes.LOGOUT,
    data : {...data}
  }
);

export const updateCachedTradeOptionsLoading = data => (
  {
    type: actionTypes.CACHED_TRADE_OPTIONS_LOADING,
    data: {'isCachedTradeOptionsLoading': data}
  }
);

export const updateCachedTradeOptions = data => (
  {
    type: actionTypes.UPDATE_CACHED_TRADE_OPTIONS,
    data: {'cachedTradeOptions': data}
  }
);

export const updateTradeInputsLoading = data => (
  {
    type: actionTypes.TRADE_INPUTS_LOADING,
    data: {'isTradeInputsLoading': data}
  }
);

export const updatePlacingTradesProgress = data => (
  {
    type: actionTypes.PLACING_TRADES_IN_PROGRESS,
    data: {'isPlacingTrades': data}
  }
);

export const showTradeSaveFailSnackbar = data => (
  {
    type: actionTypes.SHOW_TRADE_SAVE_FAIL_SNACKBAR,
    data: {'tradeSaveFailed': data}
  }
);

export const showTradeBlotterSnackbar = data => (
  {
    type: actionTypes.SHOW_TRADE_BLOTTER_SNACKBAR,
    data: {'tradeBlotterSnackbarMessage': data}
  }
);

export const updateTradeInlineWarning = data => (
  {
    type: actionTypes.UPDATE_TRADE_INLINE_WARNING,
    data: {'inlineWarnings': data}
  }
);

export const showNewAccountModalSnackbar = data => (
  {
    type: actionTypes.SHOW_NEW_ACCOUNT_MODAL_SNACKBAR,
    data: {'newAccountModalSnackbarMessage': data}
  }
);

export const updateMessageCount = data => (
  {
    type: actionTypes.UPDATE_MESSAGE_COUNT,
    data: {'prioritizedMessageCount': data}
  }
);

export const updatePendingTradesForApprovalFlag = data => (
  {
    type: actionTypes.UPDATE_PENDING_TRADES_FOR_APPROVAL_FLAG,
    data
  }
);

export const pendingTradesForApprovalFlagLoading = data => ({
  type: actionTypes.PENDING_TRADES_FOR_APPROVAL_FLAG_DATA_LOADING,
  data
});


export const updateMessages = data => (
  {
    type: actionTypes.UPDATE_MESSAGES,
    data: {'messages': data}
  }
);

export const getThirdPartyRedirectUrl = data => (
  {
    type: actionTypes.FETCH_THIRD_PARTY_REDIRECT_URL,
    data
  }
);

export const clearWaterSsoOutbound = data => (
  {
    type: actionTypes.CLEARWATER_SSO_OUTBOUND,
    data
  }
);

export const isSessionExpired = () => (
  {
    type: actionTypes.IS_SESSION_EXPIRED,
    data: {'isSessionExpired': true}
  }
);

export const stopPollingTickerApi = () => (
  {
    type: actionTypes.STOP_POLLING_TICKER_API,
    data: {'stopPollingTickerApi': true}
  }
);

export const updateShellAccountState = data => (
  {
    type: actionTypes.UPDATE_SHELL_ACCOUNT_STATE,
    data: {'shellAccountState': data}
  }
);

export const addShellAccountConfigs = data => (
  {
    type: actionTypes.ADD_SHELL_ACCOUNT_CONFIGS,
    data: {'shellAccountConfigs': data}
  }
);

export const addReportingPermitRoles = data => ({
  type: actionTypes.ADD_REPORTING_PERMIT_ROLES,
  data
});

export const onTmxProfilingComplete = flag => (
  {
    type: actionTypes.IS_TMX_PROFILING_COMPLETE,
    data: {'isTmxProfilingComplete': flag}
  }
);

export const refreshPageData = data => (
  {
    type: actionTypes.REFRESH_PAGA_DATA,
    data
  }
);

export const setGlobalError = data => (
  {
    type: actionTypes.SET_GLOBAL_ERROR,
    data
  }
);

