import { cloneDeep } from "lodash";
import {
  getInitialSelectedMap,
  changeFirmBranch,
  changeAllFirmBranch,
  changeTaAccounts,
  changeAllTaAccounts,
  changeShareclass,
  changeAllShareclasses,
} from "./utils";

const reducer = (state, action) => {
  let newState = cloneDeep(state);
  const { type, data } = action;

  switch(type) {
    case "FIRM_CHANGE": {
      const { targetNode: {children, checked, value } } = data;
      newState = changeFirmBranch(newState, { type: "firm", checked, value, children });
      break;
    }
    case "BRANCH_CHANGE": {
      const { targetNode: { checked, value, parent } } = data;
      newState = changeFirmBranch(newState, { type: "branch", checked, value, parent });
      break;
    }
    case "FIRM_BRANCH_ALL_CHANGED": {
      const { selected } = data;
      newState = changeAllFirmBranch(newState, { checked: selected.length !== 0 });
      break;
    }
    case "TA_ACCOUNT_CHANGED": {
      const { isChecked, value, isAll } = data;
      if (isAll) {
        newState = changeAllTaAccounts(newState, {checked: isChecked});
      } else {
        newState = changeTaAccounts(newState, {checked: isChecked, value});
      }
      break;
    }
    case "FUND_CHANGED": {
      const { isChecked, value, isAll } = data;
      if (isAll) {
        newState = changeAllShareclasses(newState, {checked: isChecked});
      } else {
        newState = changeShareclass(newState, {checked: isChecked, value});
      }
      break;
    }
    default: {
      return state;
    }
  }
  newState.stateUpdated = {update: true};
  return newState;
}

const getInitialState = (filterData) => {
  const {
    firmNodeMap,
    branchNodeMap,
    taAccountNodeMap,
    shareclassNodeMap,

    firmMap,
    branchMap,
    taAccountMap,
    shareclassMap,
    accountMap,

    firmBranchValuesObj,
    taAccountValuesObj,
    shareclassValuesObj
  } = filterData.nodeMap;
  const {
    shareclassNodeMap: shareclassNodeMapWithoutAcct = {},
    shareclassValuesObj: shareclassValuesObjWithoutAcct = {}
  } = filterData.scWithoutAcct
  return {
    initial: {
      accountMap: cloneDeep(accountMap),
      taAccountMap: cloneDeep(taAccountMap),
      shareclassMap: cloneDeep(shareclassMap),
      taAccountValuesObj: cloneDeep(taAccountValuesObj),
      shareclassValuesObj: cloneDeep(shareclassValuesObj)
    },
    selected: {
      taAccountsSelected: getInitialSelectedMap(taAccountMap),
      shareclassSelected: getInitialSelectedMap(shareclassMap),
      firmBranchSelected: { firmNodeMapSelected: getInitialSelectedMap(firmMap), branchNodeMapSelected: getInitialSelectedMap(branchMap)},
      shareclassSelectedWihtoutAcct: getInitialSelectedMap(shareclassNodeMapWithoutAcct),
    },
    accountMap,
    firmMap,
    branchMap,
    taAccountMap,
    shareclassMap,

    firmNodeMap,
    branchNodeMap,
    taAccountNodeMap,
    shareclassNodeMap,
    shareclassNodeMapWithoutAcct,

    firmBranchValuesObj,
    firmBranchValues: Object.values(firmBranchValuesObj),

    taAccountValuesObj,
    taAccountValues: Object.values(taAccountValuesObj),

    shareclassValuesObj,
    shareclassValues: Object.values(shareclassValuesObj),

    shareclassValuesObjWithoutAcct,
    shareclassValuesWithoutAcct: Object.values(shareclassValuesObjWithoutAcct),

    isAllFirmBranchSelected: true,
    isAllTaAccountSelected: true,
    stateUpdated: {update: false}
  }
}

export {
  reducer,
  getInitialState
}
