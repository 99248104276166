import { CssClassDefinitions, CssFactoryPropsType } from './types';
import { StyleSheet } from './style-sheet';

/**
 * Creates a CSS-in-JS Stylesheet which is used to dynamically create CSS at runtime
 * based on a CSS classes definition object or factory function. A factory
 * function can be used to accept parameters that will customize the CSS
 * properties.
 *
 * For Example:
 *
 *     import { createStyleSheet } from '@gs-ux-uitoolkit-react/style';
 *
 *     const styleSheet = createStyleSheet('alert', ({ theme }: AlertStyleSheetProps) => {
 *         root: {
 *             position: 'relative',
 *             padding: theme.padding,
 *             paddingLeft: '44px',
 *             // ...
 *         },
 *         dismissible: {
 *             paddingRight: '14px',
 *             // ...
 *         }
 *     });
 *
 * For more info, see {@link StyleSheet}
 *
 * @param name A name for the StyleSheet, usually the name of the component that the
 *   StyleSheet is for. Example: 'alert'. This is used as part of the name of all generated
 *   CSS classes, and assists in debugging so we can know which CSS classes belong to which
 *   components.
 * @param classDefinitions The CSS class definitions for the StyleSheet. These define the CSS
 *   classes and their associated CSS properties.
 */
export function createStyleSheet<ClassNames extends string, Props extends CssFactoryPropsType>(
    /**
     * A name for the stylesheet, usually the name of the component that the
     * stylesheet is for. Example: 'alert'
     *
     * This is used as part of the name of all generated CSS classes, and
     * assists in debugging so we can know which CSS classes belong to which
     * components.
     */
    name: string,

    /**
     * The CSS class definitions for the StyleSheet. These define the CSS
     * classes and their associated CSS properties.
     */
    classDefinitions: CssClassDefinitions<ClassNames, Props>
) {
    return new StyleSheet(name, classDefinitions);
}
