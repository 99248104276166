import actionTypes from '../../../../actions/page/tradeActivity/approvals/actionTypes';

export function tradeApprovalsPageDataReducer(state = {}, action) {
  const { type } = action;
  switch (type) {
    default:
      return state;
  }
}

export function tradeApprovalsPageContextReducer(state = {}, action) {
  const { type, data = {} } = action;
  switch (type) {
    case actionTypes.TRADE_APPROVAL_DATA_LOADING: {
      return { ...state,
        isTradeApprovalInitialDataLoading: data.initialLoad && data.isLoading,
        isTradeApprovalDataLoading: !data.initialLoad && data.isLoading };
    }
    case actionTypes.APPROVE_TRADE:
    case actionTypes.REJECT_TRADE:
      return {...state, ...action, ...{currentTxnId: action.currentApprovalRowData.id,
        errorObject: undefined, isPageInErrorMode: false}};
    case actionTypes.CLEAR_TRADE_APPROVAL_RESPONSE:
      return {...state, ...action, tradeApprovalResponse:{}};
    case actionTypes.CLEAR_TRADE_REJECTION_RESPONSE:
      return {...state, ...action, tradeRejectionResponse: {}};
    case actionTypes.TRADE_REJECTION_LOADER:
    case actionTypes.TRADE_APPROVAL_LOADER:
    case actionTypes.ADD_TRADE_APPROVAL_DATA:
    case actionTypes.ADD_TRADE_APPROVAL_RESPONSE:
    case actionTypes.RELOAD_TRADE_APPROVAL_DATA:
    case actionTypes.ADD_TRADE_REJECTION_RESPONSE:{
      return {...state, ...action.data};
    }
    // case actionTypes.RELOAD_TRADE_APPROVAL_DATA:
    //   return {...state, tradeApprovalsData: {trades: action.data } };
    case actionTypes.OPEN_TRADE_REJECTION_MODAL: {
      return {...state, ...{rejectionModalState: 'open', currentTxnId: data.id}};
    }
    case actionTypes.CLOSE_TRADE_REJECTION_MODAL: {
      return {...state, ...{rejectionModalState: action.data || 'close',  errorObject: undefined, isPageInErrorMode: false}};
    }
    case actionTypes.UPDATE_TRADE_APPROVAL_DATA:{
      return {...state, tradeApprovalsData : {...state.tradeApprovalsData, 'trades': [...action.data] }};
    }
    case actionTypes.OPEN_TRADE_APPROVAL_MODAL: {
      return {...state, ...{approvalModalState: 'open', currentTxnId: data.id}};
    }
    case actionTypes.CLOSE_TRADE_APPROVAL_MODAL: {
      return {...state, ...{approvalModalState: 'close'}};
    }
    case actionTypes.DISABLE_CURRENT_TRADE: {
      return {...state, lastDisabledTradeId: action.data.transactionId};
    }
    default:
      return state;
  }
}
