import { Injectable } from '@gs-ux-uitoolkit-common/shared';

/**
 * Broadcasts click events to radio inputs in controlled mode, enabling the
 * radio inputs to maintain the correct checked state.
 *
 * "@Injectable()" is a decorator that makes this class compatible with
 * Angular's dependency injection. It is absolutely benign when used in other
 * contexts, such as React.
 */
@Injectable()
export class RadioGroupService {
    private groups: Map<string, Set<RadioOnClick>> = new Map();
    private checked: Map<string, string> = new Map();

    static get instance(): RadioGroupService {
        if (!instance) {
            instance = new RadioGroupService();
        }
        return instance;
    }

    constructor() {
        if (instance) {
            return instance;
        }
        instance = this;
    }

    addRadio(name: string, onClick: RadioOnClick) {
        if (!name) {
            return;
        }
        const group = this.groups.get(name) || new Set();
        if (!this.groups.has(name)) {
            this.groups.set(name, group);
        }
        group.add(onClick);
    }

    removeRadio(name: string, onClick: RadioOnClick) {
        const group = this.groups.get(name);
        if (group && group.has(onClick)) {
            group.delete(onClick);
            if (group.size === 0) {
                this.groups.delete(name);
                this.checked.delete(name);
            }
        }
    }

    clickRadio(name: string, uid: string) {
        const group = this.groups.get(name);
        if (group) {
            group.forEach(onClick => {
                onClick(uid);
            });
        }
    }

    markChecked(name: string, uid: string) {
        if (!name) {
            return;
        }
        this.checked.set(name, uid);
    }

    unmarkChecked(name: string, uid: string) {
        if (!name || uid !== this.checked.get(name)) {
            return;
        }
        this.checked.set(name, '');
    }

    getChecked(name: string) {
        return this.checked.get(name);
    }

    reset() {
        this.groups.clear();
    }
}

let instance: RadioGroupService;

export type RadioOnClick = (uid: string) => void;
