import useFetch from '../useFetch';
import useSnackBar from '../useSnackbar';
import useUserMaintenance from '../../context';
import endPointsMapper from '../../../../../configs/endPointsMapper';
import { UPDATE_FIRMS_AVAILABLE_TO_MAP_COUNT } from '../../actions';

export default function useGetAvailableFirmsToMapCount() {
  const { dispatch } = useUserMaintenance();
  const { openSnackbar } = useSnackBar();

  const onSuccess = availableFirmsToMapCount => {
    dispatch({ type: UPDATE_FIRMS_AVAILABLE_TO_MAP_COUNT, payload: availableFirmsToMapCount });
  };

  const onError = () => {
    openSnackbar({
      type: 'error',
      message: 'Failed to fetch unmapped firms count',
    });
  };

  const { doFetch: getAvailableFirmsToMapCount, loading: countLoader } = useFetch(endPointsMapper.FETCH_UNMAPPED_FIRMS_COUNT, onSuccess, onError);

  return { getAvailableFirmsToMapCount, countLoader };
}
