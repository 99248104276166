/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */
// TODO: https://jira.site.gs.com/browse/UX-14730

import {
    useCallback,
    useContext,
    MouseEvent,
    HTMLAttributes,
    CSSProperties,
    ReactNode,
    FC,
    memo,
} from 'react';

import PropTypes from 'prop-types';
import {
    ListGroupItemProps as ListGroupItemCommonProps,
    getListGroupItemClasses,
    listGroupItemStyleSheet,
    listGroupItemDefaultProps,
    ListGroupSize,
    DraggableConfigProps,
} from '@gs-ux-uitoolkit-common/list-group';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { ListGroupContext } from './list-group-context';
import { Draggable } from '@gs-ux-uitoolkit-react/drag-and-drop';

export type ReactListGroupMouseEvent = MouseEvent<HTMLElement>;

export interface ListGroupItemProps
    extends ListGroupItemCommonProps<CSSProperties, ReactListGroupMouseEvent>,
        Omit<HTMLAttributes<HTMLElement>, 'onClick'> {
    /**
     * Content to display inside of the ListGroupItem.
     */
    children?: ReactNode;

    /**
     * Event raised when an item is clicked in 'action' mode.
     */
    onClick?: (e: MouseEvent<HTMLElement>) => void;
}

/**
 * A List Group Item represents on of the items in a ListGroup.
 */
export const ListGroupItem: FC<ListGroupItemProps> = memo(props => {
    const {
        className,
        children,
        classes: overrideClasses,
        action = listGroupItemDefaultProps.action,
        active = listGroupItemDefaultProps.active,
        disabled = listGroupItemDefaultProps.disabled,
        draggableConfig = listGroupItemDefaultProps.draggableConfig,
        onClick,
        ...attributes
    } = props;

    const theme = useTheme();

    const sizeContext: ListGroupSize = useContext(ListGroupContext).size;

    const dragAndDrop = useContext(ListGroupContext).dragAndDrop;
    const cssClasses = useStyleSheet(listGroupItemStyleSheet, {
        theme,
        size: sizeContext,
        dragAndDrop,
        iconPosition: draggableConfig.iconPosition,
        action,
        active,
        disabled,
    });
    const listGroupItemClasses = getListGroupItemClasses({
        cssClasses,
        className,
        overrideClasses,
    });

    const dragHandleConfigInherit: DraggableConfigProps = {
        ...draggableConfig,
        dragHandleConfig: draggableConfig.dragHandleConfig
            ? {
                  ...draggableConfig.dragHandleConfig,
                  size: draggableConfig.dragHandleConfig.size,
              }
            : {
                  size: sizeContext,
                  name: 'drag-handle',
                  type: 'outlined',
              },
    };

    const handleClick = useCallback(
        (e: MouseEvent<HTMLElement>) => {
            if (disabled || !action) {
                e.preventDefault();
            } else if (onClick) {
                onClick(e);
            }
        },
        [disabled, action, onClick]
    );

    // TODO: Define a custom type and code-mod the onClick events. Call it onSelect?
    // ListGroup requires a lot of changes to be a11y compatible. Apart from handling keyboard
    // events we also need to ensure we use interactive elements (<button>) when there is an
    // onClick() event associated with this
    // const handleKeyPress = useCallback(
    //     (e: KeyboardEvent) => {
    //         if (e.key !== 'Enter') {
    //             return;
    //         }

    //         if (disabled || !action) {
    //             e.preventDefault();
    //         } else if (onClick) {
    //             onClick(e);
    //         }
    //     },
    //     [disabled, action, onClick]
    // );

    return dragAndDrop ? (
        <Draggable
            index={draggableConfig.index}
            draggableId={draggableConfig.draggableId}
            dragHandleConfig={dragHandleConfigInherit.dragHandleConfig}
            grabArea={draggableConfig.grabArea}
            dragIconVisibility={draggableConfig.dragIconVisibility}
            disabled={draggableConfig.disabled}
            iconPlacement={draggableConfig.iconPlacement}
            iconPosition={draggableConfig.iconPosition}
            classes={{
                ...dragHandleConfigInherit.classes,
                boxInner: listGroupItemClasses,
                boxInnerDrag: cssClasses.itemDrag,
                dragHandleClasses: {
                    dragHandle: cssClasses.dragHandle,
                },
            }}
            data-gs-uitk-component="list-group-item"
            data-active={active}
            data-disabled={disabled}
            data-action={action}
            {...attributes}
            onClick={handleClick}
        >
            {() => <li>{children}</li>}
        </Draggable>
    ) : (
        <li
            data-gs-uitk-component="list-group-item"
            data-action={action}
            data-active={active}
            data-disabled={disabled}
            {...attributes}
            className={listGroupItemClasses}
            onClick={handleClick}
        >
            {children}
        </li>
    );
});
ListGroupItem.displayName = 'ListGroupItem';

ListGroupItem.propTypes = {
    className: PropTypes.string,
    action: PropTypes.bool,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};
