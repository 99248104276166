/**
 * List of possible elevation variants in the design system.
 */
export type Elevation = '00' | '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08';

/**
 * Array of possible elevations to allow for easier iteration in demos, etc.
 */
export const elevations: Elevation[] = ['00', '01', '02', '03', '04', '05', '06', '07', '08'];

export interface ElevationVariant {
    boxShadow: string;
}

export const elevationVariants: { [name in Elevation]: ElevationVariant } = {
    '00': {
        boxShadow: 'none',
    },
    '01': {
        boxShadow:
            '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    },
    '02': {
        boxShadow:
            '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
    },
    '03': {
        boxShadow:
            '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)',
    },
    '04': {
        boxShadow:
            '0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px -1px rgba(0, 0, 0, 0.2)',
    },
    '05': {
        boxShadow:
            '0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 5px 5px -3px rgba(0, 0, 0, 0.2)',
    },
    '06': {
        boxShadow:
            '0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12), 0px 7px 8px -4px rgba(0, 0, 0, 0.2)',
    },
    '07': {
        boxShadow:
            '0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12), 0px 8px 10px -5px rgba(0, 0, 0, 0.2)',
    },
    '08': {
        boxShadow:
            '0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 11px 15px -7px rgba(0, 0, 0, 0.2)',
    },
};
