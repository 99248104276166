import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { hasOwnProperty } from 'gs-uitk-object-utils';

export class DatasourceDynamicSchema {
    public static createagGridSchema(data: any[]): (ColDef | ColGroupDef)[] {
        const schema: (ColDef | ColGroupDef)[] = [];
        let firstRow = Array.isArray(data) && data[0];

        firstRow = typeof firstRow === 'object' ? firstRow : {};
        Object.keys(firstRow).forEach(p => {
            if (hasOwnProperty(firstRow, p)) {
                schema.push({ field: p, colId: p });
            }
        });
        return schema;
    }
}
