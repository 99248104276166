import { ThemeManager, ThemeTypeClassName } from '@gs-ux-uitoolkit-common/datacore';
import { Component, CSSProperties } from 'react';
import { AG_BODY_VIEWPORT_CLASS } from '../../../grid-wrappers/grid-wrapper';
import { rowCustomisationPluginStyleSheet } from '../../../style/plugins/row-customisation-plugin-stylesheet';
import { Style } from '../row-customisation';
/**
 * The props for RowCustomisationPreviewer
 */
export interface RowCustomisationPreviewerProps {
    /**
     * a RowCustomisation Style
     */
    style: Style;
}

/**
 * This is a previewer for the row customisation styles
 */
export class RowCustomisationPreviewer extends Component<RowCustomisationPreviewerProps> {
    public componentWillUnmount() {
        rowCustomisationPluginStyleSheet.unmount(this);
    }

    public render() {
        const cssClasses = rowCustomisationPluginStyleSheet.mount(this, {
            height: '32px',
            backgroundColor: this.props.style.backColor,
        });

        const textStyle: CSSProperties = {
            color: this.props.style.foreColor,
            fontSize: this.props.style.fontStyle.fontSize,
            fontStyle: this.props.style.fontStyle.italic ? 'italic' : 'normal',
            fontWeight: this.props.style.fontStyle.bold ? 'bold' : 'normal',
            textDecoration: this.props.style.fontStyle.underline ? 'underline' : 'initial',
            // Match AG Grid's default word wrapping for cells
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        };

        const theme = ThemeManager.getTheme();
        const classnames = `${theme.themeClassNames} ${ThemeTypeClassName.ROOT} ${cssClasses.rowCustomisationPreview} gs-uitk-row-customisation-preview`;
        const cellClassnames = 'ag-cell ag-cell-value gs-uitk-row-customisation-preview-cell';

        return (
            <div className={classnames}>
                <div className={ThemeTypeClassName.AG_GRID_THEME}>
                    <div className={AG_BODY_VIEWPORT_CLASS}>
                        <div className={cellClassnames}>
                            <span style={textStyle}>Preview</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
