import { rgba } from 'polished';
import { colors, ColorName } from './color';
import { Emphasis } from './emphasis';
import { Status } from './status';
import { camelCase } from 'gs-uitk-lodash';

const alphaSubtle = 0.3;

/**
 * @deprecated Use theme tokens instead
 */
export const emphasisColorVariants: { [name in EmphasisColorVariantName]: ColorVariant } = {
    boldGray: {
        backgroundColor: colors.gray060,
        color: colors.white,
    },
    boldRed: {
        backgroundColor: colors.red060,
        color: colors.white,
    },
    boldOrange: {
        backgroundColor: colors.orange050,
        color: colors.white,
    },
    boldYellow: {
        backgroundColor: colors.yellow030,
        color: colors.gray100,
    },
    boldLime: {
        backgroundColor: colors.lime060,
        color: colors.white,
    },
    boldGreen: {
        backgroundColor: colors.green060,
        color: colors.white,
    },
    boldTeal: {
        backgroundColor: colors.teal060,
        color: colors.white,
    },
    boldTurquoise: {
        backgroundColor: colors.turquoise060,
        color: colors.white,
    },
    boldAqua: {
        backgroundColor: colors.aqua070,
        color: colors.white,
    },
    boldBlue: {
        backgroundColor: colors.blue060,
        color: colors.white,
    },
    boldUltramarine: {
        backgroundColor: colors.ultramarine060,
        color: colors.white,
    },
    boldPurple: {
        backgroundColor: colors.purple060,
        color: colors.white,
    },
    boldPink: {
        backgroundColor: colors.pink060,
        color: colors.white,
    },
    subtleGray: {
        backgroundColor: rgba(colors.gray040, alphaSubtle),
        color: colors.gray100,
    },
    subtleRed: {
        backgroundColor: rgba(colors.red040, alphaSubtle),
        color: colors.gray100,
    },
    subtleOrange: {
        backgroundColor: rgba(colors.orange040, alphaSubtle),
        color: colors.gray100,
    },
    subtleYellow: {
        backgroundColor: rgba(colors.yellow020, alphaSubtle),
        color: colors.gray100,
    },
    subtleLime: {
        backgroundColor: rgba(colors.lime040, alphaSubtle),
        color: colors.gray100,
    },
    subtleGreen: {
        backgroundColor: rgba(colors.green040, alphaSubtle),
        color: colors.gray100,
    },
    subtleTeal: {
        backgroundColor: rgba(colors.teal040, alphaSubtle),
        color: colors.gray100,
    },
    subtleTurquoise: {
        backgroundColor: rgba(colors.turquoise040, alphaSubtle),
        color: colors.gray100,
    },
    subtleAqua: {
        backgroundColor: rgba(colors.aqua040, alphaSubtle),
        color: colors.gray100,
    },
    subtleBlue: {
        backgroundColor: rgba(colors.blue040, alphaSubtle),
        color: colors.gray100,
    },
    subtleUltramarine: {
        backgroundColor: rgba(colors.ultramarine040, alphaSubtle),
        color: colors.gray100,
    },
    subtlePurple: {
        backgroundColor: rgba(colors.purple040, alphaSubtle),
        color: colors.gray100,
    },
    subtlePink: {
        backgroundColor: rgba(colors.pink040, alphaSubtle),
        color: colors.gray100,
    },
};

/**
 * @deprecated Use theme tokens instead
 */
export const emphasisStatusVariants: { [name in EmphasisStatusVariantName]: ColorVariant } = {
    boldNone: emphasisColorVariants.boldGray,
    boldInformation: emphasisColorVariants.boldBlue,
    boldSuccess: emphasisColorVariants.boldGreen,
    boldWarning: emphasisColorVariants.boldYellow,
    boldError: emphasisColorVariants.boldRed,
    subtleNone: emphasisColorVariants.subtleGray,
    subtleInformation: emphasisColorVariants.subtleBlue,
    subtleSuccess: emphasisColorVariants.subtleGreen,
    subtleWarning: emphasisColorVariants.subtleYellow,
    subtleError: emphasisColorVariants.subtleRed,
};

/**
 * @deprecated Use theme helper methods instead (theme.getColorInteractionShade)
 */
export function getColorVariant(color: ColorName, emphasis: Emphasis): ColorVariant {
    const emphasisColor = camelCase(`${emphasis} ${color}`) as EmphasisColorVariantName;
    return emphasisColorVariants[emphasisColor];
}

/**
 * @deprecated Use theme helper methods instead (theme.getColorInteractionShade)
 */
export function getStatusVariant(status: Status, emphasis: Emphasis): ColorVariant {
    const emphasisStatus = camelCase(`${emphasis} ${status}`) as EmphasisStatusVariantName;
    return emphasisStatusVariants[emphasisStatus];
}

/**
 * @deprecated Use theme helper methods instead (theme.getColorInteractionShade)
 */
export function getEmphasisStatusClassName(emphasis: Emphasis, status: Status): string {
    return `emphasis-status-${emphasis}-${status}`;
}

/**
 * @deprecated Use theme helper methods instead
 */
export interface ColorVariant {
    backgroundColor: string;
    color: string;
}

/**
 * @deprecated Use the theme types instead
 */
export type EmphasisColorVariantName =
    | 'boldGray'
    | 'boldRed'
    | 'boldOrange'
    | 'boldYellow'
    | 'boldLime'
    | 'boldGreen'
    | 'boldTeal'
    | 'boldTurquoise'
    | 'boldAqua'
    | 'boldBlue'
    | 'boldUltramarine'
    | 'boldPurple'
    | 'boldPink'
    | 'subtleGray'
    | 'subtleRed'
    | 'subtleOrange'
    | 'subtleYellow'
    | 'subtleLime'
    | 'subtleGreen'
    | 'subtleTeal'
    | 'subtleTurquoise'
    | 'subtleAqua'
    | 'subtleBlue'
    | 'subtleUltramarine'
    | 'subtlePurple'
    | 'subtlePink';

/**
 * @deprecated Use the theme types instead
 */
export type EmphasisStatusVariantName =
    | 'boldNone'
    | 'boldInformation'
    | 'boldSuccess'
    | 'boldWarning'
    | 'boldError'
    | 'subtleNone'
    | 'subtleInformation'
    | 'subtleSuccess'
    | 'subtleWarning'
    | 'subtleError';
