import { eventAnalytics } from '../../../actions/sagas';
import { SORT } from '../../../constants/pageConstants';

export const EVENT_NAME = {
  EVENT_GRID_START_LOAD: 'start_load',
  EVENT_GRID_LOAD: 'load',
  EVENT_COLUMN_REARRANGE: 'rearrange',
  EVENT_COLUMN_SORTING: 'sorting',
  EVENT_COLUMN_SEARCH: 'search',
  EVENT_COLUMN_REMOVE: 'drag_remove',
  EVENT_GROUP_CHANGE: 'group_by',
  EVENT_VIEW_CHANGE: 'view_change',
  EVENT_EXPAND_COLLAPSE: 'expand_collapse',
  EVENT_EXPORT_VIEW: 'export_view',
  EVENT_ADD_TO_BLOTTER: 'add_to_blotter',
  EVENT_VIEW_SAVE: 'view_save',
  EVENT_FUND_FACT_OPEN: 'fund_fact_resource_open',
  EVENT_FUND_FACT_CLOSE: 'fund_fact_resource_close',
  EVENT_FUND_FACT_RESOURCE: 'fund_fact_resource',
  EVENT_ACCOUNT_DETAILS_FACT_OPEN: 'account_details_fact_card_open',
  EVENT_ACCOUNT_DETAILS_CLOSE: 'account_details_fact_card_close',
  EVENT_ZERO_BALANCE_FUNDS: 'zero_balance_funds'
}

export const getColumnList = (headers = []) => {
  const visibleColumns = [];
  headers.forEach((header) => {
    if (header) {
      const { hide = false, headerName = ''} = header;
      if(!hide){
        visibleColumns.push(headerName);
      }
    }
  });
  return visibleColumns.join('|');
};

export const getGridStartLoadAnalyticsData = () => {
  return ({
    event : {
      eventName: EVENT_NAME.EVENT_GRID_START_LOAD,
      startTime: Date.now()
    }
  });
};

export const getGridEndLoadAnalyticsData = (eventType, columns, viewName, numberOfRecords, filterTerm, time) => {
  const getFilterTerm = () => eventType === EVENT_NAME.EVENT_COLUMN_SEARCH && { filterTerm };
  return {
    event: {
      eventName: EVENT_NAME.EVENT_GRID_LOAD,
      eventType,
      valueChosen: getColumnList(columns),
      viewChosen: viewName,
      numberOfRecords,
      timeDiff: new Date().getTime() - time,
      ...getFilterTerm()
    }
  };
};

export const getColumnRearrangeAnalyticsData = (columnName) => ({
  event: {
    eventName: EVENT_NAME.EVENT_COLUMN_REARRANGE,
    valueChosen: columnName
  }
});

export const getColumnSortingAnalyticsData = (order, columnName) => ({
  event: {
    eventName: EVENT_NAME.EVENT_COLUMN_SORTING,
    eventStage:  order === SORT.ASC ? 'ascending' : 'descending',
    valueChosen: columnName
  }
});

export const getColumnSearchAnalyticsData = (headerName) => ({
  event: {
    eventName: EVENT_NAME.EVENT_COLUMN_SEARCH,
    valueChosen: headerName
  }
});

export const getColumnRemoveAnalyticsData = (columns, columnName, viewName) => ({
  event: {
    eventName: EVENT_NAME.EVENT_COLUMN_REMOVE,
    valueChosen: getColumnList(columns),
    viewChosen: viewName,
    columnRemoved: columnName
  }
});

export const getGroupChangeAnalyticsData = (groupName) => ({
  event: {
    eventName: EVENT_NAME.EVENT_GROUP_CHANGE,
    eventStage: 'group_by_changed',
    valueChosen: groupName
  }
});

export const getViewChangeAnalyticsData = (columns, viewName) => ({
  event: {
    eventName: EVENT_NAME.EVENT_VIEW_CHANGE,
    eventStage: 'view_changed',
    valueChosen: getColumnList(columns),
    viewChosen:  viewName
  }
});

export const getExpandCollapseAnalyticsData = (state) => ({
  event: {
    eventName: EVENT_NAME.EVENT_EXPAND_COLLAPSE,
    eventStage: state ? 'on': 'off'
  }
});

export const getExportViewAnalyticsData = (columns, viewName, fileType) => ({
  event: {
    eventName: EVENT_NAME.EVENT_EXPORT_VIEW,
    fileType,
    valueChosen: getColumnList(columns),
    viewChosen: viewName
  }
});

export const getAddToBlotterAnalyticsData = () => ({
  event: {
    eventName: EVENT_NAME.EVENT_ADD_TO_BLOTTER
  }
});

export const getSaveViewAnalyticsData = (columns, viewName) => ({
  event: {
    eventName: EVENT_NAME.EVENT_VIEW_SAVE,
    eventStage: 'save_and_apply',
    valueChosen: getColumnList(columns),
    viewChosen: viewName
  }
});

export const getOpenFundFactCardAnalyticsData = () => ({
  page: {
    pageInfo: {
      component:'Fund Fact card'
    }
  },
  event: {
    eventName: EVENT_NAME.EVENT_FUND_FACT_OPEN,
    eventStage:'open'
  }
});

export const getCloseFundFactCardAnalyticsData = () => ({
  event: {
    eventName: EVENT_NAME.EVENT_FUND_FACT_CLOSE,
    eventStage:'close',
    resourceEndTime: Date.now()
  }
});

export const getFundFactCardAnalyticsData = (resourceNameLink) => ({
  event: {
    eventName: EVENT_NAME.EVENT_FUND_FACT_RESOURCE,
    valueChosen: resourceNameLink,
    resourceStartTime: Date.now(),
    eventStage: 'open'
  }
});

export const getOpenAccountDetailsFactCardAnalyticsData = () => ({
  page: {
    pageInfo: {
      component:'Account Details Fact card'
    }
  },
  event: {
    eventName: EVENT_NAME.EVENT_ACCOUNT_DETAILS_FACT_OPEN,
    eventStage:'open'
  }
});

export const getCloseAccountDetailsFactCardAnalyticsData = () => ({
  event: {
    eventName: EVENT_NAME.EVENT_ACCOUNT_DETAILS_CLOSE,
    eventStage:'close',
  }
});

export const getZeroBalanaceAccount = (state) => ({
  event: {
    eventName: EVENT_NAME.EVENT_ZERO_BALANCE_FUNDS,
    eventStage: state ? 'on' : 'off'
  }
});

export const getGridEndLoadOnSearchAnalyticsData = (filterTerm, totalRecords, activeColumns, selectedView) => {
  return ({
    event : {
      eventName: EVENT_NAME.EVENT_GRID_LOAD,
      eventType: 'search',
      filterTerm,
      numberOfRecords: totalRecords,
      viewChosen: selectedView,
      valueChosen: getColumnList(activeColumns),
      timeDiff: 0
    }
  });
};

export const dispatchAnalytics = (dispatch, data) => {
  const cloneData = data.event ? {...data, event: {...data.event}} : {...data};
  dispatch(eventAnalytics(cloneData));
};
