import { StyleSheet, CssProperties } from '@gs-ux-uitoolkit-common/style';
import { Theme } from '@gs-ux-uitoolkit-common/theme';
import { defaultButtonSelectProps } from './button-select-props';
import { ButtonSize } from '../button/button-props';
import {
    focusedBorderMargin,
    applyButtonTypography,
    buttonSizeVariants,
    applyButtonIconAndTextPositions,
    applyButtonCommonBase,
} from '../common/common-style-sheet';
import { getButtonSelectTheme } from './button-select-theme';

export interface ButtonSelectStyleSheetProps {
    theme: Theme;
    size: ButtonSize;
}

export interface ButtonSelectCssClasses {
    root: string;
    label: string;
    input: string;
    target: string;
    focusBorder: string;
    size: string;
}

export const buttonSelectStyleSheet = new StyleSheet(
    'button-select',
    ({ theme, size = defaultButtonSelectProps.size }: ButtonSelectStyleSheetProps) => {
        const buttonSelectTheme = getButtonSelectTheme(theme);

        return {
            root: {
                display: 'inline-block',

                [`[data-cy="gs-uitk-button-select__input"]:disabled + [data-cy="gs-uitk-button-select__target"], 
                    [data-cy="gs-uitk-button-select__input"]:disabled + [data-cy="gs-uitk-button-select__target"]:active`]:
                    {
                        cursor: 'default',
                        opacity: theme.state.disabledOpacity,
                    },
                [`[data-cy="gs-uitk-button-select__input"]:checked + [data-cy="gs-uitk-button-select__target"]`]:
                    {
                        color: buttonSelectTheme.selected.textColor,
                        background: buttonSelectTheme.selected.backgroundColor,

                        '&:hover': {
                            background: buttonSelectTheme.selected.backgroundHoverColor,
                        },
                        '&:active': {
                            background: buttonSelectTheme.selected.backgroundActiveColor,
                        },
                    },
                [`[data-cy="gs-uitk-button-select__input"]:checked:disabled + [data-cy="gs-uitk-button-select__target"], 
                    [data-cy="gs-uitk-button-select__input"]:checked:disabled + [data-cy="gs-uitk-button-select__target"]:active`]:
                    {
                        cursor: 'default',
                        opacity: theme.state.disabledOpacity,
                    },
            },
            label: {
                ...applyButtonBase(),
                padding: '0',
                margin: '0',
                border: '0',
                color: 'transparent',
                background: 'transparent',
            },
            input: {
                width: '100%',
                height: '100%',
                position: 'absolute',
                opacity: '0',
                pointerEvents: 'none',
                margin: 0,
            },
            target: {
                ...applyButtonBase(),

                color: buttonSelectTheme.unselected.textColor,
                background: buttonSelectTheme.unselected.backgroundColor,
                textAlign: 'center',

                '&:hover': {
                    background: buttonSelectTheme.unselected.backgroundHoverColor,
                },
                '&:active': {
                    background: buttonSelectTheme.unselected.backgroundActiveColor,
                },
            },
            focusBorder: {
                ...applyButtonFocusBorder({ theme }),
            },
            size: {
                '[data-cy="gs-uitk-button-select__target"]': {
                    ...applyButtonTypography({ size, theme }),
                    height: buttonSizeVariants[size].buttonHeight,
                    padding: `${buttonSizeVariants[size].buttonPaddingY} 0px`,
                    border: '0px',

                    ...applyButtonIconAndTextPositions({ size }),
                },
            },
        };
    }
);

function applyButtonBase(): CssProperties {
    return {
        ...applyButtonCommonBase(),

        borderRadius: '2px',
    };
}

function applyButtonFocusBorder(props: { theme: Theme }): CssProperties {
    const { theme } = props;

    const borderMargin = '0';
    const focusBorderNotFocused: CssProperties = {
        borderRadius: '2px',
        border: `1px solid transparent`,
        top: borderMargin,
        bottom: borderMargin,
        right: borderMargin,
        left: borderMargin,
    };

    const focusBorderFocused: CssProperties = {
        borderRadius: '4px',
        zIndex: 100,
        borderColor: theme.colorScheme.primary,
        top: focusedBorderMargin,
        bottom: focusedBorderMargin,
        right: focusedBorderMargin,
        left: focusedBorderMargin,
    };

    return {
        position: 'relative', // to position the ::before focus element

        // add the focus border

        '&:before': {
            content: '""',
            position: 'absolute',
            display: 'block',
            transition:
                'border-color 0.15s ease-in-out, ' +
                'border-radius 0.15s ease-in-out, ' +
                'top 0.15s ease-in-out, ' +
                'right 0.15s ease-in-out, ' +
                'bottom 0.15s ease-in-out, ' +
                'left 0.15s ease-in-out',
            ...focusBorderNotFocused,
        },

        '&:not(:disabled):active': {
            '&:focus, &:focus-within': {
                '&:before': {
                    ...focusBorderNotFocused,
                },
            },
        },

        '&:focus, &:focus-within': {
            outline: 0,
            boxShadow: 'none', // instead we use a ::before element to indicate focus

            '&:before': {
                ...focusBorderFocused,
            },
        },
    };
}
