import { eventAnalytics } from '../../actions/sagas';

import {fundFinderAnalytics, portfolioAnalytics, SORT} from '../../constants/pageConstants';

// All the helper function
const getColumnList = (headers) => {
  const visibleColumns = [];
  headers.forEach(header => {
    const { hide = false, headerName = ''} = header;
    if(!hide){
      visibleColumns.push(headerName);
    }
  });
  return visibleColumns.join('|');
};

const getAdjustedColumnsList = (headers) => {
  return getColumnList(headers.filter((header) => header && header.headerName && header.headerName.endsWith('AdjYield')));
};

const containsAdjustedFields = (headers) => {
  return headers && headers.length && headers.some((header) =>
    header && header.headerName && ( header.headerName.endsWith('AdjYield') || header.headerName.startsWith('Adjusted Yield') )
    );
};

export const getGridStartLoadAnalyticsData = () => {
  return ({
    event : {
      eventName: fundFinderAnalytics.EVENT_GRID_START_LOAD,
      startTime: Date.now()
    }
  });
};


// All Analytics
export const getGridEndLoadAnalyticsData = (eventType, columns, viewName, numberOfRecords, filterTerm, time, snackbarList) => {
  const getFilterTerm = () => eventType === portfolioAnalytics.EVENT_COLUMN_SEARCH && { filterTerm };
  const snackbar = (eventType === 'load') ? {snackbarId : snackbarList.map((obj) => (obj.msgCopy || obj.message)).join(" | ")} : undefined;
  const data = {
    event: {
      eventName: portfolioAnalytics.EVENT_GRID_LOAD,
      eventType,
      valueChosen: getColumnList(columns),
      viewChosen: viewName,
      numberOfRecords,
      timeDiff: new Date().getTime() - time,
      ...getFilterTerm(),
      ...(
        containsAdjustedFields(columns)
        && {
          eventDesc: 'RebateFlowInteraction',
          eventDetail: 'PortfolioGridLoadedWithAdjustedYield',
          eventAction: 'GridLoad',
          componentInfo: {
            componentName: 'Portfolio Grid with Rebate'
          },
          satelliteTrackEventName: 'rebatevents'
        }
      )
    }
  };

  return (snackbar ? {...data, snackbar} : data);
};

export const getColumnRearrangeAnalyticsData = (columnName) => ({
  event: {
    eventName: portfolioAnalytics.EVENT_COLUMN_REARRANGE,
    valueChosen: columnName
  }
});

export const getColumnSortingAnalyticsData = (order, columnName) => ({
  event: {
    eventName: portfolioAnalytics.EVENT_COLUMN_SORTING,
    eventStage:  order === SORT.ASC ? 'ascending' : 'descending',
    valueChosen: columnName
  }
});

export const getColumnSearchAnalyticsData = (headerName) => ({
  event: {
    eventName: portfolioAnalytics.EVENT_COLUMN_SEARCH,
    valueChosen: headerName
  }
});

export const getColumnRemoveAnalyticsData = (columns, columnName, viewName) => ({
  event: {
    eventName: portfolioAnalytics.EVENT_COLUMN_REMOVE,
    valueChosen: getColumnList(columns),
    viewChosen: viewName,
    columnRemoved: columnName
  }
});

export const getGroupChangeAnalyticsData = (groupName) => ({
  event: {
    eventName: portfolioAnalytics.EVENT_GROUP_CHANGE,
    eventStage: 'group_by_changed',
    valueChosen: groupName
  }
});

export const getViewChangeAnalyticsData = (columns, viewName) => ({
  event: {
    eventName: portfolioAnalytics.EVENT_VIEW_CHANGE,
    eventStage: 'view_changed',
    valueChosen: getColumnList(columns),
    viewChosen:  viewName
  }
});

export const getExpandCollapseAnalyticsData = (state) => ({
  event: {
    eventName: portfolioAnalytics.EVENT_EXPAND_COLLAPSE,
    eventStage: state ? 'on': 'off'
  }
});

export const getExportViewAnalyticsData = (columns, viewName, fileType) => ({
  event: {
    eventName: portfolioAnalytics.EVENT_EXPORT_VIEW,
    fileType,
    valueChosen: getColumnList(columns),
    viewChosen: viewName
  }
});

export const getAddToBlotterAnalyticsData = () => ({
  event: {
    eventName: portfolioAnalytics.EVENT_ADD_TO_BLOTTER
  }
});

export const getSaveViewAnalyticsData = (columns) => ({
  event: {
    eventName: portfolioAnalytics.EVENT_VIEW_SAVE,
    eventStage: 'save_and_apply',
    valueChosen: getColumnList(columns),
    ...(
      containsAdjustedFields(columns)
      && {
        eventDesc: 'RebateFlowInteraction',
        eventDetail: getAdjustedColumnsList(columns),
        eventAction: 'Adjusted Yield Checkbox Saved Clicks',
        componentInfo: {
          componentName: 'Portfolio Grid - Save Settings'
        },
        satelliteTrackEventName: 'rebatevents'
      }
    )
  }
});

export const getOpenFundFactCardAnalyticsData = () => ({
  page: {
    pageInfo: {
      component:'Fund Fact card'
    }
  },
  event: {
    eventName: portfolioAnalytics.EVENT_FUND_FACT_OPEN,
    eventStage:'open'
  }
});

export const getCloseFundFactCardAnalyticsData = () => ({
  event: {
    eventName: portfolioAnalytics.EVENT_FUND_FACT_CLOSE,
    eventStage:'close',
    resourceEndTime: Date.now()
  }
});

export const getFundFactCardAnalyticsData = (resourceNameLink) => ({
  event: {
    eventName: 'fund_fact_resource',
    valueChosen: resourceNameLink,
    resourceStartTime: Date.now(),
    eventStage: 'open'
  }
});

export const getOpenAccountDetailsFactCardAnalyticsData  = () => ({
  page: {
    pageInfo: {
      component:'Account Details Fact card'
    }
  },
  event: {
    eventName: portfolioAnalytics.EVENT_ACCOUNT_DETAILS_FACT_OPEN,
    eventStage:'open'
  }
});

export const getCloseAccountDetailsFactCardAnalyticsData  = () => ({
  event: {
    eventName: portfolioAnalytics.EVENT_ACCOUNT_DETAILS_CLOSE,
    eventStage:'close',
  }
});

export const getZeroBalanaceAccount = (state) => ({
  event: {
    eventName: portfolioAnalytics.EVENT_ZERO_BALANCE_FUNDS,
    eventStage: state ? 'on' : 'off'
  }
});

export const dispatchAnalytics = (dispatch, data) => {
  const cloneData = data.event ? {...data, event: {...data.event}} : {...data};
  dispatch(eventAnalytics(cloneData));
};
