import { ComponentProps, CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { Size } from '@gs-ux-uitoolkit-common/design-system';
import { IconBaseProps } from '@gs-ux-uitoolkit-common/icon-font';
import {
    DragAndDropStateUpdateEvent,
    DragAndDropHandleVisibility,
    DragAndDropDragArea,
    DragAndDropIconPlacement,
    DragAndDropIconPosition,
    DraggableOverrideClasses,
} from '@gs-ux-uitoolkit-react/drag-and-drop';

export type DraggableConfigProps = {
    draggableId?: string;
    index: number;
    dragHandleConfig?: IconBaseProps;
    dragIconVisibility?: DragAndDropHandleVisibility;
    grabArea?: DragAndDropDragArea;
    disabled?: boolean;
    iconPosition?: DragAndDropIconPosition;
    iconPlacement?: DragAndDropIconPlacement;
    classes?: DraggableOverrideClasses;
};

export type ListGroupProps<StyleType = CommonStyleType> = ConstListGroupProps<StyleType> &
    ConditionalListGroupProps;
export interface ConstListGroupProps<StyleType = CommonStyleType>
    extends ComponentProps<StyleType> {
    /**
     * Style classes to override.
     */
    classes?: ListGroupOverrideClasses;

    /**
     * Whether or not the items should be laid out horizontally.
     */
    horizontal?: boolean;

    /**
     * Size of the ListGroup.
     */
    size?: ListGroupSize;
}

export type ConditionalListGroupProps =
    | {
          /**
           * Enables and diables drag and drop in ListGroup
           */
          dragAndDrop?: false;

          /**
           * Callback for mutating data after drop
           */
          onStateUpdate?: (event: DragAndDropStateUpdateEvent) => void;

          /**
           * Id for undrling Droppable component
           */
          droppableId?: string;

          /**
           * When true, it allows for more complex drag and drop with externaly managed context (e.g. dragging between lists)
           */
          disableInternalDragAndDropContext?: boolean;
      }
    | {
          /**
           * Enables and diables drag and drop in ListGroup
           */
          dragAndDrop: true;

          /**
           * Callback for mutating data after drop
           */
          onStateUpdate: (event: DragAndDropStateUpdateEvent) => void;

          /**
           * Id for undrling Droppable component
           */
          droppableId?: string;

          /**
           * When true, it allows for more complex drag and drop with externaly managed context (e.g. dragging between lists)
           */
          disableInternalDragAndDropContext?: boolean;
      };

export interface ListGroupItemProps<StyleType = CommonStyleType, MouseEventType = MouseEvent>
    extends ComponentProps<StyleType> {
    /**
     * Whether or not the item responds to hover/click events.
     */
    action?: boolean;

    /**
     * Whether or not the item is currently active.
     */
    active?: boolean;

    /**
     * Style classes to override.
     */
    classes?: ListGroupOverrideClasses;

    /**
     * Whether or not the item is disabled.
     */
    disabled?: boolean;

    /**
     * Handler for the click event.
     */
    onClick?: (event: MouseEventType) => void;

    draggableConfig?: DraggableConfigProps;
}

export type ListGroupSize = Extract<Size, 'sm' | 'md' | 'lg'>;
export const listGroupSizes: ListGroupSize[] = ['sm', 'md', 'lg'];

export type ListGroupDefaultProps = Required<
    Pick<
        ListGroupProps,
        | 'size'
        | 'horizontal'
        | 'dragAndDrop'
        | 'onStateUpdate'
        | 'disableInternalDragAndDropContext'
    >
>;

export const listGroupDefaultProps: ListGroupDefaultProps = {
    horizontal: false,
    size: 'md',
    dragAndDrop: false,
    onStateUpdate: _event => {},
    disableInternalDragAndDropContext: false,
};

export type ListGroupItemDefaultProps = Required<
    Pick<ListGroupItemProps, 'action' | 'active' | 'disabled' | 'draggableConfig'>
>;

export const listGroupItemDefaultProps: ListGroupItemDefaultProps = {
    action: false,
    active: false,
    disabled: false,
    draggableConfig: {
        index: 0,
        draggableId: undefined,
        dragHandleConfig: { size: 'md', type: 'outlined', name: 'drag-handle' },
        dragIconVisibility: 'visible',
        grabArea: 'anywhere',
        disabled: false,
        iconPosition: 'trailing',
        iconPlacement: 'top',
        classes: {},
    },
};

export type ListGroupOverrideClasses = Partial<Record<'root' | 'item', string>>;
