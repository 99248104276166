import {
    EXPRESSION_GROUP_CONDITION_PROPERTY_NAME,
    ExpressionCondition,
    ExpressionGroup,
    ExpressionOperator,
    ExpressionQuery,
    ExpressionRule,
    NEGATE_FRIENDLY_STRING,
    EMPTY_EXPRESSION,
    EmptyExpressionRule,
} from '@gs-ux-uitoolkit-common/dra';
import { QuickFilter } from '../../plugin/quick-filter/quickfilter';
import { logger } from '../logger';
import { arrayHelper } from './array-helper';
import { hasOwnProperty } from 'gs-uitk-object-utils';

export interface Point {
    x: number;
    y: number;
}

export interface ExpressionColumn {
    primaryColumnId: string;
    columnId: string;
    columnLabel: string;
    pivotKeys: string[] | undefined;
}

export const invariantQuery: ExpressionQuery = { condition: ExpressionCondition.And, rules: [] };

export class GenericExpressionHelper {
    /**
     * Test is the expression is a basic expression compatible which is different from mode === basic
     * Structure will be like that
     *
     * {
     *  condition : 'AND',
     *  rules: [
     *          {
     *              condition: 'OR'
     *              rules: [{field:'col1', operator:'=', value:'thevalue'},{field:'col1', operator:'=', value:'thevalue2'}]
     *          },
     *          {
     *              condition: 'OR'
     *              rules: [{field:'col2', operator:'>', value:'0'}]
     *          }
     *          ]
     * }
     * @param expressionQuery the Expression
     */
    /**
     *
     * @param expressionQuery
     * @param onlyOneLevelOfRules set this to check for one level of rules so no nested rules allowed.
     */
    public isBasicCompatibleExpression(
        expressionQuery: ExpressionQuery,
        onlyOneLevelOfRules?: boolean
    ): boolean {
        // top element has to be a AND group
        if (!this.isGroupRule(expressionQuery)) {
            return false;
        }
        const topGroup = expressionQuery as ExpressionGroup;
        if (topGroup.condition !== ExpressionCondition.And || topGroup.negate) {
            return false;
        }
        // All rules in that group should be OR groups
        return topGroup.rules.every(rule => {
            if (!this.isGroupRule(rule)) {
                return false;
            }
            const subGroup = rule as ExpressionGroup;
            if (subGroup.condition !== ExpressionCondition.Or || subGroup.negate) {
                return false;
            }
            let subGroupColumnName = '';
            // should be simple rules only for the same column
            return subGroup.rules.every(columnRule => {
                if (onlyOneLevelOfRules) {
                    // check to see if there is nested rules.
                    return (columnRule as ExpressionGroup).rules ? false : true;
                }
                if (!this.isSingleRule(columnRule)) {
                    return false;
                }
                const castedColumnRule = columnRule as ExpressionRule;
                if (castedColumnRule.negate) {
                    return false;
                }
                if (subGroupColumnName === '') {
                    subGroupColumnName = castedColumnRule.field;
                }
                return subGroupColumnName === castedColumnRule.field;
            });
        });
    }
    public toString(
        expressionQuery: ExpressionQuery,
        columnList: ExpressionColumn[],
        includeColumnName: boolean
    ): string {
        let toStringValue = '';
        if (this.isEmptyRule(expressionQuery)) {
            toStringValue = 'Empty';
        } else if (this.isSingleRule(expressionQuery)) {
            toStringValue = this.expressionRuletoString(
                expressionQuery as ExpressionRule,
                columnList,
                includeColumnName
            );
        } else if (this.isGroupRule(expressionQuery)) {
            toStringValue = this.expressionGrouptoString(
                expressionQuery as ExpressionGroup,
                columnList,
                includeColumnName
            );
        }
        return toStringValue;
    }
    public isSingleRule(expressionQuery: ExpressionQuery): boolean {
        return !this.isGroupRule(expressionQuery);
    }

    public isGroupRule(expressionQuery: ExpressionQuery): boolean {
        return hasOwnProperty(expressionQuery, EXPRESSION_GROUP_CONDITION_PROPERTY_NAME);
    }
    public simpleExpressionRuletoString(expressionRule: ExpressionRule): string {
        return `${expressionRule.operator} '${expressionRule.value}'`;
    }
    /**
     * Expands all the quick filters into their actual rules. Mutates the expression query passed in
     */
    public expandQuickFiltersFromExpressionQuery(
        expressionQuery: ExpressionQuery,
        expressionQueryParent: ExpressionQuery | null,
        quickFilters: QuickFilter[]
    ): ExpressionQuery {
        if (this.isSingleRule(expressionQuery)) {
            if (this.isEmptyRule(expressionQuery)) {
                return expressionQuery;
            }
            if ((expressionQuery as ExpressionRule).operator === ExpressionOperator.QuickFilter) {
                if (expressionQueryParent) {
                    this.expressionRuleQuickFilterExpand(
                        expressionQuery as ExpressionRule,
                        expressionQueryParent as ExpressionGroup,
                        quickFilters
                    );
                }
            }
        } else if (this.isGroupRule(expressionQuery)) {
            (expressionQuery as ExpressionGroup).rules.forEach(rule => {
                this.expandQuickFiltersFromExpressionQuery(rule, expressionQuery, quickFilters);
            });
        }
        return expressionQuery;
    }

    public isValid(query: ExpressionQuery) {
        return this.hasExpressionRules(query);
    }

    public isEmptyRule(expressionQuery: ExpressionQuery): boolean {
        return (
            hasOwnProperty(expressionQuery, 'type') &&
            hasOwnProperty(expressionQuery, 'emptyFilterShowAll') &&
            (expressionQuery as EmptyExpressionRule).type === EMPTY_EXPRESSION
        );
    }

    private hasExpressionRules(expressionQuery: ExpressionQuery, isValid?: boolean): boolean {
        let hasRules: boolean = isValid !== undefined ? isValid : true;
        if (this.isGroupRule(expressionQuery)) {
            const group: ExpressionGroup = expressionQuery as ExpressionGroup;

            if (!group.rules.length) hasRules = false;

            group.rules.forEach((query: ExpressionQuery) => {
                hasRules = this.hasExpressionRules(query, hasRules);
            });
        } else {
            const rule: ExpressionRule = expressionQuery as ExpressionRule;
            if (rule.value == null) hasRules = false;
        }
        return hasRules;
    }
    private expressionRuletoString(
        expressionRule: ExpressionRule,
        columnList: ExpressionColumn[],
        includeColumnName: boolean
    ): string {
        const column =
            expressionRule.pivotKeys && expressionRule.pivotKeys.length
                ? columnList.find(
                      col =>
                          col.primaryColumnId === expressionRule.field &&
                          arrayHelper.areIdentical(
                              col.pivotKeys || [],
                              expressionRule.pivotKeys || []
                          )
                  )
                : columnList.find(col => col.columnId === expressionRule.field);
        if (column) {
            if (includeColumnName) {
                return `${expressionRule.negate ? NEGATE_FRIENDLY_STRING : ''} [${
                    column.columnLabel
                }] ${expressionRule.operator} '${expressionRule.value}'`;
            }
            return `${expressionRule.negate ? NEGATE_FRIENDLY_STRING : ''} ${
                expressionRule.operator
            } '${expressionRule.value}'`;
        }
        if (expressionRule.field) {
            logger.error(`Cannot find field ${expressionRule.field}`);
        }

        const errorMessage = expressionRule.field === '' ? 'NULL' : '(ERROR)';

        return `${expressionRule.negate ? NEGATE_FRIENDLY_STRING : ''} [${
            expressionRule.field + errorMessage
        }] ${expressionRule.operator} '${expressionRule.value}'`;
    }

    private expressionGrouptoString(
        expressionGroup: ExpressionGroup,
        columnList: ExpressionColumn[],
        includeColumnName: boolean
    ): string {
        const returnStringList: string[] = [];
        expressionGroup.rules.forEach(rule =>
            returnStringList.push(this.toString(rule, columnList, includeColumnName))
        );
        if (returnStringList.length === 1) {
            return returnStringList[0];
        }
        const joinString = ` ${expressionGroup.condition} `;
        return `${expressionGroup.negate ? NEGATE_FRIENDLY_STRING : ''}( ${returnStringList.join(
            joinString
        )} )`;
    }

    /**
     * Takes a rule and converts the quick filter into an expression group
     * @param quickFilterExpressionRule
     * @param parent
     */
    private expressionRuleQuickFilterExpand(
        quickFilterExpressionRule: ExpressionRule,
        parent: ExpressionGroup,
        quickFilters: QuickFilter[]
    ) {
        const foundQuickFilterRule = quickFilters.find(
            quickFilter => quickFilter.name === quickFilterExpressionRule.value
        );
        // Add the quickfilter rule to the parent
        if (foundQuickFilterRule) {
            parent.rules.push(
                this.expandQuickFiltersFromExpressionQuery(
                    foundQuickFilterRule.expression?.query ?? invariantQuery,
                    null,
                    quickFilters
                )
            );
        } else {
            logger.error(`Cannot find Quick Filter ${quickFilterExpressionRule.value}`);
        }

        // remove the current rule from the parent
        const quickFilterRuleInParentIndex = parent.rules.findIndex(
            rule => rule === quickFilterExpressionRule
        );
        if (quickFilterRuleInParentIndex > -1) {
            parent.rules.splice(quickFilterRuleInParentIndex, 1);
        }
    }
}
export const genericExpressionHelper = new GenericExpressionHelper();
