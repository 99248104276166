import { useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import useFetch from '../useFetch';
import endPointsMapper from '../../../../../configs/endPointsMapper';

export default function useGetRecentSearch(userGuid, categoryTypeName, limit = 5) {
  const url = useMemo(() => {
    const endpoint = cloneDeep(endPointsMapper.FETCH_RECENT_ORG_SEARCH);
    endpoint.path = `${endpoint.path}?requestedUserGuid=${userGuid}&categoryTypeName=${categoryTypeName}&limit=${limit}`;
    return endpoint;
  }, [userGuid, categoryTypeName, limit]);

  const { doFetch, data } = useFetch(url);

  return { getRecentSearch: doFetch, recentSearchData: data };
}
