import { DebouncedFunc, debounce } from 'gs-uitk-lodash';
import { Component, ChangeEvent } from 'react';
import { Input, InputSize } from '@gs-ux-uitoolkit-react/input';

export interface SearchProps {
    debounceTime: number;
    value: string;
    placeholder?: string;
    onChange: (value: string) => void;
    size?: InputSize;
}
export interface SearchState {
    inputValue: string;
}

export class SearchInput extends Component<SearchProps, SearchState> {
    private debounceOnChange: DebouncedFunc<() => void>;
    constructor(props: SearchProps) {
        super(props);
        this.state = {
            inputValue: props.value ? props.value : '',
        };
        this.debounceOnChange = debounce(
            () => this.props.onChange(this.state.inputValue),
            this.props.debounceTime
        );
    }
    public componentDidUpdate(prevProps: SearchProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({
                inputValue: this.props.value,
            });
        }
    }
    public render() {
        const onChange = (event: ChangeEvent<HTMLInputElement>) => this.onChange(event);
        return (
            <Input
                className="search-input"
                type="search"
                size={this.props.size || 'sm'}
                clearable={true}
                onClearClick={this.onClearClick}
                onChange={onChange}
                value={this.state.inputValue}
                placeholder={this.props.placeholder}
                data-cy="search-input"
            />
        );
    }
    private onClearClick = () => {
        this.setState(
            {
                inputValue: '',
            },
            () => this.debounceOnChange()
        );
    };
    private onChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState(
            {
                inputValue: event.target.value,
            },
            () => this.debounceOnChange()
        );
    }
}
