import React from 'react';
import ControlPanel  from './ControlPanel';
import Grid from './Grid';
import { MODULES_KEYS } from '../../../constants/pageConstants';
import WithLoader from '../../WithLoader';
import './index.scss';

const CurrentTrades = () => {
  return(
    <WithLoader>
      <div className="currentTrades">
        <ControlPanel id={MODULES_KEYS.CURRENT_TRADES} />
        <div className="currentTrades__grid">
          <Grid id={MODULES_KEYS.CURRENT_TRADES} />
        </div>
      </div>
    </WithLoader>
  );
};

export default CurrentTrades;
