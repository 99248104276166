import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import {createDeepEqualSelector} from "../../utils/selectorUtils"
import {shareClassFromFundFinderOrPreferenceSelector} from "../app";

const globalPreferences = {};
export const preferenceSelector = state => state.preferences;
export const globalPreferenceSelector = state => state.preferences && state.preferences.global || globalPreferences;
export const selectedFirmsSelector = state => state.preferences && state.preferences.global && state.preferences.global.selectedFirms || [];
export const accountIdentifierPreference = state => state.preferences && state.preferences.global && state.preferences.global.accountIdentifier || '';
export const fundIdentifierPreference = state => state.preferences && state.preferences.global && state.preferences.global.fundIdentifier || '';
export const selectedTradeTemplateId = state => state.preferences &&  state.preferences.global && state.preferences.global.selectedTradeTemplateId || '';
export const topOpeningBalanceShareclassIdsSelector = state => state.preferences &&  state.preferences.fundTracker && state.preferences.fundTracker.topOpeningBalanceShareclassIds || [];
export const lastShareclassIdsSelector = state => state.preferences &&  state.preferences.fundTracker && state.preferences.fundTracker.lastShareclassIds || [];
export const primaryMetricFromFundFinderOrPreferenceSelector = createDeepEqualSelector(
  shareClassFromFundFinderOrPreferenceSelector,
  (shareClassDetails = {}) => {
    const {primaryShareClassDetails} = shareClassDetails;
    if (primaryShareClassDetails) {
      return primaryShareClassDetails.metric;
    }
    return "";
  });


export const defaultFundTrackerShareClassIdsSelector = createDeepEqualSelector(
  topOpeningBalanceShareclassIdsSelector,
  lastShareclassIdsSelector,
  shareClassFromFundFinderOrPreferenceSelector,
  (topOpeningBalanceShareclassIds = [], lastShareclassIds = [], shareClassFromFundFinderOrPreference) => {
    let shareClassIds = [];
    // If user navigates from FundFinder or Preference screen, that takes first precedence
    if (!isEmpty(shareClassFromFundFinderOrPreference) && !isEmpty(shareClassFromFundFinderOrPreference.primaryShareClassDetails)) {
      const {primaryShareClassDetails: {shareClassId}} = shareClassFromFundFinderOrPreference;
      shareClassIds = [shareClassId];
    } else if (!isEmpty(lastShareclassIds)) { // As a list of user preferred shareclass(es) id takes second precedence, return it if found in preferences
      shareClassIds = uniq(lastShareclassIds);
    } else if (!isEmpty(topOpeningBalanceShareclassIds)) { // Followed by top opening shareclass ids grabbed from portfolio details call
      shareClassIds = uniq(topOpeningBalanceShareclassIds);
    }
    if (!isEmpty(shareClassIds)) {
      const [primaryShareClassId, ...restIds] = shareClassIds;
      const restShareClassIds = restIds && restIds.map(shareclassId => ({type: 'fund', value: shareclassId})) || [];
      shareClassIds = [primaryShareClassId, ...restShareClassIds];
    }
    return shareClassIds;
  });

export default {
  preferenceSelector
};
