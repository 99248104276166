const tradeElem = document.getElementById('tradeSelectAnimation');
const fixedMargin = 13;
let shouldAnimate = true;

export const animationFunc = (animateText, e) => {
  if(animateText && shouldAnimate && tradeElem) {
    const tradeBlotter = document.getElementsByClassName('tradeBlotter')[0];
    let gridWrapper = document.getElementById('grid-wrapper-default');
    if(gridWrapper){
      gridWrapper = gridWrapper.parentElement;
    }else{
      return;
    }

    shouldAnimate = false;
    tradeElem.textContent = animateText;
    const { top } = e.target.getBoundingClientRect();
    const marginTop = gridWrapper.offsetHeight + fixedMargin + (gridWrapper.offsetTop - top) - window.pageYOffset;

    tradeElem.style.top = `${tradeBlotter.offsetTop - window.pageYOffset}px`;
    tradeElem.style.marginTop = `-${marginTop}px`;
    tradeElem.style.left = '50%';
    tradeElem.style.visibility = 'visible';
    tradeElem.style.animation  = '';

    tradeElem.classList.remove('animation-compress-and-slide-down-row');
    /* eslint-disable-next-line */
    void tradeElem.offsetWidth;// we need to read prop,that will cause recal and animation will be reset
    tradeElem.classList.add('animation-compress-and-slide-down-row');
  }
};
