import { Component, Fragment } from 'react';
import {
    ColumnHint,
    ColumnHintDefaults,
    DefaultColumnHintTypes,
    logger,
} from '@gs-ux-uitoolkit-common/datacore';
import { GridColumn } from '../../../../grid-wrappers/grid-wrapper';
import { ButtonGroup } from '../../../../components/buttons/buttons-group';
import { columnHintHelper } from '../../column-hint-helper';
import { ManagementTable, TableHeadings } from '../../../../components/table/management-table';

export interface ColumnHintConfigTableProps {
    /**
     * Configured column hints
     */
    configItemList: ColumnHint[];
    /**
     * Columns from the grid
     */
    columns: GridColumn[];
    /**
     * Default column hints
     */
    defaults: ColumnHintDefaults;
    /**
     * Remove a column hint
     */
    removeConfigItem: (columnHint: ColumnHint) => void;
    /**
     * Edit a column hint
     */
    editConfigItem: (columnHint: ColumnHint) => void;
    /**
     * Removes the default column hint type
     */
    removeDefaultColumnHint: (hintType: DefaultColumnHintTypes) => void;
    /**
     * When a default column hint is edited
     */
    editDefaultColumnHint: (hintType: DefaultColumnHintTypes) => void;
}

export class ColumnHintConfigTable extends Component<ColumnHintConfigTableProps> {
    public render() {
        const columnLabelAndHints = this.props.configItemList.map(columnHint => {
            const column = this.props.columns.find(
                col =>
                    col.columnId ===
                    columnHintHelper.getPivotedColumnId(columnHint, this.props.columns)
            );
            if (!column) {
                logger.error(
                    `Could not find column with id ${columnHintHelper.getPivotedColumnId(
                        columnHint,
                        this.props.columns
                    )} for column hints`,
                    columnHint
                );
            }
            return { columnHint, columnLabel: (column && column.columnLabel) || 'Unknown Column' };
        });

        const columnHintElements = columnLabelAndHints
            .sort((a, b) => {
                const x = a.columnLabel.toLowerCase();
                const y = b.columnLabel.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
            })
            .map(columnLabelAndHint => {
                const columnHint = columnLabelAndHint.columnHint;

                const editColumnHint = () => this.props.editConfigItem(columnHint);
                const removeColumnCustomisation = () => this.props.removeConfigItem(columnHint);
                return (
                    <tr key={columnHintHelper.getPivotedColumnId(columnHint, this.props.columns)}>
                        <td>{columnLabelAndHint.columnLabel}</td>
                        <td>
                            <ButtonGroup
                                editClick={editColumnHint}
                                deleteClick={removeColumnCustomisation}
                            />
                        </td>
                    </tr>
                );
            });

        const columnhintheader: TableHeadings[] = [
            {
                label: 'Column Name',
                props: {
                    className: 'column-name-column',
                },
            },
            {
                label: '',
                props: {
                    className: 'action-column',
                },
            },
        ];

        return (
            <ManagementTable
                headingConfig={columnhintheader}
                bodyRowElements={[...columnHintElements, this.buildDefaultsBody()]}
            />
        );
    }

    private buildDefaultRow = (name: string, hintType: DefaultColumnHintTypes) => {
        return (
            <tr data-hint-type={hintType} key={name}>
                <td>
                    <b>Default: </b> {name}
                </td>
                <td>
                    <ButtonGroup
                        editClick={() => this.props.editDefaultColumnHint(hintType)}
                        deleteClick={() => this.props.removeDefaultColumnHint(hintType)}
                    />
                </td>
            </tr>
        );
    };

    private buildDefaultsBody = () => {
        const dateDefaults = this.props.defaults.dateDefaults
            ? this.buildDefaultRow('Date', DefaultColumnHintTypes.Date)
            : null;
        const dateTimeDefaults = this.props.defaults.dateTimeDefaults
            ? this.buildDefaultRow('DateTime', DefaultColumnHintTypes.DateTime)
            : null;
        const numberDefaults = this.props.defaults.numberDefaults
            ? this.buildDefaultRow('Number', DefaultColumnHintTypes.Number)
            : null;
        const stringDefaults = this.props.defaults.stringDefaults
            ? this.buildDefaultRow('String', DefaultColumnHintTypes.String)
            : null;
        const defaultHints = columnHintHelper.hasDefaultHints(this.props.defaults)
            ? this.buildDefaultRow('All Columns', DefaultColumnHintTypes.Default)
            : null;

        return (
            <Fragment key={'default-column-hints'}>
                {dateDefaults}
                {dateTimeDefaults}
                {numberDefaults}
                {stringDefaults}
                {defaultHints}
            </Fragment>
        );
    };
}
