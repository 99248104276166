import { eventAnalytics } from '../../../../actions/sagas';

export const dispatchAnalytics = (dispatch, data) => {
  const cloneData = data.event ? { ...data, event: { ...data.event } } : { ...data };
  dispatch(eventAnalytics(cloneData));
};

export const searchResultSelected = (searchTerm, searchType) => ({
  event: {
    eventName: 'auto_search_result_selected',
    searchType,
    searchTerm,
    startTime: Date.now(),
  },
});

export const userDetailsLoad = () => ({
  page: {
    pageInfo: {
      component: 'user card',
    },
  },
  event: {
    eventName: 'user_card_open',
    eventStage: 'open',
    endTime: Date.now(),
  },
});

export const userDetailsTabChange = payload => ({
  event: {
    eventName: 'user_card_link',
    valueChosen: payload,
    resourceStartTime: Date.now(),
    eventStage: 'open',
  },
});

export const userDetailsClose = () => ({
  event: {
    eventName: 'user_card_close',
    eventStage: 'close',
    resourceEndTime: Date.now(),
  },
});
