import { cx, CssClasses } from '@gs-ux-uitoolkit-common/style';
import { ColProps } from './col-props';
import { colStyleSheet } from './col-style-sheet';
import { ContainerProps } from './container-props';
import { containerStyleSheet } from './container-style-sheet';
import { RowProps } from './row-props';
import { rowStyleSheet } from './row-style-sheet';
import { OverlayContainerProps, overlayContainerStyleSheet } from './overlay-container-style-sheet';
import { boxStyleSheet } from './box-style-sheet';
import { flexStyleSheet } from './flex-style-sheet';

const globalClassName = 'gs-layout';

export const getColClassNames = (props: {
    cssClasses: CssClasses<typeof colStyleSheet>;
    className: ColProps['className'];
}): string => {
    const { cssClasses, className } = props;
    return cx(cssClasses.root, className, `${globalClassName}-col`);
};

export const getContainerClassNames = (props: {
    cssClasses: CssClasses<typeof containerStyleSheet>;
    className: ContainerProps['className'];
}): string => {
    const { cssClasses, className } = props;
    return cx(cssClasses.root, className, `${globalClassName}-container`);
};

export const getRowClassNames = (props: {
    cssClasses: CssClasses<typeof rowStyleSheet>;
    className: RowProps['className'];
}): string => {
    const { cssClasses, className } = props;
    return cx(cssClasses.root, className, `${globalClassName}-row`);
};

export const getOverlayContainerClassNames = (props: {
    cssClasses: CssClasses<typeof overlayContainerStyleSheet>;
    className?: OverlayContainerProps['className'];
}): string => {
    const { cssClasses, className } = props;
    return cx(cssClasses.root, className, `${globalClassName}-overlay-container`);
};

export const getBoxClassNames = (props: {
    cssClasses: CssClasses<typeof boxStyleSheet>;
    className?: string;
}): string => {
    const { cssClasses, className } = props;
    return cx(cssClasses.root, className, 'gs-box');
};

export const getFlexClassNames = (props: {
    cssClasses: CssClasses<typeof flexStyleSheet>;
    className?: string;
}): string => {
    const { cssClasses, className } = props;
    return cx(cssClasses.root, className, 'gs-flex');
};
