import { PopoverBaseProps } from '@gs-ux-uitoolkit-common/popover-base';
import { Size } from '@gs-ux-uitoolkit-common/design-system';

export interface PopoverProps extends PopoverBaseProps {
    /**
     * Size of the popover
     */
    size?: PopoverSize;
}

export type PopoverSize = Extract<Size, 'sm' | 'md'>;

export const defaultPopoverProps: DefaultPopoverProps = {
    size: 'md',
};

export type DefaultPopoverProps = Required<Pick<PopoverProps, 'size'>>;
