import { cx } from '@gs-ux-uitoolkit-common/style';
import { DatePickerProps } from './date-picker-props';
import { DatePickerCssClasses } from './date-picker-style-sheet';

const globalClassName = 'gs-date-picker';

export const getDatePickerRootClasses = (props: {
    cssClasses: DatePickerCssClasses;
    overrideClasses?: DatePickerProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(cssClasses.root, overrideClasses?.root, globalClassName);
};

let datepickerUniqueId = 0;
/**
 * Returns a unique id to apply as a data-id attribute on the datepicker and its calendar,
 * so that we can find the calendar in tests.
 */
export function getNextDatepickerId(): number {
    return ++datepickerUniqueId;
}
