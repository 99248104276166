import { Events, IComponent, IHeaderParams } from '@ag-grid-community/core';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { createRoot } from 'react-dom/client';
import { getGridOptionsService } from '../util/getGridOptionsService';
/**
 * This is our Custom ExpansionDepthHeaderRenderer
 */
export class ExpansionDepthHeaderRenderer implements IComponent<IHeaderParams> {
    private eGui: HTMLDivElement;
    private eMenu: HTMLSpanElement | null = null;
    private eIncreaseButton: HTMLSpanElement | null = null;
    private eDecreaseButton: HTMLSpanElement | null = null;
    private params: IHeaderParams | null = null;
    private maxDepth: number = 1;
    private minDepth: number = 0;
    private SORT_DIR_TO_CLASS_MAP: any = {
        asc: '.ag-sort-ascending-icon',
        desc: '.ag-sort-descending-icon',
    };
    constructor() {
        // create the header
        this.eGui = document.createElement('div');
    }
    public init(params: IHeaderParams) {
        this.params = params;
        const gridOptionsService = getGridOptionsService(this.params?.api);
        if (this.params) {
            if (!gridOptionsService.getNum('groupDefaultExpanded')) {
                gridOptionsService.set('groupDefaultExpanded', 0);
            }
            this.maxDepth = this.params.columnApi.getRowGroupColumns().length + 1;
            this.eGui.className = 'expansion-depth-header ag-cell-label-container';
            this.eGui.setAttribute('style', 'height: 100%');
            this.eGui.innerHTML = `
                <span class="expansion-depth-header-renderer-menu-button ag-header-cell-menu-button">
                    <span class="ag-icon ag-icon-menu"></span>
                </span>
                <span class="expansion-depth-header-renderer-depth-div">
                    <span class="expansion-depth-header-renderer-decrease-depth-button"></span>
                    <span class="expansion-depth-header-renderer-depth">${gridOptionsService.getNum(
                        'groupDefaultExpanded'
                    )}</span>
                    <span class="expansion-depth-header-renderer-increase-depth-button"></span>
                </span>
                <div class="expansion-depth-header-label ag-header-cell-label">
                    <span class="expansion-depth-header-renderer-header-name">
                        ${this.params.displayName}
                    </span>
                    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden" aria-hidden="true">
                        <span class="ag-icon ag-icon-asc" unselectable="on"></span>
                    </span>
                    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden" aria-hidden="true">
                        <span class="ag-icon ag-icon-desc" unselectable="on"></span>
                    </span>
                </div>
                `;

            this.eIncreaseButton = this.eGui.querySelector(
                '.expansion-depth-header-renderer-increase-depth-button'
            );
            this.eDecreaseButton = this.eGui.querySelector(
                '.expansion-depth-header-renderer-decrease-depth-button'
            );

            this.eMenu = this.eGui.querySelector('.expansion-depth-header-renderer-menu-button');
            if (this.eMenu) {
                this.eMenu.style.opacity = '0';
                if (this.params.enableMenu) {
                    this.eGui.addEventListener('mouseover', this.mouseOverHeader);
                    this.eGui.addEventListener('mouseout', this.mouseOutHeader);
                    this.eMenu.addEventListener('click', this.menuClick);
                    const style = this.eMenu.style;
                    style.transition = 'opacity 0.2s, border 0.2s';
                }
            }
            if (this.params.column) {
                this.params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
                this.onSortChanged();
            }
            if (this.params.enableSorting) {
                this.eGui.addEventListener('click', this.onSortClick.bind(this));
            }
            if (this.eIncreaseButton) {
                const root = createRoot(this.eIncreaseButton);
                root.render(<Icon size="sm" name="add" type="filled" />);
                this.eIncreaseButton.addEventListener(
                    'click',
                    this.onIncreaseButtonClickedListener
                );
                if (
                    gridOptionsService.getNum('groupDefaultExpanded') !== undefined &&
                    gridOptionsService.getNum('groupDefaultExpanded') === this.maxDepth
                ) {
                    this.eIncreaseButton.style.visibility = 'collapse';
                }
            }
            if (this.eDecreaseButton) {
                const root = createRoot(this.eDecreaseButton);
                root.render(<Icon size="sm" name="remove" type="filled" />);
                this.eDecreaseButton.addEventListener(
                    'click',
                    this.onDecreaseButtonClickedListener
                );
                if (
                    gridOptionsService.getNum('groupDefaultExpanded') !== undefined &&
                    gridOptionsService.getNum('groupDefaultExpanded') === this.minDepth
                ) {
                    this.eDecreaseButton.style.visibility = 'collapse';
                }
            }
        }
    }

    public onSortChanged() {
        if (this.params && this.params.column) {
            const sort = this.params.column.getSort();
            if (sort) this.showSortIndicator(sort);
        }
    }

    public onSortClick() {
        if (this.params) {
            this.params.progressSort(false);
        }
    }

    public getGui() {
        return this.eGui;
    }

    public destroy() {
        this.eGui.removeEventListener('mouseover', this.mouseOverHeader);
        this.eGui.removeEventListener('mouseout', this.mouseOutHeader);
        this.eGui.removeEventListener('click', this.onSortClick.bind(this));

        if (this.eMenu) {
            this.eMenu.removeEventListener('click', this.menuClick);
        }
        if (this.eIncreaseButton) {
            this.eIncreaseButton.removeEventListener('click', this.onIncreaseButtonClickedListener);
        }
        if (this.eDecreaseButton) {
            this.eDecreaseButton.removeEventListener('click', this.onDecreaseButtonClickedListener);
        }
        if (this.params && this.params.column) {
            this.params.column.removeEventListener('sortChanged', this.onSortChanged.bind(this));
        }
    }

    private onIncreaseButtonClickedListener: (e: MouseEvent) => void = (e: MouseEvent) => {
        this.onIncreaseButtonClicked();
        e.stopPropagation();
    };
    private onIncreaseButtonClicked(): void {
        const groupDefaultExpanded = getGridOptionsService(this.params?.api).getNum(
            'groupDefaultExpanded'
        );
        if (this.params && groupDefaultExpanded !== undefined) {
            getGridOptionsService(this.params.api).set(
                'groupDefaultExpanded',
                groupDefaultExpanded + 1
            );
            this.params.api.dispatchEvent({ type: Events.EVENT_COLUMN_ROW_GROUP_CHANGED });
            // ag-grid v24 does not refresh the header on EVENT_COLUMN_ROW_GROUP_CHANGED anymore
            this.params.api.refreshHeader();
        }
    }

    private onDecreaseButtonClickedListener: (e: MouseEvent) => void = (e: MouseEvent) => {
        this.onDecreaseButtonClicked();
        e.stopPropagation();
    };
    private onDecreaseButtonClicked(): void {
        const groupDefaultExpanded = getGridOptionsService(this.params?.api).getNum(
            'groupDefaultExpanded'
        );
        if (this.params && groupDefaultExpanded !== undefined) {
            getGridOptionsService(this.params?.api).set(
                'groupDefaultExpanded',
                groupDefaultExpanded - 1
            );
            this.params.api.dispatchEvent({ type: Events.EVENT_COLUMN_ROW_GROUP_CHANGED });
            // ag-grid v24 does not refresh the header on EVENT_COLUMN_ROW_GROUP_CHANGED anymore
            this.params.api.refreshHeader();
        }
    }

    private mouseOverHeader = () => {
        if (this.eMenu) {
            this.eMenu.style.opacity = '1';
        }
    };
    private mouseOutHeader = () => {
        if (this.eMenu) {
            this.eMenu.style.opacity = '0';
        }
    };
    private menuClick = (e: MouseEvent) => {
        if (this.params && this.eMenu) {
            this.params.showColumnMenu(this.eMenu);
            e.stopPropagation();
        }
    };

    private showSortIndicator(sort?: string) {
        const sortIconClasses: string[] = Object.values(this.SORT_DIR_TO_CLASS_MAP);
        sortIconClasses.forEach(iconClass => {
            const icon = this.eGui.querySelector(iconClass);
            if (icon) {
                icon.classList.add('ag-hidden');
            }
        });
        const classForSortDir = this.classForSortDirection(sort);
        if (classForSortDir) {
            const icon = this.eGui.querySelector(classForSortDir);
            if (icon) {
                icon.classList.remove('ag-hidden');
            }
        }
    }

    private classForSortDirection(sort?: string) {
        if (sort) {
            return this.SORT_DIR_TO_CLASS_MAP[sort];
        }
    }
}
