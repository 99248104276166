import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-react/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './droppable-theme-overrides';

export interface DroppableStyleSheetProps {
    theme: Theme;
    spacing: number;
    direction: 'vertical' | 'horizontal';
}

export interface DroppableCssClasses {
    list: string;
}

export type DroppableStyledClasses = CssClassDefinitionsObject<keyof DroppableCssClasses>;

export interface DroppableStyleOverridesParams {
    props: DeepReadonly<DroppableStyleSheetProps>;
    createDefaultStyledClasses: () => DroppableStyledClasses;
}

export const droppableStyleSheet = new StyleSheet(
    'droppable',
    (props: DroppableStyleSheetProps) => {
        return createComponentClassDefinitions<DroppableStyleSheetProps, DroppableStyledClasses>(
            props,
            createDefaultStyledClasses,
            props.theme.styleOverrides?.droppable
        );
    }
);

function createDefaultStyledClasses({
    spacing,
    direction,
}: DroppableStyleSheetProps): DroppableStyledClasses {
    return {
        list: {
            position: 'relative',
            display: 'flex',
            flexDirection: direction === 'vertical' ? 'column' : 'row',
            margin: -spacing,
            boxSizing: 'border-box',
        },
    };
}
