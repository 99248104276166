import { createSelector } from 'reselect';
import Constants from '../../constants/appConstants';
import {pageIdSelector} from './index';
import {userSelector, allowFeaturesSelector, errorSelector } from '../user';
import {labelSelector} from "../app";
import {getLoginRoute, isRSATMXOrTMXProfiling} from "../../utils/commonUtils";

export const userLockedSelector = state => state.pageData.isUserLocked;
export const isUserLoggedInSelector = state => state.pageData && state.pageData.isUserLoggedIn || false;
export const isLoginInProgressSelector = state => state.pageData && state.pageData.isLoginInProgress || false;
export const isMFAFlowInProgressSelector = state => state.pageData && state.pageData.isMFAFlowInProgress || false;
export const resumePathSelector = state => state.appContext ?  state.appContext.resumePath : null;
export const nextStepSelector = state => state.appContext  ? state.appContext.nextStep : null;
export const submitCredentialsErrorSelector = state => state.appContext ? state.appContext.submitCredentialsError : null;
export const pingConfigSelector = state => state.appContext ? state.appContext.pingConfig : true;
export const mfaConfigSelector = state => state.appContext && state.appContext.mfaConfig || null;
export const pingDownTriesSelector = state => state.appContext && state.appContext.pingDownTries ? state.appContext.pingDownTries : 0;
export const isInternalUserMFASelector = state => state.appContext && state.appContext.isInternalUserMFA || false;
export const internalUserMFAParamsSelector = state => state.appContext && state.appContext.internalUserMFAParams || false;
export const localStorageUserNameSelector = () => {
  const uName = localStorage.getItem(Constants.LOCAL_STORAGE_USER_KEY);
  return (uName ? atob(uName) :'');
};

export const isTmxProfilingCompleteSelector = state => {
  return (window.location.pathname === getLoginRoute(Constants.FORGOT_PASSWORD_ROUTE)) || (window.location.pathname === getLoginRoute(Constants.CREATE_PASSWORD_ROUTE))
    || state.appContext.isTmxProfilingComplete || false;
};

export const loginServiceErrorSelector = createSelector(
  userSelector,
  labelSelector,
  isUserLoggedInSelector,
  allowFeaturesSelector,
  errorSelector,
  submitCredentialsErrorSelector,
  (user, labels, isUserLoggedIn, allowFeatures, userLoginError, submitCredentialsError) => {
    if (userLoginError) {
      return labels.tkSomethingWentWrongError;
    } else if (isUserLoggedIn && (user && !allowFeatures || ((allowFeatures && !allowFeatures.length)))) {
      return labels.tkEntitlementError;
    } else if (submitCredentialsError) {
      return submitCredentialsError;
    }
    return null;
  }
);
export const pwdMustContainListSelector = state => state.pageData ? state.pageData.pwdMustContainList : [];
export const pwdCannotContainListSelector = state => state.pageData ? state.pageData.pwdCannotContainList : [];
export const pinValidationListSelector = state => state.pageData ? state.pageData.pinValidationList : true;
export const isShowMosaicHelpSelector = state => state.pageData && state.pageData.isShowMosaicHelp || false;

export const canPerformTMXProfilingSelector = createSelector(
  mfaConfigSelector,
  (mfaConfig) => (mfaConfig ? isRSATMXOrTMXProfiling(mfaConfig.mfaRiskEngine) : false)
)

export const getHelpTitleSelector = createSelector(
  labelSelector,
  userLockedSelector,
  (labels, isUserLocked) => {
    return isUserLocked ? labels.tkYourAccountIsLocked : labels.tkHelp;
  }
);

export const isInterstitialModalSelector = createSelector(
  pageIdSelector,
  (pageId) => {
    return pageId === Constants.INTERSTITIAL_MODAL;
  }
);
