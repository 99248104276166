// Event constructor is not supported in IE, so use this function
// to create an Event object that is not browser-dependent.
export const createCrossBrowserEvent = (props: {
    type: string;
    bubbles?: boolean;
    cancelable?: boolean;
}): Event => {
    let event;
    const { type, bubbles = true, cancelable = true } = props;
    if (typeof Event === 'function') {
        event = new Event(type, { bubbles, cancelable });
    } else {
        event = document.createEvent('Event');
        event.initEvent(type, bubbles, cancelable);
    }
    return event;
};
