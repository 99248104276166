import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  isEmpty
} from 'lodash';
import Modal from '../../../../components/core/Modal';
import {
  filterConfigSelector,
} from '../../../../selectors/pages/reports/individualReport';
import translator from '../../../../services/translator';
import './index.scss';
import Filter from './Filter';

const { translate: t } = translator;

const getColumnWidth = (filterConfig) => {
  const { columns, additionalCriteria } = filterConfig;
  let columnLength = columns.length;
  columnLength = additionalCriteria && additionalCriteria.length > 0 ? columnLength + 1 : columnLength;
  return Math.round((100 / columnLength) * 1000) / 1000;
};

const FilterModal = (props) => {
  const {
    filterConfig,
    handleClose,
    isDataLoading = false,
    showModal
  } = props;

  const [showFilter, setShowFilter] = useState(false);
  const showFirmBranchColumn = useRef(filterConfig.columns.filter(column => column.key === "firmBranch").length > 0);
  const showAccountColumn = useRef(filterConfig.columns.filter(column => column.key === "account").length > 0);
  const shareclassConfig = useRef(filterConfig.columns.filter(column => column.key === "shareclass"));
  const showShareclassColumn = useRef(shareclassConfig.current.length > 0);
  const showUnfundedShareclasses = useRef(showShareclassColumn.current && shareclassConfig.current[0].include && shareclassConfig.current[0].include === "unfunded");
  const showAdditionalFilterColumn = useRef(!isEmpty(filterConfig.additionalCriteria));
  const columnWidth = getColumnWidth(filterConfig);

  useEffect(() => {
    /* this is to show loading in case of big list of accounts / funds / to get rendered */
    /** Will be remove with React 18 updgrade by using useTransisition hook. */
    setTimeout(() => {
      setShowFilter(true);
    }, 10);
  }, [showModal]);

  return (
    <Modal
        customClass="filters-modal"
        handleClose={handleClose}
        isCloseDisabled={isDataLoading}
        isLoading={!showFilter}
        open={true}
        overrideCloseHandler={handleClose}
        suppressScrollOnActive={true}
        testId="filters-modal"
      >
        <div className="">
          <h2 className="filters-modal__header">{t('tkSelectedCriteria')}</h2>
          { showFilter &&
            <Filter
              handleClose={handleClose}
              showAdditionalFilterColumn={showAdditionalFilterColumn.current}
              showFirmBranchColumn={showFirmBranchColumn.current}
              showAccountColumn={showAccountColumn.current}
              showShareclassColumn={showShareclassColumn.current}
              showUnfundedShareclasses={showUnfundedShareclasses.current}
              columnWidth={columnWidth}
            />
          }
        </div>
      </Modal>

  );
};

FilterModal.propTypes = {
  handleClose: PropTypes.func,
  isDataLoading: PropTypes.bool,
  filterConfig: PropTypes.object,
  showModal: PropTypes.bool
};

const mapStateToProps = (state) => ({
  filterConfig: filterConfigSelector(state)
});

export default connect(mapStateToProps)(FilterModal);
