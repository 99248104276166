import actionTypes from './actionTypes';

export const submitCredentials = (data) => (
  {
    type: actionTypes.SUBMIT_CREDENTIALS,
    data
  }
);

export const createNewPassword = (data) => (
  {
    type: actionTypes.CREATE_NEW_PASSWORD,
    data
  }
);

export const createNewPin = (data) => (
  {
    type: actionTypes.CREATE_NEW_PIN,
    data
  }
);

export const fpwSubmitUID = (data) => (
  {
    type: actionTypes.SUBMIT_FPW_UID,
    data
  }
);

export const fpwSubmitOTP = (data) => (
  {
    type: actionTypes.SUBMIT_FPW_OTP,
    data
  }
);

export const isShowMosaicHelp = (flag) => (
  {
    type: actionTypes.IS_SHOW_MOSAIC_HELP,
    data: {
      'isShowMosaicHelp': flag,
      'isUserLocked': false
    }
  }
);

export const updateTargetURL = data => (
  {
    type: actionTypes.UPDATE_TARGET_URL,
    data: {'targetURL': data}
  }
);

export const isLoginInProgress = (flag) => (
  {
    type: actionTypes.IS_LOGIN_IN_PROGRESS,
    data: {'isLoginInProgress': flag}
  }
);

export const isMFAFlowInProgress = (flag) => (
  {
    type: actionTypes.IS_MFA_FLOW_IN_PROGRESS,
    data: {'isMFAFlowInProgress': flag}
  }
);

export const isUserLoggedIn = (flag) => (
  {
    type: actionTypes.IS_USER_LOGGED_IN,
    data: {
      'isUserLoggedIn': flag
    }
  }
);
