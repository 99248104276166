import {
    StyleSheet,
    CssClassDefinitionsObject,
    CssColor,
    CssPropertyType,
} from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { StepProps, StepsProps } from './';
import { StepStatus } from './step-props';
import { DeepReadonly } from 'ts-essentials';
import './step-theme-overrides';

export interface StepStyleSheetProps {
    theme: Theme;
    status: StepStatus;
    currentStep?: number;
    index: number;
    inline: boolean;
    expandable: boolean;
    expanded: boolean;
    orientation: StepsProps['orientation'];
    fillIndicator: StepsProps['fillIndicators'];
    hideLabel: StepsProps['hideLabels'];
    disabled: StepProps['disabled'];
    size: StepsProps['size'];
    clickable: boolean;
}

export interface StepCssClasses {
    root: string;
    button: string;
    indicator: string;
    label: string;
    content: string;
    icon: string;
}

export type StepStyledClasses = CssClassDefinitionsObject<keyof StepCssClasses>;

export interface StepStyleOverridesParams {
    props: DeepReadonly<StepStyleSheetProps>;
    createDefaultStyledClasses: () => StepStyledClasses;
}

export const stepStyleSheet = new StyleSheet('step', (props: StepStyleSheetProps) => {
    return createComponentClassDefinitions<StepStyleSheetProps, StepStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.step
    );
});

function createDefaultStyledClasses({
    theme,
    currentStep,
    index,
    inline,
    expandable,
    expanded,
    status,
    orientation = 'horizontal',
    fillIndicator,
    hideLabel,
    disabled = false,
    size = 'md',
    clickable,
}: StepStyleSheetProps): StepStyledClasses {
    const isCurrentStep = currentStep === index;
    const isHorizontallyAligned = orientation === 'horizontal';
    const isHorizontalInline = isHorizontallyAligned && inline;
    const isVerticalExpandable = !isHorizontallyAligned && expandable;

    let statusColor = theme.status[status].bold;
    if (status === 'warning') {
        // Steps use the orange 'warningAlt' foreground color for warning in a step instead of
        // the default yellow status used in other components in light mode.
        statusColor = theme.status.warningAlt.bold;
    } else if (status === 'success') {
        // Use a blue color in case of success too. Steps override this value to show a
        // blue color on each 'completed' step
        statusColor = theme.status.information.bold;
    } else if (status === 'none') {
        // Steps use the moderate border color which is subtler than the status gray
        statusColor = theme.border.moderate;
    }

    /*** Color palette ***/

    const stepColor = disabled ? theme.surface.bold.toString() : statusColor;

    const stepBackgroundColor = (color: CssColor, status: StepStatus) =>
        fillIndicator || isFilledStatus(status) || (size === 'sm' && !isCurrentStep)
            ? color
            : theme.surface.primary;

    const stepCircleColor = disabled ? theme.surface.bold.toString() : stepColor;

    const stepCircleDimension =
        isVerticalExpandable && expanded ? '22px' : sizeVariants[size].stepCircleDimension;

    const stepCircleBorderWidth = isVerticalExpandable && expanded ? '5px' : '2px';

    /*** Label ***/
    const stepLabel = isHorizontallyAligned
        ? ({
              display: orientation === 'horizontal' && !inline ? 'block' : 'inline-block',
              backgroundColor: isHorizontalInline ? theme.surface.primary : '',
              position: isHorizontalInline ? 'relative' : 'inherit',
              paddingRight: isHorizontalInline ? '7px' : '',
              paddingTop: '8px',
              paddingLeft: isHorizontalInline ? '6px' : '',
          } as const)
        : {
              paddingLeft: isVerticalExpandable && expanded ? '8px' : '12px',
          };

    const stepLabelTypography =
        size === 'md'
            ? status !== 'none' || isCurrentStep || isVerticalExpandable
                ? theme.typography.heading06
                : theme.typography.body02
            : status !== 'none' || isCurrentStep
              ? theme.typography.heading07
              : theme.typography.body03;

    const stepLabelColor = theme.text.primary;

    const stepLabelVisibility = hideLabel ? { display: 'none' } : {};

    const stepContentColor = disabled ? stepColor : theme.text.secondary;

    // The default color of the line in between two steps.
    const stepLineColor = isCurrentStep || expanded || disabled ? theme.text.tertiary : stepColor;

    return {
        root: {
            opacity: disabled ? 0.3 : 1,
            position: 'relative',
            textAlign: orientationVariants[orientation].stepContainerTextAlign,
            flexBasis: orientationVariants[orientation].stepContainerFlexBasis,
            flexGrow: orientationVariants[orientation].stepContainerFlexGrow,
            '&::before': {
                content: '""',
                position: 'absolute',
                display: 'block',
                backgroundColor: stepLineColor,
                top: isHorizontallyAligned
                    ? inline
                        ? sizeVariants[size].horizontalStepLineTop
                        : '15px'
                    : sizeVariants[size].verticalStepLineTop,
                left: isHorizontallyAligned
                    ? sizeVariants[size].horizontalStepLineLeft
                    : sizeVariants[size].verticalStepLineLeft,
                height: orientationVariants[orientation].stepLineHeight,
                width: orientationVariants[orientation].stepLineWidth,
            },
            '&:last-child::before': {
                content: 'none',
            },
        },
        indicator: {
            minWidth: stepCircleDimension,
            width: stepCircleDimension,
            height: stepCircleDimension,
            position: 'relative',
            borderRadius: '50%',
            transform: getCircleTranslate(
                size,
                orientation,
                inline,
                expandable,
                expanded,
                isCurrentStep
            ),
            border: `${stepCircleBorderWidth} solid ${stepCircleColor}`,
            boxSizing: 'border-box',
            backgroundColor: stepBackgroundColor(stepColor, status),
            color: stepCircleColor,
            display: 'inline-flex',
            marginRight: orientationVariants[orientation].stepCircleIndicatorMarginRight,
        },
        label: {
            color: stepLabelColor,
            ...stepLabelTypography,
            ...stepLabel,
            ...stepLabelVisibility,
        },
        content: {
            marginLeft: orientationVariants[orientation].stepContentMarginLeft,
            margin: orientationVariants[orientation].stepContentMargin,
            color: stepContentColor,
            ...theme.typography.caption01,
            display: isVerticalExpandable && !expanded ? 'none' : 'block', // todo: remove when Angular has collapse animation
        },
        icon: {
            position: 'absolute',
            left: '50%',
            top: '55%',
            transform: 'translate(-50%, -55%)',
            fontSize: '12px',
            fontWeight: 600,
            color: isFilledStatus(status) ? theme.surface.primary : stepColor,
            display: sizeVariants[size].iconDisplay,
        },
        button: {
            marginTop: isHorizontallyAligned
                ? ''
                : sizeVariants[size].verticalStepAlignmentMarginTop,
            display: orientationVariants[orientation].display,
            alignItems: orientationVariants[orientation].alignItems,
            cursor: clickable ? 'pointer' : 'default',
        },
    };
}

function isFilledStatus(status: StepStatus) {
    return status === 'success' || status === 'error' || status === 'warning';
}

function getCircleTranslate(
    size: StepsProps['size'],
    orientation: StepsProps['orientation'],
    inline: boolean,
    expandable: boolean,
    expanded: boolean,
    isCurrentStep: boolean
): string {
    let translateXy: [string, string];

    if (orientation === 'horizontal') {
        if (inline) {
            if (size === 'sm' && !isCurrentStep) {
                translateXy = ['0', '10%'];
            } else {
                translateXy = ['0', '25%'];
            }
        } else {
            if (size === 'sm') {
                translateXy = ['0', '70%'];
            } else {
                translateXy = ['0', '35%'];
            }
        }
    } else {
        // orientation === 'vertical'
        if (expandable && expanded) {
            translateXy = ['20%', '0'];
        } else {
            if (size === 'sm') {
                translateXy = ['85%', '0'];
            } else {
                translateXy = ['35%', '0'];
            }
        }
    }
    return `translate(${translateXy[0]}, ${translateXy[1]})`;
}

const orientationVariants: { [key: string]: OrientationVariantsType } = {
    horizontal: {
        stepContainerTextAlign: 'center',
        stepContainerFlexBasis: 0,
        stepContainerFlexGrow: 1,
        stepContentMargin: '0 8px',
        stepLineHeight: '2px',
        stepLineWidth: '100%',
    },
    vertical: {
        display: 'flex',
        alignItems: 'center',
        stepCircleIndicatorMarginRight: '8px',
        stepContentMarginLeft: '40px',
        stepLineHeight: '100%',
        stepLineWidth: '2px',
    },
};

interface OrientationVariantsType {
    stepContainerTextAlign?: CssPropertyType['textAlign'];
    stepContainerFlexBasis?: CssPropertyType['flexBasis'];
    stepContainerFlexGrow?: CssPropertyType['flexGrow'];
    display?: CssPropertyType['display'];
    alignItems?: CssPropertyType['alignItems'];
    stepCircleIndicatorMarginRight?: CssPropertyType['marginRight'];
    stepContentMargin?: CssPropertyType['margin'];
    stepContentMarginLeft?: CssPropertyType['marginLeft'];
    stepLineHeight: CssPropertyType['height'];
    stepLineWidth: CssPropertyType['width'];
}

const sizeVariants: { [key: string]: SizeVariantsType } = {
    sm: {
        iconDisplay: 'none',
        horizontalStepLineLeft: '50%',
        verticalStepLineLeft: '10px',
        verticalStepLineTop: '20px',
        verticalStepAlignmentMarginTop: '16px',
        horizontalStepLineTop: '16px',
        stepCircleDimension: '8px',
    },
    md: {
        horizontalStepLineLeft: 'calc(50% - 8px)',
        verticalStepLineLeft: '14px',
        verticalStepLineTop: '16px',
        verticalStepAlignmentMarginTop: '12px',
        horizontalStepLineTop: '17px',
        stepCircleDimension: '18px',
    },
};

interface SizeVariantsType {
    iconDisplay?: CssPropertyType['display'];
    horizontalStepLineLeft: CssPropertyType['left'];
    horizontalStepLineTop: CssPropertyType['top'];
    verticalStepLineLeft: CssPropertyType['left'];
    verticalStepLineTop: CssPropertyType['top'];
    verticalStepAlignmentMarginTop: CssPropertyType['marginTop'];
    stepCircleDimension: string;
}
