import actionTypes from "../../../actions/page/mimic/actionTypes";

const mimicInitialState = {};

export function mimicUserPageDataReducer(state = mimicInitialState, action) {
  const { mimicInfo } = state;
  const { type, data = {} } = action;
  const updatedMimicInfo = { mimicInfo: {...mimicInfo, ...data}};
  switch (type) {
    case actionTypes.SHOW_MIMIC_USER_MODAL:
    case actionTypes.UPDATE_MIMIC_INFO :
    case actionTypes.SET_IS_MIMIC_LOADING:
      return { ...state, ...updatedMimicInfo };
    default:
      return state;
  }
}
