export const INPUT = "input";
export const DROPDOWN = "dropdown";
export const OPTIONAL = "optional";
export const ENTER_AMOUNT = "Enter Amount";
export const regexForRebateAmount = /^-?\d+$|^-?\d+\.\d+$/;
export const PAYMENT_DATE_FROM = 1;
export const PAYMENT_DATE_TO = 15;
export const REBATE_NO_ERROR = 0;
export const REBATE_NOT_A_NUMBER = 1;
export const REBATE_OUT_OF_RANGE = 2;
export const REBATE_OUT_OF_RANGE_AND_EXCEEDS_PRECISION = 3;
export const REBATE_EXCEEDS_PRECISION = 4;
export const REBATE_SAVE_SUCCESS = "rebate_save_success";
export const REBATE_SAVE_ERROR = "rebate_save_error";
export const REBATE_UNSAVED_CHANGES = "rebate_unsaved_changes";
export const REBATE_NAVIGATION_WITH_UNSAVED_CHANGES = "rebate_navigation_with_unsaved_changes";
