import React from 'react';
import cn from "classnames";
import FundTracker from '../../modules/FundTracker';
import './index.scss';

const FundTrackerPage = (props) => (
  <div className={cn(
    "js-main-content main-container__content",
    "fund-tracker"
  )}>
    <FundTracker {...props} />
  </div>
);

export default FundTrackerPage;
