import { createSelector } from 'reselect';
import { labelSelector, navBarSelector, hasPendingTradesForApproval } from '../../app';
import appRoutes from '../../../routes/appRoutes';
import Constants from '../../../constants/appConstants';
import {isInternalUserSelector} from '../../user';
import {reportsConfigSelector} from '../reports/individualReport';
import {reportSubMenuItemsSelector} from "../reports/allReports";

const swap = (arr) => {
  [arr[0], arr[1]] = [arr[1], arr[0]];
  return arr;
};

const getSubMenu = (activityRoute, swapTab) => {
  const subMenu = activityRoute ? activityRoute.subMenu : [];
  return swapTab && subMenu.length > 1 && swap([...subMenu]) || subMenu;
};

const needToActivateCurrentTab = createSelector(isInternalUserSelector, hasPendingTradesForApproval, (isInternalUser, hasPendingApproval) => {
  return !isInternalUser && !hasPendingApproval;
});

export const getTradeActivityConfig = createSelector(
  labelSelector,
  navBarSelector,
  (labels, navBar) => {
    const activityNavConfigs = (navBar && navBar.navRootConfig && navBar.navRootConfig.find(data => data.routingPathId === Constants.TRADE_ACTIVITY));
    const activityRoutes = appRoutes.find(item => item.id === Constants.TRADE_ACTIVITY).subRoutes;
    return activityNavConfigs && activityNavConfigs.subMenu.map(data => {
      const matchedRoute = activityRoutes.find(item => item.id === data.routingPathId) || '';
      const {id, exact, path} = matchedRoute;
      return {id, exact, path, label: labels[data.displayNameKey]};
    }) || [];
  }
);

export const getActivityNavLinks = createSelector(
  navBarSelector,
  needToActivateCurrentTab,
  (navBar, currentTab) => {
    const activityRoute = (navBar && navBar.navRootConfig.find(data => data.routingPathId === Constants.TRADE_ACTIVITY));
    return activityRoute ? getSubMenu(activityRoute, currentTab) : [];
  }
);

export const ctsReportConfigSelector = createSelector(
  reportsConfigSelector,
  reportSubMenuItemsSelector,
  (reportsConfig, reportSubMenuItems) => {
    const pageId = 'current-trade-status';
    const ctsReportConfig = reportsConfig && reportsConfig.find(({newReportId}) =>
      (pageId === newReportId)
    );
    if (ctsReportConfig) {
      const isNewReport = reportSubMenuItems.some(({routingPathId}) =>
        routingPathId === ctsReportConfig.newReportId
      );
      return {
        isNewReport,
        ...ctsReportConfig
      };
    }
    return null;
  }
);
