import {
    ExpressionCondition,
    ExpressionGroup,
    ExpressionQuery,
    ExpressionRule,
    genericExpressionHelper,
} from '@gs-ux-uitoolkit-common/datacore';
import { Text } from '@gs-ux-uitoolkit-react/text';
import { Fragment, KeyboardEvent } from 'react';
import { GridColumn, GetFormattedValueFromColumn } from '../../../grid-wrappers/grid-wrapper';
import { expressionHelper } from '../../../libraries/helpers/expression-helper';
import { ExperiencedExpressionRule } from './experienced-expression-rule';
import { AccordionPanel } from '@gs-ux-uitoolkit-react/accordion';
import { IconName, Icon } from '@gs-ux-uitoolkit-react/icon-font';
import {
    Dropdown,
    DropdownButton,
    DropdownMenu,
    MenuOption,
} from '@gs-ux-uitoolkit-react/dropdown';
import { AddExpressionButton } from '../add-expression-button';
import { useState } from 'react';
export interface ExperiencedExpressionGroupProps {
    expressionGroup: ExpressionGroup;
    columns: GridColumn[];
    level: string;
    index: number;
    onEditExpression: (newExpression: ExpressionGroup) => void;
    onDeleteExpression: () => void;
    getFormattedValueFromColumn: GetFormattedValueFromColumn;
    showRowGroups?: boolean;
    invertBackgroundColor: boolean;
    isLastChild: boolean;
    handleNegatedRules: (operator: ExpressionRule) => ExpressionRule;
}
export function ExperiencedExpressionGroup(props: ExperiencedExpressionGroupProps) {
    const [accordionExpanded, setAccordionExpanded] = useState(true);

    const rules: JSX.Element[] = props.expressionGroup.rules.map((rule, idx, ruleList) => {
        const ruleIndex = idx + 1;

        const newLevel = props.level === '0' ? `${ruleIndex}` : `${props.level}.${ruleIndex}`;
        const key = `rule_${newLevel}`;
        const isLastChild = ruleList.length === ruleIndex;

        if (genericExpressionHelper.isGroupRule(rule)) {
            const groupRule = rule as ExpressionGroup;

            return (
                <Fragment key={key}>
                    <div
                        className="gs-uitk-experienced-expression-group-or-rule-container"
                        key={key}
                    >
                        <ExperiencedExpressionGroup
                            isLastChild={isLastChild}
                            handleNegatedRules={props.handleNegatedRules}
                            columns={props.columns}
                            expressionGroup={groupRule}
                            level={newLevel}
                            index={idx}
                            onEditExpression={newGroup => onEditExpressionFromChild(newGroup, idx)}
                            invertBackgroundColor={!props.invertBackgroundColor}
                            onDeleteExpression={() => ononDeleteExpressionFromChild(idx)}
                            getFormattedValueFromColumn={props.getFormattedValueFromColumn}
                        />
                    </div>
                    {getConditionButton(isLastChild)}
                </Fragment>
            );
        }
        const simpleRule = rule as ExpressionRule;
        return (
            <Fragment key={key}>
                <div className="gs-uitk-experienced-expression-group-or-rule-container">
                    <ExperiencedExpressionRule
                        columns={props.columns}
                        ruleNumber={ruleIndex}
                        expressionRule={props.handleNegatedRules(simpleRule)}
                        onEditExpression={newGroup => onEditExpressionFromChild(newGroup, idx)}
                        onDeleteExpression={() => ononDeleteExpressionFromChild(idx)}
                        getFormattedValueFromColumn={props.getFormattedValueFromColumn}
                        showRowGroups={props.showRowGroups}
                    />
                </div>
                {getConditionButton(isLastChild)}
            </Fragment>
        );
    });

    return isRootParentGroup() ? (
        <div className={`gs-uitk-experienced-expression-group-container`}>
            {rules}
            {getExpressionButtonGroup()}
        </div>
    ) : (
        <AccordionPanel
            className={`gs-uitk-experienced-expression-group-container ${
                props.invertBackgroundColor ? 'inverted-background' : ''
            }`}
            defaultExpanded={true}
            data-cy={`gs-uitk-experienced-group-${props.level}`}
            header={getAccordionHeader()}
            onToggle={event => setAccordionExpanded(event.expanded)}
            hideExpandIcon={true}
        >
            {rules}
            {getExpressionButtonGroup()}
        </AccordionPanel>
    );

    function getExpressionButtonGroup() {
        return !isRootParentGroup() ? (
            <div className="gs-uitk-experienced-expression-button-group">
                <AddExpressionButton
                    data-cy={'gs-uitk-experienced-expression-add-group'}
                    isGroup={true}
                    onClickCallback={() => addNewGroup()}
                />
                <AddExpressionButton
                    data-cy={'gs-uitk-experienced-expression-add-rule'}
                    onClickCallback={() => addNewRule()}
                />
            </div>
        ) : null;
    }

    function onKeyPress(event: KeyboardEvent<HTMLSpanElement>) {
        if (event.key === 'Enter') {
            deleteGroup();
        }
    }

    function getAccordionHeader() {
        return (
            <div className={'gs-uitk-advanced-expression-header'}>
                <Icon
                    name={`keyboard-arrow-${accordionExpanded ? 'down' : 'right'}` as IconName}
                    type="filled"
                />

                <Text
                    typography="body03"
                    display="block"
                    className="gs-uitk-advanced-expression-header-group-label"
                >
                    Group {props.level}
                </Text>
                <span
                    className={'gs-uitk-advanced-expression-header-delete'}
                    onClick={deleteGroup}
                    onKeyPress={onKeyPress}
                    role="button"
                    tabIndex={0}
                >
                    <Icon name={'delete'} type={'outlined'} size={'sm'} />
                </span>
            </div>
        );
    }

    function onEditExpressionFromChild(newChild: ExpressionQuery, index: number) {
        const newGroup = expressionHelper.replaceChildinExpressionGroup(
            props.expressionGroup,
            newChild,
            index
        );
        props.onEditExpression(newGroup);
    }

    function isRootParentGroup() {
        return props.index === 0 && props.level === '0';
    }

    function ononDeleteExpressionFromChild(index: number) {
        const newGroup = expressionHelper.deleteChildinExpressionGroup(
            props.expressionGroup,
            index
        );
        props.onEditExpression(newGroup);
    }

    function addNewGroup() {
        const newGroup = expressionHelper.addNewExpressionGroupToNode(props.expressionGroup);
        props.onEditExpression(newGroup);
    }

    function addNewRule() {
        const newGroup = expressionHelper.addNewExpressionRuleToNode(props.expressionGroup);
        props.onEditExpression(newGroup);
    }

    function deleteGroup() {
        props.onDeleteExpression();
    }

    function getConditionButton(isLastChild: boolean) {
        if (isLastChild) return null;

        const conditionOptions = [
            { value: ExpressionCondition.And, label: ExpressionCondition.And },
            { value: ExpressionCondition.Or, label: ExpressionCondition.Or },
            { value: 'Nand', label: 'Not all of (Nand)' },
            { value: 'Nor', label: 'None of (Nor)' },
        ];
        return (
            <Dropdown className="gs-uitk-experienced-expression-condition-dropdown" size="sm">
                <DropdownButton>{getInitialExpressionCondition().toUpperCase()}</DropdownButton>
                <DropdownMenu
                    onChange={(condition: any) => {
                        onConditionChanged(condition);
                    }}
                >
                    {conditionOptions.map(condition => (
                        <MenuOption value={condition.value} key={condition.value}>
                            {condition.label.toUpperCase()}
                        </MenuOption>
                    ))}
                </DropdownMenu>
            </Dropdown>
        );
    }

    function getInitialExpressionCondition() {
        let defaultCondition: ExpressionCondition | 'Nand' | 'Nor' = ExpressionCondition.And;
        const currentCondition = props.expressionGroup.condition;
        const negate = props.expressionGroup.negate;

        if (currentCondition === ExpressionCondition.And && !negate) {
            defaultCondition = ExpressionCondition.And;
        } else if (currentCondition === ExpressionCondition.Or && !negate) {
            defaultCondition = ExpressionCondition.Or;
        } else if (currentCondition === ExpressionCondition.And && negate) {
            defaultCondition = 'Nand';
        } else if (currentCondition === ExpressionCondition.Or && negate) {
            defaultCondition = 'Nor';
        }

        return defaultCondition;
    }

    function onConditionChanged(condition: ExpressionCondition | 'Nand' | 'Nor') {
        let newGroup;

        switch (condition) {
            case ExpressionCondition.And:
                newGroup = expressionHelper.changeConditionInExpressionGroup(
                    props.expressionGroup,
                    ExpressionCondition.And,
                    false
                );
                props.onEditExpression(newGroup);
                break;

            case ExpressionCondition.Or:
                newGroup = expressionHelper.changeConditionInExpressionGroup(
                    props.expressionGroup,
                    ExpressionCondition.Or,
                    false
                );
                props.onEditExpression(newGroup);
                break;

            case 'Nor':
                newGroup = expressionHelper.changeConditionInExpressionGroup(
                    props.expressionGroup,
                    ExpressionCondition.Or,
                    true
                );
                props.onEditExpression(newGroup);
                break;

            case 'Nand':
                newGroup = expressionHelper.changeConditionInExpressionGroup(
                    props.expressionGroup,
                    ExpressionCondition.And,
                    true
                );
                props.onEditExpression(newGroup);
                break;
        }
    }
}
