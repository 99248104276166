import React, { useState, useRef, useMemo, memo } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@gs-ux-uitoolkit-react/modal';
import { Button } from '@gs-ux-uitoolkit-react/button';
import PropTypes from 'prop-types';
import useFetch from '../../hooks/useFetch';
import AutoComplete from '../../../../../components/core/AutoComplete';
import endPointsMapper from '../../../../../configs/endPointsMapper';
import { getSortedStringData } from '../../../../../utils/sorting';
import translator from '../../../../../services/translator';
import './index.scss';

const { translate: t } = translator;

const renderNullPlaceholder = value => (!value || value === 'null' ? t('tkNullDataPlaceholder') : value);

const FirmOrganizationMappingModal = ({ closeModal, firmData: { firmName, firmCode, id: firmId }, onMapFirmToOrganization }) => {
  const ref = useRef();

  const { data: autoComplete = {}, loading, doFetch: fetchData, error } = useFetch(endPointsMapper.FETCH_USER_SEARCH);

  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const { organizations: { values = [] } = {} } = autoComplete || {};

  const { id, name, clientOrganizationId, city, coverage, country, state } = selectedOrganization || {};

  const onInputChange = data => {
    data.length >= 3 && fetchData({ searchString: data, searchGroups: ['organization'], searchMaxResults: 7 });
  };

  const onOptionSelection = data => {
    setSelectedOrganization(data);
    ref.current && ref.current.setSearchTerm('');
  };

  const updatedOptions = useMemo(() => {
    if (!autoComplete) return [];
    const result = [];
    if (values.length) {
      const allMatches = values.map(item => ({
        ...item,
        label: item.name,
        labels: [
          { name: renderNullPlaceholder(item.name), isScGreyText: false },
          { name: renderNullPlaceholder(item.city), isScGreyText: true },
          { name: renderNullPlaceholder(item.state), isScGreyText: true },
          { name: renderNullPlaceholder(item.country), isScGreyText: true },
          { name: renderNullPlaceholder(item.coverage.replace(/;/g, '; ')), isScGreyText: true },
        ],
      }));
      result.push({ title: null, values: getSortedStringData(allMatches, 'label', true) });
    }
    return result;
  }, [autoComplete]);

  const handleModalClose = () => {
    setSelectedOrganization(null);
    closeModal();
  };

  const handleMapping = () => {
    const requestBody = {
      orgFirmMappingRequest: {
        organizationRequest: {
          id,
          name,
          clientOrganizationId,
        },
        firmRequest: {
          firmCode,
          firmName,
          id: firmId,
        },
      },
    };
    onMapFirmToOrganization(requestBody);
    handleModalClose();
  };

  return (
    <Modal visible={true} placement='center' className='firmOrgMap-modal gs-uitk-override-css'>
      <ModalHeader onDismissButtonClick={handleModalClose} className='firmOrgMap-modal__header'>
        {t('tkMapToAnOrganization', firmName, firmCode)}
      </ModalHeader>
      <ModalBody className='firmOrgMap-modal__body'>
        <header>
          <h4>{t('tkSearchForAnOrganization')}</h4>
          <AutoComplete
            ref={ref}
            placeholderText={t('tkEnterNameOfOrganization')}
            searchIconPosition='left'
            onInputChange={onInputChange}
            debounceTime={150}
            minChar={3}
            isLoading={loading}
            options={updatedOptions}
            onOptionSelection={onOptionSelection}
            defaultSearchTerm={''}
            errorMessage={t('tkUsearSearchErrorMessage')}
          />
        </header>
        {selectedOrganization && !error && (
          <section className='searchresults'>
            <div className='searchresults__header'>{t('tkMapToTheFollowingOrganization')}</div>
            <div className='searchresults__content'>
              <span className='searchresults__organizationlogo' />
              <div className='searchresults__organizationdata'>
                <div>{name}</div>
                <div>{`${renderNullPlaceholder(city)}, ${renderNullPlaceholder(state)}, ${renderNullPlaceholder(country)}`}</div>
                <div className='searchresults__coverage'>
                  <span>{t('tkCoverage')}:</span>
                  <span className='searchresults__coverage--value'>{`${renderNullPlaceholder(coverage.replace(/;/g, ', '))}`}</span>
                </div>
              </div>
            </div>
          </section>
        )}
        {error && <div className='searchresults__errorMsg'>{t('tkOrganizationAutoCompleteErrorMsg')}</div>}
      </ModalBody>
      <ModalFooter className='firmOrgMap-modal__footer'>
        <Button actionType='secondary' onClick={handleModalClose}>
          {t('tkModalCancelCTA')}
        </Button>
        {selectedOrganization && (
          <Button actionType='primary' onClick={handleMapping}>
            {t('tkMap')}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

FirmOrganizationMappingModal.propTypes = {
  closeModal: PropTypes.func,
  firmData: PropTypes.object,
  onMapFirmToOrganization: PropTypes.func,
};

export default memo(FirmOrganizationMappingModal);
