import { globalHelper } from '../../../../libraries/helpers/global-helper';
import { CrossModelActionKeys } from '../actions/action-constants';
import { AllActionTypes } from '../actions/all-action-types';
import { CrossModelState } from '../cross-model-state';

/**
 * Initial state of the Cross Model Service State
 */
export const defaultCrossModelState: CrossModelState = {};

/**
 * Reducer for the GridState
 * @param state State of the GridState
 * @param action Action received
 */
export const crossModelReducer = (
    state: CrossModelState = defaultCrossModelState,
    action: AllActionTypes
): CrossModelState => {
    switch (action.type) {
        case CrossModelActionKeys.CREATE_CONTROLLER_STATE: {
            const newState = { ...state };
            newState[action.controllerName] = {};
            return newState;
        }
        case CrossModelActionKeys.CREATE_COMPONENT_STATE: {
            const newState = { ...state };
            const controllerState = { ...newState[action.controllerName] };
            newState[action.controllerName] = controllerState;
            controllerState[action.componentId] = {
                componentId: action.componentId,
                selectedRecordCompositeIdList: [],
                selectedRowIdList: [],
                selectedRowIndexList: [],
            };
            return newState;
        }
        case CrossModelActionKeys.UPDATE_COMPONENT_ROW_SELECTION: {
            const newState = { ...state };
            const controllerState = { ...newState[action.controllerName] };
            newState[action.controllerName] = controllerState;
            const componentState = globalHelper.cloneObject(controllerState[action.componentId]);
            componentState.selectedRowIdList = action.selectedRecordIdList;
            componentState.selectedRowIndexList = action.selectedRecordIndexList;
            componentState.selectedRecordCompositeIdList = action.selectedRecordCompositeIdList;
            controllerState[action.componentId] = componentState;
            return newState;
        }
        default:
            return state;
    }
};
