import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { Tabs, TABS } from '../../UserDetails/TabSwitchBar';
import Snackbar from '../../../../../../components/core/Snackbar';
import ChangeManagementDetailsGrid from '../Grid/ChangeManagementDetailsGrid';
import { CHANGE_MANAGEMENT_TAB_SWITCHED } from '../../../actions';
import useUserMaintenance from '../../../context';
import DownloadButton from '../../../../../../components/core/DownloadButton/DownloadButton';
import { Conditional } from '../../../../../../components/core/Conditional';
import translator from '../../../../../../services/translator';
import { ThreeSquaresLoader } from '../../../../../../components/core/Loaders';
import useGetChangeManagementGrid from '../../../hooks/useGetChangeManagementGrid';

import './index.scss';

const { translate: t } = translator;

export const ModalTabs = () => {
  const [currentTab, setCurrentTab] = useState(TABS.PROFILE);
  const [gridDataAccounts, setGridDataAccounts] = useState(null);
  const [gridDataProfile, setGridDataProfile] = useState(null);
  const [triggerExport, setTriggerExport] = useState(false);
  const [profileGridApi, setProfileGridApi] = useState(null);
  const [accountGridApi, setAccountGridApi] = useState(null);
  const [isSnackBarVisible, setIsSnackBarVisible] = useState(false);
  const {
    dispatch,
    state: { userDetails },
    storeValues: { profileChgMgmtDefs, accountsChgMgmtDefs },
  } = useUserMaintenance();
  const {
    data: profileData,
    loading: profileLoading,
    doFetch: fetchProfileAccounts,
    error: profileGridError,
  } = useGetChangeManagementGrid('USER_DETAIL');
  const {
    data: accountsData,
    loading: accountsLoading,
    doFetch: fetchAccountsData,
    error: accountsGridError,
  } = useGetChangeManagementGrid('ACCOUNT_PERMISSION');

  const { userGuid } = userDetails;

  useEffect(() => {
    fetchProfileAccounts();
  }, [userGuid]);

  useEffect(() => {
    profileData && setGridDataProfile(profileData);
  }, [profileData]);

  useEffect(() => {
    accountsData && setGridDataAccounts(accountsData);
  }, [accountsData]);

  const getFormattedFilename = () => {
    return `${t('tkUserAudit')}${moment().format(t('tkDataAndTimeFormat'))}`;
  };

  useEffect(() => {
    if (accountsData && triggerExport && profileGridApi && accountGridApi) {
      const spreadSheets = [];
      spreadSheets.push(
        profileGridApi.getSheetDataForExcel({ sheetName: t('tkSheetNameProfile') }),
        accountGridApi.getSheetDataForExcel({ sheetName: t('tkSheetNameAccounts') })
      );
      profileGridApi.exportMultipleSheetsAsExcel({ data: spreadSheets, fileName: getFormattedFilename() });
      setTriggerExport(false);
    } else if (accountsGridError || profileGridError) {
      setIsSnackBarVisible(true);
      setTriggerExport(false);
    }
  }, [triggerExport, accountsData, profileGridApi, accountGridApi]);

  const tabConfig = useMemo(() => {
    return [
      { id: TABS.PROFILE, label: 'tkProfile' },
      { id: TABS.ACCOUNTS, label: 'tkAccounts' },
    ];
  }, []);

  const switchTab = tabId => {
    !accountsData && fetchAccountsData();
    setCurrentTab(tabId);
    dispatch({ type: CHANGE_MANAGEMENT_TAB_SWITCHED, payload: tabId });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const handleDownloadExcel = async () => {
    !accountsData && fetchAccountsData();
    setTriggerExport(true);
  };

  const renderErrorFallBack = () => {
    return (
      <>
        <Icon className='changemanagement-modal__error-icon' name='error' type='outlined' />
        <span>{t('tkGridError')}</span>
      </>
    );
  };

  return (
    <>
      {isSnackBarVisible && (
        <Snackbar
          sticky={false}
          type='error'
          autoHide={true}
          msgCopy={t('tkChangeManagementDetailsError')}
          onSnackbarClose={() => setIsSnackBarVisible(false)}
        />
      )}
      <div className='tabswitchbar' data-testid='tabswitch-container'>
        <div className='tabswitchbar__left'>
          {tabConfig.map(tab => (
            <Tabs key={tab.id} tabId={tab.id} label={t(tab.label)} currentTab={currentTab} switchTab={switchTab} />
          ))}
        </div>
        <div className='tabswitchbar__right'>
          <DownloadButton onClick={handleDownloadExcel} onKeyPress={handleDownloadExcel} />
        </div>
      </div>
      <Conditional condition={profileLoading || accountsLoading}>
        <ThreeSquaresLoader />
      </Conditional>
      <div className={currentTab === TABS.PROFILE ? 'tab-content' : 'hidden'} data-testid='profile-tab-content'>
        {profileGridError
          ? renderErrorFallBack()
          : gridDataProfile && (
              <ChangeManagementDetailsGrid
                gridData={gridDataProfile}
                colDef={profileChgMgmtDefs}
                tab={TABS.PROFILE}
                setProfileGridApi={setProfileGridApi}
              />
            )}
      </div>

      <div className={currentTab === TABS.ACCOUNTS ? 'tab-content' : 'hidden'} data-testid='accounts-tab-content'>
        {accountsGridError
          ? renderErrorFallBack()
          : gridDataAccounts && (
              <ChangeManagementDetailsGrid
                gridData={gridDataAccounts}
                colDef={accountsChgMgmtDefs}
                tab={TABS.ACCOUNTS}
                setAccountGridApi={setAccountGridApi}
              />
            )}
      </div>
    </>
  );
};
