import {
    arrayHelper,
    CancelEditedQuickFilter,
    EditQuickFilter,
    NewQuickFilter,
    QuickFilter,
    RemoveQuickFilter,
    SaveEditedQuickFilter,
    stringHelper,
    ToggleEnabledQuickFilter,
    UpdateEditedQuickFilter,
    genericExpressionHelper,
    invariantQuery,
} from '@gs-ux-uitoolkit-common/datacore';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as Redux from 'redux';
import { PluginFooter } from '../../../components/plugin-footer/plugin-footer';
import { ModalProps, ModalWithItems } from '../../../components/modal-props';
import { GridColumn, GetFormattedValueFromColumn } from '../../../grid-wrappers/grid-wrapper';
import {
    GridGetColumnValueList,
    GridResetColumnValueList,
    GridResetBasicExpressionColumnValueList,
    GridToggleFloatingFilter,
} from '../../../redux/actions/grid-action';
import { DataGridState } from '../../../redux/datagrid-state';
import { CreateButtonText } from '../../plugin-enum';
import { QuickFilterListTable } from './quickfilter-list-table';
import { QuickFilterCreateEdit } from './quickfilter-create-and-edit';
import { Alert } from '@gs-ux-uitoolkit-react/alert';
import { PluginHeader } from '../../../components/plugin-header/plugin-header';
import {
    getQuickFilterPluginClassNames,
    quickfilterStyleSheet,
} from '../../../style/plugin/quickfilter-plugin-stylesheet';
import { ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';
import { SwitchChangeEvent, Switch } from '@gs-ux-uitoolkit-react/switch';
import { Text } from '@gs-ux-uitoolkit-react/text';

/**
 * The props for the QuickFilterModalComponent
 */
export interface QuickFilterModalProps
    extends ModalProps<QuickFilter>,
        ModalWithItems<QuickFilter> {
    /**
     * Data for the Quickfilter
     */
    data: QuickFilter | null;
    /**
     * Columns from the grid
     */
    columns: GridColumn[];
    /**
     * Get the valueList for a column
     */
    gridGetColumnValueList: (columnId: string) => void;
    /**
     * Reset the column valueList in the store
     */
    gridResetColumnValueList: (columnId: string | null) => void;
    /**
     * to toggle the enabled state for the quickfilter items
     */
    toggleEnabledQuickFilter: (quickFilter: QuickFilter) => void;
    getFormattedValueFromColumn: GetFormattedValueFromColumn;
    gridResetBasicExpressionColumnValueList: () => void;
    toggleFloatingFilter: (value: boolean) => void;
    floatingFilterValue: boolean;
}

export interface QuickFilterModalState {
    nameIsNull: string;
    nameIsDuplicate: string;
    expressionNotValid: string;
    originalName: string | null;
    createButtonDisplayName: string;
}

/**
 * * The main Quick Filter modal
 */
export class QuickFilterModalComponent extends Component<
    QuickFilterModalProps,
    QuickFilterModalState
> {
    public static getDerivedStateFromProps(
        props: QuickFilterModalProps,
        state: QuickFilterModalState
    ) {
        if (props.data && state.originalName === null) {
            return {
                originalName: props.data.name,
            };
        }
        return null;
    }
    public constructor(props: QuickFilterModalProps) {
        super(props);
        this.state = {
            createButtonDisplayName: CreateButtonText.Create,
            expressionNotValid: '',
            nameIsDuplicate: '',
            nameIsNull: '',
            originalName: null,
        };
    }
    public componentWillUnmount() {
        quickfilterStyleSheet.unmount(this);
    }

    private setFloatingFilterValue = (e: SwitchChangeEvent) => {
        this.props.toggleFloatingFilter(e.toggledOn);
    };

    public render() {
        const visibleColumns = this.props.columns.filter(column => !column.hidden);
        const sortedQuickFilter = arrayHelper.sort(this.props.configItemList, false, 'name');
        const newConfigItem = () => {
            this.setState({ createButtonDisplayName: CreateButtonText.Create });
            this.props.newConfigItem();
        };
        const editConfigItem = (configItem: QuickFilter) => {
            this.setState({ createButtonDisplayName: CreateButtonText.Update });
            this.props.editConfigItem(configItem);
        };
        const isError =
            this.state.nameIsDuplicate || this.state.nameIsNull || this.state.expressionNotValid;

        return (
            <ThemeConsumer>
                {theme => {
                    const cssClasses = quickfilterStyleSheet.mount(this, { theme });
                    return (
                        <Fragment>
                            <div
                                className={`config-items-container gs-uitk-quick-filter-plugin gs-uitk-plugin-flex ${getQuickFilterPluginClassNames(
                                    { cssClasses }
                                )}`}
                            >
                                {!this.props.data ? (
                                    <Fragment>
                                        <PluginHeader
                                            displayCreateButton={true}
                                            subTitle={'Current Quick Filters'}
                                            createButtonCallback={newConfigItem}
                                            secondaryAction={
                                                <Switch
                                                    toggledOn={this.props.floatingFilterValue}
                                                    onChange={this.setFloatingFilterValue}
                                                    name="toggle"
                                                    size={'sm'}
                                                >
                                                    <Text typography="body03">
                                                        Floating Column Filters
                                                    </Text>
                                                </Switch>
                                            }
                                        />

                                        <QuickFilterListTable
                                            createQuickFilterCallback={newConfigItem}
                                            data={this.props.data}
                                            columns={visibleColumns}
                                            sortedQuickFilter={sortedQuickFilter}
                                            editConfigItem={editConfigItem}
                                        ></QuickFilterListTable>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        {isError ? (
                                            <Alert status="error">
                                                {this.state.nameIsDuplicate ||
                                                    this.state.nameIsNull ||
                                                    this.state.expressionNotValid ||
                                                    ''}
                                            </Alert>
                                        ) : null}
                                        <QuickFilterCreateEdit
                                            expression={this.props.data.expression!}
                                            onChangeExpression={this.props.updateEditedConfigItem}
                                            quickfiler={this.props.data}
                                            columns={visibleColumns}
                                            gridGetColumnValueList={
                                                this.props.gridGetColumnValueList
                                            }
                                            gridResetColumnValueList={
                                                this.props.gridResetColumnValueList
                                            }
                                            getFormattedValueFromColumn={
                                                this.props.getFormattedValueFromColumn
                                            }
                                        ></QuickFilterCreateEdit>
                                    </Fragment>
                                )}
                            </div>
                            {this.props.data ? (
                                <PluginFooter
                                    createButtonDisplayName={this.state.createButtonDisplayName}
                                    createActionType={'primary'}
                                    onCreate={
                                        !this.props.data
                                            ? newConfigItem
                                            : () => this.createQuickFilter()
                                    }
                                    onCancel={() => this.cancelQuickFilter()}
                                />
                            ) : null}
                        </Fragment>
                    );
                }}
            </ThemeConsumer>
        );
    }

    /**
     * Validates submission to create a quickfilter
     */
    private createQuickFilter = () => {
        if (!this.props.data) return;
        const isNameNull: boolean = this.isNameNull(this.props.data.name);
        const isNameUnique: boolean = this.nameIsUnique(this.props.data.name);
        const isExpressionValid: boolean = this.validateExpression(this.props.data);

        isNameUnique
            ? this.setState({ nameIsDuplicate: '' })
            : this.setState({ nameIsDuplicate: 'Name is not unique' });

        isNameNull
            ? this.setState({ nameIsNull: 'Name is null' })
            : this.setState({ nameIsNull: '' });

        isExpressionValid
            ? this.setState({ expressionNotValid: '' })
            : this.setState({ expressionNotValid: 'Expression is not valid' });

        if (isNameUnique && !isNameNull && isExpressionValid) {
            this.props.saveEditedConfigItem();
            this.props.gridResetColumnValueList(null);
            this.setState({ originalName: null });
        }
        this.props.gridResetBasicExpressionColumnValueList();
    };

    /**
     * Checks if a quickfilter name is unique
     */
    private nameIsUnique(name: string): boolean {
        return (
            this.props.configItemList.every(
                item => item.name !== name.trim() && item !== this.props.data
            ) || this.state.originalName === name
        );
    }

    /**
     * Checks if a quickfilter name is null
     */
    private isNameNull(name: string = '') {
        return stringHelper.isNullOrEmpty(name);
    }

    /**
     * Cancels the process of creating a quickfilter
     */
    private cancelQuickFilter() {
        this.props.cancelEditedConfigItem();
        this.props.gridResetColumnValueList(null);
        this.setState({ nameIsNull: '' });
        this.setState({ nameIsDuplicate: '' });
        this.setState({ expressionNotValid: '' });
        this.props.gridResetBasicExpressionColumnValueList();
    }

    /**
     * Checks if proposed quickfilter expression is valid
     */
    private validateExpression(data: QuickFilter) {
        const isValid = genericExpressionHelper.isValid(data.expression?.query ?? invariantQuery);
        return isValid;
    }
}

export const QuickFilterPluginModal = (
    getFormattedValueFromColumn: GetFormattedValueFromColumn
) => {
    const mapStateToProps = (state: DataGridState) => {
        return {
            columns: state.grid.primaryColumnList,
            configItemList: state.quickFilter.configItemList,
            data: state.quickFilter.editedItem,
            getFormattedValueFromColumn,
            floatingFilterValue: state.grid.floatingFilter.enabled,
        };
    };

    const mapDispatchToProps = (dispatch: (action: Redux.Action) => void) => {
        return {
            toggleFloatingFilter: (value: boolean) => dispatch(GridToggleFloatingFilter(value)),
            cancelEditedConfigItem: () => dispatch(CancelEditedQuickFilter()),
            editConfigItem: (quickFilter: QuickFilter) => dispatch(EditQuickFilter(quickFilter)),
            gridGetColumnValueList: (columnId: string) =>
                dispatch(GridGetColumnValueList(columnId)),
            gridResetColumnValueList: (columnId: string | null) =>
                dispatch(GridResetColumnValueList(columnId)),
            gridResetBasicExpressionColumnValueList: () =>
                dispatch(GridResetBasicExpressionColumnValueList()),
            newConfigItem: () => {
                dispatch(NewQuickFilter());
                dispatch(GridResetBasicExpressionColumnValueList());
            },
            removeConfigItem: (quickFilter: QuickFilter) =>
                dispatch(RemoveQuickFilter(quickFilter)),
            saveEditedConfigItem: () => dispatch(SaveEditedQuickFilter()),
            toggleEnabledQuickFilter: (quickFilter: QuickFilter) => {
                dispatch(ToggleEnabledQuickFilter(quickFilter));
            },
            updateEditedConfigItem: (newData: Partial<QuickFilter>) =>
                dispatch(UpdateEditedQuickFilter(newData)),
        };
    };
    return connect(mapStateToProps, mapDispatchToProps)(QuickFilterModalComponent);
};
