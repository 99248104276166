import { CellStyle } from '@ag-grid-community/core';
import {
    arrayHelper,
    ColumnHint,
    HintList,
    stringHelper,
    DefaultColumnHintTypes,
    ColumnHintDefaults,
} from '@gs-ux-uitoolkit-common/datacore';
import { GridColumn } from '../../grid-wrappers/grid-wrapper';

export enum ColumnHintFormatTypes {
    SpecificColumn = 'Specific Columns',
    DateColumns = 'Date Columns',
    DateTimeColumns = 'Date Time Columns',
    NumericColumns = 'Numeric Columns',
    StringColumns = 'String Columns',
    AllColumns = 'All Columns',
}

export class ColumnHintHelper {
    public getStyles(columnHints: HintList): CellStyle {
        const styles: CellStyle = {};

        if (columnHints.bg) {
            styles.backgroundColor = columnHints.bg;
        }

        if (columnHints.fg) {
            styles.color = columnHints.fg;
        }

        if (columnHints.fontSize) {
            styles.fontSize = columnHints.fontSize;
        }

        if (columnHints.fontWeight) {
            styles.fontWeight = columnHints.fontWeight;
        }

        if (columnHints.alignment) {
            styles.textAlign = columnHints.alignment;
        }
        return styles;
    }
    public getHintList(stringHintList: string): HintList {
        if (stringHelper.isNullOrEmpty(stringHintList)) {
            return {};
        }
        const parts = stringHintList.split(/ *; */);
        const hintList: HintList = {};
        for (const part of parts) {
            if (stringHelper.isNullOrEmpty(part)) {
                continue;
            }
            const expr = part.split(/ *: */);
            hintList[expr[0]] = expr[1];
        }
        return hintList;
    }

    public selectedFormatToDefaultType(formatType: ColumnHintFormatTypes): DefaultColumnHintTypes {
        switch (formatType) {
            case ColumnHintFormatTypes.StringColumns:
                return DefaultColumnHintTypes.String;
            case ColumnHintFormatTypes.NumericColumns:
                return DefaultColumnHintTypes.Number;
            case ColumnHintFormatTypes.DateColumns:
                return DefaultColumnHintTypes.Date;
            case ColumnHintFormatTypes.DateTimeColumns:
                return DefaultColumnHintTypes.DateTime;
            case ColumnHintFormatTypes.AllColumns:
                return DefaultColumnHintTypes.Default;
            case ColumnHintFormatTypes.SpecificColumn:
                throw new Error('Not a default type');
            default:
                throw new Error('Unknown format type');
        }
    }

    public hasDefaultHints = (defaultColumnHints: ColumnHintDefaults): boolean => {
        const defaultsKeys = Object.keys(defaultColumnHints);
        const filteredKeys = defaultsKeys.filter(key => {
            return !this.isADefaultKey(key);
        });
        return !!filteredKeys.length;
    };

    public getPivotedColumnId(columnHint: ColumnHint, columns: GridColumn[]): string {
        if (columnHint.pivotKeys && columnHint.pivotKeys.length) {
            const pivotedColumn = columns.find(col => {
                return (
                    arrayHelper.areIdentical(col.pivotKeys || [], columnHint.pivotKeys || []) &&
                    col.primaryColumnId === columnHint.columnId
                );
            });
            return (pivotedColumn && pivotedColumn.columnId) || columnHint.columnId;
        }
        return columnHint.columnId;
    }

    private isADefaultKey(key: string): boolean {
        return Object.values(DefaultColumnHintTypes).includes(key as DefaultColumnHintTypes);
    }
}

export const columnHintHelper = new ColumnHintHelper();
