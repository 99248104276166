import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as Redux from 'redux';
import { ButtonGroup } from '../../../components/buttons/buttons-group';

import { genericExpressionHelper } from '@gs-ux-uitoolkit-common/datacore';
import { PluginFooter } from '../../../components/plugin-footer/plugin-footer';
import { PluginHeader } from '../../../components/plugin-header/plugin-header';
import { ModalProps, ModalWithItems } from '../../../components/modal-props';
import { GridColumn, GetFormattedValueFromColumn } from '../../../grid-wrappers/grid-wrapper';
import {
    GridGetColumnValueList,
    GridResetColumnValueList,
} from '../../../redux/actions/grid-action';
import {
    CancelEditedRowCustomisation,
    EditRowCustomisation,
    NewRowCustomisation,
    RemoveRowCustomisation,
    SaveEditedRowCustomisation,
    UpdateEditedRowCustomisation,
} from '../../../redux/actions/row-customisation-action';
import { DataGridState } from '../../../redux/datagrid-state';
import { CreateButtonText } from '../../plugin-enum';
import { EntireRowLabel, RowCustomisation } from '../row-customisation';
import { RowCustomisationExpressionBuilder } from './components/row-customisation-expression-builder';
import { RowCustomisationStyleBuilder } from './components/row-customisation-style-builder';
import { RowCustomisationPreviewer } from './row-customisation-previewer';
import { rowCustomisationHelper } from '../row-customisation-helper';
import { rowGroupColumnColId } from '../../../datasources/dra-viewport-datasource';
import { ManagementTable, TableHeadings } from '../../../components/table/management-table';
import { rowCustomisationPluginStyleSheet } from '../../../style/plugins/row-customisation-plugin-stylesheet';

/**
 * The props for the RowCustomisationModalComponent
 */
export interface RowCustomisationModalProps
    extends ModalProps<RowCustomisation>,
        ModalWithItems<RowCustomisation> {
    /**
     * Data for the currently editing rowCustomisation item
     */
    data: RowCustomisation | null;
    /**
     * Columns from the grid
     */
    columns: GridColumn[];
    /**
     * Get the valueList for a column
     */
    gridGetColumnValueList: (columnId: string) => void;
    /**
     * Reset the column valueList in the store
     */
    gridResetColumnValueList: (columnId: string | null) => void;
    getFormattedValueFromColumn: GetFormattedValueFromColumn;
}

export interface RowCustomisationModalState {
    createButtonDisplayName: string;
    createButtonDisabled: boolean;
}

/**
 * The main RowCustomisation modal
 */
export class RowCustomisationModalComponent extends Component<
    RowCustomisationModalProps,
    RowCustomisationModalState
> {
    public constructor(props: RowCustomisationModalProps) {
        super(props);
        this.state = {
            createButtonDisabled: true,
            createButtonDisplayName: CreateButtonText.Create,
        };
    }
    public componentWillUnmount() {
        rowCustomisationPluginStyleSheet.unmount(this);
    }
    public render() {
        const visibleColumns = this.props.columns.filter(
            column => !column.hidden || column.columnId === rowGroupColumnColId
        );
        const cssClasses = rowCustomisationPluginStyleSheet.mount(this, {});
        const rowCustomisationElements = this.props.configItemList.map(
            (rowCustomisation, index) => {
                const editRowCustomisationItem = () => {
                    this.props.editConfigItem(rowCustomisation);
                    this.setState({
                        createButtonDisplayName: CreateButtonText.Update,
                    });
                };
                const removeRowCustomisationItem = () =>
                    this.props.removeConfigItem(rowCustomisation);
                const pivotedColumnId = rowCustomisationHelper.getPivotedApplyToColumnId(
                    rowCustomisation,
                    this.props.columns
                );
                const column = this.props.columns.find(col => col.columnId === pivotedColumnId);
                let columnLabel: string | null = null;
                columnLabel = rowCustomisation.columnId
                    ? column
                        ? column.columnLabel
                        : null
                    : (columnLabel = EntireRowLabel);
                if (columnLabel) {
                    return (
                        <tr key={index}>
                            <td>{columnLabel}</td>
                            <td style={{ padding: '0px' }}>
                                <RowCustomisationPreviewer style={rowCustomisation.style} />
                            </td>
                            <td style={{ wordWrap: 'normal' }}>
                                {genericExpressionHelper.toString(
                                    rowCustomisation.expression.query,
                                    this.props.columns.map(gridColumn => ({
                                        columnId: gridColumn.columnId,
                                        columnLabel: gridColumn.columnLabel,
                                        pivotKeys: gridColumn.pivotKeys,
                                        primaryColumnId: gridColumn.primaryColumnId || '',
                                    })),
                                    true
                                )}
                            </td>
                            <td>
                                <ButtonGroup
                                    editClick={editRowCustomisationItem}
                                    deleteClick={removeRowCustomisationItem}
                                />
                            </td>
                        </tr>
                    );
                }
                return null;
            }
        );

        const rowCustomisationHeading: TableHeadings[] = [
            {
                label: 'Row or Column',
                props: {
                    className: 'column-name-column',
                },
            },
            {
                label: 'Preview',
                props: {
                    className: 'column-name-column',
                },
            },
            {
                label: 'Expression',
                props: {
                    className: 'list-column',
                },
            },
            {
                label: '',
                props: {
                    className: 'action-column',
                },
            },
        ];

        return (
            <Fragment>
                <div
                    className={`config-items-container gs-uitk-row-customisation-plugin ${cssClasses.rowCustomisationModal}`}
                >
                    <PluginHeader
                        displayCreateButton={this.props.data ? false : true}
                        createButtonCallback={this.props.newConfigItem}
                        subTitle={'Row Customisation'}
                    />

                    <div className="gs-uitk-plugin-content-container">
                        {!this.props.data ? (
                            <ManagementTable
                                headingConfig={rowCustomisationHeading}
                                bodyRowElements={rowCustomisationElements}
                            />
                        ) : (
                            <>
                                <RowCustomisationStyleBuilder
                                    columns={visibleColumns}
                                    updatePartialData={this.props.updateEditedConfigItem}
                                    data={this.props.data}
                                />
                                <div>
                                    <RowCustomisationExpressionBuilder
                                        columns={visibleColumns}
                                        gridGetColumnValueList={this.props.gridGetColumnValueList}
                                        gridResetColumnValueList={
                                            this.props.gridResetColumnValueList
                                        }
                                        updatePartialData={this.props.updateEditedConfigItem}
                                        data={this.props.data}
                                        onValidate={(isValid: boolean) =>
                                            this.onExpressionValidate(isValid)
                                        }
                                        getFormattedValueFromColumn={
                                            this.props.getFormattedValueFromColumn
                                        }
                                    />
                                </div>
                                <PluginFooter
                                    createButtonDisplayName={this.state.createButtonDisplayName}
                                    createActionType={'primary'}
                                    onCreate={() => this.finishCreate()}
                                    onCancel={() => this.cancelCreate()}
                                    createDisabled={this.state.createButtonDisabled}
                                />
                            </>
                        )}
                    </div>
                </div>
            </Fragment>
        );
    }
    public onExpressionValidate(isValid: boolean): void {
        this.setState({ createButtonDisabled: !isValid });
    }
    private finishCreate = () => {
        this.props.saveEditedConfigItem();
        this.props.gridResetColumnValueList(null);
        this.resetState();
    };

    private cancelCreate = () => {
        this.props.cancelEditedConfigItem();
        this.props.gridResetColumnValueList(null);
        this.resetState();
    };

    private resetState = () => {
        this.setState({
            createButtonDisplayName: CreateButtonText.Create,
        });
    };
}

export const RowCustomisationPluginModal = (
    getFormattedValueFromColumn: GetFormattedValueFromColumn
) => {
    const mapStateToProps = (state: DataGridState) => {
        return {
            columns: state.grid.columnList,
            configItemList: state.rowCustomisation.configItemList,
            data: state.rowCustomisation.editedItem,
            getFormattedValueFromColumn,
        };
    };

    const mapDispatchToProps = (dispatch: (action: Redux.Action) => void) => {
        return {
            cancelEditedConfigItem: () => dispatch(CancelEditedRowCustomisation()),
            editConfigItem: (rowCustomisation: RowCustomisation) =>
                dispatch(EditRowCustomisation(rowCustomisation)),
            gridGetColumnValueList: (columnId: string) =>
                dispatch(GridGetColumnValueList(columnId)),
            gridResetColumnValueList: (columnId: string | null) =>
                dispatch(GridResetColumnValueList(columnId)),
            newConfigItem: () => dispatch(NewRowCustomisation()),
            removeConfigItem: (rowCustomisation: RowCustomisation) =>
                dispatch(RemoveRowCustomisation(rowCustomisation)),
            saveEditedConfigItem: () => dispatch(SaveEditedRowCustomisation()),
            updateEditedConfigItem: (newData: Partial<RowCustomisation>) =>
                dispatch(UpdateEditedRowCustomisation(newData)),
        };
    };

    return connect(mapStateToProps, mapDispatchToProps)(RowCustomisationModalComponent);
};
