import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {DebounceInput} from 'react-debounce-input';
import cn from 'classnames';
import {PORTFOLIO_FILTER_STATE} from '../../../constants/pageConstants';
import './customSearch.scss';

const trim = (value, isPaste) => {
    return isPaste
        ? value.replace(/(^\s*)/gi, '').// removes leading
            replace(/\s+$/, '').replace(/\n +/, '\n')
        : value.replace(/(^\s*)/gi, '').// removes leading
            replace(/\n +/, '\n');           // Removes spaces after newlines
};

const validate = (char) => {
    const validChars = /[ A-Za-z0-9-+]|[\\b]|[\\t]/;
    return validChars.test(char);
};

const ConditionalWrapper = ({condition, wrapper, children}) => (
    condition ? wrapper(children) : children
);

const ConditionalWrapperComp = (children) => (
    <div className='inputfield'>{children}</div>
);

export class SliderFloatingFilter extends Component {
    static getDerivedStateFromProps(nextProps) {
        let state = {};
        if (nextProps.filterVisibility === PORTFOLIO_FILTER_STATE.HIDDEN) {
            state = {...state, currentValue: ''};
        }
        return state;
    }

    constructor (props) {
        super(props);
        let filterTerm = '';
        const filterBy = props.getFilterBy();
        if (filterBy && props.column) {
            filterTerm = filterBy.find((filter) => {
                return (filter.field === props.column.colId);
            });
            filterTerm = filterTerm && filterTerm.term;
        }

        this.state = {
            currentValue: filterTerm || '',
            showPlaceholder: true,
            gotFocus: false,
            pasted: false
        };
        this.applyFilter = this.applyFilter.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.buildModel = this.buildModel.bind(this);
        this.setModel = this.setModel.bind(this);
        this.onPaste = this.onPaste.bind(this);
    }

    clearSearch() {
        this.state.currentValue && this.setState({
                currentValue: ''
            },
            () => {
                const {colId} = this.props.column;
                this.props.api.setFilterModel({model: this.buildModel()});
                this.props.onFilterChanged(colId, null, true);
            });
    }

    onKeyUp(event) {
        event = event || window.event;
        const keyCode = window.event ? event.keyCode : event.which;
        if (keyCode === 8 || keyCode === 46) {
            this.setState({
                showPlaceholder: event.target.value === ''
            });
            return false;
        }
    }

    onKeyDown(event) {
        event = event || window.event;
        const keyCode = window.event ? event.keyCode : event.which;

        switch (keyCode) {
            case 13:
                this.props.api.setFilterModel({model: this.buildModel()});
                this.state.currentValue.length === 1 && this.setModel();
                return true;
            // escape key */
            case 27:
                this.clearSearch();
                return false;
            case 8:
            case 46:
                return false;
            default:
                this.setState({
                    showPlaceholder: false
                });
                return true;
        }
    }


    buildModel() {
        if (this.state.currentValue === '') {
            return null;
        }
        return {
            filterType: 'string',
            type: 'contains',
            filter: this.state.currentValue
        };
    }


    setModel() {
        this.props.api.setFilterModel({model: this.buildModel()});
        const {colId} = this.props.column;
        this.props.onFilterChanged(colId, this.buildModel(), false);
    }

    applyFilter(event) {
        event.persist();
        // clearTimeout(this.timeout);

        this.setState({
                currentValue: this.state.pasted ? trim(event.target.value, true).trim() : trim(event.target.value),
                pasted: false
            },
            () => {
                if (this.state.currentValue.length !== 1 || (!validate(this.state.currentValue))) {
                    this.setModel();
                }
            }
        );
    }

    onPaste() {
        this.state.pasted = true;
    }

    togglePlaceholder(event, focus) {
        this.setState({
            gotFocus: focus
        });
    }

    render() {
        const {context, column, filterVisibility, onColumnFilterFocus} = this.props;
        const {gsToolKit = false} = context;

        return (
            <div className={gsToolKit ? 'gs-toolkit-filter' : 'custom-search'}>
              {!gsToolKit && this.state.gotFocus && (!this.state.currentValue) && (this.state.showPlaceholder) &&
                <label>Search</label>}
                <ConditionalWrapper condition={gsToolKit} wrapper={ConditionalWrapperComp}>
                  {
                    filterVisibility === PORTFOLIO_FILTER_STATE.VISIBLE &&
                        <DebounceInput
                            value={this.state.currentValue}
                            className={!gsToolKit ? `custom-search__box ${this.state.gotFocus && 'focus'}` : null}
                            debounceTimeout={250}
                            forceNotifyByEnter={true}
                            onChange={this.applyFilter}
                            onKeyUp={this.onKeyUp}
                            onKeyDown={this.onKeyDown}
                            onPaste={this.onPaste}
                            onBlur={event => this.togglePlaceholder(event)}
                            onFocus={(event) => {
                                this.togglePlaceholder(event, true);
                                return onColumnFilterFocus(column, event);
                            }}
                        />
                  }
                </ConditionalWrapper>
                <span
                    className={cn({
                        'filter-icon': gsToolKit,
                        'custom-search__icon': this.state.gotFocus && !gsToolKit,
                        'custom-search__icon close': this.state.currentValue && !gsToolKit
                    })}
                    onClick={this.clearSearch}
                >&nbsp;
          </span>
                {gsToolKit && <div className="divider"/>}
            </div>

        );
    }
}

SliderFloatingFilter.propTypes = {
    context: PropTypes.object,
    column: PropTypes.object,
    onFilterChanged: PropTypes.func,
    getFilterBy: PropTypes.func,
    filterVisibility: PropTypes.string,
    onColumnFilterFocus: PropTypes.func,
    api: PropTypes.object
};

export default SliderFloatingFilter;
