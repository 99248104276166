import { Emotion } from '@emotion/css/types/create-instance';
import { Theme } from './theme';
import { getEmotionInstance, injectFont } from '@gs-ux-uitoolkit-common/style';
import { TwoKeyWeakSet } from 'gs-uitk-set-utils';

// We need to tie the Theme that is injected to the given Emotion instance
// because in a browser, there is just one global Emotion instance, but for SSR
// with Next.js's App Router, there is an Emotion instance per page that is
// being server-side rendered.
const fontsInjectedSet = new TwoKeyWeakSet<Theme, Emotion>();

/**
 * This function will be responsible for injecting the global @font-face rules
 * required by the Theme.
 */
export function injectFontsForTheme(
    theme: Theme,
    { emotionInstance = getEmotionInstance() }: InjectFontsForThemeOptions = {}
) {
    // Somehow theme is passed in as undefined in datagrid common
    // tests requiring the "?" check on theme. It can be removed if
    // the tests are fixed
    if (theme?.fonts) {
        const fontsAlreadyInjected = fontsInjectedSet.has(theme, emotionInstance);
        if (!fontsAlreadyInjected) {
            theme.fonts.forEach(fontFace => injectFont(fontFace, { emotionInstance }));
            fontsInjectedSet.add(theme, emotionInstance);
        }
    }
}

export interface InjectFontsForThemeOptions {
    /**
     * For SSR in Next.js's App Router, we need a context to register the styles
     * for a given SSR request as each SSR request will populate a different
     * Emotion instance with styles. Otherwise defaults to the global Emotion
     * instance.
     */
    emotionInstance?: Emotion;
}
