import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { ModalSize } from './modal-props';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './modal-footer-theme-overrides';

export interface ModalFooterStyleSheetProps {
    theme: Theme;
    size: ModalSize;
}

export interface ModalFooterCssClasses {
    root: string;
}

export type ModalFooterStyledClasses = CssClassDefinitionsObject<keyof ModalFooterCssClasses>;

export interface ModalFooterStyleOverridesParams {
    props: DeepReadonly<ModalFooterStyleSheetProps>;
    createDefaultStyledClasses: () => ModalFooterStyledClasses;
}

export const modalFooterStyleSheet = new StyleSheet(
    'modalFooter',
    (props: ModalFooterStyleSheetProps) => {
        return createComponentClassDefinitions<
            ModalFooterStyleSheetProps,
            ModalFooterStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.modalFooter);
    }
);

function createDefaultStyledClasses({
    size,
}: ModalFooterStyleSheetProps): ModalFooterStyledClasses {
    return {
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: size === 'sm' ? '8px' : '12px',
            '> *': {
                marginLeft: '8px',
            },
        },
    };
}
