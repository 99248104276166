import { colors, fontWeight } from '@gs-ux-uitoolkit-common/design-system';
import { lightTheme, Theme } from '@gs-ux-uitoolkit-common/theme';
import { StyleSheet, CssClasses } from '@gs-ux-uitoolkit-react/style';
import { MOBILE_BREAKPOINT } from '../style-constant';

export const columnFormattingPluginStylePrefix = 'gs-uitk-datagrid_column-formatting';

interface ColumnHintStyleSheet {
    theme?: Theme;
}

export const columnHintStylesheet = new StyleSheet(
    'gs-uitk-column-hint-plugin',
    ({ theme }: ColumnHintStyleSheet) => {
        const themeObject = theme || lightTheme;
        return {
            root: {
                strong: {
                    fontWeight: fontWeight.bold,
                },
                '.gs-input__input': {
                    maxWidth: '100% !important',
                },
                '.column-hint-format-section': {
                    borderBottom: `1px solid ${colors.gray040}`,
                },

                '.column-hint-control-column-width-pixel-container': {
                    display: 'inline-flex',
                },

                '.column-hint-control-column-width-pixel-input': {
                    width: '100%',
                },
                [`& [data-gs-uitk-component='input-group-content']`]: {
                    padding: 0,
                    overflow: 'hidden',
                },
                '.gs-layout-row': {
                    maxWidth: '100%',
                },
                [`.${columnFormattingPluginStylePrefix}_display_in_red`]: {
                    color: themeObject.text.destructive,
                },
                [`.${columnFormattingPluginStylePrefix}_numeric-date-container`]: {
                    display: 'flex',
                    width: '100%',
                    [`.${columnFormattingPluginStylePrefix}_flex-column-container`]: {
                        flex: 1,
                        alignItems: 'center',
                        display: 'flex',
                        '&:first-child': {
                            marginRight: '34px',
                        },
                        a: {
                            color: themeObject.text.link,
                        },
                    },
                    '.gs-checkbox': {
                        marginBottom: 0,
                        minWidth: '105px',
                    },
                    '.column-hint-control-column-width-pixel-input': {
                        marginLeft: '12px',
                    },
                    '[data-gs-uitk-component="select"]': {
                        width: '100%',
                    },
                    [`.${columnFormattingPluginStylePrefix}_specifier-input`]: {
                        width: '100%',
                    },
                },
                [`.${columnFormattingPluginStylePrefix}_numeric-negative-container`]: {
                    marginTop: '11px',
                    display: 'flex',
                    '.gs-checkbox:first-child': {
                        marginRight: '26px',
                    },
                },
                [`.${columnFormattingPluginStylePrefix}_numeric-prefix-container`]: {
                    display: 'flex',
                    '.column-hint-control-column-width-pixel-input': {
                        flex: 1,
                    },
                    '.gs-form-group': {
                        width: '100%',
                        overflow: 'hidden',
                    },

                    '& > .gs-form-group': {
                        flex: 0.333,
                    },
                    [`.${columnFormattingPluginStylePrefix}_numeric-prefix-sub-container`]: {
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 0.666,
                        '.gs-form-group': {
                            marginRight: '24px',
                        },
                    },
                },
                [`.${columnFormattingPluginStylePrefix}_flex-two-column-container`]: {
                    display: 'flex',
                    '& > *': {
                        flex: 1,
                    },
                },
                [`.${columnFormattingPluginStylePrefix}_flex-column`]: {
                    flexDirection: 'column',
                },
                [`.${columnFormattingPluginStylePrefix}_layout-column-size`]: {
                    '.gs-radio': {
                        marginBottom: '0',
                    },
                    '.gs-radio:first-child': {
                        whiteSpace: 'nowrap',
                        marginRight: '28px',
                        flex: 0,
                    },
                    alignItems: 'center',
                    '.gs-input': {
                        minWidth: '111px',
                    },
                },
                [`.${columnFormattingPluginStylePrefix}_column-header-wrapping`]: {
                    alignItems: 'center',
                    minWidth: '160px',
                    display: 'flex',

                    flexDirection: 'column',
                    '.gs-form-group': {
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'flex-start',
                        flex: 1,
                        boxSizing: 'border-box',
                        '.gs-checkbox': {
                            marginBottom: 0,
                        },
                    },
                    '& > .gs-label': {
                        alignSelf: 'flex-start',
                    },
                },
                [`.${columnFormattingPluginStylePrefix}_layout-column-pixel`]: {
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    '& > *': {
                        flex: 1,
                    },
                    '.gs-radio': {
                        minWidth: '75px',
                    },
                },
                [`.${columnFormattingPluginStylePrefix}_alignment-container`]: {
                    flex: 0,
                    marginLeft: '34px',
                    marginRight: '34px',
                },
                [`.${columnFormattingPluginStylePrefix}_text-weight-container`]: {
                    marginRight: '34px',
                },
                [`.${columnFormattingPluginStylePrefix}_no-wrap`]: {
                    whiteSpace: 'nowrap',
                },
                [`.${columnFormattingPluginStylePrefix}_flex`]: {
                    display: 'flex',
                },
                [`.${columnFormattingPluginStylePrefix}_colour-picker-container`]: {
                    alignItems: 'center',
                    display: 'flex',
                    '.gs-input-group': {
                        flex: 1,
                    },
                },
                [`.${columnFormattingPluginStylePrefix}_colour-picker-label`]: {
                    whiteSpace: 'nowrap',
                },
                '.gs-color-picker__selector-chip': {
                    height: '30px',
                },
                [`.${columnFormattingPluginStylePrefix}_colour-container`]: {
                    flexWrap: 'wrap',
                    '.gs-form-group': {
                        flex: 1,
                        minWidth: '173px',
                    },
                    '.gs-form-group:not(:last-child)': {
                        marginRight: '24px',
                    },
                },
                '.input-group-text': {
                    padding: '10px',
                },
                [`.${columnFormattingPluginStylePrefix}_text-size-container`]: {
                    width: 'calc(50% - 16px)',
                },
                [`.${columnFormattingPluginStylePrefix}_other`]: {
                    '.gs-form-group:first-child': {
                        marginRight: '24px',
                    },
                },
                [`.${columnFormattingPluginStylePrefix}_custom-weight`]: {
                    alignItems: 'center',
                    '.gs-checkbox': {
                        marginBottom: 0,
                        marginRight: '12px',
                    },
                },
                [`.${columnFormattingPluginStylePrefix}_hyperlink-container`]: {
                    [`[data-gs-uitk-component="select"]`]: {
                        maxWidth: '162px',
                        width: '100%',
                    },
                    [`[data-cy="gs-uitk-datagrid-column-hint-url"]`]: {
                        flex: 1,
                    },
                    [`.${columnFormattingPluginStylePrefix}_iframe-container`]: {
                        flex: 1,
                        marginLeft: '24px',
                        alignItems: 'center',
                        '.gs-checkbox': {
                            minWidth: '85px',
                            marginRight: '12px',
                            marginBottom: '0',
                        },
                    },
                    [`[data-cy="gs-uitk-datagrid-column-iframe-input"]`]: {
                        flex: 1,
                    },
                    [`.${columnFormattingPluginStylePrefix}_pivot-container`]: {
                        '.gs-form-group:first-child': {
                            marginRight: '24px',
                        },
                    },
                },
                [`.${columnFormattingPluginStylePrefix}_date-format-type`]: {
                    marginBottom: '16px',
                },
                [`.${columnFormattingPluginStylePrefix}_layout`]: {
                    flexWrap: 'wrap',
                },

                [`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
                    [`.${columnFormattingPluginStylePrefix}_alignment-container`]: {
                        marginLeft: 0,
                        marginRight: 0,
                    },
                    [`.${columnFormattingPluginStylePrefix}_numeric-date-container`]: {
                        flexDirection: 'column',
                        [`.${columnFormattingPluginStylePrefix}_flex-column-container`]: {
                            '&:first-child': {
                                marginRight: '0',
                                marginBottom: '16px',
                            },
                        },
                    },
                    [`.${columnFormattingPluginStylePrefix}_numeric-prefix-container`]: {
                        flexWrap: 'wrap',
                        '& > .gs-form-group': {
                            flex: 1,
                            minWidth: '100%',
                        },
                        [`.${columnFormattingPluginStylePrefix}_numeric-prefix-sub-container`]: {
                            flex: 1,
                            '.gs-form-group:last-child': {
                                marginRight: '0',
                            },
                        },
                    },
                    [`.${columnFormattingPluginStylePrefix}_layout`]: {
                        flexDirection: 'column',
                    },
                    [`.${columnFormattingPluginStylePrefix}_text-container`]: {
                        flexDirection: 'column',
                    },
                    [`.${columnFormattingPluginStylePrefix}_text-weight-container`]: {
                        marginRight: '0',
                        marginBottom: '24px',
                    },
                    [`.${columnFormattingPluginStylePrefix}_text-size-container`]: {
                        width: '100%',
                    },
                    '.gs-input__input': {
                        maxWidth: '100% !important',
                    },
                    [`.${columnFormattingPluginStylePrefix}_colour-container`]: {
                        flexDirection: 'column',
                        '.gs-form-group:not(:last-child)': {
                            marginRight: '0',
                        },
                    },
                    [`.${columnFormattingPluginStylePrefix}_other`]: {
                        flexDirection: 'column',
                        '.gs-form-group:first-child': {
                            marginRight: '0',
                        },
                    },
                    [`.${columnFormattingPluginStylePrefix}_hyperlink-container`]: {
                        [`[data-gs-uitk-component="select"]`]: {
                            maxWidth: '100% !important',
                        },
                        [`.${columnFormattingPluginStylePrefix}_target-container`]: {
                            flexDirection: 'column',
                        },
                        [`.${columnFormattingPluginStylePrefix}_iframe-container`]: {
                            marginLeft: '0 !important',
                            marginTop: '12px',
                        },
                        [`.${columnFormattingPluginStylePrefix}_pivot-container`]: {
                            flexDirection: 'column',
                        },
                    },
                },
            },
            '.column-hint-red-if-negative': {
                color: colors.red050,
            },
        };
    }
);

export const getRedNegativeClassNames = (props: {
    cssClasses: CssClasses<typeof columnHintStylesheet>;
}): string => {
    return props.cssClasses['.column-hint-red-if-negative'];
};
