import { SearchableActionKeys } from '../actions/action-constants';
import { SearchableActionTypes } from '../actions/searchable-action';
import { SearchableState } from '../datagrid-state';

/**
 * Initial state of the Searchable State
 */
export const defaultSearchableState: SearchableState = {
    currentSearch: '',
    DRASearchableColumnList: null,
};

/**
 * Reducer for the SearchableState
 * @param state State of the SearchableState
 * @param action Action received
 */
export const searchableReducer = (
    state: SearchableState = defaultSearchableState,
    action: SearchableActionTypes
): SearchableState => {
    switch (action.type) {
        case SearchableActionKeys.SET_SEARCH:
            return {
                ...state,
                currentSearch: action.search,
            };
        case SearchableActionKeys.SET_DRA_SEARCHABLE_COLUMN_LIST:
            return {
                ...state,
                DRASearchableColumnList: action.DRASearchableColumnList,
            };

        default:
            return state;
    }
};
