import { Component, Fragment, ChangeEvent } from 'react';
import { FormGroup } from '@gs-ux-uitoolkit-react/form';
import { Input } from '@gs-ux-uitoolkit-react/input';
import { Label } from '@gs-ux-uitoolkit-react/label';
import { HintList } from '@gs-ux-uitoolkit-common/datacore';
import { Select, SelectOption, SelectChangeEvent } from '@gs-ux-uitoolkit-react/select';
import { FormatControlProps } from '../column-hint-format-manager';
import { columnFormattingPluginStylePrefix } from '../../../../../style/plugins/column-hint-plugin-stylesheet';
import { Checkbox, CheckboxChangeEvent } from '@gs-ux-uitoolkit-react/checkbox';

export enum FontWeight {
    Normal = 'normal',
    Bold = 'bold',
    Lighter = 'lighter',
}
export class ColumnHintControlFontWeight extends Component<FormatControlProps> {
    public render() {
        const fontWeightOptions: SelectOption[] = [
            { label: 'Light', value: FontWeight.Lighter },
            { label: 'Regular', value: FontWeight.Normal },
            { label: 'Bold', value: FontWeight.Bold },
        ];

        const radioNamedChecked: boolean =
            this.props.columnHint.fontWeight === undefined ||
            this.props.columnHint.fontWeight === FontWeight.Lighter ||
            this.props.columnHint.fontWeight === FontWeight.Normal ||
            this.props.columnHint.fontWeight === FontWeight.Bold;

        return (
            <Fragment>
                <FormGroup>
                    <Label>Text Weight</Label>
                    <div
                        className={`${columnFormattingPluginStylePrefix}_text-container ${columnFormattingPluginStylePrefix}_flex-two-column-container`}
                    >
                        <div
                            className={`${columnFormattingPluginStylePrefix}_text-weight-container`}
                        >
                            <Select
                                options={fontWeightOptions}
                                onChange={this.onFontWeightNamedChanged}
                                selectedValue={
                                    (radioNamedChecked &&
                                        (this.props.columnHint.fontWeight as string)) ||
                                    ''
                                }
                                disabled={!radioNamedChecked}
                            />
                        </div>
                        <div
                            className={`${columnFormattingPluginStylePrefix}_custom-weight ${columnFormattingPluginStylePrefix}_no-wrap ${columnFormattingPluginStylePrefix}_flex`}
                        >
                            <Checkbox
                                onChange={this.onChangeWeight}
                                name={`${columnFormattingPluginStylePrefix}_weight-checkbox`}
                                checked={!radioNamedChecked}
                            >
                                Custom Weight
                            </Checkbox>
                            <Input
                                className={'column-hint-control-column-width-pixel-input'}
                                value={
                                    (!radioNamedChecked && this.props.columnHint.fontWeight) || ''
                                }
                                placeholder="e.g 900"
                                onChange={this.onFontWeightValueChanged}
                                disabled={radioNamedChecked}
                            />
                        </div>
                    </div>
                </FormGroup>
            </Fragment>
        );
    }

    private onChangeWeight = (e: CheckboxChangeEvent) => {
        const checked = e.checkedState === true;
        const clonedHint: HintList = {
            ...this.props.columnHint,
            fontWeight: checked ? '' : undefined,
        };
        this.props.onChangeHint(clonedHint);
    };

    private onFontWeightNamedChanged = (e: SelectChangeEvent) => {
        const value = e.selectedValue;

        const clonedHint: HintList = {
            ...this.props.columnHint,
            fontWeight: value || FontWeight.Normal,
        };

        if (value === null) {
            delete clonedHint.fontWeight;
        }

        this.props.onChangeHint(clonedHint);
    };

    private onFontWeightValueChanged = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const clonedHint: HintList = {
            ...this.props.columnHint,
            fontWeight: value,
        };
        this.props.onChangeHint(clonedHint);
    };
}
