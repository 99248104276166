import { createContext } from 'react';
import { StepsProps } from '@gs-ux-uitoolkit-common/steps';

// Context to allow child Step components to know about the configuration of the
// parent Steps component.
export const StepsContext = createContext<StepsContextObject>({
    index: -1, // will be filled by Steps component
});

// Each individual Step instance will need to know the index it is within
// the parent Steps component
export type StepsContextObject = StepsProps & { index: number };
