import { portfolioAnalytics, SORT } from '../constants/pageConstants';

// All the helper function
const getColumnList = (headers) => {
  let headersList = '';
  headers && headers.length && headers.forEach((headersData, index) => {
    if(index !== (0 || headers.length-1)){
      headersList += `${headersData.headerName}|`;
    } else {
      headersList += `${headersData.headerName}`;
    }
  });
  return headersList;
};

// All Analytics
export const getGridLoadAnalyticsData = (eventType, columns, viewName, numberOfRecords, filterTerm, time ) => {
  const getFilterTerm = () => eventType === portfolioAnalytics.EVENT_COLUMN_SEARCH && { filterTerm };
  return {
    event: {
      eventName: portfolioAnalytics.EVENT_GRID_LOAD,
      eventType,
      valueChosen: getColumnList(columns),
      viewChosen: viewName,
      numberOfRecords,
      timeDiff: new Date().getTime() - time,
      ...getFilterTerm()
    }
  };
};

export const getColumnRearrangeAnalyticsData = (columnName) => ({
  event: {
    eventName: portfolioAnalytics.EVENT_COLUMN_REARRANGE,
    valueChosen: columnName
  }
});

export const getColumnSortingAnalyticsData = (order, columnName) => ({
  event: {
    eventName: portfolioAnalytics.EVENT_COLUMN_SORTING,
    eventStage:  order === SORT.ASC ? 'ascending' : 'descending',
    valueChosen: columnName
  }
});

export const getColumnSearchAnalyticsData = (headerName) => ({
  event: {
    eventName: portfolioAnalytics.EVENT_COLUMN_SEARCH,
    valueChosen: headerName
  }
});

export const getColumnRemoveAnalyticsData = (columns, columnName, viewName) => ({
  event: {
    eventName: portfolioAnalytics.EVENT_COLUMN_REMOVE,
    valueChosen: getColumnList(columns),
    viewChosen: viewName,
    columnRemoved: columnName
  }
});

export const getGroupChangeAnalyticsData = (groupName) => ({
  event: {
    eventName: portfolioAnalytics.EVENT_GROUP_CHANGE,
    eventStage: 'group_by_changed',
    valueChosen: groupName
  }
});

export const getViewChangeAnalyticsData = (columns, viewName) => ({
  event: {
    eventName: portfolioAnalytics.EVENT_VIEW_CHANGE,
    eventStage: 'view_changed',
    valueChosen: getColumnList(columns),
    viewChosen:  viewName
  }
});

export const getExpandCollapseAnalyticsData = (state) => ({
  event: {
    eventName: portfolioAnalytics.EVENT_EXPAND_COLLAPSE,
    eventStage: state ? 'on': 'off'
  }
});

export const getExportViewAnalyticsData = (columns, viewName) => ({
  event: {
    eventName: portfolioAnalytics.EVENT_EXPORT_VIEW,
    fileType: 'Excel',
    valueChosen: getColumnList(columns),
    viewChosen: viewName
  }
});

export const getAddToBlotterAnalyticsData = () => ({
  event: {
    eventName: portfolioAnalytics.EVENT_ADD_TO_BLOTTER
  }
});

export const getSaveViewAnalyticsData = (columns) => ({
  event: {
    eventName: portfolioAnalytics.EVENT_VIEW_SAVE,
    eventStage: 'save_and_apply',
    valueChosen: getColumnList(columns)
  }
});

export const getOpenFundFactCardAnalyticsData = (rebateExists = false, fundIdentifier) => ({
  page: {
    pageInfo: {
      component:'Fund Fact card'
    }
  },
  event: {
    eventName: portfolioAnalytics.EVENT_FUND_FACT_OPEN,
    eventStage:'open',
    eventDesc: '',
    eventDetail: '',
    eventAction: '',
    ...(
      rebateExists
      && {
        eventDesc: 'RebateFlowInteraction',
        eventDetail: 'FundFactCardDisplayWithRebate',
        eventAction: fundIdentifier,
        satelliteTrackEventName: 'rebatevents',
        componentInfo: {
          componentName: 'Fund Fact Card'
        }
      }
    )
  }
});

export const getCloseFundFactCardAnalyticsData = () => ({
  event: {
    eventName: portfolioAnalytics.EVENT_FUND_FACT_CLOSE,
    eventStage:'close',
    resourceEndTime: Date.now()
  }
});

export const getFundFactCardAnalyticsData = (resourceName, link) => ({
  event: {
    eventName: 'fund_fact_resource',
    valueChosen: link,
    resourceName,
    resourceStartTime: Date.now(),
    eventStage: 'open'
  }
});

export const getZeroBalanaceAccount = (state) => ({
  event: {
    eventName: portfolioAnalytics.EVENT_ZERO_BALANCE_FUNDS,
    eventStage: state ? 'on' : 'off'
  }
});

export const getOpenAccountDetailsFactCardAnalyticsData = () => ({
  page: {
    pageInfo: {
      component:'Account Details Fact card'
    }
  },
  event: {
    eventName: portfolioAnalytics.EVENT_ACCOUNT_DETAILS_FACT_OPEN,
    eventStage:'open'
  }
});

export const getCloseAccountDetailsFactCardAnalyticsData = () => ({
  event: {
    eventName: portfolioAnalytics.EVENT_ACCOUNT_DETAILS_CLOSE,
    eventStage:'close',
  }
});

export const getRebateCTAAnalyticsData = (eventDetail, component) => ({
  event: {
    eventDesc: 'RebateFlowInteraction',
    eventDetail,
    eventAction: 'Click',
    componentInfo: {
      componentName: component
    },
    satelliteTrackEventName: 'rebatevents'
  }
});

export const getRebateToggleAnalyticsData = (eventDetail, fundIdentifier) => ({
  event: {
    eventDesc: 'RebateFlowInteraction',
    eventDetail,
    eventAction: fundIdentifier,
    componentInfo: {
      componentName: 'Fund Fact Card'
    },
    satelliteTrackEventName: 'rebatevents'
  }
});

export const getRebateSaveAnalyticsData = () => ({
  event: {
    eventDesc: 'rebate_completed',
    eventDetail: "Rebate Success",
    eventAction: "success",
    componentInfo: {
      componentName: 'Fund Fact Card'
    },
    satelliteTrackEventName: 'rebatevents'
  }
});

export const getRebateErrorAnalyticsData = (errorMessage) => ({
  event: {
    eventDesc: 'rebate_error',
    eventDetail: errorMessage,
    eventAction: "error",
    componentInfo: {
      componentName: 'Error Dialog Box'
    },
    satelliteTrackEventName: 'rebatevents'
  }
});

export const getRebateDeleteAnalyticsData = () => ({
  event: {
    eventDesc: 'RebateFlowInteraction',
    eventDetail: 'Rebate Delete',
    eventAction: 'Delete',
    componentInfo: {
      componentName: 'Fund Fact Card'
    },
    satelliteTrackEventName: 'rebatevents'
  }
});
