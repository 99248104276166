import React, {useState}  from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
const ListType = {
  ADD: 'add',
  REMOVE: 'remove'
};

const ListItem = (props) => {
  const {selected, disabled, label, value, index, testId, type, onSelect, onAdd, onRemove} = props;
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);
  const itemTestId = `${testId}-item-${index}`;

  const handleAddClick = (addedItemValue, addedItemProps) => {
    onAdd(addedItemValue, addedItemProps);
  };
  const handleRemoveClick = (removedItemValue, removedItemProps) => {
    onRemove(removedItemValue, removedItemProps);
  };

  const handleSelect =  (selectedItemValue, selectedItemProps) => {
    onSelect(selectedItemValue, selectedItemProps);
  };

  const onActionClick = () => {
    if (type === ListType.ADD) {
      handleAddClick(value, props);
    }
    handleRemoveClick(value, props);
  };

  return (
    <li
      className={cn(`${testId}__item`, {[`${testId}__item--selected`]: selected}, {[`${testId}__item--hovered`]: hover},
        {[`${testId}__item--disabled`]: disabled}, {[`${testId}__item--focused`]: focus})}
      data-testid={`${itemTestId}-item-${index}`}
      onFocus={(e) => {e.stopPropagation(); setFocus(true);}}
      onBlur={(e) => {e.stopPropagation();  setFocus(false);}}
      onMouseEnter={(e) => {e.stopPropagation(); setHover(true);}}
      onMouseLeave={(e) => {e.stopPropagation();  setHover(false);}}>
      <div data-value={value} onClick={handleSelect}>
        <label>{label}</label>
        <span onClick={onActionClick} className={cn(`${testId}__remove`, {[`${testId}__remove--disabled`]: disabled})} data-testid={`${itemTestId}-icon`}>
          <span className="icon" />
        </span>
      </div>
    </li>
  );
};

ListItem.defaultProps = {
  testId: '',
  selected: false,
  disabled: false,
  onSelect: () =>  { /* TODO: This will be implemented when edit mode is enabled */ }
};

ListItem.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  index: PropTypes.number,
  testId: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onSelect: PropTypes.func,
};

export default ListItem;
