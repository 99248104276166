import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import {errorMessages} from '../../../error/errorType';
import Button from '../Button';
import './index.scss';


const ErrorBody = ({message}) => {
  return (
    <div className="global-server-error__content">
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message)}} />
    </div>);
};

ErrorBody.propTypes = {
  message: PropTypes.string
};

const Error = ({code, label, messages, children, props, customClass='' }) => {
  const Child = children ? React.Children.toArray(children)[0].type : null;
  const clickHandler = () => {
    window.location.href = errorMessages[code].tkLink;
  };
  return (
    <div className={`global-server-error ${customClass}`} >
      <h1 className="error__title">{(messages && messages.title) || label(errorMessages[code].tkTitle)}</h1>
      {children ?  <Child {...props} /> : <ErrorBody message={(messages && messages.body) || label(errorMessages[code].tkBody)} />}
      {errorMessages[code].tkButton && <Button label={label(errorMessages[code].tkButton)} customClass='button-primary-dark' clickHandler={clickHandler} />}
    </div>
  );
};

Error.propTypes = {
  messages: PropTypes.object,
  title: PropTypes.string,
  children: PropTypes.any,
  customClass: PropTypes.string,
  props: PropTypes.object,
  code: PropTypes.string,
  label: PropTypes.func
};

export default Error;
