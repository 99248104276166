import { useContext, CSSProperties, ReactNode, FC, memo } from 'react';
import PropTypes from 'prop-types';
import { cardStyleSheet, getCardFooterClasses } from '@gs-ux-uitoolkit-common/card';

import { ComponentProps } from '@gs-ux-uitoolkit-react/component';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { CardContext } from './card-context';

export interface CardFooterProps extends ComponentProps<CSSProperties> {
    children?: ReactNode;
}

/**
 * CardFooter provides appropriate spacing around the footer section of a Card.
 */
export const CardFooter: FC<CardFooterProps> = memo(props => {
    const { className, children, ...attributes } = props;

    const theme = useTheme();
    const { size } = useContext(CardContext);
    const cssClasses = useStyleSheet(cardStyleSheet, { theme, size });

    return (
        <div
            data-gs-uitk-component="card-footer"
            {...attributes}
            className={getCardFooterClasses({ cssClasses, className })}
        >
            {children}
        </div>
    );
});
CardFooter.displayName = 'CardFooter';

CardFooter.propTypes = {
    className: PropTypes.string,
};
