import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import find from 'lodash/find';
import extend from 'lodash/extend';
import intersectionBy from 'lodash/intersectionBy';
import cloneDeep from 'lodash/cloneDeep';
import {labelSelector, draftTradesInBlotterSelector, isTradeSaveFailed, snackbarDataList as appSnackbarDataList} from '../app';
import { isTradingDisabledSelector } from '../containers/tradeBlotter';
import errorTypes, {errorMessages} from '../../error/errorType';
import {
  COLUMN_COLID, PORTFOLIO_FILTER_STATE, SHOW_ERROR_ON, SORT,
  TRADE_COLUMN_CONFIG
} from '../../constants/pageConstants';

import {isTradingAllowedSelector, isInternalUserSelector} from '../user';
import translator  from '../../services/translator';
import {FILE_DOWNLOAD_ORIGIN, FILE_DOWNLOAD_TYPES} from "../../constants/appConstants";
import {getFileDownloadSignaturesSelector} from "../app/reports";
import {entitledToRebates} from "../containers/fundFactView";

const {translate: t} = translator;

const defaultFilterBy = [];
const defaultErrorObject = {};
const defaultManagers = [];
const defaultColumns = [];
const defaultViews = [];
const defaultCurrencies = [];
const defaultSelectedRow = [];
const defaultFundTypes = [];

// page data only

export const getLastEvent = state => state.pageContext.fundFinderData && state.pageContext.fundFinderData.event;
export const allManagers =  state => state.pageData.managers || [];
export const allCurrencies =  state => state.pageData.fundCurrencies || [];
export const allEsg = state => state.pageData.esgFund || [];
export const allFundInvestmentTypes = state => state.pageData.fundInvestmentTypes || [];
export const columnsMetadata = (state) => {
  return state.pageData.columnsMetadata && state.pageData.columnsMetadata.columns || defaultColumns;
};

// preferences only
export const institutionalShareClassOnly = state => state.preferences.fundFinder && state.preferences.fundFinder.institutionalShareClassOnly;
export const openAccountsOnly = state => state.preferences.fundFinder && state.preferences.fundFinder.openAccountsOnly;
export const getBaseCcy = state => state.preferences && state.preferences.global && state.preferences.global.baseCcy;
export const getFundManagersPreferences = state => state.preferences && state.preferences.fundFinder && state.preferences.fundFinder.fundManagers || defaultManagers;
export const getCurrenciesPreferences = state => state.preferences && state.preferences.fundFinder && state.preferences.fundFinder.currencies || defaultCurrencies;
export const getFundInvestmentTypesPreferences = state => state.preferences && state.preferences.fundFinder && state.preferences.fundFinder.fundTypes || defaultFundTypes;
const filterVisibility = state => state.pageContext.filterVisibility || PORTFOLIO_FILTER_STATE.HIDDEN;

export const fundFinderResponse = state => state.pageContext.fundFinderData;
export const getFilters = state => state.pageContext.filterBy || defaultFilterBy;
export const getFilteredDataLength = state => state.pageContext.filteredDataLength;
export const getFilterModel = state => state.pageContext.filterModel || null;
export const isLoading = state => state.pageContext.loading;
export const modelActiveMode = state => state.pageContext.modelActiveMode;
export const isModalLoading = state => state.pageContext.isModalLoading;
export const checkIsPageInErrorMode = state => state.pageContext.isPageInErrorMode || false;
export const duplicateViewName = state => state.pageContext.duplicateViewName;
export const allViews =  state => state.pageContext.views || defaultViews;
export const selectedViewId = state => state.pageContext.selectedViewId;
export const isOpenCustomViewModal = state => state.pageContext.isOpenCustomViewModal || false;
export const getSelectedFileType = state => state.pageContext.fundFinderFileDownloadType || FILE_DOWNLOAD_TYPES.EXCEL;


export const selectedRows = createSelector(
  draftTradesInBlotterSelector,
  (draftTrades) => {
    return draftTrades && draftTrades.length && draftTrades.map(trade => ({id: trade.shareClass.id})) || defaultSelectedRow;
  }
);

export const getFundManagers = state => state.pageContext.fundManagers || defaultManagers;

export const showErrorOn = state => ((state.pageContext.errorObject && state.pageContext.errorObject.showErrorOn) || '');

export const getErrorObject = state => state.pageContext.errorObject || defaultErrorObject;
export const exportExcel = state => state.pageContext.fundFinderExportExcel;

export const isInstitutionalShareClassOnly = createSelector (
  institutionalShareClassOnly, (instShareClassOnly) =>  instShareClassOnly);

export const isOpenAccountsOnly = createSelector (
  openAccountsOnly, (openAccountsOnly) =>  openAccountsOnly);

export const startExcelExport = createSelector(exportExcel, (exportExcel) => {
  return exportExcel;
});

export const getFilterVisibility =  createSelector(filterVisibility, (filterVisibility) => filterVisibility);

export const getSelectedManagers = createSelector(getFundManagersPreferences, (managers) => managers);

export const getSelectedCurrencies = createSelector(getCurrenciesPreferences, (currencies) => currencies);


export const getActiveView = createSelector(allViews, (views) => views.find(view => view.isActive));

export const getActiveViewName = createSelector(getActiveView, (view) => view && view.name || '');

export const getGridData = createSelector(
  fundFinderResponse,
  (fundFinderResponse) => {
    if(fundFinderResponse && fundFinderResponse.funds && fundFinderResponse.funds.length){
      return fundFinderResponse.funds;
    }
    return [];
  }
);

export const commonManagers = createSelector(
  allManagers,
  getFundManagersPreferences,
  (allManagers, managersPreferences) => allManagers.filter(manager => managersPreferences.includes(manager.code))
);

export const commonCurrencies = createSelector(
  allCurrencies,
  getCurrenciesPreferences,
  (allCurrencies, currenciesPreferences) => allCurrencies.filter(currency => currenciesPreferences.includes(currency.code))
);

export const getSavedInvestmentTypes = createSelector(getFundInvestmentTypesPreferences, allFundInvestmentTypes
  , (investmentTypesPref, allInvestmentTypes) => {
    return investmentTypesPref.map((pref) => {
      const obj = find(allInvestmentTypes, {code: pref.code});
      const isAllSelected = obj && obj.investmentTypes.length === pref.investmentTypes.length;
      return {...pref, isAllSelected };
    });
  });

export const flattenInvestmentTypesPreferences = createSelector(
  getFundInvestmentTypesPreferences,
  (types) => {
    const list = [];
    types && types.forEach((type) => {
      list.push({code: type.code,  nodeId: type.code});
      type.investmentTypes && type.investmentTypes.forEach((code) => {
        list.push({code, nodeId: code, parentId: type.code});
      });
    });
    return list;
  }
);

export const flattenAllInvestmentTypes = createSelector(
  allFundInvestmentTypes,
  (types) => {
    const list = [];
    types && types.forEach((type) => {
      list.push({code: type.code,  nodeId: type.code});
      type.investmentTypes && type.investmentTypes.forEach((iType) => {
        list.push({code: iType.code, nodeId: iType.code, parentId: type.code});
      });
    });
    return list;
  }
);


export const getManagersList = createSelector(
  getFundManagersPreferences,
  allManagers,
  commonManagers,
  (managers, all, commonManagers) => {

    const selectAll = commonManagers.length === all.length || managers.length === 0 || commonManagers.length === 0;
    const list = [{
      value: '',
      type: 'checkbox',
      label: 'All Managers',
      selected: selectAll,
      nodeId: 'all',
      open: false
    }];

    all && all.forEach((manager) => {
      const isSelected = (managers.some((mgr) => mgr === manager.code)) || selectAll;
      list.push({
        value: manager.code,
        type: 'checkbox',
        label: manager.name,
        disabled: manager.disabled ? 'no-data' : '',
        selected: isSelected && !manager.disabled,
        parentId: 'all',
        nodeId: manager.code
      });
    });
    return list;
  });

export const getEsgList = createSelector(
  columnsMetadata,
  (metadata) => {
    const esgDataList = new Map();

    for(let i = 0; i < metadata.length; i++) {
      if (metadata[i].category === 'tkESGCharacteristics' || metadata[i].colId === 'euSFDRArticle6' || metadata[i].colId === 'euSFDRArticle8' || metadata[i].colId === 'euSFDRArticle9') {
        if (metadata[i].colId !== 'esgCharacteristicsAsOf' && metadata[i].colId !== 'euSFDR') {
          esgDataList.set(metadata[i].headerName, metadata[i].customFilterValueGetter);
        }
      }
    }

    const selectAll = metadata.length === 8 || metadata.length === 0;
    const list = [{value: '', type: 'checkbox', label:  t('tkIncludeAllESG/D&ICharacteristics'), selected: selectAll, nodeId: 'parentdata', open: false}];

    esgDataList && esgDataList.forEach((esgValue, esgKey) => {
      const isSelected = (metadata.some((esg) => esg === esgKey.colId)) || selectAll;
      list.push({value: esgValue, type: 'checkbox', label: t(esgKey), disabled: '', selected: isSelected, parentId: 'parentdata', nodeId: 'childdata' });
    });
    return list;
  });


export const getCurrencies = createSelector(
  getCurrenciesPreferences,
  allCurrencies,
  commonCurrencies,
  (currencies, all, commonCurrencies) => {

    const selectAll = commonCurrencies.length === all.length || currencies.length === 0 || commonCurrencies.length === 0;
    const list = [{value: '', type: 'checkbox', label: t('tkAllCurrencies'), selected: selectAll, nodeId: 'allCurrencies', open: false}];

    all && all.forEach((currency) => {
      const isSelected = (currencies.some((cur) => cur === currency.code)) || selectAll;
      list.push({value: currency.code, type: 'checkbox', label: t(`tk${currency.code}`), disabled: currency.disabled ? 'no-data' : '', selected: isSelected && !currency.disabled, parentId: 'allCurrencies', nodeId: currency.code });
    });
    return list;
  });

export const getFundInvestmentTypes = createSelector(
  flattenInvestmentTypesPreferences,
  flattenAllInvestmentTypes,
  (preferences, all) => {
    const selectAll = all.length === preferences.length || preferences.length === 0;
    const list = [];
    all && all.forEach((type) => {
      const isSelected = (preferences.some((pref) => pref.code === type.code && pref.parentId === type.parentId)) || selectAll;
      list.push({value: type.code, type: 'checkbox', label: t(type.code), selected: isSelected, parentId: type.parentId, nodeId: type.nodeId });
    });
    return list;
  });


export const getManagerNames = createSelector(
  getFundManagersPreferences,
  getFundManagers,
  allManagers,
  (preferences, managers, all) => {
    let managersArr = [];
    const managerList = (managers.length ? managers : preferences);
    if (all.length && managerList.length) {
      managerList.forEach((manager) => {
        const managerItem = all.find(mgrData => (mgrData.code === manager));
        managerItem && managersArr.push(managerItem);
      });
    }else{
      managersArr = all;
    }
    return managersArr.map(manager => manager.name).join(',');
  });

export const getViewsList = createSelector(
  allViews,
  (views) => {
    const list = [];
    views && views.forEach((view) => {
      list.push({value: view.name, label: view.name, selected: view.isActive, parentId: 'views', nodeId: view.id });
    });
    return list;
  });


export const disableExcelExport = createSelector(
  getGridData,
  getFilteredDataLength,
  filterVisibility,
  getFilters,
  (data, filteredData, filterVisibility, filters = []) => {
    if(filterVisibility === PORTFOLIO_FILTER_STATE.HIDDEN){
      return !data.length;
    } else {
      return filters.length ? !filteredData : !data.length;
    }
  }
);

export const gridDataErrorCode = createSelector(
  fundFinderResponse,
  (fundFinderResponse) => {
    if(fundFinderResponse && fundFinderResponse.errorCode){
      return fundFinderResponse.errorCode;
    }
    return '';
  }
);


export const currentViewSelector = createSelector(
  allViews,
  isTradingAllowedSelector,
  isTradingDisabledSelector,
  isInternalUserSelector,
  (allViews, canTrade, isTradingDisabled, isInternalUser) => {
    const currentView = allViews.filter(view => view.isActive).length ? allViews.filter(view => view.isActive)[0] : {};
    if(currentView && currentView.columns){
      if((!canTrade || isTradingDisabled || isInternalUser)){
        return { ...currentView, columns: currentView.columns.filter(column => column.colId !== COLUMN_COLID.TRADE)};
      } else {
        const index = currentView.columns.findIndex(column => column.colId === COLUMN_COLID.TRADE);
        if(index === -1){
          let colIndex = 0;
          const columns = [{...TRADE_COLUMN_CONFIG, columnIndex: colIndex}];
          currentView.columns.forEach(column => {
            if(column.hasOwnProperty('columnIndex')){
              columns.push({ ...column, columnIndex: ++colIndex});
            }
          });
          return { ...currentView, columns};
        }
      }
    }
    return currentView;
  }
);

export const getCurrentGridView = createSelector (
  currentViewSelector,
  columnsMetadata,
  labelSelector,
  (viewColumns, metadata, labels) =>  {
    const {columns = []} = viewColumns;

    if(columns) {
      const intersectionColumns = intersectionBy(columns, metadata, 'field');
      const data = intersectionColumns.map((column) => {
        const metadataObject = metadata.find(data => data.field === column.field);
        const category = metadataObject.category ? { category: labels[metadataObject.category]} : {};
        const transformedName = {
          headerTooltip: labels[metadataObject.headerName],
          headerName: labels[metadataObject.headerName]
        };
        return extend({}, metadataObject, column, transformedName, category);
      });

      data.forEach(header => {
        if(header.sort === 'none'){
          delete header.sort;
        }
        if(header.hasOwnProperty('width')){
          header.suppressSizeToFit = true;
        }
        const {dependentColumns = []} = header;
        if(dependentColumns.length){
          header.lockVisible = data.some( item => dependentColumns.includes(item.field));
        }
      });

      return sortBy(data, (header) => { return header.columnIndex;});
    }
    return [];
  });


export const gridHeader = createSelector(
  getCurrentGridView,
  (headerData = []) => {
    if(!headerData.length) return [];
    return headerData;
  }
);

export const getActiveColumnsName = createSelector(
  gridHeader,
  (data) => data.map((item) => item.headerName).join('|')
);

export const metaDataSelector = createSelector (
  columnsMetadata,
  labelSelector,
  entitledToRebates,
  (metaData, labels, entitledToRebates) => {
    const columnsWithRebatesCheck = !entitledToRebates && metaData.filter(
      data => data.headerName !== 'tk1DayAdjYield' && data.headerName !== 'tk7DayAdjCurYield' && data.headerName !== 'tk7DayAdjEffYield' && data.headerName !== 'tk30DayAdjYield') || metaData;
    return columnsWithRebatesCheck.map( data => {
      const columnData = {...data};
      columnData.headerTooltip = labels[data.headerName];
      columnData.headerName = labels[data.headerName];
      if(data.category) {
        columnData.category = labels[data.category];
      }
      return columnData;
    });
  }
);

export const selectedModelView = createSelector(
  allViews,
  selectedViewId,
  (allview, id) => {
    if(!id){
      return allview.find((view) => view.isActive);
    }
    return allview.find((view) => view.id === id);
  });


export const uniqueColumnsSelector = createSelector(
  metaDataSelector,
  columns => {
    const headers = [];
    columns.forEach(column => {
      if(column.hasOwnProperty('category')) {
        headers.push(column.category);
      }
    });
    const uniqueHeaders = uniq(headers).sort();
    if(uniqueHeaders.indexOf('Required') !== -1){
      uniqueHeaders.splice(uniqueHeaders.indexOf('Required'), 1);
      uniqueHeaders.unshift('Required');
    }
    return uniqueHeaders;
  }
);

export const groupedColumnsSelector = createSelector(
  uniqueColumnsSelector,
  metaDataSelector,
  selectedModelView,
  (uniqueColumns, allColumns, currentView) => {
    const sortedColumns = allColumns.sort((a, b) => {
      return a.headerName.localeCompare(b.headerName);
    });
    const groupedColumns = uniqueColumns.map(uniqueColumn => {
      const uniqueColumnObj = {category: uniqueColumn, headers: []};
      sortedColumns.forEach(column => {
        if(column.category === uniqueColumn) {
          uniqueColumnObj.headers.push(column);
        }
      });
      return uniqueColumnObj;
    });
    const currentViewFields = [];
    currentView && currentView.columns && currentView.columns.forEach(column => {
      if(!column.hide){
        currentViewFields.push(column.field);
      }
    }
    );
    return groupedColumns.map(group => {
      const groupCopy = {...group};
      const updatedGroup = groupCopy.headers.map(header => {
        const headerCopy = {...header};
        headerCopy.isChecked = currentViewFields.indexOf(headerCopy.field) > -1;
        return headerCopy;
      });
      groupCopy.headers = updatedGroup;
      return groupCopy;
    });

  }
);


export const preDefinedSortingState = createSelector(
  getCurrentGridView,
  (currentViewColumns) => {
    return currentViewColumns.filter((column) => (column.sort === SORT.ASC || column.sort === SORT.DESC)) || [];
  }
);

export const noRowDataSelector = createSelector(
  getGridData,
  isLoading,
  (data, loading) => {
    return (data.length || loading) ? '' : t('tkNoFundFinderDataMsg');
  }
);

// to handle snackbar error
// don't add any extra selectors here, only for error
export const snackbarErrorList = createSelector(
  labelSelector, getErrorObject, (labels, errorObject) => {

    const list = [];
    /**
     * if SHOW_ERROR_ON.SNACKBAR is true errorObject.errorCode will always be UNKNOWN_SERVICE_ERROR
     * because we are setting it from errorHandler
     * */
    if(errorObject.showErrorOn === SHOW_ERROR_ON.SNACKBAR && (errorObject.errorCode === errorTypes.UNKNOWN_SERVICE_ERROR)){
      const message = errorObject.errorMessage && labels[errorObject.errorMessage] ||  labels[errorMessages[errorObject.errorCode].tkBody];
      list.push({displayMultiple:true, id: new Date().toLocaleString(), type:'error', message});
    }
    return list;
  }
);

export const snackbarDataList = createSelector(
  gridDataErrorCode, labelSelector, getGridData, getErrorObject, isTradingDisabledSelector, isTradeSaveFailed,
  (errorCode, labels, data, errorObject, isTradingDisabled, isTradeSaveFailed) => {

    const list = [];
    const dataLength = data && data.length;

    if( dataLength > labels.tkMaxDataRowLowLimit){
      list.push({displayMultiple:true, type:'warning', message:labels.tkCopyPort02});
    }

    if(isTradingDisabled){
      list.push({displayMultiple:true, type:'error', message:t('BLOTTER_SAVE_FAILED_MULTIPLE')});
    }

    if(isTradeSaveFailed){
      list.push({displayMultiple:true, id: new Date().toLocaleString(), type:'error', message: isTradeSaveFailed.msgCopy});
    }

    return list;
  }
);

export const snackbarList = createSelector(
  snackbarErrorList, appSnackbarDataList, snackbarDataList, (errors, globalMsgs, messages) => {
    return [...errors, ...globalMsgs, ...messages];
  }
);

export const filterByMappedData = createSelector (
  getCurrentGridView,
  getFilters,
  (currentView, filterBy) => {
    const coulmnsFilteredArray =[];
    filterBy.forEach((filteredColumn) => {
      const columnFound = currentView.find(column => filteredColumn.field === column.colId);
      if (columnFound){
        coulmnsFilteredArray.push({colId: columnFound.headerName, term: filteredColumn.term});
      }
    });
    return coulmnsFilteredArray;
  });

export const columnSortingState = createSelector(
  getCurrentGridView,
  (currentViewColumns) => {
    const sortedColumn = currentViewColumns.filter((column) => (column.sort === SORT.ASC || column.sort === SORT.DESC));
    if(sortedColumn.length > 1){
      const column = sortedColumn.filter(column => column.colId !== COLUMN_COLID.GROUP) || [{}];
      return column[0];
    }
    return sortedColumn[0] || {};
  }
);

export const sortBySelector = createSelector (
  getCurrentGridView,
  (currentView) => {
    const coulmnsSortedArray =[];
    if(currentView.length > 0) {
      currentView.forEach((column) => {
        if (column.sort === SORT.ASC || column.sort === SORT.ASC) {
          coulmnsSortedArray.push({columnId: column.headerName, sort: column.sort});
        }
      });
      return coulmnsSortedArray;
    }else{
      return [];
    }
  });

export const columnSortingStateMappedData = createSelector (
  getCurrentGridView,
  columnSortingState,
  sortBySelector,
  (currentView, sortByState, sortBy) => {
    const mappedSortColumn = cloneDeep(sortByState);
    if(currentView.length > 0 && Object.keys(mappedSortColumn).length > 0) {
      const column = currentView.find((column) => mappedSortColumn.colId === column.colId);
      if(column){
        mappedSortColumn.colId = column.headerName;
      }
      return [mappedSortColumn];
    }else{
      return sortBy;
    }
  });

const getFileTypeOptions = () => {
  const fileTypesOptions = [
    {
      id: FILE_DOWNLOAD_TYPES.EXCEL,
      label: t('tkXLS'),
      value: FILE_DOWNLOAD_TYPES.EXCEL,
      isSelected: true
    },
    {
      id: FILE_DOWNLOAD_TYPES.PDF,
      label: t('tkPDF'),
      value: FILE_DOWNLOAD_TYPES.PDF
    }];
  return fileTypesOptions;
};

export const getFileTypes = createSelector(getSelectedFileType, getFileTypeOptions,
  (selectedFile, options) => {
    return options.map((option) => {
      return {
        value: option.id,
        label: option.label,
        isSelected: selectedFile === option.id};
    });
  });

export const disableFileDownload = createSelector(
  getGridData,
  getFilteredDataLength,
  filterVisibility,
  getFilters,
  getFileDownloadSignaturesSelector,
  (data, filteredData, filterVisibility, filters = [], files) => {

    const fundFinderFiles = files && files.filter(file => {
      return file.origin ===  FILE_DOWNLOAD_ORIGIN.FUND_FINDER;
    }) || [];

    if(filterVisibility === PORTFOLIO_FILTER_STATE.HIDDEN){
      return !data.length || fundFinderFiles.length > 0;
    } else {
      return (filters.length ? !filteredData : !data.length) || fundFinderFiles.length > 0;
    }
  }
);
