import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@gs-ux-uitoolkit-react/modal';
import { Radio } from '@gs-ux-uitoolkit-react/radio';
import { Button } from '@gs-ux-uitoolkit-react/button';
import PropTypes from 'prop-types';
import { BOOLEAN } from '../../../../../../constants/pageConstants';
import translator from '../../../../../../services/translator';
import './index.scss';

const { translate: t } = translator;

export default function FundsRemovalWarningModal({ closeModal, fundsList, updateFunds }) {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = event => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = () => {
    if (selectedOption === BOOLEAN.YES) {
      const { id: fundId, fundGroupId, deletingFund } = fundsList[0] || {};
      if (deletingFund) {
        updateFunds(fundId, true);
      } else {
        updateFunds(fundGroupId, false);
      }
    }
    closeModal();
  };

  return (
    <Modal visible placement='center' className='fundsWarningModal gs-uitk-override-css gs-scrollbar'>
      <ModalHeader onDismissButtonClick={closeModal} className='fundsWarningModal__header'>
        {t('tkWarning')}
        <p className='fundsWarningModal__infoMsg'>{t('tkOrgFundsWarningModalMsg')}</p>
      </ModalHeader>
      <ModalBody className='fundsWarningModal__body'>
        {!!fundsList.length && (
          <ul>
            {fundsList.map(fund => (
              <li key={fund.id}>{fund.longName}</li>
            ))}
          </ul>
        )}
      </ModalBody>
      <ModalFooter className='fundsWarningModal__footer'>
        <span>{t('tkOrgFundsWarningModalActionMsg')}</span>
        <div className='fundsWarningModal__radio'>
          <Radio name='modalRadio' value={BOOLEAN.YES} inline checked={selectedOption === BOOLEAN.YES} onChange={handleOptionChange}>
            {t('tkYesFirstLetterCapital')}
          </Radio>
          <Radio name='modalRadio' value={BOOLEAN.NO} inline checked={selectedOption === BOOLEAN.NO} onChange={handleOptionChange}>
            {t('tkNoFirstLetterCapital')}
          </Radio>
        </div>
        <div className='fundsWarningModal__actionBtns'>
          <Button actionType='secondary' onClick={closeModal}>
            {t('tkCancelModal')}
          </Button>
          <Button actionType='primary' onClick={handleSubmit}>
            {t('tkOk')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

FundsRemovalWarningModal.propTypes = {
  closeModal: PropTypes.func,
  fundsList: PropTypes.array,
  updateFunds: PropTypes.func,
};
