import { ListGroupCssClasses } from './list-group-style-sheet';
import { ListGroupItemCssClasses } from './list-group-item-style-sheet';
import { ListGroupProps, ListGroupItemProps } from './list-group-props';
import { cx } from '@gs-ux-uitoolkit-common/style';

export interface ListGroupClassNameProps {
    cssClasses: ListGroupCssClasses;
    className?: ListGroupProps['className'];
    overrideClasses?: ListGroupProps['classes'];
}

export interface ListGroupItemClassNameProps {
    cssClasses: ListGroupItemCssClasses;
    className?: ListGroupItemProps['className'];
    action?: ListGroupItemProps['action'];
    active?: ListGroupItemProps['active'];
    disabled?: ListGroupItemProps['disabled'];
    overrideClasses?: ListGroupProps['classes'];
}

export function getListGroupClasses(props: ListGroupClassNameProps): string {
    const { cssClasses, className, overrideClasses } = props;
    return cx(cssClasses.root, className, overrideClasses && overrideClasses.root, 'gs-list-group');
}

export function getListGroupItemClasses(props: ListGroupItemClassNameProps): string {
    const { cssClasses, className, overrideClasses } = props;
    return cx(
        cssClasses.root,
        className,
        overrideClasses && overrideClasses.item,
        'gs-list-group__item'
    );
}
