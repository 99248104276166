import React, { useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AutoComplete from '../../../../../components/core/AutoComplete';
import { fetchUserSearchDetails, updateCurrentPage, showDetailsGrid } from '../../actions';
import { getPreviousSearchTerm } from '../../selectors';
import { setGlobalError } from '../../../../../actions/app';
import { addPageContext } from '../../../../../actions/page';
import { PAGES } from '../../reducers';
import { actionsCreators } from '../../../../../modules/Grid/actionsCreator';
import useFetch from '../../hooks/useFetch';
import endPointsMapper from '../../../../../configs/endPointsMapper';
import { dispatchAnalytics, searchResultSelected } from '../../analytics';
import { getSortedStringData } from '../../../../../utils/sorting';
import { SHOW_ERROR_ON, MODULES_KEYS } from '../../../../../constants/pageConstants';
import { USERS, FIRMS, ORGANIZATIONS } from '../../constants';
import translator from '../../../../../services/translator';

const { translate: t } = translator;

const { addGridData } = actionsCreators(MODULES_KEYS.USER_SEARCH);

const AutoCompleteDropDown = props => {
  const { data: autoComplete = {}, loading: isLoading, doFetch: fetchData, error } = useFetch(endPointsMapper.FETCH_USER_SEARCH);
  const { onError, onOptionSelection, previousSearchTerm } = props;
  const ref = useRef(null);
  useEffect(() => {
    if (error) {
      error.componentType = 'autocomplete';
      onError({ error, isPageLoaded: true, showErrorOn: SHOW_ERROR_ON.SNACKBAR, componentType: 'autocomplete' });
    }
  }, [error]);

  const updatedData = useMemo(() => {
    const getLabelArray = (type, data) => {
      if (type === FIRMS) {
        const tempLabel = [
          {
            name: data.name,
            isScGreyText: false,
          },
          {
            name: data.code,
            isScGreyText: false,
          },
        ];

        return tempLabel;
      } else if (type === USERS) {
        const tempLabel = [
          {
            name: `${data.firstName} ${data.lastName}`,
            isScGreyText: false,
          },
          {
            name: data.contactDetails.email,
            isScGreyText: true,
          },
        ];

        return tempLabel;
      } else if (type === ORGANIZATIONS) {
        const tempLabel = [
          {
            name: data.name,
            isScGreyText: false,
          },
        ];

        return tempLabel;
      }
    };

    const getLabel = (type, data) => {
      if (type === FIRMS) {
        return `${data.name}, ${data.code}`;
      } else if (type === USERS) {
        return `${data.firstName} ${data.lastName}, ${data.contactDetails.email}`;
      } else if (type === ORGANIZATIONS) {
        return data.name;
      }
    };

    if (!autoComplete) return [];

    const result = [];
    Object.keys(autoComplete).forEach(key => {
      if (autoComplete[key] && autoComplete[key].values && autoComplete[key].values.length) {
        const values = autoComplete[key].values.map(list => {
          return {
            ...list,
            openUserDetailsPage: key === USERS,
            openOrganizationDetailsPage: key === ORGANIZATIONS,
            isFirm: key === FIRMS,
            isUser: key === USERS,
            isOrganization: key === ORGANIZATIONS,
            label: getLabel(key, list),
            labels: getLabelArray(key, list),
          };
        });
        result.push({
          title: t(`tk${key}`),
          values: getSortedStringData(values, 'label', true),
        });
      }
    });
    return result;
  }, [autoComplete]);

  const onChange = (data, isFreeText) => {
    if (!isFreeText) {
      data.length >= 3 && fetchData({ searchString: data, searchGroups: ['user', 'firm', 'organization'], searchMaxResults: 5 });
    } else {
      props.onInputChange(data, isFreeText);
    }
  };

  const onSelection = data => {
    if (data.isUser || data.isOrganization) {
      ref.current && ref.current.setSearchTerm('');
    }
    onOptionSelection(data);
  };

  const propsData = {
    debounceTime: 150,
    minChar: 3,
    isLoading,
    options: updatedData,
    onInputChange: onChange,
    onOptionSelection: onSelection,
    defaultSearchTerm: previousSearchTerm,
    errorMessage: t('tkUsearSearchErrorMessage'),
    placeholderText: t('tkAdministratorDashboardPlaceholder'),
  };

  return <AutoComplete ref={ref} {...propsData} />;
};

const mapDispatchToProps = dispatch => ({
  onInputChange: (data, isFreeText) => {
    if (isFreeText) {
      dispatch(fetchUserSearchDetails({ data, isFreeText }));
      dispatch(showDetailsGrid(true));
      dispatch(addPageContext({ isUser: false, isOrganization: false }));
      dispatch(addGridData({ filterBy: [], filterModel: {}, gridData: { data: [] } }));
      dispatchAnalytics(dispatch, searchResultSelected(data, 'FreeText'));
    }
  },
  onOptionSelection: data => {
    if (data.openUserDetailsPage) {
      dispatch(updateCurrentPage(PAGES.DETAILS_PAGE));
      dispatch(addPageContext({ isUser: true, isOrganization: false, pageKey: USERS }));
      dispatchAnalytics(dispatch, searchResultSelected(data.label, 'User'));
    } else if (data.openOrganizationDetailsPage) {
      dispatch(updateCurrentPage(PAGES.DETAILS_PAGE));
      dispatch(addPageContext({ isUser: false, isOrganization: true, pageKey: ORGANIZATIONS }));
      dispatchAnalytics(dispatch, searchResultSelected(data.label, 'Organization'));
    } else {
      dispatch(showDetailsGrid(true));
      dispatch(addPageContext({ isUser: false, isOrganization: false }));
      dispatchAnalytics(dispatch, searchResultSelected(data.label, 'Firm'));
    }
    dispatch(fetchUserSearchDetails(data));
    dispatch(addGridData({ filterBy: [], filterModel: {}, gridData: { data: [] } }));
  },
  onError: error => {
    dispatch(setGlobalError(error));
  },
});

const mapStateToProps = state => ({
  previousSearchTerm: getPreviousSearchTerm(state),
});

AutoCompleteDropDown.propTypes = {
  onInputChange: PropTypes.func,
  onError: PropTypes.func,
  onOptionSelection: PropTypes.func,
  previousSearchTerm: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoCompleteDropDown);
