import { CSSProperties, TableHTMLAttributes, ReactNode, FunctionComponent, useEffect } from 'react';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import {
    TableProps as TableCommonProps,
    tableStyleSheet,
    defaultTableProps,
    getTableClassNames,
    getRootClassNames,
} from '@gs-ux-uitoolkit-common/table';
import { splitDataAttributes } from '@gs-ux-uitoolkit-react/shared';
import { componentAnalytics } from './analytics-tracking';

export interface TableProps
    extends TableCommonProps<CSSProperties>,
        Omit<TableHTMLAttributes<HTMLTableElement>, 'size'> {
    /**
     * Children to render in the table
     */
    children?: ReactNode;
}

/**
 * Tables are used to display simple tabular data.
 */
export const Table: FunctionComponent<TableProps> = ({
    className,
    classes: overrideClasses,
    children,
    bordered = defaultTableProps.bordered,
    cellAlignment = defaultTableProps.cellAlignment,
    density = defaultTableProps.density,
    emphasis = defaultTableProps.emphasis,
    hideRowSeparators = defaultTableProps.hideRowSeparators,
    highlightOnRowHover = defaultTableProps.highlightOnRowHover,
    showColumnSeparators = defaultTableProps.showColumnSeparators,
    size = defaultTableProps.size,
    striped = defaultTableProps.striped,
    style,
    id,
    title,
    ...other
}) => {
    const theme = useTheme();
    const cssClasses = useStyleSheet(tableStyleSheet, {
        theme,
        size,
        emphasis,
        density,
        cellAlignment,
        rowHover: highlightOnRowHover,
        striped,
        bordered,
        hideRowSeparators,
        showColumnSeparators,
    });
    const tableClasses = getTableClassNames({
        cssClasses,
        overrideClasses,
    });
    const rootClasses = getRootClassNames({
        className,
        cssClasses,
        overrideClasses,
    });
    const { split: dataAttributeProps, remaining: tableProps } = splitDataAttributes(other);

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'table' });
    }, []); // Only run once

    return (
        <span
            {...{ style, id, title, ...dataAttributeProps }}
            data-gs-uitk-component="table"
            data-cell-alignment={cellAlignment}
            data-density={density}
            data-emphasis={emphasis}
            data-size={size}
            className={rootClasses}
        >
            <table {...tableProps} className={tableClasses} data-cy="gs-uitk-table__table">
                {children}
            </table>
        </span>
    );
};
