import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@gs-ux-uitoolkit-react/button';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PageHeader from '../../../../../components/app/PageHeader';
import Snackbar from '../../../../../components/core/Snackbar/index';
import SnackbarList from './SnackbarList';
import MimicModal from '../MimicModal';
import { isMimicSessionSelector, labelSelector } from '../../../../../selectors/app';
import { autocompleteSnackbarList } from '../../selectors';
import Constants from '../../../../../constants/appConstants';
import { addPageContext } from '../../../../../actions/page';
import { updateCurrentPage } from '../../actions';
import { PAGES } from '../../reducers';
import { isNonProdEnvironment } from '../../../../../utils/dom';
import { CREATE_USER } from '../../constants';
import './index.scss';

const UserSearch = ({ snackbarItems = [], labels, onNewUserCreation, isMimicSessionActive }) => {
  const [showMimicModal, setShowMimicModal] = useState(false);

  const nonProdEnvironment = useMemo(() => isNonProdEnvironment(), []);

  const handleUserCreation = () => {
    if (isMimicSessionActive) {
      setShowMimicModal(true);
      return;
    }
    onNewUserCreation();
  };

  const closeMimicModal = () => {
    setShowMimicModal(false);
  };

  return (
    <div className='portfolio__header'>
      {isMimicSessionActive && showMimicModal && (
        <MimicModal closeModal={closeMimicModal} modalMsg={'tkMimicCreateNewUserMsg'} showCancelBtn={false} handleMimicOkBtnClick={closeMimicModal} />
      )}
      <SnackbarList />
      <TransitionGroup component={null}>
        {snackbarItems.map((data, idx) => (
          <CSSTransition key={idx} classNames='snackbar__transition' timeout={Constants.CSS_DURATION_HORSE}>
            <Snackbar labels={labels} {...data} />
          </CSSTransition>
        ))}
      </TransitionGroup>
      <div className='header-container'>
        <div className='header-container__left'>
          {PageHeader({
            title: labels.tkAdministratorDashboard,
            description: labels.tkAdministratorDashboardDescription || '--',
          })}
        </div>
        {nonProdEnvironment && (
          <Button actionType='primary' onClick={handleUserCreation}>
            <Icon name='person-add' type='filled' />
            <span className='gs-btn'>{labels.tkSetupNewUser}</span>
          </Button>
        )}
      </div>
    </div>
  );
};

UserSearch.propTypes = {
  labels: PropTypes.object,
  snackbarItems: PropTypes.array,
  autocomplete: PropTypes.array,
  onNewUserCreation: PropTypes.func,
  isMimicSessionActive: PropTypes.bool,
};

const mapStateToProps = state => ({
  labels: labelSelector(state),
  snackbarItems: autocompleteSnackbarList(state),
  isMimicSessionActive: isMimicSessionSelector(state),
});

const mapDispatchToProps = dispatch => ({
  onNewUserCreation: () => {
    dispatch(updateCurrentPage(PAGES.DETAILS_PAGE));
    dispatch(addPageContext({ isUser: true, isOrganization: false, pageKey: CREATE_USER }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSearch);
