import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { RowProps } from './row-props';
import { breakpoints as defaultBreakpoints } from './breakpoints';
import { grid as defaultGrids } from './grid';
import { defaultContainerProps } from './container-props';
import { DeepReadonly } from 'ts-essentials';
import './row-theme-overrides';

export interface RowStyleSheetProps {
    theme: Theme;
    breakpoints?: RowProps['breakpoints'];
    grid?: RowProps['grid'];
    density?: RowProps['density'];
    noGutters?: RowProps['noGutters'];
}

export interface RowCssClasses {
    root: string;
}

export type RowStyledClasses = CssClassDefinitionsObject<keyof RowCssClasses>;

export interface RowStyleOverridesParams {
    props: DeepReadonly<RowStyleSheetProps>;
    createDefaultStyledClasses: () => RowStyledClasses;
}

export const rowStyleSheet = new StyleSheet('row', (props: RowStyleSheetProps) => {
    return createComponentClassDefinitions<RowStyleSheetProps, RowStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.row
    );
});

function createDefaultStyledClasses({
    breakpoints = defaultBreakpoints,
    density = defaultContainerProps.density,
    noGutters = defaultContainerProps.noGutters,
    grid: customGrid,
}: RowStyleSheetProps): RowStyledClasses {
    const grid = customGrid || defaultGrids[density];
    return {
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            boxSizing: 'border-box',

            '& > *': {
                boxSizing: 'border-box',
            },

            [`@media (min-width:${breakpoints.xs.minWidth})`]: {
                marginLeft: noGutters ? 0 : `${grid.xs.gutter * -0.5}px`,
                marginRight: noGutters ? 0 : `${grid.xs.gutter * -0.5}px`,
            },
            [`@media (min-width:${breakpoints.sm.minWidth}px)`]: {
                marginLeft: noGutters ? 0 : `${grid.sm.gutter * -0.5}px`,
                marginRight: noGutters ? 0 : `${grid.sm.gutter * -0.5}px`,
            },
            [`@media (min-width:${breakpoints.md.minWidth}px)`]: {
                marginLeft: noGutters ? 0 : `${grid.md.gutter * -0.5}px`,
                marginRight: noGutters ? 0 : `${grid.md.gutter * -0.5}px`,
            },
            [`@media (min-width:${breakpoints.lg.minWidth}px)`]: {
                marginLeft: noGutters ? 0 : `${grid.lg.gutter * -0.5}px`,
                marginRight: noGutters ? 0 : `${grid.lg.gutter * -0.5}px`,
            },
            [`@media (min-width:${breakpoints.xl.minWidth}px)`]: {
                marginLeft: noGutters ? 0 : `${grid.lg.gutter * -0.5}px`,
                marginRight: noGutters ? 0 : `${grid.lg.gutter * -0.5}px`,
            },
        },
    };
}
