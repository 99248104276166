import { ButtonCommonProps } from '@gs-ux-uitoolkit-common/button';
import { FormSize } from '@gs-ux-uitoolkit-common/form';
import { IconBaseProps } from '@gs-ux-uitoolkit-common/icon-font';
import { cx, StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './file-upload-area-theme-overrides';

export interface FileUploadAreaStyleSheetProps {
    theme: Theme;
    disabled?: boolean;
    size: FormSize;
}

export interface FileUploadAreaClasses {
    root: string;
    rootDrop: string;
    divider: string;
    primaryText: string;
    supportiveText: string;
    input: string;
    icon: string;
}

export type FileUploadAreaStyledClasses = CssClassDefinitionsObject<keyof FileUploadAreaClasses>;

export interface FileUploadAreaStyleOverridesParams {
    props: DeepReadonly<FileUploadAreaStyleSheetProps>;
    createDefaultStyledClasses: () => FileUploadAreaStyledClasses;
}

export const fileUploadAreaStyleSheet = new StyleSheet(
    'file-upload-area',
    (props: FileUploadAreaStyleSheetProps) => {
        return createComponentClassDefinitions<
            FileUploadAreaStyleSheetProps,
            FileUploadAreaStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.fileUploadArea);
    }
);

export type FileUploadAreaOverrideClasses = Partial<
    Omit<FileUploadAreaClasses, 'input' | 'icon'> & {
        iconClasses: IconBaseProps['classes'];
        buttonClasses: ButtonCommonProps['classes'];
    }
>;

export interface GetFileUploadAreaClassesProps {
    classes: FileUploadAreaClasses;
    overrideClasses?: FileUploadAreaOverrideClasses;
    isHighlighted: boolean;
}

export const getFileUploadAreaClasses = ({
    classes,
    overrideClasses,
    isHighlighted,
}: GetFileUploadAreaClassesProps) => {
    const rootClass = cx(
        classes.root,
        overrideClasses?.root,
        isHighlighted && classes.rootDrop,
        classes && overrideClasses?.rootDrop
    );

    const inputClass = classes.input;

    const iconClasses: IconBaseProps['classes'] = {
        ...overrideClasses?.iconClasses,
        root: cx(classes.icon, overrideClasses?.iconClasses?.root),
    };

    const primaryTextClass = cx(classes.primaryText, overrideClasses?.primaryText);
    const dividerClass = cx(classes.divider, overrideClasses?.divider);

    const buttonClasses = overrideClasses?.buttonClasses;

    const supportiveTextClass = cx(classes.supportiveText, overrideClasses?.supportiveText);

    return {
        rootClass,
        inputClass,
        iconClasses,
        primaryTextClass,
        dividerClass,
        buttonClasses,
        supportiveTextClass,
    };
};

function createDefaultStyledClasses({
    theme,
    disabled,
    size = 'md',
}: FileUploadAreaStyleSheetProps): FileUploadAreaStyledClasses {
    const magnetismSpace = { sm: 8, md: 12, lg: 16 }[size];
    const spacing = { sm: 16, md: 24, lg: 32 }[size];

    return {
        root: {
            display: 'flex',
            cursor: 'default',
            userSelect: 'none',
            flexDirection: 'column',
            padding: spacing,
            gap: spacing,
            alignItems: 'center',
            border: `1px dashed ${theme.border.input}`,
            background: theme.surface.secondary,
            transition: 'border 0.2s',
            opacity: disabled ? 0.3 : 1,
            position: 'relative',

            '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: -magnetismSpace,
                left: -magnetismSpace,
                width: `calc(100% + ${magnetismSpace * 2}px)`,
                height: `calc(100% + ${magnetismSpace * 2}px)`,
                backgroundColor: 'transparent',
            },
        },
        rootDrop: {
            cursor: 'copy',
            border: `1px dashed ${theme.colorScheme.primary}`,
            background: theme.getSurfaceInteractionShades('secondary').hover,
        },
        icon: {
            color: theme.text.tertiary,
            fontSize: { sm: 32, md: 40, lg: 48 }[size],
            marginBottom: { sm: -8, md: -16, lg: -8 }[size],
        },
        divider: {
            position: 'relative',
            height: '1.3rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            '& > .text': {
                color: theme.text.secondary,
                position: 'absolute',
                padding: '0 16px',
            },

            '& > .line': {
                display: 'flex',
                gap: '2rem',

                '&::before': {
                    background: theme.text.tertiary,
                    content: '""',
                    display: 'block',
                    height: 1,
                    width: 100,
                },

                '&::after': {
                    background: theme.text.tertiary,
                    content: '""',
                    display: 'block',
                    height: 1,
                    width: 100,
                },
            },
        },
        primaryText: {
            ...{
                sm: theme.typography.heading07,
                md: theme.typography.heading06,
                lg: theme.typography.heading05,
            }[size],
            color: theme.text.secondary,
        },
        supportiveText: {
            ...{
                sm: theme.typography.body05,
                md: theme.typography.body03,
                lg: theme.typography.body02,
            }[size],
            color: theme.text.secondary,
        },
        input: {
            display: 'none',
        },
    };
}
