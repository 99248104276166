import appEndPointMapper from './app';
import fundFinderEndPointMapper from './fundFinder';
import fundTrackerEndPointMapper from './fundTracker';
import messageCenterEndPointMapper from './messageCenter';
import portfolioEndPointMapper from './portfolio';
import tradeblotterEndPointMapper from './tradeblotter';
import userEndPointMapper from './user';
import tradeActivity from './tradeActivity';
import importTrades from './importTrades';
import investmentPolicy from './investmentPolicy';
import reports from './reports';
import userMaintenance from './userMaintenance';
import mimic from './mimic';
import accountFact from './accountFact';
import fundFact from './fundFact'

export default {
  ...appEndPointMapper,
  ...fundFinderEndPointMapper,
  ...fundTrackerEndPointMapper,
  ...messageCenterEndPointMapper,
  ...portfolioEndPointMapper,
  ...tradeblotterEndPointMapper,
  ...userEndPointMapper,
  ...tradeActivity,
  ...investmentPolicy,
  ...importTrades,
  ...reports,
  ...userMaintenance,
  ...mimic,
  ...accountFact,
  ...fundFact
}
