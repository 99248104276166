import { Component } from 'react';
import { connect } from 'react-redux';
import * as Redux from 'redux';
import {
    QuickFilter,
    RemoveQuickFilter,
    ToggleEnabledQuickFilter,
    genericExpressionHelper,
    invariantQuery,
} from '@gs-ux-uitoolkit-common/datacore';
import { Text } from '@gs-ux-uitoolkit-react/text';
import { Checkbox } from '@gs-ux-uitoolkit-react/checkbox';
import { ButtonGroup } from '../../../components/buttons/buttons-group';
import { GridColumn } from '../../../grid-wrappers/grid-wrapper';
import { ManagementTable, TableHeadings } from '../../../components/table/management-table';
export interface QuickFilterListTableProps {
    /**
     * Data for the Quickfilter
     */
    data: QuickFilter | null;
    /**
     * Create a new quickfilter callback
     * */
    createQuickFilterCallback: () => void;
    /**
     * list of quickfilters element
     */
    sortedQuickFilter: QuickFilter[];
    /**
     * Edit the Item
     */
    editConfigItem: (configItem: QuickFilter) => void;
    /**
     * Delete the config item from the store
     */
    removeConfigItem: (configItem: QuickFilter) => void;
    /**
     * to toggle the enabled state for the quickfilter items
     */
    toggleEnabledQuickFilter: (quickFilter: QuickFilter) => void;
    /**
     * Columns from the grid
     */
    columns: GridColumn[];
}
export class QuickFilterListTableComponent extends Component<QuickFilterListTableProps> {
    public render() {
        const quickfilterHeaders: TableHeadings[] = [
            {
                label: 'On/Off',
                props: {
                    className: 'enable-filter',
                },
            },
            {
                label: 'Name',
                props: {
                    className: 'column-name-column',
                },
            },
            {
                label: 'Expression',
                props: {
                    className: 'list-column',
                },
            },
            {
                label: '',
                props: {
                    className: 'action',
                },
            },
        ];
        const quickFilterElements = this.props.sortedQuickFilter.map(quickFilter => {
            const editQuickFilter = () => {
                this.props.editConfigItem(quickFilter);
            };
            const removeQuickFilter = () => this.props.removeConfigItem(quickFilter);
            const toggleEnabledQuickFilter = () => this.props.toggleEnabledQuickFilter(quickFilter);

            return (
                <tr key={quickFilter.name}>
                    <td>
                        <Checkbox
                            key={`checkbox-${quickFilter.name}`}
                            defaultChecked={quickFilter.isEnabled}
                            onChange={toggleEnabledQuickFilter}
                        />
                    </td>
                    <td>
                        {quickFilter.name}{' '}
                        {quickFilter.secondaryLabel ? (
                            <div>
                                <Text typography="body03">{quickFilter.secondaryLabel}</Text>
                            </div>
                        ) : null}
                    </td>
                    <td style={{ wordWrap: 'normal' }}>
                        {genericExpressionHelper.toString(
                            quickFilter.expression?.query ?? invariantQuery,
                            this.props.columns.map(column => ({
                                columnId: column.columnId,
                                columnLabel: column.columnLabel,
                                pivotKeys: column.pivotKeys,
                                primaryColumnId: column.primaryColumnId || '',
                            })),
                            true
                        )}
                    </td>
                    <td>
                        <ButtonGroup
                            editClick={editQuickFilter}
                            deleteClick={removeQuickFilter}
                            hideEdit={quickFilter.isReadOnly || quickFilter.isComputedExternally}
                            hideDelete={quickFilter.isReadOnly || quickFilter.isComputedExternally}
                        />
                    </td>
                </tr>
            );
        });
        return (
            <ManagementTable
                headingConfig={quickfilterHeaders}
                bodyRowElements={quickFilterElements}
            />
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch: (action: Redux.Action) => void) => {
    return {
        removeConfigItem: (quickFilter: QuickFilter) => dispatch(RemoveQuickFilter(quickFilter)),
        toggleEnabledQuickFilter: (quickFilter: QuickFilter) => {
            dispatch(ToggleEnabledQuickFilter(quickFilter));
        },
    };
};
export const QuickFilterListTable = connect(
    mapStateToProps,
    mapDispatchToProps
)(QuickFilterListTableComponent);
