import { fontFamily } from './typography';

/**
 * Fallback fonts for GoldmanSans. Includes sans serif fonts
 */
const goldmanSansFallback = ['Helvetica', 'Arial', 'sans-serif'];

/**
 * Fallback fonts for Basis. Includes sans serif fonts
 */
const basisFallback = ['Helvetica', 'Arial'];

/**
 * Fallback fonts for Sabon. Includes serif fonts
 */
const sabonFallback = ['Georgia', 'Times New Roman', 'serif'];

/**
 * Fallback fonts for Roboto Mono. Includes monospace fonts
 */
const monospaceFallback = ['Courier New', 'monospace'];

/**
 * For valid CSS font family definitions font families with a space should be quoted
 * with double-quotes. This method checks if there's a space in the name and returns
 * a valid quoted string
 */
function wrapWithQuotes(font: string): string {
    return font.includes(' ') ? `"${font}"` : font;
}

/**
 * Font family with fallback fonts for GoldmanSans
 */
export function withGoldmanSansFallback(font: string): string {
    return [font, ...goldmanSansFallback].map(wrapWithQuotes).join(',');
}

/**
 * Font family with fallback fonts for GoldmanSans Variable
 * since not all browsers support variable fonts
 */
export function withGoldmanSansVariableFallback(font: string): string {
    return [font, fontFamily.goldmanSans, ...goldmanSansFallback].map(wrapWithQuotes).join(',');
}

/**
 * Font family with fallback fonts for Basis
 */
export function withBasisFallback(font: string): string {
    return [font, ...basisFallback].map(wrapWithQuotes).join(',');
}

/**
 * Font family with fallback fonts for Sabon
 */
export function withSabonFallback(font: string): string {
    return [font, ...sabonFallback].map(wrapWithQuotes).join(',');
}

/**
 * Font family with fallback fonts for monospace fonts
 */
export function withMonospaceFallback(font: string): string {
    return [font, ...monospaceFallback].map(wrapWithQuotes).join(',');
}
