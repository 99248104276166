/**
 * fn will replace values matching the regex pattern with the replacement string
 * it also ensures that there are no consecutive spaces in the formatted string
 */
export const applyPatternFormatter = (
  str,
  pattern = /[^a-zA-Z0-9]/g,
  replacement = ' '
) => {
  if (!str || typeof str !== 'string' || typeof replacement !== 'string') {
    return '';
  }
  const formattedValue = str.replace(pattern, replacement);
  return formattedValue.replace(/\s+/g, ' ').trim();
};
