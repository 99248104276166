import { ComponentProps, CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { FormSize } from './form-types';

export interface FormTextProps<StyleType = CommonStyleType> extends ComponentProps<StyleType> {
    /**
     * Sets font size and other styling appropriate for the size.
     */
    size?: FormSize;

    /**
     * Whether or not the text appears with disabled styling
     */
    disabled?: boolean;
}

export const defaultFormTextProps: FormTextProps = {
    size: 'md',
    disabled: false,
};
