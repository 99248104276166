import {
    StyleSheet,
    CssProperties,
    CssClassDefinitionsObject,
} from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { InputSize, InputStatus } from './types';
import { DeepReadonly } from 'ts-essentials';
import './input-icon-theme-overrides';

export type SpecialIcon = 'search' | 'cancel';

export interface InputIconStyleSheetProps {
    theme: Theme;
    size: InputSize;
    status: InputStatus;
    disabled: boolean;
    position: 'leading' | 'trailing';
    isTextarea: boolean;
    color?: CssProperties['color'];

    // Some icons in the context of an Input need to be treated uniquely.
    special?: SpecialIcon;
}

export interface InputIconCssClasses {
    root: string;
}

export type InputIconStyledClasses = CssClassDefinitionsObject<keyof InputIconCssClasses>;

export interface InputIconStyleOverridesParams {
    props: DeepReadonly<InputIconStyleSheetProps>;
    createDefaultStyledClasses: () => InputIconStyledClasses;
}

export const inputIconStyleSheet = new StyleSheet(
    'input-icon',
    (props: InputIconStyleSheetProps) => {
        return createComponentClassDefinitions<InputIconStyleSheetProps, InputIconStyledClasses>(
            props,
            createDefaultStyledClasses,
            props.theme.styleOverrides?.inputIcon
        );
    }
);

export const globalInputIconClass = 'gs-input-icon';

const defaultInputIconTheme: { [key: string]: any } = {
    'font-size-sm': '14px',
    'font-size-md': '18px',
    'font-size-lg': '20px',

    'font-size-sm-search': '15px',
    'font-size-md-search': '19px',
    'font-size-lg-search': '23px',

    'font-size-sm-cancel': '14px',
    'font-size-md-cancel': '18px',
    'font-size-lg-cancel': '20px',

    'margin-sm': '7px',
    'margin-md': '8px',
    'margin-lg': '10px',
};

function getColor(
    theme: Theme,
    status?: InputStatus,
    color?: CssProperties['color']
): CssProperties['color'] {
    if (status && status !== 'none') {
        let statusColor = theme.status[status].bold;
        // Inputs & form feedback use the 'warningAlt' treatment as the default warning color,
        // yellow, is very light for borders and fails AA contrast of 3:1 for UI elements
        if (status === 'warning') {
            statusColor = theme.status.warningAlt.bold;
        }
        return statusColor;
    } else if (color) {
        return color;
    } else {
        return theme.text.primary;
    }
}

function getMargin(
    position: InputIconStyleSheetProps['position'],
    size: InputIconStyleSheetProps['size']
) {
    const margin = defaultInputIconTheme[`margin-${size}`];
    if (position === 'leading') {
        return `0px 0 0 ${margin}`;
    } else if (position === 'trailing') {
        return `0px ${margin} 0 0`;
    }
    return 0;
}

function getFontSize(size: InputSize, special?: SpecialIcon) {
    const specialStr = special ? `-${special}` : '';
    return defaultInputIconTheme[`font-size-${size}${specialStr}`];
}

function getTextareaProps() {
    return {
        position: 'absolute',
        right: 0,
        top: '9px',
    };
}

export function getInputIconStyleSheet({
    theme,
    status,
    color,
    position,
    size = 'md',
    isTextarea = false,
    special,
}: InputIconStyleSheetProps): { root: any } {
    return {
        root: {
            color: getColor(theme, status, color),
            margin: getMargin(position, size),
            '& [data-cy=gs-uitk-icon__content]': {
                fontSize: getFontSize(size, special),
                // transform rotate hack below - circular icons such as status are
                // blurry on Windows:  https://github.com/google/material-design-icons/issues/648
                transform: 'rotate(0.03deg)',
            },
            ...(isTextarea ? getTextareaProps() : {}),
        },
    };
}

function createDefaultStyledClasses(props: InputIconStyleSheetProps): InputIconStyledClasses {
    return getInputIconStyleSheet(props);
}
