import { StyleSheet } from '@gs-ux-uitoolkit-react/style';
export const basicExpressionClassNamePrefix = 'gs-uitk-quickfilter__basic-expression-builder';
export const basicExpressionStyleSheet = new StyleSheet('quickfilter-basic-expression', () => {
    const horizontalMargin = '12px';

    const commonSelectStyles = {
        marginRight: horizontalMargin,
        marginBottom: '24px',
    };
    return {
        root: {
            [`.${basicExpressionClassNamePrefix}__expression-container`]: {
                position: 'relative',
                display: 'flex',
                width: '100%',

                [`.${basicExpressionClassNamePrefix}__column-selector`]: {
                    ...commonSelectStyles,
                    width: '100%',
                    flex: 1,
                },
                [`.${basicExpressionClassNamePrefix}__operator-selector`]: {
                    ...commonSelectStyles,

                    minWidth: '135px',
                    flex: 1,
                },
                [`.${basicExpressionClassNamePrefix}__value-selector`]: {
                    ...commonSelectStyles,
                    width: '188px',
                    maxWidth: '188px',
                    minWidth: '188px',
                    '.gs-uitk-select-multiple__list': {
                        maxHeight: '150px',
                    },
                },
                '.gs-loading-icon': {
                    width: '188px',
                },
            },
            ['[data-cy="gs-uitk-basic-expression-add-rule"] > span']: {
                marginBottom: '16px',
            },
            [`@media (max-width: 998px)`]: {
                [`.${basicExpressionClassNamePrefix}__expression-container`]: {
                    flexDirection: 'column',
                    marginBottom: '16px',
                    '.gs-uitk-column-selector-container': {
                        width: '100% !important',
                    },
                    [`.${basicExpressionClassNamePrefix}__column-selector`]: {
                        maxWidth: 'calc(100% - 36px)',
                        marginRight: '0',
                        marginBottom: '16px',
                    },
                    [`.${basicExpressionClassNamePrefix}__operator-selector`]: {
                        maxWidth: 'calc(100% - 36px)',
                        marginRight: '0',
                        width: '100%',
                        marginBottom: '16px',
                    },
                    [`.${basicExpressionClassNamePrefix}__value-selector`]: {
                        maxWidth: 'calc(100% - 36px)',
                        marginRight: '0',
                        marginBottom: '16px',
                        width: '100%',
                        '.gs-input': {
                            width: '100%',
                        },
                    },
                    [`.${basicExpressionClassNamePrefix}__delete`]: {
                        position: 'absolute',
                        right: 0,
                    },
                },
            },
        },
    };
});
