import Constants from '../constants/appConstants';

export const inputDOMEnforcer = (dom = {}) => {
  return {
    ...dom,
    maxLength: dom.maxLength || 1 // if not provided force to 1.
  };
};
export const getWindow = () => window;

export const setLocationHref = (path) => {
  getWindow().location.href = path;
};

export const toggleBrowserScrollVisiblilty = (isHidden) => {
  const html = document.getElementsByTagName('html')[0];
  if(isHidden){
    html.style.overflowY = 'hidden';
  }else{
    html.style.overflowY = 'auto';
  }
};

// Scroll page to the bottom.
export const scrollToBottom = () => {
  const {body} = document;
  window.scrollTo(0, body.scrollHeight);
};

export const getViewportResolution = () => {
  const w = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName('body')[0];
  const x = w.innerWidth || e.clientWidth || g.clientWidth;
  const y = w.innerHeight || e.clientHeight || g.clientHeight;
  return {width: x, height: y};
};

export const isChrome = () => {
  return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
};

export const getFormActionAndElements = (htmlContent) => {
  try {
    if (htmlContent.includes('html')) {
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(htmlContent, 'text/html');
      const htmlFormElement = htmlDoc.querySelector('form');
      const htmlInputElements = htmlDoc.getElementsByTagName('input');
      const action = htmlFormElement.getAttribute('action');
      return {action, elements: htmlInputElements};
    }
  } catch (err) {
    console.log(`Error occurred => ${err}`);
  }
  return {};
};

export const handleClearWaterSSOResponse = (response) => {
  const {action, elements} = getFormActionAndElements(response);
  const form = document.createElement('form');
  form.setAttribute('method', 'POST');
  form.setAttribute('action', action);
  form.setAttribute('target', '_blank');
  form.setAttribute('enctype', 'application/x-www-form-urlencoded');

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    if (element.getAttribute('type') === 'hidden') {
      const hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', element.getAttribute('name'));
      hiddenField.setAttribute('value', element.getAttribute('value'));
      form.appendChild(hiddenField);
    }
  }
  document.body.appendChild(form);
  form.submit();
};


export const environmentSelector = () => {
  return document.body.getAttribute('data-sys-env') || '';
};

export const isNonProdEnvironment = () => {
  return environmentSelector().toLowerCase() !== Constants.PROD.toLowerCase();
};

export function getPosition(e) {
  const ract = e && e.getBoundingClientRect();
  return ract && {left: ract.left, top: ract.top + window.pageYOffset}  || {left: 0, top: 0};
}

