import { CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { StyleSheet, CssClasses } from '@gs-ux-uitoolkit-common/style';
import { BadgeOverlayProps } from './badge-overlay-props';

export type BadgeOverlayCssClasses = CssClasses<typeof badgeOverlayStyleSheet>;

// only supports size 'sm' for first cut
const placementPosition = {
    sm: {
        'top-right': {
            right: '-16px',
            top: '-8px',
        },
        'bottom-right': {
            right: '-16px',
            bottom: '-6px',
        },
        'bottom-left': {
            left: '-16px',
            bottom: '-6px',
        },
        'top-left': {
            left: '-16px',
            top: '-8px',
        },
    },
};

export const badgeOverlayStyleSheet = new StyleSheet(
    'badge-overlay',
    (props: BadgeOverlayProps<CommonStyleType>) => {
        let { size } = props;
        if (size !== 'sm') {
            console.warn(
                `Using overlay position values for size 'sm'.  Values for other sizes are coming soon...`
            );
            size = 'sm';
        }
        const { placement = 'top-right' } = props;

        return {
            root: {
                position: 'absolute',
                ...placementPosition[size][placement],
            },
        };
    }
);
