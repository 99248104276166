import React, { useState, useRef, Fragment, useEffect } from 'react';
import { FixedSizeList } from 'react-window';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@gs-ux-uitoolkit-react/modal';
import { Checkbox } from '@gs-ux-uitoolkit-react/checkbox';
import { Button } from '@gs-ux-uitoolkit-react/button';
import PropTypes from 'prop-types';
import useUserMaintenance from '../../../context';
import useFetch from '../../../hooks/useFetch';
import useSnackbar from '../../../hooks/useSnackbar';
import { ThreeSquaresLoader } from '../../../../../../components/core/Loaders';
import { Conditional } from '../../../../../../components/core/Conditional';
import endPointsMapper from '../../../../../../configs/endPointsMapper';
import { MAPPED_FIRMS_ACCOUNTS, MAP_UNMAP_FIRMS_LIST, UPDATE_FIRMS_AVAILABLE_TO_MAP_COUNT } from '../../../actions';
import translator from '../../../../../../services/translator';
import './index.scss';

const { translate: t } = translator;

export default function FirmOrganizationMappingModal({ firmsData, closeModal, loading }) {
  const {
    state: {
      organizationDetails: { organizationName },
      firmsAvailableToMapCount,
      mapUnmapFirmsList,
    },
    dispatch,
  } = useUserMaintenance();

  const { openSnackbar } = useSnackbar();

  const firmsToBeMapped = useRef(new Map());

  const [firmsList, setFirmsList] = useState([]);

  useEffect(() => {
    if (firmsData?.values.length) {
      const mappedFirms = mapUnmapFirmsList.filter(firm => firm.operationType === 'MAP').map(firm => firm.firmId);
      setFirmsList(
        [
          ...firmsData.values
            .map(({ displayValue }) => ({ ...displayValue, firmId: displayValue.id }))
            .filter(firm => !mappedFirms.includes(firm.firmId)),
          ...mapUnmapFirmsList.filter(firm => firm.operationType === 'UNMAP'),
        ].sort((a, b) => (a.firmName === b.firmName ? a.firmCode.localeCompare(b.firmCode) : a.firmName.localeCompare(b.firmName)))
      );
    }
  }, [firmsData, mapUnmapFirmsList]);

  const onAccountsTreeFetchSuccess = accountDetails => {
    dispatch({ type: MAPPED_FIRMS_ACCOUNTS, payload: accountDetails });
    dispatch({ type: UPDATE_FIRMS_AVAILABLE_TO_MAP_COUNT, payload: firmsAvailableToMapCount - firmsToBeMapped.current.size });
    dispatch({ type: MAP_UNMAP_FIRMS_LIST, payload: Array.from(firmsToBeMapped.current.values()) });
    if (firmsToBeMapped.current.size > 1) {
      openSnackbar({
        type: 'success',
        message: t('tkMultipleFirmOrgMappingSuccessMsg', firmsToBeMapped.current.size, organizationName),
      });
    } else {
      const [[, { firmName, firmCode }]] = firmsToBeMapped.current.entries();
      openSnackbar({
        type: 'success',
        message: t('tkFirmOrgMappingSuccessMsg', firmName, firmCode, organizationName),
      });
    }
    closeModal();
  };

  const onAccountsTreeFetchError = () => {
    if (firmsToBeMapped.current.size > 1) {
      openSnackbar({
        type: 'error',
        message: t('tkMultipleFirmOrgMappingErrorMsg', firmsToBeMapped.current.size, organizationName),
      });
    } else {
      const [[, { firmName, firmCode }]] = firmsToBeMapped.current.entries();
      openSnackbar({
        type: 'error',
        message: t('tkFirmOrgMappingErrorMsg', firmName, firmCode, organizationName),
      });
    }
    closeModal();
  };

  const { doFetch, loading: accountsLoading } = useFetch(
    endPointsMapper.FETCH_MAPPED_FIRMS_ACCOUNTS,
    onAccountsTreeFetchSuccess,
    onAccountsTreeFetchError
  );

  const handleMapping = () => {
    const payload = Array.from(firmsToBeMapped.current.keys());
    doFetch(payload);
  };

  const handleCheckboxChange = ({ checked, name }, firmId, firmName, firmCode) => {
    if (checked) {
      firmsToBeMapped.current.set(name, { firmId, firmName, firmCode, operationType: 'MAP' });
    } else {
      firmsToBeMapped.current.delete(name);
    }
    setFirmsList(prev => prev.map(firm => (firm.firmId === name ? { ...firm, isChecked: checked } : firm)));
  };

  return (
    <Modal visible placement='center' className='firmorg-map gs-uitk-override-css gs-scrollbar'>
      <Conditional condition={loading || accountsLoading}>
        <ModalBody className='firmorg-map__loadingBody'>
          <ThreeSquaresLoader />
        </ModalBody>
        <>
          <ModalHeader onDismissButtonClick={closeModal} className='firmorg-map__header'>
            {t('tkMapFirmToOrganizationModalHeader', organizationName)}
            {firmsList.length ? <p>{t('tkAvailableFirms')}</p> : null}
          </ModalHeader>
          <ModalBody>
            <div className={firmsList.length ? 'firmorg-map__firmsList' : 'firmorg-map__emptyList'}>
              <Conditional condition={firmsList.length}>
                <FixedSizeList itemData={firmsList} itemCount={firmsList.length} itemSize={25} height={295}>
                  {({ index, style, data }) => {
                    const { firmId, firmName, firmCode, isChecked = false } = data[index];
                    return (
                      <Checkbox
                        style={style}
                        checked={isChecked}
                        key={index}
                        name={firmId}
                        onChange={e => handleCheckboxChange(e, firmId, firmName, firmCode)}>
                        {firmName} ({firmCode})
                      </Checkbox>
                    );
                  }}
                </FixedSizeList>
                <div className='firmorg-map__noFirmsToMap'>{t('tkNoFirmsAvailableToMap')}</div>
              </Conditional>
            </div>
          </ModalBody>
          <ModalFooter>
            <Conditional condition={firmsList.length}>
              <Fragment>
                <Button actionType='secondary' onClick={closeModal}>
                  {t('tkModalCancelCTA')}
                </Button>
                <Button actionType='primary' onClick={handleMapping}>
                  {t('tkMap')}
                </Button>
              </Fragment>
              <Button actionType='primary' onClick={closeModal}>
                {t('tkOk')}
              </Button>
            </Conditional>
          </ModalFooter>
        </>
      </Conditional>
    </Modal>
  );
}

FirmOrganizationMappingModal.propTypes = {
  firmsData: PropTypes.object,
  closeModal: PropTypes.func,
  loading: PropTypes.bool,
};
