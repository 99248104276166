import React, { memo, useReducer, useCallback, useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { uniq, intersection, isEmpty } from 'lodash';
import cn from 'classnames';
import isArray from 'lodash/isArray';
import {ThreeSquaresLoader} from '../../../../components/core/Loaders';
import {
  additionalCriteriaFilterSelector, entitlementSelector,
  selectedNodeMapSelector, selectedFilterDataSelector,
  getSelectedTaAccountAndAccountIds, defaultAdditionalFiltersSelector,
  filterConfigSelector, isIncludeAdditionalFilterAsPayloadSelector,
  isAllSelectedForEntity
} from '../../../../selectors/pages/reports/individualReport';
import {
  setSelectedFilterData
} from '../../../../actions/app/reports';
import translator from '../../../../services/translator';
import EntityContainer from './FilterColumn/Entity';
import DropDownItem from './dropDownItem';
import Repeater from '../../../../components/core/Repeater';
import Button from '../../../../components/core/Button';
import { reducer, getInitialState } from './reducer';
import {additionalFiltersServiceFieldMapper} from '../../../../services/reports/common';

const { translate: t } = translator;

const getCommonIds = (filterCriteria, allIds, key) => {
  const filteredIds = filterCriteria.reduce((final, item) => {
    const items = item[key] || [];
    return [...final, ...items];
  }, []);
  return intersection(allIds, filteredIds);
};

const processFilter = (filter, entities, entityKey) => {
  if (entityKey) {
    return getCommonIds(filter, entities, entityKey);
  } else {
    return filter.reduce((final, {value}) => {
      const itemValue = isArray(value) ? [...value] : [value];
      return [...final, ...itemValue];
    }, []);
  }
};

const getShareclassValues = (showUnfundedShareclasses, filterState) => {
  const {
    shareclassValues,
    shareclassValuesWithoutAcct,
    selected: {
      shareclassSelected,
      shareclassSelectedWihtoutAcct
    },
  } = filterState;

  return {
    shareclassValues: showUnfundedShareclasses ? [...shareclassValues, ...shareclassValuesWithoutAcct] : [...shareclassValues],
    shareclassSelected: showUnfundedShareclasses ? {...shareclassSelected, ...shareclassSelectedWihtoutAcct} : {...shareclassSelected}
  }
};

const Filter = (props) => {
  const {
    additionalFilterDropDownItems, dispatchSelectedFilterData, showAdditionalFilterColumn,
    showFirmBranchColumn, showAccountColumn, showShareclassColumn, columnWidth,
    handleClose, filterData, selectedNodeMap, filterConfig: {additionalCriteria = {}},
    defaultAdditionalFilters, selectedFilterData: {additionalCriteria: {filters = {}} = {}} = {},
    isIncludeAdditionalFilterAsPayload, showUnfundedShareclasses
  } = props;

  const [filterState, dispatch] = useReducer(
    reducer,
    selectedNodeMap || getInitialState(filterData)
  );
  const selectedFilters = Object.entries(filters).reduce((newObj, [key, values = []]) => {
    newObj[key] = values.map(value => ({value}));
    return newObj;
  }, {});
  const filtersRef = useRef(selectedFilters || {});
  const actionRef = useRef({});
  const [loader, setLoader] = useState(false);
  const {
    firmBranchValues,
    taAccountValues,
    selected: {
      firmBranchSelected,
      taAccountsSelected,
    },
    stateUpdated
  } = filterState;

  const handleChangeAndSetActionRef = useCallback((action) => {
    actionRef.current = action;
    setLoader(true);
  }, []);

  useEffect(() => {
    /** Will be remove with React 18 updgrade by using useTransisition hook. */
    setTimeout(() => {
      if (loader && actionRef.current) {
        dispatch(actionRef.current);
        actionRef.current = {}
      }
    }, 1);
  }, [loader]);

  useEffect(() => {
    if (stateUpdated && stateUpdated.update) {
      actionRef.current = {};
      setLoader(false);
    }
  }, [stateUpdated])

  const isAllItem = (value) => (
    !isArray(value) && value.toLowerCase() === 'all'
  );

  const isAllDropdownValueSelected = useCallback((selectedItems = []) => (
    selectedItems.filter(({value = ''}) => isAllItem(value)).length > 0
  ), []);

  const getSelectedItems = (itemId, selectedItems) => (
    selectedItems && uniq(selectedItems.map(item => ({
      value: item.value,
      accountIds: item.accountIds,
      shareclassIds: item.shareclassIds,
      type: item.type,
      itemId
    })))
  );

  const initialFilterItem = (itemId) => {
    return additionalFilterDropDownItems
      .find(({itemId: id}) => (id === itemId)) || {};
  };

  const handleClick = (id, selectedItems = []) => {
    const itemId = additionalFiltersServiceFieldMapper[id] || id;
    // If 'All' option is selected
    if (isAllDropdownValueSelected(selectedItems)) {
      /* if default filter value found for the item (for specific report), then include
        "All" the items selected, instead of deleting the filter. This is to avoid
        sending the default filter value, incase user selects 'All' option */
      if (defaultAdditionalFilters[itemId]) {
        const {items: filterItems = []} = initialFilterItem(itemId);
        const allItems = filterItems.filter(({value}) => !isAllItem(value));
        filtersRef.current[itemId] = getSelectedItems(itemId, allItems);
      } else {
        delete filtersRef.current[itemId];
      }
    } else {
      filtersRef.current[itemId] = getSelectedItems(
        itemId,
        selectedItems
      );
    }
  };

  const addFilterValuesToList = (filterList, filter, key) => {
    const selectedValues = processFilter(filter);
    if (! isEmpty(selectedValues)) {
      filterList[key] = selectedValues;
    }
  };

  /* Filter item may have just 'value' but we also need other props,
     so grabbing it from master list */
  const getUpdatedFilterItem = (filter, key) => {
    const {items: initialFilterItems = []} = initialFilterItem(key);
    return filter.reduce((final, {value: currentFilterValue}) => {
      const item = initialFilterItems.find(({value}) => value === currentFilterValue);
      if (item) {
        final.push({
          value: item.value,
          accountIds: item.accountIds,
          shareclassIds: item.shareclassIds,
          type: item.type,
          key
        });
      }
      return final;
    }, []);
  };

  const onHandleApply = useCallback(() => {
    const {
      selected : {
        firmBranchSelected: {
          branchNodeMapSelected,
          firmNodeMapSelected
        },
        shareclassSelected,
        shareclassSelectedWihtoutAcct = {}
      },
      initial
    } = filterState;

    const selectedFirmIds = Object.keys(firmNodeMapSelected);
    const selectedBranchIds = Object.keys(branchNodeMapSelected).map(
      (branch) => branch.split('-')[0]
    );

    const {
      taAccountIds,
      accountIds
    } = getSelectedTaAccountAndAccountIds(filterState);
    const selectedShareclassIds = Object.keys(shareclassSelected);

    const { isAllFirmBranchSelected, isAllTaAccountSelected } = isAllSelectedForEntity(filterState);

    const value = {
      firmIds: selectedFirmIds,
      branchIds: selectedBranchIds,
      accountIds,
      shareclassIds: selectedShareclassIds,
      shareclassIdsWithoutAcct: Object.keys(shareclassSelectedWihtoutAcct),
      taAccountIds,
      initial,
      isAllFirmBranchSelected,
      isAllTaAccountSelected
    };
    let acAccounts = [...accountIds];
    let acShareclasses = [...selectedShareclassIds];
    const filters = {};
    if (filtersRef.current && Object.keys(filtersRef.current).length > 0) {
      Object.keys(filtersRef.current).forEach((key) => {
        const filter = filtersRef.current[key];
        switch(key) {
          case 'fundManagers':
          case 'currencies':
          case 'fundTypes':
          case 'reps': {
            if (isIncludeAdditionalFilterAsPayload) {
              const stitchAddnlFilterServiceField = additionalFiltersServiceFieldMapper[key];
              filters[stitchAddnlFilterServiceField] = processFilter(filter);
            } else {
              const updatedFilter = getUpdatedFilterItem(filter, key);
              acAccounts = processFilter(updatedFilter, acAccounts, 'accountIds');
              acShareclasses = processFilter(updatedFilter, acShareclasses, 'shareclassIds');
              addFilterValuesToList(filters, updatedFilter, key);
            }
            break;
          }
          case 'ZeroBalanceAccount':
          case 'midMonthlyDividend':
          case 'intradayNav':
          case 'holdingStatus':
          case 'holdingStatusForFb':
          case 'transactionState':
          case 'accountWithNoTransactions': {
            const filterKey = additionalFiltersServiceFieldMapper[key] || key;
            addFilterValuesToList(filters, filter, filterKey);
            break;
          }
          default: break;
        }
      });
    }
    // User selected filter + default filter values
    // TODO: Check this logic later
    const updatedFilters = additionalCriteria.reduce((obj, key) => {
      const filterKey = additionalFiltersServiceFieldMapper[key];
      if (filterKey) {
        const filterValue = filters[filterKey] || defaultAdditionalFilters[filterKey];
        if (filterValue) {
          obj[filterKey] = filterValue;
        }
      }
      return obj;
    }, {});

    // Update filtersRef
    Object.entries(updatedFilters).forEach(([key, values]) => {
      if (!filtersRef.current[key]) {
        filtersRef.current[key] = values.map(value => ({value}));
      }
    });

    const final = {
      ...value,
      additionalCriteria: {
        additionalFilters: filtersRef.current,
        accounts: acAccounts,
        shareclasses: acShareclasses,
        filters: updatedFilters
      }
    };
    dispatchSelectedFilterData({ filterData: final, nodeMap: filterState });
    handleClose();
  }, [filterState]);

  return (
    <div
      className={cn('filters-modal__body')}
    >
      {
        loader &&
        <div
          className="filters-modal__loader"
        >
          <ThreeSquaresLoader />
        </div>
      }
      <div className="filters__container">
        <div className="filters__row">
          { showFirmBranchColumn &&
            <div className="filters__column" style={{ width: `${columnWidth}%`}}>
              <EntityContainer
                entityMap={firmBranchValues}
                handleChange={handleChangeAndSetActionRef}
                selected={firmBranchSelected}
                placeholder={`${t('tkSearchFirmBranch')}`}
                entityType="firmBranch"
                label={`${t('tkFirm')} & ${t('tkBranches')}`}
              />
            </div>
          }
          {
            showAccountColumn &&
            <div className="filters__column" style={{ width: `${columnWidth}%`}}>
              <EntityContainer
                entityMap={taAccountValues}
                handleChange={handleChangeAndSetActionRef}
                selected={taAccountsSelected}
                placeholder={`${t('tkSearch')} ${t('tkAccounts')}`}
                entityType="taAccount"
                label={`${t('tkAccounts')}`}
              />
            </div>
          }
          {
            showShareclassColumn &&
            <div className="filters__column" style={{ width: `${columnWidth}%`}}>
              <EntityContainer
                entityMap={getShareclassValues(showUnfundedShareclasses, filterState).shareclassValues}
                handleChange={handleChangeAndSetActionRef}
                selected={getShareclassValues(showUnfundedShareclasses, filterState).shareclassSelected}
                placeholder={`${t('tkSearch')} ${t('tkFunds')}`}
                entityType="fund"
                label={`${t('tkFunds')}`}
              />
            </div>
          }
          {
            showAdditionalFilterColumn &&
            <div className="filters__column additional-filters" style={{ width: `${columnWidth}%`}}>
              <div className="header">
                <div className="title">{t('tkAdditionalCriteria')}</div>
              </div>
              <div className="filters__column__tree">
                <Repeater items={additionalFilterDropDownItems} props={{handleClick}}>
                  <DropDownItem />
                </Repeater>
              </div>
            </div>
          }
        </div>
      </div>
      <div className="filters-modal__footer">
        <Button
          clickHandler={handleClose}
          customClass="button-secondary-small"
          label={t('tkCancel')}
        />
        <Button
          clickHandler={onHandleApply}
          customClass="button-primary-small"
          label={t('tkOk')}
        />
      </div>
    </div>
  );
};

Filter.propTypes = {
  additionalFilterDropDownItems: PropTypes.object,
  showAdditionalFilterColumn: PropTypes.bool,
  showFirmBranchColumn: PropTypes.bool,
  showAccountColumn: PropTypes.bool,
  showShareclassColumn: PropTypes.bool,
  showUnfundedShareclasses: PropTypes.bool,
  columnWidth: PropTypes.number,
  handleClose: PropTypes.func,
  filterData: PropTypes.object,
  selectedFilterData: PropTypes.object,
  dispatchSelectedFilterData: PropTypes.func,
  selectedNodeMap: PropTypes.object,
  filterConfig: PropTypes.object,
  defaultAdditionalFilters: PropTypes.object,
  isIncludeAdditionalFilterAsPayload: PropTypes.string
};

const mapStateToProps = (state) => ({
  filterData: entitlementSelector(state),
  additionalFilterDropDownItems: additionalCriteriaFilterSelector(state),
  filterConfig: filterConfigSelector(state),
  defaultAdditionalFilters: defaultAdditionalFiltersSelector(state),
  selectedNodeMap: selectedNodeMapSelector(state),
  selectedFilterData: selectedFilterDataSelector(state),
  isIncludeAdditionalFilterAsPayload: isIncludeAdditionalFilterAsPayloadSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSelectedFilterData: (props) => {
    dispatch(setSelectedFilterData(props));
  }
});

export default memo(connect(mapStateToProps, mapDispatchToProps)(Filter));
