import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { getStatusStyles } from './input-style-sheet';
import { BUTTON, BUTTON_GROUP, DROPDOWN_TOGGLE } from './input-group-style-sheet';
import { InputStatus } from './types';
import { ButtonSize, buttonSizeVariants } from '@gs-ux-uitoolkit-common/button';
import { getFormTheme } from './form-theme';
import { DeepReadonly } from 'ts-essentials';
import './input-group-dropdown-button-theme-overrides';

export interface InputGroupDropdownButtonStyleSheetProps {
    theme: Theme;
    size: ButtonSize;
    status: InputStatus;
}

export interface InputControlDropdownButtonCssClasses {
    root: string;
}

export type InputGroupDropdownButtonStyledClasses = CssClassDefinitionsObject<
    keyof InputControlDropdownButtonCssClasses
>;

export interface InputGroupDropdownButtonStyleOverridesParams {
    props: DeepReadonly<InputGroupDropdownButtonStyleSheetProps>;
    createDefaultStyledClasses: () => InputGroupDropdownButtonStyledClasses;
}

export const inputGroupDropdownButtonStyleSheet = new StyleSheet(
    'input-group-dropdown-button',
    (props: InputGroupDropdownButtonStyleSheetProps) => {
        return createComponentClassDefinitions<
            InputGroupDropdownButtonStyleSheetProps,
            InputGroupDropdownButtonStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.inputGroupDropdownButton);
    }
);

export const globalInputGroupDropdownButtonClass = 'gs-input-group-dropdown-button';

function getDropdownButtonStyles({ theme }: { theme: Theme }) {
    const formTheme = getFormTheme(theme);

    return {
        lineHeight: 'normal',
        margin: '-1px',
        borderColor: formTheme.input.borderColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 0,
        transition: 'border-color 0.15s ease-in-out',

        '&:hover': {
            borderColor: formTheme.input.borderHoverColor,
            zIndex: 1,
        },
        '&:focus-within': {
            borderColor: theme.colorScheme.primary,
            zIndex: 2,
        },
    };
}

function getTheme({ theme, size = 'md', status }: InputGroupDropdownButtonStyleSheetProps) {
    const buttonTheme = {
        [`& ${BUTTON}`]: {
            ...getDropdownButtonStyles({ theme }),
            ...(status && getStatusStyles({ theme, status })),
        },
        [`& ${BUTTON} button`]: {
            // Must compenstate for the 2px of height that are added to an
            // input-group element that contains this button.
            height: `calc(${buttonSizeVariants[size].buttonHeight} - 2px)`,
            borderRadius: 0,
        },
        [`& ${BUTTON_GROUP} ${DROPDOWN_TOGGLE}:not(:first-child)`]: {
            marginLeft: 0,
        },
        [`& ${BUTTON} button::before`]: {
            // This is the main thing: Button's own focus rect is disabled,
            // replaced by borders set as part of InputGroup.
            content: 'none',
        },
    };
    return {
        ...buttonTheme,
    };
}

function createDefaultStyledClasses(
    props: InputGroupDropdownButtonStyleSheetProps
): InputGroupDropdownButtonStyledClasses {
    return {
        root: {
            ...getTheme(props),
        },
    };
}
