import {
    Border,
    colors,
    Emphasis,
    TypographyColor,
    ColorName,
    Status,
    Surface,
} from '@gs-ux-uitoolkit-common/design-system';
import { CssColor } from '@gs-ux-uitoolkit-common/style';
import { meetsContrastGuidelines, mix, rgba } from 'polished';
import {
    InteractionShades,
    ColorSchemeVariantProps,
    Theme,
    ColorMode,
    ColorContrastAmount,
    ThemeStatus,
} from '../theme';
import { getColorValueFromHex, parseColorValue } from './color';

/**
 * Provides interaction shades for ramps. This method takes a value that could map to multiple
 * theme tokens. Based on each value type the actual color is retrieved from the theme and passed
 * on for further processing
 */
export function getColorShades(
    theme: Theme,
    mode: 'light' | 'dark',
    value: ColorName | ThemeStatus | keyof ColorSchemeVariantProps,
    emphasis: Emphasis = 'bold'
): InteractionShades {
    let color: CssColor;
    const colorEmphasis = emphasis === 'minimal' ? 'bold' : emphasis;

    if (value in theme.color) {
        color = theme.color[value as ColorName][colorEmphasis];
    } else if (value in theme.status) {
        color = theme.status[value as Status][colorEmphasis];
    } else if (value in theme.colorScheme) {
        color = theme.colorScheme[value as keyof ColorSchemeVariantProps];
    } else {
        throw new Error(
            `Invalid value "${value}" provided to getRampInteractionShades. Allowed types are ColorName | Status | keyof ColorVariantProps`
        );
    }

    return getShades(theme, mode, color, emphasis);
}

/**
 * Provides the interaction shades for a given surface. The general calculations are as follows:
 *
 * :hover shade = base shade overlaid with gray 2 shades darker at 20%
 * :hover shade = base shade overlaid with gray 2 shades darker at 40%
 * text is based on a pre-defined map that maintains AA contrast
 * border is based on the minimal visual weight added to the given surface
 */
export function getSurfaceShades(
    theme: Theme,
    mode: ColorMode,
    surface: Surface,
    emphasis?: Emphasis
): InteractionShades {
    if (emphasis === 'bold') {
        // For selected + bold, all colors will follow the primary blue shade
        return getShades(theme, mode, theme.colorScheme.primary, 'bold');
    }
    if (emphasis === 'minimal' || emphasis === 'subtle') {
        const color = theme.surface[surface].toString();
        const themeColor = theme.colorScheme.primary.toString();
        const background = surface === 'none' ? rgba(themeColor, 0.2) : mix(0.2, themeColor, color);
        const hover = surface === 'none' ? rgba(themeColor, 0.4) : mix(0.2, themeColor, background);
        const active =
            surface === 'none' ? rgba(themeColor, 0.6) : mix(0.4, themeColor, background);
        const text = theme.text[surfaceToTypographyMap[surface]];
        const border = theme.border[surfaceToBorderMap[surface]];

        return {
            background,
            hover,
            active,
            text,
            border,
        };
    }

    // On light mode use a color on the ramp that's 3 shades darker and in dark mode use a
    // color that's 4 shades lighter
    const surfaceOverlayContrast = mode === 'dark' ? 4 : 3;
    const shades =
        surface === 'none'
            ? {
                  background: theme.surface.none,
                  active: rgba(mode === 'light' ? colors.gray030 : colors.gray070, 0.4),
                  hover: rgba(mode === 'light' ? colors.gray030 : colors.gray070, 0.2),
              }
            : getShades(theme, mode, theme.surface[surface], 'bold', surfaceOverlayContrast);
    const text = theme.text[surfaceToTypographyMap[surface]];
    const border = theme.border[surfaceToBorderMap[surface]];

    return {
        ...shades,
        text,
        border,
    };
}

/**
 * Helper method to get a message for an invalid color
 */
function getInvalidColorMessage(color: CssColor, fn: string) {
    return [
        `Invalid color "${color}" provided to "${fn}(...)". `,
        `Only valid GS Design System colors are allowed in this theme`,
    ].join(' ');
}

/**
 * Helper method used by all get*InteractionShades(...) methods. This takes in any color as
 * an argument and generates the shades based on the given emphasis. This method is not exposed
 * as the theme is locked down to provide emphasis shades only for the color values defined in
 * the theme object. This helper could be exposed for any theme creators to use once there are
 * clear directions on how color themes, apart from light and dark, can be built on the core
 * GS Design System color definitions
 * @param theme Current theme object
 * @param mode A 'light' or 'dark' value to base calculations on
 * @param color A CssColor value for which the interaction shades need to be created
 * @param emphasis The emphasis based on which the interaction shades are decided
 */
function getShades(
    theme: Theme,
    mode: 'light' | 'dark',
    color: CssColor,
    emphasis: Emphasis = 'bold',
    overlayContrast: ColorContrastAmount = 2
): InteractionShades {
    // First parse the color to a valid GS Design System color that matches the color ramp. This
    // will be used to perform additional calculations based on the lightness and darkness of the
    // color.
    let colorValue = getColorValueFromHex(color.toString());
    if (!colorValue) {
        throw new Error(getInvalidColorMessage(color.toString(), 'getInteractionShades'));
    }

    // Adjust white & transparent to the lightest gray
    if (colorValue === 'white' || colorValue === 'transparent') {
        colorValue = 'gray010';
    }
    // Adjust black to the darkest gray
    if (colorValue === 'black') {
        colorValue = 'gray110';
    }

    // Parse the string into it's corresponding parts. Eg: Parse "gray040" into "gray" and "040"
    const parsedColor = parseColorValue(colorValue);
    if (!parsedColor) {
        throw new Error(`"${color}" could not be parsed to a valid GS Design System color`);
    }

    // const colorName = parsedColor.name;
    const swatch = Number(parsedColor.swatch);

    const variationFnMap: { [e in Emphasis]: () => InteractionShades } = {
        bold: () => {
            const background = color.toString();

            // Default overlay is generated by increasing the contrast by 2 steps in the hue
            let overlay = theme.increaseContrast(colors[colorValue!], overlayContrast).toString();
            const overlayAmount = 0.2;

            // Use reversed text by default on bold styles
            let text = theme.text.reversed;

            // Dark shades will not have 2 additional steps in the color ramp to darken the
            // color to generate interaction shades. In this case the interaction will reverse and
            // a lighter shade is overlaid on the dark colors for :hover and :active shades
            if (mode === 'light' && swatch > 90) {
                overlay = colors.gray060;
            }

            // Similarly on dark mode the lightest shades can't be lightened anymore and we overlay
            // a darker shade
            if (mode === 'dark' && swatch < 20) {
                overlay = colors.gray030;
            }

            const hover = mix(overlayAmount, overlay, background);
            const active = mix(overlayAmount * 2, overlay, background);

            const meetsContrast = meetsContrastGuidelines(background, text.toString());

            // If the generated color does not meet AA contrast levels choose a dark shade of grey
            // as the text on the background color to generate the required contrast
            if (!meetsContrast.AA && mode === 'light') {
                text = colors.gray110;
            }

            // Similarly on dark mode use white text to meet AA contrast
            if (!meetsContrast.AA && mode === 'dark') {
                text = colors.white;
            }

            const border = 'transparent';

            return {
                background,
                hover,
                active,
                text,
                border,
            };
        },
        subtle: () => {
            const background = color.toString();
            const overlay = theme.increaseContrast(color, 2).toString();
            const hover = mix(0.2, overlay, background);
            const active = mix(0.4, overlay, background);
            let text = theme.increaseContrast(background, 5);
            const meetsContrast = meetsContrastGuidelines(background, text.toString());

            // If the generated color does not meet AA contrast levels choose a dark shade of grey
            // as the text on the background color to generate the required contrast
            if (mode === 'light' && (!meetsContrast.AA || color === theme.color.yellow.subtle)) {
                text = colors.gray090;
            }

            // Similarly on dark mode use a darker gray text to meet AA contrast
            if (mode === 'dark' && (!meetsContrast.AA || color === theme.color.yellow.subtle)) {
                text = colors.gray110;
            }

            const border = text;

            return {
                background,
                hover,
                active,
                text,
                border,
            };
        },
        minimal: () => {
            const background = 'transparent';
            const hover = rgba(color.toString(), 0.1);
            const active = rgba(color.toString(), 0.2);

            let text = color.toString();
            while (!meetsContrastGuidelines(theme.surface.primary.toString(), text.toString()).AA) {
                text = theme.increaseContrast(text, 1).toString();
            }
            const border = 'transparent';

            return {
                background,
                hover,
                active,
                text,
                border,
            };
        },
    };

    return variationFnMap[emphasis]();
}

/**
 * Maps the list of all surfaces to a text color as defined in the text tokens
 */
const surfaceToTypographyMap: { [name in Surface]: TypographyColor } = {
    primary: 'primary',
    secondary: 'primary',
    tertiary: 'primary',
    moderate: 'primary',
    bold: 'primary',
    strong: 'reversed',
    contrast: 'reversed',
    none: 'primary',
};

/**
 * Maps the list of all surfaces to a border color as defined in the border tokens
 */
const surfaceToBorderMap: { [name in Surface]: Border } = {
    primary: 'minimal',
    secondary: 'minimal',
    tertiary: 'subtle',
    moderate: 'moderate',
    bold: 'bold',
    strong: 'bold',
    contrast: 'contrast',
    none: 'minimal',
};
