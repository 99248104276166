import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const SwitchButton = ({disabled, active, onClick, id = ''}) => {
  const className = `btn-switch ${active ? 'active' : ''}${disabled ? 'disabled' : ''}`;
  return (
    <label className={className}>
      <input
        id={id}
        key={id}
        disabled={disabled}
        checked={active}
        defaultChecked={active}
        type="checkbox"
        onChange={(event) => {
          !disabled && onClick(event.target.checked, id)
        }}
      />
      <span
        className="slider"
      />
    </label>
  );
};

SwitchButton.defaultProps = {
  onClick: () => false,
  active: false,
  disabled: false
};

SwitchButton.propTypes = {
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.string
};

export default SwitchButton;
