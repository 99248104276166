import { Theme } from '@gs-ux-uitoolkit-common/theme';

export function getButtonSelectTheme(theme: Theme) {
    const selectedShades = theme.getSurfaceInteractionShades('primary', 'bold');
    const unselectedShades = theme.getSurfaceInteractionShades('tertiary');

    return {
        selected: {
            backgroundColor: selectedShades.background,
            backgroundHoverColor: selectedShades.hover,
            backgroundActiveColor: selectedShades.active,
            textColor: selectedShades.text,
        },
        unselected: {
            backgroundColor: unselectedShades.background,
            backgroundHoverColor: unselectedShades.hover,
            backgroundActiveColor: unselectedShades.active,
            textColor: unselectedShades.text,
        },
    };
}
