// tslint:disable:no-submodule-imports
import { Instance } from 'flatpickr/dist/types/instance';

/**
 * Checks if the flatpickr instance has a calendar to render plugins to
 *
 * @param instance Flatpickr instance
 */
export const hasCalendar = (instance: Instance): boolean =>
    !(instance.config.noCalendar || instance.isMobile);

export function getPrevMonthElement(instance: Instance): HTMLElement | null {
    if (hasCalendar(instance)) {
        const prevMonthEl =
            instance.calendarContainer.querySelector<HTMLElement>('.flatpickr-prev-month');
        if (!prevMonthEl) {
            throw new Error('could not find prevMonthEl');
        }
        return prevMonthEl;
    }
    return null;
}

export function getNextMonthElement(instance: Instance): HTMLElement | null {
    if (hasCalendar(instance)) {
        const nextMonthEl =
            instance.calendarContainer.querySelector<HTMLElement>('.flatpickr-next-month');
        if (!nextMonthEl) {
            throw new Error('could not find nextMonthEl');
        }
        return nextMonthEl;
    }
    return null;
}

export const prevMonthIconName = 'arrow-back';
export const nextMonthIconName = 'arrow-forward';
export const prevMonthIconType = 'filled';
export const nextMonthIconType = 'filled';
