import {
    GSFloatingFilterBase,
    FloatingFilterLogicalOperator,
    FLOATING_FILTER_OPERATORS_REGEX,
    FloatingFilterOperators,
    GSFloatingFilter,
} from './floating-filter-base';
import { arrayHelper } from '@gs-ux-uitoolkit-common/datacore';
import { NumberFilter, JoinOperator } from '@ag-grid-community/core';

export class GSNumberFloatingFilter extends GSFloatingFilterBase implements GSFloatingFilter {
    public filterType: string = 'number';
    public updateFilter(value: string): void {
        super.updateFilter(value);

        let condition: JoinOperator = 'OR';
        const filterModels: { [key: string]: any }[] = [];

        // This is where the filter-rule for this column is actually created
        const splitString = this.getConditionsSplitFromValue(value);
        // When an && or || is specified in the text of the inline filter

        splitString.forEach(splitStr => {
            let operandValue: string | number = splitStr.trim();
            let operator = NumberFilter.EQUALS;
            if (operandValue === FloatingFilterLogicalOperator.And) {
                condition = 'AND';
            } else if (operandValue === FloatingFilterLogicalOperator.Or) {
                condition = 'OR';
            } else {
                if (FLOATING_FILTER_OPERATORS_REGEX.test(operandValue)) {
                    const matchResult = operandValue.match(FLOATING_FILTER_OPERATORS_REGEX);

                    switch (arrayHelper.first(matchResult || [])) {
                        case FloatingFilterOperators.GreaterThan:
                            operator = NumberFilter.GREATER_THAN;
                            break;
                        case FloatingFilterOperators.GreaterThanOrEqual:
                            operator = NumberFilter.GREATER_THAN_OR_EQUAL;
                            break;
                        case FloatingFilterOperators.LessThan:
                            operator = NumberFilter.LESS_THAN;
                            break;
                        case FloatingFilterOperators.LessThanOrEqual:
                            operator = NumberFilter.LESS_THAN_OR_EQUAL;
                            break;
                        case FloatingFilterOperators.Equals:
                            operator = NumberFilter.EQUALS;
                            break;
                        case FloatingFilterOperators.NotEquals:
                            operator = NumberFilter.NOT_EQUAL;
                            break;
                    }
                    operandValue = operandValue.replace(FLOATING_FILTER_OPERATORS_REGEX, '');

                    // ignore if the value is empty
                    if (operandValue.trim() === '') return;

                    operandValue = Number(operandValue);
                    // No need to create an expression when the value is NaN
                    if (isNaN(operandValue)) {
                        return;
                    }

                    filterModels.push({
                        condition,
                        operator,
                        operandValue,
                    });
                } else {
                    // ignore if the value is empty
                    if (operandValue.trim() === '') return;

                    operandValue = Number(operandValue);
                    // No need to create an expression when the value is NaN
                    if (isNaN(operandValue)) {
                        return;
                    }
                    filterModels.push({
                        condition,
                        operator,
                        operandValue,
                    });
                }
            }
        });

        this.setFilterModel(filterModels);
    }
}
