import React, { useState, createContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SNACKBAR_AUTO_DISMISS } from '../../constants';

export const SnackBarContext = createContext();

const SnackBarProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const openSnackbar = useCallback(
    data =>
      setToasts(toasts => {
        const list = toasts.filter(toast => toast.messageId !== data.messageId);
        return [{ hideTime: SNACKBAR_AUTO_DISMISS, autoHide: true, ...data, id: data.id || new Date().getMilliseconds() }, ...list];
      }),
    []
  );

  const closeSnackbar = useCallback(id => setToasts(data => data.filter(item => item.id !== id)), []);

  const value = useMemo(() => ({snackbars: toasts, openSnackbar, closeSnackbar}));

  return <SnackBarContext.Provider value={value}>{children}</SnackBarContext.Provider>;
};

SnackBarProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default SnackBarProvider;
