import { GridApi, GridOptionsService } from '@ag-grid-community/core';

// we shouldn't use this function for new functionalities as it's depending on
// private GridApi member
export function getGridOptionsService(gridApi: GridApi | undefined): GridOptionsService {
    // just because we are accessing a private member here...
    const gridOptionsService: GridOptionsService = (gridApi as any).gridOptionsService;
    if (gridOptionsService) {
        return gridOptionsService;
    }
    throw new Error('Cannot access the GridOptionsService');
}
