import { math } from 'polished';
import {
    StyleSheet,
    CssClassDefinitionsObject,
    CssPropertyType,
    CssProperties,
    browserSupportsSelector,
} from '@gs-ux-uitoolkit-common/style';
import { getIconStyles, getPulseStyles } from '@gs-ux-uitoolkit-common/icon-font';
import {
    Theme,
    darkTheme,
    lightTheme,
    createComponentClassDefinitions,
} from '@gs-ux-uitoolkit-common/theme';
import {
    ButtonSize,
    ButtonShape,
    ButtonActionType,
    ButtonStatus,
    ButtonEmphasis,
    defaultButtonProps,
} from './button-props';
import {
    applyButtonIconAndTextPositions,
    buttonSizeVariants,
    applyButtonTypography,
    focusedBorderMargin,
    applyButtonCommonBase,
} from '../common/common-style-sheet';
import { colors } from '@gs-ux-uitoolkit-common/design-system';
import { isBrowser } from '@gs-ux-uitoolkit-common/shared';
import { getButtonTheme } from './button-theme';
import { DeepReadonly } from 'ts-essentials';
import './button-theme-overrides';

export interface ButtonStyleSheetProps {
    theme: Theme;
    actionType: ButtonActionType;
    status: ButtonStatus;
    emphasis: ButtonEmphasis;
    size: ButtonSize;
    shape: ButtonShape;
    disabled: boolean;
    active: boolean;
}

export interface ButtonCssClasses {
    root: string;
    button: string;
}

export type ButtonStyledClasses = CssClassDefinitionsObject<keyof ButtonCssClasses>;

export interface ButtonStyleOverridesParams {
    props: DeepReadonly<ButtonStyleSheetProps>;
    createDefaultStyledClasses: () => ButtonStyledClasses;
}

const borderWidth = '1px';

export const buttonStyleSheet = new StyleSheet('button', (props: ButtonStyleSheetProps) => {
    return createComponentClassDefinitions<ButtonStyleSheetProps, ButtonStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.button
    );
});

function createDefaultStyledClasses({
    theme,
    actionType = defaultButtonProps.actionType,
    status = defaultButtonProps.status,
    emphasis = defaultButtonProps.emphasis,
    size = defaultButtonProps.size,
    shape = defaultButtonProps.shape,
    disabled = defaultButtonProps.disabled,
    active = defaultButtonProps.active,
}: ButtonStyleSheetProps): ButtonStyledClasses {
    return {
        root: {
            display: 'inline-block',
        },
        button: {
            ...applyButtonBase({
                theme,
                actionType,
                emphasis,
                size,
                status,
                shape,
                disabled,
                active,
            }),

            '&:before': {
                ...applyFocusBorderBase({ size, shape }),
            },

            '&:hover:not(:disabled)': {
                ...applyButtonHover({ theme, actionType, emphasis, status }),
            },

            '&:disabled': {
                opacity: theme.state.disabledOpacity,
                cursor: 'not-allowed',
            },

            '&:not(:disabled):active': {
                ...applyButtonActive({ theme, actionType, emphasis, status }),

                // Sept 19, 2022: We had to conditionally apply focus-visible due to its unavailability in
                //    Chrome versions 85 and below. ION Dealbook was using Chrome 80 in Marquee Desktop OpenFin
                //    where :focus-visible is not supported. See this ticket for context: https://jira.site.gs.com/browse/UX-15248
                [!isBrowser || browserSupportsSelector(':focus-visible')
                    ? '&:focus-visible'
                    : '&:focus']: {
                    '&:before': {
                        ...applyFocusBorderWhenNotFocused({ size, shape }),
                    },
                },
            },

            // Sept 19, 2022: We had to conditionally apply focus-visible due to its unavailability in
            //    Chrome versions 85 and below. ION Dealbook was using Chrome 80 in Marquee Desktop OpenFin
            //    where :focus-visible is not supported. See this ticket for context: https://jira.site.gs.com/browse/UX-15248
            [!isBrowser || browserSupportsSelector(':focus-visible')
                ? '&:focus-visible'
                : '&:focus']: {
                ...applyButtonFocus({ theme, actionType, emphasis, status }),

                '&:before': {
                    ...applyFocusBorderWhenFocused({
                        theme,
                        emphasis,
                        size,
                        shape,
                        actionType,
                    }),
                },
            },

            '&:after': {
                ...applyButtonStatusIcon({ theme, actionType, emphasis, size, status }),
            },

            '[data-gs-uitk-component="icon"]': {
                ...applyIconStyles({ actionType, size, status }),
            },

            ...applyButtonIconAndTextPositions({ emphasis, size }),
        },
    };
}

function applyButtonBase(props: {
    theme: Theme;
    actionType: ButtonActionType;
    emphasis: ButtonEmphasis;
    size: ButtonSize;
    status: ButtonStatus;
    shape: ButtonShape;
    disabled: boolean;
    active: boolean;
}): CssProperties {
    const { theme, actionType, emphasis, size, status, shape, disabled, active } = props;
    const buttonTheme = getButtonTheme(theme);

    const notSubtleStyles: CssProperties = {
        padding: `${buttonSizeVariants[size].buttonPaddingY} 0px`,
        border: '0px',
    };
    const subtleStyles: CssProperties = {
        padding: `calc(${buttonSizeVariants[size].buttonPaddingY} - ${borderWidth}) 0px`,
        transition: 'border-color 0.15s ease-in-out',
        border: `${borderWidth} solid ${buttonTheme.action[actionType].emphasis.subtle.borderColor}`,
    };
    const actionTypeStyles = emphasis == 'subtle' ? subtleStyles : notSubtleStyles;

    const activeStyles =
        active && !disabled ? applyButtonActive({ theme, actionType, emphasis, status }) : {};

    return {
        ...applyButtonCommonBase(),

        height: buttonSizeVariants[size].buttonHeight,
        borderRadius: shape === 'circle' ? buttonSizeVariants[size].circleRadius : '2px',

        ...applyButtonTypography({ size, theme }),

        background: buttonTheme.action[actionType].emphasis[emphasis].backgroundColor,
        color: buttonTheme.action[actionType].emphasis[emphasis].textColor,
        ...actionTypeStyles,
        ...applyButtonBaseStatus({ theme, actionType, emphasis, status }), // apply status last since it overrides action type styles
        ...activeStyles,
    };
}

function applyButtonBaseStatus(props: {
    theme: Theme;
    actionType: ButtonActionType;
    emphasis: ButtonEmphasis;
    status: ButtonStatus;
}): CssProperties {
    const { theme, actionType, emphasis, status } = props;

    const commonStatusStyles: CssProperties = {
        color: 'transparent',
    };

    if (status === 'none') {
        return {};
    } else if (emphasis === 'bold' && actionType === 'primary') {
        return {
            ...commonStatusStyles,
            background: theme.status[status].bold,
        };
    } else {
        return { ...commonStatusStyles };
    }
}

function applyButtonHover(props: {
    theme: Theme;
    actionType: ButtonActionType;
    emphasis: ButtonEmphasis;
    status: ButtonStatus;
}): CssProperties {
    const { theme, actionType, emphasis, status } = props;
    const buttonTheme = getButtonTheme(theme);

    return {
        background: buttonTheme.action[actionType].emphasis[emphasis].backgroundHoverColor,
        ...applyButtonHoverStatus({ theme, actionType, emphasis, status }), // apply status last since it overrides action type styles
    };
}

function applyButtonHoverStatus(props: {
    theme: Theme;
    actionType: ButtonActionType;
    emphasis: ButtonEmphasis;
    status: ButtonStatus;
}): CssProperties {
    const { theme, actionType, emphasis, status } = props;

    if (emphasis === 'bold' && actionType === 'primary' && status !== 'none') {
        return {
            background: theme.status[status].bold,
        };
    } else {
        return {};
    }
}

function applyButtonActive(props: {
    theme: Theme;
    actionType: ButtonActionType;
    emphasis: ButtonEmphasis;
    status: ButtonStatus;
}): CssProperties {
    const { theme, actionType, emphasis, status } = props;
    const buttonTheme = getButtonTheme(theme);

    // Increase active contrast by 1 for button text
    const text = theme.increaseContrast(
        buttonTheme.action[actionType].emphasis[emphasis].textColor,
        1
    );
    const background = buttonTheme.action[actionType].emphasis[emphasis].backgroundActiveColor;

    return {
        color: status !== 'none' ? 'transparent' : text,
        background,
        // apply status last since it overrides action type styles
        ...applyButtonActiveStatus({ theme, actionType, emphasis, status }),
    };
}

function applyButtonActiveStatus(props: {
    theme: Theme;
    actionType: ButtonActionType;
    emphasis: ButtonEmphasis;
    status: ButtonStatus;
}): CssProperties {
    const { theme, actionType, emphasis, status } = props;

    const interactionShades = theme.getColorInteractionShades(status, emphasis);

    if (emphasis === 'bold' && actionType === 'primary' && status !== 'none') {
        return {
            background: interactionShades.active,
        };
    } else {
        return {};
    }
}

function applyButtonStatusIcon(props: {
    theme: Theme;
    actionType: ButtonActionType;
    emphasis: ButtonEmphasis;
    size: ButtonSize;
    status: ButtonStatus;
}): CssProperties {
    const { theme, actionType, emphasis, size, status } = props;
    const buttonTheme = getButtonTheme(theme);

    const iconFontSize = buttonSizeVariants[size].iconFontSize;
    const commonStyles: CssProperties = {
        position: 'absolute',
        left: `calc(50% - ${iconFontSize} / 2)`,
        fontSize: iconFontSize,
    };

    const actionTextColor = buttonTheme.action[actionType].emphasis[emphasis].textColor;
    const keepActionTextColor =
        emphasis === 'bold' && (actionType === 'primary' || actionType === 'destructive');

    // TODO: Prevent this!!!
    const isDarkTheme = theme === darkTheme;
    const isLightTheme = theme === lightTheme;

    switch (status) {
        case 'loading':
            return {
                ...getIconStyles({ name: 'loading', type: 'filled' }),
                ...commonStyles,
                // edge case for info and contrast actionType
                color: keepActionTextColor
                    ? actionTextColor
                    : actionType === 'info' && isLightTheme
                      ? colors.blue050
                      : actionType === 'contrast' || (actionType === 'info' && isDarkTheme)
                        ? colors.blue060
                        : theme.status[status].bold,
                ...getPulseStyles(),
            };
        case 'success':
            return {
                ...getIconStyles({ name: 'check-circle', type: 'outlined' }),
                ...commonStyles,
                // edge case for info and contrast actionType
                color: keepActionTextColor
                    ? actionTextColor
                    : actionType === 'info' && isLightTheme
                      ? colors.green050
                      : actionType === 'contrast' || (actionType === 'info' && isDarkTheme)
                        ? colors.green060
                        : theme.status[status].bold,
            };
        case 'error':
            return {
                ...getIconStyles({ name: 'error-outline', type: 'outlined' }),
                ...commonStyles,
                // edge case for info and contrast actionType
                color: keepActionTextColor
                    ? actionTextColor
                    : actionType === 'info' && isLightTheme
                      ? colors.red050
                      : actionType === 'contrast' || (actionType === 'info' && isDarkTheme)
                        ? colors.red060
                        : theme.status[status].bold, // edge case for info actionType, since the default status base color does not pop up enough on dark background
            };
        case 'none':
        default:
            return {};
    }
}

function applyFocusBorderBase(props: { size: ButtonSize; shape: ButtonShape }): CssProperties {
    const { size, shape } = props;

    return {
        ...applyFocusBorderWhenNotFocused({ size, shape }),

        content: '""',
        position: 'absolute',
        display: 'block',
        transition:
            'border-color 0.15s ease-in-out, ' +
            'border-radius 0.15s ease-in-out, ' +
            'top 0.15s ease-in-out, ' +
            'right 0.15s ease-in-out, ' +
            'bottom 0.15s ease-in-out, ' +
            'left 0.15s ease-in-out',
    };
}

function applyFocusBorderWhenFocused(props: {
    theme: Theme;
    actionType: ButtonActionType;
    emphasis: ButtonEmphasis;
    size: ButtonSize;
    shape: ButtonShape;
}): CssProperties {
    const { theme, emphasis, size, shape, actionType } = props;
    const buttonTheme = getButtonTheme(theme);

    const borderMargin =
        emphasis === 'subtle'
            ? math(`${focusedBorderMargin} - ${borderWidth}`)
            : focusedBorderMargin;

    return {
        borderRadius: shape === 'circle' ? buttonSizeVariants[size].circleRadius : '4px',
        borderColor: buttonTheme.focus[actionType].borderColor,
        top: borderMargin,
        bottom: borderMargin,
        right: borderMargin,
        left: borderMargin,
    };
}

function applyFocusBorderWhenNotFocused(props: {
    borderMargin?: CssPropertyType['margin'];
    size: ButtonSize;
    shape: ButtonShape;
}): CssProperties {
    const { borderMargin = '0', size, shape } = props;

    return {
        borderRadius: shape === 'circle' ? buttonSizeVariants[size].circleRadius : '2px',
        border: `1px solid transparent`,
        top: borderMargin,
        bottom: borderMargin,
        right: borderMargin,
        left: borderMargin,
    };
}

function applyIconStyles(props: {
    actionType: ButtonActionType;
    size: ButtonSize;
    status: ButtonStatus;
}): CssProperties {
    const { size } = props;

    return {
        fontSize: buttonSizeVariants[size].iconFontSize,
    };
}

function applyButtonFocus(props: {
    theme: Theme;
    actionType: ButtonActionType;
    emphasis: ButtonEmphasis;
    status: ButtonStatus;
}): CssProperties {
    const { theme, emphasis, actionType, status } = props;
    const buttonTheme = getButtonTheme(theme);

    const notSubtleStyles: CssProperties = {};

    const subtleStyles: CssProperties = {
        borderColor: 'transparent',
    };
    const emphasisStyles = emphasis === 'subtle' ? subtleStyles : notSubtleStyles;

    return {
        backgroundColor: status !== 'none' ? '' : buttonTheme.focus[actionType].backgroundColor,
        color: status !== 'none' ? '' : buttonTheme.focus[actionType].textColor,
        outline: 0,
        boxShadow: 'none', // instead we use a ::before element to indicate focus
        ...emphasisStyles,
    };
}
