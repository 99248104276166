import {
    StyleSheet,
    CssPropertyType,
    CssClassDefinitionsObject,
} from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { CheckboxSize, CheckboxCommonProps } from './checkbox-props';
import { DeepReadonly } from 'ts-essentials';
import './checkbox-theme-overrides';

export interface CheckboxStyleSheetProps {
    theme: Theme;
    size: CheckboxSize;
    inline: CheckboxCommonProps['inline'];
    status: CheckboxCommonProps['status'];
    labelPlacement?: CheckboxCommonProps['labelPlacement'];
}

export interface CheckboxCssClasses {
    root: string;
    input: string;
    container: string;
    inner: string;
    label: string;
    children: string;
}

export type CheckboxStyledClasses = CssClassDefinitionsObject<keyof CheckboxCssClasses>;

export interface CheckboxStyleOverridesParams {
    props: DeepReadonly<CheckboxStyleSheetProps>;
    createDefaultStyledClasses: () => CheckboxStyledClasses;
}

export const checkboxStyleSheet = new StyleSheet('checkbox', (props: CheckboxStyleSheetProps) => {
    return createComponentClassDefinitions<CheckboxStyleSheetProps, CheckboxStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.checkbox
    );
});

function createDefaultStyledClasses({
    theme,
    size,
    inline,
    labelPlacement,
    status,
}: CheckboxStyleSheetProps): CheckboxStyledClasses {
    const errorColors = theme.status.error;

    const borderColor = theme.border.strong;
    const borderHoverColor = theme.increaseContrast(borderColor, 1);

    const checkboxIconColor = theme.text.reversed;

    const errorStyles =
        status === 'error'
            ? {
                  '&:not(:disabled)': {
                      '+ span': {
                          borderColor: errorColors.bold,
                      },
                  },
                  '&:checked': {
                      '+ span': {
                          backgroundColor: errorColors.bold,
                      },
                  },
                  '&:focus': {
                      '+ span': {
                          boxShadow: `0 0 0 1px ${checkboxIconColor}, 0 0 0 2px ${errorColors.bold}`,
                      },
                  },
              }
            : {};

    return {
        root: {
            display: inline ? 'inline-flex' : 'block',
            marginBottom: inline
                ? checkboxSizeVariants[size].rootInlineMarginBottom
                : checkboxSizeVariants[size].rootMarginBottom,
            paddingBottom: inline ? 0 : checkboxSizeVariants[size].rootPaddingBottom, // fixing height when inline. Can be removed when children marginTop be removed (fixing styles in next version)
            lineHeight: 'normal', // Fixing height of root - iherited line height was bigger than expected height
            alignItems: inline ? 'baseline' : 'normal',
            marginRight: inline ? checkboxSizeVariants[size].inlineMarginRight : '0px',
        },
        input: {
            cursor: 'pointer',
            transition:
                'background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
            bottom: 0,
            left: 0,
            opacity: 0,
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 1,

            '&:checked + span, &:indeterminate + span': {
                borderColor: theme.colorScheme.primary,
                backgroundColor: theme.colorScheme.primary,

                '&::after': {
                    borderBottom: `${checkboxSizeVariants[size].checkedBorderWidth} solid ${checkboxIconColor}`,
                    borderLeft: 0,
                    borderRight: `${checkboxSizeVariants[size].checkedBorderWidth} solid ${checkboxIconColor}`,
                    borderTop: 0,
                    transform: 'rotate(45deg) scale(1)',
                },
            },
            '&:indeterminate + span': {
                '&::after': {
                    height: checkboxSizeVariants[size].indeterminateHeight,
                    left: checkboxSizeVariants[size].indeterminateLeft,
                    top: checkboxSizeVariants[size].indeterminateTop,
                    width: checkboxSizeVariants[size].indeterminateWidth,
                    transform: 'scale(1)',
                },
            },
            '&:disabled': {
                cursor: 'default',
            },
            '&:focus': {
                '+ span': {
                    boxShadow: `0 0 0 1px ${checkboxIconColor}, 0 0 0 2px ${theme.colorScheme.primary}`,
                },
            },
            '&:hover:not(:disabled):not(:checked)': {
                borderColor: borderHoverColor,
            },
            ...errorStyles,
        },
        container: {
            order: labelPlacement === 'left' ? 2 : 0, // Changing position of label
            display: 'inline-flex',
        },
        inner: {
            border: '1px solid',
            borderColor: borderColor,
            backgroundColor: theme.surface.primary,
            boxSizing: 'border-box',
            display: 'block',
            position: 'relative',
            transition:
                'background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
            height: checkboxSizeVariants[size].inputHeight,
            marginRight: labelPlacement === 'left' ? 0 : checkboxSizeVariants[size].inputMargin,
            marginLeft: labelPlacement === 'left' ? checkboxSizeVariants[size].inputMargin : 0,
            top: checkboxSizeVariants[size].inputTop,
            width: checkboxSizeVariants[size].inputWidth,
            '&::after': {
                boxSizing: 'border-box',
                content: '""',
                display: 'table',
                position: 'absolute',

                height: checkboxSizeVariants[size].checkedHeight,
                left: checkboxSizeVariants[size].checkedLeft,
                top: checkboxSizeVariants[size].checkedTop,
                width: checkboxSizeVariants[size].checkedWidth,
            },
        },
        label: {
            marginBottom: 0,
            display: inline ? 'inline-flex' : 'flex',
        },
        children: {
            order: 1,
            marginTop: inline ? checkboxSizeVariants[size].inlineLabelMarginTop : '0',
        },
    };
}

const checkboxSizeVariants: { [name in CheckboxSize]: CheckboxSizeVariant } = {
    sm: {
        rootMarginBottom: '2px',
        rootPaddingBottom: '2px',
        rootInlineMarginBottom: '2px',
        inputFontSize: '12px',
        inputHeight: '14px',
        inputMargin: '8px',
        inputTop: '1px',
        inputWidth: '14px',
        inlineMarginRight: '16px',
        inlineLabelMarginTop: '2px',
        checkedBorderWidth: '1.5px',
        checkedHeight: '8px',
        checkedLeft: '4px',
        checkedTop: '0px',
        checkedWidth: '5px',
        indeterminateHeight: '0px',
        indeterminateLeft: '3px',
        indeterminateTop: '5px',
        indeterminateWidth: '6px',
    },
    md: {
        rootMarginBottom: '6px',
        rootPaddingBottom: '2px',
        rootInlineMarginBottom: '6px',
        inputFontSize: '14px',
        inputHeight: '16px',
        inputMargin: '10px',
        inputTop: '2px',
        inputWidth: '16px',
        inlineMarginRight: '24px',
        inlineLabelMarginTop: '2px',
        checkedBorderWidth: '1.5px',
        checkedHeight: '9px',
        checkedLeft: '5px',
        checkedTop: '1px',
        checkedWidth: '5px',
        indeterminateHeight: '0px',
        indeterminateLeft: '4px',
        indeterminateTop: '6.5px',
        indeterminateWidth: '6px',
    },
    lg: {
        rootMarginBottom: '14px',
        rootPaddingBottom: '1px',
        rootInlineMarginBottom: '14px',
        inputFontSize: '16px',
        inputHeight: '18px',
        inputMargin: '10px',
        inputTop: '3px',
        inputWidth: '18px',
        inlineMarginRight: '32px',
        inlineLabelMarginTop: '1px',
        checkedBorderWidth: '2px',
        checkedHeight: '12px',
        checkedLeft: '5px',
        checkedTop: '1px',
        checkedWidth: '6px',
        indeterminateHeight: '0px',
        indeterminateLeft: '4px',
        indeterminateTop: '7px',
        indeterminateWidth: '8px',
    },
};

interface CheckboxSizeVariant {
    rootMarginBottom: CssPropertyType['marginBottom'];
    rootPaddingBottom: CssPropertyType['paddingBottom']; // fixing height of component after changing display to flex - remove in v17
    rootInlineMarginBottom: CssPropertyType['marginBottom']; // Margin fixing distance between checkboxes when inline
    inputFontSize: CssPropertyType['fontSize'];
    inputHeight: CssPropertyType['height'];
    inputMargin: CssPropertyType['margin'];
    inputTop: CssPropertyType['top'];
    inputWidth: CssPropertyType['width'];
    inlineMarginRight: CssPropertyType['marginRight'];
    inlineLabelMarginTop: CssPropertyType['marginTop']; // Margin fixing label vertical position when checkbox is set inline
    checkedBorderWidth: CssPropertyType['width'];
    checkedHeight: CssPropertyType['height'];
    checkedLeft: CssPropertyType['left'];
    checkedTop: CssPropertyType['top'];
    checkedWidth: CssPropertyType['width'];
    indeterminateHeight: CssPropertyType['height'];
    indeterminateLeft: CssPropertyType['left'];
    indeterminateTop: CssPropertyType['top'];
    indeterminateWidth: CssPropertyType['width'];
}
