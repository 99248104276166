import { MenuBlurEvent } from './menu-event';
import { MenuSize, MenuValue } from './menu-props';

export interface MenuContext {
    optionClick: () => void;
    optionSelect: (value: MenuValue) => void;
    optionDeselect: (value: MenuValue) => void;

    submenuShow: () => void;
    submenuHide: (event: MenuBlurEvent) => void;

    addChildren(children: HTMLElement[], afterElement: HTMLElement, autoFocus?: boolean): void;
    removeChildren(afterElement: HTMLElement): void;

    disabled: boolean;
    size: MenuSize;
    multiple: boolean;
    values: Set<MenuValue>;
}

export const defaultMenuContext: MenuContext = {
    optionClick: () => undefined,
    optionSelect: () => undefined,
    optionDeselect: () => undefined,
    submenuShow: () => undefined,
    submenuHide: () => undefined,
    addChildren: () => undefined,
    removeChildren: () => undefined,
    disabled: false,
    size: 'md',
    multiple: false,
    values: new Set(),
};
