import {components} from "react-select";
import PropTypes from "prop-types";
import cn from "classnames";
import React from "react";
import {Conditional} from "../../../../components/core/Conditional";

const highLightMatchedText = (textToHighlight, sourceText = "") => {
  const index = sourceText.toLowerCase().indexOf(textToHighlight.toLowerCase());
  const textToHighlightLength = textToHighlight.length;
  const firstPart = sourceText.substr(0, index);
  const middlePart = sourceText.substring(index, index + textToHighlightLength);
  const lastPart = sourceText.substr(index + textToHighlightLength);
  return (
    <>
      <span>{firstPart}</span>
      <span style={{color: "rgb(24, 106, 222)"}}>{middlePart}</span>
      <span>{lastPart}</span>
    </>
  )
};

const FundBenchmarkOption = (props) => {
  const {selectProps: {inputValue} = {}, data: {label: option, value} = {}} = props;
  const isGroupOption = (value === 'myTopHoldings');
  // eslint-disable-next-line no-unused-vars
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  return (
    <components.Option {...newProps} className={cn({"custom-option": !isGroupOption}, {"custom-group-option": isGroupOption})}>
      <Conditional condition={!isGroupOption}>
        {highLightMatchedText(inputValue, option)}
        <span>{option}</span>
      </Conditional>
    </components.Option>
  );
};

FundBenchmarkOption.propTypes = {
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object,
  data: PropTypes.object
};

export default FundBenchmarkOption;
