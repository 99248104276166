import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import translator  from '../../../../services/translator';
import Constants from '../../../../constants/appConstants';
import  { EXTERNAL_LINK_SKIP_ATTRIBUTE } from '../../../../constants/pageConstants';
import {getAppRoute} from '../../../../utils/commonUtils';

const {translate: t} = translator;

const Header = ({rollBackToLegacyReport, currentReportConfig = {}}) => {
  const skipAtt = {
    [EXTERNAL_LINK_SKIP_ATTRIBUTE]: true
  };

  const ctsReportUrl = useMemo(() => {
    const {legacyReportId, newReportId, isNewReport} = currentReportConfig || {};
    const rollBackToOldReport = (rollBackToLegacyReport || !isNewReport);
    const url = rollBackToOldReport ? legacyReportId : newReportId;
    const reportBaseUrl = `${Constants.REPORTS_ROUTE}/${url}`;
    return getAppRoute(reportBaseUrl);
  }, [rollBackToLegacyReport, currentReportConfig]);

  return (
    <React.Fragment>
      <p>{t('tkCtsSubTitle1')}</p>
      <p>{t('tkCtsSubTitle2')} <a {...skipAtt} href={ctsReportUrl}>{t('tkCtsSubTitle3')}</a> {t('tkCtsSubTitle4')}</p>
    </React.Fragment>
  );
};

Header.propTypes = {
  rollBackToLegacyReport: PropTypes.bool,
  currentReportConfig: PropTypes.object
};

export default Header;
