import { StyleSheet, CssClasses } from '@gs-ux-uitoolkit-react/style';

export type ButtonGroupCssClasses = CssClasses<typeof buttonGroupStyleSheet>;

export const buttonGroupStyleSheet = new StyleSheet('button-group', () => {
    return {
        root: {
            '.gs-uitk-responsive-button-dropdown': {
                display: 'none',
            },

            '@media only screen and (max-width: 560px)': {
                '.gs-uitk-responsive-button-dropdown': {
                    display: 'flex !important',
                    justifyContent: 'flex-end',
                },
                '.gs-uitk-button-group': {
                    display: 'none !important',
                },
            },
        },
    };
});
