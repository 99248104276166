import { ComponentProps, CommonStyleType } from '@gs-ux-uitoolkit-common/component';
import { Placement, Size, Status } from '@gs-ux-uitoolkit-common/design-system';
import { RadioCssClasses } from './radio-style-sheet';

export interface RadioProps<StyleType = CommonStyleType> extends ComponentProps<StyleType> {
    /**
     * Whether or not the radio is checked
     */
    checked?: boolean;

    /**
     * Style classes to override.
     */
    classes?: Partial<RadioCssClasses>;

    /**
     * Whether or not the radio is in a disabled state
     */
    disabled?: boolean;

    /**
     * Whether or not the radio starts off checked
     */
    defaultChecked?: boolean;

    /**
     * Whether or not the radio is inline
     */
    inline?: boolean;

    /**
     * Name of the radio
     */
    name?: string;

    /**
     * Size of the radio
     */
    size?: RadioSize;

    /**
     * Label placement
     */
    labelPlacement?: RadioLabelPlacement;

    /**
     * Status of the radio
     */
    status?: RadioStatus;

    /**
     * The value of a specific radio input among a group of radio inputs that share a common name.
     * Learn more at [Mozilla Developer Network](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio#Data_representation_of_a_radio_group).
     */
    value?: string;
}

export type RadioSize = Extract<Size, 'sm' | 'md' | 'lg'>;
export type RadioStatus = Extract<Status, 'none' | 'error'>;
export type RadioLabelPlacement = Extract<Placement, 'left' | 'right'>;

export const radioSizes: RadioSize[] = ['sm', 'md', 'lg'];
export const radioStatuses: RadioStatus[] = ['none', 'error'];

export type RadioDefaultProps = Required<
    Pick<RadioProps, 'inline' | 'size' | 'status' | 'labelPlacement'>
>;

export const radioDefaultProps: RadioDefaultProps = {
    inline: false,
    size: 'md',
    status: 'none',
    labelPlacement: 'right',
};

export interface RadioChangeEventProps {
    checked: RadioProps['checked'];
    name?: RadioProps['name'];
    value?: RadioProps['value'];
}
