import React from 'react';
import PropTypes from 'prop-types';
import List from '../../../../components/core/List';
import ListItem from '../ListItem';
import {getNoDataComponent} from '../../../../components/core/NoDataComponent';
import './index.scss';

const FundBenchmarkList = (props) => {
  const {items, disabled, placeholder} = props;
  const id = 'fundbenchmark-list';
  return (
    <List
      items={items}
      disabled={disabled}
      NoDataComponent={getNoDataComponent(id, placeholder)}
      props={props}
      cssName={id}
      testId={id}>
      <ListItem />
    </List>
  );
};

FundBenchmarkList.propTypes = {
  items:PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  placeholder: PropTypes.string
};

export default FundBenchmarkList;
