import {endPointGenerator} from './urlGenerator';
import parameterGenerator from './parameterGenerator';
import fetchService, {downloadService} from './fetchService';
import {jsonToNameValue} from '../utils/jsonHelper';

const serviceManager = ({
  state = {}, endPointConfig = {}, payLoad = [], contentType = 'application/json', skipCommonParams = true,
  serviceType = '', serviceSubType = '', signalKey = ''
}) => {
  const {method, parameters} = endPointConfig;
  let {path} = endPointConfig;

  if (typeof path === 'function') {
    path = path(state);
  }

  let endPoint = endPointGenerator(state, path);
  let params = parameterGenerator(parameters, state, payLoad, skipCommonParams);

  if (['POST', 'DELETE', 'PUT'].includes(method)) {
    params = JSON.stringify(params);
  }
  if (method === 'GET') {
    const queryParameters = jsonToNameValue(params);
    if (queryParameters) {
      endPoint = `${endPoint}?${queryParameters}`;
    }
  }

  return fetchService(
    endPoint, params, method, contentType,
    serviceType, serviceSubType, signalKey
  );
};

export const DownloadServiceManager = ({
   state = {}, endPointConfig = {}, payLoad = [], contentType = 'application/json',
   progressCallback,  skipCommonParams = true
}) => {
  const {method, parameters} = endPointConfig;
  let {path} = endPointConfig;

  if (typeof path === 'function') {
    path = path(state);
  }

  let endPoint = endPointGenerator(state, path);
  let params = parameterGenerator(parameters, state, payLoad, skipCommonParams);

  if (method === 'POST') {
    params = JSON.stringify(params);
  }

  if (method === 'GET') {
    const queryParameters = jsonToNameValue(params);
    if (queryParameters) {
      endPoint = `${endPoint}?${queryParameters}`;
    }
  }

  return downloadService(endPoint, params, method, contentType, progressCallback)
    .then((response) => {
      return response;
    });
};


export default serviceManager;
