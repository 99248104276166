
export default {
 
  FETCH_ACCOUNT_FACT_DEBIT_CREDIT_INFO: {
    path: '/api/v1/mfaccounts/{accountId}/debitCredit',
    method: 'GET'
  },
  UPDATE_DEBIT_CREDIT: {
    path: '/api/v1/mfaccounts/updateDebitCredit',
    method: 'POST',
  },
};
