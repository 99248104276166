import React, { useEffect } from 'react';
import constants from '../../../../../../constants/appConstants';
import './index.scss';

const GridNotification = params => {
  const {
    data: { notificationType, notificationMsg },
  } = params;

  const handleNotificationClose = () => {
    params.api.setPinnedTopRowData([]);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleNotificationClose();
    }, constants.SNACKBAR_HIDE_TIME);
    
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={`gridAlert__container gridAlert__${notificationType}`}>
      <div className='gridAlert__msg'>{notificationMsg}</div>
      <span className='gridAlert__closeIcon' onClick={handleNotificationClose} />
    </div>
  );
};

export default GridNotification;
