import React from 'react';
import PropTypes from 'prop-types';
// const getNodeLabel = (node) => last(node.path.split('/'));
import Row from '../Row';

const TreeNode = (props) => {
  const { node, getChildNodes, level=0, open, indentationPadding} = props;

  const getPaddingLeft = (level, type) => {
    let paddingLeft = level * indentationPadding;
    if (type === 'file') paddingLeft += indentationPadding;
    return paddingLeft;
  };

  return (
    <React.Fragment>
      <Row {...node} {...props} padding={getPaddingLeft(level)} />
      {open && getChildNodes(node).map((childNode, index) => (
        <TreeNode
          {...props}
          node={childNode}
          level={level + 1}
          key={index}
        />
      ))}
    </React.Fragment>
  );
};
TreeNode.propTypes = {
  node: PropTypes.object.isRequired,
  getChildNodes: PropTypes.func.isRequired,
  level: PropTypes.number,
  onToggle: PropTypes.func.isRequired,
  open: PropTypes.bool,
  indentationPadding: PropTypes.number

};
TreeNode.defaultProps = {
  level: 0,
  open: true
};
export default TreeNode;

/* <StyledTreeNode level={level} type={node.type}>
        <NodeIcon onClick={() => onToggle(node)}>
          { node.type === 'folder' && (node.isOpen ? <FaChevronDown /> : <FaChevronRight />) }
        </NodeIcon>

        <NodeIcon marginRight={10}>
          { node.type === 'file' && <FaFile /> }
          { node.type === 'folder' && node.isOpen === true && <FaFolderOpen /> }
          { node.type === 'folder' && !node.isOpen && <FaFolder /> }
        </NodeIcon>

        <span role="button">
          { getNodeLabel(node) }
        </span>
      </StyledTreeNode> */
