import { Emotion } from '@emotion/css/types/create-instance';
import { getEmotionInstance } from './emotion';

/**
 * Helper to inject a global @font-face rule into the document
 */
export function injectFont(
    fontFace: FontFace,
    { emotionInstance = getEmotionInstance() }: InjectFontOptions = {}
): void {
    const fontFaceSrcs = normalizeFontFaceSrcs(fontFace.src);
    const fontFaceDefinitionStrings = fontFaceSrcs.map(src => {
        return `url("${src.url}")${src.format ? ` format("${src.format}")` : ''}`;
    });

    // Eventually want to add the following back as one of the src values
    // once the Goldman Sans version deployed locally is fixed:
    // `local("${fontFace.systemName || fontFace.family}"),
    emotionInstance.injectGlobal({
        '@font-face': {
            fontFamily: fontFace.family,
            src: `${fontFaceDefinitionStrings.join(', ')}`,
            fontWeight: fontFace.weight,
            fontStyle: fontFace.style,
        },
    });
}

export interface InjectFontOptions {
    /**
     * For SSR in Next.js's App Router, we need a context to register the styles
     * for a given SSR request as each SSR request will populate a different
     * Emotion instance with styles. Otherwise defaults to the global Emotion
     * instance.
     *
     * This is handled automatically if using theming to define your fonts.
     */
    emotionInstance?: Emotion;
}

function normalizeFontFaceSrcs(
    src: string | FontFaceSrc | (string | FontFaceSrc)[]
): FontFaceSrc[] {
    if (typeof src === 'string') {
        return [
            {
                url: src,
                format: parseFormat(src),
            },
        ];
    } else if (Array.isArray(src)) {
        return src.map(src => normalizeFontFaceSrcs(src)).reduce((arr, item) => arr.concat(item)); // flatten
    } else {
        return [
            {
                url: src.url,
                format: src.format || parseFormat(src.url),
            },
        ];
    }
}

/**
 * Parses the extension in the url to determine the format of the font.
 *
 * Examples:
 *
 *     .woff  -> woff
 *     .woff2 -> woff2
 *     .ttf   -> truetype
 *     .eot   -> embedded-opentype
 *
 *     // etc.
 */
function parseFormat(url: string): string {
    const extension = url.match(/\.(\w+)$/); // ex: 'GoldmanSans.woff2' -> 'woff2'
    switch (extension && extension[1]) {
        case 'woff':
        case 'woff2':
        case 'svg':
            return extension![1];
        case 'ttf':
            return 'truetype';
        case 'eot':
            return 'embedded-opentype';
        case 'otf':
        case 'otc':
        case 'ttc':
            // case 'ttf': note: opentype was based on its predecessor ttf (truetype) and
            //             sometimes maintains its extension, but we assume ttf to be truetype above
            return 'opentype';
        default:
            return ''; // unknown or couldn't parse
    }
}

/**
 * Represents the properties for an @font-face CSS rule. This can be used with the
 * `injectFont()` function in the style package, but is also used in design-system.
 */
export interface FontFace {
    family: string;

    /**
     * The name of the font as it might be on the local system. Used to create
     * a local("font-system-name") entry if the system name is different than the
     * font family name itself.
     *
     * Defaults to the {@link #family}.
     */
    systemName?: string;

    /**
     * String type is used for variable fonts, for example weight: '1 999'
     */
    weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 950 | string;
    style?: 'normal' | 'italic' | 'oblique';

    /**
     * A single URL, an array of URLs, an object defining both the URL and format,
     * or an array of objects which each define the URLs and formats for the font face.
     *
     * Examples:
     *
     *     src: 'http://fonts.google.com/font-1.woff2'  // auto-detected format as woff2
     *
     *     src: [
     *         'http://fonts.google.com/font-1.woff2',  // auto-detected format as woff2
     *         'http://fonts.google.com/font-1.woff'    // auto-detected format as woff
     *     ]
     *
     *     src: [
     *         'http://fonts.google.com/font-1.woff2',  // auto-detected format as woff2
     *         {
     *             url: 'http://fonts.google.com/font-1.ttf',
     *             format: 'opentype'  // explicit format since .ttf is ambiguous between "truetype" and "opentype"
     *         }
     *     ]
     */
    src: string | FontFaceSrc | (string | FontFaceSrc)[];
}

/**
 * Represents the 'src' property of an @font-face. An @font-face can have multiple
 * src's, each with its own url and `format`. If the `format` is not provided, it is
 * auto-detected from its file extension.
 */
export interface FontFaceSrc {
    url: string;

    /**
     * Optional format. If missing, it is parsed from the file extension in the
     * url.
     */
    format?: string;
}
