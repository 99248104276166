import { PropsWithChildren, FC, useContext, useEffect, CSSProperties } from 'react';
import { FormContext as FormContextProps } from '@gs-ux-uitoolkit-common/form';
import { FormContext } from '@gs-ux-uitoolkit-react/form';
import {
    InputGroupProps as InputGroupCommonProps,
    inputGroupStyleSheet,
    globalInputGroupClass,
} from '@gs-ux-uitoolkit-common/input';
import { cx, useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { componentAnalytics } from './analytics-tracking';

export interface InputGroupProps extends InputGroupCommonProps<CSSProperties> {}

/**
 * InputGroup allows form controls to be extended by grouping inputs, buttons,
 * and other elements into a contiguous visual unit.
 */
export const InputGroup: FC<PropsWithChildren<InputGroupProps>> = props => {
    const { className, disabled, size, status, ...otherProps } = props;

    const formContext = useContext(FormContext);

    const context: FormContextProps = {
        ...formContext,
        disabled: disabled !== undefined ? disabled : formContext.disabled,
        size: size !== undefined ? size : formContext.size,
        status: status !== undefined ? status : formContext.status,
        containedByInputGroup: true,
    };

    const theme = useTheme();
    const cssClasses: any = useStyleSheet(inputGroupStyleSheet, { theme });
    const containerClasses = cx(cssClasses.root, className, globalInputGroupClass);

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({
            componentName: 'input-group',
            officialComponentName: 'other',
        });
    }, []); // Only run once

    return (
        <FormContext.Provider value={context}>
            <div {...otherProps} data-gs-uitk-component="input-group" className={containerClasses}>
                {props.children}
            </div>
        </FormContext.Provider>
    );
};
