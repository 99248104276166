import XLSX from "xlsx-js-style";
import moment from 'moment/moment';

export const attributes = () => {
  return {
    'NAV: Trading': 'navTrading',
    'Nav: Market Value': 'navMarketValue',
    'Daily Factor': 'dailyFactor',
    'Yield: 1-Day': 'yield1Day',
    'Yield: 7-Day Current': 'yield7DayCurrent',
    'Yield: 7-Day Effective': 'yield7DayEffective',
    'Yield: 30-Day': 'yield30Day',
    'Liquid Assets: Daily (%)': 'dailyLiquidAssets',
    'Liquid Assets: Weekly (%)': 'weeklyLiquidAssets',
    'WAM': 'wam',
    'WAL': 'wal',
    'Total Fund Assets (MM)': 'fundAssets',
    'Net Shareholder Flows: Daily (%)': 'netShareholderFlowsDailyPercent',
    'Net Shareholder Flows: Daily (MM)': 'netShareholderFlowsDailyMm'
  };
};


const getFormattedObject = (row, exportData, header, headers) => {
  return {
    Date: moment(row.perfDate).format("DD-MMM-YY"),
    "Fund Name": exportData.shareclass.longName,
    CUSIP: exportData.shareclass.cusip || "-",
    ISIN: exportData.shareclass.isin || "-",
    "BBG ID": exportData.shareclass.bloombergTicker || "-",
    "Fund #": exportData.shareclass.shareClassDetails.displayCode || "-",
    "NASDAQ Ticker": exportData.shareclass.nasdaqTicker || "-",
    [header]: row[headers] || "-"
  };
};

export const getData = (excelExportData, selectedOption, header, headers) => {
  const data = [];
  for(let j=0; j<excelExportData.length; j++){
    const len = excelExportData[j].metrics.length -1;
    const startDate = moment(excelExportData[j].metrics[len].perfDate).format("DD");
    excelExportData[j].metrics.forEach((row, index) => {
      if(selectedOption.label === 'Daily'){
        data.push(getFormattedObject(row, excelExportData[j], header, headers));
      }
      else if(selectedOption.label === 'Weekly' && ((len - index) === 0 || (len - index) % 7 === 0)) {
        data.push(getFormattedObject(row, excelExportData[j], header, headers));
      }
      /* For monthly data frequency, in the given date range, if the start date present in the month, take data
       for the date and if it is not present, take date for the last date of the month  */
      else if(selectedOption.label ==='Monthly') {
        const date = moment(row.perfDate).format("DD");
        if(startDate < '29'){
          if(date === startDate){
            data.push(getFormattedObject(row, excelExportData[j], header, headers));
          }
        }
        else{
          const month = moment(row.perfDate).format("MM");
          const lastDate = moment(month).endOf('month').format("DD");
          if(startDate < lastDate ) {
            if(date === startDate) {
              data.push(getFormattedObject(row, excelExportData[j], header, headers));
            }
          }
          else {
            if(date === lastDate) {
              data.push(getFormattedObject(row, excelExportData[j], header, headers));
            }
          }
        }
      }
    });
  }
  return data;
};

export const setSheetStyle = (data, worksheet, text) => {
  worksheet['!merges'] = [{s: {c: 0, r: 0}, e: {c: 1, r: 0}}, {s: {c: 0, r: data.length+3}, e: {c: 1, r: data.length+3}}, {s: {c: 0, r: data.length+5}, e: {c: 1, r: data.length+5}}];
  worksheet['!rows'] = [];
  worksheet['!rows'][data.length+5] = {hpt: text.length/5};
  const keys = Object.keys(worksheet);
  for (let w=0; w<keys.length; w++){
    if (typeof(worksheet[keys[w]]) === "object") {
      const cell = XLSX.utils.decode_cell(keys[w]);
      if(cell.c === 0 && cell.r === 0) {
        worksheet[keys[w]].s = {font: {bold: true, sz: '14', name: "Arial"}, fill : {bgColor: {rgb:'D0CECE'}, fgColor: {rgb: 'D0CECE'}}};
      }
      else if(cell.r === 1) {
        worksheet[keys[w]].s = {font : {bold: true, sz: '12', name: "Arial"}};
      }
      else if( cell.r === data.length + 3 || cell.r === data.length + 5 ) {
        worksheet[keys[w]].s = {font : {bold: true, sz: '8.5', name: "Arial"},  alignment : {wrapText: true, vertical: 'center'}};
      }
      else {
        worksheet[keys[w]].s = {font : {sz: '8.5', name: "Arial"}};
      }
    }
  }
};

export const getWidth = (data, maxWidths, worksheet) => {
  const rowHeader = Object.keys(data[0]);
  const headerWidths = rowHeader.map(r => r.length);
  const widths = rowHeader.map(row => (data.reduce((w, r) => Math.max(w, r[row].length), 20)));
  maxWidths = maxWidths.map((w, index) => ( Math.max(Math.max(w, widths[index]), headerWidths[index])));
  worksheet['!cols'] = maxWidths.map( w => ({wch: w}));
  return maxWidths;
};

export const setCoverPageSheetStyle = (coverPageData, coverPageWorksheet, allShareClassData) => {
  const keys = Object.keys(coverPageWorksheet);
  for (let w=0; w<keys.length; w++){
    if (typeof(coverPageWorksheet[keys[w]]) === "object") {
      const cell = XLSX.utils.decode_cell(keys[w]);
      if(cell.r === 0) {
        coverPageWorksheet[keys[w]].s = {font: {sz: '48'}};
      }
      else if(cell.r === 1) {
        coverPageWorksheet[keys[w]].s = {font: {sz: '20'}};
      }
      else if( cell.r === 6) {
        coverPageWorksheet[keys[w]].s = {font: {sz: '18'}};
      }
      else if( (cell.r >= 9 && cell.r < 9 + allShareClassData.length) || cell.r === 10 + allShareClassData.length  ) {
        coverPageWorksheet[keys[w]].s = {font: {sz: '16'}, alignment : {wrapText: '1'}};
      }
      else {
        coverPageWorksheet[keys[w]].s = {font : {sz: '12'}};
      }
      if(cell.r ===  coverPageData.length + 10 && cell.c === 1){
        coverPageWorksheet[keys[w]].s = {alignment : {horizontal: 'right'}};
      }
    }
  }
};

export const setDisclaimerStyle = (disclaimerWorksheet, disclaimerData) => {
  disclaimerWorksheet['!merges'] = [{s: {c:0, r: 0}, e: {c: 5, r: 0}}];
  disclaimerWorksheet['!rows'] = [{hpt: (disclaimerData[0].label.length)/5}];
  const keys = Object.keys(disclaimerWorksheet);
  for (let w=0; w<keys.length; w++){
    if (typeof(disclaimerWorksheet[keys[w]]) === "object") {
        disclaimerWorksheet[keys[w]].s = {font : {bold: true, sz: '8.5', name: "Arial"}, alignment : {wrapText: true, vertical: 'center'}};
    }
  }
};

