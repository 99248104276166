import { createContext, HTMLAttributes, ReactNode, FC } from 'react';
import {
    DragAndDropContext,
    Droppable,
    DragAndDropStateUpdateEvent,
    DroppableOverrideClasses,
} from '@gs-ux-uitoolkit-react/drag-and-drop';
import { CardSize } from '@gs-ux-uitoolkit-common/card';

export type ConditionalCardContainerProps =
    | {
          /**
           * Callback for mutating data after drop
           */
          onStateUpdate: (event: DragAndDropStateUpdateEvent) => void;

          /**
           * Enables and disables drag and drop CardContainer
           */
          dragAndDrop: true;
      }
    | {
          /**
           * Callback for mutating data after drop
           */
          onStateUpdate?: (event: DragAndDropStateUpdateEvent) => void;

          /**
           * Enables and disables drag and drop CardContainer
           */
          dragAndDrop?: false;
      };

export type CardContainerProps = HTMLAttributes<HTMLElement> &
    ConditionalCardContainerProps & {
        /**
         * Id for undrling Droppable component
         */
        droppableId?: string;

        /**
         * Direction of cards in the CardContainer
         */
        direction?: 'vertical' | 'horizontal';

        /**
         * Size of the Cards in CardContainer.
         */
        size?: CardSize;

        /**
         * Style classes to override.
         */
        classes?: DroppableOverrideClasses;

        /**
         * Content to display inside the CardContainer.
         */
        children?: ReactNode;
    };

export type CardContainerDefaultProps = Required<
    Pick<CardContainerProps, 'onStateUpdate' | 'dragAndDrop' | 'direction' | 'size'>
>;

export const cardContainerDefaultProps: CardContainerDefaultProps = {
    onStateUpdate: () => {},
    size: 'md',
    dragAndDrop: false,
    direction: 'vertical',
};

export const CardContainer: FC<CardContainerProps> = ({
    children,
    size = cardContainerDefaultProps.size,
    direction = cardContainerDefaultProps.direction,
    onStateUpdate = cardContainerDefaultProps.onStateUpdate,
    dragAndDrop = cardContainerDefaultProps.dragAndDrop,
    droppableId,
    classes,
    ...attributes
}) => {
    return (
        <CardContainerContext.Provider value={{ dragAndDrop, size }}>
            <DragAndDropContext
                onStateUpdate={onStateUpdate}
                spacing={{ sm: 4, md: 8, lg: 16 }[size]}
                direction={direction}
            >
                <Droppable
                    droppableId={droppableId}
                    data-gs-uitk-component="card-container"
                    data-size={size}
                    classes={classes}
                    {...attributes}
                >
                    {children}
                </Droppable>
            </DragAndDropContext>
        </CardContainerContext.Provider>
    );
};

export const CardContainerContext = createContext<{ dragAndDrop: boolean; size?: CardSize }>({
    dragAndDrop: false,
});
