import React from 'react';

const CapabilityRendererWithOnlyIcon = params => {

  const {
    dynamicIcons,
    newCapabilities: {
      canView: newCanview = '',
      trade: { canTrade: newCanTrade = '' } = {},
      approver: { canApprove: newCanApprove = '' } = {},
    } = params.data.capabilities || {},
  } = params.data;

  const includesLevel = [0, 1, 3].includes(params.node.level);
  const nodeSelected = params.node.selected && !params.data.disabled;

  return (
    <span>
      {includesLevel && dynamicIcons ? (
        <span className='accounts__editSelectedIcons'>
          {newCanview && (
            <span
              className={includesLevel ? (nodeSelected ? 'accounts__editSelectedIcons--viewSelected' : 'accounts__editSelectedIcons--view') : ''}
            />
          )}
          {newCanTrade && (
            <span
              className={includesLevel ? (nodeSelected ? 'accounts__editSelectedIcons--tradeSelected' : 'accounts__editSelectedIcons--trade') : ''}
            />
          )}
          {newCanApprove && (
            <span
              className={
                includesLevel ? (nodeSelected ? 'accounts__editSelectedIcons--approverSelected' : 'accounts__editSelectedIcons--approver') : ''
              }
            />
          )}
        </span>
      ) : null}
    </span>
  );
};

export default CapabilityRendererWithOnlyIcon;
