import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './tooltip-target-theme-overrides';

export interface TooltipTargetStyleSheetProps {
    theme: Theme;
    inline?: boolean;
}

export interface TooltipTargetCssClasses {
    root: string;
}

export type TooltipTargetStyledClasses = CssClassDefinitionsObject<keyof TooltipTargetCssClasses>;

export interface TooltipTargetStyleOverridesParams {
    props: DeepReadonly<TooltipTargetStyleSheetProps>;
    createDefaultStyledClasses: () => TooltipTargetStyledClasses;
}

export const tooltipTargetStyleSheet = new StyleSheet(
    'tooltip-target',
    (props: TooltipTargetStyleSheetProps) => {
        return createComponentClassDefinitions<
            TooltipTargetStyleSheetProps,
            TooltipTargetStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.tooltipTarget);
    }
);

function createDefaultStyledClasses({
    inline,
}: TooltipTargetStyleSheetProps): TooltipTargetStyledClasses {
    return {
        root: {
            // 'table-cell' allows the TooltipTarget to have a width based
            // on its content (instead of defaulting to 100%) while still
            // behaving as 'block'.
            display: inline ? 'inline-block' : 'table-cell',
        },
    };
}
