import { TextTypography } from '@gs-ux-uitoolkit-common/design-system';
import { TextCssClasses } from './text-style-sheet';
import { TypographyCommonProps } from './typography-common-props';
export interface TextCommonProps extends TypographyCommonProps {
    /**
     * Typography variant of the GS Design System.
     *
     * View the Typography page in the Styling Foundation section of the UI Toolkit docs
     * for more info on when to use each variant.
     */
    typography?: TextTypography;

    /**
     * Style classes to override.
     */
    classes?: Partial<TextCssClasses>;
}

export const defaultTextProps: DefaultTextProps = {
    typography: 'body02',
    color: 'primary',
    display: 'inline-block',
    bold: false,
    italic: false,
    underline: false,
};

export type DefaultTextProps = Required<
    Pick<TextCommonProps, 'typography' | 'color' | 'bold' | 'italic' | 'underline' | 'display'>
>;
