import { arrayHelper } from '@gs-ux-uitoolkit-common/datacore';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as Redux from 'redux';
import { ButtonGroup } from '../../../components/buttons/buttons-group';
import { PluginHeader } from '../../../components/plugin-header/plugin-header';
import { ManagementTable, TableHeadings } from '../../../components/table/management-table';
import { ModalWithItems, ModalProps } from '../../../components/modal-props';
import {
    CancelEditedKeyboardShortcut,
    RemoveKeyboardShortcut,
    SaveEditedKeyboardShortcut,
    UpdateEditedKeyboardShortcut,
    NewKeyboardShortcut,
    EditKeyboardShortcut,
} from '../../../redux/actions/keyboard-shortcut-action';
import { DataGridState } from '../../../redux/datagrid-state';
import { KeyboardShortcut } from '../keyboard-shortcut';
import { KeyboardShortcutsScreen } from './component/keyboard-shortcuts-screen';
import { keyboardShortcutStylesheet } from '../../../style/plugins/keyboard-shortcuts-stylesheet';
import { PluginFooter } from '../../../components/plugin-footer/plugin-footer';
import { ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';

/**
 * The props for the KeyboardShortcutModalComponent
 */
export interface KeyboardShortcutModalProps
    extends ModalProps<KeyboardShortcut>,
        ModalWithItems<KeyboardShortcut> {
    data: KeyboardShortcut | null;
}

/**
 * * The main custom sort modal
 */
export class KeyboardShortcutModalComponent extends Component<KeyboardShortcutModalProps> {
    public componentWillUnmount() {
        keyboardShortcutStylesheet.unmount(this);
    }
    public render() {
        const sortedConfigItemList = arrayHelper.sort(this.props.configItemList, false, 'key');
        const shortcutKeyTaken = this.props.configItemList.map(es => es.key);
        const keyboardShortcutElements = sortedConfigItemList.map(es => {
            const editCustomSort = () => this.props.editConfigItem(es);
            const removeCustomSort = () => this.props.removeConfigItem(es);
            return (
                <tr key={es.key}>
                    <td>{es.key}</td>
                    <td>{es.operation}</td>
                    <td>{es.value}</td>
                    <td>
                        <ButtonGroup editClick={editCustomSort} deleteClick={removeCustomSort} />
                    </td>
                </tr>
            );
        });
        const keyboardShortcutHeading: TableHeadings[] = [
            {
                label: 'Key',
                props: {
                    className: 'key-column',
                },
            },
            {
                label: 'Operation',
                props: {
                    className: 'operation-column',
                },
            },
            {
                label: 'Value',
                props: {
                    className: 'value-column',
                },
            },
            {
                label: '',
                props: {
                    className: 'action-column',
                },
            },
        ];
        return (
            <ThemeConsumer>
                {theme => {
                    const cssClasses = keyboardShortcutStylesheet.mount(this, { theme });
                    return (
                        <div
                            className={`${cssClasses.root} config-items-container gs-uitk-keyboard-shortcut-plugin`}
                        >
                            {!this.props.data ? (
                                <Fragment>
                                    <PluginHeader
                                        displayCreateButton={true}
                                        createButtonCallback={this.props.newConfigItem}
                                        subTitle="Current Keyboard Shortcuts"
                                    />
                                    <ManagementTable
                                        headingConfig={keyboardShortcutHeading}
                                        bodyRowElements={keyboardShortcutElements}
                                    />
                                </Fragment>
                            ) : null}

                            {this.props.data ? (
                                <Fragment>
                                    <KeyboardShortcutsScreen
                                        data={this.props.data}
                                        shortcutKeyTaken={shortcutKeyTaken}
                                        updateEditedConfigItem={this.props.updateEditedConfigItem}
                                    ></KeyboardShortcutsScreen>
                                    <PluginFooter
                                        createButtonDisplayName="Save"
                                        createActionType="primary"
                                        onCancel={this.props.cancelEditedConfigItem}
                                        onCreate={this.props.saveEditedConfigItem}
                                        cancelButtonDisplayName="Discard"
                                    ></PluginFooter>
                                </Fragment>
                            ) : null}
                        </div>
                    );
                }}
            </ThemeConsumer>
        );
    }
}

const mapStateToProps = (state: DataGridState) => {
    return {
        configItemList: state.keyboardShortcut.configItemList,
        data: state.keyboardShortcut.editedItem,
    };
};

const mapDispatchToProps = (dispatch: (action: Redux.Action) => void) => {
    return {
        cancelEditedConfigItem: () => dispatch(CancelEditedKeyboardShortcut()),
        editConfigItem: (keyboardShortcut: KeyboardShortcut) =>
            dispatch(EditKeyboardShortcut(keyboardShortcut)),
        newConfigItem: () => dispatch(NewKeyboardShortcut()),
        removeConfigItem: (keyboardShortcut: KeyboardShortcut) =>
            dispatch(RemoveKeyboardShortcut(keyboardShortcut)),
        saveEditedConfigItem: () => dispatch(SaveEditedKeyboardShortcut()),
        updateEditedConfigItem: (newData: Partial<KeyboardShortcut>) =>
            dispatch(UpdateEditedKeyboardShortcut(newData)),
    };
};

export const KeyboardShortcutPluginModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(KeyboardShortcutModalComponent);
