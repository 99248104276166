import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@gs-ux-uitoolkit-react/modal';
import { Button } from '@gs-ux-uitoolkit-react/button';
import PropTypes from 'prop-types';
import translator from '../../../../../services/translator';
import './index.scss';

const { translate: t } = translator;

function MimicModal({ modalMsg, closeModal, showCancelBtn, handleMimicOkBtnClick }) {
  return (
    <Modal visible={true} placement='center' className='gs-uitk-override-css ssaMimicModal'>
      <ModalHeader onDismissButtonClick={closeModal} className='ssaMimicModal__header'>
        {t('tkWarning')}
      </ModalHeader>
      <ModalBody className='ssaMimicModal__body'>{t(modalMsg)}</ModalBody>
      <ModalFooter className='ssaMimicModal__footer'>
        {showCancelBtn && (
          <Button actionType='secondary' onClick={closeModal}>
            {t('tkCancelModal')}
          </Button>
        )}
        <Button actionType='primary' onClick={showCancelBtn ? handleMimicOkBtnClick : closeModal}>
          {t('tkOk')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

MimicModal.propTypes = {
  modalMsg: PropTypes.string,
  closeModal: PropTypes.func,
  showCancelBtn: PropTypes.bool,
  handleMimicOkBtnClick: PropTypes.func,
};

export default MimicModal;
