import { useContext, useEffect, useRef } from 'react';
import { StyleSheet, CssClasses, getEmotionInstance } from '@gs-ux-uitoolkit-common/style';
import { EmotionInstanceContext } from './emotion-instance-context';

/**
 * React Hook function to mount and unmount a StyleSheet in a functional manner.
 *
 * @param jsStyleSheet The object that holds the definitions of the CSS classes.
 * @param props The object with the properties to fill in dynamic values in the
 *   `classDefinitions`. These are properties which are function values, where the
 *   functions will be called with this object to produce a final value.
 * @return An object keyed by the CSS class names (the same keys returned from the
 *   `stylesFactory`), whose values are the generated CSS class names that now exist
 *   in the DOM. This object also contains a special 'cx' (classnames) method which is
 *   aware of the CSS classes being generated and can be used to combine them correctly
 *   and in the correct order.
 */
export function useStyleSheet<ClassNames extends string, Props extends object | null>(
    jsStyleSheet: StyleSheet<ClassNames, Props>,
    props: Props
): CssClasses<ClassNames> {
    const emotionInstance = useContext(EmotionInstanceContext) || getEmotionInstance(); // use global Emotion instance if none provided on context
    const stylesheetRef = useRef<StyleSheet<ClassNames, Props>>(jsStyleSheet);

    // The componentKey is just an object to allow the StyleSheet to keep track
    // of mounted/unmounted state, since function components don't have an instance
    // reference we can naturally use
    const componentKey = useRef<object>({});

    // Handle if a different `jsStyleSheet` is passed in than the last one used. We'll
    // unmount the previous one before mounting the new one in this case
    if (stylesheetRef.current !== jsStyleSheet) {
        stylesheetRef.current.unmount(componentKey);
        stylesheetRef.current = jsStyleSheet;
    }

    // Note: no need for `useMemo()` here, and actually, we don't want to use it
    // because the `props` object will always be a different object reference
    // unless `props` itself is memoized by the component (which we generally do not do).
    // However, there is still no need for `useMemo()` here because the `StyleSheet.mount()`
    // does its own memoization based on the shallow set of properties in the object in
    // an efficient manner
    const classes = stylesheetRef.current.mount(componentKey, props, emotionInstance);

    // useEffect() so we clean up after the component is destroyed
    useEffect(() => {
        return () => {
            stylesheetRef.current.unmount(componentKey);
        };
    }, []); // note: no need for any values in 2nd arg array here because if the jsStyleSheet reference is changed, it is cleaned up above

    return classes;
}
