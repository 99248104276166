/**
 * Function used to set multiple attributes on an HTML element.
 * @param element The element to set the attributes on.
 * @param attributes The attributes to set on the {@link element}. This should be an object where
 *    the key is the attribute name and the value is attribute value.
 */
export function setAttributes(element: HTMLElement, attributes: { [key: string]: any }): void {
    for (const key in attributes) {
        element.setAttribute(key, attributes[key]);
    }
}
