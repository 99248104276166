import { Expression, genericExpressionHelper } from '@gs-ux-uitoolkit-common/datacore';
import { Component } from 'react';
import { ExpressionBuilder } from '../../../../components/expression/expression-builder';
import { GridColumn, GetFormattedValueFromColumn } from '../../../../grid-wrappers/grid-wrapper';
import { RowCustomisation } from '../../row-customisation';

export interface Select2Interface {
    value: string;
}

/**
 * The props for the RowCustomisationExpressionBuilder component
 */
export interface RowCustomisationExpressionBuilderProps {
    /**
     * Columns from the grid
     */
    columns: GridColumn[];
    gridGetColumnValueList: (columnId: string) => void;
    /**
     * Reset the column valueList in the store
     */
    gridResetColumnValueList: (columnId: string | null) => void;
    /**
     * RowCustomisation data
     */
    data: RowCustomisation;
    /**
     * Update the state with the new data from the state
     */
    updatePartialData: (newData: Partial<RowCustomisation>) => void;
    /**
     * Callback with validation state
     */
    onValidate: (isValid: boolean) => void;
    getFormattedValueFromColumn: GetFormattedValueFromColumn;
}

/**
 * Component to build the expression
 */
export class RowCustomisationExpressionBuilder extends Component<RowCustomisationExpressionBuilderProps> {
    public componentDidMount() {
        this.props.onValidate(genericExpressionHelper.isValid(this.props.data.expression.query));
    }
    public render() {
        return (
            <ExpressionBuilder
                columns={this.props.columns}
                onChangeExpression={this.onChangeExpression}
                expression={this.props.data.expression}
                gridGetColumnValueList={this.props.gridGetColumnValueList}
                gridResetColumnValueList={this.props.gridResetColumnValueList}
                getFormattedValueFromColumn={this.props.getFormattedValueFromColumn}
                showRowGroups={true}
            />
        );
    }
    private onChangeExpression = (expression: Expression) => {
        this.props.updatePartialData({ expression });
        this.props.onValidate(genericExpressionHelper.isValid(expression.query));
    };
}
