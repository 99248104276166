import { Button, ButtonGroup } from '@gs-ux-uitoolkit-react/button';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { WidgetContentCommonProps } from '@gs-ux-uitoolkit-common/datacore';
import { Context, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { connect, ReactReduxContextValue } from 'react-redux';
import {
    CreateSavedView,
    SavedViewActionTypes,
    SaveSavedView,
    SetSavedView,
    UpdateSavedViewForm,
} from '../../../redux/actions/saved-view-action';
import { DataGridState } from '../../../redux/datagrid-state';
import { SavedView, SavedViewConfig, SavedViewFormState, SavedViewOptions } from '../saved-view';
import { Dropdown, DropdownMenu, DropdownButton } from '@gs-ux-uitoolkit-react/input';
import { savedViewsWidgetStylesheet } from '../../../style/plugins/saved-views-widget-stylesheet';
import { CreateSavedViewsMenu } from './widget/create-saved-views-menu';
import { Select, SelectChangeEvent } from '@gs-ux-uitoolkit-react/select';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { debounce } from 'gs-uitk-lodash';

export interface SavedViewPluginWidgetProps extends WidgetContentCommonProps {
    createSavedView: (savedViewOption: SavedViewOptions, name: string) => void;
    saveSavedView: (savedView: SavedView) => void;
    setSavedView: (name: string | null) => void;
    updateSavedViewForm: (savedViewForm: SavedViewFormState) => void;
    setGridStateChange: (onGridStateCallback: undefined | (() => void)) => void;
    savedViewList: SavedView[];
    currentSavedView: string;
    savedViewConfig: SavedViewConfig | undefined;
    savedViewForm: SavedViewFormState;
}

export interface SavedViewConfigUI {
    checked: boolean;
    disabled: boolean;
    visible: boolean;
}

export interface SaveViewsState {
    gridStateHasChanged: boolean;
}

export const SavedViewPluginWidgetComponent = ({
    createSavedView,
    saveSavedView,
    setSavedView,
    setGridStateChange,
    savedViewList,
    currentSavedView,
    savedViewConfig,
    savedViewForm,
    widgetContentCommonProps,
}: SavedViewPluginWidgetProps) => {
    const dropdownRef = useRef<Dropdown | null>(null);
    const size = widgetContentCommonProps?.size || 'sm';
    const [gridStateHasChanged, setGridStateHasChanged] = useState(false);
    const cssClasses = useStyleSheet(savedViewsWidgetStylesheet, { size });

    const savedViewAutoSave = useMemo(
        () => savedViewList.find(({ name }) => name === currentSavedView),
        [savedViewList, currentSavedView]
    );

    const isStateChangeSavable = useMemo(
        () =>
            gridStateHasChanged &&
            savedViewAutoSave &&
            !savedViewAutoSave?.savedViewOptions?.readOnly,
        [savedViewAutoSave?.savedViewOptions?.readOnly, gridStateHasChanged] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(() => {
        setGridStateChange(() => setGridStateHasChanged(true));
        return () => {
            setGridStateChange(undefined);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const savedView = savedViewAutoSave?.savedViewOptions?.autoSave;
        if (gridStateHasChanged && savedView?.enabled) {
            autoSaveStateChange(savedView?.debounceTime);
        }
    }, [gridStateHasChanged]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSaveClicked = useCallback(() => {
        setGridStateHasChanged(false);
        if (savedViewAutoSave) saveSavedView(savedViewAutoSave);
    }, [savedViewAutoSave, saveSavedView]);

    const autoSaveStateChange = useCallback(
        (debounceTime: number | undefined) => {
            const debouncedSave = debounce(onSaveClicked, debounceTime || 2000);

            if (isStateChangeSavable) {
                debouncedSave();
            }
        },
        [isStateChangeSavable] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const onChange = useCallback(
        (event: SelectChangeEvent) => {
            setGridStateHasChanged(false);
            setSavedView(event.selectedValue);
        },
        [setSavedView]
    );

    const options = useMemo(
        () =>
            savedViewList.map(({ name }) => ({
                label: name,
                value: name,
            })),
        [savedViewList]
    );

    const onCreateSavedView = useCallback(
        (savedViewOption: SavedViewOptions, name: string) => {
            createSavedView(savedViewOption, name);
            setGridStateHasChanged(false);
            dropdownRef?.current?.hideMenu();
        },
        [createSavedView]
    );

    return (
        <div
            className={`saved-view-widget ${cssClasses.root}`}
            data-cy="gs-uitk-datagrid-saved-view-widget"
        >
            <ButtonGroup>
                <Dropdown
                    className="gs-uitk-saved-views-widget__add-dropdown"
                    data-cy="gs-uitk-saved-views-widget__add-dropdown"
                    ref={dropdownRef}
                >
                    <DropdownButton
                        actionType="secondary"
                        emphasis="minimal"
                        size={size}
                        className="gs-uitk-saved-views-widget__add_button"
                    >
                        <Icon name="add" type="filled" />
                    </DropdownButton>
                    <DropdownMenu
                        style={{ marginRight: '8px' }}
                        data-cy="gs-uitk-saved-views-widget__dropdown-menu"
                    >
                        <CreateSavedViewsMenu
                            savedViewConfig={savedViewConfig}
                            savedViewForm={savedViewForm}
                            savedViewList={savedViewList}
                            createSavedView={onCreateSavedView}
                        ></CreateSavedViewsMenu>
                    </DropdownMenu>
                </Dropdown>
                <Select
                    className="gs-uitk-saved-views-widget__select"
                    data-cy="gs-uitk-saved-views-widget__select"
                    options={options}
                    onChange={onChange}
                    size={size}
                    selectedValue={currentSavedView}
                    noOptionsContent={`Use + to save view`}
                    clearable={false}
                />
                <Button
                    actionType="secondary"
                    emphasis="minimal"
                    size={size}
                    className="gs-uitk-saved-views-widget__save_button"
                    disabled={!isStateChangeSavable}
                    onClick={onSaveClicked}
                >
                    <Icon name="save" type="filled" />
                </Button>
            </ButtonGroup>
        </div>
    );
};

const mapStateToProps = (state: DataGridState) => {
    return {
        currentSavedView: state.savedView.currentSavedView || '',
        savedViewConfig: state.savedView.savedViewConfig,
        savedViewList: state.savedView.savedViewList,
        savedViewForm: state.savedView.savedViewForm,
    };
};

export const getSavedViewPluginWidget = (
    setGridStateChange: (onGridStateCallback: undefined | (() => void)) => void,
    context?: Context<ReactReduxContextValue>
) => {
    const mapDispatchToProps = (dispatch: (action: SavedViewActionTypes) => void) => {
        return {
            setGridStateChange,
            createSavedView: (savedViewOption: SavedViewOptions, savedViewName: string) => {
                dispatch(CreateSavedView(savedViewOption, savedViewName));
            },
            saveSavedView: (savedView: SavedView) => {
                dispatch(SaveSavedView(savedView));
            },
            setSavedView: (name: string | null) => {
                dispatch(SetSavedView(name));
            },
            updateSavedViewForm: (savedViewForm: SavedViewFormState) => {
                dispatch(UpdateSavedViewForm(savedViewForm));
            },
        };
    };
    const SavedViewPluginWidget = connect(mapStateToProps, mapDispatchToProps, null, { context })(
        SavedViewPluginWidgetComponent
    );

    return SavedViewPluginWidget;
};
