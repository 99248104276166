import React, { useState, useEffect } from 'react';
import { Checkbox } from '@gs-ux-uitoolkit-react/checkbox';
import { Input } from '@gs-ux-uitoolkit-react/input';
import { Select } from '@gs-ux-uitoolkit-react/select';
import PropTypes from 'prop-types';
import { APPROVE, TRADE, VIEW } from '../../../constants';
import translator from '../../../../../../services/translator';

const { translate: t } = translator;

const CapabilitiesRow = ({ label, capabilities = {}, capabilitiesUpdater, allowTradeApproval, selectedNodes }) => {
  const { canView, trade: { canTrade, tradeLimit, tradeLevel } = {}, approver: { canApprove, approverLimit, approverLevel } = {} } = capabilities;

  const getLimits = () => {
    if (label === TRADE) return tradeLimit;
    if (label === APPROVE) return approverLimit;
  };
  const checkCapabilities = () => {
    if (label === VIEW) return canView;
    if (label === TRADE) return canTrade;
    if (label === APPROVE) return canApprove;
    return false;
  };

  const [isChecked, setIsChecked] = useState(checkCapabilities);
  const [tradeLimitInput, setTradeLimitInput] = useState(getLimits);
  const [approverLimitInput, setApproverLimitInput] = useState(getLimits);

  useEffect(() => {
    setIsChecked(checkCapabilities());
  }, [capabilities]);

  const renderLabel = () => {
    if (false) {
      if (label === VIEW) return label;
      if (label === TRADE) return isChecked ? t('tkTradeUpTo') : label;
      if (label === APPROVE) return isChecked ? t('tkApproveTradesUpTo') : label;
    }
    return label;
  };

  const handleCheckBoxChange = checked => {
    setIsChecked(checked);
    if (label === VIEW) {
      capabilitiesUpdater({ ...capabilities, canView: checked });
    }
    if (label === TRADE) {
      capabilitiesUpdater({ ...capabilities, ...(checked ? { canView: checked } : {}), trade: { ...capabilities.trade, canTrade: checked } });
    }
    if (label === APPROVE) {
      capabilitiesUpdater({ ...capabilities, ...(checked ? { canView: checked } : {}), approver: { ...capabilities.approver, canApprove: checked } });
    }
  };

  const tradeOptions = [
    { label: t('tkNoApprovalRequired'), value: 'DEFAULT' },
    { label: t('tkLevel1ApprovalRequired'), value: 'L1' },
    { label: t('tkLevel2ApprovalRequired'), value: 'L2' },
    { label: t('tkLevel3ApprovalRequired'), value: 'L3' },
    { label: t('tkLevel4ApprovalRequired'), value: 'L4' },
    { label: t('tkLevel5ApprovalRequired'), value: 'L5' },
  ];

  const approvalOptions = [
    { label: t('tkLevel1Approver'), value: 'L1' },
    { label: t('tkLevel2Approver'), value: 'L2' },
    { label: t('tkLevel3Approver'), value: 'L3' },
    { label: t('tkLevel4Approver'), value: 'L4' },
    { label: t('tkLevel5Approver'), value: 'L5' },
  ];

  const handleInputChange = e => {
    if (label === TRADE) {
      setTradeLimitInput(e.target.value);
      capabilitiesUpdater({ ...capabilities, trade: { ...capabilities.trade, tradeLimit: e.target.checked } });
    }
    if (label === APPROVE) {
      setApproverLimitInput(e.target.value);
      capabilitiesUpdater({ ...capabilities, approver: { ...capabilities.approver, approverLimit: e.target.checked } });
    }
  };

  const handleDropdownChange = ({ selectedValue }) => {
    if (label === TRADE) {
      capabilitiesUpdater({ ...capabilities, trade: { ...capabilities.trade, tradeLevel: selectedValue } });
    }
    if (label === APPROVE) {
      capabilitiesUpdater({ ...capabilities, approver: { ...capabilities.approver, approverLevel: selectedValue } });
    }
  };

  return (
    <div className='capabilitiesRowContainer'>
      <div className='capabilitiesRowContainer__checkbox'>
        <Checkbox
          checked={isChecked}
          onChange={() => handleCheckBoxChange(!isChecked)}
          disabled={
            (label === VIEW && (canTrade || canApprove)) ||
            (label === APPROVE && allowTradeApproval && selectedNodes.length === 1 && selectedNodes[0].level)
          }
        />
      </div>
      <div className='capabilitiesRowContainer__label'>{renderLabel()}</div>
      {label !== VIEW && isChecked && (
        <React.Fragment>
          {false && (
            <div className='capabilitiesRowContainer__input'>
              <Input
                placeholder={label === TRADE ? t('tkNoTradeLimit') : t('tkNoApprovalLimit')}
                value={label === TRADE ? tradeLimitInput : approverLimitInput}
                onChange={e => handleInputChange(e)}
                disabled
              />
            </div>
          )}
          <div className='capabilitiesRowContainer__dropdown'>
            <Select
              options={label === TRADE ? tradeOptions : approvalOptions}
              selectedValue={label === TRADE ? tradeLevel : approverLevel}
              onSelect={val => handleDropdownChange(val)}
              clearable={false}
              disabled={allowTradeApproval && selectedNodes.length === 1 && selectedNodes[0].level}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

CapabilitiesRow.propTypes = {
  label: PropTypes.string,
  capabilities: PropTypes.object,
  capabilitiesUpdater: PropTypes.func,
  allowTradeApproval: PropTypes.bool,
  selectedNodes: PropTypes.array,
};

export default CapabilitiesRow;
