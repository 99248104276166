import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import AppHeader from './components/AppHeader';
import AutoComplete from './components/AutoComplete';
import DashboardCards from './components/DashboardCards';
import FirmAndFreeTextSearch from './components/FirmAndFreeTextSearch';
import SnackBarProvider from './context/provider/SnackBarProvider';
import UserMaintenanceWithProvider from './components/UserMaintenanceWithProvider';
import WithPageError from '../../../containers/WithPageError';
import UserSearchCustomView from './components/CustomView';
import { ThreeSquaresLoader } from '../../../components/core/Loaders';
import { Conditional } from '../../../components/core/Conditional';
import { isMimicSessionSelector } from '../../../selectors/app';
import { isInternalUserSelector } from '../../../selectors/user';
import { isLoading } from '../../../selectors/pages';
import {
  getUserDetails,
  getDropdownData,
  getCurrentPage,
  showDetailsGrid,
  getOrganizationDetails,
  getPageKey,
  isSsaAdminSelector,
  fundsTabGridHeader,
  profileChgMgmtGridHeader,
  accountsChgMgmtGridHeader,
} from './selectors';
import { PAGES } from './reducers';
import { MODULES_KEYS } from '../../../constants/pageConstants';
import './index.scss';

const UserSearch = ({
  currentPage,
  isShowDetailsGrid,
  isInternalUser,
  dispatch,
  userDetails,
  editModeDropdownData,
  loading,
  organizationDetails,
  fundsColumnDefs,
  profileChgMgmtDefs,
  accountsChgMgmtDefs,
  pageKey,
  isSsaAdmin,
  isMimicSessionActive,
}) => {
  const showLandingPageCls = classNames('usersearch', { 'hide-page': currentPage === PAGES.DETAILS_PAGE, firmAndFreeTextSearch: isShowDetailsGrid });

  return (
    <WithPageError>
      <SnackBarProvider>
        <div className='js-main-content main-container__content gs-uitk-override-css'>
          <UserSearchCustomView />
          <section data-testid='user-search-page' className={showLandingPageCls} id='user-search'>
            <AppHeader />
            <div className='usersearch-autocomplete'>
              <AutoComplete />
            </div>
            {isShowDetailsGrid ? (
              <FirmAndFreeTextSearch isShowDetailsGrid={isShowDetailsGrid} />
            ) : (
              currentPage === PAGES.LANDING_PAGE && <DashboardCards />
            )}
          </section>
          {currentPage === PAGES.DETAILS_PAGE && (
            <Conditional condition={loading}>
              <ThreeSquaresLoader />
              <UserMaintenanceWithProvider
                storeValues={{
                  isInternalUser,
                  isSsaAdmin,
                  editModeDropdownData,
                  fundsColumnDefs,
                  pageKey,
                  profileChgMgmtDefs,
                  accountsChgMgmtDefs,
                  isMimicSessionActive,
                }}
                userDetails={userDetails}
                organizationDetails={organizationDetails}
                reduxDispatch={dispatch}
              />
            </Conditional>
          )}
        </div>
      </SnackBarProvider>
    </WithPageError>
  );
};

const mapStateToProps = state => ({
  isSsaAdmin: isSsaAdminSelector(state),
  isMimicSessionActive: isMimicSessionSelector(state),
  currentPage: getCurrentPage(state),
  isShowDetailsGrid: showDetailsGrid(state),
  isInternalUser: isInternalUserSelector(state),
  userDetails: getUserDetails(state),
  loading: isLoading(state),
  editModeDropdownData: getDropdownData(state, MODULES_KEYS.USER_SEARCH),
  fundsColumnDefs: fundsTabGridHeader(state, MODULES_KEYS.USER_SEARCH),
  profileChgMgmtDefs: profileChgMgmtGridHeader(state, MODULES_KEYS.USER_SEARCH),
  accountsChgMgmtDefs: accountsChgMgmtGridHeader(state, MODULES_KEYS.USER_SEARCH),
  organizationDetails: getOrganizationDetails(state),
  pageKey: getPageKey(state),
});

const mapDispatchToProps = dispatch => ({ dispatch });

UserSearch.propTypes = {
  isSsaAdmin: PropTypes.bool,
  isMimicSessionActive: PropTypes.bool,
  currentPage: PropTypes.string,
  isShowDetailsGrid: PropTypes.bool,
  isInternalUser: PropTypes.bool,
  dispatch: PropTypes.func,
  userDetails: PropTypes.object,
  editModeDropdownData: PropTypes.object,
  loading: PropTypes.bool,
  organizationDetails: PropTypes.object,
  pageKey: PropTypes.string,
  fundsColumnDefs: PropTypes.array,
  profileChgMgmtDefs: PropTypes.array,
  accountsChgMgmtDefs: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSearch);
