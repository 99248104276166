/* eslint-disable react-hooks/exhaustive-deps */
// TODO: https://jira.site.gs.com/browse/UX-14776

import {
    FunctionComponent,
    ReactNode,
    useContext,
    useState,
    useRef,
    useEffect,
    CSSProperties,
    HTMLAttributes,
} from 'react';

import PropTypes from 'prop-types';
import { Checkbox, CheckboxChangeEvent } from '@gs-ux-uitoolkit-react/checkbox';
import {
    getCheckboxOptionClass,
    getCheckboxOptionComponentClass,
    MenuCheckboxOptionProps as MenuCheckboxOptionCommonProps,
    menuCheckboxOptionStyleSheet,
    defaultMenuProps,
} from '@gs-ux-uitoolkit-common/menu';
import { KeyHelpers } from '@gs-ux-uitoolkit-react/shared';
import { MenuContext } from './menu-context';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';

export interface MenuCheckboxOptionProps
    extends MenuCheckboxOptionCommonProps<CSSProperties>,
        Omit<HTMLAttributes<HTMLElement>, 'checked' | 'defaultChecked' | 'onChange'> {
    children?: ReactNode;
}

export const MenuCheckboxOption: FunctionComponent<MenuCheckboxOptionProps> = (
    props: MenuCheckboxOptionProps
) => {
    const {
        children = '',
        disabled,
        size = defaultMenuProps.size,
        checked,
        defaultChecked,
        value,
        className,
        classes: overrideClasses,
        ...otherProps
    } = props;

    const menuContext = useContext(MenuContext);
    const hostRef = useRef<HTMLDivElement>(null);
    const theme = useTheme();
    const cssClasses = useStyleSheet(menuCheckboxOptionStyleSheet, {
        theme,
        size: size || menuContext.size,
    });

    function updateChecked(checked: boolean) {
        updateContextSelected(checked);
    }

    function updateContextSelected(isChecked: boolean) {
        if (isChecked) {
            menuContext.optionSelect(getValue());
        } else {
            menuContext.optionDeselect(getValue());
        }
    }

    function onCheckboxChange(event: CheckboxChangeEvent) {
        updateChecked((event.target as HTMLInputElement).checked);
    }

    const onHostKeyDown = (event: KeyboardEvent) => {
        const { SPACE, ENTER } = KeyHelpers.keyCode;
        if (event.which === SPACE || event.which === ENTER) {
            updateChecked(!isSelected());
            event.preventDefault();
        }
    };

    const [valueForSelected, setValueForSelected] = useState(value || '');

    function isSelected() {
        return menuContext.values.has(value || valueForSelected);
    }

    function getValue(): string | number {
        return value || valueForSelected;
    }

    // If props.value is not set, then label text is used in place of props.value.
    // However, it may not have rendered yet. This useEffect will catch the
    // just-rendered label text and, only if it needs to, force a new render
    // which will update the proxy value and therefore the selected state.
    useEffect(() => {
        if (
            !(value != null) &&
            hostRef.current &&
            hostRef.current.textContent !== valueForSelected
        ) {
            setValueForSelected(hostRef.current.textContent || '');
        }
    }, [menuContext.values, value]);

    function getCheckboxOptionClassName() {
        return getCheckboxOptionClass({
            cssClasses,
            className,
            overrideClasses,
        });
    }

    function getCheckboxOptionComponentClassName() {
        return getCheckboxOptionComponentClass({
            cssClasses,
            overrideClasses,
        });
    }

    function isDisabled() {
        return disabled !== undefined
            ? disabled
            : menuContext.disabled !== undefined
              ? menuContext.disabled
              : false;
    }

    useEffect(() => {
        if (hostRef.current) {
            hostRef.current.addEventListener('keydown', onHostKeyDown);
        }
        return () => {
            if (hostRef.current) {
                hostRef.current.removeEventListener('keydown', onHostKeyDown);
            }
        };
    }, [hostRef.current]);

    useEffect(() => {
        if (typeof checked === 'boolean') {
            updateChecked(checked);
        }
    }, [checked]);

    return (
        <div
            data-gs-uitk-component="menu-checkbox-option"
            tabIndex={0}
            className={getCheckboxOptionClassName()}
            {...otherProps}
            ref={hostRef}
            aria-checked={isSelected()}
            role="menuitemcheckbox"
        >
            <Checkbox
                data-cy="gs-uitk-menu-checkbox-option__checkbox"
                checked={isSelected()}
                disabled={isDisabled()}
                onChange={onCheckboxChange}
                className={getCheckboxOptionComponentClassName()}
                size={size}
            >
                {children}
            </Checkbox>
        </div>
    );
};

MenuCheckboxOption.propTypes = {
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
