import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import InjectRoutes from '../../routes';
import routes from '../../routes/loginRoutes';
import LoginViewTemplate from '../../templates/Login';
import TermsAndConditionsModal from '../../containers/TermsAndConditions';
import { isShowTermsAndConditionsSelector } from '../../selectors/pages/termsAndCondition';
import {isAppLoadingSelector, labelSelector} from '../../selectors/app';
import {
  isLoginInProgressSelector, isInterstitialModalSelector, isTmxProfilingCompleteSelector
} from '../../selectors/pages/login';
import {Conditional} from '../../components/core/Conditional';
import InterstitialModal from '../../containers/InterstitialModal';
import {pageIdSelector} from '../../selectors/pages';
import Constants from '../../constants/appConstants';

export class LoginApp extends Component {
  componentDidCatch(error, info) {
    console.error(error, info);
  }

  render() {
    const {
      isAppLoading, isShowTermsAndConditions, isTmxProfilingComplete,
      isInterstitialPage, isLoginInProgress, pageId, labels
    } = this.props;
    const hasLabelLoaded = !!Object.keys(labels).length;
    const redirectRoutes = [Constants.HOME_ROUTE, Constants.CONTENT_ROUTE];

    return (
      <Conditional condition={hasLabelLoaded}>
        <React.Fragment>
          <Conditional condition={!isInterstitialPage}>
            <LoginViewTemplate
              pageId={pageId}
              isAppLoading={isAppLoading}
              isTmxProfilingComplete={isTmxProfilingComplete}
              isLoginInProgress={isLoginInProgress}>
              <InjectRoutes
                routes={routes}
                redirectRoutes={redirectRoutes}
                isLogin={true}
              />
            </LoginViewTemplate>
            <InterstitialModal />
          </Conditional>
          <Conditional condition={isShowTermsAndConditions}>
            <TermsAndConditionsModal />
          </Conditional>
        </React.Fragment>
      </Conditional>
    );
  }
}

LoginApp.propTypes = {
  isAppLoading: PropTypes.bool,
  isInterstitialPage: PropTypes.bool,
  isShowTermsAndConditions: PropTypes.bool,
  isLoginInProgress: PropTypes.bool,
  isTmxProfilingComplete: PropTypes.bool,
  pageId: PropTypes.string,
  labels: PropTypes.object
};

const mapStateToProps = (state) => ({
  isAppLoading: isAppLoadingSelector(state),
  isInterstitialPage: isInterstitialModalSelector(state),
  isLoginInProgress: isLoginInProgressSelector(state),
  isShowTermsAndConditions: isShowTermsAndConditionsSelector(state),
  isTmxProfilingComplete: isTmxProfilingCompleteSelector(state),
  pageId: pageIdSelector(state),
  labels: labelSelector(state)
});


export default withRouter(connect(mapStateToProps)(LoginApp));
