// Internal component Not to be used separately
import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';

const Picker = (props) => {

  const {selected, selectsRange, dateFormat, startDate, endDate, handleChange, useWeekdaysShort, minDate, maxDate,
    showDisabledMonthNavigation, disabledKeyboardNavigation, excludeDates, highlightDates, filterDate, disabled, inline,
    customInput, disableWeekends, handleSelect, dropdownMode, showMonthDropdown, showYearDropdown} = props;

  return (<ReactDatePicker
    inline={inline}
    selected={selected}
    selectsRange={selectsRange}
    disableWeekends={disableWeekends}
    dateFormat={dateFormat}
    startDate={startDate}
    endDate={endDate}
    onChange={handleChange}
    onSelect={handleSelect}
    useWeekdaysShort={useWeekdaysShort}
    minDate={minDate || undefined}
    maxDate={maxDate || undefined}
    showDisabledMonthNavigation={showDisabledMonthNavigation}
    disabledKeyboardNavigation={disabledKeyboardNavigation}
    excludeDates={excludeDates}
    highlightDates={highlightDates}
    disabled={disabled}
    filterDate={filterDate}
    customInput={customInput}
    showMonthDropdown={showMonthDropdown}
    showYearDropdown={showYearDropdown}
    dropdownMode={dropdownMode}
  />);
};

Picker.defaultProps = {
  dropdownMode: "select"
}

Picker.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  selected: PropTypes.instanceOf(Date),
  highlightDates:  PropTypes.arrayOf(Date),
  excludeDates:  PropTypes.arrayOf(Date),
  useWeekdaysShort : PropTypes.bool,
  disableWeekends: PropTypes.bool,
  inline: PropTypes.bool,
  showDisabledMonthNavigation: PropTypes.bool,
  disabledKeyboardNavigation: PropTypes.bool,
  selectsRange:  PropTypes.bool,
  disabled:  PropTypes.bool,
  filterDate: PropTypes.func,
  handleSelect: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  customInput: PropTypes.element,
  showMonthDropdown: PropTypes.bool,
  showYearDropdown: PropTypes.bool,
  dropdownMode: PropTypes.string
};

export default Picker;
