import React from 'react';
import PropTypes from 'prop-types';
import Scrollbar from '../../core/Scrollbar';
import './index.scss';

const List = ({items, props, children, cssName, disabled, disabledCss, scrollStyle, NoDataComponent, testId}) => {

  const childrenArray = React.Children.toArray(children);
  const Child = childrenArray[0].type;
  const onClick = (event) => {
    if(disabled){
      event.stopPropagation();
      return false;
    }
  };

  const className = `${cssName} ${!disabled && disabledCss || ''}`;
  return (
    <div className={className} onClick={onClick} data-testid={testId}>
      <Scrollbar config={{cssContent: 'custom-scroll__content scroll__content', style: scrollStyle}}>
        <ul className='List--padding-left-none'>
          {
            items && items.length > 0 && items.map((item, index) => {
            return <Child testId={testId} {...item} key={index} index={index} {...props} />;
            }) || <NoDataComponent />
          }
        </ul>
      </Scrollbar>
    </div>
  );
};

List.defaultProps = {
  disabled: false,
  disabledCss: '',
  scrollStyle: {'maxHeight': '170px'},
  NoDataComponent: () => (<div />)
};

List.propTypes = {
  items:PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  props: PropTypes.object,
  cssName: PropTypes.string,
  disabledCss: PropTypes.string,
  disabled: PropTypes.bool,
  scrollStyle: PropTypes.object,
  NoDataComponent: PropTypes.func,
  testId: PropTypes.string
};

export default List;
