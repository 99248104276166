/**
 * Filters a props object by only keeping the keys that the predicate returns
 * `true` for.
 */
export function filterProps(
    props: { [key: string]: unknown },
    predicate: (propName: string) => boolean
) {
    const newPropsObj: { [key: string]: unknown } = {};
    for (const key of Object.keys(props)) {
        if (predicate(key)) {
            newPropsObj[key] = props[key];
        }
    }
    return newPropsObj;
}
