import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './index.scss';

const classes = {
  'link-primary': 'link link__primary',
  'link-primary--light': 'link link__primary link__primary--light',
  'link-primary-small': 'link link__primary link__primary--small',
  'link-secondary': 'link link__secondary',
  'link-secondary-small': 'link link__secondary link__secondary--small',
  'link-secondary--dark': 'link link__secondar link link__secondar--dark'
};

const Link = (props) => {
  const {href, target, label, customClass, handleClick, classType, isDisabled, extraProps, testId } = props;
  return (
    <a
      data-testid={testId}
      className={cn(classes[classType], { 'link__disabled': isDisabled}, customClass)}
      href={href}
      target={target}
      onClick={isDisabled? () => false : (event) => {
        handleClick && handleClick(event, props)
      }}
      {...extraProps}>
      {label}
    </a>
  );
};

Link.defaultProps = {
  extraProps: {},
  handleClick() {
    return true;
  },
  classType: 'link-primary'
};

Link.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
  label: PropTypes.string,
  handleClick: PropTypes.func,
  classType: PropTypes.string,
  isDisabled: PropTypes.bool,
  customClass: PropTypes.string,
  extraProps: PropTypes.object,
  testId: PropTypes.string
};

export default Link;
