import { win } from '@gs-ux-uitoolkit-common/shared';

import { Analytics } from './analytics';
import {
    AnalyticsHostApplication,
    AnalyticsProperties,
    AnalyticsCustomApplicationProperties,
    AnalyticsTrackOptions,
} from 'gs-uitk-analytics-common';
import { getAppTitle } from './helper';
import { toolkitProdAnalyticsConfig, toolkitQaAnalyticsConfig } from './toolkit-analytics-config';

export interface ToolkitAnalyticsTrackOptions {
    eventName: string;
    properties?: Omit<AnalyticsProperties, 'count' | 'app'>;
    applicationDetails?: ToolkitAnalyticsApplicationDetails;
}

/**
 * Interface for application details
 */
export interface ToolkitAnalyticsApplicationDetails {
    name: string;
    component: string;
    version: string;
    platform: string;
}

export class ToolkitAnalytics {
    private analytics: Analytics | undefined = undefined;
    private trackQueue: AnalyticsTrackOptions[] = [];
    private isRunning: boolean = true;

    constructor() {
        // We wait for the document to load to ensure we capture the qa and disabled window variables set by applications
        if (win && win.document) {
            switch (document.readyState) {
                case 'loading':
                    document.addEventListener('DOMContentLoaded', () => this.initialize());
                    break;
                case 'interactive':
                case 'complete':
                    this.initialize();
                    break;
            }
        }
    }
    public destroy() {
        if (this.analytics) {
            this.analytics.stop();
            this.analytics = undefined;
        }
        this.trackQueue = [];
    }
    public initialize() {
        const isQa = win && win.UITOOLKIT_ANALYTICS_LOG_TO_QA; // Note: check for SSR (Server-side Rendering) environments
        const isDisabled = win && win.GS_UX_UITOOLKIT_DISABLE_COMPONENT_ANALYTICS;
        this.analytics = new Analytics(
            isQa ? toolkitQaAnalyticsConfig : toolkitProdAnalyticsConfig
        );
        if (!isDisabled && this.isRunning) {
            this.analytics.start();
        } else {
            this.analytics.stop();
        }

        this.trackQueue.forEach((item: ToolkitAnalyticsTrackOptions) => this.track(item));
        this.trackQueue = [];
    }
    public stop() {
        if (this.analytics) {
            this.analytics.stop();
        } else {
            this.isRunning = false;
        }
    }
    public start() {
        if (this.analytics) {
            this.analytics.start();
        } else {
            this.isRunning = true;
        }
    }
    public track(options: ToolkitAnalyticsTrackOptions) {
        const hostApplication: AnalyticsHostApplication = {
            title: getAppTitle() || '',
            host: location.host,
            port: location.port,
            hostname: location.hostname,
        };

        const analyticsProperties: AnalyticsProperties = {
            ...options.properties,
            count: 1,
            payloadModelVersion: 'v2',
            app: hostApplication,
        };

        const trackDetails: AnalyticsTrackOptions = {
            eventName: options.eventName,
            properties: analyticsProperties,
            customApplicationProperties:
                options.applicationDetails as unknown as AnalyticsCustomApplicationProperties,
        };
        // We need this check to prevent it sending prod tracking when using localhost
        // only allow local analytic tracking if set to QA
        if (this.analytics && this.canTrack()) {
            this.analytics.track(trackDetails);
        } else {
            this.trackQueue.push(trackDetails);
        }
    }

    private canTrack(): boolean {
        // We need this check to prevent it sending prod tracking when using localhost
        // only allow local analytic tracking if set to QA
        return !this.isDisabled() && (!this.isLocalhost() || this.isQa());
    }

    private isQa() {
        return win && win.UITOOLKIT_ANALYTICS_LOG_TO_QA;
    }

    private isDisabled(): boolean {
        return win && win.GS_UX_UITOOLKIT_DISABLE_COMPONENT_ANALYTICS;
    }

    private isLocalhost(): boolean {
        return win?.location?.host.includes('localhost');
    }
}
/**
 * This is an instance of the analytics class for use with toolkit components
 */
export const toolkitAnalytics = new ToolkitAnalytics();
