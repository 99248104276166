import { StyleSheet, CssClasses, cx } from '@gs-ux-uitoolkit-common/style';

export type DualListBoxCssClasses = CssClasses<typeof dualListBoxStyleSheet>;

export const dualListBoxStyleSheet = new StyleSheet('button', () => {
    return {
        root: {
            // should be $wizard-step-wrapper-after-height but we cannot
            // rely yet on other component style. Waiting for Lukazs' magic
            height: 'calc(100% - 2rem)',
            '.category-label': {
                textAlign: 'center',
            },
            '.gs-layout-col': {
                maxWidth: '24%',
            },
            '.column-action': {
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
            },
            '.row-list-group': {
                height: '70%',
                '& > *': {
                    height: '100%',
                },
            },
            'span.gs-button': {
                width: '100%',
            },
            '.gs-layout-col > span.gs-button': {
                maxWidth: '120px',
            },
            button: {
                width: '100%',
                display: 'flex',
                alignItems: 'center',
            },
            '.gs-list-group': {
                height: '100%',
                overflowY: 'auto',
                overflowX: 'hidden',
                maxWidth: '100%',
            },

            '.remove-mouse-interaction': {
                overflow: 'hidden',

                '.widget-card': {
                    button: {
                        width: '100%',
                    },
                },
            },
        },
    };
});
export const getDualListBoxClassNames = (props: { cssClasses: DualListBoxCssClasses }): string => {
    const { cssClasses } = props;

    return cx(cssClasses.root, `dual-list-box`);
};
