import { createLoader } from './createLoader';
import { createMiddleware } from './createMiddleware';
import { reducer } from './reducer';
import { LOAD, SAVE } from './constants';
export { type StorageEngine } from './types';

export const ReduxStorage = {
    createLoader,
    createMiddleware,
    reducer,
    LOAD,
    SAVE,
};
