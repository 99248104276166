import {
    ComponentClass,
    FunctionComponent,
    ComponentType,
    ForwardRefExoticComponent,
    RefAttributes,
    ComponentProps,
    PropsWithoutRef,
    forwardRef,
} from 'react';

import { CssProperties, cx, StyleSheet } from '@gs-ux-uitoolkit-common/style';
import { isValidHtmlProp } from './is-valid-html-prop';
import { useStyleSheet } from './use-style-sheet';
import { StyledOptions } from './styled-options';
import { getComponentName } from './util/get-component-name';
import { filterProps } from './util/filter-props';

/**
 * Styled Components API for CSS-in-JS styling which resembles the "styled-components"
 * npm package, and allows developers to easily style HTML elements.
 *
 * Example usage with plain HTML tag:
 *
 *     const MyButton = styled('button', {
 *         color: 'blue',
 *         border: '1px solid red'
 *     });
 *
 *     const root = createRoot(document.body);
 *     root.render(<MyButton />);
 *
 *
 * Example usage to style a Component (note: the Component must accept `className` prop):
 *
 *     import { Button } from '@gs-ux-uitoolkit-react/button';
 *
 *     const MyButton = styled(Button, {
 *         color: 'blue',
 *         border: '1px solid red'
 *     });
 *
 *     const root = createRoot(document.body);
 *     root.render(<MyButton />);
 */

// Class Components

// **Signature 1**: styled(SomeClassComponent, { color: 'blue' })
export function styled<WrappedComponentClass extends ComponentClass<any>>(
    component: WrappedComponentClass,
    styles: CssProperties,
    options?: StyledOptions
): ForwardRefExoticComponent<
    PropsWithoutRef<ComponentProps<WrappedComponentClass>> &
        RefAttributes<InstanceType<WrappedComponentClass>>
>;

// **Signature 2**: styled(SomeClassComponent, (props: CssProps) => ({ color: props.color }))
export function styled<
    CssProps extends object, // CssProps is the first type arg in case Partial Type Argument Inference is ever implemented (https://github.com/microsoft/TypeScript/issues/26242)
    WrappedComponentClass extends ComponentClass<any>,
>(
    component: WrappedComponentClass,
    styles: (props: CssProps) => CssProperties,
    options?: StyledOptions
): ForwardRefExoticComponent<
    PropsWithoutRef<ComponentProps<WrappedComponentClass>> &
        CssProps &
        RefAttributes<InstanceType<WrappedComponentClass>>
>;

// Ref-forwarding Function Components

// **Signature 3**: styled(SomeForwardedRefExoticComponent, { color: 'blue' })
export function styled<WrappedFunctionComponent extends ForwardRefExoticComponent<any>>(
    component: WrappedFunctionComponent,
    styles: CssProperties,
    options?: StyledOptions
): ForwardRefExoticComponent<ComponentProps<WrappedFunctionComponent>>;

// **Signature 4**: styled(SomeForwardedRefExoticComponent, (props: CssProps) => ({ color: props.color }))
export function styled<
    CssProps extends object, // CssProps is the first type arg in case Partial Type Argument Inference is ever implemented (https://github.com/microsoft/TypeScript/issues/26242)
    WrappedFunctionComponent extends ForwardRefExoticComponent<any>,
>(
    component: WrappedFunctionComponent,
    styles: (props: CssProps) => CssProperties,
    options?: StyledOptions
): ForwardRefExoticComponent<ComponentProps<WrappedFunctionComponent> & CssProps>;

// Non-ref-forwarding Function Components

// **Signature 5**: styled(SomeFunctionComponent, { color: 'blue' })
// - Note: doesn't accept a 'ref' attribute
export function styled<WrappedFunctionComponent extends FunctionComponent<any>>(
    component: WrappedFunctionComponent,
    styles: CssProperties,
    options?: StyledOptions
): FunctionComponent<ComponentProps<WrappedFunctionComponent>>;

// **Signature 6**: styled(SomeFunctionComponent, (props: CssProps) => ({ color: props.color }))
// - Note: doesn't accept a 'ref' attribute
export function styled<
    CssProps extends object, // CssProps is the first type arg in case Partial Type Argument Inference is ever implemented (https://github.com/microsoft/TypeScript/issues/26242)
    WrappedFunctionComponent extends FunctionComponent<any>,
>(
    component: WrappedFunctionComponent,
    styles: (props: CssProps) => CssProperties,
    options?: StyledOptions
): FunctionComponent<ComponentProps<WrappedFunctionComponent> & CssProps>;

// **Signature 7**: styled('span', { color: blue })
export function styled<TagName extends keyof JSX.IntrinsicElements>(
    tagName: TagName,
    styles: CssProperties,
    options?: StyledOptions
): ForwardRefExoticComponent<JSX.IntrinsicElements[TagName]>;

// **Signature 8**: styled('span', (props: CssProps) => ({ color: props.color }))
export function styled<
    CssProps extends object, // CssProps is the first type arg in case Partial Type Argument Inference is ever implemented (https://github.com/microsoft/TypeScript/issues/26242)
    TagName extends keyof JSX.IntrinsicElements,
>(
    tagName: TagName,
    styles: (props: CssProps) => CssProperties,
    options?: StyledOptions
): ForwardRefExoticComponent<JSX.IntrinsicElements[TagName] & CssProps>;

// Implementation signature
export function styled(
    tagOrComponent:
        | keyof JSX.IntrinsicElements
        | ComponentType<unknown>
        | ForwardRefExoticComponent<unknown>,
    styles: CssProperties | ((props: unknown) => CssProperties),
    { label, shouldForwardProp }: StyledOptions = {}
): ForwardRefExoticComponent<any> {
    const styleSheetName = label || createStyleSheetName(tagOrComponent);
    const styleSheet = new StyleSheet('', (props: unknown) => ({
        [styleSheetName]: typeof styles === 'function' ? styles(props) : styles,
    }));
    const StyleElement = tagOrComponent;
    const isHtmlElement = typeof tagOrComponent === 'string';

    const Styled: ForwardRefExoticComponent<any> = forwardRef((props, ref) => {
        const classes = useStyleSheet(styleSheet, props);
        const propsFilterFn = shouldForwardProp || (isHtmlElement ? isValidHtmlProp : undefined);
        const propsWithRef = { ...props, ref };
        const filteredProps = propsFilterFn
            ? filterProps(propsWithRef as { [key: string]: unknown }, propsFilterFn)
            : propsWithRef;
        const finalProps = {
            ...filteredProps,
            className: cx(classes[styleSheetName], props.className),
        };

        return <StyleElement {...finalProps}>{props.children}</StyleElement>;
    });
    Styled.displayName = 'Styled';

    return Styled;
}

/**
 * Creates a name for the StyleSheet (and therefore the final computed styles)
 * by either:
 *
 * 1. Using the tagOrComponent's `.displayName` prop (if it is a component, and
 *    the property exists)
 * 2. Using the tagOrComponent's `.name` prop (if it is a component)
 * 2. Using the tagOrComponent's tag name if it's an html element
 */
function createStyleSheetName(
    tagOrComponent: keyof JSX.IntrinsicElements | ComponentType<unknown>
): string {
    if (typeof tagOrComponent === 'string') {
        return tagOrComponent;
    } else {
        return getComponentName(tagOrComponent) || 'styled';
    }
}
