import React from 'react';
import PropTypes from 'prop-types';

export const Conditional = ({ condition, children }) => {
  const childrenArray = React.Children.toArray(children);
  return (condition ? childrenArray[0] : (childrenArray[1] || null));
};

Conditional.propTypes = {
  condition: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};
