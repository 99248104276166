import { NotificationItem } from '../../plugins/notification/notification-plugin';
import { NotificationActionKeys } from './action-constants';

export type NotificationActionTypes =
    | AddNotificationAction
    | RemoveNotificationAction
    | RemoveAllNotificationAction
    | RemoveAllDismissibleNotificationAction;

export interface AddNotificationAction {
    notificationItem: NotificationItem;
    type: NotificationActionKeys.ADD_NOTIFICATION;
}

export interface RemoveNotificationAction {
    notificationItem: NotificationItem;
    type: NotificationActionKeys.REMOVE_NOTIFICATION;
}

export interface RemoveAllNotificationAction {
    type: NotificationActionKeys.REMOVE_ALL_NOTIFICATION;
}

export interface RemoveAllDismissibleNotificationAction {
    type: NotificationActionKeys.REMOVE_ALL_DISMISSABLE_NOTIFICATION;
}

export const AddNotification = (notificationItem: NotificationItem): AddNotificationAction => ({
    notificationItem,
    type: NotificationActionKeys.ADD_NOTIFICATION,
});
export const RemoveNotification = (
    notificationItem: NotificationItem
): RemoveNotificationAction => ({
    notificationItem,
    type: NotificationActionKeys.REMOVE_NOTIFICATION,
});
export const RemoveAllNotification = (): RemoveAllNotificationAction => ({
    type: NotificationActionKeys.REMOVE_ALL_NOTIFICATION,
});
export const RemoveAllDismissibleNotification = (): RemoveAllDismissibleNotificationAction => ({
    type: NotificationActionKeys.REMOVE_ALL_DISMISSABLE_NOTIFICATION,
});
