import { SystemConfigurationActionKeys } from '../actions/action-constants';
import { SystemConfigurationActionTypes } from '../actions/system-configuration-action';
import { SystemConfigurationState } from '../datagrid-state';
import { defaultDebounceTime } from '../../grid-wrappers/grid-wrapper';
import { cloneDeep } from 'gs-uitk-lodash';

/**
 * Initial state of the SystemConfiguration State
 */
export const defaultSystemConfigurationState: SystemConfigurationState = {
    gridWrapperConfiguration: {
        id: '',
        uiConfiguration: {
            debounceTime: defaultDebounceTime,
        },
    },
};

/**
 * Reducer for SystemConfiguration
 * @param state State of the SystemConfiguration
 * @param action Action received
 */
export const systemConfigurationReducer = (
    state: SystemConfigurationState = defaultSystemConfigurationState,
    action: SystemConfigurationActionTypes
): SystemConfigurationState => {
    switch (action.type) {
        case SystemConfigurationActionKeys.SET_GRID_WRAPPER_CONFIGURATION:
            if (!action.configuration.uiConfiguration) {
                action.configuration.uiConfiguration =
                    state.gridWrapperConfiguration.uiConfiguration;
            }

            return {
                ...state,
                gridWrapperConfiguration: cloneDeep(action.configuration),
            };
        default:
            return state;
    }
};
