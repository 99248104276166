import { useContext } from 'react';
import { FormContext as ReactFormContext } from './form-context';
import { FormContext } from '@gs-ux-uitoolkit-common/form';

export function useFormContext(props: FormContext): FormContext {
    const context = useContext(ReactFormContext);

    function getDisabled(): FormContext['disabled'] {
        const propValue = props.disabled !== undefined ? props.disabled : context.disabled;
        return propValue !== undefined ? propValue : false;
    }

    function getInline(): FormContext['inline'] {
        const propValue = props.inline !== undefined ? props.inline : context.inline;
        return propValue !== undefined ? propValue : false;
    }

    function getSize(): FormContext['size'] {
        const propValue = props.size !== undefined ? props.size : context.size;
        return propValue !== undefined ? propValue : 'md';
    }

    function getStatus(): FormContext['status'] {
        const propValue = props.status !== undefined ? props.status : context.status;
        return propValue !== undefined ? propValue : 'none';
    }

    return {
        disabled: getDisabled(),
        inline: getInline(),
        size: getSize(),
        status: getStatus(),
    };
}
