import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@gs-ux-uitoolkit-react/button';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import useUserMaintenance from '../../../context';
import FieldRenderer from '../../FieldRenderer';
import translator from '../../../../../../services/translator';
import { UPDATE_PROFILE_RELATIONSHIPS_FIELD } from '../../../actions';
import './index.scss';

const { translate: t } = translator;

export default function RelationshipGroup({ data, groupTitle, testId, showAddClearingAgent }) {
  const {
    state: { organizationDetails, isOrgEditable, orgDetailsValidation },
    dispatch,
  } = useUserMaintenance();

  const { relationships = [] } = organizationDetails;

  const [clearingAgents] = useMemo(() => {
    const clearingAgents = [];
    let intermediary = null;
    let subsidiary = null;
    if (relationships.length) {
      relationships.forEach(relationship => {
        if (relationship.relationshipType === 'SUBSIDIARY') {
          subsidiary = relationship;
        } else if (relationship.relationshipType === 'INTERMEDIARY') {
          intermediary = relationship;
        } else if (relationship.relationshipType === 'CLEARING') {
          clearingAgents.push(relationship);
        }
      });
    }
    return [clearingAgents, intermediary, subsidiary];
  }, [relationships]);

  const handleChange = ({ action, value, fieldIndex, config }) => {
    dispatch({ type: UPDATE_PROFILE_RELATIONSHIPS_FIELD, payload: { action, value, fieldIndex, config } });
  };

  const addClearingAgent = () => {
    dispatch({ type: UPDATE_PROFILE_RELATIONSHIPS_FIELD, payload: { action: 'add' } });
  };

  const config = useMemo(() => {
    return showAddClearingAgent ? data.filter((_, i) => i < clearingAgents.length) : data;
  }, [relationships, data, showAddClearingAgent, clearingAgents]);

  return (
    <div className='profile-main-container__info-section-container'>
      <div data-testid={testId} className='profile-main-container__header-title'>
        {t(groupTitle)}
      </div>
      {config.map((coreItem, idx, array) => {
        const value = coreItem.valueGetter(relationships, coreItem, idx) || {};
        return (
          <FieldRenderer
            key={idx}
            isEditable={isOrgEditable}
            config={coreItem}
            value={value}
            handleChange={handleChange}
            sectionStart={idx === 0}
            sectionEnd={idx === array.length - 1}
            canDeleteField={coreItem.dataType === 'CLEARING' && clearingAgents.length > 1}
            activeIcon={coreItem.value === 'status'}
            errorState={orgDetailsValidation[value.organizationId]}
            fieldIndex={idx}
          />
        );
      })}
      {showAddClearingAgent && isOrgEditable && clearingAgents.length < 10 && (
        <Button actionType='primary' emphasis='minimal' onClick={addClearingAgent}>
          <Icon name='add' type='filled' size='sm' />
          {t('tkAddClearingAgent')}
        </Button>
      )}
    </div>
  );
}

RelationshipGroup.propTypes = {
  data: PropTypes.array,
  groupTitle: PropTypes.string,
  testId: PropTypes.string,
  showAddClearingAgent: PropTypes.bool,
};
