import { ButtonSize, buttonSizes } from '../button/button-props';
import { ButtonSelectCssClasses } from './button-select-style-sheet';

export interface ButtonSelectCommonProps<ChangeEventType = Event, FocusEventType = FocusEvent> {
    /**
     * Selection type of the button, which determines its underlying `input` component.
     *
     * `single` will behave similar to a `Radio`.
     * `multi` will behave similar to a `Checkbox`.
     */
    type: ButtonSelectType;

    /**
     * Selects the button.
     *
     * This property controls the selected value by forcing the component's selected state to always be the value of this prop.
     * If you want to use the component in an "uncontrolled" mode, do not pass this property.
     */
    selected?: boolean;

    /**
     * Selects the button as the default state when using the "uncontrolled" mode of the component.
     *
     * To "control" the selected value, use the {@link #selected} property.
     */
    defaultSelected?: boolean;

    /**
     * Sets the HTML `disabled` state on the underlying `input` and prevents the button from being clicked.
     *
     * See the [MDN docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-disabled) for more info on this attribute.
     */
    disabled?: boolean;

    /**
     * Sets the HTML `name` of the underlying `input`.
     *
     * See the [MDN docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-name) for more info on this attribute.
     */
    name?: string;

    /**
     * Sets the HTML `value` of the underlying `input`.
     *
     * See the [MDN docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-value) for more info on this attribute.
     */
    value?: string | number | string[];

    /**
     * Size of the button.
     */
    size?: ButtonSize;

    /**
     * Additional classes to add on the root element.
     */
    className?: string;

    /**
     * Style classes to override.
     */
    classes?: ButtonSelectOverrideClasses;

    /**
     * Handler for the change event fired when the selected value changes on the underlying `input`.
     */
    onChange?: (event: ChangeEventType) => void;

    /**
     * Handler for the change event fired when selected object loses the input focus.
     */
    onBlur?: (event: FocusEventType) => void;
}

export const defaultButtonSelectProps: DefaultButtonSelectProps = {
    size: 'md',
    disabled: false,
};

export type DefaultButtonSelectProps = Required<Pick<ButtonSelectCommonProps, 'size' | 'disabled'>>;

export type ButtonSelectOverrideClasses = Partial<
    Pick<ButtonSelectCssClasses, 'root' | 'label' | 'input' | 'target'>
>;

export type ButtonSelectType = 'single' | 'multi';
export type ButtonSelectSize = ButtonSize;

export const buttonSelectTypes: ButtonSelectType[] = ['single', 'multi'];
export const buttonSelectSizes: ButtonSelectSize[] = buttonSizes;

export interface ButtonSelectChangeEventProps {
    selected: ButtonSelectCommonProps['selected'];
    name?: ButtonSelectCommonProps['name'];
    value?: ButtonSelectCommonProps['value'];
}
