/* eslint-disable jsx-a11y/no-autofocus */
import {
    FC,
    FocusEvent,
    useState,
    ChangeEvent,
    useMemo,
    useContext,
    useRef,
    MouseEvent,
    KeyboardEvent,
    useEffect,
} from 'react';
import {
    inputStyleSheet,
    textareaStyleSheet,
    InputThemeStyleSheetProps,
    TextareaThemeStyleSheetProps,
    inputIconStyleSheet,
    InputStateViewModel,
    inputControlStyleSheet,
    inputNumberControlStyleSheet,
    inputTextareaStyleSheet,
    globalInputClass,
} from '@gs-ux-uitoolkit-common/input';
import { FormContext as FormContextProps } from '@gs-ux-uitoolkit-common/form';
import { createCrossBrowserEvent } from '@gs-ux-uitoolkit-react/shared';
import { FormContext } from '@gs-ux-uitoolkit-react/form';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { cx, useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';

import { InputInternalIconsUi } from './input-internal-icons-ui';
import { useFormInputContext } from './hooks/use-form-input-context';
import { InputIconContext, InputIconContextProps } from './input-icon-context';
import { InputInternalProps } from './input-props';

export interface Ref {
    value: string;
    setValue: (value: string) => void;
}

export const InputInternal: FC<InputInternalProps> = (props: InputInternalProps) => {
    const {
        autoFocus,
        className,
        classes,
        clearable = false,
        arrowButtons,
        changeValueOnWheel,
        cols,
        disabled,
        form,
        defaultValue = '',
        inline,
        inputId,
        inputMode,
        max,
        maxLength,
        min,
        minLength,
        multiple = false,
        name,
        pattern,
        placeholder,
        readOnly = false,
        required = false,
        resize = 'both',
        rows = 4,
        size,
        spellCheck = true,
        status,
        step = 1,
        tabIndex,
        style,
        componentType = 'text',
        type = 'text',
        value,
        wrap,
        leadingContent,
        trailingContent,
        trailingInternalContent,
        inputRef,
        onBlur = () => undefined,
        onFocus = () => undefined,
        onWheel = () => undefined,
        onChange = () => undefined,
        onInput = () => undefined,
        onMouseEnter = () => undefined,
        onMouseLeave = () => undefined,
        onClearClick = () => undefined,
        onKeyPress = () => undefined,
        onKeyUp = () => undefined,
        onPaste = () => undefined,
        onCopy = () => undefined,
        onCut = () => undefined,
        onDrag = () => undefined,
        onDrop = () => undefined,
        'data-input': isDatepickerInput, // datepicker inputs need to pass down 'data-input' for flatpickr to attach
        'aria-label': ariaLabel,
        inputAttrs,
        ...otherProps
    }: InputInternalProps & { 'data-input'?: boolean } = props;

    const [clearButtonVisible, setClearButtonVisible] = useState(
        typeof clearable === 'boolean' ? clearable : false
    );

    function onFocusInternal(event: FocusEvent<HTMLInputElement>) {
        if (clearable === 'onFocus' || clearable === 'onFocusOrHover') {
            setClearButtonVisible(true);
        }
        onFocus && onFocus(event);
    }

    function onBlurInternal(event: FocusEvent<HTMLInputElement>) {
        if (clearable === 'onFocus' || clearable === 'onFocusOrHover') {
            setClearButtonVisible(false);
        }
        onBlur && onBlur(event);
    }

    function onMouseEnterInternal(event: MouseEvent<HTMLDivElement>) {
        if (clearable === 'onHover' || clearable === 'onFocusOrHover') {
            setClearButtonVisible(true);
        }
        onMouseEnter && onMouseEnter(event);
    }

    function onMouseLeaveInternal(event: MouseEvent<HTMLDivElement>) {
        if (
            clearable === 'onHover' ||
            (clearable === 'onFocusOrHover' && inputInternalRef.current !== document.activeElement)
        ) {
            setClearButtonVisible(false);
        }
        onMouseLeave && onMouseLeave(event);
    }

    const formContext = useContext<FormContextProps>(FormContext);

    const internal = useFormInputContext({ disabled, inline, size, status });

    const stateVm = useMemo(() => {
        return new InputStateViewModel(type, internal.status);
    }, [type, internal.status]);
    const [stateValue, setStateValue] = useState<string | number | undefined>(
        value !== undefined ? value : defaultValue
    );
    const inputInternalRef = useRef<HTMLInputElement | HTMLTextAreaElement>();

    const theme = useTheme();

    function getStyleSheet() {
        return componentType === 'textarea' ? textareaStyleSheet : inputStyleSheet;
    }

    //when clearable is set to onHover, the buttons should be hidden when any keyboard typing action takes place
    function onKeyDown() {
        if (clearable === 'onHover') {
            setClearButtonVisible(false);
        }
    }

    function getStyleSheetProps() {
        const cssProps = {
            theme,
            size: internal.size,
            status: internal.status,
            disabled: internal.disabled,
            readOnly,
            inline: internal.inline,
            hidden: type === 'hidden',
        };
        if (componentType === 'textarea') {
            (cssProps as TextareaThemeStyleSheetProps).cols = !!cols;
        } else {
            (cssProps as InputThemeStyleSheetProps).grouped = formContext.containedByInputGroup;
        }
        return cssProps;
    }

    const Tag: any = componentType === 'textarea' ? 'textarea' : 'input';

    function getDataComponentType() {
        if (componentType === 'number') {
            return 'input-number';
        } else if (componentType === 'file') {
            return 'file-upload';
        } else if (componentType === 'textarea') {
            return 'textarea';
        }
        return 'input';
    }

    const cssClasses: { [key: string]: any } = useStyleSheet(getStyleSheet(), getStyleSheetProps());
    const containerClassName = cx(
        cssClasses.root,
        className,
        classes && classes.root,
        globalInputClass
    );

    function useTagClassName() {
        const cssProps = { theme, size: internal.size || 'md', resize };
        let tagClassNames = '';
        let styleSheet = inputControlStyleSheet;
        let styleSheetParams = cssProps;

        if (componentType === 'textarea') {
            styleSheet = inputTextareaStyleSheet;
            styleSheetParams = {
                ...cssProps,
                resize,
            };
        } else if (componentType === 'number') {
            styleSheet = inputNumberControlStyleSheet;
        }
        tagClassNames = useStyleSheet(styleSheet, styleSheetParams).input;

        return cx(tagClassNames, classes && classes.input, `${globalInputClass}__input`);
    }

    function onInternalChange(event: ChangeEvent<HTMLInputElement>) {
        if (value === undefined) {
            setStateValue((event.target as HTMLInputElement).value);
        }
        onChange(event);
    }

    function onInternalSearchClearClick() {
        const currentValue = getValue();
        setStateValue('');
        if (currentValue !== '') {
            const inputEl = inputInternalRef.current!;
            inputEl.value = '';
            onClearClick();

            // In addition to calling the onClearClick callback, we also need to
            // call onInput with an "input" event just as happens when "x" is
            // clicked on a HTML <input type="search">. In order for the
            // event.target and event.target value to be correct, we manually
            // dispatch an "input" event.
            const inputEvent = createCrossBrowserEvent({ type: 'input' });
            inputEl.dispatchEvent(inputEvent);

            // if this is a controlled component, revert back to previous value
            if (value != null) {
                inputEl.value = `${value}`;
            }
        }
        inputInternalRef.current!.focus();
    }

    function onInternalInput(event: KeyboardEvent<HTMLInputElement>) {
        if (onInput) {
            onInput(event);
        }
    }

    function getValue() {
        return value != null ? value : stateValue;
    }

    function useLeadingContent() {
        const cssClasses: { [key: string]: any } = useStyleSheet(inputIconStyleSheet, {
            theme,
            size: internal.size || 'md',
            status: internal.status || 'none',
            position: 'leading',
            special: 'search',
            disabled: disabled || false,
            isTextarea: false,
        });
        return (
            leadingContent ||
            (type === 'search' && getDataComponentType() === 'input' && (
                <Icon name="search" type="filled" className={cssClasses.root} />
            )) ||
            null
        );
    }

    function inputInternalCallbackRef(element: HTMLInputElement) {
        inputInternalRef.current = element;
    }

    function getInputIconContext(position: InputIconContextProps['position']) {
        return {
            disabled: internal.disabled || false,
            size: internal.size || 'md',
            position,
        };
    }
    useEffect(() => {
        if (typeof inputRef === 'function') {
            inputRef(inputInternalRef.current!);
        } else if (inputRef) {
            inputRef.current = inputInternalRef.current!;
        }
    }, [inputRef]);
    return (
        <div
            className={containerClassName}
            onMouseEnter={onMouseEnterInternal}
            onMouseLeave={onMouseLeaveInternal}
            style={style}
            data-gs-uitk-component={getDataComponentType()}
            {...otherProps}
        >
            <InputIconContext.Provider value={getInputIconContext('leading')}>
                {useLeadingContent()}
            </InputIconContext.Provider>
            <Tag
                {...inputAttrs}
                aria-label={ariaLabel}
                autoFocus={autoFocus}
                autoComplete="off"
                className={useTagClassName()}
                cols={cols !== undefined ? cols : undefined}
                data-cy={`gs-uitk-${getDataComponentType()}__${getDataComponentType()}`}
                disabled={internal.disabled}
                form={form}
                id={inputId}
                inputMode={inputMode}
                min={min !== undefined ? min : undefined}
                max={max !== undefined ? max : undefined}
                minLength={stateVm.isText() && minLength !== undefined ? minLength : undefined}
                maxLength={stateVm.isText() && maxLength !== undefined ? maxLength : undefined}
                multiple={stateVm.supportsMultiple() && multiple}
                name={name}
                pattern={stateVm.isText() && pattern !== undefined ? pattern : undefined}
                placeholder={placeholder}
                readOnly={readOnly}
                required={required}
                step={step !== undefined ? step : undefined}
                tabIndex={tabIndex}
                type={componentType === 'number' ? 'text' : stateVm.getType()}
                aria-required={required}
                aria-invalid={stateVm.isError()}
                value={getValue()}
                onWheel={onWheel}
                onChange={onInternalChange}
                onInput={onInternalInput}
                onFocus={onFocusInternal}
                onBlur={onBlurInternal}
                onKeyPress={onKeyPress}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                onPaste={onPaste}
                onCopy={onCopy}
                onCut={onCut}
                onDrag={onDrag}
                onDrop={onDrop}
                ref={inputInternalCallbackRef}
                rows={componentType === 'textarea' && rows !== undefined ? rows : undefined}
                spellCheck={
                    componentType === 'textarea' ? (spellCheck ? 'true' : 'false') : undefined
                }
                wrap={wrap !== undefined ? wrap : undefined}
                {...(isDatepickerInput ? { 'data-input': '' } : {})}
            />

            <InputInternalIconsUi
                clearable={stateVm.isSearch() || clearButtonVisible}
                componentType={componentType}
                disabled={internal.disabled}
                size={size}
                status={stateVm.status}
                value={getValue()}
                onClearClick={onInternalSearchClearClick}
            />
            <InputIconContext.Provider value={getInputIconContext('trailing')}>
                {trailingContent}
            </InputIconContext.Provider>
            {trailingInternalContent}
        </div>
    );
};
