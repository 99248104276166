import { ColumnHintFormatSection } from '../../plugins/column-hint/view/components/formatScreens/column-hint-format-section';
import { Row, Col } from '@gs-ux-uitoolkit-react/layout';
import { FormatControlProps } from '../../plugins/column-hint/view/components/column-hint-format-manager';
import { ColumnHintControlNamedFormat } from '../../plugins/column-hint/view/components/formatControls/column-hint-control-named-format';
import { ColumnHintControlPrefixSuffix } from '../../plugins/column-hint/view/components/formatControls/column-hint-control-prefix-suffix';
import { ColumnHintControlMultiplier } from '../../plugins/column-hint/view/components/formatControls/column-hint-control-multiplier';
import { columnFormattingPluginStylePrefix } from '../../style/plugins/column-hint-plugin-stylesheet';
export const ColumnHintDateSection = (props: FormatControlProps) => {
    return (
        <ColumnHintFormatSection title="Date">
            <Row>
                <Col sm={12}>
                    <ColumnHintControlNamedFormat
                        columnHint={props.columnHint}
                        onChangeHint={props.onChangeHint}
                        dateFormat={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <div
                        className={`${columnFormattingPluginStylePrefix}_numeric-prefix-container`}
                    >
                        <div
                            className={`${columnFormattingPluginStylePrefix}_numeric-prefix-sub-container`}
                        >
                            <ColumnHintControlPrefixSuffix
                                columnHint={props.columnHint}
                                onChangeHint={props.onChangeHint}
                            />
                        </div>

                        <ColumnHintControlMultiplier
                            columnHint={props.columnHint}
                            onChangeHint={props.onChangeHint}
                        />
                    </div>
                </Col>
            </Row>
        </ColumnHintFormatSection>
    );
};
