import { Component, Context } from 'react';
import { connect, ReactReduxContextValue } from 'react-redux';
import {
    DataToolbarSize,
    QuickFilter,
    QuickFilterActionTypes,
    SetEnabledQuickFilterList,
    WidgetContentCommonProps,
} from '@gs-ux-uitoolkit-common/datacore';
import { QuickFilterSelector } from '../../../../components/quick-filter/quick-filter-selector';
import { DataGridState } from '../../../../redux/datagrid-state';

interface QuickFilterPluginWidgetComponentReduxProps {
    quickFilterList: QuickFilter[];
    setEnabledQuickFilterList: (quickFilterList: QuickFilter[]) => void;
}

export interface QuickFilterPluginWidgetComponentProps
    extends QuickFilterPluginWidgetComponentReduxProps,
        WidgetContentCommonProps {}
export interface QuickFilterPluginWidgetComponentState {
    size?: DataToolbarSize;
}
/**
 * The QuickFilter Plugin Widget Component
 */
export class QuickFilterPluginWidgetComponent extends Component<
    QuickFilterPluginWidgetComponentProps,
    QuickFilterPluginWidgetComponentState
> {
    constructor(props: QuickFilterPluginWidgetComponentProps) {
        super(props);
        this.state = {
            size: this.props.widgetContentCommonProps?.size,
        };
    }

    public render() {
        return (
            <div className="quick-filter-widget">
                <QuickFilterSelector
                    quickFilterList={this.props.quickFilterList}
                    setEnabledQuickFilterList={this.props.setEnabledQuickFilterList}
                    size={this.state.size}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: DataGridState) => {
    return {
        quickFilterList: state.quickFilter.configItemList,
    };
};

const mapDispatchToProps = (dispatch: (action: QuickFilterActionTypes) => void) => {
    const setEnabledQuickFilterList = (quickFilterList: QuickFilter[]) =>
        dispatch(SetEnabledQuickFilterList(quickFilterList));
    return {
        setEnabledQuickFilterList,
    };
};

export const getQuickFilterPluginWidget = (context?: Context<ReactReduxContextValue>) =>
    connect(mapStateToProps, mapDispatchToProps, null, { context })(
        QuickFilterPluginWidgetComponent
    );
