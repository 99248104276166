import React from 'react';
import ProfileGroup from './ProfileGroup';
import RelationshipGroup from './RelationshipGroup';
import { profileConfig } from './config';
import translator from '../../../../../../services/translator';
import './index.scss';

const { translate: t } = translator;

const Profile = () => {
  const { identifyingInformationConfig, orgRelationshipConfig, clearingAgentConfig, setupConfig, fundRebatesConfig, changeManagementDetailsConfig } =
    profileConfig;

  return (
    <>
      <div className='main-header'>{t('tkOrganizationProfile')}</div>
      <div className='profile-main-container'>
        <div className='profile-main-container__left'>
          <ProfileGroup data={identifyingInformationConfig} groupTitle='tkIdentifyingInformation' testId='identifying-information' />
          <RelationshipGroup
            showAddClearingAgent={false}
            data={orgRelationshipConfig}
            groupTitle='tkOrganizationRelationship'
            testId='organization-relationship'
          />
          <RelationshipGroup showAddClearingAgent={true} data={clearingAgentConfig} groupTitle='tkClearingAgents' testId='clearing-agent' />
        </div>
        <div className='profile-main-container__right'>
          <ProfileGroup data={setupConfig} groupTitle='tkSetup' testId='setup' />
          <ProfileGroup data={fundRebatesConfig} groupTitle='tkFundRebates' testId='fund-rebates' />
          <ProfileGroup data={changeManagementDetailsConfig} groupTitle='tkChangeManagementDetails' testId='change-mgmt-details' />
        </div>
      </div>
    </>
  );
};

export default Profile;
