import {createSelector} from 'reselect/lib/index';
import appRoutes from '../../routes/appRoutes';
import Constants from '../../constants/appConstants';
import {EMAIL_PREFERENCES_CONFIG} from '../../constants/pageConstants';
import {canShowEmailPreferenceNavItemSelector} from '../user';
import {globalPreferenceSelector, accountIdentifierPreference, fundIdentifierPreference} from '../preferences';

const defaultNavRootConfig =  { navRootConfig: [] };

export const appLabelsSelector = state => state.appData.labels || {};
export const appNavBarSelector = state =>  state.appData.navBar || defaultNavRootConfig;
export const displaySettingsPrefConfigSelector = state => state.appData && state.appData.displaySettingsConfig || [];
export const emailNotificationsPrefConfigSelector = state => state.appData && state.appData.emailNotificationsConfig || [];
export const fileDownloadConfigSelector = state => state.appData && state.appData.fileDownloadConfig || {};


export const preferenceRouteConfigSelector = createSelector(
  appLabelsSelector,
  appNavBarSelector,
  canShowEmailPreferenceNavItemSelector,
  (labels, navBar, canShowEmailPreferenceNavItem) => {
    const preferencesNavConfigs = (navBar && navBar.navRootConfig && navBar.navRootConfig.find(data => data.routingPathId === Constants.PREFERENCES));
    const preferencesRoutes = appRoutes.find(item => item.id === Constants.PREFERENCES).subRoutes;
    return preferencesNavConfigs && preferencesNavConfigs.subMenu.map(data => {
      const isEmailNotifications = (data.routingPathId === Constants.PREFERENCE_EMAIL_NOTIFICATIONS);
      if(!isEmailNotifications || canShowEmailPreferenceNavItem) {
        const matchedRoute = preferencesRoutes.find(item => item.id === data.routingPathId) || '';
        const {id, exact, path} = matchedRoute;
        return {id, exact, path, label: labels[data.displayNameKey]};
      }
      return null;
    }).filter(item => item) || [];
  }
);

export const preferenceNavLinksSelector = createSelector(
  appNavBarSelector,
  (navBar, canTrade) => {
    const preferenceRoute = (navBar && navBar.navRootConfig.find(data => data.routingPathId === Constants.PREFERENCES));
    if (preferenceRoute) {
      const {subMenu = []} = preferenceRoute;
      return subMenu.filter(({routingPathId}) => (routingPathId === Constants.PREFERENCE_EMAIL_NOTIFICATIONS) ? canTrade : true);
    }
    return [];
  }
);

export const displaySettingsConfigSelector = createSelector(
  displaySettingsPrefConfigSelector,
  appLabelsSelector,
  globalPreferenceSelector,
  (displaySettingsConfig = [], labels, globalPreference) => {
    if(!displaySettingsConfig.length) return [];
    const updatedPreferences = displaySettingsConfig && displaySettingsConfig.filter(data => !data.hide);
    updatedPreferences.sort((a, b) => a.order - b.order);
    updatedPreferences.forEach(data => {
      data.list.sort((a, b) => a.order - b.order);
    });
    return updatedPreferences && updatedPreferences.map(config => (
      {
        ...config,
        title: labels[config.title],
        list: config.list.map(data => ({ ...data, label: labels[data.label], isSelected: globalPreference[config.valueKey] === data.value}))
      }
    ));
  }
);

export const emailNotificationsConfigSelector = createSelector(
  emailNotificationsPrefConfigSelector,
  appLabelsSelector,
  globalPreferenceSelector,
  (emailNotificationsConfig = [], labels, globalPreference = {}) => {
    if (!emailNotificationsConfig.length) return [];
    const configuration = emailNotificationsConfig.sort((a, b) => a.order - b.order);
    const {emailNotificationSettings} = globalPreference;
    const {IS_CHECKED_KEY, IS_ENABLED_KEY} = EMAIL_PREFERENCES_CONFIG;
    const notificationGroupEntitledStatus = configuration.map(({notificationItems}) => (
      !notificationItems.every(({rootKey}) => {
        const emailRootItem = emailNotificationSettings && emailNotificationSettings[rootKey] || {[IS_ENABLED_KEY]: true};
        return emailRootItem ? !emailRootItem[IS_ENABLED_KEY] : false;
      })
    ));
    const notificationGroupCheckedStatus = configuration.map(({notificationItems}) => (
      notificationItems.some(({rootKey}) => {
        const emailRootItem = emailNotificationSettings && emailNotificationSettings[rootKey] || {[IS_CHECKED_KEY]: true, [IS_ENABLED_KEY]: true};
        return emailRootItem ? (emailRootItem[IS_ENABLED_KEY] && emailRootItem[IS_CHECKED_KEY]) : true;
      }
    )));
    return configuration.map(({headerKey, subHeaderKey, noAccessSubHeaderKey, notificationItems}, i) => ({
      headerKey,
      header: labels[headerKey],
      subHeader: notificationGroupEntitledStatus[i] ? labels[subHeaderKey] : labels[noAccessSubHeaderKey],
      isGroupEntitled: notificationGroupEntitledStatus[i],
      isActive: notificationGroupCheckedStatus[i],
      notificationItems: notificationItems.map((data, i) => {
        const {titleKey, subTitleKey, rootKey, parentKey} = data
        const emailSettings = emailNotificationSettings && emailNotificationSettings[rootKey]
          || {[IS_CHECKED_KEY]: true, [IS_ENABLED_KEY]: true};
        const config = rootKey === EMAIL_PREFERENCES_CONFIG.CUSTOM_TRADE_APPROVAL_REMINDER_EMAIL ? { ...data, ...emailNotificationSettings[rootKey]} : {}
        return {
          ...config,
          id: `${rootKey}.${IS_CHECKED_KEY}${i}`,
          title: labels[titleKey],
          subTitle: labels[subTitleKey],
          rootKey,
          parentKey,
          isChecked: emailSettings[IS_CHECKED_KEY],
          isEnabled: emailSettings[IS_ENABLED_KEY]
        };
      })
    }));
  }
);

export const accountIdentifierPreferenceSelector = createSelector(
  accountIdentifierPreference, displaySettingsConfigSelector,
  (accountIdentifier, displaySettingsConfig) => {
    const accountPreferencesConfig = displaySettingsConfig.find(config => config.valueKey === Constants.ACCOUNT_IDENTIFIER) || {list: []};
    const accountIdentifierConfig = accountPreferencesConfig.list.find(data => data.value === accountIdentifier) ||  {tradeBlotterKey: ''};
    return accountIdentifierConfig.tradeBlotterKey || '';
  }
);

export const fundIdentifierPreferenceSelector = createSelector(
  fundIdentifierPreference, displaySettingsConfigSelector,
  (fundIdentifier, displaySettingsConfig) => {
    const fundPreferencesConfig = displaySettingsConfig.find(config => config.valueKey === Constants.FUND_IDENTIFIER) || {list: []};
    const fundIdentifierConfig = fundPreferencesConfig.list.find(data => data.value === fundIdentifier) ||  {tradeBlotterKey: ''};
    return fundIdentifierConfig.tradeBlotterKey || '';
  }
);

export const fundTrackerFundIdentifierPreferenceSelector = createSelector(
  fundIdentifierPreference, displaySettingsConfigSelector,
  (fundIdentifier, displaySettingsConfig) => {
    const fundPreferencesConfig = displaySettingsConfig.find(config => config.valueKey === Constants.FUND_IDENTIFIER) || {list: []};
    const {fundTrackerKey} = fundPreferencesConfig.list.find(data => data.value === fundIdentifier) ||  {fundTrackerKey: ''};
    return fundTrackerKey || '';
  }
);

export const allowPDFDownloadFeatureSelector = createSelector(
  fileDownloadConfigSelector,
  (downloadConfig) => {
    const {pdfConfig} = downloadConfig;
    return pdfConfig && pdfConfig.allowPDFDownload;
  }
);
