import { ButtonBase, ButtonProps } from './button-base';

/**
 * The Edit button
 * @param props the props of the edit button
 */
export const ButtonEdit = (props: ButtonProps) => {
    const icon = props.icon || { name: 'edit', type: 'outlined' };
    return (
        <ButtonBase
            icon={icon}
            label={props.label || 'Edit'}
            disabled={props.disabled}
            onClick={props.onClick}
            displayType={props.displayType}
            emphasis={props.emphasis}
            size={props.size}
            actionType={props.actionType || 'secondary'}
        />
    );
};
