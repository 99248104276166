import {
    useRef,
    useState,
    CSSProperties,
    HTMLAttributes,
    ReactNode,
    FC,
    memo,
    useEffect,
} from 'react';
import { Transition } from 'react-transition-group';
import {
    CollapseProps as CollapseCommonProps,
    collapseStyleSheet,
} from '@gs-ux-uitoolkit-common/collapse';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { cx, useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { componentAnalytics } from './analytics-tracking';
import { omit, pick } from 'gs-uitk-lodash';

const TransitionPropTypeKeys = [
    'in',
    'mountOnEnter',
    'unmountOnExit',
    'appear',
    'enter',
    'exit',
    'timeout',
    'onEnter',
    'onEntering',
    'onEntered',
    'onExit',
    'onExiting',
    'onExited',
];

export interface CollapseProps
    extends CollapseCommonProps<CSSProperties>,
        HTMLAttributes<HTMLElement> {
    children?: ReactNode;
    appear?: boolean;
    enter?: boolean;
    exit?: boolean;
    timeout?:
        | number
        | { appear?: number | undefined; enter?: number | undefined; exit?: number | undefined };

    /**
     * Handler for the expand animation starting.
     */
    onBeforeExpand?: () => void;

    /**
     * Handler for the expand animation completing.
     */
    onExpand?: () => void;

    /**
     * Handler for the collapse animation starting.
     */
    onBeforeCollapse?: () => void;

    /**
     * Handler for the collapse animation completing.
     */
    onCollapse?: () => void;
}

export const Collapse: FC<CollapseProps> = memo(props => {
    const {
        expanded = false,
        children,
        className,
        onBeforeExpand: onBeforeExpandProp,
        onExpand: onExpandProp,
        onBeforeCollapse: onBeforeCollapseProp,
        onCollapse: onCollapseProp,
        appear = false,
        enter = true,
        exit = true,
        timeout = 250,
        ...otherProps
    } = props;
    const ref = useRef(null);
    const [height, setHeight] = useState(0);
    const cssClasses = useStyleSheet(collapseStyleSheet, null);
    function getTransitionClass(status: string) {
        switch (status) {
            case 'entering':
                return cssClasses.collapsing;
            case 'entered':
                return cssClasses.show;
            case 'exiting':
                return cssClasses.collapsing;
            case 'exited':
                return cssClasses.collapse;
            default:
                return cssClasses.collapse;
        }
    }

    function onBeforeExpand(_node: HTMLElement, _isAppearing: boolean) {
        setHeight((ref!.current! as HTMLElement).scrollHeight);
        if (onBeforeExpandProp) {
            onBeforeExpandProp();
        }
    }

    // this function adds the expanding animation
    function onExpanding(_node: HTMLElement, _isAppearing: boolean) {
        setHeight((ref!.current! as HTMLElement).scrollHeight);
    }

    function onExpand(_node: HTMLElement, _isAppearing: boolean) {
        setHeight(0);
        if (onExpandProp) {
            onExpandProp();
        }
    }

    function onBeforeCollapse(_node: HTMLElement) {
        setHeight((ref!.current! as HTMLElement).scrollHeight);
        if (onBeforeCollapseProp) {
            onBeforeCollapseProp();
        }
    }

    // this function adds the collapsing animation
    function onCollapsing(_node: HTMLElement) {
        // getting this variable triggers a reflow
        const unused = (ref!.current! as HTMLElement).offsetHeight;
        if (unused === null) {
            setHeight(0);
        }
        setHeight(0);
    }

    function onCollapse(_node: HTMLElement) {
        setHeight(0);
        if (onCollapseProp) {
            onCollapseProp();
        }
    }

    const transitionProps = {
        ...pick(otherProps, TransitionPropTypeKeys),
        ...{
            appear,
            enter,
            exit,
            timeout,
        },
    };
    const childProps = omit(otherProps, TransitionPropTypeKeys);

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'collapse' });
    }, []); // Only run once

    useTheme(); // not consuming the theme just yet, but injects the fonts into the DOM
    return (
        <Transition
            {...transitionProps}
            addEndListener={() => {}}
            in={expanded}
            onEnter={onBeforeExpand}
            onEntering={onExpanding}
            onEntered={onExpand}
            onExit={onBeforeCollapse}
            onExiting={onCollapsing}
            onExited={onCollapse}
            nodeRef={ref}
        >
            {(status: string) => {
                const collapseClass = getTransitionClass(status);
                const classes = cx(collapseClass, className);
                const style = height === 0 ? null : { height };
                return (
                    <div
                        {...childProps}
                        data-gs-uitk-component="collapse"
                        style={{ ...childProps.style, ...style }}
                        className={classes}
                        aria-hidden={!expanded}
                        ref={ref}
                    >
                        {children}
                    </div>
                );
            }}
        </Transition>
    );
});
Collapse.displayName = 'Collapse';
