import {
    DataGridDensity,
    DataGridSize,
    DATAGRID_DENSITY_AND_SIZE_TO_HEADER_VERTICAL_PADDING_MAP,
    DATAGRID_DENSITY_AND_SIZE_TO_ROW_VERTICAL_PADDING_MAP,
    getLineHeightForSize,
} from '../interfaces/styles';

export interface ThemeItem {
    themeClassNames: string;
    headerHeight: number;
    rowHeight: number;
    floatingFilterHeight: number;
}

export const ThemeTypeClassName = {
    AG_GRID_THEME: 'ag-theme-balham',
    AG_GRID_DARK_THEME: 'ag-theme-balham-dark',
    ROOT: 'gs-ux-uitoolkit-ag-grid',
};

// TODO: Convert to class with static methods
export const ThemeManager = {
    getTheme(density?: DataGridDensity, size?: DataGridSize): ThemeItem {
        // Theme will be deprecated in v15. If @param {size} or @param {density} are specified as
        // prop(s) they will take precedence over the values set in the chosen/default theme.
        const sizeClass = size ? `gs-uitk-datagrid-size-${size}` : '';
        const [densityHeaderHeight, densityRowHeight, densityFloatingFilterHeight] = density
            ? this.calculateHeightsForDensity(density, size)
            : [0, 0];
        return {
            headerHeight: densityHeaderHeight || 24,
            rowHeight: densityRowHeight || 28,
            themeClassNames: `${sizeClass}`.trim(),
            floatingFilterHeight: densityFloatingFilterHeight || 32,
        };
    },
    /**
     * Calculates the appropriate heights for a grid's headers and rows by adding the base line
     * height derived from the font size selected (default 'sm') with additional top and bottom
     * padding determined by density specified.
     * @param density - Density prop specified in {@link DataGrid}
     * @param size - Size prop specified in {@link DataGrid}
     */
    calculateHeightsForDensity(density: DataGridDensity, size?: DataGridSize): number[] {
        let densityHeaderHeight = 0,
            densityRowHeight = 0,
            densityFloatingFilterHeight = 0,
            lineHeight = 0;

        const appliedSize = density === 'extraCompact' || !size ? 'sm' : size;
        lineHeight = getLineHeightForSize(appliedSize);

        const headerPadding =
            DATAGRID_DENSITY_AND_SIZE_TO_HEADER_VERTICAL_PADDING_MAP.get(
                `${density}-${appliedSize}`
            ) || 1;

        densityHeaderHeight = lineHeight + 2 * headerPadding;

        densityFloatingFilterHeight = densityHeaderHeight + headerPadding;

        densityRowHeight =
            lineHeight +
            2 *
                (DATAGRID_DENSITY_AND_SIZE_TO_ROW_VERTICAL_PADDING_MAP.get(
                    `${density}-${appliedSize}`
                ) || 1);

        return [densityHeaderHeight, densityRowHeight, densityFloatingFilterHeight];
    },
};
