export interface Expression {
    query: ExpressionQuery;
    mode: ExpressionMode;
}

export type ExpressionQuery = ExpressionRule | ExpressionGroup | EmptyExpressionRule;

export interface ExpressionRule {
    field: string;
    negate?: boolean;
    pivotKeys?: string[];
    operator: ExpressionOperator;
    value: ExpressionValue;
    formattedValue?: ExpressionValue;
    valueTypeRule?: ValueTypeRule;
}

export type ExpressionValue = string | number | Date | null | undefined;

export enum ValueTypeRule {
    DisplayValue = 'DisplayValue',
    RawValue = 'RawValue',
}

export interface ExpressionGroup {
    condition: ExpressionCondition;
    rules: ExpressionQuery[];
    negate?: boolean;
}

export const EXPRESSION_GROUP_CONDITION_PROPERTY_NAME = 'condition';

export enum ExpressionCondition {
    And = 'AND',
    Or = 'OR',
}

export enum ExpressionOperator {
    Equals = '=',
    NotEquals = '!=',
    GreaterThan = '>',
    GreaterThanOrEqual = '>=',
    LessThan = '<',
    LessThanOrEqual = '<=',
    Contains = 'contains',
    ContainsCaseInsensitive = 'contains_case_insensitive',
    NotContains = 'not_contains',
    NotContainsCaseInsensitive = 'not_contains_case_insensitive',
    QuickFilter = 'Satisfy quick filter ',
    StartsWith = 'starts_with',
    EndsWith = 'ends_with',
}

export enum ExpressionMode {
    Basic = 'Basic',
    Experienced = 'Experienced',
}

export const NEGATE_FRIENDLY_STRING = 'Not';

export const EMPTY_EXPRESSION = 'EMPTY';

export type EmptyExpression = typeof EMPTY_EXPRESSION;

export interface EmptyExpressionRule {
    type: EmptyExpression;
    emptyFilterShowAll: boolean;
}
