import React, { Fragment, useCallback, useEffect, useReducer, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { DebounceInput } from "react-debounce-input";
import cloneDeep from "lodash/cloneDeep";
import Entity from "./Entity";
import FirmBranch from "./FirmBranch";

const escapeRegExp = (text) => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const filterEntityOnSearch = ({initial: initialEntityMap, filterText}) => {
  const filterTextWithEscapes = escapeRegExp(filterText);
  const regExp = new RegExp(filterTextWithEscapes, 'gi');
  const newEntityMap = initialEntityMap.filter((entity) => {
    const { label } = entity;
    return regExp.test(label);
  })
  return newEntityMap;
}

const getChangedFirmBranch = ({initial: initialEntityMap, filterText}) => {
  const filterTextWithEscapes = escapeRegExp(filterText);
  const regExp = new RegExp(filterTextWithEscapes, 'gi');
  const filteredData = []
  initialEntityMap.forEach((entity) => {
    const clone = {...entity};
    const { label, children } = clone;
    const firmFound = regExp.test(label);
    const childrenMatch = children.filter((branch) => {
      const { label } = branch;
      return regExp.test(label);
    });
    if (firmFound) {
      filteredData.push(clone);
    } else if(childrenMatch.length > 0) {
      clone.children = childrenMatch;
      filteredData.push(clone);
    }
  })
  return filteredData;
}

const contReducer = (state, action) => {
  const { type, data } = action;
  const newState = {...state};
  let filterText = "";
  switch(type) {
    case "SEARCH_CHANGED": {
      newState.showSearch = data;
      return;
    }
    case "FILTER_TEXT_CHANGED": {
      newState.filterText = data;
      break;
    }
    default: {
      return state;
    }
  }
  filterText = newState.filterText;
  const { initial, entityType } = newState;
  if (filterText !== "") {
    if (entityType === "firmBranch") {
      newState.entityMap = getChangedFirmBranch({initial, filterText});
    }
    else {
      newState.entityMap = filterEntityOnSearch({initial, filterText});
    }
  }
  return newState;
}

const getFirmBranchSelected = ({firmNodeMapSelected, branchNodeMapSelected}) => {
  return Object.keys(firmNodeMapSelected).concat(Object.keys(branchNodeMapSelected));
}

const getTotalFirmBranchSelection = (entityMap = []) => {
  let count = 0;
  entityMap.forEach((node) => {
    const { children = [] } = node;
    count += children.length + 1;
  })
  return count;
}

const EntityContainer = React.memo((props) => {
  const { entityMap, selected, entityType, placeholder, label, handleChange: dispatchAction } = props;
  const [showSearch, setShowSearch] = useState(false);
  const [state, containerDispatch] = useReducer(
    contReducer,
    {
      filterText: "",
      entityType,
      entityMap,
      totalSelection: entityType === "firmBranch" ? getTotalFirmBranchSelection(entityMap) : -1,
      initial: [...entityMap],
      prevEntityMap: cloneDeep(entityMap)
    }
  );

  useEffect(() => {
    console.log("EntityContainer Completed!!!!");
  }, []);

  let inputRef = useRef();
  const handleChange = useCallback((e) => {
    const value = e.target.value.trim();
    containerDispatch({ type: "FILTER_TEXT_CHANGED", data: value });
  }, []);

  useEffect(() => {
    showSearch && inputRef.focus();
  }, [showSearch]);

  const toggleSearch = useCallback(() =>
    setShowSearch(!showSearch)
  , [showSearch]);
  const handleClear = useCallback(() => containerDispatch({ type: "FILTER_TEXT_CHANGED", data: ""}), []);

  return (
    <Fragment>
      <div className="header">
        <div className="title">{label}</div>
        <button
          onClick={toggleSearch}
          className="filters__search-icon"
        />
      </div>
      <div className="filters__containerColumn">
        { showSearch &&
          <div className="rct-list__search">
            <DebounceInput
              inputRef={(ref) => {
                inputRef = ref;
              }}
              type="text"
              placeholder={placeholder}
              className="rct-list__search-box"
              value={state.filterText}
              debounceTimeout={500}
              forceNotifyByEnter={true}
              onChange={handleChange}
            />{" "}
            <span className="rct-list__search-clear" onClick={handleClear} />
          </div>
        }
        {
          entityType === "firmBranch" ?
            <FirmBranch
              entityMap={state.filterText !== "" ? state.entityMap : entityMap}
              handleChange={dispatchAction}
              filterText={state.filterText}
              selected={getFirmBranchSelected(selected)}
              entityType={entityType}
              totalSelection={state.totalSelection}
            /> :
            <Entity
              entityMap={state.filterText !== "" ? state.entityMap : entityMap}
              handleChange={dispatchAction}
              filterText={state.filterText}
              selected={selected}
              entityType={entityType}
            />
        }
      </div>
    </Fragment>
  )
})

EntityContainer.propTypes = {
  entityMap: PropTypes.array,
  handleChange: PropTypes.func,
  selected: PropTypes.object,
  placeholder: PropTypes.string,
  entityType: PropTypes.string,
  label: PropTypes.string
}

export default EntityContainer;
