import { FormContext } from '@gs-ux-uitoolkit-common/form';
import { useFormContext } from '@gs-ux-uitoolkit-react/form';
import { InputStatus } from '@gs-ux-uitoolkit-common/input';

export interface ImportFormContext extends Exclude<FormContext, 'status'> {
    status: InputStatus;
}

export function useFormInputContext(props: FormContext): ImportFormContext {
    const { disabled, inline, size, status: formStatus } = useFormContext(props);

    return {
        disabled,
        inline,
        size,
        status: (formStatus === 'information' ? 'none' : formStatus) as InputStatus,
    };
}
