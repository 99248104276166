import moment from 'moment-business-days';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import Constants, {TRADEBLOTTER_FUND_FEES} from '../constants/appConstants';
import {injectKeys} from './dynamici18nInjection';
import {regex} from '../helpers/regex';
import ErrorTypes from '../error/errorType';
import {INTENDED_SOURCE_SYSTEM} from '../constants/tradeBlotter';

export const hasWarning = (config, trade) => {
  if (config && trade && config.validationRules && (trade.warnings || trade.inlineWarnings)) {
    const {warnings = [], inlineWarnings = []} = trade;
    return (
      warnings.some(({code}) => Object.prototype.hasOwnProperty.call(config.validationRules, code)) ||
      inlineWarnings.some(({code}) => Object.prototype.hasOwnProperty.call(config.validationRules, code))
    );
  }
  return false;
};

export const hasDateUpdateWarning = (key, trade) => {
  if (trade && (trade.inlineWarnings)) {
    const {inlineWarnings = []} = trade;
    return (
      inlineWarnings.some(warning => warning.code === key)
    );
  }
  return false;
};

export const hasError = (config, trade) => {
  if (config && trade && config.validationRules && (trade.errors || trade.inlineErrors)) {
    const {errors = [], inlineErrors = []} = trade;
    return (
      errors.some(({code})  => Object.prototype.hasOwnProperty.call(config.validationRules, code)) ||
      inlineErrors.some(({code}) => Object.prototype.hasOwnProperty.call(config.validationRules, code))
    );
  }
  return false;
};

export const createErrorObject = (code = '', labels, injectKey) => {
  return ({
    code,
    message: injectKey ? injectKeys(labels[code], injectKey) : labels[code],
    description: code
  });
};


export const getTradeDateChangeWarning = (labels) => {
  const inlineWarnings = [];
  const warning = createErrorObject('TRADE_DATE_AUTO_UPDATE_WARNING', labels);
  inlineWarnings.push(warning);
  return inlineWarnings;
};

export const runInlineValidations = (trade, config, tradeRowValidationRules = [], labels, currencySymbols, isTradeDateUpdated) => {
  const inlineInformation = [];
  const inlineErrors = [];
  const inlineWarnings = [];
  const {warnings: existingTradeWarnings = []} = trade;

  tradeRowValidationRules.forEach(validationRule => {
    switch (validationRule) {
      case 'NAV_UNAVAILABLE_ERROR':
        if (trade.options && isUndefined(trade.options.nav)) {
          const error = createErrorObject(validationRule, labels);
          inlineErrors.push(error);
        }
        break;

      // this uses dynamic link injection so message will be created in TradeRowErrors container
      case 'MODIFY_NEW_ACCOUNT_INFO':
        if (trade.isNewAccount) {
          const information = {
            code: 'MODIFY_NEW_ACCOUNT_INFO',
            message: '',
            description: ''
          };

          inlineInformation.push(information);
        }
        break;
      default:
        break;
    }
  });

  Object.keys(config).forEach(key => {
    const subConfig = config[key];

    if (subConfig.validationRules) {
      const rules = Object.keys(subConfig.validationRules);
      rules.forEach(rule => {
        switch (rule) {
          case 'TRADEBLOTTER_9611':
            if (trade.amountType && trade.amountType === Constants.SHARES_DATA_KEY) {
              const isZero = (!isUndefined(trade.tradeAmount) && trade.tradeAmount === '') || (new RegExp(regex.isZeroShares)).test(trade.tradeAmount);
              if (isZero) {
                const error = createErrorObject(rule, labels);
                inlineErrors.push(error);
              }
            }
            break;
          case 'TRADEBLOTTER_9610':
            if (trade.amountType && trade.amountType === Constants.CURRENCY_DATA_KEY) {
              const isZero = (!isUndefined(trade.tradeAmount) && trade.tradeAmount === '') || (new RegExp(regex.isZeroCurrency)).test(trade.tradeAmount);
              if (isZero) {
                const error = createErrorObject(rule, labels);
                inlineErrors.push(error);
              }
            }
            break;
          case 'TRADEBLOTTER_22047':
            if (trade.tradeType && trade.tradeType === Constants.TRADE_TYPE_REDEEM_DATA_KEY && trade.options && trade.options.paymentInstructions && (!trade.options.paymentInstructions.redeem || !trade.options.paymentInstructions.redeem.length)) {
              const error = createErrorObject(rule, labels);
              inlineErrors.push(error);
            }
            break;
          case 'TRADEBLOTTER_9682':
            if (trade.shareClass && trade.shareClass.isFeeActive) {
              const warning = createErrorObject(rule, labels);
              inlineWarnings.push(warning);
            }
            break;
          case 'REDEMPTION_GATE_WARNING':
            if (trade.shareClass && trade.shareClass.isGateActive && trade.shareClass.taId && trade.shareClass.taId !== 2) {
              const warning = createErrorObject(rule, labels);
              inlineWarnings.push(warning);
            }
            break;
          case 'MAX_TRADE_AMOUNT_ERROR': {
            const {shareClassCurrency} = trade;
            if (trade.tradeAmount && +trade.tradeAmount > 1000000000000) {
              const error = createErrorObject(rule, labels, currencySymbols[shareClassCurrency]);
              inlineErrors.push(error);
            }
            break;
          }
          case 'IS_SUSPENSION_ACTIVE_ERROR':
            if (trade.shareClass && trade.shareClass.isSuspended) {
              const error = createErrorObject(rule, labels);
              inlineErrors.push(error);
            }
            break;
          case 'ACCOUNT_BIN_MISSING':
            if (trade.shareClass && trade.account && Object.keys(trade.account).length > 1 && trade.shareClass.taId === 1 && !trade.account.bin) {
              const error = createErrorObject(rule, labels);
              inlineErrors.push(error);
            }
            break;
          case 'EU_REDEMPTION_GATE_WARNING':
            if (trade.shareClass && trade.shareClass.isGateActive && trade.shareClass.taId === 2) {
              const warning = createErrorObject(rule, labels);
              inlineWarnings.push(warning);
            }
            break;
          case 'TRADE_DATE_AUTO_UPDATE_WARNING':
            if (isTradeDateUpdated) {
              const warning = createErrorObject(rule, labels);
              inlineWarnings.push(warning);
            }
            break;
          case 'TRADE_DATE_CUTOFF_WARNING':
            if (trade.options && trade.options.userTimeZone) {
              const userDate = moment().tz(trade.options.userTimeZone).format('YYYY-MM-DD');
              if (trade.options.tradeDate !== userDate && (trade.options.purchaseNoticePeriod === 'T' || trade.options.redemptionNoticePeriod === 'T')) {
                const warning = createErrorObject(rule, labels);
                inlineWarnings.push(warning);
              }
            }
            break;
          case 'TRADE_DATE_NOTICE_INFO':
            if (trade.options && trade.options.purchaseNoticePeriod !== 'T' && trade.options.redemptionNoticePeriod !== 'T') {
              const warning = createErrorObject(rule, labels);
              inlineWarnings.push(warning);
            }
            break;
          case 'TRADEBLOTTER_200010':
            if (trade.shareClass && trade.shareClass.fundFees) {
              const discretionaryFeeItem = trade.shareClass.fundFees.find(({subjectToFee, feeTypeShortCode}) =>
                subjectToFee && feeTypeShortCode === TRADEBLOTTER_FUND_FEES.DISCRETIONARY_FEE
              );
              if (discretionaryFeeItem && trade.options) {
                const {tradeDate} = trade.options;
                const {feeEffectiveDate, feePercent} = discretionaryFeeItem;
                const isFeePercentPresent = feePercent && (feePercent !== '0.00');
                if ((moment(tradeDate) >= moment(feeEffectiveDate)) && isFeePercentPresent) {
                  inlineWarnings.push(createErrorObject(rule, labels));
                }
              }
            }
            break;
          default:
            break;
        }
      });
    }
  });

  // Filter the inline warnings, if the same warning already present as part of Trade Warnings
  const updatedInlineWarnings = inlineWarnings
    .filter(({code: inlineWarningCode}) => (
      !existingTradeWarnings.some(({code: tradeWarningCode}) =>
        tradeWarningCode === inlineWarningCode
      )
    ));

  return ({
    inlineErrors,
    inlineInformation,
    inlineWarnings: updatedInlineWarnings
  });
};

export const mapServerValidations = ({rules = [], tradeData = {}, labels = {}}) => {
  return rules.map(rule => {
    const mappedRule = {...rule};
    switch (mappedRule.code) {
      case 'TRADEBLOTTER_2999': {
        const {tradeType} = tradeData;
        mappedRule.message = tradeType && labels[mappedRule.code] ? injectKeys(labels[mappedRule.code], tradeType) : mappedRule.message;
        break;
      }
      case 'TRADEBLOTTER_9683': {
        const {options: {nextNavStrikeTime} = {}} = tradeData;
        mappedRule.message = nextNavStrikeTime && labels[mappedRule.code] ? injectKeys(labels[mappedRule.code], nextNavStrikeTime) : mappedRule.message;
        break;
      }
      default:
        // assume this is a rule that doesn't need dynamic injection
        mappedRule.message = labels[mappedRule.code] ? labels[mappedRule.code] : mappedRule.message;
        break;
    }
    return mappedRule;
  });
};

export const runServerValidations = (serverTradeData, clientTradeData, labels) => {
  const {errors = [], warnings = []} = serverTradeData;
  const tradeData = {...serverTradeData, ...clientTradeData};
  const mappedErrors = mapServerValidations({rules: errors, tradeData, labels});
  const mappedWarnings = mapServerValidations({rules: warnings, tradeData, labels});
  return ({
    errors: mappedErrors,
    warnings: mappedWarnings
  });
};


export const isRowComplete = (rowData, config) => {
  if (isUndefined(config)) {
    return false;
  }

  return Object.keys(config).every((key) => {
    const {isKeyField = false, valid = {}} = config[key];
    let data = rowData[key];
    const validKeys = Object.keys(valid);
    if (validKeys.length !== 0) {
      const areAllScenarioValid = validKeys.every((validKey) => (rowData[validKey] === valid[validKey]));
      if (areAllScenarioValid) {
        data = true;
      }
    }
    return (isKeyField && !isEmpty(data) || (!isKeyField && !!data));
  });
};

export const isOnlyOneFirmAndBranch = (firms) => {
  return firms && firms.length === 1;
};

export const getDefaultTradeData = (options = {}) => {
  const {tradeDate, settlementCycle, tradeType, amountType, paymentInstructions} = options;
  const defaultOptions = {};

  if (tradeDate && tradeDate !== '') {
    defaultOptions[Constants.TRADE_DATE] = tradeDate;
    if (settlementCycle && settlementCycle[Constants.SETTLEMENT_CYCLE_CURRENT] && settlementCycle[Constants.SETTLEMENT_CYCLE_CURRENT].length === 1) {
      const [defaultSettlementCycle] = settlementCycle[Constants.SETTLEMENT_CYCLE_CURRENT];
      defaultOptions[Constants.SETTLEMENT_CYCLE] = defaultSettlementCycle;

      if (tradeType && tradeType.length === 1) {
        const [defaultTradeType] = tradeType;
        defaultOptions[Constants.TRADE_TYPE] = defaultTradeType;
        if (amountType && amountType[defaultTradeType] && amountType[defaultTradeType].length === 1) {
          const [defaultAmountType] = amountType[defaultTradeType];
          defaultOptions[Constants.AMOUNT_TYPE] = defaultAmountType;
          if (defaultAmountType === Constants.AMOUNT_TYPE_ALL_DATA_KEY) {
            defaultOptions[Constants.TRADE_AMOUNT] = null;
          }
        }
        if (paymentInstructions && paymentInstructions[defaultTradeType] && paymentInstructions[defaultTradeType].length === 1) {
          const [defaultPaymentInstruction] = paymentInstructions[defaultTradeType];
          const {paymentInstructionId} = defaultPaymentInstruction;
          defaultOptions[Constants.PAYMENT_INSTRUCTION_ID] = paymentInstructionId;
        }
      }
    }
  }

  return defaultOptions;
};

// NOTE: This is a growing list based on new business logic
const findInternalAccountErrors = (trade) => (
  (trade.inlineErrors && trade.inlineErrors.filter(error => error.code === ErrorTypes.TA_ACCOUNT_NOT_FOUND || error.code === ErrorTypes.UNABLE_TO_FETCH_ACCOUNTS || error.code === ErrorTypes.ACCOUNT_INVALID)) || []
);

export const getTradesDataSet = (draftTrades, configs, tradeRowValidationRules, labels, isInternal, currencySymbols) => {
  const completeTrades = {};
  const validTrades = {};
  const normalizedDraftTrades = draftTrades.map(draftTrade => {
    let trade;
    let internalAccountErrors = [];

    if (isInternal) {
      internalAccountErrors = findInternalAccountErrors(draftTrade);
      if (internalAccountErrors.length) {
        const {isNewAccount, hasOpenAccounts, displayPosition, account} = draftTrade;
        trade = {isNewAccount, hasOpenAccounts, displayPosition, account, inlineErrors: internalAccountErrors};
      }
    }

    // trade by either an external user or an internal user without account errors
    if (!internalAccountErrors.length) {
      trade = {...draftTrade};

      const {firm, tradeDate, settlementCycle, tradeType, amountType, options} = trade;
      if (firm && Object.keys(firm).length) {
        const {id: firmId, firmCode, name: firmName, subEntities} = firm;
        const {id: branchId, branchCode, name: branchName} = subEntities[0];
        const name = firmCode && branchCode && `${firmCode}-${branchCode}`;
        trade.firm = {firmId, firmCode, firmName, branchId, branchCode, branchName, name};
      }

      if (!tradeDate && !settlementCycle && !tradeType && !amountType) {
        const {tradeDate, settlementCycle, tradeType, amountType} = getDefaultTradeData(options);
        trade = {...trade, tradeDate, settlementCycle, tradeType, amountType, options};
      }
    }

    const {inlineErrors: serverInlineErrors} = trade;
    const {inlineErrors = [], inlineWarnings = [], inlineInformation = []} = runInlineValidations(trade, configs, tradeRowValidationRules, labels, currencySymbols);
    const mappedServerInlineErrors = mapServerValidations({rules: serverInlineErrors, labels});
    const updatedInlineErrors = [...inlineErrors, ...mappedServerInlineErrors];

    if (isRowComplete(trade, configs)) {
      completeTrades[trade[Constants.DISPLAY_POSITION]] = true;
      if (inlineErrors.length === 0) {
        validTrades[trade[Constants.DISPLAY_POSITION]] = true;
      }
    }

    // Add Inline Error Analytics

    return ({...trade, inlineErrors: updatedInlineErrors, inlineWarnings, inlineInformation});
  });
  return {normalizedTrades: sortBy(normalizedDraftTrades, [Constants.DISPLAY_POSITION]), completeTrades, validTrades};
};

export const getNormalizedFirms = ({firms, selectedFirm, selectedSubEntity}) => {
  const data = [];
  const defaultSelect = (firms.length === 1 && firms[0].subEntities.length === 1);
  let defaultSelection = {};
  firms.forEach((firm, firmIndex) => {
    const {id: firmId, firmCode, name: firmName, subEntities} = firm;
    subEntities.forEach((subEntity, subEntityIndex) => {
      const {id: branchId, branchCode, name: branchName} = subEntity;
      if ((defaultSelect && firmIndex === 0 && subEntityIndex === 0) || (selectedFirm === firmIndex && selectedSubEntity === subEntityIndex)) {
        defaultSelection = {
          firmId,
          firmCode,
          firmName,
          branchId,
          branchCode,
          branchName,
          name: `${firmCode}-${branchCode}`
        };
        data.push({
          firmId,
          firmCode,
          firmName,
          branchId,
          branchCode,
          branchName,
          name: `${firmCode}-${branchCode}`,
          isSelected: true
        });
      } else {
        data.push({firmId, firmCode, firmName, branchId, branchCode, branchName, name: `${firmCode}-${branchCode}`});
      }
    });
  });
  return {normalizedFirms: data, firm: (Object.keys(defaultSelection).length !== 0) && defaultSelection};
};

export const getNormalizedShareClasses = ({shareClasses, selectedShareClass, shouldNotAutoDefault}) => {
  const data = JSON.parse(JSON.stringify(shareClasses));
  const shareClassIndex = shouldNotAutoDefault ? -1 : (shareClasses.length === 1) ? 0 : selectedShareClass;
  let defaultSelection = {};
  if (shareClassIndex !== -1) {
    defaultSelection = {...data[shareClassIndex]};
    data[shareClassIndex].isSelected = true;
  }
  return {
    normalizedShareClasses: data,
    shareClass: (Object.keys(defaultSelection).length !== 0) && defaultSelection
  };
};

export const getNormalizedAccounts = ({accounts, selectedAccount, prevAccount, shouldNotAutoDefault, isInternalUser}) => {
  if (isInternalUser && accounts.length !== 1) {
    return {normalizedAccounts: [], account: prevAccount};
  }
  const data = [];
  const accountIndex = shouldNotAutoDefault ? -1 : (accounts.length === 1) ? 0 : selectedAccount;
  let defaultSelection = {};
  accounts.forEach((account, index) => {
    const {bin, name, taAccount} = account;
    if (accountIndex === index) {
      defaultSelection = {...account, accountHash: `${name}${bin}${taAccount}`};
      data.push({...account, accountHash: `${name}${bin}${taAccount}`, isSelected: true});
    } else {
      data.push({...account, accountHash: `${name}${bin}${taAccount}`});
    }
  });
  return {normalizedAccounts: data, account: (Object.keys(defaultSelection).length !== 0) && defaultSelection};
};

export const isAutoDefaulted = ({previous, next}) => {
  if (isUndefined(next.firm) && isUndefined(next.shareClass) && isUndefined(next.account)) {
    return false;
  }
  const {firm = {}, shareClass = {}, account = {}} = previous;
  return !(isEqual(firm && firm.firmId, next.firm && next.firm.firmId) && isEqual(firm && firm.branchId, next.firm && next.firm.branchId)) ||
    !isEqual(shareClass && shareClass.id, next.shareClass && next.shareClass.id) || !isEqual(account && account.taAccount, next.account && next.account.taAccount);
};

export const getAssociatedFieldDefaults = (key, selectedValue, data, associatedFields) => {
  const obj = {};
  if (!data || !data.options) return obj;
  if (key === Constants.TRADE_DATE) {
    const settlementCycleType = new Date(selectedValue) > new Date(data.options.tradeDate) ? Constants.SETTLEMENT_CYCLE_FUTURE : Constants.SETTLEMENT_CYCLE_CURRENT;
    if (data.options.settlementCycle[settlementCycleType] && data.options.settlementCycle[settlementCycleType].length === 1) {
      const [associatedFieldData] = data.options.settlementCycle[settlementCycleType];
      const associatedKey = associatedFields[2];
      obj[associatedKey] = associatedFieldData;
    } else {
      const associatedKey = associatedFields[2];
      obj[associatedKey] = undefined;
    }
  }
  if ((key === Constants.SETTLEMENT_CYCLE && data[Constants.TRADE_DATE] !== '') || (key === Constants.TRADE_DATE && data[Constants.SETTLEMENT_CYCLE] !== '')) {
    const tradeType = data.options[Constants.TRADE_TYPE];
    if (tradeType.length === 1) {
      const associatedKey = associatedFields[0];
      const [defaultValue] = tradeType;
      obj[associatedKey] = defaultValue;

      const amountType = data.options[Constants.AMOUNT_TYPE][defaultValue];
      if (amountType.length === 1) {
        const associatedKey = associatedFields[1];
        const [defaultValue] = amountType;
        obj[associatedKey] = defaultValue;
      }
    }
  }
  if (key === Constants.TRADE_TYPE) {
    const amountType = data.options[Constants.AMOUNT_TYPE];
    const paymentInstructions = data.options[Constants.PAYMENT_INSTRUCTIONS];
    if (amountType[selectedValue] && amountType[selectedValue].length === 1) {
      const [defaultAmountType] = amountType[selectedValue];
      const associatedKey = associatedFields[0];
      obj[associatedKey] = defaultAmountType;
    } else {
      const associatedKey = associatedFields[0];
      obj[associatedKey] = undefined;
    }
    const associatedKey = associatedFields[1];
    obj[associatedKey] = undefined;
    if (paymentInstructions[selectedValue] && paymentInstructions[selectedValue].length === 1) {
      const [defaultPaymentInstruction] = paymentInstructions[selectedValue];
      const {paymentInstructionId} = defaultPaymentInstruction;
      const associatedKey = associatedFields[2];
      obj[associatedKey] = paymentInstructionId;
    } else {
      const associatedKey = associatedFields[2];
      obj[associatedKey] = undefined;
    }
  }
  if (key === Constants.AMOUNT_TYPE) {
    if (selectedValue === Constants.AMOUNT_TYPE_ALL_DATA_KEY) {
      const associatedKey = associatedFields[0];
      obj[associatedKey] = data.options.availableBalance;
    } else {
      const associatedKey = associatedFields[0];
      obj[associatedKey] = undefined;
    }
  }
  return obj;
};

export const getDisplayPosition = () => Math.floor(Date.now());

export const getNewTrade = () => {
  return {
    [Constants.DISPLAY_POSITION]: getDisplayPosition(),
    [Constants.IS_NEW_ACCOUNT]: false,
    intendedSourceSystem: INTENDED_SOURCE_SYSTEM.MOSAIC_TB
  };
};

export const includePaymentInstruction = (trade, draftTrade) => {
  const {options, paymentInstructionId, intendedSourceSystem} = trade;
  const sourceSystem = !intendedSourceSystem && draftTrade && draftTrade.intendedSourceSystem || intendedSourceSystem;

  if (paymentInstructionId && options && options.paymentInstructions) {
    const {paymentInstructions} = options;
    const {redeem} = paymentInstructions;
    const paymentInst = redeem.find((item) => {
      return item.paymentInstructionId === paymentInstructionId;
    });
    const paymentInstruction = {
      paymentInstructionId,
      isStdInstruction: paymentInst && paymentInst.label === 'STD'
    };
    return {...trade, paymentInstruction, intendedSourceSystem: sourceSystem};
  }
  return {...trade, intendedSourceSystem: sourceSystem};
};

export const getUpdatedDraftTrades = (activeTrade = {}, draftTrades = [], isWarningAcknowledgeMode = false) => {
  const updatedTrades = [];
  draftTrades.forEach(draftTrade => {
    if (draftTrade[Constants.DISPLAY_POSITION] === activeTrade[Constants.DISPLAY_POSITION]) {
      updatedTrades.push(includePaymentInstruction(activeTrade, draftTrade));
    } else {
      updatedTrades.push(includePaymentInstruction(draftTrade));
    }
  });
  // Don't send the warnings on Verify/Place Trade, but on 'Acknowledge and Proceed' mode
  if (!isWarningAcknowledgeMode) {
    return updatedTrades.map(trade => ({...trade, warnings: []}));
  }
  return updatedTrades;
};

export const getUpdatedCompleteAndValidTrades = (activeTrade, configs, completeTrades, validTrades) => {
  if (isRowComplete(activeTrade, configs)) {
    completeTrades[activeTrade[Constants.DISPLAY_POSITION]] = true;
    if ((!activeTrade.inlineErrors || !activeTrade.inlineErrors.length) && (!activeTrade.errors || !activeTrade.errors.length)) {
      validTrades[activeTrade[Constants.DISPLAY_POSITION]] = true;
    } else {
      delete validTrades[activeTrade[Constants.DISPLAY_POSITION]];
    }
  } else {
    delete completeTrades[activeTrade[Constants.DISPLAY_POSITION]];
    delete validTrades[activeTrade[Constants.DISPLAY_POSITION]];
  }
  return {
    completeTrades,
    validTrades
  };
};

export const getPartiallyFilledTrades = (draftTrades = []) => {
  return draftTrades.reduce((accumulator, draftTrade) => {
    const {firm = {}, account = {}, shareClass = {}} = draftTrade;
    if ((firm.firmId && firm.branchId) || shareClass.id || (account.taAccount || account.bin)) {
      accumulator.push(draftTrade);
    }
    return accumulator;
  }, []);
};

export const getDraftTradesFromPlain = (draftTrades) => {
  return draftTrades.map(draftTrade => {
    const {firmId, branchId, shareClassId, ...rest} = draftTrade;
    return {
      ...rest,
      firm: {
        id: firmId,
        subEntities: [{
          id: branchId
        }]
      },
      shareClass: {
        id: shareClassId
      }
    };
  });
};

export const getAccountLabelKey = (isInternalUser, account, isNewAccount, accountIdentifierPreference) => {
  // Shell Account with newly created bin
  let labelKey = isInternalUser ? Constants.TA_ACCOUNT : 'name';
  if (isNewAccount && account && Object.keys(account).length === 1 && Object.keys(account).indexOf(Constants.BIN) === 0) {
    labelKey = Constants.BIN;
  } else {
    // Account Identifier: Preference > name > bin > taAccount
    const accountLabelKeys = [];
    if (accountIdentifierPreference) {
      accountLabelKeys.push(accountIdentifierPreference);
    }
    accountLabelKeys.push(labelKey, Constants.BIN, Constants.TA_ACCOUNT);
    for (let i = 0; i < accountLabelKeys.length; i++) {
      if (account && account[accountLabelKeys[i]]) {
        labelKey = accountLabelKeys[i];
        break;
      }
    }
  }
  return labelKey;
};


export const getShareClassLabelKey = (shareClass, fundIdentifierPreference) => {
  let labelKey = 'name';
  const shareClassLabelKeys = [];
  if (fundIdentifierPreference) {
    shareClassLabelKeys.push(fundIdentifierPreference);
  }
  shareClassLabelKeys.push(labelKey);
  for (let i = 0; i < shareClassLabelKeys.length; i++) {
    if (shareClass && shareClass[shareClassLabelKeys[i]]) {
      labelKey = shareClassLabelKeys[i];
      break;
    }
  }

  return labelKey;
};

export const getNewAccountLabelKey = ({config = {}, account, shareClass, accountIdentifierPreference, fundIdentifierPreference}) => {
  let accountLabelKey = '';
  let shareClassLabelKey = '';

  const accountLabelKeys = [];
  if (accountIdentifierPreference) {
    accountLabelKeys.push(accountIdentifierPreference);
  }
  accountLabelKeys.push(config.account.labelKey, Constants.BIN);
  for (let i = 0; i < accountLabelKeys.length; i++) {
    if (account && account[accountLabelKeys[i]]) {
      accountLabelKey = accountLabelKeys[i];
      break;
    }
  }

  const shareClassLabelKeys = [];
  if (fundIdentifierPreference) {
    shareClassLabelKeys.push(fundIdentifierPreference);
  }
  shareClassLabelKeys.push(config.shareClass.labelKey);
  for (let i = 0; i < shareClassLabelKeys.length; i++) {
    if (shareClass && shareClass[shareClassLabelKeys[i]]) {
      shareClassLabelKey = shareClassLabelKeys[i];
      break;
    }
  }

  return {accountLabelKey, shareClassLabelKey};
};

export const areTradeValuesSame = ({previous, next}) => {
  const {firm: prevFirm, shareClass: prevShareClass, account: prevAccount} = previous;
  const {firm: nextFirm, shareClass: nextShareClass, account: nextAccount} = next;
  return isEqual(prevFirm, nextFirm) && isEqual(prevShareClass, nextShareClass) && isEqual(prevAccount, nextAccount);
};

export const getTMinusNDate = (startDate, tMinusN, holidays) => {
  moment.updateLocale('us', {
    holidays,
    holidayFormat: 'YYYY-MM-DD',
  });
  const date = moment(startDate, 'YYYY-MM-DD').businessSubtract(tMinusN)._d;
  return moment(date).format('YYYY-MM-DD');
};

export const getTPlusNDate = (startDate, tPlusN, holidays) => {
  moment.updateLocale('us', {
    holidays,
    holidayFormat: 'YYYY-MM-DD',
  });
  const date = moment(startDate, 'YYYY-MM-DD').businessAdd(tPlusN)._d;
  return moment(date).format('YYYY-MM-DD');
};
