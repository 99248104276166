import { ColumnApi, GridApi } from '@ag-grid-community/core';
import { DatasourceConfiguration, DatasourceType } from '../grid-wrappers/grid-wrapper';
import { CustomDRAOptions } from '../plugins/dra/dra-plugin';
import { DraViewportDatasource } from './dra-viewport-datasource';
import { InMemoryDatasource } from './in-memory-datasource';

export interface Datasource {
    connect(defaultData?: any[] | null): Promise<void>;
    destroy(): void;
}
export class DatasourceFactory {
    public static getDatasource(
        datasourceConfiguration: DatasourceConfiguration,
        gridApi: GridApi,
        columnApi: ColumnApi,
        customDraOptions: CustomDRAOptions
    ): Datasource {
        switch (datasourceConfiguration.datasourceType) {
            case DatasourceType.InMemory:
                return new InMemoryDatasource(datasourceConfiguration, gridApi, columnApi);
            case DatasourceType.DRA:
                return new DraViewportDatasource(
                    datasourceConfiguration,
                    gridApi,
                    columnApi,
                    customDraOptions
                );
            default:
                throw new Error('Cannot recognize the type of datasource');
        }
    }
}
