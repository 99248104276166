import { BaseStateViewModel } from './base-state-view-model';

/**
 * Tracks and controls state (especially focus) between all Menu instances in
 * an application so that focus and other states can be moved and restored
 * between Menu instances.
 */
export class StateViewModelController {
    private static _instance = new StateViewModelController();

    private focussed?: BaseStateViewModel;

    constructor() {
        if (StateViewModelController.instance) {
            return StateViewModelController.instance;
        }
        StateViewModelController._instance = this;
    }

    static get instance(): StateViewModelController {
        return this._instance;
    }

    isFocussed(vm: BaseStateViewModel) {
        return this.focussed === vm;
    }

    focus(vm: BaseStateViewModel) {
        this.blur();
        this.focussed = vm;
        this.focussed.setupBlurHandler();
    }

    blur(vm?: BaseStateViewModel) {
        if ((vm && this.focussed === vm) || (!vm && this.focussed)) {
            this.focussed.cancelBlurHandler();
            this.focussed = undefined;
        }
    }
}
