import { StyleSheet } from '@gs-ux-uitoolkit-common/style';

export const headingsStyleSheet = new StyleSheet('headings', {
    h1: { margin: '8px 0px 16px' },
    h2: { margin: '20px 0px 12px' },
    h3: { margin: '20px 0px 12px' },
    h4: { margin: '20px 0px 12px' },
    h5: { margin: '20px 0px 8px' },
    h6: { margin: '20px 0px 4px' },
    h7: { margin: '20px 0px 4px' },
});
