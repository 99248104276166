import { StorageEngine } from '../redux-storage-package';

export class ReduxStorageEngine<T> implements StorageEngine {
    constructor(private defaultConfig: T) {}

    public load(): Promise<T> {
        return new Promise(resolve => resolve(this.defaultConfig));
    }
    public save(): Promise<void> {
        return new Promise(resolve => resolve());
    }
}
