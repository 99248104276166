import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';

const SelectButton = (props) => {
  const {item, handlePresetsChange} = props;
  const {label, value, isActive, disabled, name =  ''} = item;
  const className = isActive ? 'button__inline--in-active' : '';
  const onClick = (e) => {
    e.stopPropagation();
    handlePresetsChange(e, value, item);
  };

  return (
    <div className='date-picker__cta'>
      <Button
        testId={name}
        isDisabled={disabled}
        clickHandler={onClick}
        label={label}
        data-value={value}
        customClass='button-inline'
        extraClass={`date-picker__range-button ${className}`}
      />
    </div>
  );
};

SelectButton.propTypes = {
  item: PropTypes.object.isRequired,
  handlePresetsChange: PropTypes.func.isRequired,
};

export default SelectButton;
