/**
 * Retrieves the global variable (`globalThis`) where it's not available.
 */
export function getGlobalThis(): any {
    if (typeof globalThis !== 'undefined') return globalThis;
    if (typeof self !== 'undefined') return self;
    if (typeof window !== 'undefined') return window;
    // @ts-expect-error (TypeScript doesn't recognize 'global')
    if (typeof global !== 'undefined') return global; // Node 11 and below
    throw new Error('Unable to locate global `this`');
}
