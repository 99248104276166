import { PropsWithChildren, FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { ControlledTabs } from './ControlledTabs';
import { UncontrolledTabs } from './UncontrolledTabs';
import { TabsCommonProps } from '@gs-ux-uitoolkit-common/tabs';

export interface TabsProps extends TabsCommonProps {
    /**
     * Determines if the tab is currently active or not.
     */
    activeTabKey?: string | number;

    /**
     * Callback used when a tab is selected.
     */
    onSelect?: (mode: any) => void;

    /**
     * Set this to true to enable responsive behavior with horizontal tabs.
     * Tabs will be displayed until the width reaches a breakpoint,
     * after which the remaining tabs will be shown in a dropdown.
     */
    responsive?: boolean;

    /**
     * Determines which mode the navbar should be in.
     */
    variant?: 'tabs' | 'pills';

    /**
     * Determines if the tabs should be in vertical mode.
     * Set this to true to orient the tabs vertically.
     */
    vertical?: boolean;

    /**
     * Determines if a vertical bar is displayed when {@link vertical} is `true`.
     */
    withVerticalBar?: boolean;
}

/**
 * Tabs organize related content on a page without requiring navigation to a new page.
 */
export const Tabs: FunctionComponent<PropsWithChildren<TabsProps>> = props => {
    const {
        contentUnderneath = true,
        iconOnly = false,
        size = 'md',
        variant = 'tabs',
        vertical = false,
        withVerticalBar = false,
        ...otherProps
    } = props;

    return props.activeTabKey === undefined ? (
        <UncontrolledTabs
            contentUnderneath={contentUnderneath}
            iconOnly={iconOnly}
            size={size}
            variant={variant}
            vertical={vertical}
            withVerticalBar={withVerticalBar}
            {...otherProps}
        />
    ) : (
        // Pass activeTabKey explicitly because Typescript narrowing isn't smart enough
        <ControlledTabs
            contentUnderneath={contentUnderneath}
            iconOnly={iconOnly}
            size={size}
            variant={variant}
            vertical={vertical}
            withVerticalBar={withVerticalBar}
            {...otherProps}
            activeTabKey={props.activeTabKey}
        />
    );
};

Tabs.propTypes = {
    activeTabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
