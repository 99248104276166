import {
    GSFloatingFilterBase,
    FloatingFilterLogicalOperator,
    FLOATING_FILTER_OPERATORS_REGEX,
    FloatingFilterOperators,
    GSFloatingFilter,
} from './floating-filter-base';
import { arrayHelper } from '@gs-ux-uitoolkit-common/datacore';
import { JoinOperator, TextFilter } from '@ag-grid-community/core';

export class GSTextFloatingFilter extends GSFloatingFilterBase implements GSFloatingFilter {
    public filterType: string = 'text';

    public updateFilter(value: string): void {
        super.updateFilter(value);

        let condition: JoinOperator = 'OR';
        const filterModels: { [key: string]: any }[] = [];

        // This is where the filter-rule for this column is actually created
        const splitString = this.getConditionsSplitFromValue(value);
        // When an && or || is specified in the text of the inline filter

        splitString.forEach(splitStr => {
            let operandValue: string | number = splitStr.trim();
            let operator = TextFilter.CONTAINS;
            if (operandValue === FloatingFilterLogicalOperator.And) {
                condition = 'AND';
            } else if (operandValue === FloatingFilterLogicalOperator.Or) {
                condition = 'OR';
            } else {
                if (FLOATING_FILTER_OPERATORS_REGEX.test(operandValue)) {
                    const matchResult = operandValue.match(FLOATING_FILTER_OPERATORS_REGEX);

                    switch (arrayHelper.first(matchResult || [])) {
                        case FloatingFilterOperators.Equals:
                            operator = TextFilter.EQUALS;
                            break;
                        case FloatingFilterOperators.NotEquals:
                            operator = TextFilter.NOT_EQUAL;
                            break;
                        case FloatingFilterOperators.Contains:
                            operator = TextFilter.CONTAINS;
                            break;
                        case FloatingFilterOperators.NotContains:
                            operator = TextFilter.NOT_CONTAINS;
                            break;
                    }
                    operandValue = operandValue.replace(FLOATING_FILTER_OPERATORS_REGEX, '');

                    // ignore if the value is empty
                    if (operandValue.trim() === '') return;

                    filterModels.push({
                        condition,
                        operator,
                        operandValue,
                    });
                } else {
                    // ignore if the value is empty
                    if (operandValue.trim() === '') return;

                    filterModels.push({
                        condition,
                        operator,
                        operandValue,
                    });
                }
            }
        });
        this.setFilterModel(filterModels);
    }
}
