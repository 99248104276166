import { PureComponent, createRef, RefObject, ContextType, MouseEvent } from 'react';
import PropTypes from 'prop-types';
import {
    buttonSelectStyleSheet,
    getButtonSelectRootClassNames,
    getButtonSelectLabelClassNames,
    getButtonSelectInputClassNames,
    getButtonSelectTargetClassNames,
    defaultButtonSelectProps,
    buttonSelectTypes,
    buttonSelectSizes,
} from '@gs-ux-uitoolkit-common/button';
import { splitDataAttributes, wrapTextChildrenInSpan } from '@gs-ux-uitoolkit-react/shared';
import { RadioGroupService, getRadioUid } from '@gs-ux-uitoolkit-common/radio';
import { EmotionInstanceContext } from '@gs-ux-uitoolkit-react/style';

import { ButtonGroupContext } from '../button-group/button-group-context';
import { ButtonSelectProps, ButtonSelectChangeEvent } from './button-select-props';

// api-extractor does not support dynamic imports.
// @ts-expect-error Issue:  https://github.com/microsoft/rushstack/issues/1050
import { ButtonSelectType, ButtonSize } from '@gs-ux-uitoolkit-common/button';
import { Theme, ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';
import { componentAnalytics } from '../analytics-tracking';
import { v4 as uuid } from 'uuid';

/**
 * ButtonSelect components enable users to make a single or multi selection, similar to Radios and Checkboxes.
 */
export class ButtonSelect extends PureComponent<ButtonSelectProps, { nameUuid?: string }> {
    static contextType = ButtonGroupContext;

    declare context: ContextType<typeof ButtonGroupContext>;

    private htmlInput: RefObject<HTMLInputElement>;

    private radioGroup = RadioGroupService.instance;
    private uid: string;

    // Tracks whether the button (in single mode) has changed on click:
    private inputElChecked = false;

    constructor(props: ButtonSelectProps) {
        super(props);
        this.htmlInput = createRef<HTMLInputElement>();
        this.uid = getRadioUid();
        this.state = {
            nameUuid: undefined,
        };
    }

    componentDidMount() {
        this.inputElChecked = !!this.htmlInput.current!.checked;
        this.addToRadioGroup();
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'other' });
        this.setState({
            nameUuid: uuid(),
        });
    }

    componentDidUpdate(prevProps: ButtonSelectProps) {
        if (prevProps.name !== this.props.name) {
            this.removeFromRadioGroup(prevProps.name);
            this.addToRadioGroup();
        }
    }

    componentWillUnmount() {
        buttonSelectStyleSheet.unmount(this);
        this.removeFromRadioGroup(this.props.name);
    }

    private addToRadioGroup() {
        if (this.props.name && this.isSingle()) {
            this.radioGroup.addRadio(this.props.name, this.onRadioGroupClick);
        }
    }

    private removeFromRadioGroup(name?: string) {
        if (name) {
            this.radioGroup.removeRadio(name, this.onRadioGroupClick);
        }
    }

    private onRadioGroupClick = (uid: string) => {
        if (this.isControlled()) {
            this.inputElChecked = !!this.props.selected;
        } else if (this.uid !== uid) {
            if (this.htmlInput.current) {
                this.inputElChecked = this.htmlInput.current!.checked;
            }
        }
    };

    private onInternalClick = (event: MouseEvent<HTMLInputElement>) => {
        if (this.props.onClick) {
            this.props.onClick(event);
        }
        if (!event.isDefaultPrevented()) {
            this.handleChange(event);
        }
    };

    private handleChange(event: MouseEvent<HTMLInputElement>) {
        const { name, value, selected, onChange } = this.props;
        if (this.isSingle() && name) {
            this.radioGroup.clickRadio(name, this.uid);
        }
        // A change event is broadcast only if the button is a multi or, if it
        // is a single, if the input's checked state has changed to true.
        if (onChange && (this.isMulti() || (this.isSingle() && !this.inputElChecked))) {
            Object.assign(event, {
                selected: this.isSingle() ? true : this.htmlInput.current!.checked,
                name,
                value,
            });
            event.persist(); // See https://reactjs.org/docs/legacy-event-pooling.html
            onChange(event as ButtonSelectChangeEvent);

            // Change events are sent only when `checked` changes from false to true.
            // `checked` turns true on click, so if inputElChecked is still false then
            // the `checked` has just changed to true as a result of the click, as
            // opposed to having already been true at the time of the click.
            if (this.isSingle() && !this.inputElChecked) {
                if (!this.isControlled()) {
                    this.inputElChecked = true;
                }
            }
        }
        if (this.isControlled()) {
            // When controlled, the input's checked state is reset to the
            // controlled checked value. Developers can update the checked value
            // in a change event handler.
            this.htmlInput.current!.checked = !!selected;
        }
    }

    /**
     * Avoids this React error:
     * ```
     * Warning: Failed prop type: You provided a `checked` prop to a form field
     * without an `onChange` handler. This will render a read-only field. If the
     * field should be mutable use `defaultChecked`. Otherwise, set either
     * `onChange` or `readOnly`.
     * ```
     */
    private onInternalChange() {}

    private isControlled() {
        return this.props.selected != undefined;
    }

    private isSingle() {
        return !this.isMulti();
    }

    private isMulti() {
        return this.props.type === 'multi';
    }

    render() {
        const {
            type,
            size,
            selected,
            defaultSelected,
            children,
            className,
            classes: overrideClasses,
            onChange = () => {},
            onClick = () => {},
            name,
            style,
            title,
            id,
            ...otherProps
        } = this.props;

        // Sets values by precedence: local prop, then context, then default.
        const internal = {
            name: name || this.context.name || this.state.nameUuid,
            size: size || this.context.size || defaultButtonSelectProps.size,
        };

        if (!type) {
            throw new Error(
                "<ButtonSelect /> error: 'type' input is required, please provide one!"
            );
        }

        const inputType = type === 'multi' ? 'checkbox' : 'radio';
        const { split: dataAttributeProps, remaining: inputProps } =
            splitDataAttributes(otherProps);

        return (
            <EmotionInstanceContext.Consumer>
                {emotionInstance => (
                    <ThemeConsumer>
                        {(theme: Theme) => {
                            const cssClasses = buttonSelectStyleSheet.mount(
                                this,
                                {
                                    theme,
                                    size: internal.size,
                                },
                                emotionInstance
                            );

                            const rootClasses = getButtonSelectRootClassNames({
                                cssClasses,
                                overrideClasses,
                                className,
                            });

                            const labelClasses = getButtonSelectLabelClassNames({
                                cssClasses,
                                overrideClasses,
                            });
                            const inputClasses = getButtonSelectInputClassNames({
                                cssClasses,
                                overrideClasses,
                            });
                            const targetClasses = getButtonSelectTargetClassNames({
                                cssClasses,
                                overrideClasses,
                            });

                            return (
                                <span
                                    {...{ style, title, id, ...dataAttributeProps }}
                                    data-gs-uitk-component="button-select"
                                    data-size={internal.size}
                                    className={rootClasses}
                                >
                                    <label
                                        className={labelClasses}
                                        data-cy="gs-uitk-button-select__label"
                                    >
                                        <input
                                            {...inputProps}
                                            name={internal.name}
                                            ref={this.htmlInput}
                                            type={inputType}
                                            checked={selected}
                                            // onClick is used to register changed events to support all browsers:
                                            // https://stackoverflow.com/questions/5575338/what-the-difference-between-click-and-change-on-a-checkbox/5575369)
                                            onClick={this.onInternalClick}
                                            onChange={this.onInternalChange}
                                            defaultChecked={
                                                // defaultChecked should only be set if checked is not set;
                                                // i.e., if the input is uncontrolled.
                                                selected === undefined ? defaultSelected : undefined
                                            }
                                            className={inputClasses}
                                            data-cy="gs-uitk-button-select__input"
                                        />
                                        <span
                                            className={targetClasses}
                                            data-cy="gs-uitk-button-select__target"
                                        >
                                            {wrapTextChildrenInSpan(children)}
                                        </span>
                                    </label>
                                </span>
                            );
                        }}
                    </ThemeConsumer>
                )}
            </EmotionInstanceContext.Consumer>
        );
    }

    /**
     * Function used to focus the button component.
     * @public
     */
    public focus() {
        const input = this.htmlInput.current;

        if (input) {
            input.focus();
        }
    }

    /**
     * Function used to blur (unfocus) the button component.
     * @public
     */
    public blur() {
        const input = this.htmlInput.current;

        if (input) {
            input.blur();
        }
    }

    static propTypes = {
        type: PropTypes.oneOf(buttonSelectTypes).isRequired,
        selected: PropTypes.bool,
        defaultSelected: PropTypes.bool,
        disabled: PropTypes.bool,
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
        size: PropTypes.oneOf(buttonSelectSizes),
        onChange: PropTypes.func,
        children: PropTypes.node,
    };
}
