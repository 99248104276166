import { cx } from '@gs-ux-uitoolkit-common/style';
import { CloseButtonProps } from './close-props';
import { CloseButtonCssClasses } from './close-style-sheet';

const globalClassName = 'gs-close';

export const getCloseRootClassNames = (props: {
    cssClasses: CloseButtonCssClasses;
    overrideClasses?: CloseButtonProps['classes'];
    className?: CloseButtonProps['className'];
}): string => {
    const { cssClasses, className, overrideClasses } = props;
    return cx(cssClasses.root, className, overrideClasses && overrideClasses.root, globalClassName);
};

export const getButtonClassNames = (props: {
    cssClasses: CloseButtonCssClasses;
    overrideClasses?: CloseButtonProps['classes'];
    buttonClassName?: CloseButtonProps['buttonClassName'];
}): string => {
    const { cssClasses, overrideClasses, buttonClassName } = props;

    return cx(
        cssClasses.button,
        overrideClasses && overrideClasses.button,
        buttonClassName,
        `${globalClassName}__button`
    );
};

export const getIconClassNames = (props: {
    cssClasses: CloseButtonCssClasses;
    overrideClasses?: CloseButtonProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;

    return cx(cssClasses.icon, overrideClasses && overrideClasses.icon, `${globalClassName}__icon`);
};
