import { PropsWithChildren, useEffect, useState, FC } from 'react';

/**
 * Helper component to render the children without StrictMode side effects. This defers the rendering
 * to after the double hook run. Avoid using this as a standard way to deal with strict mode issues.
 * This is intended to prevent strict mode errors only for components that have 3rd party dependencies
 * that don't work in strict mode.
 */
export const NoStrictMode: FC<PropsWithChildren> = ({ children }) => {
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        const animation = requestAnimationFrame(() => setEnabled(true));

        return () => {
            cancelAnimationFrame(animation);
            setEnabled(false);
        };
    }, []);

    return enabled ? <>{children}</> : null;
};
