import { SearchableActionKeys } from './action-constants';

export type SearchableActionTypes = SetSearchAction | SetDRASearchableColumnListAction;
export interface SetSearchAction {
    type: SearchableActionKeys.SET_SEARCH;
    search: string;
}

export const SetSearch = (search: string): SetSearchAction => ({
    search,
    type: SearchableActionKeys.SET_SEARCH,
});
export interface SetDRASearchableColumnListAction {
    type: SearchableActionKeys.SET_DRA_SEARCHABLE_COLUMN_LIST;
    DRASearchableColumnList: string[] | null;
}

export const SetDRASearchableColumnList = (
    DRASearchableColumnList: string[] | null
): SetDRASearchableColumnListAction => ({
    DRASearchableColumnList,
    type: SearchableActionKeys.SET_DRA_SEARCHABLE_COLUMN_LIST,
});
