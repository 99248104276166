import { cx } from '@gs-ux-uitoolkit-common/style';
import { StepsProps } from './steps-props';
import { StepsCssClasses } from './steps-style-sheet';
import { StepsSummaryProps } from './steps-summary-props';
import { StepsSummaryCssClasses } from './steps-summary-style-sheet';

const globalClassName = 'gs-steps';

export const getStepsRootClasses = (props: {
    cssClasses?: StepsCssClasses;
    overrideClasses?: StepsProps['classes'];
    className?: StepsProps['className'];
}): string => {
    const { className, overrideClasses } = props;
    return cx(className, overrideClasses && overrideClasses.root, globalClassName);
};

export const getStepsListClasses = (props: {
    cssClasses: StepsCssClasses;
    overrideClasses?: StepsProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(cssClasses.list, overrideClasses && overrideClasses.list, `${globalClassName}__list`);
};

export const getStepsSummaryClasses = (props: {
    cssClasses: StepsSummaryCssClasses;
    overrideClasses?: StepsSummaryProps['classes'];
}): string => {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.summary,
        overrideClasses && overrideClasses.root,
        `${globalClassName}__summary`
    );
};

export const getStepsSummaryBoldLabelClasses = (props: {
    cssClasses: StepsSummaryCssClasses;
}): string => {
    const { cssClasses } = props;
    return cx(cssClasses.boldLabel, `${globalClassName}__bold-label`);
};

export const getToEdgeToEdgeContainerClasses = (props: { cssClasses: StepsCssClasses }): string => {
    const { cssClasses } = props;
    return cx(cssClasses.container, `${globalClassName}__to-edge-container`);
};

// Get the corresponding steps summary content
// When either stepsLabels or currentStep is undefined, return a empty string
export const getStepsSummaryContent = (props: {
    currentStep: StepsSummaryProps['currentStep'];
    stepsLabels: StepsSummaryProps['stepsLabels'];
}): { stepsSummaryStateContent: string; stepsSummaryLabelContent: string } => {
    const { currentStep, stepsLabels } = props;
    let stepsSummaryStateContent = '';
    let stepsSummaryLabelContent = '';
    if (currentStep !== undefined && stepsLabels !== undefined) {
        stepsSummaryStateContent = `Step ${currentStep} of ${stepsLabels.length}: `;
        stepsSummaryLabelContent = stepsLabels[currentStep - 1] as string;
    }

    return { stepsSummaryStateContent, stepsSummaryLabelContent };
};
