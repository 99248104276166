import { connect } from 'react-redux';
import Glossary from '../../components/app/Glossary';
import {faqGlossaryPageLoadEventSelector, faqGlossaryContentsSelector} from '../../selectors/app';
import { eventAnalytics } from '../../actions/sagas';
import {glossaryDefinitionViewedAnalyticsData} from '../../components/app/Glossary/analytics';

const mapStateToProps = (state) => ({
  glossaryContents: faqGlossaryContentsSelector(state),
  glossaryPageLoadEventData: faqGlossaryPageLoadEventSelector(state)
});

const mapDispatchToProps = (dispatch) => {
  return {
    onPageLoad: (glossaryPageOnLoadAnalyticsData) => (
      dispatch(eventAnalytics(glossaryPageOnLoadAnalyticsData))
    ),
    onExpandGlossaryQuestionAnswer: (articleName) => (
      dispatch(eventAnalytics(glossaryDefinitionViewedAnalyticsData(articleName)))
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Glossary);
