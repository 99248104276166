import { cx } from '@gs-ux-uitoolkit-common/style';
import { SwitchProps } from './switch-props';
import { SwitchCssClasses } from './switch-style-sheet';

const globalClassName = 'gs-switch';

export function getSwitchRootClasses(props: SwitchRootClassNameProps): string {
    const { cssClasses, className, overrideClasses } = props;
    return cx(cssClasses.root, className, overrideClasses && overrideClasses.root, globalClassName);
}

export function getSwitchInputClasses(props: SwitchInputClassNameProps): string {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.input,
        overrideClasses && overrideClasses.input,
        `${globalClassName}__input`
    );
}

export function getSwitchLabelClasses(props: SwitchLabelClassNameProps): string {
    const { cssClasses, overrideClasses } = props;
    return cx(
        cssClasses.label,
        overrideClasses && overrideClasses.label,
        `${globalClassName}__label`
    );
}

interface SwitchRootClassNameProps {
    cssClasses: SwitchCssClasses;
    className?: SwitchProps['className'];
    overrideClasses?: SwitchProps['classes'];
}

interface SwitchInputClassNameProps {
    cssClasses: SwitchCssClasses;
    overrideClasses?: SwitchProps['classes'];
}

interface SwitchLabelClassNameProps {
    cssClasses: SwitchCssClasses;
    overrideClasses?: SwitchProps['classes'];
}
