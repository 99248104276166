import { SavedViewActionKeys } from '../actions/action-constants';
import { SavedViewActionTypes } from '../actions/saved-view-action';
import { SavedViewState } from '../datagrid-state';

/**
 * Initial state of the SavedView State
 */
export const defaultSavedViewState: SavedViewState = {
    currentSavedView: null,
    savedViewForm: {
        columnGroupChecked: true,
        columnsChecked: true,
        createInputValue: '',
        expansionDepthChecked: true,
        pivotChecked: true,
        rowGroupColumnsChecked: true,
        showCreateNew: false,
        autoSaveChecked: false,
    },
    savedViewList: [],
};

/**
 * Reducer for the SavedViewState
 * @param state State of the SavedViewState
 * @param action Action received
 */
export const savedViewReducer = (
    state: SavedViewState = defaultSavedViewState,
    action: SavedViewActionTypes
): SavedViewState => {
    switch (action.type) {
        case SavedViewActionKeys.ADD_SAVED_VIEW:
            return {
                ...state,
                savedViewList: [...state.savedViewList, action.savedView],
            };
        case SavedViewActionKeys.REMOVE_SAVED_VIEW:
            return {
                ...state,
                savedViewList: state.savedViewList.filter(x => x.name !== action.name),
            };
        case SavedViewActionKeys.SET_SAVED_VIEW:
            return {
                ...state,
                currentSavedView: action.name,
            };
        case SavedViewActionKeys.SAVE_SAVED_VIEW:
            return {
                ...state,
                savedViewList: [
                    ...state.savedViewList.filter(
                        savedView => savedView.name !== action.savedView.name
                    ),
                    action.savedView,
                ],
            };
        case SavedViewActionKeys.UPDATE_SAVED_VIEW_CONFIG:
            return {
                ...state,
                savedViewConfig: action.savedViewConfig,
                savedViewForm: {
                    ...state.savedViewForm,
                    rowGroupColumnsChecked: state.savedViewForm.columnsChecked,
                },
            };
        case SavedViewActionKeys.UPDATE_SAVED_VIEW_FORM:
            return {
                ...state,
                savedViewForm: {
                    ...state.savedViewForm,
                    ...action.savedViewForm,
                },
            };
        default:
            return state;
    }
};
