import {
    cancelEditedConfigItem,
    ColumnHint,
    DefaultColumnHintTypes,
    editConfigItem,
    newConfigItem,
    removeConfigItem,
    saveEditedConfigItem,
    updateEditedConfigItem,
} from '@gs-ux-uitoolkit-common/datacore';
import { ColumnHintActionKeys } from '../actions/action-constants';
import { ColumnHintActionTypes } from '../actions/column-hint-action';
import { ColumnHintState } from '../datagrid-state';

/**
 * Initial state of the ColumnHintState
 */
export const defaultColumnHintState: ColumnHintState = {
    configItemList: [],
    defaults: {
        // dateDefaults: {} etc
        // all defaults are defined here
    },
    editedItem: null,
    originalEditedItem: null,

    editedDefaults: null,
    originalEditedDefaults: null,
};

const emptyColumnHint: ColumnHint = {
    columnId: '',
    hints: {},
    pivotKeys: [],
};

/**
 * Reducer for the ColumnHintState
 * @param state State of the ColumnHintState
 * @param action Action received
 */
export const columnHintReducer = (
    state: ColumnHintState = defaultColumnHintState,
    action: ColumnHintActionTypes
): ColumnHintState => {
    switch (action.type) {
        case ColumnHintActionKeys.SAVE_EDITED_DEFAULT_COLUMN_HINT: {
            if (state.editedDefaults) {
                return {
                    ...state,
                    defaults: { ...state.editedDefaults },
                    editedDefaults: null,
                    originalEditedDefaults: null,
                };
            }
            return state;
        }

        case ColumnHintActionKeys.EDIT_DEFAULT_COLUMN_HINT: {
            return {
                ...state,
                editedDefaults: { ...state.defaults },
                originalEditedDefaults: state.defaults || null,
            };
        }

        case ColumnHintActionKeys.UPDATE_EDITED_DEFAULT_COLUMN_HINT: {
            if (state.editedDefaults) {
                let clonedEditedDefaults = { ...state.editedDefaults };

                switch (action.defaultType) {
                    case DefaultColumnHintTypes.String:
                        clonedEditedDefaults.stringDefaults = { ...action.hints };
                        break;
                    case DefaultColumnHintTypes.Number:
                        clonedEditedDefaults.numberDefaults = { ...action.hints };
                        break;
                    case DefaultColumnHintTypes.Date:
                        clonedEditedDefaults.dateDefaults = { ...action.hints };
                        break;
                    case DefaultColumnHintTypes.DateTime:
                        clonedEditedDefaults.dateTimeDefaults = { ...action.hints };
                        break;
                    case DefaultColumnHintTypes.Default:
                        clonedEditedDefaults = {
                            dateDefaults: state.editedDefaults.dateDefaults,
                            dateTimeDefaults: state.editedDefaults.dateTimeDefaults,
                            numberDefaults: state.editedDefaults.numberDefaults,
                            stringDefaults: state.editedDefaults.stringDefaults,
                            ...action.hints,
                        };
                        break;
                    default:
                        break;
                }

                return {
                    ...state,
                    editedDefaults: clonedEditedDefaults,
                };
            }
            return state;
        }

        case ColumnHintActionKeys.REMOVE_DEFAULT_COLUMN_HINT:
            if (state.defaults) {
                let newDefaults = { ...state.defaults };
                if (action.defaultType !== DefaultColumnHintTypes.Default) {
                    delete newDefaults[action.defaultType];
                } else {
                    const { stringDefaults, dateDefaults, dateTimeDefaults, numberDefaults } =
                        newDefaults;
                    newDefaults = {
                        dateDefaults,
                        dateTimeDefaults,
                        numberDefaults,
                        stringDefaults,
                    };

                    // Remove undefined values
                    Object.keys(newDefaults).forEach(key =>
                        newDefaults[key] === undefined ? delete newDefaults[key] : newDefaults[key]
                    );
                }
                return {
                    ...state,
                    defaults: newDefaults,
                };
            }
            return state;

        case ColumnHintActionKeys.NEW_DEFAULT_COLUMN_HINT:
            // new default column hint will contain all the ones that already exist
            return { ...state, editedDefaults: { ...state.defaults } };

        case ColumnHintActionKeys.REMOVE_COLUMN_HINT:
            return removeConfigItem(state, action);
        case ColumnHintActionKeys.EDIT_COLUMN_HINT:
            return editConfigItem(state, action);
        case ColumnHintActionKeys.NEW_COLUMN_HINT:
            return newConfigItem(state, emptyColumnHint);
        case ColumnHintActionKeys.SAVE_EDITED_COLUMN_HINT:
            return saveEditedConfigItem(state);
        case ColumnHintActionKeys.UPDATE_EDITED_COLUMN_HINT:
            return updateEditedConfigItem(state, action);
        case ColumnHintActionKeys.CANCEL_EDITED_COLUMN_HINT: {
            // We reset both the config item and defaults here
            const columnHintState: ColumnHintState = {
                ...cancelEditedConfigItem(state),
                editedDefaults: null,
                originalEditedDefaults: null,
            };
            return columnHintState;
        }
        case ColumnHintActionKeys.ADD_COLUMN_HINT:
            return {
                ...state,
                configItemList: [...state.configItemList, action.configItem],
            };
        case ColumnHintActionKeys.ADD_COLUMN_HINT_LIST:
            return {
                ...state,
                configItemList: [...state.configItemList, ...action.configItemList],
            };
        case ColumnHintActionKeys.REMOVE_COLUMN_HINT_LIST:
            return {
                ...state,
                configItemList: state.configItemList.filter(
                    x => action.configItemList.findIndex(y => y.columnId === x.columnId) === -1
                ),
            };
        default:
            return state;
    }
};
