import React from 'react';
import { renderNodeLabel } from '../../../utils/accountsTree';

const InnerRenderer = params => {
  const { searchTerm, isEditable } = params.context;

  const displayCheckbox = params.node.level === 0 || params.node.level === 1 || params.node.level === 3;
  const checkboxClassName = params.node.data.disabled ? 'checkbox-disabled' : displayCheckbox ? 'ag-icon-checkbox-checked checkboxRenderer' : '';

  const checkbox =
    isEditable && displayCheckbox ? <span className="ag-selection-checkbox"><span className={`ag-icon ${checkboxClassName}`} /></span> : null;

  const { isFundAccountExcluded, label } = params.data;
  const title = `${label}`;

  const result = searchTerm && label ? [] : label;
  if (searchTerm && label) {
    const escapeRegExp = (str = '') => str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    const regex = new RegExp(`(${escapeRegExp(searchTerm)})`, 'gi');
    const parts = label.split(regex).filter(part => part);
    for (let i = 0; i < parts.length; i++) {
      const cls = regex.test(parts[i]) ? 'highlightedText' : '';
      result.push(<span key={i} className={`${cls}`}>{parts[i]}</span>);
    }
  }

  const innerLabel = params.node.level !== 4 ? <span className="light-label">{renderNodeLabel(params.node.level)}</span> : null
  const text = <span className={isFundAccountExcluded ? 'show-tooltip cell-label-line-through': ''}>{result}</span>

  return <span title={title}>{checkbox}{text}{innerLabel}</span>
};

export default InnerRenderer;
