import { isBrowser } from '@gs-ux-uitoolkit-common/shared';

export const qaUrl = 'https://gs-analytics-qa.url.gs.com:8443/appevent/bulk';
// Only run on a browser environment as we do not want to keep an
// interval running on Node (i.e. SSR) environments (which can cause the
// Node process to never exit)
const batch = isBrowser;

export const toolkitProdAnalyticsConfig = {
    url: 'https://gs-analytics.url.gs.com:8443/appevent/bulk',
    token: '98311094-bcd0-41ab-8346-2d9cc6b715c4',
    batch,
    errorTolerance: 10,
    batchSyncInterval: 10 * 1000,
    maxBatchItems: 50,
    deploymentId: '148898',
    deduplicate: true,
    credentials: false,
    appId: '27399',
};
export const toolkitQaAnalyticsConfig = {
    url: qaUrl,
    token: '94ad9122-e982-4a68-a94e-98b69f981419',
    batch,
    errorTolerance: 10,
    batchSyncInterval: 10 * 1000,
    maxBatchItems: 50,
    deploymentId: '148907',
    deduplicate: true,
    credentials: false,
    appId: '27399',
};
