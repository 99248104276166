import { Component, ChangeEvent } from 'react';
import {
    createdSavedViewsMenuStyleSheet,
    createdSavedViewsMenuClassNamePrefix,
} from '../../../../style/widget/created-saved-views-menu-stylesheet';
import { Input } from '@gs-ux-uitoolkit-react/input';
import { Collapse } from '@gs-ux-uitoolkit-react/collapse';
import { Label } from '@gs-ux-uitoolkit-react/label';
import { Icon, IconName } from '@gs-ux-uitoolkit-react/icon-font';
import { Checkbox, CheckboxChangeEvent } from '@gs-ux-uitoolkit-react/checkbox';
import { SavedViewConfig, SavedViewFormState, SavedView, SavedViewOptions } from '../../saved-view';
import { Button } from '@gs-ux-uitoolkit-react/button';
import { ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';

export interface CreateSavedViewsMenuProps {
    savedViewConfig: SavedViewConfig | undefined;
    savedViewForm: SavedViewFormState;
    savedViewList: SavedView[];
    createSavedView: (savedViewOption: SavedViewOptions, name: string) => void;
}
export interface CreateSavedViewsMenuState {
    advanceExpanded: boolean;
    newSavedViewOptions: SavedViewOptions;
    newSavedViewName: string;
}

export class CreateSavedViewsMenu extends Component<
    CreateSavedViewsMenuProps,
    CreateSavedViewsMenuState
> {
    constructor(props: CreateSavedViewsMenuProps) {
        super(props);
        this.state = {
            advanceExpanded: false,
            newSavedViewName: '',
            newSavedViewOptions: {
                columnGroups: true,
                columns: true,
                expansionDepth: true,
                pivot: true,
                rowGroupColumns: true,
            },
        };
    }

    componentDidMount() {
        const savedViewConfig = this.props.savedViewConfig;
        if (savedViewConfig) {
            this.setState({
                ...this.state,
                newSavedViewOptions: {
                    columnGroups: savedViewConfig.columnGroups?.defaultValue ?? true,
                    columns: savedViewConfig.columns?.defaultValue ?? true,
                    expansionDepth: savedViewConfig.expansionDepth?.defaultValue ?? true,
                    pivot: savedViewConfig.pivots?.defaultValue ?? true,
                    rowGroupColumns: savedViewConfig.hPivots?.defaultValue ?? true,
                    autoSave: {
                        enabled: savedViewConfig.autoSave?.defaultValue ?? false,
                        debounceTime: savedViewConfig.autoSave?.debounceTime || 2000,
                    },
                },
            });
        }
    }

    public componentWillUnmount() {
        createdSavedViewsMenuStyleSheet.unmount(this);
    }
    public render() {
        const toggleCollapse = () => {
            this.setState({ advanceExpanded: !this.state.advanceExpanded });
        };
        const collapseIcon = `keyboard-arrow-${this.state.advanceExpanded ? 'down' : 'right'}`;

        return (
            <ThemeConsumer>
                {theme => {
                    const cssClasses = createdSavedViewsMenuStyleSheet.mount(this, { theme });
                    return (
                        <div className={`gs-uitk-saved-views__create-menu ${cssClasses.root}`}>
                            <p className={`${createdSavedViewsMenuClassNamePrefix}__header`}>
                                CREATE A NEW VIEW
                            </p>
                            <Input
                                size="sm"
                                placeholder="Create view name"
                                value={this.state.newSavedViewName}
                                onChange={this.onInputChanged}
                                className={`${createdSavedViewsMenuClassNamePrefix}__input`}
                                data-cy={`${createdSavedViewsMenuClassNamePrefix}__input`}
                            />
                            <div
                                className={`${createdSavedViewsMenuClassNamePrefix}__menu-advanced-container`}
                            >
                                <Label
                                    className={`${createdSavedViewsMenuClassNamePrefix}__collapse`}
                                    onClick={toggleCollapse}
                                >
                                    <Icon name={collapseIcon as IconName} type="filled"></Icon>{' '}
                                    Advanced Settings
                                </Label>
                                <Collapse expanded={this.state.advanceExpanded}>
                                    <p>Include the following in the custom view.:</p>
                                    <Checkbox
                                        name="column-width-position"
                                        data-cy="gs-uitk-datagrid-saved-view-widget__column-width-position-checkbox"
                                        checked={this.state.newSavedViewOptions.columns}
                                        onChange={this.onCheckboxChange}
                                    >
                                        Column Width & Position
                                    </Checkbox>
                                    <Checkbox
                                        name="column-groups"
                                        data-cy="gs-uitk-datagrid-saved-view-widget__column-groups-checkbox"
                                        checked={this.state.newSavedViewOptions.columnGroups}
                                        onChange={this.onCheckboxChange}
                                    >
                                        Column Groups
                                    </Checkbox>
                                    <Checkbox
                                        name="pivots"
                                        data-cy="gs-uitk-datagrid-saved-view-widget__pivots-checkbox"
                                        checked={this.state.newSavedViewOptions.pivot}
                                        onChange={this.onCheckboxChange}
                                    >
                                        Pivots
                                    </Checkbox>
                                    <Checkbox
                                        name="hpivots"
                                        data-cy="gs-uitk-datagrid-saved-view-widget__hpivots-checkbox"
                                        checked={this.state.newSavedViewOptions.rowGroupColumns}
                                        onChange={this.onCheckboxChange}
                                    >
                                        hPivots
                                    </Checkbox>
                                    <Checkbox
                                        name="expansion-depth-setting"
                                        data-cy="gs-uitk-datagrid-saved-view-widget__expansion-depth-setting-checkbox"
                                        checked={this.state.newSavedViewOptions.expansionDepth}
                                        onChange={this.onCheckboxChange}
                                    >
                                        Expansion Depth Setting
                                    </Checkbox>
                                    <Checkbox
                                        name="auto-save"
                                        data-cy="gs-uitk-datagrid-saved-view-widget__auto-save-checkbox"
                                        checked={
                                            this.state.newSavedViewOptions.autoSave?.enabled ||
                                            false
                                        }
                                        onChange={this.onCheckboxChange}
                                    >
                                        AutoSave
                                    </Checkbox>
                                </Collapse>
                                <Button
                                    actionType="primary"
                                    className={`${createdSavedViewsMenuClassNamePrefix}__save-button`}
                                    data-cy={`${createdSavedViewsMenuClassNamePrefix}__save-button`}
                                    disabled={!this.state.newSavedViewName || !this.isNameUnique()}
                                    onClick={this.onSaveClicked}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    );
                }}
            </ThemeConsumer>
        );
    }
    private onCheckboxChange = (event: CheckboxChangeEvent) => {
        const newSavedViewOptions = this.state.newSavedViewOptions;
        const checked = event.currentTarget.checked;
        switch (event.currentTarget.name) {
            case 'column-width-position':
                newSavedViewOptions.columns = checked;
                break;
            case 'column-groups':
                newSavedViewOptions.columnGroups = checked;
                break;
            case 'pivots':
                newSavedViewOptions.pivot = checked;
                break;
            case 'hpivots':
                newSavedViewOptions.rowGroupColumns = checked;
                break;
            case 'expansion-depth-setting':
                newSavedViewOptions.expansionDepth = checked;
                break;
            case 'auto-save':
                newSavedViewOptions.autoSave = {
                    ...(newSavedViewOptions.autoSave || {}),
                    enabled: checked,
                };
                break;
        }

        this.setState({
            newSavedViewOptions: newSavedViewOptions,
        });
    };
    private onInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;
        this.setState({
            newSavedViewName: value,
        });
    };
    private onSaveClicked = () => {
        this.props.createSavedView(this.state.newSavedViewOptions, this.state.newSavedViewName);
        this.resetAdvancedOptions();
    };
    private resetAdvancedOptions() {
        this.setState({
            advanceExpanded: false,
            newSavedViewName: '',
            newSavedViewOptions: {
                columnGroups: true,
                columns: true,
                expansionDepth: true,
                pivot: true,
                rowGroupColumns: true,
            },
        });
    }
    private isNameUnique(): boolean {
        return !this.props.savedViewList.some(
            savedView =>
                savedView.name.toLowerCase() === this.state.newSavedViewName.trim().toLowerCase()
        );
    }
}
