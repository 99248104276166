export const dropdownData = {
  accountType: [
    {
      value: "CHECKING",
      label: "tkChecking",
    },
    {
      value: "SAVINGS",
      label: "tkSavings",
    },
    {
      value: "NONE",
      label: "tkNone",
    },
  ],
  investOnlyType: [
    {
      value: "C",
      label: "tkCheck",
    },
    {
      value: "W",
      label: "tkWire",
    },
    {
      value: "N",
      label: "tkNone",
    },
  ],
};
