export default {
  FETCH_ALL_USERS: {
    path: "/api/v1/users/all?includeInactive=false",
    method: "GET",
  },
  FETCH_USER_FIRMS: {
    path: "/api/v1/firms/search/userId?userId={userId}",
    method: "POST",
  },
  MIMIC_USER: {
    path: "/users/mimic",
    method: "POST",
  },
  AUDIT_SAVE_USER: {
    path: "/api/v1/audit/savemimic",
    method: "POST",
  },
};
