import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
import { Checkbox } from '@gs-ux-uitoolkit-react/checkbox';
import PropTypes from 'prop-types';
import translator from '../../../../../../services/translator';
import withGrid from '../../../../../../components/hoc/withGrid';
import Grid from '../../../../../../components/core/Grid';
import { MODULES_KEYS } from '../../../../../../constants/pageConstants';
import { userTabGridHeader } from '../../../selectors';
import useUserMaintenance from '../../../context';
import { fetchUserDetailsFromOrganizationDetails, SHOW_INACTIVE_ORG_USERS, SHOW_LOADER } from '../../../actions';
import { ACTIVE } from '../../../constants';
import { injectKeys } from '../../../../../../utils/dynamici18nInjection';
import { labelSelector } from '../../../../../../selectors/app';

const { translate: t } = translator;


const Users = ({ columnDefs: columns, labels }) => {
  const {
    state: {
      showInActiveOrgUsers,
      organizationDetails: { users = [] },
    },
    dispatch,
    reduxDispatch,
    storeValues: { isSsaAdmin },
  } = useUserMaintenance();
  const [columnDefs, setColumnDefs] = useState(isSsaAdmin ? columns : columns.map(col => ({ ...col, cellClass: [] })));
  const [filteredRow, setFilteredRow] = useState(null);
  const [filterBy, setFilterBy] = useState([]);

  const data = useMemo(() => {
    return showInActiveOrgUsers ? users : users.filter(user => user.userAccountStatus === ACTIVE);
  }, [users, showInActiveOrgUsers]);

  const dispatchFilterApplied = (filter, count) => {
    setFilteredRow(count);
  };

  const dispatchColumnWidthChanged = col => {
    setColumnDefs(
      columnDefs.map(column => {
        if (column.colId === col[0].colId) {
          return { ...column, ...col[0], suppressSizeToFit: true };
        }
        return column;
      })
    );
  };

  const dispatchCellClick = params => {
    const colIds = ['lastName', 'firstName', 'workEmailAddress', 'login'];
    if (isSsaAdmin && params.data && colIds.includes(params.colDef.colId)) {
      dispatch({ type: SHOW_LOADER, payload: true });
      reduxDispatch(fetchUserDetailsFromOrganizationDetails({ id: params.data.id, fetchingFromOrganizationDetails: true }));
    }
  };

  const dispatchFilterChange = filterModel => {
    const filters = map(filterModel, (obj, key) => ({ field: key, term: obj.filter }));
    setFilterBy(filters);
  };

  const noGridRows = useMemo(() => (!data.length ? t('tkNoUsersWithOrganization') : ''), [data]);

  const updatedProps = {
    dispatchFilterApplied,
    ...(isSsaAdmin && { dispatchCellClick }),
    columnDefs,
    data,
    gsToolKit: true,
    config: {
      enableServerSideFilter: false,
      enableServerSideSorting: false,
      suppressScrollOnNewData: true,
      sortingOrder: ['desc', 'asc'],
    },
    dispatchFilterChange,
    filterBy,
    dispatchColumnWidthChanged,
    noRowDataSelector: noGridRows,
    footerInfo: injectKeys(labels.tkGridTotalRows, filteredRow === null ? data.length : filteredRow, data.length),
  };

  const onChange = () => {
    dispatch({ type: SHOW_INACTIVE_ORG_USERS, payload: !showInActiveOrgUsers });
  };

  return (
    <div className='userdetailsfunds gs-scrollbar org-users'>
      <div className='userdetailsfunds__header'>{t('tkUsersInThisOrganization')}</div>
      <div className='userdetailsfunds__container' data-testid='funds-grid'>
        <div className='userdetailsfunds__subHeader'>
          <Checkbox name='showInActiveOrgUsers' checked={!!showInActiveOrgUsers} onChange={onChange}>
            {t('tkIncludeInactiveUsers')}
          </Checkbox>
        </div>
        {withGrid(Grid)(updatedProps)}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  columnDefs: userTabGridHeader(state, MODULES_KEYS.USER_SEARCH),
  labels: labelSelector(state),
});

Users.propTypes = {
  columnDefs: PropTypes.array,
  labels: PropTypes.object,
};

export default connect(mapStateToProps)(Users);
