import { connect } from 'react-redux';
import { labelSelector } from '../../selectors/app';
import { MainLogout } from '../../components/app/Logout';
import { } from '../../actions/page';

const mapStateToProps = (state) => ({
  label : labelSelector(state)
});

export default connect(mapStateToProps)(MainLogout);
