import { CellClassParams } from '@ag-grid-community/core';
import { expressionEvaluator, PluginIcon } from '@gs-ux-uitoolkit-common/datacore';
import { ModuleIdentfier } from '../module-identfier';
import { GridWrapper } from '../../grid-wrappers/grid-wrapper';
import { DataGridState, RowCustomisationState } from '../../redux/datagrid-state';
import { ColumnRefreshPluginBase } from '../column-refresh/column-refresh-plugin-base';
import { Categories, Plugins } from '../plugin-enum';
import { RowCustomisation } from './row-customisation';
import { RowCustomisationPluginModal } from './view/row-customisation-plugin-modal';
import { OpenGridConfiguration } from '../../redux/actions/grid-configuration-action';
import { DataGridToolbarItem } from '../../toolbar-state';

const mainIcon: PluginIcon = { name: 'border-color', type: 'filled' };
const componentId = 'RowCustomisationModal';

export const ROWCUSTOMISATION_PREFIX = 'row-customisation';

/**
 * The RowCustomisation plugin allows cells to be row customised based on some expression.
 */
export class RowCustomisationPlugin extends ColumnRefreshPluginBase<
    GridWrapper,
    DataGridState,
    RowCustomisationState
> {
    protected static requiredModules: ModuleIdentfier[] = [];
    constructor(wrapper: GridWrapper) {
        super(
            Plugins.RowCustomisationPlugin,
            Categories.Data,
            mainIcon,
            wrapper,
            state => state.rowCustomisation
        );
        this.actions = [
            {
                action: OpenGridConfiguration(Plugins.RowCustomisationPlugin),
                componentId: 'RowCustomisationShortcut' as DataGridToolbarItem,
                icon: mainIcon,
                label: 'Row Customisation',
                store: wrapper.getReduxStore(),
                context: wrapper.getReduxStoreContext(),
            },
        ];
        this.screens = [
            {
                componentId,
                icon: mainIcon,
                label: 'Row Customisation',
                screen: RowCustomisationPluginModal(
                    (columnId: string, value: string | number | Date) =>
                        wrapper.getFormattedValueFromColumn(columnId, value)
                ),
                store: wrapper.getReduxStore(),
                context: wrapper.getReduxStoreContext(),
            },
        ];
    }

    protected stateChangedOrStart(): void {
        const previousPluginState = this.getPreviousPluginState();
        const previousRowCustomisationsLength = previousPluginState
            ? previousPluginState.configItemList.length
            : 0;
        this.removeRowCustomisations(this.getPreviousPluginState());
        this.addrowCustomisations();
        if (
            previousRowCustomisationsLength !== 0 ||
            this.getPluginState()?.configItemList.length !== 0 ||
            this.shouldForceRefresh()
        ) {
            this.setRefreshComplete();
            this.wrapper.refreshGrid();
        }
    }

    protected internalStop(): void {
        super.internalStop();
        this.removeRowCustomisations(this.getPluginState());
    }

    private removeRowCustomisations(state: RowCustomisationState | null) {
        if (state !== null) {
            state.configItemList.forEach((rowCustomisation, index) =>
                this.wrapper.removeCellClassRule(
                    this.wrapper.getPivotedColumnId(
                        rowCustomisation.columnId,
                        rowCustomisation.pivotKeys
                    ),
                    `${ROWCUSTOMISATION_PREFIX}-${this.wrapper.getId()}-${index}`
                )
            );
        }
    }

    private addrowCustomisations() {
        this.getPluginState()?.configItemList.forEach(rowCustomisation =>
            this.wrapper.addCellClassRule(
                this.wrapper.getPivotedColumnId(
                    rowCustomisation.columnId,
                    rowCustomisation.pivotKeys
                ),
                `${ROWCUSTOMISATION_PREFIX}-${this.wrapper.getId()}-${this.getPluginState()?.configItemList.findIndex(
                    myRowCustomisation => rowCustomisation === myRowCustomisation
                )}`,
                this.createCellClassRuleFunc(rowCustomisation)
            )
        );
    }

    private createCellClassRuleFunc(
        rowCustomisation: RowCustomisation
    ): (params: CellClassParams) => boolean {
        return cellClassParams => this.evaluateRowCustomisation(rowCustomisation, cellClassParams);
    }

    private evaluateRowCustomisation = (
        rowCustomisation: RowCustomisation,
        cellClassParams: CellClassParams
    ) => {
        const result = expressionEvaluator.evaluate(
            cellClassParams.node,
            rowCustomisation.expression.query,
            this.wrapper,
            []
        );

        return result;
    };
}
