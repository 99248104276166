export const getUserData = () => {
  return {
    firstName: '',
    lastName: '',
    login: '',
    contactDetails: {
      email: '',
      phone: '',
    },
    gtamUserId: '',
    userGuid: '',
    iMoneyNetId: '',
    accessTypes: null,
    isMosaicInternal: false,
    isSystemUser: false,
    jurisdiction: 'US',
    isBlockedMfa: false,
    organizationId: null,
    organizationName: '',
    timezone: 'America/New_York',
    showDisclaimerOnLogin: false,
    userAccountStatus: 'Active',
    profile: {
      profileId: null,
      name: null,
    },
    adminEntitlement: 'No',
    entitlements: {
      featureEntitlements: {
        all: 'No',
        contactUs: 'No',
        faq: 'No',
        messageCenter: 'No',
        accountDetails: 'No',
        activityTransaction: 'No',
        investmentPolicy: 'No',
        portfolio: 'No',
        reporting: 'No',
        fundFinder: 'No',
        fundTracker: 'No',
        transpInsight: 'No',
        tradeBlotter: 'No',
        tradeImport: 'No',
        tradeApproval: 'No',
        reportingAccountBalance: 'No',
        reportingCurrTradeStatus: 'No',
        reportingCurrTradeTotalByFund: 'No',
        reportingFundBalance: 'No',
        reportingGainLossOnshore: 'No',
        reportingHistoricalTxn: 'No',
        reportingHistoricalTradeApprovalException: 'No',
        reportingMonthlyDividenedActivity: 'No',
        reportingWireSettlement: 'No',
        reportingClassFee: 'No',
        reportingDebitCreditDailyFeedSummary: 'No',
        reportingDebitCreditTxnActivity: 'No',
        reportingDebitCreditSettlementSummary: 'No',
        reportingExPostCostAndChargesSummary: 'No',
        reportingMonthlyServiceFee: 'No',
        navCal: 'No',
        fundRatesAndFactors: 'No',
        reportingDebitCreditAccListing: 'No',
        tradeWebActivity: 'No',
        disableLogin: 'No',
      },
    },
    permissions: {
      nscc: 'No NSCC Access',
      overrideTradeLimit: false,
      denyBalanceTransfer: false,
      allowShellAccounts: false,
      allowCloseouts: false,
      useCutoffClientBuffer: false,
    },
    accountDetails: [],
    funds: {},
    ssoClient: [],
    isInternal: false,
    isSSOEnabled: false,
  };
};
