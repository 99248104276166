import {
    CancelEditedConfigItemAction,
    ColumnHint,
    DefaultColumnHintTypes,
    EditConfigItemAction,
    NewConfigItemAction,
    RemoveConfigItemAction,
    SaveEditedConfigItemAction,
    UpdateEditedConfigItemAction,
    HintList,
} from '@gs-ux-uitoolkit-common/datacore';
import { ColumnHintActionKeys } from './action-constants';

export type ColumnHintActionTypes =
    | RemoveColumnHintAction
    | NewColumnHintAction
    | EditColumnHintAction
    | UpdateEditedColumnHintAction
    | SaveEditedColumnHintAction
    | CancelEditedColumnHintAction
    | AddColumnHintAction
    | AddColumnHintListAction
    | RemoveColumnHintListAction
    | RemoveDefaultColumnHintAction
    | UpdateEditedDefaultColumnHintAction
    | EditDefaultColumnHintAction
    | SaveEditedDefaultColumnHintAction
    | NewDefaultHintAction;

export interface RemoveColumnHintAction extends RemoveConfigItemAction<ColumnHint> {
    type: ColumnHintActionKeys.REMOVE_COLUMN_HINT;
}

export const RemoveColumnHint = (columnHint: ColumnHint): RemoveColumnHintAction => ({
    configItem: columnHint,
    type: ColumnHintActionKeys.REMOVE_COLUMN_HINT,
});

export interface EditColumnHintAction extends EditConfigItemAction<ColumnHint> {
    type: ColumnHintActionKeys.EDIT_COLUMN_HINT;
}

export const EditColumnHint = (columnHint: ColumnHint): EditColumnHintAction => ({
    configItem: columnHint,
    type: ColumnHintActionKeys.EDIT_COLUMN_HINT,
});

export interface NewColumnHintAction extends NewConfigItemAction {
    type: ColumnHintActionKeys.NEW_COLUMN_HINT;
}

export const NewColumnHint = (): NewColumnHintAction => ({
    type: ColumnHintActionKeys.NEW_COLUMN_HINT,
});

export interface UpdateEditedColumnHintAction extends UpdateEditedConfigItemAction<ColumnHint> {
    type: ColumnHintActionKeys.UPDATE_EDITED_COLUMN_HINT;
}

export const UpdateEditedColumnHint = (
    columnHintUpdatedPart: Partial<ColumnHint>
): UpdateEditedColumnHintAction => ({
    configItemUpdatedPart: columnHintUpdatedPart,
    type: ColumnHintActionKeys.UPDATE_EDITED_COLUMN_HINT,
});

export interface SaveEditedColumnHintAction extends SaveEditedConfigItemAction {
    type: ColumnHintActionKeys.SAVE_EDITED_COLUMN_HINT;
}

export const SaveEditedColumnHint = (): SaveEditedColumnHintAction => ({
    type: ColumnHintActionKeys.SAVE_EDITED_COLUMN_HINT,
});

export interface CancelEditedColumnHintAction extends CancelEditedConfigItemAction {
    type: ColumnHintActionKeys.CANCEL_EDITED_COLUMN_HINT;
}

export const CancelEditedColumnHint = (): CancelEditedColumnHintAction => ({
    type: ColumnHintActionKeys.CANCEL_EDITED_COLUMN_HINT,
});

export interface AddColumnHintAction {
    configItem: ColumnHint;
    type: ColumnHintActionKeys.ADD_COLUMN_HINT;
}

export const AddColumnCustomisation = (configItem: ColumnHint): AddColumnHintAction => ({
    configItem,
    type: ColumnHintActionKeys.ADD_COLUMN_HINT,
});
export interface AddColumnHintListAction {
    configItemList: ColumnHint[];
    type: ColumnHintActionKeys.ADD_COLUMN_HINT_LIST;
}

export const AddColumnHintList = (configItemList: ColumnHint[]): AddColumnHintListAction => ({
    configItemList,
    type: ColumnHintActionKeys.ADD_COLUMN_HINT_LIST,
});

export interface RemoveColumnHintListAction {
    configItemList: ColumnHint[];
    type: ColumnHintActionKeys.REMOVE_COLUMN_HINT_LIST;
}

export const RemoveColumnHintList = (configItemList: ColumnHint[]): RemoveColumnHintListAction => ({
    configItemList,
    type: ColumnHintActionKeys.REMOVE_COLUMN_HINT_LIST,
});

export interface RemoveDefaultColumnHintAction {
    defaultType: DefaultColumnHintTypes;
    type: ColumnHintActionKeys.REMOVE_DEFAULT_COLUMN_HINT;
}

export const RemoveDefaultColumnHint = (
    defaultType: DefaultColumnHintTypes
): RemoveDefaultColumnHintAction => ({
    defaultType,
    type: ColumnHintActionKeys.REMOVE_DEFAULT_COLUMN_HINT,
});

export interface EditDefaultColumnHintAction {
    type: ColumnHintActionKeys.EDIT_DEFAULT_COLUMN_HINT;
}

export const EditDefaultColumnHint = (): EditDefaultColumnHintAction => ({
    type: ColumnHintActionKeys.EDIT_DEFAULT_COLUMN_HINT,
});

export interface UpdateEditedDefaultColumnHintAction {
    hints: HintList;
    defaultType: DefaultColumnHintTypes;
    type: ColumnHintActionKeys.UPDATE_EDITED_DEFAULT_COLUMN_HINT;
}

export const UpdateEditedDefaultColumnHint = (
    hints: HintList,
    defaultType: DefaultColumnHintTypes
): UpdateEditedDefaultColumnHintAction => ({
    defaultType,
    hints,
    type: ColumnHintActionKeys.UPDATE_EDITED_DEFAULT_COLUMN_HINT,
});

export interface SaveEditedDefaultColumnHintAction {
    type: ColumnHintActionKeys.SAVE_EDITED_DEFAULT_COLUMN_HINT;
}

export const SaveEditedDefaultColumnHint = (): SaveEditedDefaultColumnHintAction => ({
    type: ColumnHintActionKeys.SAVE_EDITED_DEFAULT_COLUMN_HINT,
});

export interface NewDefaultHintAction {
    type: ColumnHintActionKeys.NEW_DEFAULT_COLUMN_HINT;
}

export const NewDefaultColumnHint = (): NewDefaultHintAction => ({
    type: ColumnHintActionKeys.NEW_DEFAULT_COLUMN_HINT,
});
