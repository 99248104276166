/**
 * A WeakSet implementation that accepts two keys instead of one.
 */
export class TwoKeyWeakSet<Key1 extends object, Key2 extends object> {
    private root = new WeakMap<Key1, WeakSet<Key2>>();

    /**
     * Adds a key to the TwoKeyWeakSet.
     */
    public add(key1: Key1, key2: Key2): this {
        if (!this.root.has(key1)) {
            this.root.set(key1, new WeakSet());
        }
        this.root.get(key1)!.add(key2);

        return this;
    }

    /**
     * Determines if the TwoKeyWeakSet has the given key.
     */
    public has(key1: Key1, key2: Key2): boolean {
        return this.root.get(key1)?.has(key2) || false;
    }

    /**
     * Deletes a key from the TwoKeyWeakSet. Returns `true` if the key existed
     * (and therefore was deleted), or `false` otherwise.
     */
    public delete(key1: Key1, key2: Key2): boolean {
        const level1 = this.root.get(key1);
        if (!level1) {
            return false; // nothing to do
        }

        return level1.delete(key2);
    }
}
