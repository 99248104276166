import isEqual from 'lodash.isequal';
import { log } from './logger.js';

/**
 * Interface for application host information
 */
export interface AnalyticsHostApplication {
    name?: string;
    title: string;
    host: string;
    port: string;
    hostname: string;
}
/**
 * Interface for application details
 */
export interface AnalyticsCustomApplicationProperties {
    [key: string]: unknown;
}

/**
 * Interface for analytics classification
 */
export interface AnalyticsClassification {
    object: string;
    action: string;
    initiator: 'System';
    count: number;
}
/**
 * Interface for analytics properties
 */
export interface AnalyticsProperties {
    app: AnalyticsHostApplication;
    [key: string]: unknown;
}
/**
 * Interface for analytics properties with count (to account for deduplication)
 */
export interface AnalyticsProcessedProperties extends AnalyticsProperties {
    count: number;
}

/**
 * Interface for a single analytics payload
 */
export interface AnalyticsItem {
    properties: AnalyticsProcessedProperties;
    token: string;
    event: string;
    timestamp: string;
    context: {
        host: {
            hostname: string;
            port: string;
            host: string;
        };
        application: {
            deploymentId: string;
            appId: string;
            [key: string]: unknown;
        };
        client: {
            os: {
                name: string;
            };
            browser?: {
                userAgent: string | undefined;
                name: string;
                isMobile: boolean;
                isTouchDevice: boolean;
            };
            screen?: {
                height: number;
                width: number;
                viewportWidth: number;
                viewportHeight: number;
            };
        };
        user?: {
            kerberos: string;
        };
    };
}

/**
 * Interface for the track function options
 */
export interface AnalyticsTrackOptions {
    eventName: string;
    properties: AnalyticsProperties;
    customApplicationProperties?: AnalyticsCustomApplicationProperties;
}

/**
 * Interface for the constructor argument of the {@link Analytics} class
 */
export interface AnalyticsArguments {
    url: string; // default to prod gs-analytics url
    token: string;
    // if we want to support Analytics batching up tracking calls but not automatically flushing on an interval, we can signify that with a -1 for this property"
    batch?: boolean;
    appId?: string;
    deploymentId?: string;
    maxBatchItems?: number;
    credentials?: boolean;
    deduplicate?: boolean;
    errorTolerance?: number;
    batchSyncInterval?: number;
    kerberos?: string;
}

/**
 * Interface for analytics custom properties to be tracked such as features
 */
export interface AnalyticsDetails {
    [k: string]: unknown;
}

/**
 * This is the analytics class that handles all analytic call for all components
 */
export abstract class AbstractAnalytics {
    protected config: Required<Omit<AnalyticsArguments, 'kerberos'>> &
        Pick<AnalyticsArguments, 'kerberos'>;
    private run = true;
    private queue: AnalyticsItem[];
    private errorCount: number;
    private intervalId: NodeJS.Timeout | undefined;

    constructor(config: AnalyticsArguments) {
        this.queue = [];
        this.errorCount = 0;
        this.config = {
            appId: config.appId || '',
            batch: config.batch !== undefined ? config.batch : true,
            batchSyncInterval:
                config.batchSyncInterval !== undefined ? config.batchSyncInterval : 10000,
            credentials: config.credentials !== undefined ? config.credentials : false,
            deduplicate: config.deduplicate !== undefined ? config.deduplicate : true,
            deploymentId: config.deploymentId || '',
            errorTolerance: config.errorTolerance !== undefined ? config.errorTolerance : 10,
            maxBatchItems: config.maxBatchItems !== undefined ? config.maxBatchItems : 50,
            token: config.token,
            url: config.url,
            kerberos: config.kerberos,
        };
        this.initializeInterval();
    }

    /**
     * log a feature of the library being used (put it on a queue and post it in interval)
     */
    public track(options: AnalyticsTrackOptions) {
        if (!this.run) {
            return;
        }

        const analyticsItem = this.generateAnalyticsItem(options);

        if (this.config.deduplicate) {
            this.addDeduplicatedEventInQueue(analyticsItem);
        } else {
            this.queue.push(analyticsItem);
        }

        if (this.config.batch === false) {
            this.flush();
        }
    }

    public stop() {
        this.run = false;
        clearInterval(this.intervalId);
        this.intervalId = undefined;
    }

    public start() {
        if (this.intervalId || this.config.batch === false) {
            return;
        }
        this.run = true;
        this.initializeInterval();
    }

    /**
     * Empties the queue. Should be called before clearing the interval, to ensure no data is getting lost.
     */
    public async flush(): Promise<void> {
        while (this.queue.length > 0) {
            const chunk = this.queue.splice(0, this.config.maxBatchItems);
            log('Chunk length', chunk.length, ' | Remaining queue length', this.queue.length);
            await this.doPost(chunk);
        }
    }

    /**
     * Takes input an event and if exact same event exists in queue, increase its count otherwise add it to queue
     */
    protected addDeduplicatedEventInQueue(event: AnalyticsItem) {
        const duplicatedEventIndex = this.queue.findIndex(
            queuedEvent =>
                event.event === queuedEvent.event &&
                // we don't want to compare the count prop on the object so we replace it with 0
                isEqual(
                    { ...event.properties, count: 0 },
                    { ...queuedEvent.properties, count: 0 }
                ) &&
                isEqual(event.context, queuedEvent.context)
        );

        if (duplicatedEventIndex < 0) {
            event.properties.count = 1;
            this.queue.push(event);
        } else {
            this.queue[duplicatedEventIndex].properties.count++;
            this.queue[duplicatedEventIndex].timestamp = event.timestamp;
        }
    }

    /**
     * Posts the data as a JSON string to the configured endpoint. Does not wait
     * for a return or perform a callback. If this call fails the error will not leak to the window.
     * @param {Object} data The data to be posted to the endpoint
     */
    protected abstract post(data: AnalyticsItem[]): Promise<any>;

    protected abstract getClientConfig(): AnalyticsItem['context']['client'];

    private generateAnalyticsItem(options: AnalyticsTrackOptions): AnalyticsItem {
        const { properties, eventName } = options;

        return {
            properties: { ...properties, count: 1 },
            token: this.config.token,
            event: eventName || 'Unknown Event',
            timestamp: new Date().toISOString(),
            context: {
                host: {
                    hostname: properties.app.hostname || '',
                    port: properties.app.port || '',
                    host: properties.app.host || '',
                },
                application: {
                    deploymentId: this.config.deploymentId || '',
                    appId: this.config.appId || '',
                    ...(options ? options.customApplicationProperties : {}),
                },
                client: this.getClientConfig(),
                user: this.config.kerberos
                    ? {
                          kerberos: this.config.kerberos,
                      }
                    : undefined,
            },
        };
    }

    private initializeInterval() {
        if (this.config.batch === false) {
            return;
        }
        this.intervalId = setInterval(() => {
            if (this.queue.length > 0) {
                const chunk = this.queue.splice(0, this.config.maxBatchItems);
                this.doPost(chunk);
            }
        }, this.config.batchSyncInterval || 10000);
    }

    private async doPost(data: AnalyticsItem[]) {
        try {
            await this.post(data);
        } catch (err: unknown) {
            // Stop running after a max number of errors have been hit
            this.errorCount += 1;
            const errorTolerance =
                this.config.errorTolerance != undefined ? this.config.errorTolerance : 10;
            log(
                'AnalyticsQueue post error. Will try',
                errorTolerance - this.errorCount,
                'more times'
            );
            if (this.errorCount >= errorTolerance) {
                this.run = false;
                log('AnalyticsQueue stopped after hitting max errors');
            }
            throw err;
        }
    }
}
