import {
    StyleSheet,
    CssClassDefinitionsObject,
    CssProperties,
} from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { rgba } from 'polished';
import { getFormTheme } from './form-theme';
import { InputSize, InputStatus } from './types';
import { DeepReadonly } from 'ts-essentials';
import './input-theme-overrides';

export const inputHeights = {
    sm: '24px',
    md: '32px',
    lg: '40px',
};

export const inputGrouped = {
    margin: '-1px 0px -1px -1px',
    flexGrow: 1,
    borderRadius: 0,
    '&:hover': {
        zIndex: 1,
    },
    '&:focus': {
        zIndex: 2,
    },
};

const displayInline = 'inline-flex';
const displayHidden = 'none';

export function getInputBaseStyles({ theme }: { theme: Theme }) {
    const formTheme = getFormTheme(theme);

    return {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: formTheme.input.backgroundColor,
        backgroundClip: 'padding-box',
        height: '32px',
        borderRadius: '2px',
        border: `1px solid ${formTheme.input.borderColor}`,
        transition: 'border-color 0.15s ease-in-out',
        boxSizing: 'border-box',

        '&:hover': {
            borderColor: formTheme.input.borderHoverColor,
        },
        '&:focus': {},
        '&:focus-within': {
            boxShadow: 'none',
            borderColor: theme.colorScheme.primary,
        },

        '& [data-gs-uitk-component=icon]': {
            display: 'flex',
        },
    };
}

// `parentSelector` is the Angular "host" element.
export interface InputThemeStyleSheetProps {
    theme: Theme;
    size?: InputSize;
    status?: InputStatus;
    disabled?: boolean;
    readOnly?: boolean;
    inline?: boolean;
    hidden?: boolean;
    grouped?: boolean;
    parentSelector?: string;
}

export interface InputCssClasses {
    root: string;
}

export type InputStyledClasses = CssClassDefinitionsObject<keyof InputCssClasses>;

export interface InputStyleOverridesParams {
    props: DeepReadonly<InputThemeStyleSheetProps>;
    createDefaultStyledClasses: () => InputStyledClasses;
}

export const inputStyleSheet = new StyleSheet('input', (props: InputThemeStyleSheetProps) => {
    return createComponentClassDefinitions<InputThemeStyleSheetProps, InputStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.input
    );
});

export const globalInputClass = 'gs-input';

function getGroupedProperties(parentSelector?: InputThemeStyleSheetProps['parentSelector']) {
    const { margin, flexGrow, borderRadius } = inputGrouped;
    return {
        margin,
        flexGrow,
        borderRadius,
        '&:hover': inputGrouped['&:hover'],
        [parentSelector ? '&:focus-within' : '&:focus']: inputGrouped['&:focus'],
    };
}

export function getStatusStyles({ theme, status }: { theme: Theme; status?: InputStatus }) {
    const formTheme = getFormTheme(theme);
    if (!status || status === 'none') {
        return {};
    }

    let statusColor = theme.status[status || 'none'].bold;
    // Inputs & form feedback use the 'warningAlt' treatment as the default warning color,
    // yellow, is very light for borders and fails AA contrast of 3:1 for UI elements
    if (status === 'warning') {
        statusColor = theme.status.warningAlt.bold;
    } else if (status === 'loading') {
        statusColor = formTheme.input.borderColor;
    }

    return {
        borderColor: statusColor,
    };
}

function getDisabledProperties({ theme }: { theme: Theme }) {
    const disabledProps = {
        opacity: theme.state.disabledOpacity,
        margin: '-1px 0px',
    };

    return {
        ...disabledProps,
        '&:hover': { ...disabledProps },
        '&:active': { ...disabledProps },
        '&:focus': { ...disabledProps },
    };
}

function getReadonlyProperties({ theme }: { theme: Theme }) {
    return {
        backgroundColor: rgba(theme.surface.strong.toString(), 0.1),
        borderColor: theme.border.moderate,
        color: theme.text.secondary,
    };
}

function getDisplay(
    defaultValue: CssProperties['display'],
    inline?: boolean,
    hidden?: boolean
): CssProperties['display'] {
    if (hidden) {
        return displayHidden;
    }
    if (inline) {
        return displayInline;
    }
    return defaultValue;
}

export function getInputStyleSheet(props: InputThemeStyleSheetProps): any {
    const {
        theme,
        size = 'md',
        status,
        disabled,
        readOnly,
        inline,
        hidden,
        grouped,
        parentSelector,
    } = props;

    const inputBaseStyles = getInputBaseStyles({ theme });
    const baseStyleSheet = {
        ...inputBaseStyles,
        display: getDisplay(inputBaseStyles.display, inline, hidden),
        height: inputHeights[size],
    };

    const baseObjWithPseudo = {
        '&:hover': {},
        '&:focus': {},
        '&:focus-within': {},
    };
    const groupStyleSheet = {
        ...baseObjWithPseudo,
        ...(grouped ? getGroupedProperties(parentSelector) : {}),
    };
    const statusStyleSheet = getStatusStyles({ theme, status });
    const disabledStyleSheet = {
        ...baseObjWithPseudo,
        ...(disabled ? getDisabledProperties({ theme }) : {}),
    };
    const readonlyStyleSheet = {
        ...baseObjWithPseudo,
        ...(readOnly ? getReadonlyProperties({ theme }) : {}),
    };

    return {
        ...baseStyleSheet,
        ...groupStyleSheet,
        ...statusStyleSheet,
        ...readonlyStyleSheet,
        ...disabledStyleSheet,
        '&:hover': {
            ...baseStyleSheet['&:hover'],
            ...groupStyleSheet['&:hover'],
            ...readonlyStyleSheet['&:hover'],
            ...disabledStyleSheet['&:hover'],
        },
        '&:focus': {
            boxShadow: 'none',
            borderColor: 'transparent',
            ...groupStyleSheet['&:focus'],
            ...readonlyStyleSheet['&:focus'],
            ...disabledStyleSheet['&:focus'],
        },
        '&:focus-within': {
            ...baseStyleSheet['&:focus-within'],
            ...groupStyleSheet['&:focus-within'],
            ...readonlyStyleSheet['&:focus-within'],
            ...disabledStyleSheet['&:focus'],
        },
    };
}

function createDefaultStyledClasses(props: InputThemeStyleSheetProps): InputStyledClasses {
    return {
        root: getInputStyleSheet(props),
    };
}
