import {
    CSSProperties,
    ReactNode,
    FormEvent,
    Ref,
    ForwardRefExoticComponent,
    useMemo,
    useEffect,
    forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import {
    FormContext as FormCommonContext,
    defaultFormContext,
    FormProps as FormCommonProps,
    defaultFormProps,
    formStatuses,
    updateContext,
} from '@gs-ux-uitoolkit-common/form';
import { FormContext } from './form-context';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { componentAnalytics } from './analytics-tracking';

export interface FormProps extends FormCommonProps<CSSProperties> {
    children?: ReactNode;

    /**
     * Called when the form submits.
     */
    onSubmit?: (event: FormEvent<HTMLFormElement>) => void;

    /**
     * Allows a reference to the HTML `<form>` element.
     */
    ref?: Ref<HTMLFormElement>;
}

/**
 * Form extends HTML `<form>`. Virtually all `<form>` attributes can be set as
 * React props on Form. See [Mozilla Developer Network](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/form)
 * for more information.
 *
 * UI Toolkit Form enhances `<form>` with "status" and "preventSubmit" props.
 */
export const Form: ForwardRefExoticComponent<FormProps> = forwardRef(
    (
        {
            children,
            disabled,
            inline = defaultFormProps.inline,
            preventSubmit = defaultFormProps.preventSubmit,
            status,
            noValidate = defaultFormProps.noValidate,
            onSubmit,
            ...props
        },
        ref: Ref<HTMLFormElement>
    ) => {
        function onFormSubmit(event: FormEvent<HTMLFormElement>) {
            if (preventSubmit) {
                event.preventDefault();
            }
            if (onSubmit) {
                onSubmit(event);
            }
            return !preventSubmit;
        }

        useTheme(); // not consuming the theme just yet, but injects the fonts into the DOM
        const formContext: FormCommonContext = useMemo(
            () => updateContext([defaultFormContext, { disabled, inline, status }]),
            [disabled, inline, status]
        );

        useEffect(() => {
            //track component has rendered
            componentAnalytics.trackRender({ officialComponentName: 'form' });
        }, []); // Only run once

        return (
            <FormContext.Provider value={formContext}>
                <form
                    data-gs-uitk-component="form"
                    ref={ref}
                    noValidate={noValidate}
                    {...props}
                    data-disabled={disabled}
                    onSubmit={onFormSubmit}
                >
                    {children}
                </form>
            </FormContext.Provider>
        );
    }
);
Form.displayName = 'Form';

Form.propTypes = {
    acceptCharset: PropTypes.string,
    action: PropTypes.string,
    autoComplete: PropTypes.string,
    disabled: PropTypes.bool,
    encType: PropTypes.string,
    inline: PropTypes.bool,
    method: PropTypes.string,
    name: PropTypes.string,
    noValidate: PropTypes.bool,
    preventSubmit: PropTypes.bool,
    target: PropTypes.string,
    status: PropTypes.oneOf(formStatuses),
};
