import { UPDATE_CURRENT_PAGE, SHOW_USER_SEARCH_DETAILS_GRID, TOGGLE_IN_ACTIVE_USERS, CLEAR_USER_DETAILS_DATA } from '../actions';
import actionTypes from '../../../../actions/page/actionTypes';

const defaultPageData = {};

export const PAGES = {
  LANDING_PAGE: 'LANDING_PAGE',
  DETAILS_PAGE: 'DETAILS_PAGE',
};

export function userSearchPageDataReducer(state = defaultPageData, action) {
  switch (action.type) {
    case actionTypes.ADD_PAGE_DATA:
      return state;
    default:
      return state;
  }
}

const defaultPageContext = {
  users: { users: [], firms: [] },
  searchTerm: '',
  searchInProgress: false,
  currentPage: PAGES.LANDING_PAGE,
  showLoader: false,
};

export function userSearchPageContextReducer(state = defaultPageContext, action) {
  switch (action.type) {
    case UPDATE_CURRENT_PAGE: {
      return { ...state, currentPage: action.data };
    }
    case SHOW_USER_SEARCH_DETAILS_GRID: {
      return { ...state, showDetailsGrid: action.data };
    }
    case TOGGLE_IN_ACTIVE_USERS: {
      return { ...state, inActiveUser: action.data };
    }
    case CLEAR_USER_DETAILS_DATA: {
      return { ...state, userDetailsStatus: null, userDetailsAccountStatus: null };
    }
    default:
      return state;
  }
}
