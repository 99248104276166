import actionTypes from './actionTypes';

export const approveTrade = data => (
  {
    type: actionTypes.APPROVE_TRADE,
    currentApprovalRowData: data
  }
);

export const rejectTrade = data => (
  {
    type: actionTypes.REJECT_TRADE,
    currentApprovalRowData: data
  }
);


export const addTradeApprovalResponse = data => (
  {
    type: actionTypes.ADD_TRADE_APPROVAL_RESPONSE,
    data
  }
);

export const addTradeRejectionResponse = data => (
  {
    type: actionTypes.ADD_TRADE_REJECTION_RESPONSE,
    data
  }
);

export const openRejectionModal = data => (
  {
    type: actionTypes.OPEN_TRADE_REJECTION_MODAL,
    data
  }
);

export const openApprovalModal = data => (
  {
    type: actionTypes.OPEN_TRADE_APPROVAL_MODAL,
    data
  }
);

export const closeRejectionModal = data => (
  {
    type: actionTypes.CLOSE_TRADE_REJECTION_MODAL,
    data
  }
);

export const closeApprovalModal = data => (
  {
    type: actionTypes.CLOSE_TRADE_APPROVAL_MODAL,
    data
  }
);


export const addTradeApprovalData = data => (
  {
    type: actionTypes.ADD_TRADE_APPROVAL_DATA,
    data
  }
);

export const updateTradeApprovalData = data => (
  {
    type: actionTypes.UPDATE_TRADE_APPROVAL_DATA,
    data
  }
);

export const reloadTradeApprovalsData = data => (
  {
    type: actionTypes.RELOAD_TRADE_APPROVAL_DATA,
    data
  }
);

export const tradeApprovalDataLoading = data => ({
  type: actionTypes.TRADE_APPROVAL_DATA_LOADING,
  data
});

export const updateTradeApprovalLoader = data => ({
  type: actionTypes.TRADE_APPROVAL_LOADER,
  data
});

export const updateTradeRejectionLoader = data => ({
  type: actionTypes.TRADE_REJECTION_LOADER,
  data
});

export const clearTradeApprovalResponse = data => ({
  type: actionTypes.CLEAR_TRADE_APPROVAL_RESPONSE,
  data
});

export const clearTradeRejectionResponse = data => ({
  type: actionTypes.CLEAR_TRADE_REJECTION_RESPONSE,
  data
});

export const disableCurrentTrade= data => ({
  type: actionTypes.DISABLE_CURRENT_TRADE,
  data
})
