import {
    PropsWithChildren,
    MouseEvent,
    ForwardRefExoticComponent,
    Ref,
    forwardRef,
    useEffect,
} from 'react';
import {
    linkStyleSheet,
    LinkCommonProps,
    defaultLinkProps,
    getLinkRootClassNames,
} from '@gs-ux-uitoolkit-common/text';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { ReactComponentProps } from '@gs-ux-uitoolkit-react/component';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { wrapTextChildrenInSpan } from '@gs-ux-uitoolkit-react/shared';
import { componentAnalytics } from './analytics-tracking';

export type LinkProps = PropsWithChildren<
    ReactComponentProps & LinkCommonProps<MouseEvent<HTMLAnchorElement>>
>;

/**
 * Link is an enhancement of the HTML anchor element with GS Design System styles applied.
 */
export const Link: ForwardRefExoticComponent<LinkProps & { ref?: Ref<HTMLAnchorElement> }> =
    forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
        const theme = useTheme();

        const {
            className,
            emphasis = defaultLinkProps.emphasis,
            href,
            onClick,
            size,
            children,
            ...otherProps
        } = props;

        function onClickLink(event: MouseEvent<HTMLAnchorElement>) {
            if (!href) {
                event.preventDefault();
            }
            onClick && onClick(event);
        }

        const cssClasses = useStyleSheet(linkStyleSheet, { theme, emphasis, size });

        useEffect(() => {
            //track component has rendered
            componentAnalytics.trackRender({ officialComponentName: 'link' });
        }, []); // Only run once

        // `ref` (enabled by forwardRef above) is provided to allow
        // compatiblity with NextJS's Link component.
        // See https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-functional-component
        return (
            <a
                ref={ref}
                data-gs-uitk-component="link"
                className={getLinkRootClassNames({ cssClasses, className })}
                href={href || defaultLinkProps.href}
                onClick={onClickLink}
                {...otherProps}
            >
                {wrapTextChildrenInSpan(children)}
            </a>
        );
    });

Link.displayName = 'Link';
