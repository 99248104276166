import {
    Children,
    FunctionComponent,
    PropsWithChildren,
    ReactNode,
    cloneElement,
    isValidElement,
    memo,
    useCallback,
    useRef,
} from 'react';
import { tooltipTargetStyleSheet } from '@gs-ux-uitoolkit-common/tooltip';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { Tooltip, CommonReactTooltipProps } from './tooltip';

export interface TooltipTargetProps extends CommonReactTooltipProps {
    inline?: boolean;

    label: ReactNode; // string | JSX.Element | HTMLElement | RefObject<HTMLElement>;
}

/**
 * The TooltipTarget variation of Tooltip is the simplest way to link a Tooltip to a target.
 */
export const TooltipTarget: FunctionComponent<PropsWithChildren<TooltipTargetProps>> = memo(
    props => {
        const targetRef = useRef<HTMLSpanElement>(null);
        const { children, inline = false, label } = props;
        const theme = useTheme();
        const cssClasses = useStyleSheet(tooltipTargetStyleSheet, { inline, theme });

        const disableFocusOnChildrenNodes = useCallback(
            (children: ReactNode): ReactNode =>
                Children.map(children, child => {
                    if (!isValidElement(child)) return child;

                    return cloneElement(child, {
                        ...child.props,
                        /** Removes focus on all custom triggers passed by users.
                         * Buttons, inputs are focusable by default so we need to remove that in order to prevent double "focus"
                         */
                        tabIndex: -1,
                        children: disableFocusOnChildrenNodes(child.props.children),
                    });
                }),
            []
        );

        return (
            <>
                <span
                    ref={targetRef}
                    className={cssClasses.root}
                    data-gs-uitk-component="tooltip-target"
                    tabIndex={0}
                    role="button"
                    aria-pressed="false"
                    aria-live="polite"
                >
                    {disableFocusOnChildrenNodes(children)}
                </span>
                <Tooltip {...props} target={targetRef}>
                    {label}
                </Tooltip>
            </>
        );
    }
);
TooltipTarget.displayName = 'TooltipTarget';
