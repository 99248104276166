import { StyleSheet, CssClasses, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { fontFamily } from '@gs-ux-uitoolkit-common/design-system';
import { getOverflowStyles } from '@gs-ux-uitoolkit-common/shared';
import { defaultPopoverProps } from './popover-props';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { DeepReadonly } from 'ts-essentials';
import './popover-theme-overrides';

export interface PopoverStyleSheetProps {
    size?: string;
    theme: Theme;
}

export type PopoverCssClasses = CssClasses<typeof popoverStyleSheet>;

export type PopoverStyledClasses = CssClassDefinitionsObject<
    'content' | 'header' | 'body' | 'dismissButton'
>;

export interface PopoverStyleOverridesParams {
    props: DeepReadonly<PopoverStyleSheetProps>;
    createDefaultStyledClasses: () => PopoverStyledClasses;
}

interface StylesForSize {
    paddingY: string;
    paddingX: string;
    bodyFontSize: string;
    titleFontSize: string;
}

enum Sizes {
    sm = 'sm',
    md = 'md',
}

export const popoverStyleSheet = new StyleSheet('popover', (props: PopoverStyleSheetProps) => {
    return createComponentClassDefinitions<PopoverStyleSheetProps, PopoverStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.popover
    );
});

function createDefaultStyledClasses({ theme, size }: PopoverStyleSheetProps): PopoverStyledClasses {
    const stylesBasedOnSize: { [key in Sizes]: StylesForSize } = {
        sm: {
            paddingY: '20px',
            paddingX: '16px',
            bodyFontSize: theme.typography.body03.fontSize,
            titleFontSize: theme.typography.heading05.fontSize,
        },
        md: {
            paddingY: '20px',
            paddingX: '16px',
            bodyFontSize: theme.typography.body02.fontSize,
            titleFontSize: theme.typography.heading04.fontSize,
        },
    };

    const popoverSize = size || defaultPopoverProps.size;
    const popoverStyles = stylesBasedOnSize[popoverSize as Sizes];

    return {
        content: {
            fontFamily: fontFamily.goldmanSans,
            fontSize: popoverStyles.bodyFontSize,
            borderRadius: '2px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            padding: `${popoverStyles.paddingY} ${popoverStyles.paddingX}`,
        },
        header: {
            fontSize: popoverStyles.titleFontSize,
            marginBottom: '12px',
        },
        body: {
            ...getOverflowStyles('wrap'),
        },
        dismissButton: {
            cursor: 'pointer',
            alignSelf: 'flex-end',
            marginTop: '-8px',
            padding: `0 0 0 ${popoverStyles.paddingY}`,
            background: 'none',
            color: 'inherit',
            border: 'none',
            font: 'inherit',
            display: 'flex',
            alignItems: 'center',
            '> span': {
                fontSize: popoverStyles.titleFontSize,
            },
        },
    };
}
