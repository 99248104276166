import { ComponentType } from 'react';
import { useTheme } from './use-theme';
import { Theme } from '@gs-ux-uitoolkit-common/theme';

export interface WithThemeProps {
    theme: Theme;
}
export function withTheme<T>(WrappedComponent: ComponentType<T & WithThemeProps>) {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    const ComponentWithTheme = (props: T) => {
        const theme = useTheme();
        return <WrappedComponent {...(props as T)} theme={theme} />;
    };

    ComponentWithTheme.displayName = `withTheme(${displayName})`;

    return ComponentWithTheme;
}
