import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/core/Modal';
import Button from '../../../../components/core/Button';
import translator from '../../../../services/translator';
import {ThresholdLabelRenderer} from '../ThresholdLabelRenderer';
import {ThresholdGroupLabelRenderer} from '../ThresholdGroupLabelRenderer';
import './index.scss';

const {translate: t} = translator;

const deleteThresholdModalStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  height: 'auto',
  transform: 'translate(-50%, -50%)'
};

const LabelRenderer = ({isShowThresholdGroupHeader, threshHoldRecordSelected}) => {
  const thresholdLabelRendererProps = {
    isShowTooltipForShareClassName: !!isShowThresholdGroupHeader(),
    ...threshHoldRecordSelected
  };

  if (isShowThresholdGroupHeader()) {
    return <ThresholdGroupLabelRenderer {...thresholdLabelRendererProps} />;
  }
  return <ThresholdLabelRenderer {...thresholdLabelRendererProps} />;
};

LabelRenderer.propTypes = {
  isShowThresholdGroupHeader: PropTypes.bool,
  threshHoldRecordSelected: PropTypes.object
};

const DeleteThresholdModal = ({threshHoldRecordSelected = {}, isModalOpen, handleClose, handleDeleteThreshold}) => {
  const deleteThresholdModalRef = useRef();

  const onClose = (status) => {
    deleteThresholdModalRef.current.closeModal(() => handleClose && handleClose(status));
  };

  const handleDeleteThresholdBtnClick = () => {
    handleDeleteThreshold();
  };

  const isShowThresholdGroupHeader = () => {
    return threshHoldRecordSelected.items && threshHoldRecordSelected.items.length > 1;
  };

  return (
    <Modal
      ref={deleteThresholdModalRef}
      setThresholdModalStyle={deleteThresholdModalStyle}
      suppressScrollOnActive={true}
      customClass="modal--center delete-confirm-threshold-modal delete-confirm-threshold-modal__index"
      backdropCustomClass="delete-confirm-threshold-modal__backdrop-index"
      open={isModalOpen}
      handleClose={handleClose}
      animation={false}>
      <div className="delete-confirm-threshold-modal">
        <div className="delete-confirm-threshold-modal__heading">{t('tkDeleteThreshold')}</div>
        <div className="delete-confirm-threshold-modal__message">
          {t('tkDeleteThresholdAlertMsg')}
        </div>
        <div className="delete-confirm-threshold-modal__message">
          <ul>
            <li>
              <LabelRenderer
                  isShowThresholdGroupHeader={isShowThresholdGroupHeader}
                  threshHoldRecordSelected={threshHoldRecordSelected}
              />
            </li>
          </ul>
        </div>
        <div className="delete-confirm-threshold-modal__bottomRow">
          <div className="button-container">
            <Button
              label={t('tkCancel')}
              customClass='button-secondary-small'
              clickHandler={(event) => onClose(event)}
            />
            <Button
              label={t('tkDelete')}
              customClass='button-primary-small'
              clickHandler={(event) => handleDeleteThresholdBtnClick(event)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

DeleteThresholdModal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDeleteThreshold: PropTypes.func,
  threshHoldRecordSelected: PropTypes.string
};

export default DeleteThresholdModal;
