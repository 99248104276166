import { isMatch, isEqual } from 'gs-uitk-lodash';
import { createRef, Component } from 'react';
import { Theme, withTheme } from '@gs-ux-uitoolkit-react/theme';
import { AgGridReact } from '@ag-grid-community/react';
import {
    AgGridWrapper,
    ColumnApi,
    CustomDRAOptions,
    CustomLoadingCellRenderer,
    DataGridApi,
    Datasource,
    DatasourceConfiguration,
    DatasourceFactory,
    DatasourceType,
    DraDisconnectedOverlay,
    DraViewportDatasource,
    FirstDataRenderedEvent,
    GridApi,
    GridOptions,
    GridReadyEvent,
    LoadingAgOverlay,
    NoRowsOverlay,
    SideBarDef,
    SuppressKeyboardEventParams,
    SystemConfigurationSetGridWrapper,
    ToolPanelDef,
    defaultChartThemes,
    getDefaultCustomChartThemes,
    defaultToolbarState,
    normalizeFeatures,
    onDataGridSuppressKeyboardEventForDra,
    processCellForExport,
    dataGridStyleSheet,
    globalClassName,
    DEFAULT_SWALLOW_FORMATTING_ERROR_CELL_VALUE,
    RowHeightParams,
} from '@gs-ux-uitoolkit-common/datagrid';
import {
    DataStackPlugin,
    DefaultRegistryState,
    DraDatasource,
    Registries,
    RegistryContext,
    ThemeManager,
    ThemeTypeClassName,
    logger,
    DataGridSize,
} from '@gs-ux-uitoolkit-common/datacore';
import { cx } from '@gs-ux-uitoolkit-common/style';
import { DataGridProps } from './props';
import { componentAnalytics } from '../../analytics-tracking';
import { ColDef, ProcessCellForExportParams } from '@ag-grid-community/core';
import { AnalyticsDetails } from '@gs-ux-uitoolkit-react/analytics';
import { Size, Density } from '@gs-ux-uitoolkit-react/design-system';
import { hasOwnProperty } from 'gs-uitk-object-utils';

export interface DataGridInternalState {
    api: GridApi | null;
    columnApi: ColumnApi | null;
    setRegistriesAndStore:
        | ((
              registries: Registries,
              plugins: DataStackPlugin[],
              defaultState?: DefaultRegistryState
          ) => void)
        | null;
    hasError: boolean;
    firstAgGridOptions: GridOptions;
    size: Size | undefined;
    density: Density | undefined;
    defaultColDef: ColDef | undefined;
}

/**
 * The React wrapper that houses ag-Grid
 *
 * @param props The props of the wrapper and the underlying agGrid
 */
class DataGridInternalComponent extends Component<
    DataGridProps & { theme: Theme },
    DataGridInternalState
> {
    public static defaultProps: Partial<DataGridProps> = {
        swallowFormattingErrorCellValue: DEFAULT_SWALLOW_FORMATTING_ERROR_CELL_VALUE,
    };

    private isUnmounted = false;
    private hasMounted = false;
    private theDatasource: Datasource | null = null;
    public gridWrapper: AgGridWrapper | null = null;

    private wrapperRef = createRef<HTMLDivElement>();
    private overlayContainerRef = createRef<HTMLDivElement>();
    private prevStateDensity?: Density;
    private prevStateSize?: Size;
    public constructor(props: DataGridProps & { theme: Theme }) {
        super(props);
        this.state = {
            api: null,
            columnApi: null,
            firstAgGridOptions: this.props.gridOptions || {},
            hasError: false,
            setRegistriesAndStore: null,
            size: this.props.size,
            density: this.props.density,
            defaultColDef: this.props.gridOptions?.defaultColDef
                ? undefined
                : {
                      ...this.props.defaultColDef,
                      suppressKeyboardEvent: params => this.onDataGridSuppressKeyboardEvent(params),
                  },
        };
    }

    public componentDidMount() {
        this.hasMounted = true;
        this.isUnmounted = false;
        const features = normalizeFeatures(this.props);
        // track component has rendered
        componentAnalytics.trackRender({
            features: features as unknown as AnalyticsDetails,
            officialComponentName: 'datagrid',
        });

        if (this.props.gridOptions?.defaultColDef) {
            this.props.gridOptions.defaultColDef = {
                ...this.props.gridOptions.defaultColDef,
                suppressKeyboardEvent: params => this.onDataGridSuppressKeyboardEvent(params),
            };
        }
    }

    public async componentDidUpdate(
        prevProps: DataGridProps & { theme: Theme },
        prevState: DataGridInternalState
    ) {
        // we want to compare that only property values of the datasource config have changes
        const previousDatasourceConfigWithoutFixedDRAFilter =
            this.datasourceConfigWithoutFixedDraFilter(prevProps.datasourceConfiguration);
        const currentDatasourceConfigWithoutFixedDRAFilter =
            this.datasourceConfigWithoutFixedDraFilter(this.props.datasourceConfiguration);

        if (
            previousDatasourceConfigWithoutFixedDRAFilter &&
            currentDatasourceConfigWithoutFixedDRAFilter &&
            !isMatch(
                previousDatasourceConfigWithoutFixedDRAFilter,
                currentDatasourceConfigWithoutFixedDRAFilter
            )
        ) {
            logger.info(
                'New datasource configuration received. Creating a new gridWrapper',
                this.props.datasourceConfiguration
            );
            const customOptions: CustomDRAOptions = this.buildCustomOptions();
            const { draDatasource } = await this.createDataSource(customOptions);
            if (this.gridWrapper && this.props.datasourceConfiguration) {
                this.gridWrapper.reset(draDatasource, this.props.datasourceConfiguration);
            }
        } else if (
            // If only the fixedDraFilter has changed then we update the store and refresh the filtering
            this.gridWrapper &&
            prevProps.datasourceConfiguration &&
            this.props.datasourceConfiguration &&
            !isEqual(
                prevProps.datasourceConfiguration.fixedDRAFilter,
                this.props.datasourceConfiguration.fixedDRAFilter
            )
        ) {
            const gridWrapperConfig = this.gridWrapper.getReduxStore().getState()
                .systemConfiguration.gridWrapperConfiguration;
            const dsConfig = gridWrapperConfig.datasourceConfiguration;

            if (dsConfig) {
                const gridWrapperConfigDatsourceConfigClone = {
                    ...dsConfig,
                };
                gridWrapperConfigDatsourceConfigClone.fixedDRAFilter =
                    this.props.datasourceConfiguration.fixedDRAFilter;

                const gridWrapperConfigClone = {
                    ...gridWrapperConfig,
                };
                gridWrapperConfigClone.datasourceConfiguration =
                    gridWrapperConfigDatsourceConfigClone;
                this.gridWrapper
                    .getReduxStore()
                    .dispatch(SystemConfigurationSetGridWrapper(gridWrapperConfigClone));
            }
        }
        if (prevProps.id !== this.props.id && this.gridWrapper) {
            this.gridWrapper.setId(this.props.id);
        }
        if (
            prevProps.columnDataTypeDefinition &&
            this.props.columnDataTypeDefinition &&
            this.gridWrapper &&
            !isEqual(prevProps.columnDataTypeDefinition, this.props.columnDataTypeDefinition)
        ) {
            this.gridWrapper.setColumnDataTypeDefinition(this.props.columnDataTypeDefinition);
        }
        if (prevProps.theme !== this.props.theme) {
            this.gridWrapper?.setTheme(this.props.theme);
            this.state.api?.refreshHeader();
        }
        if (prevState.density !== this.state.density || prevState.size !== this.state.size) {
            this.state.api?.resetRowHeights();
        }

        if (
            (!prevProps.gridOptions?.defaultColDef && this.props.gridOptions?.defaultColDef) ||
            (prevProps.gridOptions?.defaultColDef &&
                this.props.gridOptions?.defaultColDef &&
                !isEqual(
                    prevProps.gridOptions?.defaultColDef,
                    this.props.gridOptions?.defaultColDef
                ))
        ) {
            this.props.gridOptions.defaultColDef = {
                ...this.props.gridOptions.defaultColDef,
                suppressKeyboardEvent: params => this.onDataGridSuppressKeyboardEvent(params),
            };
        } else if (
            (this.props.defaultColDef && !prevProps.defaultColDef) ||
            (prevProps.defaultColDef &&
                this.props.defaultColDef &&
                !isEqual(prevProps.defaultColDef, this.props.defaultColDef))
        ) {
            this.setState({
                defaultColDef: {
                    ...this.props.defaultColDef,
                    suppressKeyboardEvent: params => this.onDataGridSuppressKeyboardEvent(params),
                },
            });
        }
    }

    getRowHeight = (params: RowHeightParams) => {
        if (this.props.getRowHeight) {
            return this.props.getRowHeight(params);
        }
        const themeConfig = ThemeManager.getTheme(
            this.state.density,
            this.state.size as DataGridSize
        );
        return themeConfig?.rowHeight;
    };

    public componentWillUnmount() {
        this.isUnmounted = true;
        if (this.gridWrapper) {
            this.gridWrapper.destroy();
        }
        dataGridStyleSheet.unmount(this);
    }

    /**
     * We use onGridReady for initialising the grid for DRA and URL datasources
     * as we won't have the data to create ColDefs
     * @param gridReadyEvent Ag grid event for when ready
     */
    onGridReady = async (
        gridReadyEvent: GridReadyEvent,
        setRegistriesAndStore?: (
            registries: Registries,
            plugins: DataStackPlugin[],
            defaultState?: DefaultRegistryState
        ) => void
    ) => {
        const { datasourceConfiguration } = this.props;
        if (
            this.props.datasourceConfiguration &&
            (this.props.datasourceConfiguration.datasourceType === DatasourceType.DRA ||
                this.props.datasourceConfiguration.url)
        ) {
            if (!gridReadyEvent.api) {
                const msg = 'gridReadyEvent.api is null check ag-Grid configuration';
                logger.error(msg);
                throw new Error(msg);
            }

            if (!gridReadyEvent.columnApi) {
                const msg = 'gridReadyEvent.columnApi is null check ag-Grid configuration';
                logger.error(msg);
                throw new Error(msg);
            }
            if (!this.isUnmounted) {
                const sideBar: SideBarDef | undefined = gridReadyEvent.api.getSideBar();
                if (sideBar && sideBar.toolPanels) {
                    const columnsPanel = sideBar.toolPanels.find((x: ToolPanelDef | string) => {
                        if (typeof x === 'object' && hasOwnProperty(x, 'id')) {
                            const toolPanelDef = x as ToolPanelDef;
                            return toolPanelDef.id === 'columns';
                        }
                        return false;
                    });
                    if (columnsPanel) {
                        const columnsPanelDef = columnsPanel as ToolPanelDef;
                        if (!columnsPanelDef.toolPanelParams) {
                            columnsPanelDef.toolPanelParams = {
                                // DRA doesn't support dynamic change of aggregation so hiding the panel
                                suppressValues: true,
                            };
                        } else {
                            // DRA doesn't support dynamic change of aggregation so hiding the panel
                            columnsPanelDef.toolPanelParams.suppressValues = true;
                        }
                        gridReadyEvent.api.setSideBar(sideBar);
                    }
                }
                this.setState(
                    {
                        api: gridReadyEvent.api,
                        columnApi: gridReadyEvent.columnApi,
                        setRegistriesAndStore: setRegistriesAndStore || null,
                    },
                    async () => {
                        await this.instantiateNewGridWrapper();
                        this.callOnGridReadyProp(gridReadyEvent);
                    }
                );
            } else {
                this.callOnGridReadyProp(gridReadyEvent);
            }
        } else if (
            !datasourceConfiguration ||
            (datasourceConfiguration &&
                datasourceConfiguration.datasourceType === DatasourceType.InMemory &&
                !datasourceConfiguration.url)
        ) {
            /**
             * We check the if there is data provided, if not then we set the registriesAndStore so that
             * the toolbar can be populate with plugins. If there is data then this is handled in onFirstDataRendered
             * so that the plugins can be applied when we have data and do things like autofit etc
             */
            if (
                !this.isUnmounted &&
                (!this.props.rowData || (this.props.rowData && this.props.rowData.length === 0))
            ) {
                this.setState(
                    {
                        api: gridReadyEvent.api,
                        columnApi: gridReadyEvent.columnApi,
                        setRegistriesAndStore: setRegistriesAndStore || null,
                    },
                    async () => {
                        await this.instantiateNewGridWrapper();
                        this.callOnGridReadyProp(gridReadyEvent);
                    }
                );
            } else {
                this.callOnGridReadyProp(gridReadyEvent);
            }
        } else {
            this.callOnGridReadyProp(gridReadyEvent);
        }
    };

    callOnGridReadyProp = (gridReadyEvent: GridReadyEvent) => {
        if (typeof this.props.onGridReady === 'function') {
            this.props.onGridReady(gridReadyEvent);
        }
    };

    /**
     * We used onFirstDataRendered for InMemory sources (exc. URL) for initializing
     * the grid since we will have data to be able to create the columns
     * @param firstDataRenderedEvent
     */
    onFirstDataRendered = async (
        firstDataRenderedEvent: FirstDataRenderedEvent,
        setRegistriesAndStore?: (
            registries: Registries,
            plugins: DataStackPlugin[],
            defaultState?: DefaultRegistryState
        ) => void
    ) => {
        // tslint:disable-next-line: no-shadowed-variable
        const { datasourceConfiguration } = this.props;

        if (
            !datasourceConfiguration ||
            (datasourceConfiguration &&
                datasourceConfiguration.datasourceType === DatasourceType.InMemory &&
                !datasourceConfiguration.url)
        ) {
            if (!firstDataRenderedEvent.api) {
                const msg = 'gridReadyEvent.api is null check ag-Grid configuration';
                logger.error(msg);
                throw new Error(msg);
            }
            if (!firstDataRenderedEvent.columnApi) {
                const msg = 'gridReadyEvent.columnApi is null check ag-Grid configuration';
                logger.error(msg);
                throw new Error(msg);
            }
            /**
             * This is so that the toolbar is populated with plugins if we have data. If there is no data then
             * it is handled in onGridReady to setRegistriesAndStore
             */
            if (!this.isUnmounted && this.props.rowData && this.props.rowData.length >= 0) {
                this.setState(
                    {
                        api: firstDataRenderedEvent.api,
                        columnApi: firstDataRenderedEvent.columnApi,
                        setRegistriesAndStore: setRegistriesAndStore || null,
                    },
                    async () => {
                        this.callOnFirstDataRenderedProp(firstDataRenderedEvent);
                        await this.instantiateNewGridWrapper();
                    }
                );
            } else {
                this.callOnFirstDataRenderedProp(firstDataRenderedEvent);
            }
        } else {
            this.callOnFirstDataRenderedProp(firstDataRenderedEvent);
        }
    };

    callOnFirstDataRenderedProp = (firstDataRenderedEvent: FirstDataRenderedEvent) => {
        if (typeof this.props.onFirstDataRendered === 'function') {
            this.props.onFirstDataRendered(firstDataRenderedEvent);
        }
    };

    onDataGridSuppressKeyboardEvent = (params: SuppressKeyboardEventParams): boolean => {
        // We handle the Ctrl + A shortcut for DRA select all so that the selection gets handled on the back end
        // Consequently we need to suppress Ctrl + A in ag grid so that we can handle it ourselves

        // get users suppression event. this.props.suppressKeyboardEvent will soon be deprecated
        const userSuppressionEvent = this.props.defaultColDef?.suppressKeyboardEvent || null;

        if (
            this.props.datasourceConfiguration &&
            this.props.datasourceConfiguration.datasourceType === DatasourceType.DRA
        ) {
            const suppress = onDataGridSuppressKeyboardEventForDra(params);
            // Combine with the user suppression too
            if (userSuppressionEvent) {
                return suppress && userSuppressionEvent(params);
            }
            return suppress;
        }
        if (userSuppressionEvent) {
            return userSuppressionEvent(params);
        }
        return false;
    };
    processCellForClipboard = (paramsCell: ProcessCellForExportParams) => {
        return processCellForExport(paramsCell, null, [], this.props.processCellForClipboard);
    };
    public render() {
        const {
            id,
            datasourceConfiguration,
            gridOptions,
            defaultGridState,
            rowClassRules,
            swallowFormattingError,
            swallowFormattingErrorCellValue,
            frameworkComponents,
            components,
            columnDefs,
            disableBuiltInThemes,
            classes: overrideClasses = {},
            className,
            theme,
            loadingCellRendererParams,
            modules,
            loadingOverlayComponent,
            noRowsOverlayComponent,
            ...restProps
        } = this.props;

        if (this.state.hasError) {
            throw new Error('Error while trying to render the grid');
        }

        const isUsingDRA =
            (this.props.datasourceConfiguration &&
                this.props.datasourceConfiguration.datasourceType === DatasourceType.DRA) ||
            false;

        const chartThemes = [
            ...(this.props.chartThemes || []),
            ...((this.props.gridOptions && this.props.gridOptions.chartThemes) || []),
            ...defaultChartThemes,
        ];

        const size = this.state.size as DataGridSize;
        const themeConfig = ThemeManager.getTheme(this.state.density, size);
        const cssClasses = dataGridStyleSheet.mount(this, {
            theme,
            disableBuiltInThemes,
        });
        const rootClassNames = cx(
            ...themeConfig.themeClassNames.split(' '),
            cssClasses.root,
            overrideClasses.root,
            ThemeTypeClassName.ROOT,
            className,
            globalClassName
        );

        const customChartThemes = {
            ...this.props.customChartThemes,
            ...(this.props.gridOptions && this.props.gridOptions.customChartThemes),
            ...getDefaultCustomChartThemes(theme),
        };
        const loadingCellRendererParameters = {
            ...loadingCellRendererParams,
            theme,
        };
        return (
            <div
                className={rootClassNames}
                id={this.props.id}
                data-cy={this.props['data-cy']}
                data-gs-uitk-component="datagrid"
                data-on-grid-ready={this.state.api ? 'true' : 'false'}
                style={{
                    height: '100%',
                    position: 'relative',
                }}
                ref={this.wrapperRef}
            >
                <div
                    className={`${
                        theme.colorMode === 'dark'
                            ? ThemeTypeClassName.AG_GRID_DARK_THEME
                            : ThemeTypeClassName.AG_GRID_THEME
                    } `}
                    style={{
                        height: '100%',
                    }}
                >
                    <RegistryContext.Consumer>
                        {context => {
                            return (
                                <AgGridReact
                                    // Introduced in AG Grid v26. Not production-ready yet. See https://www.ag-grid.com/react-data-grid/reactui/
                                    // TODO: Uncomment out in upgrade to v26.2.0 UX-14973
                                    // reactUi={false}
                                    // groupDisplayType={isUsingDRA ? 'custom' : undefined}
                                    // ColumnDefs for dra are handled by passing them down the the customDRAOptions, using the ag-grid api means that the rowGrouping isn't handled correctly
                                    columnDefs={isUsingDRA ? undefined : columnDefs}
                                    groupHeaderHeight={themeConfig.headerHeight}
                                    headerHeight={themeConfig.headerHeight}
                                    floatingFiltersHeight={themeConfig.floatingFilterHeight}
                                    rowClassRules={rowClassRules}
                                    rowHeight={themeConfig.rowHeight}
                                    rowModelType={isUsingDRA ? 'viewport' : undefined}
                                    loadingCellRenderer={CustomLoadingCellRenderer}
                                    loadingCellRendererParams={loadingCellRendererParameters}
                                    pivotSuppressAutoColumn={isUsingDRA ? true : undefined}
                                    suppressAggFuncInHeader={isUsingDRA ? true : undefined}
                                    // if we use a datasource we know we are managing flat objects so we suppressFieldDotNotation
                                    suppressFieldDotNotation={
                                        this.props.datasourceConfiguration ? true : undefined
                                    }
                                    // we return cell value except for rowGroupColumnColId where we return the TreeCol value
                                    processCellForClipboard={this.processCellForClipboard}
                                    modules={modules}
                                    {...restProps}
                                    defaultColDef={this.state.defaultColDef}
                                    context={{
                                        ...this.props.gridOptions?.context,
                                        ...this.props.context,
                                        theme,
                                    }}
                                    gridOptions={
                                        this.props.gridOptions || this.state.firstAgGridOptions
                                    }
                                    customChartThemes={customChartThemes}
                                    chartThemes={chartThemes}
                                    onGridReady={event => {
                                        this.onGridReady(event, context.setRegistriesAndStore);
                                    }}
                                    onFirstDataRendered={event =>
                                        this.onFirstDataRendered(
                                            event,
                                            context.setRegistriesAndStore
                                        )
                                    }
                                    components={{
                                        draDisconnectedOverlay: DraDisconnectedOverlay,
                                        loadingOverlay: loadingOverlayComponent || LoadingAgOverlay,
                                        noRowsOverlay: noRowsOverlayComponent || NoRowsOverlay,
                                        ...frameworkComponents,
                                        ...components,
                                        ...(this.props.gridOptions?.components || []),
                                    }}
                                    loadingOverlayComponent={'loadingOverlay'}
                                    noRowsOverlayComponent={
                                        isUsingDRA ? 'draDisconnectedOverlay' : 'noRowsOverlay'
                                    }
                                    getRowHeight={this.state.size && this.getRowHeight}
                                />
                            );
                        }}
                    </RegistryContext.Consumer>
                </div>
                <div
                    ref={this.overlayContainerRef}
                    className={`gs-uitk-datagrid-overlay-container ${this.props.id}-container`}
                />
            </div>
        );
    }

    private datasourceConfigWithoutFixedDraFilter(
        datasourceConfig: DatasourceConfiguration | undefined
    ) {
        let datasourceConfigWithoutFixedDRAFilter: DatasourceConfiguration | undefined;
        if (datasourceConfig) {
            datasourceConfigWithoutFixedDRAFilter = { ...datasourceConfig };
            delete datasourceConfigWithoutFixedDRAFilter.fixedDRAFilter;
        }
        return datasourceConfigWithoutFixedDRAFilter;
    }

    private async instantiateNewGridWrapper() {
        // We should not create the wrapper multiple times for given life cycle
        if (this.gridWrapper) {
            return;
        }

        if (!this.state.api) {
            const msg = 'api is null check ag-Grid configuration';
            logger.error(msg);
            throw new Error(msg);
        }
        if (!this.state.columnApi) {
            const msg = 'columnApi is null check ag-Grid configuration';
            logger.error(msg);
            throw new Error(msg);
        }

        const {
            columnDataTypeDefinition,
            defaultGridState,
            swallowFormattingError,
            swallowFormattingErrorCellValue,
            exportCallbacks,
            theme,
        } = this.props;

        const customOptions = this.buildCustomOptions();

        const { draDatasource } = await this.createDataSource(customOptions);

        const wrapper = new AgGridWrapper(
            {
                swallowFormattingError,
                swallowFormattingErrorCellValue,
                datasourceConfiguration: this.props.datasourceConfiguration,
                id: this.props.id,
            },
            this.state.firstAgGridOptions,
            this.state.api,
            this.state.columnApi,
            columnDataTypeDefinition,
            draDatasource,
            defaultGridState || {},
            customOptions,
            exportCallbacks || {},
            this.wrapperRef.current,
            this.overlayContainerRef.current
        );
        wrapper.setTheme(theme);
        const gridConfiguration = wrapper.getReduxStore().getState().gridConfiguration;
        if (gridConfiguration) {
            const { size, density } = gridConfiguration;
            this.prevStateDensity = density;
            this.prevStateSize = size;
        }
        if (this.props.size) {
            wrapper.setSize(this.props.size);
        }
        if (this.props.density) {
            wrapper.setDensity(this.props.density);
        }

        // We provide the created registries to our component (Toolbar) that is using RegistryContext.Provider
        if (this.state.setRegistriesAndStore) {
            this.state.setRegistriesAndStore(
                {
                    actionsRegistry: wrapper.getActionsRegistry(),
                    screensRegistry: wrapper.getScreensRegistry(),
                    widgetsRegistry: wrapper.getWidgetsRegistry(),
                },
                wrapper.getPlugins(),
                defaultToolbarState
            );
        }
        const dataGridApi = new DataGridApi(wrapper);
        if (this.props.instanceCallback) {
            this.props.instanceCallback(dataGridApi);
        }

        this.gridWrapper = wrapper;
        this.gridWrapper.getReduxStore().subscribe(this.onReduxChange);
    }

    private onReduxChange = () => {
        const gridConfiguration = this.gridWrapper?.getReduxStore().getState().gridConfiguration;
        if (gridConfiguration) {
            const { size: stateSize, density: stateDensity } = gridConfiguration;
            if (
                (this.prevStateSize !== stateSize || this.prevStateDensity !== stateDensity) &&
                ((stateSize && stateSize !== this.state.size) ||
                    (stateDensity && stateDensity !== this.state.density))
            ) {
                this.setState({
                    size: stateSize,
                    density: stateDensity,
                });
                const columnDefs = this.state.api?.getColumnDefs();
                const themeConfig = ThemeManager.getTheme(stateDensity, stateSize as DataGridSize);
                this.state.api?.setHeaderHeight(themeConfig.headerHeight);
                this.state.api?.setGroupHeaderHeight(themeConfig.headerHeight);
                this.state.api?.refreshHeader();
                if (columnDefs) {
                    this.state.api?.setColumnDefs(columnDefs);
                }
                this.prevStateDensity = stateDensity;
                this.prevStateSize = stateSize;
            }
        }
    };

    private buildCustomOptions(): CustomDRAOptions {
        const customOptions: CustomDRAOptions = {};
        if (
            this.props.datasourceConfiguration &&
            this.props.datasourceConfiguration.datasourceType === DatasourceType.DRA
        ) {
            const rowSelectionVal =
                this.props.rowSelection ||
                (this.props.gridOptions && this.props.gridOptions.rowSelection);
            if (rowSelectionVal) {
                customOptions.rowSelection = rowSelectionVal;
            }
            if (this.props.columnDefs) {
                customOptions.columnDefs = this.props.columnDefs;
            }
            if (this.props.draOptions) {
                customOptions.checkboxSelection = this.props.draOptions.checkboxSelection;
                customOptions.hideLeafCheckbox = this.props.draOptions.hideLeafCheckbox;
                customOptions.showRowGroupLeafName = this.props.draOptions.showRowGroupLeafName;
                customOptions.enableRowGroupColumnSorting =
                    this.props.draOptions.enableRowGroupColumnSorting;
                customOptions.treeColInnerRenderer = this.props.draOptions.treeColInnerRenderer;
                customOptions.getRowId = this.props.draOptions.getRowId;
                customOptions.groupSelectsChildren =
                    this.props.gridOptions &&
                    this.props.gridOptions.groupSelectsChildren !== undefined
                        ? this.props.gridOptions.groupSelectsChildren
                        : this.props.groupSelectsChildren;
            }
            if (this.props.datasourceConfiguration.draFilterTransformer) {
                customOptions.draFilterTransformer =
                    this.props.datasourceConfiguration.draFilterTransformer;
            }
        }
        return customOptions;
    }

    private async createDataSource(customOptions: CustomDRAOptions) {
        if (!this.state.api) {
            const msg = 'api is null check ag-Grid configuration';
            logger.error(msg);
            throw new Error(msg);
        }
        if (!this.state.columnApi) {
            const msg = 'columnApi is null check ag-Grid configuration';
            logger.error(msg);
            throw new Error(msg);
        }
        if (this.theDatasource) {
            this.theDatasource.destroy();
        }
        let theDatasource: Datasource | null = null;
        if (
            (this.props.datasourceConfiguration && this.props.datasourceConfiguration.code) ||
            (this.props.datasourceConfiguration &&
                this.props.datasourceConfiguration.datasourceType !== DatasourceType.DRA)
        ) {
            theDatasource = DatasourceFactory.getDatasource(
                this.props.datasourceConfiguration,
                this.state.api,
                this.state.columnApi,
                customOptions
            );
            this.state.api.showLoadingOverlay();

            this.theDatasource = theDatasource;
            await theDatasource
                .connect(
                    this.props.rowData ||
                        (this.props.gridOptions ? this.props.gridOptions.rowData : null)
                )
                .catch(() => {
                    const msg = 'Cannot connect to the Datasource using the configuration';
                    logger.error(msg, this.props.datasourceConfiguration);
                    if (this.hasMounted) {
                        // only throw error if component has already mounted for ssr
                        this.setState({ hasError: true });
                    }
                });
        }
        let draDatasource: DraDatasource | null = null;
        if (
            this.props.datasourceConfiguration &&
            this.props.datasourceConfiguration.datasourceType === DatasourceType.DRA &&
            theDatasource
        ) {
            const draViewportDatasource = theDatasource as DraViewportDatasource;
            draDatasource = draViewportDatasource.getDraDatasource();
        }
        return { draDatasource };
    }
}

export const DataGridInternal = withTheme(DataGridInternalComponent);
