import {
    StyleSheet,
    CssPropertyType,
    CssClassDefinitionsObject,
} from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { TableSize, TableEmphasis, TableDensity, TableCellAlignment } from './table-props';
import { DeepReadonly } from 'ts-essentials';
import './table-theme-overrides';

// lineHeights are excluded as Table's lineHeights are determined by the `Density` and `Size` props
function getTableBodySizes(theme: Theme) {
    const { lineHeight: lineHeight1, ...smBody } = theme.typography.body03;
    const { lineHeight: lineHeight2, ...mdBody } = theme.typography.body02;
    const { lineHeight: lineHeight3, ...lgBody } = theme.typography.body01;

    return {
        sm: smBody,
        md: mdBody,
        lg: lgBody,
    };
}

function getTableHeaderSizes(theme: Theme) {
    const { lineHeight: lineHeight4, ...smHeader } = theme.typography.heading07;
    const { lineHeight: lineHeight5, ...mdHeader } = theme.typography.heading06;
    const { lineHeight: lineHeight6, ...lgHeader } = theme.typography.heading05;

    return {
        sm: { ...smHeader },
        md: { ...mdHeader },
        lg: { ...lgHeader },
    };
}

const tablePadding = {
    standard: {
        sm: '6px 8px',
        md: '6px 8px',
        lg: '12px 12px',
    },
    compact: {
        sm: '3px 8px',
        md: '2px 8px',
        lg: '6px 12px',
    },
};

const tableLineHeights = {
    standard: {
        sm: '16px',
        md: '20px',
        lg: '24px',
    },
    compact: {
        sm: '16px',
        md: '20px',
        lg: '24px',
    },
};

interface TableStyleSheetProps {
    theme: Theme;
    size: TableSize;
    emphasis: TableEmphasis;
    density: TableDensity;
    cellAlignment: TableCellAlignment;
    rowHover: boolean;
    striped: boolean;
    bordered: boolean;
    hideRowSeparators: boolean;
    showColumnSeparators: boolean;
}

interface TableStyles {
    backgroundColor: CssPropertyType['backgroundColor'];
    borderColor: CssPropertyType['borderColor'];
    borderWidth: CssPropertyType['borderWidth'];
    color: CssPropertyType['color'];
    stripedRowHoverBackgroundColor: CssPropertyType['backgroundColor'];
    hoverBackgroundColor: CssPropertyType['backgroundColor'];
    stripedBackgroundColor: CssPropertyType['backgroundColor'];
    grayBorderColor: CssPropertyType['borderColor'];
    headerColor: CssPropertyType['color'];
    fontFamily: CssPropertyType['fontFamily'];
}

export type TableCssClasses = {
    root: string;
    table: string;
};

export type TableStyledClasses = CssClassDefinitionsObject<keyof TableCssClasses>;

export interface TableStyleOverridesParams {
    props: DeepReadonly<TableStyleSheetProps>;
    createDefaultStyledClasses: () => TableStyledClasses;
}

function getTableStyles(theme: Theme, rowHover: boolean, striped: boolean): TableStyles {
    const normalRowShades = theme.getSurfaceInteractionShades('primary');
    const stripedRowShades = theme.getSurfaceInteractionShades(
        rowHover || striped ? 'secondary' : 'primary'
    );

    return {
        backgroundColor: normalRowShades.background,
        borderColor: theme.border.minimal,
        borderWidth: '1px',
        color: theme.text.primary,
        hoverBackgroundColor: normalRowShades.hover,
        stripedRowHoverBackgroundColor: stripedRowShades.hover,
        stripedBackgroundColor: stripedRowShades.background,
        grayBorderColor: theme.border.moderate,
        headerColor: theme.text.secondary,
        fontFamily: theme.typography.body01.fontFamily,
    };
}

export const tableStyleSheet = new StyleSheet('table', (props: TableStyleSheetProps) => {
    return createComponentClassDefinitions<TableStyleSheetProps, TableStyledClasses>(
        props,
        createDefaultStyledClasses,
        props.theme.styleOverrides?.table
    );
});

function createDefaultStyledClasses({
    theme,
    size,
    emphasis,
    density,
    cellAlignment,
    rowHover,
    striped,
    bordered,
    hideRowSeparators,
    showColumnSeparators,
}: TableStyleSheetProps): TableStyledClasses {
    const padding = tablePadding[density][size];
    const tableStyles = getTableStyles(theme, rowHover, striped);
    const emphasisShading = emphasis === 'bold' ? theme.surface.secondary : 'none';

    const borderedStyles = bordered
        ? {
              border: `${tableStyles.borderWidth} solid ${tableStyles.grayBorderColor}`,
              'thead, tbody tr td:first-child, tbody th': {
                  borderLeft: `${tableStyles.borderWidth} solid ${tableStyles.grayBorderColor}`,
              },
              'thead, tbody td:last-child': {
                  borderRight: `${tableStyles.borderWidth} solid ${tableStyles.grayBorderColor}`,
              },
              'tbody tr:last-child td, tbody tr:last-child th': {
                  borderBottom: `${tableStyles.borderWidth} solid ${tableStyles.grayBorderColor}`,
              },
          }
        : {};

    const hideRowSeparatorsStyles = hideRowSeparators
        ? {
              lineHeight: tableLineHeights[density][size],
              borderBottom: 'none',
          }
        : {};

    const rowHoverStyles = rowHover
        ? {
              'tbody tr:nth-of-type(even):hover': {
                  backgroundColor: tableStyles.stripedRowHoverBackgroundColor,
              },
              'tbody tr:hover': {
                  backgroundColor: tableStyles.hoverBackgroundColor,
              },
          }
        : {};

    const columnSeparatorsStyles = showColumnSeparators
        ? {
              'th:not(:last-child), td:not(:last-child)': {
                  borderRight: `${tableStyles.borderWidth} solid ${tableStyles.grayBorderColor}`,
              },
          }
        : {};

    const stripedStyles = striped
        ? {
              'tbody tr:nth-of-type(even)': {
                  backgroundColor: tableStyles.stripedBackgroundColor,
              },
          }
        : {};

    return {
        root: {},
        table: {
            backgroundColor: tableStyles.backgroundColor,
            borderCollapse: 'collapse',
            color: tableStyles.color,
            width: '100%',
            fontFamily: tableStyles.fontFamily,
            lineHeight: tableLineHeights[density][size],
            textAlign: cellAlignment,
            th: {
                backgroundColor: emphasisShading,
            },
            'th, td': {
                ...getTableBodySizes(theme)[size],
                padding,
                lineHeight: `calc(${tableLineHeights[density][size]} - ${tableStyles.borderWidth})`,
                borderBottom: `${tableStyles.borderWidth} solid ${tableStyles.borderColor}`,
                ...hideRowSeparatorsStyles,
            },
            'thead th': {
                borderBottom: `${tableStyles.borderWidth} solid ${tableStyles.grayBorderColor}`,
                ...getTableHeaderSizes(theme)[size],
                color: tableStyles.headerColor,
            },
            'tbody th': {
                borderRight: `${tableStyles.borderWidth} solid ${tableStyles.grayBorderColor}`,
                ...getTableHeaderSizes(theme)[size],
                color: tableStyles.headerColor,
            },
            ...borderedStyles,
            ...rowHoverStyles,
            ...columnSeparatorsStyles,
            ...stripedStyles,
        },
    };
}
