export const regex = {
  numberModel: /^([0-9]*)(\.[0-9]*)?([kKmMbB])?$/,
  isZero: /^0+$/,
  isZeroCurrency: /^(?=.*[0])[0]*(?:\.[0]{2})?$/,
  isZeroShares: /^(?=.*[0])[0]*(?:\.[0]{3})?$/,
  noLeadingZeros: /^0+/,
  invalidTradeAmountInputs1: /[^0-9kKmMbB.,]/g,
  invalidTradeAmountInputs2: /[^0-9.,]/g,
  alphaNumeric: /^[A-Za-z0-9\s,.]{0,}$/,
  validSavedReportName: /^[a-zA-Z0-9\s-_]+$/,
  validSavedReportDesc: /^[a-zA-Z0-9\s-_.]+$/,
  portfolioInputText: /^[A-Za-z0-9\s-+().&%$#,/]*$/,
  invalidShellAccountInput: /[^0-9a-zA-Z?!"#$&'()*+\-.:@_ ]/g
};

export const regexConstants = {
  validSaveReportName: /[^a-zA-Z0-9\s-_]/g, // used to replace invalid characters in report name
  validSaveReportDesc: /[^a-zA-Z0-9\s-_.]/g, // used to replace invalid characters in report description
};
