import { DataType, PluginBase, PluginIcon } from '@gs-ux-uitoolkit-common/datacore';
import { ModuleIdentfier } from '../module-identfier';
import { EditorKeyDownEventArgs, GridWrapper } from '../../grid-wrappers/grid-wrapper';
import { DataGridState, KeyboardShortcutState } from '../../redux/datagrid-state';
import { Categories, Plugins } from '../plugin-enum';
import { KeyboardShortcutType } from './keyboard-shortcut';
import { shortcutHelper } from './shortcut-helper';
import { KeyboardShortcutPluginModal } from './view/keyboard-shortcut-plugin-modal';
import { OpenGridConfiguration } from '../../redux/actions/grid-configuration-action';
import { DataGridToolbarItem } from '../../toolbar-state';

// this icon should really be type='outlined' but the API does not support it yet.
// Make the update when https://jira.site.gs.com/browse/UX-10889 is implemented.
const mainIcon: PluginIcon = { name: 'file-document-edit', type: 'filled' };
const componentId = 'KeyboardShortcutsModal';

/**
 * The Edit Shortcuts plugin. Allows the developers and end-users to create Edit Shortcuts at runtime and design time
 */
export class KeyboardShortcutPlugin extends PluginBase<
    GridWrapper,
    DataGridState,
    KeyboardShortcutState
> {
    protected static requiredModules: ModuleIdentfier[] = [];
    constructor(wrapper: GridWrapper) {
        super(
            Plugins.KeyboardShortcutPlugin,
            Categories.Edit,
            mainIcon,
            wrapper,
            state => state.keyboardShortcut
        );
        this.actions = [
            {
                action: OpenGridConfiguration(Plugins.KeyboardShortcutPlugin),
                componentId: 'KeyboardShortcutsShortcut' as DataGridToolbarItem,
                icon: mainIcon,
                label: 'Keyboard Shortcuts',
                store: wrapper.getReduxStore(),
                context: wrapper.getReduxStoreContext(),
            },
        ];
        this.screens = [
            {
                componentId,
                icon: mainIcon,
                label: 'Keyboard Shortcuts',
                screen: KeyboardShortcutPluginModal,
                store: wrapper.getReduxStore(),
                context: wrapper.getReduxStoreContext(),
            },
        ];
    }
    protected stateChangedOrStart(): void {
        this.wrapper.editorKeyDown.unsubscribe(this.handleEditorKeyDownFunc);
        this.wrapper.editorKeyDown.subscribe(this.handleEditorKeyDownFunc);
    }

    protected internalStop(): void {
        this.wrapper.editorKeyDown.unsubscribe(this.handleEditorKeyDownFunc);
    }

    private handleEditorKeyDownFunc = (event: EditorKeyDownEventArgs) =>
        this.handleEditorKeyDown(event);
    private handleEditorKeyDown(event: EditorKeyDownEventArgs): void {
        const column = this.wrapper
            .getReduxStore()
            .getState()
            .grid.columnList.find(col => col.columnId === event.columnId);
        if (column && column.dataType === DataType.Number) {
            const keyboardShortcut = this.getPluginState()?.configItemList.find(
                es =>
                    es.key === event.keyboardEvent.key.toUpperCase() &&
                    es.type === KeyboardShortcutType.Numeric
            );
            if (keyboardShortcut) {
                const computedValue = shortcutHelper.computeKeyboardShortcut(
                    event.currentEditorValue,
                    keyboardShortcut
                );
                event.keyboardEvent.preventDefault();
                if (event.rowIndex != undefined) {
                    this.wrapper.setEditorValue(
                        event.rowIndex,
                        event.columnId,
                        String(computedValue)
                    );
                }
            }
        }
    }
}
