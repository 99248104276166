import React, { Component } from 'react';

import PropTypes from 'prop-types';
import cn from 'classnames';
import get from 'lodash/get';
import extend from 'lodash/extend';
import isEqual from 'lodash/isEqual';
import { DataGrid, GSNumberFloatingFilter, GSTextFloatingFilter } from '@gs-ux-uitoolkit-react/datagrid';
import { AllModules } from '@gs-ux-uitoolkit-react/datagrid-modules';
import CustomCellRenderer from '../Grid/CustomCellRenderer';
import constants, { cssClassConst } from '../Grid/constants';
import { defaultContainerStyle } from './utils';
import columnTypes from '../Grid/config/columnTypesConfig';
import { config, defaultColDef, toolKitConfig } from './config';
import { CustomLoadingOverlay } from './overlays/CustomLoadingOverlay';
import { CustomNoRowsMsgOverlay } from './overlays/CustomNoRowsMsgOverlay';

class Grid extends Component {
  constructor(props) {
    super(props);

    this.gridWrapperId = `grid-wrapper-${get(this.props, 'gridId', 'default')}`;
    this.onGridReady = this.onGridReady.bind(this);
    this.changeView = this.changeView.bind(this);
    this.onColumnVisible = this.onColumnVisible.bind(this);
    this.toggleGroupExpandCollapse = this.toggleGroupExpandCollapse.bind(this);
    this.onDisplayedColumnsChanged = this.onDisplayedColumnsChanged.bind(this);
    this.toggleFilterVisibility = this.toggleFilterVisibility.bind(this);
    this.onSortChanged = this.onSortChanged.bind(this);
    this.onGridSizeChanged = this.onGridSizeChanged.bind(this);
    this.onColumnFilterFocus = this.onColumnFilterFocus.bind(this);
    this.onColumnMoved = this.onColumnMoved.bind(this);
    this.onDragStarted = this.onDragStarted.bind(this);
    this.onDragStopped = this.onDragStopped.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this);
    this.onRowClicked = this.onRowClicked.bind(this);
    this.setFilterModel = this.setFilterModel.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.onFloatingFilterChanged = this.onFloatingFilterChanged.bind(this);
    this.getFilterBy = this.getFilterBy.bind(this);
    this.sizeColumnsToFit = this.sizeColumnsToFit.bind(this);
    this.toggleOverlay = this.toggleOverlay.bind(this);
    this.onRowGroupOpened = this.onRowGroupOpened.bind(this);
    this.onGroupRowAggNodes = this.onGroupRowAggNodes.bind(this);
    this.setOverlayStyle = this.setOverlayStyle.bind(this);
    this.onFirstDataRendered = this.onFirstDataRendered(this);
    this.onClientSideFilterChanged = this.onClientSideFilterChanged.bind(this);

    this.state = {
      addNoPinCls: false,
    };

    this.filterModel = {};
    this.eventStack = [];
    this.previousColumnState = [];
    this.columnState = '';
    this.doSizeColumnsToFit = false;
    this.updateColumnList = false;

    this.events = {
      onGridReady: this.onGridReady,
      onColumnVisible: this.onColumnVisible,
      onDisplayedColumnsChanged: this.onDisplayedColumnsChanged,
      onGridSizeChanged: this.onGridSizeChanged,
      onSortChanged: this.onSortChanged,
      onColumnFilterFocus: this.onColumnFilterFocus,
      onDragStopped: this.onDragStopped,
      onColumnMoved: this.onColumnMoved,
      onDragStarted: this.onDragStarted,
      onCellClicked: this.onCellClicked,
      onRowClicked: this.onRowClicked,
      onRowGroupOpened: this.onRowGroupOpened,
      onFirstDataRendered: this.onFirstDataRendered,
      onFilterChanged: this.onClientSideFilterChanged
    };

    this.frameworkComponents = {
      gsTextFloatingFilter: GSTextFloatingFilter,
      gsNumberFloatingFilter: GSNumberFloatingFilter,
    };

    this.methods = {
      setFilterModel: this.setFilterModel,
      removeFilter: this.removeFilter,
      onFloatingFilterChanged: this.onFloatingFilterChanged,
      getFilterBy: this.getFilterBy,
      changeView: this.changeView,
      onColumnVisible: this.onColumnVisible,
      toggleGroupExpandCollapse: this.toggleGroupExpandCollapse,
    };

    const { events, excelStyles, methods, frameworkComponents, components } = this.props;

    // move this to grid
    config.excelStyles = excelStyles;
    config.noRowsOverlayComponent = 'customNoRowsOverlay';

    // end move
    const gsToolKitConfig = this.props.gsToolKit ? toolKitConfig : {};
    this.configOptions = extend({}, config, this.props.config || {}, gsToolKitConfig, {
      getGroupRowAgg: this.onGroupRowAggNodes
    });
    if (this.props.config && this.props.config.aggFuncs) {
      delete this.configOptions.getGroupRowAgg;
    }
    this.eventsOptions = extend(this.events, events);
    this.methods = { ...this.methods, ...methods };
    this.components = { ...components, ...CustomCellRenderer };
    this.frameworkComponents = extend({}, this.frameworkComponents, frameworkComponents || {});
    this.columnTypesOption = extend(columnTypes, this.props.columnTypes || {});
    this.customTheme = this.configOptions.customTheme || constants.defaultCustomTheme;
    this.agGridTheme = this.configOptions.agGridTheme || constants.defaultCustomTheme;

    defaultColDef.floatingFilterComponentParams.onFilterChanged = this.methods.onFloatingFilterChanged;
    defaultColDef.floatingFilterComponentParams.getFilterBy = this.methods.getFilterBy;
    defaultColDef.floatingFilterComponentParams.onColumnFilterFocus = this.onColumnFilterFocus;

    if (frameworkComponents) {
      const [floatingFilterComponent] = Object.keys(frameworkComponents);
      defaultColDef.floatingFilterComponent = floatingFilterComponent;
    }
  }

  /* TODO: this is not being used, but is required especially for server side filter */
  /* eslint-disable react/no-unused-class-component-methods */
  onFilterChanged() {
    const { config: { enableServerSideFilter } = {}, filterBy = [] } = this.props;
    if (enableServerSideFilter === false && filterBy.length && this.api) {
      if (this.api.getDisplayedRowCount()) {
        this.setState({ addNoPinCls: false });
      } else {
        this.setState({ addNoPinCls: true });
      }
    }
    this.props.onFilterApplied(this.filterModel, this.api.getDisplayedRowCount());
  }

  onGroupRowAggNodes(params) {
    if (this.props.config.groupRowAggNodes) {
      return this.props.config.groupRowAggNodes(params.nodes, this.props.aggregatedData, params.columnApi);
    }
  }

  onFirstDataRendered(params) {
    this.props.onFirstDataRendered && this.props.onFirstDataRendered(params);
  }

  toggleOverlay(isLoading, noRow) {
    // TODO :: Update, Even though grid method called, grid is not showing loader. so setTimeout is given to execute after Javascript stack complete
    setTimeout(() => {
      if (isLoading) {
        this.gridApi && this.gridApi.showLoadingOverlay();
      } else if (noRow) {
        this.gridApi && this.gridApi.showNoRowsOverlay();
      } else {
        this.gridApi && this.gridApi.hideOverlay && this.gridApi.hideOverlay();
      }
    }, 0);
  }

  onDragStarted(params) {
    this.columnState = params.columnApi.getColumnState();
    if (!isEqual(this.columnState, this.previousColumnState)) {
      this.previousColumnState = this.columnState.map(column => column.colId);
    }
  }

  onDragStopped(params) {
    const { onColumnMoved, onColumnVisible, onColumnWidthChanged } = this.props;
    const updatedColumnState = params.columnApi.getColumnState();
    const columns = [];
    this.columnState.forEach(columnState => {
      const updatedColState = updatedColumnState.find(column => column.colId === columnState.colId);
      if (updatedColState && updatedColState.width !== columnState.width) {
        columns.push(updatedColState);
      }
    });
    if (columns.length) {
      onColumnWidthChanged && onColumnWidthChanged(columns);
    }
    const eventStack = this.eventStack[this.eventStack.length - 1];
    if (eventStack) {
      const currentVisibleColumn = [];
      params.columnApi.getAllDisplayedColumns().forEach(column => {
        currentVisibleColumn.push(column.colId);
      });
      const columnMoved = () => {
        if (!isEqual(currentVisibleColumn, this.previousColumnState)) {
          const columns = eventStack.value.columnApi.getAllDisplayedColumns();
          onColumnMoved && onColumnMoved(columns, eventStack.value);
        }
      };
      const columnVisible = () => {
        const { visible, colId } = eventStack.value.column;
        onColumnVisible && onColumnVisible(colId, visible);
        if (!visible && this.filterModel[colId]) {
          this.removeFilter(colId);
          this.props.onFilterChanged(extend({}, this.filterModel));
        }
      };
      switch (eventStack.eventName) {
        case 'columnMoved':
          columnMoved();
          break;
        case 'columnVisible':
          if (eventStack.value && !eventStack.value.column.visible) {
            columnVisible();
          } else {
            columnMoved();
          }
          break;
        default:
      }

      this.eventStack = [];
      this.previousColumnState = currentVisibleColumn.slice();
    }
  }

  onColumnMoved(params) {
    this.eventStack.push({
      eventName: params.type,
      value: params,
    });
  }

  onCellClicked(params) {
    const { onCellClicked } = this.props;
    onCellClicked && onCellClicked(params);
  }

  // TODO :: Once render method props cal done in state keep condition to make it as core feature
  onRowClicked(params) {
    const { onCheckboxSelected, onRowClicked } = this.props;
    const isSelected = !params.node.isSelected();
    if (params.event.target.className.includes(cssClassConst.checkboxRenderer)) {
      params.node.setSelected(isSelected);
      onCheckboxSelected && onCheckboxSelected(params, isSelected);
    }
    onRowClicked && onRowClicked(params);
  }

  onGridReady(params) {
    const { filterVisibility, onGridReady } = this.props;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.toggleFilterVisibility(filterVisibility, true);
    this.gridApi.sizeColumnsToFit();
    this.gridColumnApi.getAllDisplayedColumns().forEach(column => {
      this.previousColumnState.push(column.colId);
    });

    onGridReady && onGridReady(params);
    const elem = document.querySelector('.grid-container');
    elem && elem.classList.add('unselectable');
  }

  onDisplayedColumnsChanged(params) {
    if (this.doSizeColumnsToFit && this.gridApi) {
      this.gridApi.sizeColumnsToFit();
      this.doSizeColumnsToFit = false;
    }
    const displayedColumns = [];
    params.columnApi.getAllDisplayedColumns().forEach(column => {
      displayedColumns.push(column.colId);
    });

    if (this.updateColumnList) {
      this.previousColumnState = displayedColumns;
      this.updateColumnList = false;
    }
    this.props.onDisplayedColumnChanged && this.props.onDisplayedColumnChanged(displayedColumns);
  }

  onGridSizeChanged(params) {
    params.api.sizeColumnsToFit();
  }

  onSortChanged(params) {
    const sortedModal = params.columnApi.getColumnState().filter(col => col.sort);
    const { onSortChanged } = this.props;
    if (!sortedModal.length) {
      onSortChanged && onSortChanged();
    } else {
      const { colId, sort } = sortedModal[0];
      const { colDef } = params.columnApi.getColumns().find(col => col.colId === colId) || {};
      onSortChanged && onSortChanged(colId, colDef.headerName, sort);
    }
    params.api.sizeColumnsToFit();
  }

  setOverlayStyle() {
    // todo: behavior in edge browser is not correct. Need to check
    const overlay = document.querySelector('.ag-overlay');
    if (overlay) {
      if (this.props.filterVisibility === 'visible') {
        overlay.style.height = 'calc(100% - 64px)';
        overlay.style.top = '106px';
      } else {
        overlay.style.height = 'calc(100% - 64px)';
        overlay.style.top = '68px';
      }
    }
  }

  componentDidMount() {
    this.setOverlayStyle();
  }

  componentDidUpdate() {
    this.setOverlayStyle();
  }

  onColumnVisible(params) {
    this.eventStack.push({
      eventName: params.type,
      value: params,
    });
  }

  /**
   * when a row group is opened/closed, callback received the selected node
   * which is propagated to the parent component's
   */
  onRowGroupOpened({ node }) {
    const { onRowGroupOpened } = this.props;
    onRowGroupOpened && onRowGroupOpened(node);
  }

  onColumnFilterFocus(column, event) {
    const { onColumnFilterFocus } = this.props;
    onColumnFilterFocus && onColumnFilterFocus(column, event);
  }

  removeFilter(colId) {
    delete this.filterModel[colId];
  }

  sizeColumnsToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }

  onFloatingFilterChanged(colId, filter, isClear) {
    // const filterModel = this.gridApi.getFilterModel();
    if (isClear || filter === null) {
      this.removeFilter(colId);
    } else {
      this.filterModel[colId] = filter;
    }
    const newObject = extend({}, this.filterModel);
    if (!isEqual(this.filterModel, this.props.filterModel) || isClear) {
      this.props.onFilterChanged(newObject);
    }
  }

  onClientSideFilterChanged(event) {
    const { api, columns } = event;
    const [{ colId } = {}] = columns || [];
    const filterInstance = api.getFilterInstance(colId);
    if (filterInstance) {
      const filterModelForColumn = api.getFilterInstance(colId).appliedModel;
      this.onFloatingFilterChanged(colId, filterModelForColumn, false);
    }
  }

  setFilterModel(filterModel) {
    if (this.gridApi) {
      this.filterModel = extend({}, filterModel);
      this.gridApi.setFilterModel(filterModel);
    }
  }

  clearFilters() {
    const { filterModel } = this.props;
    if (this.gridApi) {
      this.setFilterModel(null);
      if (filterModel && Object.keys(filterModel).length) {
        // this.props.onFilterChanged({});
      }
    }
  }

  toggleGroupExpandCollapse(state) {
    if (this.gridApi) {
      this.gridApi.forEachNode(node => {
        node.expanded = !state;
      });
      this.gridApi.onGroupExpandedOrCollapsed();
    }
  }

  toggleFilterVisibility(visibility = 'hidden', notClear) {
    if (this.gridApi) {
      // todo: ensure we handle this better either with constants or handling at component level
      const { floatingFilterRowHeight = 48 } = this.props;
      visibility === 'hidden' && !notClear && this.clearFilters();
      this.gridApi.setFloatingFiltersHeight(visibility === 'visible' ? floatingFilterRowHeight : 0);
      // this.toggleOverlayHeight(visibility);
      // this.setFocusOnFilter(this.gridApi);
    }
  }

  changeView(columns) {
    this.updateColumnList = true;
    this.gridColumnApi && this.gridColumnApi.setColumnState(columns);
  }

  getFilterBy() {
    return this.props.filterBy;
  }

  render() {
    const {
      rowData,
      gsToolKit,
      footerInfo,
      domLayout = 'normal',
      config: { context = {} }
    } = this.props;
    const noPin = this.state.addNoPinCls || !rowData.length;
    const additionalNoRowsOverlayCompParams = this.props.additionalNoRowsOverlayCompParams || {};
    const customContextProps = { gsToolKit, ...context };
    return (
      <div
        id={this.gridWrapperId}
        className={cn(this.customTheme, 'grid-container', {
          'gs-toolkit-style': gsToolKit,
          'footer-info': footerInfo
        })}>
        <div
          style={this.props.gridContainerStyle ? this.props.gridContainerStyle : defaultContainerStyle}
          className={cn(this.agGridTheme, { 'no-pin': noPin })}>
          <DataGrid
            id='data-grid'
            modules={AllModules} // todo: check if we need all these modules
            aggFuncs={() => { }}
            domLayout={domLayout}
            {...this.eventsOptions}
            {...this.configOptions}
            groupDisplayType='custom'
            columnTypes={this.columnTypesOption}
            columnDefs={this.props.columnDefs}
            defaultColDef={defaultColDef}
            context={customContextProps}
            components={{ ...this.components, ...this.frameworkComponents }}
            rowData={rowData}
            noRowsOverlayComponent={CustomNoRowsMsgOverlay}
            loadingOverlayComponent={CustomLoadingOverlay}
            noRowsOverlayComponentParams={{
              noRowMsg: this.props.noRowMsg,
              ...additionalNoRowsOverlayCompParams,
            }}
            getRowHeight={this.configOptions.getRowHeight || undefined}
          />
        </div>
        {
          footerInfo && (
            <div className="total-records">{footerInfo}</div>
          )
        }
      </div >
    );
  }
}

Grid.propTypes = {
  gridId: PropTypes.string,
  config: PropTypes.object,
  events: PropTypes.object,
  methods: PropTypes.object,
  onCheckboxSelected: PropTypes.func,
  frameworkComponents: PropTypes.object,
  aggregatedData: PropTypes.array,
  gridContainerStyle: PropTypes.string,
  columnDefs: PropTypes.array.isRequired,
  columnDefsForExport: PropTypes.array,
  onColumnVisible: PropTypes.func,
  components: PropTypes.object,
  rowData: PropTypes.array.isRequired,
  columnTypes: PropTypes.object,
  floatingFilterRowHeight: PropTypes.number,
  filterVisibility: PropTypes.string,
  onSortChanged: PropTypes.func,
  excelStyles: PropTypes.array,
  onColumnFilterFocus: PropTypes.func,
  onColumnMoved: PropTypes.func,
  onFilterChanged: PropTypes.func,
  filterBy: PropTypes.array,
  noRowMsg: PropTypes.string,
  additionalNoRowsOverlayCompParams: PropTypes.func,
  labels: PropTypes.object,
  onDisplayedColumnChanged: PropTypes.func,
  filterModel: PropTypes.object,
  onCellClicked: PropTypes.func,
  onColumnWidthChanged: PropTypes.func,
  onFirstDataRendered: PropTypes.func,
  selectedRows: PropTypes.array,
  selectedRowsComparator: PropTypes.func,
  onRowClicked: PropTypes.func,
  onGridReady: PropTypes.func,
  onRowGroupOpened: PropTypes.func,
  onFilterApplied: PropTypes.func,
  gsToolKit: PropTypes.bool,
  toolKitConfig: PropTypes.object,
  footerInfo: PropTypes.any,
  domLayout: PropTypes.string
};

export default Grid;
