import createEmotion, { Emotion } from '@emotion/css/create-instance';
import { getGlobalThis } from '@gs-ux-uitoolkit-common/shared';
import { classNamePrefix } from '../class-name-prefix';

const globalObj = getGlobalThis();

// the key to store the global Emotion instance under the global object
const globalKey = '__gs_uitk_emotion_instance';

/**
 * We'll use the same Emotion instance for all style sheets so that Emotion's
 * cx() function works correctly between StyleSheet instances
 * -----------------------------------------------------------------------------
 * UPDATE 2020-01-28: There is a bug in Emotion (still exists as of v11.1.3)
 * where creating a second Emotion instance with the same key can erase the
 * styles of the first Emotion instance. This is a problem when using Emotion
 * with micro-frontends.
 *
 * Issue reported on GitHub: https://github.com/emotion-js/emotion/issues/2210
 * As well as a PR (yet unmerged): https://github.com/emotion-js/emotion/pull/2222
 *
 * As a workaround, we're going to make sure we have exactly one Emotion
 * instance at a global level. We may be able to remove this when the above PR
 * is merged / issue is solved.
 *
 * The upside of this global approach is that there is only ever one Emotion
 * instance which makes sure we're never duplicating styles in the DOM.
 *
 * The downside of this global approach is multiple versions of UI Toolkit
 * existing on the page may rely on different versions of Emotion, which could
 * cause conflicts and unpredictable problems.
 */
export function getEmotionInstance(): Emotion {
    let instance = globalObj[globalKey];
    if (!instance) {
        instance = globalObj[globalKey] = createEmotionInstance();
    }
    return instance;
}

/**
 * Creates a new Emotion instance.
 *
 * Most styling is globally shared with the single Emotion instance returned by
 * {@link #getEmotionInstance} (which is also a shared cache of styles), but
 * individual Emotion instances may need to be constructed to support
 * server-side rendering on a per-request level in Next.js's "App Router".
 *
 * This function should only be used internally by the UI Toolkit.
 */
export function createEmotionInstance({
    container,
    nonce: userNonce,
}: CreateEmotionInstanceArgs = {}) {
    // First look for a <meta> element on the page that might deliver us a CSP
    // nonce to use for the <style> tag that Emotion will generate. This is
    // needed to whitelist our <style> tags so that they are not blocked by the
    // CSP
    const metaEl =
        typeof document !== 'undefined'
            ? document.querySelector('meta[property="csp-nonce"]')
            : null;
    const nonce = userNonce || metaEl?.getAttribute('content') || undefined;

    const emotion = createEmotion({
        key: classNamePrefix,
        container,

        // In order to whitelist our <style> tag for a content security policy
        // (CSP) which restricts <style> tags by default, a nonce is needed for
        // the <style> tag we'll generate
        nonce,

        // Prepend styles into the <head> rather than append them. This allows
        // users' own CSS classes from .css files to override our components
        // via higher source order when they use the 'className' and 'classes' props
        prepend: true,

        // enable fast mode (i.e. use CSSStyleSheet.insertRule() API vs. adding
        // new <style> tags for each rule)
        speedy: true,
    });

    return emotion;
}

export interface CreateEmotionInstanceArgs {
    /**
     * The HTMLElement to render the generated `<style>` tag into.
     */
    container?: HTMLElement | undefined;

    /**
     * A nonce ("number used once") to allow-list the generated `<style>` tag
     * for use with a CSP policy.
     *
     * Because styles are dynamically inserted, a checksum (SRI) cannot be used
     * to secure the `<style>` tag's content, and hence a `nonce` must be used
     * instead.
     *
     * See: https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/nonce
     * for details on implementation.
     *
     * Also see: https://ui.web.gs.com/docs/react/styling-components
     *
     * Defaults to reading the value from a `<meta property="csp-nonce" value="...">`
     * element in the HTML document, if there is one.
     */
    nonce?: string;
}
