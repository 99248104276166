import { PureComponent, RefObject, createRef } from 'react';
import PropTypes from 'prop-types';

import { EmotionInstanceContext } from '@gs-ux-uitoolkit-react/style';
import { MenuBlurEvent } from '@gs-ux-uitoolkit-react/menu';
import {
    dropdownStyleSheet,
    defaultDropdownProps,
    getDropdownRootClassNames,
    dropdownSizes,
} from '@gs-ux-uitoolkit-common/dropdown';

import { DropdownProps } from './dropdown-props';
import { DropdownContext } from './dropdown-context';
import { Theme, ThemeConsumer } from '@gs-ux-uitoolkit-react/theme';

// @ts-expect-error: needed because api-extractor can not parse dynamic imports in .d.ts files
import { DropdownSize } from '@gs-ux-uitoolkit-common/dropdown';
import { componentAnalytics } from './analytics-tracking';

/**
 * Dropdowns group a collection of actions which the user can choose from.
 */
export class Dropdown extends PureComponent<DropdownProps, DropdownState> {
    private menuTriggerRef: RefObject<HTMLButtonElement>;
    private menuTargetRef: RefObject<HTMLSpanElement>;

    constructor(props: DropdownProps) {
        super(props);
        this.menuTriggerRef = createRef<HTMLButtonElement>();
        this.menuTargetRef = createRef<HTMLSpanElement>();

        this.state = {
            menuVisible: false,
        };
    }

    componentDidMount() {
        // update the context once the refs passed to children have resolved
        this.updateContext();
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'dropdown' });
    }

    componentWillUnmount() {
        dropdownStyleSheet.unmount(this);
    }

    render() {
        const { className, classes, onMenuToggle, menuVisible, children, ...otherProps } =
            this.props;

        return (
            <EmotionInstanceContext.Consumer>
                {emotionInstance => (
                    <ThemeConsumer>
                        {(theme: Theme) => {
                            const cssClasses = dropdownStyleSheet.mount(
                                this,
                                { theme },
                                emotionInstance
                            );
                            const rootClasses = getDropdownRootClassNames({
                                cssClasses,
                                overrideClasses: classes,
                                className,
                            });

                            return (
                                <DropdownContext.Provider value={this.getContext()}>
                                    <span
                                        ref={this.menuTargetRef}
                                        {...otherProps}
                                        data-gs-uitk-component="dropdown"
                                        className={rootClasses}
                                    >
                                        {children}
                                    </span>
                                </DropdownContext.Provider>
                            );
                        }}
                    </ThemeConsumer>
                )}
            </EmotionInstanceContext.Consumer>
        );
    }

    toggleMenu = () => {
        this.setState(
            prevState => {
                return {
                    menuVisible: !prevState.menuVisible,
                };
            },
            () => {
                this.props.onMenuToggle &&
                    this.props.onMenuToggle({ visible: this.state.menuVisible });
            }
        );
    };

    hideMenu = () => {
        const nextVisible = false;

        if (!this.isControlled()) {
            this.setState({
                menuVisible: nextVisible,
            });
        }

        this.props.onMenuToggle && this.props.onMenuToggle({ visible: nextVisible });
    };

    updateContext(): void {
        this.forceUpdate();
    }

    getContext(): DropdownContext {
        const { menuTargetRef, menuTriggerRef } = this;
        const { size = defaultDropdownProps.size, menuVisible: menuVisibleProp } = this.props;
        const menuVisibleState = this.state && this.state.menuVisible;
        const finalVisible = menuVisibleProp != null ? menuVisibleProp : menuVisibleState;
        const menuTargetEl = menuTargetRef && menuTargetRef.current;
        const menuTriggerEl = menuTriggerRef && menuTriggerRef.current;

        return {
            size: size,
            menuVisible: finalVisible,
            menuTargetEl: menuTargetEl || undefined,
            menuTriggerEl: menuTriggerEl || undefined,
            toggleMenu: this.toggleMenu.bind(this),
            menuOnBlur: this.menuOnBlur.bind(this),
            menuTriggerRef: menuTriggerRef,
            updateContext: this.updateContext.bind(this),
        };
    }

    menuOnBlur(event: MenuBlurEvent) {
        const { menuTriggerEl: menuTrigger } = this.getContext();

        if (menuTrigger != event.nextActiveElement) {
            this.hideMenu();
        }
    }

    isControlled(): boolean {
        return this.props.menuVisible != null;
    }

    static propTypes = {
        menuVisible: PropTypes.bool,
        size: PropTypes.oneOf(dropdownSizes),
        onMenuToggle: PropTypes.func,
        children: PropTypes.node,
    };
}

interface DropdownState {
    menuVisible: boolean;
}
