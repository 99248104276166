import React from 'react';
import PropTypes from 'prop-types';
import TreeNode from './TreeNode';

function convert(array) {
  const map = {};
  for (let i = 0; i < array.length; i++) {
    const obj = array[i];
    obj.items = [];
    map[obj.nodeId] = obj;

    const parent = obj.parentId || '-';
    if (!map[parent]) {
      map[parent] = {
        items: []
      };
    }
    map[parent].items.push(obj);
  }
  return map['-'].items;
}

const Tree = ({items, props, indentationPadding}) => {
  const treeNodes = items.length > 0 ? convert(items) : [];
  const getRootNodes = () => {
    return treeNodes.map(node => node);
  };
  const rootNodes = getRootNodes();
  const hasAtleastOneParentNode = rootNodes.some(({items}) => items.length > 0);

  return (
    <div style={{'paddingRight': '20px'}}>
      {
        rootNodes.map((node, index) => {
          return (
            <TreeNode
              {...props}
              node={{...node}}
              hasAtleastOneParentNode={hasAtleastOneParentNode}
              indentationPadding={indentationPadding}
              onToggle={() => {}}
              key={index}
            />);
        })
      }
    </div>
  );
};

Tree.propTypes = {
  items: PropTypes.array.isRequired,
  props: PropTypes.object,
  indentationPadding: PropTypes.number
};
export default Tree;
