import { StyleSheet, CssClassDefinitionsObject } from '@gs-ux-uitoolkit-common/style';
import { Theme, createComponentClassDefinitions } from '@gs-ux-uitoolkit-common/theme';
import { FormSize, FormStatus } from './form-types';
import { getFormTextStyles, formTextSizeVariants } from './form-text-style-sheet';
import { DeepReadonly } from 'ts-essentials';
import './form-feedback-theme-overrides';

export interface FormFeedbackStyleSheetProps {
    theme: Theme;
    size: FormSize;
    status: FormStatus;
    disabled: boolean;
}

export interface FormFeedbackCssClasses {
    root: string;
}

export type FormFeedbackStyledClasses = CssClassDefinitionsObject<keyof FormFeedbackCssClasses>;

export interface FormFeedbackStyleOverridesParams {
    props: DeepReadonly<FormFeedbackStyleSheetProps>;
    createDefaultStyledClasses: () => FormFeedbackStyledClasses;
}

export const formFeedbackStyleSheet = new StyleSheet(
    'form-feedback',
    (props: FormFeedbackStyleSheetProps) => {
        return createComponentClassDefinitions<
            FormFeedbackStyleSheetProps,
            FormFeedbackStyledClasses
        >(props, createDefaultStyledClasses, props.theme.styleOverrides?.formFeedback);
    }
);

function createDefaultStyledClasses({
    theme,
    size,
    status,
    disabled,
}: FormFeedbackStyleSheetProps): FormFeedbackStyledClasses {
    let statusColor = theme.status[status].bold;
    // Inputs & form feedback use the 'warningAlt' treatment as the default warning color,
    // yellow, is very light for borders and fails AA contrast of 3:1 for UI elements
    if (status === 'warning') {
        statusColor = theme.getColorInteractionShades('warningAlt', 'minimal').text;
    }

    return {
        root: {
            ...getFormTextStyles({ theme, disabled }),
            fontSize: formTextSizeVariants[size].fontSize,
            color: status ? statusColor : theme.text.secondary,
        },
    };
}
