import { Component, Fragment } from 'react';
import { ColumnHintDateFormat } from './formatScreens/column-hint-date-format';
import { ColumnHintStringFormat } from './formatScreens/column-hint-string-format';
import { ColumnHintNumberFormat } from './formatScreens/column-hint-number-format';
import { ColumnHintAllFormat } from './formatScreens/column-hint-all-format';
import { GridColumn } from '../../../../grid-wrappers/grid-wrapper';
import {
    DataType,
    ColumnHintDefaults,
    ColumnHint,
    HintList,
} from '@gs-ux-uitoolkit-common/datacore';
import { ColumnHintFormatTypes } from '../../column-hint-helper';

export interface ColumnHintFormatManagerProps {
    /**
     * The type of the format to add
     */
    selectedTypeToFormat: ColumnHintFormatTypes | null;
    /**
     * id of the specific column to format
     */
    specificColumnToFormat: string | null;
    /**
     * Columns in the grid
     */
    columns: GridColumn[];
    /**
     * Default hints
     */
    defaultHints: ColumnHintDefaults;
    /**
     * Specific column hint for the specific column to format
     */
    specificColumnHints: ColumnHint | null;
    /**
     * Called when the hint changes
     */
    onChangeHint: (columnHint: HintList) => void;
}

export interface FormatControlProps {
    columnHint: HintList;
    onChangeHint: (columnHint: HintList) => void;
}

export class ColumnHintFormatManager extends Component<ColumnHintFormatManagerProps> {
    public render() {
        return <Fragment>{this.getFormatUI()}</Fragment>;
    }

    private getFormatUI() {
        if (
            this.props.selectedTypeToFormat === ColumnHintFormatTypes.SpecificColumn &&
            this.props.specificColumnToFormat
        ) {
            const foundColumn = this.props.columns.find(
                column => column.columnId === this.props.specificColumnToFormat
            );

            if (foundColumn) {
                return this.getTypeFormatUI(foundColumn.dataType, foundColumn);
            }
            throw new Error('Unable to find the column to get the UI for');
        } else if (this.props.selectedTypeToFormat) {
            return this.getTypeFormatUI(this.props.selectedTypeToFormat);
        }
        throw new Error('Unable to get the formatting UI');
    }

    private getTypeFormatUI(
        formatType: ColumnHintFormatTypes | DataType,
        specificColumn?: GridColumn
    ) {
        let hint: HintList = {};
        if (specificColumn && this.props.specificColumnHints) {
            hint = this.props.specificColumnHints.hints;
        } else {
            // We haven't been given a specific column so we know we're dealing with defaults
            if (
                formatType === ColumnHintFormatTypes.StringColumns &&
                this.props.defaultHints.stringDefaults
            ) {
                hint = this.props.defaultHints.stringDefaults;
            } else if (
                formatType === ColumnHintFormatTypes.NumericColumns &&
                this.props.defaultHints.numberDefaults
            ) {
                hint = this.props.defaultHints.numberDefaults;
            } else if (
                formatType === ColumnHintFormatTypes.DateColumns &&
                this.props.defaultHints.dateDefaults
            ) {
                hint = this.props.defaultHints.dateDefaults;
            } else if (
                formatType === ColumnHintFormatTypes.DateTimeColumns &&
                this.props.defaultHints.dateTimeDefaults
            ) {
                hint = this.props.defaultHints.dateTimeDefaults;
            } else if (formatType === ColumnHintFormatTypes.AllColumns) {
                hint = this.props.defaultHints;
            }
        }
        switch (formatType) {
            case ColumnHintFormatTypes.DateColumns:
            case ColumnHintFormatTypes.DateTimeColumns:
            case DataType.Date:
            case DataType.DateTime:
                return (
                    <ColumnHintDateFormat
                        columnHint={hint}
                        onChangeHint={this.props.onChangeHint}
                    />
                );
            case ColumnHintFormatTypes.NumericColumns:
            case DataType.Number:
                return (
                    <ColumnHintNumberFormat
                        columnHint={hint}
                        onChangeHint={this.props.onChangeHint}
                    />
                );
            case ColumnHintFormatTypes.StringColumns:
            case DataType.String:
            case DataType.Boolean:
            case DataType.Unknown: // If we don't know the datatype we treat it as a string for a specific column
                return (
                    <ColumnHintStringFormat
                        columnHint={hint}
                        onChangeHint={this.props.onChangeHint}
                    />
                );
            case ColumnHintFormatTypes.AllColumns:
                return (
                    <ColumnHintAllFormat columnHint={hint} onChangeHint={this.props.onChangeHint} />
                );
            default:
                throw new Error('Unknown format ui to display');
        }
    }
}
