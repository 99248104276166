import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import PolicyGuidelines from '../../../../../components/app/Preferences/InvestmentPolicy/PolicyGuidelines';
import PolicyScope from '../../../../../components/app/Preferences/InvestmentPolicy/PolicyScope';
import {getCurrencyList, getInvestmentPolicyData} from '../../../../../selectors/pages/preferences/investmentPolicy';
import './index.scss';
import {Conditional} from '../../../../../components/core/Conditional';
import translator from '../../../../../services/translator';
import Button from '../../../../../components/core/Button';
const {translate: t} = translator;

export const PolicyDetails = (props) => {
  const {investmentPolicyData, currencyList} = props;
  const testId = 'policy-details';
  return (
    <div className="invt-policy-details">
      <Conditional condition={isEmpty(investmentPolicyData)}>
        <div className="invt-policy-details__no-data" >{t('tkNoInvestmentPolicyData')}</div>
        <div>
          <PolicyScope  {...investmentPolicyData} testId={testId} />
          <div className="invt-policy-details__divider"  />
          <PolicyGuidelines  policyGuidelines={investmentPolicyData.guidelines} currencyList={currencyList}  />
          <div className="invt-policy-details__cta" >
            <Button isDisabled={true} customClass="button-primary" label={t('tkSave')} />
          </div>
        </div>
      </Conditional>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currencyList:getCurrencyList(state),
  investmentPolicyData: getInvestmentPolicyData(state),
});

PolicyDetails.propTypes = {
  investmentPolicyData: PropTypes.array,
  currencyList: PropTypes.array
};

export const mapDispatchToProps = () => (
  {

  }
);
export default connect(mapStateToProps, mapDispatchToProps)(PolicyDetails);
