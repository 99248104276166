import {
    MouseEvent,
    KeyboardEvent,
    CSSProperties,
    ReactNode,
    FunctionComponent,
    useEffect,
} from 'react';
import { Icon, IconBaseProps } from '@gs-ux-uitoolkit-react/icon-font';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import {
    CloseButtonProps as CommonCloseButtonProps,
    closeStyleSheet,
    getCloseRootClassNames,
    getIconClassNames,
    getButtonClassNames,
    defaultCloseButtonProps,
} from '@gs-ux-uitoolkit-common/close-button';
import { extractAriaAttributes } from '@gs-ux-uitoolkit-react/shared';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { componentAnalytics } from './analytics-tracking';

export type CloseButtonClickEvent = MouseEvent | KeyboardEvent;

export interface CloseButtonProps extends CommonCloseButtonProps<CSSProperties> {
    /**
     * Custom attributes for the close button.
     *
     * This can be used to set analytics attributes.
     */
    buttonAttrs?: { [attrName: string]: string };

    /**
     * Additional children to render with the close
     */
    children?: ReactNode;

    /**
     * Icon to be used. This defaults to the close icon from icon-fonts
     */
    icon?: IconBaseProps;

    /**
     * ID of the parent element or a description section that will be closed by this button.
     * A valid value must be passed to this to ensure accessibility
     */
    controlId?: string;

    /**
     * Handler for click event. The args for this event could be a mouse event or a keyboard
     * event based on the user's interaction
     */
    onClick?: (e: CloseButtonClickEvent) => void;
}

/**
 * CloseButton component to render a simple <close> control
 */
export const CloseButton: FunctionComponent<CloseButtonProps> = ({
    children,
    classes: overrideClasses,
    buttonAttrs,
    size = defaultCloseButtonProps.size,
    surface,
    emphasis,
    link,
    className,
    buttonClassName,
    controlId,
    onClick,
    icon = { name: 'close', type: 'filled' },
    tabIndex,
    disabled,
    ...attributes
}) => {
    const theme = useTheme();
    const cssClasses = useStyleSheet(closeStyleSheet, {
        theme,
        size,
        surface,
        emphasis,
        link,
    });
    const rootClasses = getCloseRootClassNames({
        cssClasses,
        className,
        overrideClasses,
    });
    const buttonClasses = getButtonClassNames({
        cssClasses,
        overrideClasses,
        buttonClassName,
    });
    const iconClasses = getIconClassNames({
        cssClasses,
        overrideClasses,
    });

    const handleOnClick = (e: MouseEvent) => {
        if (onClick) {
            onClick(e);
        }
    };

    const ariaAttributes = extractAriaAttributes(attributes);

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'other' });
    }, []); // Only run once

    return (
        <span
            data-gs-uitk-component="close-button"
            data-size={size}
            data-link={link}
            className={rootClasses}
            {...attributes}
        >
            <button
                {...buttonAttrs}
                data-cy="gs-uitk-close__button"
                className={buttonClasses}
                aria-controls={controlId}
                aria-label="Close"
                {...ariaAttributes}
                onClick={handleOnClick}
                tabIndex={tabIndex}
                disabled={disabled}
                type="button"
            >
                <span className={iconClasses} data-cy="gs-uitk-close__icon">
                    <Icon aria-hidden="true" {...icon} size={size} />
                </span>
            </button>
        </span>
    );
};
