import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/core/Modal';
import Button from '../../../../components/core/Button';
import translator  from '../../../../services/translator';

import './index.scss';
const {translate: t} = translator;

const customModalStyle={position: 'fixed', top: '50%', left: '50%', height: 'auto', transform: 'translate(-50%, -50%)'};

const ApprovalModal = ({isModalOpen, handleClose, handleApproval, currencyCode, fee}) => {
  const approvalModalRef = useRef();
  const onClose = (status) => {
    approvalModalRef.current.closeModal(() => handleClose(status));
  };

  const handleTradeApproval = () => {
    approvalModalRef.current.closeModal(() => handleApproval());
  };

  return(
    <Modal
      ref={approvalModalRef}
      customModalStyle={customModalStyle}
      suppressScrollOnActive={true}
      customClass="modal--center approval-modal external-modal-index"
      backdropCustomClass="external-modal-backdrop-index"
      open={isModalOpen}
      handleClose={handleClose}
      animation={true}
      animationTiming={580}
      animationClassOpen="approval-animation-expand-center"
      animationClassClose="approval-animation-collapse-center"

    >
      <div className="extNavConfirmation">
        <div className="extNavConfirmation__heading">{t('tkFeeNotice')}</div>
        <hr className="extNavConfirmation__separator" />
        <div className="extNavConfirmation__inputContainer">
          {t('tkApprovalWarning', currencyCode, fee)}
        </div>
        <div className="extNavConfirmation__buttonRow">
          <Button label={t('tkApprove')} customClass='button-primary-small' clickHandler={(event) => handleTradeApproval(event)} />
          <Button label={t('tkCancel')}   customClass='button-secondary-small' clickHandler={(event) => onClose(event)} />
        </div>
      </div>
    </Modal>
  );
};

ApprovalModal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleApproval: PropTypes.func,
  currencyCode: PropTypes.string,
  fee: PropTypes.string
};

export default ApprovalModal;
