import { Component, Context } from 'react';
import { connect, ReactReduxContextValue } from 'react-redux';
import * as Redux from 'redux';
import { arrayHelper } from '@gs-ux-uitoolkit-common/datacore';
import { Checkbox } from '@gs-ux-uitoolkit-react/checkbox';
import { ButtonGroup } from '../../../components/buttons/buttons-group';
import { PluginHeader } from '../../../components/plugin-header/plugin-header';
import { RemoveSavedView, SetSavedView } from '../../../redux/actions/saved-view-action';
import { DataGridState } from '../../../redux/datagrid-state';
import { SavedView } from '../saved-view';
import { ManagementTable, TableHeadings } from '../../../components/table/management-table';

/**
 * The props for the SavedViewModalComponent
 */
export interface SavedViewModalProps {
    currentSavedView: string | null;
    savedViewList: SavedView[];
    removeSavedView: (name: string) => void;
    setSavedView: (name: string) => void;
}

/**
 * * The main SavedView modal
 */
export class SavedViewModalComponent extends Component<SavedViewModalProps> {
    public render() {
        const sortedSavedView = arrayHelper.sort(this.props.savedViewList, false, 'name');
        const savedViewElements = sortedSavedView.map(savedView => {
            const removeSavedView = () => this.props.removeSavedView(savedView.name);
            const onSetCurrent = () => {
                this.props.setSavedView(savedView.name);
            };
            return (
                <tr
                    key={savedView.name}
                    className={
                        savedView.name === this.props.currentSavedView ? 'table-primary' : ''
                    }
                >
                    <td>{savedView.name}</td>
                    <td>
                        <Checkbox
                            name="current"
                            checked={this.props.currentSavedView === savedView.name}
                            onChange={onSetCurrent}
                            disabled={this.props.currentSavedView === savedView.name}
                        />
                    </td>
                    <td>{savedView.columnState && <Checkbox defaultChecked disabled />}</td>
                    <td>{savedView.columnGroups && <Checkbox defaultChecked disabled />}</td>
                    <td>{savedView.rowGroupColumns && <Checkbox defaultChecked disabled />}</td>
                    <td>{savedView.pivotState && <Checkbox defaultChecked disabled />}</td>
                    <td>
                        <ButtonGroup
                            hideEdit={true}
                            deleteClick={removeSavedView}
                            disableDelete={this.props.currentSavedView === savedView.name}
                            hideDelete={
                                savedView.savedViewOptions && savedView.savedViewOptions.readOnly
                            }
                        />
                    </td>
                </tr>
            );
        });

        const savedViewHeaders: TableHeadings[] = [
            {
                label: 'Name',
                props: {
                    className: 'column-name-column',
                },
            },
            { label: 'Current Saved View' },
            { label: 'Columns' },
            { label: 'Column Groups' },
            { label: 'Pivots' },
            { label: 'HPivots' },
            {
                label: '',
                props: {
                    className: 'action',
                },
            },
        ];

        return (
            <div className="config-items-container gs-uitk-saved-view-plugin">
                <PluginHeader displayCreateButton={true} subTitle="Current SavedViews" />
                <ManagementTable
                    headingConfig={savedViewHeaders}
                    bodyRowElements={savedViewElements}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: DataGridState) => {
    return {
        currentSavedView: state.savedView.currentSavedView,
        savedViewList: state.savedView.savedViewList,
    };
};

const mapDispatchToProps = (dispatch: (action: Redux.Action) => void) => {
    return {
        removeSavedView: (name: string) => dispatch(RemoveSavedView(name)),
        setSavedView: (name: string) => dispatch(SetSavedView(name)),
    };
};

export const getSavedViewPluginModal = (context?: Context<ReactReduxContextValue>) =>
    connect(mapStateToProps, mapDispatchToProps, null, { context })(SavedViewModalComponent);
