import { createRoot } from 'react-dom/client';

import { ILoadingCellRenderer } from '@ag-grid-community/core';
import { LoadingOverlay, LoadingIcon } from '@gs-ux-uitoolkit-react/loading';
import { lightTheme, Theme, ThemeProvider } from '@gs-ux-uitoolkit-react/theme';

export class CustomLoadingCellRenderer implements ILoadingCellRenderer {
    public eGui: HTMLDivElement;
    constructor() {
        this.eGui = document.createElement('div');
    }
    public init(params?: { loadingMessage: string; theme?: Theme }): void {
        const msg = params && params.loadingMessage ? params.loadingMessage : 'One Moment...';

        const innerHTML = (
            <ThemeProvider theme={params?.theme || lightTheme}>
                <div className="gs-uitk-ag-custom-loading-row">
                    <LoadingOverlay visible>
                        <LoadingIcon shape="circle" />
                    </LoadingOverlay>
                    <span>{msg}</span>
                </div>
            </ThemeProvider>
        );

        const root = createRoot(this.eGui);
        root.render(innerHTML);
    }
    public getGui(): HTMLElement {
        return this.eGui;
    }
    public refresh(): boolean {
        return true;
    }
}
