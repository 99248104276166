import { Theme } from '@gs-ux-uitoolkit-common/theme';

export function getFormTheme(theme: Theme) {
    return {
        input: {
            backgroundColor: theme.surface.primary,
            borderColor: theme.border.input,
            borderHoverColor: theme.increaseContrast(theme.border.input, 1),
        },
        clearButton: {
            iconColor: theme.increaseContrast(theme.border.strong, 1),
            iconHoverColor: theme.increaseContrast(theme.border.strong, 2),
            iconActiveColor: theme.increaseContrast(theme.border.strong, 3),
        },
    };
}
