import { useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import useFetch from '../useFetch';
import useSnackBar from '../useSnackbar';
import endPointsMapper from '../../../../../configs/endPointsMapper';
import useUserMaintenance from '../../context';
import translator from '../../../../../services/translator';

const { translate: t } = translator;

const useGetChangeManagementGrid = type => {
  const {
    state: { userDetails },
  } = useUserMaintenance();
  const { openSnackbar } = useSnackBar();

  let endpoint = '';

  const { userGuid } = userDetails;
  useMemo(() => {
    endpoint = cloneDeep(endPointsMapper.CHANGE_MANAGEMENT_DETAILS);
    endpoint.path = `${endpoint.path}/${userGuid}?type=${type}`;
  });

  const onError = () => {
    openSnackbar({
      type: 'error',
      message: t('tkChangeManagementDetailsError'),
    });
  };

  const { data, doFetch, loading, error } = useFetch(endpoint, null, onError);

  return { data, loading, doFetch, error };
};

export default useGetChangeManagementGrid;
