import { ColumnHintFormatSection } from '../../plugins/column-hint/view/components/formatScreens/column-hint-format-section';
import { Row, Col } from '@gs-ux-uitoolkit-react/layout';
import { FormatControlProps } from '../../plugins/column-hint/view/components/column-hint-format-manager';
import { ColumnHintControlHyperlink } from '../../plugins/column-hint/view/components/formatControls/column-hint-control-hyperlink';
export const ColumnHintHyperlinksSection = (props: FormatControlProps) => {
    return (
        <ColumnHintFormatSection title="Hyperlinks">
            <Row>
                <Col sm={12}>
                    <ColumnHintControlHyperlink
                        columnHint={props.columnHint}
                        onChangeHint={props.onChangeHint}
                    />
                </Col>
            </Row>
        </ColumnHintFormatSection>
    );
};
