import { ComponentType } from 'react';

/**
 * Retrieves the name of the component. If the name cannot be determined,
 * returns undefined.
 *
 * 1. Returns the component's `.displayName` prop (if the property exists)
 * 2. Returns the component's `.name` prop (if it exists)
 */
export function getComponentName(component: ComponentType<unknown>): string | undefined {
    return component.displayName || component.name;
}
